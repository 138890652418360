import React from "react";
import cx from "classnames";
import styles from "./EditInput.module.css";
import commonStyles from "../../ChartsRibbon.module.css";

export default function EditInput(props: {
    disabled?: boolean;
    showDeleteButton: boolean;
    onDelete?: () => void;
    optionalClassName?: string;
    optionalStyles?: React.CSSProperties;
    optionalInputStyle?: React.CSSProperties;
    value: string | number;
    onChange?: (value: string | number) => void;
}) {
    let [value, setValue] = React.useState(props.value);
    React.useEffect(() => {
        if (typeof props.value === "number" && props.value !== Number(value))
            setValue(props.value);
        if (typeof props.value === "string" && props.value !== value)
            setValue(props.value);
        // value should not be in dependencies
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);
    return (
        <div
            style={{ position: "relative", ...props.optionalStyles }}
            className={cx(styles.valueContainer, props.optionalClassName)}
        >
            <input
                className={styles.value}
                disabled={props.disabled}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                    if (evt.key === "Enter") {
                        props.onChange?.(value);
                    }
                }}
                value={value}
                onChange={(evt) => {
                    let newValue = evt.target.value;
                    setValue(newValue);
                }}
                onBlur={() => {
                    props.onChange?.(value);
                }}
                style={props.optionalInputStyle}
            ></input>
            {props.showDeleteButton && (
                <div
                    style={{ position: "absolute", right: 0, top: 0 }}
                    className={commonStyles.upDownButtons}
                    onClick={props?.onDelete}
                >
                    {"\uFF0D"}
                </div>
            )}
        </div>
    );
}
