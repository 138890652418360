import React from "react";
import { formatValue } from "common/utilities/FormatValue";

function OutcomesBlockInnerV3(item, leftTitle, rightTitle, leftKey, rightKey) {
    let current = item[leftKey] || 0;
    let optimal = item[rightKey] || 0;
    let unitName = item.units || "";

    let formatValueOptimal = formatValue(optimal, false);
    let formatValueCurrent = formatValue(current, false);
    let formatValueOptimalString = formatValueOptimal[0].concat(
        formatValueOptimal[1]
    );
    let formatValueCurrentString = formatValueCurrent[0].concat(
        formatValueCurrent[1]
    );

    return (
        <div
            className="my-row"
            style={{
                marginTop: 10,
                marginBottom: 10,
            }}
        >
            <span
                style={{
                    color: "#FFAB4F",
                    marginLeft: 10,
                    textAlign: "left",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    wordWrap: "break-word",
                }}
            >
                {`${leftTitle} ${item.name || ""}: ${
                    unitName && unitName !== "%" ? unitName : ""
                }${formatValueCurrentString}${
                    unitName === "%" ? unitName : ""
                }`}
            </span>
            <span
                style={{
                    color: "#05C985",

                    marginLeft: 10,
                    textAlign: "left",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    wordWrap: "break-word",
                }}
            >
                {`${rightTitle} ${item.name || ""}: ${
                    unitName && unitName !== "%" ? unitName : ""
                }${formatValueOptimalString}${
                    unitName === "%" ? unitName : ""
                }`}
            </span>
        </div>
    );
}

function OutcomesBlockV3(props) {
    const { item, leftTitle, rightTitle, leftKey, rightKey } = props;
    return OutcomesBlockInnerV3(item, leftTitle, rightTitle, leftKey, rightKey);
}

export default OutcomesBlockV3;
