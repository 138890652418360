import {
    CanvasSurvey,
    SurveyQuestionType,
    SurveyQuestion,
    isRuleSurveyQuestion,
    RuleSurveyQuestion,
} from "common/Canvas";
import React from "react";
import styles from "../Questions.module.css";
import cx from "classnames";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { ReactComponent as RemoveIcon } from "icons/canvas/close.svg";
import SliderConfig from "./Inputs/Slider";
import SelectConfig from "./Inputs/Select";
import { EditVariable, NewVariable } from "common/VariableCreator";
import { shortenColumnName, typeOptions } from "../../utils";

interface QuestionProps {
    modifyDbSchema: (
        newVariable: NewVariable | null,
        dropVariable: number | null,
        editVariable: EditVariable | null,
        previousState?: SurveyQuestion
    ) => void;
    questionIndex: number;
    node: CanvasSurvey;
    onChange: (
        changes: Partial<CanvasSurvey>,
        commit?: boolean,
        skipHistory?: boolean
    ) => void;
}

const RenderQuestion: React.FC<QuestionProps> = ({
    questionIndex,
    onChange,
    node,
    modifyDbSchema,
}) => {
    const question = node.questions[questionIndex];
    let [optionSelected, setOptionSelected] = React.useState(0);

    const regex = /Question (\d+)\.\.\.$/;

    const _removeQuestion = (
        evt: React.MouseEvent<SVGSVGElement, MouseEvent>
    ) => {
        evt.stopPropagation();
        if (
            node.questions.filter((q) => q.type !== SurveyQuestionType.Rule)
                .length > 1
        ) {
            let newQuestions = [...node.questions];
            let deletedQuestion = node.questions[questionIndex];
            newQuestions.splice(questionIndex, 1);
            for (let i = 0; i < newQuestions.length; ++i) {
                let question = newQuestions[i];
                if (
                    isRuleSurveyQuestion(question) &&
                    question.linkedToId === node.questions[questionIndex].id
                ) {
                    newQuestions[i] = {
                        ...newQuestions[i],
                        linkedToId: null,
                    } as RuleSurveyQuestion;
                }
            }
            onChange({ questions: newQuestions }, true, false);

            modifyDbSchema(null, questionIndex, null, deletedQuestion);
        } else {
            let rules = node.questions.filter(
                (q) => q.type === SurveyQuestionType.Rule
            );
            onChange(
                {
                    questions: [
                        {
                            id: 1,
                            question: "Question 1...",
                            type: 1,
                            options: [],
                            required: false,
                        },
                        ...rules,
                    ],
                },
                true,
                false
            );

            let oldQuestion = node.questions[questionIndex];

            let editVariable: EditVariable = {
                index: questionIndex,
                new_name: "Question 1...",
            };

            modifyDbSchema(null, null, editVariable, oldQuestion);
        }
    };

    const _onSelectOptionChange = (question: SurveyQuestion) => {
        let newQuestions = [...node.questions];
        newQuestions[questionIndex] = question;

        onChange({ questions: newQuestions });
    };

    const _onRequiredChange = () => {
        let newQuestions = [...node.questions];
        newQuestions[questionIndex] = question;
        newQuestions[questionIndex].required =
            !newQuestions[questionIndex].required ?? true;
        onChange({ questions: newQuestions });
    };

    const _updateQuestionTitle = async (
        question: SurveyQuestion,
        questionIndex: number
    ) => {
        if (node.backendOutput.tableOption?.data_table_idx) {
            let newQuestion = {
                ...question,
                columnName: shortenColumnName(
                    question.question,
                    node.questions.map((q) => q.columnName ?? q.question)
                ),
            };
            let questions = [...node.questions];
            let oldQuestion = questions[questionIndex];
            questions[questionIndex] = newQuestion;

            onChange({ questions: questions });
            let editVariable: EditVariable = {
                index: questionIndex,
                new_name: newQuestion.columnName,
            };

            modifyDbSchema(null, null, editVariable, oldQuestion);
        }
    };
    const defaultValue =
        question.question?.match(regex) ||
        question.question === "Invalid question title"
            ? ""
            : question.question;
    return (
        <div className={styles.questionWrapper}>
            <Accordion
                className={styles.accordion}
                style={{
                    borderBottom: "none",
                }}
                activeKey={String(optionSelected)}
                onSelect={(option) => {
                    setOptionSelected(Number(option));
                }}
            >
                <Accordion.Toggle
                    eventKey={"1"}
                    className={styles.editMenuAdvancedOption}
                >
                    <div key={defaultValue}>
                        <input
                            placeholder={question.question}
                            className={styles.questionTitleInput}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                            }}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onKeyUp={(evt) => {
                                evt.preventDefault();
                            }}
                            onBlur={(e) => {
                                let val = e.target.value.trim();
                                let newQuestion = {
                                    ...node.questions[questionIndex],
                                    question: val
                                        ? val
                                        : `Question ${question.id}...`,
                                };
                                _updateQuestionTitle(
                                    newQuestion,
                                    questionIndex
                                );
                            }}
                            defaultValue={defaultValue}
                        />
                    </div>
                    <ChevronIcon
                        className={cx(styles.editMenuAdvancedOptionIcon, {
                            [styles.chevronOpen]: optionSelected === 1,
                        })}
                    />

                    <RemoveIcon
                        onClick={(evt) => {
                            _removeQuestion(evt);
                        }}
                        className={styles.removeQuestion}
                    />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={"1"}>
                    <div className={styles.editMenuAdvancedOptionContent}>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "2fr 1fr",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Select
                                isSearchable={false}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: "4px",
                                        width: "140px",
                                        minHeight: "24px",
                                        maxHeight: "24px",
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none",
                                    }),
                                    indicatorsContainer: (provided) => ({
                                        ...provided,
                                        height: "20px",
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        maxWidth: 120,
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        color: "black",
                                        "&:hover": {
                                            backgroundColor: "#eaf0fa",
                                        },
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 10,
                                    colors: {
                                        ...theme.colors,
                                        text: "black",
                                        primary25: "#eaf0fa",
                                    },
                                })}
                                options={typeOptions}
                                onChange={(e) => {
                                    let newQuestions = [...node.questions];
                                    let q = {
                                        ...newQuestions[questionIndex],
                                        type: e!.value,
                                    };
                                    newQuestions[questionIndex] = q;
                                    if (
                                        q.type === SurveyQuestionType.Dropdown
                                    ) {
                                        if (!q.options.length)
                                            q.options = [
                                                {
                                                    value: "",
                                                },
                                            ];
                                    } else if (
                                        q.type === SurveyQuestionType.Slider
                                    ) {
                                        q.min = "0";
                                        q.max = "10";
                                    }

                                    onChange({ questions: newQuestions });

                                    if (
                                        node.backendOutput.tableOption
                                            ?.data_table_idx
                                    ) {
                                        let editVariable: EditVariable = {
                                            index: questionIndex,
                                            type: e!.type,
                                        };

                                        modifyDbSchema(
                                            null,
                                            null,
                                            editVariable,
                                            node.questions[questionIndex]
                                        );
                                    }
                                }}
                                value={typeOptions.find(
                                    (x) => x.value === question.type
                                )}
                            />
                            <div
                                className="pretty p-default"
                                contentEditable={false}
                            >
                                <input
                                    type="checkbox"
                                    checked={question.required ?? false}
                                    onChange={(e) => {
                                        _onRequiredChange();
                                    }}
                                />
                                <div
                                    className={cx(
                                        "state p-primary",
                                        styles.allowNansLabel
                                    )}
                                >
                                    <label>Require input</label>
                                </div>
                            </div>
                        </div>
                        {question.type === SurveyQuestionType.Slider && (
                            <SliderConfig
                                onChange={_onSelectOptionChange}
                                question={question}
                            />
                        )}
                        {question.type === SurveyQuestionType.Dropdown && (
                            <SelectConfig
                                onChange={_onSelectOptionChange}
                                question={question}
                            />
                        )}
                    </div>
                </Accordion.Collapse>
            </Accordion>
            <div className={styles.questionDivLine}></div>
        </div>
    );
};

export default RenderQuestion;
