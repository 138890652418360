import React from "react";
import Switch from "react-switch";

import { CanvasSlider } from "common/Canvas";

import styles from "./Settings.module.css";
import { GlobalContextContents } from "GlobalContext";

interface Props {
    node: CanvasSlider;
    shared: boolean;
    globalContext: GlobalContextContents;
    onChange: (changes: Partial<CanvasSlider>, commit?: boolean) => void;
    onChangeShared: (shared: boolean) => void;
}

function Settings({
    node,
    onChange,
    shared,
    globalContext,
    onChangeShared,
}: Props) {
    const [sliderStepSize, setSliderStepSize] = React.useState(
        String(node.stepSize ?? 1)
    );

    const [sliderThickness, setSliderThickness] = React.useState(
        String(node?.lineThickness ?? 3)
    )

    return (
        <div className={styles.root}>
            <div className={styles.otherFields}>

                <span className={styles.inputLabel}>Thickness / Height</span>
                <input
                    className="like-select"
                    style={{
                        marginTop: "10px",
                        width: "100%",
                        marginBottom: "10px"
                    }}
                    placeholder=""
                    onChange={(e) => {
                        const value: string = e.target.value;
                        if (!/^[0-9]*\.?[0-9]*$/.test(value)) {
                            e.preventDefault();
                        } else {
                            let lineThickness: number = Number(value);
                            setSliderThickness(value);

                            if (lineThickness > 0) {
                                onChange({ lineThickness: lineThickness });
                            }
                        }
                    }}
                    value={sliderThickness}
                />
                <span className={styles.inputLabel}>Step size</span>
                <input
                    className="like-select"
                    style={{
                        marginTop: "10px",
                        width: "100%",
                    }}
                    placeholder=""
                    onChange={(e) => {
                        const value: string = e.target.value;
                        if (!/^[0-9]*\.?[0-9]*$/.test(value)) {
                            e.preventDefault();
                        } else {
                            setSliderStepSize(value);
                            let stepSize: number = Number(value);
                            if (stepSize > 0) {
                                onChange({ stepSize: stepSize });
                            }
                        }
                    }}
                    value={sliderStepSize}
                />
                <div className={styles.switchField}>
                    <Switch
                        onChange={() => {
                            onChange({
                                vertical: !(
                                    (node as CanvasSlider).vertical ?? false
                                ),
                            });
                        }}
                        checked={node.vertical ?? false}
                        width={26}
                        height={13}
                        offColor="#D1D1D1"
                        onColor="#20293C"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#FFF"
                        onHandleColor="#1F8EFA"
                    />
                    <span className={styles.switchLabel}>Vertical</span>
                </div>
                <div className={styles.switchField}>
                    <Switch
                        onChange={(checked) => {
                            onChangeShared(checked);
                        }}
                        checked={shared}
                        width={26}
                        height={13}
                        offColor="#D1D1D1"
                        onColor="#20293C"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#FFF"
                        onHandleColor="#1F8EFA"
                    />
                    <span className={styles.switchLabel}>
                        Linkable from other slides
                    </span>
                </div>
                {"CanvasLiveStream" in globalContext.permissions && (
                    <div className={styles.switchField}>
                        <Switch
                            onChange={(checked) => {
                                onChange({ liveStreaming: checked });
                            }}
                            checked={node.liveStreaming ?? false}
                            width={26}
                            height={13}
                            offColor="#D1D1D1"
                            onColor="#20293C"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#FFF"
                            onHandleColor="#1F8EFA"
                        />
                        <span className={styles.switchLabel}>
                            Record Metric to Dataset
                        </span>
                    </div>
                )}
                <div className={styles.switchField}>
                    <Switch
                        onChange={(checked) => {
                            onChange({
                                range: checked,
                                additionalOutputs: [ node.maxOutput ],
                            });
                        }}
                        checked={node.range ?? false}
                        width={26}
                        height={13}
                        offColor="#D1D1D1"
                        onColor="#20293C"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#FFF"
                        onHandleColor="#1F8EFA"
                    />
                    <span className={styles.switchLabel}>
                        Add start and end points
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Settings;
