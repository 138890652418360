import React from "react";
import ManageTableView from "../ManageTableView";
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { Resizable } from "re-resizable";
import elements from "common/CanvasElements";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import HtmlElementProps from "./HtmlElementProps";
import {
    getNewSizeAfterResize2,
    changeElementWhenResize2,
} from "../BaseCanvasResizableFunctions";
import { InnerCanvasChanges } from "common/Canvas";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../Snap";

interface InnerProps extends HtmlElementProps {
    manageDataId: string;
    rootDataTestId: string;
}

interface InnerState {}

const typeName = "manageTableElementsState";

@observer
class ManageDataElementWrapper extends CanvasInteractionComponent<
    InnerProps,
    InnerState
> {
    private drag: boolean;

    constructor(props: InnerProps) {
        super(props);
        this.drag = false;
    }

    public render(): JSX.Element {
        const { canvasViewMode } = this.props.canvasTreeStore;
        let key = this.props.manageDataId;
        let manageTableElement = this.props.canvasTreeStore.manageTableElementsState.get(
            key
        )!;
        let manageTableElementSize = {
            height:
                manageTableElement.nodeSize[canvasViewMode].height *
                this.props.scale,
            width:
                manageTableElement.nodeSize[canvasViewMode].width *
                this.props.scale,
        };

        return (
            <DraggableWithSnapping
                key={key}
                disabled={this.props.live || !this.props.canWrite}
                position={{
                    x:
                        manageTableElement.nodePosition[canvasViewMode].x *
                        this.props.scale,
                    y:
                        manageTableElement.nodePosition[canvasViewMode].y *
                        this.props.scale,
                }}
                onDrag={(_evt, _data) => {
                    this.drag = true;
                    let nearestPoints = this.props.onRebuildSnapLine(
                        {
                            x: _data.x,
                            y: _data.y,
                            width: manageTableElementSize.width,
                            height: manageTableElementSize.height,
                        },
                        {
                            type: typeName,
                            id: this.props.manageDataId,
                            groupId: manageTableElement.groupId,
                        }
                    );
                    this.props.onUpdateSelectionBounds?.();
                    let newPosition = snapElementToPoints(
                        manageTableElementSize.width,
                        manageTableElementSize.height,
                        nearestPoints
                    );
                    if (newPosition.x != null || newPosition.y != null) {
                        // Snap to this position
                        return newPosition;
                    }
                }}
                onStop={(_evt, data) => {
                    if (this.drag) {
                        this.props.onDeleteSnapLine();
                        this.trackNewPerformance(
                            elements.manageTablesDataProcessing
                        );
                        let x = Math.max(data.x / this.props.scale, 0);
                        let y = Math.max(data.y / this.props.scale, 0);
                        let deltaX =
                            x -
                            manageTableElement.nodePosition[canvasViewMode].x;
                        let deltaY =
                            y -
                            manageTableElement.nodePosition[canvasViewMode].y;

                        const nodePosition = {
                            ...manageTableElement.nodePosition,
                            [canvasViewMode]: {
                                x,
                                y,
                            },
                        };

                        let changes: InnerCanvasChanges = {};
                        this.props.canvasTreeStore.updateManageTableElementAction(
                            key,
                            { nodePosition },
                            changes
                        );
                        this.props.canvasTreeStore.updateCanvasSizeAction({
                            x: x,
                            y: y,
                            width:
                                manageTableElement.nodeSize[canvasViewMode]
                                    .width,
                            height:
                                manageTableElement.nodeSize[canvasViewMode]
                                    .height,
                        });
                        this.props.onMoveGroupSelection(
                            deltaX,
                            deltaY,
                            {
                                id: this.props.manageDataId,
                                type: typeName,
                                groupId: manageTableElement.groupId,
                            },
                            false,
                            changes
                        );
                        this.props.canvasTreeStore.saveChangesAction(
                            changes,
                            true,
                            true,
                            false,
                            this.props.canvasTreeStore.backgroundsState.toJSON(),
                            BackgroundMode.Update,
                            false
                        );
                        this.drag = false;
                    }
                }}
            >
                <div
                    style={{
                        top: 0,
                        left: 0,
                        position: "absolute",
                        zIndex: manageTableElement.zIndex ?? 50,
                    }}
                    onContextMenu={(evt) => {
                        this.props.onContextMenu(
                            evt,
                            {
                                id: key,
                                type: typeName,
                            },
                            true
                        );
                    }}
                >
                    <Resizable
                        className="selectable-by-pointer"
                        ref={(ref) => {
                            let innerRef = ref?.resizable;
                            if (innerRef != null) {
                                innerRef.setAttribute("type", typeName);
                                if (manageTableElement.groupId != null)
                                    innerRef.setAttribute(
                                        "groupId",
                                        manageTableElement.groupId
                                    );
                                else {
                                    innerRef.removeAttribute("groupId");
                                }
                                innerRef.setAttribute(
                                    "id",
                                    String(this.props.manageDataId)
                                );
                                innerRef.setAttribute(
                                    "data-test-id",
                                    this.props.rootDataTestId
                                );
                            }
                        }}
                        enable={
                            this.props.live || !this.props.canWrite
                                ? {
                                      top: false,
                                      right: false,
                                      bottom: false,
                                      left: false,
                                      topRight: false,
                                      bottomRight: false,
                                      bottomLeft: false,
                                      topLeft: false,
                                  }
                                : {
                                      top: true,
                                      right: true,
                                      bottom: true,
                                      left: true,
                                      topRight: true,
                                      bottomRight: true,
                                      bottomLeft: true,
                                      topLeft: true,
                                  }
                        }
                        onResizeStart={(evt) => {
                            evt.stopPropagation();
                        }}
                        onResize={(_e, _direction, _ref, d) => {
                            const {
                                canvasViewMode,
                            } = this.props.canvasTreeStore;
                            changeElementWhenResize2(
                                {
                                    position: manageTableElement.nodePosition,
                                    size: manageTableElement.nodeSize,
                                },
                                this.props.scale,
                                _direction,
                                d,
                                _ref,
                                canvasViewMode
                            );
                            this.props.onUpdateSelectionBounds?.();
                        }}
                        onResizeStop={(_e, _direction, _ref, d) => {
                            const {
                                canvasViewMode,
                            } = this.props.canvasTreeStore;
                            this.trackNewPerformance(
                                elements.manageTablesDataProcessing
                            );
                            let newSize = getNewSizeAfterResize2(
                                {
                                    position: manageTableElement.nodePosition,
                                    size: manageTableElement.nodeSize,
                                },
                                this.props.scale,
                                _direction,
                                d,
                                canvasViewMode
                            );
                            this.props.canvasTreeStore.updateManageTableElementAction(
                                key,
                                {
                                    nodePosition: newSize.nodePosition,
                                    nodeSize: newSize.nodeSize,
                                }
                            );
                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                x: newSize.nodePosition[canvasViewMode].x,
                                y: newSize.nodePosition[canvasViewMode].y,
                                ...newSize.nodeSize[canvasViewMode],
                            });
                            this.props.onResize();
                        }}
                        size={manageTableElementSize}
                    >
                        <ManageTableView
                            onContextMenu={(evt) => {
                                this.props.onContextMenu(
                                    evt,
                                    {
                                        id: key,
                                        type: typeName,
                                    },
                                    true
                                );
                            }}
                            sharedPolicy={this.props.sharedPolicy}
                            frozen={!this.props.canWrite}
                            height={manageTableElementSize.height}
                            live={this.props.live}
                            manageTableElement={manageTableElement}
                            manageTableElementId={key}
                            onChange={(manageTableElement) => {
                                this.trackNewPerformance(
                                    elements.manageTablesDataProcessing
                                );

                                this.props.canvasTreeStore.updateManageTableElementAction(
                                    key,
                                    manageTableElement
                                );
                            }}
                            onDelete={() => {
                                this.trackNewPerformance(
                                    elements.manageTablesDataProcessing
                                );
                                this.props.showDeletePopup(() => {
                                    this.props.onClearEditing();
                                    this.props.canvasTreeStore.deleteManageTableElementAction(
                                        key
                                    );
                                });
                            }}
                            currentModuleId={this.props.currentModuleId}
                        />
                    </Resizable>
                </div>
            </DraggableWithSnapping>
        );
    }
}

export default observer(function ManageDataElements(props: HtmlElementProps) {
    let manageDataUis: JSX.Element[] = [];
    for (let manageDataId of props.canvasTreeStore.manageTableElementsState.keys())
        manageDataUis.push(
            <ManageDataElementWrapper
                rootDataTestId={`manageDataElement-${manageDataUis.length + 1}`}
                key={manageDataId}
                manageDataId={manageDataId}
                {...props}
            />
        );
    return <>{manageDataUis}</>;
});
