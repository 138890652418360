import { stringSessionId } from "common/SessionId";
import Variables from "common/Variables";
import Tables from "common/Tables";
import DataScopes from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import {
	globalDatasetOperations,
	variableOperations,
	tableOperations,
} from "common/DatasetOperations";
import CurrentUser from "common/CurrentUser";

export function trackDataSetChanges(
	data: {
		user_name?: string;
		data_table_idx: string | number;
		operation: string;
		update_id?: string;
	},
	moduleId?: number
) {
	const operation = data.operation;
	const updateId = data.update_id;
	const sessionId = stringSessionId();
	if (
		updateId != null &&
		sessionId != null &&
		data.user_name?.toLowerCase() ===
			CurrentUser.infoState?.user_name?.toLowerCase() &&
		updateId === sessionId
	)
		return;
	if (variableOperations.has(operation)) {
		Variables(data.data_table_idx).update(moduleId);
	}
	if (globalDatasetOperations.has(operation)) {
		DataScopes.update();
		if (moduleId != null) DataScopesForModules(moduleId).update();
	}
	if (tableOperations.has(operation)) {
		Tables(data.data_table_idx).update(moduleId);
	}
}