import React, { Component } from "react";
import { ItemMetadata } from "common/Canvas";
import CanvasTreeStore from "../CanvasTreeStore";
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { snapElementToPoints, SnapPoint } from "../Snap";

interface SelectionBounds {
    left: number;
    top: number;
    right: number;
    bottom: number;
}

interface Props {
    canvasTreeStore: CanvasTreeStore;
    selectionBounds: SelectionBounds;
    selectedMetadata: ItemMetadata[];
    onContextMenu: (evt: any) => void;
    scale: number;
    onMoveSelection: (deltaX: number, deltaY: number) => void;
    onRebuildSnapLine: (
        rect: {
            x: number;
            y: number;
            width: number;
            height: number;
        },
        metadata: ItemMetadata | ItemMetadata[]
    ) => SnapPoint[];
    onDeleteSnapLine: () => void;
}

interface State {
    drag: boolean;
}
export default class DraggableTransformer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            drag: false,
        };
    }
    public render(): JSX.Element {
        let outlineZIndex: number =
            this.props.selectedMetadata.length === 1
                ? this.props.canvasTreeStore.zIndex(
                      this.props.selectedMetadata[0].id,
                      this.props.selectedMetadata[0].type
                  ) ?? 49
                : 997;
        return (
            <>
                <DraggableWithSnapping
                    onDrag={(_evt, data) => {
                        this.setState({ drag: true });
                        let nearestPoints = this.props.onRebuildSnapLine(
                            {
                                x: data.x,
                                y: data.y,
                                width:
                                    this.props.selectionBounds.right -
                                    this.props.selectionBounds.left,
                                height:
                                    this.props.selectionBounds.bottom -
                                    this.props.selectionBounds.top,
                            },
                            this.props.selectedMetadata
                        );
                        let newPosition = snapElementToPoints(
                            this.props.selectionBounds.right -
                                this.props.selectionBounds.left,
                            this.props.selectionBounds.bottom -
                                this.props.selectionBounds.top,
                            nearestPoints
                        );
                        if (newPosition.x != null || newPosition.y != null) {
                            // Snap to this position
                            return newPosition;
                        }
                    }}
                    onStop={(_evt, data) => {
                        this.setState((state) => {
                            if (state.drag) {
                                this.props.onDeleteSnapLine();
                                let x = Math.max(data.x / this.props.scale, 0);
                                let y = Math.max(data.y / this.props.scale, 0);
                                let deltaX =
                                    x -
                                    this.props.selectionBounds.left /
                                        this.props.scale;
                                let deltaY =
                                    y -
                                    this.props.selectionBounds.top /
                                        this.props.scale;
                                this.props.onMoveSelection(deltaX, deltaY);
                                return { drag: false };
                            } else {
                                return null;
                            }
                        });
                    }}
                    position={{
                        x: this.props.selectionBounds.left,
                        y: this.props.selectionBounds.top,
                    }}
                >
                    <div
                        id="select-transformer"
                        onContextMenu={(evt) => {
                            evt.preventDefault();
                            this.props.onContextMenu(evt);
                        }}
                        style={{
                            pointerEvents:
                                this.props.selectedMetadata.length === 1
                                    ? "none"
                                    : "auto",
                            background: "transparent",
                            outline: this.state.drag
                                ? "2px solid #39F"
                                : "none",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width:
                                this.props.selectionBounds.right -
                                this.props.selectionBounds.left,
                            height:
                                this.props.selectionBounds.bottom -
                                this.props.selectionBounds.top,
                            zIndex:
                                this.props.selectedMetadata.length === 1
                                    ? undefined
                                    : outlineZIndex,
                        }}
                    />
                </DraggableWithSnapping>
                {/*HACK TO AVOID SHOWING SELECTION OUTLINE FOR SHAPES*/}
                {(this.props.selectedMetadata.length !== 1 ||
                    (this.props.selectedMetadata[0].type !==
                        "shapeElementsState" &&
                        this.props.selectedMetadata[0].type !==
                            "backgroundsState")) && (
                    <div
                        id="selection-outline"
                        style={{
                            background: "transparent",
                            pointerEvents: "none",
                            outline: "2px solid #39F",
                            zIndex: outlineZIndex,
                            position: "absolute",
                            left: this.props.selectionBounds.left,
                            top: this.props.selectionBounds.top,
                            width:
                                this.props.selectionBounds.right -
                                this.props.selectionBounds.left,
                            height:
                                this.props.selectionBounds.bottom -
                                this.props.selectionBounds.top,
                        }}
                    />
                )}
            </>
        );
    }
}
