import React from "react";
import { ChatbotActions } from "./ChatbotTypes";

interface Props {
    actions: ChatbotActions;
    children: JSX.Element[];
}

const MessageParser = ({ children, actions }: Props) => {
    const parse = (message: string): void => {
        actions.handleMessage(message);
    };

    return React.Children.map(children, (child) => {
        return React.cloneElement(child, {
            parse: parse,
            actions,
        });
    });
};

export default MessageParser;
