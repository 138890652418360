import React from "react";
import styles from "./Input.module.css";
import StringUtils from "common/utilities/StringUtils";

function Input(props: {
    value: string | number;
    onChange: (value: string | number) => void;
    step?: number;
    maxValue?: number;
    decimals?: number;
    type?: string;
}) {
    const { maxValue } = props;
    let [value, setValue] = React.useState(props.value);

    return (
        <div className="my-row">
            <input
                onKeyDown={(evt) => {
                    if((evt.key === "e" || 
                        evt.key === "E" ||
                        evt.key === "+" || 
                        evt.key === "-" || 
                        evt.key === ".") && props.type === "number")
                        evt.preventDefault();
                    evt.stopPropagation();
                    if (evt.key === "Enter") {
                        props.onChange(value);
                    }
                }}
                style={{ padding: "0 0 0 10px" }}
                className={styles.input}
                value={value}
                type={(props.type?? "text")}
                onChange={(evt) => {
                    let newValue: string | number = evt.target.value;
                    if (maxValue && StringUtils.isNumber(newValue))
                        newValue = Math.min(maxValue, Number(newValue));
                    setValue(newValue);
                }}
                onBlur={() => {
                    props.onChange(value);
                }}
            ></input>
        </div>
    );
}

export default Input;
