import React, { Component } from "react";
import UserInfo from "common/UserInfo";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { UserIcon } from "common/UserIcon";

export function getUserNames(mention: string, users: UserInfo[]): string[] {
	let mentions = mention.split(" ");
	let result = [];
	for (let mention of mentions) {
		if (mention.startsWith("@")) {
			let userName = mention.slice(1);
			let user = users.find((user) => user.user_name === userName);
			if (user != null) result.push(userName);
		}
	}
	return result;
}

interface Props {
	users: UserInfo[];
	value: string;
	onChange: (value: string) => void;
}

interface DropdownProps {
	prefix: string;
	editAreaRef: any;
	users: UserInfo[];
	onMentionUser: (user: UserInfo) => void;
}

interface MentionState {
	prefixStart: number | null;
	prefixEnd: number | null;
	showMentionsDropdown: boolean;
}

export default class MentionComponent extends Component<Props, MentionState> {
	childRef = React.createRef<any>();
	constructor(props: Props) {
		super(props);
		this.state = {
			prefixStart: null,
			prefixEnd: null,
			showMentionsDropdown: false,
		};
	}
	public focus() {
		if (this.childRef.current) {
			this.childRef.current.focus({ preventScroll: true });
			this.childRef.current.selectionStart = this.childRef.current.selectionEnd = this.childRef.current.value.length;
		}
	}
	render() {
		const childElement = React.Children.only(this.props.children);

		return (
			<div
				style={{ position: "relative" }}
				onKeyUp={(evt) => {
					let currentIndex = (evt.target as HTMLTextAreaElement)
						.selectionStart!;
					if (
						evt.key === "@" ||
						this.props.value[currentIndex - 1] === "@"
					) {
						this.setState({
							prefixStart: currentIndex,
							prefixEnd: currentIndex,
							showMentionsDropdown: true,
						});
					}
					if (this.state.showMentionsDropdown) {
						let currentEnd = (evt.target as HTMLTextAreaElement)
							.selectionStart!;
						if (currentEnd < this.state.prefixStart!) {
							this.setState({ showMentionsDropdown: false });
						} else {
							this.setState({ prefixEnd: currentEnd });
						}
					}
				}}
				onMouseDown={(evt) => {
					let currentIndex = (evt.target as HTMLTextAreaElement)
						.selectionStart!;
					evt.stopPropagation();
					this.setState((state) => {
						if (this.props.value[currentIndex - 1] === "@")
							return {
								...state,
								prefixStart: currentIndex,
								prefixEnd: currentIndex,
								showMentionsDropdown: true,
							};
						return {};
					});
				}}
				onBlur={(evt) => {
					if (evt.target.getAttribute("preventBlur") !== "true") {
						this.setState({ showMentionsDropdown: false });
					} else {
						evt.target.removeAttribute("preventBlur");
					}
				}}
			>
				{React.cloneElement(childElement as any, {
					ref: this.childRef,
				})}
				{this.state.showMentionsDropdown && (
					<MentionsDropdown
						editAreaRef={this.childRef.current}
						prefix={this.props.value.slice(
							this.state.prefixStart!,
							this.state.prefixEnd!
						)}
						users={this.props.users}
						onMentionUser={(user) => {
							this.setState({ showMentionsDropdown: false });

							let newValue =
								this.props.value.slice(
									0,
									this.state.prefixStart!
								) + user.user_name.concat(" ");
							let cursorPosition = newValue.length;
							newValue += this.props.value.slice(
								this.state.prefixEnd!
							);
							this.props.onChange(newValue);
							setTimeout(() => {
								this.childRef.current?.focus({
									preventScroll: true,
								});
								this.childRef.current?.setSelectionRange(
									cursorPosition,
									cursorPosition
								);
							}, 0);
						}}
					/>
				)}
			</div>
		);
	}
}
function MentionsDropdown(props: DropdownProps) {
	return (
		<div style={{ position: "absolute", width: "100%", zIndex: 999 }}>
			<ListGroup>
				{props.users
					.filter((user) => user.user_name.startsWith(props.prefix))
					.map((user, index) => (
						<ListGroupItem key={index} style={{ padding: 0 }}>
							<div
								className="my-row hover-div"
								style={{
									padding: "10px 15px",
									width: "100%",
									height: "100%",
									alignItems: "center",
									cursor: "pointer",
								}}
								onClick={() => {
									props.onMentionUser(user);
								}}
								onMouseDown={(evt) => {
									evt.stopPropagation();
									props.editAreaRef?.setAttribute(
										"preventBlur",
										"true"
									);
								}}
							>
								<UserIcon
									width={25}
									height={25}
									fontSize={8}
									user={user}
								/>

								<span
									style={{
										marginLeft: 20,
										color: "black",
										fontSize: "11px",
										fontWeight: 400,
										fontFamily: "Roboto",
									}}
									className="unselectable"
								>
									{user.user_name}
								</span>
							</div>
						</ListGroupItem>
					))}
			</ListGroup>
		</div>
	);
}