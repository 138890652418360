import { makeAutoObservable } from "mobx";

interface OllamaApiResponse {
    model: string;
    created_at: string;
    response: string;
    text: string;
    vid: string;
    pic: string;
    done: boolean;
  }

class Store {
    userInput = "";
    prompts: string[] = [""];
    answers: string[] = [
        '## Atomic Habits Notes \n \n  You are only half-way through the book... \n * You have last read Chapter 8 "The Secret to Self-Control." \n * Next-up you have "How to Make a Habit Irresistable."  \n \n  Do you want me to summarize it for you?'
    ];
    textDisplay = "";
    picURL = "";
    vidURL = "";

    constructor() {
        makeAutoObservable(this);
    }
    
    setUserInput(value: string) {
        this.userInput = value;
    }
    
    addPrompt(prompt: string) {
        this.prompts.push(prompt);
    }

    setAnswers(newAnswer: string) {
      this.answers = [newAnswer];
    }
    
    addAnswer(answer: string) {
    this.answers.push(answer);
    }

    setText(text: string) {
    this.textDisplay = text;
    }

    setPic(pic: string) {
    this.picURL = pic;
    }

    setVid(vid: string) {
    this.vidURL = vid;
    }

    async sendMessageToFlask(msg: string): Promise<OllamaApiResponse> {
        const requestBody = {
          prompt: msg
        };
    
        const apiUrl = process.env.REACT_APP_API_URL;
        console.log('API URL:', apiUrl);
    
        try {
          const response = await fetch(`${apiUrl}/langgrapho_gen`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
          });
    
          if (response.ok) {
            const result: OllamaApiResponse = await response.json();
            console.log('Server response:', result);
            return result;
          } else {
            console.error('Server error:', response.status, response.statusText);
            return {
              model: '',
              created_at: '',
              response: 'Error occurred',
              text: '',
              vid: 'nan',
              pic: 'nan',
              done: false,
            };
          }
        } catch (error) {
          console.error('Network error:', error);
          return {
            model: '',
            created_at: '',
            response: "I'm not connected to the internet -- I can't answer your question at this point...",
            text: '',
            vid: '',
            pic: '',
            done: false,
          };
        }
    }
}

export const store = new Store();