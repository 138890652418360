import React, { Component } from "react";
import { observer } from "mobx-react";
import styles from "./SlideNumber.module.css";
import cx from "classnames";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import CurrentModulesStore from "common/CurrentModulesStore";
import { ItemMetadata, SlideNumberOptions } from "common/Canvas";
import { SharedModule } from "common/Module";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import {
    changeElementWhenResize2,
    getNewSizeAfterResize2,
} from "../BaseCanvasResizableFunctions";
import Canvases from "common/Canvases";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import { addBackwardCompatibilityForPositionAndSize } from "common/Canvas";
import {
    defaultMobileSlideHeight,
    defaultMobileSlideWidth,
} from "../Constants";

const type = "slideNumber";

interface SlideNumberProps {
    currentPageId: number;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId: number | undefined;
    currentSlideId: number;
    customSlideNumber?: number;
    sharedModule?: SharedModule;
    frozen: boolean;
    live: boolean;
    scale: number;
    onMoveGroupSelection: (
        deltaX: number,
        deltaY: number,
        metadata: ItemMetadata,
        skipHistory?: boolean
    ) => void;
    onResize: () => void;
}

interface State {}

const SlideNumber = observer(
    class SlideNumber extends Component<SlideNumberProps, State> {
        private performance: Date | null;
        private drag: boolean = false;

        constructor(props: SlideNumberProps) {
            super(props);

            this.performance = null;
        }

        render(): JSX.Element | null {
            const { canvasViewMode } = this.props.canvasTreeStore;
            let slideNumber = this.props.customSlideNumber;
            if (!slideNumber) {
                if (this.props.sharedModule == null) {
                    slideNumber =
                        PagesStore(PageType.Canvases)
                            .pages.map((page) =>
                                Canvases(page.id).canvases.filter(
                                    (canvas) => !canvas.hide_in_slideshow
                                )
                            )
                            .flat()
                            .findIndex(
                                (item) => item.id === this.props.currentSlideId
                            ) + 1;
                } else {
                    slideNumber =
                        this.props.sharedModule.pages
                            .map((page) =>
                                page.sheets.filter(
                                    (sheet) => !sheet.hide_in_slideshow
                                )
                            )
                            .flat()
                            .findIndex(
                                (sheet) =>
                                    sheet.id === this.props.currentSlideId
                            ) + 1;
                }
            }
            if (slideNumber === 0) return null;
            let slideNumberOptions: SlideNumberOptions | undefined;
            if (this.props.sharedModule == null) {
                if (this.props.currentModuleId != null) {
                    let currentModule = CurrentModulesStore.getModule(
                        this.props.currentModuleId
                    );
                    slideNumberOptions =
                        currentModule?.options?.slideNumberOptions;
                }
            } else
                slideNumberOptions = this.props.sharedModule.options
                    ?.slideNumberOptions;

            slideNumberOptions = addBackwardCompatibilityForPositionAndSize(
                slideNumberOptions
            );
            if (
                slideNumberOptions!.nodePosition["mobile"].x ===
                slideNumberOptions!.nodePosition["desktop"].x
            ) {
                slideNumberOptions!.nodePosition["mobile"] = {
                    x: defaultMobileSlideWidth - 40,
                    y: defaultMobileSlideHeight - 25,
                };
            }
            if (slideNumberOptions == null) return null;

            let nonScaledSize = {
                ...slideNumberOptions.nodeSize[canvasViewMode],
            };
            let scaledSize = {
                width: nonScaledSize.width * this.props.scale,
                height: nonScaledSize.height * this.props.scale,
            };

            return (
                <Draggable
                    disabled={this.props.frozen}
                    cancel="div[draggable='true']"
                    position={{
                        x:
                            slideNumberOptions.nodePosition[canvasViewMode].x *
                            this.props.scale,
                        y:
                            slideNumberOptions.nodePosition[canvasViewMode].y *
                            this.props.scale,
                    }}
                    onDrag={(_evt, _data) => {
                        this.drag = true;
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            let x = data.x / this.props.scale;
                            let y = data.y / this.props.scale;
                            let deltaX =
                                x -
                                slideNumberOptions!.nodePosition[canvasViewMode]
                                    .x;
                            let deltaY =
                                y -
                                slideNumberOptions!.nodePosition[canvasViewMode]
                                    .y;
                            if (this.props.currentModuleId != null) {
                                CurrentModulesStore.updateModuleOptions(
                                    this.props.currentModuleId,
                                    {
                                        slideNumberOptions: {
                                            ...slideNumberOptions!,
                                            nodePosition: {
                                                ...slideNumberOptions!.nodePosition,
                                                [canvasViewMode]: {
                                                    x: x,
                                                    y: y,
                                                },
                                            },
                                        },
                                    }
                                );
                            }
                            this.props.onMoveGroupSelection(
                                deltaX,
                                deltaY,
                                {
                                    id: type,
                                    type: type,
                                    groupId: null,
                                },
                                false
                            );
                        }
                    }}
                >
                    <Resizable
                        className="selectable-by-pointer"
                        ref={(ref) => {
                            let innerRef = ref?.resizable;
                            if (innerRef != null) {
                                innerRef.setAttribute("type", type);
                                innerRef.setAttribute("id", type);
                            }
                        }}
                        enable={
                            this.props.frozen
                                ? {
                                      top: false,
                                      right: false,
                                      bottom: false,
                                      left: false,
                                      topRight: false,
                                      bottomRight: false,
                                      bottomLeft: false,
                                      topLeft: false,
                                  }
                                : {
                                      top: true,
                                      right: true,
                                      bottom: true,
                                      left: true,
                                      topRight: true,
                                      bottomRight: true,
                                      bottomLeft: true,
                                      topLeft: true,
                                  }
                        }
                        onResizeStart={(evt) => {
                            evt.stopPropagation();
                        }}
                        onResize={(_e, _direction, _ref, d) => {
                            changeElementWhenResize2(
                                {
                                    position: slideNumberOptions!.nodePosition,
                                    size: slideNumberOptions!.nodeSize,
                                },
                                this.props.scale,
                                _direction,
                                d,
                                _ref,
                                canvasViewMode
                            );
                        }}
                        onResizeStop={(_e, _direction, _ref, d) => {
                            let newSize = getNewSizeAfterResize2(
                                {
                                    position: slideNumberOptions!.nodePosition,
                                    size: slideNumberOptions!.nodeSize,
                                },
                                this.props.scale,
                                _direction,
                                d,
                                canvasViewMode
                            );
                            if (this.props.currentModuleId != null) {
                                CurrentModulesStore.updateModuleOptions(
                                    this.props.currentModuleId,
                                    {
                                        slideNumberOptions: {
                                            ...slideNumberOptions!,
                                            nodeSize: newSize.nodeSize,
                                        },
                                    }
                                );
                            }
                            this.props.onResize();
                        }}
                        size={scaledSize}
                        style={{ zIndex: 990, position: "absolute" }}
                    >
                        <div
                            style={{
                                cursor: "pointer",
                                width: `calc(100% / ${this.props.scale})`,
                                height: `calc(100% / ${this.props.scale})`,
                                transformOrigin: "left top",
                                transform: `scale(${this.props.scale})`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <span
                                className={cx(styles.textStyle, "unselectable")}
                                style={{
                                    color: slideNumberOptions.fontColor,
                                    fontSize: slideNumberOptions.fontSize,
                                }}
                            >
                                {slideNumber}
                            </span>
                        </div>
                    </Resizable>
                </Draggable>
            );
        }
    }
);

export default SlideNumber;
