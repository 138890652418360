import StringOption from "common/StringOption";

export interface RuleCondition {
    operation: StringOption | null;
    value: string | number | null;
    logical: StringOption | null;
    leftBracket: StringOption | null;
    rightBracket: StringOption | null;
}

const comparisonFunctions: Readonly<{
    [key: string]: (
        value1: string | number,
        value2: string | number
    ) => boolean;
}> = {
    // We have to use == and != here instead of === and !== since it should not
    // differentiate strings and numbers
    /*jslint eqeq: true*/
    "=": (value1, value2) => value1 == value2, // eslint-disable-line eqeqeq
    "!=": (value1, value2) => value1 != value2, // eslint-disable-line eqeqeq
    "<": (value1, value2) => value1 < value2,
    "<=": (value1, value2) => value1 <= value2,
    ">": (value1, value2) => value1 > value2,
    ">=": (value1, value2) => value1 >= value2,
};

function evaluateRuleCondition(
    ruleCondition: RuleCondition,
    value: string | number
): boolean {
    if (
        ruleCondition.operation?.value == null ||
        comparisonFunctions[ruleCondition.operation.value] == null
    ) {
        return false;
    } else {
        let f = comparisonFunctions[ruleCondition.operation.value];
        return f(value, ruleCondition.value ?? "");
    }
}

function checkBrackets(ruleConditions: RuleCondition[]): boolean {
    let level = 0;
    for (let ruleCondition of ruleConditions) {
        if (ruleCondition.leftBracket != null) {
            level += 1;
        }
        if (ruleCondition.rightBracket != null) {
            level -= 1;
        }
        if (level < 0) {
            return false;
        }
    }
    return level === 0;
}

function evaluateBooleanOperation(
    value1: boolean | null,
    logical: string | undefined | null,
    value2: boolean | null
): boolean | null {
    if (value1 == null || logical == null) {
        return value2;
    } else if (logical === "and") {
        return value1 && value2;
    } else {
        return value1 || value2;
    }
}

export function evaluateRuleConditions(
    ruleConditions: RuleCondition[],
    value: string | number
): boolean {
    if (!checkBrackets(ruleConditions)) {
        throw new Error("Unbalanced brackets");
    }

    let index: number = 0;
    let currentValue: boolean | null = null;

    let stack: {
        previousValue: boolean | null;
        logical: string | undefined | null;
    }[] = [];

    while (index < ruleConditions.length) {
        const ruleCondition = ruleConditions[index];
        // Brackets are disabled
        const leftBracket: StringOption | null = null;
        const rightBracket: StringOption | null = null;
        if (ruleCondition.logical?.value === "and" && !currentValue) {
            if (leftBracket != null) {
                // skip current condition
                index += 1;
                // skip until the next right bracket
                while (rightBracket == null) {
                    index += 1;
                }
            } else if (stack.length === 0) {
                return false;
            } else {
                let stackEntry = stack.pop()!;
                currentValue = evaluateBooleanOperation(
                    stackEntry.previousValue,
                    stackEntry.logical,
                    false
                );
                // skip current condition
                index += 1;
                // skip until the next right bracket
                while (rightBracket == null) {
                    index += 1;
                }
            }
        } else if (ruleCondition.logical?.value === "or" && currentValue) {
            if (leftBracket != null) {
                // skip current condition
                index += 1;
                // skip until the next right bracket
                while (rightBracket == null) {
                    index += 1;
                }
            } else if (stack.length === 0) {
                return true;
            } else {
                let stackEntry = stack.pop()!;
                currentValue = evaluateBooleanOperation(
                    stackEntry.previousValue,
                    stackEntry.logical,
                    true
                );
                // skip current condition
                index += 1;
                // skip until the next right bracket
                while (rightBracket == null) {
                    index += 1;
                }
            }
        } else {
            if (leftBracket != null) {
                stack.push({
                    previousValue: currentValue,
                    logical: ruleCondition.logical?.value,
                });
                currentValue = null;
            }

            currentValue = evaluateBooleanOperation(
                currentValue,
                ruleCondition.logical?.value,
                evaluateRuleCondition(ruleCondition, value)
            );

            if (rightBracket != null) {
                let stackEntry = stack.pop()!;
                currentValue = evaluateBooleanOperation(
                    stackEntry.previousValue,
                    stackEntry.logical,
                    currentValue
                );
            }
            index += 1;
        }
    }

    return currentValue ?? false;
}
