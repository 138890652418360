import React, { Component } from "react";
import { Button } from "react-bootstrap";

import Instrumentation from "common/Instrumentation";
import {
    createPremiumPlan,
    editPremiumPlan,
    ExtendedPremiumPlan,
    getPremiumPlansAll,
    listUnlistPremiumPlan,
    NewPremiumPlan,
    PremiumPlanRequest,
} from "common/PremiumPlansApi";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import FieldEditor, { Field } from "modules/settings_page/common/FieldEditor";
import UpdateStatus from "modules/settings_page/common/UpdateStatus";
import StringUtils from "common/utilities/StringUtils";

interface State {
    newPlan: Field[];
    sendingUpdate: boolean;
    allPlans: ExtendedPremiumPlan[];
    status?: {
        message: string;
        status: PopupStatus;
    };
}
const defaultValue: Field[] = [
    {
        name: "name",
        label: "Name",
        value: "",
        editable: true,
        hidden: false,
    },
    {
        name: "description",
        label: "Description",
        value: "",
        editable: true,
        hidden: false,
    },
    {
        name: "features",
        label: "Features (comma-separated)",
        value: "",
        editable: true,
        hidden: false,
    },
    {
        name: "price",
        label: "Price $",
        value: "0",
        editable: true,
        hidden: false,
    },
    {
        name: "payment_type",
        label: "Payment type",
        value: "monthly",
        select: true,
        options: ["monthly", "yearly"],
    },
];

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            sendingUpdate: false,
            newPlan: defaultValue,
            allPlans: [],
        };
        this.performance = null;
    }

    componentDidMount() {
        this.getPlans();
    }

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    private createNewPlan(): void {
        this.setState({
            sendingUpdate: true,
            status: {
                status: PopupStatus.Success,
                message: "Creating a new plan",
            },
        });
        if (!StringUtils.isNumber(this.state.newPlan[3].value)) {
            this.setState({
                sendingUpdate: false,
                status: {
                    status: PopupStatus.Error,
                    message: "Price should be a number",
                },
            });
            return;
        }
        let newPlan: NewPremiumPlan = {
            name: this.state.newPlan[0].value,
            description: this.state.newPlan[1].value,
            features: this.state.newPlan[2].value
                .split(",")
                .map((item) => item.trim()),
            price: Number(this.state.newPlan[3].value),
            payment_type: this.state.newPlan[4].value as "monthly" | "yearly",
        };
        createPremiumPlan(newPlan)
            .then(() => {
                this.setState(
                    {
                        sendingUpdate: false,
                        newPlan: defaultValue,
                        status: {
                            status: PopupStatus.Success,
                            message: "New plan is created successfully",
                        },
                    },
                    () => {
                        this.getPlans();
                    }
                );
            })
            .catch((error) => {
                this.setState({
                    sendingUpdate: false,
                    status: {
                        status: PopupStatus.Error,
                        message: String(error),
                    },
                });
            });
    }

    private updatePlan(plan: ExtendedPremiumPlan): void {
        this.setState({
            sendingUpdate: true,
            status: {
                status: PopupStatus.Success,
                message: "Editing plan",
            },
        });
        let newPlan: PremiumPlanRequest = {
            plan_id: plan.id,
            name: plan.name,
            description: plan.description,
            features: JSON.stringify(plan.features.map((item) => item.trim())),
        };
        editPremiumPlan(newPlan)
            .then(() => {
                this.setState(
                    {
                        sendingUpdate: false,
                        newPlan: defaultValue,
                        status: {
                            status: PopupStatus.Success,
                            message: "Plan updated successfully",
                        },
                    },
                    () => {
                        this.getPlans();
                    }
                );
            })
            .catch((error) => {
                this.setState({
                    sendingUpdate: false,
                    status: {
                        status: PopupStatus.Error,
                        message: String(error),
                    },
                });
            });
    }

    private showHidePlan(plan: ExtendedPremiumPlan): void {
        this.setState({
            sendingUpdate: true,
            status: {
                status: PopupStatus.Success,
                message: `${plan.unlisted ? "Show" : "Hide"} plan`,
            },
        });
        listUnlistPremiumPlan(plan.id, !plan.unlisted)
            .then(() => {
                this.setState(
                    {
                        sendingUpdate: false,
                        newPlan: defaultValue,
                        status: {
                            status: PopupStatus.Success,
                            message: "Plan updated successfully",
                        },
                    },
                    () => {
                        this.getPlans();
                    }
                );
            })
            .catch((error) => {
                this.setState({
                    sendingUpdate: false,
                    status: {
                        status: PopupStatus.Error,
                        message: String(error),
                    },
                });
            });
    }

    private getPlans(): void {
        getPremiumPlansAll()
            .then((plans) => {
                this.setState({ allPlans: plans });
            })
            .catch((error) => {
                this.setState({
                    status: {
                        status: PopupStatus.Error,
                        message: String(error),
                    },
                });
            });
    }

    private buildPlansSection(): JSX.Element {
        return (
            <div style={{ marginTop: 20, overflow: "auto" }}>
                <span className="big-title-span">{"Premium plans"}</span>

                <div style={{ marginTop: 10 }} className="flex-simple-column">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                        }}
                    >
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Name"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "165px",
                            }}
                        >
                            {"Description"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "165px",
                            }}
                        >
                            {"Features"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "100px",
                            }}
                        >
                            {"Price"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Payment Type"}
                        </div>
                    </div>
                    {this.state.allPlans.map((plan, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingBottom: "10px",
                                    opacity: plan.unlisted ? 0.5 : 1,
                                    background: plan.unlisted
                                        ? "#CCCCCC55"
                                        : undefined,
                                }}
                            >
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let plans = Array.from(
                                                state.allPlans
                                            );
                                            plans[index] = {
                                                ...plans[index],
                                                name: value,
                                            };
                                            return { allPlans: plans };
                                        });
                                    }}
                                    value={plan.name}
                                />
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "165px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let plans = Array.from(
                                                state.allPlans
                                            );
                                            plans[index] = {
                                                ...plans[index],
                                                description: value,
                                            };
                                            return { allPlans: plans };
                                        });
                                    }}
                                    value={plan.description}
                                />
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "165px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let plans = Array.from(
                                                state.allPlans
                                            );
                                            plans[index] = {
                                                ...plans[index],
                                                features: value.split(","),
                                            };
                                            return { allPlans: plans };
                                        });
                                    }}
                                    value={plan.features.join(",")}
                                />
                                <div
                                    className="regular-text"
                                    style={{
                                        marginLeft: "20px",

                                        minWidth: "100px",
                                    }}
                                >
                                    {plan.price}
                                </div>
                                <div
                                    className="regular-text"
                                    style={{
                                        marginLeft: "20px",

                                        minWidth: "140px",
                                    }}
                                >
                                    {plan.payment_type}
                                </div>

                                <div
                                    style={{
                                        marginTop: "10px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <Button
                                        type="button"
                                        disabled={this.state.sendingUpdate}
                                        className="btn btn-sm btn-primary my-primary"
                                        onClick={() => {
                                            this.performance = new Date();
                                            this.updatePlan(plan);
                                        }}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{ marginLeft: "5px" }}
                                        onClick={() => {
                                            this.performance = new Date();
                                            this.showHidePlan(plan);
                                        }}
                                    >
                                        {plan.unlisted ? "Show" : "Hide"}
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <FieldEditor
                    buttonTitle="Create"
                    updateButtonDisabled={this.state.sendingUpdate}
                    title="Create New Plan"
                    value={this.state.newPlan}
                    onChange={(value) => this.setState({ newPlan: value })}
                    onUpdate={() => this.createNewPlan()}
                    status={UpdateStatus.NotUploaded}
                    onStatusChange={() => {}}
                    errorMessage=""
                />
                {this.buildPlansSection()}
                {this.state.status != null && (
                    <StatusPopup
                        status={this.state.status.status}
                        message={this.state.status.message}
                        onClose={() => {
                            this.setState({ status: undefined });
                        }}
                    />
                )}
            </>
        );
    }
}

export { MainComponent };
export let requirePermission = "ManagePremiumPlans";
