import React from "react";
import { defaultCustomFields, registrationFields, SelfSignupInfo } from "common/ModulesApi";
import { Button } from "react-bootstrap";
import ColorPicker from "common/ColorPicker";
import styles from "./LinkCustomizePopup.module.css";
import cx from "classnames";
import _ from "lodash";
import OutsideAlerter from "common/OutsideAlerter";
import "pretty-checkbox/dist/pretty-checkbox.min.css";

function Checkbox(props: {
    value: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
}) {
    return (
        <div
            className="pretty p-default"
            contentEditable={false}
            style={{ marginRight: 0, marginTop: -4 }}
        >
            <input
                disabled={props.disabled}
                type="checkbox"
                checked={props.value}
                onChange={() => {
                    props.onChange(!props.value);
                }}
            />
            <div className="state p-primary">
                <label></label>
            </div>
        </div>
    );
}
interface Props {
    selfSignupInfo: SelfSignupInfo | null;
    onApply: (selfSignupInfo: SelfSignupInfo) => void;
    onClose: () => void;
}

function ColorInput(props: {
    name: string;
    clearable?: boolean;
    isGradient?: boolean;
    value: string | null | undefined;
    onChange: (value: string | undefined) => void;
}) {
    return (
        <div
            className="my-row"
            style={{
                marginTop: "10px",
                alignItems: "center",
            }}
        >
            <span className={cx("unselectable", styles.optionName)}>
                {props.name}
            </span>
            <ColorPicker
                isGradient={props.isGradient}
                value={props.value ?? ""}
                onChange={(newValue) => {
                    props.onChange(newValue);
                }}
            />
            {!props.value && (
                <span
                    style={{ marginLeft: "5px", width: 100 }}
                    className={cx("unselectable", styles.optionName)}
                >
                    {"(Default)"}
                </span>
            )}
            {props.clearable && (
                <Button
                    style={{ marginLeft: "5px" }}
                    className="close"
                    onClick={() => {
                        props.onChange(undefined);
                    }}
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </Button>
            )}
        </div>
    );
}

export default function LinkCustomizePopup(props: Props) {
    let [selfSignupInfo, setSelfSignupInfo] = React.useState<SelfSignupInfo>(
        _.cloneDeep(props.selfSignupInfo) ?? {
            logo: "",
            options: {},
        }
    );
    return (
        <OutsideAlerter onReject={props.onClose}>
            <div className={cx("dashboard-rect", styles.root)}>
                <div
                    className="flex-simple-column"
                    style={{
                        width: "100%",
                        flexGrow: 1,
                    }}
                >
                    <div
                        className="my-row"
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                            paddingRight: 8,
                            alignItems: "center",
                            paddingTop: 9,
                        }}
                    >
                        <span
                            style={{
                                marginLeft: 40,
                            }}
                            className={cx("unselectable", styles.title)}
                        >
                            Customize self-signup page
                        </span>
                    </div>
                    <div
                        className="flex-simple-column"
                        style={{
                            width: "100%",
                            marginTop: 20,
                            paddingLeft: 40,
                            paddingRight: 40,
                            flexGrow: 1,
                        }}
                    >
                        <ColorInput
                            clearable
                            name="Logo color"
                            value={selfSignupInfo?.options?.logoColor}
                            onChange={(value) => {
                                setSelfSignupInfo({
                                    ...selfSignupInfo,
                                    options: {
                                        ...selfSignupInfo.options!,
                                        logoColor: value,
                                    },
                                });
                            }}
                        />
                        <ColorInput
                            clearable
                            name="Background Color"
                            value={selfSignupInfo?.options?.backgroundColor}
                            onChange={(value) => {
                                setSelfSignupInfo({
                                    ...selfSignupInfo,
                                    options: {
                                        ...selfSignupInfo.options!,
                                        backgroundColor: value,
                                    },
                                });
                            }}
                        />
                        <ColorInput
                            clearable
                            isGradient
                            name="Background Gradient"
                            value={selfSignupInfo?.options?.backgroundColor}
                            onChange={(value) => {
                                setSelfSignupInfo({
                                    ...selfSignupInfo,
                                    options: {
                                        ...selfSignupInfo.options!,
                                        backgroundColor: value,
                                    },
                                });
                            }}
                        />
                        <ColorInput
                            clearable
                            name="SignUp Button color"
                            value={selfSignupInfo?.options?.createButtonColor}
                            onChange={(value) => {
                                setSelfSignupInfo({
                                    ...selfSignupInfo,
                                    options: {
                                        ...selfSignupInfo.options!,
                                        createButtonColor: value,
                                    },
                                });
                            }}
                        />
                        {/* <ColorInput
                            name="Text Color"
                            value={selfSignupInfo?.options?.textColor}
                            onChange={(value) => {
                                setSelfSignupInfo({
                                    ...selfSignupInfo,
                                    options: {
                                        ...selfSignupInfo.options!,
                                        textColor: value,
                                    },
                                });
                            }}
                        />
                        <ColorInput
                            name="Input Background Color"
                            value={selfSignupInfo?.options?.inputFieldColor}
                            onChange={(value) => {
                                setSelfSignupInfo({
                                    ...selfSignupInfo,
                                    options: {
                                        ...selfSignupInfo.options!,
                                        inputFieldColor: value,
                                    },
                                });
                            }}
                        />
                        <ColorInput
                            name="Input Text Color"
                            value={selfSignupInfo?.options?.inputFieldTextColor}
                            onChange={(value) => {
                                setSelfSignupInfo({
                                    ...selfSignupInfo,
                                    options: {
                                        ...selfSignupInfo.options!,
                                        inputFieldTextColor: value,
                                    },
                                });
                            }}
                        /> */}
                        <div
                            className="my-row"
                            style={{ marginTop: 10, alignItems: "center" }}
                        >
                            <span
                                className={cx(
                                    "unselectable",
                                    styles.optionName
                                )}
                            >
                                Background image
                            </span>
                            <input
                                className="like-select module-template"
                                placeholder="Background image"
                                value={
                                    selfSignupInfo.options?.backgroundImage ??
                                    ""
                                }
                                onKeyDown={(evt) => evt.stopPropagation()}
                                onChange={(evt) => {
                                    let value = (evt.target as any)?.value;
                                    setSelfSignupInfo({
                                        ...selfSignupInfo,
                                        options: {
                                            ...selfSignupInfo.options!,
                                            backgroundImage: value,
                                        },
                                    });
                                }}
                            />
                        </div>
                        <div
                            className="my-row"
                            style={{ marginTop: 10, alignItems: "center" }}
                        >
                            <span
                                className={cx(
                                    "unselectable",
                                    styles.optionName
                                )}
                            >
                                Register text
                            </span>
                            <input
                                className="like-select module-template"
                                placeholder="Register text"
                                value={
                                    selfSignupInfo.options?.registerText ?? ""
                                }
                                onKeyDown={(evt) => evt.stopPropagation()}
                                onChange={(evt) => {
                                    let value = (evt.target as any)?.value;
                                    setSelfSignupInfo({
                                        ...selfSignupInfo,
                                        options: {
                                            ...selfSignupInfo.options!,
                                            registerText: value,
                                        },
                                    });
                                }}
                            />
                        </div>
                        <div
                            className="my-row"
                            style={{ marginTop: 10, alignItems: "center" }}
                        >
                            <span
                                className={cx(
                                    "unselectable",
                                    styles.optionName
                                )}
                            >
                                Logo
                            </span>
                            <input
                                className="like-select module-template"
                                placeholder="Logo url"
                                value={selfSignupInfo.logo ?? ""}
                                onKeyDown={(evt) => evt.stopPropagation()}
                                onChange={(evt) => {
                                    let value = (evt.target as any)?.value;
                                    setSelfSignupInfo({
                                        ...selfSignupInfo,
                                        logo: value,
                                    });
                                }}
                            />
                        </div>
                        <div
                            className="my-row"
                            style={{
                                marginTop: 10,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                className={cx(
                                    "unselectable",
                                    styles.optionName
                                )}
                            >
                                Registration fields
                            </span>
                        </div>
                        {registrationFields.map((field, index) => {
                            let customFields =
                                selfSignupInfo.options?.customFields ?? defaultCustomFields;
                            let matchedField = customFields.find(
                                (item) => item.name === field.name
                            );

                            return (
                                <div
                                    key={index}
                                    className="my-row"
                                    style={{
                                        marginTop: 10,
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        className={cx(
                                            "unselectable",
                                            styles.optionName
                                        )}
                                    >
                                        {field.label}
                                    </span>
                                    <Checkbox
                                        value={matchedField != null}
                                        onChange={(value) => {
                                            if (value) {
                                                customFields.push({
                                                    ...field,
                                                    required: false,
                                                });
                                            } else {
                                                customFields =
                                                    customFields.filter(
                                                        (item) =>
                                                            item.name !==
                                                            field.name
                                                    );
                                            }
                                            setSelfSignupInfo({
                                                ...selfSignupInfo,
                                                options: {
                                                    ...selfSignupInfo.options!,
                                                    customFields: customFields,
                                                },
                                            });
                                        }}
                                    />
                                    <span
                                        className={cx(
                                            "unselectable",
                                            styles.optionName
                                        )}
                                        style={{ marginLeft: "50px" }}
                                    >
                                        {"Required"}
                                    </span>
                                    <Checkbox
                                        disabled={matchedField == null}
                                        value={
                                            matchedField != null &&
                                            matchedField.required
                                        }
                                        onChange={(value) => {
                                            matchedField!.required =
                                                !matchedField!.required;
                                            setSelfSignupInfo({
                                                ...selfSignupInfo,
                                                options: {
                                                    ...selfSignupInfo.options!,
                                                    customFields: customFields,
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            );
                        })}
                        {/* <div className="my-row" style={{ marginTop: 10 }}>
                            <span
                                className={cx(
                                    "unselectable",
                                    styles.optionName
                                )}
                            >
                                Description
                            </span>
                            <TextareaAutosize
                                className="insights-text-area-popup module-template"
                                placeholder="Description"
                                value={
                                    selfSignupInfo.options?.description ?? ""
                                }
                                style={{
                                    padding: 8,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    backgroundColor: mainStyle.getPropertyValue(
                                        "--selectors-background-color"
                                    ),
                                    minHeight: 150,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    marginBottom: 0,
                                }}
                                onChange={(evt) => {
                                    let value = (evt.target as any)?.value;
                                    setSelfSignupInfo({
                                        ...selfSignupInfo,
                                        options: {
                                            ...selfSignupInfo.options!,
                                            description: value,
                                        },
                                    });
                                }}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                            />
                        </div> */}
                        <div style={{ flexGrow: 1 }} />
                        <div
                            style={{
                                marginTop: "13px",
                                height: "1px",
                                backgroundColor: "#242E42",
                                width: "100%",
                            }}
                        />
                        <div
                            className="my-row"
                            style={{
                                marginTop: "15px",
                                marginBottom: "24px",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    color: "#000000",
                                    marginLeft: "27px",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    width: "70px",
                                }}
                                onClick={props.onClose}
                            >
                                CANCEL
                            </Button>
                            <Button
                                type="button"
                                style={{
                                    width: "112px",
                                }}
                                className="btn btn-sm btn-primary my-primary"
                                onClick={() => {
                                    props.onApply(selfSignupInfo);
                                }}
                            >
                                SAVE
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </OutsideAlerter>
    );
}
