import React, { Component, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { toJS } from "mobx";
import { Button } from "react-bootstrap";
import moment from "moment";
import { DropdownMenu, DropdownItem } from "./DropdownMenu";
import {
    ColorOptions,
    QuestionnaireElement,
    Question,
    DateQuestion,
    QuestionType,
    QuestionnaireOutputType,
    TextQuestion,
    MultipleChoiceQuestion,
    DropdownQuestion,
    isTextQuestion,
    isDateQuestion,
    isMultipleChoiceQuestion,
    isDropdownQuestion,
    isTemplateSheetQuestion,
    TemplateSheetQuestion,
    createTemplateNodesCanvas,
    questionToAnswer,
} from "common/Canvas";
import { mainStyle } from "common/MainStyle";
import QuestionHeaderView from "./question_views/QuestionHeaderView";
import TextQuestionView from "./question_views/TextQuestionView";
import MultipleChoiceQuestionView from "./question_views/MultipleChoiceQuestionView";
import DropdownQuestionView from "./question_views/DropdownQuestionView";
import DateQuestionView from "./question_views/DateQuestionView";
import BackendOptionsView from "./question_views/BackendOptionsView";
import TemplateSheetQuestionView from "./question_views/TemplateSheetQuestionView";
import { insertRows } from "common/DataApi";
import { EditCanvasRequest, editCanvasApi } from "common/CanvasUserApi";
import Canvases from "common/Canvases";
import CanvasTreeStore from "./CanvasTreeStore";
import CanvasPreventPropagationButton from "./CanvasPreventPropagationButton";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";

// Added SVG buttons
import UpButtonBig from "icons/UpButtonBig.svg";
import DownButtonBig from "icons/DownButtonBig.svg";

enum OutputSelection {
    NotActive = 1,
    SelectOutput = 2,
    SelectBackendTable = 3,
    SelectSheetTemplate = 4,
}

interface Props {
    canvasTreeStore: CanvasTreeStore;
    onContextMenu: (evt: React.MouseEvent) => void;
    height: number;
    questionnaireElement: QuestionnaireElement;
    live: boolean;
    frozen: boolean;
    questionnaireElementId: string;
    onChange: (questionnaireElement: QuestionnaireElement) => void;
    onDelete: () => void;
    onAddGridRow: (questionnaireElement: QuestionnaireElement) => void;
    onDeleteGrid: () => void;
    onLinkCanvasSheets: (canvasIds: number[]) => void;
    onOpenColorOptions: (colorOptions: ColorOptions) => void;
    currentModuleId: number | undefined;
}
interface State {
    currentQuestion: number;
    animationDirection: string;
    outputSelection: OutputSelection;
    errorMessage: string | null;
    showFinalScreen: boolean;
}

function defaultTemplateSheetQuestion() {
    let templateSheetQuestion: TemplateSheetQuestion = {
        type: QuestionType.TemplateSheet,
        pageOption: null,
        sheetOption: null,
        leftSheetTitle: "",
        titleSheetPlaceholder: null,
        rightSheetTitle: null,
        nodeOptions: {},
        question: "Select or create template sheet",
        liveQuestion: "Enter title for the new sheet",
    };
    return templateSheetQuestion;
}

function getQuestionsLength(
    questionnaireElement: QuestionnaireElement,
    live: boolean
) {
    if (!live) return questionnaireElement.questions.length;
    else
        return questionnaireElement.questions.filter(
            (question) => !isTemplateSheetQuestion(question)
        ).length;
}

function AddQuestionDropdownToggle(props: {
    questionnaireElementId: string;
    open: boolean;
}) {
    return (
        <Dropdown.Toggle
            id={"AddQuestionMenu".concat(props.questionnaireElementId)}
            style={{
                marginLeft: "29px",
                border: 0,
                padding: "0px 0px",
                backgroundColor: "transparent",
            }}
        >
            <div className="my-row" style={{ alignItems: "center" }}>
                <img alt="" src={"/dist/img/canvas/add_question.png"} />
                <span
                    style={{
                        marginLeft: "6px",
                        fontFamily: "Roboto",
                        fontSize: 17,
                        color: props.open ? "#5A7289" : "#1F8EFA",
                        fontWeight: 300,
                    }}
                >
                    {"add question"}
                </span>
            </div>
        </Dropdown.Toggle>
    );
}

function EditQuestionDropdownToggle(props: {
    questionnaireElementId: string;
    index: number;
}) {
    return (
        <Dropdown.Toggle
            id={"EditQuestionMenu"
                .concat(props.questionnaireElementId)
                .concat("_")
                .concat(String(props.index))}
            style={{
                marginLeft: "29px",
                border: 0,
                padding: "0px 0px",
                backgroundColor: "transparent",
            }}
        >
            <svg
                width="12"
                height="15"
                style={{
                    fill: "#70889E",
                    marginLeft: "5px",
                    marginTop: "5px",
                }}
                viewBox="0 0 12 15"
            >
                <circle cx="3" cy="3" r="2" />
                <circle cx="3" cy="8" r="2" />
                <circle cx="3" cy="13" r="2" />
            </svg>
        </Dropdown.Toggle>
    );
}

function EditQuestionDropdown(props: {
    questionnaireElementId: string;
    index: number;
    canDelete: boolean;
    onDuplicate: () => void;
    onDelete: () => void;
}) {
    let [toggleOpen, setToggleOpen] = useState(false);
    return (
        <Dropdown
            show={toggleOpen}
            onToggle={(open: boolean) => {
                setToggleOpen(open);
            }}
        >
            <EditQuestionDropdownToggle
                questionnaireElementId={props.questionnaireElementId}
                index={props.index}
            />
            <DropdownMenu>
                <DropdownItem
                    hoverColor={"#EE423D"}
                    title={"DUPLICATE"}
                    onClick={(_evt) => {
                        setToggleOpen(false);
                        props.onDuplicate();
                    }}
                />
                {props.canDelete && (
                    <DropdownItem
                        hoverColor={"#EE423D"}
                        title={"DELETE QUESTION"}
                        onClick={(_evt) => {
                            setToggleOpen(false);
                            props.onDelete();
                        }}
                    />
                )}
            </DropdownMenu>
        </Dropdown>
    );
}

function Header(props: {
    frozen: boolean;
    canAddQuestions: boolean;
    questionnaireElementId: string;
    onNewQuestion: (question: Question) => void;
    onDeleteElement: () => void;
    onOpenColorOptions: () => void;
}) {
    let [toggleOpen, setToggleOpen] = useState(false);
    return (
        <div
            style={{
                minHeight: "90px",
                width: "100%",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 30,
            }}
        >
            <div className="my-row" style={{ justifyContent: "space-between" }}>
                <div className="my-row" style={{ alignItems: "center" }}>
                    <img
                        alt=""
                        src={"/dist/img/data-exploration/server_purple.png"}
                    />
                    <span
                        style={{
                            marginLeft: 30,
                            fontFamily: "Roboto",
                            fontSize: mainStyle.getPropertyValue(
                                "--path-header-size"
                            ),
                            color: dataScienceElementsStyle.primaryTextColor,
                        }}
                    >
                        {"Creating Questionnaire Element"}
                    </span>
                    {!props.frozen && props.canAddQuestions && (
                        <Dropdown
                            show={toggleOpen}
                            onToggle={(open: boolean) => {
                                setToggleOpen(open);
                            }}
                        >
                            <AddQuestionDropdownToggle
                                questionnaireElementId={
                                    props.questionnaireElementId
                                }
                                open={toggleOpen}
                            />
                            <DropdownMenu>
                                <DropdownItem
                                    hoverColor={"#1F8EFA"}
                                    title={"TEXT ANSWER"}
                                    onClick={(_evt) => {
                                        setToggleOpen(false);
                                        let question: TextQuestion = {
                                            type: QuestionType.Text,
                                            question: "",
                                            answer: "",
                                        };
                                        props.onNewQuestion(question);
                                    }}
                                />
                                <DropdownItem
                                    hoverColor={"#1F8EFA"}
                                    title={"MULTIPLE CHOICE"}
                                    onClick={(_evt) => {
                                        setToggleOpen(false);
                                        let question: MultipleChoiceQuestion = {
                                            type: QuestionType.MultipleChoice,
                                            question: "",
                                            options: [
                                                {
                                                    option: "",
                                                    selected: false,
                                                },
                                            ],
                                        };
                                        props.onNewQuestion(question);
                                    }}
                                />
                                <DropdownItem
                                    hoverColor={"#1F8EFA"}
                                    title={"DROPDOWN"}
                                    onClick={(_evt) => {
                                        setToggleOpen(false);
                                        let question: DropdownQuestion = {
                                            type: QuestionType.Dropdown,
                                            question: "",
                                            tableOption: null,
                                            tableValue: null,
                                            variableOption: null,
                                            dataScopeOption: null,
                                        };
                                        props.onNewQuestion(question);
                                    }}
                                />
                                <DropdownItem
                                    hoverColor={"#1F8EFA"}
                                    title={"DATE INPUT"}
                                    onClick={(_evt) => {
                                        setToggleOpen(false);
                                        let question: DateQuestion = {
                                            type: QuestionType.Date,
                                            question: "",
                                            answer: "",
                                            currentDate: false,
                                        };
                                        props.onNewQuestion(question);
                                    }}
                                />
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </div>
                <div className="my-row" style={{ alignItems: "center" }}>
                    {!props.frozen && (
                        <CanvasPreventPropagationButton>
                            <div
                                onClick={() => {
                                    props.onDeleteElement();
                                }}
                            >
                                <img
                                    alt=""
                                    src="/dist/img/insights/insights_remove.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </CanvasPreventPropagationButton>
                    )}
                    {!props.frozen && (
                        <CanvasPreventPropagationButton>
                            <div
                                title={"Style"}
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                }}
                                onClick={(_evt) => {
                                    props.onOpenColorOptions();
                                }}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    style={{
                                        fill:
                                            dataScienceElementsStyle.secondaryTextColor,
                                    }}
                                    viewBox="0 0 24 24"
                                >
                                    <circle cx="10" cy="6" r="2" />
                                    <circle cx="10" cy="14" r="2" />
                                    <circle cx="10" cy="22" r="2" />
                                </svg>
                            </div>
                        </CanvasPreventPropagationButton>
                    )}
                </div>
            </div>
        </div>
    );
}

export default class QuestionnaireView extends Component<Props, State> {
    rootRef: React.RefObject<HTMLDivElement>;
    constructor(props: Props) {
        super(props);
        this.state = {
            showFinalScreen: false,
            outputSelection: OutputSelection.NotActive,
            currentQuestion: -1,
            animationDirection: "top",
            errorMessage: null,
        };
        this.rootRef = React.createRef();
    }
    componentDidUpdate(prevProps: Props) {
        if (this.props.live && !prevProps.live && this.state.outputSelection) {
            this.setState({
                outputSelection: OutputSelection.NotActive,
            });
        }
        if (!this.props.live && prevProps.live) {
            this.setState({ errorMessage: null });
        }
    }
    getScreenKey() {
        return this.state.outputSelection * 10 + this.state.currentQuestion;
    }
    buildHeader(colorOptions: ColorOptions) {
        if (this.props.live || this.props.questionnaireElement.isDone)
            return <div style={{ minHeight: "90px" }} />;
        return (
            <Header
                onOpenColorOptions={() => {
                    this.props.onOpenColorOptions(colorOptions);
                }}
                frozen={this.props.frozen}
                canAddQuestions={
                    this.state.outputSelection === OutputSelection.NotActive &&
                    (this.state.currentQuestion === -1 ||
                        !isTemplateSheetQuestion(
                            this.props.questionnaireElement.questions[
                                this.state.currentQuestion
                            ]
                        ))
                }
                onNewQuestion={(question: Question) => {
                    let questionnaireElement = Object.assign(
                        {},
                        this.props.questionnaireElement
                    );
                    questionnaireElement.questions.splice(
                        this.state.currentQuestion + 1,
                        0,
                        question
                    );
                    this.props.onChange(questionnaireElement);
                    this.setState((state) => ({
                        currentQuestion: state.currentQuestion + 1,
                    }));
                }}
                onDeleteElement={() => {
                    this.props.onDelete();
                }}
                questionnaireElementId={this.props.questionnaireElementId}
            />
        );
    }
    stepUp() {
        if (this.state.showFinalScreen) return;
        if (this.state.currentQuestion < 0) return;
        this.setState({ animationDirection: "bottom" }, () => {
            this.setState((state) => ({
                currentQuestion: state.currentQuestion - 1,
            }));
        });
    }
    writeOutputToTemplateSheet(questionnaireElement: QuestionnaireElement) {
        let templateSheetQuestions = questionnaireElement.questions.filter(
            (question) =>
                isTemplateSheetQuestion(question) &&
                question.pageOption != null &&
                question.sheetOption != null
        );
        if (templateSheetQuestions.length > 0) {
            let editCanvasRequest: EditCanvasRequest = {
                mergeTemplates: true,
                sheets: templateSheetQuestions.map((question) => {
                    let templateQuestion = question as TemplateSheetQuestion;
                    let pageOption = templateQuestion.pageOption;
                    let pageId = pageOption!.value;
                    let sheetOption = templateQuestion.sheetOption;
                    let titleSheetPlaceholder =
                        templateQuestion.titleSheetPlaceholder;

                    let newCanvas = createTemplateNodesCanvas(
                        questionnaireElement,
                        question as TemplateSheetQuestion,
                        pageId
                    );
                    let titleAnswer = titleSheetPlaceholder
                        ? questionToAnswer(
                              questionnaireElement.questions[
                                  titleSheetPlaceholder.value
                              ]
                          ) ?? ""
                        : "";
                    let mergeTemplatesTitle = `${
                        templateQuestion.leftSheetTitle ?? ""
                    } ${titleAnswer} ${templateQuestion.rightSheetTitle ?? ""}`;
                    return {
                        pageId: pageId,
                        canvasId: sheetOption!.value,
                        canvas: newCanvas,
                        backgrounds: [],
                        thumbnail: null,
                        userGroups: undefined,
                        mergeTemplatesTitle: mergeTemplatesTitle,
                        mergeTemplatesUpdateEditingCanvas:
                            this.props.canvasTreeStore.canvasPageId !== pageId,
                    };
                }),
            };
            editCanvasApi(editCanvasRequest)
                .then((_ids) => {
                    editCanvasRequest.sheets.forEach((sheet) => {
                        Canvases(sheet.pageId).update();
                    });
                    this.setState({ errorMessage: null });
                    if (
                        this.props.questionnaireElement.outputType ===
                        QuestionnaireOutputType.Sheet
                    )
                        this.props.onLinkCanvasSheets(_ids);
                })
                .catch((errorMessage) => {
                    this.setState({ errorMessage: String(errorMessage) });
                    console.log(String(errorMessage));
                });
        }
    }

    sendOutputToBackend(questionnaireElement: QuestionnaireElement) {
        if (
            questionnaireElement.backendOutput != null &&
            questionnaireElement.outputType ===
                QuestionnaireOutputType.BackendTable
        ) {
            if (questionnaireElement.backendOutput.tableOption != null) {
                let row: { [key: string]: string | number | null } = {};
                for (const [key, value] of Object.entries(
                    questionnaireElement.backendOutput.variableOptions
                )) {
                    let question = this.props.questionnaireElement.questions[
                        Number(key)
                    ];
                    if (question == null) {
                        continue;
                    }
                    let answer: string | number | null = null;
                    if (isTextQuestion(question)) {
                        answer = question.answer;
                    }
                    if (isDateQuestion(question)) {
                        answer =
                            (question.currentDate
                                ? new Date()
                                : moment.utc(question.answer).toDate()
                            ).getTime() / 1000;
                        if (question.dateOnly) {
                            answer -= answer % (24 * 60 * 60);
                        }
                    }
                    if (isMultipleChoiceQuestion(question)) {
                        let selected = question.options
                            .filter((option) => option.selected)
                            .map((option) => option.option);
                        answer = selected.join(" ");
                    }
                    if (isDropdownQuestion(question)) {
                        answer = String(question.tableValue ?? "");
                    }
                    if (["int", "float", "datetime"].includes(value.type)) {
                        answer = Number(answer);
                    }
                    row[value.label] = answer;
                }
                if (Object.keys(row).length > 0) {
                    insertRows(
                        questionnaireElement.backendOutput.tableOption,
                        [row],
                        remoteModuleId ?? this.props.currentModuleId
                    )
                        .then(() => {
                            this.setState({ errorMessage: null });
                        })
                        .catch((err) => {
                            this.setState({ errorMessage: String(err) });
                            console.log(err);
                        });
                }
            }
        }
    }

    stepDown() {
        if (this.state.showFinalScreen) return;
        if (
            this.state.currentQuestion <
            getQuestionsLength(
                this.props.questionnaireElement,
                this.props.live
            ) -
                1
        )
            this.setState({ animationDirection: "top" }, () => {
                this.setState((state) => ({
                    currentQuestion: state.currentQuestion + 1,
                }));
            });
    }

    buildLeftBar() {
        if (
            this.state.showFinalScreen ||
            this.state.outputSelection >= OutputSelection.SelectOutput ||
            (this.props.questionnaireElement.isDone && !this.props.live)
        ) {
            return (
                <div
                    style={{
                        width: "116px",
                    }}
                ></div>
            );
        }
        let showDownArrow: boolean =
            (this.state.currentQuestion >= 0 &&
                this.state.currentQuestion <
                    getQuestionsLength(
                        this.props.questionnaireElement,
                        this.props.live
                    ) -
                        1) ||
            (this.state.currentQuestion === -1 &&
                getQuestionsLength(
                    this.props.questionnaireElement,
                    this.props.live
                ) > 0);
        return (
            <div
                className="flex-simple-column"
                style={{
                    paddingLeft: 48,
                    width: "116px",
                }}
            >
                {this.state.currentQuestion > -1 && (
                    <CanvasPreventPropagationButton>
                        <div
                            onClick={() => {
                                this.stepUp();
                            }}
                        >
                            <img
                                alt=""
                                src={UpButtonBig} //"/dist/img/canvas/up_big.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </CanvasPreventPropagationButton>
                )}
                <div style={{ flexGrow: 1 }}> </div>
                {showDownArrow && (
                    <CanvasPreventPropagationButton>
                        <div
                            onClick={() => {
                                this.stepDown();
                            }}
                        >
                            <img
                                alt=""
                                src={DownButtonBig}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </CanvasPreventPropagationButton>
                )}
                {this.state.currentQuestion >= 0 &&
                    this.state.outputSelection ===
                        OutputSelection.NotActive && (
                        <span
                            style={{
                                marginLeft: "5px",
                                fontFamily: "Roboto",
                                fontSize: 17,
                                color: "#485B70",
                            }}
                        >
                            {this.state.currentQuestion + 1}
                        </span>
                    )}
            </div>
        );
    }
    goNext(question?: Question) {
        if (question != null) {
            let questionnaireElement = Object.assign(
                {},
                this.props.questionnaireElement
            );
            questionnaireElement.questions[
                this.state.currentQuestion
            ] = question;
            this.props.onChange(questionnaireElement);
        }
        if (
            this.state.currentQuestion <
            getQuestionsLength(
                this.props.questionnaireElement,
                this.props.live
            ) -
                1
        ) {
            this.stepDown();
            this.rootRef.current?.focus({ preventScroll: true });
        } else {
            this.submitAnswers();
        }
    }
    selectSheetTemplateEnabled(enabled: boolean) {
        let questionnaireElement = Object.assign(
            {},
            this.props.questionnaireElement
        );
        questionnaireElement.templateSheetOutputEnabled = enabled;
        if (questionnaireElement.templateSheetOutputEnabled) {
            let hasTemplateQuestion =
                questionnaireElement.questions.find((question) =>
                    isTemplateSheetQuestion(question)
                ) != null;
            if (!hasTemplateQuestion) {
                questionnaireElement.questions.push(
                    defaultTemplateSheetQuestion()
                );
            }
        } else {
            questionnaireElement.questions = questionnaireElement.questions.filter(
                (question) => !isTemplateSheetQuestion(question)
            );
        }
        this.setState({ animationDirection: "" }, () => {
            this.setState(
                (state) => ({
                    currentQuestion: Math.min(
                        state.currentQuestion,
                        questionnaireElement.questions.length - 1
                    ),
                }),
                () => {
                    this.props.onChange(questionnaireElement);
                }
            );
        });
    }

    selectOutput(selectedIndex: number) {
        let questionnaireElement = Object.assign(
            {},
            this.props.questionnaireElement
        );
        questionnaireElement.outputType = (selectedIndex +
            1) as QuestionnaireOutputType;
        this.props.onChange(questionnaireElement);
    }

    buildInnerItem() {
        if (this.state.showFinalScreen) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        paddingBottom: "52px",
                        cursor: "pointer",
                        paddingRight: "116px",
                    }}
                    onClick={(evt) => {
                        if (this.props.live) {
                            evt.stopPropagation();
                            this.setState({ showFinalScreen: false });
                        }
                    }}
                >
                    {!this.props.live &&
                    !this.props.frozen &&
                    !this.props.questionnaireElement.isDone ? (
                        <input
                            placeholder={"Add thank you text here..."}
                            style={{
                                fontSize: mainStyle.getPropertyValue(
                                    "--primary-path-title-size"
                                ),
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                fontFamily: "Roboto",
                                color:
                                    dataScienceElementsStyle.primaryTextColor,
                                textAlign: "center",
                            }}
                            defaultValue={
                                this.props.questionnaireElement.thankYouTitle
                            }
                            onMouseDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onBlur={(evt) => {
                                let questionnaireElement = {
                                    ...this.props.questionnaireElement,
                                    thankYouTitle: evt.target.value,
                                };
                                this.props.onChange(questionnaireElement);
                            }}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                                if (evt.key === "Enter") {
                                    evt.preventDefault();
                                    let questionnaireElement = {
                                        ...this.props.questionnaireElement,
                                        thankYouTitle: evt.currentTarget.value,
                                    };
                                    this.props.onChange(questionnaireElement);
                                }
                            }}
                        />
                    ) : (
                        <span
                            style={{
                                textAlign: "center",
                                fontSize: mainStyle.getPropertyValue(
                                    "--primary-path-title-size"
                                ),
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                fontFamily: "Roboto",
                                color:
                                    dataScienceElementsStyle.primaryTextColor,
                            }}
                        >
                            {this.props.questionnaireElement.thankYouTitle ??
                                ""}
                        </span>
                    )}
                </div>
            );
        }
        if (this.state.currentQuestion === -1) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        paddingBottom: "52px",
                        paddingRight: "116px",
                    }}
                >
                    {!this.props.live &&
                    !this.props.frozen &&
                    !this.props.questionnaireElement.isDone ? (
                        <div
                            className="flex-simple-column"
                            style={{ width: "100%" }}
                        >
                            <input
                                placeholder={"Add intro text here..."}
                                style={{
                                    fontSize: mainStyle.getPropertyValue(
                                        "--primary-path-title-size"
                                    ),
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    color:
                                        dataScienceElementsStyle.primaryTextColor,
                                    textAlign: "center",
                                }}
                                defaultValue={
                                    this.props.questionnaireElement.introTitle
                                }
                                onMouseDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                onBlur={(evt) => {
                                    let questionnaireElement = {
                                        ...this.props.questionnaireElement,
                                        introTitle: evt.target.value,
                                    };
                                    this.props.onChange(questionnaireElement);
                                }}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                    if (evt.key === "Enter") {
                                        evt.preventDefault();
                                        let questionnaireElement = {
                                            ...this.props.questionnaireElement,
                                            introTitle: evt.currentTarget.value,
                                        };
                                        this.props.onChange(
                                            questionnaireElement
                                        );
                                    }
                                }}
                            />
                            <input
                                placeholder={"Add subtitle here..."}
                                style={{
                                    textAlign: "right",
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    color:
                                        dataScienceElementsStyle.secondaryTextColor,
                                    fontWeight: 700,
                                }}
                                defaultValue={
                                    this.props.questionnaireElement
                                        .introSubtitle
                                }
                                onMouseDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                onBlur={(evt) => {
                                    let questionnaireElement = {
                                        ...this.props.questionnaireElement,
                                        introSubtitle: evt.target.value,
                                    };
                                    this.props.onChange(questionnaireElement);
                                }}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                    if (evt.key === "Enter") {
                                        evt.preventDefault();
                                        let questionnaireElement = {
                                            ...this.props.questionnaireElement,
                                            introSubtitle:
                                                evt.currentTarget.value,
                                        };
                                        this.props.onChange(
                                            questionnaireElement
                                        );
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            className="flex-simple-column"
                            style={{ width: "100%", alignItems: "center" }}
                        >
                            <div className="flex-simple-column">
                                <span
                                    style={{
                                        textAlign: "center",
                                        fontSize: mainStyle.getPropertyValue(
                                            "--primary-path-title-size"
                                        ),
                                        border: "none",
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        fontFamily: "Roboto",
                                        color:
                                            dataScienceElementsStyle.primaryTextColor,
                                    }}
                                >
                                    {this.props.questionnaireElement
                                        .introTitle ?? ""}
                                </span>
                                <span
                                    style={{
                                        textAlign: "right",
                                        cursor: this.props.live
                                            ? "pointer"
                                            : "auto",
                                        border: "none",
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        fontFamily: "Roboto",
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        color:
                                            dataScienceElementsStyle.secondaryTextColor,
                                    }}
                                    onClick={(_evt) => {
                                        if (this.props.live) {
                                            this.stepDown();
                                        }
                                    }}
                                >
                                    {this.props.questionnaireElement
                                        .introSubtitle ?? ""}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            );
        }
        if (this.state.outputSelection === OutputSelection.NotActive) {
            let question = this.props.questionnaireElement.questions[
                this.state.currentQuestion
            ];
            let canDelete =
                !isTemplateSheetQuestion(question) ||
                (this.state.currentQuestion > 0 &&
                    isTemplateSheetQuestion(
                        this.props.questionnaireElement.questions[
                            this.state.currentQuestion - 1
                        ]
                    ));
            return (
                <div
                    key={this.getScreenKey()}
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <div className="my-row" style={{ alignItems: "center" }}>
                        <QuestionHeaderView
                            question={
                                this.props.live && question.liveQuestion != null
                                    ? question.liveQuestion
                                    : question.question
                            }
                            live={this.props.live}
                            frozen={
                                this.props.frozen ||
                                this.props.questionnaireElement.isDone
                            }
                            onChange={(question) => {
                                let questionnaireElement = Object.assign(
                                    {},
                                    this.props.questionnaireElement
                                );
                                questionnaireElement.questions[
                                    this.state.currentQuestion
                                ].question = question;
                                this.props.onChange(questionnaireElement);
                            }}
                        />
                        <div style={{ flexGrow: 1 }}></div>
                        {!this.props.questionnaireElement.isDone &&
                            !this.props.live &&
                            !this.props.frozen && (
                                <EditQuestionDropdown
                                    questionnaireElementId={
                                        this.props.questionnaireElementId
                                    }
                                    index={this.state.currentQuestion}
                                    canDelete={canDelete}
                                    onDelete={() => {
                                        let questionnaireElement = Object.assign(
                                            {},
                                            this.props.questionnaireElement
                                        );
                                        questionnaireElement.questions.splice(
                                            this.state.currentQuestion,
                                            1
                                        );
                                        this.props.onChange(
                                            questionnaireElement
                                        );
                                        this.setState((state) => ({
                                            currentQuestion:
                                                state.currentQuestion - 1,
                                        }));
                                    }}
                                    onDuplicate={() => {
                                        let questionnaireElement = Object.assign(
                                            {},
                                            this.props.questionnaireElement
                                        );
                                        let question = Object.assign(
                                            {},
                                            questionnaireElement.questions[
                                                this.state.currentQuestion
                                            ]
                                        );
                                        questionnaireElement.questions.splice(
                                            this.state.currentQuestion + 1,
                                            0,
                                            question
                                        );
                                        this.props.onChange(
                                            questionnaireElement
                                        );
                                        this.stepDown();
                                    }}
                                />
                            )}
                    </div>
                    <div style={{ flexGrow: 0.3 }} />
                    {isDateQuestion(question) && (
                        <DateQuestionView
                            onNext={(question) => {
                                this.goNext(question);
                            }}
                            frozen={
                                this.props.frozen ||
                                this.props.questionnaireElement.isDone
                            }
                            live={this.props.live}
                            maxHeight={this.props.height * 0.5}
                            question={question}
                            onChange={(question) => {
                                let questionnaireElement = Object.assign(
                                    {},
                                    this.props.questionnaireElement
                                );
                                questionnaireElement.questions[
                                    this.state.currentQuestion
                                ] = question;
                                this.props.onChange(questionnaireElement);
                            }}
                        />
                    )}
                    {isTextQuestion(question) && (
                        <TextQuestionView
                            onNext={(question) => {
                                this.goNext(question);
                            }}
                            frozen={
                                this.props.frozen ||
                                this.props.questionnaireElement.isDone
                            }
                            live={this.props.live}
                            maxHeight={this.props.height * 0.5}
                            question={question}
                            onChange={(question) => {
                                let questionnaireElement = Object.assign(
                                    {},
                                    this.props.questionnaireElement
                                );
                                questionnaireElement.questions[
                                    this.state.currentQuestion
                                ] = question;
                                this.props.onChange(questionnaireElement);
                            }}
                        />
                    )}
                    {isMultipleChoiceQuestion(question) && (
                        <MultipleChoiceQuestionView
                            multiselection={true}
                            frozen={
                                this.props.frozen ||
                                this.props.questionnaireElement.isDone
                            }
                            showPlusButton={
                                !(
                                    this.props.frozen ||
                                    this.props.questionnaireElement.isDone
                                )
                            }
                            key={this.getScreenKey()}
                            live={this.props.live}
                            maxHeight={this.props.height * 0.5}
                            question={question}
                            onChange={(question) => {
                                let questionnaireElement = Object.assign(
                                    {},
                                    this.props.questionnaireElement
                                );
                                questionnaireElement.questions[
                                    this.state.currentQuestion
                                ] = question;
                                this.props.onChange(questionnaireElement);
                            }}
                        />
                    )}
                    {isDropdownQuestion(question) && (
                        <DropdownQuestionView
                            frozen={
                                this.props.frozen ||
                                this.props.questionnaireElement.isDone
                            }
                            key={this.getScreenKey()}
                            live={this.props.live}
                            maxHeight={this.props.height * 0.5}
                            question={question}
                            onChange={(question) => {
                                let questionnaireElement = Object.assign(
                                    {},
                                    this.props.questionnaireElement
                                );
                                questionnaireElement.questions[
                                    this.state.currentQuestion
                                ] = question;
                                this.props.onChange(questionnaireElement);
                            }}
                            currentModuleId={this.props.currentModuleId}
                        />
                    )}
                    {isTemplateSheetQuestion(question) && (
                        <TemplateSheetQuestionView
                            frozen={
                                this.props.frozen ||
                                this.props.questionnaireElement.isDone
                            }
                            onNext={(question) => {
                                this.goNext(question);
                            }}
                            restQuestionsCount={
                                this.props.questionnaireElement.questions.filter(
                                    (question) =>
                                        !isTemplateSheetQuestion(question)
                                ).length
                            }
                            key={this.getScreenKey()}
                            maxHeight={this.props.height * 0.5}
                            question={question}
                            onChange={(question) => {
                                let questionnaireElement = Object.assign(
                                    {},
                                    this.props.questionnaireElement
                                );
                                questionnaireElement.questions[
                                    this.state.currentQuestion
                                ] = question;
                                this.props.onChange(questionnaireElement);
                            }}
                        />
                    )}
                </div>
            );
        }
        if (this.state.outputSelection === OutputSelection.SelectOutput) {
            let question = {
                question: "What do you want to output the data to?",
                type: QuestionType.MultipleChoice,
                options: [
                    {
                        option: "Sheet",
                        selected:
                            this.props.questionnaireElement.outputType ===
                            QuestionnaireOutputType.Sheet,
                    },
                    {
                        option: "Backend Table",
                        selected:
                            this.props.questionnaireElement.outputType ===
                            QuestionnaireOutputType.BackendTable,
                    },
                ],
            };
            return (
                <div
                    key={this.getScreenKey()}
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"What do you want to output the data to?"}
                        live={this.props.live}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <MultipleChoiceQuestionView
                        frozen={true}
                        multiselection={false}
                        live={true}
                        maxHeight={this.props.height * 0.5}
                        question={question}
                        onChange={(question) => {
                            let selectedIndex =
                                question.options.findIndex(
                                    (item: {
                                        option: string;
                                        selected: boolean;
                                    }) => item.selected
                                ) + 1;
                            this.selectOutput(selectedIndex);
                        }}
                    />
                </div>
            );
        }
        if (this.state.outputSelection === OutputSelection.SelectBackendTable) {
            return (
                <div
                    key={this.getScreenKey()}
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Select backend table options"}
                        live={this.props.live}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <BackendOptionsView
                        questionnaireElement={this.props.questionnaireElement}
                        onChange={(questionnaireElement) => {
                            this.props.onChange(questionnaireElement);
                        }}
                        maxHeight={this.props.height * 0.5}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        }
        if (
            this.state.outputSelection === OutputSelection.SelectSheetTemplate
        ) {
            let question = {
                question: "Do you want to output to template?",
                type: QuestionType.MultipleChoice,
                options: [
                    {
                        option: "YES",
                        selected: this.props.questionnaireElement
                            .templateSheetOutputEnabled,
                    },
                    {
                        option: "NO",
                        selected: !this.props.questionnaireElement
                            .templateSheetOutputEnabled,
                    },
                ],
            };
            return (
                <div
                    key={this.getScreenKey()}
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={question.question}
                        live={this.props.live}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <MultipleChoiceQuestionView
                        frozen={true}
                        multiselection={false}
                        live={true}
                        maxHeight={this.props.height * 0.5}
                        question={question}
                        onChange={(question) => {
                            let selectedIndex = question.options.findIndex(
                                (item: { option: string; selected: boolean }) =>
                                    item.selected
                            );
                            this.selectSheetTemplateEnabled(
                                selectedIndex === 0
                            );
                        }}
                    />
                </div>
            );
        }
        return null;
    }
    buildBottomBar() {
        if (this.props.live)
            return this.state.errorMessage != null ? (
                <div
                    className="my-row"
                    style={{
                        alignSelf: "flex-end",
                        paddingRight: "109px",
                        marginTop: "10px",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "red",
                        }}
                    >
                        {this.state.errorMessage}
                    </span>
                </div>
            ) : null;
        if (
            this.state.currentQuestion >= 0 &&
            this.state.outputSelection === OutputSelection.NotActive &&
            !this.props.questionnaireElement.isDone &&
            !this.props.frozen &&
            !this.state.showFinalScreen
        )
            return (
                <div
                    className="my-row"
                    style={{
                        alignSelf: "flex-end",
                        paddingRight: "109px",
                        marginTop: "10px",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            width: "200px",
                        }}
                        onClick={() => {
                            this.setState({
                                showFinalScreen: true,
                            });
                        }}
                    >
                        ENTER THANK YOU TEXT
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: "5px",
                            width: "200px",
                        }}
                        onClick={() => {
                            this.setState({
                                outputSelection: OutputSelection.SelectOutput,
                            });
                        }}
                    >
                        SELECT OUTPUT TO FINISH
                    </Button>
                    {isTemplateSheetQuestion(
                        this.props.questionnaireElement.questions[
                            this.state.currentQuestion
                        ]
                    ) && (
                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginLeft: "5px",
                                width: "110px",
                            }}
                            onClick={() => {
                                let questionnaireElement = Object.assign(
                                    {},
                                    this.props.questionnaireElement
                                );
                                questionnaireElement.questions.sort((a, b) => {
                                    if (
                                        !isTemplateSheetQuestion(a) &&
                                        isTemplateSheetQuestion(b)
                                    )
                                        return -1;
                                    if (
                                        isTemplateSheetQuestion(a) &&
                                        !isTemplateSheetQuestion(b)
                                    )
                                        return 1;
                                    return 0;
                                });
                                questionnaireElement.isDone = true;

                                this.props.onChange(questionnaireElement);
                                this.setState({
                                    outputSelection: OutputSelection.NotActive,
                                    currentQuestion: -1,
                                });
                            }}
                        >
                            DONE
                        </Button>
                    )}
                </div>
            );
        if (this.state.showFinalScreen && !this.props.frozen) {
            return (
                <div
                    className="my-row"
                    style={{ alignSelf: "flex-end", paddingRight: "109px" }}
                >
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            color: dataScienceElementsStyle.primaryTextColor,
                            backgroundColor: "transparent",
                            border: "none",
                            width: "200px",
                        }}
                        onClick={() => {
                            this.setState({
                                showFinalScreen: false,
                            });
                        }}
                    >
                        BACK TO QUESTIONS
                    </Button>
                </div>
            );
        }

        if (
            this.state.outputSelection >= OutputSelection.SelectOutput &&
            !this.props.frozen
        ) {
            return (
                <div
                    className="my-row"
                    style={{ alignSelf: "flex-end", paddingRight: "109px" }}
                >
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            backgroundColor: "transparent",
                            color: dataScienceElementsStyle.primaryTextColor,
                            border: "none",
                            width: "200px",
                        }}
                        onClick={() => {
                            this.setState({
                                outputSelection: OutputSelection.NotActive,
                            });
                        }}
                    >
                        BACK TO QUESTIONS
                    </Button>
                    {(this.state.outputSelection ===
                        OutputSelection.SelectOutput ||
                        this.state.outputSelection ===
                            OutputSelection.SelectBackendTable) && (
                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginLeft: "5px",
                                width: "110px",
                            }}
                            onClick={() => {
                                this.rootRef.current?.focus({
                                    preventScroll: true,
                                });
                                this.setState({
                                    outputSelection:
                                        OutputSelection.SelectSheetTemplate,
                                });
                            }}
                        >
                            NEXT
                        </Button>
                    )}
                    {this.state.outputSelection ===
                        OutputSelection.SelectOutput &&
                        this.props.questionnaireElement.outputType ===
                            QuestionnaireOutputType.BackendTable && (
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: "5px",
                                    width: "110px",
                                }}
                                onClick={() => {
                                    this.setState({
                                        outputSelection:
                                            OutputSelection.SelectBackendTable,
                                    });
                                }}
                            >
                                SELECT TABLE
                            </Button>
                        )}
                    {this.state.outputSelection ===
                        OutputSelection.SelectSheetTemplate &&
                        this.props.questionnaireElement
                            .templateSheetOutputEnabled && (
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: "5px",
                                    width: "150px",
                                }}
                                onClick={() => {
                                    this.setState({
                                        outputSelection:
                                            OutputSelection.NotActive,
                                        currentQuestion:
                                            this.props.questionnaireElement
                                                .questions.length - 1,
                                    });
                                }}
                            >
                                SELECT OPTIONS
                            </Button>
                        )}
                    {this.state.outputSelection ===
                        OutputSelection.SelectSheetTemplate &&
                        !this.props.questionnaireElement
                            .templateSheetOutputEnabled && (
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: "5px",
                                    width: "150px",
                                }}
                                onClick={() => {
                                    let questionnaireElement = Object.assign(
                                        {},
                                        this.props.questionnaireElement
                                    );
                                    questionnaireElement.isDone = true;
                                    this.props.onChange(questionnaireElement);
                                    this.setState({
                                        outputSelection:
                                            OutputSelection.NotActive,
                                        currentQuestion: -1,
                                    });
                                }}
                            >
                                DONE
                            </Button>
                        )}
                </div>
            );
        }
        if (this.props.questionnaireElement.isDone && !this.props.frozen)
            return (
                <div
                    className="my-row"
                    style={{ alignSelf: "flex-end", paddingRight: "109px" }}
                >
                    <span
                        title={"Edit"}
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            let questionnaireElement = Object.assign(
                                {},
                                this.props.questionnaireElement
                            );
                            questionnaireElement.isDone = false;
                            this.props.onChange(questionnaireElement);
                            this.props.onDeleteGrid();
                        }}
                    >
                        <svg
                            style={{
                                fill:
                                    dataScienceElementsStyle.secondaryTextColor,
                            }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z" />
                        </svg>
                    </span>
                </div>
            );
        return null;
    }
    clearAnswers() {
        let questionnaireElement = { ...this.props.questionnaireElement };
        for (let question of questionnaireElement.questions) {
            if (isTextQuestion(question)) {
                question.answer = "";
            }
            if (isDateQuestion(question)) {
                question.answer = "";
            }
            if (isDropdownQuestion(question)) {
                question.tableValue = null;
            }
            if (isMultipleChoiceQuestion(question)) {
                question.options = question.options.map((option) => ({
                    ...option,
                    selected: false,
                }));
            }
        }
        this.props.onChange(questionnaireElement);
    }
    submitAnswers() {
        if (this.props.live) {
            this.setState({ animationDirection: "top" }, () => {
                this.setState({
                    showFinalScreen: true,
                    currentQuestion: 0,
                });
            });
            this.rootRef.current?.focus({ preventScroll: true });
            if (
                this.props.questionnaireElement.outputType ===
                QuestionnaireOutputType.Sheet
            )
                this.props.onAddGridRow(toJS(this.props.questionnaireElement));
            if (
                this.props.questionnaireElement.outputType ===
                QuestionnaireOutputType.BackendTable
            ) {
                this.sendOutputToBackend(toJS(this.props.questionnaireElement));
            }
            if (this.props.questionnaireElement.templateSheetOutputEnabled) {
                let questionnaireElement = toJS(
                    this.props.questionnaireElement
                );
                this.writeOutputToTemplateSheet(questionnaireElement);
            }
            this.clearAnswers();
            return;
        }
    }

    buildFinishScreenBar() {
        if (
            this.state.showFinalScreen ||
            getQuestionsLength(
                this.props.questionnaireElement,
                this.props.live
            ) === 0 ||
            this.state.currentQuestion !==
                getQuestionsLength(this.props.questionnaireElement, true) - 1 ||
            this.state.outputSelection >= OutputSelection.SelectOutput
        )
            return null;

        return (
            <div
                className="my-row"
                style={{
                    alignSelf: "flex-end",
                    paddingRight: "109px",
                }}
            >
                {!this.props.live &&
                !this.props.frozen &&
                !this.props.questionnaireElement.isDone ? (
                    <input
                        placeholder={"Edit submit text here..."}
                        style={{
                            width: "200px",
                            border: "2px solid #1f8efa",
                            borderRadius: "2px",
                            textAlign: "center",
                            outline: "none",
                            backgroundColor: "#1f8efa",
                            fontFamily: "Roboto",
                            fontSize: "18px",
                            margin: "2px 4px",
                            lineHeight: "30px",
                            color: "white",
                            fontWeight: 700,
                        }}
                        defaultValue={this.props.questionnaireElement.endTitle}
                        onMouseDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onBlur={(evt) => {
                            let questionnaireElement = {
                                ...this.props.questionnaireElement,
                                endTitle: evt.target.value,
                            };
                            this.props.onChange(questionnaireElement);
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                            if (evt.key === "Enter") {
                                evt.preventDefault();
                                let questionnaireElement = {
                                    ...this.props.questionnaireElement,
                                    endTitle: evt.currentTarget.value,
                                };
                                this.props.onChange(questionnaireElement);
                            }
                        }}
                    />
                ) : (
                    <span
                        style={{
                            cursor: this.props.live ? "pointer" : "auto",
                            width: "200px",
                            border: "2px solid #1f8efa",
                            borderRadius: "2px",
                            textAlign: "center",
                            outline: "none",
                            backgroundColor: "#1f8efa",
                            fontFamily: "Roboto",
                            fontSize: "18px",
                            margin: "2px 4px",
                            lineHeight: "30px",
                            color: dataScienceElementsStyle.primaryTextColor,
                        }}
                        onClick={(evt) => {
                            if (this.props.live) {
                                evt.stopPropagation();
                                this.submitAnswers();
                            }
                        }}
                    >
                        {this.props.questionnaireElement.endTitle ?? ""}
                    </span>
                )}
            </div>
        );
    }

    buildContent() {
        return (
            <div
                className="my-row"
                style={{ height: "100%", width: "100%", paddingBottom: "38px" }}
            >
                {this.buildLeftBar()}
                <div className="flex-simple-column" style={{ width: "100%" }}>
                    {this.buildInnerItem()}
                    {this.buildFinishScreenBar()}
                    {this.buildBottomBar()}
                </div>
            </div>
        );
    }
    render() {
        let colorOptions: ColorOptions = this.props.questionnaireElement
            .colorOptions ?? {
            borderShadow: false, //mainStyle.getPropertyValue("--questionnaire-shadow"),
            fillColor: dataScienceElementsStyle.contentColor,
            borderColor: dataScienceElementsStyle.borderColor,
        };
        return (
            <div
                ref={this.rootRef}
                className="dashboard-rect-canvas dashboard-rect-canvas-focus"
                tabIndex={0}
                onContextMenu={this.props.onContextMenu}
                style={{
                    boxShadow: colorOptions.borderShadow
                        ? dataScienceElementsStyle.boxShadow
                        : "none",
                    backgroundColor: colorOptions.fillColor,
                    border: colorOptions.borderColor
                        ? `1px solid ${colorOptions.borderColor}`
                        : undefined,
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                }}
                onKeyDown={(evt) => {
                    if (
                        !this.props.live &&
                        this.props.questionnaireElement.isDone
                    )
                        return;
                    if (evt.key === "ArrowDown") {
                        evt.stopPropagation();

                        this.stepDown();
                        evt.preventDefault();
                        return;
                    }
                    if (evt.key === "ArrowUp") {
                        evt.stopPropagation();

                        evt.preventDefault();
                        this.stepUp();
                        return;
                    }
                    if (
                        evt.key === "Enter" &&
                        evt.shiftKey &&
                        this.props.live &&
                        this.state.currentQuestion >= 0
                    ) {
                        evt.stopPropagation();

                        evt.preventDefault();
                        this.goNext();
                    }
                    if (this.state.currentQuestion >= 0 && this.props.live) {
                        let question = Object.assign(
                            {},
                            this.props.questionnaireElement.questions[
                                this.state.currentQuestion
                            ]
                        );
                        let charCode =
                            evt.key.charCodeAt(0) - "a".charCodeAt(0);
                        if (isMultipleChoiceQuestion(question)) {
                            if (
                                charCode >= 0 &&
                                charCode < question.options.length
                            ) {
                                evt.stopPropagation();

                                evt.preventDefault();

                                let questionnaireElement = Object.assign(
                                    {},
                                    this.props.questionnaireElement
                                );
                                question.options[charCode].selected = !question
                                    .options[charCode].selected;
                                questionnaireElement.questions[
                                    this.state.currentQuestion
                                ] = question;
                                this.props.onChange(questionnaireElement);
                                return;
                            }
                        }
                    }
                    if (
                        this.state.outputSelection ===
                            OutputSelection.SelectOutput &&
                        !this.props.frozen
                    ) {
                        let charCode =
                            evt.key.charCodeAt(0) - "a".charCodeAt(0);
                        if (
                            charCode >= 0 &&
                            charCode <= QuestionnaireOutputType.BackendTable - 2
                        ) {
                            evt.stopPropagation();

                            evt.preventDefault();
                            let selectedIndex = charCode + 1;
                            this.selectOutput(selectedIndex);
                            return;
                        }
                    }
                    if (
                        this.state.outputSelection ===
                            OutputSelection.SelectSheetTemplate &&
                        !this.props.frozen
                    ) {
                        let charCode =
                            evt.key.charCodeAt(0) - "a".charCodeAt(0);

                        if (charCode >= 0 && charCode <= 1) {
                            evt.stopPropagation();
                            evt.preventDefault();
                            this.selectSheetTemplateEnabled(charCode === 0);
                            return;
                        }
                    }
                }}
            >
                <div
                    className="flex-column"
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                >
                    {this.buildHeader(colorOptions)}

                    <TransitionGroup
                        style={{
                            height: "100%",
                            display: "flex",
                        }}
                    >
                        <CSSTransition
                            key={this.getScreenKey()}
                            timeout={500}
                            classNames={"journeywizard-".concat(
                                this.state.animationDirection || ""
                            )}
                        >
                            <div style={{ minWidth: "100%" }}>
                                {this.buildContent()}
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}
