import React from "react";
import { CorrelogramFinding,
    defaultCorrelogramOneColor,
    defaultCorrelogramZeroColor,
    defaultCorrelogramMinusOneColor,
    defaultCorrelogramVariableNamesColor,
    defaultCorrelogramFontSize,
    defaultCorrelogramMaxRadius,
    defaultCorrelogramMinRadius
} from "common/Finding";
import styles from "../StylingSection.module.css";
import Input from "../Input";
import StringUtils from "common/utilities/StringUtils";
import { observer } from "mobx-react";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import ColorPicker from "common/ColorPicker";

interface Props {
    showCenteringOption?: boolean;
    dashboardId: string;
    finding: CorrelogramFinding;
    canvasTreeStore: CanvasTreeStore;
    onClearEditing: () => void;
    onChange: (config: CorrelogramFinding["config"], updateData?: boolean) => void;
    currentModuleId?: number;
    currentZoomLevel?: number; // currently unused
}

function CorrelogramSection(props: Props) {
    return (
        <>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>1 color</span>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    tooltip="1 color"
                    value={props.finding.config.oneColor ?? defaultCorrelogramOneColor}
                    onChange={(newValue) => {
                        let newConfig = {
                            ...props.finding.config,
                            oneColor: newValue,
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>0 color</span>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    tooltip="0 color"
                    value={props.finding.config.zeroColor ?? defaultCorrelogramZeroColor}
                    onChange={(newValue) => {
                        let newConfig = {
                            ...props.finding.config,
                            zeroColor: newValue,
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>-1 color</span>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    tooltip="-1 color"
                    value={props.finding.config.minusOneColor ?? defaultCorrelogramMinusOneColor}
                    onChange={(newValue) => {
                        let newConfig = {
                            ...props.finding.config,
                            minusOneColor: newValue,
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Diagonal color</span>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    tooltip="Diagonal color"
                    value={props.finding.config.variableNamesColor ?? defaultCorrelogramVariableNamesColor}
                    onChange={(newValue) => {
                        let newConfig = {
                            ...props.finding.config,
                            variableNamesColor: newValue,
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Font size</span>
                <Input
                    value={
                        props.finding.config.fontSize ??
                        defaultCorrelogramFontSize
                    }
                    onChange={(value) => {
                        let newConfig = {
                            ...props.finding.config,
                        };
                        if (StringUtils.isNumber(value)) {
                            newConfig.fontSize = Number(value);
                        } else {
                            newConfig.fontSize = null;
                        }
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Min radius</span>
                <Input
                    value={
                        props.finding.config.minRadius ??
                        defaultCorrelogramMinRadius
                    }
                    onChange={(value) => {
                        let newConfig = {
                            ...props.finding.config,
                        };
                        if (StringUtils.isNumber(value)) {
                            newConfig.minRadius = Number(value);
                        } else {
                            newConfig.minRadius = null;
                        }
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Max radius</span>
                <Input
                    value={
                        props.finding.config.maxRadius ??
                        defaultCorrelogramMaxRadius
                    }
                    onChange={(value) => {
                        let newConfig = {
                            ...props.finding.config,
                        };
                        if (StringUtils.isNumber(value)) {
                            newConfig.maxRadius = Number(value);
                        } else {
                            newConfig.maxRadius = null;
                        }
                        props.onChange(newConfig);
                    }}
                />
            </div>
        </>
    );
}

export default observer(CorrelogramSection);
