import axios from "common/ServerConnection";
import { DataScope } from "./DataScopes";
import { stringSessionId } from "./SessionId";

export function publishToMarketPlace(dataScope: DataScope): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/create_or_update_marketplace", {
            update_id: stringSessionId(),
            data_table_idx: dataScope.id,
            description: dataScope.marketplaceInfo?.description,
            keywords: dataScope.marketplaceInfo?.keywords,
            logo: dataScope.marketplaceInfo?.logo,
        })
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export function updateVariableDescriptionApi(dataScopeId: string | number, variableIndex: number, description: string): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/update_variable_description", {
            update_id: stringSessionId(),
            data_table_idx: dataScopeId,
            description: description,
            variable_index: variableIndex,
        })
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export function removeMarketplace(dataScope: DataScope): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/remove_marketplace", {
            update_id: stringSessionId(),
            data_table_idx: dataScope.id,
        })
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}
