
/*!
 * function TooltipItemContent renders tooltip item: regular variable item, image item or video item
 * according to value
 */

import { useState } from "react";
import { TooltipItemProps } from "../types";
import StringUtils from "common/utilities/StringUtils";
import processUrl from "common/processUrl";
import { tooltipItemPosition, tooltipItemSize } from "../helpers";
import { getTextSize } from "common/utilities/MeasureText";
import { TextAlign } from "common/Canvas";
import Draggable from "react-draggable";
import { MapFinding } from "common/Finding";
import { Resizable } from "re-resizable";
import { TooltipVideoComponent } from "./TooltipVideoComponent";

export function TooltipItemContent(props: TooltipItemProps) {
  let option = props.option;
  let [drag, setDrag] = useState(false);
  let isImageUrl = false;
  let isUrl = false;
  let isVideoUrl = false;
  if (typeof props.value === "string") {
      isUrl = StringUtils.isValidHttpUrl(props.value as string);
      isImageUrl = StringUtils.isImageUrl(props.value as string);
      isVideoUrl =
          StringUtils.isVideoUrl(props.value) ||
          processUrl(props.value) != null;
  }
  if (props.linkValue != null) {
      isUrl = true;
      isVideoUrl = false;
      isImageUrl = false;
  }
  let showTitle = option.options.showTitle ?? true;
  let position = tooltipItemPosition(
      props.option,
      props.defaultY,
      props.widthOffset
  );
  position.x = position.x * props.scale;
  position.y = position.y * props.scale;
  let itemSize = tooltipItemSize(
      props.option,
      props.defaultWidth,
      props.defaultHeight
  );
  let targetValue = "";
  let defaultTextStyle = {};
  if (
      option.options.width == null &&
      option.options.height == null &&
      !props.editable &&
      !isUrl &&
      !isImageUrl &&
      !isVideoUrl
  ) {
      defaultTextStyle = {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap" as "nowrap",
      };
      targetValue = showTitle
          ? `${option.variable.label} : ${props.value}`
          : `${props.value}`;
      itemSize = getTextSize(
          targetValue,
          "Roboto",
          Math.ceil(option.options.fontSize * props.scale),
          "normal"
      );
      itemSize.width += 5;
  } else {
      itemSize.width = itemSize.width * props.scale;
      itemSize.height = itemSize.height * props.scale;
  }
  let textStyle = {
      ...defaultTextStyle,
      textAlign: option.options.textAlign ?? TextAlign.left,
      display: "block",
      width: itemSize.width,
      height: itemSize.height,
      color: option.options.fontColor,
      fontSize: option.options.fontSize * props.scale,
      fontWeight: 400,
  };
  return (
      <Draggable
          key={props.index}
          disabled={!props.editable}
          position={position}
          onMouseDown={(evt) => {
              evt.stopPropagation();
          }}
          onStart={() => {}}
          onDrag={(_evt, _data) => {
              setDrag(true);
          }}
          onStop={(_evt, data) => {
              if (drag) {
                  let x = Math.max(data.x, 0);
                  let y = Math.max(data.y, 0);
                  let mapFinding: MapFinding = {
                      ...props.mapFinding!,
                      config: {
                          ...props.mapFinding!.config,
                      },
                  };
                  mapFinding.config.tooltipVariables![
                      props.index
                  ]!.options = {
                      ...mapFinding.config.tooltipVariables![props.index]!
                          .options,
                      x: x / props.scale,
                      y: y / props.scale,
                  };
                  props.onChange!(mapFinding);
                  setDrag(false);
              }
          }}
      >
          <Resizable
              enable={
                  !props.editable
                      ? {
                            top: false,
                            right: false,
                            bottom: false,
                            left: false,
                            topRight: false,
                            bottomRight: false,
                            bottomLeft: false,
                            topLeft: false,
                        }
                      : {
                            top: true,
                            right: true,
                            bottom: true,
                            left: true,
                            topRight: true,
                            bottomRight: true,
                            bottomLeft: true,
                            topLeft: true,
                        }
              }
              onResizeStart={(evt) => {
                  evt.stopPropagation();
              }}
              onResizeStop={(_e, _direction, _ref, d) => {
                  let width = itemSize.width + d.width;
                  let height = itemSize.height + d.height;
                  let mapFinding: MapFinding = {
                      ...props.mapFinding!,
                      config: {
                          ...props.mapFinding!.config,
                      },
                  };
                  mapFinding.config.tooltipVariables![
                      props.index
                  ]!.options = {
                      ...mapFinding.config.tooltipVariables![props.index]!
                          .options,
                      width: width / props.scale,
                      height: height / props.scale,
                  };
                  props.onChange!(mapFinding);
              }}
              size={{
                  width: itemSize.width,
                  height: itemSize.height,
              }}
              style={{
                  overflow: "hidden",
                  top: 0,
                  left: 0,
                  border: props.editable ? "1px dashed black" : "none",
                  position: "absolute",
              }}
          >
              {!isUrl && (
                  <span style={textStyle} className="regular-text">
                      {showTitle
                          ? `${option.variable.label} : ${props.value}`
                          : `${props.value}`}
                  </span>
              )}
              {isImageUrl && (
                  <div className="flex-simple-column">
                      {showTitle && (
                          <span
                              style={{
                                  ...textStyle,
                                  display: "inline-block",
                                  height: undefined,
                              }}
                          >
                              {`${option.variable.label}`}
                          </span>
                      )}
                      <img
                          draggable={false}
                          alt=""
                          style={{
                              marginTop: "5px",
                              width: "100%",
                              height: "100%",
                          }}
                          src={props.value as string}
                      />
                  </div>
              )}
              {isUrl && !isImageUrl && !isVideoUrl && (
                  <div className="flex-simple-column">
                      <a target="_blank" rel="noreferrer" href={`${props.value}`}>
                          <span
                              style={{
                                  textAlign:
                                      option.options.textAlign ?? "left",
                                  display: "inline-block",
                                  width: itemSize.width,
                                  color: option.options.fontColor,
                                  fontSize:
                                      option.options.fontSize * props.scale,
                              }}
                              className="regular-text"
                          >
                              {`${props.linkValue ?? props.value}`}
                          </span>
                      </a>
                  </div>
              )}
              {isUrl && !isImageUrl && isVideoUrl && (
                  <div className="flex-simple-column">
                      {showTitle && (
                          <span
                              style={{
                                  textAlign:
                                      option.options.textAlign ?? "left",
                                  display: "inline-block",
                                  width: itemSize.width,
                                  color: option.options.fontColor,
                                  fontSize:
                                      option.options.fontSize * props.scale,
                              }}
                              className="regular-text"
                          >
                              {`${option.variable.label}`}
                          </span>
                      )}

                      <TooltipVideoComponent
                          iframeTitle={`map-tooltip-iframe-${props.mapId}-${props.option.variable.label}-${props.index}`}
                          style={{
                              marginTop: "5px",
                          }}
                          width={itemSize.width}
                          height={
                              itemSize.height -
                              5 -
                              option.options.fontSize * props.scale * 1.4
                          }
                          src={props.value as string}
                      />
                  </div>
              )}
          </Resizable>
      </Draggable>
  );
}
