import React, { useState } from "react";

import { colorList } from "./LineColors";
import { LegendEditElement } from "./LegendEditElement";
import { LegendElement } from "./LegendElement";
import { formatValue } from "common/utilities/FormatValue";
import CustomizedAxisTick from "./CustomizedAxisTick";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";

import {
    ScatterChart,
    XAxis,
    YAxis,
    Scatter,
    Cell,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";
import { TooltipStyles } from "./TooltipStyles";

function ScatterChartInner(props) {
    var axisFontSize = "10px";

    let { data, xAxis, yAxis, scale } = props;

    let plot = (
        <div
            className="my-row center-container"
            style={{
                width: `calc(100%*${scale})`,
                height: `calc(100%*${scale})`,
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                <span
                    style={{
                        textAlign: "right",
                        color: mainStyle.getPropertyValue(
                            "--graphs-axes-text-color"
                        ),
                        fontFamily: "Arial",
                        fontSize:
                            mainStyle.getPropertyValue("--graphs-axes-size"),
                    }}
                >
                    {yAxis.name}
                </span>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                }}
            >
                <ResponsiveContainer height="100%">
                    <ScatterChart>
                        {sections._Global.watermark != null && (
                            <text
                                x="50%"
                                y="30%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--content-secondary-text-color"
                                )}
                                fontSize={"4rem"}
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {sections._Global.watermark}
                            </text>
                        )}
                        <XAxis
                            tick={
                                <CustomizedAxisTick
                                    formatValues={true}
                                    truncValues={false}
                                    fontSize={axisFontSize}
                                    dx={16}
                                    dy={16}
                                />
                            }
                            dataKey={xAxis.name}
                            type="number"
                            domain={["dataMin", "dataMax"]}
                            allowDuplicatedCategory={false}
                            stroke={mainStyle.getPropertyValue(
                                "--graphs-stroke-color"
                            )}
                            strokeWidth={1}
                        />
                        <YAxis
                            type="number"
                            dataKey={yAxis.name}
                            domain={["dataMin", "dataMax"]}
                            axisLine={false}
                            tickLine={false}
                            tick={
                                <CustomizedAxisTick
                                    formatValues={true}
                                    truncValues={false}
                                    fontSize={axisFontSize}
                                    dx={0}
                                    dy={0}
                                />
                            }
                        />
                        <RechartsTooltip
                            formatter={(value, name, props) => {
                                let unit =
                                    xAxis.name === name
                                        ? xAxis.unit
                                        : yAxis.unit;
                                let formattedValue = formatValue(value, false);
                                let result = formattedValue[0]
                                    .concat(formattedValue[1])
                                    .concat(unit);

                                return result;
                            }}
                            {...TooltipStyles()}
                        />
                        <Scatter data={data} fill="#8884d8">
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                />
                            ))}
                        </Scatter>
                    </ScatterChart>
                </ResponsiveContainer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--graphs-axes-text-color"
                            ),
                            fontFamily: "Arial",
                            fontSize:
                                mainStyle.getPropertyValue(
                                    "--graphs-axes-size"
                                ),
                        }}
                    >
                        {xAxis.name}
                    </span>
                </div>
            </div>
        </div>
    );
    return plot;
}

function getScatterData(data, xAxis, yAxis, colors) {
    let scatterData = [];
    data.forEach((cluster, index) => {
        for (let i = 0; i < cluster.count; ++i) {
            let point = {};
            point[xAxis] = cluster[xAxis][i];
            point[yAxis] = cluster[yAxis][i];
            point.color = colorList[index % colorList.length];
            scatterData.push(point);
        }
    });
    return scatterData;
}

export default function ClustersChart(props) {
    let { data, dataKeys } = props;
    const [scale, setScale] = useState(1);
    if (data.length === 0) return null;
    let graphicsData = data.map((item) => item.graphics);
    let scatterDatas = graphicsData.map((graphics) =>
        getScatterData(graphics, dataKeys[0].name, dataKeys[1].name)
    );
    return (
        <div
            className="flex-simple-column"
            onClick={(evt) => {
                evt.stopPropagation();
            }}
            style={{ width: "100%", height: "100%", margin: "0 auto" }}
        >
            <div
                className="my-row"
                style={{
                    marginTop: "5px",
                    alignItems: "center",
                    alignSelf: "flex-end",
                }}
            >
                <button
                    className="zoom-button"
                    onClick={(evt) => {
                        setScale(Math.max(1.0, scale - 0.2));
                    }}
                >
                    {"\uFF0D"}
                </button>

                <span
                    style={{
                        padding: 2,
                        color: "#FFF",
                        fontFamily: "Arial",
                        fontSize: "10px",
                    }}
                >
                    {`${Math.round(100 * scale)}%`}
                </span>
                <button
                    className="zoom-button"
                    onClick={(evt) => {
                        setScale(Math.min(2, scale + 0.2));
                    }}
                >
                    {"\uFF0B"}
                </button>
            </div>
            <div
                className="my-row"
                style={{
                    marginTop: 10,
                    width: "100%",
                    height: "calc(100% - 40px)",
                }}
            >
                {scatterDatas.map((scatterData, groupIndex) => {
                    let title = "";
                    if (data[groupIndex].where) {
                        title = `${
                            data[groupIndex].where.group ||
                            data[groupIndex].where.group_name
                        } ${data[groupIndex].where.operation ?? "="} ${
                            data[groupIndex].where.value
                        }`;
                    }
                    return (
                        <div
                            style={{
                                width: `calc(100%/${scatterDatas.length})`,
                                height: "calc(100% - 40px)",
                            }}
                        >
                            {data[groupIndex].where ? (
                                <div
                                    className="center-container"
                                    style={{ marginBottom: 10 }}
                                >
                                    <span
                                        style={{
                                            color: mainStyle.getPropertyValue(
                                                "--exploration-tertiary-text-color"
                                            ),
                                            fontFamily: "Arial",
                                            fontSize: "12px",
                                            lineHeight: "13px",
                                        }}
                                    >
                                        {title}
                                    </span>
                                </div>
                            ) : null}
                            <div
                                className="my-row center-container"
                                style={{
                                    flexWrap: "wrap",
                                    marginLeft: 20,
                                    marginRight: 20,
                                    width: "100%",
                                    minHeight: 16,
                                }}
                            >
                                {graphicsData[groupIndex].map(
                                    (cluster, index) => {
                                        return props.editable ? (
                                            <LegendEditElement
                                                onFinishEdit={(label) => {
                                                    props.onFinishEdit(
                                                        groupIndex,
                                                        index,
                                                        label
                                                    );
                                                }}
                                                key={index}
                                                color={
                                                    colorList[
                                                        index %
                                                            scatterDatas[0]
                                                                .length
                                                    ]
                                                }
                                                text={cluster.name}
                                            />
                                        ) : (
                                            <LegendElement
                                                key={index}
                                                color={
                                                    colorList[
                                                        index %
                                                            scatterDatas[0]
                                                                .length
                                                    ]
                                                }
                                                text={cluster.name}
                                            />
                                        );
                                    }
                                )}
                            </div>
                            <div
                                className="element"
                                style={{
                                    marginLeft: "5px",
                                    marginTop: "5px",
                                    height: "100%",
                                    width: "100%",
                                    overflow: "auto",
                                }}
                            >
                                <ScatterChartInner
                                    scale={scale}
                                    data={scatterData}
                                    xAxis={dataKeys[0]}
                                    yAxis={dataKeys[1]}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
