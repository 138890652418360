import React from "react";
import styles from "./CategoriesBar.module.css";
import commonStyles from "modules/home_page/home_page.module.css";
import cx from "classnames";
import { CategoryData } from "state/models/Presentation";
import { defaultSelectedCategoryData } from "modules/home_page/main";

interface Props {
    category: CategoryData;
    isActive: boolean;
    setSelectedCategory: (category: CategoryData) => void;
}

function Tab({
    category,
    isActive,
    setSelectedCategory,
}: Props) {
    return (
        <button
            className={cx(styles.tab, isActive && styles.tabActive)}
            onClick={() => {
                if (isActive) {
                    setSelectedCategory(defaultSelectedCategoryData);
                    return;
                }

                setSelectedCategory(category)
            }}
        >
            <img src={category.thumbnail} alt=""/>
            <span className={commonStyles.bigText}>{category.title}</span>
        </button>
    )
}

export default Tab;
