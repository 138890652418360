class FilterDropdownOption {
	label: string;
	value: string;

	static ALL = new FilterDropdownOption("All", "all");
	static CREATED_BY_ME = new FilterDropdownOption("Created by me", "created_by_me");
	static SHARED_WITH_ME = new FilterDropdownOption("Shared with me", "shared_with_me");

	static OPTIONS = [FilterDropdownOption.ALL, FilterDropdownOption.CREATED_BY_ME, FilterDropdownOption.SHARED_WITH_ME];

	private constructor(label: string, value: string) {
		this.label = label;
		this.value = value;
	}
}

export default FilterDropdownOption;
