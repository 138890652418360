import {
    createClient,
    LiveClient,
    LiveConnectionState,
    LiveTranscriptionEvents,
    LiveSchema,
    LiveTranscriptionEvent,
} from "@deepgram/sdk";
  
import {
    createContext,
    useContext,
    useState,
    ReactNode,
    FunctionComponent,
} from "react";

/* for backend API calls */
import axios from "common/ServerConnection";

interface DeepgramContextType {
    connection: LiveClient | null;
    connectToDeepgram: (options: LiveSchema, endpoint?: string) => Promise<void>;
    disconnectFromDeepgram: () => void;
    connectionState: LiveConnectionState;
}
  
const DeepgramContext = createContext<DeepgramContextType | undefined>(
undefined
);

interface DeepgramContextProviderProps {
children: ReactNode;
}

// Extracting key from backend.
const getApiKey = async () => {
    //const myDGKey = process.env.REACT_APP_DG_KEY;
    const response = await axios.get<any>('/api/e/call_get_DGKey');            
    const myDGKey = response.data.dg_key;    
    
    //console.log("DeepGram key used:", myDGKey);
    return {
        key: myDGKey
    };     
};

const DeepgramContextProvider: FunctionComponent<
DeepgramContextProviderProps
> = ({ children }) => {
const [connection, setConnection] = useState<LiveClient | null>(null);
const [connectionState, setConnectionState] = useState<LiveConnectionState>(
    LiveConnectionState.CLOSED
);

/**
 * Connects to the Deepgram speech recognition service and sets up a live transcription session.
 *
 * @param options - The configuration options for the live transcription session.
 * @param endpoint - The optional endpoint URL for the Deepgram service.
 * @returns A Promise that resolves when the connection is established.
 */
const connectToDeepgram = async (options: LiveSchema, endpoint?: string) => {
    const key = await getApiKey();
    const deepgram = createClient(key);

    const conn = deepgram.listen.live(options, endpoint);

    conn.addListener(LiveTranscriptionEvents.Open, () => {
    setConnectionState(LiveConnectionState.OPEN);
    });

    conn.addListener(LiveTranscriptionEvents.Close, () => {
    setConnectionState(LiveConnectionState.CLOSED);
    });

    setConnection(conn);
};

const disconnectFromDeepgram = async () => {
    if (connection) {
    connection.finish();
    setConnection(null);
    }
};

return (
    <DeepgramContext.Provider
    value={{
        connection,
        connectToDeepgram,
        disconnectFromDeepgram,
        connectionState,
    }}
    >
    {children}
    </DeepgramContext.Provider>
);
};

function useDeepgram(): DeepgramContextType {
    const context = useContext(DeepgramContext);
    if (context === undefined) {
        throw new Error(
        "useDeepgram must be used within a DeepgramContextProvider"
        );
    }
    return context;
}

export {
    DeepgramContextProvider,
    useDeepgram,
    LiveConnectionState,
    LiveTranscriptionEvents,
};    
export type { LiveTranscriptionEvent };  // TODO: Need to check with Pedro on why there is this discrepancy?

