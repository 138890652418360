import React from "react";
import {
    AiCopilotFinding,
    defaultAiCopilotTitle, defaultAiCopilotQuestionOne, defaultAiCopilotQuestionTwo, defaultAiCopilotQuestionThree,
    defaultAiCopilotBackgroundColor,
    defaultAiCopilotBannerBgColor,
    defaultAiCopilotBotMsgBgColor,
    defaultAiCopilotCustomFirstMessageBtnColor
} from "common/Finding";
import styles from "../StylingSection.module.css";
import copilotStyles from "./AiCopilotSection.module.css";
import TextInput from "../TextInput";
import { observer } from "mobx-react";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import ColorPicker from "common/ColorPicker";
import cx from "classnames";

interface Props {
    showCenteringOption?: boolean;
    dashboardId: string;
    finding: AiCopilotFinding;
    canvasTreeStore: CanvasTreeStore;
    onClearEditing: () => void;
    onChange: (
        config: AiCopilotFinding["config"],
        updateData?: boolean
    ) => void;
    currentModuleId?: number;
    currentZoomLevel?: number; // currently unused
}

function AiCopilotSection(props: Props) {
    return (
        <>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Title</span>
                <TextInput
                    value={props.finding.config.title ?? defaultAiCopilotTitle}
                    onChange={(value) => {
                        props.onChange({
                            ...props.finding.config,
                            title: value,
                        });
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Initial Choice (L)</span>
                <TextInput
                    value={props.finding.config.questionOne ?? defaultAiCopilotQuestionOne}
                    onChange={(value) => {
                        props.onChange({
                            ...props.finding.config,
                            questionOne: value,
                        });
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Initial Choice (M)</span>
                <TextInput
                    value={props.finding.config.questionTwo ?? defaultAiCopilotQuestionTwo}
                    onChange={(value) => {
                        props.onChange({
                            ...props.finding.config,
                            questionTwo: value,
                        });
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Initial Choice (R)</span>
                <TextInput
                    value={props.finding.config.questionThree ?? defaultAiCopilotQuestionThree}
                    onChange={(value) => {
                        props.onChange({
                            ...props.finding.config,
                            questionThree: value,
                        });
                    }}
                />
            </div>


            <div className={styles.optionContainer}>
                <span className={cx(styles.optionName, copilotStyles.optionName)}>Initial Choices Background</span>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    tooltip="Initial Choices Background Color"
                    value={
                        props.finding.config.customFirstMessageBtnColor ??
                        defaultAiCopilotCustomFirstMessageBtnColor
                    }
                    onChange={(value) => {
                        props.onChange({
                            ...props.finding.config,
                            customFirstMessageBtnColor: value,  
                        });
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={cx(styles.optionName, copilotStyles.optionName)}>Banner Color</span>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    tooltip="Banner Color"
                    value={
                        props.finding.config.bannerBgColor ??
                        defaultAiCopilotBannerBgColor
                    }
                    onChange={(value) => {
                        props.onChange({
                            ...props.finding.config,
                            bannerBgColor: value,  
                        });
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={cx(styles.optionName, copilotStyles.optionName)}>Background Color</span>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    tooltip="Background Color"
                    value={
                        props.finding.config.backgroundColor ??
                        defaultAiCopilotBackgroundColor
                    }
                    onChange={(value) => {
                        props.onChange({
                            ...props.finding.config,
                            backgroundColor: value,
                        });
                    }}
                />
            </div>
            
            <div className={styles.optionContainer}>
                <span className={cx(styles.optionName, copilotStyles.optionName)}>Response Background Color</span>
                <ColorPicker
                    topAppearance
                    enableAlpha
                    tooltip="Message Background color"
                    value={
                        props.finding.config.botMsgBgColor ??
                        defaultAiCopilotBotMsgBgColor
                    }
                    onChange={(value) => {
                        props.onChange({
                            ...props.finding.config,
                            botMsgBgColor: value,  
                        });
                    }}
                />
            </div>
            
        </>
    );
}

export default observer(AiCopilotSection);
