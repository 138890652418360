import React from "react";
import ErrorBoundary from "common/ErrorBoundary";

function FindingItem(props) {
    try {
        let finding = props.finding;
        if (!finding) return null;
        let type = finding.type;
        let dir = `insight_${type}`;
        let mod = require(`insights_api/${dir}/main`);
        return (
            <ErrorBoundary>
                <mod.MainComponent {...props} />
            </ErrorBoundary>
        );
    } catch (e) {
        return null;
    }
}

export default FindingItem;
