function toDocument(htmlData: string | Document): Document {
    if (typeof htmlData === "string") {
        let domParser = new DOMParser();
        return domParser.parseFromString(htmlData, "text/html");
    } else {
        return htmlData;
    }
}

function extractTable(doc: Document): string[][] | null {
    let table: string[][] = [];
    let row: string[];
    for (let tr of doc.getElementsByTagName("tr")) {
        row = [];
        for (let td of tr.getElementsByTagName("td")) {
            row.push(td.textContent ?? "");
        }
        table.push(row);
    }
    if (table.length !== 0) {
        return table;
    } else {
        return null;
    }
}

export function parseGoogleSheetsData(
    htmlData: string | Document
): string[][] | null {
    let doc: Document = toDocument(htmlData);
    if (doc.getElementsByTagName("google-sheets-html-origin").length !== 0) {
        return extractTable(doc);
    } else {
        return null;
    }
}

export function parseCanvasData(
    htmlData: string | Document
): string[][] | null {
    let doc: Document = toDocument(htmlData);
    if (doc.getElementsByTagName("canvas-sheet").length !== 0) {
        return extractTable(doc);
    } else {
        return null;
    }
}

export function parseExcelData(htmlData: string | Document): string[][] | null {
    let doc: Document = toDocument(htmlData);
    let progId = doc.getElementsByName("ProgId");
    let generator = doc.getElementsByName("generator");
    if (
        (progId.length !== 0 &&
            progId[0].getAttribute("content") === "Excel.Sheet") ||
        (generator.length !== 0 &&
            generator[0].getAttribute("content")?.startsWith("Microsoft Excel"))
    ) {
        return extractTable(doc);
    } else {
        return null;
    }
}

export function parseLibreOfficeData(
    htmlData: string | Document
): string[][] | null {
    let doc: Document = toDocument(htmlData);
    let generator = doc.getElementsByName("generator");
    if (
        generator.length !== 0 &&
        generator[0].getAttribute("content")?.startsWith("LibreOffice")
    ) {
        return extractTable(doc);
    } else {
        return null;
    }
}

export default function parseSheetData(
    htmlData: string | Document
): string[][] | null {
    let doc: Document = toDocument(htmlData);
    return (
        parseGoogleSheetsData(doc) ??
        parseExcelData(doc) ??
        parseLibreOfficeData(doc) ??
        parseCanvasData(doc)
    );
}
