import React from "react";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { observer } from "mobx-react";
import styles from "./DeploySession.module.css";
import {
    Entry,
    guidanceWizardEntryGetApi,
} from "../../Api";
import {
    guidanceWizardEntryPermissionsAdd,
    guidanceWizardEntryPermissionsGet,
    guidanceWizardEntryPermissionsRemove,
} from "../../Api";
import UserGroupsStore from "common/UserGroupsStore";
import { addEmailInvitation, removeEmailInvitation } from "common/EmailInvitation";

interface Props {
    entryId: number | undefined;
}

const DeploySession: React.FC<Props> = (props) => {
    const [inputValue, setInputValue] = React.useState<string>("");
    const [entry, setEntry] = React.useState<Entry | undefined>(undefined);
    const [users, setUsers] = React.useState<
        {
            userName: string;
            pending: boolean;
            groupId?: number;
            linkId?: string;
        }[]
    >([]);

    UserGroupsStore.init();

    React.useEffect(() => {
        if (props.entryId != null) {
            guidanceWizardEntryGetApi(props.entryId)
                .then(setEntry)
                .catch((error) => {
                    console.log(error);
            });
            guidanceWizardEntryPermissionsGet(props.entryId)
                .then(({ permissions, invitations }) => {
                    setUsers(
                        invitations
                            .map((invitation): {
                                userName: string;
                                pending: boolean;
                                groupId?: number;
                                linkId?: string;
                            } => ({
                                userName: invitation.email,
                                pending: true,
                                linkId: invitation.link_id,
                            }))
                            .concat(
                                permissions.map((permission) => ({
                                    userName: permission.group_name,
                                    pending: false,
                                    groupId: permission.group_id,
                                }))
                            )
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setUsers([]);
        }
    }, [props.entryId]);

    return (
        <div className={styles.card}>
            <div className={styles.contentWrapper}>
                <p className={styles.title}>
                    Invite people to " {entry?.title} "
                </p>
                <p className={cx(styles.subtitle, styles.text)}>
                    Invite teammates to collaborate and use products in your
                    organization. We’ll ask new users to enter their personal
                    details when they sign up.
                </p>

                <div className={styles.emailForm}>
                    <p className={styles.text}>
                        Email addresses <b style={{ color: "red" }}>*</b>
                    </p>
                    <div className={styles.emailInput}>
                        <input
                            placeholder="Invite by Email Address..."
                            className={styles.input}
                            onChange={(e) => {
                                setInputValue(e.target.value);
                            }}
                            value={inputValue}
                        ></input>

                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                width: "6em",
                                height: "33px",
                                fontSize: 14,
                                borderRadius: "6px",
                            }}
                            onClick={async () => {
                                let users: {
                                    userName: string;
                                    groupId: number;
                                }[] = [];
                                let newEmails: string[] = [];
                                for (let email of inputValue.split(",")) {
                                    email = email.trim();
                                    let group = UserGroupsStore.userGroupsOptionsForSharing().find(
                                        (group) =>
                                            group.value === email ||
                                            group.userInfo?.emails.includes(
                                                email
                                            )
                                    );
                                    if (group != null) {
                                        users.push({
                                            userName: group.value,
                                            groupId: group.id,
                                        });
                                    } else {
                                        newEmails.push(email);
                                    }
                                }

                                try {
                                    await guidanceWizardEntryPermissionsAdd(
                                        props.entryId!,
                                        users.map((user) => user.groupId)
                                    );
                                } catch (error) {
                                    console.log(error);
                                    users = [];
                                }

                                let invitations: {
                                    userName: string;
                                    pending: boolean;
                                    linkId: string;
                                }[] = [];
                                for (let newEmail of newEmails) {
                                    try {
                                        let linkId = await addEmailInvitation(
                                            undefined,
                                            props.entryId,
                                            undefined,
                                            newEmail
                                        );
                                        invitations.push({
                                            userName: newEmail,
                                            pending: true,
                                            linkId: linkId,
                                        });
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }

                                setUsers((oldUsers) => [
                                    ...oldUsers,
                                    ...users.map((user) => ({
                                        ...user,
                                        pending: false,
                                    })),
                                    ...invitations,
                                ]);
                                setInputValue("");
                            }}
                        >
                            Invite
                        </Button>
                    </div>
                </div>

                <p className={cx(styles.text, styles.textSmall)}>
                    Separate emails using a comma.
                </p>

                <div className={styles.userList}>
                    <p className={cx(styles.text)}>Shared with:</p>
                    <div className={styles.line}></div>
                    {users.map((user, index) => {
                        return (
                            <div className={styles.userWrapper}>
                                <div className={styles.userProfile}>
                                    <div className={styles.icon}></div>
                                    <p className={styles.text}>
                                        {user.userName}
                                    </p>
                                </div>
                                <Button
                                    className="btn my-outline"
                                    style={{
                                        fontSize: "14px",
                                        width: "6em",
                                        height: "33px",
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        borderColor: "transparent",
                                        color: "#3b82c9",
                                        border: "1px solid #3b82c9",
                                        backgroundColor: "transparent",
                                        borderRadius: "6px",
                                    }}
                                    onClick={() => {
                                        if (user.pending) {
                                            removeEmailInvitation(user.linkId!, undefined, props.entryId)
                                                .then(() => {
                                                    setUsers((users) => {
                                                        let newUsers = [
                                                            ...users,
                                                        ];
                                                        newUsers.splice(
                                                            index,
                                                            1
                                                        );
                                                        return newUsers;
                                                    });
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        } else {
                                            guidanceWizardEntryPermissionsRemove(
                                                props.entryId!,
                                                [user.groupId!]
                                            )
                                                .then(() => {
                                                    setUsers((users) => {
                                                        let newUsers = [
                                                            ...users,
                                                        ];
                                                        newUsers.splice(
                                                            index,
                                                            1
                                                        );
                                                        return newUsers;
                                                    });
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }
                                    }}
                                >
                                    Remove
                                </Button>
                            </div>
                        );
                    })}
                </div>

                {/* email list with delete button */}
            </div>
        </div>
    );
};

export default observer(DeploySession);
