import React, { PureComponent } from "react";
import { mainStyle } from "common/MainStyle";

export class HorizontalBarsAxisTick extends PureComponent {
    render() {
        if (isNaN(this.props.x) || isNaN(this.props.y)) return null;
        return (
            <g
                transform={
                    "translate( " + this.props.x + "," + this.props.y + " )"
                }
            >
                <text
                    x={0}
                    y={0}
                    dy={this.props.dy}
                    dx={this.props.dx}
                    fontFamily="Arial"
                    fontSize={this.props.fontSize ?? mainStyle.getPropertyValue("--graphs-axes-size")}
                    textAnchor="end"
                    fill={
                        this.props.axesColor ??
                        mainStyle.getPropertyValue("--graphs-axes-text-color")
                    }
                    className="no-selection"
                >
                    {this.props.payload.value}
                </text>
            </g>
        );
    }
}

export class MultiLineAxisTick extends PureComponent {
    render() {
        // this.props.payload.value.split('\n').map((value, index, array) => <tspan>{value}</tspan>)
        let angle = this.props.angle || 0;
        return (
            <g
                transform={`translate(${this.props.x}, ${this.props.y}) rotate(${angle})`}
            >
                <text
                    x={0}
                    y={0}
                    fontFamily="Arial"
                    fontSize={mainStyle.getPropertyValue("--graphs-axes-size")}
                    fill={
                        this.props.axesColor ??
                        mainStyle.getPropertyValue("--graphs-axes-text-color")
                    }
                    className="no-selection"
                >
                    {this.props.payload.value
                        .split("\n")
                        .map((value, index) => {
                            return (
                                <tspan
                                    key={index}
                                    textAnchor="end"
                                    x={0}
                                    y={0}
                                    dx={this.props.dx}
                                    dy={this.props.dy * (index + 1)}
                                >
                                    {value}
                                </tspan>
                            );
                        })}
                </text>
            </g>
        );
    }
}

export class SingleLineAxisTick extends PureComponent {
    render() {
        let angle = this.props.angle || 0;
        return (
            <g
                transform={`translate(${this.props.x}, ${this.props.y}) rotate(${angle})`}
            >
                <text
                    x={0}
                    y={0}
                    fontFamily="Arial"
                    fontSize={
                        this.props.fontSize ??
                        mainStyle.getPropertyValue("--graphs-axes-size")
                    }
                    fill={
                        this.props.axesColor ??
                        mainStyle.getPropertyValue("--graphs-axes-text-color")
                    }
                    className="no-selection"
                >
                    <tspan
                        textAnchor="end"
                        x={0}
                        y={0}
                        dx={this.props.dx}
                        dy={this.props.dy}
                    >
                        {this.props.formatValue
                            ? this.props.formatValue(this.props.payload.value)
                            : this.props.payload.value}
                    </tspan>
                </text>
            </g>
        );
    }
}
