import React from "react";
import { observer } from "mobx-react-lite";
import Select, { createFilter } from "react-select";
import {
    getCustomSelectStyleForDataSection,
    leftAlignAndScrollBarCSS,
} from "common/SelectStyles";
import variables, { Variable } from "common/Variables";
import styles from "./SideBySideSection.module.css";
import StringOption from "common/StringOption";
import { SearchComponentOption } from "common/SearchComponent";
import ValueWithOperator from "common/ValueWithOperator";
import Finding, {
    isBarChart,
    isLeverOutcomeChart,
    isHoldOutPredictionChart,
} from "common/Finding";
import commonStyles from "../DataSection.module.css";
import { Button } from "react-bootstrap";
import cx from "classnames";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import switchStyleProps from "../../SwitchStyleProps";
import Switch from "react-switch";

export interface SideBySideSectionProps {
    finding: Finding;
    maxValuesCount: number;
    onChange: (finding: Finding, updateData?: boolean) => void;
    currentModuleId?: number;
}

interface NullableNumberOption {
    label: string;
    value: number | null;
}

export default observer(function SideBySideSection(
    props: SideBySideSectionProps
) {
    let dataScopeId = props.finding.config.dataScope.value;
    let sideBySideVariableIndex = props.finding.config.sideBySideVariableIndex;
    let variableOptions = variables(dataScopeId, props.currentModuleId)
        .variableOptions;
    let options: NullableNumberOption[] = [
        {
            label: "None",
            value: null,
        } as NullableNumberOption,
    ].concat(
        variableOptions.map((option) => ({
            label: option.label,
            value: option.value,
        }))
    );
    let additionalValues: (SearchComponentOption | null)[] =
        props.finding.config.additionalValues ?? [];
    if (additionalValues.length === 0) {
        additionalValues.push(null);
    }
    let additionalOperators: StringOption[] =
        props.finding.config.additionalOperators ?? [];
    if (additionalOperators.length === 0) {
        additionalOperators.push({
            label: "=",
            value: "=",
        });
    }
    let variableInfo: Variable | undefined = undefined;
    const dataVariables = variables(dataScopeId, props.currentModuleId)
        .dataVariables;
    if (
        sideBySideVariableIndex != null &&
        sideBySideVariableIndex < dataVariables.length
    )
        variableInfo = dataVariables[sideBySideVariableIndex];
    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    let updateFinding =
        !isLeverOutcomeChart(props.finding) &&
        !isHoldOutPredictionChart(props.finding);
    let groupByAllSupported = isBarChart(props.finding);
    let groupByAll = !groupByAllSupported
        ? false
        : props.finding.config.groupByAll ?? true;
    return (
        <div className={styles.container}>
            <h3
                className={commonStyles.textItem}
                style={{ marginTop: 0, paddingLeft: 0 }}
            >
                {"Vary by"}
            </h3>
            <div
                className={cx(
                    commonStyles.optionContainer,
                    styles.optionContainer
                )}
                style={{ marginTop: 3 }}
            >
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Select variable"}
                    styles={{
                        ...selectStyles,
                        ...leftAlignAndScrollBarCSS,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                            minHeight: "29px",
                            borderRadius: 5,
                        }),
                        singleValue: (base) => ({
                            ...base,
                            paddingLeft: 5,
                        }),
                        placeholder: (base) => ({
                            ...base,
                            color: "#333",
                            paddingLeft: 5,
                        }),
                        control: (provided) => ({
                            ...provided,
                            minHeight: 30,
                            height: 30,
                            borderRadius: 3,
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: 0,
                        }),
                    }}
                    options={options}
                    onChange={(newValue) => {
                        let newFinding = {
                            ...props.finding,
                            config: {
                                ...props.finding.config,
                                sideBySideVariableIndex: (newValue as NullableNumberOption)
                                    .value,
                                sideBySideVariable: (newValue as NullableNumberOption)
                                    .label,
                                additionalOperators: null,
                                additionalValues: null,
                            },
                        };
                        props.onChange(newFinding, updateFinding);
                    }}
                    value={options.find(
                        (option) =>
                            option.value ===
                            props.finding.config.sideBySideVariableIndex
                    )}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            {isBarChart(props.finding) && (
                <div
                    className={commonStyles.optionContainer}
                    style={{ marginTop: "20px" }}
                >
                    <Switch
                        checked={!groupByAll}
                        {...switchStyleProps}
                        onChange={() => {
                            let newFinding = {
                                ...props.finding,
                                config: {
                                    ...props.finding.config,
                                    groupByAll: !groupByAll,
                                },
                            };
                            props.onChange(newFinding, true);
                        }}
                    />
                    <span
                        style={{ marginLeft: "10px" }}
                        className={styles.optionName}
                    >
                        Side by side
                    </span>
                </div>
            )}

            <div>
                {!groupByAll &&
                    variableInfo != null &&
                    additionalValues.map(
                        (
                            value: SearchComponentOption | null,
                            index: number
                        ) => (
                            <div
                                key={index}
                                style={{
                                    marginTop: 10,
                                    width: "100%",
                                    display: "flex",
                                }}
                            >
                                <ValueWithOperator
                                    nospace
                                    smallText
                                    small
                                    selectStyles={selectStyles}
                                    currentModuleId={props.currentModuleId}
                                    dataScopeId={dataScopeId}
                                    operator={additionalOperators[index]}
                                    value={value}
                                    searchKey={variableInfo!.name}
                                    searchIndex={
                                        props.finding.config
                                            .sideBySideVariableIndex
                                    }
                                    numeric={
                                        variableInfo!.type === "float" ||
                                        variableInfo!.type === "int"
                                    }
                                    equalsOnly={false}
                                    onOperatorChange={(newOperator) => {
                                        let newAdditionalOperators: StringOption[] = Array.from(
                                            additionalOperators
                                        );
                                        newAdditionalOperators[
                                            index
                                        ] = newOperator;
                                        let newFinding = {
                                            ...props.finding,
                                            config: {
                                                ...props.finding.config,
                                                additionalOperators: newAdditionalOperators,
                                            },
                                        };
                                        props.onChange(newFinding, true);
                                    }}
                                    onValueChange={(newValue) => {
                                        let newAdditionalValues: (SearchComponentOption | null)[] = Array.from(
                                            additionalValues
                                        );
                                        newAdditionalValues[index] = newValue;
                                        let newFinding = {
                                            ...props.finding,
                                            config: {
                                                ...props.finding.config,
                                                additionalValues: newAdditionalValues,
                                            },
                                        };
                                        props.onChange(
                                            newFinding,
                                            updateFinding
                                        );
                                    }}
                                    clearable={true}
                                />

                                {index === 0 && props.maxValuesCount > 1 && (
                                    <Button
                                        title="Add value"
                                        className={cx(
                                            "btn btn-sm btn-primary my-primary",
                                            commonStyles.addColumnButton
                                        )}
                                        onClick={() => {
                                            if (
                                                additionalValues.length >=
                                                props.maxValuesCount
                                            ) {
                                                return;
                                            }
                                            let newAdditionalValues = Array.from(
                                                additionalValues
                                            );

                                            newAdditionalValues.splice(
                                                index + 1,
                                                0,
                                                null
                                            );
                                            let newAdditionalOperators = Array.from(
                                                additionalOperators
                                            );
                                            newAdditionalOperators.splice(
                                                index + 1,
                                                0,
                                                {
                                                    label: "=",
                                                    value: "=",
                                                }
                                            );

                                            let newFinding = {
                                                ...props.finding,
                                                config: {
                                                    ...props.finding.config,
                                                    additionalValues: newAdditionalValues,
                                                    additionalOperators: newAdditionalOperators,
                                                },
                                            };
                                            props.onChange(newFinding);
                                        }}
                                    >
                                        {"\uFF0B"}
                                    </Button>
                                )}
                                {index === 1 && (
                                    <Button
                                        title="Remove value"
                                        className={cx(
                                            "btn btn-sm btn-primary my-primary",
                                            commonStyles.addColumnButton,
                                            commonStyles.deleteColumnButton
                                        )}
                                        onClick={() => {
                                            let newAdditionalValues = Array.from(
                                                additionalValues
                                            );
                                            newAdditionalValues.splice(
                                                index,
                                                1
                                            );
                                            if (
                                                newAdditionalValues.length === 0
                                            ) {
                                                newAdditionalValues.push(null);
                                            }
                                            let newAdditionalOperators = Array.from(
                                                additionalOperators
                                            );
                                            newAdditionalOperators.splice(
                                                index,
                                                1
                                            );
                                            if (
                                                newAdditionalOperators.length ===
                                                0
                                            ) {
                                                newAdditionalOperators.push({
                                                    label: "=",
                                                    value: "=",
                                                });
                                            }
                                            let newFinding = {
                                                ...props.finding,
                                                config: {
                                                    ...props.finding.config,
                                                    additionalValues: newAdditionalValues,
                                                    additionalOperators: newAdditionalOperators,
                                                },
                                            };
                                            props.onChange(
                                                newFinding,
                                                updateFinding
                                            );
                                        }}
                                    >
                                        {"\u2715"}
                                    </Button>
                                )}
                            </div>
                        )
                    )}
            </div>
        </div>
    );
});
