import React from "react";
import LeadersLaggers from "common/graphics/v2/LeadersLaggers";
import Finding from "common/Finding";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { CanvasDashboard } from "common/Canvas";

function MainComponent(props: {
    finding: Finding;
    canvasTreeStore: CanvasTreeStore
    scale: number;
    disableDashboardDrag: (disable: boolean) => void;
    onNewFinding?: (finding: Finding, updateData?: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    dataSetMenuIsOpen?: boolean;
    columnDragActive?: boolean;
    dashboardId?: string;
    currentModuleId?: string;
    onExpandCard?: (node: CanvasDashboard, 
        popup: {popupStatus: string, popupMessage: string} | undefined) => void;
    live?: boolean;
}) {
	let finding = props.finding;
	return (
		<LeadersLaggers
            live={props.live}
            onExpandCard={props.onExpandCard}
            data={finding.content.data}
			config={finding.config}
            disableDashboardDrag={props.disableDashboardDrag}
            preview={props.preview}
            scale={props.scale}
            canvasTreeStore={props.canvasTreeStore}
            editable={props.editable}
            dashboardId={props.dashboardId}
            currentModuleId={props.currentModuleId}
            columnDragActive={props.columnDragActive}
            dataSetMenuIsOpen={props.dataSetMenuIsOpen}
            onChangeData={
                props.onNewFinding
                    ? (data: any, updateData?: boolean) => {
                          let finding = { ...props.finding };
                          finding.content.data = data;
                          props.onNewFinding!(finding, updateData);
                      }
                    : undefined
            }
            onChangeConfig={
                props.onNewFinding
                    ? (config: any, updateData?: boolean) => {
                          let finding = { ...props.finding };
                          finding.config = config;
                          props.onNewFinding!(finding, updateData);
                      }
                    : undefined
            }
		/>
	);
}

export { MainComponent };
