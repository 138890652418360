import React from "react";
import { HistogramFinding } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import styles from "./HistogramSection.module.css";
import { Button } from "react-bootstrap";
import EditInput from "../EditInput";
import cx from "classnames";
import StringUtils from "common/utilities/StringUtils";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

interface Props {
    columnDragActive: boolean;
    finding: HistogramFinding;
    onChange: (finding: HistogramFinding, updateData?: boolean) => void;
    onClearEditing: () => void;
    dashboardId: string;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
}

export default function ScatterPlotSection(props: Props) {
    let data = props.finding.content.data;
    let currentEditVariableIndex: number | undefined = undefined;
    if (props.finding.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex =
            data[0].variableIndex == null ? 0 : undefined;
    }
    let xAxisName = props.finding.content.data[0].name;
    let yAxisName = props.finding.content.data[1].name;
    let datasetIsConnected = props.finding.config.dataScope != null;
    let maxRowCount = 3;
    let restCount = data[0].value.length - maxRowCount;
    return (
        <div>
            <div className="my-row">
                <div className={styles.dataContainer}>
                    <div className={commonStyles.header}></div>
                    <div
                        className={cx(commonStyles.header, {
                            [commonStyles.highlightValueVerticalTop]:
                                currentEditVariableIndex === 0,
                        })}
                    >
                        {xAxisName}
                    </div>
                    <div
                        className={cx(commonStyles.header, {
                            [commonStyles.highlightValueVerticalTop]:
                                currentEditVariableIndex === 1,
                        })}
                    >
                        {yAxisName}
                    </div>
                    {(datasetIsConnected
                        ? data[0].value.slice(0, maxRowCount)
                        : data[0].value
                    ).map((_, index, array) => (
                        <React.Fragment key={index}>
                            <EditInput
                                showDeleteButton={
                                    props.finding.config.dataScope == null
                                }
                                disabled={true}
                                value={`Value ${index + 1}`}
                                onDelete={() => {
                                    let finding: HistogramFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: [
                                                {
                                                    ...props.finding!.content
                                                        .data[0],
                                                    value: [
                                                        ...props.finding!
                                                            .content.data[0]
                                                            .value,
                                                    ],
                                                },
                                                {
                                                    ...props.finding!.content
                                                        .data[1],
                                                    value: [
                                                        ...props.finding!
                                                            .content.data[1]
                                                            .value,
                                                    ],
                                                },
                                            ],
                                        },
                                    };
                                    finding.content.data[0].value.splice(
                                        index,
                                        1
                                    );
                                    if (
                                        index >=
                                        finding.content.data[1].value.length
                                    ) {
                                        finding.content.data[1].value.splice(
                                            index - 1,
                                            1
                                        );
                                    } else {
                                        finding.content.data[1].value.splice(
                                            index,
                                            1
                                        );
                                    }
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVertical]:
                                        currentEditVariableIndex === 0,
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 0 &&
                                        index === array.length - 1 &&
                                        !(datasetIsConnected && restCount > 0),
                                })}
                                value={data[0].value[index]}
                                onChange={(value) => {
                                    let finding: HistogramFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: [
                                                {
                                                    ...props.finding!.content
                                                        .data[0],
                                                    value: [
                                                        ...props.finding!
                                                            .content.data[0]
                                                            .value,
                                                    ],
                                                },
                                                {
                                                    ...props.finding!.content
                                                        .data[1],
                                                    value: [
                                                        ...props.finding!
                                                            .content.data[1]
                                                            .value,
                                                    ],
                                                },
                                            ],
                                        },
                                    };
                                    finding.content.data[0].value[
                                        index
                                    ] = Number(value);
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVertical]:
                                        currentEditVariableIndex === 1,
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 1 &&
                                        index === array.length - 1 &&
                                        !(datasetIsConnected && restCount > 0),
                                    [commonStyles.greyedOutValue]:
                                        index >= data[1].value.length,
                                })}
                                disabled={
                                    props.finding!.config.dataScope != null ||
                                    index >= data[1].value.length
                                }
                                value={data[1].value[index] ?? ""}
                                onChange={(value) => {
                                    let finding: HistogramFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: [
                                                {
                                                    ...props.finding!.content
                                                        .data[0],
                                                    value: [
                                                        ...props.finding!
                                                            .content.data[0]
                                                            .value,
                                                    ],
                                                },
                                                {
                                                    ...props.finding!.content
                                                        .data[1],
                                                    value: [
                                                        ...props.finding!
                                                            .content.data[1]
                                                            .value,
                                                    ],
                                                },
                                            ],
                                        },
                                    };
                                    finding.content.data[1].value[
                                        index
                                    ] = Number(value);
                                    props.onChange(finding);
                                }}
                            />
                        </React.Fragment>
                    ))}
                    {datasetIsConnected && restCount > 0 && (
                        <React.Fragment>
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={`+${restCount} values`}
                            />
                            <EditInput
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 0,
                                })}
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                            <EditInput
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 1,
                                })}
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
            {datasetIsConnected && (
                <>
                    <div className={styles.optionContainer}>
                        <span className={styles.optionName}>Bins</span>
                        <EditInput
                            optionalStyles={{ marginLeft: 10 }}
                            optionalClassName={styles.input}
                            showDeleteButton={false}
                            disabled={false}
                            value={props.finding.config.bins ?? 10}
                            onChange={(value) => {
                                let finding: HistogramFinding = {
                                    ...props.finding!,
                                    config: {
                                        ...props.finding!.config,
                                    },
                                };
                                if (StringUtils.isNumber(value)) {
                                    let bins = Number(value);
                                    if (bins !== finding.config.bins) {
                                        finding.config.bins = bins;
                                        props.onChange(finding, true);
                                    }
                                }
                            }}
                        />
                    </div>
                </>
            )}
            {props.finding.config.dataScope == null && (
                <Button
                    style={{ marginTop: 10 }}
                    className={cx(
                        "btn btn-sm btn-primary my-primary",
                        commonStyles.addItemButton
                    )}
                    onClick={() => {
                        let finding: HistogramFinding = {
                            ...props.finding!,
                            content: {
                                ...props.finding!.content,
                                data: [
                                    {
                                        ...props.finding!.content.data[0],
                                        value: [
                                            ...props.finding!.content.data[0]
                                                .value,
                                        ],
                                    },
                                    {
                                        ...props.finding!.content.data[1],
                                        value: [
                                            ...props.finding!.content.data[1]
                                                .value,
                                        ],
                                    },
                                ],
                            },
                        };
                        // X axis
                        let lastValue =
                            finding.content.data[0].value[
                                finding.content.data[0].value.length - 1
                            ] ?? 0;
                        let step =
                            finding.content.data[0].value.length <= 1
                                ? 10
                                : lastValue -
                                  finding.content.data[0].value[
                                      finding.content.data[0].value.length - 2
                                  ];
                        finding.content.data[0].value.push(lastValue + step);
                        // Y axis
                        lastValue =
                            finding.content.data[1].value[
                                finding.content.data[1].value.length - 1
                            ] ?? 50;
                        finding.content.data[1].value.push(lastValue);
                        // Y axis
                        props.onChange(finding);
                    }}
                >
                    {"\uFF0B Add Item"}
                </Button>
            )}
        </div>
    );
}
