import React from "react";
import Select from "react-select";
import cx from "classnames";
import styles from "./Preset.module.css";
import { ColumnIndex } from "../../GuidanceWizardDataSet";
import ViewOption from "common/graphics/ViewOption";
import axios from "common/ServerConnection";
import StringOption from "common/StringOption";
import BarChart from "./BarChart";
import RankChart from "./RankChart";

interface Props {
    entryId: number; // ID of the guidance wizard entry
    dataScopeId: number; // Index of the user-uploaded data set
    title: string; // The title, for example "Ed's Sales Tool"
    logo: string | null; // Logotype as base64 image, or null if there is no logo
}

const dropdownOptions: StringOption[] = [
    {
        label: "YTD",
        value: "ytd",
    },
    {
        label: "MTD",
        value: "mtd",
    },
    {
        label: "QTR",
        value: "qtr",
    },
];

function Preset(props: Props): JSX.Element {
    const [selectedPeriod, setSelectedPeriod] = React.useState<string>("ytd");
    const [barChartData, setBarChartData] = React.useState<
        {
            leftValue: number;
            rightValue: number;
            segment: string;
        }[]
    >([]);
    const [rankChartData, setRankChartData] = React.useState<
        { [key: string]: string | number | null }[]
    >([]);

    // Calculate bar chart data
    React.useEffect(() => {
        axios
            .post<{
                compare_data: {
                    values?: { [key: string | number]: number };
                    where?: {
                        value?: string | number;
                        group_name?: string;
                        operation?: string;
                    };
                }[][];
                horizontal_lines: { [key: number]: number };
                success: boolean;
                error_msg: string;
            }>("/api/e/compare_variables", {
                data_table_idx: props.dataScopeId,
                table: [],
                variable_indices: [
                    ColumnIndex.CustomerRevenueCurrentPeriod,
                    ColumnIndex.CustomerRevenueLastPeriod,
                ],
                conditions: [
                    {
                        variable: ColumnIndex.Period,
                        operation: "not in",
                        value: ["ytd", "mtd", "qtr"],
                    },
                ],
                where: {
                    group_index: ColumnIndex.Period,
                    expressions: [],
                },
                group_operation: "sum",
                entry_id: props.entryId,
            })
            .then((response) => {
                setBarChartData(
                    response.data.compare_data[0].map((item) => ({
                        leftValue: item.values![
                            ColumnIndex.CustomerRevenueLastPeriod
                        ],
                        rightValue: item.values![
                            ColumnIndex.CustomerRevenueCurrentPeriod
                        ],
                        segment:
                            (item.where?.value as string | null)?.slice(
                                "segment_".length
                            ) ?? "",
                    }))
                );
            })
            .catch((error) => {
                console.log(error);
            });
    }, [props.entryId, props.dataScopeId]);

    // Calculate rank chart data
    React.useEffect(() => {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                leaders: { [key: string]: string | number | null }[];
                laggers: { [key: string]: string | number | null }[];
                variable_indices: number[];
                show_map: boolean;
            }>("/api/e/get_leaders_and_laggers", {
                measured_by_index: ColumnIndex.Opportunity,
                variable_indices: [
                    ColumnIndex.Customer,
                    ColumnIndex.CustomerRevenueCurrentPeriod,
                    ColumnIndex.CustomerRevenueLastPeriod,
                ],
                data_table_idx: props.dataScopeId,
                table: [],
                conditions: [
                    {
                        variable: ColumnIndex.Period,
                        operation: "=",
                        value: selectedPeriod,
                    },
                ],
                count: 100,
                view_option: ViewOption.Leaders,
                entry_id: props.entryId,
            })
            .then((response) => {
                if (response.data.success) {
                    setRankChartData(response.data.leaders);
                } else {
                    console.log(response.data.error_msg);
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    }, [props.entryId, props.dataScopeId, selectedPeriod]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <span className={styles.title}>{props.title}</span>
                <span className={styles.subtitle}>
                    Opportunity = Decline in Sales vs. Prior Year
                </span>
                <div className={styles.dropdownArea}>
                    <span className={styles.dropdownAreaTitle}>
                        Select period:
                    </span>
                    <Select
                        placeholder=""
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                borderRadius: "4px",
                            }),
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                            }),
                        }}
                        options={dropdownOptions}
                        onChange={(newValue) => {
                            setSelectedPeriod((newValue as StringOption).value);
                        }}
                        value={dropdownOptions.find(
                            (option) => option.value === selectedPeriod
                        )}
                    />
                </div>
                {props.logo != null && props.logo.length !== 0 && (
                    <div className={styles.logoContainer}>
                        <img className={styles.logo} alt="" src={props.logo} />
                    </div>
                )}
            </div>
            <div className={styles.chartContainer}>
                {barChartData.length !== 0 && (
                    <div className={styles.barChartContainer}>
                        <span className={styles.chartTitle}>
                            Sales by Channel
                        </span>
                        <div className={styles.barChartInnerContainer}>
                            <div
                                className={cx(
                                    styles.barChartWrapper,
                                    "element"
                                )}
                            >
                                {barChartData.map((item) => (
                                    <BarChart
                                        leftValue={item.leftValue}
                                        leftLabel="Last"
                                        rightValue={item.rightValue}
                                        rightLabel="Current"
                                        segment={item.segment}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.rankChartContainer}>
                    <span className={styles.chartTitle}>
                        Opportunities by Customer
                    </span>
                    <div className={styles.rankChartInnerContainer}>
                        <div className={styles.rankChartWrapper}>
                            <RankChart data={rankChartData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Preset;
