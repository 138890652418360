import React from "react";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";
import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import { Permission } from "common/Permissions";
import VariableEditor from "common/VariableEditor";
import { renameVariablesApi } from "common/DataApi";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
                renameVariablesExpanded: false,
                renameVariables: [
                    {
                        name: null,
                        unit: null,
                        new_name: null,
                    },
                ],
            };
        }

        renameVariables() {
            this.setStepState(StepState.running);

            renameVariablesApi(
                this.props.commonState.viewDataScopeId,
                this.state.renameVariables,
                null
            )
                .then(() => {
                    this.setStepState(StepState.finished);
                    setInterval(window.location.reload(), 1000);
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        renderRenameVariablesSelector() {
            return (
                <VariableEditor
                    dataScopeId={this.props.commonState.viewDataScopeId}
                    renameVariables={this.state.renameVariables}
                    onChange={(renameVariables) => {
                        this.setState({ renameVariables: renameVariables });
                    }}
                />
            );
        }

        renderRenameVariables() {
            if (this.state.expanded) {
                return (
                    <>
                        <div />
                        <div
                            style={{
                                gridColumnStart: 2,
                                gridColumnEnd: 4,
                                minWidth: 0,
                                background: "#3f4b62",
                                padding: "10px",
                            }}
                        >
                            {this.renderRenameVariablesSelector()}
                        </div>
                        <div />
                    </>
                );
            } else return null;
        }

        render() {
            let hasAccess =
                this.props.commonState.viewDataScope?.permissionType ===
                Permission.ReadWrite;
            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        className="btn-processing-tab"
                        disabled={
                            this.props.disabled || (
                                this.props.commonState.viewDataScope != null &&
                                !hasAccess
                            )
                        }
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState({ expanded: !this.state.expanded });
                        }}
                    >
                        <span className="content-regular-text">
                            Rename variables
                        </span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    {hasAccess && this.state.expanded ? (
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            onClick={() => {
                                this.renameVariables();
                            }}
                            style={{
                                fontFamily: "Roboto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: "25px",
                                width: "100%",
                                minWidth: "110px",
                            }}
                        >
                            RENAME
                        </Button>
                    ) : (
                        <div style={{ minWidth: "110px" }} />
                    )}
                    <div>
                        {hasAccess &&
                            this.state.expanded &&
                            this.renderStepState()}
                    </div>
                    {hasAccess && this.renderRenameVariables()}
                </>
            );
        }
    }
);

export { MainComponent };
export let requirePermission = "DataSetRenameVariables";
