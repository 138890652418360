import React, { CSSProperties } from "react";
import { mainStyle } from "common/MainStyle";
import ColorPicker from "common/ColorPicker";

interface Props {
    color: string;
    text: string;
    textSize?: number;
    onFinishColor?: (newColor: string) => void;
    onFinishEdit?: (newText: string) => void;
    containerStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    allowColorChange?: boolean;
    disallowTextChange?: boolean;
    textColor?: string;
    showLegend?: boolean;
}

export function LegendEditElement(props: Props) {
    let {
        color,
        text,
        textSize,
        allowColorChange,
        disallowTextChange,
        inputStyle,
        containerStyle,
        showLegend = true,
    } = props;
    return (
        <div
            key={text}
            className="my-row"
            style={{
                alignItems: "center",
                marginLeft: 10,
                ...containerStyle,
            }}
        >
            {allowColorChange && showLegend ? (
                <ColorPicker
                    enableAlpha={true}
                    isLegend={true}
                    value={props.color}
                    onChange={(newValue) => {
                        if (props.onFinishColor != null)
                            props.onFinishColor(newValue);
                    }}
                    style={{
                        width: 32,
                        height: 8,
                    }}
                />
            ) : (
                <>
                    {showLegend && <div
                        style={{
                            width: 32,
                            height: 8,
                            backgroundColor: color,
                        }}
                    />}
                </>
            )}
            {disallowTextChange ? (
                <span
                    style={{
                        marginLeft: 10,
                        color:
                            props.textColor ??
                            mainStyle.getPropertyValue(
                                "--graphs-legends-text-color"
                            ),
                        fontFamily: "Arial",
                        fontSize:textSize ?? mainStyle.getPropertyValue(
                            "--graphs-legends-size"
                        ),
                    }}
                >
                    {text}
                </span>
            ) : (
                <input
                    defaultValue={text}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    onBlur={(evt) => {
                        evt.preventDefault();
                        let label = evt.target.value;
                        if (props.onFinishEdit != null)
                            props.onFinishEdit(label);
                    }}
                    style={{
                        ...inputStyle,
                        backgroundColor: "transparent",
                        outline: "none",
                        marginLeft: 10,
                        color:
                            props.textColor ??
                            mainStyle.getPropertyValue(
                                "--graphs-legends-text-color"
                            ),
                        fontFamily: "Arial",
                        fontSize: textSize ?? mainStyle.getPropertyValue(
                            "--graphs-legends-size"
                        ),
                    }}
                />
            )}
        </div>
    );
}
