import StringOption from "./StringOption";

export const aggregationFunctions: StringOption[] = [
    { value: "mean", label: "Average" },
    { value: "count", label: "Count" },
    { value: "sum", label: "Sum" },
];

export const valueToTitle: { [key: string]: string } = {
    mean: "Average",
    count: "Count",
    count_distinct: "Count",
    sum: "Sum",
};
export const additionalBarChartAndPieChartOperations = new Set([
    "mean_column",
    "count_column",
    "sum_column",
]);

export const columnByOptions = new Set(["mean_column", "sum_column"]);

export const additionalBarChartAndPieChartOptions: StringOption[] = [
    { value: "mean_column", label: "Mean within column" },
    { value: "count_column", label: "Count within column" },
    { value: "sum_column", label: "Sum within column" },
];

export const aggregationToAdditionalMap: { [key: string]: string } = {
    mean: "mean_column",
    sum: "sum_column",
    count: "count_column",
};
