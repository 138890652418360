import classNames from "classnames";
import React, { useState } from "react";

import styles from "./styles.module.css";

const INTEREST_LIST = [
  "Data analytics",
  "Surveys",
  "Sports",
  "Business Inteligence (BI)",
  "Sales",
  "Business Administration",
  "Financial Planning",
  "Data Visualization",
];

const FAMILIAR_LIST = ["I'm getting started", "I'm good with it", "I'm a pro"];

const WelcomeModal: React.FC<Props> = ({
  show = false,
  onSkip = () => null,
  onDone = () => null,
}) => {
  const [selectedInterests, setSelectedInterests] = useState(
    new Array<string>()
  );
  const [selectedFamiliarity, setSelectedFamiliarity] = useState<string>();

  const handleInterestClick = (interest: string) => {
    let newValue = [];
    if (selectedInterests.includes(interest)) {
      newValue = selectedInterests.filter((f) => f !== interest);
    } else {
      newValue = [...selectedInterests, interest];
    }
    if (newValue.length > 3) {
      newValue = newValue.slice(1, 4);
    }
    setSelectedInterests(newValue);
  };

  return show ? (
    <>
      <div className={styles.background} />
      <div className={styles.container}>
        <div className={styles.content}>
          <h2>Let's get you started...</h2>
          <section>
            <span className={styles.optionDesc}>
              What are you interested in? (Choose up to 3)
            </span>
            <ul className={styles.optionsContainer}>
              {INTEREST_LIST.map((interest) => (
                <li
                  className={classNames(styles.option, {
                    [styles.optionSelected]:
                      selectedInterests.includes(interest),
                  })}
                  onClick={() => handleInterestClick(interest)}
                >
                  {interest}
                </li>
              ))}
            </ul>
          </section>
          <section>
            <span className={styles.optionDesc}>
              How familiar are you with data?
            </span>
            <ul className={styles.optionsContainer}>
              {FAMILIAR_LIST.map((familiarity) => (
                <li
                  className={classNames(styles.option, {
                    [styles.optionSelected]:
                      selectedFamiliarity === familiarity,
                  })}
                  onClick={() => setSelectedFamiliarity(familiarity)}
                >
                  {familiarity}
                </li>
              ))}
            </ul>
          </section>
          <section className={styles.buttonsContainer}>
            <button className={styles.button} onClick={onSkip}>
              Skip
            </button>
            <button
              className={styles.button2}
              style={{
                display:
                  selectedInterests.length > 0 && selectedFamiliarity
                    ? "block"
                    : "none",
              }}
              onClick={() =>
                onDone(
                  selectedInterests?.[0],
                  selectedInterests?.[1],
                  selectedInterests?.[2],
                  selectedFamiliarity || ""
                )
              }
            >
              Done
            </button>
          </section>
        </div>
      </div>
    </>
  ) : null;
};

interface Props {
  show: boolean;
  onSkip: () => void;
  onDone: (
    interest1: string,
    interest2: string,
    interest3: string,
    familiarWithData: string
  ) => void;
}

export default WelcomeModal;
