import React from "react";

import { colorList } from "./LineColors";
//import db from "./db";
//import InsightsType from "./InsightsType";
import { LegendEditElement } from "./LegendEditElement";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";
import StringUtils from "common/utilities/StringUtils";

import {
    LabelList,
    FunnelChart,
    Funnel,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";
import { TooltipStyles } from "./TooltipStyles";

interface Config {
    selectedVariable: string;
    labelVariable: string;
    labelsColor?: string;
    valuesColor?: string;
    funnelColor?: string;
    titleColor?: string;
    title?: string;
    labelsFontSize?: number;
    valuesFontSize?: number;
}

interface FunnelProps {
    onConfigChange: (config: Config) => void;
    data: {
        [key: string]: number;
    }[];
    config: Config;
    editable?: boolean;
}

function formatter(value: string | number) {
    if (typeof value === "string" || typeof value === "number") {
        let formattedValue = formatValue(value, false);

        let result = formattedValue[0].concat(formattedValue[1]);
        return result;
    }
    return value;
}

function EditInput(props: {
    name: string;
    value: string | number;
    onChange: (value: string) => void;
}) {
    return (
        <div
            className="my-row"
            style={{ alignItems: "center", paddingLeft: "5px" }}
        >
            <span
                style={{
                    color: mainStyle.getPropertyValue(
                        "--graphs-legends-text-color"
                    ),
                    fontFamily: "Arial",
                    fontSize: mainStyle.getPropertyValue(
                        "--graphs-legends-size"
                    ),
                }}
            >
                {props.name}
            </span>
            <input
                defaultValue={props.value}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                placeholder=""
                onChange={(e) => {
                    e.preventDefault();
                    let value = e.target.value;
                    props.onChange(value);
                }}
                style={{
                    marginLeft: "2px",
                    width: "50px",
                    alignSelf: "center",
                    backgroundColor: "transparent",
                    outline: "none",
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    color: mainStyle.getPropertyValue(
                        "--graphs-legends-text-color"
                    ),
                }}
            ></input>
        </div>
    );
}

export default function FunnelPlot(props: FunnelProps) {
    let editable = props.editable ?? false;
    let labelsColor =
        props.config.labelsColor ??
        mainStyle.getPropertyValue("--graphs-axes-text-color").trim();
    let valuesColor =
        props.config.valuesColor ??
        mainStyle.getPropertyValue("--graphs-axes-text-color").trim();
    let titleColor =
        props.config.titleColor ??
        mainStyle.getPropertyValue("--graphs-legends-text-color").trim();
    let funnelColor = props.config.funnelColor ?? colorList[1];
    let title = props.config.title ?? "";
    let labelsFontSize =
        props.config.labelsFontSize ??
        parseFloat(mainStyle.getPropertyValue("--graphs-axes-size").trim());
    let valuesFontSize =
        props.config.valuesFontSize ??
        parseFloat(mainStyle.getPropertyValue("--graphs-axes-size").trim());
    let ref = React.useRef<typeof ResponsiveContainer>(null);
    let [containerHeight, setContainerHeight] = React.useState(0);
    React.useEffect(() => {
        let newContainerHeight = ((ref.current as any)?.current as any)
            ?.clientHeight;
        if (containerHeight !== newContainerHeight) {
            setContainerHeight(newContainerHeight);
        }
        // containerHeight should not be in dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    let plot = (
        <div
            className="flex-simple-column"
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            {editable && (
                <div
                    className="my-row"
                    style={{
                        flexWrap: "wrap",
                        justifyContent: "center",
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 10,
                        width: "100%",
                        minHeight: 16,
                    }}
                >
                    <EditInput
                        name={"Labels size"}
                        value={labelsFontSize}
                        onChange={(value) => {
                            if (StringUtils.isNumber(value)) {
                                let config = {
                                    ...props.config,
                                    labelsFontSize: Number(value),
                                };
                                props.onConfigChange(config);
                            }
                        }}
                    />
                    <EditInput
                        name={"Values size"}
                        value={valuesFontSize}
                        onChange={(value) => {
                            if (StringUtils.isNumber(value)) {
                                let config = {
                                    ...props.config,
                                    valuesFontSize: Number(value),
                                };
                                props.onConfigChange(config);
                            }
                        }}
                    />

                    <LegendEditElement
                        allowColorChange
                        disallowTextChange
                        onFinishColor={(color: string) => {
                            let config = {
                                ...props.config,
                                labelsColor: color,
                            };
                            props.onConfigChange(config);
                        }}
                        color={labelsColor}
                        text={"Labels Color"}
                    />
                    <LegendEditElement
                        allowColorChange
                        disallowTextChange
                        onFinishColor={(color: string) => {
                            let config = {
                                ...props.config,
                                valuesColor: color,
                            };
                            props.onConfigChange(config);
                        }}
                        color={valuesColor}
                        text={"Values Color"}
                    />
                    <LegendEditElement
                        allowColorChange
                        disallowTextChange
                        onFinishColor={(color: string) => {
                            let config = {
                                ...props.config,
                                funnelColor: color,
                            };
                            props.onConfigChange(config);
                        }}
                        color={funnelColor}
                        text={"Funnel Color"}
                    />
                    <LegendEditElement
                        allowColorChange
                        disallowTextChange
                        onFinishColor={(color: string) => {
                            let config = {
                                ...props.config,
                                titleColor: color,
                            };
                            props.onConfigChange(config);
                        }}
                        color={titleColor}
                        text={"Title Color"}
                    />
                </div>
            )}
            {editable ? (
                <input
                    defaultValue={title}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    placeholder="Title"
                    onBlur={(e) => {
                        e.preventDefault();
                        let title = e.target.value;
                        let config = {
                            ...props.config,
                            title: title,
                        };
                        props.onConfigChange(config);
                    }}
                    style={{
                        marginTop: 10,

                        alignSelf: "center",
                        backgroundColor: "transparent",
                        outline: "none",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        color: titleColor,
                    }}
                ></input>
            ) : (
                title && (
                    <span
                        style={{
                            marginTop: 10,
                            alignSelf: "center",
                            fontFamily: "Roboto",
                            fontSize: "15px",
                            color: titleColor,
                        }}
                    >
                        {title}
                    </span>
                )
            )}

            <div style={{ marginTop: 10, height: "100%", overflow: "hidden" }}>
                <ResponsiveContainer ref={ref} width="100%" height="99%">
                    <FunnelChart>
                        {sections._Global.watermark != null && (
                            <text
                                x="50%"
                                y="30%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--content-secondary-text-color"
                                )}
                                fontSize={"4rem"}
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {sections._Global.watermark}
                            </text>
                        )}
                        <RechartsTooltip
                            formatter={(
                                _value: number,
                                _name: string,
                                _props: any
                            ) => {
                                let originalValue =
                                    _props.payload[
                                        props.config.selectedVariable
                                    ];
                                return formatter(originalValue);
                            }}
                            {...TooltipStyles()}
                        />

                        <Funnel
                            isAnimationActive={false}
                            data={props.data}
                            nameKey={props.config.labelVariable}
                            dataKey={"%percentage"}
                            fill={funnelColor}
                        >
                            <LabelList
                                position="insideTop"
                                fill={labelsColor}
                                offset={
                                    containerHeight / (2 * props.data.length) -
                                    labelsFontSize
                                }
                                fontSize={labelsFontSize}
                                stroke="none"
                                dataKey={props.config.labelVariable}
                                {...{
                                    formatter: formatter,
                                }}
                            />
                            <LabelList
                                position="insideTop"
                                fill={valuesColor}
                                offset={
                                    containerHeight / (2 * props.data.length)
                                }
                                fontSize={valuesFontSize}
                                stroke="none"
                                dataKey={props.config.selectedVariable}
                                {...{
                                    formatter: formatter,
                                }}
                            />
                        </Funnel>
                    </FunnelChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
    return plot;
}
