import React from "react";
import { mainStyle } from "common/MainStyle";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";
import Select from "react-select";
import { getQualtricsOptions, QualtricSurvey } from "common/ExternalApiOptionsApi";
import { getCustomSelectStyleLight } from "common/SelectStyles";

export default class QUALTRICS extends React.Component<APIComponentProps, State> {

    constructor(props: APIComponentProps) {
        super(props);
        this.state = {
            selectedSurvey: null,
            surveyOptions: [],
            loadingSurveyOptions: true
        }
    }

    componentDidMount() {
        getQualtricsOptions().then(response => {
            this.setState({surveyOptions: response.data.options.surveys, loadingSurveyOptions: false});
        }).catch(() => {
            this.setState({surveyOptions: null, loadingSurveyOptions: false})
        });
    }

    render() {
        let selectStyles = getCustomSelectStyleLight(14, false);
        let contentStyle: React.CSSProperties = {
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: 400,
            color: mainStyle.getPropertyValue("--content-primary-text-color"),
        };
        let titleStyle = {
            ...contentStyle,
            color: "#39F",
        };
        return (
            <div
                className="flex-simple-column"
                style={this.props.containerStyle}
            >
                <BaseAPIComponent {...this.props} />
                <div className="flex-simple-column" style={{ marginTop: "23px" }}>
                    <span style={titleStyle}>Survey ID</span>
                    <Select
                        styles={{
                            ...selectStyles,
                            container: (base) => ({
                                ...base,
                                marginTop: 4,
                                marginRight: 163,
                                width: 500,
                            }),
                        }}
                        value={this.state.selectedSurvey}
                        onChange={(value) => {
                            this.setState({selectedSurvey: value as QualtricSurvey | null});
                            let options = { ...this.props.options };
                            this.props.onOptionsChange({
                                ...options,
                                survey_id: (value as QualtricSurvey | null)?.id,
                            });
                        }}
                        options={this.state.surveyOptions || []}
                        getOptionLabel={data => data.name}
                        getOptionValue={data => data.id}
                        placeholder={this.state.loadingSurveyOptions ? 'Loading...' : this.state.surveyOptions ? 'Select a survey' : 'Error fetching surveys'}
                        isDisabled={this.state.loadingSurveyOptions} 
                    />
                </div>
            </div>
        );
    }
}

interface State {
    selectedSurvey: QualtricSurvey | null;
    surveyOptions: Array<QualtricSurvey> | null;
    loadingSurveyOptions: boolean;
}

export { QUALTRICS as MainComponent };
