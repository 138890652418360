import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
    CanvasDropdownSelector,
    CanvasFilter,
    isDropdownSelector,
} from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import TabTitle from "common/TabTitle";
import { GlobalContextContents } from "GlobalContext";
import Settings from "./Settings";
import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";
import styles from "./DropdownSelector.module.css";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

interface Props {
    canvasId: number;
    node: CanvasDropdownSelector | CanvasFilter;
    nodeLinkOptions: NodeLinkOption[];
    shared: boolean;
    currentModuleId?: number;
    globalContext: GlobalContextContents;
    canvasTreeStore: CanvasTreeStore;
    onChange: (
        changes: Partial<CanvasDropdownSelector> | Partial<CanvasFilter>,
        commit?: boolean
    ) => void;
    onChangeShared: (shared: boolean) => void;
    onClose: () => void;
}

function DropdownSelector({
    node,
    nodeLinkOptions,
    currentModuleId,
    globalContext,
    onChangeShared,
    shared,
    onChange,
    onClose,
    canvasTreeStore
}: Props) {
    const [selectedTab, setSelectedTab] = useState("settings");

    function selectTab(tabKey: string | null) {
        if (tabKey == null) return;
        setSelectedTab(tabKey);
    }

    return (
        <div>
            <div className={styles.editMenuHeader}>
                <h6 className={styles.editMenuHeaderTitle}>Advanced Options</h6>
                <button
                    type="button"
                    onClick={onClose}
                    className={styles.editMenuCloseButton}
                >
                    <CloseIcon />
                </button>
            </div>
            <Tabs
                id={`advanced-menu-${
                    isDropdownSelector(node) ? "dropdown-selector" : "filter"
                }-tabs`}
                activeKey={selectedTab}
                onSelect={selectTab}
                className={styles.tabMenu}
            >
                <Tab
                    eventKey="settings"
                    title={
                        <TabTitle
                            title="Settings"
                            selected={selectedTab === "settings"}
                            className={styles.tabTitle}
                        />
                    }
                    className={styles.tab}
                >
                    <Settings
                        canvasTreeStore={canvasTreeStore}
                        currentModuleId={currentModuleId}
                        nodeLinkOptions={nodeLinkOptions}
                        shared={shared}
                        globalContext={globalContext}
                        onChangeShared={onChangeShared}
                        node={node}
                        onChange={onChange}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}

export default DropdownSelector;
