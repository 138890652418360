/*!
 * function MarkerWrapper renders marker on a map
 * If we have variables that we need to display always, then tooltip
 * will be rendered within this component;
 * If we have variables displayed on hover or on click, we are using
 * callbacks - onShowPopup or onShowHoverTooltip respectively
 */

import { useMemo } from "react";
import "leaflet/dist/leaflet.css";
import Leaflet from "leaflet";
import { colorList } from "common/graphics/LineColors";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";

import { Marker, Tooltip } from "react-leaflet";

import { MarkerWrapperProps } from "../types";
import { MapVariableOption, MapTooltipDisplayMode } from "common/Canvas";
import { markerIcon, markerIconTransparent } from "./MarkerIcon";
import { TooltipContent } from "./TooltipContent";
import { FlagType } from "common/Finding";

export function MarkerWrapper(props: MarkerWrapperProps) {
    const tooltipVariables = useMemo(() => {
        return (props.tooltipVariables ?? []).filter(
            (item): item is MapVariableOption => item != null
        );
    }, [props.tooltipVariables]);
    if (props.data == null) return null;
    let displayModeValue =
        props.displayMode?.value ?? MapTooltipDisplayMode.onClick;

    const mouseEventHandlers: any = {};
    if (
        displayModeValue === MapTooltipDisplayMode.onClick &&
        tooltipVariables?.length !== 0
    ) {
        mouseEventHandlers.click = () => {
            props.onShowPopup(props.index, props.tooltipMapLevel);
        };
    } else if (
        displayModeValue === MapTooltipDisplayMode.onHover &&
        tooltipVariables?.length !== 0
    ) {
        mouseEventHandlers.mouseover = (evt: Leaflet.LeafletMouseEvent) => {
            if (props.hoverInfo === null) {
                props.onShowHoverTooltip(
                    props.index,
                    props.tooltipMapLevel,
                    evt.containerPoint
                );
            }
        };

        mouseEventHandlers.mouseout = () => {
            props.onShowHoverTooltip(null, 0);
        };
    }

    let markerColor: string | undefined | null = props.markerColor;
    if (props.varyMarkerColorByVariable) {
        // If props.markerColorVariableIndex.toString() is not in props.data
        // then the data was not updated yet
        if (
            props.markerColorVariableIndex != null &&
            props.markerColorVariableIndex.toString() in props.data
        ) {
            const colorVariable =
                props.data[props.markerColorVariableIndex.toString()]?.[
                    props.index
                ];
            let customColor: string | undefined;
            if (colorVariable != null) {
                customColor = props.markerValueToColor?.[colorVariable];
            }
            if (customColor != null) {
                markerColor = customColor;
            } else {
                let colorIndex:
                    | number
                    | undefined = props.colorVariableValueToIndex?.get(
                    colorVariable
                );
                if (colorIndex != null) {
                    markerColor = colorList[colorIndex % colorList.length];
                }
            }
        }
    }
    let position = [
        Number((props.data?.[props.latIndex]?.[props.index] ?? NaN) as number),
        Number((props.data?.[props.lonIndex]?.[props.index] ?? NaN) as number),
    ];
    if (Number.isNaN(position[0]) || Number.isNaN(position[1])) return null;

    const flagData = props?.flags?.flagData ?? [];
    let flagValues: string[] = [];
    if (Array.isArray(flagData)) {
        flagData.forEach((data) => {
            if (data) {
                const value =
                    props.data?.[data.variable.value.toString()]?.[
                        props.index!
                    ];
                if (value) flagValues.push(String(value));
            }
        });
    }

    const flagOptions = {
        flagType: props.flags?.type?.value ?? FlagType.Pins,
        flagValues: flagValues,
        textColor: props.flags?.textColor ?? "#000",
        textBackgroundColor: props.flags?.textBackgroundColor,
        flagColor: props.flags?.flagColor,
    };

    const pinOptions = {
        color: markerColor,
        borderColor: props.markerBorderColor ?? "transparent",
    };

    return (
        <Marker
            key={props.index}
            eventHandlers={mouseEventHandlers}
            icon={
                props.findingType === "maps_pins"
                    ? markerIcon(
                          flagOptions,
                          pinOptions,
                          props.markerIcon,
                          props.markerLogoIcon
                      )
                    : markerIconTransparent
            }
            position={[position[0], position[1]]}
        >
            {displayModeValue === MapTooltipDisplayMode.always &&
                tooltipVariables.length !== 0 && (
                    <Tooltip
                        direction="bottom"
                        permanent={true}
                        ref={(ref) => {
                            if (ref != null && ref.getElement() != null) {
                                ref.getElement()!.style.pointerEvents = "auto";
                            }
                        }}
                    >
                        <TooltipContent
                            scale={props.scale}
                            dataIndex={props.index}
                            data={props.data}
                            variables={tooltipVariables}
                            mapId={props.mapId}
                        />
                    </Tooltip>
                )}
        </Marker>
    );
}
