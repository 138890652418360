import React, { Component } from "react";
import FindingItem from "common/insights_components/FindingItem";
import elements from "common/CanvasElements";
import { mainStyle } from "common/MainStyle";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "../CanvasInteractionComponent";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import HtmlElementProps from "../HtmlElementProps";
import styles from "./Dashboards.module.css";
import { minLimitEditMenuWidth, mapViewScale } from "../../Constants";
import Finding, {
    canUpdateFinding,
    isKanbanBoard,
    isMap,
    isNetworkOverMap,
    MapFinding,
} from "common/Finding";
import TablePreview from "./TablePreview";
import modules from "modules/data_exploration_page/modules/module_list.json";
import HamburgerMenu from "../HamburgerMenu";
import {
    CanvasDashboard,
    DashboardVersion,
    DefaultCreatedNodePosition,
    InnerCanvasChanges,
    ItemMetadata,
} from "common/Canvas";
import castToType from "common/utilities/castToType";
import { deleteTable, insertRows } from "common/DataApi";
import remoteModuleId from "common/remoteModuleId";
import DataSyncArrow from "common/DataSyncArrow";
import CanvasPreventPropagationButton from "../../CanvasPreventPropagationButton";
import { ReactComponent as BoxDownIcon } from "icons/box-arrow-in-down-left.svg";
import { ReactComponent as BoxUpIcon } from "icons/box-arrow-up-right.svg";
import { ReactComponent as TableIcon } from "icons/table.svg";
import classNames from "classnames";
import {
    mobileAndTabletBreakpoint,
    openDelayedNodeMenuAfterTouch,
} from "common/utilities/UIResponsiveManager";
import cx from "classnames";
import moveableStyles from "../Moveable.module.css";
import {
    makeMoveable,
    DraggableProps,
    ResizableProps,
    RotatableProps,
    Rotatable,
    Draggable as RMDraggable,
    Resizable as RMResizable,
    OnDrag,
} from "react-moveable";
import { getTransformList } from "common/utilities/parseTransformString";
import Portal from "common/Portal";
import OutsideAlerter from "common/OutsideAlerter";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "modules/canvas_page/Snap";
import {
    CanvasElement,
} from "common/Canvas";

const typeName = "dashboardsState";

// This allows us to have certain dashboard elements so it doesn't have the 10px padding that wraps around the outside
const noPaddingTypes = new Set<string>([
    "maps_pins",
    "maps_heatmap",
    "maps_networkovermap",
    "maps_bubble",
    "maps_choropleth",
    "aicopilot",
]);

interface Props extends HtmlElementProps {
    onExpandCard: (node: CanvasElement ) => void;
    setCurrentEditId: (dashboardId: string | undefined) => void;
    tablePreviewVisible: boolean;
    dashboardEditMenuIsOpened: boolean;
    setDashboardEditMenuIsOpened: (isOpened: boolean) => void;
    deleteSelectionByMetadata: (metadata: ItemMetadata[]) => void;
    themesPopupOpen?: boolean;
    finding?: Finding;
}

interface WrapperProps extends Props {
    onExpandCard: (node: CanvasElement ) => void;
    tablePreviewVisible: boolean;
    finding?: Finding;
    dashboardId: string;
    rootDataTestId: string;
    editMenuIsOpen: boolean;
    disableDrag: boolean;
    setDisableDrag: (disable: boolean) => void;
    onOpenEditMenu: (dashboardId: string | undefined) => void;
    filterIndexInitializer?: number;
    selectedMetadata?: ItemMetadata[];
    prevSelectedMetadata?: ItemMetadata[];
}

interface WrapperState {
    dataSetMenuIsOpen: boolean;
    columnDragActive: boolean;
    focused: boolean;
    pushingData: boolean;
    updatingDashboard: boolean;
    hamburgerMenuOpened: boolean;
    dashboardEditMenuWidth: number;
    tablePreviewMinimized: boolean;
    tablePreviewPosition: { x: number; y: number };
    tablePreviewSize: { width: number; height: number };
}

const Moveable = makeMoveable<DraggableProps & ResizableProps & RotatableProps>(
    [RMDraggable, RMResizable, Rotatable]
);

@observer
class DashboardWrapper extends CanvasInteractionComponent<
    WrapperProps,
    WrapperState
> {
    drag: boolean = false;
    _oldTablePreviewPosition: { x: number; y: number } = { x: 0, y: 0 };
    _oldTablePreviewSize: { width: number; height: number } = {
        width: 0,
        height: 0,
    };
    private previewDashboardId = "";
    private moveableRef = React.createRef<any>();
    private innerRef = React.createRef<HTMLDivElement>();

    constructor(props: WrapperProps) {
        super(props);
        let dashboard = this.props.canvasTreeStore.dashboardsState.get(
            this.props.dashboardId
        )!;
        this.state = {
            pushingData: false,
            columnDragActive: false,
            dataSetMenuIsOpen: dashboard?.finding?.config?.dataScope != null,
            focused: false,
            hamburgerMenuOpened: false,
            dashboardEditMenuWidth: minLimitEditMenuWidth,
            updatingDashboard: false,
            tablePreviewMinimized: false,
            tablePreviewPosition: { x: 0, y: window.innerHeight * 0.75 - 60 },
            tablePreviewSize: { width: 500, height: window.innerHeight * 0.25 },
        };
        this.connectDataset = this.connectDataset.bind(this);
        this.onNewFinding = this.onNewFinding.bind(this);
    }

    componentDidMount(): void {
        let dashboard = this.props.canvasTreeStore.dashboardsState.get(
            this.props.dashboardId
        )!;
        let innerRef = this.innerRef.current;
        if (innerRef != null) {
            innerRef.setAttribute("type", typeName);
            if (dashboard.groupId != null)
                innerRef.setAttribute("groupId", dashboard.groupId);
            else {
                innerRef.removeAttribute("groupId");
            }
            innerRef.setAttribute("id", String(this.props.dashboardId));
            innerRef.setAttribute("data-test-id", this.props.rootDataTestId);
        }

        if (this.props.filterIndexInitializer !== undefined) {
            this.props.onOpenEditMenu(this.props.dashboardId);
        }
    }

    componentDidUpdate(prev: WrapperProps, prevState: WrapperState) {
        if (prev.selectedMetadata !== this.props.selectedMetadata) {
            let dashboard = this.props.canvasTreeStore.dashboardsState.get(
                this.props.dashboardId
            )!;
            let innerRef = this.innerRef.current;
            if (innerRef != null) {
                if (dashboard.groupId != null)
                    innerRef.setAttribute("groupId", dashboard.groupId);
                else {
                    innerRef.removeAttribute("groupId");
                }
            }
        }
        this.moveableRef.current?.updateRect();
    }

    connectDataset(open: boolean) {
        if (!open) {
            let dashboard = this.props.canvasTreeStore.dashboardsState.get(
                this.props.dashboardId
            )!;

            let finding = { ...dashboard.finding! };
            finding = this.resetFindingData(finding);
            this.props.canvasTreeStore.updateDashboardAction(
                this.props.dashboardId,
                {
                    finding: finding,
                }
            );
            this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                this.props.dashboardId,
                null
            );
        }
        this.setState({ dataSetMenuIsOpen: open });
    }

    uploadDataToBackend(finding?: Finding | null) {
        if (
            finding != null &&
            isKanbanBoard(finding) &&
            finding.config.dataScope != null &&
            finding.config.titleVariable != null &&
            finding.config.categoryVariable != null
        ) {
            this.setState({ pushingData: true });
            const metadata = finding.content.data.metadata;
            let data: any = [];
            for (let lane of finding.content.data.lanes) {
                for (let card of lane.cards) {
                    let row = {
                        [finding.config.categoryVariable]: castToType(
                            lane.title,
                            metadata.laneTitleType,
                            metadata.laneTitleFormat
                        ),
                        [finding.config.titleVariable]: castToType(
                            card.title,
                            metadata.titleType,
                            metadata.titleFormat
                        ),
                    };
                    for (let [name, value] of Object.entries(card.variables)) {
                        row[name as any] = castToType(
                            value as any,
                            metadata.variableTypes[name],
                            metadata.variableFormats[name]
                        );
                    }
                    data.push(row);
                }
            }
            let tableOption = {
                label: "",
                value: finding.config.selectedTable!.value,
                data_table_idx: finding.config.dataScope!.value,
                optimized: false,
                condition_id: finding.config.selectedTable!.condition_id,
            };
            deleteTable(
                tableOption,
                this.props.currentModuleId ?? remoteModuleId
            )
                .then(() => {
                    insertRows(
                        tableOption,
                        data,
                        this.props.currentModuleId ?? remoteModuleId
                    )
                        .then(() => {
                            this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                                this.props.dashboardId,
                                null
                            );

                            this.setState({
                                pushingData: false,
                            });
                        })
                        .catch((error) => {
                            this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                                this.props.dashboardId,
                                `Error: ${String(error)}`
                            );

                            this.setState({
                                pushingData: false,
                            });
                        });
                })
                .catch((error) => {
                    this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                        this.props.dashboardId,
                        `Error: ${error}`
                    );

                    this.setState({
                        pushingData: false,
                    });
                });
        }
    }
    onNewFinding(finding: Finding, updateData?: boolean) {
        this.setState({
            columnDragActive: false,
        });
        this.props.canvasTreeStore.updateDashboardAction(
            this.props.dashboardId,
            {
                finding: finding,
            }
        );
        if (updateData) this.updateFinding(finding);
    }

    async updateFinding(finding: Finding) {
        if (!canUpdateFinding(finding)) {
            this.props.canvasTreeStore.updateDashboardAction(
                this.props.dashboardId,
                {
                    finding: finding,
                }
            );
            return;
        }
        let charts = modules.map((module) => module.submodules).flat();
        let chart = charts.find(
            (chart) => chart.name === finding?.config?.journeyName
        );
        if (chart != null) {
            try {
                let api = require(`modules/data_exploration_page/modules/${chart.dir}/ApiV2`);
                this.setState({ updatingDashboard: true });
                let newFinding = await api?.Api?.getData(
                    finding,
                    {},
                    this.props.currentModuleId
                );
                if (
                    (isMap(newFinding) || isNetworkOverMap(newFinding)) &&
                    newFinding.additionalMapsFindings != null
                ) {
                    newFinding.additionalMapsFindings = [
                        ...newFinding.additionalMapsFindings,
                    ];
                    for (
                        let i = 0;
                        i < newFinding.additionalMapsFindings.length;
                        ++i
                    ) {
                        let finding = newFinding.additionalMapsFindings[i];
                        let chart = charts.find(
                            (chart) =>
                                chart.name === finding?.config?.journeyName
                        );
                        if (chart != null) {
                            let api = require(`modules/data_exploration_page/modules/${chart.dir}/ApiV2`);
                            newFinding.additionalMapsFindings[
                                i
                            ] = await api?.Api?.getData(
                                finding,
                                {},
                                this.props.currentModuleId
                            );
                        }
                    }
                }
                this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                    this.props.dashboardId,
                    null
                );
                this.props.canvasTreeStore.updateDashboardAction(
                    this.props.dashboardId,
                    {
                        finding: newFinding,
                    }
                );
                this.setState({ updatingDashboard: false });
            } catch (error) {
                this.setState({ updatingDashboard: false });

                console.error(error);
                this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                    this.props.dashboardId,
                    `Error: ${String(error)}`
                );
            }
        }
    }
    resetFindingData(finding: Finding, clearValues = false): Finding {
        let charts = modules.map((module) => module.submodules).flat();
        let chart = charts.find(
            (chart) => chart.name === finding?.config?.journeyName
        );
        if (chart != null) {
            let api = require(`modules/data_exploration_page/modules/${chart.dir}/ApiV2`);
            let newFinding = api?.Api?.getPreviewFinding(chart.name);
            if (clearValues) {
                for (let dataItem of newFinding.content.data) {
                    dataItem.value = [[]];
                }
            }
            for (let key in finding.content) {
                finding.content[key] = null;
            }
            for (let key in finding.config) {
                (finding.config as any)[key] = null;
            }
            finding.content = {
                ...finding.content,
                ...newFinding.content,
            };
            finding.config = {
                ...finding.config,
                ...newFinding.config,
            };
        }
        return finding;
    }

    render() {
        const {
            canvasViewMode,
            mobileViewWasEdited,
        } = this.props.canvasTreeStore;
        let dashboard = this.props.canvasTreeStore.dashboardsState.get(
            this.props.dashboardId
        )!;
        if (this.props.selectedMetadata) {
            this.previewDashboardId =
                (this.props.selectedMetadata[0]?.id as string) ?? "";
        }
        let dashboardSize = {
            height:
                dashboard.nodeSize[canvasViewMode].height * this.props.scale,
            width: dashboard.nodeSize[canvasViewMode].width * this.props.scale,
        };

        let position = {
            x: dashboard.nodePosition[canvasViewMode].x * this.props.scale,
            y: dashboard.nodePosition[canvasViewMode].y * this.props.scale,
        };

        let colorOptions = dashboard.colorOptions ?? {
            borderShadow: false,
            fillColor: mainStyle
                .getPropertyValue("--slide-data-processing-content-color")
                .trim(),
            borderColor: "",
            borderRadius: 0,
        };

        const selected =
            this.props.selectedMetadata?.length === 1 &&
            this.props.selectedMetadata?.[0]?.id === this.props.dashboardId;

        let refreshButtonStyles = {};
        if (this.props.live) {
            refreshButtonStyles = {
                transition: "opacity .2s ease-out",
                opacity: this.state.focused
                    ? this.state.pushingData
                        ? 0.3
                        : 1
                    : 0,
                pointerEvents: this.state.focused ? "auto" : "none",
            };
        }
        let finding = dashboard.finding;

        const renderTablePreview = (
            currentFinding: Finding | null | undefined
        ) => (
            <>
                <div className={styles.tablePreviewHeader}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <TableIcon style={{ marginRight: 5 }} />
                        <div>
                            {currentFinding?.config?.selectedTable?.label}
                        </div>
                    </div>
                    <BoxDownIcon
                        onClick={() =>
                            this.setState({ tablePreviewMinimized: true })
                        }
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <TablePreview
                    onMinimize={() =>
                        this.setState({
                            tablePreviewMinimized: !this.state
                                .tablePreviewMinimized,
                        })
                    }
                    onClose={() => {
                        this.setState({
                            dataSetMenuIsOpen: false,
                        });
                    }}
                    onDragActive={(active) => {
                        this.setState({
                            columnDragActive: active,
                        });
                    }}
                    finding={currentFinding!}
                    selectedColumns={
                        Array.isArray(currentFinding?.content?.data)
                            ? currentFinding?.content?.data?.filter(
                                (item: {
                                    variableIndex?: number;
                                    value: number;
                                }) => item.variableIndex !== null
                            )
                            : undefined
                    }
                    operationVariable={
                        currentFinding?.config?.operationVariable
                    }
                    canvasTreeStore={this.props.canvasTreeStore}
                    dataScope={currentFinding?.config?.dataScope!}
                    selectedTable={currentFinding?.config?.selectedTable!}
                    conditions={currentFinding?.config?.conditions!}
                    currentModuleId={this.props.currentModuleId}
                />
            </>
        );

        let findings = [dashboard.finding];

        if (
            dashboard?.finding != null &&
            (isMap(dashboard.finding) || isNetworkOverMap(dashboard.finding))
        ) {
            findings = [
                ...findings,
                ...((dashboard.finding as MapFinding)?.additionalMapsFindings ??
                    []),
            ];
        }

        return (
            <OutsideAlerter
                onReject={() => {
                    this.setState({
                        focused: false,
                    });
                }}
            >
                <>
                    <div
                        style={{
                            zIndex: dashboard.zIndex ?? 50,
                            position: "absolute",
                            left: position.x,
                            top: position.y,
                            width: dashboardSize.width,
                            height: dashboardSize.height,
                        }}
                    >
                        {(this.state.focused ||
                            this.state.hamburgerMenuOpened ||
                            // The hamburger menu has to appear when we just
                            // created a chart
                            this.props.currentEditId ===
                            this.props.dashboardId) &&
                            !this.props.live && (
                                <Portal
                                    rootNode={this.props.htmlElementsRootRef}
                                >
                                    <div
                                        data-test-id={`${this.props.rootDataTestId}-menucontainer`}
                                    >
                                        <HamburgerMenu
                                            onToggle={(show) => {
                                                this.setState({
                                                    hamburgerMenuOpened: show,
                                                });
                                            }}
                                            onTouchStart={() => {
                                                this.setState({
                                                    hamburgerMenuOpened: true,
                                                });
                                            }}
                                            customStyle={{
                                                zIndex: 999,
                                                left:
                                                    position.x +
                                                    dashboardSize.width,
                                                top: position.y,
                                                position: "absolute",
                                                width: 20 * this.props.scale,
                                                height: 20 * this.props.scale,
                                            }}
                                            sharedPolicy={
                                                this.props.sharedPolicy
                                            }
                                            scale={this.props.scale}
                                            elementId={this.props.dashboardId}
                                            onDelete={() => {
                                                this.props.showDeletePopup(
                                                    () => {
                                                        this.props.onClearEditing();
                                                        this.props.canvasTreeStore.deleteDashboardAction(
                                                            this.props
                                                                .dashboardId
                                                        );
                                                    }
                                                );
                                            }}
                                            onEdit={(evt) => {
                                                this.props.onOpenEditMenu(
                                                    this.props.dashboardId
                                                );
                                                this.props.setDashboardEditMenuIsOpened(
                                                    true
                                                );
                                            }}
                                            onHide={() => {
                                                let dashboard = this.props.canvasTreeStore.dashboardsState.get(
                                                    this.props.dashboardId
                                                )! as CanvasDashboard;
                                                this.props.canvasTreeStore.updateDashboardAction(
                                                    this.props.dashboardId,
                                                    {
                                                        nodeIsHidden: {
                                                            ...dashboard.nodeIsHidden,
                                                            [canvasViewMode]: true,
                                                        },
                                                    }
                                                );
                                            }}
                                        />
                                    </div>
                                </Portal>
                            )}
                    </div>

                    <div
                        onContextMenu={(evt) => {
                            this.props.onContextMenu(
                                evt,
                                {
                                    id: this.props.dashboardId,
                                    type: typeName,
                                },
                                true
                            );
                        }}
                        style={{
                            position: "absolute",
                            left: position.x,
                            top: position.y,
                            width: dashboardSize.width,
                            height: dashboardSize.height,
                            zIndex: dashboard!.zIndex ?? 50,
                        }}
                        className="selectable-by-pointer"
                        ref={this.innerRef}
                        onClick={() => {
                            this.setState({ focused: true });
                            if (this.props.dashboardEditMenuIsOpened) {
                                this.props.onOpenEditMenu(
                                    this.props.dashboardId
                                );
                            }
                        }}
                        onTouchStart={() => {
                            openDelayedNodeMenuAfterTouch(
                                () => {
                                    this.setState({ focused: true });
                                },
                                () => {
                                    this.setState({ focused: false });
                                }
                            );
                        }}
                    >
                        <div
                            className="dashboard-rect-canvas"
                            style={{
                                boxShadow: colorOptions.borderShadow
                                    ? //? "2px 4px 12px 0 rgba(21, 33, 56, 0.40)"
                                    mainStyle.getPropertyValue(
                                        "--card-box-shadow"
                                    ) // Modified shadow behavior so I can use a tag in Constants.css & DefaultConstants.css?
                                    : "none",
                                backgroundColor: colorOptions.fillColor,
                                borderRadius: colorOptions.borderRadius,
                                border: colorOptions.borderColor
                                    ? `2px solid ${colorOptions.borderColor}`
                                    : "none",
                                padding:
                                    (finding?.type != null &&
                                        noPaddingTypes.has(finding.type)) ||
                                        (this.props.finding?.type != null &&
                                            noPaddingTypes.has(
                                                this.props.finding.type
                                            ))
                                        ? undefined
                                        : 10,
                                width: `calc((100% - 20px) / ${this.props.scale} * ${mapViewScale})`,
                                height: `calc((100% - 20px) / ${this.props.scale} * ${mapViewScale})`,
                                transform: `scale(${this.props.scale / mapViewScale
                                    })`,
                                transformOrigin: "top left",
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                            }}
                        >
                            {this.props.canvasTreeStore.canvasDashboardErrorsState.get(
                                this.props.dashboardId
                            ) && (
                                    <img
                                        title={
                                            this.props.canvasTreeStore.canvasDashboardErrorsState.get(
                                                this.props.dashboardId
                                            ) ?? ""
                                        }
                                        src="/dist/img/error.png"
                                        alt=""
                                        style={{
                                            zIndex: 1,
                                            position: "absolute",
                                            right: 5 * this.props.scale,
                                            transform: `scale(${0.7 * this.props.scale
                                                })`,
                                            transformOrigin: "right top",
                                        }}
                                    />
                                )}
                            {finding != null &&
                                isKanbanBoard(finding) &&
                                finding.config.dataScope != null &&
                                finding.config.titleVariable != null &&
                                finding.config.categoryVariable != null && (
                                    <CanvasPreventPropagationButton>
                                        <div
                                            style={{
                                                width:
                                                    24 * 0.7 * this.props.scale,
                                                height:
                                                    24 * 0.7 * this.props.scale,
                                                position: "absolute",
                                                opacity:
                                                    this.state.pushingData ||
                                                        !(
                                                            "metadata" in
                                                            finding.content.data
                                                        )
                                                        ? 0.3
                                                        : 1,
                                                ...refreshButtonStyles,
                                            }}
                                            title={
                                                "metadata" in
                                                    finding.content.data
                                                    ? "Upload data"
                                                    : "Uploading not supported. Please refresh the data."
                                            }
                                            onClick={(evt) => {
                                                evt.stopPropagation();

                                                if (
                                                    !(
                                                        "metadata" in
                                                        finding?.content.data
                                                    ) ||
                                                    this.state.pushingData
                                                )
                                                    return;
                                                this.uploadDataToBackend(
                                                    finding
                                                );
                                            }}
                                        >
                                            <DataSyncArrow
                                                push
                                                active={this.state.pushingData}
                                            />
                                        </div>
                                    </CanvasPreventPropagationButton>
                                )}
                            <div
                                className="flex-simple-column"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {finding != null ||
                                    this.props.finding != null ? (
                                    <FindingItem
                                        onExpandCard={this.props.onExpandCard}
                                        live={this.props.live}
                                        editable={this.props.editMenuIsOpen}
                                        preview={finding == null}
                                        canvasTreeStore={
                                            this.props.canvasTreeStore
                                        }
                                        disableDashboardDrag={(
                                            disable: boolean
                                        ) => {
                                            this.props.setDisableDrag(disable);
                                        }}
                                        dataSetMenuIsOpen={
                                            this.state.dataSetMenuIsOpen
                                        }
                                        onNewFinding={this.onNewFinding}
                                        columnDragActive={
                                            this.state.columnDragActive
                                        }
                                        finding={finding ?? this.props.finding}
                                        dashboardId={this.props.dashboardId}
                                        currentModuleId={
                                            this.props.currentModuleId
                                        }
                                        scale={this.props.scale}
                                        width={
                                            dashboard.nodeSize[canvasViewMode]
                                                .width
                                        }
                                        height={
                                            dashboard.nodeSize[canvasViewMode]
                                                .height
                                        }
                                        nodePosition={
                                            dashboard.nodePosition[
                                            canvasViewMode
                                            ]
                                        }
                                        selected={selected}
                                    />
                                ) : (
                                    <span
                                        className={styles.editText}
                                        onClick={() =>
                                            this.props.onOpenEditMenu(
                                                this.props.dashboardId
                                            )
                                        }
                                    >
                                        {!this.props.editMenuIsOpen
                                            ? "Edit"
                                            : "Chart Preview will appear here"}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="not-selectable-by-pointer"
                        tabIndex={0}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                    >
                        {this.props.editMenuIsOpen &&
                            this.props.tablePreviewVisible && (
                                <div
                                    className={styles.dragArea}
                                    style={{
                                        left: this.state.dashboardEditMenuWidth,
                                        right: 0,
                                    }}
                                >
                                    {findings.length &&
                                        findings.map((finding) => {
                                            return (
                                                <>
                                                    {!this.state
                                                        .tablePreviewMinimized &&
                                                        finding?.config
                                                            .dataScope !=
                                                        null && (
                                                            <Draggable
                                                                position={
                                                                    this.state
                                                                        .tablePreviewPosition
                                                                }
                                                                onDrag={(
                                                                    e,
                                                                    data
                                                                ) =>
                                                                    this.setState(
                                                                        {
                                                                            tablePreviewPosition: data,
                                                                        }
                                                                    )
                                                                }
                                                                cancel=".cancel"
                                                                bounds={`.${styles.dragArea}`}
                                                                defaultClassName={
                                                                    styles.draggable
                                                                }
                                                            >
                                                                <Resizable
                                                                    size={
                                                                        this
                                                                            .state
                                                                            .tablePreviewSize
                                                                    }
                                                                    onResize={(
                                                                        e,
                                                                        dir,
                                                                        el,
                                                                        delta
                                                                    ) => {
                                                                        const resize = () =>
                                                                            this.setState(
                                                                                {
                                                                                    tablePreviewSize: {
                                                                                        width:
                                                                                            this
                                                                                                ._oldTablePreviewSize
                                                                                                .width +
                                                                                            delta.width,
                                                                                        height:
                                                                                            this
                                                                                                ._oldTablePreviewSize
                                                                                                .height +
                                                                                            delta.height,
                                                                                    },
                                                                                }
                                                                            );

                                                                        if (
                                                                            dir ===
                                                                            "top" ||
                                                                            dir ===
                                                                            "topRight"
                                                                        ) {
                                                                            this.setState(
                                                                                {
                                                                                    tablePreviewPosition: {
                                                                                        x: this
                                                                                            ._oldTablePreviewPosition
                                                                                            .x,
                                                                                        y:
                                                                                            this
                                                                                                ._oldTablePreviewPosition
                                                                                                .y -
                                                                                            delta.height,
                                                                                    },
                                                                                },
                                                                                resize
                                                                            );
                                                                        } else if (
                                                                            dir ===
                                                                            "left" ||
                                                                            dir ===
                                                                            "topLeft"
                                                                        ) {
                                                                            this.setState(
                                                                                {
                                                                                    tablePreviewPosition: {
                                                                                        x:
                                                                                            this
                                                                                                ._oldTablePreviewPosition
                                                                                                .x -
                                                                                            delta.width,
                                                                                        y:
                                                                                            this
                                                                                                ._oldTablePreviewPosition
                                                                                                .y -
                                                                                            delta.height,
                                                                                    },
                                                                                },
                                                                                resize
                                                                            );
                                                                        } else if (
                                                                            dir ===
                                                                            "bottomLeft"
                                                                        ) {
                                                                            this.setState(
                                                                                {
                                                                                    tablePreviewPosition: {
                                                                                        x:
                                                                                            this
                                                                                                ._oldTablePreviewPosition
                                                                                                .x -
                                                                                            delta.width,
                                                                                        y: this
                                                                                            ._oldTablePreviewPosition
                                                                                            .y,
                                                                                    },
                                                                                },
                                                                                resize
                                                                            );
                                                                        } else {
                                                                            resize();
                                                                        }
                                                                    }}
                                                                    onResizeStart={(
                                                                        evt
                                                                    ) => {
                                                                        evt.stopPropagation();
                                                                        this._oldTablePreviewPosition = this.state.tablePreviewPosition;
                                                                        this._oldTablePreviewSize = this.state.tablePreviewSize;
                                                                    }}
                                                                // bounds="parent"
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.tablePreviewContainer
                                                                        }
                                                                    >
                                                                        {renderTablePreview(
                                                                            finding
                                                                        )}
                                                                    </div>
                                                                </Resizable>
                                                            </Draggable>
                                                        )}
                                                    {this.state
                                                        .tablePreviewMinimized && (
                                                            <div
                                                                className={classNames(
                                                                    styles.tablePreviewContainer,
                                                                    styles.tablePreviewContainerMinimized
                                                                )}
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.tablePreviewHeader
                                                                    }
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <TableIcon
                                                                            style={{
                                                                                marginRight: 5,
                                                                            }}
                                                                        />
                                                                        <div>
                                                                            {
                                                                                finding
                                                                                    ?.config
                                                                                    ?.selectedTable
                                                                                    ?.label
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <BoxUpIcon
                                                                        onClick={() => {
                                                                            this.setState(
                                                                                {
                                                                                    tablePreviewMinimized: false,
                                                                                }
                                                                            )
                                                                        }}
                                                                        style={{
                                                                            cursor:
                                                                                "pointer",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                </>
                                            );
                                        })}
                                </div>
                            )}
                    </div>

                    {!this.props.live && this.props.canWrite && (
                        <Moveable
                            className={cx(
                                moveableStyles.moveable,
                                !selected && moveableStyles.moveableNotSelected
                            )}
                            checkInput={true}
                            key={this.props.dashboardId}
                            ref={this.moveableRef}
                            draggable={
                                !this.props.live || this.props.canWrite
                                // We can't check disableDrag here, otherwise
                                // it stays disabled forever
                                // https://eisengardai.atlassian.net/browse/EIS-460
                            }
                            throttleDrag={0}
                            onDragStart={(e) => {
                                // e.inputEvent.path[2] contains resizable div (rank chart)
                                const rankChartResizerClassName =
                                    e.inputEvent?.srcElement?.offsetParent
                                        ?.className;
                                if (
                                    rankChartResizerClassName &&
                                    rankChartResizerClassName?.includes?.(
                                        "resizer"
                                    )
                                ) {
                                    this.props.setDisableDrag(true);
                                    e.stopDrag();
                                    return;
                                } else {
                                    if (this.props.disableDrag) {
                                        this.props.setDisableDrag(false);
                                    }
                                }

                                const clickedElementClassName =
                                    e.inputEvent.target.className;
                                const clickedSvgElementClassName =
                                    e.inputEvent.target.className?.baseVal;
                                if (
                                    clickedElementClassName?.includes?.(
                                        "leaflet"
                                    ) ||
                                    clickedElementClassName?.includes?.(
                                        "cancel-drag"
                                    ) ||
                                    clickedSvgElementClassName?.includes?.(
                                        "cancel-drag"
                                    ) ||
                                    clickedSvgElementClassName?.includes?.(
                                        "leaflet"
                                    ) ||
                                    clickedSvgElementClassName?.includes?.(
                                        "choropleth-map"
                                    )
                                ) {
                                    e.stopDrag();
                                }
                            }}
                            preventClickEventOnDrag={true}
                            onDrag={({
                                target,
                                beforeDelta,
                                beforeDist,
                                left,
                                top,
                                right,
                                bottom,
                                delta,
                                dist,
                                transform,
                                clientX,
                                clientY,
                            }: OnDrag) => {
                                target!.style.left = `${left}px`;
                                target!.style.top = `${top}px`;
                                this.drag = true;
                                let nearestPoints = this.props.onRebuildSnapLine(
                                    {
                                        x: left,
                                        y: top,
                                        width: dashboardSize.width,
                                        height: dashboardSize.height,
                                    },
                                    {
                                        type: typeName,
                                        id: this.props.dashboardId,
                                        groupId: dashboard.groupId,
                                    }
                                );
                                let newPosition = snapElementToPoints(
                                    target.clientWidth,
                                    target.clientHeight,
                                    nearestPoints
                                );
                                if (newPosition.x != null) {
                                    target!.style.left = `${newPosition.x}px`;
                                }
                                if (newPosition.y != null) {
                                    target!.style.top = `${newPosition.y}px`;
                                }
                                this.props.onUpdateSelectionBounds?.();
                            }}
                            onDragEnd={({ target }) => {
                                if (this.drag) {
                                    this.trackNewPerformance(
                                        elements.dashboard
                                    );
                                    this.props.onDeleteSnapLine();
                                    let x =
                                        parseFloat(target.style.left) /
                                        this.props.scale;

                                    let y =
                                        parseFloat(target.style.top) /
                                        this.props.scale;

                                    let deltaX =
                                        x -
                                        dashboard.nodePosition[canvasViewMode]
                                            .x;
                                    let deltaY =
                                        y -
                                        dashboard.nodePosition[canvasViewMode]
                                            .y;

                                    const newNodePosition = {
                                        ...dashboard.nodePosition,
                                        [canvasViewMode]: {
                                            x,
                                            y,
                                        },
                                    };

                                    if (!mobileViewWasEdited) {
                                        if (canvasViewMode === "desktop") {
                                            const desktopSlideWidth = this.props
                                                .canvasTreeStore.slideWidth[
                                                "desktop"
                                            ];
                                            const relativeLeftPoint =
                                                x / desktopSlideWidth;
                                            newNodePosition["mobile"] = {
                                                x:
                                                    DefaultCreatedNodePosition *
                                                    relativeLeftPoint,
                                                y,
                                            };
                                        } else
                                            this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                    }

                                    let changes: InnerCanvasChanges = {};
                                    this.props.canvasTreeStore.updateDashboardAction(
                                        this.props.dashboardId,
                                        {
                                            nodePosition: newNodePosition,
                                            nodeSize: dashboard.nodeSize,
                                        },
                                        false,
                                        changes
                                    );
                                    this.props.canvasTreeStore.updateCanvasSizeAction(
                                        {
                                            x: x,
                                            y: y,
                                            ...dashboardSize,
                                        }
                                    );
                                    this.props.onMoveGroupSelection(
                                        deltaX,
                                        deltaY,
                                        {
                                            id: this.props.dashboardId,
                                            type: typeName,
                                            groupId: dashboard.groupId,
                                        },
                                        false,
                                        changes
                                    );
                                    this.props.canvasTreeStore.saveChangesAction(
                                        changes,
                                        true,
                                        true,
                                        false,
                                        this.props.canvasTreeStore.backgroundsState.toJSON(),
                                        BackgroundMode.Update,
                                        false
                                    );
                                    this.drag = false;
                                    this.setState({ focused: true });
                                    if (mobileAndTabletBreakpoint()) {
                                        openDelayedNodeMenuAfterTouch(
                                            () => {
                                                this.setState({
                                                    focused: true,
                                                });
                                            },
                                            () => {
                                                this.setState({
                                                    focused: false,
                                                });
                                            }
                                        );
                                    }
                                }
                            }}
                            target={this.innerRef}
                            resizable={
                                (!this.props.live || this.props.canWrite) &&
                                selected
                            }
                            rotatable={false}
                            onResize={(e) => {
                                e.target.style.width = `${e.width}px`;
                                e.target.style.height = `${e.height}px`;
                                e.target.style.transform = e.afterTransform;
                            }}
                            onResizeEnd={({ target }) => {
                                this.trackNewPerformance(elements.dashboard);
                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;

                                let transfromOptions = getTransformList(
                                    target.style.transform
                                );
                                let translatePosition = (
                                    transfromOptions["translate"] ?? "0px, 0px"
                                )
                                    .split(",")
                                    .map(
                                        (item) =>
                                            parseFloat(item) / this.props.scale
                                    );

                                let newX =
                                    parseFloat(target.style.left) /
                                    this.props.scale +
                                    translatePosition[0];
                                let newY =
                                    parseFloat(target.style.top) /
                                    this.props.scale +
                                    translatePosition[1];
                                let newWidth =
                                    parseFloat(target.style.width) /
                                    this.props.scale;
                                let newHeight =
                                    parseFloat(target.style.height) /
                                    this.props.scale;

                                let newSize = {
                                    nodePosition: {
                                        ...dashboard.nodePosition,
                                        [canvasViewMode]: {
                                            x: newX,
                                            y: newY,
                                        },
                                    },
                                    nodeSize: {
                                        ...dashboard.nodeSize,
                                        [canvasViewMode]: {
                                            width: newWidth,
                                            height: newHeight,
                                        },
                                    },
                                };

                                target.style.transform = "none";

                                if (!mobileViewWasEdited) {
                                    this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                }

                                this.props.canvasTreeStore.updateDashboardAction(
                                    this.props.dashboardId,
                                    {
                                        ...newSize,
                                    }
                                );

                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        ...newSize.nodePosition[canvasViewMode],
                                        ...newSize.nodeSize[canvasViewMode],
                                    }
                                );
                                this.props.onResize();
                            }}
                        ></Moveable>
                    )}
                </>
            </OutsideAlerter>
        );
    }
}

interface State {
    disableDrag: boolean;
}

@observer
export default class Dashboards extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            disableDrag: false,
        };
        this.openEditMenu = this.openEditMenu.bind(this);
    }
    openEditMenu(dashboardId: string | undefined) {
        this.props.setCurrentEditId?.(dashboardId);
    }

    componentDidMount() {
        //HACK TO AVOID OPENING DASHBOARDS ON NEXUS LOAD
        this.props.setCurrentEditId?.(undefined);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            prevProps.dashboardEditMenuIsOpened !==
            this.props.dashboardEditMenuIsOpened
        )
            if (!this.props.dashboardEditMenuIsOpened)
                this.openEditMenu(undefined);

        // If another user deleted the chart that we're editing
        if (
            this.props.currentEditId != null &&
            this.props.canvasTreeStore.dashboardsState.get(
                this.props.currentEditId
            ) == null
        ) {
            this.props.setCurrentEditId(undefined);
            this.props.setDashboardEditMenuIsOpened(false);
        }
    }

    render() {
        let dashboards = [];
        for (let dashboardId of this.props.canvasTreeStore.dashboardsState.keys()) {
            let dashboard = this.props.canvasTreeStore.dashboardsState.get(
                dashboardId
            )!;
            if (dashboard.version !== DashboardVersion.Second) continue;
            if (
                dashboard.nodeIsHidden &&
                dashboard.nodeIsHidden[
                this.props.canvasTreeStore.canvasViewMode
                ]
            )
                continue;
            let dashboardUi = (
                <DashboardWrapper
                    rootDataTestId={`dashboardV2-${dashboards.length + 1}`}
                    key={dashboardId}
                    {...this.props}
                    dashboardId={dashboardId}
                    currentEditId={this.props.currentEditId}
                    editMenuIsOpen={
                        !this.props.live &&
                        (this.props.themesPopupOpen ?? false) &&
                        this.props.currentEditId === dashboardId
                    }
                    filterIndexInitializer={dashboard.filterIndexInitializer}
                    onOpenEditMenu={this.openEditMenu}
                    onExpandCard={this.props.onExpandCard}
                    disableDrag={this.state.disableDrag}
                    setDisableDrag={(disable) => {
                        this.setState({
                            disableDrag: disable,
                        });
                    }}
                />
            );
            dashboards.push(dashboardUi);
        }
        return <>{dashboards}</>;
    }
}
