import axios from "common/ServerConnection";
import { stringSessionId } from "common/SessionId";

export interface Category {
    title: string;
    id: number;
    thumbnail: string;
    position: number;
}

export function addCategoryApi(title: string, thumbnail: string): Promise<number> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/category_add", {
            title: title,
            thumbnail: thumbnail,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.id!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function deleteCategoryApi(
    categoryId: number,
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/category_delete", {
            id: categoryId,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function editCategoryApi(
    categoryId: number,
    title?: string,
    thumbnail?: string,
    position?: number,
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/category_edit", {
            title: title,
            thumbnail: thumbnail,
            id: categoryId,
            position: position,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getCategoriesApi(): Promise<Category[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            categories?: Category[];
        }>("/api/categories_get")
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(
                    response.data.categories!
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getCategoryApi(categoryId: number): Promise<Category> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            category?: Category;
        }>("/api/category_get", {
            id: categoryId,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(
                    response.data.category!
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
