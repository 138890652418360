import React, { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";

import { colorList } from "../../LineColors";
//import db from "./db";
//import InsightsType from "./InsightsType";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import { PieChartFinding, getValue } from "common/Finding";
// import { useDrop } from "react-dnd";
import OutsideAlerter from "common/OutsideAlerter";
import Portal from "common/Portal";
import ColorPicker from "../ChartColorPicker";
import { previewColors } from "../previewColors";
import { TooltipStyles } from "../../TooltipStyles";
import { LegendEditElement } from "../../LegendEditElement";
import { valueToTitle } from "common/AggregationFunctionsv2";
import EditableAxisItem from "../EditableAxisItem";
import * as d3 from "d3";
import { calculateChartColorPickerPosition } from "../utils";
import Variables from "common/Variables";
import remoteModuleId from "common/remoteModuleId";

// interface DropProps {
//     preview?: boolean;
//     editable?: boolean;
//     dataScopeConnected: boolean;
//     nameColorMapping?: { [key: string]: string };
//     onDrop: (
//         index: number,
//         variableName: string,
//         variableIndex: number
//     ) => void;
//     onClick?: (name: string, index: number, x: number, y: number) => void;
// }
//
// interface CustomizedLabelProps {
//     percent: number;
//     cx: number;
//     cy: number;
//     midAngle: number;
//     outerRadius: number;
//     value: number;
//     name: string;
// }

interface Datum {
    originalName?: string;
    name: string;
    groupName?: string;
    value: number | number[];
    index: number;
    globalIndex: number;
    fill: string;
    variableIndex?: number;
    sum: number;
}

interface SunburstDatum {
    originalName?: string;
    name: string;
    value: number;
    globalIndex: number;
    fill: string;
    sum: number;
}

const getVaryBySum = (
    datasWithIndexAndColor: {
        name: string;
        value: number | number[];
        index: number;
        fill: string;
        variableIndex?: number;
    }[][],
    operationVariable: string
) => {
    let sum = datasWithIndexAndColor
        .map((dataItem) => {
            let sum = dataItem.reduce((accumulator, item) => {
                return accumulator + getValue(item, 0);
            }, 0);
            return sum;
        })
        .reduce((accumulator, item) => {
            return accumulator + item;
        }, 0);
    let sumValue = formatValue(sum, false);
    let sumLabel = `Total ${operationVariable}:`;
    return [sumLabel, sumValue.join("")].join(" ");
};

const getLabel = (
    item: {
        name: string;
        value: number | number[];
        sum: number;
    },
    labelValuesType: string,
    showLegend: boolean,
    showLabels: boolean
) => {
    const isPercentages = labelValuesType === "percentages";
    let arcValue = (isPercentages
        ? (getValue(item, 0) / item.sum) * 100
        : getValue(item, 0)
    )
        .toFixed(0)
        .toString();
    let name =
        item && item.name.length !== 0
            ? item.name[0].toUpperCase() + item.name.slice(1, item.name.length)
            : "";

    if (showLabels) name = `${name}: `;
    if (isPercentages) arcValue += "%";
    if (showLegend) name = "";

    if (showLabels) return `${name}${arcValue}`;
    else return name;
};

interface InnerChartProps {
    config: PieChartFinding["config"];
    data: PieChartFinding["content"]["data"];
    groupNames: PieChartFinding["content"]["groupNames"];
    dataItem: {
        name: string;
        groupName?: string;
        value: number | number[];
        index: number;
        globalIndex: number;
        fill: string;
        variableIndex?: number;
    }[][];
    isVaryByData?: boolean;
    editable?: boolean;
    onShowBarColorMenu: React.Dispatch<
        React.SetStateAction<{
            originalName?: string;
            name: string;
            index: number;
            x: number;
            y: number;
        } | null>
    >;
}

interface TooltipInfo {
    x: number;
    y: number;
    value: number | number[];
    name: string;
    fill: string;
    sum: number;
}

function addLabels(
    labelValuesType: string,
    showLegend: boolean,
    svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
    data: d3.PieArcDatum<Datum>[],
    maxRadius: number,
    arc: d3.Arc<unknown, d3.PieArcDatum<Datum>>,
    showLabels: boolean
): void {
    let outerArc = d3
        .arc<d3.PieArcDatum<Datum>>()
        .innerRadius(maxRadius)
        .outerRadius(maxRadius);

    // Add the polylines between chart and labels:
    if (showLabels || (!showLabels && !showLegend)) {
        svg.selectAll<SVGPolylineElement, d3.PieArcDatum<Datum>>("polyline")
            .data(data)
            .enter()
            .append("polyline")
            .attr("stroke", "black")
            .style("fill", "none")
            .attr("stroke-width", 1)
            .attr("points", (d) => {
                let posA = arc.centroid(d); // line insertion in the slice
                let posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
                let posC = outerArc.centroid(d); // Label position = almost the same as posB
                let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
                posC[0] = maxRadius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
                return [posA.join(","), posB.join(","), posC.join(",")].join(
                    " "
                );
            });
    }

    // Add the polylines between chart and labels:
    svg.selectAll<SVGTextElement, d3.PieArcDatum<Datum>>("text")
        .data(data)
        .enter()
        .append("text")
        .text((d) => getLabel(d.data, labelValuesType, showLegend, showLabels))
        .attr("transform", (d) => {
            let pos = outerArc.centroid(d);
            let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
            pos[0] = maxRadius * 0.99 * (midangle < Math.PI ? 1 : -1);
            return "translate(" + pos + ")";
        })
        .style("text-anchor", (d) => {
            let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
            return midangle < Math.PI ? "start" : "end";
        });
}

const InnerChart = (props: InnerChartProps) => {
    let [showTooltipInfo, setShowTooltipInfo] = useState<TooltipInfo | null>(
        null
    );
    const tooltipFormat = (tooltipInfo: TooltipInfo): string => {
        let value = getValue(tooltipInfo, 0);
        let formattedValue = formatValue(value, false);
        let formattedValueSum = formatValue(
            (value * 100) / tooltipInfo.sum,
            false
        );

        let result = formattedValue[0].concat(formattedValue[1]);
        result = result
            .concat(", ")
            .concat(formattedValueSum[0])
            .concat(formattedValueSum[1])
            .concat("%");
        return result;
    };
    const mouseenter = (
        event: MouseEvent,
        d: d3.PieArcDatum<Datum> | d3.PieArcDatum<SunburstDatum>
    ) => {
        setShowTooltipInfo({
            x: event.x,
            y: event.y,
            value: d.data.value,
            name: d.data.name,
            fill: d.data.fill,
            sum: d.data.sum,
        });
    };
    const mousemove = (event: MouseEvent) => {
        setShowTooltipInfo((showTooltipInfo) => {
            if (showTooltipInfo == null) {
                return null;
            } else {
                return {
                    ...showTooltipInfo,
                    x: event.x + 5,
                    y: event.y + 5,
                };
            }
        });
    };
    const mouseleave = () => {
        setShowTooltipInfo(null);
    };
    let parentSvgRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);
    let tooltipStyle = {
        ...TooltipStyles(
            props.config.tooltipColor,
            props.config.tooltipFontSize
        ),
    };

    const onShowBarColorMenu = props.onShowBarColorMenu;

    useEffect(() => {
        const clickSector = (
            event: MouseEvent,
            d: d3.PieArcDatum<Datum> | d3.PieArcDatum<SunburstDatum>
        ) => {
            const { y } = calculateChartColorPickerPosition(event);
            if (props.editable) {
                onShowBarColorMenu({
                    originalName: d.data.originalName,
                    name: d.data.name,
                    index: d.data.globalIndex,
                    x: event.clientX,
                    y,
                });
            }
        };

        let containerHeight = parentSvgRef.current?.clientHeight ?? 0;
        let containerWidth = parentSvgRef.current?.clientWidth ?? 0;
        d3.select(svgRef.current!).selectAll("*").remove();
        let radius =
            Math.min(containerHeight, containerWidth) / 2 -
            (props.isVaryByData && props.dataItem.length > 1 ? 75 : 50);
        let innerRadius = (radius * props.config.innerRadius) / 100;
        let outerRadius = (radius * props.config.outerRadius) / 100;
        let radiusOffset = outerRadius - innerRadius;

        let svg = d3
            .select(svgRef.current!)
            .attr("width", containerWidth)
            .attr("height", containerHeight)
            .attr(
                "viewBox",
                `${-containerWidth / 2} ${
                    -containerHeight / 2
                } ${containerWidth} ${containerHeight}`
            );

        // Compute the position of each group on the pie:
        if (props.isVaryByData && props.dataItem.length > 1) {
            let dataByValuesLength = props.dataItem[0].length;
            let dataByVariablesLength = props.dataItem.length;
            let data: {
                name: string;
                value: number | number[];
                index: number;
                globalIndex: number;
                fill: string;
                variableIndex?: number;
            }[] = [];
            let valuesData: {
                name: string;
                value: number;
                globalIndex: number;
                fill: string;
            }[] = props.dataItem[0].map((value, index) => {
                let globalIndex =
                    dataByVariablesLength * dataByVariablesLength + index;
                return {
                    name: value.name,
                    value: 0,
                    globalIndex: globalIndex,
                    fill:
                        props.config.nameColorMapping?.[globalIndex] ??
                        colorList[globalIndex % colorList.length],
                };
            });
            let sum = 0;
            for (let i = 0; i < dataByValuesLength; ++i)
                for (let j = 0; j < dataByVariablesLength; ++j) {
                    data.push(props.dataItem[j][i]);
                    sum += getValue(props.dataItem[j][i], 0);
                    valuesData[i].value += getValue(props.dataItem[j][i], 0);
                }
            let pie = d3
                .pie<Datum>()
                .sort(null)
                .value((d) => getValue(d, 0));
            let dataReady = pie(
                data.map((element) => ({ ...element, sum: sum }))
            );
            let arc = d3
                .arc<d3.PieArcDatum<Datum>>()
                .innerRadius(innerRadius) // This is the size of the donut hole
                .outerRadius(outerRadius);
            svg.selectAll<SVGPathElement, d3.PieArcDatum<Datum>>("path")
                .data(dataReady)
                .enter()
                .append("path")
                .attr("d", arc)
                .attr("fill", (d) => d.data.fill)
                .attr("stroke", "white")
                .style("stroke-width", "2px")
                .on("mouseenter", mouseenter)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)
                .on("click", clickSector);
            let sunburstPie = d3
                .pie<SunburstDatum>()
                .sort(null)
                .value((d) => d.value);
            let valuesDataPie = sunburstPie(
                valuesData.map((element) => ({ ...element, sum: sum }))
            );
            let valuesArc = d3
                .arc<d3.PieArcDatum<SunburstDatum>>()
                .innerRadius(innerRadius + radiusOffset) // This is the size of the donut hole
                .outerRadius(outerRadius + radiusOffset);
            svg.append("g")
                .selectAll<SVGPathElement, d3.PieArcDatum<Datum>>("path")
                .data(valuesDataPie)
                .enter()
                .append("path")
                .attr("d", valuesArc)
                .attr("fill", (d) => d.data.fill)
                .attr("stroke", "white")
                .style("stroke-width", "2px")
                .on("mouseenter", mouseenter)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)
                .on("click", clickSector);
            addLabels(
                props.config.labelValuesType ?? "percentages",
                props.config.showLegend ?? true,
                svg,
                dataReady,
                outerRadius + radiusOffset + 5,
                arc,
                props.config.showLabels ?? true
            );
        } else {
            for (let i = 0; i < props.dataItem.length; ++i) {
                let item = props.dataItem[i];
                let sum = item.reduce(
                    (accumulator, subItem) =>
                        accumulator + getValue(subItem, 0),
                    0
                );

                let pie = d3
                    .pie<Datum>()
                    .sort(null)
                    .value((d) => getValue(d, 0));
                let dataReady = pie(
                    item.map((element) => ({ ...element, sum: sum }))
                );
                let arc = d3
                    .arc<d3.PieArcDatum<Datum>>()
                    .innerRadius(innerRadius) // This is the size of the donut hole
                    .outerRadius(outerRadius);
                svg.selectAll<SVGPathElement, d3.PieArcDatum<Datum>>("path")
                    .data(dataReady)
                    .enter()
                    .append("path")
                    .attr("d", arc)
                    .attr("fill", (d) => d.data.fill)
                    .attr("stroke", "white")
                    .style("stroke-width", "2px")
                    .on("mouseenter", mouseenter)
                    .on("mousemove", mousemove)
                    .on("mouseleave", mouseleave)
                    .on("click", clickSector);
                addLabels(
                    props.config.labelValuesType ?? "percentages",
                    props.config.showLegend ?? true,
                    svg,
                    dataReady,
                    outerRadius + 5,
                    arc,
                    props.config.showLabels ?? true
                );
            }
        }
    }, [
        parentSvgRef.current?.clientHeight,
        parentSvgRef.current?.clientWidth,
        props.data,
        props.dataItem,
        props.config,
        props.config.tooltipColor,
        props.config.tooltipFontSize,
        props.config.innerRadius,
        props.config.outerRadius,
        props.config.nameColorMapping,
        props.config.labelValuesType,
        props.config.showLegend,
        props.config.showLabels,
        props.isVaryByData,
        props.editable,
        onShowBarColorMenu,
    ]);

    return (
        <div
            ref={parentSvgRef}
            style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                // Resizing does not work properly
                // without overflow: "hidden"
                overflow: "hidden",
            }}
        >
            {showTooltipInfo && (
                <Portal rootNode={document.body}>
                    <div
                        style={{
                            zIndex: 100000,
                            position: "absolute",
                            top: showTooltipInfo.y,
                            left: showTooltipInfo.x + 5,
                            padding: "10px",
                            ...tooltipStyle.contentStyle,
                            display: "flex",
                            flexDirection: "column",
                            pointerEvents: "none",
                        }}
                    >
                        <span
                            className="unselectable"
                            style={{
                                ...tooltipStyle.itemStyle,
                                color: showTooltipInfo.fill,
                            }}
                        >
                            {`${showTooltipInfo.name} : ${tooltipFormat(
                                showTooltipInfo
                            )}`}
                        </span>
                    </div>
                </Portal>
            )}
            <svg ref={svgRef} />
        </div>
    );
};

interface Props {
    data: PieChartFinding["content"]["data"];
    varyByData: PieChartFinding["content"]["varyByData"];
    additionalVaryByData: PieChartFinding["content"]["additionalVaryByData"];
    config: PieChartFinding["config"];
    groupNames: PieChartFinding["content"]["groupNames"];
    editable?: boolean;
    columnDragActive?: boolean;
    preview?: boolean;
    onChangeData?: (
        data: PieChartFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: PieChartFinding["config"],
        updateData?: boolean
    ) => void;
    currentModuleId?: number;
}

function PieChartD3(props: Props) {
    let transformedDataLength: number = useMemo(() => {
        if (props.varyByData != null) {
            return 1 + (props.additionalVaryByData?.length ?? 0);
        } else {
            return 1;
        }
    }, [props.varyByData, props.additionalVaryByData]);
    let { config } = props;
    let showTotals = config.showTotals !== undefined ? config.showTotals : true;

    let dataVariables = Variables(
        props.config.dataScope?.value,
        props.currentModuleId ?? remoteModuleId
    ).dataVariables;

    let [showBarColorMenu, setShowBarColorMenu] = React.useState<{
        originalName?: string;
        name: string;
        index: number;
        x: number;
        y: number;
    } | null>(null);

    let legendsColor =
        config.ticksColor ??
        mainStyle.getPropertyValue("--graphs-axes-text-color");
    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData = Array.from(props.data);
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        props.onChangeData?.(newData, true);
    };
    let linkedVariables = props.data.filter(
        (item) => item.variableIndex != null
    );
    let currentEditVariableIndex = props.data.findIndex(
        (item) => item.variableIndex == null
    );
    let datasWithIndexAndColor: {
        index: number;
        globalIndex: number;
        fill: string;
        name: string;
        groupName?: string;
        value: number | number[];
        variableIndex?: number;
        originalName?: string;
    }[][] = useMemo(() => {
        let transformedData: Exclude<
            PieChartFinding["content"]["additionalVaryByData"],
            null | undefined
        >;
        if (props.varyByData != null) {
            if (props.additionalVaryByData != null) {
                transformedData = [props.varyByData].concat(
                    props.additionalVaryByData
                );
            } else {
                transformedData = [props.varyByData];
            }
        } else {
            transformedData = [props.data];
        }
        let groupNames = props.groupNames ?? [""];
        let colors = { ...config?.nameColorMapping };
        if (props.varyByData)
            return transformedData.map((dataItem, groupIndex) =>
                dataItem.map((item, index) => {
                    let globalIndex =
                        transformedData.length === 1
                            ? groupIndex * dataItem.length + index
                            : groupIndex;

                    const key = globalIndex.toString();
                    const colorsKey = item.originalName as typeof colors;
                    if (
                        key in (config?.nameColorMapping ?? {}) &&
                        !colors[colorsKey]
                    ) {
                        colors[colorsKey] = config.nameColorMapping[key];
                    }
                    return {
                        ...item,
                        index: index,
                        globalIndex: globalIndex,
                        fill:
                            colors?.[item.originalName ?? globalIndex] ??
                            colorList[globalIndex % colorList.length],
                    };
                })
            );
        else
            return groupNames.map((groupName, groupIndex) =>
                transformedData[0].map((item, index) => {
                    let sectorIsNotInitialized =
                        props.editable &&
                        config.dataScope != null &&
                        item.variableIndex == null;

                    const globalIndex =
                        groupIndex * transformedData[0].length + index;
                    return {
                        ...item,
                        index: index,
                        groupName: groupName,
                        value: getValue(item, groupIndex),
                        globalIndex,
                        fill: props.preview
                            ? previewColors[index % previewColors.length]
                            : sectorIsNotInitialized
                            ? "#EFF5FA"
                            : config.nameColorMapping?.[
                                  item.originalName ?? globalIndex
                              ] ??
                              (item.variableIndex == null
                                  ? previewColors[
                                        ((groupIndex *
                                            transformedData[0].length +
                                            index) %
                                            previewColors.length) %
                                            previewColors.length
                                    ]
                                  : colorList[
                                        (groupIndex *
                                            transformedData[0].length +
                                            index) %
                                            colorList.length
                                    ]),
                    };
                })
            );
    }, [
        props.data,
        props.varyByData,
        props.additionalVaryByData,
        props.groupNames,
        props.editable,
        props.preview,
        config.nameColorMapping,
        config.dataScope,
    ]);
    let plot = (
        <div
            style={{
                display: "flex",
                height: "100%",
                width: "100%",
                alignItems: "center",
            }}
        >
            {props.config.showVariableLabels && (
                <div className="flex-simple-column">
                    {props.data.map((item, index) => {
                        return (
                            <div style={{ marginTop: index !== 0 ? 16 : 0 }}>
                                <EditableAxisItem
                                    width={150}
                                    onChange={(value) => {
                                        let newData = Array.from(props.data);
                                        newData[index].name = value;
                                        props.onChangeData?.(newData, true);
                                    }}
                                    color={"#333333"}
                                    currentEditVariableIndex={
                                        currentEditVariableIndex
                                    }
                                    editable={props.editable}
                                    index={index}
                                    vertical={false}
                                    name={
                                        item.name === item.originalName &&
                                        item.variableIndex != null
                                            ? dataVariables[item.variableIndex]
                                                  ?.name ?? item.name
                                            : item.name
                                    }
                                    onDrop={linkVariable}
                                />
                            </div>
                        );
                    })}
                </div>
            )}

            {props.varyByData == null &&
                datasWithIndexAndColor.map((dataItem, groupIndex) => {
                    const { operationVariable } = props.config;
                    let value: string | number = 0;
                    if (
                        operationVariable === "sum" ||
                        operationVariable === "mean"
                    ) {
                        value = dataItem.reduce((accumulator, item) => {
                            return accumulator + getValue(item, 0);
                        }, 0);
                    } else if (operationVariable === "count_distinct") {
                        value = dataItem.length;
                    }
                    if (operationVariable === "mean") {
                        value /= dataItem.length;
                    }

                    value = formatValue(value, false).join("");
                    let sumLabel = `Total ${valueToTitle[operationVariable]}:`;

                    return (
                        <div
                            style={{
                                overflow: "hidden",
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                                height: "100%",
                                width: `calc(100%/${datasWithIndexAndColor.length})`,
                            }}
                        >
                            {/* NEVER pass a lambda function as onShowBarColorMenu - it will cause the chart to re-render every time*/}
                            <InnerChart
                                onShowBarColorMenu={setShowBarColorMenu}
                                data={props.data}
                                config={props.config}
                                groupNames={props.groupNames}
                                dataItem={[dataItem]}
                                editable={props.editable}
                            />
                            {(config.showLegend ?? true) && (
                                <div
                                    className="my-row"
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        flexWrap: "wrap",
                                        height: 20,
                                        justifyContent: "center",
                                    }}
                                >
                                    {dataItem.map((item, index) => (
                                        <LegendEditElement
                                            allowColorChange={props.editable}
                                            disallowTextChange
                                            textSize={config.legendSize}
                                            text={
                                                item.name ===
                                                    item.originalName &&
                                                item.variableIndex != null
                                                    ? dataVariables[
                                                          item.variableIndex
                                                      ]?.name ?? item.name
                                                    : item.name
                                            }
                                            color={item.fill}
                                            textColor={legendsColor}
                                            onFinishColor={(color) => {
                                                let nameColorMapping = {
                                                    ...config.nameColorMapping,
                                                };
                                                nameColorMapping[
                                                    item.originalName ??
                                                        groupIndex *
                                                            dataItem.length +
                                                            index
                                                ] = color;
                                                props.onChangeConfig?.({
                                                    ...config,
                                                    nameColorMapping: nameColorMapping,
                                                });
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                            {showTotals && (
                                <span
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        bottom: 0,
                                        color: legendsColor,
                                        margin: 10,
                                        textAlign: "center",
                                        fontFamily: "Roboto",
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        wordWrap: "break-word",
                                    }}
                                >
                                    {[
                                        props.groupNames?.[groupIndex] ?? "",
                                        sumLabel,
                                        value,
                                    ].join(" ")}
                                </span>
                            )}
                        </div>
                    );
                })}
            {props.varyByData != null && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: `100%`,
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    {/* NEVER pass a lambda function as onShowBarColorMenu - it will cause the chart to re-render every time*/}
                    <InnerChart
                        onShowBarColorMenu={setShowBarColorMenu}
                        data={props.data}
                        config={props.config}
                        isVaryByData
                        groupNames={props.groupNames}
                        dataItem={datasWithIndexAndColor}
                        editable={props.editable}
                    />
                    {(config.showLegend ?? true) && (
                        <div
                            className="my-row"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                flexWrap: "wrap",
                                height: 20,
                                justifyContent: "center",
                            }}
                        >
                            {transformedDataLength === 1 &&
                                datasWithIndexAndColor.map(
                                    (dataItem, groupIndex) =>
                                        dataItem.map((item, index) => (
                                            <LegendEditElement
                                                allowColorChange={
                                                    props.editable
                                                }
                                                disallowTextChange
                                                textSize={config.legendSize}
                                                text={
                                                    item.name ===
                                                        item.originalName &&
                                                    item.variableIndex != null
                                                        ? dataVariables[
                                                              item.variableIndex
                                                          ]?.name ?? item.name
                                                        : item.name
                                                }
                                                color={item.fill}
                                                textColor={legendsColor}
                                                onFinishColor={(color) => {
                                                    let nameColorMapping = {
                                                        ...config.nameColorMapping,
                                                    };
                                                    let globalIndex =
                                                        transformedDataLength ===
                                                        1
                                                            ? groupIndex *
                                                                  dataItem.length +
                                                              index
                                                            : groupIndex;
                                                    nameColorMapping[
                                                        item.originalName ??
                                                            globalIndex
                                                    ] = color;
                                                    props.onChangeConfig?.({
                                                        ...config,
                                                        nameColorMapping: nameColorMapping,
                                                    });
                                                }}
                                            />
                                        ))
                                )}
                            {transformedDataLength > 1 &&
                                linkedVariables.map((linkedVariable, index) => (
                                    <LegendEditElement
                                        allowColorChange={props.editable}
                                        disallowTextChange
                                        textSize={config.legendSize}
                                        text={
                                            linkedVariable.name ===
                                                linkedVariable.originalName &&
                                            linkedVariable.variableIndex != null
                                                ? dataVariables[
                                                      linkedVariable
                                                          .variableIndex
                                                  ]?.name ?? linkedVariable.name
                                                : linkedVariable.name
                                        }
                                        color={
                                            props.config?.nameColorMapping?.[
                                                index
                                            ] ??
                                            colorList[index % colorList.length]
                                        }
                                        textColor={legendsColor}
                                        onFinishColor={(color) => {
                                            let nameColorMapping = {
                                                ...config.nameColorMapping,
                                            };
                                            let globalIndex = index;
                                            nameColorMapping[
                                                globalIndex
                                            ] = color;
                                            props.onChangeConfig?.({
                                                ...config,
                                                nameColorMapping: nameColorMapping,
                                            });
                                        }}
                                    />
                                ))}
                            {transformedDataLength > 1 &&
                                datasWithIndexAndColor[0].map(
                                    (variable, index) => {
                                        let globalIndex =
                                            transformedDataLength *
                                                datasWithIndexAndColor.length +
                                            index;
                                        return (
                                            <LegendEditElement
                                                allowColorChange={
                                                    props.editable
                                                }
                                                disallowTextChange
                                                textSize={config.legendSize}
                                                text={
                                                    variable.name ===
                                                        variable.originalName &&
                                                    variable.variableIndex !=
                                                        null
                                                        ? dataVariables[
                                                              variable
                                                                  .variableIndex
                                                          ]?.name ??
                                                          variable.name
                                                        : variable.name
                                                }
                                                color={
                                                    props.config
                                                        ?.nameColorMapping?.[
                                                        globalIndex
                                                    ] ?? colorList[globalIndex]
                                                }
                                                textColor={legendsColor}
                                                onFinishColor={(color) => {
                                                    let nameColorMapping = {
                                                        ...config.nameColorMapping,
                                                    };
                                                    nameColorMapping[
                                                        globalIndex
                                                    ] = color;
                                                    props.onChangeConfig?.({
                                                        ...config,
                                                        nameColorMapping: nameColorMapping,
                                                    });
                                                }}
                                            />
                                        );
                                    }
                                )}
                        </div>
                    )}
                    {showTotals &&
                        props.data.find((item) => item.variableIndex != null) !=
                            null && (
                            <span
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    bottom: 0,
                                    color: legendsColor,
                                    margin: 10,
                                    textAlign: "center",
                                    fontFamily: "Roboto",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    wordWrap: "break-word",
                                }}
                            >
                                {getVaryBySum(
                                    datasWithIndexAndColor,
                                    props.config.operationVariable!
                                )}
                            </span>
                        )}
                </div>
            )}

            {showBarColorMenu && (
                <Portal rootNode={document.body}>
                    <OutsideAlerter
                        onReject={() => {
                            setShowBarColorMenu(null);
                        }}
                    >
                        <div
                            style={{
                                zIndex: 100000000,
                                position: "absolute",
                                left: showBarColorMenu.x,
                                top: showBarColorMenu.y,
                            }}
                        >
                            <ColorPicker
                                enableAlpha={true}
                                width={"220px"}
                                color={
                                    config.nameColorMapping?.[
                                        showBarColorMenu.originalName ??
                                            showBarColorMenu.index
                                    ] ??
                                    colorList[
                                        showBarColorMenu.index %
                                            colorList.length
                                    ]
                                }
                                onChange={(color) => {
                                    let nameColorMapping = {
                                        ...config.nameColorMapping,
                                    };
                                    nameColorMapping[
                                        showBarColorMenu?.originalName ??
                                            showBarColorMenu!.index
                                    ] = color;
                                    props.onChangeConfig?.({
                                        ...config,
                                        nameColorMapping: nameColorMapping,
                                    });
                                }}
                            />
                        </div>
                    </OutsideAlerter>
                </Portal>
            )}
        </div>
    );
    return plot;
}

export default observer(PieChartD3);
