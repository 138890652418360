import React from "react";
import CompareVariablesChart from "common/graphics/CompareVariablesChart";

function MainComponent(props) {
	let finding = props.finding;

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
			}}
		>
			<CompareVariablesChart
				data={finding.content.data}
				error={finding.content.error}
				dataKeys={finding.content.dataKeys}
				config={finding.config}
			/>
		</div>
	);
}

export { MainComponent };