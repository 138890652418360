import React, { Component } from "react";
import Instrumentation from "common/Instrumentation";
import OutsideAlerter from "./OutsideAlerter";
import sections from "sections.json";
import PasswordChangeForm from "./PasswordChangeForm";

interface Props {
    onClose: () => void;
    mandatory?: boolean;
    section?: keyof typeof sections;
    userName?: string;
    reset?: boolean;
}

interface State {}

class PasswordChangePopup extends Component<Props, State> {
    private performance: Date | null;

    constructor(props: Props) {
        super(props);
        this.state = {};

        this.performance = null;
    }

    componentDidUpdate(): void {
        if (this.props.section != null && this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction(this.props.section, timeMs);
        }
    }

    render() {
        // let height = this.props.reset ? 300 : 350;
        let width = 450;

        const top = 60;

        return (
            <OutsideAlerter
                onReject={() => {
                    if (!this.props.mandatory) this.props.onClose();
                }}
            >
                <div
                    className="dashboard-rect"
                    style={{
                        overflow: "visible",
                        position: "absolute",
                        top: top,
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: 10,
                        left: (window.innerWidth - width) / 2,
                        width: width,
                        zIndex: 100000000,
                    }}
                >
                    <PasswordChangeForm {...this.props} />
                </div>
            </OutsideAlerter>
        );
    }
}

export default PasswordChangePopup;
