import React, { useState } from "react";

import { colorList } from "../LineColors";
//import db from "./db";
//import InsightsType from "./InsightsType";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";
import CustomizedAxisTick from "../CustomizedAxisTick";
import { SingleLineAxisTick } from "../AxesTicks";
import _ from "lodash";
import { ganttPreviewColors } from "./ganttPreviewColors";
import {
    LabelList,
    BarChart,
    Bar,
    Cell,
    XAxis,
    CartesianGrid,
    YAxis,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
    ReferenceLine,
} from "recharts";
import { defaultGanttChartFilter, GanttChartFinding } from "common/Finding";
import EditableAxisItem from "./EditableAxisItem";
import { displayModeToDiffType, getDatesInRange, timeLabelFormatter } from "../TimeChartUtils";
import { TooltipStyles } from "../TooltipStyles";
import { getTextWidthCanvas } from "common/utilities/MeasureText";
import { GanttChartDateIntervalType } from "common/Canvas";
import { formatTimeForCharts } from "common/utilities/TimeFormatUtils";
import { calculateChartColorPickerPosition, clipText, getTextWidth } from "./utils";
import Popup from "reactjs-popup";
import { chartColorPickerPopupStyles } from "common/Constants";
import ColorPicker from "./ChartColorPicker";
import { dateToUtc } from "common/MonthData";

interface Props {
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    data: GanttChartFinding["content"]["data"];
    colorBy: GanttChartFinding["content"]["colorBy"];
    config: GanttChartFinding["config"];
    onChangeData?: (
        data: GanttChartFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: GanttChartFinding["config"],
        updateData?: boolean
    ) => void;
}

function formatter(value: string | number) {
    if (typeof value === "string" || typeof value === "number") {
        let formattedValue = formatValue(value, false);

        let result = formattedValue[0].concat(formattedValue[1]);
        return result;
    }
    return value;
}

export default function GanttChart(props: Props) {
    let [showColorPicker, setShowColorPicker] = useState<{
        x: number,
        y: number,
        index: number,
    } | null>(null);

    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData = Array.from(props.data);

        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        props.onChangeData?.(newData, true);
    };

    const getCellColor =(index: number) => {
        return (props.colorBy
            ? ganttVariableColors[
                  props.colorBy[index]
              ]
            : undefined) ??
        props.config.nameColorMapping?.[
            index
        ] ??
        (props.data[0].variableIndex == null
            ? ganttPreviewColors[
                  index %
                      ganttPreviewColors.length
              ]
            : colorList[
                  index % colorList.length
              ]);
    }

    const dateIntervalType = props.config.filter?.dateIntervalType ?? defaultGanttChartFilter.dateIntervalType;
    const filterDateStart = props.config.filter?.date?.start;
    const filterDateEnd = props.config.filter?.date?.end;

    const limitDateStart = new Date(filterDateStart).getTime();
    const limitDateStartTimestamp = Date.parse(filterDateStart) / 1000;
    const limitDateEnd = new Date(filterDateEnd).getTime();
    const limitDateEndTimestamp = Date.parse(filterDateEnd) / 1000;

    let onChangeName = (index: number, value: string) => {
        let newData = Array.from(props.data);
        newData[index].name = value;
        props.onChangeData?.(newData);
    };
    let labelsColor =
        props.config.labelsColor ??
        mainStyle.getPropertyValue("--graphs-axes-text-color").trim();
    let ticksColor =
        props.config.ticksColor ??
        mainStyle.getPropertyValue("--graphs-axes-text-color").trim();
    let ticksSize =
        props.config.ticksSize ??
        mainStyle.getPropertyValue("--graphs-axes-size").trim();
    let modifiedData = [];
    let seriesLength = props.data[0]?.value?.length;
    for (let i = 0; i < seriesLength; ++i) {
        let startTime = props.data[1].value[i] as number;
        let endTime = props.data[2].value[i] as number;
        if (!startTime) continue;
        if (!endTime) continue;

        let leftTime = Math.min(
            startTime,
            endTime
        );
        let rightTime = Math.max(
            startTime,
            endTime
        );

        // If "date" filter was chosen
        // Check if date less than start limit
        if (filterDateStart && limitDateStartTimestamp > 0) {
            if (new Date(rightTime * 1000).getTime() < limitDateStart) {
                rightTime = limitDateStartTimestamp;
            }

            if (new Date(leftTime * 1000).getTime() < limitDateStart) {
                leftTime = limitDateStartTimestamp;
            }
        }

        // Check if date more than end limit
        if (filterDateEnd && limitDateEndTimestamp > 0) {
            if (new Date(rightTime * 1000).getTime() > limitDateEnd) {
                rightTime = limitDateEndTimestamp;
            }

            if (new Date(leftTime * 1000).getTime() > limitDateEnd) {
                leftTime = limitDateEndTimestamp;
            }
        }

        modifiedData.push({
            name: props.data[0].value[i],
            "%gantt_interval": [leftTime, rightTime],
        });
    }

    let currentEditVariableIndex: number | undefined = undefined;
    if (props.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = props.data.findIndex(
            (item) => item.variableIndex == null
        );
    }
    let displayMode: keyof typeof displayModeToDiffType | "Date" =
        props.config.displayMode ?? "Date";
    let dateFormat = {
        year: true,
        month: true,
        day: true,
    };

    let timestamps: number[] = modifiedData
        .map((item) => item["%gantt_interval"])
        .flat() as number[];
    let maxTimestamp = Math.max(...timestamps);
    let minTimestamp = Math.min(...timestamps);
    let chunk = 7 * 24 * 60 * 60;
    if (displayMode === "Day Count") chunk = 1 * 24 * 60 * 60;
    if (displayMode === "Year Count") chunk = 365 * 24 * 60 * 60;
    if (displayMode === "Month Count") chunk = 30 * 24 * 60 * 60;

    let intervals = _.range(minTimestamp, maxTimestamp, chunk);
    if (intervals[intervals.length - 1] < maxTimestamp) {
        intervals.push(maxTimestamp);
    }

    const dates = getDatesInRange(
        new Date(intervals[0] * 1000),
        new Date(filterDateEnd ? filterDateEnd : intervals[intervals.length - 1] * 1000),
        dateIntervalType.value,
    )
    const lastDate = new Date(dates[dates.length - 1] * 1000);

    switch(dateIntervalType.value) {
        case GanttChartDateIntervalType.Week:
            dates.push(Date.parse(new Date(lastDate.setDate(lastDate.getDate() + 7)).toLocaleDateString()) / 1000);
            break;
        case GanttChartDateIntervalType.Month:
            dates.push(Date.parse(new Date(lastDate.setMonth(lastDate.getMonth() + 1)).toLocaleDateString()) / 1000);
            break;
        case GanttChartDateIntervalType.Quarter:
            dates.push(Date.parse(new Date(lastDate.setMonth(lastDate.getMonth() + 3)).toLocaleDateString()) / 1000);
            break;
        case GanttChartDateIntervalType.Year:
            dates.push(Date.parse(new Date(lastDate.setFullYear(lastDate.getFullYear() + 1)).toLocaleDateString()) / 1000);
            break;
    }

    intervals = dates;
    
    let starts = modifiedData.map((item) => item["%gantt_interval"][0]);
    intervals.sort();

    let ganttVariableColors: { [key: string | number]: string } = {};
    let colorListIndex = 0;
    if (props.colorBy != null) {
        for (let value of props.colorBy) {
            if (value != null && !(value in ganttVariableColors)) {
                ganttVariableColors[value] =
                    colorList[colorListIndex % colorList.length];
                colorListIndex += 1;
            }
        }
    }
    let widths = modifiedData.map((item) => getTextWidthCanvas(String(item.name), "Arial", ticksSize, "normal"));
    let maxYaxisWidth = Math.max(...widths);
    if (!(props.config.showYAxisName ?? true)){
        maxYaxisWidth = 0;
    }

    // Set value for referenceLine to show today's date
    const today = dateToUtc(new Date()).setHours(0, 0, 0, 0);
    let referenceLineTimestamp = null;
    if (props.config.showCurrentDateLine) {
        referenceLineTimestamp = intervals.find(timestamp => {
            return today === dateToUtc(new Date(timestamp * 1000)).setHours(0, 0, 0, 0);
        })
    }

    const CustomizedLabel = (props: any) => {
        const {x, y, name, width} = props;
        const text = formatter(name);
        const textWidth = getTextWidth(text);
        const newText = clipText(
            text,
            textWidth,
            width - 35,
            "...",
        );

        return (
            <text
                x={x}
                y={y + 16}
                dx={5}
                fontSize={16}
                fill={labelsColor}
                textAnchor="left"
            >
                {newText}
            </text>
        )
      };

    let plot = (
        <div
        className="flex-simple-column"
        style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
        }}
    >
        <div style={{ marginTop: 10, height: "100%", overflow: "hidden" }}>
            <ResponsiveContainer width="100%" height="99%">
                <BarChart
                    barCategoryGap={5}
                    layout="vertical"
                    data={modifiedData}
                    margin={{
                        top: 10,
                        left: 50,
                        right: 50,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid
                        stroke={
                            props.config.axesLinesColor ??
                            mainStyle.getPropertyValue(
                                "--graphs-stroke-color"
                            )
                        }
                        horizontal={false}
                        strokeWidth={1}
                    />
                    <XAxis
                        domain={["dataMin", "dataMax"]}
                        stroke={
                            props.config.axesLinesColor ??
                            mainStyle.getPropertyValue(
                                "--graphs-stroke-color"
                            )
                        }
                        orientation="bottom"
                        type="number"
                        ticks={intervals}
                        tickLine={false}
                        axisLine={false}
                        tick={
                            <SingleLineAxisTick
                                fontSize={ticksSize}
                                axesColor={ticksColor}
                                dx={16}
                                dy={8}
                                angle={0}
                                formatValue={(value: number) => {
                                    return timeLabelFormatter(
                                        value,
                                        true,
                                        dateFormat,
                                        displayMode,
                                        minTimestamp
                                    );
                                }}
                            />
                        }
                    />
                    <YAxis
                        width={maxYaxisWidth}
                        type="category"
                        dataKey="name"
                        axisLine={false}
                        tickLine={false}
                        tick={
                            <CustomizedAxisTick
                                axesColor={ticksColor}
                                unit={undefined}
                                formatValues={true}
                                truncValues={false}
                                fontSize={ticksSize}
                                dx={0}
                                dy={0}
                            />
                        }
                    />
                    <Bar
                        isAnimationActive={false}
                        dataKey="%gantt_interval"
                        label={(props.config?.showYAxisName ?? true) ? <></> : <CustomizedLabel />}
                    >
                        {modifiedData.map((_entry, index) => (
                            <Cell
                                key={index}
                                onClick={(evt) => {
                                    const { y } = calculateChartColorPickerPosition(evt);
                                    setShowColorPicker({
                                        x: evt.clientX + 5,
                                        y: y + 5,
                                        index,
                                    });
                                }}
                                fill={getCellColor(index)}
                            />
                        ))}
                    </Bar>
                    {sections._Global.watermark != null && (
                        <text
                            x="50%"
                            y="30%"
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill={mainStyle.getPropertyValue(
                                "--content-secondary-text-color"
                            )}
                            fontSize={"4rem"}
                            style={{
                                opacity: 0.1,
                            }}
                        >
                            {sections._Global.watermark}
                        </text>
                    )}
                    <RechartsTooltip
                        cursor={false}
                        labelFormatter={formatter}
                        formatter={(
                            value: number[],
                            name: string,
                            _props: any
                        ) => {
                            let formattedValue = `${formatTimeForCharts(
                                value[0]
                            )}-${formatTimeForCharts(value[1])}`;
                            return [formattedValue, "interval"];
                        }}
                        {...TooltipStyles(props.config.tooltipColor, props.config.tooltipFontSize)}
                    />
                    {referenceLineTimestamp && <ReferenceLine x={referenceLineTimestamp} stroke="rgb(59, 130, 201)" label="" />}
                </BarChart>
            </ResponsiveContainer>
        </div>
        {props.config.showXAxisName && (
            <div className="my-row" style={{ marginTop: 16 }}>
                {props.data.map((item, index) => (
                    <EditableAxisItem
                        key={index}
                        width={"calc(100% / 3)"}
                        onChange={(value) => {
                            onChangeName(index, value);
                        }}
                        color={props.config.axesNamesColor}
                        currentEditVariableIndex={currentEditVariableIndex}
                        editable={props.editable}
                        index={index}
                        vertical={false}
                        name={item.name}
                        onDrop={linkVariable}
                    />
                ))}
            </div>
        )}

        {showColorPicker && (
            <Popup
                arrow={true}
                contentStyle={{
                    ...chartColorPickerPopupStyles,
                    left: showColorPicker.x,
                    top: showColorPicker.y,
                }}
                open={true}
                onClose={() => {
                    setShowColorPicker(null);
                }}
                nested={true}
                closeOnDocumentClick
            >
                <ColorPicker
                    enableAlpha={true}
                    width={"220px"}
                    color={getCellColor(showColorPicker.index)}
                    onChange={(color) => {
                        if (!showColorPicker) return;

                        let newConfig = {
                            ...props.config,
                            nameColorMapping: {
                                ...props.config.nameColorMapping,
                                [showColorPicker.index]: color,
                            },
                        };
                        props.onChangeConfig?.(newConfig);
                    }}
                />
            </Popup>
        )}
    </div>
    );
    return plot;
}
