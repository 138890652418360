import React from "react";
import Select, { createFilter } from "react-select";
import Switch from "react-switch";

import {
    CanvasInput,
    CanvasNode,
    ColumnFormat,
    DateFormat,
    DefaultValueType,
    DropdownOptionsAligment,
    dropdownOptionsAligmentOptions,
    formatNumber,
    isDateFormat,
    isNumberFormat,
    NumberFormat,
    NumberFormatOption,
    numberFormatOptions,
    NumberFormatType,
    SpreadSheetColumnOption,
    spreadSheetColumnOptionsForInput,
    SpreadSheetColumnType,
    TextAlign,
} from "common/Canvas";
import DateTimeFormatSelect, {
    OptionType as DateTimeOptionType,
} from "common/DateTimeFormatSelect";
import { GlobalContextContents } from "GlobalContext";
import { strptime, strftime } from "common/utilities/TimeFormatUtils";

import styles from "./Settings.module.css";
import StringUtils from "common/utilities/StringUtils";
import selectStyles from "../../common/SelectStyles";

interface Props {
    node: CanvasInput;
    shared: boolean;
    globalContext: GlobalContextContents;
    onChange: (
        changes: Partial<CanvasInput | CanvasNode>,
        commit?: boolean
    ) => void;
    onChangeShared: (shared: boolean) => void;
}

function convertMetricAndValue(node: CanvasInput, columnFormat: DateFormat) {
    let value = node.value;
    let metric = node.metric;
    let time = strptime(columnFormat.format, metric);
    if (time != null) {
        value = time.getTime() / 1000;
    }
    if (time == null && node.value != null && !isNaN(node.value as number)) {
        metric =
            strftime(
                columnFormat.format,
                new Date((node.value as number) * 1000)
            ) ?? metric;
    }
    return {
        metric: metric,
        value: value,
    };
}

function Settings({
    node,
    shared,
    globalContext,
    onChange,
    onChangeShared,
}: Props) {
    let [formatOptions, setFormatOptions] = React.useState(
        DateTimeFormatSelect.defaultOptions()
    );

    return (
        <div className={styles.root}>
            <div>
                <span className={styles.selectLabel}>Format</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    styles={selectStyles}
                    options={spreadSheetColumnOptionsForInput}
                    onChange={(newValue) => {
                        const type = (newValue as SpreadSheetColumnOption)
                            .value;
                        let newDefaultValueType =
                            type !== SpreadSheetColumnType.Date &&
                            node.defaultValueType ===
                                DefaultValueType.CurrentDateTime
                                ? DefaultValueType.LastTextAdded
                                : node.defaultValueType;
                        switch (type) {
                            case SpreadSheetColumnType.Text: {
                                const columnFormat: ColumnFormat = {
                                    type: type,
                                };
                                onChange({
                                    format: columnFormat,
                                    defaultValueType: newDefaultValueType,
                                });
                                break;
                            }
                            case SpreadSheetColumnType.Number:
                                {
                                    if (isNumberFormat(node.format)) break;
                                    let columnFormat: NumberFormat = {
                                        type: type,
                                        decimalPoints: 2,
                                        useCommaSeparator: true,
                                        useAbbreviation: false,
                                        numberType: NumberFormatType.Number,
                                    };
                                    let value = node.value;
                                    let metric = node.metric;
                                    if (
                                        value != null &&
                                        !isNaN(value as number)
                                    )
                                        metric = formatNumber(
                                            value as number,
                                            columnFormat
                                        );

                                    onChange({
                                        metric: metric,
                                        format: columnFormat,
                                        defaultValueType: newDefaultValueType,
                                    });
                                }
                                break;
                            case SpreadSheetColumnType.Date: {
                                if (isDateFormat(node.format)) break;

                                const columnFormat: DateFormat = {
                                    type,
                                    format: formatOptions[0].value,
                                };
                                onChange({
                                    format: columnFormat,
                                    ...convertMetricAndValue(
                                        node,
                                        columnFormat
                                    ),
                                    defaultValueType: newDefaultValueType,
                                });
                                break;
                            }
                            default:
                                // code...
                                break;
                        }
                    }}
                    value={
                        node.format != null
                            ? spreadSheetColumnOptionsForInput.find(
                                  (item) => item.value === node.format!.type
                              )
                            : null
                    }
                />
                {isNumberFormat(node.format) && (
                    <>
                        <span
                            className={styles.selectLabel}
                            style={{ marginTop: 10 }}
                        >
                            {"Number format"}
                        </span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Number format"}
                            styles={selectStyles}
                            options={numberFormatOptions}
                            onChange={(newValue) => {
                                let type = (newValue as NumberFormatOption)
                                    .value;
                                onChange({
                                    format: {
                                        ...(node.format as NumberFormat),
                                        numberType: type,
                                    } as NumberFormat,
                                });
                            }}
                            value={numberFormatOptions.find(
                                (option) =>
                                    option.value ===
                                    (node.format as NumberFormat).numberType
                            )}
                        />

                        <span
                            className={styles.selectLabel}
                            style={{ marginTop: 10 }}
                        >
                            {"Decimals"}
                        </span>
                        <input
                            className="like-select"
                            style={{
                                marginLeft: 6,
                                fontWeight: 400,
                                width: "70px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                fontFamily: "monospace",
                                textAlign: "right",
                            }}
                            placeholder="Decimal places"
                            onChange={(e) => {
                                let value = e.target.value;
                                let decimalPoints: number = 0;
                                if (StringUtils.isNumber(value)) {
                                    decimalPoints = Math.min(
                                        Math.max(0, Math.trunc(Number(value))),
                                        20
                                    );
                                }
                                onChange({
                                    format: {
                                        ...(node.format as NumberFormat),
                                        decimalPoints: decimalPoints,
                                    } as NumberFormat,
                                });
                            }}
                            defaultValue={String(
                                (node.format as NumberFormat).decimalPoints
                            )}
                        />

                        <div className={styles.switchField}>
                            <Switch
                                onChange={(checked) => {
                                    onChange({
                                        format: {
                                            ...(node.format as NumberFormat),
                                            useAbbreviation: checked,
                                        } as NumberFormat,
                                    });
                                }}
                                checked={
                                    (node.format as NumberFormat)
                                        .useAbbreviation ?? false
                                }
                                width={26}
                                height={13}
                                offHandleColor="#FFF"
                                onHandleColor="#1F8EFA"
                                offColor="#D1D1D1"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                            <span className={styles.switchLabel}>
                                {"Use Abbreviation"}
                            </span>
                        </div>

                        <div className={styles.switchField}>
                            <Switch
                                onChange={(checked) => {
                                    onChange({
                                        format: {
                                            ...(node.format as NumberFormat),
                                            useCommaSeparator: checked,
                                        } as NumberFormat,
                                    });
                                }}
                                checked={
                                    (node.format as NumberFormat)
                                        .useCommaSeparator ?? true
                                }
                                width={26}
                                height={13}
                                offHandleColor="#FFF"
                                onHandleColor="#1F8EFA"
                                offColor="#D1D1D1"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                            <span className={styles.switchLabel}>
                                {"Use 1000 separator(,)"}
                            </span>
                        </div>
                    </>
                )}
                <>
                    <span
                        className={styles.selectLabel}
                        style={{ marginTop: 10 }}
                    >
                        {"Alignment"}
                    </span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={"Alignment"}
                        styles={selectStyles}
                        options={dropdownOptionsAligmentOptions}
                        value={node.textAlignment ?? { label: "Left", value: TextAlign.left }}
                        onChange={(newValue) => {
                            onChange({
                                textAlignment: newValue as DropdownOptionsAligment,
                            });
                        }}
                    />
                </>
                {isDateFormat(node.format) && (
                    <DateTimeFormatSelect
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                flex: "1 1 auto",
                                marginTop: 10,
                            }),
                            control: (provided) => ({
                                ...provided,
                                borderRadius: "4px",
                                minWidth: "140px",
                            }),
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                            }),
                        }}
                        options={formatOptions}
                        onCreateOption={(option) => {
                            setFormatOptions([option, ...formatOptions]);
                            let newFormat = {
                                ...((node as CanvasInput).format as DateFormat),
                                format: option.value,
                            } as DateFormat;
                            onChange({
                                format: newFormat,
                                ...convertMetricAndValue(node, newFormat),
                            });
                        }}
                        onChange={(newValue) => {
                            const format: DateFormat = {
                                ...(node.format as DateFormat),
                                format: (newValue as DateTimeOptionType).value,
                            };
                            onChange({
                                format: format,
                                ...convertMetricAndValue(node, format),
                            });
                        }}
                        value={node.format.format ?? ""}
                    />
                )}
            </div>
            <div className={styles.switchField}>
                <Switch
                    onChange={(checked) => {
                        onChangeShared(checked);
                    }}
                    checked={shared}
                    width={26}
                    height={13}
                    offColor="#D1D1D1"
                    onColor="#20293C"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor="#FFF"
                    onHandleColor="#1F8EFA"
                />
                <span className={styles.switchLabel}>
                    Linkable from other slides
                </span>
            </div>
            {"CanvasLiveStream" in globalContext.permissions && (
                <div className={styles.switchField}>
                    <Switch
                        onChange={(checked) => {
                            onChange({ liveStreaming: checked });
                        }}
                        checked={(node as CanvasNode).liveStreaming ?? false}
                        width={26}
                        height={13}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#FFF"
                        onHandleColor="#1F8EFA"
                        offColor="#D1D1D1"
                        onColor="#20293C"
                    />
                    <span className={styles.switchLabel}>
                        Record Metric to Dataset
                    </span>
                </div>
            )}
            <div className={styles.switchField}>
                <Switch
                    onChange={(checked) => {
                        onChange({
                            multiline: checked,
                        });
                    }}
                    checked={node?.multiline ?? false}
                    width={26}
                    height={13}
                    offColor="#D1D1D1"
                    onColor="#20293C"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor="#FFF"
                    onHandleColor="#1F8EFA"
                />
                <span className={styles.switchLabel}>Multiline</span>
            </div>
        </div>
    );
}

export default Settings;
