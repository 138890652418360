import { observable, makeObservable, action } from "mobx";
import {
    moduleUserUpdatePageBarInfo,
    moduleUserUpdateOptions,
    getUserModuleByIdOrCurrent,
} from "common/ModulesApi";
import { BriefModule } from "common/Module";
import {
    ModuleOptions,
    defaultSlideNumberOptions,
    PageBarInfo,
    defaultPageBarInfo,
    NodeSize,
} from "common/Canvas";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class CurrentModulesStore {
    modulesState: BriefModule[];
    initialized: boolean;
    initialModuleId?: number;

    constructor(moduleId?: number) {
        makeObservable(this, {
            modulesState: observable,
            initialized: observable,
        });
        this.modulesState = [];
        this.initialized = false;
        this.initialModuleId = moduleId;
        if (this.initialModuleId != null)
            this.updateCurrentModules(this.initialModuleId);
        else this.initialized = true;
    }
    @action.bound
    updateModule(moduleId: number, props: Partial<BriefModule>) {
        let index = this.modulesState.findIndex(
            (module) => module.id === moduleId
        );
        if (index !== -1) {
            let modulesState = Array.from(this.modulesState);
            modulesState[index] = {
                ...modulesState[index],
                ...props,
            };
            this.modulesState = modulesState;
        }
    }

    getModule(moduleId: number): BriefModule | undefined {
        const module = this.modulesState.find((item) => item.id === moduleId);
        if (
            module == null &&
            moduleId !== this.initialModuleId &&
            moduleId != null &&
            this.initialized
        ) {
            this.updateCurrentModules(moduleId);
        }
        return module;
    }
    @action.bound
    private initModules(modules: BriefModule[]) {
        this.modulesState = modules;
        this.initialized = true;
    }
    @action.bound
    toggleShowPageBar(
        moduleId: number,
        slideWidth: number,
        updateServer: boolean = true
    ) {
        let modulesState = Array.from(this.modulesState);
        let currentModule = modulesState.find((item) => item.id === moduleId);
        if (currentModule != null) {
            if (currentModule.page_bar_info == null) {
                currentModule.page_bar_info = defaultPageBarInfo(slideWidth);
            } else
                currentModule.page_bar_info.show =
                    !currentModule.page_bar_info.show;
            this.modulesState = modulesState;
            if (updateServer)
                moduleUserUpdatePageBarInfo(
                    moduleId,
                    currentModule.page_bar_info,
                    cookies.get("instrumentation_session_id")
                )
                    .then()
                    .catch((error) => {
                        console.log(String(error));
                    });
        }
    }
    @action.bound
    toggleShowSlideNumber(
        moduleId: number,
        slideSize: NodeSize,
        updateServer: boolean = true
    ) {
        let modulesState = Array.from(this.modulesState);
        let currentModule = modulesState.find((item) => item.id === moduleId);
        if (currentModule != null) {
            if (currentModule.options == null) {
                currentModule.options = {};
            }
            if (currentModule.options.slideNumberOptions == null) {
                currentModule.options.slideNumberOptions =
                    defaultSlideNumberOptions(slideSize);
            } else
                currentModule.options.slideNumberOptions.show =
                    !currentModule.options.slideNumberOptions.show;

            this.modulesState = modulesState;
            if (updateServer)
                moduleUserUpdateOptions(
                    moduleId,
                    currentModule.options,
                    cookies.get("instrumentation_session_id")
                )
                    .then()
                    .catch((error) => {
                        console.log(String(error));
                    });
        }
    }
    @action.bound
    updatePageBar(
        moduleId: number,
        props: Partial<PageBarInfo>,
        updateServer: boolean = true
    ) {
        let modulesState = Array.from(this.modulesState);
        let currentModule = modulesState.find((item) => item.id === moduleId);
        if (currentModule != null && currentModule.page_bar_info != null) {
            currentModule.page_bar_info = {
                ...currentModule.page_bar_info,
                ...props,
            };
            this.modulesState = modulesState;
            if (updateServer)
                moduleUserUpdatePageBarInfo(
                    moduleId,
                    currentModule.page_bar_info,
                    cookies.get("instrumentation_session_id")
                )
                    .then()
                    .catch((error) => {
                        console.log(String(error));
                    });
        }
    }
    @action.bound
    updateModuleOptions(
        moduleId: number,
        props: Partial<ModuleOptions>,
        updateServer: boolean = true
    ) {
        let modulesState = Array.from(this.modulesState);
        let currentModule = modulesState.find((item) => item.id === moduleId);
        if (currentModule != null && currentModule.options != null) {
            currentModule.options = {
                ...currentModule.options,
                ...props,
            };
            this.modulesState = modulesState;
            if (updateServer)
                moduleUserUpdateOptions(
                    moduleId,
                    currentModule.options,
                    cookies.get("instrumentation_session_id")
                )
                    .then()
                    .catch((error) => {
                        console.log(String(error));
                    });
        }
    }

    updateCurrentModules(moduleId: number) {
        getUserModuleByIdOrCurrent(moduleId)
            .then((modules) => {
                this.initModules(modules);
            })
            .catch((error) => {
                console.log(String(error));
            });
    }
}
const urlParams = new URLSearchParams(window.location.search);

let currentModuleId: number | undefined = undefined;

if (window.location.pathname === "/canvas.html") {
    currentModuleId = NaN;
    let currentModuleIdString = urlParams.get("current_module_id");
    if (currentModuleIdString != null) {
        currentModuleId = Number(currentModuleIdString) ?? NaN;
    }
}

export default new CurrentModulesStore(currentModuleId);
