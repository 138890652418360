import React, { Component, CSSProperties } from "react";

import StringOption from "common/StringOption";
import { SearchComponentOption } from "common/SearchComponent";
import ValueWithOperator from "common/ValueWithOperator";
import { mainStyle } from "common/MainStyle";

interface Props {
	currentModuleId: string | number | undefined;
	dataScopeId: number | string;
    operators: (StringOption | null | undefined)[];
	values: (SearchComponentOption | null)[];
	equalsOnly?: boolean;
	numeric?: boolean;
	searchKey: string;
	searchIndex: number;
	style?: CSSProperties;
	clearable?: boolean;

	onChange: (newOperators: StringOption[], newValues: (SearchComponentOption | null)[]) => void;
}

interface State {
	operator: StringOption | null | undefined;
	value: SearchComponentOption | null;
}

class MultiValueWithOperator extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			operator: null,
			value: null
		};
	}

    render() {
		const values: SearchComponentOption[] = this.props.values
			.filter((value): value is SearchComponentOption => value != null && value.label != null);
		const operators: StringOption[] = this.props.operators
			.filter((_, index) => this.props.values[index] != null && this.props.values[index]!.label != null)
			.map(operator =>
				operator != null
					? operator
					: {
						label: "=",
						value: "="
					}
			);
		return (
			<div
				style={{
					backgroundColor: mainStyle.getPropertyValue(
			            "--selectors-background-color"
			        ),
				    border: "none",
				    borderRadius: 3,
				    boxSizing: "border-box",
					display: "flex",
					flexDirection: "column",
					...this.props.style
				}}
			>
				{values.map((value, index) => (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							marginLeft: 10,
							marginRight: 10
						}}
					>
						<ValueWithOperator
							small
							transparent
							dataScopeId={this.props.dataScopeId}
							currentModuleId={this.props.currentModuleId}
							operator={operators[index]}
							value={value}
							searchKey={this.props.searchKey}
							searchIndex={this.props.searchIndex}

							numeric={this.props.numeric}
							equalsOnly={this.props.equalsOnly}

							onOperatorChange={newOperator => {
								let newOperators: StringOption[] = Array.from(operators);
								newOperators[index] = newOperator;
								this.props.onChange(newOperators, values);
							}}
							onValueChange={newValue => {
								let newValues: SearchComponentOption[] = Array.from(values);
								newValues[index] = newValue;
								this.props.onChange(operators, newValues);
							}}
						/>
						<button
							style={{
								backgroundColor: "transparent",
								border: "none",
								padding: 0,
								marginLeft: 5
							}}
							onClick={() => {
								let newOperators: StringOption[] = Array.from(operators);
								newOperators.splice(index, 1);
								let newValues: SearchComponentOption[] = Array.from(values);
								newValues.splice(index, 1);
								this.props.onChange(newOperators, newValues);
							}}
						>
							<i
								className="fa fa-minus"
								style={{ color: "#39F" }}
								aria-hidden="true"
							></i>
						</button>
						{this.props.clearable && index === 0 &&
							<button
								style={{
									backgroundColor: "transparent",
									border: "none",
									padding: 0,
									marginLeft: 10,
									display: "flex",
									alignItems: "center"
								}}
								onClick={() => {
									let newOperators: StringOption[] = [];
									let newValues: SearchComponentOption[] = [];
									this.props.onChange(newOperators, newValues);
								}}
							>
								<svg
									height="20"
									width="20"
									viewBox="0 0 20 20"
									aria-hidden="true"
									focusable="false"
									style={{
										color: "rgb(204, 204, 204)",
										stroke: "none",
										fill: "rgb(204, 204, 204)"
									}}
								>
									<path d={
										"M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 " +
										"0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-" +
										"0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 " +
										"1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"}
									></path>
								</svg>
							</button>
						}
					</div>
				))}
				{/* New value */}
				{values.length !== 0 &&
					<hr
						style={{
							color: "#39F",
							backgroundColor: "#39F",
							borderColor: "#39F",
							borderStyle: "inset",
							borderWidth: 0,
							margin: 0,
							height: 0.5
						}}
					/>
				}
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginLeft: 10,
						marginRight: 10
					}}
				>
					<ValueWithOperator
						small
						transparent
						currentModuleId={this.props.currentModuleId}
						dataScopeId={this.props.dataScopeId}
						operator={this.state.operator}
						value={this.state.value}
						searchKey={this.props.searchKey}
						searchIndex={this.props.searchIndex}

						numeric={this.props.numeric}
						equalsOnly={this.props.equalsOnly}

						onOperatorChange={newOperator => {
							this.setState({
								operator: newOperator
							});
						}}
						onValueChange={newValue => {
							this.setState({
								value: newValue
							});
						}}
					/>
					<button
						style={{
							backgroundColor: "transparent",
							border: "none",
							padding: 0,
							marginLeft: 5
						}}
						onClick={() => {
							if(this.state.operator != null && this.state.value != null) {
								let newOperators: StringOption[] = [
									...operators,
									this.state.operator
								];
								let newValues: SearchComponentOption[] = [
									...values,
									this.state.value
								];
								this.props.onChange(newOperators, newValues);
								this.setState({
									operator: null,
									value: null
								});
							}
						}}
					>
						<i
							className="fa fa-plus"
							aria-hidden="true"
							style={{ color: "#39F" }}
						></i>
					</button>
					{this.props.clearable &&
						<div
							aria-hidden="true"
							style={{
								height: 20,
								width: 20,
								marginLeft: 10,
								opacity: 0
							}}
						></div>
					}
				</div>
			</div>
		);
    }
}

export default MultiValueWithOperator;
