import React, { Component } from "react";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import { AppModuleProps } from "AppModule";
import { goToInternalLink } from "common/InternalLinksHelper";
import linkIdToUrl, { sharedModuleIdToUrl } from "common/utilities/linkIdToUrl";
import axios from "common/ServerConnection";

class MainComponent extends Component<AppModuleProps> {
    private redirectOnSuccess(urlParams: URLSearchParams): void {
        if (urlParams.get("app_id") != null) {
            goToInternalLink(linkIdToUrl(true, urlParams.get("app_id")!));
        } else if (urlParams.get("shared_module_id") != null) {
            goToInternalLink(
                sharedModuleIdToUrl(Number(urlParams.get("shared_module_id")))
            );
        } else {
            goToInternalLink("home.html");
        }
    }

    public componentDidMount(): void {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("session_id") != null) {
            axios
                .post<{
                    success: boolean;
                    error_msg: string;
                }>("/api/confirm_setup_checkout_session", {
                    session_id: urlParams.get("session_id"),
                })
                .then((response) => {
                    if (!response.data.success) {
                        console.error(response.data.error_msg);
                    }
                    this.redirectOnSuccess(urlParams);
                })
                .catch((error) => {
                    console.error(error);
                    goToInternalLink("home.html");
                });
        } else {
            goToInternalLink("home.html");
        }
    }

    public render(): JSX.Element {
        return <div />;
    }
}

export { MainComponent };
export let route = "/csession.html";
