import React, { useState, useEffect, useCallback, useMemo } from "react";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import { getValueFillColor, StatusBarExpression } from "common/Canvas";
// @ts-ignore
import Portal from "common/Portal";
import ColorPicker from "../ChartColorPicker";
import {
    BarChartTheme,
    getGridColorByTheme,
    getDefaultColorsByTheme,
    getOptionsByTheme,
} from "../../BarChartTheme";
import { BarChartFinding, getValue, TicksAndLabels } from "common/Finding";
import { TooltipStyles } from "../../TooltipStyles";
import * as d3 from "d3";
import EditableAxisItem from "../EditableAxisItem";
import { cutXAxisName } from "./D3Utils";
import { useUpdateEffect } from "common/CustomHooks";
import OutsideAlerter from "common/OutsideAlerter";
import { calculateChartColorPickerPosition } from "../utils";
import D3ChartBase from "../D3ChartBase";

function wrapText(textNode: SVGTextElement, width: number) {
    let textLength = textNode.getComputedTextLength();
    let text = textNode.innerHTML;
    while (textLength > width && text.length > 0) {
        text = text.slice(0, -1);
        textNode.innerHTML = text + "...";
        textLength = textNode.getComputedTextLength();
    }
}

type BarChartData = {
    name: string;
    value?: number | number[];
    variableIndex?: number | undefined;
    originalName?: string | undefined;
    shortName?: string;
};

type CircularBarChartData = {
    key: string;
    name: string;
    value: number;
    prevValue: number;
    variableIndex: number | undefined;
    index: number;
    dataIndex: number;
};

type HorizontalLines = ({
    name: string;
    value: number;
    variableIndex?: number;
    originalName?: string;
} | null)[];

interface EntryData {
    key: string;
    name: string;
    value: number;
    prevValue?: number;
    variableIndex: number | undefined;
    index: number;
    dataIndex: number;
}

interface Props {
    groupNames?: string[];
    groupIndex?: number;
    groupInfo: BarChartFinding["content"]["groupInfo"];
    showGrid?: boolean; // Default: true
    showXAxisName: boolean;
    showYAxisName: boolean;
    scale: number;
    sortYAxis: boolean;
    yAxisSortType: string;
    yAxisName?: string;
    currentEditVariableIndex?: number;
    editable?: boolean;
    statusExpressions?: StatusBarExpression[];
    barIndices?: number[];
    minYRange?: number;
    maxYRange?: number;
    axesColor?: string;
    circular?: boolean;
    errorBarColor?: string;
    showError?: boolean;
    dotRadius?: number;
    lineColor?: string;
    nameColorMapping?: { [key: string]: string };
    labelMapping?: { [key: string]: string };
    data: BarChartData[][];
    originalData: BarChartData[];
    varyByData?: BarChartData[];
    horizontalLines?: HorizontalLines;
    barColors: string[];
    selected: boolean;
    colorByGroup?: boolean;
    title?: string;
    maxChartValue?: number;
    watermark?: string;
    barSize?: number;
    barGap?: number;
    barCategoryGap?: number;
    stacked?: boolean;
    showAsDots?: boolean;
    dashLine?: boolean;
    chartTheme?: BarChartTheme;
    axesLinesColor?: string;
    axesNamesColor?: string;
    ticksColor?: string;
    labelsColor?: string;
    ticksSize?: number;
    ticksAndLabels?: TicksAndLabels;
    baseBackgroundColor?: string;
    tooltipColor?: string;
    tooltipFontSize?: number;
    isSideBySide?: boolean;
    transpose?: boolean;
    flip?: boolean;
    onLinkVariable: (
        index: number,
        variableName: string,
        variableIndex: number
    ) => void;
    onRenameVariable: (index: number, name: string) => void;
    onConfigChange: (changes: BarChartFinding["config"]) => void;
}

function CustomBarChart(props: Props) {
    const color = useCallback(
        (entry: EntryData, index: number) => {
            let expressionColor = null;
            if (props.nameColorMapping != null) {
                expressionColor = props.nameColorMapping[entry.dataIndex];
            }
            if (props.statusExpressions != null) {
                let value = entry.value;
                expressionColor =
                    getValueFillColor(
                        value,
                        entry.dataIndex,
                        props.statusExpressions
                    ) ?? expressionColor;
            }
            let fillColor =
                expressionColor ??
                (entry.variableIndex == null
                    ? "#D1D1D1"
                    : expressionColor ??
                      props.barColors[
                          (props.colorByGroup ? index : entry.dataIndex) %
                              props.barColors.length
                      ]);
            return fillColor;
        },
        [
            props.nameColorMapping,
            props.statusExpressions,
            props.barColors,
            props.colorByGroup,
        ]
    );

    let [showTooltipInfo, setShowTooltipInfo] = useState<{
        x: number;
        y: number;
        index: number;
    } | null>(null);
    let [colorPickerIsEnabled, setColorPickerIsEnabled] = useState<boolean>(
        false
    );

    let barCategoryGap = props.barCategoryGap ?? 100;
    let stacked =
        props.stacked &&
        !props.showAsDots &&
        !props.circular &&
        props.groupIndex == null;
    let barGap = stacked ? 0 : props.barGap ?? 0;
    let barSize = props.barSize ?? 80;
    let data: BarChartData[] = useMemo(() => {
        if (props.transpose) {
            return props.originalData
                .filter(
                    (group) =>
                        group.variableIndex != null &&
                        Array.isArray(group.value)
                )
                .map((group, index) => ({
                    name: group.name,
                    shortName: group.name,
                    originalName: group.originalName!,
                    value: undefined,
                    index: index,
                }));
        } else {
            return props.data[0].map((item, index) => ({
                ...item,
                value: undefined,
                index: index,
            }));
        }
    }, [props.transpose, props.originalData, props.data]);

    let groupNames: string[] = useMemo(() => {
        let groupNames: string[];
        if (props.transpose) {
            groupNames = props.data[0].map((item) => item.name);
        } else {
            groupNames = props.groupNames || ["value"];
        }
        // It works differently for groupIndex
        if (props.groupIndex != null) {
            groupNames = [groupNames[props.groupIndex]];
        }
        return groupNames;
    }, [props.transpose, props.data, props.groupNames, props.groupIndex]);

    let groupInfo = useMemo(() => {
        let groupInfo: Props["groupInfo"] | undefined;
        if (props.transpose) {
            groupInfo = undefined;
        } else {
            groupInfo = props.groupInfo;
        }
        if (props.groupIndex != null && groupInfo != null) {
            groupInfo = [groupInfo[props.groupIndex]];
        }
        return groupInfo;
    }, [props.transpose, props.groupInfo, props.groupIndex]);

    let groupIndices: number[] = useMemo(() => {
        if (props.groupIndex != null) {
            return [props.groupIndex];
        } else {
            return props.groupNames?.map((_, index) => index) ?? [0];
        }
    }, [props.groupIndex, props.groupNames]);

    let [dataMin, dataMax, groupNamesToData] = useMemo(() => {
        let dataMin = Infinity;
        let dataMax = -Infinity;
        // Maps group names to data
        let groupNamesToData: { [key: string]: number }[] = [];
        for (let i = 0; i < data.length; ++i) {
            let dataSum = 0;
            groupNamesToData.push({});
            for (let j = 0; j < groupNames.length; ++j) {
                groupNamesToData[i][groupNames[j]] = props.transpose
                    ? getValue(
                          props.data[i]?.[j] as {
                              value: number | number[];
                          },
                          0
                      )
                    : getValue(
                          props.data[0]?.[i] as {
                              value: number | number[];
                          },
                          groupIndices[j]
                      );
                dataMin = Math.min(dataMin, groupNamesToData[i][groupNames[j]]);
                if (!stacked && !props.circular)
                    dataMax = Math.max(
                        dataMax,
                        groupNamesToData[i][groupNames[j]]
                    );
                else
                    dataSum =
                        dataSum + Math.abs(groupNamesToData[i][groupNames[j]]);
            }
            if (stacked || props.circular) {
                dataMin = 0;
                dataMax = Math.max(dataSum, dataMax);
            }
        }
        dataMin = Math.min(0, dataMin);

        if (props.horizontalLines != null) {
            for (let item of props.horizontalLines) {
                if (item != null) {
                    dataMin = Math.min(dataMin, item.value);
                    dataMax = Math.max(dataMax, item.value);
                }
            }
        }

        return [dataMin, dataMax, groupNamesToData];
    }, [
        data,
        props.data,
        props.transpose,
        stacked,
        props.circular,
        props.horizontalLines,
        groupNames,
        groupIndices,
    ]);

    let countInGroup = stacked ? 1 : groupNames.length;
    barSize = barSize / (stacked && !props.showAsDots ? 1 : groupNames.length);
    const innerGroupLength =
        barGap * (countInGroup - 1) + barSize * countInGroup;
    const innerGroupPaddingLength = barGap * (countInGroup - 1);
    const groupPadding = innerGroupPaddingLength / innerGroupLength;
    const chartPaddingLength = barCategoryGap * data.length;
    const axisPadding =
        data.length === 1
            ? 0
            : chartPaddingLength /
              (chartPaddingLength + innerGroupLength * data.length);
    let barChartWidth: number =
        data.length > 0
            ? chartPaddingLength + innerGroupLength * data.length // barCategoryGap
            : 800;

    let xAxisFontSize: number =
        props.ticksAndLabels?.x?.size ??
        props.ticksSize ??
        parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

    let yAxisFontSize: number =
        props.ticksAndLabels?.y?.size ??
        props.ticksSize ??
        parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

    let labelsFontSize: number =
        props.ticksAndLabels?.labels?.size ??
        props.ticksSize ??
        parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

    let titleSize: number = 12;
    const chartTheme = props.chartTheme ?? BarChartTheme.Default;
    const defaultColors = useMemo(() => getDefaultColorsByTheme(chartTheme), [
        chartTheme,
    ]);
    let themeOptions = getOptionsByTheme(chartTheme);
    let gridFillColor = getGridColorByTheme(
        chartTheme,
        props.baseBackgroundColor
    );
    let [showBarColorMenu, setShowBarColorMenu] = useState<{
        name: string;
        dataIndex: number;
        index: number;
        variableIndex?: number;
        x: number;
        y: number;
    } | null>(null);
    let [axisItemStyles, setAxisItemStyles] = useState<Record<string, number>>({
        yAxisHeight: 0,
    });
    let [editYAxisName, setEditYAxisName] = useState<{
        boundingRect: {
            x: number;
            y: number;
            width: number;
            height: number;
        };
    } | null>(null);

    let minYRange = props.minYRange ?? 0; // Here min has to be 0 by default
    let maxYRange = props.maxYRange ?? dataMax;

    let { ticks: yTicks } = useMemo(
        () =>
            D3ChartBase.calculateLinearTicks(
                minYRange,
                maxYRange,
                props.ticksAndLabels?.y?.interval
            ),
        [props.ticksAndLabels?.y?.interval, maxYRange, minYRange]
    );

    let parentRef = React.useRef<HTMLDivElement>(null);
    let parentSvgRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);

    let width = barChartWidth;
    let yAxisWidth = 50;
    let xAxisGroupHeight =
        (!props.stacked || props.transpose) &&
        !props.circular &&
        !props.showAsDots &&
        props.isSideBySide
            ? 50
            : 0;
    let showXAxisTicks = props.stacked && props.transpose;

    let fullWidth =
        barChartWidth + yAxisWidth * (props.showYAxisName ? props.scale : 1);
    if (props.showYAxisName) fullWidth += 15;

    const clickBar = useCallback(
        (event: MouseEvent, entry: EntryData) => {
            const { y } = calculateChartColorPickerPosition(event);
            if (props.editable && colorPickerIsEnabled)
                setShowBarColorMenu({
                    name: entry.name,
                    index: entry.index,
                    dataIndex: entry.dataIndex,
                    variableIndex: entry.variableIndex,
                    x: event.clientX,
                    y,
                });
        },
        [props.editable, colorPickerIsEnabled]
    );

    const mouseenter = useCallback(
        (event: MouseEvent, d: EntryData) => {
            setShowTooltipInfo({
                x: event.x,
                y: event.y,
                index: props.transpose ? d.index : d.dataIndex,
            });
        },
        [props.transpose, setShowTooltipInfo]
    );
    const mousemove = useCallback(
        (event: MouseEvent) => {
            setShowTooltipInfo((showTooltipInfo) => {
                if (showTooltipInfo == null) return null;
                return {
                    ...showTooltipInfo,
                    x: event.x + 5,
                    y: event.y + 5,
                };
            });
        },
        [setShowTooltipInfo]
    );
    const mouseleave = useCallback(
        (_event: MouseEvent) => {
            setShowTooltipInfo(null);
        },
        [setShowTooltipInfo]
    );

    useUpdateEffect(() => {
        setTimeout(() => {
            setColorPickerIsEnabled(props.selected);
        }, 100);
    }, [props.selected, props.data]);

    useEffect(() => {
        let containerHeight = parentSvgRef.current?.clientHeight ?? 0;
        let containerWidth = parentSvgRef.current?.clientWidth ?? 0;
        let fullHeight = containerHeight;
        setAxisItemStyles({
            yAxisHeight: fullHeight - 9,
        });

        if (props.circular) {
            // append the svg object to the body of the page
            d3.select(svgRef.current!).selectAll("*").remove();
            let svg = d3
                .select(svgRef.current!)
                .attr("width", containerWidth)
                .attr("height", fullHeight)
                .attr("fill", gridFillColor ?? "transparent")
                .attr(
                    "viewBox",
                    `${-containerWidth / 2} ${
                        -fullHeight / 2
                    } ${containerWidth} ${fullHeight}`
                );

            // Add X axis
            let minSize = Math.min(fullHeight, containerWidth);
            let innerRadius = minSize / 4;
            let outerRadius = minSize / 2;
            let x = d3
                .scaleBand()
                .domain(data.map((d) => d.name))
                .range([0, 2 * Math.PI])
                .align(0);
            let y = d3
                .scaleRadial()
                .domain([minYRange, maxYRange])
                .range([innerRadius, outerRadius]);
            let xAxis = (
                g: d3.Selection<SVGGElement, unknown, null, undefined>
            ) =>
                g.attr("text-anchor", "middle").call((g) =>
                    g
                        .selectAll<SVGGElement, BarChartData>("g")
                        .data(data)
                        .join("g")
                        .attr(
                            "transform",
                            (d) =>
                                `rotate(${
                                    (((x(d.name) ?? 0) + x.bandwidth() / 2) *
                                        180) /
                                        Math.PI -
                                    90
                                }) translate(${innerRadius},0)`
                        )
                        .call((g) =>
                            g
                                .append("line")
                                .attr("x2", -5)
                                .attr("stroke", "#000")
                        )
                        .call((g) =>
                            g
                                .append("text")
                                .attr("transform", (d) =>
                                    ((x(d.name) ?? 0) +
                                        x.bandwidth() / 2 +
                                        Math.PI / 2) %
                                        (2 * Math.PI) <
                                    Math.PI
                                        ? "rotate(90)translate(0,16)"
                                        : "rotate(-90)translate(0,-9)"
                                )
                                .text((d) =>
                                    cutXAxisName(d.shortName ?? d.name)
                                )
                                .attr(
                                    "fill",
                                    props.ticksColor ??
                                        mainStyle.getPropertyValue(
                                            "--graphs-axes-text-color"
                                        )
                                )
                                .attr("font-size", xAxisFontSize)
                        )
                );

            let yAxis = (
                g: d3.Selection<SVGGElement, unknown, null, undefined>
            ) =>
                g
                    .selectAll("g")
                    .data(yTicks)
                    .join("g")
                    .attr("fill", "none")
                    .call((g) => {
                        let circle = g
                            .append("circle")
                            .attr(
                                "stroke",
                                props.axesLinesColor ??
                                    defaultColors.axesLinesColor
                            )
                            .attr("stroke-width", 1)
                            .attr("r", y);
                        if (themeOptions.dashGrid) {
                            circle.style("stroke-dasharray", "3, 3");
                        }
                    })
                    .call((g) =>
                        g
                            .append("text")
                            .attr("y", (d) => -y(d))
                            .attr("dy", "0.35em")
                            .text((value) => formatValue(value, false).join(""))
                            .attr(
                                "fill",
                                props.labelsColor ??
                                    mainStyle.getPropertyValue(
                                        "--graphs-axes-text-color"
                                    )
                            )
                            .attr("font-size", yAxisFontSize)
                    );

            let arc = d3
                .arc<CircularBarChartData>()
                .innerRadius((d) => {
                    //   if (d.prevValue * d.value < 0) return y(0);
                    //   if (d.prevValue > d.value) return y(d.value);
                    return y(d.prevValue ?? 0);
                })
                .outerRadius((d) => {
                    //    if (d.prevValue > d.value) return y(d.prevValue);
                    return y(d.value);
                })
                .startAngle((d) => x(d.name) ?? 0)
                .endAngle((d) => (x(d.name) ?? 0) + x.bandwidth())
                .padAngle(0.01)
                .padRadius(innerRadius);

            svg.append("g")
                .selectAll("g")
                // Enter in data = loop group per group
                .data(data)
                .join("g")
                .selectAll("path")
                .data((d, dataIndex) =>
                    groupNames.map(
                        (key, index): CircularBarChartData => {
                            let prevValue = 0;
                            for (let i = 0; i < index; ++i) {
                                prevValue += Math.abs(
                                    groupNamesToData[dataIndex][groupNames[i]]
                                );
                            }
                            return {
                                key: key,
                                name: d.name,
                                value:
                                    prevValue +
                                    Math.abs(groupNamesToData[dataIndex][key]),
                                prevValue: prevValue,
                                variableIndex: props.transpose
                                    ? props.data[dataIndex][index]
                                          ?.variableIndex
                                    : d.variableIndex,
                                index: props.transpose ? dataIndex : index,
                                dataIndex: props.transpose ? index : dataIndex,
                            };
                        }
                    )
                )
                .join("path")
                .attr("d", arc)
                .attr("fill", color)
                .attr("class", "chart-bar")
                .attr("stroke", (entry) =>
                    entry.dataIndex === props.currentEditVariableIndex
                        ? "#8DB8E3"
                        : "#D1D1D1"
                )
                .on("mouseenter", mouseenter)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)
                .on("click", clickBar);

            svg.append("g").call(xAxis);
            svg.append("g").call(yAxis);

            if (props.horizontalLines != null) {
                svg.append("g")
                    .selectAll("g")
                    .data(props.horizontalLines?.filter((item) => item != null))
                    .join("g")
                    .attr("fill", "none")
                    .call((g) =>
                        g
                            .append("circle")
                            .attr("stroke", "red")
                            .attr("stroke-width", 1)
                            .style("stroke-dasharray", "3, 3")
                            .attr("r", (d) => y(d?.value ?? 0))
                    )

                    .call((g) =>
                        g
                            .append("text")
                            .attr("y", (d) => y(d?.value ?? 0))
                            .attr("dy", "0.35em")
                            .text((d) => d?.name ?? "")
                            .attr(
                                "fill",
                                props.ticksColor ??
                                    mainStyle.getPropertyValue(
                                        "--graphs-axes-text-color"
                                    )
                            )
                            .attr("font-size", yAxisFontSize)
                    );
            }
            return;
        }
        // set the dimensions and margins of the graph
        if (!props.flip) {
            let xAxisMargin = 10;
            let height = fullHeight - xAxisMargin - xAxisGroupHeight;

            // append the svg object to the body of the page
            d3.select(svgRef.current!).selectAll("*").remove();
            let svg = d3
                .select(svgRef.current!)
                .attr("width", fullWidth)
                .attr("height", fullHeight)
                .attr("viewBox", null)
                .append("g")
                .attr("transform", `translate(${yAxisWidth}, 5)`);

            svg.append("g")
                .append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("fill", gridFillColor ?? "transparent");

            // Add X axis
            let x = d3
                .scaleBand()
                .domain(data.map((d) => d.name))
                .range([0, width])
                .paddingInner(axisPadding)
                .paddingOuter(barCategoryGap / barChartWidth);
            let y = d3
                .scaleLinear()
                .domain([minYRange, maxYRange])
                .range([height, 0]);

            // Make sure that labels fit
            let label = svg
                .append("text")
                .text(formatValue(dataMax, false).join(""))
                .attr("fill", "transparent")
                .attr("font-size", labelsFontSize);
            const labelHeight = y.invert(
                height - label.node()!.getBBox().height
            );
            label.remove();
            if (Math.abs(dataMax - maxYRange) < labelHeight) {
                y.domain([minYRange, maxYRange + labelHeight]);
            } else {
                // Add padding
                y.domain([minYRange, maxYRange + y.invert(height - 5)]);
            }

            let xAxisContainer = svg
                .append("g")
                .attr("transform", `translate(0, ${height})`)
                .call(
                    d3
                        .axisBottom(x)
                        .tickSize(1)
                        .tickFormat((_value: string, index: number) =>
                            cutXAxisName(
                                data[index].shortName ?? data[index].name
                            )
                        )
                );
            xAxisContainer
                .selectAll("path")
                .attr(
                    "stroke",
                    props.axesLinesColor ??
                        mainStyle.getPropertyValue("--graphs-axes-text-color")
                );

            // Add Y axis
            let yAxisContainer = svg.append("g");

            yAxisContainer.call(
                d3
                    .axisLeft<number>(y)
                    .tickValues(yTicks)
                    .tickFormat((value) => formatValue(value, false).join(""))
                    .tickSizeOuter(0)
            );
            yAxisContainer
                .selectAll("path")
                .attr(
                    "stroke",
                    props.axesLinesColor ?? defaultColors.axesLinesColor
                );

            yAxisContainer
                .selectAll("line")
                .attr(
                    "stroke",
                    props.axesLinesColor ?? defaultColors.axesLinesColor
                );
            if (!themeOptions.showAxesLines) {
                yAxisContainer.selectAll("path").attr("visibility", "hidden");
                xAxisContainer.selectAll("path").attr("visibility", "hidden");
            }
            if (!themeOptions.showTickLines) {
                yAxisContainer.selectAll("line").attr("visibility", "hidden");
                xAxisContainer.selectAll("line").attr("visibility", "hidden");
            }
            yAxisContainer
                .selectAll("text")
                .attr(
                    "fill",
                    props.ticksColor ??
                        mainStyle.getPropertyValue("--graphs-axes-text-color")
                )
                .attr("font-size", yAxisFontSize);
            if (showXAxisTicks) {
                xAxisContainer
                    .selectAll("text")
                    .attr(
                        "transform",
                        `translate(0, ${xAxisMargin}) rotate(-15)`
                    )
                    .attr(
                        "fill",
                        props.ticksColor ??
                            mainStyle.getPropertyValue(
                                "--graphs-axes-text-color"
                            )
                    )
                    .attr("font-size", xAxisFontSize);
                let labels = xAxisContainer.selectAll<SVGTextElement, null>(
                    "text"
                );
                for (let label of labels) {
                    wrapText(label, barSize / Math.cos((15 * Math.PI) / 180));
                }
            } else xAxisContainer.selectAll("text").attr("font-size", 0);

            const grid = svg
                .append("g")
                .selectAll("g")
                .data(yTicks)
                .join("g")
                .append("line")
                .attr("x1", 0)
                .attr("x2", barChartWidth)
                .attr("y1", (d) => y(d))
                .attr("y2", (d) => y(d))
                .attr("fill", "none")
                .attr("stroke", props.axesLinesColor ?? defaultColors.gridColor)
                .attr("stroke-width", 1);
            if (themeOptions.dashGrid) grid.style("stroke-dasharray", "3, 3");
            if (!stacked) {
                if (!props.showAsDots) {
                    let xSubgroup = d3
                        .scaleBand()
                        .domain(groupNames)
                        .range([0, x.bandwidth()])
                        .paddingInner(groupPadding)
                        .paddingOuter(0);
                    if (props.isSideBySide) {
                        for (let d of data) {
                            let xAXisGroupContainer = svg
                                .append("g")
                                .attr(
                                    "transform",
                                    `translate(${x(d.name)},  ${
                                        height + xAxisMargin
                                    })`
                                )
                                .call(
                                    d3
                                        .axisBottom(xSubgroup)
                                        .tickSize(1)
                                        .tickFormat(
                                            (_value: string, index: number) => {
                                                return groupInfo
                                                    ? groupInfo[index].value
                                                    : groupNames[index];
                                            }
                                        )
                                );
                            xAXisGroupContainer
                                .selectAll("path")
                                .attr("visibility", "hidden");
                            xAXisGroupContainer
                                .selectAll("line")
                                .attr("visibility", "hidden");
                            xAXisGroupContainer
                                .selectAll("text")
                                .attr("transform", "rotate(-15)")
                                .attr(
                                    "fill",
                                    props.ticksColor ??
                                        mainStyle.getPropertyValue(
                                            "--graphs-axes-text-color"
                                        )
                                )
                                .attr("font-size", xAxisFontSize);
                            const labels = xAXisGroupContainer.selectAll<
                                SVGTextElement,
                                null
                            >("text");
                            for (let label of labels) {
                                wrapText(
                                    label,
                                    barSize / Math.cos((15 * Math.PI) / 180)
                                );
                            }
                        }
                    }
                    let g = svg
                        .append("g")
                        .selectAll("g")
                        // Enter in data = loop group per group
                        .data(data)
                        .join("g")
                        .attr("transform", (d) => `translate(${x(d.name)}, 0)`);
                    g.selectAll("rect")
                        .data((d, dataIndex) =>
                            groupNames.map((key, index) => ({
                                key: key,
                                name: d.name,
                                value: groupNamesToData[dataIndex][key],
                                variableIndex: props.transpose
                                    ? props.data[dataIndex][index]
                                          ?.variableIndex
                                    : d.variableIndex,
                                index: props.transpose ? dataIndex : index,
                                dataIndex: props.transpose ? index : dataIndex,
                            }))
                        )
                        .join("rect")
                        .attr("x", (d) => xSubgroup(d.key)!)
                        .attr("y", (d) => (d.value < 0 ? y(0) : y(d.value)))
                        .attr("width", barSize)
                        .attr("height", (d) =>
                            d.value < 0 ? y(d.value) - y(0) : y(0) - y(d.value)
                        )
                        .attr("fill", color)
                        .attr("stroke", (entry: EntryData) =>
                            entry.dataIndex === props.currentEditVariableIndex
                                ? "#8DB8E3"
                                : "#D1D1D1"
                        )
                        .attr("class", "chart-bar")
                        .on("mouseenter", mouseenter)
                        .on("mousemove", mousemove)
                        .on("mouseleave", mouseleave)
                        .on("click", clickBar);
                    g.selectAll("text")
                        .data((d, dataIndex) =>
                            groupNames.map((key) => ({
                                key: key,
                                value: groupNamesToData[dataIndex][key],
                            }))
                        )
                        .join("text")
                        .attr("width", barSize)
                        .attr("x", (d) => xSubgroup(d.key)! + barSize / 2)
                        .attr("y", (d) => (d.value < 0 ? y(0) : y(d.value)))
                        .attr("dy", "-0.35em")
                        .attr("text-anchor", "middle")
                        .attr(
                            "fill",
                            props.labelsColor ??
                                mainStyle.getPropertyValue(
                                    "--graphs-axes-text-color"
                                )
                        )
                        .attr("font-size", labelsFontSize)
                        .text((d) => formatValue(d!.value, false).join(""));
                } else {
                    let points = groupNames.map((key) =>
                        data.map((item, dataIndex) => ({
                            x: item.name,
                            y: groupNamesToData[dataIndex][key],
                        }))
                    );
                    for (let pointGroup of points) {
                        let line = svg
                            .append("path")
                            .datum(pointGroup)
                            .attr("fill", "none")
                            .attr(
                                "stroke",
                                props.lineColor ??
                                    (data[0].variableIndex == null
                                        ? "#D1D1D1"
                                        : props.barColors[0])
                            )
                            .attr("stroke-width", 1.5)
                            .attr(
                                "d",
                                d3
                                    .line<{ x: string; y: number }>()
                                    .x(
                                        (d, index) =>
                                            index *
                                                (barChartWidth / data.length) +
                                            barChartWidth / (2 * data.length)
                                    )
                                    .y((d) => y(d.y))
                            );
                        if (props.dashLine) {
                            line.attr("stroke-dasharray", "5, 5");
                        }
                    }
                    svg.append("g")
                        .selectAll("g")
                        // Enter in data = loop group per group
                        .data(data)
                        .join("g")
                        .selectAll("circle")
                        .data((d, dataIndex) =>
                            groupNames.map((key, index) => ({
                                key: key,
                                name: d.name,
                                value: groupNamesToData[dataIndex][key],
                                variableIndex: props.transpose
                                    ? props.data[dataIndex][index]
                                          ?.variableIndex
                                    : d.variableIndex,
                                index: props.transpose ? dataIndex : index,
                                dataIndex: props.transpose ? index : dataIndex,
                            }))
                        )
                        .join("circle")
                        .attr(
                            "cx",
                            (d) =>
                                d.dataIndex * (barChartWidth / data.length) +
                                barChartWidth / (2 * data.length)
                        )
                        .attr("cy", (d) => y(d.value))
                        .attr("r", props.dotRadius ?? 5)
                        .attr("fill", color)
                        .attr("class", "chart-bar")
                        .attr("stroke", (entry: EntryData) =>
                            entry.dataIndex === props.currentEditVariableIndex
                                ? "#8DB8E3"
                                : "#D1D1D1"
                        )
                        .on("mouseenter", mouseenter)
                        .on("mousemove", mousemove)
                        .on("mouseleave", mouseleave)
                        .on("click", clickBar);
                }
            } else {
                svg.append("g")
                    .selectAll("g")
                    // Enter in data = loop group per group
                    .data(data)
                    .join("g")
                    .attr("transform", (d) => `translate(${x(d.name)}, 0)`)
                    .selectAll("rect")
                    .data((d, dataIndex) =>
                        groupNames.map((key, index) => {
                            let prevValue = 0;
                            for (let i = 0; i < index; ++i) {
                                prevValue += Math.abs(
                                    groupNamesToData[dataIndex][groupNames[i]]
                                );
                            }
                            return {
                                key: key,
                                name: d.name,
                                value:
                                    prevValue +
                                    Math.abs(groupNamesToData[dataIndex][key]),
                                prevValue: prevValue,
                                variableIndex: props.transpose
                                    ? props.data[dataIndex][index]
                                          ?.variableIndex
                                    : d.variableIndex,
                                index: props.transpose ? dataIndex : index,
                                dataIndex: props.transpose ? index : dataIndex,
                            };
                        })
                    )
                    .join("rect")
                    .attr("x", null)
                    .attr("y", (d) => y(Math.abs(d.value)))
                    .attr("width", barSize)
                    .attr("height", (d) =>
                        Math.abs(y(d.prevValue) - y(d.value))
                    )
                    .attr("fill", color)
                    .attr("class", "chart-bar")
                    .attr("stroke", (entry) =>
                        entry.dataIndex === props.currentEditVariableIndex
                            ? "#8DB8E3"
                            : "#D1D1D1"
                    )
                    .on("mouseenter", mouseenter)
                    .on("mousemove", mousemove)
                    .on("mouseleave", mouseleave)
                    .on("click", clickBar);
            }
            if (props.horizontalLines != null) {
                let container = svg
                    .append("g")
                    .selectAll("g")
                    .data(props.horizontalLines.filter((item) => item != null))
                    .join("g");

                container
                    .append("line")
                    .attr("x1", 0)
                    .attr("x2", barChartWidth)
                    .attr("y1", (d) => y(d!.value))
                    .attr("y2", (d) => y(d!.value))
                    .attr("fill", "none")
                    .attr("stroke", "red")
                    .attr("stroke-width", 1)
                    .style("stroke-dasharray", "3, 3");
                container
                    .append("text")
                    .attr("x", barChartWidth - 50)
                    .attr("y", (d) => y(d!.value) + 10)
                    // .attr("dy", ".35em")
                    .attr("text-anchor", "start")
                    .style(
                        "fill",
                        props.ticksColor ??
                            mainStyle.getPropertyValue(
                                "--graphs-axes-text-color"
                            )
                    )
                    .style("font-weight", 700)
                    .style("font-size", 12)
                    .text((d) => d!.name);
            }
        } else {
            let xAxisTickHeight = 50;
            fullHeight = barChartWidth + xAxisTickHeight;
            let yAxisWidth = 50;
            let height = barChartWidth;
            let width = containerWidth - yAxisWidth;
            // append the svg object to the body of the page
            d3.select(svgRef.current!).selectAll("*").remove();
            let svg = d3
                .select(svgRef.current!)
                .attr("width", containerWidth)
                .attr("height", fullHeight)
                .attr("viewBox", null)
                .append("g")
                .attr("transform", `translate(${yAxisWidth}, 5)`);

            svg.append("g")
                .append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("fill", gridFillColor ?? "transparent");

            // Add X axis
            let y = d3
                .scaleBand()
                .domain(data.map((d) => d.name))
                .range([0, height])
                .paddingInner(axisPadding)
                .paddingOuter(barCategoryGap / height);
            let x = d3
                .scaleLinear()
                .domain([minYRange, maxYRange])
                .range([0, width]);

            // Even though the axes are flipped we stile use maxYRange
            // Make sure that labels fit
            let label = svg
                .append("text")
                .text(formatValue(dataMax, false).join(""))
                .attr("fill", "transparent")
                .attr("font-size", labelsFontSize);
            const labelWidth = x.invert(label.node()!.getBBox().width);
            label.remove();
            if (Math.abs(dataMax - maxYRange) < labelWidth) {
                x.domain([minYRange, maxYRange + labelWidth]);
            } else {
                x.domain([minYRange, maxYRange + x.invert(5)]);
            }

            let yAxisContainer = svg.append("g").call(
                d3
                    .axisLeft(y)
                    .tickSize(1)
                    .tickFormat((_value: string, index: number) =>
                        cutXAxisName(data[index].shortName ?? data[index].name)
                    )
            );
            yAxisContainer
                .selectAll("path")
                .attr(
                    "stroke",
                    props.axesLinesColor ??
                        mainStyle.getPropertyValue("--graphs-axes-text-color")
                );

            // Add Y axis
            let xAxisContainer = svg.append("g");
            xAxisContainer.attr("transform", `translate(0, ${height})`);

            xAxisContainer.call(
                d3
                    .axisBottom<number>(x)
                    .tickValues(yTicks)
                    .tickFormat((value) => formatValue(value, false).join(""))
            );
            xAxisContainer
                .selectAll("path")
                .attr(
                    "stroke",
                    props.axesLinesColor ?? defaultColors.axesLinesColor
                );

            yAxisContainer
                .selectAll("line")
                .attr(
                    "stroke",
                    props.axesLinesColor ?? defaultColors.axesLinesColor
                );
            if (!themeOptions.showAxesLines) {
                yAxisContainer.selectAll("path").attr("visibility", "hidden");
                xAxisContainer.selectAll("path").attr("visibility", "hidden");
            }
            if (!themeOptions.showTickLines) {
                yAxisContainer.selectAll("line").attr("visibility", "hidden");
                xAxisContainer.selectAll("line").attr("visibility", "hidden");
            }
            xAxisContainer
                .selectAll("text")
                .attr(
                    "fill",
                    props.ticksColor ??
                        mainStyle.getPropertyValue("--graphs-axes-text-color")
                )
                .attr("font-size", xAxisFontSize);
            if (showXAxisTicks) {
                yAxisContainer
                    .selectAll("text")
                    .attr(
                        "fill",
                        props.ticksColor ??
                            mainStyle.getPropertyValue(
                                "--graphs-axes-text-color"
                            )
                    )
                    .attr("font-size", yAxisFontSize);
                let labels = xAxisContainer.selectAll<SVGTextElement, null>(
                    "text"
                );
                for (let label of labels) {
                    wrapText(label, yAxisWidth);
                }
            } else yAxisContainer.selectAll("text").attr("font-size", 0);

            const grid = svg
                .append("g")
                .selectAll("g")
                .data(yTicks)
                .join("g")
                .append("line")
                .attr("x1", (d) => x(d))
                .attr("x2", (d) => x(d))
                .attr("y1", 0)
                .attr("y2", height)
                .attr("fill", "none")
                .attr("stroke", props.axesLinesColor ?? defaultColors.gridColor)
                .attr("stroke-width", 1);
            if (themeOptions.dashGrid) grid.style("stroke-dasharray", "3, 3");
            if (!stacked) {
                if (!props.showAsDots) {
                    let ySubgroup = d3
                        .scaleBand()
                        .domain(groupNames)
                        .range([0, y.bandwidth()])
                        .paddingInner(groupPadding)
                        .paddingOuter(0);
                    if (props.isSideBySide) {
                        for (let d of data) {
                            let yAxisGroupContainer = svg
                                .append("g")
                                .attr("transform", `translate(0, ${y(d.name)})`)
                                .call(
                                    d3
                                        .axisLeft(ySubgroup)
                                        .tickSize(1)
                                        .tickFormat(
                                            (_value: string, index: number) => {
                                                return groupInfo
                                                    ? groupInfo[index].value
                                                    : groupNames[index];
                                            }
                                        )
                                );
                            yAxisGroupContainer
                                .selectAll("path")
                                .attr("visibility", "hidden");
                            yAxisGroupContainer
                                .selectAll("line")
                                .attr("visibility", "hidden");
                            yAxisGroupContainer
                                .selectAll("text")
                                .attr(
                                    "fill",
                                    props.ticksColor ??
                                        mainStyle.getPropertyValue(
                                            "--graphs-axes-text-color"
                                        )
                                )
                                .attr("font-size", yAxisFontSize);
                            const labels = yAxisGroupContainer.selectAll<
                                SVGTextElement,
                                null
                            >("text");
                            for (let label of labels) {
                                wrapText(label, yAxisWidth);
                            }
                        }
                    }
                    let g = svg
                        .append("g")
                        .selectAll("g")
                        // Enter in data = loop group per group
                        .data(data)
                        .join("g")
                        .attr("transform", (d) => `translate(0, ${y(d.name)})`);
                    g.selectAll("rect")
                        .data((d, dataIndex) =>
                            groupNames.map((key, index) => ({
                                key: key,
                                name: d.name,
                                value: groupNamesToData[dataIndex][key],
                                variableIndex: props.transpose
                                    ? props.data[dataIndex][index]
                                          ?.variableIndex
                                    : d.variableIndex,
                                index: props.transpose ? dataIndex : index,
                                dataIndex: props.transpose ? index : dataIndex,
                            }))
                        )
                        .join("rect")
                        .attr("x", (d) => (d.value < 0 ? x(d.value) : x(0)))
                        .attr("y", (d) => ySubgroup(d.key)!)
                        .attr("width", (d) =>
                            d.value < 0 ? x(0) - x(d.value) : x(d.value) - x(0)
                        )
                        .attr("height", barSize)
                        .attr("fill", color)
                        .attr("class", "chart-bar")
                        .attr("stroke", (entry) =>
                            entry.dataIndex === props.currentEditVariableIndex
                                ? "#8DB8E3"
                                : "#D1D1D1"
                        )
                        .on("mouseenter", mouseenter)
                        .on("mousemove", mousemove)
                        .on("mouseleave", mouseleave)
                        .on("click", clickBar);
                    g.selectAll("text")
                        .data((d, dataIndex) =>
                            groupNames.map((key) => ({
                                key: key,
                                value: groupNamesToData[dataIndex][key],
                            }))
                        )
                        .join("text")
                        .attr("width", barSize)
                        .attr("x", (d) => (d.value < 0 ? x(0) : x(d.value)))
                        .attr("y", (d) => ySubgroup(d.key)! + barSize / 2)
                        .attr("dy", "-0.35em")
                        .attr("text-anchor", "middle")
                        .attr(
                            "fill",
                            props.labelsColor ??
                                mainStyle.getPropertyValue(
                                    "--graphs-axes-text-color"
                                )
                        )
                        .attr("font-size", labelsFontSize)
                        .text((d) => formatValue(d!.value, false).join(""));
                } else {
                    let points = groupNames.map((key) =>
                        data.map((item, dataIndex) => ({
                            x: item.name,
                            y: groupNamesToData[dataIndex][key],
                        }))
                    );
                    for (let pointGroup of points) {
                        let line = svg
                            .append("path")
                            .datum(pointGroup)
                            .attr("fill", "none")
                            .attr(
                                "stroke",
                                props.lineColor ??
                                    (data[0].variableIndex == null
                                        ? "#D1D1D1"
                                        : props.barColors[0])
                            )
                            .attr("stroke-width", 1.5)
                            .attr(
                                "d",
                                d3
                                    .line<{ x: string; y: number }>()
                                    .x(
                                        (d, index) =>
                                            index *
                                                (barChartWidth / data.length) +
                                            barChartWidth / (2 * data.length)
                                    )
                                    .y((d) => y(d.y.toString()) ?? 0)
                            );
                        if (props.dashLine) {
                            line.attr("stroke-dasharray", "5, 5");
                        }
                    }
                    svg.append("g")
                        .selectAll("g")
                        // Enter in data = loop group per group
                        .data(data)
                        .join("g")
                        .selectAll("circle")
                        .data((d, dataIndex) =>
                            groupNames.map((key, index) => ({
                                key: key,
                                name: d.name,
                                value: groupNamesToData[dataIndex][key],
                                variableIndex: props.transpose
                                    ? props.data[dataIndex][index]
                                          ?.variableIndex
                                    : d.variableIndex,
                                index: props.transpose ? dataIndex : index,
                                dataIndex: props.transpose ? index : dataIndex,
                            }))
                        )
                        .join("circle")
                        .attr("cx", (d) => x(d.value))
                        .attr(
                            "cy",
                            (d) =>
                                d.dataIndex * (barChartWidth / data.length) +
                                barChartWidth / (2 * data.length)
                        )
                        .attr("r", props.dotRadius ?? 5)
                        .attr("fill", color)
                        .attr("class", "chart-bar")
                        .attr("stroke", (entry) =>
                            entry.dataIndex === props.currentEditVariableIndex
                                ? "#8DB8E3"
                                : "#D1D1D1"
                        )
                        .on("mouseenter", mouseenter)
                        .on("mousemove", mousemove)
                        .on("mouseleave", mouseleave)
                        .on("click", clickBar);
                }
            } else {
                svg.append("g")
                    .selectAll("g")
                    // Enter in data = loop group per group
                    .data(data)
                    .join("g")
                    .attr("transform", (d) => `translate(0, ${y(d.name)})`)
                    .selectAll("rect")
                    .data((d, dataIndex) =>
                        groupNames.map((key, index) => {
                            let prevValue = 0;
                            for (let i = 0; i < index; ++i) {
                                prevValue += Math.abs(
                                    groupNamesToData[dataIndex][groupNames[i]]
                                );
                            }
                            return {
                                key: key,
                                name: d.name,
                                value:
                                    prevValue +
                                    Math.abs(groupNamesToData[dataIndex][key]),
                                prevValue: prevValue,
                                variableIndex: props.transpose
                                    ? props.data[dataIndex][index]
                                          ?.variableIndex
                                    : d.variableIndex,
                                index: props.transpose ? dataIndex : index,
                                dataIndex: props.transpose ? index : dataIndex,
                            };
                        })
                    )
                    .join("rect")
                    .attr("x", (d) => x(Math.abs(d.prevValue)))
                    .attr("y", null)
                    .attr("height", barSize)
                    .attr("width", (d) => Math.abs(x(d.value) - x(d.prevValue)))
                    .attr("fill", color)
                    .attr("class", "chart-bar")
                    .attr("stroke", (entry) =>
                        entry.dataIndex === props.currentEditVariableIndex
                            ? "#8DB8E3"
                            : "#D1D1D1"
                    )
                    .on("mouseenter", mouseenter)
                    .on("mousemove", mousemove)
                    .on("mouseleave", mouseleave)
                    .on("click", clickBar);
            }
            if (props.horizontalLines != null) {
                let container = svg
                    .append("g")
                    .selectAll("g")
                    .data(props.horizontalLines.filter((item) => item != null))
                    .join("g");

                container
                    .append("line")
                    .attr("y1", 0)
                    .attr("y2", barChartWidth)
                    .attr("x1", (d) => x(d!.value))
                    .attr("x2", (d) => x(d!.value))
                    .attr("fill", "none")
                    .attr("stroke", "red")
                    .attr("stroke-width", 1)
                    .style("stroke-dasharray", "3, 3");
                container
                    .append("text")
                    .attr("y", barChartWidth - 20)
                    .attr("x", (d) => x(d!.value) - 10)
                    // .attr("dy", ".35em")
                    .attr("text-anchor", "start")
                    .style(
                        "fill",
                        props.ticksColor ??
                            mainStyle.getPropertyValue(
                                "--graphs-axes-text-color"
                            )
                    )
                    .style("font-weight", 700)
                    .style("font-size", 12)
                    .text((d) => d!.name);
            }
        }
    }, [
        props.data,
        gridFillColor,
        props.labelsColor,
        props.ticksColor,
        props.ticksAndLabels?.y?.interval,
        props.minYRange,
        props.maxYRange,
        props.barCategoryGap,
        props.barGap,
        props.barSize,
        props.showXAxisName,
        props.showYAxisName,
        props.sortYAxis,
        props.yAxisSortType,
        props.showAsDots,
        props.dotRadius,
        props.nameColorMapping,
        props.statusExpressions,
        props.axesLinesColor,
        props.barColors,
        props.circular,
        props.transpose,
        xAxisFontSize,
        yAxisFontSize,
        labelsFontSize,
        axisPadding,
        barChartWidth,
        yAxisWidth,
        barSize,
        width,
        themeOptions.dashGrid,
        fullWidth,
        props.currentEditVariableIndex,
        props.horizontalLines,
        groupPadding,
        themeOptions.showTickLines,
        themeOptions.showAxesLines,
        stacked,
        parentSvgRef.current?.clientHeight,
        parentSvgRef.current?.clientWidth,
        barGap,
        barCategoryGap,
        props.dashLine,
        props.lineColor,
        props.isSideBySide,
        props.editable,
        props.flip,
        colorPickerIsEnabled,
        clickBar,
        mousemove,
        mouseenter,
        mouseleave,
        color,
        data,
        groupInfo,
        groupNames,
        defaultColors.axesLinesColor,
        defaultColors.gridColor,
        showXAxisTicks,
        xAxisGroupHeight,
        yTicks,
        dataMin,
        dataMax,
        minYRange,
        maxYRange,
        groupNamesToData,
    ]);

    let tooltipStyle = {
        ...TooltipStyles(props.tooltipColor, props.tooltipFontSize),
    };
    let yAxisDiv = props.showYAxisName && (
        <div
            style={{
                height: !props.flip ? "100%" : 0,
                marginLeft: props.circular || !props.flip ? 0 : yAxisWidth,
                position: "relative",
                top: 5,
            }}
        >
            <EditableAxisItem
                height={axisItemStyles.yAxisHeight}
                onChange={(value) => {
                    props.onConfigChange({ yAxisName: value });
                }}
                color={props.axesNamesColor ?? "#333333"}
                currentEditVariableIndex={props.currentEditVariableIndex}
                index={-1}
                editable={props.editable}
                vertical={true}
                name={props.yAxisName ?? "Y Axis"}
                onDrop={() => {}}
            />
        </div>
    );
    let xAxesDiv = props.showXAxisName && (
        <div
            className="my-row"
            style={{
                flexDirection: props.flip ? "column" : "row",
                marginLeft: props.circular || props.flip ? 0 : yAxisWidth,
            }}
        >
            {props.originalData.map((item, index) => {
                return (
                    <EditableAxisItem
                        width={
                            props.circular
                                ? `calc(100%/${props.originalData.length})`
                                : !props.flip
                                ? barChartWidth / props.originalData.length
                                : yAxisWidth
                        }
                        height={
                            props.circular || !props.flip
                                ? undefined
                                : barChartWidth / props.originalData.length
                        }
                        onChange={(value) => {
                            props.onRenameVariable(index, value);
                        }}
                        color={props.axesNamesColor ?? "#333333"}
                        currentEditVariableIndex={
                            props.currentEditVariableIndex
                        }
                        editable={props.editable}
                        index={index}
                        vertical={!props.flip ? false : true}
                        name={item.name}
                        onDrop={props.onLinkVariable}
                    />
                );
            })}
        </div>
    );
    return (
        <div
            style={{
                height: "100%",
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                width: "100%",
            }}
        >
            <span
                style={{
                    fontFamily: "Arial",
                    fontSize: titleSize,
                    color: mainStyle.getPropertyValue(
                        "--exploration-secondary-text-color"
                    ),
                    display: "block",
                    textAlign: "center",
                }}
            >
                {props.title}
            </span>

            <div
                ref={parentRef}
                style={{
                    width: props.circular || props.flip ? "100%" : fullWidth,
                    height: !props.flip ? "100%" : fullWidth,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        height: !props.flip ? "100%" : undefined,
                        width: props.flip ? "100%" : undefined,
                        display: "flex",
                    }}
                >
                    {!props.flip ? yAxisDiv : xAxesDiv}
                    <div
                        style={{
                            width:
                                props.circular || props.flip
                                    ? "100%"
                                    : fullWidth,
                            height: props.flip ? fullWidth : "100%",
                            display: "flex",
                            flexDirection: "column",
                            // Resizing does not work properly
                            // without overflow: "hidden"
                            overflow: "hidden",
                        }}
                    >
                        <div
                            ref={parentSvgRef}
                            style={{
                                width:
                                    props.circular || props.flip
                                        ? "100%"
                                        : fullWidth,
                                height: props.flip ? fullWidth : "100%",
                                display: "flex",
                                position: "relative",
                                // Resizing does not work properly
                                // without overflow: "hidden"
                                overflow: "hidden",
                            }}
                        >
                            {showTooltipInfo && !showBarColorMenu && (
                                <Portal rootNode={document.body}>
                                    <div
                                        style={{
                                            zIndex: 100000,
                                            position: "absolute",
                                            top: showTooltipInfo.y,
                                            left: showTooltipInfo.x + 5,
                                            padding: "10px",
                                            ...tooltipStyle.contentStyle,
                                            display: "flex",
                                            flexDirection: "column",
                                            pointerEvents: "none",
                                        }}
                                    >
                                        <span
                                            className="unselectable"
                                            style={tooltipStyle.labelStyle}
                                        >
                                            {data[showTooltipInfo.index].name}
                                        </span>
                                        {groupNames.map((key, index) => (
                                            <span
                                                style={{
                                                    ...tooltipStyle.itemStyle,
                                                    color: color(
                                                        {
                                                            variableIndex: props.transpose
                                                                ? props.data[0][
                                                                      index
                                                                  ]
                                                                      ?.variableIndex
                                                                : data[
                                                                      showTooltipInfo!
                                                                          .index
                                                                  ]
                                                                      .variableIndex,
                                                            value:
                                                                groupNamesToData[
                                                                    showTooltipInfo!
                                                                        .index
                                                                ][
                                                                    groupNames[
                                                                        index
                                                                    ]
                                                                ],
                                                            dataIndex: props.transpose
                                                                ? index
                                                                : showTooltipInfo!
                                                                      .index,
                                                            index: props.transpose
                                                                ? showTooltipInfo!
                                                                      .index
                                                                : index,
                                                        } as EntryData,
                                                        showTooltipInfo!.index
                                                    ),
                                                }}
                                                className="unselectable"
                                            >
                                                {`${key}: ${formatValue(
                                                    groupNamesToData[
                                                        showTooltipInfo!.index
                                                    ][groupNames[index]]
                                                ).join("")}`}
                                            </span>
                                        ))}
                                    </div>
                                </Portal>
                            )}
                            <svg ref={svgRef} />
                        </div>
                        {!props.flip ? xAxesDiv : yAxisDiv}
                    </div>
                </div>
            </div>
            {showBarColorMenu && (
                <Portal rootNode={document.body}>
                    <OutsideAlerter
                        onReject={(event) => {
                            const clickedElementIsDendrogramCircle = Array.from(
                                (event.target as HTMLElement)?.classList
                            ).includes("chart-bar");

                            if (!clickedElementIsDendrogramCircle) {
                                setShowBarColorMenu(null);
                            }
                        }}
                    >
                        <div
                            style={{
                                zIndex: 100000000,
                                position: "absolute",
                                left: showBarColorMenu.x,
                                top: showBarColorMenu.y,
                            }}
                        >
                            <ColorPicker
                                enableAlpha={true}
                                width={"220px"}
                                color={
                                    props.nameColorMapping?.[
                                        showBarColorMenu.dataIndex
                                    ] ??
                                    (showBarColorMenu.variableIndex == null
                                        ? "#D1D1D1"
                                        : props.barColors[
                                              showBarColorMenu.dataIndex %
                                                  props.barColors.length
                                          ])
                                }
                                onChange={(color) => {
                                    let nameColorMapping = {
                                        ...props.nameColorMapping,
                                    };
                                    nameColorMapping[
                                        showBarColorMenu!.dataIndex
                                    ] = color;
                                    props.onConfigChange({
                                        nameColorMapping: nameColorMapping,
                                    });
                                }}
                            />
                        </div>
                    </OutsideAlerter>
                </Portal>
            )}
            {editYAxisName && (
                <Portal rootNode={document.body}>
                    <input
                        style={{
                            zIndex: 1000,
                            position: "absolute",
                            fontFamily: "Roboto",
                            fontSize: "14",
                            fontWeight: 500,
                            textAlign: "center",
                            color: props.axesNamesColor ?? "#333333",
                            outline: "none",
                            border: "1px solid #8DB8E3",
                            left: editYAxisName.boundingRect.x,
                            top: editYAxisName.boundingRect.y,
                            width: editYAxisName.boundingRect.width,
                            height: editYAxisName.boundingRect.height,
                        }}
                        defaultValue={props.yAxisName ?? "Y Axis"}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                            if (evt.key === "Enter") {
                                props.onConfigChange({
                                    yAxisName: evt.currentTarget.value,
                                });
                                setEditYAxisName(null);
                            }
                        }}
                        onBlur={(evt) => {
                            props.onConfigChange({
                                yAxisName: evt.currentTarget.value,
                            });

                            setEditYAxisName(null);
                        }}
                    ></input>
                </Portal>
            )}
        </div>
    );
}

export default CustomBarChart;
