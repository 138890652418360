import "common/styles/App.css";
import React, { Component, useContext } from "react";
import { observer } from "mobx-react";
import ModuleCard from "common/home_components/ModuleCard";
import TabType from "common/TabType";
import ViewMode from "common/home_components/PresentationTabs/ViewMode";
import Toolbar from "common/home_components/Toolbar";
import UserOnboarding from "common/home_components/UserOnboarding";
import PresentationTabs from "common/home_components/PresentationTabs";
import ThemesBar from "common/home_components/ThemesBar";
import Instrumentation from "common/Instrumentation";
import mobileBreakpoint, { getWidth, getHeight } from "common/utilities/UIResponsiveManager";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import StoreContext, { store } from "state/StoreContext";
import { goToInternalLink } from "common/InternalLinksHelper";
import MessagePopup from "common/MessagePopup";
import Popup from "reactjs-popup";
import {unhideUserModuleApi } from "common/ModulesApi";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import linkIdToUrl from "common/utilities/linkIdToUrl";
import WelcomeTour from "common/WelcomeTour";
import { IReactionDisposer, reaction } from "mobx";
import styles from "./home_page.module.css";
import CurrentUser from "common/CurrentUser";
import { UserType } from "common/UserInfo";
import GetStartedModal from "./GetStartedModal";
import WeRecommendModal from "./WeRecommendModal";
import { updateUserInfo } from "common/UserApi";
import TosContext from "contexts/TosContext";
import { confirmSession } from "common/PremiumPlansApi";
import { Folder, FolderType } from "common/FoldersApi";
import FolderCreator from "common/home_components/FolderCreator";
import CategoriesBar from "common/home_components/CategoriesBar";
import CreateNewButton from "common/home_components/CreateNewButton";
import Presentation, { CategoryData } from "state/models/Presentation";
import RootStore from "state/store";
import { getCategoryApi } from "common/CategoriesApi";

export const defaultSelectedCategoryData = {
    id: null,
    title: "",
    thumbnail: "",
}

interface Props {
    store: RootStore;
    globalContext: GlobalContextContents;
}

interface State {
    innerWidth: number,
    innerHeight: number,
    popupHeight: number,
    popupWidth: number,
    creationInfo: {
        status: PopupStatus,
        message: string
    } | null,
    deleteInfo: {
        id: number,
        isTemplate: boolean,
        moveToTrash?: boolean,
    } | undefined,
    restoreInfo: {
        id: string | number,
    } | undefined,
    currentModule: Presentation | null,
    viewMode: ViewMode,
    showTour: boolean,
    showGetStarted: boolean,
    showWeRecommend: boolean,
    status: {
        status: PopupStatus,
        message: string,
    } | null,
    newFolderType: FolderType | null,
    editFolderId: number | null,
    editFolderTitle: string | null,
    deleteFolder: Folder | null,
    selectedCategory: CategoryData,
    reload: boolean
}


function defaultState(pageId: number): State {
    return {
        innerWidth: getWidth(),
        innerHeight: getHeight(),
        popupHeight: 0,
        popupWidth: 0,
        creationInfo: null,
        deleteInfo: undefined,
        restoreInfo: undefined,
        currentModule: null,
        viewMode: ViewMode.Grid,
        showTour: false,
        showGetStarted: false,
        showWeRecommend: false,
        status: null,
        newFolderType: null,
        editFolderId: null,
        editFolderTitle: null,
        deleteFolder: null,
        selectedCategory: defaultSelectedCategoryData,
        reload: false
    };
}

function shouldShowGettingStartedFlow() {
    if (CurrentUser.isRoot) return false;
    if (CurrentUser.info == null) return false;
    const { interest_1, interest_2, interest_3, familiar_with_data } =
        CurrentUser.info;
    return !((interest_1 && interest_2 && interest_3) || familiar_with_data);
}

const MainComponent = observer(
    class MainComponent extends Component<Props, State> {
        static contextType = TosContext;
        public tabType: number | null = null;
        public performance: Date | null = null;
        public mounted: boolean = false;
        public sessionId: string | null = "";
        public categoryId: string | null = "";
        public currentUserReaction: IReactionDisposer | null = null;

        constructor(props: Props) {
            super(props);
            this.state = defaultState(-1);
            this.tabType = TabType.modules;
            this.updateDimensions = this.updateDimensions.bind(this);
            this.onModuleOpen = this.onModuleOpen.bind(this);
            this.handleGetStartedDone = this.handleGetStartedDone.bind(this);
            this.handleGetStartedSkip = this.handleGetStartedSkip.bind(this);
            this.handleWeRecommendDone = this.handleWeRecommendDone.bind(this);
            this.onClickPresentation = this.onClickPresentation.bind(this);
            const urlParams = new URLSearchParams(window.location.search);
            this.sessionId = urlParams.get("session_id");
            this.categoryId = urlParams.get("category_id");
        }

        popupHeight() {
            return this.state.innerHeight * 0.7;
        }
        updateDimensions() {
            // calcScale(false);
            let innerWidth = getWidth();
            let innerHeight = getHeight();
            this.setState({
                innerWidth: innerWidth,
                innerHeight: innerHeight,
            });
        }
        popupWidth() {
            return this.state.innerWidth * 0.7;
        }

        onModuleOpen(module: Presentation) {
            this.setState({ currentModule: module });
        }
        componentDidMount() {
            if (this.sessionId != null) {
                confirmSession(this.sessionId)
                    .then(() => {
                        this.setState({
                            status: {
                                status: PopupStatus.Success,
                                message:
                                    "Your account was upgraded. Page will be reloaded",
                            },
                        });

                        window.history.replaceState(
                            {},
                            document.title,
                            `/home.html`
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    })
                    .catch((error) => {
                        this.setState({
                            status: {
                                status: PopupStatus.Error,
                                message: String(error),
                            },
                        });
                    });
            }

            this.fetchApps();

            if (this.performance != null) {
                let timeMs = new Date().getTime() - this.performance.getTime();
                this.performance = null;
                Instrumentation.addInteraction("Modules", timeMs);
            }
            this.updateDimensions();
            window.addEventListener("resize", this.updateDimensions);

            if (CurrentUser.info != null) {
                this.setState({
                    showGetStarted: shouldShowGettingStartedFlow(),
                });
            } else {
                this.currentUserReaction = reaction(
                    () => CurrentUser.infoState,
                    () => {
                        this.currentUserReaction?.();
                        this.setState({
                            showGetStarted: shouldShowGettingStartedFlow(),
                        });
                    }
                );
            }

            if (this.categoryId) {
                getCategoryApi(parseInt(this.categoryId, 10)).then(category => {
                    if (category) {
                        this.setState({
                            selectedCategory: category,
                        })
                    }
                });
                
            }
        }

        componentWillUnmount() {
            if (this.currentUserReaction != null) {
                this.currentUserReaction();
            }
            window.removeEventListener("resize", this.updateDimensions);
        }

        componentDidUpdate(prevProps: Props, prevState: State) {
            if(this.state.reload !== prevState.reload)
                store.presentationStore.fetchTemplates();
                
            if (this.performance != null) {
                let timeMs = new Date().getTime() - this.performance.getTime(); // Speak about
                this.performance = null;
                Instrumentation.addInteraction("Modules", timeMs);
            }
        }

        fetchPresentations = () => {
            const { store } = this.props;

            if (!store.presentationStore.presentations.length) {
                store.presentationStore.fetchPresentations();
            }
        };

        fetchTemplates = () => {
            const { store } = this.props;

            if (!store.presentationStore.templates.length) {
                store.presentationStore.fetchTemplates();
            }
        };

        fetchApps = () => {
            const { store } = this.props;

            if (!store.presentationStore.apps.length) {
                store.presentationStore.fetchApps();
            }
        };

        searchPresentations = (searchText: string) => {
            const { store } = this.props;
            store.presentationStore.searchPresentations(searchText);
        };

        createPresentation = async () => {
            try {
                const presentationId =
                    await store.presentationStore.createBlankPresentation();
                goToInternalLink(
                    `canvas.html?current_module_id=${presentationId}`
                );
            } catch (error) {
                this.setState({
                    creationInfo: {
                        status: PopupStatus.Error,
                        message: `Creation error: ${String(error)}`,
                    },
                });
            }
        };
        openApp = (appId: string) => {
            goToInternalLink(linkIdToUrl(true, appId));
        };
        openPresentation = (presentationId: number) => {
            unhideUserModuleApi(presentationId)
                .then((id) => {
                    goToInternalLink(`canvas.html?current_module_id=${id}`);
                })
                .catch((error) => {
                    this.setState({
                        creationInfo: {
                            status: PopupStatus.Error,
                            message: `Error: ${String(error)}`,
                        },
                    });
                });
        };

        changeViewMode = (viewMode: ViewMode) => {
            this.setState({ viewMode });
        };

        handleGetStartedDone(
            interest_1: string,
            interest_2: string,
            interest_3: string,
            familiar_with_data: string
        ) {
            updateUserInfo({
                interest_1,
                interest_2,
                interest_3,
                familiar_with_data,
            }).finally(() => {
                this.setState({ showGetStarted: false, showWeRecommend: true });
            });
        }

        handleGetStartedSkip() {
            const skipped = "_Skipped_";
            updateUserInfo({
                interest_1: skipped,
                interest_2: skipped,
                interest_3: skipped,
                familiar_with_data: skipped,
            }).finally(() => {
                this.setState({
                    showGetStarted: false,
                    showWeRecommend: false,
                });
            });
        }

        handleWeRecommendDone() {
            this.setState({ showWeRecommend: false });
        }

        onClickPresentation(key: string, id: number | string) {
            if (key === "presentations") {
                this.openPresentation(id as number);
            }
            if (key === "apps") {
                this.openApp(id as string);
            }
            if (key === "templates") {
                let template = store.presentationStore.templates.find(
                    (template) => template.id === id
                );
                if (template != null)
                    this.setState({
                        currentModule: template,
                    });
            }
            if (key === "archive") {
                this.setState({
                    restoreInfo: {
                        id: id,
                    },
                });
            }
        }

        render() {
            let isViewer =
                CurrentUser.info != null &&
                CurrentUser.info.type === UserType.Viewer;
            if (!this.mounted) {
                this.performance = new Date();
                this.mounted = true;
            }

            const { viewMode } = this.state;

            const showOnboarding = false;

            const themeBarData = [
                ...store.presentationStore.templates.filter(template => 
                    template.categoryId && template.categoryId === this.state.selectedCategory.id
                ),
                ...store.presentationStore.apps.filter(app => 
                    app.categoryId && app.categoryId === this.state.selectedCategory.id
                ),
            ];

            const showThemeBar = !!this.state.selectedCategory.id && !!themeBarData.length;

            const onDeletePresentation = (key: string, id: number) => {
                if (key === "presentations") {
                    this.setState({
                        deleteInfo: {
                            id: id,
                            isTemplate: false,
                            moveToTrash: true,
                        },
                    });
                }
                if (key === "templates") {
                    this.setState({
                        deleteInfo: {
                            id: id,
                            isTemplate: true,
                        },
                    });
                }
                if (key === "archive") {
                    this.setState({
                        deleteInfo: {
                            id: id,
                            isTemplate: false,
                        },
                    });
                }
            }

            return (
                <>
                    <div className="content-wrapper">
                        {showOnboarding ? (
                            <UserOnboarding
                                onClickTemplate={(id) => {
                                    let template =
                                        store.presentationStore.templates.find(
                                            (template) => template.id === id
                                        );
                                    if (template != null)
                                        this.setState({
                                            currentModule: template,
                                        });
                                }}
                                onDeleteTemplate={(id) => {
                                    this.setState({
                                        deleteInfo: {
                                            id: id,
                                            isTemplate: true,
                                        },
                                    });
                                }}
                            />
                        ) : (
                            CurrentUser.info != null &&
                            !isViewer && (
                                <div className={styles.topBarsContainer}>
                                    <div className={styles.content}>
                                        <Toolbar
                                            title="START FROM EXISTING CONTENT"
                                            onChangeSearchText={
                                                this.searchPresentations
                                            }
                                            createPresentation={this.createPresentation}
                                        />
                                        <CategoriesBar
                                            category={this.state.selectedCategory}
                                            setSelectedCategory={(category) => {
                                                this.setState({
                                                    selectedCategory: category,
                                                })
                                                if (this.categoryId) {
                                                    window.history.replaceState(
                                                        {},
                                                        document.title,
                                                        `/home.html?category_id=${category.id}`
                                                    );
                                                }
                                            }}
                                        />
                                        {showThemeBar && (
                                            <ThemesBar
                                                themeBarData={themeBarData}
                                                onClickTheme={this.onClickPresentation}
                                                deleteTheme={(id: number) => {
                                                    onDeletePresentation("templates", id);
                                                }}
                                            />
                                        )}
                                        {!mobileBreakpoint() ? (
                                            <div className={styles.createNewSection}>
                                                <p className={styles.middleText}>BUILD SOMETHING </p>
                                                <CreateNewButton
                                                    onClickNew={this.createPresentation}
                                                />
                                            </div>
                                        ) : (
                                            <div style={{ flexGrow: 1 }} />
                                        )}
                                    </div>
                                </div>
                            )
                        )}
                        <div className={styles.content}>
                            <section>
                                {CurrentUser.info != null && (
                                    <PresentationTabs
                                        isViewer={isViewer}
                                        onClickPresentation={
                                            this.onClickPresentation
                                        }
                                        onCreateFolder={(key) => {
                                            this.setState({
                                                newFolderType:
                                                    key === "presentations"
                                                        ? FolderType.Presentations
                                                        : FolderType.Templates,
                                            });
                                        }}
                                        onRenameFolder={(folder) => {
                                            this.setState({
                                                editFolderTitle: folder.title,
                                                newFolderType: folder.type,
                                                editFolderId: folder.id,
                                            });
                                        }}
                                        onDeleteFolder={(folder) => {
                                            this.setState({
                                                deleteFolder: folder,
                                            });
                                        }}
                                        onDeletePresentation={onDeletePresentation}
                                        viewMode={viewMode}
                                        changeViewMode={this.changeViewMode}
                                    />
                                )}
                            </section>
                            <Popup
                                arrow={true}
                                contentStyle={{
                                    width: this.popupWidth(),
                                    maxWidth: this.popupWidth(),
                                    top: 20,
                                    minHeight: this.popupHeight(),
                                    border: "none",
                                    backgroundColor: "transparent",
                                }}
                                open={this.state.currentModule != null}
                                onClose={() => {
                                    this.setState({ currentModule: null });
                                }}
                                closeOnDocumentClick
                            >
                                <div
                                    className="dashboard-rect element"
                                    style={{
                                        minHeight: "100%",
                                        width: this.popupWidth(),
                                    }}
                                >
                                    {this.state.currentModule ? (
                                        <ModuleCard
                                            doReload={() => {
                                                this.setState({
                                                    reload: !this.state.reload
                                                })
                                            }}
                                            reload={this.state.reload}
                                            sectionName={"Modules"}
                                            parentContainerHeight={this.popupHeight()}
                                            parentContainerWidth={this.popupWidth()}
                                            module={this.state.currentModule}
                                        />
                                    ) : null}
                                </div>
                            </Popup>

                            {this.state.deleteInfo && (
                                <MessagePopup
                                    danger
                                    title={
                                        this.state.deleteInfo.moveToTrash
                                            ? "Move to trash"
                                            : "Delete"
                                    }
                                    message={`Are you sure you want to ${
                                        this.state.deleteInfo.moveToTrash
                                            ? "move to trash"
                                            : "delete"
                                    } this ${
                                        this.state.deleteInfo.isTemplate
                                            ? "template"
                                            : "presentation"
                                    }?`}
                                    acceptButtonTitle={
                                        this.state.deleteInfo.moveToTrash
                                            ? "Move to trash"
                                            : "Delete"
                                    }
                                    onAccept={() => {
                                        if (!this.state.deleteInfo) return;

                                        this.performance = new Date();
                                        if (!this.state.deleteInfo.isTemplate) {
                                            if (
                                                this.state.deleteInfo
                                                    .moveToTrash
                                            ) {
                                                store.presentationStore.movePresentationToTrash(
                                                    this.state.deleteInfo.id
                                                );
                                            } else {
                                                store.presentationStore.deletePresentationFromTrash(
                                                    this.state.deleteInfo.id
                                                );
                                            }
                                        } else {
                                            store.presentationStore.deletePresentationFromTemplates(
                                                this.state.deleteInfo.id
                                            );
                                        }

                                        this.setState({
                                            deleteInfo: undefined,
                                        });
                                    }}
                                    onReject={() => {
                                        this.setState({
                                            deleteInfo: undefined,
                                        });
                                    }}
                                />
                            )}
                            {this.state.deleteFolder && (
                                <MessagePopup
                                    title={"Delete folder"}
                                    message={
                                        "Are you want to delete folder (data will be kept)?"
                                    }
                                    acceptButtonTitle={"Delete"}
                                    onAccept={() => {
                                        if (!this.state.deleteFolder) return;

                                        this.performance = new Date();
                                        store.presentationStore.deleteFolder(
                                            this.state.deleteFolder
                                        );
                                        this.setState({
                                            deleteFolder: null,
                                        });
                                    }}
                                    onReject={() => {
                                        this.setState({
                                            deleteFolder: null,
                                        });
                                    }}
                                />
                            )}
                            {this.state.restoreInfo && (
                                <MessagePopup
                                    title={"Restore presentation"}
                                    message={
                                        "Are you sure you want to restore this presentation?"
                                    }
                                    acceptButtonTitle={"Restore"}
                                    onAccept={() => {
                                        if (!this.state.restoreInfo) return;

                                        this.performance = new Date();
                                        store.presentationStore.restorePresentationFromTrash(
                                            this.state.restoreInfo.id as number
                                        );
                                        this.setState({
                                            restoreInfo: undefined,
                                        });
                                    }}
                                    onReject={() => {
                                        this.setState({
                                            restoreInfo: undefined,
                                        });
                                    }}
                                />
                            )}
                            {this.state.creationInfo && (
                                <StatusPopup
                                    status={this.state.creationInfo.status}
                                    message={this.state.creationInfo.message}
                                    onClose={() => {
                                        this.setState({ creationInfo: null });
                                    }}
                                />
                            )}
                            <WelcomeTour
                                run={this.state.showTour}
                                onFinish={() => console.log("FINISHED TOUR")}
                            />
                            <GetStartedModal
                                show={
                                    this.state.showGetStarted &&
                                    this.context.agreed
                                }
                                onDone={this.handleGetStartedDone}
                                onSkip={this.handleGetStartedSkip}
                            />
                            <WeRecommendModal
                                show={this.state.showWeRecommend}
                                onDone={this.handleWeRecommendDone}
                                onSkip={this.handleWeRecommendDone}
                                onClickPresentation={(type, id) => {
                                    this.setState({ showWeRecommend: false });
                                    this.onClickPresentation(type, id);
                                }}
                            />
                        </div>
                    </div>
                    {this.state.status && (
                        <StatusPopup
                            status={this.state.status.status}
                            message={this.state.status.message}
                            onClose={() => {
                                this.setState({ status: null });
                            }}
                        />
                    )}
                    {this.state.newFolderType != null && (
                        <FolderCreator
                            type={this.state.newFolderType}
                            title={this.state.editFolderTitle}
                            editFolderId={this.state.editFolderId}
                            onSave={(newFolderId) => {
                                this.setState({
                                    newFolderType: null,
                                    editFolderId: null,
                                    editFolderTitle: null,
                                    status: {
                                        status: PopupStatus.Success,
                                        message:
                                            newFolderId != null
                                                ? "Folder is created"
                                                : "Folder is edited",
                                    },
                                });
                            }}
                            onClose={() => {
                                this.setState({
                                    newFolderType: null,
                                    editFolderId: null,
                                    editFolderTitle: null,
                                });
                            }}
                            onError={(errorMessage) => {
                                this.setState({
                                    status: {
                                        status: PopupStatus.Error,
                                        message: errorMessage,
                                    },
                                });
                            }}
                        />
                    )}
                </>
            );
        }
    }
);

const ConnectedComponent = () => {
    const store = useContext(StoreContext);
    const globalContext = useContext(GlobalContext);

    return <MainComponent store={store} globalContext={globalContext} />;
};

export { ConnectedComponent as MainComponent };
export let route = "/home.html",
    section = "Modules",
    leftPanelItem = {
        section: "Home",
        href: "home.html",
        icon: "Home",
    },
    requirePermission = "Modules";
