import { observable, makeObservable, runInAction } from "mobx";

class PinInformationStore {
    @observable expandedPinId: number | null;
    newPinId: number | null;
    constructor() {
        makeObservable(this);
        this.newPinId = null;
        this.expandedPinId = null;
    }
    public setExpandedPinId(pinId: number | null) {
        runInAction(() => {
            this.expandedPinId = pinId;
        });
    }
    public setNewPinId(pinId: number | null) {
        this.newPinId = pinId;
    }
}

export default new PinInformationStore();