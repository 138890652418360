import React from "react";
import styles from "./SliderConfig.module.css";
import { SurveyQuestion } from "common/Canvas";
import Input from "modules/canvas_page/AdvancedModeMenu/common/Input";

interface SliderConfigProps {
    onChange: (questions: SurveyQuestion) => void,
    question: SurveyQuestion
}

const SliderConfig: React.FC<SliderConfigProps> = ({ onChange, question }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.flexRow}>
                <p className={styles.text}>Min</p>
                
                <Input
                    type="number"
                    step={1}
                    decimals={0}
                    value={question.min as string}
                    onChange={(value: string | number) => {
                        let newQuestion = question;
                        newQuestion.min = value + '';
                        onChange(newQuestion);
                    }}
                />
            </div>
            <div className={styles.flexRow}>
                <p className={styles.text}>Max</p>

                <Input
                    type="number"
                    step={1}
                    decimals={0}
                    value={question.max as string}
                    onChange={(value: string | number) => {
                        let newQuestion = question;
                        newQuestion.max = value + '';
                        onChange(newQuestion);
                    }}
                />
            </div>
        </div>
    )
}

export default SliderConfig
