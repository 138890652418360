import React from "react";

import { colorList } from "./LineColors";
//import db from "./db";
//import InsightsType from "./InsightsType";
import { LegendElement } from "./LegendElement";
import { LegendEditElement } from "./LegendEditElement";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";
import { TooltipStyles } from "./TooltipStyles";

import {
	PieChart,
	Pie,
	Cell,
	ResponsiveContainer,
	Tooltip as RechartsTooltip,
} from "recharts";

function DonutChart(props) {
	let {
		data,
		colorMapping,
		nameMapping,
		sumLabel,
		sumColor,
		editable,
		onSumColorChange,
		onSumLabelChange,
	} = props;
	let sum = data.reduce((accumulator, item) => {
		return accumulator + item.value;
	}, 0);
	let sumValue = formatValue(sum, false);
	sumLabel = sumLabel ?? "Total Sum:";
	sumValue = (data[0].units !== "%" ? (data[0].units ?? "") : "")
		.concat(sumValue[0].concat(sumValue[1]))
		.concat(data[0].units === "%" ? (data[0].units ?? "") : "");
	let plot = (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%",
			}}
		>
			{editable ? (
				<div className="my-row" style={{ justifyContent: "center" }}>
					<LegendEditElement
						allowColorChange
						onFinishEdit={onSumLabelChange}
						onFinishColor={onSumColorChange}
						color={sumColor ?? "#FFAB4F"}
						text={sumLabel}
						textColor={sumColor ?? "#FFAB4F"}
					/>
					<span
						style={{
							color: sumColor ?? "#FFAB4F",
							margin: 10,
							textAlign: "center",
							fontFamily: "Roboto",
							fontSize: "15px",
							wordWrap: "break-word",
						}}
					>
						{sumValue}
					</span>
				</div>
			) : (
				<span
					style={{
						color: sumColor ?? "#FFAB4F",
						margin: 10,
						textAlign: "center",
						fontFamily: "Roboto",
						fontSize: "15px",
						wordWrap: "break-word",
					}}
				>
					{[sumLabel, sumValue].join(" ")}
				</span>
			)}
			<div style={{ height: "100%", overflow: "hidden" }}>
				<ResponsiveContainer width="100%" height="99%">
					<PieChart>
						{sections._Global.watermark != null && (
							<text
								x="50%"
								y="30%"
								textAnchor="middle"
								dominantBaseline="middle"
								fill={mainStyle.getPropertyValue(
									"--content-secondary-text-color"
								)}
								fontSize={"4rem"}
								style={{
									opacity: 0.1,
								}}
							>
								{sections._Global.watermark}
							</text>
						)}
						<RechartsTooltip
							formatter={(value, name, props) => {
								let formattedValue = formatValue(value, false);
								let formattedValueSum = formatValue(
									(value * 100) / sum,
									false
								);
								let result = "";
								if (props.payload.units === "%")
									result = formattedValue[0]
										.concat(formattedValue[1])
										.concat(props.payload.units);
								else
									result = props.payload.units
										.concat(formattedValue[0])
										.concat(formattedValue[1]);
								result = result
									.concat(", ")
									.concat(formattedValueSum[0])
									.concat(formattedValueSum[1])
									.concat("%");
								return [result, nameMapping?.[name] ?? name];
							}}
							{...TooltipStyles()}
						/>
						<Pie
							isAnimationActive={false}
							data={data}
							innerRadius="80%"
							outerRadius="100%"
							fill="#8884d8"
							paddingAngle={5}
							dataKey="value"
						>
							{data.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={
										colorMapping?.[entry.name] ??
										colorList[index % colorList.length]
									}
								/>
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
	return plot;
}

export default function RatiosChart(props) {
	let { data, config, editable } = props;
	let {
		nameMapping,
		labelMapping,
		colorMapping,
		legendsColor,
		titlesColor,
		sumColors,
		sumLabels,
	} = config ?? {};
	if (data.length === 0) return null;

	return (
		<div
			className="my-row"
			style={{
				margin: "0 auto",
				width: "100%",
				height: "100%",
			}}
		>
			{data.map((series, serieIndex) => {
				let key = "Overall";
				if (series.where) {
					key = `${series.where.group || series.where.group_name} ${
						series.where.operation ?? "="
					} ${series.where.value}`;
				}
				let values = series["values"];

				let variablesKeys = values.map((item) => item.name);
				if (variablesKeys.length === 0) return null;

				let contentWidth = data.length === 1 ? "100%" : "50%";
				return (
					<div
						className="flex-simple-column"
						key={serieIndex}
						style={{ width: contentWidth, height: "100%" }}
					>
						<div
							style={{
								marginBottom: 10,
								display: "flex",
								justifyContent: "center",
							}}
						>
							{serieIndex === 0 && editable && (
								<LegendEditElement
									allowColorChange
									disallowTextChange
									onFinishColor={(color) => {
										props.onConfigChange(
											"titlesColor",
											color
										);
									}}
									key={variablesKeys.length + 2}
									color={
										titlesColor ??
										mainStyle
											.getPropertyValue(
												"--graphs-legends-text-color"
											)
											.trim()
									}
									text={"Titles Color"}
								/>
							)}
							{serieIndex === 0 && editable && (
								<LegendEditElement
									allowColorChange
									disallowTextChange
									onFinishColor={(color) => {
										props.onConfigChange(
											"legendsColor",
											color
										);
									}}
									key={variablesKeys.length + 1}
									color={
										legendsColor ??
										mainStyle
											.getPropertyValue(
												"--graphs-legends-text-color"
											)
											.trim()
									}
									text={"Legends Color"}
								/>
							)}
							{editable ? (
								<input
									defaultValue={labelMapping?.[key] ?? key}
									onKeyDown={(evt) => {
										evt.stopPropagation();
									}}
									placeholder=""
									onBlur={(e) => {
										e.preventDefault();
										props.onFinishEditLabel(
											key,
											e.target.value
										);
									}}
									style={{
										marginLeft: "10px",
										width: "100px",
										alignSelf: "center",
										backgroundColor: "transparent",
										outline: "none",
										color:
											titlesColor ??
											mainStyle
												.getPropertyValue(
													"--graphs-legends-text-color"
												)
												.trim(),
										fontFamily: "Arial",
										fontSize: mainStyle.getPropertyValue(
											"--graphs-legends-size"
										),
									}}
								></input>
							) : (
								<span
									style={{
										color:
											titlesColor ??
											mainStyle.getPropertyValue(
												"-graphs-legends-text-color"
											),
										fontFamily: "Arial",
										fontSize: mainStyle.getPropertyValue(
											"--graphs-legends-size"
										),
									}}
								>
									{labelMapping?.[key] ?? key}
								</span>
							)}
						</div>
						<div
							className="my-row"
							style={{
								justifyContent: "center",
								flexWrap: "wrap",
								marginLeft: 20,
								marginRight: 20,
								width: "100%",
								minHeight: 16,
							}}
						>
							{variablesKeys.map((barKey, itemIndex) => {
								return editable ? (
									<LegendEditElement
										allowColorChange
										onFinishEdit={(label) => {
											props.onFinishEdit(
												serieIndex,
												barKey,
												label
											);
										}}
										onFinishColor={(color) => {
											props.onFinishColor(
												serieIndex,
												barKey,
												color
											);
										}}
										key={itemIndex}
										color={
											colorMapping?.[serieIndex]?.[
												barKey
											] ??
											colorList[
												itemIndex % colorList.length
											]
										}
										text={
											nameMapping?.[serieIndex]?.[
												barKey
											] ?? barKey
										}
										textColor={legendsColor}
									/>
								) : (
									<LegendElement
										key={itemIndex}
										textColor={legendsColor}
										color={
											colorMapping?.[serieIndex]?.[
												barKey
											] ??
											colorList[
												itemIndex % colorList.length
											]
										}
										text={
											nameMapping?.[serieIndex]?.[
												barKey
											] ?? barKey
										}
									/>
								);
							})}
						</div>
						<DonutChart
							editable={editable}
							data={series.values}
							colorMapping={colorMapping?.[serieIndex]}
							nameMapping={nameMapping?.[serieIndex]}
							sumLabel={sumLabels?.[serieIndex]}
							sumColor={sumColors?.[serieIndex]}
							onSumLabelChange={(label) => {
								sumLabels = {
									...sumLabels,
									[serieIndex]: label,
								};
								props.onConfigChange("sumLabels", sumLabels);
							}}
							onSumColorChange={(color) => {
								sumColors = {
									...sumColors,
									[serieIndex]: color,
								};
								props.onConfigChange("sumColors", sumColors);
							}}
						/>
					</div>
				);
			})}
		</div>
	);
}