import React from "react";
import { useDrop } from "react-dnd";
import { Element } from "react-scroll";
import sections from "sections.json";
import CustomBarChart from "./CustomBarChart";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import { SentimentAnalysisFinding } from "common/Finding";
import {
    getDefaultColorsByTheme,
    getContainerStyleByTheme,
} from "../BarChartTheme";

interface Props {
    data: SentimentAnalysisFinding["content"]["data"];
    config: SentimentAnalysisFinding["config"];
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    dataSetMenuIsOpen?: boolean;
    onChangeData?: (data: any[], updateFinding?: boolean) => void;
    onChangeConfig?: (config: any, updateFinding?: boolean) => void;
}

const sentimentsMapping: { [key: string]: string } = {
    neu: "Neutral",
    neg: "Negative",
    pos: "Positive",
    compound: "Compound",
};
export default function SentimentAnalysisChart(props: Props) {
    let { data, config, editable, preview, onChangeData, onChangeConfig } =
        props;
    let {
        colorMapping,
        barSize,
        ticksSize,
        yAxisName,
        maxYRange,
        minYRange,
        linesCount,
        axesColor,
        chartTheme,
        showXAxisName,
        showYAxisName,
        axesLinesColor,
        ticksColor,
        axesNamesColor,
        tooltipColor,
        tooltipFontSize,
        barGap,
        barCategoryGap,
        statusExpressions,
        selectedTargetVariablesIndices,
        baseBackgroundColor,
    } = config;
    let defaultOptions = getDefaultColorsByTheme(chartTheme);
    let containerStyle = getContainerStyleByTheme(
        chartTheme,
        baseBackgroundColor ?? defaultOptions.baseBackgroundColor
    );

    const ref = React.useRef(null);
    const drop = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            if (props.onChangeConfig != null) {
                let config = {
                    ...props.config,
                    selectedVariableIndex: otherItem.content.variableIndex,
                };
                props.onChangeConfig(config, true);
            }
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    })[1];
    if (props.editable) {
        drop(ref);
    }

    return (
        <div
            className="flex-simple-column"
            style={{
                width: "100%",
                height: "100%",
                margin: "0 auto",
                position: "relative",
            }}
            ref={ref}
        >
            <div
                className="my-row"
                style={{
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                <Element
                    name="scrollable"
                    className="element"
                    style={{
                        height: "100%",
                        paddingTop: 10,
                        overflowX: mobileBreakpoint() ? "hidden" : "auto",
                        overflowY: "hidden",
                        backgroundColor: "transparent",
                        position: "relative",
                        ...containerStyle,
                    }}
                >
                    <div className="my-row" style={{ height: "100%" }}>
                        <div
                            className="flex-simple-column"
                            style={{ height: "100%" }}
                        >
                            <CustomBarChart
                                data={data}
                                ticksSize={ticksSize}
                                labelMapping={sentimentsMapping}
                                nameColorMapping={colorMapping}
                                axesColor={axesColor}
                                title=""
                                minYRange={minYRange}
                                maxYRange={maxYRange}
                                linesCount={linesCount}
                                barSize={barSize}
                                watermark={sections._Global?.watermark}
                                showXAxisName={showXAxisName ?? true}
                                showYAxisName={showYAxisName}
                                tooltipColor={tooltipColor}
                                tooltipFontSize={tooltipFontSize}
                                yAxisName={yAxisName}
                                editable={editable}
                                chartTheme={chartTheme}
                                baseBackgroundColor={
                                    baseBackgroundColor ??
                                    defaultOptions.baseBackgroundColor
                                }
                                statusExpressions={statusExpressions}
                                barIndices={selectedTargetVariablesIndices}
                                axesLinesColor={axesLinesColor}
                                axesNamesColor={axesNamesColor}
                                ticksColor={ticksColor}
                                barGap={barGap}
                                barCategoryGap={barCategoryGap}
                                barColors={
                                    preview
                                        ? ["#FFFFFF"]
                                        : defaultOptions.barColors
                                }
                                onLinkVariable={(
                                    index,
                                    variableName,
                                    variableIndex
                                ) => {
                                    let newData = Array.from(data);
                                    newData[index].name = variableName;
                                    newData[index].originalName = variableName;
                                    newData[index].variableIndex =
                                        variableIndex;
                                    onChangeData?.(newData, true);
                                }}
                                onRenameVariable={(index, name) => {
                                    let newData = Array.from(data);
                                    newData[index].name = name;
                                    onChangeData?.(newData);
                                }}
                                onConfigChange={(changes) => {
                                    let newConfig = { ...config, ...changes };
                                    onChangeConfig?.(newConfig);
                                }}
                            />
                        </div>
                    </div>
                </Element>
            </div>
            {props.columnDragActive && (
                <div
                    style={{
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        display:
                            props.editable &&
                            props.dataSetMenuIsOpen &&
                            !props.preview
                                ? "flex"
                                : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "dashed 3px #8DB8E3",
                        backgroundColor: "#EBF2F9EE",
                    }}
                >
                    <span
                        className="no-selection"
                        style={{
                            color: "#333",
                            fontSize: "14px",
                            fontWeight: 500,
                        }}
                    >
                        Drop variable here
                    </span>
                </div>
            )}
        </div>
    );
}
