import { observable, computed, makeObservable, action } from "mobx";
// import { Permission } from "./Permissions";
import { PreviewHeader } from "modules/data_hub_page/MergeSection";



export interface Preview {
    headers: PreviewHeader[] | null;
    body: string[][] | null;
}

class DragAndDropStates {
    showDrag: number | null = null;
    datasett: number | null = null;
    selected1: PreviewHeader[] = []
    selected2: PreviewHeader[] = []
    showEditVariablePopup: string | null = null

    constructor() {
        makeObservable(this, {
            showDrag: observable,
            datasett: observable,
            selected1: observable,
            selected2: observable,
            showEditVariablePopup: observable,
            getdataset: computed,
            getshowDrag: computed,
            getSelected1: computed,
            getSelected2: computed,
            getShowEditVariablePopup: computed,
            datasetUpdate: action,
            makedefault: action,
            setShowEditVariablePopup: action,
        });
    }
    makedefault() {
        this.datasett = null
        this.showDrag = null
        this.selected1 = []
        this.selected2 = []
    }


    get getdataset() {
        return this.datasett;
    }
    get getshowDrag() {
        return this.showDrag;
    }
    get getSelected1() {
        return this.selected1
    }

    get getSelected2() {
        return this.selected2
    }
    get getShowEditVariablePopup() {
        return this.showEditVariablePopup
    }

    datasetUpdate(id: number | null, datasetNumber: number | null) {
        this.datasett = datasetNumber
        this.showDrag = id
    }
    setShowEditVariablePopup(name: string | null) {
        this.showEditVariablePopup = name
    }

    updateSelected(item: any) {
        if (item.dataSetNumber === 1) {
            let matched = false;
            this.selected1.map((curr, i) => {
                if (curr.id === item.name.id) {
                    this.selected1 = [...this.selected1]
                    matched = true;
                }
                return null;
            })
            if (!matched) {
                this.selected1 = [...this.selected1, item.name]
            }

        };
        if (item.dataSetNumber === 2) {
            let matched = false;
            this.selected2.map((curr, i) => {
                if (curr.id === item.name.id) {
                    this.selected2 = [...this.selected2]
                    matched = true;
                }
                return null;
            })
            if (!matched) {
                this.selected2 = [...this.selected2, item.name]
            }

        };
    }

    // ReUpdateSelected(id: any, datasetNumber: number) {
    //     if (datasetNumber == 1) {
    //         this.selected1.splice(id, 1)
    //         this.selected1 = [...this.selected1]
    //     }
    //     if (datasetNumber == 2) {
    //         this.selected2.splice(id, 1)
    //         this.selected2 = [...this.selected2]
    //     }
    // }

    ReUpdateSelected(updatedData: any, datasetNumber: number) {
        if (datasetNumber === 1) {
            this.selected1 = [...updatedData]
        }
        if (datasetNumber === 2) {
            this.selected2 = [...updatedData]
        }
    }


}

export default new DragAndDropStates();