function formatValue(
    value: number | string | null,
    trunc: boolean = false,
    decimalPoints: number = 2
): [string, string] {
    if (typeof value === "number") {
        if (Math.abs(value) < 0.01) {
            decimalPoints = Math.max(1, Math.min(decimalPoints, 100));
            return [
                trunc
                    ? Math.trunc(value).toString()
                    : value.toPrecision(decimalPoints),
                "",
            ];
        }
        if (Math.abs(value) >= 1000000000000) {
            let formatValue: number = value / 1000000000;
            decimalPoints = Math.max(0, Math.min(decimalPoints, 20));
            return [
                trunc
                    ? Math.trunc(formatValue).toString()
                    : formatValue.toExponential(decimalPoints),
                "B",
            ];
        }
        if (Math.abs(value) >= 1000000000) {
            decimalPoints = Math.max(0, Math.min(decimalPoints, 100));
            let formatValue: number = value / 1000000000;
            return [
                trunc
                    ? Math.trunc(formatValue).toString()
                    : formatValue.toFixed(decimalPoints),
                "B",
            ];
        }
        if (Math.abs(value) >= 1000000) {
            decimalPoints = Math.max(0, Math.min(decimalPoints, 100));

            let formatValue: number = value / 1000000;
            return [
                trunc
                    ? Math.trunc(formatValue).toString()
                    : formatValue.toFixed(decimalPoints),
                "M",
            ];
        }
        if (Math.abs(value) >= 1000) {
            decimalPoints = Math.max(0, Math.min(decimalPoints, 100));

            let formatValue: number = value / 1000;
            return [
                trunc
                    ? Math.trunc(formatValue).toString()
                    : formatValue.toFixed(decimalPoints),
                "K",
            ];
        }
        decimalPoints = Math.max(0, Math.min(decimalPoints, 100));

        return [
            trunc ? Math.trunc(value).toString() : value.toFixed(decimalPoints),
            "",
        ];
    } else return [value ?? "", ""];
}
export { formatValue };
