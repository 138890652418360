import React, { CSSProperties } from "react";
import { mainStyle } from "common/MainStyle";
import Draggable from "react-draggable";
import { LeadersLaggersLegendPosition } from "common/Finding";

interface Props {
    color: string;
    text: string;
    textSize?: number;
    containerStyle?: CSSProperties;
    textStyle?: CSSProperties;
    textColor?: string;
    showLegend?: boolean;
    legendPosition?: LeadersLaggersLegendPosition
    scale?: number;
    onChangeLegendPosition?: (position: LeadersLaggersLegendPosition) => void
}

export function LegendElement(props: Props) {
    let {
        color,
        text,
        textSize,
        containerStyle,
        textStyle,
        showLegend = true,
        legendPosition = {
            x: 0,
            y: 0,
        },
        onChangeLegendPosition,
    } = props;
    
    return (
        <div
            key={text}
            className="my-row"
            style={{
                alignItems: "center",
                marginLeft: 10,
                ...containerStyle,
                zIndex: 99999,
                position: "relative"
            }}
        >
            {showLegend && <div
                style={{
                    width: 32,
                    height: 8,
                    backgroundColor: color,
                }}
            />}
            <Draggable
                disabled={showLegend}
                axis="x"
                scale={props?.scale ?? 1}
                position={showLegend ? { x: 0, y: 0 } : legendPosition}
                onStart={(evt) => {
                    evt.stopPropagation();
                }}
                onStop={(evt, data) => {
                    if (!onChangeLegendPosition) return;
                    
                    onChangeLegendPosition({
                        x: data.x,
                        y: 0,
                    });
                }}
            >
                <span
                    style={{
                        marginLeft: 10,
                        color:
                            props.textColor ??
                            mainStyle.getPropertyValue(
                                "--graphs-legends-text-color"
                            ),
                        fontFamily: "Arial",
                        fontSize: textSize ?? mainStyle.getPropertyValue(
                            "--graphs-legends-size"
                        ),
                        ...textStyle,
                    }}
                >
                    {text}
                </span>
            </Draggable>
        </div>
    );
}
