import { lightThemeStyle } from "common/LightThemeStyle";
import { getCustomSelectStyleLight } from "common/SelectStyles";
import { CSSProperties } from "react";
import { CSSObjectWithLabel } from "react-select";
import conditionStyles from "./ConditionSelectorStyles.module.css";

const styles = {
    rowStyle: () => ({
        backgroundColor: "transparent",
        marginTop: "0px",
        alignItems: "center",
    }),
    rowStyleWithoutPlusMinus: () => {
        return {
            padding: "2px",
            backgroundColor: lightThemeStyle.backgroundColor,
        };
    },
    linkSwitchStyle: {
        offColor: lightThemeStyle.switchOffColor,
        onColor: lightThemeStyle.switchOnColor,
        offHandleColor: lightThemeStyle.switchOffHandleColor,
        onHandleColor: lightThemeStyle.switchOnHandleColor,
    },
    linkElementStyle: {
        color: lightThemeStyle.primaryTextColor,
    },
    inputStyle: {
        ...(getCustomSelectStyleLight(10, true).input!({}) as CSSProperties),
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "0px",
        marginRight: "0px",
        minHeight: "27px",
        width: "100px",
        backgroundColor: lightThemeStyle.conditionInputsBackgroundColor,
        border: "none",
    },
    selectStyle: () => {
        let baseStyle = getCustomSelectStyleLight(
            10,
            true,
            lightThemeStyle.conditionInputsBackgroundColor
        );
        let style = { ...baseStyle };
        let control = (base: CSSObjectWithLabel, props: any) => {
            let backgroundOptions = props?.hasValue
                ? undefined
                : {
                      background: "#FFFFFF",
                  };
            return {
                ...baseStyle.control!(base, props),
                borderRadius: 0,
                border: "none !important",
                boxShadow: "none",
                height: "100%",
                ...backgroundOptions,
            };
        };
        let container = (base: CSSObjectWithLabel, props: any) => {
            return {
                ...base,
                height: "100%",
            };
        };
        style.container = container;
        style.control = control;
        let dropdownIndicator = (base: CSSObjectWithLabel, props: any) => {
            return {
                ...baseStyle.dropdownIndicator!(base, props),
                display: "none",
            };
        };
        style.dropdownIndicator = dropdownIndicator;
        return style;
    },
    buttonsStyle: {
        backgroundColor: "transparent",
        color: lightThemeStyle.secondaryTextColor,
        fontFamily: "Roboto",
        fontSize: "12px",
    },
    auxiliarySelectorsStyle: () => {
        let baseStyle = getCustomSelectStyleLight(10, true, "#A3B1C033");
        let style = { ...baseStyle };
        style.dropdownIndicator = () => {
            return {
                display: "none",
            };
        };
        let control = (base: CSSObjectWithLabel, props: any) => {
            let backgroundOptions = props?.hasValue
                ? undefined
                : {
                      background: "#FFFFFF",
                  };
            return {
                ...baseStyle.control!(base, props),
                borderRadius: 0,
                border: "none !important",
                boxShadow: "none",
                height: "100%",
                ...backgroundOptions,
            };
        };
        style.control = control;
        return style;
    },
    titleStyle: {
        background: "#A3B1C033",
    },
    rowWithoutPlusMinusClassName: conditionStyles.rowWithoutPlusMinus,
    asWizard: true,
};

export default styles;
