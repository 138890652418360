import "common/styles/App.css";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reaction } from "mobx";

import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";

import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import BaseJourney from "../../common/BaseJourney";
import { Api } from "./Api";
import FinalStep1A from "common/graphics/FinalStep1A";
import FinalStep1Ai from "common/graphics/FinalStep1Ai";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import ViewOption from "common/graphics/ViewOption";

const viewOptions = [
    { label: "Rank chart", value: ViewOption.Both },
    { label: "Leaders only", value: ViewOption.Leaders },
    { label: "Laggards only", value: ViewOption.Laggards },
];

const Step = Object.freeze({
    initial: 0,
    selectDataScope: 1,
    selectTable: 2,
    selectConditions: 3,
    selectTarget: 4,
    selectAggregate: 5,
    selectShowed: 6,
    selectViewOptions: 7,
    selectAdditionalParameter: 8,
    selectAdditionalValue: 9,
    final: 10,
});

function StepFinal(props) {
    const {
        finding,
        onBack,
        onSaveFinding,
        onShowRecipesPopup,
        loading,
        onNewFinding,
    } = props;
    return (
        <>
            <div
                className="my-row"
                style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                <div
                    onClick={() => {
                        onBack();
                    }}
                    style={{
                        minWidth: 100,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/data-exploration/chevron_back.png"
                    />
                </div>
                {loading ? (
                    <span className="exploration-big-title-span">
                        {"LOADING"}
                    </span>
                ) : (
                    finding && (
                        <div
                            className="flex-simple-column"
                            style={{
                                height: "490px",
                                width: "100%",
                            }}
                        >
                            {finding.type === "1ai" ? (
                                <ErrorBoundary>
                                    <FinalStep1Ai
                                        editable
                                        onConfigChange={(config) => {
                                            let newFinding = {
                                                ...finding,
                                                config: config,
                                            };
                                            onNewFinding(newFinding);
                                        }}
                                        config={finding.config}
                                        compareInfo={
                                            finding.content.compareInfo
                                        }
                                        targetVariable={
                                            finding.config.targetVariable
                                        }
                                        showedVariables={
                                            finding.content.showedVariables
                                        }
                                        geographyLevels={
                                            finding.content.geographyLevels
                                        }
                                        units={finding.content.units}
                                        showMap={finding.content.showMap}
                                    />
                                </ErrorBoundary>
                            ) : (
                                <ErrorBoundary>
                                    <FinalStep1A
                                        editable
                                        onConfigChange={(config) => {
                                            let newFinding = {
                                                ...finding,
                                                config: config,
                                            };
                                            onNewFinding(newFinding);
                                        }}
                                        config={finding.config}
                                        showMap={finding.content.showMap}
                                        leaders={finding.content.leaders}
                                        geographyLevels={
                                            finding.content.geographyLevels
                                        }
                                        laggers={finding.content.laggers}
                                        targetVariable={
                                            finding.config.targetVariable
                                        }
                                        showedVariables={
                                            finding.content.showedVariables
                                        }
                                        units={finding.content.units}
                                    />
                                </ErrorBoundary>
                            )}
                            <FinalStepBottomBar
                                onSaveFinding={onSaveFinding}
                                onShowRecipesPopup={onShowRecipesPopup}
                                topMargin={10}
                            />
                        </div>
                    )
                )}
                <div
                    style={{
                        minWidth: 100,
                        height: "100%",
                    }}
                />
            </div>
        </>
    );
}

class MainComponent extends BaseJourney {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            step: 0,
            count: 5,
            viewOption: ViewOption.Both,
            loading: false,
            showTooltip: false,
            showMap: true,
            aggregateVariable: undefined,
            previousStep: 0,
            animationDirection: "top",
            aggregateVariables: [],
            defaultAggregateVariables: [],
            targetVariable: undefined,
            targetVariables: [],
            additionalParameters: [],
            additionalParameter: undefined,
            showedVariables: [],
            finding: undefined,
            variablesForShowedSelection: [],
            selectedTable: undefined,
            leftAdditionalValue: undefined,
            rightAdditionalValue: undefined,
            showRecipesPopup: false,
            step2VariablesMap: {
                [Step.selectAggregate]: [
                    "aggregateVariable",
                    "aggregateVariables",
                ],
                [Step.selectShowed]: [
                    "showedVariables",
                    "variablesForShowedSelection",
                ],
                [Step.selectTarget]: ["targetVariable", "targetVariables"],
                [Step.selectAdditionalParameter]: [
                    "additionalParameter",
                    "additionalParameters",
                ],
            },
            conditions: [
                {
                    leftBracket: null,
                    rightBracket: null,
                    variable: null,
                    operation: null,
                    value: null,
                    logical: null,
                },
            ],
        };
        this.initializeVariables = this.initializeVariables.bind(this);
        this.switchVariables = this.switchVariables.bind(this);
    }
    clearOptionalArgs() {
        this.setState({
            count: 5,
            viewOption: ViewOption.Both,
            aggregateVariable: null,
            targetVariable: null,
            targetVariableIndex: null,
            aggregateVariableIndex: null,
            showedVariablesIndices: [],
            showedVariables: [],
            additionalParameter: null,
            additionalParameterIndex: null,
            leftAdditionalOperator: null,
            leftAdditionalValue: null,
            rightAdditionalOperator: null,
            rightAdditionalValue: null,
            selectedTable: this.defaultTable(),
            conditions: ConditionsSelector.defaultValue,
            dynamicOptions: {},
            labelMapping: null,
            colorMapping: null,
        });
    }
    getConfig() {
        let geographyLevels = {
            latitude: "%latitude",
            longitude: "%longitude",
        };
        if (this.state.aggregateVariable != null) {
            this.getVariables()
                .geographyGroup(this.state.aggregateVariable)
                .forEach((item) => {
                    geographyLevels[item.level] = item.name;
                });
        }
        let targetVariableIndex = this.getVariables().variableToIndex(
            this.state.targetVariable
        );
        let aggregateVariableIndex = this.getVariables().variableToIndex(
            this.state.aggregateVariable
        );
        let additionalParameterIndex = this.getVariables().variableToIndex(
            this.state.additionalParameter
        );
        let showedVariablesIndices = this.state.showedVariables.map(
            (variable) => this.getVariables().variableToIndex(variable)
        );
        let config = {
            version: configVersion,
            geographyLevels: geographyLevels ?? null,
            dataScope: this.state.dataScope ?? null,
            aggregateVariable: this.state.aggregateVariable ?? null,
            targetVariable: this.state.targetVariable ?? null,
            targetVariableIndex: targetVariableIndex ?? null,
            aggregateVariableIndex: aggregateVariableIndex ?? null,
            additionalParameterIndex: additionalParameterIndex ?? null,
            showedVariablesIndices: showedVariablesIndices ?? null,
            additionalParameter: this.state.additionalParameter ?? null,
            showedVariables: this.state.showedVariables ?? null,
            selectedTable: this.state.selectedTable ?? null,
            leftAdditionalOperator: this.state.leftAdditionalOperator ?? null,
            rightAdditionalOperator: this.state.rightAdditionalOperator ?? null,
            leftAdditionalValue: this.state.leftAdditionalValue ?? null,
            rightAdditionalValue: this.state.rightAdditionalValue ?? null,
            conditions: this.state.conditions ?? null,
            additionalParameters: this.state.additionalParameters ?? null,
            count: this.state.count ?? null,
            viewOption: this.state.viewOption ?? ViewOption.Both,
            labelMapping: this.state.labelMapping ?? null,
            colorMapping: this.state.colorMapping ?? null,
            journeyName: this.props.journeyName,
        };
        if (this.props.addToCanvas) {
            config.dynamicOptions = this.prepareDynamicOptions(
                this.state.dynamicOptions,
                config
            );
        }
        return config;
    }
    buildContent() {
        switch (this.state.step) {
            case Step.initial:
                return this.buildInitial(
                    "How do you want to examine your leaders and laggards data?"
                );
            case Step.selectDataScope:
                return this.buildDataScopesSelector();
            case Step.selectTable:
                return this.buildTableSelector();
            case Step.selectConditions:
                return this.buildConditionsSelector();
            case Step.selectTarget:
                return this.buildVariablesSelector(
                    "I want to find our best and worst as measured by outcome",
                    "targetVariable",
                    "targetVariables",
                    false,
                    DynamicOptionType.variable,
                    "targetVariableIndex"
                );
            case Step.selectAggregate:
                return this.buildVariablesSelector(
                    "with geography",
                    "aggregateVariable",
                    "aggregateVariables",
                    false,
                    DynamicOptionType.variable,
                    "aggregateVariableIndex"
                );
            case Step.selectShowed:
                return this.buildVariablesSelector(
                    "and also report",
                    "showedVariables",
                    "variablesForShowedSelection",
                    true,
                    DynamicOptionType.variable,
                    "showedVariablesIndices"
                );
            case Step.selectViewOptions:
                let viewOptionsComponent = (
                    <div>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    marginTop: "10px",
                                    width: "200px",
                                    marginLeft: "20px",
                                    height: "38px",
                                }),
                            }}
                            options={viewOptions}
                            value={viewOptions.find(
                                (option) =>
                                    option.value === this.state.viewOption
                            )}
                            onChange={(newValue) => {
                                this.setState({ viewOption: newValue.value });
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                );
                return this.buildInput("Show", "count", "count", true, [
                    viewOptionsComponent,
                ]);
            case Step.selectAdditionalParameter:
                return this.buildVariablesSelector(
                    "[+] and how they vary by",
                    "additionalParameter",
                    "additionalParameters",
                    false,
                    DynamicOptionType.additionalParameter,
                    "additionalParameterIndex"
                );
            case Step.selectAdditionalValue:
                return this.buildAdditionalValueSelector("Comparing", true);
            case Step.final:
                return (
                    <StepFinal
                        onShowRecipesPopup={this.showRecipesPopup}
                        onSaveFinding={this.saveFinding}
                        onNewFinding={this.onNewFinding}
                        onBack={this.back}
                        finding={this.state.finding}
                        loading={this.state.loading}
                    />
                );

            default:
                return <div />;
        }
    }
    buildLeftBar() {
        return super.buildLeftBar(this.state.step < Step.final - 1);
    }
    getData() {
        let config = this.getConfig();
        this.setState({ loading: true });
        Api.getData(config, undefined, this.props.currentModuleId)
            .then((item) => {
                this.setState({
                    finding: item,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }
    showFinalStep() {
        if (
            this.state.targetVariable &&
            this.state.step > Step.initial &&
            this.state.step < Step.final
        )
            this.setState({ animationDirection: "top" }, () => {
                this.setState(
                    (state) => ({
                        previousStep: state.step,
                        step: Step.final,
                    }),
                    () => {
                        this.getData();
                        this.saveNewJourneyConfig();
                    }
                );
            });
    }
    initializeVariables() {
        let scopeVariables = this.getVariables();
        let targetVariables = scopeVariables.numericVariables;
        this.setState({
            additionalParameters: [null].concat(
                scopeVariables.dataVariables.map((item) => item.name)
            ),
            targetVariables: targetVariables.map((item) => item.name),
            aggregateVariables: [null].concat(
                scopeVariables.geographyVariables.map((item) => item.name)
            ),
            variablesForShowedSelection: scopeVariables.dataVariables.map(
                (item) => item.name
            ),
        });
    }
    componentDidMount() {
        this.getLastJourneyConfig();
    }
    switchVariables() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
        this.initializeVariables();
        this.initializeVariablesReaction = reaction(
            () => this.getVariables().dataVariables,
            () => {
                this.initializeVariables();
            }
        );
    }

    componentWillUnmount() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
    }

    selectVariable(variableName, variablesName, index) {
        if (index >= this.state[variablesName].length) return;
        let stateDiff = {};
        if (this.state.step === Step.selectShowed) {
            let variable = this.state[variablesName][index];
            let selectedVariables = Array.from(this.state[variableName]);
            if (selectedVariables.includes(variable))
                selectedVariables = selectedVariables.filter(
                    (item) => item !== variable
                );
            else selectedVariables = selectedVariables.concat(variable);
            stateDiff = {
                [variableName]: selectedVariables,
            };
        } else {
            stateDiff = {
                [variableName]: this.state[variablesName][index],
            };
        }
        if (this.state.step === Step.selectAggregate) {
            stateDiff.showedVariables = [];
        }
        if (this.state.step === Step.selectAdditionalParameter) {
            stateDiff.leftAdditionalValue = undefined;
            stateDiff.rightAdditionalValue = undefined;
        }
        this.setState(stateDiff, () => {
            if (this.state.step !== Step.selectShowed)
                setTimeout(() => {
                    this.stepDown();
                }, 100);
        });
    }

    getSubtitle() {
        let conditionsString = conditionsToString(
            Array.from(this.state.conditions)
        );
        if (
            this.state.step === Step.selectTable ||
            this.state.step === Step.selectDataScope
        )
            return "How do you want to examine your leaders and laggards data?";
        if (
            this.state.step > Step.selectTable &&
            this.state.step < Step.final
        ) {
            let subtitle = `For the data in ${
                this.state.dataScope ? this.state.dataScope.label : ""
            } ${this.state.selectedTable.label}`;
            if (this.state.step > Step.selectConditions) {
                if (conditionsString.length > 0)
                    subtitle += ` and under the conditions ${conditionsString}`;
            }
            if (this.state.step > Step.selectTarget)
                subtitle += ` I want to find our best and worst as measured by outcome ${
                    this.state.targetVariable || ""
                }`;
            if (this.state.step > Step.selectAggregate)
                subtitle += ` with geography ${
                    this.state.aggregateVariable || ""
                }`;

            if (this.state.step > Step.selectShowed)
                subtitle +=
                    this.state.showedVariables.length === 0
                        ? ""
                        : ` and also report ${this.state.showedVariables.join(
                              ", "
                          )}`;
            if (this.state.step > Step.selectAdditionalParameter)
                subtitle += ` and how they vary by ${
                    this.state.additionalParameter || ""
                }`;
            return subtitle;
        }

        if (this.state.step === Step.final)
            return (
                <>
                    <span>For the data </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.dataScope
                            ? this.state.dataScope.label.concat(" ")
                            : ""}
                    </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedTable.label}
                    </span>
                    {conditionsString.length > 0 && (
                        <>
                            <span> and under the conditions </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {conditionsString}
                            </span>
                        </>
                    )}
                    <span>
                        I want to find our best and worst as measured by the
                        outcome
                    </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {" ".concat(this.state.targetVariable)}
                    </span>
                    <span> with geography </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {" ".concat(this.state.aggregateVariable || "")}
                    </span>

                    {this.state.showedVariables.length > 0 ? (
                        <>
                            <span> and also report </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {this.state.showedVariables.join(", ")}
                            </span>
                        </>
                    ) : null}
                    {this.state.additionalParameter ? (
                        <>
                            <span> and how they vary by</span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {" ".concat(this.state.additionalParameter)}
                            </span>
                        </>
                    ) : null}
                    {this.state.additionalParameter &&
                    this.state.leftAdditionalValue &&
                    this.state.rightAdditionalValue ? (
                        <>
                            <span> comparing </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {` ${this.state.additionalParameter}${this.state.leftAdditionalOperator.label}${this.state.leftAdditionalValue.label}`}
                            </span>
                            <span> to</span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {` ${this.state.additionalParameter}${this.state.rightAdditionalOperator.label}${this.state.rightAdditionalValue.label}`}
                            </span>
                        </>
                    ) : null}
                    <span>.</span>
                </>
            );

        return "";
    }
    getFooterTitle() {
        switch (this.state.step) {
            case Step.initial:
                return "... For the data in...";
            case Step.selectTable:
                return "... and under the conditions...";
            case Step.selectConditions:
                return "... I want to find our best and worst as measured by outcome...";
            case Step.selectAggregate:
                return "... with geography...";
            case Step.selectTarget:
                return "[optional] and also report ...";
            case Step.selectShowed:
                return "[optional] and show ...";
            case Step.selectViewOptions:
                return "[optional] and they vary by ...";
            default:
                return "";
        }
    }
    stepDown() {
        let delta = 1;
        if (this.state.step === Step.selectTable && !this.state.selectedTable) {
            return;
        }
        if (this.state.step === Step.selectAggregate) {
            this.setState((state) => ({
                variablesForShowedSelection: this.getVariables()
                    .dataVariables.filter(
                        (item) => item.name !== state.aggregateVariable
                    )
                    .map((item) => item.name),
            }));
        }
        if (
            this.state.step === Step.selectAdditionalParameter &&
            !this.state.additionalParameter
        )
            delta = 2;
        this.setState({ animationDirection: "top" }, () => {
            if (this.state.step + delta < Step.final) {
                this.setState((state) => ({
                    previousStep: state.step,
                    step: state.step + delta,
                }));
            } else {
                this.showFinalStep();
            }
        });
    }

    stepUp() {
        let step = this.state.step;
        if (step > 0)
            this.setState({ animationDirection: "bottom" }, () => {
                this.setState({
                    step: step - 1,
                });
            });
        if (step === Step.selectAdditionalValue) {
            this.setState({
                leftAdditionalValueSelected: false,
                rightAdditionalValueSelected: false,
            });
        }
    }

    render() {
        return (
            <div
                className="dashboard-rect-journey-focus"
                tabIndex="0"
                style={{
                    height: 620,
                    overflow: "hidden",
                    display: !this.props.collapsed ? "block" : "none",
                }}
                onClick={() => {
                    if (this.state.step === Step.initial) this.stepDown();
                }}
                onKeyDown={(evt) => {
                    if (evt.key === "Escape") {
                        this.props.onClose();
                    }

                    if (evt.key === "ArrowDown") {
                        if (
                            this.state.step > Step.initial &&
                            this.state.step < Step.final
                        ) {
                            this.stepDown();
                        }
                        evt.preventDefault();
                    }
                    if (evt.key === "ArrowRight") {
                        this.showFinalStep();
                    }
                    if (evt.key === "ArrowLeft") {
                        if (this.state.step === Step.final) {
                            this.back();
                        }
                    }

                    if (evt.key === "ArrowUp") {
                        if (this.state.step < Step.final) {
                            this.stepUp();
                        }
                        evt.preventDefault();
                    }
                    if (evt.key === "p") {
                        if (this.state.step === Step.final) {
                            this.saveFinding();
                        }
                    }
                    if (evt.key === "d") {
                        if (this.state.step === Step.final) {
                            evt.preventDefault();
                            this.showRecipesPopup();
                        }
                    }
                    let variableInfo = this.state.step2VariablesMap[
                        this.state.step
                    ];
                    if (variableInfo) {
                        let variablesLength = this.state[variableInfo[1]]
                            .length;
                        let variableIndex = evt.keyCode - 65;
                        if (
                            variableIndex >= 0 &&
                            variableIndex < variablesLength
                        ) {
                            this.selectVariable(
                                variableInfo[0],
                                variableInfo[1],
                                variableIndex
                            );
                        }
                    }
                    if (evt.key === "Enter") {
                        if (
                            this.state.step === Step.selectAdditionalValue &&
                            this.getVariables().isMonthVariable(
                                this.state.additionalParameter
                            ) &&
                            this.state.leftAdditionalValue &&
                            !this.state.leftAdditionalValueSelected
                        ) {
                            this.setState({
                                leftAdditionalValueSelected: true,
                            });
                            return;
                        }
                        if (
                            this.state.step === Step.selectAdditionalValue &&
                            this.getVariables().isMonthVariable(
                                this.state.additionalParameter
                            ) &&
                            this.state.leftAdditionalValueSelected &&
                            this.state.rightAdditionalValue
                        ) {
                            this.setState(
                                {
                                    rightAdditionalValueSelected: true,
                                },
                                () => {
                                    this.showFinalStep();
                                }
                            );
                            return;
                        }
                    }

                    if (
                        this.state.step === Step.selectAdditionalValue &&
                        this.getVariables().isMonthVariable(
                            this.state.additionalParameter
                        )
                    ) {
                        this.selectMonth(evt.key, true);
                    }
                }}
            >
                <div
                    className="my-row"
                    style={{ justifyContent: "space-between", height: "100%" }}
                >
                    <div
                        className="flex-column"
                        style={{
                            height: "100%",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {this.buildHeader()}
                        <TransitionGroup
                            style={{
                                minHeight: "inherit",
                            }}
                        >
                            <CSSTransition
                                style={{
                                    height: "100%",
                                }}
                                key={this.state.step}
                                timeout={500}
                                classNames={"journeywizard-".concat(
                                    this.state.animationDirection || ""
                                )}
                            >
                                <div
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    {this.buildContent()}
                                </div>
                            </CSSTransition>
                        </TransitionGroup>

                        <Footer
                            showArrow={
                                this.state.step > Step.initial &&
                                this.state.step < Step.final
                            }
                            title={this.getFooterTitle()}
                        />
                    </div>
                </div>
                {this.buildAddToRecipesPopup()}
            </div>
        );
    }
}

export { MainComponent };