import React, { useState } from "react";
import styles from "./LeftSideSection.module.css";
import { ReactComponent as ManageIcon } from "icons/data_hub/manage.svg";
import { ReactComponent as MergeIcon } from "icons/data_hub/merge.svg";
import { ReactComponent as AggregateIcon } from "icons/data_hub/aggregate.svg";
import { ReactComponent as ImportIcon } from "icons/data_hub/import.svg";
import { ReactComponent as TrashIcon } from "icons/data_hub/trash.svg";
import { ReactComponent as ExportIcon } from "icons/data_hub/export.svg";
import { ReactComponent as CleanIcon } from "icons/data_hub/clean.svg";
import { ReactComponent as AnalyzeIcon } from "icons/data_hub/analyze.svg";
import { ReactComponent as CareateVaraibelIcon } from "icons/data_hub/createVariable.svg";
import { ReactComponent as ReshapeIcon } from "icons/data_hub/reshape.svg";
import cx from "classnames";
import SelectedOption from "../SelectedOption";
import DataScopes from "common/DataScopes";

export enum Option {
    Manage = 1,
    Merge = 2,
    Aggregate = 3,
    Import = 4,
    Export = 5,
    Create_Variable = 6,
    Clean = 8,
    Analyze = 11,
    Reshape = 12,
    Trash = 13,
    Visualize = 14,
}

export const Options: ReadonlyArray<{
    label: string;
    value: Option;
    icon: JSX.Element;
}> = [
        {
            label: "Import",
            value: Option.Import,
            icon: <ImportIcon />,
        },
        {
            label: "Manage",
            value: Option.Manage,
            icon: <ManageIcon />,
        },
        {
            label: "Merge",
            value: Option.Merge,
            icon: <MergeIcon />,
        },
        {
            label: "Aggregate",
            value: Option.Aggregate,
            icon: <AggregateIcon />,
        },
        {
            label: "Export",
            value: Option.Export,
            icon: <ExportIcon />
        },
        {
            label: "Create Variable",
            value: Option.Create_Variable,
            icon: <CareateVaraibelIcon />,
        },
        {
            label: "Clean",
            value: Option.Clean,
            icon: <CleanIcon />,
        },
        {
            label: "Analyze",
            value: Option.Analyze,
            icon: <AnalyzeIcon />,
        },
        {
            label: "Reshape",
            value: Option.Reshape,
            icon: <ReshapeIcon />,
        },

        {
            label: "Trash",
            value: Option.Trash,
            icon: <TrashIcon />,
        },
       
    ];

interface Props {
    selectedOption: Option;
    onSelectOption: (option: Option) => void;
}



export default function LeftSideSection(props: Props) {
    const [isExpanded, setisExpanded] = useState<boolean>(false)
    return (
        <div className={!isExpanded ? `${styles.container} hide-scroll` : `${styles.container} ${styles.expanded} hide-scroll`}
            onMouseOver={() => setisExpanded(true)} onMouseLeave={() => setisExpanded(false)} onMouseEnter={() => setisExpanded(true)}
        >
            {Options.map((option, index) => {
                let currentOption = option.value === props.selectedOption;
                let icon = option.icon;
                icon = React.cloneElement(icon, {
                    stroke: currentOption ? "#326EAA" : "#555",
                });

                let dropdown = option.value === Option.Merge ||
                    option.value === Option.Aggregate ||
                    option.value === Option.Clean ||
                    option.value === Option.Create_Variable ||
                    option.value === Option.Reshape;

                return (
                    <div
                        key={index}
                        className={cx(
                            "unselectable",
                            styles.option,
                            currentOption && styles.optionSelected
                        )}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            props.onSelectOption(option.value);
                            SelectedOption.seletedOptionValue !== option.value && SelectedOption.UpdateaskSwitchTab(true);
                            if (SelectedOption.getSwitchTab) {
                                DataScopes.previewState = { headers: null, body: null }
                            }


                            (dropdown && !SelectedOption.getAskSwitchTab) && SelectedOption.changeshowOptions(true);
                            (!dropdown && !SelectedOption.getAskSwitchTab) && SelectedOption.changeshowOptions(false);
                        }}
                    >
                        <span className={styles.icon}>{icon}</span>
                        {
                            <span
                                className={cx(
                                    styles.label,
                                    currentOption && styles.labelSelected
                                )}
                            >
                                {option.label}
                            </span>
                        }
                    </div>
                );
            })}
        </div >
    );
}