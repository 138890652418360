import axios from "axios";
import io, * as SocketIOClient from "socket.io-client";

const debug = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export interface SocketIoError {
    type: string;
    description: Event;
}
let SocketIOInstance: SocketIOClient.Socket | undefined = undefined;

//axios.defaults.headers.common["x-remote-user"] = window.localStorage['user']; // temporary workaround. it doesn't work well
//axios.defaults.headers.common["Authorization"] = 'Bearer ' + window.localStorage['auth0_access_token'];

if (debug) {
    axios.defaults.baseURL = "http://127.0.0.1:8000";
    //axios.defaults.headers.common["x-remote-user"] = "admin";
    axios.defaults.headers.common["domainname"] = "example.com";

    // SocketIOInstance = io("ws://127.0.0.1:8002", {
    //     transports: ["websocket"],
    // });
} else {
    // SocketIOInstance = io({
    //     transports: ["websocket"],
    // });
}

export function imageUrlPrefix() {
    if (debug) return axios.defaults.baseURL;
    return "";
}

export function initSocketIO(accessToken: string) {
    if (debug) {
        SocketIOInstance = io("ws://localhost:8002", {
            transports: ["websocket"],
            query: {
                access_token: accessToken,
            },
        });
    } else
        SocketIOInstance = io({
            transports: ["websocket"],
            query: {
                access_token: accessToken,
            },
        });
}

export default axios;
export { SocketIOInstance };
