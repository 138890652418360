const youtube: Set<string> = new Set([
    "www.youtube.com",
    "youtube.com",
    "youtu.be",
]);

function processUrl(urlString: string): string | null {
    let url: URL;
    try {
        url = new URL(urlString);
    } catch (e) {
        return null;
    }
    if (youtube.has(url.host)) {
        if (url.host === "youtu.be") {
            return `https://www.youtube.com/embed/${url.pathname.slice(
                1
            )}?enablejsapi=1`;
        } else if (
            url.pathname.startsWith("/watch") &&
            url.searchParams.has("v")
        ) {
            return `https://www.youtube.com/embed/${url.searchParams.get(
                "v"
            )}?enablejsapi=1`;
        } else if (url.pathname.startsWith("/embed")) {
            url.searchParams.set("enablejsapi", "1");
            return url.toString();
        } else {
            return null;
        }
    } else {
        return null;
    }
}

function processUrlId(urlString: string): string | null {
    let url: URL;
    try {
        url = new URL(urlString);
    } catch (e) {
        return null;
    }
    if (youtube.has(url.host)) {
        if (url.host === "youtu.be") {
            return url.pathname.slice(1);
        } else if (
            url.pathname.startsWith("/watch") &&
            url.searchParams.has("v")
        ) {
            return url.searchParams.get("v");
        } else if (url.pathname.startsWith("/embed/")) {
            return url.pathname.slice("/embed/".length);
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export default processUrl;
export { processUrlId };
