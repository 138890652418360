import React from "react";
import RegressionResultsComponent from "common/graphics/RegressionResultsComponent";

function MainComponent(props) {
	let finding = props.finding;
	return (
		<div style={{ width: "100%", height: "100%" }}>
			<RegressionResultsComponent
				showLegend={finding.content.regressionResult.showLegend}
				lastCustomRegressionResults={finding.content.regressionResult}
			/>
		</div>
	);
}

export { MainComponent };