import React from "react";

import BaseSubmodule from "../../common/BaseSubmodule";
import CodeNotebookComponent from "common/CodeNotebookComponent";

class MainComponent extends BaseSubmodule {
    static getNumberOfSteps() {
        return 0;
    }

    static getTitle() {
        return "Notebook";
    }

    render() {
        return (
            <>
                <hr
                    style={{
                        color: "#969aa3",
                        backgroundColor: "#969aa3",
                        borderColor: "#969aa3",
                        height: 0.05
                    }}
                />
                <CodeNotebookComponent
                    onNewFinding={undefined}
                    tag="processingPage"
                />
            </>
        );
    }
}

export { MainComponent };
export let requirePermission = "Notebook";
