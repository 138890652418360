import React, { Component, ComponentClass } from "react";
import { observer } from "mobx-react";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import "common/styles/input.css";
import Instrumentation from "common/Instrumentation";
import ScaledPage from "common/ScaledPage";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import { AppModuleProps } from "AppModule";

import { ReactComponent as SettingsIcon } from "icons/left_nav_menu/settings.svg";

import styles from "./main.module.css";
import modules from "./modules/module_list.json";
import config from "sections.json";

interface State {
    submodules: {
        MainComponent: ComponentClass<{}, any>;
        requirePermission?: string;
    }[];
}

const MainComponent = observer(
    class MainComponent extends Component<AppModuleProps, State> {
        private performance: Date | null;
        private mounted: boolean;

        constructor(props: AppModuleProps) {
            super(props);
            this.performance = null;
            this.mounted = false;
            this.state = {
                submodules: [],
            };

            this.renderContents = this.renderContents.bind(this);
        }

        componentDidMount() {
            if (this.performance != null) {
                let timeMs = new Date().getTime() - this.performance.getTime();
                this.performance = null;
                Instrumentation.addInteraction("Settings", timeMs);
            }
            let submodules: {
                MainComponent: ComponentClass<{}, any>;
                requirePermission?: string;
            }[] = [];
            modules.forEach((dir) => {
                let mod = require(`./modules/${dir}/main`);
                submodules.push(mod);
            });
            this.setState({
                submodules: submodules,
            });
        }

        componentDidUpdate() {
            if (this.performance != null) {
                let timeMs = new Date().getTime() - this.performance.getTime();
                this.performance = null;
                Instrumentation.addInteraction("Settings", timeMs);
            }
        }

        private renderContents(
            globalContext: GlobalContextContents
        ): JSX.Element {
            if (!this.mounted) {
                this.performance = new Date();
                this.mounted = true;
            }
            let items: JSX.Element[] = [];
            for (let submodule of this.state.submodules) {
                if (
                    submodule.requirePermission == null ||
                    submodule.requirePermission in globalContext.permissions
                ) {
                    const MainComponent: ComponentClass<{}, any> =
                        submodule.MainComponent;
                    items.push(<MainComponent />);
                }
            }
            return (
                <ScaledPage>
                    <div className="content-wrapper hide-scroll">
                        <section
                            className="content"
                            style={this.props.positionStyle}
                        >
                            <span className="big-title-span">
                                {`Version: ${config._Global.version}`}
                            </span>

                            {items}
                        </section>
                    </div>
                </ScaledPage>
            );
        }

        render() {
            return (
                <GlobalContext.ObserverConsumer>
                    {this.renderContents}
                </GlobalContext.ObserverConsumer>
            );
        }
    }
);

export { MainComponent };
export let route: string = "/settings.html",
    section: string = "Settings",
    leftPanelItem = {
        section: "Settings",
        href: "settings.html",
        icon: <SettingsIcon className={styles.icon} />,
    },
    requirePermission: string = "Settings";