import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Cookies from "universal-cookie";

import { mainStyle } from "./MainStyle";
import Popup from "reactjs-popup";
import PagesStore from "common/PagesStore";
import { observer } from "mobx-react";
import GroupsPermissionsSelector from "common/GroupsPermissionsSelector";
import Alert from "./Alert";
import {
    addUserPageToModuleApi,
    editUserPageModuleApi,
} from "common/ModulesApi";
import { addUserPageApi, editUserPageApi } from "common/UserApi";
import { getUserPageGroups } from "common/UserGroupsApi";

const cookies = new Cookies();

const AddPagePopup = observer(
    class AddPagePopup extends Component {
        constructor(props) {
            super(props);
            this.state = {
                title: this.props.title || "",
                errorMessage: undefined,
                selectedUserGroups: [],
            };
            this.inputRef = React.createRef();
        }

        componentDidMount() {
            if (this.inputRef.current) this.inputRef.current.focus();
            if (this.props.pageId) {
                getUserPageGroups(this.props.pageId)
                    .then((selectedUserGroups) => {
                        this.setState({
                            selectedUserGroups: selectedUserGroups,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
        componentWillUnmount() {}

        buildInnerView() {
            return (
                <div
                    className="center-container"
                    style={{
                        overflow: "visible",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{
                            overflow: "visible",
                            marginTop: 20,
                            height: "100%",
                            width: "90%",
                        }}
                    >
                        <input
                            ref={this.inputRef}
                            type="text"
                            className="like-tab"
                            placeholder="Enter title"
                            style={{
                                width: "100%",
                                alignSelf: "center",
                            }}
                            value={this.state.title}
                            onChange={(evt) => {
                                let title = evt.target.value;
                                this.setState({ title: title });
                            }}
                        />
                        {this.props.moduleId == null && (
                            <>
                                <span
                                    style={{
                                        marginTop: 20,
                                        fontFamily: "Roboto",
                                        fontSize: mainStyle.getPropertyValue(
                                            "--path-header-size"
                                        ),
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        fontWeight: 300,
                                    }}
                                >
                                    {"Select Groups and Permissions"}
                                </span>
                                <GroupsPermissionsSelector
                                    selectedUserGroups={
                                        this.state.selectedUserGroups
                                    }
                                    onChange={(selectedUserGroups) => {
                                        this.setState({
                                            selectedUserGroups: selectedUserGroups,
                                        });
                                    }}
                                />
                            </>
                        )}
                        <div
                            className="my-row"
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                                alignSelf: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: 10,
                                    width: "112px",
                                }}
                                onClick={() => {
                                    if (this.props.pageId) {
                                        if (this.props.moduleId == null) {
                                            editUserPageApi(
                                                this.state.title,
                                                this.props.pageId,
                                                this.state.selectedUserGroups
                                                    .filter(
                                                        (item) => item != null
                                                    )
                                                    .map((item) => ({
                                                        group_id: item.group_id,
                                                        permission_type:
                                                            item.permission_type,
                                                    }))
                                            )
                                                .then((id) => {
                                                    PagesStore(
                                                        this.props.type
                                                    ).updatePages();
                                                    this.props.onClose();
                                                })
                                                .catch((error) => {
                                                    this.setState({
                                                        errorMessage: error,
                                                    });
                                                });
                                        } else {
                                            editUserPageModuleApi(
                                                this.state.title,
                                                this.props.pageId,
                                                cookies.get(
                                                    "instrumentation_session_id"
                                                )
                                            )
                                                .then((id) => {
                                                    PagesStore(
                                                        this.props.type
                                                    ).updatePages();
                                                    this.props.onClose();
                                                })
                                                .catch((error) => {
                                                    this.setState({
                                                        errorMessage: error,
                                                    });
                                                });
                                        }
                                    } else {
                                        if (this.props.moduleId == null) {
                                            addUserPageApi(
                                                this.state.title,
                                                this.props.type,
                                                this.state.selectedUserGroups
                                                    .filter(
                                                        (item) => item != null
                                                    )
                                                    .map((item) => ({
                                                        group_id: item.group_id,
                                                        permission_type:
                                                            item.permission_type,
                                                    }))
                                            )
                                                .then((id) => {
                                                    PagesStore(
                                                        this.props.type
                                                    ).updatePages();

                                                    this.props.onClose();
                                                })
                                                .catch((error) => {
                                                    this.setState({
                                                        errorMessage: error,
                                                    });
                                                });
                                        } else {
                                            addUserPageToModuleApi(
                                                this.state.title,
                                                this.props.moduleId
                                            )
                                                .then((id) => {
                                                    PagesStore(
                                                        this.props.type
                                                    ).updatePages();

                                                    this.props.onClose();
                                                })
                                                .catch((error) => {
                                                    this.setState({
                                                        errorMessage: error,
                                                    });
                                                });
                                        }
                                    }
                                }}
                            >
                                SAVE
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: 10,
                                    width: "112px",
                                }}
                                onClick={() => {
                                    this.props.onClose();
                                }}
                            >
                                CLOSE
                            </Button>
                        </div>
                        {this.state.errorMessage && (
                            <Alert
                                text={"Error: ".concat(this.state.errorMessage)}
                                className="alert alert-danger alert-dismissible"
                                onClosed={() =>
                                    this.setState({
                                        errorMessage: undefined,
                                    })
                                }
                            />
                        )}
                    </div>
                </div>
            );
        }

        render() {
            let height = 240;
            let width = 450;
            return (
                <Popup
                    arrow={true}
                    contentStyle={{
                        width: width,
                        minHeight: height,
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    open={true}
                    onClose={() => {
                        this.props.onClose();
                    }}
                    closeOnDocumentClick
                >
                    <div
                        className="dashboard-rect"
                        style={{
                            overflow: "visible",
                            position: "absolute",
                            border: "1px solid black",
                            alignItems: "center",
                            cursor: "pointer",
                            minHeight: height,
                            width: width,
                        }}
                    >
                        {this.buildInnerView()}
                    </div>
                </Popup>
            );
        }
    }
);
export default AddPagePopup;
