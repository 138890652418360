import { Menu, Item, useContextMenu } from "react-contexify";

interface Props {
    menuId: string;
    onCreateFolder: Function;
}

export function FolderMenu(props: Props) {
    function handleContextMenu(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        show(event);
    }
    const menuId = props.menuId;
    const { show } = useContextMenu({
        id: menuId,
    });

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
                left: 0,
                top: 0,
            }}
            onContextMenu={handleContextMenu}
        >
            <Menu id={menuId}>
                <Item
                    onClick={() => {
                        props.onCreateFolder();
                    }}
                >
                    Create folder
                </Item>
            </Menu>
        </div>
    );
}
