import { formatDate } from "common/utilities/FormatDate";
import {
    SharedModuleCanvas,
    InnerCanvas,
    PageBarInfo,
    ModuleOptions,
    CanvasBackgroundResponse,
    parseBackground,
} from "common/Canvas";
import { Permission } from "common/Permissions";
import _ from "lodash";
import { PaymentType } from "common/mystripe_components/ProductParametersInput";

interface BriefModuleCommon {
    title: string;
    description?: string;
    keywords?: string[];
    editable: boolean;
    republish: boolean;
    thumbnail?: string;
}

export enum SpecialTabId {
    CreateNew = "CREATENEW",
    Tutorials = "TUTORIALS",
    Kit = "KIT",
    TutorialsInitialList = "TUTORIALS_INITIAL_LIST",
}
export function getSpecialTabIdIndex(tabId: SpecialTabId) {
    return Object.values(SpecialTabId).indexOf(tabId) + 1;
}

interface LinkSharingInfoResponse {
    link_id: string;
    require_authentication: boolean;
    creation_time: number;
    update_time: number;
    view_count: number;
    copied_from_link: boolean;
    show_as_slideshow: boolean;
    editable_by_collaborators: boolean;
    password_protection: boolean;
}

export interface LinkSharingInfo {
    link_id: string;
    require_authentication: boolean;
    creation_time: string;
    update_time: string;
    view_count: number;
    copied_from_link: boolean;
    show_as_slideshow: boolean;
    editable_by_collaborators: boolean;
    password_protection: boolean;
}

export interface BriefModuleRequest extends BriefModuleCommon {
    user_groups: string[];
    paywall?: {
        title: string;
        client: string;
        description: string;
        payment_type: PaymentType;
        price: number;
        logo: string;
    };
    category_id?: number;
}

export interface BriefModuleResponse extends BriefModuleCommon {
    id: number;
    tab_id: number | null | SpecialTabId;
    has_new_feedbacks: boolean;
    user_data?: {
        user_name: string;
        first_name: string;
        last_name: string;
        icon_url: string;
        is_admin: boolean;
        is_owner: boolean;
    };
    creation_time: number;
    update_time: number;
    permission_type: Permission;
    shared_module_id?: number;
    user_copy_exists?: boolean;
    shared_link?: LinkSharingInfoResponse;
    page_bar_info?: PageBarInfo;
    options?: ModuleOptions;
    folder_id?: number;
}

interface SharedModuleCommon {
    title: string;
    description?: string;
    keywords?: string[];
    thumbnail?: string;
    view_count: number;
    page_bar_info?: PageBarInfo;
    options?: ModuleOptions;
    show_as_slideshow: boolean;
}

interface ModuleSharedBoxElementResponse {
    id: number;
    box: string;
}

export interface SharedModuleResponse {
    module: SharedModuleInnerResponse;
    shared_boxes: { [key: string]: ModuleSharedBoxElementResponse[] };
    backgrounds: { [key: string]: CanvasBackgroundResponse[] };
}

export interface SharedModuleInnerResponse extends SharedModuleCommon {
    contents: string;
    creation_time: number;
    update_time: number;
}

export interface SharedModule extends SharedModuleCommon {
    pages: {
        pageId: string;
        sheets: SharedModuleCanvas[];
    }[];
    update_time: string;
    creation_time: string;
}

export function toSharedModule(sharedModuleResponse: SharedModuleResponse) {
    let sharedModule: SharedModule = {
        title: sharedModuleResponse.module.title,
        show_as_slideshow: sharedModuleResponse.module.show_as_slideshow,
        view_count: sharedModuleResponse.module.view_count,
        page_bar_info: sharedModuleResponse.module.page_bar_info,
        options: sharedModuleResponse.module.options,
        update_time: formatDate(
            sharedModuleResponse.module.update_time,
            "updated"
        ),
        creation_time: formatDate(
            sharedModuleResponse.module.creation_time,
            "created"
        ),
        pages: [],
    };
    let contents = JSON.parse(sharedModuleResponse.module.contents);
    let pages_order: string[] = contents["page_order"];
    let previous_sheet_ids: { [sheetId: string]: string } = _.invert(
        contents["previous_sheet_ids"]
    );
    let pages_optional_info: {
        [pageId: string]: {
            [sheetId: string]: {
                title: string;
                thumbnail: string | null;
                hide_in_slideshow?: boolean;
                layer_for_canvas_id?: string;
            };
        };
    } = contents["pages_optional_info"];
    let pages: { [pageId: string]: { [sheetId: string]: string } } =
        contents["pages"];
    if (pages_order == null) {
        pages_order = Object.keys(pages);
    }
    let pagesSlideOrder: { [key: string]: string[] } =
        contents["pages_slide_order"];
    for (let pageId of pages_order) {
        let page: {
            pageId: string;
            sheets: SharedModuleCanvas[];
        } = {
            pageId: pageId,
            sheets: [],
        };
        for (const sheetId of pagesSlideOrder[pageId]) {
            const contents = pages[pageId][sheetId];
            const layerForCanvasId = Number(
                previous_sheet_ids[
                    pages_optional_info[pageId][sheetId]?.layer_for_canvas_id ?? ""
                ]
            ) ?? undefined
            
            page.sheets.push({
                hide_in_slideshow:
                    pages_optional_info[pageId][sheetId].hide_in_slideshow ??
                    false,
                id: Number(previous_sheet_ids[sheetId]),
                layer_for_canvas_id: !isNaN(layerForCanvasId) ? layerForCanvasId : undefined,
                page_id: pageId,
                canvas: JSON.parse(contents) as InnerCanvas,
                backgrounds:
                    sharedModuleResponse.backgrounds[
                        previous_sheet_ids[sheetId]
                    ]?.map((background) => parseBackground(background)) ?? [],
                shared_boxes: (
                    sharedModuleResponse.shared_boxes[
                        previous_sheet_ids[sheetId]
                    ] ?? []
                ).map((item) => ({ ...item, box: JSON.parse(item.box) })),
                title: pages_optional_info[pageId][sheetId].title,
                thumbnail: pages_optional_info[pageId][sheetId].thumbnail,
            });
        }
        sharedModule.pages.push(page);
    }
    return sharedModule;
}

export interface BriefModule extends BriefModuleCommon {
    id: number;
    tab_id: number | null | SpecialTabId;
    has_new_feedbacks: boolean;
    user_data?: {
        // TODO Check if ? is necessary here
        user_name: string;
        first_name: string;
        last_name: string;
        icon_url: string;
        is_admin: boolean;
        is_owner: boolean;
    };
    creation_time: string;
    update_time: string;
    raw_timestamp: number;
    raw_update_timestamp: number;
    shared_module_id?: number;
    user_copy_exists?: boolean;
    shared_link?: LinkSharingInfo;
    page_bar_info?: PageBarInfo;
    options?: ModuleOptions;
    permission_type: Permission;
    folder_id?: number;
    category?: {
        id: number,
        title: string,
    }
}

export interface AppModuleResponse extends BriefModuleCommon {
    id: string;
    has_new_feedbacks: boolean;
    user_data?: {
        // TODO Check if ? is necessary here
        user_name: string;
        first_name: string;
        last_name: string;
        icon_url: string;
        is_admin: boolean;
        is_owner: boolean;
    };
    creation_time: number;
    update_time: number;
    app: {
        title: string;
        tab_id: number | null | SpecialTabId;
        description?: string;
        keywords?: string[];
        thumbnail?: string;
    };
    paywall?: {
        title: string;
        client: string;
        payment_type: string;
        price: number;
    };
    folder_id?: number;
    category?: {
        id: number,
        title: string,
    }
}
export interface AppModule extends BriefModuleCommon {
    id: string;
    has_new_feedbacks: boolean;
    user_data?: {
        // TODO Check if ? is necessary here
        user_name: string;
        first_name: string;
        last_name: string;
        icon_url: string;
        is_admin: boolean;
        is_owner: boolean;
    };
    creation_time: string;
    update_time: string;
    raw_timestamp: number;
    raw_update_timestamp: number;
    app: {
        title: string;
        tab_id: number | null | SpecialTabId;
        description?: string;
        keywords?: string[];
        thumbnail?: string;
    };
    paywall?: {
        title: string;
        client: string;
        payment_type: string;
        price: number;
    };
    folder_id?: number;
    category?: {
        id: number,
        title: string,
    }
}

export function toLinkSharingInfo(
    linkSharingInfoResponse: LinkSharingInfoResponse
): LinkSharingInfo {
    let result = {
        require_authentication: linkSharingInfoResponse.require_authentication,
        link_id: linkSharingInfoResponse.link_id,
        view_count: linkSharingInfoResponse.view_count,
        update_time: formatDate(linkSharingInfoResponse.update_time, "updated"),
        creation_time: formatDate(
            linkSharingInfoResponse.creation_time,
            "created"
        ),
        show_as_slideshow: linkSharingInfoResponse.show_as_slideshow,
        copied_from_link: linkSharingInfoResponse.copied_from_link,
        editable_by_collaborators:
            linkSharingInfoResponse.editable_by_collaborators,
        password_protection: linkSharingInfoResponse.password_protection,
    };
    return result;
}

export function toBriefModule(
    module: BriefModuleResponse,
    isCurrent: boolean,
    inHistory: boolean
): BriefModule {
    let result = {
        ...module,
        raw_timestamp: module.creation_time,
        raw_update_timestamp: module.update_time,
        update_time: formatDate(module.update_time, "updated"),
        creation_time: formatDate(module.creation_time, "created"),
        shared_link:
            module.shared_link != null
                ? toLinkSharingInfo(module.shared_link)
                : undefined,
    };
    return result;
}

export function toAppModule(module: AppModuleResponse): AppModule {
    let result = {
        ...module,
        raw_timestamp: module.creation_time,
        raw_update_timestamp: module.update_time,
        update_time: formatDate(module.update_time, "updated"),
        creation_time: formatDate(module.creation_time, "created"),
    };
    return result;
}
