import React from "react";
import Finding, { isBarChart, isMap, isPieChart } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import styles from "./AggregateSection.module.css";
import Select, { createFilter } from "react-select";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import StringOption from "common/StringOption";
import MagicWand from "../MagicWand";
import { DynamicOptionType } from "common/DynamicOptions";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { observer } from "mobx-react";
// import Variables, { VariableOption } from "common/Variables";
import {
    aggregationFunctions,
    //   additionalBarChartAndPieChartOperations,
    columnByOptions,
    //   additionalBarChartAndPieChartOptions,
} from "common/AggregationFunctionsv2";

interface Props {
    dashboardId: string;
    currentModuleId?: number;
    canvasTreeStore: CanvasTreeStore;
    finding: Finding;
    onClearEditing: () => void;
    onChange: (finding: Finding, updateData?: boolean) => void;
}

export default observer(function AggregateSection(props: Props) {
    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    // let selectedVariableIndex: number = -1;

    // if (props.finding.config.dataScope != null) {
    //     selectedVariableIndex = props.finding.content.data.findIndex(
    //         (item: any) => item.variableIndex != null
    //     );
    // }
    // let variableOptions: VariableOption[] = [];
    // let dataScopeId = props.finding.config.dataScope?.value;
    // if (dataScopeId != null)
    //     variableOptions = Variables(
    //         dataScopeId,
    //         props.currentModuleId
    //     ).variableOptions;
    let showAggregationOptions = props.finding.config.dataScope != null;
    if (isPieChart(props.finding)) {
        showAggregationOptions = true;
    }
    let chartAggregationFunctions = aggregationFunctions;
    // if (isBarChart(props.finding) || isPieChart(props.finding)) {
    //     chartAggregationFunctions = chartAggregationFunctions.concat(
    //         additionalBarChartAndPieChartOptions
    //     );
    // }
    let operationVariable = props.finding.config.operationVariable;
    if (isMap(props.finding)) {
        operationVariable = operationVariable ?? "mean";
    }
    return showAggregationOptions ? (
        <>
            <div className={styles.aggregationOptionContainer}>
                {!isMap(props.finding) && (
                    <MagicWand
                        onClearEditing={props.onClearEditing}
                        title={"Data aggregation"}
                        dashboardId={props.dashboardId}
                        allowMultiple={false}
                        canvasTreeStore={props.canvasTreeStore}
                        type={DynamicOptionType.Aggregation}
                        optionName={"operationVariable"}
                    />
                )}
                <span className={commonStyles.optionTitle}>
                    Data aggregation
                </span>
                <Select
                    id="dataAggregationSelect"
                    isClearable={false}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Aggregation"}
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            width: "120px",
                            height: "38px",
                            marginRight: "30px",
                        }),
                    }}
                    options={chartAggregationFunctions}
                    onChange={(newValue) => {
                        let newFinding = { ...props.finding };

                        // if (
                        //     additionalBarChartAndPieChartOperations.has(
                        //         (newValue as StringOption).value
                        //     )
                        // ) {
                        //     if (newFinding.content?.data != null) {
                        //         newFinding.content.data =
                        //             newFinding.content.data.slice(0, 1);
                        //     }
                        // }
                        if (
                            (isBarChart(props.finding) ||
                                isPieChart(props.finding)) &&
                            !columnByOptions.has(
                                (newValue as StringOption).value
                            )
                        ) {
                            newFinding.config.columnByVariable = null;
                            newFinding.config.columnByVariableIndex = null;
                        }
                        newFinding.config.operationVariable =
                            (newValue as StringOption | null)?.value ?? null;
                        props.onChange(newFinding, true);
                    }}
                    value={
                        chartAggregationFunctions.find(
                            (option) => option.value === operationVariable
                        ) ?? null
                    }
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            {/* {(isBarChart(props.finding) || isPieChart(props.finding)) &&
                columnByOptions.has(props.finding.config.operationVariable) && (
                    <div className={styles.aggregationOptionContainer}>
                        <span className={commonStyles.optionTitle}>
                            {"Column by"}
                        </span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Select variable"}
                            styles={{
                                ...selectStyles,
                                container: (base) => ({
                                    ...base,
                                    width: "100%",
                                    height: "38px",
                                }),
                            }}
                            options={variableOptions}
                            onChange={(newValue) => {
                                let newFinding = { ...props.finding };
                                newFinding.config.columnByVariableIndex = (
                                    newValue as VariableOption
                                ).value;
                                newFinding.config.columnByVariable = (
                                    newValue as VariableOption
                                ).label;
                                props.onChange(newFinding, true);
                            }}
                            value={variableOptions.find(
                                (option) =>
                                    option.value ===
                                    props.finding.config.columnByVariableIndex
                            ) ?? null}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                )} */}
        </>
    ) : null;
});
