import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import styles from "./PivotSection.module.css";
import DataScopeAndTableSelectorView from "common/DataScopeAndTableSelectorView";
import DataScopeCreatableSelectorView from "common/DataScopeCreatableSelectorView";
import {
    PivotState,
    transformData,
    transformOptions,
    TransformState,
    UnpivotState,
} from "common/TransformData";
import cx from "classnames";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import { GroupExtendedPermission } from "common/GroupPermissions";
import { VariableOption } from "common/Variables";
import { SocketIOInstance } from "common/ServerConnection";
import { preview, PreviewHeader } from "../MergeSection";
// import MessagePopup from "common/MessagePopup";
import MessagePopup from "../MessagePopup";
import DragAndDropStates from "common/DragAndDropStates";
import { useDrop } from "react-dnd";
import SelectedOption from "../SelectedOption";
import TabSwitchConfirmationPopup from "common/TabSwitchConfirmationPopup";

export interface CardProps {
    index: number;
    data: any;
    dataset: number;
}

const pivotHeaders = [
    { id: 0, name: "Week", type: "text" },
    { id: 1, name: "Item", type: "text" },
    { id: 2, name: "Spend", type: "double" },
];

const pivotBody = [
    ["1", "Grocery", "$100"],
    ["2", "Grocery", "$101"],
    ["3", "Grocery", "$102"],
    ["1", "Travel", "$200"],
    ["2", "Travel", "$201"],
    ["1", "Fun", "$302"],
];
const unpivotHeaders = [
    { id: 0, name: "Week", type: "text" },
    { id: 1, name: "Grocery", type: "double" },
    { id: 2, name: "Travel", type: "double" },
    { id: 3, name: "Fun", type: "double" },

];

const unpivotBody = [
    ["1", "$100", "$200", "$302"],
    ["2", "$101", "$201", ""],
    ["3", "$102", "", ""],
];

const unpivotedHeaders = [
    { id: 0, name: "Column 2", type: "text" },
    { id: 1, name: "Column 3", type: "double" },
];

const unpivotedData = [
    ["1", "Grocery", "$100"],
    ["2", "Grocery", "$101"],
    ["3", "Grocery", "$102"],
    ["1", "Travel", "$200"],
    ["2", "Travel", "$201"],
    ["1", "Fun", "$302"],
]
// const unpivotedData = [
//     ["12/09/20", "FB", "972"],
//     ["13/09/20", "FB", "973"],
//     ["14/09/20", "FB", "974"],
//     ["12/09/20", "IG", "975"],
//     ["13/09/20", "IG", "976"],
//     ["14/09/20", "IG", "977"],
//     ["12/09/20", "Tiktok", "978"],
//     ["13/09/20", "Tiktok", "979"],
//     ["14/09/20", "Tiktok", "980"],
// ];
export interface ppt {
    data: any;
    b: number;
    onDrop: (item: any) => void;
    values: { variable: VariableOption | null; name: string }[][][];
    pivotIndex: (VariableOption | null)[];
    sourceDataset: DataScopeOption | null;
    unpivotValues: Array<Array<Array<number | string | null>>>;
    variableColumnNames: string[];
    setVariableColumnNames: (item: any) => void;
    valueColumnNames: string[][];
    setValueColumnNames: (item: any) => void;
    setValues: (item: any) => void;
    lastPosition: number;
}

interface switchButton {
    options: any;
    onClick: (option: any) => void;
    selectedOption: string;
}
export function SwitchButtons(props: switchButton) {
    return (
        <div className={styles.transformContainer}>
            {props.options.map((option: any, index: number) => (
                <button
                    className={cx(
                        styles.dataHubButton,
                        option === props.selectedOption &&
                        styles.dataHubButtonSelected
                    )}
                    key={option}
                    type="button"
                    onClick={() => {
                        props.onClick(option)
                    }}
                >
                    {option}
                </button>
            ))}
        </div>
    )
}

const RightUnPivotTable = (props: ppt) => {
    let {
        data,
        b,
        onDrop,
        valueColumnNames,
        values,
        variableColumnNames,
        setValueColumnNames,
        setValues,
        setVariableColumnNames,
        unpivotValues,
        sourceDataset,
        pivotIndex,
        lastPosition,
    } = props;

    const [{ isOver4, canDrop4 }, drop4] = useDrop({
        accept: "image",
        drop: onDrop,
        collect: (monitor) => ({
            isOver4: monitor.isOver(),
            canDrop4: monitor.canDrop(),
        }),
    });

    return (
        <td
            ref={pivotIndex.length > 1 ? drop4 : null}
            className={
                DragAndDropStates.showDrag != null &&
                    pivotIndex.length > 1 &&
                    unpivotValues.length === 0 &&
                    sourceDataset != null &&
                    canDrop4
                    ? `${styles.dropIndicatorHighlighted} ${styles.previewLeftTable}`
                    : `${styles.dropIndicator} ${styles.previewLeftTable}  `
            }
            style={{
                border: isOver4
                    ? "2px dotted var(--hot-area-greenBorder)"
                    : pivotIndex.length > 1 && canDrop4
                        ? "2px dotted var(--hot-area-blueBorder)"
                        : unpivotValues[0].length <= 0
                            ? "2px dotted grey"
                            : "0px dotted transparent",

                backgroundColor: isOver4
                    ? "var(--hot-area-greenBg)"
                    : pivotIndex.length > 1 &&
                        unpivotValues.length <= 0 &&
                        canDrop4
                        ? "var(--hot-area-blueBg)"
                        : "transparent",

                color:
                    pivotIndex.length > 1 && isOver4
                        ? "var(--hot-area-greenBorder)"
                        : pivotIndex.length > 1 && canDrop4
                            ? "#0B7FB0"
                            : "",
                width: "auto",
            }}
        >
            {unpivotValues[0].length > 0 ? (
                <table className={styles.unpivotTable2}>
                    <thead>
                        <tr className={styles.tableRow}>
                            <td className={styles.indexBody}>
                                <input
                                    type={"text"}
                                    value={variableColumnNames[b]}
                                    onChange={(evt) => {
                                        evt.preventDefault();
                                        let value = evt.target.value;
                                        let newVariableColumnNames = [
                                            ...variableColumnNames,
                                        ];
                                        newVariableColumnNames[b] = value;
                                        setVariableColumnNames(
                                            newVariableColumnNames
                                        );
                                    }}
                                    className={styles.variableColumnHeader}
                                />
                            </td>
                            <td className={styles.indexBody}>
                                <input
                                    value={valueColumnNames[b]}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        let value = e.target.value;
                                        let newvalueColumnNames = [
                                            ...valueColumnNames,
                                        ];
                                        newvalueColumnNames[b][0] = value;
                                        setValueColumnNames(
                                            newvalueColumnNames
                                        );
                                    }}
                                    className={styles.variableColumnHeader}
                                />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((dp: any, index: number) => {
                            return (
                                <tr
                                    style={{
                                        outline:
                                            unpivotValues.length - 1 === index
                                                ? "2px dotted lightgrey"
                                                : "0px solid green",
                                        backgroundColor:
                                            lastPosition === b &&
                                                unpivotValues[lastPosition]
                                                    ?.length -
                                                1 ===
                                                index
                                                ? "rgba(59, 130, 201, 0.1)"
                                                : "transparent",
                                    }}
                                >
                                    <td
                                        colSpan={2}
                                        style={{ verticalAlign: "top" }}
                                    >
                                        {dp.map((value: any) => {
                                            return (
                                                <table style={{ width: "100%" }}   >
                                                    <tr
                                                        className={styles.tableRow}
                                                        style={{ height: 37 }}
                                                    >
                                                        <td
                                                            className={styles.indexBody}
                                                            style={{
                                                                width: "50%",
                                                                padding: "3px 15px",
                                                            }}
                                                        >
                                                            <input
                                                                type={"text"}
                                                                value={values[b][0][index]?.variable?.label}
                                                                onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    let oldVariable = values[b][0][index].variable;
                                                                    let variable: VariableOption | null = null;
                                                                    if (oldVariable) {
                                                                        variable =
                                                                        {
                                                                            label: value,
                                                                            value: oldVariable?.value,
                                                                            type: oldVariable?.type,
                                                                            panel: oldVariable?.panel,
                                                                        };
                                                                    }
                                                                    let newValues = [...values,];
                                                                    newValues[b][0].splice(index, 1, {
                                                                        variable: variable,
                                                                        name: value,
                                                                    }
                                                                    );
                                                                    setValues(newValues);
                                                                }}
                                                                className={styles.variableColumnHeader}
                                                            />
                                                        </td>
                                                        <td
                                                            className={
                                                                styles.indexBody
                                                            }
                                                            style={{
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {value}
                                                        </td>
                                                    </tr>
                                                </table>
                                            );
                                        })}
                                    </td>
                                </tr>
                            );
                        })}
                        {DataScopes.preview?.headers &&
                            unpivotValues.flat(1).length <
                            DataScopes.preview.headers.length - 1 &&
                            unpivotValues.length <
                            DataScopes.preview.headers.length - 1 ? (
                            <tr style={{ height: 67 }}>
                                <td
                                    colSpan={2}
                                    rowSpan={2}
                                    style={{
                                        padding: 20,
                                        textAlign: "center",
                                        border: "1px solid lightgrey",
                                    }}
                                >
                                    Drop your new variables here
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            ) : (
                <table className={styles.unpivotTable2}>
                    {sourceDataset != null && (
                        <span
                            className={styles.dropOvershow}
                            style={{ transform: "translate(-53%, -18%)" }}
                        >
                            {" "}
                            <h1>2</h1> Drop your new variables here
                        </span>
                    )}
                    <thead>
                        {/* <thead style={{ opacity: 0.5 }}> */}
                        <tr
                            className={`${styles.tableRow} ${styles.strawmanHeaderRow}`}
                            style={{ top: -2 }}
                        >
                            {unpivotedHeaders.map((item, i) => (
                                <td className={styles.indexBody} style={{ opacity: 0.5 }}>
                                    {item.name}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody style={{ opacity: 0.5 }}>
                        {unpivotedData.map((item) => (
                            <tr className={styles.tableRow}>
                                {item.map(
                                    (name, i) =>
                                        i > 0 && (
                                            <td className={styles.indexBody}>
                                                {name}
                                            </td>
                                        )
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </td>
    );
};

// function AddRemoveComponent(props: AddRemoveProps) {
//     return (
//         <div className="flex-simple-column" style={{ marginLeft: 5 }}>
//             <Button
//                 className={"btn-small-questionnaire"}
//                 title={"Add variable"}
//                 style={{
//                     width: "19px",
//                     height: "19px",
//                     border: "none",
//                 }}
//                 onClick={props.onAdd}
//             >
//                 {"\uFF0B" /* plus */}
//             </Button>
//             {props.showRemoveButton && (
//                 <Button
//                     className={"btn-small-questionnaire"}
//                     title={"Remove variable"}
//                     style={{
//                         width: "19px",
//                         height: "19px",
//                         border: "none",
//                     }}
//                     onClick={props.onRemove}
//                 >
//                     {"\uFF0D" /* minus */}
//                 </Button>
//             )}
//         </div>
//     );
// }


export function strawmanPreview(
    previewHeader: PreviewHeader[] | null,
    previewBody: Array<Array<string | number | null>> | null,
) {
    return (
        <div className={`${styles.strawmanWrapper} scrollBar`}>
            <div className={`${styles.scrollbar} scrollBar`}>
                <table
                    className={styles.previewTable}
                >
                    <thead>
                        <tr className={styles.strawmanHeaderRow}>
                            {
                                previewHeader?.map(item => (
                                    <td className={styles.strawmanHeaderData}>{item.name}</td>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            previewBody?.map(item => (
                                <tr className={styles.strawmanRow}>
                                    {
                                        item.map(value => (
                                            <td className={styles.strawmanData}>{value}</td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}












function PivotSection(props: { setOperationInProgress: (state: boolean) => void; }) {
    let [sourceDataset, setSourceDataset] =
        React.useState<DataScopeOption | null>(null);
    let [transformType, setTransformType] = React.useState<"pivot" | "unpivot">(
        transformOptions[0]
    );
    let roomIdRef = React.useRef<string | undefined>(undefined);
    let [targetDataset, setTargetDataset] =
        React.useState<DataScopeOption | null>(null);
    let [sourceTable, setSourceTable] = React.useState<TableOption | null>(
        null
    );
    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    let [transforming, setTransforming] = React.useState<boolean>(false);
    let [permissions, setPermissions] = React.useState<
        (GroupExtendedPermission | null)[]
    >([]);
    let [pivotIndex, setPivotIndex] = React.useState<(VariableOption | null)[]>(
        [null]
    );
    let [pivotIndexBody, setPivotIndexBody] = React.useState<
        (string | number | null)[][]
    >([[""]]);
    let [pivotValues, setPivotValues] = React.useState<
        (VariableOption | null)[]
    >([null]);
    let [pivotValuesList, setPivotValuesList] = React.useState<string[][]>([
        [""],
    ]);
    let [pivotColumns, setPivotColumns] = React.useState<
        (VariableOption | null)[]
    >([null]);
    let [pivotColumnsName, setPivotColumnsName] = React.useState<string[][]>([
        [""],
    ]);

    let [variableColumnNames, setVariableColumnNames] = React.useState<
        string[]
    >(["Column 2"]);
    // let [variableColumns, setVariableColumns] = React.useState<string[]>([]);
    let [valueColumnNames, setValueColumnNames] = React.useState<string[][]>([
        ["Column 3"],
    ]);
    let [values, setValues] = React.useState<
        { variable: VariableOption | null; name: string }[][][]
    >([
        [
            [
                {
                    name: "",
                    variable: null,
                },
            ],
        ],
    ]);
    let [unpivotValues, setunpivotValues] = useState<
        Array<Array<Array<number | string | null>>>
    >([[]]);
    let [savedataSet, setsavedataSet] = useState<boolean>(false);
    let [columnNumber, setcolumnNumber] = useState<number>();
    let [hoverEffect, sethoverEffect] = useState<number>(0);
    let [lastPosition, setlastPosition] = useState<number>(0);
    let [demoPivotHeaders, setdemoPivotHeaders] = useState<PreviewHeader[] | []>(pivotHeaders);
    let [demoPivotBody, setdemoPivotBody] = useState<string[][] | []>(pivotBody);
    let [demoUnpivotHeaders, setdemoUnpivotHeaders] = useState<PreviewHeader[] | []>(unpivotHeaders);
    let [demoUnpivotBody, setdemoUnpivotBody] = useState<string[][] | []>(unpivotBody);

    React.useEffect(() => {
        props.setOperationInProgress((sourceDataset)? true : false);
    }, [sourceDataset]);
    
    const onError = (errorMessage: string) => {
        setTransforming(false);
        setStatusPopup({
            status: PopupStatus.Error,
            message: errorMessage,
        });
        if (roomIdRef.current != null) {
            leaveChangesRoom(roomIdRef.current);
            roomIdRef.current = undefined;
        }
    };
    const onSuccess = (preview: boolean) => {
        setTransforming(false);
        setStatusPopup({
            status: PopupStatus.Success,
            message: "Reshaped",
        });
        setTimeout(() => {
            setStatusPopup(null)
        }, 3000);
        if (!preview) {
            DataScopes.update();
            setTimeout(() => {
                DataScopes.update();
            }, 5000);
            if (roomIdRef.current != null) {
                leaveChangesRoom(roomIdRef.current);
                roomIdRef.current = undefined;
            }
        }
    };

    const subscribeHandler = (response: {
        data: {
            success: boolean;
            error_msg: string;
            data: {
                format_error?: boolean;
            };
        };
    }) => {
        if (response.data.success) {
            onSuccess(false);
        } else {
            onError(response.data.error_msg);
        }
    };
    const subscribeToDatasetUpdates = () => {
        SocketIOInstance?.on("dataset_uploading_changes", subscribeHandler);
    };
    useEffect(() => {
        subscribeToDatasetUpdates();
        return function cleanup() {
            SocketIOInstance?.off(
                "dataset_uploading_changes",
                subscribeHandler
            );
            if (roomIdRef.current != null) {
                leaveChangesRoom(roomIdRef.current);
            }
        };
    }, []);
    const joinChangesRoom = (roomId: string) => {
        SocketIOInstance?.emit("dataset_uploading_changes_join", {
            room: roomId,
        });
    };

    const leaveChangesRoom = (roomId: string) => {
        SocketIOInstance?.emit("dataset_uploading_changes_leave", {
            room: roomId,
        });
    };
    const executeTransform = (
        transformState: TransformState,
        preview: boolean
    ) => {
        setTransforming(true);
        setStatusPopup({
            status: PopupStatus.Success,
            message: "Reshaping data...",
        });
        transformData(
            transformState,
            (previewData, roomId) => {
                setStatusPopup({
                    status: PopupStatus.Success,
                    message: "Reshaped",
                });
                setTimeout(() => {
                    setStatusPopup(null)
                }, 3000);

                if (!preview) {
                    roomIdRef.current = roomId;
                    joinChangesRoom(roomId!);
                    DataScopes.update();
                } else {
                    onSuccess(true);
                }
            },
            (errorMessage: string) => {
                onError(errorMessage);
            },
            preview ? 5 : undefined
        );
    };

    const [{ canDrop1, isOver1 }, drop1] = useDrop(
        () => ({
            accept: "image",
            drop: (item: any) => {
                const variable = {
                    label: item.name.name,
                    value: item.name.id,
                    type: item.name.type,
                    panel: "regular",
                };

                let matched = false;
                pivotIndex.map((curr, i) => {
                    if (curr?.value === item.name.id) {
                        setPivotIndex([...pivotIndex]);
                        matched = true;
                    }
                    return null;
                });
                if (!matched) {
                    setPivotIndex([...pivotIndex, variable]);
                }
                DataScopes.columnValues(
                    item.name.id,
                    Number(sourceDataset?.value)
                );
                setcolumnNumber(1);
            },
            collect: (monitor) => ({
                isOver1: !!monitor.isOver(),
                canDrop1: !!monitor.canDrop(),
            }),
        }),
        [pivotIndex]
    );

    const [{ canDrop2, isOver2 }, drop2] = useDrop(
        () => ({
            accept: "image",
            drop: (item: any) => {
                const variable = {
                    label: item.name.name,
                    value: item.name.id,
                    type: item.name.type,
                    panel: "regular",
                };
                let matched = false;
                pivotColumns.map((curr, i) => {
                    if (curr?.value === item.name.id) {
                        setPivotColumns([...pivotColumns]);
                        matched = true;
                    }
                    return null;
                });
                if (!matched) {
                    setPivotColumns([...pivotColumns, variable]);
                }

                DataScopes.columnValues(
                    item.name.id,
                    Number(sourceDataset?.value)
                );
                setcolumnNumber(2);
            },
            collect: (monitor) => ({
                isOver2: !!monitor.isOver(),
                canDrop2: !!monitor.canDrop(),
            }),
        }),
        [pivotColumns]
    );

    const [{ canDrop3, isOver3 }, drop3] = useDrop(
        () => ({
            accept: "image",
            drop: (item: any) => {
                const variable = {
                    label: item.name.name,
                    value: item.name.id,
                    type: item.name.type,
                    panel: "regular",
                };
                let matched = false;
                pivotValues.map((curr, i) => {
                    if (curr?.value === item.name.id) {
                        setPivotValues([...pivotValues]);
                        matched = true;
                    }
                    return null;
                });
                if (!matched) {
                    setPivotValues([...pivotValues, variable]);
                }
                DataScopes.columnValues(
                    item.name.id,
                    Number(sourceDataset?.value)
                );
                setcolumnNumber(3);
            },
            collect: (monitor) => ({
                isOver3: !!monitor.isOver(),
                canDrop3: !!monitor.canDrop(),
            }),
        }),
        [pivotValues]
    );

    const [{ canDrop5, isOver5 }, drop5] = useDrop(
        () => ({
            accept: "image",
            drop: (item: any) => {
                const variable: VariableOption = {
                    label: item.name.name,
                    value: item.name.id,
                    type: item.name.type,
                    panel: "regular",
                };
                let matched = false;
                values.map((curr, i) => {
                    curr.map((itm) => {
                        itm.map((data) => {
                            if (data?.variable?.value === item.name.id) {
                                setValues([...values]);
                                matched = true;
                            }
                            return null;
                        });
                        return null;
                    });
                    return null;
                });
                pivotIndex.map((data) => {
                    if (data?.value === item.name.id) {
                        setValues([...values]);
                        matched = true;
                    }
                    return null;
                });
                if (!matched) {
                    let newValues = [...values];
                    newValues.push([
                        [{ variable: variable, name: variable.label }],
                    ]);
                    setValues(newValues);
                    setlastPosition(newValues.length - 1);

                    let newVariableColumnNames = [...variableColumnNames];
                    newVariableColumnNames.push(
                        `Column ${Number(
                            newVariableColumnNames[
                                newVariableColumnNames.length - 1
                            ].charAt(
                                newVariableColumnNames[
                                    newVariableColumnNames.length - 1
                                ].length - 1
                            )
                        ) + 2
                        }`
                    );
                    setVariableColumnNames(newVariableColumnNames);

                    let newValueColumnNames = [...valueColumnNames];
                    newValueColumnNames.push([
                        `Column ${newVariableColumnNames.length * 2 + 1}`,
                    ]);
                    setValueColumnNames(newValueColumnNames);

                    let arr: Array<string | number | null> = [];
                    let arr2 = [...unpivotValues];

                    DataScopes.preview.body?.map((data) => {
                        arr = [...arr, data[item.name.id]];
                        return null;
                    });
                    arr.pop()
                    arr2.push([arr]);
                    setunpivotValues(arr2);
                }
                setcolumnNumber(0);
            },

            collect: (monitor) => ({
                isOver5: !!monitor.isOver(),
                canDrop5: !!monitor.canDrop(),
            }),
        }),
        [values, unpivotValues]
    );

    useEffect(() => {
        if (columnNumber === 1) {
            // if (transformType === "pivot") {
            //     DataScopes.columnvalues.pop();
            //     setPivotIndexBody((prev) => [...prev, DataScopes.columnvalues]);
            // } else {
            let arr: Array<string | null | number> = [];
            DataScopes.preview.body?.map((item, i) => {
                if (pivotIndex[1]) {
                    arr = [...arr, item[pivotIndex[1].value]];
                }
                return null;
            });
            arr.pop();
            arr = transformType === "pivot" ? Array.from(new Set(arr)) : arr;
            setPivotIndexBody((prev) => [...prev, arr]);
            // }

            setcolumnNumber(0);
        } else if (columnNumber === 2) {
            setPivotColumnsName((prev) => [...prev, DataScopes.columnvalues]);
            setcolumnNumber(0);
        } else if (columnNumber === 3) {
            let list: Array<Array<string>> = [];

            pivotColumnsName[0] && pivotColumnsName[0].map((data, i) => {
                list.push([]);
                return null;
            });

            DataScopes.previewState.body?.map((item, i) => {
                let index: number | null = null;

                if (pivotColumns[1]?.value) {
                    index = pivotColumnsName[0].indexOf(
                        String(item[pivotColumns[1]?.value])
                    );
                    if (index >= 0 && pivotValues[1]) {
                        list[index].push(String(item[pivotValues[1].value]));
                    }
                }
                return null;
            });
            list = list[0].map((data, i) =>
                list.map((item, ind) => list[ind][i])
            );
            setPivotValuesList([...list]);
            setcolumnNumber(0);
        } else if (columnNumber === 4) {
            let arr: Array<string | number | null> = [];
            let arr2 = [...unpivotValues];

            values.map((a, i) => {
                a.map((b, index) => {
                    b.map((_, ind) => {
                        ind === b.length - 1 &&
                            DataScopes.preview.body?.map((item) => {
                                if (values[i][index][ind].variable?.value) {
                                    arr = [
                                        ...arr,
                                        item[
                                        values[i][index][ind].variable
                                            ?.value!
                                        ],
                                    ];
                                }
                                return null;
                            });
                        arr.length > 0 && arr2[0].push(arr);
                        return null;
                    });
                    return null;
                });
                return null;
            });
            arr2.pop();
            setunpivotValues(arr2);
            setcolumnNumber(0);
        }
    }, [DataScopes.columnValuesState]);


    let demoIndexVariableBody: (string | number)[] = [];
    let demoColumnVariables: (string | number)[] = [];
    let demoValueVariables: (string | number)[][] = [];
    let demolist: Array<Array<string | number>> = [[], [], []];

    demoPivotBody.map((item) => {
        demoIndexVariableBody = [...demoIndexVariableBody, item[0]];
        demoColumnVariables = [...demoColumnVariables, item[1]];

        let index: number | null = null;
        index = Array.from(new Set(demoColumnVariables)).indexOf(
            item[demoPivotHeaders[1]?.id]?.toString()
        );
        // demolist[index].push(item[demoPivotHeaders[2]?.id]);
        let ind: number | null = null;
        ind = Array.from(new Set(demoIndexVariableBody)).indexOf(
            item[0]?.toString()
        );
        // demolist[index].push(item[demoPivotHeaders[2]?.id]);
        demolist[index][ind] = (item[demoPivotHeaders[2]?.id]);
        return null;
    });
    demolist = demolist[0].map((data, i) =>
        demolist.map((item, ind) => demolist[ind][i])
    );
    demoValueVariables = [...demolist];

    useEffect(() => {
        return () => {
            DragAndDropStates.makedefault();
            DataScopes.previewState = { headers: null, body: null };
        };
    }, []);

    function rightPreviewTable() {
        return (
            <>
                {
                    pivotValuesList.map((item, i) => (
                        <tr className={styles.tableRow}>
                            {item.map((curr) => (
                                <td className={styles.indexBody}>{curr}</td>
                            ))}
                        </tr>
                    ))
                }

                {
                    pivotValuesList.length < pivotIndexBody[0].length &&
                    pivotIndexBody[0].map(
                        (item, index) =>
                            index <=
                            pivotIndexBody[0].length -
                            pivotValuesList.length -
                            1 && (
                                <tr className={styles.tableRow}>
                                    <td className={styles.indexBody}></td>
                                </tr>
                            )
                    )
                }
            </>
        );
    }

    function leftPreviewTable() {
        return (
            <>
                {pivotIndexBody.map((item, index) =>
                    item.map((curr) => (
                        <tr className={styles.tableRow}>
                            {curr && (
                                <td className={styles.indexBody}>{curr}</td>
                            )}
                        </tr>
                    ))
                )}

                {pivotValues.length > 1 &&
                    pivotIndexBody[0].length < pivotValuesList?.length &&
                    pivotValuesList.map(
                        (item, i) =>
                            i <=
                            pivotValuesList.length -
                            pivotIndexBody[0].length -
                            1 && (
                                <tr className={styles.tableRow}>
                                    <td className={styles.indexBody}></td>
                                </tr>
                            )
                    )}
            </>
        );
    }

    function handleDrop(item: any, b: number) {
        const variable: VariableOption = {
            label: item.name.name,
            value: item.name.id,
            type: item.name.type,
            panel: "regular",
        };

        let matched = false;
        values.map((curr, i) => {
            curr.map((itm) => {
                itm.map((data) => {
                    if (data?.variable?.value === item.name.id) {
                        setValues([...values]);
                        matched = true;
                    }
                    return null;
                });
                return null;
            });
            return null;
        });

        pivotIndex.map((data) => {
            if (data?.value === item.name.id) {
                setValues([...values]);
                matched = true;
            }
            return null;
        });

        if (!matched) {
            let newValues = [...values];
            if (values[0][0][0].variable) {
                newValues[b][0].push({
                    variable: variable,
                    name: variable.label,
                });
                pivotIndexBody.length < newValues[b][0].length &&
                    pivotIndexBody.push(pivotIndexBody[0]);
            } else {
                newValues[0][0].splice(0, 1, {
                    variable: variable,
                    name: variable.label,
                });
            }

            setValues((prev) => newValues);
            DataScopes.columnValues(item.name.id, Number(sourceDataset?.value));
            setlastPosition(b);

            let arr: Array<string | number | null> = [];
            let arr2 = [...unpivotValues];

            DataScopes.preview.body?.map((data) => {
                arr = [...arr, data[item.name.id]];
                return null;
            });
            arr.pop()
            arr2[0].length > 0 ? arr2[b].push(arr) : arr2.splice(0, 1, [arr]);

            setunpivotValues(arr2);
            setcolumnNumber(0);
        }
    }

    const makeDefault = () => {
        setPivotValues([null]);
        setPivotColumns([null]);
        setPivotColumnsName([]);
        setPivotIndex([null]);
        setPivotIndexBody([]);
        setVariableColumnNames(["Column 2"]);
        setValueColumnNames([["Column 3"]]);
        setValues([
            [
                [
                    {
                        variable: null,
                        name: "",
                    },
                ],
            ],
        ]);
        setunpivotValues([[]]);
    }

    let allValues: (VariableOption | null)[] = [];
    if (transformType === "unpivot")
        allValues = values.flat(2).map((value) => value.variable);
    let variablesAreSelected =
        transformType === "pivot"
            ? pivotValues.filter((value) => value != null).length > 0 &&
            pivotColumns.filter((value) => value != null).length > 0 &&
            pivotIndex.filter((value) => value != null).length > 0
            : pivotIndex.filter((value) => value != null).length > 0 &&
            allValues.filter((value) => value != null).length > 0;

    return (
        <div className="mainContainer">
            <h1 style={{ display: "none" }}>{DragAndDropStates.showDrag}</h1>
            <h1 style={{ display: "none" }}>{DragAndDropStates.datasett}</h1>

            <div className={"datasetsContainer"}>
                <div className={"dataset"}>
                    <div className="datasetSelector">
                        <div className={styles.textItem}>Source</div>
                        <DataScopeAndTableSelectorView
                            dataHubMode
                            dataScopeOption={sourceDataset}
                            tableOption={sourceTable}
                            onChange={(dataScopeOption, tableOption) => {
                                setSourceDataset(dataScopeOption);
                                setSourceTable(tableOption);

                                setdemoPivotBody([])
                                setdemoPivotHeaders([])
                                setdemoUnpivotBody([])
                                setdemoUnpivotHeaders([])

                                makeDefault();

                                if (dataScopeOption?.value != null) {
                                    DataScopes.DatasetPreview(
                                        dataScopeOption?.value,
                                    );
                                }
                                DataScopes.previewState = {
                                    headers: null,
                                    body: null,
                                };
                                DataScopes.previewState = { headers: null, body: null };

                                SelectedOption.UpdateaskSwitchTab(false);
                                SelectedOption.UpdateSwitchTab(false);
                            }}
                            dataScopePlaceholder={"Select dataset"}
                            tableOptionPlaceholder={"Select table"}
                            needWritePermissions={false}
                            tab="Reshape"
                            ChangeDataset={setSourceDataset}
                            onReset={() => {
                                setdemoPivotBody(pivotBody);
                                setdemoPivotHeaders(pivotHeaders);
                                setdemoUnpivotBody(unpivotBody);
                                setdemoUnpivotHeaders(unpivotHeaders);
                                makeDefault();

                                SelectedOption.UpdateaskSwitchTab(false);
                                SelectedOption.UpdateSwitchTab(true);
                            }}
                        />
                    </div>
                    {DataScopes.preview?.headers != null ? (
                        <div
                            className={"customPreview"}
                            style={{ padding: "0px 15px 15px 15px" }}
                        >
                            {preview(
                                DataScopes.preview?.headers,
                                DataScopes.preview?.body,
                                1,
                                false,
                                "Reshape"
                            )}
                        </div>
                    ) : transformType === "pivot" ? (
                        <div className={`${styles.Manish} customPreview`}>
                            {
                                strawmanPreview(
                                    demoPivotHeaders,
                                    demoPivotBody)
                            }
                        </div>
                    ) : (
                        <div className={`${styles.Manish} customPreview`}>
                            {
                                strawmanPreview(
                                    demoUnpivotHeaders,
                                    demoUnpivotBody)
                            }
                        </div>
                    )}
                </div>
            </div>

            <div
                className={styles.transformAndTargetContainer}
                style={{ marginBlock: 22 }}
            >
                <div className={styles.textItem} style={{ margin: "0 5px 0 0" }}>
                    Transform options
                </div>
                <div className="flex-simple-column">
                    <SwitchButtons
                        options={transformOptions}
                        onClick={(option) => {
                            setTransformType(option);
                            setPivotValuesList([[""]]);
                            setPivotValues([null]);
                            setPivotColumns([null]);
                            setPivotColumnsName([]);
                            setPivotIndex([null]);
                            setPivotIndexBody([]);
                            setVariableColumnNames(["Column 2"]);
                            setValueColumnNames([["Column 3"]]);
                            setValues([
                                [
                                    [
                                        {
                                            variable: null,
                                            name: "",
                                        },
                                    ],
                                ],
                            ]);
                            setunpivotValues([[]]);
                        }}
                        selectedOption={transformType}
                    />
                </div>
            </div>

            {transformType === "pivot" && (
                <div className={styles.variableContainer}>
                    <div className={`${styles.scrollable} scrollBar`}>
                        <div
                            className={styles.dropVariable1}
                            ref={pivotIndex.length === 1 ? drop1 : null}
                            style={{
                                border:
                                    DragAndDropStates.showDrag != null &&
                                        pivotIndex.length <= 1 &&
                                        sourceDataset != null &&
                                        isOver1
                                        ? "2px dotted var(--hot-area-greenBorder)"
                                        : DragAndDropStates.showDrag != null &&
                                            pivotIndex.length <= 1 &&
                                            sourceDataset != null &&
                                            canDrop1
                                            ? "2px dotted #0B7FB0"
                                            : pivotIndex.length <= 1 &&
                                                sourceDataset != null
                                                ? "2px dotted grey"
                                                : "0px dotted grey",
                                borderWidth:
                                    sourceDataset != null
                                        ? "2px 2px 2px 2px"
                                        : "0px",
                                marginRight:
                                    pivotIndex.length <= 1 &&
                                        sourceDataset != null
                                        ? 5
                                        : 0,
                            }}
                        >
                            {
                                <div className={styles.dropletItem}>
                                    {pivotIndex.length > 1 ? (
                                        pivotIndex.map(
                                            (card, i) =>
                                                card != null && (
                                                    <table
                                                        className={
                                                            styles.previewLeftTable
                                                        }
                                                    >
                                                        <thead>
                                                            <tr
                                                                className={
                                                                    styles.tableRow
                                                                }
                                                            >
                                                                <td
                                                                    className={
                                                                        styles.indexBody
                                                                    }
                                                                >
                                                                    {
                                                                        card?.label
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {leftPreviewTable()}
                                                        </tbody>
                                                    </table>
                                                )
                                        )
                                    ) : (
                                        <table
                                            onMouseOver={() => {
                                                sethoverEffect(1);
                                            }}
                                            onMouseLeave={() => {
                                                sethoverEffect(0);
                                            }}
                                            className={
                                                (DragAndDropStates.showDrag !=
                                                    null &&
                                                    pivotIndex.length === 1 &&
                                                    sourceDataset != null) ||
                                                    (hoverEffect === 1 &&
                                                        sourceDataset != null &&
                                                        canDrop1)
                                                    ? `${styles.dropIndicatorHighlighted} ${styles.previewLeftTable}`
                                                    : `${styles.dropIndicator} ${styles.previewLeftTable}`
                                            }
                                            style={{
                                                backgroundColor:
                                                    DragAndDropStates.showDrag !=
                                                        null &&
                                                        pivotIndex.length <= 1 &&
                                                        sourceDataset != null &&
                                                        isOver1
                                                        ? " var(--hot-area-greenBg)"
                                                        : DragAndDropStates.showDrag !=
                                                            null &&
                                                            pivotIndex.length <=
                                                            1 &&
                                                            sourceDataset !=
                                                            null &&
                                                            canDrop1
                                                            ? "var(--hot-area-blueBg)"
                                                            : "transparent",
                                                color:
                                                    DragAndDropStates.showDrag !=
                                                        null &&
                                                        pivotIndex.length <= 1 &&
                                                        sourceDataset != null &&
                                                        isOver1
                                                        ? " var(--hot-area-greenBorder)"
                                                        : DragAndDropStates.showDrag !=
                                                            null &&
                                                            pivotIndex.length <=
                                                            1 &&
                                                            sourceDataset !=
                                                            null &&
                                                            canDrop1
                                                            ? "#0B7FB0"
                                                            : "",
                                            }}
                                        >
                                            {sourceDataset != null && (
                                                <span
                                                    className={
                                                        styles.dropOvershow
                                                    }
                                                    // style={{
                                                    //     transform:
                                                    //         "translate(-50%, -25%)",
                                                    // }
                                                    style={{
                                                        color:
                                                            hoverEffect ===
                                                                1
                                                                ? "#0B7FB0"
                                                                : "#555",
                                                        transform: "translate(-50%, -25%)"
                                                    }}

                                                >
                                                    {" "}
                                                    <h1 style={{ marginTop: 10 }}>1</h1> Drag data to set
                                                    <br /> row index
                                                </span>
                                            )}
                                            <thead>
                                                <tr
                                                    className={`${styles.tableRow}`}
                                                // style={{ border: 'none' }}
                                                >
                                                    <td
                                                        className={
                                                            styles.indexBody
                                                        }
                                                        style={{ opacity: 0.5 }}
                                                    >
                                                        {demoPivotHeaders[0]?.name}
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.from(
                                                    new Set(
                                                        demoIndexVariableBody
                                                    )
                                                ).map((value) => (
                                                    <tr
                                                        className={
                                                            styles.tableRow
                                                        }
                                                    >
                                                        <td
                                                            className={
                                                                styles.indexBody
                                                            }
                                                            style={{
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            {value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            }
                        </div>

                        <div className={styles.dropVariable2}>
                            <div className={styles.headerTableContainer}>
                                {
                                    <table
                                        className={`${styles.previewRightTable}  `}
                                        style={{
                                            height:
                                                pivotValues.length <= 1
                                                    ? "100%"
                                                    : "auto",
                                        }}
                                    >
                                        {pivotColumns.length <= 1 &&
                                            sourceDataset != null &&
                                            pivotIndex.length <= 1 && (
                                                <span
                                                    className={
                                                        styles.dropOvershow
                                                    }
                                                    onMouseOver={() => {
                                                        sethoverEffect(2);
                                                    }}
                                                    onMouseLeave={() => {
                                                        sethoverEffect(0);
                                                    }}
                                                    style={{
                                                        color:
                                                            DragAndDropStates.showDrag !=
                                                                null &&
                                                                pivotIndex.length >
                                                                1 &&
                                                                pivotColumns.length <=
                                                                1 &&
                                                                isOver2
                                                                ? "var(--hot-area-greenBorder)"
                                                                : (DragAndDropStates.showDrag !=
                                                                    null &&
                                                                    pivotIndex.length >
                                                                    1 &&
                                                                    pivotColumns.length <=
                                                                    1 &&
                                                                    canDrop2) ||
                                                                    hoverEffect ===
                                                                    2
                                                                    ? "#0B7FB0"
                                                                    : "#555",
                                                        transform: "translate(-50%, -135%)"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            position: "absolute",
                                                            transform: "translate(-122%, -21%)",
                                                            textAlign: "center",
                                                        }}
                                                    >2</span> Drag data to
                                                    set columns
                                                </span>
                                            )}
                                        {pivotValues.length <= 1 &&
                                            sourceDataset != null &&
                                            pivotIndex.length <= 1 && (
                                                <span
                                                    className={
                                                        styles.dropOvershow
                                                    }
                                                    onMouseOver={() => {
                                                        sethoverEffect(3);
                                                    }}
                                                    onMouseLeave={() => {
                                                        sethoverEffect(0);
                                                    }}
                                                    style={{
                                                        transform:
                                                            "translate(-50%, 175%)",
                                                        color:
                                                            DragAndDropStates.showDrag !=
                                                                null &&
                                                                pivotColumns.length >
                                                                1 &&
                                                                pivotValues.length <=
                                                                1 &&
                                                                isOver3
                                                                ? "var(--hot-area-greenBorder)"
                                                                : (DragAndDropStates.showDrag !=
                                                                    null &&
                                                                    pivotColumns.length >
                                                                    1 &&
                                                                    pivotValues.length <=
                                                                    1 &&
                                                                    canDrop3) ||
                                                                    hoverEffect ===
                                                                    3
                                                                    ? "#0B7FB0"
                                                                    : "#555",
                                                    }}
                                                >
                                                    {" "}
                                                    <span
                                                        style={{
                                                            position: "absolute",
                                                            transform: "translate(-125%, -20%)",
                                                        }}
                                                    >3</span> Drag data to
                                                    set cell values
                                                </span>
                                            )}
                                        <thead
                                            ref={
                                                pivotColumns.length === 1
                                                    ? drop2
                                                    : null
                                            }
                                            onMouseOver={() => {
                                                sethoverEffect(2);
                                            }}
                                            onMouseLeave={() => {
                                                sethoverEffect(0);
                                            }}
                                            className={
                                                (DragAndDropStates.showDrag !=
                                                    null &&
                                                    pivotIndex.length > 1 &&
                                                    pivotColumns.length <= 1) ||
                                                    (hoverEffect === 2 &&
                                                        pivotColumns.length <= 1 &&
                                                        sourceDataset != null &&
                                                        canDrop2)
                                                    ? `${styles.dropIndicatorHighlighted} ${styles.previewLeftTable}`
                                                    : `${styles.dropIndicator} ${styles.previewLeftTable}`
                                            }
                                            style={{
                                                border:
                                                    DragAndDropStates.showDrag !=
                                                        null &&
                                                        pivotColumns.length <= 1 &&
                                                        pivotIndex.length > 1 &&
                                                        sourceDataset != null &&
                                                        isOver2
                                                        ? "2px dotted var(--hot-area-greenBorder)"
                                                        : DragAndDropStates.showDrag !=
                                                            null &&
                                                            pivotColumns.length <=
                                                            1 &&
                                                            pivotIndex.length >
                                                            1 &&
                                                            sourceDataset !=
                                                            null &&
                                                            canDrop2
                                                            ? "2px dotted #0B7FB0"
                                                            : pivotColumns.length <=
                                                                1 &&
                                                                sourceDataset != null
                                                                ? "2px dotted grey"
                                                                : "0px dotted grey",

                                                backgroundColor:
                                                    DragAndDropStates.showDrag !=
                                                        null &&
                                                        pivotColumns.length <= 1 &&
                                                        pivotIndex.length > 1 &&
                                                        sourceDataset != null &&
                                                        isOver2
                                                        ? "var(--hot-area-greenBg)"
                                                        : DragAndDropStates.showDrag !=
                                                            null &&
                                                            pivotColumns.length <=
                                                            1 &&
                                                            pivotIndex.length >
                                                            1 &&
                                                            sourceDataset !=
                                                            null &&
                                                            canDrop2
                                                            ? "var(--hot-area-blueBg)"
                                                            : "transparent",
                                            }}
                                        >
                                            {pivotColumns.length > 1 ? (
                                                <tr className={styles.tableRow}>
                                                    {pivotColumnsName.map(
                                                        (card) =>
                                                            card.map(
                                                                (header) => (
                                                                    <td
                                                                        className={
                                                                            styles.indexBody
                                                                        }
                                                                    >
                                                                        {header}
                                                                    </td>
                                                                )
                                                            )
                                                    )}
                                                </tr>
                                            ) : pivotIndex.length <= 1 ? (
                                                <tr className={styles.tableRow}>
                                                    {Array.from(
                                                        new Set(
                                                            demoColumnVariables
                                                        )
                                                    ).map((card) => (
                                                        <td
                                                            className={
                                                                styles.indexBody
                                                            }
                                                            style={{
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            {card}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ) : (
                                                <div
                                                    ref={drop2}
                                                    className={
                                                        DragAndDropStates.showDrag !=
                                                            null &&
                                                            pivotIndex.length > 1 &&
                                                            pivotColumns.length <=
                                                            1 &&
                                                            canDrop2
                                                            ? styles.dropIndicatorHighlighted1
                                                            : styles.dropIndicator1
                                                    }
                                                    style={{
                                                        color:
                                                            DragAndDropStates.showDrag !=
                                                                null &&
                                                                pivotIndex.length >
                                                                1 &&
                                                                pivotColumns.length <=
                                                                1 &&
                                                                isOver2
                                                                ? "var(--hot-area-greenBorder)"
                                                                : canDrop2
                                                                    ? "#0B7FB0"
                                                                    : "",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            marginRight: 14,
                                                            fontSize: 22,
                                                        }}
                                                    >
                                                        2
                                                    </span>
                                                    Drag data to set columns
                                                </div>
                                            )}
                                        </thead>

                                        <tbody
                                            ref={
                                                pivotValues.length === 1 &&
                                                    pivotColumns.length > 1
                                                    ? drop3
                                                    : null
                                            }
                                            onMouseOver={() => {
                                                sethoverEffect(3);
                                            }}
                                            onMouseLeave={() => {
                                                sethoverEffect(0);
                                            }}
                                            className={
                                                (DragAndDropStates.showDrag !=
                                                    null &&
                                                    pivotColumns.length > 1 &&
                                                    pivotValues.length <= 1) ||
                                                    (hoverEffect === 3 &&
                                                        pivotColumns.length <= 1 &&
                                                        sourceDataset != null &&
                                                        canDrop3)
                                                    ? `${styles.dropIndicatorHighlighted} ${styles.previewLeftTable}`
                                                    : `${styles.dropIndicator} ${styles.previewLeftTable}`
                                            }
                                            style={{
                                                border:
                                                    DragAndDropStates.showDrag !=
                                                        null &&
                                                        pivotValues.length <= 1 &&
                                                        pivotColumns.length > 1 &&
                                                        sourceDataset != null &&
                                                        isOver3
                                                        ? "2px dotted var(--hot-area-greenBorder)"
                                                        : DragAndDropStates.showDrag !=
                                                            null &&
                                                            pivotValues.length <=
                                                            1 &&
                                                            pivotColumns.length >
                                                            1 &&
                                                            sourceDataset !=
                                                            null &&
                                                            canDrop3
                                                            ? "2px dotted #0B7FB0"
                                                            : pivotValues.length <=
                                                                1 &&
                                                                sourceDataset != null
                                                                ? "2px dotted grey"
                                                                : "0px dotted grey",
                                                borderWidth:
                                                    sourceDataset != null
                                                        ? "0px 2px 2px 2px"
                                                        : "0px",
                                                backgroundColor:
                                                    DragAndDropStates.showDrag !=
                                                        null &&
                                                        pivotValues.length <= 1 &&
                                                        pivotColumns.length > 1 &&
                                                        sourceDataset != null &&
                                                        isOver3
                                                        ? "var(--hot-area-greenBg)"
                                                        : DragAndDropStates.showDrag !=
                                                            null &&
                                                            pivotValues.length <=
                                                            1 &&
                                                            pivotColumns.length >
                                                            1 &&
                                                            sourceDataset !=
                                                            null &&
                                                            canDrop3
                                                            ? "var(--hot-area-blueBg)"
                                                            : "transparent",
                                            }}
                                        >
                                            {pivotValues.length > 1 ? (
                                                rightPreviewTable()
                                            ) : pivotIndex.length <= 1 ? (
                                                demoValueVariables.map(
                                                    (item, i) => (
                                                        <tr
                                                            className={
                                                                styles.tableRow
                                                            }
                                                        >
                                                            {item.map(
                                                                (curr) => (
                                                                    <td
                                                                        className={
                                                                            styles.indexBody
                                                                        }
                                                                        style={{
                                                                            opacity: 0.5,
                                                                        }}
                                                                    >
                                                                        {curr}
                                                                    </td>
                                                                )
                                                            )}
                                                        </tr>
                                                    )
                                                )
                                            ) : (
                                                <tr className={styles.tableRow}>
                                                    <td
                                                        colSpan={
                                                            pivotColumnsName[0]
                                                                ?.length
                                                        }
                                                    >
                                                        <div
                                                            ref={
                                                                pivotValues.length ===
                                                                    1 &&
                                                                    pivotColumns.length >
                                                                    1
                                                                    ? drop3
                                                                    : null
                                                            }
                                                            className={
                                                                DragAndDropStates.showDrag !=
                                                                    null &&
                                                                    pivotColumns.length >
                                                                    1 &&
                                                                    pivotValues.length <=
                                                                    1 &&
                                                                    canDrop3
                                                                    ? styles.dropIndicatorHighlighted1
                                                                    : styles.dropIndicator1
                                                            }
                                                            style={{
                                                                color:
                                                                    DragAndDropStates.showDrag !=
                                                                        null &&
                                                                        pivotColumns.length >
                                                                        1 &&
                                                                        pivotValues.length <=
                                                                        1 &&
                                                                        isOver3
                                                                        ? "var(--hot-area-greenBorder)"
                                                                        : DragAndDropStates.showDrag !=
                                                                            null &&
                                                                            pivotColumns.length >
                                                                            1 &&
                                                                            pivotValues.length <=
                                                                            1 &&
                                                                            canDrop3
                                                                            ? "#0B7FB0"
                                                                            : "",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    marginRight: 14,
                                                                    fontSize: 22,
                                                                }}
                                                            >
                                                                {" "}
                                                                3
                                                            </span>{" "}
                                                            Drag data to set
                                                            cell values
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {transformType === "unpivot" && (
                <div className={styles.variableContainer}>
                    <div
                        className={`${styles.scrollable} scrollBar`}
                        style={{ display: "block" }}
                    >
                        {
                            <table
                                className={styles.previewRightTable}
                                style={{
                                    height: pivotValues.length <= 1 ? "100%" : "auto",
                                }}
                            >
                                <tr>
                                    <td
                                        onMouseOver={() => { sethoverEffect(1); }}
                                        onMouseLeave={() => { sethoverEffect(0); }}
                                        className={
                                            (DragAndDropStates.showDrag !=
                                                null ||
                                                hoverEffect === 1) &&
                                                pivotIndex.length <= 1 &&
                                                sourceDataset != null &&
                                                canDrop1
                                                ? `${styles.dropIndicatorHighlighted} `
                                                : `${styles.dropIndicator} `
                                        }
                                        style={{
                                            border: isOver1
                                                ? "2px dotted var(--hot-area-greenBorder)"
                                                : pivotIndex.length <= 1 &&
                                                    canDrop1
                                                    ? "2px dotted var(--hot-area-blueBorder)"
                                                    : pivotIndex.length <= 1 &&
                                                        sourceDataset != null
                                                        ? "2px dotted grey"
                                                        : "0px dotted transparent",
                                            backgroundColor: isOver1
                                                ? "var(--hot-area-greenBg)"
                                                : pivotIndex.length <= 1 &&
                                                    canDrop1
                                                    ? "var(--hot-area-blueBg)"
                                                    : "",
                                            color: isOver1
                                                ? "var(--hot-area-greenBorder)"
                                                : pivotIndex.length <= 1 &&
                                                    canDrop1
                                                    ? "#0B7FB0"
                                                    : "",
                                            marginRight:
                                                pivotIndex.length <= 1 ? 5 : 0,
                                            width: "20%",
                                        }}
                                    >
                                        {pivotIndex.length > 1 ? (
                                            <table className={styles.unpivotTable1}    >
                                                <thead>
                                                    <tr
                                                        className={styles.tableRow}
                                                    >
                                                        <td
                                                            className={styles.indexBody}
                                                        >
                                                            {pivotIndex[1]?.label}
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {leftPreviewTable()}
                                                    <tr aria-rowspan={2}></tr>
                                                </tbody>
                                            </table>
                                        ) : (
                                            <table
                                                ref={drop1}
                                                className={styles.unpivotTable1}
                                                style={{ position: "relative" }}
                                            >
                                                {sourceDataset != null && (
                                                    <span
                                                        className={
                                                            styles.dropOvershow
                                                        }
                                                        style={{
                                                            transform:
                                                                "translate(-53%, -23%)",
                                                            width: 173,
                                                        }}
                                                    >
                                                        {" "}
                                                        <h1>1</h1> Drop column
                                                        to set
                                                        <br /> row index
                                                    </span>
                                                )}

                                                {/* <thead style={{ opacity: 0.5 }}> */}
                                                <thead>
                                                    <tr
                                                        className={
                                                            `${styles.tableRow} ${styles.strawmanHeaderRow}`
                                                        }
                                                        style={{ top: -2 }}
                                                    >
                                                        <td
                                                            className={
                                                                styles.indexBody
                                                            }
                                                            style={{ opacity: 0.5 }}
                                                        >
                                                            {
                                                                demoUnpivotHeaders[0]?.name
                                                            }
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ opacity: 0.5 }}>
                                                    {unpivotedData.map(
                                                        (item, i) => {
                                                            return (
                                                                <tr
                                                                    className={
                                                                        styles.tableRow
                                                                    }
                                                                >
                                                                    <td
                                                                        className={
                                                                            styles.indexBody
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        {
                                                                            item[0]
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        )}
                                    </td>

                                    {unpivotValues.map((data, b) => {
                                        return (
                                            <RightUnPivotTable
                                                data={data}
                                                b={b}
                                                onDrop={(item) =>
                                                    handleDrop(item, b)
                                                }
                                                key={b}
                                                values={values}
                                                pivotIndex={pivotIndex}
                                                sourceDataset={sourceDataset}
                                                unpivotValues={unpivotValues}
                                                variableColumnNames={
                                                    variableColumnNames
                                                }
                                                setVariableColumnNames={
                                                    setVariableColumnNames
                                                }
                                                valueColumnNames={
                                                    valueColumnNames
                                                }
                                                setValueColumnNames={
                                                    setValueColumnNames
                                                }
                                                setValues={setValues}
                                                lastPosition={lastPosition}
                                            />
                                        );
                                    })}

                                    {values[0][0][0].variable != null &&
                                        DataScopes.preview?.headers &&
                                        unpivotValues.length <
                                        DataScopes.preview.headers.length -
                                        1 &&
                                        unpivotValues.flat(1).length <
                                        DataScopes.preview.headers.length -
                                        1 && (
                                            <td
                                                ref={drop5}
                                                onMouseOver={() => {
                                                    sethoverEffect(3);
                                                }}
                                                onMouseLeave={() => {
                                                    sethoverEffect(0);
                                                }}
                                                className={
                                                    (DragAndDropStates.showDrag !=
                                                        null ||
                                                        hoverEffect === 3) &&
                                                        pivotIndex.length <= 1 &&
                                                        sourceDataset != null &&
                                                        canDrop1
                                                        ? `${styles.dropIndicatorHighlighted} `
                                                        : `${styles.dropIndicator} `
                                                }
                                                style={{
                                                    border:
                                                        pivotIndex.length > 1 &&
                                                            isOver5
                                                            ? "2px dotted var(--hot-area-greenBorder)"
                                                            : pivotIndex.length >
                                                                1 && canDrop5
                                                                ? "2px dotted var(--hot-area-blueBorder)"
                                                                : pivotIndex.length >
                                                                    1 &&
                                                                    sourceDataset !=
                                                                    null
                                                                    ? "2px dotted grey"
                                                                    : "2px dotted transparent",
                                                    backgroundColor:
                                                        pivotIndex.length > 1 &&
                                                            isOver5
                                                            ? "var(--hot-area-greenBg)"
                                                            : pivotIndex.length >
                                                                1 && canDrop5
                                                                ? "var(--hot-area-blueBg)"
                                                                : "",
                                                    color:
                                                        pivotIndex.length > 1 &&
                                                            isOver5
                                                            ? "var(--hot-area-greenBorder)"
                                                            : pivotIndex.length >
                                                                1 && canDrop5
                                                                ? "#0B7FB0"
                                                                : "",
                                                    marginRight:
                                                        pivotIndex.length <= 1
                                                            ? 5
                                                            : 0,
                                                    width: "10%",
                                                    textAlign: "center",
                                                    paddingTop: 60,
                                                    verticalAlign: "top",
                                                }}
                                            >
                                                <span>
                                                    {" "}
                                                    Drop new
                                                    <br /> columns here
                                                </span>
                                            </td>
                                        )}
                                </tr>
                            </table>
                        }
                    </div>
                </div>
            )}


            <div className={"pageEndbuttons"}>
                <button
                    // (sourceDataset != null)
                    className={"cancelBtn"}
                    type="button"
                    disabled={
                        // !variablesAreSelected ||
                        // transforming ||
                        sourceDataset == null ||
                        (pivotIndex.length <= 1 && pivotColumns.length <= 1)
                        //  ||
                        // sourceTable == null
                    }
                    onClick={() => {
                        // setSourceDataset(null)
                        setPivotValues([null]);
                        setPivotColumns([null]);
                        setPivotColumnsName([]);
                        setPivotIndex([null]);
                        setPivotIndexBody([]);
                        setunpivotValues([[]]);
                        setValues([
                            [
                                [
                                    {
                                        variable: null,
                                        name: "",
                                    },
                                ],
                            ],
                        ]);
                    }}
                >
                    {"Reset"}
                </button>
                <button
                    className={"acceptBtn"}
                    type="button"
                    disabled={
                        !variablesAreSelected ||
                        sourceDataset == null ||
                        sourceTable == null
                    }
                    onClick={() => {
                        setsavedataSet(true);
                        setTargetDataset(null);
                    }}
                >
                    {"Reshape"}
                </button>
            </div>


            {
                (SelectedOption.getAskSwitchTab && sourceDataset !== null) &&
                <TabSwitchConfirmationPopup />
            }

            {
                savedataSet && (
                    <MessagePopup
                        title={<p >Target dataset</p>}
                        acceptButtonTitle="Reshape"
                        rejactButton="false"
                        message={
                            <div className={styles.datasetSelector}>
                                <DataScopeCreatableSelectorView
                                    hidePermissions
                                    dataHubMode
                                    value={targetDataset ?? undefined}
                                    onChange={(dataScopeOption) => {
                                        setTargetDataset(dataScopeOption);
                                    }}
                                    permissions={permissions}
                                    onPermissionsChange={setPermissions}
                                ></DataScopeCreatableSelectorView>
                            </div>
                        }
                        disabled={targetDataset == null}
                        onAccept={() => {
                            executeTransformClick(false);
                            setsavedataSet(false);
                            setSourceDataset(null);
                            setdemoPivotBody(pivotBody)
                            setdemoPivotHeaders(pivotHeaders)
                            setdemoUnpivotBody(unpivotBody)
                            setdemoUnpivotHeaders(unpivotHeaders)
                            makeDefault();
                            DataScopes.previewState = { headers: null, body: null };


                            SelectedOption.UpdateaskSwitchTab(false);
                            SelectedOption.UpdateSwitchTab(true);
                        }}
                        onReject={() => {
                            setsavedataSet(false);
                        }}
                    />
                )}

            {
                statusPopup != null && (
                    <StatusPopup
                        status={statusPopup.status}
                        message={statusPopup.message}
                        onClose={() => {
                            setStatusPopup(null);
                        }}
                    />
                )
            }
        </div >
    );

    function executeTransformClick(preview: boolean) {
        if (transformType === "pivot") {
            let transformState: PivotState = {
                sourceDataScope: sourceDataset!,
                sourceTable: sourceTable!,
                pivotColumns: pivotColumns,
                pivotIndex: pivotIndex,
                pivotValues: pivotValues,
                transformType: transformType,
                permissions: permissions,
                targetDataScope: targetDataset,
            };
            executeTransform(transformState, preview);
        } else {
            let transformState: UnpivotState = {
                sourceDataScope: sourceDataset!,
                sourceTable: sourceTable!,
                pivotIndex: pivotIndex,
                variableColumnNames: variableColumnNames,
                valueColumnNames: valueColumnNames,
                values: values,
                transformType: transformType,
                permissions: permissions,
                targetDataScope: targetDataset,
            };
            executeTransform(transformState, preview);
        }
    }
}

export default observer(PivotSection);
