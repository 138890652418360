import React from "react";
import MergeDataView from "../MergeDataView";
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { Resizable } from "re-resizable";
import elements from "common/CanvasElements";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import HtmlElementProps from "./HtmlElementProps";
import {
    getNewSizeAfterResize2,
    changeElementWhenResize2,
} from "../BaseCanvasResizableFunctions";
import { InnerCanvasChanges } from "common/Canvas";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../Snap";

interface InnerProps extends HtmlElementProps {
    mergeDataId: string;
    rootDataTestId: string;
}

interface InnerState {}

const typeName = "mergeDataElementsState";

@observer
class MergeDataElementWrapper extends CanvasInteractionComponent<
    InnerProps,
    InnerState
> {
    drag: boolean;
    constructor(props: InnerProps) {
        super(props);
        this.drag = false;
    }
    render() {
        const { canvasViewMode } = this.props.canvasTreeStore;
        let key = this.props.mergeDataId;
        let mergeDataElement = this.props.canvasTreeStore.mergeDataElementsState.get(
            key
        )!;
        let mergeDataElementSize = {
            height:
                mergeDataElement.nodeSize[canvasViewMode].height *
                this.props.scale,
            width:
                mergeDataElement.nodeSize[canvasViewMode].width *
                this.props.scale,
        };

        return (
            <DraggableWithSnapping
                key={key}
                disabled={this.props.live || !this.props.canWrite}
                position={{
                    x:
                        mergeDataElement.nodePosition[canvasViewMode].x *
                        this.props.scale,
                    y:
                        mergeDataElement.nodePosition[canvasViewMode].y *
                        this.props.scale,
                }}
                onDrag={(_evt, _data) => {
                    this.drag = true;
                    let nearestPoints = this.props.onRebuildSnapLine(
                        {
                            x: _data.x,
                            y: _data.y,
                            width: mergeDataElementSize.width,
                            height: mergeDataElementSize.height,
                        },
                        {
                            type: typeName,
                            id: this.props.mergeDataId,
                            groupId: mergeDataElement.groupId,
                        }
                    );
                    this.props.onUpdateSelectionBounds?.();
                    let newPosition = snapElementToPoints(
                        mergeDataElementSize.width,
                        mergeDataElementSize.height,
                        nearestPoints
                    );
                    if (newPosition.x != null || newPosition.y != null) {
                        // Snap to this position
                        return newPosition;
                    }
                }}
                onStop={(_evt, data) => {
                    if (this.drag) {
                        this.props.onDeleteSnapLine();
                        this.trackNewPerformance(elements.mergeDataProcessing);
                        let x = Math.max(data.x / this.props.scale, 0);
                        let y = Math.max(data.y / this.props.scale, 0);
                        let deltaX =
                            x - mergeDataElement.nodePosition[canvasViewMode].x;
                        let deltaY =
                            y - mergeDataElement.nodePosition[canvasViewMode].y;

                        const nodePosition = {
                            ...mergeDataElement.nodePosition,
                            [canvasViewMode]: {
                                x: Math.max(data.x / this.props.scale, 0),
                                y: Math.max(data.y / this.props.scale, 0),
                            },
                        };

                        let changes: InnerCanvasChanges = {};
                        this.props.canvasTreeStore.updateMergeDataElementAction(
                            key,
                            { nodePosition },
                            changes
                        );
                        this.props.canvasTreeStore.updateCanvasSizeAction({
                            x: x,
                            y: y,
                            width:
                                mergeDataElement.nodeSize[canvasViewMode].width,
                            height:
                                mergeDataElement.nodeSize[canvasViewMode]
                                    .height,
                        });
                        this.props.onMoveGroupSelection(
                            deltaX,
                            deltaY,
                            {
                                id: this.props.mergeDataId,
                                type: typeName,
                                groupId: mergeDataElement.groupId,
                            },
                            false,
                            changes
                        );
                        this.props.canvasTreeStore.saveChangesAction(
                            changes,
                            true,
                            true,
                            false,
                            this.props.canvasTreeStore.backgroundsState.toJSON(),
                            BackgroundMode.Update,
                            false
                        );
                        this.drag = false;
                    }
                }}
            >
                <div
                    onContextMenu={(evt) => {
                        this.props.onContextMenu(
                            evt,
                            {
                                id: key,
                                type: typeName,
                            },
                            true
                        );
                    }}
                    style={{
                        top: 0,
                        left: 0,
                        position: "absolute",
                        zIndex: mergeDataElement.zIndex ?? 50,
                    }}
                >
                    <Resizable
                        className="selectable-by-pointer"
                        ref={(ref) => {
                            let innerRef = ref?.resizable;
                            if (innerRef != null) {
                                innerRef.setAttribute("type", typeName);
                                if (mergeDataElement.groupId != null)
                                    innerRef.setAttribute(
                                        "groupId",
                                        mergeDataElement.groupId
                                    );
                                else {
                                    innerRef.removeAttribute("groupId");
                                }
                                innerRef.setAttribute(
                                    "id",
                                    String(this.props.mergeDataId)
                                );
                                innerRef.setAttribute(
                                    "data-test-id",
                                    this.props.rootDataTestId
                                );
                            }
                        }}
                        enable={
                            this.props.live || !this.props.canWrite
                                ? {
                                      top: false,
                                      right: false,
                                      bottom: false,
                                      left: false,
                                      topRight: false,
                                      bottomRight: false,
                                      bottomLeft: false,
                                      topLeft: false,
                                  }
                                : {
                                      top: true,
                                      right: true,
                                      bottom: true,
                                      left: true,
                                      topRight: true,
                                      bottomRight: true,
                                      bottomLeft: true,
                                      topLeft: true,
                                  }
                        }
                        onResizeStart={(evt) => {
                            evt.stopPropagation();
                        }}
                        onResize={(_e, _direction, _ref, d) => {
                            const {
                                canvasViewMode,
                            } = this.props.canvasTreeStore;
                            changeElementWhenResize2(
                                {
                                    position: mergeDataElement.nodePosition,
                                    size: mergeDataElement.nodeSize,
                                },
                                this.props.scale,
                                _direction,
                                d,
                                _ref,
                                canvasViewMode
                            );
                            this.props.onUpdateSelectionBounds?.();
                        }}
                        onResizeStop={(_e, _direction, _ref, d) => {
                            const {
                                canvasViewMode,
                            } = this.props.canvasTreeStore;
                            this.trackNewPerformance(
                                elements.mergeDataProcessing
                            );

                            let newSize = getNewSizeAfterResize2(
                                {
                                    position: mergeDataElement.nodePosition,
                                    size: mergeDataElement.nodeSize,
                                },
                                this.props.scale,
                                _direction,
                                d,
                                canvasViewMode
                            );

                            this.props.canvasTreeStore.updateMergeDataElementAction(
                                key,
                                {
                                    nodePosition: newSize.nodePosition,
                                    nodeSize: newSize.nodeSize,
                                }
                            );
                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                x: newSize.nodePosition[canvasViewMode].x,
                                y: newSize.nodePosition[canvasViewMode].y,
                                ...newSize.nodeSize[canvasViewMode],
                            });
                            this.props.onResize();
                        }}
                        size={mergeDataElementSize}
                    >
                        <MergeDataView
                            onContextMenu={(evt) => {
                                this.props.onContextMenu(
                                    evt,
                                    {
                                        id: key,
                                        type: typeName,
                                    },
                                    true
                                );
                            }}
                            sharedPolicy={this.props.sharedPolicy}
                            frozen={!this.props.canWrite}
                            height={mergeDataElementSize.height}
                            live={this.props.live}
                            mergeDataElement={mergeDataElement}
                            mergeDataElementId={key}
                            onChange={(mergeDataElement) => {
                                this.trackNewPerformance(
                                    elements.mergeDataProcessing
                                );

                                this.props.canvasTreeStore.updateMergeDataElementAction(
                                    key,
                                    mergeDataElement
                                );
                            }}
                            onDelete={() => {
                                this.trackNewPerformance(
                                    elements.mergeDataProcessing
                                );
                                this.props.showDeletePopup(() => {
                                    this.props.onClearEditing();
                                    this.props.canvasTreeStore.deleteMergeDataElementAction(
                                        key
                                    );
                                });
                            }}
                            currentModuleId={this.props.currentModuleId}
                        />
                    </Resizable>
                </div>
            </DraggableWithSnapping>
        );
    }
}

export default observer(function MergeDataElements(props: HtmlElementProps) {
    let mergeUis: JSX.Element[] = [];
    for (let mergeDataId of props.canvasTreeStore.mergeDataElementsState.keys())
        mergeUis.push(
            <MergeDataElementWrapper
                rootDataTestId={`mergeDataElement-${mergeUis.length + 1}`}
                key={mergeDataId}
                mergeDataId={mergeDataId}
                {...props}
            />
        );
    return <>{mergeUis}</>;
});
