import React, { Component } from "react";
import TagProps from "./TagProps";
import {
    CanvasTextBox,
    isTextBox,
    //    generateRawValue,
    getFormulaTextEntities,
    generateRawMetric,
    StatusColorType,
    getStatusColor,
    getCanvasTextBoxOutput,
    getAnimateNumberFormatOutput,
    NodePosition,
    NodeSize,
    InnerCanvasChanges,
    DefaultValueType,
} from "common/Canvas";
import elements from "common/CanvasElements";
import CanvasTreeStore, { CanvasViewMode } from "../CanvasTreeStore";
import {
    tagDefaultWidth,
    tagDefaultHeight,
    identifierFontSize,
    fontFamilies,
} from "../Constants";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { Resizable } from "re-resizable";
import {
    SelectionState,
    ContentState,
    ContentBlock,
    EditorState,
    Modifier,
    convertToRaw,
    convertFromRaw,
    convertFromHTML,
    getVisibleSelectionRect,
    RawDraftContentState,
} from "draft-js";
// @ts-ignore
import { CHECKABLE_LIST_ITEM } from "draft-js-checkable-list-item";
import { Editor } from "common/react_draft_wysiwyg/src/index";
import "common/styles/draft-styles.css";
import { throttle } from "lodash";

import DropdownNodeMenu from "./DropdownNodeMenu";
import Immutable from "immutable";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import getSelectedBlocks from "common/draft_utils/getSelectedBlocks";
import { observer } from "mobx-react";
import { mainStyle } from "common/MainStyle";
import _ from "lodash";
import AsyncLock from "common/AsyncLock";
import delay from "common/utilities/delay";
import { UserIcon } from "common/UserIcon";
import "./Tags.css";
import Hashids from "hashids";
import defaultBlockStyleFn from "common/react_draft_wysiwyg/src/utils/BlockStyle";
import FormulaPopup from "../FormulaPopup";
import Portal from "common/Portal";
import FormulaInformationStore, { InputType } from "../FormulaInformationStore";
import { PortalType } from "../SectionPortal";
import { getNewSizeAfterResize2 } from "../BaseCanvasResizableFunctions";
import CustomLinkOption from "./text_box_components/CustomLinkOption";
import sessionId from "common/SessionId";
import StringUtils from "common/utilities/StringUtils";
import mobileBreakpoint, {
    mobileAndTabletBreakpoint,
    onDoubleTouch,
    openDelayedNodeMenuAfterTouch,
} from "common/utilities/UIResponsiveManager";

import {
    jsonDiffPatchBlocks,
    jsonDiffPatchEntityMap,
} from "common/draft_utils/JsonDiffs";
import FontLoader from "common/FontLoader";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import SavePresetPopup from "./SavePresetPopup";
import OutsideAlerter from "common/OutsideAlerter";
import {
    keepInlineStyles,
    removeInlineStyles,
    setLineHeightPropToTextParent,
} from "common/draft_utils/patchedInlineUtils";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../Snap";

const hashids = new Hashids();

interface CheckListOptionProps {
    editorState: EditorState;
    onChange: (editorState: EditorState) => void;
}

/*!
 * helper function getSelectedBlockElement returns
 * rect of current focused element
 */
function getSelectedBlockElement() {
    var selection = window.getSelection();
    if (selection!.rangeCount === 0) return null;
    var node: any = selection!.getRangeAt(0).startContainer;
    do {
        if (
            node != null &&
            node.getAttribute &&
            node.getAttribute("data-block") === "true"
        )
            return node.getBoundingClientRect();
        node = node.parentNode;
    } while (node != null);
    return null;
}

/*!
 * function containsEntity checks if contentState contains entity with output index
 */
function containsEntity(contentState: ContentState, outputIndex: number) {
    let blocks = contentState.getBlocksAsArray();
    for (let block of blocks) {
        for (let i = 0; i < block.getLength(); ++i) {
            let entityKey = block.getEntityAt(i);
            if (entityKey != null) {
                let entity = contentState.getEntity(entityKey);
                if (entity.getType() === "FORMULA") {
                    if (entity.getData().outputIndex === outputIndex)
                        return true;
                }
            }
        }
    }
    return false;
}

interface CustomBlockProps {
    editorState: EditorState;
    ordered?: boolean;
    "data-offset-key"?: string;
}
class CustomBlockList extends Component<CustomBlockProps> {
    render() {
        let contentState = this.props.editorState.getCurrentContent();
        let blocks = contentState.getBlocksAsArray();
        const childrenWithExtraProp = React.Children.map(
            this.props.children,
            (child, idx) => {
                let key = (child! as any).key as string;
                let style: React.CSSProperties = {};
                let block = blocks.find((block) => block.getKey() === key);
                if (block != null) {
                    block.getInlineStyleAt(0).forEach((styleKey) => {
                        if (styleKey != null && styleKey.startsWith("color-")) {
                            style.color = styleKey.replace("color-", "");
                        }
                        if (
                            styleKey != null &&
                            styleKey.startsWith("fontsize-")
                        ) {
                            style.fontSize = `${styleKey.replace(
                                "fontsize-",
                                ""
                            )}px`;
                        }
                    });
                    if (idx > 0) {
                        // Take font-size of the previous element because when you press ENTER the new bullet or
                        // list doesn't have font-size
                        const fontSizeClassName = (this.props.children as any)[
                            idx - 1
                        ].props.className
                            .split(" ")[0]
                            .split("-");
                        let fontSize =
                            fontSizeClassName[fontSizeClassName.length - 1];
                        if (
                            !(child as any).props.className.includes("fontsize")
                        ) {
                            style.fontSize = `${fontSize}px`;
                        }
                    }
                }
                return React.cloneElement(child as React.ReactElement, {
                    style: style,
                });
            }
        );
        let lastNumber = String(childrenWithExtraProp?.length ?? 0);
        let digits = lastNumber.length;
        return this.props.ordered ? (
            <ol
                className="public-DraftStyleDefault-ol"
                style={{ marginLeft: `${1 + digits * 0.5}em` }}
                data-offset-key={this.props["data-offset-key"]}
            >
                {childrenWithExtraProp}
            </ol>
        ) : (
            <ul
                className="public-DraftStyleDefault-ul"
                data-offset-key={this.props["data-offset-key"]}
            >
                {childrenWithExtraProp}
            </ul>
        );
    }
}

class CheckListOption extends Component<CheckListOptionProps> {
    constructor(props: CheckListOptionProps) {
        super(props);
        this.addCheckList = this.addCheckList.bind(this);
    }

    private addCheckList(): void {
        const { editorState, onChange } = this.props;
        let selection = editorState.getSelection();
        //    if (selection.isCollapsed()) return;
        let contentState = editorState.getCurrentContent();
        let blocks = getSelectedBlocks(
            contentState,
            selection.getAnchorKey(),
            selection.getFocusKey(),
            selection.getAnchorOffset(),
            selection.getFocusOffset(),
            selection.getIsBackward()
        );

        let blockType = CHECKABLE_LIST_ITEM;
        for (let block of blocks) {
            if (block.getType() === CHECKABLE_LIST_ITEM) {
                blockType = "unstyled";
                break;
            }
        }
        let updateSelection = new SelectionState({
            anchorKey: blocks[0].getKey(),
            anchorOffset: 0,
            focusKey: blocks[blocks.length - 1].getKey(),
            focusOffset: blocks[blocks.length - 1].getLength(),
        });
        contentState = Modifier.setBlockType(
            contentState,
            updateSelection,
            blockType
        );
        let map = Immutable.Map({
            checked: false,
        });
        contentState = Modifier.setBlockData(contentState, selection, map);

        let newEditorState = EditorState.push(
            editorState,
            contentState,
            "change-block-data"
        );
        newEditorState = EditorState.forceSelection(
            newEditorState,
            updateSelection
        );

        onChange(newEditorState);
    }

    render() {
        return (
            <div
                style={{
                    width: 35,
                    height: 35,
                    marginBottom: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                title="Checklist"
            >
                <div
                    style={{ width: 27, height: 20 }}
                    title="Checklist"
                    className="rdw-option-wrapper"
                    onClick={this.addCheckList}
                >
                    <div>&#10003;</div>
                </div>
            </div>
        );
    }
}

interface Props extends TagProps {
    tag: CanvasTextBox;
    index: number;
    canvasViewMode: CanvasViewMode;
}

interface FormulaOptions {
    outputIndex: number;
    left: number;
    entityState?: SelectionState;
    top: number;
}

interface InnerState {
    dropdownOpened: boolean;
    showPresetsPopup: boolean;
    linkPopupOpened: boolean;
    showFormulaView: FormulaOptions | undefined;
    drag: boolean;
    focused: boolean;
    editorState: EditorState;
    lastEditIndex: number | undefined;
    editingAllowed: boolean;
    tag: CanvasTextBox;
    lastChangeType?: string;
    resizing?: {
        old: { position: NodePosition; size: NodeSize };
        new: { position: NodePosition; size: NodeSize };
    };
}

const styleMap = {
    FORMULA: {
        backgroundColor: "#39F",
    },
};

/*!
 * function findFormulas is used for formula entity decorator;
 */
function findFormulas(
    contentBlock: ContentBlock,
    callback: any,
    contentState: ContentState
) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "FORMULA"
        );
    }, callback);
}

interface FormulaProps {
    contentState: ContentState;
    entityKey: string;
    tag: CanvasTextBox;
    live: boolean;
    canvasTreeStore: CanvasTreeStore;
    onClick: (outputIndex: number, left: number, top: number) => void;
}

/*!
 * function Formula is a component that decorates pieces of text which correspond formula entities
 */
const Formula: React.FunctionComponent<FormulaProps> = observer((props) => {
    let tag = props.tag;
    let entity = props.contentState.getEntity(props.entityKey);
    let outputIndex = entity.getData().outputIndex;
    let highlighted =
        FormulaInformationStore.highlightItems &&
        FormulaInformationStore.currentItem?.type === InputType.Regular &&
        FormulaInformationStore.currentItem?.value === tag.id &&
        (FormulaInformationStore.currentItem?.outputIndex == null ||
            FormulaInformationStore.currentItem?.outputIndex === outputIndex);
    let style: React.CSSProperties = {
        border: "none",
        outline: "none",
    };
    if (highlighted) {
        style.backgroundColor = "#1F8EFA33";
        style.color = "#1F8EFA";
    }
    let alreadySelected =
        FormulaInformationStore.highlightItems &&
        FormulaInformationStore?.editOutput?.childrenIds?.includes(tag.id);
    if (alreadySelected) {
        style.color = "#1F8EFA";
    }
    let editing =
        tag.id === FormulaInformationStore.editItemId &&
        outputIndex === FormulaInformationStore.outputIndex;

    let textBoxOutput = getCanvasTextBoxOutput(tag, outputIndex);
    let [animateOptions, setAnimateOptions] = React.useState({
        current: 0,
        animate: false,
    });
    React.useEffect(() => {
        if (StringUtils.isNumber(textBoxOutput) && props.live) {
            let absValue = Number(textBoxOutput);
            let delta =
                Math.max(Math.round(Math.abs(absValue) / 20), 1) *
                (absValue > 0 ? 1 : -1);
            setAnimateOptions({
                current: 0,
                animate: true,
            });
            const interval = setInterval(() => {
                setAnimateOptions((animateOptions) => {
                    if (
                        Math.abs(animateOptions.current + delta) >
                        Math.abs(absValue)
                    ) {
                        clearInterval(interval);
                        return {
                            current: 0,
                            animate: false,
                        };
                    }
                    return {
                        ...animateOptions,
                        current: animateOptions.current + delta,
                        animate: true,
                    };
                });
            }, 100);
            return () => clearInterval(interval);
        }
        // Show animation only initially
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Check if this formula is formatting now
    let formatting =
        FormulaInformationStore.isFormatting &&
        tag.id === FormulaInformationStore.editItemId &&
        outputIndex === FormulaInformationStore.outputIndex;
    if (editing) {
        style.backgroundColor = "#1F8EFA33";
    }
    if (formatting) {
        style.fontWeight = 700;
    }
    if (!highlighted && !alreadySelected) {
        let statusFont = getStatusColor(tag, StatusColorType.Text, outputIndex);
        if (statusFont.color !== "transparent") {
            style.color = statusFont.color;
        }
    }
    let childrenWithExtraProp: any;
    if (!FormulaInformationStore.highlightItems) {
        childrenWithExtraProp = React.Children.map(props.children, (child) =>
            React.cloneElement(child as React.ReactElement, {
                customStyleFn: () => {
                    return style;
                },
                text: animateOptions.animate
                    ? getAnimateNumberFormatOutput(
                          tag,
                          animateOptions.current,
                          outputIndex
                      )
                    : (child as any).props.text,
            })
        );
    }
    return (
        <span
            style={style}
            className="rdw-link-decorator-wrapper"
            onDoubleClick={(evt) => {
                evt.stopPropagation();
                props.onClick(outputIndex, evt.clientX, evt.clientY);
            }}
        >
            {formatting && (
                <span
                    style={{
                        position: "absolute",
                        top: -5,
                        left: 0,
                        fontSize: 8,
                        color: "#39F",
                        fontWeight: 400,
                        fontFamily: "Roboto",
                    }}
                >
                    {`output_${outputIndex}`}
                </span>
            )}
            {FormulaInformationStore.highlightItems
                ? props.children
                : childrenWithExtraProp}
        </span>
    );
});

@observer
class Tag extends CanvasInteractionComponent<Props, InnerState> {
    keepChanges: boolean = false;
    tagAreaRef: any = null;
    updateTagLock: AsyncLock;
    lastEditorState: EditorState;
    parentTagAreaRef: any = null;
    elementAreaRef = React.createRef<HTMLDivElement>();
    menuAreaRef = React.createRef<HTMLDivElement>();
    constructor(props: Props) {
        super(props);
        let editorState = this.initEditorState();
        this.state = {
            lastEditIndex: undefined,
            showFormulaView: undefined,
            dropdownOpened: false,
            drag: false,
            focused: false,
            editingAllowed: false,
            linkPopupOpened: false,
            tag: _.cloneDeep(this.props.tag),
            editorState: editorState,
            resizing: undefined,
            showPresetsPopup: false,
        };
        this.lastEditorState = editorState;
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onEditorBlur = this.onEditorBlur.bind(this);
        this.onEditorFocus = this.onEditorFocus.bind(this);
        this.onFormulaClick = this.onFormulaClick.bind(this);
        this.blockStyleFn = this.blockStyleFn.bind(this);
        this.updateTagLock = new AsyncLock({ timeout: 3000 });
        this.onLinkPopupOpen = this.onLinkPopupOpen.bind(this);
        this.handlePastedText = this.handlePastedText.bind(this);
        this.renderContents = this.renderContents.bind(this);
        this.publishToPresets = this.publishToPresets.bind(this);
    }

    private blockStyleFn(block: ContentBlock): string | null {
        let style = defaultBlockStyleFn(block);
        if (style == null) style = "";
        switch (block.getType()) {
            case "unordered-list-item":
            case "ordered-list-item":
                let classNames: string[] = [];
                block.getInlineStyleAt(0).forEach((styleKey) => {
                    if (styleKey != null && styleKey.startsWith("fontsize-")) {
                        classNames.push(`block-style-${styleKey}`);
                    }
                });
                if (style) return [style, classNames.join("")].join(" ");
                else return classNames.join("");
            default:
                return style;
        }
    }
    private publishToPresets() {
        this.setState({ showPresetsPopup: true });
    }
    private onLinkPopupOpen(opened: boolean) {
        this.setState({ linkPopupOpened: opened });
    }
    private initEditorState(): EditorState {
        let editorState: EditorState | undefined = undefined;
        if (this.props.tag.rawMetric == null) {
            let contentState: ContentState;
            if (this.props.tag.raw != null) {
                contentState = convertFromRaw(this.props.tag.raw);
            } else if (this.props.tag.html != null) {
                const blocksFromHtml = convertFromHTML(this.props.tag.html);
                const { contentBlocks, entityMap } = blocksFromHtml;
                contentState = ContentState.createFromBlockArray(
                    contentBlocks,
                    entityMap
                );
            } else {
                contentState = ContentState.createFromText(this.props.tag.text);
            }
            editorState = EditorState.createWithContent(contentState);
        } else {
            editorState = EditorState.createWithContent(
                convertFromRaw(this.props.tag.rawMetric)
            );
        }
        return editorState;
    }

    private onClickEditorClickContainer(data: {
        tag: CanvasTextBox;
        canWrite: boolean;
        edited: boolean;
        element: string;
    }) {
        const { tag, canWrite, edited, element } = data;
        if (!this.state.drag) {
            if ((!this.props.live || tag.unlocked) && canWrite && !edited) {
                this.trackNewPerformance(element);
                this.props.onStartEditTag(this.state.tag.id);
                return;
            }

            if (edited && this.state.editingAllowed) {
                this.forceKeepChanges(() => {
                    this.tagAreaRef?.editor.blur();
                    this.parentTagAreaRef?.focus({
                        preventScroll: true,
                    });
                });
            }
        } else this.setState({ drag: false });
    }

    private onClickEditorFocusContainer(data: { edited: boolean; evt: any }) {
        const { edited, evt } = data;
        if (!edited) {
            return;
        }
        evt.stopPropagation();
        if (edited && !this.state.editingAllowed) {
            this.startEditText();
        }
    }
    public componentDidMount(): void {
        this.calculateNewHeight(true);
        if (
            this.props.tag.defaultValueType != null &&
            this.props.tag.defaultValueType !== DefaultValueType.LastTextAdded
        ) {
            let contentState = ContentState.createFromText(
                (this.props.tag.defaultValueType ===
                DefaultValueType.ClearEverySession
                    ? ""
                    : this.props.tag.defaultText) ?? ""
            );
            this.props.canvasTreeStore.updateNodeAction<
                CanvasTextBox,
                "value" | "metric" | "additionalOutputs" | "rawMetric"
            >(this.props.tag.id, {
                value: NaN,
                metric: "",
                additionalOutputs: [],
                rawMetric: convertToRaw(contentState),
            });
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        let edited = this.props.editedTagNodeId === this.props.tag.id;
        let prevEdited = prevProps.editedTagNodeId === this.props.tag.id;
        this.setState<never>((state) => {
            if (
                !_.isEqual(prevProps.tag, this.props.tag) &&
                !_.isEqual(state.tag, this.props.tag)
            ) {
                if (
                    !_.isEqual(state.tag.rawMetric, this.props.tag.rawMetric) &&
                    (sessionId() !== this.props.tag.lastTextEditSessionId ||
                        !state.editingAllowed)
                ) {
                    let newRawMetric:
                        | RawDraftContentState
                        | undefined = undefined;
                    if (this.props.tag.lastRawMetricDelta != null) {
                        // Under some conditions state.tag.rawMetric?.blocks
                        // can be undefined here, but those conditions are
                        // currently unknown.
                        // https://eisengardai.atlassian.net/browse/EIS-264
                        // Note: jsonDiffPatchBlocks.patch does not support
                        // arrays but somehow simultaneous editing still
                        // works. Possibly need to look into this later.
                        newRawMetric = {
                            blocks:
                                this.props.tag.lastRawMetricDelta.blocks != null
                                    ? jsonDiffPatchBlocks.patch(
                                          state.tag.rawMetric?.blocks ?? [],
                                          this.props.tag.lastRawMetricDelta
                                              .blocks
                                      )
                                    : state.tag.rawMetric?.blocks ?? [],
                            entityMap:
                                this.props.tag.lastRawMetricDelta.entityMap !=
                                null
                                    ? jsonDiffPatchEntityMap.patch(
                                          state.tag.rawMetric?.entityMap ?? {},
                                          this.props.tag.lastRawMetricDelta
                                              .entityMap
                                      )
                                    : state.tag.rawMetric?.entityMap ?? {},
                        };
                        newRawMetric = generateRawMetric(
                            this.props.tag,
                            newRawMetric
                        )!;
                    }
                    let nextContentState =
                        newRawMetric != null
                            ? convertFromRaw(newRawMetric)
                            : this.props.tag.rawMetric != null
                            ? convertFromRaw(this.props.tag.rawMetric)
                            : null;
                    let editorState =
                        nextContentState != null
                            ? EditorState.createWithContent(nextContentState)
                            : EditorState.createEmpty();

                    let currentSelection = state.editorState.getSelection();
                    if (state.lastEditIndex != null) {
                        let entities = getFormulaTextEntities(
                            editorState.getCurrentContent()
                        );
                        let entity = entities.find(
                            (entity) =>
                                entity.outputIndex === state.lastEditIndex
                        );
                        if (entity != null) {
                            currentSelection = new SelectionState({
                                anchorKey: entity.blockKey,
                                focusKey: entity.blockKey,
                                anchorOffset: entity.end,
                                focusOffset: entity.end,
                            });
                        }
                    } else {
                        let anchorBlockKey = currentSelection.getIsBackward()
                            ? currentSelection.getFocusKey()
                            : currentSelection.getAnchorKey();
                        let sourceContentState = state.editorState.getCurrentContent();
                        let newContentState = editorState.getCurrentContent();
                        let sourceAnchorBlock = sourceContentState.getBlockForKey(
                            anchorBlockKey
                        );
                        let newAnchorBlock = newContentState.getBlockForKey(
                            anchorBlockKey
                        );
                        //   let focusOffset = 0;
                        let anchorOffset = 0;
                        if (
                            sourceAnchorBlock != null &&
                            newAnchorBlock != null
                        ) {
                            anchorOffset =
                                sourceAnchorBlock.getLength() -
                                newAnchorBlock.getLength();
                        }
                        // if (anchorBlockKey !== focusBlockKey) {
                        //     let sourceFocusBlock = sourceContentState.getBlockForKey(focusBlockKey);
                        //     let newFocusBlock = newContentState.getBlockForKey(focusBlockKey);
                        //     if (sourceAnchorBlock != null && sourceFocusBlock != null) {
                        //         focusOffset = sourceFocusBlock.getLength() - newFocusBlock.getLength();
                        //     }
                        // }
                        currentSelection = currentSelection.merge({
                            focusOffset:
                                currentSelection.getFocusOffset() -
                                anchorOffset,
                            anchorOffset:
                                currentSelection.getAnchorOffset() -
                                anchorOffset,
                        });
                    }
                    let text = editorState
                        .getCurrentContent()
                        .getPlainText("\u0001");
                    if (edited && text && !state.showFormulaView) {
                        editorState = EditorState.forceSelection(
                            editorState,
                            currentSelection
                        );
                    }
                    return {
                        lastEditIndex: undefined,
                        tag: _.cloneDeep(this.props.tag),
                        editorState: editorState,
                    };
                } else {
                    return {
                        lastEditIndex: undefined,
                        tag: _.cloneDeep(this.props.tag),
                    };
                }
            }
            return null;
        });

        if (
            prevProps.scale !== this.props.scale ||
            prevProps.canvasViewMode !== this.props.canvasViewMode
        ) {
            this.calculateNewHeight(true);
        }

        if (edited === prevEdited) return;
        if (edited) {
            if (this.parentTagAreaRef != null) {
                setTimeout(() => {
                    this.parentTagAreaRef?.focus({ preventScroll: true });
                }, 0);
            }
        }
        if (!edited) {
            let contentState = this.state.editorState.getCurrentContent();
            if (
                !this.state.focused &&
                _.isEqual(convertToRaw(contentState), this.props.tag.rawMetric)
            )
                this.keepChanges = false;
            setTimeout(() => {
                this.forceKeepChanges(() => {
                    this.parentTagAreaRef?.blur();
                    this.tagAreaRef?.editor.blur();
                });
            }, 0);
        }
    }

    private startEditText() {
        this.keepChanges = true;
        this.props.onAllowTextBoxEdit(true);

        this.setState(
            (_state) => {
                let newEditorState = EditorState.forceSelection(
                    _state.editorState,
                    _state.editorState.getSelection()
                );
                return {
                    editorState: newEditorState,
                    editingAllowed: true,
                };
            },
            () => {
                this.tagAreaRef?.focusEditor({
                    preventScroll: true,
                });
            }
        );
    }

    private forceKeepChanges(stateCallback: () => void) {
        if (this.keepChanges) {
            //    raw = generateRawValue(this.state.tag, rawContentState);
            this.props.canvasTreeStore.updateNodeAction<
                CanvasTextBox,
                keyof CanvasTextBox
            >(
                this.state.tag.id,
                {
                    ...this.state.tag,
                    lastTextEditSessionId: sessionId(),
                    //       raw: generateRawValue(this.state.tag, rawContentState),
                },
                true,
                true
            );
            this.keepChanges = false;
        }
        this.props.onAllowTextBoxEdit(false);
        this.setState(
            {
                editingAllowed: false,
            },
            stateCallback
        );
    }
    private addOutput(state: InnerState): ContentState {
        let contentState = state.editorState.getCurrentContent();
        let entityKey = contentState
            .createEntity("FORMULA", "IMMUTABLE", {
                outputIndex: this.state.showFormulaView!.outputIndex,
            })
            .getLastCreatedEntityKey();

        contentState = Modifier.applyEntity(
            contentState,
            state.showFormulaView!.entityState!,
            entityKey
        );

        contentState = Modifier.replaceText(
            contentState,
            state.showFormulaView!.entityState!,
            " ",
            state.editorState.getCurrentInlineStyle(),
            entityKey
        );
        contentState = Modifier.removeInlineStyle(
            contentState,
            state.showFormulaView!.entityState!,
            "FORMULA"
        );
        return contentState;
    }

    calculateNewHeight(timeoutResize?: boolean) {
        let tagNodeSize = this.state.tag.nodeSize ?? {
            desktop: { width: tagDefaultWidth, height: tagDefaultHeight },
            mobile: { width: tagDefaultWidth, height: tagDefaultHeight },
        };

        let currentHeight = Math.round(
            tagNodeSize[this.props.canvasTreeStore.canvasViewMode].height
        );
        if (this.tagAreaRef != null) {
            let height = Math.max(
                Math.round(this.tagAreaRef.wrapper.offsetHeight + 10),
                tagDefaultHeight
            );
            if (height !== currentHeight) {
                const canvasViewMode = this.props.canvasTreeStore
                    .canvasViewMode;
                let changes = {
                    nodeSize: {
                        ...tagNodeSize,
                        [canvasViewMode]: {
                            ...tagNodeSize[canvasViewMode],
                            height: height,
                        },
                    },
                };
                this.setState(
                    (state) => ({
                        tag: {
                            ...state.tag,
                            ...changes,
                        },
                    }),
                    () => {
                        this.props.canvasTreeStore.updateCanvasSizeAction({
                            x: this.state.tag.nodePosition[canvasViewMode].x,
                            y: this.state.tag.nodePosition[canvasViewMode].y,
                            width: tagNodeSize[canvasViewMode].width,
                            height: height,
                        });
                        if (timeoutResize) {
                            setTimeout(() => {
                                this.props.onResize();
                            }, 0);
                        } else {
                            this.props.onResize();
                        }
                    }
                );
            }
        }
        return null;
    }

    private onEditorStateChange(
        editorState: EditorState,
        TriggeredStyleChange: string = ""
    ): void {
        this.setState<never>(
            (state) => {
                let showFormulaView: FormulaOptions | undefined =
                    state.showFormulaView;
                let contentState = editorState.getCurrentContent();
                let blocks = contentState.getBlocksAsArray();
                let selectionState = editorState.getSelection();
                let newOutputIndex: number = 1;
                let currentEntities = getFormulaTextEntities(contentState);
                let lastChangeType = editorState.getLastChangeType();
                let focusBlock = selectionState.getFocusKey();
                let blockText = blocks
                    .find((block) => block.getKey() === focusBlock)!
                    .getText();
                // we find index of new possible formula output
                for (let currentEntity of currentEntities) {
                    newOutputIndex = Math.max(
                        newOutputIndex,
                        currentEntity.outputIndex + 1
                    );
                }
                if (
                    selectionState.getAnchorKey() === blocks[0].getKey() &&
                    (editorState.getLastChangeType() ===
                        "backspace-character" ||
                        editorState.getLastChangeType() ===
                            "delete-character" ||
                        editorState.getLastChangeType() === "remove-range")
                ) {
                    let newBlocks = blocks.filter(
                        (block) =>
                            block.getType() !== CHECKABLE_LIST_ITEM ||
                            block.getText().length > 0
                    );
                    if (
                        newBlocks.length === 0 ||
                        newBlocks.length < blocks.length
                    ) {
                        contentState = ContentState.createFromBlockArray(
                            newBlocks
                        );
                        editorState = EditorState.createWithContent(
                            contentState
                        );
                    }
                }
                // handle formulas
                if (
                    editorState.getLastChangeType() === "insert-characters" &&
                    selectionState.isCollapsed()
                ) {
                    let symbolFocusOffset = selectionState.getFocusOffset();
                    let symbol = blockText[symbolFocusOffset - 1];
                    if (symbol === "=") {
                        // we select text with "=" symbol and apply background to it
                        let entityState: SelectionState = new SelectionState({
                            anchorKey: focusBlock,
                            focusKey: focusBlock,
                            anchorOffset: symbolFocusOffset - 1,
                            focusOffset: symbolFocusOffset,
                        });
                        contentState = Modifier.applyInlineStyle(
                            contentState,
                            entityState,
                            "FORMULA"
                        );
                        editorState = EditorState.push(
                            editorState,
                            contentState,
                            "change-inline-style"
                        );
                        editorState = EditorState.forceSelection(
                            editorState,
                            selectionState
                        );
                        let visibleSelectionRect = getVisibleSelectionRect(
                            window
                        );
                        if (visibleSelectionRect === null)
                            visibleSelectionRect = getSelectedBlockElement();
                        if (visibleSelectionRect != null)
                            // we save left-top position of selection to show formula popup
                            showFormulaView = {
                                entityState: entityState,
                                left: visibleSelectionRect.left,
                                top: visibleSelectionRect.bottom,
                                outputIndex: newOutputIndex,
                            };
                    }
                }
                if (
                    editorState.getLastChangeType() === "split-block" ||
                    editorState.getLastChangeType() === "change-block-type"
                ) {
                    let selectionState = editorState.getSelection();
                    if (selectionState.isCollapsed()) {
                        for (let i = 0; i < blocks.length; ++i) {
                            let block = blocks[i];
                            let prevBlock = blocks[i - 1];
                            if (
                                block.getKey() ===
                                    selectionState.getAnchorKey() &&
                                block.getText().length === 0 &&
                                prevBlock?.getType() === CHECKABLE_LIST_ITEM
                            ) {
                                contentState = Modifier.setBlockType(
                                    contentState,
                                    selectionState,
                                    CHECKABLE_LIST_ITEM
                                );
                                editorState = EditorState.push(
                                    editorState,
                                    contentState,
                                    "change-block-type"
                                );
                                let map = Immutable.Map({
                                    checked: false,
                                });
                                contentState = Modifier.setBlockData(
                                    contentState,
                                    selectionState,
                                    map
                                );
                                editorState = EditorState.push(
                                    editorState,
                                    contentState,
                                    "change-block-data"
                                );
                                break;
                            }
                        }
                    }
                }
                let tagUpdate: Partial<CanvasTextBox> = {};
                //    let hasChanges: boolean = false;
                let indicesToRemove: number[] = [];
                let offset = 0;
                // checking which entities were removed, removing corresponding items and shifting indices of other outputs
                // outputIndex == 1 corresponds to default output, outputIndex == 2 corresponds to first additional output etc.
                if (
                    currentEntities.find((item) => item.outputIndex === 1) ==
                    null
                ) {
                    //means that default output was removed
                    //If no other outputs we fill tagUpdate by default values
                    if (currentEntities.length === 0) {
                        tagUpdate.metric = "";
                        tagUpdate.childrenIds = [];
                        tagUpdate.globalInputIds = [];
                        tagUpdate.childrenSharedIds = [];
                        //    hasChanges = true;
                        tagUpdate.value = NaN;
                    } else {
                        // else we shift first additional output to default and remove it from additional outputs
                        let minOutputIndex = Math.min(
                            ...currentEntities.map(
                                (entity) => entity.outputIndex
                            )
                        );
                        tagUpdate = {
                            ...tagUpdate,
                            ...state.tag.additionalOutputs[minOutputIndex - 2],
                        };
                        indicesToRemove.push(minOutputIndex);
                        offset += 1;
                        //    hasChanges = true;
                    }
                }
                let offsetMap: { [key: number]: number } = {};
                // We check if output entities corresponding to additional outputs were removed,
                // remove this additional outputs and
                // shift other additional outputs
                for (
                    let i = 0;
                    i < (state.tag?.additionalOutputs ?? []).length;
                    ++i
                ) {
                    if (
                        currentEntities.find(
                            (item) => item.outputIndex === i + 2
                        ) == null
                    ) {
                        indicesToRemove.push(i);
                        offset += 1;
                    } else {
                        offsetMap[i + 2] = offset;
                    }
                }
                if (indicesToRemove.length > 0) {
                    //    hasChanges = true;
                    tagUpdate.additionalOutputs = this.state.tag.additionalOutputs!.filter(
                        (item, index) => !indicesToRemove.includes(index)
                    );
                    for (let currentEntity of currentEntities) {
                        if (offsetMap[currentEntity.outputIndex] != null) {
                            contentState = contentState.mergeEntityData(
                                currentEntity.key,
                                {
                                    outputIndex:
                                        currentEntity.outputIndex -
                                        offsetMap[currentEntity.outputIndex],
                                }
                            );
                        }
                    }
                    editorState = EditorState.push(
                        editorState,
                        contentState,
                        "apply-entity"
                    );
                }

                // There is a bug with textbox created from theme panel where some styles didn't get changed because of style duplicates
                // (like : fontsize-25, fontsize-26)
                // So we are looking for duplicated styles and remove them
                const currentInlineStyles: string[] = editorState
                    .getCurrentInlineStyle()
                    .toList()
                    .toJS();
                const uniqueInlineStyles: string[] = Array.from(
                    new Set(
                        currentInlineStyles.map((style) => style.split("-")[0])
                    )
                );
                uniqueInlineStyles.forEach((uniqueStyle) => {
                    const filtered = currentInlineStyles.filter((style) =>
                        style.includes(uniqueStyle)
                    );
                    if (filtered.length > 1) {
                        const index = currentInlineStyles.indexOf(filtered[0]);
                        editorState = removeInlineStyles(editorState, [
                            currentInlineStyles[index],
                        ]);
                    }
                });
                // --------------------------------------------------------------------------------------------------------------------------------------------

                if (blockText.length === 0) {
                    editorState = keepInlineStyles(
                        editorState,
                        this.lastEditorState
                    );
                } else {
                    const textStyles = [
                        "FONTFAMILY",
                        "FONTSIZE",
                        "LINEHEIGHT",
                        "LETTERSPACING",
                        "COLOR",
                    ];

                    if (textStyles.includes(TriggeredStyleChange)) {
                        this.lastEditorState = editorState;
                    }
                }

                if (TriggeredStyleChange === "LINEHEIGHT") {
                    setLineHeightPropToTextParent(editorState);
                }

                let rawMetric = convertToRaw(contentState);
                tagUpdate.rawMetric = rawMetric;

                let tag = { ...state.tag, ...tagUpdate };
                return {
                    lastChangeType: lastChangeType,
                    tag: tag,
                    showFormulaView: showFormulaView,
                    editorState: editorState,
                };
            },
            () => {
                if (
                    this.state.lastChangeType != null &&
                    this.state.focused === true
                )
                    this.updateTag();
                setTimeout(() => {
                    this.calculateNewHeight();
                }, 0);
            }
        );
    }
    private checkClickedCheckBox(checked: boolean, key: string = "") {
        const { onEditorStateChange } = this;
        const { editorState } = this.state;
        let contentState = editorState.getCurrentContent();
        let blocks = contentState.getBlocksAsArray();

        blocks.forEach((block) => {
            if (key.includes(block.getKey())) {
                let clickedBlock: ContentBlock | undefined = block;
                let updateSelection = new SelectionState({
                    anchorKey: clickedBlock.getKey(),
                    anchorOffset: 0,
                    focusKey: clickedBlock.getKey(),
                    focusOffset: 0,
                });

                let map = Immutable.Map({
                    checked,
                });

                contentState = Modifier.setBlockData(
                    contentState,
                    updateSelection,
                    map
                );

                let newEditorState = EditorState.push(
                    editorState,
                    contentState,
                    "change-block-data"
                );
                onEditorStateChange(newEditorState);
            }
        });
    }
    private handlePastedText(
        text: string,
        html: string | undefined,
        editorState: EditorState
    ) {
        if (html == null) {
            if (editorState.getSelection().isCollapsed()) {
                const newContent = Modifier.insertText(
                    editorState.getCurrentContent(),
                    editorState.getSelection(),
                    text
                );

                // update our state with the new editor content
                this.onEditorStateChange(
                    EditorState.push(
                        editorState,
                        newContent,
                        "insert-characters"
                    )
                );
            } else {
                const newContent = Modifier.replaceText(
                    editorState.getCurrentContent(),
                    editorState.getSelection(),
                    text
                );

                // update our state with the new editor content
                this.onEditorStateChange(
                    EditorState.push(
                        editorState,
                        newContent,
                        "insert-characters"
                    )
                );
            }
        } else {
            const blocks = convertFromHTML(html);
            const newContentState = ContentState.createFromBlockArray(
                blocks.contentBlocks,
                blocks.entityMap
            );
            const newState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                newContentState.getBlockMap()
            );
            this.onEditorStateChange(
                EditorState.push(editorState, newState, "insert-fragment")
            );
        }
        return true;
    }

    private updateTag(): void {
        let pageId = this.props.canvasTreeStore.canvasPageId;
        let canvasId = this.props.canvasTreeStore.canvasId;
        let fn = async () => {
            try {
                if (!this.keepChanges) return;
                let exists =
                    this.props.canvasTreeStore.canvasTreeState.get(
                        this.state.tag.id
                    ) != null &&
                    this.props.canvasTreeStore.canvasPageId === pageId &&
                    this.props.canvasTreeStore.canvasId === canvasId;
                if (!exists) return;
                this.props.canvasTreeStore.updateNodeAction<
                    CanvasTextBox,
                    keyof CanvasTextBox
                >(
                    this.state.tag.id,
                    {
                        ...this.state.tag,
                        lastTextEditSessionId: sessionId(),
                    },
                    true,
                    true
                );
                await delay(2000);
            } catch (error) {}
        };
        try {
            this.updateTagLock.acquire("keepTagChanges", fn, (err, ret) => {}, {
                skipQueue: true,
            });
        } catch (error) {}
    }
    private onEditorFocus(): void {
        this.setState({
            editorState: keepInlineStyles(
                this.state.editorState,
                this.lastEditorState
            ),
        });
    }
    private onEditorBlur(): void {
        //   this.updateTag();
    }
    private onFormulaClick(
        outputIndex: number,
        left: number,
        top: number
    ): void {
        let edited = this.props.editedTagNodeId === this.state.tag.id;
        if (!edited) return;
        let showFormulaView: FormulaOptions = {
            left: left,
            top: top,
            outputIndex: outputIndex,
        };
        // Open FormulaPopup for existing formula
        this.setState({ showFormulaView: showFormulaView });
    }
    public renderContents(globalContext: GlobalContextContents): JSX.Element {
        const {
            canvasViewMode,
            mobileViewWasEdited,
            slideWidthRatio,
        } = this.props.canvasTreeStore;
        let tag = this.state.tag;
        tag = {
            ...tag,
            nodePosition:
                this.state.resizing?.new?.position || tag.nodePosition,
            nodeSize: this.state.resizing?.new?.size ||
                tag.nodeSize || {
                    desktop: {
                        width: tagDefaultWidth,
                        height: tagDefaultHeight,
                    },
                    mobile: {
                        width: tagDefaultWidth,
                        height: tagDefaultHeight,
                    },
                },
        };
        const decorators = [
            {
                strategy: findFormulas,
                component: Formula,
                props: {
                    // Clicking on formula will call onFormulaClick
                    onClick: this.onFormulaClick,
                    tag: tag,
                    live: this.props.live,
                    canvasTreeStore: this.props.canvasTreeStore,
                },
            },
        ];

        let canWrite = this.props.canWrite;
        let edited = this.props.editedTagNodeId === this.state.tag.id;
        let tagNodeSize = tag.nodeSize ?? {
            desktop: { width: tagDefaultWidth, height: tagDefaultHeight },
            mobile: { width: tagDefaultWidth, height: tagDefaultHeight },
        };
        let element = elements.textBox;
        let scaledWidth = tagNodeSize[canvasViewMode].width * this.props.scale;
        let scaledHeight =
            tagNodeSize[canvasViewMode].height * this.props.scale;
        let backgroundColor =
            !edited &&
            FormulaInformationStore.highlightItems &&
            FormulaInformationStore.currentItem?.type === InputType.Regular &&
            FormulaInformationStore.currentItem?.value === tag.id
                ? "#1F8EFA19"
                : tag.fillColor ?? "transparent";
        let statusBackground = getStatusColor(tag, StatusColorType.Fill);
        let statusBorder = getStatusColor(tag, StatusColorType.Border);
        if (statusBackground.color !== "transparent") {
            backgroundColor = statusBackground.color;
        }
        let borderWidth = this.props.scale;
        let borderStyle = "solid";
        let borderColor = "transparent";
        let borderRadius = tag.borderRadius ?? 0;
        if (!edited && FormulaInformationStore.highlightItems) {
            borderWidth = 1;
            borderColor = "#1F8EFA";
            if (FormulaInformationStore.currentItem?.value !== tag.id) {
                borderStyle = "dashed";
            }
        } else {
            borderColor = statusBorder.color;
            borderWidth = statusBorder.borderWidth * this.props.scale;

            if (statusBorder.color === "transparent" && tag.borderColor) {
                borderColor = tag.borderColor;
            }
        }
        let statusTextHashList = [];
        for (let i = 1; i < tag.additionalOutputs.length + 2; ++i) {
            statusTextHashList.push(
                getStatusColor(tag, StatusColorType.Text, i).color
            );
        }
        let statusTextHash = statusTextHashList
            .join("_")
            .concat(String(this.props.live ? 1 : 0));

        const error =
            this.props.canvasTreeStore.canvasTreeRequestErrorsState.get(
                tag.id
            ) != null ||
            this.props.canvasTreeStore.canvasTreeParseErrorsState.get(tag.id) !=
                null;

        const customBlockRenderMap = Immutable.Map({
            "unordered-list-item": {
                element: "li",
                wrapper: (
                    <CustomBlockList editorState={this.state.editorState} />
                ),
            },
            "ordered-list-item": {
                element: "li",
                wrapper: (
                    <CustomBlockList
                        ordered
                        editorState={this.state.editorState}
                    />
                ),
            },
        });
        let rootDataTestId = `textBox-${this.props.index}`;
        const { canvasTreeStore } = this.props;

        const selected =
            this.props.selectedMetadata?.length === 1 &&
            this.props.selectedMetadata?.[0]?.type === "canvasTreeState" &&
            this.props.selectedMetadata?.[0]?.id === this.props.tag.id;

        let component = (
            <>
                <div
                    data-test-id={`${rootDataTestId}-menucontainer`}
                    ref={this.menuAreaRef}
                    style={{
                        zIndex: tag.zIndex ?? 50,
                        position: "absolute",
                        left:
                            tag.nodePosition[canvasTreeStore.canvasViewMode].x *
                            this.props.scale,
                        top:
                            tag.nodePosition[canvasTreeStore.canvasViewMode].y *
                            this.props.scale,
                        width:
                            scaledWidth +
                            15 * this.props.scale +
                            borderWidth * 2,
                        height: scaledHeight + borderWidth * 2,
                    }}
                    onClick={() => {
                        this.setState({ focused: true });
                    }}
                    onTouchStart={() => {
                        openDelayedNodeMenuAfterTouch(
                            () => {
                                this.setState({ focused: true });
                            },
                            () => {
                                this.setState({ focused: false });
                            }
                        );
                    }}
                >
                    {!this.state.drag &&
                        (this.state.focused ||
                            this.state.dropdownOpened ||
                            // The hamburger menu has to appear as soon as the node gets created
                            selected) &&
                        !this.props.live &&
                        this.props.canWrite && (
                            <Portal rootNode={this.props.htmlElementsRootRef}>
                                <div data-test-id={`${rootDataTestId}-menu`}>
                                    <DropdownNodeMenu
                                        canPublishToPresets={
                                            "CreateMediaCollections" in
                                            globalContext.permissions
                                        }
                                        onPublishToPresets={
                                            this.publishToPresets
                                        }
                                        rootMenuTestId={`${rootDataTestId}-menu`}
                                        customStyle={{
                                            zIndex: 999,
                                            position: "absolute",
                                            top:
                                                tag.nodePosition[
                                                    canvasTreeStore
                                                        .canvasViewMode
                                                ].y * this.props.scale,
                                            left:
                                                tag.nodePosition[
                                                    canvasTreeStore
                                                        .canvasViewMode
                                                ].x *
                                                    this.props.scale +
                                                scaledWidth,
                                            right: 0,
                                            width: 15 * this.props.scale,
                                            height: 20 * this.props.scale,
                                            backgroundColor: "transparent",
                                        }}
                                        onToggle={(show) => {
                                            this.setState({
                                                dropdownOpened: show,
                                            });
                                        }}
                                        element={element}
                                        canWrite={this.props.canWrite}
                                        canEdit={this.props.canWrite}
                                        canLock={true}
                                        canDelete={this.props.canWrite}
                                        onStartAdvancedEditing={(node) => {
                                            this.setState({
                                                focused: false,
                                            });
                                            this.props.onStartAdvancedEditing(
                                                node
                                            );
                                        }}
                                        sharedPolicy={this.props.sharedPolicy}
                                        onClearEditing={
                                            this.props.onClearEditing
                                        }
                                        moduleTitle={this.props.moduleTitle}
                                        currentModuleId={
                                            this.props.currentModuleId
                                        }
                                        canvasTreeStore={
                                            this.props.canvasTreeStore
                                        }
                                        scale={this.props.scale}
                                        node={tag}
                                        showDeleteElementWithDatasetPopup={
                                            this.props
                                                .showDeleteElementWithDatasetPopup
                                        }
                                    />
                                </div>
                            </Portal>
                        )}
                </div>
                <DraggableWithSnapping
                    key={this.state.tag.id}
                    onMouseDown={(e) => {
                        this.setState({
                            drag: false,
                        });
                    }}
                    disabled={
                        (this.props.live && !tag.unlocked) ||
                        !this.props.canWrite ||
                        this.state.editingAllowed ||
                        mobileBreakpoint()
                    }
                    position={{
                        x:
                            tag.nodePosition[canvasTreeStore.canvasViewMode].x *
                            this.props.scale,
                        y:
                            tag.nodePosition[canvasTreeStore.canvasViewMode].y *
                            this.props.scale,
                    }}
                    onDrag={throttle((evt, data) => {
                        if (!this.state.drag) this.setState({ drag: true });
                        let nearestPoints = this.props.onRebuildSnapLine(
                            {
                                x: data.x,
                                y: data.y,
                                width: scaledWidth,
                                height: scaledHeight,
                            },
                            {
                                type: "canvasTreeState",
                                id: tag.id,
                                groupId: tag.groupId,
                            }
                        );
                        this.props.onUpdateSelectionBounds?.();
                        let newPosition = snapElementToPoints(
                            scaledWidth,
                            scaledHeight,
                            nearestPoints
                        );
                        if (newPosition.x != null || newPosition.y != null) {
                            // Snap to this position
                            return newPosition;
                        }
                    }, 10)}
                    onStop={(_evt, data) => {
                        if (this.state.drag) {
                            this.props.onDeleteSnapLine();
                            this.trackNewPerformance(element);
                            let x = Math.max(0, data.x / this.props.scale);
                            let y = Math.max(0, data.y / this.props.scale);
                            let deltaX =
                                x -
                                this.state.tag.nodePosition[
                                    canvasTreeStore.canvasViewMode
                                ].x;
                            let deltaY =
                                y -
                                this.state.tag.nodePosition[
                                    canvasTreeStore.canvasViewMode
                                ].y;

                            let tagChanges = {
                                nodePosition: {
                                    ...this.state.tag.nodePosition,
                                    [canvasTreeStore.canvasViewMode]: {
                                        x,
                                        y,
                                    },
                                },
                            };

                            if (!mobileViewWasEdited) {
                                if (canvasViewMode === "desktop")
                                    tagChanges.nodePosition["mobile"] = {
                                        x: x * slideWidthRatio,
                                        y,
                                    };
                                else
                                    this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                            }

                            let changes: InnerCanvasChanges = {};
                            this.props.canvasTreeStore.updateNodeAction(
                                this.state.tag.id,
                                tagChanges,
                                true,
                                false,
                                false,
                                changes
                            );
                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                x: x,
                                y: y,
                                width: scaledWidth,
                                height: scaledHeight,
                            });
                            this.props.onMoveGroupSelection(
                                deltaX,
                                deltaY,
                                {
                                    id: this.state.tag.id,
                                    type: "canvasTreeState",
                                    groupId: this.state.tag.groupId,
                                },
                                false,
                                changes
                            );
                            this.props.canvasTreeStore.saveChangesAction(
                                changes,
                                true,
                                true,
                                false,
                                this.props.canvasTreeStore.backgroundsState.toJSON(),
                                BackgroundMode.Update,
                                false
                            );
                            this.setState({ focused: true });
                            if (mobileAndTabletBreakpoint()) {
                                openDelayedNodeMenuAfterTouch(
                                    () => {
                                        this.setState({
                                            focused: true,
                                            drag: false,
                                        });
                                    },
                                    () => {
                                        this.setState({ focused: false });
                                    }
                                );
                            }
                        }
                    }}
                >
                    <div
                        style={{
                            width: scaledWidth,
                            height: scaledHeight,
                            top: 0,
                            left: 0,
                            position: "absolute",
                            zIndex: tag.zIndex ?? 50,
                        }}
                        onContextMenu={(evt: React.MouseEvent) => {
                            if (!this.state.editingAllowed)
                                this.props.onContextMenu(evt, {
                                    id: tag.id,
                                    type: "canvasTreeState",
                                });
                            else {
                                this.props.onHideContextMenu();
                            }
                        }}
                        onClick={() => {
                            this.setState({ focused: true });
                        }}
                        onTouchStart={() => {
                            openDelayedNodeMenuAfterTouch(
                                () => {
                                    this.setState({ focused: true });
                                },
                                () => {
                                    this.setState({ focused: false });
                                }
                            );
                        }}
                    >
                        <Resizable
                            className="selectable-by-pointer"
                            ref={(ref) => {
                                let innerResizable = ref?.resizable;
                                if (innerResizable != null) {
                                    innerResizable.setAttribute(
                                        "type",
                                        "canvasTreeState"
                                    );
                                    if (this.state.tag.groupId != null)
                                        innerResizable.setAttribute(
                                            "groupId",
                                            this.state.tag.groupId
                                        );
                                    else {
                                        innerResizable.removeAttribute(
                                            "groupId"
                                        );
                                    }
                                    innerResizable.setAttribute(
                                        "id",
                                        String(this.state.tag.id)
                                    );
                                    innerResizable.setAttribute(
                                        "data-test-id",
                                        `${rootDataTestId}`
                                    );
                                }
                            }}
                            enable={
                                (this.props.live && !tag.unlocked) ||
                                !this.props.canWrite
                                    ? {
                                          top: false,
                                          right: false,
                                          bottom: false,
                                          left: false,
                                          topRight: false,
                                          bottomRight: false,
                                          bottomLeft: false,
                                          topLeft: false,
                                      }
                                    : {
                                          top: false,
                                          right: true,
                                          bottom: false,
                                          left: true,
                                          topRight: false,
                                          bottomRight: false,
                                          bottomLeft: false,
                                          topLeft: false,
                                      }
                            }
                            onResizeStart={(evt) => {
                                evt.stopPropagation();
                                this.setState({
                                    resizing: {
                                        old: {
                                            position: { ...tag.nodePosition },
                                            size: {
                                                ...tag.nodeSize,
                                            } as NodeSize,
                                        },
                                        new: {
                                            position: { ...tag.nodePosition },
                                            size: {
                                                ...tag.nodeSize,
                                            } as NodeSize,
                                        },
                                    },
                                });
                            }}
                            onResize={(_e, _direction, ref, d) => {
                                if (!this.state.resizing) {
                                    return;
                                }

                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;
                                let newSize = getNewSizeAfterResize2(
                                    {
                                        position: this.state.resizing.old
                                            .position,
                                        size: this.state.resizing.old.size,
                                    },
                                    this.props.scale,
                                    _direction,
                                    d,
                                    canvasViewMode
                                );
                                let newHeight = Math.max(
                                    Math.round(
                                        this.tagAreaRef.wrapper.firstChild
                                            .firstChild.firstChild
                                            .scrollHeight + 5
                                    ),
                                    tagDefaultHeight
                                );
                                newSize.nodeSize[
                                    canvasViewMode
                                ].height = newHeight;
                                ref.style.height = `${
                                    newHeight * this.props.scale
                                }px`;
                                this.setState(
                                    (state) => ({
                                        resizing: {
                                            ...state.resizing!,
                                            new: {
                                                position: newSize.nodePosition,
                                                size: newSize.nodeSize,
                                            },
                                        },
                                    }),
                                    () => {
                                        this.props.onUpdateSelectionBounds?.();
                                    }
                                );
                            }}
                            onResizeStop={(_e, _direction, _ref, d) => {
                                if (!this.state.resizing) {
                                    return;
                                }

                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;
                                this.trackNewPerformance(element);
                                _ref.style.outline = "none";
                                const newSize = this.state.resizing?.new;
                                this.props.canvasTreeStore.updateNodeAction<
                                    CanvasTextBox,
                                    "nodePosition" | "nodeSize"
                                >(this.state.tag.id, {
                                    nodePosition: newSize.position,
                                    nodeSize: newSize.size,
                                });
                                if (!mobileViewWasEdited) {
                                    this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                }
                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        x: newSize.position[canvasViewMode].x,
                                        y: newSize.position[canvasViewMode].y,
                                        width:
                                            newSize.size[canvasViewMode].width,
                                        height:
                                            newSize.size[canvasViewMode].height,
                                    }
                                );
                                this.props.onResize();
                                this.setState({ resizing: undefined });
                            }}
                            size={{
                                width: scaledWidth,
                                height: scaledHeight,
                            }}
                            style={{
                                borderWidth,
                                borderStyle,
                                borderColor,
                                borderRadius,
                                backgroundColor,
                                boxShadow:
                                    !edited &&
                                    FormulaInformationStore.highlightItems &&
                                    FormulaInformationStore.currentItem
                                        ?.type === InputType.Regular &&
                                    FormulaInformationStore.currentItem
                                        ?.value === tag.id
                                        ? "0px 0px 10px #1F8EFAB3"
                                        : !tag.borderShadow
                                        ? "none"
                                        : "0 6px 13px 0 rgba(21, 33, 56, 0.53)",
                            }}
                        >
                            {error && (
                                <img
                                    alt=""
                                    width={`${8 * this.props.scale}`}
                                    height={`${8 * this.props.scale}`}
                                    style={{
                                        zIndex: 1,
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: 25,
                                        top: 10,
                                    }}
                                    title={
                                        this.props.canvasTreeStore.canvasTreeRequestErrorsState.get(
                                            tag.id
                                        ) ??
                                        this.props.canvasTreeStore.canvasTreeParseErrorsState.get(
                                            tag.id
                                        ) ??
                                        ""
                                    }
                                    src="/dist/img/error.png"
                                />
                            )}
                            {tag.delegate != null &&
                                this.props.canvasTreeStore.connectedUsersState.get(
                                    tag.delegate
                                ) && (
                                    <div
                                        data-test-id={`${rootDataTestId}-delegate`}
                                        style={{
                                            position: "absolute",
                                            transformOrigin: "bottom right",
                                            bottom: 2 * this.props.scale,
                                            right: 2 * this.props.scale,
                                            transform: `scale(${this.props.scale})`,
                                        }}
                                        onClick={(evt: any) => {
                                            if (this.props.live) {
                                                this.trackNewPerformance(
                                                    element
                                                );
                                                evt.stopPropagation();
                                                let absolutePositionNode = {
                                                    ...tag,
                                                };

                                                this.props.onExpandUserCard(
                                                    absolutePositionNode
                                                );
                                            }
                                        }}
                                    >
                                        <UserIcon
                                            width={16}
                                            height={16}
                                            iconUrl={
                                                this.props.canvasTreeStore.connectedUsersState.get(
                                                    tag.delegate
                                                )!.icon_url
                                            }
                                            user={
                                                this.props.canvasTreeStore.connectedUsersState.get(
                                                    tag.delegate
                                                )!
                                            }
                                            fontSize={8}
                                        />
                                    </div>
                                )}
                            {!this.props.live && (
                                <span
                                    className="unselectable"
                                    style={{
                                        backgroundColor: FormulaInformationStore.highlightItems
                                            ? "#1F8EFA"
                                            : "transparent",
                                        position: "absolute",
                                        padding: 1,
                                        left: 0,
                                        top: 0,
                                        fontWeight: 400,
                                        fontSize:
                                            identifierFontSize *
                                            this.props.scale,
                                        fontFamily: "Roboto",
                                        color: FormulaInformationStore.highlightItems
                                            ? "#FFFFFF"
                                            : mainStyle.getPropertyValue(
                                                  "--secondary-text-color"
                                              ),
                                    }}
                                >
                                    {this.state.tag.outerId ?? ""}
                                </span>
                            )}
                            <div
                                data-test-id={`${rootDataTestId}-editor-click-container`}
                                ref={this.elementAreaRef}
                                className="hide-scroll"
                                style={{
                                    boxShadow:
                                        this.state.editingAllowed &&
                                        !this.props.live
                                            ? "0 0 10px rgba(0,0,0,0.5)"
                                            : "none",
                                    width: scaledWidth - 2 * borderWidth,
                                    height: scaledHeight - 2 * borderWidth,
                                    paddingLeft: this.props.scale * 10,
                                    paddingRight: this.props.scale * 10,
                                    paddingTop: this.props.scale * 5,
                                }}
                                onContextMenu={(evt: React.MouseEvent) => {
                                    if (!this.state.editingAllowed)
                                        this.props.onContextMenu(evt, {
                                            id: tag.id,
                                            type: "canvasTreeState",
                                        });
                                    else {
                                        this.props.onHideContextMenu();
                                    }
                                }}
                                onClick={(evt) => {
                                    const nodeName = (evt.target as Element)
                                        .nodeName;
                                    if (nodeName === "INPUT" && !edited) {
                                        return;
                                    }
                                    this.onClickEditorClickContainer({
                                        tag,
                                        canWrite,
                                        edited,
                                        element,
                                    });
                                }}
                                onTouchStart={(_evt) => {
                                    onDoubleTouch(() => {
                                        this.onClickEditorClickContainer({
                                            tag,
                                            canWrite,
                                            edited,
                                            element,
                                        });
                                    });
                                }}
                            >
                                <div
                                    data-test-id={`${rootDataTestId}-editor-focus-container`}
                                    tabIndex={0}
                                    ref={(ref) => {
                                        this.parentTagAreaRef = ref;
                                    }}
                                    style={{
                                        outline: "none",
                                    }}
                                    onMouseDown={(evt) => {
                                        if (this.state.editingAllowed)
                                            evt.stopPropagation();
                                    }}
                                    onPaste={(evt) => {
                                        evt.stopPropagation();
                                    }}
                                    onClick={(evt) => {
                                        const nodeName = (evt.target as Element)
                                            .nodeName;
                                        if (nodeName === "INPUT" && !edited) {
                                            const nodeKey =
                                                (evt.target as any)
                                                    ?.offsetParent?.offsetParent
                                                    ?.dataset?.offsetKey ?? "";
                                            const newTag = {
                                                ...tag,
                                                rawMetric: _.cloneDeep(
                                                    tag.rawMetric
                                                ),
                                            };
                                            if (newTag.rawMetric) {
                                                newTag.rawMetric.blocks = newTag.rawMetric.blocks.map(
                                                    (block) => {
                                                        if (
                                                            nodeKey.includes(
                                                                block.key
                                                            )
                                                        ) {
                                                            const checked =
                                                                block.data
                                                                    ?.checked ??
                                                                false;
                                                            this.checkClickedCheckBox(
                                                                !checked,
                                                                block?.key
                                                            );
                                                            return {
                                                                ...block,
                                                                data: {
                                                                    ...block.data,
                                                                    checked: nodeKey.includes(
                                                                        block.key
                                                                    )
                                                                        ? !checked
                                                                        : checked,
                                                                },
                                                            };
                                                        }
                                                        return block;
                                                    }
                                                );
                                            }

                                            this.setState({
                                                tag: newTag,
                                            });

                                            this.props.canvasTreeStore.updateNodeAction<
                                                CanvasTextBox,
                                                keyof typeof newTag
                                            >(
                                                newTag.id,
                                                {
                                                    ...newTag,
                                                    lastTextEditSessionId: sessionId(),
                                                },
                                                true,
                                                true
                                            );

                                            return;
                                        }

                                        this.onClickEditorFocusContainer({
                                            edited,
                                            evt,
                                        });
                                    }}
                                    onTouchStart={(evt) => {
                                        onDoubleTouch(() => {
                                            this.onClickEditorFocusContainer({
                                                edited,
                                                evt,
                                            });
                                        });
                                    }}
                                    onKeyDown={(evt) => {
                                        if (
                                            (evt.key === "Delete" ||
                                                evt.key === "Backspace" ||
                                                evt.key === "ArrowUp" ||
                                                evt.key === "ArrowDown" ||
                                                evt.key === "ArrowLeft" ||
                                                evt.key === "ArrowRight") &&
                                            !this.state.editingAllowed
                                        ) {
                                            return;
                                        }
                                        if (
                                            (evt.ctrlKey || evt.metaKey) &&
                                            !this.state.editingAllowed
                                        ) {
                                            return;
                                        }
                                        evt.stopPropagation();
                                        if (
                                            evt.key === "Escape" &&
                                            !evt.shiftKey
                                        ) {
                                            evt.preventDefault();
                                            this.forceKeepChanges(() => {
                                                this.tagAreaRef?.editor.blur();
                                                this.parentTagAreaRef?.focus({
                                                    preventScroll: true,
                                                });
                                            });
                                        } else {
                                            if (
                                                edited &&
                                                !this.state.editingAllowed
                                            ) {
                                                this.startEditText();
                                            }
                                        }
                                    }}
                                >
                                    <Editor
                                        readOnly={
                                            !this.state.editingAllowed ||
                                            this.state.showFormulaView != null
                                        }
                                        handlePastedText={this.handlePastedText}
                                        key={statusTextHash}
                                        customDecorators={decorators}
                                        customStyleMap={styleMap}
                                        blockStyleFn={this.blockStyleFn}
                                        customBlockRenderMap={
                                            customBlockRenderMap
                                        }
                                        onOpenInternalLink={(
                                            link: number,
                                            inPopup: boolean
                                        ) => {
                                            if (inPopup) {
                                                this.props.onExpandCard({
                                                    ...this.state.tag,
                                                    linkPopups: [link],
                                                    links: [],
                                                });
                                            } else {
                                                this.props.onExpandCard({
                                                    ...this.state.tag,
                                                    links: [link],
                                                    linkPopups: [],
                                                });
                                            }
                                        }}
                                        toolbarCustomButtons={[
                                            <CheckListOption
                                                onChange={
                                                    this.onEditorStateChange
                                                }
                                                editorState={
                                                    this.state.editorState
                                                }
                                            />,
                                            <CustomLinkOption
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onChange={
                                                    this.onEditorStateChange
                                                }
                                                editorState={
                                                    this.state.editorState
                                                }
                                                tag={this.state.tag}
                                                currentModuleId={
                                                    this.props.currentModuleId
                                                }
                                                onPopupOpened={
                                                    this.onLinkPopupOpen
                                                }
                                            />,
                                        ]}
                                        toolbarHidden={
                                            !this.state.editingAllowed
                                        }
                                        spellCheck
                                        onFocus={this.onEditorFocus}
                                        onBlur={this.onEditorBlur}
                                        toolbarOnFocus={
                                            !this.state.linkPopupOpened
                                        }
                                        editorState={this.state.editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        editorStyle={{
                                            width:
                                                tagNodeSize[canvasViewMode]
                                                    .width - 20,
                                            transformOrigin: "left top",
                                            transform: `scale(${this.props.scale})`,
                                            overflow: "hidden",
                                            fontFamily: "Roboto",
                                        }}
                                        toolbarStyle={{
                                            zIndex: 999,
                                            minWidth: 500,
                                            transform: "translate(-50%, 0)",
                                            left: "50%",
                                            position: "absolute",
                                            bottom: 30,
                                        }}
                                        placeholder="Click to add text..."
                                        ref={(ref) => {
                                            this.tagAreaRef = ref;
                                        }}
                                        onEditorStateChange={
                                            this.onEditorStateChange
                                        }
                                        toolbar={{
                                            options: [
                                                "fontFamily",
                                                "fontSize",
                                                "lineHeight",
                                                "letterSpacing",
                                                "colorPicker",
                                                "history",
                                                "inline",
                                                "blockType",
                                                "list",
                                                "textAlign",
                                            ],
                                            inline: {
                                                options: [
                                                    "bold",
                                                    "italic",
                                                    "underline",
                                                    "strikethrough",
                                                ],
                                            },
                                            link: {
                                                showOpenOptionOnHover: false,
                                            },
                                            fontFamily: {
                                                options: fontFamilies.concat(
                                                    FontLoader.fonts
                                                ),
                                                className: undefined,
                                                component: undefined,
                                                dropdownClassName: undefined,
                                            },
                                            blockType: {
                                                inDropdown: false,
                                                options: [],
                                            },
                                        }}
                                        canvasTreeStore={canvasTreeStore}
                                    />
                                </div>
                            </div>
                        </Resizable>
                    </div>
                </DraggableWithSnapping>
                {this.state.showFormulaView && (
                    <Portal rootNode={document.body}>
                        <div
                            data-test-id={`${rootDataTestId}-formula-popup-container`}
                        >
                            <FormulaPopup
                                left={this.state.showFormulaView.left}
                                top={this.state.showFormulaView.top}
                                canvasTreeStore={this.props.canvasTreeStore}
                                onOpenCloudStorage={(callbackArgs) => {
                                    // This is called when we click cloud storage button in formula popup
                                    this.props.onOpenBottomPortal(
                                        PortalType.CloudStorage,
                                        {
                                            ...callbackArgs,
                                            currentModuleId: this.props
                                                .currentModuleId,
                                        }
                                    );
                                }}
                                onReject={() => {
                                    // This is called when we click Cancel in Formula Popup
                                    this.setState(
                                        (state) => {
                                            let contentState = state.editorState.getCurrentContent();
                                            // If we tried to add new Formula then we
                                            // remove inline blue style
                                            if (
                                                !containsEntity(
                                                    contentState,
                                                    state.showFormulaView!
                                                        .outputIndex
                                                )
                                            ) {
                                                contentState = Modifier.removeInlineStyle(
                                                    contentState,
                                                    state.showFormulaView!
                                                        .entityState!,
                                                    "FORMULA"
                                                );
                                                let newEditorState = EditorState.push(
                                                    state.editorState,
                                                    contentState,
                                                    "change-inline-style"
                                                );
                                                newEditorState = EditorState.forceSelection(
                                                    newEditorState,
                                                    state.editorState.getSelection()
                                                );
                                                return {
                                                    editorState: newEditorState,
                                                    tag: {
                                                        ...state.tag,
                                                        rawMetric: convertToRaw(
                                                            newEditorState.getCurrentContent()
                                                        ),
                                                    },
                                                    showFormulaView: undefined,
                                                };
                                            } else {
                                                // Else we simply select formula item
                                                let entities = getFormulaTextEntities(
                                                    state.editorState.getCurrentContent()
                                                );
                                                let currentEntity = entities.find(
                                                    (entity) =>
                                                        entity.outputIndex ===
                                                        this.state
                                                            .showFormulaView!
                                                            .outputIndex
                                                )!;
                                                let newEditorState = EditorState.forceSelection(
                                                    state.editorState,
                                                    new SelectionState({
                                                        anchorKey:
                                                            currentEntity.blockKey,
                                                        focusKey:
                                                            currentEntity.blockKey,
                                                        anchorOffset:
                                                            currentEntity.end,
                                                        focusOffset:
                                                            currentEntity.end,
                                                    })
                                                );

                                                return {
                                                    editorState: newEditorState,
                                                    showFormulaView: undefined,
                                                } as any;
                                            }
                                        },
                                        () => {
                                            this.updateTag();
                                        }
                                    );
                                }}
                                onAccept={(
                                    tag: CanvasTextBox,
                                    hidePopup: boolean
                                ) => {
                                    // called when we click SAVE in Formula popup
                                    this.setState(
                                        (state) => {
                                            if (
                                                !containsEntity(
                                                    state.editorState.getCurrentContent(),
                                                    state.showFormulaView!
                                                        .outputIndex
                                                )
                                            ) {
                                                // If it is new output we add new entity
                                                let rawMetric = convertToRaw(
                                                    this.addOutput(state)
                                                );
                                                // generate new textbox state
                                                tag.rawMetric = generateRawMetric(
                                                    tag,
                                                    rawMetric
                                                );
                                            } else {
                                                // If we edit existing output, then we regenerate textbox state
                                                if (tag.rawMetric != null)
                                                    tag.rawMetric = generateRawMetric(
                                                        tag,
                                                        tag.rawMetric
                                                    );
                                            }
                                            let editorState;
                                            let changes: any = {
                                                tag: tag,
                                                editingAllowed: false,
                                            };

                                            if (tag.rawMetric != null) {
                                                editorState = EditorState.createWithContent(
                                                    convertFromRaw(
                                                        tag.rawMetric
                                                    )
                                                );
                                                let entities = getFormulaTextEntities(
                                                    editorState.getCurrentContent()
                                                );
                                                let currentEntity = entities.find(
                                                    (entity) =>
                                                        entity.outputIndex ===
                                                        this.state
                                                            .showFormulaView!
                                                            .outputIndex
                                                )!;
                                                // Here we select formula item
                                                if (currentEntity != null)
                                                    editorState = EditorState.forceSelection(
                                                        editorState,
                                                        new SelectionState({
                                                            anchorKey:
                                                                currentEntity.blockKey,
                                                            focusKey:
                                                                currentEntity.blockKey,
                                                            anchorOffset:
                                                                currentEntity.end,
                                                            focusOffset:
                                                                currentEntity.end,
                                                        })
                                                    );
                                                changes.editorState = editorState;
                                            }
                                            if (hidePopup) {
                                                changes.lastEditIndex = state.showFormulaView!.outputIndex;
                                                changes.showFormulaView = undefined;
                                            }
                                            return changes;
                                        },
                                        () => {
                                            this.props.canvasTreeStore.updateNodeAction(
                                                this.state.tag.id,
                                                {
                                                    ...this.state.tag,
                                                    lastTextEditSessionId: sessionId(),
                                                }
                                            );
                                            this.updateTag();
                                            if (hidePopup) {
                                                this.parentTagAreaRef?.focus({
                                                    preventScroll: true,
                                                });
                                            }
                                        }
                                    );
                                }}
                                key={this.state.showFormulaView.outputIndex}
                                currentModuleId={this.props.currentModuleId}
                                tag={this.state.tag}
                                outputIndex={
                                    this.state.showFormulaView.outputIndex
                                }
                            />
                        </div>
                    </Portal>
                )}
                {this.state.showPresetsPopup && (
                    <SavePresetPopup
                        tag={this.state.tag}
                        onClose={() => {
                            this.setState({ showPresetsPopup: false });
                        }}
                    />
                )}
            </>
        );

        return (
            <OutsideAlerter
                onReject={() => {
                    this.setState({
                        focused: false,
                    });
                }}
            >
                {component}
            </OutsideAlerter>
        );
    }
    render() {
        return (
            <GlobalContext.ObserverConsumer>
                {this.renderContents}
            </GlobalContext.ObserverConsumer>
        );
    }
}

export default observer(function Tags(props: TagProps) {
    let tags: JSX.Element[] = [];
    for (let node of props.canvasTreeStore.canvasTreeState.values()) {
        if (
            node.nodeIsHidden &&
            node.nodeIsHidden[props.canvasTreeStore.canvasViewMode]
        )
            continue;
        if (isTextBox(node)) {
            let key =
                typeof props.canvasTreeStore.canvasPageId === "number"
                    ? hashids.encode(
                          props.canvasTreeStore.canvasPageId as number,
                          props.canvasTreeStore.canvasId!,
                          node.id
                      )
                    : String(props.canvasTreeStore.canvasPageId).concat(
                          hashids.encode(
                              props.canvasTreeStore.canvasId!,
                              node.id
                          )
                      );
            tags.push(
                <Tag
                    index={tags.length + 1}
                    key={key}
                    tag={{ ...node }}
                    {...props}
                    canvasViewMode={props.canvasTreeStore.canvasViewMode}
                />
            );
        }
    }

    return <>{tags}</>;
});
