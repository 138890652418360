import "common/styles/App.css";
import React from "react";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reaction } from "mobx";
import { Api } from "./Api";

import axios from "common/ServerConnection";
import LeversChartsComponentWithOverlay from "./LeversChartsComponentWithOverlay";
import { Button } from "react-bootstrap";
import OutcomesBlockV3 from "common/graphics/OutcomesBlockV3";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import { mainStyle } from "common/MainStyle";
import Variables from "common/Variables";
import BaseJourney from "../../common/BaseJourney";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";

const Step = Object.freeze({
	initial: 0,
	selectDataScope: 1,
	selectTarget: 2,
	selectAdditionalParameter: 3,
	selectTable: 4,
	selectAdditionalValue: 5,
	final: 6,
});

function StepFinal(props) {
	let {
		predictOutcome,
		onSaveFinding,
		onShowRecipesPopup,
		errorMessage,
		onBack,
		leversChartsComponentRef,
		finding,
	} = props;
	return (
		<>
			<div
				className="my-row"
				style={{
					alignItems: "center",
					width: "100%",
				}}
			>
				<div
					onClick={() => {
						onBack();
					}}
					style={{
						width: 100,
						height: "100%",
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
						justifyContent: "center",
					}}
				>
					<img
						alt=""
						src="/dist/img/data-exploration/chevron_back.png"
					/>
				</div>
				{finding && (
					<div
						className="flex-simple-column"
						style={{
							width: "calc(100% - 200px)",
							height: "100%",
						}}
					>
						<div
							className="flex-simple-column"
							style={{ width: "100%", height: "370px" }}
						>
							<div style={{ width: "100%", height: "100%" }}>
								<ErrorBoundary>
									<LeversChartsComponentWithOverlay
										ref={leversChartsComponentRef}
										clustNames={finding.content.clustNames}
										clust={finding.content.clust}
									/>
								</ErrorBoundary>
							</div>
							<div
								className="my-row"
								style={{ alignItems: "center" }}
							>
								<Button
									type="button"
									className="btn btn-md btn-primary my-primary"
									style={{
										width: "112px",
									}}
									onClick={() => {
										predictOutcome();
									}}
								>
									RUN
								</Button>
								{errorMessage ? (
									<span
										style={{
											marginLeft: 10,
											fontFamily: "Roboto",
											fontSize: "12px",
											lineHeight: "14px",
											color: "red",
										}}
									>
										{errorMessage}
									</span>
								) : null}
								{finding.content.outcome ? (
									<OutcomesBlockV3
										item={finding.content.outcome}
										leftTitle="Simulated"
										rightTitle="Optimized"
										leftKey="projected"
										rightKey="optimal"
									/>
								) : null}
							</div>
						</div>
						<div
							className="my-row"
							style={{
								marginTop: -20,
								alignItems: "center",
								alignSelf: "flex-end",
							}}
						>
							<span
								style={{
									cursor: "pointer",
									fontFamily: "Roboto",
									fontSize: "12px",
									lineHeight: "14px",
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
									fontWeight: 700,
								}}
							>
								press [s] to snap to grid
							</span>
							<div style={{ marginLeft: 10 }}>
								<FinalStepBottomBar
									onShowRecipesPopup={onShowRecipesPopup}
									onSaveFinding={onSaveFinding}
									topMargin={0}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

class MainComponent extends BaseJourney {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			clustKeys: [],
			clustNames: [],
			loading: false,
			finding: undefined,
			targetVariables: [],
			targetVariable: undefined,
			panelVariablesMap: {},
			additionalParameters: [],
			additionalParameter: undefined,
			outcome2TablePanelMap: {},
			step: 0,
			previousStep: 0,
			animationDirection: "top",
			step2VariablesMap: {
				[Step.selectTarget]: ["targetVariable", "targetVariables"],
				[Step.selectAdditionalParameter]: [
					"additionalParameter",
					"additionalParameters",
				],
			},
			aggregateTableList: [],
			selectedTable: undefined,
		};
		this.getData = this.getData.bind(this);
		this.predictOutcome = this.predictOutcome.bind(this);
		this.buildContent = this.buildContent.bind(this);
		this.getSubtitle = this.getSubtitle.bind(this);
		this.selectVariable = this.selectVariable.bind(this);
		this.initializeVariables = this.initializeVariables.bind(this);
		this.switchVariables = this.switchVariables.bind(this);
		this.getTrainedOutcomesAndTables = this.getTrainedOutcomesAndTables.bind(
			this
		);
		this.leversChartsComponentRef = React.createRef();
	}
	getConfig() {
		return {
			version: configVersion,
			modelId: this.state.outcome2TablePanelMap[
				this.state.targetVariable
			][this.state.additionalParameter].model_id,
			dataScope: this.state.dataScope,
			targetVariable: this.state.targetVariable,
			selectedTable: this.state.selectedTable,
			additionalParameter: this.state.additionalParameter,
			leftAdditionalValue: this.state.leftAdditionalValue,
			journeyName: this.props.journeyName,
		};
	}

	stepDown() {
		if (this.state.step === Step.final - 1) {
			this.showFinalStep();
			return;
		}
		if (
			this.state.step === Step.selectTarget &&
			this.state.targetVariable
		) {
			let additionalParameters = Object.keys(
				this.state.outcome2TablePanelMap[this.state.targetVariable] ||
					{}
			);

			this.setState((state) => ({
				additionalParameters: additionalParameters,
				additionalParameter: additionalParameters.includes(
					state.additionalParameter
				)
					? state.additionalParameter
					: undefined,
			}));
		}
		if (
			this.state.step === Step.selectAdditionalParameter &&
			this.state.targetVariable &&
			this.state.additionalParameter
		) {
			let aggregateTableList = this.state.outcome2TablePanelMap[
				this.state.targetVariable
			][this.state.additionalParameter].tables;
			this.setState({ aggregateTableList: aggregateTableList });
		}

		if (
			this.state.step === Step.selectTable &&
			this.state.selectedTable &&
			this.state.additionalParameter === "overall"
		) {
			this.showFinalStep();
			return;
		}
		if (this.state.step === Step.selectTable && !this.state.selectedTable) {
			return;
		}

		if (
			this.state.step === Step.selectAdditionalParameter &&
			!this.state.additionalParameter
		) {
			return;
		}

		this.setState({ animationDirection: "top" }, () => {
			this.setState((state) => ({
				step: state.step + 1,
			}));
		});
	}

	stepUp() {
		if (this.state.step > Step.initial)
			this.setState({ animationDirection: "bottom" }, () => {
				this.setState((state) => ({
					step: state.step - 1,
				}));
			});
	}

	selectVariable(variableName, variablesName, index) {
		let stateDiff = { [variableName]: this.state[variablesName][index] };
		if (this.state.step === Step.selectAdditionalParameter) {
			stateDiff.leftAdditionalValue = undefined;
		}
		this.setState(stateDiff, () => {
			setTimeout(() => {
				this.stepDown();
			}, 100);
		});
	}
	predictOutcome() {
		let clust = Array.from(
			this.leversChartsComponentRef.current.state.clust
		);
		let finding = { ...this.state.finding };
		finding.content.clust = clust;
		this.setState({ finding: finding }, () => {
			this.getData();
		});
	}
	getData() {
		let config = this.getConfig();
		if (!this.state.finding) this.setState({ loading: true });
		Api.getData(config, this.state.finding)
			.then((item) => {
				this.setState({
					finding: item,
					loading: false,
				});
			})
			.catch((error) => {
				console.log(error);
				this.setState({ loading: false, errorMessage: String(error) });
			});
	}
	buildContent() {
		switch (this.state.step) {
			case Step.initial:
				return this.buildInitial(
					"How do you want to test the impact of various lever configurations?"
				);
			case Step.selectDataScope:
				return this.buildDataScopesSelector();
			case Step.selectTarget:
				return this.buildVariablesSelector(
					"I want to test how different lever configurations would drive",
					"targetVariable",
					"targetVariables"
				);
			case Step.selectTable:
				return this.buildTableSelector(this.state.aggregateTableList);
			case Step.selectAdditionalParameter:
				return this.buildVariablesSelector(
					"For",
					"additionalParameter",
					"additionalParameters"
				);
			case Step.selectAdditionalValue:
				return this.buildAdditionalValueSelector("Select");
			case Step.final:
				return (
					<StepFinal
						onShowRecipesPopup={this.showRecipesPopup}
						onSaveFinding={this.saveFinding}
						leversChartsComponentRef={this.leversChartsComponentRef}
						errorMessage={this.state.errorMessage}
						finding={this.state.finding}
						predictOutcome={this.predictOutcome}
						loading={this.state.loading}
						onBack={this.back}
					/>
				);

			default:
				return <div />;
		}
	}
	initializeVariables() {
		this.getTrainedOutcomesAndTables();
	}
	getTrainedOutcomesAndTables() {
		axios
			.post(
				"/api/get_trained_outcomes_and_tables",
				{
					data_table_idx: this.dataScopeValue(),
				},
				null
			)
			.then((response) => {
				if (response.data.success === false) {
					console.log(response.data.error_msg);
				} else {
					let outcomes_and_tables = response.data.outcomes_and_tables;
					let outcomes = outcomes_and_tables.map(
						(outcome_and_table) => outcome_and_table.outcome
					);
					let outcome2TablePanelMap = {};
					outcomes_and_tables.forEach((outcome_and_table) => {
						outcome2TablePanelMap[outcome_and_table.outcome] =
							outcome_and_table.panels_and_tables;
					});
					this.setState({
						targetVariables: outcomes,
						outcome2TablePanelMap: outcome2TablePanelMap,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	switchVariables() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
		this.initializeVariables();
		this.initializeVariablesReaction = reaction(
			() => Variables(this.dataScopeValue()).dataVariables,
			() => {
				this.initializeVariables();
			}
		);
	}
	componentDidMount() {
		this.getLastJourneyConfig();
	}
	componentWillUnmount() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
	}
	getSubtitle() {
		if (
			this.state.step === Step.selectDataScope ||
			this.state.step === Step.selectTarget
		)
			return "How do you want to test the impact of various lever configurations?";
		if (
			this.state.step > Step.selectTarget &&
			this.state.step < Step.final
		) {
			let subtitle = "";
			if (this.state.step > Step.initial)
				subtitle =
					"I want to test how different lever configurations would drive";
			if (this.state.step > Step.selectTarget)
				subtitle = subtitle.concat(
					` ${this.state.targetVariable || ""}`
				);
			if (this.state.step > Step.selectAdditionalParameter)
				subtitle = subtitle.concat(
					` for ${this.state.additionalParameter || ""}`
				);

			if (this.state.step > Step.selectTable) {
				subtitle += ` for the data in ${
					this.state.dataScope ? this.state.dataScope.label : ""
				} ${this.state.selectedTable.label}`;
			}
			return subtitle;
		}
		if (this.state.step === Step.final) {
			return (
				<>
					<span>
						I want to test how different lever configurations would
						drive
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{" ".concat(this.state.targetVariable)}
					</span>
					{this.state.additionalParameter ? (
						<>
							<span> for </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{" ".concat(this.state.additionalParameter)}
							</span>
						</>
					) : null}
					{this.state.additionalParameter &&
					this.state.leftAdditionalValue ? (
						<>
							<span>=</span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{this.state.leftAdditionalValue.label}
							</span>
						</>
					) : null}
					{this.state.selectedTable ? (
						<>
							<span> for the data in </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{this.state.dataScope
									? this.state.dataScope.label.concat(" ")
									: ""}
							</span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{this.state.selectedTable.label}
							</span>
						</>
					) : null}
					<span>.</span>
				</>
			);
		}
	}

	showFinalStep() {
		if (
			!this.state.selectedTable ||
			!this.state.targetVariable ||
			!this.state.additionalParameter
		)
			return;
		if (this.state.step < Step.final)
			this.setState({ animationDirection: "top" }, () => {
				this.setState(
					(state) => ({
						previousStep: state.step,
						step: Step.final,
					}),
					() => {
						this.getData(
							this.state.targetVariable,
							this.state.additionalParameter,
							this.state.leftAdditionalValue,
							this.state.selectedTable
						);
						this.saveNewJourneyConfig();
					}
				);
			});
	}

	getFooterTitle() {
		switch (this.state.step) {
			case Step.initial:
				return "I want to test how different lever configurations would drive ...";
			case Step.selectTarget:
				return "..for..";
			default:
				return "";
		}
	}
	buildLeftBar() {
		return super.buildLeftBar(this.state.step < Step.final - 1);
	}
	render() {
		return (
			<div
				className="dashboard-rect-journey-focus"
				tabIndex="0"
				style={{
					height: 520,
					overflow: "hidden",

					display: !this.props.collapsed ? "block" : "none",
				}}
				onClick={() => {
					if (this.state.step === Step.initial) this.stepDown();
				}}
				onKeyDown={(evt) => {
					if (evt.key === "Escape") {
						this.props.onClose();
					}

					if (evt.key === "ArrowDown") {
						if (this.state.step > 0 && this.state.step < Step.final)
							this.stepDown();
						evt.preventDefault();
					} else if (evt.key === "ArrowLeft") {
						return;
					} else if (evt.key === "ArrowRight") {
						this.showFinalStep();
					} else if (evt.key === "ArrowUp") {
						if (this.state.step < Step.final) this.stepUp();
						evt.preventDefault();
					} else if (evt.key === "p") {
						if (this.state.step === Step.final) {
							this.saveFinding();
						}
					} else if (evt.key === "d") {
						if (this.state.step === Step.final) {
							evt.preventDefault();
							this.showRecipesPopup();
						}
					}
					let variableInfo = this.state.step2VariablesMap[
						this.state.step
					];
					if (variableInfo) {
						let variablesLength = this.state[variableInfo[1]]
							.length;
						let variableIndex = evt.keyCode - 65;
						if (
							variableIndex >= 0 &&
							variableIndex < variablesLength
						) {
							this.selectVariable(
								variableInfo[0],
								variableInfo[1],
								variableIndex
							);
						}
					}
					if (
						this.state.step === Step.selectAdditionalValue &&
						Variables(this.dataScopeValue()).isMonthVariable(
							this.state.additionalParameter
						)
					) {
						this.selectMonth(this);
					}
					if (evt.key === "Enter") {
						if (
							this.state.step === Step.selectAdditionalValue &&
							this.state.leftAdditionalValue &&
							Variables(this.dataScopeValue()).isMonthVariable(
								this.state.additionalParameter
							)
						) {
							this.showFinalStep();
						}
					}
				}}
			>
				<div
					className="my-row"
					style={{ justifyContent: "space-between", height: "100%" }}
				>
					<div
						className="flex-column"
						style={{
							height: "100%",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						{this.buildHeader()}

						<TransitionGroup
							style={{
								minHeight: "inherit",
							}}
						>
							<CSSTransition
								style={{
									height: "100%",
								}}
								key={this.state.step}
								timeout={500}
								classNames={"journeywizard-".concat(
									this.state.animationDirection || ""
								)}
							>
								<div
									style={{
										height: "100%",
									}}
								>
									{this.buildContent()}
								</div>
							</CSSTransition>
						</TransitionGroup>
						<Footer
							showArrow={
								this.state.step > Step.initial &&
								this.state.step < Step.final
							}
							title={this.getFooterTitle()}
						/>
					</div>
				</div>
				{this.buildAddToRecipesPopup()}
			</div>
		);
	}
}

export { MainComponent };