import React, { useEffect, useState, useRef } from "react";
import Webcam from "react-webcam";
import { BrowserMultiFormatReader } from "@zxing/library";
import cx from "classnames";

import { CanvasBarcodeReader } from "common/Canvas";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import submitData from "modules/canvas_page/SubmitData";

import { ReactComponent as CameraIcon } from "icons/canvas/camera.svg";
import { ReactComponent as TickIcon } from "icons/canvas/tick.svg";

import styles from "./BarcodeReader.module.css";

enum SubmitStatus {
	IDLE = 1,
	SUCCESS = 2,
	ERROR = 3,
}

interface Props {
	canvasTreeStore: CanvasTreeStore;
	node: CanvasBarcodeReader;
	live: boolean;
	currentModuleId: number | undefined;
	rootDataTestId: string;
}

function BarcodeReader({
	rootDataTestId,
	canvasTreeStore,
	node,
	live,
	currentModuleId,
}: Props) {
	const [currentBarcodeData, setCurrentBarcodeData] = useState("");
	const [capturing, setCapturing] = useState(false);
	const [currentScreenshot, setCurrentScreenshot] = useState("");
	const [submitStatus, setSubmitStatus] = useState(SubmitStatus.IDLE);

	const rootRef = useRef<HTMLDivElement>(null);
	const webcamRef = useRef<Webcam>(null);

	const codeReader = useRef<BrowserMultiFormatReader | null>(null);
	const captureTimer = useRef<NodeJS.Timer | null>(null);

	async function captureScreenshot() {
		const imageSrc = webcamRef?.current?.getScreenshot();

		if (imageSrc) {
			setCurrentScreenshot(imageSrc);

			try {
				const result = await codeReader.current?.decodeFromImage(
					undefined,
					imageSrc
				);

				setCurrentBarcodeData(result?.getText() ?? "");
				setCapturing(false);
			} catch (error) {
				setCurrentScreenshot("");
			}
		}
	}

	useEffect(() => {
		codeReader.current = new BrowserMultiFormatReader();
	}, []);

	useEffect(() => {
		setCapturing(live);

		if (!live) {
			clearCurrentBarcodeData();
		}
	}, [live]);

	useEffect(() => {
		if (!capturing) {
			if (captureTimer.current) {
				clearInterval(captureTimer.current);
			}

			return;
		}

		captureTimer.current = setInterval(captureScreenshot, 300);
	}, [capturing]);

	async function submit() {
		try {
			setSubmitStatus(SubmitStatus.IDLE);

			const urlParams = new URLSearchParams(window.location.search);
			const remoteModuleId = urlParams.get("remote_moduleid");

			canvasTreeStore.updateNodeAction(node.id, {
				value: currentBarcodeData,
			});

			await submitData(
				canvasTreeStore,
				node,
				remoteModuleId ?? currentModuleId
			);

			canvasTreeStore.canvasTreeRequestErrorsState.delete(node.id);

			setSubmitStatus(SubmitStatus.SUCCESS);
		} catch (error) {
			canvasTreeStore.canvasTreeRequestErrorsState.set(
				node.id,
				String(error)
			);
			setSubmitStatus(SubmitStatus.ERROR);
		}
	}

	function clearCurrentBarcodeData() {
		setCurrentBarcodeData("");
		setCurrentScreenshot("");
		setCapturing(true);
		setSubmitStatus(SubmitStatus.IDLE);
	}

	const rectWidth = rootRef.current?.offsetWidth ?? 1;
	const rectHeight = rootRef.current?.offsetHeight ?? 1;

	const calculatedHeight = rectHeight - 80;

	const ratio = rectWidth / calculatedHeight;

	let pictureWidth: number, pictureHeight: number;
	if (ratio > 1) {
		pictureHeight = calculatedHeight;
		pictureWidth = pictureHeight * ratio;
	} else {
		pictureWidth = rectWidth;
		pictureHeight = pictureWidth * ratio;
	}

	function renderLiveMode() {
		if (capturing) {
			return (
				<Webcam
					audio={false}
					width={pictureWidth}
					height={pictureHeight}
					ref={webcamRef}
					screenshotFormat="image/png"
					videoConstraints={{
						facingMode: "environment",
					}}
				/>
			);
		}

		return (
			<div
				style={{
					position: "relative",
					width: pictureWidth,
					height: pictureHeight,
				}}
			>
				<img
					src={currentScreenshot}
					alt="current barcode screenshot"
					className={styles.screenshot}
				/>
				<TickIcon className={styles.screenshotSuccessIcon} />
			</div>
		);
	}

	return (
		<div
			ref={rootRef}
			style={{
				width: "100%",
				height: "100%",
			}}
		>
			<div className={styles.container}>
				<div
					className={styles.cameraContainer}
					style={{ width: pictureWidth, height: pictureHeight + 2 }}
				>
					{live ? (
						renderLiveMode()
					) : (
						<div
							className={styles.editModeCameraInnerContainer}
							style={{
								width: pictureWidth,
								height: pictureHeight,
							}}
						>
							<CameraIcon className={styles.cameraIcon} />
						</div>
					)}
				</div>
				<div className={styles.liveDataContainer}>
					<input
						data-test-id={`${rootDataTestId}-input`}
						type="text"
						disabled
						className={styles.liveDataInput}
						value={currentBarcodeData}
					/>
				</div>
				<div className={styles.buttonsContainer}>
					{submitStatus === SubmitStatus.SUCCESS ? (
						<button
							data-test-id={`${rootDataTestId}-captureAnotherButton`}
							type="button"
							onClick={clearCurrentBarcodeData}
							className={styles.button}
						>
							CAPTURE ANOTHER
						</button>
					) : (
						<>
							<button
								data-test-id={`${rootDataTestId}-submitButton`}
								type="button"
								onClick={clearCurrentBarcodeData}
								disabled={!currentBarcodeData}
								className={cx(
									styles.button,
									styles.cancelButton
								)}
							>
								CLEAR
							</button>
							<button
								data-test-id={`${rootDataTestId}-clearButton`}
								type="button"
								onClick={submit}
								disabled={!currentBarcodeData}
								className={cx(
									styles.button,
									styles.submitButton
								)}
							>
								SUBMIT
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default BarcodeReader;
