import React, { useEffect, useRef, useCallback } from "react";
import cx from "classnames";

import styles from "./DataSyncArrow.module.css";

interface Props {
	pull?: boolean;
	push?: boolean;
	active?: boolean;
}

function DataSyncArrow({ pull, push, active = false }: Props) {
	const arrowRef = useRef<HTMLSpanElement>(null);

	const animationTeardown = useCallback(() => {
		arrowRef.current?.classList.remove(styles.active);
		arrowRef.current?.removeEventListener("animationiteration", animationTeardown);
	}, []);

	useEffect(() => {
		if (active) {
			arrowRef.current?.classList.add(styles.active);
			return;
		}

		if (!active) {
			arrowRef.current?.addEventListener("animationiteration", animationTeardown);
		}
	}, [active, animationTeardown]);

	return (
		<span
			ref={arrowRef}
			className={cx(styles.arrow, { [styles.inverted]: push })}
		/>
	);
}

export default DataSyncArrow;
