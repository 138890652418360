import Portal from "common/Portal";
import React, { useContext } from "react";

import { useLayoutEffect, useState } from "react";

//This is the method I use for detecting actual  mouse position
export function useMousePosition() {
  const [position, setPosition] = useState({
    x: 0,
    y: 0
  });
  useLayoutEffect(() => {
    function updatePosition(e) {
      if (e?.target?.classList?.[0] === "recharts-rectangle") {
        setPosition({ x: e.clientX, y: e.clientY });
      }
    }
    document.addEventListener("mousemove", updatePosition);
    return () => document.removeEventListener("mousemove", updatePosition);
  }, []);

  return position;
}

const TooltipContext = React.createContext();

//Function for all those little tooltip context consumers out there
export function useTooltipContext() {
  const tooltipContext = useContext(TooltipContext);
  return tooltipContext;
}

//Tooltip provider - place somewhere in your app with access to full window
//width. You can use the same thing for all of your tooltips, and the callback
//functions openTooltip and closeTooltip will modify it's state
export default function CustomTooltip({ children }) {
  const emptyTooltip = {
    open: false,
    content: null,
    style: null
  };
  const [tooltip, setTooltip] = React.useState(emptyTooltip);

  const openTooltip = ({ content, style }) => {
    setTooltip({
      open: true,
      content: content,
      style: style
    });
  };

  const closeTooltip = () => {
    setTooltip(emptyTooltip);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Tooltip tooltip={tooltip} />
      <TooltipProvider openTooltip={openTooltip} closeTooltip={closeTooltip}>
        {children}
      </TooltipProvider>
    </div>
  );
}

const Tooltip = ({ tooltip }) => {
  const position = useMousePosition();
  //This part is essential to the tooltip functioning properyly, but
  //I haven't thought of a way to stop listening to useMousePosition
  //when the tooltip is closed
  const left = tooltip.open ? position.x : -9999;
  const top = tooltip.open ? position.y : -9999;
  return (
    <Portal rootNode={document.body}>
      <div
        style={{
          position: "absolute",
          left: left + 5,
          top: top + 5,
          zIndex: 9999,
          ...tooltip.style
        }}
      >
        {tooltip.content}
      </div>
    </Portal>
  );
};

//Used in the CustomTooltip wrapper above
function TooltipProvider({ children, openTooltip, closeTooltip }) {
  const tooltipContext = React.useMemo(() => {
    return {
      openTooltip: openTooltip,
      closeTooltip: closeTooltip
    };
  }, [openTooltip, closeTooltip]);

  return (
    <TooltipContext.Provider value={tooltipContext}>
      {children}
    </TooltipContext.Provider>
  );
}
