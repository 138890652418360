import React from "react";
import BubbleD3 from "common/graphics/v2/D3Charts/BubbleD3";
import { BubbleFinding } from "common/Finding";

interface Props {
    finding: BubbleFinding;
    onNewFinding?: (finding: BubbleFinding, updateData?: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
    width: number;
    height: number;
    scale: number;
    selected: boolean;
}

function MainComponent(props: Props) {
    let finding = props.finding;
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <BubbleD3
                editable={props.editable}
                selected={props.selected}
                preview={props.preview}
                config={finding.config}
                columnDragActive={props.columnDragActive}
                content={finding.content}
                trendlineInfo={finding.content.trendlineInfo}
                onChangeData={
                    props.onNewFinding
                        ? (data: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.content.data = data;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeConfig={
                    props.onNewFinding
                        ? (config: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.config = config;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeContent={
                    props.onNewFinding
                        ? (content: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.content = content;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                width={props.width}
                height={props.height}
                scale={props.scale}
            />
        </div>
    );
}

export { MainComponent };
