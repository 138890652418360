import React from "react";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import cx from "classnames";

import styles from "./DropdownButton.module.css";

interface Props {
	id: string
	children: React.ReactNode
	className?: string
}

function DropdownButton({ id, children, className }: Props) {
	const buttonClasses = {
		[styles.root]: true
	};

	if (className) {
		buttonClasses[className] = true;
	}

	return (
		<BootstrapDropdown.Toggle id={id} className={cx(buttonClasses)}>
			{ children }
		</BootstrapDropdown.Toggle>
	);
}

export default DropdownButton;
