import React, { Component } from "react";
import { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button } from "react-bootstrap";

import axios from "common/ServerConnection";
import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";
import FieldEditor, { Field } from "../../common/FieldEditor";
import customSelectStyles from "common/SelectStyles";

interface ColorScheme {
    label: string;
    value: string | null;
}

interface State {
    updateColorSchemeStatus: UpdateStatus;
    updateColorSchemeMessage: string;
    loadColorSchemeStatus: UpdateStatus;
    loadColorSchemeMessage: string;
    uploadColorSchemasStatus: UpdateStatus;
    uploadColorSchemasErrorMessage: string;
    value: Field[];
    selectedColorSchema: ColorScheme | null;
    newColorSchema: ColorScheme | null;
    colorSchemas: ColorScheme[];
}

function parseCssVars(css: string): {[key: string]: string} {
    var matches: RegExpMatchArray | null = css.match(/(--)\w.+;/gi);
    var cssVars: {[key: string]: string} = {};

    //Get all CSS Variables in the document
    for (let match in matches) {
        var property = matches[Number(match)];
        //split the Variable name from its value
        let splitprop = property.split(":");

        //turn the value into a string
        let value = splitprop[1].trim().toString();

        cssVars[splitprop[0]] = value.slice(0, -1); //remove ;
    }
    return cssVars;
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            updateColorSchemeStatus: UpdateStatus.NotUploaded,
            updateColorSchemeMessage: "",
            loadColorSchemeStatus: UpdateStatus.NotUploaded,
            loadColorSchemeMessage: "",
            uploadColorSchemasStatus: UpdateStatus.NotUploaded,
            uploadColorSchemasErrorMessage: "",
            value: [],
            selectedColorSchema: null,
            newColorSchema: null,
            colorSchemas: []
        };
        this.performance = null;
    }

    componentDidMount() {
        this.getColorSchemas();
        this.loadColorScheme();
    }

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs: number = new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    private buildColorSchemasSelector(): JSX.Element {
        return (
            <div className="flex-simple-column">
                <div className="my-row" style={{ marginTop: "5px" }}>
                    <CreatableSelect
                        isClearable={false}
                        placeholder={"Select or create color theme"}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                height: "38px",
                                width: "250px",
                            }),
                        }}
                        options={
                            this.state.newColorSchema
                                ? this.state.colorSchemas.concat(
                                      this.state.newColorSchema
                                  )
                                : this.state.colorSchemas
                        }
                        value={this.state.selectedColorSchema}
                        onChange={(newValue) => {
                            this.setState({
                                selectedColorSchema: newValue as ColorScheme
                            });
                        }}
                        onCreateOption={(option) => {
                            let newColorSchema = {
                                label: option,
                                value: null,
                            };
                            this.setState({
                                newColorSchema: newColorSchema as ColorScheme,
                                selectedColorSchema: newColorSchema as ColorScheme
                            });
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25: "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                    {this.state.selectedColorSchema && (
                        <>
                            <Button
                                disabled={
                                    this.state.selectedColorSchema.value ==
                                    null
                                }
                                type="button"
                                style={{ marginLeft: "5px" }}
                                className="btn btn-sm btn-primary my-primary"
                                onClick={() => {
                                    this.loadColorScheme(
                                        this.state.selectedColorSchema?.value
                                    );
                                }}
                            >
                                Load
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{ marginLeft: "5px" }}
                                onClick={() => {
                                    this.saveColorScheme();
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                disabled={
                                    this.state.selectedColorSchema.value ==
                                    null
                                }
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{ marginLeft: "5px" }}
                                onClick={() => {
                                    this.deleteColorScheme();
                                }}
                            >
                                Delete
                            </Button>
                        </>
                    )}
                </div>
                <UpdateStatusAlert
                    value={this.state.loadColorSchemeStatus}
                    onChange={(status: UpdateStatus) => this.setState({loadColorSchemeStatus: status})}
                    errorMessage={this.state.loadColorSchemeMessage}
                />
            </div>
        );
    }

    private updateColorScheme(): void {
        this.performance = null;
        let localPerformance = new Date();
        this.setState({
            updateColorSchemeStatus: UpdateStatus.Loading,
            updateColorSchemeMessage: "",
        });
        let css = " :root {\n";
        this.state.value.forEach((cssProp) => {
            css = css.concat(` ${cssProp.name}: ${cssProp.value};\n`);
        });
        css = css.concat("}");
        axios
            .post<{
                success: boolean,
                error_msg: string
            }>("/api/update_color_scheme", css)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        updateColorSchemeStatus: UpdateStatus.Success,
                    });
                    Instrumentation.addInteraction(
                        "Settings",
                        new Date().getTime() - localPerformance.getTime()
                    ).then(() => {
                        document.location.reload();
                    });
                } else {
                    this.performance = localPerformance;
                    console.log(response.data.error_msg);
                    this.setState({
                        updateColorSchemeStatus: UpdateStatus.Error,
                        updateColorSchemeMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                this.performance = localPerformance;
                console.log(error);
                this.setState({
                    updateColorSchemeStatus: UpdateStatus.Error
                });
            });
    }

    private loadColorScheme(path: string | null | undefined = undefined): void {
        let pathForFetch = path ?? "/dist/css/Constants.css";
        this.setState({
            loadColorSchemeStatus: UpdateStatus.Loading,
            loadColorSchemeMessage: ""
        });
        axios
            .get<string>(`${pathForFetch}?ts=`.concat(Date.now().toString()))
            .then((response) => {
                let colorSchemeFields: Field[] = [];
                let cssVars = parseCssVars(response.data);
                Object.keys(cssVars).forEach((key) => {
                    let colorSchemeField = {
                        name: key,
                        value: cssVars[key],
                        label: key,
                        editable: true,
                        hidden: false,
                    };
                    colorSchemeFields.push(colorSchemeField);
                });
                this.setState({
                    value: colorSchemeFields,
                    loadColorSchemeStatus: UpdateStatus.Success,
                });
            })
            .catch((error) => {
                this.setState({
                    loadColorSchemeStatus: UpdateStatus.Error,
                });
                console.log(error);
            });
    }

    private saveColorScheme(): void {
        this.performance = null;
        let localPerformance = new Date();
        this.setState({
            loadColorSchemeStatus: UpdateStatus.Loading,
            loadColorSchemeMessage: "",
        });
        let css = " :root {\n";
        this.state.value.forEach((cssProp) => {
            css = css.concat(` ${cssProp.name}: ${cssProp.value};\n`);
        });
        css = css.concat("}");

        let formData = new FormData();
        formData.append("content", css);
        formData.append(
            "metadata",
            JSON.stringify({
                name: this.state.selectedColorSchema?.label,
            })
        );
        axios
            .post<{
                success: boolean,
                error_msg: string
            }>("/api/save_color_scheme", formData)
            .then((response) => {
                if (response.data.success) {
                    this.setState(
                        {
                            loadColorSchemeStatus: UpdateStatus.Success,
                        },
                        () => this.getColorSchemas()
                    );
                    Instrumentation.addInteraction(
                        "Settings",
                        new Date().getTime() - localPerformance.getTime()
                    );
                } else {
                    this.performance = localPerformance;
                    console.log(response.data.error_msg);
                    this.setState({
                        loadColorSchemeStatus: UpdateStatus.Error,
                        loadColorSchemeMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                this.performance = localPerformance;
                console.log(error);
                this.setState({
                    loadColorSchemeStatus: UpdateStatus.Error,
                });
            });
    }

    private deleteColorScheme(): void {
        this.performance = null;
        let localPerformance = new Date();
        this.setState({
            loadColorSchemeStatus: UpdateStatus.Loading,
            loadColorSchemeMessage: ""
        });
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>(
                "/api/delete_color_scheme",
                { name: this.state.selectedColorSchema?.label }
            )
            .then((response) => {
                if (response.data.success) {
                    this.setState(
                        {
                            loadColorSchemeStatus: UpdateStatus.Success,
                        },
                        () => this.getColorSchemas()
                    );
                    Instrumentation.addInteraction(
                        "Settings",
                        new Date().getTime() - localPerformance.getTime()
                    );
                } else {
                    this.performance = localPerformance;
                    console.log(response.data.error_msg);
                    this.setState({
                        loadColorSchemeStatus: UpdateStatus.Error,
                        loadColorSchemeMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                this.performance = localPerformance;
                console.log(error);
                this.setState({
                    loadColorSchemeStatus: UpdateStatus.Error,
                });
            });
    }

    private getColorSchemas(): void {
        axios
            .post<{
                success: boolean,
                error_msg: string,
                schemas: Array<{name: string, path: string}>
            }>("/api/get_color_schemas", {})
            .then((response) => {
                if (response.data.success) {
                    let colorSchemas = response.data.schemas.map(
                        (schema) => ({
                            label: schema.name,
                            value: schema.path,
                        })
                    );
                    let newSelectedColorSchema = null;
                    if (this.state.selectedColorSchema) {
                        let selectedColorSchema = colorSchemas.filter(
                            (item) =>
                                item.label ===
                                this.state.selectedColorSchema?.label
                        );
                        if (selectedColorSchema.length > 0)
                            newSelectedColorSchema = selectedColorSchema[0];
                    }
                    this.setState({
                        selectedColorSchema: newSelectedColorSchema,
                        newColorSchema: null,
                        colorSchemas: response.data.schemas.map(
                            (schema) => ({
                                label: schema.name,
                                value: schema.path,
                            })
                        ),
                    });
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private resetColorScheme(): void {
        this.performance = null;
        let localPerformance: Date = new Date();
        axios
            .post<{
                success: boolean,
                error_msg: string
            }>("/api/reset_color_scheme", {})
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        updateColorSchemeStatus: UpdateStatus.Success,
                    });
                    Instrumentation.addInteraction(
                        "Settings",
                        new Date().getTime() - localPerformance.getTime()
                    ).then(() => {
                        document.location.reload();
                    });
                } else {
                    this.performance = localPerformance;
                    console.log(response.data.error_msg);
                    this.setState({
                        updateColorSchemeStatus: UpdateStatus.Error,
                        updateColorSchemeMessage: response.data.error_msg
                    });
                }
            })
            .catch((error) => {
                this.performance = localPerformance;
                console.log(error);
                this.setState({
                    updateColorSchemeStatus: UpdateStatus.Error,
                });
            });
    }

    render() {
        return (
            <>
                <FieldEditor
                    title="Color Theme"
                    value={this.state.value}
                    onChange={(value) => this.setState({value: value})}
                    onUpdate={() => this.updateColorScheme()}
                    onReset={() => this.resetColorScheme()}

                    beforeInputs={this.buildColorSchemasSelector()}

                    status={this.state.updateColorSchemeStatus}
                    onStatusChange={(status: UpdateStatus) => this.setState({updateColorSchemeStatus: status})}
                    errorMessage={this.state.updateColorSchemeMessage}
                />
                <div style={{ marginTop: 20 }}>
                    <span className="big-title-span">
                        Save and Restore Color Themes
                    </span>
                    <div
                        style={{ marginTop: 10, width: 500 }}
                        className="flex-simple-column"
                    >
                        <label
                            htmlFor="file-upload-color-schemas"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginLeft: "19px",
                                marginBottom: 10,
                                width: 200,
                            }}
                        >
                            Load
                        </label>
                        <input
                            id="file-upload-color-schemas"
                            type="file"
                            accept=".zip"
                            onChange={(evt) => {
                                if (evt.target.files != null && evt.target.files[0]) {
                                    let localPerformance = new Date();
                                    this.setState({
                                        uploadColorSchemasStatus:
                                            UpdateStatus.Loading,
                                    });
                                    var formData = new FormData();
                                    formData.append(
                                        "color_schemas",
                                        evt.target.files[0]
                                    );
                                    axios
                                        .post<{
                                            success: boolean;
                                            error_msg: string;
                                        }>(
                                            "/api/import_color_schemas",
                                            formData,
                                            {
                                                headers: {
                                                    "Content-Type":
                                                        "multipart/form-data",
                                                },
                                            }
                                        )
                                        .then((response) => {
                                            this.performance = localPerformance;
                                            if (
                                                response.data
                                                    .success
                                            ) {
                                                this.setState({
                                                    uploadColorSchemasStatus:
                                                        UpdateStatus.Success,
                                                });
                                                this.getColorSchemas();
                                            } else {
                                                this.setState({
                                                    uploadColorSchemasErrorMessage:
                                                        response
                                                            .data
                                                            .error_msg,
                                                    uploadColorSchemasStatus:
                                                        UpdateStatus.Error,
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            this.performance = localPerformance;
                                            this.setState({
                                                uploadColorSchemasErrorMessage:
                                                    `${error.response.status} ${error.response.statusText}`,
                                                uploadColorSchemasStatus: UpdateStatus.Error
                                            });
                                        });
                                }
                            }}
                            style={{
                                display: "none",
                            }}
                        />
                        <form
                            action="/api/export_color_schemas"
                            method="GET"
                        >
                            <Button
                                type="submit"
                                className="btn btn-lg btn-primary my-primary"
                                style={{
                                    marginLeft: "19px",
                                    marginBottom: 10,
                                    width: 200,
                                }}
                            >
                                Save
                            </Button>
                        </form>
                    </div>
                    <UpdateStatusAlert
                        value={this.state.uploadColorSchemasStatus}
                        onChange={(status: UpdateStatus) => this.setState({uploadColorSchemasStatus: status})}
                        errorMessage={this.state.uploadColorSchemasErrorMessage}
                    />
                </div>
            </>
        );
    }
}

export { MainComponent };
export let requirePermission = "ManageColorThemes";
