import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";
import Variables from "common/Variables";
import { isSklearn } from "common/Regressions";

class Api {
	static getFinding(props, findingOptions) {
		let { regressionResult, config } = props;
		if (!findingOptions) return Api.getAbstractFinding(props);
		let item = {
			type: findingOptions.type,
			content: {
				regressionResult: regressionResult,
			},
			config: config,
		};
		return item;
	}

	static getAbstractFinding(props) {
		let { regressionResult, config } = props;
		let item = {
			content: {
				regressionResult: regressionResult,
			},
			config: config,
		};
		return item;
	}

	static getData(config, findingOptions, moduleId) {
		let {
			confidenceLevel,
			datasetFraction,
			dataScope,
			conditions,
			selectedTable,
			regressionInfo,
			selectedDependentVariable,
			additionalParameter,
			leftAdditionalOperator,
			rightAdditionalOperator,
			leftAdditionalValue,
			rightAdditionalValue,
		} = config;
		conditions = conditions.filter(
			(condition) =>
				condition.variable && condition.value && condition.operation
		);
		conditions = conditionsToJson(conditions);
		let [regressionDict, , , , ,] = regressionInfo;
		let newRegressionDict = Object.assign({}, regressionDict);
		newRegressionDict.table = selectedTable.value;
		newRegressionDict.data_table_idx =
			typeof dataScope === "undefined" ? 0 : dataScope.value;
		newRegressionDict.condition_id = selectedTable.condition_id;
		newRegressionDict.conditions = conditions;
		newRegressionDict.predict_split = {
			train_size: datasetFraction / 100,
			confidence_level: confidenceLevel / 100,
		};
		let whereDict = undefined;
		if (
			additionalParameter &&
			(leftAdditionalValue || rightAdditionalValue)
		) {
			whereDict = {};
			newRegressionDict.where = {};

			newRegressionDict.where.group = additionalParameter;
			whereDict.group = additionalParameter;

			newRegressionDict.where.expressions = [];
			whereDict.expressions = [];

			if (leftAdditionalValue) {
				newRegressionDict.where.expressions.push({
					operation: leftAdditionalOperator?.value ?? "=",
					value: leftAdditionalValue.value,
				});
				whereDict.expressions.push({
					operation: leftAdditionalOperator ?? {
						label: "=",
						value: "=",
					},
					value: leftAdditionalValue,
				});
			}
			if (rightAdditionalValue) {
				newRegressionDict.where.expressions.push({
					operation: rightAdditionalOperator?.value ?? "=",
					value: rightAdditionalValue.value,
				});
				whereDict.expressions.push({
					operation: rightAdditionalOperator ?? {
						label: "=",
						value: "=",
					},
					value: rightAdditionalValue,
				});
			}
		}

		let url = "/api/e/get_custom_hold_out_regression";
		if (isSklearn(newRegressionDict.model.name)) {
			url = "/api/e/get_custom_sklearn_hold_out_regression";
		}
		return axios
			.post(url, newRegressionDict, null)
			.then((response) => {
				if (response.data.success === true) {
					var predictions = response.data.predictions;
					let regressionResult = {
						where: whereDict,
						dependentVariable: selectedDependentVariable,
						independentVariable: newRegressionDict.iv.main,
						iv_other_names: response.data.iv_other_names.flat(),
						iv_main_names: response.data.iv_main_names,
						main_fixed: newRegressionDict.iv.main_fixed,
					};
					let predictedBars = predictions.map((item, index) => ({
						confidence_level: confidenceLevel,
						show_intervals: true,
						sample_size: item["dv"].sample_size,
						mean_predicted: item["dv"].mean_predicted,
						mean_iv: item["dv"].mean_iv,
						model_id: item["dv"].model_id,
						confidence_interval: item["dv"]["confidence_interval"],
						mean_true: item["dv"].mean_true?.[0],
						units: Variables(
							newRegressionDict.data_table_idx,
							moduleId
						).getUnits(selectedDependentVariable),
						name:
							item.where == null
								? "overall"
								: `${item.where.group} ${item.where.operation} ${item.where.value}`,
					}));
					regressionResult.predictedBars = predictedBars;
					let finding = Api.getFinding(
						{
							regressionResult: regressionResult,
							config: config,
						},
						findingOptions
					);
					return Promise.resolve(finding);
				} else {
					return Promise.reject(response.data.error_msg);
				}
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	}
}

export { Api };