import { mainStyle } from "common/MainStyle";
import { colorList } from "./LineColors";
import {
	RGBAToHexA,
	hexToRGBComponents,
	RGBAStrToRGBAComponents,
} from "common/utilities/ColorUtils";

export const colors = ["#FFAB4F", "#05C985", "#39F", "#EE423D", "#808080"].concat(
	colorList
);

export enum BarChartTheme {
	Default = 0,
	Monochrome = 1,
	Scientific = 2
}

export function getDefaultColorsByTheme(
	theme: BarChartTheme | undefined
): {
	axesColor: string;
	barColors: string[];
	gridColor: string;
	baseBackgroundColor: string;
	axesLinesColor: string;
} {
	switch (theme) {
		case BarChartTheme.Default: {
			return {
				axesColor: mainStyle
					.getPropertyValue("--graphs-axes-text-color")
					.trim(),
				barColors: colors,
				gridColor: mainStyle.getPropertyValue("--graphs-stroke-color"),
				baseBackgroundColor: "#FFFFFF",
				axesLinesColor: mainStyle.getPropertyValue(
					"--graphs-stroke-color"
				),
			};
		}
		case BarChartTheme.Monochrome: {
			return {
				axesColor: "#FFFFFF",
				barColors: ["#FFFFFF"],
				gridColor: "#FFFFFF",
				baseBackgroundColor: "#26c6da",
				axesLinesColor: mainStyle.getPropertyValue(
					"--graphs-stroke-color"
				),
			};
		}
		case BarChartTheme.Scientific: {
			return {
				axesColor: "#000000",
				barColors: colors,
				gridColor: "#FFFFFF",
				baseBackgroundColor: "#EBEBEB",
				axesLinesColor: mainStyle.getPropertyValue(
					"--graphs-stroke-color"
				),
			};
		}
	}
	return {
		axesColor: mainStyle
			.getPropertyValue("--graphs-axes-text-color")
			.trim(),
		barColors: colors,
		gridColor: mainStyle.getPropertyValue("--graphs-stroke-color"),
		baseBackgroundColor: "#FFF",
		axesLinesColor: mainStyle.getPropertyValue("--graphs-stroke-color"),
	};
}

function getMonochromeStyle(backgroundColor: string) {
	let fillColor = backgroundColor;
	let colorComponents = [];
	if (fillColor === "transparent") {
		colorComponents = [0, 0, 0, 0];
	} else if (fillColor.startsWith("#")) {
		colorComponents = hexToRGBComponents(fillColor);
	} else {
		colorComponents = RGBAStrToRGBAComponents(fillColor);
	}
	let originalColor = RGBAToHexA(
		colorComponents[0],
		colorComponents[1],
		colorComponents[2],
		1
	);
	let opacityColor = RGBAToHexA(
		Math.max(0, colorComponents[0] - 25),
		Math.max(0, colorComponents[1] - 25),
		Math.max(0, colorComponents[2] - 25),
		1
	);
	let containerStyle: React.CSSProperties = {
		background: `linear-gradient(60deg, ${originalColor}, ${opacityColor})`,
	};
	return containerStyle;
}

export function getContainerStyleByTheme(
	theme: BarChartTheme | undefined,
	backgroundColor?: string
): React.CSSProperties {
	if (backgroundColor == null) return {};
	switch (theme) {
		case BarChartTheme.Default:
			return {
				background: backgroundColor,
			};
		case BarChartTheme.Monochrome: {
			return getMonochromeStyle(backgroundColor);
		}
		case BarChartTheme.Scientific:
			return {};
	}
	return {};
}

export function getGridColorByTheme(
	theme: BarChartTheme | undefined,
	backgroundColor?: string
): string | undefined {
	if (backgroundColor == null) return undefined;
	switch (theme) {
		case BarChartTheme.Default:
			return backgroundColor;
		case BarChartTheme.Monochrome:
			return undefined;
		case BarChartTheme.Scientific:
			return backgroundColor;
	}
	return undefined;
}

export function getOptionsByTheme(
	theme: BarChartTheme | undefined
): {
	showAxesLines: boolean;
	showTickLines: boolean;
	dashGrid: boolean;
} {
	switch (theme) {
		case BarChartTheme.Default:
			return {
				dashGrid: true,
				showAxesLines: true,
				showTickLines: false,
			};
		case BarChartTheme.Monochrome:
			return {
				dashGrid: true,
				showAxesLines: false,
				showTickLines: false,
			};
		case BarChartTheme.Scientific:
			return {
				dashGrid: false,
				showAxesLines: false,
				showTickLines: true,
			};
	}
	return { showAxesLines: true, showTickLines: false, dashGrid: true };
}
