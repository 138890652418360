import React, { ReactElement, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import Tables, { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import styles from "./CreateVariablesSection.module.css";
import DataScopeAndTableSelectorView from "common/DataScopeAndTableSelectorView";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import Switch from "react-switch";
import Variables from "common/Variables";
import VariableCreator, { NewVariable } from "common/VariableCreator";
import { Panel, Type } from "common/InputData";
import {
    variableCreatorRowLevelSample,
    variableCreatorSample,
} from "common/NotebookSample";
import ReactTooltip from "react-tooltip";
import processTab from "common/utilities/processTab";
import { addVariable } from "common/DataApi";
import { preview, PreviewHeader } from "../MergeSection";
import EditVariablesSection from "../EditVariablesSection";
import OutsideAlerter from "common/OutsideAlerter";
import SelectedOption from "../SelectedOption";
import TabSwitchConfirmationPopup from "common/TabSwitchConfirmationPopup";
import { SwitchButtons } from "../PivotSection";
import DragAndDropStates from "common/DragAndDropStates";
import { useDrop } from "react-dnd";
import Select, { GroupBase, SingleValueProps, components } from "react-select";
import { leftAlignAndScrollBarCSS } from "common/SelectStyles";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import { ConditionsSelector } from "../CleanSection/conditionSelector";
import { Condition, NodeLinkOption } from "common/Conditions";
import StringOption from "common/StringOption";
import { ReactComponent as CategoriesScrollArrow } from "icons/categories-scroll-arrow.svg";
import { checkScrollPosition, gatherScrollData, ScrollInfo, useHorizontalScroll } from "common/utilities/scrollUtils";
import { ReactComponent as addIcon } from "../../../icons/data_hub/add.svg";
import { ReactComponent as productIcon } from "../../../icons/data_hub/product.svg";
import { ReactComponent as muIcon } from "../../../icons/data_hub/mu.svg";
import { ReactComponent as tildeIcon } from "../../../icons/data_hub/tilde.svg";
import { ReactComponent as aIcon } from "../../../icons/data_hub/capital_a.svg";
import { ReactComponent as calenderIcon } from "../../../icons/data_hub/calender.svg";
import { ReactComponent as globeIcon } from "../../../icons/data_hub/globe.svg";
import { ReactComponent as funcIcon } from "../../../icons/data_hub/function.svg";
import { ReactComponent as minusIcon } from "../../../icons/data_hub/minus.svg";
import { ReactComponent as divisionIcon } from "../../../icons/data_hub/division.svg";

export const methodOptions: ReadonlyArray<"GUI" | "Python"> = ["GUI", "Python"];
export const rowOptions: ReadonlyArray<"All Rows" | "Filter Rows"> = [
    "All Rows",
    "Filter Rows",
];
  
const arithmeticOperators: Array<{ label: string; value: string; OperationIcon: React.FC }> = [
    { label: "+ Addition", value: "+", OperationIcon: addIcon },
    { label: "- Subtract", value: "-", OperationIcon: minusIcon },
    { label: "x Multiply", value: "x", OperationIcon: productIcon },
    { label: "/ Divide", value: "/", OperationIcon: divisionIcon },
];

const assignRandom: Array<{ label: string; value: string }> = [
    {
        label: `Randomly draw from`,
        value: `Randomly draw from`,
    },
    {
        label: `Repeat values`,
        value: `Repeat values`,
    },
    {
        label: `Sequence dates`,
        value: `Sequence dates`,
    },
];

const randomGenerator: Array<{ label: string; value: string }> = [
    {
        label: "Randomly draw from set",
        value: "Randomly draw from set",
    },
    {
        label: "Randomly draw from sequence",
        value: "Randomly draw from sequence",
    },
    {
        label: "Draw from Uniform dist.",
        value: "Draw from Uniform dist.",
    },
    {
        label: "Draw from Normal dist.",
        value: "Draw from Normal dist.",
    },
];

const logicalOperators: Array<{ label: string; value: string }> = [
    { label: "average", value: "mean" },
    { label: "rank", value: "" },
    { label: "min", value: "min" },
    { label: "max", value: "max" },
    { label: "sum", value: "sum" },
    { label: "std", value: "" },
    { label: "count", value: "count" },
];
const operations: ReadonlyArray<{
    Icon: React.FC;
    iconWidth: number;
    label: ReactElement;
    name: string;
    optOperator: string;
    defaultOperator: {label: string, value: string, OperationIcon: React.FC<IconProps>};
}> = [
    {
        Icon: addIcon,
        iconWidth: 36,
        label: (
            <p>
                ADD TWO <br /> COLUMNS
            </p>
        ),
        name: "Add two Columns",
        optOperator: arithmeticOperators[0].label,
        defaultOperator: arithmeticOperators[0],
    },
    {
        Icon: productIcon,
        iconWidth: 36,
        label: (
            <p>
                MULTIPLY A <br /> COLUMN
            </p>
        ),
        name: "Airthmetic operation",
        optOperator: arithmeticOperators[2].label,
        defaultOperator: arithmeticOperators[2],
    },
    {
        Icon: muIcon,
        iconWidth: 36,
        label: (
            <p>
                AVERAGE A <br /> COLUMN
            </p>
        ),
        name: "Logical Operatoions",
        optOperator: "average",
        defaultOperator: { label: "", value: "",  OperationIcon: () => (<></>)},
    },
    {
        Icon: tildeIcon,
        iconWidth: 40,
        label: (
            <p>
                RANDOM # <br /> GENERATOR
            </p>
        ),
        name: "Random Generator",
        optOperator: "Randomly draw from set",
        defaultOperator: { label: "", value: "",  OperationIcon: () => (<></>)},
    },
    {
        Icon: aIcon,
        iconWidth: 25,
        label: (
            <p>
                ASSIGN TEXT <br /> TO A COLUMN
            </p>
        ),
        name: "Add text column",
        optOperator: "Randomly draw from",
        defaultOperator: { label: "", value: "",  OperationIcon: () => (<></>)},
    },
    {
        Icon: calenderIcon,
        iconWidth: 36,
        label: (
            <p>
                ASSIGN DATES <br /> TO A COLUMN
            </p>
        ),
        name: "Add date column",
        optOperator: "Randomly draw from",
        defaultOperator: { label: "", value: "",  OperationIcon: () => (<></>)},
    },
    {
        Icon: globeIcon,
        iconWidth: 36,
        label: (
            <p>
                ASSIGN ZIPCODES
            </p>
        ),
        name: "ZipCode",
        optOperator: "Randomly draw from",
        defaultOperator: { label: "", value: "",  OperationIcon: () => (<></>)},
    },
    {
        Icon: funcIcon,
        iconWidth: 36,
        label: <p>DO YOUR OWN</p>,
        name: "custom function",
        optOperator: "",
        defaultOperator: { label: "", value: "",  OperationIcon: () => (<></>)},
    },
];

interface DroppedVariable {
    header: PreviewHeader;
    body: Array<string | number | Date>;
}
interface VariableContainerProps {
    variable: DroppedVariable | null;
    variableNumber: number;
}

interface InputValuesProps {
    values: string;
    placeholder1: string;
    placeholder2: string;
    placeholder3: string;
}

interface IconProps {fill: string, stroke: string, width: number, height: number}

const previewHeader = [
    { id: 0, name: "Week", type: "text" },
    { id: 0, name: "Grocery Spend", type: "double" },
    { id: 0, name: "Travel Spend", type: "double" },
    { id: 0, name: "Fun Spend", type: "double" },
];

const previewBody = [
    ["1", "$100", "$200", "$300"],
    ["2", "$101", "$201", "$301"],
    ["3", "$102", "$202", "$302"],
];

function sanitizeConditions(conditions: Condition[]): Condition[] {
    let newConditions = Array.from(conditions);
    newConditions[0] = {
        ...newConditions[0],
        logical: null,
    };
    return newConditions;
}

function CreateVariableSection(props: { setOperationInProgress: (state: boolean) => void; }) {
    let variableHeder = {
        id: 0,
        name: "",
        type: Type.Str,
    };

    // (props: JSX.IntrinsicAttributes & SingleValueProps<{
    //     OperationIcon: React.FC<IconProps>,
    //     value: string,
    //     label: string   
    // }>)

    const { SingleValue, } = components;
    const IconValue = (
        props: JSX.IntrinsicAttributes & 
        SingleValueProps<{
            OperationIcon: React.FC<IconProps>,
            value: string,
            label: string,   
        }, boolean, GroupBase<{
            OperationIcon: React.FC<IconProps>,
            value: string,
            label: string,   
        }>>) => (
        <SingleValue {...props}>
            <div 
                style={{
                    display:"flex",
                    alignContent: "center",
                    alignItems: "center"
                }}
            >
                <props.data.OperationIcon
                    fill={
                            selectedColor !== 5
                            ? "var(--acceptBtn-color)"
                            : "#333"
                        }
                    stroke={
                            selectedColor !== 5
                            ? "var(--acceptBtn-color)"
                            : "#333"
                        }
                        width={36}
                        height={36}
                />
            </div>
        </SingleValue>
    );

    const executeCreateVariables = (
        table: TableOption,
        creatorCode: string,
        genericVariables: NewVariable[],
        rowLevel: boolean,
        preview: boolean
    ) => {
        genericVariables.map((item, index) => {
            if (item.level === "Percent" || item.level === "Currency") {
                genericVariables[index] = {
                    ...genericVariables[index],
                    type: Type.Str,
                    level: null,
                };
            } else if (item.level === "Number") {
                genericVariables[index] = {
                    ...genericVariables[index],
                    level: null,
                };
            } else {
                genericVariables[index] = {
                    ...genericVariables[index],
                    level: item.level,
                };
            }

            return null;
        });

        setCreating(true);
        if (preview) {
            setPreviewInfo(null);
        }
        setStatusPopup({
            status: PopupStatus.Success,
            message: "Generating data...",
        });
        addVariable(
            table!,
            creatorCode,
            genericVariables,
            async (previewData) => {
                setCreating(false);
                if (!preview) {
                    setStatusPopup({
                        status: PopupStatus.Success,
                        message: "Success",
                    });
                    setGenericVariables([
                        {
                            name: variableHeder.name,
                            unit: null,
                            type: Type.Str,
                            level: null,
                            panel: Panel.Regular,
                            format: null,
                            decimal: null,
                        },
                    ]);
                    setFinalVariable({ header: variableHeder, body: [] });
                    setPreviewInfo(null);
                    DataScopes.previewState = { headers: null, body: null };
                    await Variables(table.data_table_idx!).update();
                    await Tables(table.data_table_idx!).update();
                    DataScopes.DatasetPreview(
                        table.data_table_idx!,
                        undefined,
                        table
                    );
                } else {
                    setStatusPopup({
                        status: PopupStatus.Success,
                        message: "Success",
                    });
                    setPreviewInfo({
                        tableHeader: previewData!.tableHeader,
                        tablePreview: previewData!.tablePreview,
                    });
                }
            },
            (errorMessage: string) => {
                setCreating(false);
                setStatusPopup({
                    status: PopupStatus.Error,
                    message: errorMessage,
                });
            },
            preview,
            rowLevel,
            undefined,
            false,
            sanitizeConditions(conditions)
        );
    };

    let [dataset, setDataset] = React.useState<DataScopeOption | null>(null);
    let [table, setTable] = React.useState<TableOption | null>(null);
    let creatorTextAreaRef = React.useRef<HTMLTextAreaElement>(null);
    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    let [previewInfo, setPreviewInfo] = React.useState<{
        tableHeader: string[];
        tablePreview: (string | number | null)[][];
    } | null>(null);
    let [creating, setCreating] = React.useState<boolean>(false);
    let [genericVariables, setGenericVariables] = React.useState<NewVariable[]>(
        [
            {
                name: variableHeder.name,
                unit: null,
                type: Type.Str,
                level: null,
                panel: Panel.Regular,
                format: null,
                decimal: null,
            },
        ]
    );

    let [rowLevel, setRowLevel] = React.useState<boolean>(false);
    let [creatorCode, setCreatorCode] = React.useState<string>(
        variableCreatorSample
    );
    let [showEdit, setShowEdit] = React.useState<{
        id: number;
        name: string;
        type: string;
        Xcoord: number;
        Ycoord: number;
    } | null>(null);
    let [methodType, setMethodType] = useState<"GUI" | "Python">("GUI");
    let [variable1, setVariable1] = useState<DroppedVariable | null>(null);
    let [variable2, setVariable2] = useState<DroppedVariable | null>(null);
    let [finalVariable, setFinalVariable] = useState<DroppedVariable | null>({
        header: variableHeder,
        body: [],
    });
    let [operator, setOperator] = useState<string>(
        arithmeticOperators[0].label
    );
    let [inputState, setInputState] = useState<InputValuesProps[]>([
        {
            values: "",
            placeholder1: "Apples",
            placeholder2: "01/01/2022",
            placeholder3: "10043",
        },
        {
            values: "",
            placeholder1: "Banana",
            placeholder2: "02/01/2022",
            placeholder3: "02198",
        },
        {
            values: "",
            placeholder1: "Cup Cakes",
            placeholder2: "03/01/2022",
            placeholder3: "90210",
        },
    ]);

    let [operatorValue, setOperatorValue] = useState<{label: string, value: string, OperationIcon: React.FC<IconProps>}>({
        label: arithmeticOperators[0].label.substring(0, 1),
        value: arithmeticOperators[0].value,
        OperationIcon: arithmeticOperators[0].OperationIcon
    });

    let [secondValue, setSecondValue] = useState<string | "">("12");
    let [operationType, setOperationType] = useState<string>(
        operations[0].name
    );
    let [editSecondValue, setEditSecondValue] = useState<boolean>(false);
    let [updateValue, setUpdateValue] = useState<boolean>(false);
    // let [dropVariableType, setdropVariableType] = useState<string>("");
    let [demoPreviewHeader, setDemoPreviewHeader] = useState<
        PreviewHeader[] | []
    >(previewHeader);
    let [demoPreviewBody, setDemoPreviewBody] = useState<string[][] | []>(
        previewBody
    );
    let [toggleEdit, setToggleEdit] = useState<boolean>(true);
    const [showDateIcon, setShowDateIcon] = useState(0);
    let [valueRange, setValueRange] = useState<{
        start: string | Date;
        end: string | Date;
        steps?: number;
    }>({ start: "", end: "" });
    let [normalDistValue, setNormalDistValue] = useState<number>();
    let [inputBoxColor, setInputBoxColor] = useState<null | number>(null);
    let [conditions, setConditions] = React.useState<Condition[]>(
        ConditionsSelector.defaultValue
    );
    let [showFilter, setShowFilter] = useState<"All Rows" | "Filter Rows">(
        "All Rows"
    );
    let [dropdownValue, setDropdownValue] = useState<StringOption>();
    let [dateTypeInput, setDateTypeInput] = useState<"text" | "date">("text");
    let [selectedColor, setSelectedColor] = useState<number>(0);

    const [scrollInfo, setScrollInfo] = useState<ScrollInfo>({
        scrollWidth: 0,
        offsetWidth: 0,
        scrollLeft: 0,
        inTheBeginning: true,
        inTheEnd: false
    });
    const scrollRef = useHorizontalScroll((info: ScrollInfo) => {
        setScrollInfo(info);
    });

    const refs: React.MutableRefObject<(HTMLInputElement | null)[]> = useRef(
        []
    );

    useEffect(() => {
        return () => {
            DataScopes.previewState = { headers: null, body: null };
        };
    }, []);

    
    useEffect(() => {
        props.setOperationInProgress((dataset)? true : false);
    }, [dataset]);
    

    const makeDefault = () => {
        setGenericVariables([
            {
                name: null,
                unit: null,
                type: Type.Str,
                level: null,
                panel: Panel.Regular,
                format: null,
                decimal: null,
            },
        ]);
        setCreatorCode(variableCreatorSample);
        setPreviewInfo(null);
        setVariable1(null);
        setVariable2(null);
        setSecondValue("12");
        setDemoPreviewBody(previewBody);
        SelectedOption.UpdateaskSwitchTab(false);
        SelectedOption.UpdateSwitchTab(true);
        setFinalVariable({ header: variableHeder, body: [] });
    };

    const parseConditions = (conditions: Condition[]) => {
        var lsop = "";
        if (conditions[0].leftBracket == null && conditions[0].value == null) {
            return "";
        }
        var skipLogical = false;
        conditions.forEach((v: Condition, index) => {
            if (v) {
                skipLogical = false;
                if (index === 0 || v.leftBracket?.value) {
                    skipLogical = true;
                }
                if (v.leftBracket?.value) {
                    lsop += "(";
                }
                if (!skipLogical) {
                    lsop += ` ${v.logical?.value === "and" ? "&" : "|"} `;
                }
                let val: string | number;
                if (Array.isArray(v.value)) {
                    val = JSON.stringify(
                        v.value.map((x) => x["value"])
                    ).replaceAll('"', "'");
                } else {
                    val = v.value ? (v.value as NodeLinkOption)?.value : "";
                }
                if (
                    !Array.isArray(v.value) &&
                    !(
                        v.variable?.type != null &&
                        ["int", "float", "bool"].includes(v.variable?.type)
                    )
                ) {
                    val = `'${val}'`;
                }
                let op = v.operation?.value === "=" ? "==" : v.operation?.value;
                if (op === "in") {
                    lsop += `(data['${v.variable?.label}'].isin(${val}))`;
                } else if (op === "not in") {
                    lsop += `(~data['${v.variable?.label}'].isin(${val}))`;
                } else {
                    lsop += `(data['${v.variable?.label}'] ${op} ${val})`;
                }
                if (v.rightBracket?.value) {
                    lsop += ")";
                }
            }
        });
        return lsop;
    };

    let screen = showEdit?.Xcoord ? window.innerWidth - showEdit?.Xcoord : 500;
    let inputData = inputState.filter((item) => item.values !== "");
    let parsedConditions = parseConditions(conditions);
    let initColumn = "";
    if (parsedConditions.length !== 0) {
        initColumn = `data['${finalVariable?.header.name}'] = None \n`;
    } else {
        parsedConditions = ":";
    }

    useEffect(() => {
        let a = Math.min(variable1?.body.length!, variable2?.body.length!);
        let arr: Array<number | string | Date> = [];
        if (operationType === operations[0].name && a > 0) {
            switch (operator) {
                case arithmeticOperators[0].label:
                    for (let i = 0; i <= a; i++) {
                        arr.push(
                            (
                                Number(variable1?.body[i]) +
                                Number(variable2?.body[i])
                            ).toFixed(2)
                        );
                    }
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions}, '${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable1?.header.name}'] + data.loc[${parsedConditions}, '${variable2?.header.name}'] \n`
                    );
                    break;
                case arithmeticOperators[1].label:
                    for (let i = 0; i <= a; i++) {
                        arr.push(
                            (
                                Number(variable1?.body[i]) -
                                Number(variable2?.body[i])
                            ).toFixed(2)
                        );
                    }
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions}, '${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable1?.header.name}'] - data.loc[${parsedConditions}, '${variable2?.header.name}'] \n`
                    );
                    break;
                case arithmeticOperators[2].label:
                    for (let i = 0; i <= a; i++) {
                        arr.push(
                            (
                                Number(variable1?.body[i]) *
                                Number(variable2?.body[i])
                            ).toFixed(2)
                        );
                    }
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions}, '${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable1?.header.name}'] * data.loc[${parsedConditions}, '${variable2?.header.name}'] \n`
                    );
                    break;

                case arithmeticOperators[3].label:
                    for (let i = 0; i <= a; i++) {
                        arr.push(
                            (
                                Number(variable1?.body[i]) /
                                Number(variable2?.body[i])
                            ).toFixed(2)
                        );
                    }
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions}, '${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable1?.header.name}'] / data.loc[${parsedConditions}, '${variable2?.header.name}'] \n`
                    );
                    break;
            }
        } else if (operationType === operations[1].name) {
            switch (operator) {
                case arithmeticOperators[0].label:
                    variable1?.body.forEach((item, i) => {
                        arr[i] = (+item + +secondValue).toFixed(2);
                    });
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable1?.header.name}'] + ${secondValue}\n`
                    );
                    break;
                case arithmeticOperators[1].label:
                    variable1?.body.forEach((item, i) => {
                        arr[i] = (+item - +secondValue).toFixed(2);
                    });
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable1?.header.name}'] - ${secondValue}\n`
                    );
                    break;
                case arithmeticOperators[2].label:
                    variable1?.body.forEach((item, i) => {
                        arr[i] = (+item * +secondValue).toFixed(2);
                    });
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable1?.header.name}'] * ${secondValue}\n`
                    );
                    break;
                case arithmeticOperators[3].label:
                    variable1?.body.forEach((item, i) => {
                        arr[i] = (+item / +secondValue).toFixed(2);
                    });
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable1?.header.name}'] / ${secondValue}\n`
                    );
                    break;

                default:
                    arr = [];
                    break;
            }
        } else if (operationType === operations[2].name) {
            let len = variable2?.body.length;
            let value: number | null;
            let values: number[] | undefined = [];
            switch (operator) {
                case logicalOperators[0].label:
                    if (variable2 != null) {
                        value =
                            variable2.body.reduce<number>(
                                (a, b): number => a + Number(b),
                                0
                            ) / len!;
                    }
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable2?.header.name}'].mean()\n`
                    );
                    break;
                case logicalOperators[1].label:
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable2?.header.name}']\n`
                    );
                    break;
                case logicalOperators[2].label:
                    values = variable2?.body.map((item) => +item);
                    value = values?.length! > 0 ? Math.min(...values!) : null;
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable2?.header.name}'].min()\n`
                    );
                    break;
                case logicalOperators[3].label:
                    values = variable2?.body.map((item) => +item);
                    value = values?.length! > 0 ? Math.max(...values!) : null;
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable2?.header.name}'].max()\n`
                    );
                    break;
                case logicalOperators[4].label:
                    values = variable2?.body.map((item) => +item);
                    value = values
                        ? values.reduce((partialSum, a) => partialSum + a, 0)
                        : null;
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable2?.header.name}'].sum()\n`
                    );
                    break;
                case logicalOperators[5].label:
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable2?.header.name}']\n`
                    );
                    break;
                case logicalOperators[6].label:
                    // value = len!;
                    value = DataScopes.previewRowCount;
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = data.loc[${parsedConditions},'${variable2?.header.name}'].count()\n`
                    );
                    break;
                default:
                    break;
            }
            variable2?.body.forEach((item, i) => {
                arr[i] = value ? value.toFixed(2) : "";
            });
        } else if (
            operationType === operations[4].name ||
            operationType === operations[5].name ||
            operationType === operations[6].name
        ) {
            genericVariables[0].type = Type.Datetime;
            //genericVariables[0].level = 'datetime';
            let arr = [];
            arr = inputState
                .map((item) => item.values)
                .filter((item) => item !== "");
            let precode = `k = data.loc[${parsedConditions}].shape[0]\n`;
            precode += "f = (k // " + arr.length + ") + 1\n";
            let p1 = JSON.stringify(arr);

            switch (operator) {
                case assignRandom[0].label:
                    if (
                        operationType === operations[4].name ||
                        operationType === operations[6].name
                    ) {
                        setCreatorCode(
                            initColumn +
                                precode +
                                `data.loc[${parsedConditions}, '${finalVariable?.header.name}'] = random.sample(${p1} * f, k)\n`
                        );
                    } else {
                        setCreatorCode(
                            initColumn +
                                precode +
                                `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = numpy.random.choice(${p1} * f, k)\n`
                            // pd.to_datetime(random.sample(${p1} * f, k)).date\n`
                            // = numpy.random.choice(${p1}) \n`
                        );
                    }
                    break;

                case assignRandom[1].label:
                    setCreatorCode(
                        initColumn +
                            precode +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = pd.to_datetime(numpy.repeat(${p1}, f)).date[:k]\n`
                    );
                    break;

                case assignRandom[2].label:
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions},'${finalVariable?.header.name}'] = pd.date_range(start='${valueRange.start}', end='${valueRange.end}').date[:data.loc[${parsedConditions}].shape[0]]\n`
                    );
                    break;
                default:
                    arr = [];
                    break;
            }
        } else if (operationType === operations[3].name) {
            let arr = inputState
                .map((item) => item.values)
                .filter((item) => item !== "");
            let precode = `k = data.loc[${parsedConditions}].shape[0]\n`;
            precode += "f = (k // " + arr.length + ") + 1\n";
            let p1 = JSON.stringify(arr);
            switch (operator) {
                case randomGenerator[0].label:
                    setCreatorCode(
                        initColumn +
                            precode +
                            `data.loc[${parsedConditions}, '${finalVariable?.header.name}'] = random.sample(${p1} * f, k)\n`
                    );
                    break;

                case randomGenerator[1].label:
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions}, '${finalVariable?.header.name}'] = numpy.random.choice(numpy.arange(${valueRange.start}, ${valueRange.end}, ${valueRange.steps}), size=data.shape[0]) \n`
                    );
                    break;

                case randomGenerator[2].label:
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions}, '${finalVariable?.header.name}'] = numpy.random.uniform(low=${valueRange.start}, high=${valueRange.end}, size=data.shape[0])\n`
                    );
                    break;

                case randomGenerator[3].label:
                    setCreatorCode(
                        initColumn +
                            `data.loc[${parsedConditions}, '${finalVariable?.header.name}'] = numpy.random.normal(loc=${normalDistValue}, scale=1.0, size=data.shape[0])\n`
                    );
                    break;
                default:
                    arr = [];
                    break;
            }
        }
        isNaN(Number(arr[arr.length - 1])) && arr.pop();
        setFinalVariable((finalVariable) => ({
            ...finalVariable!,
            body: arr,
        }));
    }, [
        variable1,
        variable2,
        secondValue,
        operationType,
        operator,
        inputState,
        genericVariables,
        valueRange,
        normalDistValue,
        finalVariable?.header.name,
        initColumn,
        parsedConditions,
    ]);

    function VariableContainer(props: VariableContainerProps) {
        const [{ canDrop1, isOver1 }, drop1] = useDrop<
            {
                name: PreviewHeader;
            },
            void,
            { isOver1: boolean; canDrop1: boolean }
        >(() => ({
            accept: "image",
            drop: (item) => {
                let arr: Array<string | number | Date> = [];
                let valueTypeOk: boolean = true;
                DataScopes.previewState.body?.forEach((value, i) => {
                    if (
                        value[item.name.id] !== "..." &&
                        isNaN(Number(value[item.name.id]))
                    ) {
                        valueTypeOk = false;
                    }
                });

                // setdropVariableType(item.name.type);
                if (
                    item.name.type === "float" ||
                    item.name.type === "int" ||
                    valueTypeOk
                ) {
                    if (props.variableNumber === 1) {
                        DataScopes.previewState.body?.forEach((value, i) => {
                            arr = [...arr, Number(value[item.name.id])!];
                        });
                        arr.pop();
                        setVariable1({ header: item.name, body: arr });
                    } else if (props.variableNumber === 2) {
                        DataScopes.previewState.body?.forEach((value, i) => {
                            arr = [...arr, Number(value[item.name.id])!];
                        });
                        arr.pop();
                        setVariable2({ header: item.name, body: arr });
                    }
                } else {
                    alert(`This column needs to be of number (float) type`);
                }
            },
            collect: (monitor) => ({
                isOver1: !!monitor.isOver(),
                canDrop1: !!monitor.canDrop(),
            }),
        }));

        // let dropable =
        //     dropVariableType === "float" || dropVariableType === "int";

        const dropStyle = () => {
            return {
                border: isOver1
                    ? "2px dashed var(--hot-area-greenBorder)"
                    : props.variableNumber === 1 && canDrop1 && !variable1
                    ? "2px dashed var(--hot-area-blueBorder)"
                    : props.variableNumber === 2 && canDrop1 && variable1
                    ? "2px dashed var(--hot-area-blueBorder)"
                    : "2px dashed lightgrey",
                backgroundColor: isOver1
                    ? "var(--hot-area-greenBg)"
                    : props.variableNumber === 1 && canDrop1 && !variable1
                    ? "var(--hot-area-blueBg)"
                    : props.variableNumber === 2 && canDrop1 && variable1
                    ? "var(--hot-area-blueBg)"
                    : "#fff",
            };
        };

        return (
            <div
                className={`${styles.pTableWrapper} scrollBar`}
                style={{
                    ...dropStyle(),
                    paddingBlock: 24,
                }}
            >
                <table
                    className={styles.pTable}
                    ref={!props.variable ? drop1 : null}
                    style={{ position: "relative" }}
                >
                    {canDrop1 && props.variableNumber === 1 && !variable1 ? (
                        <div className={styles.dropVariable}>
                            1 <br />
                            Drop column here
                        </div>
                    ) : canDrop1 && variable1 && props.variableNumber === 2 ? (
                        <div className={styles.dropVariable}>
                            2 <br />
                            Drop column here
                        </div>
                    ) : null}
                    <thead>
                        <tr>
                            <td
                                style={{
                                    color: props.variable?.header.name
                                        ? "#555"
                                        : "rgba(85, 85, 85, 0.5)",
                                }}
                            >
                                {props.variable?.header.name
                                    ? props.variable?.header.name
                                    : props.variableNumber === 1
                                    ? "Grocery Spend"
                                    : "Travel Spend"}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {props.variable
                            ? props.variable?.body.map((item, index) =>
                                  index < 5 ? (
                                      <tr className={styles.pRow}>
                                          <td>{item}</td>
                                      </tr>
                                  ) : (
                                      index === 5 && (
                                          <tr className={styles.pRow}>
                                              <td>
                                                  +{" "}
                                                  {DataScopes.previewRowCount -
                                                      5}{" "}
                                                  rows
                                              </td>
                                          </tr>
                                      )
                                  )
                              )
                            : Array.from({ length: 6 }).map((_, i) => (
                                  <tr className={styles.pRow}>
                                      <td></td>
                                  </tr>
                              ))}
                        <tr>
                            <div className={styles.dropdownIcon}></div>
                            {/* <img
                                src="/dist/img/data-exploration/dropdown-icon.png"
                                alt="logo"
                                className={styles.dropdownIcon}
                            /> */}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    function Card({
        Icon,
        iconWidth,
        label,
        name,
        optOperator,
        defaultOperator,
        index,
    }: {
        Icon: React.FC<{fill: string, width: number, height: number, role: string}>;
        iconWidth: number;
        label: ReactElement;
        name: string;
        optOperator: string;
        defaultOperator: {label: string, value: string, OperationIcon: React.FC<IconProps>};
        index: number;
    }) {
        return (
            <div
                className={
                    name === operationType
                        ? `${styles.card} ${styles.selectedCard}`
                        : styles.card
                }
                onClick={() => {
                    setOperationType(name);
                    setOperator(optOperator);
                    setOperatorValue({
                        label: defaultOperator?.label.substring(0, 1)!,
                        value: defaultOperator?.value!,
                        OperationIcon: defaultOperator?.OperationIcon!,
                    });
                    setVariable1(null);
                    setVariable2(null);
                    setSecondValue("12");
                    setValueRange({ start: "", end: "" });
                    setDropdownValue(
                        name === operations[2].name
                            ? logicalOperators[0]
                            : name === operations[3].name
                            ? randomGenerator[0]
                            : name === operations[4].name ||
                              name === operations[5].name ||
                              name === operations[6].name
                            ? assignRandom[0]
                            : { label: "", value: "" }
                    );
                    setInputState([
                        {
                            values: "",
                            placeholder1: "Apples",
                            placeholder2: "01/01/2022",
                            placeholder3: "10043",
                        },
                        {
                            values: "",
                            placeholder1: "Banana",
                            placeholder2: "02/01/2022",
                            placeholder3: "02198",
                        },
                        {
                            values: "",
                            placeholder1: "Cup Cakes",
                            placeholder2: "03/01/2022",
                            placeholder3: "90210",
                        },
                    ]);
                }}
            >
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "65px"
                }}>
                    <Icon
                        fill={
                            (name === operationType) ?
                                "#3b82c9" : "#7E7C7C"
                        }
                        width={iconWidth}
                        height={iconWidth}
                        role="img"
                    />
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    height: "65px"
                }}>
                    <p className={styles.cardLabel}>{label}</p>
                </div>
            </div>
        );
    }

    function SecondValue() {
        return (
            <div className={styles.valueBox}>
                <div
                    onClick={() => setEditSecondValue(!editSecondValue)}
                    style={{ width: 200, cursor: "pointer" }}
                >
                    <span
                        className={
                            updateValue
                                ? styles.secondUpdatedValue
                                : styles.secondValue
                        }
                        style={{ opacity: updateValue ? 0.8 : 1 }}
                    >
                        {Math.round(Number(secondValue) * 100) / 100}
                    </span>
                </div>
                <div style={{ height: 36 }}>
                    {editSecondValue && (
                        <input
                            className={styles.secondValueClass}
                            placeholder="Enter a number"
                            type={"number"}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    setSecondValue(e.currentTarget.value);
                                    setEditSecondValue(!editSecondValue);
                                    setUpdateValue(true);
                                }
                            }}
                            onBlur={(e) => {
                                setSecondValue(e.currentTarget.value);
                                setEditSecondValue(!editSecondValue);
                                setUpdateValue(true);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }

    const openEditMenu = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const xdistance = e.clientX;
        const ydistance = e.clientY;
        setToggleEdit(!toggleEdit);

        const showedit = {
            ...finalVariable?.header!,
            Xcoord: xdistance,
            Ycoord: ydistance,
        };
        if (setShowEdit) {
            toggleEdit && setShowEdit(showedit);
        }
    };

    function handleInputChange(
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) {
        let newValue = { ...inputState[index] };
        newValue = {
            ...newValue,
            values: e.target.value,
        };
        inputState[index] = newValue;
        setInputState([...inputState]);
        setShowDateIcon(index + 1);
    }

    function inputStateFunction() {
        const clamp = (min: number, max: number, val: number) =>
            Math.max(min, Math.min(val, max));
        const handleKeyPress = (index: number) => {
            const nextIndex = clamp(0, inputState.length - 1, index + 1);
            refs.current[nextIndex]?.focus();
        };

        return inputState.map((item, index) => (
            <div key={index}>
                <input
                    ref={(el) => (refs.current[index] = el)}
                    className={
                        index !== showDateIcon
                            ? `${styles.repeatInputStyle}`
                            : styles.repeatInputStyle
                    }
                    type={
                        operationType === "Add date column" &&
                        index === showDateIcon
                            ? dateTypeInput
                            : operationType === "ZipCode"
                            ? "number"
                            : "text"
                    }
                    onFocus={() => {
                        operationType === "Add date column"
                            ? setDateTypeInput("date")
                            : setDateTypeInput("text");
                    }}
                    style={{
                        color: inputBoxColor === index ? "#326eaa" : "#333",
                        width: "100%",
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            if (index + 1 === inputState.length - 1) {
                                setInputState((prevState) => [
                                    ...prevState,
                                    {
                                        values: "",
                                        placeholder1: "",
                                        placeholder2: "",
                                        placeholder3: "",
                                    },
                                ]);
                                index < 9 && handleKeyPress(index);
                            } else {
                                index < 9 && handleKeyPress(index);
                            }
                        }
                    }}
                    onBlur={(e) => {
                        setInputBoxColor(null);
                    }}
                    onClick={() => setShowDateIcon(index)}
                    value={item.values}
                    onChange={(e) => {
                        handleInputChange(e, index);
                        setInputBoxColor(index);
                    }}
                    placeholder={
                        operationType === operations[5].name
                            ? item.placeholder2
                            : operationType === operations[6].name
                            ? item.placeholder3
                            : item.placeholder1
                    }
                />
                <br />
            </div>
        ));
    }

    // function ResultTable() {
    //     return (
    //         <table className={styles.pTable}>
    //             <thead>
    //                 <tr>
    //                     <td>
    //                         <div className={styles.newColumnName}>
    //                             <input
    //                                 style={{
    //                                     color:
    //                                         finalVariable?.body.length! > 0
    //                                             ? "#555"
    //                                             : "rgba(85, 85, 85, 0.5)",
    //                                 }}
    //                                 placeholder={
    //                                     operationType === "Airthmetic operation"
    //                                         ? "Yearly Spend"
    //                                         : operationType ===
    //                                           "Logical Operatoions"
    //                                         ? "Avg Travel Spend"
    //                                         : operationType === "ZipCode"
    //                                         ? "Random US Zipcodes"
    //                                         : "Name new Column"
    //                                 }
    //                                 value={finalVariable?.header.name}
    //                                 onChange={(e) => {
    //                                     let header = {
    //                                         id: 0,
    //                                         name: e.target.value,
    //                                         type: genericVariables[0].type,
    //                                     };
    //                                     setfinalVariable({
    //                                         ...finalVariable!,
    //                                         header: header,
    //                                     });
    //                                     setGenericVariables([
    //                                         {
    //                                             ...genericVariables[0],
    //                                             name: e.target.value,
    //                                         },
    //                                     ]);
    //                                 }}
    //                             />
    //                             {dataset && (
    //                                 <span style={{ position: "relative" }}>
    //                                     <span
    //                                         style={{
    //                                             width: 20,
    //                                             textAlign: "center",
    //                                             position: "relative",
    //                                             right: 0,
    //                                         }}
    //                                         onClick={OpenEditMenu}
    //                                     >
    //                                         <ChevronIcon stroke="#555" />
    //                                     </span>
    //
    //                                     {showEdit != null && !toggleEdit && (
    //                                         <div
    //                                             className={`${styles.EditPopup} `}
    //                                             style={{
    //                                                 width: "max-content",
    //                                                 height: "374px",
    //                                                 maxHeight: "400px",
    //                                                 position: "absolute",
    //                                                 top: 34,
    //                                                 // left: 5,
    //                                                 background: "transparent",
    //                                                 overflow: "hidden",
    //                                                 zIndex: 8,
    //                                             }}
    //                                         >
    //                                             <OutsideAlerter
    //                                                 onReject={() => {
    //                                                     setShowEdit(null);
    //                                                     settoggleEdit(
    //                                                         !toggleEdit
    //                                                     );
    //                                                 }}
    //                                             >
    //                                                 <EditVariablesSection
    //                                                     dataset={dataset}
    //                                                     selectedVariable={
    //                                                         showEdit
    //                                                     }
    //                                                     showEdit={setShowEdit}
    //                                                     setStatusPopup={
    //                                                         setStatusPopup
    //                                                     }
    //                                                     table={table}
    //                                                     newVariable={true}
    //                                                     genericVariable={
    //                                                         genericVariables
    //                                                     }
    //                                                     setgenericVariable={
    //                                                         setGenericVariables
    //                                                     }
    //                                                 />
    //                                             </OutsideAlerter>
    //                                         </div>
    //                                     )}
    //                                 </span>
    //                             )}
    //                         </div>
    //                     </td>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 {finalVariable?.body.length! > 0
    //                     ? finalVariable?.body.map((item, index) =>
    //                           index < 5 ? (
    //                               <tr className={styles.pRow}>
    //                                   <td>{item}</td>
    //                               </tr>
    //                           ) : (
    //                               index === 5 && (
    //                                   <tr className={styles.pRow}>
    //                                       <td>
    //                                           +{" "}
    //                                           {finalVariable?.body.length! - 5}{" "}
    //                                           rows
    //                                       </td>
    //                                   </tr>
    //                               )
    //                           )
    //                       )
    //                     : Array.from({ length: 5 }).map((_, i) => (
    //                           <tr className={styles.pRow}>
    //                               <td></td>
    //                           </tr>
    //                       ))}
    //             </tbody>
    //         </table>
    //     );
    // }

    // function OptionSelector(props: {
    //     options: any;
    //     onChange: (newValue: any) => void;
    //     defaultValue?: any;
    //     style?: Styles;
    // }) {
    //     return (
    //         <>
    //             <Select
    //                 isSearchable={false}
    //                 components={{
    //                     DropdownIndicator: () => null,
    //                     IndicatorSeparator: () => null,
    //                 }}
    //                 isMulti={false}
    //                 styles={{
    //                     ...leftAlignAndScrollBarCSS,
    //                     container: (base) => ({
    //                         ...base,
    //                         width: "fit-content",
    //                         marginLeft: "auto",
    //                     }),
    //                     control: (base) => ({
    //                         ...base,
    //                         border: "none",
    //                         cursor: "pointer",
    //                         // boxShadow: 'none',
    //                         // "&:hover": {
    //                         //     boxShadow: 'none',
    //                         // }
    //                     }),
    //                     singleValue: (base) => ({
    //                         ...base,
    //                         textAlign: "right",
    //                         fontSize: "40px",
    //                         textTransform: "lowercase",
    //                     }),
    //                     // menuList: (base) => ({
    //                     //   ...base,
    //                     //   padding: 0,
    //                     //   "::-webkit-scrollbar": {
    //                     //     width: "6px",
    //                     //     height: 5,
    //                     //   },
    //                     //   "::-webkit-scrollbar-track": {
    //                     //     background: "transparent",
    //                     //     // background: "var(--greyColor)"
    //                     //   },
    //                     //   "::-webkit-scrollbar-thumb": {
    //                     //     background: "#888",
    //                     //     borderRadius: "50px",
    //                     //   },
    //                     //   "::-webkit-scrollbar-thumb:hover": {
    //                     //     background: "#555",
    //                     //   },
    //                     // }),
    //                     // menu: (base) => ({
    //                     //   ...base,
    //                     //   border: "1px solid lightgrey",
    //                     //   borderRadius: 6,
    //                     // }),
    //                     // ...props.style,
    //                 }}
    //                 options={props.options}
    //                 onChange={props.onChange}
    //                 defaultValue={props.defaultValue ? props.defaultValue : ""}
    //                 theme={(theme) => ({
    //                     ...theme,
    //                     borderRadius: 0,
    //                     colors: {
    //                         ...theme.colors,
    //                         text: "white",
    //
    //                         primary25:
    //                             "var(--selectors-background-hover-color)",
    //                     },
    //                 })}
    //             />
    //         </>
    //     );
    // }

    const disable =
        operationType === operations[0].name
            ? variable1 === null || variable1 === null
            : operationType === operations[1].name
            ? variable1 === null
            : operationType === operations[2].name
            ? variable2 === null
            : operationType === operations[3].name
            ? inputData.length === 0 &&
              (valueRange.start === "" || valueRange.end === "") &&
              normalDistValue === undefined
            : operationType === operations[4].name
            ? inputData.length === 0
            : operationType === operations[5].name
            ? inputData.length === 0 &&
              (valueRange.start === "" || valueRange.end === "")
            : operationType === operations[6].name
            ? inputData.length === 0
            : inputData.length === 0;

    function scrollArrowOnClick(scrollRange: number) {
        scrollRef.current.scrollBy({
            top: 0,
            left: scrollRange,
            behavior : "smooth"
        });
        setTimeout(() => {
            const scrollData = gatherScrollData(scrollRef.current);
            setScrollInfo({
                ...scrollData,
                ...checkScrollPosition(scrollData)
            });
        }, 400);
    }

    return (
        <div className={"mainContainer"} style={{ paddingBlock: 0 }}>
            <h1 style={{ display: "none" }}> {DragAndDropStates.showDrag}</h1>
            <h1 style={{ display: "none" }}> {DragAndDropStates.datasett}</h1>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBlock: 20,
                }}
            >
                <p style={{ marginBlock: "auto" }}>
                    <b>Create a new data column: </b>
                    using a dataset’s existing columns
                </p>
                <SwitchButtons
                    options={methodOptions}
                    onClick={(option) => {
                        setMethodType(option);
                        setPreviewInfo(null);
                        setVariable1(null);
                        setVariable2(null);
                        setSecondValue("12");
                        setDemoPreviewBody(previewBody);
                        setDemoPreviewHeader(previewHeader);
                        setCreatorCode(variableCreatorSample);
                        setFinalVariable({ header: variableHeder, body: [] });
                        setOperationType(operations[0].name);
                        setShowFilter("All Rows");

                        if (option === "GUI") {
                            setGenericVariables([
                                {
                                    name: null,
                                    unit: null,
                                    type: Type.Float,
                                    level: null,
                                    panel: Panel.Regular,
                                    format: null,
                                    decimal: null,
                                },
                            ]);
                        } else {
                            setGenericVariables([
                                {
                                    name: null,
                                    unit: null,
                                    type: Type.Str,
                                    level: null,
                                    panel: Panel.Regular,
                                    format: null,
                                    decimal: null,
                                },
                            ]);
                        }
                    }}
                    selectedOption={methodType}
                />
            </div>
            <div className={styles.datasetsContainer}>
                <DataScopeAndTableSelectorView
                    dataHubMode
                    dataScopeOption={dataset}
                    tableOption={table}
                    onChange={(dataScopeOption, tableOption) => {
                        setDataset(dataScopeOption);
                        setTable(tableOption);
                        setPreviewInfo(null);
                        DataScopes.previewState = { headers: null, body: null };
                        if (dataScopeOption?.value != null) {
                            DataScopes.DatasetPreview(
                                dataScopeOption?.value,
                                undefined,
                                tableOption ?? undefined
                            );
                        }

                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(false);
                        setVariable1(null);
                        setVariable2(null);
                        setFinalVariable({ header: variableHeder, body: [] });
                        setDemoPreviewBody([]);
                        setDemoPreviewHeader([]);
                    }}
                    onFocus={() => {
                        setShowEdit(null);
                    }}
                    dataScopePlaceholder={"Select dataset"}
                    tableOptionPlaceholder={"Select table"}
                    needWritePermissions={true}
                    hideTable={dataset == null}
                ></DataScopeAndTableSelectorView>
                <div className={styles.rowLevelContainer}>
                    <div
                        className={styles.textItem}
                        style={{ marginBottom: 0 }}
                    >{`Row level`}</div>
                    <div className={styles.rowLevelSwitch}>
                        <Switch
                            onChange={(checked) => {
                                setRowLevel(checked);
                                setCreatorCode(
                                    checked
                                        ? variableCreatorRowLevelSample
                                        : variableCreatorSample
                                );
                            }}
                            checked={rowLevel}
                            width={34}
                            height={16}
                            handleDiameter={14}
                            borderRadius={8}
                            activeBoxShadow="none"
                            offColor="#CCC"
                            onColor="#3474B2"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#70889E"
                            onHandleColor="#fff"
                        />
                    </div>
                    <div>
                        <img
                            data-tip=" Auto-rerun formula  to create new rows as new data rows are added."
                            data-for="rowLevel"
                            src="/dist/img/data-exploration/question.svg"
                            alt=""
                            style={{
                                marginLeft: 5,
                            }}
                        />
                        <ReactTooltip
                            effect="solid"
                            id="rowLevel"
                            multiline={true}
                        />
                    </div>
                </div>
            </div>
            {DataScopes.preview?.headers != null ? (
                <div
                    className={"customPreview"}
                    style={{
                        position: "relative",
                        padding: "0px 15px 15px 15px",
                    }}
                >
                    {preview(
                        DataScopes.preview?.headers,
                        DataScopes.preview?.body,
                        1,
                        false,
                        "createVariable",
                        dataset,
                        setShowEdit,
                        true
                    )}
                    {showEdit != null && toggleEdit && (
                        <div
                            className={`${styles.EditPopup} `}
                            style={{
                                width: "max-content",
                                height: "374px",
                                maxHeight: "400px",
                                position: "absolute",
                                top: 50,
                                left: `${
                                    screen > 285
                                        ? showEdit.Xcoord - 143
                                        : showEdit.Xcoord - 336
                                }px`,
                                background: "transparent",
                                overflow: "hidden",
                                zIndex: 8,
                            }}
                        >
                            <OutsideAlerter
                                onReject={() => {
                                    setShowEdit(null);
                                }}
                            >
                                <EditVariablesSection
                                    dataset={dataset}
                                    selectedVariable={showEdit}
                                    setSelectedVariable={setShowEdit}
                                    setStatusPopup={setStatusPopup}
                                    table={table}
                                />
                            </OutsideAlerter>
                        </div>
                    )}
                </div>
            ) : DataScopes.preview?.headers === null && methodType === "GUI" ? (
                <div
                    className={"customPreview"}
                    style={{ color: "rgba(85, 85, 85, 0.5)" }}
                >
                    {preview(
                        demoPreviewHeader,
                        demoPreviewBody,
                        1,
                        true,
                        "",
                        dataset,
                        setShowEdit,
                        true
                    )}
                </div>
            ) : null}

            {dataset != null && methodType === "GUI" && (
                    <div className={styles.commonExamples}>
                        <p className={styles.logics}>
                            Common <br /> Examples
                        </p>
                        <div style={{
                            position:"relative",
                            width: "95%"
                        }}>
                        <div
                            ref={scrollRef}
                            className="hide-scroll"
                            style={{
                                borderRadius:"10px",
                                backgroundColor:"#E8E6E6",
                                display: "flex",
                                overflowY: "auto",
                                marginLeft: "10px",
                                marginRight: "10px"
                            }}
                        >
                            {!scrollInfo.inTheBeginning && (
                                <div style={{
                                    position: "absolute",
                                    left: "15px",
                                    top: "30px"
                                    
                                }}>
                                    <button
                                        onClick={() => scrollArrowOnClick(-300)}
                                        className={styles.scrollArrowLeft}
                                    >
                                        <CategoriesScrollArrow />
                                    </button>
                                </div>
                            )} 
                            {operations.map((item, index) => (
                                <Card
                                    Icon={item.Icon}
                                    iconWidth={item.iconWidth}
                                    label={item.label}
                                    name={item.name}
                                    optOperator={item.optOperator}
                                    index={index}
                                    defaultOperator={item.defaultOperator}
                                />
                            ))}
                            {!scrollInfo.inTheEnd && (
                                <div style={{
                                    position: "absolute",
                                    right: "15px",
                                    top: "30px"
                                }}>
                                    <button
                                        onClick={() => scrollArrowOnClick(300)}
                                        className={styles.scrollArrowRight}
                                    >
                                        <CategoriesScrollArrow />
                                    </button>
                                </div>
                            )} 
                        </div>
                    </div>
                </div>
            )}

            {methodType === "GUI" && (
                <div
                    className={styles.variablesContainer}
                    style={{ marginTop: dataset ? 0 : 20 }}
                >
                    <div
                        className={`${styles.variablesBox} my-row`}
                        style={{ marginBottom: 0 }}
                    >
                        <div
                            className={`${styles.pTableWrapper} scrollBar`}
                            style={{ position: "relative" }}
                        >
                            {!dataset && <div className={styles.connector} />}
                            {/* <ResultTable /> */}

                            <table className={styles.pTable}>
                                <thead>
                                    <tr>
                                        <td>
                                            <div
                                                className={styles.newColumnName}
                                            >
                                                <input
                                                    style={{
                                                        color:
                                                            finalVariable?.body
                                                                .length! > 0
                                                                ? "#555"
                                                                : "rgba(85, 85, 85, 0.5)",
                                                    }}
                                                    placeholder={
                                                        "Name new Column"
                                                    }
                                                    value={
                                                        finalVariable?.header
                                                            .name
                                                    }
                                                    onChange={(e) => {
                                                        let value =
                                                            e.target.value;
                                                        let header = {
                                                            id: 0,
                                                            name: value,
                                                            type:
                                                                genericVariables[0]
                                                                    .type,
                                                        };
                                                        setFinalVariable(
                                                            (
                                                                finalVariable
                                                            ) => ({
                                                                ...finalVariable!,
                                                                header: header,
                                                            })
                                                        );
                                                        setGenericVariables(
                                                            (
                                                                genericVariables
                                                            ) => [
                                                                {
                                                                    ...genericVariables[0],
                                                                    name: value,
                                                                },
                                                            ]
                                                        );
                                                    }}
                                                />
                                                {dataset && (
                                                    <span
                                                        style={{
                                                            position:
                                                                "relative",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                width: 20,
                                                                textAlign:
                                                                    "center",
                                                                position:
                                                                    "relative",
                                                                right: 0,
                                                            }}
                                                            onClick={
                                                                openEditMenu
                                                            }
                                                        >
                                                            <ChevronIcon stroke="#555" />
                                                        </span>

                                                        {showEdit != null &&
                                                            !toggleEdit && (
                                                                <div
                                                                    className={`${styles.EditPopup} `}
                                                                    style={{
                                                                        width:
                                                                            "max-content",
                                                                        height:
                                                                            "374px",
                                                                        maxHeight:
                                                                            "400px",
                                                                        position:
                                                                            "absolute",
                                                                        top: 34,
                                                                        // left: 5,
                                                                        background:
                                                                            "transparent",
                                                                        overflow:
                                                                            "hidden",
                                                                        zIndex: 8,
                                                                    }}
                                                                >
                                                                    <OutsideAlerter
                                                                        onReject={() => {
                                                                            setShowEdit(
                                                                                null
                                                                            );
                                                                            setToggleEdit(
                                                                                (
                                                                                    toggleEdit
                                                                                ) =>
                                                                                    !toggleEdit
                                                                            );
                                                                        }}
                                                                    >
                                                                        <EditVariablesSection
                                                                            dataset={
                                                                                dataset
                                                                            }
                                                                            selectedVariable={
                                                                                showEdit
                                                                            }
                                                                            setSelectedVariable={
                                                                                setShowEdit
                                                                            }
                                                                            setStatusPopup={
                                                                                setStatusPopup
                                                                            }
                                                                            table={
                                                                                table
                                                                            }
                                                                            newVariable={
                                                                                true
                                                                            }
                                                                            setGenericVariables={
                                                                                setGenericVariables
                                                                            }
                                                                        />
                                                                    </OutsideAlerter>
                                                                </div>
                                                            )}
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {finalVariable?.body.length! > 0
                                        ? finalVariable?.body.map(
                                              (item, index) =>
                                                  index < 5 ? (
                                                      <tr
                                                          className={
                                                              styles.pRow
                                                          }
                                                      >
                                                          <td>{item}</td>
                                                      </tr>
                                                  ) : (
                                                      index === 5 && (
                                                          <tr
                                                              className={
                                                                  styles.pRow
                                                              }
                                                          >
                                                              <td>
                                                                  +{" "}
                                                                  {DataScopes.previewRowCount -
                                                                      5}{" "}
                                                                  rows
                                                              </td>
                                                          </tr>
                                                      )
                                                  )
                                          )
                                        : Array.from({ length: 6 }).map(
                                              (_, i) => (
                                                  <tr className={styles.pRow}>
                                                      <td></td>
                                                  </tr>
                                              )
                                          )}
                                    <tr>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: 20,
                                                marginBottom: 5,
                                            }}
                                        >
                                            <SwitchButtons
                                                options={rowOptions}
                                                onClick={(option) => {
                                                    if (option === "All Rows") {
                                                        setShowFilter(
                                                            "All Rows"
                                                        );
                                                    } else {
                                                        setShowFilter(
                                                            "Filter Rows"
                                                        );
                                                    }
                                                    setConditions(
                                                        ConditionsSelector.defaultValue
                                                    );
                                                }}
                                                selectedOption={showFilter}
                                            />
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={styles.pOperator}>=</div>

                        {operationType === "custom function" ? (
                            <div className={styles.customFunctionWrapper}>
                                <div
                                    className={styles.pOperator}
                                    style={{
                                        color: "#555",
                                        opacity: "70%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontStyle: "italic",
                                            paddingRight: 10,
                                        }}
                                    >
                                        f
                                    </span>
                                    {"("}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        alt=""
                                        src="/dist/img/data-exploration/Group.svg"
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                            marginBottom: "10px",
                                        }}
                                    />
                                    <div>
                                        Please use the
                                        <span
                                            style={{
                                                color: "var(--acceptBtn-color)",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setMethodType("Python");
                                                setPreviewInfo(null);
                                                setVariable1(null);
                                                setVariable2(null);
                                                setSecondValue("12");
                                                setDemoPreviewBody(previewBody);
                                                setDemoPreviewHeader(
                                                    previewHeader
                                                );
                                                setCreatorCode(
                                                    variableCreatorSample
                                                );
                                                setFinalVariable({
                                                    header: variableHeder,
                                                    body: [],
                                                });
                                                setGenericVariables([
                                                    {
                                                        name: null,
                                                        unit: null,
                                                        type: Type.Str,
                                                        level: null,
                                                        panel: Panel.Regular,
                                                        format: null,
                                                        decimal: null,
                                                    },
                                                ]);
                                            }}
                                        >
                                            {" "}
                                            code feature{" "}
                                        </span>
                                        to define
                                        <br /> custom functions using python
                                        code.
                                        <br />
                                        <br />
                                        More drag-and-drop GUI's are in the
                                        work.
                                    </div>
                                </div>
                                <div
                                    className={styles.pOperator}
                                    style={{
                                        color: "#555",
                                        opacity: "70%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {")"}
                                </div>
                            </div>
                        ) : (
                            <>
                                {operationType === "Logical Operatoions" ? (
                                    <div
                                        className={styles.logicalOperator}
                                        style={{
                                            width: 180,
                                            marginRight: 5,
                                            paddingLeft: 0,
                                        }}
                                    >
                                        {/* <OptionSelector
                      options={logical_operators}
                      onChange={(newValue) => {
                        setoperator(newValue?.label!);
                      }}
                      defaultValue={logical_operators[0]}
                    /> */}

                                        <Select
                                            isSearchable={false}
                                            components={{
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                            }}
                                            isMulti={false}
                                            styles={{
                                                ...leftAlignAndScrollBarCSS,
                                                container: (base) => ({
                                                    ...base,
                                                    width: "180px",
                                                    // width: "fit-content",
                                                    marginLeft: "auto",
                                                }),
                                                control: (base, state) => ({
                                                    ...base,
                                                    border: "none",
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                        state.isFocused ||
                                                        selectedColor !== 2
                                                            ? "#ebf2f9"
                                                            : "transparent",
                                                    boxShadow:
                                                        selectedColor !== 2
                                                            ? "0 0 0 1px var(--acceptBtn-color)"
                                                            : "none",
                                                }),
                                                singleValue: (base, state) => ({
                                                    ...base,
                                                    textAlign: "center",
                                                    // textAlign: "right",
                                                    fontSize: "40px",
                                                    textTransform: "lowercase",
                                                    color:
                                                        selectedColor !== 2
                                                            ? "var(--acceptBtn-color)"
                                                            : "#333",
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    borderRadius: 6,
                                                    overflow: "auto",
                                                }),
                                            }}
                                            options={logicalOperators}
                                            onChange={(newValue) => {
                                                setOperator(newValue?.label!);
                                                setDropdownValue(newValue!);
                                            }}
                                            onFocus={() => {
                                                setSelectedColor(0);
                                            }}
                                            onBlur={() => {
                                                setSelectedColor(2);
                                            }}
                                            // value={logical_operators}
                                            value={dropdownValue}
                                            defaultValue={logicalOperators[0]}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25:
                                                        "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </div>
                                ) : operationType === "Airthmetic operation" ||
                                  operationType === "Add two Columns" ? (
                                    <>
                                        <VariableContainer
                                            variable={variable1}
                                            variableNumber={1}
                                        />
                                    </>
                                ) : operationType === "Add text column" ||
                                  operationType === "Add date column" ||
                                  operationType === "ZipCode" ? (
                                    <div
                                        className={styles.repeatOperator}
                                        style={{
                                            width: 300,
                                            marginRight: 5,
                                            paddingLeft: 0,
                                        }}
                                    >
                                        {/* <OptionSelector
                      options={
                        operationType === "Add text column" ||
                          operationType === "ZipCode"
                          ? [Assign_Random[0], Assign_Random[1]]
                          : Assign_Random
                      }
                      onChange={(newValue) => {
                        setoperator(newValue?.label!);
                      }}
                      defaultValue={Assign_Random[0]}
                      style={{
                        menu: (base) => ({
                          ...base,
                          border: "1px solid lightgrey",
                          borderRadius: 6,
                          fontSize: "16px",
                          lineHeight: "19px",
                          fontStyle: "normal",
                          fontFamily: "Roboto",
                        }),
                      }}
                    /> */}

                                        <Select
                                            isSearchable={false}
                                            components={{
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                            }}
                                            isMulti={false}
                                            styles={{
                                                ...leftAlignAndScrollBarCSS,
                                                container: (base) => ({
                                                    ...base,
                                                    width: 300,
                                                    marginLeft: "auto",
                                                    maxWidth: 300,
                                                }),
                                                control: (base, state) => ({
                                                    ...base,
                                                    border: "none",
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                        state.isFocused ||
                                                        selectedColor !== 3
                                                            ? "#ebf2f9"
                                                            : "transparent",
                                                    boxShadow:
                                                        selectedColor !== 3
                                                            ? "0 0 0 1px var(--acceptBtn-color)"
                                                            : "none",
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    textAlign: "left",
                                                    fontSize: "40px",
                                                    textTransform: "lowercase",
                                                    whiteSpace: "normal",
                                                    color:
                                                        selectedColor !== 3
                                                            ? "var(--acceptBtn-color)"
                                                            : "#333",
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    border:
                                                        "1px solid lightgrey",
                                                    borderRadius: 6,
                                                    fontSize: "16px",
                                                    lineHeight: "19px",
                                                    fontStyle: "normal",
                                                    fontFamily: "Roboto",
                                                }),
                                            }}
                                            options={
                                                operationType ===
                                                    "Add text column" ||
                                                operationType === "ZipCode"
                                                    ? [
                                                          assignRandom[0],
                                                          assignRandom[1],
                                                      ]
                                                    : assignRandom
                                            }
                                            onChange={(newValue) => {
                                                setOperator(newValue?.label!);
                                                setDropdownValue(newValue!);
                                            }}
                                            onFocus={() => {
                                                setSelectedColor(0);
                                            }}
                                            onBlur={() => {
                                                setSelectedColor(3);
                                            }}
                                            // value={Assign_Random}
                                            value={dropdownValue}
                                            defaultValue={assignRandom[0]}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",

                                                    primary25:
                                                        "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </div>
                                ) : operationType === "Random Generator" ? (
                                    <div
                                        className={styles.repeatOperator}
                                        style={{
                                            width: 300,
                                            marginRight: 5,
                                            paddingLeft: 0,
                                        }}
                                    >
                                        {/* <OptionSelector
                      options={Random_Generator}
                      onChange={(newValue) => {
                        setoperator(newValue?.label!);
                        setvalueRange({ start: "", end: "", });
                      }}
                      defaultValue={Random_Generator[0]}
                      style={{
                        menu: (base) => ({
                          ...base,
                          border: "1px solid lightgrey",
                          borderRadius: 6,
                          fontSize: "16px",
                          lineHeight: "19px",
                          fontStyle: "normal",
                          fontFamily: "Roboto",
                        }),
                      }}
                    /> */}

                                        <Select
                                            isSearchable={false}
                                            components={{
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                            }}
                                            isMulti={false}
                                            styles={{
                                                ...leftAlignAndScrollBarCSS,
                                                container: (base) => ({
                                                    ...base,
                                                    width: 300,
                                                    marginLeft: "auto",
                                                    maxWidth: 300,
                                                }),
                                                control: (base, state) => ({
                                                    ...base,
                                                    border: "none",
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                        state.isFocused ||
                                                        selectedColor !== 4
                                                            ? "#ebf2f9"
                                                            : "transparent",
                                                    boxShadow:
                                                        selectedColor !== 4
                                                            ? "0 0 0 1px var(--acceptBtn-color)"
                                                            : "none",
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    textAlign: "left",
                                                    fontSize: "40px",
                                                    textTransform: "lowercase",
                                                    whiteSpace: "normal",
                                                    color:
                                                        selectedColor !== 4
                                                            ? "var(--acceptBtn-color)"
                                                            : "#333",
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    border:
                                                        "1px solid lightgrey",
                                                    borderRadius: 6,
                                                    fontSize: "16px",
                                                    lineHeight: "19px",
                                                    fontStyle: "normal",
                                                    fontFamily: "Roboto",
                                                }),
                                            }}
                                            options={randomGenerator}
                                            onChange={(newValue) => {
                                                setOperator(newValue?.label!);
                                                setValueRange({
                                                    start: "",
                                                    end: "",
                                                });
                                                setDropdownValue(newValue!);
                                            }}
                                            onFocus={() => {
                                                setSelectedColor(0);
                                            }}
                                            onBlur={() => {
                                                setSelectedColor(4);
                                            }}
                                            // value={Random_Generator}
                                            value={dropdownValue}
                                            defaultValue={randomGenerator[0]}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",

                                                    primary25:
                                                        "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}

                                <div
                                    className={styles.pOperator}
                                    style={
                                        operationType === "Logical Operatoions"
                                            ? { width: "5%" }
                                            : operationType ===
                                                  "Add text column" ||
                                              operationType ===
                                                  "Add date column" ||
                                              operationType === "ZipCode" ||
                                              operationType ===
                                                  "Random Generator"
                                            ? { width: "5%" }
                                            : {
                                                  minWidth: "10%",
                                                  width: "fit-content",
                                              }
                                    }
                                >
                                    {operationType === "Airthmetic operation" ||
                                    operationType === "Add two Columns" ? (
                                        <Select 
                                            defaultValue={arithmeticOperators[2]}
                                            options={arithmeticOperators}
                                            value={operatorValue}
                                            components={{ 
                                                SingleValue: (IconValue as React.FC), 
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                            }}
                                            isSearchable={false}
                                            isMulti={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25:
                                                        "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                            styles={{
                                                container: (base) => ({
                                                    ...base,
                                                    width: "55px",
                                                    height: "55px",
                                                    marginInline: "auto",
                                                    textAlign: "center",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }),
                                                control: (base, state) => ({
                                                    ...base,
                                                    border: "none",
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                        state.isFocused ||
                                                        selectedColor !== 5
                                                            ? "#ebf2f9"
                                                            : "transparent",
                                                    boxShadow:
                                                        selectedColor !== 5
                                                            ? "0 0 0 1px var(--acceptBtn-color)"
                                                            : "none",
                                                    height: '55px',
                                                    
                                                }),
                                                valueContainer: (base) => ({
                                                    ...base,
                                                    display: 'flex',
                                                    height: '100%',
                                                    padding: "0",
                                                    margin: "0",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',                                                    
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    padding: "0",
                                                    margin: "0",
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isSelected
                                                        ? "var(--greyColor)"
                                                        : "white",
                                                    color: "black",
                                                    borderRadius: 5,
                                                    textAlign: "left",
                                                    "&:hover": {
                                                        backgroundColor: state.isSelected
                                                            ? "var(--greyColor)"
                                                            : "var(--greyColor)",
                                                    },
                                                    fontSize: 16,
                                                }),
                                                menuList: (base) => ({
                                                    ...base,
                                                    padding: 0,
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    border:
                                                        "1px solid lightgrey",
                                                    borderRadius: 6,
                                                    width: 170,
                                                }),
                                            }}
                                            onChange={(newValue) => {
                                                setOperator(newValue?.label!);
                                                setOperatorValue({
                                                    label: newValue?.label.substring(
                                                        0,
                                                        1
                                                    )!,
                                                    value: newValue?.value!,
                                                    OperationIcon: newValue?.OperationIcon!
                                                });
                                            }}
                                            onFocus={() => {
                                                setSelectedColor(0);
                                                setEditSecondValue(false);
                                            }}
                                            onBlur={() => {
                                                setSelectedColor(5);
                                            }}
                                            formatOptionLabel={(data) => (
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        fontSize: "inherit",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {data.label.substring(
                                                            0,
                                                            1
                                                        )}
                                                    </span>
                                                    {data.label.substring(1)}
                                                </p>
                                            )}
                                        />

                                    ) : operationType ===
                                      "Logical Operatoions" ? (
                                        "("
                                    ) : operationType === "Add text column" ||
                                      operationType === "Add date column" ||
                                      operationType === "ZipCode" ||
                                      operationType === "Random Generator" ? (
                                        <div
                                            className={styles.squareBracket}
                                        ></div>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                {operationType === "Airthmetic operation" ? (
                                    <SecondValue />
                                ) : operationType === "Logical Operatoions" ||
                                  operationType === "Add two Columns" ? (
                                    <>
                                        <VariableContainer
                                            variable={variable2}
                                            variableNumber={2}
                                        />
                                    </>
                                ) : operationType === operations[4].name ||
                                  operationType === operations[5].name ||
                                  operationType === operations[6].name ||
                                  operator === randomGenerator[0].label ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            width: "20%",
                                        }}
                                    >
                                        <div
                                            className={
                                                operator === "Sequence dates"
                                                    ? ""
                                                    : `${styles.formInputStyle} scrollBar`
                                            }
                                        >
                                            {operator === "Sequence dates" ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <span>from</span>
                                                    <br />
                                                    <input
                                                        className={
                                                            styles.repeatInputStyle
                                                        }
                                                        type="date"
                                                        onChange={(e) =>
                                                            setValueRange({
                                                                ...valueRange,
                                                                start:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                    <br />
                                                    <span>to</span>
                                                    <br />
                                                    <input
                                                        className={
                                                            styles.repeatInputStyle
                                                        }
                                                        type="date"
                                                        onChange={(e) =>
                                                            setValueRange({
                                                                ...valueRange,
                                                                end:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                inputStateFunction()
                                            )}
                                        </div>
                                        {operator === "Sequence dates"
                                            ? ""
                                            : inputState.values.length <= 9 && (
                                                  <input
                                                      className={
                                                          styles.repeatInputStyle1
                                                      }
                                                      type="button"
                                                      value="[ENTER] to add more"
                                                      onClick={() => {
                                                          if (
                                                              operator ===
                                                              "Repeat values"
                                                          ) {
                                                              let arr = inputState.filter(
                                                                  (item) =>
                                                                      item.values !==
                                                                      ""
                                                              );
                                                              if (
                                                                  arr.length > 0
                                                              ) {
                                                                  arr.push(
                                                                      ...arr
                                                                  );
                                                                  setInputState(
                                                                      [...arr]
                                                                  );
                                                              } else {
                                                                  setInputState(
                                                                      [
                                                                          ...inputState,
                                                                      ]
                                                                  );
                                                              }
                                                          } else {
                                                              setInputState(
                                                                  (
                                                                      prevState
                                                                  ) => [
                                                                      ...prevState,
                                                                      {
                                                                          values:
                                                                              "",
                                                                          placeholder1:
                                                                              "",
                                                                          placeholder2:
                                                                              "",
                                                                          placeholder3:
                                                                              "",
                                                                      },
                                                                  ]
                                                              );
                                                          }
                                                      }}
                                                      placeholder="[ENTER] to add more"
                                                  />
                                              )}
                                    </div>
                                ) : operator === randomGenerator[1].label ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            width: "20%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    marginBottom: "10px",
                                                    fontSize: 16,
                                                    marginTop: 5,
                                                }}
                                            >
                                                from
                                            </p>
                                            <input
                                                className={` ${styles.repeatInputStyle}`}
                                                type="number"
                                                onChange={(e) =>
                                                    setValueRange({
                                                        ...valueRange,
                                                        start: e.target.value,
                                                    })
                                                }
                                                placeholder="0"
                                                style={{ width: "100%" }}
                                            />
                                            <p
                                                style={{
                                                    marginBottom: "10px",
                                                    fontSize: 16,
                                                    marginTop: 5,
                                                }}
                                            >
                                                to
                                            </p>
                                            <input
                                                className={` ${styles.repeatInputStyle}`}
                                                type="number"
                                                onChange={(e) =>
                                                    setValueRange({
                                                        ...valueRange,
                                                        end: e.target.value,
                                                    })
                                                }
                                                placeholder="10"
                                                style={{ width: "100%" }}
                                            />
                                            <p
                                                style={{
                                                    marginBottom: "10px",
                                                    fontSize: 16,
                                                    marginTop: 5,
                                                }}
                                            >
                                                at steps of
                                            </p>
                                            <input
                                                className={` ${styles.repeatInputStyle}`}
                                                type="number"
                                                onChange={(e) =>
                                                    setValueRange({
                                                        ...valueRange,
                                                        steps: Number(
                                                            e.target.value
                                                        ),
                                                    })
                                                }
                                                placeholder="2"
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                    </div>
                                ) : operator === randomGenerator[2].label ? (
                                    <div
                                        style={{
                                            width: "20%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div
                                            className={
                                                styles.uniformDistContainer
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            <p style={{ textAlign: "center" }}>
                                                Uniform Distribution
                                            </p>
                                            <div
                                                className={
                                                    styles.uniformDistlower
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.uniformDist
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.uniformDistValuesBox
                                                }
                                            >
                                                <div>
                                                    <p>min</p>
                                                    <input
                                                        type={"number"}
                                                        className={`${styles.repeatInputStyle} ${styles.uniformDistInput}`}
                                                        placeholder="0"
                                                        onChange={(e) =>
                                                            setValueRange({
                                                                ...valueRange,
                                                                start:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <p>max</p>
                                                    <input
                                                        type={"number"}
                                                        className={`${styles.repeatInputStyle} ${styles.uniformDistInput}`}
                                                        placeholder="20"
                                                        onChange={(e) =>
                                                            setValueRange({
                                                                ...valueRange,
                                                                end:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : operator === randomGenerator[3].label ? (
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "20%",
                                        }}
                                    >
                                        <img
                                            alt=""
                                            className={styles.normalDistImg}
                                            src="/dist/img/data-exploration/Group1.svg"
                                        />
                                        <div
                                            className={
                                                styles.normalDistValuebox
                                            }
                                        >
                                            <p>mean</p>
                                            <input
                                                type={"number"}
                                                onChange={(e) =>
                                                    setNormalDistValue(
                                                        Number(e.target.value)
                                                    )
                                                }
                                                className={`${styles.repeatInputStyle}`}
                                                placeholder="0"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </>
                        )}

                        {operationType === operations[2].name ? (
                            <div style={{ width: "calc(35% - 180px)" }}>
                                <div
                                    className={styles.pOperator}
                                    style={{ width: "25%" }}
                                >
                                    {")"}
                                </div>
                            </div>
                        ) : operationType === operations[4].name ||
                          operationType === operations[5].name ||
                          operationType === operations[6].name ||
                          operationType === operations[3].name ? (
                            <div style={{ width: "calc(45% - 315px)" }}>
                                <div className={styles.squareBracket1}></div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            )}

            {showFilter === "Filter Rows" && (
                <div className={styles.variablesContainer}>
                    <div className={styles.variablesWrapper}>
                        <ConditionsSelector
                            style={{ width: "380px" }}
                            key={dataset?.value}
                            small
                            dataScopeId={dataset?.value}
                            value={conditions}
                            title={""}
                            onChange={setConditions}
                            allVariables={
                                Variables(dataset?.value).dataVariables
                            }
                            {...ConditionSelectorStyles}
                            asWizard={false}
                            logicalButtons={true}
                            switch={true}
                            tab="Slice"
                        />
                    </div>
                </div>
            )}
            {methodType === "Python" && (
                <div
                    className={styles.variablesContainer}
                    style={{ marginTop: 26 }}
                >
                    <div
                        className="my-row"
                        style={{
                            alignItems: "center",
                            marginBottom: "10px",
                        }}
                    >
                        <div
                            className={styles.textItem}
                            style={{ marginBottom: 0 }}
                        >
                            {rowLevel
                                ? "Please use Pandas syntax python code to create new variables or edit existing. The row variable is called 'row'."
                                : "Please use Pandas syntax python code to create new variables or edit existing. Your dataset is called 'data'."}
                        </div>
                        <img
                            data-tip="Here is a list of functions that we currently support:<br/>
                range; pandas functions: pd.isnull, pd.MultiIndex.droplevel;<br/>
                pandas DataFrame and Series methods: .mean, .rank.,<br/>
                .max, .min, .sum, .std, .count, .groupby, .transform, .rolling; <br/>
                pandas Series string methods: .str.contains,
                .str.endswith, .str.extract,<br/>
                .str.extractall, .str.match, .str.slice, .str.slice_replace, .str.startswith"
                            data-for="functionsList"
                            src="/dist/img/data-exploration/question.svg"
                            alt=""
                            style={{
                                marginLeft: 5,
                            }}
                        />
                        <ReactTooltip
                            effect="solid"
                            id="functionsList"
                            multiline={true}
                        />
                    </div>
                    <VariableCreator
                        dataScopeId={table?.data_table_idx!}
                        value={genericVariables}
                        onChange={(newVariables) => {
                            setGenericVariables(newVariables);
                        }}
                        tab="createVariable"
                    />
                    <div style={{ paddingTop: "10px" }}>
                        <textarea
                            className={`${styles.textArea} like-select element`}
                            style={{
                                height: "250px",
                                width: "100%",
                                fontFamily: "roboto",
                                resize: "vertical",
                                whiteSpace: "pre",
                                borderRadius: 5,
                                padding: 12,
                                border: "none",
                            }}
                            spellCheck={false}
                            placeholder=""
                            onChange={(e) => {
                                let value = e.target.value;
                                setCreatorCode(value);
                                SelectedOption.UpdateaskSwitchTab(false);
                                SelectedOption.UpdateSwitchTab(false);
                            }}
                            ref={creatorTextAreaRef}
                            onKeyDown={(e) => {
                                e.stopPropagation();
                                processTab(
                                    e,
                                    (
                                        newSelectionStart,
                                        newSelectionEnd,
                                        newValue
                                    ) => {
                                        setCreatorCode(newValue);
                                        setTimeout(() => {
                                            creatorTextAreaRef.current!.selectionStart = newSelectionStart;
                                            creatorTextAreaRef.current!.selectionEnd = newSelectionEnd;
                                        });
                                    }
                                );
                            }}
                            value={creatorCode}
                        />
                    </div>
                </div>
            )}

            <div className={"buttonsContainer"}>
                <button
                    className={"cancelBtn"}
                    type="button"
                    onClick={() => {
                        makeDefault();
                        setDataset(null);
                        setDemoPreviewBody(previewBody);
                        setDemoPreviewHeader(previewHeader);
                        DataScopes.previewState = { headers: null, body: null };
                        setOperationType(operations[0].name);
                        setConditions(ConditionsSelector.defaultValue);
                    }}
                >
                    {"Reset"}
                </button>
                <button
                    className={"acceptBtn"}
                    type="button"
                    disabled={
                        !creatorCode ||
                        creating ||
                        dataset == null ||
                        table == null ||
                        genericVariables[0].name === "" ||
                        genericVariables[0].name === null ||
                        (methodType === "GUI" &&
                            (finalVariable?.header.name === "" || disable))
                    }
                    onClick={() => {
                        executeCreateVariables(
                            table!,
                            creatorCode,
                            genericVariables,
                            rowLevel,
                            true
                        );
                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(false);
                    }}
                >
                    {"Preview"}
                </button>
            </div>

            <div className={styles.mergedPreview}>
                <div className={styles.previewContainer}>
                    {previewInfo != null ? (
                        <div
                            className={`${styles.mergedTableWrapper} scrollBar`}
                        >
                            <AdminTableWithFullFeatures
                                tableName="CreateTable"
                                className="import-table"
                                paging={false}
                                small={false}
                                tableHeader={previewInfo.tableHeader}
                                tableContent={previewInfo.tablePreview}
                            />
                        </div>
                    ) : (
                        <p
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            Preview affected rows
                        </p>
                    )}
                </div>
            </div>

            <div className={"pageEndbuttons"} style={{ marginRight: 0 }}>
                <button
                    style={{ paddingBlock: 7 }}
                    className={"acceptBtn"}
                    type="button"
                    disabled={
                        !creatorCode ||
                        creating ||
                        dataset == null ||
                        table == null ||
                        previewInfo == null
                    }
                    onClick={() => {
                        executeCreateVariables(
                            table!,
                            creatorCode,
                            genericVariables,
                            rowLevel,
                            false
                        );
                        makeDefault();
                    }}
                >
                    {"Create"}
                </button>
            </div>

            {SelectedOption.getAskSwitchTab &&
                (dataset !== null ||
                    genericVariables[0].name !== null ||
                    creatorCode !== variableCreatorSample) && (
                    <TabSwitchConfirmationPopup />
                )}

            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
            {DataScopes.previewState.headers === null && dataset != null && (
                <StatusPopup
                    status={PopupStatus.Error}
                    message={"Loading Data"}
                    onClose={() => {}}
                />
            )}
        </div>
    );
}

export default observer(CreateVariableSection);
