import React from "react";
import { Button } from "react-bootstrap";
import { Element } from "react-scroll";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import variables from "common/Variables";
import { getRawDataApi } from "common/DataApi";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
                rawData: null,
            };
        }

        getRawData() {
            if (!this.props.commonState.viewDataTable) return;
            this.setStepState(StepState.running);

            getRawDataApi(this.props.commonState.viewDataTable, 5)
                .then((data) => {
                    this.setStepState(StepState.finished, null, {
                        rawData: data.currentLevels,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setStepState(StepState.error, String(error));
                });
        }

        render() {
            let dataTable;
            if (
                this.state.expanded &&
                this.state.rawData !== null &&
                variables(this.props.commonState.viewDataScopeId)
                    .dataVariables !== null
            ) {
                let tableHeader = variables(
                    this.props.commonState.viewDataScopeId
                ).dataVariables.map((value, index, array) => value.name);
                let tableContent = [];
                let rowCount = Object.values(this.state.rawData)[0].length;
                for (let i = 0; i < rowCount; ++i) {
                    let row = [];
                    for (let varName of tableHeader) {
                        if (typeof this.state.rawData[varName] === "undefined")
                            break;
                        row.push(
                            this.state.rawData[varName][i] ||
                                (typeof this.state.rawData[varName][i] ===
                                "number"
                                    ? this.state.rawData[varName][i]
                                    : "-")
                        );
                    }
                    tableContent.push(row);
                }
                dataTable = (
                    <Element
                        name="scrollable"
                        className="element"
                        style={{
                            overflowX: "scroll",
                            position: "relative",
                            overflowY: "scroll",
                            backgroundColor: "#3f4b62",
                        }}
                    >
                        <AdminTableWithFullFeatures
                            className="processing-page-table"
                            paging={false}
                            small={false}
                            tableName="Data"
                            tableHeader={tableHeader}
                            tableContent={tableContent}
                        />
                    </Element>
                );
            } else {
                dataTable = null;
            }

            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        disabled={this.props.disabled}
                        className="btn-processing-tab"
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState(
                                (state) => ({
                                    expanded: !state.expanded,
                                }),
                                () => {
                                    if (this.state.rawData === null)
                                        this.getRawData();
                                }
                            );
                        }}
                    >
                        <span className="content-regular-text">
                            View head of raw data
                        </span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    {this.state.expanded ? (
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            onClick={() => {
                                this.getRawData();
                            }}
                            style={{
                                fontFamily: "Roboto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: "25px",
                                width: "100%",
                                minWidth: "110px",
                            }}
                        >
                            REFRESH
                        </Button>
                    ) : (
                        <div style={{ minWidth: "110px" }} />
                    )}
                    {this.state.expanded ? (
                        <div>{this.renderStepState()}</div>
                    ) : (
                        <div />
                    )}
                    {dataTable !== null &&
                        this.state.expanded && [
                            <div />,
                            <div
                                style={{
                                    overflow: "hidden",
                                    gridColumnStart: 2,
                                    gridColumnEnd: 4,
                                }}
                            >
                                {dataTable}
                            </div>,
                            <div />,
                        ]}
                </>
            );
        }
    }
);

export { MainComponent };
