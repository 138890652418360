import React, { Component } from "react";

import Select from "react-select";
import customSelectStyles from "common/SelectStyles";

import MetricsTable from "./MetricsTable";
import MapComponent from "./MapComponent";
import sections from "sections.json";
import ViewOption from "./ViewOption";

class FinalStep1Ai extends Component {
	constructor(props) {
		super(props);
		let viewOption = this.props.config?.viewOption ?? ViewOption.Both;
		if (
			viewOption === ViewOption.Both ||
			viewOption === ViewOption.Leaders
		) {
			this.state = {
				currentValue: { label: "LEADERS", value: 0 },
			};
		} else {
			this.state = {
				currentValue: { label: "LAGGARDS", value: 1 },
			};
		}
	}

	render() {
		const {
			editable,
			config,
			onConfigChange,
			compareInfo,
			targetVariable,
			units,
			showMap,
			// frozen,
			geographyLevels,
			showedVariables,
		} = this.props;
		let { labelMapping, colorMapping } = config ?? {};

		let defaultFirstKey =
			compareInfo.length === 2
				? `${compareInfo[0].group}${compareInfo[0].operator ?? "="}${
						compareInfo[0].label
				  }`
				: "";
		let defaultFirstColor = "#39F";
		let defaultSecondKey =
			compareInfo.length === 2
				? `${compareInfo[1].group}${compareInfo[1].operator ?? "="}${
						compareInfo[1].label
				  }`
				: "";
		let defaultSecondColor = "#843FA0";
		let leaders = [];
		let laggers = [];
		if (compareInfo.length === 2) {
			leaders =
				compareInfo[0][
					this.state.currentValue.value === 0 ? "leaders" : "laggers"
				];
			laggers =
				compareInfo[1][
					this.state.currentValue.value === 0 ? "leaders" : "laggers"
				];
		}
		let viewOption = this.props.config?.viewOption ?? ViewOption.Both;

		return (
			<div
				className="my-row"
				style={{
					alignItems: "center",
					overflow: "auto",
					justifyContent: "space-between",
					width: "100%",
					height: "100%",
				}}
			>
				{showMap && (
					<>
						<div />
						<div
							className="flex-simple-column"
							style={{
								borderRadius: "8px",
								border: "1px solid #242E42",
								width: "50%",
								maxHeight: 350,
							}}
						>
							<MapComponent
								geographyLevels={geographyLevels}
								leaders={leaders}
								leadersColor={
									colorMapping?.[defaultFirstKey] ??
									defaultFirstColor
								}
								laggersColor={
									colorMapping?.[defaultSecondKey] ??
									defaultSecondColor
								}
								laggers={laggers}
								height={230}
								watermark={sections._Global?.watermark}
							/>
						</div>
					</>
				)}
				{!showMap && <div />}
				<div
					className="flex-simple-column element"
					style={{
						height: "100%",
						overflow: "auto",
						width: "50%",
						marginLeft: 20,
					}}
				>
					{viewOption === ViewOption.Both && (
						<div style={{ width: 110, alignSelf: "flex-end" }}>
							<Select
								styles={customSelectStyles}
								value={this.state.currentValue}
								options={[
									{ label: "LEADERS", value: 0 },

									{ label: "LAGGARDS", value: 1 },
								]}
								onChange={(val) => {
									this.setState({ currentValue: val });
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 0,
									colors: {
										...theme.colors,
										text: "white",
										primary25:
											"var(--selectors-background-hover-color)",
									},
								})}
							/>
						</div>
					)}
					<MetricsTable
						editable={editable}
						showedVariables={showedVariables}
						data={leaders}
						title={
							labelMapping?.[defaultFirstKey] ?? defaultFirstKey
						}
						color={
							colorMapping?.[defaultFirstKey] ?? defaultFirstColor
						}
						onColorChange={(color) => {
							let newColorMapping = {
								...colorMapping,
								[defaultFirstKey]: color,
							};
							let newConfig = {
								...config,
								colorMapping: newColorMapping,
							};
							onConfigChange(newConfig);
						}}
						onTitleChange={(title) => {
							let newLabelMapping = {
								...labelMapping,
								[defaultFirstKey]: title,
							};
							let newConfig = {
								...config,
								labelMapping: newLabelMapping,
							};
							onConfigChange(newConfig);
						}}
						selectedVariable={targetVariable}
						units={units}
					/>
					<MetricsTable
						editable={editable}
						showedVariables={showedVariables}
						data={laggers}
						title={
							labelMapping?.[defaultSecondKey] ?? defaultSecondKey
						}
						color={
							colorMapping?.[defaultSecondKey] ??
							defaultSecondColor
						}
						onTitleChange={(title) => {
							let newLabelMapping = {
								...labelMapping,
								[defaultSecondKey]: title,
							};
							let newConfig = {
								...config,
								labelMapping: newLabelMapping,
							};
							onConfigChange(newConfig);
						}}
						onColorChange={(color) => {
							let newColorMapping = {
								...colorMapping,
								[defaultSecondKey]: color,
							};
							let newConfig = {
								...config,
								colorMapping: newColorMapping,
							};
							onConfigChange(newConfig);
						}}
						selectedVariable={targetVariable}
						units={units}
					/>
				</div>
				{!showMap && <div />}
			</div>
		);
	}
}
export default FinalStep1Ai;