import React, { Component, CSSProperties } from "react";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";
import Select, { createFilter } from "react-select";

import customSelectStyles, { customFilterStyles } from "common/SelectStyles";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Tables, { TableOption } from "common/Tables";
import Variables from "common/Variables";
import { ConditionsSelector, Condition } from "common/Conditions";
import { CanvasDataTableInputDetails } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import { mainStyle } from "common/MainStyle";
import aggregationOptions from "./AggregationOptions";
import { lightThemeStyle } from "common/LightThemeStyle";
import remoteModuleId from "common/remoteModuleId";
import StringUtils from "common/utilities/StringUtils";

export interface VariableInfo {
    name?: string | null; // legacy
    index: number | null;
    aggregation: string;
    alias: string;
    percentile?: number;
    correlation_with_index?: number;
}

export interface DataTableInput {
    dataScope: DataScopeOption | null;
    table: TableOption | null;
    conditions: Condition[];
    variables: VariableInfo[];
    zeroIfNoData?: boolean; // Assign 0 instead of NaN if there is no data
}

export function toCanvasDataTableInputDetails(
    dataTableInput: DataTableInput[]
): CanvasDataTableInputDetails[] {
    let result: CanvasDataTableInputDetails[] = [];
    for (let input of dataTableInput) {
        if (input.dataScope != null && input.table != null) {
            let variables: CanvasDataTableInputDetails["variables"] = input.variables.filter(
                (variableInfo: VariableInfo) => variableInfo.index != null
            ) as CanvasDataTableInputDetails["variables"];
            if (variables.length !== 0) {
                let conditions: Condition[] = input.conditions.filter(
                    (condition) =>
                        condition.variable != null &&
                        condition.operation != null &&
                        condition.value != null
                );
                result.push({
                    data_table_idx: input.dataScope.value,
                    optimized: input.table.optimized,
                    table: input.table.value,
                    condition_id: input.table.condition_id,
                    conditions: conditions,
                    update_time: 0,
                    variables: variables,
                    zero_if_no_data: input.zeroIfNoData,
                });
            }
        }
    }
    return result;
}

export async function fromCanvasDataTableInputDetails(
    dataTableInputDetails: CanvasDataTableInputDetails[],
    currentModuleId?: number
): Promise<DataTableInput[]> {
    let dataScopes =
        currentModuleId != null
            ? DataScopesForModules(currentModuleId)
            : DataScopes;

    let result: DataTableInput[] = [];
    let processedDataTableIdx: Set<number | string> = new Set();
    for (let item of dataTableInputDetails) {
        if (item.data_table_idx != null) {
            if (!processedDataTableIdx.has(item.data_table_idx)) {
                await Tables(
                    item.data_table_idx,
                    currentModuleId ?? remoteModuleId
                ).update(currentModuleId ?? remoteModuleId);
                await Variables(
                    item.data_table_idx,
                    currentModuleId ?? remoteModuleId
                ).update(currentModuleId ?? remoteModuleId);
                processedDataTableIdx.add(item.data_table_idx);
            }
            const table: TableOption | null =
                (item.optimized
                    ? Tables(
                          item.data_table_idx,
                          currentModuleId ?? remoteModuleId
                      ).rawAndOptimizedTableOptions
                    : Tables(
                          item.data_table_idx,
                          currentModuleId ?? remoteModuleId
                      ).rawAndAggregateTableOptions
                ).find(
                    (option) =>
                        option.value.join("_") === item.table.join("_") &&
                        option.condition_id === item.condition_id
                ) ?? null;
            let conditions: Condition[] =
                item.conditions.length !== 0
                    ? item.conditions
                    : ConditionsSelector.defaultValue;
            result.push({
                dataScope:
                    dataScopes.dataScopesOptions.find(
                        (option) => option.value === item.data_table_idx
                    ) ?? null,
                table: table,
                conditions: conditions,
                variables: item.variables.map(
                    (variable): VariableInfo => {
                        if (variable.name != null) {
                            const variableIndex: number = Variables(
                                item.data_table_idx,
                                currentModuleId ?? remoteModuleId
                            ).variableNames.indexOf(variable.name);
                            return {
                                index: variableIndex < 0 ? 0 : variableIndex,
                                aggregation: variable.aggregation,
                                alias: variable.alias,
                                percentile: variable.percentile,
                                correlation_with_index:
                                    variable.correlation_with_index,
                            };
                        } else {
                            return variable;
                        }
                    }
                ),
                zeroIfNoData: item.zero_if_no_data,
            });
        } else {
            result.push(emptyInput);
        }
    }
    return result;
}

interface Props {
    style?: CSSProperties;
    value: DataTableInput[];
    onChange: (dataTableInput: DataTableInput[]) => void;
    nodeLinkOptions: NodeLinkOption[];
    currentModuleId?: number;
}

const emptyVariables: VariableInfo[] = [
    {
        index: null,
        aggregation: "Mean",
        alias: "",
    },
];

const emptyInput: DataTableInput = {
    dataScope: null,
    table: null,
    conditions: ConditionsSelector.defaultValue,
    variables: emptyVariables,
};

const DataTableInputSelector = observer(
    class DataTableInputSelector extends Component<Props> {
        private renderVariableSelectorError(
            variableInfo: VariableInfo,
            aliasCounts: { [key: string]: number }
        ): JSX.Element | null {
            let error: string | null = null;
            if (variableInfo.index != null) {
                if (variableInfo.alias.length === 0)
                    error = "Alias can't be empty";
                else if (aliasCounts[variableInfo.alias] > 1) {
                    error = "Aliases cannot repeat";
                }
            }

            if (error != null) {
                return (
                    <span
                        style={{
                            flex: 1,
                            marginLeft: 10,
                            textAlign: "left",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: 14,
                        }}
                    >
                        <img
                            src="/dist/img/error.png"
                            alt=""
                            style={{
                                marginRight: 5,
                            }}
                        />
                        {error}
                    </span>
                );
            } else {
                return null;
            }
        }

        private renderVariableSelector(
            value: DataTableInput[],
            item: DataTableInput,
            index: number
        ): JSX.Element[] {
            if (item.dataScope == null && item.table == null) return [];
            const allVariableInfo: VariableInfo[] =
                item.variables.length !== 0 ? item.variables : emptyVariables;
            const aliasCounts: { [key: string]: number } = {};
            for (let variableInfo of allVariableInfo) {
                aliasCounts[variableInfo.alias] =
                    (aliasCounts[variableInfo.alias] ?? 0) + 1;
            }

            return allVariableInfo.map(
                (variableInfo: VariableInfo, variableIndex: number) => (
                    <React.Fragment key={variableIndex}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "5px",
                                width: "100%",
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    width: 100,
                                    textAlign: "left",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontFamily: "Roboto",
                                    fontSize: 14,
                                }}
                            >
                                {variableIndex === 0 ? "select" : ""}
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        height: "38px",
                                        marginLeft: "5px",
                                        width: 95,
                                    }),
                                }}
                                options={aggregationOptions}
                                value={{
                                    label: variableInfo.aggregation,
                                    value: variableInfo.aggregation,
                                }}
                                onChange={(option) => {
                                    const newValue: DataTableInput[] = Array.from(
                                        value
                                    );
                                    const newVariables: VariableInfo[] = Array.from(
                                        newValue[index].variables
                                    );
                                    let aggregation = (option as {
                                        label: string;
                                        value: string;
                                    }).value;
                                    let currentVariableIndex =
                                        newVariables[variableIndex].index;
                                    let variableName = "";
                                    if (currentVariableIndex != null) {
                                        variableName = Variables(
                                            item.dataScope!.value,
                                            this.props.currentModuleId ??
                                                remoteModuleId
                                        ).variableNames[currentVariableIndex];
                                    }
                                    newVariables[variableIndex] = {
                                        ...newVariables[variableIndex],
                                        aggregation: aggregation,
                                        alias: StringUtils.normalizeString(
                                            `${aggregation} ${
                                                variableName ?? ""
                                            }`
                                        ).toUpperCase(),
                                    };
                                    newValue[index] = {
                                        ...newValue[index],
                                        variables: newVariables,
                                    };
                                    this.props.onChange(newValue);
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        height: "38px",
                                        marginLeft: "10px",
                                        width: 95,
                                    }),
                                }}
                                options={Variables(
                                    item.dataScope!.value,
                                    this.props.currentModuleId ?? remoteModuleId
                                ).variableNames.map((name, index) => ({
                                    label: name,
                                    value: index,
                                }))}
                                value={{
                                    label:
                                        variableInfo.index != null
                                            ? Variables(
                                                  item.dataScope!.value,
                                                  this.props.currentModuleId ??
                                                      remoteModuleId
                                              ).variableNames[
                                                  variableInfo.index
                                              ]
                                            : "",
                                    value: variableInfo.index!,
                                }}
                                onChange={(option) => {
                                    const newValue: DataTableInput[] = Array.from(
                                        value
                                    );
                                    const newVariables: VariableInfo[] = Array.from(
                                        newValue[index].variables
                                    );
                                    const newOption = option as {
                                        label: string;
                                        value: number;
                                    };
                                    const newIndex = newOption.value;
                                    newVariables[variableIndex] = {
                                        ...newVariables[variableIndex],
                                        index: newIndex,
                                        alias: StringUtils.normalizeString(
                                            `${newVariables[variableIndex].aggregation} ${newOption.label}`
                                        ).toUpperCase(),
                                    };
                                    newValue[index] = {
                                        ...newValue[index],
                                        variables: newVariables,
                                    };
                                    this.props.onChange(newValue);
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <span
                                style={{
                                    marginLeft: 10,
                                    textAlign: "left",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontFamily: "Roboto",
                                    fontSize: 14,
                                }}
                            >
                                as
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 100,
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "38px",
                                    textAlign: "left",
                                    marginLeft: "10px",
                                }}
                                placeholder="alias"
                                onChange={(e) => {
                                    const newValue: DataTableInput[] = Array.from(
                                        value
                                    );
                                    const newVariables: VariableInfo[] = Array.from(
                                        newValue[index].variables
                                    );
                                    const newAlias = e.target.value;
                                    newVariables[variableIndex] = {
                                        ...newVariables[variableIndex],
                                        alias: newAlias.toUpperCase(),
                                    };
                                    newValue[index] = {
                                        ...newValue[index],
                                        variables: newVariables,
                                    };
                                    this.props.onChange(newValue);
                                }}
                                value={variableInfo.alias}
                            />
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 5 }}
                            >
                                <Button
                                    className="btn-small-like-select"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        const newValue: DataTableInput[] = Array.from(
                                            value
                                        );
                                        const newVariables: VariableInfo[] = Array.from(
                                            newValue[index].variables
                                        );
                                        newVariables.push(emptyVariables[0]);
                                        newValue[index] = {
                                            ...newValue[index],
                                            variables: newVariables,
                                        };
                                        this.props.onChange(newValue);
                                    }}
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>
                                <Button
                                    className="btn-small-like-select"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        const newValue: DataTableInput[] = Array.from(
                                            value
                                        );
                                        const newVariables: VariableInfo[] = Array.from(
                                            newValue[index].variables
                                        );
                                        newVariables.splice(variableIndex, 1);
                                        newValue[index] = {
                                            ...newValue[index],
                                            variables: newVariables,
                                        };
                                        this.props.onChange(newValue);
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                            {this.renderVariableSelectorError(
                                variableInfo,
                                aliasCounts
                            )}
                        </div>
                    </React.Fragment>
                )
            );
        }

        render() {
            let dataScopes =
                this.props.currentModuleId != null
                    ? DataScopesForModules(this.props.currentModuleId)
                    : DataScopes;
            const value: DataTableInput[] =
                this.props.value.length !== 0 ? this.props.value : [emptyInput];
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        marginTop: "5px",
                        marginLeft: "26px",
                        justifyContent: "start",
                        ...this.props.style,
                    }}
                >
                    {value.map((item: DataTableInput, index: number) => (
                        <React.Fragment key={index}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    alignItems: "center",
                                    marginTop: index === 0 ? undefined : 5,
                                }}
                            >
                                <span
                                    style={{
                                        width: 100,
                                        textAlign: "left",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        fontFamily: "Roboto",
                                        fontSize: 14,
                                    }}
                                >
                                    Input {index + 1}
                                </span>
                                <div
                                    className="flex-simple-column"
                                    style={{ marginLeft: 5 }}
                                >
                                    <Button
                                        className="btn-small-like-select"
                                        style={{
                                            width: "19px",
                                            height: "19px",
                                        }}
                                        onClick={() => {
                                            const newValue: DataTableInput[] = Array.from(
                                                value
                                            );
                                            newValue.push(emptyInput);
                                            this.props.onChange(newValue);
                                        }}
                                    >
                                        {"\uFF0B" /* plus */}
                                    </Button>
                                    <Button
                                        className="btn-small-like-select"
                                        style={{
                                            width: "19px",
                                            height: "19px",
                                        }}
                                        onClick={() => {
                                            const newValue: DataTableInput[] = Array.from(
                                                value
                                            );
                                            newValue.splice(index, 1);
                                            this.props.onChange(newValue);
                                        }}
                                    >
                                        {"\uFF0D" /* minus */}
                                    </Button>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "5px",
                                    width: "100%",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        width: 100,
                                        textAlign: "left",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        fontFamily: "Roboto",
                                        fontSize: 14,
                                    }}
                                >
                                    from dataset
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            flex: 1,
                                            height: "38px",
                                            marginLeft: 5,
                                        }),
                                    }}
                                    options={dataScopes.dataScopesOptions}
                                    onChange={(option) => {
                                        const newValue: DataTableInput[] = Array.from(
                                            value
                                        );
                                        newValue[index] = {
                                            ...emptyInput,
                                            dataScope: option as DataScopeOption | null,
                                            table: Tables(
                                                (option as DataScopeOption)
                                                    .value,
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            ).tableToOption(),
                                        };
                                        this.props.onChange(newValue);
                                    }}
                                    value={item.dataScope}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            {item.dataScope != null && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "5px",
                                        width: "100%",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            width: 100,
                                            textAlign: "left",
                                            color: mainStyle.getPropertyValue(
                                                "--popup-primary-text-color"
                                            ),
                                            fontFamily: "Roboto",
                                            fontSize: 14,
                                        }}
                                    >
                                        and table
                                    </span>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                flex: 1,
                                                height: "38px",
                                                marginLeft: 5,
                                            }),
                                        }}
                                        options={Tables(
                                            item.dataScope.value,
                                            this.props.currentModuleId ??
                                                remoteModuleId
                                        ).rawAndAggregateTableOptions.concat(
                                            Tables(
                                                item.dataScope.value,
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            ).rawAndOptimizedTableOptions
                                        )}
                                        onChange={(option) => {
                                            const newValue: DataTableInput[] = Array.from(
                                                value
                                            );
                                            newValue[index] = {
                                                ...newValue[index],
                                                table: option as TableOption | null,
                                            };
                                            this.props.onChange(newValue);
                                        }}
                                        value={item.table}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            )}
                            {item.dataScope != null && item.table != null && (
                                <>
                                    <ConditionsSelector
                                        allowLinks
                                        nodeLinkOptions={
                                            this.props.nodeLinkOptions
                                        }
                                        currentModuleId={
                                            this.props.currentModuleId
                                        }
                                        small
                                        dataScopeId={item.dataScope.value}
                                        title={"where"}
                                        style={{
                                            marginTop: 0,
                                            backgroundColor: "transparent",
                                        }}
                                        value={item.conditions}
                                        onChange={(conditions: Condition[]) => {
                                            const newValue: DataTableInput[] = Array.from(
                                                value
                                            );
                                            newValue[index] = {
                                                ...newValue[index],
                                                conditions: conditions,
                                            };
                                            this.props.onChange(newValue);
                                        }}
                                        allVariables={
                                            Variables(
                                                item.dataScope.value,
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            ).dataVariables
                                        }
                                        titleStyle={{
                                            color: mainStyle.getPropertyValue(
                                                "--popup-primary-text-color"
                                            ),
                                            width: 100,
                                            textAlign: "start",
                                        }}
                                        linkElementStyle={{
                                            width: undefined,
                                        }}
                                        rowStyle={(index) => ({
                                            backgroundColor: "transparent",
                                            marginLeft:
                                                index === 0 ? undefined : 30,
                                        })}
                                        rowStyleWithoutPlusMinus={{
                                            backgroundColor: "transparent",
                                        }}
                                        linkSwitchStyle={{
                                            offColor:
                                                lightThemeStyle.switchOffColor,
                                            onColor:
                                                lightThemeStyle.switchOnColor,
                                            offHandleColor:
                                                lightThemeStyle.switchOffHandleColor,
                                            onHandleColor:
                                                lightThemeStyle.switchOnHandleColor,
                                        }}
                                        inputStyle={{
                                            width: 94,
                                            marginLeft: 3,
                                            marginRight: 3,
                                            marginBottom: 0,
                                            backgroundColor: undefined,
                                            color: undefined,
                                        }}
                                        buttonsStyle={{
                                            backgroundColor: "transparent",
                                            color:
                                                "var(--popup-primary-text-color)",
                                            fontFamily: "Roboto",
                                            fontSize: "12px",
                                        }}
                                        selectStyle={{
                                            control: (base, state) => ({
                                                ...customFilterStyles.control!(
                                                    base,
                                                    state
                                                ),
                                                backgroundColor:
                                                    "var(--selectors-background-color)",
                                            }),
                                            input: (base, state) => ({
                                                ...customFilterStyles.input!(
                                                    base,
                                                    state
                                                ),
                                            }),
                                            singleValue: (base, state) => ({
                                                ...customFilterStyles.singleValue!(
                                                    base,
                                                    state
                                                ),
                                                color:
                                                    "var(--selectors-text-color)",
                                            }),
                                        }}
                                    />
                                    {this.renderVariableSelector(
                                        value,
                                        item,
                                        index
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            );
        }
    }
);

export default DataTableInputSelector;
