import React from "react";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";
import { Styles } from "common/SelectStyles";
import { CanvasSubmitButton, SubmitType, SubmitOption } from "common/Canvas";
import Tables, { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Variables, { VariableOption } from "common/Variables";
import { Permission } from "common/Permissions";
import CanvasTreeStore from "../CanvasTreeStore";
import GlobalInputs from "common/GlobalInputs";
import remoteModuleId from "common/remoteModuleId";
import styles from "./SubmitOptionsSelector.module.css";
import cx from "classnames";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import Collapse from "react-bootstrap/Collapse";
import SharedBoxesStore from "../SharedBoxesStore";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import { InputType } from "../FormulaInformationStore";

interface Props {
    canvasTreeStore: CanvasTreeStore;
    value: Pick<CanvasSubmitButton, "backendOutput">;
    onChange: (
        changes: Partial<Pick<CanvasSubmitButton, "backendOutput">>
    ) => void;
    currentModuleId?: number;
}

interface InputOption {
    label: string;
    value: number | number[];
    type?: InputType;
    outputIndex?: number;
    canvasId?: number;
    outerId?: string;
}

const SubmitOptionsSelector = observer((props: Props) => {
    let [collapsedRows, setCollapsedRows] = React.useState<{
        [key: string]: boolean;
    }>({});
    const [contextOptions, setContextOptions] = React.useState<InputOption[]>([]);

    function getOptions(): InputOption[] {
        let options: InputOption[] = [];

        options = options
            .concat(
                GlobalInputs.map((globalInput) => ({
                    ...globalInput,
                    type: InputType.Global,
                }))
            );

        return options;
    }

    React.useEffect(() => {
        let options = getOptions();
        setContextOptions(options);
    }, []);

    const selectStyles: Styles = {
        container: (provided) => ({
            ...provided,
            flex: "1 1 auto",
        }),
        control: (provided) => ({
            ...provided,
            borderRadius: "4px",
            minWidth: "140px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
    };

    let dataScopes = props.currentModuleId
        ? DataScopesForModules(props.currentModuleId)
        : DataScopes;
    let nodeOptions: SubmitOption[] = [];
    for (let node of props.canvasTreeStore.canvasTreeState.values()) {
        nodeOptions.push({
            type: SubmitType.Regular,
            label: node.outerId,
            value: node.id,
        });
    }
    for (let item of SharedBoxesStore.sharedBoxes(
        props.canvasTreeStore.canvasId!
    )) {
        nodeOptions.push({
            label: item.label,
            value: item.value,
            type: SubmitType.Linked,
            outerId: item.outerId,
        });
    }
    for (let globalInput of GlobalInputs) {
        nodeOptions.push({
            type: SubmitType.Global,
            label: globalInput.label,
            value: globalInput.value,
        });
    }

    return (
        <div
            className="flex-simple-column"
            style={{ flex: 1, minHeight: 300 }}
            onKeyDown={(evt) => {
                evt.stopPropagation();
            }}
            onMouseDown={(evt) => {
                evt.stopPropagation();
            }}
        >
            <div>
                <div className={styles.field}>
                    <span className={styles.label}>Dataset</span>
                    <Select
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={"Write to backend data & column"}
                        styles={selectStyles}
                        options={dataScopes.dataScopesOptions.filter(
                            (option) =>
                                option.permissionType === Permission.ReadWrite
                        )}
                        onChange={(newValue) => {
                            let backendOutput = {
                                dataScopeOption: newValue as DataScopeOption,
                                tableOption: Tables(
                                    (newValue as DataScopeOption).value,
                                    props.currentModuleId ?? remoteModuleId
                                ).tableToOption(),
                                variableOptions: [
                                    {
                                        node: null,
                                        variable: null,
                                    },
                                ],
                            };
                            props.onChange({
                                backendOutput: backendOutput,
                            });
                        }}
                        value={
                            props.value.backendOutput?.dataScopeOption ?? null
                        }
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                </div>
            </div>

            {props.value.backendOutput?.dataScopeOption && (
                <div>
                    <div className={styles.field}>
                        <span className={styles.label}>Table</span>
                        <Select
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Select table"}
                            styles={selectStyles}
                            options={
                                Tables(
                                    props.value.backendOutput.dataScopeOption
                                        .value,
                                    props.currentModuleId ?? remoteModuleId
                                ).rawAndAggregateTableOptions
                            }
                            onChange={(newValue) => {
                                let backendOutput = Object.assign(
                                    {},
                                    props.value.backendOutput
                                );
                                backendOutput!.tableOption = newValue as TableOption;
                                backendOutput!.variableOptions = [
                                    {
                                        node: null,
                                        variable: null,
                                    },
                                ];
                                props.onChange({
                                    backendOutput: backendOutput,
                                });
                            }}
                            value={props.value.backendOutput!.tableOption}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                </div>
            )}
            {props.value.backendOutput?.dataScopeOption &&
                props.value.backendOutput.variableOptions.map(
                    (value, index) => (
                        <React.Fragment key={index}>
                            <div className={styles.collapsableLine}>
                                <div className={styles.lineButtons}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const collapsedRowsCopy = {
                                                ...collapsedRows,
                                            };

                                            const key = String(index);
                                            if (key in collapsedRowsCopy) {
                                                delete collapsedRowsCopy[key];
                                            } else {
                                                collapsedRowsCopy[key] = true;
                                            }

                                            setCollapsedRows(collapsedRowsCopy);
                                        }}
                                        className={
                                            styles.expressionCollapseButton
                                        }
                                    >
                                        <ChevronIcon
                                            className={cx(
                                                styles.expressionCollapseButtonIcon,
                                                {
                                                    [styles.chevronOpen]: !(
                                                        String(index) in
                                                        collapsedRows
                                                    ),
                                                }
                                            )}
                                        />
                                        Output {index + 1}
                                    </button>
                                    {index === 0 && (
                                        <button
                                            className={styles.addRemoveButton}
                                            type="button"
                                            onClick={() => {
                                                let backendOutput = {
                                                    ...props.value
                                                        .backendOutput,
                                                };

                                                backendOutput.variableOptions.push(
                                                    {
                                                        node: null,
                                                        variable: null,
                                                    }
                                                );
                                                props.onChange({
                                                    backendOutput: backendOutput,
                                                });
                                            }}
                                        >
                                            {"\uFF0B"} Add
                                        </button>
                                    )}
                                    <button
                                        className={styles.addRemoveButton}
                                        type="button"
                                        onClick={() => {
                                            let backendOutput = {
                                                ...props.value.backendOutput,
                                            };
                                            backendOutput.variableOptions.splice(
                                                index,
                                                1
                                            );
                                            if (
                                                backendOutput.variableOptions
                                                    .length === 0
                                            ) {
                                                backendOutput.variableOptions.push(
                                                    {
                                                        node: null,
                                                        variable: null,
                                                    }
                                                );
                                            }
                                            props.onChange({
                                                backendOutput: backendOutput,
                                            });
                                        }}
                                    >
                                        {"\u2715"}
                                    </button>
                                </div>
                                <Collapse
                                    in={!(String(index) in collapsedRows)}
                                >
                                    <div>
                                        <div
                                            style={{ marginTop: 10 }}
                                            className="my-row"
                                            onKeyDown={(evt) => {
                                                evt.stopPropagation();
                                            }}
                                            onMouseDown={(evt) => {
                                                evt.stopPropagation();
                                            }}
                                        >
                                            <Select
                                                onKeyDown={(evt) => {
                                                    evt.stopPropagation();
                                                }}
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={"Element"}
                                                styles={{
                                                    ...selectStyles,
                                                    container: (base) => ({
                                                        ...base,
                                                        width: "50%",
                                                        height: "35px",
                                                    }),
                                                }}
                                                options={nodeOptions}
                                                onChange={(newValue) => {
                                                    let backendOutput = {
                                                        ...props.value
                                                            .backendOutput,
                                                    };
                                                    backendOutput.variableOptions[
                                                        index
                                                    ].node = newValue as SubmitOption;
                                                    props.onChange({
                                                        backendOutput: backendOutput,
                                                    });
                                                }}
                                                value={value?.node}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                            <Select
                                                onKeyDown={(evt) => {
                                                    evt.stopPropagation();
                                                }}
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={"Variable"}
                                                styles={{
                                                    ...selectStyles,
                                                    container: (base) => ({
                                                        ...base,
                                                        marginLeft: 5,
                                                        width: "50%",
                                                        height: "35px",
                                                    }),
                                                }}
                                                options={Variables(
                                                    props.value.backendOutput!
                                                        .dataScopeOption!.value,
                                                    props.currentModuleId ??
                                                    remoteModuleId
                                                )
                                                    .dataVariables.filter(
                                                        (variable) =>
                                                            variable.derived_from ==
                                                            null &&
                                                            props.value.backendOutput.variableOptions.findIndex(
                                                                (mapping) =>
                                                                    mapping
                                                                        .variable
                                                                        ?.value ===
                                                                    variable.index
                                                            ) === -1
                                                    )
                                                    .map((variable) => ({
                                                        label: variable.name,
                                                        value:
                                                            variable.index ??
                                                            -1,
                                                        type: variable.type,
                                                        panel: variable.panel,
                                                    }))}
                                                onChange={(newValue) => {
                                                    let backendOutput = {
                                                        ...props.value
                                                            .backendOutput,
                                                    };
                                                    backendOutput.variableOptions[
                                                        index
                                                    ].variable = newValue as VariableOption;
                                                    props.onChange({
                                                        backendOutput: backendOutput,
                                                    });
                                                }}
                                                value={
                                                    value.variable != null
                                                        ? Variables(
                                                            props.value
                                                                .backendOutput!
                                                                .dataScopeOption!
                                                                .value,
                                                            props.currentModuleId ??
                                                            remoteModuleId
                                                        ).variableOptions.find(
                                                            (option) =>
                                                                option.value ===
                                                                value.variable
                                                                    ?.value
                                                        )
                                                        : null
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        </div>
                                        <div
                                            className="pretty p-default"
                                            contentEditable={false}
                                            style={{ marginTop: "15px" }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={
                                                    !(value.allowNans ?? true)
                                                }
                                                onChange={() => {
                                                    let backendOutput = {
                                                        ...props.value
                                                            .backendOutput,
                                                    };
                                                    backendOutput.variableOptions[
                                                        index
                                                    ].allowNans = !(
                                                        value.allowNans ?? true
                                                    );
                                                    props.onChange({
                                                        backendOutput: backendOutput,
                                                    });
                                                }}
                                            />
                                            <div
                                                className={cx(
                                                    "state p-primary",
                                                    styles.allowNansLabel
                                                )}
                                            >
                                                <label>Require input</label>
                                            </div>
                                        </div>

                                        <div
                                            className="pretty p-default"
                                            contentEditable={false}
                                            style={{ marginTop: "15px" }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={
                                                    (value.update ?? false)
                                                }
                                                onChange={() => {
                                                    let backendOutput = {
                                                        ...props.value
                                                            .backendOutput,
                                                    };
                                                    backendOutput.variableOptions[
                                                        index
                                                    ].update = !(
                                                        value.update ?? false
                                                    );
                                                    props.onChange({
                                                        backendOutput: backendOutput,
                                                    });
                                                }}
                                            />
                                            <div
                                                className={cx(
                                                    "state p-primary",
                                                    styles.allowNansLabel
                                                )}
                                            >
                                                <label>update</label>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </React.Fragment>
                    )
                )}
        </div>
    );
});

export default SubmitOptionsSelector;
