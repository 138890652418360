import React, { useState, useEffect } from "react";

import {
    ViolinPlotFinding,
    defaultViolinPlotChartColor,
    defaultViolinPlotStrokeColor,
    defaultViolinPlotBins,
} from "common/Finding";
import EditableAxisItem from "../EditableAxisItem";
import * as d3 from "d3";
import { TooltipStyles } from "../../TooltipStyles";
import { mainStyle } from "common/MainStyle";
import {
    getGridColorByTheme,
    getDefaultColorsByTheme,
} from "../../BarChartTheme";
import { formatValue } from "common/utilities/FormatValue";
import D3ChartBase, { AxisType } from "common/graphics/v2/D3ChartBase";
import Portal from "common/Portal";
import AnimationSlider from "common/AnimationSlider";
import Popup from "reactjs-popup";
import { chartColorPickerPopupStyles } from "common/Constants";
import ColorPicker from "../ChartColorPicker";
import { colorList } from "common/graphics/LineColors";
import { getTextSize } from "common/utilities/MeasureText";
import { useUpdateEffect } from "common/CustomHooks";
import { calculateChartColorPickerPosition, getLongestString } from "../utils";

interface Props {
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    content: ViolinPlotFinding["content"];
    config: ViolinPlotFinding["config"];
    onChangeData?: (
        data: ViolinPlotFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: ViolinPlotFinding["config"],
        updateData?: boolean
    ) => void;
    onChangeContent?: (
        content: ViolinPlotFinding["content"],
        updateData?: boolean
    ) => void;
    width: number;
    height: number;
    scale: number;
    selected: boolean;
}

interface Point {
    x: string;
    y: number;
    time?: string | number | null;
}

type Datum = [string, d3.Bin<Point, number>[]];

function countByTime(
    points: Point[],
    time: string | number | null | undefined
): number {
    if (time == null) {
        return points.length;
    }
    let length = 0;
    for (let item of points) {
        if (item.time == null || item.time <= time) {
            length += 1;
        }
    }
    return length;
}

export default function BoxPlot(props: Props) {
    let [showTooltipInfo, setShowTooltipInfo] = useState<{
        x: number;
        y: number;
        lastBin: boolean;
        binX: string;
        bin: d3.Bin<Point, number>;
    } | null>(null);
    let [longestYAxisValue, setLongestYAxisValue] = useState<string>("0");
    let [colorPickerIsEnabled, setColorPickerIsEnabled] = useState<boolean>(
        false
    );
    let [axisItemStyles, setAxisItemStyles] = useState<Record<any, number>>({
        xAxisWidth: 0,
        yAxisHeight: 0,
        xAxisOffset: 0,
        yAxisOffset: 0,
    });
    let [showColorPicker, setShowColorPicker] = useState<{
        x: number;
        y: number;
    } | null>(null);

    let parentRef = React.useRef<HTMLDivElement>(null);
    let parentSvgRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);
    let timeValueRef = React.useRef<string | number | null>();
    let timeAnimationTickRef = React.useRef<() => void>();
    let gridGRef = React.useRef<
        d3.Selection<SVGGElement, unknown, null, undefined>
    >();

    let currentEditVariableIndex: number | undefined = undefined;
    if (props.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = props.content.data.findIndex(
            (item) => item.variableIndex == null
        );
    }

    let onChangeName = (index: number, value: string) => {
        let newData: ViolinPlotFinding["content"]["data"] = [
            {
                ...props.content.data[0],
            },
            {
                ...props.content.data[1],
            },
        ];
        newData[index].name = value;
        props.onChangeData?.(newData);
    };

    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData: ViolinPlotFinding["content"]["data"] = [
            {
                ...props.content.data[0],
            },
            {
                ...props.content.data[1],
            },
        ];
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        props.onChangeData?.(newData, true);
    };

    useUpdateEffect(() => {
        setTimeout(() => {
            setColorPickerIsEnabled(props.selected);
        }, 100);
    }, [props.selected]);

    useEffect(() => {
        let xAxisFontSize: number =
            props.config.ticksAndLabels?.x?.size ??
            props.config.ticksSize ??
            parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

        let yAxisFontSize: number =
            props.config.ticksAndLabels?.y?.size ??
            props.config.ticksSize ??
            parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

        let defaultColors = getDefaultColorsByTheme(props.config.chartTheme);
        // let themeOptions = getOptionsByTheme(props.config.chartTheme);
        let gridFillColor = getGridColorByTheme(
            props.config.chartTheme,
            props.config.baseBackgroundColor
        );

        let [minY, maxY] = d3.extent(props.content.data[1].value);
        minY = minY ?? 0;
        maxY = maxY ?? 10;

        let minYRange = props.config.minYRange ?? minY!;
        let maxYRange = props.config.maxYRange ?? maxY!;

        const yAxisExtraSpace =
            getTextSize(longestYAxisValue, "Roboto", yAxisFontSize, "normal")
                .width + 7;

        const xAxisExtraSpace =
            getTextSize("0", "Roboto", xAxisFontSize, "normal").height / 2 - 22;

        // Padding is necessary to prevent tick text from being cut off
        let fullHeight = parentSvgRef.current?.clientHeight ?? 0;
        let fullWidth = parentSvgRef.current?.clientWidth ?? 0;
        let yAxisWidth = 50;
        let xAxisHeight = 30;
        // set the dimensions and margins of the graph
        let height = fullHeight - xAxisHeight;
        // We subtract yAxisExtraSpace to prevent the chart from shifting to
        // the right and cutting off axis label
        // https://eisengardai.atlassian.net/browse/EIS-259?focusedCommentId=12079
        let width = fullWidth - yAxisWidth - yAxisExtraSpace;

        setAxisItemStyles({
            xAxisWidth: width,
            yAxisHeight: height + 2,
            xAxisOffset: yAxisExtraSpace,
            yAxisOffset: xAxisExtraSpace,
        });

        // append the svg object to the body of the page
        d3.select(svgRef.current!).selectAll("*").remove();
        let svg = d3
            .select(svgRef.current!)
            .attr("width", fullWidth)
            .attr("height", fullHeight)
            .append("g")
            .style("cursor", "crosshair")
            .attr(
                "transform",
                `translate(${yAxisExtraSpace}, ${-xAxisExtraSpace})`
            );

        let base = new D3ChartBase(svg, width, height);

        base.drawBackground(gridFillColor ?? "transparent");

        // Calculate ticks
        let {
            ticks: yTicks,
            decimals: yDecimals,
        } = D3ChartBase.calculateLinearTicks(
            minYRange,
            maxYRange,
            props.config?.ticksAndLabels?.y?.interval
        );

        let yTickFormat = (tick: number) => tick.toFixed(yDecimals);

        // X Axis
        let xValues = props.content.data[0].value.map(String);
        let { axis: xAxis } = base.drawBandAxis(
            AxisType.XAxis,
            {
                color:
                    props.config.axesLinesColor ?? defaultColors.axesLinesColor,
            },
            {
                color:
                    props.config.ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: xAxisFontSize,
                fontFamily: "Open Sans",
                tickSize: 0,
            },
            xValues
        );
        let xScale = xAxis.scale<d3.ScaleBand<string>>();
        // Y Axis
        let { axis: yAxis } = base.drawLinearAxis(
            AxisType.YAxis,
            {
                color:
                    props.config.axesLinesColor ?? defaultColors.axesLinesColor,
            },
            {
                color:
                    props.config.ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: yAxisFontSize,
                fontFamily: "Open Sans",
                tickSize: 0,
                tickValues: yTicks,
                tickFormat: yTickFormat,
            },
            [minYRange, maxYRange],
            undefined,
            {
                end: 5,
            }
        );
        let yScale = yAxis.scale<d3.ScaleLinear<number, number>>();

        const longestYValue = getLongestString(yTicks.map(yTickFormat));

        if (longestYAxisValue !== longestYValue) {
            setLongestYAxisValue(longestYValue);
        }
        let chartG = svg.append("g");

        // Plot data
        let plotData: Point[] = props.content.data[0].value.map((x, index) => ({
            x: String(x),
            y: props.content.data[1].value[index],
            time: props.content.time?.value[index],
        }));

        let bins: number = props.config.bins ?? defaultViolinPlotBins;

        let delta = (maxY! - minY!) / bins;
        // Range can generate an array longer than necessary due to floating
        // point error
        let thresholds = d3
            .range(minY!, maxY! + delta, delta)
            .slice(0, bins + 1);

        let histogram = d3
            .bin<Point, number>()
            .domain([minY!, maxY!])
            .thresholds(thresholds)
            .value((d) => d.y);

        let rollup = d3.rollup(plotData, histogram, (d) => d.x);

        let maxWidth = xScale.bandwidth() * 0.75;

        let maxLength =
            d3.max(rollup, (bins) => d3.max(bins[1], (bin) => bin.length)) ?? 1;

        let binScale = d3
            .scaleLinear()
            .range([-maxWidth / 2, maxWidth / 2])
            .domain([-maxLength, maxLength]);

        chartG.selectAll("*").remove();

        let violinG = chartG
            .selectAll<SVGGElement, Datum>("g")
            .data(rollup)
            .join("g");

        // Violin
        let pathG = violinG
            .append("path")
            .style(
                "stroke",
                props.config.strokeColor ?? defaultViolinPlotStrokeColor
            )
            .style(
                "fill",
                props.config.chartColor ?? defaultViolinPlotChartColor
            );

        // Invisible rectangles for the tooltip
        violinG
            .selectAll<SVGRectElement, Datum[1]>("rect")
            .data((d) =>
                d[1].map((bin, index) => ({ x: d[0], bin: bin, index: index }))
            )
            .join("rect")
            .attr(
                "x",
                (d) => xScale(d.x)! + xScale.bandwidth() / 2 - maxWidth / 2
            )
            .attr("y", (d) => yScale(d.bin.x0!))
            .attr("height", (d) => yScale(d.bin.x0!) - yScale(d.bin.x1!))
            .attr("width", maxWidth)
            .attr("stroke", null)
            .style("fill", "transparent")
            .on(
                "mouseenter",
                (
                    event: MouseEvent,
                    d: { x: string; bin: d3.Bin<Point, number>; index: number }
                ) => {
                    setShowTooltipInfo({
                        x: event.x,
                        y: event.y,
                        lastBin: d.index === bins - 1,
                        binX: d.x,
                        bin: d.bin,
                    });
                }
            )
            .on(
                "mousemove",
                (
                    event: MouseEvent,
                    d: { x: string; bin: d3.Bin<Point, number>; index: number }
                ) => {
                    setShowTooltipInfo((showTooltipInfo) => {
                        if (showTooltipInfo == null) return null;
                        return {
                            x: event.x,
                            y: event.y,
                            lastBin: d.index === bins - 1,
                            binX: d.x,
                            bin: d.bin,
                        };
                    });
                }
            )
            .on("mouseleave", (_event: MouseEvent) => {
                setShowTooltipInfo(null);
            })
            .on("click", (evt) => {
                const { y } = calculateChartColorPickerPosition(evt);
                if (colorPickerIsEnabled) {
                    setShowColorPicker({
                        x: evt.clientX + 5,
                        y: y + 5,
                    });
                }
            });

        // Grid
        if (props.config.showGrid) {
            if (gridGRef.current != null) {
                gridGRef.current.remove();
            }
            gridGRef.current = base.drawGrid(
                [],
                yTicks,
                props.config.axesLinesColor ?? defaultColors.gridColor,
                () => 0,
                yScale
            );
        }

        timeValueRef.current = props.content.time?.uniqueValues[0];

        timeAnimationTickRef.current = () => {
            pathG.attr("d", (d) => {
                let offset = xScale(d[0])! + xScale.bandwidth() / 2;
                let area = d3
                    .area<{ countByTime: number; bin: d3.Bin<Point, number> }>()
                    .x0((d) => offset + binScale(-d.countByTime))
                    .x1((d) => offset + binScale(d.countByTime))
                    .y((d) => yScale(d.bin.x0 ?? 0))
                    .curve(d3.curveCatmullRom);
                return area(
                    d[1].map((bin) => ({
                        countByTime: countByTime(bin, timeValueRef.current),
                        bin: bin,
                    }))
                );
            });
        };

        timeAnimationTickRef.current();
    }, [
        props.content.data,
        props.content.time,
        props.content.time?.value,
        props.content.time?.uniqueValues,
        props.config.ticksAndLabels,
        props.config.chartTheme,
        props.config.baseBackgroundColor,
        props.config.linesCount,
        props.config.showXAxisName,
        props.config.showYAxisName,
        props.config.minYRange,
        props.config.maxYRange,
        props.config.count,
        props.config.trendline,
        props.config.random,
        props.config.trendlineColor,
        props.config.axesLinesColor,
        props.config.chartColor,
        props.config.strokeColor,
        props.config.showGrid,
        props.config.ticksColor,
        props.config.ticksSize,
        props.config.bins,
        props.preview,
        props.width,
        props.height,
        props.scale,
        longestYAxisValue,
        colorPickerIsEnabled,
    ]);

    let tooltipStyle = {
        ...TooltipStyles(
            props.config.tooltipColor,
            props.config.tooltipFontSize
        ),
    };
    return (
        <div style={{ height: "100%" }}>
            <div
                ref={parentRef}
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {props.content.time?.variableIndex != null &&
                    props.content.time?.uniqueValues.length !== 0 && (
                        <AnimationSlider
                            sliderStyle={{
                                cursor: "default",
                                width: 165,
                                pointerEvents: "auto",
                            }}
                            values={props.content.time.uniqueValues}
                            onChange={(index) => {
                                timeValueRef.current = props.content.time!.uniqueValues[
                                    index
                                ];
                                timeAnimationTickRef.current!();
                            }}
                        />
                    )}
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "space-between",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <div
                            className="flex-simple-column"
                            style={{ width: "48px", height: "100%" }}
                        >
                            <div
                                style={{
                                    height: axisItemStyles.yAxisHeight,
                                    position: "relative",
                                    top: -axisItemStyles.yAxisOffset - 1,
                                }}
                            >
                                {props.config.showYAxisName && (
                                    <EditableAxisItem
                                        onChange={(value) => {
                                            onChangeName(1, value);
                                        }}
                                        color={props.config.axesNamesColor}
                                        index={1}
                                        currentEditVariableIndex={
                                            currentEditVariableIndex
                                        }
                                        vertical
                                        name={props.content.data[1].name}
                                        onDrop={linkVariable}
                                        editable={props.editable}
                                    />
                                )}
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            <div
                                style={{
                                    width: 48,
                                    minHeight: 48,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {props.editable && (
                                    <div
                                        style={{
                                            width: 32,
                                            height: 32,
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // Resizing does not work properly
                            // without overflow: "hidden"
                            overflow: "hidden",
                        }}
                    >
                        <div
                            ref={parentSvgRef}
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                position: "relative",
                                // Resizing does not work properly
                                // without overflow: "hidden"
                                overflow: "hidden",
                            }}
                        >
                            {showTooltipInfo && (
                                <Portal rootNode={document.body}>
                                    <div
                                        style={{
                                            zIndex: 100000,
                                            position: "absolute",
                                            top: showTooltipInfo.y,
                                            left: showTooltipInfo.x,
                                            display: "flex",
                                            pointerEvents: "none",
                                            transform: "translateY(-50%)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: 10,
                                                    height: 10,
                                                    transform:
                                                        "translateX(50%) rotate(45deg)",
                                                    ...tooltipStyle.contentStyle,
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px",
                                                ...tooltipStyle.contentStyle,
                                                display: "flex",
                                                flexDirection: "column",
                                                zIndex: 100001,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    ...tooltipStyle.itemStyle,
                                                    color: "rgb(85, 105, 125)",
                                                }}
                                                className="unselectable"
                                            >{`${
                                                props.content.data[0].name
                                            }: ${formatValue(
                                                showTooltipInfo.binX
                                            ).join("")}`}</span>
                                            <span
                                                style={{
                                                    ...tooltipStyle.itemStyle,
                                                    color: "rgb(85, 105, 125)",
                                                }}
                                                className="unselectable"
                                            >{`${
                                                props.content.data[1].name
                                            }: [${formatValue(
                                                showTooltipInfo.bin.x0!
                                            ).join("")}, ${formatValue(
                                                showTooltipInfo.bin.x1!
                                            ).join("")}${
                                                showTooltipInfo.lastBin
                                                    ? "]"
                                                    : ")"
                                            }`}</span>
                                            <span
                                                style={{
                                                    ...tooltipStyle.itemStyle,
                                                    color: "rgb(85, 105, 125)",
                                                }}
                                                className="unselectable"
                                            >{`count: ${countByTime(
                                                showTooltipInfo.bin,
                                                timeValueRef.current
                                            )}`}</span>
                                        </div>
                                    </div>
                                </Portal>
                            )}
                            <svg ref={svgRef} />
                        </div>
                        {props.config.showXAxisName && (
                            <div
                                className="my-row"
                                style={{
                                    // height: "48px" would not be enough since
                                    // its size would change when x axis name is
                                    // hidden. We have to set min and max height.
                                    minHeight: "48px",
                                    maxHeight: "48px",
                                    position: "relative",
                                    width: axisItemStyles.xAxisWidth,
                                    left: axisItemStyles.xAxisOffset,
                                }}
                            >
                                <EditableAxisItem
                                    onChange={(value) => {
                                        onChangeName(0, value);
                                    }}
                                    color={props.config.axesNamesColor}
                                    currentEditVariableIndex={
                                        currentEditVariableIndex
                                    }
                                    editable={props.editable}
                                    index={0}
                                    vertical={false}
                                    name={props.content.data[0].name}
                                    onDrop={linkVariable}
                                    inputStyle={{
                                        width: "100%",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showColorPicker && (
                <Popup
                    arrow={true}
                    contentStyle={{
                        ...chartColorPickerPopupStyles,
                        left: showColorPicker.x,
                        top: showColorPicker.y,
                    }}
                    open={true}
                    onClose={() => {
                        setShowColorPicker(null);
                    }}
                    nested={true}
                    closeOnDocumentClick
                >
                    <ColorPicker
                        enableAlpha={true}
                        width={"220px"}
                        color={props.config.chartColor ?? colorList[1]}
                        onChange={(color) => {
                            let newConfig = {
                                ...props.config,
                                chartColor: color,
                            };
                            props.onChangeConfig?.(newConfig);
                        }}
                    />
                </Popup>
            )}
        </div>
    );
}
