import { observable, computed, makeObservable, action } from "mobx";

import DataScopes, { DataScope, DataScopeOption } from "./DataScopes";
import {
    getModuleUserDataSetsApi,
    getModuleUserSharedLinkDataSetsApi,
} from "./ModulesApi";
import { Permission } from "./Permissions";

class DataScopesForModuleInner {
    @observable public moduleUserDataScopes: DataScope[] = [];

    private moduleId: number | string | undefined;

    constructor(moduleId: number | string | undefined) {
        makeObservable(this);
        this.moduleId = moduleId;
        this.update();
    }

    @computed public get dataScopesOptions(): DataScopeOption[] {
        let options = Array.from(DataScopes.dataScopesOptions);
        let ids = new Set<number | string>(
            options.map((option) => option.value)
        );
        for (const dataScope of this.moduleUserDataScopes) {
            if (!ids.has(dataScope.id)) {
                options.push({
                    label: dataScope.name,
                    value: dataScope.id,
                    permissionType: dataScope.permissionType,
                });
            }
        }
        return options;
    }

    @computed public get dataScopesOptionsModuleOnly(): DataScopeOption[] {
        return this.moduleUserDataScopes.map((dataScope) => ({
            label: dataScope.name,
            value: dataScope.id,
            permissionType: dataScope.permissionType,
        }));
    }

    @action.bound
    private assignModuleUserDataScopes(
        moduleUserDataScopes: DataScope[]
    ): void {
        this.moduleUserDataScopes = moduleUserDataScopes;
        DataScopes.joinChangesRoom(
            this.moduleUserDataScopes.map((dataScope) => dataScope.id),
            this.moduleId
        );
    }

    public async update(): Promise<void> {
        if (this.moduleId != null) {
            if (typeof this.moduleId === "string") {
                getModuleUserSharedLinkDataSetsApi(this.moduleId, false, true)
                    .then(({ dataSets }) => {
                        let dataScopes: DataScope[] = [];
                        for (let dataSet of dataSets) {
                            dataScopes.push({
                                id: dataSet.data_table_idx,
                                name: dataSet.data_table_name,
                                permissionType: Permission.ReadWrite,
                            });
                        }
                        this.assignModuleUserDataScopes(dataScopes);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                getModuleUserDataSetsApi(this.moduleId, false, true)
                    .then((dataSets) => {
                        let dataScopes: DataScope[] = [];
                        for (let dataSet of dataSets) {
                            dataScopes.push({
                                id: dataSet.data_table_idx,
                                name: dataSet.data_table_name,
                                permissionType: Permission.ReadWrite,
                            });
                        }
                        this.assignModuleUserDataScopes(dataScopes);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } else return Promise.resolve();
    }
}

let dataScopesMap: {
    [key: number]: DataScopesForModuleInner;
} = {};

export default function DataScopesForModule(
    moduleId: number
): DataScopesForModuleInner {
    if (!(moduleId in dataScopesMap)) {
        dataScopesMap[moduleId] = new DataScopesForModuleInner(moduleId);
    }
    return dataScopesMap[moduleId];
}
