import React from "react";
import LeversBenchmarkingChart from "common/graphics/LeversBenchmarkingChart";

function MainComponent(props) {
	let finding = props.finding;
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
			}}
		>
			<LeversBenchmarkingChart
				config={finding.config}
				groupValue={finding.content.groupValue}
				compareValue={finding.content.compareValue}
				aggregateVariable={finding.content.aggregateVariable}
				dataComparison={finding.content.dataComparison}
				groupOperation={finding.content.groupOperation}
				compareOperation={finding.content.compareOperation}
			/>
		</div>
	);
}

export { MainComponent };