import React from "react";
import { Button } from "react-bootstrap";
import { Element } from "react-scroll";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import StringUtils from "common/utilities/StringUtils";
import customSelectStyles from "common/SelectStyles";
import axios from "common/ServerConnection";
import tables from "common/Tables";
import variables from "common/Variables";
import models from "common/Models";
import dataScopes from "common/DataScopes";
import processTab from "common/utilities/processTab";
import { Permission } from "common/Permissions";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                dataScope: undefined,
                expanded: false,
                optimizationTable: undefined,
                optimizationModel: null,
                optimizationFunctions: "",
                optimizationAdvancedFunctions: "",
                optimizationAdvancedFunctionsExpanded: false,
                optimalData: null,
                optimalDataTable: null,
                killButtonDisabled: true,
            };
            this.dataScopeValue = this.dataScopeValue.bind(this);
        }
        dataScopeValue() {
            if (this.state.dataScope) return this.state.dataScope.value;
            return undefined;
        }
        componentDidMount() {
            if (this.state.dataScope) this.getLastOptimizationFunctions();
            if (this.state.dataScope) this.optimizationStatus();
            this.interval = setInterval(() => {
                if (this.state.stepState === StepState.running)
                    if (this.state.dataScope) this.optimizationStatus();
            }, 10000);
        }

        componentWillUnmount() {
            clearInterval(this.interval);
        }

        getLastOptimizationFunctions() {
            axios
                .post(
                    "/api/get_last_optimization_functions",
                    {
                        data_table_idx: this.dataScopeValue(),
                    },
                    null
                )
                .then((response) => {
                    if (response.data.success === false) {
                        console.log(response.data.error_msg);
                    } else {
                        this.setState((state) => ({
                            optimizationFunctions: response.data.code,
                            optimizationAdvancedFunctions:
                                response.data.advanced_code,
                            optimizationTable: tables(
                                this.dataScopeValue()
                            ).tableToOption(response.data.table),
                        }));
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 401 && error.response.status !== 403)
                        console.log(error);
                });
        }

        onOptimizationFunctionsChange(e) {
            let value = e.target.value;
            this.setState({ optimizationFunctions: value });
        }

        onOptimizationAdvancedFunctionsChange(e) {
            let value = e.target.value;
            this.setState({ optimizationAdvancedFunctions: value });
        }

        optimizationStart(code, advancedCode) {
            let json = {
                table: this.state.optimizationTable.value,
                model_id: this.state.optimizationModel
                    ? this.state.optimizationModel.value
                    : undefined,
                data_table_idx: this.state.optimizationTable.data_table_idx,
                condition_id: this.state.optimizationTable.condition_id,
                condition: this.state.optimizationTable.condition,
                code: code,
                advanced_code: advancedCode,
            };

            this.setStepState(StepState.running, "", {
                killButtonDisabled: true,
            });
            axios
                .post("/api/optimization_start", json, null)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        this.setState({ killButtonDisabled: false });
                    }
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        optimizationKill() {
            axios
                .post("/api/optimization_kill", null, null)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        this.setStepState(StepState.not_running);
                    }
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        optimizationStatus() {
            if (!this.state.optimizationTable) return;
            let json = {
                table: this.state.optimizationTable.value,
                data_table_idx: this.state.optimizationTable.data_table_idx,
            };
            axios
                .post("/api/optimization_status", json, null)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        let text = null;
                        if (response.data.status === StepState.error) {
                            text = response.data.result;
                        }
                        let tablePreview = response.data.table_preview || null;
                        let tablePreviewTable = response.data.table || null;
                        this.setStepState(response.data.status, text, {
                            killButtonDisabled:
                                response.data.status !== StepState.running,
                            optimalData: tablePreview,
                            optimalDataTable: tablePreviewTable,
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 401 && error.response.status !== 403) {
                        console.log(error);
                        this.setStepState(
                            StepState.error,
                            error.response.status.toString()
                        );
                    }
                });
        }

        renderOptimization(optimalDataTable) {
            if (!this.state.expanded) return null;
            else {
                return (
                    <>
                        <div />
                        <div
                            style={{
                                gridColumnStart: 2,
                                gridColumnEnd: 4,
                                minWidth: 0,
                                background: "#3f4b62",
                                padding: "10px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            >
                                <span
                                    className="content-regular-text"
                                    style={{
                                        width: "10em",
                                        display: "flex",
                                        alignItems: "center",
                                        marginRight: "10px",
                                    }}
                                >
                                    Select data
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "25em",
                                            height: "38px",
                                            marginRight: "5px",
                                        }),
                                    }}
                                    options={dataScopes.dataScopesOptions.filter(
                                        (option) =>
                                            option.permissionType ===
                                            Permission.ReadWrite
                                    )}
                                    onChange={(newValue) =>
                                        this.setState(
                                            {
                                                dataScope: newValue,
                                            },
                                            () => {
                                                this.getLastOptimizationFunctions();
                                                this.optimizationStatus();
                                            }
                                        )
                                    }
                                    value={this.state.dataScope}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                }}
                            >
                                <span
                                    className="content-regular-text"
                                    style={{
                                        marginRight: "5px",
                                    }}
                                >
                                    Current table
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "25em",
                                            height: "38px",
                                            marginRight: "10px",
                                        }),
                                    }}
                                    options={
                                        tables(this.dataScopeValue())
                                            .rawAndAggregateTableOptions
                                    }
                                    onChange={(newValue) =>
                                        this.setState(
                                            { optimizationTable: newValue },
                                            this.optimizationStatus
                                        )
                                    }
                                    value={this.state.optimizationTable}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                <span
                                    className="content-regular-text"
                                    style={{
                                        marginRight: "5px",
                                    }}
                                >
                                    Model
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "8em",
                                            height: "38px",
                                        }),
                                    }}
                                    options={models(this.dataScopeValue())
                                        .models.filter(
                                            (item) => item.type === "smf"
                                        )
                                        .map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                            info: item,
                                        }))}
                                    onChange={(newValue) =>
                                        this.setState({
                                            optimizationModel: newValue,
                                        })
                                    }
                                    value={this.state.optimizationModel}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                {this.state.dataScope?.value != null &&
                                    this.state.optimizationModel != null && (
                                        <span
                                            className="content-regular-text"
                                            style={{
                                                marginLeft: "10px",
                                                marginRight: "5px",
                                            }}
                                        >
                                            (outcome:{" "}
                                            {
                                                variables(
                                                    this.state.dataScope.value
                                                ).variableNames[
                                                    this.state.optimizationModel
                                                        .info.outcome_index
                                                ]
                                            }
                                            {this.state.optimizationModel.info
                                                .panel_index != null &&
                                                `, panel: ${
                                                    variables(
                                                        this.state.dataScope
                                                            .value
                                                    ).variableNames[
                                                        this.state
                                                            .optimizationModel
                                                            .info.panel_index
                                                    ]
                                                }`}
                                            )
                                        </span>
                                    )}
                            </div>
                            <div
                                className="content-regular-text"
                                style={{
                                    marginBottom: "10px",
                                }}
                            >
                                Note: you have to define all 3 functions:
                                objective_function, initial_guess, constraints
                            </div>
                            <textarea
                                className="like-select element"
                                style={{
                                    minHeight: "460px",
                                    width: "100%",
                                    fontFamily: "monospace",
                                    resize: "vertical",
                                    whiteSpace: "pre",
                                    marginBottom: "10px",
                                }}
                                spellCheck={false}
                                placeholder=""
                                onChange={(e) =>
                                    this.onOptimizationFunctionsChange(e)
                                }
                                value={this.state.optimizationFunctions}
                                ref="optimizationFunctionsTextArea"
                                onKeyDown={(e) =>
                                    processTab(
                                        e,
                                        (
                                            newSelectionStart,
                                            newSelectionEnd,
                                            newValue
                                        ) => {
                                            this.setState(
                                                {
                                                    optimizationFunctions: newValue,
                                                },
                                                () => {
                                                    this.refs.optimizationFunctionsTextArea.selectionStart = newSelectionStart;
                                                    this.refs.optimizationFunctionsTextArea.selectionEnd = newSelectionEnd;
                                                }
                                            );
                                        }
                                    )
                                }
                            />
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "10px",
                                }}
                            >
                                <Button
                                    className="btn-processing-tab-blue"
                                    style={{
                                        height: "25px",
                                        flex: 1,
                                    }}
                                    onClick={() => {
                                        this.setState((state) => ({
                                            optimizationAdvancedFunctionsExpanded: !state.optimizationAdvancedFunctionsExpanded,
                                        }));
                                    }}
                                >
                                    <span className="content-regular-text">
                                        Edit optimization code
                                    </span>
                                    <span className="content-regular-text">
                                        {this.state
                                            .optimizationAdvancedFunctionsExpanded
                                            ? "\uFF0D"
                                            : "\uFF0B"}
                                    </span>
                                </Button>
                            </div>
                            {this.state
                                .optimizationAdvancedFunctionsExpanded && (
                                <textarea
                                    className="like-select element"
                                    style={{
                                        minHeight: "780px",
                                        width: "100%",
                                        fontFamily: "monospace",
                                        resize: "vertical",
                                        whiteSpace: "pre",
                                        marginBottom: "10px",
                                    }}
                                    placeholder=""
                                    spellCheck={false}
                                    onChange={(e) =>
                                        this.onOptimizationAdvancedFunctionsChange(
                                            e
                                        )
                                    }
                                    value={
                                        this.state.optimizationAdvancedFunctions
                                    }
                                    ref="optimizationAdvancedFunctionsTextArea"
                                    onKeyDown={(e) =>
                                        processTab(
                                            this,
                                            e,
                                            "optimizationAdvancedFunctionsTextArea",
                                            "optimizationAdvancedFunctions"
                                        )
                                    }
                                />
                            )}
                            {optimalDataTable !== null && [
                                <div
                                    className="content-regular-text"
                                    style={{
                                        marginBottom: "5px",
                                    }}
                                >
                                    {`Optimized table preview for ${StringUtils.decapitalize(
                                        tables(
                                            this.dataScopeValue()
                                        ).tableToOptionOptimized(
                                            this.state.optimalDataTable
                                        ).label
                                    )}`}
                                </div>,
                                optimalDataTable,
                            ]}
                        </div>
                        <div />
                    </>
                );
            }
        }

        render() {
            let optimalDataTable;
            if (
                this.state.expanded &&
                this.state.optimalData !== null &&
                this.state.dataVariables !== null
            ) {
                let tableHeader = variables(
                    this.dataScopeValue()
                ).dataVariables.map((value, index, array) => value.name);
                let tableContent = [];
                let rowCount = Object.values(this.state.optimalData)[0].length;
                for (let i = 0; i < rowCount; ++i) {
                    let row = [];
                    for (let varName of tableHeader) {
                        let column = this.state.optimalData[varName];
                        if (typeof column === "undefined") {
                            row.push("-");
                        } else {
                            row.push(
                                column[i] ||
                                    (typeof column[i] === "number"
                                        ? column[i]
                                        : "-")
                            );
                        }
                    }
                    tableContent.push(row);
                }
                optimalDataTable = (
                    <Element
                        name="scrollable"
                        className="element"
                        style={{
                            overflowX: "scroll",
                            backgroundColor: "transparent",
                            position: "relative",
                            overflowY: "scroll",
                        }}
                    >
                        <AdminTableWithFullFeatures
                            className="processing-page-table"
                            paging={false}
                            tableName="Data"
                            tableHeader={tableHeader}
                            tableContent={tableContent}
                        />
                    </Element>
                );
            } else {
                optimalDataTable = null;
            }
            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        disabled={this.props.disabled}
                        className="btn-processing-tab"
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState((state) => ({
                                expanded: !state.expanded,
                            }));
                        }}
                    >
                        <span className="content-regular-text">
                            Perform constrained optimization
                        </span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    {this.state.expanded ? (
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            onClick={() => {
                                if (
                                    this.state.stepState === StepState.running
                                ) {
                                    this.optimizationKill();
                                } else {
                                    this.optimizationStart(
                                        this.state.optimizationFunctions,
                                        this.state.optimizationAdvancedFunctions
                                    );
                                }
                            }}
                            style={{
                                fontFamily: "Roboto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: "25px",
                                width: "100%",
                                minWidth: "110px",
                            }}
                            disabled={
                                this.state.stepState === StepState.running
                                    ? this.state.killButtonDisabled
                                    : false
                            }
                        >
                            {this.state.stepState === StepState.running
                                ? "KILL"
                                : "RUN"}
                        </Button>
                    ) : (
                        <div style={{ minWidth: "110px" }} />
                    )}
                    <div>{this.state.expanded && this.renderStepState()}</div>
                    {this.renderOptimization(optimalDataTable)}
                </>
            );
        }
    }
);

export { MainComponent };
export let requirePermission = "Optimization";
