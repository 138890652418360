import React from "react";
import { observer } from "mobx-react";
import { LeadersLaggersFinding } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import styles from "./LeadersLaggersSection.module.css";
import barChartStyles from "../BarChartSection/BarChartSection.module.css";
import Select, { createFilter } from "react-select";
import { getCustomSelectStyleLight } from "common/SelectStyles";
import ViewOption from "common/graphics/ViewOption";
import Variables from "common/Variables";
import MagicWand from "../MagicWand";
import EditInput from "../EditInput";
import { Button } from "react-bootstrap";
import { useDrop } from "react-dnd";
import { DynamicOptionType } from "common/DynamicOptions";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import cx from "classnames";
import remoteModuleId from "common/remoteModuleId";

interface Props {
    finding: LeadersLaggersFinding;
    dashboardId: string;
    canvasTreeStore: CanvasTreeStore;
    columnDragActive: boolean;
    onChange: (finding: LeadersLaggersFinding, updateData?: boolean) => void;
    onClearEditing: () => void;
    currentModuleId?: number;
}

interface ViewOptionType {
    label: string;
    value: ViewOption;
}

const viewOptions: ReadonlyArray<ViewOptionType> = [
    {
        label: "Rank chart",
        value: ViewOption.Both,
    },
    {
        label: "Only Top",
        value: ViewOption.Leaders,
    },
    {
        label: "Only Bottom",
        value: ViewOption.Laggards,
    },
];

const viewOptionsMap: Readonly<{ [key in ViewOption]: ViewOptionType }> = {
    [ViewOption.Both]: viewOptions[0],
    [ViewOption.Leaders]: viewOptions[1],
    [ViewOption.Laggards]: viewOptions[2],
};

interface DropProps {
    index: number;
    onDrop: (
        index: number,
        variableName: string,
        variableIndex: number
    ) => void;
    optionalClassName?: string;
}

const DropArea: React.FC<DropProps> = (props) => {
    const [collected, drop] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            props.onDrop(
                props.index,
                otherItem.content.variableName,
                otherItem.content.variableIndex
            );
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    return (
        <div
            className={props.optionalClassName}
            style={{
                backgroundColor: collected.hover ? "#F4FBF5" : undefined,
            }}
            ref={drop}
        >
            {props.children}
        </div>
    );
};

function Input(props: {
    value: string | number;
    containerStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    onChange: (value: string | number) => void;
}) {
    let [value, setValue] = React.useState(props.value);

    return (
        <div className="my-row" style={props.containerStyle}>
            <input
                style={props.style}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                    if (evt.key === "Enter") {
                        props.onChange(value);
                    }
                }}
                className={styles.input}
                value={value}
                onChange={(evt) => {
                    let newValue = evt.target.value;
                    setValue(newValue);
                }}
                onBlur={() => {
                    props.onChange(value);
                }}
            />
        </div>
    );
}

function LeadersLaggersSection(props: Props) {
    let selectStyles = getCustomSelectStyleLight(14, false);

    let geographyVariables = Variables(
        props.finding.config.dataScope?.value,
        props.currentModuleId ?? remoteModuleId
    ).geographyVariables.map((variable) => ({
        label: variable.name,
        value: variable.index,
    }));

    let dataVariables = Variables(
        props.finding.config.dataScope?.value,
        props.currentModuleId ?? remoteModuleId
    ).dataVariables;

    const variableAliases = props.finding.config.variableAliases ?? {};

    let currentGeographyVariable = Variables(
        props.finding.config.dataScope?.value,
        props.currentModuleId ?? remoteModuleId
    ).getVariableByIndex(props.finding.config.aggregateVariableIndex);

    let linkVariable = (
        variableName: string,
        variableIndex: number,
        key: number
    ) => {
        let variable = {
            label: variableName,
            value: variableIndex,
        } as {
            label: string;
            value: number;
        };

        let shownVariableIndices = Array.from(
            props.finding.config.shownVariableIndices
        );

        if (shownVariableIndices.includes(variable.value)) return;

        shownVariableIndices[key] = variable.value;

        props.onChange(
            {
                ...props.finding,
                config: {
                    ...props.finding.config,
                    shownVariableIndices: shownVariableIndices,
                },
            },
            true
        );
    };

    let linkTargetVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        props.onChange(
            {
                ...props.finding,
                config: {
                    ...props.finding.config,
                    targetVariableIndex: variableIndex,
                },
            },
            true
        );
    };

    const { leaders } = props.finding.content.data;
    const { targetVariableIndex } = props.finding.config;
    const targetVariableName =
        targetVariableIndex != null
            ? variableAliases[targetVariableIndex] ??
              dataVariables[targetVariableIndex].name
            : "variable";
    return (
        <div>
            <div className={styles.dataContainer}>
                {props.finding.config.dataScope?.value != null && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="my-row">
                            <MagicWand
                                onClearEditing={props.onClearEditing}
                                dashboardId={props.dashboardId}
                                allowMultiple={true}
                                title={"Variables"}
                                optionName="data"
                                canvasTreeStore={props.canvasTreeStore}
                                type={DynamicOptionType.DataVariable}
                            />
                            <div className={barChartStyles.dataContainer}>
                                <div className={commonStyles.header}>
                                    Variables
                                </div>
                                <div className={commonStyles.header}>
                                    Values
                                </div>
                                <React.Fragment>
                                    <DropArea
                                        optionalClassName={
                                            props.columnDragActive
                                                ? commonStyles.highlightVariable
                                                : undefined
                                        }
                                        onDrop={linkTargetVariable}
                                        index={0}
                                    >
                                        <EditInput
                                            disabled={
                                                targetVariableIndex == null
                                            }
                                            showDeleteButton={false}
                                            value={targetVariableName}
                                            onChange={(newValue) => {
                                                let newVariableAliases = {
                                                    ...variableAliases,
                                                };
                                                if (
                                                    newValue === "" ||
                                                    newValue ===
                                                        dataVariables[
                                                            targetVariableIndex ??
                                                                0
                                                        ]?.name
                                                ) {
                                                    newVariableAliases[
                                                        targetVariableIndex ??
                                                            "preview"
                                                    ] = null;
                                                } else {
                                                    newVariableAliases[
                                                        targetVariableIndex ??
                                                            "preview"
                                                    ] = newValue;
                                                }
                                                props.onChange(
                                                    {
                                                        ...props.finding,
                                                        config: {
                                                            ...props.finding
                                                                .config,
                                                            variableAliases: newVariableAliases,
                                                        },
                                                    },
                                                    true
                                                );
                                            }}
                                        ></EditInput>
                                    </DropArea>
                                    <DropArea
                                        onDrop={linkTargetVariable}
                                        index={1}
                                        optionalClassName={
                                            props.columnDragActive
                                                ? commonStyles.highlightValue
                                                : undefined
                                        }
                                    >
                                        <EditInput
                                            showDeleteButton={false}
                                            disabled={true}
                                            value={`${
                                                leaders?.[0]?.[
                                                    props.finding.config
                                                        .targetVariableIndex ??
                                                        0
                                                ] ?? ""
                                            }...`}
                                        ></EditInput>
                                    </DropArea>
                                </React.Fragment>
                                {props.finding.config.shownVariableIndices.map(
                                    (index: number | null, key: number) => {
                                        const variableName =
                                            index != null
                                                ? variableAliases[index] ??
                                                  dataVariables[index].name
                                                : `variable ${key + 1}`;
                                        return (
                                            <React.Fragment key={key}>
                                                <DropArea
                                                    optionalClassName={
                                                        props.columnDragActive
                                                            ? commonStyles.highlightVariable
                                                            : undefined
                                                    }
                                                    onDrop={(
                                                        index,
                                                        variableName,
                                                        variableIndex
                                                    ) =>
                                                        linkVariable(
                                                            variableName,
                                                            variableIndex,
                                                            key
                                                        )
                                                    }
                                                    index={key}
                                                >
                                                    <EditInput
                                                        showDeleteButton={true}
                                                        disabled={index == null}
                                                        value={variableName}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            let newVariableAliases = {
                                                                ...variableAliases,
                                                            };
                                                            if (
                                                                newValue ===
                                                                    "" ||
                                                                newValue ===
                                                                    dataVariables[
                                                                        index ??
                                                                            key
                                                                    ]?.name
                                                            ) {
                                                                newVariableAliases[
                                                                    index ?? key
                                                                ] = null;
                                                            } else {
                                                                newVariableAliases[
                                                                    index ?? key
                                                                ] = newValue;
                                                            }
                                                            props.onChange(
                                                                {
                                                                    ...props.finding,
                                                                    config: {
                                                                        ...props
                                                                            .finding
                                                                            .config,
                                                                        variableAliases: newVariableAliases,
                                                                    },
                                                                },
                                                                true
                                                            );
                                                        }}
                                                        onDelete={() => {
                                                            let newVariableAliases = {
                                                                ...variableAliases,
                                                            };
                                                            newVariableAliases[
                                                                index ?? key
                                                            ] = null;
                                                            let shownVariableIndices = Array.from(
                                                                props.finding
                                                                    .config
                                                                    .shownVariableIndices
                                                            );
                                                            shownVariableIndices.splice(
                                                                key,
                                                                1
                                                            );
                                                            props.onChange(
                                                                {
                                                                    ...props.finding,
                                                                    config: {
                                                                        ...props
                                                                            .finding
                                                                            .config,
                                                                        variableAliases: newVariableAliases,
                                                                        shownVariableIndices: shownVariableIndices,
                                                                    },
                                                                },
                                                                true
                                                            );
                                                        }}
                                                    ></EditInput>
                                                </DropArea>
                                                <DropArea
                                                    onDrop={(
                                                        index,
                                                        variableName,
                                                        variableIndex
                                                    ) =>
                                                        linkVariable(
                                                            variableName,
                                                            variableIndex,
                                                            key
                                                        )
                                                    }
                                                    index={key}
                                                    optionalClassName={
                                                        props.columnDragActive
                                                            ? commonStyles.highlightValue
                                                            : undefined
                                                    }
                                                >
                                                    <EditInput
                                                        showDeleteButton={false}
                                                        disabled={true}
                                                        value={`${
                                                            leaders?.[0]?.[
                                                                index ??
                                                                    "preview"
                                                            ] ?? ""
                                                        }...`}
                                                    ></EditInput>
                                                </DropArea>
                                            </React.Fragment>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                        <Button
                            style={{ marginTop: 10 }}
                            className={cx(
                                "btn btn-sm btn-primary my-primary",
                                commonStyles.addItemButton
                            )}
                            onClick={() => {
                                let shownVariableIndices =
                                    props.finding.config.shownVariableIndices ??
                                    [];
                                shownVariableIndices = [
                                    ...shownVariableIndices,
                                    null,
                                ];
                                props.onChange(
                                    {
                                        ...props.finding,
                                        config: {
                                            ...props.finding.config,
                                            shownVariableIndices: shownVariableIndices,
                                        },
                                    },
                                    true
                                );
                            }}
                        >
                            {"\uFF0B Add Item"}
                        </Button>
                    </div>
                )}
                <div style={{ display: "flex", width: "100%" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                        }}
                    >
                        <span className={styles.optionName}>{"Show"}</span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={""}
                            styles={{
                                ...selectStyles,
                                container: (base) => ({
                                    ...base,
                                }),
                            }}
                            options={viewOptions}
                            onChange={(newValue) => {
                                props.onChange(
                                    {
                                        ...props.finding,
                                        config: {
                                            ...props.finding.config,
                                            viewOption: (newValue as {
                                                label: string;
                                                value: ViewOption;
                                            }).value,
                                        },
                                    },
                                    true
                                );
                            }}
                            value={
                                viewOptionsMap[
                                    props.finding.config
                                        .viewOption as ViewOption
                                ]
                            }
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: 10,
                        }}
                    >
                        <span
                            className={styles.optionName}
                            style={{ minWidth: undefined }}
                        >
                            {"Count"}
                        </span>
                        <Input
                            value={props.finding.config.count}
                            onChange={(value) => {
                                let count = Number(value);
                                if (Number.isNaN(count)) {
                                    count = 10;
                                }
                                props.onChange(
                                    {
                                        ...props.finding,
                                        config: {
                                            ...props.finding.config,
                                            count: count,
                                        },
                                    },
                                    true
                                );
                            }}
                        />
                    </div>
                    <div
                        className={commonStyles.upDownButtons}
                        style={{
                            marginLeft: 8,
                            width: 18,
                        }}
                    />
                </div>

                {props.finding.config.dataScope?.value != null && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                            className={styles.optionName}
                            style={{ marginTop: 20 }}
                        >
                            {"Geo variable"}
                        </span>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                marginTop: 7,
                                alignItems: "center",
                            }}
                        >
                            <Select
                                isClearable
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"Select variable"}
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        flex: 1,
                                    }),
                                }}
                                options={geographyVariables}
                                onChange={(newValue) => {
                                    let variable = newValue as {
                                        label: string;
                                        value: number;
                                    } | null;
                                    props.onChange(
                                        {
                                            ...props.finding,
                                            config: {
                                                ...props.finding.config,
                                                aggregateVariable:
                                                    variable?.label,
                                                aggregateVariableIndex:
                                                    variable?.value,
                                                geographyLevels:
                                                    variable != null
                                                        ? Object.fromEntries(
                                                              Variables(
                                                                  props.finding
                                                                      .config
                                                                      .dataScope
                                                                      ?.value,
                                                                  props.currentModuleId ??
                                                                      remoteModuleId
                                                              )
                                                                  .geographyGroup(
                                                                      variable.label
                                                                  )
                                                                  .map(
                                                                      (
                                                                          item
                                                                      ) => [
                                                                          item.level,
                                                                          item.name,
                                                                      ]
                                                                  )
                                                          )
                                                        : null,
                                            },
                                            content: {
                                                ...props.finding.content,
                                                data: {
                                                    ...props.finding.content
                                                        .data,
                                                    showMap:
                                                        variable?.value != null,
                                                },
                                            },
                                        },
                                        true
                                    );
                                }}
                                value={
                                    currentGeographyVariable != null
                                        ? {
                                              label:
                                                  currentGeographyVariable.name,
                                              value:
                                                  currentGeographyVariable.index,
                                          }
                                        : null
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <div
                                className={commonStyles.upDownButtons}
                                style={{
                                    marginLeft: 8,
                                    width: 18,
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default observer(LeadersLaggersSection);
