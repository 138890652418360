import React from "react";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import axios from "common/ServerConnection";
import tables from "common/Tables";
import { Permission } from "common/Permissions";
import { stringSessionId } from "common/SessionId";

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a === null || b === null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }

    return true;
}

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
            };
        }

        updateAggregateTablesList() {
            this.setStepState(StepState.running);
            tables(this.props.commonState.viewDataScopeId)
                .update()
                .then(() => {
                    this.setStepState(StepState.finished);
                })
                .catch((error) => {
                    this.setStepState(StepState.error, error);
                });
        }

        renderTables() {
            if (!this.state.expanded) return null;
            if (!this.props.commonState.viewDataScope) return null;
            let currentTables = tables(
                this.props.commonState.viewDataScopeId
            ).rawAndAggregateTableOptions.concat(
                tables(this.props.commonState.viewDataScopeId)
                    .rawAndOptimizedTableOptions
            );
            let tableDisplay = (
                <div
                    className="flex-simple-column"
                    style={{ background: "#3f4b62" }}
                >
                    {currentTables.map((item, index) => {
                        let tableName = `${index + 1}. ${item.label}`;
                        return (
                            <div className="my-row" key={index}>
                                <span
                                    style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                    className="content-regular-text"
                                >
                                    {tableName}
                                </span>
                                {this.props.commonState.viewDataScope
                                    ?.permissionType ===
                                    Permission.ReadWrite && (
                                    <div style={{ alignSelf: "center" }}>
                                        <Button
                                            className="close"
                                            onClick={() => {
                                                this.deleteTable(item);
                                            }}
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">
                                                &times;
                                            </span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            );

            return (
                <>
                    <div />
                    {tableDisplay}
                    <div />
                    <div />
                </>
            );
        }

        deleteTable(item) {
            let json = {
                table: item.value,
                data_table_idx: item.data_table_idx,
                condition_id: item.condition_id,
                optimized: item.optimized || false,
                update_id: stringSessionId(),
            };
            this.setStepState(StepState.running);
            axios
                .post("/api/e/delete_table", json)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        if (
                            this.props.commonState.viewDataTable != null &&
                            arraysEqual(
                                this.props.commonState.viewDataTable.value,
                                item.value
                            )
                        )
                            this.props.commonState.setViewDataTable(
                                tables(item.data_table_idx)
                                    .rawAndAggregateTableOptions[0]
                            );
                        this.updateAggregateTablesList();
                    }
                })
                .catch((error) => {
                    this.setStepState(StepState.error, String(error));
                });
        }

        render() {
            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        disabled={this.props.disabled}
                        className="btn-processing-tab"
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState({
                                expanded: !this.state.expanded,
                            });
                        }}
                    >
                        <span className="content-regular-text">Tables</span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    {this.state.expanded ? (
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            onClick={() => {
                                this.updateAggregateTablesList();
                            }}
                            style={{
                                fontFamily: "Roboto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: "25px",
                                width: "100%",
                                minWidth: "110px",
                            }}
                        >
                            REFRESH
                        </Button>
                    ) : (
                        <div style={{ minWidth: "110px" }} />
                    )}
                    <div>
                        {this.state.expanded &&
                            this.renderStepState(this.tablesIndex)}
                    </div>
                    {this.renderTables()}
                </>
            );
        }
    }
);

export { MainComponent };
export let requirePermission = "DataSetDeleteTable";