import "common/styles/App.css";
import React from "react";
import { reaction } from "mobx";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import TimeTrendPlotComponent from "common/graphics/TimeTrendPlotComponent";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";
import BaseJourney from "../../common/BaseJourney";
import { Api } from "./Api";
import DynamicOptionType from "common/DynamicOptionType";
import axios from "common/ServerConnection";
import { configVersion } from "common/PathConfigVersion";
import "rc-slider/assets/index.css";
import ErrorBoundary from "common/ErrorBoundary";
import { getLabelByTime } from "common/MonthData";
import Slider, { createSliderWithTooltip } from "rc-slider";
import RangeSelector from "common/graphics/RangeSelector";
// const Slider = require('rc-slider');
// const createSliderWithTooltip = Slider.createSliderWithTooltip;
const TooltipSlider = createSliderWithTooltip(Slider);

const Step = Object.freeze({
    initial: 0,
    selectDataScope: 1,
    selectTable: 2,
    selectConditions: 3,
    selectTimeParameter: 4,
    selectAggregate: 5,
    selectTarget: 6,
    selectAdditionalParameter: 7,
    selectAdditionalValue: 8,
    final: 9,
});

function StepFinal(props) {
    let {
        onBack,
        onSaveFinding,
        onNewFinding,
        onShowRecipesPopup,
        finding,
        loading,
        // data
    } = props;
    return (
        <>
            <div
                className="my-row"
                style={{
                    justifyContent: "space-between",
                    marginTop: 10,
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    onClick={() => {
                        onBack();
                    }}
                    style={{
                        minWidth: 100,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/data-exploration/chevron_back.png"
                    />
                </div>
                {loading ? (
                    <span className="exploration-big-title-span">
                        {"LOADING"}
                    </span>
                ) : finding ? (
                    <div
                        className="flex-simple-column"
                        style={{
                            width: "calc(100% - 200px)",
                            height: "100%",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                height: "380px",
                                alignSelf: "center",
                            }}
                        >
                            <ErrorBoundary>
                                <div
                                    style={{
                                        display: "flex",
                                        marginBottom: 10,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <RangeSelector
                                        value={finding}
                                        onChange={onNewFinding}
                                    />
                                </div>
                                <div style={{ height: "330px" }}>
                                    <TimeTrendPlotComponent
                                        editable
                                        config={finding.config}
                                        content={finding.content}
                                        onConfigChange={(config) => {
                                            let newFinding = {
                                                ...finding,
                                                config: config,
                                            };
                                            onNewFinding(newFinding);
                                        }}
                                    />
                                </div>
                            </ErrorBoundary>
                        </div>

                        <FinalStepBottomBar
                            onShowRecipesPopup={onShowRecipesPopup}
                            onSaveFinding={onSaveFinding}
                            topMargin={10}
                        />
                    </div>
                ) : (
                    <span className="exploration-big-title-span">
                        {"NO DATA FOR SELECTED FILTERS"}
                    </span>
                )}
                <div
                    style={{
                        minWidth: 100,
                        height: "100%",
                    }}
                />
            </div>
        </>
    );
}

class MainComponent extends BaseJourney {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            previousStep: 0,
            step: 0,
            animationDirection: "top",
            targetVariable: undefined,
            targetVariables: [],
            timeParameter: undefined,
            timeParameters: [],
            timeRange: [],
            finding: undefined,
            loading: false,
            additionalParameter: undefined,
            showRecipesPopup: false,
            leftAdditionalValue: undefined,
            additionalParameters: [],
            selectedTable: undefined,
            step2VariablesMap: {
                [Step.selectTarget]: ["targetVariable", "targetVariables"],
                [Step.selectTimeParameter]: ["timeParameter", "timeParameters"],
                [Step.selectAdditionalParameter]: [
                    "additionalParameter",
                    "additionalParameters",
                ],
            },
            conditions: [
                {
                    variable: null,
                    operation: null,
                    value: null,
                    logical: null,
                    leftBracket: null,
                    rightBracket: null,
                },
            ],
        };
        this.initializeVariables = this.initializeVariables.bind(this);
        this.switchVariables = this.switchVariables.bind(this);
        this.getData = this.getData.bind(this);
        this.getTimeRange = this.getTimeRange.bind(this);
    }
    clearOptionalArgs() {
        this.setState({
            targetVariable: null,
            targetVariableIndex: null,
            additionalParameter: null,
            additionalParameterIndex: null,
            leftAdditionalOperator: null,
            leftAdditionalValue: null,
            rightAdditionalOperator: null,
            rightAdditionalValue: null,
            selectedTable: this.defaultTable(),
            conditions: ConditionsSelector.defaultValue,
            dynamicOptions: {},
            timeSplit: null,
            timeParameter: null,
            timeParameterIndex: null,
            labelMapping: null,
            nameMapping: null,
            axesColor: null,
            linesCount: null,
            legendsColor: null,
            trend1Color: null,
            trend2Color: null,
            referenceLineColor: null,
            variableColor: null,
            minYRange: null,
            maxYRange: null,
        });
    }
    getConfig() {
        let config = {
            version: configVersion,
            dataScope: this.state.dataScope ?? null,
            timeParameter: this.state.timeParameter ?? null,
            timeParameterIndex:
                this.getVariables().variableToIndex(this.state.timeParameter) ??
                null,
            additionalParameter: this.state.additionalParameter ?? null,
            additionalParameterIndex:
                this.getVariables().variableToIndex(
                    this.state.additionalParameter
                ) ?? null,
            selectedTable: this.state.selectedTable ?? null,
            leftAdditionalValue: this.state.leftAdditionalValue ?? null,
            leftAdditionalOperator: this.state.leftAdditionalOperator ?? null,
            rightAdditionalValue: this.state.rightAdditionalValue ?? null,
            rightAdditionalOperator: this.state.rightAdditionalOperator ?? null,
            timeSplit: this.state.timeSplit ?? null,
            conditions: this.state.conditions ?? null,
            additionalParameters: this.state.additionalParameters ?? null,
            targetVariable: this.state.targetVariable ?? null,
            journeyName: this.props.journeyName,
            targetVariableIndex:
                this.getVariables().variableToIndex(
                    this.state.targetVariable
                ) ?? null,
            labelMapping: this.state.labelMapping ?? null,
            nameMapping: this.state.nameMapping ?? null,
            axesColor: this.state.axesColor ?? null,
            linesCount: this.state.linesCount ?? null,
            legendsColor: this.state.legendsColor ?? null,
            trend1Color: this.state.trend1Color ?? null,
            trend2Color: this.state.trend2Color ?? null,
            referenceLineColor: this.state.referenceLineColor ?? null,
            variableColor: this.state.variableColor ?? null,
            minYRange: this.state.minYRange ?? null,
            maxYRange: this.state.maxYRange ?? null,
        };
        if (this.props.addToCanvas) {
            config.dynamicOptions = this.prepareDynamicOptions(
                this.state.dynamicOptions,
                config
            );
        }
        return config;
    }

    getData() {
        let config = this.getConfig();
        this.setState({ loading: true });
        Api.getData(config, undefined, this.props.currentModuleId)
            .then((item) => {
                this.setState({
                    finding: item,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }
    buildContent() {
        switch (this.state.step) {
            case Step.initial:
                return this.buildInitial(
                    "How do you want to examine time trends?"
                );
            case Step.selectDataScope:
                return this.buildDataScopesSelector();
            case Step.selectTable:
                return this.buildTableSelector();
            case Step.selectConditions:
                return this.buildConditionsSelector();
            case Step.selectTimeParameter:
                return this.buildVariablesSelector(
                    "I want to see how, splitting by",
                    "timeParameter",
                    "timeParameters",
                    false,
                    DynamicOptionType.variable,
                    "timeParameterIndex"
                );
            case Step.selectAggregate:
                return this.buildTimeSliderSelector();
            case Step.selectTarget:
                return this.buildVariablesSelector(
                    "our outcome compared to our historical trends",
                    "targetVariable",
                    "targetVariables",
                    false,
                    DynamicOptionType.variable,
                    "targetVariableIndex"
                );
            case Step.selectAdditionalParameter:
                return this.buildVariablesSelector(
                    "our outcome compared to our historical trends for",
                    "additionalParameter",
                    "additionalParameters",
                    false,
                    DynamicOptionType.additionalParameter,
                    "additionalParameterIndex"
                );
            case Step.selectAdditionalValue:
                return this.buildAdditionalValueSelector(
                    `our outcome compared to our historical trends for ${this.state.additionalParameter}`,
                    true
                );

            case Step.final:
                return (
                    <StepFinal
                        onShowRecipesPopup={this.showRecipesPopup}
                        dataReceived={(data) => {
                            this.setState({ data: data });
                        }}
                        onNewFinding={this.onNewFinding}
                        loading={this.state.loading}
                        onBack={this.back}
                        onSaveFinding={this.saveFinding}
                        finding={this.state.finding}
                    />
                );

            default:
                return <div />;
        }
    }
    initializeVariables() {
        let scopeVariables = this.getVariables();
        let targetVariables = scopeVariables.numericVariables;
        this.setState(
            {
                timeParameters: scopeVariables.dataVariables
                    .filter((item) => item.type === "datetime")
                    .map((item) => item.name),
                additionalParameters: [null].concat(
                    scopeVariables.dataVariables
                        .filter((item) => item.panel !== "time")
                        .map((item) => item.name)
                ),
                targetVariables: targetVariables.map((item) => item.name),
            },
            () => {
                this.getTimeRange();
            }
        );
    }
    buildLeftBar() {
        return super.buildLeftBar(this.state.step < Step.final - 1);
    }
    componentDidMount() {
        this.getLastJourneyConfig();
    }
    switchVariables() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
        this.initializeVariables();
        this.initializeVariablesReaction = reaction(
            () => this.getVariables().dataVariables,
            () => {
                this.initializeVariables();
            }
        );
    }
    getTimeRange() {
        if (this.state.timeParameter) {
            axios
                .post(
                    "/api/e/autocomplete",
                    {
                        data_table_idx: this.dataScopeValue(),
                        variable_index: this.getVariables().variableToIndex(
                            this.state.timeParameter
                        ),
                        starts_with: "",
                        filter_missing: true,
                        module_id: this.props.currentModuleId,
                    },
                    null
                )
                .then((response) => {
                    if (response.data.success) {
                        let data = response.data;
                        this.setState((state) => ({
                            timeRange: data.items,
                            timeSplit:
                                state.timeSplit == null && data.items.length > 0
                                    ? data.items[0]
                                    : state.timeSplit,
                        }));
                    }
                })
                .catch((error) => {
                    console.log(String(error));
                });
        }
    }
    componentWillUnmount() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
    }
    showFinalStep() {
        if (
            this.state.targetVariable &&
            this.state.timeSplit != null &&
            this.state.step > Step.initial &&
            this.state.step < Step.final
        ) {
            this.setState({ animationDirection: "top" }, () => {
                this.setState((state) => ({
                    previousStep: state.step,
                    step: Step.final,
                }));
            });
            this.getData();
            this.saveNewJourneyConfig();
        }
    }
    buildTimeSliderSelector() {
        if (this.state.timeRange.length === 0) return null;
        return (
            <div className="my-row" style={{ height: "100%" }}>
                {this.buildLeftBar()}
                <div
                    className="flex-simple-column"
                    style={{
                        marginTop: 25,
                        marginLeft: 100,
                        width: "100%",
                    }}
                >
                    <span className="exploration-big-title-span">
                        {"Splitting by"}
                    </span>
                    {this.state.timeRange.length > 0 && (
                        <div style={{ width: "calc(100% - 100px)" }}>
                            <TooltipSlider
                                tipFormatter={(value) => {
                                    return getLabelByTime(value);
                                }}
                                min={this.state.timeRange[0]}
                                max={
                                    this.state.timeRange[
                                        this.state.timeRange.length - 1
                                    ]
                                }
                                marks={this.state.timeRange.reduce(function (
                                    map,
                                    obj
                                ) {
                                    map[obj] = {};
                                    return map;
                                },
                                {})}
                                step={null}
                                onChange={(value) => {
                                    this.setState({
                                        timeSplit: value,
                                    });
                                }}
                                defaultValue={this.state.timeSplit}
                            />
                        </div>
                    )}
                    <div style={{ height: 250 }} />
                </div>
            </div>
        );
    }

    selectVariable(variableName, variablesName, index) {
        let stateDiff = {
            [variableName]: this.state[variablesName][index],
        };
        if (this.state.step === Step.selectAdditionalParameter) {
            stateDiff.leftAdditionalValue = undefined;
            stateDiff.rightAdditionalValue = undefined;
        }

        this.setState(stateDiff, () => {
            if (this.state.step === Step.selectTimeParameter) {
                this.getTimeRange();
            }
            setTimeout(() => {
                this.stepDown();
            }, 100);
        });
    }

    getSubtitle() {
        let conditionsString = conditionsToString(
            Array.from(this.state.conditions)
        );
        if (
            this.state.step === Step.selectTable ||
            this.state.step === Step.selectDataScope
        )
            return "How do you want to examine time trends?";
        if (
            this.state.step > Step.selectTable &&
            this.state.step < Step.final
        ) {
            let subtitle = `For the data in ${
                this.state.dataScope ? this.state.dataScope.label : ""
            } ${this.state.selectedTable.label}`;
            if (this.state.step > Step.selectConditions) {
                if (conditionsString.length > 0)
                    subtitle += ` and under the conditions ${conditionsString}`;
            }
            if (this.state.step > Step.selectAggregate)
                subtitle += ` I want to see how, splitting by ${
                    this.state.timeParameter || ""
                } ${getLabelByTime(this.state.timeSplit) || ""},`;
            if (this.state.step > Step.selectTarget)
                subtitle += ` our ${this.state.targetVariable || ""}`;
            if (this.state.step > Step.selectAdditionalParameter)
                subtitle += ` compared to our historical trends for ${
                    this.state.additionalParameter || ""
                }`;

            return subtitle;
        }

        if (this.state.step === Step.final) {
            return (
                <>
                    <span>For the data </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.dataScope
                            ? this.state.dataScope.label.concat(" ")
                            : ""}
                    </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedTable.label}
                    </span>
                    {conditionsString.length > 0 && (
                        <>
                            <span> and under the conditions </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {conditionsString}
                            </span>
                        </>
                    )}
                    <span> I want to see how, splitting by </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {`${this.state.timeParameter} ${
                            getLabelByTime(this.state.timeSplit) || ""
                        }`}
                    </span>
                    <span>, our </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.targetVariable || ""}
                    </span>
                    <span> compared to our historical trends</span>
                    {this.state.additionalParameter &&
                    [
                        this.state.leftAdditionalValue,
                        this.state.rightAdditionalValue,
                    ]
                        .filter((item) => item)
                        .filter((item) => item.value).length > 0 ? (
                        <>
                            <span> for </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {(this.state.leftAdditionalValue?.value != null
                                    ? ` ${this.state.additionalParameter} ${
                                          this.state.leftAdditionalOperator
                                              ?.label ?? "="
                                      } ${this.state.leftAdditionalValue.label}`
                                    : "") +
                                    (this.state.rightAdditionalValue?.label !=
                                    null
                                        ? (this.state.leftAdditionalValue
                                              ?.label != null
                                              ? ", "
                                              : " ") +
                                          `${this.state.additionalParameter} ${
                                              this.state.rightAdditionalOperator
                                                  ?.label ?? "="
                                          } ${
                                              this.state.rightAdditionalValue
                                                  .label
                                          }`
                                        : "")}
                            </span>
                        </>
                    ) : null}
                    <span>.</span>
                </>
            );
        }
        return "";
    }
    getFooterTitle() {
        switch (this.state.step) {
            case Step.initial:
                return "... For the data in...";
            case Step.selectTable:
                return "... and under the conditions...";
            case Step.selectConditions:
                return "... I want to see how, splitting by ...";
            case Step.selectTimeParameter:
                return "... splitting by ...";
            case Step.selectAggregate:
                return "... our outcome compared to our historical trends ...";
            case Step.selectTarget:
                return "... [optional] for ...";
            default:
                return "";
        }
    }
    stepDown() {
        let delta = 1;
        if (this.state.step === Step.selectTable && !this.state.selectedTable) {
            return;
        }
        if (
            this.state.step === Step.selectAdditionalParameter &&
            !this.state.additionalParameter
        )
            delta = 2;
        if (this.state.step + delta < Step.final) {
            this.setState({ animationDirection: "top" }, () => {
                this.setState((state) => ({
                    previousStep: state.step,
                    step: state.step + delta,
                }));
            });
        } else {
            this.showFinalStep();
        }
    }

    stepUp() {
        let step = this.state.step;

        if (step > Step.initial)
            this.setState({ animationDirection: "bottom" }, () => {
                this.setState({
                    step: step - 1,
                });
            });
    }

    render() {
        return (
            <div
                className="dashboard-rect-journey-focus"
                tabIndex="0"
                style={{
                    height: 570,
                    overflow: "hidden",
                    display: !this.props.collapsed ? "block" : "none",
                }}
                onClick={() => {
                    if (this.state.step === Step.initial) this.stepDown();
                }}
                onKeyDown={(evt) => {
                    if (evt.key === "Escape") {
                        this.props.onClose();
                    }
                    if (evt.key === "ArrowDown") {
                        if (
                            this.state.step > Step.initial &&
                            this.state.step < Step.final
                        )
                            this.stepDown();
                        evt.preventDefault();
                    }
                    if (evt.key === "ArrowLeft") {
                        if (this.state.step === Step.final) {
                            this.back();
                        }
                    }
                    if (evt.key === "ArrowRight") {
                        this.showFinalStep();
                    }
                    if (evt.key === "ArrowUp") {
                        if (this.state.step < Step.final) this.stepUp();
                        evt.preventDefault();
                    }
                    if (evt.key === "p") {
                        if (this.state.step === Step.final) {
                            this.saveFinding();
                        }
                    }
                    if (evt.key === "d") {
                        if (this.state.step === Step.final) {
                            evt.preventDefault();
                            this.showRecipesPopup();
                        }
                    }

                    let variableInfo = this.state.step2VariablesMap[
                        this.state.step
                    ];
                    if (variableInfo) {
                        let variablesLength = this.state[variableInfo[1]]
                            .length;
                        let variableIndex = evt.keyCode - 65;
                        if (
                            variableIndex >= 0 &&
                            variableIndex < variablesLength
                        ) {
                            this.selectVariable(
                                variableInfo[0],
                                variableInfo[1],
                                variableIndex
                            );
                        }
                    }
                }}
            >
                <div
                    className="my-row"
                    style={{ justifyContent: "space-between", height: "100%" }}
                >
                    <div
                        className="flex-column"
                        style={{
                            height: "100%",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {this.buildHeader()}

                        <TransitionGroup
                            style={{
                                minHeight: "inherit",
                            }}
                        >
                            <CSSTransition
                                style={{
                                    height: "100%",
                                }}
                                key={this.state.step}
                                timeout={500}
                                classNames={"journeywizard-".concat(
                                    this.state.animationDirection || ""
                                )}
                            >
                                <div
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    {this.buildContent()}
                                </div>
                            </CSSTransition>
                        </TransitionGroup>

                        <Footer
                            showArrow={
                                this.state.step > Step.initial &&
                                this.state.step < Step.final
                            }
                            title={this.getFooterTitle()}
                        />
                    </div>
                </div>
                {this.buildAddToRecipesPopup()}
            </div>
        );
    }
}

export { MainComponent };
