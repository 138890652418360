import React, { Component } from "react";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import { Element } from "react-scroll";
import { observer } from "mobx-react";
import "common/styles/App.css";
import { getCustomSelectStyleLight } from "common/SelectStyles";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Tables, { TableOption } from "common/Tables";

import { ConditionsSelector, Condition } from "common/Conditions";
import Variables, { VariableOption } from "common/Variables";
import { getRawDataApi, renameVariablesApi } from "common/DataApi";
import { ColumnFormat } from "common/Canvas";
import {
    Type,
    Panel,
    SchemaOptions,
    variableToColumnFormat,
    applyColumnFormatsToSchemaOptions,
} from "common/InputData";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import CanvasTreeStore from "./CanvasTreeStore";
import FormatDropdown from "./FormatDropdown";
import { reaction } from "mobx";
import { strftime } from "common/utilities/TimeFormatUtils";
import { RenameVariable } from "common/VariableEditor";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import { lightThemeStyle } from "common/LightThemeStyle";
import remoteModuleId from "common/remoteModuleId";
import DatasetFilterToolbar from "./DatasetFilterToolbar";
import DatasetEditToolbar from "./DatasetEditToolbar";

interface State {
    dataScope: DataScopeOption | undefined;
    table: TableOption | null;
    advancedOptionsExpanded: boolean;

    bottomRows: boolean;
    limitText: string;
    conditions: Condition[];
    columnFilter: VariableOption[];
    rawData: { [key: string]: (string | number | null)[] } | null;
    rowIds: number[];
    columnFormats: ColumnFormat[];
    popupMessage: string;
    popupStatus: PopupStatus | null;
}

interface Props {
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
    onReturnToCanvas: () => void;
    spreadsheetId: string;
    dockSize: number;
}

@observer
class MainComponent extends Component<Props, State> {
    dataVariablesReaction: any;
    dataScopesReaction: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            dataScope: undefined,
            table: null,
            advancedOptionsExpanded: false,
            bottomRows: false,
            limitText: "10",
            conditions: ConditionsSelector.defaultValue,
            columnFilter: [],
            rawData: null,
            rowIds: [],
            columnFormats: [],
            popupStatus: null,
            popupMessage: "",
        };

        this.getData = this.getData.bind(this);
        this.displayClick = this.displayClick.bind(this);
        this.initializeDataScope = this.initializeDataScope.bind(this);
    }

    initializeDataScope(fromReaction: boolean) {
        if (fromReaction) {
            if (this.dataScopesReaction != null) {
                this.dataScopesReaction();
            }
        }
        let grid = this.props.canvasTreeStore.gridsState.get(
            this.props.spreadsheetId
        );
        let fullSpreadSheetBackendOutputOptions =
            grid?.fullSpreadSheetBackendOutputOptions;
        if (fullSpreadSheetBackendOutputOptions == null) return;
        const dataScopes =
            this.props.currentModuleId == null
                ? DataScopes
                : DataScopesForModules(this.props.currentModuleId);
        let dataScopeOption = dataScopes.dataScopesOptions.find(
            (datascope) =>
                datascope.value ===
                fullSpreadSheetBackendOutputOptions!.dataScopeId
        );
        if (dataScopeOption != null) {
            this.setState(
                {
                    dataScope: dataScopeOption,
                    table:
                        fullSpreadSheetBackendOutputOptions?.tableOption ??
                        Tables(
                            dataScopeOption.value,
                            this.props.currentModuleId
                        ).tableToOption(),
                    bottomRows:
                        fullSpreadSheetBackendOutputOptions?.bottomRows ??
                        false,
                    limitText:
                        fullSpreadSheetBackendOutputOptions?.limit != null
                            ? String(fullSpreadSheetBackendOutputOptions.limit)
                            : "10",
                    conditions:
                        fullSpreadSheetBackendOutputOptions?.conditions &&
                        fullSpreadSheetBackendOutputOptions.conditions.length >
                            0
                            ? fullSpreadSheetBackendOutputOptions?.conditions
                            : ConditionsSelector.defaultValue,
                    columnFilter:
                        fullSpreadSheetBackendOutputOptions?.variables ?? [],
                },
                this.getData
            );
        } else {
            if (!fromReaction)
                this.dataScopesReaction = reaction(
                    () => dataScopes.dataScopesOptions,
                    () => {
                        this.initializeDataScope(true);
                    }
                );
        }
    }
    componentDidMount() {
        this.initializeDataScope(false);
    }

    componentWillUnmount() {
        if (this.dataVariablesReaction) this.dataVariablesReaction();
        if (this.dataScopesReaction != null) this.dataScopesReaction();
    }

    private initializeColumnFormats(dataScope: DataScopeOption) {
        let tableHeader: VariableOption[] = [];
        if (this.state.columnFilter.length > 0) {
            tableHeader = this.state.columnFilter.sort(
                (varOption1, varOption2) => varOption1.value - varOption2.value
            );
        } else {
            tableHeader = Variables(
                dataScope.value,
                this.props.currentModuleId ?? remoteModuleId
            ).variableOptions;
        }
        let columnFormats: ColumnFormat[] = [];
        for (let varInfo of tableHeader) {
            let variable = Variables(
                dataScope.value,
                this.props.currentModuleId ?? remoteModuleId
            ).getVariableByIndex(varInfo.value)!;
            let columnFormat = variableToColumnFormat(variable);
            columnFormats.push(columnFormat);
        }
        this.setState({ columnFormats: columnFormats });
    }
    private initializeColumnFormatsReaction(dataScope: DataScopeOption): void {
        if (this.dataVariablesReaction) this.dataVariablesReaction();
        if (
            Variables(
                dataScope.value,
                this.props.currentModuleId ?? remoteModuleId
            ).initialized
        ) {
            this.initializeColumnFormats(dataScope);
        }
        this.dataVariablesReaction = reaction(
            () =>
                Variables(
                    dataScope.value,
                    this.props.currentModuleId ?? remoteModuleId
                ).dataVariables,
            () => {
                this.initializeColumnFormats(dataScope);
            }
        );
    }

    private getData(): void {
        let columnFilter: VariableOption[] | undefined = undefined;
        if (this.state.columnFilter.length > 0) {
            columnFilter = this.state.columnFilter;
        }

        if (this.state.dataScope != null) {
            getRawDataApi(
                this.state.table ?? {
                    label: "",
                    value: [],
                    optimized: false,
                    data_table_idx: this.state.dataScope.value,
                },
                Number(this.state.limitText),
                this.state.conditions,
                columnFilter,
                this.state.bottomRows,
                this.props.currentModuleId ?? remoteModuleId
            )
                .then((data) => {
                    this.setState({
                        rawData: data.currentLevels,
                        rowIds: data.rowId,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
            this.initializeColumnFormatsReaction(this.state.dataScope);
        } else {
            this.setState({
                rawData: null,
                rowIds: [],
            });
        }
    }

    private displayClick(): void {
        if (this.state.dataScope == null) return;

        let columnFilter: VariableOption[] | undefined = undefined;
        if (this.state.columnFilter.length > 0) {
            columnFilter = this.state.columnFilter;
        }
        this.props.canvasTreeStore
            .readDataIntoSpreadSheet(
                this.props.spreadsheetId,
                this.state.dataScope.value,
                this.state.table,
                Number(this.state.limitText),
                true,
                false,
                this.state.conditions,
                columnFilter,
                this.state.bottomRows
            )
            .then(() => {
                this.props.onReturnToCanvas();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private renderDataTable(): JSX.Element | null {
        if (
            this.state.rawData == null ||
            this.state.dataScope == null ||
            !Variables(
                this.state.dataScope.value,
                this.props.currentModuleId ?? remoteModuleId
            ).initialized
        ) {
            return <div style={{ flex: 1 }} />;
        }
        let tableHeader: string[];
        if (this.state.columnFilter.length > 0) {
            tableHeader = this.state.columnFilter
                .sort(
                    (varOption1, varOption2) =>
                        varOption1.value - varOption2.value
                )
                .map((varOption) => varOption.label);
        } else {
            tableHeader = Variables(
                this.state.dataScope.value,
                this.props.currentModuleId ?? remoteModuleId
            ).variableNames;
        }
        let variablesInfo = tableHeader.map((name) =>
            Variables(
                this.state.dataScope!.value,
                this.props.currentModuleId ?? remoteModuleId
            ).getVariableByName(name)
        );
        let tableContent: (string | number | null)[][] = [];
        let rowCount = this.state.rowIds.length;
        for (let i = 0; i < rowCount; ++i) {
            let row: (string | number | null)[] = [];
            for (let [j, varName] of tableHeader.entries()) {
                if (typeof this.state.rawData[varName] === "undefined") break;

                row.push(
                    variablesInfo[j]!.type === "datetime" &&
                        typeof this.state.rawData[varName][i] === "number"
                        ? strftime(
                              variablesInfo[j]!.format!,
                              new Date(
                                  (this.state.rawData[varName][i] as number) *
                                      1000
                              )
                          )
                        : this.state.rawData[varName][i] ||
                              (typeof this.state.rawData[varName][i] ===
                              "number"
                                  ? this.state.rawData[varName][i]
                                  : "-")
                );
            }
            tableContent.push(row);
        }
        return (
            <div
                style={{
                    marginLeft: "17px",
                    marginRight: "17px",
                    display: "flex",
                    justifyContent: "center",
                    overflow: "hidden",
                }}
            >
                <Element
                    name="scrollable"
                    className="element"
                    style={{
                        padding: "10px",
                        overflow: "auto",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "8px",
                        width: "100%",
                    }}
                >
                    <AdminTableWithFullFeatures
                        headerRenderer={(item, index) => (
                            <div className="flex-simple-column" key={index}>
                                <div
                                    className="my-row"
                                    style={{
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            fontSize: "10px",
                                            color: "black",
                                        }}
                                    >
                                        {item}
                                    </span>
                                    <FormatDropdown
                                        onChangeFormat={(format) => {
                                            let schemaOptions: SchemaOptions = {
                                                names: [item],
                                                types: [Type.Float],
                                                units: [""],
                                                panels: [Panel.Regular],
                                                levels: [null],
                                                formats: [null],
                                                namesToIndices: {},
                                            };
                                            schemaOptions = applyColumnFormatsToSchemaOptions(
                                                [format],
                                                schemaOptions
                                            );
                                            let renameVariable: RenameVariable = {
                                                index: null,
                                                name: schemaOptions.names[0],
                                                new_name:
                                                    schemaOptions.names[0],
                                                type: schemaOptions.types[0],
                                                panel: schemaOptions.panels[0],
                                                unit: schemaOptions.units[0],
                                                level: schemaOptions.levels[0],
                                                format:
                                                    schemaOptions.formats[0],
                                            };
                                            renameVariablesApi(
                                                this.state.dataScope!.value,
                                                [renameVariable],
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            )
                                                .then(() => {
                                                    this.setState({
                                                        rawData: null,
                                                        rowIds: [],
                                                        columnFormats: [],
                                                        popupStatus:
                                                            PopupStatus.Success,
                                                        popupMessage:
                                                            "Variable is Edited Succesfully",
                                                    });
                                                    Variables(
                                                        this.state.dataScope!
                                                            .value,
                                                        this.props
                                                            .currentModuleId ??
                                                            remoteModuleId
                                                    ).update(
                                                        this.props
                                                            .currentModuleId ??
                                                            remoteModuleId
                                                    );
                                                    this.getData();
                                                })
                                                .catch((error) => {
                                                    this.setState({
                                                        popupStatus:
                                                            PopupStatus.Error,
                                                        popupMessage: String(
                                                            error
                                                        ),
                                                    });
                                                });
                                        }}
                                        column={index}
                                        format={this.state.columnFormats[index]}
                                    />
                                </div>
                            </div>
                        )}
                        className="import-table"
                        paging={false}
                        small={false}
                        tableName="Data"
                        tableHeader={tableHeader}
                        tableContent={tableContent}
                    />
                </Element>
            </div>
        );
    }

    public render(): JSX.Element {
        const dataScopes =
            this.props.currentModuleId == null
                ? DataScopes
                : DataScopesForModules(this.props.currentModuleId);
        let selectStyles = getCustomSelectStyleLight(14, false);

        return (
            <div
                className="hide-scroll"
                style={{
                    backgroundColor: lightThemeStyle.backgroundColor,
                    height: this.props.dockSize,
                }}
            >
                <div
                    className="flex-simple-column"
                    style={{
                        boxShadow: "none",
                        backgroundColor: lightThemeStyle.contentColor,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            marginTop: "20px",
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: "57px",
                            border: "1px solid #d1d1d1",
                            borderRadius: 6,
                            backgroundColor: "#f8f8f8",
                            padding: 20,
                            margin: 20,
                        }}
                    >
                        <div
                            className="content-regular-text"
                            style={{
                                fontSize: "20px",
                                fontWeight: 600,
                                marginBottom: 15,
                                marginRight: "1em",
                                color: lightThemeStyle.primaryTextColor,
                            }}
                        >
                            VIEW DATA
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Select
                                inputId="select-data-scope"
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"Select an existing data set"}
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "30em",
                                        height: "38px",
                                    }),
                                }}
                                options={dataScopes.dataScopesOptions}
                                onChange={(newValue) => {
                                    this.setState(
                                        {
                                            dataScope: newValue as DataScopeOption,
                                            table: Tables(
                                                (newValue as DataScopeOption)
                                                    .value,
                                                this.props.currentModuleId
                                            ).tableToOption(),
                                            conditions:
                                                ConditionsSelector.defaultValue,
                                            columnFilter: [],
                                        },
                                        this.getData
                                    );
                                }}
                                value={this.state.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <div
                                style={{
                                    color: this.state.advancedOptionsExpanded
                                        ? lightThemeStyle.secondaryTextColor
                                        : lightThemeStyle.primaryTextColor,
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginLeft: 15,
                                    backgroundColor: this.state
                                        .advancedOptionsExpanded
                                        ? "#e6edf4"
                                        : undefined,
                                    padding: 10,
                                    borderRadius: 4,
                                    cursor: "pointer",
                                }}
                                className="unselectable"
                                onClick={() => {
                                    this.setState((state) => ({
                                        advancedOptionsExpanded: !state.advancedOptionsExpanded,
                                    }));
                                }}
                            >
                                Advanced Options
                            </div>
                        </div>
                        {this.state.advancedOptionsExpanded && (
                            <div
                                className="flex-simple-column"
                                style={{
                                    marginTop: "5px",
                                }}
                            >
                                <DatasetFilterToolbar
                                    table={this.state.table}
                                    dataScopeId={this.state.dataScope?.value}
                                    currentModuleId={this.props.currentModuleId}
                                    conditions={this.state.conditions}
                                    canvasTreeStore={this.props.canvasTreeStore}
                                    bottomRows={this.state.bottomRows}
                                    limitText={this.state.limitText}
                                    columnFilter={this.state.columnFilter}
                                    spreadsheetId={this.props.spreadsheetId}
                                    onChange={(changes) => {
                                        this.setState(
                                            changes as any,
                                            this.getData
                                        );
                                    }}
                                />
                                <div
                                    style={{
                                        backgroundColor: "#d1d1d1",
                                        height: "1px",
                                        marginTop: "20px",
                                        marginBottom: "10px",
                                    }}
                                />
                                <div
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "15px",
                                        display: "flex",
                                    }}
                                >
                                    <DatasetEditToolbar
                                        table={this.state.table}
                                        dataScopeId={
                                            this.state.dataScope?.value
                                        }
                                        currentModuleId={
                                            this.props.currentModuleId
                                        }
                                        onUpdate={this.getData}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {this.renderDataTable()}
                    <div style={{ flex: 1 }} />
                    <div
                        style={{
                            marginTop: "18px",
                            marginBottom: "32px",
                            marginRight: "95px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            type="button"
                            className="btn btn-lg my-outline"
                            style={{
                                fontSize: "14px",
                                width: "6em",
                                height: "33px",
                                paddingTop: 0,
                                paddingBottom: 0,
                                borderColor: "transparent",
                                color: "#3b82c9",
                                border: "1px solid #3b82c9",
                                backgroundColor: "transparent",
                            }}
                            onClick={this.props.onReturnToCanvas}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                fontSize: "14px",
                                width: "15em",
                                height: "33px",
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginLeft: "5px",
                                backgroundColor: "#3b82c9",
                            }}
                            disabled={this.state.dataScope == null}
                            onClick={this.displayClick}
                        >
                            Display in spreadsheet
                        </Button>
                    </div>
                </div>
                {this.state.popupStatus != null && (
                    <StatusPopup
                        onClose={() => {
                            this.setState({
                                popupMessage: "",
                                popupStatus: null,
                            });
                        }}
                        status={this.state.popupStatus}
                        message={this.state.popupMessage}
                    />
                )}
            </div>
        );
    }
}

export { MainComponent };
