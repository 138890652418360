import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import {
    BoxPlotFinding,
    ViolinPlotFinding,
    RidgelineFinding,
    isRidgeline,
} from "common/Finding";
import remoteModuleId from "common/remoteModuleId";
import { updateOriginalName, updateOriginalNames } from "../../common/Utils";

const journeyNameToTypeMap: Readonly<{ [key: string]: string }> = {
    BoxPlotJourney: "boxplot",
    ViolinPlotJourney: "violinplot",
    RidgelineJourney: "ridgeline",
};

class Api {
    static getDefaultConfig(
        journeyName: string
    ): BoxPlotFinding["config"] | ViolinPlotFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            showXAxisName: true,
            showYAxisName: true,
            showGrid: false,
        };
    }

    static getPreviewFinding(
        journeyName: string
    ): BoxPlotFinding | ViolinPlotFinding | RidgelineFinding {
        let groupValues = [
            "A",
            "A",
            "A",
            "A",
            "A",
            "B",
            "B",
            "B",
            "B",
            "B",
            "B",
        ];
        let metricValues: number[];
        if (journeyName === "BoxPlotJourney") {
            metricValues = [
                // A
                50,
                60,
                70,
                80,
                90,
                // B
                100,
                10,
                20,
                30,
                40,
                50,
            ];
        } else {
            metricValues = [
                // A
                59,
                60,
                61,
                65,
                90,
                // B
                10,
                28,
                29,
                30,
                31,
                100,
            ];
        }
        if (journeyName === "RidgelineJourney") {
            let item: RidgelineFinding = {
                type: journeyNameToTypeMap[journeyName],
                content: {
                    data: [
                        {
                            name: "metric",
                            value: metricValues,
                        },
                        {
                            name: "group",
                            value: groupValues,
                        },
                    ],
                    time: {
                        name: "time",
                        value: [],
                        uniqueValues: [],
                    },
                },
                config: Api.getDefaultConfig(journeyName),
            };
            return item;
        } else {
            let item: BoxPlotFinding | ViolinPlotFinding = {
                type: journeyNameToTypeMap[journeyName],
                content: {
                    data: [
                        {
                            name: "group",
                            value: groupValues,
                        },
                        {
                            name: "metric",
                            value: metricValues,
                        },
                    ],
                    time: {
                        name: "time",
                        value: [],
                        uniqueValues: [],
                    },
                },
                config: Api.getDefaultConfig(journeyName),
            };
            return item;
        }
    }

    static async getData<
        F extends BoxPlotFinding | ViolinPlotFinding | RidgelineFinding
    >(finding: F, findingOptions: any, moduleId: number): Promise<F> {
        let { dataScope, selectedTable, conditions } = finding.config ?? {};
        let variables = finding.content.data.filter(
            (item) => item.variableIndex != null
        );
        if (variables.length !== 2) return Promise.resolve(finding);
        conditions = filterNonEmptyConditions(conditions ?? []);

        let variableIndices = new Set(
            variables.map((variable) => variable.variableIndex)
        );

        if (finding.content.time?.variableIndex != null) {
            variableIndices.add(finding.content.time.variableIndex);
        }

        let requestJson = {
            variable_indices: Array.from(variableIndices),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            conditions: conditionsToJson(conditions),
            data_table_idx: dataScope == null ? 0 : dataScope.value,
            module_id: moduleId ?? remoteModuleId,
            use_indices_in_current_levels: true,
        };

        return axios
            .post<{
                success: boolean;
                error_msg: string;
                current_levels: {
                    [key: string]: (number | string | null)[];
                };
                row_id: number[];
                count?: number;
            }>("/api/e/get_raw_data", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newTime: BoxPlotFinding["content"]["time"] = {
                        ...finding.content.time,
                        name: finding.content.time?.name ?? "name",
                        value: [],
                        uniqueValues: [],
                    };

                    let newX: (string | number)[] = [];
                    let newY: (string | number)[] = [];

                    let x =
                        response.data.current_levels[
                            variables[0].variableIndex!
                        ];
                    let y =
                        response.data.current_levels[
                            variables[1].variableIndex!
                        ];

                    let xCast = isRidgeline(finding) ? Number : String;
                    let yCast = isRidgeline(finding) ? String : Number;

                    let time: (string | number | null)[] = [];
                    if (finding.content.time?.variableIndex != null) {
                        time =
                            response.data.current_levels[
                                finding.content.time.variableIndex
                            ];
                    }

                    for (let i = 0; i < x.length; ++i) {
                        if (x[i] != null && y[i] != null) {
                            newX.push(xCast(x[i]));
                            newY.push(yCast(y[i]));
                            if (finding.content.time?.variableIndex != null) {
                                newTime.value.push(time[i]);
                            }
                        }
                    }

                    if (finding.content.time?.variableIndex != null) {
                        newTime.uniqueValues = Array.from(
                            new Set(
                                newTime.value.filter(
                                    (item): item is string | number =>
                                        item != null
                                )
                            )
                        ).sort((value1, value2) =>
                            typeof value1 === "number" &&
                            typeof value2 === "number"
                                ? value1 - value2
                                : String(value1).localeCompare(String(value2))
                        );
                    }
                    let newData: F["content"]["data"] = Array.from(
                        finding.content.data
                    ) as F["content"]["data"];

                    for (let i = 0; i < 2; ++i) {
                        newData[i] = {
                            ...newData[i],
                        };
                    }

                    newData[0].value = newX;
                    newData[1].value = newY;

                    // Check wherther variables were renamed
                    updateOriginalNames(newData, dataScope.value, moduleId);
                    updateOriginalName(newTime, dataScope.value, moduleId);

                    let newFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: newData,
                            time: newTime,
                        },
                    };

                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
