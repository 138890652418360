import axios from "./ServerConnection";

import UserInfo from "./UserInfo";
import {
    GroupExtendedPermission,
    GroupExtendedPermissionWithUserInfo,
} from "common/GroupPermissions";

export interface Group {
    id: number;
    name: string;
}

export interface PersonalGroup {
    id: number;
    user_name: string;
    first_name: string;
    last_name: string;
    icon_url: string;
    is_admin: boolean;
    group_id: number;
    emails: string[];
}

export async function getPersonalUserGroupsAndEmails(): Promise<
    PersonalGroup[]
> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            groups_and_emails?: PersonalGroup[];
        }>("/api/get_personal_groups_and_emails", {})
        .then((response) => {
            if (
                response.data.success &&
                response.data.groups_and_emails != null
            ) {
                return Promise.resolve(response.data.groups_and_emails);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getGroupIdByUsernameOrEmail(username_or_email: string): Promise<
    number
> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/get_group_id_by_username_or_email", {'username_or_email': username_or_email})
        .then((response) => {
            if (
                response.data.success &&
                response.data.id != null
            ) {
                return Promise.resolve(response.data.id);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}


export async function getUserGroups(): Promise<Group[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            groups?: Group[];
        }>("/api/get_user_groups", {})
        .then((response) => {
            if (response.data.success && response.data.groups != null) {
                return Promise.resolve(response.data.groups);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getInsightUserGroups(
    insightId: number
): Promise<string[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            groups?: string[];
        }>("/api/get_insight_user_groups", { id: insightId })
        .then((response) => {
            if (response.data.success && response.data.groups != null) {
                return Promise.resolve(response.data.groups);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getStoryUserGroups(storyId: number): Promise<string[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            groups?: string[];
        }>("/api/get_story_user_groups", { id: storyId })
        .then((response) => {
            if (response.data.success && response.data.groups != null) {
                return Promise.resolve(response.data.groups);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getCanvasUserGroups(canvasId: number): Promise<string[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            groups?: string[];
        }>("/api/get_canvas_user_groups", { id: canvasId })
        .then((response) => {
            if (response.data.success && response.data.groups != null) {
                return Promise.resolve(response.data.groups);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getUserPageGroups(
    pageId: number
): Promise<GroupExtendedPermission[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            permissions?: GroupExtendedPermission[];
        }>("/api/get_user_page_groups", { id: pageId })
        .then((response) => {
            if (response.data.success && response.data.permissions != null) {
                return Promise.resolve(response.data.permissions);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getGroupNeighboursUsers(): Promise<UserInfo[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            users?: UserInfo[];
        }>("/api/get_group_neighbors_users", {})
        .then((response) => {
            if (response.data.success && response.data.users != null) {
                return Promise.resolve(response.data.users);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getDataTablePermissionsApi(
    dataScopeId: number | string
): Promise<GroupExtendedPermission[]>;
export async function getDataTablePermissionsApi(
    dataScopeId: number | string,
    withUserInfo: false
): Promise<GroupExtendedPermission[]>;
export async function getDataTablePermissionsApi(
    dataScopeId: number | string,
    withUserInfo: true
): Promise<GroupExtendedPermissionWithUserInfo[]>;
export async function getDataTablePermissionsApi(
    dataScopeId: number | string,
    withUserInfo: boolean = false
): Promise<GroupExtendedPermission[] | GroupExtendedPermissionWithUserInfo[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            permissions?:
                | GroupExtendedPermission[]
                | GroupExtendedPermissionWithUserInfo[];
        }>("/api/get_data_table_permissions", {
            data_table_idx: dataScopeId,
            with_user_info: withUserInfo,
        })
        .then((response) => {
            if (response.data.success && response.data.permissions != null) {
                return Promise.resolve(response.data.permissions);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function setDataTablePermissionsApi(
    dataScopeId: number | string,
    permissions: GroupExtendedPermission[]
): Promise<void> {
    const jsonData = {
        data_table_idx: dataScopeId,
        permissions: permissions.map((permission) => ({
            group_id: permission.group_id,
            permission_type: permission.permission_type,
        })),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/set_data_table_permissions", jsonData)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
