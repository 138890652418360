import React, { Component } from "react";
import { observer } from "mobx-react";

import GlobalContext, { GlobalContextContents } from "GlobalContext";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import styles from "./TemplatesPopup.module.css";
import SlideTemplates from "common/SlideTemplates";
import cx from "classnames";
import { ReactComponent as CrossIcon } from "icons/canvas/themes/cross.svg";
import { ReactComponent as PlusIcon } from "icons/canvas/themes/plus.svg";
import { ReactComponent as MinusIcon } from "icons/canvas/themes/minus.svg";
import modules from "modules/data_exploration_page/modules/module_list.json";

import {
    replaceCanvasByTemplate,
    deleteCollectionTemplateApi,
    editSlideTemplateThumbnail,
} from "common/CanvasUserApi";
import { SlideTemplate } from "common/Canvas";
import { Menu, Item, useContextMenu } from "react-contexify";
import MessagePopup from "common/MessagePopup";
import CanvasTreeStore, { SPECIAL_TEMPLATE_PAGE } from "../CanvasTreeStore";
import {
    Collection,
    CollectionType,
    getCollectionsApi,
    getFilesFromCollection,
    MediaFile,
    PresetContent,
} from "common/MediaCollectionsApi";
import { imageUrlPrefix } from "common/ServerConnection";
import _ from "lodash";
import Presets from "common/Presets";
import { Editor } from "common/react_draft_wysiwyg/src/index";
import SaveTemplatePopup from "../SaveTemplatePopup";
import Dropzone from "react-dropzone";
import { toBase64 } from "common/utilities/retrieveImageFromClipboard";
import ElementsMenuFilter from "components/Filters/Themes/TemplatesMenuFilter";
import Canvases from "common/Canvases";
import ChartsRibbon from "../canvas_elements/NewDashboards/ChartsRibbon";
import Finding, {
    canUpdateFinding,
    isLinePlot,
    isMap,
    isNetworkOverMap,
} from "common/Finding";
import Tables from "common/Tables";
import Dock from "react-dock";
import { headerBarHeight, minLimitEditMenuWidth } from "../Constants";
import { isSafari } from "common/utilities/UIResponsiveManager";
import ItemInitializer from "../ItemInitializer";
import elements from "common/CanvasElements";
import StringUtils from "common/utilities/StringUtils";

// function EditTitleInput(props: {
//     customClassName?: string;
//     value: string;
//     disabled?: boolean;
//     onChange?: (value: string) => void;
// }) {
//     let [value, setValue] = React.useState(props.value);
//     React.useEffect(() => {
//         if (props.value !== value) setValue(props.value);
//         // value should not be in dependencies
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [props.value]);
//     return (
//         <input
//             className={props.customClassName ?? styles.title}
//             disabled={props.disabled}
//             onKeyDown={(evt) => {
//                 evt.stopPropagation();
//                 if (evt.key === "Enter") {
//                     props.onChange?.(value);
//                 }
//             }}
//             value={value}
//             onChange={(evt) => {
//                 let newValue = evt.target.value;
//                 setValue(newValue);
//             }}
//             onBlur={() => {
//                 props.onChange?.(value);
//             }}
//         ></input>
//     );
// }

function LinkSlidePopupWrapper(props: {
    slide: SlideTemplate;
    highlight: boolean;
    globalContext: GlobalContextContents;
    onDelete: () => void;
    onSelect: () => void;
    onUploadThumbnail: (acceptedFile: File) => void;
}) {
    const menuId = `template_${props.slide.id}`;
    const { show, hideAll } = useContextMenu({
        id: menuId,
    });
    const [iconSelection, setIconSelection] = React.useState<boolean>(false);
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    function handleContextMenu(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        show(event);
    }

    return (
        <div
            onContextMenu={handleContextMenu}
            onClick={!iconSelection ? props.onSelect : undefined}
            style={{ position: "relative", margin: "15px 15px 0 0" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div
                className={styles.slide}
                style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    height: 140,
                    backgroundColor: "white",
                    position: "relative",
                }}
            >
                {props.slide.thumbnail != null && (
                    <img
                        alt=""
                        src={props.slide.thumbnail}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                    />
                )}
                <div className={styles.themeTitle}>
                    <span>{props.slide.title}</span>
                </div>
                <div className={styles.themeFog}></div>
            </div>
            {iconSelection && (
                <Dropzone
                    onDrop={(acceptedFiles) => {
                        let acceptedFile = acceptedFiles[0];
                        setIconSelection(false);
                        props.onUploadThumbnail(acceptedFile);
                    }}
                >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                            style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                width: "100%",
                                height: 140,
                            }}
                        >
                            <div
                                {...getRootProps()}
                                className={styles.fileContainerDragArea}
                            >
                                <div
                                    className="flex-simple-column"
                                    style={{
                                        alignItems: "center",
                                    }}
                                >
                                    {!isDragActive && (
                                        <>
                                            <span
                                                style={{
                                                    cursor: "default",
                                                    color: "#a3a3a3",
                                                    fontSize: "20px",
                                                    marginBottom: 10,
                                                }}
                                            >
                                                Drop image
                                            </span>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: 1,
                                                        width: 40,
                                                        backgroundColor:
                                                            "#a3a3a3",
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        margin: "0 15px",
                                                        color: "#a3a3a3",
                                                    }}
                                                >
                                                    or
                                                </span>
                                                <div
                                                    style={{
                                                        height: 1,
                                                        width: 40,
                                                        backgroundColor:
                                                            "#a3a3a3",
                                                    }}
                                                />
                                            </div>
                                            <span
                                                style={{
                                                    margin: "0 15px",
                                                    color: "#a3a3a3",
                                                }}
                                            >
                                                click
                                            </span>
                                        </>
                                    )}
                                    {isDragActive && (
                                        <span
                                            style={{
                                                cursor: "pointer",
                                                marginTop: "6px",
                                                color: "#869AAC",
                                                fontSize: "15px",
                                            }}
                                        >
                                            Drop File Here
                                        </span>
                                    )}
                                </div>
                                <input {...getInputProps()} />
                            </div>
                        </div>
                    )}
                </Dropzone>
            )}
            <Menu id={menuId}>
                {/* {!iconSelection &&
                    "CreateSlideTemplates" in
                        props.globalContext.permissions && (
                        <Item
                            onClick={(evt) => {
                                evt.event.stopPropagation();
                                hideAll();
                                props.onDelete();
                            }}
                        >
                            Delete
                        </Item>
                    )} */}
                {!iconSelection &&
                    "CreateSlideTemplates" in
                        props.globalContext.permissions && (
                        <Item
                            onClick={(evt) => {
                                evt.event.stopPropagation();
                                hideAll();
                                setIconSelection(true);
                            }}
                        >
                            Change icon
                        </Item>
                    )}
                {iconSelection && (
                    <Item
                        onClick={(evt) => {
                            evt.event.stopPropagation();
                            hideAll();
                            setIconSelection(false);
                        }}
                    >
                        Cancel
                    </Item>
                )}
            </Menu>

            {isHovered && (
                <div className={styles.themeHoverElement}>
                    <span>APPLY</span>
                </div>
            )}
        </div>
    );
}

interface Props {
    onFindingSelected: any;
    canvasTreeStore: CanvasTreeStore;
    live: boolean;
    dashboardId: string;
    rootRef: any;
    themeType: ThemeType;
    chartsFilterIdInitializer?: number;
    onEditSlide: (slide: SlideTemplate) => void;
    onRestore: (close: boolean) => void;
    onClearEditing: () => void;
    onUpdateSelectionBounds: () => void;
    onToggleTablePreview: () => void;
    tablePreviewVisible: boolean;
    onAddImage: (
        url: string,
        naturalWidth: number,
        naturalHeight: number
    ) => void;
    onAddPreset: (preset: PresetContent) => void;
    onReplaceCanvasTemplate: (
        canvasId: number,
        pageId: number,
        backgroundLayerId?: number
    ) => void;
    onNewPerformance: (elementName?: string) => void;
    onTurnItemInitializer: (
        itemInitializer: ItemInitializer | null,
        options?: any
    ) => void;
    currentPageId?: number | string;
    currentCanvasId?: number;
    currentModuleId?: number;
}

export enum ThemeType {
    Templates = 1,
    Elements = 2,
    Text = 3,
    Charts = 4,
}

interface State {
    dashboardEditMenuWidth: number;
    // currentSection: ThemeType;
    applySlideId: number | null;
    filterId: number | undefined;
    deleteSlide: SlideTemplate | null;
    deletePreset: number | null;
    deleteTemplateCollection: Collection | null;
    selectedSlide: SlideTemplate | undefined;
    status: {
        status: PopupStatus;
        message: string;
    } | null;
    collections: Collection[];
    files: { [collectionId: number]: MediaFile[] };
    collectionsExpanded: { [collectionId: number]: boolean };
    presetsExpanded: { [collectionId: number]: boolean };
    hoverPreset?: number;
    showNewThemeMenu: boolean;
    showApplyThemePopup: boolean;
    uncollapsedThemesCollection: number | null;
    dataSetMenuIsOpen: boolean;
    updatingDashboard: boolean;
}

@observer
class TemplatesPopup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            dashboardEditMenuWidth: minLimitEditMenuWidth,
            // currentSection: ThemeType.Templates,
            status: null,
            applySlideId: null,
            deleteSlide: null,
            selectedSlide: undefined,
            collections: [],
            files: [],
            collectionsExpanded: {},
            presetsExpanded: {},
            deletePreset: null,
            deleteTemplateCollection: null,
            showNewThemeMenu: false,
            showApplyThemePopup: false,
            dataSetMenuIsOpen: false,
            uncollapsedThemesCollection: null,
            filterId: undefined,
            updatingDashboard: false,
        };
        this.renderContents = this.renderContents.bind(this);
        this.setFilterId = this.setFilterId.bind(this);
        this.connectDataset = this.connectDataset.bind(this);
    }

    private setFilterId(filterId: number | undefined): void {
        this.setState({ filterId: filterId });
    }

    private async fetchItems(): Promise<void> {
        if (this.props.themeType === ThemeType.Templates) {
            await SlideTemplates.update();
            let allSlides = SlideTemplates.allSlides();
            if (allSlides.length > 0) {
                this.setState({ selectedSlide: allSlides[0] });
            }
        } else if (this.props.themeType === ThemeType.Text) {
            await Presets.update();
        } else if (this.props.themeType === ThemeType.Elements) {
            this.updateCollections();
        }
    }

    async componentDidMount() {
        await this.fetchItems();
    }

    async updateCollections() {
        try {
            let collections = await getCollectionsApi(CollectionType.Image);
            let files: State["files"] = {};
            for (let collection of collections) {
                let collectionFiles = await getFilesFromCollection(
                    collection.id,
                    false
                );
                files[collection.id] = collectionFiles;
            }
            this.setState({
                collections: collections,
                files: files,
            });
        } catch (error) {
            this.setState({
                status: {
                    status: PopupStatus.Error,
                    message: String(error),
                },
            });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.currentCanvasId == null) return;
        if (this.props.live) {
            this.props.onRestore(true);
            return;
        }
        if (this.props.currentPageId === SPECIAL_TEMPLATE_PAGE) {
            if (
                SlideTemplates.allSlides().find(
                    (slide) => slide.id === this.props.currentCanvasId
                ) == null
            ) {
                this.props.onRestore(false);
            }
        }
        if (prevProps.themeType !== this.props.themeType) {
            this.fetchItems();
        }
    }

    applyTheme(applySlideId: number) {
        if (
            this.props.currentPageId != null &&
            this.props.currentPageId !== SPECIAL_TEMPLATE_PAGE &&
            this.props.currentCanvasId != null
        ) {
            let canvasForReplace = this.props.currentCanvasId;
            let pageForReplace = this.props.currentPageId as number;

            replaceCanvasByTemplate(applySlideId!, canvasForReplace)
                .then((res) => {
                    this.setState({
                        showApplyThemePopup: false,
                        applySlideId: null,
                    });
                    this.props.onReplaceCanvasTemplate(
                        canvasForReplace,
                        pageForReplace
                    );
                    this.props.canvasTreeStore.setCanvasWasEditedSate(false);
                    Canvases(pageForReplace).update();
                })
                .catch((error) => {
                    this.setState({
                        showApplyThemePopup: false,
                        applySlideId: null,
                        status: {
                            status: PopupStatus.Error,
                            message: String(error),
                        },
                    });
                });
        } else {
            this.setState({
                showApplyThemePopup: false,
                applySlideId: null,
                status: {
                    status: PopupStatus.Error,
                    message: "Slide not found",
                },
            });
        }
    }

    public connectDataset(open: boolean) {
        if (!open) {
            let dashboard = this.props.canvasTreeStore.dashboardsState.get(
                this.props.dashboardId
            )!;

            let finding = { ...dashboard.finding! };
            finding = this.resetFindingData(finding);
            this.props.canvasTreeStore.updateDashboardAction(
                this.props.dashboardId,
                {
                    finding: finding,
                }
            );
            this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                this.props.dashboardId,
                null
            );
        }
        this.setState({
            dataSetMenuIsOpen: open,
        });
    }

    private async updateFinding(finding: Finding) {
        if (!canUpdateFinding(finding)) {
            this.props.canvasTreeStore.updateDashboardAction(
                this.props.dashboardId,
                {
                    finding: finding,
                }
            );
            return;
        }
        let charts = modules.map((module) => module.submodules).flat();
        let chart = charts.find(
            (chart) => chart.name === finding?.config?.journeyName
        );
        if (chart != null) {
            try {
                let api = require(`modules/data_exploration_page/modules/${chart.dir}/ApiV2`);
                this.setState({ updatingDashboard: true });
                let newFinding = await api?.Api?.getData(
                    finding,
                    {},
                    this.props.dashboardId
                );
                if (
                    (isMap(newFinding) || isNetworkOverMap(newFinding)) &&
                    newFinding.additionalMapsFindings != null
                ) {
                    newFinding.additionalMapsFindings = [
                        ...newFinding.additionalMapsFindings,
                    ];
                    for (
                        let i = 0;
                        i < newFinding.additionalMapsFindings.length;
                        ++i
                    ) {
                        let finding = newFinding.additionalMapsFindings[i];
                        let chart = charts.find(
                            (chart) =>
                                chart.name === finding?.config?.journeyName
                        );
                        if (chart != null) {
                            let api = require(`modules/data_exploration_page/modules/${chart.dir}/ApiV2`);
                            newFinding.additionalMapsFindings[
                                i
                            ] = await api?.Api?.getData(
                                finding,
                                {},
                                this.props.dashboardId
                            );
                        }
                    }
                }
                this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                    this.props.dashboardId,
                    null
                );
                this.props.canvasTreeStore.updateDashboardAction(
                    this.props.dashboardId,
                    {
                        finding: newFinding,
                    }
                );
                this.setState({ updatingDashboard: false });
            } catch (error) {
                this.setState({ updatingDashboard: false });

                console.error(error);
                this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                    this.props.dashboardId,
                    `Error: ${String(error)}`
                );
            }
        }
    }

    private resetFindingData(finding: Finding, clearValues = false): Finding {
        let charts = modules.map((module) => module.submodules).flat();
        let chart = charts.find(
            (chart) => chart.name === finding?.config?.journeyName
        );
        if (chart != null) {
            let api = require(`modules/data_exploration_page/modules/${chart.dir}/ApiV2`);
            let newFinding = api?.Api?.getPreviewFinding(chart.name);
            if (clearValues) {
                for (let dataItem of newFinding.content.data) {
                    dataItem.value = [[]];
                }
            }
            finding = {
                ...finding,
                content: {
                    ...finding.content,
                },
                config: {
                    ...finding.config,
                },
            };
            for (let key in finding.content) {
                finding.content[key] = null;
            }
            for (let key in finding.config) {
                finding.config[key] = null;
            }
            finding.content = {
                ...finding.content,
                ...newFinding.content,
            };
            finding.config = {
                ...finding.config,
                ...newFinding.config,
            };
        }
        return finding;
    }

    private buildInnerView(
        globalContext: GlobalContextContents
    ): JSX.Element | null {
        const { canvasWasEdited } = this.props.canvasTreeStore;
        const { themeType } = this.props;
        if (themeType === ThemeType.Elements) {
            return (
                <div className={styles.slidesContainer}>
                    <div style={{ position: "relative" }}>
                        <div
                            className={cx(
                                "unselectable",
                                styles.collectionTitle
                            )}
                        >
                            Basic
                        </div>
                        <div className={styles.collectionContainer}>
                            {[
                                "Square",
                                "Parallelogram",
                                "Circle",
                                "Line",
                                "Arrow",
                            ].map((item) => {
                                return (
                                    <div
                                        className={cx(
                                            "unselectable",
                                            styles.fileContainer
                                        )}
                                        style={{
                                            position: "relative",
                                            marginRight: "10px",
                                            marginBottom: "10px",
                                        }}
                                        key={item}
                                        onClick={(evt) => {
                                            this.props.onNewPerformance(
                                                elements[
                                                    StringUtils.decapitalize(
                                                        item
                                                    )
                                                ]
                                            );
                                            this.props.onTurnItemInitializer(
                                                ItemInitializer.Shape,
                                                {
                                                    subtype: item,
                                                }
                                            );
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            alt=""
                                            src={`/dist/img/canvas/shapes/${item.toLowerCase()}.png`}
                                        ></img>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {this.state.collections
                        .filter(
                            (collection) =>
                                collection.type === CollectionType.Image
                        )
                        .map((collection, index) => (
                            <div key={index} style={{ position: "relative" }}>
                                <div
                                    className="unselectable"
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: 6,
                                        left: 320,
                                    }}
                                    onClick={() => {
                                        this.setState((state) => {
                                            let collectionsExpanded = {
                                                ...state.collectionsExpanded,
                                            };
                                            collectionsExpanded[
                                                collection.id
                                            ] = !collectionsExpanded[
                                                collection.id
                                            ];
                                            return {
                                                collectionsExpanded: collectionsExpanded,
                                            };
                                        });
                                    }}
                                >
                                    {this.state.collectionsExpanded[
                                        collection.id
                                    ] ? (
                                        <MinusIcon />
                                    ) : (
                                        <PlusIcon />
                                    )}
                                </div>
                                <div
                                    className={cx(
                                        "unselectable",
                                        styles.collectionTitle
                                    )}
                                >
                                    {collection.title}
                                </div>
                                <div className={styles.collectionContainer}>
                                    {(this.state.collectionsExpanded[
                                        collection.id
                                    ]
                                        ? this.state.files[collection.id]
                                        : this.state.files[
                                              collection.id
                                          ]?.slice(0, 5)
                                    )?.map((file, fileIndex) => {
                                        return (
                                            <div
                                                className={cx(
                                                    "unselectable",
                                                    styles.fileContainer
                                                )}
                                                style={{
                                                    position: "relative",
                                                    marginRight: "10px",
                                                    marginBottom: "10px",
                                                }}
                                                key={fileIndex}
                                                onClick={(evt) => {
                                                    evt.stopPropagation();
                                                    evt.preventDefault();

                                                    const naturalWidth = (evt.target as HTMLImageElement)
                                                        .naturalWidth;
                                                    const naturalHeight = (evt.target as HTMLImageElement)
                                                        .naturalHeight;
                                                    this.props.onAddImage(
                                                        `${imageUrlPrefix()}/media/${
                                                            file.id
                                                        }/content`,
                                                        naturalWidth,
                                                        naturalHeight
                                                    );
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                    }}
                                                    alt=""
                                                    src={`${imageUrlPrefix()}/media/${
                                                        file.id
                                                    }/content`}
                                                ></img>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ))}
                </div>
            );
        }
        if (themeType === ThemeType.Text) {
            return (
                <div className={styles.slidesContainer}>
                    {Presets.presetCollections.map((collection, index) => (
                        <div key={index} style={{ position: "relative" }}>
                            <div
                                className="unselectable"
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    left: 320,
                                    top: 10,
                                }}
                                onClick={() => {
                                    this.setState((state) => {
                                        let presetsExpanded = {
                                            ...state.presetsExpanded,
                                        };
                                        presetsExpanded[
                                            collection.id
                                        ] = !presetsExpanded[collection.id];
                                        return {
                                            presetsExpanded: presetsExpanded,
                                        };
                                    });
                                }}
                            >
                                {this.state.presetsExpanded[collection.id] ? (
                                    <MinusIcon />
                                ) : (
                                    <PlusIcon />
                                )}
                            </div>
                            <div
                                className={cx(
                                    "unselectable",
                                    styles.collectionTitle
                                )}
                            >
                                {collection.title}
                            </div>
                            <div className={styles.collectionContainer}>
                                {(this.state.presetsExpanded[collection.id]
                                    ? Presets.presets[collection.id]
                                    : Presets.presets[collection.id]?.slice(
                                          0,
                                          2
                                      )
                                )?.map((preset, presetIndex) => {
                                    return (
                                        <div
                                            key={presetIndex}
                                            className={styles.collection}
                                            style={{
                                                position: "relative",
                                                marginRight: "10px",
                                                marginBottom: "10px",
                                                padding: "3px",
                                                display: "flex",
                                                minWidth: "100px",
                                                minHeight: "50px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                border: "1px solid #e9e9e9",
                                                cursor: "pointer",
                                            }}
                                            // onMouseEnter={() => {
                                            //     if (
                                            //         "CreateSlideTemplates" in
                                            //         globalContext.permissions
                                            //     ) {
                                            //         this.setState({ hoverPreset: preset.id });
                                            //     }
                                            // }}
                                            // onMouseLeave={() => {
                                            //     if (
                                            //         "CreateSlideTemplates" in
                                            //         globalContext.permissions
                                            //     ) {
                                            //         this.setState({ hoverPreset: undefined });
                                            //     }
                                            // }}
                                            onClick={(evt) => {
                                                evt.preventDefault();
                                                evt.stopPropagation();
                                                this.props.onAddPreset(
                                                    preset.preset
                                                );
                                            }}
                                        >
                                            {/* {this.state.hoverPreset === preset.id && (
                                                <div
                                                    className="unselectable"
                                                    style={{
                                                        position: "absolute",
                                                        right: "10px",
                                                        top: "10px",
                                                        zIndex: 100,
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        evt.stopPropagation();
                                                        this.setState({
                                                            deletePreset: preset.id,
                                                        });
                                                    }}
                                                >
                                                    <CrossIcon />
                                                </div>
                                            )} */}
                                            <Editor
                                                editorStyle={{
                                                    cursor: "pointer",
                                                }}
                                                toolbarHidden
                                                readOnly
                                                contentState={
                                                    preset.preset.rawMetric
                                                }
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        if (themeType === ThemeType.Templates) {
            const { filterId } = this.state;
            return (
                <>
                    {SlideTemplates.slideCollection.map((collection, index) => {
                        let uncollapsed =
                            this.state.uncollapsedThemesCollection ===
                            collection.id;
                        const collectionHeight =
                            SlideTemplates.slides[collection.id]?.length > 4 &&
                            !uncollapsed
                                ? 350
                                : "auto";
                        if (
                            filterId !== undefined &&
                            filterId !== collection.id
                        )
                            return <></>;
                        return (
                            <React.Fragment key={index}>
                                <div
                                    className="my-row"
                                    style={{ alignItems: "flex-end" }}
                                >
                                    <span
                                        className={styles.themeCollectionTitle}
                                    >
                                        {collection.title}
                                    </span>
                                    <div style={{ flexGrow: 1 }} />
                                    {/* {"CreateSlideTemplates" in
                                        globalContext.permissions && (
                                        <div
                                            className="unselectable"
                                            style={{
                                                marginRight: "10px",
                                                cursor: "pointer",
                                            }}
                                            onClick={(evt) => {
                                                evt.preventDefault();
                                                evt.stopPropagation();
                                                this.setState({
                                                    deleteTemplateCollection:
                                                        collection,
                                                });
                                            }}
                                        >
                                            <CrossIcon />
                                        </div>
                                    )} */}
                                    <button
                                        className={cx(
                                            styles.themeCollectionUncollapser,
                                            "unselectable btn-small-like-select"
                                        )}
                                        style={{
                                            marginRight: "10px",
                                            cursor: "pointer",
                                        }}
                                        onClick={(evt) => {
                                            this.setState({
                                                uncollapsedThemesCollection: uncollapsed
                                                    ? -1
                                                    : collection.id,
                                            });
                                        }}
                                    >
                                        {uncollapsed ? (
                                            <MinusIcon />
                                        ) : (
                                            <PlusIcon />
                                        )}
                                    </button>
                                </div>
                                <div
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr 1fr",
                                        margin: "0 23px 0 28px",
                                        height: collectionHeight,
                                        overflow: "hidden",
                                    }}
                                >
                                    {SlideTemplates.slides[collection.id]?.map(
                                        (slide, index) => (
                                            <React.Fragment key={index}>
                                                <LinkSlidePopupWrapper
                                                    highlight={
                                                        slide.id ===
                                                        this.state.selectedSlide
                                                            ?.id
                                                    }
                                                    onSelect={() => {
                                                        if (
                                                            this.props
                                                                .currentPageId ===
                                                            SPECIAL_TEMPLATE_PAGE
                                                        ) {
                                                            this.props.onRestore(
                                                                false
                                                            );
                                                        }
                                                        this.setState({
                                                            selectedSlide: _.cloneDeep(
                                                                slide
                                                            ),
                                                        });

                                                        setTimeout(() => {
                                                            this.setState({
                                                                applySlideId: this
                                                                    .state
                                                                    .selectedSlide!
                                                                    .id,
                                                            });

                                                            if (
                                                                this.props
                                                                    .currentPageId !=
                                                                    null &&
                                                                this.props
                                                                    .currentPageId !==
                                                                    SPECIAL_TEMPLATE_PAGE &&
                                                                this.props
                                                                    .currentCanvasId !=
                                                                    null &&
                                                                this.state
                                                                    .selectedSlide !=
                                                                    null
                                                            ) {
                                                                if (
                                                                    canvasWasEdited
                                                                ) {
                                                                    this.setState(
                                                                        {
                                                                            showApplyThemePopup: true,
                                                                        }
                                                                    );
                                                                } else {
                                                                    this.applyTheme(
                                                                        this
                                                                            .state
                                                                            .selectedSlide!
                                                                            .id
                                                                    );
                                                                }
                                                            }
                                                        }, 100);
                                                    }}
                                                    slide={slide}
                                                    globalContext={
                                                        globalContext
                                                    }
                                                    onDelete={() => {
                                                        this.setState({
                                                            deleteSlide: slide,
                                                        });
                                                    }}
                                                    onUploadThumbnail={async (
                                                        acceptedFile
                                                    ) => {
                                                        if (
                                                            !acceptedFile.type.startsWith(
                                                                "image/"
                                                            )
                                                        ) {
                                                            this.setState({
                                                                status: {
                                                                    status:
                                                                        PopupStatus.Error,
                                                                    message: `${acceptedFile.name} is not an image`,
                                                                },
                                                            });
                                                            return;
                                                        }
                                                        let url: string = "";
                                                        try {
                                                            url = await toBase64(
                                                                acceptedFile
                                                            );
                                                        } catch (error) {
                                                            this.setState({
                                                                status: {
                                                                    status:
                                                                        PopupStatus.Error,
                                                                    message: String(
                                                                        error
                                                                    ),
                                                                },
                                                            });

                                                            return;
                                                        }
                                                        try {
                                                            await editSlideTemplateThumbnail(
                                                                slide.id,
                                                                url
                                                            );
                                                            SlideTemplates.updateThumbnail(
                                                                slide.id,
                                                                slide.collection_id,
                                                                url
                                                            );
                                                        } catch (error) {
                                                            this.setState({
                                                                status: {
                                                                    status:
                                                                        PopupStatus.Error,
                                                                    message: String(
                                                                        error
                                                                    ),
                                                                },
                                                            });
                                                        }
                                                    }}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </>
            );
        }

        if (themeType === ThemeType.Charts) {
            let dashboard = this.props.canvasTreeStore.dashboardsState.get(
                this.props.dashboardId
            )!;

            return (
                <div className={styles.dashboardsContainer}>
                    <ChartsRibbon
                        canvasTreeStore={this.props.canvasTreeStore}
                        updatingDashboard={this.state.updatingDashboard}
                        columnDragActive={false}
                        dashboardId={this.props.dashboardId}
                        finding={dashboard?.finding}
                        filterIdInitializer={
                            this.props.chartsFilterIdInitializer
                        }
                        onNewFinding={(
                            finding,
                            updateData?: boolean,
                            preview?: boolean
                        ) => {
                            if (preview) {
                                this.props.onFindingSelected(finding);
                            } else {
                                this.props.canvasTreeStore.updateDashboardAction(
                                    this.props.dashboardId,
                                    {
                                        finding: finding,
                                    }
                                );
                                if (updateData) {
                                    this.updateFinding(finding);
                                }
                            }
                        }}
                        onConnectDataset={this.connectDataset}
                        onChangeDataScope={(dataScope, currentFinding) => {
                            // DO NOT change additionalMapsFindings here
                            // since map sections do not use
                            // onChangeDataScope
                            let newFinding = {
                                ...currentFinding!,
                            };

                            newFinding = this.resetFindingData(
                                newFinding,
                                isLinePlot(newFinding)
                            );

                            newFinding.config.dataScope = dataScope;
                            newFinding.config.selectedTable = Tables(
                                dataScope.value,
                                this.props.dashboardId
                            ).tableToOption();

                            this.props.canvasTreeStore.updateDashboardAction(
                                this.props.dashboardId,
                                {
                                    finding: newFinding,
                                }
                            );
                            this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                                this.props.dashboardId,
                                null
                            );
                        }}
                        onChangeConditions={(conditions) => {
                            let newFinding = {
                                ...dashboard.finding!,
                            };
                            newFinding.config.conditions = conditions;

                            this.updateFinding(newFinding);
                        }}
                        onChangeSelectedTable={(selectedTable) => {
                            let newFinding = {
                                ...dashboard.finding!,
                            };
                            newFinding.config.selectedTable = selectedTable;
                            newFinding.config.conditions = null;

                            this.updateFinding(newFinding);
                        }}
                        connectDataset={this.state.dataSetMenuIsOpen}
                        onClearEditing={
                            // () => {}
                            this.props.onClearEditing
                        }
                        onUpdateSelectionBounds={
                            this.props.onUpdateSelectionBounds
                        }
                        onToogleTablePreview={this.props.onToggleTablePreview}
                        toogleTablePreview={this.props.tablePreviewVisible}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        }

        return null;
    }

    public renderContents(globalContext: GlobalContextContents): JSX.Element {
        const { themeType } = this.props;
        const isTemplatesType = themeType === ThemeType.Templates;
        const isDashboardType = themeType === ThemeType.Charts;
        return (
            <>
                <Dock
                    dockStyle={{
                        backgroundColor: "#FFFFFF",
                        top: `${headerBarHeight}px`,
                        height: `calc(100% - ${headerBarHeight}px)`,
                    }}
                    position="left"
                    isVisible={true}
                    fluid={false}
                    size={this.state.dashboardEditMenuWidth}
                    dimMode="none"
                    duration={isSafari() ? 0 : 200}
                    defaultSize={minLimitEditMenuWidth}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                dashboardEditMenuWidth: size,
                            });
                        }
                    }}
                >
                    <div
                        className={styles.header}
                        style={{
                            height: isTemplatesType ? 95 : 55,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <span className={styles.title}>
                                {isTemplatesType ? "Templates" : ""}
                                {isDashboardType ? "Charts" : ""}
                                {themeType === ThemeType.Elements
                                    ? "Shapes"
                                    : ""}
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    margin: "0 17px 0 0",
                                }}
                                onClick={() => {
                                    this.props.onRestore(true);
                                }}
                            >
                                <CrossIcon />
                            </div>
                        </div>
                        {isTemplatesType && (
                            <ElementsMenuFilter
                                filterIndex={this.state.filterId}
                                onChangeFilterIndex={this.setFilterId}
                                modules={SlideTemplates.slideCollection}
                            />
                        )}
                    </div>

                    {this.buildInnerView(globalContext)}
                    {/* Edit/Apply theme panel */}
                    {/* {this.state.currentSection === ThemeType.Templates && (
                        <div
                            className="flex-simple-column"
                            style={{
                                position: "absolute",
                                bottom: 0,
                                zIndex: 1000,
                                left: 0,
                                borderTop: "1px solid #E8E8E8",
                                width: "100%",
                                height: 76,
                                background: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                className="my-row"
                                style={{ justifyContent: "center" }}
                            >
                                {this.props.currentCanvasId != null &&
                                    this.props.currentPageId ===
                                        SPECIAL_TEMPLATE_PAGE && (
                                        <>
                                            <Button
                                                type="button"
                                                className={
                                                    styles.newTemplateButton
                                                }
                                                onClick={async (
                                                    evt: React.MouseEvent<HTMLButtonElement>
                                                ) => {
                                                    this.props.onRestore(false);
                                                    if (
                                                        this.state.selectedSlide
                                                            ?.id ===
                                                        this.props
                                                            .currentCanvasId
                                                    ) {
                                                        editNotSavedCanvasApi(
                                                            this.state
                                                                .selectedSlide!
                                                                .canvas!,
                                                            this.state
                                                                .selectedSlide!
                                                                .id,
                                                            this.state
                                                                .selectedSlide!
                                                                .backgrounds,
                                                            BackgroundMode.Replace,
                                                            true,
                                                            undefined,
                                                            stringSessionId(),
                                                            true
                                                        )
                                                            .then(() => {})
                                                            .catch((error) => {
                                                                console.log(
                                                                    String(
                                                                        error
                                                                    )
                                                                );
                                                            });
                                                    }
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <div style={{ width: "10px" }} />
                                            <Button
                                                type="button"
                                                className={
                                                    styles.newTemplateButton
                                                }
                                                onClick={async (
                                                    evt: React.MouseEvent<HTMLButtonElement>
                                                ) => {
                                                    await SlideTemplates.update();
                                                    this.setState((state) => {
                                                        return {
                                                            selectedSlide:
                                                                SlideTemplates.allSlides().find(
                                                                    (slide) =>
                                                                        slide.id ===
                                                                        state
                                                                            .selectedSlide
                                                                            ?.id
                                                                ),
                                                        };
                                                    });

                                                    this.props.onRestore(false);
                                                }}
                                            >
                                                Save & close
                                            </Button>
                                        </>
                                    )}
                                {"CreateSlideTemplates" in
                                    globalContext.permissions &&
                                    this.props.currentPageId !==
                                        SPECIAL_TEMPLATE_PAGE && (
                                        <Button
                                            type="button"
                                            className={styles.newTemplateButton}
                                            onClick={async (
                                                evt: React.MouseEvent<HTMLButtonElement>
                                            ) => {
                                                this.setState({
                                                    showNewThemeMenu: true,
                                                });
                                            }}
                                        >
                                            Create Theme
                                        </Button>
                                    )}
                                {"CreateSlideTemplates" in
                                    globalContext.permissions &&
                                    this.props.currentPageId !==
                                        SPECIAL_TEMPLATE_PAGE &&
                                    this.state.selectedSlide != null && (
                                        <>
                                            <div style={{ width: "10px" }} />
                                            <Button
                                                type="button"
                                                className={
                                                    styles.newTemplateButton
                                                }
                                                onClick={async (
                                                    evt: React.MouseEvent<HTMLButtonElement>
                                                ) => {
                                                    this.setState({
                                                        status: {
                                                            status: PopupStatus.Success,
                                                            message: "Loading",
                                                        },
                                                    });
                                                    try {
                                                        let slide =
                                                            await getSlideTemplateApi(
                                                                this.state
                                                                    .selectedSlide!
                                                                    .id!
                                                            );
                                                        this.setState(
                                                            (state) => {
                                                                if (
                                                                    state
                                                                        .selectedSlide
                                                                        ?.id ===
                                                                    slide.id
                                                                ) {
                                                                    return {
                                                                        selectedSlide:
                                                                            slide,
                                                                        status: null,
                                                                    };
                                                                }
                                                                return {
                                                                    selectedSlide:
                                                                        state.selectedSlide,
                                                                    status: null,
                                                                };
                                                            },
                                                            () => {
                                                                this.props.onEditSlide(
                                                                    this.state
                                                                        .selectedSlide!
                                                                );
                                                            }
                                                        );
                                                    } catch (error) {
                                                        this.setState({
                                                            status: {
                                                                status: PopupStatus.Error,
                                                                message:
                                                                    String(
                                                                        error
                                                                    ),
                                                            },
                                                        });
                                                        return;
                                                    }
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        </>
                                    )}
                            </div>
                        </div>
                    )} */}
                </Dock>
                {/* {this.state.deleteSlide && (
                    <MessagePopup
                        danger
                        title={"Remove theme"}
                        acceptButtonTitle={"Remove"}
                        message={"Do you want to remove theme?"}
                        onAccept={() => {
                            deleteCanvasTemplate(this.state.deleteSlide!.id)
                                .then(() => {
                                    SlideTemplates.deleteSlide(
                                        this.state.deleteSlide!.id,
                                        this.state.deleteSlide!.collection_id
                                    );
                                    this.setState((state) => {
                                        return {
                                            selectedSlide:
                                                state.deleteSlide?.id ===
                                                state.selectedSlide?.id
                                                    ? undefined
                                                    : state.selectedSlide,
                                            deleteSlide: null,
                                            status: {
                                                status: PopupStatus.Success,
                                                message: "Theme was deleted",
                                            },
                                        };
                                    });
                                })
                                .catch((error) => {
                                    this.setState({
                                        deleteSlide: null,
                                        status: {
                                            status: PopupStatus.Error,
                                            message: String(error),
                                        },
                                    });
                                });
                        }}
                        onReject={() => {
                            this.setState({ deleteSlide: null });
                        }}
                    />
                )} */}
                {/* {this.state.deletePreset && (
                    <MessagePopup
                        danger
                        title={"Remove preset"}
                        acceptButtonTitle={"Remove"}
                        message={"Do you want to remove preset?"}
                        onAccept={() => {
                            deletePresetApi(this.state.deletePreset!)
                                .then(() => {
                                    Presets.deletePreset(
                                        this.state.deletePreset!,
                                    );
                                    this.setState({
                                        deletePreset: null,
                                        status: {
                                            status: PopupStatus.Success,
                                            message: "Preset was deleted",
                                        },
                                    });
                                })
                                .catch((error) => {
                                    this.setState({
                                        deletePreset: null,
                                        status: {
                                            status: PopupStatus.Error,
                                            message: String(error),
                                        },
                                    });
                                });
                        }}
                        onReject={() => {
                            this.setState({ deletePreset: null });
                        }}
                    />
                )} */}
                {this.state.showApplyThemePopup && (
                    <MessagePopup
                        danger
                        title={"Apply theme"}
                        acceptButtonTitle={"Apply"}
                        message={
                            "Apply theme? Your current slide will be replaced"
                        }
                        onAccept={() => {
                            this.applyTheme(this.state.applySlideId!);
                        }}
                        onReject={() => {
                            this.setState({
                                applySlideId: null,
                                showApplyThemePopup: false,
                            });
                        }}
                    />
                )}
                {this.state.deleteTemplateCollection != null && (
                    <MessagePopup
                        danger
                        title={"Remove collection"}
                        acceptButtonTitle={"Remove"}
                        message={
                            "Remove theme? Collection and all themes will be deleted"
                        }
                        onAccept={() => {
                            deleteCollectionTemplateApi(
                                this.state.deleteTemplateCollection!.id
                            )
                                .then(() => {
                                    SlideTemplates.deleteCollection(
                                        this.state.deleteTemplateCollection!.id
                                    );
                                    this.setState((state) => {
                                        return {
                                            selectedSlide: SlideTemplates.allSlides().find(
                                                (slide) =>
                                                    slide.id ===
                                                    state.selectedSlide?.id
                                            ),
                                            deleteTemplateCollection: null,
                                            status: {
                                                status: PopupStatus.Success,
                                                message:
                                                    "Collection was deleted",
                                            },
                                        };
                                    });
                                })
                                .catch((error) => {
                                    this.setState({
                                        deleteTemplateCollection: null,
                                        status: {
                                            status: PopupStatus.Error,
                                            message: String(error),
                                        },
                                    });
                                });
                        }}
                        onReject={() => {
                            this.setState({ deleteTemplateCollection: null });
                        }}
                    />
                )}
                {this.state.status && (
                    <StatusPopup
                        message={this.state.status.message}
                        status={this.state.status.status}
                        onClose={() => {
                            this.setState({
                                status: null,
                            });
                        }}
                    />
                )}
                {this.state.showNewThemeMenu && (
                    <SaveTemplatePopup
                        onClose={async (newSlide) => {
                            this.setState({
                                selectedSlide: newSlide as
                                    | SlideTemplate
                                    | undefined,
                                showNewThemeMenu: false,
                                status: {
                                    status: PopupStatus.Success,
                                    message: "Template was created",
                                },
                            });
                        }}
                    />
                )}
            </>
        );
    }

    render() {
        return (
            <GlobalContext.ObserverConsumer>
                {this.renderContents}
            </GlobalContext.ObserverConsumer>
        );
    }
}

export default TemplatesPopup;
