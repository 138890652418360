import React from "react";
import Select, { createFilter, CSSObjectWithLabel, Theme } from "react-select";
import Switch from "react-switch";
import {
    SpreadSheetColumnOption,
    DateFormat,
    spreadSheetColumnOptionsForInput,
    CanvasInput,
    NumberFormat,
    ColumnFormat,
    SpreadSheetColumnType,
    isDateFormat,
    formatNumber,
    isNumberFormat,
    numberFormatOptions,
    NumberFormatType,
    NumberFormatOption,
    DefaultValueType,
} from "common/Canvas";
import { mainStyle } from "common/MainStyle";
import customSelectStyles from "common/SelectStyles";
import { strptime, strftime } from "common/utilities/TimeFormatUtils";
import DateTimeFormatSelect, {
    OptionType as DateTimeOptionType,
} from "common/DateTimeFormatSelect";
import StringUtils from "common/utilities/StringUtils";

interface Props {
    node: CanvasInput;
    onChange: (node: CanvasInput) => void;
}

function convertMetricAndValue(node: CanvasInput, columnFormat: DateFormat) {
    let value = node.value;
    let metric = node.metric;
    let time = strptime(columnFormat.format, metric);
    if (time != null) {
        value = time.getTime() / 1000;
    }
    if (time == null && node.value != null && !isNaN(node.value as number)) {
        metric =
            strftime(
                columnFormat.format,
                new Date((node.value as number) * 1000)
            ) ?? metric;
    }
    return {
        metric: metric,
        value: value,
    };
}

export default function InputFormatSelector(props: Props) {
    let [formatOptions, setFormatOptions] = React.useState(
        DateTimeFormatSelect.defaultOptions()
    );
    const spanStyle = {
        fontFamily: "Arial",
        fontSize: "17px",
        color: mainStyle.getPropertyValue("--popup-primary-text-color"),
        width: "80px",
    };
    const selectStyle = {
        ...customSelectStyles,
        container: (base: CSSObjectWithLabel) => ({
            ...base,
            width: "16em",
            marginLeft: "5px",
        }),
        menuPortal: (base: CSSObjectWithLabel) => ({
            ...base,
            zIndex: 100000000,
        }),
    };

    const themeFunction = (theme: Theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            text: "white",
            primary25: "var(--selectors-background-hover-color)",
        },
    });

    return (
        <>
            <div
                className="my-row"
                style={{ alignItems: "center", marginTop: "10px" }}
            >
                <span style={spanStyle}>{"Format"}</span>

                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Format type"}
                    styles={selectStyle}
                    options={spreadSheetColumnOptionsForInput}
                    onChange={(newValue) => {
                        let type = (newValue as SpreadSheetColumnOption).value;
                        let newDefaultValueType =
                            type !== SpreadSheetColumnType.Date &&
                            props.node.defaultValueType ===
                                DefaultValueType.CurrentDateTime
                                ? DefaultValueType.LastTextAdded
                                : props.node.defaultValueType;
                        switch (type) {
                            case SpreadSheetColumnType.Text:
                                {
                                    let columnFormat: ColumnFormat = {
                                        type: type,
                                    };
                                    props.onChange({
                                        ...props.node,
                                        format: columnFormat,
                                        defaultValueType: newDefaultValueType,
                                    });
                                }
                                break;
                            case SpreadSheetColumnType.Number:
                                {
                                    if (isNumberFormat(props.node.format))
                                        break;
                                    let columnFormat: NumberFormat = {
                                        type: type,
                                        decimalPoints: 2,
                                        useCommaSeparator: true,
                                        useAbbreviation: false,
                                        numberType: NumberFormatType.Number,
                                    };
                                    let value = props.node.value;
                                    let metric = props.node.metric;
                                    if (
                                        value != null &&
                                        !isNaN(value as number)
                                    )
                                        metric = formatNumber(
                                            value as number,
                                            columnFormat
                                        );

                                    props.onChange({
                                        ...props.node,
                                        metric: metric,
                                        format: columnFormat,
                                        defaultValueType: newDefaultValueType,
                                    });
                                }
                                break;

                            case SpreadSheetColumnType.Date:
                                {
                                    if (isDateFormat(props.node.format)) break;
                                    let columnFormat: DateFormat = {
                                        type: type,
                                        format: formatOptions[0].value,
                                    };
                                    props.onChange({
                                        ...props.node,
                                        format: columnFormat,
                                        ...convertMetricAndValue(
                                            props.node,
                                            columnFormat
                                        ),
                                        defaultValueType: newDefaultValueType,
                                    });
                                }
                                break;
                            default:
                                // code...
                                break;
                        }
                    }}
                    value={
                        props.node.format != null
                            ? spreadSheetColumnOptionsForInput.find(
                                  (item) =>
                                      item.value === props.node.format!.type
                              )
                            : null
                    }
                    theme={themeFunction}
                />
            </div>
            {isNumberFormat(props.node.format) && (
                <>
                    <div
                        className="my-row"
                        style={{ alignItems: "center", marginTop: "10px" }}
                    >
                        <span style={spanStyle}>{"Number format"}</span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Number format"}
                            styles={selectStyle}
                            options={numberFormatOptions}
                            onChange={(newValue) => {
                                let type = (newValue as NumberFormatOption)
                                    .value;
                                props.onChange({
                                    ...props.node,
                                    format: {
                                        ...(props.node.format as NumberFormat),
                                        numberType: type,
                                    } as NumberFormat,
                                });
                            }}
                            value={numberFormatOptions.find(
                                (option) =>
                                    option.value ===
                                    (props.node.format as NumberFormat)
                                        .numberType
                            )}
                            theme={themeFunction}
                        />
                    </div>
                    <div
                        className="my-row"
                        style={{ alignItems: "center", marginTop: "10px" }}
                    >
                        <span style={spanStyle}>{"Decimals"}</span>
                        <input
                            className="like-select"
                            style={{
                                marginLeft: 6,
                                fontWeight: 400,
                                width: "70px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                fontFamily: "monospace",
                                textAlign: "right",
                            }}
                            placeholder="Decimal places"
                            onChange={(e) => {
                                let value = e.target.value;
                                let decimalPoints: number = 0;
                                if (StringUtils.isNumber(value)) {
                                    decimalPoints = Math.min(
                                        Math.max(0, Math.trunc(Number(value))),
                                        20
                                    );
                                }
                                props.onChange({
                                    ...props.node,
                                    format: {
                                        ...(props.node.format as NumberFormat),
                                        decimalPoints: decimalPoints,
                                    } as NumberFormat,
                                });
                            }}
                            defaultValue={String(
                                (props.node.format as NumberFormat)
                                    .decimalPoints
                            )}
                        />
                    </div>
                    <div
                        className="my-row"
                        style={{ alignItems: "center", marginTop: "10px" }}
                    >
                        <span style={spanStyle}>{"Use Abbreviation"}</span>
                        <div style={{ marginLeft: 6 }}>
                            <Switch
                                onChange={(checked) => {
                                    props.onChange({
                                        ...props.node,
                                        format: {
                                            ...(props.node
                                                .format as NumberFormat),
                                            useAbbreviation: checked,
                                        } as NumberFormat,
                                    });
                                }}
                                checked={
                                    (props.node.format as NumberFormat)
                                        .useAbbreviation ?? false
                                }
                                width={26}
                                height={13}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                    </div>
                    <div
                        className="my-row"
                        style={{ alignItems: "center", marginTop: "10px" }}
                    >
                        <span style={spanStyle}>{"Use 1000 separator(,)"}</span>
                        <div style={{ marginLeft: 6 }}>
                            <Switch
                                onChange={(checked) => {
                                    props.onChange({
                                        ...props.node,
                                        format: {
                                            ...(props.node
                                                .format as NumberFormat),
                                            useCommaSeparator: checked,
                                        } as NumberFormat,
                                    });
                                }}
                                checked={
                                    (props.node.format as NumberFormat)
                                        .useCommaSeparator ?? true
                                }
                                width={26}
                                height={13}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                    </div>
                </>
            )}
            {isDateFormat(props.node.format) && (
                <div
                    className="my-row"
                    style={{
                        alignItems: "center",
                        marginTop: "10px",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Arial",
                            fontSize: "17px",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            width: "80px",
                        }}
                    >
                        {"Date format"}
                    </span>
                    <DateTimeFormatSelect
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        onCreateOption={(option) => {
                            setFormatOptions([option, ...formatOptions]);
                            let newFormat = {
                                ...((props.node as CanvasInput)
                                    .format as DateFormat),
                                format: option.value,
                            } as DateFormat;
                            props.onChange({
                                ...props.node,
                                format: newFormat,
                                ...convertMetricAndValue(props.node, newFormat),
                            });
                        }}
                        styles={selectStyle}
                        options={formatOptions}
                        onChange={(newValue) => {
                            let newFormat = {
                                ...((props.node as CanvasInput)
                                    .format as DateFormat),
                                format: (newValue as DateTimeOptionType).value,
                            } as DateFormat;
                            props.onChange({
                                ...props.node,
                                format: newFormat,
                                ...convertMetricAndValue(props.node, newFormat),
                            });
                        }}
                        value={props.node.format.format ?? ""}
                        theme={themeFunction}
                    />
                </div>
            )}
        </>
    );
}
