import React from "react";

import { UserIcon } from "common/UserIcon";
import currentUser from "common/CurrentUser";

interface Props {
    message: string;
}

const CustomUserMessage = (props: Props): JSX.Element => {
    return (
        <div
            style={{
                minHeight: 83,
                paddingLeft: 29,
                paddingRight: 29,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    alignSelf: "flex-start",
                    marginTop: 26,
                }}
            >
                <UserIcon
                    width={32}
                    height={32}
                    fontSize={14}
                    user={currentUser.info ?? undefined}
                    iconUrl={currentUser.info?.icon_url}
                    customStyle={{
                        borderRadius: 3,
                    }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 21,
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <span
                    style={{
                        color: "#333333",
                    }}
                >
                    {props.message}
                </span>
            </div>
        </div>
    );
};

export default CustomUserMessage;
