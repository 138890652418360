export interface Recipe {
    id: number;
    position: number;
    title: string;
    tab_id: number;
    name: string;
    // TODO replace object with a more specifi type
    config: any;
}

// Recipe as it is sent by the server
export interface RecipeResponse {
    id: number;
    position: number;
    title: string;
    tab_id: number;
    name: string;
    config: string; // config is encoded as string
}

export interface RecipeTab {
    id: number;
    title: string;
}

export function toRecipe(recipeResponse: RecipeResponse): Recipe {
    return {
        id: recipeResponse.id,
        position: recipeResponse.position,
        title: recipeResponse.title,
        tab_id: recipeResponse.tab_id,
        name: recipeResponse.name,
        config: JSON.parse(recipeResponse.config)
    };
}
