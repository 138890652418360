import React, { Component } from "react";
import CustomizedAxisTick from "./CustomizedAxisTick";
import { LegendElement } from "./LegendElement";
import { formatValue } from "common/utilities/FormatValue";
import ReferenceLabel from "./ReferenceLabel";
import { TooltipStyles } from "./TooltipStyles";

import {
    LineChart,
    Line,
    ReferenceLine,
    ReferenceDot,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";

const colors = [
    ["#09e398", "#FFAB4F"],
    ["#057e55", "#d6760c"],
];

// function renderLegendText(value, entry) {
// 	return (
// 		<span style={{ fontFamily: "Arial", fontSize: "10px", color: "#FFF" }}>
// 			{value}
// 		</span>
// 	);
// }

function targetKey(showMarginals) {
    return showMarginals ? "y_marginal" : "y";
}

function OutcomesCurveInner(props) {
    var axisFontSize = "10px";
    const {
        mainFixed,
        maxY,
        minY,
        hideYaxisLabel,
        dependentVariable,
        independentVariable,
        interactionVariable,
        series,
        means,
        hideMeanDot,
        showMarginals,
        // showTooltip,
        // hideTooltip
    } = props;
    let domain = [
        (dataMin) => dataMin - Math.abs(dataMin) * 0.001,
        (dataMax) => dataMax + Math.abs(dataMax) * 0.001,
    ];
    if (maxY && minY) {
        domain = [minY - (maxY - minY) * 0.001, maxY + (maxY - minY) * 0.001];
    }
    let showMean =
        means &&
        means.length > 0 &&
        series.length > 0 &&
        series[0].data.length > 0;
    let showEachMean = means.map(
        (mean) =>
            showMean &&
            mean &&
            mean.x >= series[0].data[0].x &&
            mean.x <= series[0].data[series[0].data.length - 1].x
    );
    return (
        <div
            className="my-row center-container"
            style={{ width: "100%", height: "100%" }}
        >
            {!hideYaxisLabel ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <span
                        style={{
                            textAlign: "right",
                            color: mainStyle.getPropertyValue(
                                "--graphs-axes-text-color"
                            ),
                            fontFamily: "Arial",
                            fontSize:
                                mainStyle.getPropertyValue(
                                    "--graphs-axes-size"
                                ),
                        }}
                    >
                        {dependentVariable}
                    </span>
                </div>
            ) : null}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                }}
            >
                <ResponsiveContainer>
                    <LineChart
                        margin={{
                            top: 5,
                            bottom: 5,
                        }}
                    >
                        {sections._Global.watermark != null && (
                            <text
                                x="50%"
                                y="30%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--content-secondary-text-color"
                                )}
                                fontSize={"4rem"}
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {sections._Global.watermark}
                            </text>
                        )}
                        <CartesianGrid
                            stroke={mainStyle.getPropertyValue(
                                "--graphs-stroke-color"
                            )}
                            strokeWidth={1}
                        />
                        <XAxis
                            axisLine={false}
                            tick={
                                <CustomizedAxisTick
                                    formatValues={true}
                                    truncValues={false}
                                    fontSize={axisFontSize}
                                    dx={10}
                                    dy={16}
                                />
                            }
                            dataKey="x"
                            type={mainFixed ? "category" : "number"}
                            domain={
                                !mainFixed ? ["dataMin", "dataMax"] : undefined
                            }
                            allowDuplicatedCategory={false}
                            stroke={mainStyle.getPropertyValue(
                                "--graphs-stroke-color"
                            )}
                        />
                        <YAxis
                            type="number"
                            dataKey="y"
                            domain={domain}
                            axisLine={false}
                            tickLine={false}
                            tick={
                                <CustomizedAxisTick
                                    formatValues={true}
                                    truncValues={false}
                                    fontSize={axisFontSize}
                                    dx={0}
                                    dy={0}
                                />
                            }
                        />
                        {series.map((item) => (
                            <Line
                                data={item.data}
                                dataKey="y"
                                name={item.name}
                                key={item.name}
                                isAnimationActive={false}
                                type="monotone"
                                stroke={item.color}
                                strokeWidth={3}
                                activeDot={false}
                                dot={false}
                            />
                        ))}
                        {showMean &&
                            means.map((mean, index) => {
                                return (
                                    showEachMean[index] && (
                                        <ReferenceLine
                                            key={"meanBackground".concat(index)}
                                            x={mean.x}
                                            isAnimationActive={false}
                                            type="monotone"
                                            stroke={"#212A3D88"}
                                            strokeWidth={60}
                                            activeDot={false}
                                            dot={false}
                                        />
                                    )
                                );
                            })}
                        {showMean &&
                            means.map((mean, index) => {
                                return (
                                    showEachMean[index] && (
                                        <ReferenceLine
                                            key={"meanLine".concat(index)}
                                            x={mean.x}
                                            isAnimationActive={false}
                                            type="monotone"
                                            stroke={"#39F"}
                                            strokeWidth={2}
                                            activeDot={false}
                                            dot={false}
                                        />
                                    )
                                );
                            })}
                        {showMean &&
                            means.map((mean, index) => {
                                if (!showEachMean[index]) return null;
                                let where = series[index].where
                                    ? series[index].where.concat(",")
                                    : "";
                                let interactionLegend = "";
                                if (interactionVariable)
                                    interactionLegend = `${interactionVariable}: ${formatValue(
                                        mean.z
                                    ).join("")}, `;
                                let meanLegend = `${where} ${independentVariable}: ${formatValue(
                                    mean.x
                                ).join(
                                    ""
                                )}, ${interactionLegend}${dependentVariable}: ${formatValue(
                                    mean[targetKey(showMarginals)]
                                ).join("")}`;

                                return (
                                    showEachMean[index] &&
                                    !hideMeanDot && (
                                        <ReferenceDot
                                            key={"meanDot".concat(index)}
                                            label={
                                                <ReferenceLabel
                                                    dx={-15}
                                                    dy={20}
                                                    fontSize={mainStyle.getPropertyValue(
                                                        "--graphs-axes-text-size"
                                                    )}
                                                    fillText={mainStyle.getPropertyValue(
                                                        "--graphs-axes-text-color"
                                                    )}
                                                    fillBackground={"#20293C88"}
                                                    value={meanLegend}
                                                    textAnchor={"start"}
                                                />
                                            }
                                            x={mean.x}
                                            y={mean[targetKey(showMarginals)]}
                                            r={9}
                                            fill="#000000"
                                            strokeWidth={3}
                                            stroke="#39F"
                                        />
                                    )
                                );
                            })}
                        <RechartsTooltip
                            {...TooltipStyles()}
                            formatter={(value, name, props) => {
                                let formattedValue = value.toFixed(3);
                                return formattedValue;
                            }}
                            labelFormatter={(label) => {
                                let formattedValue = independentVariable
                                    .concat(": ")
                                    .concat(
                                        typeof label === "number"
                                            ? label.toFixed(3)
                                            : label
                                    );
                                return formattedValue;
                            }}
                        />
                    </LineChart>
                </ResponsiveContainer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--graphs-axes-text-color"
                            ),
                            fontFamily: "Arial",
                            fontSize:
                                mainStyle.getPropertyValue(
                                    "--graphs-axes-size"
                                ),
                        }}
                    >
                        {props.independentVariable}
                    </span>
                </div>
            </div>
        </div>
    );
}

class OutcomeCurveComponent extends Component {
    constructor(props) {
        super(props);

        this.showTooltip = this.showTooltip.bind(this);
        this.hideTooltip = this.hideTooltip.bind(this);
    }
    componentDidMount() {}
    showTooltip = (cx, cy, payloadForTooltip) => {
        let x = Math.round(cx);
        let y = Math.round(cy);
        this.refs.tooltip.show(x, y, payloadForTooltip);
    };

    hideTooltip = (e) => {
        this.refs.tooltip.hide();
    };

    getData(
        regressionResult,
        curveNames,
        independentVariable,
        interactionVariable,
        legendDict,
        showMarginals
    ) {
        let series = [];
        if (!regressionResult.regressionCurves) return series;
        if (regressionResult.regressionCurves.length === 0) return series;
        if (regressionResult.where) {
            let expressions;
            if (this.props.regressionResult.where?.values != null) {
                expressions = this.props.regressionResult.where.values.map(
                    (value) => ({
                        operation: "=",
                        value: value,
                    })
                );
            } else {
                expressions = this.props.regressionResult.where?.expressions;
            }
            expressions.forEach((expression, index) => {
                let whereFormatted = `${
                    this.props.regressionResult.where.group
                } ${expression.operation?.value ?? "="} ${
                    expression.value.label
                }`;
                let whereString = ` in ${
                    this.props.regressionResult.where.group
                } ${expression.operation?.value ?? "="} ${
                    expression.value.label
                }`;
                for (let i = 0; i < curveNames.length; i += 1) {
                    let serie = {
                        where: whereFormatted,
                        name: legendDict[curveNames[i]].concat(whereString),
                        origin: legendDict[curveNames[i]].concat(whereString),
                        color: colors[index][i],
                        dot: false,
                        data: regressionResult.regressionCurves[index][
                            curveNames[i]
                        ].x.map((x_item, index2) => {
                            return {
                                x: x_item,
                                y: regressionResult.regressionCurves[index][
                                    curveNames[i]
                                ][targetKey(showMarginals)][index2],
                            };
                        }),
                    };
                    series.push(serie);
                }
            });
        } else {
            for (let i = 0; i < curveNames.length; i += 1) {
                let serie = {
                    name: legendDict[curveNames[i]],
                    origin: legendDict[curveNames[i]],
                    color: colors[0][i],
                    dot: false,
                    data: regressionResult.regressionCurves[0][
                        curveNames[i]
                    ].x.map((x_item, index2) => {
                        return {
                            x: x_item,
                            y: regressionResult.regressionCurves[0][
                                curveNames[i]
                            ][targetKey(showMarginals)][index2],
                        };
                    }),
                };
                series.push(serie);
            }
        }

        return series;
    }

    render() {
        if (!this.props.regressionResult) return null;
        if (!this.props.regressionResult.regressionCurves) return null;
        if (this.props.regressionResult.regressionCurves.length === 0)
            return null;
        let legendDict = {
            effectCurve: this.props.regressionResult.dependentVariable,
            effectCurveHigh: `${this.props.regressionResult.dependentVariable} for High ${this.props.regressionResult.interactionVariable}`,
            effectCurveLow: `${this.props.regressionResult.dependentVariable} for Low ${this.props.regressionResult.interactionVariable}`,
        };

        let expressions;
        if (this.props.regressionResult.where?.values != null) {
            expressions = this.props.regressionResult.where.values.map(
                (value) => ({
                    operation: "=",
                    value: value,
                })
            );
        } else {
            expressions = this.props.regressionResult.where?.expressions;
        }

        if (!this.props.regressionResult.interactionVariable) {
            let series = this.getData(
                this.props.regressionResult,
                ["effectCurve"],
                this.props.regressionResult.independentVariable,
                this.props.regressionResult.interactionVariable,
                legendDict,
                this.props.showMarginals
            );
            return (
                <div
                    className="flex-column"
                    style={{
                        margin: "0 auto",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        className="my-row center-container"
                        style={{
                            marginLeft: 20,
                            marginRight: 20,
                            marginBottom: 10,
                            width: "100%",
                        }}
                    >
                        {!this.props.regressionResult.where ? (
                            <LegendElement
                                color={colors[0][0]}
                                text={
                                    this.props.regressionResult
                                        .dependentVariable
                                }
                            />
                        ) : (
                            expressions.map((expression, index) => {
                                let legendTitle = `${
                                    this.props.regressionResult
                                        .dependentVariable
                                } for ${
                                    this.props.regressionResult.where.group
                                } ${expression.operation?.label ?? "="} ${
                                    expression.value.label
                                }`;

                                return (
                                    <LegendElement
                                        color={colors[index][0]}
                                        text={legendTitle}
                                    />
                                );
                            })
                        )}
                    </div>
                    <OutcomesCurveInner
                        mainFixed={this.props.regressionResult.main_fixed}
                        showMarginals={this.props.showMarginals}
                        means={this.props.regressionResult.regressionCurves.map(
                            (item) => item.effectCurveMean
                        )}
                        dependentVariable={
                            this.props.regressionResult.dependentVariable
                        }
                        independentVariable={
                            this.props.regressionResult.independentVariable
                        }
                        hideMeanDot={false}
                        showTooltip={this.showTooltip}
                        hideTooltip={this.hideTooltip}
                        series={series}
                    />
                </div>
            );
        } else {
            let series = this.getData(
                this.props.regressionResult,
                ["effectCurveHigh", "effectCurveLow"],
                this.props.regressionResult.independentVariable,
                this.props.regressionResult.interactionVariable,
                legendDict,
                this.props.showMarginals
            );
            let maxY = undefined;
            let minY = undefined;
            if (series.length > 2) {
                minY = series[0].data[0].y;
                maxY = series[0].data[0].y;
                for (let i = 0; i < series.length; ++i)
                    for (let j = 0; j < series[i].data.length; ++j) {
                        maxY = Math.max(maxY, series[i].data[j].y);
                        minY = Math.min(minY, series[i].data[j].y);
                    }
            }

            let chunkSeries = [];
            while (series.length > 0) {
                chunkSeries.push(series.slice(0, 2));
                series = series.slice(2);
            }

            return (
                <div
                    className="my-row"
                    style={{
                        margin: "0 auto",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {chunkSeries.map((series, index) => {
                        let legendTitle1 = `${this.props.regressionResult.dependentVariable} for High ${this.props.regressionResult.interactionVariable}`;
                        let legendTitle2 = `${this.props.regressionResult.dependentVariable} for Low ${this.props.regressionResult.interactionVariable}`;
                        if (this.props.regressionResult.where) {
                            let whereString = ` in ${this.props.regressionResult.where.group} ${this.props.regressionResult.where.expressions[index].operation.label}
								${this.props.regressionResult.where.expressions[index].value.label}`;
                            legendTitle1 = legendTitle1.concat(whereString);
                            legendTitle2 = legendTitle2.concat(whereString);
                        }
                        let title = "";
                        if (this.props.regressionResult.where) {
                            title = `${this.props.regressionResult.where.group} ${this.props.regressionResult.where.expressions[index].operation.label} ${this.props.regressionResult.where.expressions[index].value.label}`;
                        }
                        let contentWidth =
                            chunkSeries.length === 1 ? "100%" : "50%";
                        return (
                            <div
                                className="flex-simple-column"
                                key={index}
                                style={{ width: contentWidth }}
                            >
                                {this.props.regressionResult.where ? (
                                    <div
                                        className="center-container"
                                        style={{ marginBottom: 10 }}
                                    >
                                        <span
                                            style={{
                                                color: mainStyle.getPropertyValue(
                                                    "--exploration-tertiary-text-color"
                                                ),
                                                fontFamily: "Arial",
                                                fontSize: "12px",
                                                lineHeight: "13px",
                                            }}
                                        >
                                            {title}
                                        </span>
                                    </div>
                                ) : null}

                                <div
                                    className="my-row center-container"
                                    style={{
                                        marginLeft: 20,
                                        marginRight: 20,
                                        marginBottom: 10,
                                        width: "100%",
                                    }}
                                >
                                    <LegendElement
                                        color={colors[index][0]}
                                        text={legendTitle1}
                                    />
                                    <LegendElement
                                        color={colors[index][1]}
                                        text={legendTitle2}
                                    />
                                </div>

                                <OutcomesCurveInner
                                    mainFixed={
                                        this.props.regressionResult.main_fixed
                                    }
                                    hideMeanDot={false}
                                    means={[
                                        this.props.regressionResult
                                            .regressionCurves[index]
                                            .effectCurveMean,
                                    ]}
                                    showMarginals={this.props.showMarginals}
                                    maxY={maxY}
                                    minY={minY}
                                    hideYaxisLabel={index !== 0}
                                    key={index}
                                    dependentVariable={
                                        this.props.regressionResult
                                            .dependentVariable
                                    }
                                    independentVariable={
                                        this.props.regressionResult
                                            .independentVariable
                                    }
                                    interactionVariable={
                                        this.props.regressionResult
                                            .interactionVariable
                                    }
                                    showTooltip={this.showTooltip}
                                    hideTooltip={this.hideTooltip}
                                    series={series}
                                />
                            </div>
                        );
                    })}
                </div>
            );
        }
    }
}

export default OutcomeCurveComponent;
