import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button } from "react-bootstrap";
import { SearchComponentOption } from "common/SearchComponent";
import { mainStyle } from "common/MainStyle";
import variables, { Variable } from "common/Variables";
import ValueWithOperator from "common/ValueWithOperator";

interface Props {
	currentModuleId: string | number | undefined;
	dataScopeId: number | string;
	targetParameterIndex: number;
	targetValues: (SearchComponentOption | null)[];
	title: string;
	onChange: (additionalValues: (SearchComponentOption | null)[]) => void;
	hideBottomHint?: boolean;
}

@observer
class MultiCountModeVariablesSelector extends Component<Props> {
	render() {
		let targetParameterIndex = this.props.targetParameterIndex;
		let targetValues = Array.from(this.props.targetValues);
		const variableInfo: Variable = variables(this.props.dataScopeId, this.props.currentModuleId)
			.dataVariables[targetParameterIndex];
		const variableName: string = variableInfo.name;
		const numeric: boolean =
			variableInfo.type === "float" || variableInfo.type === "int";
		return (
			<div
				className="flex-simple-column"
				style={{ marginTop: 25, marginLeft: 100 }}
				onKeyDown={(evt) => {
					evt.stopPropagation();
				}}
			>
				<span className="exploration-big-title-span">{this.props.title}</span>
				{targetValues.map((value, index) => (
					<div className="my-row" style={{ alignItems: "center" }}>
						<ValueWithOperator
							dataScopeId={this.props.dataScopeId}
							value={value}
							searchKey={variableName}
							searchIndex={targetParameterIndex}
							operator={{
								label: "=",
								value: "=",
							}}
							currentModuleId={this.props.currentModuleId}
							numeric={numeric}
							equalsOnly={true}
							onOperatorChange={(newOperator) => {}}
							onValueChange={(newValue) => {
								let targetValues = Array.from(
									this.props.targetValues
								);
								targetValues[index] = newValue;
								this.props.onChange(targetValues);
							}}
							style={{
								marginLeft: "1em",
								marginTop: "5px",
							}}
							clearable={true}
						/>
						<div
							className="flex-simple-column"
							style={{ marginLeft: 5, marginTop: 5 }}
						>
							<Button
								title={"Add value"}
								className="btn-small-like-select"
								style={{
									width: "19px",
									height: "19px",
								}}
								onClick={() => {
									let targetValues = Array.from(
										this.props.targetValues
									);

									targetValues.splice(index + 1, 0, null);
									this.props.onChange(targetValues);
								}}
							>
								{"\uFF0B" /* plus */}
							</Button>
							<Button
								title={"Remove value"}
								className="btn-small-like-select"
								style={{
									width: "19px",
									height: "19px",
								}}
								onClick={() => {
									let targetValues = Array.from(
										this.props.targetValues
									);

									targetValues.splice(index, 1);
									if (targetValues.length === 0) {
										targetValues.push(null);
									}
									this.props.onChange(targetValues);
								}}
							>
								{"\uFF0D" /* minus */}
							</Button>
						</div>
					</div>
				))}
				<div style={{ height: 20 }} />
				{!this.props.hideBottomHint ? (
					<span
						style={{
							marginTop: 10,
							alignSelf: "flex-end",
							cursor: "pointer",
							fontFamily: "Roboto",
							fontSize: "12px",
							lineHeight: "14px",
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
							fontWeight: 700,
						}}
					>
						press [&darr;] Key to view results
					</span>
				) : null}
			</div>
		);
	}
}

export default MultiCountModeVariablesSelector;
