import { observable, computed, makeObservable, action } from "mobx";
import {
	BriefCanvas,
	Canvas,
	InnerCanvas,
	CanvasBackground,
} from "common/Canvas";
import { getCanvasesApi, getCanvasApi } from "common/CanvasUserApi";
import { SPECIAL_TEMPLATE_PAGE } from "modules/canvas_page/CanvasTreeStore";

export interface CanvasOption {
	label: string;
	value: number;
}

export interface CanvasStrOption {
	label: string;
	value: string;
}
class CanvasInner {
	@observable public canvasesState: BriefCanvas[] = [];
	@observable public cachedFullCanvasesState: { [key: number]: Canvas } = {};
	private pageId: number | string;
	public initialized: boolean = false;

	constructor(pageId: number | string) {
		makeObservable(this);
		this.pageId = pageId;
		if (pageId !== SPECIAL_TEMPLATE_PAGE) {
			this.update()
		} else {
			this.initialized = true;
		}
	}
	@computed public get canvases() {
		return this.canvasesState;
	}

	@computed public get canvasOptions() {
		return this.canvasesState.map((canvas) => ({
			label: canvas.title,
			value: canvas.id,
		}));
	}
	deleteCanvas(canvasId: number) {
		this.assignCanvases(
			this.canvasesState.filter((canvas) => canvas.id !== canvasId)
		);
	}
	getCanvas(canvasId: number): BriefCanvas | undefined {
		return this.canvasesState.filter((canvas) => canvas.id === canvasId)[0];
	}

	@action.bound
	private assignCanvases(canvases: BriefCanvas[]) {
		this.canvasesState = canvases;
	}

	@action.bound
	private assignCachedCanvases(cashedCanvases: { [key: number]: Canvas }) {
		this.cachedFullCanvasesState = cashedCanvases;
	}

	async getFullCanvas(
		canvasId: number,
		allowCache: boolean = false
	): Promise<Canvas> {
		if (allowCache) {
			if (canvasId in this.cachedFullCanvasesState) {
				return this.cachedFullCanvasesState[canvasId];
			}
		}
		let canvas = await getCanvasApi(canvasId, false);
		let cachedFullCanvasesState = Object.assign(
			{},
			this.cachedFullCanvasesState
		);
		cachedFullCanvasesState[canvasId] = canvas;
		this.assignCachedCanvases(cachedFullCanvasesState);
		return canvas;
	}
	public updateFullCanvas(
		canvasId: number,
		canvas: InnerCanvas,
		backgrounds: CanvasBackground[]
	) {
		if (canvasId in this.cachedFullCanvasesState) {
			let cachedFullCanvasesState = Object.assign(
				{},
				this.cachedFullCanvasesState
			);
			cachedFullCanvasesState[canvasId].canvas = canvas;
			cachedFullCanvasesState[canvasId].backgrounds = backgrounds;
			this.assignCachedCanvases(cachedFullCanvasesState);
		}
	}

	public getCachedFullCanvas(canvasId: number): Canvas | undefined {
		if (canvasId in this.cachedFullCanvasesState)
			return this.cachedFullCanvasesState[canvasId];
		else {
			// non-blocking
			try {
				this.getFullCanvas(canvasId);
			} catch (exception) {}
		}
		return undefined;
	}

	public updateCanvas(canvasId: number) {
		getCanvasApi(canvasId, true)
			.then((canvas) => {
				let index = this.canvasesState.findIndex(
					(item) => item.id === canvasId
				);
				if (index < 0) return;
				let canvasesState = Array.from(this.canvasesState);
				canvasesState[index] = canvas;
				this.assignCanvases(canvasesState);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	public turnOnNotifications(canvasId: number) {
		let index = this.canvasesState.findIndex(
			(item) => item.id === canvasId
		);
		if (index < 0) return;
		let canvasesState = Array.from(this.canvasesState);
		canvasesState[index].has_new_notifications = true;
		this.assignCanvases(canvasesState);
	}

	public hideInSlideShow(canvasId: number, hideInSlideShow: boolean) {
		let index = this.canvasesState.findIndex(
			(item) => item.id === canvasId
		);
		if (index < 0) return;
		let canvasesState = Array.from(this.canvasesState);
		canvasesState[index].hide_in_slideshow = hideInSlideShow;
		this.assignCanvases(canvasesState);
	}

	public async update() {
		this.assignCachedCanvases({});
		return getCanvasesApi(undefined, this.pageId as number)
			.then((canvases) => {
				this.assignCanvases(canvases);
				this.initialized = true;
			})
			.catch((err) => {
				console.log(String(err));
			});
	}
}

let canvasMap: {
	[key in number | string]: CanvasInner;
} = {};

export default function Canvases(pageId: number): CanvasInner {
	if (!(pageId in canvasMap)) {
		canvasMap[pageId] = new CanvasInner(pageId);
	}
	return canvasMap[pageId];
}
