import React, { Component } from "react";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import { AppModuleProps } from "AppModule";
import {
    PermanentLinkType,
    selfSignupExistingAccountApi,
} from "common/ModulesApi";
import { goToInternalLink } from "common/InternalLinksHelper";
import linkIdToUrl, { sharedModuleIdToUrl } from "common/utilities/linkIdToUrl";
import axios from "common/ServerConnection";

class MainComponent extends Component<AppModuleProps> {
    public componentDidMount(): void {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("app_invitation_id") != null) {
            selfSignupExistingAccountApi(
                urlParams.get("app_invitation_id")!,
                PermanentLinkType.App
            )
                .then((linkId) => {
                    goToInternalLink(linkIdToUrl(true, linkId as string));
                })
                .catch(() => {
                    goToInternalLink("home.html");
                });
        } else if (urlParams.get("shared_module_invitation_id") != null) {
            selfSignupExistingAccountApi(
                urlParams.get("shared_module_invitation_id")!,
                PermanentLinkType.Module
            )
                .then((moduleId) => {
                    goToInternalLink(sharedModuleIdToUrl(moduleId as number));
                })
                .catch(() => {
                    goToInternalLink("home.html");
                });
        } else if (urlParams.get("module_invitation_id") != null) {
            axios
                .post<{
                    success: boolean;
                    error_msg: string;
                    module_ids: number[];
                    link_ids: string[];
                    entry_ids: number[];
                }>("/api/email_invitation_existing_account", {
                    id: urlParams.get("module_invitation_id"),
                })
                .then((response) => {
                    if (response.data.success) {
                        let moduleIds = response.data.module_ids;
                        let linkIds = response.data.link_ids;
                        let entryIds = response.data.entry_ids;
                        goToInternalLink(
                            moduleIds.length !== 0
                                ? `canvas.html?current_module_id=${moduleIds[0]}`
                                : linkIds.length !== 0
                                ? linkIdToUrl(true, linkIds[0])
                                : `wizard.html?entry_id=${entryIds[0]}`
                        );
                    } else {
                        console.log(response.data.error_msg);
                        goToInternalLink("home.html");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    goToInternalLink("home.html");
                });
        } else {
            goToInternalLink("home.html");
        }
    }

    public render(): JSX.Element {
        return <div />;
    }
}

export { MainComponent };
export let route = "/invitation.html";
