import StringOption from "common/StringOption";
import { DataScopeOption } from "common/DataScopes";
import { TableOption } from "common/Tables";
import { VariableOption } from "common/Variables";
import { GroupExtendedPermission } from "common/GroupPermissions";
import axios from "common/ServerConnection";


export const mergeOptions: ReadonlyArray<StringOption> = [
    { label: "inner", value: "inner" },
    { label: "left", value: "left" },
    { label: "right", value: "right" },
];

export interface VariableJoin {
    leftVariable: VariableOption | null;
    rightVariable: VariableOption | null;
}

export function isVariableJoinsValid(variableJoins: VariableJoin[]) {
    if (variableJoins.length === 0) return false;
    for (let variableJoin of variableJoins) {
        if (
            variableJoin.leftVariable == null ||
            variableJoin.rightVariable == null
        )
            return false;
    }
    return true;
}

export interface MergeState {
    leftDataScope: DataScopeOption | null;
    leftTableOption: TableOption | null;
    rightDataScope: DataScopeOption | null;
    rightTableOption: TableOption | null;
    targetDataScope: DataScopeOption | null;
    mergeType: StringOption | null;
    variableJoins: VariableJoin[];
    dropMissingLeft: boolean;
    dropMissingRight: boolean;
    permissions: (GroupExtendedPermission | null)[];
}

export async function mergeData(
    mergeState: MergeState,
    onSuccess: (
        preview:
            | {
                tableHeader: string[];
                tablePreview: any[];
                dropped: number | undefined;
                rightRenameMapping: {[key: string]: string}
            }
            | undefined
    ) => void,
    onError: (error: string) => void,
    preview: number | undefined = undefined,
    moduleId?: number | string | null,
    defaultPermissions?: boolean,

    headersToStoreLeft?: string[] | [],
    headersToStoreRight?: string[] | [],

) {
    let leftTableOption = mergeState.leftTableOption;
    let rightTableOption = mergeState.rightTableOption;

    let json = {
        left_table: {
            data_table_idx: leftTableOption!.data_table_idx,
            optimized: leftTableOption!.optimized,
            table: leftTableOption!.value,
            condition_id: leftTableOption!.condition_id ?? null,
            drop_missing: mergeState.dropMissingLeft,
        },
        right_table: {
            data_table_idx: rightTableOption!.data_table_idx,
            optimized: rightTableOption!.optimized,
            table: rightTableOption!.value,
            condition_id: rightTableOption!.condition_id ?? null,
            drop_missing: mergeState.dropMissingRight,
        },
        merge_type: mergeState.mergeType!.value,
        data_table_idx: mergeState.targetDataScope?.value,
        data_table_name:
            preview != null ? "" : mergeState.targetDataScope?.label ?? "",
        merge_variables: mergeState.variableJoins.map((varJoin) => ({
            left_variable_index: varJoin.leftVariable!.value,
            right_variable_index: varJoin.rightVariable!.value,
        })),
        permissions: defaultPermissions ? undefined : mergeState.permissions
            .filter((permission) => permission != null)
            .map((permission) => ({
                group_id: permission!.group_id,
                permission_type: permission!.permission_type,
            })),
        preview: preview,
        module_id: moduleId,

        left_headers: headersToStoreLeft,
        right_headers: headersToStoreRight
    };
    axios
        .post<{
            success: boolean;
            error_msg: string;
            table_header: string[];
            table_preview: any[];
            right_rename_mapping: {[key: string]: string};
            dropped?: number;
        }>("/api/e/merge_tables", json, {})
        .then((response) => {
            if (response.data.success === false) {
                onError(response.data.error_msg);
            } else {
                if (preview != null) {
                    onSuccess({
                        tableHeader: response.data.table_header,
                        tablePreview: response.data.table_preview,
                        dropped: response.data.dropped,
                        rightRenameMapping: response.data.right_rename_mapping
                    });
                } else onSuccess(undefined);
            }
        })
        .catch((error) => {
            onError(String(error));
        });
}

export async function mergeDataCheckMatch(
    mergeState: MergeState,
    leftVariableIndex: number,
    rightVariableIndex: number,
    moduleId?: number | string | null
): Promise<"valid" | "invalid" | "possible"> {
    let leftTableOption = mergeState.leftTableOption;
    let rightTableOption = mergeState.rightTableOption;

    let json = {
        left_table: {
            data_table_idx: leftTableOption!.data_table_idx,
            optimized: leftTableOption!.optimized,
            table: leftTableOption!.value,
            condition_id: leftTableOption!.condition_id ?? null
        },
        right_table: {
            data_table_idx: rightTableOption!.data_table_idx,
            optimized: rightTableOption!.optimized,
            table: rightTableOption!.value,
            condition_id: rightTableOption!.condition_id ?? null
        },
        left_variable_index: leftVariableIndex,
        right_variable_index: rightVariableIndex,
        merge_type: mergeState.mergeType!.value,
        module_id: moduleId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            match: "valid" | "invalid" | "possible";
        }>("/api/e/merge_tables_check_match", json, {})
        .then((response) => {
            if (response.data.success === false) {
                return Promise.reject(response.data.error_msg);
            } else {
                return response.data.match;
            }
        });
}
