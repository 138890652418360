import "common/styles/App.css";

import React from "react";
import InsightsContent from "common/insights_components/InsightsContent";
import InsightsSearchBar from "common/insights_components/InsightsSearchBar";
import OrderByDropdown, {
    SortingPolicy,
} from "common/insights_components/OrderByDropdown.js";
import PagesBar from "common/insights_components/PagesBar";
import { getInsightsApi } from "common/UserApi";
import { getInsightUserGroups } from "common/UserGroupsApi";
import ExpandableTab from "common/insights_components/ExpandableTab";
import { CSSTransition } from "react-transition-group";
import StoryCreator from "./StoryCreator";
import { Element } from "react-scroll";
import Popup from "reactjs-popup";
import InsightCard from "common/insights_components/InsightCard";
import InsightsCreationCard from "./InsightsCreationCard";
import axios from "common/ServerConnection";
import ShareLinkToast from "common/ShareLinkToast";
import {
    updateInsightAccessTimeApi,
    addInsightApi,
    getInsightImageApi,
    deleteInsightApi,
    moveInsightApi,
    getInsightApi,
} from "common/UserApi";
import TabPage from "common/TabPage";
import ls from "local-storage";
import PopupType from "common/PopupType";
import AddKpiPopup from "common/AddKpiPopup";
import { SocketIOInstance } from "common/ServerConnection";
import mobileBreakpoint, {
    calcScale,
    isLandscape,
} from "common/utilities/UIResponsiveManager";
import PageType from "common/PageType";
import Instrumentation from "common/Instrumentation";
import PagesStore from "common/PagesStore";
import { Permission } from "common/Permissions";
import TabType from "common/TabType";
import { goToInternalLink } from "common/InternalLinksHelper";
import { ReactComponent as AssessmentIcon } from "icons/left_nav_menu/assessment.svg";

import styles from "./main.module.css";

function defaultState(pageId) {
    return {
        sortingPolicy: SortingPolicy.default,
        pageId: pageId,
        filteredInsights: undefined,
        editedTab: "",
        pageTabs: [],
        insightsMap: {},
        sections: {},
        removingPopupShowed: false,
        removingTabId: undefined,
        editedTabId: undefined,
        showInsightsCard: false,
        currentInsight: undefined,
        editInsight: false,
        showShareToast: false,
        lastLink: undefined,
    };
}

function sortInsights(insights, sortingPolicy) {
    if (sortingPolicy === SortingPolicy.default) return insights;
    if (sortingPolicy === SortingPolicy.recentFirst)
        insights.sort((left, right) => {
            return right.raw_timestamp - left.raw_timestamp;
        });
    else
        insights.sort((left, right) => {
            return left.raw_timestamp - right.raw_timestamp;
        });
    return insights;
}

class MainComponent extends TabPage {
    constructor(props) {
        super(props);
        this.state = {
            ...defaultState(),
            landscapeOrientation: isLandscape(),
            innerWidth: window.screen.availWidth,
            innerHeight: window.screen.availHeight,
            popupHeight: 0,
            popupWidth: 0,
        };
        this.lastEditNode = ls.get("lastEditNode");
        this.tabType = TabType.insights;
        this.handleInsightsChange = this.handleInsightsChange.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.fetchDataInner = this.fetchDataInner.bind(this);
        this.fetchPage = this.fetchPage.bind(this);

        this.onTabOpen = this.onTabOpen.bind(this);
        this.onDropItem = this.onDropItem.bind(this);
        this.onInsightCopy = this.onInsightCopy.bind(this);
        this.onInsightAdd = this.onInsightAdd.bind(this);
        this.onInsightDelete = this.onInsightDelete.bind(this);
        this.onInsightShare = this.onInsightShare.bind(this);
        this.onInsightClick = this.onInsightClick.bind(this);
        this.onInsightsSwap = this.onInsightsSwap.bind(this);
        this.onInsightPin = this.onInsightPin.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.allInsights = this.allInsights.bind(this);
        this.afterInsightUpdate = this.afterInsightUpdate.bind(this);
        this.subscribeToFeedbackUpdates = this.subscribeToFeedbackUpdates.bind(
            this
        );
        this.storyCreatorRef = React.createRef();

        this.performance = null;
        this.mounted = false;
    }
    popupHeight() {
        return mobileBreakpoint()
            ? this.state.innerHeight * 1 - (this.props.frozen ? 0 : 50)
            : this.state.innerHeight * 0.7;
    }
    updateDimensions() {
        calcScale(true);
        let innerWidth = isLandscape()
            ? Math.max(window.screen.availWidth, window.screen.availHeight)
            : Math.min(window.screen.availWidth, window.screen.availHeight);
        let innerHeight = isLandscape()
            ? Math.min(window.screen.availWidth, window.screen.availHeight)
            : Math.max(window.screen.availWidth, window.screen.availHeight);
        this.setState({
            landscapeOrientation: isLandscape(),
            innerWidth: innerWidth,
            innerHeight: innerHeight,
        });
    }
    popupWidth() {
        return mobileBreakpoint()
            ? this.state.innerWidth * 1 -
                  (this.state.landscapeOrientation ? 88 : 0)
            : this.state.innerWidth * 0.7;
    }

    onInsightCopy(item) {
        let tabId = item.tab_id;
        getInsightUserGroups(item.id).then((groups) => {
            let newItem = Object.assign({}, item);
            getInsightImageApi(item.id)
                .then((imageBlob) => {
                    addInsightApi(
                        newItem.contents,
                        imageBlob,
                        tabId,
                        groups
                    ).then(
                        function (updated) {
                            this.handleInsightsChange(tabId);
                        }.bind(this)
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    onInsightAdd(tabId, scrollableRow) {
        let contents = {
            title: "",
            insight: "",
            recommendation: "",
        };
        addInsightApi(contents, null, tabId).then((id) => {
            getInsightApi(id).then((insight) => {
                let insights = Array.from(this.state.insightsMap[tabId] || []);
                insights.push(insight);
                let insightsMap = Object.assign({}, this.state.insightsMap);
                insightsMap[tabId] = insights;
                this.setState(
                    {
                        insightsMap: insightsMap,
                        currentInsight: insight,
                        editInsight: true,
                        showInsightsCard: true,
                    },
                    () => {
                        if (scrollableRow && scrollableRow.current)
                            scrollableRow.current.scrollToEnd();
                    }
                );
            });
        });
    }
    onInsightClick(insight) {
        if (this.lastEditNode) {
            ls.set("selectedPopup", {
                id: insight.id,
                type: PopupType.Insight,
                hash: String(insight.id)
                    .concat("_")
                    .concat(String(PopupType.Insight)),
            });
            goToInternalLink("/canvas.html");
            return;
        }

        this.setState({
            currentInsight: insight,
            editInsight: false,
            showInsightsCard: true,
        });
        updateInsightAccessTimeApi(insight.id);
        this.disableParentScroll();
    }
    onInsightPin(insight) {
        this.setState({
            pinnedInsight: insight,
        });
    }
    onInsightDelete(insight) {
        let tabId = insight.tab_id;
        deleteInsightApi(insight).then(() => {
            this.storyCreatorRef.current.deleteInsight(insight.id);
            let insights = Array.from(this.state.insightsMap[tabId]);
            if (insights) {
                insights = insights.filter((item) => item.id !== insight.id);
                let insightsMap = Object.assign({}, this.state.insightsMap);
                insightsMap[tabId] = insights;
                let filteredInsights = undefined;
                if (this.state.filteredInsights != null) {
                    filteredInsights = Array.from(
                        this.state.filteredInsights
                    ).filter((item) => item.id !== insight.id);
                }
                this.setState({
                    insightsMap: insightsMap,
                    filteredInsights: filteredInsights,
                });
            }
        });
    }
    onDropItem(item, tabId) {
        this.performance = new Date();
        let oldTabId = item.tab_id;
        moveInsightApi(item.id, undefined, tabId).then(() => {
            this.handleInsightsChange(oldTabId);
            this.handleInsightsChange(tabId);
            this.onExpandTab(tabId, true);
        });
    }
    onInsightsSwap(item, otherItem) {
        let tabId = item.tab_id;
        moveInsightApi(item.id, otherItem.id, undefined).then(() => {
            this.handleInsightsChange(tabId);
        });
    }

    onInsightShare(insight) {
        let url = new URL(window.location.origin);
        url.pathname = "/view_card.html";
        let editedInsight = Object.assign({}, insight);
        let insightJson = {
            card: JSON.stringify({
                ...editedInsight.contents,
                creation_time: editedInsight.creation_time,
                user_data:
                    editedInsight.user_data != null
                        ? {
                              first_name: editedInsight.user_data.first_name,
                              last_name: editedInsight.user_data.last_name,
                              icon_url: editedInsight.user_data.icon_url,
                          }
                        : undefined,
            }),
        };
        let formData = new FormData();
        formData.append("metadata", JSON.stringify(insightJson));
        getInsightImageApi(editedInsight.id)
            .then((image) => {
                if (
                    image != null &&
                    editedInsight.contents.finding.type === "image"
                ) {
                    formData.append("image", image, "image");
                }
                axios
                    .post("/api/insights_card_add", formData, null)
                    .then((response) => {
                        if (response.data.success) {
                            url.searchParams.append(
                                "remote_insightid",
                                response.data.id
                            );
                            this.setState({
                                showShareToast: true,
                                lastLink: url.toString(),
                            });
                        } else {
                            this.setState({
                                showShareToast: true,
                                lastLink: undefined,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            showShareToast: true,
                            lastLink: undefined,
                        });
                    });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    showShareToast: true,
                    lastLink: undefined,
                });
            });
    }
    afterInsightUpdate(insightId) {
        getInsightApi(insightId).then((insight) => {
            this.storyCreatorRef.current.updateInsight(insight);
            let insights = Array.from(
                this.state.insightsMap[insight.tab_id] || []
            );
            let insightsMap = Object.assign({}, this.state.insightsMap);

            if (insights) {
                let index = insights.findIndex((element, index, array) => {
                    return String(element.id) === String(insight.id);
                });
                if (index < 0) return;
                insights[index] = insight;

                insightsMap[insight.tab_id] = insights;
                this.setState({ insightsMap: insightsMap });
            }
            if (
                this.state.currentInsight &&
                this.state.currentInsight.id === insightId
            ) {
                this.setState({ currentInsight: insight });
            }
        });
    }

    handleInsightsChange(tabId) {
        let insightsMap = Object.assign({}, this.state.insightsMap);
        insightsMap[tabId] = [];

        this.setState(
            { insightsMap: insightsMap, filteredInsights: undefined },
            () => {
                this.fetchData(tabId);
            }
        );
    }
    onTabOpen(tabId, force = false) {
        if (
            (this.state.sections[tabId] && !this.state.insightsMap[tabId]) ||
            force
        ) {
            this.fetchData(tabId);
        }
    }

    buildPageTab(title, tabId) {
        return (
            <ExpandableTab
                frozen={
                    PagesStore(PageType.Insights).permission(
                        this.state.pageId
                    ) === Permission.ReadOnly
                }
                acceptDrop={"insight"}
                title={title}
                tabId={tabId}
                tabComponent={this}
            />
        );
    }
    allInsights() {
        return Object.values(this.state.insightsMap).flat();
    }
    fetchData(tabId) {
        this.fetchDataInner(tabId, 0, 5, []);
    }
    fetchPage() {
        this.state.pageTabs.forEach((tab) => {
            this.fetchData(tab.id);
        });
    }

    fetchDataInner(tabId, offset, limit, allInsights) {
        getInsightsApi(tabId, offset, limit).then((insights) => {
            if (insights.length > 0) {
                allInsights = allInsights.concat(insights);
                let insightsMap = Object.assign({}, this.state.insightsMap);
                insightsMap[tabId] = allInsights;

                this.setState({ insightsMap: insightsMap });
                this.fetchDataInner(tabId, offset + limit, limit, allInsights);
            }
        });
    }

    buildInsightsRow(tabId) {
        let insights = sortInsights(
            Array.from(this.state.insightsMap[tabId] || []),
            this.state.sortingPolicy
        );
        return (
            <div
                className="journey-column"
                key={tabId}
                style={{
                    display: this.state.sections[tabId] ? "block" : "none",
                }}
            >
                <InsightsContent
                    onInsightPin={(insight) => {
                        this.performance = new Date();
                        this.onInsightPin(insight);
                    }}
                    onInsightsSwap={(insight1, insight2) => {
                        if (
                            this.state.sortingPolicy === SortingPolicy.default
                        ) {
                            this.performance = new Date();
                            this.onInsightsSwap(insight1, insight2);
                        }
                    }}
                    onInsightAdd={(tabId, scrollableRow) => {
                        this.performance = new Date();
                        this.onInsightAdd(tabId, scrollableRow);
                    }}
                    onInsightDelete={(insight) => {
                        this.performance = new Date();
                        this.onInsightDelete(insight);
                    }}
                    onInsightCopy={(insight) => {
                        this.performance = new Date();
                        this.onInsightCopy(insight);
                    }}
                    onInsightShare={(insight) => {
                        this.performance = new Date();
                        this.onInsightShare(insight);
                    }}
                    onInsightClick={(insight) => {
                        this.performance = new Date();
                        this.onInsightClick(insight);
                    }}
                    permission={PagesStore(PageType.Insights).permission(
                        this.state.pageId
                    )}
                    tabId={tabId}
                    insights={insights}
                />
            </div>
        );
    }

    buildSearchRow() {
        let insights = sortInsights(
            Array.from(this.state.filteredInsights),
            this.state.sortingPolicy
        );
        return (
            <div
                className="journey-column"
                style={{
                    marginLeft: 20,
                    marginTop: 20,
                }}
            >
                <InsightsContent
                    hideAddButton
                    tabs={this.state.pageTabs.reduce(function (map, obj) {
                        map[obj.id] = obj.title;
                        return map;
                    }, {})}
                    onInsightPin={(insight) => {
                        this.performance = new Date();
                        this.onInsightPin(insight);
                    }}
                    onInsightsSwap={() => {}}
                    onInsightAdd={() => {}}
                    onInsightDelete={(insight) => {
                        this.performance = new Date();
                        this.onInsightDelete(insight);
                    }}
                    onInsightCopy={(insight) => {
                        this.performance = new Date();
                        this.onInsightCopy(insight);
                    }}
                    onInsightShare={(insight) => {
                        this.performance = new Date();
                        this.onInsightShare(insight);
                    }}
                    onInsightClick={(insight) => {
                        this.performance = new Date();
                        this.onInsightClick(insight);
                    }}
                    permission={PagesStore(PageType.Insights).permission(
                        this.state.pageId
                    )}
                    insights={insights}
                />
            </div>
        );
    }

    subscribeToFeedbackUpdates() {
        SocketIOInstance?.on("new_insight_feedbacks", (response) => {
            let cardId = response.data["card_id"];
            this.afterInsightUpdate(cardId);
        });
    }

    componentDidMount() {
        if (this.performance != null) {
            let timeMs = new Date() - this.performance;
            this.performance = null;
            Instrumentation.addInteraction("Insights", timeMs);
        }
        this.updateDimensions();
        this.subscribeToFeedbackUpdates();
        let lastEditInsight = ls.get("lastEditInsight");
        if (lastEditInsight) {
            getInsightApi(lastEditInsight.id)
                .then((insight) => {
                    this.setState({
                        showInsightsCard: true,
                        currentInsight: insight,
                        editInsight: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            ls.remove("lastEditInsight");
        }
        window.addEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.performance != null) {
            let timeMs = new Date() - this.performance;
            this.performance = null;
            Instrumentation.addInteraction("Insights", timeMs);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    disableParentScroll() {
        document.querySelector(
            "#insights-page-content-wrapper"
        ).style.overflow = "hidden";
        document.querySelector("body").style.overflow = "hidden";
    }

    enableParentScroll() {
        document.querySelector(
            "#insights-page-content-wrapper"
        ).style.overflow = "auto";
        document.querySelector("body").style.overflow = "auto";
    }
    render() {
        if (!this.mounted) {
            this.mounted = true;
            this.performance = new Date();
        }
        return (
            <div
                id="insights-page-content-wrapper"
                className="content-wrapper element"
                style={{ marginRight: 50, overflowY: "auto" }}
            >
                <section className="content-header">
                    <div
                        style={{
                            display: "flex",
                            marginTop: 30,
                            height: 40,
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <InsightsSearchBar
                            input={this.allInsights()}
                            output={(filtered) => {
                                this.performance = new Date();
                                this.setState({
                                    filteredInsights: filtered,
                                });
                            }}
                        />
                        <div style={{ flexGrow: 1 }} />
                        <OrderByDropdown
                            value={this.state.sortingPolicy}
                            onChange={(value) => {
                                this.performance = new Date();
                                this.setState({ sortingPolicy: value });
                            }}
                        />
                    </div>
                </section>
                <section className="content">
                    <div style={{ marginTop: 20 }}>
                        <PagesBar
                            type={PageType.Insights}
                            frozen={false}
                            onPageChange={(pageId) => {
                                this.setState(defaultState(pageId), () => {
                                    this.getPageTabs(pageId, this.fetchPage);
                                });
                            }}
                        />
                    </div>
                    {!this.state.filteredInsights ? (
                        <>
                            <div
                                style={{
                                    marginLeft: "34px",
                                }}
                            >
                                {this.state.pageTabs.map((pageTab) => (
                                    <div key={pageTab.id}>
                                        {this.buildPageTab(
                                            pageTab.title,
                                            pageTab.id
                                        )}
                                        <CSSTransition
                                            key={pageTab.id}
                                            timeout={500}
                                            in={this.state.sections[pageTab.id]}
                                            classNames={"journey-column"}
                                        >
                                            {this.buildInsightsRow(pageTab.id)}
                                        </CSSTransition>
                                    </div>
                                ))}
                                {PagesStore(PageType.Insights).permission(
                                    this.state.pageId
                                ) > Permission.ReadOnly
                                    ? this.buildPageTab(this.state.editedTab)
                                    : null}
                            </div>

                            {this.buildTabRemovePopup()}
                        </>
                    ) : (
                        this.buildSearchRow()
                    )}
                </section>
                <StoryCreator
                    ref={this.storyCreatorRef}
                    onInsightClick={this.onInsightClick}
                    onInsightShare={this.onInsightShare}
                />
                <Popup
                    arrow={true}
                    contentStyle={{
                        width: this.popupWidth(),
                        maxWidth: this.popupWidth(),
                        top: mobileBreakpoint()
                            ? this.props.frozen
                                ? 0
                                : 50
                            : 20,
                        maxHeight: this.popupHeight(),
                        height: this.popupHeight(),
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    open={this.state.showInsightsCard}
                    onClose={() => {
                        this.enableParentScroll();
                        this.setState({ showInsightsCard: false });
                    }}
                    closeOnDocumentClick
                >
                    <Element
                        name="scrollable"
                        className="element"
                        style={{
                            height: this.popupHeight(),
                            width: this.popupWidth(),
                            overflow: "hidden",
                        }}
                    >
                        <div
                            className="dashboard-popup"
                            style={{
                                minHeight: "100%",
                                width: this.popupWidth(),
                                padding: mobileBreakpoint() ? 10 : 0,
                            }}
                        >
                            {this.state.currentInsight ? (
                                this.state.editInsight ? (
                                    <InsightsCreationCard
                                        parentContainerHeight={
                                            this.popupHeight() -
                                            (mobileBreakpoint() ? 20 : 0)
                                        }
                                        insightId={this.state.currentInsight.id}
                                        insight={this.state.currentInsight}
                                        onInsightsChange={
                                            this.handleInsightsChange
                                        }
                                        beforeInsightUpdate={() => {
                                            this.performance = new Date();
                                        }}
                                        afterInsightUpdate={
                                            this.afterInsightUpdate
                                        }
                                        onHide={() => {
                                            this.setState({
                                                showInsightsCard: false,
                                            });
                                        }}
                                    />
                                ) : (
                                    <InsightCard
                                        canShowFeedbacks
                                        parentContainerHeight={
                                            this.popupHeight() -
                                            (mobileBreakpoint() ? 20 : 0)
                                        }
                                        parentContainerWidth={
                                            this.popupWidth() -
                                            (mobileBreakpoint() ? 20 : 0)
                                        }
                                        onEdit={() => {
                                            this.setState({
                                                editInsight: true,
                                            });
                                        }}
                                        onPin={() => {
                                            this.performance = new Date();
                                            this.setState((state) => ({
                                                pinnedInsight:
                                                    state.currentInsight,
                                            }));
                                        }}
                                        pinnable={true}
                                        onGetFeedbacks={this.afterInsightUpdate}
                                        landscapeOrientation={
                                            this.state.landscapeOrientation
                                        }
                                        mobileView={mobileBreakpoint()}
                                        editable={
                                            !this.props.frozen &&
                                            PagesStore(
                                                PageType.Insights
                                            ).permission(this.state.pageId) >
                                                Permission.ReadOnly
                                        }
                                        insightId={this.state.currentInsight.id}
                                        insight={this.state.currentInsight}
                                        presentation={false}
                                        onHide={() => {
                                            this.setState({
                                                showInsightsCard: false,
                                            });
                                        }}
                                    />
                                )
                            ) : null}
                        </div>
                    </Element>
                </Popup>
                {this.state.pinnedInsight && (
                    <AddKpiPopup
                        beforeSave={() => {
                            this.performance = new Date();
                        }}
                        contents={this.state.pinnedInsight.contents}
                        tabType={TabType.kpiStreams}
                        onClose={() => {
                            this.setState({
                                pinnedInsight: undefined,
                            });
                        }}
                    />
                )}
                {this.state.showShareToast ? (
                    <ShareLinkToast
                        value={this.state.lastLink}
                        onClosed={() => {
                            this.setState({
                                showShareToast: false,
                                lastLink: undefined,
                            });
                        }}
                    />
                ) : null}
            </div>
        );
    }
}

export { MainComponent };
export let route = "/insights.html",
    section = "Insights",
    leftPanelItem = {
        section: "Insights",
        href: "insights.html",
        icon: <AssessmentIcon className={styles.icon} />,
    },
    requirePermission = "Insights";
