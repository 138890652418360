import React from "react";
// import { mainStyle } from "common/MainStyle";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";

// Custom styling
import Select from "react-select";
import { getCustomSelectStyleLight } from "common/SelectStyles";
import axios from "common/ServerConnection";

type ApiResponse = {
    success: boolean
}


type FirebaseProject = {
    id: string;
    numericId: string;
    friendlyName: string;
}

type ProjectsApiResponse = ApiResponse & { projects: FirebaseProject[] }

type FirestoreDatabase = {
    id: string;
    name: string;
}

type DatabasesApiResponse = ApiResponse & { databases: FirestoreDatabase[] }

interface Options {
    value: string,
    label: string
}

interface State {
    projects: Options[];
    databases: Options[];
    selectedProject: String;
    selectedDatabase: String;
    selectedCollection: string;
}

export default class FIRESTORE extends React.Component<APIComponentProps, State> {
    constructor(props: APIComponentProps) {
        super(props);
        this.state = {
            projects: [],
            databases: [],
            selectedProject: '',
            selectedDatabase: '',
            selectedCollection: ''
        }

        this.props.onOptionsChange({
            project: "",
            database: "",
            collection: ""
        });
    }

    componentDidMount() {
        this.setState({
            projects: [],
            databases: [],
            selectedProject: '',
            selectedDatabase: '',
            selectedCollection: ''
        })

        axios.get<ProjectsApiResponse>("/api/firestore/projects").then(({ data }) => {
            if (data.success) {
                this.setState({
                    projects: data.projects.map((p) => ({
                        value: p.id,
                        label: p.friendlyName
                    })),
                });
            }
        });
    }

    onProjectChange(evt: any) {
        this.setState({
            databases: [],
            selectedProject: evt.value,
            selectedDatabase: '',
            selectedCollection: ''
        })

        axios.get<DatabasesApiResponse>(`/api/firestore/projects/${evt.value}/databases`).then(({ data }) => {
            if (data.success) {
                this.setState({
                    databases: data.databases.map((d) => ({
                        value: d.name,
                        label: d.name
                    })),
                });
            }
        });
    }

    onDatabaseChange(evt: any) {
        this.setState({
            selectedDatabase: evt.value,
            selectedCollection: ''
        })
    }

    onCollectionChange(evt: any) {
        const value = evt.target.value;

        this.setState({
            selectedCollection: value
        })

        let options = { ...this.props.options };

        this.props.onOptionsChange({
            ...options,
            project: this.state.selectedProject,
            database: this.state.selectedDatabase,
            collection: value
        });
    }

    render() {
        let selectStyles = getCustomSelectStyleLight(14, false);
        // let contentStyle: React.CSSProperties = {
        //     fontFamily: "Roboto",
        //     fontSize: 16,
        //     fontWeight: 400,
        //     color: mainStyle.getPropertyValue("--content-primary-text-color"),
        // };
        // let titleStyle = {
        //     ...contentStyle,
        //     color: "#39F",
        // };
        return (
            <div
                className="flex-simple-column"
                style={this.props.containerStyle}
            >
                <BaseAPIComponent {...this.props} />
                <div className="flex-simple-column" style={{ marginTop: "23px" }}>
                    {
                        this.state.projects.length > 0 && (
                            <div className="my-row" style={{ marginLeft: 0, marginTop: 9 }} >
                                <div style={{ width: "100%" }}>
                                    <label>Project</label>
                                    <Select name="projects-option"
                                        options={this.state.projects}
                                        styles={{
                                            ...selectStyles,
                                            container: (base) => ({
                                                ...base,
                                                height: "40px",
                                                width: "40%",
                                                marginLeft: "0px",
                                                marginRight: "10px",
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25: "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                        onChange={(e) => this.onProjectChange(e)}
                                    ></Select>
                                </div>
                            </div>
                        )
                    }
                    {
                        this.state.databases.length > 0 && (
                            <div className="my-row" style={{ marginLeft: 0, marginTop: 9 }} >
                                <div style={{ width: "100%" }}>
                                    <label>Database</label>
                                    <Select name="projects-option"
                                        options={this.state.databases}
                                        styles={{
                                            ...selectStyles,
                                            container: (base) => ({
                                                ...base,
                                                height: "40px",
                                                width: "40%",
                                                marginLeft: "0px",
                                                marginRight: "10px",
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25: "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                        onChange={(e) => this.onDatabaseChange(e)}
                                    ></Select>
                                </div>
                            </div>
                        )
                    }
                    {
                        this.state.selectedDatabase.length > 0 && (
                            <div className="my-row" style={{ marginLeft: 0, marginTop: 9 }} >
                                <div style={{ width: "100%" }}>
                                    <label>Collection</label>
                                    <div>
                                        <input
                                            style={{ width: "40%" }}
                                            className="like-select module-template"
                                            placeholder="Collection id..."
                                            onChange={(e) => this.onCollectionChange(e)}
                                            value={this.state.selectedCollection}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export { FIRESTORE as MainComponent };
