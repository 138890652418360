import React, {
    useState,
    useEffect,
    useMemo,
    Component,
    CSSProperties,
} from "react";
import ReactDOMServer from "react-dom/server";
import "leaflet/dist/leaflet.css";
import Leaflet from "leaflet";
import {
    useMap,
    useMapEvents,
    MapContainer,
    Marker,
    Tooltip,
    GeoJSON,
} from "react-leaflet";
import "leaflet.heat";
import { DualRing } from "react-spinners-css";

import elements from "common/CanvasElements";
import {
    TextAlign,
    MapElement,
    MapVariableOption,
    ColorOptions,
    MapTooltipDisplayModeOption,
    MapTooltipDisplayMode,
} from "common/Canvas";
import { TooltipOptions } from "common/ConfigurableTooltip";
import { goToInternalLink } from "common/InternalLinksHelper";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import axios from "common/ServerConnection";
import { VariableOption } from "common/Variables";
import { Resizable } from "re-resizable";
import Draggable from "react-draggable";
import { GeoJsonObject } from "geojson";
import StringUtils from "common/utilities/StringUtils";
import ColorPicker from "common/ColorPicker";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { getTextSize } from "common/utilities/MeasureText";
import { colorList } from "common/graphics/LineColors";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import processUrl from "common/processUrl";
import remoteModuleId from "common/remoteModuleId";
import { PopupStatus } from "common/StatusPopup";
import HamburgerMenu from "./canvas_elements/MapElements/HamburgerMenu";
import { iconToPath } from "common/MakiIcons";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

// Added SVG buttons
import TileFilterLayer from "common/leaflet_addons/TileFilterLayer";
import OutsideAlerter from "common/OutsideAlerter";

const defaultItemHeight = 50;
const defaultItemWidth = 100;

const markerIcon = (base64Icon?: string | null, color?: string | null) => {
    if (base64Icon != null || color == null) {
        return new Leaflet.Icon({
            iconUrl: base64Icon ?? "/dist/img/canvas/map/marker.png",
            iconRetinaUrl: base64Icon ?? "/dist/img/canvas/map/marker.png",
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
        });
    } else {
        return new Leaflet.DivIcon({
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            // labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        backgroundColor: color,
                        maskImage: "url(/dist/img/canvas/map/marker.png)",
                        WebkitMaskImage: "url(/dist/img/canvas/map/marker.png)",
                        width: 64,
                        height: 64,
                    }}
                />
            ),
        });
    }
};

const markerIconWithSymbol = (
    base64Icon?: string | null,
    color?: string | null,
    symbol?: string | null
) => {
    const defaultIconPath: string =
        symbol != null
            ? "/dist/img/canvas/map/marker_filled.png"
            : "/dist/img/canvas/map/marker.png";
    if (base64Icon != null || color == null) {
        return new Leaflet.DivIcon({
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            // labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        position: "relative",
                        width: 64,
                        height: 64,
                    }}
                >
                    <img
                        alt=""
                        src={base64Icon ?? defaultIconPath}
                        style={{
                            width: 64,
                            height: 64,
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                    />
                    {symbol != null && (
                        <div
                            style={{
                                backgroundColor: "white",
                                maskImage: `url(${iconToPath[symbol]})`,
                                maskSize: "contain",
                                WebkitMaskImage: `url(${iconToPath[symbol]})`,
                                WebkitMaskSize: "contain",
                                width: 28,
                                height: 28,
                                position: "absolute",
                                top: "33%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    )}
                </div>
            ),
        });
    } else {
        return new Leaflet.DivIcon({
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            // labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        position: "relative",
                        width: 64,
                        height: 64,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: color,
                            maskImage: `url(${defaultIconPath})`,
                            WebkitMaskImage: `url(${defaultIconPath})`,
                            width: 64,
                            height: 64,
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                    />
                    {symbol != null && (
                        <div
                            style={{
                                backgroundColor: "white",
                                maskImage: `url(${iconToPath[symbol]})`,
                                maskSize: "contain",
                                WebkitMaskImage: `url(${iconToPath[symbol]})`,
                                WebkitMaskSize: "contain",
                                width: 28,
                                height: 28,
                                position: "absolute",
                                top: "33%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    )}
                </div>
            ),
        });
    }
};

const markerIconTransparent = new Leaflet.Icon({
    iconUrl: "/dist/img/canvas/map/marker_transparent.png",
    iconRetinaUrl: "/dist/img/canvas/map/marker_transparent.png",
    iconAnchor: [32, 32],
    popupAnchor: [0, 0],
    labelAnchor: [0, 0],
    tooltipAnchor: [0, 0],
    iconSize: new Leaflet.Point(64, 64),
    className: "",
});

interface Props {
    // called when we click on context menu
    onContextMenu: (evt: React.MouseEvent) => void;

    canvasTreeStore: CanvasTreeStore;

    // data layer element from Canvas structure
    mapElement: MapElement;

    // unique identifier of data layer element
    mapElementId: string;

    // height of element (unscaled)
    height: number;

    // if true then we in live mode
    live: boolean;

    // if true than we cannot edit map
    frozen: boolean;

    // scale of presentation
    scale: number;

    // indicates if we are seeing map from shared link
    sharedPolicy: CanvasSharedPolicy;

    // onChange callback
    onChange: (
        mapElement: Partial<MapElement>,
        geoJsonFiles?: MapElement["geoJsonFiles"]
    ) => void;
    // onDelete callback
    onDelete: () => void;
    // id of current presentation
    currentModuleId: number | undefined;
    // track performance callback
    onTrackNewPerformance: (element: string) => void;
    toggleOpenEditMenu: (mapId: string, zoomLevel?: number) => void;
    htmlElementsRootRef: HTMLDivElement | undefined;
    hovered: boolean;
    heatMapData: Array<[number, number, number]>;
    data: { [key: string]: Array<number | string | null> } | null;
    loading: boolean;
}

enum OperationStatus {
    NotStarted = 1,
    Running = 2,
    Success = 3,
    Error = 4,
}

interface State {
    updating: boolean;
    operationErrorMessage: string | null;
    operationStatus: OperationStatus;
    popupMarkerIndex: number | null;
    hoverInfo: {
        index: number;
        x: number;
        y: number;
    } | null;
    colorVariableValues: (string | number | null)[];
    colorVariableValueToIndex: Map<string | number | null, number>;
    geoJsonSizes: { [key: string]: number };
    popupStatus: PopupStatus | undefined;
    message: string | undefined;
    hamburgerMenuOpened: boolean;
}

// properties of TooltipItem
interface TooltipItemProps {
    defaultY: number;
    defaultWidth?: number;
    defaultHeight?: number;
    widthOffset: number;
    editable: boolean;
    mapElement?: MapElement;
    mapElementId: string;
    onTrackNewPerformance: (element: string) => void;
    onChange?: (map: MapElement) => void;
    index: number;
    option: MapVariableOption;
    value: number | string | null;
    scale: number;
}

/*!
 * function tooltipItemPosition calculates position of tooltip item
 * in tooltip
 */
function tooltipItemPosition(
    option: MapVariableOption,
    defaultY: number,
    widthOffset: number
): {
    x: number;
    y: number;
} {
    let position = {
        x: (option.options.x ?? 10) - widthOffset,
        y: option.options.y ?? defaultY,
    };
    return position as {
        x: number;
        y: number;
    };
}

/*!
 * function tooltipItemPosition calculates size of tooltip item
 * in tooltip
 */
function tooltipItemSize(
    option: MapVariableOption,
    defaultWidth?: number,
    defaultHeight?: number
): {
    width: number;
    height: number;
} {
    let itemSize = {
        width: option.options?.width ?? defaultWidth ?? defaultItemWidth,
        height: option.options?.height ?? defaultHeight ?? defaultItemHeight,
    };
    return itemSize;
}

/*!
 * function tooltipItemRect defines scaled
 * rectangle of tooltip item
 */
function tooltipItemRect(props: TooltipItemProps) {
    let option = props.option;
    let isImageUrl = false;
    let isUrl = false;
    if (typeof props.value === "string") {
        isUrl = StringUtils.isValidHttpUrl(props.value as string);
        isImageUrl = StringUtils.isImageUrl(props.value as string);
    }
    let showTitle = option.options.showTitle ?? true;
    let position = tooltipItemPosition(
        props.option,
        props.defaultY,
        props.widthOffset
    );
    position.x = position.x * props.scale;
    position.y = position.y * props.scale;
    let itemSize = tooltipItemSize(
        props.option,
        props.defaultWidth,
        props.defaultHeight
    );
    let targetValue = "";
    if (
        option.options.width == null &&
        option.options.height == null &&
        !props.editable &&
        !isUrl &&
        !isImageUrl
    ) {
        targetValue = showTitle
            ? `${option.variable.label} : ${props.value}`
            : `${props.value}`;
        itemSize = getTextSize(
            targetValue,
            "Roboto",
            option.options.fontSize * props.scale,
            "normal"
        );
        itemSize.width += 5;
    } else {
        itemSize.width = itemSize.width * props.scale;
        itemSize.height = itemSize.height * props.scale;
    }
    return {
        x: position.x,
        y: position.y,
        width: itemSize.width,
        height: itemSize.height,
    };
}

/*!
 * TooltipVideoComponent defines tooltip item with video url
 *
 */

function TooltipVideoComponent({
    src,
    width,
    height,
    iframeTitle,
    style,
}: {
    src: string;
    width: number | string;
    height: number | string;
    iframeTitle: string;
    style?: CSSProperties;
}) {
    let urlString = processUrl(src);
    if (urlString != null) {
        return (
            <iframe
                draggable={false}
                title={iframeTitle}
                frameBorder="0"
                allow=""
                allowFullScreen
                width={width}
                height={height}
                src={urlString}
                style={style}
            />
        );
    } else {
        return (
            <video controls={true} width={width} height={height} style={style}>
                <source src={src} />
            </video>
        );
    }
}

/*!
 * function TooltipItemContent renders tooltip item: regular variable item, image item or video item
 * according to value
 */
function TooltipItemContent(props: TooltipItemProps) {
    let option = props.option;
    let [drag, setDrag] = useState(false);
    let isImageUrl = false;
    let isUrl = false;
    if (typeof props.value === "string") {
        isUrl = StringUtils.isValidHttpUrl(props.value as string);
        isImageUrl = StringUtils.isImageUrl(props.value as string);
    }
    let showTitle = option.options.showTitle ?? true;
    let position = tooltipItemPosition(
        props.option,
        props.defaultY,
        props.widthOffset
    );
    position.x = position.x * props.scale;
    position.y = position.y * props.scale;
    let itemSize = tooltipItemSize(
        props.option,
        props.defaultWidth,
        props.defaultHeight
    );
    let targetValue = "";
    let defaultTextStyle = {};
    if (
        option.options.width == null &&
        option.options.height == null &&
        !props.editable &&
        !isUrl &&
        !isImageUrl
    ) {
        defaultTextStyle = {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap" as "nowrap",
        };
        targetValue = showTitle
            ? `${option.variable.label} : ${props.value}`
            : `${props.value}`;
        itemSize = getTextSize(
            targetValue,
            "Roboto",
            Math.ceil(option.options.fontSize * props.scale),
            "normal"
        );
        itemSize.width += 5;
    } else {
        itemSize.width = itemSize.width * props.scale;
        itemSize.height = itemSize.height * props.scale;
    }
    let textStyle = {
        ...defaultTextStyle,
        textAlign: option.options.textAlign ?? TextAlign.left,
        display: "block",
        width: itemSize.width,
        height: itemSize.height,
        color: option.options.fontColor,
        fontSize: option.options.fontSize * props.scale,
        fontWeight: 400,
    };
    return (
        <Draggable
            key={props.index}
            disabled={!props.editable}
            position={position}
            onMouseDown={(evt) => {
                evt.stopPropagation();
            }}
            onStart={() => {}}
            onDrag={(_evt, _data) => {
                setDrag(true);
            }}
            onStop={(_evt, data) => {
                if (drag) {
                    props.onTrackNewPerformance(elements.map);
                    let x = Math.max(data.x, 0);
                    let y = Math.max(data.y, 0);
                    let mapElement: MapElement = { ...props.mapElement! };
                    mapElement.tooltipVariables![props.index]!.options = {
                        ...mapElement.tooltipVariables![props.index]!.options,
                        x: x / props.scale,
                        y: y / props.scale,
                    };
                    props.onChange!(mapElement);
                    setDrag(false);
                }
            }}
        >
            <Resizable
                enable={
                    !props.editable
                        ? {
                              top: false,
                              right: false,
                              bottom: false,
                              left: false,
                              topRight: false,
                              bottomRight: false,
                              bottomLeft: false,
                              topLeft: false,
                          }
                        : {
                              top: true,
                              right: true,
                              bottom: true,
                              left: true,
                              topRight: true,
                              bottomRight: true,
                              bottomLeft: true,
                              topLeft: true,
                          }
                }
                onResizeStart={(evt) => {
                    evt.stopPropagation();
                }}
                onResizeStop={(_e, _direction, _ref, d) => {
                    props.onTrackNewPerformance(elements.map);
                    let width = itemSize.width + d.width;
                    let height = itemSize.height + d.height;
                    let mapElement: MapElement = { ...props.mapElement! };
                    mapElement.tooltipVariables![props.index]!.options = {
                        ...mapElement.tooltipVariables![props.index]!.options,
                        width: width / props.scale,
                        height: height / props.scale,
                    };
                    props.onChange!(mapElement);
                }}
                size={{
                    width: itemSize.width,
                    height: itemSize.height,
                }}
                style={{
                    overflow: "hidden",
                    top: 0,
                    left: 0,
                    border: props.editable ? "1px dashed black" : "none",
                    position: "absolute",
                }}
            >
                {!isUrl && (
                    <span style={textStyle} className="regular-text">
                        {showTitle
                            ? `${option.variable.label} : ${props.value}`
                            : `${props.value}`}
                    </span>
                )}
                {isImageUrl && (
                    <div className="flex-simple-column">
                        {showTitle && (
                            <span
                                style={{
                                    ...textStyle,
                                    display: "inline-block",
                                    height: undefined,
                                }}
                            >
                                {`${option.variable.label}`}
                            </span>
                        )}
                        <img
                            draggable={false}
                            alt=""
                            style={{
                                marginTop: "5px",
                                width: "100%",
                                height: "100%",
                            }}
                            src={props.value as string}
                        />
                    </div>
                )}
                {isUrl && !isImageUrl && (
                    <div className="flex-simple-column">
                        {showTitle && (
                            <span
                                style={{
                                    textAlign:
                                        option.options.textAlign ?? "left",
                                    display: "inline-block",
                                    width: itemSize.width,
                                    color: option.options.fontColor,
                                    fontSize:
                                        option.options.fontSize * props.scale,
                                }}
                                className="regular-text"
                            >
                                {`${option.variable.label}`}
                            </span>
                        )}

                        <TooltipVideoComponent
                            iframeTitle={`map-tooltip-iframe-${props.mapElementId}-${props.option.variable.label}-${props.index}`}
                            style={{
                                marginTop: "5px",
                            }}
                            width={itemSize.width}
                            height={
                                itemSize.height -
                                5 -
                                option.options.fontSize * props.scale * 1.4
                            }
                            src={props.value as string}
                        />
                    </div>
                )}
            </Resizable>
        </Draggable>
    );
}

// properties for tooltip
interface TooltipProps {
    scale?: number;
    editable?: boolean;
    onTrackNewPerformance: (element: string) => void;
    mapElement?: MapElement;
    mapElementId: string;
    onChange?: (map: Partial<MapElement>) => void;
    options?: TooltipOptions | null;
    variables?: (MapVariableOption | null)[];
    dataIndex: number;
    data: { [key: string]: Array<number | string | null> } | null;
    className?: string;
}

/*!
 * function TooltipContent renders content of tooltip according to selected
 * options as an array of TooltipItemContent components
 *
 */
export function TooltipContent(props: TooltipProps) {
    function isNullOrEmpty(value: any) {
        return value == null || value === "";
    }
    const tooltipVariables = useMemo(() => {
        return (
            props.variables ?? []
        ).filter((item): item is MapVariableOption => item != null);
    }, [props.variables]);
    let editable = props.editable ?? false;
    let scale = props.scale ?? 1;
    let widthOffsets: {
        x: number;
        y: number;
        width: number;
        height: number;
    }[] = [];
    let tooltipSize = {
        width: 0,
        height: 0,
    };
    let widthOffsetsMap: {
        [index: string]: number;
    } = {};
    if (!editable) {
        tooltipVariables.forEach((option, index) => {
            let value =
                props.data?.[option.variable.value.toString()]?.[
                    props.dataIndex
                ];
            let defaultY = tooltipSize.height / scale;
            let defaultTooltipRect = tooltipItemRect({
                scale: scale,
                editable: false,
                widthOffset: 0,
                defaultY: defaultY,
                index: index,
                onChange: props.onChange,
                onTrackNewPerformance: props.onTrackNewPerformance,
                mapElement: props.mapElement,
                mapElementId: props.mapElementId,
                option: option,
                value: isNullOrEmpty(value) ? "value" : value!,
            });
            if (isNullOrEmpty(value)) {
                let width = defaultTooltipRect.width / scale;
                let x = defaultTooltipRect.x / scale;
                let height = defaultTooltipRect.height / scale;
                let y = defaultTooltipRect.y / scale;
                widthOffsets.push({
                    width: width,
                    x: x,
                    height: height,
                    y: y,
                });
                return;
            }
            tooltipSize.width = Math.max(
                tooltipSize.width,
                defaultTooltipRect.x + defaultTooltipRect.width
            );
            tooltipSize.height = Math.max(
                tooltipSize.height,
                defaultTooltipRect.y + defaultTooltipRect.height
            );
        });
        tooltipSize = {
            width: 0,
            height: 0,
        };

        tooltipVariables.forEach((option, index) => {
            if (option == null) return;
            let value =
                props.data?.[option.variable.value.toString()]?.[
                    props.dataIndex
                ];
            if (isNullOrEmpty(value)) return;
            let defaultY = tooltipSize.height / scale;
            let defaultTooltipRect = tooltipItemRect({
                scale: scale,
                editable: false,
                widthOffset: 0,
                defaultY: defaultY,
                index: index,
                onChange: props.onChange,
                onTrackNewPerformance: props.onTrackNewPerformance,
                mapElement: props.mapElement,
                mapElementId: props.mapElementId,
                option: option,
                value: value ?? "",
            });
            let xPosition = defaultTooltipRect.x / scale;
            let yPosition = defaultTooltipRect.y / scale;
            let height = defaultTooltipRect.height / scale;
            widthOffsetsMap[index] = widthOffsets
                .filter(
                    (item) =>
                        item.x + item.width < xPosition &&
                        Math.abs(item.y - yPosition) < 5 &&
                        Math.abs(item.height + item.y - (height + yPosition)) <
                            5
                )
                .map((item) => item.width)
                .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                );
            tooltipSize.width = Math.max(
                tooltipSize.width,
                defaultTooltipRect.x + defaultTooltipRect.width
            );
            tooltipSize.height = Math.max(
                tooltipSize.height,
                defaultTooltipRect.y + defaultTooltipRect.height
            );
        });
    }
    tooltipSize = {
        width: 0,
        height: 0,
    };
    let items = tooltipVariables.map((option, index) => {
        let value =
            props.data?.[option.variable.value.toString()]?.[props.dataIndex];
        if (editable && isNullOrEmpty(value)) value = "value";
        if (!editable && isNullOrEmpty(value)) return null;
        let defaultY = tooltipSize.height / scale;
        let defaultTooltipRect = tooltipItemRect({
            scale: scale,
            editable: false,
            widthOffset: widthOffsetsMap[index] ?? 0,
            defaultY: defaultY,
            index: index,
            onChange: props.onChange,
            onTrackNewPerformance: props.onTrackNewPerformance,
            mapElement: props.mapElement,
            mapElementId: props.mapElementId,
            option: option,
            value: value ?? "",
        });
        let defaultHeight = defaultTooltipRect.height / scale;
        let defaultWidth = defaultTooltipRect.width / scale;
        let tooltipRect = tooltipItemRect({
            defaultHeight: defaultHeight,
            defaultWidth: defaultWidth,
            scale: scale,
            editable,
            widthOffset: widthOffsetsMap[index] ?? 0,
            defaultY: defaultY,
            index: index,
            onChange: props.onChange,
            onTrackNewPerformance: props.onTrackNewPerformance,
            mapElement: props.mapElement,
            mapElementId: props.mapElementId,
            option: option,
            value: value ?? "",
        });
        // already scaled;
        tooltipSize.width = Math.max(
            tooltipSize.width,
            tooltipRect.x + tooltipRect.width
        );
        tooltipSize.height = Math.max(
            tooltipSize.height,
            tooltipRect.y + tooltipRect.height
        );
        return (
            <TooltipItemContent
                defaultHeight={defaultHeight}
                defaultWidth={defaultWidth}
                defaultY={defaultY}
                scale={scale}
                key={index}
                editable={editable}
                widthOffset={widthOffsetsMap[index] ?? 0}
                index={index}
                onChange={props.onChange}
                onTrackNewPerformance={props.onTrackNewPerformance}
                mapElement={props.mapElement}
                mapElementId={props.mapElementId}
                option={option}
                value={value ?? ""}
            />
        );
    });
    tooltipSize.width += 5;
    tooltipSize.height += 5;
    return (
        <div
            style={{
                minWidth: tooltipSize.width,
                minHeight: tooltipSize.height,
                maxWidth: tooltipSize.width,
                maxHeight: tooltipSize.height,
                backgroundColor: props.options?.fillColor ?? "white",
                position: "relative",
            }}
            className={props.className}
        >
            {editable && (
                <div
                    style={{
                        position: "absolute",
                        right: -30,
                        top: 0,
                    }}
                >
                    <ColorPicker
                        enableAlpha
                        value={props.options?.fillColor ?? "white"}
                        onChange={(value) => {
                            props.onTrackNewPerformance(elements.map);
                            props.onChange!({
                                ...props.mapElement,
                                tooltipOptions: {
                                    width: tooltipSize.width,
                                    height: tooltipSize.height,
                                    fillColor: value,
                                },
                            });
                        }}
                    />
                </div>
            )}
            {items}
        </div>
    );
}

const visibleMarkersLimit = 1000;

interface MarkerClusterWrapperProps {
    coordinates: MapElement["coordinates"];
    usesCoordinates: MapElement["usesCoordinates"];
    displayMode: MapTooltipDisplayModeOption | undefined;
    tooltipVariables?: (MapVariableOption | null)[];
    markerIcon?: string | null; // base64 image
    markerColor?: string | null;
    markerColorVariableIndex?: number | null;
    varyMarkerColorByVariable?: boolean; // default: false
    heatMap?: VariableOption | null;
    lockMap: boolean;
    minZoomLevel: number;
    data: { [key: string]: Array<number | string | null> } | null;
    colorVariableValueToIndex: Map<string | number | null, number>;
    scale: number;
    onTrackNewPerformance: (element: string) => void;
    onShowPopup: (index: number) => void;
    onShowHoverTooltip: (
        index: number | null,
        point?: {
            x: number;
            y: number;
        }
    ) => void;
    mapElementId: string;
}

// This component is a wrapper for MarkerClusterGroup that
// renders clusters on a map
const MarkerClusterGroupWrapper = React.forwardRef(
    (props: MarkerClusterWrapperProps, ref) => {
        const markerGroup = useMemo(() => {
            if (props.data == null) return null;
            let latIndex: string | undefined;
            let lonIndex: string | undefined;
            if (props.usesCoordinates) {
                latIndex = props.coordinates?.latitude?.value.toString();
                lonIndex = props.coordinates?.longitude?.value.toString();
            } else {
                latIndex = "%lat";
                lonIndex = "%lon";
            }
            //we prepare marker for each coordinate and
            //wrap markers into MarkerClusterGroup
            //
            let markers: Array<JSX.Element>;
            if (!latIndex || !lonIndex) {
                markers = [];
            } else {
                markers = props.data[latIndex]
                    ?.slice(0, visibleMarkersLimit)
                    .map((_, index) => (
                        <MarkerWrapper
                            {...props}
                            key={index}
                            index={index}
                            latIndex={latIndex!}
                            lonIndex={lonIndex!}
                        />
                    ));
            }
            return (
                <MarkerClusterGroup
                    ref={ref}
                    onClick={(evt: any) => {
                        if (props.lockMap) {
                            if (evt.layer._group._spiderfied != null) {
                                evt.layer.unspiderfy();
                            } else {
                                evt.layer.spiderfy();
                            }
                        }
                    }}
                    spiderfyDistanceMultiplier={3}
                    key={Date.now()}
                    maxClusterRadius={30}
                    zoomToBoundsOnClick={!props.lockMap}
                    showCoverageOnHover={false}
                    removeOutsideVisibleBounds
                    disableClusteringAtZoom={17}
                    chunkedLoading
                >
                    {markers}
                </MarkerClusterGroup>
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            props.data,
            props.coordinates,
            props.displayMode,
            props.tooltipVariables,
            props.markerIcon,
            props.markerColor,
            props.markerColorVariableIndex,
            props.varyMarkerColorByVariable,
            props.heatMap,
            props.lockMap,
            props.onShowPopup,
            props.onShowHoverTooltip,
            props.onTrackNewPerformance,
            props.colorVariableValueToIndex,
            props.usesCoordinates,
            props.scale,
            props.minZoomLevel,
            ref,
        ]);
        return markerGroup;
    }
);

interface MarkerWrapperProps extends MarkerClusterWrapperProps {
    latIndex: string;
    lonIndex: string;
    index: number;
}

/*!
 * function MarkerWrapper renders marker on a map
 * If we have variables that we need to display always, then tooltip
 * will be rendered within this component;
 * If we have variables displayed on hover or on click, we are using
 * callbacks - onShowPopup or onShowHoverTooltip respectively
 */
function MarkerWrapper(props: MarkerWrapperProps) {
    if (props.data == null) return null;

    const mouseEventHandlers: any = {};
    if (
        props.displayMode?.value === MapTooltipDisplayMode.onClick &&
        props.tooltipVariables?.length !== 0
    ) {
        mouseEventHandlers.click = () => {
            props.onShowPopup(props.index);
        };
    } else if (props.displayMode?.value === MapTooltipDisplayMode.onHover &&
        props.tooltipVariables?.length !== 0
    ) {
        mouseEventHandlers.mouseover = (evt: Leaflet.LeafletMouseEvent) => {
            props.onShowHoverTooltip(
                props.index,
                evt.containerPoint
            );
        };

        mouseEventHandlers.mouseout = () => {
            props.onShowHoverTooltip(null);
        };
    }

    let markerColor: string | undefined | null = undefined;
    if (props.varyMarkerColorByVariable) {
        // If props.markerColorVariableIndex.toString() is not in props.data
        // then the data was not updated yet
        if (
            props.markerColorVariableIndex != null &&
            props.markerColorVariableIndex.toString() in props.data
        ) {
            let colorIndex:
                | number
                | undefined = props.colorVariableValueToIndex.get(
                props.data[props.markerColorVariableIndex.toString()][
                    props.index
                ]
            );
            if (colorIndex != null) {
                markerColor = colorList[colorIndex];
            }
        }
    } else {
        markerColor = props.markerColor;
    }
    let position = [
        Number((props.data?.[props.latIndex][props.index] ?? NaN) as number),
        Number((props.data?.[props.lonIndex][props.index] ?? NaN) as number),
    ];
    if (Number.isNaN(position[0]) || Number.isNaN(position[1])) return null;
    return (
        <Marker
            key={props.index}
            eventHandlers={mouseEventHandlers}
            icon={
                props.heatMap == null
                    ? markerIcon(props.markerIcon, markerColor)
                    : markerIconTransparent
            }
            position={[position[0], position[1]]}
        >
            {props.displayMode?.value === MapTooltipDisplayMode.always &&
                props.tooltipVariables?.length !== 0 && (
                    <Tooltip
                        direction="bottom"
                        permanent={true}
                        ref={(ref) => {
                            if (ref != null && ref.getElement() != null) {
                                ref.getElement()!.style.pointerEvents = "auto";
                            }
                        }}
                    >
                        <TooltipContent
                            scale={props.scale}
                            onTrackNewPerformance={props.onTrackNewPerformance}
                            dataIndex={props.index}
                            data={props.data}
                            variables={props.tooltipVariables ?? []}
                            mapElementId={props.mapElementId}
                        />
                    </Tooltip>
                )}
        </Marker>
    );
}

interface ListenerProps {
    mapElement: MapElement;
    onChange: (mapElement: Partial<MapElement>) => void;
}

/*!
 * funciton mapListener checks if center or zoom of map are changed
 * and saves changes to MapElement
 */
function MapListener(props: ListenerProps) {
    const mapEvents = useMapEvents({
        zoomend: () => {
            let zoom = mapEvents.getZoom();
            let center = mapEvents.getCenter();
            props.onChange({
                zoom: zoom,
                center: { lat: center.lat, lng: center.lng },
            });
        },
        moveend: () => {
            let zoom = mapEvents.getZoom();
            let center = mapEvents.getCenter();
            props.onChange({
                zoom: zoom,
                center: { lat: center.lat, lng: center.lng },
            });
        },
    });
    return null;
}

// Currently unused
// const geoJsonSizeLimit: number = 50 * 1024 * 1024;

function HeatMap(props: { data: Array<[number, number, number]> }) {
    const map = useMap();
    useEffect(() => {
        if (props.data.length !== 0) {
            let newHeatLayer = Leaflet.heatLayer(props.data, {
                minOpacity: 0.7,
                blur: 20,
                gradient: {
                    0.0: "lime",
                    0.6: "yellow",
                    0.9: "red",
                },
            }).addTo(map);
            return () => {
                newHeatLayer.remove();
            };
        }
    }, [props.data, map]);
    return null;
}

/*!
 * MapElementView is a root class that renders
 * wizard for creation of map and result map
 */
export default class MapElementView extends Component<Props, State> {
    rootRef: React.RefObject<HTMLDivElement>;
    mapRef: Leaflet.Map | null;
    clusterRef = React.createRef<MarkerClusterGroup>();
    hamburgerMenuRef = React.createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);
        this.state = {
            updating: false,
            operationErrorMessage: null,
            operationStatus: OperationStatus.NotStarted,
            popupMarkerIndex: null,
            hoverInfo: null,
            colorVariableValues: [],
            colorVariableValueToIndex: new Map(),
            geoJsonSizes: {},
            popupStatus: undefined,
            message: undefined,
            hamburgerMenuOpened: false,
        };
        this.rootRef = React.createRef();
        this.mapRef = null;
        this.showPopup = this.showPopup.bind(this);
        this.showHoverTooltip = this.showHoverTooltip.bind(this);
    }

    public componentDidMount(): void {
        if (
            this.props.mapElement.varyMarkerColorByVariable &&
            this.props.mapElement.markerColorVariableIndex != null &&
            this.props.mapElement.markerIcon == null
        ) {
            this.getColorVariableValues();
        }

        let geoJsonSizes: { [key: string]: number } = {};
        for (let [key, item] of Object.entries(
            this.props.mapElement.geoJsonFiles ?? {}
        )) {
            if (item != null) {
                geoJsonSizes[key] = JSON.stringify(item).length;
            }
        }
        this.setState({
            geoJsonSizes: geoJsonSizes,
        });
    }

    public componentDidUpdate(prevProps: Props) {
        const { canvasViewMode } = this.props.canvasTreeStore;
        // Update geoJsonSizes
        let prevGeoJsonFiles = prevProps.mapElement.geoJsonFiles ?? {};
        let geoJsonSizes: { [key: string]: number } = {};
        let changed = false;
        for (let [key, item] of Object.entries(
            this.props.mapElement.geoJsonFiles ?? {}
        )) {
            let prevItem = prevGeoJsonFiles[key];
            if (item == null && prevItem != null) {
                geoJsonSizes[key] = 0;
                changed = true;
            } else if (item != null && prevItem == null) {
                geoJsonSizes[key] = JSON.stringify(item).length;
                changed = true;
            }
        }
        if (changed) {
            this.setState((state) => ({
                geoJsonSizes: {
                    ...state.geoJsonSizes,
                    ...geoJsonSizes,
                },
            }));
        }

        // We are invalidating size of leaflet map if parent component is
        // rescaled or resized
        if (
            (this.props.mapElement.nodeSize[canvasViewMode].width !== prevProps.mapElement.nodeSize[canvasViewMode].width ||
                this.props.mapElement.nodeSize[canvasViewMode].height !== prevProps.mapElement.nodeSize[canvasViewMode].height) &&
            prevProps.scale === this.props.scale
        ) {
            if (this.mapRef != null) {
                let oldBounds = this.mapRef.getBounds();
                this.mapRef.invalidateSize();
                if (oldBounds != null) {
                    this.mapRef.fitBounds(oldBounds);
                }
            }
        }
        let minZoomLevel =
            this.props.mapElement.colorOptions?.minZoomLevel ?? 0;
        if (this.mapRef != null) {
            this.mapRef.setMinZoom(minZoomLevel);
        }

        if (this.props.live !== prevProps.live) {
            let lockMap = this.props.mapElement.colorOptions?.lockMap ?? false;
            lockMap = lockMap && this.props.live;
            if (this.clusterRef.current != null) {
                ((this.clusterRef.current as any)
                    .options as any).zoomToBoundsOnClick = !lockMap;
            }
            if (this.mapRef != null) {
                if (lockMap) {
                    this.mapRef.touchZoom.disable();
                    this.mapRef.doubleClickZoom.disable();
                    this.mapRef.boxZoom.disable();
                    this.mapRef.scrollWheelZoom.disable();
                    this.mapRef.dragging.disable();
                    this.mapRef.tap?.disable();
                    this.mapRef.keyboard.disable();
                    this.mapRef.zoomControl.remove();
                } else {
                    this.mapRef.touchZoom.enable();
                    this.mapRef.doubleClickZoom.enable();
                    this.mapRef.boxZoom.enable();
                    this.mapRef.scrollWheelZoom.enable();
                    this.mapRef.dragging.enable();
                    this.mapRef.tap?.enable();
                    this.mapRef.keyboard.enable();
                    if (
                        this.mapRef.zoomControl == null ||
                        (this.mapRef.zoomControl as any)._map == null
                    ) {
                        let zoomControl = new Leaflet.Control.Zoom();
                        this.mapRef.zoomControl = zoomControl;
                        this.mapRef.addControl(zoomControl);
                    }
                }
            }
        }
        if (
            this.props.mapElement.varyMarkerColorByVariable &&
            this.props.mapElement.markerColorVariableIndex != null &&
            this.props.mapElement.markerIcon == null &&
            this.props.mapElement.markerColorVariableIndex !==
                prevProps.mapElement.markerColorVariableIndex
        ) {
            this.getColorVariableValues();
        }
    }

    /*!
     * function getColorVariableValues obtains values of this.props.mapElement.markerColorVariableIndex;
     * Called if this.props.mapElement.varyMarkerColorByVariable is true
     */
    private getColorVariableValues(): void {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                items?: (string | number | null)[];
            }>("/api/e/autocomplete", {
                variable_index: this.props.mapElement.markerColorVariableIndex,
                data_table_idx: this.props.mapElement.dataScope?.value,
                starts_with: "",
                module_id: this.props.currentModuleId ?? remoteModuleId,
            })
            .then((response) => {
                if (response.data.success && response.data.items != null) {
                    let colorVariableValueToIndex: Map<
                        string | number | null,
                        number
                    > = new Map();
                    for (let i = 0; i < response.data.items.length; ++i) {
                        colorVariableValueToIndex.set(
                            response.data.items[i],
                            i
                        );
                    }
                    this.setState({
                        colorVariableValues: response.data.items,
                        colorVariableValueToIndex: colorVariableValueToIndex,
                    });
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /*!
     * function showPopup called if we clock some marker and
     * have variables displayed on click
     */
    private showPopup(index: number) {
        this.setState({ popupMarkerIndex: index });
    }

    /*!
     * function showHoverTooltip called if we hover some marker and
     * have variables displayed on hover
     */
    private showHoverTooltip(
        index: number | null,
        point?: { x: number; y: number }
    ) {
        if (index != null) {
            this.setState({
                hoverInfo: {
                    index: index,
                    x: point!.x,
                    y: point!.y,
                },
            });
        } else this.setState({ hoverInfo: null });
    }

    /*!
     * function buildInnerItem renders main view of component
     */
    private buildInnerItem(): JSX.Element | null {
        //If step is initial and map element is done
        //then we render map element
        let lockMap = this.props.mapElement.colorOptions?.lockMap ?? false;
        lockMap = lockMap && this.props.live;
        let minZoomLevel =
            this.props.mapElement.colorOptions?.minZoomLevel ?? 0;

        // To make the map less blurry on higher scales
        const mapViewScale = 1.5;
        return (
            <div>
                <div
                    style={{
                        width: `calc((100% - 20px) / ${this.props.scale} * ${mapViewScale})`,
                        height: `calc((100% - 20px) / ${this.props.scale} * ${mapViewScale})`,
                        // If position is not absolute, then scaleY
                        // would consider the parent's height, which
                        // is NOT the desired behavior
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        transform: `scale(${this.props.scale / mapViewScale})`,
                        transformOrigin: "top left",
                    }}
                >
                    {this.props.loading && (
                        <div
                            style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                zIndex: 2,
                                backgroundColor: "rgba(0, 0, 0, .2)",
                            }}
                        >
                            <DualRing
                                color="#323232"
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            />
                        </div>
                    )}
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            zIndex: 1,
                        }}
                    >
                        <MapContainer
                            worldCopyJump
                            minZoom={minZoomLevel}
                            tap={!lockMap}
                            keyboard={!lockMap}
                            touchZoom={!lockMap}
                            doubleClickZoom={!lockMap}
                            scrollWheelZoom={!lockMap}
                            boxZoom={!lockMap}
                            dragging={!lockMap}
                            zoomControl={!lockMap}
                            whenCreated={(mapInstance) => {
                                this.mapRef = mapInstance;
                            }}
                            className="element-leaflet-map"
                            center={
                                this.props.mapElement.center
                                    ? [
                                          this.props.mapElement.center.lat,
                                          this.props.mapElement.center.lng,
                                      ]
                                    : [0, 0]
                            }
                            zoom={this.props.mapElement.zoom ?? 1}
                        >
                            <MapListener
                                mapElement={this.props.mapElement}
                                onChange={this.props.onChange}
                            />
                            <HeatMap data={this.props.heatMapData} />
                            {Object.values(
                                this.props.mapElement.geoJsonFiles ?? {}
                            )
                                .filter(
                                    (
                                        item
                                    ): item is {
                                        name: string;
                                        contents: GeoJsonObject;
                                        color?: string;
                                    } => item != null
                                )
                                .map(({ contents, color }, index) => {
                                    return (
                                        <GeoJSON
                                            key={`map-geojson-${index}`}
                                            data={contents}
                                            style={{
                                                fillColor:
                                                    color ??
                                                    colorList[
                                                        index % colorList.length
                                                    ],
                                                fillOpacity: 0.4,
                                                color:
                                                    color ??
                                                    colorList[
                                                        index % colorList.length
                                                    ],
                                                opacity: 1,
                                                weight: 2,
                                            }}
                                            pointToLayer={(feature, latlng) => {
                                                return Leaflet.marker(latlng, {
                                                    icon: markerIconWithSymbol(
                                                        this.props.mapElement
                                                            .markerIcon,
                                                        feature.properties[
                                                            "marker-color"
                                                        ] ??
                                                            color ??
                                                            colorList[
                                                                index %
                                                                    colorList.length
                                                            ],
                                                        feature.properties[
                                                            "marker-symbol"
                                                        ]
                                                    ),
                                                });
                                            }}
                                        />
                                    );
                                })}
                            <TileFilterLayer
                                attribution='&copy; <a href="http://osm.org/copyright" spellcheck="false">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                filter={
                                    this.props.mapElement.colorOptions
                                        ?.grayscale
                                        ? ["grayscale:100%", "invert: 100%"]
                                        : []
                                }
                            />
                            <MarkerClusterGroupWrapper
                                mapElementId={this.props.mapElementId}
                                coordinates={this.props.mapElement.coordinates}
                                usesCoordinates={
                                    this.props.mapElement.usesCoordinates
                                }
                                displayMode={this.props.mapElement.displayMode}
                                tooltipVariables={this.props.mapElement.tooltipVariables}
                                markerIcon={this.props.mapElement.markerIcon}
                                markerColor={this.props.mapElement.markerColor}
                                markerColorVariableIndex={
                                    this.props.mapElement
                                        .markerColorVariableIndex
                                }
                                varyMarkerColorByVariable={
                                    this.props.mapElement
                                        .varyMarkerColorByVariable
                                }
                                heatMap={this.props.mapElement.heatMap}
                                scale={mapViewScale}
                                data={this.props.data}
                                colorVariableValueToIndex={
                                    this.state.colorVariableValueToIndex
                                }
                                onTrackNewPerformance={
                                    this.props.onTrackNewPerformance
                                }
                                onShowPopup={this.showPopup}
                                onShowHoverTooltip={this.showHoverTooltip}
                                ref={this.clusterRef}
                                lockMap={lockMap}
                                minZoomLevel={minZoomLevel}
                            />
                        </MapContainer>
                        {this.state.popupMarkerIndex != null && (
                            <OutsideAlerter
                                onReject={() => {
                                    this.setState({
                                        popupMarkerIndex: null,
                                    });
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: 1000,
                                        opacity: 0.9,
                                        boxShadow: "0 1px 3px rgba(0,0,0,.4)",
                                        backgroundColor: "white",
                                        userSelect: "none",
                                    }}
                                    onDrag={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onDragStart={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onDragEnd={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <TooltipContent
                                        options={this.props.mapElement.tooltipOptions}
                                        mapElementId={this.props.mapElementId}
                                        onTrackNewPerformance={
                                            this.props.onTrackNewPerformance
                                        }
                                        scale={mapViewScale}
                                        onChange={this.props.onChange}
                                        mapElement={this.props.mapElement}
                                        dataIndex={this.state.popupMarkerIndex}
                                        data={this.props.data}
                                        variables={this.props.mapElement.tooltipVariables}
                                    />
                                </div>
                            </OutsideAlerter>
                        )}
                        {this.state.hoverInfo != null && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: this.state.hoverInfo.y,
                                    left: this.state.hoverInfo.x,
                                    zIndex: 1000,
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onDrag={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onDragStart={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                onDragEnd={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <div
                                    style={{
                                        opacity: 0.9,
                                        boxShadow: "0 1px 3px rgba(0,0,0,.4)",
                                        padding: "6px",
                                        borderRadius: "3px",
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        borderColor: "white",
                                        backgroundColor: "white",
                                        userSelect: "none",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onDrag={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onDragStart={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                    onDragEnd={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <TooltipContent
                                        options={this.props.mapElement.tooltipOptions}
                                        mapElementId={this.props.mapElementId}
                                        onTrackNewPerformance={
                                            this.props.onTrackNewPerformance
                                        }
                                        scale={mapViewScale}
                                        onChange={this.props.onChange}
                                        mapElement={this.props.mapElement}
                                        dataIndex={this.state.hoverInfo.index}
                                        data={this.props.data}
                                        variables={this.props.mapElement.tooltipVariables}
                                    />
                                </div>
                            </div>
                        )}
                        {this.props.mapElement.varyMarkerColorByVariable &&
                            this.state.colorVariableValues.length !== 0 &&
                            this.props.mapElement.markerIcon == null && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-start",
                                        width: "100%",
                                        height: "100%",
                                        zIndex: 999,
                                        pointerEvents: "none",
                                    }}
                                >
                                    <div
                                        className="element-leaflet-map"
                                        style={{
                                            marginTop: "30px",
                                            marginRight: "30px",
                                            maxHeight: "calc(100% - 47px)",
                                            opacity: 0.9,
                                            boxShadow:
                                                "0 1px 3px rgba(0,0,0,.4)",
                                            padding: "6px",
                                            borderRadius: "3px",
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            borderColor: "white",
                                            backgroundColor: "white",
                                            userSelect: "none",
                                            display: "flex",
                                            flexDirection: "column",
                                            overflowY: "auto",
                                            pointerEvents: "auto",
                                        }}
                                    >
                                        {this.state.colorVariableValues.map(
                                            (value, index) => (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        marginTop:
                                                            index !== 0
                                                                ? "5px"
                                                                : undefined,
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "12px",
                                                            height: "12px",
                                                            backgroundColor:
                                                                colorList[
                                                                    index
                                                                ],
                                                            borderRadius: "6px",
                                                        }}
                                                    />
                                                    <span
                                                        className="regular-text"
                                                        style={{
                                                            marginLeft: "5px",
                                                            color: "black",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        {value}
                                                    </span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }

    private buildContent(): JSX.Element {
        return (
            <div
                style={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <div style={{ width: "100%" }}>{this.buildInnerItem()}</div>
            </div>
        );
    }

    public render(): JSX.Element {
        let colorOptions: ColorOptions = this.props.mapElement.colorOptions ?? {
            borderShadow: false,
            fillColor: dataScienceElementsStyle.contentColor,
            borderColor: dataScienceElementsStyle.borderColor,
        };
        return (
            <>
                <div
                    ref={this.rootRef}
                    className="dashboard-rect-canvas dashboard-rect-canvas-focus"
                    tabIndex={0}
                    style={{
                        boxShadow: colorOptions.borderShadow
                            ? "0 6px 13px 0 rgba(21, 33, 56, 0.53)"
                            : "none",
                        backgroundColor: colorOptions.fillColor,
                        border: colorOptions.borderColor
                            ? `1px solid ${colorOptions.borderColor}`
                            : undefined,
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                    }}
                    onContextMenu={this.props.onContextMenu}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        {this.buildContent()}
                    </div>
                    {!this.props.live &&
                        !this.props.frozen &&
                        (this.props.hovered ||
                            this.state.hamburgerMenuOpened) && (
                            <div
                                ref={this.hamburgerMenuRef}
                                style={{
                                    position: "absolute",
                                    width: 15 * this.props.scale,
                                    height: "100%",
                                    top: 0,
                                    right: -15 * this.props.scale,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <HamburgerMenu
                                    onToggle={(show) => {
                                        this.setState({
                                            hamburgerMenuOpened: show,
                                        });
                                    }}
                                    onTouchStart={() => {
                                        this.setState({
                                            hamburgerMenuOpened: true,
                                        });
                                    }}
                                    sharedPolicy={this.props.sharedPolicy}
                                    scale={this.props.scale}
                                    elementId={this.props.mapElementId}
                                    onDelete={this.props.onDelete}
                                    onEdit={(evt) => {
                                        evt.stopPropagation();
                                        if (
                                            this.props.sharedPolicy ===
                                            CanvasSharedPolicy.NotShared
                                        ) {
                                            this.props.toggleOpenEditMenu(
                                                this.props.mapElementId,
                                                this.mapRef?.getZoom()
                                            );
                                        }
                                        if (
                                            this.props.sharedPolicy ===
                                            CanvasSharedPolicy.SharedSlideUnAuth
                                        )
                                            goToInternalLink("/");
                                    }}
                                />
                            </div>
                        )}
                </div>
            </>
        );
    }
}
