import "common/styles/App.css";

import React, { useRef, Component } from "react";
import { mainStyle } from "common/MainStyle";
import { UserIcon } from "common/UserIcon";
import { useDrag, useDrop } from "react-dnd";
import FindingItem from "common/insights_components/FindingItem";
import { isValidConfig } from "common/PathConfigVersion";
import { Permission } from "common/Permissions";

function KpiGridCard(props) {
	let {
		canWrite,
		loading,
		itemHeight,
		item,
		index,
		acceptDrag,
		acceptDrop,
		onCrossClicked,
		onShareClicked,
		onCardsSwapped,
		onReloadClicked,
	} = props;
	const ref = useRef(null);
	// [{ isDragging }, drag]

	const drag = useDrag({
		type: acceptDrag,
		item: {
			content: item,
		},
		end: (item, monitor) => {},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})[1];

	const drop = useDrop({
		accept: acceptDrop,
		drop(otherItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = otherItem.content.id;
			const hoverIndex = item.id;
			if (dragIndex === hoverIndex) {
				return;
			}
			onCardsSwapped(item, otherItem.content);
		},
	})[1];
	if (canWrite) drag(drop(ref));
	let hasConfig =
		item.contents.finding && isValidConfig(item.contents.finding.config);
	return (
		<div
			ref={ref}
			key={item.id}
			className="dashboard-rect flex-column"
			style={{
				height: itemHeight,
				cursor: "pointer",
			}}
		>
			<div className="my-row" style={{ height: "40px", width: "100%" }}>
				<div
					className="my-row"
					style={{
						alignSelf: "flex-end",
						marginLeft: "21px",
						marginTop: "16px",
					}}
				>
					{canWrite && (
						<div
							title={hasConfig ? "Update card" : undefined}
							style={{
								opacity: hasConfig ? 1 : 0.3,
							}}
							onClick={(evt) => {
								evt.stopPropagation();
								if (hasConfig) onReloadClicked();
							}}
						>
							<img
								alt=""
								src="/dist/img/insights/insights_reload.png"
								style={{ cursor: "pointer" }}
							/>
						</div>
					)}
					<div
						className="flex-simple-column"
						style={{
							marginLeft: "5px",
							justifyContent: "flex-end",
						}}
					>
						<span
							style={{
								minHeight: "14px",
								color: mainStyle.getPropertyValue(
									"--content-primary-text-color"
								),
								fontFamily: "Arial",
								fontSize: 10,
							}}
						>
							{"Stream"}
						</span>
						<span
							style={{
								color: mainStyle.getPropertyValue(
									"--content-secondary-text-color"
								),
								fontFamily: "Arial",
								fontSize: 10,
							}}
						>
							{item.update_time || ""}
						</span>
					</div>
				</div>
				<div style={{ flexGrow: 1 }} />
				{canWrite && (
					<div
						title={"Delete card"}
						style={{
							alignSelf: "flex-end",
							marginRight: "18px",
							marginTop: "16px",
						}}
						onClick={(evt) => {
							evt.stopPropagation();
							onCrossClicked();
						}}
					>
						<img
							alt=""
							src="/dist/img/insights/insights_remove.png"
							style={{ cursor: "pointer" }}
						/>
					</div>
				)}
			</div>
			<div
				style={{
					width: "100%",
					paddingLeft: "10px",
					paddingRight: "10px",
					marginTop: "10px",
					marginBottom: "10px",
				}}
			>
				<input
					className="like-card-title"
					placeholder="Insert KPI Stream title"
					style={{
						maxHeight: "45px",
						width: "100%",
						textAlign: "center",
					}}
					onClick={(evt) => {
						evt.stopPropagation();
					}}
					onChange={(evt) => {
						let title = evt.target.value;
						props.onEdit(index, title, false);
					}}
					onBlur={(evt) => {
						let title = evt.target.value;
						props.onEdit(index, title, true);
					}}
					onKeyDown={(evt) => {
						if (evt.which === 13 && !evt.shiftKey) {
							evt.preventDefault();
							evt.target.blur();
						}
					}}
					value={item.contents.title}
				/>
			</div>
			{loading ? (
				<div
					className="element"
					style={{
						paddingLeft: "20px",
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: itemHeight - 150,
					}}
				>
					<span className="big-title-span">{"LOADING"}</span>
				</div>
			) : (
				<div
					className="element"
					style={{
						paddingLeft: "20px",
						paddingRight: "20px",
						overflow: "auto",
						height: itemHeight - 150,
					}}
				>
					<FindingItem
						finding={item.contents.finding}
						insightId={item.id}
					/>
				</div>
			)}
			<div style={{ flexGrow: 1 }} />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginLeft: "21px",
					marginBottom: "14px",
				}}
			>
				{item.user_data && (
					<UserIcon
						width="22px"
						height="22px"
						fontSize={10}
						user={item.user_data}
						iconUrl={item.user_data.icon_url}
					/>
				)}
				<div
					className="flex-simple-column"
					style={{ marginLeft: "5px" }}
				>
					<span
						style={{
							minHeight: "14px",
							color: mainStyle.getPropertyValue(
								"--content-primary-text-color"
							),
							fontFamily: "Arial",
							fontSize: 10,
						}}
					>
						{item.user_data
							? item.user_data.first_name
									.concat(" ")
									.concat(item.user_data.last_name)
							: ""}
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--content-secondary-text-color"
							),
							fontFamily: "Arial",
							fontSize: 10,
						}}
					>
						{item.creation_time || ""}
					</span>
				</div>
				<div style={{ flexGrow: 1 }} />
				<div
					title={"Share card"}
					style={{ marginRight: "18px" }}
					onClick={(evt) => {
						evt.stopPropagation();
						onShareClicked();
					}}
				>
					<img
						alt=""
						src="/dist/img/insights/insights_share.png"
						style={{ cursor: "pointer" }}
					/>
				</div>
			</div>
		</div>
	);
}

class KPIContent extends Component {
	constructor(props) {
		super(props);
		this.buildKpiStreamsGrid = this.buildKpiStreamsGrid.bind(this);
	}

	componentDidMount() {}

	componentWillUnmount() {}

	buildKpiStreamsGrid() {
		let canWrite = this.props.permission > Permission.ReadOnly;
		if (typeof this.props.kpiStreams === "undefined") return null;
		let kpiStreams = Array.from(this.props.kpiStreams);

		while (kpiStreams.length < 6) {
			kpiStreams.push(null);
		}
		let itemHeight = 450;
		let grid = kpiStreams.map((item, index) => {
			return item ? (
				<KpiGridCard
					canWrite={canWrite}
					loading={this.props.updatingStreams.includes(item.id)}
					itemHeight={itemHeight}
					acceptDrag={"kpi_stream"}
					acceptDrop={"kpi_stream"}
					key={item.id}
					item={item}
					index={index}
					onCrossClicked={() => {
						this.props.onKpiStreamDelete(item);
					}}
					onReloadClicked={() => {
						this.props.onKpiStreamReload(item);
					}}
					onCardsSwapped={(item, other) => {
						this.props.onKpiStreamsSwapped(item, other);
					}}
					onEdit={(index, title, update) => {
						this.props.onKpiStreamTitleUpdate(index, title, update);
					}}
					onShareClicked={() => {
						this.props.onKpiStreamShare(item);
					}}
				/>
			) : (
				<div
					className="flex-column"
					style={{
						border: "2px dashed rgba(101,125,149, 0.2)",
						borderRadius: "5px",
						height: itemHeight,
						cursor: "pointer",
					}}
				/>
			);
		});
		return grid;
	}

	render() {
		return (
			<div
				style={{
					gridTemplateColumns: "repeat(3, calc(33% - 20px))",
					gap: "20px",
					display: "grid",
				}}
			>
				{this.buildKpiStreamsGrid()}
			</div>
		);
	}
}

export default KPIContent;
