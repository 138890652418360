import React from "react";
import { observer } from "mobx-react";
import NotificationStore from "common/notifications/NotificationStore";
import styles from "./NotificationPopup.module.css";
import NotificationItem from "./NotificationItem";

export default observer(function NotificationPopup() {
	const notifications = NotificationStore.notificationsState;

	return (
		<div className={styles.container}>
			<span className={styles.mainTextStyle}>Notifications</span>
			{notifications.map((notification) => (
				<NotificationItem notification={notification} />
			))}
		</div>
	);
});
