import React from "react";
import { mainStyle } from "common/MainStyle";

export default function VariablesSelector(props) {
	let variableName = props.variableName;
	let variablesName = props.variablesName;
	let value = props.value;
	let values = props.values;
	let multiselection = props.multiselection || false;
	let grid = props.grid;
	let aliases = props.aliases;
	let optional = props.optional;
	if (grid)
		return (
			<div
				style={{
					marginTop: 20,
					display: "grid",
					gridTemplateColumns: "repeat(4, 1fr)",
					gridRowGap: "20px",
					gridColumnGap: "20px",
				}}
			>
				{values.map((variable, index) => {
					let selected = false;
					if (multiselection) selected = value.includes(variable);
					else selected = variable === value;
					let journeyVariableStyle = "journey-variable".concat(
						selected ? "-selected" : ""
					);
					let journeyVariableSmallStyle = "journey-small-variable".concat(
						selected ? "-selected" : ""
					);

					return (
						<div
							onClick={() => {
								if (optional && selected) {
									props.onChange(
										variableName,
										variablesName,
										null
									);
								} else {
									props.onChange(
										variableName,
										variablesName,
										index
									);
								}
							}}
							key={index}
							className={"my-row ".concat(journeyVariableStyle)}
							style={{
								justifyContent: "space-between",
								height: "56px",
								width: "250px",
								cursor: "pointer",
							}}
						>
							<div
								className="my-row"
								style={{ alignItems: "center" }}
							>
								<div
									className={journeyVariableSmallStyle}
									style={{
										margin: 10,
										height: "28px",
										width: "26px",
									}}
								>
									<div
										className="center-container"
										style={{
											height: "100%",
											alignItems: "center",
										}}
									>
										<span
											style={{
												fontFamily: "Roboto",
												fontSize: "20px",
												lineHeight: "23px",
												color: selected
													? "#20293C"
													: mainStyle.getPropertyValue(
															"--selectors-text-color"
													  ),
												fontWeight: 300,
											}}
										>
											{String.fromCharCode(65 + index)}
										</span>
									</div>
								</div>
								<span
									style={{
										textAlign: "center",
										wordBreak: "break-word",
										fontFamily: "Roboto",
										fontSize: "20px",
										lineHeight: "23px",
										color: selected
											? "#39F"
											: mainStyle.getPropertyValue(
													"--selectors-text-color"
											  ),
										fontWeight: 300,
										display: "block",
									}}
								>
									{variable || "Basic"}
								</span>
							</div>
							{selected ? (
								<div
									style={{
										margin: 20,
										alignItems: "center",
										display: "flex",
									}}
								>
									<img
										alt=""
										src="/dist/img/data-exploration/icon_checkbox.png"
									/>
								</div>
							) : (
								<div style={{ height: "27px" }} />
							)}
						</div>
					);
				})}
			</div>
		);

	return (
		<div className="my-row">
			{values.map((variable, index) => {
				let selected = false;
				if (multiselection) selected = value.includes(variable);
				else selected = variable === value;
				let journeyVariableStyle = "journey-variable".concat(
					selected ? "-selected" : ""
				);
				let journeyVariableSmallStyle = "journey-small-variable".concat(
					selected ? "-selected" : ""
				);

				return (
					<div
						className={journeyVariableStyle}
						key={index}
						style={{
							margin: 20,
							minHeight: "185px",
							minWidth: "182px",
						}}
						onClick={() => {
							props.onChange(variableName, variablesName, index);
						}}
					>
						{selected ? (
							<div
								style={{
									justifyContent: "flex-end",
									display: "flex",
								}}
							>
								<img
									alt=""
									src="/dist/img/data-exploration/icon_checkbox.png"
								/>
							</div>
						) : (
							<div style={{ height: "27px" }} />
						)}
						<div
							className="center-container"
							style={{
								alignItems: "center",
								margin: 20,
							}}
						>
							<div className="flex-column">
								<span
									style={{
										textAlign: "center",
										wordBreak: "break-word",
										fontFamily: "Roboto",
										fontSize: "20px",
										lineHeight: "23px",
										color: selected
											? mainStyle.getPropertyValue(
													"--exploration-secondary-text-color"
											  )
											: mainStyle.getPropertyValue(
													"--selectors-text-color"
											  ),
										fontWeight: 300,
										display: "block",
									}}
								>
									{(aliases != null
										? aliases[variable]
										: variable) || "Basic"}
								</span>
								<div
									className="center-container"
									style={{
										marginTop: 10,
									}}
								>
									<div
										className={journeyVariableSmallStyle}
										style={{
											height: "28px",
											width: "26px",
										}}
									>
										<div
											className="center-container"
											style={{
												height: "100%",
												alignItems: "center",
											}}
										>
											<span
												style={{
													fontFamily: "Roboto",
													fontSize: "20px",
													lineHeight: "23px",
													color: selected
														? "#20293C"
														: mainStyle.getPropertyValue(
																"--selectors-text-color"
														  ),
													fontWeight: 300,
												}}
											>
												{String.fromCharCode(
													65 + index
												)}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}
