import React, { Component, CSSProperties } from "react";
import { observer } from "mobx-react";
import Popup from "reactjs-popup";

import OutsideAlerter from "common/OutsideAlerter";
import CustomSketchPicker from "./custom_sketch_picker/CustomSketchPicker";
import recentColors from "./RecentColors";
import ReactGPicker from "react-gcolor-picker";
import Portal from "./Portal";
import { calculateChartColorPickerPosition } from "./graphics/v2/utils";

type PresetColors = (string | { color: string; title: string })[];

interface Props {
    value: string;
    isLegend?: boolean;
    customView?: JSX.Element;
    onChange: (newColor: string) => void;

    // Pre-defined colors at the bottom of the color picker.
    // If omitted, then recent colors will be used.
    presetColors?: PresetColors;
    style?: CSSProperties;
    width?: string;
    className?: string;
    tooltip?: string;
    topAppearance?: boolean;
    openInClickPosition?: boolean;
    rightAppearance?: boolean;
    enableAlpha?: boolean;
    inPopup?: boolean;
    isGradient?: boolean;
    overlayStyle?: CSSProperties;
    nested?: boolean;
    opened?: boolean;
    offset?: {
        bottom: number;
    };
    onOpen?: (isOpened: boolean) => void;
    onClickOutside?: () => void;
}

interface State {
    colorChanged: boolean;
    popupOpen: boolean;
    currentColor: string;
    popupPosition: {
        x: number;
        y: number;
    };
}

@observer
class ColorPicker extends Component<Props, State> {
    private buttonRef: React.RefObject<HTMLButtonElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            colorChanged: false,
            popupOpen: false,
            currentColor: "#FFFFFF",
            popupPosition: {
                x: 0,
                y: 0,
            },
        };

        this.buttonRef = React.createRef();
    }

    private saveChanges(): void {
        this.props.onChange(this.state.currentColor);
    }

    private renderCustomSketchPicker(
        useRecentColors: boolean,
        presetColors?: PresetColors
    ): JSX.Element {
        return (
            <CustomSketchPicker
                width={this.props.width ?? "220px"}
                disableAlpha={!this.props.enableAlpha}
                color={this.state.currentColor}
                onChange={(color) => {
                    this.setState({
                        currentColor: this.props.enableAlpha
                            ? `rgba(${color.rgb.r},${color.rgb.g},${
                                  color.rgb.b
                              },${color.rgb.a ?? 1})`
                            : color.hex,
                        colorChanged: true,
                    });
                }}
                onChangeComplete={() => {
                    this.saveChanges();
                }}
                presetColorsTitle={
                    useRecentColors ? "Recent Colors" : undefined
                }
                presetColors={presetColors}
            />
        );
    }

    private renderPopupContent(): JSX.Element {
        const bottomOffset = this.props?.offset?.bottom;
        const useRecentColors: boolean = this.props.presetColors == null;
        let presetColors = useRecentColors
            ? recentColors.recentColors
            : this.props.presetColors;

        let bottomAppearanceOffset = this.props.topAppearance ? 0 : undefined;
        if (bottomOffset) bottomAppearanceOffset = bottomOffset;

        const position = this.props.openInClickPosition
            ? {
                  top: this.state.popupPosition.y,
                  left: this.state.popupPosition.x + 10,
              }
            : {
                  bottom: bottomAppearanceOffset,
                  right: this.props.rightAppearance ? 0 : undefined,
              };
        return (
            <OutsideAlerter
                onReject={(evt) => {
                    if (useRecentColors && this.state.colorChanged) {
                        recentColors.addColor(this.state.currentColor);
                    }
                    // Do not set state if the button was clicked to prevent
                    // this bug: https://eisengardai.atlassian.net/browse/EIS-503
                    if (
                        evt.target instanceof Element &&
                        (this.buttonRef.current == null ||
                            !this.buttonRef.current.contains(evt.target))
                    ) {
                        if (this.props.onClickOutside != null) {
                            this.props.onClickOutside();
                        }
                        this.setState({
                            popupOpen: false,
                            colorChanged: false,
                        });
                    } else {
                        this.setState({
                            colorChanged: false,
                        });
                    }
                }}
            >
                <div
                    style={{
                        ...position,
                        position: "fixed",
                        zIndex: 1000,
                        width: "auto",
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                >
                    {this.props.isGradient ? (
                        <ReactGPicker
                            showAlpha={this.props.enableAlpha}
                            popupWidth={Number(this.props.width)}
                            value={this.state.currentColor}
                            gradient={true}
                            solid={false}
                            onChange={(color) => {
                                this.setState(
                                    {
                                        currentColor: color,
                                        colorChanged: true,
                                    },
                                    () => {
                                        this.saveChanges();
                                    }
                                );
                            }}
                        />
                    ) : (
                        this.renderCustomSketchPicker(
                            useRecentColors,
                            presetColors
                        )
                    )}
                </div>
            </OutsideAlerter>
        );
    }

    public render(): JSX.Element {
        const { isLegend } = this.props;
        const isOpened =
            this.props.opened !== undefined
                ? this.props.opened
                : this.state.popupOpen;
        return (
            <div style={{ position: "relative", display: "flex" }}>
                <button
                    ref={this.buttonRef}
                    title={this.props.tooltip}
                    className={this.props.className}
                    style={{
                        padding: "0 0 0 0",
                        width: "24px",
                        height: "24px",
                        border:
                            this.props.customView == null
                                ? "1px solid gray"
                                : "none",
                        ...this.props.style,
                        background:
                            this.props.customView != null
                                ? "transparent"
                                : this.props.value,
                    }}
                    onClick={(evt) => {
                        if (this.props.onOpen != null) {
                            this.props.onOpen(!isOpened);
                        }
                        const { y } = calculateChartColorPickerPosition(evt);
                        this.setState((state) => ({
                            popupOpen: !state.popupOpen,
                            currentColor: this.props.value,
                            popupPosition: {
                                x: evt.clientX,
                                y,
                            },
                        }));
                    }}
                >
                    {this.props.customView}
                </button>
                {isOpened &&
                    (this.props.inPopup ? (
                        <Popup
                            arrow={true}
                            contentStyle={{
                                border: "none",
                                backgroundColor: "transparent",
                                minWidth: 220,
                                minHeight: 334,
                            }}
                            open={true}
                            onClose={() => {
                                if (this.props.onClickOutside != null) {
                                    this.props.onClickOutside();
                                }
                                this.setState({
                                    popupOpen: false,
                                });
                            }}
                            overlayStyle={
                                this.props.overlayStyle
                                    ? this.props.overlayStyle
                                    : {
                                          zIndex: 100000001,
                                      }
                            }
                            nested={this.props.nested}
                            closeOnDocumentClick
                        >
                            {this.renderPopupContent()}
                        </Popup>
                    ) : (
                        <>
                            {isLegend ? (
                                <Portal rootNode={document.body}>
                                    <div
                                        style={{
                                            zIndex: 100000000,
                                            position: "absolute",
                                            top:
                                                this.state.popupPosition.y + 10,
                                            left: this.state.popupPosition.x,
                                        }}
                                    >
                                        {this.renderPopupContent()}
                                    </div>
                                </Portal>
                            ) : (
                                this.renderPopupContent()
                            )}
                        </>
                    ))}
            </div>
        );
    }
}

export default ColorPicker;
