export enum UserType {
    Admin = 0,
    Creator = 1,
    User1 = 2,
    User2 = 3,
    Viewer = 4,
}
export const UserTypeOptions = [
    { label: "admin", value: UserType.Admin },
    { label: "creator", value: UserType.Creator },
    { label: "user1", value: UserType.User1 },
    { label: "user2", value: UserType.User2 },
    { label: "viewer", value: UserType.Viewer },
];

export function userTypeToString(userType: UserType | null | undefined): string {
    if (userType != null) {
        return UserType[userType].toLowerCase();
    } else {
        return "ROOT";
    }
}

interface UserInfo {
    id: number;
    user_name: string;
    first_name: string;
    last_name: string;
    icon_url: string;
    position?: string;
    organization?: string;
    type?: UserType;
    phone_number?: string;
    email?: string;
    verified?: boolean;
    interest_1?: string | null;
    interest_2?: string | null;
    interest_3?: string | null;
    familiar_with_data?: string | null;
    user_time_zone?: string;
}

export default UserInfo;
