import React from "react";

import { CanvasElement, CanvasTextBox, generateRawMetric, getDefaultPrintExpression } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import PrintExpressionsSelector from "modules/canvas_page/RevampedPrintExpressionsSelector";

interface Props {
  node: CanvasTextBox;
  nodeLinkOptions: NodeLinkOption[];
  onChange: (changes: Partial<CanvasElement>, commit?: boolean) => void;
}

function PrintConditions({ node, nodeLinkOptions, onChange }: Props) {
  return (
    <PrintExpressionsSelector
        onChange={(printExpressions, commit = true) => {
          const changes: Partial<CanvasTextBox> = {
            printExpressions
          };

          if (node.rawMetric != null) {
            changes.rawMetric = generateRawMetric(
              node,
              node.rawMetric
            );
          }

          onChange(changes, commit);
        }}
        printExpressions={node.printExpressions ?? [getDefaultPrintExpression()]}
        nodeLinkOptions={nodeLinkOptions}
        node={node}
    />
  );
}

export default PrintConditions;
