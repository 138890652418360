import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";
import remoteModuleId from "common/remoteModuleId";
import { stringSessionId } from "common/SessionId";

class Api {
    static getFinding(props) {
        let { data, config } = props;
        let item = {
            type: "13",
            content: data,
            config: config,
        };
        return item;
    }
    static getData(config, oldConfig, moduleId, initial = false) {
        let {
            dataScope,
            axesVariables,
            scatterVariable,
            selectedTargetVariablesIndices,
            additionalParameterIndex,
            additionalParameter,
            leftAdditionalOperator,
            rightAdditionalOperator,
            leftAdditionalValue,
            rightAdditionalValue,
            conditions,
            selectedTable,
            numClusters,
        } = config;

        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        conditions = conditionsToJson(conditions);
        let requestJson = {
            num_clusters: numClusters,
            variable_indices: selectedTargetVariablesIndices,
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            conditions: conditions,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            module_id: moduleId,
        };
        if (
            additionalParameter &&
            (leftAdditionalValue || rightAdditionalValue)
        ) {
            requestJson.where = {
                group_index: additionalParameterIndex,
                expressions: [],
            };
            if (leftAdditionalValue) {
                requestJson.where.expressions.push({
                    operation: leftAdditionalOperator?.value ?? "=",
                    value: leftAdditionalValue.value,
                });
            }
            if (rightAdditionalValue) {
                requestJson.where.expressions.push({
                    operation: rightAdditionalOperator?.value ?? "=",
                    value: rightAdditionalValue.value,
                });
            }
        }

        if (!initial && axesVariables.length !== 2) {
            return Promise.reject("Count of axes is not equal to 2");
        }
        if (!initial) {
            let plotParameters = {};
            plotParameters.x_axis = axesVariables[0].name;
            plotParameters.y_axis = axesVariables[1].name;
            plotParameters.scatter = scatterVariable.value;
            plotParameters.point_count = 50;
            requestJson.plot_parameters = plotParameters;
        }
        return axios
            .post("/api/e/find_clusters", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let result = response.data.result;
                    return Promise.resolve(
                        Api.getFinding({ data: result, config: config })
                    );
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    static addNewVariable(config, columnName, clusterNames) {
        let {
            dataScope,
            selectedTargetVariablesIndices,
            conditions,
            selectedTable,
            numClusters,
        } = config;

        const urlParams = new URLSearchParams(window.location.search);
        let currentModuleId = urlParams.get("current_module_id");

        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        conditions = conditionsToJson(conditions);
        let requestJson = {
            num_clusters: numClusters,
            variable_indices: selectedTargetVariablesIndices,
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            conditions: conditions,
            cluster_names: clusterNames,
            cluster_variable_name: columnName,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            module_id:
                currentModuleId != null
                    ? Number(currentModuleId)
                    : remoteModuleId,
            update_id: stringSessionId(),
        };
        return axios
            .post("/api/e/add_cluster_variable", requestJson)
            .then((response) => {
                if (response.data.success) {
                    return Promise.resolve(true);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };