import React from "react";
import ClustersChart from "common/graphics/ClustersChart";

function MainComponent(props) {
	let finding = props.finding;

	return (
		<div style={{ width: "100%", height: "100%" }}>
			<ClustersChart
				data={
					Array.isArray(finding.content)
						? finding.content
						: [finding.content]
				}
				dataKeys={finding.config.axesVariables}
			/>
		</div>
	);
}

export { MainComponent };