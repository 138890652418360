import {
    CanvasSurvey,
    SurveyQuestion,
    RuleSurveyQuestion,
    isRuleSurveyQuestion,
} from "common/Canvas";
import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import styles from "../Rules.module.css";
import cx from "classnames";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { ReactComponent as RemoveIcon } from "icons/canvas/close.svg";
import { EditVariable, NewVariable } from "common/VariableCreator";
import RuleConditionsSelector from "common/Survey/RuleConditionsSelector";
import Variables from "common/Variables";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import { shortenColumnName } from "../../utils";
import { Type } from "common/InputData";

interface RuleProps {
    modifyDbSchema: (
        newVariable: NewVariable | null,
        dropVariable: number | null,
        editVariable: EditVariable | null,
        previousState?: SurveyQuestion
    ) => void;
    questionIndex: number;
    node: CanvasSurvey;
    onChange: (
        changes: Partial<CanvasSurvey>,
        commit?: boolean,
        skipHistory?: boolean
    ) => void;
    currentModuleId?: number;
}

const RenderRule: React.FC<RuleProps> = ({
    questionIndex,
    onChange,
    node,
    modifyDbSchema,
    currentModuleId,
}) => {
    const question = node.questions[questionIndex] as RuleSurveyQuestion;
    const rules =
        question.rules.length !== 0
            ? question.rules
            : [
                  {
                      value: "",
                      ruleConditions: [],
                  },
              ];

    let [optionSelected, setOptionSelected] = React.useState(0);

    const columnOptions = useMemo(
        () =>
            node.questions
                .filter((q) => !isRuleSurveyQuestion(q))
                .map((q) => ({
                    label: q.question,
                    value: q.id,
                })),
        [node.questions]
    );

    const regex = /Rule (\d+)\.\.\.$/;

    const _removeQuestion = (
        evt: React.MouseEvent<SVGSVGElement, MouseEvent>
    ) => {
        evt.stopPropagation();
        if (node.questions.length > 1) {
            let newQuestions = [...node.questions];
            let deletedQuestion = node.questions[questionIndex];
            newQuestions.splice(questionIndex, 1);
            onChange({ questions: newQuestions }, true, false);

            modifyDbSchema(null, questionIndex, null, deletedQuestion);
        }
    };

    const _updateQuestionTitle = async (
        question: SurveyQuestion,
        questionIndex: number
    ) => {
        if (node.backendOutput.tableOption?.data_table_idx) {
            let newQuestion = {
                ...question,
                columnName: shortenColumnName(
                    question.question,
                    node.questions.map((q) => q.columnName ?? q.question)
                ),
            };
            let questions = [...node.questions];
            let oldQuestion = questions[questionIndex];
            questions[questionIndex] = newQuestion;

            onChange({ questions: questions });
            let editVariable: EditVariable = {
                index: questionIndex,
                new_name: newQuestion.columnName,
            };

            modifyDbSchema(null, null, editVariable, oldQuestion);
        }
    };
    const defaultValue =
        question.question?.match(regex) ||
        question.question === "Invalid question title"
            ? ""
            : question.question;
    return (
        <div className={styles.questionWrapper}>
            <Accordion
                activeKey={String(optionSelected)}
                onSelect={(option) => {
                    setOptionSelected(Number(option));
                }}
            >
                <Accordion.Toggle
                    eventKey={"1"}
                    className={styles.editMenuAdvancedOption}
                >
                    <div key={defaultValue}>
                        <input
                            placeholder={question.question}
                            className={styles.questionTitleInput}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                            }}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onKeyUp={(evt) => {
                                evt.preventDefault();
                            }}
                            onBlur={(e) => {
                                let val = e.target.value.trim();
                                let newQuestion = {
                                    ...node.questions[questionIndex],
                                    question: val
                                        ? val
                                        : `Question ${question.id}...`,
                                };
                                _updateQuestionTitle(
                                    newQuestion,
                                    questionIndex
                                );
                            }}
                            defaultValue={defaultValue}
                        />
                    </div>
                    <ChevronIcon
                        className={cx(styles.editMenuAdvancedOptionIcon, {
                            [styles.chevronOpen]: optionSelected === 1,
                        })}
                    />

                    <RemoveIcon
                        onClick={(evt) => {
                            _removeQuestion(evt);
                        }}
                        className={styles.removeQuestion}
                    />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={"1"}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "15px",
                        }}
                    >
                        <div
                            className={styles.thinOptionName}
                            style={{ marginBottom: 5 }}
                        >
                            Dataset Column
                        </div>
                        <Select
                            isSearchable={false}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    borderRadius: "4px",
                                    width: "100%",
                                    minHeight: "24px",
                                    maxHeight: "24px",
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                }),
                                indicatorsContainer: (provided) => ({
                                    ...provided,
                                    height: "20px",
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    maxWidth: 120,
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 10,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                            options={columnOptions}
                            onChange={(e) => {
                                let newQuestions = [...node.questions];
                                newQuestions[questionIndex] = {
                                    ...newQuestions[questionIndex],
                                    linkedToId: e!.value,
                                } as RuleSurveyQuestion;

                                onChange({ questions: newQuestions });
                            }}
                            value={columnOptions.find(
                                (q) => q.value === question.linkedToId
                            )}
                            placeholder="Select Column..."
                        />
                        {rules.map((rule, ruleIndex) => (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: 15,
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: 1,
                                    }}
                                >
                                    <div
                                        className={styles.thinOptionName}
                                        style={{ marginBottom: 5 }}
                                    >
                                        Conditions
                                    </div>
                                    <RuleConditionsSelector
                                        small
                                        style={{
                                            marginTop: 0,
                                        }}
                                        dataScopeId={
                                            node.backendOutput.tableOption
                                                ?.data_table_idx
                                        }
                                        title=""
                                        variable={
                                            Variables(
                                                node.backendOutput.tableOption
                                                    ?.data_table_idx
                                            ).variableOptions[
                                                node.questions.findIndex(
                                                    (q) =>
                                                        q.id ===
                                                        question.linkedToId
                                                )!
                                            ]
                                        }
                                        value={
                                            rule.ruleConditions.length !== 0
                                                ? rule.ruleConditions
                                                : RuleConditionsSelector.defaultValue
                                        }
                                        onChange={(ruleConditions) => {
                                            let newQuestions = [
                                                ...node.questions,
                                            ];
                                            let q = {
                                                ...newQuestions[questionIndex],
                                                rules: [...rules],
                                            } as RuleSurveyQuestion;
                                            newQuestions[questionIndex] = q;
                                            q.rules[ruleIndex] = {
                                                ...rule,
                                                ruleConditions: ruleConditions,
                                            };

                                            onChange({
                                                questions: newQuestions,
                                            });
                                        }}
                                        currentModuleId={currentModuleId}
                                        {...ConditionSelectorStyles}
                                        titleStyle={{
                                            backgroundColor: "transparent",
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginLeft: 5,
                                        marginRight: 5,
                                    }}
                                >
                                    =
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: 1,
                                    }}
                                >
                                    <div
                                        className={styles.thinOptionName}
                                        style={{ marginBottom: 5 }}
                                    >
                                        Category
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <input
                                            value={rule.value}
                                            className={styles.input}
                                            placeholder="Define category"
                                            onChange={(e) => {
                                                let newQuestions = [
                                                    ...node.questions,
                                                ];
                                                let q = {
                                                    ...newQuestions[
                                                        questionIndex
                                                    ],
                                                    rules: [...rules],
                                                } as RuleSurveyQuestion;
                                                newQuestions[questionIndex] = q;
                                                q.rules[ruleIndex] = {
                                                    ...rule,
                                                    value: e.target.value,
                                                };

                                                let oldType = Variables(
                                                    node.backendOutput
                                                        .tableOption
                                                        ?.data_table_idx
                                                ).variableOptions[
                                                    questionIndex + 1
                                                ].type;

                                                let newType = "float";
                                                for (let rule of q.rules) {
                                                    if (
                                                        isNaN(
                                                            Number(rule.value)
                                                        )
                                                    ) {
                                                        newType = "str";
                                                        break;
                                                    }
                                                }

                                                if (oldType !== newType) {
                                                    modifyDbSchema(
                                                        null,
                                                        null,
                                                        {
                                                            index: questionIndex,
                                                            type:
                                                                newType ===
                                                                "float"
                                                                    ? Type.Float
                                                                    : Type.Str,
                                                        },
                                                        node.questions[
                                                            questionIndex
                                                        ]
                                                    );
                                                }

                                                onChange({
                                                    questions: newQuestions,
                                                });
                                            }}
                                            style={{ flex: 1 }}
                                        />
                                        <div
                                            className="flex-simple-column"
                                            style={{ marginLeft: 5 }}
                                        >
                                            <Button
                                                className="btn-small-like-select"
                                                style={{
                                                    width: "19px",
                                                    height: "19px",
                                                }}
                                                onClick={() => {
                                                    let newQuestions = [
                                                        ...node.questions,
                                                    ];
                                                    let q = {
                                                        ...newQuestions[
                                                            questionIndex
                                                        ],
                                                        rules: [...rules],
                                                    } as RuleSurveyQuestion;
                                                    newQuestions[
                                                        questionIndex
                                                    ] = q;
                                                    q.rules.push({
                                                        value: "",
                                                        ruleConditions: [],
                                                    });

                                                    onChange({
                                                        questions: newQuestions,
                                                    });
                                                }}
                                            >
                                                {"\uFF0B" /* plus */}
                                            </Button>
                                            <Button
                                                className="btn-small-like-select"
                                                style={{
                                                    width: "19px",
                                                    height: "19px",
                                                }}
                                                onClick={() => {
                                                    let newQuestions = [
                                                        ...node.questions,
                                                    ];
                                                    let q = {
                                                        ...newQuestions[
                                                            questionIndex
                                                        ],
                                                        rules: [...rules],
                                                    } as RuleSurveyQuestion;
                                                    newQuestions[
                                                        questionIndex
                                                    ] = q;
                                                    q.rules.splice(
                                                        ruleIndex,
                                                        1
                                                    );

                                                    onChange({
                                                        questions: newQuestions,
                                                    });
                                                }}
                                            >
                                                {"\uFF0D" /* minus */}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Accordion.Collapse>
            </Accordion>
            <div className={styles.questionDivLine}></div>
        </div>
    );
};

export default RenderRule;
