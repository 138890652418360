import "common/styles/App.css";
import React from "react";
import { reaction } from "mobx";
import { Button } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";
import ClustersChart from "common/graphics/ClustersChart";
import BaseJourney from "../../common/BaseJourney";
import { Api } from "./Api";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Element } from "react-scroll";
import Switch from "react-switch";
import AddVariablePopup from "./AddVariablePopup";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";
import { Permission } from "common/Permissions";

const Step = Object.freeze({
    initial: 0,
    selectDataScope: 1,
    selectTable: 2,
    selectConditions: 3,
    selectClusterOptions: 4,
    selectAdditionalParameter: 5,
    selectAdditionalValue: 6,
    selectPlotOptions: 7,
    final: 8,
});

function StepFinal(props) {
    let {
        onBack,
        onShowRecipesPopup,
        onAddNewVariable,
        addNewVariableVisible,
        onSaveFinding,
        onClusterEdit,
        finding,
        loading,
        // data
    } = props;
    return (
        <>
            <div
                className="my-row"
                style={{
                    justifyContent: "space-between",
                    marginTop: 10,
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    onClick={() => {
                        onBack();
                    }}
                    style={{
                        minWidth: 100,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/data-exploration/chevron_back.png"
                    />
                </div>
                {loading ? (
                    <span className="exploration-big-title-span">
                        {"LOADING"}
                    </span>
                ) : (
                    finding && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "calc(100% - 200px)",
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "center",
                                    width: "100%",
                                    height: "330px",
                                }}
                            >
                                <ErrorBoundary>
                                    <ClustersChart
                                        editable
                                        onFinishEdit={onClusterEdit}
                                        data={finding.content}
                                        dataKeys={finding.config.axesVariables}
                                    />
                                </ErrorBoundary>
                            </div>

                            <FinalStepBottomBar
                                onShowRecipesPopup={onShowRecipesPopup}
                                onSaveFinding={onSaveFinding}
                                topMargin={5}
                            />
                            {addNewVariableVisible && (
                                <div
                                    className="my-row"
                                    style={{
                                        alignSelf: "flex-end",
                                        alignItems: "center",
                                        marginTop: 10,
                                    }}
                                >
                                    <span
                                        style={{
                                            cursor: "pointer",
                                            fontFamily: "Roboto",
                                            fontSize: "12px",
                                            lineHeight: "14px",
                                            color: mainStyle.getPropertyValue(
                                                "--exploration-secondary-text-color"
                                            ),
                                            fontWeight: 700,
                                        }}
                                    >
                                        press KEY [T] or click ++ button to add
                                        new variable
                                    </span>
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        style={{
                                            marginLeft: 10,
                                        }}
                                        onClick={onAddNewVariable}
                                    >
                                        ++
                                    </Button>
                                </div>
                            )}
                        </div>
                    )
                )}
                <div
                    style={{
                        minWidth: 100,
                        height: "100%",
                    }}
                />
            </div>
        </>
    );
}

class MainComponent extends BaseJourney {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            previousStep: 0,
            step: 0,
            animationDirection: "top",
            numClusters: 1,
            selectedTargetVariables: [],
            targetVariables: [],
            finding: undefined,
            initialFinding: undefined,
            selectedTable: undefined,
            scatterVariable: undefined,
            axesVariables: [],
            conditions: [
                {
                    variable: null,
                    operation: null,
                    value: null,
                    logical: null,
                    leftBracket: null,
                    rightBracket: null,
                },
            ],
            additionalParameters: [],
            additionalParameter: undefined,
            leftAdditionalValue: undefined,
            rightAdditionalValue: undefined,
            step2VariablesMap: {
                [Step.selectTarget]: ["targetVariable", "targetVariables"],
                [Step.selectAdditionalParameter]: [
                    "additionalParameter",
                    "additionalParameters",
                ],
            },
        };
        this.getData = this.getData.bind(this);
        this.initializeVariables = this.initializeVariables.bind(this);
        this.switchVariables = this.switchVariables.bind(this);
        this.editCluster = this.editCluster.bind(this);
        this.addNewVariable = this.addNewVariable.bind(this);
    }
    clearOptionalArgs() {
        this.setState({
            additionalParameter: null,
            additionalParameterIndex: null,
            leftAdditionalOperator: null,
            leftAdditionalValue: null,
            rightAdditionalOperator: null,
            rightAdditionalValue: null,
            selectedTargetVariables: [],
            selectedTargetVariablesIndices: [],
            axesVariables: [],
            scatterVariable: null,
            initialFinding: null,
            selectedTable: this.defaultTable(),
            conditions: ConditionsSelector.defaultValue,
            dynamicOptions: {},
        });
    }
    getConfig() {
        let config = {
            version: configVersion,
            additionalParameterIndex: this.getVariables().variableToIndex(
                this.state.additionalParameter
            ),
            selectedTargetVariablesIndices: this.state.selectedTargetVariables.map(
                (variable) => this.getVariables().variableToIndex(variable)
            ),
            additionalParameter: this.state.additionalParameter,
            leftAdditionalOperator: this.state.leftAdditionalOperator,
            rightAdditionalOperator: this.state.rightAdditionalOperator,
            leftAdditionalValue: this.state.leftAdditionalValue,
            rightAdditionalValue: this.state.rightAdditionalValue,
            additionalParameters: this.state.additionalParameters,
            scatterVariable: this.state.scatterVariable,
            axesVariables: this.state.axesVariables,
            dataScope: this.state.dataScope,
            selectedTable: this.state.selectedTable,
            conditions: this.state.conditions,
            selectedTargetVariables: this.state.selectedTargetVariables,
            numClusters: this.state.numClusters,
            journeyName: this.props.journeyName,
        };
        if (this.props.addToCanvas) {
            config.dynamicOptions = this.prepareDynamicOptions(
                this.state.dynamicOptions,
                config
            );
        }
        return config;
    }
    addNewVariable() {
        if (this.state.dataScope.permissionType === Permission.ReadWrite)
            this.setState({ showAddVariablePopup: true });
    }
    editCluster(groupIndex, index, value) {
        let finding = Object.assign({}, this.state.finding);
        finding.content[groupIndex].graphics[index].name = value;
        this.setState({ finding: finding });
    }

    getData(initial = false) {
        this.setState({ loading: true, error: undefined });

        Api.getData(
            this.getConfig(),
            undefined,
            this.props.currentModuleId,
            initial
        )
            .then((finding) => {
                if (initial)
                    this.setState({ initialFinding: finding, loading: false });
                else
                    this.setState({
                        finding: finding,
                        loading: false,
                    });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: "Clusterization error",
                });
                console.log(error);
            });
    }
    buildClusterOptionsSelector() {
        let columns = [
            {
                label: "variable",
                field: "variable",
            },
            {
                label: "turn on",
                field: "turn_on",
            },
        ];
        let rows = this.state.targetVariables.map((targetVariable) => {
            let checked = this.state.selectedTargetVariables.includes(
                targetVariable
            );
            let item = [];
            item.push(targetVariable);
            item.push(
                <Switch
                    onChange={() => {
                        let targetVariables = Array.from(
                            this.state.targetVariables
                        );
                        let selectedTargetVariables = Array.from(
                            this.state.selectedTargetVariables
                        ).filter((variable) =>
                            targetVariables.includes(variable)
                        );
                        if (checked)
                            selectedTargetVariables = selectedTargetVariables.filter(
                                (variable) => variable !== targetVariable
                            );
                        else selectedTargetVariables.push(targetVariable);
                        this.setState({
                            selectedTargetVariables: selectedTargetVariables,
                        });
                    }}
                    checked={checked}
                    width={26}
                    height={13}
                    offColor="#20293C"
                    onColor="#20293C"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor="#70889E"
                    onHandleColor="#1F8EFA"
                />
            );
            return item;
        });
        return (
            <div className="my-row" style={{ height: "100%" }}>
                {this.buildLeftBar()}
                <div
                    className="flex-simple-column"
                    style={{
                        marginTop: 25,
                        marginLeft: 10,
                        marginRight: 100,
                        width: "100%",
                    }}
                >
                    <span className="exploration-big-title-span">
                        {"I want to find clusters by"}
                    </span>
                    <div className="my-row" style={{ alignItems: "center" }}>
                        <span
                            style={{
                                fontFamily: "Arial",
                                fontSize: "12px",
                                color: mainStyle.getPropertyValue(
                                    "--exploration-primary-text-color"
                                ),
                            }}
                        >
                            {"Specify count of clusters: "}
                        </span>
                        <input
                            step="1"
                            min="1"
                            style={{ marginLeft: 10, width: "50px" }}
                            className="input-range"
                            type="number"
                            value={this.state.numClusters}
                            placeholder={""}
                            onChange={(evt) => {
                                let number = evt.target.value;
                                if (!number)
                                    this.setState({
                                        numClusters: 1,
                                    });
                                else
                                    this.setState({
                                        numClusters: Math.trunc(number),
                                    });
                            }}
                        />
                    </div>
                    <Element
                        name="scrollable"
                        className="element"
                        style={{
                            overflowX: "auto",
                            backgroundColor: "transparent",
                            position: "relative",
                            marginTop: 20,
                            height: 220,
                            width: "100%",
                            overflowY: "auto",
                        }}
                    >
                        <MDBTable
                            style={{ wordBreak: "break-word" }}
                            className="customized-table customized-link-table no-border"
                        >
                            <MDBTableHead columns={columns} />
                            <MDBTableBody rows={rows} />
                        </MDBTable>
                    </Element>
                </div>
            </div>
        );
    }
    buildScatterSwitch(variable, label) {
        let checked =
            this.state.scatterVariable &&
            this.state.scatterVariable.value === variable;
        return (
            <Switch
                onChange={() => {
                    if (checked) {
                        this.setState({ scatterVariable: undefined });
                    } else {
                        this.setState({
                            scatterVariable: { label: label, value: variable },
                        });
                    }
                }}
                checked={checked}
                width={26}
                height={13}
                offColor="#20293C"
                onColor="#20293C"
                checkedIcon={false}
                uncheckedIcon={false}
                offHandleColor="#70889E"
                onHandleColor="#1F8EFA"
            />
        );
    }

    buildAxesSwitch(variable) {
        let checked = this.state.axesVariables
            .map((item) => item.name)
            .includes(variable);
        return (
            <Switch
                onChange={() => {
                    let axesVariables = Array.from(
                        this.state.axesVariables
                    ).filter((item) =>
                        this.state.targetVariables.includes(item.name)
                    );
                    if (checked)
                        axesVariables = axesVariables.filter(
                            (item) => item.name !== variable
                        );
                    else
                        axesVariables.push({
                            name: variable,
                            unit: this.getVariables().getUnits(variable),
                        });
                    axesVariables = axesVariables.slice(-2);
                    this.setState({ axesVariables: axesVariables });
                }}
                checked={checked}
                width={26}
                height={13}
                offColor="#20293C"
                onColor="#20293C"
                checkedIcon={false}
                uncheckedIcon={false}
                offHandleColor="#70889E"
                onHandleColor="#1F8EFA"
            />
        );
    }

    buildPlotOptionsSelector() {
        let columns = [];
        let rows = [];
        if (this.state.initialFinding) {
            let items = this.state.initialFinding.content;

            columns.push({ label: "Name", value: "name" });
            items.forEach((item) => {
                columns.push({
                    label: item.where
                        ? item.where.group_name
                              .concat(":")
                              .concat(item.where.value)
                        : "Basic",
                    value: item.where
                        ? item.where.group_name
                              .concat(":")
                              .concat(item.where.value)
                        : "Basic",
                });
                columns = columns.concat(
                    item.clusters.map((cluster) => {
                        return {
                            label: cluster.name,
                            value: cluster.name,
                        };
                    })
                );
            });

            columns.push({ label: "Scatter", value: "scatter" });
            columns.push({ label: "Axes", value: "axes" });
            let valuesRow = [];
            items.forEach((item) => {
                valuesRow.push("");

                valuesRow = valuesRow.concat(
                    item.clusters.map((cluster) => {
                        return `${formatValue(cluster.count, false).join(
                            ""
                        )}(${formatValue(
                            cluster.count_percent * 100,
                            false
                        ).join("")}%)`;
                    })
                );
            });

            let countRow = [
                "counts",
                ...valuesRow,
                this.buildScatterSwitch("%count", "clusters"),
                "",
            ];
            rows.push(["Counts:", ...new Array(columns.length - 1).fill("")]);
            rows.push(countRow);
            let outcomesRows = this.getVariables().numericVariables.map(
                (variable) => {
                    let valuesRow = [];
                    items.forEach((item) => {
                        valuesRow.push("");

                        valuesRow = valuesRow.concat(
                            item.clusters.map((cluster) => {
                                let variableInfo = cluster.variables.filter(
                                    (item) => item.name === variable.name
                                )[0];
                                return `${formatValue(
                                    variableInfo.mean,
                                    false
                                ).join("")}(${formatValue(
                                    variableInfo.std,
                                    false
                                ).join("")})`;
                            })
                        );
                    });

                    return [
                        variable.name,
                        ...valuesRow,
                        this.state.selectedTargetVariables.includes(
                            variable.name
                        )
                            ? this.buildScatterSwitch(
                                  variable.name,
                                  variable.name
                              )
                            : "",
                        this.buildAxesSwitch(variable.name),
                    ];
                }
            );
            rows.push([
                "Variables:",
                ...new Array(columns.length - 1).fill(""),
            ]);
            rows = rows.concat(outcomesRows);
        }
        return (
            <div className="my-row" style={{ height: "100%" }}>
                {this.buildLeftBar()}
                {this.state.loading || this.state.error ? (
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            height: 265,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <span className="exploration-big-title-span">
                            {this.state.loading ? "LOADING" : this.state.error}
                        </span>
                    </div>
                ) : (
                    <div
                        className="flex-simple-column"
                        style={{
                            marginTop: 25,
                            marginLeft: 10,
                            marginRight: 100,
                            width: "100%",
                        }}
                    >
                        <span className="exploration-big-title-span">
                            {"and show"}
                        </span>
                        <Element
                            name="scrollable"
                            className="element"
                            style={{
                                overflowX: "auto",
                                backgroundColor: "transparent",
                                position: "relative",
                                marginTop: 20,
                                height: 220,
                                width: "100%",
                                overflowY: "auto",
                            }}
                        >
                            <MDBTable
                                style={{ wordBreak: "break-word" }}
                                className="customized-table customized-link-table no-border"
                            >
                                <MDBTableHead columns={columns} />
                                <MDBTableBody rows={rows} />
                            </MDBTable>
                        </Element>
                    </div>
                )}
            </div>
        );
    }

    buildContent() {
        switch (this.state.step) {
            case Step.initial:
                return this.buildInitial(
                    "How do you want to examine your variables?"
                );
            case Step.selectDataScope:
                return this.buildDataScopesSelector();
            case Step.selectTable:
                return this.buildTableSelector();
            case Step.selectConditions:
                return this.buildConditionsSelector();
            case Step.selectClusterOptions:
                return this.buildClusterOptionsSelector();
            case Step.selectAdditionalParameter:
                return this.buildVariablesSelector(
                    "[+] and how they vary by",
                    "additionalParameter",
                    "additionalParameters",
                    false,
                    DynamicOptionType.additionalParameter,
                    "additionalParameterIndex"
                );
            case Step.selectAdditionalValue:
                return this.buildAdditionalValueSelector("Comparing", true);
            case Step.selectPlotOptions:
                return this.buildPlotOptionsSelector();
            case Step.final:
                return (
                    <StepFinal
                        onShowRecipesPopup={this.showRecipesPopup}
                        onAddNewVariable={this.addNewVariable}
                        addNewVariableVisible={
                            this.state.dataScope.permissionType ===
                            Permission.ReadWrite
                        }
                        loading={this.state.loading}
                        finding={this.state.finding}
                        onClusterEdit={this.editCluster}
                        onSaveFinding={this.saveFinding}
                        onBack={this.back}
                    />
                );

            default:
                return <div />;
        }
    }
    buildLeftBar() {
        return super.buildLeftBar(this.state.step < Step.final - 1);
    }
    initializeVariables() {
        let scopeVariables = this.getVariables();
        let newState = Object.assign({}, this.state);
        newState.targetVariables = scopeVariables.numericVariables.map(
            (item) => item.name
        );
        newState.additionalParameters = [null].concat(
            scopeVariables.dataVariables.map((item) => item.name)
        );
        this.setState(newState);
    }

    componentDidMount() {
        this.getLastJourneyConfig();
    }
    switchVariables() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
        this.initializeVariables();
        this.initializeVariablesReaction = reaction(
            () => this.getVariables().dataVariables,
            () => {
                this.initializeVariables();
            }
        );
    }
    componentWillUnmount() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
    }
    showFinalStep() {
        if (
            this.state.selectedTargetVariables.length > 0 &&
            this.state.scatterVariable &&
            this.state.axesVariables.length === 2 &&
            this.state.step > Step.initial &&
            this.state.step < Step.final
        )
            this.setState({ animationDirection: "top" }, () => {
                this.setState(
                    (state) => ({
                        previousStep: state.step,
                        step: Step.final,
                    }),
                    () => {
                        this.getData();
                        this.saveNewJourneyConfig();
                    }
                );
            });
    }

    selectVariable(variableName, variablesName, index) {
        let stateDiff = {};
        let multiselection = Array.isArray(this.state[variableName]);
        if (!multiselection) {
            stateDiff = {
                [variableName]: this.state[variablesName][index],
            };
        } else {
            let selectedVariables = Array.from(this.state[variableName]);
            let newVariable = this.state[variablesName][index];
            if (selectedVariables.includes(newVariable)) {
                selectedVariables = selectedVariables.filter(
                    (item) => item !== newVariable
                );
            } else {
                selectedVariables.push(newVariable);
            }
            let allVariables = Array.from(this.state[variablesName]);
            selectedVariables = selectedVariables.filter((variable) =>
                allVariables.includes(variable)
            );
            stateDiff = {
                [variableName]: selectedVariables,
            };
        }
        if (this.state.step === Step.selectAdditionalParameter) {
            stateDiff.leftAdditionalValue = undefined;
            stateDiff.rightAdditionalValue = undefined;
        }

        this.setState(stateDiff, () => {
            if (this.state.step !== Step.selectTarget)
                setTimeout(() => {
                    this.stepDown();
                }, 100);
        });
    }

    getSubtitle() {
        let conditionsString = conditionsToString(
            Array.from(this.state.conditions)
        );
        if (
            this.state.step === Step.selectTable ||
            this.state.step === Step.selectDataScope
        )
            return "How do you want to examine your variables?";
        if (
            this.state.step > Step.selectTable &&
            this.state.step < Step.final
        ) {
            let subtitle = `For the data in ${
                this.state.dataScope ? this.state.dataScope.label : ""
            } ${this.state.selectedTable.label}`;
            if (this.state.step > Step.selectConditions) {
                if (conditionsString.length > 0)
                    subtitle += ` and under the conditions ${conditionsString}`;
            }
            if (this.state.step > Step.selectClusterOptions)
                subtitle += ` I want to find clusters by ${this.state.selectedTargetVariables.join(
                    ", "
                )}`;
            if (this.state.step > Step.selectAdditionalParameter)
                subtitle += ` and how they vary by ${
                    this.state.additionalParameter || ""
                }`;
            if (this.state.step > Step.selectAdditionalValue) {
                subtitle += ` ${[
                    this.state.leftAdditionalValue,
                    this.state.rightAdditionalValue,
                ]
                    .filter((item) => item)
                    .filter((value) => value.value)
                    .map((value) => value.label)
                    .join(", ")}`;
            }
            return subtitle;
        }

        if (this.state.step === Step.final) {
            return (
                <>
                    <span>For the data </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.dataScope
                            ? this.state.dataScope.label.concat(" ")
                            : ""}
                    </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedTable.label}
                    </span>
                    {conditionsString.length > 0 && (
                        <>
                            <span> and under the conditions </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {conditionsString}
                            </span>
                        </>
                    )}
                    <span> I want to find clusters by </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedTargetVariables.join(", ")}
                    </span>
                    {this.state.additionalParameter &&
                    [
                        this.state.leftAdditionalValue,
                        this.state.rightAdditionalValue,
                    ]
                        .filter((item) => item)
                        .filter((item) => item.value).length > 0 ? (
                        <>
                            <span> and how they vary by </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {(this.state.leftAdditionalValue?.label != null
                                    ? ` ${this.state.additionalParameter} ${
                                          this.state.leftAdditionalOperator
                                              ?.label ?? "="
                                      } ${this.state.leftAdditionalValue.label}`
                                    : "") +
                                    (this.state.rightAdditionalValue?.label !=
                                    null
                                        ? (this.state.leftAdditionalValue
                                              ?.label != null
                                              ? ", "
                                              : " ") +
                                          `${this.state.additionalParameter} ${
                                              this.state.rightAdditionalOperator
                                                  ?.label ?? "="
                                          } ${
                                              this.state.rightAdditionalValue
                                                  .label
                                          }`
                                        : "")}
                            </span>
                        </>
                    ) : null}
                    {this.state.axesVariables && (
                        <>
                            <span> and show </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {this.state.axesVariables
                                    .map((item) => item.name)
                                    .join(", ")}
                            </span>
                        </>
                    )}
                    {this.state.scatterVariable && (
                        <>
                            <span> related to </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {this.state.scatterVariable.label}
                            </span>
                        </>
                    )}
                    <span>.</span>
                </>
            );
        }
        return "";
    }
    getFooterTitle() {
        switch (this.state.step) {
            case Step.initial:
                return "... For the data in...";
            case Step.selectTable:
                return "... and under the conditions...";
            case Step.selectConditions:
                return "... I want to find clusters by ...";
            case Step.selectClusterOptions:
                return "[optional] and they vary by ...";
            case Step.selectAdditionalValue:
                return "... and show ...";
            default:
                return "";
        }
    }
    stepDown() {
        let delta = 1;
        if (this.state.step === Step.selectTable && !this.state.selectedTable) {
            return;
        }
        if (
            this.state.step === Step.selectClusterOptions &&
            this.state.selectedTargetVariables.length === 0
        ) {
            return;
        }
        if (
            this.state.step === Step.selectAdditionalParameter &&
            !this.state.additionalParameter
        )
            delta = 2;

        this.setState({ animationDirection: "top" }, () => {
            if (this.state.step + 1 < Step.final) {
                this.setState(
                    (state) => ({
                        previousStep: state.step,
                        step: state.step + delta,
                    }),
                    () => {
                        if (this.state.step === Step.selectPlotOptions) {
                            this.getData(true);
                        }
                    }
                );
            } else {
                this.showFinalStep();
            }
        });
    }

    stepUp() {
        let step = this.state.step;
        let newStep = step - 1;
        if (step === Step.selectPlotOptions) {
            if (!this.state.additionalParameter) newStep = newStep - 1;
        }
        if (step > Step.initial)
            this.setState({ animationDirection: "bottom" }, () => {
                this.setState((state) => ({
                    step: newStep,
                }));
            });
        if (step === Step.selectAdditionalValue) {
            this.setState({
                leftAdditionalValueSelected: false,
                rightAdditionalValueSelected: false,
            });
        }
    }

    render() {
        return (
            <div
                className="dashboard-rect-journey-focus"
                tabIndex="0"
                style={{
                    height: 520,
                    overflow: "hidden",
                    display: !this.props.collapsed ? "block" : "none",
                }}
                onClick={() => {
                    if (this.state.step === Step.initial) this.stepDown();
                }}
                onKeyDown={(evt) => {
                    if (evt.key === "Escape") {
                        this.props.onClose();
                    }
                    if (evt.key === "ArrowDown") {
                        if (
                            this.state.step > Step.initial &&
                            this.state.step < Step.final
                        )
                            this.stepDown();
                        evt.preventDefault();
                    }
                    if (evt.key === "ArrowLeft") {
                        if (this.state.step === Step.final) {
                            this.back();
                        }
                    }
                    if (evt.key === "ArrowRight") {
                        this.showFinalStep();
                    }
                    if (evt.key === "ArrowUp") {
                        if (this.state.step < Step.final) this.stepUp();
                        evt.preventDefault();
                    }
                    if (evt.key === "p") {
                        if (this.state.step === Step.final) {
                            this.saveFinding();
                        }
                    }
                    if (evt.key === "t") {
                        if (this.state.step === Step.final) {
                            evt.preventDefault();
                            this.addNewVariable();
                        }
                    }
                    if (evt.key === "d") {
                        if (this.state.step === Step.final) {
                            evt.preventDefault();
                            this.showRecipesPopup();
                        }
                    }
                    let variableInfo = this.state.step2VariablesMap[
                        this.state.step
                    ];
                    if (variableInfo) {
                        let variablesLength = this.state[variableInfo[1]]
                            .length;
                        let variableIndex = evt.keyCode - 65;
                        if (
                            variableIndex >= 0 &&
                            variableIndex < variablesLength
                        ) {
                            this.selectVariable(
                                variableInfo[0],
                                variableInfo[1],
                                variableIndex
                            );
                        }
                    }
                    if (evt.key === "Enter") {
                        if (
                            this.state.step === Step.selectAdditionalValue &&
                            this.getVariables().isMonthVariable(
                                this.state.additionalParameter
                            ) &&
                            this.state.leftAdditionalValue &&
                            !this.state.leftAdditionalValueSelected
                        ) {
                            this.setState({
                                leftAdditionalValueSelected: true,
                            });
                            return;
                        }
                        if (
                            this.state.step === Step.selectAdditionalValue &&
                            this.getVariables().isMonthVariable(
                                this.state.additionalParameter
                            ) &&
                            this.state.leftAdditionalValueSelected &&
                            this.state.rightAdditionalValue
                        ) {
                            this.stepDown();
                            return;
                        }
                    }

                    if (
                        this.state.step === Step.selectAdditionalValue &&
                        this.getVariables().isMonthVariable(
                            this.state.additionalParameter
                        )
                    ) {
                        this.selectMonth(evt.key, true);
                    }
                }}
            >
                <div
                    className="my-row"
                    style={{ justifyContent: "space-between", height: "100%" }}
                >
                    <div
                        className="flex-column"
                        style={{
                            height: "100%",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {this.buildHeader()}

                        <TransitionGroup
                            style={{
                                minHeight: "inherit",
                            }}
                        >
                            <CSSTransition
                                style={{
                                    height: "100%",
                                }}
                                key={this.state.step}
                                timeout={500}
                                classNames={"journeywizard-".concat(
                                    this.state.animationDirection || ""
                                )}
                            >
                                <div
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    {this.buildContent()}
                                </div>
                            </CSSTransition>
                        </TransitionGroup>

                        <Footer
                            showArrow={
                                this.state.step > Step.initial &&
                                this.state.step < Step.final
                            }
                            title={this.getFooterTitle()}
                        />
                    </div>
                </div>
                {this.state.showAddVariablePopup && (
                    <AddVariablePopup
                        config={this.getConfig()}
                        clusterNames={
                            this.state.finding &&
                            this.state.scatterVariable.value === "%count"
                                ? this.state.finding.content[0].graphics.map(
                                      (item) => item.name
                                  )
                                : undefined
                        }
                        onClose={() => {
                            this.setState({ showAddVariablePopup: false });
                        }}
                    />
                )}
                {this.buildAddToRecipesPopup()}
            </div>
        );
    }
}

export { MainComponent };
