export function visibilityChangeProps(
    document: Document & { msHidden?: boolean; webkitHidden?: boolean }
) {
    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
    let supported = !(
        typeof document.addEventListener === "undefined" || hidden === undefined
    );
    return {
        supported: supported,
        hidden: hidden,
        visibilityChange: visibilityChange,
    };
}