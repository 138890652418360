import React from "react";
import {
	defaultErrorColors,
} from "common/Canvas";
import StringUtils from "common/utilities/StringUtils";
import styles from "./LeadersLaggersSection.module.css";
import ColorPicker from "common/ColorPicker";
import Finding from "common/Finding";

interface Props {
	onChange: (finding: Finding, updateData?: boolean) => void;
	finding: Finding | null;
}

const ErrorColorsChanger = (props: Props) => {
	let errorColors = props.finding?.config?.arrowIndicatorColumn?.errorColors ?? defaultErrorColors;

	return (
		<>
			{["positive", "negative", "neutral"].map((key, index) => {
				return (
					<div key={index} className={styles.colorContainer}>
						<span className={styles.selectLabel}>
							{StringUtils.capitalize(key)}
						</span>
						<ColorPicker
							overlayStyle={{ zIndex: 100000000 }}
							inPopup
							enableAlpha
							tooltip={`${key} color`}
							value={
								errorColors[
								key as "positive" | "negative" | "neutral"
								]
							}
							onChange={(newValue) => {
								if (!props.finding) return;

								const newFinding: Finding = {
									...props.finding,
									config: {
										...props.finding?.config,
										arrowIndicatorColumn: {
											...props.finding?.config.arrowIndicatorColumn,
											errorColors: {
												...errorColors,
												[key]: newValue,
											},
										}
									}
								}
								props.onChange(newFinding);
							}}
						/>
					</div>
				);
			})}
		</>
	)
}

export default ErrorColorsChanger;
