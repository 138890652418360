import axios from "../ServerConnection";
import {
    ChoroplethLevel,
    MapFinding
} from "../Finding";
import "leaflet/dist/leaflet.css";
import Leaflet from "leaflet";
import * as d3 from "d3";
import _ from "lodash";
import StringUtils from "common/utilities/StringUtils";
import { getTextSize } from "common/utilities/MeasureText";
import processUrl from "common/processUrl";
import {
    MapVariableOption,
} from "common/Canvas";
import { defaultItemHeight,
    defaultItemWidth } from "./consts"
import {
    useMapEvents } from "react-leaflet";
import {
    ListenerProps,
    TooltipItemProps} from "./types"



export async function getPlaceBoundaries(
    uniqueValues: string[],
    level: ChoroplethLevel,
    callback: (response: {
        success: boolean;
        error_msg: string;
        boundaries: { [key: string]: number[][] | number[][][] };
    }) => void
): Promise<void> {
    let batchList: any[] = [];
    // let fullResponse: { success: boolean; error_msg: string; boundaries: { [key: string]: number[][] | number[][][]; }; } = {
    //     success: true,
    //     error_msg: '',
    //     boundaries: {}
    // }
    let endpoint = "/api/e/zipcode_boundaries";
    if (level === "zipcode_us") {
        endpoint = "/api/e/zipcode_boundaries";
    } else if (level === "county_us") {
        endpoint = "/api/e/county_boundaries";
    } else {
        return;
    }

    batchList.push(uniqueValues);

    const chunkSize = 500;
    for (let i = 0; i < uniqueValues.length; i += chunkSize) {
        batchList.push(uniqueValues.slice(i, i + chunkSize))
    }

    await Promise.all(
        batchList.map((batch) => {
            return axios.post<{
                success: boolean;
                error_msg: string;
                boundaries: { [key: string]: number[][] | number[][][] };
            }>(endpoint, { values: batch })
        })
    ).then((results) => {
        callback(results.flatMap((x) => x.data)[0])
    })
}

export function getPlacePathData(
  map: any,
  polygon: number[][] | number[][][] | undefined
): string {
  let path = d3.path();
  let point: { x: number; y: number };

  if (polygon == null) {
      return "";
  } else if (typeof polygon[0][0] === "number") {
      // polygon is number[][]
      let first = true;
      for (let latLng of polygon) {
          // The order of coordinates in this data is longitude, then latitude
          point = map.latLngToLayerPoint(
              Leaflet.latLng(latLng[1] as number, latLng[0] as number)
          );
          if (first) {
              path.moveTo(point.x, point.y);
              first = false;
          } else {
              path.lineTo(point.x, point.y);
          }
      }
      path.closePath();
  } else {
      // polygon is number[][][]
      for (let subpolygon of polygon) {
          let first = true;
          for (let latLng of subpolygon as number[][]) {
              // The order of coordinates in this data is longitude, then latitude
              point = map.latLngToLayerPoint(
                  Leaflet.latLng(latLng[1] as number, latLng[0] as number)
              );
              if (first) {
                  path.moveTo(point.x, point.y);
                  first = false;
              } else {
                  path.lineTo(point.x, point.y);
              }
          }
          path.closePath();
      }
  }

  return path.toString();
}

export function filterAdditionalMapData(
  finding: MapFinding,
  filterAdditionalMapVariableIndex: number | null,
  filterVariableIndex: number
): any {
  let findingData = _.clone(finding.content.data) || {};
  let findingHeatMapData = _.clone(finding.content.heatMapData) || [];
  let findingChoroplethData =
      _.clone(finding.content.choroplethData) || [];
  const filteredIndicies: number[] = [];

  findingData[filterVariableIndex]?.forEach((item, idx) => {
      if (
          findingData[filterVariableIndex][
              filterAdditionalMapVariableIndex!
          ] === item
      ) {
          filteredIndicies.push(idx);
      }
  });

  if (
      filterAdditionalMapVariableIndex !== null &&
      finding.config.isAdditional &&
      finding.config.filterAdditionalMapVariable
  ) {
      if (finding.type === "maps_pins") {
          Object.keys(findingData).forEach((key) => {
              // fitlering for pins
              let newFindingData: any = [];
              filteredIndicies.forEach((idx) => {
                  newFindingData.push(findingData[key][idx]);
              });
              findingData[key] = newFindingData;
              newFindingData = [];
          });
      }
      findingHeatMapData = findingHeatMapData.filter(
          (
              item,
              idx // filtering for heatmap and bubble
          ) => filteredIndicies.includes(idx)
      );
      findingChoroplethData = findingChoroplethData.filter(
          (
              item,
              idx // filtering for choropleth
          ) => filteredIndicies.includes(idx)
      );
  }

  return {
      findingData,
      findingHeatMapData,
      findingChoroplethData,
  };
}


/*!
 * function tooltipItemPosition calculates position of tooltip item
 * in tooltip
 */
export function tooltipItemPosition(
  option: MapVariableOption,
  defaultY: number,
  widthOffset: number
): {
  x: number;
  y: number;
} {
  let position = {
      x: (option.options.x ?? 10) - widthOffset,
      y: option.options.y ?? defaultY,
  };
  return position as {
      x: number;
      y: number;
  };
}



/*!
 * function tooltipItemPosition calculates size of tooltip item
 * in tooltip
 */
export function tooltipItemSize(
  option: MapVariableOption,
  defaultWidth?: number,
  defaultHeight?: number
): {
  width: number;
  height: number;
} {
  let itemSize = {
      width: option.options?.width ?? defaultWidth ?? defaultItemWidth,
      height: option.options?.height ?? defaultHeight ?? defaultItemHeight,
  };
  return itemSize;
}


/*!
 * function tooltipItemRect defines scaled
 * rectangle of tooltip item
 */
export function tooltipItemRect(props: TooltipItemProps) {
  let option = props.option;
  let isImageUrl = false;
  let isUrl = false;
  let isVideoUrl = false;
  if (typeof props.value === "string") {
      isUrl = StringUtils.isValidHttpUrl(props.value as string);
      isImageUrl = StringUtils.isImageUrl(props.value as string);
      isVideoUrl =
          StringUtils.isVideoUrl(props.value) ||
          processUrl(props.value) != null;
  }
  if (props.linkValue != null) {
      isImageUrl = false;
      isUrl = true;
      isVideoUrl = false;
  }
  let showTitle = option.options.showTitle ?? true;
  let position = tooltipItemPosition(
      props.option,
      props.defaultY,
      props.widthOffset
  );
  position.x = position.x * props.scale;
  position.y = position.y * props.scale;
  let itemSize = tooltipItemSize(
      props.option,
      props.defaultWidth,
      props.defaultHeight
  );
  let targetValue = "";
  if (
      option.options.width == null &&
      option.options.height == null &&
      !props.editable &&
      !isImageUrl &&
      !isVideoUrl &&
      (!isUrl || (isUrl && props.linkValue != null))
  ) {
      if (isUrl) {
          targetValue = String(props.linkValue ?? props.value);
      } else
          targetValue = showTitle
              ? `${option.variable.label} : ${props.value}`
              : `${props.value}`;
      itemSize = getTextSize(
          targetValue,
          "Roboto",
          option.options.fontSize * props.scale,
          "normal"
      );
      itemSize.width += 5;
  } else {
      itemSize.width = itemSize.width * props.scale;
      itemSize.height = itemSize.height * props.scale;
  }
  return {
      x: position.x,
      y: position.y,
      width: itemSize.width,
      height: itemSize.height,
  };
}


/*!
 * funciton mapListener checks if center or zoom of map are changed
 * and saves changes to MapElement
 */
export function MapListener(props: ListenerProps) {
  const mapEvents = useMapEvents({
      zoomend: () => {
          let zoom = mapEvents.getZoom();
          let center = mapEvents.getCenter();
          props.onChange({
              ...props.mapFinding,
              config: {
                  ...props.mapFinding.config,
                  zoom: zoom,
                  center: { lat: center.lat, lng: center.lng },
              },
          });
      },
      moveend: () => {
          if (props.moveendDisabledRef.current) {
              return;
          }
          let zoom = mapEvents.getZoom();
          let center = mapEvents.getCenter();
          props.onChange({
              ...props.mapFinding,
              config: {
                  ...props.mapFinding.config,
                  zoom: zoom,
                  center: { lat: center.lat, lng: center.lng },
              },
          });
      },
  });
  return null;
}
