import React from "react";

export function MySvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                d="M 18.750075,5.4893184 H 8.2500752 c -1.275,0 -2.25,0.975 -2.25,2.2499998 v 0 c 0,1.2749998 0.975,2.2499998 2.25,2.2499998 H 18.750075 v 11.25"
                style={{
                    fill: "none",
                    strokeWidth: 1.5,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 10,
                }}
            />
            <path
                d="M 18.750075,21.239318 H 8.2500752 c -1.275,0 -2.25,-0.975 -2.25,-2.25 v 0 -11.2499998"
                style={{
                    fill: "none",
                    strokeWidth: 1.5,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 10,
                }}
            />
            <line
                x1="18.750076"
                x2="8.2500753"
                y1="7.7393188"
                y2="7.7393188"
                style={{
                    fill: "none",
                    strokeWidth: 1.5,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 10,
                }}
            />
            <path
                d="m 13.500075,0.9893184 h -10.5 c -1.275,0 -2.25,0.9750001 -2.25,2.25 v 0 c 0,1.275 0.975,2.25 2.25,2.25 h 6.0000002"
                style={{
                    fill: "none",
                    strokeWidth: 1.5,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 10,
                }}
            />
            <path
                d="M 6.0000752,16.739318 H 3.000075 c -1.275,0 -2.25,-0.975 -2.25,-2.25 v 0 -11.2499996"
                style={{
                    fill: "none",
                    strokeWidth: 1.5,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 10,
                }}
            />
            <line
                x1="13.500075"
                x2="3.0000753"
                y1="3.2393188"
                y2="3.2393188"
                style={{
                    fill: "none",
                    strokeWidth: 1.5,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 10,
                }}
            />
        </svg>
    );
}