import React, { Component, CSSProperties } from "react";
import Select, { createFilter } from "react-select";
import { Field } from "./MyStripeForm";
import customSelectStyles from "common/SelectStyles";
export type PaymentType = "one_time" | "monthly" | "yearly";
export type PaymentOption = { label: string; value: PaymentType };

const paymentOptions: ReadonlyArray<PaymentOption> = [
    {
        label: "One Time",
        value: "one_time",
    },
    {
        label: "Monthly",
        value: "monthly",
    },
    {
        label: "Yearly",
        value: "yearly",
    },
];

type Changes = {
    client: string;
    name: string;
    paymentType: PaymentType;
    price: string;
    logo: string;
};

interface Props {
    client: string;
    name: string;
    paymentType: PaymentType;
    price: string;
    logo: string;
    onChange: <T extends keyof Changes>(changes: Pick<Changes, T>) => void;
    style?: CSSProperties;
}

class ProductParametersInput extends Component<Props> {
    public render(): JSX.Element {
        return (
            <div className="section-main" style={this.props.style}>
                <div className="section-content">
                    <form className="Form">
                        <fieldset className="FormGroup">
                            <Field
                                label="Creator Name"
                                id="client"
                                type="text"
                                placeholder="Frodo Baggins"
                                required
                                autoComplete="client"
                                value={this.props.client}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.props.onChange({ client: value });
                                }}
                            />
                            <Field
                                label="App Name"
                                id="product"
                                type="text"
                                placeholder=""
                                required
                                autoComplete="product"
                                value={this.props.name}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.props.onChange({ name: value });
                                }}
                            />

                            <div className="FormRow">
                                <label
                                    htmlFor={"type"}
                                    className="FormRowLabel"
                                    style={{ width: "11em" }}
                                >
                                    {"Payment Type"}
                                </label>
                                <Select
                                    name="type"
                                    id="type"
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={"Select page"}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            height: "38px",
                                            flex: 1,
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 1000,
                                        }),
                                    }}
                                    options={paymentOptions}
                                    value={paymentOptions.find(
                                        (o) =>
                                            o.value === this.props.paymentType
                                    )}
                                    onChange={(newValue) => {
                                        this.props.onChange({
                                            paymentType: (newValue as PaymentOption)
                                                .value,
                                        });
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                    })}
                                />
                            </div>
                            <Field
                                label="App Price&emsp;&emsp;&emsp;&emsp;&emsp;$"
                                id="price"
                                type="number"
                                placeholder="9.99"
                                required
                                autoComplete="price"
                                value={this.props.price}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.props.onChange({ price: value });
                                }}
                            />
                            <Field
                                label="Logo URL"
                                id="logo"
                                type="text"
                                placeholder="Logo URL"
                                required
                                autoComplete="logo"
                                value={this.props.logo}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.props.onChange({ logo: value });
                                }}
                            />
                        </fieldset>
                    </form>
                </div>
            </div>
        );
    }
}

export default ProductParametersInput;
