import React from "react";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";
import { toJS, reaction } from "mobx";

import BaseStepModule, { StepState } from "./BaseStepModule";
import customSelectStyles from "common/SelectStyles";
import axios from "common/ServerConnection";
import db from "common/db";
import tables from "common/Tables";
import variables from "common/Variables";
import models from "common/Models";
import dataScopes from "common/DataScopes";
import { Permission } from "common/Permissions";

function statsmodelsEscapeName(name) {
    // If name is simple
    if (name.match(/^[a-zA-Z_][a-zA-Z0-9_]*$/) !== null) {
        return name;
    } else {
        return `Q('${name.replace(/\\/g, "\\\\").replace(/'/g, "\\'")}')`;
    }
}

const regressionModelOptions = Object.freeze([
    "smf.ols",
    "smf.gls",
    "smf.glm",
    "smf.mixedlm",
]);

const glmDefault = Object.freeze({
    binomial: "logit",
    gamma: "inverse",
    gaussian: "identity",
    inverse_gaussian: "inverse_squared",
    poisson: "log",
});

const glmOptions = Object.freeze({
    binomial: ["logit", "probit", "cauchy", "log", "cloglog"],
    gamma: ["log", "identity", "inverse"],
    gaussian: ["log", "identity", "inverse"],
    inverse_gaussian: ["inverse_squared", "log", "identity", "inverse"],
    poisson: ["log", "identity", "sqrt"],
});

const defaultStatsmodelsModel = "smf.ols";
const defaultGLMFamily = "binomial";
const defaultGLMLink = "logit";
const defaultStatsmodelsFunctionLeft = "np.log(";
const defaultStatsmodelsFunctionRight = ")";
const defaultStatsmodelsFormula = (variables) =>
    variables
        .map((variable) => `np.log(${statsmodelsEscapeName(variable)})`)
        .join(" + ");
const defaultStatsmodelsInverseFunction = "lambda x: np.exp(x)";

const defaultStatsmodelsInput = (dataScope) => {
    if (dataScope === undefined) {
        let options = dataScopes.dataScopesOptions.filter(
            (option) => option.permissionType === Permission.ReadWrite
        );
        dataScope =
            options.length > 0
                ? options[0]
                : undefined;
    }
    let dataScopeValue = dataScope ? dataScope.value : undefined;

    return {
        // Table
        dataScope: toJS(dataScope),
        modelName: "New model",
        table: tables(dataScopeValue).rawAndAggregateTableOptions[0],
        // Model (e.g. 'smf.ols')
        model: defaultStatsmodelsModel,
        // glm family
        glmFamily: defaultGLMFamily,
        // glm link
        glmLink: defaultGLMLink,
        // Function of the dependent variable, left part (e.g. 'np.log(')
        functionLeft: defaultStatsmodelsFunctionLeft,
        // The dependent variable (e.g. 'var18')
        outcome:
            variables(dataScopeValue).numericVariables.length > 0
                ? variables(dataScopeValue).numericVariables[0].name
                : undefined,
        // Function of the dependent variable, right part (e.g. ')')
        functionRight: defaultStatsmodelsFunctionRight,
        // Formula (e.g. 'var4 + var5 + var6')
        formula:
            typeof variables(dataScopeValue).numericVariables === "undefined"
                ? undefined
                : defaultStatsmodelsFormula(
                      variables(dataScopeValue)
                          .numericVariables.slice(1)
                          .map((item) => item.name)
                  ),

        randomEffectsFormula:
            typeof variables(dataScopeValue).numericVariables === "undefined"
                ? undefined
                : defaultStatsmodelsFormula(
                      variables(dataScopeValue)
                          .numericVariables.slice(1)
                          .map((item) => item.name)
                  ),
        optimizationPanel: undefined,
        // Inverse function (e.g. 'lambda x: np.exp(x)')
        inverseFunction: defaultStatsmodelsInverseFunction,
    };
};

const TrainModelStep = observer(
    class TrainModelStep extends BaseStepModule {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                expanded: false,
                input: defaultStatsmodelsInput(),
                // We memorize the last input if user presses up arrow
                lastInput: defaultStatsmodelsInput(),
                result: "",
                lastId: null,
                // Kill button must be disabled before the process started
                killButtonDisabled: true,
            };
            this.dataScopeValue = this.dataScopeValue.bind(this);
            this.initializeVariables = this.initializeVariables.bind(this);
            this.switchVariables = this.switchVariables.bind(this);
        }

        componentDidMount() {
            db.table("processingStatsmodels_" + this.props.tag)
                .toCollection()
                .last()
                .then((obj) => {
                    if (typeof obj !== "undefined") {
                        let id = obj.id;
                        let newInput = {};
                        for (let key of Object.keys(defaultStatsmodelsInput()))
                            newInput[key] = obj[key];
                        this.setState({
                            lastId: id,
                            input: newInput,
                        });
                    }
                })
                .catch((e) => {});
            this.evaluateStatsmodelsStatus();
            this.interval = setInterval(() => {
                if (this.state.stepState === StepState.running) {
                    this.evaluateStatsmodelsStatus();
                }
            }, 10000);
            this.switchVariables();
        }
        initializeVariables() {
            this.setState((state) => ({
                input: defaultStatsmodelsInput(state.input.dataScope),
            }));
        }
        switchVariables() {
            if (this.initializeVariablesReaction)
                this.initializeVariablesReaction();
            this.initializeVariables();
            this.initializeVariablesReaction = reaction(
                () => variables(this.dataScopeValue()).dataVariables,
                () => {
                    this.initializeVariables();
                }
            );
        }
        componentWillUnmount() {
            clearInterval(this.interval);
            if (this.initializeVariablesReaction)
                this.initializeVariablesReaction();
        }
        dataScopeValue() {
            if (this.state.input.dataScope)
                return this.state.input.dataScope.value;
            return undefined;
        }
        onStatsmodelsInputKeyPress(e) {
            if (e.key === "ArrowUp") {
                e.preventDefault();
                let request;
                let noInput = this.state.lastId === null;
                if (noInput) {
                    request = db
                        .table("processingStatsmodels_" + this.props.tag)
                        .toCollection()
                        .last();
                } else {
                    request = db
                        .table("processingStatsmodels_" + this.props.tag)
                        .where("id")
                        .below(this.state.lastId)
                        .last();
                }
                request
                    .then((obj) => {
                        let id = obj.id;
                        let newInput = {};
                        for (let key of Object.keys(defaultStatsmodelsInput()))
                            newInput[key] = obj[key];
                        if (typeof obj !== "undefined") {
                            this.setState((state) => ({
                                lastId: id,
                                input: newInput,
                                lastInput: noInput
                                    ? state.input
                                    : state.lastInput,
                            }));
                        }
                    })
                    .catch((e) => {});
            } else if (e.key === "ArrowDown") {
                e.preventDefault();
                if (this.state.lastId !== null) {
                    db.table("processingStatsmodels_" + this.props.tag)
                        .where("id")
                        .above(this.state.lastId)
                        .first()
                        .then((obj) => {
                            if (typeof obj !== "undefined") {
                                let id = obj.id;
                                let newInput = {};
                                for (let key of Object.keys(
                                    defaultStatsmodelsInput()
                                ))
                                    newInput[key] = obj[key];
                                this.setState({
                                    lastId: id,
                                    input: newInput,
                                });
                            } else {
                                this.setState((state) => ({
                                    lastId: null,
                                    input: state.lastInput,
                                    lastInput: defaultStatsmodelsInput(),
                                }));
                            }
                        })
                        .catch((e) => {
                            this.setState((state) => ({
                                lastId: null,
                                input: state.lastInput,
                                lastInput: defaultStatsmodelsInput(),
                            }));
                        });
                }
            }
        }

        onStatsmodelsInputChange(property, newValue) {
            this.setState((state) => ({
                input: {
                    ...state.input,
                    [property]: newValue,
                },
            }));
        }

        statsmodelsMemorizeCommand() {
            let input = toJS(this.state.input);
            delete input.id;
            db.table("processingStatsmodels_" + this.props.tag)
                .add(input)
                .then((id) => {
                    this.setState({ lastId: id });
                });
        }

        evaluateStatsmodelsStart() {
            let json = {
                model: this.state.input.model,
                model_name: this.state.input.modelName,
                function_left: this.state.input.functionLeft,
                outcome: this.state.input.outcome,
                function_right: this.state.input.functionRight,
                formula: this.state.input.formula,
                inverse_function: this.state.input.inverseFunction,
                tag: this.props.tag,
                table: this.state.input.table.value,
                data_table_idx: this.state.input.table.data_table_idx,
                condition_id: this.state.input.table.condition_id,
            };
            if (json.model === "smf.glm") {
                json.glm_family = this.state.input.glmFamily;
                json.glm_link = this.state.input.glmLink;
            }
            if (json.model === "smf.mixedlm") {
                json.random_effects_formula = this.state.input.randomEffectsFormula;
                json.optimization_panel = this.state.input.optimizationPanel;
            }
            this.setStepState(StepState.running, "", {
                killButtonDisabled: true,
            });
            axios
                .post("/api/evaluate_statsmodels_start", json, null)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        this.setState({ killButtonDisabled: false });
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 401 && error.response.status !== 403) {
                        this.setStepState(
                            StepState.error,
                            error.response.status.toString()
                        );
                    }
                });
        }

        evaluateStatsmodelsStatus() {
            let json = {
                tag: this.props.tag,
            };
            axios
                .post("/api/evaluate_statsmodels_status", json, null)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        let text = null;
                        let additionalChanges = {};
                        if (response.data.status === StepState.error) {
                            text = response.data.result;
                        } else if (
                            response.data.status === StepState.finished
                        ) {
                            additionalChanges = (state) => ({
                                result: response.data.result,
                            });
                        }
                        this.setStepState(
                            response.data.status,
                            text,
                            additionalChanges
                        );
                        if (response.data.status === StepState.finished)
                            models(this.dataScopeValue()).update();
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 401 && error.response.status !== 403) {
                        this.setStepState(
                            StepState.error,
                            error.response.status.toString()
                        );
                    }
                });
        }

        evaluateStatsmodelsKill() {
            let json = {
                tag: this.props.tag,
            };
            axios
                .post("/api/evaluate_statsmodels_kill", json, null)
                .then((response) => {
                    if (response.data.success === false) {
                        let errorMsg = response.data.error_msg;
                        let additionalChanges = {};
                        if (typeof response.data.result !== "undefined") {
                            if (response.data.status === StepState.error) {
                                errorMsg += `: ${response.data.result}`;
                            } else {
                                additionalChanges = (state) => ({
                                    result: response.data.result,
                                });
                            }
                        }
                        this.setStepState(
                            StepState.error,
                            errorMsg,
                            additionalChanges
                        );
                    } else {
                        this.setStepState(StepState.not_running);
                    }
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        render() {
            let rendered = [
                <div className="circle-letter" key={0}>
                    {this.props.index}
                </div>,
                <Button
                    key={1}
                    disabled={this.props.disabled}
                    className="btn-processing-tab"
                    style={{
                        height: "25px",
                    }}
                    onClick={() => {
                        this.setState((state) => ({
                            expanded: !state.expanded,
                        }));
                    }}
                >
                    <span className="content-regular-text">
                        {this.props.caption}
                    </span>
                    <span className="content-regular-text">
                        {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                    </span>
                </Button>,
                this.state.expanded ? (
                    <Button
                        key={2}
                        className="btn btn-lg btn-primary my-primary"
                        onClick={() => {
                            if (this.state.stepState === StepState.running) {
                                this.evaluateStatsmodelsKill();
                            } else {
                                db.table(
                                    "processingStatsmodels_" + this.props.tag
                                )
                                    .toCollection()
                                    .last()
                                    .then((obj) => {
                                        this.statsmodelsMemorizeCommand();
                                    })
                                    .catch((e) => {});
                                this.evaluateStatsmodelsStart();
                            }
                        }}
                        style={{
                            fontFamily: "Roboto",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            height: "25px",
                            width: "100%",
                        }}
                        disabled={
                            this.state.stepState === StepState.running
                                ? this.state.killButtonDisabled
                                : false
                        }
                    >
                        {this.state.stepState === StepState.running
                            ? "KILL"
                            : "RUN"}
                    </Button>
                ) : (
                    <div key={2} />
                ),
                <div key={3}>
                    {this.state.expanded && this.renderStepState()}
                </div>,
            ];

            if (this.state.expanded) {
                rendered = rendered.concat([
                    <div />,
                    <div
                        style={{
                            gridColumnStart: 2,
                            gridColumnEnd: 4,
                            background: "#3f4b62",
                            padding: "10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {"Enter model name"}
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    marginLeft: "11px",
                                    width: "15em",
                                }}
                                placeholder=""
                                onChange={(e) =>
                                    this.onStatsmodelsInputChange(
                                        "modelName",
                                        e.target.value
                                    )
                                }
                                value={this.state.input.modelName}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                }}
                            >
                                Select data
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={dataScopes.dataScopesOptions.filter(
                                    (option) => option.permissionType === Permission.ReadWrite
                                )}
                                onChange={(newValue) => {
                                    this.setState({
                                        input: defaultStatsmodelsInput(
                                            newValue
                                        ),
                                    });
                                    this.switchVariables();
                                }}
                                value={this.state.input.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                Using
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                    }),
                                }}
                                options={
                                    tables(this.dataScopeValue())
                                        .rawAndAggregateTableOptions
                                }
                                onChange={(newValue) =>
                                    this.onStatsmodelsInputChange(
                                        "table",
                                        newValue
                                    )
                                }
                                value={this.state.input.table}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "8em",
                                        height: "38px",
                                        fontFamily: "monospace",
                                        marginRight: "4px",
                                    }),
                                }}
                                options={regressionModelOptions.map(
                                    (value) => ({
                                        label: value,
                                        value: value,
                                    })
                                )}
                                onChange={(newValue) =>
                                    this.onStatsmodelsInputChange(
                                        "model",
                                        newValue.value
                                    )
                                }
                                value={{
                                    label: this.state.input.model,
                                    value: this.state.input.model,
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <span className="content-regular-text">(</span>
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    fontFamily: "monospace",
                                    textAlign: "right",
                                    marginLeft: "4px",
                                    marginRight: "4px",
                                    width: "8em",
                                }}
                                placeholder=""
                                onKeyUp={(e) =>
                                    this.onStatsmodelsInputKeyPress(e)
                                }
                                onChange={(e) =>
                                    this.onStatsmodelsInputChange(
                                        "functionLeft",
                                        e.target.value
                                    )
                                }
                                value={this.state.input.functionLeft}
                            />
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "8em",
                                        height: "38px",
                                    }),
                                }}
                                options={variables(
                                    this.dataScopeValue()
                                ).numericVariables.map((value) => ({
                                    label: value.name,
                                    value: value.name,
                                }))}
                                onChange={(newValue) =>
                                    this.onStatsmodelsInputChange(
                                        "outcome",
                                        newValue.value
                                    )
                                }
                                value={{
                                    label: this.state.input.outcome,
                                    value: this.state.input.outcome,
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    fontFamily: "monospace",
                                    textAlign: "left",
                                    marginLeft: "4px",
                                    marginRight: "4px",
                                    width: "8em",
                                }}
                                placeholder=""
                                onKeyUp={(e) =>
                                    this.onStatsmodelsInputKeyPress(e)
                                }
                                onChange={(e) =>
                                    this.onStatsmodelsInputChange(
                                        "functionRight",
                                        e.target.value
                                    )
                                }
                                value={this.state.input.functionRight}
                            />
                            <span className="content-regular-text">~</span>
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    fontFamily: "monospace",
                                    textAlign: "left",
                                    marginLeft: "4px",
                                    marginRight: "4px",
                                    flex: 1,
                                }}
                                placeholder=""
                                onKeyUp={(e) =>
                                    this.onStatsmodelsInputKeyPress(e)
                                }
                                onChange={(e) =>
                                    this.onStatsmodelsInputChange(
                                        "formula",
                                        e.target.value
                                    )
                                }
                                value={this.state.input.formula}
                            />
                            <span className="content-regular-text">)</span>
                        </div>

                        {this.state.input.model === "smf.glm" && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                }}
                            >
                                <span className="content-regular-text">
                                    Family:
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "12em",
                                            height: "38px",
                                            marginLeft: "4px",
                                            marginRight: "4px",
                                        }),
                                    }}
                                    options={Object.keys(glmOptions).map(
                                        (value) => ({
                                            label: value,
                                            value: value,
                                        })
                                    )}
                                    onChange={(newValue) => {
                                        this.onStatsmodelsInputChange(
                                            "glmFamily",
                                            newValue.value
                                        );
                                        this.onStatsmodelsInputChange(
                                            "glmLink",
                                            glmDefault[newValue.value]
                                        );
                                    }}
                                    value={{
                                        label: this.state.input.glmFamily,
                                        value: this.state.input.glmFamily,
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                <span className="content-regular-text">
                                    link:
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "12em",
                                            height: "38px",
                                            marginLeft: "4px",
                                        }),
                                    }}
                                    options={glmOptions[
                                        this.state.input.glmFamily
                                    ].map((value) => ({
                                        label: value,
                                        value: value,
                                    }))}
                                    onChange={(newValue) => {
                                        this.onStatsmodelsInputChange(
                                            "glmLink",
                                            newValue.value
                                        );
                                    }}
                                    value={{
                                        label: this.state.input.glmLink,
                                        value: this.state.input.glmLink,
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                        )}
                        {this.state.input.model === "smf.mixedlm" && (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <span className="content-regular-text">
                                        Random effects formula: ~
                                    </span>
                                    <input
                                        className="like-select"
                                        style={{
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                            height: "25px",
                                            fontFamily: "monospace",
                                            textAlign: "left",
                                            marginLeft: "4px",
                                            marginRight: "4px",
                                            flex: 1,
                                        }}
                                        placeholder=""
                                        onKeyUp={(e) =>
                                            this.onStatsmodelsInputKeyPress(e)
                                        }
                                        onChange={(e) =>
                                            this.onStatsmodelsInputChange(
                                                "randomEffectsFormula",
                                                e.target.value
                                            )
                                        }
                                        value={
                                            this.state.input
                                                .randomEffectsFormula
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <span
                                        className="content-regular-text"
                                        style={{
                                            marginRight: "5px",
                                        }}
                                    >
                                        Train the following model with
                                        clustering on
                                    </span>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                width: "15em",
                                                height: "38px",
                                            }),
                                        }}
                                        options={variables(
                                            this.dataScopeValue()
                                        ).dataVariables.map((value) => ({
                                            label: value.name,
                                            value: value.name,
                                        }))}
                                        onChange={(newValue) => {
                                            this.setState((state) => ({
                                                input: {
                                                    ...state.input,
                                                    optimizationPanel:
                                                        newValue.value,
                                                },
                                            }));
                                        }}
                                        value={{
                                            label: this.state.input
                                                .optimizationPanel,
                                            value: this.state.input
                                                .optimizationPanel,
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25: "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            </>
                        )}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span className="content-regular-text">
                                Inverse function:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    fontFamily: "monospace",
                                    textAlign: "left",
                                    marginLeft: "4px",
                                    flex: 1,
                                }}
                                placeholder=""
                                onKeyUp={(e) =>
                                    this.onStatsmodelsInputKeyPress(e)
                                }
                                onChange={(e) =>
                                    this.onStatsmodelsInputChange(
                                        "inverseFunction",
                                        e.target.value
                                    )
                                }
                                value={this.state.input.inverseFunction}
                            />
                        </div>

                        <div>
                            <textarea
                                readOnly={true}
                                className="like-select element"
                                style={{
                                    minHeight: "150px",
                                    width: "100%",
                                    fontFamily: "monospace",
                                    resize: "vertical",
                                    whiteSpace: "pre",
                                }}
                                placeholder="Results"
                                value={this.state.result}
                            />
                        </div>
                    </div>,
                    <div />,
                ]);
            }
            return rendered;
        }
    }
);

export default TrainModelStep;
export { defaultStatsmodelsFormula };
