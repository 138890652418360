import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import cx from "classnames";

import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import { MapElement } from "common/Canvas";
import { getRawDataApi } from "common/DataApi";
import remoteModuleId from "common/remoteModuleId";
import Tables from "common/Tables";
import { strftime } from "common/utilities/TimeFormatUtils";
import Variables from "common/Variables";
import {
    mapAdvancedEditMenuWidth,
    sheetRibbonWidth,
} from "modules/canvas_page/Constants";

import styles from "./TablePreview.module.css";

interface Props {
    mapElement: MapElement;
    currentModuleId?: number;
    editMenuIsOpen: boolean;
    fetchingData: boolean;
}

function TablePreview({
    mapElement,
    currentModuleId,
    editMenuIsOpen,
    fetchingData,
}: Props) {
    const [loading, setLoading] = useState(false);
    const [tableHeader, setTableHeader] = useState<string[]>([]);
    const [tableContent, setTableContent] = useState<
        (string | number | null)[][]
    >([]);

    useEffect(() => {
        if (!mapElement.dataScope || !editMenuIsOpen) {
            setTableContent([]);
            setTableHeader([]);
            return;
        }

        const tableHeader = Variables(
            mapElement.dataScope?.value,
            currentModuleId ?? remoteModuleId
        ).variableNames;

        if (!tableHeader.length) {
            setTableContent([]);
            return;
        }

        const table = Tables(
            mapElement.dataScope!.value,
            currentModuleId ?? remoteModuleId
        ).tableToOption();

        const variablesInfo = tableHeader.map((name) =>
            Variables(
                mapElement.dataScope?.value,
                currentModuleId ?? remoteModuleId
            ).getVariableByName(name)
        );

        async function getData() {
            if (!mapElement.dataScope) return;

            setLoading(true);

            try {
                const data = await getRawDataApi(
                    table ?? {
                        label: "",
                        value: [],
                        optimized: false,
                        data_table_idx: mapElement.dataScope.value,
                    },
                    10,
                    undefined,
                    undefined,
                    undefined,
                    currentModuleId ?? remoteModuleId
                );

                let tableContent: (string | number | null)[][] = [];
                let rowCount = data.rowId.length;
                for (let i = 0; i < rowCount; ++i) {
                    let row: (string | number | null)[] = [];
                    for (let [j, varName] of tableHeader.entries()) {
                        if (typeof data.currentLevels[varName] === "undefined")
                            break;

                        row.push(
                            variablesInfo[j]!.type === "datetime" &&
                                typeof data.currentLevels[varName][i] ===
                                    "number"
                                ? strftime(
                                      variablesInfo[j]!.format!,
                                      new Date(
                                          (data.currentLevels[varName][
                                              i
                                          ] as number) * 1000
                                      )
                                  )
                                : data.currentLevels[varName][i] ||
                                      (typeof data.currentLevels[varName][i] ===
                                      "number"
                                          ? data.currentLevels[varName][i]
                                          : "-")
                        );
                    }
                    tableContent.push(row);
                }

                setTableContent(tableContent);
                setTableHeader(tableHeader);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, [currentModuleId, mapElement.dataScope, editMenuIsOpen, fetchingData]);

    return (
        <div
            className={cx(styles.root, {
                [styles.open]: editMenuIsOpen && mapElement.dataScope,
            })}
            style={{
                left: mapAdvancedEditMenuWidth + 5,
                right: sheetRibbonWidth + 5,
            }}
        >
            <div className={styles.header}>
                {mapElement.dataScope?.label && (
                    <h6 className={styles.headerTitle}>
                        {mapElement.dataScope.label}
                    </h6>
                )}
                {!!tableHeader.length && (
                    <span
                        className={styles.headerTableDescription}
                    >{`${tableHeader.length} columns, ${tableContent.length} rows`}</span>
                )}
            </div>
            <div
                style={{
                    marginLeft: "17px",
                    marginRight: "17px",
                    display: "flex",
                    justifyContent: "center",
                    overflow: "hidden",
                }}
            >
                {loading && <h1>Loading...</h1>}
                {!!tableHeader.length && !loading && (
                    <Element
                        name="scrollable"
                        style={{
                            padding: "10px",
                            overflow: "auto",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                        }}
                    >
                        <AdminTableWithFullFeatures
                            headerRenderer={(item, index) => (
                                <div className="flex-simple-column" key={index}>
                                    <div
                                        className="my-row"
                                        style={{
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: "Open Sans",
                                                fontWeight: 600,
                                                fontSize: "10px",
                                                color: "black",
                                            }}
                                        >
                                            {item}
                                        </span>
                                    </div>
                                </div>
                            )}
                            className="import-table"
                            paging={false}
                            small={false}
                            tableName="Data"
                            tableHeader={tableHeader}
                            tableContent={tableContent}
                        />
                    </Element>
                )}
            </div>
        </div>
    );
}

export default TablePreview;
