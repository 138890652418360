import React, { useState } from "react";

import { colorList } from "../LineColors";
//import db from "./db";
//import InsightsType from "./InsightsType";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";
import { useDrop } from "react-dnd";

import {
    LabelList,
    FunnelChart,
    Funnel,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";
import { FunnelPlotFinding } from "common/Finding";
import { TooltipStyles } from "../TooltipStyles";
import Popup from "reactjs-popup";
import { chartColorPickerPopupStyles } from "common/Constants";
import ColorPicker from "./ChartColorPicker";

interface FunnelProps {
    data: FunnelPlotFinding["content"]["data"];
    config: FunnelPlotFinding["config"];
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
    dataSetMenuIsOpen?: boolean;
    nodePosition?: {
        x: number;
        y: number;
    };
    onChangeData?: (data: any[], updateFinding?: boolean) => void;
    onChangeConfig?: (config: any, updateFinding?: boolean) => void;
}

function formatter(value: string | number) {
    if (typeof value === "string" || typeof value === "number") {
        let formattedValue = formatValue(value, false);

        let result = formattedValue[0].concat(formattedValue[1]);
        return result;
    }
    return value;
}

function sortFunnel(
    a: { [key: string]: string | number },
    b: { [key: string]: string | number },
    sortKey: string
): -1 | 0 | 1 {
    let aVal = a[sortKey];
    let bVal = b[sortKey];
    if (aVal < bVal) {
        return -1;
    }
    if (aVal > bVal) {
        return 1;
    }
    return 0;
}

function prepareFunnelData(data: FunnelPlotFinding["content"]["data"]) {
    if (data.length < 2) return [];
    let result = [];
    let valuesLength = Math.min(data[0].value.length, data[1].value.length);
    if (valuesLength === 0) return [];
    for (let i = 0; i < valuesLength; ++i) {
        result.push({
            [data[0].name]: data[0].value[i],
            [data[1].name]: data[1].value[i],
        });
    }
    result.sort((a, b) => -1 * sortFunnel(a, b, data[1].name));
    let maxValue: number = result[0][data[1].name] as number;
    for (let i = 0; i < valuesLength; ++i) {
        result[i]["%percentage"] =
            ((result[i][data[1].name] as number) * 100) / maxValue;
    }
    return result;
}

export default function FunnelPlot(props: FunnelProps) {
    let [showColorPicker, setShowColorPicker] = useState<{
        x: number,
        y: number,
    } | null>(null);

    let currentEditVariableIndex: number | undefined = undefined;
    if (props.config.dataScope != null) {
        currentEditVariableIndex = props.data.findIndex(
            (item) => item.variableIndex == null
        );
    }
    let [lastModifiedIndex, setLastModifiedIndex] = React.useState<
        number | undefined
    >(undefined);
    if (props.config.dataScope != null && currentEditVariableIndex === -1) {
        if (lastModifiedIndex == null) currentEditVariableIndex = 0;
        else currentEditVariableIndex = (lastModifiedIndex + 1) % 2;
    }
    const dropRef = React.useRef(null);
    const [collected, drop] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            if (
                props.onChangeData != null &&
                currentEditVariableIndex != null
            ) {
                setLastModifiedIndex(currentEditVariableIndex);
                let newData = Array.from(props.data);
                newData[currentEditVariableIndex].name =
                    otherItem.content.variableName;
                newData[currentEditVariableIndex].originalName =
                    otherItem.content.variableName;
                newData[currentEditVariableIndex].variableIndex =
                    otherItem.content.variableIndex;
                props.onChangeData?.(newData, true);
            }
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    if (props.editable) {
        drop(dropRef);
    }
    let ref = React.useRef<typeof ResponsiveContainer>(null);
    let [containerHeight, setContainerHeight] = React.useState(0);
    React.useEffect(() => {
        let newContainerHeight = ((ref.current as any)?.current as any)
            ?.clientHeight;
        if (containerHeight !== newContainerHeight) {
            setContainerHeight(newContainerHeight);
        }
        // containerHeight should not be in dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    let preparedData = prepareFunnelData(props.data);
    if (preparedData == null) return null;
    let labelVariable = props.data[0].name;
    let selectedVariable = props.data[1].name;
    let labelsColor = props.preview
        ? "#FFFFFF"
        : props.config.labelsColor ??
          mainStyle.getPropertyValue("--graphs-axes-text-color").trim();
    let valuesColor = props.preview
        ? "#FFFFFF"
        : props.config.valuesColor ??
          mainStyle.getPropertyValue("--graphs-axes-text-color").trim();
    let funnelColor = props.preview
        ? "#A6A6A6"
        : props.config.funnelColor ?? colorList[1];
    let labelsFontSize =
        props.config.labelsFontSize ??
        parseFloat(mainStyle.getPropertyValue("--graphs-axes-size").trim());
    let valuesFontSize =
        props.config.valuesFontSize ??
        parseFloat(mainStyle.getPropertyValue("--graphs-axes-size").trim());

    let plot = (
        <div
            ref={dropRef}
            className="flex-simple-column"
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            <div style={{ marginTop: 10, height: "100%", overflow: "hidden" }}>
                <ResponsiveContainer ref={ref} width="100%" height="99%">
                    <FunnelChart>
                        {sections._Global.watermark != null && (
                            <text
                                x="50%"
                                y="30%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--content-secondary-text-color"
                                )}
                                fontSize={"4rem"}
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {sections._Global.watermark}
                            </text>
                        )}
                        <RechartsTooltip
                            formatter={(
                                _value: number,
                                _name: string,
                                _props: any
                            ) => {
                                let originalValue =
                                    _props.payload[selectedVariable];
                                return formatter(originalValue);
                            }}
                            {...TooltipStyles(props.config?.tooltipColor, props.config?.tooltipFontSize)}
                        />

                        <Funnel
                            isAnimationActive={false}
                            data={preparedData}
                            nameKey={labelVariable}
                            dataKey={"%percentage"}
                            fill={funnelColor}
                            onClick={(evt) => {
                                setShowColorPicker({
                                    x: (props.nodePosition?.x ?? 0) + evt.x + evt.width,
                                    y: (props.nodePosition?.y ?? 0) + evt.y + (evt.parentViewBox.height / 2),
                                });
                            }}
                        >
                            <LabelList
                                position="insideTop"
                                fill={labelsColor}
                                offset={
                                    containerHeight /
                                        (2 * preparedData.length) -
                                    labelsFontSize
                                }
                                fontSize={labelsFontSize}
                                stroke="none"
                                dataKey={labelVariable}
                                {...{
                                    formatter: formatter,
                                }}
                            />
                            <LabelList
                                position="insideTop"
                                fill={valuesColor}
                                offset={
                                    containerHeight / (2 * preparedData.length)
                                }
                                fontSize={valuesFontSize}
                                stroke="none"
                                dataKey={selectedVariable}
                                {...{
                                    formatter: formatter,
                                }}
                            />
                        </Funnel>
                    </FunnelChart>
                </ResponsiveContainer>
            </div>
            {props.columnDragActive && (
                <div
                    style={{
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        display:
                            props.editable &&
                            props.dataSetMenuIsOpen &&
                            !props.preview
                                ? "flex"
                                : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        border: `dashed 3px ${
                            collected.hover ? "#36B743" : "#8DB8E3"
                        }`,
                        backgroundColor: collected.hover
                            ? "#F4FBF577"
                            : "#EBF2F977",
                    }}
                >
                    <span
                        className="no-selection"
                        style={{
                            color: "#333",
                            fontSize: "14px",
                            fontWeight: 500,
                        }}
                    >
                        {`Drop ${
                            currentEditVariableIndex === 0
                                ? "variables"
                                : "values"
                        } here`}
                    </span>
                </div>
            )}
            {showColorPicker && (
                <Popup
                    arrow={true}
                    contentStyle={{
                        ...chartColorPickerPopupStyles,
                        left: showColorPicker.x,
                        top: showColorPicker.y,
                    }}
                    open={true}
                    onClose={() => {
                        setShowColorPicker(null);
                    }}
                    nested={true}
                    closeOnDocumentClick
                >
                    <ColorPicker
                        enableAlpha={true}
                        width={"220px"}
                        color={
                            props.config.funnelColor ?? colorList[0]
                        }
                        onChange={(color) => {
                            let newConfig = {
                                ...props.config,
                                funnelColor: color,
                            };
                            props.onChangeConfig?.(newConfig);
                        }}
                    />
                </Popup>
            )}
        </div>
    );
    return plot;
}
