import React, { Component } from "react";
import { StylesConfig, GroupBase } from "react-select";

import CustomSelect from "common/CustomSelect";
import { monthName } from "common/MonthData";
import axios, { SocketIOInstance } from "./ServerConnection";
//import { mainStyle } from "./MainStyle";
import { searchComponentStyles } from "./SelectStyles";
import Variables from "common/Variables";
import { strftime, strptime } from "common/utilities/TimeFormatUtils";
import { Condition, conditionsToJson } from "common/Conditions";
import _ from "lodash";
import remoteModuleId from "common/remoteModuleId";
import { reaction } from "mobx";
import {
    SearchComponentOptionWithMeta,
    selectAllOptions,
} from "./SearchComponentOptionWithMeta";
import { DropdownStyle, NodeValue } from "./Canvas";
import Switch from "react-switch";
import mobileBreakpoint from "./utilities/UIResponsiveManager";

export type SearchComponentValue = NodeValue | null;
export interface SearchComponentOption {
    label: string;
    value: SearchComponentValue;
}

const maxAllValuesCount = 100;

interface Props {
    keepColumnOrder?: boolean;
    allowNewOption?: boolean;
    formatCreateLabel?: (userInput: string) => string;
    createOptionPosition?: "first" | "last";
    allowSelectAllOption?: boolean;
    allValues?: boolean;
    isDisabled?: boolean;
    isMulti?: boolean;
    customIndicators?: boolean;
    clearable?: boolean;
    backspaceRemovesValue?: boolean;
    filterMissing?: boolean;
    dataScopeId: number | string | undefined;
    customStyles: StylesConfig<
        SearchComponentOption,
        false,
        GroupBase<SearchComponentOption>
    >;
    initialValue: SearchComponentOption | null | SearchComponentOption[];
    searchIndex: number;
    onOptionSelected: (
        value: SearchComponentOption | SearchComponentOption[] | null,
        allValues?: boolean
    ) => void;
    onSelectAllOptions?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    additionalOptions?: { [key: string]: any };
    additionalStyles?: { [key: string]: any };
    onKeyDown?: (evt: any) => void;
    conditions?: Condition[];
    menuPortalTarget?: HTMLElement | null;
    currentModuleId?: number | string;
    rootDataTestId?: string;
    theme?: DropdownStyle;
    switch?: boolean;
}

interface State {
    allOptions: SearchComponentOption[];
    options: SearchComponentOption[];
    allItems: SearchComponentValue[];
    switch: boolean;
    switchOptions: Array<any>;
}

export default class SearchComponent extends Component<Props, State> {
    selectRef: React.RefObject<CustomSelect>;
    dataVariablesReaction: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            allOptions: [],
            options: [],
            allItems: [],
            switch: false,
            switchOptions: [],
        };
        this.selectRef = React.createRef();

        this.onDatasetChanges = this.onDatasetChanges.bind(this);
    }

    public focus(): void {
        if (this.selectRef.current != null) this.selectRef.current.focus();
    }

    private mapValuesToOption(
        values: SearchComponentValue[]
    ): SearchComponentOption[] {
        // mainStyle.getPropertyValue("--primary-content-color")
        let variable = Variables(
            this.props.dataScopeId,
            this.props.currentModuleId ?? remoteModuleId
        ).getVariableByIndex(this.props.searchIndex);
        return values.map(
            (item: SearchComponentValue): SearchComponentOption => {
                if (item != null) {
                    if (item === "") {
                        return {
                            label: "EMPTY STRING",
                            value: "",
                        };
                    } else {
                        return {
                            label:
                                variable?.panel === "time" &&
                                variable?.level === "month"
                                    ? monthName[Number(Number(item) - 1)] ?? ""
                                    : variable?.type === "datetime"
                                    ? strftime(
                                          variable.format!,
                                          new Date(Number(item) * 1000)
                                      )
                                    : item.toString(),
                            value: item,
                        };
                    }
                } else {
                    return {
                        label: "NULL",
                        value: null,
                    };
                }
            }
        );
    }

    private async loadOptions(input: string): Promise<void> {
        this.setState({
            allOptions: [],
            options: [],
            allItems: [],
            switchOptions: [""],
        });
        let variable = Variables(
            this.props.dataScopeId,
            this.props.currentModuleId ?? remoteModuleId
        ).getVariableByIndex(this.props.searchIndex);
        let filteredConditions: Condition[] | undefined = undefined;
        if (this.props.conditions != null) {
            filteredConditions = this.props.conditions.filter(
                (condition) =>
                    condition.variable != null &&
                    condition.operation != null &&
                    condition.value != null
            );
        }
        return axios
            .post<{
                success: boolean;
                error_msg: string;
                items?: SearchComponentValue[];
            }>("/api/e/autocomplete", {
                keep_column_order: this.props.keepColumnOrder,
                variable_index: this.props.searchIndex ?? variable?.index,
                starts_with: input,
                data_table_idx: this.props.dataScopeId,
                filter_missing: this.props.filterMissing,
                conditions:
                    filteredConditions != null
                        ? conditionsToJson(filteredConditions)
                        : undefined,
                module_id: this.props.currentModuleId ?? remoteModuleId,
            })
            .then((response) => {
                if (response.data.success && response.data.items != null) {
                    let manishOptions = response.data.items.filter(
                        (item) =>
                            item?.toString().toLocaleLowerCase() !== "on" &&
                            item?.toString().toLocaleLowerCase() !== "off"
                    );
                    if (manishOptions.length === 0) {
                        this.props.onOptionSelected({
                            label: "Off",
                            value: "Off",
                        });
                    }

                    let allOptions = this.mapValuesToOption(
                        response.data.items
                    );

                    this.setState({
                        allItems: response.data.items,
                        allOptions: allOptions,
                        options: allOptions.slice(0, 100),
                        switchOptions: manishOptions,
                    });
                    if (this.props.allValues) {
                        this.props.onOptionSelected(
                            allOptions.slice(0, maxAllValuesCount),
                            true
                        );
                    }
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private filterOptions(input: string): void {
        this.setState((state) => ({
            options: state.allOptions
                .filter((item: SearchComponentOption) =>
                    item.label.toLowerCase().startsWith(input.toLowerCase())
                )
                .slice(0, 100),
        }));
    }

    private inputChange(input: string): void {
        this.filterOptions(input);
    }

    private onChange(
        optionSelected:
            | SearchComponentOptionWithMeta
            | SearchComponentOptionWithMeta[]
            | null
    ): void {
        if (Array.isArray(optionSelected)) {
            if (optionSelected.find((option) => option.meta?.selectAll)) {
                this.props.onSelectAllOptions?.();
                return;
            }
            // if (
            //     optionSelected.find(
            //         (option) => option.meta?.selectAll === false
            //     )
            // ) {
            //     this.props.onOptionSelected(null);
            //     return;
            // }
        } else if (optionSelected?.meta?.selectAll) {
            this.props.onSelectAllOptions?.();
            return;
        }

        this.props.onOptionSelected(optionSelected);
    }

    componentDidMount(): void {
        this.loadOptions("");
        this.initializeReaction();
        SocketIOInstance?.on("dataset_changes", this.onDatasetChanges);
    }

    private onDatasetChanges(content: {
        data: {
            user_name?: string;
            data_table_idx: string | number;
            operation: string;
            update_id?: string;
        };
    }): void {
        if (content.data.data_table_idx === this.props.dataScopeId) {
            this.loadOptions("");
        }
    }

    initializeReaction() {
        if (this.dataVariablesReaction) this.dataVariablesReaction();
        this.dataVariablesReaction = reaction(
            () =>
                Variables(
                    this.props.dataScopeId,
                    this.props.currentModuleId ?? remoteModuleId
                ).dataVariables,
            () => {
                let allOptions = this.mapValuesToOption(this.state.allItems);
                this.setState({
                    allOptions: allOptions,
                    options: allOptions.slice(0, 100),
                });
                if (this.props.allValues) {
                    this.props.onOptionSelected(
                        allOptions.slice(0, maxAllValuesCount),
                        true
                    );
                }
            }
        );
    }

    componentDidUpdate(prevProps: Props): void {
        if (
            prevProps.dataScopeId !== this.props.dataScopeId ||
            prevProps.searchIndex !== this.props.searchIndex ||
            !_.isEqual(prevProps.conditions, this.props.conditions) ||
            prevProps.keepColumnOrder !== this.props.keepColumnOrder
        ) {
            this.loadOptions("");
            this.initializeReaction();
        }
        if (!prevProps.allValues && this.props.allValues) {
            this.props.onOptionSelected(
                this.state.allOptions.slice(0, maxAllValuesCount),
                true
            );
        }
    }

    componentWillUnmount() {
        if (this.dataVariablesReaction) this.dataVariablesReaction();
        SocketIOInstance?.off("dataset_changes", this.onDatasetChanges);
    }

    render() {
        let variablesInitialized = Variables(
            this.props.dataScopeId,
            this.props.currentModuleId ?? remoteModuleId
        ).initialized;
        let options = variablesInitialized
            ? this.props.allowSelectAllOption
                ? selectAllOptions.concat(this.state.options)
                : this.state.options
            : [];

        return (
            // <div style={{ width: "100%", height: '29px' }}>
            <div>
                {this.state.switchOptions.length === 0 && this.props.switch ? (
                    <Switch
                        onChange={(option: any) => {
                            let abc = option ? options[1] : options[0];
                            this.onChange(
                                abc as
                                    | SearchComponentOptionWithMeta
                                    | SearchComponentOptionWithMeta[]
                                    | null
                            );
                            this.setState({ switch: option });
                        }}
                        checked={this.state.switch}
                        width={70}
                        height={29}
                        handleDiameter={29}
                        borderRadius={0}
                        activeBoxShadow="none"
                        offColor="#CCC"
                        onColor="#CCC"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#70889E"
                        onHandleColor="#5BBD64"
                        uncheckedHandleIcon={
                            <div
                                className="manish"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 15,
                                    width: "35px",
                                    backgroundColor: "#70889E",
                                    color: "#fff",
                                }}
                            >
                                Off
                            </div>
                        }
                        checkedHandleIcon={
                            <div
                                className="manish"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    color: "#fff",
                                    fontSize: 15,
                                    width: "35px",
                                    backgroundColor: "#5BBD64",
                                }}
                            >
                                On
                            </div>
                        }
                    />
                ) : (
                    <CustomSelect
                        data-test-id={
                            this.props.rootDataTestId
                                ? `${this.props.rootDataTestId}-searchselector`
                                : undefined
                        }
                        ref={this.selectRef}
                        {...this.props.additionalOptions}
                        isCreatable={this.props.allowNewOption}
                        formatCreateLabel={this.props.formatCreateLabel}
                        createOptionPosition={this.props.createOptionPosition}
                        onCreateOption={(option: string) => {
                            let variable = Variables(
                                this.props.dataScopeId,
                                this.props.currentModuleId ?? remoteModuleId
                            ).getVariableByIndex(this.props.searchIndex);

                            if (
                                variable?.panel === "time" &&
                                variable?.derived_from === null
                            ) {
                                let time = strptime(variable.format!, option);
                                if (time != null) {
                                    let newOption = {
                                        label: option,
                                        value: time.getTime() / 1000,
                                    };
                                    this.onChange(newOption);
                                }
                            } else {
                                let newOption = {
                                    label: option,
                                    value: option,
                                };
                                this.onChange(newOption);
                            }
                        }}
                        isDisabled={this.props.isDisabled}
                        isMulti={this.props.isMulti && !this.props.allValues}
                        customIndicators={this.props.customIndicators}
                        isClearable={this.props.clearable}
                        backspaceRemovesValue={this.props.backspaceRemovesValue}
                        onFocus={() => {
                            if (this.props.onFocus != null)
                                this.props.onFocus();
                        }}
                        onBlur={() => {
                            if (this.props.onBlur != null) this.props.onBlur();
                        }}
                        onKeyDown={this.props.onKeyDown}
                        value={
                            this.props.allValues
                                ? [
                                      {
                                          label: "ALL",
                                      },
                                  ]
                                : this.props.initialValue
                        }
                        styles={{
                            ...(this.props.customStyles ||
                                searchComponentStyles),
                            ...this.props.additionalStyles,
                        }}
                        placeholder={`Select ${
                            Variables(
                                this.props.dataScopeId,
                                this.props.currentModuleId ?? remoteModuleId
                            ).getVariableByIndex(this.props.searchIndex)?.name
                        }`}
                        options={options}
                        onInputChange={(input: string) =>
                            this.inputChange(input)
                        }
                        onChange={(option: any) =>
                            this.onChange(
                                option as
                                    | SearchComponentOptionWithMeta
                                    | SearchComponentOptionWithMeta[]
                                    | null
                            )
                        }
                        menuPosition="absolute"
                        customTheme={this.props.theme}
                        theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                        menuPortalTarget={this.props.menuPortalTarget}
                        menuPlacement="auto"
                        isSearchable={mobileBreakpoint() ? false : true}
                    />
                )}
            </div>
        );
    }
}
