import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import Switch from "react-switch";
import CreatableSelect from "react-select/creatable";
import { observer } from "mobx-react";
import customSelectStyles, { leftAlignAndScrollBarCSS } from "common/SelectStyles";
import Variables from "common/Variables";
import StringOption from "common/StringOption";
import {
    TypeOption,
    Type,
    Panel,
    typeOptions,
    geographyLevelsOptions,
    geographyLevelsSelectOptions,
    timeLevelsOptions,
    numberLevelsOptions,
    numberLevelsSelectOptions,
} from "common/InputData";
import DateTimeFormatSelect, {
    OptionType as DateTimeOptionType,
} from "common/DateTimeFormatSelect";
import SelectedOption from "modules/data_hub_page/SelectedOption";

export interface NewVariable {
    name?: string | null;
    unit: string | null;
    type: Type;
    level: string | null;
    format: string | null;
    panel: Panel;
    decimal?: number | null
}

export interface EditVariable extends Partial<NewVariable> {
    index: number;
    new_name?: string;
}

interface Props {
    value: NewVariable[];
    dataScopeId: string | number;
    enabled?: boolean[];
    onEnabled?: (enabled: boolean[]) => void | undefined;
    onChange: (newValue: NewVariable[]) => void;
    tab?: string | null;
}
interface State {
    formatOptions: DateTimeOptionType[];
    decimal: number | null
}

const VariableCreator = observer(
    class VariableCreator extends Component<Props, State> {
        /*
         * Requires the following props:
         * onChange: function of 1 argument that gets called on every change
         * value: value of this component
         */
        constructor(props: Props) {
            super(props);
            this.state = {
                formatOptions: DateTimeFormatSelect.defaultOptions(),
                decimal: null
            };
        }

        render() {
            let currentVariables = this.props.value;

            return (
                <div style={{ backgroundColor: '#fff', borderRadius: 5, paddingBlock:8 }}>
                    {
                        currentVariables.map((variable, index) => {
                            const levelOptions = variable.panel === Panel.Geography ? geographyLevelsSelectOptions : numberLevelsSelectOptions;

                            return (
                                <div className="my-row mainVariableCreator" key={index}
                                    style={{ alignItems: 'center' }}>
                                    <div style={{ width: 170, marginLeft: 5, }}>
                                        <input
                                            className="like-select newVariable inputOutline"
                                            placeholder="new variable name"
                                            onChange={(e) => {
                                                let newVariables = Array.from(currentVariables);
                                                newVariables[index] = {
                                                    ...newVariables[index],
                                                    name: e.target.value,
                                                };
                                                this.props.onChange(newVariables);
                                                SelectedOption.UpdateaskSwitchTab(false);
                                                SelectedOption.UpdateSwitchTab(false);
                                            }}
                                            value={variable.name || ""}
                                        />
                                    </div>

                                    <Select
                                        filterOption={createFilter({ ignoreAccents: false, })}
                                        placeholder={"Data type"}
                                        styles={{
                                            ...customSelectStyles,
                                            ...leftAlignAndScrollBarCSS,
                                            container: (base) => ({
                                                ...base,
                                                marginLeft: "10px",
                                                width: "10em",
                                                height: "38px",
                                            }),
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 1000,
                                            }),
                                            control: (base => ({
                                                ...base,
                                                border: '1px solid #ccc',
                                                borderRadius: 3
                                            })),
                                            input:(base=>({
                                                ...base,
                                                paddingLeft:5
                                            }))
                                        }}
                                        options={typeOptions}
                                        onChange={(newValue) => {
                                            let typeOption = newValue as TypeOption;
                                            let type = typeOption.type;
                                            let panel = typeOption.panel;
                                            let level =
                                                panel === Panel.Time
                                                    ? timeLevelsOptions[0]
                                                    : panel === Panel.Geography
                                                        ? geographyLevelsOptions[0]
                                                        : null;
                                            let unit = typeOption.fixedUnit
                                                ? null
                                                : variable.unit ?? null;
                                            let newVariable = {
                                                ...variable!,
                                                type: type,
                                                panel: panel,
                                                level: level,
                                                format: null,
                                                unit: unit,
                                            };
                                            let newVariables = Array.from(currentVariables);
                                            newVariables[index] = newVariable;
                                            this.props.onChange(newVariables);
                                        }}
                                        value={typeOptions.find(
                                            (option) =>
                                                option.type === variable.type &&
                                                option.panel === variable.panel
                                        )}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25: "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                    {
                                        !(this.props.tab === "createVariable") &&
                                        <CreatableSelect
                                            isDisabled={variable.panel !== Panel.Regular}
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            placeholder={"unit"}
                                            styles={{
                                                ...customSelectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    width: "100px",
                                                    marginLeft: "5px",
                                                    height: "38px",
                                                }),
                                                dropdownIndicator: (provided) => {
                                                    if (variable.panel !== Panel.Regular)
                                                        return {
                                                            ...provided,
                                                            display: "none",
                                                        };
                                                    else return provided;
                                                },
                                            }}
                                            options={Variables(
                                                this.props.dataScopeId
                                            ).columnUnitOptions.map((option) => ({
                                                label: option.length === 0 ? "None" : option,
                                                value: option,
                                            }))}
                                            onChange={(newValue) => {
                                                let newVariables = Array.from(currentVariables);
                                                newVariables[
                                                    index
                                                ].unit = (newValue as StringOption).value;
                                                this.props.onChange(newVariables);
                                            }}
                                            value={
                                                variable.unit
                                                    ? {
                                                        label: variable.unit,
                                                        value: variable.unit,
                                                    }
                                                    : null
                                            }
                                            onCreateOption={(option) => {
                                                Variables(
                                                    this.props.dataScopeId
                                                ).setColumnUnitOptions([
                                                    ...Variables(this.props.dataScopeId)
                                                        .columnUnitOptions,
                                                    option,
                                                ]);
                                                let newVariables = Array.from(currentVariables);
                                                newVariables[index] = {
                                                    ...newVariables[index],
                                                    unit: option,
                                                };
                                                this.props.onChange(newVariables);
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25: "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    }
                                    {
                                        ((this.props.value[index].type === "int" || this.props.value[index].type === "float") ||
                                            (this.props.value[index].type === "str" && this.props.value[index].panel === "geography")) &&
                                        <>
                                            <Select
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={"Special level"}
                                                styles={{
                                                    ...customSelectStyles,
                                                    ...leftAlignAndScrollBarCSS,
                                                    container: (base) => ({
                                                        ...base,
                                                        marginLeft: "5px",
                                                        width: "11em",
                                                        height: "38px",
                                                    }),
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 1000,
                                                    }),
                                                    dropdownIndicator: (provided) => {
                                                        if (variable.panel === Panel.Regular)
                                                            return {
                                                                ...provided,
                                                                display: "none",
                                                            };
                                                        else return provided;
                                                    },
                                                    input:(base=>({
                                                        ...base,
                                                        paddingLeft:5
                                                    }))
                                                }}
                                                options={levelOptions}
                                                onChange={(newValue) => {
                                                    let level = (newValue as StringOption).value;
                                                    let newVariables = Array.from(currentVariables);
                                                    newVariables[index].level = level;
                                                    this.props.onChange(newVariables);

                                                    // if (level == "Percent" || level == "Currency") {
                                                    //     newVariables[index] = {
                                                    //         ...newVariables[index],
                                                    //         type: Type.Str,
                                                    //         level: level
                                                    //     };
                                                    //     this.props.onChange(newVariables)
                                                    // }
                                                    // else {
                                                    //     newVariables[index].level = level
                                                    //     this.props.onChange(newVariables)
                                                    // }
                                                }}
                                                value={
                                                    variable.level
                                                        ? {
                                                            label: variable.level,
                                                            value: variable.level,
                                                        }
                                                        : variable.type === "int"
                                                            ? {
                                                                label: numberLevelsOptions[0],
                                                                value: numberLevelsOptions[0],
                                                            }
                                                            : null
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25: "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                            {
                                                (this.props.value[index].type === "int" || this.props.value[index].type === "float") &&
                                                <div className="hideIcon" >
                                                    <input
                                                        className="like-select inputOutline"
                                                        style={{
                                                            width: 100,
                                                            paddingTop: "0px",
                                                            paddingBottom: "0px",
                                                            height: "25px",
                                                            fontFamily: "monospace",
                                                            textAlign: "left",
                                                            marginLeft: "4px",
                                                            marginRight: "4px",
                                                            flex: 1,
                                                            border: '1px solid #ccc',
                                                            color: "#333",
                                                        }}
                                                        type="number"
                                                        placeholder="decimals"
                                                        onChange={(e) => {
                                                            let value = Number(e.target.value)
                                                            let newVariables = Array.from(currentVariables);

                                                            if (value > 6 || value < 0) {
                                                                newVariables[index] = {
                                                                    ...newVariables[index],
                                                                    decimal: 2,
                                                                };
                                                                this.setState({ decimal: 2 })
                                                                this.props.onChange(newVariables)
                                                            }
                                                            else {
                                                                newVariables[index] = {
                                                                    ...newVariables[index],
                                                                    decimal: value,
                                                                    type: value !== 0 ? Type.Float : Type.Int
                                                                };
                                                                this.setState({ decimal: value })
                                                                this.props.onChange(newVariables)
                                                            }
                                                        }}
                                                        value={variable.decimal ?? ""}
                                                    />
                                                </div>
                                            }
                                        </>
                                    }
                                    {
                                        this.props.value[index].type === "datetime" &&
                                        <DateTimeFormatSelect
                                            isDisabled={variable.panel !== Panel.Time}
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            onCreateOption={(option) => {
                                                let newVariables = Array.from(currentVariables);
                                                newVariables[
                                                    index
                                                ].format = (option as DateTimeOptionType).value;
                                                this.props.onChange(newVariables);
                                                this.setState((state) => ({
                                                    formatOptions: [option, ...state.formatOptions],
                                                }));
                                            }}
                                            placeholder={"DD/MM/YYYY"}
                                            styles={{
                                                ...customSelectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    marginLeft: "5px",
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                    width: "22em",
                                                }),
                                                dropdownIndicator: (provided) => {
                                                    if (variable.panel !== Panel.Time)
                                                        return {
                                                            ...provided,
                                                            display: "none",
                                                        };
                                                    else return provided;
                                                },
                                                placeholder:(base=>({
                                                    ...base,
                                                    paddingLeft:5
                                                })),
                                                input:(base=>({
                                                    ...base,
                                                    paddingLeft:5
                                                }))
                                            }}
                                            options={this.state.formatOptions}
                                            onChange={(newValue) => {
                                                let newVariables = Array.from(currentVariables);
                                                newVariables[
                                                    index
                                                ].format = (newValue as DateTimeOptionType).value;
                                                this.props.onChange(newVariables);
                                            }}
                                            value={variable.format ?? null}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25: "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    }
                                    <div
                                        className="flex-simple-column"
                                        style={{ marginLeft: 5 }}
                                    >
                                        {this.props.enabled == null && (
                                            <>
                                                <Button
                                                    className="btn-small-like-select"
                                                    style={{
                                                        width: "19px",
                                                        height: "19px",
                                                        fontWeight: "bold",
                                                        color: '#3B82C9'
                                                    }}
                                                    onClick={() => {
                                                        let newVariables = Array.from(currentVariables);
                                                        newVariables.push({
                                                            name: null,
                                                            unit: null,
                                                            type: Type.Str,
                                                            level: null,
                                                            panel: Panel.Regular,
                                                            format: null,
                                                        });
                                                        this.props.onChange(newVariables);
                                                    }}
                                                >
                                                    {"\uFF0B" /* plus */}
                                                </Button>
                                                <Button
                                                    className="btn-small-like-select"
                                                    style={{
                                                        width: "19px",
                                                        height: "19px",
                                                        fontWeight: "bold",
                                                        color: '#3B82C9'
                                                    }}
                                                    onClick={() => {
                                                        let newVariables = Array.from(
                                                            currentVariables
                                                        );
                                                        newVariables.splice(index, 1);
                                                        if (newVariables.length === 0)
                                                            newVariables.push({
                                                                name: null,
                                                                unit: null,
                                                                type: Type.Str,
                                                                level: null,
                                                                panel: Panel.Regular,
                                                                format: null,
                                                            });
                                                        this.props.onChange(newVariables);
                                                    }}
                                                >
                                                    {"\uFF0D" /* minus */}
                                                </Button>
                                            </>
                                        )}
                                        {this.props.enabled != null && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "100%",
                                                }}
                                            >
                                                <Switch
                                                    onChange={(checked) => {
                                                        let enabled = Array.from(
                                                            this.props.enabled!
                                                        );
                                                        enabled[index] = checked;
                                                        if (this.props.onEnabled != null)
                                                            this.props.onEnabled(enabled);
                                                    }}
                                                    checked={this.props.enabled[index]}
                                                    width={26}
                                                    height={13}
                                                    offColor="#20293C"
                                                    onColor="#20293C"
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    offHandleColor="#70889E"
                                                    onHandleColor="#1F8EFA"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )


        }
    }
);

export default VariableCreator;
