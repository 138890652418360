import React from "react";
import OutsideAlerter from "common/OutsideAlerter";
import UserInfo from "common/UserInfo";
import { UserIcon } from "common/UserIcon";
import { mainStyle } from "common/MainStyle";

interface Props {
	left: number;
	top: number;
	userInfo: UserInfo;
	onReject: () => void;
}

function buildUserInfoRow(name: string, value: string) {
	return (
		<div className="my-row" style={{ marginTop: "5px" }}>
			<span
				style={{
					width: 150,
					textAlign: "left",
					color: mainStyle.getPropertyValue(
						"--popup-tertiary-text-color"
					),
					fontFamily: "Roboto",
					fontSize: 20,
				}}
			>
				{name}
			</span>
			<span
				style={{
					marginLeft: "10px",
					textAlign: "center",
					color: mainStyle.getPropertyValue(
						"--popup-primary-text-color"
					),
					fontFamily: "Roboto",
					fontSize: 20,
				}}
			>
				{value}
			</span>
		</div>
	);
}

export default function ContactUserPopup(props: Props) {
	return (
		<OutsideAlerter
			onReject={() => {
				props.onReject();
			}}
		>
			<div
				style={{
					position: "absolute",
					top: props.top,
					left: props.left,
					zIndex: 998,
				}}
			>
				<div
					className="flex-simple-column dashboard-rect"
					style={{ padding: "20px" }}
				>
					<div className="my-row">
						<UserIcon
							width={90}
							height={90}
							fontSize={20}
							iconUrl={props.userInfo.icon_url}
							user={props.userInfo}
						/>
						<span
							style={{
								alignSelf: "center",
								marginLeft: "10px",
								textAlign: "center",
								color: mainStyle.getPropertyValue(
									"--popup-primary-text-color"
								),
								fontFamily: "Roboto",
								fontSize: 20,
							}}
						>
							{`${props.userInfo.first_name} ${props.userInfo.last_name}`}
						</span>
					</div>
					<div style={{ height: 20 }} />
					{buildUserInfoRow(
						"Position",
						props.userInfo.position ?? ""
					)}
					<div className="my-row">
						{buildUserInfoRow("Email", props.userInfo.email ?? "")}
						<i
							title={
								props.userInfo.verified
									? "Verified"
									: "Not Verified"
							}
							style={{
								marginLeft: "5px",
								alignSelf: "center",
								color: props.userInfo.verified
									? "green"
									: "black",
							}}
							className="fa fa-check-circle"
						></i>
					</div>
					{buildUserInfoRow(
						"Phone Number",
						props.userInfo.phone_number ?? ""
					)}
				</div>
			</div>
		</OutsideAlerter>
	);
}
