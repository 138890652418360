import React from "react";
import cx from "classnames";

import styles from "./Button.module.css";

interface Props {
	variant?: "contained" | "text";
	onClick: React.MouseEventHandler;
	children: React.ReactNode;
	className?: string;
}

function Button({ variant = "contained", onClick, className, children }: Props) {
	return (
		<button
			type="button"
			onClick={onClick}
			className={cx(styles.root, styles[variant], className)}
		>
			{ children }
		</button>
	);
}

export default Button;
