import { strptime } from "common/utilities/TimeFormatUtils";

function castToType(
    value?: string,
    type?: string,
    format?: string
): string | number | boolean | Date | null | undefined {
    if (value == null) return null;
    if (type == null || type === "str") {
        return value.toString();
    } else if (type === "int") {
        if (value.match(/[0-9]*/)) {
            return Number(value);
        } else {
            return undefined;
        }
    } else if (type === "float") {
        let number = Number(value);
        if (isNaN(number)) {
            return undefined;
        } else {
            return number;
        }
    } else if (type === "bool") {
        value = value.toLowerCase();
        if (value === "true" || value === "1") {
            return true;
        } else if (value === "false" || value === "0") {
            return false;
        } else {
            return Boolean(value);
        }
    } else if (type === "datetime" && format != null) {
        if (strptime(format, value, true) != null) {
            return value; // insert_rows accepts strings as dates
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}

export default castToType;
