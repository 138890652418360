export const gridSpreadSheetDefaultWidth = 80;
export const gridSpreadSheetDefaultHeight = 30;

export const verticalSectionBarItemSizePadding = 20;
export const verticalSectionBarItemSize = 50;
export const verticalSectionBarFontSize = 18;

export const fullGridSpreadSheetDefaultWidth = 80;
export const fullGridSpreadSheetDefaultHeight = 20;
export const defaultNumberLetterTitleSize = 20;

export const dropdownSelectorDefaultWidth = 180;
export const dropdownSelectorDefaultLiveHeight = 60;

export const filterDefaultWidth = 375;
export const filterDefaultLiveHeight = 75;

export const toggleDefaultWidth = 55;

export const tagDefaultWidth = 200;
export const tagDefaultHeight = 30;
export const shapeDefaultWidth = 100;
export const shapeDefaultHeight = 100;
export const defaultLabelSize = 8;
export const defaultFontSize = 10;
export const defaultTagSize = 10;
export const identifierFontSize = 7;

export const barcodeReaderDefaultWidth = 201;
export const barcodeReaderDefaultHeight = 200;

export const radioButtonsGroupDefaultWidth = 220;
export const radioButtonsGroupDefaultHeight = 120;

export const progressElementDefaultWidth = 190;
export const progressElementDefaultHeight = 75;

export const surveyElementDefaultWidth = 390;
export const surveyElementDefaultHeight = 390;

export const defaultHeaderHeight = 30;
// let defaultHeaderColor = "#000000";
export const defaultHeaderSize = defaultLabelSize;

// let defaultHeaderFillColor = "#CCCCCC";
export const sheetRibbonWidth = 190;

export const mapAdvancedEditMenuWidth = 550;
export const minLimitEditMenuWidth = 350;

export const defaultSlideHeight = 542;
export const defaultMobileSlideHeight = 780;
export const defaultSlideWidth = 962;
export const defaultMobileSlideWidth = 462;
export const canvasEditModeMargin = 20;
export const headerBarHeight = 60;
export const desktopMobileButtonHeight = 22;

export const defaultMapColorOptions = {
    fontColor: "black",
    fontSize: 10,
};

export const fontFamilies = [
    "Arial",
    "Avenir",
    "Bebas Neue",
    "Century Gothic",
    "Code Pro",
    "Eau",
    "Georgia",
    "Garamound",
    "Impact",
    "Intro Rust",
    "Jost",
    "Manrope",
    "Montserrat",
    "Mullish",
    "Nunito",
    "Roboto",
    "Serif",
    "Tahoma",
    "Times New Roman",
    "Verdana",
];

export const defaultFontFamily = {
    label: "Roboto",
    value: "Roboto",
}

export const mapViewScale = 1.5;

export const defaultInputFieldPlaceholder = "Type here...";

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
