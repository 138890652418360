import React, { Component } from "react";
import { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import Popup from "reactjs-popup";
import { PropsValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { reaction } from "mobx";
import { observer } from "mobx-react";
import Cookies from "universal-cookie";

import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import Canvases from "common/Canvases";
import Alert from "common/Alert";
import CanvasTreeStore from "./CanvasTreeStore";
import UserGroupsSelector from "common/UserGroupsSelector";
import { getCanvasUserGroups } from "common/UserGroupsApi";
import {
    addCanvasApi,
    editCanvasApi,
    EditCanvasRequest,
} from "common/CanvasUserApi";
import customSelectStyles from "common/SelectStyles";
import { mainStyle } from "common/MainStyle";
import {
    renderHtmlRef,
    renderEmptyCanvasThumbnail,
} from "common/utilities/renderIcon";
import { Canvas, CanvasBackground } from "common/Canvas";

const cookies = new Cookies();

interface CanvasIdOption {
    label: string;
    value: number;
}

// Creatable select interacts with reactjs-popup in a
// weird way. Under unknown conditions onChange might
// be called instead of onCreateOption. We detect such
// cases by testing if newValue has __isNew__ property.
// value will be a string in this case.
interface CanvasIdCreateOption {
    label: string;
    value: string;
    __isNew__: boolean;
}
export enum SaveType {
    New = 1,
    Import = 2,
    Save = 3,
}

interface Props {
    canvasTreeStore: CanvasTreeStore;
    saveOptions: {
        type: SaveType;
        pageId: number;
        importingCanvas?: {
            foreground: {
                canvas: Canvas["canvas"],
                backgrounds: CanvasBackground[]
            },
            background?: {
                canvas: Canvas["canvas"],
                backgrounds: CanvasBackground[]
            } | null,
        };
    };
    onClearEditing: () => void;
    scale: number;
    canWrite: boolean;
    onSave: () => void;
    onClose: () => void;                 
    loadCanvas: (canvasId: any) => void;
    rootRef: React.Ref<HTMLElement>;
}

enum LoadStatus {
    NotUploaded = 1,
    Uploading = 2,
    Success = 3,
    Error = 4,
}

interface State {
    canvasesOptions: CanvasIdOption[];
    selectedCanvas: CanvasIdOption | undefined;
    selectedUserGroups: string[];
    errorMessage: string | undefined;
    status: LoadStatus;
}

@observer
class SavePopup extends Component<Props, State> {
    private initializeCanvasesReaction: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            canvasesOptions: [],
            selectedCanvas: undefined,
            selectedUserGroups: [],
            errorMessage: undefined,
            status: LoadStatus.NotUploaded,
        };
        this.updateCanvasGroups = this.updateCanvasGroups.bind(this);
    }

    private updateCanvasGroups(canvasId: number): void {
        if (!this.props.canWrite) return;
        if (canvasId < 0) {
            this.setState({ selectedUserGroups: [] });
            return;
        }
        getCanvasUserGroups(canvasId)
            .then((groups) => {
                this.setState({
                    selectedUserGroups: groups,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    componentDidMount() {
        this.initializeCanvases();
        this.initializeCanvasesReaction = reaction(
            () => Canvases(this.props.saveOptions.pageId).canvases,
            () => {
                this.initializeCanvases();
            }
        );
    }
    componentWillUnmount() {
        this.initializeCanvasesReaction();
    }

    initializeCanvases() {
        this.setState(
            {
                canvasesOptions: Canvases(
                    this.props.saveOptions.pageId
                ).canvases.map((item) => ({
                    label: item.title,
                    value: item.id,
                    isDisabled: this.props.saveOptions.type < SaveType.Save,
                })),
            },
            () => {
                if (
                    this.props.canvasTreeStore.canvasId &&
                    this.props.saveOptions.type === SaveType.Save
                ) {
                    this.setState(
                        (state) => {
                            let canvasOption = state.canvasesOptions.filter(
                                (item) =>
                                    item.value ===
                                    this.props.canvasTreeStore.canvasId
                            );
                            if (canvasOption.length > 0) {
                                return {
                                    selectedCanvas: canvasOption[0],
                                };
                            } else {
                                return {
                                    selectedCanvas: state.selectedCanvas,
                                };
                            }
                        },
                        () => {
                            if (this.state.selectedCanvas)
                                this.updateCanvasGroups(
                                    this.state.selectedCanvas.value
                                );
                        }
                    );
                }
            }
        );
    }

    private onCreateOption(option: string): void {
        this.setState(
            (state) => {
                let canvasesOptions = Array.from(state.canvasesOptions);
                let selectedOption = {
                    label: option,
                    value: -state.canvasesOptions.length - 1,
                    isDisabled: false,
                };
                canvasesOptions.push(selectedOption);
                return {
                    canvasesOptions: canvasesOptions,
                    selectedCanvas: selectedOption,
                };
            },
            () => {
                if (this.state.selectedCanvas != null)
                    this.updateCanvasGroups(this.state.selectedCanvas.value);
            }
        );
    }

    private addCanvas(
        thumbnail: any,
        currentCanvasId: number | undefined,
        currentPageId: string | number | undefined,
        importingCanvas: {
            canvas: Canvas["canvas"],
            backgrounds: CanvasBackground[]
        } | null | undefined,
    ) {
        if (!importingCanvas) return;
        addCanvasApi(
            this.props.saveOptions.pageId,
            this.state.selectedCanvas!.label,
            importingCanvas != null
                ? importingCanvas.canvas
                : this.props.canvasTreeStore.serialize(),
            importingCanvas != null
                ? importingCanvas.backgrounds
                : this.props.canvasTreeStore.backgroundsState.toJSON(),
            thumbnail,
            this.state.selectedUserGroups,
            currentCanvasId,
            cookies.get(
                "instrumentation_session_id"
            )
        )
            .then(async (_id) => {
                Canvases(
                    this.props.saveOptions.pageId
                ).update();
                this.props.canvasTreeStore.joinPageRoom(
                    currentPageId,
                    this.props.saveOptions.pageId
                );
                this.props.canvasTreeStore.canvasPageId =
                    this.props.saveOptions.pageId;
                if (
                    importingCanvas
                ) {
                    this.props.canvasTreeStore.joinCanvasRoom(
                        _id,
                        currentCanvasId
                    );
                    this.props.onClearEditing();
                    this.props.canvasTreeStore.deserializeAsyncAction.bothParts(
                        importingCanvas.canvas,
                        {
                            canvasId: _id,
                            backgrounds:
                                importingCanvas
                                    .backgrounds,
                        }
                    );
                } else {
                    this.props.canvasTreeStore.joinCanvasRoom(
                        _id,
                        currentCanvasId
                    );
                    this.props.canvasTreeStore.canvasId =
                        _id;
                }
                this.props.onClose();
            })
            .catch((errorMessage) => {
                this.setState({
                    status: LoadStatus.Error,
                    errorMessage: errorMessage,
                });
            });
    }

    private buildInnerView(): JSX.Element {
        let createNew = this.props.saveOptions.type < SaveType.Save;

        return (
            <div
                className="flex-simple-column"
                style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    overflow: "visible",
                }}
            >
                {!createNew && (
                    <>
                        <span
                            style={{
                                marginRight: "1em",
                                marginTop: "20px",
                                marginBottom: "5px",
                                textAlign: "left",
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontFamily: "Roboto",
                                fontSize: 14,
                            }}
                        >
                            Save to slide name:
                        </span>
                    </>
                )}
                <CreatableSelect
                    isDisabled={!this.props.canWrite}
                    onCreateOption={(option) => {
                        this.onCreateOption(option);
                    }}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={
                        createNew ? "Enter name" : "Enter or select name"
                    }
                    styles={{
                        ...customSelectStyles,
                        container: (base) => ({
                            ...base,
                            marginTop: createNew ? "20px" : undefined,
                            height: "38px",
                        }),
                        option: (base, state) => {
                            if (state.isDisabled) {
                                return {
                                    ...base,
                                    color:
                                        mainStyle.getPropertyValue(
                                            "--selectors-text-color"
                                        ) + "88",
                                    cursor: "not-allowed",
                                };
                            } else if (customSelectStyles.option != null) {
                                return customSelectStyles.option(base, state);
                            } else {
                                return base;
                            }
                        },
                    }}
                    options={this.state.canvasesOptions}
                    value={this.state.selectedCanvas}
                    onChange={(
                        newValue: PropsValue<
                            CanvasIdOption | CanvasIdCreateOption
                        >
                    ) => {
                        // Creatable select interacts with reactjs-popup in a
                        // weird way. Under unknown conditions onChange might
                        // be called instead of onCreateOption. We detect such
                        // cases by testing if newValue has __isNew__ property.
                        if (newValue != null && "__isNew__" in newValue) {
                            this.onCreateOption(newValue.value);
                        } else {
                            this.setState(
                                {
                                    selectedCanvas: newValue as
                                        | CanvasIdOption
                                        | undefined,
                                },
                                () => {
                                    if (this.state.selectedCanvas != null)
                                        this.updateCanvasGroups(
                                            this.state.selectedCanvas.value
                                        );
                                }
                            );
                        }
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                {this.props.canWrite &&
                    PagesStore(PageType.Canvases).module(
                        this.props.saveOptions.pageId
                    ) == null && (
                        <UserGroupsSelector
                            selectedUserGroups={this.state.selectedUserGroups}
                            onChange={(selectedUserGroups) => {
                                this.setState({
                                    selectedUserGroups: selectedUserGroups,
                                });
                            }}
                        />
                    )}
                <div
                    className="my-row"
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        alignSelf: "flex-end",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Button
                        type="button"
                        disabled={
                            !this.state.selectedCanvas ||
                            this.state.status === LoadStatus.Uploading
                        }
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            width: "112px",
                        }}
                        onClick={async () => {
                            if (this.state.selectedCanvas != null) {
                                if (
                                    createNew &&
                                    this.state.selectedCanvas.value >= 0
                                ) {
                                    this.setState({
                                        status: LoadStatus.Error,
                                        errorMessage: "Slide already exists",
                                    });
                                    return;
                                }
                                this.props.onSave();
                                this.setState({
                                    status: LoadStatus.Uploading,
                                });
                                let cropRect = this.props.canvasTreeStore
                                    .layerRect(
                                        this.props.scale,
                                        PagesStore(PageType.Canvases).pages
                                    )
                                    .get();
                                cropRect.x = cropRect.x * this.props.scale;
                                cropRect.y = cropRect.y * this.props.scale;
                                cropRect.width =
                                    cropRect.width * this.props.scale;
                                cropRect.height =
                                    cropRect.height * this.props.scale;
                                let thumbnail = null;
                                if (createNew) {
                                    try {
                                        thumbnail =
                                            renderEmptyCanvasThumbnail();
                                    } catch (error) {
                                        console.log(String(error));
                                    }
                                } else {
                                    try {
                                        thumbnail = await renderHtmlRef(
                                            this.props.rootRef,
                                            cropRect.x,
                                            cropRect.y,
                                            cropRect.width,
                                            cropRect.height,
                                            true
                                        );
                                    } catch (error) {
                                        console.log(String(error));
                                    }
                                }
                                let currentCanvasId =
                                    this.props.canvasTreeStore.canvasId;
                                let currentPageId =
                                    this.props.canvasTreeStore.canvasPageId;

                                if (this.state.selectedCanvas.value < 0) {
                                    if (
                                        createNew &&
                                        this.props.saveOptions
                                            .importingCanvas == null
                                    ) {
                                        this.props.onClearEditing();
                                        this.props.canvasTreeStore.clearAllFieldsAction();
                                    }
                                    this.addCanvas(
                                        thumbnail,
                                        currentCanvasId,
                                        currentPageId,
                                        this.props.saveOptions.importingCanvas?.foreground,
                                    );
                                } else {
                                    let editCanvasRequest: EditCanvasRequest = {
                                        mergeTemplates: false,
                                        sheets: [
                                            {
                                                pageId: this.props.saveOptions
                                                    .pageId,
                                                canvasId:
                                                    this.state.selectedCanvas
                                                        .value,
                                                canvas: this.props.canvasTreeStore.serialize(),
                                                backgrounds:
                                                    this.props.canvasTreeStore.backgroundsState.toJSON(),
                                                thumbnail: thumbnail,
                                                userGroups: this.props.canWrite
                                                    ? this.state
                                                          .selectedUserGroups
                                                    : undefined,
                                            },
                                        ],
                                    };
                                    editCanvasApi(editCanvasRequest)
                                        .then((_ids) => {
                                            this.props.canvasTreeStore.joinPageRoom(
                                                currentPageId,
                                                this.props.saveOptions.pageId
                                            );
                                            this.props.canvasTreeStore.canvasPageId =
                                                this.props.saveOptions.pageId;
                                            this.props.canvasTreeStore.joinCanvasRoom(
                                                _ids[0],
                                                currentCanvasId
                                            );
                                            this.props.canvasTreeStore.canvasId =
                                                _ids[0];
                                            Canvases(
                                                this.props.saveOptions.pageId
                                            ).update();
                                            this.props.onClose();
                                        })
                                        .catch((errorMessage) => {
                                            this.setState({
                                                status: LoadStatus.Error,
                                                errorMessage: errorMessage,
                                            });
                                        });
                                }
                            }
                        }}
                    >
                        {createNew ? "CREATE" : "SAVE"}
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={() => {
                            this.props.onClose();
                        }}
                    >
                        CLOSE
                    </Button>
                </div>

                {!createNew && (
                    <>
                        <div
                            style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <span
                                style={{
                                    textAlign: "left",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontFamily: "Roboto",
                                    fontSize: 14,
                                }}
                            >
                                Export into JSON file
                            </span>
                            <div style={{ flex: 1 }} />
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: 10,
                                    width: "112px",
                                }}
                                onClick={() => {
                                    this.props.onSave();
                                    let contents: string = JSON.stringify({
                                        canvas: this.props.canvasTreeStore.serialize(),
                                        backgrounds:
                                            this.props.canvasTreeStore.backgroundsState.toJSON(),
                                    });
                                    const element = document.createElement("a");
                                    const file = new Blob([contents], {
                                        type: "text/plain",
                                    });
                                    element.href = URL.createObjectURL(file);
                                    element.download = "canvas.json";
                                    document.body.appendChild(element);
                                    element.click();
                                    this.props.onClose();
                                }}
                            >
                                EXPORT
                            </Button>
                        </div>
                    </>
                )}

                {this.state.status === LoadStatus.Error && (
                    <Alert
                        text={"Error: ".concat(this.state.errorMessage ?? "")}
                        className="alert alert-danger alert-dismissible"
                        onClosed={() =>
                            this.setState({
                                status: LoadStatus.NotUploaded,
                                errorMessage: undefined,
                            })
                        }
                    />
                )}
                {this.state.status === LoadStatus.Uploading && (
                    <Alert
                        text={"Saving"}
                        className="alert alert-warning"
                        onClosed={() => {}}
                    />
                )}
            </div>
        );
    }

    render() {
        let height = 150;
        let width = 350;
        return (
            <Popup
                arrow={true}
                contentStyle={{
                    width: width,
                    minHeight: height,
                    border: "none",
                    backgroundColor: "transparent",
                }}
                open={true}
                onClose={() => {
                    this.props.onClose();
                }}
                closeOnDocumentClick
            >
                <div
                    className="dashboard-rect"
                    style={{
                        overflow: "visible",
                        boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                        borderRadius: 0,
                        alignItems: "center",
                        cursor: "pointer",
                        minHeight: height,
                        width: width,
                    }}
                >
                    {this.buildInnerView()}
                </div>
            </Popup>
        );
    }
}

export default SavePopup;
