import React, { useEffect, useState, useMemo } from "react";

import { colorList } from "../../LineColors";
//import db from "./db";
//import InsightsType from "./InsightsType";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import { PieChartFinding, getValue } from "common/Finding";
// import { useDrop } from "react-dnd";
import OutsideAlerter from "common/OutsideAlerter";
import Portal from "common/Portal";
import ColorPicker from "../ChartColorPicker";
import { previewColors } from "../previewColors";
import { TooltipStyles } from "../../TooltipStyles";
import { LegendEditElement } from "../../LegendEditElement";
import { valueToTitle } from "common/AggregationFunctionsv2";
import EditableAxisItem from "../EditableAxisItem";
import * as d3 from "d3";

// interface DropProps {
//     preview?: boolean;
//     editable?: boolean;
//     dataScopeConnected: boolean;
//     nameColorMapping?: { [key: string]: string };
//     onDrop: (
//         index: number,
//         variableName: string,
//         variableIndex: number
//     ) => void;
//     onClick?: (name: string, index: number, x: number, y: number) => void;
// }
//
// interface CustomizedLabelProps {
//     percent: number;
//     cx: number;
//     cy: number;
//     midAngle: number;
//     outerRadius: number;
//     value: number;
//     name: string;
// }

const getVaryBySum = (
    datasWithIndexAndColor: {
        name: string;
        value: number | number[];
        index: number;
        fill: string;
        variableIndex?: number;
    }[][],
    operationVariable: string
) => {
    let sum = datasWithIndexAndColor
        .map((dataItem) => {
            let sum = dataItem.reduce((accumulator, item) => {
                return accumulator + getValue(item, 0);
            }, 0);
            return sum;
        })
        .reduce((accumulator, item) => {
            return accumulator + item;
        }, 0);
    let sumValue = formatValue(sum, false);
    let sumLabel = `Total ${operationVariable}:`;
    return [sumLabel, sumValue.join("")].join(" ");
};

interface InnerChartProps {
    config: PieChartFinding["config"];
    data: PieChartFinding["content"]["data"];
    groupNames: PieChartFinding["content"]["groupNames"];
    dataItem: {
        name: string;
        groupName?: string;
        value: number | number[];
        index: number;
        globalIndex: number;
        fill: string;
        variableIndex?: number;
    }[][];
    isVaryByData?: boolean;
    editable?: boolean;
    onShowBarColorMenu: React.Dispatch<
        React.SetStateAction<{
            name: string;
            index: number;
            x: number;
            y: number;
        } | null>
    >;
}

interface TooltipInfo {
    x: number;
    y: number;
    value: number | number[];
    name: string;
    fill: string;
    sum: number;
}

const cfg = {
    levels: 10, //How many levels or inner circles should there be drawn
    maxValue: 0, //What is the value that the biggest circle will represent
    labelFactor: 1.05, //How much farther than the radius of the outer circle should the labels be placed
    wrapWidth: 60, //The number of pixels after which a label needs to be given a new line
    opacityArea: 0.35, //The opacity of the area of the blob
    dotRadius: 4, //The size of the colored circles of each blog
    opacityCircles: 0.1, //The opacity of the circles of each blob
    strokeWidth: 2, //The width of the stroke around each blob
    roundStrokes: false, //If true the area and stroke will follow a round path (cardinal-closed)
};

const InnerChart = (props: InnerChartProps) => {
    let [showTooltipInfo, setShowTooltipInfo] = useState<TooltipInfo | null>(
        null
    );
    const tooltipFormat = (tooltipInfo: TooltipInfo): string => {
        let value = getValue(tooltipInfo, 0);
        let formattedValue = formatValue(value, false);
        let formattedValueSum = formatValue(
            (value * 100) / tooltipInfo.sum,
            false
        );

        let result = formattedValue[0].concat(formattedValue[1]);
        result = result
            .concat(", ")
            .concat(formattedValueSum[0])
            .concat(formattedValueSum[1])
            .concat("%");
        return result;
    };

    const mouseenter = (event: MouseEvent, d: any) => {
        setShowTooltipInfo({
            x: event.x,
            y: event.y,
            value: d.value,
            name: d.name,
            fill: d.fill,
            sum: d.sum,
        });
    };
    const mousemove = (event: MouseEvent) => {
        setShowTooltipInfo((showTooltipInfo) => {
            if (showTooltipInfo == null) {
                return null;
            } else {
                return {
                    ...showTooltipInfo,
                    x: event.x + 5,
                    y: event.y + 5,
                };
            }
        });
    };
    const mouseleave = () => {
        setShowTooltipInfo(null);
    };
    let parentSvgRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);
    let tooltipStyle = {
        ...TooltipStyles(
            props.config.tooltipColor,
            props.config.tooltipFontSize
        ),
    };

    const onShowBarColorMenu = props.onShowBarColorMenu;

    useEffect(() => {
        // const clickSector = (
        //     event: MouseEvent,
        //     d: d3.PieArcDatum<Datum> | d3.PieArcDatum<SunburstDatum>
        // ) => {
        //     if (props.editable) {
        //         onShowBarColorMenu({
        //             name: d.data.name,
        //             index: d.data.globalIndex,
        //             x: event.clientX,
        //             y: event.clientY,
        //         });
        //     }
        // };
        let containerHeight = parentSvgRef.current?.clientHeight ?? 0;
        let containerWidth = parentSvgRef.current?.clientWidth ?? 0;
        d3.select(svgRef.current!).selectAll("*").remove();

        let svg = d3
            .select(svgRef.current!)
            .attr("width", containerWidth)
            .attr("height", containerHeight)
            .attr(
                "viewBox",
                `${-containerWidth / 2} ${
                    -containerHeight / 2
                } ${containerWidth} ${containerHeight}`
            );
        let minSize = Math.min(containerHeight, containerWidth);
        let radius = minSize / 2;
        radius = radius * 0.8;
        //Filter for the outside glow
        let filter = svg.append("defs").append("filter").attr("id", "glow");
        filter
            .append("feGaussianBlur")
            .attr("stdDeviation", "2.5")
            .attr("result", "coloredBlur");
        let feMerge = filter.append("feMerge");
        feMerge
            .append("feMergeNode")
            .attr("in", "coloredBlur");
        feMerge
            .append("feMergeNode")
            .attr("in", "SourceGraphic");

        if (props.isVaryByData) {
            let allAxis = props.dataItem[0].map((subItem) => subItem.name);
            let total = allAxis.length;
            let angleSlice = (Math.PI * 2) / total;

            let dataByValuesLength = props.dataItem[0].length;
            let dataByVariablesLength = props.dataItem.length;
            let data: {
                name: string;
                value: number | number[];
                index: number;
                globalIndex: number;
                fill: string;
                variableIndex?: number;
                sum?: number;
            }[][] = [];

            let maxValue = 0;
            for (let j = 0; j < dataByVariablesLength; ++j) {
                if (data[j] == null) data[j] = [];
                let sum = 0;
                for (let i = 0; i < dataByValuesLength; ++i) {
                    data[j].push(props.dataItem[j][i]);
                    let value = getValue(props.dataItem[j][i], 0);
                    maxValue = Math.max(maxValue, value);
                    sum += value;
                }
                for (let i = 0; i < dataByValuesLength; ++i) {
                    data[j][i].sum = sum;
                }
            }
            let rScale = d3
                .scaleLinear()
                .range([0, radius])
                .domain([0, maxValue]);

            let axisGrid = svg.append("g").attr("class", "axisWrapper");

            axisGrid
                .selectAll(".levels")
                .data(d3.range(1, cfg.levels + 1).reverse())
                .enter()
                .append("circle")
                .attr("class", "gridCircle")
                .attr("r", function (d) {
                    return (radius / cfg.levels) * d;
                })
                .style("fill", "#CDCDCD")
                .style("stroke", "#CDCDCD")
                .style("fill-opacity", cfg.opacityCircles)
                .style("filter", "url(#glow)");
            //Create the straight lines radiating outward from the center

            let axis = axisGrid
                .selectAll(".axis")
                .data(allAxis)
                .enter()
                .append("g")
                .attr("class", "axis");
            //Append the lines
            axis.append("line")
                .attr("x1", 0)
                .attr("y1", 0)
                .attr("x2", function (_d, i) {
                    return (
                        rScale(maxValue * 1.1) *
                        Math.cos(angleSlice * i - Math.PI / 2)
                    );
                })
                .attr("y2", function (_d, i) {
                    return (
                        rScale(maxValue * 1.1) *
                        Math.sin(angleSlice * i - Math.PI / 2)
                    );
                })
                .attr("class", "line")
                .style("stroke", "white")
                .style("stroke-width", "2px");

            axis.append("text")
                .attr("class", "legend")
                .style("font-size", "11px")
                .attr("text-anchor", "middle")
                .attr(
                    "fill",
                    props.config.ticksColor ??
                        mainStyle.getPropertyValue("--graphs-axes-text-color")
                )
                .attr("dy", "0.35em")
                .attr("x", function (_d, i) {
                    return (
                        rScale(maxValue * cfg.labelFactor) *
                        Math.cos(angleSlice * i - Math.PI / 2)
                    );
                })
                .attr("y", function (_d, i) {
                    return (
                        rScale(maxValue * cfg.labelFactor) *
                        Math.sin(angleSlice * i - Math.PI / 2)
                    );
                })
                .text(function (d) {
                    return d;
                });

            let radarLine = d3
                .lineRadial()
                .angle(function (_d, i) {
                    return i * angleSlice;
                })
                .radius(function (d) {
                    return rScale(getValue(d as any, 0));
                })

                .curve(
                    !props.config.linear
                        ? d3.curveCardinalClosed
                        : d3.curveLinearClosed
                );

            //Create a wrapper for the blobs
            let blobWrapper = svg
                .selectAll(".radarWrapper")
                .data(data)
                .enter()
                .append("g")
                .attr("class", "radarWrapper");
            let circleWrapper = svg
                .selectAll(".circleWrapper")
                .data(data)
                .enter()
                .append("g")
                .attr("class", "circleWrapper");

            //Append the backgrounds
            blobWrapper
                .append("path")
                .attr("class", "radarArea")
                .attr("d", (d) => radarLine(d as any))
                .style("fill", function (_d, i) {
                    return props.dataItem[i]?.[0].fill;
                })
                .style("fill-opacity", cfg.opacityArea)
                .on("mouseover", function () {
                    //Dim all blobs
                    d3.selectAll(".radarArea")
                        .transition()
                        .duration(200)
                        .style("fill-opacity", 0.1);
                    //Bring back the hovered over blob
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .style("fill-opacity", 0.7);
                })
                .on("mouseout", function () {
                    //Bring back all blobs
                    d3.selectAll(".radarArea")
                        .transition()
                        .duration(200)
                        .style("fill-opacity", cfg.opacityArea);
                });

            //   Create the outlines
            blobWrapper
                .append("path")
                .attr("class", "radarStroke")
                .attr("d", (d) => radarLine(d as any))

                .style("stroke-width", cfg.strokeWidth + "px")
                .style("stroke", function (_d, i) {
                    return props.dataItem[i]?.[0].fill;
                })
                .style("fill", "none")
                .style("filter", "url(#glow)");
            circleWrapper
                .selectAll(".radarCircle")
                .data(function (d) {
                    return d;
                })
                .enter()
                .append("circle")
                .attr("class", "radarCircle")
                .attr("r", cfg.dotRadius)
                .attr("cx", function (d, i) {
                    return (
                        rScale(getValue(d as any, 0)) *
                        Math.cos(angleSlice * i - Math.PI / 2)
                    );
                })
                .attr("cy", function (d, i) {
                    return (
                        rScale(getValue(d as any, 0)) *
                        Math.sin(angleSlice * i - Math.PI / 2)
                    );
                })
                .style("fill", function (d) {
                    return d.fill;
                })
                .style("fill-opacity", 0.8)
                .on("mouseenter", mouseenter)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave);
        } else {
            for (let i = 0; i < props.dataItem.length; ++i) {
                let item = props.dataItem[i];
                let allAxis = item.map((subItem) => subItem.name);
                let total = allAxis.length;
                let angleSlice = (Math.PI * 2) / total;
                let maxValue = item.reduce(
                    (accumulator, subItem) =>
                        Math.max(accumulator, getValue(subItem, 0)),
                    0
                );
                let sum = item.reduce(
                    (accumulator, subItem) =>
                        accumulator + getValue(subItem, 0),
                    0
                );
                item = item.map((item) => ({
                    ...item,
                    sum: sum,
                }));
                //Scale for the radius
                let rScale = d3
                    .scaleLinear()
                    .range([0, radius])
                    .domain([0, maxValue]);

                let axisGrid = svg.append("g").attr("class", "axisWrapper");

                //Draw the background circles
                axisGrid
                    .selectAll(".levels")
                    .data(d3.range(1, cfg.levels + 1).reverse())
                    .enter()
                    .append("circle")
                    .attr("class", "gridCircle")
                    .attr("r", function (d) {
                        return (radius / cfg.levels) * d;
                    })
                    .style("fill", "#CDCDCD")
                    .style("stroke", "#CDCDCD")
                    .style("fill-opacity", cfg.opacityCircles)
                    .style("filter", "url(#glow)");
                //Create the straight lines radiating outward from the center
                axisGrid
                    .selectAll(".axis")
                    .data(allAxis)
                    .enter()
                    .append("g")
                    .attr("class", "axis");
                //Append the lines

                axisGrid
                    .selectAll(".radiusArea")
                    .data(item)
                    .enter()
                    .append("circle")
                    .attr("class", "gridCircle")
                    .attr("r", function (d) {
                        return rScale(getValue(d, 0));
                    })
                    .style("fill", function (d) {
                        return (d as any).fill;
                    })
                    .style("stroke", function (d) {
                        return (d as any).fill;
                    })
                    .style("fill-opacity", cfg.opacityCircles);
                axisGrid
                    .selectAll(".circleArea")
                    .data(item)
                    .enter()
                    .append("circle")
                    .attr("class", "radarCircle")
                    .attr("r", cfg.dotRadius)
                    .attr("cx", function (d, i) {
                        return (
                            rScale(getValue(d as any, 0)) *
                            Math.cos(angleSlice * i - Math.PI / 2)
                        );
                    })
                    .attr("cy", function (d, i) {
                        return (
                            rScale(getValue(d as any, 0)) *
                            Math.sin(angleSlice * i - Math.PI / 2)
                        );
                    })
                    .style("fill", function (d) {
                        return (d as any).fill;
                    })
                    .style("fill-opacity", 0.8)
                    .on("mouseenter", mouseenter)
                    .on("mousemove", mousemove)
                    .on("mouseleave", mouseleave);
            }
        }
    }, [
        parentSvgRef.current?.clientHeight,
        parentSvgRef.current?.clientWidth,
        props.data,
        props.config,
        props.config.tooltipColor,
        props.config.tooltipFontSize,
        props.config.linear,
        props.config.nameColorMapping,
        props.config.labelValuesType,
        props.config.showLegend,
        props.config.showLabels,
        props.isVaryByData,
        props.editable,
        onShowBarColorMenu,
    ]);

    return (
        <div
            ref={parentSvgRef}
            style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                // Resizing does not work properly
                // without overflow: "hidden"
                overflow: "hidden",
            }}
        >
            {showTooltipInfo && (
                <Portal rootNode={document.body}>
                    <div
                        style={{
                            zIndex: 100000,
                            position: "absolute",
                            top: showTooltipInfo.y,
                            left: showTooltipInfo.x + 5,
                            padding: "10px",
                            ...tooltipStyle.contentStyle,
                            display: "flex",
                            flexDirection: "column",
                            pointerEvents: "none",
                        }}
                    >
                        <span
                            className="unselectable"
                            style={{
                                ...tooltipStyle.itemStyle,
                                color: showTooltipInfo.fill,
                            }}
                        >
                            {`${showTooltipInfo.name} : ${tooltipFormat(
                                showTooltipInfo
                            )}`}
                        </span>
                    </div>
                </Portal>
            )}
            <svg ref={svgRef} />
        </div>
    );
};

interface Props {
    data: PieChartFinding["content"]["data"];
    varyByData: PieChartFinding["content"]["varyByData"];
    additionalVaryByData: PieChartFinding["content"]["additionalVaryByData"];
    config: PieChartFinding["config"];
    groupNames: PieChartFinding["content"]["groupNames"];
    editable?: boolean;
    columnDragActive?: boolean;
    preview?: boolean;
    onChangeData?: (
        data: PieChartFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: PieChartFinding["config"],
        updateData?: boolean
    ) => void;
}

export default function RadarChart(props: Props) {
    let transformedDataLength: number = useMemo(() => {
        if (props.varyByData != null) {
            return 1 + (props.additionalVaryByData?.length ?? 0);
        } else {
            return 1;
        }
    }, [props.varyByData, props.additionalVaryByData]);
    let { config } = props;
    let showTotals = config.showTotals !== undefined ? config.showTotals : true;

    let [showBarColorMenu, setShowBarColorMenu] = React.useState<{
        name: string;
        index: number;
        x: number;
        y: number;
    } | null>(null);

    let legendsColor =
        config.ticksColor ??
        mainStyle.getPropertyValue("--graphs-axes-text-color");
    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData = Array.from(props.data);
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        props.onChangeData?.(newData, true);
    };
    let linkedVariables = props.data.filter(
        (item) => item.variableIndex != null
    );
    let currentEditVariableIndex = props.data.findIndex(
        (item) => item.variableIndex == null
    );
    let datasWithIndexAndColor: {
        index: number;
        globalIndex: number;
        fill: string;
        name: string;
        groupName?: string;
        value: number | number[];
        variableIndex?: number;
        originalName?: string;
    }[][] = useMemo(() => {
        let transformedData: Exclude<
            PieChartFinding["content"]["additionalVaryByData"],
            null | undefined
        >;
        if (props.varyByData != null) {
            if (props.additionalVaryByData != null) {
                transformedData = [props.varyByData].concat(
                    props.additionalVaryByData
                );
            } else {
                transformedData = [props.varyByData];
            }
        } else {
            transformedData = [props.data];
        }
        let groupNames = props.groupNames ?? [""];
        if (props.varyByData)
            return transformedData.map((dataItem, groupIndex) =>
                dataItem.map((item, index) => {
                    return {
                        ...item,
                        index: 0,
                        globalIndex: groupIndex,
                        fill:
                            config.nameColorMapping?.[groupIndex] ??
                            colorList[groupIndex % colorList.length],
                    };
                })
            );
        else
            return groupNames.map((groupName, groupIndex) =>
                transformedData[0].map((item, index) => {
                    let sectorIsNotInitialized =
                        props.editable &&
                        config.dataScope != null &&
                        item.variableIndex == null;
                    return {
                        ...item,
                        index: index,
                        groupName: groupName,
                        value: getValue(item, groupIndex),
                        globalIndex:
                            groupIndex * transformedData[0].length + index,
                        fill: props.preview
                            ? previewColors[index % previewColors.length]
                            : sectorIsNotInitialized
                            ? "#EFF5FA"
                            : config.nameColorMapping?.[
                                  groupIndex * transformedData[0].length + index
                              ] ??
                              (item.variableIndex == null
                                  ? previewColors[
                                        ((groupIndex *
                                            transformedData[0].length +
                                            index) %
                                            previewColors.length) %
                                            previewColors.length
                                    ]
                                  : colorList[
                                        (groupIndex *
                                            transformedData[0].length +
                                            index) %
                                            colorList.length
                                    ]),
                    };
                })
            );
    }, [
        props.data,
        props.varyByData,
        props.additionalVaryByData,
        props.groupNames,
        props.editable,
        props.preview,
        config.nameColorMapping,
        config.dataScope,
    ]);
    let plot = (
        <div
            style={{
                display: "flex",
                height: "100%",
                width: "100%",
            }}
        >
            {props.config.showVariableLabels && (
                <div className="flex-simple-column">
                    {props.data.map((item, index) => {
                        return (
                            <div style={{ marginTop: 16 }}>
                                <EditableAxisItem
                                    width={150}
                                    onChange={(value) => {
                                        let newData = Array.from(props.data);
                                        newData[index].name = value;
                                        props.onChangeData?.(newData, true);
                                    }}
                                    color={"#333333"}
                                    currentEditVariableIndex={
                                        currentEditVariableIndex
                                    }
                                    editable={props.editable}
                                    index={index}
                                    vertical={false}
                                    name={item.name}
                                    onDrop={linkVariable}
                                />
                            </div>
                        );
                    })}
                </div>
            )}

            {props.varyByData == null &&
                datasWithIndexAndColor.map((dataItem, groupIndex) => {
                    let sum = dataItem.reduce((accumulator, item) => {
                        return accumulator + getValue(item, 0);
                    }, 0);
                    let sumValue = formatValue(sum, false);
                    let sumLabel = `Total ${
                        valueToTitle[props.config.operationVariable]
                    }:`;
                    return (
                        <div
                            style={{
                                overflow: "hidden",
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                                height: "100%",
                                width: `calc(100%/${datasWithIndexAndColor.length})`,
                            }}
                        >
                            {/* NEVER pass a lambda function as onShowBarColorMenu - it will cause the chart to re-render every time*/}
                            <InnerChart
                                onShowBarColorMenu={setShowBarColorMenu}
                                data={props.data}
                                config={props.config}
                                groupNames={props.groupNames}
                                dataItem={[dataItem]}
                                editable={props.editable}
                            />
                            {(config.showLegend ?? true) && (
                                <div
                                    className="my-row"
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        flexWrap: "wrap",
                                        height: 20,
                                        justifyContent: "center",
                                    }}
                                >
                                    {dataItem.map((item, index) => (
                                        <LegendEditElement
                                            allowColorChange={props.editable}
                                            disallowTextChange
                                            textSize={config.legendSize}
                                            text={item.name}
                                            color={item.fill}
                                            textColor={legendsColor}
                                            onFinishColor={(color) => {
                                                let nameColorMapping = {
                                                    ...config.nameColorMapping,
                                                };
                                                nameColorMapping[
                                                    groupIndex *
                                                        dataItem.length +
                                                        index
                                                ] = color;
                                                props.onChangeConfig?.({
                                                    ...config,
                                                    nameColorMapping:
                                                        nameColorMapping,
                                                });
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                            {showTotals &&
                                props.data.filter(
                                    (item) => item.variableIndex != null
                                ).length > 0 && (
                                    <span
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            bottom: 0,
                                            color: legendsColor,
                                            margin: 10,
                                            textAlign: "center",
                                            fontFamily: "Roboto",
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        {[
                                            props.groupNames?.[groupIndex] ??
                                                "",
                                            sumLabel,
                                            sumValue.join(""),
                                        ].join(" ")}
                                    </span>
                                )}
                        </div>
                    );
                })}
            {props.varyByData != null && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: `100%`,
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    {/* NEVER pass a lambda function as onShowBarColorMenu - it will cause the chart to re-render every time*/}
                    <InnerChart
                        onShowBarColorMenu={setShowBarColorMenu}
                        data={props.data}
                        config={props.config}
                        isVaryByData
                        groupNames={props.groupNames}
                        dataItem={datasWithIndexAndColor}
                        editable={props.editable}
                    />
                    {(config.showLegend ?? true) && (
                        <div
                            className="my-row"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                flexWrap: "wrap",
                                height: 20,
                                justifyContent: "center",
                            }}
                        >
                            {linkedVariables.map((linkedVariable, index) => (
                                <LegendEditElement
                                    allowColorChange={props.editable}
                                    disallowTextChange
                                    textSize={config.legendSize}
                                    text={linkedVariable.name}
                                    color={
                                        props.config?.nameColorMapping?.[
                                            index
                                        ] ?? colorList[index % colorList.length]
                                    }
                                    textColor={legendsColor}
                                    onFinishColor={(color) => {
                                        let nameColorMapping = {
                                            ...config.nameColorMapping,
                                        };
                                        let globalIndex = index;
                                        nameColorMapping[globalIndex] = color;
                                        props.onChangeConfig?.({
                                            ...config,
                                            nameColorMapping: nameColorMapping,
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    )}
                    {props.data.filter((item) => item.variableIndex != null)
                        .length > 0 && (
                        <span
                            style={{
                                position: "absolute",
                                width: "100%",
                                bottom: 0,
                                color: legendsColor,
                                margin: 10,
                                textAlign: "center",
                                fontFamily: "Roboto",
                                fontWeight: "bold",
                                fontSize: "12px",
                                wordWrap: "break-word",
                            }}
                        >
                            {getVaryBySum(
                                datasWithIndexAndColor,
                                props.config.operationVariable!
                            )}
                        </span>
                    )}
                </div>
            )}

            {showBarColorMenu && (
                <Portal rootNode={document.body}>
                    <OutsideAlerter
                        onReject={() => {
                            setShowBarColorMenu(null);
                        }}
                    >
                        <div
                            style={{
                                zIndex: 100000000,
                                position: "absolute",
                                left: showBarColorMenu.x,
                                top: showBarColorMenu.y,
                            }}
                        >
                            <ColorPicker
                                enableAlpha={true}
                                width={"220px"}
                                color={
                                    config.nameColorMapping?.[
                                        showBarColorMenu.index
                                    ] ??
                                    colorList[
                                        showBarColorMenu.index %
                                            colorList.length
                                    ]
                                }
                                onChange={(color) => {
                                    let nameColorMapping = {
                                        ...config.nameColorMapping,
                                    };
                                    nameColorMapping[showBarColorMenu!.index] =
                                        color;
                                    props.onChangeConfig?.({
                                        ...config,
                                        nameColorMapping: nameColorMapping,
                                    });
                                }}
                            />
                        </div>
                    </OutsideAlerter>
                </Portal>
            )}
        </div>
    );
    return plot;
}
