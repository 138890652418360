import Cookies from "universal-cookie";
import amplitude from "amplitude-js";

import axios from "common/ServerConnection";
import {PermanentLinkType} from "./ModulesApi";

import sections from "sections.json";

type EventType =
    | "interaction"
    | "section_visit"
    | "module_visit"
    | "module_shared"
    | "module_shared_link"
    | "new_self_sign_up"
    | "new_sign_up_link"
    | "new_sign_up_share"
    | "new_sign_up_uncategorized"
    | "referral";


const eventTypeLabel: Readonly<{ [key in EventType]: string }> = {
    interaction: "Interaction",
    section_visit: "Section visit",
    module_visit: "Presentation visit",
    module_shared: "Shared presentation",
    module_shared_link: "Shared presentation (link)",
    new_self_sign_up: "New self sign up",
    new_sign_up_link: "New sign up link",
    new_sign_up_share: "New sign up share",
    new_sign_up_uncategorized: "New sign up uncategorized",
    referral: "Referral",
};

export interface InstrumentationValues {
    close?: boolean;
    close_broken?: boolean;
    set_status?: string;
    data?: Array<{
        type: EventType;
        start_time: number;
        section: string;
        element: string;
        module: string;
        count_times: number | null;
        time_ms: number | null;
        referral_link_id?: string | null;
    }>;
}

export enum InstrumentationStatus {
    Active = "active",
    Inactive = "inactive",
}

class Instrumentation {
    public static async updateOrCreate(
        values: InstrumentationValues = {}
    ): Promise<void> {
        if (values.data != null) {
            for (let item of values.data) {
                amplitude.getInstance().logEvent(eventTypeLabel[item.type], {
                    section: item.section,
                    element: item.element,
                    module: item.module,
                    time_ms: item.time_ms,
                });
            }
        }
        const cookies = new Cookies();
        let sessionId = parseInt(cookies.get("instrumentation_session_id"));
        return axios
            .post<{
                success: boolean;
                error_msg: string;
                session_id?: number;
            }>("/api/session_update_or_create", {
                session_id: sessionId,
                ...values,
            })
            .then((response) => {
                if (!response.data.success) {
                    console.log(response.data.error_msg);
                } else if ("session_id" in response.data) {
                    cookies.set(
                        "instrumentation_session_id",
                        response.data.session_id,
                        {
                            httpOnly: false,
                            path: "/",
                            secure: true,
                            sameSite: "strict",
                        }
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    public static async addInteraction(
        section: keyof typeof sections,
        interactionTimeMs: number,
        element: string = "",
        module: string = ""
    ): Promise<void> {
        return Instrumentation.updateOrCreate({
            data: [
                {
                    type: "interaction",
                    start_time: Date.now() / 1000,
                    section: section,
                    element: element,
                    module: module,
                    count_times: 1,
                    time_ms: interactionTimeMs,
                },
            ],
        });
    }

    public static async addSectionVisit(
        section: keyof typeof sections
    ): Promise<void> {
        return Instrumentation.updateOrCreate({
            data: [
                {
                    type: "section_visit",
                    start_time: Date.now() / 1000,
                    section: section,
                    element: "",
                    module: "",
                    count_times: 1,
                    time_ms: null,
                },
            ],
        });
    }

    public static async addModuleVisit(module: string): Promise<void> {
        return Instrumentation.updateOrCreate({
            data: [
                {
                    type: "module_visit",
                    start_time: Date.now() / 1000,
                    section: "Modules",
                    element: "",
                    module: module,
                    count_times: 1,
                    time_ms: null,
                },
            ],
        });
    }

    public static async addModuleShared(module: string): Promise<void> {
        return Instrumentation.updateOrCreate({
            data: [
                {
                    type: "module_shared",
                    start_time: Date.now() / 1000,
                    section: "Modules",
                    element: "",
                    module: module,
                    count_times: 1,
                    time_ms: null,
                },
            ],
        });
    }

    public static async addModuleSharedLink(module: string): Promise<void> {
        return Instrumentation.updateOrCreate({
            data: [
                {
                    type: "module_shared_link",
                    start_time: Date.now() / 1000,
                    section: "Modules",
                    element: "",
                    module: module,
                    count_times: 1,
                    time_ms: null,
                },
            ],
        });
    }

    public static async close(): Promise<void> {
        amplitude.getInstance().logEvent("Logout");
        return Instrumentation.updateOrCreate({
            close: true,
        });
    }

    // public static closeBeforeUnload(): boolean {
    //     if ("sendBeacon" in navigator) {
    //         const cookies = new Cookies();
    //         let sessionId = parseInt(cookies.get("instrumentation_session_id"));
    //         let data = JSON.stringify({ session_id: sessionId, close: true });
    //         return navigator.sendBeacon("/api/session_update_or_create", data);
    //     } else {
    //         return false;
    //     }
    // }

    public static async setStatus(status: InstrumentationStatus) {
        if (status === InstrumentationStatus.Active) {
            amplitude.getInstance().logEvent("Active");
        } else {
            amplitude.getInstance().logEvent("Inactive");
        }
        return Instrumentation.updateOrCreate({
            set_status: status,
        });
    }

    public static async addRegistration(permanentLinkType: PermanentLinkType,
                                        linkId: string | null): Promise<void> {
        let signUpType: EventType;
        let referralLinkId = null;
        if (permanentLinkType === PermanentLinkType.Vanilla) {
            signUpType = "new_self_sign_up";
        } else if (permanentLinkType === PermanentLinkType.App) {
            signUpType = "new_sign_up_link";
        } else if (permanentLinkType === PermanentLinkType.Module) {
            signUpType = "new_sign_up_share";
        } else if (permanentLinkType === PermanentLinkType.Referral) {
            signUpType = "referral";
            referralLinkId = linkId;
        } else {
            signUpType = "new_sign_up_uncategorized"; // Assign the EventType value
        }
        amplitude.getInstance().logEvent(signUpType, {
            start_time: Date.now() / 1000,
            section: "",
            element: "",
            module: "",
            count_times: 1,
            referral_link_id: referralLinkId,
            time_ms: null,
        })
    }

}

export default Instrumentation;
