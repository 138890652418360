import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { observer } from "mobx-react";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import CollapseExtendButton from "common/CollapseExtendButton";
import ScaledPage from "common/ScaledPage";
import Instrumentation from "common/Instrumentation";
import GlobalContext from "GlobalContext";
import { ReactComponent as AutoRenewIcon } from "icons/left_nav_menu/autorenew.svg";

import styles from "./main.module.css";

import modules from "./modules/module_list.json";

const MainComponent = observer(
    class MainComponent extends Component {
        constructor(props) {
            super(props);
            this.state = {
                submodules: [],
                sections: [],
            };

            this.performance = null;
            this.mounted = false;

            this.renderContents = this.renderContents.bind(this);
        }

        componentDidMount() {
            if (this.performance != null) {
                let timeMs = new Date() - this.performance;
                this.performance = null;
                Instrumentation.addInteraction("Processing", timeMs);
            }
            let submodules = [];
            let sections = new Array(modules.length).fill(false);
            let stepIndex = 1;
            modules.forEach((dir, index) => {
                let mod = require(`./modules/${dir}/main.js`);
                mod.stepIndex = stepIndex;
                submodules.push(mod);
                stepIndex += mod.MainComponent.getNumberOfSteps();
            });
            this.setState({
                submodules: submodules,
                sections: sections,
            });
        }

        componentDidUpdate() {
            if (this.performance != null) {
                let timeMs = new Date() - this.performance;
                this.performance = null;
                Instrumentation.addInteraction("Processing", timeMs);
            }
        }

        renderContents(globalContext) {
            if (!this.mounted) {
                this.performance = new Date();
                this.mounted = true;
            }
            let items = [];
            let submodule;
            let MainComponent;
            for (let index in this.state.submodules) {
                submodule = this.state.submodules[index];
                MainComponent = submodule.MainComponent;
                if (
                    submodule.requirePermission == null ||
                    submodule.requirePermission in globalContext.permissions
                ) {
                    items.push(
                        <>
                            <CollapseExtendButton
                                caption={MainComponent.getTitle()}
                                expanded={this.state.sections[index]}
                                onClick={() =>
                                    this.setState((state) => {
                                        let sections = new Array(
                                            modules.length
                                        ).fill(false);
                                        sections[index] = !state.sections[
                                            index
                                        ];
                                        return {
                                            sections: sections,
                                        };
                                    })
                                }
                            />
                            {this.state.sections[index] && (
                                <MainComponent index={submodule.stepIndex} />
                            )}
                        </>
                    );
                }
            }
            return (
                <ScaledPage>
                    <div className="content-wrapper hide-scroll">
                        <section
                            className="content"
                            style={this.props.positionStyle}
                        >
                            <Navbar className="bg-light justify-content-between">
                                {items}
                            </Navbar>
                        </section>
                    </div>
                </ScaledPage>
            );
        }

        render() {
            return (
                <GlobalContext.ObserverConsumer>
                    {this.renderContents}
                </GlobalContext.ObserverConsumer>
            );
        }
    }
);

export { MainComponent };
export let route = "/processing.html",
    section = "Processing",
    leftPanelItem = {
        section: "Processing",
        href: "processing.html",
        icon: <AutoRenewIcon className={styles.icon} />,
    },
    requirePermission = "Processing";
