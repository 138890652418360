import React from "react";

import { MapDataVariableValue, MapElement } from "common/Canvas";
import LocationSelectorView from "modules/canvas_page/question_views/RevampedLocationSelectorView";

import styles from "./PinsConfig.module.css";

interface Props {
    mapElement: MapElement;
    height: number;
    onChange: (value: Partial<MapElement>) => void;
    currentModuleId?: number;
}

function PinsConfig({ mapElement, height, onChange, currentModuleId }: Props) {
    return (
        <div className={styles.root}>
            <LocationSelectorView
                dataScope={mapElement.dataScope}
                usesCoordinates={mapElement.usesCoordinates}
                dataVariableOption={mapElement.dataVariableOption}
                country={mapElement.location?.country}
                state={mapElement.location?.state}
                city={mapElement.location?.city}
                address={mapElement.location?.address}
                zipcode={mapElement.location?.zipcode}
                latitude={mapElement.coordinates?.latitude}
                longitude={mapElement.coordinates?.longitude}
                maxHeight={height * 0.5}
                onChange={(locationChanges, coordinateChanges) => {
                    let changes: Partial<MapElement> = {};
                    if (locationChanges != null) {
                        changes.location = {
                            ...mapElement.location,
                            ...locationChanges,
                        };
                        changes.coordinates = undefined;
                    }
                    if (coordinateChanges != null) {
                        changes.coordinates = {
                            ...mapElement.coordinates,
                            ...coordinateChanges,
                        };
                        changes.location = undefined;
                    }
                    onChange(changes);
                }}
                onToggle={(newOption) => {
                    let changes = {
                        usesCoordinates:
                            newOption.value ===
                            MapDataVariableValue.LatitudeLongitude,
                        dataVariableOption: newOption,
                        location: undefined,
                        coordinates: undefined,
                    };
                    onChange(changes);
                }}
                currentModuleId={currentModuleId}
            />
        </div>
    );
}

export default PinsConfig;
