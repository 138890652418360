import React from "react";
import Finding, {
    formatValues,
    isLinePlot,
    LinePlotFinding,
} from "common/Finding";
import commonStyles from "../DataSection.module.css";
import barChartStyles from "../BarChartSection/BarChartSection.module.css";
import styles from "./LinePlotSection.module.css";
import ribbonStyles from "../../ChartsRibbon.module.css";
import { Button } from "react-bootstrap";
import EditInput from "../EditInput";
import cx from "classnames";
import StringUtils from "common/utilities/StringUtils";
import { useDrop } from "react-dnd";
import { VariableOption } from "common/Variables";
import variables from "common/Variables";
import { observer } from "mobx-react";
import AggregateSection from "../AggregateSection";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import MagicWand from "../MagicWand";
import { DynamicOptionType } from "common/DynamicOptions";
import { Accordion } from "react-bootstrap";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import Select, { createFilter } from "react-select";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";

interface Props {
    columnDragActive: boolean;
    dashboardId: string;
    canvasTreeStore: CanvasTreeStore;
    finding: LinePlotFinding;
    currentModuleId?: number;
    onClearEditing: () => void;
    onChange: (finding: Finding, updateData?: boolean) => void;
}

enum LinePlotChartMenuOptions {
    None = 0,
    Time = 1,
}

interface DropProps {
    index: number;
    onDrop: (
        index: number,
        variableName: string,
        variableIndex: number
    ) => void;
    optionalClassName?: string;
}

const DropArea: React.FC<DropProps> = (props) => {
    const [collected, drop] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            props.onDrop(
                props.index,
                otherItem.content.variableName,
                otherItem.content.variableIndex
            );
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    return (
        <div
            className={props.optionalClassName}
            style={{
                backgroundColor: collected.hover ? "#F4FBF5" : undefined,
            }}
            ref={drop}
        >
            {props.children}
        </div>
    );
};

function createName(
    baseName: string,
    data: {
        name: string;
        value: (string | number)[][];
        variableIndex?: number | undefined;
        originalName?: string | undefined;
    }[]
) {
    let names = new Set(data.map((item) => item.name));
    if (!names.has(baseName)) {
        return baseName;
    }
    let name = baseName;
    let nameIndex = 2;
    while (names.has(name)) {
        name = `${baseName} ${nameIndex}`;
        ++nameIndex;
    }
    return name;
}

export default observer(function LinePlotSection(props: Props) {
    let datasetIsConnected = props.finding.config.dataScope != null;
    let data = props.finding.content.data;
    let seriesLength = data.length;
    let sideBySideLength = data[0].value.length;
    let dataLength = data[0]?.value[0]?.length;
    let cols = dataLength + 1;
    let variableOptions: VariableOption[] = [];
    let [menuOptionSelected, setMenuOptionSelected] = React.useState(
        LinePlotChartMenuOptions.None
    );

    if (datasetIsConnected) {
        variableOptions = variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions;
    }

    const linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData = Array.from(props.finding.content.data);
        let newFinding = { ...props.finding };
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        newFinding.content.data = newData;
        props.onChange(newFinding, true);
    };
    let selectStyles = getCustomSelectStyleForDataSection(14, false);

    return (
        <div>
            {!datasetIsConnected && (
                <>
                    <div className="my-row">
                        <div
                            className={styles.dataContainer}
                            style={{
                                width: "calc(100% - 30px)",
                                gridTemplateColumns: `35% repeat(${
                                    cols - 1
                                }, calc(65%/${cols - 1}))`,
                            }}
                        >
                            {data.map((item, index) => (
                                <>
                                    <EditInput
                                        showDeleteButton={index !== 0}
                                        disabled={false}
                                        value={item.name}
                                        onDelete={() => {
                                            let finding: LinePlotFinding = {
                                                ...props.finding!,
                                                content: {
                                                    ...props.finding.content,
                                                    data: [
                                                        ...props.finding.content
                                                            .data,
                                                    ],
                                                },
                                            };
                                            finding.content.data.splice(
                                                index,
                                                1
                                            );
                                            props.onChange(finding);
                                        }}
                                        onChange={(value) => {
                                            let finding: LinePlotFinding = {
                                                ...props.finding!,
                                                content: {
                                                    ...props.finding.content,
                                                    data: [
                                                        ...props.finding.content
                                                            .data,
                                                    ],
                                                },
                                            };
                                            finding.content.data[
                                                index
                                            ].name = value as string;
                                            props.onChange(finding);
                                        }}
                                    ></EditInput>
                                    {item.value[0].map((valItem, valIndex) => (
                                        <EditInput
                                            disabled={false}
                                            showDeleteButton={
                                                index === 0 && valIndex !== 0
                                            }
                                            onDelete={() => {
                                                let finding: LinePlotFinding = {
                                                    ...props.finding!,
                                                    content: {
                                                        ...props.finding
                                                            .content,
                                                        data: [
                                                            ...props.finding
                                                                .content.data,
                                                        ],
                                                    },
                                                };
                                                finding.content.data.forEach(
                                                    (item) => {
                                                        item.value[0].splice(
                                                            valIndex,
                                                            1
                                                        );
                                                    }
                                                );
                                                props.onChange(finding);
                                            }}
                                            value={valItem}
                                            onChange={(value) => {
                                                let finding: LinePlotFinding = {
                                                    ...props.finding!,
                                                    content: {
                                                        ...props.finding
                                                            .content,
                                                        data: [
                                                            ...props.finding
                                                                .content.data,
                                                        ],
                                                    },
                                                };
                                                finding.content.data[
                                                    index
                                                ].value[0][valIndex] = Number(
                                                    value
                                                );
                                                props.onChange(finding);
                                            }}
                                        ></EditInput>
                                    ))}
                                </>
                            ))}
                        </div>
                        <Button
                            className={cx(
                                "btn btn-sm btn-primary my-primary",
                                commonStyles.addColumnButton
                            )}
                            onClick={() => {
                                let finding: LinePlotFinding = {
                                    ...props.finding!,
                                    content: {
                                        ...props.finding.content,
                                        data: [...props.finding.content.data],
                                    },
                                };
                                finding.content.data.forEach((item) => {
                                    item.value[0].push(0);
                                });
                                props.onChange(finding);
                            }}
                        >
                            {"\uFF0B"}
                        </Button>
                    </div>
                </>
            )}

            {datasetIsConnected && (
                <div className="my-row">
                    <MagicWand
                        onClearEditing={props.onClearEditing}
                        dashboardId={props.dashboardId}
                        allowMultiple={true}
                        title={"Variables"}
                        optionName="data"
                        canvasTreeStore={props.canvasTreeStore}
                        type={DynamicOptionType.DataVariable}
                    />
                    <div className={barChartStyles.dataContainer}>
                        <div className={commonStyles.header}>Variables</div>
                        <div className={commonStyles.header}>Values</div>
                        {data.map((item, index) => (
                            <React.Fragment key={index}>
                                <DropArea
                                    optionalClassName={
                                        props.columnDragActive
                                            ? commonStyles.highlightVariable
                                            : undefined
                                    }
                                    onDrop={linkVariable}
                                    index={index}
                                >
                                    <EditInput
                                        showDeleteButton={props.finding.content.data.length > 1}
                                        disabled={false}
                                        value={item.name}
                                        onChange={(value) => {
                                            let finding = {
                                                ...props.finding!,
                                                content: {
                                                    ...props.finding.content,
                                                    data: [
                                                        ...props.finding.content
                                                            .data,
                                                    ],
                                                },
                                            };
                                            finding.content.data[
                                                index
                                            ].name = value as string;

                                            props.onChange(finding);
                                        }}
                                        onDelete={() => {
                                            let finding = {
                                                ...props.finding!,
                                                content: {
                                                    ...props.finding.content,
                                                    data: [
                                                        ...props.finding.content
                                                            .data,
                                                    ],
                                                },
                                            };

                                            finding.content.data.splice(
                                                index,
                                                1
                                            );

                                            props.onChange(
                                                finding,
                                                item.variableIndex != null
                                            );
                                        }}
                                    ></EditInput>
                                </DropArea>
                                <DropArea
                                    onDrop={linkVariable}
                                    index={index}
                                    optionalClassName={
                                        props.columnDragActive
                                            ? commonStyles.highlightValue
                                            : undefined
                                    }
                                >
                                    <EditInput
                                        showDeleteButton={false}
                                        disabled={
                                            props.finding.content.data[index]
                                                .variableIndex != null
                                        }
                                        onChange={(value) => {
                                            let finding = {
                                                ...props.finding!,
                                                content: {
                                                    ...props.finding.content,
                                                    data: [
                                                        ...props.finding.content
                                                            .data,
                                                    ],
                                                },
                                            };
                                            finding.content.data[index] = {
                                                ...finding.content.data[index],
                                                value: [
                                                    ...finding.content.data[
                                                        index
                                                    ].value,
                                                ],
                                            };
                                            finding.content.data[
                                                index
                                            ].value[0] = [
                                                ...(value as string)
                                                    .split(",")
                                                    .map((v: string) =>
                                                        Number(v)
                                                    ),
                                            ];
                                            props.onChange(finding);
                                        }}
                                        value={
                                            props.finding.content.data[index]
                                                .variableIndex == null
                                                ? String(item.value[0])
                                                : formatValues(
                                                      item.value[0] as number[]
                                                  )
                                        }
                                    ></EditInput>
                                </DropArea>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}
            <Button
                style={{ marginTop: 10, width: "120px" }}
                className={cx(
                    "btn btn-sm btn-primary my-primary",
                    commonStyles.addItemButton
                )}
                onClick={() => {
                    let finding: LinePlotFinding = {
                        ...props.finding!,
                        content: {
                            ...props.finding.content,
                            data: [...props.finding.content.data],
                        },
                    };
                    let baseName = seriesLength === 0 ? "X series" : "Y series";
                    let name = createName(baseName, data);

                    finding.content.data.push({
                        name: name,
                        value: datasetIsConnected
                            ? new Array(sideBySideLength).fill([])
                            : new Array(sideBySideLength).fill(
                                  new Array(dataLength).fill(0)
                              ),
                    });
                    props.onChange(finding);
                }}
            >
                {"\uFF0B Add Series"}
            </Button>
            {isLinePlot(props.finding) &&
                props.finding.config.journeyName === "LineplotD3Journey" && (
                    <Accordion
                        activeKey={String(menuOptionSelected)}
                        onSelect={(event: any) => {
                            setMenuOptionSelected(Number(event));
                        }}
                    >
                        <Accordion.Toggle
                            eventKey={String(LinePlotChartMenuOptions.Time)}
                            className={ribbonStyles.editMenuAdvancedOption}
                        >
                            Time Animation
                            <ChevronIcon
                                className={cx(
                                    ribbonStyles.editMenuAdvancedOptionIcon,
                                    {
                                        [ribbonStyles.chevronOpen]:
                                            menuOptionSelected ===
                                            LinePlotChartMenuOptions.Time,
                                    }
                                )}
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse
                            eventKey={String(LinePlotChartMenuOptions.Time)}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Select
                                    isClearable
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder="Select variable"
                                    styles={{
                                        ...selectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "100%",
                                            height: "38px",
                                            marginTop: "8px",
                                        }),
                                    }}
                                    options={variableOptions}
                                    onChange={(newValue) => {
                                        let finding: LinePlotFinding = {
                                            ...(props.finding as LinePlotFinding),
                                            content: {
                                                ...(props.finding as LinePlotFinding)
                                                    .content,
                                                time:
                                                    (props.finding as LinePlotFinding)
                                                        .content.time != null
                                                        ? {
                                                              ...(props.finding as LinePlotFinding)
                                                                  .content
                                                                  .time!,
                                                          }
                                                        : {
                                                              name: "time",
                                                              value: [],
                                                              uniqueValues: [],
                                                          },
                                            },
                                        };
                                        if (newValue == null) {
                                            finding.content.time = null;
                                        } else {
                                            finding.content.time!.variableIndex = (newValue as VariableOption).value;
                                            finding.content.time!.originalName = (newValue as VariableOption).label;
                                            finding.content.time!.name = (newValue as VariableOption).label;
                                        }
                                        props.onChange(finding, true);
                                    }}
                                    value={
                                        props.finding.content.time
                                            ?.variableIndex != null
                                            ? {
                                                  label:
                                                      props.finding.content.time
                                                          .name,
                                                  value:
                                                      props.finding.content.time
                                                          .variableIndex,
                                              }
                                            : null
                                    }
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                )}
            {datasetIsConnected && (
                <>
                    <AggregateSection
                        dashboardId={props.dashboardId}
                        canvasTreeStore={props.canvasTreeStore}
                        finding={props.finding}
                        onChange={props.onChange}
                        onClearEditing={props.onClearEditing}
                    />
                    <div className={styles.optionContainer}>
                        <span className={commonStyles.optionName}>
                            Randomly sample
                        </span>
                        <EditInput
                            optionalStyles={{ marginLeft: 10 }}
                            optionalClassName={styles.input}
                            showDeleteButton={false}
                            disabled={false}
                            value={props.finding.config.count}
                            onChange={(value) => {
                                let finding: LinePlotFinding = {
                                    ...props.finding!,
                                    config: {
                                        ...props.finding.config,
                                    },
                                };
                                if (StringUtils.isNumber(value)) {
                                    let count = Number(value);
                                    if (count !== finding.config.count) {
                                        finding.config.count = count;
                                        props.onChange(finding, true);
                                    }
                                }
                            }}
                        ></EditInput>
                        <span
                            style={{ marginLeft: 10 }}
                            className={commonStyles.optionName}
                        >
                            points
                        </span>
                    </div>
                </>
            )}
        </div>
    );
});
