import React, { Component } from "react";
import { Navbar, Button } from "react-bootstrap";
import Popup from "reactjs-popup";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import { AppModuleProps } from "AppModule";
import ScaledPage from "common/ScaledPage";
import { AppInfo, listAppsApi, listAppsForUserApi } from "./api";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import linkIdToUrl from "common/utilities/linkIdToUrl";
import AppCreator from "common/canvas/AppCreator";
import UserSelector, { UserName } from "common/canvas/UserSelector";

const paymentTypeLabel: Readonly<{ [key: string]: string }> = {
    none: "None",
    one_time: "One Time",
    monthly: "Monthly Subscription",
    yearly: "Yearly Subscription",
};

interface State {
    status: PopupStatus | null;
    message: string;
    apps: AppInfo[];
    editingModuleId: number | null;
    userName: UserName | undefined | null;
    loading: boolean;
}

class MainComponent extends Component<AppModuleProps, State> {
    constructor(props: AppModuleProps) {
        super(props);
        this.state = {
            status: null,
            message: "",
            apps: [],
            editingModuleId: null,
            userName: null,
            loading: true,
        };
        this.renderContents = this.renderContents.bind(this);
        this.listApps = this.listApps.bind(this);
    }

    public componentDidMount(): void {
        this.listApps();
    }

    private listApps(): void {
        const promise =
            this.state.userName != null
                ? listAppsForUserApi(this.state.userName.user_name)
                : listAppsApi();
        promise
            .then((apps) => {
                this.setState({ apps: apps, loading: false });
            })
            .catch((message) => {
                console.log(message);
                this.setState({
                    status: PopupStatus.Error,
                    message: message.toString(),
                });
            });
    }

    private renderApps(): JSX.Element {
        if (this.state.apps.length === 0) {
            return (
                <span className="regular-text">
                    {this.state.loading ? "LOADING" : "NO APPS"}
                </span>
            );
        }
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "min-content",
                }}
            >
                {this.state.apps.map((app, index) => (
                    <>
                        {index !== 0 && (
                            <div
                                style={{
                                    width: "100%",
                                    height: "1px",
                                    backgroundColor:
                                        "var(--secondary-content-color)",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            />
                        )}
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span
                                className="regular-text"
                                style={{ width: "30em" }}
                            >
                                {`${index + 1}. ${app.title}`}
                            </span>
                            <span
                                style={{
                                    marginLeft: 10,
                                    marginRight: 5,
                                }}
                                className="regular-text unselectable"
                            >
                                Link:
                            </span>
                            <CopyToClipboard
                                text={linkIdToUrl(true, app.link_id)}
                                options={{
                                    format: "text/plain",
                                }}
                                onCopy={() => {
                                    this.setState({
                                        status: PopupStatus.Success,
                                        message: "App Link Copied to Clipboard",
                                    });
                                }}
                            >
                                <div
                                    className="my-row"
                                    style={{ alignItems: "center" }}
                                >
                                    <img
                                        alt=""
                                        src="/dist/img/canvas/share_module_link.png"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <span
                                        style={{
                                            marginLeft: 5,
                                            color: "#39F",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            fontFamily: "Roboto",
                                            cursor: "pointer",
                                        }}
                                        className="unselectable"
                                    >
                                        COPY
                                    </span>
                                </div>
                            </CopyToClipboard>
                            <span
                                style={{
                                    marginLeft: 20,
                                    width: "17em",
                                }}
                                className="regular-text unselectable"
                            >
                                {`Payment Type: ${
                                    paymentTypeLabel[
                                        app.paywall?.payment_type ?? "none"
                                    ]
                                }`}
                            </span>
                            <span
                                style={{
                                    marginLeft: 20,
                                    width: "7em",
                                }}
                                className="regular-text unselectable"
                            >
                                {app.paywall != null &&
                                    `Price: $${app.paywall.price}`}
                            </span>
                            <span
                                style={{
                                    marginLeft: 20,
                                    width: "12em",
                                }}
                                className="regular-text unselectable"
                            >
                                {app.paywall != null &&
                                    `Total revenue: $${app.paywall.revenue}`}
                            </span>
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: 30,
                                }}
                                onClick={() => {
                                    const linkTab = window.open(
                                        linkIdToUrl(true, app.link_id),
                                        "blank"
                                    );
                                    // linkTab can be null when the window failed to open.
                                    if (linkTab) {
                                        linkTab.focus();
                                    }
                                }}
                            >
                                OPEN
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: 5,
                                }}
                                onClick={() => {
                                    this.setState({
                                        editingModuleId: app.module_id,
                                    });
                                }}
                            >
                                EDIT
                            </Button>
                        </div>
                    </>
                ))}
            </div>
        );
    }

    private renderContents(globalContext: GlobalContextContents): JSX.Element {
        return (
            <ScaledPage>
                <div className="content-wrapper hide-scroll">
                    <section className="content-header">
                        <h3>Purchased Apps</h3>
                    </section>
                    <section
                        className="content"
                        style={this.props.positionStyle}
                    >
                        <Navbar className="bg-light justify-content-between">
                            {"ModifyAnyApp" in globalContext.permissions && (
                                <div style={{ marginBottom: 20 }}>
                                    <UserSelector
                                        value={this.state.userName}
                                        onChange={(newValue) => {
                                            this.setState(
                                                {
                                                    userName: newValue,
                                                },
                                                this.listApps
                                            );
                                        }}
                                        placeholder="SELECT USER"
                                    />
                                </div>
                            )}
                            {this.renderApps()}
                            {this.state.status != null && (
                                <StatusPopup
                                    status={this.state.status}
                                    message={this.state.message}
                                    onClose={() => {
                                        this.setState({
                                            status: null,
                                            message: "",
                                        });
                                    }}
                                />
                            )}
                        </Navbar>
                    </section>
                    {this.state.editingModuleId != null && (
                        <Popup
                            arrow={true}
                            contentStyle={{
                                width: 977,
                                minHeight: 380,
                                border: "none",
                                backgroundColor: "transparent",
                            }}
                            open={true}
                            closeOnDocumentClick={false}
                        >
                            <div
                                className="dashboard-rect"
                                style={{
                                    boxShadow:
                                        "0px 6px 13px rgba(21, 33, 56, 0.533345)",
                                    borderRadius: 10,
                                    alignItems: "center",
                                    cursor: "pointer",
                                    minHeight: 380,
                                    maxHeight: "100vh",
                                    width: 977,
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        paddingRight: 8,
                                        paddingTop: 9,
                                    }}
                                >
                                    <div
                                        className="my-row"
                                        style={{ justifyContent: "flex-end" }}
                                    >
                                        <div
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                this.setState({
                                                    editingModuleId: null,
                                                });
                                                this.listApps();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src="/dist/img/insights/insights_remove.png"
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <AppCreator
                                    moduleId={this.state.editingModuleId}
                                    onCancel={() => {
                                        this.setState({
                                            editingModuleId: null,
                                        });
                                        this.listApps();
                                    }}
                                />
                            </div>
                        </Popup>
                    )}
                </div>
            </ScaledPage>
        );
    }

    public render(): JSX.Element {
        return (
            <GlobalContext.ObserverConsumer>
                {this.renderContents}
            </GlobalContext.ObserverConsumer>
        );
    }
}

export { MainComponent };
export let route = "/apps.html";
