import React from "react";
import { getTargetValue, MapElement } from "common/Canvas";
import { ConditionsSelector } from "common/ConditionalsEditor";
import Variables from "common/Variables";
import remoteModuleId from "common/remoteModuleId";
import { lightThemeStyle } from "common/LightThemeStyle";
import { Condition, NodeLinkOption } from "common/Conditions";
import SharedBoxesStore from "modules/canvas_page/SharedBoxesStore";
import GlobalInputs, { getGlobalInputValue } from "common/GlobalInputs";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { observer } from "mobx-react";

interface Props {
    canvasTreeStore: CanvasTreeStore;
    mapElement: MapElement;
    onChange: (value: Partial<MapElement>) => void;
    currentModuleId?: number;
}

function ConditionsConfig({ mapElement, onChange, currentModuleId, canvasTreeStore }: Props) {
    if (!mapElement.dataScope) {
        return null;
    }
    let nodeLinkOptions = Array.from(canvasTreeStore.canvasTreeState.values())
    .map(
        (item) =>
            ({
                label: item.outerId,
                value: item.id,
                isCloneInput: false,
                target: getTargetValue(item),
            } as NodeLinkOption)
    )
    .concat(
        SharedBoxesStore.sharedBoxes(canvasTreeStore.canvasId!).map(
            (item) => ({
                label: item.label,
                value: item.value,
                isCloneInput: true,
                target: getTargetValue(item.item.box),
            })
        )
    )
    .concat(
        GlobalInputs.map((input) => ({
            label: input.label,
            value: input.value,
            isCloneInput: false,
            target: getGlobalInputValue(input.value, true),
            isGlobalInput: true,
        }))
    );

    return (
        <div>
            <ConditionsSelector
                small
                allowLinks
                nodeLinkOptions={nodeLinkOptions}
                dataScopeId={mapElement.dataScope!.value}
                currentModuleId={currentModuleId}
                title="where"
                style={{
                    marginTop: 0,
                }}
                value={mapElement.conditions ?? undefined}
                onChange={(conditions: Condition[]) => {
                    onChange({
                        conditions: conditions,
                    });
                }}
                allVariables={
                    Variables(
                        mapElement.dataScope!.value,
                        currentModuleId ?? remoteModuleId
                    ).dataVariables
                }
                titleStyle={{
                    color: lightThemeStyle.primaryTextColor,
                    width:
                        mapElement.conditions != null &&
                        mapElement.conditions.length > 1
                            ? "70px"
                            : undefined,
                }}
                rowStyle={(_index) => ({
                    backgroundColor: "transparent",
                    alignItems: "center",
                })}
                linkSwitchStyle={{
                    offColor: lightThemeStyle.switchOffColor,
                    onColor: lightThemeStyle.switchOnColor,
                    offHandleColor: lightThemeStyle.switchOffHandleColor,
                    onHandleColor: lightThemeStyle.switchOnHandleColor,
                }}
                buttonsStyle={{
                    backgroundColor: "transparent",
                    color: lightThemeStyle.secondaryTextColor,
                    fontFamily: "Roboto",
                    fontSize: "12px",
                }}
            />
        </div>
    );
}

export default observer(ConditionsConfig);
