import { NumberFormat, numberFormatOptions, NumberFormatType } from "common/Canvas";
import { mainStyle } from "common/MainStyle";
import { getSmallSelectStyle } from "common/SelectStyles";
import StringUtils from "common/utilities/StringUtils";
import React from "react";
import Select, { createFilter } from "react-select";
import Switch from "react-switch";
import styles from "./style.module.css";

interface Props {
    onChange: (format: NumberFormat) => void;
    currentFormat?: NumberFormat;
    extraTypes?: {
        month: boolean;
    }
}

function NumberFormater(props: Props) {
    const {
        onChange,
        currentFormat,
    } = props;

    const fontSize = 10;
    let customSelectStylesSmall = getSmallSelectStyle(fontSize);
    let textStyle = {
        fontWeight: 300,
        fontFamily: "Roboto",
        fontSize: fontSize,
        color: mainStyle.getPropertyValue("--popup-primary-text-color"),
    };

    const numberTypeOptions = [
        ...numberFormatOptions,
        {
            label: "Month",
            value: 4,
        },
    ]

    return (
        <div className={styles.root}>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>
                    Format
                </span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    isClearable={true}
                    placeholder={"Number format"}
                    styles={{
                        ...customSelectStylesSmall,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                        }),
                    }}
                    options={numberTypeOptions}
                    onChange={(newValue: any) => {
                        const newFormat = {
                            ...(currentFormat ?? {}),
                            numberType: newValue.value
                        }
                        onChange(newFormat as NumberFormat);
                    }}
                    value={{
                        label: NumberFormatType[currentFormat?.numberType ?? 0] ?? "Select...",
                        value: currentFormat?.numberType ?? null,
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            
            <div
                className="my-row"
                style={{
                    marginTop: "10px",
                    alignItems: "center",
                    alignSelf: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <span style={textStyle}>{"Decimals"}</span>
                <input
                    className="like-select"
                    style={{
                        ...textStyle,
                        fontWeight: 400,
                        minHeight: "18px",
                        width: "70px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        fontFamily: "monospace",
                        textAlign: "right",
                    }}
                    placeholder=""
                    onChange={(e) => {
                        let value = e.target.value;
                        let decimalPoints: number = 0;
                        if (StringUtils.isNumber(value)) {
                            decimalPoints = Math.min(
                                Math.max(
                                    0,
                                    Math.trunc(Number(value))
                                ),
                                20
                            );
                        }

                        const newFormat = {
                            ...(currentFormat ?? {}),
                            decimalPoints,
                        }
                        onChange(newFormat as NumberFormat);
                    }}
                    defaultValue={currentFormat?.decimalPoints ?? ""}
                />
            </div>
            <div
                className="my-row"
                style={{
                    marginTop: "10px",
                    alignItems: "center",
                    alignSelf: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <span style={textStyle}>
                    {"Use Abbreviation"}
                </span>
                <div style={{ marginLeft: 5 }}>
                    <Switch
                        onChange={(checked) => {
                            const newFormat = {
                                ...(currentFormat ?? {}),
                                useAbbreviation: checked,
                            }
                            onChange(newFormat as NumberFormat);
                        }}
                        checked={currentFormat?.useAbbreviation ?? false}
                        width={16}
                        height={7}
                        offColor="#20293C"
                        onColor="#20293C"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#70889E"
                        onHandleColor="#1F8EFA"
                    />
                </div>
            </div>
            <div
                className="my-row"
                style={{
                    marginTop: "10px",
                    alignItems: "center",
                    alignSelf: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <span style={textStyle}>
                    {"Use 1000 separator(,)"}
                </span>
                <div style={{ marginLeft: 5 }}>
                    <Switch
                        onChange={(checked) => {
                            const newFormat = {
                                ...(currentFormat ?? {}),
                                useCommaSeparator: checked,
                            }
                            onChange(newFormat as NumberFormat);
                        }}
                        checked={currentFormat?.useCommaSeparator ?? true}
                        width={16}
                        height={7}
                        offColor="#20293C"
                        onColor="#20293C"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#70889E"
                        onHandleColor="#1F8EFA"
                    />
                </div>
            </div>
        </div>
    );
}

export default NumberFormater;
