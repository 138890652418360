import React from "react";
import LeversBenchmarkingChart from "common/graphics/v2/LeversBenchmarkingChart";
import { LeversBenchmarkingFinding } from "common/Finding";

interface Props {
    finding: LeversBenchmarkingFinding;
    onNewFinding?: (
        finding: LeversBenchmarkingFinding,
        updateData?: boolean
    ) => void;
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
}

function MainComponent(props: Props) {
    let finding = props.finding;
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <LeversBenchmarkingChart
                editable={props.editable}
                preview={props.preview}
                columnDragActive={props.columnDragActive}
                config={finding.config}
                data={finding.content.data}
                groupValue={finding.content.groupValue}
                compareValue={finding.content.compareValue}
                aggregateVariable={finding.content.aggregateVariable}
                groupOperation={finding.content.groupOperation}
                compareOperation={finding.content.compareOperation}
                onChangeData={
                    props.onNewFinding
                        ? (data: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.content.data = data;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeConfig={
                    props.onNewFinding
                        ? (config: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.config = config;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
            />
        </div>
    );
}

export { MainComponent };
