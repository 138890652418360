import React from "react";
import { DropdownQuestion } from "common/Canvas";
import Select, { createFilter } from "react-select";
import Tables, { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Variables, { VariableOption } from "common/Variables";
import { observer } from "mobx-react";
import SearchComponent, {SearchComponentOption} from "common/SearchComponent";
import { dataScienceSelectStyles } from "common/SelectStyles";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";

interface Props {
    question: DropdownQuestion;
    maxHeight: number;
    live: boolean;
    frozen: boolean;
    onChange: (question: DropdownQuestion) => void;
    currentModuleId?: number;
}

const DropdownQuestionView = observer((props: Props) => {
    let dataScopes =
        props.currentModuleId != null
            ? DataScopesForModules(props.currentModuleId)
            : DataScopes;
    return (
        <div
            className="flex-simple-column"
            style={{ maxHeight: props.maxHeight, marginLeft: "90px" }}
        >
            <div
                className="flex-simple-column"
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                {!props.live && (
                    <>
                        <div
                            onMouseDown={(evt) => {
                                evt.stopPropagation();
                            }}
                        >
                            <Select
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                isDisabled={props.frozen}
                                placeholder={"Link to backend data & column"}
                                styles={{
                                    ...dataScienceSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "100%",
                                        height: "35px",
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 51 }),
                                }}
                                options={dataScopes.dataScopesOptions}
                                onChange={(newValue) => {
                                    let newQuestion = Object.assign(
                                        {},
                                        props.question
                                    );
                                    newQuestion.dataScopeOption = newValue as DataScopeOption;
                                    newQuestion.tableOption = null;
                                    props.onChange(newQuestion);
                                }}
                                value={props.question.dataScopeOption}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                height: "2px",
                                backgroundColor:
                                    dataScienceElementsStyle.secondaryTextColor,
                            }}
                        />
                    </>
                )}
                {!props.live && props.question.dataScopeOption && (
                    <>
                        <div
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            <Select
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                isDisabled={props.frozen}
                                placeholder={"Select table"}
                                styles={{
                                    ...dataScienceSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginTop: "20px",
                                        width: "100%",
                                        height: "35px",
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 51 }),
                                }}
                                options={
                                    Tables(
                                        props.question.dataScopeOption.value,
                                        props.currentModuleId ?? remoteModuleId
                                    ).rawAndAggregateTableOptions
                                }
                                onChange={(newValue) => {
                                    let newQuestion = Object.assign(
                                        {},
                                        props.question
                                    );
                                    newQuestion.tableOption = newValue as TableOption;
                                    newQuestion.tableValue = null;
                                    props.onChange(newQuestion);
                                }}
                                value={props.question.tableOption}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                            />
                        </div>
                        <div
                            style={{
                                height: "2px",
                                backgroundColor:
                                    dataScienceElementsStyle.secondaryTextColor,
                            }}
                        />
                    </>
                )}
                {!props.live && props.question.dataScopeOption && (
                    <>
                        <div
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            <Select
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                isDisabled={props.frozen}
                                placeholder={"Select column"}
                                styles={{
                                    ...dataScienceSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginTop: "20px",
                                        width: "100%",
                                        height: "35px",
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 51 }),
                                }}
                                options={Variables(
                                    props.question.dataScopeOption.value,
                                    props.currentModuleId ?? remoteModuleId
                                ).dataVariables.map((variable) => ({
                                    label: variable.name,
                                    value: variable.index ?? -1,
                                    type: variable.type,
                                    panel: variable.panel,
                                }))}
                                onChange={(newValue) => {
                                    let newQuestion = Object.assign(
                                        {},
                                        props.question
                                    );
                                    newQuestion.variableOption = newValue as VariableOption;
                                    newQuestion.tableValue = null;
                                    props.onChange(newQuestion);
                                }}
                                value={props.question.variableOption}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                            />
                        </div>
                        <div
                            style={{
                                height: "2px",
                                backgroundColor:
                                    dataScienceElementsStyle.secondaryTextColor,
                            }}
                        />
                    </>
                )}
                {props.question.dataScopeOption &&
                    props.question.variableOption &&
                    props.live && (
                        <>
                            <div
                                style={{ width: "100%", marginTop: "20px" }}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                onMouseDown={(evt: any) => {
                                    evt.stopPropagation();
                                }}
                            >
                                <SearchComponent
                                    additionalOptions={{
                                        menuPortalTarget: document.body,
                                        menuShouldBlockScroll: true,
                                    }}
                                    additionalStyles={{
                                        menuPortal: (base: any) => ({
                                            ...base,
                                            zIndex: 51,
                                        }),
                                    }}
                                    clearable
                                    dataScopeId={
                                        props.question.dataScopeOption.value
                                    }
                                    customStyles={dataScienceSelectStyles}
                                    initialValue={
                                        props.question.tableValue != null
                                            ? {
                                                  label: String(
                                                      props.question.tableValue
                                                  ),
                                                  value:
                                                      props.question.tableValue,
                                              }
                                            : null
                                    }
                                    searchIndex={
                                        props.question.variableOption.value
                                    }
                                    onOptionSelected={(selectedValue) => {
                                        let newQuestion = Object.assign(
                                            {},
                                            props.question
                                        );
                                        newQuestion.tableValue =
                                            selectedValue != null
                                                ? (selectedValue as SearchComponentOption).value as string | number
                                                : null;
                                        props.onChange(newQuestion);
                                    }}
                                    currentModuleId={props.currentModuleId}
                                />
                            </div>
                            <div
                                style={{
                                    height: "2px",
                                    backgroundColor:
                                        dataScienceElementsStyle.secondaryTextColor,
                                }}
                            />
                        </>
                    )}
            </div>
        </div>
    );
});

export default DropdownQuestionView;
