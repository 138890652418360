import React, { useRef } from "react";
import { useDrop } from "react-dnd";
import { mainStyle } from "common/MainStyle";
import { Button } from "react-bootstrap";

function TabDrop(props) {
	const ref = useRef(null);

	const drop = useDrop({
		accept: props.acceptDrop,
		drop(otherItem, monitor) {
			if (!props.tabId) return;
			let item = Object.assign({}, otherItem.content);
			if (String(item.tab_id) === String(props.tabId)) return;
			props.onDrop(item, props.tabId);
		},
	})[1];
	if (!props.frozen) {
		drop(ref);
	}
	return <div ref={ref}>{React.Children.only(props.children)}</div>;
}

export default function ExpandableTab(props) {
	let { title, tabId, tabComponent, acceptDrop, frozen } = props;

	let editMode = tabId == null || tabComponent.state.editedTabId === tabId;

	return (
		<TabDrop
			frozen={frozen}
			acceptDrop={acceptDrop}
			tabId={tabId}
			onDrop={tabComponent.onDropItem}
		>
			<div
				onClick={() => {
					if (tabId == null) return;
					tabComponent.onExpandTab(tabId);
				}}
				className="my-row collapse-expand-div"
				style={{
					cursor: "pointer",
					height: 47,
					marginTop: 20,
					marginBottom: 20,
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div
					className="my-row"
					style={{
						width: "100%",
						height: "100%",
						marginRight: 50,
						alignItems: "center",
					}}
					onClick={(evt) => {
						if (editMode) evt.stopPropagation();
					}}
				>
					<div
						style={{
							width: 7,
							height: "100%",
							backgroundColor: tabComponent.state.sections[tabId]
								? mainStyle.getPropertyValue(
										"--sections-title-color"
								  )
								: mainStyle.getPropertyValue(
										"--sections-inactive-title-color"
								  ),
						}}
					/>

					<input
						readOnly={!editMode || frozen}
						className="like-tab"
						style={{
							width: "100%",
							marginLeft: 20,
							alignSelf: "center",
							color: tabComponent.state.sections[tabId]
								? mainStyle.getPropertyValue(
										"--sections-title-color"
								  )
								: mainStyle.getPropertyValue(
										"--sections-inactive-title-color"
								  ),
						}}
						onClick={(evt) => {
							if (evt.detail === 1) {
								if (editMode) {
									evt.stopPropagation();
								}
							} else if (evt.detail === 2) {
								if (!frozen) tabComponent.onStartEditTab(tabId);
							}
						}}
						value={title}
						onChange={(evt) => {
							if (!frozen)
								tabComponent.onEditTab(evt.target.value, tabId);
						}}
						onBlur={(evt) => {
							if (editMode && !frozen)
								tabComponent.onUpdateTab(
									evt.target.value,
									tabId
								);
						}}
						onKeyDown={(evt) => {
							if (evt.which === 13 && !evt.shiftKey) {
								evt.preventDefault();
								if (editMode && !frozen)
									tabComponent.onUpdateTab(
										evt.target.value,
										tabId
									);
							}
						}}
					/>
				</div>
				{tabId != null && (
					<>
						<span
							title={
								tabComponent.state.sections[tabId]
									? "Collapse"
									: "Expand"
							}
							className="unselectable sections-title-span"
							style={{
								marginRight: 20,
								alignSelf: "center",
							}}
						>
							{tabComponent.state.sections[tabId]
								? "\uFF0D"
								: "\uFF0B"}
						</span>
						{!frozen && (
							<Button
								title={"Delete"}
								style={{
									marginTop: "-3px",
									marginRight: 20,
									alignSelf: "center",
								}}
								className="close"
								onClick={(evt) => {
									evt.stopPropagation();
									tabComponent.onRemoveTab(tabId);
								}}
							>
								<span className="sections-title-span">
									&times;
								</span>
							</Button>
						)}
					</>
				)}
			</div>
		</TabDrop>
	);
}