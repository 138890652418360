import React, { Component } from "react";
import { observer } from "mobx-react";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";

import customSelectStyles from "common/SelectStyles";
import UserGroupsStore, { UserGroupOption } from "common/UserGroupsStore";

interface Props {
    containerStyle?: React.CSSProperties;
    selectedUserGroups: string[];
    onChange: (selectedUserGroups: string[]) => void;
}

function userGroupsIsEqual(
    group1: (string | null)[],
    group2: (string | null)[]
): boolean {
    return (
        group1.length === group2.length &&
        group1.every((value, index) => value === group2[index])
    );
}
interface State {
    selectedUserGroups: (string | null)[];
}

const UserGroupsSelector = observer(
    class UserGroupsSelector extends Component<Props, State> {
        constructor(props: Props) {
            super(props);
            this.state = {
                selectedUserGroups:
                    this.props.selectedUserGroups.length > 0
                        ? this.props.selectedUserGroups
                        : [null],
            };
        }

        componentDidMount() {
            UserGroupsStore.init();
        }

        componentDidUpdate(prevProps: Props, _prevState: State) {
            if (
                !userGroupsIsEqual(
                    this.props.selectedUserGroups,
                    prevProps.selectedUserGroups
                )
            ) {
                this.setState({
                    selectedUserGroups:
                        this.props.selectedUserGroups.length > 0
                            ? this.props.selectedUserGroups
                            : [null],
                });
            }
        }

        render() {
            let options = UserGroupsStore.userGroupsOptions.filter(
                (item) => !this.state.selectedUserGroups.includes(item.value)
            );
            return (
                <div className="flex-simple-column">
                    {this.state.selectedUserGroups.map((userGroup, index) => (
                        <div
                            className="my-row"
                            key={index}
                            style={{
                                marginTop: "5px",
                                ...this.props.containerStyle,
                            }}
                        >
                            <Select
                                placeholder={"Enter group"}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        height: "38px",
                                        width: "140px",
                                    }),
                                }}
                                options={options}
                                value={
                                    userGroup != null
                                        ? {
                                              label: userGroup,
                                              value: userGroup,
                                              id: 0,
                                          }
                                        : null
                                }
                                onChange={(newValue) =>
                                    this.setState(
                                        (state) => {
                                            let selectedUserGroups = Array.from(
                                                state.selectedUserGroups
                                            );
                                            selectedUserGroups[index] = (
                                                newValue as UserGroupOption
                                            ).value;
                                            return {
                                                selectedUserGroups:
                                                    selectedUserGroups,
                                            };
                                        },
                                        () =>
                                            this.props.onChange(
                                                this.state.selectedUserGroups.filter(
                                                    (
                                                        value: string | null
                                                    ): value is string =>
                                                        value != null
                                                )
                                            )
                                    )
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 5 }}
                            >
                                <Button
                                    title={"Add new group"}
                                    className="btn-small-like-select"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() =>
                                        this.setState(
                                            (state) => {
                                                let selectedUserGroups =
                                                    Array.from(
                                                        state.selectedUserGroups
                                                    );
                                                selectedUserGroups.splice(
                                                    index + 1,
                                                    0,
                                                    null
                                                );
                                                return {
                                                    selectedUserGroups:
                                                        selectedUserGroups,
                                                };
                                            },
                                            () =>
                                                this.props.onChange(
                                                    this.state.selectedUserGroups.filter(
                                                        (
                                                            value: string | null
                                                        ): value is string =>
                                                            value != null
                                                    )
                                                )
                                        )
                                    }
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>
                                <Button
                                    title={"Remove group"}
                                    className="btn-small-like-select"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() =>
                                        this.setState(
                                            (state) => {
                                                let selectedUserGroups =
                                                    Array.from(
                                                        state.selectedUserGroups
                                                    );
                                                selectedUserGroups.splice(
                                                    index,
                                                    1
                                                );
                                                if (
                                                    selectedUserGroups.length ===
                                                    0
                                                ) {
                                                    selectedUserGroups.push(
                                                        null
                                                    );
                                                }
                                                return {
                                                    selectedUserGroups:
                                                        selectedUserGroups,
                                                };
                                            },
                                            () =>
                                                this.props.onChange(
                                                    this.state.selectedUserGroups.filter(
                                                        (
                                                            value: string | null
                                                        ): value is string =>
                                                            value != null
                                                    )
                                                )
                                        )
                                    }
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
    }
);
export default UserGroupsSelector;
