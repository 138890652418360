import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";
import { UserType, UserTypeOptions } from "common/UserInfo";
import TermsOfServiceModal from "common/TermsOfServiceModal";
import {
    changeOrDeleteTosText,
    checkTosUploaded,
} from "common/TermsOfServiceModal/api";

interface State {
    status: UpdateStatus;
    errorMessage: string;
    viewTosFor: UserType | null;
    changedFor: Set<UserType>;
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            viewTosFor: null,
            changedFor: new Set<UserType>(),
        };
        this.performance = null;
    }

    public componentDidMount(): void {
        checkTosUploaded()
            .then((changedFor) => {
                this.setState({ changedFor: changedFor });
            })
            .catch((error) => {
                this.setState({
                    status: UpdateStatus.Error,
                    errorMessage: String(error),
                });
            });
    }

    public componentDidUpdate(): void {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    public uploadTosText(
        userType: UserType,
        event?: React.ChangeEvent<HTMLInputElement>
    ): void {
        const files = event?.target?.files ?? [];

        let file: File | undefined;

        if (files.length !== 0) {
            file = files[0];
        } else {
            file = undefined;
        }

        changeOrDeleteTosText(userType, file)
            .then(() => {
                this.setState((state) => {
                    let changedFor = new Set(state.changedFor);
                    if (file == null) {
                        changedFor.delete(userType);
                    } else {
                        changedFor.add(userType);
                    }
                    return {
                        status: UpdateStatus.Success,
                        errorMessage: "",
                        changedFor: changedFor,
                    };
                });
            })
            .catch((error) => {
                this.setState({
                    status: UpdateStatus.Error,
                    errorMessage: String(error),
                });
            });
    }

    public renderUploadButton(
        userType: UserType,
        buttonText: string
    ): JSX.Element {
        return (
            <label
                className="btn btn-lg btn-primary my-primary"
                style={{
                    marginLeft: 10,
                    width: 100,
                    height: 38,
                    paddingTop: 0,
                    paddingBottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <input
                    style={{ display: "none" }}
                    type="file"
                    accept=".md"
                    onChange={(event) => this.uploadTosText(userType, event)}
                />
                {buttonText}
            </label>
        );
    }

    public render(): JSX.Element {
        return (
            <div style={{ marginTop: 20 }} className="flex-simple-column">
                <span className="big-title-span">
                    Terms of Service for User Types
                </span>
                {UserTypeOptions.map((userTypeOption) => (
                    <div
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span className="regular-text" style={{ width: 250 }}>
                            {userTypeOption.label}
                        </span>
                        {this.state.changedFor.has(userTypeOption.value) ? (
                            <>
                                <Button
                                    type="button"
                                    className="btn btn-lg btn-primary my-primary"
                                    style={{
                                        marginLeft: 10,
                                        width: 100,
                                        height: 38,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            viewTosFor: userTypeOption.value,
                                        });
                                    }}
                                >
                                    View
                                </Button>
                                {this.renderUploadButton(
                                    userTypeOption.value,
                                    "Change"
                                )}
                                <Button
                                    type="button"
                                    className="btn btn-lg btn-primary my-primary"
                                    style={{
                                        marginLeft: 10,
                                        width: 100,
                                        height: 38,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                    onClick={() =>
                                        this.uploadTosText(userTypeOption.value)
                                    }
                                >
                                    Delete
                                </Button>
                            </>
                        ) : (
                            this.renderUploadButton(
                                userTypeOption.value,
                                "Upload"
                            )
                        )}
                    </div>
                ))}

                <UpdateStatusAlert
                    value={this.state.status}
                    onChange={(status) => {
                        this.setState({ status: status });
                    }}
                    errorMessage={this.state.errorMessage}
                />
                <TermsOfServiceModal
                    show={this.state.viewTosFor != null}
                    confirm={() => {}}
                    cancel={() => {
                        this.setState({ viewTosFor: null });
                    }}
                    viewFor={this.state.viewTosFor ?? undefined}
                />
            </div>
        );
    }
}

export { MainComponent };
export let requirePermission = "ChangeTosForUserTypes";
