import React, { Component, CSSProperties } from "react";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";

import { mainStyle } from "common/MainStyle";
import customSelectStyles from "common/SelectStyles";
import DataScopes from "common/DataScopes";

interface DataSet {
    label: string;
    value: number | string;
    accessType: AccessType;
}

interface Props {
    onSelectedDataSetsChanged: (
        selectedDataSets: DataSet[],
        change: { operation: "add_or_edit" | "delete"; index: number }
    ) => void;
    onSelectedDataSetChanged: (selectedDataSet: DataSet | null) => void;
    selectedDataSetsByOtherUsers?: DataSet[];
    selectedDataSets: DataSet[];
    selectedDataSet: DataSet | null;
    menuPortalEnabled?: boolean;
    className?: string;
    style?: CSSProperties;
    rowDirection?: boolean;
    createLocalCopyShown?: boolean;
    canEditShown?: boolean;
    addButton?: boolean;
    showSharedByOthers?: boolean;
    sharedByYouTitle?: string;
    dataSetOptions?: DataSet[];
}

enum AccessType {
    CanRead = 0,
    CanSubmit = 1,
    CreateLocalCopy = 2,
    CanEdit = 3,
}

interface Option {
    label: string;
    value: AccessType;
}

const optionMap: Readonly<{ [key in AccessType]: Option }> = {
    [AccessType.CanRead]: {
        label: "Can Read",
        value: AccessType.CanRead,
    },
    [AccessType.CanSubmit]: {
        label: "Can Submit Data",
        value: AccessType.CanSubmit,
    },
    [AccessType.CreateLocalCopy]: {
        label: "Create Local Copy",
        value: AccessType.CreateLocalCopy,
    },
    [AccessType.CanEdit]: {
        label: "Can Edit",
        value: AccessType.CanEdit,
    },
};

class DataSetsSelector extends Component<Props> {
    public render(): JSX.Element {
        let rowDirection = this.props.rowDirection ?? false;
        let dataSetIndices = new Set<number | string>(
            this.props.selectedDataSets.map((dataSet) => dataSet.value)
        );
        for (let dataSet of this.props.selectedDataSetsByOtherUsers ?? []) {
            dataSetIndices.add(dataSet.value);
        }

        const currentOptions: Option[] = [
            optionMap[AccessType.CanRead],
            optionMap[AccessType.CanSubmit],
        ];
        const currentOptionsSpecial: Option[] = [optionMap[AccessType.CanRead]];
        const currentOptionsReadOnly: Option[] = [
            optionMap[AccessType.CanRead],
        ];
        const currentOptionsReadAndSubmit: Option[] = [
            optionMap[AccessType.CanRead],
            optionMap[AccessType.CanSubmit],
        ];

        if (this.props.canEditShown) {
            currentOptions.push(optionMap[AccessType.CanEdit]);
        }
        if (this.props.createLocalCopyShown) {
            currentOptions.push(optionMap[AccessType.CreateLocalCopy]);
            currentOptionsReadOnly.push(optionMap[AccessType.CreateLocalCopy]);
            currentOptionsReadAndSubmit.push(
                optionMap[AccessType.CreateLocalCopy]
            );
        }

        let dataSetOptionsMap: { [key: number | string]: Option[] } = {};
        if (this.props.dataSetOptions != null) {
            for (let dataSet of this.props.dataSetOptions) {
                switch (dataSet.accessType) {
                    case AccessType.CanSubmit:
                        dataSetOptionsMap[
                            dataSet.value
                        ] = currentOptionsReadAndSubmit;
                        break;
                    case AccessType.CanEdit:
                        dataSetOptionsMap[dataSet.value] = currentOptions;
                        break;
                    default:
                        dataSetOptionsMap[
                            dataSet.value
                        ] = currentOptionsReadOnly;
                        break;
                }
            }
        }

        return (
            <div
                className={this.props.className}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "visible",
                    flex: 1,
                    ...this.props.style,
                }}
            >
                {this.props.showSharedByOthers && (
                    <>
                        <span
                            style={{
                                padding: "10px",
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                width: "16em",
                            }}
                            className="regular-text"
                        >
                            Shared by others
                        </span>
                        {(this.props.selectedDataSetsByOtherUsers == null ||
                            this.props.selectedDataSetsByOtherUsers.length ===
                                0) && (
                            <span
                                style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                }}
                                className="regular-text"
                            >
                                No data sets shared
                            </span>
                        )}
                    </>
                )}
                <div className="flex-simple-column">
                    {this.props.selectedDataSetsByOtherUsers != null &&
                        this.props.selectedDataSetsByOtherUsers.length !==
                            0 && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: !rowDirection
                                        ? "column"
                                        : "row",
                                    flexWrap: !rowDirection ? "nowrap" : "wrap",
                                }}
                            >
                                {this.props.selectedDataSetsByOtherUsers.map(
                                    (dataSet, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    padding: "10px",
                                                    color: mainStyle.getPropertyValue(
                                                        "--popup-primary-text-color"
                                                    ),
                                                    textAlign: "right",
                                                    width: "16em",
                                                }}
                                                className="regular-text"
                                            >
                                                {dataSet.label}
                                            </span>
                                            <div
                                                title="Allow uploading (submit button)"
                                                style={{
                                                    display: "flex",
                                                    marginLeft: 5,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Select
                                                    isDisabled
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    placeholder=""
                                                    styles={{
                                                        ...customSelectStyles,
                                                        container: (base) => ({
                                                            ...base,
                                                            height: "38px",
                                                            width: "16em",
                                                        }),
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 100000000,
                                                        }),
                                                        indicatorsContainer: (
                                                            base
                                                        ) => ({
                                                            ...base,
                                                            color:
                                                                "transparent",
                                                        }),
                                                    }}
                                                    options={
                                                        typeof dataSet?.value ===
                                                        "string"
                                                            ? currentOptionsSpecial
                                                            : dataSetOptionsMap[
                                                                  dataSet?.value
                                                              ] ??
                                                              currentOptions
                                                    }
                                                    value={
                                                        optionMap[
                                                            dataSet.accessType
                                                        ]
                                                    }
                                                    onChange={(option) => {
                                                        let selectedDataSets = Array.from(
                                                            this.props
                                                                .selectedDataSets
                                                        );
                                                        selectedDataSets[
                                                            index
                                                        ] = {
                                                            ...selectedDataSets[
                                                                index
                                                            ],
                                                            accessType: (option as Option)
                                                                .value,
                                                        };
                                                        this.props.onSelectedDataSetsChanged(
                                                            selectedDataSets,
                                                            {
                                                                operation:
                                                                    "add_or_edit",
                                                                index: index,
                                                            }
                                                        );
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                    menuPortalTarget={
                                                        this.props
                                                            .menuPortalEnabled
                                                            ? document.body
                                                            : undefined
                                                    }
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginLeft: "5px",
                                                    width: "19px",
                                                }}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    <span
                        style={{
                            padding: "10px",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                        }}
                        className="regular-text"
                    >
                        {this.props.sharedByYouTitle ?? "Shared by you"}
                    </span>
                    {this.props.selectedDataSets.length === 0 &&
                        this.props.selectedDataSet == null && (
                            <span
                                style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                }}
                                className="regular-text"
                            >
                                No data sets shared
                            </span>
                        )}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: !rowDirection ? "column" : "row",
                            flexWrap: !rowDirection ? "nowrap" : "wrap",
                        }}
                    >
                        {this.props.selectedDataSets.map((dataSet, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flex: 1,
                                    marginBottom: 5
                                }}
                            >
                                <span
                                    style={{
                                        padding: "10px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        textAlign: "right",
                                        flex: 1
                                    }}
                                    className="regular-text"
                                >
                                    {dataSet.label}
                                </span>
                                <div
                                    title="Allow uploading (submit button)"
                                    style={{
                                        display: "flex",
                                        marginLeft: 5,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder=""
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                height: "38px",
                                                width: "16em",
                                            }),
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 100000000,
                                            }),
                                        }}
                                        options={
                                            typeof dataSet?.value === "string"
                                                ? currentOptionsSpecial
                                                : dataSetOptionsMap[
                                                      dataSet?.value
                                                  ] ?? currentOptions
                                        }
                                        value={optionMap[dataSet.accessType]}
                                        onChange={(option) => {
                                            let selectedDataSets = Array.from(
                                                this.props.selectedDataSets
                                            );
                                            selectedDataSets[index] = {
                                                ...selectedDataSets[index],
                                                accessType: (option as Option)
                                                    .value,
                                            };
                                            this.props.onSelectedDataSetsChanged(
                                                selectedDataSets,
                                                {
                                                    operation: "add_or_edit",
                                                    index: index,
                                                }
                                            );
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                        menuPortalTarget={
                                            this.props.menuPortalEnabled
                                                ? document.body
                                                : undefined
                                        }
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginLeft: "5px",
                                        width: "44px",
                                    }}
                                >
                                    <Button
                                        className="close"
                                        onClick={() => {
                                            let selectedDataSets = Array.from(
                                                this.props.selectedDataSets
                                            );
                                            selectedDataSets.splice(index, 1);
                                            this.props.onSelectedDataSetsChanged(
                                                selectedDataSets,
                                                {
                                                    operation: "delete",
                                                    index: index,
                                                }
                                            );
                                        }}
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Select
                        isClearable={true}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={"Select Data Set"}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                height: "38px",
                                width: "16em",
                                flex: 1
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={
                            this.props.dataSetOptions
                                ?.filter(
                                    (dataSet) =>
                                        !dataSetIndices.has(dataSet.value)
                                )
                                ?.map((dataSet) => ({
                                    ...dataSet,
                                    accessType: AccessType.CanRead,
                                })) ??
                            DataScopes.dataScopesOptions
                                .filter(
                                    (dataSet) =>
                                        !dataSetIndices.has(dataSet.value)
                                )
                                .map((dataSet) => ({
                                    ...dataSet,
                                    accessType: AccessType.CanRead,
                                }))
                        }
                        value={this.props.selectedDataSet}
                        onChange={(newValue) => {
                            this.props.onSelectedDataSetChanged(
                                newValue as Props["selectedDataSet"]
                            );
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                        menuPortalTarget={
                            this.props.menuPortalEnabled
                                ? document.body
                                : undefined
                        }
                    />
                    <div
                        style={{
                            display: "flex",
                            marginLeft: 5,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder=""
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    height: "38px",
                                    width: "16em",
                                }),
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 100000000,
                                }),
                            }}
                            options={
                                typeof this.props.selectedDataSet?.value ===
                                "string"
                                    ? currentOptionsSpecial
                                    : this.props.selectedDataSet?.value == null
                                    ? []
                                    : dataSetOptionsMap[
                                          this.props.selectedDataSet.value
                                      ] ?? currentOptions
                            }
                            value={
                                this.props.selectedDataSet != null
                                    ? optionMap[
                                          this.props.selectedDataSet.accessType
                                      ]
                                    : null
                            }
                            onChange={(option) => {
                                if (this.props.selectedDataSet != null) {
                                    let selectedDataSet = {
                                        ...this.props.selectedDataSet!,
                                        accessType: (option as Option).value,
                                    };
                                    this.props.onSelectedDataSetChanged(
                                        selectedDataSet
                                    );
                                }
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                            menuPortalTarget={
                                this.props.menuPortalEnabled
                                    ? document.body
                                    : undefined
                            }
                        />
                    </div>
                    <div
                        className="flex-simple-column"
                        style={{
                            marginLeft: 5,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {!this.props.addButton && (
                            <Button
                                className="btn-small-like-select"
                                style={{
                                    width: "30px",
                                    height: "100%",
                                    background: 'linear-gradient(94.78deg, #1A60B3 0%, #13529B 100%)',
                                    color: "white",
                                    fontWeight: 'bold',
                                    fontSize: 18
                                }}
                                onClick={() => {
                                    if (this.props.selectedDataSet == null)
                                        return;
                                    this.props.onSelectedDataSetsChanged(
                                        [
                                            ...this.props.selectedDataSets,
                                            this.props.selectedDataSet,
                                        ],
                                        {
                                            operation: "add_or_edit",
                                            index: -1,
                                        }
                                    );
                                    this.props.onSelectedDataSetChanged(null);
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>
                        )}
                        {this.props.addButton && (
                            <Button
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    height: "100%",
                                    background: 'linear-gradient(94.78deg, #1A60B3 0%, #13529B 100%)',
                                }}
                                onClick={() => {
                                    if (this.props.selectedDataSet == null)
                                        return;
                                    this.props.onSelectedDataSetsChanged(
                                        [
                                            ...this.props.selectedDataSets,
                                            this.props.selectedDataSet,
                                        ],
                                        {
                                            operation: "add_or_edit",
                                            index: this.props.selectedDataSets
                                                .length,
                                        }
                                    );
                                    this.props.onSelectedDataSetChanged(null);
                                }}
                            >
                                Add
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default DataSetsSelector;
export { AccessType };
export type { DataSet };
