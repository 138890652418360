import React, { Component } from "react";
import { observer } from "mobx-react";
import { VariableSizeGrid as Grid } from "react-window";
import {
    CanvasSpreadSheetGrid,
    CanvasElement,
    SelectionArea,
    GridColorOptions,
} from "common/Canvas";
import CanvasTreeStore from "../CanvasTreeStore";
import { identifierFontSize } from "../Constants";

import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import "common/styles/rotate.css";
import DataScopes, {
    DataScopeOption,
    DataScopeOptionWithNull,
} from "common/DataScopes";
import { Permission } from "common/Permissions";
import DataScopesForModules from "common/DataScopesForModules";
import {
    initializeSchema,
    saveSimpleSpreadSheet,
    saveSimpleSpreadSheetChanges,
} from "./SpreadSheetStreamApi";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import remoteModuleId from "common/remoteModuleId";
import Select, { createFilter } from "react-select";
import { getSpreadSheetDropdownSelectStyle } from "./SpreadSheetElementDropdownStyle";
import { stopPropagation } from "common/utilities/EventFunctions";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import { Button } from "react-bootstrap";
import { ConditionsSelector, Condition } from "common/Conditions";
import { TableOption } from "common/Tables";
import { VariableOption } from "common/Variables";
import Portal from "common/Portal";
import MessagePopup from "common/MessagePopup";

interface Props {
    canvasTreeStore: CanvasTreeStore;
    hovered: boolean;
    currentModuleId?: number;
    rootDataTestId: string;
    colorOptions: GridColorOptions;
    selectionArea: SelectionArea | undefined;
    scale: number;
    spreadSheetGrid: CanvasSpreadSheetGrid;
    nodes: CanvasElement[];
    live: boolean;
    sharedPolicy: CanvasSharedPolicy;
    onChange: (props: Partial<CanvasSpreadSheetGrid>) => void;
    setShowStreamButtonsAdvancedOptions: () => void;
}

interface State {
    streamStatus: PopupStatus | null;
    streamMessage: string | null;
    initPullingIsActive: boolean;
    syncingIsActive: boolean;
    newDataScopeOption: DataScopeOptionWithNull | null;
    dataScopeOptionToSave: DataScopeOptionWithNull | null;
    showAdvancedOptions: boolean;
    datasetDropdownHovered: boolean;
    showRewriteDatasetWarning: boolean;
    switchedDataScopeOption: DataScopeOption | null;
}

@observer
class SpreadSheetElement extends Component<Props, State> {
    private datasetSelectorRef = React.createRef<any>();
    private gridRef = React.createRef<Grid>();
    private gridHeaderRef = React.createRef<Grid>();

    constructor(props: Props) {
        super(props);
        this.state = {
            streamMessage: null,
            streamStatus: null,
            initPullingIsActive: false,
            syncingIsActive: false,
            newDataScopeOption: null,
            dataScopeOptionToSave: null,
            showAdvancedOptions: false,
            datasetDropdownHovered: false,
            showRewriteDatasetWarning: false,
            switchedDataScopeOption: null,
        };
    }
    componentDidUpdate(prevProps: Props) {
        // Talk about this one
        let prevStream =
            prevProps.spreadSheetGrid.fullSpreadSheetBackendOutputOptions !=
            null;
        let currentStream =
            this.props.spreadSheetGrid.fullSpreadSheetBackendOutputOptions !=
            null;
        if (prevStream !== currentStream) {
            this.setState({ streamStatus: null, streamMessage: null });
        }
        if (!prevProps.live && this.props.live) {
            this.setState({ showAdvancedOptions: false });
        }

        if (this.props.scale !== prevProps.scale) {
            this.gridRef.current?.resetAfterIndices({
                rowIndex: 0,
                columnIndex: 0,
            });
            this.gridHeaderRef.current?.resetAfterIndices({
                rowIndex: 0,
                columnIndex: 0,
            });
        } else {
            if (
                this.props.spreadSheetGrid.leftHeadersEnabled !==
                    prevProps.spreadSheetGrid.leftHeadersEnabled ||
                this.props.spreadSheetGrid.cols !==
                    prevProps.spreadSheetGrid.cols
            ) {
                this.gridRef.current?.resetAfterColumnIndex(0);
                this.gridHeaderRef.current?.resetAfterColumnIndex(0);
            }
            if (
                this.props.spreadSheetGrid.rows !==
                prevProps.spreadSheetGrid.rows
            ) {
                this.gridRef.current?.resetAfterRowIndex(0);
            }
        }
    }

    private initData(
        dataScopeOption: DataScopeOption,
        tableOption: TableOption | null,
        conditions: Condition[],
        columnFilter: VariableOption[],
        limit: number,
        bottomRows: boolean
    ) {
        if (this.state.syncingIsActive || this.state.initPullingIsActive)
            return;
        this.setState(
            {
                initPullingIsActive: true,
                streamStatus: null,
                streamMessage: null,
            },
            () => {
                this.props.canvasTreeStore
                    .readDataIntoSpreadSheet(
                        this.props.spreadSheetGrid.id,
                        dataScopeOption.value,
                        tableOption ?? null,
                        limit,
                        true,
                        false,
                        conditions,
                        columnFilter,
                        bottomRows
                    )
                    .then(() => {
                        this.props.canvasTreeStore.updateAllAsyncAction.bothParts(
                            false,
                            this.props.canvasTreeStore.canvasTreeState
                        );

                        this.setState({
                            initPullingIsActive: false,

                            streamStatus: PopupStatus.Success,
                            streamMessage: `Data pulled from ${dataScopeOption.label} successfully`,
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            initPullingIsActive: false,
                            streamStatus: PopupStatus.Error,
                            streamMessage: String(error),
                        });
                    });
            }
        );
    }

    private pullData(
        spreadSheetGrid: CanvasSpreadSheetGrid,
        dataScopeOption: DataScopeOption,
        pullFrame: boolean,
        silent: boolean = false,
        updateFromDataSource: boolean = false
    ) {
        this.props.canvasTreeStore
            .readDataIntoSpreadSheet(
                spreadSheetGrid.id,
                dataScopeOption.value,
                spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                    .tableOption ?? null,
                spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.limit ??
                    10,
                true,
                pullFrame,
                spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                    .conditions ?? [],
                spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                    .variables ?? [],
                spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                    .bottomRows ?? false,
                undefined,
                updateFromDataSource
            )
            .then(() => {
                this.props.canvasTreeStore.updateAllAsyncAction.bothParts(
                    false,
                    this.props.canvasTreeStore.canvasTreeState
                );
                if (!silent) {
                    this.setState({
                        streamStatus: PopupStatus.Success,
                        streamMessage: pullFrame
                            ? `Data synced with ${dataScopeOption.label} successfully`
                            : `Data pulled from ${dataScopeOption.label} successfully`,
                        syncingIsActive: false,
                    });
                }
            })
            .catch((error) => {
                if (!silent) {
                    this.setState({
                        streamStatus: PopupStatus.Error,
                        streamMessage: String(error),
                        syncingIsActive: false,
                    });
                } else {
                    console.log(error);
                }
            });
    }
    private rewriteDataset() {
        let newDataScopeOption: DataScopeOption = {
            label: this.state.dataScopeOptionToSave!.label,
            value: this.state.dataScopeOptionToSave!.value ?? NaN,
            permissionType: this.state.dataScopeOptionToSave!.permissionType,
        };
        let schema = initializeSchema(
            this.props.spreadSheetGrid,
            this.props.nodes,
            this.props.canvasTreeStore
        );
        if (!schema.success) {
            this.setState({
                streamMessage: schema.errorMessage!,
                streamStatus: PopupStatus.Error,
            });
            return;
        }
        saveSimpleSpreadSheet(
            this.props.spreadSheetGrid,
            this.props.nodes,
            schema.names,
            schema.types,
            schema.panels,
            schema.levels,
            schema.formats,
            schema.units,
            newDataScopeOption,
            // Do not change permissions for existing data sets,
            // set them to user's personal group for new data sets.
            undefined,
            (dataTableIdx: string | number) => {
                this.props.canvasTreeStore
                    .readDataIntoSpreadSheet(
                        this.props.spreadSheetGrid.id,
                        dataTableIdx,
                        null,
                        this.props.spreadSheetGrid.rows,
                        true,
                        false,
                        undefined,
                        undefined,
                        undefined
                    )
                    .then(() => {
                        this.setState({
                            newDataScopeOption: null,
                            streamMessage: "Data Saved",
                            streamStatus: PopupStatus.Success,
                        });
                    })
                    .catch((errorMessage) => {
                        this.setState({
                            streamMessage: String(errorMessage),
                            streamStatus: PopupStatus.Error,
                        });
                    });
            },
            (errorMessage) => {
                this.setState({
                    streamMessage: errorMessage,
                    streamStatus: PopupStatus.Error,
                });
            },
            this.props.currentModuleId ?? remoteModuleId
        );
    }
    private buildSaveButton(
        // Talk about this one
        dataScopeOption: DataScopeOption | null | undefined
    ): JSX.Element | null {
        if (dataScopeOption != null) return null;
        if (!this.props.hovered) return null;
        if (this.state.dataScopeOptionToSave == null) return null;
        return (
            <Button
                data-test-id={`${this.props.rootDataTestId}-saveButton`}
                type="button"
                className="btn btn-sm btn-primary my-primary"
                style={{
                    width: "100px",
                    height: "28px",
                    marginTop: 5,
                    marginRight: 5,
                }}
                onClick={(_evt: React.MouseEvent<HTMLButtonElement>) => {
                    let showWarning =
                        this.state.dataScopeOptionToSave!.value != null;
                    if (!showWarning) {
                        this.rewriteDataset();
                    } else {
                        this.setState({ showRewriteDatasetWarning: true });
                    }
                }}
            >
                Save
            </Button>
        );
    }

    private syncProcess(dataScopeOption: DataScopeOption) {
        if (this.state.syncingIsActive) return;
        this.setState(
            {
                streamStatus: PopupStatus.Success,
                syncingIsActive: true,
                streamMessage: "Pushing changes...",
            },
            () => {
                saveSimpleSpreadSheetChanges(
                    _.cloneDeep(this.props.spreadSheetGrid),
                    this.props.nodes,
                    (spreadSheetGrid: CanvasSpreadSheetGrid) => {
                        // We do not save the changes here since we will call
                        // readDataIntoSpreadSheet later
                        // https://eisengardai.atlassian.net/browse/EIS-369?focusedCommentId=12719
                        this.props.canvasTreeStore.updateGridAction(
                            this.props.spreadSheetGrid.id,
                            spreadSheetGrid,
                            {} // Discard the changes
                        );
                        this.props.canvasTreeStore.updateAllAsyncAction.bothParts(
                            false,
                            this.props.canvasTreeStore.canvasTreeState
                        );
                        // We don't call pullData here because the reaction
                        // updateNodesByDatasetAction in CanvasTreeStore
                        // will call readDataIntoSpreadSheet
                    },
                    (
                        spreadSheetGrid: CanvasSpreadSheetGrid,
                        message: string
                    ) => {
                        // We do not save the changes here since we will call
                        // readDataIntoSpreadSheet later
                        // https://eisengardai.atlassian.net/browse/EIS-369?focusedCommentId=12719
                        this.props.canvasTreeStore.updateGridAction(
                            this.props.spreadSheetGrid.id,
                            spreadSheetGrid,
                            {} // Discard the changes
                        );
                        this.setState({
                            streamStatus: PopupStatus.Success,
                            streamMessage: message,
                        });
                    },
                    (errorMessage) => {
                        this.setState({
                            streamStatus: PopupStatus.Error,
                            streamMessage: errorMessage,
                            syncingIsActive: false,
                        });
                    },
                    this.props.currentModuleId ?? remoteModuleId
                );
            }
        );
    }

    private pullProcess(
        dataScopeOption: DataScopeOption,
        updateFromDataSource: boolean = false
    ) {
        if (this.state.syncingIsActive) return;
        this.setState(
            {
                streamStatus: PopupStatus.Success,
                syncingIsActive: true,
                streamMessage: "Pulling data...",
            },
            () => {
                this.pullData(
                    this.props.spreadSheetGrid,
                    dataScopeOption,
                    false,
                    false,
                    updateFromDataSource
                );
            }
        );
    }

    private buildStreamButtons(
        dataScopeOption: DataScopeOption | null | undefined
    ): JSX.Element | null {
        if (dataScopeOption == null) return null;
        // let buttonStyles = {};
        // if (this.props.live) {
        //     buttonStyles = {
        //         transition: "opacity .2s ease-out",
        //         opacity: this.props.hovered ? 1 : 0,
        //         pointerEvents: this.props.hovered ? "auto" : "none",
        //     };
        // }

        return (
            <div
                className="my-row"
                style={{
                    marginTop: this.props.live ? 0 : 5,
                }}
            >
                {(!this.props.live || this.props.spreadSheetGrid.unlocked) && (
                    <>
                        <Button
                            data-test-id={`${this.props.rootDataTestId}-advancedOptionsButton`}
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginRight: 5,
                                height: "28px",
                                border: "none",
                                backgroundColor: this.state.showAdvancedOptions
                                    ? "rgba(31, 142, 250, 0.2)"
                                    : "#F0F2F5",
                                color: this.state.showAdvancedOptions
                                    ? "#39F"
                                    : "#4A4047",
                            }}
                            onClick={(
                                _evt: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                this.props.setShowStreamButtonsAdvancedOptions();
                            }}
                        >
                            Advanced options
                        </Button>
                        <Button
                            data-test-id={`${this.props.rootDataTestId}-saveToCloudButton`}
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginRight: 5,
                                height: "28px",
                                border: "none",
                                backgroundColor: "#F0F2F5",
                                color: "#4A4047",
                            }}
                            onClick={(
                                _evt: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                this.syncProcess(dataScopeOption);
                            }}
                        >
                            Save to cloud
                        </Button>
                    </>
                )}
                <Button
                    data-test-id={`${this.props.rootDataTestId}-getLatestVersionButton`}
                    type="button"
                    className="btn btn-sm btn-primary my-primary"
                    style={{
                        marginRight: 5,
                        height: "28px",
                        border: "none",
                        backgroundColor: "#F0F2F5",
                        color: "#4A4047",
                    }}
                    onClick={(_evt: React.MouseEvent<HTMLButtonElement>) => {
                        this.pullProcess(dataScopeOption, true);
                    }}
                >
                    Get latest version
                </Button>
                {/*     <UpdateDataButton
                    onUpdateDataClick={() => {
                        this.syncProcess(dataScopeOption);
                    }}
                    onPullDataClick={() => {
                        this.pullProcess(dataScopeOption);
                    }}
                />*/}

                {/*<CanvasPreventPropagationButton>
                    <div
                        title="Sync"
                        style={{
                            marginRight: 5,
                            ...buttonStyles,
                        }}
                        onClick={(_evt) => {
                            this.syncProcess(dataScopeOption);
                        }}
                    >
                        <DataSyncArrow
                            pull
                            active={this.state.syncingIsActive}
                        />
                    </div>
                </CanvasPreventPropagationButton>*/}
            </div>
        );
    }

    private getHeaderHeight(): number {
        if (
            this.props.live &&
            (!this.props.spreadSheetGrid.unlocked ||
                this.props.spreadSheetGrid
                    .fullSpreadSheetBackendOutputOptions == null)
        )
            return 0;
        return (this.props.live ? 30 : 40) * this.props.scale;
    }

    private buildHeader(
        colorOptions: GridColorOptions,
        dataScopeOption: DataScopeOption | null | undefined
    ): JSX.Element | null {
        const dataScopes =
            this.props.currentModuleId == null
                ? DataScopes
                : DataScopesForModules(this.props.currentModuleId);
        let selectStyles = getSpreadSheetDropdownSelectStyle(
            12,
            this.state.datasetDropdownHovered
        );
        selectStyles = {
            ...selectStyles,
            container: (base) => ({
                ...base,
                marginLeft: "5px",
                width: "140px",
            }),
            menuPortal: (base) => ({
                ...base,
                zIndex: 100000000,
            }),
        };
        let dataScopesOptions: DataScopeOptionWithNull[] =
            dataScopes.dataScopesOptions;
        if (this.state.newDataScopeOption != null) {
            dataScopesOptions = dataScopesOptions.concat(
                this.state.newDataScopeOption
            );
        }
        let scaleMultiplier = 0.7;
        let scaleFactor = scaleMultiplier * this.props.scale;
        return (
            <div style={{ height: this.getHeaderHeight() }}>
                <div
                    style={{
                        height: this.getHeaderHeight() / scaleFactor,
                        transform: `scale(${scaleFactor})`,
                        transformOrigin: "left top",
                        width: `calc(100% / ${scaleFactor})`,
                        paddingLeft: 20,
                        display: "flex",
                        zIndex: 2,
                    }}
                >
                    {!this.props.live ? (
                        <>
                            <span
                                className="unselectable"
                                style={{
                                    marginTop: 5,
                                    fontWeight: 400,
                                    fontSize:
                                        identifierFontSize / scaleMultiplier,
                                    fontFamily: "Roboto",
                                    color: colorOptions.textColor,
                                }}
                            >
                                {this.props.spreadSheetGrid.index ?? ""}
                            </span>
                            {this.props.hovered ||
                            this.props.spreadSheetGrid.viewDataset ? (
                                <div
                                    style={{
                                        flexGrow: 1,
                                        marginTop: 5,
                                    }}
                                    onContextMenu={stopPropagation}
                                >
                                    <div
                                        data-test-id={`${this.props.rootDataTestId}-datasetSelector`}
                                        style={{ width: "140px" }}
                                        onMouseEnter={() => {
                                            this.setState({
                                                datasetDropdownHovered: true,
                                            });
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({
                                                datasetDropdownHovered: false,
                                            });
                                        }}
                                    >
                                        {this.props.spreadSheetGrid
                                            .viewDataset ||
                                        dataScopeOption != null ? (
                                            <Select
                                                onKeyDown={(evt) => {
                                                    evt.stopPropagation();
                                                }}
                                                ref={this.datasetSelectorRef}
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                menuPortalTarget={document.body}
                                                placeholder={"Select Dataset"}
                                                styles={selectStyles}
                                                options={
                                                    dataScopes.dataScopesOptions
                                                }
                                                onChange={(newValue) => {
                                                    this.setState({
                                                        datasetDropdownHovered: false,
                                                    });
                                                    if (
                                                        dataScopeOption == null
                                                    ) {
                                                        this.initData(
                                                            newValue as DataScopeOption,
                                                            null,
                                                            ConditionsSelector.defaultValue,
                                                            [],
                                                            10,
                                                            this.props
                                                                .spreadSheetGrid
                                                                .fullSpreadSheetBackendOutputOptions
                                                                ?.bottomRows ??
                                                                false
                                                        );
                                                    } else {
                                                        this.setState({
                                                            switchedDataScopeOption: newValue as DataScopeOption,
                                                        });
                                                    }
                                                }}
                                                value={dataScopeOption}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        ) : (
                                            <div
                                                onMouseEnter={() => {
                                                    this.setState({
                                                        datasetDropdownHovered: true,
                                                    });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        datasetDropdownHovered: false,
                                                    });
                                                }}
                                            >
                                                <CreatableSelect
                                                    data-test-id={`${this.props.rootDataTestId}-datasetSelector`}
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    onKeyDown={(evt) => {
                                                        evt.stopPropagation();
                                                    }}
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    placeholder={
                                                        "Select Dataset"
                                                    }
                                                    styles={selectStyles}
                                                    options={dataScopesOptions}
                                                    onChange={(newValue) => {
                                                        this.setState({
                                                            datasetDropdownHovered: false,
                                                        });
                                                        if (
                                                            dataScopeOption ==
                                                            null
                                                        ) {
                                                            this.initData(
                                                                newValue as DataScopeOption,
                                                                null,
                                                                ConditionsSelector.defaultValue,
                                                                [],
                                                                10,
                                                                this.props
                                                                    .spreadSheetGrid
                                                                    .fullSpreadSheetBackendOutputOptions
                                                                    ?.bottomRows ??
                                                                    false
                                                            );
                                                        } else {
                                                            this.setState({
                                                                switchedDataScopeOption: newValue as DataScopeOption,
                                                            });
                                                        }
                                                    }}
                                                    onCreateOption={(
                                                        option
                                                    ) => {
                                                        let newDataScope = {
                                                            label: option,
                                                            value: null,
                                                            permissionType:
                                                                Permission.ReadWrite,
                                                        };
                                                        this.setState({
                                                            datasetDropdownHovered: false,
                                                            newDataScopeOption: newDataScope,
                                                            dataScopeOptionToSave: newDataScope,
                                                        });
                                                    }}
                                                    value={
                                                        this.state
                                                            .dataScopeOptionToSave
                                                    }
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div style={{ flexGrow: 1 }} />
                            )}

                            {this.buildSaveButton(dataScopeOption)}
                        </>
                    ) : (
                        <div style={{ flexGrow: 1 }} />
                    )}
                    {this.buildStreamButtons(dataScopeOption)}
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        let streamingOff =
            this.props.spreadSheetGrid.fullSpreadSheetBackendOutputOptions
                ?.dataScopeId == null;
        let dataScopeOption = undefined;
        if (!streamingOff) {
            let dataScopes =
                this.props.currentModuleId == null
                    ? DataScopes
                    : DataScopesForModules(this.props.currentModuleId);
            dataScopeOption =
                dataScopes.dataScopesOptions.find(
                    (option) =>
                        option.value ===
                        this.props.spreadSheetGrid
                            .fullSpreadSheetBackendOutputOptions?.dataScopeId
                ) ?? null;
        }
        return (
            <>
                {this.buildHeader(this.props.colorOptions, dataScopeOption)}
                {this.state.showRewriteDatasetWarning && (
                    <Portal rootNode={document.body}>
                        <MessagePopup
                            title="Warning"
                            message={"Selected dataset will be overwritten"}
                            onAccept={() => {
                                this.setState({
                                    showRewriteDatasetWarning: false,
                                });
                                this.rewriteDataset();
                            }}
                            acceptButtonTitle="Save"
                            onReject={() => {
                                this.setState({
                                    showRewriteDatasetWarning: false,
                                });
                            }}
                        />
                    </Portal>
                )}
                {this.state.switchedDataScopeOption != null && (
                    <Portal rootNode={document.body}>
                        <MessagePopup
                            title="Warning"
                            message={
                                "Your unsaved data in current spreadsheet will be overwritten by this dataset"
                            }
                            onAccept={() => {
                                let switchedDataScope = {
                                    ...this.state.switchedDataScopeOption!,
                                };
                                this.setState({
                                    switchedDataScopeOption: null,
                                });
                                this.initData(
                                    switchedDataScope,
                                    null,
                                    ConditionsSelector.defaultValue,
                                    [],
                                    10,
                                    this.props.spreadSheetGrid
                                        .fullSpreadSheetBackendOutputOptions
                                        ?.bottomRows ?? false
                                );
                            }}
                            acceptButtonTitle="Continue"
                            onReject={() => {
                                this.setState({
                                    switchedDataScopeOption: null,
                                });
                            }}
                        />
                    </Portal>
                )}
                {this.state.streamStatus != null && (
                    <Portal rootNode={document.body}>
                        <StatusPopup
                            status={this.state.streamStatus}
                            message={this.state.streamMessage!}
                            onClose={() => {
                                this.setState({
                                    streamStatus: null,
                                    streamMessage: null,
                                });
                            }}
                        />
                    </Portal>
                )}
            </>
        );
    }
}

export default SpreadSheetElement;
