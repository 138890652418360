import { CSSProperties } from "react";

export const greyColor = "#f7f7f7";
export const blueColor = "#3b83c9";

export const chartColorPickerPopupStyles: CSSProperties = {
    border: "none",
    backgroundColor: "transparent",
    minWidth: 220,
    minHeight: 334,
    position: "absolute",
}