import React from "react";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import VariableCreator from "common/VariableCreator";
import customSelectStyles from "common/SelectStyles";
import axios from "common/ServerConnection";
import tables from "common/Tables";
import variables from "common/Variables";
import models from "common/Models";
import dataScopes from "common/DataScopes";
import processTab from "common/utilities/processTab";
import { Permission } from "common/Permissions";
import { stringSessionId } from "common/SessionId";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
                roeFunctionsExpanded: false,
                dataScope: undefined,
                roeCreatorTable: undefined,
                roeVariables: [{ name: null, unit: null, type: null }],
                roeModel: null,
                roeVariablesEnabled: [true],
                roeCreatorCode: "",
            };
            this.dataScopeValue = this.dataScopeValue.bind(this);
        }
        dataScopeValue() {
            if (this.state.dataScope) return this.state.dataScope.value;
            return undefined;
        }

        componentDidMount() {
            if (this.state.dataScope) this.getLastOptimizationFunctions();
        }

        getLastOptimizationFunctions() {
            axios
                .post(
                    "/api/get_last_optimization_functions",
                    {
                        data_table_idx: this.dataScopeValue(),
                    },
                    null
                )
                .then((response) => {
                    if (response.data.success === false) {
                        console.log(response.data.error_msg);
                    } else {
                        const roeVariables =
                            response.data.roe.roe_variables ||
                            variables(this.dataScopeValue()).roeVariables;
                        this.setState((state) => ({
                            roeCreatorCode: response.data.roe.code,
                            roeCreatorTable: tables(
                                this.dataScopeValue()
                            ).tableToOption(response.data.roe.table),
                            roeVariables: roeVariables,
                            roeVariablesEnabled: new Array(
                                roeVariables.length
                            ).fill(true),
                        }));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        createRoe() {
            if (!this.state.roeCreatorTable) {
                this.setStepState(StepState.error, "Table is not selected");
                return;
            }
            for (let roeVariable of this.state.roeVariables) {
                if (roeVariable.name.length === 0) {
                    this.setStepState(
                        StepState.error,
                        "Variable names can't be empty"
                    );
                    return;
                }
            }
            let jsonRequest = {
                table: this.state.roeCreatorTable.value,
                model_id: this.state.roeModel
                    ? this.state.roeModel.value
                    : undefined,
                data_table_idx: this.state.roeCreatorTable.data_table_idx,
                condition: this.state.roeCreatorTable.condition,
                condition_id: this.state.roeCreatorTable.condition_id,
                code: this.state.roeCreatorCode,
                roe_variables: this.state.roeVariables.filter(
                    (_variable, index) => this.state.roeVariablesEnabled[index]
                ),
                update_id: stringSessionId(),
            };
            this.setStepState(StepState.running);

            axios
                .post("/api/create_roe", jsonRequest)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        this.setStepState(StepState.finished);
                        variables(
                            this.state.roeCreatorTable.data_table_idx
                        ).update();
                    }
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        render() {
            let rendered = [
                <div key={0} className="circle-letter">
                    {this.props.index}
                </div>,
                <Button
                    key={1}
                    disabled={this.props.disabled}
                    className="btn-processing-tab"
                    style={{
                        height: "25px",
                    }}
                    onClick={() => {
                        this.setState((state) => ({
                            expanded: !state.expanded,
                        }));
                    }}
                >
                    <span className="content-regular-text">Create roe</span>
                    <span className="content-regular-text">
                        {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                    </span>
                </Button>,
                this.state.expanded ? (
                    <Button
                        key={2}
                        className="btn btn-lg btn-primary my-primary"
                        onClick={() => this.createRoe()}
                        disabled={this.state.stepState === StepState.running}
                        style={{
                            fontFamily: "Roboto",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            height: "25px",
                            width: "100%",
                            minWidth: "110px",
                        }}
                    >
                        CREATE
                    </Button>
                ) : (
                    <div key={2} style={{ minWidth: "110px" }} />
                ),
                <div key={3}>
                    {this.state.expanded && this.renderStepState()}
                </div>,
            ];
            if (this.state.expanded) {
                rendered = rendered.concat([
                    <div />,
                    <div
                        style={{
                            gridColumnStart: 2,
                            gridColumnEnd: 4,
                            background: "#3f4b62",
                            padding: "10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                }}
                            >
                                Select data
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={dataScopes.dataScopesOptions.filter(
                                    (option) =>
                                        option.permissionType ===
                                        Permission.ReadWrite
                                )}
                                onChange={(newValue) =>
                                    this.setState(
                                        {
                                            roeCreatorTable: null,
                                            dataScope: newValue,
                                        },
                                        () => {
                                            this.getLastOptimizationFunctions();
                                        }
                                    )
                                }
                                value={this.state.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                Using
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                    }),
                                }}
                                options={
                                    tables(this.dataScopeValue())
                                        .rawAndAggregateTableOptions
                                }
                                onChange={(newValue) => {
                                    this.setState({
                                        roeCreatorTable: newValue,
                                    });
                                }}
                                value={this.state.roeCreatorTable}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <span
                                className="content-regular-text"
                                style={{
                                    marginLeft: "10px",
                                    marginRight: "5px",
                                }}
                            >
                                and model
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "8em",
                                        height: "38px",
                                    }),
                                }}
                                options={models(this.dataScopeValue())
                                    .models.filter(
                                        (item) => item.type === "smf"
                                    )
                                    .map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                        info: item,
                                    }))}
                                onChange={(newValue) =>
                                    this.setState({
                                        roeModel: newValue,
                                    })
                                }
                                value={this.state.roeModel}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            {this.state.dataScope?.value != null &&
                                this.state.roeModel != null && (
                                    <span
                                        className="content-regular-text"
                                        style={{
                                            marginLeft: "10px",
                                            marginRight: "5px",
                                        }}
                                    >
                                        (outcome:{" "}
                                        {
                                            variables(
                                                this.state.dataScope.value
                                            ).variableNames[
                                                this.state.roeModel.info
                                                    .outcome_index
                                            ]
                                        }
                                        {this.state.roeModel.info.panel_index !=
                                            null &&
                                            `, panel: ${
                                                variables(
                                                    this.state.dataScope.value
                                                ).variableNames[
                                                    this.state.roeModel.info
                                                        .panel_index
                                                ]
                                            }`}
                                        )
                                    </span>
                                )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "10px",
                            }}
                        >
                            <Button
                                className="btn-processing-tab-blue"
                                style={{
                                    height: "25px",
                                    flex: 1,
                                }}
                                onClick={() => {
                                    this.setState((state) => ({
                                        roeFunctionsExpanded: !state.roeFunctionsExpanded,
                                    }));
                                }}
                            >
                                <span className="content-regular-text">
                                    Edit roe code
                                </span>
                                <span className="content-regular-text">
                                    {this.state.roeFunctionsExpanded
                                        ? "\uFF0D"
                                        : "\uFF0B"}
                                </span>
                            </Button>
                        </div>
                        {this.state.roeFunctionsExpanded && (
                            <div
                                style={{
                                    padding: "10px",
                                }}
                            >
                                <textarea
                                    className="like-select element"
                                    style={{
                                        minHeight: "460px",
                                        width: "100%",
                                        fontFamily: "monospace",
                                        resize: "vertical",
                                        whiteSpace: "pre",
                                        marginBottom: "10px",
                                    }}
                                    spellCheck={false}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        this.setState({
                                            roeCreatorCode: value,
                                        });
                                    }}
                                    ref="roeCreatorTextArea"
                                    onKeyDown={(e) =>
                                        processTab(
                                            e,
                                            (
                                                newSelectionStart,
                                                newSelectionEnd,
                                                newValue
                                            ) => {
                                                this.setState(
                                                    {
                                                        roeCreatorCode: newValue,
                                                    },
                                                    () => {
                                                        this.refs.roeCreatorTextArea.selectionStart = newSelectionStart;
                                                        this.refs.roeCreatorTextArea.selectionEnd = newSelectionEnd;
                                                    }
                                                );
                                            }
                                        )
                                    }
                                    value={this.state.roeCreatorCode}
                                />
                            </div>
                        )}
                        <VariableCreator
                            value={this.state.roeVariables}
                            onChange={(newVariables) =>
                                this.setState({ roeVariables: newVariables })
                            }
                            enabled={this.state.roeVariablesEnabled}
                            onEnabled={(enabled) => {
                                this.setState({
                                    roeVariablesEnabled: enabled,
                                });
                            }}
                        />
                    </div>,
                    <div />,
                ]);
            }

            return rendered;
        }
    }
);

export { MainComponent };
export let requirePermission = "CreateROE";