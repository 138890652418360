import React from "react";
import { observer } from "mobx-react";
import { Button } from "react-bootstrap";
import { AppModuleProps } from "AppModule";
import ScaledPage from "common/ScaledPage";
import { ReactComponent as WizardIcon } from "icons/guidance_wizard/wizard.svg";
import styles from "./main.module.css";
import ConnectSession from "./components/ConnectSession";
import EditSession from "./components/EditSession";
import DeploySession from "./components/DeploySession";
import cx from "classnames";
import ShareSession from "./components/ShareSession";
import HomeSession from "./components/HomeSession";

export enum Sessions {
    Connect,
    Edit,
    Deploy,
    Home,
}

const MainComponent = observer((props: AppModuleProps) => {
    const [currentSession, setCurrentSession] = React.useState(Sessions.Home);
    // This is a sample entryId. The real ID should be set by the "Connect" section
    const [entryId, setEntryId] = React.useState<number | undefined>(undefined);
    const [preview, setPreview] = React.useState<boolean>(false);

    const urlParams = new URLSearchParams(window.location.search);
    let sharedEntryId = urlParams.get("entry_id");

    if (sharedEntryId != null && !Number.isNaN(Number(sharedEntryId))) {
        return (
            <ScaledPage>
                <div className="content-wrapper hide-scroll">
                    <ShareSession entryId={Number(sharedEntryId)} />
                </div>
            </ScaledPage>
        );
    } else {
        const navigate = (page: Sessions) => {
            setCurrentSession(page);
        };
        return (
            <ScaledPage>
                <div className="content-wrapper hide-scroll">
                    <div className={styles.header}>
                        <div className={styles.homeOption}>
                            <p
                                className={cx(styles.option, {
                                    [styles.active]:
                                        currentSession === Sessions.Home,
                                })}
                                onClick={() => {
                                    navigate(Sessions.Home);
                                }}
                            >
                                Home
                            </p>
                        </div>
                        {preview && (
                            <p className={styles.previewModeTitle}>
                                You are in Preview Mode
                            </p>
                        )}
                        {!preview && currentSession !== Sessions.Home && (
                            <>
                                <div className={styles.optionWrapper}>
                                    <p
                                        className={cx(styles.option, {
                                            [styles.active]:
                                                currentSession ===
                                                Sessions.Connect,
                                        })}
                                        onClick={() => {
                                            navigate(Sessions.Connect);
                                            setPreview(false);
                                        }}
                                    >
                                        Connect
                                    </p>
                                </div>
                                <div className={styles.optionWrapper}>
                                    <p
                                        className={cx(styles.option, {
                                            [styles.active]:
                                                currentSession ===
                                                Sessions.Edit,
                                        })}
                                        onClick={() => {
                                            navigate(Sessions.Edit);
                                            setPreview(false);
                                        }}
                                    >
                                        Edit
                                    </p>
                                </div>
                                <div className={styles.optionWrapper}>
                                    <p
                                        className={cx(styles.option, {
                                            [styles.active]:
                                                currentSession ===
                                                Sessions.Deploy,
                                        })}
                                        onClick={() => {
                                            navigate(Sessions.Deploy);
                                            setPreview(false);
                                        }}
                                    >
                                        Deploy
                                    </p>
                                </div>
                            </>
                        )}
                        {currentSession === Sessions.Home && (
                            <div className={styles.buttonContainer}>
                                <Button
                                    type="button"
                                    className={cx("btn", styles.nextButton)}
                                    onClick={() => {
                                        setEntryId(undefined);
                                        setPreview(false);
                                        setCurrentSession(Sessions.Connect);
                                    }}
                                >
                                    Create New
                                </Button>
                            </div>
                        )}
                        {currentSession === Sessions.Connect && (
                            <div className={styles.buttonContainer}>
                                <Button
                                    disabled={entryId == null}
                                    type="button"
                                    className={cx("btn", styles.nextButton)}
                                    onClick={() => {
                                        setCurrentSession(Sessions.Edit);
                                        setPreview(false);
                                    }}
                                >
                                    Next
                                </Button>
                            </div>
                        )}
                        {currentSession === Sessions.Edit && (
                            <div className={styles.buttonContainer}>
                                <Button
                                    type="button"
                                    className={cx("btn", styles.previewButton)}
                                    onClick={() => {
                                        setPreview((preview) => !preview);
                                    }}
                                >
                                    {preview ? "Edit" : "Preview"}
                                </Button>
                                <Button
                                    type="button"
                                    className={cx("btn", styles.nextButton)}
                                    onClick={() => {
                                        setCurrentSession(Sessions.Deploy);
                                        setPreview(false);
                                    }}
                                >
                                    Next
                                </Button>
                            </div>
                        )}
                    </div>
                    <section>
                        {currentSession === Sessions.Connect && (
                            <ConnectSession setEntryId={setEntryId} />
                        )}
                        {currentSession === Sessions.Edit && (
                            <EditSession entryId={entryId} preview={preview} />
                        )}
                        {currentSession === Sessions.Deploy && (
                            <DeploySession entryId={entryId} />
                        )}
                        {currentSession === Sessions.Home && (
                            <HomeSession
                                onEditClicked={(entryId) => {
                                    setEntryId(entryId);
                                    navigate(Sessions.Edit);
                                }}
                                onShareClicked={(entryId) => {
                                    setEntryId(entryId);
                                    navigate(Sessions.Deploy);
                                }}
                            />
                        )}
                    </section>
                </div>
            </ScaledPage>
        );
    }
});

export { MainComponent };
export let route: string = "/wizard.html",
    section = "Wizard",
    leftPanelItem = {
        section: "Wizard",
        href: "wizard.html",
        icon: <WizardIcon className={styles.icon} />,
    },
    requirePermission: string = "Wizard";
