import React from "react";
import ReactDOM from "react-dom";
import domtoimage from "dom-to-image";
import FindingItem from "common/insights_components/FindingItem";
import Hermite_class from "hermite-resize";
import { mainStyle } from "common/MainStyle";
import delay from "./delay";


export function renderIcon(ref, finding, wait = 500, imageBlob = undefined) {
    let virtualDom = (
        <div
            style={{
                width: 800,
                height: 400,
            }}
        >
            <FindingItem
                caller="renderIcon"
                finding={finding}
                imageBlob={imageBlob}
            />
        </div>
    );
    const el = document.createElement("div");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    ReactDOM.render(virtualDom, el);
    ref.current.appendChild(el);
    return delay(wait).then(() => {
        return Promise.resolve(
            domtoimage
                .toPng(el.firstChild)
                .then((dataUrl) => {
                    ref.current.removeChild(el);
                    return Promise.resolve(dataUrl);
                })
                .catch((error) => {
                    ref.current.removeChild(el);
                    return Promise.reject(error);
                })
        );
    });
}

export function renderEmptyCanvasThumbnail() {
    const canvas = document.createElement("canvas");
    try {
        canvas.width = 144;
        canvas.height = 84;
        const ctx = canvas.getContext("2d");
        ctx.beginPath();
        ctx.fillStyle = mainStyle.getPropertyValue("--slide-background-color");
        ctx.fillRect(0, 0, 144, 84);

        return canvas.toDataURL();
    } finally {
        canvas.remove();
    }
}

export function renderHtmlRef(ref, x, y, width, height, asThumbnail = false, generateBothData = false) {
    return Promise.resolve(
        domtoimage
            .toPixelData(ref.current)
            .then((pixels) => {
                let node = ref.current;
                let canvas = document.createElement("canvas");
                canvas.width = node.scrollWidth;
                canvas.height = node.scrollHeight;
                const ctx = canvas.getContext("2d");

                var imgData = ctx.createImageData(
                    node.scrollWidth,
                    node.scrollHeight
                );
                for (let i = 0; i < imgData.data.length; i += 1) {
                    imgData.data[i] = pixels[i];
                }
                ctx.putImageData(imgData, 0, 0);
                imgData = ctx.getImageData(x, y, width, height);
                canvas.width = width;
                canvas.height = height;
                ctx.putImageData(imgData, 0, 0);

                if (generateBothData) {
                    return new Promise((resolve, reject) => {
                        var HERMITE = new Hermite_class();
                        HERMITE.resample(canvas, 432, 252, true, () => {
                            canvas.toBlob(blob => {
                                resolve({
                                    image: blob,
                                    thumbnail: canvas.toDataURL(),
                                });
                            })
                        });
                    });
                }

                if (asThumbnail) {
                    return new Promise((resolve, reject) => {
                        var HERMITE = new Hermite_class();
                        HERMITE.resample(canvas, 432, 252, true, () => {
                            resolve(canvas.toDataURL());
                        });
                    });
                }

                return new Promise((resolve, reject) => {
                    canvas.toBlob((blob) => {
                        resolve(blob);
                    });
                });
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    );
}
