import React from "react";
import CreatableSelect from "react-select/creatable";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import Variables from "common/Variables";
import StringOption from "common/StringOption";
import { Button } from "react-bootstrap";
import {
    Panel,
    Type,
} from "common/InputData";

export interface RenameVariable {
    name: string | null;
    index: number | null;
    new_name: string | null;
    unit: string | null;
    type?: Type | string | null;
    panel?: Panel | null;
    level?: string | null;
    format?: string | null;
    decimal?: number;                                                                           
}

interface Props {
    dataScopeId: number | string | undefined;
    renameVariables: RenameVariable[];
    onChange: (renameVariables: RenameVariable[]) => void;
}

export default function VariableEditor(props: Props) {
    return (
        <>
            {props.renameVariables.map((renameVariable, index) => (
                <div className="my-row" key={index} style={{ marginTop: 5 }}>
                    <div style={{ width: 100, marginLeft: 5 }}>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"name"}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    height: "38px",
                                    marginRight: "5px",
                                }),
                            }}
                            options={Variables(
                                props.dataScopeId
                            ).variablesForRenaming.map((option) => ({
                                label: option,
                                value: option,
                            }))}
                            onChange={(newValue) => {
                                let renameVariables = Array.from(
                                    props.renameVariables
                                );
                                renameVariables[
                                    index
                                ].name = (newValue as StringOption).value;
                                renameVariables[
                                    index
                                ].new_name = (newValue as StringOption).value;
                                let dataVar = Variables(
                                    props.dataScopeId
                                ).dataVariables.filter(
                                    (item) =>
                                        item.name ===
                                        (newValue as StringOption).value
                                );
                                if (dataVar.length > 0) {
                                    renameVariables[index].unit =
                                        dataVar[0].unit;
                                }

                                props.onChange(renameVariables);
                            }}
                            value={
                                renameVariable.name
                                    ? {
                                        label: renameVariable.name,
                                        value: renameVariable.name,
                                    }
                                    : null
                            }
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25: "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>

                    <input
                        className="like-select"
                        style={{
                            width: 100,
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            height: "25px",
                            fontFamily: "monospace",
                            textAlign: "left",
                            marginLeft: "4px",
                            marginRight: "4px",
                        }}
                        placeholder="new name"
                        onChange={(e) => {
                            let newVariables = Array.from(
                                props.renameVariables
                            );
                            newVariables[index].new_name = e.target.value;
                            props.onChange(newVariables);
                        }}
                        value={renameVariable.new_name || ""}
                    />
                    <div style={{ width: 100, marginLeft: 5 }}>
                        <CreatableSelect
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"unit"}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    height: "38px",
                                    marginRight: "5px",
                                }),
                            }}
                            options={Variables(
                                props.dataScopeId
                            ).columnUnitOptions.map((option) => ({
                                label: option.length === 0 ? "None" : option,
                                value: option,
                            }))}
                            onChange={(newValue) => {
                                let renameVariables = Array.from(
                                    props.renameVariables
                                );
                                renameVariables[
                                    index
                                ].unit = (newValue as StringOption).value;
                                props.onChange(renameVariables);
                            }}
                            value={
                                renameVariable.unit
                                    ? {
                                        label: renameVariable.unit,
                                        value: renameVariable.unit,
                                    }
                                    : null
                            }
                            onCreateOption={(option) => {
                                Variables(
                                    props.dataScopeId
                                ).setColumnUnitOptions([
                                    ...Variables(props.dataScopeId)
                                        .columnUnitOptions,
                                    option,
                                ]);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25: "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                    <div
                        className="flex-simple-column"
                        style={{ marginLeft: 5 }}
                    >
                        <Button
                            className="btn-small-like-select"
                            style={{
                                width: "19px",
                                height: "19px",
                            }}
                            onClick={() => {
                                let newVariables = Array.from(
                                    props.renameVariables
                                );
                                newVariables.push({
                                    name: null,
                                    index: null,
                                    unit: null,
                                    new_name: null,
                                });
                                props.onChange(newVariables);
                            }}
                        >
                            {"\uFF0B" /* plus */}
                        </Button>
                        <Button
                            className="btn-small-like-select"
                            style={{
                                width: "19px",
                                height: "19px",
                            }}
                            onClick={() => {
                                let newVariables = Array.from(
                                    props.renameVariables
                                );
                                newVariables.splice(index, 1);
                                if (newVariables.length === 0)
                                    newVariables.push({
                                        name: null,
                                        index: null,
                                        unit: null,
                                        new_name: null,
                                    });
                                props.onChange(newVariables);
                            }}
                        >
                            {"\uFF0D" /* minus */}
                        </Button>
                    </div>
                </div>
            ))}
        </>
    );
}
