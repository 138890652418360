import React from "react";
import { UserGroupOption } from "common/UserGroupsStore";
import { getGroupIdByUsernameOrEmail } from "common/UserGroupsApi"
import Select, { createFilter } from "react-select";
import { customSelectStylesForSharePopupNoBorder } from "common/SelectStyles";
import customSelectStyles from "common/SelectStyles";
import {
    permissionOptions,
    Permission,
    PermissionNames,
    PermissionOptionType,
} from "common/Permissions";
import { Button } from "react-bootstrap";

const MakeOwner = -2;

interface Props {
    options: UserGroupOption[];
    newGroupInput: string;
    selectedNewGroup: UserGroupOption | null;
    onChange: (change: {
        newGroupInput?: string;
        selectedNewGroup?: UserGroupOption | null;
        selectedPermission?: Permission | typeof MakeOwner;
    }) => void;
    onInviteUser: () => void;
    showPermissions: boolean;
    selectedPermission?: Permission | typeof MakeOwner;
    showMakeOwner?: boolean;
}

const UserInviter = (props: Props) => {
    let [newGroupFocused, setNewGroupsFocused] = React.useState(false);

    let permissionOptionsEx = Array.from(permissionOptions);
    if (props.showMakeOwner) {
        permissionOptionsEx = permissionOptionsEx.concat({
            label: "Owner",
            value: MakeOwner,
        });
    }

    return (
        <div
            className="my-row"
            style={{ marginTop: "17px", alignItems: "center" }}
        >
            <div
                className="my-row"
                style={{
                    border: newGroupFocused
                        ? "1px solid #1F8EFA"
                        : "1px solid transparent",
                    width: "100%",
                    backgroundColor: "var(--selectors-background-color)",
                    borderRadius: 4,
                    height: 40
                }}
            >
                <Select
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                    placeholder={
                        "Type name, email, or groups comma separated ..."
                    }
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    onFocus={() => {
                        setNewGroupsFocused(true);
                    }}
                    onInputChange={(input, { action }) => {
                        if (action === "input-change")
                            props.onChange({
                                selectedNewGroup: null,
                                newGroupInput: input,
                            });
                    }}
                    onBlur={async (input) => {
                        if(props.selectedNewGroup===null){
                            const group_id = await getGroupIdByUsernameOrEmail(props.newGroupInput);
                            if(group_id){
                                props.onChange({
                                    selectedNewGroup: {
                                        'id': group_id,
                                        'label': props.newGroupInput,
                                        'value': props.newGroupInput
                                    }
                                })
                            }
                        }
                        setNewGroupsFocused(false);
                    }}
                    styles={{
                        ...customSelectStylesForSharePopupNoBorder,
                        container: (base) => ({
                            ...base,
                            flexGrow: 1,
                            height: "32px",
                        }),
                        dropdownIndicator: (_base) => ({
                            display: "none",
                        }),
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 100000000,
                        }),
                        control: (base) => ({
                            ...base,
                            height: 38,
                            border: newGroupFocused ? 'none' : undefined,
                            borderRight: 'none',
                            '&:hover': {
                                border: '1px 0 1px 1px solid #ccc'
                            }
                        })
                    }}
                    options={props.options}
                    value={
                        props.selectedNewGroup ??
                        (props.newGroupInput
                            ? {
                                  label: props.newGroupInput,
                                  value: null,
                              }
                            : null)
                    }
                    onChange={(newValue) => {
                        props.onChange({
                            newGroupInput: "",
                            selectedNewGroup: newValue as UserGroupOption,
                        });
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                    })}
                />
                {props.showPermissions && (
                    <Select
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        placeholder={"Enter permissions"}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        onFocus={() => {
                            setNewGroupsFocused(true);
                        }}
                        onBlur={() => {
                            setNewGroupsFocused(false);
                        }}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                height: "32px",
                                width: "140px",
                                textAlign: 'right',
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                            control: (base) => ({
                                ...base,
                                height: 38,
                                border: newGroupFocused ? 'none' : undefined,
                                borderLeft: 'none',
                                '&:hover': {
                                    border: '1px 1px 1px 0 solid #ccc'
                                }
                            })
                        }}
                        options={permissionOptionsEx}
                        value={{
                            value: props.selectedPermission,
                            label:
                                props.selectedPermission === MakeOwner
                                    ? "Owner"
                                    : PermissionNames[
                                          props.selectedPermission!
                                      ],
                        }}
                        onChange={(newValue) => {
                            let permissionType = (newValue as PermissionOptionType)
                                .value as Permission;
                            props.onChange({
                                selectedPermission: permissionType,
                            });
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                        })}
                    />
                )}
            </div>
            <Button
                type="button"
                className="btn btn-sm btn-primary my-primary"
                style={{
                    marginLeft: 10,
                    width: "112px",
                    height: "38px",
                    background: 'linear-gradient(94.78deg, #1A60B3 0%, #13529B 100%)',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontSize: 16,
                    padding: "0 10px"
                }}
                onClick={props.onInviteUser}
            >
                Send invite
            </Button>
        </div>
    );
};

export default UserInviter;
