import axios from "./ServerConnection";
import { PaymentType } from "common/mystripe_components/ProductParametersInput";

export interface PaywallInfo {
    title: string;
    client: string;
    description: string;
    payment_type: PaymentType;
    price: number;
    logo: string | null;
}

export async function getModulePaywallApi(
    moduleId: number
): Promise<PaywallInfo | null> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            paywall: PaywallInfo | null;
        }>("/api/module_shared_paywall_get", {
            id: moduleId,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.paywall);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function removeModulePaywallApi(moduleId: number): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            paywall: PaywallInfo | null;
        }>("/api/module_shared_paywall_remove", {
            id: moduleId,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function addOrEditModulePaywallApi(
    moduleId: number,
    paywallInfo: PaywallInfo
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_shared_paywall_add_or_edit", {
            id: moduleId,
            ...paywallInfo,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function addSelfSignupLinkToModuleApi(
    moduleId: number,
    options: string | undefined | null,
    logo: string | undefined | null
): Promise<string> {
    let jsonRequest = {
        shared_module_id: moduleId,
        options: options,
        logo: logo
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            self_signup_link_id?: string;
        }>("/api/module_shared_self_signup_add", jsonRequest)
        .then((response) => {
            if (
                response.data.success &&
                response.data.self_signup_link_id != null
            ) {
                return Promise.resolve(response.data.self_signup_link_id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function getSelfSignupLinkToModuleApi(moduleId: number): Promise<{
    self_signup_link_id: string;
    options: string | null;
    logo: string | null;
}> {
    let jsonRequest = {
        shared_module_id: moduleId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            self_signup_link_id?: string;
            options?: string | null;
            logo?: string | null;
        }>("/api/module_shared_self_signup_get", jsonRequest)
        .then((response) => {
            if (
                response.data.success &&
                response.data.self_signup_link_id != null
            ) {
                return Promise.resolve({
                    self_signup_link_id: response.data.self_signup_link_id!,
                    logo: response.data.logo!,
                    options: response.data.options!,
                });
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function removeSelfSignupLinkFromModuleApi(
    moduleId: number
): Promise<void> {
    let jsonRequest = {
        shared_module_id: moduleId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_shared_self_signup_remove", jsonRequest)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}
