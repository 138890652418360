import React, { Component } from "react";
import { mainStyle } from "common/MainStyle";
import Instrumentation from "common/Instrumentation";
import { BriefModule } from "common/Module";
import ImageIFrame from "common/graphics/ImageIFrame";
import { Button } from "react-bootstrap";
import { loadModuleApi } from "common/ModulesApi";
import Alert from "common/Alert";
import { goToInternalLink } from "common/InternalLinksHelper";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import Presentation, { PresentationType } from "state/models/Presentation";
import Avatar from "common/Avatar";
import ManageModuleCard from "../ManageModuleCard";
import sections from "sections.json";
import styles from "./ModuleCard.module.css";

interface Props {
    sectionName: keyof typeof sections;
    parentContainerHeight: number;
    parentContainerWidth: number;
    module: Presentation;
    doReload?: () => void;
    reload?: boolean;
}

interface State {
    showManageModulePopup: boolean;
    errorMessage: string | undefined;
}

class ModuleCard extends Component<Props, State> {
    performance: Date | null;
    constructor(props: Props) {
        super(props);
        this.state = {
            showManageModulePopup: false,
            errorMessage: undefined,
        };
        this.performance = null;

        this.renderContents = this.renderContents.bind(this);
    }

    componentDidUpdate() {
        this.buildModuleRow()
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction(this.props.sectionName, timeMs);
        }
    }

    showMultilineText(text: string) {
        return (
            <>
                {text.split("\n").map((line, index) => (
                    <span
                        key={index}
                        style={{
                            textAlign: "left",
                            marginTop: 10,
                            marginLeft: 10,
                            wordBreak: "break-word",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: 12,
                            display: "block",
                        }}
                    >
                        {line}
                    </span>
                ))}
            </>
        );
    }
    downloadExportedFile(text: string, module: BriefModule) {
        const element = document.createElement("a");
        const file = new Blob([text], {
            type: "text/plain;charset=utf-8",
        });
        element.href = URL.createObjectURL(file);
        element.download = `${module.title}.json`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    buildModuleRow() {
        let id = this.props.module.id;
        return (
            <div
                className={styles.moduleCardIframe}
                style={{
                    marginTop: 20,
                    width: "100%",
                    height: 0.6 * this.props.parentContainerHeight,
                }}
            >
                <ImageIFrame doReload={this.props.reload ?? false} src={`/modules_shared/${id}/image`} />
            </div>
        );
    }

    buildStepTitle() {
        return this.props.module.title;
    }

    private buildContent(): JSX.Element {
        return (
            <div
                className={`my-row ${styles.tutorialInfoContainer}`}
                style={{
                    width: "100%",
                    height: "100%",
                    marginTop: 20,
                }}
            >
                <div
                    style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        width: "75%",
                        height: "100%",
                        minHeight: 0,
                    }}
                >
                    {this.buildModuleRow()}
                </div>
                <div
                    className={`flex-simple-column ${styles.tutorialInfoContainerText}`}
                    style={{
                        width: "25%",
                        paddingRight: 30,
                        height: 0.6 * this.props.parentContainerHeight + 20,
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{
                            width: "100%",
                            height: "50%",
                            overflow: "auto",
                        }}
                    >
                        <span
                            style={{
                                textAlign: "left",
                                color: mainStyle.getPropertyValue(
                                    "--popup-secondary-text-color"
                                ),
                                fontFamily: "Roboto",
                                fontSize: 17,
                                fontWeight: 700,
                                marginTop: 10,
                            }}
                        >
                            Description
                        </span>
                        <div>
                            {this.showMultilineText(
                                this.props.module.description ?? ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    loadModule() {
        if (this.props.module.isTutorial()) {
            goToInternalLink(
                `/tutorial.html?tutorial_id=${this.props.module.id}`
            );
        }
        loadModuleApi(this.props.module.id as number)
            .then((data) => {
                let actionRequired = data.action_required;
                if (actionRequired === "payment") {
                    goToInternalLink(
                        `/shared_module.html?shared_module_id=${this.props.module.id}`
                    );
                } else if (actionRequired === "attach_payment_method") {
                    goToInternalLink(
                        `/attach_and_subscribe.html?shared_module_id=${this.props.module.id}`
                    );
                } else if (data?.redirect_url != null) {
                    window.location.href = data.redirect_url;
                } else {
                    goToInternalLink(
                        `/canvas.html?current_module_id=${data.id}`
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    errorMessage: String(error),
                });
            });
    }

    private buildFooter(globalContext: GlobalContextContents): JSX.Element {
        return (
            <div
                style={{
                    display: "flex",
                    marginRight: "10px",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginBottom: 14,
                }}
            >
                <div style={{ display: "flex" }}>
                    {this.state.errorMessage != null && (
                        <Alert
                            text={this.state.errorMessage}
                            className="alert alert-danger alert-dismissible"
                            onClosed={() => {
                                this.setState({
                                    errorMessage: undefined,
                                });
                            }}
                            style={{
                                alignSelf: "flex-end",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                marginBottom: "0px",
                                marginRight: "20px",
                            }}
                        />
                    )}
                    {(!this.props.module.isShared ||
                        (this.props.module.type === PresentationType.Template &&
                            "ModifyAnyModule" in
                                globalContext.permissions)) && (
                        <Button
                            type="button"
                            style={{ width: 100, marginRight: "20px" }}
                            className="btn btn-md btn-primary my-primary"
                            onClick={() => {
                                this.setState({
                                    showManageModulePopup: true,
                                });
                            }}
                        >
                            {"MANAGE"}
                        </Button>
                    )}
                    <Button
                        type="button"
                        style={{ width: 100, marginRight: "20px" }}
                        className="btn btn-md btn-primary my-primary"
                        onClick={() => {
                            this.loadModule();
                        }}
                    >
                        {"LOAD"}
                    </Button>
                </div>
                {this.props.module.owner && (
                    <Avatar user={this.props.module.owner} />
                )}
                <div
                    className="flex-simple-column"
                    style={{ marginLeft: "5px" }}
                >
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--popup-tertiary-text-color"
                            ),
                            fontFamily: "Arial",
                            fontSize: 10,
                        }}
                    >
                        {this.props.module.lastUpdated}
                    </span>
                </div>
            </div>
        );
    }

    private renderContents(globalContext: GlobalContextContents): JSX.Element {
        return (
            <div>
                <div
                    className="flex-simple-column"
                    style={{
                        width: "100%",
                        minHeight: this.props.parentContainerHeight,
                        justifyContent: "space-between",
                    }}
                >
                    <div className="flex-simple-column">
                        <div
                            className="center-container"
                            style={{ minHeight: 30 }}
                        >
                            <span
                                style={{
                                    marginTop: 20,
                                    paddingTop: 0,
                                    paddingLeft: 10,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontFamily: "Roboto",
                                    fontSize: 30,
                                    fontWeight: 300,
                                }}
                            >
                                {this.buildStepTitle()}
                            </span>
                        </div>

                        {this.buildContent()}
                    </div>
                    <div style={{ flexGrow: 1 }} />
                    {this.buildFooter(globalContext)}
                </div>
                {this.state.showManageModulePopup && (
                    <ManageModuleCard
                        isKit={this.props.module.isKit()}
                        isShared={this.props.module.isShared}
                        moduleId={this.props.module.id as number}
                        onClose={() => {
                            this.setState({ 
                                showManageModulePopup: false,
                            });
                            this.props.doReload?.();
                            
                        }}
                    ></ManageModuleCard>
                )}
            </div>
        );
    }

    render() {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

export default ModuleCard;
