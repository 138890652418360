import React from "react";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import {
    getDataTablePermissionsApi,
    setDataTablePermissionsApi,
} from "common/UserGroupsApi";
import GroupsPermissionsSelector from "common/GroupsPermissionsSelector";
import { Permission } from "common/Permissions";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
                permissions: null,
                lastDataScopeId: null,
            };
        }

        getPermissions() {
            if (this.props.commonState.viewDataScopeId != null) {
                let dataScopeId = this.props.commonState.viewDataScopeId;
                getDataTablePermissionsApi(
                    dataScopeId
                ).then((permissions) => {
                    this.setState({
                        permissions: permissions,
                        lastDataScopeId: dataScopeId,
                    });
                });
            }
        }

        setPermissions() {
            if (this.props.commonState.viewDataScopeId != null) {
                this.setStepState(StepState.running);
                setDataTablePermissionsApi(
                    this.props.commonState.viewDataScopeId,
                    this.state.permissions?.filter(
                        (item) => item != null
                    ) ?? []
                )
                    .then(() => {
                        this.setStepState(StepState.finished);
                    })
                    .catch((error) => {
                        this.setStepState(StepState.error, String(error));
                    });
            }
        }

        componentDidMount() {
            this.getPermissions();
        }

        componentDidUpdate(_prevProps, _prevState) {
            if (
                this.props.commonState.viewDataScopeId !==
                this.state.lastDataScopeId
            ) {
                this.getPermissions();
            }
        }

        render() {
            let hasWriteAccess =
                this.props.commonState.viewDataScope?.permissionType ===
                Permission.ReadWrite;
            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        disabled={this.props.disabled}
                        className="btn-processing-tab"
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState((state) => ({
                                expanded: !state.expanded,
                            }));
                        }}
                    >
                        <span className="content-regular-text">
                            Access control
                        </span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    {this.state.expanded ? (
                        <Button
                            disabled={!hasWriteAccess}
                            className="btn btn-lg btn-primary my-primary"
                            onClick={() => {
                                this.setPermissions();
                            }}
                            style={{
                                fontFamily: "Roboto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: "25px",
                                width: "100%",
                                minWidth: "110px",
                            }}
                        >
                            UPDATE
                        </Button>
                    ) : (
                        <div style={{ minWidth: "110px" }} />
                    )}
                    {this.state.expanded && hasWriteAccess ? (
                        <div>{this.renderStepState()}</div>
                    ) : (
                        <div />
                    )}
                    {this.state.expanded &&
                        this.props.commonState.viewDataScopeId != null && (
                            <>
                                <div />
                                <div
                                    style={{
                                        gridColumnStart: 2,
                                        gridColumnEnd: 4,
                                        background: "#3f4b62",
                                        padding: "10px",
                                        display: "flex",
                                        alignItems: "left",
                                        flexDirection: "column",
                                    }}
                                >
                                    <GroupsPermissionsSelector
                                        selectedUserGroups={
                                            this.state.permissions ?? []
                                        }
                                        onChange={(permissions) => {
                                            this.setState({
                                                permissions: permissions,
                                            });
                                        }}
                                        readOnly={!hasWriteAccess}
                                    />
                                </div>
                                <div />
                            </>
                        )}
                </>
            );
        }
    }
);

export { MainComponent };
export let requirePermission = "DataSetAccessControl";
