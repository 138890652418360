import React, { Component } from "react";

interface Props {
    onReject: (event: MouseEvent) => void;
    listenMouseUp?: boolean;
}

/**
 * Component that alerts if you click outside of it
 */
export default class OutsideAlerter extends Component<Props> {
    private wrapperRef: HTMLDivElement | undefined;

    constructor(props: Props) {
        super(props);

        this.wrapperRef = undefined;

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        if (this.props.listenMouseUp) {
          document.addEventListener("mouseup", this.handleClickOutside);
          return;
        }
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        if (this.props.listenMouseUp) {
          document.removeEventListener("mouseup", this.handleClickOutside);
          return;
        }
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    public setWrapperRef(node: HTMLDivElement): void {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    public handleClickOutside(event: MouseEvent): void {
        if(event.target instanceof Element) {
            if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
                this.props.onReject(event);
            }
        } else {
          this.props.onReject(event);
        }
    }

  render() {
    return (
      <div ref={this.setWrapperRef}>
        {React.Children.only(this.props.children)}
      </div>
    );
  }
}
