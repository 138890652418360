import { FlagOptions, PinOptions } from "../types";
import "leaflet/dist/leaflet.css";
import Leaflet from "leaflet";
import { FlagType } from "common/Finding";
import ReactDOMServer from "react-dom/server";
import { ReactComponent as FlagIcon } from "icons/canvas/map/flag.svg";
import { getTextSize } from "common/utilities/MeasureText";
import { iconToPath } from "common/MakiIcons";
import { ReactComponent as PinIcon } from "icons/canvas/map/pin.svg";

export const markerIcon = (
    flagOptions: FlagOptions,
    pinOptions: PinOptions,
    base64Icon?: string | null,
    base64LogoIcon?: string | null
) => {
    const { color, borderColor } = pinOptions;
    const {
        flagType,
        flagValues,
        flagColor,
        textColor,
        textBackgroundColor,
    } = flagOptions;
    const isPinsType = flagType === FlagType.Pins;

    if (isPinsType) {
        return new Leaflet.DivIcon({
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            // labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {!base64Icon && color && (
                        <div
                            style={{
                                width: 64,
                                height: 64,
                            }}
                        >
                            <PinIcon
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    filter: `drop-shadow(1px 1px 0 ${borderColor}) drop-shadow(-1px -1px 0 ${borderColor}) drop-shadow(1px -1px 0 ${borderColor}) drop-shadow(-1px 1px 0 ${borderColor})`,
                                }}
                                fill={color}
                            />
                        </div>
                    )}
                    {base64Icon && <img src={base64Icon} alt="" />}
                    {!base64Icon && !color && (
                        <img
                            src="/dist/img/canvas/map/pin.svg"
                            alt=""
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    )}
                    {base64LogoIcon && (
                        <img
                            src={base64LogoIcon}
                            alt=""
                            style={{
                                width: "38px",
                                height: "38px",
                                objectFit: "cover",
                                position: "absolute",
                                top: 3,
                                zIndex: 9999,
                                borderRadius: "50%",
                            }}
                        />
                    )}
                </div>
            ),
        });
    }

    if (flagType === FlagType.Marker) {
        return new Leaflet.DivIcon({
            iconAnchor: [0, 64],
            popupAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        width: 64,
                        height: 64,
                        display: "flex",
                        justifyContent: "center",
                        color: textColor,
                    }}
                >
                    <FlagIcon
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                        }}
                        fill={flagColor ?? "#000"}
                    />
                    <div
                        className="map-flag-tooltip-text"
                        style={{
                            backgroundColor:
                                textBackgroundColor ?? "trasparent",
                            borderRadius: 5,
                        }}
                    >
                        {flagValues[0]}
                    </div>
                </div>
            ),
        });
    } else {
        const longestValue =
            flagValues.sort(function (a, b) {
                return b.length - a.length;
            })[0] ?? "";
        const flagWidth =
            getTextSize(longestValue, "Roboto", 12, "normal").width + 25;
        const flagHeight = 42 + 22 * (flagValues.length || 1);

        return new Leaflet.DivIcon({
            iconAnchor: [0, flagHeight],
            popupAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, flagHeight),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        width: flagWidth,
                        height: flagHeight,
                        borderLeft: `2px solid ${flagColor ?? "#acacac"}`,
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: 22 * (flagValues.length || 1),
                            backgroundColor: textBackgroundColor ?? "#fff",
                            borderRight: `1px solid ${flagColor ?? "#acacac"}`,
                            borderTop: `1px solid ${flagColor ?? "#acacac"}`,
                            borderBottom: `1px solid ${flagColor ?? "#acacac"}`,
                            textAlign: "center",
                            lineHeight: "21px",
                            fontSize: 12,
                            color: textColor,
                        }}
                    >
                        {flagValues.map((value) => (
                            <p style={{ margin: 0 }}>{value}</p>
                        ))}
                    </div>
                </div>
            ),
        });
    }
};

export const markerIconWithSymbol = (
    base64Icon?: string | null,
    color?: string | null,
    symbol?: string | null
) => {
    const defaultIconPath: string =
        symbol != null
            ? "/dist/img/canvas/map/marker_filled.png"
            : "/dist/img/canvas/map/marker.png";
    if (base64Icon != null || color == null) {
        return new Leaflet.DivIcon({
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            // labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        position: "relative",
                        width: 64,
                        height: 64,
                    }}
                >
                    <img
                        alt=""
                        src={base64Icon ?? defaultIconPath}
                        style={{
                            width: 64,
                            height: 64,
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                    />
                    {symbol != null && (
                        <div
                            style={{
                                backgroundColor: "white",
                                maskImage: `url(${iconToPath[symbol]})`,
                                maskSize: "contain",
                                WebkitMaskImage: `url(${iconToPath[symbol]})`,
                                WebkitMaskSize: "contain",
                                width: 28,
                                height: 28,
                                position: "absolute",
                                top: "33%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    )}
                </div>
            ),
        });
    } else {
        return new Leaflet.DivIcon({
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            // labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        position: "relative",
                        width: 64,
                        height: 64,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: color,
                            maskImage: `url(${defaultIconPath})`,
                            WebkitMaskImage: `url(${defaultIconPath})`,
                            width: 64,
                            height: 64,
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                    />
                    {symbol != null && (
                        <div
                            style={{
                                backgroundColor: "white",
                                maskImage: `url(${iconToPath[symbol]})`,
                                maskSize: "contain",
                                WebkitMaskImage: `url(${iconToPath[symbol]})`,
                                WebkitMaskSize: "contain",
                                width: 28,
                                height: 28,
                                position: "absolute",
                                top: "33%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    )}
                </div>
            ),
        });
    }
};

export const markerIconTransparent = new Leaflet.Icon({
    iconUrl: "/dist/img/canvas/map/marker_transparent.png",
    iconRetinaUrl: "/dist/img/canvas/map/marker_transparent.png",
    iconAnchor: [32, 32],
    popupAnchor: [0, 0],
    labelAnchor: [0, 0],
    tooltipAnchor: [0, 0],
    iconSize: new Leaflet.Point(64, 64),
    className: "",
});
