import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import { UserIcon } from "common/UserIcon";
import { mainStyle } from "common/MainStyle";
import NotificationIcon from "common/NotificationIcon";
import { Insight } from "./Insight";
import { isValidConfig } from "common/PathConfigVersion";

interface Props {
    item: Insight;
    tabName?: string;
    canEdit?: boolean;
    canPin?: boolean;
    canCopy?: boolean;
    frozen: boolean;
    acceptDrag: string;
    acceptDrop: string | string[];
    onCrossClicked: () => void;
    onPinClicked: () => void;
    onCardClicked: () => void;
    onShareClicked: () => void;
    onCopyClicked: () => void;
    onCardsSwapped: (insight1: Insight, insight2: Insight) => void;
}

export default function InsightsGridCard(props: Props) {
    let {
        item,
        tabName,
        canEdit,
        canPin,
        canCopy,
        frozen,
        acceptDrag,
        acceptDrop,
        onCrossClicked,
        onPinClicked,
        onCardClicked,
        onShareClicked,
        onCopyClicked,
        onCardsSwapped,
    } = props;
    const ref = useRef(null);
    // [{ isDragging }, drag]

    const drag = useDrag({
        type: acceptDrag,
        item: {
            content: item,
        },
        end: (_item, _monitor) => {},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })[1];

    const drop = useDrop<
        {
            type: string;
            content: Insight;
        },
        void,
        void
    >({
        accept: acceptDrop,
        drop(otherItem, _monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = otherItem.content.id;
            const hoverIndex = item.id;
            if (dragIndex === hoverIndex) {
                return;
            }
            onCardsSwapped(item, otherItem.content);
        },
    })[1];
    if (!frozen) {
        drag(drop(ref));
    }
    let hasConfig =
        item.contents.finding && isValidConfig(item.contents.finding.config);
    return (
        <div
            ref={ref}
            key={item.id}
            className="dashboard-rect-card flex-column"
            onClick={() => {
                onCardClicked();
            }}
            style={{
                width: 357,
                minHeight: 324,
                cursor: "pointer",
                position: "relative",
            }}
        >
            {item.has_new_feedbacks && <NotificationIcon />}
            {tabName && (
                <div style={{ backgroundColor: "#3f4b62" }}>
                    <span
                        style={{
                            padding: 5,
                            width: "100%",
                            color: "#FFF",
                            fontFamily: "Arial",
                            fontSize: 10,
                        }}
                    >
                        {tabName}
                    </span>
                </div>
            )}
            <div className="flex-simple-column" style={{ height: "40px" }}>
                <div
                    style={{
                        justifyContent: "flex-end",
                        display: "flex",
                        height: "16px",
                        width: "100%",
                    }}
                />
                <div
                    className="my-row"
                    style={{ height: "24px", width: "100%" }}
                >
                    {!frozen && canPin && (
                        <div
                            title={hasConfig ? "Pin to KPI Stream" : undefined}
                            style={{
                                opacity: hasConfig ? 1 : 0.3,
                                marginLeft: "21px",
                            }}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                if (hasConfig) onPinClicked();
                            }}
                        >
                            <img
                                alt=""
                                src="/dist/img/insights/insights_pin.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    )}
                    <div style={{ flexGrow: 1 }} />
                    {!frozen && canEdit && (
                        <div
                            title={"Delete card"}
                            style={{
                                alignSelf: "flex-end",
                                marginRight: "18px",
                            }}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                onCrossClicked();
                            }}
                        >
                            <img
                                alt=""
                                src="/dist/img/insights/insights_remove.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div
                className="flex-column"
                style={{
                    alignItems: "center",
                    margin: "20px",
                    flexGrow: 1,
                }}
            >
                {item.contents.imageSrc && (
                    <img
                        alt=""
                        src={item.contents.imageSrc}
                        style={{
                            width: "250px",
                            height: "125px",
                            objectFit: "contain",
                        }}
                    />
                )}
                <span
                    className="line-clamp"
                    style={{
                        maxHeight: "45px",
                        marginLeft: 10,
                        marginRight: 10,
                        width: "100%",
                        marginTop: item.contents.imageSrc ? "28px" : "0px",
                        color: item.contents.title
                            ? mainStyle.getPropertyValue(
                                  "--content-primary-text-color"
                              )
                            : "#20293C",
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        fontWeight: 700,
                        lineHeight: "23px",
                        textAlign: "center",
                    }}
                >
                    {item.contents.title || "Insert insight title."}
                </span>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "21px",
                    marginBottom: "14px",
                }}
            >
                {item.user_data != null && (
                    <UserIcon
                        width={22}
                        height={22}
                        fontSize={10}
                        user={item.user_data!}
                        iconUrl={item.user_data.icon_url}
                    />
                )}
                <div
                    className="flex-simple-column"
                    style={{ marginLeft: "5px" }}
                >
                    <span
                        style={{
                            minHeight: "14px",
                            color: mainStyle.getPropertyValue(
                                "--content-primary-text-color"
                            ),
                            fontFamily: "Arial",
                            fontSize: 10,
                        }}
                    >
                        {item.user_data
                            ? item.user_data.first_name
                                  .concat(" ")
                                  .concat(item.user_data.last_name)
                            : ""}
                    </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--content-secondary-text-color"
                            ),
                            fontFamily: "Arial",
                            fontSize: 10,
                        }}
                    >
                        {item.creation_time || ""}
                    </span>
                </div>
                <div style={{ flexGrow: 1 }} />
                {!frozen && (
                    <div
                        title={"Share card"}
                        style={{ marginRight: "18px" }}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            onShareClicked();
                        }}
                    >
                        <img
                            alt=""
                            src="/dist/img/insights/insights_share.png"
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                )}
                {!frozen && canCopy && (
                    <div
                        title={"Clone card"}
                        style={{ marginRight: "18px" }}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            onCopyClicked();
                        }}
                    >
                        <img
                            alt=""
                            src="/dist/img/insights/insights_copy.png"
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
