import React from "react";
import styles from "./CreateNewButton.module.css";
import commonStyles from "modules/home_page/home_page.module.css";
import cx from "classnames";

interface Props {
    onClickNew: React.MouseEventHandler;
    className?: string;
}

export default function CreateNewButton(props: Props) {
    return (
        <button
            type="button"
            onClick={props.onClickNew}
            className={cx("btn", styles.button, "tour-step-1", commonStyles.middleText)}
        >
            New
        </button>
    );
}