import React from "react";
import { createClientMessage } from "react-chatbot-kit";
import "./CustomFirstMessageButton.css";
import { CustomMessageProps } from "../../ChatbotTypes";
import { defaultAiCopilotCustomFirstMessageBtnColor } from "common/Finding";

interface Props extends CustomMessageProps {
    text: string;
}

const CustomFirstMessageButton = ({ text, setState, actions, finding }: Props) => {
    return (
        <button
            className="custom-first-message-button"
            style={{
                backgroundColor: finding.config.customFirstMessageBtnColor ?? defaultAiCopilotCustomFirstMessageBtnColor,
            }}
            onClick={() => {
                const message = createClientMessage(text, {});
                setState((prev) => ({
                    messages: [...prev.messages, message],
                }));
                actions.handleMessage(message.message);
            }}
        >
            {text}
        </button>
    );
};

export default CustomFirstMessageButton;
