import React, { Component, CSSProperties } from "react";
import Select, {
    createFilter,
    PropsValue,
} from "react-select";

import customSelectStyles from "common/SelectStyles";
import axios from "common/ServerConnection";
import { UserIcon } from "common/UserIcon";

export interface UserName {
    user_name: string;
    first_name: string;
    last_name: string;
    icon_url: string;
}

function toOption(
    userName: UserName | undefined | null
): Option | undefined | null {
    if (userName != null) {
        return {
            label: `${userName.first_name} ${userName.last_name}`,
            value: userName,
        };
    } else {
        return userName;
    }
}

interface Option {
    label: string;
    value: UserName;
}

interface Props {
    onChange: (newValue: UserName | undefined | null) => void;
    value: UserName | undefined | null;
    style?: CSSProperties;
    menuPortalEnabled?: boolean;
    placeholder?: string;
}

interface State {
    options: Option[];
}

class UserSelector extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            options: [],
        };
        this.onChange = this.onChange.bind(this);
        this.formatOptionLabel = this.formatOptionLabel.bind(this);
    }

    public componentDidMount(): void {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                user_names?: UserName[];
            }>("/api/get_user_names")
            .then((response) => {
                if (response.data.success && response.data.user_names != null) {
                    this.setState({
                        options: response.data.user_names.map(
                            toOption
                        ) as Option[],
                    });
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private onChange(newValue: PropsValue<Option>): void {
        this.props.onChange(
            newValue != null ? (newValue as Option).value : newValue
        );
    }

    private formatOptionLabel(
        option: Option,
        _labelMeta: any
    ): React.ReactNode {
        return (
            <div
                style={{
                    display: "flex",
                }}
            >
                <UserIcon
                    width={22}
                    height={22}
                    fontSize={10}
                    user={option.value}
                    iconUrl={option.value.icon_url}
                />
                <span
                    className="regular-text unselectable"
                    style={{
                        marginLeft: 5,
                        color: "var(--selectors-text-color)",
                    }}
                >
                    {option.label}
                </span>
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <Select
                filterOption={createFilter({
                    ignoreAccents: false,
                })}
                placeholder={this.props.placeholder}
                styles={{
                    ...customSelectStyles,
                    container: (base) => ({
                        ...base,
                        height: "38px",
                        width: "16em",
                        ...this.props.style,
                    }),
                    menuPortal: (base) => ({
                        ...base,
                        zIndex: 100000000,
                    }),
                }}
                options={this.state.options}
                formatOptionLabel={this.formatOptionLabel}
                value={toOption(this.props.value)}
                onChange={this.onChange}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        text: "white",
                        primary25: "var(--selectors-background-hover-color)",
                    },
                })}
                menuPortalTarget={
                    this.props.menuPortalEnabled ? document.body : undefined
                }
            />
        );
    }
}

export default UserSelector;
