enum StylingMenuOption {
    None = 0,
    Xaxis = 1,
    Yaxis = 2,
    VariablesColor = 3,
    Chart = 4,
    Tooltip = 5,
}

export default StylingMenuOption;
