import React, { useState } from "react";
import cx from "classnames";

import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import Dropdown from "common/HeaderBarRevamped/Dropdown";
import DropdownMenu from "common/HeaderBarRevamped/Dropdown/DropdownMenu";
import DropdownCanvasButton from "common/HeaderBarRevamped/Dropdown/DropdownCanvasButton";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import styles from "./ListOfHiddenElements.module.css";
import { CanvasBackground, CanvasDashboard, CanvasNode, CanvasType } from "common/Canvas";

interface Props {
    canvasTreeStore: CanvasTreeStore,
}

function ListOfHiddenElements(props: Props) {
    const { canvasTreeStore } = props;
    const { canvasViewMode } = canvasTreeStore;

    const [ updater, setUpdater ] = useState(0)
    let isThereAnyHiddenElement = false;

    const elements = Array.from(canvasTreeStore.canvasTreeState.values());
    const backgrounds = Array.from(canvasTreeStore.backgroundsState);
    const dashboards = Array.from(canvasTreeStore.dashboardsState.keys());
    
    const hiddenElements: CanvasNode[] = [];
    const hiddenBackgrounds: CanvasBackground[] = [];
    const hiddenDashboards: CanvasDashboard[] = [];
    const hiddenDashboardIds: string[] = [];

    elements.forEach(elem => {
        if (elem.nodeIsHidden && elem.nodeIsHidden[canvasViewMode]) {
            isThereAnyHiddenElement = true;
            hiddenElements.push(elem)
        };
    });

    backgrounds.forEach(elem => {
        if (
            (canvasViewMode === "desktop" && elem.is_hidden)
            || (canvasViewMode === "mobile" && elem.mobile_is_hidden)
        ) {
            isThereAnyHiddenElement = true;
            hiddenBackgrounds.push(elem)
        };
    });

    dashboards.forEach(dashboardId => {
        let dashboard = canvasTreeStore.dashboardsState.get(
            dashboardId
        );
        if (dashboard && dashboard.nodeIsHidden && dashboard.nodeIsHidden[canvasViewMode]) {
            isThereAnyHiddenElement = true;
            hiddenDashboards.push(dashboard);
            hiddenDashboardIds.push(dashboardId);
        };
    });

    const listIsHidden = isThereAnyHiddenElement ? "" : "hidden";

	return (
		<>
            <div
                className={cx(
                    styles.listOfHiddenElements,
                    listIsHidden,
                )}
            >
                <Dropdown>
                    <DropdownCanvasButton
                        id="hidden-elements-list"
                        title="Hidden Elements"
                        className={styles.listOfHiddenElementsToggle}
                    >
                        <ChevronIcon />
                        <p>Hidden elements</p>
                    </DropdownCanvasButton>
                    <DropdownMenu className={styles.listItem}>
                        {hiddenElements.map((elem: CanvasNode, idx: number) => {
                            return (
                                <div>
                                    <span>{ `${CanvasType[elem.canvasType as keyof typeof elem.canvasType]} ${idx + 1}` }</span>
                                    <button onClick={() => {
                                        canvasTreeStore.toggleVisibilityElementAction(elem.id, false)
                                        setUpdater(updater + 1);
                                    }}>
                                        Show
                                    </button>
                                </div>
                            )
                        })}
                        {hiddenBackgrounds.map((elem: CanvasBackground, idx: number) => {
                            return (
                                <div>
                                    <span>{ `Image ${idx + 1}` }</span>
                                    <button onClick={() => {
                                        canvasTreeStore.toggleVisibilityBackgroundAction(elem.id, false)
                                        setUpdater(updater + 1);
                                    }}>
                                        Show
                                    </button>
                                </div>
                            )
                        })}
                        {hiddenDashboards.map((dashboard: CanvasDashboard, idx: number) => {
                            return (
                                <div>
                                    <span>{`${dashboard?.finding?.type} ${idx + 1}` }</span>
                                    <button onClick={() => {
                                        canvasTreeStore.updateDashboardAction(
                                            hiddenDashboardIds[idx],
                                            {
                                                nodeIsHidden: {
                                                    ...dashboard!.nodeIsHidden,
                                                    [canvasViewMode]: false,
                                                },
                                            }
                                        );
                                        setUpdater(updater + 1);
                                    }}>
                                        Show
                                    </button>
                                </div>
                            )
                        })}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </>
	);
}

export default ListOfHiddenElements;
