import React, { Component, CSSProperties } from "react";
import { Button } from "react-bootstrap";
import Popup from "reactjs-popup";
import Alert from "common/Alert";
import Draggable from "react-draggable";
import OutsideAlerter from "common/OutsideAlerter";

interface Props {
    defaultTitle?: string;
    onClose: () => void;
    overlayStyle?: CSSProperties;
    exportFunction: (title: string) => Promise<void>;
}

enum UpdateStatus {
    success = 1,
    loading = 2,
    error = 3,
    notUploaded = 4,
}

interface State {
    left: number;
    top: number;
    status: UpdateStatus;
    title: string;
    errorMessage: string;
}

class ExportPopup extends Component<Props, State> {
    inputRef = React.createRef<HTMLInputElement>();
    drag: boolean = true;
    constructor(props: Props) {
        super(props);
        this.state = {
            left: 0,
            top: 0,
            title: props.defaultTitle ?? "",
            status: UpdateStatus.notUploaded,
            errorMessage: "",
        };
    }
    private buildAlert(): JSX.Element {
        let alert: JSX.Element = <div style={{ minHeight: 38 }} />;
        let alertStyle = {
            marginBottom: 0,
            minHeight: 38,
            paddingTop: 10,
            paddingBottom: 10,
        };
        if (this.state.status === UpdateStatus.success) {
            alert = (
                <Alert
                    style={alertStyle}
                    text="Success."
                    className="alert alert-success alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.error) {
            alert = (
                <Alert
                    style={alertStyle}
                    text={"Error: ".concat(this.state.errorMessage)}
                    className="alert alert-danger alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.loading) {
            alert = (
                <Alert
                    style={alertStyle}
                    text={"Exporting..."}
                    className="alert alert-warning alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        }
        return alert;
    }

    private buildInnerView(): JSX.Element {
        return (
            <div
                className="flex-simple-column"
                style={{
                    overflow: "visible",
                    marginLeft: "38px",
                    marginRight: "38px",
                    paddingTop: "30px",
                    paddingBottom: "10px",
                }}
            >
                <input
                    ref={this.inputRef}
                    type="text"
                    className="like-tab"
                    placeholder="Enter name of file"
                    style={{
                        width: "100%",
                        alignSelf: "center",
                    }}
                    value={this.state.title}
                    onChange={(evt) => {
                        let title = evt.target.value;
                        this.setState({ title: title });
                    }}
                />
                <div
                    className="my-row"
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        alignSelf: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <Button
                        disabled={this.state.title.length === 0}
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={async () => {
                            this.setState({ status: UpdateStatus.loading });
                            try {
                                await this.props.exportFunction(
                                    this.state.title
                                );
                                this.setState({
                                    status: UpdateStatus.success,
                                });
                            } catch (error) {
                                this.setState({
                                    status: UpdateStatus.error,
                                    errorMessage: String(error),
                                });
                            }
                        }}
                    >
                        EXPORT
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={() => {
                            this.props.onClose();
                        }}
                    >
                        CLOSE
                    </Button>
                </div>
                {this.buildAlert()}
            </div>
        );
    }

    public render(): JSX.Element {
        const height: number = 150;
        const width: number = 550;
        return (
            <Popup
                arrow={true}
                contentStyle={{
                    width: width,
                    minHeight: height,
                    border: "none",
                    backgroundColor: "transparent",
                }}
                overlayStyle={this.props.overlayStyle}
                open={true}
                onClose={() => {
                    this.props.onClose();
                }}
                closeOnDocumentClick
            >
                <Draggable
                    cancel="input"
                    position={{
                        x: this.state.left,
                        y: this.state.top,
                    }}
                    onDrag={() => {
                        this.drag = true;
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            this.drag = false;
                            this.setState({
                                left: data.x,
                                top: data.y,
                            });
                        }
                    }}
                >
                    <div
                        className="dashboard-rect element cancel-drag"
                        style={{
                            overflowX: "visible",
                            overflowY: "auto",
                            boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                            borderRadius: 0,
                            alignItems: "center",
                            cursor: "pointer",
                            minHeight: height,
                            maxHeight: "100vh",
                            width: width,
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt: any) => {
                            evt.stopPropagation();
                        }}
                    >
                        <OutsideAlerter
                            onReject={() => {
                                this.props.onClose();
                            }}
                        >
                            {this.buildInnerView()}
                        </OutsideAlerter>
                    </div>
                </Draggable>
            </Popup>
        );
    }
}
export default ExportPopup;
