import React from "react";
import styles from "./WarningModal.module.css";
import OutsideAlerter from "common/OutsideAlerter";
import cx from "classnames";

interface IProps {
    onReplace: () => void;
    onKeepBoth?: () => void;
    onReject?: () => void;
    title: string;
    text: string;
    subText: string;
    replaceText?: string;
    keepBothText?: string;
    rejectText?: string;
    zIndex?: number;
}

const WarningModalPopup: React.FC<IProps> = ({
    title,
    text,
    subText,
    onReplace,
    onKeepBoth,
    onReject,
    replaceText,
    keepBothText,
    rejectText,
    zIndex,
}) => {
    const _buildInnerView = (): JSX.Element => {
        return (
            <div
                className={`flex-simple-column ${styles.messagePopupContainer}`}
                style={{ zIndex: zIndex }}
            >
                <div className={styles.header}>
                    <p>{title}</p>
                </div>

                <div className={styles.mainContent}>
                    <p style={{ paddingInline: 20 }}>{text}</p>
                </div>
                <div className={styles.subContent}>
                    <p className={styles.paragraph}>{subText}</p>
                </div>
                <div className={styles.btnRow}>
                    {onKeepBoth ? (
                        <button
                            type="button"
                            className={"cancelBtn"}
                            style={{
                                color: "var(--acceptBtn-color)",
                                width: "150px",
                            }}
                            onClick={() => {
                                onKeepBoth!();
                            }}
                        >
                            {keepBothText ?? "Keep Both"}
                        </button>
                    ) : (
                        <></>
                    )}
                    <button
                        type="button"
                        className={"cancelBtn"}
                        style={{
                            color: "var(--acceptBtn-color)",
                            width: "150px",
                        }}
                        onClick={() => {
                            if (onReject) onReject!();
                        }}
                    >
                        {rejectText ?? "Cancel"}
                    </button>
                    <button
                        type="button"
                        className={cx("acceptBtn")}
                        style={{
                            marginLeft: 10,
                            width: "150px",
                        }}
                        onClick={() => {
                            onReplace!();
                        }}
                    >
                        {replaceText ?? "Replace"}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <OutsideAlerter
            onReject={() => {
                if (onReject != null) {
                    onReject();
                }
            }}
        >
            <div
                className={`${styles.container}`}
                onClick={(evt) => {
                    evt.stopPropagation();
                }}
                style={{
                    height: "50%",
                    width: "100%",
                }}
            >
                {_buildInnerView()}
            </div>
        </OutsideAlerter>
    );
};

export default WarningModalPopup;
