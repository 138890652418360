import React from "react";
import { mainStyle } from "common/MainStyle";
import {
    LeversOutcomePredictionFinding,
    LeversOutcomeType,
} from "common/Finding";
import RegressionVariablesBarChart from "./RegressionVariablesBarChart";
import OutcomeCurve from "./OutcomeCurve";
import styles from "./LeverOutcomeChart.module.css";
import cx from "classnames";

interface Props {
    data: LeversOutcomePredictionFinding["content"]["data"];
    config: LeversOutcomePredictionFinding["config"];
    regressionInfo: LeversOutcomePredictionFinding["content"]["regressionInfo"];
    groupNames?: string[];
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    dataSetMenuIsOpen?: boolean;
    onChangeData?: (
        data: LeversOutcomePredictionFinding["content"]["data"],
        updateFinding?: boolean
    ) => void;
    onChangeConfig?: (
        config: LeversOutcomePredictionFinding["config"],
        updateFinding?: boolean
    ) => void;
}

function getModel(
    regressionInfo: LeversOutcomePredictionFinding["content"]["regressionInfo"],
    config: LeversOutcomePredictionFinding["config"]
): string {
    let model: string = "";
    let mainTerm = "";
    let interactsWithTerm = "";
    if (regressionInfo == null && config.dataScope == null)
        model = "Y = intercept + \u03B21 x1 + \u03B22 x2";
    else if (regressionInfo == null) model = "";
    else {
        let betaIndex = 1;
        mainTerm = `${regressionInfo.iv.main_log ? "log " : ""}${
            regressionInfo.iv.main ?? ""
        }`;
        model = `${regressionInfo.dv_log ? "log " : ""}${regressionInfo.dv} = ${
            regressionInfo.intercept ? "intercept + " : ""
        }\u03B2${betaIndex} ${mainTerm}`;
        if (regressionInfo?.iv.interacts_with != null) {
            betaIndex += 1;
            interactsWithTerm = `${
                regressionInfo?.iv.interacts_with_log ? "log " : ""
            }${regressionInfo?.iv.interacts_with ?? ""}`;
            model += ` + \u03B2${betaIndex} ${interactsWithTerm}`;
            betaIndex += 1;
            model += ` + \u03B2${betaIndex} ${mainTerm} ${interactsWithTerm}`;
        }
        for (let i in regressionInfo?.iv.other ?? []) {
            betaIndex += 1;
            model += ` + \u03B2${betaIndex} ${regressionInfo?.iv.other_log?.[i] ? "log " : ""}${
                regressionInfo?.iv.other?.[i] ?? ""
            }`;
        }
    }

    return "Current model: ".concat(model);
}

export function LeverOutcomeChart(props: Props) {
    if (props.data.length === 0) return null;
    const getChart = () => {
        let showModel = props.config.showModel ?? true;
        if (props.config.outputType === LeversOutcomeType.Bars)
            return (
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {props.groupNames?.map((groupName, index) => (
                        <div className="center-container" key={index}>
                            <span
                                style={{
                                    color: "#333",
                                    fontFamily: "Arial",
                                    fontSize: "10px",
                                    lineHeight: "11px",
                                }}
                            >
                                {`${groupName} (${
                                    index === 0 ? "Top" : "Bottom"
                                })`}
                            </span>
                        </div>
                    ))}

                    <div
                        style={{
                            width: "100%",
                            height: `calc(100% - 30px - ${
                                showModel ? 60 : 0
                            }px - ${15 * (props.groupNames?.length ?? 0)}px)`,
                            overflowX: "hidden",
                            overflowY: "auto",
                        }}
                    >
                        <RegressionVariablesBarChart
                            config={props.config}
                            preview={props.regressionInfo == null}
                            data={props.data}
                        />
                    </div>

                    <div
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--graphs-axes-text-color"
                            ),
                            width: "100%",
                            height: "30px",
                            fontSize: "11px",
                            fontFamily: "Arial",
                            textAlign: "left",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            backgroundColor: "transparent",
                        }}
                    >
                        Significance Levels: ★★★ 99%, ★★ 95%, ★ 90%
                    </div>
                </div>
            );
        if (
            props.config.outputType === LeversOutcomeType.Curve ||
            props.config.outputType === LeversOutcomeType.MarginalCurve
        ) {
            return (
                <div
                    style={{
                        height: `calc(100% - ${showModel ? 60 : 0}px)`,
                        width: "100%",
                    }}
                >
                    <OutcomeCurve
                        data={props.data}
                        config={props.config}
                        groupNames={props.groupNames}
                        regressionInfo={props.regressionInfo}
                        showMarginals={
                            props.config.outputType ===
                            LeversOutcomeType.MarginalCurve
                        }
                    />
                </div>
            );
        }
        if (props.config.outputType === LeversOutcomeType.Raw) {
            return (
                <div
                    style={{
                        height: `calc(100% - ${showModel ? 60 : 0}px)`,
                        width: "100%",
                    }}
                >
                    {props.data.map((item, index) => (
                        <div
                            key={index}
                            className={styles.modelContainer}
                            style={{
                                height: `calc(100%/${props.data.length} - 10px)`,
                                width: "100%",
                            }}
                        >
                            <textarea
                                disabled
                                className={styles.summaryArea}
                                style={{
                                    resize: "none",
                                    height: "100%",
                                    width: "100%",
                                }}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                onMouseDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                value={item.summary}
                            ></textarea>
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    };
    let model = getModel(props.regressionInfo, props.config);
    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            {(props.config.showModel ?? true) && (
                <div className={styles.modelContainer} title={model}>
                    <textarea
                        disabled
                        className={cx(styles.summaryArea, styles.modelArea)}
                        style={{
                            resize: "none",
                            width: "100%",
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        value={model}
                    ></textarea>
                </div>
            )}
            {getChart()}
        </div>
    );
}

export default LeverOutcomeChart;
