import React, { Component, CSSProperties } from "react";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import customSelectStyles from "common/SelectStyles";
import { CanvasNode, GlobalInputLink } from "common/Canvas";
import GlobalInputs, {
	GlobalInput,
	GlobalInputsMap,
} from "common/GlobalInputs";
import { mainStyle } from "common/MainStyle";

interface Props {
	value: CanvasNode;
	onChange: <T extends keyof CanvasNode>(
		changes: Pick<CanvasNode, T>
	) => void;
	style?: CSSProperties;
}

class GlobalInputsSelector extends Component<Props> {
	render() {
		let selectedInputs: (GlobalInputLink | null)[] = Array.from(
			this.props.value.globalInputIds ?? []
		);
		selectedInputs.push(null);
		return (
			<div className="flex-simple-column" style={this.props.style}>
				{selectedInputs.map((input, index) => (
					<div
						className="my-row"
						key={index}
						style={{ marginTop: "5px", alignItems: "center" }}
					>
						<Select
							filterOption={createFilter({
								ignoreAccents: false,
							})}
							placeholder={"ADD GLOBAL INPUTS"}
							styles={{
								...customSelectStyles,
								container: (base) => ({
									...base,
									flex: 1,
									marginLeft: "26px",
									height: "38px",
								}),
								placeholder: (base) => ({
									...base,
									color: "#869AAC",
								}),
							}}
							options={GlobalInputs}
							value={
								input
									? {
											label: GlobalInputsMap[input.value],
											value: input.value,
									  }
									: null
							}
							onChange={(newValue) => {
								let globalInputIds: GlobalInputLink[] = Array.from(
									this.props.value.globalInputIds ?? []
								);
								let newId: number = (newValue as GlobalInput)
									.value;
								if (input != null) {
									globalInputIds[index].value = newId;
								} else {
									globalInputIds.push({
										...(newValue as GlobalInput),
									});
								}
								this.props.onChange({
									globalInputIds: globalInputIds,
								});
							}}
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								colors: {
									...theme.colors,
									text: "white",
									primary25: "var(--selectors-background-hover-color)",
								},
							})}
						/>
						{input != null && (
							<>
								<span
									style={{
										marginLeft: 10,
										textAlign: "left",
										color: mainStyle.getPropertyValue(
											"--popup-primary-text-color"
										),
										fontFamily: "Roboto",
										fontSize: 14,
									}}
								>
									as
								</span>
								<input
									className="like-select"
									style={{
										width: 100,
										paddingTop: "0px",
										paddingBottom: "0px",
										height: "38px",
										textAlign: "left",
										marginLeft: "10px",
									}}
									placeholder="alias"
									onChange={(e) => {
										let newLabel = e.target.value;
										let globalInputIds: GlobalInputLink[] = Array.from(
											this.props.value.globalInputIds ??
												[]
										);
										globalInputIds[index].label = newLabel;
										this.props.onChange({
											globalInputIds: globalInputIds,
										});
									}}
									value={input.label}
								/>
							</>
						)}
						<div className="flex-simple-column">
							{input && (
								<Button
									title={"Remove input"}
									className="btn-small-like-select"
									style={{
										marginLeft: 5,
										width: "19px",
										height: "19px",
									}}
									onClick={() => {
										let globalInputIds: GlobalInputLink[] = Array.from(
											this.props.value.globalInputIds ??
												[]
										);
										globalInputIds.splice(index, 1);
										this.props.onChange({
											globalInputIds: globalInputIds,
										});
									}}
								>
									{"\uFF0D" /* minus */}
								</Button>
							)}
						</div>
					</div>
				))}
			</div>
		);
	}
}
export default GlobalInputsSelector;