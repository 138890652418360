import React from "react";
//import APIButton from "../common_components/APIButton";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";

export default class VACCINES extends React.Component<APIComponentProps, {}> {
    onClick() {
        //    this.props.createMessage(`Updating ${this.props.name}...`, "info");
        // axios
        //     .post(this.props.uri)
        //     .then((response) => {
        //         if (!response.data.success) {
        //             this.props.createMessage(response.data.error_msg, "danger");
        //         } else {
        //             this.props.createMessage(`Successfully updated ${this.props.name}!`, "success");
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         this.props.createMessage("Something went wrong. Try again later.", "danger");
        //     })
    }

    render() {
        return (
            <div
                className="flex-simple-column"
                style={this.props.containerStyle}
            >
                <BaseAPIComponent {...this.props} />
            </div>
        );
    }
}

export { VACCINES as MainComponent };