import React from "react";

import BaseSubmodule from "../../common/BaseSubmodule";
import GlobalContext from "GlobalContext";

import modules from "./modules/module_list.json";

class MainComponent extends BaseSubmodule {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            submodules: [],
        };

        this.renderContents = this.renderContents.bind(this);
    }

    static getNumberOfSteps() {
        return modules.length;
    }

    static getTitle() {
        return "Slice";
    }

    componentDidMount() {
        let submodules = [];
        modules.forEach((dir) => {
            let mod = require(`./modules/${dir}/main.js`);
            submodules.push(mod);
        });

        this.setState({ submodules: submodules });
    }

    renderContents(globalContext) {
        let items = [];
        let submodule;
        let MainComponent;
        for (let index in this.state.submodules) {
            submodule = this.state.submodules[index];
            MainComponent = submodule.MainComponent;
            if (
                submodule.requirePermission == null ||
                submodule.requirePermission in globalContext.permissions
            ) {
                items.push(
                    <MainComponent index={this.props.index + Number(index)} />
                );
            }
        }
        return (
            <>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 13fr 1fr 3fr",
                        gridRowGap: "1em",
                        gridColumnGap: "1em",
                        alignItems: "center",
                    }}
                >
                    {items}
                </div>
            </>
        );
    }

    render() {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

export { MainComponent };
export let requirePermission = "SliceCurrent";