import React, { Component, CSSProperties } from "react";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import customSelectStyles from "common/SelectStyles";
import { SharedBoxLink, CanvasNode, SharedBoxOption } from "common/Canvas";
import { mainStyle } from "common/MainStyle";

interface SharedIdOption {
	label: string;
	value: number;
}

interface Props {
	value: CanvasNode;
	onChange: <T extends keyof CanvasNode>(
		changes: Pick<CanvasNode, T>
	) => void;
	allSharedBoxes: SharedBoxOption[];
	style?: CSSProperties;
}

class InputsSelector extends Component<Props> {
	render() {
		let childrenSharedIds: SharedBoxLink[] =
			this.props.value.childrenSharedIds ?? [];
		let selectedSharedBoxes: (SharedIdOption | null)[] = childrenSharedIds
			.map((link: SharedBoxLink): SharedIdOption | null => {
				let sharedBox = null;
				let filteredSharedBox = this.props.allSharedBoxes.filter(
					(box) => box.item.id === link.value
				);
				if (filteredSharedBox.length > 0) {
					sharedBox = filteredSharedBox[0].item;
				}

				return {
					label:
						sharedBox != null && sharedBox.box != null
							? sharedBox.page_title
									.concat(" >> ")
									.concat(sharedBox.canvas_title)
									.concat(" >> ")
									.concat(sharedBox.box.outerId)
							: `sharedNode=${link.value}`,
					value: link.value,
				};
			})
			.concat(null);
		let sharedBoxesOptions: SharedBoxOption[] = this.props.allSharedBoxes.filter(
			(sharedBox) =>
				!childrenSharedIds
					.map((link) => link.value)
					.includes(sharedBox.item.id)
		);

		return (
			<div className="flex-simple-column" style={this.props.style}>
				{selectedSharedBoxes.map((box, index) => (
					<div
						className="my-row"
						key={index}
						style={{ marginTop: "5px", alignItems: "center" }}
					>
						<Select
							filterOption={createFilter({
								ignoreAccents: false,
							})}
							placeholder={"ADD LINKED INPUTS"}
							styles={{
								...customSelectStyles,
								container: (base) => ({
									...base,
									flex: 1,
									marginLeft: "26px",
									height: "38px",
								}),
								placeholder: (base) => ({
									...base,
									color: "#869AAC",
								}),
							}}
							options={sharedBoxesOptions}
							value={box ? box : null}
							onChange={(newValue) => {
								let childrenSharedIds: SharedBoxLink[] = Array.from(
									this.props.value.childrenSharedIds
								);
								let newId: number = (newValue as SharedBoxOption)
									.value;
								if (box != null) {
									childrenSharedIds[index] = {
										...childrenSharedIds[index],
										value: newId,
									};
								} else {
									childrenSharedIds.push({
										label: "",
										value: newId,
									});
								}
								this.props.onChange({
									childrenSharedIds: childrenSharedIds,
								});
							}}
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								colors: {
									...theme.colors,
									text: "white",
									primary25: "var(--selectors-background-hover-color)",
								},
							})}
						/>
						{box != null && (
							<>
								<span
									style={{
										marginLeft: 10,
										textAlign: "left",
										color: mainStyle.getPropertyValue(
											"--popup-primary-text-color"
										),
										fontFamily: "Roboto",
										fontSize: 14,
									}}
								>
									as
								</span>
								<input
									className="like-select"
									style={{
										width: 100,
										paddingTop: "0px",
										paddingBottom: "0px",
										height: "38px",
										textAlign: "left",
										marginLeft: "10px",
									}}
									placeholder="alias"
									onChange={(e) => {
										let childrenSharedIds: SharedBoxLink[] = Array.from(
											this.props.value.childrenSharedIds
										);
										let newLabel = e.target.value;
										childrenSharedIds[
											index
										].label = newLabel;

										this.props.onChange({
											childrenSharedIds: childrenSharedIds,
										});
									}}
									value={childrenSharedIds[index].label}
								/>
							</>
						)}
						<div className="flex-simple-column">
							{box && (
								<Button
									title={"Remove input"}
									className="btn-small-like-select"
									style={{
										marginLeft: 5,
										width: "19px",
										height: "19px",
									}}
									onClick={() => {
										let childrenSharedIds: SharedBoxLink[] = Array.from(
											this.props.value.childrenSharedIds
										);
										childrenSharedIds.splice(index, 1);
										this.props.onChange({
											childrenSharedIds: childrenSharedIds,
										});
									}}
								>
									{"\uFF0D" /* minus */}
								</Button>
							)}
						</div>
					</div>
				))}
			</div>
		);
	}
}
export default InputsSelector;
