import React, { Component } from "react";
import { SharedModule } from "common/Module";
import SlideCard from "./SlideCard";
import { SharedModuleCanvas } from "common/Canvas";
import { sheetRibbonWidth } from "./Constants";
import { mainStyle } from "common/MainStyle";

interface Props {
    sharedModule: SharedModule;
    pageId: string;
    canvasId: number;
    onCanvasClick: (canvas: SharedModuleCanvas) => void;
}

class LoadSharedSlidePopup extends Component<Props> {
    private buildHeader(): JSX.Element {
        return (
            <div
                className="flex-simple-column"
                style={{
                    marginTop: "9px",
                    marginLeft: "9px",
                }}
            >
                <span
                    className="unselectable"
                    style={{
                        color: mainStyle.getPropertyValue(
                            "--sheet-ribbon-title-color"
                        ),
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: 12,
                        letterSpacing: "0.05em",
                    }}
                >
                    Slides in Section
                </span>
                <div
                    style={{
                        marginTop: "6px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        width: "100%",
                    }}
                ></div>
            </div>
        );
    }
    private buildInnerView(): JSX.Element {
        let index = this.props.sharedModule.pages.findIndex(
            (page) => page.pageId === this.props.pageId
        );
        return (
            <div
                className="flex-simple-column"
                style={{
                    marginTop: 10,
                    overflow: "visible",
                    alignItems: "center",
                }}
            >
                {(this.props.sharedModule.pages[index].sheets ?? []).map(
                    (canvas) => {
                        return (
                            <SlideCard
                                sharedCanvas={canvas}
                                highlight={this.props.canvasId === canvas.id}
                                hasNewFeedbacks={false}
                                canWrite={false}
                                key={canvas.id}
                                onClick={() => {
                                    this.props.onCanvasClick(canvas);
                                }}
                                onEdit={() => {}}
                                onContextMenu={() => {}}
                            />
                        );
                    }
                )}
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    overflow: "visible",
                    zIndex: 500,
                    position: "absolute",
                    right: 0,
                    top: 40,
                    width: sheetRibbonWidth,
                    paddingBottom: 10,
                    height: "calc(100% - 40px)",
                    backgroundColor: mainStyle.getPropertyValue(
                        "--sheet-ribbon-background-color"
                    ),
                    borderRadius: 0,
                }}
            >
                {this.buildHeader()}
                <div
                    className="element"
                    style={{
                        overflow: "auto",
                        alignItems: "center",
                        cursor: "pointer",
                        height: "calc(100% - 50px)",
                        width: "100%",
                    }}
                >
                    {this.buildInnerView()}
                </div>
            </div>
        );
    }
}

export default LoadSharedSlidePopup;
