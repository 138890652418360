import React, { Component } from "react";

import Avatar from "common/Avatar";
import PasswordChangePopup from "common/PasswordChangePopup";
import Dropdown from "common/HeaderBarRevamped/Dropdown";
import DropdownButton from "common/HeaderBarRevamped/Dropdown/DropdownButton";
import DropdownMenu from "common/HeaderBarRevamped/Dropdown/DropdownMenu";
import DropdownItem from "common/HeaderBarRevamped/Dropdown/DropdownItem";
import { goToInternalLink } from "common/InternalLinksHelper";
import currentUser from "common/CurrentUser";
import EmailPopup from "common/EmailPopup";
import UserInfoPopup from "common/UserInfoPopup";
import { getEmailApi } from "common/EmailApi";
import { LeftPanelItem } from "AppModule";
import User from "state/models/User";
import NotificationStore from "common/notifications/NotificationStore";
// import CurrentModulesStore from "common/CurrentModulesStore";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { ReactComponent as NotificationIcon } from "icons/notification_bell.svg";
import { ReactComponent as HomeIcon } from "icons/header/home.svg";
import { ReactComponent as TutorialIcon } from "icons/header/tutorial.svg";
import NotificationPopup from "common/NotificationPopup";
import { observer } from "mobx-react";
import NotificationDot from "common/NotificationIcon";
import styles from "./HeaderBar.module.css";
import Instrumentation from "common/Instrumentation";
import CurrentUser from "common/CurrentUser";
import { UserType } from "common/UserInfo";
import sections from "sections.json";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import UpgradeToPremium from "common/UpgradeToPremium";
import ProfileSettingsPopup from "common/ProfileSettingsPopup";
import { store } from "state/StoreContext";

import { ReactComponent as LiveModeIcon } from "icons/live-mode.svg";
import axios from "common/ServerConnection";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import { getUserModuleByIdOrCurrent, importModuleApi } from "common/ModulesApi";
import StatusPopup, { PopupStatus } from "common/StatusPopup";

interface State {
    showProfileSettingsPopup: boolean;
    showUpgradeToPremiumPopup: boolean;
    showNotificationsPopup: boolean;
    showPasswordPopup: boolean;
    showEmailPopup: boolean;
    showUserInfoPopup: boolean;
    hovered: boolean;
    emailInfo: { email: string; verified: boolean } | null;
    creationInfo: {
        status: PopupStatus;
        message: string;
    } | null;
}

interface Props {
    centerComponent?: JSX.Element;
    hideUserMenu?: boolean;
    hideAdvancedUserMenu?: boolean;
    hideNotifications?: boolean;
    hideNavigationMenu?: boolean;
    hoverMode?: boolean;
    routes?: { root: LeftPanelItem; requirePermission: string }[];
    additionalUserMenuOptions?: {
        name: string;
        handler: () => void;
    }[];
    shared?: boolean;
}

@observer
class HeaderBar extends Component<Props, State> {
    private importerRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            showProfileSettingsPopup: false,
            showUpgradeToPremiumPopup: false,
            showUserInfoPopup: false,
            showNotificationsPopup: false,
            showPasswordPopup: false,
            showEmailPopup: false,
            hovered: false,
            emailInfo: null,
            creationInfo: null,
        };
        this.importerRef = React.createRef();
        this.getEmail = this.getEmail.bind(this);
        this.renderContents = this.renderContents.bind(this);
    }

    public componentDidMount(): void {
        this.getEmail();
    }

    private getEmail(): void {
        getEmailApi()
            .then((emailInfo) => {
                this.setState({ emailInfo: emailInfo });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <GlobalContext.ObserverConsumer>
                {this.renderContents}
            </GlobalContext.ObserverConsumer>
        );
    }

    importModule(moduleStr: string) {
        importModuleApi(moduleStr, 0, true, [])
            .then((id: number | void) => {
                if (!id) return;

                getUserModuleByIdOrCurrent(id)
                    .then((module) => {
                        this.setState({
                            creationInfo: {
                                status: PopupStatus.Success,
                                message: "Presentation imported succesfully",
                            },
                        });
                        store.presentationStore.appendPresentation(module[0]);
                    })
                    .catch((error) => {
                        this.setState({
                            creationInfo: {
                                status: PopupStatus.Error,
                                message: `Import error: ${String(error)}`,
                            },
                        });
                    });
            })
            .catch((error) => {
                this.setState({
                    creationInfo: {
                        status: PopupStatus.Error,
                        message: `Import error: ${String(error)}`,
                    },
                });
            });
    }

    public renderContents(globalContext: GlobalContextContents): JSX.Element {
        let isUser1 =
            CurrentUser.info != null &&
            CurrentUser.info.type === UserType.User1;

        const isMobile = mobileBreakpoint();
        const size = !isMobile ? 50 : 35;
        let shared = this.props.shared ?? false;

        if (shared) return <></>;

        if (!this.state.hovered && this.props.hoverMode) {
            return (
                // Right hover area in live mode
                <>
                    <div
                        className={styles.root}
                        style={{
                            background: "white",
                            right: !isMobile ? 95 : 25,
                            border: "none",
                            width: size,
                            height: size,
                            boxShadow: "0px 0px 15px 0px rgb(0 0 0 / 18%)",
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        onMouseEnter={() => {
                            this.setState({
                                hovered: true,
                            });
                        }}
                        onMouseLeave={() => {
                            this.setState({
                                hovered: false,
                            });
                        }}
                        onTouchStart={() => {
                            this.setState({
                                hovered: true,
                            });
                            setTimeout(() => {
                                this.setState({
                                    hovered: false,
                                });
                            }, 4000);
                        }}
                    >
                        <LiveModeIcon
                            width={!isMobile ? "32" : "20"}
                            height={!isMobile ? "17" : "11"}
                        />
                    </div>
                </>
            );
        }
        let newMentions = NotificationStore.newMentions();
        // newMentions = newMentions.filter(
        //     (item) => CurrentModulesStore.getModule(item.module_id) != null
        // );
        let hasNewMentions = newMentions.length > 0;
        return (
            <header
                className={styles.root}
                onMouseEnter={() => {
                    this.setState({
                        hovered: true,
                    });
                }}
                onMouseLeave={() => {
                    this.setState({
                        hovered: false,
                    });
                }}
            >
                {!this.props.hideNavigationMenu && (
                    <Dropdown>
                        <DropdownButton
                            id="user-menu-dropdown"
                            className={styles.logoNavButton}
                        >
                            <img
                                src="/dist/img/logo/logo.svg"
                                alt="logo"
                                className={styles.logo}
                            />
                            <ChevronIcon />
                        </DropdownButton>
                        <DropdownMenu arrowPlacement={{ left: "45px" }}>
                            <DropdownItem
                                leftIcon={<HomeIcon />}
                                //rightIcon={
                                //    <ChevronIcon
                                //        className={styles.navChevron}
                                //    />
                                //}
                                onClick={() => goToInternalLink("home.html")}
                            >
                                Home
                            </DropdownItem>
                            {this.props.routes?.find(
                                (route) => route.root.href === "tutorials.html"
                            ) && (
                                <DropdownItem
                                    leftIcon={<TutorialIcon />}
                                    onClick={() =>
                                        goToInternalLink("tutorials.html")
                                    }
                                >
                                    Tutorials
                                </DropdownItem>
                            )}
                            {/*
                             <DropdownItem
                                leftIcon={<CloudIcon />}
                                onClick={() => goToInternalLink("home.html")}
                            >
                                Cloud Storage
                            </DropdownItem>*/}
                            {this.props.routes
                                ?.filter(
                                    (route) =>
                                        route.root.href !== "home.html" &&
                                        route.root.href !== "tutorials.html"
                                )
                                .map((route) => (
                                    <DropdownItem
                                        key={route.root.section}
                                        premium={
                                            globalContext.permissions[
                                                route.requirePermission
                                            ]?.access_type === -1
                                        }
                                        leftIcon={route.root.icon}
                                        onClick={() =>
                                            goToInternalLink(route.root.href)
                                        }
                                    >
                                        {route.root.section}
                                    </DropdownItem>
                                ))}
                        </DropdownMenu>
                    </Dropdown>
                )}
                <div className={styles.titleContainer}>
                    {this.props.centerComponent ?? (
                        <h1 className={styles.title}>
                            {sections._Global.appname.toUpperCase()}
                        </h1>
                    )}

                    <div className={styles.rightSide}>
                        {!this.props.hideNotifications &&
                            !this.props.hoverMode && (
                                <Dropdown>
                                    <DropdownButton
                                        id="user-menu-dropdown"
                                        className={styles.notificationButton}
                                    >
                                        <NotificationIcon />
                                        {hasNewMentions && (
                                            <NotificationDot hideBorder />
                                        )}
                                    </DropdownButton>
                                    <DropdownMenu
                                        arrowPlacement={{ right: "12px" }}
                                        className={styles.notificationDropdown}
                                    >
                                        <NotificationPopup />
                                    </DropdownMenu>
                                </Dropdown>
                            )}
                        {!this.props.hideUserMenu && (
                            <Dropdown>
                                <DropdownButton
                                    id="user-menu-dropdown"
                                    className={styles.avatarButton}
                                >
                                    {currentUser.info != null && (
                                        <Avatar
                                            image={currentUser.info.icon_url}
                                            user={new User(currentUser.info)}
                                        />
                                    )}
                                    <ChevronIcon
                                        className={styles.avatarChevron}
                                    />
                                </DropdownButton>
                                <DropdownMenu
                                    arrowPlacement={{ right: "15px" }}
                                >
                                    <DropdownItem
                                        onClick={() => {
                                            this.setState({
                                                showProfileSettingsPopup: true,
                                            });
                                        }}
                                    >
                                        Profile Settings
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            this.importerRef.current?.click();
                                        }}
                                    >
                                        Import from JSON
                                    </DropdownItem>
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        ref={this.importerRef}
                                        onChange={(event) => {
                                            const files =
                                                event?.target?.files ?? [];
                                            if (files.length !== 0) {
                                                const file = files[0];
                                                const reader = new FileReader();
                                                reader.addEventListener(
                                                    "load",
                                                    (event) => {
                                                        this.importModule(
                                                            event.target
                                                                ?.result as string
                                                        );
                                                    }
                                                );
                                                reader.readAsText(file);
                                            }
                                        }}
                                        id={"import-module-file-selector"}
                                    />
                                    {!this.props.hideAdvancedUserMenu && (
                                        <>
                                            <DropdownItem
                                                onClick={() =>
                                                    goToInternalLink(
                                                        "apps.html"
                                                    )
                                                }
                                            >
                                                Purchased Apps
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    axios
                                                        .get<{
                                                            success: boolean;
                                                            error_msg: string;
                                                            redirect_url?: string;
                                                        }>(
                                                            "/api/create_setup_checkout_session"
                                                        )
                                                        .then((response) => {
                                                            if (
                                                                response.data
                                                                    .success
                                                            ) {
                                                                window.location.href = response.data.redirect_url!;
                                                            } else {
                                                                console.log(
                                                                    response
                                                                        .data
                                                                        .error_msg
                                                                );
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.log(
                                                                String(error)
                                                            );
                                                        });
                                                }}
                                            >
                                                Billing Info
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() =>
                                                    goToInternalLink(
                                                        "/subscriptions.html"
                                                    )
                                                }
                                            >
                                                App Creator Dashboard
                                            </DropdownItem>
                                            <DropdownItem
                                                premium={
                                                    globalContext.permissions[
                                                        "APIs"
                                                    ]?.access_type === -1
                                                }
                                                onClick={() =>
                                                    goToInternalLink(
                                                        "connected_accounts.html"
                                                    )
                                                }
                                            >
                                                API Connections
                                            </DropdownItem>
                                        </>
                                    )}
                                    <DropdownItem
                                        onClick={() =>
                                            this.setState({
                                                showUpgradeToPremiumPopup: true,
                                            })
                                        }
                                    >
                                        Upgrade to Premium
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            Instrumentation.close().then(() => {
                                                goToInternalLink(
                                                    "/logout.html"
                                                );
                                            });
                                        }}
                                    >
                                        Logout
                                    </DropdownItem>
                                    {this.props.additionalUserMenuOptions?.map(
                                        (option, index) => (
                                            <DropdownItem
                                                onClick={option.handler}
                                                key={index}
                                            >
                                                {option.name}
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        )}
                    </div>
                </div>
                {this.state.showProfileSettingsPopup && (
                    <ProfileSettingsPopup
                        emailInfo={this.state.emailInfo!}
                        onClose={() => {
                            this.setState({
                                showProfileSettingsPopup: false,
                            });
                        }}
                        onNewEmail={this.getEmail}
                        hideAdvancedUserMenu={this.props.hideAdvancedUserMenu}
                    />
                )}
                {this.state.showPasswordPopup && (
                    <PasswordChangePopup
                        onClose={() => {
                            this.setState({
                                showPasswordPopup: false,
                            });
                        }}
                    />
                )}
                {this.state.showEmailPopup ? (
                    <EmailPopup
                        emailInfo={this.state.emailInfo!}
                        onClose={() => {
                            this.setState({
                                showEmailPopup: false,
                            });
                        }}
                        onNewEmail={this.getEmail}
                    />
                ) : null}
                {this.state.showUserInfoPopup ? (
                    <UserInfoPopup
                        onClose={() => {
                            this.setState({
                                showUserInfoPopup: false,
                            });
                        }}
                    />
                ) : null}
                {this.state.showUpgradeToPremiumPopup && (
                    <UpgradeToPremium
                        onClose={() => {
                            this.setState({ showUpgradeToPremiumPopup: false });
                        }}
                    />
                )}
                {this.state.creationInfo && (
                    <StatusPopup
                        status={this.state.creationInfo.status}
                        message={this.state.creationInfo.message}
                        onClose={() => {
                            this.setState({ creationInfo: null });
                        }}
                    />
                )}
            </header>
        );
    }
}

export default HeaderBar;
