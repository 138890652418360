import React, { useContext, useState } from "react";
import cx from "classnames";
import styles from "./styles.module.css";
import { addFolder, editFolder, FolderType } from "common/FoldersApi";
import StoreContext from "state/StoreContext";

function applyUnselectable(style: string) {
    return cx(style, "unselectable");
}

interface Props {
    type: FolderType;
    editFolderId: number | null;
    title: string | null;
    onSave: (folderId?: number) => void;
    onError: (errorMessage: string) => void;
    onClose: () => void;
}
const FolderCreator = (props: Props) => {
    const store = useContext(StoreContext);

    const [title, setTitle] = useState<string>(props.title ?? "");
    return (
        <>
            <div className={styles.background} />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={applyUnselectable(styles.title)}>
                        {`${
                            props.editFolderId ? "RENAME" : "NAME"
                        } YOUR FOLDER`}
                    </div>
                    <input
                        className={styles.input}
                        value={title}
                        onChange={(evt) => {
                            let newTitle = evt.target.value;
                            setTitle(newTitle);
                        }}
                    ></input>
                    <div style={{ flexGrow: 1 }} />
                    <div className={styles.buttonsContainer}>
                        <button
                            className={styles.cancelButton}
                            onClick={props.onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className={styles.saveButton}
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                                if (props.editFolderId != null) {
                                    editFolder(
                                        title,
                                        props.editFolderId,
                                        props.type
                                    )
                                        .then(() => {
                                            if (!props.editFolderId) return;
                                            
                                            store.presentationStore.updateFolder(
                                                title,
                                                props.editFolderId,
                                                props.type
                                            );
                                            props.onSave();
                                        })
                                        .catch((error) => {
                                            props.onError(String(error));
                                        });
                                } else {
                                    addFolder(title, props.type)
                                        .then((folderId) => {
                                            store.presentationStore.addFolder(
                                                title,
                                                folderId,
                                                props.type
                                            );
                                            props.onSave(folderId);
                                        })
                                        .catch((error) => {
                                            props.onError(String(error));
                                        });
                                }
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

interface Props {
    onClose: () => void;
}

export default FolderCreator;
