import React from "react";
import cx from "classnames";

import styles from "./TabTitle.module.css";

interface Props {
	title: string;
	icon?: React.ReactNode;
	selected: Boolean;
	className?: string;
}

function TabTitle({ title, icon, selected, className }: Props) {
	return (
		<div className={cx(styles.root, className, { [styles.selected]: selected })}>
			{ icon }
			<span className={styles.title}>{ title }</span>
		</div>
	);
}

export default TabTitle;
