import { observable, computed, makeObservable, action } from "mobx";
import amplitude from "amplitude-js";

import axios from "./ServerConnection";
import UserInfo, { userTypeToString } from "./UserInfo";
import { SocketIOInstance } from "common/ServerConnection";
import moment from "moment";

class CurrentUser {
    @observable public infoState: UserInfo | null = null;

    constructor() {
        makeObservable(this);
        this.update();
    }

    @computed public get info(): UserInfo | null {
        return this.infoState;
    }

    @computed public get isRoot(): boolean {
        // The backend returns -1 in this case
        return this.infoState?.id === -1;
    }

    @action.bound
    private assignUserInfo(userInfo: UserInfo) {
        this.infoState = userInfo;
        this.infoState.user_time_zone =
            this.infoState.user_time_zone || moment.tz.guess();
    }
    private joinMentionsRoom(userName: string) {
        SocketIOInstance?.emit("canvas_mentions_join");
    }

    public async update(joinMentionsRoom = true): Promise<void> {
        axios
            .post<{
                user_info: UserInfo;
            }>("/api/get_user", {})
            .then((response) => {
                if (!response.data.user_info) return;

                this.assignUserInfo(response.data.user_info);
                let instance = amplitude.getInstance();

                instance.setUserId(response.data.user_info.user_name);
                instance.setUserProperties({
                    type: userTypeToString(response.data.user_info.type),
                    first_name: response.data.user_info.first_name,
                    last_name: response.data.user_info.last_name,
                    position: response.data.user_info.position,
                    organization: response.data.user_info.organization,
                    // Answers to the onboarding questions
                    interest_1: response.data.user_info.interest_1,
                    interest_2: response.data.user_info.interest_2,
                    interest_3: response.data.user_info.interest_3,
                    familiar_with_data:
                        response.data.user_info.familiar_with_data,
                });

                if (joinMentionsRoom)
                    this.joinMentionsRoom(response.data.user_info.user_name);
            })
            .catch((error) => {                
                // if got 401 http status code get user name from local storage
                // the other userinfo will be empty
                if (error.response?.status === 401) {

                    // wait for 1 second for localstorage to be updated
                    // this is a temporary solution
                    setTimeout(() => {
                        this.assignUserInfo({
                            id: -1,
                            user_name: localStorage.getItem("username")!,
                            first_name: "",
                            last_name: "",
                            email: localStorage.getItem("username")!,
                            type: 0,
                            position: "",
                            organization: "",
                            icon_url: "",
                            interest_1: "",
                            interest_2: "",
                            interest_3: "",
                            familiar_with_data: "",
                            user_time_zone: moment.tz.guess(),
                        });
                    }, 1000);                    

                    let instance = amplitude.getInstance();
                    instance.setUserId(localStorage.getItem("username"));
                    if (joinMentionsRoom)
                        this.joinMentionsRoom(localStorage.getItem("username")!);
                    }
            });
    }
}

export default new CurrentUser();
