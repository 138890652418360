import React from "react";
import { DendrogramFinding } from "common/Finding";
import DendrogramD3 from "common/graphics/v2/D3Charts/DendrogramD3";

function MainComponent(props: {
    finding: DendrogramFinding;
    onNewFinding?: (finding: DendrogramFinding, updateData?: boolean) => void;
    preview?: boolean;
    columnDragActive?: boolean;
    editable?: boolean;
}) {
    let finding = props.finding;

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <DendrogramD3
                data={finding.content.data}
                additionalVaryByData={finding.content.additionalVaryByData}
                varyByData={finding.content.varyByData}
                groupNames={finding.content.groupNames}
                config={finding.config}
                columnDragActive={props.columnDragActive}
                preview={props.preview}
                editable={props.editable}
                onChangeData={
                    props.onNewFinding
                        ? (data: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.content.data = data;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeConfig={
                    props.onNewFinding
                        ? (config: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.config = config;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
            />
        </div>
    );
}

export { MainComponent };
