import React, { Component, CSSProperties } from "react";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import { getCanvasesApi } from "common/CanvasUserApi";
import customSelectStyles from "common/SelectStyles";
import {
    Canvas,
    CanvasElement,
    CanvasSubmitButton,
    CanvasTextBox,
} from "common/Canvas";
import { observer } from "mobx-react";

interface CanvasIdOption {
    label: string;
    value: number;
}

interface Props {
    linkPopups?: boolean;
    maxLinksCount: number;
    currentModuleId: number | undefined;
    value: CanvasElement | CanvasSubmitButton | CanvasTextBox;
    onChange: <T extends keyof CanvasSubmitButton>(
        changes: Pick<CanvasSubmitButton, T>
    ) => void;
    canWrite: boolean;
    style?: CSSProperties;
}

interface State {
    canvases: Canvas[];
}

@observer
class LinksSelector extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            canvases: [],
        };
    }

    componentDidMount() {
        getCanvasesApi(this.props.currentModuleId)
            .then((canvases) => {
                this.setState({ canvases: canvases });
            })
            .catch((_error) => {});
    }

    render() {
        const field: "linkPopups" | "links" = this.props.linkPopups
            ? "linkPopups"
            : "links";
        let links: number[] = this.props.value[field] ?? [];
        let accessedLinks: number[] = Array.from(links);
        let options: CanvasIdOption[] = this.state.canvases
            .filter((canvas) => !links.includes(canvas.id))
            .map((canvas) => {
                return {
                    value: canvas.id,
                    label: canvas.page_title
                        .concat(" >> ")
                        .concat(canvas.title),
                };
            });
        return (
            <div className="flex-simple-column" style={this.props.style}>
                {accessedLinks.map((link, index) => {
                    let canvasLink = this.state.canvases.find(
                        (canvas) => canvas.id === link
                    );
                    let linkedCanvasName = canvasLink
                        ? canvasLink.page_title
                              .concat(" >> ")
                              .concat(canvasLink.title)
                        : `canvas_id=${link}`;
                    return (
                        <div
                            className="my-row"
                            key={index}
                            style={{ marginTop: "5px" }}
                        >
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={
                                    this.props.linkPopups
                                        ? "ADD POPUPS"
                                        : "ADD LINKS"
                                }
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        flex: 1,
                                        marginLeft: "26px",
                                        height: "38px",
                                    }),
                                    placeholder: (base) => ({
                                        ...base,
                                        color: "#869AAC",
                                    }),
                                }}
                                options={options}
                                value={
                                    link
                                        ? {
                                              label: linkedCanvasName,
                                              value: link,
                                          }
                                        : null
                                }
                                onChange={(newValue) => {
                                    let links: number[] = Array.from(
                                        this.props.value[field] ?? []
                                    );
                                    links[index] = (
                                        newValue as CanvasIdOption
                                    ).value;
                                    this.props.onChange({
                                        [field]: links,
                                    } as { [key in "linkPopups" | "links"]: number[] });
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 5 }}
                            >
                                {link && (
                                    <Button
                                        title={"Remove link"}
                                        className="btn-small-like-select"
                                        style={{
                                            width: "19px",
                                            height: "19px",
                                        }}
                                        onClick={() => {
                                            let links: number[] = Array.from(
                                                this.props.value[field] ?? []
                                            );
                                            links.splice(index, 1);
                                            this.props.onChange({
                                                [field]: links,
                                            } as { [key in "linkPopups" | "links"]: number[] });
                                        }}
                                    >
                                        {"\uFF0D" /* minus */}
                                    </Button>
                                )}
                            </div>
                        </div>
                    );
                })}
                {links.length < this.props.maxLinksCount && (
                    <div className="my-row" style={{ marginTop: "5px" }}>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={
                                this.props.linkPopups
                                    ? "ADD POPUPS"
                                    : "ADD LINKS"
                            }
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    flex: 1,
                                    marginLeft: "26px",
                                    height: "38px",
                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    color: "#869AAC",
                                }),
                            }}
                            options={options}
                            value={null}
                            onChange={(newValue) => {
                                let links: number[] = Array.from(
                                    this.props.value[field] ?? []
                                );
                                links.push((newValue as CanvasIdOption).value);
                                this.props.onChange({
                                    [field]: links,
                                } as { [key in "linkPopups" | "links"]: number[] });
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default LinksSelector;
