import React, { Component } from "react";
import CustomBarChart from "./CustomBarChart";
import { Element } from "react-scroll";
import { LegendElement } from "./LegendElement";
import OutcomesBlockV3 from "./OutcomesBlockV3";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import sections from "sections.json";

// function capitalize(string) {
// 	if (string === null || typeof string === "undefined") return string;
// 	return string.charAt(0).toUpperCase() + string.slice(1);
// }

function getSumItem(clustItem, leftBarKey, rightBarKey) {
	let currentSum = clustItem.reduce((accumulator, value) => {
		return accumulator + value[leftBarKey];
	}, 0);
	let projectedSum = clustItem.reduce((accumulator, value) => {
		return accumulator + value[rightBarKey];
	}, 0);
	let units = clustItem.length > 0 ? clustItem[0].units : "";

	return {
		[leftBarKey]: currentSum,
		[rightBarKey]: projectedSum,
		units: units,
	};
}

class LeversChartsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.clust.length === 0) return prevState;
		let maxChartValue = 0;
		for (let i = 0; i < nextProps.clust.length; ++i)
			for (let j = 0; j < nextProps.clust[i].length; ++j) {
				let data = nextProps.clust[i][j];
				maxChartValue = Math.max(
					maxChartValue,
					Math.max(
						data[nextProps.leftBarKey],
						data[nextProps.rightBarKey]
					)
				);
			}
		let newState = Object.assign({}, prevState);
		newState.maxChartValue = maxChartValue;
		return newState;
	}

	render() {
		let chartWidth =
			this.props.clust.length > 0
				? Math.floor(100 / this.props.clust.length)
				: undefined;
		let clustNames =
			this.props.clustNames ||
			new Array(this.props.clust.length).fill("");
		return (
			<div className="flex-simple-column" style={{ height: "100%" }}>
				<div
					className="my-row center-container"
					style={{ width: "100%" }}
				>
					<LegendElement color={"#FFAB4F"} text={"Current Level"} />
					<LegendElement color={"#05C985"} text={"Projected Level"} />
				</div>

				<div
					className="my-row element"
					style={{
						height: "100%",
						paddingBottom: "5px",
						overflowX: mobileBreakpoint() ? "hidden" : "auto",
						display: mobileBreakpoint() ? "flex" : null,
						flexDirection: mobileBreakpoint() ? "column" : null,
					}}
				>
					{this.props.clust.length !== 0 &&
						clustNames.map((clustName, index) => (
							<div
								className="flex-simple-column"
								key={index}
								style={{
									height: mobileBreakpoint() ? 290 : "100%",
									minWidth: `calc(${chartWidth}% - 5px)`,
									marginRight: "5px",
								}}
							>
								<Element
									className="element"
									style={{
										height: "100%",
										overflowX: "auto",
										backgroundColor: "transparent",
										position: "relative",
										overflowY: "hidden",
										minWidth: `calc(${chartWidth}% - 5px)`,
									}}
								>
									<CustomBarChart
										maxChartValue={this.state.maxChartValue}
										barKeys={[
											this.props.leftBarKey,
											this.props.rightBarKey,
										]}
										barSize={this.props.barSize}
										barColors={["#FFAB4F", "#05C985"]}
										type={this.props.type}
										data={this.props.clust[index]}
										title={clustName}
										watermark={sections._Global?.watermark}
									/>
								</Element>
								<OutcomesBlockV3
									leftTitle={`Current ${clustName} levers total`}
									rightTitle={`Projected ${clustName} levers total`}
									item={getSumItem(
										this.props.clust[index],
										this.props.leftBarKey,
										this.props.rightBarKey
									)}
									leftKey={this.props.leftBarKey}
									rightKey={this.props.rightBarKey}
								/>
							</div>
						))}
				</div>
			</div>
		);
	}
}

export default LeversChartsComponent;