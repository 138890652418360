import { getTextSize } from "common/utilities/MeasureText";
import React, { useRef, CSSProperties } from "react";
import { useDrop } from "react-dnd";
import { clipText } from "./utils";

interface Props {
    name: string;
    width?: number | string;
    color?: string;
    index: number;
    vertical: boolean;
    height?: number | undefined;
    currentEditVariableIndex: number | undefined;
    onDrop?: (
        index: number,
        variableName: string,
        variableIndex: number
    ) => void;
    onChange: (value: string) => void;
    editable?: boolean;
    inputStyle?: CSSProperties;
}

function resizeToFit(value: string, fontSize: number, limitWidth: number): number {
    const textWidth = getTextSize(value, "Roboto", fontSize, "normal").width;
    if (textWidth > limitWidth && fontSize > 8) {
        return resizeToFit(value, fontSize - 1, limitWidth);
    }
    return fontSize;
}

export default function EditableAxisItem(props: Props) {
    const defaultInputWidth = 130;
    let [value, setValue] = React.useState(props.name);
    React.useEffect(() => {
        if (props.name !== value) setValue(props.name);
        // value should not be in dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.name]);
    let currentEdit = props.index === props.currentEditVariableIndex;

    let [editMode, setEditMode] = React.useState(false);

    const ref = useRef<HTMLDivElement | null>(null);
    const [collected, drop] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            props.onDrop?.(
                props.index,
                otherItem.content.variableName,
                otherItem.content.variableIndex
            );
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    if (props.editable) {
        drop(ref);
    }
    let size = props.vertical
        ? {
              height: props.height ?? "100%",
              width: 48,
          }
        : {
              width: props.width ?? "100%",
              height: props.height ?? 48,
          };

    let fontSize = 16;
    let AxisInputWidth: string | number = "100%";
    let inputWidth = defaultInputWidth
    if (props.vertical) {
        fontSize = resizeToFit(value, fontSize, ref.current?.clientHeight ?? defaultInputWidth);
        AxisInputWidth = ref.current?.clientHeight ?? "auto";
        inputWidth = ref.current?.clientHeight ?? defaultInputWidth;
    } else {
        fontSize = resizeToFit(value, fontSize, ref.current?.clientWidth ?? defaultInputWidth);
        inputWidth = ref.current?.clientWidth ?? defaultInputWidth;
    }
    const textWidth = getTextSize(value, "Roboto", fontSize, "normal").width;

    const cutValue = !editMode && props.editable && currentEdit
        ? "Drop variable here"
        : value.charAt(0).toUpperCase() +
            (
                size.width !== "100%" && !props.vertical && fontSize < 10
                    ? `${value.substring(0, 24).slice(1)}...`
                    : fontSize < 10
                        ? clipText(
                            value.slice(1),
                            textWidth,
                            inputWidth - 40,
                            "...",
                        )
                        : value.slice(1)
            )
    return (
        <div
            ref={ref}
            style={{
                ...size,
                border: !props.editable
                    ? "none"
                    : `1px ${
                          (collected.hover && props.onDrop != null) ||
                          (currentEdit && props.editable)
                              ? "dashed"
                              : "solid"
                      } ${
                          collected.hover && props.onDrop != null
                              ? "#36B743"
                              : props.editable && currentEdit
                              ? "#8DB8E3"
                              : "#E9E9E9"
                      }`,
                display: props.vertical ? "grid" : "flex",
                background:
                    collected.hover && props.onDrop != null
                        ? "#F4FBF5"
                        : props.editable && currentEdit
                        ? "#EBF2F9"
                        : "transparent",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
                MozBoxSizing: "border-box",
                WebkitBoxSizing: "border-box",
            }}
            onClick={() => {
                if (props.editable) setEditMode(true);
            }}
        >
            <input
                readOnly={!editMode || !props.editable}
                style={{
                    backgroundColor: "transparent",
                    height: "100%",
                    width: AxisInputWidth,
                    transform: props.vertical ? "rotate(-90deg)" : undefined,
                    fontFamily: "Roboto",
                    fontSize: fontSize,
                    fontWeight: 500,
                    textAlign: "center",
                    color: props.color ?? "#333333",
                    outline: "none",
                    border: "none",
                    ...props.inputStyle,
                }}
                value={editMode ? value : cutValue}
                onChange={(evt) => {
                    let newValue = evt.target.value;
                    setValue(newValue);
                }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                    if (evt.key === "Enter") {
                        props.onChange?.(value);
                        setEditMode(false);
                    }
                }}
                onBlur={() => {
                    props.onChange?.(value);
                    setEditMode(false);
                }}
            ></input>
        </div>
    );
}
