import { Component } from "react";
import { BubbleMapCircleInterface } from "../types";
import * as d3 from "d3";
import { getPlaceBoundaries, getPlacePathData } from "../helpers";
import Leaflet from "leaflet";
import { colorList } from "../../../common/graphics/LineColors";
import countryData from "../../../common/countries.json";
import statesUsData from "../../../common/states_us.json";
import { MapFinding, ChoroplethLevel } from "common/Finding";

interface IProps {
    heatmapData: Array<[number, number, number]>;
    data: { [key: string]: Array<number | string | null> } | null;
    currentTimeValue?: number | string;
    heatMapTime?: Array<number | string | null>;
    minRadius: number;
    maxRadius: number;
    map: Leaflet.Map;
    markerColor: string;
    varyMarkerColorByVariable: boolean;
    boundariesColor: string;
    markerValueToColor?: MapFinding["config"]["markerValueToColor"];
    colorVariableValueToIndex?: Map<string | number | null, number>;
    markerColorVariableIndex?: number | null;
    showBoundaries?: boolean;
    bounderiesIdx?: number;
    level: ChoroplethLevel;
}

export class BubbleMap extends Component<IProps> {
    static defaultProps = {
        minRadius: 5,
        maxRadius: 25,
        color: "#69B3A2",
    };

    // TODO Fix types, make separate functions to use them in both maps to avoir copy/paste
    private circles: BubbleMapCircleInterface[];
    private data: { [key: string]: Array<number | string | null> } | null;
    private boundaries: {
        [key: string]: number[][] | number[][][];
    };
    private d3Objects:
        | {
              svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
              rootG: d3.Selection<SVGGElement, unknown, null, undefined>;
              paths: d3.Selection<
                  SVGPathElement,
                  string | number,
                  SVGGElement,
                  undefined
              > | null;
              circles: d3.Selection<
                  SVGCircleElement,
                  BubbleMapCircleInterface,
                  SVGGElement,
                  unknown
              >;
          }
        | undefined;

    constructor(props: IProps) {
        super(props);

        this.circles = [];
        this.data = {};
        this.boundaries = {};

        this.getCircleX = this.getCircleX.bind(this);
        this.getCircleY = this.getCircleY.bind(this);
        this.getCircleRadius = this.getCircleRadius.bind(this);
        this.getCircleColor = this.getCircleColor.bind(this);
        this.getCircleHidden = this.getCircleHidden.bind(this);
        this.getPathData = this.getPathData.bind(this);

        this.onMapViewReset = this.onMapViewReset.bind(this);

        this.updateDataFromProps();
    }

    public componentDidMount(): void {
        let svg = d3
            .select(this.props.map.getPane("overlayPane")!)
            .select<SVGSVGElement>("svg");
        // In new versions of Leaflet the svg already has a g appended to it
        let rootG = svg.select<SVGGElement>("g").append("g");

        let paths = null;
        if (
            this.props.showBoundaries &&
            this.props.bounderiesIdx !== undefined
        ) {
            paths = this.updatePaths(
                rootG
                    .selectAll<SVGPathElement, (string | number)[]>("path")
                    .data(
                        (this.data?.[this.props.bounderiesIdx] as (
                            | string
                            | number
                        )[]) ?? []
                    )
            );
        }

        let circles = this.updateCircles(
            rootG
                .selectAll<SVGCircleElement, BubbleMapCircleInterface[]>(
                    "circle"
                )
                .data(this.circles)
        );

        this.d3Objects = {
            svg: svg,
            rootG: rootG,
            paths: paths,
            circles: circles,
        };

        this.props.map.on("viewreset", this.onMapViewReset);
        this.props.map.on("zoom", this.onMapViewReset);

        if (this.props.showBoundaries) {
            if (
                this.props.level === "zipcode_us" ||
                this.props.level === "county_us"
            ) {
                this.getBoundaries();
            }
        }
    }

    public componentDidUpdate(prevProps: IProps): void {
        if (
            prevProps.data !== this.props.data ||
            prevProps.showBoundaries !== this.props.showBoundaries ||
            prevProps.heatmapData !== this.props.heatmapData ||
            prevProps.heatMapTime !== this.props.heatMapTime ||
            prevProps.markerColor !== this.props.markerColor ||
            prevProps.boundariesColor !== this.props.boundariesColor ||
            prevProps.varyMarkerColorByVariable !==
                this.props.varyMarkerColorByVariable ||
            prevProps.markerColorVariableIndex !==
                this.props.markerColorVariableIndex ||
            prevProps.colorVariableValueToIndex !==
                this.props.colorVariableValueToIndex ||
            prevProps.markerValueToColor !== this.props.markerValueToColor
        ) {
            this.updateDataFromProps();

            // Update the data
            if (
                this.d3Objects != null &&
                this.props.showBoundaries &&
                this.props.bounderiesIdx !== undefined
            ) {
                this.d3Objects.paths = this.updatePaths(
                    this.d3Objects.rootG
                        .selectAll<SVGPathElement, string | number>("path")
                        .data(
                            (this.data?.[this.props.bounderiesIdx] as (
                                | string
                                | number
                            )[]) ?? []
                        )
                );
                if (
                    this.props.level === "zipcode_us" ||
                    this.props.level === "county_us"
                ) {
                    this.getBoundaries();
                }
            } else if (!this.props.showBoundaries) {
                this.clearBoundaries();
            }

            if (this.d3Objects != null) {
                this.d3Objects.circles = this.updateCircles(
                    this.d3Objects.rootG
                        .selectAll<
                            SVGCircleElement,
                            BubbleMapCircleInterface[]
                        >("circle")
                        .data(this.circles)
                );
            }
            return;
        }
        if (
            prevProps.minRadius !== this.props.minRadius ||
            prevProps.maxRadius !== this.props.maxRadius
        ) {
            this.d3Objects?.circles.attr("r", this.getCircleRadius);
        }
        if (prevProps.currentTimeValue !== this.props.currentTimeValue) {
            this.d3Objects?.circles.attr("hidden", this.getCircleHidden);
        }
    }

    public componentWillUnmount(): void {
        // In new versions of Leaflet the svg already has a g appended to it
        let rootG = this.d3Objects?.rootG;

        rootG
            ?.selectAll<SVGCircleElement, BubbleMapCircleInterface[]>("circle")
            .remove();
        rootG
            ?.selectAll<SVGCircleElement, BubbleMapCircleInterface[]>("path")
            .remove();
    }

    private clearBoundaries(): void {
        let rootG = this.d3Objects?.rootG;
        rootG
            ?.selectAll<SVGCircleElement, BubbleMapCircleInterface[]>("path")
            .remove();
    }

    public render(): null {
        return null;
    }

    private getBoundaries(): void {
        if (!this.data) return;
        if (!this.props.bounderiesIdx) return;

        let uniqueValues = Array.from(
            new Set(
                this.data[this.props.bounderiesIdx]?.map((item) => String(item))
            )
        );

        getPlaceBoundaries(uniqueValues, this.props.level, (data) => {
            this.boundaries = data.boundaries;
            this.d3Objects?.paths?.attr("d", this.getPathData);
        });
    }

    private updateDataFromProps(): void {
        this.circles = [];
        this.data = this.props.data;
        for (let i = 0; i < this.props.heatmapData.length; ++i) {
            let circle: BubbleMapCircleInterface = {
                latLng: Leaflet.latLng(
                    this.props.heatmapData[i][0],
                    this.props.heatmapData[i][1]
                ),
                weight: this.props.heatmapData[i][2] || 0,
            };
            if (
                this.props.heatMapTime != null &&
                this.props.heatMapTime[i] != null
            ) {
                circle.time = this.props.heatMapTime[i] as string | number;
            }
            this.circles.push(circle);
        }
    }

    private getCircleX(circle: BubbleMapCircleInterface): number {
        return this.props.map.latLngToLayerPoint(circle.latLng).x;
    }

    private getCircleY(circle: BubbleMapCircleInterface): number {
        return this.props.map.latLngToLayerPoint(circle.latLng).y;
    }

    private getCircleRadius(circle: BubbleMapCircleInterface): number {
        return (
            (this.props.maxRadius - this.props.minRadius) * circle.weight +
            this.props.minRadius
        );
    }

    private getCircleColor(
        _circle: BubbleMapCircleInterface,
        index: number
    ): string {
        let markerColor: string | undefined;

        let colorVariable =
            this.props.markerColorVariableIndex != null
                ? this.props.data?.[this.props.markerColorVariableIndex]?.[
                      index
                  ]
                : null;
        let customColor =
            colorVariable != null
                ? this.props.markerValueToColor?.[colorVariable]
                : null;
        if (customColor != null) return customColor;
        let colorIndex: number | undefined =
            colorVariable != null
                ? this.props.colorVariableValueToIndex?.get(colorVariable)
                : undefined;
        if (colorIndex != null) {
            markerColor = colorList[colorIndex % colorList.length];
        }
        return markerColor ?? this.props.markerColor;
    }

    private getCircleHidden(circle: BubbleMapCircleInterface): true | null {
        return this.props.currentTimeValue == null ||
            circle.time == null ||
            circle.time <= this.props.currentTimeValue
            ? null
            : true;
    }

    private updateCircles(
        updateSelection: d3.Selection<
            SVGCircleElement,
            BubbleMapCircleInterface,
            SVGGElement,
            unknown
        >
    ): d3.Selection<
        SVGCircleElement,
        BubbleMapCircleInterface,
        SVGGElement,
        unknown
    > {
        updateSelection.exit().remove();
        return updateSelection
            .enter()
            .append("circle")
            .attr("stroke-width", 3)
            .attr("fill-opacity", 0.4)
            .merge(updateSelection)
            .attr("cx", this.getCircleX)
            .attr("cy", this.getCircleY)
            .attr("r", this.getCircleRadius)
            .style("fill", this.getCircleColor)
            .attr("stroke", this.getCircleColor)
            .attr("hidden", this.getCircleHidden);
    }

    private getPathData(data: string | number): string {
        if (data == null || !this.props?.map) {
            return "";
        }

        let dataSource: {
            [key: string]: number[][] | number[][][];
        };
        switch (this.props?.level) {
            case "state_us":
                dataSource = statesUsData;
                break;
            case "zipcode_us":
            case "county_us":
                dataSource = this.boundaries;
                break;
            default:
                dataSource = countryData;
                break;
        }

        let polygon: number[][] | number[][][] | undefined =
            dataSource[String(data)?.toLowerCase()];

        return getPlacePathData(this.props.map, polygon);
    }

    private updatePaths(
        updateSelection: d3.Selection<
            SVGPathElement,
            string | number,
            SVGGElement,
            undefined
        >
    ): d3.Selection<SVGPathElement, string | number, SVGGElement, undefined> {
        updateSelection.exit().remove();
        return updateSelection
            .enter()
            .append("path")
            .merge(updateSelection)
            .attr("d", this.getPathData)
            .attr("fill", this.props.boundariesColor)
            .attr("fill-opacity", 0.3)
            .attr("stroke", this.props.boundariesColor);
    }

    // Update positions on the map
    private onMapViewReset(): void {
        if (this.d3Objects != null) {
            this.d3Objects.circles
                .attr("cx", this.getCircleX)
                .attr("cy", this.getCircleY);

            this.d3Objects.paths?.attr("d", this.getPathData);
        }
    }
}
