import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Avatar from "common/Avatar";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { PresentationType } from "state/models/Presentation";
import User from "state/models/User";
import { exportModuleApi } from "common/ModulesApi";
import styles from "./PresentationCard.module.css";
import { store } from "state/StoreContext";
import { FolderType } from "common/FoldersApi";

interface Props {
    id: number | string;
    title: string;
    thumbnail?: string;
    inOnboarding?: boolean;
    lastUpdatedAt: string;
    owner: User;
    type: PresentationType;
    onClickDelete?: Function;
    onClickCard: Function;
    isKit?: boolean;
    folderId?: number;
    folderType?: FolderType;
}

function downloadExportedFile(text: string, title: string) {
    const element = document.createElement("a");
    const file = new Blob([text], {
        type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${title}.json`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

function PresentationCard({
    folderId,
    folderType,
    id,
    title,
    inOnboarding,
    type,
    thumbnail,
    lastUpdatedAt,
    owner,
    onClickDelete,
    onClickCard,
    isKit,
}: Props) {
    const menuId = `presentation_${id}_${type}_${inOnboarding ?? false}`;
    const { show } = useContextMenu({
        id: menuId,
    });

    function handleContextMenu(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        show(event);
    }

    function deleteCallback(event: React.MouseEvent) {
        event.stopPropagation();

        if (onClickDelete) {
            onClickDelete(id);
        }
    }

    function cardClick() {
        onClickCard(id);
    }

    const renderTooltip = (props: any) => {
        return (
            <Tooltip id={`tooltip-${id}`} placement="top" {...props}>
                {title}
            </Tooltip>
        );
    };


    return (
        <>
            <div
                className={styles.root}
                onClick={cardClick}
                onContextMenu={handleContextMenu}
            >
                {isKit && (
                    <div className={styles.badge}>BUILD-YOUR-APP KIT </div>
                )}

                {thumbnail ? (
                    <>
                        <img
                            src={thumbnail}
                            alt="presentation front page"
                            className={styles.image}
                        />
                        <div className={styles.thumbnailForeground}/>
                    </>
                ) : (
                    <div className={styles.thumbnailPlaceholder}>
                        <h3 className={styles.titleThumbnail}>{title}</h3>
                    </div>
                )}
                <div className={styles.content}>
                    <div className={styles.leftSide}>
                        <div>
                            <OverlayTrigger
                                delay={{ show: 200, hide: 300 }}
                                overlay={renderTooltip}
                            >
                                <h5 className={styles.title}>{title}</h5>
                            </OverlayTrigger>
                            <p className={styles.updatedAt}>{lastUpdatedAt}</p>
                        </div>
                    </div>
                    <Avatar inGridView user={owner} className={styles.avatar} />
                </div>

                {onClickDelete && (
                    <button
                        type="button"
                        onClick={deleteCallback}
                        className={styles.deleteButton}
                    >
                        <img
                            src="/dist/img/insights/cross_big.png"
                            alt="delete"
                            className={styles.deleteIcon}
                        />
                    </button>
                )}
            </div>
            {type !== PresentationType.App && (
                <Menu id={menuId}>
                    <Item
                        onClick={() => {
                            exportModuleApi(
                                { id: id as number },
                                type === PresentationType.Template
                            )
                                .then((moduleStr: string) => {
                                    downloadExportedFile(moduleStr, title);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }}
                    >
                        Export
                    </Item>
                    {folderId != null && folderType != null && (
                        <Item
                            onClick={() => {
                               store.presentationStore.removePresentationFromFolder(id as number, folderId, folderType)
                            }}
                        >
                            Remove from folder
                        </Item>
                    )}
                </Menu>
            )}
        </>
    );
}

export default PresentationCard;
