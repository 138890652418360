import React, { useState } from "react";
import Switch from "react-switch";
import Accordion from "react-bootstrap/Accordion";
import cx from "classnames";

import { CanvasElement, CanvasProgressElement } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import ConditionalFormatting from "../../common/ConditionalFormatting";

import styles from "./Settings.module.css";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

interface Props {
    node: CanvasProgressElement;
    nodeLinkOptions: NodeLinkOption[];
    onChange: (
        changes: Partial<CanvasElement | CanvasProgressElement>,
        commit?: boolean
    ) => void;
}

enum CollapsableMenuOptions {
    None = 0,
    ConditionalFormatting = 1,
}

function Settings({ node, nodeLinkOptions, onChange }: Props) {
    const [progressElementThickness, setProgressElementThickness] =
        React.useState(String(node.thickness ?? 2));
    const [currentOpenedMenu, setCurrentOpenedMenu] = useState(
        CollapsableMenuOptions.None
    );

    return (
        <div className={styles.root}>
            <div className={styles.otherFields}>
                <span className={styles.inputLabel}>Thickness / Height</span>
                <input
                    className="like-select"
                    style={{
                        marginTop: "10px",
                        width: "100%",
                    }}
                    placeholder=""
                    onChange={(e) => {
                        const value: string = e.target.value;
                        if (!/^[0-9]*\.?[0-9]*$/.test(value)) {
                            e.preventDefault();
                        } else {
                            setProgressElementThickness(value);
                            let thickness: number = Number(value);
                            if (thickness > 0) {
                                onChange({ thickness: thickness });
                            }
                        }
                    }}
                    value={progressElementThickness}
                />
                <div className={styles.switchField}>
                    <Switch
                        onChange={() => {
                            onChange({
                                linear: !(
                                    (node as CanvasProgressElement).linear ??
                                    false
                                ),
                            });
                        }}
                        checked={node.linear ?? false}
                        width={26}
                        height={13}
                        offColor="#D1D1D1"
                        onColor="#20293C"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#FFF"
                        onHandleColor="#1F8EFA"
                    />
                    <span className={styles.switchLabel}>Linear</span>
                </div>
                <Accordion
                    activeKey={String(currentOpenedMenu)}
                    onSelect={(event: any) => {
                    setCurrentOpenedMenu(Number(event));
                    }}
                >
                    <Accordion.Toggle
                    eventKey={String(CollapsableMenuOptions.ConditionalFormatting)}
                    className={styles.editMenuAdvancedOption}
                    >
                    Conditional Formatting
                    <ChevronIcon
                        className={cx(styles.editMenuAdvancedOptionIcon, {
                        [styles.chevronOpen]:
                            currentOpenedMenu ===
                            CollapsableMenuOptions.ConditionalFormatting,
                        })}
                    />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                    eventKey={String(CollapsableMenuOptions.ConditionalFormatting)}
                    >
                    <ConditionalFormatting
                        node={node}
                        nodeLinkOptions={nodeLinkOptions}
                        onChange={onChange}
                    />
                    </Accordion.Collapse>
                </Accordion>
            </div>
        </div>
    );
}

export default Settings;
