import React from "react";
import Select, { components } from "react-select";

import { CanvasElement, CanvasProgressElement, CanvasTextBox, isTextBox } from "common/Canvas";
import ColorOptions from "common/ColorOptions";
import { NodeLinkOption } from "common/Conditions";
import StatusExpressionsSelector, { StatusFillOption, statusFillOptions, FillType } from "./StatusExpressionsSelector";

import styles from "./ConditionalFormatting.module.css";

interface StringOption {
  label: string;
  value: string;
}

interface StringNullOption {
  label: string;
  value: string | null;
}

const colorOptions: ReadonlyArray<StringNullOption> = [
  ...ColorOptions,
  { label: "Automatic", value: null },
];

interface Props {
  node: CanvasElement | CanvasTextBox | CanvasProgressElement;
  nodeLinkOptions: NodeLinkOption[];
  onChange: (changes: Partial<CanvasElement>) => void;
}

function ColorSelectOption(props: any) {
  return (
    <div
      className={styles.colorSelectOption}
      style={{
        background:
          props.isSelected
            ? "#2684FF"
            : (props.isFocused ? "#EAF0FA" : "transparent")
      }}
    >
      <div
        className={styles.colorSample}
        style={{
          background: props.value ?? "linear-gradient(126.57deg, rgba(51, 51, 51, 0.8) 80.38%, rgba(51, 51, 51, 0.64) 123.5%)",
          border: props.value === "transparent" ? "1px solid #E9E9E9" : "none",
        }}
      />
      <components.Option {...props} />
    </div>
  );
}

function ConditionalFormatting({ node, nodeLinkOptions, onChange }: Props) {
  return (
    <div>
      <div className={styles.formatSelectors}>
        <Select
          options={colorOptions}
          value={
            node.statusColor ?? (
              node.statusExpressions ? colorOptions[4] : colorOptions[0]
            )
          }
          onChange={(newValue) => {
            const newStatusColorValue: StringOption = newValue as StringOption;
            if (!newStatusColorValue.value) {
              onChange({
                statusColor: null,
                statusExpressions: [
                  {
                    subexpressions: [
                      {
                        operation: "",
                        value: "",
                      },
                    ],
                    color: colorOptions[0] as StringOption,
                  },
                ],
              });
            } else {
              onChange({
                statusColor: newStatusColorValue,
                statusExpressions: undefined,
              });
            }
          }}
          styles={{
            container: (provided) => ({
              ...provided,
              flex: "1 1 auto"
            }),
            control: (provided) => ({
              ...provided,
              borderRadius: "4px",
              minWidth: "140px"
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            option: (provided) => ({
              ...provided,
              background: "transparent",
              "&:active": {
                background: "transparent",
              }
            })
          }}
          components={{
            Option: ColorSelectOption
          }}
        />
        {!node.statusExpressions && (
          <>
            <Select
              options={statusFillOptions}
              value={
                node.statusFont
                  ? statusFillOptions[2]
                  : node.statusFill
                    ? statusFillOptions[1]
                    : statusFillOptions[0]
              }
              onChange={(newValue) => {
                onChange({
                  statusFill:
                      (newValue as StatusFillOption)
                          .value ===
                      FillType.Fill,
                  statusFont:
                      (newValue as StatusFillOption)
                          .value ===
                      FillType.Font,
                });
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderRadius: "4px",
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
              }}
            />
            {!node.statusFill && !node.statusFont && (
              <>
                <input
                  className={styles.textInput}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!/^[0-9]*$/.test(value)) {
                      e.preventDefault();
                    } else {
                      onChange({
                        statusBorderWidth: Number(value),
                      });
                    }
                  }}
                  value={
                    node.statusBorderWidth === 0
                      ? ""
                      : (node.statusBorderWidth ?? 1).toString()
                  }
                />
                <span
                  style={{
                    fontFamily: "Arial",
                    fontSize: "17px",
                    width: "15px",
                    marginLeft: "5px",
                  }}
                >
                  px
                </span>
              </>
            )}
          </>
        )}
      </div>
      {node.statusExpressions && (
        <StatusExpressionsSelector
          node={node}
          statusExpressions={node.statusExpressions}
          selectOutput={isTextBox(node)}
          nodeLinkOptions={nodeLinkOptions}
          onChange={(statusExpressions) => {
            onChange({
              statusExpressions: statusExpressions,
            });
          }}
        />
      )}
    </div>
  );
}

export default ConditionalFormatting;
