import React from "react";
import { Text } from "recharts";

import "common/styles/div_span.css";

interface Props {
	viewBox?: {
		x: number;
		y: number;
		height: number;
		width: number;
	};
	editable?: boolean;
	fontColor: string;

	name?: string;
	onEdit: (boundingRect: {
		x: number;
		y: number;
		width: number;
		height: number;
	}) => void;
}

const height = 48;
const width = 75;

export default function YAxisItem(props: Props) {
	return (
		<g
			transform={`translate(0, ${
				(props.viewBox!.height + props.viewBox!.x) / 2 - height / 2
			})`}
			width={width}
			height={height}
			onDoubleClick={(evt) => {
				if (props.editable) {
					props.onEdit(evt.currentTarget.getBoundingClientRect());
				}
			}}
		>
			<rect
				stroke={"#E9E9E9"}
				fill={"transparent"}
				width={width}
				height={height}
			></rect>

			<Text
				width={width}
				x={width / 2}
				y={height / 2}
				dominantBaseline="middle"
				textAnchor="middle"
				fontFamily="Roboto"
				fontWeight={500}
				fontSize={14}
				fill={props.fontColor}
				className="no-selection"
			>
				{props.name ?? "Y Axis"}
			</Text>
		</g>
	);
}