import React, { useState } from "react";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { mainStyle } from "common/MainStyle";
import IconContainer from "common/IconContainer";
import { HorizontalBarsAxisTick } from "common/graphics/AxesTicks";
import sections from "sections.json";
import { LeversOutcomePredictionFinding } from "common/Finding";
import { TooltipStyles } from "common/graphics/TooltipStyles";
import CustomTooltip, { useTooltipContext } from "./CustomTooltip";

interface Props {
    data: LeversOutcomePredictionFinding["content"]["data"];
    config: LeversOutcomePredictionFinding["config"];
    preview: boolean;
}

// export default function RegressionVariablesBarChart(props: Props) {
function RegressionVariablesBarChartContent(props: Props) {
    const { openTooltip, closeTooltip } = useTooltipContext();
    let bars = React.useMemo(() => {
        let innerBars: {
            name: string;
            significance1: number;
            significance2: number | undefined;
            coefficient1: number;
            coefficient2: number | undefined;
        }[] = [];
        if (props.data.length === 0) return [];
        let regressionBarsLength = props.data[0].regressionBars.length;

        for (let j = 0; j < regressionBarsLength; ++j) {
            let regressionBar = props.data[0].regressionBars[j];
            if (!regressionBar.show) continue;
            let bar: any = {};
            for (let i = 0; i < props.data.length; ++i) {
                let regressionBar = props.data[i].regressionBars[j];
                bar.name = regressionBar.name;
                bar[`significance${i + 1}`] = regressionBar.significance;
                bar[`coefficient${i + 1}`] = regressionBar.coefficient;
            }
            innerBars.push(bar as typeof innerBars[number]);
        }
        return innerBars;
    }, [props.data]);
    if (props.data.length === 0) return null;

    let {
        axesLinesColor,
        ticksColor,
        tooltipColor,
        tooltipFontSize,
        ticksSize,
    } = props.config;
    const tooltipStyles = TooltipStyles(tooltipColor, tooltipFontSize);

    let colorItems = [
        ["#09e398", "#FFAB4F", "#fb5858"],
        ["#057e55", "#d6760c", "#a11313"],
    ];

    let maxYaxisWidth = 60;
    let lengths = bars.map((item) => item.name.length);
    maxYaxisWidth = Math.max(maxYaxisWidth, Math.max(...lengths) * 10);
    let barSize = 20;
    let barCategoryGap = 25;
    let barGap = 4;
    let chartSize =
        (barSize * props.data.length +
            barGap * props.data.length +
            barCategoryGap) *
        bars.length;
    const createStarsScale = (args: {
        index: number;
        payload: any;
        x: number;
        y: number;
        width: number;
        height: number;
        value: number;
    }) => {
        let { index, x, y, width, value } = args;
        if (
            !isNaN(bars[index].significance1) &&
            !isNaN(x) &&
            !isNaN(y) &&
            !isNaN(width)
        ) {
            let sig = 100 - bars[index].significance1 * 100;
            if (sig <= 100 && sig >= 99) {
                sig = 3;
            } else if (sig < 99 && sig >= 95) {
                sig = 2;
            } else if (sig < 95 && sig >= 90) {
                sig = 1;
            } else {
                sig = 0;
            }
            let stars = [];
            let isOutOfYAxis = false;
            
            while (sig >= 1) {
                const starPosition = value > 0
                    ? x + width - (sig + 1) * 20
                    : x + 7 + width + sig * 20

                if (!isOutOfYAxis && starPosition < maxYaxisWidth) {
                    isOutOfYAxis = true;
                }
                
                stars.push(
                    <svg
                        key={index + "-" + sig}
                        x={
                            isOutOfYAxis
                                ? (maxYaxisWidth - 15) + sig * 20
                                : starPosition
                        }
                        y={y + 2}
                        style={{
                            fill: mainStyle.getPropertyValue(
                                "--graphs-legends-text-color"
                            ),
                        }}
                        width="10"
                        height="10"
                        viewBox="0 0 24 24"
                    >
                        <IconContainer type="Star" />
                    </svg>
                );
                sig -= 1;
            }
            return <>{stars}</>;
        } else {
            return <span>&nbsp;</span>;
        }
    };

    const tooltipContent = (e: any) => {
        const coefficient1Value = e["coefficient1"];
        const coefficient2Value = e["coefficient2"];
        let coefficient1FormattedValue =
            typeof coefficient1Value === "number"
                ? coefficient1Value.toFixed(3)
                : coefficient1Value;
        let coefficient2FormattedValue =
            typeof coefficient2Value === "number"
                ? coefficient2Value.toFixed(3)
                : coefficient2Value;
        return (
            <div
                style={tooltipStyles.itemStyle}
            >
                <p
                    style={{ margin: 0 }}
                >
                    {e.name}
                </p>
                <span
                    style={{ fontWeight: 400 }}
                >
                    coefficient1 : {coefficient1FormattedValue}
                </span>
                {coefficient2Value && <span
                    style={{ fontWeight: 400 }}
                >
                    coefficient2 : {coefficient2FormattedValue}
                </span>}
            </div>
        );
    };
    
    return (
            <div style={{ width: "100%", height: "100%" }}>
                <ResponsiveContainer width="100%" height={chartSize}>
                    <BarChart
                        height={chartSize}
                        data={bars}
                        barSize={barSize}
                        barGap={barGap}
                        barCategoryGap={barCategoryGap}
                        layout="vertical"
                        margin={{
                            top: 5,
                            bottom: 5,
                        }}
                    >
                        {sections._Global.watermark != null && (
                            <text
                                x="50%"
                                y="150"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--content-secondary-text-color"
                                )}
                                fontSize={"4rem"}
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {sections._Global.watermark}
                            </text>
                        )}
                        <XAxis
                            orientation="top"
                            type="number"
                            tickLine={false}
                            axisLine={false}
                            tick={
                                <HorizontalBarsAxisTick
                                    fontSize={ticksSize}
                                    axesColor={ticksColor}
                                    dx={0}
                                    dy={0}
                                />
                            }
                        />
                        <YAxis
                            type="category"
                            width={maxYaxisWidth}
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            tick={
                                <HorizontalBarsAxisTick
                                    fontSize={ticksSize}
                                    axesColor={ticksColor}
                                    dx={0}
                                    dy={-5}
                                />
                            }
                        />
                        <CartesianGrid
                            stroke={
                                axesLinesColor ??
                                mainStyle.getPropertyValue("--graphs-stroke-color")
                            }
                            horizontal={false}
                            strokeWidth={1}
                        />
                        <Bar
                            dataKey="coefficient1"
                            label={createStarsScale}
                            onMouseEnter={e => {
                                openTooltip({
                                    content: tooltipContent(e),
                                    style: {
                                        ...tooltipStyles.contentStyle,
                                        padding: "10px",
                                    },
                                  })
                                }
                            }
                            onMouseLeave={() => closeTooltip()}
                        >
                            {bars.map((entry, index) => {
                                var color = props.preview
                                    ? "#D1D1D1"
                                    : colorItems[0][1];

                                return <Cell key={`cell-${index}`} fill={color} />;
                            })}
                        </Bar>
                        <Bar dataKey="coefficient2" label={createStarsScale}>
                            {bars.map((entry, index) => {
                                var color = props.preview
                                    ? "#D1D1D1"
                                    : colorItems[1][1];

                                return <Cell key={`cell-${index}`} fill={color} />;
                            })}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
    );
}

export default function RegressionVariablesBarChart(props: Props) {
    return (
        <CustomTooltip>
            <RegressionVariablesBarChartContent {...props} />
        </CustomTooltip>
    )
}
