
import React, { useMemo } from "react";
import { markerClusterGroupWrapperSupportedTypes,
         visibleMarkersLimit } from "../consts";
import { HoverInfo } from "../types";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { createClusterCustomIcon } from "./CreateClusterCustomIcon";
import { mainStyle } from "common/MainStyle";
import { Flags, MapFinding } from "common/Finding";
import { MapTooltipDisplayModeOption, MapVariableOption } from "common/Canvas";
import { VariableOption } from "common/Variables";
import { MarkerWrapper } from "./MarkerWrapper";

export interface IProps {
    coordinates: MapFinding["config"]["coordinates"];
    markerValueToColor?: MapFinding["config"]["markerValueToColor"];
    usesCoordinates: MapFinding["config"]["usesCoordinates"];
    displayMode: MapTooltipDisplayModeOption | undefined;
    tooltipVariables?: (MapVariableOption | null)[];
    markerIcon?: string | null; // base64 image
    markerLogoIcon?: string | null; // base64 image
    markerColor?: string | null;
    markerClusterColor?: string | null;
    markerBorderColor?: string | null;
    markerColorVariableIndex?: number | null;
    varyMarkerColorByVariable?: boolean; // default: false
    heatMap?: VariableOption | null;
    timeVariableIndex?: number;
    currentTimeValue?: number | string;
    lockMap: boolean;
    minZoomLevel: number;
    data: { [key: string]: Array<number | string | null> } | null;
    colorVariableValueToIndex?: Map<string | number | null, number>;
    scale: number;
    onShowPopup: (index: number, tooltipMapLevel: number) => void;
    onShowHoverTooltip: (
        index: number | null,
        tooltipMapLevel: number,
        point?: {
            x: number;
            y: number;
        }
    ) => void;
    mapId: string;
    findingType: string;
    tooltipMapLevel: number;
    hoverInfo: HoverInfo | null;
    zoomLevel: number | undefined;
    flags: Flags | undefined;
  }
// This component is a wrapper for MarkerClusterGroup that
// renders clusters on a map
export const MarkerClusterGroupWrapper = React.forwardRef(
  (props: IProps, ref) => {
      const markerGroup = useMemo(() => {
          if (props.data == null) return null;
          let latIndex: string | undefined;
          let lonIndex: string | undefined;
          let timeIndex: string | undefined;
          if (props.usesCoordinates) {
              latIndex = props.coordinates?.latitude?.value.toString();
              lonIndex = props.coordinates?.longitude?.value.toString();
          } else {
              latIndex = "%lat";
              lonIndex = "%lon";
          }

          if (props.timeVariableIndex != null) {
              timeIndex = props.timeVariableIndex.toString();
          }
          // we prepare a marker for each coordinate and
          // wrap markers into MarkerClusterGroup
          let markers: Array<JSX.Element> = [];
          if (
              markerClusterGroupWrapperSupportedTypes.has(
                  props.findingType
              ) &&
              latIndex != null &&
              lonIndex != null
          ) {
              let len = Math.min(
                  visibleMarkersLimit,
                  props.data[latIndex]?.length ?? 0
              );
              for (let index = 0; index < len; ++index) {
                  if (
                      timeIndex == null ||
                      props.currentTimeValue == null ||
                      props.data[timeIndex][index] == null ||
                      props.data[timeIndex][index]! <= props.currentTimeValue
                  ) {
                      markers.push(
                          <MarkerWrapper
                              {...props}
                              key={index}
                              index={index}
                              latIndex={latIndex!}
                              lonIndex={lonIndex!}
                          />
                      );
                  }
              }
          }
          return (
              <MarkerClusterGroup
                  ref={ref}
                  onClick={(evt: any) => {
                      if (props.lockMap) {
                          if (evt.layer._group._spiderfied != null) {
                              evt.layer.unspiderfy();
                          } else {
                              evt.layer.spiderfy();
                          }
                      }
                  }}
                  spiderfyDistanceMultiplier={3}
                  key={Date.now()}
                  maxClusterRadius={30}
                  zoomToBoundsOnClick={!props.lockMap}
                  showCoverageOnHover={false}
                  removeOutsideVisibleBounds
                  disableClusteringAtZoom={
                      props.findingType === "maps_pins" ? 17 : 0
                  }
                  iconCreateFunction={(cluster: any) => createClusterCustomIcon(
                      cluster,
                      props?.markerClusterColor ?? mainStyle.getPropertyValue("--marker-cluster-color")
                  )}
                  chunkedLoading
              >
                  {markers}
              </MarkerClusterGroup>
          );
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
          props.data,
          props.coordinates,
          props.displayMode,
          props.tooltipVariables,
          props.markerIcon,
          props.markerLogoIcon,
          props.markerColor,
          props.markerColorVariableIndex,
          props.varyMarkerColorByVariable,
          props.markerValueToColor,
          props.heatMap,
          props.lockMap,
          props.onShowPopup,
          props.onShowHoverTooltip,
          props.colorVariableValueToIndex,
          props.usesCoordinates,
          props.scale,
          props.minZoomLevel,
          props.currentTimeValue,
          props.timeVariableIndex,
          props.findingType,
          ref,
      ]);
      return markerGroup;
  }
);