import React, { Component } from "react";
import OutsideAlerter from "./OutsideAlerter";
import sections from "sections.json";
import UserInfoForm from "./UserInfoForm";


interface State {
}

interface Props {
    onClose: () => void;
    section?: keyof typeof sections;
}

class UserInfoPopup extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        let height = 500;
        let width = 800;
        return (
            <OutsideAlerter
                onReject={() => {
                    this.props.onClose();
                }}
            >
                <div
                    className="dashboard-rect"
                    style={{
                        overflow: "visible",
                        position: "absolute",
                        border: "1px solid black",
                        top: (window.innerHeight - height) / 2,
                        alignItems: "center",
                        cursor: "pointer",
                        left: (window.innerWidth - width) / 2,
                        width: width,
                        zIndex: 100000000,
                    }}
                >
                    <UserInfoForm {...this.props} />
                </div>
            </OutsideAlerter>
        );
    }
}

export default UserInfoPopup;
