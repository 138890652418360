import React, { useEffect, useRef } from "react";

export interface ScrollPositionInfo {
    inTheBeginning: Boolean;
    inTheEnd: Boolean;
}

export interface ScrollData {
    scrollWidth: number,
    offsetWidth: number,
    scrollLeft: number,
}

export interface ScrollInfo extends ScrollData, ScrollPositionInfo {}

export function useHorizontalScroll(callback: Function) {
    const elRef = useRef<any>();
    useEffect(() => {
        const elem = elRef.current;
        if (elem) {
            const onWheel = (e: any) => {
                if (e.deltaY == 0) return;
                e.preventDefault();
                elem.scrollBy(e.deltaY, 0);
                const scrollData: ScrollData = gatherScrollData(elem)
                const scrollPosition = checkScrollPosition(scrollData);
                callback?.({
                    ...scrollData,
                    ...scrollPosition,
                })
            };
            elem.addEventListener("wheel", onWheel);
            return () => elem.removeEventListener("wheel", onWheel);
        }
    }, []);
    return elRef;
}

export function gatherScrollData(elem: HTMLDivElement): ScrollData {
    return {
        scrollWidth: elem.scrollWidth,
        offsetWidth: elem.offsetWidth,
        scrollLeft: elem.scrollLeft,
    }
}

export function checkScrollPosition(data: ScrollData): ScrollPositionInfo {
    const {
        scrollWidth,
        offsetWidth,
        scrollLeft,
    } = data;
    return {
        inTheBeginning: scrollLeft === 0,
        inTheEnd: offsetWidth + scrollLeft === scrollWidth,
    }
}