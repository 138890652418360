import React from "react";
import { observer } from "mobx-react";
import FlowChartProps from "../FlowChartProps";
import {
    CanvasDropdownSelector,
    CanvasElementType,
    CanvasFilter,
    InnerCanvasChanges,
    isDropdownSelector,
    isFilter,
    isSpreadSheetGrid,
    ItemMetadata,
    NodePosition,
    NodeSize,
    NodeValue,
} from "common/Canvas";
import CanvasDropdownSelectorView from "../CanvasDropdownSelectorView";
import elements from "common/CanvasElements";
import {
    SearchComponentOption,
    SearchComponentValue,
} from "common/SearchComponent";
import CanvasInteractionComponent from "../CanvasInteractionComponent";
import DropdownNodeMenu from "../DropdownNodeMenu";
import {
    identifierFontSize,
    dropdownSelectorDefaultLiveHeight,
    dropdownSelectorDefaultWidth,
    filterDefaultLiveHeight,
    filterDefaultWidth,
} from "../../Constants";
import Portal from "common/Portal";
import DynamicDropdown from "../NewDashboards/DynamicDropdown";
import { ConditionsSelector } from "common/Conditions";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import Variables from "common/Variables";
import { DynamicOptionType } from "common/DynamicOptions";
import {
    mobileAndTabletBreakpoint,
    openDelayedNodeMenuAfterTouch,
} from "common/utilities/UIResponsiveManager";
import cx from "classnames";
import styles from "../Moveable.module.css";
import {
    makeMoveable,
    DraggableProps,
    ResizableProps,
    RotatableProps,
    Rotatable,
    Draggable,
    Resizable,
    OnDrag,
} from "react-moveable";
import { getTransformList } from "common/utilities/parseTransformString";
import OutsideAlerter from "common/OutsideAlerter";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "modules/canvas_page/Snap";
import { getRawDataApi } from "common/DataApi";
import PopupData from "common/PopupData";

interface CommonDropdownProps extends FlowChartProps {
    scale: number;
    onContextMenu: (
        evt: React.MouseEvent,
        elementInfo: { id: string | number; type: CanvasElementType }
    ) => void;
    currentModuleId: number | undefined;
}

interface DropdownProps extends CommonDropdownProps {
    nodeId: number;
    index: number;
    moduleTitle: string;
    htmlElementsRootRef: any;
    currentModuleId: number | undefined;
    selectedMetadata?: ItemMetadata[];
}

interface InnerState {
    drag: boolean;
    focused: boolean;
    dropdownOpened: boolean;
    rawData?: any;
    resizing?: {
        old: { position: NodePosition; size: NodeSize };
        new: { position: NodePosition; size: NodeSize };
    };
}

const Moveable = makeMoveable<DraggableProps & ResizableProps & RotatableProps>(
    [Draggable, Resizable, Rotatable]
);

function searchComponentOptionsToNode(
    options: SearchComponentOption[] | SearchComponentOption | null
): Pick<CanvasDropdownSelector, "metric" | "value" | "additionalOutputs"> {
    let result: Pick<
        CanvasDropdownSelector,
        "metric" | "value" | "additionalOutputs"
    > = {
        metric: "",
        value: NaN,
        additionalOutputs: [],
    };
    if (options == null) return result;
    if (!Array.isArray(options)) {
        options = [options];
    } else {
        result.value = [];
    }
    for (let i = 0; i < options.length; ++i) {
        let option = options[i];
        if (i === 0) {
            if (options.length <= 1) {
                result.metric = option.label;
                result.value = option.value ?? NaN;
            }
        }
        if (Array.isArray(options)) {
            result.metric = option.label;
            if (Array.isArray(result.value))
                (result.value as SearchComponentValue[]).push(option.value);
        }
    }
    return result;
}

function nodeToSearchComponentOptions(node: CanvasDropdownSelector) {
    let options: SearchComponentOption[] = [];

    if (node.metric) {
        let value: NodeValue | null = node.value;
        if (typeof value === "number" && Number.isNaN(value)) value = null;
        options.push({ label: node.metric, value: value });
    }

    for (let output of node.additionalOutputs ?? []) {
        let value: NodeValue | null = output.value;
        if (typeof value === "number" && Number.isNaN(value)) value = null;
        options.push({ label: output.metric, value: value });
    }
    if (node.additionalOutputs == null) return options?.[0] ?? null;

    return options;
}

@observer
class DropdownSelector extends CanvasInteractionComponent<
    DropdownProps,
    InnerState
> {
    private moveableRef = React.createRef<any>();
    private innerRef = React.createRef<HTMLDivElement>();

    constructor(props: DropdownProps) {
        super(props);
        this.state = {

            drag: false,
            focused: false,
            dropdownOpened: false,
            resizing: undefined,
        };
    }


    componentDidMount(): void {
        let node = this.props.canvasTreeStore.canvasTreeState.get(
            this.props.nodeId
        )! as CanvasDropdownSelector | CanvasFilter;
        let innerRef = this.innerRef.current;
        if (innerRef != null) {
            let rootDataTestId = `dropdownSelector-${this.props.index}`;
            innerRef.setAttribute("type", "canvasTreeState");
            if (node.groupId != null)
                innerRef.setAttribute("groupId", node.groupId);
            else {
                innerRef.removeAttribute("groupId");
            }
            innerRef.setAttribute("id", String(node.id));
            innerRef.setAttribute("data-test-id", rootDataTestId);
        }

        if(node.update?.active)
            this.getTableData(node)
    }

    componentDidUpdate(prev: DropdownProps) {
        let node = this.props.canvasTreeStore.canvasTreeState.get(
            this.props.nodeId
        )! as CanvasDropdownSelector | CanvasFilter;

        if (prev.selectedMetadata !== this.props.selectedMetadata) {
            let innerRef = this.innerRef.current;
            if (innerRef != null) {
                if (node.groupId != null)
                    innerRef.setAttribute("groupId", node.groupId);
                else {
                    innerRef.removeAttribute("groupId");
                }
            }
        }
        this.moveableRef.current?.updateRect();

        let key = node.id;
        let dynamicOption = node.dynamicOption;
        let dashboard =
            dynamicOption != null
                ? this.props.canvasTreeStore.dashboardsState.get(
                      dynamicOption.dashboardId
                  )
                : null;
        if (dynamicOption != null && dashboard == null) {
            this.props.canvasTreeStore.updateNodeAction<
                CanvasDropdownSelector | CanvasFilter,
                "dynamicOption"
            >(
                key,
                {
                    dynamicOption: null,
                },
                false
            );
        }
    }

    getTableData(node: any) {
        if(node.update?.active && node.update.contextId) {
            getRawDataApi(
                {
                    label: "",
                    value: [],
                    optimized: false,
                    data_table_idx: node.dataScopeOption?.value,
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                PopupData.data?.row_id ?[PopupData.data?.row_id] : undefined,
                true
            ).then((res) => {             
                const value = res.currentLevels[node.variableOption.label][0] as string ?? '';
                let result = searchComponentOptionsToNode(
                    [{
                        label: res.rowId[0]+'',
                        value: value
                    }]
                );
                this.props.canvasTreeStore.updateNodeAction<
                    CanvasDropdownSelector,
                    | keyof typeof result
                    | "allValues"
                >(node.id, {
                    ...result,
                    allValues: false,
                    metric: value,
                });
            });
        }
    };

    public render(): JSX.Element {
        // Table and variable selectors were moved into advanced edit mode
        const {
            canvasViewMode,
            mobileViewWasEdited,
            slideWidthRatio,
        } = this.props.canvasTreeStore;
        let node = this.props.canvasTreeStore.canvasTreeState.get(
            this.props.nodeId
        )! as CanvasDropdownSelector | CanvasFilter;
        node = {
            ...node,
            nodePosition:
                this.state.resizing?.new?.position || node.nodePosition,
            nodeSize: this.state.resizing?.new?.size || node.nodeSize,
        };
        let allowMultipleSelection =
            isDropdownSelector(node) && node.multipleSelection;
        let allowAllValues = isDropdownSelector(node) && node.allowAllValues;
        let filter =
            isFilter(node) ||
            node.dynamicOption?.type === DynamicOptionType.Filter;

        let nodeSize = {
            width: filter ? filterDefaultWidth : dropdownSelectorDefaultWidth,
            height: filter
                ? filterDefaultLiveHeight
                : dropdownSelectorDefaultLiveHeight,
        };
        let dropdownNodeSize = node.nodeSize ?? {
            desktop: nodeSize,
            mobile: nodeSize,
        };

        const selected =
            this.props.selectedMetadata?.length === 1 &&
            this.props.selectedMetadata?.[0]?.type === "canvasTreeState" &&
            this.props.selectedMetadata?.[0]?.id === node.id;

        let position = {
            x: node.nodePosition[canvasViewMode].x * this.props.scale || 0,
            y: node.nodePosition[canvasViewMode].y * this.props.scale || 0,
        };

        let key = node.id;
        let dropdownSize = {
            height: dropdownNodeSize[canvasViewMode].height * this.props.scale,
            width: dropdownNodeSize[canvasViewMode].width * this.props.scale,
        };
        let rootDataTestId = `dropdownSelector-${this.props.index}`;
        // Grid with this ID might not exist
        // https://eisengardai.atlassian.net/browse/EIS-331?focusedCommentId=11866
        const grid =
            this.props.editedNode?.gridId != null
                ? this.props.canvasTreeStore.gridsState.get(
                      this.props.editedNode.gridId
                  )
                : null;
        const editingFormula: boolean =
            this.props.editedNode != null &&
            (this.props.editedNode.focus ?? false) &&
            grid != null &&
            isSpreadSheetGrid(grid) &&
            this.props.editedNode.id !== node.id &&
            this.props.editedNode.metric.startsWith("=");
        let dynamicOption = node.dynamicOption;
        let dashboard =
            dynamicOption != null
                ? this.props.canvasTreeStore.dashboardsState.get(
                      dynamicOption.dashboardId
                  )
                : null;
        return (
            <OutsideAlerter
                onReject={() => {
                    this.setState({
                        focused: false,
                    });
                }}
            >
                <>
                    <div
                        data-test-id={`${rootDataTestId}-menucontainer`}
                        style={{
                            zIndex: node.zIndex ?? 50,
                            // transform: `scale(${this.props.scale})`,
                            // transformOrigin: "left top",
                            position: "absolute",
                            left: position.x,
                            top: position.y,
                            width: dropdownNodeSize[canvasViewMode].width + 15,
                            height: dropdownNodeSize[canvasViewMode].height,
                        }}
                        onClick={() => {
                            this.setState({ focused: true });
                        }}
                    >
                        {!this.state.drag &&
                            (this.state.focused ||
                                this.state.dropdownOpened ||
                                // The hamburger menu has to appear as soon as the node gets created
                                selected) &&
                            this.props.canWrite &&
                            !this.props.live && (
                                <Portal
                                    rootNode={this.props.htmlElementsRootRef}
                                >
                                    <div
                                        data-test-id={`${rootDataTestId}-menu`}
                                    >
                                        <DropdownNodeMenu
                                            {...this.props}
                                            rootMenuTestId={`${rootDataTestId}-menu`}
                                            onToggle={(open) => {
                                                this.setState({
                                                    dropdownOpened: open,
                                                });
                                            }}
                                            disableAdvancedEdit={
                                                dynamicOption != null &&
                                                dashboard != null
                                            }
                                            scale={this.props.scale}
                                            customStyle={{
                                                zIndex: 999,
                                                position: "absolute",
                                                left:
                                                    position.x +
                                                    dropdownNodeSize[
                                                        canvasViewMode
                                                    ].width *
                                                        this.props.scale,
                                                top: position.y,
                                                width: 15 * this.props.scale,
                                                height: 20 * this.props.scale,
                                            }}
                                            canEdit={this.props.canWrite}
                                            canDelete={this.props.canWrite}
                                            canLock={false}
                                            node={node}
                                            element={elements.dropdownInput}
                                            showDeleteElementWithDatasetPopup={
                                                this.props
                                                    .showDeleteElementWithDatasetPopup
                                            }
                                        />
                                    </div>
                                </Portal>
                            )}
                    </div>

                    <div
                        style={{
                            width: dropdownSize.width,
                            height: dropdownSize.height,
                            top: position.y,
                            left: position.x,
                            position: "absolute",
                            zIndex: node.zIndex ?? 50,
                            boxShadow: !node.borderShadow
                                ? "none"
                                : "0 6px 13px 0 rgba(21, 33, 56, 0.53)",
                        }}
                        className="selectable-by-pointer"
                        ref={this.innerRef}
                        onContextMenu={(evt: React.MouseEvent) => {
                            this.props.onContextMenu(evt, {
                                id: node.id,
                                type: "canvasTreeState",
                            });
                        }}
                        onClick={() => {
                            this.setState({ focused: true });
                        }}
                        onTouchStart={() => {
                            openDelayedNodeMenuAfterTouch(
                                () => {
                                    this.setState({ focused: true });
                                },
                                () => {
                                    this.setState({ focused: false });
                                }
                            );
                        }}
                    >
                        <div
                            data-test-id={`${rootDataTestId}-selectorcontainer`}
                            onClick={(evt) => {
                                if (this.state.drag) {
                                    this.setState({ drag: false });
                                }
                                if (editingFormula) {
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                    this.props.onSimpleEditInsertOuterId(node);
                                }
                            }}
                            onContextMenu={(evt: React.MouseEvent) => {
                                this.props.onContextMenu(evt, {
                                    id: node.id,
                                    type: "canvasTreeState",
                                });
                            }}
                            style={{
                                transform: `scale(${this.props.scale})`,
                                transformOrigin: "left top",
                                height: dropdownNodeSize[canvasViewMode].height,
                                width: dropdownNodeSize[canvasViewMode].width,
                            }}
                        >
                            <div
                                className="flex-simple-column"
                                style={{
                                    overflow: "auto",
                                    height:
                                        dropdownNodeSize[canvasViewMode].height,
                                    width:
                                        dropdownNodeSize[canvasViewMode].width,
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <span
                                        style={{
                                            //    position: "absolute",
                                            marginTop: 5,
                                            marginLeft: 5,
                                            fontSize: identifierFontSize,
                                            color: this.props.live
                                                ? "transparent"
                                                : "var(--secondary-text-color)",
                                        }}
                                        className="content-regular-text"
                                    >
                                        {node.outerId}
                                    </span>
                                    {dashboard && dynamicOption ? (
                                        <DynamicDropdown
                                            currentModuleId={
                                                this.props.currentModuleId
                                            }
                                            dashboard={dashboard}
                                            dynamicOption={dynamicOption}
                                            canvasTreeStore={
                                                this.props.canvasTreeStore
                                            }
                                            canWrite={this.props.canWrite}
                                            rootDataTestId={rootDataTestId}
                                            node={
                                                node as CanvasDropdownSelector
                                            }
                                            theme={
                                                (node as CanvasDropdownSelector)
                                                    .dropdownStyle
                                            }
                                        />
                                    ) : isDropdownSelector(node) ? (
                                        <CanvasDropdownSelectorView
                                            onSelectAllValues={() => {
                                                this.props.canvasTreeStore.updateNodeAction<
                                                    CanvasDropdownSelector,
                                                    "allValues"
                                                >(key, { allValues: true });
                                            }}
                                            allowAllValues={allowAllValues}
                                            variableMode={node.variableMode}
                                            allValues={node.allValues}
                                            rootDataTestId={rootDataTestId}
                                            currentModuleId={
                                                this.props.currentModuleId
                                            }
                                            conditions={
                                                node.conditions ?? undefined
                                            }
                                            allowMultipleSelection={
                                                allowMultipleSelection
                                            }
                                            live={this.props.live}
                                            frozen={editingFormula}
                                            tableOption={node.tableOption}
                                            variableOption={node.variableOption}
                                            dataScopeOption={
                                                node.dataScopeOption
                                            }
                                            value={nodeToSearchComponentOptions(
                                                node
                                            )}
                                            onChangeValue={(
                                                value:
                                                    | SearchComponentOption
                                                    | SearchComponentOption[]
                                                    | null,
                                                allValues?: boolean
                                            ) => {
                                                this.trackNewPerformance(
                                                    elements.dropdownInput
                                                );
                                                let result = searchComponentOptionsToNode(
                                                    value
                                                );

                                                this.props.canvasTreeStore.updateNodeAction<
                                                    CanvasDropdownSelector,
                                                    | keyof typeof result
                                                    | "allValues"
                                                >(key, {
                                                    ...result,
                                                    allValues:
                                                        allValues ?? false,
                                                });
                                            }}
                                            node={
                                                node as CanvasDropdownSelector
                                            }
                                            theme={
                                                (node as CanvasDropdownSelector)
                                                    .dropdownStyle
                                            }
                                            scale={this.props.scale}
                                        />
                                    ) : (
                                        <ConditionsSelector
                                            single
                                            style={{
                                                marginTop: 0,
                                                alignItems: "center",
                                            }}
                                            key={node.dataScopeOption?.value}
                                            currentModuleId={
                                                this.props.currentModuleId
                                            }
                                            small
                                            dataScopeId={
                                                node.dataScopeOption?.value
                                            }
                                            value={
                                                node.condition
                                                    ? [node.condition]
                                                    : ConditionsSelector.defaultValue
                                            }
                                            title={""}
                                            onChange={(value) => {
                                                this.props.canvasTreeStore.updateNodeAction<
                                                    CanvasFilter,
                                                    "condition"
                                                >(key, { condition: value[0] });
                                            }}
                                            allVariables={
                                                Variables(
                                                    node.dataScopeOption?.value,
                                                    this.props.currentModuleId
                                                ).dataVariables
                                            }
                                            {...ConditionSelectorStyles}
                                            titleStyle={{ display: "none" }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {!this.props.live && this.props.canWrite && (
                        <Moveable
                            className={cx(
                                styles.moveable,
                                !selected && styles.moveableNotSelected
                            )}
                            checkInput={true}
                            key={node.id}
                            ref={this.moveableRef}
                            draggable={!this.props.live || this.props.canWrite}
                            throttleDrag={0}
                            onDrag={({
                                target,
                                beforeDelta,
                                beforeDist,
                                left,
                                top,
                                right,
                                bottom,
                                delta,
                                dist,
                                transform,
                                clientX,
                                clientY,
                            }: OnDrag) => {
                                target!.style.left = `${left}px`;
                                target!.style.top = `${top}px`;
                                if (!this.state.drag)
                                    this.setState({ drag: true });
                                let nearestPoints = this.props.onRebuildSnapLine(
                                    {
                                        x: left,
                                        y: top,
                                        width: dropdownSize.width,
                                        height: dropdownSize.height,
                                    },
                                    {
                                        type: "canvasTreeState",
                                        id: node.id,
                                        groupId: node.groupId,
                                    }
                                );
                                let newPosition = snapElementToPoints(
                                    target.clientWidth,
                                    target.clientHeight,
                                    nearestPoints
                                );
                                if (newPosition.x != null) {
                                    target!.style.left = `${newPosition.x}px`;
                                }
                                if (newPosition.y != null) {
                                    target!.style.top = `${newPosition.y}px`;
                                }
                                this.props.onUpdateSelectionBounds?.();
                            }}
                            onDragEnd={({ target }) => {
                                if (this.state.drag) {
                                    this.trackNewPerformance(
                                        elements.dropdownInput
                                    );
                                    this.props.onDeleteSnapLine();
                                    let x =
                                        parseFloat(target.style.left) /
                                        this.props.scale;

                                    let y =
                                        parseFloat(target.style.top) /
                                        this.props.scale;

                                    let deltaX =
                                        x - node.nodePosition[canvasViewMode].x;
                                    let deltaY =
                                        y - node.nodePosition[canvasViewMode].y;

                                    const newNodePosition = {
                                        ...node.nodePosition,
                                        [canvasViewMode]: {
                                            x,
                                            y,
                                        },
                                    };

                                    if (!mobileViewWasEdited) {
                                        if (canvasViewMode === "desktop")
                                            newNodePosition["mobile"] = {
                                                x: (x * slideWidthRatio) / 1.1,
                                                y,
                                            };
                                        else
                                            this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                    }

                                    let changes: InnerCanvasChanges = {};
                                    this.props.canvasTreeStore.updateNodeAction(
                                        node.id,
                                        {
                                            nodePosition: newNodePosition,
                                        },
                                        true,
                                        false,
                                        false,
                                        changes
                                    );
                                    this.props.canvasTreeStore.updateCanvasSizeAction(
                                        {
                                            x: x,
                                            y: y,
                                            ...dropdownSize,
                                        }
                                    );

                                    this.props.onMoveGroupSelection(
                                        deltaX,
                                        deltaY,
                                        {
                                            id: node.id,
                                            type: "canvasTreeState",
                                            groupId: node.groupId,
                                        },
                                        false,
                                        changes
                                    );
                                    this.props.canvasTreeStore.saveChangesAction(
                                        changes,
                                        true,
                                        true,
                                        false,
                                        this.props.canvasTreeStore.backgroundsState.toJSON(),
                                        BackgroundMode.Update,
                                        false
                                    );
                                    this.setState({ focused: true });
                                    if (mobileAndTabletBreakpoint()) {
                                        openDelayedNodeMenuAfterTouch(
                                            () => {
                                                this.setState({
                                                    focused: true,
                                                    drag: false,
                                                });
                                            },
                                            () => {
                                                this.setState({
                                                    focused: false,
                                                });
                                            }
                                        );
                                    } else {
                                        this.setState({ drag: false });
                                    }
                                }
                            }}
                            target={this.innerRef}
                            resizable={
                                (!this.props.live || this.props.canWrite) &&
                                selected
                            }
                            rotatable={false}
                            onResize={(e) => {
                                e.target.style.width = `${e.width}px`;
                                e.target.style.height = `${e.height}px`;
                                e.target.style.transform = e.afterTransform;
                            }}
                            onResizeEnd={({ target }) => {
                                this.trackNewPerformance(
                                    elements.dropdownInput
                                );
                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;

                                let transfromOptions = getTransformList(
                                    target.style.transform
                                );
                                let translatePosition = (
                                    transfromOptions["translate"] ?? "0px, 0px"
                                )
                                    .split(",")
                                    .map(
                                        (item) =>
                                            parseFloat(item) / this.props.scale
                                    );

                                const x =
                                    parseFloat(target.style.left) /
                                        this.props.scale +
                                    translatePosition[0];
                                const y =
                                    parseFloat(target.style.top) /
                                        this.props.scale +
                                    translatePosition[1];
                                let newSize = {
                                    x,
                                    y,
                                    nodePosition: {
                                        ...node.nodePosition,
                                        [canvasViewMode]: {
                                            x,
                                            y,
                                        },
                                    },
                                    nodeSize: {
                                        ...dropdownNodeSize,
                                        [canvasViewMode]: {
                                            width:
                                                parseFloat(target.style.width) /
                                                this.props.scale,
                                            height:
                                                parseFloat(
                                                    target.style.height
                                                ) / this.props.scale,
                                        },
                                    },
                                };

                                target.style.transform = "none";

                                if (!mobileViewWasEdited) {
                                    this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                }

                                this.props.canvasTreeStore.updateNodeAction<
                                    CanvasDropdownSelector | CanvasFilter,
                                    keyof typeof newSize
                                >(node.id, {
                                    ...newSize,
                                });

                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        ...newSize.nodePosition[canvasViewMode],
                                        ...newSize.nodeSize[canvasViewMode],
                                    }
                                );
                                this.props.onResize();
                            }}
                        ></Moveable>
                    )}
                </>
            </OutsideAlerter>
        );
    }
}

export default observer(function DropdownSelectors(props: CommonDropdownProps) {
    let dropdownSelectors: JSX.Element[] = [];
    for (let node of props.canvasTreeStore.canvasTreeState.values()) {
        if (
            node.nodeIsHidden &&
            node.nodeIsHidden[props.canvasTreeStore.canvasViewMode]
        )
            continue;

        if (isDropdownSelector(node) || isFilter(node)) {
            dropdownSelectors.push(
                <DropdownSelector
                    index={dropdownSelectors.length + 1}
                    key={node.id}
                    nodeId={node.id}
                    {...props}
                />
            );
        }
    }
    return <>{dropdownSelectors}</>;
});
