import React from "react";
import { Button } from "react-bootstrap";
import Popup from "reactjs-popup";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import axios from "common/ServerConnection";
import dataScopes from "common/DataScopes";
import { Permission } from "common/Permissions";
import { stringSessionId } from "common/SessionId";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
                popupDataScope: null, // null or DataScope
            };
        }

        renderDataScopes() {
            if (!this.state.expanded) return null;

            let dataScopeElement = (
                <div
                    className="flex-simple-column"
                    style={{ background: "#3f4b62" }}
                >
                    {dataScopes.dataScopes
                        .filter((item) => typeof item.id === "number")
                        .map((item, index) => {
                            return (
                                <div className="my-row" key={index}>
                                    <span
                                        style={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                        }}
                                        className="content-regular-text"
                                    >
                                        {item.name}
                                    </span>
                                    {item.permissionType ===
                                        Permission.ReadWrite && (
                                        <div style={{ alignSelf: "center" }}>
                                            <Button
                                                className="close"
                                                onClick={() => {
                                                    this.setState({
                                                        popupDataScope: item,
                                                    });
                                                }}
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
            );
            return (
                <>
                    <div />
                    {dataScopeElement}
                    <div />
                    <div />
                </>
            );
        }

        deleteDataScope(item) {
            this.setStepState(StepState.running);
            axios
                .post("/api/e/delete_data_tables", {
                    data_table_idx: item.id,
                    update_id: stringSessionId(),
                })
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        dataScopes
                            .update()
                            .then(() => {
                                this.setStepState(StepState.notRunning);
                            })
                            .catch((error) => {
                                this.setStepState(
                                    StepState.error,
                                    String(error)
                                );
                            });
                    }
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        render() {
            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        disabled={this.props.disabled}
                        className="btn-processing-tab"
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState({ expanded: !this.state.expanded });
                        }}
                    >
                        <span className="content-regular-text">Datasets</span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    {this.state.expanded ? (
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            onClick={() => {
                                this.setStepState(StepState.running);
                                dataScopes
                                    .update()
                                    .then(() => {
                                        this.setStepState(StepState.notRunning);
                                    })
                                    .catch((error) => {
                                        this.setStepState(
                                            StepState.error,
                                            String(error)
                                        );
                                    });
                            }}
                            style={{
                                fontFamily: "Roboto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: "25px",
                                width: "100%",
                                minWidth: "110px",
                            }}
                        >
                            REFRESH
                        </Button>
                    ) : (
                        <div style={{ minWidth: "110px" }} />
                    )}
                    <div>{this.state.expanded && this.renderStepState()}</div>
                    {this.renderDataScopes()}

                    {this.state.popupDataScope != null && (
                        <Popup
                            arrow={true}
                            contentStyle={{
                                width: 350,
                                border: "none",
                                backgroundColor: "transparent",
                            }}
                            open={true}
                            onClose={() => {
                                this.setState({ popupDataScope: null });
                            }}
                            closeOnDocumentClick
                        >
                            <div
                                className="dashboard-rect flex-simple-column"
                                style={{
                                    display: "flex",
                                    overflow: "visible",
                                    boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                                    borderRadius: 0,
                                    width: 350,
                                    padding: "20px",
                                }}
                            >
                                <span className="popup-regular-text">
                                    Delete {this.state.popupDataScope.name}?
                                    This action cannot be undone.
                                </span>
                                <div style={{ height: "1em" }} />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <Button
                                        className="btn btn-lg btn-primary my-primary"
                                        onClick={() => {
                                            this.deleteDataScope(
                                                this.state.popupDataScope
                                            );
                                            this.setState({
                                                popupDataScope: null,
                                            });
                                        }}
                                    >
                                        Delete
                                    </Button>
                                    <div
                                        style={{
                                            flexGrow: 1,
                                        }}
                                    />
                                    <Button
                                        className="btn btn-lg btn-primary my-primary"
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                popupDataScope: null,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Popup>
                    )}
                </>
            );
        }
    }
);
export { MainComponent };
export let requirePermission = "DeleteDataSets";