import React, { Component } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceDot,
    ReferenceLine,
} from "recharts";
// @ts-ignore: no types for DefaultTooltipContent
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import CustomizedAxisTick from "../../CustomizedAxisTick";
import axios from "common/ServerConnection";
import { mainStyle } from "common/MainStyle";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import HoldOutPredictionParametersChart from "./HoldOutPredictionParametersChart";
import { HorizontalBarsAxisTick } from "common/graphics/AxesTicks";
import { formatValue } from "common/utilities/FormatValue";
import { LegendElement } from "../../LegendElement";
import PredictBlock from "../../PredictBlock";
import { Button, ButtonGroup } from "react-bootstrap";
import { indexOfBiggest } from "common/utilities/NumericUtils";
import remoteModuleId from "common/remoteModuleId";
import _ from "lodash";
import { HoldOutPredictionFinding } from "common/Finding";
import { ClustItem } from "./ClustItem";
import styles from "./HoldOutPredictionChart.module.css";
import { TooltipStyles } from "common/graphics/TooltipStyles";
type NonNullable<T> = Exclude<T, null | undefined>; // Remove null and undefined from T

const defaultHeight = 525;
const defaultWidth = 576;

enum TooltipType {
    hidden = 0,
    regular = 1,
    lowerInterwal = 2,
    higherInterval = 3,
    meanPredicted = 4,
    meanTrue = 5,
}

enum ScaleButtonDirection {
    hidden = 1,
    left = 2,
    right = 3,
    both = 4,
}

const clustDiff = (
    clust1: ClustItem[][],
    clust2: ClustItem[][]
): Set<string> => {
    const diffs = new Set<string>();
    if (clust1 != null && clust2 != null) {
        clust1.forEach((clustItem, clustIndex) => {
            clustItem.forEach((item, index) => {
                let value = item.value;
                let otherValue = clust2[clustIndex]?.[index]?.value;
                if (value !== otherValue) diffs.add(item.name);
            });
        });
    }
    return diffs;
};

const CustomTooltip = (props: {
    type: TooltipType;
    wrapperStyle?: React.CSSProperties;
    item: HoldOutPredictionFinding["content"]["data"]["predictedBars"][number];
}) => {
    if (props.type === TooltipType.hidden) return null;
    if (props.item != null) {
        let newPayload;
        if (props.type === TooltipType.lowerInterwal) {
            newPayload = [
                {
                    name: "Lower Prediction Interval : ",
                    value: props.item.confidence_interval[0],
                },
            ];
        }
        if (props.type === TooltipType.higherInterval) {
            newPayload = [
                {
                    name: "Higher Prediction Interval : ",
                    value: props.item.confidence_interval[1],
                },
            ];
        }
        if (props.type === TooltipType.meanPredicted) {
            newPayload = [
                {
                    name: "Predicted Mean : ",
                    value: props.item.mean_predicted,
                },
                {
                    name: "Click to Recenter Scale.",
                },
            ];
        }
        if (props.type === TooltipType.meanTrue) {
            newPayload = [
                {
                    name: "True Mean : ",
                    value: props.item.mean_true,
                },
            ];
        }
        return (
            <DefaultTooltipContent
                {...props}
                active
                separator={""}
                label={undefined}
                payload={newPayload}
                wrapperStyle={{ ...props.wrapperStyle, visibility: "visible" }}
            />
        );
    }
    return <DefaultTooltipContent {...props} />;
};

interface Props {
    data: HoldOutPredictionFinding["content"]["data"];
    config: HoldOutPredictionFinding["config"];
    preview: boolean;
    regressionInfo: NonNullable<
        HoldOutPredictionFinding["content"]["regressionInfo"]
    >;
    groupNames?: string[] | null;
    onChangeData?: (
        data: HoldOutPredictionFinding["content"]["data"],
        updateFinding?: boolean
    ) => void;
    onChangeConfig?: (
        config: HoldOutPredictionFinding["config"],
        updateFinding?: boolean
    ) => void;
}

interface HoldOutState {
    independentVariables: string[];
    continuousVariables: string[];
    categoricalVariables: string[];
    ivNames: string[];
    independentVariablesFixed: boolean[];
    clust: ClustItem[][];
    categoriesClust: ClustItem[][];
    hiddenContinuousVariables: string[];
    hiddenCategoricalVariables: string[];
    hiddenClust: ClustItem[][];
    hiddenCategoriesClust: ClustItem[][];
}

interface State extends HoldOutState {
    tooltipType: TooltipType;
    errorMessages: { [key: number]: string | undefined };
}

class HoldOutPredictionBarChart extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            tooltipType: TooltipType.hidden,
            errorMessages: {},
            independentVariables: [],
            continuousVariables: [],
            categoricalVariables: [],
            ivNames: [],
            independentVariablesFixed: [],
            clust: [],
            categoriesClust: [],
            hiddenContinuousVariables: [],
            hiddenCategoricalVariables: [],
            hiddenClust: [],
            hiddenCategoriesClust: [],
            ...this.initialState(),
        };
        this.updateUserValues = this.updateUserValues.bind(this);
    }
    onShowIntervalsChange(showIntervals: boolean, clustIndex: number) {
        let newData = { ...this.props.data };
        newData.predictedBars[clustIndex].show_intervals = showIntervals;
        this.props.onChangeData?.(newData);
    }
    onCenterItem(clustIndex: number, chartInterval: number[]) {
        let newData = { ...this.props.data };
        newData.predictedBars[clustIndex].chart_interval = chartInterval;
        this.props.onChangeData?.(newData);
    }
    onConfidenceLevelChange(confidenceLevel: number, clustIndex: number) {
        let newData = { ...this.props.data };
        newData.predictedBars[clustIndex].confidence_level = confidenceLevel;
        this.props.onChangeData?.(newData);
    }
    onNewResult(
        update: Partial<
            HoldOutPredictionFinding["content"]["data"]["predictedBars"][number]
        >,
        clustIndex: number
    ) {
        let newData = { ...this.props.data };
        newData.predictedBars[clustIndex] = {
            ...newData.predictedBars[clustIndex],
            ...update,
        };
        this.props.onChangeData?.(newData);
    }
    onNewUserValues(
        userValues: HoldOutPredictionFinding["content"]["data"]["userValues"],
        diff: Set<string>
    ) {
        let newData = { ...this.props.data };
        newData.userValues = userValues;
        this.props.onChangeData?.(newData);
    }

    buildSignificanceLevelBlock(clustIndex: number) {
        let confidenceLevels = this.props.data.predictedBars.map((item) => {
            if (item.confidence_level == null)
                return this.props.config.confidenceLevel;
            return item.confidence_level;
        });
        let showIntervals = this.props.data.predictedBars.map((item) => {
            return item.show_intervals;
        });
        let buttonFirstSelected =
            confidenceLevels[clustIndex] === 99 && showIntervals[clustIndex];
        let buttonSecondSelected =
            confidenceLevels[clustIndex] === 95 && showIntervals[clustIndex];
        let buttonThirdSelected =
            confidenceLevels[clustIndex] === 90 && showIntervals[clustIndex];
        let buttonForthSelected = !showIntervals[clustIndex];
        return (
            <div
                className="my-row"
                style={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <span className={styles.significanceLabel}>
                    {`Significance Level`}
                </span>
                <div
                    className={styles.significanceGroup}
                    style={{
                        marginLeft: "10px",
                        padding: "5px",
                    }}
                >
                    <ButtonGroup>
                        <Button
                            className={
                                buttonFirstSelected
                                    ? styles.btnSmallSignificanceSelected
                                    : styles.btnSmallSignificance
                            }
                            onClick={() => {
                                this.onConfidenceLevelChange(99, clustIndex);
                                if (!showIntervals[clustIndex])
                                    this.onShowIntervalsChange(
                                        true,
                                        clustIndex
                                    );
                            }}
                        >
                            99%
                        </Button>
                        <Button
                            className={
                                buttonSecondSelected
                                    ? styles.btnSmallSignificanceSelected
                                    : styles.btnSmallSignificance
                            }
                            onClick={() => {
                                this.onConfidenceLevelChange(95, clustIndex);

                                if (!showIntervals[clustIndex])
                                    this.onShowIntervalsChange(
                                        true,
                                        clustIndex
                                    );
                            }}
                        >
                            95%
                        </Button>
                        <Button
                            className={
                                buttonThirdSelected
                                    ? styles.btnSmallSignificanceSelected
                                    : styles.btnSmallSignificance
                            }
                            onClick={() => {
                                this.onConfidenceLevelChange(90, clustIndex);

                                if (!showIntervals[clustIndex])
                                    this.onShowIntervalsChange(
                                        true,
                                        clustIndex
                                    );
                            }}
                        >
                            90%
                        </Button>
                        <Button
                            className={
                                buttonForthSelected
                                    ? styles.btnSmallSignificanceSelected
                                    : styles.btnSmallSignificance
                            }
                            onClick={() => {
                                if (showIntervals[clustIndex])
                                    this.onShowIntervalsChange(
                                        false,
                                        clustIndex
                                    );
                            }}
                        >
                            Off
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        );
    }
    static pointInInterval(point: number, interval: number[]) {
        return point >= interval[0] && point <= interval[1];
    }
    calculateChartInterval(
        item: HoldOutPredictionFinding["content"]["data"]["predictedBars"][number]
    ) {
        let minChartValue = Math.min(
            item.confidence_interval[0],
            item.confidence_interval[1],
            item.mean_predicted
        );
        let maxChartValue = Math.max(
            item.confidence_interval[0],
            item.confidence_interval[1],
            item.mean_predicted
        );
        if (this.props.config.showTrueValue) {
            minChartValue = Math.min(minChartValue, item.mean_true);
            maxChartValue = Math.max(maxChartValue, item.mean_true);
        }
        let maxInterval = item.max_interval;
        if (maxInterval != null) {
            maxChartValue = Math.max(
                maxChartValue,
                item.mean_predicted + maxInterval
            );
            minChartValue = Math.min(
                minChartValue,
                item.mean_predicted - maxInterval
            );
        }
        maxChartValue =
            maxChartValue + Math.abs(maxChartValue - minChartValue) * 0.1;
        minChartValue =
            minChartValue - Math.abs(maxChartValue - minChartValue) * 0.1;
        return [minChartValue, maxChartValue];
    }

    runPredict(clustIndex: number) {
        const urlParams = new URLSearchParams(window.location.search);
        let currentModuleId = urlParams.get("current_module_id");
        let errorMessages = Object.assign({}, this.state.errorMessages);
        errorMessages[clustIndex] = undefined;
        this.setState({ errorMessages: errorMessages });
        let ivNames = this.state.ivNames;
        let independentVariablesFixed = this.state.independentVariablesFixed;
        let item = this.props.data.predictedBars[clustIndex];
        let request: {
            data_table_idx: string | number;
            model_id: number;
            module_id: number | string | null;
            parameters: number[];
            confidence_level: number;
            one_hot_encode: boolean;
        } = {
            data_table_idx: this.props.config.dataScope!.value,
            model_id: item.model_id,
            module_id:
                currentModuleId != null
                    ? Number(currentModuleId)
                    : remoteModuleId,
            parameters: [],
            confidence_level:
                (item.confidence_level ?? this.props.config.confidenceLevel) /
                100,
            one_hot_encode: false,
        };
        let parameters = [];
        if (this.props.regressionInfo?.intercept) parameters.push(1);
        let logs = [this.props.regressionInfo.iv.main_log].concat(
            this.props.regressionInfo.iv.other_log
        );
        let interactions = this.props.regressionInfo.iv.interactions;
        let allParameterNames = [this.props.regressionInfo.iv.main].concat(
            this.props.regressionInfo.iv.other
        );

        let restParametersOriginal: number[] | number[][] =
            allParameterNames.map((name, paramIndex) => {
                let value: number | number[] = 0;
                if (this.state.continuousVariables.includes(name)) {
                    value = this.state.clust[clustIndex][
                        this.state.continuousVariables.indexOf(name)
                    ].value as number;
                } else if (this.state.categoricalVariables.includes(name)) {
                    let categoricalItem =
                        this.state.categoriesClust[clustIndex][
                            this.state.categoricalVariables.indexOf(name)
                        ];
                    value = categoricalItem.values!.map((value) =>
                        value === categoricalItem.value ? 1 : 0
                    );
                } else if (
                    this.state.hiddenContinuousVariables.includes(name)
                ) {
                    value = this.state.hiddenClust[clustIndex][
                        this.state.hiddenContinuousVariables.indexOf(name)
                    ].value as number;
                } else if (
                    this.state.hiddenCategoricalVariables.includes(name)
                ) {
                    let categoricalItem =
                        this.state.hiddenCategoriesClust[clustIndex][
                            this.state.hiddenCategoricalVariables.indexOf(name)
                        ];
                    value = categoricalItem.values!.map((value) =>
                        value === categoricalItem.value ? 1 : 0
                    );
                } else {
                    let fixed = independentVariablesFixed[paramIndex];
                    if (!fixed) {
                        let valueIndex = ivNames.indexOf(name);
                        value = item.mean_iv[valueIndex];
                    } else {
                        let rawValues = ivNames.filter((otherName) =>
                            otherName.startsWith(`${name}%`)
                        );
                        let valuesFirstIndex = ivNames.indexOf(rawValues[0]);

                        value = item.mean_iv.slice(
                            valuesFirstIndex,
                            valuesFirstIndex + rawValues.length
                        );
                    }
                }
                if (Array.isArray(value)) return value;
                else return [value];
            });
        let restParameters: number[] | number[][] = restParametersOriginal.map(
            (value, paramIndex) =>
                logs[paramIndex] ? value.map((item) => Math.log(item)) : value
        );
        if (interactions != null) {
            for (let interaction of interactions) {
                let firstIndex = allParameterNames.indexOf(interaction.var1);
                let secondIndex = allParameterNames.indexOf(interaction.var2);
                if (firstIndex >= 0 && secondIndex >= 0) {
                    for (let i = 0; i < restParameters[firstIndex].length; ++i)
                        for (
                            let j = 0;
                            j < restParameters[secondIndex].length;
                            ++j
                        ) {
                            restParameters.push(
                                ((restParameters[firstIndex][i] as number) *
                                    (restParameters[secondIndex][
                                        j
                                    ] as number)) as any
                            );
                        }
                }
            }
        }
        restParametersOriginal = restParametersOriginal.flat();
        restParameters = restParameters.flat();

        parameters = parameters.concat(restParameters);
        request.parameters = parameters;
        axios
            .post<{
                success: boolean;
                error_msg?: string;
                result?: number;
                max_interval?: number;
                confidence_interval?: number[];
            }>("/api/e/model_predict", request)
            .then((result) => {
                if (!result.data.success) {
                    let errorMessages = Object.assign(
                        {},
                        this.state.errorMessages
                    );
                    errorMessages[clustIndex] = result.data.error_msg;
                    this.setState({ errorMessages: errorMessages });
                } else {
                    let newUpdate: {
                        mean_iv: number[];
                        mean_predicted: number;
                        max_interval?: number;
                        confidence_interval?: number[];
                        chart_interval?: number[];
                    } = {
                        mean_iv: restParametersOriginal as number[],
                        mean_predicted: this.props.regressionInfo.dv_log
                            ? Math.exp(result.data.result!)
                            : result.data.result!,
                    };
                    if (result.data.max_interval != null) {
                        newUpdate.max_interval = this.props.regressionInfo
                            .dv_log
                            ? Math.exp(result.data.max_interval)
                            : result.data.max_interval!;
                    }
                    if (result.data.confidence_interval != null) {
                        newUpdate.confidence_interval = this.props
                            .regressionInfo.dv_log
                            ? result.data.confidence_interval.map((item) =>
                                  Math.exp(item)
                              )
                            : result.data.confidence_interval;
                    }
                    if (
                        newUpdate.confidence_interval == null &&
                        newUpdate.max_interval == null
                    ) {
                        item.chart_interval = this.calculateChartInterval({
                            ...item,
                            mean_predicted: newUpdate.mean_predicted,
                        });
                    }
                    if (
                        item.chart_interval == null &&
                        newUpdate.confidence_interval != null &&
                        newUpdate.max_interval != null
                    ) {
                        let minChartValue = Math.min(
                            newUpdate.confidence_interval[0] -
                                newUpdate.max_interval,
                            newUpdate.confidence_interval[1] +
                                newUpdate.max_interval,
                            newUpdate.mean_predicted
                        );
                        let maxChartValue = Math.max(
                            newUpdate.confidence_interval[0] -
                                newUpdate.max_interval,
                            newUpdate.confidence_interval[1] +
                                newUpdate.max_interval,
                            newUpdate.mean_predicted
                        );
                        if (this.props.config.showTrueValue) {
                            minChartValue = Math.min(
                                minChartValue,
                                item.mean_true
                            );
                            maxChartValue = Math.max(
                                maxChartValue,
                                item.mean_true
                            );
                        }
                        maxChartValue =
                            maxChartValue +
                            Math.abs(maxChartValue - minChartValue) * 0.1;
                        minChartValue =
                            minChartValue -
                            Math.abs(maxChartValue - minChartValue) * 0.1;

                        newUpdate.chart_interval = [
                            minChartValue,
                            maxChartValue,
                        ];
                    }
                    this.onNewResult(newUpdate, clustIndex);
                }
            })
            .catch((error) => {
                let errorMessages = Object.assign({}, this.state.errorMessages);
                errorMessages[clustIndex] = String(error);
                this.setState({ errorMessages: errorMessages });
                console.log(error);
            });
    }

    initialState(prevProps?: Props): Partial<State> {
        if (this.props.preview) return {};
        let newState: Partial<State> = {};
        let regressionInfo = this.props.regressionInfo;

        let ivNames = [
            ...this.props.data.ivMainNames,
            ...this.props.data.ivOtherNames,
        ];
        let independentVariablesFixed: boolean[] = [];
        if (
            regressionInfo.iv.main_fixed != null &&
            regressionInfo.iv.other_fixed != null
        )
            independentVariablesFixed = [regressionInfo.iv.main_fixed]
                .concat(regressionInfo.iv.other_fixed)
                .filter((item) => item != null);
        else independentVariablesFixed = new Array(ivNames.length).fill(false);

        let independentVariables = [regressionInfo.iv.main]
            .concat(regressionInfo.iv.other)
            .filter((item) => item != null);
        let independentConfigVariables = Object.values(
            this.props.config.independentVariables
        ).filter((item) => item != null);
        let independentConfigVariablesDict: {
            [
                key: string
            ]: HoldOutPredictionFinding["config"]["independentVariables"][number];
        } = {};
        independentConfigVariables.forEach((item: any) => {
            independentConfigVariablesDict[item.label] = item;
        });

        let continuousVariables = independentVariables.filter(
            (item, index) =>
                !independentVariablesFixed[index] &&
                (independentConfigVariablesDict[item]?.show ?? true)
        );
        let categoricalVariables = independentVariables.filter(
            (item, index) =>
                independentVariablesFixed[index] &&
                (independentConfigVariablesDict[item]?.show ?? true)
        );
        let hiddenContinuousVariables = independentVariables.filter(
            (item, index) =>
                !independentVariablesFixed[index] &&
                (!independentConfigVariablesDict[item]?.show ?? true)
        );
        let hiddenCategoricalVariables = independentVariables.filter(
            (item, index) =>
                independentVariablesFixed[index] &&
                (!independentConfigVariablesDict[item]?.show ?? true)
        );
        if (
            prevProps == null ||
            !_.isEqual(prevProps.data, this.props.data) ||
            !_.isEqual(prevProps.config, this.props.config) ||
            !_.isEqual(prevProps.regressionInfo, this.props.regressionInfo)
        ) {
            let [clust, hiddenClust] = [
                continuousVariables,
                hiddenContinuousVariables,
            ].map((variables) =>
                this.props.data.predictedBars.map((item, clustIndex) => {
                    let result: ClustItem[] = [];
                    variables.forEach((variable, index) => {
                        let valueIndex = ivNames.indexOf(variable);
                        let userValue =
                            this.props.data.userValues?.[clustIndex]?.[
                                variable
                            ];
                        result.push({
                            name: variable,
                            value: userValue ?? item.mean_iv[valueIndex] ?? 0,
                        });
                    });

                    return result.filter((item) => item.name != null);
                })
            );
            let [categoriesClust, hiddenCategoriesClust] = [
                categoricalVariables,
                hiddenCategoricalVariables,
            ].map((variables) =>
                this.props.data.predictedBars.map((item, clustIndex) => {
                    let result: ClustItem[] = [];
                    variables.forEach((variable, index) => {
                        let rawValues = ivNames.filter((otherName) =>
                            otherName.startsWith(`${variable}%`)
                        );

                        let values = rawValues.map((value) => {
                            let percentIndex = value.indexOf("%");
                            if (percentIndex >= 0) {
                                value = value.slice(percentIndex + 1);
                            }
                            return JSON.parse(value);
                        });
                        if (values.length === 0) return;
                        let valuesFirstIndex = ivNames.indexOf(rawValues[0]);
                        let valueIndex = indexOfBiggest(
                            item.mean_iv.slice(
                                valuesFirstIndex,
                                valuesFirstIndex + values.length
                            )
                        );
                        let userValue =
                            this.props.data.userValues?.[clustIndex]?.[
                                variable
                            ];
                        if (!values.includes(userValue)) userValue = undefined;
                        result.push({
                            name: variable,
                            values: values,
                            value: userValue ?? values[valueIndex],
                        });
                    });

                    return result.filter((item) => item.name != null);
                })
            );
            newState.clust = clust;
            newState.categoriesClust = categoriesClust;
            newState.hiddenClust = hiddenClust;
            newState.hiddenCategoriesClust = hiddenCategoriesClust;
        }
        newState.independentVariables = independentVariables;
        newState.independentVariablesFixed = independentVariablesFixed;
        newState.ivNames = ivNames;
        newState.continuousVariables = continuousVariables;
        newState.categoricalVariables = categoricalVariables;
        newState.hiddenContinuousVariables = hiddenContinuousVariables;
        newState.hiddenCategoricalVariables = hiddenCategoricalVariables;
        return newState;
    }
    componentDidUpdate(prevProps: Props) {
        let newState = this.initialState(prevProps);
        this.setState((state) => {
            newState = {
                ...state,
                ...newState,
            };
            if (!_.isEqual(state, newState)) {
                return newState as any;
            }
            return null;
        });
    }
    updateUserValues(diff: Set<string>) {
        let userValuesDict: {
            [key: number]: { [key: string]: number | string };
        } = {};
        let clusters = [
            this.state.clust,
            this.state.categoriesClust,
            this.state.hiddenClust,
            this.state.hiddenCategoriesClust,
        ];
        for (let clust of clusters) {
            if (clust != null) {
                clust.forEach((clustItem, index) => {
                    let reducedClust: { [key: string]: number | string } =
                        clustItem.reduce(
                            (
                                result: { [key: string]: number | string },
                                item
                            ) => {
                                result[item.name] = item.value;
                                return result;
                            },
                            {}
                        );
                    userValuesDict[index] = {
                        ...userValuesDict[index],
                        ...reducedClust,
                    };
                });
            }
        }
        this.onNewUserValues(userValuesDict, diff);
    }

    render() {
        var axisFontSize = this.props.config.ticksSize ?? "10px";
        let maxYaxisWidth = 60;
        if (this.props.data) {
            let lengths = this.props.data.predictedBars.map(
                (item, index) =>
                    (this.props.groupNames?.[index] ?? "overall").length
            );
            maxYaxisWidth = Math.max(maxYaxisWidth, Math.max(...lengths) * 10);
        }
        let transformedData: (HoldOutPredictionFinding["content"]["data"]["predictedBars"][number] & {
            show_scale_button?: boolean;
            show_bar?: boolean;
            bar_interval?: number[];
            scale_button_direction?: ScaleButtonDirection;
            name?: string;
        })[] = this.props.data.predictedBars.map((item) => {
            let transformedItem = Object.assign({}, item);

            if (transformedItem.chart_interval == null) {
                transformedItem.chart_interval =
                    this.calculateChartInterval(item);
            }
            return transformedItem;
        });
        let maxChartInterval = transformedData[0].chart_interval!;
        for (let i = 1; i < transformedData.length; ++i) {
            maxChartInterval[0] = Math.min(
                maxChartInterval[0],
                transformedData[i].chart_interval![0]
            );
            maxChartInterval[1] = Math.max(
                maxChartInterval[1],
                transformedData[1].chart_interval![1]
            );
        }
        transformedData = transformedData.map((item, index) => {
            item.chart_interval = maxChartInterval;
            item.bar_interval = [
                Math.max(item.chart_interval[0], item.confidence_interval[0]),
                Math.min(item.chart_interval[1], item.confidence_interval[1]),
            ];
            item.show_bar =
                HoldOutPredictionBarChart.pointInInterval(
                    item.confidence_interval[0],
                    item.chart_interval
                ) ||
                HoldOutPredictionBarChart.pointInInterval(
                    item.confidence_interval[1],
                    item.chart_interval
                ) ||
                (item.confidence_interval[0] <= item.chart_interval[0] &&
                    item.confidence_interval[1] >= item.chart_interval[1]);
            item.show_scale_button =
                !HoldOutPredictionBarChart.pointInInterval(
                    item.confidence_interval[0],
                    item.chart_interval
                ) ||
                !HoldOutPredictionBarChart.pointInInterval(
                    item.confidence_interval[1],
                    item.chart_interval
                );
            if (item.show_scale_button) {
                if (
                    item.confidence_interval[0] <= item.chart_interval[0] &&
                    item.confidence_interval[1] >= item.chart_interval[1]
                )
                    item.scale_button_direction = ScaleButtonDirection.both;
                else
                    item.scale_button_direction =
                        item.confidence_interval[0] < item.chart_interval[0]
                            ? ScaleButtonDirection.left
                            : ScaleButtonDirection.right;
            } else {
                item.scale_button_direction = ScaleButtonDirection.hidden;
            }
            item.name = this.props.groupNames?.[index] ?? "overall";
            return item;
        });
        let chartWidth =
            transformedData.length > 0
                ? Math.floor(100 / transformedData.length)
                : undefined;
        let showIntervals = this.props.data.predictedBars.map((item) => {
            return item.show_intervals;
        });
        return (
            <div
                style={{
                    overflowX: mobileBreakpoint() ? "visible" : "auto",
                    backgroundColor: "transparent",
                    position: "relative",
                    height: "100%",
                    //   overflowY: "hidden",
                    //  overflowY: mobileBreakpoint() ? "visible" : "auto",
                }}
                ref={(ref) => {
                    if (ref != null) {
                        let heightScale =
                            ref.parentElement!.clientHeight / defaultHeight;

                        let widthScale =
                            ref.parentElement!.clientWidth / defaultWidth;

                        let minScale = Math.min(heightScale, widthScale);
                        if (minScale < 1) {
                            ref.style.height = null as any;
                        } else {
                            ref.style.height = "100%";
                        }
                        for (let child of ref.childNodes) {
                            let node = child as any;
                            node.style.transform = `scale(${minScale})`;
                            node.style.transformOrigin = "left top";
                            let attribute =
                                node.getAttribute("graphicsContainer");
                            if (attribute != null) {
                                node.style.width = `calc(100%*${1 / minScale})`;
                            }
                        }
                    }
                }}
            >
                <div
                    ref={(ref) => {
                        if (ref != null) {
                            ref.setAttribute("graphicsContainer", "true");
                        }
                    }}
                >
                    <span
                        className={styles.title}
                        style={{
                            paddingLeft: 30,
                            paddingBottom: 10,
                            display: "block",
                            textAlign: "left",
                        }}
                    >
                        Prediction
                    </span>
                    <div style={{ width: "100%", height: "100%" }}>
                        <div
                            className="my-row"
                            style={{
                                width: "100%",
                                height: "179px",
                            }}
                        >
                            {transformedData.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex-simple-column"
                                        style={{
                                            width: `calc(${chartWidth}%)`,
                                        }}
                                    >
                                        {this.buildHorizontalBarSection(
                                            item,
                                            index,
                                            axisFontSize,
                                            maxYaxisWidth,
                                            showIntervals
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        {!this.props.preview && (
                            <HoldOutPredictionParametersChart
                                axesLinesColor={this.props.config.axesLinesColor}
                                ticksColor={this.props.config.ticksColor}
                                ticksSize={this.props.config.ticksSize}
                                categoriesClust={_.cloneDeep(
                                    this.state.categoriesClust
                                )}
                                clust={_.cloneDeep(this.state.clust)}
                                continuousVariables={
                                    this.state.continuousVariables
                                }
                                categoricalVariables={
                                    this.state.categoricalVariables
                                }
                                onCategoriesClustChange={(categoriesClust) => {
                                    const diff = clustDiff(
                                        categoriesClust,
                                        this.state.categoriesClust
                                    );
                                    this.setState(
                                        { categoriesClust: categoriesClust },
                                        () => {
                                            this.updateUserValues(diff);
                                        }
                                    );
                                }}
                                onClustChange={(clust) => {
                                    const diff = clustDiff(
                                        clust,
                                        this.state.clust
                                    );
                                    this.setState({ clust: clust }, () => {
                                        this.updateUserValues(diff);
                                    });
                                }}
                                showLeverBars={this.props.config.showLeverBars}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private buildHorizontalBarSection(
        item: {
            confidence_interval: number[];
            chart_interval?: number[] | null | undefined;
            max_interval?: number | null | undefined;
            confidence_level: number;
            mean_iv: number[];
            mean_predicted: number;
            mean_true: number;
            show_intervals: boolean;
            model_id: number;
            sample_size: number;
        } & {
            show_scale_button?: boolean | undefined;
            show_bar?: boolean | undefined;
            bar_interval?: number[] | undefined;
            scale_button_direction?: ScaleButtonDirection | undefined;
            name?: string | undefined;
        },
        index: number,
        axisFontSize: string,
        maxYaxisWidth: number,
        showIntervals: boolean[]
    ) {
        return (
            <div className="my-row" style={{ alignItems: "center" }}>
                <div
                    style={{
                        width: "calc(100% - 200px)",
                    }}
                >
                    {item.show_scale_button &&
                        (item.scale_button_direction ===
                            ScaleButtonDirection.left ||
                            item.scale_button_direction ===
                                ScaleButtonDirection.both) && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    title="Click to Recenter Scale."
                                    onClick={() => {
                                        this.onCenterItem(
                                            index,
                                            this.calculateChartInterval(item)
                                        );
                                    }}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <img
                                        alt=""
                                        src={
                                            "/dist/img/data-exploration/chevron_green_back.png"
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    {item.show_scale_button &&
                        (item.scale_button_direction ===
                            ScaleButtonDirection.right ||
                            item.scale_button_direction ===
                                ScaleButtonDirection.both) && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <div
                                    title="Click to Recenter Scale."
                                    onClick={() => {
                                        this.onCenterItem(
                                            index,
                                            this.calculateChartInterval(item)
                                        );
                                    }}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <img
                                        alt=""
                                        src={
                                            "/dist/img/data-exploration/chevron_green.png"
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    {(this.props.config.showLegend ?? true) && (
                        <div
                            className="my-row center-container"
                            style={{
                                width: "100%",
                                paddingBottom: "10px",
                            }}
                        >
                            {this.props.config.showTrueValue && (
                                <LegendElement
                                    textSize={this.props.config.legendSize}
                                    color={
                                        !this.props.preview
                                            ? "#e24245"
                                            : "#d1d1d1"
                                    }
                                    text={"Actual Level"}
                                />
                            )}
                            <LegendElement
                                textSize={this.props.config.legendSize}
                                color={
                                    !this.props.preview ? "#05C985" : "#d1d1d1"
                                }
                                text={"Predicted Level"}
                            />
                        </div>
                    )}
                    <ResponsiveContainer height={50} key={index}>
                        <BarChart data={[item]} barSize={10} layout="vertical">
                            <XAxis
                                domain={item.chart_interval!}
                                stroke={
                                    this.props.config.axesLinesColor ??
                                    mainStyle.getPropertyValue(
                                        "--graphs-stroke-color"
                                    )
                                }
                                orientation="bottom"
                                type="number"
                                interval={0}
                                tickLine={true}
                                axisLine={true}
                                tick={
                                    <CustomizedAxisTick
                                        axesColor={this.props.config.ticksColor}
                                        formatValues={true}
                                        truncValues={false}
                                        fontSize={axisFontSize}
                                        dx={0}
                                        dy={8}
                                    />
                                }
                            />
                            <YAxis
                                type="category"
                                width={maxYaxisWidth}
                                dataKey="name"
                                axisLine={false}
                                tickLine={false}
                                tick={
                                    <HorizontalBarsAxisTick
                                        axesColor={this.props.config.ticksColor}
                                        fontSize={axisFontSize}
                                        dx={0}
                                        dy={0}
                                    />
                                }
                            />
                            <CartesianGrid
                                stroke={
                                    this.props.config.axesLinesColor ??
                                    mainStyle.getPropertyValue(
                                        "--graphs-stroke-color"
                                    )
                                }
                                horizontal={false}
                                strokeWidth={1}
                            />
                            <Tooltip
                                wrapperStyle={{
                                    zIndex: 10 - index,
                                }}
                                content={
                                    <CustomTooltip
                                        type={this.state.tooltipType}
                                        item={item}
                                    />
                                }
                                formatter={(value: string | number) => {
                                    return typeof value === "number"
                                        ? formatValue(value).join("")
                                        : value;
                                }}
                                cursor={false}
                                {...TooltipStyles(
                                    this.props.config.tooltipColor,
                                    this.props.config.tooltipFontSize
                                )}
                            />
                            <Bar
                                fill={
                                    showIntervals[index] && item.show_bar
                                        ? this.props.preview
                                            ? "#A9A9A980"
                                            : "#05C985"
                                        : "transparent"
                                }
                                fillOpacity={0.3}
                                strokeOpacity={0.3}
                                stroke={
                                    showIntervals[index] && item.show_bar
                                        ? this.props.preview
                                            ? "#A9A9A980"
                                            : "#05C985"
                                        : "transparent"
                                }
                                barSize={4}
                                dataKey="bar_interval"
                            />
                            {HoldOutPredictionBarChart.pointInInterval(
                                item.confidence_interval[0],
                                item.chart_interval!
                            ) &&
                                showIntervals[index] && (
                                    <ReferenceLine
                                        x={item.confidence_interval[0]}
                                        strokeWidth={3}
                                        stroke={
                                            this.props.preview
                                                ? "#A9A9A980"
                                                : "#05C985"
                                        }
                                        onMouseOver={() => {
                                            this.setState({
                                                tooltipType:
                                                    TooltipType.lowerInterwal,
                                            });
                                        }}
                                        onMouseOut={() => {
                                            this.setState({
                                                tooltipType: TooltipType.hidden,
                                            });
                                        }}
                                    />
                                )}
                            {HoldOutPredictionBarChart.pointInInterval(
                                item.confidence_interval[1],
                                item.chart_interval!
                            ) &&
                                showIntervals[index] && (
                                    <ReferenceLine
                                        x={item.confidence_interval[1]}
                                        strokeWidth={3}
                                        stroke={
                                            this.props.preview
                                                ? "#A9A9A980"
                                                : "#05C985"
                                        }
                                        onMouseOver={() => {
                                            this.setState({
                                                tooltipType:
                                                    TooltipType.higherInterval,
                                            });
                                        }}
                                        onMouseOut={() => {
                                            this.setState({
                                                tooltipType: TooltipType.hidden,
                                            });
                                        }}
                                    />
                                )}
                            {HoldOutPredictionBarChart.pointInInterval(
                                item.mean_predicted,
                                item.chart_interval!
                            ) && (
                                <ReferenceDot
                                    x={item.mean_predicted}
                                    y={item.name}
                                    r={4.5}
                                    fill="white"
                                    strokeWidth={2}
                                    stroke={
                                        this.props.preview
                                            ? "#A9A9A980"
                                            : "#05C985"
                                    }
                                    onClick={() => {
                                        this.onCenterItem(
                                            index,
                                            this.calculateChartInterval(item)
                                        );
                                    }}
                                    onMouseOver={() => {
                                        this.setState({
                                            tooltipType:
                                                TooltipType.meanPredicted,
                                        });
                                    }}
                                    onMouseOut={() => {
                                        this.setState({
                                            tooltipType: TooltipType.hidden,
                                        });
                                    }}
                                />
                            )}

                            {this.props.config.showTrueValue &&
                                HoldOutPredictionBarChart.pointInInterval(
                                    item.mean_true,
                                    item.chart_interval!
                                ) && (
                                    <ReferenceDot
                                        x={item.mean_true}
                                        y={item.name}
                                        r={4.5}
                                        fill="white"
                                        strokeWidth={2}
                                        stroke={
                                            this.props.preview
                                                ? "#A9A9A980"
                                                : "#e24245"
                                        }
                                        onMouseOver={() => {
                                            this.setState({
                                                tooltipType:
                                                    TooltipType.meanTrue,
                                            });
                                        }}
                                        onMouseOut={() => {
                                            this.setState({
                                                tooltipType: TooltipType.hidden,
                                            });
                                        }}
                                    />
                                )}
                        </BarChart>
                    </ResponsiveContainer>
                    {this.buildSignificanceLevelBlock(index)}
                </div>
                <div
                    style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                    }}
                >
                    <PredictBlock
                        baseColor={this.props.preview ? "#A9A9A9" : undefined}
                        name={this.props.regressionInfo.dv!}
                        sampleSize={item.sample_size}
                        value={item.mean_predicted}
                        predictInterval={item.confidence_interval}
                        onCenterItemClick={() => {
                            this.onCenterItem(
                                index,
                                this.calculateChartInterval(item)
                            );
                        }}
                    />
                    <Button
                        type="button"
                        className="btn btn-md btn-primary my-primary"
                        disabled={this.props.preview}
                        style={{
                            marginTop: "10px",
                            width: "112px",
                        }}
                        onClick={() => {
                            this.runPredict(index);
                        }}
                    >
                        RUN
                    </Button>
                    {this.state.errorMessages[index] ? (
                        <span
                            style={{
                                marginLeft: 10,
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: "red",
                            }}
                        >
                            {this.state.errorMessages[index]}
                        </span>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default HoldOutPredictionBarChart;
