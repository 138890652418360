import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import { FunnelPlotFinding } from "common/Finding";
import { configVersionV2 } from "common/PathConfigVersion";
import axios from "common/ServerConnection";
import { mainStyle } from "common/MainStyle";
import { colorList } from "common/graphics/LineColors";
import { updateOriginalNames } from "../../common/Utils";

class Api {
    static getDefaultConfig(journeyName: string) {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            count: 5,
            labelsColor: mainStyle.getPropertyValue("--graphs-axes-text-color"),
            valuesColor: mainStyle.getPropertyValue("--graphs-axes-text-color"),
            funnelColor: colorList[1],
            labelsFontSize: parseFloat(
                mainStyle.getPropertyValue("--graphs-axes-size")
            ),
            valuesFontSize: parseFloat(
                mainStyle.getPropertyValue("--graphs-axes-size")
            ),
            tooltipColor: mainStyle.getPropertyValue(
                "--charts-tooltip-background-color"
            ),
        };
    }

    static getPreviewFinding(journeyName: string): FunnelPlotFinding {
        let item = {
            type: "funnelplot",
            content: {
                data: [
                    {
                        name: "Variable",
                        value: ["Variable 1", "Variable 2", "Variable 3"],
                    },
                    {
                        name: "Value",
                        value: [300, 200, 100],
                    },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static getData(
        finding: FunnelPlotFinding,
        findingOptions: any,
        moduleId?: number
    ): Promise<FunnelPlotFinding> {
        let { dataScope, selectedTable, conditions, count } = finding.config;

        conditions = filterNonEmptyConditions(conditions ?? []);
        let selectedVariableIndex = finding.content.data[1].variableIndex;
        let labelVariableIndex = finding.content.data[0].variableIndex;
        if (selectedVariableIndex == null || labelVariableIndex == null)
            return Promise.resolve(finding);

        let requestJson = {
            variable_index: selectedVariableIndex,
            label_index: labelVariableIndex,
            table: selectedTable.value,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            conditions: conditionsToJson(conditions),
            limit: count,
            module_id: moduleId,
        };
        return axios
            .post<{
                data?: { [key: string]: number[] };
                success: boolean;
                error_msg: string;
            }>("/api/e/funnel_plot", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let data = response.data.data!;
                    let newFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: [...finding.content.data],
                        },
                    };
                    newFinding.content.data[1] = {
                        ...newFinding.content.data[1],
                        value: data[selectedVariableIndex!],
                    };
                    newFinding.content.data[0] = {
                        ...newFinding.content.data[0],
                        value: data[labelVariableIndex!],
                    };

                    // Check wherther variables were renamed
                    updateOriginalNames(
                        newFinding.content.data,
                        dataScope.value,
                        moduleId
                    );

                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
