import { observable, computed, makeObservable } from "mobx";
import axios from "./ServerConnection";

class RecentColorsInner {
    @observable private recentColorsState: string[] = [];

    // We can memorize at most this many colors
    private static MAX_SIZE: number = 24;

    constructor() {
        makeObservable(this);
        this.update();
    }

    @computed public get recentColors(): string[] {
        return this.recentColorsState;
    }

    public async addColor(color: string): Promise<void> {
        if (this.recentColorsState.indexOf(color) < 0) {
            this.recentColorsState = [color, ...this.recentColorsState].slice(
                0,
                RecentColorsInner.MAX_SIZE
            );
            return axios
                .post<{
                    success: boolean;
                    error_msg: string;
                }>("/api/user_settings_update", {
                    settings: JSON.stringify({
                        recent_colors: this.recentColorsState,
                    }),
                })
                .then((response) => {
                    if (!response.data.success) {
                        console.log(response.data.error_msg);
                    }
                })
                .catch((error) => {
                    // We should not print the error if the user is unauthorized
                    if (error.response.status !== 401 && error.response.status !== 403) {
                        console.log(error);
                        return Promise.reject(error);
                    }
                });
        } else {
            return Promise.resolve();
        }
    }

    public async update(): Promise<void> {
        return axios
            .post<{
                success: boolean;
                error_msg: string;
                settings?: string;
            }>("/api/user_settings_get")
            .then((response) => {
                if (response.data.success && response.data.settings != null) {
                    let parsed = JSON.parse(response.data.settings);
                    if (Array.isArray(parsed.recent_colors))
                        this.recentColorsState = parsed.recent_colors;
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                if (!error.response) return;
                // We should not print the error if the user is unauthorized
                if (error.response.status !== 401 && error.response.status !== 403) {
                    console.log(error);
                    return Promise.reject(error);
                }
            });
    }
}

export default new RecentColorsInner();
