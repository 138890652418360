import React, { Component } from "react";
import Cookies from "universal-cookie";
import NotificationIcon from "common/NotificationIcon";
import { BriefCanvas, SharedModuleCanvas } from "common/Canvas";
import { editCanvasTitle, BriefEditCanvasRequest } from "common/CanvasUserApi";
import Canvases from "common/Canvases";
import { preventDefault } from "common/utilities/EventFunctions";
import { ReactComponent as HiddenIcon } from "icons/hidden_slide.svg";
import SlideCardDropArea from "./SlideCardDropArea";
const cookies = new Cookies();

interface Props {
    isFirstOfSection?: boolean;
    index?: number;
    draggingDirection?: boolean | undefined;
    highlight: boolean;
    highlightAsSelected?: boolean;
    highlightAsOption?: boolean;
    highlightString?: string;
    canvas?: BriefCanvas;
    sharedCanvas?: SharedModuleCanvas;
    hasNewFeedbacks: boolean;
    canWrite: boolean;
    onClick: (evt:React.MouseEvent<HTMLDivElement>) => void;
    onEdit: () => void;
    onContextMenu: (
        canvas: BriefCanvas,
        evt: React.MouseEvent<HTMLDivElement>
    ) => void;
    onSlideCardDropAreaEnter?: (
        position: string,
    ) => void;
}

interface State {
    editedTitle: string | null;
    slideRenamingMode: boolean;
}

class SlideCard extends Component<Props, State> {
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            editedTitle: null,
            slideRenamingMode: false,
        };
        this.inputRef = React.createRef();
        this.onContextMenu = this.onContextMenu.bind(this);
    }

    private onContextMenu(evt: React.MouseEvent<HTMLDivElement>): void {
        if (this.props.canvas == null) return;
        evt.stopPropagation();
        evt.preventDefault();
        this.props.onContextMenu(this.props.canvas, evt);
    }

    public startSlideEdit(title: string) {
        this.setState(
            {
                editedTitle: title,
                slideRenamingMode: true,
            },
            () => {
                this.inputRef.current?.focus({
                    preventScroll: true,
                });
                this.inputRef.current?.setSelectionRange(
                    0,
                    title.length
                );
            }
        );
    }

    private rename(id: number, pageId: number) {
        if (this.props.canvas == null) return;
        let editCanvasRequest: BriefEditCanvasRequest = {
            sheets: [
                {
                    canvasId: id!,
                    title: this.state.editedTitle!,
                },
            ],
        };
        editCanvasTitle(
            editCanvasRequest,
            cookies.get("instrumentation_session_id")
        )
            .then(() => {
                Canvases(pageId!).update();
            })
            .catch((errorMessage) => {
                console.log(errorMessage);
            });
        this.setState({ editedTitle: null });
    }

    public render(): JSX.Element | null {
        let title: string;
        let thumbnail: string | null | undefined;
        let id: number | undefined = undefined;
        let pageId: number | undefined = undefined;
        if (this.props.canvas != null) {
            title = this.props.canvas.title;
            thumbnail = this.props.canvas.thumbnail;
            id = this.props.canvas.id;
            pageId = this.props.canvas.page_id;
        } else {
            title = this.props.sharedCanvas!.title;
            thumbnail = this.props.sharedCanvas!.thumbnail;
        }

        return (
            <div
                style={{
                    position: "relative",
                    cursor: "pointer",
                    backgroundColor: this.props.highlight
                        ? "var(--sheet-ribbon-highlight-color)"
                        : "transparent",
                }}
                onContextMenu={this.onContextMenu}
            >
               <SlideCardDropArea
                    position="top"
                    isDropTarget={true}
                    onClick={this.props.onClick}
                    onSlideCardDropAreaEnter={this.props.onSlideCardDropAreaEnter}
                />

                {!this.state.slideRenamingMode && (
                    <SlideCardDropArea
                        position="bottom"
                        isDropTarget={true}
                        onClick={this.props.onClick}
                        onSlideCardDropAreaEnter={this.props.onSlideCardDropAreaEnter}
                    />
                )}

                {!this.state.slideRenamingMode && (
                    <SlideCardDropArea
                        position="title"
                        isDropTarget={false}
                        onDoubleClick={() => this.startSlideEdit(title)}
                    />
                )}

                <div style={{ position: "relative", height: "100%" }}>
                    <div
                        className="flex-simple-column"
                        style={{
                            height: "100%",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                marginTop: 7,
                                display: "flex",
                                justifyContent: "center",
                                position: "relative"
                            }}
                        >
                            <span
                                style={{
                                    position: "absolute",
                                    right: "0",
                                    bottom: "0",
                                    fontSize: "9px",
                                    zIndex: 2,
                                    backgroundColor: "#ffffff",
                                    borderTopLeftRadius: "5px",
                                    padding: "3px 5px 0 5px",
                                    color: this.props.highlight
                                        ? "var(--sheet-ribbon-slide-title-highlight-color)"
                                        : "var(--sheet-ribbon-slide-title-color)",
                                }}
                            >{this.props.index}</span>
                            <div
                                style={{
                                    position: "relative",
                                    boxSizing: "border-box",
                                    boxShadow: "0px 0px 4px 0px #242E424D",
                                    width: 144,
                                    height: 84,
                                    background: "transparent",
                                }}
                                onClick={this.props.onClick}
                            >
                                {this.props.canvas?.hide_in_slideshow ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "100%",
                                            width: "100%",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{ flexGrow: 0.5 }} />
                                        <HiddenIcon />
                                        <span
                                            className="unselectable"
                                            style={{
                                                marginTop: "15px",
                                                fontFamily: "Roboto",
                                                fontWeight: 200,
                                                fontSize: 8,
                                                letterSpacing: "0.2em",
                                                color: "rgba(0,0,0,0.7)",
                                            }}
                                        >
                                            HIDDEN IN SLIDESHOW
                                        </span>
                                    </div>
                                ) : (
                                    thumbnail != null && (
                                        <img
                                            alt=""
                                            src={thumbnail}
                                            style={{
                                                width: "144px",
                                                height: "84px",
                                                objectFit: "contain",
                                            }}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                        <input
                            ref={this.inputRef}
                            className="bordered"
                            style={{
                                display:
                                    this.state.editedTitle != null
                                        ? undefined
                                        : "none",
                                paddingLeft: 2,
                                paddingRight: 2,
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginTop: 3,
                                marginBottom: 3,
                                fontFamily: "Roboto",
                                fontSize: 9,
                                color:
                                    "var(--sheet-ribbon-slide-title-highlight-color)",
                                fontWeight: "bold",
                                width: 150,
                                height: 11,
                                lineHeight: "11px",
                                textTransform: "uppercase",
                            }}
                            value={this.state.editedTitle ?? ""}
                            onChange={(e) => {
                                let value = e.target.value;
                                this.setState({
                                    editedTitle: value,
                                });
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    this.rename(id!, pageId!);
                                }
                            }}
                            onBlur={() => {
                                this.rename(id!, pageId!);
                                this.setState({
                                    slideRenamingMode: false,
                                })
                            }}
                        />
                        <span
                            style={{
                                display:
                                    this.state.editedTitle != null
                                        ? "none"
                                        : undefined,
                                marginTop: 3,
                                marginBottom: 3,
                                fontFamily: "Roboto",
                                fontSize: 9,
                                color: this.props.highlight
                                    ? "var(--sheet-ribbon-slide-title-highlight-color)"
                                    : "var(--sheet-ribbon-slide-title-color)",
                                fontWeight: this.props.highlight
                                    ? "bold"
                                    : "normal",
                                width: 144,
                                lineHeight: "11px",
                                textTransform: "uppercase",
                            }}
                            onClick={preventDefault}
                            onDoubleClick={() => this.startSlideEdit(title)}
                        >
                            {title}
                        </span>
                    </div>
                </div>
                {this.props.hasNewFeedbacks && (
                    <NotificationIcon
                        style={{
                            right: 10,
                            top: 10,
                        }}
                    />
                )}
            </div>
        );
    }
}

export default SlideCard;
