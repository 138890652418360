import React from "react";
import GanttChart from "common/graphics/v2/GanttChart";
import { GanttChartFinding } from "common/Finding";

interface Props {
	finding: GanttChartFinding;
	onNewFinding?: (finding: GanttChartFinding, updateData?: boolean) => void;
	preview?: boolean;
	editable?: boolean;
	columnDragActive?: boolean;
}

function MainComponent(props: Props) {
	let finding = props.finding;
	return (
		<div style={{ width: "100%", height: "100%" }}>
			<GanttChart
				colorBy={finding.content.colorBy}
				columnDragActive={props.columnDragActive}
				editable={props.editable}
				preview={props.preview}
				config={finding.config}
				data={finding.content.data}
				onChangeData={
					props.onNewFinding
						? (data: any, updateData?: boolean) => {
								let finding = { ...props.finding };
								finding.content.data = data;
								props.onNewFinding!(finding, updateData);
						  }
						: undefined
				}
				onChangeConfig={
					props.onNewFinding
						? (config: any, updateData?: boolean) => {
								let finding = { ...props.finding };
								finding.config = config;
								props.onNewFinding!(finding, updateData);
						  }
						: undefined
				}
			/>
		</div>
	);
}

export { MainComponent };