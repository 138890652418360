import React from "react";
import FinalStep1Ai from "common/graphics/FinalStep1Ai";

function MainComponent(props) {
	let finding = props.finding;

	return (
		<FinalStep1Ai
			frozen={true}
			compareInfo={finding.content.compareInfo}
			geographyLevels={finding.content.geographyLevels}
			targetVariable={finding.content.targetVariable}
			showedVariables={finding.content.showedVariables}
			units={finding.content.units}
			showMap={finding.content.showMap}
			config={finding.config}
		/>
	);
}

export { MainComponent };