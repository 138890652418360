import React from "react";

import * as d3 from "d3";

export interface GraphNode {
    id: number;
    title: string;
    x: number;
    y: number;
    type: string;
    metrics?: { [key: string]: string | number | null };
}

export interface GraphEdge {
    source: number;
    target: number;
    type: string;
}

interface Props {
    nodes: { [key: string]: GraphNode };
    edges: { [key: string]: GraphEdge };
    onNodeClick?: (nodeId: number) => void;
}

/// Network with manual node placement
class NetworkManual extends React.Component<Props> {
    private svgRef: React.RefObject<SVGSVGElement>;
    private gRef: React.RefObject<SVGGElement>;

    private d3Objects:
        | {
              svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
              g: d3.Selection<SVGGElement, unknown, null, undefined>;
              edges: d3.Selection<
                  SVGLineElement,
                  GraphEdge,
                  SVGGElement,
                  unknown
              >;
              nodes: d3.Selection<
                  SVGCircleElement,
                  GraphNode,
                  SVGGElement,
                  unknown
              >;
              zoom: d3.ZoomBehavior<SVGSVGElement, unknown>;
          }
        | undefined;

    constructor(props: Props) {
        super(props);

        this.svgRef = React.createRef();
        this.gRef = React.createRef();
    }

    public componentDidMount(): void {
        const svg = d3.select(this.svgRef.current!);
        const g = d3.select(this.gRef.current!);

        let edges = g
            .selectAll("line")
            .data(Object.values(this.props.edges))
            .enter()
            .append("line")
            .attr("x1", (d) => {
                return this.props.nodes[d.source].x;
            })
            .attr("y1", (d) => {
                return this.props.nodes[d.source].y;
            })
            .attr("x2", (d) => {
                return this.props.nodes[d.target].x;
            })
            .attr("y2", (d) => {
                return this.props.nodes[d.target].y;
            })
            .style("stroke", "#aaa");

        let nodes = g
            .selectAll("circle")
            .data(Object.values(this.props.nodes))
            .enter()
            .append("circle")
            .attr("r", 20)
            .attr("cx", (d) => d.x)
            .attr("cy", (d) => d.y)
            .style("fill", "#69b3a2")
            .on("click", (e, d) => {
                if (e.defaultPrevented) {
                    return; // panning, not clicking
                }
                this.props.onNodeClick?.(d.id);
            });

        // Zooming and panning
        let zoom = d3.zoom<SVGSVGElement, unknown>().on("zoom", (e) => {
            g.attr("transform", e.transform);
        });
        svg.call(zoom);

        this.d3Objects = {
            svg: svg,
            g: g,
            edges: edges,
            nodes: nodes,
            zoom: zoom,
        };

        // Zoom to fit initially
        this.zoomToFit();
    }

    public render(): JSX.Element {
        return (
            <svg ref={this.svgRef} width={"100%"} height={"100%"}>
                <g ref={this.gRef} />
            </svg>
        );
    }

    private zoomToFit(): void {
        if (this.d3Objects == null) {
            return;
        }

        let xExtent = d3.extent(this.d3Objects.nodes.data(), (d) => d.x);
        let yExtent = d3.extent(this.d3Objects.nodes.data(), (d) => d.y);
        // It is enough to check just one coorditnate. The other one won't be
        // null as well in this case.
        if (xExtent[0] != null && yExtent[0] != null) {
            let width = this.svgRef.current!.clientWidth;
            let height = this.svgRef.current!.clientHeight;
            let scale =
                0.85 *
                Math.min(
                    width / (xExtent[1] - xExtent[0]),
                    height / (yExtent[1] - yExtent[0])
                );

            let transform = d3.zoomIdentity
                .translate(width / 2, height / 2)
                .scale(scale)
                .translate(
                    -(xExtent[0] + xExtent[1]) / 2,
                    -(yExtent[0] + yExtent[1]) / 2
                );
            this.d3Objects.svg.call(this.d3Objects.zoom.transform, transform);
        }
    }
}

export default NetworkManual;
