import React, { useRef } from "react";
import { Text } from "recharts";

import "common/styles/div_span.css";
import { useDrop } from "react-dnd";

interface Props {
	x?: number;
	y?: number;
	editable?: boolean;
	fontColor: string;
	currentEditVariableIndex?: number;
	width: number;
	payload?: {
		name: string;
		value: string | number;
		index: number;
	};
	onEdit: (
		index: number,
		boundingRect: {
			x: number;
			y: number;
			width: number;
			height: number;
		}
	) => void;
	onDrop: (
		index: number,
		variableName: string,
		variableIndex: number
	) => void;
}

const height = 48;

export default function XAxisItem(props: Props) {
	let currentEdit = props.payload!.index === props.currentEditVariableIndex;
	const ref = useRef(null);
	const [collected, drop] = useDrop({
		accept: "variable_column",
		drop(
			otherItem: {
				content: {
					variableName: string;
					variableIndex: number;
				};
			},
			monitor
		) {
			props.onDrop(
				props.payload!.index,
				otherItem.content.variableName,
				otherItem.content.variableIndex
			);
		},
		collect(monitor) {
			return { hover: monitor.isOver() };
		},
	});
	if (props.editable) {
		drop(ref);
	}
	return (
		<g
			ref={ref}
			transform={`translate(${props.x! - props.width / 2}, ${props.y})`}
			width={props.width}
			height={height}
			onDoubleClick={(evt) => {
				if (props.editable) {
					props.onEdit(
						props.payload!.index,
						evt.currentTarget.getBoundingClientRect()
					);
				}
			}}
		>
			<rect
				stroke={
					!props.editable
						? undefined
						: collected.hover
						? "#36B743"
						: props.editable && currentEdit
						? "#8DB8E3"
						: "#E9E9E9"
				}
				strokeDasharray={
					collected.hover || (props.editable && currentEdit)
						? "3 3"
						: undefined
				}
				fill={
					collected.hover
						? "#F4FBF5"
						: props.editable && currentEdit
						? "#EBF2F9"
						: "transparent"
				}
				width={props.width}
				height={height}
			></rect>

			<Text
				width={props.width}
				x={props.width / 2}
				y={height / 2}
				dominantBaseline="middle"
				textAnchor="middle"
				fontFamily="Roboto"
				fontWeight={500}
				fontSize={14}
				fill={props.fontColor}
				className="no-selection"
			>
				{props.editable && currentEdit
					? "Drop variable here"
					: props.payload?.value}
			</Text>
		</g>
	);
}
