import React, { useState } from "react";
import { HoldOutPredictionFinding } from "common/Finding";
import { VariableOption } from "common/Variables";
import SaveRegressionResultsPopup from "common/SaveRegressionResultsPopup";
import commonStyles from "../../DataSection.module.css";
import styles from "./RegressionOptionsSelector.module.css";
import cx from "classnames";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { getCustomSelectStyleForRegressionOptions } from "common/SelectStyles";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import Alert from "common/Alert";
import { modelPredictAddVariable } from "common/DataApi";
import { Panel, Type } from "common/InputData";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

function Checkbox(props: {
    value: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
}) {
    return (
        <div
            className="pretty p-default"
            contentEditable={false}
            style={{ marginRight: 0, marginTop: -4 }}
        >
            <input
                disabled={props.disabled}
                type="checkbox"
                checked={props.value}
                onChange={() => {
                    props.onChange(!props.value);
                }}
            />
            <div className="state p-primary">
                <label className={commonStyles.optionCheckboxName}></label>
            </div>
        </div>
    );
}

interface Props {
    finding: HoldOutPredictionFinding;
    updatingDashboard: boolean;
    canvasTreeStore: CanvasTreeStore;
    dashboardId: string;
    currentModuleId?: number;
    variableOptions: VariableOption[];
    onChange: (finding: HoldOutPredictionFinding, updateData?: boolean) => void;
}

export default function RegressionOptionsSelector(props: Props) {
    let variables = props.variableOptions.filter(
        (item) => item.value !== props.finding.config.dependentVariable?.value
    );
    let regressionStyles = getCustomSelectStyleForRegressionOptions(14, false);

    const [addingPrediction, setAddingPrediction] = useState<boolean>(false);

    const [showSaveRegressionPopup, setShowRegressionPopup] = React.useState(
        false
    );
    const allIncluded =
        props.finding.config.intercept &&
        variables.reduce(
            (acc, item) =>
                acc &&
                props.finding.config.independentVariables[item.value] != null,
            true
        );
    const allCategorical = variables.reduce(
        (acc, item) =>
            (acc &&
                props.finding.config.independentVariables[item.value] ==
                    null) ||
            (item.type !== "int" && item.type !== "float"),
        true
    );
    const allExcluded = variables.reduce(
        (acc, item) =>
            acc &&
            props.finding.config.independentVariables[item.value] == null,
        true
    );
    const allShowed =
        !allExcluded &&
        (Object.values(props.finding.config.independentVariables).reduce(
            (acc, item) => acc && (item == null || (item as any)!.show),
            true
        ) as boolean);
    const allFixed =
        !allExcluded &&
        variables.reduce(
            (acc, item) =>
                acc &&
                (props.finding.config.independentVariables[item.value] ==
                    null ||
                    props.finding.config.independentVariables[item.value]
                        .fixed),
            true
        );
    const allLogs =
        !allCategorical &&
        !allExcluded &&
        variables.reduce(
            (acc, item) =>
                acc &&
                (props.finding.config.independentVariables[item.value] ==
                    null ||
                    (item.type !== "int" && item.type !== "float") ||
                    props.finding.config.independentVariables[item.value].log),
            true
        );

    const includeAll = (include: boolean) => {
        let newFinding = { ...props.finding };
        if (include) {
            if (!newFinding.config.intercept) {
                newFinding.config.intercept = true;
            }
            variables.forEach((item) => {
                if (
                    newFinding.config.independentVariables[item.value] == null
                ) {
                    newFinding.config.independentVariables[item.value] = {
                        show: true,
                        log: false,
                        fixed: item.type !== "int" && item.type !== "float",
                        label: item.label,
                        value: item.value,
                    };
                }
            });
        } else {
            newFinding.config.intercept = false;
            for (let key in newFinding.config.independentVariables) {
                newFinding.config.independentVariables[key] = null;
                newFinding.config.interactions[key] = null;
            }
        }
        props.onChange(newFinding);
    };
    const logAll = (log: boolean) => {
        let newFinding = { ...props.finding };
        variables.forEach((item) => {
            if (
                newFinding.config.independentVariables[item.value] != null &&
                (item.type === "float" || item.type === "int")
            ) {
                newFinding.config.independentVariables[item.value].log = log;
            }
        });

        props.onChange(newFinding);
    };
    const fixAll = (fix: boolean) => {
        let newFinding = { ...props.finding };
        variables.forEach((item) => {
            if (
                newFinding.config.independentVariables[item.value] != null &&
                (item.type === "float" || item.type === "int")
            ) {
                newFinding.config.independentVariables[item.value].fixed = fix;
            }
        });
        props.onChange(newFinding);
    };
    const showAll = (show: boolean) => {
        let newFinding = { ...props.finding };
        Object.values(newFinding.config.independentVariables).forEach(
            (item) => {
                if (item != null) {
                    (item as any).show = show;
                }
            }
        );
        props.onChange(newFinding);
    };
    let independentVariables: HoldOutPredictionFinding["config"]["independentVariables"] =
        props.finding.config.independentVariables;
    return (
        <div>
            <div className={styles.dataContainer}>
                <div />
                <div className={styles.checkboxColumn} style={{ height: 50 }}>
                    <div
                        className={styles.optionName}
                        style={{ marginBottom: 4 }}
                    >
                        include
                    </div>
                    <Checkbox
                        value={allIncluded}
                        onChange={() => {
                            includeAll(!allIncluded);
                        }}
                    />
                </div>
                <div className={styles.checkboxColumn} style={{ height: 50 }}>
                    <div
                        className={styles.optionName}
                        style={{ marginBottom: 4 }}
                    >
                        log
                    </div>
                    <Checkbox
                        value={allLogs}
                        onChange={() => {
                            logAll(!allLogs);
                        }}
                    />
                </div>
                <div className={styles.checkboxColumn} style={{ height: 50 }}>
                    <div
                        className={styles.optionName}
                        style={{ marginBottom: 4 }}
                    >
                        fixed
                    </div>
                    <Checkbox
                        value={allFixed}
                        onChange={() => {
                            fixAll(!allFixed);
                        }}
                    />
                </div>
                <div
                    className={styles.optionName}
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    interact with
                </div>

                <div className={styles.checkboxColumn} style={{ height: 50 }}>
                    <div
                        className={styles.optionName}
                        style={{ marginBottom: 4 }}
                    >
                        levers
                    </div>
                    <Checkbox
                        value={allShowed}
                        onChange={() => {
                            showAll(!allShowed);
                        }}
                    />
                </div>

                <div className={cx(styles.optionName, styles.mediumOptionName)}>
                    intercept
                </div>
                <div className={styles.checkboxColumn}>
                    <Checkbox
                        value={props.finding.config.intercept}
                        onChange={() => {
                            let newFinding = { ...props.finding };
                            newFinding.config.intercept = !newFinding.config
                                .intercept;
                            props.onChange(newFinding);
                        }}
                    />
                </div>
                <div />

                <div />
                <div />
                <div />
                {variables.map((item, index) => (
                    <React.Fragment key={index}>
                        <div
                            className={cx(
                                styles.optionName,
                                styles.mediumOptionName
                            )}
                        >
                            {item.label}
                        </div>
                        <div className={styles.checkboxColumn}>
                            <Checkbox
                                value={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ] != null
                                }
                                onChange={() => {
                                    let newFinding = { ...props.finding };
                                    if (
                                        props.finding.config
                                            .independentVariables[item.value] ==
                                        null
                                    ) {
                                        newFinding.config.independentVariables[
                                            item.value
                                        ] = {
                                            show: true,
                                            log: false,
                                            fixed:
                                                item.type !== "int" &&
                                                item.type !== "float",
                                            label: item.label,
                                            value: item.value,
                                        };
                                    } else {
                                        newFinding.config.independentVariables[
                                            item.value
                                        ] = null;
                                        newFinding.config.interactions[
                                            item.value
                                        ] = null;
                                        for (let key of Object.keys(
                                            newFinding.config.interactions
                                        )) {
                                            let value =
                                                newFinding.config.interactions[
                                                    key
                                                ];
                                            if (value === item.value)
                                                newFinding.config.interactions[
                                                    key
                                                ] = null;
                                        }
                                    }

                                    props.onChange(newFinding);
                                }}
                            />
                        </div>
                        <div className={styles.checkboxColumn}>
                            <Checkbox
                                value={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ]?.log
                                }
                                disabled={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ] == null ||
                                    (item.type !== "int" &&
                                        item.type !== "float")
                                }
                                onChange={() => {
                                    let newFinding = { ...props.finding };
                                    newFinding.config.independentVariables[
                                        item.value
                                    ].log = !newFinding.config
                                        .independentVariables[item.value].log;
                                    props.onChange(newFinding);
                                }}
                            />
                        </div>
                        <div className={styles.checkboxColumn}>
                            <Checkbox
                                value={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ]?.fixed
                                }
                                disabled={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ] == null ||
                                    (item.type !== "int" &&
                                        item.type !== "float")
                                }
                                onChange={() => {
                                    let newFinding = { ...props.finding };
                                    newFinding.config.independentVariables[
                                        item.value
                                    ].fixed = !newFinding.config
                                        .independentVariables[item.value].fixed;
                                    props.onChange(newFinding);
                                }}
                            />
                        </div>
                        {item.value in independentVariables ? (
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                isClearable
                                backspaceRemovesValue
                                menuPortalTarget={document.body}
                                placeholder={"Select"}
                                styles={{
                                    ...regressionStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={Object.values(
                                    independentVariables
                                ).filter(
                                    (
                                        intependentItem: HoldOutPredictionFinding["config"]["independentVariables"][number]
                                    ) =>
                                        intependentItem != null &&
                                        intependentItem.value !== item?.value &&
                                        props.finding.config.interactions[
                                            intependentItem.value
                                        ] !== item?.value
                                )}
                                onChange={(newValue) => {
                                    let newFinding = { ...props.finding };
                                    if (newValue != null) {
                                        newFinding.config.interactions = {
                                            ...newFinding.config.interactions,
                                            [item.value]: (newValue as any)
                                                .value,
                                        };
                                    } else {
                                        newFinding.config.interactions = {
                                            ...newFinding.config.interactions,
                                            [item.value]: null,
                                        };
                                    }
                                    props.onChange(newFinding);
                                }}
                                value={
                                    Object.values(independentVariables).find(
                                        (independentItem: any) =>
                                            independentItem != null &&
                                            props.finding.config.interactions?.[
                                                item.value
                                            ] === independentItem.value
                                    ) ?? null
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        ) : (
                            <div />
                        )}
                        <div className={styles.checkboxColumn}>
                            <Checkbox
                                disabled={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ] == null
                                }
                                value={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ]?.show
                                }
                                onChange={() => {
                                    let newFinding = { ...props.finding };
                                    newFinding.config.independentVariables[
                                        item.value
                                    ].show = !newFinding.config
                                        .independentVariables[item.value].show;
                                    props.onChange(newFinding);
                                }}
                            />
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className={styles.buttonsContainer}>
                <Button
                    type="button"
                    disabled={
                        props.updatingDashboard ||
                        addingPrediction ||
                        allExcluded ||
                        props.finding.config.dependentVariable == null
                    }
                    className="btn btn-sm btn-primary my-primary"
                    style={{
                        width: "62px",
                        height: "35px",
                    }}
                    onClick={() => {
                        props.onChange({ ...props.finding }, true);
                    }}
                >
                    Run
                </Button>
                <Button
                    type="button"
                    disabled={
                        addingPrediction ||
                        props.finding?.content.regressionInfo == null
                    }
                    className="btn btn-sm btn-primary my-primary"
                    style={{
                        marginLeft: "10px",
                        width: "200px",
                        height: "35px",
                    }}
                    onClick={() => {
                        let finding = { ...props.finding };
                        finding.content.data = {
                            ...finding.content.data,
                            userValues: null,
                        };
                        props.onChange(finding, false);
                    }}
                >
                    Reset to means
                </Button>
            </div>
            <div className={styles.buttonsContainer}>
                <Button
                    type="button"
                    disabled={
                        props.updatingDashboard ||
                        addingPrediction ||
                        allExcluded ||
                        props.finding.config.dependentVariable == null ||
                        props.finding.content.data.predictedBars.length !== 1
                    }
                    className="btn btn-sm btn-primary my-primary"
                    style={{
                        width: "272px",
                        height: "35px",
                    }}
                    onClick={() => {
                        setAddingPrediction(true);
                        let variableNamesSet = new Set(
                            props.variableOptions.map((option) => option.label)
                        );
                        let newVariableName = `${props.finding.config.dependentVariable.label}_predicted`;
                        let i = 2;
                        while (variableNamesSet.has(newVariableName)) {
                            newVariableName = `${props.finding.config.dependentVariable.label}_predicted_${i}`;
                            i += 1;
                        }
                        modelPredictAddVariable(
                            props.finding.config.selectedTable,
                            {
                                name: newVariableName,
                                unit: null,
                                type: Type.Float,
                                level: null,
                                format: null,
                                panel: Panel.Regular,
                            },
                            props.finding.content.data.predictedBars[0]
                                .model_id,
                            props.currentModuleId
                        )
                            .then(() => {
                                setAddingPrediction(false);
                            })
                            .catch((error) => {
                                setAddingPrediction(false);
                                console.error(error);
                                props.canvasTreeStore.canvasDashboardErrorsState.set(
                                    props.dashboardId,
                                    `Error: ${String(error)}`
                                );
                            });
                    }}
                >
                    Add prediction to dataset
                </Button>
            </div>
            {props.updatingDashboard && (
                <Alert
                    text={"Running regression"}
                    className="alert alert-warning alert-dismissible"
                />
            )}

            {addingPrediction && (
                <Alert
                    text={"Adding prediction to data set"}
                    className="alert alert-warning alert-dismissible"
                />
            )}
            {showSaveRegressionPopup && (
                <SaveRegressionResultsPopup
                    regressionResults={props.finding?.content.nativeData}
                    currentModuleId={props.currentModuleId}
                    onClose={() => {
                        setShowRegressionPopup(false);
                    }}
                />
            )}
        </div>
    );
}
