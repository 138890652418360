import React from "react";
import { useDrop } from "react-dnd";
import Popup from "reactjs-popup";
import cx from "classnames";
import NetworkComponent from "common/Network";
import { NetworkFinding } from "common/Finding";
import styles from "./Network.module.css";

const journeyNameToNodePlacementMap: Readonly<{
    [key: string]: "force" | "arc" | "edgebundling";
}> = {
    NetworkJourney: "force",
    ArcDiagramJourney: "arc",
    EdgeBundlingJourney: "edgebundling",
};

interface Props {
    dashboardId: string;
    data: NetworkFinding["content"]["data"];
    config: NetworkFinding["config"];
    preview?: boolean;
    editable?: boolean;
    dataSetMenuIsOpen?: boolean;
    columnDragActive?: boolean;
    onChangeData?: (data: any[], updateFinding?: boolean) => void;
    onChangeConfig?: (config: any, updateFinding?: boolean) => void;
}

export default function Network(props: Props): JSX.Element | null {
    let [popupData, setPopupData] = React.useState<
        NetworkFinding["content"]["data"][0] | null
    >(null);

    let [svgDragActive, setSvgDragActive] = React.useState<boolean>(false);

    const ref1 = React.useRef(null);
    const ref2 = React.useRef(null);
    const refWeight = React.useRef(null);
    const [collected1, drop1] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            if (props.onChangeConfig != null) {
                let config = {
                    ...props.config,
                    edgeSourceVariable: otherItem.content.variableName,
                    edgeSourceVariableIndex: otherItem.content.variableIndex,
                };
                if (!config.edgeTargetVariable) {
                    config.edgeTargetVariable = otherItem.content.variableName;
                    config.edgeTargetVariableIndex = otherItem.content.variableIndex;
                }
                props.onChangeConfig(config, true);
            }
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    const [collected2, drop2] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            if (props.onChangeConfig != null) {
                let config = {
                    ...props.config,
                    edgeTargetVariable: otherItem.content.variableName,
                    edgeTargetVariableIndex: otherItem.content.variableIndex,
                };
                if (!config.edgeSourceVariable) {
                    config.edgeSourceVariable = otherItem.content.variableName;
                    config.edgeSourceVariableIndex = otherItem.content.variableIndex;
                }
                props.onChangeConfig(config, true);
            }
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    const [collectedWeight, dropWeight] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            if (props.onChangeConfig != null) {
                let config = {
                    ...props.config,
                    edgeWeightVariable: otherItem.content.variableName,
                    edgeWeightVariableIndex: otherItem.content.variableIndex,
                };
                props.onChangeConfig(config, true);
            }
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    if (props.editable) {
        drop1(ref1);
        drop2(ref2);
        dropWeight(refWeight);
    }
    return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    overflow: "visible", //To Do
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    className={cx({ "cancel-drag": svgDragActive })}
                    style={{
                        minHeight: 100,
                        minWidth: 100,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <NetworkComponent
                        networkId={props.dashboardId}
                        edgeList={props.data}
                        attachNodeColorToTarget={
                            props.config.attachNodeColorToTarget
                        }
                        edgeColor={props.config.edgeColor}
                        nodeColor={props.config.nodeColor}
                        baseEdgeThickness={props.config.baseEdgeThickness}
                        config={props.config}
                        onChangeConfig={props.onChangeConfig}
                        onNodeClick={(nodeId) => {
                            // let data:
                            //     | NetworkFinding["content"]["data"][0]
                            //     | undefined = undefined;
                            // if (props.config.attachMetricsToTarget) {
                            //     data = props.data.find(
                            //         (edge) => edge.target === nodeId
                            //     );
                            // } else {
                            //     data = props.data.find(
                            //         (edge) => edge.source === nodeId
                            //     );
                            // }
                            // if (data != null && data.metrics != null) {
                            //     setPopupData(data);
                            // }
                        }}
                        dragActive={svgDragActive}
                        nodePlacement={
                            journeyNameToNodePlacementMap[
                                props.config.journeyName
                            ]
                        }
                        nodeLabelsDisplayMode={
                            props.config.nodeLabelsDisplayMode
                        }
                        nodeLabelsDisplayPosition={
                            props.config.nodeLabelsDisplayPosition
                        }
                    />
                </div>
            </div>
            <div
                className={cx(styles.pointerEventsButton, {
                    [styles.pointerEventsButtonActive]: svgDragActive,
                })}
                onClick={() =>
                    setSvgDragActive(
                        (pointerEventsActive) => !pointerEventsActive
                    )
                }
            >
                <i className="fa fa-hand-paper-o" aria-hidden="true" />
            </div>
            {popupData != null && (
                <Popup
                    arrow={true}
                    contentStyle={{
                        maxHeight: "100vh",
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    open={true}
                    onClose={() => {
                        setPopupData(null);
                    }}
                >
                    <div
                        className="dashboard-rect element"
                        style={{
                            overflowX: "visible",
                            overflowY: "auto",
                            boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                            borderRadius: 0,
                            alignItems: "center",
                            cursor: "pointer",
                            // minHeight: 600,
                            maxHeight: "100vh",
                            // width: 600,
                            padding: 15,
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt) => {
                            evt.stopPropagation();
                        }}
                    >
                        <span
                            className="regular-text"
                            style={{ whiteSpace: "nowrap" }}
                        >
                            {Object.entries(popupData.metrics ?? {})
                                .map(([key, value]) => `${key}: ${value}`)
                                .join("\n")}
                        </span>
                    </div>
                </Popup>
            )}
            <div
                style={{
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    display: props.columnDragActive ? "flex" : "none",
                }}
            >
                <div
                    ref={ref1}
                    style={{
                        width: "33%",
                        borderStyle: "dashed",
                        borderWidth: 1,
                        borderColor: collected1.hover ? "#36B743" : "#8DB8E3",
                        display: "flex",
                        background: collected1.hover ? "#F4FBF5" : "#EBF2F9",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <span
                        className="no-selection"
                        style={{
                            backgroundColor: "transparent",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "center",
                            color: "#333333",
                        }}
                    >
                        Drop node 1 variable here
                    </span>
                </div>
                <div
                    ref={ref2}
                    style={{
                        width: "33%",
                        borderStyle: "dashed",
                        borderWidth: 1,
                        borderColor: collected2.hover ? "#36B743" : "#8DB8E3",
                        display: "flex",
                        background: collected2.hover ? "#F4FBF5" : "#EBF2F9",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <span
                        className="no-selection"
                        style={{
                            backgroundColor: "transparent",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "center",
                            color: "#333333",
                        }}
                    >
                        Drop node 2 variable here
                    </span>
                </div>
                <div
                    ref={refWeight}
                    style={{
                        width: "33%",
                        borderStyle: "dashed",
                        borderWidth: 1,
                        borderColor: collectedWeight.hover
                            ? "#36B743"
                            : "#8DB8E3",
                        display: "flex",
                        background: collectedWeight.hover
                            ? "#F4FBF5"
                            : "#EBF2F9",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <span
                        className="no-selection"
                        style={{
                            backgroundColor: "transparent",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "center",
                            color: "#333333",
                        }}
                    >
                        Drop weight variable here
                    </span>
                </div>
            </div>
        </div>
    );
}
