import * as ls from "local-storage";
import {ButtonStyle, DropdownStyle, InputFieldStyle, RadioButtonsGroupStyle} from "common/Canvas";

export default class LastNodeOptionsStorage {
	// Button
	public static setLastButtonStyle(style: ButtonStyle): void {
		ls.set("lastButtonsStyle", style);
	}
	public static lastButtonsStyle(): ButtonStyle {
		return ls.get("lastButtonsStyle") ?? ButtonStyle.Default;
	}
	// Dropdown
	public static setLastDropdownStyle(style: DropdownStyle): void {
		ls.set("lastDropdownStyle", style);
	}
	public static lastDropdownStyle(): DropdownStyle {
		return ls.get("lastDropdownStyle") ?? DropdownStyle.Default;
	}
	// Input Field
	public static setLastInputFieldStyle(style: InputFieldStyle): void {
		ls.set("lastInputFieldStyle", style);
	}
	public static lastInputFieldStyle(): InputFieldStyle {
		return ls.get("lastInputFieldStyle") ?? InputFieldStyle.Default;
	}
	// Radio buttons group
	public static setLastRadioButtonsGroupStyle(style: RadioButtonsGroupStyle): void {
		ls.set("lastRadioButtonsGroupStyle", style);
	}
	public static lastRadioButtonsGroupStyle(): RadioButtonsGroupStyle {
		return ls.get("lastRadioButtonsGroupStyle") ?? RadioButtonsGroupStyle.Default;
	}
	// External Link
	public static setLastExternalLink(external: boolean) {
		ls.set("lastExternalLink", external)
	}
	public static lastExternalLink(): boolean {
		return ls.get("lastExternalLink") ?? false;
	}
	// Text Link
	public static setLastTextLink(textLink: boolean) {
		ls.set("lastTextLink", textLink)
	}
	public static lastTextLink(): boolean {
		return ls.get("lastTextLink") ?? false;
	}
}