import React from "react";
import FunnelPlot from "common/graphics/v2/FunnelPlot";
import { FunnelPlotFinding } from "common/Finding";

function MainComponent(props: {
    finding: FunnelPlotFinding;
    onNewFinding?: (finding: FunnelPlotFinding, updateData?: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    dataSetMenuIsOpen?: boolean;
    columnDragActive?: boolean;
    nodePosition?: {
        x: number;
        y: number;
    };
}) {
    let finding = props.finding;
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <FunnelPlot
                dataSetMenuIsOpen={props.dataSetMenuIsOpen}
                preview={props.preview}
                editable={props.editable}
                columnDragActive={props.columnDragActive}
                data={finding.content.data}
                config={finding.config}
                nodePosition={props.nodePosition}
                onChangeData={
                    props.onNewFinding
                        ? (data: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.content.data = data;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeConfig={
                    props.onNewFinding
                        ? (config: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.config = config;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
            />
        </div>
    );
}

export { MainComponent };
