import React, { Dispatch, SetStateAction, useState } from "react";

const TosContext = React.createContext<TosContextType | null>(null);

export const TosProvider: React.FC = ({ children }) => {
  const [agreed, setAgreed] = useState(true);
  return (
    <TosContext.Provider value={{ agreed, setAgreed }}>
      {children}
    </TosContext.Provider>
  );
};

export interface TosContextType {
  agreed: boolean;
  setAgreed: Dispatch<SetStateAction<boolean>>;
}

export default TosContext;