import React from "react";
import {
    getContainerStyleByTheme,
    getDefaultColorsByTheme,
} from "./BarChartTheme";
import CustomBarChart from "./CustomBarChart";
import { Element } from "react-scroll";
import { LegendElement } from "./LegendElement";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import sections from "sections.json";
import { LegendEditElement } from "./LegendEditElement";
import { mainStyle } from "common/MainStyle";

function transposeData(data, dataKeys) {
	let newData = {};
	let newDataKeys = data.map((item) => item.name);
	for (let dataKey of dataKeys) {
		newData[dataKey] = {
			name: dataKey,
		};
	}
	for (let item of data) {
		for (let dataKey of dataKeys) {
			newData[dataKey][item.name] = item[dataKey] ?? 0;
			if (!newData[dataKey].units) newData[dataKey].units = item.units;
		}
	}
	return {
		newData: Object.values(newData),
		newDataKeys: newDataKeys,
	};
}

function CompareVariablesChart(props) {
	let {
		editable,
		error,
		data,
		dataKeys,
		config,
		//width
	} = props;
	let {
		barSize,
		barGap,
		barCategoryGap,
		chartType,
		nameMapping,
		labelMapping,
		colorMapping,
		errorBarColor,
		xAxisName,
		yAxisName,
		axesColor,
		legendsColor,
		maxYRange,
		minYRange,
		linesCount,
		statusExpressions,
		selectedTargetVariablesIndices,
		chartTheme,
		baseBackgroundColor,
	} = config;
	let { newData, newDataKeys } = transposeData(data, dataKeys);
	if (error != null) {
		let newError = transposeData(error, dataKeys)["newData"];
		for (let i = 0; i < newData.length; ++i) {
			for (let barKey of newDataKeys) {
				newData[i]["%error_".concat(barKey)] = newError[i][barKey];
			}
		}
	}
	let defaultOptions = getDefaultColorsByTheme(chartTheme);
	let containerStyle = getContainerStyleByTheme(
		chartTheme,
		baseBackgroundColor ?? defaultOptions.baseBackgroundColor
	);
	let colors = defaultOptions.barColors;
	let defaultAxesColor = defaultOptions.axesColor;
	return (
		<div
			className="flex-simple-column"
			style={{
				width: "100%",
				height: "100%",
				justifyContent: "center",
			}}
		>
			<div
				className="my-row"
				style={{
					flexWrap: "wrap",
					justifyContent: "center",
					marginLeft: 20,
					marginRight: 20,
					marginTop: 10,
					marginBottom: 10,
					width: "100%",
					minHeight: 16,
				}}
			>
				{editable && (
					<LegendEditElement
						allowColorChange
						disallowTextChange
						onFinishColor={(color) => {
							props.onConfigChange("baseBackgroundColor", color);
						}}
						key={newDataKeys.length}
						color={
							baseBackgroundColor ??
							defaultOptions.baseBackgroundColor
						}
						text={"Background"}
					/>
				)}
				{newDataKeys.map((barKey, index) => {
					return editable ? (
						<LegendEditElement
							allowColorChange
							onFinishEdit={(label) => {
								props.onFinishEdit(barKey, label);
							}}
							onFinishColor={(color) => {
								props.onFinishColor(barKey, color);
							}}
							key={index}
							color={
								colorMapping?.[barKey] ??
								colors[index % colors.length]
							}
							text={nameMapping?.[barKey] ?? barKey}
							textColor={legendsColor}
						/>
					) : (
						<LegendElement
							key={index}
							textColor={legendsColor}
							color={
								colorMapping?.[barKey] ??
								colors[index % colors.length]
							}
							text={nameMapping?.[barKey] ?? barKey}
						/>
					);
				})}
				{error != null && editable && (
					<LegendEditElement
						allowColorChange
						disallowTextChange
						onFinishColor={(color) => {
							props.onConfigChange("errorBarColor", color);
						}}
						color={errorBarColor ?? "black"}
						text={"Error Bars"}
					/>
				)}
				{editable && (
					<LegendEditElement
						allowColorChange
						disallowTextChange
						onFinishColor={(color) => {
							props.onConfigChange("axesColor", color);
						}}
						color={axesColor ?? defaultAxesColor}
						text={"Axes Color"}
					/>
				)}
				{editable && (
					<LegendEditElement
						allowColorChange
						disallowTextChange
						onFinishColor={(color) => {
							props.onConfigChange("legendsColor", color);
						}}
						color={
							legendsColor ??
							mainStyle
								.getPropertyValue("--graphs-legends-text-color")
								.trim()
						}
						text={"Legends Color"}
					/>
				)}
			</div>
			<div
				className="my-row"
				style={{
					justifyContent: "center",
					height: "100%",
					width: "100%",
				}}
			>
				<Element
					name="scrollable"
					className="element"
					style={{
						height: "100%",
						overflowX: mobileBreakpoint() ? "hidden" : "auto",
						overflowY: "hidden",
						position: "relative",
						...containerStyle,
					}}
				>
					<div
						className="my-row"
						style={{
							paddingTop: 10,
							height: "100%",
						}}
					>
						{editable ? (
							<input
								defaultValue={yAxisName}
								onKeyDown={(evt) => {
									evt.stopPropagation();
								}}
								placeholder="Enter Y-Axis Name"
								onBlur={(e) => {
									e.preventDefault();
									props.onConfigChange(
										"yAxisName",
										e.target.value
									);
								}}
								style={{
									width: "110px",
									alignSelf: "center",
									backgroundColor: "transparent",
									outline: "none",
									color: axesColor ?? defaultAxesColor,
									fontFamily: "Arial",
									fontSize: mainStyle.getPropertyValue(
										"--graphs-axes-size"
									),
								}}
							></input>
						) : yAxisName != null ? (
							<span
								style={{
									alignSelf: "center",
									textAlign: "right",
									color: axesColor ?? defaultAxesColor,
									fontFamily: "Arial",
									fontSize: mainStyle.getPropertyValue(
										"--graphs-axes-size"
									),
								}}
							>
								{yAxisName}
							</span>
						) : null}
						<div
							className="flex-simple-column"
							style={{ height: "100%" }}
						>
							<CustomBarChart
								chartTheme={chartTheme}
								baseBackgroundColor={
									baseBackgroundColor ??
									defaultOptions.baseBackgroundColor
								}
								statusExpressions={statusExpressions}
								barIndices={selectedTargetVariablesIndices}
								minYRange={minYRange}
								linesCount={linesCount}
								maxYRange={maxYRange}
								errorBarColor={errorBarColor}
								axesColor={axesColor ?? defaultAxesColor}
								showError={error != null}
								colorMapping={colorMapping}
								labelMapping={labelMapping}
								nameMapping={nameMapping}
								barSize={barSize}
								barGap={barGap}
								barCategoryGap={barCategoryGap}
								watermark={sections._Global?.watermark}
								showUnits
								barKeys={newDataKeys}
								barColors={colors}
								chartType={chartType}
								data={newData}
								title={""}
							/>
							{editable && (
								<div
									className="my-row"
									style={{ alignSelf: "center" }}
								>
									{dataKeys.map((key) => (
										<input
											key={key}
											defaultValue={
												labelMapping?.[key] ?? key
											}
											onKeyDown={(evt) => {
												evt.stopPropagation();
											}}
											placeholder=""
											onBlur={(e) => {
												e.preventDefault();
												props.onFinishEditLabel(
													key,
													e.target.value
												);
											}}
											style={{
												width: "100px",
												alignSelf: "center",
												backgroundColor: "transparent",
												outline: "none",
												color:
													axesColor ??
													defaultAxesColor,
												fontFamily: "Arial",
												fontSize: mainStyle.getPropertyValue(
													"--graphs-axes-size"
												),
											}}
										></input>
									))}
								</div>
							)}
							{editable ? (
								<input
									defaultValue={xAxisName}
									onKeyDown={(evt) => {
										evt.stopPropagation();
									}}
									placeholder="Enter X-Axis Name"
									onBlur={(e) => {
										e.preventDefault();
										props.onConfigChange(
											"xAxisName",
											e.target.value
										);
									}}
									style={{
										marginTop: "5px",
										width: "110px",
										alignSelf: "center",
										backgroundColor: "transparent",
										outline: "none",
										color: axesColor ?? defaultAxesColor,
										fontFamily: "Arial",
										fontSize: mainStyle.getPropertyValue(
											"--graphs-axes-size"
										),
									}}
								></input>
							) : xAxisName != null ? (
								<span
									style={{
										alignSelf: "center",
										textAlign: "right",
										color: axesColor ?? defaultAxesColor,
										fontFamily: "Arial",
										fontSize: mainStyle.getPropertyValue(
											"--graphs-axes-size"
										),
									}}
								>
									{xAxisName}
								</span>
							) : null}
						</div>
					</div>
				</Element>
			</div>
		</div>
	);
}

export default CompareVariablesChart;