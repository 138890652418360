import React from "react";
import TimePatternsChart from "common/graphics/TimePatternsChart";

function MainComponent(props) {
	let finding = props.finding;

	return (
		<div style={{ width: "100%", height: "100%" }}>
			<TimePatternsChart
				config={finding.config}
				isTimeSeries={finding.content.isTimeSeries}
				data={finding.content.data}
				additionalData={finding.content.additionalData}
				timeKey={finding.content.timeKey}
				displayMode={finding.config.displayMode}
				dateFormat={finding.config.dateFormat}
			/>
		</div>
	);
}

export { MainComponent };
