import React, { useState, useEffect } from "react";

import {
    BoxPlotFinding,
    defaultBoxPlotChartColor,
    defaultBoxPlotStrokeColor,
    defaultBoxPlotOutlierCount,
} from "common/Finding";
import EditableAxisItem from "../EditableAxisItem";
import * as d3 from "d3";
import { TooltipStyles } from "../../TooltipStyles";
import { mainStyle } from "common/MainStyle";
import {
    getGridColorByTheme,
    getDefaultColorsByTheme,
} from "../../BarChartTheme";
import { formatValue } from "common/utilities/FormatValue";
import D3ChartBase, { AxisType } from "common/graphics/v2/D3ChartBase";
import Portal from "common/Portal";
import AnimationSlider from "common/AnimationSlider";
import Popup from "reactjs-popup";
import { chartColorPickerPopupStyles } from "common/Constants";
import ColorPicker from "../ChartColorPicker";
import { colorList } from "common/graphics/LineColors";
import { getTextSize } from "common/utilities/MeasureText";
import { useUpdateEffect } from "common/CustomHooks";
import { calculateChartColorPickerPosition, getLongestString } from "../utils";

interface Props {
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    content: BoxPlotFinding["content"];
    config: BoxPlotFinding["config"];
    onChangeData?: (
        data: BoxPlotFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: BoxPlotFinding["config"],
        updateData?: boolean
    ) => void;
    onChangeContent?: (
        content: BoxPlotFinding["content"],
        updateData?: boolean
    ) => void;
    width: number;
    height: number;
    scale: number;
    selected: boolean;
}

interface Point {
    x: string;
    y: number;
}

type Datum = [
    string,
    {
        q1: number;
        median: number;
        q3: number;
        interQuantileRange: number;
        min: number;
        max: number;
        outliers: Point[];
    }
];

export default function BoxPlot(props: Props) {
    let [showTooltipInfo, setShowTooltipInfo] = useState<{
        x: number;
        y: number;
        box?: Datum;
        outlier?: Point;
    } | null>(null);
    let [longestYAxisValue, setLongestYAxisValue] = useState<string>("0");
    let [colorPickerIsEnabled, setColorPickerIsEnabled] = useState<boolean>(
        false
    );
    let [axisItemStyles, setAxisItemStyles] = useState<Record<any, number>>({
        xAxisWidth: 0,
        yAxisHeight: 0,
        xAxisOffset: 0,
        yAxisOffset: 0,
    });
    let [showColorPicker, setShowColorPicker] = useState<{
        x: number;
        y: number;
    } | null>(null);

    let parentRef = React.useRef<HTMLDivElement>(null);
    let parentSvgRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);
    let timeValueRef = React.useRef<string | number | null>();
    let timeAnimationTickRef = React.useRef<() => void>();
    let gridGRef = React.useRef<
        d3.Selection<SVGGElement, unknown, null, undefined>
    >();

    let currentEditVariableIndex: number | undefined = undefined;
    if (props.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = props.content.data.findIndex(
            (item) => item.variableIndex == null
        );
    }

    let onChangeName = (index: number, value: string) => {
        let newData: BoxPlotFinding["content"]["data"] = [
            {
                ...props.content.data[0],
            },
            {
                ...props.content.data[1],
            },
        ];
        newData[index].name = value;
        props.onChangeData?.(newData);
    };

    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData: BoxPlotFinding["content"]["data"] = [
            {
                ...props.content.data[0],
            },
            {
                ...props.content.data[1],
            },
        ];
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        props.onChangeData?.(newData, true);
    };

    useUpdateEffect(() => {
        setTimeout(() => {
            setColorPickerIsEnabled(props.selected);
        }, 100);
    }, [props.selected]);

    useEffect(() => {
        let xAxisFontSize: number =
            props.config.ticksAndLabels?.x?.size ??
            props.config.ticksSize ??
            parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

        let yAxisFontSize: number =
            props.config.ticksAndLabels?.y?.size ??
            props.config.ticksSize ??
            parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

        let defaultColors = getDefaultColorsByTheme(props.config.chartTheme);
        // let themeOptions = getOptionsByTheme(props.config.chartTheme);
        let gridFillColor = getGridColorByTheme(
            props.config.chartTheme,
            props.config.baseBackgroundColor
        );

        let [minY, maxY] = d3.extent(props.content.data[1].value);
        minY = minY ?? 0;
        maxY = maxY ?? 10;

        let minYRange = props.config.minYRange ?? minY!;
        let maxYRange = props.config.maxYRange ?? maxY!;

        const yAxisExtraSpace =
            getTextSize(longestYAxisValue, "Roboto", yAxisFontSize, "normal")
                .width + 7;

        const xAxisExtraSpace =
            getTextSize("0", "Roboto", xAxisFontSize, "normal").height / 2 - 22;

        // Padding is necessary to prevent tick text from being cut off
        let fullHeight = parentSvgRef.current?.clientHeight ?? 0;
        let fullWidth = parentSvgRef.current?.clientWidth ?? 0;
        let yAxisWidth = 50;
        let xAxisHeight = 30;
        // set the dimensions and margins of the graph
        let height = fullHeight - xAxisHeight;
        // We subtract yAxisExtraSpace to prevent the chart from shifting to
        // the right and cutting off axis label
        // https://eisengardai.atlassian.net/browse/EIS-259?focusedCommentId=12079
        let width = fullWidth - yAxisWidth - yAxisExtraSpace;

        setAxisItemStyles({
            xAxisWidth: width,
            yAxisHeight: height + 2,
            xAxisOffset: yAxisExtraSpace,
            yAxisOffset: xAxisExtraSpace,
        });

        // append the svg object to the body of the page
        d3.select(svgRef.current!).selectAll("*").remove();
        let svg = d3
            .select(svgRef.current!)
            .attr("width", fullWidth)
            .attr("height", fullHeight)
            .append("g")
            .style("cursor", "crosshair")
            .attr(
                "transform",
                `translate(${yAxisExtraSpace}, ${-xAxisExtraSpace})`
            );

        let base = new D3ChartBase(svg, width, height);

        base.drawBackground(gridFillColor ?? "transparent");

        // Calculate ticks
        let {
            ticks: yTicks,
            decimals: yDecimals,
        } = D3ChartBase.calculateLinearTicks(
            minYRange,
            maxYRange,
            props.config?.ticksAndLabels?.y?.interval
        );

        let yTickFormat = (tick: number) => tick.toFixed(yDecimals);

        // X Axis
        let xValues = props.content.data[0].value.map(String);
        let { axis: xAxis } = base.drawBandAxis(
            AxisType.XAxis,
            {
                color:
                    props.config.axesLinesColor ?? defaultColors.axesLinesColor,
            },
            {
                color:
                    props.config.ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: xAxisFontSize,
                fontFamily: "Open Sans",
                tickSize: 0,
            },
            xValues
        );
        let xScale = xAxis.scale<d3.ScaleBand<string>>();

        // Y Axis
        let { axis: yAxis } = base.drawLinearAxis(
            AxisType.YAxis,
            {
                color:
                    props.config.axesLinesColor ?? defaultColors.axesLinesColor,
            },
            {
                color:
                    props.config.ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: yAxisFontSize,
                fontFamily: "Open Sans",
                tickSize: 0,
                tickValues: yTicks,
                tickFormat: yTickFormat,
            },
            [minYRange, maxYRange],
            undefined,
            {
                end: 5,
            }
        );
        let yScale = yAxis.scale<d3.ScaleLinear<number, number>>();

        const longestYValue = getLongestString(yTicks.map(yTickFormat));

        if (longestYAxisValue !== longestYValue) {
            setLongestYAxisValue(longestYValue);
        }

        let outlierCount =
            props.config.outlierCount ?? defaultBoxPlotOutlierCount;
        let minOutlierCount = Math.floor(outlierCount / 2);
        let maxOutlierCount = outlierCount - minOutlierCount;

        let chartG = svg.append("g");

        timeValueRef.current = props.content.time?.uniqueValues[0];

        timeAnimationTickRef.current = () => {
            let plotData: Point[];
            if (timeValueRef.current == null) {
                plotData = props.content.data[0].value.map((x, index) => ({
                    x: String(x),
                    y: props.content.data[1].value[index],
                }));
            } else {
                plotData = [];
                for (
                    let index = 0;
                    index < props.content.data[0].value.length;
                    ++index
                ) {
                    let time = props.content.time?.value[index];
                    if (time == null || time <= timeValueRef.current) {
                        plotData.push({
                            x: String(props.content.data[0].value[index]),
                            y: props.content.data[1].value[index],
                        });
                    }
                }
            }

            plotData = plotData.filter(
                (data) => data.y >= minYRange! && data.y <= maxYRange!
            );

            let rollup = d3.rollup(
                plotData,
                (points) => {
                    let q1 = d3.quantile(points, 0.25, (d) => d.y)!;
                    let median = d3.quantile(points, 0.5, (d) => d.y)!;
                    let q3 = d3.quantile(points, 0.75, (d) => d.y)!;
                    let interQuantileRange = q3 - q1;
                    let lower = q1 - 1.5 * interQuantileRange;
                    let upper = q3 + 1.5 * interQuantileRange;
                    let min = Infinity;
                    let max = -Infinity;
                    for (let point of points) {
                        if (point.y >= lower && point.y < min) {
                            min = point.y;
                        }
                        if (point.y <= upper && point.y > max) {
                            max = point.y;
                        }
                    }
                    let outliers: Point[];
                    if (outlierCount > 0) {
                        outliers = points.filter((d) => d.y < min || d.y > max);
                        if (outliers.length > outlierCount) {
                            // We can do this in 2n instead of n*log(n), but this
                            // would only make a difference for very large arrays
                            outliers.sort((a, b) => a.y - b.y);
                            outliers = outliers
                                .slice(0, minOutlierCount)
                                .concat(
                                    outliers.slice(
                                        outliers.length - maxOutlierCount,
                                        outliers.length
                                    )
                                );
                        }
                    } else {
                        outliers = [];
                    }
                    return {
                        q1: q1,
                        median: median,
                        q3: q3,
                        interQuantileRange: interQuantileRange,
                        min: min,
                        max: max,
                        outliers: outliers,
                    };
                },
                (d) => d.x
            );

            chartG.selectAll("*").remove();

            let boxG = chartG
                .selectAll<SVGGElement, Datum>("g")
                .data(rollup)
                .join("g");

            // vertical lines
            boxG.append("line")
                .attr("x1", (d) => xScale(d[0])! + xScale.bandwidth() / 2)
                .attr("x2", (d) => xScale(d[0])! + xScale.bandwidth() / 2)
                .attr("y1", (d) => yScale(d[1].min))
                .attr("y2", (d) => yScale(d[1].max))
                .attr(
                    "stroke",
                    props.config.strokeColor ?? defaultBoxPlotStrokeColor
                );

            // box
            let boxWidth = Math.max(0.75 * xScale.bandwidth(), 2);
            boxG.append("rect")
                .attr(
                    "x",
                    (d) => xScale(d[0])! + xScale.bandwidth() / 2 - boxWidth / 2
                )
                .attr("y", (d) => yScale(d[1].q3))
                .attr("height", (d) => yScale(d[1].q1) - yScale(d[1].q3))
                .attr("width", boxWidth)
                .attr(
                    "stroke",
                    props.config.strokeColor ?? defaultBoxPlotStrokeColor
                )
                .style(
                    "fill",
                    props.config.chartColor ?? defaultBoxPlotChartColor
                );

            // median, max, min
            for (let property of ["median", "max", "min"] as const) {
                boxG.append("line")
                    .attr(
                        "x1",
                        (d) =>
                            xScale(d[0])! +
                            xScale.bandwidth() / 2 -
                            boxWidth / 2
                    )
                    .attr(
                        "x2",
                        (d) =>
                            xScale(d[0])! +
                            xScale.bandwidth() / 2 +
                            boxWidth / 2
                    )
                    .attr("y1", (d) => yScale(d[1][property]))
                    .attr("y2", (d) => yScale(d[1][property]))
                    .attr(
                        "stroke",
                        props.config.strokeColor ?? defaultBoxPlotStrokeColor
                    );
            }

            // Invisible rectangles for the tooltip
            boxG.append("rect")
                .attr(
                    "x",
                    (d) => xScale(d[0])! + xScale.bandwidth() / 2 - boxWidth / 2
                )
                .attr("y", (d) => yScale(d[1].max))
                .attr("height", (d) => yScale(d[1].min) - yScale(d[1].max))
                .attr("width", boxWidth)
                .attr("stroke", null)
                .style("fill", "transparent")
                .on("mouseenter", (event: MouseEvent, box: Datum) => {
                    setShowTooltipInfo({
                        x: event.x,
                        y: event.y,
                        box: box,
                    });
                })
                .on("mousemove", (event: MouseEvent, box: Datum) => {
                    setShowTooltipInfo((showTooltipInfo) => {
                        if (showTooltipInfo == null) return null;
                        return {
                            x: event.x,
                            y: event.y,
                            box: box,
                        };
                    });
                })
                .on("mouseleave", (_event: MouseEvent) => {
                    setShowTooltipInfo(null);
                })
                .on("click", (evt) => {
                    const { y } = calculateChartColorPickerPosition(evt);
                    if (colorPickerIsEnabled) {
                        setShowColorPicker({
                            x: evt.clientX + 5,
                            y: y + 5,
                        });
                    }
                });

            // outliers
            boxG.selectAll<SVGCircleElement, Datum[1]["outliers"]>("circle")
                .data((d) => d[1].outliers)
                .join("circle")
                .attr("cx", (d) => xScale(d.x)! + xScale.bandwidth() / 2)
                .attr("cy", (d) => yScale(d.y))
                .attr("r", 3)
                .attr(
                    "fill",
                    props.config.chartColor ?? defaultBoxPlotChartColor
                )
                .attr(
                    "stroke",
                    props.config.strokeColor ?? defaultBoxPlotStrokeColor
                )
                .on("mouseenter", (event: MouseEvent, outlier: Point) => {
                    setShowTooltipInfo({
                        x: event.x,
                        y: event.x,
                        outlier: outlier,
                    });
                })
                .on("mousemove", (event: MouseEvent, outlier: Point) => {
                    setShowTooltipInfo((showTooltipInfo) => {
                        if (showTooltipInfo == null) return null;
                        return {
                            x: event.x,
                            y: event.y,
                            outlier: outlier,
                        };
                    });
                })
                .on("mouseleave", (_event: MouseEvent) => {
                    setShowTooltipInfo(null);
                });

            // Grid
            if (props.config.showGrid) {
                if (gridGRef.current != null) {
                    gridGRef.current.remove();
                }
                gridGRef.current = base.drawGrid(
                    [],
                    yTicks,
                    props.config.axesLinesColor ?? defaultColors.gridColor,
                    () => 0,
                    yScale
                );
            }
        };

        timeAnimationTickRef.current();
    }, [
        props.content.data,
        props.content.time,
        props.content.time?.value,
        props.content.time?.uniqueValues,
        props.config.ticksAndLabels,
        props.config.chartTheme,
        props.config.baseBackgroundColor,
        props.config.linesCount,
        props.config.showXAxisName,
        props.config.showYAxisName,
        props.config.minYRange,
        props.config.maxYRange,
        props.config.count,
        props.config.trendline,
        props.config.random,
        props.config.trendlineColor,
        props.config.axesLinesColor,
        props.config.chartColor,
        props.config.strokeColor,
        props.config.showGrid,
        props.config.ticksColor,
        props.config.ticksSize,
        props.config.outlierCount,
        props.preview,
        props.width,
        props.height,
        props.scale,
        longestYAxisValue,
        colorPickerIsEnabled,
    ]);

    let tooltipStyle = {
        ...TooltipStyles(
            props.config.tooltipColor,
            props.config.tooltipFontSize
        ),
    };
    return (
        <div style={{ height: "100%" }}>
            <div
                ref={parentRef}
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {props.content.time?.variableIndex != null &&
                    props.content.time?.uniqueValues.length !== 0 && (
                        <AnimationSlider
                            sliderStyle={{
                                cursor: "default",
                                width: 165,
                                pointerEvents: "auto",
                            }}
                            values={props.content.time.uniqueValues}
                            onChange={(index) => {
                                timeValueRef.current = props.content.time!.uniqueValues[
                                    index
                                ];
                                timeAnimationTickRef.current!();
                            }}
                        />
                    )}
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "space-between",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <div
                            className="flex-simple-column"
                            style={{ width: "48px", height: "100%" }}
                        >
                            <div
                                style={{
                                    height: axisItemStyles.yAxisHeight,
                                    position: "relative",
                                    top: -axisItemStyles.yAxisOffset - 1,
                                }}
                            >
                                {props.config.showYAxisName && (
                                    <EditableAxisItem
                                        onChange={(value) => {
                                            onChangeName(1, value);
                                        }}
                                        color={props.config.axesNamesColor}
                                        index={1}
                                        currentEditVariableIndex={
                                            currentEditVariableIndex
                                        }
                                        vertical
                                        name={props.content.data[1].name}
                                        onDrop={linkVariable}
                                        editable={props.editable}
                                    />
                                )}
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            <div
                                style={{
                                    width: 48,
                                    minHeight: 48,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {props.editable && (
                                    <div
                                        style={{
                                            width: 32,
                                            height: 32,
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // Resizing does not work properly
                            // without overflow: "hidden"
                            overflow: "hidden",
                        }}
                    >
                        <div
                            ref={parentSvgRef}
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                position: "relative",
                                // Resizing does not work properly
                                // without overflow: "hidden"
                                overflow: "hidden",
                            }}
                        >
                            {showTooltipInfo && (
                                <Portal rootNode={document.body}>
                                    <div
                                        style={{
                                            zIndex: 100000,
                                            position: "absolute",
                                            top: showTooltipInfo.y,
                                            left: showTooltipInfo.x,
                                            display: "flex",
                                            pointerEvents: "none",
                                            transform: "translateY(-50%)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: 10,
                                                    height: 10,
                                                    transform:
                                                        "translateX(50%) rotate(45deg)",
                                                    ...tooltipStyle.contentStyle,
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px",
                                                ...tooltipStyle.contentStyle,
                                                display: "flex",
                                                flexDirection: "column",
                                                zIndex: 100001,
                                            }}
                                        >
                                            {showTooltipInfo.box != null && (
                                                <>
                                                    <span
                                                        style={{
                                                            ...tooltipStyle.itemStyle,
                                                            color:
                                                                "rgb(85, 105, 125)",
                                                        }}
                                                        className="unselectable"
                                                    >{`${
                                                        props.content.data[0]
                                                            .name
                                                    }: ${formatValue(
                                                        showTooltipInfo.box[0]
                                                    ).join("")}`}</span>
                                                    <span
                                                        style={{
                                                            ...tooltipStyle.itemStyle,
                                                            color:
                                                                "rgb(85, 105, 125)",
                                                        }}
                                                        className="unselectable"
                                                    >{`Max: ${formatValue(
                                                        showTooltipInfo.box[1]
                                                            .max
                                                    ).join("")}`}</span>
                                                    <span
                                                        style={{
                                                            ...tooltipStyle.itemStyle,
                                                            color:
                                                                "rgb(85, 105, 125)",
                                                        }}
                                                        className="unselectable"
                                                    >{`Q3: ${formatValue(
                                                        showTooltipInfo.box[1]
                                                            .q3
                                                    ).join("")}`}</span>
                                                    <span
                                                        style={{
                                                            ...tooltipStyle.itemStyle,
                                                            color:
                                                                "rgb(85, 105, 125)",
                                                        }}
                                                        className="unselectable"
                                                    >{`Median: ${formatValue(
                                                        showTooltipInfo.box[1]
                                                            .median
                                                    ).join("")}`}</span>
                                                    <span
                                                        style={{
                                                            ...tooltipStyle.itemStyle,
                                                            color:
                                                                "rgb(85, 105, 125)",
                                                        }}
                                                        className="unselectable"
                                                    >{`Q1: ${formatValue(
                                                        showTooltipInfo.box[1]
                                                            .q1
                                                    ).join("")}`}</span>
                                                    <span
                                                        style={{
                                                            ...tooltipStyle.itemStyle,
                                                            color:
                                                                "rgb(85, 105, 125)",
                                                        }}
                                                        className="unselectable"
                                                    >{`Min: ${formatValue(
                                                        showTooltipInfo.box[1]
                                                            .min
                                                    ).join("")}`}</span>
                                                </>
                                            )}
                                            {showTooltipInfo.outlier !=
                                                null && (
                                                <>
                                                    <span
                                                        style={{
                                                            ...tooltipStyle.itemStyle,
                                                            color:
                                                                "rgb(85, 105, 125)",
                                                        }}
                                                        className="unselectable"
                                                    >{`${
                                                        props.content.data[0]
                                                            .name
                                                    }: ${formatValue(
                                                        showTooltipInfo.outlier
                                                            .x
                                                    ).join("")}`}</span>
                                                    <span
                                                        style={{
                                                            ...tooltipStyle.itemStyle,
                                                            color:
                                                                "rgb(85, 105, 125)",
                                                        }}
                                                        className="unselectable"
                                                    >{`${
                                                        props.content.data[1]
                                                            .name
                                                    }: ${formatValue(
                                                        showTooltipInfo.outlier
                                                            .y
                                                    ).join("")}`}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Portal>
                            )}
                            <svg ref={svgRef} />
                        </div>
                        <div
                            className="my-row"
                            style={{
                                // height: "48px" would not be enough since
                                // its size would change when x axis name is
                                // hidden. We have to set min and max height.
                                minHeight: "48px",
                                maxHeight: "48px",
                                position: "relative",
                                width: axisItemStyles.xAxisWidth,
                                left: axisItemStyles.xAxisOffset,
                            }}
                        >
                            {props.config.showXAxisName && (
                                <EditableAxisItem
                                    onChange={(value) => {
                                        onChangeName(0, value);
                                    }}
                                    color={props.config.axesNamesColor}
                                    currentEditVariableIndex={
                                        currentEditVariableIndex
                                    }
                                    editable={props.editable}
                                    index={0}
                                    vertical={false}
                                    name={props.content.data[0].name}
                                    onDrop={linkVariable}
                                    inputStyle={{
                                        width: "100%",
                                    }}
                                />
                            )}
                            <div style={{ flexGrow: 1 }} />
                        </div>
                    </div>
                </div>
            </div>
            {showColorPicker && (
                <Popup
                    arrow={true}
                    contentStyle={{
                        ...chartColorPickerPopupStyles,
                        left: showColorPicker.x,
                        top: showColorPicker.y,
                    }}
                    open={true}
                    onClose={() => {
                        setShowColorPicker(null);
                    }}
                    nested={true}
                    closeOnDocumentClick
                >
                    <ColorPicker
                        enableAlpha={true}
                        width={"220px"}
                        color={props.config.chartColor ?? colorList[0]}
                        onChange={(color) => {
                            let newConfig = {
                                ...props.config,
                                chartColor: color,
                            };
                            props.onChangeConfig?.(newConfig);
                        }}
                    />
                </Popup>
            )}
        </div>
    );
}
