import React from "react";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import customSelectStyles from "common/SelectStyles";
import axios from "common/ServerConnection";
import tables from "common/Tables";
import variables from "common/Variables";
import dataScopes from "common/DataScopes";
import { Permission } from "common/Permissions";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
                dataScope: undefined,
                updateSimplifiedOptimalPanel: undefined,
            };
            this.dataScopeValue = this.dataScopeValue.bind(this);
        }
        dataScopeValue() {
            if (this.state.dataScope) return this.state.dataScope.value;
            return undefined;
        }

        renderSimplifiedUpdateOptimal() {
            if (!this.state.expanded) return null;
            else {
                return (
                    <>
                        <div />
                        <div
                            style={{
                                gridColumnStart: 2,
                                gridColumnEnd: 4,
                                background: "#3f4b62",
                                padding: "10px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <span className="content-regular-text">Select data</span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginLeft: "5px",
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={dataScopes.dataScopesOptions.filter(
                                    (option) => option.permissionType === Permission.ReadWrite
                                )}
                                onChange={(newValue) =>
                                    this.setState({
                                        dataScope: newValue,
                                    })
                                }
                                value={this.state.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <span
                                className="content-regular-text"
                                style={{
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                }}
                            >
                                Insert simplified optmization results for panel
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                isClearable={true}
                                backspaceRemovesValue={true}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "15em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={variables(
                                    this.dataScopeValue()
                                ).dataVariables.map((value) => ({
                                    label: value.name,
                                    value: value.name,
                                }))}
                                onChange={(newValue) =>
                                    this.setState({
                                        updateSimplifiedOptimalPanel: newValue
                                            ? newValue.value
                                            : undefined,
                                    })
                                }
                                value={{
                                    label: this.state
                                        .updateSimplifiedOptimalPanel,
                                    value: this.state
                                        .updateSimplifiedOptimalPanel,
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div />
                    </>
                );
            }
        }

        updateOptimalAggregate() {
            let json = {
                simplified: true,
                data_table_idx: this.dataScopeValue(),
                panel: this.state.updateSimplifiedOptimalPanel,
            };
            this.setStepState(StepState.running);
            axios
                .post("/api/update_optimal_aggregate", json, null)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        this.setStepState(StepState.finished);
                        tables(this.dataScopeValue()).update();
                    }
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        render() {
            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        disabled={this.props.disabled}
                        className="btn-processing-tab"
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState((state) => ({
                                expanded: !state.expanded,
                            }));
                        }}
                    >
                        <span className="content-regular-text">
                            Update optimized table using simplified optimization
                            results
                        </span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    {this.state.expanded ? (
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            onClick={() => this.updateOptimalAggregate()}
                            style={{
                                fontFamily: "Roboto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: "25px",
                                width: "100%",
                                minWidth: "110px",
                            }}
                        >
                            UPDATE
                        </Button>
                    ) : (
                        <div style={{ minWidth: "110px" }} />
                    )}
                    <div>{this.state.expanded && this.renderStepState()}</div>

                    {this.renderSimplifiedUpdateOptimal()}
                </>
            );
        }
    }
);

export { MainComponent };
export let requirePermission = "SimplifiedOptimization";
