import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import DropdownOption from "state/models/PresentationFilterDropdownOptions";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import styles from "./FilterDropdown.module.css";

interface Props {
	id: string
	options: Array<DropdownOption>
	filterFunction: Function
}

function FilterDropdown({ id, options, filterFunction }: Props) {
	const [selectedOption, setSelectedOption] = useState("");

	useEffect(() => {
		if (!options || !options.length) return;

		setSelectedOption(options[0].label);
	}, [options]);

	const selectOption = (option: DropdownOption) => () => {
		setSelectedOption(option.label);
		filterFunction(option);
	};

	return (
		<Dropdown className={styles.root}>
			<Dropdown.Toggle id={id} className={styles.toggleButton}>
				{ selectedOption }
				<ChevronIcon className={styles.chevronIcon} />
			</Dropdown.Toggle>
			<Dropdown.Menu className={styles.menu}>
				{options.map((option) => (
					<Dropdown.Item key={option.value} as="button" onClick={selectOption(option)} className={styles.item}>
						{ option.label }
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
}

export default FilterDropdown;
