import React from "react";
import ReactMarkdown from "react-markdown";

function iframe() {
    return {
        __html: '<iframe src="/dist/legal/tos.html" width="100%"></iframe>',
    };
}

interface Props {
    tosText: string | null;
}

function TermsOfServiceModalContent({ tosText }: Props) {
    if (tosText != null) {
        return (
            <div style={{ maxHeight: 425, overflowY: "auto" }}>
                <ReactMarkdown>{tosText}</ReactMarkdown>
            </div>
        );
    } else {
        return <div dangerouslySetInnerHTML={iframe()} />;
    }
}

export default TermsOfServiceModalContent;
