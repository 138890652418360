import React, { Component } from "react";
import { observer } from "mobx-react";
import { Relation, AnchorPosition } from "react-archer";
import ArcherContainer from "common/canvas/archer/ArcherContainer";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import ArcherElement from "common/canvas/archer/ArcherElement";
import {
    isInput,
    CanvasInput,
    isSlider,
    CanvasSlider,
    isToggle,
    CanvasToggle,
    isSubmitButton,
    CanvasSubmitButton,
    isSpreadSheetGrid,
    CanvasElement,
    CanvasNode,
    isBox,
    isBarcodeReader,
    isProgressElement,
    StatusColorType,
    ItemMetadata,
    ArrowPosition,
    getStatusColor,
    ShapeOptions,
    InputFieldStyle,
    isRadioButtonsGroup,
    InnerCanvasChanges,
    CanvasElementOutput,
    DefaultValueType,
    isDateFormat,
    NodeValue,
    NodePosition,
    isSurvey,
} from "common/Canvas";
import { defaultFontSize, identifierFontSize } from "../Constants";
import { canvasTypeToElement } from "common/CanvasElements";
import InputView from "../inputs/InputView";
import { mainStyle } from "common/MainStyle";
import { UserIcon } from "common/UserIcon";
import DropdownNodeMenu from "./DropdownNodeMenu";
import FlowChartProps from "./FlowChartProps";
import submitData from "../SubmitData";
import ErrorWithTooltip from "./HTMLErrorWithTooltip";
import { getButtonStyle } from "./ButtonStyles";
import Portal from "common/Portal";
import "common/styles/rotate.css";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import remoteModuleId from "common/remoteModuleId";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { getNodeSize, getDefaultNodeSize } from "./getNodeSize";
import PopupData from "common/PopupData";
import {
    mobileAndTabletBreakpoint,
    onDoubleTouch,
    openDelayedNodeMenuAfterTouch,
} from "common/utilities/UIResponsiveManager";
import cx from "classnames";
import styles from "./Moveable.module.css";
import {
    makeMoveable,
    DraggableProps,
    ResizableProps,
    RotatableProps,
    Rotatable,
    Draggable,
    Resizable,
    OnDrag,
    InitialMoveable,
} from "react-moveable";
import { getTransformList } from "common/utilities/parseTransformString";
import OutsideAlerter from "common/OutsideAlerter";
import FlowChartAdd from "./FlowChartAdd";
import { BackgroundMode } from "common/CanvasUserApi";
import { strftime } from "common/utilities/TimeFormatUtils";
import DateTimeFormatSelect from "common/DateTimeFormatSelect";
import { snapElementToPoints } from "../Snap";
import { getRawDataApi } from "common/DataApi";

const LinkIcon = (props: { scale: number }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12 * props.scale}
        height={12 * props.scale}
        viewBox={`0 0 20 20`}
    >
        <path
            fill="#39F"
            d={
                /*
                "m 8.0527319,8.2138495 c 0.356367,0.04713 0.550956,0.554798 0.301682,0.889994 -2.317298,2.7945225 -4.649179,5.5735135 -6.951893,8.3841025 -0.639821,0.801521 -0.621592,2.164686 0.05423,2.941193 0.665113,0.745435 1.333871,1.486394 2.006958,2.222351 0.689265,0.735429 1.854066,0.739906 2.544926,-0.06267 2.336665,-2.785575 4.6598871,-5.586159 6.9678431,-8.403068 0.643466,-0.805733 0.630934,-2.173374 -0.07246,-2.961466 0,0 -0.149018,-0.21065 -0.137398,-0.412873 0.02461,-0.43262 0.51974,-0.668019 0.88864,-0.20591 0.917121,1.149619 0.958135,3.083644 -0.01299,4.299353 -2.312969,2.823492 -4.6414311,5.630395 -6.9833371,8.421763 -0.993909,1.154885 -2.731541,1.253364 -3.817731,0.09427 -1.247057,-1.363955 -2.769137,-2.618636 -2.838405,-4.348594 -0.03372,-0.840491 0.239021,-1.683878 0.733697,-2.303451 0,0 6.959868,-8.3938455 6.959868,-8.3938455 0,0 0.199602,-0.1622 0.356367,-0.161147 z M 19.363742,0.2431399 c 1.48904,0.03871 2.624448,1.7186352 3.846897,3.0915432 1.003706,1.152252 1.079354,3.1916009 0.05901,4.4389094 l -6.937538,8.3664615 c -0.300314,0.324926 -0.430192,0.19406 -0.586957,0.06978 -0.218059,-0.172996 -0.246769,-0.56217 -0.07109,-0.798624 2.317525,-2.794527 4.651228,-5.571409 6.95212,-8.384103 C 23.26327,6.2300621 23.251194,4.8658441 22.571724,4.0859141 21.536345,2.9231301 20.57183,1.318771 19.349153,1.29639 c -0.491941,-0.009 -0.978642,0.2227611 -1.329313,0.6298411 -2.336665,2.785575 -4.659887,5.5861594 -6.967843,8.4030639 -0.64301,0.80547 -0.637314,2.165738 0.07269,2.96173 0,0 0.280264,0.583761 -0.08704,0.832327 -0.899348,0.608513 -2.1147331,-2.680249 -0.651441,-4.513158 2.313197,-2.82349 4.641431,-5.6303939 6.983338,-8.422026 0.523157,-0.60719606 1.255943,-0.9529251 1.994197,-0.9450251 z"
                */
                "M8.46504 20.535C6.44288 20.535 4.61982 19.3169 3.84585 17.4487C3.07187 15.5805 3.49938 13.4301 4.92904 12L7.05104 9.87799L8.46504 11.292L6.34404 13.413C5.58611 14.1709 5.2901 15.2756 5.56752 16.311C5.84495 17.3464 6.65366 18.1551 7.68902 18.4325C8.72439 18.7099 9.8291 18.4139 10.587 17.656L12.708 15.535L14.122 16.95L12.001 19.071C11.0651 20.0115 9.7919 20.5387 8.46504 20.535ZM9.17204 16.242L7.75804 14.828L14.829 7.75699L16.243 9.17099L9.17304 16.241L9.17204 16.242ZM16.951 14.121L15.536 12.707L17.657 10.586C18.4253 9.83032 18.7292 8.72065 18.4533 7.67897C18.1773 6.63729 17.3639 5.82364 16.3223 5.54743C15.2806 5.27123 14.1709 5.57491 13.415 6.34299L11.293 8.46399L9.87904 7.04999L12.001 4.92799C13.9561 2.98996 17.11 2.99686 19.0566 4.94344C21.0032 6.89002 21.0101 10.0439 19.072 11.999L16.951 14.12V14.121Z"
            }
        />
    </svg>
);

function arrowPositionToAnchor(position: ArrowPosition): AnchorPosition {
    switch (position) {
        case ArrowPosition.Left:
            return "left";
        case ArrowPosition.Right:
            return "right";
        case ArrowPosition.Bottom:
            return "bottom";
        case ArrowPosition.Top:
            return "top";
        default:
            return "bottom";
    }
}

interface Props extends FlowChartProps {
    positionArrows: () => void;
    currentModuleId: number | undefined;
    canvasSize: {
        width: number;
        height: number;
    };
    onResize: () => void;
    onRotate: () => void;
    selectedMetadata?: ItemMetadata[];
    onConnectionIntention?: (state: boolean) => void;
    arrowConnectIntention?: boolean;
}

interface InnerProps extends Props {
    canvasTreeStore: CanvasTreeStore;
    nodeId: number;
    remoteModuleId: string | null;
    rootDataTestId: string;
    addLinkingArrow?: (
        relation: Relation,
        origin: number,
        clear: boolean
    ) => void;
    clearArrows?: (previewOnly: boolean) => void;
    relations?: RelationWithOrigin[];
    onConnectionIntention?: (state: boolean) => void;
    arrowConnectIntention?: boolean;
}

interface RelationWithOrigin extends Relation {
    origin?: number;
}

interface State {
    relations: RelationWithOrigin[];
    update: boolean;
}

interface InnerState {
    submitting: boolean;
    submitStatus: PopupStatus | null;
    submitMessage: string | null;
    hovered: boolean;
    focused: boolean;
    mouseDown: boolean;
    dropdownOpened: boolean;
    highlighted: boolean;
}

const Moveable = makeMoveable<DraggableProps & ResizableProps & RotatableProps>(
    [Draggable, Resizable, Rotatable]
);

@observer
class InputWrapper extends CanvasInteractionComponent<InnerProps, InnerState> {
    private drag: boolean;
    private moveableRef = React.createRef<
        InitialMoveable<DraggableProps & ResizableProps & RotatableProps>
    >();
    private innerRef = React.createRef<HTMLDivElement>();
    // private textSpan = React.createRef<HTMLDivElement>();

    constructor(props: InnerProps) {
        super(props);
        this.state = {
            dropdownOpened: false,
            submitting: false,
            hovered: false,
            focused: false,
            mouseDown: false,
            highlighted: false,
            submitStatus: null,
            submitMessage: null,
        };
        this.drag = false;
    }

    private propagateSimpleEdit(
        node: CanvasElement,
        canEditBox: boolean,
        element: string
    ): void {
        if (
            !isToggle(node) &&
            (!this.props.live || node.unlocked || isInput(node)) &&
            canEditBox
        ) {
            this.setState({
                highlighted: false,
                hovered: false,
            });
            this.trackNewPerformance(element);
            this.props.onClearEditing();
            let editedNode = { ...node };
            this.props.onStartSimpleEditing(editedNode);
            this.props.onChangeSelector({
                color:
                    editedNode.fontColor ||
                    mainStyle
                        .getPropertyValue("--content-primary-text-color")
                        .trim(),
                size: editedNode.fontSize || defaultFontSize,
            });
        }
    }

    private activateMouseOverNode(node: CanvasNode) {
        let highlighted = false;
        if (isInput(node) && this.props.live) {
            highlighted = true;
        }
        this.setState({
            highlighted: highlighted,
            hovered: true,
        });
    }

    private deactivateMouseOverNode() {
        this.setState({
            highlighted: false,
            hovered: false,
            mouseDown: false,
        });
    }

    componentDidMount(): void {
        let node = this.props.canvasTreeStore.canvasTreeState.get(
            this.props.nodeId
        )! as CanvasInput | CanvasSubmitButton | CanvasSlider | CanvasToggle;
        if(node.update?.active && node.update.contextId) {
            getRawDataApi(
                {
                    label: "",
                    value: [],
                    optimized: false,
                    data_table_idx: (node as any).dataScopeOption?.value,
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                PopupData.data?.row_id ?[PopupData.data?.row_id] : undefined,
                true
            ).then((res) => {
                if((node as any).variableOption?.label)
                    this.props.canvasTreeStore.updateNodeAction(
                        node.id, {
                            metric: res.currentLevels[(node as any).variableOption?.label][0] as string ?? ''
                        }
                    );
            })
        }
        let innerRef = this.innerRef.current;
        if (innerRef != null) {
            innerRef.setAttribute("type", "canvasTreeState");
            if (node.groupId != null)
                innerRef.setAttribute("groupId", node.groupId);
            else {
                innerRef.removeAttribute("groupId");
            }
            innerRef.setAttribute("id", String(node.id));
            innerRef.setAttribute("data-test-id", this.props.rootDataTestId);
        }
        if (
            node.defaultValueType != null &&
            node.defaultValueType !== DefaultValueType.LastTextAdded
        ) {
            let metric: string | undefined;
            let value: number;
            switch (node.defaultValueType) {
                case DefaultValueType.ClearEverySession:
                    metric = "";
                    value = NaN;
                    break;
                case DefaultValueType.CurrentDateTime:
                    let date = new Date();
                    metric = strftime(
                        isInput(node) && isDateFormat(node.format)
                            ? node.format.format
                            : DateTimeFormatSelect.defaultOptions()[0].value,
                        date
                    );
                    value = date.getTime() / 1000;
                    break;
                default:
                    metric = node.defaultText;
                    value = NaN;
                    break;
            }
            this.props.canvasTreeStore.updateNodeAction(node.id, {
                value: value,
                metric: metric ?? "",
            });
        }
    }

    componentDidUpdate(prev: InnerProps): void {
        if (prev.arrowConnectIntention !== this.props.arrowConnectIntention) {
            if (!this.props.arrowConnectIntention) {
                this.moveableRef.current?.stopDrag();
            }
        }
        let node = this.props.canvasTreeStore.canvasTreeState.get(
            this.props.nodeId
        )! as CanvasInput | CanvasSubmitButton | CanvasSlider | CanvasToggle;

        if (prev.selectedMetadata !== this.props.selectedMetadata) {
            let innerRef = this.innerRef.current;
            if (innerRef != null) {
                if (node.groupId != null)
                    innerRef.setAttribute("groupId", node.groupId);
                else {
                    innerRef.removeAttribute("groupId");
                }
            }
        }
        this.moveableRef.current?.updateRect();
    }

    public render(): JSX.Element {
        const {
            canvasViewMode,
            mobileViewWasEdited,
            slideWidthRatio,
        } = this.props.canvasTreeStore;

        let node = this.props.canvasTreeStore.canvasTreeState.get(
            this.props.nodeId
        )! as CanvasInput | CanvasSubmitButton | CanvasSlider | CanvasToggle;
        let element = canvasTypeToElement[node.canvasType!];
        let originalNodeSize = {
            nodePosition: node.nodePosition,
            ...getNodeSize(node, canvasViewMode),
        };

        let defaultNodeSize = getDefaultNodeSize(node);
        let scale = this.props.scale ?? 1;
        let nodeSize = {
            height: originalNodeSize.nodeSize[canvasViewMode].height * scale,
            width: originalNodeSize.nodeSize[canvasViewMode].width * scale,
        };

        let edited =
            this.props.editedNode && this.props.editedNode.id === node.id;
        const linkedParent =
            this.props.linkedParentNode &&
            this.props.linkedParentNode.id === node.id;
        let boxShadow: string = "none";
        let backgroundColor: string = "transparent";
        let borderRadius: number | undefined;
        let borderWidth = 1;

        const selected =
            this.props.selectedMetadata?.length === 1 &&
            this.props.selectedMetadata?.[0]?.type === "canvasTreeState" &&
            this.props.selectedMetadata?.[0]?.id === node.id;

        let position = {
            x:
                originalNodeSize.nodePosition[canvasViewMode].x *
                    this.props.scale || 0,
            y:
                originalNodeSize.nodePosition[canvasViewMode].y *
                    this.props.scale || 0,
        };

        if (isBox(node)) {
            backgroundColor =
                node.fillColor ??
                mainStyle.getPropertyValue(
                    "--slide-flowchart-background-color"
                );
            boxShadow = mainStyle.getPropertyValue(
                "--slide-flowchart-box-shadow"
            );
            borderRadius = 12;
        }
        if (isInput(node)) {
            backgroundColor = node.fillColor ?? "transparent";
            borderRadius = 5;
        }
        if (edited) {
            boxShadow = "0px 0px 5px rgba(51,153,255,0.5)";
        } else if (this.state.highlighted) {
            boxShadow = "0px 0px 5px rgba(0,0,0,0.5)";
        } else if (!isToggle(node) && !isSlider(node) && node.borderShadow) {
            boxShadow = "0px 6px 13px rgba(21,33,56,0.53)";
        }
        if (
            isBox(node) &&
            this.state.hovered &&
            !this.props.selectedMetadata?.find(
                (item) => item.id === node.id && item.type === "canvasTreeState"
            )
        ) {
            boxShadow = mainStyle.getPropertyValue(
                "--slide-flowchart-hover-box-shadow"
            );
        }

        if (isInput(node) && node.inputFieldStyle !== InputFieldStyle.Legacy)
            boxShadow = "";

        let linkIconVisible: boolean = false;
        if (isBox(node)) {
            let connectedLinks = node.links ?? [];
            let connectedLinkPopups = node.linkPopups ?? [];
            linkIconVisible =
                (node.external && (node.externalLink ?? "").length > 0) ||
                connectedLinks.length > 0 ||
                connectedLinkPopups.length > 0;
        }
        const error =
            this.props.canvasTreeStore.canvasTreeRequestErrorsState.get(
                node.id
            ) != null ||
            this.props.canvasTreeStore.canvasTreeParseErrorsState.get(
                node.id
            ) != null;

        if (isBarcodeReader(node)) {
            backgroundColor = "transparent";
            borderRadius = 5;
        }

        let borderColor = "transparent";
        let fontColor =
            node.fontColor ??
            mainStyle.getPropertyValue(
                isBox(node)
                    ? "--slide-flowchart-text-color"
                    : "--secondary-text-color"
            );
        // Arrows
        let relations: Relation[] = [];
        if (isBox(node) || isProgressElement(node)) {
            let statusBackground = getStatusColor(node, StatusColorType.Fill);
            if (statusBackground.color !== "transparent")
                backgroundColor = statusBackground.color;

            let statusFont = getStatusColor(node, StatusColorType.Text);

            if (statusFont.color !== "transparent")
                fontColor = statusFont.color;

            let statusBorder = getStatusColor(node, StatusColorType.Border);

            borderColor = statusBorder.color;
            borderWidth = statusBorder.borderWidth * this.props.scale;

            let doubleArrowStyle = {
                endMarker: true,
                startMarker: true,
            };

            for (let parentEdge of node.parentIds) {
                let sourceAnchor = arrowPositionToAnchor(
                    parentEdge.childPosition
                );
                let targetAnchor = arrowPositionToAnchor(
                    parentEdge.parentPosition
                );

                relations.push({
                    targetId: `archer-element-node-${parentEdge.id}`,
                    sourceAnchor: sourceAnchor,
                    targetAnchor: targetAnchor,
                    style: parentEdge.arrowDoubleSided
                        ? doubleArrowStyle
                        : undefined,
                    label: (
                        <div
                            contentEditable={true}
                            id={`archer-element-node-${parentEdge.id}-text`}
                            onKeyDown={(evt) => {
                                if (
                                    evt.currentTarget.textContent === "Add Text"
                                ) {
                                    evt.currentTarget.textContent = "";
                                }
                                if (evt.key === "Enter")
                                    evt.currentTarget.blur();
                                evt.stopPropagation();
                            }}
                            onFocus={(evt) => {
                                if (!evt.currentTarget.textContent)
                                    evt.currentTarget.textContent = "Add Text";
                            }}
                            onBlur={(evt) => {
                                if (
                                    evt.currentTarget.textContent === "Add Text"
                                )
                                    evt.currentTarget.textContent = "";
                                let arrowTexts = node.arrowTexts?.filter(
                                    (x) => x.id !== parentEdge.id
                                );
                                arrowTexts?.push({
                                    id: parentEdge.id,
                                    label: evt.currentTarget.textContent,
                                });
                                this.props.canvasTreeStore.updateNodeAction(
                                    node.id,
                                    {
                                        arrowTexts: arrowTexts,
                                    }
                                );
                            }}
                            style={{
                                fontSize: this.props.scale * 10,
                                display: "flex",
                                textAlign: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                                minWidth: "25px",
                                minHeight: "25px",
                                maxWidth: "200px",
                                backgroundColor: node.arrowTexts?.filter(
                                    (x) => x.id === parentEdge.id
                                )[0]?.label
                                    ? "white"
                                    : "transparent",
                                cursor: "copy",
                            }}
                        >
                            {
                                node.arrowTexts?.filter(
                                    (x) => x.id === parentEdge.id
                                )[0]?.label
                            }
                        </div>
                    ),
                });
            }
        }

        let buttonStyle: {
            containerStyle?: React.CSSProperties;
            textStyle?: React.CSSProperties;
        } = {};
        if (isSubmitButton(node) && !node.isTextLink) {
            buttonStyle = getButtonStyle(
                node,
                this.props.scale,
                this.state.hovered,
                this.state.mouseDown
            );
        }

        return (
            <OutsideAlerter
                onReject={() => {
                    this.props.onConnectionIntention?.(false);
                    this.setState({
                        focused: false,
                    });
                }}
            >
                <>
                    <div
                        onContextMenu={(evt) => {
                            this.props.onContextMenu(
                                evt,
                                {
                                    id: this.props.nodeId,
                                    type: "canvasTreeState",
                                },
                                true
                            );
                        }}
                        className="selectable-by-pointer"
                        ref={this.innerRef}
                        style={{
                            ...nodeSize,
                            position: "absolute",
                            top:
                                originalNodeSize.nodePosition[canvasViewMode]
                                    .y * this.props.scale,
                            left:
                                originalNodeSize.nodePosition[canvasViewMode]
                                    .x * this.props.scale,
                            zIndex: node.zIndex ?? 50,
                        }}
                        onMouseEnter={() => {
                            this.activateMouseOverNode(node);
                        }}
                        onMouseLeave={() => {
                            this.deactivateMouseOverNode();
                        }}
                        onClick={() => {
                            this.setState({
                                focused: true,
                            });
                        }}
                        onTouchStart={() => {
                            openDelayedNodeMenuAfterTouch(
                                () => {
                                    this.activateMouseOverNode(node);
                                },
                                () => {
                                    this.deactivateMouseOverNode();
                                }
                            );
                        }}
                    >
                        <div
                            data-test-id={`${this.props.rootDataTestId}-clickarea`}
                            style={{
                                ...nodeSize,
                                borderWidth: borderWidth,
                                borderStyle: "solid",
                                borderColor: borderColor,
                                borderRadius: borderRadius,
                                boxShadow: boxShadow,
                                backgroundColor: backgroundColor,
                                ...buttonStyle.containerStyle,
                            }}
                            onMouseDown={() => {
                                if (this.props.live && isSubmitButton(node)) {
                                    this.setState({ mouseDown: true });
                                }
                            }}
                            onMouseUp={() => {
                                if (this.props.live && isSubmitButton(node)) {
                                    this.setState({ mouseDown: false });
                                }
                            }}
                            onClick={async () => {
                                let linkStatusPopup:
                                    | {
                                          status: PopupStatus;
                                          message: string;
                                      }
                                    | undefined = undefined;
                                let linked = false;
                                if (isSubmitButton(node) || isBox(node)) {
                                    let connectedLinks = node.links ?? [];
                                    let connectedLinkPopups =
                                        node.linkPopups ?? [];
                                    if (
                                        this.props.live &&
                                        (connectedLinks.length > 0 ||
                                            connectedLinkPopups.length > 0 ||
                                            ((isBox(node) ||
                                                isSubmitButton(node)) &&
                                                node.external))
                                    ) {
                                        linked = true;
                                    }
                                }
                                if (
                                    !this.state.submitting &&
                                    this.props.live &&
                                    isSubmitButton(node) &&
                                    node.backendOutput != null
                                ) {

                                    let mapping = node.backendOutput.variableOptions.filter(
                                        (option) =>
                                            option.node != null &&
                                            option.variable != null
                                    );
                                    
                                    let hasSubmitOptions =
                                        mapping.length > 0 &&
                                        node.backendOutput.dataScopeOption !=
                                            null &&
                                        node.backendOutput.tableOption != null;

                                    if (hasSubmitOptions) {
                                        try {
                                            this.setState({
                                                submitting: true,
                                            });
                                            await submitData(
                                                this.props.canvasTreeStore,
                                                node,
                                                this.props.remoteModuleId ??
                                                    this.props.currentModuleId
                                            );
                                            this.props.canvasTreeStore.canvasTreeRequestErrorsState.delete(
                                                node.id
                                            );
                                            const message = `Data submitted into ${
                                                (node as CanvasSubmitButton)
                                                    .backendOutput!
                                                    .dataScopeOption?.label ??
                                                ""
                                            } successfully`;
                                            setTimeout(() => {
                                                // The status popup will be rendered
                                                // in main.js if the button is linked
                                                this.setState({
                                                    submitStatus: linked
                                                        ? null
                                                        : PopupStatus.Success,
                                                    submitMessage: linked
                                                        ? null
                                                        : message,
                                                    submitting: false,
                                                });
                                            }, 500);
                                            linkStatusPopup = {
                                                status: PopupStatus.Success,
                                                message: message,
                                            };
                                        } catch (error) {
                                            this.props.canvasTreeStore.canvasTreeRequestErrorsState.set(
                                                node.id,
                                                String(error)
                                            );
                                            // The status popup will be rendered
                                            // in main.js if the button is linked
                                            this.setState({
                                                submitStatus: linked
                                                    ? null
                                                    : PopupStatus.Error,
                                                submitMessage: linked
                                                    ? null
                                                    : String(error),
                                                submitting: false,
                                            });
                                            linkStatusPopup = {
                                                status: PopupStatus.Error,
                                                message: String(error),
                                            };
                                        }
                                    }
                                }
                                if (linked) {
                                    this.trackNewPerformance(element);
                                    this.props.onExpandCard(
                                        node as
                                            | CanvasElement
                                            | CanvasSubmitButton,
                                        linkStatusPopup
                                    );
                                    return;
                                }
                                if (
                                    this.props.editedNode != null &&
                                    this.props.editedNode.gridId != null &&
                                    this.props.editedNode.focus &&
                                    isSpreadSheetGrid(
                                        this.props.canvasTreeStore.gridsState.get(
                                            this.props.editedNode.gridId
                                        )!
                                    ) &&
                                    this.props.editedNode.id !== node.id &&
                                    this.props.editedNode.metric.startsWith("=")
                                ) {
                                    this.trackNewPerformance(element);

                                    this.props.onSimpleEditInsertOuterId(
                                        node as CanvasElement
                                    );
                                } else {
                                    if (!this.props.live) {
                                        if (!this.props.canWrite) return;
                                        if (!linkedParent) {
                                            // We only draw arrows between flowcharts, not
                                            // inputs
                                            this.trackNewPerformance(element);
                                        }
                                        this.trackNewPerformance(element);
                                    } else {
                                        this.propagateSimpleEdit(
                                            node as CanvasElement,
                                            this.props.canWrite,
                                            element
                                        );
                                    }
                                }
                            }}
                        >
                            <ArcherElement
                                key={this.props.nodeId}
                                id={`archer-element-node-${this.props.nodeId}`}
                                relations={[
                                    ...(this.props?.relations ?? [])
                                        .filter((x) => x.origin === node.id)
                                        .filter(
                                            (x) =>
                                                x.targetId ===
                                                "archer-element-node-preview"
                                        ),
                                    ...relations,
                                ]}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    {isBox(node) &&
                                        node.delegate != null &&
                                        this.props.canvasTreeStore.connectedUsersState.get(
                                            node.delegate
                                        ) && (
                                            <div
                                                data-test-id={`${this.props.rootDataTestId}-delegate`}
                                                style={{
                                                    position: "absolute",
                                                    top: -8 * scale,
                                                    left: `calc(50% - ${
                                                        8 * scale
                                                    }px)`,
                                                }}
                                                onClick={(e) => {
                                                    if (this.props.live) {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        this.trackNewPerformance(
                                                            element
                                                        );
                                                        let absolutePositionNode = {
                                                            ...(node as CanvasElement),
                                                        };
                                                        this.props.onExpandUserCard(
                                                            absolutePositionNode
                                                        );
                                                    }
                                                }}
                                            >
                                                <UserIcon
                                                    customStyle={{
                                                        boxShadow:
                                                            "3px 5px 10px rgba(21,33,56,0.53)",
                                                    }}
                                                    width={16 * scale}
                                                    height={16 * scale}
                                                    iconUrl={
                                                        this.props.canvasTreeStore.connectedUsersState.get(
                                                            node.delegate
                                                        )!.icon_url
                                                    }
                                                    user={
                                                        this.props.canvasTreeStore.connectedUsersState.get(
                                                            node.delegate
                                                        )!
                                                    }
                                                    fontSize={8 * scale}
                                                />
                                            </div>
                                        )}
                                    {!edited && (
                                        <InputView
                                            onExpandCard={
                                                this.props.onExpandCard
                                            }
                                            canvasTreeStore={
                                                this.props.canvasTreeStore
                                            }
                                            rootDataTestId={
                                                this.props.rootDataTestId
                                            }
                                            node={node}
                                            hovered={this.state.hovered}
                                            fontColor={fontColor}
                                            fontSize={
                                                node.fontSize ?? defaultFontSize
                                            }
                                            live={this.props.live}
                                            onMetricChanged={(metric) => {
                                                let changes: {
                                                    metric: string;
                                                    value: NodeValue;
                                                    additionalOutputs?: CanvasElementOutput[];
                                                } = {
                                                    metric: metric,
                                                    value: node.value,
                                                };

                                                if (isSlider(node)) {
                                                    this.trackNewPerformance(
                                                        element
                                                    );

                                                    if (node.range) {
                                                        const splitedRangeValue = metric.split(
                                                            ","
                                                        );
                                                        changes = {
                                                            metric:
                                                                splitedRangeValue[0] ??
                                                                "",
                                                            value:
                                                                splitedRangeValue[0],
                                                            additionalOutputs: [
                                                                {
                                                                    metric:
                                                                        splitedRangeValue[1] ??
                                                                        "",
                                                                    value:
                                                                        splitedRangeValue[1],
                                                                    unit: "",
                                                                    outerId: `${node.outerId}_2`,
                                                                },
                                                            ],
                                                        };
                                                    }
                                                }

                                                if (
                                                    !(node as CanvasSlider)
                                                        .range &&
                                                    !isRadioButtonsGroup(node)
                                                ) {
                                                    changes.value = Number(
                                                        metric
                                                    );
                                                }

                                                this.props.canvasTreeStore.updateNodeAction<
                                                    CanvasSlider,
                                                    keyof typeof changes
                                                >(node.id, changes);
                                            }}
                                            scale={scale}
                                            width={nodeSize.width}
                                            height={nodeSize.height}
                                            onDblClick={(_evt) => {
                                                this.propagateSimpleEdit(
                                                    node as CanvasElement,
                                                    this.props.canWrite,
                                                    element
                                                );
                                            }}
                                            onTouchStart={(_evt) => {
                                                onDoubleTouch(() => {
                                                    this.propagateSimpleEdit(
                                                        node as CanvasElement,
                                                        this.props.canWrite,
                                                        element
                                                    );
                                                });
                                            }}
                                            currentModuleId={
                                                this.props.currentModuleId
                                            }
                                        />
                                    )}
                                    {!this.props.live && (
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: 3 * scale,
                                                left: 3 * scale,
                                                backgroundColor: "transparent",
                                                color: fontColor,
                                                fontSize:
                                                    identifierFontSize * scale,
                                            }}
                                        >
                                            {node.outerId}
                                        </span>
                                    )}
                                    {linkIconVisible && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 3 * scale,
                                                right: 3 * scale,
                                            }}
                                        >
                                            <LinkIcon scale={scale} />
                                        </div>
                                    )}
                                    {isBox(node) &&
                                    this.props.canWrite &&
                                    !this.props.live ? (
                                        <FlowChartAdd
                                            arrowConnectionIntention={
                                                this.props.arrowConnectIntention
                                            }
                                            onConnectionIntention={
                                                this.props.onConnectionIntention
                                            }
                                            addLinkingArrow={
                                                this.props.addLinkingArrow!
                                            }
                                            clearArrows={
                                                this.props.clearArrows!
                                            }
                                            node={node}
                                            scale={scale}
                                            element={element}
                                            backgroundColor={backgroundColor}
                                            linkedParent={linkedParent}
                                            position={ArrowPosition.Top}
                                            rootDataTestId={
                                                this.props.rootDataTestId
                                            }
                                            trackNewPerformance={this.trackNewPerformance.bind(
                                                this
                                            )}
                                            focused={this.state.focused}
                                            hovered={this.state.hovered}
                                            onClearEditing={
                                                this.props.onClearEditing
                                            }
                                            canvasTreeStore={
                                                this.props.canvasTreeStore
                                            }
                                            linkedParentNode={
                                                this.props.linkedParentNode
                                            }
                                            onStartLinkedParent={
                                                this.props.onStartLinkedParent
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {isBox(node) &&
                                    this.props.canWrite &&
                                    !this.props.live ? (
                                        <FlowChartAdd
                                            arrowConnectionIntention={
                                                this.props.arrowConnectIntention
                                            }
                                            onConnectionIntention={
                                                this.props.onConnectionIntention
                                            }
                                            addLinkingArrow={
                                                this.props.addLinkingArrow!
                                            }
                                            clearArrows={
                                                this.props.clearArrows!
                                            }
                                            node={node}
                                            scale={scale}
                                            element={element}
                                            backgroundColor={backgroundColor}
                                            linkedParent={linkedParent}
                                            position={ArrowPosition.Bottom}
                                            rootDataTestId={
                                                this.props.rootDataTestId
                                            }
                                            trackNewPerformance={this.trackNewPerformance.bind(
                                                this
                                            )}
                                            focused={this.state.focused}
                                            hovered={this.state.hovered}
                                            onClearEditing={
                                                this.props.onClearEditing
                                            }
                                            canvasTreeStore={
                                                this.props.canvasTreeStore
                                            }
                                            linkedParentNode={
                                                this.props.linkedParentNode
                                            }
                                            onStartLinkedParent={
                                                this.props.onStartLinkedParent
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {isBox(node) &&
                                    this.props.canWrite &&
                                    !this.props.live ? (
                                        <FlowChartAdd
                                            arrowConnectionIntention={
                                                this.props.arrowConnectIntention
                                            }
                                            onConnectionIntention={
                                                this.props.onConnectionIntention
                                            }
                                            addLinkingArrow={
                                                this.props.addLinkingArrow!
                                            }
                                            clearArrows={
                                                this.props.clearArrows!
                                            }
                                            node={node}
                                            scale={scale}
                                            element={element}
                                            backgroundColor={backgroundColor}
                                            linkedParent={linkedParent}
                                            position={ArrowPosition.Left}
                                            rootDataTestId={
                                                this.props.rootDataTestId
                                            }
                                            trackNewPerformance={this.trackNewPerformance.bind(
                                                this
                                            )}
                                            focused={this.state.focused}
                                            hovered={this.state.hovered}
                                            onClearEditing={
                                                this.props.onClearEditing
                                            }
                                            canvasTreeStore={
                                                this.props.canvasTreeStore
                                            }
                                            linkedParentNode={
                                                this.props.linkedParentNode
                                            }
                                            onStartLinkedParent={
                                                this.props.onStartLinkedParent
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {isBox(node) &&
                                    this.props.canWrite &&
                                    !this.props.live ? (
                                        <FlowChartAdd
                                            arrowConnectionIntention={
                                                this.props.arrowConnectIntention
                                            }
                                            onConnectionIntention={
                                                this.props.onConnectionIntention
                                            }
                                            addLinkingArrow={
                                                this.props.addLinkingArrow!
                                            }
                                            clearArrows={
                                                this.props.clearArrows!
                                            }
                                            node={node}
                                            scale={scale}
                                            element={element}
                                            backgroundColor={backgroundColor}
                                            linkedParent={linkedParent}
                                            position={ArrowPosition.Right}
                                            rootDataTestId={
                                                this.props.rootDataTestId
                                            }
                                            trackNewPerformance={this.trackNewPerformance.bind(
                                                this
                                            )}
                                            focused={this.state.focused}
                                            hovered={this.state.hovered}
                                            onClearEditing={
                                                this.props.onClearEditing
                                            }
                                            canvasTreeStore={
                                                this.props.canvasTreeStore
                                            }
                                            linkedParentNode={
                                                this.props.linkedParentNode
                                            }
                                            onStartLinkedParent={
                                                this.props.onStartLinkedParent
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}

                                    {!this.props.live && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                width: 15 * scale + borderWidth,
                                                height: "100%",
                                                left: position.x,
                                                top: position.y,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            {(this.state.focused ||
                                                this.state.dropdownOpened ||
                                                // The hamburger menu has to appear as soon as the node gets created
                                                selected) &&
                                                !this.drag &&
                                                this.props.canWrite &&
                                                !this.props.live &&
                                                this.props
                                                    .htmlElementsRootRef !=
                                                    null && (
                                                    <Portal
                                                        rootNode={
                                                            this.props
                                                                .htmlElementsRootRef
                                                        }
                                                    >
                                                        <div
                                                            data-test-id={`${this.props.rootDataTestId}-menucontainer`}
                                                        >
                                                            <DropdownNodeMenu
                                                                rootMenuTestId={`${this.props.rootDataTestId}-menu`}
                                                                {...this.props}
                                                                scale={
                                                                    this.props
                                                                        .scale
                                                                }
                                                                customStyle={{
                                                                    zIndex: 999,
                                                                    position:
                                                                        "absolute",
                                                                    left:
                                                                        position.x +
                                                                        nodeSize.width,
                                                                    top:
                                                                        position.y,
                                                                    width:
                                                                        15 *
                                                                        this
                                                                            .props
                                                                            .scale,
                                                                    height:
                                                                        20 *
                                                                        this
                                                                            .props
                                                                            .scale,
                                                                }}
                                                                onToggle={(
                                                                    show
                                                                ) => {
                                                                    this.setState(
                                                                        {
                                                                            dropdownOpened: show,
                                                                        }
                                                                    );
                                                                }}
                                                                canEdit={
                                                                    this.props
                                                                        .canWrite
                                                                }
                                                                canDelete={
                                                                    this.props
                                                                        .canWrite
                                                                }
                                                                canLock={
                                                                    isBox(
                                                                        node
                                                                    ) &&
                                                                    node.gridId ==
                                                                        null
                                                                }
                                                                node={node}
                                                                element={
                                                                    element
                                                                }
                                                                onError={(
                                                                    message
                                                                ) => {
                                                                    this.setState(
                                                                        {
                                                                            submitStatus:
                                                                                PopupStatus.Error,
                                                                            submitMessage: message,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </Portal>
                                                )}
                                        </div>
                                    )}
                                    {this.state.submitting && (
                                        <div
                                            className={
                                                this.state.submitting
                                                    ? "rotate"
                                                    : undefined
                                            }
                                            style={{
                                                position: "absolute",
                                                bottom: 3 * scale,
                                                right: 3 * scale,
                                                display: "flex",
                                                alignItems: "center",
                                                transition:
                                                    "opacity .2s ease-out",
                                                opacity: 1,
                                                pointerEvents: this.state
                                                    .hovered
                                                    ? "auto"
                                                    : "none",
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src="/dist/img/insights/insights_reload_white.png"
                                                style={{
                                                    width: Math.min(
                                                        20 * scale,
                                                        24
                                                    ),
                                                    height: Math.min(
                                                        20 * scale,
                                                        24
                                                    ),
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </div>
                                    )}
                                    {error && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                bottom: 3 * scale,
                                                right: 3 * scale,
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            <ErrorWithTooltip
                                                scale={scale}
                                                canvasTreeParseError={
                                                    this.props.canvasTreeStore.canvasTreeParseErrorsState.get(
                                                        node.id
                                                    )!
                                                }
                                                canvasTreeRequestError={
                                                    this.props.canvasTreeStore.canvasTreeRequestErrorsState.get(
                                                        node.id
                                                    )!
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </ArcherElement>
                        </div>
                    </div>
                    {!this.props.live && this.props.canWrite && (
                        <Moveable
                            className={cx(
                                styles.moveable,
                                !selected && styles.moveableNotSelected
                            )}
                            checkInput={true}
                            key={node.id}
                            ref={this.moveableRef}
                            draggable={!this.props.live || this.props.canWrite}
                            throttleDrag={0}
                            onDrag={({
                                target,
                                beforeDelta,
                                beforeDist,
                                left,
                                top,
                                right,
                                bottom,
                                delta,
                                dist,
                                transform,
                                clientX,
                                clientY,
                            }: OnDrag) => {
                                if (!this.props.arrowConnectIntention) {
                                    target!.style.left = `${left}px`;
                                    target!.style.top = `${top}px`;
                                    this.drag = true;

                                    let nearestPoints = this.props.onRebuildSnapLine(
                                        {
                                            x: left,
                                            y: top,
                                            width: nodeSize.width,
                                            height: nodeSize.height,
                                        },
                                        {
                                            type: "canvasTreeState",
                                            id: node.id,
                                            groupId: node.groupId,
                                        }
                                    );
                                    let newPosition = snapElementToPoints(
                                        target.clientWidth,
                                        target.clientHeight,
                                        nearestPoints
                                    );
                                    if (newPosition.x != null) {
                                        target!.style.left = `${newPosition.x}px`;
                                    }
                                    if (newPosition.y != null) {
                                        target!.style.top = `${newPosition.y}px`;
                                    }
                                    this.props.onUpdateSelectionBounds?.();
                                }
                            }}
                            onDragEnd={({ target }) => {
                                if (this.drag) {
                                    this.trackNewPerformance(element);
                                    this.props.onDeleteSnapLine();
                                    let x =
                                        parseFloat(target.style.left) /
                                        this.props.scale;

                                    let y =
                                        parseFloat(target.style.top) /
                                        this.props.scale;

                                    let deltaX =
                                        x -
                                        originalNodeSize.nodePosition[
                                            canvasViewMode
                                        ].x;
                                    let deltaY =
                                        y -
                                        originalNodeSize.nodePosition[
                                            canvasViewMode
                                        ].y;

                                    const newNodePosition = {
                                        ...originalNodeSize.nodePosition,
                                        [canvasViewMode]: {
                                            x,
                                            y,
                                        },
                                    };

                                    if (
                                        !mobileViewWasEdited &&
                                        !isSurvey(node)
                                    ) {
                                        if (canvasViewMode === "desktop")
                                            newNodePosition["mobile"] = {
                                                x: x * slideWidthRatio,
                                                y,
                                            };
                                        else
                                            this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                    }
                                    let changes: InnerCanvasChanges = {};
                                    this.props.canvasTreeStore.updateNodeAction(
                                        node.id,
                                        {
                                            nodePosition: newNodePosition,
                                        },
                                        true,
                                        false,
                                        false,
                                        changes
                                    );
                                    this.props.canvasTreeStore.updateCanvasSizeAction(
                                        {
                                            x: x,
                                            y: y,
                                            width:
                                                originalNodeSize.nodeSize[
                                                    canvasViewMode
                                                ].width,
                                            height:
                                                originalNodeSize.nodeSize[
                                                    canvasViewMode
                                                ].height,
                                        }
                                    );

                                    this.props.onMoveGroupSelection(
                                        deltaX,
                                        deltaY,
                                        {
                                            id: node.id,
                                            type: "canvasTreeState",
                                            groupId: node.groupId,
                                        },
                                        false,
                                        changes
                                    );

                                    this.props.canvasTreeStore.saveChangesAction(
                                        changes,
                                        true,
                                        true,
                                        false,
                                        this.props.canvasTreeStore.backgroundsState.toJSON(),
                                        BackgroundMode.Update,
                                        false
                                    );
                                    this.props.canvasTreeStore.updateNodesByLinkedInputsAction(
                                        changes
                                    );

                                    this.drag = false;
                                    // this.setState({ focused: true });
                                    if (mobileAndTabletBreakpoint()) {
                                        openDelayedNodeMenuAfterTouch(
                                            () => {
                                                this.activateMouseOverNode(
                                                    node
                                                );
                                            },
                                            () => {
                                                this.deactivateMouseOverNode();
                                            }
                                        );
                                    }
                                }
                            }}
                            target={this.innerRef}
                            resizable={
                                (!this.props.live || this.props.canWrite) &&
                                selected &&
                                !isSurvey(node)
                            }
                            rotatable={false}
                            onResize={(e) => {
                                e.target.style.width = `${e.width}px`;
                                e.target.style.height = `${e.height}px`;
                                e.target.style.transform = e.afterTransform;
                            }}
                            onResizeEnd={({ target }) => {
                                this.trackNewPerformance(element);
                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;

                                let transfromOptions = getTransformList(
                                    target.style.transform
                                );
                                let translatePosition = (
                                    transfromOptions["translate"] ?? "0px, 0px"
                                )
                                    .split(",")
                                    .map(
                                        (item) =>
                                            parseFloat(item) / this.props.scale
                                    );

                                const x =
                                    parseFloat(target.style.left) /
                                        this.props.scale +
                                    translatePosition[0];
                                const y =
                                    parseFloat(target.style.top) /
                                        this.props.scale +
                                    translatePosition[1];
                                let newSize: {
                                    x: number;
                                    y: number;
                                    nodePosition: NodePosition;
                                    nodeSize: {
                                        desktop: {
                                            width: number;
                                            height: number;
                                        };
                                        mobile: {
                                            width: number;
                                            height: number;
                                        };
                                    };
                                } = {
                                    x: x,
                                    y: y,
                                    nodePosition: {
                                        ...originalNodeSize.nodePosition,
                                        [canvasViewMode]: {
                                            x,
                                            y,
                                        },
                                    },
                                    nodeSize: {
                                        ...originalNodeSize.nodeSize,
                                        [canvasViewMode]: {
                                            width:
                                                parseFloat(target.style.width) /
                                                this.props.scale,
                                            height:
                                                parseFloat(
                                                    target.style.height
                                                ) / this.props.scale,
                                        },
                                    },
                                };

                                target.style.transform = "none";

                                if (!mobileViewWasEdited) {
                                    this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                }

                                let scaleX =
                                    newSize.nodeSize[canvasViewMode].width /
                                    defaultNodeSize.width;
                                let scaleY =
                                    newSize.nodeSize[canvasViewMode].height /
                                    defaultNodeSize.height;

                                const newShapeOptions: ShapeOptions = {
                                    desktop: {
                                        scaleX:
                                            node?.shapeOptions?.desktop
                                                ?.scaleX ?? 1,
                                        scaleY:
                                            node?.shapeOptions?.desktop
                                                ?.scaleY ?? 1,
                                    },
                                    mobile: {
                                        scaleX:
                                            node?.shapeOptions?.mobile
                                                ?.scaleX ?? 1,
                                        scaleY:
                                            node?.shapeOptions?.mobile
                                                ?.scaleY ?? 1,
                                    },
                                    [canvasViewMode]: {
                                        scaleX,
                                        scaleY,
                                    },
                                };

                                this.props.canvasTreeStore.updateNodeAction(
                                    node.id,
                                    {
                                        nodePosition: newSize.nodePosition,
                                        shapeOptions: newShapeOptions,
                                    }
                                );

                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        ...newSize.nodePosition[canvasViewMode],
                                        ...newSize.nodeSize[canvasViewMode],
                                    }
                                );
                                this.props.onResize();
                            }}
                        ></Moveable>
                    )}
                    {this.state.submitStatus != null && (
                        <StatusPopup
                            status={this.state.submitStatus}
                            message={this.state.submitMessage!}
                            onClose={() => {
                                this.setState({
                                    submitStatus: null,
                                    submitMessage: null,
                                });
                            }}
                        />
                    )}
                </>
            </OutsideAlerter>
        );
    }
}

@observer
class Inputs extends Component<Props, State> {
    private arrowDrawingDivRef = React.createRef<HTMLDivElement>();

    constructor(props: InnerProps) {
        super(props);
        this.state = {
            relations: [],
            update: false,
        };
    }

    private _mousemoveHandler = (ev: { clientY: number; clientX: number }) => {
        let arrowDrawingDivRef = this.arrowDrawingDivRef.current;
        if (arrowDrawingDivRef !== null) {
            arrowDrawingDivRef.style.transform =
                "translate3d(" +
                ev.clientX +
                "px," +
                (ev.clientY - 90) +
                "px, 0px)";
            this.setState({
                update: true,
            });
        }
    };

    componentDidMount(): void {
        document.addEventListener("mousemove", this._mousemoveHandler);
    }

    componentWillUnmount(): void {
        document.removeEventListener("mousemove", this._mousemoveHandler);
    }

    private _clearArrows = (previewOnly: boolean) => {
        if (previewOnly) {
            this.setState({
                relations: [
                    ...this.state.relations.filter(
                        (x) => x.targetId !== "archer-element-node-preview"
                    ),
                ],
            });
        } else {
            this.setState({
                relations: [],
            });
        }
    };

    private _addLinkingArrow = (relation: Relation, origin: number) => {
        this.setState({
            relations: [Object.assign(relation, { origin: origin })],
        });
    };

    public render(): JSX.Element | null {
        let flowCharts: JSX.Element[] = [];
        let inputs: JSX.Element[] = [];
        let submitButtons: JSX.Element[] = [];
        let sliders: JSX.Element[] = [];
        let toggles: JSX.Element[] = [];
        let barcodeReaders: JSX.Element[] = [];
        let surveys: JSX.Element[] = [];
        let progressElements: JSX.Element[] = [];
        let isEmptyContainer = true;

        for (let nodeId of this.props.canvasTreeStore.canvasTreeState.keys()) {
            let node = this.props.canvasTreeStore.canvasTreeState.get(nodeId)!;
            if (
                node.nodeIsHidden &&
                node.nodeIsHidden[this.props.canvasTreeStore.canvasViewMode]
            )
                continue;

            if (isRadioButtonsGroup(node)) {
                isEmptyContainer = false;
                flowCharts.push(
                    <InputWrapper
                        key={nodeId}
                        rootDataTestId={`radioButton-${flowCharts.length + 1}`}
                        nodeId={nodeId}
                        remoteModuleId={remoteModuleId}
                        {...this.props}
                    />
                );
            }
            if (isBox(node) && node.gridId == null) {
                isEmptyContainer = false;
                flowCharts.push(
                    <InputWrapper
                        relations={this.state.relations}
                        addLinkingArrow={this._addLinkingArrow}
                        clearArrows={this._clearArrows}
                        key={nodeId}
                        rootDataTestId={`flowChart-${flowCharts.length + 1}`}
                        nodeId={nodeId}
                        remoteModuleId={remoteModuleId}
                        {...this.props}
                    />
                );
            }
            if (isInput(node)) {
                isEmptyContainer = false;
                inputs.push(
                    <InputWrapper
                        key={nodeId}
                        rootDataTestId={`input-${inputs.length + 1}`}
                        nodeId={nodeId}
                        remoteModuleId={remoteModuleId}
                        {...this.props}
                    />
                );
            }
            if (isSubmitButton(node)) {
                isEmptyContainer = false;
                submitButtons.push(
                    <InputWrapper
                        key={nodeId}
                        rootDataTestId={`submitButton-${
                            submitButtons.length + 1
                        }`}
                        nodeId={nodeId}
                        remoteModuleId={remoteModuleId}
                        {...this.props}
                    />
                );
            }
            if (isSlider(node)) {
                isEmptyContainer = false;
                sliders.push(
                    <InputWrapper
                        key={nodeId}
                        rootDataTestId={`slider-${sliders.length + 1}`}
                        nodeId={nodeId}
                        remoteModuleId={remoteModuleId}
                        {...this.props}
                    />
                );
            }
            if (isToggle(node)) {
                isEmptyContainer = false;
                toggles.push(
                    <InputWrapper
                        key={nodeId}
                        rootDataTestId={`toggle-${toggles.length + 1}`}
                        nodeId={nodeId}
                        remoteModuleId={remoteModuleId}
                        {...this.props}
                    />
                );
            }
            if (isBarcodeReader(node)) {
                isEmptyContainer = false;
                barcodeReaders.push(
                    <InputWrapper
                        key={nodeId}
                        rootDataTestId={`barcodeReader-${
                            barcodeReaders.length + 1
                        }`}
                        nodeId={nodeId}
                        remoteModuleId={remoteModuleId}
                        {...this.props}
                    />
                );
            }
            if (isSurvey(node)) {
                isEmptyContainer = false;
                surveys.push(
                    <InputWrapper
                        key={nodeId}
                        rootDataTestId={`survey-${surveys.length + 1}`}
                        nodeId={nodeId}
                        remoteModuleId={remoteModuleId}
                        {...this.props}
                    />
                );
            }
            if (isProgressElement(node)) {
                isEmptyContainer = false;
                progressElements.push(
                    <InputWrapper
                        key={nodeId}
                        rootDataTestId={`progressElement-${
                            progressElements.length + 1
                        }`}
                        nodeId={nodeId}
                        remoteModuleId={remoteModuleId}
                        {...this.props}
                    />
                );
            }
        }
        if (isEmptyContainer) return null;
        return (
            <div
                onClick={() => {
                    this.setState({
                        relations: [],
                    });
                }}
            >
                <ArcherContainer
                    scale={this.props.scale}
                    onClearEditing={this.props.onClearEditing}
                    canvasTreeStore={this.props.canvasTreeStore}
                    lineStyle="angle"
                    strokeColor="#39F"
                    strokeWidth={2 * (this.props.scale ?? 1)}
                    endShape={{
                        arrow: {
                            arrowLength: 6,
                            arrowThickness: 6,
                        },
                    }}
                    svgContainerStyle={{
                        width: this.props.canvasSize.width,
                        height: this.props.canvasSize.height,
                    }}
                >
                    {flowCharts}
                    {inputs}
                    {submitButtons}
                    {sliders}
                    {toggles}
                    {barcodeReaders}
                    {surveys}
                    {progressElements}
                    <div
                        ref={this.arrowDrawingDivRef}
                        style={{
                            position: "fixed",
                            left: "0px",
                            pointerEvents: "none",
                            height: "10px",
                            width: "10px",
                        }}
                    >
                        <ArcherElement
                            id="linkingPreviewer"
                            relations={[
                                ...this.state.relations!.filter(
                                    (x) =>
                                        x.targetId !==
                                        "archer-element-node-preview"
                                ),
                            ]}
                        >
                            <div
                                style={{
                                    height: "10px",
                                    width: "10px",
                                }}
                            ></div>
                        </ArcherElement>
                    </div>
                </ArcherContainer>
            </div>
        );
    }
}

export default Inputs;
