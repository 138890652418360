import React from "react";
import cx from "classnames";
import BootstrapDropdown from "react-bootstrap/Dropdown";

import styles from "./DropdownMenu.module.css";

interface Props {
	children: React.ReactNode;
	arrowPlacement?: React.CSSProperties;
	className?: string;
}

function DropdownMenu({ children, arrowPlacement, className }: Props) {
	return (
		<BootstrapDropdown.Menu className={cx(styles.root, className)}>
			{ arrowPlacement && <div className={styles.arrow} style={arrowPlacement} /> }
			{ children }
		</BootstrapDropdown.Menu>
	)
}

export default DropdownMenu;
