import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import Tables, { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import styles from "./AggregateSection.module.css";
import DataScopeAndTableSelectorView from "common/DataScopeAndTableSelectorView";
import {
    getCustomSelectStyleForDataSection,
    leftAlignAndScrollBarCSS,
    Styles,
} from "common/SelectStyles";
import {
    AggregateState,
    aggregationOptions,
    updateCurrentAggregate,
    defaultAggregateOptions,
} from "common/AggregateTable";
import cx from "classnames";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import StringOption from "common/StringOption";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import Switch from "react-switch";
// import VariablesSelectorView from "common/VariablesSelectorView";
import Variables, { VariableOption } from "common/Variables";
import Select, { createFilter } from "react-select";
import { reaction, toJS } from "mobx";
import { useDrop } from "react-dnd";
import { preview, PreviewHeader, removeitem } from "../MergeSection";
import { ReactComponent as CrossIcon } from "icons/popups/cross.svg";
import DragAndDropStates from "common/DragAndDropStates";
import SelectedOption from "../SelectedOption";
import TabSwitchConfirmationPopup from "common/TabSwitchConfirmationPopup";
import { Permission } from "common/Permissions";
import remoteModuleId from "common/remoteModuleId";

export interface Props {
    id: any;
    name: PreviewHeader;
    databody: Array<string | number | Date> | null;
}

export const applyToAllOptions = [
    {
        label: "Apply to all",
        value: 0,
    },
    {
        label: "Numeric Values",
        value: 1,
    },
    {
        label: "Non- Numeric Values",
        value: 2,
    },
];

function AggregateSection(props: { currentModuleId?: number, setOperationInProgress: (state: boolean) => void; }) {


    let dataVariablesReaction: any = undefined;
    const initializeReaction = (dataScope: DataScopeOption) => {
        if (dataVariablesReaction != null) dataVariablesReaction();
        dataVariablesReaction = reaction(
            () => Variables(dataScope.value).dataVariables,
            () => {
                dataVariablesReaction();

                setUpdateCurrentVariableAggregation(
                    defaultAggregateOptions(dataScope.value)
                );
            }
        );
    };

    const executeAggregate = (
        aggregateState: AggregateState,
        preview: boolean
    ) => {
        setAggregating(true);
        if (preview) {
            setPreviewInfo(null);
        }
        setStatusPopup({
            status: PopupStatus.Success,
            message: "Aggregating data...",
        });
        updateCurrentAggregate(
            aggregateState,
            (previewData, targetDataTableIdx) => {
                setAggregating(false);

                setStatusPopup({
                    status: PopupStatus.Success,
                    message: "Aggregated",
                });
                setTimeout(() => {
                    setStatusPopup(null);
                }, 3000);
                if (!preview) {
                    DataScopes.update();
                    Tables(aggregateState.dataScope!.value).update();
                    if (targetDataTableIdx != null) {
                        Tables(targetDataTableIdx).update();
                        Variables(
                            targetDataTableIdx,
                            props.currentModuleId ?? remoteModuleId
                        ).update(props.currentModuleId ?? remoteModuleId);
                        setTargetDataset((targetDataset) =>
                            targetDataset != null
                                ? {
                                      ...targetDataset,
                                      value: targetDataTableIdx,
                                  }
                                : null
                        );
                        setNewDataScope(null);
                    }
                } else {
                    setPreviewInfo(previewData ?? null);
                }
            },
            (errorMessage: string) => {
                setAggregating(false);

                setStatusPopup({
                    status: PopupStatus.Error,
                    message: errorMessage,
                });
            },
            preview,
            aggregateState.updateCurrentDropMissing
        );
    };
    let [dataset, setDataset] = React.useState<DataScopeOption | null>(null);
    let [table, setTable] = React.useState<TableOption | null>(null);

    let [
        targetDataset,
        setTargetDataset,
    ] = React.useState<DataScopeOption | null>(null);

    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    let [previewInfo, setPreviewInfo] = React.useState<{
        tableHeader: string[];
        tablePreview: any[];
        droppedValuesCount: number;
    } | null>(null);
    let [updateCurrentBy, setUpdateCurrentBy] = React.useState<
        (VariableOption | null)[] | []
    >([]);
    let [dropMissing, setDropMissing] = React.useState<boolean>(false);
    let [aggregating, setAggregating] = React.useState<boolean>(false);
    let [
        updateCurrentVariableAggregation,
        setUpdateCurrentVariableAggregation,
    ] = React.useState<StringOption[]>([]);
    let [commonChangeOption, setCommonChangeOption] = React.useState<number>(0);
    let [functionType, setfunctionType] = React.useState<StringOption | null>(
        null
    );
    let [allNone, setallNone] = useState<boolean>(false);
    let [dataScopeText, setDataScopeText] = useState<string | undefined>(
        undefined
    );
    let [newDataScope, setNewDataScope] = useState<DataScopeOption | null>(
        null
    );

    let dataHubStyles: Styles = {
        ...getCustomSelectStyleForDataSection(14, false),
        ...leftAlignAndScrollBarCSS,
        container: (base) => ({
            ...base,
            width: "200px",
            height: "35px",
        }),
    };

    let variablesAreSelected =
        updateCurrentBy
            .filter((item) => item != null)
            .map((item) => item!.value).length > 0;

    async function Dataset_Preview(id: number | string) {
        await DataScopes.DatasetPreview(id);
    }

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: "image",
            drop: (item: any) => {
                DragAndDropStates.updateSelected(item);
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        }),
        [DragAndDropStates.selected1]
    );

    const onclickremove = (index: number) => {
        updateCurrentBy.splice(index, 1);
        setUpdateCurrentBy((curr) => [...curr]);
    };
    
    React.useEffect(() => {
        props.setOperationInProgress((dataset || targetDataset)? true : false);
    },[dataset, targetDataset]);

    useEffect(() => {
        let newUpdateCurrentVariableAggregation = [
            ...updateCurrentVariableAggregation,
        ];
        setUpdateCurrentBy([]);
        DragAndDropStates.selected1.map((item, i) => {
            let matched = false;
            updateCurrentBy.map((curr, i) => {
                if (curr?.value === item.id) {
                    setUpdateCurrentBy([...updateCurrentBy]);
                    matched = true;
                }
                return null;
            });
            if (!matched) {
                setUpdateCurrentBy([
                    ...updateCurrentBy,
                    {
                        label: item?.name,
                        value: item?.id,
                        type: item?.type,
                        panel: "regular",
                    },
                ]);
            }
            return null;
        });
        DragAndDropStates.selected1.forEach((variable) => {
            if (variable != null) {
                newUpdateCurrentVariableAggregation[variable.id] =
                    aggregationOptions[6];
            }
        });

        setUpdateCurrentVariableAggregation(
            newUpdateCurrentVariableAggregation
        );
    }, [DragAndDropStates.selected1]);

    useEffect(() => {
        return () => {
            DragAndDropStates.makedefault();
            DataScopes.previewState = { headers: null, body: null };
        };
    }, []);

    useEffect(() => {
        const variables = toJS(Variables(dataset?.value).dataVariables);
        if (commonChangeOption === 0) {
            variables != null &&
                variables.map((currentVariable, index) => {
                    if (
                        functionType?.label === "Min" ||
                        functionType?.label === "Max" ||
                        functionType?.label === "None"
                    ) {
                        updateCurrentVariableAggregation.splice(index, 1, {
                            label: functionType?.label,
                            value: functionType?.value,
                        });
                        setUpdateCurrentVariableAggregation([
                            ...updateCurrentVariableAggregation,
                        ]);
                        for (let i = 0; i < updateCurrentBy.length; i++) {
                            if (
                                currentVariable.name ===
                                updateCurrentBy[i]?.label
                            ) {
                                updateCurrentVariableAggregation.splice(
                                    index,
                                    1,
                                    { label: "None", value: "none" }
                                );
                                setUpdateCurrentVariableAggregation([
                                    ...updateCurrentVariableAggregation,
                                ]);
                                break;
                            }
                        }
                    } else {
                        if (
                            currentVariable.type === "float" ||
                            currentVariable.type === "int"
                        ) {
                            updateCurrentVariableAggregation.splice(index, 1, {
                                label: functionType?.label ?? "",
                                value: functionType?.value ?? "",
                            });
                            setUpdateCurrentVariableAggregation([
                                ...updateCurrentVariableAggregation,
                            ]);
                            for (let i = 0; i < updateCurrentBy.length; i++) {
                                if (
                                    currentVariable.name ===
                                    updateCurrentBy[i]?.label
                                ) {
                                    updateCurrentVariableAggregation.splice(
                                        index,
                                        1,
                                        { label: "None", value: "none" }
                                    );
                                    setUpdateCurrentVariableAggregation([
                                        ...updateCurrentVariableAggregation,
                                    ]);
                                    break;
                                }
                            }
                        } else {
                            updateCurrentVariableAggregation.splice(index, 1, {
                                label: "None",
                                value: "none",
                            });
                            setUpdateCurrentVariableAggregation([
                                ...updateCurrentVariableAggregation,
                            ]);
                        }
                    }
                    return null;
                });
        }
        if (commonChangeOption === 1) {
            variables != null &&
                variables.map((currentVariable, index) => {
                    if (
                        currentVariable.type === "float" ||
                        currentVariable.type === "int"
                    ) {
                        updateCurrentVariableAggregation.splice(index, 1, {
                            label: functionType?.label ?? "",
                            value: functionType?.value ?? "",
                        });
                        setUpdateCurrentVariableAggregation([
                            ...updateCurrentVariableAggregation,
                        ]);
                        for (let i = 0; i < updateCurrentBy.length; i++) {
                            if (
                                currentVariable.name ===
                                updateCurrentBy[i]?.label
                            ) {
                                updateCurrentVariableAggregation.splice(
                                    index,
                                    1,
                                    { label: "None", value: "none" }
                                );
                                setUpdateCurrentVariableAggregation([
                                    ...updateCurrentVariableAggregation,
                                ]);
                                break;
                            }
                        }
                    }
                    return null;
                });
        }
        if (commonChangeOption === 2) {
            variables != null &&
                variables.map((currentVariable, index) => {
                    if (
                        !(
                            currentVariable.type === "float" ||
                            currentVariable.type === "int"
                        )
                    ) {
                        if (
                            functionType?.label === "Min" ||
                            functionType?.label === "Max" ||
                            functionType?.label === "None"
                        ) {
                            updateCurrentVariableAggregation.splice(index, 1, {
                                label: functionType?.label,
                                value: functionType?.value,
                            });
                            setUpdateCurrentVariableAggregation([
                                ...updateCurrentVariableAggregation,
                            ]);
                            for (let i = 0; i < updateCurrentBy.length; i++) {
                                if (
                                    currentVariable.name ===
                                    updateCurrentBy[i]?.label
                                ) {
                                    updateCurrentVariableAggregation.splice(
                                        index,
                                        1,
                                        { label: "None", value: "none" }
                                    );
                                    setUpdateCurrentVariableAggregation([
                                        ...updateCurrentVariableAggregation,
                                    ]);
                                    break;
                                }
                            }
                        } else {
                            updateCurrentVariableAggregation.splice(index, 1, {
                                label: "None",
                                value: "none",
                            });
                            setUpdateCurrentVariableAggregation([
                                ...updateCurrentVariableAggregation,
                            ]);
                        }
                    }
                    return null;
                });
        }
    }, [functionType, commonChangeOption]);

    const resetToDefault = () => {
        setUpdateCurrentBy([]);
        setUpdateCurrentVariableAggregation([]);
        setDropMissing(false);
        setAggregating(false);
        setCommonChangeOption(0);
        DragAndDropStates.makedefault();
    };

    useEffect(() => {
        for (let i = 0; i < updateCurrentVariableAggregation.length; i++) {
            if (updateCurrentVariableAggregation[i]?.label === "None") {
                setallNone(true);
            } else {
                setallNone(false);
                break;
            }
        }
    }, [dataset, updateCurrentVariableAggregation]);

    const hotAreaStyle = (dataset: number) => {
        return {
            border:
                DragAndDropStates.getdataset === dataset && isOver
                    ? "2px dotted var(--hot-area-greenBorder)"
                    : DragAndDropStates.getdataset === dataset
                    ? "2px dotted var(--hot-area-blueBorder)"
                    : "2px dotted transparent",
            backgroundColor:
                DragAndDropStates.getdataset === dataset && isOver
                    ? "var(--hot-area-greenBg)"
                    : DragAndDropStates.getdataset === dataset
                    ? "var(--hot-area-blueBg)"
                    : "#fff",
            outline: "1px solid #fff",
        };
    };

    const getTargetSubText = useCallback(
        (
            dataset: DataScopeOption | null,
            targetDataset: DataScopeOption | null
        ) => {
            if (
                dataset != null &&
                dataset.permissionType < Permission.ReadWrite &&
                targetDataset == null
            ) {
                return "insufficient permissions - choose another dataset";
            } else if (targetDataset != null) {
                return "base table of the dataset";
            } else {
                return "aggregate table of the same dataset";
            }
        },
        []
    );

    const dataScopes =
        props.currentModuleId == null
            ? DataScopes
            : DataScopesForModules(props.currentModuleId);

    return (
        <div className={"mainContainer"}>
            <h1 style={{ display: "none" }}> {DragAndDropStates.showDrag}</h1>
            <h1 style={{ display: "none" }}> {DragAndDropStates.datasett}</h1>
            <div className={styles.datasetsContainer}>
                <div className={styles.dataset}>
                    <div className={styles.datasetSelector}>
                        <div
                            className={styles.textItem}
                            style={{ marginTop: 0 }}
                        >
                            Source
                        </div>
                        <DataScopeAndTableSelectorView
                            dataHubMode
                            dataScopeOption={dataset}
                            tableOption={table}
                            onChange={(dataScopeOption, tableOption) => {
                                if (dataVariablesReaction != null)
                                    dataVariablesReaction();
                                setDataset(dataScopeOption);
                                if (
                                    dataScopeOption?.value ===
                                    targetDataset?.value
                                ) {
                                    setTargetDataset(null);
                                }
                                setTable(tableOption);
                                setUpdateCurrentBy([]);
                                if (dataScopeOption != null) {
                                    setUpdateCurrentVariableAggregation(
                                        defaultAggregateOptions(
                                            dataScopeOption.value
                                        )
                                    );
                                    initializeReaction(dataScopeOption);
                                }
                                if (dataScopeOption?.value != null) {
                                    Dataset_Preview(dataScopeOption?.value);
                                }
                                setPreviewInfo(null);
                                DataScopes.previewState = {
                                    headers: null,
                                    body: null,
                                };
                                DragAndDropStates.ReUpdateSelected([], 1);

                                SelectedOption.UpdateaskSwitchTab(false);
                                SelectedOption.UpdateSwitchTab(false);
                            }}
                            dataScopePlaceholder={"Select dataset"}
                            needWritePermissions={false}
                            hideTable={true}
                        />
                    </div>
                </div>
                <div className={styles.dataset} style={{ marginLeft: 50 }}>
                    <div className={styles.datasetSelector}>
                        <div
                            className={styles.textItem}
                            style={{ marginTop: 0 }}
                        >
                            Target
                        </div>
                        {/*<DataScopeAndTableSelectorView
                            dataHubMode
                            dataScopeOption={targetDataset}
                            tableOption={null}
                            onChange={(dataScopeOption, _tableOption) => {
                                if (dataScopeOption?.value === dataset?.value) {
                                    setTargetDataset(null);
                                } else {
                                    setTargetDataset(dataScopeOption);
                                }
                            }}
                            dataScopePlaceholder={"Same dataset"}
                            needWritePermissions={true}
                            hideTable={true}
                        />*/}
                        <Select
                            inputId="select-data-scope"
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Same dataset"}
                            styles={{
                                ...dataHubStyles,
                                container: (base) => ({
                                    ...base,
                                    width: "50%",
                                    height: "35px",
                                    justifyContent: "left",
                                }),
                            }}
                            options={dataScopes.dataScopesOptions
                                .filter(
                                    (option) => typeof option.value === "number"
                                )
                                .concat(
                                    newDataScope != null ? [newDataScope] : []
                                )}
                            onChange={(dataScopeOption, _p2) => {
                                if (
                                    (dataScopeOption as DataScopeOption | null)
                                        ?.value === dataset?.value
                                ) {
                                    setTargetDataset(null);
                                } else {
                                    setTargetDataset(
                                        dataScopeOption as DataScopeOption | null
                                    );
                                }
                            }}
                            inputValue={dataScopeText}
                            onInputChange={(newValue, { action }) => {
                                if (
                                    ["input-blur"].includes(action) &&
                                    (dataScopeText?.length ?? 0) > 0
                                ) {
                                    setNewDataScope({
                                        label: dataScopeText ?? "",
                                        value: NaN,
                                        permissionType: Permission.ReadWrite,
                                    });
                                    setTargetDataset({
                                        label: dataScopeText ?? "",
                                        value: NaN,
                                        permissionType: Permission.ReadWrite,
                                    });
                                }
                                setDataScopeText(newValue);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    (e.target as HTMLElement).blur();
                                }
                            }}
                            value={targetDataset}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <small
                            className={styles.textItem}
                            style={{ marginTop: 0, fontSize: "10px" }}
                        >
                            {getTargetSubText(dataset, targetDataset)}
                        </small>
                    </div>
                </div>
            </div>
            {DataScopes.preview?.headers != null && (
                <div
                    className={"customPreview"}
                    style={{ padding: "0px 15px 15px 15px" }}
                >
                    {preview(
                        DataScopes.preview?.headers,
                        DataScopes.preview?.body,
                        1
                    )}
                </div>
            )}
            <div className={styles.mergeCustomSec}>
                {DragAndDropStates.selected1.length === 0 ? (
                    <h3 className={styles.textItem}>
                        Select dataset to drop variables
                    </h3>
                ) : (
                    <h3 className={styles.textItem}>Selected variables</h3>
                )}
                <div className={styles.mainMergeSec} ref={drop} style={hotAreaStyle(1)}>
                    <div className={styles.mergeSecContent}>
                        {DragAndDropStates.selected1?.length !== 0 ? (
                            <div className={styles.dropletItem}>
                                {DragAndDropStates.selected1.map((card, i) => (
                                    <span className={styles.mergefild}>
                                        {card.name}
                                        <button
                                            className={styles.removeBtn}
                                            onClick={() => {
                                                removeitem(i, 1);
                                            }}
                                        >
                                            <CrossIcon
                                                stroke="grey"
                                                onClick={() => onclickremove(i)}
                                            />
                                        </button>
                                    </span>
                                ))}
                            </div>
                        ) : (
                            <p
                                className={styles.previewNull}
                                style={hotAreaStyle(1)}
                            >
                                Drop variables here
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.variablesContainer}>
                <h3 className={styles.textItem}>
                    Apply function to other variables
                </h3>
                <div className={styles.variableTableWrapper}>
                    <table>
                        <tr>
                            <th style={{ padding: "10px 15px" }}>
                                <h3
                                    className={styles.textItem}
                                    style={{ paddingLeft: 0 }}
                                >
                                    Variable
                                </h3>
                            </th>
                            <th>
                                <h3 className={styles.textItem}>Function</h3>
                            </th>
                        </tr>
                        {dataset != null &&
                            Variables(dataset.value).dataVariables.map(
                                (variable, index) => {
                                    let isDisabled = updateCurrentBy
                                        .map((item) => item?.value ?? null)
                                        .includes(variable.index);
                                    return (
                                        <tr key={index}>
                                            <td
                                                className={styles.textItem}
                                                style={{ fontSize: 14 }}
                                            >
                                                {variable.name}
                                            </td>

                                            <td>
                                                <Select
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    menuShouldBlockScroll={true}
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    isDisabled={isDisabled}
                                                    placeholder={"Variable"}
                                                    styles={dataHubStyles}
                                                    options={
                                                        [
                                                            "int",
                                                            "float",
                                                        ].includes(
                                                            variable.type
                                                        )
                                                            ? aggregationOptions
                                                            : aggregationOptions.slice(
                                                                  4
                                                              )
                                                    }
                                                    onChange={(newValue) => {
                                                        let newUpdateCurrentVariableAggregation = [
                                                            ...updateCurrentVariableAggregation,
                                                        ];
                                                        newUpdateCurrentVariableAggregation[
                                                            index
                                                        ] = newValue as StringOption;
                                                        setUpdateCurrentVariableAggregation(
                                                            newUpdateCurrentVariableAggregation
                                                        );
                                                    }}
                                                    value={
                                                        updateCurrentVariableAggregation[
                                                            index
                                                        ]
                                                    }
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                />
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                    </table>
                </div>
            </div>
            <div className={styles.dropMissingContainer}>
                <span className={styles.dropMissing}>
                    <h3 className={styles.textItem}>{`Drop missing`}</h3>
                    <Switch
                        onChange={(checked) => {
                            setDropMissing(checked);
                        }}
                        checked={dropMissing}
                        width={40}
                        height={20}
                        offColor="#CCC"
                        onColor="#CCC"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#70889E"
                        onHandleColor="#1F8EFA"
                    />
                </span>

                <div>
                    <div>
                        <Select
                            options={
                                commonChangeOption === 0 ||
                                commonChangeOption === 1
                                    ? aggregationOptions
                                    : aggregationOptions.slice(4)
                            }
                            onChange={(e) => {
                                setfunctionType(e);
                                SelectedOption.UpdateaskSwitchTab(false);
                                SelectedOption.UpdateSwitchTab(false);
                            }}

                            isMulti={false}
                            defaultValue={{ label: "Select", value: "" }}
                            styles={{
                                ...leftAlignAndScrollBarCSS,
                                container: (base) => ({
                                    ...base,
                                    backgroundColor: "transparent",
                                    width: "120px",
                                    border: "navajowhite",
                                    padding: "0 0 0 5px",
                                    justifyContent: "center",
                                }),
                                option: () => ({
                                    paddingLeft: 10,
                                    paddingBlock: 5,
                                    fontSize: 14,
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor: "var(--greyColor)",
                                        borderRadius: 6,
                                    },
                                }),
                                indicatorSeparator: (base) => ({
                                    ...base,
                                    display: "none",
                                    width: 0,
                                }),
                            }}
                        />
                    </div>
                    <div>
                        <Select
                            options={applyToAllOptions}
                            onChange={(e) => {
                                setCommonChangeOption(e?.value ?? 0);
                            }}
                            defaultValue={{ label: "Apply to all", value: 0 }}
                            styles={{
                                container: (base) => ({
                                    ...base,
                                    backgroundColor: "transparent",
                                    width: "200px",
                                    border: "navajowhite",
                                    padding: "0 0 0 5px",
                                    justifyContent: "center",
                                    marginInline: 8,
                                    marginLeft: 11,
                                }),
                                option: () => ({
                                    paddingLeft: 10,
                                    paddingBlock: 5,
                                    fontSize: 14,
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                    "&:hover": {
                                        backgroundColor: "var(--greyColor)",
                                        borderRadius: 6,
                                    },
                                }),
                                singleValue: (base) => ({
                                    ...base,
                                    paddingLeft: 0,
                                    color: "white",
                                }),
                                valueContainer: (base) => ({
                                    ...base,
                                    paddingLeft: 5,
                                }),
                                input: (base) => ({
                                    ...base,
                                    color: "white",
                                }),
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: "#3b83c9",
                                }),
                                menuList: (base) => ({
                                    ...base,
                                    "::-webkit-scrollbar": {
                                        width: "4px",
                                        height: "0px",
                                    },
                                    "::-webkit-scrollbar-track": {
                                        background: "#f1f1f1",
                                    },
                                    "::-webkit-scrollbar-thumb": {
                                        background: "#888",
                                    },
                                    "::-webkit-scrollbar-thumb:hover": {
                                        background: "#555",
                                    },
                                }),
                                indicatorSeparator: (base) => ({
                                    ...base,
                                    display: "none",
                                    width: 0,
                                }),
                            }}
                        />
                    </div>
                    <button
                        className={"cancelBtn"}
                        style={{ fontSize: 14 }}
                        onClick={resetToDefault}
                    >
                        Reset
                    </button>
                </div>
            </div>
            <div className={styles.previewbtnContainer}>
                <button
                    className={"acceptBtn"}
                    type="button"
                    disabled={
                        !variablesAreSelected ||
                        aggregating ||
                        dataset === null ||
                        allNone ||
                        table === null ||
                        (dataset != null &&
                            dataset.permissionType < Permission.ReadWrite &&
                            targetDataset == null)
                    }
                    onClick={() => {
                        let aggregateState: AggregateState = {
                            updateCurrentBy: updateCurrentBy,
                            updateCurrentVariableAggregation: updateCurrentVariableAggregation,
                            dataScope: dataset,
                            aggregateDataTable: table,
                            updateCurrentDropMissing: dropMissing,
                            targetDataScope: targetDataset,
                        };
                        executeAggregate(aggregateState, true);
                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(false);
                    }}
                >
                    {"Preview"}
                </button>
            </div>

            <div className={styles.mergedPreview}>
                <div className={styles.previewContainer}>
                    {previewInfo != null ? (
                        <>
                            <div className={`mergedTableWrapper scrollBar`}>
                                <AdminTableWithFullFeatures
                                    tableName="AggregateTable"
                                    className="import-table"
                                    paging={false}
                                    small={false}
                                    tableHeader={previewInfo.tableHeader}
                                    tableContent={previewInfo.tablePreview}
                                />
                            </div>
                            <div
                                className="my-row"
                                style={{
                                    marginTop: "20px 20px 0 20px",
                                    justifyContent: "space-between",
                                }}
                            >
                                {previewInfo.droppedValuesCount != null &&
                                    previewInfo.droppedValuesCount >= 0 && (
                                        <div
                                            className={styles.textItem}
                                            style={{ margin: "10px 0 -5px 0" }}
                                        >
                                            {`${previewInfo.droppedValuesCount} will be dropped`}
                                        </div>
                                    )}
                            </div>
                        </>
                    ) : (
                        <p style={{ textAlign: "center", paddingTop: 72 }}>
                            Preview
                        </p>
                    )}
                </div>
            </div>

            <div className={"pageEndbuttons"}>
                <button
                    className={"acceptBtn"}
                    style={{ padding: "7px 10px" }}
                    type="button"
                    disabled={
                        !variablesAreSelected ||
                        aggregating ||
                        dataset === null ||
                        table === null ||
                        (dataset != null &&
                            dataset.permissionType < Permission.ReadWrite &&
                            targetDataset == null)
                    }
                    onClick={() => {
                        let aggregateState: AggregateState = {
                            updateCurrentBy: updateCurrentBy,
                            updateCurrentVariableAggregation: updateCurrentVariableAggregation,
                            dataScope: dataset,
                            aggregateDataTable: table,
                            updateCurrentDropMissing: dropMissing,
                            targetDataScope: targetDataset,
                        };
                        executeAggregate(aggregateState, false);

                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(true);
                    }}
                >
                    Aggregate data
                </button>
            </div>

            {SelectedOption.getAskSwitchTab &&
                (dataset !== null ||
                    functionType !== null ||
                    commonChangeOption !== 0) && <TabSwitchConfirmationPopup />}

            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
            {DataScopes.previewState.headers === null && dataset !== null && (
                <StatusPopup
                    status={PopupStatus.Error}
                    message={"Loading Data"}
                    onClose={() => {
                        // console.log("popup closed")
                    }}
                />
            )}
        </div>
    );
}

export default observer(AggregateSection);
