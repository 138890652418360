import axios from "common/ServerConnection";

export interface TokenSettings {
    display_name: string;
    name: string;
    settings: {
        [key: string]: {
            display_name: string;
            type: SettingsType;
            options?: {
                label: string;
                value: string | number;
            }[];
        };
    };
    settings_values: { [key: string]: string | number | null | undefined };
}

export enum SettingsType {
    String = "str",
    Choice = "choice",
}

export async function getTokenSettingsApi(): Promise<TokenSettings[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
            settings?: TokenSettings[];
        }>("/api/token_storage_get_token_settings")
        .then((response) => {
            if (response.data.success && response.data.settings != null) {
                return Promise.resolve(response.data.settings);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function setTokenSettingsApi(
    tokenName: string,
    settingsValues: { [key: string]: string | number | null | undefined }
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
        }>("/api/token_storage_set_token_settings", {
            token_name: tokenName,
            settings_values: settingsValues,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
