import React from "react";
import KanbanBoard from "common/graphics/KanbanBoard";

function MainComponent(props) {
    let finding = props.finding;
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <KanbanBoard
                onDataChanged={(newData) => {
                    if (props.onNewFinding != null) {
                        props.onNewFinding({
                            ...finding,
                            content: {
                                ...finding.content,
                                data: {
                                    ...finding.content.data,
                                    lanes: newData.lanes,
                                },
                            },
                        });
                    }
                }}
                editable={props.onNewFinding != null}
                data={finding.content.data}
                config={finding.config}
            />
        </div>
    );
}

export { MainComponent };
