import React, { Component } from "react";
import Variables, { Variable } from "common/Variables";
import { IReactionDisposer, autorun } from "mobx";
import { observer } from "mobx-react";
import cx from "classnames";
import dataScopes from "common/DataScopes";
import { Permission } from "common/Permissions";
import { Element } from "react-scroll";
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { Resizable } from "re-resizable";
import BackendTableSelector from "./BackendTableSelector";
import elements from "common/CanvasElements";
import {
    ColorOptions,
    CanvasTable,
    ConnectedBackendTable,
    InnerCanvasChanges,
} from "common/Canvas";
import CanvasInteractionComponent from "../CanvasInteractionComponent";
import { stopPropagation } from "common/utilities/EventFunctions";
import { goToInternalLink } from "common/InternalLinksHelper";
import Tables, { TableOption } from "common/Tables";
import ExportCsvPopup from "../ExportCsvPopup";
import CanvasPreventPropagationButton from "../../CanvasPreventPropagationButton";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import HtmlElementProps from "../HtmlElementProps";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import {
    changeElementWhenResize2,
    getNewSizeAfterResize2,
} from "../../BaseCanvasResizableFunctions";
import remoteModuleId from "common/remoteModuleId";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../../Snap";
import CanvasTreeStore from "../../CanvasTreeStore";
import CanvasIconsContainter from "../../CanvasIconsContainer";
import { CSVRow } from "common/CSVTypes";
import { strftime } from "common/utilities/TimeFormatUtils";
import styles from "./BackendTables.module.css";
import MessagePopup from "common/MessagePopup";
import { deleteTable } from "common/DataApi";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import Portal from "common/Portal";

interface Props extends HtmlElementProps {}

const typeName = "backendTablesState";

export interface EditedTableCell {
    row: number;
    column: number;
    row_id: number;
    value: string | number | undefined;
    tableId: string;
}

interface State {
    exportedTableOption: TableOption | undefined;
    hamburgerMenuOpened: boolean;
    clearDataPopupTableId: string | undefined;
    popupStatus: PopupStatus | undefined;
    popupMessage: string | undefined;
}

interface InnerProps {
    tableId: string;
    hasWriteAccess: boolean;
    live: boolean;
    canWrite: boolean;
    connectedFullTable: ConnectedBackendTable | undefined;
    connectedTableFormats: Variable[] | undefined;
    tableDescription: CanvasTable;
    currentModuleId?: number;
    canvasTreeStore: CanvasTreeStore;
    scale: number;
}

interface InnerState {
    columnScales: { [key: number]: number };
    resizing: {
        initialWidth: number;
        initialWidthNext: number;
    } | null;
    tableHeader: string[];
    tableContent: (string | number)[][];
    rowHovered: number | null;
}

const minColumnWidth = 18;

@observer
class TableInner extends Component<InnerProps, InnerState> {
    private autorunDispose: IReactionDisposer | null = null;

    constructor(props: InnerProps) {
        super(props);

        this.sortColumnClicked = this.sortColumnClicked.bind(this);
        this.tableInnerHeaderRenderer = this.tableInnerHeaderRenderer.bind(
            this
        );

        this.state = {
            columnScales: props.tableDescription.columnScales ?? {},
            resizing: null,
            tableHeader: [],
            tableContent: [],
            rowHovered: null,
        };
        this.updateTableState();
    }

    public componentDidMount(): void {
        // We need to update the table when Variables changes
        this.autorunDispose = autorun(() => this.updateTableState());
    }

    public componentWillUnmount(): void {
        if (this.autorunDispose != null) {
            this.autorunDispose();
            this.autorunDispose = null;
        }
    }

    public componentDidUpdate(prevProps: InnerProps): void {
        if (
            prevProps.tableDescription.columnScales !==
            this.props.tableDescription.columnScales
        ) {
            this.setState({
                columnScales: this.props.tableDescription.columnScales ?? {},
            });
        }
        if (
            prevProps.connectedFullTable?.rawTable !==
                this.props.connectedFullTable?.rawTable ||
            prevProps.tableDescription.sort !== this.props.tableDescription.sort
        ) {
            this.updateTableState();
        }
    }

    private updateTableState(): void {
        let dataVariables = Variables(
            this.props.tableDescription.tableOption!.data_table_idx,
            this.props.currentModuleId ?? remoteModuleId
        ).dataVariables;
        let connectedTable = this.props.connectedFullTable?.rawTable;
        if (connectedTable != null) {
            let tableHeader = Object.keys(connectedTable);
            let tableContent: (string | number)[][] = [];
            let rowCount = Object.values(connectedTable)[0].length;
            for (let i = 0; i < rowCount; ++i) {
                let row = [];
                for (let varName of tableHeader) {
                    if (typeof connectedTable[varName] === "undefined") break;
                    if (
                        dataVariables.find((v) => v.name === varName)?.type ===
                        "datetime"
                    ) {
                        const format = this.props.connectedTableFormats?.filter(
                            (x) => x.name === varName
                        )[0]?.format;
                        const formattedTime = strftime(
                            format ?? ("%m/%d/%y %I:%M %p" as string),
                            new Date(
                                (connectedTable[varName][i] as number) * 1000
                            ),
                            false
                        );
                        if (typeof connectedTable[varName][i] === "number") {
                            row.push(formattedTime);
                        } else {
                            row.push(connectedTable[varName][i] ?? "-");
                        }
                    } else {
                        row.push(connectedTable[varName][i] ?? "-");
                    }
                }
                tableContent.push(row);
            }
            if (this.props.tableDescription.sort != null) {
                const {
                    columnIndex,
                    direction,
                } = this.props.tableDescription.sort;
                const compareFn: (row1: CSVRow, row2: CSVRow) => number =
                    direction === "asc"
                        ? (row1, row2) => {
                              if (
                                  row1[columnIndex] == null &&
                                  row2[columnIndex] == null
                              ) {
                                  return 0;
                              } else if (row1[columnIndex] == null) {
                                  return 1;
                              } else if (row2[columnIndex] == null) {
                                  return -1;
                              } else {
                                  return row1[columnIndex]! < row2[columnIndex]!
                                      ? -1
                                      : 1;
                              }
                          }
                        : (row1, row2) => {
                              if (
                                  row1[columnIndex] == null &&
                                  row2[columnIndex] == null
                              ) {
                                  return 0;
                              } else if (row1[columnIndex] == null) {
                                  return -1;
                              } else if (row2[columnIndex] == null) {
                                  return 1;
                              } else {
                                  return row1[columnIndex]! > row2[columnIndex]!
                                      ? -1
                                      : 1;
                              }
                          };
                tableContent.sort(compareFn);
            }
            this.setState({
                tableHeader: tableHeader,
                tableContent: tableContent,
            });
        } else {
            this.setState({
                tableHeader: [],
                tableContent: [],
            });
        }
    }

    private sortColumnClicked(
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void {
        const index = Number(evt.currentTarget.getAttribute("data-col"));
        let direction = this.props.tableDescription.sort?.direction;
        if (
            this.props.tableDescription.sort?.columnIndex === index &&
            direction === "asc"
        ) {
            direction = "desc";
        } else {
            direction = "asc";
        }
        this.props.canvasTreeStore.updateBackendTableAction(
            this.props.tableId,
            {
                sort: {
                    columnIndex: index,
                    direction: direction,
                },
            }
        );
    }

    private tableInnerHeaderRenderer(
        item: string,
        index: number,
        numberOfColumns: number,
        width: number
    ): JSX.Element {
        // We use a custom header to implement sorting. We can't use the default
        // sorting feature because there are several bugs that make it reset the
        // sort order to descending.
        return (
            <div
                style={{
                    backgroundColor:
                        this.state.rowHovered === -1 ? "#e2f6ff" : undefined,
                    display: "flex",
                    width: width,
                    minWidth: width,
                    maxWidth: width,
                    height: 35,
                    minHeight: 35,
                    maxHeight: 35,
                    alignItems: "center",
                    padding: 8,
                    borderStyle: "solid",
                    borderColor: "rgba(194,201,209,.5)",
                    borderLeftWidth: index === 0 ? 1 : 0,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    borderTopLeftRadius: index === 0 ? 8 : 0,
                    borderTopRightRadius: index === numberOfColumns - 1 ? 8 : 0,
                    overflow: "clip",
                    boxSizing: "border-box",
                    MozBoxSizing: "border-box",
                    WebkitBoxSizing: "border-box",
                }}
                onMouseEnter={() => {
                    this.setState({ rowHovered: -1 });
                }}
                onMouseLeave={() => {
                    this.setState({ rowHovered: null });
                }}
            >
                <div
                    style={{
                        flex: 1,
                        textOverflow: "clip",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                >
                    {item}
                </div>
                <div
                    title="Sort"
                    data-col={index}
                    onClick={this.sortColumnClicked}
                    style={{
                        cursor: "pointer",
                        transformOrigin: "center center",
                        transform:
                            this.props.tableDescription.sort?.columnIndex ===
                                index &&
                            this.props.tableDescription.sort?.direction ===
                                "asc"
                                ? "none"
                                : "rotate(180deg)",
                    }}
                >
                    <CanvasIconsContainter
                        type={"Sort"}
                        options={{
                            width: 11 * this.props.scale,
                            height: 11 * this.props.scale,
                        }}
                    />
                </div>
            </div>
        );
    }

    private tableInnerCellRenderer(
        item: string,
        index: number,
        numberOfColumns: number,
        rowIndex: number,
        numberOfRows: number,
        width: number
    ): JSX.Element {
        // We use a custom header to implement sorting. We can't use the default
        // sorting feature because there are several bugs that make it reset the
        // sort order to descending.
        return (
            <div
                key={index}
                style={{
                    backgroundColor:
                        this.state.rowHovered === rowIndex
                            ? "#e2f6ff"
                            : undefined,
                    display: "flex",
                    width: width,
                    minWidth: width,
                    maxWidth: width,
                    height: 35,
                    minHeight: 35,
                    maxHeight: 35,
                    alignItems: "center",
                    padding: 8,
                    borderStyle: "solid",
                    borderColor: "rgba(194,201,209,.5)",
                    borderLeftWidth: index === 0 ? 1 : 0,
                    borderRightWidth: index === numberOfColumns - 1 ? 1 : 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 1,
                    borderBottomLeftRadius:
                        rowIndex === numberOfRows - 1 && index === 0 ? 8 : 0,
                    borderBottomRightRadius:
                        rowIndex === numberOfRows - 1 &&
                        index === numberOfColumns - 1
                            ? 8
                            : 0,
                    textOverflow: "clip",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    boxSizing: "border-box",
                    MozBoxSizing: "border-box",
                    WebkitBoxSizing: "border-box",
                }}
                onMouseEnter={() => {
                    this.setState({ rowHovered: rowIndex });
                }}
                onMouseLeave={() => {
                    this.setState({ rowHovered: null });
                }}
            >
                {item}
            </div>
        );
    }

    private calculateTransformedDelta(delta: number): number {
        // We multiply delta by 2 and divide by this.props.scale because
        // transform: scale is set to 0.5 * this.props.scale
        return (delta * 2) / this.props.scale;
    }

    private getColumnWidth(index: number, tableInnerWidth: number): number {
        return (
            (tableInnerWidth / this.state.tableHeader!.length) *
            (this.state.columnScales[index] ?? 1)
        );
    }

    public render(): JSX.Element | null {
        // 40 = padding, 12 = vertical scroll bar
        let tableInnerWidth =
            this.props.tableDescription.nodeSize[
                this.props.canvasTreeStore.canvasViewMode
            ].width *
                2 -
            40 -
            12;

        let varsInitialized = Variables(
            this.props.tableDescription.tableOption!.data_table_idx,
            this.props.currentModuleId ?? remoteModuleId
        ).initialized;

        let loading = this.props.connectedFullTable == null;
        if (!varsInitialized) loading = false;

        if (loading) {
            return (
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <span className="big-title-span">{"LOADING"}</span>
                </div>
            );
        }
        if (this.state.tableHeader.length !== 0) {
            return (
                <Element
                    name="scrollable"
                    className="element"
                    style={{
                        overflowX: "hidden",
                        overflowY: "auto",
                        width: "100%",
                    }}
                    onMouseDown={stopPropagation}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div style={{ display: "flex", width: "100%" }}>
                            {this.state.tableHeader.map((item, index) => (
                                <Resizable
                                    key={index}
                                    minWidth={minColumnWidth}
                                    enable={{
                                        top: false,
                                        right:
                                            !this.props.live &&
                                            this.props.canWrite &&
                                            index !==
                                                this.state.tableHeader.length -
                                                    1,
                                        bottom: false,
                                        left: false,
                                        topRight: false,
                                        bottomRight: false,
                                        bottomLeft: false,
                                        topLeft: false,
                                    }}
                                    onResizeStart={(evt) => {
                                        evt.stopPropagation();
                                        this.setState({
                                            resizing: {
                                                initialWidth: this.getColumnWidth(
                                                    index,
                                                    tableInnerWidth
                                                ),
                                                initialWidthNext: this.getColumnWidth(
                                                    index + 1,
                                                    tableInnerWidth
                                                ),
                                            },
                                        });
                                    }}
                                    onResize={(evt, _direction, ref, d) => {
                                        evt.stopPropagation();
                                        if (this.state.resizing != null) {
                                            let dwidth = this.calculateTransformedDelta(
                                                d.width
                                            );
                                            if (
                                                this.state.resizing
                                                    .initialWidth +
                                                    dwidth <
                                                minColumnWidth
                                            ) {
                                                dwidth =
                                                    minColumnWidth -
                                                    this.state.resizing
                                                        .initialWidth;
                                            }
                                            if (
                                                this.state.resizing
                                                    .initialWidthNext -
                                                    dwidth <
                                                minColumnWidth
                                            ) {
                                                dwidth =
                                                    this.state.resizing
                                                        .initialWidthNext -
                                                    minColumnWidth;
                                            }
                                            let newWidth =
                                                this.state.resizing
                                                    .initialWidth + dwidth;
                                            let newScale =
                                                newWidth /
                                                (tableInnerWidth /
                                                    this.state.tableHeader!
                                                        .length);
                                            let newWidthNext =
                                                this.state.resizing
                                                    .initialWidthNext - dwidth;
                                            let newScaleNext =
                                                newWidthNext /
                                                (tableInnerWidth /
                                                    this.state.tableHeader!
                                                        .length);
                                            this.setState((state) => {
                                                return {
                                                    columnScales: {
                                                        ...state.columnScales,
                                                        [index]: newScale,
                                                        [index +
                                                        1]: newScaleNext,
                                                    },
                                                };
                                            });
                                            ref.style.width = `${newWidth}px`;
                                        }
                                    }}
                                    onResizeStop={(_e, _direction, _ref, d) => {
                                        if (this.state.resizing != null) {
                                            this.props.canvasTreeStore.updateBackendTableAction(
                                                this.props.tableId,
                                                {
                                                    columnScales: this.state
                                                        .columnScales,
                                                }
                                            );
                                            this.setState({ resizing: null });
                                        }
                                    }}
                                    size={{
                                        width: this.getColumnWidth(
                                            index,
                                            tableInnerWidth
                                        ),
                                        height: "auto",
                                    }}
                                >
                                    {this.tableInnerHeaderRenderer(
                                        item,
                                        index,
                                        this.state.tableHeader.length,
                                        this.getColumnWidth(
                                            index,
                                            tableInnerWidth
                                        )
                                    )}
                                </Resizable>
                            ))}
                        </div>
                        {this.state.tableContent.length === 0 ? (
                            <div
                                style={{
                                    backgroundColor:
                                        this.state.rowHovered === 0
                                            ? "#e2f6ff"
                                            : undefined,
                                    display: "flex",
                                    width: this.state.tableHeader.reduce(
                                        (acc, _, index) =>
                                            acc +
                                            this.getColumnWidth(
                                                index,
                                                tableInnerWidth
                                            ),
                                        0
                                    ),
                                    flex: 1,
                                    padding: 8,
                                    borderStyle: "solid",
                                    borderColor: "rgba(194,201,209,.5)",
                                    borderLeftWidth: 1,
                                    borderRightWidth: 1,
                                    borderTopWidth: 0,
                                    borderBottomWidth: 1,
                                    borderBottomLeftRadius: 8,
                                    borderBottomRightRadius: 8,
                                }}
                                onMouseEnter={() => {
                                    this.setState({ rowHovered: 0 });
                                }}
                                onMouseLeave={() => {
                                    this.setState({ rowHovered: null });
                                }}
                            >
                                No matching records found
                            </div>
                        ) : (
                            this.state.tableContent.map((row, rowIndex) => (
                                <div
                                    key={rowIndex}
                                    style={{ display: "flex", width: "100%" }}
                                >
                                    {row.map((item, index) =>
                                        this.tableInnerCellRenderer(
                                            item.toString(),
                                            index,
                                            row.length,
                                            rowIndex,
                                            this.state.tableContent.length,
                                            this.getColumnWidth(
                                                index,
                                                tableInnerWidth
                                            )
                                        )
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </Element>
            );
        } else {
            return null;
        }
    }
}

@observer
class BackendTables extends CanvasInteractionComponent<Props, State> {
    private drag: boolean = false;
    private hovered: boolean = false;
    constructor(props: Props) {
        super(props);
        this.state = {
            exportedTableOption: undefined,
            hamburgerMenuOpened: false,
            clearDataPopupTableId: undefined,
            popupStatus: undefined,
            popupMessage: undefined,
        };
    }
    render() {
        const { canvasViewMode } = this.props.canvasTreeStore;
        let backendTableUIs: JSX.Element[] = [];
        for (let tableId of this.props.canvasTreeStore.backendTablesState.keys()) {
            let rootDataTestId = `backendTable-${backendTableUIs.length + 1}`;
            let table = this.props.canvasTreeStore.backendTablesState.get(
                tableId
            )!;
            let colorOptions: ColorOptions = table.colorOptions ?? {
                borderShadow: false,
                fillColor: dataScienceElementsStyle.contentColor,
                borderColor: "",
            };
            let tableSize = {
                height:
                    table.nodeSize[canvasViewMode].height * this.props.scale,
                width: table.nodeSize[canvasViewMode].width * this.props.scale,
            };
            let connectedTable = this.props.canvasTreeStore.connectedBackendTablesState.get(
                tableId
            );
            let dataVariables: Variable[] = Variables(
                table.dataScopeOption?.value
            ).dataVariables;
            let refreshButtonStyles = {};
            if (this.props.live) {
                refreshButtonStyles = {
                    transition: "opacity .2s ease-out",
                    opacity: this.hovered
                        ? connectedTable == null
                            ? 0.3
                            : 1
                        : 0,
                    pointerEvents: this.hovered ? "auto" : "none",
                };
            }
            const hasWriteAccess =
                table.tableOption != null &&
                this.props.sharedPolicy === CanvasSharedPolicy.NotShared &&
                dataScopes.dataScopes.find(
                    (x) => x.id === table.tableOption!.data_table_idx
                )?.permissionType === Permission.ReadWrite;
            backendTableUIs.push(
                <DraggableWithSnapping
                    key={tableId}
                    disabled={this.props.live || !this.props.canWrite}
                    position={{
                        x:
                            table.nodePosition[canvasViewMode].x *
                            this.props.scale,
                        y:
                            table.nodePosition[canvasViewMode].y *
                            this.props.scale,
                    }}
                    onDrag={(_evt, _data) => {
                        this.drag = true;
                        let nearestPoints = this.props.onRebuildSnapLine(
                            {
                                x: _data.x,
                                y: _data.y,
                                width: tableSize.width,
                                height: tableSize.height,
                            },
                            {
                                type: typeName,
                                id: tableId,
                                groupId: table.groupId,
                            }
                        );
                        let newPosition = snapElementToPoints(
                            tableSize.width,
                            tableSize.height,
                            nearestPoints
                        );
                        if (newPosition.x != null || newPosition.y != null) {
                            // Snap to this position
                            return newPosition;
                        }
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            this.props.onDeleteSnapLine();
                            this.trackNewPerformance(elements.backendTable);
                            let x = Math.max(data.x / this.props.scale, 0);
                            let y = Math.max(data.y / this.props.scale, 0);
                            let deltaX =
                                x - table.nodePosition[canvasViewMode].x;
                            let deltaY =
                                y - table.nodePosition[canvasViewMode].y;

                            const nodePosition = {
                                ...table.nodePosition,
                                [canvasViewMode]: {
                                    x: Math.max(data.x / this.props.scale, 0),
                                    y: Math.max(data.y / this.props.scale, 0),
                                },
                            };

                            let changes: InnerCanvasChanges = {};
                            this.props.canvasTreeStore.updateBackendTableAction(
                                tableId,
                                { nodePosition },
                                changes
                            );
                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                x: x,
                                y: y,
                                width: table.nodeSize[canvasViewMode].width,
                                height: table.nodeSize[canvasViewMode].height,
                            });
                            this.props.onMoveGroupSelection(
                                deltaX,
                                deltaY,
                                {
                                    id: tableId,
                                    type: typeName,
                                    groupId: table.groupId,
                                },
                                false,
                                changes
                            );
                            this.props.canvasTreeStore.saveChangesAction(
                                changes,
                                true,
                                true,
                                false,
                                this.props.canvasTreeStore.backgroundsState.toJSON(),
                                BackgroundMode.Update,
                                false
                            );
                            this.drag = true;
                        }
                    }}
                >
                    <div
                        onContextMenu={(evt) => {
                            this.props.onContextMenu(
                                evt,
                                {
                                    id: tableId,
                                    type: typeName,
                                },
                                true
                            );
                        }}
                        style={{
                            top: 0,
                            left: 0,
                            position: "absolute",
                            zIndex: table.zIndex ?? 50,
                        }}
                        onMouseEnter={() => {
                            this.hovered = true;
                        }}
                        onMouseLeave={() => {
                            this.hovered = false;
                        }}
                    >
                        <Resizable
                            className="selectable-by-pointer"
                            ref={(ref) => {
                                let innerRef = ref?.resizable;
                                if (innerRef != null) {
                                    innerRef.setAttribute("type", typeName);
                                    if (table.groupId != null)
                                        innerRef.setAttribute(
                                            "groupId",
                                            table.groupId
                                        );
                                    else {
                                        innerRef.removeAttribute("groupId");
                                    }
                                    innerRef.setAttribute(
                                        "id",
                                        String(tableId)
                                    );
                                    innerRef.setAttribute(
                                        "data-test-id",
                                        rootDataTestId
                                    );
                                }
                            }}
                            enable={
                                this.props.live || !this.props.canWrite
                                    ? {
                                          top: false,
                                          right: false,
                                          bottom: false,
                                          left: false,
                                          topRight: false,
                                          bottomRight: false,
                                          bottomLeft: false,
                                          topLeft: false,
                                      }
                                    : {
                                          top: true,
                                          right: true,
                                          bottom: true,
                                          left: true,
                                          topRight: true,
                                          bottomRight: true,
                                          bottomLeft: true,
                                          topLeft: true,
                                      }
                            }
                            onResizeStart={(evt) => {
                                evt.stopPropagation();
                            }}
                            onResize={(_e, _direction, _ref, d) => {
                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;
                                changeElementWhenResize2(
                                    {
                                        position: table.nodePosition,
                                        size: table.nodeSize,
                                    },
                                    this.props.scale,
                                    _direction,
                                    d,
                                    _ref,
                                    canvasViewMode
                                );
                            }}
                            onResizeStop={(_e, _direction, _ref, d) => {
                                this.trackNewPerformance(elements.backendTable);
                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;

                                let newSize = getNewSizeAfterResize2(
                                    {
                                        position: table.nodePosition,
                                        size: table.nodeSize,
                                    },
                                    this.props.scale,
                                    _direction,
                                    d,
                                    canvasViewMode
                                );
                                this.props.canvasTreeStore.updateBackendTableAction(
                                    tableId,
                                    { nodeSize: newSize.nodeSize }
                                );
                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        x:
                                            newSize.nodePosition[canvasViewMode]
                                                .x,
                                        y:
                                            newSize.nodePosition[canvasViewMode]
                                                .y,
                                        ...newSize.nodeSize[canvasViewMode],
                                    }
                                );
                                this.props.onResize();
                            }}
                            size={tableSize}
                        >
                            <div
                                className="dashboard-rect-canvas"
                                style={{
                                    boxShadow: colorOptions.borderShadow
                                        ? "0 6px 13px 0 rgba(21, 33, 56, 0.53)"
                                        : "none",
                                    backgroundColor: colorOptions.fillColor,
                                    border: colorOptions.borderColor
                                        ? `1px solid ${colorOptions.borderColor}`
                                        : "none",
                                    overflow: "hidden",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <div
                                    className="flex-simple-column"
                                    style={{
                                        transformOrigin: "left top",
                                        transform: `scale(${
                                            0.5 * this.props.scale
                                        })`,
                                        width:
                                            (tableSize.width * 2) /
                                            this.props.scale,
                                        height:
                                            (tableSize.height * 2) /
                                            this.props.scale,
                                    }}
                                >
                                    <div
                                        className="my-row"
                                        style={{
                                            width: "100%",
                                            justifyContent: "flex-end",
                                            marginTop: "10px",
                                        }}
                                    >
                                        {!this.props.live &&
                                            this.props.canWrite && (
                                                <button
                                                    disabled={
                                                        connectedTable == null
                                                    }
                                                    type="button"
                                                    className={cx(
                                                        "btn btn-sm btn-primary",
                                                        styles.clearDataButton
                                                    )}
                                                    style={{
                                                        marginRight: 10,
                                                        backgroundColor:
                                                            "var(--danger-color)",
                                                    }}
                                                    onClick={(evt) => {
                                                        evt.stopPropagation();
                                                        if (
                                                            this.props
                                                                .sharedPolicy ===
                                                            CanvasSharedPolicy.SharedSlideUnAuth
                                                        ) {
                                                            goToInternalLink(
                                                                "/"
                                                            );
                                                            return;
                                                        }
                                                        this.setState({
                                                            clearDataPopupTableId: tableId,
                                                        });
                                                    }}
                                                >
                                                    Clear data
                                                </button>
                                            )}
                                        {!this.props.live && (
                                            <>
                                                <CanvasPreventPropagationButton>
                                                    <div
                                                        title={"Export"}
                                                        style={{
                                                            marginRight: "12px",
                                                            opacity:
                                                                table.tableOption ==
                                                                null
                                                                    ? 0.3
                                                                    : 1,
                                                        }}
                                                        onClick={(evt) => {
                                                            evt.stopPropagation();
                                                            if (
                                                                this.props
                                                                    .sharedPolicy ===
                                                                CanvasSharedPolicy.SharedSlideUnAuth
                                                            ) {
                                                                goToInternalLink(
                                                                    "/"
                                                                );
                                                                return;
                                                            } else {
                                                                if (
                                                                    table.tableOption !=
                                                                    null
                                                                )
                                                                    this.setState(
                                                                        {
                                                                            exportedTableOption:
                                                                                table.tableOption,
                                                                        }
                                                                    );
                                                            }
                                                        }}
                                                    >
                                                        <img
                                                            alt=""
                                                            src="/dist/img/insights/insights_share.png"
                                                            style={{
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                        />
                                                    </div>
                                                </CanvasPreventPropagationButton>
                                            </>
                                        )}
                                        <CanvasPreventPropagationButton>
                                            <div
                                                style={{
                                                    marginRight: "12px",
                                                    opacity:
                                                        connectedTable == null
                                                            ? 0.3
                                                            : 1,
                                                    ...refreshButtonStyles,
                                                }}
                                                title={"Update table"}
                                                onClick={(evt) => {
                                                    evt.stopPropagation();
                                                    if (
                                                        this.props
                                                            .sharedPolicy ===
                                                        CanvasSharedPolicy.SharedSlideUnAuth
                                                    ) {
                                                        goToInternalLink("/");
                                                        return;
                                                    }
                                                    this.props.canvasTreeStore.loadBackendTableAsyncAction(
                                                        tableId
                                                    );
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    src="/dist/img/insights/insights_reload.png"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </div>
                                        </CanvasPreventPropagationButton>

                                        {!this.props.live &&
                                            this.props.canWrite && (
                                                <CanvasPreventPropagationButton>
                                                    <div
                                                        title={"Delete"}
                                                        style={{
                                                            marginRight: "12px",
                                                        }}
                                                        onClick={(_evt) => {
                                                            this.trackNewPerformance(
                                                                elements.backendTable
                                                            );
                                                            this.props.showDeletePopup(
                                                                () => {
                                                                    this.props.onClearEditing();
                                                                    this.props.canvasTreeStore.deleteBackendTableAction(
                                                                        tableId
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <img
                                                            alt=""
                                                            src="/dist/img/insights/insights_remove.png"
                                                            style={{
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                        />
                                                    </div>
                                                </CanvasPreventPropagationButton>
                                            )}
                                    </div>
                                    {!this.props.live && (
                                        <div
                                            style={{
                                                marginLeft: "45px",
                                            }}
                                        >
                                            <BackendTableSelector
                                                currentModuleId={
                                                    this.props.currentModuleId
                                                }
                                                rowCount={table.rowCount}
                                                dataScopeOption={
                                                    table.dataScopeOption
                                                }
                                                conditions={table.conditions}
                                                onChangeConditions={(
                                                    conditions
                                                ) => {
                                                    this.trackNewPerformance(
                                                        elements.backendTable
                                                    );
                                                    this.props.canvasTreeStore.updateBackendTableAction(
                                                        tableId,
                                                        {
                                                            conditions: conditions,
                                                        }
                                                    );
                                                    this.props.canvasTreeStore.loadBackendTableAsyncAction(
                                                        tableId
                                                    );
                                                }}
                                                onChangeRowCount={(
                                                    rowCount: number
                                                ) => {
                                                    this.trackNewPerformance(
                                                        elements.backendTable
                                                    );
                                                    this.props.canvasTreeStore.updateBackendTableAction(
                                                        tableId,
                                                        {
                                                            rowCount: rowCount,
                                                        }
                                                    );
                                                    this.props.canvasTreeStore.loadBackendTableAsyncAction(
                                                        tableId
                                                    );
                                                }}
                                                onChangeDataScope={(
                                                    dataScopeOption
                                                ) => {
                                                    this.trackNewPerformance(
                                                        elements.backendTable
                                                    );

                                                    this.props.canvasTreeStore.updateBackendTableAction(
                                                        tableId,
                                                        {
                                                            dataScopeOption: dataScopeOption,
                                                            tableOption: Tables(
                                                                dataScopeOption.value,
                                                                this.props
                                                                    .currentModuleId ??
                                                                    remoteModuleId
                                                            ).tableToOption(),
                                                            conditions: null,
                                                            variables: null,
                                                            sort: null,
                                                            columnScales: null,
                                                        }
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}
                                    {table.tableOption && (
                                        <div
                                            className="element"
                                            style={{
                                                paddingTop: "10px",
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                paddingBottom: "20px",
                                                overflow: "auto",
                                                justifyContent: "flex-start",
                                                display: "flex",
                                                height: "100%",
                                                width: "100%",
                                            }}
                                        >
                                            <TableInner
                                                connectedTableFormats={
                                                    dataVariables
                                                }
                                                connectedFullTable={
                                                    connectedTable
                                                }
                                                hasWriteAccess={hasWriteAccess}
                                                live={this.props.live}
                                                tableId={tableId}
                                                tableDescription={table}
                                                currentModuleId={
                                                    this.props.currentModuleId
                                                }
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                scale={this.props.scale}
                                                canWrite={this.props.canWrite}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Resizable>
                    </div>
                </DraggableWithSnapping>
            );
        }
        return (
            <>
                {backendTableUIs}
                {this.state.exportedTableOption != null && (
                    <ExportCsvPopup
                        inputTable={this.state.exportedTableOption}
                        onClose={() => {
                            this.setState({
                                exportedTableOption: undefined,
                            });
                        }}
                        currentModuleId={this.props.currentModuleId}
                    />
                )}
                {this.state.clearDataPopupTableId != null && (
                    <MessagePopup
                        title={"Clear data"}
                        danger
                        message={"Are you sure you want to clear data?"}
                        acceptButtonTitle={"Clear"}
                        onAccept={() => {
                            let table = this.props.canvasTreeStore.backendTablesState.get(
                                this.state.clearDataPopupTableId!
                            )!;
                            if (table.tableOption != null) {
                                deleteTable(
                                    table.tableOption,
                                    this.props.currentModuleId ?? remoteModuleId
                                )
                                    .then(() => {
                                        this.props.canvasTreeStore.loadBackendTableAsyncAction(
                                            this.state.clearDataPopupTableId!
                                        );
                                        this.setState({
                                            clearDataPopupTableId: undefined,
                                        });
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            popupStatus: PopupStatus.Error,
                                            popupMessage: String(error),
                                            clearDataPopupTableId: undefined,
                                        });
                                    });
                            }
                        }}
                        onReject={() => {
                            this.setState({
                                clearDataPopupTableId: undefined,
                            });
                        }}
                        zIndex={100000000}
                    />
                )}
                {this.state.popupStatus != null && (
                    <Portal rootNode={document.body}>
                        <StatusPopup
                            status={this.state.popupStatus}
                            message={this.state.popupMessage ?? ""}
                            onClose={() => {
                                this.setState({
                                    popupStatus: undefined,
                                    popupMessage: undefined,
                                });
                            }}
                        />
                    </Portal>
                )}
            </>
        );
    }
}

export default BackendTables;
