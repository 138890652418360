import { observable, makeObservable, runInAction } from "mobx";
import { getNewCanvasComments } from "./CanvasCommentsApi";
import { NewComment } from "./CanvasComments";

class NewCommentsStoreInner {
	@observable public newCommentsState: NewComment[] = [];
	private moduleId: number | null;
	constructor(moduleId: number) {
		makeObservable(this);
		this.moduleId = moduleId;
		this.update();
	}
	update() {
		this.getNewComments();
	}
	public hasPin(pinId: number) {
		return (
			this.newCommentsState.findIndex((item) => item.id === pinId) >= 0
		);
	}
	public markPinAsRead(pinId: number) {
		let newComments = Array.from(this.newCommentsState);
		newComments = newComments.filter((item) => item.id !== pinId);
		runInAction(() => {
			this.newCommentsState = newComments;
		});
	}
	public async getNewComments() {
		if (this.moduleId == null) return;
		try {
			let newComments = await getNewCanvasComments(this.moduleId);
			runInAction(() => {
				this.newCommentsState = newComments;
			});
		} catch (error) {
			console.log(error);
		}
	}
}

let newCommentsStoreMap: {
	[key in number | string]: NewCommentsStoreInner;
} = {};

export default function NewCommentsStore(
	moduleId: number
): NewCommentsStoreInner {
	if (!(moduleId in newCommentsStoreMap)) {
		newCommentsStoreMap[moduleId] = new NewCommentsStoreInner(moduleId);
	}
	return newCommentsStoreMap[moduleId];
}