import React, { Component, CSSProperties } from "react";
import { Button } from "react-bootstrap";
import Popup from "reactjs-popup";
import { observer } from "mobx-react";
import Alert from "common/Alert";
import Tables, { TableOption } from "common/Tables";
import { addVariable } from "common/DataApi";
import VariableCreator, { NewVariable } from "common/VariableCreator";
import processTab from "common/utilities/processTab";
import Variables from "common/Variables";
import { Element } from "react-scroll";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import {
    variableCreatorSample,
    variableCreatorRowLevelSample,
} from "common/NotebookSample";
import ReactTooltip from "react-tooltip";
import Draggable from "react-draggable";
import OutsideAlerter from "common/OutsideAlerter";
import { Type, Panel } from "common/InputData";
import remoteModuleId from "common/remoteModuleId";

interface Props {
    inputTable: TableOption;
    onClose: () => void;
    onUpdate: (dataScopeId: string | number) => void;
    overlayStyle?: CSSProperties;
    currentModuleId?: number;
    rowLevel?: boolean;
}

enum UpdateStatus {
    success = 1,
    loading = 2,
    error = 3,
    notUploaded = 4,
}

interface State {
    left: number;
    top: number;
    status: UpdateStatus;
    errorMessage: string;
    genericVariables: NewVariable[];
    creatorCode: string;
    tableHeader: string[] | null;
    tablePreview: any[] | null;
}

@observer
class AddVariablePopup extends Component<Props, State> {
    hintRef: HTMLImageElement | null;
    drag: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            left: 0,
            top: 0,
            status: UpdateStatus.notUploaded,
            errorMessage: "",
            genericVariables: [
                {
                    name: null,
                    unit: null,
                    type: Type.Float,
                    level: null,
                    panel: Panel.Regular,
                    format: null,
                },
            ],
            creatorCode: props.rowLevel
                ? variableCreatorRowLevelSample
                : variableCreatorSample,
            tableHeader: null,
            tablePreview: null,
        };
        this.hintRef = null;
    }

    private addVariable(preview = false) {
        this.setState({
            status: UpdateStatus.loading,
        });
        addVariable(
            this.props.inputTable,
            this.state.creatorCode,
            this.state.genericVariables,
            (previewData) => {
                if (!preview) {
                    this.setState({
                        status: UpdateStatus.success,
                    });
                    Tables(this.props.inputTable.data_table_idx!).update(
                        this.props.currentModuleId ?? remoteModuleId
                    );
                    Variables(this.props.inputTable.data_table_idx!).update(
                        this.props.currentModuleId ?? remoteModuleId
                    );
                    this.props.onUpdate(this.props.inputTable.data_table_idx!);
                } else {
                    this.setState({
                        status: UpdateStatus.success,
                        tableHeader: previewData!.tableHeader,
                        tablePreview: previewData!.tablePreview,
                    });
                }
            },
            (errorMessage: string) => {
                if (!preview) {
                    this.setState({
                        status: UpdateStatus.error,
                        errorMessage: errorMessage,
                    });
                } else
                    this.setState({
                        status: UpdateStatus.error,
                        errorMessage: errorMessage,
                    });
            },
            preview,
            this.props.rowLevel
        );
    }

    private buildAlert(): JSX.Element {
        let alert: JSX.Element = <div style={{ minHeight: 38 }} />;
        let alertStyle = {
            marginBottom: 0,
            minHeight: 38,
            paddingTop: 10,
            paddingBottom: 10,
        };
        if (this.state.status === UpdateStatus.success) {
            alert = (
                <Alert
                    style={alertStyle}
                    text="Success."
                    className="alert alert-success alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.error) {
            alert = (
                <Alert
                    style={alertStyle}
                    text={"Error: ".concat(this.state.errorMessage)}
                    className="alert alert-danger alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.loading) {
            alert = (
                <Alert
                    style={alertStyle}
                    text={"Saving"}
                    className="alert alert-warning alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        }
        return alert;
    }

    private buildInnerView(height: number): JSX.Element {
        let tablePreview: JSX.Element | null = null;
        if (
            this.state.tableHeader !== null &&
            this.state.tablePreview !== null
        ) {
            let tableContent = this.state.tablePreview.map((row) =>
                row.map(
                    (element: any) =>
                        element || (typeof element === "number" ? element : "-")
                )
            );

            tablePreview = (
                <Element
                    name="scrollable"
                    className="element"
                    style={{
                        overflowX: "scroll",
                        backgroundColor: "transparent",
                        position: "relative",
                        overflowY: "scroll",
                    }}
                >
                    <AdminTableWithFullFeatures
                        className="primary-page-table"
                        paging={false}
                        tableName="Data"
                        tableHeader={this.state.tableHeader}
                        tableContent={tableContent}
                    />
                </Element>
            );
        } else {
            tablePreview = null;
        }
        return (
            <div
                className="flex-simple-column"
                style={{
                    overflow: "visible",
                    paddingLeft: "38px",
                    paddingRight: "20px",
                    paddingTop: "20px",
                    height: height,
                }}
            >
                <div
                    className="my-row"
                    style={{
                        marginBottom: "10px",
                    }}
                >
                    <div className="popup-regular-text">
                        {this.props.rowLevel
                            ? "Please use Pandas syntax python code to create new variables. The row variable is called 'row'."
                            : "Please use Pandas syntax python code to create new variables. Your dataset is called 'data'."}
                    </div>
                    <img
                        onClick={() => {
                            ReactTooltip.show(this.hintRef!);
                        }}
                        ref={(ref) => (this.hintRef = ref)}
                        data-tip="Here is a list of functions that we currently support:
                        range; pandas functions: pd.isnull, pd.MultiIndex.droplevel;
                        pandas DataFrame and Series methods: .mean, .rank.,
                        .max, .min, .sum, .std, .count, .groupby, .transform, .rolling;
                        pandas Series string methods: .str.contains,
                        .str.endswith, .str.extract, .str.extractall,
                        .str.match, .str.slice, .str.slice_replace, .str.startswith"
                        src="/dist/img/warning.png"
                        alt=""
                        style={{
                            marginLeft: 5,
                        }}
                    />
                </div>
                <VariableCreator
                    dataScopeId={this.props.inputTable.data_table_idx!}
                    value={this.state.genericVariables}
                    onChange={(newVariables) =>
                        this.setState({
                            genericVariables: newVariables,
                        })
                    }
                />
                <div
                    style={{
                        padding: "10px",
                    }}
                >
                    <textarea
                        className="like-select element"
                        style={{
                            minHeight: "250px",
                            width: "100%",
                            fontFamily: "monospace",
                            resize: "vertical",
                            whiteSpace: "pre",
                            marginBottom: "10px",
                        }}
                        spellCheck={false}
                        placeholder=""
                        onChange={(e) => {
                            let value = e.target.value;
                            this.setState({ creatorCode: value });
                        }}
                        ref="creatorCodeTextArea"
                        onKeyDown={(e) => {
                            e.stopPropagation();
                            processTab(
                                e,
                                (
                                    newSelectionStart,
                                    newSelectionEnd,
                                    newValue
                                ) => {
                                    this.setState(
                                        {
                                            creatorCode: newValue,
                                        },
                                        () => {
                                            (this.refs
                                                .creatorCodeTextArea as HTMLTextAreaElement).selectionStart = newSelectionStart;
                                            (this.refs
                                                .creatorCodeTextArea as HTMLTextAreaElement).selectionEnd = newSelectionEnd;
                                        }
                                    );
                                }
                            );
                        }}
                        value={this.state.creatorCode}
                    />
                </div>
                {tablePreview}

                <div style={{ flexGrow: 1 }} />
                <div
                    style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        marginBottom: "15px",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            width: 150,
                            height: "38px",
                            paddingTop: 0,
                            marginRight: "10px",
                            paddingBottom: 0,
                        }}
                        onClick={() => {
                            this.addVariable(true);
                        }}
                    >
                        PREVIEW
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            width: 150,
                            height: "38px",
                            paddingTop: 0,
                            marginRight: "10px",
                            paddingBottom: 0,
                        }}
                        onClick={() => {
                            this.addVariable(false);
                        }}
                    >
                        SAVE
                    </Button>
                    {this.buildAlert()}
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        const height: number = 600;
        const width: number = 950;
        return (
            <Popup
                arrow={true}
                contentStyle={{
                    width: width,
                    minHeight: height,
                    border: "none",
                    backgroundColor: "transparent",
                }}
                overlayStyle={this.props.overlayStyle}
                open={true}
                onClose={() => {
                    this.props.onClose();
                }}
                closeOnDocumentClick
            >
                <Draggable
                    cancel="input, textarea"
                    position={{
                        x: this.state.left,
                        y: this.state.top,
                    }}
                    onDrag={() => {
                        this.drag = true;
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            this.drag = false;
                            this.setState({
                                left: data.x,
                                top: data.y,
                            });
                        }
                    }}
                >
                    <div
                        className="dashboard-rect element cancel-drag"
                        style={{
                            overflowX: "visible",
                            overflowY: "auto",
                            boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                            borderRadius: 0,
                            alignItems: "center",
                            cursor: "pointer",
                            minHeight: height,
                            maxHeight: "100vh",
                            width: width,
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt: any) => {
                            evt.stopPropagation();
                        }}
                    >
                        <OutsideAlerter
                            onReject={() => {
                                this.props.onClose();
                            }}
                        >
                            {this.buildInnerView(height)}
                        </OutsideAlerter>
                    </div>
                </Draggable>
                <ReactTooltip />
            </Popup>
        );
    }
}

export default AddVariablePopup;
