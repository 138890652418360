import React from "react";
import WordCloud from "common/graphics/WordCloud";

function MainComponent(props) {
	let finding = props.finding;
	return (
		<div style={{ width: "100%", height: "100%" }}>
			<WordCloud
				data={finding.content.data}
			/>
		</div>
	);
}

export { MainComponent };
