import React, { Component, CSSProperties } from "react";
import Select, { createFilter } from "react-select";

import StringOption from "common/StringOption";
import SearchComponent, { SearchComponentOption } from "common/SearchComponent";
import customSelectStyles, { leftAlignAndScrollBarCSS, Styles } from "common/SelectStyles";
// import { multiValueRemoveCSS } from "react-select/dist/declarations/src/components/MultiValue";

export const numericOperators: ReadonlyArray<StringOption> = [
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
    {
        label: "<",
        value: "<",
    },
    {
        label: ">",
        value: ">",
    },
    {
        label: "<=",
        value: "<=",
    },
    {
        label: ">=",
        value: ">=",
    },
];

export const stringOperators: ReadonlyArray<StringOption> = [
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
];

interface Props {
    currentModuleId: number | string | undefined;
    dataScopeId: number | string;
    operator: StringOption | null | undefined;
    value: SearchComponentOption | null;
    equalsOnly?: boolean;
    numeric?: boolean;
    searchKey: string;
    searchIndex: number;
    style?: CSSProperties;
    selectStyles?: Styles;
    clearable?: boolean;
    smallText?: boolean;
    small?: boolean;
    transparent?: boolean;
    nospace?: boolean;

    onOperatorChange: (newOperator: StringOption) => void;
    onValueChange: (newValue: SearchComponentOption) => void;
}

class ValueWithOperator extends Component<Props> {
    render() {
        let operatorOptions: ReadonlyArray<StringOption> = this.props.numeric
            ? numericOperators
            : stringOperators;

        let additionalStyle: CSSProperties = {};
        if (this.props.transparent) {
            additionalStyle = {
                backgroundColor: "transparent",
                border: "none",
            };
        }
        if (this.props.small) {
            additionalStyle = {
                ...additionalStyle,
                padding: 0,
            };
        }

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "95%",
                    alignItems: "center",
                    ...this.props.style,
                }}
            >
                <div
                    className={
                        this.props.smallText || this.props.small
                            ? "regular-text"
                            : "exploration-big-title-span"
                    }
                    style={{ paddingLeft: 5, fontSize: 12 }}
                >
                    {this.props.searchKey}
                </div>
                <div style={{ display: 'flex', width: '60%' }}>
                    {this.props.equalsOnly ? (
                        <input
                            className="like-select"
                            disabled={true}
                            style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: this.props.small ? undefined : "25px",
                                width: this.props.small ? "70px" : "100px",
                                textAlign: "left",
                                marginLeft: 5,
                                ...additionalStyle,
                            }}
                            value="="
                        />
                    ) : (
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={""}
                            styles={{
                                ...customSelectStyles,
                                ...leftAlignAndScrollBarCSS,
                                container: (base, state) => ({
                                    ...base,
                                    height: this.props.small ? undefined : "38px",
                                    width: this.props.small ? "70px" : "100px",
                                    marginLeft: 10,
                                    marginRight: 3,
                                    ...additionalStyle,
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    minHeight: 29,
                                    height: 29,
                                    borderRadius: 5,
                                    width: 70
                                }),
                                valueContainer: (base => ({
                                    ...base,
                                    padding: 0
                                }))
                            }}
                            options={operatorOptions}
                            value={this.props.operator}
                            onChange={(newOperator) => {
                                this.props.onOperatorChange(
                                    newOperator as StringOption
                                );
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    )}
                    <div style={{ marginLeft: this.props.nospace ? 0 : 5 }}>
                        {this.props.operator?.value !== "=" &&
                            this.props.operator?.value !== "!=" ? (
                            <input
                                className="like-select"
                                style={{
                                    ...additionalStyle,
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    height: this.props.small ? undefined : "29px",
                                    minHeight: '29px',
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                placeholder=""
                                onChange={(e) => {
                                    const value: string = e.target.value;
                                    if (!/^[0-9]*\.?[0-9]*$/.test(value)) {
                                        e.preventDefault();
                                    } else {
                                        this.props.onValueChange({
                                            label: value,
                                            value: Number(value),
                                        });
                                    }
                                }}
                                value={this.props.value?.label ?? ""}
                            />
                        ) : (
                            <SearchComponent
                                filterMissing
                                dataScopeId={this.props.dataScopeId}
                                currentModuleId={this.props.currentModuleId}
                                initialValue={this.props.value}
                                searchIndex={this.props.searchIndex}
                                onOptionSelected={(newValue) => {
                                    this.props.onValueChange(
                                        newValue as SearchComponentOption
                                    );
                                }}
                                clearable={this.props.clearable}
                                customStyles={{
                                    // ...customSelectStyles,
                                    // ...leftAlignAndScrollBarCSS,
                                    // container: (base, state) => ({
                                    //     ...base,
                                    //     ...additionalStyle,
                                    //     width: '152px',
                                    // }),
                                    // valueContainer: (base, state) => ({
                                    //     ...base,
                                    //     overflow: 'hidden',
                                    //     whiteSpace: 'nowrap',
                                    //     textOverflow: 'ellipsis',
                                    //     padding:0,
                                    // }),
                                    // control: (provided) => ({
                                    //     ...provided,
                                    //     minHeight: 29,
                                    //     height: 29,
                                    //     borderRadius: 5,
                                    // }),
                                    // dropdownIndicator: (base => ({
                                    //     ...base,
                                    //     padding: 0
                                    // })),
                                    ...customSelectStyles,
                                    ...leftAlignAndScrollBarCSS,

                                    container: (base, state) => ({
                                        ...base,
                                        ...additionalStyle,
                                        // border:'1px solid red',
                                        width: '152px',
                                    }),
                                    valueContainer: (base, state) => ({
                                        ...base,
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        padding: 0,
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: 29,
                                        height: 29,
                                        borderRadius: 5,
                                    }),
                                    indicatorsContainer: (base => ({
                                        ...base,
                                        padding: 0,
                                        height: '29px',
                                        minHeight: '29px',
                                    })),
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ValueWithOperator;
