import "common/styles/App.css";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reaction } from "mobx";
import { Api } from "./Api";

import axios from "common/ServerConnection";
import LeversChartsComponent from "common/graphics/LeversChartsComponent";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import { mainStyle } from "common/MainStyle";
import OutcomesBlockV3 from "common/graphics/OutcomesBlockV3";
import Variables from "common/Variables";
import BaseJourney from "../../common/BaseJourney";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";
import BarSizeSlider from "../../common/BarSizeSlider";

const Step = Object.freeze({
	initial: 0,
	selectDataScope: 1,
	selectTable: 2,
	selectTarget: 3,
	selectAdditionalParameter: 4,
	selectAdditionalValue: 5,
	final: 6,
});

function StepFinal(props) {
	let {
		finding,
		onBack,
		onNewFinding,
		onSaveFinding,
		onShowRecipesPopup,
	} = props;
	return (
		<>
			<div
				className="my-row"
				style={{
					alignItems: "center",

					width: "100%",
				}}
			>
				<div
					onClick={() => {
						onBack();
					}}
					style={{
						width: 80,
						height: "100%",
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
						justifyContent: "center",
					}}
				>
					<img
						alt=""
						src="/dist/img/data-exploration/chevron_back.png"
					/>
				</div>
				{finding && (
					<div
						className="flex-simple-column"
						style={{
							width: "calc(100% - 180px)",
							height: "350px",
						}}
					>
						<div style={{ width: "100%", height: "100%" }}>
							<ErrorBoundary>
								<BarSizeSlider
									onNewFinding={onNewFinding}
									finding={finding}
								/>
								<LeversChartsComponent
									barSize={finding.config.barSize}
									clust={finding.content.clust}
									leftBarKey="current"
									rightBarKey="projected"
								/>
							</ErrorBoundary>
						</div>
						{finding.content.outcome ? (
							<OutcomesBlockV3
								item={finding.content.outcome}
								leftTitle="Current"
								rightTitle="Projected"
								leftKey="current"
								rightKey="projected"
							/>
						) : null}
						<FinalStepBottomBar
							onShowRecipesPopup={onShowRecipesPopup}
							onSaveFinding={onSaveFinding}
							topMargin={-50}
						/>
					</div>
				)}
			</div>
		</>
	);
}

class MainComponent extends BaseJourney {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			step: 0,
			finding: undefined,
			additionalParameter: undefined,
			leftAdditionalValue: undefined,
			additionalParameters: [],
			targetVariable: undefined,
			targetVariables: [],
			aggregateTableList: [],
			selectedTable: undefined,
			animationDirection: "top",
			step2VariablesMap: {
				[Step.selectTarget]: ["targetVariable", "targetVariables"],
				[Step.selectAdditionalParameter]: [
					"additionalParameter",
					"additionalParameters",
				],
			},
		};
		this.getOptimizedTablesList = this.getOptimizedTablesList.bind(this);
		this.getData = this.getData.bind(this);
		this.buildContent = this.buildContent.bind(this);
		this.buildLeftBar = this.buildLeftBar.bind(this);
		this.selectVariable = this.selectVariable.bind(this);
		this.getSubtitle = this.getSubtitle.bind(this);
		this.getFooterTitle = this.getFooterTitle.bind(this);
		this.showFinalStep = this.showFinalStep.bind(this);
		this.stepDown = this.stepDown.bind(this);
		this.stepUp = this.stepUp.bind(this);
		this.initializeVariables = this.initializeVariables.bind(this);
		this.switchVariables = this.switchVariables.bind(this);
	}
	getOptimizedTablesList() {
		axios
			.post(
				"/api/get_optimized_outcomes_and_tables",
				{
					data_table_idx: this.dataScopeValue(),
				},
				null
			)
			.then((response) => {
				if (response.data.success === false) {
					console.log(response.data.error_msg);
				} else {
					let table2OutcomeMap = {};
					let aggregateTableList = response.data.optimize_list;
					aggregateTableList.forEach((tableWithOutcomes) => {
						table2OutcomeMap[
							tableWithOutcomes.table_id.concat(
								tableWithOutcomes.condition_id
							)
						] = tableWithOutcomes.outcomes_and_panels;
					});

					this.setState({
						aggregateTableList: aggregateTableList,
						table2OutcomeMap: table2OutcomeMap,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	getConfig() {
		return {
			version: configVersion,
			variableIndices: Variables(
				this.dataScopeValue()
			).numericVariables.map((item) => item.index),
			variables: Variables(this.dataScopeValue()).numericVariables.map(
				(item) => item.name
			),
			dataScope: this.state.dataScope,
			targetVariable: this.state.targetVariable,
			targetVariableIndex: Variables(
				this.dataScopeValue()
			).variableToIndex(this.state.targetVariable),
			selectedTable: this.state.selectedTable,
			additionalParameter: this.state.additionalParameter,
			additionalParameterIndex: Variables(
				this.dataScopeValue()
			).variableToIndex(this.state.additionalParameter),
			leftAdditionalValue: this.state.leftAdditionalValue,
			journeyName: this.props.journeyName,
		};
	}

	getData() {
		let config = this.getConfig();
		this.setState({ loading: true });
		Api.getData(config)
			.then((item) => {
				this.setState({
					finding: item,
					loading: false,
				});
			})
			.catch((error) => {
				console.log(error);
				this.setState({ loading: false });
			});
	}
	buildContent() {
		switch (this.state.step) {
			case Step.initial:
				return this.buildInitial(
					"How do you want to examine the optimal lever levels?"
				);
			case Step.selectDataScope:
				return this.buildDataScopesSelector();
			case Step.selectTable:
				return this.buildTableSelector(this.state.aggregateTableList);
			case Step.selectTarget:
				return this.buildVariablesSelector(
					"I want to compare our current effort allocations to the optimal allocation levels for",
					"targetVariable",
					"targetVariables"
				);

			case Step.selectAdditionalParameter:
				return this.buildVariablesSelector(
					"In",
					"additionalParameter",
					"additionalParameters"
				);
			case Step.selectAdditionalValue:
				return this.buildAdditionalValueSelector("Select");
			case Step.final:
				return (
					<StepFinal
						loading={this.state.loading}
						onNewFinding={this.onNewFinding}
						onShowRecipesPopup={this.showRecipesPopup}
						onSaveFinding={this.saveFinding}
						finding={this.state.finding}
						onBack={this.back}
					/>
				);

			default:
				return <div />;
		}
	}

	buildLeftBar() {
		return super.buildLeftBar(this.state.step < Step.final - 1);
	}
	initializeVariables() {
		this.getOptimizedTablesList();
	}
	switchVariables() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
		this.initializeVariables();
		this.initializeVariablesReaction = reaction(
			() => Variables(this.dataScopeValue()).dataVariables,
			() => {
				this.initializeVariables();
			}
		);
	}
	componentDidMount() {
		this.getLastJourneyConfig();
	}
	componentWillUnmount() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
	}
	selectVariable(variableName, variablesName, index) {
		if (index >= this.state[variablesName].length) return;
		let stateDiff = { [variableName]: this.state[variablesName][index] };
		if (this.state.step === Step.selectAdditionalParameter) {
			stateDiff.leftAdditionalValue = undefined;
		}

		this.setState(stateDiff, () => {
			setTimeout(() => {
				this.stepDown();
			}, 100);
		});
	}

	getSubtitle() {
		if (this.state.step === Step.initial) return "";
		if (
			this.state.step === Step.selectDataScope ||
			this.state.step === Step.selectTable
		)
			return "How do you want to examine the optimal lever levels?";

		if (
			this.state.step > Step.selectTable &&
			this.state.step < Step.final
		) {
			let subtitle = "";
			if (this.state.step > Step.selectTable) {
				subtitle += `For the data in ${
					this.state.dataScope ? this.state.dataScope.label : ""
				} ${this.state.selectedTable.label}`;
				subtitle +=
					" I want to compare our current effort allocations to the optimal allocation levels for";
			}
			if (this.state.step > Step.selectTarget) {
				subtitle += ` ${this.state.targetVariable || ""} `;
			}
			if (this.state.step > Step.selectAdditionalParameter) {
				subtitle += ` in ${this.state.additionalParameter || ""} `;
			}
			if (this.state.step > Step.selectAdditionalValue) {
				subtitle += ` ${
					this.state.leftAdditionalValue
						? this.state.leftAdditionalValue.label
						: ""
				} in amount of`;
			}
			return subtitle;
		}

		if (this.state.step === Step.final)
			return (
				<>
					<span>For the data </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.dataScope
							? this.state.dataScope.label.concat(" ")
							: ""}
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedTable.label}
					</span>
					<span>
						{
							" I want to compare our current effort allocations to the optimal allocation levels for "
						}
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.targetVariable || ""}
					</span>
					<span>{" in"}</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{" ".concat(this.state.additionalParameter)}
					</span>
					{this.state.leftAdditionalValue ? (
						<span
							style={{
								color: mainStyle.getPropertyValue(
									"--exploration-secondary-text-color"
								),
							}}
						>
							{" ".concat(this.state.leftAdditionalValue.label)}
						</span>
					) : null}
					<span> in amount of all levers</span>
					<span> as compared to the optimal value.</span>
				</>
			);
	}

	getFooterTitle() {
		switch (this.state.step) {
			case Step.initial:
				return "... For the data in..";
			case Step.selectTable:
				return "... I want to compare our current effort allocations to the optimal allocation levels for ...";
			case Step.selectTarget:
				return "... in ...";
			case Step.selectAdditionalParameter:
				return "... as compared to the optimal value.";
			default:
				return "";
		}
	}

	showFinalStep() {
		if (
			!this.state.selectedTable ||
			!this.state.targetVariable ||
			!this.state.additionalParameter
		)
			return;
		if (this.state.step < Step.final)
			this.setState({ animationDirection: "top" }, () => {
				this.setState(
					(state) => ({
						previousStep: state.step,
						step: Step.final,
					}),
					() => {
						this.getData();
						this.saveNewJourneyConfig();
					}
				);
			});
	}
	stepDown() {
		if (this.state.step === Step.selectTable && !this.state.selectedTable)
			return;
		if (
			this.state.step === Step.selectAdditionalParameter &&
			!this.state.additionalParameter
		)
			return;
		if (this.state.step === Step.selectTarget && !this.state.targetVariable)
			return;

		let targetStep = this.state.step + 1;
		if (this.state.step === Step.selectTable) {
			if (this.state.selectedTable.value) {
				let key = this.state.selectedTable.value.concat(
					this.state.selectedTable.condition_id
				);
				let targetVariables = Object.keys(
					this.state.table2OutcomeMap[key] || {}
				);
				this.setState((state) => ({
					targetVariables: targetVariables,
					targetVariable: targetVariables.includes(
						state.targetVariable
					)
						? state.targetVariable
						: undefined,
				}));
			}
		}
		if (this.state.step === Step.selectTarget) {
			if (this.state.targetVariable) {
				let additionalParameters = this.state.table2OutcomeMap[
					this.state.selectedTable.value.concat(
						this.state.selectedTable.condition_id
					)
				][this.state.targetVariable];
				this.setState((state) => ({
					additionalParameters: additionalParameters,
					additionalParameter: additionalParameters.includes(
						state.additionalParameter
					)
						? state.additionalParameter
						: undefined,
				}));
			}
		}
		if (
			this.state.step === Step.selectAdditionalParameter &&
			this.state.additionalParameter === "overall"
		) {
			this.showFinalStep();
			return;
		}

		this.setState({ animationDirection: "top" }, () => {
			this.setState({
				step: targetStep,
			});
		});
	}

	stepUp() {
		let targetStep = this.state.step - 1;
		if (this.state.step > Step.initial) {
			this.setState({ animationDirection: "bottom" }, () => {
				this.setState({
					step: targetStep,
				});
			});
		}
	}

	render() {
		return (
			<div
				className="dashboard-rect-journey-focus"
				tabIndex="0"
				style={{
					height: 520,
					overflow: "hidden",
					display: !this.props.collapsed ? "block" : "none",
				}}
				onClick={() => {
					if (this.state.step === Step.initial) this.stepDown();
				}}
				onKeyDown={(evt) => {
					if (evt.key === "Escape") {
						this.props.onClose();
					}

					if (evt.key === "ArrowDown") {
						if (
							this.state.step > Step.initial &&
							this.state.step < Step.final
						)
							this.stepDown();
						evt.preventDefault();
					} else if (evt.key === "ArrowLeft") {
						if (this.state.step === Step.final) {
							this.back();
						}
					} else if (
						evt.key === "ArrowRight" &&
						this.state.step > Step.initial
					) {
						this.showFinalStep();
					} else if (evt.key === "ArrowUp") {
						if (this.state.step < Step.final) this.stepUp();
						evt.preventDefault();
					} else if (evt.key === "p") {
						if (this.state.step === Step.final) {
							this.saveFinding();
						}
					} else {
						if (evt.key === "d") {
							if (this.state.step === Step.final) {
								evt.preventDefault();
								this.showRecipesPopup();
							}
						}
					}

					let variableInfo = this.state.step2VariablesMap[
						this.state.step
					];
					if (variableInfo) {
						let variablesLength = this.state[variableInfo[1]]
							.length;
						let variableIndex = evt.keyCode - 65;
						if (
							variableIndex >= 0 &&
							variableIndex < variablesLength
						) {
							this.selectVariable(
								variableInfo[0],
								variableInfo[1],
								variableIndex
							);
						}
					}
					if (
						this.state.step === Step.selectAdditionalValue &&
						Variables(this.dataScopeValue()).isMonthVariable(
							this.state.additionalParameter
						)
					) {
						this.selectMonth(evt.key);
					}
					if (evt.key === "Enter") {
						if (
							this.state.step === Step.selectAdditionalValue &&
							this.state.leftAdditionalValue &&
							Variables(this.dataScopeValue()).isMonthVariable(
								this.state.additionalParameter
							)
						) {
							this.stepDown();
						}
					}
				}}
			>
				<div
					className="my-row"
					style={{ justifyContent: "space-between", height: "100%" }}
				>
					<div
						className="flex-column"
						style={{
							height: "100%",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						{this.buildHeader()}
						<TransitionGroup
							style={{
								minHeight: "inherit",
							}}
						>
							<CSSTransition
								style={{
									height: "100%",
								}}
								key={this.state.step}
								timeout={500}
								classNames={"journeywizard-".concat(
									this.state.animationDirection || ""
								)}
							>
								<div
									style={{
										height: "100%",
									}}
								>
									{this.buildContent()}
								</div>
							</CSSTransition>
						</TransitionGroup>

						<Footer
							showArrow={
								this.state.step > Step.initial &&
								this.state.step < Step.final
							}
							title={this.getFooterTitle()}
						/>
					</div>
				</div>
				{this.buildAddToRecipesPopup()}
			</div>
		);
	}
}

export { MainComponent };