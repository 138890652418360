import React, { PureComponent } from "react";
import CustomBarChart from "common/graphics/CustomBarChart";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";

class HoldOutPredictionStaticParametersChart extends PureComponent {
    buildInputItem(name, value, index) {
        return (
            <div
                key={index}
                className="flex-simple-column"
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                <input
                    style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                        marginRight: "5px",
                        width: "100px",
                    }}
                    className="like-select"
                    value={value}
                    disabled
                ></input>

                <span
                    style={{
                        width: "100%",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: mainStyle.getPropertyValue(
                            "--graphs-axes-text-color"
                        ),
                        display: "block",
                        textAlign: "center",
                        wordWrap: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {name}
                </span>
            </div>
        );
    }

    buildCategoricalColumns(clustIndex) {
        let categoriesClustItem = this.props.categoriesClust[clustIndex];
        return (
            <div className="my-row">
                {categoriesClustItem.map((item, index) => {
                    return this.buildInputItem(item.name, item.value, index);
                })}
            </div>
        );
    }
    buildLeversInRow(clustIndex) {
        let clustItem = this.props.clust[clustIndex];
        let categoriesClustItem = this.props.categoriesClust[clustIndex];
        return (
            <div
                className="my-row"
                style={{ height: "250px", flexWrap: "wrap", overflow: "auto" }}
            >
                {clustItem.map((item, index) => {
                    return this.buildInputItem(
                        item.name,
                        item.value?.toFixed(
                            this.props.decimals?.[item.name] ?? 1
                        ) ?? "",
                        index
                    );
                })}
                {categoriesClustItem.map((item, index) => {
                    return this.buildInputItem(item.name, item.value, index);
                })}
            </div>
        );
    }

    render() {
        let chartWidth =
            this.props.clust.length > 0
                ? Math.floor(100 / this.props.clust.length)
                : undefined;
        if (
            this.props.categoricalVariables.length +
                this.props.continuousVariables.length ===
            0
        )
            return null;
        return (
            <div
                className="flex-simple-column"
                style={{ height: "calc(100% - 85px)" }}
            >
                <span
                    style={{
                        paddingLeft: 30,
                        fontFamily: "Arial",
                        fontSize: 12,
                        paddingTop: 10,
                        paddingBottom: 10,
                        color: mainStyle.getPropertyValue(
                            "--exploration-tertiary-text-color"
                        ),
                        display: "block",
                        textAlign: "left",
                    }}
                >
                    {`Levers`}
                </span>
                <div
                    className="my-row"
                    style={{
                        height: "100%",
                    }}
                >
                    {this.props.clust.length !== 0 &&
                        this.props.clust.map((clustItem, clustIndex) => (
                            <div
                                key={clustIndex}
                                className="element"
                                style={{
                                    overflow: "auto hidden",
                                    position: "relative",
                                    height: "100%",
                                    minWidth: `calc(${chartWidth}% - 5px)`,
                                    marginRight: "5px",
                                }}
                            >
                                {this.props.showLeverBars && (
                                    <div style={{ height: "190px" }}>
                                        {this.props.clust[clustIndex].length >
                                            0 && (
                                            <CustomBarChart
                                                barKeys={["value"]}
                                                barColors={["#FFAB4F"]}
                                                data={
                                                    this.props.clust[clustIndex]
                                                }
                                                title={
                                                    this.props.data[clustIndex]
                                                        .name
                                                }
                                                watermark={
                                                    sections._Global?.watermark
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                                {this.props.showLeverBars &&
                                    this.buildCategoricalColumns(clustIndex)}
                                {!this.props.showLeverBars &&
                                    this.buildLeversInRow(clustIndex)}
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}

export default HoldOutPredictionStaticParametersChart;
