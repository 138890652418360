import React, { ButtonHTMLAttributes, Component } from "react";
import AutosizeInput from "react-input-autosize";
import moment from "moment";
import {
    CanvasButtonType,
    isBox,
    isInput,
    isSubmitButton,
    isSlider,
    isSimpleSpreadSheetInput,
    isDateFormat,
    isListFormat,
    InputFieldStyle,
    CanvasTextBox,
    CanvasSubmitButton,
    InnerCanvasChanges,
    SelectionArea as CanvasSelectionArea,
    CanvasSharedNode,
    PageBarInfo,
    CanvasElementType,
    CanvasInput,
    DateFormat,
    CanvasSpreadSheetGrid,
    CanvasSlider,
    DashboardVersion,
    CanvasType,
    isSurvey,
} from "common/Canvas";
import CanvasTreeStore, { CanvasViewMode } from "./CanvasTreeStore";
import AdvancedModeMenu from "./AdvancedModeMenu";
import PlacementToolbar from "./PlacementToolbar";
import SelectionArea from "@simonwep/selection-js";
import { mainStyle } from "common/MainStyle";
import { SnapDirection, SnapPosition } from "./Snap";
import { observer } from "mobx-react";
import retrieveImageFromClipboardAsBase64, {
    checkIfClipboardItemIsImage,
    retrieveImageFromClipboardAsBase64V2,
    retrieveImageFromFileAsBase64,
} from "common/utilities/retrieveImageFromClipboard";
import CustomSketchPicker from "common/custom_sketch_picker/CustomSketchPicker";
import {
    defaultLabelSize,
    defaultFontSize,
    defaultTagSize,
    defaultHeaderSize,
    verticalSectionBarItemSize,
    verticalSectionBarItemSizePadding,
    defaultMobileSlideHeight,
    headerBarHeight,
    sheetRibbonWidth,
    // let defaultHeaderColor = "#000000";
    // let defaultHeaderFillColor = "#CCCCCC";
} from "./Constants";
import Instrumentation from "common/Instrumentation";
import ItemInitializer from "./ItemInitializer";
import PinStore from "./comments/PinStore";
import CaptureOverlay from "./CaptureOverlay";
import { renderHtmlRef } from "common/utilities/renderIcon";
import { SlideBackground, Backgrounds } from "./canvas_elements/Backgrounds";
import MobileViewFrame from "./canvas_elements/MobileViewFrame";
import Tags from "./canvas_elements/Tags";
import DropdownSelectors from "./canvas_elements/DropdownSelectors";
import SpreadSheetElements from "./canvas_elements/SpreadSheetElements";
import Dashboards from "./canvas_elements/Dashboards";
import NewDashboards from "./canvas_elements/NewDashboards";
import BackendTables, {
    EditedTableCell,
} from "./canvas_elements/BackendTables";
import QuestionnaireElements from "./canvas_elements/QuestionnaireElements";
import MapElements from "./canvas_elements/MapElements";
import MapElementsOld from "./canvas_elements/MapElementsOld";
import GraphElements from "./canvas_elements/GraphElements";
import EmbedUrlElements from "./canvas_elements/EmbedUrlElements";
import ShapeElements from "./canvas_elements/ShapeElements";
import AggregateDataElements from "./canvas_elements/AggregateDataElements";
import ManageDataElements from "./canvas_elements/ManageDataElements";
import MergeDataElements from "./canvas_elements/MergeDataElements";
import CanvasButtons from "./canvas_elements/CanvasButtons";
import Inputs from "./canvas_elements/Inputs";
import ErrorBoundary from "common/ErrorBoundary";
import elements from "common/CanvasElements";
import MessagePopup from "common/MessagePopup";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import { PortalType } from "./SectionPortal";
import parseSheetData from "common/ParseSheetData";
import { deleteDataSet, deleteRows } from "common/DataApi";
import DraggableTransformer from "./canvas_elements/DraggableTransformer";
import { getButtonStyle } from "./canvas_elements/ButtonStyles";
import CanvasPageBar from "./CanvasPageBarWrapper";
import SlideNumber from "./SlideNumber";
import CommentPins from "./comment_ui/CommentPins";
import PinInformationStore from "./comments/PinInformationStore";
import remoteModuleId from "common/remoteModuleId";
import {
    FormatType,
    getFormatType,
    valueToDateInput,
    formatTypeToInputType,
    dateInputToValue,
} from "common/utilities/TimeFormatUtils";
import { getNodeSize } from "./canvas_elements/getNodeSize";
import { useDrop } from "react-dnd";
import { addNewItemUtil } from "./utilities/AddNewItemUtil";
import { NativeTypes } from "react-dnd-html5-backend";
import ListOfHiddenElements from "./canvas_elements/ListOfHiddenElements/ListOfHiddenElements";
import { ReactComponent as DesktopViewIcon } from "icons/desktop-view.svg";
import { ReactComponent as PhoneViewIcon } from "icons/phone-view.svg";
import styles from "modules/canvas_page/canvasContent.module.css";
import {
    clearLongPressTimer,
    getWidth,
    isPWA,
    startLongPressTimer,
} from "common/utilities/UIResponsiveManager";
import {
    getAlignment,
    getInputFieldStyle,
} from "./inputs/themes/InputFieldStyles";
import InputFieldDefault from "components/InputFieldStyled/Default";
import InputFieldMetrialDesign from "components/InputFieldStyled/MaterialDesign";
import OutsideAlerter from "common/OutsideAlerter";
import {
    distanceBetweenIntervals,
    middleBetweenIntervals,
} from "common/Intervals";
import { ThemeType } from "./TemplatesPopup";
import { CanvasNode } from "common/Canvas";
import CanvasExtendedGridHeader from "./canvas_elements/CanvasExtendedGridHeader";
import { CanvasElement } from "common/Canvas";
import { ItemMetadata } from "common/Canvas";
import {
    CanvasEditedElement,
    CanvasLinkedElement,
} from "./canvas_elements/FlowChartProps";
import { Page } from "common/Page";
import { SharedModule } from "common/Module";
import Finding from "common/Finding";
import { NodePosition } from "common/Canvas";
import cx from "classnames";
import WarningModalPopup from "common/WarningModal";

require("focus-options-polyfill");

function PreventMouseLeaveButton(
    props: ButtonHTMLAttributes<HTMLButtonElement>
) {
    return <button prevent-mouse-leave="true" {...props}></button>;
}

function DropArea(props: {
    onAddNewItem: (
        itemInitializer: {
            type: ItemInitializer;
            options?: any;
        },
        position?: {
            x: number;
            y: number;
            nodePosition?: NodePosition;
        }
    ) => void;
    onAddNewImages: (
        images: {
            backgroundUrl: string;
            naturalWidth: number;
            naturalHeight: number;
        }[],
        position: {
            x: number;
            y: number;
        }
    ) => void;
    children: JSX.Element;
}) {
    const [, drop] = useDrop({
        accept: ["item_initializer", NativeTypes.FILE],
        async drop(
            otherItem: {
                type: "item_initializer" | "__NATIVE_FILE__";
                files?: File[];
                content?: {
                    type: ItemInitializer;
                    options?: any;
                };
            },
            monitor
        ) {
            const clientOffset = monitor.getClientOffset();
            if (otherItem.files != null) {
                let newImages: {
                    backgroundUrl: string;
                    naturalWidth: number;
                    naturalHeight: number;
                }[] = [];
                let imageFiles = otherItem.files.filter(
                    (file: { type: string }) => file.type.startsWith("image/")
                );
                for (let imageFile of imageFiles) {
                    try {
                        let imageInfo = await retrieveImageFromFileAsBase64(
                            imageFile,
                            imageFile.type
                        );
                        newImages.push(imageInfo);
                    } catch (error) {
                        console.log(error);
                    }
                }
                if (newImages.length > 0 && clientOffset)
                    props.onAddNewImages(newImages, {
                        x: clientOffset.x,
                        y: clientOffset.y - headerBarHeight, //header bar height
                    });
            }
            if (otherItem.content != null && clientOffset) {
                props.onAddNewItem(otherItem.content, {
                    x: clientOffset.x,
                    y: clientOffset.y - headerBarHeight, //header bar height
                });
            }
        },
    });
    return <div ref={drop}>{props.children}</div>;
}

function elementToItemMetadata(element: Element): ItemMetadata {
    let itemMetadata: ItemMetadata = {
        groupId: element.getAttribute("groupId"),
        type: element.getAttribute("type") as ItemMetadata["type"],
        id: element.getAttribute("id") as ItemMetadata["id"],
    };
    if (
        itemMetadata.type === "backgroundsState" ||
        itemMetadata.type === "canvasTreeState"
    ) {
        itemMetadata.id = Number(itemMetadata.id);
    }
    if (!itemMetadata.groupId) itemMetadata.groupId = null;
    return itemMetadata;
}

interface SelectionBounds {
    left: number;
    right: number;
    top: number;
    bottom: number;
}

function getSelectionBounds(
    elements: Element[],
    offsetBounds: SelectionBounds
) {
    let selectionBounds;
    for (let selected of elements) {
        let boundingRect = selected.getBoundingClientRect();
        let offset = offsetBounds;
        if (offset == null)
            return {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            };

        if (selectionBounds == null) {
            selectionBounds = {
                left: boundingRect.x - offset.left,
                top: boundingRect.y - offset.top,
                right: boundingRect.x + boundingRect.width - offset.left,
                bottom: boundingRect.y + boundingRect.height - offset.top,
            };
        } else {
            selectionBounds.left = Math.min(
                selectionBounds.left,
                boundingRect.x - offset.left
            );
            selectionBounds.top = Math.min(
                selectionBounds.top,
                boundingRect.y - offset.top
            );
            selectionBounds.right = Math.max(
                selectionBounds.right,
                boundingRect.x + boundingRect.width - offset.left
            );
            selectionBounds.bottom = Math.max(
                selectionBounds.bottom,
                boundingRect.y + boundingRect.height - offset.top
            );
        }
    }
    return selectionBounds;
}

interface DateInputItemProps {
    scale: number;
    currentSimpleEdit: React.RefObject<HTMLInputElement>;
    editedNode: CanvasEditedElement;
    onKeepSimpleEditChanges: () => void;
    onClearEditing: (
        keepSelector?: boolean,
        shouldChangeSelectorAfterSelection?: boolean
    ) => void;
    onChangeEditedNode: (node: CanvasEditedElement) => void;
    canvasViewMode: CanvasViewMode;
}

interface DateInputItemState {
    inputValue: string | undefined;
}

class DateInputItem extends Component<DateInputItemProps, DateInputItemState> {
    constructor(props: DateInputItemProps) {
        super(props);

        let formatType = getFormatType(
            (props.editedNode.format as DateFormat).format
        );

        this.state = {
            // Inputs display time in local timezone
            inputValue: valueToDateInput(
                props.editedNode.value,
                formatType,
                false
            ),
        };
    }

    public componentDidMount(): void {
        this.props.currentSimpleEdit.current?.focus();
        this.props.currentSimpleEdit.current?.click();
    }

    public render(): JSX.Element | null {
        if (
            this.props.editedNode.format == null ||
            !isDateFormat(this.props.editedNode.format)
        )
            return null;

        const { canvasViewMode } = this.props;
        let nodeSize = getNodeSize(this.props.editedNode, canvasViewMode)
            .nodeSize[canvasViewMode];
        let formatType = getFormatType(this.props.editedNode.format.format);
        let value = this.state.inputValue;
        if (value != null && formatType === FormatType.Time) {
            let momentFormat = value.length === 5 ? "HH:mm" : "HH:mm:ss";
            value = moment(value, momentFormat)
                .subtract(new Date().getTimezoneOffset(), "m")
                .format(momentFormat);
        }
        return (
            <input
                type={formatTypeToInputType(formatType)}
                ref={this.props.currentSimpleEdit}
                value={value}
                style={{
                    fontSize:
                        this.props.scale *
                        (this.props.editedNode.fontSize || defaultFontSize),
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent",
                    fontFamily: "Roboto",
                    color:
                        this.props.editedNode.fontColor ||
                        mainStyle.getPropertyValue(
                            "--slide-flowchart-text-color"
                        ),
                    width: nodeSize.width * this.props.scale,
                    height: nodeSize.height * this.props.scale,
                }}
                onChange={(e) => {
                    let value = e.target.value;
                    if (value != null && formatType === FormatType.Time) {
                        let momentFormat =
                            value.length === 5 ? "HH:mm" : "HH:mm:ss";
                        value = moment(value, momentFormat)
                            .add(new Date().getTimezoneOffset(), "m")
                            .format(momentFormat);
                    }
                    this.setState({ inputValue: value });
                    let metricAndValue = dateInputToValue(
                        value,
                        (this.props.editedNode.format as DateFormat).format,
                        formatType,
                        // Inputs display time in local timezone
                        false
                    );
                    let editedNode = {
                        ...this.props.editedNode,
                        ...metricAndValue,
                    };
                    this.props.onChangeEditedNode(editedNode);
                }}
                onBlur={() => {
                    this.props.onKeepSimpleEditChanges();
                }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                    if (
                        (evt.key === "Enter" || evt.key === "Escape") &&
                        !evt.shiftKey
                    ) {
                        evt.preventDefault();
                        this.props.onKeepSimpleEditChanges();
                        this.props.onClearEditing();
                    }
                }}
            />
        );
    }
}

interface Props {
    onOpenThemes: (
        isOpened: boolean,
        type?: ThemeType,
        filterId?: number
    ) => void;
    hideHeaderBar: () => void;
    onScrollToTop: () => void;
    pages: Page[];
    rootSectionRef?: React.RefObject<HTMLElement>;
    headerBarIsOpened: boolean;
    canvasTreeStore: CanvasTreeStore;
    showPageBar: boolean;
    pageBarInfo: PageBarInfo | undefined;
    switchCanvasView: (view: "desktop" | "mobile") => void;
    onShowGuideDots: () => void;
    onZoomChange: (scale: number) => void;
    onDashboardEditMenuIsOpened: (isOpened: boolean) => void;
    // TODO options refers to the options prop of SectionPortal, which also
    // has "any" type. Need to change it.
    onOpenBottomPortal: (portalType: PortalType, options?: any) => void;
    sharedPolicy: CanvasSharedPolicy;
    showDots: boolean;
    showSlideNumbers: boolean;
    hidden: boolean;
    ribbonIsOpen: boolean;
    showAsSlideShow: boolean;
    isLiveStreaming: boolean;
    captureCanvas: boolean;
    canWrite: boolean;
    onCloseCapture: () => void;
    onFinishCapture: (imageBlob: Blob | string) => void;
    scale: number;
    live: boolean;
    pinInitializer: { type: ItemInitializer } | undefined;
    selectionIsActive: boolean;
    onExpandCard: (node: CanvasSharedNode) => void;
    onExpandUserCard: (node: CanvasElement | CanvasTextBox) => void;
    moduleId: number | undefined;
    moduleTitle: string;
    tablePreviewVisible: boolean;
    disableZoomWheel: boolean;
    dashboardEditMenuIsOpened: boolean;
    slideContainerClassName?: string;
    currentEditId?: string;
    customSlideNumber?: number;
    sharedModule?: SharedModule;
    currentModuleId?: string | number | null;
    setCurrentEditId: (dashboardId: string | undefined) => void;
    themesPopupOpen?: boolean;
    finding?: Finding;
}

interface State {
    clipboardReadEnabled: boolean;
    selectionBounds: SelectionBounds | undefined;
    textBoxEditAllowed: boolean;
    spreadSheetSelectionArea: CanvasSelectionArea | undefined;
    gridIdToDelete: string | undefined;
    editedNode: CanvasEditedElement | undefined;
    editedAdvancedNode: CanvasNode | undefined;
    linkedParentNode: CanvasLinkedElement | undefined;
    htmlElementsRootRef: HTMLDivElement | undefined;
    editedTagNodeId: number | undefined;
    editedGridHeader: CanvasExtendedGridHeader | undefined;
    errorTooltipVisible: {}; // {[key: number]: boolean}
    errorImage: HTMLImageElement | null;
    fontColorSizeDisplayed: boolean;
    fontColorValue: string;
    fontSizeValue: number;
    fontForLabel: boolean;
    colorPicker: {
        isOpened: boolean;
        left: number;
        top: number;
    };
    deletePopupData: {
        onDelete: () => void;
        customMessage: string | undefined;
    } | null;
    deleteElementWithDatasetPopupData: {
        onDelete: () => void;
        onKeepDataSet: () => void;
        elementName: string;
    } | null;
    tempSelectedMetadata: ItemMetadata[] | undefined;
    selectedMetadata: ItemMetadata[] | undefined;
    contextMenuInfo:
        | {
              left: number;
              top: number;
              showSelectorOptions: boolean;
              hasImage?: boolean;
              spreadSheetNode?: CanvasElement;
              toggleTitles?: {
                  gridId: string;
                  rowIndex?: number;
                  colIndex?: number;
                  insertFirstRow?: boolean;
                  insertFirstColumn?: boolean;
              };
              backendTableNode?: EditedTableCell;
              elementInfo?: {
                  id: string | number;
                  type: CanvasElementType;
              };
          }
        | undefined;
    arrowConnectIntention: boolean;
    switcherShown: boolean;
}

export default observer(
    class CanvasContent extends Component<Props, State> {
        private selection: SelectionArea | undefined;
        private backgroundRootRef: React.RefObject<HTMLDivElement>;
        private slideRootRef: React.RefObject<HTMLDivElement>;
        private layerRef: React.RefObject<HTMLDivElement>;
        private stageRef: React.RefObject<HTMLDivElement>;
        private rootRef: React.MutableRefObject<HTMLDivElement | null>;
        private currentSimpleEdit: React.RefObject<
            AutosizeInput & HTMLInputElement
        >;
        private placementToolbarSimpleEdit: React.RefObject<
            AutosizeInput & HTMLInputElement
        >;
        private viewSwitcherWrapper: React.RefObject<HTMLDivElement>;
        private performance: Date | null;
        private performanceElement: string;

        constructor(props: Props) {
            super(props);
            this.state = {
                clipboardReadEnabled: false,
                selectionBounds: undefined,
                textBoxEditAllowed: false,
                spreadSheetSelectionArea: undefined,
                htmlElementsRootRef: undefined,
                gridIdToDelete: undefined,
                editedNode: undefined,
                editedAdvancedNode: undefined,
                linkedParentNode: undefined,
                editedTagNodeId: undefined,
                editedGridHeader: undefined,
                arrowConnectIntention: false,
                errorTooltipVisible: {}, // {[key: number]: boolean}
                errorImage: null,
                fontColorSizeDisplayed: false,
                fontColorValue: "#000000",
                fontSizeValue: defaultFontSize,
                fontForLabel: false,
                colorPicker: {
                    isOpened: false,
                    left: 0,
                    top: 0,
                },
                deletePopupData: null,
                deleteElementWithDatasetPopupData: null,
                // use it for input field and submit button
                // to enable reposition and resize option while editing the element
                // (original selectedMetadata state is being cleared after start editing that's why we need this new state)
                tempSelectedMetadata: undefined,
                selectedMetadata: undefined,
                contextMenuInfo: undefined,
                switcherShown: false,
            };
            this.updateSpreadSheetSelectionArea = this.updateSpreadSheetSelectionArea.bind(
                this
            );
            this.updateSelection = this.updateSelection.bind(this);
            this.buildSimpleEditItemAutosize = this.buildSimpleEditItemAutosize.bind(
                this
            );
            this.buildSimpleEditItem = this.buildSimpleEditItem.bind(this);
            this.trackNewPerformance = this.trackNewPerformance.bind(this);

            this.safeSelectByMetadata = this.safeSelectByMetadata.bind(this);
            this.openHtmlElementContextMenu = this.openHtmlElementContextMenu.bind(
                this
            );
            this.openSpreadSheetElementContextMenu = this.openSpreadSheetElementContextMenu.bind(
                this
            );
            this.openSpreadSheetHeaderContextMenu = this.openSpreadSheetHeaderContextMenu.bind(
                this
            );
            this.openBackendTableElementContextMenu = this.openBackendTableElementContextMenu.bind(
                this
            );
            this.hideContextMenu = this.hideContextMenu.bind(this);
            this.keepSimpleEditChanges = this.keepSimpleEditChanges.bind(this);
            this.updateSelectionBounds = this.updateSelectionBounds.bind(this);
            this.deleteSnapLine = this.deleteSnapLine.bind(this);
            this.rebuildSnapLine = this.rebuildSnapLine.bind(this);
            this.startSimpleEditing = this.startSimpleEditing.bind(this);
            this.startLinkedParent = this.startLinkedParent.bind(this);
            this.startAdvancedEditing = this.startAdvancedEditing.bind(this);
            this.startEditGridHeader = this.startEditGridHeader.bind(this);
            this.clearEditing = this.clearEditing.bind(this);
            this.simpleEditInsertOuterId = this.simpleEditInsertOuterId.bind(
                this
            );
            this.moveGroupSelection = this.moveGroupSelection.bind(this);
            this.moveSelection = this.moveSelection.bind(this);
            this.deleteGridConfirm = this.deleteGridConfirm.bind(this);
            this.expandSpreadSheetNode = this.expandSpreadSheetNode.bind(this);
            this.startEditTag = this.startEditTag.bind(this);
            this.backgroundRootRef = React.createRef();
            this.slideRootRef = React.createRef();
            this.layerRef = React.createRef();
            this.stageRef = React.createRef();
            this.rootRef = React.createRef();
            this.currentSimpleEdit = React.createRef();
            this.placementToolbarSimpleEdit = React.createRef();
            this.clearSelector = this.clearSelector.bind(this);
            this.changeSelector = this.changeSelector.bind(this);
            this.canvasButtonClick = this.canvasButtonClick.bind(this);
            this.keepRootFocus = this.keepRootFocus.bind(this);
            this.onAllowTextBoxEdit = this.onAllowTextBoxEdit.bind(this);
            this.rootOnWheel = this.rootOnWheel.bind(this);
            this.openSelectorContextMenu = this.openSelectorContextMenu.bind(
                this
            );
            this.changeEditedNode = this.changeEditedNode.bind(this);
            this.showDeletePopup = this.showDeletePopup.bind(this);
            this.showDeleteElementWithDatasetPopup = this.showDeleteElementWithDatasetPopup.bind(
                this
            );
            this.performance = null;
            this.performanceElement = "";
            this.addNewItem = this.addNewItem.bind(this);
            this.addNewImages = this.addNewImages.bind(this);
            this.viewSwitcherWrapper = React.createRef();
            this.documentOnPaste = this.documentOnPaste.bind(this);
        }

        private onAllowTextBoxEdit(textBoxEditAllowed: boolean): void {
            this.setState({ textBoxEditAllowed: textBoxEditAllowed });
        }

        private showDeletePopup(
            onDelete: () => void,
            customMessage?: string
        ): void {
            this.setState({
                deletePopupData: {
                    onDelete: onDelete,
                    customMessage: customMessage,
                },
            });
        }

        private showDeleteElementWithDatasetPopup(
            onDelete: () => void,
            onKeepDataSet: () => void,
            elementName: string
        ): void {
            this.setState({
                deleteElementWithDatasetPopupData: {
                    onDelete: onDelete,
                    onKeepDataSet: onKeepDataSet,
                    elementName: elementName,
                },
            });
        }

        private initializeSelection(): void {
            this.selection = new SelectionArea({
                document: window.document,
                class: "selection-area",
                container: "body",
                selectables: ["div.selectable-by-pointer"],
                startareas: [`div[id=${"canvas-root-element"}]`],
                boundaries: [`div[id=${"canvas-root-element"}]`],
                startThreshold: 10,
                allowTouch: true,
                intersect: "touch",
                overlap: "invert",
                scrolling: {
                    speedDivider: 10,
                    manualSpeed: 750,
                },
                // SelectionArea does not support zIndex
            });
            this.selection
                .on("beforestart", (evt: any) => {
                    //   this.clearSelector();
                    if (evt.event.target.id === "select-transformer") {
                        return false;
                    }
                    let closestNotSelectableElement = evt.event.target.closest(
                        ".not-selectable-by-pointer"
                    );
                    if (closestNotSelectableElement != null) {
                        return false;
                    }
                    let closestElement = evt.event.target.closest(
                        "div.selectable-by-pointer"
                    );
                    if (closestElement != null) {
                        let metadata = elementToItemMetadata(
                            closestElement
                        ) as ItemMetadata;
                        if (
                            metadata.type === "canvasTreeState" &&
                            this.state.editedNode != null &&
                            this.state.editedNode.gridId != null &&
                            this.state.editedNode.focus &&
                            this.state.editedNode.id !== metadata.id &&
                            this.state.editedNode.metric.startsWith("=")
                        ) {
                            return false;
                        }
                        if (
                            metadata.type === "gridsState" &&
                            this.state.editedNode != null &&
                            this.state.editedNode.gridId != null &&
                            this.state.editedNode.gridId === metadata.id
                        ) {
                            return false;
                        }

                        // Grid header
                        if (
                            metadata.type === "gridsState" &&
                            this.state.editedGridHeader != null &&
                            this.state.editedGridHeader.gridId === metadata.id
                        ) {
                            return false;
                        }
                        // Selection
                        if (
                            metadata.type === "gridsState" &&
                            this.state.spreadSheetSelectionArea != null &&
                            this.state.spreadSheetSelectionArea.gridId ===
                                metadata.id
                        ) {
                            return false;
                        }

                        if (
                            this.state.selectedMetadata?.find(
                                (item) =>
                                    item.type === metadata.type &&
                                    item.id === metadata.id
                            ) != null
                        ) {
                            return false;
                        }
                        if (
                            (evt.event.ctrlKey || evt.event.metaKey) &&
                            this.state.selectedMetadata!.length > 0
                        ) {
                            let newMetadataList = [
                                ...this.state.selectedMetadata!,
                            ].concat([metadata]);
                            this.safeSelectByMetadataList(newMetadataList);
                        } else {
                            this.safeSelectByMetadata(metadata);
                        }
                        return false;
                    }
                    return true;
                })
                .on("start", () => {
                    if (this.selection != null) {
                        // T is not exported by SelectionArea
                        (this.selection as any).T.style.zIndex = 10000;
                    }
                })
                .on("stop", (evt: any) => {
                    if (evt.event.ctrlKey || evt.event.metaKey) {
                        this.setState((state) => {
                            let selectedMetadata = this.getGroupSelectedMetadata(
                                evt.store.selected.map((node: Element) =>
                                    elementToItemMetadata(node)
                                )
                            );
                            let newMetadata = selectedMetadata.filter(
                                (newMetadataItem: ItemMetadata) =>
                                    state.selectedMetadata?.find(
                                        (item) =>
                                            item.id === newMetadataItem.id &&
                                            item.type === newMetadataItem.type
                                    ) == null
                            );
                            let elements = newMetadata
                                .map((item: ItemMetadata) =>
                                    this.getNodeByMetadata(item)
                                )
                                .filter(
                                    (item: Element | undefined) => item != null
                                );
                            let selectedNodes = this.selectedNodes();
                            let selectionBounds = getSelectionBounds(
                                elements.concat(selectedNodes) as Element[],
                                this.state.htmlElementsRootRef?.getBoundingClientRect() as SelectionBounds
                            );

                            return {
                                selectedMetadata: [
                                    ...(state.selectedMetadata ?? []),
                                ].concat(newMetadata),
                                selectionBounds: selectionBounds,
                            };
                        }, this.changeSelectorAfterSelection);
                    } else {
                        let selectedMetadata = this.getGroupSelectedMetadata(
                            evt.store.selected.map((node: Element) =>
                                elementToItemMetadata(node)
                            )
                        );
                        let elements = selectedMetadata
                            .map((item: ItemMetadata) =>
                                this.getNodeByMetadata(item)
                            )
                            .filter((item) => item != null);
                        let selectionBounds = getSelectionBounds(
                            elements as Element[],
                            this.state.htmlElementsRootRef?.getBoundingClientRect() as SelectionBounds
                        );

                        this.setState(
                            {
                                selectedMetadata: selectedMetadata,
                                selectionBounds: selectionBounds,
                            },
                            this.changeSelectorAfterSelection
                        );
                    }
                });
            if (!this.props.selectionIsActive) {
                this.selection.disable();
            }
        }
        private getGroupSelectedMetadata(
            selectedMetadata: ItemMetadata[]
        ): ItemMetadata[] {
            let additionalMetadata: ItemMetadata[] = [];
            for (let metadataItem of selectedMetadata) {
                if (metadataItem.groupId != null) {
                    let group = this.selectableNodes()
                        .map((node) => elementToItemMetadata(node))
                        .filter(
                            (item) => item.groupId === metadataItem.groupId
                        );
                    additionalMetadata = additionalMetadata.concat(
                        group as ItemMetadata[]
                    );
                }
            }
            selectedMetadata = selectedMetadata.concat(additionalMetadata);
            selectedMetadata = selectedMetadata.filter(
                (other, index, self) =>
                    index ===
                    self.findIndex(
                        (t) => t.id === other.id && t.type === other.type
                    )
            );
            return selectedMetadata;
        }

        private onHtmlElementsRootRefChange = (node: HTMLDivElement): void => {
            this.setState({ htmlElementsRootRef: node });
        };

        private onRootRefChange = (node: HTMLDivElement): void => {
            this.rootRef.current = node;
        };

        componentDidMount() {
            const queryOpts = {
                name: "clipboard-read" as PermissionName,
                allowWithoutGesture: false,
            };

            if (navigator && navigator?.permissions) {
                navigator.permissions
                    .query(queryOpts)
                    .then((permissionStatus) => {
                        this.setState({
                            clipboardReadEnabled:
                                permissionStatus.state === "granted"
                                    ? true
                                    : false,
                        });
                    })
                    .catch(() => {
                        // This will throw an error if the browser does not support
                        // clipboard-read
                        this.setState({
                            clipboardReadEnabled: false,
                        });
                    });
            }

            if (
                this.props.sharedPolicy === CanvasSharedPolicy.NotShared &&
                this.performance != null
            ) {
                let timeMs = new Date().getTime() - this.performance.getTime();
                Instrumentation.addInteraction(
                    "Canvas",
                    timeMs,
                    this.performanceElement,
                    this.props.moduleTitle
                );
                this.performance = null;
                this.performanceElement = "";
            }
            let image = new window.Image();
            image.src = "/dist/img/error.png";
            image.onload = () => {
                this.setState({ errorImage: image });
            };
            window.addEventListener("wheel", this.rootOnWheel, {
                passive: false,
            });
            if (this.rootRef.current) {
                this.rootRef.current.focus();
            }

            // There are two CanvasContent - one for background and one
            // for foreground, and there are two listeners
            document.addEventListener("paste", this.documentOnPaste);

            if(this.props.selectionIsActive) 
                this.initializeSelection();

            if(this.props.selectionIsActive) 
                this.initializeSelection();
        }

        public componentWillUnmount(): void {
            window.removeEventListener("wheel", this.rootOnWheel);
            document.removeEventListener("paste", this.documentOnPaste);
            this.selection?.destroy();
        }

        private documentOnPaste(evt: ClipboardEvent): void {
            if (evt.target instanceof HTMLElement) {
                let tagName = evt.target.tagName?.toUpperCase() ?? "";
                let dataTextAttribute = evt.target.getAttribute("data-text");
                if (
                    dataTextAttribute !== "true" &&
                    tagName !== "BR" && //rich editor
                    tagName !== "INPUT" &&
                    tagName !== "TEXTAREA"
                ) {
                    evt.preventDefault();
                    this.pasteSelectionFromEvent(evt);
                }
            }
        }

        private clearSelector(
            shouldChangeSelectorAfterSelection = false
        ): void {
            this.setState(
                {
                    tempSelectedMetadata: this.state.selectedMetadata,
                    selectionBounds: undefined,
                    selectedMetadata: undefined,
                    fontColorSizeDisplayed: false,
                },
                shouldChangeSelectorAfterSelection
                    ? this.changeSelectorAfterSelection
                    : () => {}
            );
        }
        private changeSelector(
            props:
                | {
                      size?: number;
                      color?: string;
                  }
                | undefined,
            fromSpreadSheetArea?: boolean
        ): void {
            if (props == null) {
                this.setState((state) => {
                    if (state.fontColorSizeDisplayed) {
                        return {
                            fontColorSizeDisplayed: false,
                        };
                    }
                    return null;
                });
            } else {
                this.setState((state) => {
                    if (
                        state.fontColorSizeDisplayed !== true ||
                        state.fontColorValue !== props.color ||
                        state.fontSizeValue !== props.size
                    )
                        return {
                            fontSizeValue: props.size ?? defaultFontSize,
                            fontColorSizeDisplayed: true,
                            fontColorValue: props.color ?? "#000000",
                            fontForLabel: false,
                        };
                    return null;
                });
            }
            if (!fromSpreadSheetArea) {
                this.setState({ spreadSheetSelectionArea: undefined });
            }
        }
        private updateSpreadSheetSelectionArea(
            selectionArea: CanvasSelectionArea | undefined,
            allowColorChanging: boolean
        ): void {
            this.setState({ spreadSheetSelectionArea: selectionArea }, () => {
                if (
                    allowColorChanging &&
                    this.state.spreadSheetSelectionArea != null
                ) {
                    let commonFontProps = this.props.canvasTreeStore.getCommonFontPropsFromSelectionArea(
                        this.state.spreadSheetSelectionArea,
                        defaultFontSize,
                        mainStyle
                            .getPropertyValue(
                                "--slide-spreadsheet-default-text-color"
                            )
                            .trim(),
                        defaultHeaderSize
                    );
                    this.changeSelector(commonFontProps, true);
                }
            });
        }

        private canvasButtonClick(buttonType: CanvasButtonType): void {
            if (buttonType === CanvasButtonType.InputData) {
                this.props.onOpenBottomPortal(PortalType.ImportData);
            }
        }

        private updateSelectionBounds(): void {
            if (this.state.htmlElementsRootRef != null) {
                let selectionBounds = getSelectionBounds(
                    this.selectedNodes() as Element[],
                    this.state.htmlElementsRootRef?.getBoundingClientRect()
                );
                this.setState({ selectionBounds: selectionBounds });
            }
        }

        public componentDidUpdate(prevProps: Props, prevState: State): void {
            if (
                prevProps.dashboardEditMenuIsOpened !==
                this.props.dashboardEditMenuIsOpened
            ) {
                if (!this.props.dashboardEditMenuIsOpened) {
                    this.clearEditing();
                }
            }
            if (
                this.props.sharedPolicy === CanvasSharedPolicy.NotShared &&
                this.performance != null
            ) {
                let timeMs = new Date().getTime() - this.performance.getTime(); // NOT sure in getTime
                Instrumentation.addInteraction(
                    "Canvas",
                    timeMs,
                    this.performanceElement,
                    this.props.moduleTitle
                );
                this.performance = null;
                this.performanceElement = "";
            }

            if (
                (!prevProps.live && this.props.live) ||
                (prevProps.selectionIsActive && !this.props.selectionIsActive)
            ) {
                if (this.selection) {
                    this.selection.destroy();
                }
                this.clearSelector();
            }
            if (!prevProps.live && this.props.live) {
                this.keepSimpleEditChanges();
                this.clearEditing();
            }
            if (!prevProps.selectionIsActive && this.props.selectionIsActive) {
                this.clearEditing();
                this.initializeSelection();
            }
            if (this.state.editedAdvancedNode != null) {
                // If another user deleted the element that we're editing
                // https://eisengardai.atlassian.net/browse/EIS-366
                if (
                    this.props.canvasTreeStore.canvasTreeState.get(
                        this.state.editedAdvancedNode.id
                    ) == null
                ) {
                    this.clearEditing();
                } else if (
                    this.state.editedAdvancedNode !==
                    prevState.editedAdvancedNode
                ) {
                    this.props.onOpenThemes(false, undefined);
                }
            }
        }

        private isEditing(): boolean {
            return (
                this.state.editedNode != null ||
                this.state.editedTagNodeId != null ||
                this.state.editedGridHeader != null ||
                this.state.editedAdvancedNode != null ||
                this.state.linkedParentNode != null
            );
        }
        private deleteGridConfirm(gridId: string): void {
            this.setState({ gridIdToDelete: gridId });
        }

        private keepSimpleEditChanges(): void {
            if (this.state.editedNode) {
                let canSave = true;
                if (
                    this.props.isLiveStreaming &&
                    this.state.editedNode.liveStreaming
                ) {
                    let originalNode = this.props.canvasTreeStore.canvasTreeState.get(
                        this.state.editedNode.id
                    );
                    canSave =
                        originalNode == null ||
                        this.props.canvasTreeStore.checkTypes(
                            originalNode,
                            this.state.editedNode
                        );
                }
                if (canSave) {
                    if (isBox(this.state.editedNode)) {
                        this.props.canvasTreeStore.updateNodeAction<
                            CanvasElement,
                            "name" | "leftUnit" | "unit" | "subtitle" | "metric"
                        >(this.state.editedNode.id, {
                            name: this.state.editedNode.name,
                            leftUnit: this.state.editedNode.leftUnit,
                            unit: this.state.editedNode.unit,
                            subtitle: this.state.editedNode.subtitle,
                            metric: this.state.editedNode.metric,
                        });
                    }
                    if (isSimpleSpreadSheetInput(this.state.editedNode)) {
                        let grid = this.props.canvasTreeStore.gridsState.get(
                            this.state.editedNode.gridId
                        ) as CanvasSpreadSheetGrid;
                        let columnFormat =
                            grid?.headers?.[this.state.editedNode.x!]
                                ?.columnFormat ?? undefined;
                        if (!isListFormat(columnFormat)) {
                            this.props.canvasTreeStore.updateNodeAction(
                                this.state.editedNode.id,
                                {
                                    metric: this.state.editedNode.metric,
                                }
                            );
                        }
                    }
                    if (
                        isSubmitButton(this.state.editedNode) ||
                        (isInput(this.state.editedNode) &&
                            !isDateFormat(this.state.editedNode.format))
                    ) {
                        this.props.canvasTreeStore.updateNodeAction(
                            this.state.editedNode.id,
                            {
                                metric: this.state.editedNode.metric,
                                value: this.state.editedNode.metric.startsWith(
                                    "="
                                )
                                    ? Number(
                                          this.state.editedNode.metric.substring(
                                              1
                                          ) || NaN
                                      )
                                    : Number(
                                          this.state.editedNode.metric || NaN
                                      ),
                            }
                        );
                    }
                    if (
                        isInput(this.state.editedNode) &&
                        isDateFormat(this.state.editedNode.format)
                    ) {
                        this.props.canvasTreeStore.updateNodeAction(
                            this.state.editedNode.id,
                            {
                                metric: this.state.editedNode.metric,
                                value: this.state.editedNode.value,
                            }
                        );
                    }
                    if (isSlider(this.state.editedNode)) {
                        this.props.canvasTreeStore.updateNodeAction<
                            CanvasSlider,
                            "minOutput" | "maxOutput"
                        >(this.state.editedNode.id, {
                            minOutput: this.state.editedNode.minOutput,
                            maxOutput: this.state.editedNode.maxOutput,
                        });
                    }
                } else {
                    this.props.canvasTreeStore.canvasTreeParseErrorsState.set(
                        this.state.editedNode.id,
                        "You cannot change type of streaming node"
                    );
                }
            }
            if (this.state.editedGridHeader) {
                if (this.state.editedGridHeader.row !== -1) {
                    this.props.canvasTreeStore.updateGridLeftHeaderNodeAction(
                        this.state.editedGridHeader.gridId,
                        this.state.editedGridHeader.row,
                        { text: this.state.editedGridHeader.text }
                    );
                } else {
                    this.props.canvasTreeStore.updateGridHeaderNodeAction(
                        this.state.editedGridHeader.gridId,
                        this.state.editedGridHeader.col,
                        { text: this.state.editedGridHeader.text }
                    );
                }
            }
        }
        private clearEditing(
            keepSelector: boolean = false,
            shouldChangeSelectorAfterSelection: boolean = false
        ): void {
            if (!keepSelector)
                this.clearSelector(shouldChangeSelectorAfterSelection);
            if (this.isEditing())
                this.setState({
                    editedTagNodeId: undefined,
                    editedNode: undefined,
                    editedAdvancedNode: undefined,
                    linkedParentNode: undefined,
                    editedGridHeader: undefined,
                });
        }
        private startSimpleEditing(
            node: CanvasElement,
            focus?: boolean | undefined,
            internalEdit?: boolean | undefined
        ): void {
            let isInternalEdit = internalEdit ?? true;
            let isFocus = focus ?? true;
            this.setState({
                editedNode: {
                    ...node,
                    internalEdit: isInternalEdit,
                    focus: isFocus,
                },
            });
        }
        private startLinkedParent(node: CanvasLinkedElement): void {
            this.setState({ linkedParentNode: node });
        }
        private startAdvancedEditing(node: CanvasNode): void {
            this.setState({ editedAdvancedNode: node });
        }
        private startEditGridHeader(header: CanvasExtendedGridHeader): void {
            this.setState({ editedGridHeader: header });
        }
        private changeEditedNode(node: CanvasEditedElement): void {
            this.setState({ editedNode: node as CanvasEditedElement });
        }

        private updateSelection(props: {
            fontSize?: number;
            fontColor?: string;
        }): void {
            if (this.state.spreadSheetSelectionArea != null) {
                this.props.canvasTreeStore.updateBySelectionAreaAction(
                    this.state.spreadSheetSelectionArea,
                    props
                );
            } else if (this.state.selectedMetadata != null) {
                this.props.canvasTreeStore.updateByMetadataAction(
                    this.state.selectedMetadata,
                    props
                );
                setTimeout(() => {
                    this.updateSelectionBounds();
                }, 0);
            }
            if (this.state.editedNode) {
                this.setState((state) => ({
                    editedNode: { ...state.editedNode!, ...props },
                }));
                this.props.canvasTreeStore.updateNodeAction(
                    this.state.editedNode.id,
                    props
                );
            }
        }

        private buildSimpleEditItemAutosize(
            fieldName: string | string[],
            placeholder: string,
            optionalStyles: React.CSSProperties,
            switchLabelAndMetric: boolean
        ): JSX.Element {
            type FieldName = keyof typeof this.state.editedNode;
            return (
                <AutosizeInput
                    ref={this.currentSimpleEdit}
                    placeholder={placeholder}
                    autoFocus
                    inputStyle={{
                        fontSize:
                            this.props.scale *
                            (fieldName === "name"
                                ? this.state.editedNode?.labelSize ||
                                  defaultLabelSize
                                : this.state.editedNode?.fontSize ||
                                  defaultFontSize),
                        border: "none",
                        outline: "none",
                        backgroundColor: "transparent",
                        fontFamily: "Roboto",
                        color:
                            (fieldName === "name"
                                ? this.state.editedNode?.labelColor
                                : this.state.editedNode?.fontColor) ||
                            (fieldName === "name"
                                ? mainStyle.getPropertyValue(
                                      "--slide-flowchart-label-color"
                                  )
                                : mainStyle.getPropertyValue(
                                      "--slide-flowchart-text-color"
                                  )),
                        ...optionalStyles,
                    }}
                    value={
                        Array.isArray(fieldName)
                            ? fieldName.reduce<any>( // Accessing a nested field in this way is not possible without any
                                  (acc, field) =>
                                      (acc != null && acc[field]) || null,
                                  this.state.editedNode
                              )
                            : this.state.editedNode?.[fieldName as FieldName] ??
                              ""
                    }
                    onChange={(evt) => {
                        // We have to read name before setState, because evt.target.value might be undefined during setState
                        let name = evt.target.value;
                        this.setState((state) => {
                            let editedNode = Object.assign(
                                {},
                                state.editedNode
                            );
                            if (Array.isArray(fieldName)) {
                                // Accessing a nested field in this way is not possible without any
                                let obj: any = editedNode;
                                let i;
                                for (i = 0; i < fieldName.length - 1; ++i) {
                                    if (obj != null) {
                                        obj[
                                            fieldName[i] as FieldName
                                        ] = Object.assign(
                                            {},
                                            obj[fieldName[i] as FieldName]
                                        );
                                        obj = obj[fieldName[i] as FieldName];
                                    }
                                }
                                // i === fieldName.length - 1 at this point
                                obj[fieldName[i]] = name;
                            } else {
                                (editedNode as any)[fieldName] = name;
                            }
                            return { editedNode: editedNode };
                        });
                    }}
                    onFocus={() => {
                        if (!switchLabelAndMetric) return;
                        this.setState((state) => {
                            if (state.fontForLabel && fieldName === "name")
                                return null;
                            if (!state.fontForLabel && fieldName !== "name")
                                return null;
                            if (!state.fontForLabel && fieldName === "name") {
                                let props = {
                                    fontSizeValue:
                                        state.editedNode?.labelSize ||
                                        defaultLabelSize,
                                    fontColorSizeDisplayed: true,
                                    fontColorValue:
                                        state.editedNode?.labelColor ||
                                        mainStyle.getPropertyValue(
                                            "--content-primary-text-color"
                                        ),
                                    fontForLabel: true,
                                };
                                return props;
                            }
                            if (state.fontForLabel && fieldName !== "name") {
                                let props = {
                                    fontSizeValue:
                                        state.editedNode?.fontSize ||
                                        defaultFontSize,
                                    fontColorSizeDisplayed: true,
                                    fontColorValue:
                                        state.editedNode?.fontColor ||
                                        mainStyle.getPropertyValue(
                                            "--content-primary-text-color"
                                        ),
                                    fontForLabel: false,
                                };
                                return props;
                            }
                            return null;
                        });
                    }}
                    onBlur={() => {
                        this.keepSimpleEditChanges();
                    }}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                        if (
                            (evt.key === "Enter" || evt.key === "Escape") &&
                            !evt.shiftKey
                        ) {
                            evt.preventDefault();
                            this.keepSimpleEditChanges();
                            this.clearEditing();
                        }
                    }}
                />
            );
        }

        private buildSimpleEditItem(
            fieldName: keyof CanvasEditedElement | string[],
            placeholder: string,
            optionalStyles: React.CSSProperties,
            switchLabelAndMetric: boolean
        ): JSX.Element {
            const { canvasViewMode } = this.props.canvasTreeStore;
            if (this.state.editedNode == null) return <></>;
            let nodeSize = getNodeSize(this.state.editedNode, canvasViewMode)
                .nodeSize[canvasViewMode as CanvasViewMode];

            const value = Array.isArray(fieldName)
                ? fieldName.reduce<any>( // Accessing a nested field in this way is not possible without any
                      (acc, field) => (acc != null && acc[field]) || null,
                      this.state.editedNode
                  )
                : this.state.editedNode[fieldName] ?? "";

            const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
                // We have to read name before setState, because evt.target.value might be undefined during setState
                let name = evt.target.value;
                this.setState((state) => {
                    let editedNode = Object.assign({}, state.editedNode);
                    if (Array.isArray(fieldName)) {
                        // Accessing a nested field in this way is not possible without any
                        let obj: any = editedNode;
                        let i;
                        for (i = 0; i < fieldName.length - 1; ++i) {
                            if (obj != null) {
                                obj[fieldName[i]] = Object.assign(
                                    {},
                                    obj[fieldName[i]]
                                );
                                obj = obj[fieldName[i]];
                            }
                        }
                        // i === fieldName.length - 1 at this point
                        obj[fieldName[i]] = name;
                    } else {
                        (editedNode[fieldName] as any) = name;
                    }
                    return { editedNode: editedNode };
                });
            };

            const onFocus = () => {
                if (!switchLabelAndMetric) return;
                this.setState((state) => {
                    if (state.fontForLabel && fieldName === "name") return;
                    if (!state.fontForLabel && fieldName !== "name") return;
                    if (!state.fontForLabel && fieldName === "name") {
                        const fontSize =
                            state.editedNode?.labelSize || defaultLabelSize;
                        const fontColor =
                            state.editedNode?.labelColor ||
                            mainStyle.getPropertyValue(
                                "--content-primary-text-color"
                            );
                        let props = {
                            ...state,
                            fontSizeValue: fontSize as number,
                            fontColorSizeDisplayed: true,
                            fontColorValue: fontColor as string,
                            fontForLabel: true,
                        };
                        return props;
                    }
                    if (state.fontForLabel && fieldName !== "name") {
                        const fontSize =
                            state.editedNode?.fontSize || defaultFontSize;
                        const fontColor =
                            state.editedNode?.fontColor ||
                            mainStyle.getPropertyValue(
                                "--content-primary-text-color"
                            );
                        let props = {
                            ...state,
                            fontSizeValue: fontSize as number,
                            fontColorSizeDisplayed: true,
                            fontColorValue: fontColor as string,
                            fontForLabel: false,
                        };
                        return props;
                    }
                });
            };

            const onKeyDown = (
                evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
                evt.stopPropagation();
                if (
                    (evt.key === "Escape") &&
                    !evt.shiftKey
                ) {
                    evt.preventDefault();
                    this.keepSimpleEditChanges();
                    this.clearEditing();
                }
            };

            const { editedNode } = this.state;

            const inputTextColor =
                (fieldName === "name"
                    ? editedNode?.labelColor
                    : editedNode?.fontColor) ||
                (fieldName === "name"
                    ? mainStyle.getPropertyValue(
                          "--slide-flowchart-label-color"
                      )
                    : mainStyle.getPropertyValue(
                          "--slide-flowchart-text-color"
                      ));

            const originalFontSize =
                fieldName === "name"
                    ? editedNode?.labelSize || defaultLabelSize
                    : editedNode?.fontSize || defaultFontSize;
            const fontSize =
                originalFontSize * this.props.scale;

            const style = {
                fontSize: fontSize,
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                fontFamily: (editedNode as CanvasInput).fontFamily ?? "Roboto",
                color: inputTextColor,
                caretColor: inputTextColor,
                width: nodeSize.width * this.props.scale,
                height: nodeSize.height * this.props.scale,
                textAlign: "center",
            };

            const isMDInputFieldTheme =
                editedNode != null &&
                isInput(editedNode) &&
                ((editedNode as CanvasInput).inputFieldStyle ===
                    InputFieldStyle.MDFilled ||
                    (editedNode as CanvasInput).inputFieldStyle ===
                        InputFieldStyle.MDOutlined);
            const isDefaultInputFieldTheme =
                editedNode != null &&
                isInput(editedNode) &&
                (editedNode as CanvasInput).inputFieldStyle ===
                    InputFieldStyle.Default;

            const textAlignment =
                editedNode != null &&
                isInput(editedNode) &&
                getAlignment((editedNode as CanvasInput).textAlignment?.value);

            return (
                <>
                    {!isMDInputFieldTheme && (
                        <InputFieldDefault
                            currentSimpleEdit={this.currentSimpleEdit}
                            placeholder={
                                isDefaultInputFieldTheme
                                    ? "Type here..."
                                    : placeholder
                            }
                            style={
                                {
                                    ...style,
                                    ...optionalStyles,
                                    ...textAlignment,
                                } as React.CSSProperties
                            }
                            value={value}
                            onChange={onChange}
                            onFocus={onFocus}
                            keepSimpleEditChanges={this.keepSimpleEditChanges}
                            onKeyDown={onKeyDown}
                        />
                    )}

                    {isMDInputFieldTheme && (
                        <>
                            <InputFieldMetrialDesign
                                currentSimpleEdit={this.currentSimpleEdit}
                                editedNode={editedNode}
                                scale={this.props.scale}
                                style={
                                    {
                                        ...style,
                                        ...optionalStyles,
                                        ...textAlignment,
                                        width:
                                            nodeSize.width * this.props.scale,
                                        height:
                                            nodeSize.height * this.props.scale,
                                    } as React.CSSProperties
                                }
                                labelSize={
                                    (editedNode.fontSize ?? 10) *
                                    this.props.scale
                                }
                                value={value}
                                onChange={onChange}
                                onChangeLabel={(label) => {
                                    this.props.canvasTreeStore.updateNodeAction<
                                        CanvasInput,
                                        "label"
                                    >(editedNode.id, {
                                        label,
                                    });
                                }}
                                onFocus={onFocus}
                                keepSimpleEditChanges={
                                    this.keepSimpleEditChanges
                                }
                                onKeyDown={onKeyDown}
                            />
                        </>
                    )}
                </>
            );
        }

        private simpleEditInsertOuterId(box: { outerId: string }): void {
            let currentSimpleEdit = this.state.editedNode?.internalEdit
                ? this.currentSimpleEdit
                : this.placementToolbarSimpleEdit;

            if (
                currentSimpleEdit.current == null ||
                this.state.editedNode == null
            )
                return;
            let inputElement =
                currentSimpleEdit.current.getInput?.() ??
                currentSimpleEdit.current;
            let selectionStart = inputElement.selectionStart;
            let selectionEnd = inputElement.selectionEnd;
            if (currentSimpleEdit.current != null) {
                currentSimpleEdit.current.focus({ preventScroll: true });
            }
            if (selectionStart == null || selectionEnd == null) {
                selectionStart = this.state.editedNode.metric.length;
                selectionEnd = this.state.editedNode.metric.length;
            }
            this.setState(
                (state) => {
                    return {
                        editedNode: {
                            ...state.editedNode!,
                            metric:
                                state.editedNode!.metric.substring(
                                    0,
                                    selectionStart!
                                ) +
                                box.outerId +
                                state.editedNode!.metric.substring(
                                    selectionEnd!
                                ),
                        },
                    };
                },
                () => {
                    if (inputElement != null) {
                        inputElement.setSelectionRange(
                            selectionStart! + box.outerId.length,
                            selectionStart! + box.outerId.length
                        );
                    }
                }
            );
        }
        private moveSelection(deltaX: number, deltaY: number): void {
            this.props.canvasTreeStore.shiftByMetadataAction(
                this.state.selectedMetadata as ItemMetadata[],
                {
                    deltaX: deltaX,
                    deltaY: deltaY,
                }
            );
            setTimeout(this.updateSelectionBounds, 0);
        }

        // If propagatedChanges is set, then updateNodeAction does not call
        // saveChangesAction and writes all changes here instead
        private moveGroupSelection(
            deltaX: number,
            deltaY: number,
            metadata: ItemMetadata,
            skipHistory?: boolean | undefined,
            propagatedChanges?: InnerCanvasChanges | undefined
        ): void {
            let restMetadata: ItemMetadata[] = [];
            if (metadata.groupId != null) {
                restMetadata = this.selectableNodes()
                    .map((node) => elementToItemMetadata(node))
                    .filter(
                        (item) =>
                            item.groupId === metadata.groupId &&
                            (item.id !== metadata.id ||
                                item.type !== metadata.type)
                    ) as ItemMetadata[];
            }
            if (
                this.state.selectedMetadata != null &&
                this.state.selectedMetadata.length > 1
            ) {
                restMetadata = restMetadata.concat(
                    this.state.selectedMetadata.filter(
                        (item) =>
                            item.groupId === null &&
                            (item.id !== metadata.id ||
                                item.type !== metadata.type)
                    )
                );
            }
            this.props.canvasTreeStore.shiftByMetadataAction(
                restMetadata,
                {
                    deltaX: deltaX,
                    deltaY: deltaY,
                },
                skipHistory,
                propagatedChanges
            );
            setTimeout(this.updateSelectionBounds, 0);
        }
        private isMetadataSelected(metadata: ItemMetadata): boolean {
            if (this.state.selectedMetadata == null) return false;
            return (
                this.state.selectedMetadata.find(
                    (item) =>
                        item.id === metadata.id && item.type === metadata.type
                ) != null
            );
        }
        private selectObjects(
            metadataList: ItemMetadata[],
            focus: boolean = true
        ): void {
            let groupMetadata = this.getGroupSelectedMetadata(metadataList);
            let nodes = groupMetadata
                .map((item) => this.getNodeByMetadata(item))
                .filter((item) => item != null);

            let selectionBounds = getSelectionBounds(
                nodes as Element[],
                this.state.htmlElementsRootRef?.getBoundingClientRect() as SelectionBounds
            );
            this.setState(
                {
                    selectionBounds: selectionBounds,
                    selectedMetadata: groupMetadata,
                },
                this.changeSelectorAfterSelection
            );

            if (this.rootRef.current && focus) {
                setTimeout(() => {
                    this.rootRef.current?.focus({ preventScroll: true });
                }, 0);
            }
        }

        private changeSelectorAfterSelection(): void {
            const selectedMetadataLength =
                this.state.selectedMetadata?.length ?? 0;
            if (selectedMetadataLength > 0) {
                let commonFontProps = this.props.canvasTreeStore.getCommonFontPropsFromMetadata(
                    this.state.selectedMetadata as ItemMetadata[],
                    defaultTagSize,
                    defaultFontSize,
                    mainStyle
                        .getPropertyValue("--content-primary-text-color")
                        .trim()
                );
                this.changeSelector(commonFontProps, false);
            } else {
                this.changeSelector(undefined);
            }
        }
        private selectableNodes(): Element[] {
            return (
                Array.from(
                    this.rootRef.current?.querySelectorAll(
                        "div.selectable-by-pointer"
                    ) ?? []
                ) ?? []
            );
        }
        private selectedNodes(): (Element | undefined)[] {
            return (
                this.state.selectedMetadata
                    ?.map((item) => this.getNodeByMetadata(item))
                    .filter((item) => item != null) ?? []
            );
        }
        private getNodeByMetadata(metadata: ItemMetadata): Element | undefined {
            let selectableNodes = this.selectableNodes();
            return selectableNodes.find((item) => {
                let itemMetadata = elementToItemMetadata(item);
                if (
                    itemMetadata.id === metadata.id &&
                    itemMetadata.type === metadata.type
                )
                    return true;
                return false;
            });
        }

        private safeSelectByMetadata(
            metadata: ItemMetadata,
            focus: boolean = true
        ): void {
            if (this.props.selectionIsActive) {
                this.keepSimpleEditChanges();
                this.clearEditing();
                this.selectObjects([metadata], focus);
            }
        }
        private expandSpreadSheetNode(
            node: CanvasElement | CanvasSubmitButton,
            evt: MouseEvent
        ): void {
            let bounds = this.rootRef.current?.getBoundingClientRect() ?? {
                left: 0,
                top: 0,
            };

            let absolutePositionNode = {
                ...node,
            };
            absolutePositionNode.x = evt.clientX - bounds.left;
            absolutePositionNode.y = evt.clientY - bounds.top;
            this.props.onExpandCard(absolutePositionNode);
        }

        private openSpreadSheetHeaderContextMenu(
            evt: MouseEvent,
            row: number,
            col: number,
            gridId: string
        ): void {
            let bounds = this.rootRef.current?.getBoundingClientRect() ?? {
                left: 0,
                top: 0,
            };
            let contextMenuInfo = {
                left: evt.clientX - bounds.left,
                top: evt.clientY - bounds.top,
                showSelectorOptions: false,
                toggleTitles:
                    col !== -1
                        ? {
                              colIndex: col,
                              insertFirstRow: true,
                              gridId: gridId,
                          }
                        : {
                              rowIndex: row,
                              insertFirstColumn: true,
                              gridId: gridId,
                          },
            };
            this.setState({
                contextMenuInfo: contextMenuInfo,
            });
        }
        private hideContextMenu(): void {
            this.setState({
                contextMenuInfo: undefined,
            });
        }
        private openSpreadSheetElementContextMenu(
            evt: MouseEvent,
            node: CanvasElement
        ): void {
            let bounds = this.rootRef.current?.getBoundingClientRect() ?? {
                left: 0,
                top: 0,
            };
            if (node.gridId != null) {
                let contextMenuInfo = {
                    left: evt.clientX - bounds.left,
                    top: evt.clientY - bounds.top,
                    showSelectorOptions: false,
                    spreadSheetNode: node,
                    toggleTitles: {
                        gridId: node.gridId,
                    },
                };
                this.setState({
                    contextMenuInfo: contextMenuInfo,
                });
            }
        }
        private openBackendTableElementContextMenu(
            evt: MouseEvent,
            node: EditedTableCell
        ): void {
            let bounds = this.rootRef.current?.getBoundingClientRect() ?? {
                left: 0,
                top: 0,
            };
            let contextMenuInfo = {
                left: evt.clientX - bounds.left,
                top: evt.clientY - bounds.top,
                showSelectorOptions: false,
                backendTableNode: node,
            };
            this.setState({
                contextMenuInfo: contextMenuInfo,
            });
        }
        private openHtmlElementContextMenu(
            evt: React.MouseEvent,
            elementInfo: {
                id: string | number;
                type: CanvasElementType;
            },
            showSelectorOptions?: boolean | undefined
        ): void {
            let metadataInfo = {
                id: elementInfo.id,
                type: elementInfo.type,
                groupId: null,
            };

            if (
                !showSelectorOptions ||
                this.isMetadataSelected(metadataInfo as ItemMetadata)
            ) {
                evt.preventDefault();
                let bounds = this.rootRef.current?.getBoundingClientRect() ?? {
                    left: 0,
                    top: 0,
                };
                let contextMenuInfo = {
                    left: evt.clientX - bounds.left,
                    top: evt.clientY - bounds.top,
                    showSelectorOptions: showSelectorOptions ?? false,
                    elementInfo: elementInfo,
                };

                this.setState({
                    contextMenuInfo: contextMenuInfo,
                });
            }
        }

        private openSelectorContextMenu(evt: MouseEvent): void {
            let bounds = this.rootRef.current?.getBoundingClientRect() ?? {
                left: 0,
                top: 0,
            };
            let contextMenuInfo = {
                left: evt.clientX - bounds.left,
                top: evt.clientY - bounds.top,
                showSelectorOptions: true,
            };

            this.setState({
                contextMenuInfo: contextMenuInfo,
            });
        }
        private safeSelectByMetadataList(metadataList: ItemMetadata[]): void {
            if (this.props.selectionIsActive) {
                this.keepSimpleEditChanges();
                this.clearEditing();
                this.selectObjects(metadataList);
            }
        }

        private buildSnapLine(
            currentPos: {
                x: number;
                y: number;
                width: number;
                height: number;
            },
            metadata: ItemMetadata | ItemMetadata[]
        ): {
            point: number;
            snapDirection: SnapDirection;
            snapPosition: SnapPosition;
        }[] {
            let metadataIdToType: { [key: string]: string } = {};
            if (Array.isArray(metadata)) {
                for (let item of metadata) {
                    metadataIdToType[item.id!.toString()] = item.type;
                }
            } else {
                metadataIdToType[metadata.id!.toString()] = metadata.type;
            }
            let autoGridElements = this.selectableNodes()
                .filter((item) => {
                    let itemMetadata = elementToItemMetadata(
                        item as HTMLBaseElement
                    ); // NOT HTMLBaseElement
                    let id = itemMetadata.id!.toString();
                    return (
                        !(id in metadataIdToType) ||
                        itemMetadata.type !== metadataIdToType[id]
                    );
                })
                .map((item) =>
                    getSelectionBounds(
                        [item],
                        this.state.htmlElementsRootRef?.getBoundingClientRect() as SelectionBounds
                    )
                );

            const threshold = 5;

            let nearestPoints: {
                point: number;
                snapDirection: SnapDirection;
                snapPosition: SnapPosition;
            }[] = [];
            // Nested object with the following indices:
            // alignItems[SnapDirection][Math.round(coordinate)]
            // we write the [left, right] or [top, bottom] of the item into the
            // most nested object (depending on snap direction)
            let alignedItems: {
                [key in SnapDirection]: {
                    [key: number]: [number, number][];
                };
            } = {
                [SnapDirection.X]: {},
                [SnapDirection.Y]: {},
            };
            let currentEdges = [
                currentPos.x,
                currentPos.y,
                currentPos.x + currentPos.width / 2,
                currentPos.y + currentPos.height / 2,
                currentPos.x + currentPos.width,
                currentPos.y + currentPos.height,
                // Other sides to top
                currentPos.x,
                currentPos.y,
                currentPos.x,
                currentPos.y,
                // Other sides to middle
                currentPos.x + currentPos.width / 2,
                currentPos.y + currentPos.height / 2,
                currentPos.x + currentPos.width / 2,
                currentPos.y + currentPos.height / 2,
                // Other sides to bottom
                currentPos.x + currentPos.width,
                currentPos.y + currentPos.height,
                currentPos.x + currentPos.width,
                currentPos.y + currentPos.height,
            ];
            autoGridElements.forEach((item) => {
                if (!item) return;
                let itemEdges = [
                    item.left,
                    item.top,
                    (item.left + item.right) / 2,
                    (item.top + item.bottom) / 2,
                    item.right,
                    item.bottom,
                    // Other sides to top
                    (item.left + item.right) / 2,
                    (item.top + item.bottom) / 2,
                    item.right,
                    item.bottom,
                    // Other sides to middle
                    item.left,
                    item.top,
                    item.right,
                    item.bottom,
                    // Other sides to bottom
                    item.left,
                    item.top,
                    (item.left + item.right) / 2,
                    (item.top + item.bottom) / 2,
                ];
                let snapPositions = [
                    SnapPosition.Start,
                    SnapPosition.Start,
                    SnapPosition.Middle,
                    SnapPosition.Middle,
                    SnapPosition.End,
                    SnapPosition.End,
                    // Other sides to top
                    SnapPosition.Start,
                    SnapPosition.Start,
                    SnapPosition.Start,
                    SnapPosition.Start,
                    // Other sides to middle
                    SnapPosition.Middle,
                    SnapPosition.Middle,
                    SnapPosition.Middle,
                    SnapPosition.Middle,
                    // Other sides to bottom
                    SnapPosition.End,
                    SnapPosition.End,
                    SnapPosition.End,
                    SnapPosition.End,
                ];
                for (let i = 0; i < currentEdges.length; ++i) {
                    if (Math.abs(currentEdges[i] - itemEdges[i]) <= threshold) {
                        let snapDirection =
                            i % 2 === 0 ? SnapDirection.Y : SnapDirection.X;
                        nearestPoints.push({
                            point: itemEdges[i],
                            snapDirection: snapDirection,
                            snapPosition: snapPositions[i],
                        });
                        // Here we round everything to the nearest pixel,
                        // since otherwise even spacing wouldn't work due to
                        // imperceivable differences.
                        let newInterval: [number, number] =
                            snapDirection === SnapDirection.X
                                ? [
                                      Math.round(item.left),
                                      Math.round(item.right),
                                  ]
                                : [
                                      Math.round(item.top),
                                      Math.round(item.bottom),
                                  ];
                        let point = Math.round(itemEdges[i]);
                        if (!(point in alignedItems[snapDirection])) {
                            alignedItems[snapDirection][point] = [newInterval];
                        } else {
                            alignedItems[snapDirection][point].push(
                                newInterval
                            );
                        }
                    }
                }
            });
            // Snap to middle of the slide
            currentEdges = [
                currentPos.x,
                currentPos.y,
                currentPos.x + currentPos.width / 2,
                currentPos.y + currentPos.height / 2,
                currentPos.x + currentPos.width,
                currentPos.y + currentPos.height,
            ];
            const slideRect = this.props.canvasTreeStore.slideRect();
            const slideHorCenter = Math.round(
                (slideRect.width * this.props.scale) / 2
            );
            const slideVerCenter = Math.round(
                (slideRect.height * this.props.scale) / 2
            );
            let slideLines = [
                slideHorCenter,
                slideVerCenter,
                slideHorCenter,
                slideVerCenter,
                slideHorCenter,
                slideVerCenter,
            ];
            let snapPositions = [
                SnapPosition.Start,
                SnapPosition.Start,
                SnapPosition.Middle,
                SnapPosition.Middle,
                SnapPosition.End,
                SnapPosition.End,
            ];
            for (let i = 0; i < currentEdges.length; ++i) {
                if (Math.abs(currentEdges[i] - slideLines[i]) <= threshold) {
                    let snapDirection =
                        i % 2 === 0 ? SnapDirection.Y : SnapDirection.X;
                    nearestPoints.push({
                        point: slideLines[i],
                        snapDirection: snapDirection,
                        snapPosition: snapPositions[i],
                    });
                }
            }

            // Draw snap lines
            for (let nearestPoint of nearestPoints) {
                let newDiv = document.createElement("div");
                newDiv.style.zIndex = "999999999";

                if (nearestPoint.snapDirection === SnapDirection.X) {
                    let width = this.layerRef.current?.clientWidth;
                    newDiv.setAttribute("tag", "snapLine");
                    newDiv.style.width = `${width}px`;
                    newDiv.style.height = "1px";
                    newDiv.style.top = `${nearestPoint.point - 1}px`;
                    newDiv.style.left = "0px";
                    newDiv.style.backgroundColor = "transparent";
                    newDiv.style.borderTop = "1px dashed #39F";

                    newDiv.style.position = "absolute";
                } else {
                    let height = this.layerRef.current?.clientHeight;
                    newDiv.setAttribute("tag", "snapLine");
                    newDiv.style.width = "0px";
                    newDiv.style.borderLeft = "1px dashed #39F";
                    newDiv.style.height = `${height}px`;
                    newDiv.style.top = "0px";
                    newDiv.style.left = `${nearestPoint.point - 1}px`;
                    newDiv.style.backgroundColor = "transparent";
                    newDiv.style.position = "absolute";
                }
                this.layerRef.current?.appendChild(newDiv);
            }
            // Calculate evenly spaced elements
            for (let snapDirection of [SnapDirection.X, SnapDirection.Y]) {
                for (let point in alignedItems[snapDirection]) {
                    if (alignedItems[snapDirection][point].length <= 1) {
                        delete alignedItems[snapDirection][point];
                    }
                }
            }
            // There can be only one snap point based on distance
            let currentSnapPoint:
                | {
                      spacing: number;
                      distance: number;
                      alignmentPoint: number;
                      point: string;
                      snapDirection: SnapDirection;
                      pairs: [number, number][][];
                  }
                | undefined = undefined;

            for (let snapDirection of [SnapDirection.X, SnapDirection.Y]) {
                let currentInterval =
                    snapDirection === SnapDirection.X
                        ? [currentPos.x, currentPos.x + currentPos.width]
                        : [currentPos.y, currentPos.y + currentPos.height];

                for (let point in alignedItems[snapDirection]) {
                    let intervals = alignedItems[snapDirection][point];
                    let beforeCurrentInterval = [];
                    let afterCurrentInterval = [];
                    let minPointAfterCurrentInterval = Infinity;
                    let maxPointBeforeCurrentInterval = -Infinity;
                    for (let interval of intervals) {
                        // We skip intervals that overlap with the current one
                        if (interval[1] < currentInterval[0]) {
                            beforeCurrentInterval.push(interval);
                            maxPointBeforeCurrentInterval = Math.max(
                                maxPointBeforeCurrentInterval,
                                interval[1]
                            );
                        } else if (interval[0] > currentInterval[1]) {
                            afterCurrentInterval.push(interval);
                            minPointAfterCurrentInterval = Math.min(
                                minPointAfterCurrentInterval,
                                interval[0]
                            );
                        }
                    }
                    // Align between two items
                    let currentSize = currentInterval[1] - currentInterval[0];
                    if (
                        isFinite(minPointAfterCurrentInterval) &&
                        isFinite(maxPointBeforeCurrentInterval) &&
                        // Ensure that the item can fit between the two
                        minPointAfterCurrentInterval -
                            maxPointBeforeCurrentInterval >
                            currentSize
                    ) {
                        let alignmentPoint =
                            (maxPointBeforeCurrentInterval +
                                minPointAfterCurrentInterval) /
                                2 -
                            currentSize / 2;
                        let distance = Math.abs(
                            currentInterval[0] - alignmentPoint
                        );
                        if (
                            distance <= threshold &&
                            (currentSnapPoint == null ||
                                distance < currentSnapPoint.distance)
                        ) {
                            let midPoint1 =
                                (alignmentPoint +
                                    maxPointBeforeCurrentInterval) /
                                2;
                            let midPoint2 =
                                (alignmentPoint +
                                    currentSize +
                                    minPointAfterCurrentInterval) /
                                2;
                            currentSnapPoint = {
                                spacing:
                                    alignmentPoint -
                                    maxPointBeforeCurrentInterval,
                                distance: distance,
                                alignmentPoint: alignmentPoint,
                                point: point,
                                snapDirection: snapDirection,
                                pairs: [
                                    [
                                        [midPoint1, midPoint1],
                                        [midPoint1, midPoint1],
                                    ],
                                    [
                                        [midPoint2, midPoint2],
                                        [midPoint2, midPoint2],
                                    ],
                                ],
                            };
                        }
                    }

                    // Align before or after items
                    const sets = [
                        {
                            intervals: beforeCurrentInterval,
                            calculateAlignmentPoint: (spacing: number) =>
                                maxPointBeforeCurrentInterval + spacing,
                            calculateMidPoint: (spacing: number) =>
                                maxPointBeforeCurrentInterval + spacing / 2,
                        },
                        {
                            maxOrMinPoint: minPointAfterCurrentInterval,
                            intervals: afterCurrentInterval,
                            calculateAlignmentPoint: (spacing: number) =>
                                minPointAfterCurrentInterval -
                                spacing -
                                (currentInterval[1] - currentInterval[0]),
                            calculateMidPoint: (spacing: number) =>
                                minPointAfterCurrentInterval - spacing / 2,
                        },
                    ];
                    for (let {
                        intervals,
                        calculateAlignmentPoint,
                        calculateMidPoint,
                    } of sets) {
                        for (let i = 0; i < intervals.length - 1; ++i) {
                            for (let j = i + 1; j < intervals.length; ++j) {
                                let spacing = distanceBetweenIntervals(
                                    intervals[i],
                                    intervals[j]
                                );
                                if (spacing > 0) {
                                    let alignmentPoint = calculateAlignmentPoint(
                                        spacing
                                    );
                                    let distance = Math.abs(
                                        currentInterval[0] - alignmentPoint
                                    );
                                    if (distance < threshold) {
                                        if (
                                            currentSnapPoint == null ||
                                            distance < currentSnapPoint.distance
                                        ) {
                                            let midPoint = calculateMidPoint(
                                                spacing
                                            );
                                            currentSnapPoint = {
                                                spacing: spacing,
                                                distance: distance,
                                                alignmentPoint: alignmentPoint,
                                                point: point,
                                                snapDirection: snapDirection,
                                                pairs: [
                                                    [
                                                        [midPoint, midPoint],
                                                        [midPoint, midPoint],
                                                    ],
                                                    [
                                                        intervals[i],
                                                        intervals[j],
                                                    ],
                                                ],
                                            };
                                        } else if (
                                            currentSnapPoint.distance ===
                                                distance &&
                                            currentSnapPoint.alignmentPoint ===
                                                alignmentPoint &&
                                            currentSnapPoint.snapDirection ===
                                                snapDirection
                                        ) {
                                            currentSnapPoint.pairs.push([
                                                intervals[i],
                                                intervals[j],
                                            ]);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (currentSnapPoint != null) {
                nearestPoints.push({
                    point: currentSnapPoint.alignmentPoint,
                    snapDirection:
                        currentSnapPoint.snapDirection === SnapDirection.X
                            ? SnapDirection.Y
                            : SnapDirection.X,
                    snapPosition: SnapPosition.Start,
                });
                for (let pair of currentSnapPoint.pairs) {
                    let newDiv = document.createElement("div");
                    newDiv.style.zIndex = "999999999";

                    newDiv.setAttribute("tag", "snapLine");
                    newDiv.style.backgroundColor = "#39F";
                    newDiv.style.color = "white";
                    newDiv.style.position = "absolute";
                    newDiv.style.borderRadius = "3px";
                    newDiv.style.transform = "translate(-50%, -50%)";
                    newDiv.style.fontFamily = "Roboto";
                    newDiv.style.fontSize = "14px";
                    newDiv.style.paddingLeft = "2px";
                    newDiv.style.paddingRight = "2px";
                    newDiv.innerHTML = `${Math.round(
                        currentSnapPoint.spacing / this.props.scale
                    )}`;

                    let middle = middleBetweenIntervals(pair[0], pair[1]);

                    if (currentSnapPoint.snapDirection === SnapDirection.X) {
                        newDiv.style.top = `${currentSnapPoint.point}px`;
                        newDiv.style.left = `${middle}px`;
                    } else {
                        newDiv.style.top = `${middle}px`;
                        newDiv.style.left = `${currentSnapPoint.point}px`;
                    }
                    this.layerRef.current?.appendChild(newDiv);
                }
            }
            nearestPoints.sort((a, b) => a.point - b.point);
            return nearestPoints;
        }
        private deleteSnapLine(): void {
            if (this.layerRef.current != null) {
                let snapLines = [];
                for (
                    let i = 0;
                    i < this.layerRef.current.children.length;
                    i++
                ) {
                    let child = this.layerRef.current.children[i];
                    if (child.getAttribute("tag") === "snapLine") {
                        snapLines.push(child);
                    }
                }

                snapLines.forEach((snapLine) => {
                    snapLine.remove();
                });
            }
        }
        private rebuildSnapLine(
            currentPos: { x: number; y: number; width: number; height: number },
            metadata: ItemMetadata | ItemMetadata[]
        ): {
            point: number;
            snapDirection: SnapDirection;
            snapPosition: SnapPosition;
        }[] {
            this.deleteSnapLine();
            return this.buildSnapLine(currentPos, metadata);
        }

        private startEditTag(tagId: number): void {
            this.setState({ editedTagNodeId: tagId });
        }
        private buildSimpleEditOverlay(): JSX.Element | null {
            if (!this.state.editedNode) return null;
            if (isSimpleSpreadSheetInput(this.state.editedNode)) return null;
            if (this.state.editedNode.gridId != null) return null;
            let originalNode = this.props.canvasTreeStore.canvasTreeState.get(
                this.state.editedNode.id
            );
            if (originalNode == null) return null;
            const { canvasViewMode } = this.props.canvasTreeStore;
            let nodeSize = getNodeSize(originalNode, canvasViewMode).nodeSize[
                canvasViewMode as CanvasViewMode
            ];
            let nodePosition = originalNode.nodePosition[canvasViewMode] ?? 0;

            let buttonStyle = {
                containerStyle: {},
                textStyle: {},
            };
            let inputFieldStyle = {
                textStyle: {},
                containerStyle: {},
            };

            if (
                isSubmitButton(this.state.editedNode) &&
                !this.state.editedNode.isTextLink
            ) {
                buttonStyle = getButtonStyle(
                    this.state.editedNode,
                    this.props.scale
                );
            }
            let isMDInputFieldTheme = false;
            let isMDFilledInputField = false;
            let isMDOutlinedInputField = false;
            if (isInput(this.state.editedNode)) {
                inputFieldStyle = getInputFieldStyle(
                    this.state.editedNode as CanvasInput,
                    true,
                    false
                );
                isMDInputFieldTheme =
                    (this.state.editedNode as CanvasInput).inputFieldStyle ===
                        InputFieldStyle.MDFilled ||
                    (this.state.editedNode as CanvasInput).inputFieldStyle ===
                        InputFieldStyle.MDOutlined;
                isMDFilledInputField =
                    (this.state.editedNode as CanvasInput).inputFieldStyle ===
                    InputFieldStyle.MDFilled;
                isMDOutlinedInputField =
                    (this.state.editedNode as CanvasInput).inputFieldStyle ===
                    InputFieldStyle.MDOutlined;
            }
            return (
                <div
                    tabIndex={0}
                    style={{
                        borderRadius: "5px",
                        border: isMDInputFieldTheme ? "none" : "1px solid #39F",
                        background:
                            this.state.editedNode.fillColor ??
                            mainStyle.getPropertyValue(
                                "--slide-flowchart-background-color"
                            ),
                        //transform: `scale(${this.props.scale}, ${this.props.scale})`,
                        outline: "none",
                        position: "absolute",
                        zIndex: 998,
                        top: nodePosition.y * this.props.scale,
                        left: nodePosition.x * this.props.scale,
                        minWidth: nodeSize.width * this.props.scale,
                        height: nodeSize.height * this.props.scale,
                        display: "flex",
                        ...buttonStyle.containerStyle,
                    }}
                    onKeyDown={(evt) => {
                        if (
                            (evt.key === "Enter" || evt.key === "Escape") &&
                            !evt.shiftKey
                        ) {
                            evt.preventDefault();

                            this.clearEditing();
                        }
                    }}
                >
                    {isBox(this.state.editedNode) && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {this.buildSimpleEditItemAutosize(
                                "name",
                                "ADD TEXT",
                                {},
                                true
                            )}
                            <div className="my-row">
                                {this.buildSimpleEditItemAutosize(
                                    "subtitle",
                                    "SUBTITLE",
                                    {},
                                    true
                                )}
                                {this.buildSimpleEditItemAutosize(
                                    "leftUnit",
                                    "UNIT",
                                    {},
                                    true
                                )}
                                {this.buildSimpleEditItemAutosize(
                                    "metric",
                                    "OUTPUT",
                                    {},
                                    true
                                )}
                                {this.buildSimpleEditItemAutosize(
                                    "unit",
                                    "UNIT",
                                    {},
                                    true
                                )}
                            </div>
                        </div>
                    )}
                    {((isInput(this.state.editedNode) &&
                        !isDateFormat(this.state.editedNode.format)) ||
                        isSubmitButton(this.state.editedNode)) && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "100%",
                                height: "100%",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                backgroundColor: isMDFilledInputField
                                    ? "rgb(231, 231, 231)"
                                    : "",
                                border: isMDOutlinedInputField
                                    ? "1px solid #3399ff"
                                    : "",
                                borderRadius: isMDOutlinedInputField ? 4 : 0,
                            }}
                        >
                            {this.buildSimpleEditItem(
                                "metric",
                                "",
                                {
                                    ...buttonStyle.textStyle,
                                    textAlign: "left",
                                    paddingLeft: "5px",
                                    ...inputFieldStyle.textStyle,
                                },
                                false
                            )}
                        </div>
                    )}
                    {isInput(this.state.editedNode) &&
                        isDateFormat(this.state.editedNode.format) && (
                            <div
                                className="flex-simple-column"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <DateInputItem
                                    scale={this.props.scale}
                                    currentSimpleEdit={this.currentSimpleEdit}
                                    editedNode={this.state.editedNode}
                                    onKeepSimpleEditChanges={
                                        this.keepSimpleEditChanges
                                    }
                                    onClearEditing={this.clearEditing}
                                    onChangeEditedNode={this.changeEditedNode}
                                    canvasViewMode={canvasViewMode}
                                />
                            </div>
                        )}
                    {isSlider(this.state.editedNode) && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div className="my-row">
                                {this.buildSimpleEditItemAutosize(
                                    ["minOutput", "metric"],
                                    "MIN",
                                    {},
                                    false
                                )}
                                {this.buildSimpleEditItemAutosize(
                                    ["maxOutput", "metric"],
                                    "MAX",
                                    {},
                                    false
                                )}
                            </div>
                        </div>
                    )}
                </div>
            );
        }
        private trackNewPerformance(element: string): void {
            this.performance = new Date();
            this.performanceElement = element;
        }

        private insertText(text: string, type: string): void {
            this.keepSimpleEditChanges();
            this.clearEditing();
            let tagId: number | undefined;
            if (type === "text/html") {
                tagId = this.props.canvasTreeStore.addTextBoxAction({
                    html: text,
                });
            } else {
                tagId = this.props.canvasTreeStore.addTextBoxAction({
                    text: text,
                });
            }
            if (tagId != null) {
                this.startEditTag(tagId);
                this.safeSelectByMetadata({
                    type: "canvasTreeState",
                    id: tagId,
                    groupId: null,
                });
            }
        }

        private copySelection(): void {
            if (this.state.spreadSheetSelectionArea != null) {
                let spreadSheetGrid = this.props.canvasTreeStore.gridsState.get(
                    this.state.spreadSheetSelectionArea.gridId
                );
                if (!spreadSheetGrid) return;
                if (this.props.live && !spreadSheetGrid.unlocked) return;
                let table = this.props.canvasTreeStore.getTableBySelectionArea(
                    this.state.spreadSheetSelectionArea
                );
                navigator.clipboard.writeText(table);
            } else if (
                this.state.selectedMetadata != null &&
                this.state.selectedMetadata.length > 0
            ) {
                if (this.props.live) return;
                let metadata = this.state.selectedMetadata;
                let serializedData = this.props.canvasTreeStore.serializeMetadata(
                    metadata as ItemMetadata[]
                );
                let json = {
                    type: "canvas/json",
                    data: serializedData,
                };
                navigator.clipboard.writeText(JSON.stringify(json));
            }
        }
        private pasteTable(table: (string | undefined)[][]): void {
            if (!this.state.spreadSheetSelectionArea) return;
            let spreadSheetGrid = this.props.canvasTreeStore.gridsState.get(
                this.state.spreadSheetSelectionArea.gridId
            );
            if (!spreadSheetGrid) return;
            if (this.props.live && !spreadSheetGrid.unlocked) return;
            let lastChangedNode: CanvasEditedElement | null = null;
            const rows = spreadSheetGrid.rows;
            const cols = spreadSheetGrid.cols;
            const row = this.state.spreadSheetSelectionArea.top;
            const col = this.state.spreadSheetSelectionArea.left;
            const lastRow = this.state.spreadSheetSelectionArea.bottom;
            const lastCol = this.state.spreadSheetSelectionArea.right;

            let additionalRows = Math.max(row + table.length - rows, 0);
            let additionalCols = Math.max(col + table[0].length - cols, 0);

            if (
                spreadSheetGrid.fullSpreadSheetBackendOutputOptions != null &&
                (additionalRows > 0 || additionalCols > 0)
            ) {
                return;
            }

            if (additionalRows > 0) {
                for (let i = 0; i < additionalRows; ++i) {
                    this.props.canvasTreeStore.insertGridRowAction(
                        spreadSheetGrid.id,
                        rows + i
                    );
                }
            }
            if (additionalCols > 0) {
                for (let j = 0; j < additionalCols; ++j) {
                    this.props.canvasTreeStore.insertGridColumnAction(
                        spreadSheetGrid.id,
                        cols + j
                    );
                }
            }
            let nodes = this.props.canvasTreeStore.getSortedGridNodes(
                spreadSheetGrid.id
            );
            let changes: { [nodeId: number]: Partial<CanvasNode> } = {};

            if (
                (row !== lastRow || col !== lastCol) &&
                table[0].length === 1 &&
                table.length === 1
            ) {
                // Paste one value to multiple cells
                for (let i = row; i <= lastRow; i++) {
                    for (let j = col; j <= lastCol; j++) {
                        let node = nodes[i * (cols + additionalCols) + j];
                        changes[node.id] = {
                            metric: table[0][0] ?? "",
                        };
                        lastChangedNode = node;
                    }
                }
            } else {
                // paste multiple values to multiple cells
                for (let i = 0; i < table.length; ++i) {
                    for (let j = 0; j < table[0].length; ++j) {
                        if (row + i < 0) {
                            this.props.canvasTreeStore.updateGridHeaderNodeAction(
                                spreadSheetGrid.id,
                                col + j,
                                {
                                    text: table[i][j] ?? "",
                                }
                            );
                        } else if (col + j < 0) {
                            this.props.canvasTreeStore.updateGridLeftHeaderNodeAction(
                                spreadSheetGrid.id,
                                row + i,
                                {
                                    text: table[i][j] ?? "",
                                }
                            );
                        } else {
                            let node =
                                nodes[
                                    (row + i) * (cols + additionalCols) +
                                        (col + j)
                                ];
                            changes[node.id] = {
                                metric: table[i][j] ?? "",
                            };
                            lastChangedNode = node;
                        }
                    }
                }
            }
            if (Object.keys(changes).length > 0) {
                this.props.canvasTreeStore.updateNodesAction(changes, true);
            }
            let selectionArea: CanvasSelectionArea = {
                left: col,
                right: col + table[0].length - 1,
                top: row,
                bottom: row + table.length - 1,
                gridId: spreadSheetGrid.id,
            };
            this.updateSpreadSheetSelectionArea(
                selectionArea,
                this.props.canWrite &&
                    (!this.props.live || (spreadSheetGrid.unlocked ?? false))
            );
            if (lastChangedNode) {
                this.changeEditedNode({
                    ...lastChangedNode,
                    metric: changes[lastChangedNode.id].metric ?? "",
                });
            } else {
                this.clearEditing(true);
            }
        }

        private async pasteSelectionFromEvent(
            evt: ClipboardEvent
        ): Promise<void> {
            let table = null;
            if (this.state.spreadSheetSelectionArea != null) {
                table = parseSheetData(
                    evt.clipboardData?.getData("text/html") ||
                        evt.clipboardData?.getData("text/plain") ||
                        ""
                );
                if (evt.clipboardData?.getData("text/plain") && !table) {
                    table = [[evt.clipboardData.getData("text/plain")]];
                }
            }
            if (table != null) {
                evt.stopPropagation();
                evt.preventDefault();
                this.pasteTable(table);
            } else {
                if (this.props.live) return;
                retrieveImageFromClipboardAsBase64(
                    evt,
                    async (
                        imageDataBase64: string | undefined,
                        naturalWidth: number | undefined,
                        naturalHeight: number | undefined
                    ) => {
                        if (imageDataBase64) {
                            this.props.canvasTreeStore.addBackgroundAction(
                                imageDataBase64,
                                false,
                                naturalWidth ?? 0,
                                naturalHeight ?? 0,
                                (id: string | number) => {
                                    this.keepSimpleEditChanges();
                                    this.clearEditing();
                                    setTimeout(() => {
                                        this.safeSelectByMetadata({
                                            type: "backgroundsState",
                                            id: id,
                                            groupId: null,
                                        });
                                    }, 0);
                                }
                            );
                        } else {
                            try {
                                let type = "text/html";
                                let text = evt.clipboardData!.getData(type);
                                if (text.length === 0) {
                                    type = "text/plain";
                                    text =
                                        evt.clipboardData
                                            ?.getData(type)
                                            .replace(/<\/?[^>]+(>|$)/g, "") ??
                                        "";
                                }

                                let isCanvas = false;
                                let data;
                                try {
                                    let json = JSON.parse(text);
                                    let jsonType = json["type"];
                                    if (jsonType === "canvas/json") {
                                        isCanvas = true;
                                        data = json["data"];
                                    }
                                } catch (exception) {}
                                if (!isCanvas) {
                                    this.insertText(text, type);
                                } else {
                                    this.keepSimpleEditChanges();
                                    this.clearEditing();
                                    this.props.canvasTreeStore.mergeCanvasAction(
                                        data,
                                        (pastedMetadata) => {
                                            setTimeout(() => {
                                                this.safeSelectByMetadataList(
                                                    pastedMetadata
                                                );
                                            }, 0);
                                        }
                                    );
                                }
                            } catch (exception) {
                                console.log(exception);
                            }
                        }
                    }
                );
            }
        }
        private addImageByUrl(
            url: string,
            naturalWidth: number,
            naturalHeight: number
        ): void {
            this.props.canvasTreeStore.addBackgroundAction(
                url,
                false,
                naturalWidth,
                naturalHeight,
                (id: string | number) => {
                    this.keepSimpleEditChanges();
                    this.clearEditing();
                    setTimeout(() => {
                        this.safeSelectByMetadata({
                            type: "backgroundsState",
                            id: id,
                            groupId: null,
                        });
                    }, 0);
                }
            );
        }
        private addTextByPreset(preset: CanvasTextBox): void {
            this.keepSimpleEditChanges();
            this.clearEditing();
            let tagId = this.props.canvasTreeStore.addTextBoxAction(
                preset
            ) as number;
            setTimeout(() => {
                this.safeSelectByMetadata({
                    type: "canvasTreeState",
                    id: tagId,
                    groupId: null,
                });
            }, 0);
        }
        private async pasteSelection(fullResolution = false): Promise<void> {
            let items: any = [];
            if (this.state.clipboardReadEnabled) {
                items = await navigator.clipboard.read();
            }
            retrieveImageFromClipboardAsBase64V2(
                items,
                async (
                    imageDataBase64: string | undefined,
                    naturalWidth: number | undefined,
                    naturalHeight: number | undefined
                ) => {
                    if (imageDataBase64) {
                        this.props.canvasTreeStore.addBackgroundAction(
                            imageDataBase64,
                            false,
                            naturalWidth ?? 0,
                            naturalHeight ?? 0,
                            (id: string | number) => {
                                this.keepSimpleEditChanges();
                                this.clearEditing();
                                setTimeout(() => {
                                    this.safeSelectByMetadata({
                                        type: "backgroundsState",
                                        id: id,
                                        groupId: null,
                                    });
                                }, 0);
                            }
                        );
                    } else {
                        try {
                            let text = "";
                            let type = "";
                            try {
                                type = "text/html";
                                let textBlob = await items[0].getType(type);
                                text = await new Response(textBlob).text();
                            } catch (exception) {
                                // We don't need to log this exception since
                                // sometimes the type just isn't text/html
                                // console.log(exception);
                            }
                            if (text.length === 0) {
                                type = "text/plain";
                                let textBlob = await items[0].getType(type);
                                text = await new Response(textBlob).text();
                            }
                            if (this.state.spreadSheetSelectionArea != null) {
                                let table = parseSheetData(text);
                                if (table != null) {
                                    this.pasteTable(table);
                                    return;
                                }
                            }
                            let isCanvas = false;
                            let data;
                            try {
                                let json = JSON.parse(text);
                                let jsonType = json["type"];
                                if (jsonType === "canvas/json") {
                                    isCanvas = true;
                                    data = json["data"];
                                }
                            } catch (exception) {}
                            if (!isCanvas) {
                                this.insertText(text, type);
                            } else {
                                this.keepSimpleEditChanges();
                                this.clearEditing();
                                this.props.canvasTreeStore.mergeCanvasAction(
                                    data,
                                    (pastedMetadata) => {
                                        setTimeout(() => {
                                            this.safeSelectByMetadataList(
                                                pastedMetadata
                                            );
                                        }, 0);
                                    }
                                );
                            }
                        } catch (exception) {
                            console.log(exception);
                        }
                    }
                },
                "",
                fullResolution
            );
        }
        private deleteSelection(): void {
            if (this.state.spreadSheetSelectionArea) {
                let spreadSheetGrid = this.props.canvasTreeStore.gridsState.get(
                    this.state.spreadSheetSelectionArea.gridId
                );
                if (!spreadSheetGrid) return;
                if (this.props.live && !spreadSheetGrid.unlocked) return;
                this.props.canvasTreeStore.clearBySelectionAreaAction(
                    this.state.spreadSheetSelectionArea
                );
                if (this.state.editedNode)
                    this.changeEditedNode({
                        ...this.state.editedNode,
                        metric: "",
                    });
                return;
            }
            if (this.state.selectedMetadata) {
                let metadata = this.state.selectedMetadata;
                this.deleteSelectionByMetadata(metadata);
            }
            setTimeout(() => {
                if (this.rootRef.current) {
                    this.rootRef.current.focus();
                }
            }, 100);
        }
        private deleteSelectionByMetadata(metadata: ItemMetadata[]): void {
            // Check if the metadata includes surveys
            let dataScopeIds: (string | number)[] = [];
            for (let item of metadata) {
                if (item.type === "canvasTreeState") {
                    let node = this.props.canvasTreeStore.canvasTreeState.get(
                        item.id as number
                    )!;
                    if (
                        isSurvey(node) &&
                        node.backendOutput.tableOption?.data_table_idx != null
                    ) {
                        dataScopeIds.push(
                            node.backendOutput.tableOption.data_table_idx
                        );
                    }
                }
            }
            if (dataScopeIds.length === 0) {
                this.props.canvasTreeStore.deleteByMetadataAction(metadata);
                this.clearSelector();
                if (this.rootRef.current != null)
                    this.rootRef.current.focus({ preventScroll: true });
            } else {
                this.showDeleteElementWithDatasetPopup(
                    async () => {
                        for (let dataScopeId of dataScopeIds) {
                            try {
                                await deleteDataSet(
                                    dataScopeId,
                                    true,
                                    this.props.currentModuleId
                                );
                            } catch (error) {
                                console.log(error);
                                return;
                            }
                        }
                        this.props.canvasTreeStore.deleteByMetadataAction(
                            metadata
                        );
                        this.clearSelector();
                        if (this.rootRef.current != null)
                            this.rootRef.current.focus({ preventScroll: true });
                    },
                    () => {
                        this.props.canvasTreeStore.deleteByMetadataAction(
                            metadata
                        );
                        this.clearSelector();
                        if (this.rootRef.current != null)
                            this.rootRef.current.focus({ preventScroll: true });
                    },
                    "survey"
                );
            }
        }
        private cutSelection(): void {
            this.copySelection();
            this.deleteSelection();
        }
        private groupSelection(group: boolean): void {
            if (this.state.selectedMetadata) {
                let groupId = this.props.canvasTreeStore.groupByMetadataAction(
                    this.state.selectedMetadata,
                    group
                );
                if (groupId != null) {
                    let selectedMetadata = this.state.selectedMetadata.map(
                        (metadata) => ({
                            ...metadata,
                            groupId: groupId,
                        })
                    );
                    this.setState({ selectedMetadata: selectedMetadata });
                } else {
                    this.clearSelector();
                }
            }
        }
        private addNewItem(
            itemInitializer: {
                type: ItemInitializer;
                options?: any;
            },
            position?: {
                x: number;
                y: number;
                nodePosition?: NodePosition;
            }
        ): string | number | undefined {
            if (itemInitializer.type !== ItemInitializer.Dashboard)
                this.props.onDashboardEditMenuIsOpened(false);

            let alignPosition = true;

            if (!position) {
                if (
                    this.state.selectedMetadata != null &&
                    this.state.selectedMetadata.length > 0
                ) {
                    let maxCommonRect = this.props.canvasTreeStore.maxCommonRectByMetadataList(
                        this.state.selectedMetadata
                    );
                    const xyPosition = {
                        x: maxCommonRect.x + 5 * this.props.scale,
                        y: maxCommonRect.y + 5 * this.props.scale,
                    };
                    position = {
                        ...xyPosition,
                        nodePosition: {
                            desktop: xyPosition,
                            mobile: xyPosition,
                        },
                    };
                    alignPosition = false;
                }
            }

            let itemMetadata = addNewItemUtil(
                itemInitializer,
                this.props.canvasTreeStore,
                position,
                alignPosition
            );
            if (itemInitializer.type === ItemInitializer.Dashboard) {
                this.props.onDashboardEditMenuIsOpened(true);
            }
            if (itemMetadata != null)
                setTimeout(() => {
                    this.clearEditing();
                    this.safeSelectByMetadata(itemMetadata!);
                }, 0);

            return itemMetadata?.id;
        }
        private addNewImages(
            images: {
                backgroundUrl: string;
                naturalWidth: number;
                naturalHeight: number;
            }[],
            position: {
                x: number;
                y: number;
            }
        ): void {
            let scale = this.props.canvasTreeStore.scale;
            let layerRect = this.props.canvasTreeStore
                .layerRect(scale, this.props.pages)
                .get();
            position = {
                x: position.x - layerRect.x * scale,
                y: position.y - layerRect.y * scale,
            };
            position.x = position.x / scale;
            position.y = position.y / scale;
            this.props.canvasTreeStore.addBackgroundsAction(
                images.map((imageInfo) => ({
                    ...imageInfo,
                    ...position,
                })),
                (ids: number[]) => {
                    this.keepSimpleEditChanges();
                    this.clearEditing();
                    setTimeout(() => {
                        let metadataList: ItemMetadata[] = ids.map((id) => ({
                            type: "backgroundsState",
                            id: id,
                            groupId: null,
                        }));
                        this.safeSelectByMetadataList(metadataList);
                    }, 0);
                }
            );
        }
        private async addNewPinItem(
            evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
            layerRect: {
                x: number;
                y: number;
            }
        ): Promise<void> {
            let position = {
                x: evt.clientX - layerRect.x * this.props.scale,
                y:
                    evt.clientY -
                    headerBarHeight -
                    layerRect.y * this.props.scale,
            };
            position.x = position.x / this.props.scale;
            position.y = position.y / this.props.scale;
            const { canvasId } = this.props.canvasTreeStore;
            if (!canvasId) return;
            let pinId = await PinStore(canvasId).addPin(position);
            PinInformationStore.setNewPinId(pinId);
            PinInformationStore.setExpandedPinId(pinId);
        }

        private keepRootFocus(): void {
            this.clearSelector();
            setTimeout(() => {
                if (this.rootRef.current != null)
                    this.rootRef.current.focus({
                        preventScroll: true,
                    });
            }, 0);
        }

        private rootOnWheel(e: WheelEvent): void {
            if (!this.props.disableZoomWheel && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                let delta = e.deltaY > 0 ? -0.1 : 0.1;
                let scale = this.props.scale + delta;
                scale = Math.max(0.1, scale);
                this.props.onZoomChange(scale);
            }
        }

        private showSwitcher(): void {
            this.setState({ switcherShown: true });
            let panel = this.viewSwitcherWrapper.current;
            if (panel != null)
                panel.style.maxHeight = panel.scrollHeight + "px";
        }

        private hideSwitcher(): void {
            this.setState({ switcherShown: false });
            if (this.viewSwitcherWrapper.current != null)
                this.viewSwitcherWrapper.current.style.maxHeight = "0px";
        }

        public render(): JSX.Element {
            let canvasSize = this.props.canvasTreeStore
                .canvasSize(this.props.scale)
                .get();
            let { width, height } = canvasSize;
            let contentEditable =
                // !clipboardReadEnabled &&
                !this.props.captureCanvas && !this.isEditing() && !isPWA();
            let slideRect = this.props.canvasTreeStore.slideRect();
            if (this.props.live) {
                height = slideRect.height * this.props.scale + 5;
            }
            let collapsed = false;
            if (this.props.showPageBar && this.props.pageBarInfo != null) {
                let accordionFormat =
                    this.props.canvasTreeStore.canvasViewMode === "mobile"
                        ? this.props.pageBarInfo.accordionFormat?.mobile
                        : this.props.pageBarInfo.accordionFormat?.desktop;
                if (accordionFormat) {
                    let padding = this.props.live
                        ? 0
                        : verticalSectionBarItemSizePadding;
                    height +=
                        (verticalSectionBarItemSize + padding) *
                        (this.props.pages?.length ?? 0) *
                        this.props.scale;
                    collapsed =
                        this.props.canvasTreeStore.collapsed && accordionFormat;
                }
            }
            let layerRect = this.props.canvasTreeStore
                .layerRect(
                    this.props.scale,
                    this.props.pages,
                    this.props.pageBarInfo
                )
                .get();

            let containsGroup =
                this.state.selectedMetadata != null &&
                this.state.selectedMetadata.length > 1 &&
                this.state.selectedMetadata.find(
                    (item) => item.groupId != null
                ) != null;

            if (!this.props.live) {
                const slideWidth = slideRect.width * this.props.scale;
                let sideOffset = 35;
                if (slideWidth > getWidth() - sheetRibbonWidth) {
                    width =
                        slideWidth +
                        sheetRibbonWidth +
                        sideOffset * this.props.scale * 2;
                    layerRect.x += sideOffset;
                } else {
                    width = getWidth();
                }
            }

            const isSingleton = () => {
                let node;
                if (this.state.selectedMetadata)
                    for (const elemet of this.state.selectedMetadata) {
                        if (elemet.id && elemet.type === "canvasTreeState") {
                            node = this.props.canvasTreeStore.canvasTreeState.get(
                                elemet.id as number
                            );
                            if (node?.canvasType === CanvasType.Survey)
                                return true;
                        }
                    }

                return false;
            };

            return (
                <ErrorBoundary
                    key={this.props.canvasTreeStore.revisionNumber}
                    customErrorMessage={
                        "Something wrong with this sheet. Please create new or load another."
                    }
                >
                    {!this.props.live && (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                padding: "0 0 8px 0",
                                position: "absolute",
                                top: 60,
                                zIndex: 3,
                            }}
                        >
                            <div className={styles.canvasViewSwitcherWrapper}>
                                <div
                                    style={{
                                        width: `calc(100vw - ${sheetRibbonWidth}px)`,
                                    }}
                                >
                                    <button
                                        className={cx(
                                            styles.canvasViewSwitcherAccordeon,
                                            {
                                                active: this.state
                                                    .switcherShown,
                                            }
                                        )}
                                        onClick={() => {
                                            if (this.state.switcherShown) {
                                                this.hideSwitcher();
                                            } else {
                                                this.showSwitcher();
                                            }
                                            this.clearSelector();
                                        }}
                                    ></button>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: `calc(100vw - ${sheetRibbonWidth}px)`,
                                        }}
                                    >
                                        <div
                                            ref={this.viewSwitcherWrapper}
                                            className={
                                                styles.canvasViewSwitcherPanel
                                            }
                                            onMouseLeave={() => {
                                                this.hideSwitcher();
                                                this.clearSelector();
                                            }}
                                        >
                                            <button
                                                style={{
                                                    border: "none",
                                                    backgroundColor:
                                                        "transparent",
                                                }}
                                                className={
                                                    this.props.canvasTreeStore
                                                        .canvasViewMode ===
                                                    "desktop"
                                                        ? styles.activeViewMode
                                                        : ""
                                                }
                                                onClick={() => {
                                                    this.props.switchCanvasView(
                                                        "desktop"
                                                    );
                                                }}
                                            >
                                                <DesktopViewIcon />
                                            </button>
                                            <button
                                                style={{
                                                    border: "none",
                                                    marginLeft: 8,
                                                    backgroundColor:
                                                        "transparent",
                                                }}
                                                className={
                                                    this.props.canvasTreeStore
                                                        .canvasViewMode ===
                                                    "mobile"
                                                        ? styles.activeViewMode
                                                        : ""
                                                }
                                                onClick={() =>
                                                    this.props.switchCanvasView(
                                                        "mobile"
                                                    )
                                                }
                                            >
                                                <PhoneViewIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <ListOfHiddenElements
                                    canvasTreeStore={this.props.canvasTreeStore}
                                />
                            </div>
                        </div>
                    )}
                    <div
                        style={{
                            position: "relative",
                        }}
                    >
                       {(this.props.live) &&
                        <div
                            style={{
                                position: "absolute",
                                zIndex: 1
                            }}
                        >
                            {!collapsed && (
                                <DropArea
                                    onAddNewItem={this.addNewItem}
                                    onAddNewImages={this.addNewImages}
                                >
                                    <div
                                        ref={this.stageRef}
                                        onMouseDown={(evt) => {
                                            if (this.props.pinInitializer) {
                                                this.addNewPinItem(
                                                    evt,
                                                    layerRect
                                                );
                                                return;
                                            }

                                            if (
                                                evt.target ===
                                                this.stageRef.current ||
                                                evt.target ===
                                                this.backgroundRootRef
                                                    .current ||
                                                evt.target ===
                                                this.slideRootRef
                                                    .current
                                            ) {
                                                if (
                                                    !this.state
                                                        .spreadSheetSelectionArea
                                                )
                                                    this.keepSimpleEditChanges();
                                                // Set timeout here to make posible for inputs catch onBlur event
                                                // Otherwise onBlur event in spreadsheet cell don't fire
                                                setTimeout(() => {
                                                    this.clearEditing();
                                                }, 0);
                                                this.props.onDashboardEditMenuIsOpened?.(
                                                    false
                                                );
                                            }
                                        }}
                                        onContextMenu={async (evt) => {
                                            evt.preventDefault();

                                            let items: any = [];
                                            if (
                                                this.state
                                                    .clipboardReadEnabled
                                            ) {
                                                items = await navigator.clipboard.read();
                                            }
                                            let clipboardHasImage = false;
                                            for (
                                                let i = 0;
                                                i < items.length;
                                                i++
                                            ) {
                                                const {
                                                    hasImage,
                                                } = checkIfClipboardItemIsImage(
                                                    items[i]
                                                );
                                                if (hasImage)
                                                    clipboardHasImage = hasImage;
                                            }
                                            let contextMenuInfo = {
                                                left: evt.clientX,
                                                top:
                                                    evt.clientY -
                                                    headerBarHeight,
                                                showSelectorOptions: false,
                                                hasImage: clipboardHasImage,
                                            };
                                            this.setState({
                                                contextMenuInfo: contextMenuInfo,
                                            });
                                        }}
                                        onTouchStart={(evt) => {
                                            const touch = evt.touches[0];
                                            startLongPressTimer(() => {
                                                let contextMenuInfo = {
                                                    left: touch.clientX,
                                                    top:
                                                        touch.clientY -
                                                        headerBarHeight,
                                                    showSelectorOptions: false,
                                                };
                                                this.setState({
                                                    contextMenuInfo: contextMenuInfo,
                                                });
                                            });
                                        }}
                                        onTouchEnd={() => {
                                            clearLongPressTimer();
                                        }}
                                        style={{
                                            backgroundColor: "transparent",
                                            width: width,
                                            height: height,
                                        }}
                                    >
                                        <div
                                            ref={this.layerRef}
                                            style={{
                                                position: "absolute",
                                                left:
                                                    layerRect.x *
                                                    this.props.scale,
                                                top:
                                                    layerRect.y *
                                                    this.props.scale,
                                                width:
                                                    layerRect.width *
                                                    this.props.scale,
                                                height:
                                                    layerRect.height *
                                                    this.props.scale,
                                            }}
                                        >
                                            {this.props.canvasTreeStore
                                                .canvasViewMode ===
                                                "mobile" &&
                                                !this.props.live && (
                                                    <MobileViewFrame
                                                        slideRect={
                                                            slideRect
                                                        }
                                                        defaultMobileSlideHeight={
                                                            defaultMobileSlideHeight
                                                        }
                                                        scale={
                                                            this.props.scale
                                                        }
                                                    />
                                                )}
                                            <div
                                                style={{
                                                    opacity: 1,
                                                }}
                                            >
                                                <SlideBackground
                                                    slideContainerClassName={
                                                        this.props
                                                            .slideContainerClassName
                                                    }
                                                    showAsSlideShow={
                                                        this.props
                                                            .showAsSlideShow
                                                    }
                                                    canvasTreeStore={
                                                        this.props
                                                            .canvasTreeStore
                                                    }
                                                    live={this.props.live}
                                                    scale={this.props.scale}
                                                    canWrite={
                                                        this.props.canWrite
                                                    }
                                                    backgroundRootRef={
                                                        this
                                                            .backgroundRootRef
                                                    }
                                                    sharedPolicy={
                                                        this.props
                                                            .sharedPolicy
                                                    }
                                                    showDots={
                                                        this.props.showDots
                                                    }
                                                    slideRect={slideRect}
                                                    slideRootRef={
                                                        this.slideRootRef
                                                    }
                                                    moduleTitle={
                                                        this.props
                                                            .moduleTitle
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </DropArea>
                            )}
                        </div>
                       }
                        <div
                            hidden={this.props.hidden}
                            style={{
                                marginLeft: "0px",
                                position: "absolute",
                                display: "flex",
                                justifyContent: "center",
                            }}
                            onClick={() => {
                                if (this.state.contextMenuInfo) {
                                    this.setState({
                                        contextMenuInfo: undefined,
                                    });
                                }
                                // Multiple clicks on elements make the
                                // body active without firing focus event,
                                // so we need to focus canvas root here.
                                if (
                                    !this.props.live &&
                                    document.activeElement === document.body
                                ) {
                                    this.rootRef.current?.focus();
                                }
                            }}
                        >
                            <div
                                id={"canvas-root-element"}
                                contentEditable={contentEditable}
                                spellCheck="false"
                                ref={this.onRootRefChange}
                                className="canvas-root unselectable"
                                tabIndex={this.props.live ? undefined : 0}
                                style={{
                                    overflowY: "hidden",
                                    overflowX: this.props.live
                                        ? "hidden"
                                        : "auto",
                                    cursor:
                                        this.props.pinInitializer != null
                                            ? "crosshair"
                                            : "default",
                                    position: "relative",
                                    width: width,
                                    height: height,
                                    caretColor: "transparent",
                                }}  
                                onKeyDown={(evt) => {
                                    if (collapsed) return;
                                    const rootSection = this.props
                                        .rootSectionRef?.current;
                                    const root = this.rootRef.current!;
                                    let scrollX = root.scrollLeft ?? 0;
                                    let scrollY = rootSection?.scrollTop ?? 0;
                                    let ctrlDown =
                                        (evt.ctrlKey || evt.metaKey) &&
                                        !evt.altKey;
                                    let shiftDown = evt.shiftKey;
                                    switch (evt.key) {
                                        case "ArrowLeft":
                                            evt.preventDefault();

                                            if (
                                                this.state.selectedMetadata !=
                                                    null &&
                                                this.state.selectedMetadata
                                                    .length > 0
                                            ) {
                                                this.props.canvasTreeStore.shiftByMetadataAction(
                                                    this.state.selectedMetadata,
                                                    {
                                                        deltaX: ctrlDown
                                                            ? -100
                                                            : -5,
                                                        deltaY: 0,
                                                    }
                                                );
                                                setTimeout(
                                                    this.updateSelectionBounds,
                                                    0
                                                );
                                            } else {
                                                if (!this.props.live) {
                                                    scrollX -= 20;
                                                    root.scrollTo(scrollX, 0);
                                                }
                                            }
                                            break;
                                        case "ArrowRight":
                                            evt.preventDefault();

                                            if (
                                                this.state.selectedMetadata !=
                                                    null &&
                                                this.state.selectedMetadata
                                                    .length > 0
                                            ) {
                                                this.props.canvasTreeStore.shiftByMetadataAction(
                                                    this.state.selectedMetadata,
                                                    {
                                                        deltaX: ctrlDown
                                                            ? 100
                                                            : 5,
                                                        deltaY: 0,
                                                    }
                                                );
                                                setTimeout(
                                                    this.updateSelectionBounds,
                                                    0
                                                );
                                            } else {
                                                if (!this.props.live) {
                                                    scrollX += 20;
                                                    root.scrollTo(scrollX, 0);
                                                }
                                            }
                                            break;
                                        case "ArrowUp":
                                            evt.preventDefault();

                                            if (
                                                this.state.selectedMetadata !=
                                                    null &&
                                                this.state.selectedMetadata
                                                    .length > 0
                                            ) {
                                                this.props.canvasTreeStore.shiftByMetadataAction(
                                                    this.state.selectedMetadata,
                                                    {
                                                        deltaX: 0,
                                                        deltaY: ctrlDown
                                                            ? -100
                                                            : -5,
                                                    }
                                                );
                                                setTimeout(
                                                    this.updateSelectionBounds,
                                                    0
                                                );
                                            } else {
                                                scrollY -= 20;
                                                if (rootSection)
                                                    rootSection.scrollTo(
                                                        0,
                                                        scrollY
                                                    );
                                            }
                                            break;
                                        case "ArrowDown":
                                            evt.preventDefault();

                                            if (
                                                this.state.selectedMetadata !=
                                                    null &&
                                                this.state.selectedMetadata
                                                    .length > 0
                                            ) {
                                                this.props.canvasTreeStore.shiftByMetadataAction(
                                                    this.state.selectedMetadata,
                                                    {
                                                        deltaX: 0,
                                                        deltaY: ctrlDown
                                                            ? 100
                                                            : 5,
                                                    }
                                                );
                                                setTimeout(
                                                    this.updateSelectionBounds,
                                                    0
                                                );
                                            } else {
                                                scrollY += 20;
                                                if (rootSection)
                                                    rootSection.scrollTo(
                                                        0,
                                                        scrollY
                                                    );
                                            }
                                            break;
                                        case "c":
                                            evt.preventDefault();

                                            if (
                                                (this.state.selectedMetadata !=
                                                    null ||
                                                    this.state
                                                        .spreadSheetSelectionArea !=
                                                        null) &&
                                                ctrlDown &&
                                                this.props.canWrite &&
                                                !isSingleton()
                                            ) {
                                                this.copySelection();
                                            }
                                            break;
                                        case "v":
                                            if (isSingleton())
                                                evt.preventDefault();
                                            // This breaks pasting image files,
                                            // so we're propagating it to onPaste
                                            // if (
                                            //     clipboardReadEnabled &&
                                            //     ctrlDown &&
                                            //     this.props.canWrite &&
                                            //     !this.props.live
                                            // ) {
                                            //     evt.preventDefault();
                                            //
                                            //     this.pasteSelection();
                                            // }
                                            break;
                                        case "x":
                                            evt.preventDefault();

                                            if (ctrlDown) {
                                                if (
                                                    (this.state
                                                        .selectedMetadata !=
                                                        null ||
                                                        this.state
                                                            .spreadSheetSelectionArea) &&
                                                    this.props.canWrite &&
                                                    !isSingleton()
                                                ) {
                                                    this.cutSelection();
                                                } else if (
                                                    this.props.canWrite &&
                                                    !this.props.live &&
                                                    !isSingleton()
                                                ) {
                                                    this.cutSelection();
                                                }
                                            }
                                            break;
                                        case "Backspace":
                                        case "Delete":
                                            evt.preventDefault();
                                            if (
                                                this.state.selectedMetadata !=
                                                    null &&
                                                this.props.canWrite &&
                                                !this.props.live
                                            ) {
                                                this.deleteSelection();
                                                this.props.canvasTreeStore.setCanvasWasEditedSate(
                                                    true
                                                );
                                            }
                                            break;
                                        case "t":
                                            evt.preventDefault();

                                            if (
                                                this.props.canWrite &&
                                                !this.props.live
                                            ) {
                                                this.addNewItem(
                                                    {
                                                        type:
                                                            ItemInitializer.Tag,
                                                    },
                                                    undefined
                                                );
                                            }
                                            break;
                                        case "f":
                                            evt.preventDefault();

                                            if (
                                                this.props.canWrite &&
                                                !this.props.live
                                            ) {
                                                this.addNewItem(
                                                    {
                                                        type:
                                                            ItemInitializer.FlowChart,
                                                    },
                                                    undefined
                                                );
                                            }
                                            break;
                                        case "d":
                                            evt.preventDefault();

                                            if (
                                                this.props.canWrite &&
                                                !this.props.live
                                            ) {
                                                this.addNewItem({
                                                    type:
                                                        ItemInitializer.Dashboard,
                                                    options: {
                                                        version:
                                                            DashboardVersion.Second,
                                                        filterIndexInitializer: -1,
                                                    },
                                                });
                                                this.props.onOpenThemes(
                                                    true,
                                                    ThemeType.Charts
                                                );
                                            }
                                            break;
                                        case "i":
                                            evt.preventDefault();

                                            if (
                                                this.props.canWrite &&
                                                !this.props.live
                                            ) {
                                                this.addNewItem(
                                                    {
                                                        type:
                                                            ItemInitializer.Input,
                                                    },
                                                    undefined
                                                );
                                            }
                                            break;
                                        case "s":
                                            evt.preventDefault();

                                            if (
                                                this.props.canWrite &&
                                                !this.props.live
                                            ) {
                                                this.addNewItem(
                                                    {
                                                        type:
                                                            ItemInitializer.SimpleSpreadSheetGrid,
                                                    },
                                                    undefined
                                                );
                                            }
                                            break;
                                        case "u":
                                            evt.preventDefault();

                                            if (
                                                this.props.canWrite &&
                                                !this.props.live
                                            ) {
                                                this.props.onOpenBottomPortal(
                                                    PortalType.ImportData
                                                );
                                            }
                                            break;
                                        case "l":
                                        case "L":
                                            evt.preventDefault();

                                            if (
                                                this.props.canWrite &&
                                                !this.props.live
                                            ) {
                                                let subtype = evt.shiftKey
                                                    ? "ArrowShape"
                                                    : "LineShape";
                                                this.addNewItem(
                                                    {
                                                        type:
                                                            ItemInitializer.Shape,
                                                        options: {
                                                            subtype: subtype,
                                                        },
                                                    },
                                                    undefined
                                                );
                                            }
                                            break;
                                        case "q":
                                            evt.preventDefault();

                                            if (
                                                this.props.canWrite &&
                                                !this.props.live
                                            ) {
                                                this.addNewItem(
                                                    {
                                                        type:
                                                            ItemInitializer.Questionnaire,
                                                    },
                                                    undefined
                                                );
                                            }
                                            break;
                                        case "z":
                                        case "Z":
                                            evt.preventDefault();

                                            if (ctrlDown) {
                                                if (shiftDown) {
                                                    this.props.canvasTreeStore.redoAsyncAction.bothParts();
                                                } else {
                                                    this.props.canvasTreeStore.undoAsyncAction.bothParts();
                                                }
                                                this.clearSelector();
                                                this.keepRootFocus();
                                            }
                                            break;
                                        case "e":
                                            evt.preventDefault();

                                            if (!this.props.live)
                                                if (ctrlDown) {
                                                    this.props.onShowGuideDots();
                                                }
                                            break;
                                        case "+":
                                            evt.preventDefault();

                                            if (ctrlDown) {
                                                this.props.onZoomChange(
                                                    this.props.scale + 0.2
                                                );
                                            }
                                            break;
                                        case "-":
                                            evt.preventDefault();

                                            if (ctrlDown) {
                                                this.props.onZoomChange(
                                                    this.props.scale - 0.2
                                                );
                                            }
                                            break;
                                        case "F5":
                                            evt.preventDefault();
                                            window.location.reload();
                                            break;
                                        default:
                                            evt.preventDefault();
                                            break;
                                    }
                                }}
                            >
                                {!this.props.live && !collapsed && (
                                    <DropArea
                                        onAddNewItem={this.addNewItem}
                                        onAddNewImages={this.addNewImages}
                                    >
                                        <div
                                            ref={this.stageRef}
                                            onMouseDown={(evt) => {
                                                //  if (this.props.live) return;
                                                if (this.props.pinInitializer) {
                                                    this.addNewPinItem(
                                                        evt,
                                                        layerRect
                                                    );
                                                    return;
                                                }

                                                if (
                                                    evt.target ===
                                                        this.stageRef.current ||
                                                    evt.target ===
                                                        this.backgroundRootRef
                                                            .current ||
                                                    evt.target ===
                                                        this.slideRootRef
                                                            .current
                                                ) {
                                                    if (
                                                        !this.state
                                                            .spreadSheetSelectionArea
                                                    )
                                                        this.keepSimpleEditChanges();
                                                    // Set timeout here to make posible for inputs catch onBlur event
                                                    // Otherwise onBlur event in spreadsheet cell don't fire
                                                    setTimeout(() => {
                                                        this.clearEditing();
                                                    }, 0);
                                                    this.props.onDashboardEditMenuIsOpened?.(
                                                        false
                                                    );
                                                }
                                            }}
                                            onContextMenu={async (evt) => {
                                                evt.preventDefault();

                                                let items: any = [];
                                                if (
                                                    this.state
                                                        .clipboardReadEnabled
                                                ) {
                                                    items = await navigator.clipboard.read();
                                                }
                                                let clipboardHasImage = false;
                                                for (
                                                    let i = 0;
                                                    i < items.length;
                                                    i++
                                                ) {
                                                    const {
                                                        hasImage,
                                                    } = checkIfClipboardItemIsImage(
                                                        items[i]
                                                    );
                                                    if (hasImage)
                                                        clipboardHasImage = hasImage;
                                                }
                                                let contextMenuInfo = {
                                                    left: evt.clientX,
                                                    top:
                                                        evt.clientY -
                                                        headerBarHeight,
                                                    showSelectorOptions: false,
                                                    hasImage: clipboardHasImage,
                                                };
                                                this.setState({
                                                    contextMenuInfo: contextMenuInfo,
                                                });
                                            }}
                                            onTouchStart={(evt) => {
                                                const touch = evt.touches[0];
                                                startLongPressTimer(() => {
                                                    let contextMenuInfo = {
                                                        left: touch.clientX,
                                                        top:
                                                            touch.clientY -
                                                            headerBarHeight,
                                                        showSelectorOptions: false,
                                                    };
                                                    this.setState({
                                                        contextMenuInfo: contextMenuInfo,
                                                    });
                                                });
                                            }}
                                            onTouchEnd={() => {
                                                clearLongPressTimer();
                                            }}
                                            style={{
                                                backgroundColor: "transparent",
                                                width: width,
                                                height: height,
                                            }}
                                        >
                                            <div
                                                ref={this.layerRef}
                                                style={{
                                                    position: "absolute",
                                                    left:
                                                        layerRect.x *
                                                        this.props.scale,
                                                    top:
                                                        layerRect.y *
                                                        this.props.scale,
                                                    width:
                                                        layerRect.width *
                                                        this.props.scale,
                                                    height:
                                                        layerRect.height *
                                                        this.props.scale,
                                                }}
                                            >
                                                {this.props.canvasTreeStore
                                                    .canvasViewMode ===
                                                    "mobile" &&
                                                    !this.props.live && (
                                                        <MobileViewFrame
                                                            slideRect={
                                                                slideRect
                                                            }
                                                            defaultMobileSlideHeight={
                                                                defaultMobileSlideHeight
                                                            }
                                                            scale={
                                                                this.props.scale
                                                            }
                                                        />
                                                    )}
                                                <div
                                                    style={{
                                                        opacity: 1,
                                                    }}
                                                >
                                                    <SlideBackground
                                                        slideContainerClassName={
                                                            this.props
                                                                .slideContainerClassName
                                                        }
                                                        showAsSlideShow={
                                                            this.props
                                                                .showAsSlideShow
                                                        }
                                                        canvasTreeStore={
                                                            this.props
                                                                .canvasTreeStore
                                                        }
                                                        live={this.props.live}
                                                        scale={this.props.scale}
                                                        canWrite={
                                                            this.props.canWrite
                                                        }
                                                        backgroundRootRef={
                                                            this
                                                                .backgroundRootRef
                                                        }
                                                        sharedPolicy={
                                                            this.props
                                                                .sharedPolicy
                                                        }
                                                        showDots={
                                                            this.props.showDots
                                                        }
                                                        slideRect={slideRect}
                                                        slideRootRef={
                                                            this.slideRootRef
                                                        }
                                                        moduleTitle={
                                                            this.props
                                                                .moduleTitle
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </DropArea>
                                )}

                                <div
                                    contentEditable={false}
                                    ref={this.onHtmlElementsRootRefChange}
                                    style={{
                                        position: "absolute",
                                        left: layerRect.x * this.props.scale,
                                        top: layerRect.y * this.props.scale,
                                        caretColor: "transparent",
                                        pointerEvents: "auto",
                                        zIndex: 1,
                                    }}
                                >
                                    {!collapsed && (
                                        <>
                                            <Backgrounds
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                canWrite={this.props.canWrite}
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                htmlElementsRootRef={
                                                    this.state
                                                        .htmlElementsRootRef
                                                }
                                                selectedMetadata={
                                                    this.state.selectedMetadata
                                                }
                                                currentEditId={undefined}
                                                currentModuleId={0}
                                                onClearEditing={() => {}}
                                                onHideContextMenu={() => {}}
                                            />
                                            <Inputs
                                                arrowConnectIntention={
                                                    this.state
                                                        .arrowConnectIntention
                                                }
                                                onConnectionIntention={(
                                                    state
                                                ) => {
                                                    this.setState({
                                                        arrowConnectIntention: state,
                                                    });
                                                }}
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                selectedMetadata={
                                                    this.state.selectedMetadata
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                linkedParentNode={
                                                    this.state.linkedParentNode!
                                                }
                                                htmlElementsRootRef={
                                                    this.state
                                                        .htmlElementsRootRef
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                layerRect={layerRect}
                                                scale={this.props.scale}
                                                editedNode={
                                                    this.state.editedNode
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onExpandCard={
                                                    this.props.onExpandCard
                                                }
                                                onExpandUserCard={
                                                    this.props.onExpandUserCard
                                                }
                                                onStartSimpleEditing={
                                                    this.startSimpleEditing
                                                }
                                                onStartLinkedParent={
                                                    this.startLinkedParent
                                                }
                                                onStartAdvancedEditing={
                                                    this.startAdvancedEditing
                                                }
                                                onChangeSelector={
                                                    this.changeSelector
                                                }
                                                onSimpleEditInsertOuterId={
                                                    this.simpleEditInsertOuterId
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props
                                                        .moduleId as number
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                canvasSize={{
                                                    height: height,
                                                    width: width,
                                                }}
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                                onRotate={() => {}}
                                                positionArrows={() => {}}
                                            />
                                            <CanvasButtons
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onButtonClick={
                                                    this.canvasButtonClick
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                scale={this.props.scale}
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                onResize={() => {}}
                                                currentEditId={undefined}
                                                currentModuleId={0}
                                                onClearEditing={() => {}}
                                                onHideContextMenu={() => {}}
                                            />
                                            <Tags
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                selectedMetadata={
                                                    this.state.selectedMetadata
                                                }
                                                htmlElementsRootRef={
                                                    this.state
                                                        .htmlElementsRootRef
                                                }
                                                editedTagNodeId={
                                                    this.state.editedTagNodeId
                                                }
                                                onStartEditTag={
                                                    this.startEditTag
                                                }
                                                onStartAdvancedEditing={
                                                    this.startAdvancedEditing
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                onAllowTextBoxEdit={
                                                    this.onAllowTextBoxEdit
                                                }
                                                scale={this.props.scale}
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onHideContextMenu={
                                                    this.hideContextMenu
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                onExpandCard={
                                                    this.props.onExpandCard
                                                }
                                                onExpandUserCard={
                                                    this.props.onExpandUserCard
                                                }
                                                onOpenBottomPortal={
                                                    this.props
                                                        .onOpenBottomPortal
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                currentEditId={undefined}
                                            />
                                            <DropdownSelectors
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                selectedMetadata={
                                                    this.state.selectedMetadata
                                                }
                                                linkedParentNode={
                                                    this.state.linkedParentNode!
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                htmlElementsRootRef={
                                                    this.state
                                                        .htmlElementsRootRef
                                                }
                                                editedNode={
                                                    this.state.editedNode
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onExpandCard={
                                                    this.props.onExpandCard
                                                }
                                                onExpandUserCard={
                                                    this.props.onExpandUserCard
                                                }
                                                onStartSimpleEditing={
                                                    this.startSimpleEditing
                                                }
                                                onStartLinkedParent={
                                                    this.startLinkedParent
                                                }
                                                onStartAdvancedEditing={
                                                    this.startAdvancedEditing
                                                }
                                                onChangeSelector={
                                                    this.changeSelector
                                                }
                                                onSimpleEditInsertOuterId={
                                                    this.simpleEditInsertOuterId
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props
                                                        .moduleId as number
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                            />
                                            <SpreadSheetElements
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                htmlElementsRootRef={
                                                    this.state
                                                        .htmlElementsRootRef
                                                }
                                                safeSelectByMetadata={
                                                    this.safeSelectByMetadata
                                                }
                                                onChangeEditedNode={
                                                    this.changeEditedNode
                                                }
                                                selectedMetadata={
                                                    this.state.selectedMetadata
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                selectionArea={
                                                    this.state
                                                        .spreadSheetSelectionArea
                                                }
                                                onSelectArea={
                                                    this
                                                        .updateSpreadSheetSelectionArea
                                                }
                                                linkedParentNode={
                                                    this.state.linkedParentNode!
                                                }
                                                onDeleteGrid={
                                                    this.deleteGridConfirm
                                                }
                                                editedNode={
                                                    this.state.editedNode
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                scale={this.props.scale}
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onExpandCard={
                                                    this.props.onExpandCard
                                                }
                                                onExpandUserCard={
                                                    this.props.onExpandUserCard
                                                }
                                                onExpandSpreadSheetNode={
                                                    this.expandSpreadSheetNode
                                                }
                                                onStartSimpleEditing={
                                                    this.startSimpleEditing
                                                }
                                                onStartLinkedParent={
                                                    this.startLinkedParent
                                                }
                                                onStartAdvancedEditing={
                                                    this.startAdvancedEditing
                                                }
                                                onChangeSelector={
                                                    this.changeSelector
                                                }
                                                onSimpleEditInsertOuterId={
                                                    this.simpleEditInsertOuterId
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                editedGridHeader={
                                                    this.state.editedGridHeader
                                                }
                                                currentSimpleEdit={
                                                    this.currentSimpleEdit
                                                }
                                                onStartEditHeader={
                                                    this.startEditGridHeader
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onItemContextMenu={
                                                    this
                                                        .openSpreadSheetElementContextMenu
                                                }
                                                onHeaderContextMenu={
                                                    this
                                                        .openSpreadSheetHeaderContextMenu
                                                }
                                                onHideContextMenu={
                                                    this.hideContextMenu
                                                }
                                                onKeepSimpleEditChanges={
                                                    this.keepSimpleEditChanges
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                onOpenBottomPortal={
                                                    this.props
                                                        .onOpenBottomPortal
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                currentEditId={undefined}
                                            />
                                            <Dashboards
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onOpenBottomPortal={
                                                    this.props
                                                        .onOpenBottomPortal
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                canWrite={this.props.canWrite}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                scale={this.props.scale}
                                                live={this.props.live}
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                            />
                                            <NewDashboards
                                                onExpandCard={
                                                    this.props.onExpandCard
                                                }
                                                setCurrentEditId={
                                                    this.props.setCurrentEditId
                                                }
                                                currentEditId={
                                                    this.props.currentEditId
                                                }
                                                tablePreviewVisible={
                                                    this.props
                                                        .tablePreviewVisible
                                                }
                                                finding={this.props.finding}
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                htmlElementsRootRef={
                                                    this.state
                                                        .htmlElementsRootRef
                                                }
                                                deleteSelectionByMetadata={this.deleteSelectionByMetadata.bind(
                                                    this
                                                )}
                                                dashboardEditMenuIsOpened={
                                                    this.props
                                                        .dashboardEditMenuIsOpened
                                                }
                                                setDashboardEditMenuIsOpened={(
                                                    isOpened: boolean
                                                ) => {
                                                    if (isOpened) {
                                                        this.props.onOpenThemes(
                                                            true,
                                                            ThemeType.Charts
                                                        );
                                                    } else {
                                                        this.props.onOpenThemes(
                                                            false,
                                                            ThemeType.Charts
                                                        );
                                                    }
                                                    this.props.onDashboardEditMenuIsOpened(
                                                        isOpened
                                                    );
                                                }}
                                                selectedMetadata={
                                                    this.state.selectedMetadata
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                canWrite={this.props.canWrite}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                scale={this.props.scale}
                                                live={this.props.live}
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                themesPopupOpen={
                                                    this.props.themesPopupOpen
                                                }
                                                onHideContextMenu={() => {}}
                                            />
                                            <BackendTables
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                canWrite={this.props.canWrite}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                            />

                                            {this.buildSimpleEditOverlay()}

                                            <QuestionnaireElements
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                selectedMetadata={
                                                    this.state.selectedMetadata
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                            />
                                            <MapElements
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                                htmlElementsRootRef={undefined}
                                            />
                                            <MapElementsOld
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                            />
                                            <GraphElements
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                                htmlElementsRootRef={undefined}
                                            />
                                            <EmbedUrlElements
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                selectedMetadata={
                                                    this.state.selectedMetadata
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                            />
                                            <ShapeElements
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                selectedMetadata={
                                                    this.state.selectedMetadata
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                            />

                                            <MergeDataElements
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                                htmlElementsRootRef={undefined}
                                            />
                                            <ManageDataElements
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                                htmlElementsRootRef={undefined}
                                            />
                                            <AggregateDataElements
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                onDeleteSnapLine={
                                                    this.deleteSnapLine
                                                }
                                                onRebuildSnapLine={
                                                    this.rebuildSnapLine
                                                }
                                                onClearEditing={
                                                    this.clearEditing
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onContextMenu={
                                                    this
                                                        .openHtmlElementContextMenu
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                canWrite={this.props.canWrite}
                                                live={this.props.live}
                                                scale={this.props.scale}
                                                moduleTitle={
                                                    this.props.moduleTitle
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                showDeletePopup={
                                                    this.showDeletePopup
                                                }
                                                showDeleteElementWithDatasetPopup={
                                                    this
                                                        .showDeleteElementWithDatasetPopup
                                                }
                                                onUpdateSelectionBounds={
                                                    this.updateSelectionBounds
                                                }
                                                currentEditId={undefined}
                                                onHideContextMenu={() => {}}
                                                htmlElementsRootRef={undefined}
                                            />
                                            {this.state.selectionBounds && (
                                                <DraggableTransformer
                                                    selectionBounds={
                                                        this.state
                                                            .selectionBounds
                                                    }
                                                    canvasTreeStore={
                                                        this.props
                                                            .canvasTreeStore
                                                    }
                                                    scale={this.props.scale}
                                                    onMoveSelection={
                                                        this.moveSelection
                                                    }
                                                    onContextMenu={
                                                        this
                                                            .openSelectorContextMenu
                                                    }
                                                    selectedMetadata={
                                                        this.state
                                                            .selectedMetadata ??
                                                        []
                                                    }
                                                    onDeleteSnapLine={
                                                        this.deleteSnapLine
                                                    }
                                                    onRebuildSnapLine={
                                                        this.rebuildSnapLine
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                    {this.props.showPageBar &&
                                        this.rootRef != null && (
                                            <CanvasPageBar
                                                collapsed={
                                                    this.props.canvasTreeStore
                                                        .collapsed
                                                }
                                                viewMode={
                                                    this.props.canvasTreeStore
                                                        .canvasViewMode
                                                }
                                                pageBarInfo={
                                                    this.props.pageBarInfo
                                                }
                                                layerRect={layerRect}
                                                rootRef={this.rootRef.current}
                                                sharedModule={
                                                    this.props.sharedModule
                                                }
                                                currentPageId={
                                                    this.props.canvasTreeStore
                                                        .canvasPageId as number
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                currentModuleId={
                                                    this.props.moduleId
                                                }
                                                live={
                                                    this.props.canvasTreeStore
                                                        .live
                                                }
                                                scale={this.props.scale}
                                                frozen={
                                                    this.props.canvasTreeStore
                                                        .live
                                                }
                                                onCurrentPageChange={async (
                                                    pageId
                                                ) => {
                                                    if (pageId == null) {
                                                        this.clearEditing();
                                                        this.props.canvasTreeStore.collapsed = true;
                                                        return;
                                                    }
                                                    if (
                                                        pageId ===
                                                        this.props
                                                            .canvasTreeStore
                                                            .canvasPageId
                                                    ) {
                                                        this.props.canvasTreeStore.collapsed = false;
                                                        return;
                                                    }

                                                    if (
                                                        this.props
                                                            .sharedModule ==
                                                        null
                                                    ) {
                                                        this.props.onScrollToTop?.();
                                                        this.performance = new Date();
                                                        await this.props.canvasTreeStore.restoreFromPageAsyncAction.bothParts(
                                                            pageId as
                                                                | number
                                                                | undefined,
                                                            () => {
                                                                this.clearEditing();
                                                            },
                                                            this.props
                                                                .canvasTreeStore
                                                                .live
                                                        );
                                                    } else {
                                                        this.props.onScrollToTop?.();
                                                        this.props.canvasTreeStore.isLoadingState = true;
                                                        this.props.canvasTreeStore.canvasPageId = pageId;
                                                        let index = this.props.sharedModule.pages.findIndex(
                                                            (page: {
                                                                pageId:
                                                                    | string
                                                                    | number;
                                                            }) =>
                                                                page.pageId ===
                                                                pageId
                                                        );
                                                        if (index >= 0) {
                                                            let sheets = this
                                                                .props
                                                                .sharedModule
                                                                .pages[index]
                                                                .sheets;
                                                            let sheet =
                                                                sheets[0];

                                                            if (sheet != null) {
                                                                await this.props.canvasTreeStore.deserializeFromSharedModuleAsyncAction.bothParts(
                                                                    sheet,
                                                                    this.props
                                                                        .sharedModule,
                                                                    false
                                                                );
                                                                this.props.canvasTreeStore.canvasId =
                                                                    sheet.id;
                                                            } else {
                                                                this.props.canvasTreeStore.clearAllFieldsAction?.();
                                                            }
                                                        } else {
                                                            this.props.canvasTreeStore.clearAllFieldsAction?.();
                                                        }
                                                        this.props.canvasTreeStore.isLoadingState = false;
                                                    }
                                                }}
                                            />
                                        )}
                                    {!collapsed &&
                                        (this.props.customSlideNumber != null ||
                                            this.props.showSlideNumbers) && (
                                            <SlideNumber
                                                sharedModule={
                                                    this.props.sharedModule
                                                }
                                                canvasTreeStore={
                                                    this.props.canvasTreeStore
                                                }
                                                currentPageId={
                                                    this.props.canvasTreeStore
                                                        .canvasPageId as number
                                                }
                                                customSlideNumber={
                                                    this.props.customSlideNumber
                                                }
                                                currentSlideId={
                                                    this.props.canvasTreeStore
                                                        .canvasId as number
                                                }
                                                onResize={
                                                    this.updateSelectionBounds
                                                }
                                                onMoveGroupSelection={
                                                    this.moveGroupSelection
                                                }
                                                currentModuleId={
                                                    this.props
                                                        .moduleId as number
                                                }
                                                live={
                                                    this.props.canvasTreeStore
                                                        .live
                                                }
                                                scale={this.props.scale}
                                                frozen={
                                                    this.props.canvasTreeStore
                                                        .live
                                                }
                                            />
                                        )}
                                    {!collapsed &&
                                        this.props.pinInitializer && (
                                            <CommentPins
                                                moduleId={
                                                    this.props
                                                        .moduleId as number
                                                }
                                                live={this.props.live}
                                                canWrite={this.props.canWrite}
                                                scale={this.props.scale}
                                                canvasId={
                                                    this.props.canvasTreeStore
                                                        .canvasId as number
                                                }
                                            />
                                        )}
                                </div>

                                {!collapsed &&
                                    this.props.canWrite &&
                                    this.state.contextMenuInfo != null && (
                                        <div
                                            className="canvas-menu"
                                            style={{
                                                zIndex: 998,
                                                position: "absolute",
                                                left: this.state.contextMenuInfo
                                                    .left,
                                                top: this.state.contextMenuInfo
                                                    .top,
                                                width:
                                                    this.state.contextMenuInfo
                                                        .spreadSheetNode !=
                                                        null ||
                                                    this.state.contextMenuInfo
                                                        .toggleTitles != null
                                                        ? "160px"
                                                        : "100px",
                                            }}
                                        >
                                            <div>
                                                {this.state.contextMenuInfo
                                                    .spreadSheetNode == null &&
                                                    this.state.contextMenuInfo
                                                        .elementInfo == null &&
                                                    !this.state.contextMenuInfo
                                                        .showSelectorOptions &&
                                                    this.state.contextMenuInfo
                                                        .backendTableNode ==
                                                        null &&
                                                    this.state.contextMenuInfo
                                                        .toggleTitles == null &&
                                                    !this.props.live &&
                                                    this.props.canWrite && (
                                                        <>
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.props.canvasTreeStore.undoAsyncAction.bothParts();
                                                                    this.clearSelector();
                                                                    this.keepRootFocus();
                                                                }}
                                                            >
                                                                {"Undo"}
                                                            </PreventMouseLeaveButton>
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.props.canvasTreeStore.redoAsyncAction.bothParts();
                                                                    this.clearSelector();
                                                                    this.keepRootFocus();
                                                                }}
                                                            >
                                                                {"Redo"}
                                                            </PreventMouseLeaveButton>
                                                        </>
                                                    )}
                                                {!this.props.live &&
                                                    this.props.canWrite &&
                                                    this.state.contextMenuInfo
                                                        .elementInfo !=
                                                        null && (
                                                        <>
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.props.canvasTreeStore.moveForward(
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .elementInfo!
                                                                            .id,
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .elementInfo!
                                                                            .type
                                                                    );
                                                                }}
                                                            >
                                                                {"Move forward"}
                                                            </PreventMouseLeaveButton>
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.props.canvasTreeStore.moveFront(
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .elementInfo!
                                                                            .id,
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .elementInfo!
                                                                            .type
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    "Move to front"
                                                                }
                                                            </PreventMouseLeaveButton>
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.props.canvasTreeStore.moveBackward(
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .elementInfo!
                                                                            .id,
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .elementInfo!
                                                                            .type
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    "Move backward"
                                                                }
                                                            </PreventMouseLeaveButton>
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.props.canvasTreeStore.moveBack(
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .elementInfo!
                                                                            .id,
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .elementInfo!
                                                                            .type
                                                                    );
                                                                }}
                                                            >
                                                                {"Move to back"}
                                                            </PreventMouseLeaveButton>
                                                        </>
                                                    )}
                                                {!this.props.live &&
                                                    this.props.canWrite &&
                                                    this.state.contextMenuInfo
                                                        .showSelectorOptions && (
                                                        <>
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.groupSelection(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                {"Group"}
                                                            </PreventMouseLeaveButton>
                                                            {containsGroup && (
                                                                <PreventMouseLeaveButton
                                                                    onClick={() => {
                                                                        this.groupSelection(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    {"Ungroup"}
                                                                </PreventMouseLeaveButton>
                                                            )}
                                                            {!isSingleton() ? (
                                                                <>
                                                                    <PreventMouseLeaveButton
                                                                        onClick={() => {
                                                                            this.cutSelection();
                                                                        }}
                                                                    >
                                                                        {"Cut"}
                                                                    </PreventMouseLeaveButton>
                                                                    <PreventMouseLeaveButton
                                                                        onClick={() => {
                                                                            this.copySelection();
                                                                        }}
                                                                    >
                                                                        {"Copy"}
                                                                    </PreventMouseLeaveButton>
                                                                </>
                                                            ) : null}
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.deleteSelection();
                                                                }}
                                                            >
                                                                {"Delete"}
                                                            </PreventMouseLeaveButton>
                                                        </>
                                                    )}

                                                {!this.state.contextMenuInfo
                                                    .toggleTitles &&
                                                    !this.state.contextMenuInfo
                                                        .elementInfo &&
                                                    !this.props.live &&
                                                    this.props.canWrite &&
                                                    !this.state.contextMenuInfo
                                                        .backendTableNode && (
                                                        <>
                                                            <PreventMouseLeaveButton
                                                                disabled={
                                                                    !this.state
                                                                        .clipboardReadEnabled
                                                                }
                                                                onClick={(
                                                                    evt
                                                                ) => {
                                                                    this.pasteSelection();
                                                                }}
                                                            >
                                                                {!this.state
                                                                    .clipboardReadEnabled
                                                                    ? "Paste (use Ctrl+V instead)"
                                                                    : "Paste"}
                                                            </PreventMouseLeaveButton>
                                                        </>
                                                    )}
                                                {!this.state.contextMenuInfo
                                                    .toggleTitles &&
                                                    !this.state.contextMenuInfo
                                                        .elementInfo &&
                                                    !this.props.live &&
                                                    this.props.canWrite &&
                                                    !this.state.contextMenuInfo
                                                        .backendTableNode && (
                                                        <PreventMouseLeaveButton
                                                            onClick={(evt) => {
                                                                this.setState({
                                                                    colorPicker: {
                                                                        isOpened: true,
                                                                        left:
                                                                            evt.clientX,
                                                                        top:
                                                                            evt.clientY,
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            Background Color
                                                        </PreventMouseLeaveButton>
                                                    )}
                                                {!this.state.contextMenuInfo
                                                    .toggleTitles &&
                                                    !this.state.contextMenuInfo
                                                        .elementInfo &&
                                                    this.state.contextMenuInfo
                                                        .hasImage &&
                                                    !this.props.live &&
                                                    this.props.canWrite &&
                                                    !this.state.contextMenuInfo
                                                        .backendTableNode && (
                                                        <PreventMouseLeaveButton
                                                            disabled={
                                                                !this.state
                                                                    .clipboardReadEnabled
                                                            }
                                                            onClick={() => {
                                                                this.pasteSelection(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            {!this.state
                                                                .clipboardReadEnabled
                                                                ? "Paste Picture at full resolution (use Ctrl+V instead)"
                                                                : "Paste Picture at full resolution"}
                                                        </PreventMouseLeaveButton>
                                                    )}

                                                {this.state.contextMenuInfo
                                                    .spreadSheetNode !=
                                                    null && (
                                                    <>
                                                        <PreventMouseLeaveButton
                                                            onClick={() => {
                                                                this.performance = new Date();
                                                                this.performanceElement =
                                                                    elements.spreadsheet;
                                                                this.props.canvasTreeStore.insertGridRowAction(
                                                                    this.state
                                                                        .contextMenuInfo!
                                                                        .spreadSheetNode!
                                                                        .gridId!,
                                                                    this.state
                                                                        .contextMenuInfo!
                                                                        .spreadSheetNode!
                                                                        .y!
                                                                );
                                                            }}
                                                        >
                                                            {"Insert row above"}
                                                        </PreventMouseLeaveButton>
                                                        <PreventMouseLeaveButton
                                                            onClick={() => {
                                                                this.performance = new Date();
                                                                this.performanceElement =
                                                                    elements.spreadsheet;
                                                                this.props.canvasTreeStore.insertGridRowAction(
                                                                    this.state
                                                                        .contextMenuInfo!
                                                                        .spreadSheetNode!
                                                                        .gridId!,
                                                                    this.state
                                                                        .contextMenuInfo!
                                                                        .spreadSheetNode!
                                                                        .y! + 1
                                                                );
                                                            }}
                                                        >
                                                            {"Insert row below"}
                                                        </PreventMouseLeaveButton>
                                                        <PreventMouseLeaveButton
                                                            onClick={() => {
                                                                this.performance = new Date();
                                                                this.performanceElement =
                                                                    elements.spreadsheet;
                                                                this.props.canvasTreeStore.insertGridColumnAction(
                                                                    this.state
                                                                        .contextMenuInfo!
                                                                        .spreadSheetNode!
                                                                        .gridId!,
                                                                    this.state
                                                                        .contextMenuInfo!
                                                                        .spreadSheetNode!
                                                                        .x!
                                                                );
                                                            }}
                                                        >
                                                            {
                                                                "Insert column left"
                                                            }
                                                        </PreventMouseLeaveButton>
                                                        <PreventMouseLeaveButton
                                                            onClick={() => {
                                                                this.performance = new Date();
                                                                this.performanceElement =
                                                                    elements.spreadsheet;
                                                                this.props.canvasTreeStore.insertGridColumnAction(
                                                                    this.state
                                                                        .contextMenuInfo!
                                                                        .spreadSheetNode!
                                                                        .gridId!,
                                                                    this.state
                                                                        .contextMenuInfo!
                                                                        .spreadSheetNode!
                                                                        .x! + 1
                                                                );
                                                            }}
                                                        >
                                                            {
                                                                "Insert column right"
                                                            }
                                                        </PreventMouseLeaveButton>
                                                        {(this.props.canvasTreeStore.gridsState.get(
                                                            this.state
                                                                .contextMenuInfo!
                                                                .spreadSheetNode!
                                                                .gridId!
                                                        )?.rows ?? 0) > 0 && (
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.performance = new Date();
                                                                    this.performanceElement =
                                                                        elements.spreadsheet;
                                                                    this.setState(
                                                                        {
                                                                            editedGridHeader: undefined,
                                                                        }
                                                                    );
                                                                    this.props.canvasTreeStore.deleteGridRowAction(
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .spreadSheetNode!
                                                                            .gridId!,
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .spreadSheetNode!
                                                                            .y!
                                                                    );
                                                                }}
                                                            >
                                                                {"Delete row"}
                                                            </PreventMouseLeaveButton>
                                                        )}
                                                        {(this.props.canvasTreeStore.gridsState.get(
                                                            this.state
                                                                .contextMenuInfo!
                                                                .spreadSheetNode!
                                                                .gridId!
                                                        )?.cols ?? 0) > 1 && (
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.performance = new Date();
                                                                    this.performanceElement =
                                                                        elements.spreadsheet;
                                                                    this.setState(
                                                                        {
                                                                            editedGridHeader: undefined,
                                                                        }
                                                                    );

                                                                    this.props.canvasTreeStore.deleteGridColumnAction(
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .spreadSheetNode!
                                                                            .gridId!,
                                                                        this
                                                                            .state
                                                                            .contextMenuInfo!
                                                                            .spreadSheetNode!
                                                                            .x!
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    "Delete column"
                                                                }
                                                            </PreventMouseLeaveButton>
                                                        )}
                                                    </>
                                                )}
                                                {this.state.contextMenuInfo
                                                    .toggleTitles != null &&
                                                    this.props.canvasTreeStore.gridsState.get(
                                                        this.state
                                                            .contextMenuInfo
                                                            .toggleTitles.gridId
                                                    ) != null && (
                                                        <>
                                                            {this.state
                                                                .contextMenuInfo
                                                                .toggleTitles
                                                                .insertFirstRow && (
                                                                <PreventMouseLeaveButton
                                                                    onClick={() => {
                                                                        this.performance = new Date();
                                                                        this.performanceElement =
                                                                            elements.spreadsheet;
                                                                        this.props.canvasTreeStore.insertGridRowAction(
                                                                            this
                                                                                .state
                                                                                .contextMenuInfo!
                                                                                .toggleTitles!
                                                                                .gridId,
                                                                            0
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        "Insert row below"
                                                                    }
                                                                </PreventMouseLeaveButton>
                                                            )}
                                                            {this.state
                                                                .contextMenuInfo
                                                                .toggleTitles
                                                                .rowIndex !=
                                                                null && (
                                                                <>
                                                                    <PreventMouseLeaveButton
                                                                        onClick={() => {
                                                                            this.performance = new Date();
                                                                            this.performanceElement =
                                                                                elements.spreadsheet;
                                                                            this.props.canvasTreeStore.insertGridRowAction(
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .gridId,
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .rowIndex!
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            "Insert row above"
                                                                        }
                                                                    </PreventMouseLeaveButton>
                                                                    <PreventMouseLeaveButton
                                                                        onClick={() => {
                                                                            this.performance = new Date();
                                                                            this.performanceElement =
                                                                                elements.spreadsheet;
                                                                            this.props.canvasTreeStore.insertGridRowAction(
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .gridId,
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .rowIndex! +
                                                                                    1
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            "Insert row below"
                                                                        }
                                                                    </PreventMouseLeaveButton>
                                                                    <PreventMouseLeaveButton
                                                                        onClick={() => {
                                                                            this.performance = new Date();
                                                                            this.performanceElement =
                                                                                elements.spreadsheet;
                                                                            this.setState(
                                                                                {
                                                                                    editedGridHeader: undefined,
                                                                                }
                                                                            );

                                                                            this.props.canvasTreeStore.deleteGridRowAction(
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .gridId,
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .rowIndex!
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            "Delete row"
                                                                        }
                                                                    </PreventMouseLeaveButton>
                                                                </>
                                                            )}
                                                            {this.state
                                                                .contextMenuInfo
                                                                .toggleTitles
                                                                .insertFirstColumn && (
                                                                <PreventMouseLeaveButton
                                                                    onClick={() => {
                                                                        this.performance = new Date();
                                                                        this.performanceElement =
                                                                            elements.spreadsheet;
                                                                        this.props.canvasTreeStore.insertGridColumnAction(
                                                                            this
                                                                                .state
                                                                                .contextMenuInfo!
                                                                                .toggleTitles!
                                                                                .gridId,
                                                                            0
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        "Insert column right"
                                                                    }
                                                                </PreventMouseLeaveButton>
                                                            )}
                                                            {this.state
                                                                .contextMenuInfo
                                                                .toggleTitles
                                                                .colIndex !=
                                                                null && (
                                                                <>
                                                                    <PreventMouseLeaveButton
                                                                        onClick={() => {
                                                                            this.performance = new Date();
                                                                            this.performanceElement =
                                                                                elements.spreadsheet;
                                                                            this.props.canvasTreeStore.insertGridColumnAction(
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .gridId,
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .colIndex!
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            "Insert column left"
                                                                        }
                                                                    </PreventMouseLeaveButton>
                                                                    <PreventMouseLeaveButton
                                                                        onClick={() => {
                                                                            this.performance = new Date();
                                                                            this.performanceElement =
                                                                                elements.spreadsheet;
                                                                            this.props.canvasTreeStore.insertGridColumnAction(
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .gridId,
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .colIndex! +
                                                                                    1
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            "Insert column right"
                                                                        }
                                                                    </PreventMouseLeaveButton>
                                                                    <PreventMouseLeaveButton
                                                                        onClick={() => {
                                                                            this.performance = new Date();
                                                                            this.performanceElement =
                                                                                elements.spreadsheet;
                                                                            this.setState(
                                                                                {
                                                                                    editedGridHeader: undefined,
                                                                                }
                                                                            );

                                                                            this.props.canvasTreeStore.deleteGridColumnAction(
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .gridId,
                                                                                this
                                                                                    .state
                                                                                    .contextMenuInfo!
                                                                                    .toggleTitles!
                                                                                    .colIndex!
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            "Delete column"
                                                                        }
                                                                    </PreventMouseLeaveButton>
                                                                </>
                                                            )}
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.performance = new Date();
                                                                    this.performanceElement =
                                                                        elements.spreadsheet;
                                                                    const gridId = this
                                                                        .state
                                                                        .contextMenuInfo!
                                                                        .toggleTitles!
                                                                        .gridId;
                                                                    this.props.canvasTreeStore.toggleRowHeadersAction(
                                                                        gridId,
                                                                        !(this.props.canvasTreeStore.gridsState.get(
                                                                            gridId
                                                                        ) as CanvasSpreadSheetGrid)
                                                                            .leftHeadersEnabled
                                                                    );
                                                                }}
                                                            >
                                                                {(this.props.canvasTreeStore.gridsState.get(
                                                                    this.state
                                                                        .contextMenuInfo
                                                                        .toggleTitles
                                                                        .gridId
                                                                ) as CanvasSpreadSheetGrid)
                                                                    .leftHeadersEnabled
                                                                    ? "Delete row titles"
                                                                    : "Add row titles"}
                                                            </PreventMouseLeaveButton>
                                                            <PreventMouseLeaveButton
                                                                onClick={() => {
                                                                    this.performance = new Date();
                                                                    this.performanceElement =
                                                                        elements.spreadsheet;
                                                                    const gridId = this
                                                                        .state
                                                                        .contextMenuInfo!
                                                                        .toggleTitles!
                                                                        .gridId;
                                                                    this.props.canvasTreeStore.toggleColumnHeadersAction(
                                                                        gridId,
                                                                        !(this.props.canvasTreeStore.gridsState.get(
                                                                            gridId
                                                                        ) as CanvasSpreadSheetGrid)
                                                                            .headersEnabled
                                                                    );
                                                                }}
                                                            >
                                                                {(this.props.canvasTreeStore.gridsState.get(
                                                                    this.state
                                                                        .contextMenuInfo
                                                                        .toggleTitles
                                                                        .gridId
                                                                ) as CanvasSpreadSheetGrid)
                                                                    .headersEnabled
                                                                    ? "Delete column titles"
                                                                    : "Add column titles"}
                                                            </PreventMouseLeaveButton>
                                                        </>
                                                    )}
                                                {this.state.contextMenuInfo
                                                    .backendTableNode !=
                                                    null && (
                                                    <>
                                                        <PreventMouseLeaveButton
                                                            onClick={() => {
                                                                const contextMenuInfo = this
                                                                    .state
                                                                    .contextMenuInfo;
                                                                this.performance = new Date();
                                                                this.performanceElement =
                                                                    elements.backendTable;
                                                                let tableOption = this.props.canvasTreeStore.backendTablesState.get(
                                                                    contextMenuInfo!
                                                                        .backendTableNode!
                                                                        .tableId
                                                                )?.tableOption;
                                                                if (
                                                                    tableOption !=
                                                                    null
                                                                ) {
                                                                    deleteRows(
                                                                        tableOption,
                                                                        [
                                                                            contextMenuInfo!
                                                                                .backendTableNode!
                                                                                .row_id,
                                                                        ],
                                                                        this
                                                                            .props
                                                                            .currentModuleId ??
                                                                            remoteModuleId
                                                                    )
                                                                        .then(
                                                                            () => {
                                                                                this.props.canvasTreeStore.loadBackendTableAsyncAction(
                                                                                    contextMenuInfo!
                                                                                        .backendTableNode!
                                                                                        .tableId
                                                                                );
                                                                            }
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                error
                                                                            ) => {
                                                                                console.log(
                                                                                    error
                                                                                );
                                                                            }
                                                                        );
                                                                }
                                                            }}
                                                        >
                                                            {"Delete row"}
                                                        </PreventMouseLeaveButton>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {this.props.captureCanvas && (
                                    <CaptureOverlay
                                        onClose={this.props.onCloseCapture}
                                        onCapture={(x, y, width, height) => {
                                            renderHtmlRef(
                                                this.rootRef,
                                                x,
                                                y,
                                                width,
                                                height
                                            )
                                                .then((base64url) => {
                                                    this.props.onFinishCapture(
                                                        base64url
                                                    );
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }}
                                        zIndex={100}
                                        {...canvasSize}
                                    />
                                )}
                                {""}
                            </div>
                            {!this.props.live &&
                                this.state.editedAdvancedNode &&
                                // It will be equal to null if another user
                                // deletes the element while this menu is open
                                // https://eisengardai.atlassian.net/browse/EIS-366
                                this.props.canvasTreeStore.canvasTreeState.get(
                                    this.state.editedAdvancedNode.id
                                ) != null && (
                                    <AdvancedModeMenu
                                        onResize={() => {
                                            setTimeout(() => {
                                                this.updateSelectionBounds();
                                            }, 0);
                                        }}
                                        canvasTreeStore={
                                            this.props.canvasTreeStore
                                        }
                                        currentModuleId={this.props.moduleId}
                                        canWrite={this.props.canWrite}
                                        isLiveStreaming={
                                            this.props.isLiveStreaming
                                        }
                                        nodeId={
                                            this.state.editedAdvancedNode.id
                                        }
                                        initialNodeState={
                                            this.props.canvasTreeStore.canvasTreeState.get(
                                                this.state.editedAdvancedNode.id
                                            )!
                                        }
                                        onClose={(commit) => {
                                            this.clearEditing();
                                        }}
                                    />
                                )}
                            {this.state.gridIdToDelete != null && (
                                <MessagePopup
                                    title={"Delete table"}
                                    danger
                                    message={
                                        "Are you sure you want to delete this table?"
                                    }
                                    acceptButtonTitle={"Delete"}
                                    onAccept={() => {
                                        this.props.canvasTreeStore.deleteGridAction(
                                            this.state.gridIdToDelete as string
                                        );
                                        this.clearSelector();

                                        this.setState({
                                            gridIdToDelete: undefined,
                                        });
                                    }}
                                    onReject={() => {
                                        this.setState({
                                            gridIdToDelete: undefined,
                                        });
                                    }}
                                    zIndex={100000000}
                                />
                            )}
                            {this.state.deletePopupData != null && (
                                <MessagePopup
                                    title={"Delete element"}
                                    danger
                                    message={
                                        this.state.deletePopupData
                                            .customMessage ||
                                        "Are you sure you want to delete this element?"
                                    }
                                    acceptButtonTitle={"Delete"}
                                    onAccept={() => {
                                        this.state.deletePopupData?.onDelete();
                                        this.setState({
                                            deletePopupData: null,
                                        });
                                    }}
                                    onReject={() => {
                                        this.setState({
                                            deletePopupData: null,
                                        });
                                    }}
                                    zIndex={100000000}
                                />
                            )}
                            {this.state.deleteElementWithDatasetPopupData !=
                                null && (
                                <WarningModalPopup
                                    onReplace={() => {
                                        this.state.deleteElementWithDatasetPopupData?.onDelete();
                                        this.setState({
                                            deleteElementWithDatasetPopupData: null,
                                        });
                                    }}
                                    onKeepBoth={() => {
                                        this.state.deleteElementWithDatasetPopupData?.onKeepDataSet();
                                        this.setState({
                                            deleteElementWithDatasetPopupData: null,
                                        });
                                    }}
                                    onReject={() => {
                                        this.setState({
                                            deleteElementWithDatasetPopupData: null,
                                        });
                                    }}
                                    title="WARNING"
                                    text={`The dataset will be deleted along with the ${this.state.deleteElementWithDatasetPopupData.elementName}.`}
                                    subText="Would you like to keep the dataset?"
                                    rejectText="Cancel"
                                    keepBothText="Keep Dataset"
                                    replaceText="Delete"
                                    zIndex={100000000}
                                />
                            )}
                            {!this.props.live &&
                                this.props.moduleId != null &&
                                !this.state.textBoxEditAllowed &&
                                (this.state.fontColorSizeDisplayed ||
                                    (this.state.selectedMetadata &&
                                        this.state.selectedMetadata.length >
                                            0)) && (
                                    <PlacementToolbar
                                        editedNode={
                                            this.state.editedNode != null &&
                                            isSimpleSpreadSheetInput(
                                                this.state.editedNode
                                            )
                                                ? this.state.editedNode
                                                : undefined
                                        }
                                        onChangeEditedNode={
                                            this.changeEditedNode
                                        }
                                        placementToolbarSimpleEdit={
                                            this.placementToolbarSimpleEdit
                                        }
                                        onClearEditing={this.clearEditing}
                                        onKeepSimpleEditChanges={
                                            this.keepSimpleEditChanges
                                        }
                                        canvasTreeStore={
                                            this.props.canvasTreeStore
                                        }
                                        spreadSheetGridId={
                                            this.state.selectedMetadata
                                                ?.length === 1 &&
                                            this.state.selectedMetadata[0]
                                                .type === "gridsState"
                                                ? (this.state
                                                      .selectedMetadata[0]
                                                      .id as string)
                                                : undefined
                                        }
                                        shapeElementId={
                                            this.state.selectedMetadata
                                                ?.length === 1 &&
                                            this.state.selectedMetadata[0]
                                                .type === "shapeElementsState"
                                                ? (this.state
                                                      .selectedMetadata[0]
                                                      .id as string)
                                                : undefined
                                        }
                                        backgroundId={
                                            this.state.selectedMetadata
                                                ?.length === 1 &&
                                            this.state.selectedMetadata[0]
                                                .type === "backgroundsState"
                                                ? (this.state
                                                      .selectedMetadata[0]
                                                      .id as number)
                                                : undefined
                                        }
                                        mapElementId={
                                            this.state.selectedMetadata
                                                ?.length === 1 &&
                                            this.state.selectedMetadata[0]
                                                .type === "mapElementsState"
                                                ? (this.state
                                                      .selectedMetadata[0]
                                                      .id as string)
                                                : undefined
                                        }
                                        dashboardId={
                                            this.state.selectedMetadata
                                                ?.length === 1 &&
                                            this.state.selectedMetadata[0]
                                                .type === "dashboardsState"
                                                ? (this.state
                                                      .selectedMetadata[0]
                                                      .id as string)
                                                : undefined
                                        }
                                        nodeId={
                                            this.state.selectedMetadata
                                                ?.length === 1 &&
                                            this.state.selectedMetadata[0]
                                                .type === "canvasTreeState"
                                                ? (this.state
                                                      .selectedMetadata[0]
                                                      .id as number)
                                                : this.state.editedNode !=
                                                      null &&
                                                  (isInput(
                                                      this.state.editedNode
                                                  ) ||
                                                      isSubmitButton(
                                                          this.state.editedNode
                                                      ))
                                                ? this.state.editedNode?.id ??
                                                  undefined
                                                : undefined
                                        }
                                        currentModuleId={this.props.moduleId}
                                        fontOptions={
                                            this.state.fontColorSizeDisplayed
                                                ? {
                                                      fontColor: this.state
                                                          .fontColorValue,
                                                      fontSize: this.state
                                                          .fontSizeValue,
                                                      fontForLabel: this.state
                                                          .fontForLabel,
                                                  }
                                                : undefined
                                        }
                                        onUpdateSelection={this.updateSelection}
                                        onUpdateSelectionBounds={() => {
                                            setTimeout(
                                                this.updateSelectionBounds,
                                                0
                                            );
                                        }}
                                        slideRect={slideRect}
                                        metadata={
                                            this.state.selectedMetadata
                                                ? this.state.selectedMetadata
                                                : this.state.editedNode !=
                                                      null &&
                                                  (isInput(
                                                      this.state.editedNode
                                                  ) ||
                                                      isSubmitButton(
                                                          this.state.editedNode
                                                      ))
                                                ? this.state
                                                      .tempSelectedMetadata
                                                : undefined
                                        }
                                    />
                                )}
                        </div>
                    </div>
                    {this.state.colorPicker.isOpened && (
                        <OutsideAlerter
                            onReject={() => {
                                this.setState({
                                    colorPicker: {
                                        ...this.state.colorPicker,
                                        isOpened: false,
                                    },
                                });
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 9,
                                    top: this.state.colorPicker.top,
                                    left: this.state.colorPicker.left,
                                }}
                            >
                                <CustomSketchPicker
                                    color={
                                        this.props.canvasTreeStore.slideColor ??
                                        "#fff"
                                    }
                                    onChange={(color) => {
                                        this.props.canvasTreeStore.updateSlideColorAction(
                                            color.hex
                                        );
                                    }}
                                />
                            </div>
                        </OutsideAlerter>
                    )}
                </ErrorBoundary>
            );
        }
    }
);
