import React, { Component } from "react";
import { mainStyle } from "./MainStyle";

class CollapseExtendButton extends Component {
    /*
     * Requires the following props:
     * expanded: boolean, whether the button is expanded
     * onClick: function, gets called when the button is clicked
     * caption: string, button caption
     */
    render() {
        return (
            <div
                onClick={() => {
                    this.props.onClick();
                }}
                className="my-row collapse-expand-div"
                style={{
                    cursor: "pointer",
                    height: 47,
                    marginTop: 20,
                    marginBottom: 20,
                    justifyContent: "space-between",
                }}
            >
                <div className="my-row">
                    <div
                        style={{
                            width: 7,
                            backgroundColor: this.props.expanded
                                ? mainStyle.getPropertyValue(
                                      "--secondary-content-color"
                                  )
                                : mainStyle.getPropertyValue(
                                      "--primary-content-color"
                                  ),
                        }}
                    />
                    <span
                        className="unselectable sections-title-span"
                        style={{
                            marginLeft: 20,
                            alignSelf: "center",
                            color: this.props.expanded
                                ? mainStyle.getPropertyValue(
                                      "--sections-title-color"
                                  )
                                : mainStyle.getPropertyValue(
                                      "--sections-inactive-title-color"
                                  ),
                        }}
                    >
                        {this.props.caption}
                    </span>
                </div>
                <span
                    className="unselectable sections-title-span"
                    style={{
                        marginRight: 20,
                        alignSelf: "center",
                    }}
                >
                    {this.props.expanded ? "\uFF0D" : "\uFF0B"}
                </span>
            </div>
        );
    }
}

export default CollapseExtendButton;