interface Rect {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface Point {
    x: number;
    y: number;
}

export function getMaxCommonRect(rects: Rect[]): Rect {
    if (rects.length === 0)
        return {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };
    let maxRect: {
        x: number;
        y: number;
        width: number;
        height: number;
    } = rects[0];
    if (rects.length > 1) {
        let minX = rects[0].x;
        let minY = rects[0].y;
        let maxX = rects[0].x + rects[0].width;
        let maxY = rects[0].y + rects[0].height;
        for (let i = 1; i < rects.length; ++i) {
            let rect = rects[i];
            minX = Math.min(rect.x, minX);
            minY = Math.min(rect.y, minY);
            maxX = Math.max(rect.width + rect.x, maxX);
            maxY = Math.max(rect.height + rect.y, maxY);
        }
        maxRect = {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        };
    }
    return maxRect;
}

export function haveIntersection(r1: Rect, r2: Rect): boolean {
    return !(
        r2.x > r1.x + r1.width ||
        r2.x + r2.width < r1.x ||
        r2.y > r1.y + r1.height ||
        r2.y + r2.height < r1.y
    );
}

export function pointInRect(r1: Rect, p1: Point): boolean {
    return (
        p1.x > r1.x &&
        p1.x < r1.x + r1.width &&
        p1.y > r1.y &&
        p1.y < r1.y + r1.height
    );
}

export function reverse(r1: Point, r2: Point): { [k: string]: any } {
    let r1x: number = r1.x;
    let r1y: number = r1.y;
    let r2x: number = r2.x;
    let r2y: number = r2.y;
    let d: number;

    if (r1x > r2x) {
        d = Math.abs(r1x - r2x);
        r1x = r2x;
        r2x = r1x + d;
    }

    if (r1y > r2y) {
        d = Math.abs(r1y - r2y);
        r1y = r2y;
        r2y = r1y + d;
    }

    return {
        x1: r1x,
        y1: r1y,
        x2: r2x,
        y2: r2y,
    };
}