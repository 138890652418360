import React, { useRef, Component, FunctionComponent, useState } from "react";
import { observer } from "mobx-react";
import { useDrag, useDrop } from "react-dnd";
import Cookies from "universal-cookie";
import { Button } from "react-bootstrap";
import Switch from "react-switch";

import axios from "common/ServerConnection";
import SlideCard from "./SlideCard";
import {
    moveCanvasApi,
    addCanvasApi,
    copyCanvasApi,
    deleteCanvasApi,
    hideInSlideShow,
} from "common/CanvasUserApi";
import MessagePopup from "common/MessagePopup";
import {
    Canvas,
    BriefCanvas,
    CanvasBackground,
    PageBarInfo,
} from "common/Canvas";
import Canvases from "common/Canvases";
import EditCanvasPopup from "./EditCanvasPopup";
import { headerBarHeight, sheetRibbonWidth } from "./Constants";
import { mainStyle } from "common/MainStyle";
import CanvasTreeStore from "./CanvasTreeStore";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import AddPagePopup from "common/AddPagePopup";
import CanvasVersion from "./CanvasVersion";
import VersionCard from "./VersionCard";
import CreateNamedVersionPopup from "./CreateNamedVersionPopup";
import ColorPicker from "common/ColorPicker";
import FormulaInformationStore from "./FormulaInformationStore";
import SharedBoxesStore from "./SharedBoxesStore";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import {
    addUserPageToModuleApi,
    editUserPageModuleApi,
    deleteUserPageModuleApi,
} from "common/ModulesApi";
import { lightThemeStyle } from "common/LightThemeStyle";
import CurrentModulesStore from "common/CurrentModulesStore";
import { defaultSlideWidth } from "./Constants";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import SaveTemplatePopup from "./SaveTemplatePopup";
const cookies = new Cookies();

function PreventMouseLeaveButton(
    props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
    let { style, ...rest } = props;
    return (
        <button
            prevent-mouse-leave="true"
            style={{
                paddingLeft: 12,
                paddingRight: 12,
                textAlign: "start",
                ...style,
            }}
            {...rest}
        />
    );
}

interface DraggableProps {
    canvasId: number | null;
    pageId: number;
    prevCanvasId?: number | null;
    nextCanvasId?: number | null;
    enteredSlideCardDropArea?: string;
    startDragSlideCard?: {
        id: number | null;
    };
    setStartDragSlideId: (id: number | null) => void;
    onCanvasesSwap: (
        pageId: number,
        dropPosition: string | undefined,
        canvasId: number | null,
        swapPageId: number,
        swapId: number | null
    ) => void;
    style?: React.CSSProperties;
    emptySpaceSlide?: boolean;
}

interface DragContent {
    type: string;
    content: {
        pageId: number;
        canvasId: number | null;
    };
}

const DraggableCanvasCard: FunctionComponent<DraggableProps> = (props) => {
    const {
        canvasId,
        pageId,
        enteredSlideCardDropArea,
        startDragSlideCard,
        setStartDragSlideId,
    } = props;
    const [canDrag, setCanDrag] = useState(true);
    const ref = useRef(null);
    const [collected, drag] = useDrag({
        item: {
            content: {
                pageId: props.pageId,
                canvasId: props.canvasId,
            },
        },
        end: (_item, _monitor) => { },
        canDrag: canDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            relativePosY: monitor.getDifferenceFromInitialOffset()?.y
        }),
        type: "slide",
    });
    const drop = useDrop({
        accept: "slide",
        drop(otherItem: DragContent, _monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = otherItem.content.canvasId;
            const hoverIndex = canvasId;
            if (startDragSlideCard?.id) {
                setStartDragSlideId(null);
            }
            if (dragIndex === hoverIndex) {
                return;
            }
            props.onCanvasesSwap(
                pageId,
                enteredSlideCardDropArea,
                canvasId,
                otherItem.content.pageId,
                otherItem.content.canvasId
            );
        },
    })[1];
    if (canvasId != null && !props.emptySpaceSlide) {
        drag(drop(ref));
    } else {
        drop(ref);
    }
    return (
        <div
            ref={ref}
            onMouseDown={(e) => {
                if (
                    e.target instanceof HTMLInputElement &&
                    e.target.nodeName === "INPUT"
                ) {
                    setCanDrag(false);
                } else {
                    setCanDrag(true);
                }

                if (!startDragSlideCard?.id) {
                    setStartDragSlideId(canvasId);
                }
            }}
            style={props.style ?? {}}
        >
            {props.children}
        </div>
    );
};

const EmptySpaceSlide = () => {
    let dropRef = React.useRef<HTMLDivElement>(null);
    const [collected, drop] = useDrop({
        accept: "slide",
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    drop(dropRef);

    return (
        <div
            ref={dropRef}
            style={{
                width: "100%",
                height: "100%",
                borderTopWidth: 2,
                borderTopStyle: "solid",
                borderTopColor: collected.hover ? "#629BD4" : "transparent",
            }}
        ></div>
    );
};

interface Props {
    lastPresentationCanvasId?: number;
    moduleId: number;
    canvasTreeStore: CanvasTreeStore;
    show: boolean;
    sectionName: string;
    canWrite: boolean;
    onClearEditing: () => void;
    onUpdateBacgroundLayer: (
        canvasId: number,
        currentPageId?: number | string
    ) => void;
    onNewCanvas: (pageId: number) => void;
    onSaveSlide: (pageId: number) => void;
    onCanvasClick: (canvas: BriefCanvas) => void;
    onShowPageBarChange: () => void;
    onImport: (
        pageId: number,
        foreground: {
            canvas: Canvas["canvas"];
            backgrounds: CanvasBackground[];
        },
        background?: {
            canvas: Canvas["canvas"];
            backgrounds: CanvasBackground[];
        } | null
    ) => void;
    scale: number;
    rootRef: React.Ref<HTMLElement>;
}

interface PagePopupOptions {
    moduleId: number;
    title: string;
    pageId: number | null;
    type: PageType;
}

interface State {
    selectedCanvasesIds: number[];
    expanded: boolean;
    pagePopupOptions: PagePopupOptions | undefined;
    showWarningMessage: boolean;
    editPopupCanvas: BriefCanvas | undefined;
    canvasToDelete: BriefCanvas | undefined;
    canvasToSwitch: BriefCanvas | undefined;
    pageIdToDelete: number | undefined;
    versions: CanvasVersion[];
    versionCanvasId: number | null;
    enteredSlideCardDropArea: string;
    startDragSlideCard: {
        id: number | null;
    };
    createNamedVersionPopupOpen: boolean;
    contextMenuInfo: {
        canvas: BriefCanvas;
        clientX: number;
        clientY: number;
    } | null;
    pageMenuInfo: {
        pageId: number;
        clientX: number;
        clientY: number;
    } | null;
    sidebarMenuInfo: {
        clientX: number;
        clientY: number;
    } | null;
    versionMenuInfo: {
        clientX: number;
        clientY: number;
    } | null;
    editedPageId: number | null;
    editedPageTitle: string | null;
    status: {
        status: PopupStatus;
        message: string;
    } | null;
    slideForTemplate?: BriefCanvas;
}

@observer
class LoadPopup extends Component<Props, State> {
    private sidebarRef: React.RefObject<HTMLDivElement>;
    private slideCardRefs: { [key: number]: SlideCard | null };
    private contextMenuRef: React.RefObject<HTMLDivElement>;
    private inputRef: React.RefObject<HTMLInputElement>;
    private performance: Date | null;
    private reverting: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            expanded: true,
            pagePopupOptions: undefined,
            editPopupCanvas: undefined,
            showWarningMessage: false,
            canvasToDelete: undefined,
            canvasToSwitch: undefined,
            pageIdToDelete: undefined,
            versions: [],
            versionCanvasId: null,
            createNamedVersionPopupOpen: false,
            contextMenuInfo: null,
            pageMenuInfo: null,
            sidebarMenuInfo: null,
            versionMenuInfo: null,
            editedPageId: null,
            editedPageTitle: null,
            status: null,
            enteredSlideCardDropArea: "",
            startDragSlideCard: {
                id: null,
            },
            selectedCanvasesIds: []
        };
        this.sidebarRef = React.createRef();
        this.slideCardRefs = {};
        this.contextMenuRef = React.createRef();
        this.inputRef = React.createRef();
        this.performance = null;
        this.reverting = false;
        this.swapCanvases = this.swapCanvases.bind(this);
        this.closePopups = this.closePopups.bind(this);
        this.renderContents = this.renderContents.bind(this);
    }

    public componentDidMount(): void {
        document.addEventListener("mousedown", this.closePopups);
    }

    public componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.closePopups);
    }

    private closePopups(evt: MouseEvent): void {
        if (
            this.contextMenuRef.current != null &&
            !this.contextMenuRef.current.contains(evt.target as Node | null)
        ) {
            this.setState({
                contextMenuInfo: null,
                pageMenuInfo: null,
                sidebarMenuInfo: null,
                versionMenuInfo: null,
            });
        }
    }

    private async swapCanvases(
        pageId: number,
        dropPosition: string | undefined,
        canvasId: number | null,
        swapPageId: number,
        swapId: number | null
    ) {
        this.performance = new Date();

        if (this.state.selectedCanvasesIds.length) {
            let selectedCanvases = [];
            if(dropPosition === 'bottom' || dropPosition === undefined) {
                selectedCanvases =
                    this.state.selectedCanvasesIds.reverse();
            } else {
                selectedCanvases =
                    this.state.selectedCanvasesIds;
            }
            
            for (const id of selectedCanvases) {
                await moveCanvasApi(
                    canvasId,
                    dropPosition,
                    id,
                    pageId,
                    cookies.get("instrumentation_session_id")
                );
                if (pageId !== swapPageId) {
                    Canvases(swapPageId).update();
                    if (this.props.canvasTreeStore.canvasId === swapId) {
                        let oldPageId = this.props.canvasTreeStore.canvasPageId;
                        this.props.canvasTreeStore.joinPageRoom(
                            oldPageId,
                            pageId
                        );
                        this.props.canvasTreeStore.canvasPageId = pageId;
                    }
                }
            }

            Canvases(pageId).update();
            this.setState({
                selectedCanvasesIds: []
            });
        } else {
            moveCanvasApi(
                canvasId,
                dropPosition,
                swapId,
                pageId,
                cookies.get("instrumentation_session_id")
            )
                .then(() => {
                    Canvases(pageId).update();
                    if (pageId !== swapPageId) {
                        Canvases(swapPageId).update();
                        if (this.props.canvasTreeStore.canvasId === swapId) {
                            let oldPageId = this.props.canvasTreeStore.canvasPageId;
                            this.props.canvasTreeStore.joinPageRoom(
                                oldPageId,
                                pageId
                            );
                            this.props.canvasTreeStore.canvasPageId = pageId;
                        }
                    }
                })
                .catch((_error) => { });
        }
    }

    private getVersions(canvasId: number): void {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                versions: {
                    id: number;
                    created_automatically: boolean;
                    creation_time: number;
                    modification_time: number;
                    title: string | null;
                    thumbnail: string | null;
                }[];
            }>("/api/canvas_get_versions", {
                canvas_id: canvasId,
            })
            .then((response) => {
                if (!response.data.success) {
                    console.log(response.data.error_msg);
                } else {
                    this.setState({
                        versions: response.data.versions.map(
                            (version): CanvasVersion => ({
                                id: version.id,
                                createdAutomatically:
                                    version.created_automatically,
                                creationTime: new Date(
                                    version.creation_time * 1000
                                ),
                                modificationTime: new Date(
                                    version.modification_time * 1000
                                ),
                                title: version.title,
                                thumbnail: version.thumbnail,
                            })
                        ),
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private revertToVersion(versionId: number): void {
        if (this.reverting) return;
        this.reverting = true;
        const pageId = this.props.canvasTreeStore.canvasPageId as number;
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/canvas_revert_to_version", {
                version_id: versionId,
                update_id: cookies.get("instrumentation_session_id"),
            })
            .then((response) => {
                if (!response.data.success) {
                    console.log(response.data.error_msg);
                    this.reverting = false;
                } else {
                    this.props.canvasTreeStore.restoreFromPageAsyncAction
                        .bothParts(
                            pageId,
                            () => { },
                            false,
                            this.state.versionCanvasId ?? undefined
                        )
                        .then(() => {
                            this.reverting = false;
                        });

                    if (this.state.versionCanvasId != null) {
                        this.getVersions(this.state.versionCanvasId);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.reverting = false;
            });
    }

    private buildVersionControlHeader(): JSX.Element {
        return (
            <div
                className="flex-simple-column"
                style={{
                    marginTop: "9px",
                    marginLeft: "9px",
                }}
            >
                <span
                    className="unselectable"
                    style={{
                        color: mainStyle.getPropertyValue(
                            "--sheet-ribbon-title-color"
                        ),
                        fontFamily: "Roboto",
                        fontWeight: "normal",
                        fontSize: 10,
                        letterSpacing: "0.05em",
                    }}
                >
                    Version History
                </span>
                <div
                    style={{
                        marginTop: "6px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        width: "100%",
                    }}
                >
                    <div
                        style={{ marginLeft: "5px" }}
                        onClick={() => {
                            this.setState({
                                versionCanvasId: null,
                            });
                        }}
                    >
                        <img
                            title="Close Version History"
                            style={{
                                cursor: "pointer",
                                width: 12,
                                height: 12,
                            }}
                            alt=""
                            src={"/dist/img/canvas/version_control.png"}
                        />
                    </div>
                    {this.props.canWrite && (
                        <div
                            style={{ marginLeft: "2px" }}
                            onClick={() => {
                                this.setState({
                                    createNamedVersionPopupOpen: true,
                                });
                            }}
                        >
                            <img
                                title="Create Named Version"
                                style={{
                                    cursor: "pointer",
                                    width: 12,
                                    height: 12,
                                }}
                                alt=""
                                src={"/dist/img/canvas/add_slide.png"}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private buildCurrentCanvasHeader(
        canvasId: number,
        pageId: number
    ): JSX.Element {
        return (
            <div
                style={{
                    backgroundColor: mainStyle.getPropertyValue(
                        "--sheet-ribbon-highlight-color"
                    ),
                    paddingTop: "5px",
                    paddingRight: "5px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    width: "100%",
                }}
            >
                <div
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                        this.props.onSaveSlide(pageId);
                    }}
                >
                    <img
                        title="Save As"
                        style={{
                            cursor: "pointer",
                            width: 15,
                            height: 15,
                        }}
                        alt=""
                        src={"/dist/img/canvas/save_slide.png"}
                    />
                </div>
                {this.props.canWrite && (
                    <>
                        <div
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                                this.setState({
                                    versionCanvasId: canvasId,
                                });
                                this.getVersions(canvasId);
                            }}
                        >
                            <img
                                title="Version History"
                                style={{
                                    cursor: "pointer",
                                    width: 15,
                                    height: 15,
                                }}
                                alt=""
                                src={"/dist/img/canvas/version_control.png"}
                            />
                        </div>
                        <div style={{ marginLeft: "10px" }}>
                            <ColorPicker
                                width={"156px"}
                                rightAppearance
                                value={
                                    this.props.canvasTreeStore.slideColor ??
                                    mainStyle
                                        .getPropertyValue(
                                            "--slide-background-color"
                                        )
                                        .trim()
                                }
                                onChange={(newValue) => {
                                    this.props.canvasTreeStore.updateSlideColorAction(
                                        newValue
                                    );
                                }}
                                style={{
                                    width: 20,
                                    height: 20,
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }
    private buildInnerView(pageBarInfo: PageBarInfo | undefined): JSX.Element {

        let canvasesCount = 1;
        let lastCanvasesLength = 0;
        let lastPageId = PagesStore(PageType.Canvases)
            .pages[PagesStore(PageType.Canvases).pages.length -1]?.id;

        if (this.state.versionCanvasId != null) {
            let firstVersion = this.state.versions.findIndex(
                (version) => version.createdAutomatically
            );
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        marginTop: 10,
                        overflow: "visible",
                        alignItems: "center",
                    }}
                >
                    {this.state.versions.map((version, index) => (
                        <VersionCard
                            highlight={index === firstVersion}
                            version={version}
                            canWrite={this.props.canWrite}
                            key={version.id}
                            onClick={() => {
                                this.revertToVersion(version.id);
                            }}
                            onEdit={() => { }}
                            onDelete={() => { }}
                        />
                    ))}
                </div>
            );
        } else {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        marginTop: 10,
                        overflow: "visible",
                        alignItems: "start",
                        height: `calc(100% - 10px)`,
                    }}
                >
                    {PagesStore(PageType.Canvases).pages.map((page, index) => {
                        const canvases = Canvases(page.id).canvases;
                        if(index > 0)
                            canvasesCount += lastCanvasesLength;

                        lastCanvasesLength = canvases.length;

                        return (
                            <div
                                key={index}
                                className="flex-simple-column"
                                style={{ 
                                    display: "flex",
                                    width: "100%", 
                                    height: (page.id === lastPageId) ? "100%" : 'auto',
                                }}
                            >
                                {(pageBarInfo?.show ?? false) && (
                                    <div
                                        className="flex-simple-column"
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                    >
                                        {this.state.editedPageId ===
                                            page.id && (
                                                <input
                                                    ref={this.inputRef}
                                                    className="bordered"
                                                    style={{
                                                        paddingLeft: 2,
                                                        paddingRight: 2,
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                        marginTop: 23,
                                                        marginBottom: 1,
                                                        fontFamily: "Roboto",
                                                        fontSize: 11,
                                                        color:
                                                            "var(--sheet-ribbon-section-title-color)",
                                                        fontWeight: "bold",
                                                        width: 150,
                                                        height: 20,
                                                    }}
                                                    value={
                                                        this.state
                                                            .editedPageTitle ??
                                                        ""
                                                    }
                                                    onChange={(e) => {
                                                        let value =
                                                            e.target.value;
                                                        this.setState({
                                                            editedPageTitle: value,
                                                        });
                                                    }}
                                                    onBlur={() => {
                                                        if (
                                                            this.state
                                                                .editedPageId ==
                                                            null ||
                                                            !this.state
                                                                .editedPageTitle
                                                        )
                                                            return;
                                                        editUserPageModuleApi(
                                                            this.state
                                                                .editedPageTitle,
                                                            this.state
                                                                .editedPageId,
                                                            cookies.get(
                                                                "instrumentation_session_id"
                                                            )
                                                        )
                                                            .then(() => {
                                                                PagesStore(
                                                                    PageType.Canvases
                                                                ).updatePages();
                                                                Canvases(
                                                                    this.state
                                                                        .editedPageId!
                                                                ).update();
                                                            })
                                                            .catch(
                                                                (
                                                                    errorMessage
                                                                ) => {
                                                                    console.log(
                                                                        errorMessage
                                                                    );
                                                                }
                                                            );
                                                        this.setState({
                                                            editedPageId: null,
                                                            editedPageTitle: null,
                                                        });
                                                    }}
                                                />
                                            )}
                                        {this.state.editedPageId !==
                                            page.id && (
                                                <span
                                                    className="unselectable line-clamp"
                                                    style={{
                                                        marginTop: 25,
                                                        marginBottom: 3,
                                                        fontFamily: "Roboto",
                                                        fontSize: "11px",
                                                        color:
                                                            "var(--sheet-ribbon-section-title-color)",
                                                        fontWeight: "bold",
                                                        width: 144,
                                                        height: 16,
                                                    }}
                                                    onDoubleClick={() => {
                                                        this.setState(
                                                            {
                                                                editedPageId:
                                                                    page.id,
                                                                editedPageTitle:
                                                                    page.title,
                                                            },
                                                            () => {
                                                                this.inputRef.current?.focus(
                                                                    {
                                                                        preventScroll: true,
                                                                    }
                                                                );
                                                                this.inputRef.current?.setSelectionRange(
                                                                    0,
                                                                    page.title
                                                                        .length
                                                                );
                                                            }
                                                        );
                                                    }}
                                                    onContextMenu={(evt) => {
                                                        evt.stopPropagation();
                                                        evt.preventDefault();
                                                        this.setState({
                                                            pageMenuInfo: {
                                                                pageId: page.id,
                                                                clientX:
                                                                    evt.clientX,
                                                                clientY:
                                                                    evt.clientY,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    {page.title}
                                                </span>
                                            )}
                                        <div
                                            style={{
                                                backgroundColor:
                                                    "var(--sheet-ribbon-section-underline-color)",
                                                width: 144,
                                                height: 1,
                                                marginBottom: 7,
                                            }}
                                        />
                                    </div>
                                )}
                                {canvases.length === 0 &&
                                    (pageBarInfo?.show ?? false) && (
                                        <DraggableCanvasCard
                                            canvasId={null}
                                            pageId={page.id}
                                            setStartDragSlideId={() => { }}
                                            onCanvasesSwap={this.swapCanvases}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: 100,
                                                }}
                                            ></div>
                                        </DraggableCanvasCard>
                                    )}                              
                                {canvases.map((canvas, canvasIdx) => {
                                    if (canvas.layer_for_canvas_id)
                                        return <></>;

                                    let highlightString: string | undefined =
                                        "";
                                    let highlightAsOption = false;
                                    let highlightAsSelected = false;
                                    if (
                                        FormulaInformationStore.highlightItems &&
                                        FormulaInformationStore.currentItem
                                            ?.canvasId === canvas.id
                                    ) {
                                        highlightString =
                                            FormulaInformationStore.currentItem
                                                .outerId;
                                        highlightAsOption = true;
                                    } else {
                                        let sharedBox = SharedBoxesStore.sharedBoxes(
                                            this.props.canvasTreeStore.canvasId!
                                        ).find(
                                            (sharedBox) =>
                                                FormulaInformationStore.editOutput?.childrenSharedIds?.find(
                                                    (item) =>
                                                        item.value ===
                                                        sharedBox.item.id
                                                ) != null &&
                                                sharedBox.canvasId ===
                                                canvas.id &&
                                                sharedBox.canvasId !==
                                                this.props.canvasTreeStore
                                                    .canvasId
                                        );
                                        if (sharedBox != null) {
                                            highlightString = sharedBox.outerId;
                                            highlightAsSelected = true;
                                        }
                                    }

                                    const startDragSlideCardx = canvases.findIndex(
                                        (canvas) =>
                                            canvas.id ===
                                            this.state.startDragSlideCard.id
                                    );
                                    const prevCanvasId =
                                        canvases[startDragSlideCardx - 1]?.id;
                                    const nextCanvasId =
                                        startDragSlideCardx <
                                            canvases.length - 1
                                            ? canvases[startDragSlideCardx + 1]
                                                ?.id
                                            : null;

                                    const _setSelectedCanvases = () => {
                                        const idx1 = canvases.findIndex(object => {
                                            return object.id === canvas.id;
                                        });
                                        const idx2 = canvases.findIndex(object => {
                                            return object.id === this.props.canvasTreeStore.canvasId;
                                        });

                                        let subset = canvases
                                            .slice(Math.min(idx1, idx2), Math.max(idx1, idx2) + 1)
                                            .map((x) => x.id);

                                        this.setState({
                                            selectedCanvasesIds: subset
                                        });
                                    }

                                    return (
                                        <DraggableCanvasCard
                                            key={canvas.id}
                                            canvasId={canvas.id}
                                            pageId={page.id}
                                            prevCanvasId={prevCanvasId}
                                            nextCanvasId={nextCanvasId}
                                            enteredSlideCardDropArea={
                                                this.state
                                                    .enteredSlideCardDropArea
                                            }
                                            startDragSlideCard={
                                                this.state.startDragSlideCard
                                            }
                                            onCanvasesSwap={this.swapCanvases}
                                            setStartDragSlideId={(id) => {
                                                this.setState({
                                                    startDragSlideCard: {
                                                        id,
                                                    },
                                                });
                                            }}
                                        >
                                            <SlideCard
                                                index={canvasesCount + canvasIdx}
                                                isFirstOfSection={canvasIdx === 0}
                                                ref={(ref) => {
                                                    this.slideCardRefs[
                                                        canvas.id
                                                    ] = ref;
                                                    return true;
                                                }}
                                                canvas={canvas}
                                                highlight={
                                                    canvas.id ===
                                                    this.props
                                                        .lastPresentationCanvasId ||
                                                    this.props.canvasTreeStore
                                                        .canvasId === canvas.id ||
                                                    this.state.selectedCanvasesIds.includes(canvas.id)
                                                }
                                                onSlideCardDropAreaEnter={(
                                                    position
                                                ) => {
                                                    this.setState({
                                                        enteredSlideCardDropArea: position,
                                                    });
                                                }}
                                                highlightAsSelected={
                                                    highlightAsSelected
                                                }
                                                highlightAsOption={
                                                    highlightAsOption
                                                }
                                                highlightString={
                                                    highlightString
                                                }
                                                hasNewFeedbacks={
                                                    canvas.has_new_notifications
                                                }
                                                canWrite={this.props.canWrite}
                                                key={canvas.id}
                                                onClick={(evt:React.MouseEvent<HTMLDivElement>) => {
                                                    if (evt.shiftKey) {
                                                      	_setSelectedCanvases();
                                                      return
                                                    } else if (this.state.selectedCanvasesIds.length) {
                                                        this.setState({
                                                            selectedCanvasesIds: []
                                                        });
                                                    }

                                                    this.props.onCanvasClick(
                                                        canvas
                                                    );
                                                }}
                                                onEdit={() => {
                                                    this.setState({
                                                        editPopupCanvas: canvas,
                                                    });
                                                }}
                                                onContextMenu={(
                                                    canvas,
                                                    evt
                                                ) => {
                                                    this.setState({
                                                        contextMenuInfo: {
                                                            canvas,
                                                            clientX:
                                                                evt.clientX,
                                                            clientY:
                                                                evt.clientY,
                                                        },
                                                    });
                                                }}
                                            />
                                        </DraggableCanvasCard>
                                    );
                                })}
                                {
                                    (page.id === lastPageId) && 
                                    <DraggableCanvasCard
                                        key={"empty-space"}
                                        canvasId={canvases[canvases.length - 1]?.id}
                                        pageId={page.id}
                                        setStartDragSlideId={() => { }}
                                        onCanvasesSwap={this.swapCanvases}
                                        style={{ 
                                            flexGrow: 1,
                                            cursor: "auto" 
                                        }}
                                        emptySpaceSlide={true}
                                    >
                                        <EmptySpaceSlide />
                                    </DraggableCanvasCard>
                                }
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    private addNewSlide(pageId: number, slideCount: number) {
        let canvases = Canvases(pageId).canvases;
        let currentCanvasId = canvases[canvases.length - 1]?.id;
        this.props.onClearEditing();
        this.props.canvasTreeStore.clearAllFieldsAction();
        let currentPageId = this.props.canvasTreeStore.canvasPageId;
        let titles: Set<string> = new Set(
            Canvases(pageId).canvases.map((canvas) => canvas.title)
        );
        let newTitle = String(slideCount + 1);
        while (titles.has(newTitle)) {
            slideCount += 1;
            newTitle = String(slideCount + 1);
        }
        addCanvasApi(
            pageId,
            newTitle,
            this.props.canvasTreeStore.serialize(),
            this.props.canvasTreeStore.backgroundsState.toJSON(),
            null,
            [],
            currentCanvasId,
            // undefined,
            cookies.get("instrumentation_session_id")
        )
            .then((id) => {
                Canvases(pageId).update();
                this.props.canvasTreeStore.joinPageRoom(currentPageId, pageId);
                this.props.canvasTreeStore.canvasPageId = pageId;
                this.props.canvasTreeStore.joinCanvasRoom(id, currentCanvasId);
                this.props.canvasTreeStore.canvasId = id;
            })
            .catch((errorMessage) => {
                console.log(errorMessage);
            });
    }

    private copySlide(originalTitle: string, pageId: number, canvasId: number) {
        let index = 1;
        let newTitle = `${originalTitle} (Copy ${index})`;
        let titles: Set<string> = new Set(
            Canvases(pageId).canvases.map((canvas) => canvas.title)
        );
        while (titles.has(newTitle)) {
            index += 1;
            newTitle = `${originalTitle} (Copy ${index})`;
        }

        copyCanvasApi(
            pageId,
            newTitle,
            canvasId,
            [],
            cookies.get("instrumentation_session_id")
        )
            .then((id) => {
                Canvases(pageId).update();
            })
            .catch((errorMessage) => {
                console.log(errorMessage);
            });
    }

    public renderContents(globalContext: GlobalContextContents): JSX.Element {
        if (!this.state.expanded) {
            return (
                <div
                    ref={this.sidebarRef}
                    style={{
                        overflow: "visible",
                        zIndex: 500,
                        position: "absolute",
                        right: 0,
                        top: 70,
                        width: 54,
                        height: 54,
                        backgroundColor:
                            "var(--sheet-ribbon-collapsed-background-color)",
                        borderRadius: "6px 0 0 6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        this.setState({ expanded: true });
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/canvas/sidebar/SideBarExpand.svg"
                    />
                </div>
            );
        }
        let pageBarInfo = CurrentModulesStore.getModule(this.props.moduleId)
            ?.page_bar_info;

        return (
            <>
                <div
                    ref={this.sidebarRef}
                    style={{
                        overflow: "visible",
                        zIndex: 500,
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        width: sheetRibbonWidth,
                        height: `calc(100% - ${headerBarHeight}px)`,
                        backgroundColor: "var(--sheet-ribbon-background-color)",
                        borderRadius: 0,
                        boxShadow: "-4px 0px 4px 0px #657D951A",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{
                            width: "100%",
                            height: 100,
                            backgroundColor:
                                "var(--sheet-ribbon-header-background-color)",
                        }}
                    >
                        <div
                            style={{
                                marginLeft: 5,
                                marginTop: 10,
                                marginBottom: 6,
                                width: "fit-content",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                this.setState({ expanded: false });
                            }}
                        >
                            <img
                                alt=""
                                src="/dist/img/canvas/sidebar/SideBarCollapse.svg"
                            />
                        </div>
                        <div
                            className="my-row"
                            style={{ justifyContent: "center" }}
                        >
                            {this.state.versionCanvasId != null ? (
                                <div
                                    style={{
                                        width: 98,
                                        height: 20,
                                    }}
                                />
                            ) : (
                                <Button
                                    type="button"
                                    className="btn btn-sm btn-primary my-primary"
                                    style={{
                                        width: 98,
                                        height: 20,
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                        borderRadius: 3,
                                        padding: 0,
                                    }}
                                    onClick={async (
                                        evt: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        evt.stopPropagation();
                                        let pages = PagesStore(
                                            PageType.Canvases
                                        ).pages;
                                        let lastPage = pages[pages.length - 1];
                                        let slideCount = 0;
                                        for (let page of pages) {
                                            slideCount += Canvases(page.id)
                                                .canvases.length;
                                        }
                                        if (lastPage == null) {
                                            try {
                                                const pageId = await addUserPageToModuleApi(
                                                    "Section Name 1",
                                                    this.props.moduleId
                                                );
                                                PagesStore(
                                                    PageType.Canvases
                                                ).updatePages();
                                                this.addNewSlide(
                                                    pageId,
                                                    slideCount
                                                );
                                            } catch (error) {
                                                console.log(error);
                                            }
                                        } else
                                            this.addNewSlide(
                                                lastPage.id,
                                                slideCount
                                            );
                                    }}
                                >
                                    New Slide
                                </Button>
                            )}
                        </div>
                        <div
                            className="my-row"
                            style={{
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                            }}
                        >
                            {this.state.versionCanvasId != null ? (
                                <div
                                    style={{
                                        width: 16,
                                        height: 7,
                                    }}
                                />
                            ) : (
                                <Switch
                                    onChange={() => {
                                        CurrentModulesStore.toggleShowPageBar(
                                            this.props.moduleId,
                                            defaultSlideWidth
                                        );
                                        this.props.onShowPageBarChange();
                                    }}
                                    checked={pageBarInfo?.show ?? false}
                                    width={24}
                                    height={10}
                                    handleDiameter={12}
                                    offColor={lightThemeStyle.switchOffColor}
                                    onColor={lightThemeStyle.switchOffColor}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor={
                                        lightThemeStyle.switchOffHandleColor
                                    }
                                    onHandleColor={
                                        lightThemeStyle.switchOnHandleColor
                                    }
                                />
                            )}
                            <span
                                style={{
                                    marginLeft: 7,
                                    marginRight: 9,
                                    fontFamily: "Roboto",
                                    fontSize: 12,
                                    color:
                                        "var(--sheet-ribbon-section-title-color)",
                                    fontWeight: "bold",
                                }}
                            >
                                {this.state.versionCanvasId != null
                                    ? "Version History"
                                    : "Enable Sections"}
                            </span>
                            {pageBarInfo?.show ||
                                this.state.versionCanvasId != null ? (
                                <div
                                    style={{
                                        width: 14,
                                        height: 14,
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        backgroundColor:
                                            "var(--secondary-content-color)",
                                        borderRadius: "50px",
                                        cursor: "pointer",
                                    }}
                                    onClick={(evt) => {
                                        if (
                                            this.state.versionCanvasId != null
                                        ) {
                                            this.setState({
                                                versionMenuInfo: {
                                                    clientX: evt.clientX,
                                                    clientY: evt.clientY,
                                                },
                                            });
                                        } else {
                                            this.setState({
                                                sidebarMenuInfo: {
                                                    clientX: evt.clientX,
                                                    clientY: evt.clientY,
                                                },
                                            });
                                        }
                                    }}
                                >
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <mask id="path-2-inside-1" fill="white">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M10 16C10 17.1046 9.10457 18 8 18C6.89543 18 6 17.1046 6 16C6 14.8954 6.89543 14 8 14C9.10457 14 10 14.8954 10 16ZM18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16ZM24 18C25.1046 18 26 17.1046 26 16C26 14.8954 25.1046 14 24 14C22.8954 14 22 14.8954 22 16C22 17.1046 22.8954 18 24 18Z"
                                            />
                                        </mask>
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10 16C10 17.1046 9.10457 18 8 18C6.89543 18 6 17.1046 6 16C6 14.8954 6.89543 14 8 14C9.10457 14 10 14.8954 10 16ZM18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16ZM24 18C25.1046 18 26 17.1046 26 16C26 14.8954 25.1046 14 24 14C22.8954 14 22 14.8954 22 16C22 17.1046 22.8954 18 24 18Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M8 20C10.2091 20 12 18.2091 12 16H8V20ZM4 16C4 18.2091 5.79086 20 8 20V16H4ZM8 12C5.79086 12 4 13.7909 4 16H8V12ZM12 16C12 13.7909 10.2091 12 8 12V16H12ZM16 20C18.2091 20 20 18.2091 20 16H16V20ZM12 16C12 18.2091 13.7909 20 16 20V16H12ZM16 12C13.7909 12 12 13.7909 12 16H16V12ZM20 16C20 13.7909 18.2091 12 16 12V16H20ZM24 16V20C26.2091 20 28 18.2091 28 16H24ZM24 16H28C28 13.7909 26.2091 12 24 12V16ZM24 16V12C21.7909 12 20 13.7909 20 16H24ZM24 16H20C20 18.2091 21.7909 20 24 20V16Z"
                                            fill="white"
                                            mask="url(#path-2-inside-1)"
                                        />
                                    </svg>
                                </div>
                            ) : (
                                <div style={{ width: 14, height: 14 }} />
                            )}
                        </div>
                    </div>
                    <div
                        className="element"
                        style={{
                            overflow: "auto",
                            alignItems: "center",
                            cursor: "pointer",
                            height: "calc(100% - 100px)",
                            width: "100%",
                        }}
                    >
                        {this.buildInnerView(pageBarInfo)}
                        {this.state.showWarningMessage && (
                            <MessagePopup
                                danger
                                title={`Remove ${this.state?.canvasToDelete
                                    ?.layer_for_canvas_id
                                    ? "background layer"
                                    : "slide"
                                    }`}
                                acceptButtonTitle={"Remove"}
                                message={`Do you want to remove saved ${this.state?.canvasToDelete
                                    ?.layer_for_canvas_id
                                    ? "background layer"
                                    : "slide"
                                    }?`}
                                onAccept={() => {
                                    if (this.state.canvasToDelete != null) {
                                        this.performance = new Date();
                                        let canvasId = this.state.canvasToDelete
                                            .id;
                                        let pageId = this.state.canvasToDelete
                                            .page_id;
                                        let currentCanvasId = this.props
                                            .canvasTreeStore.canvasId;
                                        let deleteCurrent =
                                            currentCanvasId === canvasId;
                                        let prevCanvas:
                                            | BriefCanvas
                                            | undefined = undefined;
                                        if (deleteCurrent) {
                                            let prevIndex = Canvases(
                                                pageId
                                            ).canvases.findIndex(
                                                (item) => item.id === canvasId
                                            );
                                            if (prevIndex > 0) prevIndex -= 1;
                                            else if (prevIndex === 0)
                                                prevIndex += 1;
                                            if (
                                                prevIndex >= 0 &&
                                                prevIndex <
                                                Canvases(pageId).canvases
                                                    .length
                                            ) {
                                                prevCanvas = Canvases(pageId)
                                                    .canvases[prevIndex];
                                            }
                                        }
                                        deleteCanvasApi(
                                            canvasId,
                                            cookies.get(
                                                "instrumentation_session_id"
                                            )
                                        )
                                            .then(() => {
                                                Canvases(pageId).deleteCanvas(
                                                    canvasId
                                                );

                                                if (this.state.canvasToSwitch) {
                                                    this.props.onCanvasClick(
                                                        this.state
                                                            .canvasToSwitch
                                                    );
                                                }

                                                this.setState({
                                                    showWarningMessage: false,
                                                    canvasToDelete: undefined,
                                                    canvasToSwitch: undefined,
                                                });

                                                if (deleteCurrent) {
                                                    if (prevCanvas != null)
                                                        this.props.onCanvasClick(
                                                            prevCanvas
                                                        );
                                                    else {
                                                        this.props.onClearEditing();
                                                        this.props.canvasTreeStore.clearAllFieldsAction();
                                                    }
                                                }
                                            })
                                            .catch((error) => {
                                                this.setState({
                                                    showWarningMessage: false,
                                                    canvasToDelete: undefined,
                                                    canvasToSwitch: undefined,
                                                });
                                                console.log(error);
                                            });
                                    }
                                }}
                                onReject={() => {
                                    this.setState({
                                        showWarningMessage: false,
                                        canvasToDelete: undefined,
                                    });
                                }}
                            />
                        )}
                    </div>
                    {this.state.editPopupCanvas && (
                        <EditCanvasPopup
                            canEditLiveStreaming={true}
                            canvas={this.state.editPopupCanvas}
                            onClose={() => {
                                this.setState({
                                    editPopupCanvas: undefined,
                                });
                            }}
                        />
                    )}
                    {this.state.createNamedVersionPopupOpen && (
                        <CreateNamedVersionPopup
                            canvasTreeStore={this.props.canvasTreeStore}
                            scale={this.props.scale}
                            onCreate={(_title) => {
                                if (this.state.versionCanvasId != null) {
                                    this.getVersions(
                                        this.state.versionCanvasId
                                    );
                                }
                            }}
                            onClose={() => {
                                this.setState({
                                    createNamedVersionPopupOpen: false,
                                });
                            }}
                            rootRef={this.props.rootRef}
                        />
                    )}
                    {this.state.pagePopupOptions && (
                        <AddPagePopup
                            onClose={() => {
                                this.setState({ pagePopupOptions: undefined });
                            }}
                            {...this.state.pagePopupOptions}
                        />
                    )}
                    {this.state.pageIdToDelete != null && (
                        <MessagePopup
                            acceptButtonTitle={"Delete"}
                            danger
                            title={"Delete section"}
                            message={"Do you want to delete this section?"}
                            onAccept={() => {
                                this.performance = new Date();
                                deleteUserPageModuleApi(
                                    this.state.pageIdToDelete!,
                                    cookies.get("instrumentation_session_id")
                                )
                                    .then(() => {
                                        PagesStore(
                                            PageType.Canvases
                                        ).updatePages();
                                        if (
                                            this.state.pageIdToDelete ===
                                            this.props.canvasTreeStore
                                                .canvasPageId
                                        ) {
                                            this.props.canvasTreeStore.restoreFromPageAsyncAction.bothParts(
                                                undefined,
                                                () => { }
                                            );
                                        }
                                        this.setState({
                                            pageIdToDelete: undefined,
                                        });
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            pageIdToDelete: undefined,
                                        });
                                    });
                            }}
                            onReject={() => {
                                this.setState({
                                    pageIdToDelete: undefined,
                                });
                            }}
                        />
                    )}
                    {this.state.pageMenuInfo != null &&
                        this.sidebarRef.current != null && (
                            <div
                                ref={this.contextMenuRef}
                                className="canvas-menu"
                                style={{
                                    zIndex: 998,
                                    position: "absolute",
                                    left: Math.min(
                                        this.state.pageMenuInfo.clientX -
                                        this.sidebarRef.current.offsetLeft,
                                        this.sidebarRef.current.clientWidth -
                                        150 -
                                        10
                                    ),
                                    top: Math.min(
                                        this.state.pageMenuInfo.clientY -
                                        this.sidebarRef.current.offsetTop,
                                        this.sidebarRef.current.clientHeight -
                                        150 -
                                        10
                                    ),
                                    width: 150,
                                    alignItems: "flex-start",
                                }}
                            >
                                <div>
                                    <PreventMouseLeaveButton
                                        onClick={() => {
                                            this.setState((state) => ({
                                                showWarningMessage: true,
                                                pageIdToDelete: state.pageMenuInfo!
                                                    .pageId,
                                                pageMenuInfo: null,
                                            }));
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-delete-color)",
                                        }}
                                    >
                                        DELETE SECTION
                                    </PreventMouseLeaveButton>
                                </div>
                            </div>
                        )}
                    {this.state.contextMenuInfo != null &&
                        this.sidebarRef.current != null && (
                            <div
                                ref={this.contextMenuRef}
                                className="canvas-menu"
                                style={{
                                    zIndex: 998,
                                    position: "absolute",
                                    left: Math.min(
                                        this.state.contextMenuInfo.clientX -
                                        this.sidebarRef.current.offsetLeft,
                                        this.sidebarRef.current.clientWidth -
                                        150 -
                                        10
                                    ),
                                    top: Math.min(
                                        this.state.contextMenuInfo.clientY -
                                        this.sidebarRef.current.offsetTop,
                                        this.sidebarRef.current.clientHeight -
                                        280 -
                                        10
                                    ),
                                    width: 150,
                                    alignItems: "flex-start",
                                }}
                            >
                                <div>
                                    {pageBarInfo?.show &&
                                        !pageBarInfo?.hiddenOnPage &&
                                        this.props.canvasTreeStore.canvasId ===
                                        this.state.contextMenuInfo!.canvas
                                            .id && (
                                            <>
                                                <PreventMouseLeaveButton
                                                    onClick={() => {
                                                        this.props.canvasTreeStore.updateHidePagesBarAction(
                                                            !this.props
                                                                .canvasTreeStore
                                                                .hidePagesBar
                                                        );
                                                        this.setState({
                                                            contextMenuInfo: null,
                                                        });
                                                    }}
                                                    style={{
                                                        color:
                                                            "var(--sheet-ribbon-context-menu-item-text-color)",
                                                    }}
                                                >
                                                    {this.props.canvasTreeStore
                                                        .hidePagesBar
                                                        ? "SHOW SECTION BAR"
                                                        : "HIDE SECTION BAR"}
                                                </PreventMouseLeaveButton>
                                            </>
                                        )}
                                    <PreventMouseLeaveButton
                                        onClick={() => {
                                            const canvasId = this.state
                                                .contextMenuInfo?.canvas.id;
                                            if (canvasId != null) {
                                                const slide = this
                                                    .slideCardRefs[canvasId];
                                                if (slide) {
                                                    slide.startSlideEdit(
                                                        this.state
                                                            .contextMenuInfo!
                                                            .canvas.title
                                                    );
                                                }
                                            }
                                            this.setState({
                                                contextMenuInfo: null,
                                            });
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        RENAME SLIDE
                                    </PreventMouseLeaveButton>
                                    <PreventMouseLeaveButton
                                        onClick={() => {
                                            this.getVersions(
                                                this.state.contextMenuInfo!
                                                    .canvas.id
                                            );
                                            this.setState((state) => ({
                                                versionCanvasId: state.contextMenuInfo!
                                                    .canvas.id,
                                                contextMenuInfo: null,
                                            }));
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        VERSION HISTORY
                                    </PreventMouseLeaveButton>
                                    <PreventMouseLeaveButton
                                        onClick={async () => {
                                            let contents: string = "";
                                            if (
                                                this.state.contextMenuInfo
                                                    ?.canvas.id ===
                                                this.props.canvasTreeStore
                                                    .canvasId
                                            ) {
                                                contents = JSON.stringify({
                                                    foreground: {
                                                        canvas: this.props.canvasTreeStore.serialize(),
                                                        backgrounds: this.props.canvasTreeStore.backgroundsState.toJSON(),
                                                    },
                                                });
                                            } else {
                                                let canvasId = this.state
                                                    .contextMenuInfo!.canvas.id;
                                                let pageId = this.state
                                                    .contextMenuInfo!.canvas
                                                    .page_id;
                                                try {
                                                    await Canvases(
                                                        pageId
                                                    ).update();
                                                    const canvases = Canvases(
                                                        pageId
                                                    );
                                                    let canvas = await canvases.getFullCanvas(
                                                        canvasId
                                                    );
                                                    contents = JSON.stringify({
                                                        foreground: {
                                                            canvas:
                                                                canvas.canvas,
                                                            backgrounds:
                                                                canvas.backgrounds,
                                                        },
                                                    });
                                                } catch (error) {
                                                    console.log(String(error));
                                                }
                                            }
                                            const element = document.createElement(
                                                "a"
                                            );
                                            const file = new Blob([contents], {
                                                type: "text/plain",
                                            });
                                            element.href = URL.createObjectURL(
                                                file
                                            );
                                            element.download = "canvas.json";
                                            document.body.appendChild(element);
                                            element.click();
                                            this.setState({
                                                contextMenuInfo: null,
                                            });
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        EXPORT SLIDE
                                    </PreventMouseLeaveButton>
                                    <PreventMouseLeaveButton
                                        onClick={async () => {
                                            let pageId = this.state
                                                .contextMenuInfo!.canvas
                                                .page_id;
                                            this.copySlide(
                                                this.state.contextMenuInfo!
                                                    .canvas.title,
                                                pageId,
                                                this.state.contextMenuInfo!
                                                    .canvas.id
                                            );

                                            this.setState({
                                                contextMenuInfo: null,
                                            });
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        DUPLICATE SLIDE
                                    </PreventMouseLeaveButton>
                                    <PreventMouseLeaveButton
                                        onClick={async () => {
                                            let canvas = this.state
                                                .contextMenuInfo!.canvas;
                                            hideInSlideShow(
                                                canvas.id,
                                                !canvas.hide_in_slideshow,
                                                cookies.get(
                                                    "instrumentation_session_id"
                                                )
                                            )
                                                .then(() => {
                                                    Canvases(
                                                        canvas.page_id
                                                    ).hideInSlideShow(
                                                        canvas.id,
                                                        !canvas.hide_in_slideshow
                                                    );
                                                })
                                                .catch((error) => {
                                                    console.log(String(error));
                                                });

                                            this.setState({
                                                contextMenuInfo: null,
                                            });
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        {`${!this.state.contextMenuInfo!.canvas
                                            .hide_in_slideshow
                                            ? "HIDE"
                                            : "SHOW"
                                            } IN SLIDESHOW`}
                                    </PreventMouseLeaveButton>
                                    {"CreateSlideTemplates" in
                                        globalContext.permissions && (
                                            <PreventMouseLeaveButton
                                                onClick={() => {
                                                    let canvas = this.state
                                                        .contextMenuInfo!.canvas;

                                                    this.setState({
                                                        slideForTemplate: canvas,
                                                        contextMenuInfo: null,
                                                    });
                                                }}
                                                style={{
                                                    color:
                                                        "var(--sheet-ribbon-context-menu-item-text-color)",
                                                }}
                                            >
                                                {"SAVE AS THEME"}
                                            </PreventMouseLeaveButton>
                                        )}
                                    <label
                                        htmlFor={`load-canvas-file-selector-${this.state.contextMenuInfo.canvas.page_id}`}
                                        style={{
                                            marginBottom: 0,
                                            width: "100%",
                                            fontWeight: "normal",
                                            cursor: "pointer",
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                            textAlign: "start",
                                            paddingLeft: 12,
                                            paddingRight: 12,
                                        }}
                                    >
                                        IMPORT SLIDE
                                    </label>
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={(event) => {
                                            const files =
                                                event?.target?.files ?? [];
                                            if (files.length !== 0) {
                                                const file: File = files[0];
                                                let reader: FileReader = new FileReader();
                                                reader.onload = () => {
                                                    if (
                                                        typeof reader.result ===
                                                        "string"
                                                    ) {
                                                        let contents = JSON.parse(
                                                            reader.result
                                                        );

                                                        if (
                                                            contents.foreground
                                                        ) {
                                                            this.props.onImport(
                                                                this.state
                                                                    .contextMenuInfo!
                                                                    .canvas
                                                                    .page_id,
                                                                contents.foreground,
                                                                contents.background
                                                            );
                                                        } else {
                                                            const foreground = {
                                                                canvas:
                                                                    contents.canvas,
                                                                backgrounds:
                                                                    contents.backgrounds ??
                                                                    [],
                                                            };
                                                            this.props.onImport(
                                                                this.state
                                                                    .contextMenuInfo!
                                                                    .canvas
                                                                    .page_id,
                                                                foreground,
                                                                null
                                                            );
                                                        }
                                                    }
                                                    this.setState({
                                                        contextMenuInfo: null,
                                                    });
                                                };
                                                event.target.value = "";
                                                reader.readAsText(file);
                                            }
                                        }}
                                        id={`load-canvas-file-selector-${this.state.contextMenuInfo.canvas.page_id}`}
                                    />
                                    <PreventMouseLeaveButton
                                        onClick={() => {
                                            this.setState((state) => ({
                                                showWarningMessage: true,
                                                canvasToDelete: state.contextMenuInfo!
                                                    .canvas,
                                                contextMenuInfo: null,
                                            }));
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-delete-color)",
                                        }}
                                    >
                                        DELETE SLIDE
                                    </PreventMouseLeaveButton>
                                </div>
                            </div>
                        )}
                    {this.state.sidebarMenuInfo != null &&
                        this.sidebarRef.current != null && (
                            <div
                                ref={this.contextMenuRef}
                                className="canvas-menu"
                                style={{
                                    zIndex: 998,
                                    position: "absolute",
                                    left: Math.min(
                                        this.state.sidebarMenuInfo.clientX -
                                        this.sidebarRef.current.offsetLeft,
                                        this.sidebarRef.current.clientWidth -
                                        153 -
                                        10
                                    ),
                                    top: Math.min(
                                        this.state.sidebarMenuInfo.clientY -
                                        this.sidebarRef.current.offsetTop,
                                        this.sidebarRef.current.clientHeight -
                                        150 -
                                        10
                                    ),
                                    width: 153,
                                    alignItems: "flex-start",
                                }}
                            >
                                <div>
                                    <PreventMouseLeaveButton
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            this.setState({
                                                sidebarMenuInfo: null,
                                            });
                                            let pages = PagesStore(
                                                PageType.Canvases
                                            ).pages;
                                            let pageNumber = pages.length;
                                            let title = `Section Name ${pageNumber}`;
                                            let titles = new Set(
                                                pages.map((page) => page.title)
                                            );
                                            while (titles.has(title)) {
                                                pageNumber += 1;
                                                title = `Section Name ${pageNumber}`;
                                            }
                                            addUserPageToModuleApi(
                                                title,
                                                this.props.canvasTreeStore
                                                    .moduleId!,
                                                cookies.get(
                                                    "instrumentation_session_id"
                                                )
                                            )
                                                .then((id) => {
                                                    PagesStore(
                                                        PageType.Canvases
                                                    ).updatePages();
                                                    this.addNewSlide(id, 0);
                                                })
                                                .catch((errorMessage) => {
                                                    console.log(errorMessage);
                                                });
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        + ADD SECTION
                                    </PreventMouseLeaveButton>
                                    <div
                                        style={{
                                            backgroundColor:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                            width: "100%",
                                            height: 1,
                                        }}
                                    />
                                    <PreventMouseLeaveButton
                                        onClick={() => {
                                            CurrentModulesStore.updatePageBar(
                                                this.props.moduleId,
                                                {
                                                    unlocked: !pageBarInfo!
                                                        .unlocked,
                                                }
                                            );
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        {pageBarInfo!.unlocked
                                            ? "LOCK SECTION BAR"
                                            : "UNLOCK SECTION BAR"}
                                    </PreventMouseLeaveButton>
                                    <PreventMouseLeaveButton
                                        onClick={() => {
                                            CurrentModulesStore.updatePageBar(
                                                this.props.moduleId,
                                                {
                                                    hiddenOnPage: !(
                                                        pageBarInfo!
                                                            .hiddenOnPage ??
                                                        false
                                                    ),
                                                }
                                            );
                                            this.props.onShowPageBarChange();
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        {pageBarInfo!.hiddenOnPage
                                            ? "SHOW SECTION BAR"
                                            : "HIDE SECTION BAR"}
                                    </PreventMouseLeaveButton>
                                    <PreventMouseLeaveButton
                                        onClick={() => {
                                            let field: "mobile" | "desktop" =
                                                this.props.canvasTreeStore
                                                    .canvasViewMode === "mobile"
                                                    ? "mobile"
                                                    : "desktop";
                                            let accordionFormat = pageBarInfo?.accordionFormat ?? {
                                                desktop: false,
                                                mobile: false,
                                            };
                                            CurrentModulesStore.updatePageBar(
                                                this.props.moduleId,
                                                {
                                                    accordionFormat: {
                                                        ...accordionFormat,
                                                        [field]: !(
                                                            pageBarInfo
                                                                ?.accordionFormat?.[
                                                            field
                                                            ] ?? false
                                                        ),
                                                    },
                                                }
                                            );
                                            this.props.onShowPageBarChange();
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        {!pageBarInfo!.accordionFormat?.[
                                            this.props.canvasTreeStore
                                                .canvasViewMode === "mobile"
                                                ? "mobile"
                                                : "desktop"
                                        ]
                                            ? "ENABLE ACCORDION MODE"
                                            : "DISABLE ACCORDION MODE"}
                                    </PreventMouseLeaveButton>
                                </div>
                            </div>
                        )}
                    {this.state.versionMenuInfo != null &&
                        this.sidebarRef.current != null && (
                            <div
                                ref={this.contextMenuRef}
                                className="canvas-menu"
                                style={{
                                    zIndex: 998,
                                    position: "absolute",
                                    left: Math.min(
                                        this.state.versionMenuInfo.clientX -
                                        this.sidebarRef.current.offsetLeft,
                                        this.sidebarRef.current.clientWidth -
                                        185 -
                                        10
                                    ),
                                    top: Math.min(
                                        this.state.versionMenuInfo.clientY -
                                        this.sidebarRef.current.offsetTop,
                                        this.sidebarRef.current.clientHeight -
                                        150 -
                                        10
                                    ),
                                    width: 185,
                                    alignItems: "flex-start",
                                }}
                            >
                                <div>
                                    <PreventMouseLeaveButton
                                        onClick={() => {
                                            this.setState({
                                                createNamedVersionPopupOpen: true,
                                                versionMenuInfo: null,
                                            });
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        SAVE CURRENT VERSION
                                    </PreventMouseLeaveButton>
                                    <PreventMouseLeaveButton
                                        onClick={() => {
                                            this.setState({
                                                versionCanvasId: null,
                                                versions: [],
                                                versionMenuInfo: null,
                                            });
                                        }}
                                        style={{
                                            color:
                                                "var(--sheet-ribbon-context-menu-item-text-color)",
                                        }}
                                    >
                                        CLOSE
                                    </PreventMouseLeaveButton>
                                </div>
                            </div>
                        )}
                </div>
                {this.state.status && (
                    <StatusPopup
                        message={this.state.status.message}
                        status={this.state.status.status}
                        onClose={() => {
                            this.setState({
                                status: null,
                            });
                        }}
                    />
                )}
                {this.state.slideForTemplate && (
                    <SaveTemplatePopup
                        briefCanvas={this.state.slideForTemplate}
                        onClose={(slideId) => {
                            let status = null;
                            if (slideId != null)
                                status = {
                                    status: PopupStatus.Success,
                                    message: "Template was created",
                                };
                            this.setState({
                                slideForTemplate: undefined,
                                status: status,
                            });
                        }}
                    />
                )}
            </>
        );
    }

    render() {
        return (
            <GlobalContext.ObserverConsumer>
                {this.renderContents}
            </GlobalContext.ObserverConsumer>
        );
    }
}

export default LoadPopup;
