import React from "react";
import AggregateTableView from "../AggregateTableView";
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { Resizable } from "re-resizable";
import elements from "common/CanvasElements";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import HtmlElementProps from "./HtmlElementProps";
import {
    changeElementWhenResize2,
    getNewSizeAfterResize2,
} from "../BaseCanvasResizableFunctions";
import { InnerCanvasChanges } from "common/Canvas";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../Snap";

interface InnerProps extends HtmlElementProps {
    aggregateDataId: string;
    rootDataTestId: string;
    moduleTitle: string;
}

const typeName = "aggregateTableElementsState";

interface InnerState {}

@observer
class AggregateElementWrapper extends CanvasInteractionComponent<
    InnerProps,
    InnerState
> {
    drag: boolean;
    constructor(props: InnerProps) {
        super(props);
        this.drag = false;
    }

    public render(): JSX.Element {
        const { canvasViewMode } = this.props.canvasTreeStore;
        let key = this.props.aggregateDataId;
        let aggregateTableElement = this.props.canvasTreeStore.aggregateTableElementsState.get(
            key
        )!;
        let aggregateTableElementSize = {
            height:
                aggregateTableElement.nodeSize[canvasViewMode].height *
                this.props.scale,
            width:
                aggregateTableElement.nodeSize[canvasViewMode].width *
                this.props.scale,
        };

        return (
            <DraggableWithSnapping
                key={key}
                disabled={this.props.live || !this.props.canWrite}
                position={{
                    x:
                        aggregateTableElement.nodePosition[canvasViewMode].x *
                        this.props.scale,
                    y:
                        aggregateTableElement.nodePosition[canvasViewMode].y *
                        this.props.scale,
                }}
                onDrag={(_evt, _data) => {
                    this.drag = true;
                    let nearestPoints = this.props.onRebuildSnapLine(
                        {
                            x: _data.x,
                            y: _data.y,
                            width: aggregateTableElementSize.width,
                            height: aggregateTableElementSize.height,
                        },
                        {
                            type: typeName,
                            id: this.props.aggregateDataId,
                            groupId: aggregateTableElement.groupId,
                        }
                    );
                    this.props.onUpdateSelectionBounds?.();
                    let newPosition = snapElementToPoints(
                        aggregateTableElementSize.width,
                        aggregateTableElementSize.height,
                        nearestPoints
                    );
                    if (newPosition.x != null || newPosition.y != null) {
                        // Snap to this position
                        return newPosition;
                    }
                }}
                onStop={(_evt, data) => {
                    if (this.drag) {
                        this.props.onDeleteSnapLine();
                        this.trackNewPerformance(
                            elements.aggregateDataProcessing
                        );
                        let x = Math.max(data.x / this.props.scale, 0);
                        let y = Math.max(data.y / this.props.scale, 0);
                        let deltaX =
                            x -
                            aggregateTableElement.nodePosition[canvasViewMode]
                                .x;
                        let deltaY =
                            y -
                            aggregateTableElement.nodePosition[canvasViewMode]
                                .y;
                        const nodePosition = {
                            ...aggregateTableElement.nodePosition,
                            [canvasViewMode]: {
                                x,
                                y,
                            },
                        };

                        let changes: InnerCanvasChanges = {};
                        this.props.canvasTreeStore.updateAggregateTableElementAction(
                            key,
                            { nodePosition },
                            changes
                        );
                        this.props.canvasTreeStore.updateCanvasSizeAction({
                            x: x,
                            y: y,
                            width:
                                aggregateTableElement.nodeSize[canvasViewMode]
                                    .width,
                            height:
                                aggregateTableElement.nodeSize[canvasViewMode]
                                    .height,
                        });
                        this.props.onMoveGroupSelection(
                            deltaX,
                            deltaY,
                            {
                                id: this.props.aggregateDataId,
                                type: typeName,
                                groupId: aggregateTableElement.groupId,
                            },
                            false,
                            changes
                        );
                        this.props.canvasTreeStore.saveChangesAction(
                            changes,
                            true,
                            true,
                            false,
                            this.props.canvasTreeStore.backgroundsState.toJSON(),
                            BackgroundMode.Update,
                            false
                        );
                        this.drag = false;
                    }
                }}
            >
                <div
                    onContextMenu={(evt) => {
                        this.props.onContextMenu(
                            evt,
                            {
                                id: this.props.aggregateDataId,
                                type: typeName,
                            },
                            true
                        );
                    }}
                    style={{
                        top: 0,
                        left: 0,
                        position: "absolute",
                        zIndex: aggregateTableElement.zIndex ?? 50,
                    }}
                >
                    <Resizable
                        className="selectable-by-pointer"
                        ref={(ref) => {
                            let innerRef = ref?.resizable;
                            if (innerRef != null) {
                                innerRef.setAttribute("type", typeName);
                                if (aggregateTableElement.groupId != null)
                                    innerRef.setAttribute(
                                        "groupId",
                                        aggregateTableElement.groupId
                                    );
                                else {
                                    innerRef.removeAttribute("groupId");
                                }
                                innerRef.setAttribute(
                                    "id",
                                    String(this.props.aggregateDataId)
                                );
                                innerRef.setAttribute(
                                    "data-test-id",
                                    String(this.props.rootDataTestId)
                                );
                            }
                        }}
                        enable={
                            this.props.live || !this.props.canWrite
                                ? {
                                      top: false,
                                      right: false,
                                      bottom: false,
                                      left: false,
                                      topRight: false,
                                      bottomRight: false,
                                      bottomLeft: false,
                                      topLeft: false,
                                  }
                                : {
                                      top: true,
                                      right: true,
                                      bottom: true,
                                      left: true,
                                      topRight: true,
                                      bottomRight: true,
                                      bottomLeft: true,
                                      topLeft: true,
                                  }
                        }
                        onResizeStart={(evt) => {
                            evt.stopPropagation();
                        }}
                        onResize={(_e, _direction, _ref, d) => {
                            const {
                                canvasViewMode,
                            } = this.props.canvasTreeStore;
                            changeElementWhenResize2(
                                {
                                    position:
                                        aggregateTableElement.nodePosition,
                                    size: aggregateTableElement.nodeSize,
                                },
                                this.props.scale,
                                _direction,
                                d,
                                _ref,
                                canvasViewMode
                            );
                        }}
                        onResizeStop={(_e, _direction, _ref, d) => {
                            this.trackNewPerformance(
                                elements.aggregateDataProcessing
                            );
                            const {
                                canvasViewMode,
                            } = this.props.canvasTreeStore;
                            let newSize = getNewSizeAfterResize2(
                                {
                                    position:
                                        aggregateTableElement.nodePosition,
                                    size: aggregateTableElement.nodeSize,
                                },
                                this.props.scale,
                                _direction,
                                d,
                                canvasViewMode
                            );
                            this.props.canvasTreeStore.updateAggregateTableElementAction(
                                key,
                                {
                                    nodePosition: newSize.nodePosition,
                                    nodeSize: newSize.nodeSize,
                                }
                            );
                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                x: newSize.nodePosition[canvasViewMode].x,
                                y: newSize.nodePosition[canvasViewMode].y,
                                ...newSize.nodeSize[canvasViewMode],
                            });
                            this.props.onResize();
                        }}
                        size={aggregateTableElementSize}
                    >
                        <AggregateTableView
                            onContextMenu={(evt) => {
                                this.props.onContextMenu(
                                    evt,
                                    {
                                        id: this.props.aggregateDataId,
                                        type: typeName,
                                    },
                                    true
                                );
                            }}
                            sharedPolicy={this.props.sharedPolicy}
                            frozen={!this.props.canWrite}
                            height={aggregateTableElementSize.height}
                            live={this.props.live}
                            aggregateTableElement={aggregateTableElement}
                            aggregateTableElementId={key}
                            onChange={(aggregateTableElement) => {
                                this.trackNewPerformance(
                                    elements.aggregateDataProcessing
                                );

                                this.props.canvasTreeStore.updateAggregateTableElementAction(
                                    key,
                                    aggregateTableElement
                                );
                            }}
                            onDelete={() => {
                                this.trackNewPerformance(
                                    elements.aggregateDataProcessing
                                );
                                this.props.showDeletePopup(() => {
                                    this.props.onClearEditing();
                                    this.props.canvasTreeStore.deleteAggregateTableElementAction(
                                        key
                                    );
                                });
                            }}
                            currentModuleId={this.props.currentModuleId}
                        ></AggregateTableView>
                    </Resizable>
                </div>
            </DraggableWithSnapping>
        );
    }
}

export default observer(function AggregateDataElements(
    props: HtmlElementProps
) {
    let aggregateUis: JSX.Element[] = [];
    for (let aggregateDataId of props.canvasTreeStore.aggregateTableElementsState.keys())
        aggregateUis.push(
            <AggregateElementWrapper
                rootDataTestId={`aggregateElement-${aggregateUis.length + 1}`}
                key={aggregateDataId}
                aggregateDataId={aggregateDataId}
                {...props}
            />
        );
    return <>{aggregateUis}</>;
});
