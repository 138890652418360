import React from "react";
import Popup from "reactjs-popup";
import OutsideAlerter from "common/OutsideAlerter";
import CustomSketchPicker from "common/custom_sketch_picker/CustomSketchPicker";
import recentColors from "common/RecentColors";
import { colorList } from "../../LineColors";

import { LinePlotFinding } from "common/Finding";
import EditableAxisItem from "../EditableAxisItem";
import { LegendEditElement } from "common/graphics/LegendEditElement";
import { previewColors } from "../previewColors";
import { TimeChart } from "./TimeChart";
import { TimeChartD3 } from "./TimeChartD3";
import { defaultFontFamily } from "modules/canvas_page/Constants";

interface Props {
    dashboardId: string;
    selected: boolean;
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    content: LinePlotFinding["content"];
    config: LinePlotFinding["config"];
    onChangeData?: (
        data: LinePlotFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: LinePlotFinding["config"],
        updateData?: boolean
    ) => void;
}

interface State {
    // The tuple consists of chart index, line index, and dot index
    dotColorPopupOpen: [number, number, number] | null;
    currentColor: string;
    colorChanged: boolean;
}

interface TimeChartDotProps {
    cx?: number;
    cy?: number;
    value?: number;
    index?: number;
    chartIndex: number;
    lineIndex: number;
    config: LinePlotFinding["config"];
}

export function TimeChartDot(props: TimeChartDotProps) {
    const { cx, cy, config } = props;
    const r: number = config.dotRadius ?? 4;
    const dotColor: string =
        config.dotColors?.[props.chartIndex.toString()]?.[
            props.lineIndex.toString()
        ]?.[props.index!.toString()] ?? "grey";
    return (
        <svg
            x={cx! - r}
            y={cy! - r}
            width={r * 2}
            height={r * 2}
            viewBox="0 0 20 20"
        >
            <circle
                r={10}
                cx={10}
                cy={10}
                type="linear"
                stroke-width="0"
                stroke={dotColor}
                fill={dotColor}
                className="recharts-dot recharts-line-dot"
            />
        </svg>
    );
}

export interface TimeChartProps {
    dashboardId: string;
    config: LinePlotFinding["config"];
    isTimeSeries: boolean;
    selected: boolean;
    editable?: boolean;
    preview?: boolean;
    data: LinePlotFinding["content"]["data"];
    content: LinePlotFinding["content"];
    index: number;
    onDotClick: (lineIndex: number, dotIndex: number) => void;
    subtitle: string | undefined;
    groupNames: string[];
    groupInfo: LinePlotFinding["content"]["groupInfo"];
    onChangeConfig: (
        config: LinePlotFinding["config"],
        updateData?: boolean
    ) => void;
}

export default class TimePatternsChart extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            dotColorPopupOpen: null,
            currentColor: "",
            colorChanged: false,
        };

        this.linkVariable = this.linkVariable.bind(this);
        this.onChangeConfig = this.onChangeConfig.bind(this);
        this.onDotClick0 = this.onDotClick0.bind(this);
        this.onDotClick1 = this.onDotClick1.bind(this);
    }

    private linkVariable(
        index: number,
        variableName: string,
        variableIndex: number
    ): void {
        let newData = Array.from(this.props.content.data);

        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        this.props.onChangeData?.(newData, true);
    }

    private onChangeConfig(config: LinePlotFinding["config"]): void {
        this.props.onChangeConfig?.(config, true);
    }

    private onChangeName(index: number, value: string): void {
        let newData = Array.from(this.props.content.data);
        newData[index].name = value;
        this.props.onChangeData?.(newData);
    }

    private onDotClick(
        index: number,
        lineIndex: number,
        dotIndex: number
    ): void {
        if (this.props.editable) {
            this.setState({
                currentColor:
                    this.props.config.dotColors?.[index.toString()]?.[
                        lineIndex.toString()
                    ]?.[dotIndex.toString()] ?? "grey",
                colorChanged: false,
                dotColorPopupOpen: [index, lineIndex, dotIndex],
            });
        }
    }

    // The purpose of this method is to avoid re-rendering
    private onDotClick0(lineIndex: number, dotIndex: number): void {
        this.onDotClick(0, lineIndex, dotIndex);
    }

    // The purpose of this method is to avoid re-rendering
    private onDotClick1(lineIndex: number, dotIndex: number): void {
        this.onDotClick(1, lineIndex, dotIndex);
    }

    public render(): JSX.Element | null {
        let {
            dashboardId,
            editable,
            preview,
            config,
            content,
            selected,
        } = this.props;
        let { data, isTimeSeries, groupNames, groupInfo } = this.props.content;
        groupNames = groupNames ?? ["value"];
        let currentEditVariableIndex: number | undefined = undefined;
        if (config.dataScope != null && this.props.columnDragActive) {
            currentEditVariableIndex = data.findIndex(
                (item) => item.variableIndex == null
            );
        }
        if (data.length === 0) return null;
        let yVariables = data.slice(1);
        if (config.dataScope != null) {
            yVariables = yVariables.filter(
                (item) => item.variableIndex != null
            );
        }
        let groupByAll =
            config.journeyName === "LineplotD3Journey" &&
            (config.groupByAll ?? true) &&
            yVariables.length === 1;
        let isSideBySide = !groupByAll && data[0].value?.length === 2;
        let contentWidth = !isSideBySide ? "100%" : "50%";
        let timeKey = data[0].name;
        let InnerChart =
            config.journeyName === "LineplotD3Journey"
                ? TimeChartD3
                : TimeChart;
        return (
            <div
                className="flex-simple-column"
                style={{
                    overflow: "visible",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                {(config.showLegend ?? true) && (
                    <div
                        className="my-row"
                        style={{
                            justifyContent: "center",
                            marginTop: 5,
                            marginBottom: 5,
                            flexWrap: "wrap",
                            minHeight: 16,
                        }}
                    >
                        {!groupByAll &&
                            yVariables.map((item, index) => (
                                <LegendEditElement
                                    key={index}
                                    textSize={config.legendSize}
                                    textColor={
                                        config.axesNamesColor ?? "#333333"
                                    }
                                    color={
                                        this.props.preview ||
                                        (item.variableIndex == null &&
                                            config.nameColorMapping?.[index] ==
                                                null)
                                            ? previewColors[
                                                  index % previewColors.length
                                              ]
                                            : config.nameColorMapping?.[
                                                  index
                                              ] ??
                                              colorList[
                                                  index % colorList.length
                                              ]
                                    }
                                    onFinishColor={(color) => {
                                        config.nameColorMapping = {
                                            ...config.nameColorMapping,
                                            [index]: color,
                                        };
                                        this.props.onChangeConfig?.(
                                            config,
                                            false
                                        );
                                    }}
                                    allowColorChange={editable}
                                    disallowTextChange
                                    text={item.name}
                                />
                            ))}
                        {groupByAll &&
                            groupNames?.map((groupName, groupIndex) =>
                                yVariables.map((item, yVariableIndex) => {
                                    let colorListIndex =
                                        yVariableIndex +
                                        groupIndex * yVariables.length;
                                    return (
                                        <LegendEditElement
                                            key={`${groupIndex}-${yVariableIndex}`}
                                            textSize={config.legendSize}
                                            textColor={
                                                config.axesNamesColor ??
                                                "#333333"
                                            }
                                            color={
                                                this.props.preview ||
                                                (item.variableIndex == null &&
                                                    config
                                                        .groupNameColorMapping?.[
                                                        groupIndex
                                                    ]?.[yVariableIndex] == null)
                                                    ? previewColors[
                                                          colorListIndex %
                                                              previewColors.length
                                                      ]
                                                    : config
                                                          .groupNameColorMapping?.[
                                                          groupIndex
                                                      ]?.[yVariableIndex] ??
                                                      colorList[
                                                          colorListIndex %
                                                              colorList.length
                                                      ]
                                            }
                                            onFinishColor={(color) => {
                                                config.groupNameColorMapping = {
                                                    ...config.groupNameColorMapping,
                                                    [groupIndex]: {
                                                        ...config
                                                            .groupNameColorMapping?.[
                                                            groupIndex
                                                        ],
                                                        [yVariableIndex]: color,
                                                    },
                                                };
                                                this.props.onChangeConfig?.(
                                                    config,
                                                    false
                                                );
                                            }}
                                            allowColorChange={editable}
                                            disallowTextChange
                                            text={`${
                                                groupInfo?.[groupIndex].value ??
                                                groupName
                                            }`}
                                        />
                                    );
                                })
                            )}
                    </div>
                )}
                <div
                    className="my-row"
                    style={{
                        width: "100%",
                        height: `calc(100% - ${
                            this.props.config.showLegend ?? true
                                ? 16 + (this.props.config.legendSize ?? 0)
                                : 0
                        }px - ${this.props.config.showXAxisName ? 48 : 0}px)`,
                        overflow: "hidden",
                    }}
                >
                    {this.props.config.showYAxisName && (
                        <div className="flex-simple-column">
                            {data.slice(1).map((item, index) => (
                                <div style={{ marginTop: 13 }}>
                                    <EditableAxisItem
                                        width={150}
                                        onChange={(value) => {
                                            this.onChangeName(index + 1, value);
                                        }}
                                        color={this.props.config.axesNamesColor}
                                        currentEditVariableIndex={
                                            currentEditVariableIndex
                                        }
                                        editable={this.props.editable}
                                        index={index + 1}
                                        vertical={false}
                                        name={item.name}
                                        onDrop={this.linkVariable}
                                        inputStyle={{
                                            fontFamily:
                                                config.axesNamesFontFamily ??
                                                defaultFontFamily.value,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    <div
                        className="my-row"
                        style={{
                            width: `calc(100% - ${
                                this.props.config.showYAxisName ? 150 : 0
                            }px)`,
                            height: "100%",
                        }}
                    >
                        <div
                            className="flex-simple-column"
                            style={{
                                width: contentWidth,
                                height: "100%",
                            }}
                        >
                            <InnerChart
                                dashboardId={dashboardId}
                                groupNames={groupNames}
                                selected={selected}
                                groupInfo={groupInfo}
                                isTimeSeries={isTimeSeries}
                                subtitle={
                                    groupByAll
                                        ? undefined
                                        : groupInfo?.[0]?.value ??
                                          groupNames?.[0]
                                }
                                config={config}
                                onChangeConfig={this.onChangeConfig}
                                data={data}
                                content={content}
                                index={groupByAll ? -1 : 0}
                                editable={editable}
                                preview={preview}
                                onDotClick={this.onDotClick0}
                            />
                        </div>
                        {isSideBySide && (
                            <div
                                className="flex-simple-column"
                                style={{
                                    width: contentWidth,
                                    height: "100%",
                                }}
                            >
                                <InnerChart
                                    dashboardId={dashboardId}
                                    groupNames={groupNames}
                                    selected={selected}
                                    groupInfo={groupInfo}
                                    subtitle={
                                        groupInfo?.[1]?.value ?? groupNames?.[1]
                                    }
                                    isTimeSeries={isTimeSeries}
                                    config={config}
                                    onChangeConfig={this.onChangeConfig}
                                    data={data}
                                    content={content}
                                    index={1}
                                    editable={editable}
                                    preview={preview}
                                    onDotClick={this.onDotClick1}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {this.props.config.showXAxisName && (
                    <EditableAxisItem
                        width={150}
                        onChange={(value) => {
                            this.onChangeName(0, value);
                        }}
                        color={this.props.config.axesNamesColor}
                        currentEditVariableIndex={currentEditVariableIndex}
                        editable={this.props.editable}
                        index={0}
                        vertical={false}
                        name={timeKey}
                        onDrop={this.linkVariable}
                        inputStyle={{
                            fontFamily:
                                config.axesNamesFontFamily ??
                                defaultFontFamily.value,
                        }}
                    />
                )}
                {this.state.dotColorPopupOpen != null && (
                    <Popup
                        arrow={true}
                        contentStyle={{
                            border: "none",
                            backgroundColor: "transparent",
                            minWidth: 220,
                            minHeight: 334,
                        }}
                        open={true}
                        onClose={() => {
                            this.setState({ dotColorPopupOpen: null });
                        }}
                        closeOnDocumentClick
                    >
                        <OutsideAlerter
                            onReject={() => {
                                if (this.state.colorChanged) {
                                    recentColors.addColor(
                                        this.state.currentColor
                                    );
                                }
                                this.setState({ dotColorPopupOpen: null });
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 1000,
                                    width: "auto",
                                    border: "none",
                                    backgroundColor: "transparent",
                                }}
                            >
                                <CustomSketchPicker
                                    disableAlpha={false}
                                    color={this.state.currentColor}
                                    onChange={(color) => {
                                        this.setState({
                                            currentColor: `rgba(${
                                                color.rgb.r
                                            },${color.rgb.g},${color.rgb.b},${
                                                color.rgb.a ?? 1
                                            })`,
                                            colorChanged: true,
                                        });
                                    }}
                                    onChangeComplete={() => {
                                        this.props.onChangeConfig?.({
                                            ...config,
                                            dotColors: {
                                                ...config.dotColors,
                                                // Chart index
                                                [this.state.dotColorPopupOpen![0].toString()]: {
                                                    ...config.dotColors?.[
                                                        this.state.dotColorPopupOpen![0].toString()
                                                    ],
                                                    // Line index
                                                    [this.state.dotColorPopupOpen![1].toString()]: {
                                                        ...config.dotColors?.[
                                                            this.state.dotColorPopupOpen![0].toString()
                                                        ]?.[
                                                            this.state.dotColorPopupOpen![1].toString()
                                                        ],
                                                        // Dot index
                                                        [this.state.dotColorPopupOpen![2].toString()]: this
                                                            .state.currentColor,
                                                    },
                                                },
                                            },
                                        });
                                    }}
                                    presetColorsTitle={"Recent Colors"}
                                    presetColors={recentColors.recentColors}
                                />
                            </div>
                        </OutsideAlerter>
                    </Popup>
                )}
            </div>
        );
    }
}
