import React from "react";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";

interface State {
    username: string;
}

export default class FORTNITE extends React.Component<APIComponentProps, State> {
    constructor(props: APIComponentProps) {
        super(props);
        this.state = {
            username: "",
        };
    }

    componentDidMount() {
        this.setState({
            username: ""
        });
    }

    render() {
        return (
            <div
                className="flex-simple-column"
                style={this.props.containerStyle}
            >
                <BaseAPIComponent {...this.props} />
                <div
                    className="flex-simple-column"
                    style={{ marginTop: "23px" }}
                ></div>
                <span className="APILabel">USERNAME</span>
                <input
                    style={{
                        marginTop: 4,
                        width: 300,
                    }}
                    className="textInput"
                    placeholder="Type the username here"
                    value={this.props.options?.username ?? ""}
                    onChange={(evt) => {
                        let username = evt.target.value;
                        let options = { ...this.props.options };
                        this.props.onOptionsChange({
                            ...options,
                            username: username,
                        });
                    }}
                ></input>
                <div style={{ marginTop: 20 }}></div>
            </div>
        );
    }
}

export { FORTNITE as MainComponent };
