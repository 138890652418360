import React, { Component, CSSProperties, useState } from "react";
import { Resizable, ResizeDirection } from "re-resizable";
import AutosizeInput from "react-input-autosize";
import moment from "moment";
import { VariableSizeGrid as Grid } from "react-window";
import {
    CanvasSpreadSheetGrid,
    CanvasNode,
    CanvasElement,
    CanvasGridHeader,
    isSpreadSheetGrid,
    SelectionArea,
    GridColorOptions,
    isListFormat,
    isDateFormat,
    ListFormat,
    DateFormat,
    getPrettyPrintFormatValue,
} from "common/Canvas";
import CanvasTreeStore from "../CanvasTreeStore";
import {
    identifierFontSize,
    defaultNumberLetterTitleSize,
    defaultFontSize,
    // let defaultHeaderColor = "#000000";
    defaultHeaderSize,
    // let defaultHeaderFillColor = "#CCCCCC";
} from "../Constants";
import {
    stopPropagation,
    preventDefault,
} from "common/utilities/EventFunctions";
import { observer } from "mobx-react";
import StringUtils from "common/utilities/StringUtils";
import { CanvasEditedElement } from "./FlowChartProps";
import "common/styles/rotate.css";
import parseSheetData from "common/ParseSheetData";
import TextareaAutosize from "react-autosize-textarea";
//import { getTextHeight } from "common/utilities/MeasureText";
import Select, {
    createFilter,
    components,
    IndicatorsContainerProps,
    InputProps,
    SingleValueProps,
    GroupBase,
    StylesConfig,
} from "react-select";
import { getCustomSelectStyle } from "common/SelectStyles";
import StringOption from "common/StringOption";
import {
    FormatType,
    getFormatType,
    valueToDateInput,
    formatTypeToInputType,
    dateInputToValue,
} from "common/utilities/TimeFormatUtils";
import CanvasIconsContainter from "../CanvasIconsContainer";
import CanvasExtendedGridHeader from "./CanvasExtendedGridHeader";
import FormatDropdown from "./FormatDropdown";
import SpreadSheetState, { MouseMultiSelection } from "./SpreadSheetState";
import { PopupStatus } from "common/StatusPopup";
import { onDoubleTouch } from "common/utilities/UIResponsiveManager";
import { Direction } from "./SpreadSheetElement";

class IndicatorsContainer<
    OptionType,
    IsMulti extends boolean = false,
    Group extends GroupBase<OptionType> = GroupBase<OptionType>
> extends Component<IndicatorsContainerProps<OptionType, IsMulti, Group>> {
    public render(): JSX.Element {
        let { className, ...rest } = this.props;
        return (
            <components.IndicatorsContainer
                className={`${className ?? ""} not-selectable-by-pointer`}
                {...rest}
            />
        );
    }
}

interface DateTimeInputProps {
    style?: React.CSSProperties;
    dataTestId: string;
    row: number;
    col: number;
    editedNode: CanvasElement;
    canWrite: boolean;
    live: boolean;
    spreadSheetGrid: CanvasSpreadSheetGrid;
    columnFormat: DateFormat;
    currentSimpleEdit: any;
    onChangeEditedNode: (node: CanvasElement) => void;
    onBlur: () => void;
    onKeyDown: (
        evt:
            | React.KeyboardEvent<HTMLTextAreaElement>
            | React.KeyboardEvent<HTMLInputElement>
    ) => void;

    onPaste: (
        evt:
            | React.ClipboardEvent<HTMLDivElement>
            | React.ClipboardEvent<HTMLTextAreaElement>
            | React.ClipboardEvent<HTMLInputElement>
    ) => void;
}

function DateTimeInput(props: DateTimeInputProps) {
    let formatType = getFormatType(props.columnFormat.format);
    let value = valueToDateInput(
        props.editedNode!.value,
        formatType,
        // Spreadsheet displays datetime in UTC
        true
    );

    let [inputValue, setInputValue] = useState(value);
    return (
        <input
            data-test-id={props.dataTestId}
            style={props.style}
            type={formatTypeToInputType(formatType)}
            onContextMenu={preventDefault}
            data-row={props.row}
            data-col={props.col}
            disabled={
                !props.canWrite ||
                (props.live && !props.spreadSheetGrid.unlocked)
            }
            ref={props.currentSimpleEdit}
            onMouseUp={stopPropagation}
            onMouseDown={stopPropagation}
            onClick={stopPropagation}
            onDoubleClick={stopPropagation}
            value={inputValue}
            onChange={(e) => {
                let value = e.target.value;
                setInputValue(value);
                // Datetime input is in local timezone
                if (value != null && formatType === FormatType.DateTime) {
                    let momentFormat = "YYYY-MM-DDTHH:mm:ss";
                    value = moment(value, momentFormat)
                        .subtract(new Date().getTimezoneOffset(), "m")
                        .format(momentFormat);
                }
                let metricAndValue = dateInputToValue(
                    value,
                    props.columnFormat.format,
                    formatType,
                    // Spreadsheet displays datetime in UTC
                    true
                );
                let editedNode = Object.assign({}, props.editedNode);
                editedNode = {
                    ...editedNode,
                    ...metricAndValue,
                };
                props.onChangeEditedNode(editedNode);
            }}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
            onPaste={props.onPaste}
        />
    );
}

class Input<
    OptionType,
    IsMulti extends boolean = false,
    Group extends GroupBase<OptionType> = GroupBase<OptionType>
> extends Component<InputProps<OptionType, IsMulti, Group>> {
    public render(): JSX.Element {
        let {
            className,
            cx,
            getStyles,
            innerRef,
            isHidden,
            isDisabled,
            theme,
            ...rest
        } = this.props;
        // Types of cx and innerRef are specified incorrectly
        // in @types/react-select
        return (
            <div
                style={{
                    ...(getStyles("input", {
                        ...this.props,
                        theme,
                    }) as CSSProperties),
                    MozUserSelect: "none",
                }}
            >
                <AutosizeInput
                    className={((cx as unknown) as (
                        arg1: any,
                        arg2: any
                    ) => string)({ input: true }, className)}
                    inputRef={
                        (innerRef as unknown) as (
                            instance: HTMLInputElement | null
                        ) => void
                    }
                    inputStyle={{
                        background: 0,
                        border: 0,
                        fontSize: "inherit",
                        opacity: isHidden ? 0 : 1,
                        outline: 0,
                        padding: 0,
                        color: "inherit",
                        MozUserSelect: "none",
                    }}
                    disabled={isDisabled}
                    {...rest}
                    tabIndex={undefined}
                />
            </div>
        );
    }
}

class SingleValue<
    OptionType,
    IsMulti extends boolean = false,
    Group extends GroupBase<OptionType> = GroupBase<OptionType>
> extends Component<SingleValueProps<OptionType, IsMulti, Group>> {
    public render(): JSX.Element {
        const {
            children,
            className,
            cx,
            getStyles,
            isDisabled,
            innerProps,
        } = this.props;
        return (
            <div
                style={{
                    ...(getStyles("singleValue", this.props) as CSSProperties),
                    MozUserSelect: "none",
                }}
                className={cx(
                    {
                        "single-value": true,
                        "single-value--is-disabled": isDisabled,
                    },
                    className
                )}
                {...innerProps}
                contentEditable={false}
            >
                {children}
            </div>
        );
    }
}

interface SpreadSheetElementCellProps {
    rootDataTestId: string;
    resizeHeader: boolean;
    canvasTreeStore: CanvasTreeStore;
    rowIndex: number;
    colIndex: number;
    showOptions: boolean;
    isSimpleSpreadSheet: boolean;
    currentSimpleEditParentItem: React.RefObject<HTMLDivElement>;
    autoFillArea: SelectionArea | undefined;
    defaultSize: { width: number; height: number };
    defaultLineBorder: string;
    hovered: boolean;
    currentModuleId?: number;
    colorOptions: GridColorOptions;
    selectionArea: SelectionArea | undefined;
    onSelectArea: (
        area: SelectionArea | undefined,
        allowFontColorChange: boolean
    ) => void;
    moduleTitle: string;
    currentSimpleEdit: React.RefObject<any>;
    scale: number;
    cellHoverId: number | undefined;
    openedFormatDropdownCol: number;
    spreadSheetGrid: CanvasSpreadSheetGrid;
    nodes: CanvasElement[];
    editedNode: CanvasEditedElement | undefined;
    live: boolean;
    canWrite: boolean;
    mouseMultiSelection: MouseMultiSelection;
    moveSelection: Function;
    enableMultiSelection: (enable: boolean) => void;
    setCellHoverId: (cellId: number | undefined) => void;
    setMouseMultiSelection: (selecting: MouseMultiSelection) => void;
    onChangeEditedNode: (node: CanvasElement) => void;
    onChangeEditedHeader: (header: CanvasExtendedGridHeader) => void;
    onStartEditHeader: (header: CanvasExtendedGridHeader) => void;
    onSimpleEditInsertOuterId: (node: CanvasNode) => void;
    onStartAdvancedEditing: (node: CanvasNode) => void;
    editedGridHeader: CanvasExtendedGridHeader | undefined;
    onClearEditing: () => void;
    onKeepSimpleEditChanges: () => void;
    onStartEditNode: (
        node: CanvasElement,
        focus?: boolean,
        internalEdit?: boolean
    ) => void;
    onNodeClick: (evt: any, node: CanvasElement) => void;
    onHeaderContextMenu: (evt: any, row: number, col: number) => void;
    onItemContextMenu: (evt: any, node: CanvasElement) => void;
    onHideContextMenu: () => void;
    onChange: (props: Partial<CanvasSpreadSheetGrid>) => void;
    onSort: (column: number) => void;
    onResize: () => void;
    onApplyFormat: () => void;
    onGenerateLocation: (col: number) => void;
    setSpreadSheetState: <T extends keyof SpreadSheetState>(
        state:
            | Pick<SpreadSheetState, T>
            | ((prevState: SpreadSheetState) => Pick<SpreadSheetState, T>)
    ) => void;
    gridRef: React.RefObject<Grid>;
    gridHeaderRef: React.RefObject<Grid>;
    setOpenedFormatDropdownCol: (col: number) => void;
}

interface State {
    spreadSheetEditedHeaderFocused: boolean;
    focusedAfterClick: boolean;
}

@observer
class SpreadSheetElementCell extends Component<
    SpreadSheetElementCellProps,
    State
> {
    constructor(props: SpreadSheetElementCellProps) {
        super(props);
        this.state = {
            spreadSheetEditedHeaderFocused: false,
            focusedAfterClick: false,
        };
        this.buildSpreadSheetItemOnContextMenu = this.buildSpreadSheetItemOnContextMenu.bind(
            this
        );
        this.buildSpreadSheetItemOnClick = this.buildSpreadSheetItemOnClick.bind(
            this
        );
        this.buildSpreadSheetHeaderOnClick = this.buildSpreadSheetHeaderOnClick.bind(
            this
        );
        this.buildSpreadSheetHeaderOnContextMenu = this.buildSpreadSheetHeaderOnContextMenu.bind(
            this
        );
        this.buildSpreadSheetHeaderAutosizeInputOnChange = this.buildSpreadSheetHeaderAutosizeInputOnChange.bind(
            this
        );
        this.buildSpreadSheetHeaderAutosizeInputOnBlur = this.buildSpreadSheetHeaderAutosizeInputOnBlur.bind(
            this
        );
        this.buildSpreadSheetHeaderAutosizeInputOnKeyDown = this.buildSpreadSheetHeaderAutosizeInputOnKeyDown.bind(
            this
        );
        this.buildSpreadSheetHeaderSortOnClick = this.buildSpreadSheetHeaderSortOnClick.bind(
            this
        );
        this.buildSpreadSheetHeaderSpanOnClick = this.buildSpreadSheetHeaderSpanOnClick.bind(
            this
        );
        this.buildSpreadSheetAutosizeInputOnChange = this.buildSpreadSheetAutosizeInputOnChange.bind(
            this
        );
        this.buildSpreadSheetAutosizeInputOnPaste = this.buildSpreadSheetAutosizeInputOnPaste.bind(
            this
        );
        this.buildSpreadSheetAutosizeInputOnBlur = this.buildSpreadSheetAutosizeInputOnBlur.bind(
            this
        );
        this.buildSpreadSheetHeaderAutosizeInputOnFocus = this.buildSpreadSheetHeaderAutosizeInputOnFocus.bind(
            this
        );
        this.buildSpreadSheetAutosizeInputOnKeyDown = this.buildSpreadSheetAutosizeInputOnKeyDown.bind(
            this
        );
        this.buildSpreadSheetSpan2OnClick = this.buildSpreadSheetSpan2OnClick.bind(
            this
        );
        this.renderResizableElementOnResizeStart = this.renderResizableElementOnResizeStart.bind(
            this
        );
        this.renderResizableElementOnResize = this.renderResizableElementOnResize.bind(
            this
        );
        this.renderResizableElementOnResizeStop = this.renderResizableElementOnResizeStop.bind(
            this
        );
        this.renderResizableLeftColumnOnResizeStop = this.renderResizableLeftColumnOnResizeStop.bind(
            this
        );
        this.renderResizableTopRowOnResizeStop = this.renderResizableTopRowOnResizeStop.bind(
            this
        );
    }

    private resizeTopRow(
        size: { width: number; height: number },
        d: { height: number; width: number },
        j: number
    ): {
        columnScales: number[];
        titleRowScale: number;
    } {
        let height = this.props.defaultSize.height;
        let width = this.props.defaultSize.width;

        let newHeidth = (size.height + d.height) / this.props.scale;
        let newWidth = (size.width + d.width) / this.props.scale;
        let columnScales: number[] =
            this.props.spreadSheetGrid.columnScales ??
            new Array(this.props.spreadSheetGrid.cols).fill(1);

        let titleRowScale = this.props.spreadSheetGrid.titleRowScale ?? 1;
        let newHeightScale = newHeidth / height;
        let newWidthScale = newWidth / width;
        columnScales[j] = newWidthScale;
        titleRowScale = newHeightScale;
        return {
            columnScales: columnScales,
            titleRowScale: titleRowScale,
        };
    }

    private renderResizableTopRowOnResizeStop(
        _e: MouseEvent | TouchEvent,
        direction: ResizeDirection,
        ref: HTMLElement,
        d: { width: number; height: number }
    ): void {
        const j = Number(ref.getAttribute("data-j"));
        const size = {
            height: Number(ref.getAttribute("data-height")),
            width: Number(ref.getAttribute("data-width")),
        };
        this.props.onChange(this.resizeTopRow(size, d, j));
        if (direction === "bottom") {
            this.props.gridHeaderRef.current?.resetAfterRowIndex(0);
            this.props.gridRef.current?.resetAfterRowIndex(0);
        }
        if (direction === "right") {
            this.props.gridHeaderRef.current?.resetAfterColumnIndex(j);
            this.props.gridRef.current?.resetAfterColumnIndex(j);
        }
        this.props.onResize();
        this.props.setSpreadSheetState({
            resizeHeader: false,
        });
    }

    private resizeLeftColumn(
        size: { width: number; height: number },
        d: { width: number; height: number },
        i: number
    ): {
        leftTitleColumnScale: number;
        rowScales: number[];
    } {
        let width = this.props.defaultSize.width;

        let newWidth = (size.width + d.width) / this.props.scale;

        let height = this.props.defaultSize.height;
        let newHeight = (size.height + d.height) / this.props.scale;

        let rowScales: number[] =
            this.props.spreadSheetGrid.rowScales ??
            new Array(this.props.spreadSheetGrid.rows).fill(1);
        let leftTitleColumnScale =
            this.props.spreadSheetGrid.leftTitleColumnScale ?? 1;
        let newWidthScale = newWidth / width;
        let newHeightScale = newHeight / height;
        rowScales[i] = newHeightScale;

        leftTitleColumnScale = newWidthScale;

        return {
            leftTitleColumnScale: leftTitleColumnScale,
            rowScales: rowScales,
        };
    }

    private renderResizableLeftColumnOnResizeStop(
        _e: MouseEvent | TouchEvent,
        direction: ResizeDirection,
        ref: HTMLElement,
        d: { width: number; height: number }
    ): void {
        const i = Number(ref.getAttribute("data-i"));
        let size = {
            height: Number(ref.getAttribute("data-height")),
            width: Number(ref.getAttribute("data-width")),
        };
        this.props.onChange(this.resizeLeftColumn(size, d, i));

        if (direction === "right") {
            this.props.gridRef.current?.resetAfterColumnIndex(0);
            this.props.gridHeaderRef.current?.resetAfterColumnIndex(0);
        }
        if (direction === "bottom") {
            this.props.gridRef.current?.resetAfterRowIndex(i);
        }
        this.props.onResize();
    }

    private buildSpreadSheetHeaderOnClick(
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void {
        evt.stopPropagation();
        this.props.onHideContextMenu();
        let row = Number(evt.currentTarget.getAttribute("data-row"));
        let col = Number(evt.currentTarget.getAttribute("data-col"));
        if (!evt.shiftKey) {
            this.startSelectArea(row, col);
        } else {
            this.addToSelectArea(row, col);
        }
    }

    private buildSpreadSheetHeaderSortOnClick(
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void {
        evt.stopPropagation();
        this.props.onSort(Number(evt.currentTarget.getAttribute("data-col")));
    }

    private buildSpreadSheetHeaderSpanOnClick(
        evt: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.props.onHideContextMenu();
        let row = Number(evt.currentTarget.getAttribute("data-row"));
        let col = Number(evt.currentTarget.getAttribute("data-col"));
        if (!evt.shiftKey) {
            this.startSelectArea(row, col);
        } else {
            this.addToSelectArea(row, col);
            return;
        }
        const fontSize = evt.currentTarget.getAttribute("data-header-fontsize");
        const fontColor = evt.currentTarget.getAttribute(
            "data-header-fontcolor"
        );
        const text = evt.currentTarget.getAttribute("data-header-text");
        setTimeout(() => {
            if (
                this.props.canWrite &&
                (!this.props.live || this.props.spreadSheetGrid.unlocked)
            )
                this.props.onStartEditHeader({
                    row: row,
                    col: col,
                    gridId: this.props.spreadSheetGrid.id,
                    text: text ?? "",
                    fontColor: fontColor ?? undefined,
                    fontSize: fontSize != null ? Number(fontSize) : undefined,
                });
        }, 0);
    }

    private buildSpreadSheetHeaderOnContextMenu(
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.props.onHeaderContextMenu(
            evt,
            Number(evt.currentTarget.getAttribute("data-row")),
            Number(evt.currentTarget.getAttribute("data-col"))
        );
    }

    private buildSpreadSheetHeaderAutosizeInputOnChange(
        evt: React.ChangeEvent<HTMLTextAreaElement>
    ): void {
        let value = evt.target.value;
        let header = {
            ...this.props.editedGridHeader,
            text: value,
            row: this.props.editedGridHeader!.row,
            col: this.props.editedGridHeader!.col,
            gridId: this.props.editedGridHeader!.gridId,
        };
        this.props.onChangeEditedHeader(header);
    }

    private buildSpreadSheetHeaderAutosizeInputOnBlur(): void {
        this.props.onKeepSimpleEditChanges();
        this.props.currentSimpleEditParentItem.current?.focus({
            preventScroll: true,
        });
    }

    private buildSpreadSheetHeaderAutosizeInputOnKeyDown(
        evt: React.KeyboardEvent<HTMLTextAreaElement>
    ): void {
        evt.stopPropagation();
        if ((evt.key === "Enter" || evt.key === "Escape") && !evt.shiftKey) {
            evt.preventDefault();
            this.props.onKeepSimpleEditChanges();
            this.props.onClearEditing();
        }
    }

    private positionInSelectArea(row: number, col: number): boolean {
        if (this.props.selectionArea == null) {
            return false;
        }
        return (
            row >= this.props.selectionArea.top &&
            row <= this.props.selectionArea.bottom &&
            col >= this.props.selectionArea.left &&
            col <= this.props.selectionArea.right
        );
    }

    private renderResizableElementOnResizeStart(
        e:
            | React.MouseEvent<HTMLElement, MouseEvent>
            | React.TouchEvent<HTMLElement>,
        direction: ResizeDirection
    ): void {
        e.stopPropagation();
        if (direction === "bottomRight") {
            this.props.setSpreadSheetState({
                autoFillArea: this.props.selectionArea,
            });
        }
    }

    private addToAutoFillArea(row: number, col: number): void {
        this.props.setSpreadSheetState({
            autoFillArea: {
                gridId: this.props.spreadSheetGrid.id,
                top: Math.min(this.props.selectionArea?.top ?? 0, row),
                bottom: Math.max(this.props.selectionArea?.bottom ?? 0, row),
                left: Math.min(this.props.selectionArea?.left ?? 0, col),
                right: Math.max(this.props.selectionArea?.right ?? 0, col),
            },
        });
    }

    private renderResizableElementOnResize(
        _e: MouseEvent | TouchEvent,
        direction: ResizeDirection,
        ref: HTMLElement,
        d: { width: number; height: number }
    ): void {
        const i = Number(ref.getAttribute("data-i"));
        const j = Number(ref.getAttribute("data-j"));
        if (direction === "bottomRight") {
            let width = d.width;
            let height = d.height;
            let maxRow = i;
            let maxCol = j;
            let goRight = width > height;
            if (goRight) {
                while (
                    width > 0 &&
                    maxCol < this.props.spreadSheetGrid.cols - 1
                ) {
                    maxCol += 1;
                    width = width - this.getSize(i, maxCol).width;
                }
            } else {
                while (
                    height > 0 &&
                    maxRow < this.props.spreadSheetGrid.rows - 1
                ) {
                    maxRow += 1;
                    height = height - this.getSize(maxRow, j).height;
                }
            }
            this.addToAutoFillArea(maxRow, maxCol);
        }
    }

    private renderResizableElementOnResizeStop(
        _e: MouseEvent | TouchEvent,
        direction: ResizeDirection,
        ref: HTMLElement,
        d: { width: number; height: number }
    ): void {
        const i = Number(ref.getAttribute("data-i"));
        const j = Number(ref.getAttribute("data-j"));
        const size = {
            height: Number(ref.getAttribute("data-height")),
            width: Number(ref.getAttribute("data-width")),
        };
        if (direction === "bottomRight") {
            this.makeAutocomplete();
        } else {
            this.props.onChange(this.resizeItem(size, d, i, j));
            if (direction === "top" || direction === "bottom") {
                this.props.gridRef.current?.resetAfterRowIndex(i);
            } else if (direction === "left" || direction === "right") {
                this.props.gridHeaderRef.current?.resetAfterColumnIndex(j);
                this.props.gridRef.current?.resetAfterColumnIndex(j);
            } else {
                this.props.gridRef.current?.resetAfterIndices({
                    rowIndex: i,
                    columnIndex: j,
                });
            }
            this.props.onResize();
        }
    }

    private resizeItem(
        size: { width: number; height: number },
        d: { width: number; height: number },
        i: number,
        j: number
    ): {
        rowScales: number[];
        columnScales: number[];
    } {
        let { width, height } = this.props.defaultSize;

        let newWidth = (size.width + d.width) / this.props.scale;
        let newHeidth = (size.height + d.height) / this.props.scale;
        let rowScales: number[] =
            this.props.spreadSheetGrid.rowScales ??
            new Array(this.props.spreadSheetGrid.rows).fill(1);
        let newHeightScale = newHeidth / height;

        rowScales[i] = newHeightScale;

        let columnScales: number[] =
            this.props.spreadSheetGrid.columnScales ??
            new Array(this.props.spreadSheetGrid.cols).fill(1);
        let newWidthScale = newWidth / width;
        columnScales[j] = newWidthScale;
        return {
            rowScales: rowScales,
            columnScales: columnScales,
        };
    }

    private makeAutocomplete(): void {
        if (this.props.autoFillArea == null || this.props.selectionArea == null)
            return;
        let selectionArea = {
            left: Math.max(0, this.props.selectionArea.left),
            right: Math.max(0, this.props.selectionArea.right),
            top: Math.max(0, this.props.selectionArea.top),
            bottom: Math.max(0, this.props.selectionArea.bottom),
            gridId: this.props.selectionArea.gridId,
        };
        let autoFillArea = {
            left: Math.max(0, this.props.autoFillArea.left),
            right: Math.max(0, this.props.autoFillArea.right),
            top: Math.max(0, this.props.autoFillArea.top),
            bottom: Math.max(0, this.props.autoFillArea.bottom),
            gridId: this.props.selectionArea.gridId,
        };
        this.props.canvasTreeStore.makeSpreadSheetAutocompleteAction(
            this.props.spreadSheetGrid.id,
            selectionArea,
            autoFillArea,
            this.props.nodes
        );
        this.props.onSelectArea(
            this.props.autoFillArea,
            this.props.canWrite &&
                (!this.props.live ||
                    (this.props.spreadSheetGrid.unlocked ?? false))
        );

        this.props.setSpreadSheetState({
            autoFillArea: undefined,
        });
    }

    private buildSpreadSheetItemOnContextMenu(
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void {
        const node = this.props.nodes[
            Number(evt.currentTarget.getAttribute("data-nodeindex"))
        ];
        evt.preventDefault();
        evt.stopPropagation();
        this.props.onItemContextMenu(evt, node);
    }

    private buildSpreadSheetItemOnClick(
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void {
        const node = this.props.nodes[
            Number(evt.currentTarget.getAttribute("data-nodeindex"))
        ];
        let dblClick = evt.detail === 2;
        const row = Number(evt.currentTarget.getAttribute("data-row"));
        const col = Number(evt.currentTarget.getAttribute("data-col"));
        evt.preventDefault();
        evt.stopPropagation();
        this.props.onHideContextMenu();
        this.props.onNodeClick(evt, node);
        if (!evt.shiftKey) {
            this.startSelectArea(row, col);
            if (
                this.props.canWrite &&
                (!this.props.live || this.props.spreadSheetGrid.unlocked)
            ) {
                evt.stopPropagation();
                setTimeout(() => {
                    this.props.onStartEditNode(node!, dblClick, dblClick);
                }, 0);
            }
        } else {
            this.addToSelectArea(row, col);
        }
    }

    private positionInAutoFillArea(row: number, col: number): boolean {
        if (this.props.autoFillArea == null) {
            return false;
        }
        return (
            row >= this.props.autoFillArea.top &&
            row <= this.props.autoFillArea.bottom &&
            col >= this.props.autoFillArea.left &&
            col <= this.props.autoFillArea.right
        );
    }

    private isMaxSelectArea(row: number, col: number): boolean {
        if (this.props.selectionArea == null) {
            return false;
        }
        return (
            row === this.props.selectionArea.bottom &&
            col === this.props.selectionArea.right
        );
    }

    private startSelectArea(row: number, col: number): void {
        this.props.onSelectArea(
            {
                top: row,
                bottom: row,
                left: col,
                right: col,
                gridId: this.props.spreadSheetGrid.id,
            },
            this.props.canWrite &&
                (!this.props.live ||
                    (this.props.spreadSheetGrid.unlocked ?? false))
        );
    }

    private addToSelectArea(row: number, col: number): void {
        document?.getSelection()?.removeAllRanges();
        if (this.props.selectionArea == null) {
            return;
        }
        let selectionArea = { ...this.props.selectionArea };

        this.props.onSelectArea(
            {
                ...selectionArea,
                top: Math.min(selectionArea?.top ?? 0, row),
                bottom: Math.max(selectionArea?.bottom ?? 0, row),
                left: Math.min(selectionArea?.left ?? 0, col),
                right: Math.max(selectionArea?.right ?? 0, col),
            },
            this.props.canWrite &&
                (!this.props.live ||
                    (this.props.spreadSheetGrid.unlocked ?? false))
        );
    }

    private getSize(
        row: number,
        col: number
    ): { width: number; height: number } {
        return {
            height:
                this.props.scale *
                this.props.defaultSize.height *
                (this.props.spreadSheetGrid.rowScales?.[row] ?? 1),
            width:
                this.props.scale *
                this.props.defaultSize.width *
                (this.props.spreadSheetGrid.columnScales?.[col] ?? 1),
        };
    }

    private buildSpreadSheetAutosizeInputOnChange(
        evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void {
        // We have to read name before setState, because evt.target.value might be undefined during setState
        let name = evt.target.value;
        let editedNode = Object.assign({}, this.props.editedNode);

        editedNode.metric = name;
        this.props.onChangeEditedNode(editedNode);
    }

    private buildSpreadSheetAutosizeInputOnPaste(
        evt:
            | React.ClipboardEvent<HTMLDivElement>
            | React.ClipboardEvent<HTMLTextAreaElement>
            | React.ClipboardEvent<HTMLInputElement>
    ): void {
        let table: string[][] | null = parseSheetData(
            evt.clipboardData.getData("text/html") ||
                evt.clipboardData.getData("text/plain")
        );
        let row: number;
        let col: number;
        const attrValue = evt.currentTarget.getAttribute("data-row");
        if (attrValue != null) {
            row = Number(attrValue);
            col = Number(evt.currentTarget.getAttribute("data-col"));
        } else if (this.props.selectionArea != null) {
            row = this.props.selectionArea.top;
            col = this.props.selectionArea.left;
        } else {
            return;
        }

        if (table != null) {
            evt.stopPropagation();
            evt.preventDefault();
            const rows = this.props.spreadSheetGrid.rows;
            const cols = this.props.spreadSheetGrid.cols;

            let additionalRows = Math.max(row + table.length - rows, 0);
            let additionalCols = Math.max(col + table[0].length - cols, 0);
            if (
                this.props.spreadSheetGrid
                    .fullSpreadSheetBackendOutputOptions != null &&
                (additionalRows > 0 || additionalCols > 0)
            ) {
                this.props.setSpreadSheetState({
                    streamStatus: PopupStatus.Error,
                    streamMessage:
                        "This table is being streamed. " +
                        "The pasted area does not match its dimensions. " +
                        "Please stop streaming the table if you want to " +
                        "paste this in.",
                });
                return;
            }

            if (additionalRows > 0) {
                for (let i = 0; i < additionalRows; ++i) {
                    this.props.canvasTreeStore.insertGridRowAction(
                        this.props.spreadSheetGrid.id,
                        rows + i
                    );
                }
            }
            if (additionalCols > 0) {
                for (let j = 0; j < additionalCols; ++j) {
                    this.props.canvasTreeStore.insertGridColumnAction(
                        this.props.spreadSheetGrid.id,
                        cols + j
                    );
                }
            }

            let nodes = this.props.canvasTreeStore.getSortedGridNodes(
                this.props.spreadSheetGrid.id
            );

            if (row < 0 || col < 0) {
                let header = {
                    ...this.props.editedGridHeader,
                    text: table[0][0],
                    row: this.props.editedGridHeader!.row,
                    col: this.props.editedGridHeader!.col,
                    gridId: this.props.editedGridHeader!.gridId,
                };
                this.props.onChangeEditedHeader(header);
            } else {
                let editedNode: CanvasEditedElement = {
                    ...this.props.editedNode!,
                    metric: table[0][0] ?? "",
                };
                this.props.onChangeEditedNode(editedNode);
            }
            let changes: { [nodeId: number]: Partial<CanvasElement> } = {};
            for (let i = 0; i < table.length; ++i) {
                for (let j = 0; j < table[0].length; ++j) {
                    if (row + i < 0) {
                        this.props.canvasTreeStore.updateGridHeaderNodeAction(
                            this.props.spreadSheetGrid.id,
                            col + j,
                            { text: table[i][j] }
                        );
                    } else if (col + j < 0) {
                        this.props.canvasTreeStore.updateGridLeftHeaderNodeAction(
                            this.props.spreadSheetGrid.id,
                            row + i,
                            { text: table[i][j] }
                        );
                    } else {
                        let node =
                            nodes[
                                (row + i) * (cols + additionalCols) + (col + j)
                            ];
                        changes[node.id] = {
                            metric: table[i][j] ?? "",
                        };
                    }
                }
            }
            if (Object.keys(changes).length > 0) {
                this.props.canvasTreeStore.updateNodesAction(changes, true);
            }
            this.props.setSpreadSheetState({
                streamMessage: null,
                streamStatus: null,
            });
            let selectionArea = {
                left: col,
                right: col + table[0].length - 1,
                top: row,
                bottom: row + table.length - 1,
                gridId: this.props.spreadSheetGrid.id,
            };
            this.props.onSelectArea(
                selectionArea,
                this.props.canWrite &&
                    (!this.props.live ||
                        (this.props.spreadSheetGrid.unlocked ?? false))
            );
        }
    }

    private buildSpreadSheetAutosizeInputOnBlur(): void {
        // edited is always true in this context
        this.props.onKeepSimpleEditChanges();
        this.props.currentSimpleEditParentItem.current?.focus({
            preventScroll: true,
        });
        this.props.setCellHoverId(undefined);
    }

    private buildSpreadSheetHeaderAutosizeInputOnFocus(): void {
        this.setState({ spreadSheetEditedHeaderFocused: true });
    }

    private buildSpreadSheetAutosizeInputOnKeyDown(
        evt:
            | React.KeyboardEvent<HTMLTextAreaElement>
            | React.KeyboardEvent<HTMLInputElement>
            | React.KeyboardEvent<HTMLDivElement>
    ): void {
        evt.stopPropagation();
        const { editedNode, canvasTreeStore } = this.props;
        const arrows = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

        if (evt.key === "Escape") {
            evt.preventDefault();
            evt.currentTarget.blur();
            this.props.onKeepSimpleEditChanges();
            this.props.onClearEditing();
        }
        if (
            (evt.key === "Enter" && !evt.shiftKey) ||
            arrows.includes(evt.key)
        ) {
            if (arrows.includes(evt.key) && this.state.focusedAfterClick)
                return;

            evt.preventDefault();

            let row = editedNode!.nodePosition[canvasTreeStore.canvasViewMode]
                .y;
            let col = editedNode!.nodePosition[canvasTreeStore.canvasViewMode]
                .x;
            if (row < this.props.spreadSheetGrid.rows - 1) {
                row += 1;
            } else if (col < this.props.spreadSheetGrid.cols - 1) {
                col += 1;
                row = 0;
            } else {
                row = 0;
                col = 0;
            }
            if (
                col ===
                    this.props.editedNode!.nodePosition[
                        canvasTreeStore.canvasViewMode
                    ].x &&
                row ===
                    this.props.editedNode!.nodePosition[
                        canvasTreeStore.canvasViewMode
                    ].y
            ) {
                evt.currentTarget.blur();
                this.props.currentSimpleEditParentItem.current?.focus({
                    preventScroll: true,
                });
                //    this.props.onClearEditing();
                return;
            }
            let node = this.props.nodes.find(
                (node) =>
                    node.nodePosition[canvasTreeStore.canvasViewMode].x ===
                        col &&
                    node.nodePosition[canvasTreeStore.canvasViewMode].y === row
            );

            if (evt.key === "Enter") {
                if (node != null) {
                    this.startSelectArea(row, col);
                    setTimeout(() => {
                        this.props.onStartEditNode(node!, false, false);
                    }, 0);
                }
            }

            switch (evt.key) {
                case "ArrowUp":
                    this.props.moveSelection(
                        evt,
                        -1,
                        0,
                        node as any,
                        this.props.colorOptions,
                        this.props.nodes
                    );
                    break;
                case "ArrowDown":
                    this.props.moveSelection(
                        evt,
                        1,
                        0,
                        node as any,
                        this.props.colorOptions,
                        this.props.nodes
                    );
                    break;
                case "ArrowLeft":
                    this.props.moveSelection(
                        evt,
                        0,
                        -1,
                        node as any,
                        this.props.colorOptions,
                        this.props.nodes
                    );
                    break;
                case "ArrowRight":
                    this.props.moveSelection(
                        evt,
                        0,
                        1,
                        node as any,
                        this.props.colorOptions,
                        this.props.nodes
                    );
                    break;
                default:
                    break;
            }
            this.props.currentSimpleEditParentItem.current?.focus({
                preventScroll: true,
            });
        }
    }

    private isInsertOuterIdEnabled(node: CanvasElement): boolean {
        return (
            this.props.editedNode != null &&
            (this.props.editedNode.focus ?? false) &&
            this.props.editedNode.gridId != null &&
            isSpreadSheetGrid(
                this.props.canvasTreeStore.gridsState.get(
                    this.props.editedNode.gridId
                )!
            ) &&
            this.props.editedNode.id !== node.id &&
            this.props.editedNode.metric.startsWith("=")
        );
    }

    private buildSpreadSheetSpan2OnClick(
        evt:
            | React.MouseEvent<HTMLSpanElement, MouseEvent>
            | React.TouchEvent<HTMLDivElement>
    ): void {
        const node = this.props.nodes[
            Number(evt.currentTarget.getAttribute("data-nodeindex"))
        ];

        let dblClick = evt.detail === 2;
        const row = Number(evt.currentTarget.getAttribute("data-row"));
        const col = Number(evt.currentTarget.getAttribute("data-col"));

        evt.preventDefault();
        this.props.onHideContextMenu();
        if (!this.isInsertOuterIdEnabled(node)) {
            if (!evt.shiftKey) {
                this.startSelectArea(row, col);
                if (
                    this.props.canWrite &&
                    (!this.props.live || this.props.spreadSheetGrid.unlocked)
                ) {
                    evt.stopPropagation();
                    setTimeout(() => {
                        if (evt.type === "touchstart") {
                            onDoubleTouch(() => {
                                this.props.onStartEditNode(node!, true, true);
                            });
                            return;
                        }
                        this.props.onStartEditNode(node!, dblClick, dblClick);
                        this.props.currentSimpleEdit.current?.focus?.();
                    }, 0);
                }
            } else {
                this.addToSelectArea(row, col);
            }
        } else {
            evt.stopPropagation();
            this.props.onSimpleEditInsertOuterId(node);
        }
    }

    private isInternalEditEnabled(node: CanvasElement, col: number) {
        let edited =
            this.props.editedNode != null &&
            node.id === this.props.editedNode.id;
        let columnFormat =
            this.props.spreadSheetGrid.headers?.[col]?.columnFormat ??
            undefined;
        return (
            edited &&
            this.props.editedNode!.internalEdit &&
            !isListFormat(columnFormat) &&
            (!isDateFormat(columnFormat) ||
                this.props.editedNode!.metric.startsWith("="))
        );
    }

    private buildSpreadSheetItem(
        node: CanvasElement,
        nodeIndex: number,
        size: { width: number; height: number },
        row: number,
        col: number,
        isSimpleSpreadSheet: boolean,
        colorOptions: GridColorOptions
    ): JSX.Element {
        let edited =
            this.props.editedNode != null &&
            node.id === this.props.editedNode.id;
        const error =
            this.props.canvasTreeStore.canvasTreeRequestErrorsState.get(
                node.id
            ) != null ||
            this.props.canvasTreeStore.canvasTreeParseErrorsState.get(
                node.id
            ) != null;
        let inputWidth =
            size.width - (isSimpleSpreadSheet ? 5 : 15) * this.props.scale;
        let inputStyleWithoutWidth = {
            paddingTop: 3 * this.props.scale,
            paddingBottom: 3 * this.props.scale,
            paddingLeft: 5 * this.props.scale,
            fontSize:
                (node.fontSize ?? colorOptions.fontSize ?? defaultFontSize) *
                this.props.scale,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            fontFamily: "Roboto",
            color: node.fontColor || colorOptions.textColor,
        };
        let inputStyle = {
            ...inputStyleWithoutWidth,
            width: inputWidth,
        };
        let columnFormat =
            this.props.spreadSheetGrid.headers?.[col]?.columnFormat ??
            undefined;
        let value =
            edited &&
            this.props.editedNode!.internalEdit &&
            !isListFormat(columnFormat)
                ? this.props.editedNode!.metric ?? ""
                : getPrettyPrintFormatValue(
                      node,
                      columnFormat ?? node.format,
                      undefined,
                      undefined,
                      true
                  );

        let customSelectStyle = getCustomSelectStyle(7.5 * this.props.scale);
        let widthOffset = 2;
        let heightOffset = 2;
        return (
            <div
                ref={
                    edited ? this.props.currentSimpleEditParentItem : undefined
                }
                data-test-id={this.props.rootDataTestId}
                tabIndex={0}
                data-row={row}
                data-col={col}
                data-nodeindex={nodeIndex}
                className="flex-simple-column"
                style={{
                    outline: "none",
                    width: size.width,
                    height: size.height,
                    position: "relative",
                }}
                onContextMenu={this.buildSpreadSheetItemOnContextMenu}
                onClick={this.buildSpreadSheetItemOnClick}
                onDoubleClick={this.buildSpreadSheetItemOnClick}
                onMouseDown={stopPropagation}
            >
                {isListFormat(columnFormat) && (
                    <Select<StringOption, boolean>
                        blurInputOnSelect={true}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        ref={edited ? this.props.currentSimpleEdit : undefined}
                        components={{
                            IndicatorsContainer: IndicatorsContainer,
                            Input: Input,
                            SingleValue: SingleValue,
                        }}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        isMulti={columnFormat.allowMultipleSelection}
                        placeholder={"METRIC"}
                        styles={
                            {
                                ...customSelectStyle,
                                container: (base, props) => ({
                                    ...base,
                                    height: size.height - heightOffset,
                                    width: `calc(100% - ${widthOffset}px)`,
                                    backgroundColor: "transparent",
                                    pointerEvents: props.isFocused
                                        ? undefined
                                        : "none",
                                    MozUserSelect: "none",
                                }),
                                control: (base, props) => ({
                                    ...customSelectStyle.control!(base, props),
                                    height: size.height - heightOffset,
                                    minHeight: size.height - heightOffset,
                                    overflow: "hidden",
                                    backgroundColor: "transparent",
                                    MozUserSelect: "none",
                                }),
                                input: (base, props) => ({
                                    ...customSelectStyle.input!(base, props),
                                    backgroundColor: "transparent",
                                    color:
                                        node.fontColor ??
                                        colorOptions.textColor,
                                    MozUserSelect: "none",
                                }),
                                singleValue: (base, props) => ({
                                    ...customSelectStyle.singleValue!(
                                        base,
                                        props
                                    ),
                                    color:
                                        node.fontColor ??
                                        colorOptions.textColor,
                                    MozUserSelect: "none",
                                }),
                                multiValueLabel: (base, props) => ({
                                    ...customSelectStyle.multiValueLabel!(
                                        base,
                                        props
                                    ),
                                    color:
                                        node.fontColor ??
                                        colorOptions.textColor,
                                    backgroundColor: "transparent",
                                    MozUserSelect: "none",
                                }),
                                multiValueRemove: (base, props) => ({
                                    ...customSelectStyle.multiValueRemove!(
                                        base,
                                        props
                                    ),
                                    color:
                                        node.fontColor ??
                                        colorOptions.textColor,
                                    backgroundColor: "transparent",
                                }),
                                indicatorsContainer: (base, _props) => ({
                                    ...base,
                                    height: size.height - heightOffset,
                                    justifyContent: "space-between",
                                    pointerEvents: "auto",
                                }),
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 558,
                                }),
                            } as StylesConfig<StringOption, boolean>
                        }
                        options={columnFormat.options.map((item) => ({
                            label: item,
                            value: item,
                        }))}
                        onChange={(newValue) => {
                            let metric = "";
                            if (newValue != null) {
                                if (
                                    (columnFormat! as ListFormat)
                                        .allowMultipleSelection
                                ) {
                                    metric = (newValue as StringOption[])
                                        .map((item) => item.value)
                                        .join(",");
                                } else {
                                    metric = (newValue as StringOption).value;
                                }
                            }
                            this.props.canvasTreeStore.updateNodeAction(
                                node.id,
                                {
                                    metric: metric,
                                },
                                true
                            );
                            this.props.currentSimpleEditParentItem.current?.focus(
                                {
                                    preventScroll: true,
                                }
                            );
                        }}
                        value={
                            columnFormat.allowMultipleSelection
                                ? value
                                    ? value.split(",").map((item) => ({
                                          label: item,
                                          value: item,
                                      }))
                                    : undefined
                                : { label: value, value: value }
                        }
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                        menuPortalTarget={document.body}
                    />
                )}
                {!isListFormat(columnFormat) &&
                    (edited && this.props.editedNode!.internalEdit ? (
                        isDateFormat(columnFormat) &&
                        !this.props.editedNode!.metric.startsWith("=") ? (
                            <DateTimeInput
                                dataTestId={`${this.props.rootDataTestId}-input`}
                                style={{ ...inputStyle }}
                                columnFormat={columnFormat}
                                currentSimpleEdit={this.props.currentSimpleEdit}
                                canWrite={this.props.canWrite}
                                spreadSheetGrid={this.props.spreadSheetGrid}
                                live={this.props.live}
                                row={row}
                                col={col}
                                onChangeEditedNode={
                                    this.props.onChangeEditedNode
                                }
                                editedNode={this.props.editedNode!}
                                onBlur={
                                    this.buildSpreadSheetAutosizeInputOnBlur
                                }
                                onKeyDown={
                                    this.buildSpreadSheetAutosizeInputOnKeyDown
                                }
                                onPaste={
                                    this.buildSpreadSheetAutosizeInputOnPaste
                                }
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    zIndex: 998,
                                    position: "absolute",
                                    height: size.height,
                                    overflow: "visible",
                                    backgroundColor: this.props.colorOptions
                                        .fillColor,
                                    border: `1px solid #39F`,
                                    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
                                }}
                            >
                                <AutosizeInput
                                    data-test-id={`${this.props.rootDataTestId}-input`}
                                    onContextMenu={preventDefault}
                                    // onResize={(evt) => {
                                    //     let height = (evt.target! as any)
                                    //         .clientHeight;
                                    //     height = height / this.props.scale;
                                    //     setTimeout(() => {
                                    //         let newScale = Math.max(
                                    //             1,
                                    //             height /
                                    //                 this.props.defaultSize
                                    //                     .height
                                    //         );
                                    //         let rowScales: number[] =
                                    //             this.props.spreadSheetGrid
                                    //                 .rowScales ??
                                    //             new Array(
                                    //                 this.props.spreadSheetGrid.rows
                                    //             ).fill(1);
                                    //         if (newScale < rowScales[row]) {
                                    //             let rowNodes = this.props.nodes.filter(
                                    //                 (otherNode) =>
                                    //                     otherNode.y === row &&
                                    //                     otherNode.id !== node.id
                                    //             );
                                    //             let heights = rowNodes.map(
                                    //                 (otherNode) => {
                                    //                     let otherColumnFormat =
                                    //                         this.props
                                    //                             .spreadSheetGrid
                                    //                             .headers?.[
                                    //                             otherNode.x
                                    //                         ]?.columnFormat ??
                                    //                         undefined;
                                    //                     let value = getPrettyPrintFormatValue(
                                    //                         otherNode,
                                    //                         otherColumnFormat ??
                                    //                             otherNode.format
                                    //                     );
                                    //                     let width =
                                    //                         this.getSize(
                                    //                             otherNode.y,
                                    //                             otherNode.x
                                    //                         ).width /
                                    //                             this.props
                                    //                                 .scale -
                                    //                         (isSimpleSpreadSheet
                                    //                             ? 10
                                    //                             : 20);
                                    //                     let fontSize =
                                    //                         node.fontSize ||
                                    //                         defaultFontSize;
                                    //                     let fontFamily =
                                    //                         "Roboto";
                                    //                     return getTextHeight(
                                    //                         value,
                                    //                         width,
                                    //                         fontFamily,
                                    //                         fontSize
                                    //                     );
                                    //                 }
                                    //             );
                                    //             let maxHeight = Math.max(
                                    //                 ...heights
                                    //             );
                                    //             if (height >= maxHeight) {
                                    //                 rowScales[row] = newScale;
                                    //                 let props = {
                                    //                     rowScales: rowScales,
                                    //                 };
                                    //                 this.props.onChange(props);
                                    //             }
                                    //         } else {
                                    //             rowScales[row] = newScale;
                                    //             let props = {
                                    //                 rowScales: rowScales,
                                    //             };

                                    //             this.props.onChange(props);
                                    //         }
                                    //     }, 0);
                                    // }}
                                    data-row={row}
                                    data-col={col}
                                    disabled={
                                        !this.props.canWrite ||
                                        (this.props.live &&
                                            !this.props.spreadSheetGrid
                                                .unlocked)
                                    }
                                    ref={this.props.currentSimpleEdit}
                                    placeholder={"METRIC"}
                                    minWidth={inputStyle.width}
                                    inputStyle={inputStyleWithoutWidth}
                                    onMouseDown={stopPropagation}
                                    onMouseUp={stopPropagation}
                                    onClick={(e) => {
                                        this.setState({
                                            focusedAfterClick: true,
                                        });
                                        stopPropagation(e);
                                    }}
                                    onDoubleClick={stopPropagation}
                                    value={value}
                                    onChange={
                                        this
                                            .buildSpreadSheetAutosizeInputOnChange
                                    }
                                    onBlur={() => {
                                        this.setState({
                                            focusedAfterClick: false,
                                        });
                                        this.buildSpreadSheetAutosizeInputOnBlur();
                                    }}
                                    onKeyDown={
                                        this
                                            .buildSpreadSheetAutosizeInputOnKeyDown
                                    }
                                    onPaste={
                                        this
                                            .buildSpreadSheetAutosizeInputOnPaste
                                    }
                                />
                            </div>
                        )
                    ) : (
                        <div
                            data-row={row}
                            style={{
                                height: size.height,
                                display: "flex",
                                alignItems: "center",
                            }}
                            data-col={col}
                            data-nodeindex={nodeIndex}
                            onClick={this.buildSpreadSheetSpan2OnClick}
                            onDoubleClick={this.buildSpreadSheetSpan2OnClick}
                            onTouchStart={this.buildSpreadSheetSpan2OnClick}
                            onMouseDown={(e) => {
                                e.stopPropagation();
                                this.props.onStartEditNode(node!, false, false);
                                this.props.setMouseMultiSelection({
                                    started: true,
                                    pos: {
                                        startRow: row,
                                        startCol: col,
                                    }
                                });
                            }}
                            onMouseUp={(e) => {
                                e.preventDefault();
                                if (!this.props.mouseMultiSelection.started) return;
                                this.addToSelectArea(row, col);
                                this.props.enableMultiSelection(false);
                            }}
                            onMouseMove={(e: any) => {
                                if (!this.props.mouseMultiSelection.started) return;
                                const { startRow, startCol } = this.props.mouseMultiSelection.pos;

                                let selectionArea = {
                                    top: Math.min(startRow, row),
                                    bottom: Math.max(startRow, row),
                                    left: Math.min(startCol, col),
                                    right: Math.max(startCol, col),
                                    gridId: this.props.spreadSheetGrid.id,
                                };
                                this.props.onSelectArea(
                                    selectionArea,
                                    false
                                );
                            }}
                        >
                            <span
                                className="unselectable"
                                style={{
                                    ...inputStyle,
                                    height: "100%",
                                    overflowWrap: "break-word",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    color: value
                                        ? node.fontColor ||
                                          colorOptions.textColor
                                        : "#869aac",
                                }}
                            >
                                {value || ""}
                            </span>
                        </div>
                    ))}

                {!edited && (
                    <>
                        {error && (
                            <img
                                alt=""
                                width={`${8 * this.props.scale}`}
                                height={`${8 * this.props.scale}`}
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: 25,
                                    bottom: 5,
                                }}
                                title={
                                    this.props.canvasTreeStore.canvasTreeRequestErrorsState.get(
                                        node.id
                                    ) ??
                                    this.props.canvasTreeStore.canvasTreeParseErrorsState.get(
                                        node.id
                                    ) ??
                                    ""
                                }
                                src="/dist/img/error.png"
                            />
                        )}
                    </>
                )}
            </div>
        );
    }

    private buildSimpleSpreadSheetHeader(
        identifier: string,
        row: number,
        col: number,
        size: { width: number; height: number },
        _colorOptions: GridColorOptions
    ): JSX.Element {
        let inputStyle = {
            width: size.width - 40 * this.props.scale,
            fontWeight: 400,
            fontFamily: "Roboto",
            fontSize: identifierFontSize * this.props.scale,
            backgroundColor: "transparent",
            color: "#70889E",
        };
        return (
            <div
                data-col={col}
                data-row={row}
                className="my-row"
                style={{
                    width: size.width,
                    height: size.height,
                    alignItems: "center",
                    marginLeft: 5 * this.props.scale,
                }}
                onMouseDown={stopPropagation}
                onContextMenu={this.buildSpreadSheetHeaderOnContextMenu}
            >
                <span
                    className="unselectable"
                    style={{
                        ...inputStyle,
                    }}
                >
                    {identifier}
                </span>
            </div>
        );
    }

    private buildSpreadSheetHeader(
        header: CanvasGridHeader | undefined,
        row: number,
        col: number,
        size: { width: number; height: number },
        colorOptions: GridColorOptions
    ): JSX.Element {
        let edited =
            this.props.editedGridHeader &&
            this.props.editedGridHeader.gridId ===
                this.props.spreadSheetGrid.id &&
            this.props.editedGridHeader.col === col &&
            this.props.editedGridHeader.row === row;
        let value = edited
            ? this.props.editedGridHeader?.text ?? ""
            : header?.text ?? "";
        let columnNumberStyle = {
            position: "absolute",
            left: 0,
            fontWeight: 400,
            fontFamily: "Roboto",
            fontSize: identifierFontSize * this.props.scale,
            backgroundColor: "transparent",
            color: "#70889E",
        } as React.CSSProperties;
        let inputStyle = {
            paddingTop: 3 * this.props.scale,
            paddingBottom: 3 * this.props.scale,
            paddingLeft: 7 * this.props.scale,
            width: size.width - (edited ? 22 : 15) * this.props.scale,
            fontWeight: 700,
            fontSize:
                (header?.fontSize ??
                    colorOptions.fontSize ??
                    defaultHeaderSize) * this.props.scale,
            border: "none",
            boxShadow: "none",
            outline: "none",
            backgroundColor: "transparent",
            fontFamily: "Roboto",
            color: header?.fontColor ?? colorOptions.textColor,
        };
        return (
            <div
                data-test-id={this.props.rootDataTestId}
                className="my-row"
                ref={
                    edited ? this.props.currentSimpleEditParentItem : undefined
                }
                tabIndex={0}
                data-row={row}
                data-col={col}
                style={{
                    width: size.width,
                    height: size.height,
                    alignItems: "center",
                    marginLeft: 5 * this.props.scale,
                    position: "relative",
                    outline: "none",
                }}
                onMouseDown={stopPropagation}
                onClick={this.buildSpreadSheetHeaderOnClick}
                onContextMenu={this.buildSpreadSheetHeaderOnContextMenu}
            >
                {edited ? (
                    <TextareaAutosize
                        data-test-id={`${this.props.rootDataTestId}-textarea`}
                        className="spreadsheet-header-textarea"
                        onContextMenu={preventDefault}
                        data-row={row}
                        data-col={col}
                        disabled={
                            !this.props.canWrite ||
                            (this.props.live &&
                                !this.props.spreadSheetGrid.unlocked)
                        }
                        ref={edited ? this.props.currentSimpleEdit : undefined}
                        placeholder={!this.props.live ? "TITLE" : ""}
                        style={{ ...inputStyle, resize: "none" }}
                        onMouseDown={stopPropagation}
                        onClick={stopPropagation}
                        value={
                            (edited
                                ? this.props.editedGridHeader?.text
                                : header?.text) ?? ""
                        }
                        onChange={
                            this.buildSpreadSheetHeaderAutosizeInputOnChange
                        }
                        onFocus={
                            this.buildSpreadSheetHeaderAutosizeInputOnFocus
                        }
                        onBlur={this.buildSpreadSheetHeaderAutosizeInputOnBlur}
                        onKeyDown={
                            this.buildSpreadSheetHeaderAutosizeInputOnKeyDown
                        }
                        onPaste={this.buildSpreadSheetAutosizeInputOnPaste}
                    />
                ) : (
                    <>
                        {!this.props.live && this.props.hovered && col >= 0 && (
                            <span
                                className="unselectable"
                                style={columnNumberStyle}
                            >
                                {StringUtils.numToAlphabet(col + 1)}
                            </span>
                        )}
                        <span
                            data-row={row}
                            data-col={col}
                            data-header-text={header?.text}
                            data-header-fontcolor={header?.fontColor}
                            data-header-fontsize={header?.fontSize}
                            className="unselectable"
                            style={{
                                ...inputStyle,
                                color: value
                                    ? header?.fontColor ??
                                      colorOptions.textColor
                                    : "#869aac",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            onClick={this.buildSpreadSheetHeaderSpanOnClick}
                        >
                            {value || "TITLE"}
                        </span>
                    </>
                )}
                {!edited &&
                    this.props.hovered &&
                    this.props.canWrite &&
                    !this.props.live &&
                    col !== -1 && (
                        <FormatDropdown
                            rootDataTestId={this.props.rootDataTestId}
                            allowLocationGeneration
                            onGenerateLocation={() => {
                                this.props.onGenerateLocation(col);
                            }}
                            canvasTreeStore={this.props.canvasTreeStore}
                            onApply={this.props.onApplyFormat}
                            sortedNodes={this.props.nodes}
                            scale={this.props.scale}
                            col={col}
                            openedFormatDropdownCol={
                                this.props.openedFormatDropdownCol
                            }
                            grid={this.props.spreadSheetGrid}
                            setOpenedFormatDropdownCol={
                                this.props.setOpenedFormatDropdownCol
                            }
                        />
                    )}
                {!edited &&
                    this.props.hovered &&
                    this.props.canWrite &&
                    !this.props.live &&
                    col !== -1 && (
                        <div
                            data-test-id={`${this.props.rootDataTestId}-sortButton`}
                            title="Sort"
                            data-col={col}
                            onClick={this.buildSpreadSheetHeaderSortOnClick}
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: 25 * this.props.scale,
                                transformOrigin: "center center",
                                transform:
                                    this.props.spreadSheetGrid
                                        .lastSortedColumn === col &&
                                    (this.props.spreadSheetGrid
                                        .lastSortDirection ?? -1) === -1
                                        ? "none"
                                        : "rotate(180deg)",
                            }}
                        >
                            <CanvasIconsContainter
                                type={"Sort"}
                                options={{
                                    width: 11 * this.props.scale,
                                    height: 11 * this.props.scale,
                                }}
                            />
                        </div>
                    )}
            </div>
        );
    }

    private renderHeaderCell(): JSX.Element[] {
        if (!this.props.spreadSheetGrid.headersEnabled) return [];

        // Cells can have additional control elements
        let cell: JSX.Element[] = [];

        let width = defaultNumberLetterTitleSize * this.props.scale;
        let rowHeaderWidth =
            this.props.scale *
            this.props.defaultSize.width *
            (this.props.spreadSheetGrid.leftTitleColumnScale ?? 1);
        if (
            this.props.colIndex < 0 ||
            (!this.props.spreadSheetGrid.leftHeadersEnabled &&
                this.props.colIndex === 0)
        ) {
            if (this.props.showOptions) {
                cell.push(
                    <div
                        style={{
                            width: width,
                            borderBottom: this.props.defaultLineBorder,
                        }}
                        key="left_top_placeholder_1"
                    />
                );
                if (this.props.spreadSheetGrid.leftHeadersEnabled) {
                    cell.push(
                        <div
                            style={{
                                width: rowHeaderWidth,
                                borderBottom: this.props.defaultLineBorder,
                            }}
                            key="left_top_placeholder_2"
                        />
                    );
                }
            } else {
                if (this.props.spreadSheetGrid.leftHeadersEnabled) {
                    cell.push(
                        <div
                            style={{
                                width: rowHeaderWidth,
                                borderBottom: this.props.defaultLineBorder,
                            }}
                            key="left_top_placeholder_1"
                        />
                    );
                }
            }
        }

        if (this.props.colIndex >= 0) {
            let i = this.props.colIndex;

            let header = this.props.spreadSheetGrid.headers?.[i] ?? undefined;

            let size = {
                height:
                    this.props.scale *
                    this.props.defaultSize.height *
                    (this.props.spreadSheetGrid?.titleRowScale ?? 1),
                width:
                    this.props.scale *
                    this.props.defaultSize.width *
                    (this.props.spreadSheetGrid.columnScales?.[i] ?? 1),
            };
            let borderOptions: CSSProperties = {
                borderTop: "1px solid transparent",
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
                borderBottom: this.props.defaultLineBorder,
            };
            let edited =
                this.props.editedGridHeader &&
                this.props.editedGridHeader.gridId ===
                    this.props.spreadSheetGrid.id &&
                this.props.editedGridHeader.col === i &&
                this.props.editedGridHeader.row === -1;
            if (edited) {
                borderOptions = {
                    border: `1px solid #39F`,
                    boxShadow: this.state.spreadSheetEditedHeaderFocused
                        ? "0 0 10px rgba(0,0,0,0.5)"
                        : "none",
                };
            } else if (
                (!this.props.live || this.props.spreadSheetGrid.unlocked) &&
                this.positionInSelectArea(-1, i) &&
                !edited
            ) {
                borderOptions = {
                    border: `1px dashed ${this.props.colorOptions.textColor}`,
                };
            }

            cell.push(
                <div
                    key={`column_header_${i}`}
                    style={{
                        height: size.height,
                        width: size.width,
                        ...borderOptions,
                    }}
                >
                    <Resizable
                        data-j={i}
                        data-width={size.width}
                        data-height={size.height}
                        className="cancel-drag"
                        enable={
                            !this.props.canWrite ||
                            (this.props.live &&
                                !this.props.spreadSheetGrid.unlocked)
                                ? {
                                      top: false,
                                      right: false,
                                      bottom: false,
                                      left: false,
                                      topRight: false,
                                      bottomRight: false,
                                      bottomLeft: false,
                                      topLeft: false,
                                  }
                                : {
                                      top: false,
                                      right: true,
                                      bottom: true,
                                      left: false,
                                      topRight: false,
                                      bottomRight: false,
                                      bottomLeft: false,
                                      topLeft: false,
                                  }
                        }
                        onResizeStart={(evt) => {
                            evt.stopPropagation();
                            this.props.setSpreadSheetState({
                                resizeHeader: true,
                            });
                        }}
                        size={size}
                        onResizeStop={this.renderResizableTopRowOnResizeStop}
                    >
                        {this.buildSpreadSheetHeader(
                            header,
                            -1,
                            i,
                            size,
                            this.props.colorOptions
                        )}
                    </Resizable>
                </div>
            );
        }

        return cell;
    }

    private renderFooterCell(): JSX.Element[] {
        if (
            this.props.colIndex < 0 ||
            (!this.props.spreadSheetGrid.leftHeadersEnabled &&
                this.props.colIndex === 0)
        ) {
            let inputStyle = {
                fontWeight: 400,
                fontFamily: "Roboto",
                fontSize: identifierFontSize * this.props.scale,
                backgroundColor: "transparent",
                color: "#70889E",
            };
            return [
                <div
                    title="Insert row"
                    key={`title_row_header_add_row`}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        marginLeft: 5 * this.props.scale,
                        height:
                            this.props.scale * this.props.defaultSize.height,
                    }}
                    onClick={(evt) => {
                        evt.stopPropagation();
                        this.props.canvasTreeStore.insertGridRowAction(
                            this.props.spreadSheetGrid.id,
                            this.props.spreadSheetGrid.rows
                        );
                    }}
                >
                    <span className="unselectable" style={inputStyle}>
                        {this.props.hovered ? "+" : " "}
                    </span>
                </div>,
            ];
        } else {
            return [];
        }
    }
    private renderRightCell(): JSX.Element[] {
        if (
            this.props.rowIndex < 0 ||
            (!this.props.spreadSheetGrid.headersEnabled &&
                this.props.rowIndex === 0)
        ) {
            let inputStyle = {
                fontWeight: 400,
                fontFamily: "Roboto",
                fontSize: identifierFontSize * this.props.scale,
                backgroundColor: "transparent",
                color: "#70889E",
            };
            return [
                <div
                    title="Insert Column"
                    key={`title_row_header_add_column`}
                    style={{
                        display: "flex",
                        height:
                            this.props.scale *
                            this.props.defaultSize.height *
                            (this.props.spreadSheetGrid.titleRowScale ?? 1),
                        alignItems: "center",
                        cursor: "pointer",
                        marginLeft: 5 * this.props.scale,
                    }}
                    onClick={(evt) => {
                        evt.stopPropagation();
                        this.props.canvasTreeStore.insertGridColumnAction(
                            this.props.spreadSheetGrid.id,
                            this.props.spreadSheetGrid.cols
                        );
                    }}
                >
                    <span className="unselectable" style={inputStyle}>
                        {this.props.hovered ? "+" : " "}
                    </span>
                </div>,
            ];
        } else {
            return [];
        }
    }

    private renderCell(): JSX.Element[] {
        let cell: JSX.Element[] = [];

        let size = {
            height:
                this.props.scale *
                this.props.defaultSize.height *
                (this.props.spreadSheetGrid.rowScales?.[this.props.rowIndex] ??
                    1),
            width: defaultNumberLetterTitleSize * this.props.scale,
        };
        if (
            this.props.showOptions &&
            (this.props.colIndex < 0 ||
                (!this.props.spreadSheetGrid.leftHeadersEnabled &&
                    this.props.colIndex === 0))
        ) {
            cell.push(
                <div
                    key={`title_row_header_${this.props.rowIndex}`}
                    style={{
                        borderBottom: this.props.defaultLineBorder,
                        height: size.height,
                        width: size.width,
                    }}
                >
                    {this.props.hovered &&
                        this.buildSimpleSpreadSheetHeader(
                            String(this.props.rowIndex + 1),
                            this.props.rowIndex,
                            -1,
                            size,
                            this.props.colorOptions
                        )}
                </div>
            );
        }

        if (
            this.props.spreadSheetGrid.leftHeadersEnabled &&
            this.props.colIndex < 0
        ) {
            let header =
                this.props.spreadSheetGrid.leftHeaders?.[this.props.rowIndex] ??
                undefined;
            let borderOptions: CSSProperties = {
                borderTop: "1px solid transparent",
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
                borderBottom: this.props.defaultLineBorder,
            };
            let edited =
                this.props.editedGridHeader &&
                this.props.editedGridHeader.gridId ===
                    this.props.spreadSheetGrid.id &&
                this.props.editedGridHeader.col === -1 &&
                this.props.editedGridHeader.row === this.props.rowIndex;

            if (edited) {
                borderOptions = {
                    border: `1px solid #39F`,
                    boxShadow: this.state.spreadSheetEditedHeaderFocused
                        ? "0 0 10px rgba(0,0,0,0.5)"
                        : "none",
                };
            } else if (
                (!this.props.live || this.props.spreadSheetGrid.unlocked) &&
                this.positionInSelectArea(this.props.rowIndex, -1) &&
                !edited
            ) {
                borderOptions = {
                    border: `1px dashed ${this.props.colorOptions.textColor}`,
                    borderBottom: "none",
                };
            }

            let size = {
                height:
                    this.props.scale *
                    this.props.defaultSize.height *
                    (this.props.spreadSheetGrid.rowScales?.[
                        this.props.rowIndex
                    ] ?? 1),
                width:
                    this.props.scale *
                    this.props.defaultSize.width *
                    (this.props.spreadSheetGrid?.leftTitleColumnScale ?? 1),
            };

            cell.push(
                <div
                    key={`row_header_${this.props.rowIndex}`}
                    style={{
                        height: size.height,
                        width: size.width,
                        ...borderOptions,
                    }}
                >
                    <Resizable
                        data-i={this.props.rowIndex}
                        data-width={size.width}
                        data-height={size.height}
                        className="cancel-drag"
                        enable={
                            !this.props.canWrite ||
                            (this.props.live &&
                                !this.props.spreadSheetGrid.unlocked)
                                ? {
                                      top: false,
                                      right: false,
                                      bottom: false,
                                      left: false,
                                      topRight: false,
                                      bottomRight: false,
                                      bottomLeft: false,
                                      topLeft: false,
                                  }
                                : {
                                      top: false,
                                      right: true,
                                      bottom: true,
                                      left: false,
                                      topRight: false,
                                      bottomRight: false,
                                      bottomLeft: false,
                                      topLeft: false,
                                  }
                        }
                        onResizeStart={stopPropagation}
                        size={size}
                        onResizeStop={
                            this.renderResizableLeftColumnOnResizeStop
                        }
                    >
                        {this.buildSpreadSheetHeader(
                            header,
                            this.props.rowIndex,
                            -1,
                            size,
                            this.props.colorOptions
                        )}
                    </Resizable>
                </div>
            );
        }
        if (this.props.colIndex < 0) return cell;

        let j = this.props.colIndex;
        size = this.getSize(this.props.rowIndex, j);
        const nodeIndex: number =
            this.props.rowIndex * this.props.spreadSheetGrid.cols + j;
        let node = this.props.nodes[nodeIndex];
        if (node == null) {
            // probably, sheets with inconsistent state exist
            cell.push(<div />);
            return cell;
        }

        let borderOptions;
        let backgroundColor = node.fillColor ?? "transparent";
        let edited =
            this.props.editedNode != null &&
            node.id === this.props.editedNode.id;
        if (edited && this.isInternalEditEnabled(node, j)) {
            borderOptions = {
                borderBottom: this.props.defaultLineBorder,
            };
        } else if (edited) {
            borderOptions = {
                border: `1px solid #39F`,
                boxShadow: this.props.editedNode?.internalEdit
                    ? "0 0 10px rgba(0,0,0,0.5)"
                    : "none",
            };
        } else if (
            (((!this.props.live || this.props.spreadSheetGrid.unlocked) &&
            this.positionInSelectArea(this.props.rowIndex, j)) ||
            (!this.props.resizeHeader && this.props.cellHoverId === node.id))
        ) {
            borderOptions = {
                border: `1px dashed ${this.props.colorOptions.textColor}`,
            };
        } else if (
            (!this.props.live || this.props.spreadSheetGrid.unlocked) &&
            this.positionInAutoFillArea(this.props.rowIndex, j)
        ) {
            borderOptions = {
                border: `0.5px solid #39F`,
            };
        } else {
            borderOptions = {
                borderBottom: this.props.defaultLineBorder,
            };
        }
        let cursorOptions = this.props.resizeHeader
            ? {
                  cursor: "row-resize",
              }
            : {};
        cell.push(
            <div
                key={node.id}
                style={{
                    position: "relative",
                    backgroundColor: backgroundColor,
                    height: size.height,
                    width: size.width,
                    ...borderOptions,
                    ...cursorOptions,
                }}
                onMouseEnter={() => {
                    if (!this.props.editedNode?.internalEdit)
                        this.props.setCellHoverId(node.id)
                }}
                onMouseLeave={() => {
                    this.props.setCellHoverId(undefined)
                }}
                onMouseUp={(e) => {
                    this.props.setMouseMultiSelection({
                        ...this.props.mouseMultiSelection,
                        started: false,
                    });
                }}
            >
                <Resizable
                    data-i={this.props.rowIndex}
                    data-j={j}
                    data-width={size.width}
                    data-height={size.height}
                    className="cancel-drag"
                    handleStyles={{
                        bottomRight: {
                            zIndex: 10,
                            position: "absolute",
                            left: size.width - 6 * this.props.scale,
                            top: size.height - 6 * this.props.scale,
                            width: 8 * this.props.scale,
                            height: 8 * this.props.scale,
                            background: "#39F",
                        },
                    }}
                    enable={
                        this.isInternalEditEnabled(node, j) ||
                        !this.props.canWrite ||
                        (this.props.live &&
                            !this.props.spreadSheetGrid.unlocked)
                            ? {
                                top: false,
                                right: false,
                                bottom: false,
                                left: false,
                                topRight: false,
                                bottomRight: false,
                                bottomLeft: false,
                                topLeft: false,
                            }
                            : this.isMaxSelectArea(this.props.rowIndex, j)
                            ? {
                                top: false,
                                right: true,
                                bottom: true,
                                left: false,
                                topRight: false,
                                bottomRight: true,
                                bottomLeft: false,
                                topLeft: false,
                            }
                            : {
                                top: false,
                                right: true,
                                bottom: true,
                                left: false,
                                topRight: false,
                                bottomRight: false,
                                bottomLeft: false,
                                topLeft: false,
                            }
                    }
                    onResizeStart={this.renderResizableElementOnResizeStart}
                    onResize={this.renderResizableElementOnResize}
                    size={size}
                    onResizeStop={this.renderResizableElementOnResizeStop}
                >
                    {this.buildSpreadSheetItem(
                        node,
                        nodeIndex,
                        size,
                        this.props.rowIndex,
                        j,
                        this.props.isSimpleSpreadSheet,
                        this.props.colorOptions
                    )}
                </Resizable>
            </div>
        );
        return cell;
    }

    public render(): JSX.Element | JSX.Element[] {
        if (this.props.colIndex < this.props.spreadSheetGrid.cols) {
            if (this.props.rowIndex < 0) {
                return this.renderHeaderCell();
            } else if (this.props.rowIndex >= this.props.spreadSheetGrid.rows) {
                return this.renderFooterCell();
            } else {
                return this.renderCell();
            }
        } else {
            return this.renderRightCell();
        }
    }
}

export default SpreadSheetElementCell;
