import CanvasTreeStore from "../CanvasTreeStore";
import React, { Component } from "react";

import { Resizable } from "re-resizable";
import elements from "common/CanvasElements";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import { mainStyle } from "common/MainStyle";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import HtmlElementProps from "./HtmlElementProps";
import {
    getNewSizeAfterResize,
    changeElementWhenResize,
} from "../BaseCanvasResizableFunctions";
import {
    getBackgroundPlacementData,
    getBoundingBoxOfRotatedRectangle,
    getNodeTranslationFromBackground,
    getNodeTranslationString,
    InnerCanvasChanges,
    SlideRect,
} from "common/Canvas";
import {
    mobileAndTabletBreakpoint,
    openDelayedNodeMenuAfterTouch,
} from "common/utilities/UIResponsiveManager";
import Portal from "common/Portal";
import DropdownBackgroundsMenu from "./DropdownBackgroundsMenu";
import {
    makeMoveable,
    DraggableProps,
    ResizableProps,
    RotatableProps,
    Rotatable,
    Draggable,
    Resizable as MoveableResizable,
    OnDrag,
} from "react-moveable";
import MoveableHelper from "moveable-helper";
import { getTransformList } from "common/utilities/parseTransformString";
import styles from "./Moveable.module.css";
import cx from "classnames";
import { BackgroundMode } from "common/CanvasUserApi";
import OutsideAlerter from "common/OutsideAlerter";
import { snapElementToPoints } from "../Snap";
import GlobalContext, { GlobalContextContents } from "GlobalContext";

// In order to use only some able, make a component with makeMoveable function.
const Moveable = makeMoveable<DraggableProps & ResizableProps & RotatableProps>(
    [Draggable, MoveableResizable, Rotatable]
);

const typeName = "backgroundsState";

const gridOffsetX = 26;
const gridOffsetY = 26;

interface SlideBackgroundProps {
    canvasTreeStore: CanvasTreeStore;
    live: boolean;
    slideContainerClassName?: string;
    showAsSlideShow?: boolean;
    scale: number;
    canWrite: boolean;
    backgroundRootRef: React.RefObject<HTMLDivElement>;
    sharedPolicy: CanvasSharedPolicy;
    showDots: boolean;
    slideRect: SlideRect;
    slideRootRef: React.RefObject<HTMLDivElement>;
    moduleTitle: string;
}

@observer
class SlideBackground extends CanvasInteractionComponent<
    SlideBackgroundProps,
    {}
> {
    constructor(props: SlideBackgroundProps) {
        super(props);

        this.renderContents = this.renderContents.bind(this);
    }

    public renderContents(globalContext: GlobalContextContents): JSX.Element {
        const backgroundComponents = [];

        let width = this.props.slideRect.width;
        let height = this.props.slideRect.height;
        let shadowProps =
            this.props.slideContainerClassName == null && !this.props.live
                ? { boxShadow: "-4px 4px 4px #EEEEEE, 4px 4px 4px #EEEEEE" }
                : undefined;
        let borderProps =
            this.props.slideContainerClassName == null && this.props.live
                ? { border: "1px solid #EEEEEE" }
                : undefined;
        backgroundComponents.push(
            <Resizable
                enable={
                    this.props.live || !this.props.canWrite
                        ? {
                              top: false,
                              right: false,
                              bottom: false,
                              left: false,
                              topRight: false,
                              bottomRight: false,
                              bottomLeft: false,
                              topLeft: false,
                          }
                        : {
                              top: false,
                              right: false,
                              bottom: true,
                              left: false,
                              topRight: false,
                              bottomRight: false,
                              bottomLeft: false,
                              topLeft: false,
                          }
                }
                onResizeStart={(evt) => {
                    evt.stopPropagation();
                }}
                onResize={(e, direction, ref, d) => {
                    let shiftKey = e.shiftKey;
                    changeElementWhenResize(
                        this.props.slideRect,
                        this.props.scale,
                        direction,
                        d,
                        ref,
                        shiftKey
                    );
                }}
                onResizeStop={(_e, _direction, _ref, d) => {
                    this.trackNewPerformance(elements.background);
                    let newSize = getNewSizeAfterResize(
                        this.props.slideRect,
                        this.props.scale,
                        _direction,
                        d
                    );

                    let height = newSize.height;
                    this.props.canvasTreeStore.updateSlideHeightAction(height);
                }}
                size={{
                    width: width * this.props.scale,
                    height: height * this.props.scale,
                }}
                handleStyles={{
                    bottom: {
                        height: mobileAndTabletBreakpoint() ? 60 : 10,
                    },
                }}
            >
                <div
                    key={"slide_background"}
                    className={this.props.slideContainerClassName}
                    ref={this.props.slideRootRef}
                    id="slide-background"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "absolute",
                        top: this.props.slideRect.y * this.props.scale,
                        left: this.props.slideRect.x * this.props.scale,
                        width: "100%",
                        height: "100%",
                        border: "none",
                        backgroundColor:
                            this.props.canvasTreeStore.slideColor ??
                            mainStyle.getPropertyValue(
                                "--slide-background-color"
                            ),
                        ...shadowProps,
                        ...borderProps,
                    }}
                ></div>
            </Resizable>
        );

        if (
            "ShowGuideDots" in globalContext.permissions &&
            this.props.showDots
        ) {
            let startX = gridOffsetX;
            let startY = gridOffsetY;
            let counter = 0;
            for (var x = startX; x < width; x += gridOffsetX) {
                for (var y = startY; y < height; y += gridOffsetY) {
                    backgroundComponents.push(
                        <div
                            key={`circle_${counter}`}
                            style={{
                                position: "absolute",
                                top: y * this.props.scale,
                                left: x * this.props.scale,
                                height: 4 * this.props.scale,
                                width: 4 * this.props.scale,
                                borderRadius: "50%",
                                background: "#BDBDBD",
                            }}
                        />
                    );
                    counter += 1;
                }
            }
        }
        return <>{backgroundComponents}</>;
    }

    public render(): JSX.Element {
        return (
            <GlobalContext.ObserverConsumer>
                {this.renderContents}
            </GlobalContext.ObserverConsumer>
        );
    }
}

interface BackgroundWrapperProps extends HtmlElementProps {
    backgroundIndex: number;
}

interface InnerState {
    dropdownOpened: boolean;
    drag: boolean;
    hovered: boolean;
    focused: boolean;
    moveableHelper: MoveableHelper;
    keepRatio?: boolean;
}

@observer
class BackgroundWrapper extends CanvasInteractionComponent<
    BackgroundWrapperProps,
    InnerState
> {
    private drag: boolean;
    menuAreaRef = React.createRef<HTMLDivElement>();
    private innerRef = React.createRef<HTMLDivElement>();
    private moveableRef = React.createRef<any>();

    constructor(props: BackgroundWrapperProps) {
        super(props);
        this.drag = false;
        this.state = {
            hovered: false,
            focused: false,
            dropdownOpened: false,
            drag: false,
            moveableHelper: new MoveableHelper(),
        };
    }

    componentDidMount(): void {
        let rootDataTestId = `backgroundElement-${
            this.props.backgroundIndex + 1
        }`;
        let backgroundElement = this.props.canvasTreeStore.backgroundsState[
            this.props.backgroundIndex
        ];
        let innerRef = this.innerRef.current;
        if (innerRef != null) {
            innerRef.setAttribute("type", typeName);
            if (backgroundElement.group_id != null)
                innerRef.setAttribute("groupId", backgroundElement.group_id);
            else {
                innerRef.removeAttribute("groupId");
            }
            innerRef.setAttribute("id", String(backgroundElement.id));
            innerRef.setAttribute("data-test-id", rootDataTestId);
        }
    }
    componentDidUpdate(prev: BackgroundWrapperProps): void {
        if (prev.selectedMetadata !== this.props.selectedMetadata) {
            let backgroundElement = this.props.canvasTreeStore.backgroundsState[
                this.props.backgroundIndex
            ];
            let innerRef = this.innerRef.current;
            if (innerRef != null) {
                if (backgroundElement.group_id != null)
                    innerRef.setAttribute(
                        "groupId",
                        backgroundElement.group_id
                    );
                else {
                    innerRef.removeAttribute("groupId");
                }
            }
        }
        // ID might change
        let backgroundElement = this.props.canvasTreeStore.backgroundsState[
            this.props.backgroundIndex
        ];
        if (
            this.innerRef.current != null &&
            this.innerRef.current.getAttribute("id") !==
                String(backgroundElement.id)
        ) {
            let rootDataTestId = `backgroundElement-${
                this.props.backgroundIndex + 1
            }`;
            this.innerRef.current.setAttribute(
                "id",
                String(backgroundElement.id)
            );
            this.innerRef.current.setAttribute("data-test-id", rootDataTestId);
        }
        this.moveableRef.current?.updateRect();
    }

    public render(): JSX.Element {
        const { canvasTreeStore } = this.props;
        const { mobileViewWasEdited, slideWidthRatio } = canvasTreeStore;
        const isCanvasDesktopView =
            canvasTreeStore.canvasViewMode === "desktop";
        let backgroundElement =
            canvasTreeStore.backgroundsState[this.props.backgroundIndex];

        const element = elements.background;

        const {
            positionX,
            positionY,
            scaleX,
            scaleY,
        } = getBackgroundPlacementData(
            canvasTreeStore.canvasViewMode,
            backgroundElement
        );

        let rootDataTestId = `backgroundElement-${
            this.props.backgroundIndex + 1
        }`;
        let naturalHeight = backgroundElement.natural_height ?? 10;
        let naturalWidth = backgroundElement.natural_width ?? 10;
        let backgroundSize = {
            height: naturalHeight * scaleY * this.props.scale,
            width: naturalWidth * scaleX * this.props.scale,
        };
        let scale = this.props.scale;
        let borderWidth: string | number | undefined =
            backgroundElement?.style_options?.borderWidth;
        let borderColor = backgroundElement?.style_options?.borderColor;

        let backgroundStyle =
            backgroundElement.style_options != null
                ? {
                      background:
                          backgroundElement.style_options.fillColor ||
                          undefined,
                      filter:
                          borderColor &&
                          backgroundElement.style_options.borderWidth != null
                              ? `drop-shadow(${borderWidth} ${borderWidth} 0 ${borderColor}) drop-shadow(-${borderWidth} -${borderWidth} 0 ${borderColor}) drop-shadow(-${borderWidth} ${borderWidth} 0 ${borderColor}) drop-shadow(${borderWidth} -${borderWidth} 0 ${borderColor})`
                              : undefined,
                      borderRadius:
                          backgroundElement.style_options.borderRadius || 0,
                  }
                : {};

        if (backgroundElement.style_options && borderColor && borderWidth) {
            borderWidth = `${borderWidth}px`;
            const leftTop = `drop-shadow(${borderWidth} ${borderWidth} 0 ${borderColor})`;
            const rightTop = `drop-shadow(-${borderWidth} -${borderWidth} 0 ${borderColor})`;
            const leftBottom = `drop-shadow(-${borderWidth} ${borderWidth} 0 ${borderColor})`;
            const rightBottom = `drop-shadow(${borderWidth} -${borderWidth} 0 ${borderColor})`;
            backgroundStyle.filter = `${leftTop} ${rightTop} ${leftBottom} ${rightBottom}`;
        }

        let selected =
            this.props.selectedMetadata?.length === 1 &&
            this.props.selectedMetadata?.[0]?.id === backgroundElement.id &&
            this.props.selectedMetadata?.[0]?.type === typeName;
        let nodeTranslation = getNodeTranslationFromBackground(
            backgroundElement
        );
        let nodeTranslationString = getNodeTranslationString(
            nodeTranslation,
            this.props.scale,
            canvasTreeStore.canvasViewMode
        );

        const boundingBox = getBoundingBoxOfRotatedRectangle(
            {
                left:
                    (positionX +
                        nodeTranslation[canvasTreeStore.canvasViewMode].x) *
                    this.props.scale,
                top:
                    (positionY +
                        nodeTranslation[canvasTreeStore.canvasViewMode].y) *
                    this.props.scale,
                right:
                    (positionX +
                        nodeTranslation[canvasTreeStore.canvasViewMode].x) *
                        this.props.scale +
                    backgroundSize.width,
                bottom:
                    (positionY +
                        nodeTranslation[canvasTreeStore.canvasViewMode].y) *
                        this.props.scale +
                    backgroundSize.height,
            },
            nodeTranslation[canvasTreeStore.canvasViewMode].angle
        );

        return (
            <>
                <div
                    data-test-id={`${rootDataTestId}-menucontainer`}
                    ref={this.menuAreaRef}
                    style={{
                        zIndex: backgroundElement.z_index ?? 50,
                        position: "absolute",
                        left:
                            (positionX +
                                nodeTranslation[canvasTreeStore.canvasViewMode]
                                    .x) *
                            this.props.scale,
                        top:
                            (positionY +
                                nodeTranslation[canvasTreeStore.canvasViewMode]
                                    .y) *
                            this.props.scale,
                        width:
                            backgroundSize.width +
                            15 * this.props.scale +
                            scale * 2,
                        height: backgroundSize.height + scale * 2,
                    }}
                    onMouseEnter={() => {
                        this.setState({ hovered: true });
                    }}
                    onMouseLeave={() => {
                        this.setState({ hovered: false });
                    }}
                    onTouchStart={() => {
                        openDelayedNodeMenuAfterTouch(
                            () => {
                                this.setState({ hovered: true });
                            },
                            () => {
                                this.setState({ hovered: false });
                            }
                        );
                    }}
                >
                    {!this.state.drag &&
                        (this.state.focused ||
                            this.state.dropdownOpened ||
                            // The hamburger menu has to appear as soon as the node gets created
                            selected) &&
                        !this.props.live && (
                            <Portal rootNode={this.props.htmlElementsRootRef}>
                                <div
                                    data-test-id={`${rootDataTestId}-menu`}
                                    onMouseEnter={() => {
                                        this.setState({ hovered: true });
                                    }}
                                    onMouseLeave={(evt) => {
                                        this.setState({ hovered: false });
                                    }}
                                >
                                    <DropdownBackgroundsMenu
                                        rootMenuTestId={`${rootDataTestId}-menu`}
                                        customStyle={{
                                            zIndex: 999,
                                            position: "absolute",
                                            left:
                                                (positionX +
                                                    nodeTranslation[
                                                        canvasTreeStore
                                                            .canvasViewMode
                                                    ].x) *
                                                    this.props.scale +
                                                backgroundSize.width,
                                            top:
                                                (positionY +
                                                    nodeTranslation[
                                                        canvasTreeStore
                                                            .canvasViewMode
                                                    ].y) *
                                                this.props.scale,
                                            right: 0,
                                            width: 15 * this.props.scale,
                                            height: 20 * this.props.scale,
                                            backgroundColor: "transparent",
                                        }}
                                        onToggle={(show) => {
                                            this.setState({
                                                dropdownOpened: show,
                                            });
                                        }}
                                        element={element}
                                        sharedPolicy={this.props.sharedPolicy}
                                        onClearEditing={
                                            this.props.onClearEditing
                                        }
                                        moduleTitle={this.props.moduleTitle}
                                        currentModuleId={
                                            this.props.currentModuleId
                                        }
                                        canvasTreeStore={
                                            this.props.canvasTreeStore
                                        }
                                        scale={this.props.scale}
                                        node={backgroundElement}
                                    />
                                </div>
                            </Portal>
                        )}
                </div>

                <OutsideAlerter
                    onReject={() => {
                        this.setState({
                            focused: false,
                        });
                    }}
                >
                    <div
                        style={{
                            top: positionY * this.props.scale,
                            left: positionX * this.props.scale,
                            width: backgroundSize.width,
                            height: backgroundSize.height,
                            position: "absolute",
                            zIndex: backgroundElement.z_index ?? 50,
                            border: selected ? "1px solid #3B82C9" : "none",
                            boxShadow: backgroundElement.style_options
                                ?.borderShadow
                                ? "0 6px 13px 0 rgba(21, 33, 56, 0.53)"
                                : "none",
                            borderRadius:
                                backgroundElement.style_options?.borderRadius,
                            transform: nodeTranslationString,
                        }}
                        ref={this.innerRef}
                        className="selectable-by-pointer"
                        onContextMenu={(evt) => {
                            this.props.onContextMenu(
                                evt,
                                {
                                    id: backgroundElement.id,
                                    type: typeName,
                                },
                                true
                            );
                        }}
                        onMouseEnter={() => {
                            this.setState({ hovered: true });
                        }}
                        onMouseLeave={() => {
                            this.setState({ hovered: false });
                        }}
                        onTouchStart={() => {
                            openDelayedNodeMenuAfterTouch(
                                () => {
                                    this.setState({
                                        hovered: true,
                                    });
                                },
                                () => {
                                    this.setState({
                                        hovered: false,
                                    });
                                }
                            );
                        }}
                        onClick={() => {
                            this.setState({
                                focused: true,
                            });
                        }}
                    >
                        <div
                            style={{
                                height: "100%",
                                width: "100%",
                                overflow: "hidden",
                            }}
                            onClick={() => {
                                if (!this.props.live && !this.drag) {
                                    this.trackNewPerformance(
                                        elements.background
                                    );
                                    //    this.props.onClearEditing();

                                    return;
                                }
                                this.drag = false;
                            }}
                        >
                            {backgroundElement.style_options?.fillColor ? (
                                <div
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        filter: backgroundStyle.filter,
                                    }}
                                >
                                    <div
                                        style={{
                                            ...backgroundStyle,
                                            width: "100%",
                                            height: "100%",
                                            // The img element should NOT be draggable
                                            pointerEvents: "none",
                                            maskImage: `url(${backgroundElement.image_url})`,
                                            WebkitMaskImage: `url(${backgroundElement.image_url})`,
                                            maskRepeat: "no-repeat",
                                            WebkitMaskRepeat: "no-repeat",
                                            maskSize: "contain",
                                            WebkitMaskSize: "contain",
                                            maskPosition: "center",
                                            WebkitMaskPosition: "center",
                                        }}
                                    />
                                </div>
                            ) : (
                                <img
                                    alt=""
                                    src={backgroundElement.image_url}
                                    style={{
                                        ...backgroundStyle,
                                        width: "100%",
                                        height: "100%", // The img element should NOT be draggable
                                        pointerEvents: "none",
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </OutsideAlerter>
                {!this.props.live && this.props.canWrite && (
                    <Moveable
                        className={cx(
                            styles.moveable,
                            !selected && styles.moveableNotSelected
                        )}
                        ref={this.moveableRef}
                        draggable={!this.props.live && this.props.canWrite}
                        throttleDrag={0}
                        onDragStart={({ target, clientX, clientY }) => {}}
                        onDrag={({
                            target,
                            beforeDelta,
                            beforeDist,
                            left,
                            top,
                            right,
                            bottom,
                            delta,
                            dist,
                            transform,
                            clientX,
                            clientY,
                        }: OnDrag) => {
                            target!.style.left = `${left}px`;
                            target!.style.top = `${top}px`;
                            this.drag = true;
                            let nearestPoints = this.props.onRebuildSnapLine(
                                {
                                    x: boundingBox.left + dist[0],
                                    y: boundingBox.top + dist[1],
                                    width: boundingBox.right - boundingBox.left,
                                    height:
                                        boundingBox.bottom - boundingBox.top,
                                },
                                {
                                    type: typeName,
                                    id: backgroundElement.id,
                                    groupId: backgroundElement.group_id,
                                }
                            );
                            let newPosition = snapElementToPoints(
                                boundingBox.right - boundingBox.left,
                                boundingBox.bottom - boundingBox.top,
                                nearestPoints
                            );
                            if (newPosition.x != null) {
                                target!.style.left = `${
                                    newPosition.x +
                                    (left - boundingBox.left - dist[0])
                                }px`;
                            }
                            if (newPosition.y != null) {
                                target!.style.top = `${
                                    newPosition.y +
                                    (top - boundingBox.top - dist[1])
                                }px`;
                            }
                            this.props.onUpdateSelectionBounds?.();
                        }}
                        onDragEnd={({ target, isDrag, clientX, clientY }) => {
                            if (this.drag) {
                                this.props.onDeleteSnapLine();
                                this.trackNewPerformance(elements.background);
                                let x =
                                    parseFloat(target.style.left) /
                                    this.props.scale;

                                let y =
                                    parseFloat(target.style.top) /
                                    this.props.scale;

                                let deltaX = x - positionX;
                                let deltaY = y - positionY;
                                let backgroundChanges = isCanvasDesktopView
                                    ? {
                                          ...backgroundElement,
                                          x: x,
                                          y: y,
                                      }
                                    : {
                                          ...backgroundElement,
                                          mobile_x: x,
                                          mobile_y: y,
                                      };

                                if (!mobileViewWasEdited) {
                                    if (
                                        canvasTreeStore.canvasViewMode ===
                                        "desktop"
                                    )
                                        backgroundChanges = {
                                            ...backgroundChanges,
                                            mobile_x: x * slideWidthRatio,
                                            mobile_y: y,
                                        };
                                    else
                                        canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                }

                                let changes: InnerCanvasChanges = {};
                                this.props.canvasTreeStore.updateBackgroundAction(
                                    backgroundElement.id,
                                    backgroundChanges,
                                    changes
                                );
                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        x: x,
                                        y: y,
                                        width: naturalWidth * scaleX,
                                        height: naturalHeight * scaleY,
                                    }
                                );
                                this.props.onMoveGroupSelection(
                                    deltaX,
                                    deltaY,
                                    {
                                        id: backgroundElement.id,
                                        type: typeName,
                                        groupId: backgroundElement.group_id,
                                    },
                                    false,
                                    changes
                                );
                                this.props.canvasTreeStore.saveChangesAction(
                                    changes,
                                    true,
                                    true,
                                    false,
                                    this.props.canvasTreeStore.backgroundsState.toJSON(),
                                    BackgroundMode.Update,
                                    false
                                );
                            }
                        }}
                        target={this.innerRef}
                        resizable={
                            !this.props.live && this.props.canWrite && selected
                        }
                        rotatable={
                            !this.props.live && this.props.canWrite && selected
                        }
                        onResizeStart={({ inputEvent }) => {
                            this.setState({
                                keepRatio: (inputEvent as any).shiftKey,
                            });
                        }}
                        keepRatio={this.state.keepRatio}
                        onResize={(e) => {
                            e.target.style.width = `${e.width}px`;
                            e.target.style.height = `${e.height}px`;
                            e.target.style.transform = e.afterTransform;
                            this.setState((state) => {
                                let keepRatio = (e.inputEvent as any).shiftKey;
                                if (keepRatio !== state.keepRatio) {
                                    return { keepRatio: keepRatio };
                                }
                                return null;
                            });
                        }}
                        onResizeEnd={({ target, isDrag, clientX, clientY }) => {
                            this.trackNewPerformance(elements.background);
                            const {
                                canvasViewMode,
                            } = this.props.canvasTreeStore;
                            let transfromOptions = getTransformList(
                                target.style.transform
                            );
                            let angle = parseFloat(
                                transfromOptions["rotate"] ?? "0"
                            );
                            let translatePosition = (
                                transfromOptions["translate"] ?? "0px, 0px"
                            )
                                .split(",")
                                .map(
                                    (item) =>
                                        parseFloat(item) / this.props.scale
                                );
                            let newX =
                                parseFloat(target.style.left) /
                                this.props.scale;
                            let newY =
                                parseFloat(target.style.top) / this.props.scale;
                            let newWidth =
                                parseFloat(target.style.width) /
                                this.props.scale;
                            let newHeight =
                                parseFloat(target.style.height) /
                                this.props.scale;
                            let resultScaleX = newWidth / naturalWidth;
                            let resultScaleY = newHeight / naturalHeight;

                            let newTranslation = {
                                ...nodeTranslation,
                                [canvasViewMode]: {
                                    angle: angle,
                                    x: translatePosition[0],
                                    y: translatePosition[1],
                                },
                            };
                            const changes = isCanvasDesktopView
                                ? {
                                      ...backgroundElement,
                                      x: newX,
                                      y: newY,
                                      scale_x: resultScaleX,
                                      scale_y: resultScaleY,
                                      style_options: {
                                          ...backgroundElement.style_options,
                                          nodeTranslation: newTranslation,
                                      },
                                  }
                                : {
                                      ...backgroundElement,
                                      mobile_x: newX,
                                      mobile_y: newY,
                                      mobile_scale_x: resultScaleX,
                                      mobile_scale_y: resultScaleY,
                                      style_options: {
                                          ...backgroundElement.style_options,
                                          nodeTranslation: newTranslation,
                                      },
                                  };

                            if (!mobileViewWasEdited) {
                                this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                            }

                            this.props.canvasTreeStore.updateBackgroundAction(
                                backgroundElement.id,
                                changes
                            );

                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                x: newX,
                                y: newY,
                                width: newWidth,
                                height: newHeight,
                            });

                            this.props.onResize();
                        }}
                        //    onRotateStart={this.state.moveableHelper.onRotateStart}
                        onRotate={this.state.moveableHelper.onRotate}
                        throttleRotate={0}
                        onRotateEnd={({ target, isDrag, clientX, clientY }) => {
                            this.trackNewPerformance(elements.background);
                            let transfromOptions = getTransformList(
                                target.style.transform
                            );
                            let angle = parseFloat(
                                transfromOptions["rotate"] ?? "0"
                            );
                            let translatePosition = (
                                transfromOptions["translate"] ?? "0px, 0px"
                            )
                                .split(",")
                                .map(
                                    (item) =>
                                        parseFloat(item) / this.props.scale
                                );
                            // let changes: Partial<CanvasBackground> = {};
                            // if (angle === 0) {
                            //     changes = isCanvasDesktopView
                            //         ? {
                            //               x:
                            //                   backgroundElement.x +
                            //                   nodeTranslation.desktop.x,
                            //               y:
                            //                   backgroundElement.y +
                            //                   nodeTranslation.desktop.y,
                            //           }
                            //         : {
                            //               mobile_x:
                            //                   backgroundElement.mobile_x +
                            //                   nodeTranslation.mobile.x,
                            //               mobile_y:
                            //                   backgroundElement.mobile_y +
                            //                   nodeTranslation.mobile.y,
                            //           };
                            // }
                            let newTranslation = {
                                ...nodeTranslation,
                                [canvasTreeStore.canvasViewMode]: {
                                    angle: angle,
                                    // x: angle === 0 ? 0 : translatePosition[0],
                                    // y: angle === 0 ? 0 : translatePosition[1],
                                    x: translatePosition[0],
                                    y: translatePosition[1],
                                },
                            };

                            this.props.canvasTreeStore.updateBackgroundAction(
                                backgroundElement.id,
                                {
                                    // ...(changes as any),
                                    style_options: {
                                        ...backgroundElement.style_options,
                                        nodeTranslation: newTranslation,
                                    },
                                }
                            );
                        }}
                    ></Moveable>
                )}
            </>
        );
    }
}

@observer
class Backgrounds extends Component<HtmlElementProps> {
    public render(): JSX.Element {
        let backgroundUIs: JSX.Element[] = [];
        const isCanvasDesktopView =
            this.props.canvasTreeStore.canvasViewMode === "desktop";
        for (
            let i = 0;
            i < this.props.canvasTreeStore.backgroundsState.length;
            ++i
        ) {
            let backgroundElement = this.props.canvasTreeStore.backgroundsState[
                i
            ];

            if (isCanvasDesktopView && backgroundElement.is_hidden) continue;
            if (!isCanvasDesktopView && backgroundElement.mobile_is_hidden)
                continue;
            backgroundUIs.push(
                <BackgroundWrapper
                    key={i}
                    backgroundIndex={i}
                    {...this.props}
                />
            );
        }
        return <>{backgroundUIs}</>;
    }
}

export { SlideBackground, Backgrounds };
