import React, { Component } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { Element } from "react-scroll";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import CustomizedAxisTick from "common/graphics/CustomizedAxisTick";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import styles from "./CustomBarChartWithOverlay.module.css";
import cx from "classnames";
import { TooltipStyles } from "../TooltipStyles";

interface BarData {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
}

interface Props {
    ticksSize?: number;
    ticksColor?: string;
    axesLinesColor?: string;
    decimals?: { [key: string]: number };
    data: ({ name: string } & { [key: string]: number })[];
    leftBarKey: string;
    barSize?: number;
    leftBarColor: string;
    rightBarKey?: string;
    rightBarColor?: string;
    title: string;
    onVariableChanged: (
        variable: string,
        maxChartValue: number,
        levelsCount: number,
        currentIndex: number,
        type: string
    ) => void;
    onSnapToGrid: (variable: string | null) => void;
    selectPreviousVariable: (variable: string | null, scroll: boolean) => void;
    selectNextVariable: (variable: string | null, scroll: boolean) => void;
    selectVariable: (variable: string | null, scroll: boolean) => void;
    onVariableEntered: (
        variable: string | null,
        type: string,
        value: number
    ) => void;
    scrollableRef: (Element & { scrollLeft: number }) | null;
    watermark?: string;
}

interface State {
    maxChartValue: number;
    currentVariable: string | null;
}

class CustomBarChartWithOverlay extends Component<Props, State> {
    private barOverlayRef: React.RefObject<HTMLDivElement> =
        React.createRef<HTMLDivElement>();
    private leftBarRef: Bar | null = null;
    private rightBarRef: Bar | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            maxChartValue: NaN,
            currentVariable: null,
        };
        this.setLeftIndex = this.setLeftIndex.bind(this);

        this.buildOverlay = this.buildOverlay.bind(this);
        this.selectVariable = this.selectVariable.bind(this);
    }

    private getCurrentIndex(value: number): number {
        return Math.round(
            (this.getLevels() * value) / this.state.maxChartValue
        );
    }

    private getLevels(): number {
        return 25;
    }

    public static getDerivedStateFromProps(
        newProps: Props,
        prevState: State
    ): State {
        if (!newProps.data) return prevState;
        let newState: State = Object.assign({}, prevState);

        let maxChartValue = newProps.data.reduce(
            (accumulator, currentValue) => {
                return Math.max(
                    accumulator,
                    newProps.rightBarKey != null
                        ? Math.max(
                              currentValue[newProps.leftBarKey] as number,
                              currentValue[newProps.rightBarKey] as number
                          )
                        : (currentValue[newProps.leftBarKey] as number)
                );
            },
            10
        );
        newState.maxChartValue = maxChartValue;
        return newState;
    }

    private setLeftIndex(index: number): void {
        if (index > this.getLevels()) return;
        if (index < 0) return;
        this.props.onVariableChanged(
            this.state.currentVariable ?? "",
            this.state.maxChartValue,
            this.getLevels(),
            index,
            this.props.leftBarKey
        );
    }

    private buildOverlay(): JSX.Element | null {
        if (this.state.currentVariable == null) return null;
        if (this.leftBarRef == null) return null;
        if (this.props.rightBarKey != null && this.rightBarRef == null)
            return null;
        let variableIndex: number = this.props.data.findIndex(
            (item) => item.name === this.state.currentVariable
        );
        let leftBarInfo:
            | ({ name?: string; background?: BarData } & BarData)
            | undefined = this.leftBarRef?.props?.data?.find(
            (item: { name?: string; background?: BarData } & BarData) =>
                item.name === this.state.currentVariable
        );
        let currentLeftValue: number =
            this.props.data.find(
                (item) => item.name === this.state.currentVariable
            )?.[this.props.leftBarKey] ?? 0;
        let currentRightValue: number | undefined = this.props.rightBarKey
            ? this.props.data.find(
                  (item) => item.name === this.state.currentVariable
              )?.[this.props.rightBarKey] ?? 0
            : undefined;
        let rightBarInfo:
            | ({ name?: string; background?: BarData } & BarData)
            | undefined = this.props.rightBarKey
            ? this.rightBarRef?.props?.data?.find(
                  (item: { name?: string; background?: BarData } & BarData) =>
                      item.name === this.state.currentVariable
              )
            : undefined;
        let currentLeftIndex: number = this.getCurrentIndex(currentLeftValue);
        let currentRightIndex: number | undefined =
            rightBarInfo != null && currentRightValue != null
                ? this.getCurrentIndex(currentRightValue)
                : undefined;
        let levelsArray: number[] = Array.from(
            { length: this.getLevels() },
            (_v, k) => k
        );
        return (
            <div
                ref={this.barOverlayRef}
                tabIndex={10}
                onKeyDown={(evt) => {
                    if (evt.key === "ArrowDown") {
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.setLeftIndex(currentLeftIndex - 1);
                    }
                    if (evt.key === "ArrowUp") {
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.setLeftIndex(currentLeftIndex + 1);
                    }
                    if (evt.key === "s") {
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.props.onSnapToGrid(this.state.currentVariable);
                    }
                    if (evt.key === "ArrowLeft") {
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.props.selectPreviousVariable(
                            this.state.currentVariable,
                            true
                        );
                    }
                    if (evt.key === "ArrowRight") {
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.props.selectNextVariable(
                            this.state.currentVariable,
                            true
                        );
                    }
                }}
            >
                <div
                    className="my-row"
                    style={{
                        left: rightBarInfo
                            ? (rightBarInfo.x ?? 0) - 37
                            : (leftBarInfo?.x ?? 30) - 40,
                        top: (leftBarInfo?.background?.y ?? 20) - 20,
                        width: 90,
                        height: (leftBarInfo?.background?.height ?? 0) + 100,
                        position: "absolute",
                        zIndex: 20,
                        justifyContent: "space-between",
                    }}
                >
                    {variableIndex > 0 ? (
                        <div
                            style={{
                                width: 15,
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div
                                onClick={() => {
                                    this.props.selectPreviousVariable(
                                        this.state.currentVariable,
                                        true
                                    );
                                }}
                                className={styles.chevronContainer}
                            >
                                <ChevronIcon transform="rotate(90)" />
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                width: 15,
                                height: "100%",
                            }}
                        />
                    )}

                    <div
                        className="flex-simple-column"
                        style={{
                            width: 60,
                            height: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div
                            onClick={() => {
                                this.setLeftIndex(currentLeftIndex + 1);
                            }}
                            className={styles.chevronContainer}
                        >
                            <ChevronIcon transform="rotate(180)" />
                        </div>
                        <div
                            className={styles.barOverlay}
                            style={{
                                width: "100%",
                                height: "100%",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    width: "100%",
                                    fontFamily: "Arial",
                                    fontSize: "12px",
                                    color:
                                        this.props.ticksColor ??
                                        mainStyle.getPropertyValue(
                                            "--graphs-axes-text-color"
                                        ),
                                    display: "block",
                                    textAlign: "center",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {this.state.currentVariable}
                            </span>
                            <input
                                key={currentLeftValue}
                                type={"number"}
                                className={cx(styles.input, "no-spin")}
                                style={{
                                    width: "calc(100% - 10px)",
                                }}
                                defaultValue={currentLeftValue.toFixed(
                                    this.props.decimals?.[
                                        this.state.currentVariable
                                    ] ?? 1
                                )}
                                placeholder=""
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                onBlur={(e) => {
                                    this.props.onVariableEntered(
                                        this.state.currentVariable,
                                        this.props.leftBarKey,
                                        Number(e.target.value)
                                    );
                                }}
                            />
                        </div>
                        <div
                            onClick={() => {
                                this.setLeftIndex(currentLeftIndex - 1);
                            }}
                            className={styles.chevronContainer}
                        >
                            <ChevronIcon />
                        </div>
                    </div>

                    {variableIndex < this.props.data.length - 1 ? (
                        <div
                            style={{
                                width: 15,
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            <div
                                onClick={() => {
                                    this.props.selectNextVariable(
                                        this.state.currentVariable,
                                        true
                                    );
                                }}
                                className={styles.chevronContainer}
                            >
                                <ChevronIcon transform="rotate(-90)" />
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                width: 15,
                                height: "100%",
                            }}
                        />
                    )}
                </div>
                {levelsArray.map((index) => {
                    let position =
                        (leftBarInfo?.background?.y ?? 0) +
                        10 +
                        (leftBarInfo?.background?.height ?? 0) -
                        index *
                            Math.round(
                                (leftBarInfo?.background?.height ?? 0) /
                                    this.getLevels()
                            );
                    let backgroundColor = "#AAAAAA";
                    if (currentLeftIndex > index) {
                        backgroundColor = this.props.leftBarColor;
                    }
                    return (
                        <div
                            key={index}
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                left: leftBarInfo?.x,
                                zIndex: 30,
                                top: position,
                                width: leftBarInfo?.width,
                                height: 5,
                                backgroundColor: backgroundColor,
                            }}
                            onClick={() => {
                                this.setLeftIndex(index + 1);
                            }}
                        />
                    );
                })}
                {rightBarInfo &&
                    levelsArray.map((index) => {
                        let position =
                            (rightBarInfo?.background?.y ?? 0) +
                            10 +
                            (rightBarInfo?.background?.height ?? 0) -
                            index *
                                Math.round(
                                    (rightBarInfo?.background?.height ?? 0) /
                                        this.getLevels()
                                );
                        let backgroundColor = "#AAAAAA";
                        if (
                            currentRightIndex != null &&
                            currentRightIndex > index &&
                            this.props.rightBarColor != null
                        ) {
                            backgroundColor = this.props.rightBarColor;
                        }
                        return (
                            <div
                                key={index}
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    left: rightBarInfo?.x,
                                    zIndex: 30,
                                    top: position,
                                    width: rightBarInfo?.width,
                                    height: 5,
                                    backgroundColor: backgroundColor,
                                }}
                            />
                        );
                    })}
            </div>
        );
    }

    private selectVariable(variable: string | null, scroll: boolean): void {
        if (!variable) {
            this.setState({ currentVariable: null });
            return;
        }

        let leftBarInfo:
            | ({ name?: string; background?: BarData } & BarData)
            | undefined = this.leftBarRef?.props?.data?.find(
            (item: { name?: string; background?: BarData } & BarData) =>
                item.name === variable
        );
        this.setState(
            {
                currentVariable: variable,
            },
            () => {
                if (scroll && this.props.scrollableRef?.scrollLeft != null) {
                    this.props.scrollableRef.scrollLeft =
                        (leftBarInfo?.x ?? 100) - 100;
                }
                this.barOverlayRef.current?.focus({
                    preventScroll: true,
                });
            }
        );
    }

    public render(): JSX.Element {
        var barWidth: number =
            this.props.data.length > 0 ? 150 * this.props.data.length : 800;
        //var barHeight: number = 130;
        var axisFontSize: number = this.props.ticksSize ?? 10;
        var titleSize: number = 12;

        return (
            <div style={{ height: "100%", width: "100%" }}>
                {this.props.title && (
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                        }}
                    >
                        <span
                            style={{
                                height: "17px",
                                fontFamily: "Arial",
                                fontSize: titleSize,
                                color: mainStyle.getPropertyValue(
                                    "--secondary-text-color"
                                ),
                                display: "block",
                                textAlign: "center",
                            }}
                        >
                            {this.props.title}
                        </span>
                    </div>
                )}

                <ResponsiveContainer
                    width={barWidth}
                    height={mobileBreakpoint() ? 265 : undefined}
                >
                    <BarChart
                        ref="barChart"
                        barSize={this.props.barSize ?? 10}
                        barCategoryGap="30%"
                        data={this.props.data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 25,
                        }}
                    >
                        {this.props.watermark != null && (
                            <text
                                x="50%"
                                y="30%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--content-secondary-text-color"
                                )}
                                fontSize={
                                    String(
                                        Object.keys(this.props.data).length - 1
                                    ) + "rem"
                                }
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {this.props.watermark}
                            </text>
                        )}
                        <CartesianGrid
                            stroke={
                                this.props.axesLinesColor ??
                                mainStyle.getPropertyValue(
                                    "--graphs-stroke-color"
                                )
                            }
                            vertical={false}
                            strokeWidth={1}
                        />
                        <XAxis
                            axisLine={false}
                            tick={
                                <CustomizedAxisTick
                                    axesColor={this.props.ticksColor}
                                    formatValues={true}
                                    truncValues={false}
                                    fontSize={axisFontSize}
                                    dx={this.props.rightBarKey ? 16 : 0}
                                    dy={16}
                                    angle={-15}
                                />
                            }
                            height={80}
                            dataKey="name"
                            stroke={
                                this.props.axesLinesColor ??
                                mainStyle.getPropertyValue(
                                    "--graphs-stroke-color"
                                )
                            }
                        />
                        <YAxis
                            type="number"
                            domain={[
                                0,
                                (dataMax: number) =>
                                    dataMax <= 0 ? 10 : dataMax,
                            ]}
                            axisLine={false}
                            tickLine={false}
                            tick={
                                <CustomizedAxisTick
                                    axesColor={this.props.ticksColor}
                                    formatValues={true}
                                    truncValues={false}
                                    fontSize={axisFontSize}
                                    dx={0}
                                    dy={0}
                                />
                            }
                        />

                        <Tooltip
                            formatter={(value: any, _name: any, props: any) => {
                                if (
                                    typeof value === "string" ||
                                    typeof value === "number"
                                ) {
                                    let formattedValue = formatValue(
                                        value,
                                        false
                                    );
                                    return formattedValue[0].concat(
                                        formattedValue[1]
                                    );
                                }
                            }}
                            cursor={false}
                            {...TooltipStyles()}
                        />
                        <Bar
                            ref={(ref: any) => {
                                this.leftBarRef = ref;
                            }}
                            isAnimationActive={false}
                            dataKey={this.props.leftBarKey}
                            fill={this.props.leftBarColor}
                            onClick={(evt) => {
                                this.props.selectVariable(evt.name, false);
                            }}
                        />
                        {this.props.rightBarKey && (
                            <Bar
                                ref={(ref: any) => {
                                    this.rightBarRef = ref;
                                }}
                                isAnimationActive={false}
                                dataKey={this.props.rightBarKey}
                                fill={this.props.rightBarColor}
                                onClick={(evt) => {
                                    this.props.selectVariable(evt.name, false);
                                }}
                            />
                        )}
                    </BarChart>
                </ResponsiveContainer>
                {this.buildOverlay()}
            </div>
        );
    }
}

export default CustomBarChartWithOverlay;
