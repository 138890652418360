import React, { Component } from "react";
import Select, { createFilter } from "react-select";
import { Button, AlertProps } from "react-bootstrap";
import { observer } from "mobx-react";
import "common/styles/App.css";
import { getCustomSelectStyleLight } from "common/SelectStyles";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Variables from "common/Variables";
import {
    NumberFormat,
    ColumnFormat,
    SpreadSheetColumnType,
    NumberFormatType,
} from "common/Canvas";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import Dropzone from "react-dropzone";
import {
    Panel,
    Type,
    InputMode,
    InputBaseState,
    defaultInputBaseState,
    realTimeLiveStreamSlugs,
    liveStreamUpdateModeOptions,
    liveStreamIntervalOptions,
    liveStreamIntervalOptionsRealTime,
    liveStreamWeekDayOptions,
    LiveStreamWeekDayOption,
    LiveStreamUpdateModeOption,
    LiveStreamIntervalOption,
    parseCsvFile,
    LoadFileStatus,
    checkLiveStreamCsv,
    checkLiveStreamEndpoint,
    checkLiveStreamMySql,
    checkLiveStreamOracleDB,
    timeToUTC,
    uploadOptions,
    UploadType,
    replaceSchema,
    uploadData,
    UploadFormatError,
    cancelUploadData,
    SchemaOptions,
    defaultSchemaOptions,
    applyColumnFormatsToSchemaOptions,
    uploadFileToBackend,
    maxUploadFileSize,
    maxChunkSize,
    getLiveStreamSheets,
    Sheet,
    liveStreamUpdateModeOptionsWithoutAppend,
} from "common/InputData";
import TimezoneSelect from "react-timezone-select";
import StringOption from "common/StringOption";
import encodings from "common/encodings";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import { Element } from "react-scroll";
import { Permission } from "common/Permissions";
import Switch from "react-switch";
import FormatDropdown from "modules/canvas_page/FormatDropdown";
import Alert from "common/Alert";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import LinearProgress from "@mui/material/LinearProgress";
import { lightThemeStyle } from "common/LightThemeStyle";
// For API's
import { APIUpdateEndpoint } from "modules/canvas_page/api_page/APIInterface";
import CanvasIconsContainer from "modules/canvas_page/CanvasIconsContainer";
import MessagePopup from "common/MessagePopup";
import remoteModuleId from "common/remoteModuleId";
import { SocketIOInstance } from "common/ServerConnection";
import axios from "common/ServerConnection";
import { sendErrorReport } from "common/ErrorReportingApi";
import { spreadSheetColumnOptions } from "common/Canvas";
import styles from "./ImportDataPage.module.css";
import cx from "classnames";
import { ReactComponent as ImportIcon } from "icons/data_hub/import.svg";
import MarketplaceComponent from "modules/marketplace_page/MarketplaceComponent";
// import SelectedOption from "modules/data_hub_page/SelectedOption";
// import TabSwitchConfirmationPopup from "common/TabSwitchConfirmationPopup";

interface Message {
    text: string;
    show: boolean;
    setShow: (show: boolean) => void;
    variant?: AlertProps["variant"];
}
////

interface Option {
    label: string;
    value: InputMode;
}

interface Props {
    dataHubMode?: boolean;
    canvasTreeStore?: CanvasTreeStore;
    currentModuleId?: number;
    dockSize?: number;
    spreadsheetId?: string;
    onReturnToCanvas?: (options?: Record<string, any>) => void;
    onSuccess?: (option: any, id: number | string) => void;
}

interface State extends InputBaseState {
    schemaOptions: SchemaOptions;
    columnFormats: ColumnFormat[];
    columnsEnabled: boolean[];
    inputLabel: string;
    advancedOptionsExpanded: boolean;
    uploadMessageError: string | null;
    uploadStatus: UploadStatus;
    uploadFormatError: UploadFormatError | null;
    uploadFormatErrorForce: {
        forceDateToNans: boolean;
        forceNumbersToNull: boolean;
    } | null;
    endpoints: APIUpdateEndpoint[];
    selected: APIUpdateEndpoint[];
    alerts: Message[];
    sheets: Sheet[];
    errorAPI?: string;
    selectedEndpointOption?: APIUpdateEndpointOption;
    dataScopeText?: string;
    showDataMarketplace: boolean;
    streamStatus: number | null;
    streamMessage: string | null;
}

const messageForUploadFormatError: Readonly<
    { [key in UploadFormatError]: string }
> = {
    date:
        "You have rows that have date values that are not the right format.  Do you want to change these values to NA?",
    number:
        "You have rows that have non-numeric values in numeric columns.  Do you want to change these values to NA?",
};

const options: ReadonlyArray<Option> = [
    { label: "CSV", value: InputMode.LocalCsv },
    { label: "Dropbox", value: InputMode.LiveStreamCsv },
    { label: "Google Drive", value: InputMode.LiveStreamCsv },
    { label: "Google Sheets", value: InputMode.LiveStreamCsv },
    { label: "MySQL", value: InputMode.LiveStreamMySQL },
    { label: "OracleDB", value: InputMode.LiveStreamOracleDB },
    { label: "API", value: InputMode.LiveStreamAPI }, // For COVID APi's for a new section
];

enum UploadStatus {
    NotUploaded = 1,
    ReplacingSchema = 2,
    UploadingFile = 3,
    UploadingData = 4,
    Success = 5,
    Error = 6,
}

interface APIUpdateEndpointOption extends APIUpdateEndpoint {
    label: string;
    value: string;
}

function defaultState(): State {
    return {
        ...defaultInputBaseState(),
        inputMode: InputMode.LocalCsv,
        inputLabel: options[0].label,
        schemaOptions: defaultSchemaOptions(),
        advancedOptionsExpanded: false,
        columnsEnabled: [],
        columnFormats: [],
        uploadMessageError: null,
        uploadStatus: UploadStatus.NotUploaded,
        endpoints: [],
        selected: [],
        alerts: [],
        uploadFormatError: null,
        uploadFormatErrorForce: null,
        sheets: [],
        showDataMarketplace: false,
        streamStatus: null,
        streamMessage: null,
    };
}

@observer
class MainComponent extends Component<Props, State> {
    private uploadProcessInfo:
        | {
              roomId: string;
              name2ColumnFormat: { [name: string]: ColumnFormat };
              dataTableIdx: string | number;
          }
        | undefined = undefined;

    private abortController: AbortController | undefined = undefined;

    constructor(props: Props) {
        super(props);
        this.state = defaultState();

        this.createMessage = this.createMessage.bind(this);
        this.renderContents = this.renderContents.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);

        this.uploadProcess = this.uploadProcess.bind(this);
        this.cancelUploadProcess = this.cancelUploadProcess.bind(this);
        this.uploadData = this.uploadData.bind(this);
        this.subscribeHandler = this.subscribeHandler.bind(this);
        this.loadMarketplaceToSpreadsheet = this.loadMarketplaceToSpreadsheet.bind(
            this
        );
    }
    // For API so I can call list_apis
    componentDidMount() {
        // Get the list of COVID APIs that can be updated
        axios
            .get<{
                success: boolean;
                error_msg: string;
                apis: APIUpdateEndpoint[];
            }>("/api/list_apis")
            .then((response) => {
                if (!response.data.success) {
                    this.setState({
                        errorAPI: response.data.error_msg,
                    });
                } else {
                    this.setState({
                        endpoints: response.data.apis,
                    });
                }
            });
        this.subscribeToDatasetUpdates();
    }
    componentWillUnmount() {
        SocketIOInstance?.off(
            "dataset_uploading_changes",
            this.subscribeHandler
        );
        if (this.uploadProcessInfo != null) {
            this.leaveChangesRoom(this.uploadProcessInfo.roomId);
        }
    }

    // For API messaging: L&V, how do I modify this so I can use the Alerts you've already created?
    setMessageShow(i: number, show: boolean) {
        let newAlerts = [...this.state.alerts];
        let alert = { ...newAlerts[i] };
        alert.show = show;
        newAlerts[i] = alert;
        this.setState({ alerts: newAlerts });
    }

    createMessage(
        text: string,
        variant?: AlertProps["variant"],
        show: boolean = true
    ) {
        const id = this.state.alerts.length;
        let newAlerts: Message[] = [...this.state.alerts];
        const newAlert: Message = {
            text: text,
            variant: variant,
            show: show,
            setShow: this.setMessageShow.bind(this, id),
        };

        newAlerts.push(newAlert);

        this.setState({
            alerts: newAlerts,
        });
    }
    //

    private handleFileUpload(file: File): void {
        this.setState(
            {
                loadFileStatus: LoadFileStatus.Loading,
                liveStreamConfigValid: false,
            },
            () => {
                this.prepareCsvFile(file);
            }
        );
    }
    private handleLiveStreamCsvLink(loadSheets: boolean): void {
        let inputData = Object.assign({}, this.state);

        let { weekday, timeString } = timeToUTC(
            inputData.liveStreamWeekDay.value,
            inputData.liveStreamTime,
            inputData.liveStreamTimezone.value
        );
        inputData = {
            ...inputData,
            loadFileStatus: LoadFileStatus.Loading,
            liveStreamConfigValid: false,
            liveStreamSelectedUpdateMode: inputData.liveStreamUpdateMode,
            liveStreamSelectedInterval: inputData.liveStreamInterval,
            liveStreamSelectedWeekDay:
                liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
                liveStreamWeekDayOptions[0],
            liveStreamSelectedTime: timeString,
            liveStreamSelectedCsvLink: inputData.liveStreamCsvLink,
        };
        this.setState(inputData, () => {
            checkLiveStreamCsv(
                inputData,
                (preview) => {
                    this.setState({ error: null });
                    this.handleFileUpload(preview);
                    if (loadSheets)
                        getLiveStreamSheets(inputData.liveStreamCsvLink)
                            .then((sheets) => {
                                this.setState((state) => {
                                    if (
                                        state.liveStreamCsvLink ===
                                        inputData.liveStreamCsvLink
                                    )
                                        return { sheets: sheets };
                                    return null;
                                });
                            })
                            .catch((error) => {
                                console.log(String(error));
                            });
                },
                (error) => {
                    this.setState({
                        error: error,
                        loadFileStatus: LoadFileStatus.NotUploaded,
                    });
                }
            );
        });
    }

    private handleEndpoint(): void {
        let inputData = Object.assign({}, this.state);

        let { weekday, timeString } = timeToUTC(
            inputData.liveStreamWeekDay.value,
            inputData.liveStreamTime,
            inputData.liveStreamTimezone.value
        );

        inputData = {
            ...inputData,
            loadFileStatus: LoadFileStatus.Loading,
            liveStreamConfigValid: false,
            liveStreamSelectedUpdateMode: inputData.liveStreamUpdateMode,
            liveStreamSelectedInterval: inputData.liveStreamInterval,
            liveStreamSelectedWeekDay:
                liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
                liveStreamWeekDayOptions[0],
            liveStreamSelectedTime: timeString,
            liveStreamSelectedEndpointSlug: inputData.liveStreamEndpointSlug,
        };

        let isRealTimeSlug =
            realTimeLiveStreamSlugs.indexOf(
                inputData.liveStreamEndpointSlug
            ) !== -1;
        if (isRealTimeSlug) {
            inputData = {
                ...inputData,
                liveStreamUpdateMode: liveStreamUpdateModeOptions[2],
                liveStreamInterval: liveStreamIntervalOptionsRealTime[0],
            };
        }

        this.setState(inputData, () => {
            checkLiveStreamEndpoint(
                inputData,
                (preview) => {
                    this.setState({ error: null });
                    this.handleFileUpload(preview);
                },
                (error) => {
                    this.setState({
                        error: error,
                        loadFileStatus: LoadFileStatus.NotUploaded,
                    });
                }
            );
        });

        if (this.state.error == null && isRealTimeSlug) {
            this.setState({ liveStreamConfigValid: true });
        }
    }

    private handleLiveStreamMySQLLink(): void {
        let inputData = Object.assign({}, this.state);

        let { weekday, timeString } = timeToUTC(
            inputData.liveStreamWeekDay.value,
            inputData.liveStreamTime,
            inputData.liveStreamTimezone.value
        );
        inputData = {
            ...inputData,
            loadFileStatus: LoadFileStatus.Loading,
            liveStreamConfigValid: false,
            liveStreamSelectedUpdateMode: inputData.liveStreamUpdateMode,
            liveStreamSelectedInterval: inputData.liveStreamInterval,
            liveStreamSelectedWeekDay:
                liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
                liveStreamWeekDayOptions[0],
            liveStreamSelectedTime: timeString,
            liveStreamSelectedMySQLHost: inputData.liveStreamMySQLHost,
            liveStreamSelectedMySQLPort: inputData.liveStreamMySQLPort,
            liveStreamSelectedMySQLDatabase: inputData.liveStreamMySQLDatabase,
            liveStreamSelectedMySQLTable: inputData.liveStreamMySQLTable,
            liveStreamSelectedMySQLLogin: inputData.liveStreamMySQLLogin,
            liveStreamSelectedMySQLPassword: inputData.liveStreamMySQLPassword,
        };
        this.setState(inputData, () => {
            checkLiveStreamMySql(
                inputData,
                (preview) => {
                    this.setState({ error: null });
                    this.handleFileUpload(preview);
                },
                (error) => {
                    this.setState({
                        error: error,
                        loadFileStatus: LoadFileStatus.NotUploaded,
                    });
                }
            );
        });
    }

    private handleLiveStreamOracleDBLink(): void {
        let inputData = Object.assign({}, this.state);

        let { weekday, timeString } = timeToUTC(
            inputData.liveStreamWeekDay.value,
            inputData.liveStreamTime,
            inputData.liveStreamTimezone.value
        );
        inputData = {
            ...inputData,
            loadFileStatus: LoadFileStatus.Loading,
            liveStreamConfigValid: false,
            liveStreamSelectedUpdateMode: inputData.liveStreamUpdateMode,
            liveStreamSelectedInterval: inputData.liveStreamInterval,
            liveStreamSelectedWeekDay:
                liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
                liveStreamWeekDayOptions[0],
            liveStreamSelectedTime: timeString,
            liveStreamSelectedOracleDBHost: inputData.liveStreamOracleDBHost,
            liveStreamSelectedOracleDBPort: inputData.liveStreamOracleDBPort,
            liveStreamSelectedOracleDBServiceName:
                inputData.liveStreamOracleDBServiceName,
            liveStreamSelectedOracleDBSchema:
                inputData.liveStreamOracleDBSchema,
            liveStreamSelectedOracleDBTable: inputData.liveStreamOracleDBTable,
            liveStreamSelectedOracleDBLogin: inputData.liveStreamOracleDBLogin,
            liveStreamSelectedOracleDBPassword:
                inputData.liveStreamOracleDBPassword,
        };
        this.setState(inputData, () => {
            checkLiveStreamOracleDB(
                inputData,
                (preview) => {
                    this.setState({ error: null });
                    this.handleFileUpload(preview);
                },
                (error) => {
                    this.setState({
                        error: error,
                        loadFileStatus: LoadFileStatus.NotUploaded,
                    });
                }
            );
        });
    }

    private prepareCsvFile(file: File | string, encoding?: StringOption): void {
        if (file == null) return;
        parseCsvFile(
            file,
            (csvHeader, csvData, _csvTypes, encoding) => {
                if (csvHeader.length !== _csvTypes.length) {
                    csvHeader = [];
                    _csvTypes = [];
                    csvData = [];
                }
                let columnCount = csvHeader.length;
                let schemaOptions: SchemaOptions = {
                    names: csvHeader,
                    types: _csvTypes as Type[],
                    units: new Array(columnCount).fill(""),
                    panels: new Array(columnCount).fill(Panel.Regular),
                    levels: new Array(columnCount).fill(null),
                    formats: new Array(columnCount).fill(null),
                    namesToIndices: {},
                };
                let columnsEnabled = new Array(columnCount).fill(true);
                let columnFormats: ColumnFormat[] = [];
                for (let type of schemaOptions.types) {
                    if (type === Type.Float || type === Type.Int) {
                        let format: NumberFormat = {
                            type: SpreadSheetColumnType.Number,
                            numberType: NumberFormatType.Number,
                            useCommaSeparator: false,
                            useAbbreviation: false,
                            decimalPoints: type === Type.Float ? 2 : 0,
                        };
                        columnFormats.push(format);
                    } else {
                        let format: ColumnFormat = {
                            type: SpreadSheetColumnType.Text,
                        };
                        columnFormats.push(format);
                    }
                }
                csvHeader.forEach((name, index) => {
                    schemaOptions.namesToIndices[name] = index;
                });
                this.setState({
                    selectedFile: file,
                    csvData: csvData,
                    csvHeader: csvHeader,
                    encoding: encoding,
                    loadFileStatus: LoadFileStatus.Success,
                    liveStreamConfigValid: true,
                    schemaOptions: schemaOptions,
                    columnsEnabled: columnsEnabled,
                    columnFormats: columnFormats,
                    error: null,
                });
            },
            async (errors) => {
                this.setState({
                    selectedFile: null,
                    loadFileStatus: LoadFileStatus.NotUploaded,
                    error:
                        errors[0]?.code === "Custom"
                            ? errors[0].message
                            : "Failed to parse data",
                });
                await sendErrorReport(
                    JSON.stringify({
                        name: "Parse csv error",
                        errors: errors,
                    })
                );
            },
            100,
            encoding
        );
    }
    private joinChangesRoom(roomId: string) {
        SocketIOInstance?.emit("dataset_uploading_changes_join", {
            room: roomId,
        });
    }

    private leaveChangesRoom(roomId: string) {
        SocketIOInstance?.emit("dataset_uploading_changes_leave", {
            room: roomId,
        });
    }

    private loadMarketplaceToSpreadsheet(dataTableIdx: string | number) {
        if (this.props.canvasTreeStore != null) {
            let spreadsheetId = this.props.spreadsheetId;
            if (this.props.spreadsheetId == null) {
                spreadsheetId = this.props.canvasTreeStore.addSpreadSheetGridAction(
                    3,
                    3,
                    false,
                    true,
                    true,
                    {}
                );
            }
            if (spreadsheetId != null) {
                this.props.canvasTreeStore
                    .readDataIntoSpreadSheet(
                        spreadsheetId!,
                        dataTableIdx,
                        null,
                        10,
                        true, // Set to true as we pull data when click on stream button
                        false,
                        [],
                        undefined,
                        false
                    )
                    .then(() => {
                        this.props.onReturnToCanvas?.();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.props.onReturnToCanvas?.();
            }
        } else {
            this.props.onReturnToCanvas?.();
        }
    }
    private onSuccess() {
        this.setState(
            {
                uploadStatus: UploadStatus.Success,
            },
            () => {
                let dataTableIdx = this.uploadProcessInfo!.dataTableIdx;
                let name2ColumnFormat = this.uploadProcessInfo!
                    .name2ColumnFormat;
                this.leaveChangesRoom(this.uploadProcessInfo!.roomId);
                this.uploadProcessInfo = undefined;
                if (
                    this.props.spreadsheetId != null &&
                    this.props.canvasTreeStore != null
                ) {
                    this.props.canvasTreeStore
                        .readDataIntoSpreadSheet(
                            this.props.spreadsheetId,
                            dataTableIdx,
                            null,
                            10,
                            true, // Set to true as we pull data when click on stream button
                            false,
                            [],
                            undefined,
                            false,
                            name2ColumnFormat
                        )
                        .then(() => {
                            this.props.onReturnToCanvas?.();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.props.onReturnToCanvas?.();
                }
                if (this.props.onSuccess) {
                    setTimeout(() => {
                        this.props.onSuccess?.(1, dataTableIdx);
                    }, 1000);
                }
            }
        );
    }

    subscribeHandler = (response: {
        data: {
            success: boolean;
            error_msg: string;
            data: {
                format_error?: UploadFormatError;
            };
        };
    }) => {
        if (response.data.success) {
            this.onSuccess();
        } else {
            this.onError(
                response.data.error_msg,
                response.data.data.format_error
            );
        }
    };

    subscribeToDatasetUpdates() {
        SocketIOInstance?.on(
            "dataset_uploading_changes",
            this.subscribeHandler
        );
    }

    private onError(error: string, formatError?: UploadFormatError) {
        this.leaveChangesRoom(this.uploadProcessInfo!.roomId);
        this.uploadProcessInfo = undefined;
        this.setState({
            uploadFormatError: formatError ?? null,
            uploadMessageError: error,
            uploadStatus: UploadStatus.Error,
        });
    }

    private async uploadFile(
        dataTableIdx: string | number,
        file: File
    ): Promise<string | undefined> {
        let chunkSize = maxChunkSize;
        let offset = 0;
        let uploadSize = 0;
        let fileId: string | undefined = undefined;
        while (offset <= file.size) {
            let blob = file.slice(offset, offset + chunkSize);
            offset += chunkSize;
            let result: {
                fileId: string;
                uploadSize: number;
            } = await uploadFileToBackend(dataTableIdx, blob, fileId);
            fileId = result.fileId;
            uploadSize = result.uploadSize;
        }
        if (uploadSize !== file.size) {
            throw new Error("Upload file error");
        }
        return fileId;
    }
    private uploadDataInner(
        dataTableIdx: string | number,
        forceDateToNans?: boolean,
        forceNumbersToNull?: boolean
    ) {
        let name2ColumnFormat: { [name: string]: ColumnFormat } = {};
        for (let index in this.state.schemaOptions.names) {
            name2ColumnFormat[
                this.state.schemaOptions.names[index]
            ] = this.state.columnFormats[index];
        }
        this.setState({
            uploadStatus: UploadStatus.UploadingData,
            uploadFormatErrorForce: {
                forceDateToNans: forceDateToNans ?? false,
                forceNumbersToNull: forceNumbersToNull ?? false,
            },
        });
        uploadData(
            dataTableIdx,
            this.state,
            (roomId) => {
                this.uploadProcessInfo = {
                    roomId: roomId,
                    dataTableIdx: dataTableIdx,
                    name2ColumnFormat: name2ColumnFormat,
                };
                this.joinChangesRoom(roomId);
            },
            (error, formatError) => {
                this.setState({
                    uploadFormatError: formatError ?? null,
                    uploadFormatErrorForce: {
                        forceDateToNans: forceDateToNans ?? false,
                        forceNumbersToNull: forceNumbersToNull ?? false,
                    },
                    uploadMessageError: error,
                    uploadStatus: UploadStatus.Error,
                });
            },
            this.state.columnsEnabled,
            forceDateToNans,
            forceNumbersToNull
        );
    }

    private async uploadData(
        forceDateToNans?: boolean,
        forceNumbersToNull?: boolean
    ) {
        let dataTableIdx = this.state.dataScope!.value;

        if (
            this.state.inputMode === InputMode.LocalCsv &&
            //            this.state.uploadFileId == null &&
            this.state.selectedFile != null &&
            (this.state.selectedFile as File).size > maxUploadFileSize
        ) {
            this.setState({
                uploadStatus: UploadStatus.UploadingFile,
            });
            try {
                let uploadFileId = await this.uploadFile(
                    dataTableIdx,
                    this.state.selectedFile as File
                );
                this.setState({ uploadFileId: uploadFileId }, () => {
                    this.uploadDataInner(
                        dataTableIdx,
                        forceDateToNans,
                        forceNumbersToNull
                    );
                });
            } catch (error) {
                if (error.name !== "AbortError") {
                    this.setState({
                        uploadFormatError: null,
                        uploadMessageError: String(error),
                        uploadStatus: UploadStatus.Error,
                    });
                }
                return;
            }
        } else {
            this.uploadDataInner(
                dataTableIdx,
                forceDateToNans,
                forceNumbersToNull
            );
        }
    }

    private uploadProcess(): void {
        let schemaOptions = applyColumnFormatsToSchemaOptions(
            this.state.columnFormats,
            this.state.schemaOptions
        );

        if (this.state.uploadOption.value === UploadType.Replace) {
            this.abortController = new AbortController();
            this.setState({ uploadStatus: UploadStatus.ReplacingSchema });
            replaceSchema(
                schemaOptions.names,
                schemaOptions.types,
                schemaOptions.units,
                schemaOptions.panels,
                schemaOptions.formats,
                schemaOptions.levels,
                this.state.columnsEnabled,
                this.state.dataScope!,
                false,
                undefined,
                (dataTableIdx) => {
                    DataScopes.update();
                    Variables(
                        dataTableIdx,
                        this.props.currentModuleId ?? remoteModuleId
                    ).update(this.props.currentModuleId ?? remoteModuleId);
                    this.setState(
                        (state) => ({
                            dataScope: {
                                ...state.dataScope!,
                                value: dataTableIdx,
                            },
                        }),
                        () => {
                            this.uploadData();
                        }
                    );
                },
                (error) => {
                    this.setState({
                        uploadMessageError: error,
                        uploadStatus: UploadStatus.Error,
                    });
                },
                this.abortController,
                this.props.currentModuleId ?? remoteModuleId
            );
        }
    }

    private cancelUploadProcess(): void {
        const popupData = {
            streamStatus: PopupStatus.Error,
            streamMessage: "Upload cancelled",
        };
        let uploadProcessInfo = this.uploadProcessInfo;
        this.uploadProcessInfo = undefined;
        this.abortController?.abort();
        this.abortController = undefined;
        cancelUploadData();
        if (uploadProcessInfo != null) {
            this.leaveChangesRoom(uploadProcessInfo.roomId);
        }
        this.props.onReturnToCanvas != null
            ? this.props.onReturnToCanvas(popupData)
            : this.setState(defaultState());
        DataScopes.update();

        this.setState(popupData);
    }

    private getDataScopes() {
        return this.props.currentModuleId == null
            ? DataScopes
            : DataScopesForModules(this.props.currentModuleId);
    }

    private getDataTypeLabel(type: any) {
        if (!type) {
            return null;
        }
        return spreadSheetColumnOptions.find((item) => item.value === type)!
            .label;
    }

    private renderContents(globalContext: GlobalContextContents): JSX.Element {
        let isRealTimeSlug =
            realTimeLiveStreamSlugs.indexOf(
                this.state.liveStreamEndpointSlug
            ) !== -1;
        if (this.state.showDataMarketplace)
            return (
                <div style={{ height: this.props.dockSize ?? "100%" }}>
                    <MarketplaceComponent
                        canvasTreeStore={this.props.canvasTreeStore}
                        onLoad={this.loadMarketplaceToSpreadsheet}
                        onClose={() => {
                            this.setState({ showDataMarketplace: false });
                        }}
                    ></MarketplaceComponent>
                </div>
            );
        const dataScopes = this.getDataScopes();
        let selectStyles = getCustomSelectStyleLight(14, false);
        let smallSelectStyles = getCustomSelectStyleLight(10, true);
        let dataIsUploading =
            this.state.uploadStatus === UploadStatus.ReplacingSchema ||
            this.state.uploadStatus === UploadStatus.UploadingFile ||
            this.state.uploadStatus === UploadStatus.UploadingData;

        let dataSetIsNotSelected = this.state.dataScope == null;

        let { weekday, timeString } = timeToUTC(
            this.state.liveStreamWeekDay.value,
            this.state.liveStreamTime,
            this.state.liveStreamTimezone.value
        );
        let APIComponent;
        try {
            if (this.state.selectedEndpointOption != null) {
                let mod = require(`modules/canvas_page/api_page/api_components/${this.state.selectedEndpointOption.slug}.tsx`);
                APIComponent = mod.MainComponent;
            }
        } catch (exception) {}

        // Create API page component
        // const apiComponents: JSX.Element[] = this.state.endpoints.map(
        //     (endpoint, index) => {
        //         const eProps: APIButtonProps = {
        //             ...endpoint,
        //             createMessage: this.createMessage,
        //             key: index,
        //         };
        //         return React.createElement(
        //             APIConversion[endpoint.slug],
        //             eProps
        //         );
        //     }
        // );

        //// End API

        let weekdayOption =
            liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
            liveStreamWeekDayOptions[0];

        let liveStreamConfigChanged: boolean = false;
        // Is there a change in the liveStream Configuration options?
        // This option is triggered for "Dropbox, Google Drive, Google Sheets, MySQL"
        if (this.state.inputMode !== InputMode.LocalCsv) {
            liveStreamConfigChanged =
                this.state.liveStreamUpdateMode.value !==
                    this.state.liveStreamSelectedUpdateMode?.value ||
                this.state.liveStreamInterval.value !==
                    this.state.liveStreamSelectedInterval?.value ||
                weekdayOption.value !==
                    this.state.liveStreamSelectedWeekDay?.value ||
                timeString !== this.state.liveStreamSelectedTime;
            if (this.state.inputMode === InputMode.LiveStreamCsv) {
                liveStreamConfigChanged =
                    liveStreamConfigChanged ||
                    this.state.liveStreamCsvLink !==
                        this.state.liveStreamSelectedCsvLink;
            } else if (this.state.inputMode === InputMode.LiveStreamMySQL) {
                liveStreamConfigChanged =
                    liveStreamConfigChanged ||
                    this.state.liveStreamMySQLHost !==
                        this.state.liveStreamSelectedMySQLHost ||
                    this.state.liveStreamMySQLPort !==
                        this.state.liveStreamSelectedMySQLPort ||
                    this.state.liveStreamMySQLDatabase !==
                        this.state.liveStreamSelectedMySQLDatabase ||
                    this.state.liveStreamMySQLTable !==
                        this.state.liveStreamSelectedMySQLTable ||
                    this.state.liveStreamMySQLLogin !==
                        this.state.liveStreamSelectedMySQLLogin ||
                    this.state.liveStreamMySQLPassword !==
                        this.state.liveStreamSelectedMySQLPassword;
            } else if (this.state.inputMode === InputMode.LiveStreamOracleDB) {
                liveStreamConfigChanged =
                    liveStreamConfigChanged ||
                    this.state.liveStreamOracleDBHost !==
                        this.state.liveStreamSelectedOracleDBHost ||
                    this.state.liveStreamOracleDBPort !==
                        this.state.liveStreamSelectedOracleDBPort ||
                    this.state.liveStreamOracleDBServiceName !==
                        this.state.liveStreamSelectedOracleDBServiceName ||
                    this.state.liveStreamOracleDBSchema !==
                        this.state.liveStreamSelectedOracleDBSchema ||
                    this.state.liveStreamOracleDBTable !==
                        this.state.liveStreamSelectedOracleDBTable ||
                    this.state.liveStreamOracleDBLogin !==
                        this.state.liveStreamSelectedOracleDBLogin ||
                    this.state.liveStreamOracleDBPassword !==
                        this.state.liveStreamSelectedOracleDBPassword;
            } else if (this.state.inputMode === InputMode.LiveStreamAPI) {
                liveStreamConfigChanged =
                    liveStreamConfigChanged ||
                    this.state.liveStreamEndpointSlug !==
                        this.state.liveStreamSelectedEndpointSlug;
            }
        }
        let liveStreamConfigChangedOrNotValid =
            liveStreamConfigChanged || !this.state.liveStreamConfigValid;
        // This displays the Table, assuming it's possible load the file.
        let adminTableWithFullFeatures: JSX.Element | null = null;

        if (
            this.state.selectedFile &&
            this.state.loadFileStatus === LoadFileStatus.Success
        ) {
            adminTableWithFullFeatures = (
                <div
                    style={{
                        marginLeft: "17px",
                        marginRight: "17px",
                        display: "flex",
                        justifyContent: "center",
                        overflow: "hidden",
                        boxShadow: "0px 0px 12px -5px rgba(0,0,0,0.75)",
                        borderRadius: 8,
                    }}
                >
                    <Element
                        name="scrollable"
                        className="element"
                        style={{
                            padding: "10px",
                            overflow: "auto",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                            flex: 1,
                        }}
                    >
                        <AdminTableWithFullFeatures
                            headerRenderer={(item, index) => (
                                <div className="flex-simple-column" key={index}>
                                    <div
                                        className="my-row"
                                        style={{
                                            alignItems: "center",
                                            alignSelf: "flex-end",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#44556B",
                                                fontWeight: 500,
                                                fontSize: "8px",
                                            }}
                                        >
                                            {"include"}
                                        </span>
                                        <div style={{ marginLeft: 5 }}>
                                            <Switch
                                                onChange={(
                                                    checked: boolean
                                                ) => {
                                                    let columnsEnabled = [
                                                        ...this.state
                                                            .columnsEnabled,
                                                    ];
                                                    columnsEnabled[
                                                        index
                                                    ] = checked;
                                                    this.setState({
                                                        columnsEnabled: columnsEnabled,
                                                    });
                                                }}
                                                checked={
                                                    this.state.columnsEnabled[
                                                        index
                                                    ]
                                                }
                                                width={16}
                                                height={7}
                                                offColor="#20293C"
                                                onColor="#20293C"
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                offHandleColor="#70889E"
                                                onHandleColor="#1F8EFA"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="my-row"
                                        style={{
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: "Open Sans",
                                                fontWeight: 600,
                                                fontSize: "10px",
                                                color: "black",
                                            }}
                                        >
                                            {item}
                                            {this.getDataTypeLabel(
                                                this.state.columnFormats[index]!
                                                    .type
                                            ) && (
                                                <span
                                                    style={{
                                                        fontSize: "8px",
                                                        paddingLeft: "10px",
                                                    }}
                                                >
                                                    (
                                                    {this.getDataTypeLabel(
                                                        this.state
                                                            .columnFormats[
                                                            index
                                                        ]!.type
                                                    )}
                                                    )
                                                </span>
                                            )}
                                        </span>
                                        <FormatDropdown
                                            onChangeFormat={(format) => {
                                                let columnFormats = [
                                                    ...this.state.columnFormats,
                                                ];
                                                columnFormats[index] = format;
                                                this.setState({
                                                    columnFormats: columnFormats,
                                                });
                                            }}
                                            column={index}
                                            format={
                                                this.state.columnFormats[index]
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            className="import-table"
                            tableName="Data"
                            tableHeader={this.state.csvHeader}
                            tableContent={this.state.csvData}
                        />
                    </Element>
                </div>
            );
        }

        // This is checked when the file is selected and also for all LiveStream options.  Returns a "liveStreamConfig" object that is basically the tags that should be rendered.
        let liveStreamSheetsConfig: JSX.Element | null = null;

        if (
            this.state.selectedFile != null &&
            this.state.inputMode === InputMode.LiveStreamCsv &&
            this.state.sheets.length > 0
        ) {
            liveStreamSheetsConfig = (
                <div
                    className="my-row"
                    style={{
                        alignItems: "center",
                        marginBottom: "18px",
                    }}
                >
                    <span
                        style={{
                            // marginLeft: "6px",
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: lightThemeStyle.primaryTextColor,
                        }}
                    >
                        Select sheet:
                    </span>
                    <Select
                        inputId="select-character-encoding"
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            ...smallSelectStyles,
                            container: (base) => ({
                                ...base,
                                marginLeft: "5px",
                                width: "200px",
                            }),
                        }}
                        options={this.state.sheets}
                        onChange={(sheet) => {
                            this.setState(
                                { liveStreamSheetId: (sheet as Sheet).value },
                                () => {
                                    this.handleLiveStreamCsvLink(false);
                                }
                            );
                        }}
                        value={
                            this.state.liveStreamSheetId == null
                                ? this.state.sheets[0]
                                : this.state.sheets.find(
                                      (item) =>
                                          item.value ===
                                          this.state.liveStreamSheetId
                                  ) ?? null
                        }
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                </div>
            );
        }

        let liveStreamConfig: JSX.Element | null = null;
        if (
            this.state.selectedFile == null &&
            (this.state.inputMode === InputMode.LiveStreamCsv ||
                (this.state.inputMode === InputMode.LiveStreamAPI &&
                    this.state.selectedFile != null))
        ) {
            liveStreamConfig = (
                <div className="center-container">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "90%",
                            marginBottom: "15px",
                        }}
                    >
                        <span
                            className="content-regular-text"
                            style={{
                                color: lightThemeStyle.primaryTextColor,
                            }}
                        >
                            Link:
                        </span>
                        <input
                            className="like-select"
                            style={{
                                flex: 1,
                                backgroundColor:
                                    lightThemeStyle.backgroundColor,
                                color: lightThemeStyle.primaryTextColor,
                                marginLeft: "15px",
                            }}
                            value={this.state.liveStreamCsvLink}
                            onChange={(e) => {
                                const text = e.target.value;
                                this.setState({
                                    liveStreamCsvLink: text,
                                });
                            }}
                        />
                    </div>
                </div>
            );
        } else if (
            this.state.selectedFile == null &&
            this.state.inputMode === InputMode.LiveStreamMySQL
        ) {
            liveStreamConfig = (
                <div className="center-container">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            width: "90%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Host:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    color: lightThemeStyle.primaryTextColor,
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    marginLeft: "15px",
                                }}
                                value={this.state.liveStreamMySQLHost}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLHost: text,
                                    });
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Port:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                                value={this.state.liveStreamMySQLPort}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLPort: text,
                                    });
                                }}
                                onKeyDown={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Database:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                                value={this.state.liveStreamMySQLDatabase}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLDatabase: text,
                                    });
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Table:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                                value={this.state.liveStreamMySQLTable}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLTable: text,
                                    });
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Login:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                }}
                                value={this.state.liveStreamMySQLLogin}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLLogin: text,
                                    });
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Password:
                            </span>
                            <input
                                className="like-select"
                                type="password"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                                value={this.state.liveStreamMySQLPassword}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLPassword: text,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        } else if (
            this.state.selectedFile == null &&
            this.state.inputMode === InputMode.LiveStreamOracleDB
        ) {
            liveStreamConfig = (
                <div className="center-container">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            width: "90%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Host:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    color: lightThemeStyle.primaryTextColor,
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    marginLeft: "15px",
                                }}
                                value={this.state.liveStreamOracleDBHost}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamOracleDBHost: text,
                                    });
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Port:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                                value={this.state.liveStreamOracleDBPort}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamOracleDBPort: text,
                                    });
                                }}
                                onKeyDown={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Service or PDB name:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                                value={this.state.liveStreamOracleDBServiceName}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamOracleDBServiceName: text,
                                    });
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Schema (optional):
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                                value={this.state.liveStreamOracleDBSchema}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamOracleDBSchema: text,
                                    });
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Table:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                                value={this.state.liveStreamOracleDBTable}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamOracleDBTable: text,
                                    });
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Login:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                }}
                                value={this.state.liveStreamOracleDBLogin}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamOracleDBLogin: text,
                                    });
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "90%",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: 70,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Password:
                            </span>
                            <input
                                className="like-select"
                                type="password"
                                style={{
                                    width: 200,
                                    marginLeft: "15px",
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                                value={this.state.liveStreamOracleDBPassword}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamOracleDBPassword: text,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        // This is activate when we change "Update Mode" to anything that is not "none"
        let liveStreamIntervalConfig: JSX.Element | null = null;
        if (
            (this.state.inputMode === InputMode.LiveStreamAPI &&
                this.state.selectedFile != null) ||
            this.state.inputMode === InputMode.LiveStreamCsv ||
            this.state.inputMode === InputMode.LiveStreamMySQL ||
            this.state.inputMode === InputMode.LiveStreamOracleDB
        ) {
            liveStreamIntervalConfig = (
                <div
                    className="center-container"
                    style={{
                        overflow: "visible",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            width: "90%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                marginBottom: "15px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    minWidth: "max-content",
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Update mode:
                            </span>
                            <Select
                                isDisabled={isRealTimeSlug}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        height: "38px",
                                        width: 110,
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                    }),
                                }}
                                options={
                                    this.state.inputMode ===
                                        InputMode.LiveStreamAPI ||
                                    this.state.inputMode ===
                                        InputMode.LiveStreamOracleDB ||
                                    this.state.inputMode ===
                                        InputMode.LiveStreamCsv
                                        ? liveStreamUpdateModeOptionsWithoutAppend
                                        : liveStreamUpdateModeOptions
                                }
                                value={this.state.liveStreamUpdateMode}
                                onChange={(option) => {
                                    this.setState({
                                        liveStreamUpdateMode: option as LiveStreamUpdateModeOption,
                                        // this should NOT change upload option
                                    });
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <span
                                className="content-regular-text"
                                style={{
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                {this.state.liveStreamUpdateMode.description}
                            </span>
                        </div>
                        {this.state.liveStreamUpdateMode.value !== "none" && (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "start",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <span
                                        className="content-regular-text"
                                        style={{
                                            color:
                                                lightThemeStyle.primaryTextColor,
                                        }}
                                    >
                                        Every
                                    </span>
                                    <Select
                                        isDisabled={isRealTimeSlug}
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        styles={{
                                            ...selectStyles,
                                            container: (base) => ({
                                                ...base,
                                                height: "38px",
                                                width: 100,
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                            }),
                                        }}
                                        options={
                                            isRealTimeSlug
                                                ? liveStreamIntervalOptionsRealTime
                                                : liveStreamIntervalOptions
                                        }
                                        value={this.state.liveStreamInterval}
                                        onChange={(option) => {
                                            this.setState({
                                                liveStreamInterval: option as LiveStreamIntervalOption,
                                            });
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                    {this.state.liveStreamInterval.value ===
                                        "week" && (
                                        <>
                                            <span
                                                className="content-regular-text"
                                                style={{
                                                    color:
                                                        lightThemeStyle.primaryTextColor,
                                                }}
                                            >
                                                on
                                            </span>
                                            <Select
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={""}
                                                styles={{
                                                    ...selectStyles,
                                                    container: (base) => ({
                                                        ...base,
                                                        height: "38px",
                                                        width: 100,
                                                        marginLeft: "10px",
                                                        marginRight: "10px",
                                                    }),
                                                }}
                                                options={
                                                    liveStreamWeekDayOptions
                                                }
                                                value={
                                                    this.state.liveStreamWeekDay
                                                }
                                                onChange={(option) => {
                                                    this.setState({
                                                        liveStreamWeekDay: option as LiveStreamWeekDayOption,
                                                    });
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        </>
                                    )}
                                    {this.state.liveStreamInterval.value !==
                                        "live" && (
                                        <>
                                            <span
                                                className="content-regular-text"
                                                style={{
                                                    color:
                                                        lightThemeStyle.primaryTextColor,
                                                }}
                                            >
                                                at
                                            </span>
                                            <input
                                                className="like-select"
                                                value={
                                                    this.state.liveStreamTime
                                                }
                                                onChange={(e) => {
                                                    const value =
                                                        e.target.value;
                                                    this.setState({
                                                        liveStreamTime: value,
                                                    });
                                                }}
                                                style={{
                                                    backgroundColor: "#fff",
                                                    width: 100,
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                }}
                                            />
                                            <TimezoneSelect
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={""}
                                                styles={{
                                                    ...selectStyles,
                                                    container: (base) => ({
                                                        ...base,
                                                        height: "38px",
                                                        width: 500,
                                                        marginLeft: "10px",
                                                        marginRight: "10px",
                                                    }),
                                                }}
                                                value={
                                                    this.state
                                                        .liveStreamTimezone
                                                }
                                                onChange={(option) => {
                                                    this.setState({
                                                        liveStreamTimezone: option as StringOption,
                                                    });
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        </>
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "start",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <img
                                        src="/dist/img/warning.png"
                                        alt=""
                                        style={{
                                            marginRight: 5,
                                        }}
                                    />
                                    <span
                                        className="content-regular-text"
                                        style={{
                                            color:
                                                lightThemeStyle.primaryTextColor,
                                        }}
                                    >
                                        <b>WARNING:</b> Do not change the order
                                        of the columns in the source data, or
                                        updates will not work
                                    </span>
                                </div>
                            </>
                        )}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                marginBottom: "15px",
                            }}
                        >
                            <Button
                                type="button"
                                className="btn btn-lg btn-primary my-primary"
                                style={{
                                    width: 150,
                                    height: "38px",
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    backgroundColor: "#3b82c9",
                                }}
                                onClick={() => {
                                    if (
                                        this.state.inputMode ===
                                        InputMode.LiveStreamCsv
                                    ) {
                                        this.handleLiveStreamCsvLink(true);
                                    } else if (
                                        this.state.inputMode ===
                                        InputMode.LiveStreamMySQL
                                    ) {
                                        this.handleLiveStreamMySQLLink();
                                    } else if (
                                        this.state.inputMode ===
                                        InputMode.LiveStreamOracleDB
                                    ) {
                                        this.handleLiveStreamOracleDBLink();
                                    } else if (
                                        this.state.inputMode ===
                                        InputMode.LiveStreamAPI
                                    ) {
                                        this.handleEndpoint();
                                    }
                                }}
                            >
                                Select
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        // This handles the various options of uploadStatus, otherwise returns the success message.
        let uploadAlert: JSX.Element | null = null;

        if (this.state.error != null) {
            uploadAlert = (
                <div>
                    <Alert
                        text={"Error while parsing data: " + this.state.error}
                        className="alert alert-danger alert-dismissible"
                        onClosed={() =>
                            this.setState({
                                error: null,
                            })
                        }
                    />
                </div>
            );
        } else if (this.state.uploadStatus === UploadStatus.Success) {
            uploadAlert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text="Successully uploaded data"
                        className="alert alert-success alert-dismissible"
                        onClosed={() =>
                            this.setState({
                                uploadStatus: UploadStatus.NotUploaded,
                            })
                        }
                    />
                </div>
            );
        } else if (this.state.uploadStatus === UploadStatus.Error) {
            uploadAlert = (
                <div>
                    <Alert
                        text={
                            "Error while uploading data: " +
                            this.state.uploadMessageError
                        }
                        className="alert alert-danger alert-dismissible"
                        onClosed={() =>
                            this.setState({
                                uploadStatus: UploadStatus.NotUploaded,
                            })
                        }
                    />
                </div>
            );
        } else if (this.state.uploadStatus === UploadStatus.ReplacingSchema) {
            uploadAlert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text={"Replacing schema"}
                        className="alert alert-warning"
                    />
                </div>
            );
        } else if (this.state.uploadStatus === UploadStatus.UploadingData) {
            uploadAlert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text={"Uploading data"}
                        className="alert alert-warning"
                    />
                </div>
            );
        } else if (this.state.uploadStatus === UploadStatus.UploadingFile) {
            uploadAlert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text={"Uploading file"}
                        className="alert alert-warning alert-dismissible"
                        onClosed={this.cancelUploadProcess}
                    />
                </div>
            );
        }
        let filterOptions = options.filter(
            (option) =>
                option.value !== InputMode.LiveStreamAPI ||
                "APIs" in globalContext.permissions
        );
        return (
            <div
                className="hide-scroll"
                style={{
                    // backgroundColor: this.props.dataHubMode
                    //     ? "transparent"
                    //     : lightThemeStyle.backgroundColor,
                    backgroundColor: "#fff",
                    height: this.props.dockSize ?? "100%",
                    ...(this.props.dataHubMode && {
                        paddingTop: "15px",
                        margin: "15px 15px 0px -5px",
                        paddingBottom: "41px",
                        borderRadius: 10,
                        paddingInline: 25,
                    }),
                    flexGrow: this.props.dataHubMode ? 1 : undefined,
                    overflow: this.props.dataHubMode ? "auto" : undefined,
                }}
            >
                <div
                    className="flex-simple-column"
                    style={{
                        boxShadow: "none",
                        backgroundColor: this.props.dataHubMode
                            ? "transparent"
                            : "#f7f7f7",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            marginTop: "20px",
                            position: "relative",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: this.props.dataHubMode
                                ? 0
                                : this.state.selectedFile == null
                                ? "57px"
                                : "38px",
                        }}
                    >
                        <span className={styles.dataHubTitle}>
                            {this.state.selectedFile != null
                                ? "Import data"
                                : "Import data from"}
                        </span>
                        {/* When data is selected, give the title  */}
                        {this.state.selectedFile != null &&
                            (this.state.liveStreamSelectedUpdateMode.value ===
                            "none" ? (
                                <Select
                                    inputId="select-data-scope"
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={
                                        "Enter new name or replace an existing data set"
                                    }
                                    styles={{
                                        ...selectStyles,
                                        container: (base) => ({
                                            ...base,
                                            marginLeft: "15px",
                                            width: "25em",
                                            height: "38px",
                                            marginRight: "10px",
                                        }),
                                    }}
                                    options={dataScopes.dataScopesOptions
                                        .filter(
                                            (option) =>
                                                option.permissionType ===
                                                Permission.ReadWrite
                                        )
                                        .concat(
                                            this.state.newDataScope != null
                                                ? [this.state.newDataScope]
                                                : []
                                        )}
                                    onChange={(newValue, p2) => {
                                        let stateDiff: any = {
                                            dataScope: newValue as DataScopeOption,
                                        };
                                        stateDiff.uploadOption =
                                            uploadOptions[0];
                                        this.setState(stateDiff);
                                    }}
                                    inputValue={this.state.dataScopeText}
                                    onInputChange={(newValue, { action }) => {
                                        if (
                                            ["input-blur"].includes(action) &&
                                            (this.state.dataScopeText?.length ||
                                                0) > 0
                                        ) {
                                            this.setState({
                                                newDataScope: {
                                                    label:
                                                        this.state
                                                            .dataScopeText ||
                                                        "",
                                                    value: NaN,
                                                    permissionType:
                                                        Permission.ReadWrite,
                                                },
                                                dataScope: {
                                                    label:
                                                        this.state
                                                            .dataScopeText ||
                                                        "",
                                                    value: NaN,
                                                    permissionType:
                                                        Permission.ReadWrite,
                                                },
                                                uploadOption: uploadOptions[0],
                                            });
                                        }
                                        this.setState({
                                            dataScopeText: newValue,
                                        });
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            (e.target as HTMLElement).blur();
                                        }
                                    }}
                                    value={this.state.dataScope}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            ) : (
                                <input
                                    id="select-data-scope"
                                    placeholder="Enter new name"
                                    className="like-select"
                                    style={{
                                        marginLeft: "15px",
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "10px",
                                    }}
                                    onChange={(e) => {
                                        this.setState({
                                            dataScopeText: e.target.value,
                                        });
                                    }}
                                    onBlur={() => {
                                        if (this.state.dataScopeText) {
                                            this.setState({
                                                newDataScope: {
                                                    label:
                                                        this.state
                                                            .dataScopeText ||
                                                        "",
                                                    value: NaN,
                                                    permissionType:
                                                        Permission.ReadWrite,
                                                },
                                                dataScope: {
                                                    label:
                                                        this.state
                                                            .dataScopeText ||
                                                        "",
                                                    value: NaN,
                                                    permissionType:
                                                        Permission.ReadWrite,
                                                },
                                                uploadOption: uploadOptions[0],
                                            });
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (this.state.dataScopeText) {
                                            if (e.key === "Enter") {
                                                this.setState({
                                                    newDataScope: {
                                                        label:
                                                            this.state
                                                                .dataScopeText ||
                                                            "",
                                                        value: NaN,
                                                        permissionType:
                                                            Permission.ReadWrite,
                                                    },
                                                    dataScope: {
                                                        label:
                                                            this.state
                                                                .dataScopeText ||
                                                            "",
                                                        value: NaN,
                                                        permissionType:
                                                            Permission.ReadWrite,
                                                    },
                                                    uploadOption:
                                                        uploadOptions[0],
                                                });
                                            }
                                        }
                                    }}
                                />
                            ))}
                        {/* When data is Not selected, render elements  */}
                        {this.state.selectedFile == null && (
                            <div className="my-row">
                                <div
                                    className={styles.dataHubButtonsContainer}
                                    style={{
                                        marginLeft: 10,
                                        height: 45,
                                        borderRadius: 6,
                                        background: "transparent",
                                    }}
                                >
                                    {filterOptions.map((option) => (
                                        <Button
                                            key={option.label}
                                            type="button"
                                            className={cx(
                                                styles.dataHubButton,
                                                option.label ===
                                                    this.state.inputLabel &&
                                                    styles.dataHubButtonSelected
                                            )}
                                            style={{
                                                width: "100%",
                                                padding: "20px 30px",
                                                borderRadius: 6,
                                                background: "transparent",
                                            }}
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    ...defaultState(),
                                                    endpoints: state.endpoints,
                                                    inputMode: option.value,
                                                    inputLabel: option.label,
                                                }));
                                            }}
                                        >
                                            {option.label}
                                        </Button>
                                    ))}
                                </div>
                                {this.props.onReturnToCanvas && (
                                    <Button
                                        type="button"
                                        className={cx(styles.dataHubButton)}
                                        style={{
                                            width: "100%",
                                            marginLeft: 15,
                                            border: "1px solid #D1D1D1",
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingLeft: 14,
                                            paddingRight: 14,
                                            height: 45,
                                            borderRadius: 6,
                                            background: "transparent",
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                showDataMarketplace: true,
                                            });
                                        }}
                                    >
                                        {"Data Marketplace"}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                    {/* begin body of pane */}
                    {/* CSV option, but havne't uploaded data */}
                    {this.state.selectedFile == null &&
                        this.state.inputMode === InputMode.LocalCsv && (
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    if (acceptedFiles.length > 0)
                                        this.handleFileUpload(acceptedFiles[0]);

                                    // SelectedOption.UpdateaskSwitchTab(false);
                                    // SelectedOption.UpdateSwitchTab(false);
                                }}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                }) => (
                                    <div
                                        {...getRootProps()}
                                        style={{
                                            display: "flex",
                                            marginTop: this.props.dataHubMode
                                                ? "20px"
                                                : "50px",
                                            marginLeft: this.props.dataHubMode
                                                ? 0
                                                : "55px",
                                            marginRight: this.props.dataHubMode
                                                ? 0
                                                : "55px",
                                            marginBottom: "65px",
                                            height: "100%",
                                            borderRadius: "4px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: "2px dashed #a3a3a3",
                                        }}
                                    >
                                        <div
                                            className="flex-simple-column"
                                            style={{ alignItems: "center" }}
                                        >
                                            {!isDragActive && (
                                                <>
                                                    <span
                                                        className="content-regular-text"
                                                        style={{
                                                            cursor: "default",
                                                            fontSize: "20px",
                                                            color:
                                                                lightThemeStyle.primaryTextColor,
                                                            marginBottom: 10,
                                                        }}
                                                    >
                                                        Drop a file
                                                    </span>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 1,
                                                                width: 80,
                                                                backgroundColor:
                                                                    "#a3a3a3",
                                                            }}
                                                        />
                                                        <span
                                                            style={{
                                                                margin:
                                                                    "0 15px",
                                                                color:
                                                                    "#a3a3a3",
                                                            }}
                                                        >
                                                            or
                                                        </span>
                                                        <div
                                                            style={{
                                                                height: 1,
                                                                width: 80,
                                                                backgroundColor:
                                                                    "#a3a3a3",
                                                            }}
                                                        />
                                                    </div>
                                                    <button
                                                        style={{
                                                            border:
                                                                "2px solid #3b82c9",
                                                            borderRadius: 4,
                                                            color: "#3b82c9",
                                                            fontWeight: 600,
                                                            padding: "5px 10px",
                                                            fontSize: 16,
                                                            marginTop: 15,
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <ImportIcon
                                                            style={{
                                                                fill: "#3b82c9",
                                                                stroke:
                                                                    "#3b82c9",
                                                                marginRight: 15,
                                                            }}
                                                        />
                                                        Import from device
                                                    </button>
                                                </>
                                            )}
                                            {isDragActive && (
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                        marginTop: "6px",
                                                        color: "#869AAC",
                                                        fontSize: "15px",
                                                    }}
                                                >
                                                    Drop File Here
                                                </span>
                                            )}
                                        </div>
                                        <input {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                        )}
                    {/* Added this display table for API' option. */}
                    {this.state.inputMode === InputMode.LiveStreamAPI && (
                        <>
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    marginLeft: 138,
                                    marginRight: 163,
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    className="content-regular-text"
                                    style={{
                                        fontSize: 20,
                                        fontFamily: "Roboto",
                                        color: lightThemeStyle.primaryTextColor,
                                    }}
                                >
                                    API Source
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={"Select API source"}
                                    isDisabled={this.state.selectedFile != null}
                                    styles={{
                                        ...selectStyles,
                                        container: (base) => ({
                                            ...base,
                                            height: "38px",
                                            flex: 1,
                                            marginLeft: "41px",
                                            marginRight: "24px",
                                        }),
                                    }}
                                    options={this.state.endpoints.map(
                                        (endpoint) => ({
                                            ...endpoint,
                                            label: endpoint.name,
                                            value: endpoint.slug,
                                        })
                                    )}
                                    value={this.state.selectedEndpointOption}
                                    onChange={(option) => {
                                        let newOption = option as APIUpdateEndpointOption;
                                        this.setState({
                                            selectedEndpointOption: newOption,
                                            liveStreamEndpointSlug:
                                                newOption.slug,
                                        });
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                {this.state.selectedEndpointOption != null && (
                                    <Button
                                        type="button"
                                        className="btn btn-lg btn-primary my-primary"
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            height: "33px",
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            backgroundColor: "#3b82c9",
                                        }}
                                        onClick={() => {
                                            if (this.state.selectedFile == null)
                                                this.handleEndpoint();
                                            else {
                                                this.setState((state) => ({
                                                    ...defaultState(),
                                                    liveStreamEndpointSlug:
                                                        state.liveStreamSelectedEndpointSlug,
                                                    liveStreamEndpointOptions:
                                                        state.liveStreamEndpointOptions,
                                                    endpoints: state.endpoints,
                                                    inputMode: state.inputMode,
                                                    inputLabel:
                                                        state.inputLabel,
                                                }));
                                            }
                                        }}
                                    >
                                        {this.state.selectedFile == null
                                            ? "Connect"
                                            : "Disconnect"}
                                    </Button>
                                )}
                            </div>
                            {this.state.selectedFile == null &&
                                this.state.selectedEndpointOption != null && (
                                    <APIComponent
                                        onOptionsChange={(options: any) => {
                                            this.setState({
                                                liveStreamEndpointOptions: options,
                                            });
                                        }}
                                        options={
                                            this.state.liveStreamEndpointOptions
                                        }
                                        containerStyle={{
                                            marginLeft: 300,
                                            marginTop: 36,
                                        }}
                                        endpoint={
                                            this.state.selectedEndpointOption
                                        }
                                    />
                                )}
                        </>
                    )}

                    {/* Selected file, but advanced options collapsed */}
                    {this.state.selectedFile != null && (
                        <div
                            className="my-row"
                            style={{
                                marginTop: "20px",
                                marginLeft: "93px",
                                marginRight: "43px",
                                marginBottom: "20px",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    color: this.state.advancedOptionsExpanded
                                        ? lightThemeStyle.secondaryTextColor
                                        : lightThemeStyle.primaryTextColor,
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    cursor: "pointer",
                                }}
                                className="unselectable"
                                onClick={() => {
                                    this.setState((state) => ({
                                        advancedOptionsExpanded: !state.advancedOptionsExpanded,
                                    }));
                                }}
                            >
                                Advanced Options
                            </span>
                            <div
                                className="unselectable"
                                style={{
                                    transform: this.state
                                        .advancedOptionsExpanded
                                        ? "none"
                                        : "rotate(90deg)",
                                    opacity: this.state.advancedOptionsExpanded
                                        ? 1
                                        : 0.5,
                                }}
                            >
                                <CanvasIconsContainer
                                    options={{
                                        fill: this.state.advancedOptionsExpanded
                                            ? lightThemeStyle.secondaryTextColor
                                            : lightThemeStyle.primaryTextColor,
                                    }}
                                    type={"Arrow"}
                                ></CanvasIconsContainer>
                            </div>
                        </div>
                    )}
                    {liveStreamSheetsConfig}
                    {/* Advanced options when expanded */}
                    {this.state.advancedOptionsExpanded &&
                        this.state.selectedFile != null && (
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    marginBottom: "18px",
                                    marginLeft: "122px",
                                    justifyContent: "flex-end",
                                    marginRight: 45,
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "Roboto",
                                        fontWeight: 300,
                                        fontSize: "12px",
                                        color: lightThemeStyle.primaryTextColor,
                                    }}
                                >
                                    Character encoding
                                </span>
                                <span
                                    style={{
                                        marginLeft: "6px",
                                        fontFamily: "Roboto",
                                        fontWeight: 300,
                                        fontSize: "12px",
                                        color:
                                            lightThemeStyle.secondaryTextColor,
                                    }}
                                >
                                    detected automatically
                                </span>
                                <Select
                                    inputId="select-character-encoding"
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...smallSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            marginLeft: "5px",
                                            width: "200px",
                                        }),
                                    }}
                                    options={encodings}
                                    onChange={(newEncoding) => {
                                        if (this.state.selectedFile != null)
                                            this.prepareCsvFile(
                                                this.state.selectedFile,
                                                newEncoding as StringOption
                                            );
                                    }}
                                    value={this.state.encoding}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                        )}

                    {/* when the file is actually selected, and then need to show the entire table */}
                    {this.state.selectedFile != null && (
                        <>{adminTableWithFullFeatures}</>
                    )}

                    {/* file is not selected, but need to show the streaming options */}
                    {((this.state.selectedFile == null &&
                        this.state.inputMode !== InputMode.LocalCsv) ||
                        (this.state.selectedFile != null &&
                            this.state.inputMode ===
                                InputMode.LiveStreamAPI)) && (
                        <div style={{ marginTop: "10px" }}>
                            {liveStreamConfig}
                            {liveStreamIntervalConfig}
                        </div>
                    )}
                    {this.state.loadFileStatus === LoadFileStatus.Loading && (
                        <div
                            style={{
                                marginTop: "20px",
                                marginLeft: 138,
                                marginRight: 138,
                            }}
                        >
                            <LinearProgress />
                        </div>
                    )}

                    <div style={{ flex: 1 }} />

                    {/* TODO: need to make this work for LiveStream API */}
                    <div
                        style={{
                            marginTop: "18px",
                            marginBottom: this.props.dataHubMode
                                ? "-11px"
                                : "28px",
                            marginRight: "21px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        {this.state.selectedFile && (
                            <Button
                                type="button"
                                className="btn btn-lg"
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    height: 40,
                                    padding: "0 20px",
                                    marginLeft: 50,
                                    backgroundColor: "#a3a3a3",
                                    border: "none",
                                }}
                                onClick={() => this.setState(defaultState())}
                            >
                                Back to initial menu
                            </Button>
                        )}
                        <div style={{ flex: 1 }} />
                        {uploadAlert}
                        <Button
                            type="button"
                            className="btn btn-lg my-outline"
                            style={{
                                fontSize: 16,
                                fontWeight: 400,
                                height: 40,
                                padding: "0 20px",
                                marginLeft: 5,
                                backgroundColor: "white",
                                border: "1px solid #e0e0e0",
                                color: "#3b82c9",
                            }}
                            onClick={this.cancelUploadProcess}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                fontSize: 16,
                                fontWeight: 400,
                                height: 40,
                                padding: "0 20px",
                                marginLeft: 5,
                                backgroundColor: "#3b82c9",
                            }}
                            title={
                                dataIsUploading
                                    ? "Data is already uploading"
                                    : liveStreamConfigChangedOrNotValid
                                    ? "Your configuration changed. Please click SELECT before uploading the data"
                                    : dataSetIsNotSelected
                                    ? "You need to select dataset before uploading the data."
                                    : !Number.isNaN(
                                          this.state.dataScope?.value
                                      ) &&
                                      this.state.liveStreamSelectedUpdateMode
                                          .value !== "none"
                                    ? "You need to set update mode to none to replace a data set"
                                    : ""
                            }
                            disabled={
                                dataSetIsNotSelected ||
                                liveStreamConfigChangedOrNotValid ||
                                dataIsUploading ||
                                (!Number.isNaN(this.state.dataScope?.value) &&
                                    this.state.liveStreamSelectedUpdateMode
                                        .value !== "none")
                            }
                            onClick={() => {
                                this.setState(
                                    {
                                        uploadOption: uploadOptions[0],
                                    },
                                    this.uploadProcess
                                );
                            }}
                        >
                            {this.state.dataScope?.value == null ||
                            Number.isNaN(this.state.dataScope?.value)
                                ? "Save dataset"
                                : "Replace"}
                        </Button>
                        {this.state.dataScope?.value != null &&
                            !Number.isNaN(this.state.dataScope?.value) && (
                                <Button
                                    type="button"
                                    className="btn btn-lg btn-primary my-primary"
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        height: 40,
                                        padding: "0 20px",
                                        marginLeft: 15,
                                        backgroundColor: "#3b82c9",
                                    }}
                                    title={
                                        dataIsUploading
                                            ? "Data is already uploading"
                                            : liveStreamConfigChangedOrNotValid
                                            ? "Your configuration changed. Please click SELECT before uploading the data"
                                            : dataSetIsNotSelected
                                            ? "You need to select dataset before uploading the data."
                                            : ""
                                    }
                                    disabled={
                                        dataSetIsNotSelected ||
                                        liveStreamConfigChangedOrNotValid ||
                                        dataIsUploading
                                    }
                                    onClick={() => {
                                        this.setState(
                                            {
                                                uploadOption: uploadOptions[1],
                                            },
                                            this.uploadData
                                        );
                                    }}
                                >
                                    Update
                                </Button>
                            )}
                        <div style={{ width: 80 }} />
                    </div>
                </div>
                {this.state.uploadFormatError != null && (
                    <MessagePopup
                        title={"Change to NA"}
                        message={
                            messageForUploadFormatError[
                                this.state.uploadFormatError
                            ]
                        }
                        rejectButtonTitle="No"
                        acceptButtonTitle="Yes"
                        onAccept={() => {
                            let forceDateToNans =
                                this.state.uploadFormatError === "date" ||
                                this.state.uploadFormatErrorForce
                                    ?.forceDateToNans;
                            let forceNumbersToNull =
                                this.state.uploadFormatError === "number" ||
                                this.state.uploadFormatErrorForce
                                    ?.forceNumbersToNull;
                            this.setState({ uploadFormatError: null }, () => {
                                this.uploadData(
                                    forceDateToNans,
                                    forceNumbersToNull
                                );
                            });
                        }}
                        onReject={() => {
                            this.setState({ uploadFormatError: null });
                        }}
                    />
                )}
                {this.state.streamStatus != null && (
                    <StatusPopup
                        status={this.state.streamStatus}
                        message={this.state.streamMessage!}
                        onClose={() => {
                            this.setState({
                                streamStatus: null,
                                streamMessage: null,
                            });
                        }}
                    />
                )}

                {/*
                {this.state.inputMode == InputMode.LocalCsv &&
                    (SelectedOption.getAskSwitchTab === true && this.state.selectedFile !== null) && (
                        // <TabSwitchConfirmationPopup />
                        alert("first")
                    )
                }
                <h1>{SelectedOption.getAskSwitchTab.toString()}</h1>
                <h1>{globalContext.ShowTabSwitchConfermation!.toString()}</h1> */}
            </div>
        );
    }
    render() {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

export { MainComponent };
