import React from "react";

import { KanbanBoardFinding } from "common/Finding";
import KanbanBoard from "common/graphics/KanbanBoard";
import styles from "./KanbanBoardWrapper.module.css";

interface Props {
    data: KanbanBoardFinding["content"]["data"];
    config: KanbanBoardFinding["config"];
    editable?: boolean;
    columnDragActive?: boolean;
    preview?: boolean;
    onChangeData?: (
        data: KanbanBoardFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: KanbanBoardFinding["config"],
        updateData?: boolean
    ) => void;
}

export default function KanbanBoardWrapper(props: Props) {
    return (
        <div className={styles.container}>
            {props.config.title && (
                <div className={styles.title}>{props.config.title}</div>
            )}
            <KanbanBoard
                containerStyle={{ width: "100%" }}
                data={props.data}
                columnDragActive={props.columnDragActive}
                editable={true}
                config={props.config}
                onDataChanged={(data: any) => {
                    if (!props.preview) {
                        let newData = { ...props.data, ...data };
                        props.onChangeData?.(newData);
                    }
                }}
                onConfigChanged={(config: any, update?: boolean) => {
                    props.onChangeConfig?.(config, update);
                }}
            />
        </div>
    );
}
