import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";
import ExpectedEffect from "common/ExpectedEffect";
import _ from "lodash";

class Api {
    static getFinding(props, findingOptions) {
        let { regressionResult, config } = props;
        if (!findingOptions) return Api.getAbstractFinding(props);
        if (findingOptions.type === "5") {
            let item = {
                type: "5",
                content: {
                    regressionResult: regressionResult,
                },
                config: config,
            };
            return item;
        } else if (findingOptions.type === "9") {
            let item = {
                type: "9",
                content: {
                    showMarginals: findingOptions.content.showMarginals,
                    regressionResult: regressionResult,
                },
                config: config,
            };
            return item;
        }
    }

    static getAbstractFinding(props) {
        let { regressionResult, config } = props;
        let item = {
            content: {
                regressionResult: regressionResult,
            },
            config: config,
        };
        return item;
    }

    static getData(config, findingOptions, moduleId, keepNativeData = false) {
        let {
            dataScope,
            conditions,
            selectedTable,
            regressionInfo,
            leftRangeValue,
            rightRangeValue,
            selectedDependentVariable,
            selectedIndependentVariable,
            interactionIndependentVariable,
            additionalParameter,
            leftAdditionalOperator,
            rightAdditionalOperator,
            leftAdditionalValue,
            rightAdditionalValue,
        } = config;
        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        conditions = conditionsToJson(conditions);
        let [
            regressionDict,
            expectedEffect,
            showDict,
            ,
            sigDict,
        ] = regressionInfo;
        let newRegressionDict = Object.assign({}, regressionDict);
        console.log(newRegressionDict);
        let percentiles = [10, 90];
        newRegressionDict.table = selectedTable.value;
        newRegressionDict.data_table_idx =
            typeof dataScope === "undefined" ? 0 : dataScope.value;
        newRegressionDict.condition_id = selectedTable.condition_id;
        newRegressionDict.conditions = conditions;
        newRegressionDict.predict_interval = {
            count: 100,
        };
        if (newRegressionDict.iv.interacts_with) {
            newRegressionDict.predict_interval.fix = {
                iv: newRegressionDict.iv.interacts_with,
                percentiles: percentiles,
            };
        } else if (
            config.journeyName === "ExploreHaloEffectsJourney" &&
            newRegressionDict.iv.other.length > 0
        ) {
            newRegressionDict.predict_interval.fix = {
                iv: newRegressionDict.iv.other[0],
                percentiles: percentiles,
            };
        }

        if (leftRangeValue && rightRangeValue) {
            newRegressionDict.predict_interval.range = [
                Number(leftRangeValue),
                Number(rightRangeValue),
            ];
        }
        let whereDict = undefined;
        if (
            additionalParameter &&
            (leftAdditionalValue || rightAdditionalValue)
        ) {
            whereDict = {};
            newRegressionDict.where = {};

            newRegressionDict.where.group = additionalParameter;
            whereDict.group = additionalParameter;

            newRegressionDict.where.expressions = [];
            whereDict.expressions = [];

            if (leftAdditionalValue) {
                newRegressionDict.where.expressions.push({
                    operation: leftAdditionalOperator?.value ?? "=",
                    value: leftAdditionalValue.value,
                });
                whereDict.expressions.push({
                    operation: leftAdditionalOperator ?? {
                        label: "=",
                        value: "=",
                    },
                    value: leftAdditionalValue,
                });
            }
            if (rightAdditionalValue) {
                newRegressionDict.where.expressions.push({
                    operation: rightAdditionalOperator?.value ?? "=",
                    value: rightAdditionalValue.value,
                });
                whereDict.expressions.push({
                    operation: rightAdditionalOperator ?? {
                        label: "=",
                        value: "=",
                    },
                    value: rightAdditionalValue,
                });
            }
        }
        let url = "/api/e/get_custom_regression";
        newRegressionDict.module_id = moduleId;
        return axios
            .post(url, newRegressionDict)
            .then((response) => {
                if (response.data.success === true) {
                    let nativeData = undefined;
                    if (keepNativeData) {
                        nativeData = _.cloneDeep(response.data);
                        delete nativeData["success"];
                        delete nativeData["error_msg"];
                    }
                    var regressionParams = response.data.regression_params;
                    var predictions = response.data.predictions;
                    let regressionResult = {
                        where: whereDict,
                        dependentVariable: selectedDependentVariable,
                        independentVariable: newRegressionDict.iv.main,
                        interactionVariable: newRegressionDict.predict_interval
                            .fix
                            ? newRegressionDict.predict_interval.fix.iv
                            : undefined,
                        regressionBars: [],
                        regressionCurves: [],
                        iv_other_names: response.data.iv_other_names,
                        iv_main_names: response.data.iv_main_names,
                        main_fixed: newRegressionDict.iv.main_fixed,
                    };
                    if (regressionParams != null) {
                        var regressionBars = [];
                        if (regressionParams.length > 0) {
                            if (
                                "interaction" in regressionParams[0] &&
                                showDict["interaction"]
                            ) {
                                let regressionBar = {
                                    name: "interaction",
                                    expectedEffect:
                                        expectedEffect["interaction"],
                                    coefficient1:
                                        regressionParams[0].interaction.cof,
                                    significance1: sigDict["interaction"]
                                        ? regressionParams[0].interaction.sig
                                        : undefined,
                                    significance2:
                                        regressionParams.length > 1
                                            ? regressionParams[1].interaction
                                                  .sig
                                            : undefined,
                                    coefficient2:
                                        regressionParams.length > 1
                                            ? regressionParams[1].interaction
                                                  .cof
                                            : undefined,
                                };
                                regressionBars.push(regressionBar);
                            }
                            if (
                                "main" in regressionParams[0] &&
                                showDict[selectedIndependentVariable]
                            ) {
                                if (!newRegressionDict.iv.main_fixed) {
                                    let regressionBar = {
                                        name: selectedIndependentVariable,
                                        expectedEffect:
                                            expectedEffect[
                                                selectedIndependentVariable
                                            ],
                                        coefficient1:
                                            regressionParams[0].main.cof,
                                        significance1: sigDict[
                                            selectedIndependentVariable
                                        ]
                                            ? regressionParams[0].main.sig
                                            : undefined,
                                        significance2:
                                            regressionParams.length > 1
                                                ? regressionParams[1].main.sig
                                                : undefined,
                                        coefficient2:
                                            regressionParams.length > 1
                                                ? regressionParams[1].main.cof
                                                : undefined,
                                    };
                                    regressionBars.push(regressionBar);
                                } else {
                                    for (
                                        let i = 0;
                                        i < regressionParams[0].main.cof.length;
                                        ++i
                                    ) {
                                        let displayName;
                                        let name =
                                            response.data.iv_main_names[i];
                                        let percentIndex = response.data.iv_main_names[
                                            i
                                        ].indexOf("%");
                                        if (percentIndex >= 0) {
                                            let value = JSON.parse(
                                                name.slice(percentIndex + 1)
                                            );
                                            let operator;
                                            if (value == null) {
                                                value = "NULL";
                                                operator = "IS";
                                            } else {
                                                operator = "=";
                                            }
                                            name = name.slice(0, percentIndex);
                                            displayName = `${name} ${operator} ${value}`;
                                        } else {
                                            displayName = selectedIndependentVariable;
                                        }
                                        let regressionBar = {
                                            name: displayName,
                                            expectedEffect:
                                                ExpectedEffect.unknown,
                                            coefficient1:
                                                regressionParams[0].main.cof[i],
                                            significance1: sigDict[
                                                selectedIndependentVariable
                                            ]
                                                ? regressionParams[0].main.sig[
                                                      i
                                                  ]
                                                : undefined,
                                            significance2:
                                                regressionParams.length > 1
                                                    ? regressionParams[1].main
                                                          .sig[i]
                                                    : undefined,
                                            coefficient2:
                                                regressionParams.length > 1
                                                    ? regressionParams[1].main
                                                          .cof[i]
                                                    : undefined,
                                        };
                                        regressionBars.push(regressionBar);
                                    }
                                }
                            }
                            if (
                                "interacts_with" in regressionParams[0] &&
                                showDict[interactionIndependentVariable]
                            ) {
                                let regressionBar = {
                                    name: interactionIndependentVariable,
                                    expectedEffect:
                                        expectedEffect[
                                            interactionIndependentVariable
                                        ],
                                    coefficient1:
                                        regressionParams[0].interacts_with.cof,
                                    significance1: sigDict[
                                        interactionIndependentVariable
                                    ]
                                        ? regressionParams[0].interacts_with.sig
                                        : undefined,
                                    significance2:
                                        regressionParams.length > 1
                                            ? regressionParams[1].interacts_with
                                                  .sig
                                            : undefined,
                                    coefficient2:
                                        regressionParams.length > 1
                                            ? regressionParams[1].interacts_with
                                                  .cof
                                            : undefined,
                                };
                                regressionBars.push(regressionBar);
                            }

                            if ("other" in regressionParams[0]) {
                                regressionParams[0].other.forEach(
                                    (value, index) => {
                                        let otherNames =
                                            regressionResult.iv_other_names ??
                                            newRegressionDict.iv.other;
                                        let name = otherNames[index];
                                        let displayName;
                                        let displayExpectedEffect;
                                        let percentIndex = name.indexOf("%");
                                        if (percentIndex >= 0) {
                                            displayExpectedEffect =
                                                ExpectedEffect.unknown;
                                            let value = JSON.parse(
                                                name.slice(percentIndex + 1)
                                            );
                                            let operator;
                                            if (value == null) {
                                                value = "NULL";
                                                operator = "IS";
                                            } else {
                                                operator = "=";
                                            }
                                            name = name.slice(0, percentIndex);
                                            displayName = `${name} ${operator} ${value}`;
                                        } else {
                                            displayExpectedEffect =
                                                expectedEffect[name];
                                            displayName = otherNames[index];
                                        }
                                        if (showDict[name]) {
                                            let regressionBar = {
                                                name: displayName,
                                                expectedEffect: displayExpectedEffect,
                                                coefficient1: value.cof,
                                                significance1: sigDict[name]
                                                    ? value.sig
                                                    : undefined,
                                                significance2:
                                                    regressionParams.length > 1
                                                        ? regressionParams[1]
                                                              .other[index].sig
                                                        : undefined,

                                                coefficient2:
                                                    regressionParams.length > 1
                                                        ? regressionParams[1]
                                                              .other[index].cof
                                                        : undefined,
                                            };
                                            regressionBars.push(regressionBar);
                                        }
                                    }
                                );
                            }
                            if (
                                "intercept" in regressionParams[0] &&
                                showDict["intercept"]
                            ) {
                                let regressionBar = {
                                    name: "intercept",
                                    expectedEffect: expectedEffect["intercept"],
                                    coefficient1:
                                        regressionParams[0].intercept.cof,
                                    significance1: sigDict["intercept"]
                                        ? regressionParams[0].intercept.sig
                                        : undefined,
                                    significance2:
                                        regressionParams.length > 1
                                            ? regressionParams[1].intercept.sig
                                            : undefined,
                                    coefficient2:
                                        regressionParams.length > 1
                                            ? regressionParams[1].intercept.cof
                                            : undefined,
                                };
                                regressionBars.push(regressionBar);
                            }
                        }

                        regressionResult.regressionBars = regressionBars;
                    } else {
                        console.log("error");
                    }

                    if (predictions != null) {
                        if (!newRegressionDict.predict_interval.fix) {
                            if (!regressionResult.where) {
                                let regressionCurve = {
                                    x: predictions[0]["iv"].main,
                                    y: predictions[0]["dv"],
                                    y_marginal: predictions[0]["dv_marginal"],
                                };
                                regressionResult.regressionCurves.push({
                                    effectCurve: regressionCurve,
                                    effectCurveMean: {
                                        x: predictions[0]["mean"]["iv"].main,
                                        y: predictions[0]["mean"]["dv"],
                                        y_marginal:
                                            predictions[0]["mean"][
                                                "dv_marginal"
                                            ],
                                    },
                                });
                            } else {
                                regressionResult.where.expressions.forEach(
                                    (expression, index) => {
                                        let regressionCurve = {
                                            x: predictions[index]["iv"].main,
                                            y: predictions[index]["dv"],
                                            y_marginal:
                                                predictions[index][
                                                    "dv_marginal"
                                                ],
                                        };
                                        regressionResult.regressionCurves.push({
                                            effectCurve: regressionCurve,
                                            effectCurveMean: {
                                                x:
                                                    predictions[index]["mean"][
                                                        "iv"
                                                    ].main,
                                                y:
                                                    predictions[index]["mean"][
                                                        "dv"
                                                    ],
                                                y_marginal:
                                                    predictions[index]["mean"][
                                                        "dv_marginal"
                                                    ],
                                            },
                                        });
                                    }
                                );
                            }
                        } else {
                            if (!regressionResult.where) {
                                let regressionCurve1 = {
                                    x: predictions[0]["iv"].main,
                                    y:
                                        predictions[0]["dv"][
                                            percentiles[0].toString()
                                        ],
                                    y_marginal:
                                        predictions[0]["dv_marginal"][
                                            percentiles[0].toString()
                                        ],
                                };
                                let regressionCurve2 = {
                                    x: predictions[0]["iv"].main,
                                    y:
                                        predictions[0]["dv"][
                                            percentiles[1].toString()
                                        ],
                                    y_marginal:
                                        predictions[0]["dv_marginal"][
                                            percentiles[1].toString()
                                        ],
                                };
                                regressionResult.regressionCurves.push({
                                    effectCurveLow: regressionCurve1,
                                    effectCurveHigh: regressionCurve2,
                                    effectCurveMean: {
                                        x: predictions[0]["mean"]["iv"].main,
                                        y: predictions[0]["mean"]["dv"],
                                        y_marginal:
                                            predictions[0]["mean"][
                                                "dv_marginal"
                                            ],
                                        z: predictions[0]["mean"]["fix"],
                                    },
                                });
                            } else {
                                regressionResult.where.expressions.forEach(
                                    (expression, index) => {
                                        let regressionCurve1 = {
                                            x: predictions[index]["iv"].main,
                                            y:
                                                predictions[index]["dv"][
                                                    percentiles[0].toString()
                                                ],
                                            y_marginal:
                                                predictions[index][
                                                    "dv_marginal"
                                                ][percentiles[0].toString()],
                                        };
                                        let regressionCurve2 = {
                                            x: predictions[index]["iv"].main,
                                            y:
                                                predictions[index]["dv"][
                                                    percentiles[1].toString()
                                                ],
                                            y_marginal:
                                                predictions[index][
                                                    "dv_marginal"
                                                ][percentiles[1].toString()],
                                        };
                                        regressionResult.regressionCurves.push({
                                            effectCurveLow: regressionCurve1,
                                            effectCurveHigh: regressionCurve2,
                                            effectCurveMean: {
                                                x:
                                                    predictions[index]["mean"][
                                                        "iv"
                                                    ].main,
                                                y:
                                                    predictions[index]["mean"][
                                                        "dv"
                                                    ],
                                                y_marginal:
                                                    predictions[index]["mean"][
                                                        "dv_marginal"
                                                    ],
                                                z:
                                                    predictions[index]["mean"][
                                                        "fix"
                                                    ],
                                            },
                                        });
                                    }
                                );
                            }
                        }
                        let finding = Api.getFinding(
                            {
                                regressionResult: regressionResult,
                                config: config,
                            },
                            findingOptions
                        );
                        if (keepNativeData) {
                            finding.config.nativeData = nativeData;
                        }
                        return Promise.resolve(finding);
                    } else {
                        return Promise.reject("error");
                    }
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
