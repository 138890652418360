import React from "react";

import { CanvasElement, CanvasTextBox } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import { DataTableInput } from "modules/canvas_page/DataTableInputSelector";
import InputsConfig from "../../common/InputsConfig";
import DefaultValueOption from "../../common/DefaultValueOption";

interface Props {
    canvasId: number;
    node: CanvasElement | CanvasTextBox;
    currentModuleId?: number;
    nodeLinkOptions: NodeLinkOption[];
    dataTableInput: DataTableInput[];
    onChange: (changes: Partial<CanvasElement>, commit?: boolean) => void;
    onChangeDataTableInput: (newValue: DataTableInput[]) => void;
}

function Data({
    canvasId,
    node,
    currentModuleId,
    nodeLinkOptions,
    dataTableInput,
    onChange,
    onChangeDataTableInput,
}: Props) {
    return (
        <>
            <InputsConfig
                canvasId={canvasId}
                node={node}
                currentModuleId={currentModuleId}
                nodeLinkOptions={nodeLinkOptions}
                dataTableInput={dataTableInput}
                onChange={onChange}
                onChangeDataTableInput={onChangeDataTableInput}
            />
            <DefaultValueOption node={node} onChange={onChange} />
        </>
    );
}

export default Data;
