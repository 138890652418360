import React from "react";
import Select from "react-select";

import { defaultBubbleBoundariesColor, MapFinding } from "common/Finding";
import ColorPicker from "common/ColorPicker";
import remoteModuleId from "common/remoteModuleId";
import Variables, { VariableOption } from "common/Variables";
import { resizeAndEncodeToBase64 } from "common/utilities/retrieveImageFromClipboard";
import commonStyles from "../../StylingSection.module.css";
import Switch from "react-switch";
import cx from "classnames";
import styles from "./MarkerConfig.module.css";
import mapSectionStyles from "../MapSection.module.css";
import { mainStyle } from "common/MainStyle";
import { getCustomSelectStyleLight } from "common/SelectStyles";

enum MarkerColoringType {
    Fixed = 0,
    Varied = 1,
}

interface MarkerColoringOption {
    label: string;
    value: MarkerColoringType;
}

const markerColoringOptions: ReadonlyArray<MarkerColoringOption> = [
    { label: "Use fixed color", value: MarkerColoringType.Fixed },
    { label: "Vary color by variable", value: MarkerColoringType.Varied },
];

interface Props {
    mapFinding: MapFinding;
    onChange: (config: MapFinding["config"], update?: boolean) => void;
    currentModuleId?: number;
}

function MarkerConfig({ mapFinding, onChange, currentModuleId }: Props) {
    const selectStyles = getCustomSelectStyleLight(
        14,
        false
    );
    return (
        <div className={styles.colorMenu}>
            <div className={styles.colorMenuFirstRow}>
                {mapFinding.type !== "maps_bubble" && (
                    <div className={styles.markerMenu}>
                        <div className={styles.markerArea}>
                            {(mapFinding.config.markerIcon != null ||
                                mapFinding.config.markerColor == null ||
                                mapFinding.config
                                    .varyMarkerColorByVariable) && (
                                <img
                                    alt=""
                                    src={
                                        mapFinding.config.markerIcon ??
                                        "/dist/img/canvas/map/marker.png"
                                    }
                                    width="32px"
                                    height="32px"
                                    className={styles.markerIcon}
                                />
                            )}
                            {mapFinding.config.markerIcon == null &&
                                mapFinding.config.markerColor != null &&
                                !mapFinding.config
                                    .varyMarkerColorByVariable && (
                                    <div
                                        className={styles.markerIcon}
                                        style={{
                                            backgroundColor:
                                                mapFinding.config.markerColor,
                                            maskImage:
                                                "url(/dist/img/canvas/map/marker.png)",
                                            maskSize: "32px 32px",
                                            WebkitMaskImage:
                                                "url(/dist/img/canvas/map/marker.png)",
                                            WebkitMaskSize: "32px 32px",
                                            width: "32px",
                                            height: "32px",
                                        }}
                                    />
                                )}
                        </div>
                        <label className={styles.changeIconButton}>
                            <input
                                style={{ display: "none" }}
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                    const files = event?.target?.files ?? [];
                                    if (files.length !== 0) {
                                        const file = files[0];
                                        resizeAndEncodeToBase64(
                                            file,
                                            (base64Image) => {
                                                onChange({
                                                    ...mapFinding.config,
                                                    markerIcon: base64Image,
                                                });
                                            },
                                            64,
                                            "image/png",
                                            "center",
                                            "bottom"
                                        );
                                    }
                                }}
                            />
                            Change icon
                        </label>
                    </div>
                )}
                <div className={styles.colorOptionsMenu}>
                    <div className={styles.colorSelect}>
                        <Select
                            placeholder=""
                            styles={{
                                ...selectStyles,
                                container: (provided) => ({
                                    ...provided,
                                    minWidth: 60,
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    minHeight: 25,
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                }),
                            }}
                            options={markerColoringOptions}
                            onChange={(newValue) => {
                                onChange(
                                    {
                                        ...mapFinding.config,
                                        varyMarkerColorByVariable:
                                            (newValue as MarkerColoringOption)
                                                .value ===
                                            MarkerColoringType.Varied,
                                    },
                                    true
                                );
                            }}
                            value={
                                mapFinding.config.varyMarkerColorByVariable
                                    ? markerColoringOptions[1]
                                    : markerColoringOptions[0]
                            }
                        />
                    </div>
                    {!mapFinding.config.varyMarkerColorByVariable && (
                        <ColorPicker
                            enableAlpha
                            value={
                                mapFinding.config.markerColor ??
                                (mapFinding.type === "maps_bubble"
                                    ? "red"
                                    : "#49A2FE")
                            }
                            onChange={(newValue) => {
                                onChange({
                                    ...mapFinding.config,
                                    markerColor: newValue,
                                });
                            }}
                            style={{
                                marginLeft: "8px",
                                borderRadius: "4px",
                                width: "32px",
                                height: "32px",
                            }}
                            inPopup
                        />
                    )}
                </div>
            </div>
            {mapFinding.type === "maps_pins" && (
                <div
                    style={{ width: 60, textAlign: "center" }}
                >
                    <div className={styles.markerArea}>
                        {mapFinding.config.markerLogoIcon != null && (
                            <img
                                alt=""
                                src={
                                    mapFinding.config.markerLogoIcon ??
                                    ""
                                }
                                width="32px"
                                height="32px"
                                className={styles.markerIcon}
                            />
                        )}
                    </div>
                    <label className={styles.changeIconButton}>
                        <input
                            style={{ display: "none" }}
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                                const files = event?.target?.files ?? [];
                                if (files.length !== 0) {
                                    const file = files[0];
                                    resizeAndEncodeToBase64(
                                        file,
                                        (base64Image) => {
                                            onChange({
                                                ...mapFinding.config,
                                                markerLogoIcon: base64Image,
                                            });
                                        },
                                        64,
                                        "image/png",
                                    );
                                }
                            }}
                        />
                        Logo icon
                    </label>
                </div>
            )}
            <div className={styles.colorMenuSecondRow}>
                {mapFinding.config.varyMarkerColorByVariable && (
                    <div
                        className="flex-simple-column"
                        style={{
                            marginTop: "15px",
                        }}
                    >
                        <Select
                            isClearable={true}
                            placeholder=""
                            styles={{
                                ...selectStyles,
                                container: (provided) => ({
                                    ...provided,
                                    minWidth: 60,
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    minHeight: 25,
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                }),
                            }}
                            options={
                                Variables(
                                    mapFinding.config.dataScope?.value,
                                    currentModuleId ?? remoteModuleId
                                ).variableOptions
                            }
                            onChange={(newValue) => {
                                onChange(
                                    {
                                        ...mapFinding.config,
                                        markerColorVariableIndex:
                                            (newValue as VariableOption)
                                                ?.value ?? null,
                                        markerValueToColor:
                                            newValue == null
                                                ? null
                                                : mapFinding.config
                                                      .markerValueToColor,
                                    },
                                    true
                                );
                            }}
                            value={
                                mapFinding.config.markerColorVariableIndex !=
                                null
                                    ? Variables(
                                          mapFinding.config.dataScope?.value,
                                          currentModuleId ?? remoteModuleId
                                      ).variableOptions[
                                          mapFinding.config
                                              .markerColorVariableIndex
                                      ]
                                    : null
                            }
                        />
                    </div>
                )}
                <div className={mapSectionStyles.stylesMenuOption}>
                    <span className={mapSectionStyles.switchLabel}>Marker cluster color</span>
                    <ColorPicker
                        enableAlpha
                        value={
                            mapFinding.config.markerClusterColor ?? mainStyle.getPropertyValue("--marker-cluster-color")
                        }
                        onChange={(color) => {
                            onChange({
                                ...mapFinding.config,
                                markerClusterColor: color,
                            });
                        }}
                        style={{
                            marginLeft: "8px",
                            borderRadius: "4px",
                            width: "32px",
                            height: "32px",
                        }}
                        inPopup
                    />
                </div>
                <div className={mapSectionStyles.stylesMenuOption}>
                    <span className={mapSectionStyles.switchLabel}>Pin border color</span>
                    <ColorPicker
                        enableAlpha
                        value={
                            mapFinding.config.markerBorderColor ?? "#fff"
                        }
                        onChange={(color) => {
                            onChange({
                                ...mapFinding.config,
                                markerBorderColor: color,
                            });
                        }}
                        style={{
                            marginLeft: "8px",
                            borderRadius: "4px",
                            width: "32px",
                            height: "32px",
                        }}
                        inPopup
                    />
                </div>
            </div>
            {(mapFinding.type === "maps_bubble" && mapFinding.config.showBoundaries) && (
                <div className={mapSectionStyles.stylesMenuOption}>
                    <span className={mapSectionStyles.switchLabel}>Boundaries color</span>
                    <ColorPicker
                        enableAlpha
                        value={
                            mapFinding.config.boundariesColor ?? defaultBubbleBoundariesColor
                        }
                        onChange={(newValue) => {
                            onChange({
                                ...mapFinding.config,
                                boundariesColor: newValue,
                            });
                        }}
                        style={{
                            marginLeft: "8px",
                            borderRadius: "4px",
                            width: "32px",
                            height: "32px",
                        }}
                        inPopup
                    />
                </div>
            )}
            <div className={mapSectionStyles.stylesMenuOption}>
                <span className={mapSectionStyles.switchLabel}>Hide color legend</span>
                <Switch
                    onChange={(checked) => {
                        let newConfig: MapFinding["config"] = {
                            ...mapFinding.config,
                        };

                        newConfig.hideVaryMarkerColorLegend =
                            checked;

                        onChange(newConfig);
                    }}
                    checked={
                        mapFinding.config.hideVaryMarkerColorLegend ?? false
                    }
                    width={26}
                    height={13}
                    offColor="#C4C4C4"
                    onColor="#3B82C9"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor="#7B7B7B"
                    onHandleColor="#3B82C9"
                    className={cx({
                        [mapSectionStyles.switchOn]:
                            mapFinding.config.hideVaryMarkerColorLegend,
                    })}
                />
            </div>
            {mapFinding.config.markerColorVariableIndex != null && (
                <div className={commonStyles.sectionContainer}>
                    {[
                        ...new Set(
                            mapFinding.content.data?.[
                                mapFinding.config.markerColorVariableIndex
                            ] ?? []
                        ),
                    ].map((item, index) => {
                        return (
                            <div
                                className={commonStyles.optionContainer}
                                key={index}
                            >
                                <span
                                    className={commonStyles.optionName}
                                    style={{ overflow: "hidden" }}
                                >
                                    {item}
                                </span>
                                <div style={{ flexGrow: 1 }} />
                                <ColorPicker
                                    inPopup
                                    enableAlpha
                                    tooltip={`${item} color`}
                                    value={
                                        mapFinding.config.markerValueToColor?.[
                                            item ?? ""
                                        ] ?? "black"
                                    }
                                    onChange={(newValue) => {
                                        onChange(
                                            {
                                                ...mapFinding.config,
                                                markerValueToColor: {
                                                    ...mapFinding.config
                                                        .markerValueToColor,
                                                    [item ?? ""]: newValue,
                                                },
                                            },
                                            true
                                        );
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default MarkerConfig;
