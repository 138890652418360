import React from "react";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import User from "state/models/User";

import { ReactComponent as GlobeIcon } from "icons/globe.svg";

import styles from "./PresentationListItem.module.css";

interface Props {
	id: number | string;
	title: string;
	lastUpdatedAt: string;
	owner: User;
	onClick: Function;
}

function PresentationListItem({ id, title, owner, lastUpdatedAt, onClick }: Props) {
	function handleClick() {
		onClick(id);
	}

	const renderTooltip = (props: any) => {
		return (
			<Tooltip id={`tooltip-${id}`} placement="top" {...props}>
				{ title }
			</Tooltip>
		);
	};

	return (
		<div className={styles.root} onClick={handleClick}>
			<OverlayTrigger delay={{ show: 200, hide: 300 }} overlay={renderTooltip} placement="top-start">
				<div className={styles.title}>
					<GlobeIcon className={styles.icon} />
					<span>{ title }</span>
				</div>
			</OverlayTrigger>
			<span className={styles.owner}>{ `${owner.firstName} ${owner.lastName}` }</span>
			<span className={styles.lastUpdatedAt}>{ lastUpdatedAt }</span>
		</div>
	);
}

export default PresentationListItem;
