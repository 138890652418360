import React, { Component, CSSProperties } from "react";

interface Props {
    className: string;
    onClosed?: () => void;

    text: string;
    style?: CSSProperties;
}

interface State {
    isActive: boolean;
}

class Alert extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isActive: true,
        };
    }

    hideAlert() {
        if (this.props.onClosed) {
            this.setState({
                isActive: false,
            });
            this.props.onClosed();
        }
    }

    render() {
        if (this.state.isActive) {
            return (
                <div
                    className={this.props.className}
                    style={this.props.style}
                    role="alert"
                >
                    {this.props.onClosed && (
                        <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={() => this.hideAlert()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    )}
                    {this.props.text}
                </div>
            );
        }
        return <div />;
    }
}

export default Alert;
