import React, { Component, CSSProperties } from "react";

import {
    CanvasSlider,
    CanvasElementOutput,
    isProgressElement,
    CanvasProgressElement,
    defaultErrorColors,
} from "common/Canvas";
import StringUtils from "common/utilities/StringUtils";
import styles from "./SliderOutputsSelector.module.css";
import ColorPicker from "common/ColorPicker";
import Collapse from "react-bootstrap/Collapse";
import cx from "classnames";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import Switch from "react-switch";

interface Props {
    value: CanvasSlider | CanvasProgressElement;
    onChange: (
        changes: Partial<{
            minOutput: CanvasElementOutput;
            maxOutput: CanvasElementOutput;
            errorOutput: CanvasElementOutput;
            errorColors: CanvasProgressElement["errorColors"];
            showErrorOutput: boolean;
        }>,
        commit?: boolean
    ) => void;
    style?: CSSProperties;
}

interface State {
    directionArrowCollapsed: boolean;
}

class SliderOutputsSelector extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            directionArrowCollapsed: false,
        };
    }
    private buildColorSettings(node: CanvasProgressElement) {
        let errorColors = node.errorColors ?? defaultErrorColors;
        let additionalCss: React.CSSProperties = node.showErrorOutput
            ? {}
            : {
                  pointerEvents: "none",
                  opacity: 0.5,
              };
        return ["positive", "negative", "neutral"].map((key, index) => {
            return (
                <div
                    key={index}
                    className={styles.colorContainer}
                    style={additionalCss}
                >
                    <span className={styles.selectLabel}>
                        {StringUtils.capitalize(key)}
                    </span>
                    <ColorPicker
                        overlayStyle={{ zIndex: 100000000 }}
                        inPopup
                        enableAlpha
                        tooltip={`${key} color`}
                        value={
                            errorColors[
                                key as "positive" | "negative" | "neutral"
                            ]
                        }
                        onChange={(newValue) => {
                            this.props.onChange({
                                errorColors: {
                                    ...errorColors,
                                    [key]: newValue,
                                },
                            });
                        }}
                    />
                </div>
            );
        });
    }
    private buildOutputsSelectorItem(
        node: CanvasSlider | CanvasProgressElement,
        index: string,
        hideTitle?: boolean
    ): JSX.Element {
        let output: CanvasElementOutput =
            index === "min"
                ? node.minOutput
                : index === "max"
                ? node.maxOutput
                : (node as CanvasProgressElement).errorOutput;

        let additionalCss: React.CSSProperties =
            index === "sign" && isProgressElement(node) && !node.showErrorOutput
                ? {
                      pointerEvents: "none",
                      opacity: 0.5,
                  }
                : {};

        return (
            <>
                {!hideTitle && (
                    <span className={styles.selectLabel}>
                        {StringUtils.capitalize(index)}
                    </span>
                )}
                <input
                    type="text"
                    className="like-select"
                    placeholder="METRIC"
                    style={{
                        width: "100%",
                        marginBottom: "10px",
                        ...additionalCss,
                    }}
                    value={output.metric}
                    onChange={(evt) => {
                        let newValue: string = evt.target.value;
                        if (index === "min")
                            this.props.onChange({
                                minOutput: {
                                    ...this.props.value.minOutput,
                                    metric: newValue,
                                },
                            });
                        // "max"
                        else if (index === "max")
                            this.props.onChange({
                                maxOutput: {
                                    ...this.props.value.maxOutput,
                                    metric: newValue,
                                },
                            });
                        else if (index === "sign")
                            this.props.onChange({
                                errorOutput: {
                                    ...(
                                        this.props
                                            .value as CanvasProgressElement
                                    ).errorOutput,
                                    metric: newValue,
                                },
                            });
                    }}
                />
            </>
        );
    }

    render() {
        return (
            <div className={styles.root}>
                {this.buildOutputsSelectorItem(this.props.value, "min")}
                {this.buildOutputsSelectorItem(this.props.value, "max")}
                {isProgressElement(this.props.value) && (
                    <div className={styles.collapsableLine}>
                        <div className={styles.lineButtons}>
                            <button
                                type="button"
                                onClick={() => {
                                    this.setState((prev) => ({
                                        directionArrowCollapsed:
                                            !prev.directionArrowCollapsed,
                                    }));
                                }}
                                className={styles.expressionCollapseButton}
                            >
                                <ChevronIcon
                                    className={cx(
                                        styles.expressionCollapseButtonIcon,
                                        {
                                            [styles.chevronOpen]:
                                                !this.state
                                                    .directionArrowCollapsed,
                                        }
                                    )}
                                />
                                Directional Arrow
                            </button>
                        </div>
                        <Collapse in={!this.state.directionArrowCollapsed}>
                            <div>
                                <div
                                    className={styles.switchField}
                                    style={{ marginBottom: 10 }}
                                >
                                    <Switch
                                        onChange={() => {
                                            this.props.onChange({
                                                showErrorOutput: !(
                                                    (
                                                        this.props
                                                            .value as CanvasProgressElement
                                                    ).showErrorOutput ?? false
                                                ),
                                            });
                                        }}
                                        checked={
                                            this.props.value.showErrorOutput ??
                                            false
                                        }
                                        width={26}
                                        height={13}
                                        offColor="#D1D1D1"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#FFF"
                                        onHandleColor="#1F8EFA"
                                    />
                                    <span className={styles.switchLabel}>
                                        Display Directional Arrow
                                    </span>
                                </div>
                                {this.buildOutputsSelectorItem(
                                    this.props.value,
                                    "sign",
                                    true
                                )}
                                {this.buildColorSettings(this.props.value)}
                            </div>
                        </Collapse>
                    </div>
                )}
            </div>
        );
    }
}
export default SliderOutputsSelector;
