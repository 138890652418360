import { observable, computed, makeObservable, runInAction } from "mobx";
import { getCanvasNotifications } from "./NotificationsApi";
import { Notification } from "./Notifications";
import { SocketIOInstance } from "common/ServerConnection";

interface Mention {
	operation: "add_mention" | "remove_mention" | "remove_pin";
	user: string;
	module_id: number;
	page_id: number;
	canvas_id: number;
	pin_id: number;
	feedback_id: number;
	creation_time: number;
}

class NotificationStore {
	@observable public notificationsState: Notification[] = [];
	private offset: number = 0;

	constructor() {
		makeObservable(this);
		this.update();
		this.updateMentions = this.updateMentions.bind(this);
		SocketIOInstance?.on("canvas_mentions", this.updateMentions);
	}
	async update() {
		try {
			let notifications = await getCanvasNotifications();
			runInAction(() => {
				this.notificationsState = notifications;
				this.offset = notifications.length;
			});
		} catch (error) {
			console.log(error);
		}
	}
	newMentions() {
		return this.notificationsState.filter((item) => item.is_new);
	}
	markPinAsRead(pinId: number) {
		let oldNotifications = Array.from(this.notificationsState);
		for (let notification of oldNotifications) {
			if (notification.pin_id === pinId) notification.is_new = false;
		}
		runInAction(() => {
			this.notificationsState = oldNotifications;
		});
	}

	async getNewMentions() {
		try {
			let notifications = await getCanvasNotifications(this.offset, 10);
			let oldNotifications = Array.from(this.notificationsState);
			oldNotifications = oldNotifications.filter(
				(notification) =>
					notifications.find(
						(newNotification) =>
							newNotification.feedback_id ===
							notification.feedback_id
					) == null
			);
			oldNotifications = notifications.concat(oldNotifications);
			runInAction(() => {
				this.notificationsState = oldNotifications;
				this.offset += notifications.length;
			});
		} catch (error) {
			console.log(error);
		}
	}

	@computed public get notififations() {
		return this.notificationsState;
	}
	removeMentionsByFeedbackId(feedbackId: number) {
		let notifications = Array.from(this.notificationsState);
		let currentLength = notifications.length;
		let newNotifications = notifications.filter(
			(notification) => notification.feedback_id !== feedbackId
		);
		let newNotificationsLength = newNotifications.length;
		runInAction(() => {
			this.notificationsState = newNotifications;
			this.offset -= currentLength - newNotificationsLength;
		});
	}
	removeMentionsByPinId(pinId: number) {
		let notifications = Array.from(this.notificationsState);
		let currentLength = notifications.length;
		let newNotifications = notifications.filter(
			(notification) => notification.pin_id !== pinId
		);
		let newNotificationsLength = newNotifications.length;
		runInAction(() => {
			this.notificationsState = newNotifications;
			this.offset -= currentLength - newNotificationsLength;
		});
	}
	updateMentions(content: { data: Mention }) {
		switch (content.data.operation) {
			case "add_mention":
				this.getNewMentions();
				break;
			case "remove_mention":
				this.removeMentionsByFeedbackId(content.data.feedback_id);
				break;
			case "remove_pin":
				this.removeMentionsByPinId(content.data.pin_id);
				break;
		}
	}
}

export default new NotificationStore();