import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { observer } from "mobx-react";
import { CanvasSubmitButton, CanvasSurvey } from "common/Canvas";
import TabTitle from "common/TabTitle";
import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";
import styles from "./SurveyComponent.module.css";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import Questions from "./Questions";
import Rules from "./Rules";
import PostMessagePage from "./PostMessagePage";
import DataScopes from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";

interface Props {
    canvasId: number;
    node: CanvasSurvey;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
    onChange: (changes: Partial<CanvasSubmitButton>, commit?: boolean, skipHistory?: boolean) => void;
    onClose: () => void;
}

function SurveyComponent({
    node,
    canvasId,
    currentModuleId,
    canvasTreeStore,
    onChange,
    onClose,
}: Props) {
    const [selectedTab, setSelectedTab] = React.useState("Settings");

    function selectTab(tabKey: string | null) {
        if (tabKey == null) return;
        setSelectedTab(tabKey);
    }

    const dataScopes =
        currentModuleId == null
            ? DataScopes
            : DataScopesForModules(currentModuleId);

    return (
        <div>
            <div className={styles.editMenuHeader}>
                <h6 className={styles.editMenuHeaderTitle}>Survey</h6>
                <button
                    type="button"
                    onClick={onClose}
                    className={styles.editMenuCloseButton}
                >
                    <CloseIcon />
                </button>
            </div>
            <Tabs
                id={"survey-config"}
                activeKey={selectedTab}
                onSelect={selectTab}
                className={styles.tabMenu}
            >
                <Tab
                    eventKey="Settings"
                    title={
                        <TabTitle
                            title="Settings"
                            selected={selectedTab === "Settings"}
                            className={styles.tabTitle}
                        />
                    }
                    className={styles.tab}
                >
                    <Questions
                        canvasTreeStore={canvasTreeStore}
                        canvasId={canvasId}
                        node={node}
                        currentModuleId={currentModuleId}
                        onChange={onChange}
                    />

                    <p className={styles.dbLabel}>
                        <b>Dataset: </b>
                        {
                            dataScopes.dataScopesOptions.find(
                                (d) =>
                                    d.value ===
                                    node.backendOutput?.dataScopeOption?.value
                            )?.label
                        }
                    </p>
                </Tab>
                <Tab
                    eventKey="Advanced"
                    title={
                        <TabTitle
                            title="Advanced"
                            selected={selectedTab === "Advanced"}
                            className={styles.tabTitle}
                        />
                    }
                    className={styles.tab}
                >
                    <Rules
                        canvasTreeStore={canvasTreeStore}
                        canvasId={canvasId}
                        node={node}
                        currentModuleId={currentModuleId}
                        onChange={onChange}
                    />
                    <PostMessagePage
                        node={node}
                        canvasId={canvasId}
                        currentModuleId={currentModuleId}
                        onChange={onChange}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}

export default observer(SurveyComponent);
