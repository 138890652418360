import React, { Component } from "react";
import { Button } from "react-bootstrap";

import axios from "common/ServerConnection";
import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";

interface State {
    status: UpdateStatus;
    errorMessage: string;
    downloadLinkBackup: string | null;
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            downloadLinkBackup: null,
        };
        this.performance = null;
    }

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    render() {
        return (
            <div style={{ marginTop: 20 }}>
                <span className="big-title-span">Save and Restore Backend</span>
                <div
                    style={{ marginTop: 10, width: 500 }}
                    className="flex-simple-column"
                >
                    <label
                        htmlFor="file-upload-dump"
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            marginLeft: "19px",
                            marginBottom: 10,
                            width: 200,
                        }}
                    >
                        Load
                    </label>
                    <input
                        id="file-upload-dump"
                        type="file"
                        accept=".sql,.zip"
                        onChange={(evt) => {
                            let localPerformance = new Date();
                            if (
                                evt.target.files != null &&
                                evt.target.files[0]
                            ) {
                                this.setState({
                                    status: UpdateStatus.Loading,
                                });
                                var formData = new FormData();
                                formData.append("backup", evt.target.files[0]);
                                axios
                                    .post<{
                                        success: boolean;
                                        error_msg: string;
                                    }>("/api/load_backup", formData, {
                                        headers: {
                                            "Content-Type":
                                                "multipart/form-data",
                                        },
                                    })
                                    .then((response) => {
                                        this.performance = localPerformance;
                                        this.setState({
                                            status: response.data.success
                                                ? UpdateStatus.Success
                                                : UpdateStatus.Error,
                                            errorMessage:
                                                response.data.error_msg,
                                        });
                                    })
                                    .catch((error) => {
                                        this.performance = localPerformance;
                                        console.error(error);
                                        this.setState({
                                            status: UpdateStatus.Error,
                                            errorMessage: String(error),
                                        });
                                    });
                            }
                        }}
                        style={{
                            display: "none",
                        }}
                    />
                    <Button
                        type="button"
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            marginLeft: "19px",
                            marginBottom: 10,
                            width: 200,
                        }}
                        onClick={() => {
                            let localPerformance = new Date();
                            this.setState({
                                status: UpdateStatus.Loading,
                            });
                            axios
                                .post<{
                                    success: boolean;
                                    error_msg: string;
                                    path: string;
                                }>("/api/save_backup", {})
                                .then((response) => {
                                    this.performance = localPerformance;
                                    this.setState({
                                        status: response.data.success
                                            ? UpdateStatus.Success
                                            : UpdateStatus.Error,
                                        errorMessage: response.data.error_msg,
                                        downloadLinkBackup: response.data
                                            .success
                                            ? window.location.origin +
                                              "/" +
                                              response.data.path
                                            : "",
                                    });
                                })
                                .catch((error) => {
                                    this.performance = localPerformance;
                                    console.error(error);
                                    this.setState({
                                        status: UpdateStatus.Error,
                                        errorMessage: String(error),
                                    });
                                });
                        }}
                    >
                        Save
                    </Button>
                    {this.state.downloadLinkBackup != null ? (
                        <div
                            style={{
                                margin: 10,
                                marginTop: 20,
                                padding: 15,
                                borderRadius: 3,
                                backgroundColor: "#3399ff",
                            }}
                        >
                            <a
                                className="regular-text"
                                href={this.state.downloadLinkBackup}
                            >
                                {this.state.downloadLinkBackup
                                    .split("/")
                                    .slice(-1)}
                            </a>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <UpdateStatusAlert
                    value={this.state.status}
                    onChange={(status: UpdateStatus) =>
                        this.setState({ status: status })
                    }
                    errorMessage={this.state.errorMessage}
                />
            </div>
        );
    }
}

export { MainComponent };
export let requirePermission = "SaveRestoreBackups";
