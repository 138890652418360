import React, { useContext } from "react";
import Theme from "./Theme";
import styles from "./ThemesBar.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Presentation, {
    PresentationType,
} from "state/models/Presentation";
import { mobileAndTabletBreakpoint } from "common/utilities/UIResponsiveManager";
import { useHorizontalScroll } from "common/utilities/scrollUtils";
import cx from "classnames";
import GlobalContext from "GlobalContext";

interface Props {
    themeBarData: Presentation[];
    onClickTheme: (key: string, id: string | number) => void;
    deleteTheme: (id: number) => void;
}

function ThemesBar({ themeBarData, onClickTheme, deleteTheme }: Props) {
    const globalContext = useContext(GlobalContext);
    const scrollRef = useHorizontalScroll(() => {});
    const settings = {
        className: "slider variable-width",
        focusOnSelect: false,
        arrows: false,
        infinite: false,
        slidesToScroll: 1,
        speed: 500,
        variableWidth: true,
        swipeToSlide: true,
    };

    const onClickDelete = (theme: Presentation) => {
        if (
            theme.type !== PresentationType.App &&
            (!theme.isShared ||
                (theme.isTutorial() &&
                    "ImportModulesToTutorials" in globalContext.permissions) ||
                (theme.type === PresentationType.Template &&
                    "ModifyAnyModule" in globalContext.permissions))
        ) {
            return deleteTheme;
        }
        return undefined;
    };

    return (
        <div className={styles.content}>
            {!mobileAndTabletBreakpoint() && (
                <div
                    ref={scrollRef}
                    className={cx(styles.scrollableTabsWrapper, "hide-scroll")}
                >
                    {themeBarData.map((theme, idx) => {
                        const deleteEvent = onClickDelete(theme);
                        return (
                            <Theme
                                key={idx}
                                theme={theme}
                                onClickTheme={onClickTheme}
                                onClickDelete={deleteEvent}
                            />
                        );
                    })}
                </div>
            )}

            {mobileAndTabletBreakpoint() && (
                <Slider {...settings}>
                    {themeBarData.map((theme, idx) => {
                        const deleteEvent = onClickDelete(theme);
                        return (
                            <Theme
                                key={idx}
                                theme={theme}
                                onClickTheme={onClickTheme}
                                onClickDelete={deleteEvent}
                            />
                        );
                    })}
                </Slider>
            )}
        </div>
    );
}

export default ThemesBar;
