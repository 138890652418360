
import { useMap } from "react-leaflet";
import { useEffect } from "react";
import Leaflet from "leaflet";
import {
  GradientColorPointer
} from "../../Finding";

export function HeatMap(props: {
  data: Array<[number, number, number]>;
  timeVariableIndex?: number;
  currentTimeValue?: number | string;
  heatMapTime?: Array<number | string | null>;
  findingType?: string;
  gradient: Array<GradientColorPointer>;
  minOpacity: number;
}) {
  const map = useMap();
  useEffect(() => {
      if (props.findingType === "maps_heatmap" && props.data.length !== 0) {
          let data = props.data;
          if (
              props.timeVariableIndex != null &&
              props.currentTimeValue != null &&
              props.heatMapTime != null
          ) {
              data = data.filter(
                  (_, index) =>
                      props.heatMapTime![index] == null ||
                      props.heatMapTime![index]! <= props.currentTimeValue!
              );
          }
          const gradient = props.gradient.reduce(
              (obj, item) => Object.assign(obj, { [item.value]: item.color }),
              {}
          );
          let newHeatLayer = Leaflet.heatLayer(data, {
              minOpacity: props.minOpacity,
              blur: 20,
              gradient,
          }).addTo(map);
          return () => {
              newHeatLayer.remove();
          };
      }
  }, [
      props.data,
      props.currentTimeValue,
      props.timeVariableIndex,
      props.heatMapTime,
      props.findingType,
      props.gradient,
      props.minOpacity,
      map,
  ]);
  return null;
}
