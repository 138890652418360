export enum ManageTableOperation {
    RemoveTable = 1,
    RemoveDataSet = 2,
    DataSetUsedIn = 3,
    EditAccessControl = 4,
    TrashPutBackIntent = 5,
    RegularTrashPutBack = 6,
    TrashPutBackReplace = 7,
    TrashPutBackKeepBoth = 8,
    RemoveDataSetForce = 9,
}

export interface ManageTableOperationOption {
    label: string;
    value: ManageTableOperation;
    // true === operation on tables, false === operation on data set
    isTableOperation: boolean;
}

export default ManageTableOperationOption;

export const manageTableOperationOptions: ReadonlyArray<ManageTableOperationOption> = [
    {
        label: "Remove Table",
        value: ManageTableOperation.RemoveTable,
        isTableOperation: true,
    },
    {
        label: "Remove Data Set",
        value: ManageTableOperation.RemoveDataSet,
        isTableOperation: false,
    },
    {
        label: "Data Set Used-in",
        value: ManageTableOperation.DataSetUsedIn,
        isTableOperation: false,
    },
    {
        label: "Edit Access Control",
        value: ManageTableOperation.EditAccessControl,
        isTableOperation: false,
    },
];
