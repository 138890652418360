import React, { Component } from "react";
import Alert from "common/Alert";

import { Element } from "react-scroll";
import { Button } from "react-bootstrap";
import ls from "local-storage";
import TextareaAutosize from "react-autosize-textarea";
import { useDrop } from "react-dnd";
import Dock from "react-dock";
import { mainStyle } from "common/MainStyle";
import {
    getInsightApi,
    getInsightImageApi,
    addStoryApi,
    getStoryApi,
    getUserPageTabsApi,
    editStoryApi,
    getStoryImageApi,
} from "common/UserApi";
import SectionIconsContainer from "common/SectionIconsContainer";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import { getStoryUserGroups } from "common/UserGroupsApi";
import InsightsGridCard from "common/insights_components/InsightsGridCard";
import { reaction } from "mobx";
import { observer } from "mobx-react";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import UserGroupsSelector from "common/UserGroupsSelector";
import Instrumentation from "common/Instrumentation";
import { goToInternalLink } from "common/InternalLinksHelper";

function DroppableInsightArea(props) {
    // [{ canDrop, isOver }, drop]
    const [{ isOver }, drop] = useDrop({
        accept: ["insight"],
        drop: (item, monitor) => {
            props.onNewInsight(item.content);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    return (
        <div
            ref={drop}
            className="flex-column"
            style={{
                border: isOver
                    ? "2px solid #39F"
                    : "2px dashed rgba(101,125,149, 0.2)",
                borderRadius: "5px",
                marginTop: 20,
                marginBottom: 20,
                width: 357,
                minHeight: 324,
                cursor: "pointer",
            }}
        >
            <div
                className="flex-column"
                style={{
                    alignItems: "center",
                    margin: "20px",
                }}
            >
                <span
                    className="unselectable"
                    style={{
                        color: mainStyle.getPropertyValue(
                            "--secondary-text-color"
                        ),
                        fontFamily: "Roboto",
                        fontSize: "20px",
                        fontWeight: "700",
                        lineHeight: "23px",
                        textAlign: "center",
                    }}
                >
                    Drag insights card here
                </span>
            </div>
        </div>
    );
}

const StoryCreator = observer(
    class StoryCreator extends Component {
        constructor(props) {
            super(props);
            this.state = {
                collapsed: true,
                dockSize: 500,
                storyTitle: "",
                storyTitleIsEditing: false,
                storyBottomTitle: "",
                storyBottomTitleIsEditing: false,
                storyInsights: {},
                editedSubtitleId: undefined,
                storyIds: [],
                storyEditId: undefined,
                storyUserData: undefined,
                storySubtitles: {},
                selectedUserGroups: [],
                storyPage: null,
                storyTab: null,
                userGroups: [],
                pageOptions: [],
                tabOptions: [],
                storyErrorMessage: undefined,
            };
            this.updateInsight = this.updateInsight.bind(this);
            this.deleteInsight = this.deleteInsight.bind(this);
            this.initUserPages = this.initUserPages.bind(this);
            this.getPageTabs = this.getPageTabs.bind(this);
            this.saveStory = this.saveStory.bind(this);
            this.insertInsightToStory = this.insertInsightToStory.bind(this);

            this.performance = null;
        }
        updateInsight(insight) {
            if (this.state.storyIds.includes(insight.id)) {
                this.setState((state) => {
                    let storyInsights = Object.assign({}, state.storyInsights);
                    storyInsights[insight.id] = insight;
                    return { storyInsights: storyInsights };
                });
            }
        }
        deleteInsight(insight_id) {
            this.setState(
                (state) => {
                    let storyIds = Array.from(state.storyIds);
                    let storyInsights = Object.assign({}, state.storyInsights);
                    let storySubtitles = Object.assign(
                        {},
                        state.storySubtitles
                    );
                    delete storyInsights[insight_id.toString()];
                    delete storySubtitles[insight_id.toString()];
                    storyIds = storyIds.filter((item) => item !== insight_id);
                    return {
                        storyInsights: storyInsights,
                        storySubtitles: storySubtitles,
                        storyIds: storyIds,
                    };
                },
                () => {
                    this.saveStory();
                }
            );
        }

        insertInsightToStory(insight, position = -1) {
            this.setState(
                (state) => {
                    let storyInsights = Object.assign({}, state.storyInsights);
                    if (insight.id.toString() in state.storyInsights) return;

                    let storyIds = Array.from(state.storyIds);
                    storyInsights[insight.id] = insight;
                    if (position === -1) storyIds.push(insight.id);
                    else {
                        storyIds.splice(position, 0, insight.id);
                    }

                    let storySubtitles = Object.assign(
                        {},
                        state.storySubtitles
                    );
                    storySubtitles[insight.id] = {
                        subtitle: "",
                    };
                    return {
                        storySubtitles: storySubtitles,
                        storyInsights: storyInsights,
                        storyIds: storyIds,
                    };
                },
                () => {
                    this.saveStory();
                }
            );
        }
        saveStory() {
            ls.set("editingStory", {
                storyPage: this.state.storyPage,
                storyTab: this.state.storyTab,
                selectedUserGroups: this.state.selectedUserGroups,
                collapsed: this.state.collapsed,
                storyIds: this.state.storyIds,
                storyTitle: this.state.storyTitle,
                storySubtitles: this.state.storySubtitles,
                storyBottomTitle: this.state.storyBottomTitle,
            });
        }

        handleDockSizeChange(size) {
            ls.set("lastDockableSize", size);
            this.setState({ dockSize: size });
        }

        handleCollapsedChange() {
            this.setState(
                (state) => ({ collapsed: !state.collapsed }),
                () => {
                    this.saveStory();
                }
            );
        }

        componentDidMount() {
            let editingStoryId = ls.get("editingStoryId");
            let lastStoryState = ls.get("editingStory") || {};
            if (editingStoryId)
                getStoryApi(Number(editingStoryId))
                    .then((story) => {
                        if (story) {
                            let collapsed =
                                typeof lastStoryState.collapsed === "undefined"
                                    ? false
                                    : lastStoryState.collapsed;
                            let storyIds = lastStoryState.storyIds || [];
                            let storyInsights = {};
                            let storyTitle =
                                lastStoryState.storyTitle || story.title;
                            let storyBottomTitle =
                                lastStoryState.storyBottomTitle || "";
                            let storySubtitles =
                                lastStoryState.storySubtitles || {};
                            story.insights.forEach((insight, index) => {
                                if (insight.contents.type === "subtitle") {
                                    let next = story.insights[index + 1];
                                    if (!next) {
                                        if (!storyBottomTitle)
                                            storyBottomTitle =
                                                insight.contents.text;
                                    } else {
                                        if (next.contents.type !== "subtitle") {
                                            if (!storySubtitles[-index - 2])
                                                storySubtitles[-index - 2] = {
                                                    subtitle:
                                                        insight.contents.text,
                                                };
                                        }
                                    }
                                } else {
                                    if (!storyIds.includes(-index - 1))
                                        storyIds.push(-index - 1);
                                    insight.id = -index - 1;
                                    storyInsights[-index - 1] = insight;
                                }
                            });
                            storyIds.forEach((storyId) => {
                                if (!(storyId.toString() in storySubtitles)) {
                                    storySubtitles[storyId] = {
                                        subtitle: "",
                                    };
                                }
                            });
                            this.setState(
                                {
                                    storyUserData: story.user_data,
                                    storyIds: storyIds,
                                    storyInsights: storyInsights,
                                    storyTitle: storyTitle,
                                    storyBottomTitle: storyBottomTitle,
                                    storySubtitles: storySubtitles,
                                    storyEditId: editingStoryId,
                                    collapsed: collapsed,
                                },
                                () => {
                                    this.initUserPages(
                                        PagesStore(PageType.Stories).pages
                                    );
                                }
                            );
                            if (!lastStoryState.selectedUserGroups) {
                                getStoryUserGroups(editingStoryId).then(
                                    (groups) => {
                                        if (groups.length > 0)
                                            this.setState({
                                                selectedUserGroups: groups,
                                            });
                                    }
                                );
                            } else {
                                this.setState({
                                    selectedUserGroups:
                                        lastStoryState.selectedUserGroups,
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        ls.remove("editingStoryId");
                        this.saveStory();
                        this.initUserPages(PagesStore(PageType.Stories).pages);
                    });
            else {
                this.setState(lastStoryState, () => {
                    this.initUserPages(PagesStore(PageType.Stories).pages);
                });
            }
            if (lastStoryState.storyIds) {
                lastStoryState.storyIds
                    .filter((id) => id >= 0)
                    .forEach((id) => {
                        getInsightApi(id)
                            .then((card) => {
                                let storyInsights = Object.assign(
                                    {},
                                    this.state.storyInsights
                                );
                                storyInsights[id] = card;
                                this.setState({ storyInsights: storyInsights });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    });
            }
            this.pagesReaction = reaction(
                () => PagesStore(PageType.Stories).pageOptions,
                (pageOptions) => {
                    this.initUserPages(pageOptions);
                }
            );
        }
        componentWillUnmount() {
            this.pagesReaction();
        }
        componentDidUpdate(prevProps, prevState, snapshot) {
            if (this.performance != null) {
                let timeMs = new Date() - this.performance;
                this.performance = null;
                Instrumentation.addInteraction("Insights", timeMs);
            }
        }
        initUserPages(pageOptions) {
            if (pageOptions.length === 0) return;
            let updatedStoryPage = null;
            if (this.state.storyPage) {
                let updatedStoryPageArray = pageOptions.filter(
                    (item) => item.value === this.state.storyPage.value
                );
                if (updatedStoryPageArray.length > 0)
                    updatedStoryPage = updatedStoryPageArray[0];
            }
            this.setState(
                {
                    storyPage: updatedStoryPage,
                    pageOptions: pageOptions,
                    tabOptions: [],
                },
                () => {
                    if (this.state.storyPage)
                        this.getPageTabs(this.state.storyPage.value);
                    this.saveStory();
                }
            );
        }

        getPageTabs(pageId) {
            getUserPageTabsApi(pageId, 2).then((tabs) => {
                let tabOptions = tabs.map((tab) => {
                    return {
                        label: tab.title,
                        value: tab.id,
                    };
                });
                this.setState({ tabOptions: tabOptions });
            });
        }

        buildStoryColumn() {
            return (
                <Element
                    name="scrollable"
                    className="element"
                    style={{
                        width: 500,
                        overflowX: "auto",
                        overflowY: "auto",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                marginTop: 10,
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            <TextareaAutosize
                                onBlur={() => {
                                    this.setState({
                                        storyTitleIsEditing: false,
                                    });
                                }}
                                onFocus={() => {
                                    this.setState({
                                        storyTitleIsEditing: true,
                                    });
                                }}
                                className="insights-text-area"
                                placeholder="Enter story title"
                                value={this.state.storyTitle}
                                style={{
                                    textAlign: "center",
                                    backgroundColor: this.state
                                        .storyTitleIsEditing
                                        ? mainStyle.getPropertyValue(
                                              "--tertiary-content-color"
                                          )
                                        : "transparent",
                                    width: 357,
                                    fontSize: 35,
                                    minHeight: 50,
                                    marginTop: 10,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    marginBottom: 0,
                                }}
                                onInput={(evt) => {
                                    this.setState(
                                        { storyTitle: evt.target.value },
                                        () => {
                                            this.saveStory();
                                        }
                                    );
                                }}
                            />
                        </div>
                        {!this.state.storyEditId && (
                            <>
                                <div
                                    className="flex-simple-column"
                                    style={{
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <Select
                                        placeholder={"Select page"}
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                height: "38px",
                                                width: "140px",
                                            }),
                                        }}
                                        options={this.state.pageOptions}
                                        value={this.state.storyPage}
                                        onChange={(newValue) => {
                                            this.setState(
                                                {
                                                    storyPage: newValue,
                                                    storyTab: null,
                                                },
                                                () => {
                                                    this.getPageTabs(
                                                        this.state.storyPage
                                                            .value
                                                    );
                                                    this.saveStory();
                                                }
                                            );
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25: "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                                <div
                                    className="flex-simple-column"
                                    style={{
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <Select
                                        placeholder={"Select tab"}
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                height: "38px",
                                                width: "140px",
                                                marginTop: 10,
                                            }),
                                        }}
                                        options={this.state.tabOptions}
                                        value={this.state.storyTab}
                                        onChange={(newValue) => {
                                            this.setState(
                                                {
                                                    storyTab: newValue,
                                                },
                                                () => {
                                                    this.saveStory();
                                                }
                                            );
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25: "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            </>
                        )}
                        <UserGroupsSelector
                            selectedUserGroups={this.state.selectedUserGroups}
                            onChange={(selectedUserGroups) => {
                                this.setState(
                                    {
                                        selectedUserGroups: selectedUserGroups,
                                    },
                                    () => {
                                        this.saveStory();
                                    }
                                );
                            }}
                        />
                        {this.state.storyIds.map((id) => (
                            <div
                                key={id}
                                style={{
                                    marginTop: 20,
                                    marginLeft: 10,
                                    marginRight: 10,
                                }}
                            >
                                <TextareaAutosize
                                    className="insights-text-area"
                                    placeholder="Enter subtitle"
                                    value={
                                        this.state.storySubtitles[id].subtitle
                                    }
                                    onBlur={() => {
                                        this.setState({
                                            editedSubtitleId: undefined,
                                        });
                                    }}
                                    onFocus={() => {
                                        this.setState({ editedSubtitleId: id });
                                    }}
                                    style={{
                                        textAlign: "center",
                                        backgroundColor:
                                            this.state.editedSubtitleId === id
                                                ? mainStyle.getPropertyValue(
                                                      "--tertiary-content-color"
                                                  )
                                                : "transparent",

                                        width: 357,
                                        fontSize: 35,
                                        minHeight: 50,
                                        marginTop: 10,
                                        marginLeft: 0,
                                        marginRight: 0,
                                        marginBottom: 0,
                                    }}
                                    onInput={(evt) => {
                                        let storySubtitles = Object.assign(
                                            {},
                                            this.state.storySubtitles
                                        );
                                        storySubtitles[id] = {
                                            subtitle: evt.target.value,
                                        };
                                        this.setState(
                                            {
                                                storySubtitles: storySubtitles,
                                            },
                                            () => {
                                                this.saveStory();
                                            }
                                        );
                                    }}
                                    onKeyDown={(evt) => {
                                        if (evt.key === "Backspace") {
                                            if (evt.target.value.length === 0) {
                                                let storySubtitles = Object.assign(
                                                    {},
                                                    this.state.storySubtitles
                                                );
                                                delete storySubtitles[
                                                    id.toString()
                                                ];
                                                this.setState(
                                                    {
                                                        storySubtitles: storySubtitles,
                                                    },
                                                    () => {
                                                        this.saveStory();
                                                    }
                                                );
                                            }
                                        }
                                    }}
                                />
                                {this.state.storyInsights[id] && (
                                    <InsightsGridCard
                                        canEdit={true}
                                        canCopy={false}
                                        acceptDrag={"story_insight"}
                                        acceptDrop={[
                                            "insight",
                                            "story_insight",
                                        ]}
                                        onCrossClicked={() => {
                                            this.performance = new Date();
                                            this.deleteInsight(id);
                                        }}
                                        onCardsSwapped={(
                                            firstItem,
                                            secondItem
                                        ) => {
                                            this.performance = new Date();
                                            this.setState(
                                                (state) => {
                                                    let firstItemId =
                                                        firstItem.id;
                                                    let secondItemId =
                                                        secondItem.id;
                                                    let indexFirst = state.storyIds.indexOf(
                                                        firstItemId
                                                    );
                                                    let indexSecond = state.storyIds.indexOf(
                                                        secondItemId
                                                    );
                                                    if (indexSecond === -1) {
                                                        this.insertInsightToStory(
                                                            secondItem,
                                                            indexFirst + 1
                                                        );
                                                        return;
                                                    }
                                                    let newStoryIds = Object.assign(
                                                        state.storyIds
                                                    );
                                                    let temp =
                                                        newStoryIds[indexFirst];
                                                    newStoryIds[indexFirst] =
                                                        newStoryIds[
                                                            indexSecond
                                                        ];
                                                    newStoryIds[
                                                        indexSecond
                                                    ] = temp;
                                                    return {
                                                        storyIds: newStoryIds,
                                                    };
                                                },
                                                () => {
                                                    this.saveStory();
                                                }
                                            );
                                        }}
                                        onCardClicked={() => {
                                            //if (Number(id) < 0) return;
                                            //this.props.onInsightClick(this.state.storyInsights[id]);
                                        }}
                                        onShareClicked={() => {
                                            this.performance = new Date();
                                            if (Number(id) < 0) return;
                                            this.props.onInsightShare(
                                                this.state.storyInsights[id]
                                            );
                                        }}
                                        item={this.state.storyInsights[id]}
                                    />
                                )}
                            </div>
                        ))}
                        <DroppableInsightArea
                            onNewInsight={(insight) => {
                                this.performance = new Date();
                                this.insertInsightToStory(insight);
                            }}
                        />
                        <TextareaAutosize
                            onBlur={() => {
                                this.setState({
                                    storyBottomTitleIsEditing: false,
                                });
                            }}
                            onFocus={() => {
                                this.setState({
                                    storyBottomTitleIsEditing: true,
                                });
                            }}
                            className="insights-text-area"
                            placeholder="Enter story finish subtitle"
                            value={this.state.storyBottomTitle}
                            style={{
                                textAlign: "center",
                                backgroundColor: this.state
                                    .storyBottomTitleIsEditing
                                    ? mainStyle.getPropertyValue(
                                          "--tertiary-content-color"
                                      )
                                    : "transparent",
                                width: 357,
                                fontSize: 35,
                                minHeight: 50,
                                marginTop: 10,
                                marginLeft: 0,
                                marginRight: 0,
                                marginBottom: 20,
                            }}
                            onInput={(evt) => {
                                this.setState(
                                    {
                                        storyBottomTitle: evt.target.value,
                                    },
                                    () => {
                                        this.saveStory();
                                    }
                                );
                            }}
                        />
                        {this.state.storyErrorMessage && (
                            <Alert
                                text={this.state.storyErrorMessage}
                                className="alert alert-danger alert-dismissible"
                                onClosed={() => {
                                    this.setState({
                                        storyErrorMessage: undefined,
                                    });
                                }}
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    minHeight: "25px",
                                }}
                            />
                        )}
                        {Object.keys(this.state.storyInsights).length > 0 &&
                        this.state.storyTitle.length > 0 &&
                        (this.state.storyTab || this.state.storyEditId) ? (
                            <Button
                                type="button"
                                style={{ width: 200, marginBottom: 20 }}
                                className="btn btn-md btn-primary my-primary"
                                onClick={async () => {
                                    this.performance = new Date();
                                    let story = {
                                        title: this.state.storyTitle,
                                        insights: [],
                                    };
                                    let images = {};
                                    let slideIndex = 0;
                                    for (let id of this.state.storyIds) {
                                        if (
                                            this.state.storySubtitles[id]
                                                .subtitle.length > 0
                                        ) {
                                            story.insights.push({
                                                contents: {
                                                    type: "subtitle",
                                                    text: this.state
                                                        .storySubtitles[id]
                                                        .subtitle,
                                                },
                                            });
                                            slideIndex += 1;
                                        }
                                        let image;
                                        if (id >= 0)
                                            image = await getInsightImageApi(
                                                id
                                            );
                                        else
                                            image = await getStoryImageApi(
                                                this.state.storyEditId,
                                                -id - 1
                                            );
                                        if (image != null) {
                                            images[slideIndex] = image;
                                        }
                                        story.insights.push(
                                            this.state.storyInsights[id]
                                        );
                                        slideIndex += 1;
                                    }
                                    if (this.state.storyBottomTitle)
                                        story.insights.push({
                                            contents: {
                                                type: "subtitle",
                                                text: this.state
                                                    .storyBottomTitle,
                                            },
                                        });
                                    if (!this.state.storyEditId) {
                                        addStoryApi(
                                            story,
                                            images,
                                            this.state.storyTab.value,
                                            this.state.selectedUserGroups
                                        )
                                            .then((id) => {
                                                ls.remove("editingStoryId");
                                                ls.remove("editingStory");
                                                let timeMs =
                                                    new Date() -
                                                    this.performance;
                                                this.performance = null;
                                                Instrumentation.addInteraction(
                                                    "Insights",
                                                    timeMs
                                                ).then(() => {
                                                    goToInternalLink(
                                                        "/stories.html"
                                                    );
                                                });
                                            })
                                            .catch((msg) => {
                                                this.setState({
                                                    storyErrorMessage: String(
                                                        msg
                                                    ),
                                                });
                                            });
                                    } else {
                                        story.user_data = this.state.storyUserData;
                                        story.id = this.state.storyEditId;
                                        editStoryApi(
                                            story,
                                            images,
                                            this.state.selectedUserGroups
                                        )
                                            .then((id) => {
                                                ls.remove("editingStoryId");
                                                ls.remove("editingStory");
                                                let timeMs =
                                                    new Date() -
                                                    this.performance;
                                                this.performance = null;
                                                Instrumentation.addInteraction(
                                                    "Insights",
                                                    timeMs
                                                ).then(() => {
                                                    goToInternalLink(
                                                        "/stories.html"
                                                    );
                                                });
                                            })
                                            .catch((msg) => {
                                                this.setState({
                                                    storyErrorMessage: msg,
                                                });
                                            });
                                    }
                                }}
                            >
                                {this.state.storyEditId
                                    ? "EDIT STORY"
                                    : "CREATE STORY"}
                            </Button>
                        ) : null}
                        <Button
                            type="button"
                            style={{ width: 200, marginBottom: 20 }}
                            className="btn btn-md btn-primary my-primary"
                            onClick={() => {
                                this.performance = new Date();
                                ls.remove("editingStoryId");
                                ls.remove("editingStory");
                                this.setState({
                                    storyTitle: "",
                                    storyPage: null,
                                    storyTab: null,
                                    storyTitleIsEditing: false,
                                    storyBottomTitle: "",
                                    storyBottomTitleIsEditing: false,
                                    storyInsights: {},
                                    editedSubtitleId: undefined,
                                    storyIds: [],
                                    storyEditId: undefined,
                                    storyUserData: undefined,
                                    storySubtitles: {},
                                    selectedUserGroups: [],
                                });
                            }}
                        >
                            {"RESET"}
                        </Button>
                    </div>
                </Element>
            );
        }

        render() {
            return (
                <Dock
                    position="right"
                    isVisible={true}
                    fluid={false}
                    dimMode="none"
                    size={this.state.collapsed ? 50 : this.state.dockSize}
                    onSizeChange={this.handleDockSizeChange.bind(this)}
                >
                    <div
                        className="dock-content flex-simple-column"
                        style={{
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            className="my-row pull-right"
                            style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{ margin: 10 }}
                                onClick={this.handleCollapsedChange.bind(this)}
                            >
                                <SectionIconsContainer
                                    options={{
                                        style: { fill: "#6a7997" },
                                        width: "32",
                                        height: "32",
                                        viewBox: "0 0 24 24",
                                    }}
                                    type="Stories"
                                />
                            </div>
                        </div>

                        {!this.props.collapsed ? this.buildStoryColumn() : null}
                    </div>
                </Dock>
            );
        }
    }
);

export default StoryCreator;