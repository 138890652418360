import React, { Component } from "react";
import TextareaAutosize from "react-autosize-textarea";
import { CanvasComment } from "../../comments/CanvasComments";
import PinInformationStore from "../../comments/PinInformationStore";
import PinStore from "../../comments/PinStore";
import Popup from "reactjs-popup";
import { Dropdown } from "react-bootstrap";
import MentionComponent, { getUserNames } from "common/Mentions";
import ModuleUserGroupsStore from "common/ModuleUserGroupsStore";
import { observer } from "mobx-react";
import styles from "./CommentPopup.module.css";
import UserInfo from "common/UserInfo";
import Avatar from "common/Avatar";
import User from "state/models/User";
import Draggable from "react-draggable";
import { sendMessageNotification } from "../../comments/CanvasCommentsApi";
import cx from "classnames";
import { formatDate } from "common/utilities/FormatDate";
import NotificationStore from "common/notifications/NotificationStore";
import NewCommentsStore from "../../comments/NewCommentsStore";

interface Props {
    canWrite: boolean;
    moduleId: number;
    canvasId: number;
    pinId: number;
    x: number;
    y: number;
    onClose: () => void;
}

interface State {
    x: number;
    y: number;
    newComment: string;
    comments: CanvasComment[];
    editedCommentId: number | undefined;
    editedComment: string;
}

@observer
export default class CommentPopup extends Component<Props, State> {
    drag: boolean = false;
    textAreaRefs: { [key: number]: MentionComponent } = {};
    constructor(props: Props) {
        super(props);
        this.state = {
            x: props.x,
            y: props.y,
            newComment: "",
            editedComment: "",
            comments: [],
            editedCommentId: undefined,
        };
        this.addNewComment = this.addNewComment.bind(this);
        this.editComment = this.editComment.bind(this);
        this.buildDropdownMenu = this.buildDropdownMenu.bind(this);
    }
    async componentDidMount() {
        try {
            await PinStore(this.props.canvasId).updateComments(
                this.props.pinId,
                false
            );
            NotificationStore.markPinAsRead(this.props.pinId);
            NewCommentsStore(this.props.moduleId).markPinAsRead(
                this.props.pinId
            );
        } catch (exception) {
            console.log(String(exception));
        }
    }
    buildTopMenu() {
        return (
            <Dropdown
                style={{
                    backgroundColor: "transparent",
                    alignSelf: "flex-end",
                }}
            >
                <Dropdown.Toggle
                    id="comment-popup-top"
                    style={{
                        backgroundColor: "transparent",
                        border: "0",
                    }}
                >
                    <span className={styles.toggleStyle}>&#8942;</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.menuStyle}>
                    <Dropdown.Item
                        className={styles.menuItemStyle}
                        href="#"
                        onClick={(evt: any) => {
                            evt.stopPropagation();
                        }}
                    >
                        Delete
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    buildMainDropdownMenu() {
        if (!this.props.canWrite) return null;
        return (
            <Dropdown
                style={{
                    backgroundColor: "transparent",
                    alignSelf: "flex-end",
                }}
            >
                <Dropdown.Toggle
                    id="comment-popup-main"
                    style={{
                        backgroundColor: "transparent",
                        border: "0",
                    }}
                >
                    <span className={styles.toggleStyle}>&#8942;</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.menuStyle}>
                    <Dropdown.Item
                        className={styles.menuItemStyle}
                        href="#"
                        onClick={() => {
                            PinStore(this.props.canvasId).deletePin(
                                this.props.pinId
                            );
                        }}
                    >
                        Delete pin
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
    buildDropdownMenu(item: CanvasComment, index: number) {
        if (!item.is_owner) return null;
        return (
            <Dropdown
                style={{
                    backgroundColor: "transparent",
                    alignSelf: "flex-end",
                }}
            >
                <Dropdown.Toggle
                    id="comment-popup-menu"
                    style={{
                        backgroundColor: "transparent",
                        border: "0",
                    }}
                >
                    <span className={styles.toggleStyle}>&#8942;</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.menuStyle}>
                    {index === 0 && this.props.canWrite && (
                        <Dropdown.Item
                            className={styles.menuItemStyle}
                            href="#"
                            onClick={(evt: any) => {
                                PinStore(this.props.canvasId).deletePin(
                                    this.props.pinId
                                );
                            }}
                        >
                            Delete pin
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item
                        className={styles.menuItemStyle}
                        href="#"
                        onClick={(evt: any) => {
                            evt.stopPropagation();
                            this.setState(
                                {
                                    editedComment: item.feedback,
                                    editedCommentId: item.id,
                                },
                                () => {
                                    setTimeout(() => {
                                        this.textAreaRefs[item.id]?.focus();
                                    }, 0);
                                }
                            );
                        }}
                    >
                        Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                        className={styles.menuItemStyle}
                        href="#"
                        onClick={(evt: any) => {
                            evt.stopPropagation();
                            PinStore(this.props.canvasId).deleteComment(
                                this.props.pinId,
                                item.id
                            );
                        }}
                    >
                        Delete
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
    editComment(item: CanvasComment, neighboursUsers: UserInfo[]) {
        let editedComment = this.state.editedComment;
        if (editedComment) {
            let users = getUserNames(editedComment, neighboursUsers);
            PinStore(this.props.canvasId)
                .editComment(this.props.pinId, item.id, editedComment, users)
                .then((id) => {})
                .catch((error) => {
                    console.log(error);
                });
            if (users.length > 0) {
                sendMessageNotification(
                    this.props.canvasId,
                    editedComment,
                    this.props.pinId,
                    users
                )
                    .then(() => {})
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } else {
            PinStore(this.props.canvasId).deleteComment(
                this.props.pinId,
                item.id
            );
        }
        this.setState({
            editedComment: "",
            editedCommentId: undefined,
        });
    }
    async addNewComment(neighboursUsers: UserInfo[]) {
        let newComment = this.state.newComment;
        try {
            let users = getUserNames(newComment, neighboursUsers);
            await PinStore(this.props.canvasId).addComment(
                this.props.pinId,
                newComment,
                users
            );
            PinInformationStore.setNewPinId(null);

            this.setState({ newComment: "" });

            if (users.length > 0) {
                sendMessageNotification(
                    this.props.canvasId,
                    newComment,
                    this.props.pinId,
                    users
                )
                    .then(() => {})
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        let neighboursUsers = ModuleUserGroupsStore(
            this.props.moduleId
        ).usersState;
        return (
            <Popup
                arrow={true}
                contentStyle={{ margin: 0 }}
                open={true}
                closeOnDocumentClick={true}
                onClose={this.props.onClose}
            >
                <Draggable
                    cancel="input, textarea"
                    position={{
                        x: this.state.x,
                        y: this.state.y,
                    }}
                    onDrag={() => {
                        this.drag = true;
                    }}
                    onStop={(evt, data) => {
                        if (this.drag) {
                            this.drag = false;
                            this.setState({
                                x: data.x,
                                y: data.y,
                            });
                        }
                    }}
                >
                    <div
                        className={cx(
                            "flex-simple-column",
                            styles.container,
                            "not-selectable-by-pointer"
                        )}
                        onClick={(evt) => {
                            evt.stopPropagation();
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                    >
                        {PinStore(this.props.canvasId).comments(
                            this.props.pinId
                        ).length === 0 && this.buildMainDropdownMenu()}
                        {PinStore(this.props.canvasId)
                            .comments(this.props.pinId)
                            .map((item, index) => {
                                let edited =
                                    item.id === this.state.editedCommentId;
                                return (
                                    <div
                                        key={index}
                                        className="flex-simple-column"
                                        style={{ padding: "10px" }}
                                    >
                                        <div
                                            className="my-row"
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <Avatar
                                                image={item.user_info.icon_url}
                                                user={new User(item.user_info)}
                                            />
                                            <div
                                                className="flex-simple-column"
                                                style={{
                                                    marginLeft: "14px",
                                                    flex: 1,
                                                }}
                                            >
                                                <div className="my-row">
                                                    <span
                                                        className={
                                                            styles.userNameTextStyle
                                                        }
                                                    >
                                                        {
                                                            item.user_info
                                                                .user_name
                                                        }
                                                    </span>
                                                    <span
                                                        className={
                                                            styles.timeTextStyle
                                                        }
                                                    >
                                                        {formatDate(
                                                            item.creation_time,
                                                            "added"
                                                        )}
                                                    </span>
                                                    <div
                                                        style={{ flexGrow: 1 }}
                                                    />
                                                    {this.buildDropdownMenu(
                                                        item,
                                                        index
                                                    )}
                                                </div>

                                                <MentionComponent
                                                    value={
                                                        this.state.editedComment
                                                    }
                                                    onChange={(value) => {
                                                        this.setState({
                                                            editedComment:
                                                                value,
                                                        });
                                                    }}
                                                    ref={(ref) => {
                                                        if (ref != null)
                                                            this.textAreaRefs[
                                                                item.id
                                                            ] = ref;
                                                    }}
                                                    users={neighboursUsers}
                                                >
                                                    <TextareaAutosize
                                                        className={
                                                            styles.editTextStyle
                                                        }
                                                        readOnly={!edited}
                                                        placeholder="Reply"
                                                        value={
                                                            edited
                                                                ? this.state
                                                                      .editedComment
                                                                : item.feedback
                                                        }
                                                        onKeyDown={(evt) => {
                                                            if (
                                                                evt.key ===
                                                                    "Enter" &&
                                                                !evt.shiftKey
                                                            ) {
                                                                evt.preventDefault();
                                                                this.editComment(
                                                                    item,
                                                                    neighboursUsers
                                                                );
                                                            }
                                                        }}
                                                        onChange={(evt) => {
                                                            let editedComment =
                                                                evt
                                                                    .currentTarget
                                                                    .value;
                                                            this.setState({
                                                                editedComment:
                                                                    editedComment,
                                                            });
                                                        }}
                                                        onBlur={(evt) => {
                                                            if (
                                                                evt.target.getAttribute(
                                                                    "preventBlur"
                                                                ) !== "true"
                                                            ) {
                                                                this.setState({
                                                                    editedComment:
                                                                        "",
                                                                    editedCommentId:
                                                                        undefined,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </MentionComponent>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        <MentionComponent
                            value={this.state.newComment}
                            onChange={(value) => {
                                this.setState({
                                    newComment: value,
                                });
                            }}
                            users={neighboursUsers}
                        >
                            <TextareaAutosize
                                className={cx(
                                    styles.editTextStyle,
                                    styles.newTextStyle
                                )}
                                placeholder="Reply"
                                value={this.state.newComment}
                                onChange={(evt) => {
                                    let newComment = evt.currentTarget.value;
                                    this.setState({
                                        newComment: newComment,
                                    });
                                }}
                                onKeyDown={(evt) => {
                                    if (evt.key === "Enter" && !evt.shiftKey) {
                                        evt.preventDefault();
                                        if (this.state.newComment) {
                                            evt.currentTarget.blur();
                                            this.addNewComment(neighboursUsers);
                                        }
                                    }
                                }}
                            />
                        </MentionComponent>
                    </div>
                </Draggable>
            </Popup>
        );
    }
}
