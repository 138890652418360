import React from "react";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import { observer } from "mobx-react";
import { Condition, ConditionsSelector } from "common/Conditions";
import Variables from "common/Variables";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";

interface Props {
    rowCount: number;
    dataScopeOption: DataScopeOption | null;
    conditions: Condition[] | null;
    onChangeDataScope: (dataScope: DataScopeOption) => void;
    onChangeConditions: (conditions: Condition[]) => void;
    onChangeRowCount: (rowCount: number) => void;
    currentModuleId: number | undefined;
}

const showEntriesOptions = [
    {
        label: "10",
        value: 10,
    },
    {
        label: "20",
        value: 20,
    },
    {
        label: "50",
        value: 50,
    },
    {
        label: "100",
        value: 100,
    },
];

@observer
class BackendTableSelector extends React.Component<Props> {
    render() {
        return (
            <div className="flex-simple-column">
                <div
                    className="my-row"
                    style={{
                        marginTop: "5px",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                >
                    <span
                        className="content-regular-text"
                        style={{
                            color: dataScienceElementsStyle.primaryTextColor,
                            marginRight: "5px",
                        }}
                    >
                        Select data
                    </span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                width: "15em",
                                height: "38px",
                                marginRight: "5px",
                            }),
                        }}
                        options={
                            this.props.currentModuleId != null
                                ? DataScopesForModules(
                                      this.props.currentModuleId
                                  ).dataScopesOptions
                                : []
                        }
                        onChange={(newValue) => {
                            this.props.onChangeDataScope(
                                newValue as DataScopeOption
                            );
                        }}
                        value={this.props.dataScopeOption}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />

                    <span
                        className="content-regular-text"
                        style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                            color: dataScienceElementsStyle.primaryTextColor,
                        }}
                    >
                        Show entries
                    </span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                width: "75px",
                                height: "38px",
                            }),
                        }}
                        options={showEntriesOptions}
                        onChange={(newValue) => {
                            this.props.onChangeRowCount(
                                (newValue as { label: string; value: number })
                                    .value
                            );
                        }}
                        value={{
                            label: String(this.props.rowCount),
                            value: this.props.rowCount,
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                </div>
                {this.props.dataScopeOption != null && (
                    <>
                        <ConditionsSelector
                            key={this.props.dataScopeOption.value}
                            style={{ paddingRight: "20px" }}
                            small
                            dataScopeId={this.props.dataScopeOption.value}
                            currentModuleId={this.props.currentModuleId}
                            value={this.props.conditions ?? undefined}
                            title={"Filters"}
                            onChange={(conditions) => {
                                this.props.onChangeConditions(conditions);
                            }}
                            allVariables={
                                Variables(
                                    this.props.dataScopeOption.value,
                                    this.props.currentModuleId ?? remoteModuleId
                                ).dataVariables
                            }
                            {...ConditionSelectorStyles}
                            titleStyle={{ backgroundColor: "transparent" }}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default BackendTableSelector;
