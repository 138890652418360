import * as React from "react";
import MyStripeForm from "./MyStripeForm";
import "./MyStripe.css";

import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getStripePublicKeyApi } from "common/StripeApi";
export { default as CreateProduct } from "./CreateProduct";

interface Props {
    linkId: string | number;
    ComponentIfSubscribed?: React.ReactNode;
    subscribeEndpoint: string;
    onPayment?: () => void;
    logoUrl?: string;
    brandName?: string;
    price: number;
}

export const MyStripePaywall = ({
    linkId,
    ComponentIfSubscribed,
    subscribeEndpoint,
    logoUrl,
    brandName,
    price,
    onPayment,
}: Props) => {
    const [stripeElements, setStripeElements] = React.useState<Stripe | null>(
        null
    );

    const [subscribed, setSubscribed] = React.useState(false);

    React.useEffect(() => {
        getStripePublicKeyApi()
            .then((publicKey) => {
                if (publicKey != null) {
                    loadStripe(publicKey)
                        .then((stripe) => {
                            setStripeElements(stripe);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div className={!subscribed ? "CheckoutContainer" : ""}>
            <Elements stripe={stripeElements}>
                {!subscribed ? (
                    <div className="ModalWrapper">
                        {logoUrl ? (
                            <div className="Logo">
                                <img
                                    className="LogoImage"
                                    src={logoUrl}
                                    alt=""
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="TitleContainer">
                            {brandName ? <p> Payment for {brandName} </p> : ""}
                        </div>
                        <MyStripeForm
                            linkId={linkId}
                            subscribeEndpoint={subscribeEndpoint}
                            ComponentIfSubscribed={ComponentIfSubscribed}
                            setParentSubscribed={setSubscribed}
                            price={price}
                            onPayment={onPayment}
                        />
                    </div>
                ) : (
                    <div>{ComponentIfSubscribed}</div>
                )}
            </Elements>
        </div>
    );
};
