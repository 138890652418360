import React from 'react'
import styles from "./Visualize.module.css"

const Visualize = () => {

    const box = [
        {
            imgPath: '/dist/img/data-exploration/dashboard.svg',
            imgAlt: 'dash',
            title: 'Presentation'
        },
        {
            imgPath: '/dist/img/data-exploration/icon.pak.svg',
            imgAlt: '',
            title: 'Dashboard'
        },
        {
            imgPath: '/dist/img/data-exploration/mobile.svg',
            imgAlt: 'mobile',
            title: 'Mobile App'
        },
    ]

    return (
        <div className={"mainContainer"} >
            <div className={styles.wrapper}>
                <h1 className={styles.head}>Go Build it!</h1>
                <p className={styles.paragraph}>
                    Now that you've finished loading & transforming datasets.Take thing up a notch by <br /> using them in a
                    <span className={styles.pre}> Presentation, dashboard, </span> or a <span className={styles.pre}>mobile app.</span>
                </p>

                <div className={styles.container}>
                    {
                        box.map((item => (
                            <div className={styles.box} >
                                <div>
                                    <img src={item.imgPath} style={{ height: '120px', width: '120px', margin: 70, transform: 'translate(0px,-25px)' }} alt={item.imgAlt} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', height: '30px' }}>
                                    <h3 style={{ transform: 'translate(0px,-50px)', fontSize: '18px', color: '#5e5c5c', fontWeight: 500 }}>{item.title} </h3>
                                </div>
                            </div>
                        )))
                    }
                </div>
            </div>
        </div>
    )
}

export default Visualize