import React from "react";
import { observable, computed, makeObservable } from "mobx";

import BaseSubmodule from "../../common/BaseSubmodule";
import GlobalContext from "GlobalContext";
import tables from "common/Tables";
import dataScopes from "common/DataScopes";

import modules from "./modules/module_list.json";

class CommonState {
    constructor() {
        makeObservable(this, {
            viewDataScopeState: observable,
            viewDataTableState: observable,
            viewDataTable: computed,
            viewDataScope: computed,
            viewDataScopeId: computed,
        });
    }

    viewDataScopeState = dataScopes.dataScopesOptions[0];
    viewDataTableState = undefined;

    get viewDataTable() {
        return this.viewDataTableState;
    }
    get viewDataScope() {
        return this.viewDataScopeState;
    }
    get viewDataScopeId() {
        if (this.viewDataScopeState) return this.viewDataScopeState.value;
        return undefined;
    }

    setViewDataTable(value) {
        this.viewDataTableState = value;
    }

    setViewDataScope(value) {
        this.viewDataScopeState = value;
        this.viewDataTableState = tables(
            value.value
        ).rawAndAggregateTableOptions[0];
    }
}

const commonState = new CommonState();

class MainComponent extends BaseSubmodule {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            submodules: [],
        };

        this.renderContents = this.renderContents.bind(this);
    }

    static getNumberOfSteps() {
        return modules.length;
    }

    static getTitle() {
        return "View";
    }

    componentDidMount() {
        let submodules = [];
        modules.forEach((dir) => {
            let mod = require(`./modules/${dir}/main.js`);
            submodules.push(mod);
        });

        this.setState({ submodules: submodules });
    }

    renderContents(globalContext) {
        let items = [];
        let submodule;
        let MainComponent;
        for (let index in this.state.submodules) {
            submodule = this.state.submodules[index];
            MainComponent = submodule.MainComponent;
            items.push(
                <MainComponent
                    index={this.props.index + Number(index)}
                    commonState={commonState}
                    disabled={
                        submodule.requirePermission != null &&
                        !(
                            submodule.requirePermission in
                            globalContext.permissions
                        )
                    }
                />
            );
        }
        return (
            <>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 13fr 1fr 3fr",
                        gridRowGap: "1em",
                        gridColumnGap: "1em",
                        alignItems: "center",
                    }}
                >
                    {items}
                </div>
            </>
        );
    }

    render() {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

export { MainComponent };
export let requirePermission = "ViewSection";
