import ItemInitializer from "../ItemInitializer";
import CanvasTreeStore from "../CanvasTreeStore";
import { ShapeType, ItemMetadata, NodePosition } from "common/Canvas";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import { Type, replaceSchema } from "common/InputData";
import { Permission } from "common/Permissions";
import DataScopes from "common/DataScopes";
import Tables from "common/Tables";
import { addOrEditModuleUserDataSetApi } from "common/ModulesApi";
import Cookies from "universal-cookie";

export function addNewItemUtil(
    itemInitializer: {
        type?: ItemInitializer;
        options?: any;
        gridOptions?: {
            gridRows: number;
            gridColumns: number;
            spreadsheetRowTitles: boolean;
            spreadsheetColumnTitles: boolean;
        };
    },
    canvasTreeStore: CanvasTreeStore,
    position?: {
        x: number;
        y: number;
        nodePosition?: NodePosition;
    },
    alignPosition?: boolean
): ItemMetadata | undefined {
    alignPosition = alignPosition ?? true;
    let scale = canvasTreeStore.scale;
    let layerRect = canvasTreeStore
        .layerRect(scale, PagesStore(PageType.Canvases).pages)
        .get();
    if (alignPosition && position != null) {
        const xyPosition = {
            x: position.x - layerRect.x * scale,
            y: position.y - layerRect.y * scale,
        };
        position = {
            ...xyPosition,
            nodePosition: {
                desktop: xyPosition,
                mobile: xyPosition,
            },
        };
        position.x = position.x / scale;
        position.y = position.y / scale;
        position.nodePosition![canvasTreeStore.canvasViewMode].x =
            position.x / scale;
        position.nodePosition![canvasTreeStore.canvasViewMode].y =
            position.y / scale;
    }
    let options: any = {
        ...position,
        zIndex: canvasTreeStore.maxZIndex() + 1,
    };
    let newId: ItemMetadata["id"] | undefined = undefined;
    let newType: ItemMetadata["type"] | undefined = undefined;
    switch (itemInitializer?.type) {
        case ItemInitializer.FlowChart:
            newId = canvasTreeStore.addNodeAction(undefined, options);
            newType = "canvasTreeState";
            break;
        case ItemInitializer.Tag:
            newId = canvasTreeStore.addTextBoxAction({
                text: "",
                ...options,
            });
            newType = "canvasTreeState";
            break;
        case ItemInitializer.Input:
            newId = canvasTreeStore.addNumericInputAction({
                ...options,
                shapeOptions: {
                    desktop: {
                        scaleX: 1,
                        scaleY: 1,
                    },
                    mobile: {
                        scaleX: 1,
                        scaleY: 1,
                    },
                },
            });
            newType = "canvasTreeState";

            break;
        case ItemInitializer.DataInputButton:
            newId = canvasTreeStore.addCanvasButtonAction({
                ...options,
            });
            newType = "buttonsState";

            break;
        case ItemInitializer.SubmitButton:
            newId = canvasTreeStore.addSubmitButtonAction({
                ...options,
                shapeOptions: {
                    desktop: {
                        scaleX: 1,
                        scaleY: 1,
                    },
                    mobile: {
                        scaleX: 1,
                        scaleY: 1,
                    },
                },
            });
            newType = "canvasTreeState";

            break;
        case ItemInitializer.Slider:
            newId = canvasTreeStore.addSliderInputAction(options);
            newType = "canvasTreeState";

            break;
        case ItemInitializer.ProgressElement:
            newId = canvasTreeStore.addProgressElementAction(options);
            newType = "canvasTreeState";

            break;
        case ItemInitializer.Toggle:
            newId = canvasTreeStore.addToggleInputAction(options);
            newType = "canvasTreeState";

            break;
        case ItemInitializer.RadioButtonsGroup:
            newId = canvasTreeStore.addRadioButtonsGroupAction(options);
            newType = "canvasTreeState";

            break;
        case ItemInitializer.DropdownSelector:
            newId = canvasTreeStore.addDropdownSelectorInputAction(options);
            newType = "canvasTreeState";

            break;
        case ItemInitializer.Filter:
            newId = canvasTreeStore.addFilterInputAction(options);
            newType = "canvasTreeState";
            break;
        case ItemInitializer.BarcodeReader:
            newId = canvasTreeStore.addBarcodeReaderInput({
                ...options,
            });
            newType = "canvasTreeState";

            break;
        case ItemInitializer.Survey:
            newId = undefined;
            newType = "canvasTreeState";

            /* Create a new data set for the survey */
            replaceSchema(
                [
                    "Timestamp",
                    "Question 1...",
                    "Question 2...",
                    "Question 3...",
                ],
                [Type.Datetime, Type.Str, Type.Str, Type.Str],
                ["", "", "", ""],
                ["regular", "regular", "regular", "regular"],
                ["%m/%d/%y %I:%M %p", null, null, null],
                [null, null, null, null],
                [true, true, true, true],
                {
                    label: "survey",
                    value: NaN,
                    permissionType: Permission.ReadWrite,
                },
                true,
                undefined,
                async (id: string | number) => {
                    // Create the survey
                    await DataScopes.update();
                    canvasTreeStore.addSurveyElement({
                        backendOutput: {
                            dataScopeOption: DataScopes.dataScopesOptions.find(
                                (option) => option.value === id
                            )!,
                            tableOption: Tables(id).tableToOption(),
                            variableOptions: [
                                {
                                    node: null,
                                    variable: null,
                                },
                            ],
                        },
                    });

                    // Share it in the presentation
                    let updateId = new Cookies().get(
                        "instrumentation_session_id"
                    );
                    if (canvasTreeStore.moduleId != null) {
                        addOrEditModuleUserDataSetApi(
                            canvasTreeStore.moduleId,
                            id,
                            Permission.ReadWrite,
                            true,
                            false,
                            true,
                            canvasTreeStore.canvasId,
                            updateId
                        );
                    }
                },
                (error) => {
                    console.log(error);
                }
            );

            break;
        case ItemInitializer.SimpleSpreadSheetGrid:
            let gridOptions = itemInitializer.options?.gridOptions ?? {
                gridRows: 3,
                gridColumns: 3,
                spreadsheetRowTitles: false,
                spreadsheetColumnTitles: true,
            };
            options = {
                ...options,
                ...itemInitializer.options,
            };
            delete options["gridOptions"];
            newId = canvasTreeStore.addSpreadSheetGridAction(
                gridOptions.gridRows,
                gridOptions.gridColumns,
                gridOptions.spreadsheetRowTitles,
                gridOptions.spreadsheetColumnTitles,
                true,
                options
            );
            newType = "gridsState";

            break;
        case ItemInitializer.Shape:
            let subtype = itemInitializer.options?.subtype;
            newId = canvasTreeStore.addShapeElementAction({
                shapeType: ShapeType[subtype],
                shapeStyle: { fillColor: "#000" },
                ...options,
            });
            newType = "shapeElementsState";

            break;
        case ItemInitializer.Dashboard:
            newId = canvasTreeStore.addEmptyDashboardAction({
                ...options,
                ...itemInitializer.options,
            });
            newType = "dashboardsState";
            break;
        case ItemInitializer.BackendTable:
            newId = canvasTreeStore.addEmptyBackendTableAction(options);
            newType = "backendTablesState";

            break;
        case ItemInitializer.Questionnaire:
            newId = canvasTreeStore.addEmptyQuestionnaireElementAction(options);
            newType = "questionnaireElementsState";

            break;
        case ItemInitializer.MergeData:
            newId = canvasTreeStore.addEmptyMergeDataElementAction(options);
            newType = "mergeDataElementsState";

            break;
        case ItemInitializer.AggregateData:
            newId = canvasTreeStore.addEmptyAggregateTableElementAction(
                options
            );
            newType = "aggregateTableElementsState";

            break;
        case ItemInitializer.ManageData:
            newId = canvasTreeStore.addEmptyManageTableElementAction(options);
            newType = "manageTableElementsState";

            break;
        case ItemInitializer.Map:
            newId = canvasTreeStore.addEmptyMapElementAction({
                ...options,
                ...itemInitializer.options,
            });
            newType = "mapElementsState";

            break;
        case ItemInitializer.Graph:
            newId = canvasTreeStore.addEmptyGraphElementAction({
                ...options,
                ...itemInitializer.options,
            });
            newType = "graphElementsState";

            break;
        case ItemInitializer.EmbedUrl:
            options = {
                ...options,
                ...itemInitializer.options,
            };
            newId = canvasTreeStore.addEmptyEmbedUrlElementAction(options);
            newType = "embedUrlElementsState";
            break;
        default:
            break;
    }
    canvasTreeStore.setCanvasWasEditedSate(true);
    if (newId == null || newType == null) return undefined;
    return {
        id: newId,
        type: newType,
        groupId: null,
    };
}
