import React from "react";
import Switch from "react-switch";
import Accordion from "react-bootstrap/Accordion";
import cx from "classnames";

import {
    MapFinding,
    defaultChoroplethMinColor,
    defaultChoroplethMaxColor,
    defaultChoroplethMiddleColor,
    defaultHeatmapMinOpacity,
    defaultHeatmapBlur,
    defaultHeatmapMaxZoom,
    defaultHeatmapRadius,
    defaultHeatmapMaxPointIntensity,
    defaultChoroplethOpacity,
    choroplethLevelLabel,
    ChoroplethLevel,
    defaultHeatmapGradient,
} from "common/Finding";
import styles from "./MapSection.module.css";
import stylingSectionStyles from "../StylingSection.module.css";
import commonStyles from "../StylingSection.module.css";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import MarkerConfig from "./MarkerConfig";
import TooltipConfig from "./TooltipConfig";
import Input from "../Input";
import GradientPicker from "./GradientPicker";
import { observer } from "mobx-react";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import Variables, { VariableOption } from "common/Variables";
import Select from "react-select";
import { CanvasDropdownSelector, isDropdownSelector } from "common/Canvas";
import { DynamicOptionType } from "common/DynamicOptions";
import StringOption from "common/StringOption";
import ColorPicker from "common/ColorPicker";
import FlagsConfig from "./MarkerConfig/FlagsConfig";
import { getCustomSelectStyleLight } from "common/SelectStyles";

enum AccordionMenu {
    None = 0,
    Marker = 1,
    Tooltip = 2,
}

interface Props {
    showCenteringOption?: boolean;
    showTableOption?: boolean;
    dashboardId: string;
    canvasTreeStore: CanvasTreeStore;
    finding: MapFinding;
    onChange: (config: MapFinding["config"], updateData?: boolean) => void;
    onClearEditing: () => void;
    currentModuleId?: number;
    currentZoomLevel?: number; // currently unused
}

const levelOptions: ReadonlyArray<StringOption> = Object.entries(
    choroplethLevelLabel
).map(([value, label]) => ({ label: label, value: value }));

function MapSection({
    finding,
    onChange,
    onClearEditing,
    currentModuleId,
    currentZoomLevel,
    dashboardId,
    showCenteringOption,
    showTableOption,
    canvasTreeStore,
}: Props) {
    let variableOptions: VariableOption[] = [];
    if (finding.config.dataScope != null) {
        variableOptions = Variables(
            finding.config.dataScope.value,
            currentModuleId
        ).variableOptions;
    }

    const [openedAccordionMenu, setOpenedAccordionMenu] = React.useState(
        AccordionMenu.None
    );
    // Backward comp... because earlier we have just min, middle and max colors instead gradient option
    const defaultChoroplethGradient = !finding.config?.heatmapGradient
        ? [
              {
                  label: "min",
                  value: 0,
                  color:
                      finding.config.choroplethMinColor ??
                      defaultChoroplethMinColor,
              },
              {
                  label: "middle",
                  value: 0.5,
                  color:
                      finding.config.choroplethMiddleColor ??
                      defaultChoroplethMiddleColor,
              },
              {
                  label: "max",
                  value: 1,
                  color:
                      finding.config.choroplethMaxColor ??
                      defaultChoroplethMaxColor,
              },
          ]
        : finding.config?.heatmapGradient;
    return (
        <div>
            {!finding.config.isAdditional && (
                <div className={styles.stylesMenu}>
                    <div className={styles.stylesMenuOption}>
                        <span className={styles.switchLabel}>
                            Lock Map Area
                        </span>
                        <Switch
                            onChange={(checked) => {
                                let newConfig: MapFinding["config"] = {
                                    ...finding.config,
                                };

                                if (finding.config.colorOptions) {
                                    newConfig.colorOptions = {
                                        ...finding.config.colorOptions,
                                    };
                                } else {
                                    newConfig.colorOptions = {
                                        borderShadow: false,
                                        fillColor: "",
                                        borderColor: "",
                                    };
                                }

                                newConfig.colorOptions.lockMap = checked;

                                onChange(newConfig);
                            }}
                            checked={
                                finding.config.colorOptions?.lockMap ?? false
                            }
                            width={26}
                            height={13}
                            offColor="#C4C4C4"
                            onColor="#3B82C9"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#7B7B7B"
                            onHandleColor="#3B82C9"
                            className={cx({
                                [styles.switchOn]:
                                    finding.config.colorOptions?.lockMap,
                            })}
                        />
                    </div>
                    <div className={styles.stylesMenuOption}>
                        <span className={styles.switchLabel}>
                            Prevent zooming above current level
                        </span>
                        <Switch
                            onChange={(checked) => {
                                let newConfig: MapFinding["config"] = {
                                    ...finding.config,
                                };

                                if (finding.config.colorOptions) {
                                    newConfig.colorOptions = {
                                        ...finding.config.colorOptions,
                                    };
                                } else {
                                    newConfig.colorOptions = {
                                        borderShadow: false,
                                        fillColor: "",
                                        borderColor: "",
                                    };
                                }

                                newConfig.colorOptions.minZoomLevel = checked
                                    ? currentZoomLevel
                                    : null;

                                onChange(newConfig);
                            }}
                            disabled={currentZoomLevel == null}
                            checked={
                                !!finding.config.colorOptions?.minZoomLevel
                            }
                            width={26}
                            height={13}
                            offColor="#C4C4C4"
                            onColor="#3B82C9"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#7B7B7B"
                            onHandleColor="#3B82C9"
                            className={cx({
                                [styles.switchOn]:
                                    finding.config.colorOptions?.minZoomLevel,
                            })}
                        />
                    </div>
                    {showCenteringOption && (
                        <div
                            className={styles.stylesMenuOption}
                            style={{ flexDirection: "column" }}
                        >
                            <span className={styles.switchLabel}>
                                Allow users to choose center location on map by
                                variable
                            </span>
                            <Select
                                isClearable
                                placeholder="Select center variable"
                                styles={{
                                    ...getCustomSelectStyleLight(
                                        14,
                                        false
                                    ),
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: 60,
                                        marginTop: 10,
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: 25,
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        marginLeft: 10,
                                    }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        marginLeft: 10,
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        marginLeft: 10,
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none",
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={variableOptions}
                                onChange={(newValue) => {
                                    let referenceNode:
                                        | CanvasDropdownSelector
                                        | undefined = undefined;
                                    canvasTreeStore.canvasTreeState.forEach(
                                        (node) => {
                                            if (
                                                isDropdownSelector(node) &&
                                                node.dynamicOption
                                                    ?.dashboardId ===
                                                    dashboardId
                                            ) {
                                                referenceNode = node;
                                            }
                                        }
                                    );
                                    let active = referenceNode != null;
                                    let centerVariable = (newValue as VariableOption | null)
                                        ?.label;
                                    let centerVariableIndex = (newValue as VariableOption | null)
                                        ?.value;
                                    if (active && centerVariableIndex == null) {
                                        onClearEditing();
                                        canvasTreeStore.deleteNodeAction(
                                            referenceNode!.id
                                        );
                                    } else if (!active) {
                                        canvasTreeStore.addDropdownSelectorInputAction(
                                            {
                                                dynamicOption: {
                                                    key: "centerVariableValue",
                                                    dashboardId: dashboardId,
                                                    title: "Center map",
                                                    type:
                                                        DynamicOptionType.CenterMapByValue,
                                                    allowMultiple: false,
                                                },
                                            }
                                        );
                                    }
                                    onChange(
                                        {
                                            ...finding.config,
                                            centerVariableIndex:
                                                centerVariableIndex ?? null,
                                            centerVariable:
                                                centerVariable ?? null,
                                            centerVariableValue: null,
                                        },
                                        true
                                    );
                                }}
                                value={
                                    variableOptions.find(
                                        (option) =>
                                            option.value ===
                                            finding.config.centerVariableIndex
                                    ) ?? null
                                }
                            />
                        </div>
                    )}
                    {showTableOption && (
                        <div className={styles.stylesMenuOption}>
                            <span className={styles.switchLabel}>
                                Show table
                            </span>
                            <Switch
                                onChange={(checked) => {
                                    let newConfig: MapFinding["config"] = {
                                        ...finding.config,
                                    };
                                    newConfig.showTable = checked;
                                    onChange(newConfig);
                                }}
                                checked={finding.config.showTable ?? false}
                                width={26}
                                height={13}
                                offColor="#C4C4C4"
                                onColor="#3B82C9"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#7B7B7B"
                                onHandleColor="#3B82C9"
                                className={cx({
                                    [styles.switchOn]: finding.config.showTable,
                                })}
                            />
                        </div>
                    )}
                    <div className={styles.stylesMenuOption}>
                        <span className={styles.switchLabel}>Grayscale</span>
                        <Switch
                            onChange={(checked) => {
                                let newConfig: MapFinding["config"] = {
                                    ...finding.config,
                                };

                                if (finding.config.colorOptions) {
                                    newConfig.colorOptions = {
                                        ...finding.config.colorOptions,
                                    };
                                } else {
                                    newConfig.colorOptions = {
                                        borderShadow: false,
                                        fillColor: "",
                                        borderColor: "",
                                    };
                                }

                                newConfig.colorOptions.grayscale = checked;

                                onChange(newConfig);
                            }}
                            checked={
                                finding.config.colorOptions?.grayscale ?? false
                            }
                            width={26}
                            height={13}
                            offColor="#C4C4C4"
                            onColor="#3B82C9"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#7B7B7B"
                            onHandleColor="#3B82C9"
                            className={cx({
                                [styles.switchOn]:
                                    finding.config.colorOptions?.grayscale,
                            })}
                        />
                    </div>
                    {finding.type === "maps_choropleth" && (
                        <>
                            <div className={styles.stylesMenuOption}>
                                <span className={styles.switchLabel}>
                                    Show borders
                                </span>
                                <Switch
                                    onChange={(checked) => {
                                        let newConfig: MapFinding["config"] = {
                                            ...finding.config,
                                        };
                                        newConfig.choroplethShowBorders = checked;
                                        onChange(newConfig);
                                    }}
                                    checked={
                                        finding.config?.choroplethShowBorders ??
                                        false
                                    }
                                    width={26}
                                    height={13}
                                    offColor="#C4C4C4"
                                    onColor="#3B82C9"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#7B7B7B"
                                    onHandleColor="#3B82C9"
                                    className={cx({
                                        [styles.switchOn]:
                                            finding.config
                                                .choroplethShowBorders,
                                    })}
                                />
                            </div>
                            <div className={styles.stylesMenuOption}>
                                <span className={styles.switchLabel}>
                                    Choropleth border thickness
                                </span>
                                <Input
                                    value={
                                        finding.config.choroplethBorder
                                            ?.thickness ?? 1
                                    }
                                    onChange={(value: string | number) => {
                                        value = Number(value);
                                        let newConfig = {
                                            ...finding.config,
                                            choroplethBorder: {
                                                ...finding.config
                                                    .choroplethBorder,
                                                thickness: value,
                                            },
                                        };
                                        onChange(newConfig);
                                    }}
                                />
                            </div>
                            <div className={styles.stylesMenuOption}>
                                <span className={styles.switchLabel}>
                                    Choropleth border color
                                </span>
                                <ColorPicker
                                    value={
                                        finding.config.choroplethBorder
                                            ?.color ?? "rgb(17, 95, 78)"
                                    }
                                    onChange={(newValue) => {
                                        onChange({
                                            ...finding.config,
                                            choroplethBorder: {
                                                ...finding.config
                                                    .choroplethBorder,
                                                color: newValue,
                                            },
                                        });
                                    }}
                                    style={{
                                        marginLeft: "8px",
                                        borderRadius: "4px",
                                        width: "32px",
                                        height: "32px",
                                    }}
                                    inPopup
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
            {finding.type === "maps_choropleth" && (
                <div className={commonStyles.sectionContainer}>
                    <GradientPicker
                        finding={finding}
                        onChange={onChange}
                        defaultGradient={defaultChoroplethGradient}
                    />
                    <div className={commonStyles.optionContainer}>
                        <span className={commonStyles.optionName}>Opacity</span>
                        <Input
                            value={
                                finding.config.choroplethOpacity ??
                                defaultChoroplethOpacity
                            }
                            onChange={(value: string | number) => {
                                value = Number(value);
                                let newConfig = {
                                    ...finding.config,
                                    choroplethOpacity: value,
                                };
                                onChange(newConfig);
                            }}
                            step={0.1}
                            maxValue={1}
                        />
                    </div>
                </div>
            )}

            {finding.type === "maps_heatmap" && (
                <div className={commonStyles.sectionContainer}>
                    <GradientPicker
                        finding={finding}
                        onChange={onChange}
                        defaultGradient={defaultHeatmapGradient}
                    />
                    <div className={commonStyles.optionContainer}>
                        <span className={commonStyles.optionName}>Blur</span>
                        <Input
                            value={
                                finding.config.heatmapBlur ?? defaultHeatmapBlur
                            }
                            onChange={(value: string | number) => {
                                value = Number(value);
                                let newConfig = {
                                    ...finding.config,
                                    heatmapBlur: value,
                                };
                                onChange(newConfig);
                            }}
                        />
                    </div>
                    <div className={commonStyles.optionContainer}>
                        <span className={commonStyles.optionName}>Radius</span>
                        <Input
                            value={
                                finding.config.heatmapRadius ??
                                defaultHeatmapRadius
                            }
                            onChange={(value: string | number) => {
                                value = Number(value);
                                let newConfig = {
                                    ...finding.config,
                                    heatmapRadius: value,
                                };
                                onChange(newConfig);
                            }}
                        />
                    </div>
                    <div className={commonStyles.optionContainer}>
                        <span className={commonStyles.optionName}>Max</span>
                        <Input
                            value={
                                finding.config.heatmapMaxPointIntensity ??
                                defaultHeatmapMaxPointIntensity
                            }
                            onChange={(value: string | number) => {
                                value = Number(value);
                                let newConfig = {
                                    ...finding.config,
                                    heatmapMaxPointIntensity: value,
                                };
                                onChange(newConfig);
                            }}
                        />
                    </div>
                    <div className={commonStyles.optionContainer}>
                        <span className={commonStyles.optionName}>
                            Max Zoom
                        </span>
                        <Input
                            value={
                                finding.config.heatmapMaxZoom ??
                                defaultHeatmapMaxZoom
                            }
                            onChange={(value: string | number) => {
                                value = Number(value);
                                let newConfig = {
                                    ...finding.config,
                                    heatmapMaxZoom: value,
                                };
                                onChange(newConfig);
                            }}
                        />
                    </div>
                    <div className={commonStyles.optionContainer}>
                        <span className={commonStyles.optionName}>
                            Min Opacity
                        </span>
                        <Input
                            value={
                                finding.config.heatmapMinOpacity ??
                                defaultHeatmapMinOpacity
                            }
                            onChange={(value: string | number) => {
                                value = Number(value);
                                let newConfig = {
                                    ...finding.config,
                                    heatmapMinOpacity: value,
                                };
                                onChange(newConfig);
                            }}
                            step={0.1}
                            maxValue={1}
                        />
                    </div>
                </div>
            )}
            {finding.type === "maps_bubble" && (
                <>
                    <div className={styles.stylesMenuOption}>
                        <span className={styles.switchLabel}>Boundaries</span>
                        <Switch
                            onChange={(checked) => {
                                let newConfig: MapFinding["config"] = {
                                    ...finding.config,
                                };
                                newConfig.showBoundaries = checked;
                                onChange(newConfig, true);
                            }}
                            checked={!!finding.config.showBoundaries}
                            width={26}
                            height={13}
                            offColor="#C4C4C4"
                            onColor="#3B82C9"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#7B7B7B"
                            onHandleColor="#3B82C9"
                            className={cx({
                                [styles.switchOn]:
                                    finding.config.showBoundaries,
                            })}
                        />
                    </div>

                    {finding.config.showBoundaries && (
                        <>
                            <div
                                className={stylingSectionStyles.optionContainer}
                            >
                                <span
                                    className={stylingSectionStyles.optionName}
                                >
                                    Boundaries
                                </span>
                                <Select
                                    placeholder=""
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: 4,
                                        }),
                                        container: (base) => ({
                                            ...base,
                                            flexGrow: 1,
                                        }),
                                        indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none",
                                        }),
                                        menu: (base) => ({
                                            ...base,
                                            zIndex: 100000000,
                                        }),
                                    }}
                                    options={levelOptions}
                                    onChange={(newValue) => {
                                        onChange(
                                            {
                                                ...finding.config,
                                                choroplethLevel: (newValue as StringOption | null)
                                                    ?.value,
                                            },
                                            true
                                        );
                                    }}
                                    value={levelOptions.find(
                                        (option) =>
                                            option.value ===
                                            (finding.config.choroplethLevel ??
                                                "country")
                                    )}
                                />
                            </div>
                            <div
                                className={stylingSectionStyles.optionContainer}
                            >
                                <span
                                    className={stylingSectionStyles.optionName}
                                >
                                    {
                                        choroplethLevelLabel[
                                            (finding.config?.choroplethLevel ??
                                                "country") as ChoroplethLevel
                                        ]
                                    }
                                </span>
                                <Select
                                    placeholder=""
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: 4,
                                        }),
                                        container: (base) => ({
                                            ...base,
                                            flexGrow: 1,
                                        }),
                                        indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none",
                                        }),
                                        menu: (base) => ({
                                            ...base,
                                            zIndex: 100000000,
                                        }),
                                    }}
                                    options={variableOptions}
                                    onChange={(newValue) => {
                                        const newFinding: MapFinding = {
                                            ...finding.config,
                                            choroplethCountryVariable: (newValue as VariableOption | null)
                                                ?.label,
                                            choroplethCountryVariableIndex: (newValue as VariableOption | null)
                                                ?.value,
                                        };
                                        onChange(newFinding, true);
                                    }}
                                    value={
                                        finding.config
                                            .choroplethCountryVariableIndex !=
                                        null
                                            ? {
                                                  label:
                                                      finding.config
                                                          .choroplethCountryVariable,
                                                  value:
                                                      finding.config
                                                          .choroplethCountryVariableIndex,
                                              }
                                            : null
                                    }
                                />
                            </div>
                        </>
                    )}
                </>
            )}

            <div>
                <Accordion
                    activeKey={String(openedAccordionMenu)}
                    onSelect={(event: any) => {
                        setOpenedAccordionMenu(Number(event));
                    }}
                >
                    {(finding.type === "maps_pins" ||
                        finding.type === "maps_bubble") && (
                        <Accordion.Toggle
                            eventKey={String(AccordionMenu.Marker)}
                            className={styles.accordionMenu}
                        >
                            Marker
                            <ChevronIcon
                                className={cx(styles.accordionMenuIcon, {
                                    [styles.chevronOpen]:
                                        openedAccordionMenu ===
                                        AccordionMenu.Marker,
                                })}
                            />
                        </Accordion.Toggle>
                    )}
                    <Accordion.Collapse eventKey={String(AccordionMenu.Marker)}>
                        <>
                            <MarkerConfig
                                mapFinding={finding}
                                onChange={onChange}
                                currentModuleId={currentModuleId}
                            />
                            {finding.type === "maps_pins" && (
                                <FlagsConfig
                                    mapFinding={finding}
                                    onChange={onChange}
                                    currentModuleId={currentModuleId}
                                />
                            )}
                        </>
                    </Accordion.Collapse>
                    <Accordion.Toggle
                        eventKey={String(AccordionMenu.Tooltip)}
                        className={styles.accordionMenu}
                    >
                        Tooltip
                        <ChevronIcon
                            className={cx(styles.accordionMenuIcon, {
                                [styles.chevronOpen]:
                                    openedAccordionMenu ===
                                    AccordionMenu.Tooltip,
                            })}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(AccordionMenu.Tooltip)}
                    >
                        <TooltipConfig
                            variableOptions={variableOptions}
                            mapFinding={finding}
                            mapId={dashboardId}
                            onChange={onChange}
                        />
                    </Accordion.Collapse>
                </Accordion>
            </div>
        </div>
    );
}

export default observer(MapSection);
