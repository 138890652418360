import React from "react";

const icons: { [id: string]: any } = {
  Dashboard: require("common/section_icons/dashboard.jsx").MySvg,

  Stories: require("common/section_icons/stories.jsx").MySvg,
  Data: require("common/section_icons/data.jsx").MySvg,
  Processing: require("common/section_icons/processing.jsx").MySvg,
  Exploration: require("common/section_icons/exploration.jsx").MySvg,
  Settings: require("common/section_icons/settings.jsx").MySvg,

  Recipes: require("common/section_icons/recipes.jsx").MySvg,
  KPIStream: require("common/section_icons/kpistream.jsx").MySvg,
  Forecasting: require("common/section_icons/forecasting.jsx").MySvg,
  Canvas: require("common/section_icons/canvas.jsx").MySvg,
  Usage: require("common/section_icons/usage.jsx").MySvg,
  Modules: require("common/section_icons/modules.jsx").MySvg,
  Home: require("common/section_icons/home.jsx").MySvg,

};

interface Props {
  type: keyof typeof icons;
  options: any;
}

class SectionIconsContainer extends React.Component<Props> {
  render() {
    let Component = icons[this.props.type];
    if (Component == null) return null;
    return <Component {...this.props.options} />;
  }
}

export default SectionIconsContainer;
