import React, { Component } from "react";
import { Button } from "react-bootstrap";

import axios from "common/ServerConnection";
import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";

interface State {
    status: UpdateStatus;
    errorMessage: string;
    downloadLinkBackup: string | null;
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            downloadLinkBackup: null
        };
        this.performance = null;
    }

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs: number = new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    render() {
        return (
            <div style={{ marginTop: 20 }}>
                <span className="big-title-span">
                    Save and Restore Models
                </span>
                <div
                    style={{ marginTop: 10, width: 500 }}
                    className="flex-simple-column"
                >
                    <label
                        htmlFor="file-upload-models"
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            marginLeft: "19px",
                            marginBottom: 10,
                            width: 200,
                        }}
                    >
                        Load
                    </label>
                    <input
                        id="file-upload-models"
                        type="file"
                        accept=".zip"
                        onChange={(evt) => {
                            if (evt.target.files != null && evt.target.files[0]) {
                                let localPerformance: Date = new Date();
                                this.setState({
                                    status: UpdateStatus.Loading
                                });
                                var formData = new FormData();
                                formData.append(
                                    "models",
                                    evt.target.files[0]
                                );
                                axios
                                    .post<{
                                        success: boolean;
                                        error_msg: string;
                                    }>(
                                        "/api/import_models",
                                        formData,
                                        {
                                            headers: {
                                                "Content-Type":
                                                    "multipart/form-data",
                                            },
                                        }
                                    )
                                    .then((response) => {
                                        this.performance = localPerformance;
                                        if (
                                            response.data
                                                .success
                                        ) {
                                            this.setState({
                                                status: UpdateStatus.Success,
                                            });
                                        } else {
                                            this.setState({
                                                errorMessage:
                                                    response.data.error_msg,
                                                status: UpdateStatus.Error
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        this.performance = localPerformance;
                                        this.setState({
                                            status: UpdateStatus.Error,
                                            errorMessage: `${error.response.status} ${error.response.statusText}`
                                        });
                                    });
                            }
                        }}
                        style={{
                            display: "none",
                        }}
                    />
                    <form
                        action="/api/export_models"
                        method="GET"
                    >
                        <Button
                            type="submit"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginLeft: "19px",
                                marginBottom: 10,
                                width: 200,
                            }}
                        >
                            Save
                        </Button>
                    </form>
                </div>
                <UpdateStatusAlert
                    value={this.state.status}
                    onChange={(status: UpdateStatus) =>
                        this.setState({status: status})
                    }
                    errorMessage={this.state.errorMessage}
                />
            </div>
        );
    }
}

export { MainComponent };
export let requirePermission = "SaveRestoreModels";
