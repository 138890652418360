import React from "react";
import TimeTrendPlotComponent from "common/graphics/TimeTrendPlotComponent";

function MainComponent(props) {
	let finding = props.finding;
	return (
		<div style={{ width: "100%", height: "100%" }}>
			<TimeTrendPlotComponent
				config={finding.config}
				content={finding.content}
			/>
		</div>
	);
}

export { MainComponent };