import React from "react";
import OutcomeCurveComponent from "common/graphics/OutcomeCurveComponent";

function MainComponent(props) {
	let finding = props.finding;
	return (
		<div style={{ width: "100%", height: "100%" }}>
			<OutcomeCurveComponent
				showMarginals={finding.content.showMarginals}
				regressionResult={finding.content.regressionResult}
			/>
		</div>
	);
}

export { MainComponent };