import { makeAutoObservable, runInAction} from "mobx";

/* for backend API calls */
import axios from "common/ServerConnection";

/* Pull user information */
import CurrentUser from "common/CurrentUser";
import { getRawDataApi }  from "common/DataApi";

interface TableJson {
  table_header: string[];
  table_content: {[key: string]: string | number }[];  
}


interface OllamaApiResponse {
    model: string;
    created_at: string;
    response: string;
    text: string;
    vid: string;
    pic: string;
    waiting: boolean;
    done: boolean;
    questions: string[];
    table_json: TableJson;
    render_table: boolean;
  }

interface llmResponse {
  success: boolean;
  error_msg: string;
  payload: OllamaApiResponse;    
}

class Store {
    userInput = "";
    prompts: string[] = [""];
    answers: string[] = [""        
    ];
    textDisplay = "";
    picURL = "";
    vidURL = "";
    renderCustom = false;
    userName = CurrentUser.info?.user_name?? "";

    myQuestions: string[] = [];  // Added to handle multiple questions
    
    currentQuestionIndex: number = 0;
    waitingForAnswers: boolean = false;

    // Initial Messages
    dayMsgInit: string = "";
    weekMsgInit: string = "";
    monthMsgInit: string = "";
    quarterMsgInit: string = "";

    currProgress: number = 0.0;
    yearGoal: number = 0.0;

    setDayMsgInit = (msg: string): void => {
      this.dayMsgInit = msg;
    }

    setWeekMsgInit = (msg: string): void => {
      this.weekMsgInit  = msg;
    }

    setMonthMsgInit = (msg: string): void => {
      this.monthMsgInit   = msg;
    }

    setQuarterMsgInit = (msg: string): void => {
      this.quarterMsgInit    = msg;
    }
    
    setCurrProgress = (msg: number): void => {
      this.currProgress = msg;
    } 

    setYearGoal = (msg: number): void => {
      this.yearGoal = msg;
    } 

    // Function to calculate greeting based on local time and return as string
    getTimeGreeting(): string {
        const now = new Date();
        const hour = now.getHours();

        if (hour < 12) {
            return "morning";
        } else if (hour < 18) {
            return "afternoon";
        } else {
            return "evening";
        }
    }

    constructor() {
      makeAutoObservable(this);            
      this.initialize().then(() => {
        this.setAnswers(this.dayMsgInit);
      });       
      //this.setAnswers(tmp_day_msg);
    }   

    async initialize() {
      const { dayMsgInit, weekMsgInit, monthMsgInit, quarterMsgInit, currProgress, yearGoal } = await this.loadInitialMessage();

      // Use MobX's runInAction to update the observable state
      runInAction(() => {
          this.dayMsgInit = dayMsgInit.replace(/\\n/g, '\n');  // TODO: This is a hack to get the new line character to work. Need to change.  
          this.weekMsgInit = weekMsgInit.replace(/\\n/g, '\n');
          this.monthMsgInit = monthMsgInit.replace(/\\n/g, '\n');
          this.quarterMsgInit = quarterMsgInit.replace(/\\n/g, '\n');
          this.currProgress = currProgress;
          this.yearGoal = yearGoal;
      });      
    }

     // Async function that loads the initial messages and sets the state
      async loadInitialMessage(): Promise<{dayMsgInit: string, weekMsgInit: string, monthMsgInit: string, quarterMsgInit: string, currProgress: number, yearGoal: number}> {
        
        let tmp_day_msg = `## Good ${this.getTimeGreeting()}, `;

        console.log("tmp_day_msg:", tmp_day_msg);                

        // Simulate async operations
        // Given table_name = "schedule_framework" & user_name, spit back out a hard-coded text from the four columns of the table.  
        let curr_data_table_idx = (await axios.post<{
          success: boolean;
          error_msg: string;
          result: number;
          }>("/api/get_data_table_idx", {
          data_table_name: "schedule_framework",
          user: CurrentUser.info?.user_name,
          })).data.result;
        
        let loading_text = await getRawDataApi(
            {
                label: "",
                value: [],
                data_table_idx: curr_data_table_idx,
                optimized: false,
            },   
            1,                     
            undefined,
            [
              {
              label: "Day",
              value: 0,
              type: "",   // String, Float...etc
              panel: ""   // Geography or Time...etc
              },
              {
                label: "Week",
                value: 1,
                type: "",
                panel: ""
              },
              {
                label: "Month",
                value: 2,
                type: "",
                panel: ""
              },
              {
                label: "Quarter",
                value: 3,
                type: "",
                panel: ""
              },
              {
                label: "Progress",
                value: 4,
                type: "",
                panel: ""
              },
              {
                label: "Goal",
                value: 5,
                type: "",
                panel: ""
              }
            ]                       
        );        

        console.log("loading_text=", loading_text.currentLevels.Day[0]);

        if(CurrentUser.info !== null) {
          console.log("CurrentUser =", CurrentUser.info.first_name);
          let first_name = CurrentUser.info.first_name;                               
          //tmp_day_msg += `${first_name} \n This is what your schedule looks like: \n * 9am: Breakfast with [Dr. Terry Smith](https://riversidehealth.org/) \n * 10:45am: Dr. Jennifer Snyder @ Montefiore \n * 12pm: Lunch with Dr. Tom Burns @ [Weill Cornell](https://maps.app.goo.gl/ZcTLCgXjuCHjECNS7) \n`    
          tmp_day_msg += `${first_name} \n ${loading_text.currentLevels.Day[0]}` 
        } else {
          //tmp_day_msg += `\n This is what your schedule looks like: \n * 9am: Breakfast with [Dr. Terry Smith](https://riversidehealth.org/) \n * 10:45am: Dr. Jennifer Snyder @ Montefiore \n * 12pm: Lunch with Dr. Tom Burns @ [Weill Cornell](https://maps.app.goo.gl/ZcTLCgXjuCHjECNS7) \n`    
          tmp_day_msg += `\n ${loading_text.currentLevels.Day[0]}` 
        }

        const dayMsgInit = tmp_day_msg;
        const weekMsgInit = loading_text.currentLevels.Week[0] as string;
        const monthMsgInit = loading_text.currentLevels.Month[0] as string;
        const quarterMsgInit = loading_text.currentLevels.Quarter[0] as string;
        const currProgress = loading_text.currentLevels.Progress[0] as number;
        const yearGoal = loading_text.currentLevels.Goal[0] as number;

        return { dayMsgInit, weekMsgInit, monthMsgInit, quarterMsgInit, currProgress, yearGoal};
    }
    
    setUserInput(value: string) {
        this.userInput = value;
    }
    
    addPrompt(prompt: string) {
        this.prompts.push(prompt);
    }

    setAnswers(newAnswer: string) {
      console.log('newAnswer=', newAnswer);
      this.answers = [newAnswer];
    }
    
    addAnswer(answer: string) {
    this.answers.push(answer);
    }

    setText(text: string) {
    this.textDisplay = text;
    }

    setPic(pic: string) {
    this.picURL = pic;
    }

    setVid(vid: string) {
    this.vidURL = vid;
    }

    setRenderCustom(val: boolean) {
      this.renderCustom = val;
    }

    // For multiple questions
    setMyQuestions(questions: string[]) {
      this.myQuestions = questions;
    }

    setCurrentQuestionIndex(value: number) {
      this.currentQuestionIndex = value;
    }

    setWaitingForAnswers(value: boolean) {
      this.waitingForAnswers = value;
    }

    

    /*
    async sendMessageToFlask(msg: string): Promise<OllamaApiResponse> {
        const requestBody = {
          prompt: msg
        };
    
        const apiUrl = process.env.REACT_APP_API_URL;
        console.log('API URL:', apiUrl);
    
        try {
          const response = await fetch(`${apiUrl}/langgrapho_gen`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
          });
    
          if (response.ok) {
            const result: OllamaApiResponse = await response.json();
            console.log('Server response:', result);
            return result;
          } else {
            console.error('Server error:', response.status, response.statusText);
            return {
              model: '',
              created_at: '',
              response: 'Error occurred',
              text: '',
              vid: 'nan',
              pic: 'nan',
              done: false,
            };
          }
        } catch (error) {
          console.error('Network error:', error);
          return {
            model: '',
            created_at: '',
            response: "I'm not connected to the internet -- I can't answer your question at this point...",
            text: '',
            vid: '',
            pic: '',
            done: false,
          };
        }
    }
     */

    async sendMsgToLLMService(msg: string, user_name: string, resume: boolean, answers_array: string[]): Promise<OllamaApiResponse> {      

      try {
        const response = await axios.post<llmResponse>('/api/e/call_dummy_microservice', 
          {
            'message': msg, 
            'user_name': user_name,
            'resume': resume,
             'answers_array': answers_array
          }
        );        
        console.log(response);                

        if (response.status >= 200 && response.status < 300 && response.data.success === true) {                    
          const result: OllamaApiResponse = response.data.payload;
          console.log('Server response:', result);

          return result;          
        } else {
          console.error('Server error:', response.status, response.statusText);
          return {
            model: '',
            created_at: '',
            response: 'Error occurred',
            text: '',
            vid: 'nan',
            pic: 'nan',
            waiting: false,
            done: false,
            questions: [],
            table_json: {
              table_header: [],
              table_content: [{}],
            },
            render_table: false,
          };
        }
        
      } catch (error: any) {
        console.error('Network error:', error);
        return {
          model: '',
          created_at: '',
          response: "I'm not connected to the internet -- I can't answer your question at this point...",
          text: '',
          vid: '',
          pic: '',
          waiting: false,
          done: false,
          questions: [],
          table_json: {
            table_header: [],
            table_content: [{}],
          },
          render_table: false,
        };
      }
    }

}

export const store = new Store();