import Variables from "common/Variables";
import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";

const compareVariablesDictionary = {
    best: "max",
    worst: "min",
    average: "avg",
};

const reverseCompareVariablesDictionary = {
    max: "best",
    min: "worst",
    avg: "average",
};

class Api {
    static getFinding(props) {
        let {
            aggregateVariable,
            dataComparison,
            groupOperation,
            compareOperation,
            groupValue,
            compareValue,
            config,
        } = props;

        let item = {
            type: "2",
            content: {
                aggregateVariable: aggregateVariable,
                dataComparison: dataComparison,
                groupOperation: groupOperation,
                compareOperation: compareOperation,
                groupValue: groupValue,
                compareValue: compareValue,
            },
            config: config,
        };
        return item;
    }

    static getData(config, findingOptions, moduleId) {
        let {
            dataScope,
            conditions,
            variableIndices,
            aggregateVariable,
            aggregateVariableIndex,
            compareVariable,
            variables,
            targetVariable,
            additionalParameter,
            targetVariableIndex,
            additionalParameterIndex,
            selectedTable,
            groupOperation,
            leftAdditionalValue,
        } = config;
        let dataTableIdx =
            typeof dataScope === "undefined" ? 0 : dataScope.value; //let compareOperation = compareVariablesDictionary[compareVariable];
        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        conditions = conditionsToJson(conditions);
        let requestJson = {
            data_table_idx: dataTableIdx,
            group_index: aggregateVariableIndex,
            group_measured_by_index: targetVariableIndex,
            compare_to: compareVariablesDictionary[compareVariable],
            group_operation: groupOperation,
            variable_indices: variableIndices.filter(
                (index) => index !== targetVariableIndex
            ),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            conditions: conditions,
            module_id: moduleId,
        };
        if (additionalParameter && leftAdditionalValue) {
            requestJson.where = {
                group_index: additionalParameterIndex,
                operation: "=",
                value: leftAdditionalValue.value,
            };
        }
        return axios
            .post("/api/e/get_data_comparison", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let data = variables
                        .filter((item) => item !== targetVariable)
                        .map((variable) => ({
                            name: variable.toUpperCase(),
                            [reverseCompareVariablesDictionary[groupOperation]]:
                                response.data["group_name"][variable] || 0,
                            [compareVariable]:
                                response.data["compare_to"][variable] || 0,
                            units: Variables(dataTableIdx, moduleId).getUnits(variable),
                        }));
                    let groupValue =
                        response.data["group_name"][aggregateVariable] || "";
                    let compareValue =
                        response.data["compare_to"][aggregateVariable] || "";

                    return Promise.resolve(
                        Api.getFinding({
                            aggregateVariable: aggregateVariable,
                            dataComparison: data,
                            groupOperation:
                                reverseCompareVariablesDictionary[
                                    groupOperation
                                ],
                            compareOperation: compareVariable,
                            groupValue: groupValue,
                            compareValue: compareValue,
                            config: config,
                        })
                    );
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
