import React, { Component } from "react";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import Dock from "react-dock";
import { observer } from "mobx-react";
import CanvasTreeStore from "../CanvasTreeStore";
import DataTableInputSelector, {
    DataTableInput,
    toCanvasDataTableInputDetails,
    fromCanvasDataTableInputDetails,
} from "../DataTableInputSelector";
import StatusExpressionsSelector, {
    FillType,
    StatusFillOption,
    statusFillOptions,
} from "../StatusExpressionsSelector";
import InputsSelector from "../InputsSelector";
import PrintExpressionsSelector from "../PrintExpressionsSelector";
import NotificationSelector from "../NotificationSelector";
import GlobalInputsSelector from "../GlobalInputsSelector";
import OutputsSelector from "../OutputsSelector";
import { getModuleUsers } from "common/ModulesApi";
import { UserIcon } from "common/UserIcon";
import customSelectStyles, { customFilterStyles } from "common/SelectStyles";
import SubmitOptionsSelector from "../SubmitOptionsSelector";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Tables, { TableOption } from "common/Tables";
import Variables, { VariableOption } from "common/Variables";
import UserInfo from "common/UserInfo";
import {
    generateRawMetric,
    getDefaultNotificationExpression,
    getDefaultPrintExpression,
    getTargetValue,
    CanvasSubmitButton,
    CanvasTextBox,
    CanvasElement,
    CanvasProgressElement,
    CanvasNode,
    CanvasSlider,
    CanvasDropdownSelector,
    isDropdownSelector,
    isBox,
    isInput,
    isTextBox,
    isSlider,
    isProgressElement,
    isSubmitButton,
    isToggle,
    ColumnFormat,
    isBarcodeReader,
    DefaultValueType,
    isFilter,
    CanvasFilter,
    isRadioButtonsGroup,
    CanvasRadioButtonsGroup,
    isDateFormat,
    isSurvey,
    InnerCanvasChanges,
} from "common/Canvas";
import { mainStyle } from "common/MainStyle";
import ColorOptions from "common/ColorOptions";
import SharedBoxesStore from "../SharedBoxesStore";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import { NodeLinkOption } from "common/Conditions";
import { ConditionsSelector, Condition } from "common/Conditions";
import { lightThemeStyle } from "common/LightThemeStyle";
import { variableToColumnFormat } from "common/InputData";
import remoteModuleId from "common/remoteModuleId";
import _ from "lodash";
import Box from "./Box";
import Textbox from "./Textbox";
import Toggle from "./Toggle";
import RadioButtonsGroup from "./RadioButtonsGroup";
import Input from "./Input";
import InputFormatSelector from "../InputFormatSelector";
import SubmitButton from "./SubmitButton";
import ProgressElement from "./ProgressElement";
import SliderOutputsSelector from "../SilderOutputsSelector";
import Slider from "./Slider";
import DropdownSelector from "./DropdownSelector";
import {
    headerBarHeight,
    minLimitEditMenuWidth,
} from "../Constants";
import GlobalInputs, { getGlobalInputValue } from "common/GlobalInputs";
import SurveyComponent from "./Survey";

interface StringOption {
    label: string;
    value: string;
}

interface StringNullOption {
    label: string;
    value: string | null;
}

const colorOptions: ReadonlyArray<StringNullOption> = [
    ...ColorOptions,
    { label: "AUTO", value: null },
];

interface DefaultValueOption {
    label: string;
    value: DefaultValueType;
}

const defaultValueOptionsForTextElements: ReadonlyArray<DefaultValueOption> = [
    { label: "Clear every session", value: DefaultValueType.ClearEverySession },
    { label: "Last value added", value: DefaultValueType.LastTextAdded },
    { label: "Custom text", value: DefaultValueType.CustomText },
];

const defaultValueOptionsForDateInput: ReadonlyArray<DefaultValueOption> = [
    ...defaultValueOptionsForTextElements,
    { label: "Current Date/Time", value: DefaultValueType.CurrentDateTime },
];

const defaultValueOptionsForNonTextElements: ReadonlyArray<DefaultValueOption> = defaultValueOptionsForTextElements.slice(
    0,
    2
);

interface Props {
    currentModuleId: number | undefined;
    canvasTreeStore: CanvasTreeStore;
    nodeId: number;
    initialNodeState: CanvasNode;
    canWrite: boolean;
    isLiveStreaming: boolean;
    onClose: (accepted: boolean) => void;
    onResize: () => void;
}

interface State {
    dockSize: number;
    editMenuWidth: number;
    neighborsUsers: UserInfo[];
    selectUser: boolean;
    shared: boolean;
    dataTableInput: DataTableInput[];
    node: CanvasNode;
    delegate: UserInfo | undefined;
    sliderStepSize: string;
    progressElementThickness: string;
    requestError: string | null;
    parseError: string | null;
}

@observer
class AdvancedModeMenu extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            dockSize: window.innerHeight * 0.7,
            editMenuWidth: 0,
            neighborsUsers: [],
            selectUser: false,
            shared:
                (this.props.canvasTreeStore.canvasTreeState.get(
                    this.props.nodeId
                )! as CanvasElement).sharedId != null,
            dataTableInput: [],
            node: _.cloneDeep(props.initialNodeState),
            delegate: undefined,
            sliderStepSize: isSlider(props.initialNodeState)
                ? String(props.initialNodeState.stepSize ?? 1)
                : "",
            progressElementThickness: isProgressElement(props.initialNodeState)
                ? String(props.initialNodeState.thickness ?? 2)
                : "",
            requestError:
                this.props.canvasTreeStore.canvasTreeRequestErrorsState.get(
                    props.nodeId
                ) ?? null,
            parseError:
                this.props.canvasTreeStore.canvasTreeParseErrorsState.get(
                    props.nodeId
                ) ?? null,
        };

        this.renderContents = this.renderContents.bind(this);
        this.handleDockSizeChange = this.handleDockSizeChange.bind(this);
    }

    componentDidMount() {
        if (
            isTextBox(this.state.node) ||
            isBox(this.state.node) ||
            isSlider(this.state.node) ||
            isProgressElement(this.state.node)
        ) {
            if (this.state.node.dataTableInputDetails != null) {
                fromCanvasDataTableInputDetails(
                    (this.props.canvasTreeStore.canvasTreeState.get(
                        this.props.nodeId
                    )! as CanvasElement).dataTableInputDetails ?? [],
                    this.props.currentModuleId
                ).then((dataTableInput) => {
                    this.setState({ dataTableInput: dataTableInput });
                });
            }
            if (this.props.currentModuleId != null) {
                getModuleUsers(this.props.currentModuleId)
                    .then((users) => {
                        this.setState((state) => ({
                            neighborsUsers: users,
                            delegate:
                                isBox(state.node) && state.node.delegate != null
                                    ? users.find(
                                          (user) =>
                                              user.id ===
                                              (state.node as CanvasElement)
                                                  .delegate
                                      )
                                    : undefined,
                        }));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }

    handleDockSizeChange(size: number) {
        this.setState({ dockSize: size });
    }

    componentDidUpdate() {
        if (
            isDropdownSelector(this.props.initialNodeState) &&
            isDropdownSelector(this.state.node)
        ) {
            if (
                this.props.initialNodeState.metric !== this.state.node.metric ||
                !_.isEqual(
                    this.props.initialNodeState.additionalOutputs?.map(
                        (item) => item.metric
                    ),
                    this.state.node.additionalOutputs?.map(
                        (item) => item.metric
                    )
                )
            ) {
                this.setState((state) => ({
                    node: {
                        ...state.node,
                        metric: this.props.initialNodeState.metric,
                        value: this.props.initialNodeState.value,
                        additionalOutputs: (this.props
                            .initialNodeState as CanvasDropdownSelector)
                            .additionalOutputs,
                    },
                }));
            }
        }
    }

    private commitChanges(
        sharedOnly?: boolean,
        skipHistory?: boolean
    ): boolean {
        if (sharedOnly) {
            this.props.canvasTreeStore.makeShareableAction(
                this.props.nodeId,
                this.state.shared
            );
            return true;
        }
        let canSave = true;
        if (this.props.isLiveStreaming && this.state.node.liveStreaming) {
            let originalNode = this.props.canvasTreeStore.canvasTreeState.get(
                this.state.node.id
            );
            canSave =
                originalNode == null ||
                this.props.canvasTreeStore.checkTypes(
                    originalNode,
                    this.state.node
                );
        }
        if (canSave) {
            let propagatedChanges: InnerCanvasChanges = {};
            if (
                isTextBox(this.state.node) ||
                isSubmitButton(this.state.node) ||
                isBox(this.state.node) ||
                isSlider(this.state.node) ||
                isDropdownSelector(this.state.node) ||
                isProgressElement(this.state.node)
            ) {
                let node:
                    | CanvasSubmitButton
                    | CanvasTextBox
                    | CanvasElement
                    | CanvasRadioButtonsGroup
                    | CanvasSlider
                    | CanvasDropdownSelector
                    | CanvasProgressElement = {
                    ...this.state.node,
                    dataTableInputDetails: toCanvasDataTableInputDetails(
                        this.state.dataTableInput
                    ),
                    childrenIds: this.state.node.childrenIds,
                    childrenSharedIds: this.state.node.childrenSharedIds,
                };
                if (isBox(node) || isTextBox(node)) {
                    if (this.state.delegate?.id !== node.delegate) {
                        node.delegate = this.state.delegate?.id;
                        if (this.state.delegate != null) {
                            let newDelegate = this.state.neighborsUsers.filter(
                                (user) => user.id === this.state.delegate?.id
                            );
                            if (newDelegate.length !== 0) {
                                this.props.canvasTreeStore.setDelegateAction(
                                    newDelegate[0]
                                );
                            }
                        }
                    }
                }
                this.props.canvasTreeStore.updateNodeAction(
                    this.props.nodeId,
                    node as any,
                    undefined,
                    undefined,
                    undefined,
                    propagatedChanges
                );
            } else {
                this.props.canvasTreeStore.updateNodeAction(
                    this.props.nodeId,
                    this.state.node,
                    undefined,
                    undefined,
                    undefined,
                    propagatedChanges
                );
                this.props.onResize();
            }
            this.props.canvasTreeStore.saveChangesAction(
                propagatedChanges,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                skipHistory
            );
            return true;
        }
        return false;
    }

    private buildInnerView(
        globalContext: GlobalContextContents,
        firstColumnWidth: number,
        columnMargin: number
    ): JSX.Element {
        let dataScopes =
            this.props.currentModuleId != null
                ? DataScopesForModules(this.props.currentModuleId)
                : DataScopes;
        const delegateWidth: number = 66;
        const delegateMarginLeft: number = 76;
        let sharedBoxes = SharedBoxesStore.sharedBoxes(
            this.props.canvasTreeStore.canvasId!
        ).map((item) => ({
            label: item.label,
            value: item.value,
            isCloneInput: true,
            target: getTargetValue(item.item.box),
        }));
        let nodeLinkOptions = Array.from(
            this.props.canvasTreeStore.canvasTreeState.values()
        )
            .map(
                (item) =>
                    ({
                        label: item.outerId,
                        value: item.id,
                        isCloneInput: false,
                        target: getTargetValue(item),
                    } as NodeLinkOption)
            )
            .concat(sharedBoxes)
            .concat(
                GlobalInputs.map((input) => ({
                    label: input.label,
                    value: input.value,
                    isCloneInput: false,
                    target: getGlobalInputValue(input.value, true),
                    isGlobalInput: true,
                }))
            );

        let defaultValueOptions: ReadonlyArray<DefaultValueOption>;
        if (isInput(this.state.node)) {
            if (isDateFormat(this.state.node.format)) {
                defaultValueOptions = defaultValueOptionsForDateInput;
            } else {
                defaultValueOptions = defaultValueOptionsForTextElements;
            }
        } else if (
            isProgressElement(this.state.node) ||
            isSlider(this.state.node) ||
            isDropdownSelector(this.state.node)
        ) {
            defaultValueOptions = defaultValueOptionsForNonTextElements;
        } else {
            defaultValueOptions = defaultValueOptionsForTextElements;
        }

        return (
            <div className="my-row">
                <div
                    className="flex-simple-column"
                    style={{
                        overflow: "visible",
                        marginLeft: "38px",
                        flex: 1,
                    }}
                >
                    <div className="my-row" style={{ marginTop: "30px" }}>
                        <div className="flex-simple-column" style={{ flex: 1 }}>
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    marginTop: "18px",
                                }}
                            />
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    marginTop: "18px",
                                }}
                            />
                        </div>
                        {(isBox(this.state.node) ||
                            isTextBox(this.state.node)) && (
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: delegateMarginLeft }}
                            >
                                <div
                                    className="combined-shape"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    onClick={(_evt) => {
                                        if (this.props.canWrite)
                                            this.setState({ selectUser: true });
                                    }}
                                    title={
                                        this.state.delegate != null
                                            ? [
                                                  this.state.delegate
                                                      .first_name,
                                                  this.state.delegate.last_name,
                                              ].join(" ")
                                            : ""
                                    }
                                >
                                    {this.state.delegate != null ? (
                                        <UserIcon
                                            fontSize={20}
                                            width={delegateWidth}
                                            height={63}
                                            iconUrl={
                                                this.state.delegate.icon_url
                                            }
                                            user={this.state.delegate}
                                        />
                                    ) : (
                                        <img
                                            alt=""
                                            src="/dist/img/canvas/icon_person.png"
                                        />
                                    )}
                                </div>
                                <span
                                    style={{
                                        marginTop: "13px",
                                        fontFamily: "Arial",
                                        fontSize: "12px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        textAlign: "center",
                                    }}
                                >
                                    {"DELEGATE"}
                                </span>
                            </div>
                        )}
                    </div>

                    {(isSubmitButton(this.state.node) ||
                        isBarcodeReader(this.state.node)) && (
                        <div className="my-row" style={{ marginTop: "18px" }}>
                            <div
                                style={{
                                    display: "flex",
                                    height: "35px",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "Arial",
                                        fontSize: "17px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        width: firstColumnWidth,
                                    }}
                                >
                                    {"Upload"}
                                </span>
                            </div>
                            <SubmitOptionsSelector
                                currentModuleId={this.props.currentModuleId}
                                canvasTreeStore={this.props.canvasTreeStore}
                                value={this.state.node}
                                onChange={(changes) => {
                                    this.setState((state) => ({
                                        node: {
                                            ...state.node,
                                            ...changes,
                                        },
                                    }));
                                }}
                            />
                        </div>
                    )}
                    {(isTextBox(this.state.node) ||
                        isBox(this.state.node) ||
                        isProgressElement(this.state.node) ||
                        isSlider(this.state.node)) &&
                        this.props.canWrite && (
                            <div
                                className="my-row"
                                style={{
                                    marginTop: "18px",
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        height: "48px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: "Arial",
                                            fontSize: "17px",
                                            color: mainStyle.getPropertyValue(
                                                "--popup-primary-text-color"
                                            ),
                                            width: firstColumnWidth,
                                        }}
                                    >
                                        {"Inputs"}
                                    </span>
                                </div>
                                <div
                                    className="flex-simple-column"
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    <InputsSelector
                                        allSharedBoxes={SharedBoxesStore.sharedBoxes(
                                            this.props.canvasTreeStore.canvasId!
                                        )}
                                        value={this.state.node}
                                        onChange={(changes) => {
                                            this.setState((state) => ({
                                                node: {
                                                    ...state.node,
                                                    ...changes,
                                                },
                                            }));
                                        }}
                                    />
                                    <GlobalInputsSelector
                                        value={this.state.node}
                                        onChange={(changes) => {
                                            this.setState((state) => ({
                                                node: {
                                                    ...state.node,
                                                    ...changes,
                                                },
                                            }));
                                        }}
                                    />
                                    <DataTableInputSelector
                                        nodeLinkOptions={nodeLinkOptions}
                                        value={this.state.dataTableInput}
                                        onChange={(newValue) =>
                                            this.setState({
                                                dataTableInput: newValue,
                                            })
                                        }
                                        style={{
                                            marginTop: "20px",
                                        }}
                                        currentModuleId={
                                            this.props.currentModuleId
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    {this.state.requestError != null && (
                        <div
                            className="my-row"
                            style={{
                                alignItems: "center",
                                marginTop: "18px",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: firstColumnWidth,
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src="/dist/img/error.png"
                                    alt=""
                                    style={{
                                        marginRight: 5,
                                    }}
                                />
                            </div>
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    flex: 1,
                                    marginLeft: columnMargin,
                                }}
                            >
                                {String(this.state.requestError)}
                            </span>
                        </div>
                    )}
                    {(isProgressElement(this.state.node) ||
                        isBox(this.state.node)) && (
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "18px" }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: firstColumnWidth,
                                }}
                            >
                                {"Output"}
                            </span>
                            <OutputsSelector
                                value={this.state.node}
                                onChange={(changes) => {
                                    this.setState((state) => ({
                                        node: {
                                            ...state.node,
                                            ...changes,
                                        },
                                    }));
                                }}
                                style={{
                                    flex: 1,
                                }}
                            />
                        </div>
                    )}
                    {(isSlider(this.state.node) ||
                        isProgressElement(this.state.node)) && (
                        <div
                            className="my-row"
                            style={{
                                alignItems: "center",
                                marginTop: "18px",
                            }}
                        >
                            <SliderOutputsSelector
                                value={this.state.node}
                                onChange={(changes) => {
                                    this.setState((state) => ({
                                        node: {
                                            ...state.node,
                                            ...changes,
                                        },
                                    }));
                                }}
                                style={{
                                    flex: 1,
                                }}
                            />
                        </div>
                    )}
                    {isSlider(this.state.node) && (
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "18px" }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: firstColumnWidth,
                                }}
                            >
                                Step size
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    flex: 1,
                                    marginLeft: "26px",
                                    alignSelf: "center",
                                }}
                                placeholder=""
                                onChange={(e) => {
                                    const value: string = e.target.value;
                                    if (!/^[0-9]*\.?[0-9]*$/.test(value)) {
                                        e.preventDefault();
                                    } else {
                                        this.setState({
                                            sliderStepSize: value,
                                        });
                                    }
                                }}
                                value={this.state.sliderStepSize}
                            />
                        </div>
                    )}
                    {isProgressElement(this.state.node) && (
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "18px" }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: firstColumnWidth,
                                }}
                            >
                                Thickness / Height
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    flex: 1,
                                    marginLeft: "26px",
                                    alignSelf: "center",
                                }}
                                placeholder=""
                                onChange={(e) => {
                                    const value: string = e.target.value;
                                    if (!/^[0-9]*\.?[0-9]*$/.test(value)) {
                                        e.preventDefault();
                                    } else {
                                        this.setState({
                                            progressElementThickness: value,
                                        });
                                    }
                                }}
                                value={this.state.progressElementThickness}
                            />
                        </div>
                    )}
                    {this.state.parseError != null && (
                        <div
                            className="my-row"
                            style={{
                                alignItems: "center",
                                marginTop: "18px",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: firstColumnWidth,
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src="/dist/img/error.png"
                                    alt=""
                                    style={{
                                        marginRight: 5,
                                    }}
                                />
                            </div>
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    flex: 1,
                                    marginLeft: columnMargin,
                                }}
                            >
                                {this.state.parseError}
                            </span>
                        </div>
                    )}
                    {(isDropdownSelector(this.state.node) ||
                        isBox(this.state.node) ||
                        isTextBox(this.state.node) ||
                        isInput(this.state.node) ||
                        isSlider(this.state.node) ||
                        isProgressElement(this.state.node)) && (
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "18px" }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: firstColumnWidth,
                                }}
                            >
                                {"Default value"}
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"Default value"}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "200px",
                                        marginLeft: "26px",
                                        height: "38px",
                                    }),
                                    placeholder: (base) => ({
                                        ...base,
                                        color: "#869AAC",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: mainStyle.getPropertyValue(
                                            "--selectors-text-color"
                                        ),
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: mainStyle.getPropertyValue(
                                            "--selectors-text-color"
                                        ),
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                        display: state.selectProps.menuIsOpen
                                            ? "none"
                                            : "block",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={defaultValueOptions}
                                value={
                                    defaultValueOptions.find(
                                        (option) =>
                                            option.value ===
                                            this.state.node.defaultValueType
                                    ) ?? defaultValueOptions[1]
                                }
                                onChange={(newValue) => {
                                    this.setState((state) => ({
                                        node: {
                                            ...state.node,
                                            defaultValueType: (newValue as DefaultValueOption)
                                                .value,
                                        },
                                    }));
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                                menuPortalTarget={document.body}
                            />
                        </div>
                    )}
                    {this.state.node.defaultValueType ===
                        DefaultValueType.CustomText && (
                        <div
                            className="my-row"
                            style={{
                                alignItems: "center",
                                marginLeft: 26 + firstColumnWidth,
                                width: "200px",
                                marginTop: "10px",
                            }}
                        >
                            <input
                                type="text"
                                className="like-select"
                                placeholder="Type custom text..."
                                style={{
                                    flex: 1,
                                    alignSelf: "center",
                                }}
                                value={this.state.node.defaultText}
                                onChange={(evt) => {
                                    let newValue: string = evt.target.value;
                                    this.setState((state) => ({
                                        node: {
                                            ...state.node,
                                            defaultText: newValue,
                                        },
                                    }));
                                }}
                            />
                        </div>
                    )}

                    {(isBox(this.state.node) || isTextBox(this.state.node)) && (
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "18px" }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: firstColumnWidth,
                                }}
                            >
                                {"Status"}
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"STATUS"}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "110px",
                                        marginLeft: "26px",
                                        height: "38px",
                                    }),
                                    placeholder: (base) => ({
                                        ...base,
                                        color: "#869AAC",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color:
                                            state.data.value == null ||
                                            state.data.value === "transparent"
                                                ? mainStyle.getPropertyValue(
                                                      "--selectors-text-color"
                                                  )
                                                : state.data.value,
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color:
                                            state.data.value == null ||
                                            state.data.value === "transparent"
                                                ? mainStyle.getPropertyValue(
                                                      "--selectors-text-color"
                                                  )
                                                : state.data.value,
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                        display: state.selectProps.menuIsOpen
                                            ? "none"
                                            : "block",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={colorOptions}
                                value={
                                    this.state.node.statusColor != null
                                        ? this.state.node.statusColor
                                        : this.state.node.statusExpressions !=
                                          null
                                        ? colorOptions[4]
                                        : colorOptions[0]
                                }
                                onChange={(newValue) => {
                                    const newStatusColorValue: StringOption = newValue as StringOption;
                                    if (newStatusColorValue.label === "AUTO") {
                                        this.setState((state) => ({
                                            node: {
                                                ...state.node,
                                                statusColor: null,
                                                statusExpressions: [
                                                    {
                                                        subexpressions: [
                                                            {
                                                                operation: "",
                                                                value: "",
                                                            },
                                                        ],
                                                        color: colorOptions[0] as StringOption,
                                                    },
                                                ],
                                            },
                                        }));
                                    } else {
                                        this.setState((state) => ({
                                            node: {
                                                ...state.node,
                                                statusColor: newStatusColorValue,
                                                statusExpressions: undefined,
                                            },
                                        }));
                                    }
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                                menuPortalTarget={document.body}
                            />
                            {this.state.node.statusExpressions == null && (
                                <>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                width: "110px",
                                                marginLeft: "5px",
                                                height: "38px",
                                            }),
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 100000000,
                                            }),
                                        }}
                                        options={statusFillOptions}
                                        value={
                                            this.state.node.statusFont
                                                ? statusFillOptions[2]
                                                : this.state.node.statusFill
                                                ? statusFillOptions[1]
                                                : statusFillOptions[0]
                                        }
                                        onChange={(newValue) => {
                                            this.setState((state) => ({
                                                node: {
                                                    ...state.node,
                                                    statusFill:
                                                        (newValue as StatusFillOption)
                                                            .value ===
                                                        FillType.Fill,
                                                    statusFont:
                                                        (newValue as StatusFillOption)
                                                            .value ===
                                                        FillType.Font,
                                                },
                                            }));
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}
                                        menuPortalTarget={document.body}
                                    />
                                    {!this.state.node.statusFill &&
                                        !this.state.node.statusFont && (
                                            <>
                                                <input
                                                    className="like-select"
                                                    style={{
                                                        marginLeft: "5px",
                                                        width: "35px",
                                                        alignSelf: "center",
                                                    }}
                                                    onChange={(e) => {
                                                        const value: string =
                                                            e.target.value;
                                                        if (
                                                            !/^[0-9]*$/.test(
                                                                value
                                                            )
                                                        ) {
                                                            e.preventDefault();
                                                        } else {
                                                            this.setState(
                                                                (state) => ({
                                                                    node: {
                                                                        ...state.node,
                                                                        statusBorderWidth: Number(
                                                                            value
                                                                        ),
                                                                    },
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    value={
                                                        this.state.node
                                                            .statusBorderWidth ===
                                                        0
                                                            ? ""
                                                            : (
                                                                  this.state
                                                                      .node
                                                                      .statusBorderWidth ??
                                                                  1
                                                              ).toString()
                                                    }
                                                />
                                                <span
                                                    style={{
                                                        fontFamily: "Arial",
                                                        fontSize: "17px",
                                                        color: mainStyle.getPropertyValue(
                                                            "--popup-primary-text-color"
                                                        ),
                                                        width: "15px",
                                                        marginLeft: "5px",
                                                    }}
                                                >
                                                    {"px"}
                                                </span>
                                            </>
                                        )}
                                </>
                            )}
                            {this.state.node.statusExpressions != null && (
                                <StatusExpressionsSelector
                                    node={this.state.node}
                                    statusExpressions={
                                        this.state.node.statusExpressions
                                    }
                                    selectOutput={isTextBox(this.state.node)}
                                    nodeLinkOptions={nodeLinkOptions}
                                    onChange={(statusExpressions) => {
                                        this.setState((state) => ({
                                            node: {
                                                ...state.node,
                                                statusExpressions: statusExpressions,
                                            },
                                        }));
                                    }}
                                />
                            )}
                        </div>
                    )}
                    {(isBox(this.state.node) || isTextBox(this.state.node)) && (
                        <div
                            className="my-row"
                            style={{
                                alignItems: "center",
                                marginTop: "10px",
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: "80px",
                                }}
                            >
                                {"Notifications"}
                            </span>
                            <div style={{ marginLeft: 6 }}>
                                <NotificationSelector
                                    neighborsUsers={this.state.neighborsUsers}
                                    onChange={(notificationExpressions) => {
                                        this.setState((state) => ({
                                            node: {
                                                ...state.node,
                                                notificationExpressions: notificationExpressions,
                                            },
                                        }));
                                    }}
                                    notificationExpressions={
                                        this.state.node
                                            .notificationExpressions ?? [
                                            getDefaultNotificationExpression(),
                                        ]
                                    }
                                    nodeLinkOptions={nodeLinkOptions}
                                    node={this.state.node}
                                />
                            </div>
                        </div>
                    )}
                    {isTextBox(this.state.node) && (
                        <div
                            className="my-row"
                            style={{
                                alignItems: "center",
                                marginTop: "10px",
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: "80px",
                                }}
                            >
                                {"Print conditions"}
                            </span>
                            <div style={{ marginLeft: 6 }}>
                                <PrintExpressionsSelector
                                    onChange={(printExpressions) => {
                                        this.setState((state) => {
                                            let node = {
                                                ...state.node,
                                                printExpressions: printExpressions,
                                            } as CanvasTextBox;
                                            if (node.rawMetric != null)
                                                node.rawMetric = generateRawMetric(
                                                    node,
                                                    node.rawMetric
                                                );
                                            return {
                                                node: node,
                                            };
                                        });
                                    }}
                                    printExpressions={
                                        this.state.node.printExpressions ?? [
                                            getDefaultPrintExpression(),
                                        ]
                                    }
                                    nodeLinkOptions={nodeLinkOptions}
                                    node={this.state.node}
                                />
                            </div>
                        </div>
                    )}
                    {isSlider(this.state.node) && (
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "10px" }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: "80px",
                                }}
                            >
                                {"Vertical"}
                            </span>
                            <div style={{ marginLeft: 6 }}>
                                <Switch
                                    onChange={() => {
                                        this.setState((state) => ({
                                            node: {
                                                ...state.node,
                                                vertical: !(
                                                    (state.node as CanvasSlider)
                                                        .vertical ?? false
                                                ),
                                            },
                                        }));
                                    }}
                                    checked={this.state.node.vertical ?? false}
                                    width={26}
                                    height={13}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        </div>
                    )}
                    {isProgressElement(this.state.node) && (
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "10px" }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: "80px",
                                }}
                            >
                                {"Linear"}
                            </span>
                            <div style={{ marginLeft: 6 }}>
                                <Switch
                                    onChange={() => {
                                        this.setState((state) => ({
                                            node: {
                                                ...state.node,
                                                linear: !(
                                                    (state.node as CanvasProgressElement)
                                                        .linear ?? false
                                                ),
                                            },
                                        }));
                                    }}
                                    checked={this.state.node.linear ?? false}
                                    width={26}
                                    height={13}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        </div>
                    )}
                    {isInput(this.state.node) && (
                        <InputFormatSelector
                            node={this.state.node}
                            onChange={(node) => {
                                this.setState({ node: node });
                            }}
                        />
                    )}

                    {isToggle(this.state.node) && (
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "10px" }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: "80px",
                                }}
                            >
                                {"Show 0/1"}
                            </span>
                            <div style={{ marginLeft: 6 }}>
                                <Switch
                                    onChange={(checked) => {
                                        this.setState((state) => ({
                                            node: {
                                                ...state.node,
                                                hideLabels: !checked,
                                            },
                                        }));
                                    }}
                                    checked={!this.state.node.hideLabels}
                                    width={26}
                                    height={13}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        </div>
                    )}
                    {!isFilter(this.state.node) &&
                        !isProgressElement(this.state.node) &&
                        !isBarcodeReader(this.state.node) &&
                        !isSurvey(this.state.node) &&
                        !isSubmitButton(this.state.node) && (
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    marginTop: "10px",
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "Arial",
                                        fontSize: "17px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        width: "80px",
                                    }}
                                >
                                    {"Linkable"}
                                </span>
                                <div style={{ marginLeft: 6 }}>
                                    <Switch
                                        onChange={() => {
                                            this.setState((state) => ({
                                                shared: !state.shared,
                                            }));
                                        }}
                                        checked={this.state.shared}
                                        width={26}
                                        height={13}
                                        offColor="#20293C"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1F8EFA"
                                    />
                                </div>
                            </div>
                        )}
                    {!isFilter(this.state.node) &&
                        !isProgressElement(this.state.node) &&
                        !isBarcodeReader(this.state.node) &&
                        !isSurvey(this.state.node) &&
                        !isSubmitButton(this.state.node) &&
                        "CanvasLiveStream" in globalContext.permissions && (
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    marginTop: "10px",
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "Arial",
                                        fontSize: "17px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        width: "80px",
                                    }}
                                >
                                    {"Stream Metric to Dataset"}
                                </span>
                                <div style={{ marginLeft: 6 }}>
                                    <Switch
                                        onChange={(checked) => {
                                            this.setState((state) => ({
                                                node: {
                                                    ...state.node,
                                                    liveStreaming: checked,
                                                },
                                            }));
                                        }}
                                        checked={
                                            this.state.node.liveStreaming ??
                                            false
                                        }
                                        width={26}
                                        height={13}
                                        offColor="#20293C"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1F8EFA"
                                    />
                                </div>
                            </div>
                        )}
                    {(isFilter(this.state.node) ||
                        isDropdownSelector(this.state.node)) && (
                        <>
                            <div
                                className="my-row"
                                style={{ alignItems: "center" }}
                            >
                                <span
                                    style={{
                                        fontFamily: "Arial",
                                        fontSize: "17px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        width: "80px",
                                    }}
                                >
                                    Select data
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "16em",
                                            marginLeft: "5px",
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 100000000,
                                        }),
                                    }}
                                    options={dataScopes.dataScopesOptions}
                                    onChange={(newValue) => {
                                        this.setState((state) => ({
                                            node: {
                                                ...state.node,
                                                dataScopeOption: newValue as DataScopeOption,
                                                tableOption: Tables(
                                                    (newValue as DataScopeOption)
                                                        .value,
                                                    this.props
                                                        .currentModuleId ??
                                                        remoteModuleId
                                                ).tableToOption(),
                                                conditions: null,
                                                variableOption: null,
                                                tableValue: null,
                                                metric: "",
                                                value: NaN,
                                            },
                                        }));
                                    }}
                                    value={this.state.node.dataScopeOption}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            {this.state.node.dataScopeOption != null && (
                                <div
                                    className="my-row"
                                    style={{
                                        alignItems: "center",
                                        marginTop: "2px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: "Arial",
                                            fontSize: "17px",
                                            color: mainStyle.getPropertyValue(
                                                "--popup-primary-text-color"
                                            ),
                                            width: "80px",
                                        }}
                                    >
                                        Select table
                                    </span>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                marginLeft: "5px",
                                                ...base,
                                                width: "16em",
                                            }),
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 100000000,
                                            }),
                                        }}
                                        options={
                                            Tables(
                                                this.state.node.dataScopeOption
                                                    .value,
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            ).rawAndAggregateTableOptions
                                        }
                                        onChange={(newValue) => {
                                            this.setState((state) => ({
                                                node: {
                                                    ...state.node,
                                                    tableOption: newValue as TableOption,
                                                    tableValue: null,
                                                    metric: "",
                                                    value: NaN,
                                                },
                                            }));
                                        }}
                                        value={this.state.node.tableOption}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            )}
                        </>
                    )}

                    {isDropdownSelector(this.state.node) && (
                        <>
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    marginTop: "10px",
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "Arial",
                                        fontSize: "17px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        width: "80px",
                                    }}
                                >
                                    {"Variable mode"}
                                </span>
                                <div style={{ marginLeft: 6 }}>
                                    <Switch
                                        onChange={() => {
                                            this.setState((state) => ({
                                                node: {
                                                    ...state.node,
                                                    variableMode: !(
                                                        (state.node as CanvasDropdownSelector)
                                                            .variableMode ??
                                                        false
                                                    ),
                                                    conditions: null,
                                                    variableOption: null,
                                                    tableValue: null,
                                                    metric: "",
                                                    value: NaN,
                                                    format: null,
                                                },
                                            }));
                                        }}
                                        checked={
                                            this.state.node.variableMode ??
                                            false
                                        }
                                        width={26}
                                        height={13}
                                        offColor="#20293C"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1F8EFA"
                                    />
                                </div>
                            </div>
                            {!this.state.node.variableMode &&
                                this.state.node.dataScopeOption != null && (
                                    <div
                                        className="my-row"
                                        style={{
                                            alignItems: "center",
                                            marginTop: "2px",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: "Arial",
                                                fontSize: "17px",
                                                color: mainStyle.getPropertyValue(
                                                    "--popup-primary-text-color"
                                                ),
                                                width: "80px",
                                            }}
                                        >
                                            Select variable
                                        </span>
                                        <Select
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            menuPortalTarget={document.body}
                                            placeholder={""}
                                            styles={{
                                                ...customSelectStyles,
                                                container: (base) => ({
                                                    marginLeft: "5px",
                                                    ...base,
                                                    width: "16em",
                                                }),
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 100000000,
                                                }),
                                            }}
                                            options={Variables(
                                                this.state.node.dataScopeOption
                                                    .value,
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            ).dataVariables.map((variable) => ({
                                                label: variable.name,
                                                value: variable.index ?? -1,
                                                type: variable.type,
                                                panel: variable.panel,
                                            }))}
                                            onChange={(newValue) => {
                                                let variable = Variables(
                                                    (this.state
                                                        .node as CanvasDropdownSelector)
                                                        .dataScopeOption!.value,
                                                    this.props
                                                        .currentModuleId ??
                                                        remoteModuleId
                                                ).getVariableByIndex(
                                                    (newValue as VariableOption)
                                                        .value
                                                );
                                                let format:
                                                    | ColumnFormat
                                                    | undefined;
                                                if (variable != null)
                                                    format = variableToColumnFormat(
                                                        variable
                                                    );
                                                this.setState((state) => ({
                                                    node: {
                                                        ...state.node,
                                                        variableOption: newValue as VariableOption,
                                                        tableValue: null,
                                                        metric: "",
                                                        value: NaN,
                                                        format: format,
                                                    },
                                                }));
                                            }}
                                            value={
                                                this.state.node.variableOption
                                            }
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25:
                                                        "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </div>
                                )}
                            {!this.state.node.variableMode &&
                                this.state.node.dataScopeOption != null && (
                                    <ConditionsSelector
                                        allowLinks
                                        nodeLinkOptions={nodeLinkOptions}
                                        small
                                        dataScopeId={
                                            this.state.node.dataScopeOption
                                                .value
                                        }
                                        title={"where"}
                                        style={{
                                            marginTop: 0,
                                            backgroundColor: "transparent",
                                        }}
                                        currentModuleId={
                                            this.props.currentModuleId
                                        }
                                        value={
                                            this.state.node.conditions ??
                                            undefined
                                        }
                                        onChange={(conditions: Condition[]) => {
                                            this.setState((state) => ({
                                                node: {
                                                    ...state.node,
                                                    conditions: conditions,
                                                    tableValue: null,
                                                    metric: "",
                                                    value: NaN,
                                                },
                                            }));
                                        }}
                                        allVariables={
                                            Variables(
                                                this.state.node.dataScopeOption
                                                    .value,
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            ).dataVariables
                                        }
                                        titleStyle={{
                                            color: mainStyle.getPropertyValue(
                                                "--popup-primary-text-color"
                                            ),
                                            width: 80,
                                            textAlign: "start",
                                            fontSize: "17px",
                                            fontFamily: "Arial",
                                        }}
                                        linkElementStyle={{
                                            width: undefined,
                                            fontSize: undefined,
                                            fontFamily: undefined,
                                        }}
                                        rowStyle={(index) => ({
                                            backgroundColor: "transparent",
                                            marginLeft:
                                                index === 0 ? undefined : 10,
                                        })}
                                        rowStyleWithoutPlusMinus={{
                                            backgroundColor: "transparent",
                                        }}
                                        linkSwitchStyle={{
                                            offColor:
                                                lightThemeStyle.switchOffColor,
                                            onColor:
                                                lightThemeStyle.switchOnColor,
                                            offHandleColor:
                                                lightThemeStyle.switchOffHandleColor,
                                            onHandleColor:
                                                lightThemeStyle.switchOnHandleColor,
                                        }}
                                        inputStyle={{
                                            width: 94,
                                            marginLeft: 3,
                                            marginRight: 3,
                                            marginBottom: 0,
                                            backgroundColor: undefined,
                                            color: undefined,
                                        }}
                                        buttonsStyle={{
                                            backgroundColor: "transparent",
                                            color:
                                                "var(--popup-primary-text-color)",
                                            fontFamily: "Roboto",
                                            fontSize: "12px",
                                        }}
                                        selectStyle={{
                                            control: (base, state) => ({
                                                ...customFilterStyles.control!(
                                                    base,
                                                    state
                                                ),
                                                backgroundColor:
                                                    "var(--selectors-background-color)",
                                            }),
                                            input: (base, state) => ({
                                                ...customFilterStyles.input!(
                                                    base,
                                                    state
                                                ),
                                            }),
                                            singleValue: (base, state) => ({
                                                ...customFilterStyles.singleValue!(
                                                    base,
                                                    state
                                                ),
                                                color:
                                                    "var(--selectors-text-color)",
                                            }),
                                        }}
                                    />
                                )}
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    marginTop: "10px",
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "Arial",
                                        fontSize: "17px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        width: "80px",
                                    }}
                                >
                                    {"Allow multiple selection"}
                                </span>
                                <div style={{ marginLeft: 6 }}>
                                    <Switch
                                        onChange={() => {
                                            this.setState((state) => ({
                                                node: {
                                                    ...state.node,
                                                    height: null,
                                                    additionalOutputs:
                                                        (state.node as CanvasDropdownSelector)
                                                            .additionalOutputs ==
                                                        null
                                                            ? []
                                                            : null,
                                                },
                                            }));
                                        }}
                                        checked={
                                            this.state.node.additionalOutputs !=
                                            null
                                        }
                                        width={26}
                                        height={13}
                                        offColor="#20293C"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1F8EFA"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div style={{ flex: 1 }} />
                    <div
                        className="my-row"
                        style={{
                            marginTop: 20,
                            marginBottom: 20,
                            marginRight: 20,
                            alignSelf: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginLeft: 10,
                                width: "112px",
                            }}
                            onClick={() => {
                                this.props.onClose(false);
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginLeft: 10,
                                width: "112px",
                            }}
                            onClick={() => {
                                let success = this.commitChanges();
                                if (success) {
                                    this.props.onClose(true);
                                } else {
                                    let warning =
                                        "You cannot change type of streaming node";
                                    this.props.canvasTreeStore.canvasTreeParseErrorsState.set(
                                        this.state.node.id,
                                        warning
                                    );
                                    this.setState({ parseError: warning });
                                }
                            }}
                        >
                            DONE
                        </Button>
                    </div>
                </div>
                {this.state.selectUser && (
                    <Select
                        placeholder={"Select person"}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        isClearable={true}
                        backspaceRemovesValue={true}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                height: "38px",
                                width: "140px",
                                marginLeft: "10px",
                                marginTop: "10px",
                                marginRight: "10px",
                            }),
                        }}
                        options={this.state.neighborsUsers.map((user) => {
                            return {
                                label: user.user_name,
                                value: user,
                            };
                        })}
                        value={
                            this.state.delegate != null
                                ? {
                                      label: this.state.delegate.user_name,
                                      value: this.state.delegate,
                                  }
                                : null
                        }
                        onChange={(newValue) => {
                            let delegate: UserInfo | undefined = (newValue as {
                                label: string;
                                value: UserInfo;
                            } | null)?.value;
                            this.setState({
                                delegate: delegate,
                            });
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                )}
                <div
                    style={{
                        width: 38,
                    }}
                />
            </div>
        );
    }

    private renderContents(globalContext: GlobalContextContents): JSX.Element {
        const sharedBoxes = SharedBoxesStore.sharedBoxes(
            this.props.canvasTreeStore.canvasId!
        ).map((item) => ({
            label: item.label,
            value: item.value,
            isCloneInput: true,
            target: getTargetValue(item.item.box),
        }));

        const nodeLinkOptions = Array.from(
            this.props.canvasTreeStore.canvasTreeState.values()
        )
            .map(
                (item) =>
                    ({
                        label: item.outerId,
                        value: item.id,
                        isCloneInput: false,
                        target: getTargetValue(item),
                    } as NodeLinkOption)
            )
            .concat(sharedBoxes)
            .concat(
                GlobalInputs.map((input) => ({
                    label: input.label,
                    value: input.value,
                    isCloneInput: false,
                    target: getGlobalInputValue(input.value, true),
                    isGlobalInput: true,
                }))
            );
        const dockStyle = {
            backgroundColor: "#FFFFFF",
            top: `${headerBarHeight}px`,
            height: `calc(100% - ${headerBarHeight}px)`,
        };
        const onChangeNode = (
            nodeChanges: Partial<CanvasElement>,
            commit: boolean = true,
            skipHistory: boolean = false
        ) => {
            let originalNode = this.props.canvasTreeStore.canvasTreeState.get(
                this.state.node.id
            );
            this.setState(
                (state) => ({
                    node: {
                        ...(originalNode ?? state.node),
                        ...nodeChanges,
                    },
                }),
                () => {
                    if (commit) {
                        this.commitChanges(false, skipHistory);
                    }
                }
            );
        };
        const onChangeDataTableInput = (newValue: DataTableInput[]) => {
            this.setState({ dataTableInput: newValue }, () => {
                this.commitChanges();
            });
        };
        const onChangeDelegate = (delegate?: UserInfo) => {
            this.setState({ delegate }, () => {
                this.commitChanges();
            });
        };
        const onChangeShared = (shared: boolean) => {
            this.setState({ shared }, () => {
                this.commitChanges(true);
            });
        };
        const onClose = () => this.props.onClose(false);

        if (isBox(this.state.node)) {
            return (
                <Dock
                    dockStyle={dockStyle}
                    position="left"
                    isVisible
                    fluid={false}
                    dimMode="none"
                    size={this.state.editMenuWidth || 550}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                editMenuWidth: size,
                            });
                        }
                    }}
                >
                    <Box
                        canvasId={this.props.canvasTreeStore.canvasId!}
                        node={this.state.node}
                        delegate={this.state.delegate}
                        userOptions={this.state.neighborsUsers}
                        nodeLinkOptions={nodeLinkOptions}
                        shared={this.state.shared}
                        currentModuleId={this.props.currentModuleId}
                        dataTableInput={this.state.dataTableInput}
                        globalContext={globalContext}
                        onChange={onChangeNode}
                        onChangeDataTableInput={onChangeDataTableInput}
                        onChangeDelegate={onChangeDelegate}
                        onChangeShared={onChangeShared}
                        onClose={onClose}
                    />
                </Dock>
            );
        }
        if (isProgressElement(this.state.node)) {
            return (
                <Dock
                    dockStyle={dockStyle}
                    position="left"
                    isVisible
                    fluid={false}
                    dimMode="none"
                    size={this.state.editMenuWidth || 550}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                editMenuWidth: size,
                            });
                        }
                    }}
                >
                    <ProgressElement
                        canvasId={this.props.canvasTreeStore.canvasId!}
                        node={this.state.node}
                        nodeLinkOptions={nodeLinkOptions}
                        shared={this.state.shared}
                        currentModuleId={this.props.currentModuleId}
                        dataTableInput={this.state.dataTableInput}
                        globalContext={globalContext}
                        onChange={onChangeNode}
                        onChangeDataTableInput={onChangeDataTableInput}
                        onChangeShared={onChangeShared}
                        onClose={onClose}
                    />
                </Dock>
            );
        }
        if (isSlider(this.state.node)) {
            return (
                <Dock
                    dockStyle={dockStyle}
                    position="left"
                    isVisible
                    fluid={false}
                    dimMode="none"
                    size={this.state.editMenuWidth || 550}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                editMenuWidth: size,
                            });
                        }
                    }}
                >
                    <Slider
                        canvasId={this.props.canvasTreeStore.canvasId!}
                        node={this.state.node}
                        nodeLinkOptions={nodeLinkOptions}
                        shared={this.state.shared}
                        currentModuleId={this.props.currentModuleId}
                        dataTableInput={this.state.dataTableInput}
                        globalContext={globalContext}
                        onChange={onChangeNode}
                        onChangeDataTableInput={onChangeDataTableInput}
                        onChangeShared={onChangeShared}
                        onClose={onClose}
                    />
                </Dock>
            );
        }
        if (
            isSubmitButton(this.state.node) ||
            isBarcodeReader(this.state.node)
        ) {
            return (
                <Dock
                    dockStyle={dockStyle}
                    position="left"
                    isVisible
                    fluid={false}
                    dimMode="none"
                    size={this.state.editMenuWidth || 350}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                editMenuWidth: size,
                            });
                        }
                    }}
                >
                    <SubmitButton
                        canvasTreeStore={this.props.canvasTreeStore}
                        canvasId={this.props.canvasTreeStore.canvasId!}
                        node={this.state.node}
                        currentModuleId={this.props.currentModuleId}
                        onChange={onChangeNode}
                        onClose={onClose}
                    />
                </Dock>
            );
        }

        if (isSurvey(this.state.node)) {
            return (
                <Dock
                    dockStyle={dockStyle}
                    position="left"
                    isVisible
                    fluid={false}
                    dimMode="none"
                    size={this.state.editMenuWidth || 500}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                editMenuWidth: size,
                            });
                        }
                    }}
                >
                    <SurveyComponent
                        canvasTreeStore={this.props.canvasTreeStore}
                        canvasId={this.props.canvasTreeStore.canvasId!}
                        node={this.state.node}
                        currentModuleId={this.props.currentModuleId}
                        onChange={onChangeNode}
                        onClose={onClose}
                    />
                </Dock>
            );
        }

        if (isTextBox(this.state.node)) {
            return (
                <Dock
                    dockStyle={dockStyle}
                    position="left"
                    isVisible
                    fluid={false}
                    dimMode="none"
                    size={this.state.editMenuWidth || 500}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                editMenuWidth: size,
                            });
                        }
                    }}
                >
                    <Textbox
                        canvasId={this.props.canvasTreeStore.canvasId!}
                        node={this.state.node}
                        delegate={this.state.delegate}
                        userOptions={this.state.neighborsUsers}
                        nodeLinkOptions={nodeLinkOptions}
                        shared={this.state.shared}
                        currentModuleId={this.props.currentModuleId}
                        dataTableInput={this.state.dataTableInput}
                        onChange={onChangeNode}
                        onChangeDataTableInput={onChangeDataTableInput}
                        onChangeDelegate={onChangeDelegate}
                        onChangeShared={onChangeShared}
                        onClose={onClose}
                    />
                </Dock>
            );
        }

        if (isRadioButtonsGroup(this.state.node)) {
            return (
                <Dock
                    dockStyle={dockStyle}
                    position="left"
                    isVisible
                    fluid={false}
                    dimMode="none"
                    size={this.state.editMenuWidth || 350}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                editMenuWidth: size,
                            });
                        }
                    }}
                >
                    <RadioButtonsGroup
                        node={this.state.node}
                        currentModuleId={this.props.currentModuleId}
                        onChange={onChangeNode}
                        onClose={onClose}
                    />
                </Dock>
            );
        }

        if (isToggle(this.state.node)) {
            return (
                <Dock
                    dockStyle={dockStyle}
                    position="left"
                    isVisible
                    fluid={false}
                    dimMode="none"
                    size={this.state.editMenuWidth || 350}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                editMenuWidth: size,
                            });
                        }
                    }}
                >
                    <Toggle
                        node={this.state.node}
                        shared={this.state.shared}
                        globalContext={globalContext}
                        onChange={onChangeNode}
                        onChangeShared={onChangeShared}
                        onClose={onClose}
                    />
                </Dock>
            );
        }

        if (isInput(this.state.node)) {
            return (
                <Dock
                    dockStyle={dockStyle}
                    position="left"
                    isVisible
                    fluid={false}
                    dimMode="none"
                    size={this.state.editMenuWidth || 350}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                editMenuWidth: size,
                            });
                        }
                    }}
                >
                    <Input
                        currentModuleId={this.props.currentModuleId}
                        node={this.state.node}
                        shared={this.state.shared}
                        globalContext={globalContext}
                        onChange={onChangeNode}
                        onChangeShared={onChangeShared}
                        onClose={onClose}
                    />
                </Dock>
            );
        }
        if (isDropdownSelector(this.state.node) || isFilter(this.state.node)) {
            return (
                <Dock
                    dockStyle={dockStyle}
                    position="left"
                    isVisible
                    fluid={false}
                    dimMode="none"
                    size={this.state.editMenuWidth || 520}
                    onSizeChange={(size) => {
                        if (size >= minLimitEditMenuWidth) {
                            this.setState({
                                editMenuWidth: size,
                            });
                        }
                    }}
                >
                    <DropdownSelector
                        canvasTreeStore={this.props.canvasTreeStore}
                        currentModuleId={this.props.currentModuleId}
                        canvasId={this.props.canvasTreeStore.canvasId!}
                        node={this.state.node}
                        nodeLinkOptions={nodeLinkOptions}
                        shared={this.state.shared}
                        globalContext={globalContext}
                        onChange={
                            onChangeNode as (
                                changes:
                                    | Partial<CanvasDropdownSelector>
                                    | Partial<CanvasFilter>,
                                commit?: boolean
                            ) => void
                        }
                        onChangeShared={onChangeShared}
                        onClose={onClose}
                    />
                </Dock>
            );
        }
        return (
            <Dock
                dockStyle={{
                    backgroundColor: "#FFFFFF",
                    overflow: "auto",
                }}
                position="bottom"
                isVisible={true}
                fluid={false}
                dimMode="none"
                size={this.state.dockSize}
                onSizeChange={this.handleDockSizeChange.bind(this)}
            >
                {this.buildInnerView(globalContext, 60, 26)}
            </Dock>
        );
    }

    render() {
        return (
            <GlobalContext.ObserverConsumer>
                {this.renderContents}
            </GlobalContext.ObserverConsumer>
        );
    }
}

export default AdvancedModeMenu;
