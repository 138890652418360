import React from "react";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";

export default class VACCINES extends React.Component<APIComponentProps, {}> {
    render() {
        return (
            <div
                className="flex-simple-column"
                style={this.props.containerStyle}
            >
                <BaseAPIComponent {...this.props} />
            </div>
        );
    }
}

export { VACCINES as MainComponent };
