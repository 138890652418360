import MessagePopup from 'modules/data_hub_page/MessagePopup';
import { Option } from 'modules/data_hub_page/LeftSideSection';
import SelectedOption from 'modules/data_hub_page/SelectedOption';
import React from 'react'
import DataScopes from 'common/DataScopes';

export default function TabSwitchConfirmationPopup() {
    return (
        <MessagePopup
            title={<p style={{ width: '100%', marginBottom: '20px', textAlign: 'center' }}>Confirm</p>}
            acceptButtonTitle="OK"
            message={`You have unsaved changes. Do you want to leave and discard your changes?`}
            onAccept={() => {
                SelectedOption.UpdateSwitchTab(true);
                SelectedOption.UpdateaskSwitchTab(false);
                SelectedOption.setSelectedOption();
                DataScopes.previewState = { headers: null, body: null }

                if (
                    SelectedOption.clickedOption === Option.Merge ||
                    SelectedOption.clickedOption === Option.Aggregate ||
                    SelectedOption.clickedOption === Option.Clean ||
                    SelectedOption.clickedOption === Option.Create_Variable ||
                    SelectedOption.clickedOption === Option.Reshape
                ) {
                    SelectedOption.changeshowOptions(true);
                }
                else {
                    SelectedOption.changeshowOptions(false);
                }

            }}
            onReject={() => {
                SelectedOption.UpdateSwitchTab(false);
                SelectedOption.UpdateaskSwitchTab(false);
            }}
        />
    )
}