import React, { useState, useRef } from "react";
import cx from "classnames";
import BootstrapDropdown from "react-bootstrap/Dropdown";

import styles from "./Dropdown.module.css";

interface Props {
	children: React.ReactNode;
}

const ANIMATION_STATES = {
	SHOW_START: 'showStart',
	SHOW_END: 'showEnd',
	HIDE_START: 'hideStart',
	HIDE_END: 'hideEnd',
};

function Dropdown({ children }: Props) {
	const [show, setShow] = useState(false);
	const [animationState, setAnimationState] = useState(ANIMATION_STATES.HIDE_END);

	const currentAnimation = useRef<ReturnType<typeof setTimeout> | null>(null);

	function toggle(isOpen: boolean) {
		if (isOpen === show || currentAnimation.current) {
			return;
		}

		if (isOpen) {
			setAnimationState(ANIMATION_STATES.SHOW_START);
			setShow(isOpen);

			currentAnimation.current = setTimeout(() => {
				setAnimationState(ANIMATION_STATES.SHOW_END);
				currentAnimation.current = null;
			}, 200);
		} else {
			setAnimationState(ANIMATION_STATES.HIDE_START);

			currentAnimation.current = setTimeout(() => {
				setAnimationState(ANIMATION_STATES.HIDE_END);
				setShow(isOpen);
				currentAnimation.current = null;
			}, 200);
		}
	}

	return (
		<BootstrapDropdown show={show} onToggle={toggle} className={cx(styles.root, styles[animationState])}>
			{ children }
		</BootstrapDropdown>
	);
}

export default Dropdown;
