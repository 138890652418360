import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { mainStyle } from "common/MainStyle";

export default class PointsSelector extends Component {
    constructor(props) {
        super(props);
        this.state = { userPoints: [] };
    }
    componentDidUpdate(prevProps) {
        let varsLength = this.props.finding?.content?.variables.length ?? 0;
        if (varsLength > 0 && this.state.userPoints.length === 0) {
            if (!this.props.finding?.content?.additionalData) {
                this.setState({ userPoints: new Array(varsLength).fill(null) });
            } else {
                this.setState({
                    userPoints: this.props.finding.content.additionalData.map(
                        (item) => String(item)
                    ),
                });
            }
        }
    }
    render() {
        let varsLength = this.props.finding?.content?.variables.length ?? 0;

        let buttonDisabled =
            this.state.userPoints.length === 0 ||
            varsLength === 0 ||
            this.state.userPoints.reduce(
                (acc, point) => acc || point == null || point?.length === 0,
                false
            );
        return (
            <div
                className="my-row center-container"
                style={{ alignItems: "center" }}
                onMouseDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                <span
                    style={{
                        fontFamily: "Arial",
                        fontSize: "10px",
                        color: mainStyle.getPropertyValue(
                            "--exploration-primary-text-color"
                        ),
                    }}
                >
                    {"Add line or dot: "}
                </span>
                {this.props.disabled && (
                    <div style={{ width: 122 + varsLength * 110 }} />
                )}
                {!this.props.disabled &&
                    this.props.finding.content.variables.map(
                        (variable, index) => (
                            <input
                                key={index}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                style={{
                                    marginLeft: 10,
                                    width: "100px",
                                }}
                                className="input-range"
                                type="number"
                                placeholder={
                                    this.props.finding.config?.nameMapping?.[
                                        variable
                                    ] ?? variable
                                }
                                onChange={(evt) => {
                                    let newUserPoints = Array.from(
                                        this.state.userPoints
                                    );
                                    newUserPoints[index] = evt.target.value;
                                    this.setState({
                                        userPoints: newUserPoints,
                                    });
                                }}
                                defaultValue={this.state.userPoints[index]}
                            />
                        )
                    )}
                {!this.props.disabled && (
                    <Button
                        disabled={buttonDisabled}
                        type="button"
                        style={{
                            width: "112px",
                            marginLeft: 10,
                        }}
                        className="btn btn-xs btn-primary my-primary"
                        onClick={() => {
                            let newFinding = Object.assign(
                                {},
                                this.props.finding
                            );
                            newFinding.content.additionalData =
                                this.state.userPoints.map((point) =>
                                    Number(point)
                                );
                            this.props.onNewFinding(newFinding);
                        }}
                    >
                        SUBMIT
                    </Button>
                )}
            </div>
        );
    }
}
