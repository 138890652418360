import Variables from "common/Variables";

export function updateOriginalNames<
    T extends {
        name: string;
        variableIndex?: number | null;
        originalName?: string | null;
    } | null
>(
    array: T[],
    dataScopeId: number | string,
    moduleId?: number | string | null
): void {
    // We don't need to call Variables(...).update() here. It gets called
    // after updates and in socket.io reactions
    const dataVariables = Variables(dataScopeId, moduleId).dataVariables;
    for (let i = 0; i < array.length; ++i) {
        if (
            array[i]?.variableIndex != null &&
            array[i]!.variableIndex! < dataVariables.length
        ) {
            let newName = dataVariables[array[i]!.variableIndex!].name;
            if (newName !== array[i]!.originalName) {
                array[i] = { ...array[i] };
                if (array[i]!.name === array[i]!.originalName) {
                    array[i]!.name = newName;
                }
                array[i]!.originalName = newName;
            }
        }
    }
}

export function updateOriginalName<
    T extends {
        variableIndex?: number | null;
        originalName?: string | null;
    } | null
>(
    item: T,
    dataScopeId: number | string,
    moduleId?: number | string | null
): void {
    // We don't need to call Variables(...).update() here. It gets called
    // after updates and in socket.io reactions
    const dataVariables = Variables(dataScopeId, moduleId).dataVariables;
    if (
        item?.variableIndex != null &&
        item.variableIndex < dataVariables.length
    ) {
        let newName = dataVariables[item.variableIndex].name;
        if (newName !== item.originalName) {
            item.originalName = newName;
        }
    }
}
