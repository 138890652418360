import React from "react";
import { CanvasRadioButtonsGroupData, RadioButtonsGroupStyle } from "common/Canvas";

function getDefaultStyle() {
	return {
		containerStyle: {
			flexDirection: "column",
		},
		buttonStyle: {
			justifyContent: "space-between",
		},
	} as any; 
}

function getIconChoiceStyle(groupData: CanvasRadioButtonsGroupData[]) {
	let fractionsStr = "";
	for (let i = 0; i < groupData.length ?? 0; i++) {
		fractionsStr += "1fr ";
	}
	
	return {
		containerStyle: {
			display: "grid",
			gridTemplateColumns: fractionsStr,
		},
		buttonStyle: {
			width: "auto",
			margin: "0 10px 0 0",
			height: "auto",
			justifyContent: "flex-end",
			alignItems: "flex-start",
			flexDirection: "column",
		},
		checkIconStyle: {
			position: "absolute",
			top: 0,
			right: 0,
			padding: "4px",
		},
	} as any; 
}

export function getRadioButtonsGroupStyle(
	groupData: CanvasRadioButtonsGroupData[],
	style?: RadioButtonsGroupStyle,
): {
	containerStyle?: React.CSSProperties;
	buttonStyle?: React.CSSProperties;
	checkIconStyle?: React.CSSProperties;
} {
	switch (style) {
		case RadioButtonsGroupStyle.Default:
			return getDefaultStyle();
		case RadioButtonsGroupStyle.IconChoice:
			return getIconChoiceStyle(groupData);
		default:
			return getDefaultStyle();
	}
}