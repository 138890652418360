//scales image by the scale property
export function resizeBase64Img(
    base64: string,
    scale: number
): Promise<{
    thumbnail: string;
}> {
    return new Promise((resolve) => {
        let canvas = document.createElement("canvas");
        let img = new Image();
        img.src = base64;

        img.onload = function () {
            canvas.width = img.width * scale;
            canvas.height = img.height * scale;
            let context = canvas.getContext("2d");
            context?.scale(
                canvas.width / img.width,
                canvas.height / img.height
            );
            context?.drawImage(img, 0, 0);
            let biteStr = canvas.toDataURL();
            canvas.remove();
            resolve({
                thumbnail: biteStr,
            });
        };
    });
}

export function resizeBase64Logo(
    base64: string,
    maxSize: number
): Promise<{
    logo: string;
}> {
    return new Promise((resolve) => {
        let canvas = document.createElement("canvas");
        let img = new Image();
        img.src = base64;

        img.onload = function () {
            const maxDimension = Math.max(img.width, img.height);
            if (maxDimension > maxSize) {
                const scale = maxSize / maxDimension;
                canvas.width = img.width * scale;
                canvas.height = img.height * scale;
                let context = canvas.getContext("2d");
                context?.scale(scale, scale);
                context?.drawImage(img, 0, 0);
                let biteStr = canvas.toDataURL();
                canvas.remove();
                resolve({
                    logo: biteStr,
                });
            }
        };
    });
}
