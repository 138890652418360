import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import {
    LeversOutcomePredictionFinding,
    LeversOutcomeType,
} from "common/Finding";
import { statsmodelsRegressions as regressions } from "common/Regressions";
import { SearchComponentOption } from "common/SearchComponent";
import StringOption from "common/StringOption";

function getCategoryName(name: string, defaultName: string): string[] {
    let displayName: string = "";

    let percentIndex = name.indexOf("%");
    if (percentIndex >= 0) {
        let value = JSON.parse(name.slice(percentIndex + 1));
        let operator;
        if (value == null) {
            value = "NULL";
            operator = "IS";
        } else {
            operator = "=";
        }
        name = name.slice(0, percentIndex);
        displayName = `${name} ${operator} ${value}`;
    } else {
        displayName = defaultName;
    }
    return [displayName, name];
}

class Api {
    static getDefaultConfig(
        journeyName: string
    ): LeversOutcomePredictionFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            dvLog: false,
            independentVariables: {},
            outputType: LeversOutcomeType.Bars,
            selectedRegression: regressions[0],
            showYAxisName: true,
            cacheData: true,
            showXAxisName: true,
        };
    }

    static getPreviewFinding(
        journeyName: string
    ): LeversOutcomePredictionFinding {
        let item = {
            type: "leveroutcome",
            content: {
                data: [
                    {
                        regressionBars: [
                            {
                                name: "intercept",
                                coefficient: 20,
                                significance: 0.95,
                                show: true,
                            },
                            {
                                name: "x1",
                                coefficient: 5,
                                significance: 0.95,
                                show: true,
                            },
                            {
                                name: "x2",
                                coefficient: 10,
                                significance: 0.05,
                                show: true,
                            },
                        ],
                        regressionCurves: {},
                        summary: "",
                    },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static getData(
        finding: LeversOutcomePredictionFinding,
        findingOptions: any,
        moduleId?: number
    ): Promise<LeversOutcomePredictionFinding> {
        finding.loading = true;
        const percentiles = [10, 90];
        const getRegressionInfo = (forQuery: boolean) => {
            let regressionInfo: LeversOutcomePredictionFinding["content"]["regressionInfo"] = {
                dv: dependentVariable!.label,
                dv_log: dvLog,
                iv: {
                    main: ivMain.label,
                    main_log: ivMain.log,
                    main_fixed: ivMain.fixed,
                    other: ivOther.map((item) => item.label),
                    other_log: ivOther.map((item) => item.log),
                    other_fixed: ivOther.map((item) => item.fixed),
                    interacts_with:
                        ivInteractsWith?.label ?? (forQuery ? undefined : null),
                    interacts_with_log:
                        ivInteractsWith?.log ?? (forQuery ? undefined : null),
                },
                intercept: intercept != null,
                model: {
                    name: selectedRegression!,
                    family: selectedFamily ?? (forQuery ? undefined : null),
                    link: selectedLink ?? (forQuery ? undefined : null),
                },
                predict_interval: {
                    count: 100,
                },
            };
            if (ivInteractsWith != null) {
                regressionInfo.predict_interval!.fix = {
                    iv: ivInteractsWith.label,
                    percentiles: percentiles,
                };
            } else {
                regressionInfo.predict_interval!.fix = forQuery
                    ? undefined
                    : null;
            }
            return regressionInfo;
        };
        let {
            dataScope,
            selectedTable,
            conditions,
            additionalOperators,
            sideBySideVariableIndex,
            additionalValues,
            dvLog,
            dependentVariable,
            independentVariables,
            intercept,
            selectedFamily,
            selectedLink,
            selectedRegression,
        } = finding.config ?? {};
        let iv: {
            label: string;
            value: number;
            log: boolean;
            fixed: boolean;
            show: boolean;
            sig: boolean;
            main?: boolean;
            interacts_with?: boolean;
        }[] = Object.values(independentVariables).filter(
            (item) => item != null
        ) as any;
        if (iv.length === 0 || dependentVariable == null)
            return Promise.resolve(finding);
        additionalOperators = (additionalOperators ?? []).filter(
            (operator: StringOption, index: number) =>
                additionalValues[index]?.value != null
        );

        additionalValues = (additionalValues ?? [])
            .filter((value: SearchComponentOption) => value?.value != null)
            .map((value: SearchComponentOption) => value.value);
        conditions = filterNonEmptyConditions(conditions ?? []);
        let requestJson: any = {
            conditions: conditionsToJson(conditions),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope!.value,
            module_id: moduleId,
        };
        let ivMain = iv.find((item) => item.main) ?? iv[0];
        let ivInteractsWith = iv.find((item) => item.interacts_with);

        let ivOther = iv.filter(
            (item) =>
                item.value !== ivMain.value &&
                item.value !== ivInteractsWith?.value
        );

        let regressionInfo = getRegressionInfo(true);
        let regressionInfoCache = getRegressionInfo(false);

        if (sideBySideVariableIndex != null && additionalValues.length > 0) {
            requestJson.where = {
                group_index: sideBySideVariableIndex,
                expressions: additionalValues.map(
                    (value: SearchComponentOption, index: number) => ({
                        operation: additionalOperators[index]?.value ?? "=",
                        value: value,
                    })
                ),
            };
        }
        requestJson = {
            ...requestJson,
            ...regressionInfo,
        };
        return axios
            .post<{
                iv_main_names: string[];
                iv_other_names: string[];
                regression_params: {
                    intercept?: {
                        cof: number;
                        sig: number;
                    };
                    interaction?: {
                        cof: number | number[];
                        sig: number | number[];
                    };
                    main: {
                        cof: number | number[];
                        sig: number | number[];
                    };
                    interacts_with: {
                        cof: number | number[];
                        sig: number | number[];
                    };
                    other: {
                        cof: number;
                        sig: number;
                    }[];
                }[];
                predictions: {
                    iv: {
                        main: number[];
                    };
                    dv: number[] | { [key: string]: number[] };
                    dv_marginal: number[] | { [key: string]: number[] };
                    mean: {
                        iv: {
                            main: number;
                        };
                        dv: number;
                        dv_marginal: number;
                        fix?: number;
                    };
                }[];
                summaries: string[];
                success: boolean;
                error_msg: string;
            }>("/api/e/get_custom_regression", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let result = response.data;
                    let newFinding = { ...finding };
                    // Remove success and error_msg
                    let { success, error_msg, ...nativeData } = response.data;
                    newFinding.content.data = [];
                    newFinding.content.nativeData = nativeData;
                    let regressionParams = result.regression_params;
                    let predictions = result.predictions;
                    let summaries = result.summaries;
                    let seriesLength = regressionParams.length;
                    for (let sIndex = 0; sIndex < seriesLength; ++sIndex) {
                        let regressionBars: LeversOutcomePredictionFinding["content"]["data"][0]["regressionBars"] = [];
                        let regressionSerieParams = regressionParams[sIndex];
                        let predictionSerie = predictions[sIndex];
                        if (regressionSerieParams.intercept != null) {
                            regressionBars.push({
                                name: "intercept",
                                show: intercept.show,
                                coefficient:
                                    regressionSerieParams.intercept.cof,
                                significance:
                                    regressionSerieParams.intercept.sig,
                            });
                        }
                        if (regressionSerieParams.interaction != null) {
                            if (!regressionInfo.iv.main_fixed) {
                                regressionBars.push({
                                    name: "interaction",
                                    show: true,
                                    coefficient: regressionSerieParams
                                        .interaction.cof as number,
                                    significance: regressionSerieParams
                                        .interaction.sig as number,
                                });
                            } else {
                                let length = (regressionSerieParams.interaction
                                    .cof as number[]).length;
                                for (
                                    let interactionIndex = 0;
                                    interactionIndex < length;
                                    ++interactionIndex
                                ) {
                                    regressionBars.push({
                                        name: `interaction_${
                                            interactionIndex + 1
                                        }`,
                                        show: true,
                                        coefficient: (regressionSerieParams
                                            .interaction.cof as number[])[
                                            interactionIndex
                                        ],
                                        significance: (regressionSerieParams
                                            .interaction.sig as number[])[
                                            interactionIndex
                                        ],
                                    });
                                }
                            }
                        }
                        if (regressionSerieParams.interacts_with != null) {
                            regressionBars.push({
                                name: regressionInfo.iv.interacts_with!,
                                show: ivInteractsWith!.show,
                                coefficient: regressionSerieParams
                                    .interacts_with.cof as number,
                                significance: regressionSerieParams
                                    .interacts_with.sig as number,
                            });
                        }
                        if (!regressionInfo.iv.main_fixed) {
                            regressionBars.push({
                                name: regressionInfo.iv.main,
                                show: ivMain.show,
                                coefficient: regressionSerieParams.main
                                    .cof as number,
                                significance: regressionSerieParams.main
                                    .sig as number,
                            });
                        } else {
                            for (
                                let i = 0;
                                i <
                                (regressionSerieParams.main.cof as number[])
                                    .length;
                                ++i
                            ) {
                                let displayName = getCategoryName(
                                    result.iv_main_names[i],
                                    regressionInfo.iv.main
                                )[0];
                                regressionBars.push({
                                    name: displayName,
                                    coefficient: (regressionSerieParams.main
                                        .cof as number[])[i],
                                    show: ivMain.show,
                                    significance: (regressionSerieParams.main
                                        .sig as number[])[i],
                                });
                            }
                        }
                        regressionSerieParams.other?.forEach(
                            (otherItem, index) => {
                                let name = result.iv_other_names[index];
                                let [
                                    displayName,
                                    variableName,
                                ] = getCategoryName(name, name);
                                regressionBars.push({
                                    name: displayName,
                                    show: iv.find(
                                        (item) => item.label === variableName
                                    )!.show,
                                    coefficient: otherItem.cof,
                                    significance: otherItem.sig,
                                });
                            }
                        );
                        let regressionCurves: LeversOutcomePredictionFinding["content"]["data"][number]["regressionCurves"] = {};
                        if (ivInteractsWith == null) {
                            let effectCurve = {
                                x: predictionSerie["iv"].main,
                                y: predictionSerie["dv"] as number[],
                                y_marginal: predictionSerie[
                                    "dv_marginal"
                                ] as number[],
                            };
                            let effectCurveMean = {
                                x: predictionSerie["mean"]["iv"].main,
                                y: predictionSerie["mean"]["dv"],
                                y_marginal:
                                    predictionSerie["mean"]["dv_marginal"],
                            };
                            regressionCurves.effectCurve = effectCurve;
                            regressionCurves.effectCurveMean = effectCurveMean;
                        } else {
                            let effectCurveLow = {
                                x: predictionSerie["iv"].main,
                                y: (predictionSerie["dv"] as {
                                    [key: string]: number[];
                                })[percentiles[0].toString()] as number[],
                                y_marginal: (predictionSerie["dv_marginal"] as {
                                    [key: string]: number[];
                                })[percentiles[0].toString()] as number[],
                            };
                            let effectCurveHigh = {
                                x: predictionSerie["iv"].main,
                                y: (predictionSerie["dv"] as {
                                    [key: string]: number[];
                                })[percentiles[1].toString()] as number[],
                                y_marginal: (predictionSerie["dv_marginal"] as {
                                    [key: string]: number[];
                                })[percentiles[1].toString()] as number[],
                            };
                            let effectCurveMean = {
                                x: predictionSerie["mean"]["iv"].main,
                                y: predictionSerie["mean"]["dv"],
                                y_marginal:
                                    predictionSerie["mean"]["dv_marginal"],
                                z: predictionSerie["mean"]["fix"],
                            };
                            regressionCurves.effectCurveHigh = effectCurveHigh;
                            regressionCurves.effectCurveLow = effectCurveLow;
                            regressionCurves.effectCurveMean = effectCurveMean;
                        }

                        newFinding.content.data.push({
                            regressionBars: regressionBars,
                            regressionCurves: regressionCurves,
                            summary: summaries[sIndex],
                        });
                    }
                    let expressions: {
                        operation: StringOption;
                        value: SearchComponentOption;
                    }[] = [];
                    if (
                        finding.config.sideBySideVariable != null &&
                        finding.config.additionalValues != null &&
                        finding.config.additionalOperators != null
                    ) {
                        expressions = finding.config.additionalValues.map(
                            (value: SearchComponentOption, index: number) => ({
                                operation:
                                    finding.config.additionalOperators[index],
                                value: value,
                            })
                        );
                    }
                    expressions = expressions.filter(
                        (item) => item.value != null
                    );
                    let groupNames = expressions.map(
                        (expression) =>
                            `${finding.config.sideBySideVariable} ${expression.operation.label} ${expression.value.label}`
                    );
                    newFinding.content.groupNames =
                        groupNames.length > 0 ? groupNames : null;
                    newFinding.content.regressionInfo = regressionInfoCache;
                    newFinding.loading = false;

                    return Promise.resolve(newFinding);
                } else {
                    finding.loading = false;

                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
                finding.loading = false;

                return Promise.reject(error);
            });
    }
}

export { Api };
