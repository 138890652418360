import React from "react";
import ReactDOM from "react-dom";
import domtoimage from "dom-to-image";
import Hermite_class from "hermite-resize";
import delay from "common/utilities/delay";
import CanvasContent from "../CanvasContent";
import CanvasTreeStore from "../CanvasTreeStore";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import Canvases from "common/Canvases";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import { renderHtmlRef } from "common/utilities/renderIcon";

export async function renderCanvas(canvasTreeStore) {
    const { scale } = canvasTreeStore;
    let root = document.getElementById("canvas-root-element");
    let cropRect = canvasTreeStore
        .layerRect(canvasTreeStore.scale, PagesStore(PageType.Canvases).pages)
        .get();
    cropRect.x *= scale;
    cropRect.y *= scale;
    cropRect.width *= scale;
    cropRect.height *= scale;
    const image = await renderHtmlRef(
        { current: root },
        cropRect.x,
        cropRect.y,
        cropRect.width,
        cropRect.height,
        false,
        true
    );
    return image;
}

export async function renderPreview(
    ref,
    canvasTreeStore,
    moduleId,
    customSlideNumber,
    wait = 0,
    asThumbnail = false
) {
    const el = document.createElement("div");
    let slideRect = canvasTreeStore.slideRect();
    let scale = canvasTreeStore.scale;
    el.style.position = "absolute";
    el.style.left = "-9999px";
    let slideWidth = slideRect.width * scale;
    let slideHeight = slideRect.height * scale;
    el.style.width = `${slideWidth}px`;
    el.style.height = `${slideHeight}px`;
    await ReactDOM.render(
        <DndProvider backend={HTML5Backend}>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <CanvasContent
                    canvasTreeStore={canvasTreeStore}
                    moduleId={moduleId}
                    showAsSlideShow={false}
                    customSlideNumber={customSlideNumber}
                    ribbonIsOpen={false}
                    sharedPolicy={CanvasSharedPolicy.SharedModuleUnAuth}
                    onZoomChange={() => {}}
                    onOpenBottomPortal={() => {}}
                    hidden={false}
                    canWrite={false}
                    scale={scale}
                    live={canvasTreeStore.live}
                    selectionIsActive={false}
                    showPageBar={false}
                    sharedModule={null}
                    onExpandCard={() => {}}
                    onExpandUserCard={() => {}}
                />
            </div>
        </DndProvider>,
        el
    );
    ref.current.appendChild(el);
    if (wait) await delay(wait);
    return Promise.resolve(
        domtoimage
            .toPixelData(el.firstChild, {
                imagePlaceholder:
                    // Transparent 1x1 placeholder to prevent domtoimage from
                    // crashing if some of the images were deleted from media
                    // collections
                    // https://eisengardai.atlassian.net/browse/EIS-698
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==",
            })
            .then((pixels) => {
                let canvas = document.createElement("canvas");
                canvas.width = el.firstChild.scrollWidth;
                canvas.height = el.firstChild.scrollHeight;
                const ctx = canvas.getContext("2d");

                let imgData = ctx.createImageData(canvas.width, canvas.height);
                for (let i = 0; i < imgData.data.length; i += 1) {
                    imgData.data[i] = pixels[i];
                }
                ctx.putImageData(imgData, 0, 0);

                imgData = ctx.getImageData(0, 0, slideWidth, slideHeight);
                canvas.width = slideWidth;
                canvas.height = slideHeight;
                ctx.putImageData(imgData, 0, 0);

                if (asThumbnail) {
                    return new Promise((resolve, reject) => {
                        var HERMITE = new Hermite_class();
                        HERMITE.resample(canvas, 432, 252, true, () => {
                            let url = canvas.toDataURL();
                            ref.current.removeChild(el);
                            resolve(url);
                        });
                    });
                }

                let url = canvas.toDataURL();
                ref.current.removeChild(el);
                return Promise.resolve(url);
            })
            .catch((error) => {
                ref.current.removeChild(el);
                return Promise.reject(error);
            })
    );
}

// Render thumbnail for a slide that is not currently rendered
export async function renderThumbnailOfNotRenderedCanvas(
    ref,
    canvasId,
    pageId,
    moduleId
) {
    let fetchedCanvas = await Canvases(pageId).getFullCanvas(canvasId, false);
    let canvasTreeStore = new CanvasTreeStore(true);
    canvasTreeStore.scale = 2;
    canvasTreeStore.moduleId = moduleId;
    canvasTreeStore.canvasPageId = pageId;
    await canvasTreeStore.deserializeAsyncAction.bothParts(
        { ...fetchedCanvas.canvas, mapElementsState: {} },
        {
            canvasId: fetchedCanvas.id,
            backgrounds: fetchedCanvas.backgrounds,
            delegateId: fetchedCanvas.delegate_id,
        }
    );

    canvasTreeStore.updateCanvasSizeAction(canvasTreeStore.slideRect());
    const pageImage = await renderPreview(
        ref,
        canvasTreeStore,
        moduleId,
        undefined,
        5000,
        false
    );

    return pageImage;
}
