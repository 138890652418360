import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { LinePlotFinding } from "common/Finding";
import { configVersionV2 } from "common/PathConfigVersion";
import StringOption from "common/StringOption";
import { SearchComponentOption } from "common/SearchComponent";
import { aggregationFunctions } from "common/AggregationFunctionsv2";
// import {
//     TooltipVariableOption,
//     defaultTooltipVariableOptions,
// } from "common/ConfigurableTooltip";
import { updateOriginalName, updateOriginalNames } from "../../common/Utils";

// Line Plot Graph produces the same graphics as Time Patterns Graph
// timeParameter can be a non-time variable, the name is not changed
// for backward compatibility

class Api {
    static getDefaultConfig(journeyName: string): LinePlotFinding["config"] {
        return {
            count: 100,
            random: false,
            journeyName: journeyName,
            operationVariable: aggregationFunctions[0].value,
            version: configVersionV2,
            showXAxisName: true,
            showYAxisName: true,
            showGrid: false,
            formatAxises: {
                xAxis: {
                    numberType: 1,
                    useAbbreviation: false,
                    useCommaSeparator: true,
                    decimalPoints: 0,
                },
                yAxis: {
                    numberType: 1,
                    useAbbreviation: false,
                    useCommaSeparator: true,
                    decimalPoints: 0,
                },
            },
        };
    }
    static getPreviewFinding(journeyName: string): LinePlotFinding {
        let item = {
            type: "lineplot",
            content: {
                isTimeSeries: false,
                data: [
                    {
                        name: "X series",
                        value: [[1, 5, 7, 10]],
                    },
                    {
                        name: "Y series",
                        value: [[2, 4, 12, 23]],
                    },
                    {
                        name: "Y series 2",
                        value: [[4, 8, 24, 50]],
                    },
                ],
                time: {
                    name: "time",
                    value: [],
                    uniqueValues: [],
                },
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static async getData(
        finding: LinePlotFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<LinePlotFinding> {
        let {
            count,
            dataScope,
            operationVariable,
            selectedTable,
            conditions,
            additionalOperators,
            additionalValues,
            sideBySideVariableIndex,
            groupByAll,
        } = finding.config ?? {};
        let variables = finding.content.data.filter(
            (item) => item.variableIndex != null
        );
        // data[0] is the x axis
        if (
            finding.content.data[0].variableIndex == null ||
            variables.length < 2
        ) {
            // This prevents the warning from appearing when the user dragged
            // a variable for one axis, but not for the other one
            return Promise.resolve(finding);
        }
        groupByAll = groupByAll ?? true;
        if (variables.length > 2) groupByAll = false;
        conditions = filterNonEmptyConditions(conditions ?? []);
        additionalOperators = (additionalOperators ?? []).filter(
            (operator: StringOption, index: number) =>
                additionalValues[index]?.value != null
        );

        additionalValues = (additionalValues ?? [])
            .filter((value: SearchComponentOption) => value?.value != null)
            .map((value: SearchComponentOption) => value.value);

        let requestJson: any = {
            count: count,
            group_operation:
                operationVariable === "count_distinct"
                    ? "count"
                    : operationVariable,
            x_axis_index: variables[0].variableIndex,
            x_time_interval:
                finding.config.ticksAndLabels?.x?.intervalUnit ?? "day",
            variable_indices: variables
                .map((variable) => variable.variableIndex)
                .slice(1),
            conditions: conditionsToJson(conditions),
            table: selectedTable!.value,
            condition_id: selectedTable!.condition_id,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope!.value,
            module_id: moduleId,
        };

        // Set time variable idx
        const timeVariableIdx = finding.content.time?.variableIndex;
        if (timeVariableIdx != null) {
            requestJson.variable_indices.push(timeVariableIdx);
        }

        if (
            sideBySideVariableIndex != null &&
            additionalValues.length > 0 &&
            !groupByAll
        ) {
            requestJson.where = {
                group_index: sideBySideVariableIndex,
                expressions: groupByAll
                    ? []
                    : additionalValues.map(
                          (value: SearchComponentOption, index: number) => ({
                              operation:
                                  additionalOperators[index]?.value ?? "=",
                              value: value,
                          })
                      ),
            };
        }
        if (sideBySideVariableIndex != null && groupByAll) {
            requestJson.group_by_all = sideBySideVariableIndex;
        }

        return axios
            .post<{
                values: {
                    curves: { [key: string]: number }[];
                    where?: {
                        value?: string | number;
                        group_name?: string;
                        operation?: string;
                    };
                }[];
                x_axis_type: string;
                success: boolean;
                error_msg: string;
            }>("/api/e/get_line_plot", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newFinding = {
                        ...finding,
                        config: {
                            ...finding.config,
                            tooltipConfig: {
                                ...finding.config.tooltipConfig,
                            },
                        },
                        content: {
                            ...finding.content,
                            data: finding.content.data.map((item) => ({
                                ...item,
                            })),
                        },
                    };
                    // Check wherther variables were renamed
                    updateOriginalNames(
                        newFinding.content.data,
                        dataScope.value,
                        moduleId
                    );

                    let values = response.data.values;
                    let dataItems = newFinding.content.data.filter(
                        (item) => item.variableIndex != null
                    );
                    for (let dataItem of dataItems) {
                        dataItem.value = [[]];
                    }
                    newFinding.content.isTimeSeries =
                        response.data.x_axis_type === "datetime";

                    newFinding.content.groupNames = values.map((item) =>
                        item.where
                            ? `${item.where.group_name} ${item.where.operation} ${item.where.value}`
                            : "value"
                    );
                    newFinding.content.groupInfo = values.map((item) =>
                        item.where
                            ? {
                                  name: item.where.group_name ?? "",
                                  operation: item.where.operation ?? "",
                                  value: `${item.where.value}`,
                              }
                            : {
                                  name: "",
                                  value: "value",
                                  operation: "",
                              }
                    );

                    for (let i = 0; i < values.length; ++i) {
                        let curves = values[i].curves;
                        let transformedCurves: {
                            [key: string]: (number | string)[];
                        } = {};
                        for (let curve of curves) {
                            for (let key in curve) {
                                if (transformedCurves[key] == null)
                                    transformedCurves[key] = [];
                                transformedCurves[key].push(curve[key]);
                            }
                        }
                        for (let dataItem of newFinding.content.data) {
                            if (dataItem.originalName == null) continue;
                            let curve =
                                transformedCurves[dataItem.originalName];
                            if (curve != null) {
                                dataItem.value[i] = curve;
                            }
                        }

                        if (finding.content.time?.originalName) {
                            const timeVariableName = finding.content.time!
                                .originalName;
                            newFinding.content.time!.value =
                                transformedCurves[timeVariableName];
                            newFinding.content.time!.uniqueValues = Array.from(
                                new Set(
                                    transformedCurves[timeVariableName].filter(
                                        (item): item is string | number =>
                                            item != null
                                    )
                                )
                            ).sort((value1, value2) =>
                                typeof value1 === "number" &&
                                typeof value2 === "number"
                                    ? value1 - value2
                                    : String(value1).localeCompare(
                                          String(value2)
                                      )
                            );
                            // Check wherther variables were renamed
                            updateOriginalName(
                                newFinding.content.time!,
                                dataScope.value,
                                moduleId
                            );
                        }
                    }

                    // Do not remove this code. It is necessary for a future item.
                    // newFinding.config.tooltipConfig.tooltipVariables = dataItems.map(
                    //     (item): TooltipVariableOption => ({
                    //         variable: {
                    //             label: item.name,
                    //             value: item.variableIndex!,
                    //         },
                    //         options: defaultTooltipVariableOptions,
                    //     })
                    // );

                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
