import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { CanvasNode, CanvasRadioButtonsGroup } from "common/Canvas";
import TabTitle from "common/TabTitle";
import Settings from "./Settings";

import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";

import styles from "./RadioButtonsGroup.module.css";

interface Props {
  node: CanvasRadioButtonsGroup;
  currentModuleId: number | undefined;
  onChange: (changes: Partial<CanvasRadioButtonsGroup | CanvasNode>, commit?: boolean) => void;
  onClose: () => void;
}

function Toggle({
  node,
  currentModuleId,
  onChange,
  onClose,
}: Props) {
  const [selectedTab, setSelectedTab] = useState("settings");

  function selectTab(tabKey: string | null) {
    if (tabKey == null) return;
    setSelectedTab(tabKey);
  }

  return (
    <div>
      <div className={styles.editMenuHeader}>
        <h6 className={styles.editMenuHeaderTitle}>Radio button</h6>
        <button
          type="button"
          onClick={onClose}
          className={styles.editMenuCloseButton}
        >
          <CloseIcon />
        </button>
      </div>
      <Tabs
        id="advanced-menu-toggle-tabs"
        activeKey={selectedTab}
        onSelect={selectTab}
        className={styles.tabMenu}
      >
        <Tab
          eventKey="settings"
          title={
            <TabTitle
              title="Settings"
              selected={selectedTab === "settings"}
              className={styles.tabTitle}
            />
          }
          className={styles.tab}
        >
          <Settings
            node={node}
            currentModuleId={currentModuleId}
            onChange={onChange}
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Toggle;
