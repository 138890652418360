import React, { Component, RefObject } from "react";

export default class ScrollableRow extends Component<{}> {
    private parentScrollRef: RefObject<HTMLDivElement>;
    private scrollRef: RefObject<HTMLDivElement>;
    private scrollLeft: number;

    constructor(props: {}) {
        super(props);
        this.parentScrollRef = React.createRef();
        this.scrollRef = React.createRef();
        this.scrollLeft = 0;
        this.scrollToEnd = this.scrollToEnd.bind(this);
    }

    private scrollToEnd(): void {
        if (this.scrollRef.current != null) {
            this.scrollRef.current.scrollLeft = this.scrollRef.current.scrollWidth;
            this.scrollLeft = this.scrollRef.current.scrollLeft;
        }
    }

    componentDidUpdate() {
        if (this.scrollRef.current != null)
            this.scrollRef.current.scrollLeft = this.scrollLeft;
    }

    render() {
        return (
            <div
                className="my-row"
                style={{
                    width: "100%",
                    paddingLeft: "10px",
                    overflow: "hidden",
                }}
                ref={this.parentScrollRef}
                onWheel={(_evt) => {
                    if (this.scrollRef.current != null)
                        this.scrollLeft = this.scrollRef.current.scrollLeft;
                }}
                onTouchEnd={(_evt) => {
                    if (this.scrollRef.current != null)
                        this.scrollLeft = this.scrollRef.current.scrollLeft;
                }}
            >
                <div
                    style={{ width: 55, height: 324, marginRight: 5 }}
                    onClick={() => {
                        if (this.scrollRef.current != null) {
                            this.scrollRef.current.scrollLeft -= Math.min(
                                3 * 357,
                                this.scrollRef.current.clientWidth
                            );
                            this.scrollLeft = this.scrollRef.current.scrollLeft;
                        }
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/insights/scroll_insight_left.png"
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div
                    ref={this.scrollRef}
                    className="my-row hide-scroll"
                    style={{ width: "100%", overflow: "auto" }}
                >
                    {React.Children.only(this.props.children)}
                </div>
                <div
                    style={{ width: 55, height: 324, marginLeft: 5 }}
                    onClick={() => {
                        if (this.scrollRef.current != null) {
                            this.scrollRef.current.scrollLeft += Math.min(
                                3 * 357,
                                this.scrollRef.current.clientWidth
                            );
                            this.scrollLeft = this.scrollRef.current.scrollLeft;
                        }
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/insights/scroll_insight_right.png"
                        style={{ cursor: "pointer" }}
                    />
                </div>
            </div>
        );
    }
}