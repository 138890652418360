import React from "react";
import { ScatterChartType } from "common/ScatterChartType";
import { mainStyle } from "common/MainStyle";
import HistogramChart, { Histogram } from "common/graphics/HistogramChart";
import PlotlyScatterChart, {
	PlotlyScatter,
	PlotlyScatterWithLabel,
} from "common/graphics/PlotlyScatterChart";
import { LegendEditElement } from "./LegendEditElement";
import { colorList } from "./LineColors";

interface Config {
	chartColor?: string;
	nameMapping?: { [key: string]: string };
	linesCount?: number | null;
	axesColor?: string;
	maxYRange?: number | null;
	minYRange?: number | null;
	barSize?: number | null;
}

interface Props {
	editable?: boolean;
	data: Histogram[] | PlotlyScatter[];
	variables: string[];
	hoverVariables?: string[];
	units: string[];
	label?: string;
	type: ScatterChartType;
	additionalData?: number[];
	config?: Config;
	onFinishEditConfig: (config: Config) => void;
}

export default function ScatterExplorationChartWrapper(props: Props) {
	let editable = props.editable ?? false;
	let { nameMapping, axesColor, chartColor } = props.config ?? {};
	if (!editable) return <ScatterExplorationChart {...props} />;
	else
		return (
			<div
				className="flex-simple-column"
				style={{ width: "100%", height: "100%" }}
			>
				<div
					className="my-row"
					style={{ alignItems: "center", justifyContent: "center" }}
				>
					{props.variables.map((variable, index) => (
						<React.Fragment key={index}>
							<span
								style={{
									marginLeft: 10,
									color: mainStyle.getPropertyValue(
										"--graphs-legends-text-color"
									),
									fontFamily: "Arial",
									fontSize: mainStyle.getPropertyValue(
										"--graphs-legends-size"
									),
								}}
							>
								{`${String.fromCharCode(
									"X".charCodeAt(0) + index
								)}-axis:`}
							</span>
							<input
								defaultValue={variable}
								onKeyDown={(evt) => {
									evt.stopPropagation();
								}}
								onBlur={(evt) => {
									evt.preventDefault();
									let label = evt.target.value;
									let newNameMapping = {
										...nameMapping,
										[variable]: label,
									};
									let newConfig = {
										...props.config,
										nameMapping: newNameMapping,
									};
									props.onFinishEditConfig(newConfig);
								}}
								style={{
									width: "100px",
									backgroundColor: "transparent",
									outline: "none",
									marginLeft: 5,
									color: mainStyle.getPropertyValue(
										"--graphs-legends-text-color"
									),
									fontFamily: "Arial",
									fontSize: mainStyle.getPropertyValue(
										"--graphs-legends-size"
									),
								}}
							/>
						</React.Fragment>
					))}
					{props.variables.length > 1 && props.label != null && (
						<React.Fragment>
							<span
								style={{
									marginLeft: 10,
									color: mainStyle.getPropertyValue(
										"--graphs-legends-text-color"
									),
									fontFamily: "Arial",
									fontSize: mainStyle.getPropertyValue(
										"--graphs-legends-size"
									),
								}}
							>
								{`Label:`}
							</span>
							<input
								defaultValue={props.label}
								onKeyDown={(evt) => {
									evt.stopPropagation();
								}}
								onBlur={(evt) => {
									evt.preventDefault();
									let label = evt.target.value;
									let newNameMapping = {
										...nameMapping,
										[props.label!]: label,
									};
									let newConfig = {
										...props.config,
										nameMapping: newNameMapping,
									};
									props.onFinishEditConfig(newConfig);
								}}
								style={{
									width: "100px",
									backgroundColor: "transparent",
									outline: "none",
									marginLeft: 5,
									color: mainStyle.getPropertyValue(
										"--graphs-legends-text-color"
									),
									fontFamily: "Arial",
									fontSize: mainStyle.getPropertyValue(
										"--graphs-legends-size"
									),
								}}
							/>
						</React.Fragment>
					)}
					<LegendEditElement
						allowColorChange
						disallowTextChange
						onFinishColor={(color: string) => {
							let newConfig = {
								...props.config,
								chartColor: color,
							};
							props.onFinishEditConfig(newConfig);
						}}
						color={chartColor ?? colorList[1]}
						text={"Items Color"}
					/>
					<LegendEditElement
						allowColorChange
						disallowTextChange
						onFinishColor={(color: string) => {
							let newConfig = {
								...props.config,
								axesColor: color,
							};
							props.onFinishEditConfig(newConfig);
						}}
						color={
							axesColor ??
							mainStyle
								.getPropertyValue("--graphs-axes-text-color")
								.trim()
						}
						text={"Axes Color"}
					/>
				</div>
				<ScatterExplorationChart {...props} />
			</div>
		);
}

export function ScatterExplorationChart(props: Props): JSX.Element | null {
	switch (props.type) {
		case ScatterChartType.Histogram:
			return (
				<HistogramChart
					nameMapping={props.config?.nameMapping}
					chartColor={props.config?.chartColor}
					barSize={props.config?.barSize}
					axesColor={props.config?.axesColor}
					maxYRange={props.config?.maxYRange}
					minYRange={props.config?.minYRange}
					linesCount={props.config?.linesCount}
					data={props.data as Histogram[]}
					variables={props.variables}
					units={props.units}
					additionalData={props.additionalData}
				/>
			);
		case ScatterChartType.TwoD:
			return (
				<PlotlyScatterChart
					nameMapping={props.config?.nameMapping}
					chartColor={props.config?.chartColor}
					axesColor={props.config?.axesColor}
					labels={
						props.label != null
							? (props.data as PlotlyScatterWithLabel[]).map(
									(scatter) =>
										scatter[props.label!] as string[]
							  )
							: undefined
					}
					label={props.label}
					hoverVariables={props.hoverVariables}
					data={props.data as PlotlyScatter[]}
					variables={props.variables}
					units={props.units}
					type={props.type}
					additionalData={props.additionalData}
				/>
			);
		case ScatterChartType.ThreeD:
			return (
				<PlotlyScatterChart
					nameMapping={props.config?.nameMapping}
					chartColor={props.config?.chartColor}
					axesColor={props.config?.axesColor}
					labels={
						props.label != null
							? (props.data as PlotlyScatterWithLabel[]).map(
									(scatter) =>
										scatter[props.label!] as string[]
							  )
							: undefined
					}
					label={props.label}
					hoverVariables={props.hoverVariables}
					data={props.data as PlotlyScatter[]}
					variables={props.variables}
					units={props.units}
					type={props.type}
					additionalData={props.additionalData}
				/>
			);
		default:
			return null;
	}
}