import React from "react";
import PropTypes from "prop-types";
import reactCSS from "reactcss";

import { Swatch } from "react-color/lib/components/common";

export const CustomSketchPresetColors = ({
    colors,
    onClick = () => {},
    onSwatchHover,
    presetColorsTitle = undefined,
}) => {
    const styles = reactCSS(
        {
            default: {
                colors: {
                    margin: "0 -10px",
                    padding: "0 0 0 10px",
                    display: "flex",
                    flexWrap: "wrap",
                    position: "relative",
                },
                swatchWrap: {
                    width: "16px",
                    height: "16px",
                    margin: "0 10px 10px 0",
                },
                swatch: {
                    borderRadius: "3px",
                    boxShadow: "inset 0 0 0 1px rgba(0,0,0,.15)",
                },
                label: {
                    display: "block",
                    textAlign: "center",
                    fontSize: "11px",
                    color: "#222",
                    paddingTop: "3px",
                    paddingBottom: "4px",
                    textTransform: "capitalize",
                },
            },
            "no-presets": {
                colors: {
                    display: "none",
                },
            },
        },
        {
            "no-presets": !colors || !colors.length,
        }
    );

    const handleClick = (hex, e) => {
        onClick(
            {
                hex,
                source: "hex",
            },
            e
        );
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid #eee",
            }}
        >
            {(presetColorsTitle != null && colors.length !== 0 ) && (
                <div style={styles.label}>{presetColorsTitle}</div>
            )}
            <div style={styles.colors} className="flexbox-fix">
                {colors.map((colorObjOrString) => {
                    const c =
                        typeof colorObjOrString === "string"
                            ? { color: colorObjOrString }
                            : colorObjOrString;
                    const key = `${c.color}${c.title || ""}`;
                    return (
                        <div key={key} style={styles.swatchWrap}>
                            <Swatch
                                {...c}
                                style={styles.swatch}
                                onClick={handleClick}
                                onHover={onSwatchHover}
                                focusStyle={{
                                    boxShadow: `inset 0 0 0 1px rgba(0,0,0,.15), 0 0 4px ${c.color}`,
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

CustomSketchPresetColors.propTypes = {
    colors: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                color: PropTypes.string,
                title: PropTypes.string,
            }),
        ])
    ).isRequired,
};

export default CustomSketchPresetColors;
