import React, { useState, CSSProperties } from "react";
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import cx from "classnames";

import Finding from "common/Finding";
import { DataScopeOption } from "common/DataScopes";
import remoteModuleId from "common/remoteModuleId";
import Tables, { TableOption } from "common/Tables";
import DataScopeAndTableSelectorView from "modules/canvas_page/question_views/RevampedDataScopeAndTableSelectorView";
import ConditionsConfig from "../ConditionsConfig";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import styles from "./DataConfig.module.css";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

interface Props<F extends Finding> {
    finding: F;
    onChange: (finding: F, update?: boolean) => void;
    currentModuleId?: number;
    canvasTreeStore: CanvasTreeStore;
    /// Can optionally reset additional fields in the config
    resetFields?: (newFinding: F) => void;
    style?: CSSProperties;
}

function DataConfig<F extends Finding>({
    finding,
    currentModuleId,
    canvasTreeStore,
    onChange,
    resetFields,
    style,
}: Props<F>) {
    const [advancedOptionsIsOpen, setAdvancedOptionsIsOpen] = useState(false);

    function toggleOpenAdvancedOptions() {
        setAdvancedOptionsIsOpen((prev) => !prev);
    }

    return (
        <div className={styles.root} style={style}>
            <DataScopeAndTableSelectorView
                needWritePermissions={false}
                dataScopeOption={finding.config.dataScope}
                tableOption={finding.config.tableOption}
                maxHeight={100}
                onChange={(
                    dataScopeOption: DataScopeOption | null,
                    tableOption: TableOption | null
                ) => {
                    let newFinding: F = {
                        ...finding,
                        config: {
                            ...finding.config,
                            dataScope: dataScopeOption,
                            usesCoordinates: false,
                            tableOption: tableOption,
                        },
                    };
                    resetFields?.(newFinding);
                    onChange(newFinding, true);
                }}
                currentModuleId={currentModuleId}
            />
            <button
                type="button"
                className={styles.editMenuAdvancedCollapseButton}
                onClick={toggleOpenAdvancedOptions}
            >
                Advanced options
                <ChevronIcon
                    className={cx(styles.editMenuAdvancedCollapseButtonIcon, {
                        [styles.chevronOpen]: advancedOptionsIsOpen,
                    })}
                />
            </button>
            <Collapse in={advancedOptionsIsOpen}>
                <div className={styles.advancedMenu}>
                    {finding.config.dataScope && (
                        <>
                            <Select
                                placeholder="Select table"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: "4px",
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none",
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={
                                    Tables(
                                        finding.config.dataScope!.value,
                                        currentModuleId ?? remoteModuleId
                                    ).rawAndAggregateTableOptions
                                }
                                onChange={(newValue) => {
                                    let newFinding: F = {
                                        ...finding,
                                        config: {
                                            ...finding.config,
                                            dataScope: finding.config.dataScope,
                                            tableOption: newValue as TableOption,
                                        },
                                    };
                                    onChange(newFinding, true);
                                }}
                                value={finding.config.tableOption}
                            />
                            <ConditionsConfig
                                canvasTreeStore={canvasTreeStore}
                                finding={finding}
                                onChange={onChange}
                                currentModuleId={currentModuleId}
                            />
                        </>
                    )}
                </div>
            </Collapse>
        </div>
    );
}

export default DataConfig;
