import React, { Component } from "react";
import { components } from "react-select";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const MultiValueRemove = (props: any) => {
	return (
		<components.MultiValueRemove {...props}>
			<i
				className="fa fa-minus"
				style={{ color: "#39F" }}
				aria-hidden="true"
			></i>
		</components.MultiValueRemove>
	);
};

const DropdownIndicator = (props: any) => {
	return (
		<components.MultiValueRemove {...props}>
			<i
				className="fa fa-plus"
				aria-hidden="true"
				style={{ color: "#39F" }}
			></i>
		</components.MultiValueRemove>
	);
};

interface State {
	top: number;
	selectCompIsOpened: boolean;
}

class CustomSelect extends Component<any, State> {
	selectRef: React.RefObject<any>;
	constructor(props: any) {
		super(props);
		this.state = {
			top: 0,
			selectCompIsOpened: false,
		};
		this.selectRef = React.createRef();
	}
	public focus() {
		if (this.selectRef.current != null) {
			this.selectRef.current.focus();
		}
	}

	handleCloseMenuOnScroll = () => {
		// forcing select menu re-rendering on scroll
		if (this.state.selectCompIsOpened) {
            this.setState({ top: 0 });
        }
		// as expected do not close the menu on scroll
		return false;
	  }

	render() {
		return this.props.isCreatable ? (
			<CreatableSelect
				ref={this.selectRef}
				components={
					this.props.customIndicators
						? { MultiValueRemove, DropdownIndicator }
						: {}
				}
				{...this.props}
			/>
		) : (
			<Select
				ref={this.selectRef}
				components={
					this.props.customIndicators
						? { MultiValueRemove, DropdownIndicator }
						: {}
				}
				{...this.props}
				onMenuOpen={() => {
					this.setState({
						selectCompIsOpened: true,
					});
				}}
				onMenuClose={() => {
					this.setState({
						selectCompIsOpened: false,
					});
				}}
				closeMenuOnScroll={this.handleCloseMenuOnScroll}
			/>
		);
	}
}

export default CustomSelect;