import React from "react";
import Select from "react-select";

import {
    MapFinding,
    ChoroplethLevel,
    choroplethLevelLabel,
} from "common/Finding";
import remoteModuleId from "common/remoteModuleId";
import Variables, { VariableOption } from "common/Variables";
import StringOption from "common/StringOption";
import ReactTooltip from "react-tooltip";

import styles from "./ChoroplethConfig.module.css";

const levelOptions: ReadonlyArray<StringOption> = Object.entries(
    choroplethLevelLabel
).map(([value, label]) => ({ label: label, value: value }));

interface Props {
    mapFinding: MapFinding;
    onChange: (finding: MapFinding, update?: boolean) => void;
    currentModuleId?: number;
}

function ChoroplethConfig({ mapFinding, onChange, currentModuleId }: Props) {
    let variableOptions = Variables(
        mapFinding.config.dataScope?.value,
        currentModuleId ?? remoteModuleId
    ).variableOptions;

    let numericVariableOptions = Variables(
        mapFinding.config.dataScope?.value,
        currentModuleId ?? remoteModuleId
    ).numericVariableOptions;
    return (
        <div className={styles.root}>
            <div className={styles.field}>
                <div className={styles.label}>
                    <span className={styles.label}>Level</span>
                    {mapFinding.config.choroplethLevel === "county_us" && (
                        <>
                            <span
                                className="regular-text unselectable"
                                data-tip={
                                    'Examples: "Garfield", "Garfield, CO", ' +
                                    '"Garfield, Colorado", "Garfield County", ' +
                                    '"Garfield County, CO", "Garfield County, Colorado"'
                                }
                                data-for="tooltip-choropleth-county"
                                data-event="click"
                                data-eventOff="scroll mousewheel"
                                data-effect="solid"
                                data-isCapture={true}
                                style={{
                                    color: "var(--secondary-text-color)",
                                    textDecoration: "underline",
                                    marginLeft: 1,
                                    cursor: "pointer",
                                }}
                            >
                                ?
                            </span>
                            <ReactTooltip id="tooltip-choropleth-county" />
                        </>
                    )}
                </div>
                <div
                    className={styles.fieldSelect}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    onMouseDown={(evt: any) => {
                        evt.stopPropagation();
                    }}
                >
                    <Select
                        placeholder=""
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                borderRadius: 4,
                            }),
                            container: (base) => ({
                                ...base,
                                flexGrow: 1,
                            }),
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                            }),
                            menu: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={levelOptions}
                        onChange={(newValue) => {
                            onChange(
                                {
                                    ...mapFinding,
                                    config: {
                                        ...mapFinding.config,
                                        choroplethLevel: (newValue as StringOption | null)
                                            ?.value,
                                    },
                                },
                                true
                            );
                        }}
                        value={levelOptions.find(
                            (option) =>
                                option.value ===
                                (mapFinding.config.choroplethLevel ?? "country")
                        )}
                    />
                </div>
            </div>
            {mapFinding.config.choroplethLevel === "county_us" && (
                <div className={styles.field}>
                    <span className={styles.label}>
                        Uses data from U.S. Census Bureau
                    </span>
                </div>
            )}
            <div className={styles.field}>
                <span className={styles.label}>
                    {
                        choroplethLevelLabel[
                            (mapFinding.config.choroplethLevel ??
                                "country") as ChoroplethLevel
                        ]
                    }
                </span>
                <div
                    className={styles.fieldSelect}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    onMouseDown={(evt: any) => {
                        evt.stopPropagation();
                    }}
                >
                    <Select
                        placeholder=""
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                borderRadius: 4,
                            }),
                            container: (base) => ({
                                ...base,
                                flexGrow: 1,
                            }),
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                            }),
                            menu: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={variableOptions}
                        onChange={(newValue) => {
                            const newFinding: MapFinding = {
                                ...mapFinding,
                                config: {
                                    ...mapFinding.config,
                                    choroplethCountryVariable: (newValue as VariableOption | null)
                                        ?.label,
                                    choroplethCountryVariableIndex: (newValue as VariableOption | null)
                                        ?.value,
                                },
                            };
                            onChange(newFinding, true);
                        }}
                        value={
                            mapFinding.config.choroplethCountryVariableIndex !=
                            null
                                ? {
                                      label:
                                          mapFinding.config
                                              .choroplethCountryVariable,
                                      value:
                                          mapFinding.config
                                              .choroplethCountryVariableIndex,
                                  }
                                : null
                        }
                    />
                </div>
            </div>
            <Select
                isClearable
                placeholder="Select metric"
                styles={{
                    container: (provided) => ({
                        ...provided,
                        minWidth: 60,
                        marginTop: 10,
                    }),
                    control: (provided) => ({
                        ...provided,
                        minHeight: 25,
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none",
                    }),
                    menu: (base) => ({
                        ...base,
                        zIndex: 100000000,
                    }),
                }}
                options={numericVariableOptions}
                onChange={(newValue) => {
                    const newFinding: MapFinding = {
                        ...mapFinding,
                        config: {
                            ...mapFinding.config,
                            choroplethMetricVariable: (newValue as VariableOption | null)
                                ?.label,
                            choroplethMetricVariableIndex: (newValue as VariableOption | null)
                                ?.value,
                        },
                    };
                    onChange(newFinding, true);
                }}
                value={
                    mapFinding.config.choroplethMetricVariableIndex != null
                        ? {
                              label: mapFinding.config.choroplethMetricVariable,
                              value:
                                  mapFinding.config
                                      .choroplethMetricVariableIndex,
                          }
                        : null
                }
            />
        </div>
    );
}

export default ChoroplethConfig;
