async function getImageNaturalSize(
    imgSrc: string
): Promise<{ naturalWidth: number; naturalHeight: number }> {
    return new Promise((resolve, _reject) => {
        let img = new Image();
        img.onload = () => {
            resolve({
                naturalWidth: img.naturalWidth,
                naturalHeight: img.naturalHeight,
            });
        };
        img.src = imgSrc;
    });
}

export default getImageNaturalSize;
