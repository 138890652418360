import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M1.625 9.75H4.875V8.66667H1.625V9.75ZM1.625 3.25V4.33333H11.375V3.25H1.625ZM1.625 7.04167H8.125V5.95833H1.625V7.04167Z"
				fill="#657D95"
			/>
		</svg>
	);
}