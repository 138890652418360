import React from "react";
import {
    CanvasInput,
    isSubmitButton,
    CanvasSubmitButton,
    isInput,
    InputFieldStyle,
} from "common/Canvas";
import { getButtonStyle } from "../canvas_elements/ButtonStyles";
import { getAlignment, getInputFieldStyle } from "./themes/InputFieldStyles";
import { getTextSize } from "common/utilities/MeasureText";
import { defaultInputFieldPlaceholder } from "../Constants";

interface Props {
    rootDataTestId: string;
    formattedValue: string;
    fontColor: string;
    hovered: boolean;
    fontSize: number;
    node: CanvasInput | CanvasSubmitButton;
    scale: number;
    onDblClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onTouchStart?: (event: React.TouchEvent<HTMLDivElement>) => void;
}

export default function HTMLInput(props: Props) {
    const { node, scale } = props;
    let multiline = false;
    const fontSize = (props.fontSize * scale);
    let fontFamily = "Roboto";
    
    let buttonStyle: {
        containerStyle?: React.CSSProperties;
        textStyle?: React.CSSProperties;
    } = {};
    let inputFieldStyle: {
        containerStyle?: React.CSSProperties;
        textStyle?: React.CSSProperties;
    } = {}
    if (
        (isSubmitButton(node) && !node.isTextLink)
    ) {
        buttonStyle = getButtonStyle(node, scale);
        if (node.fontFamily) {
            fontFamily = node.fontFamily;
        }
    }

    let isMDInputFieldTheme = false;
    let isMDFilledInputField = false;
    let isMDOutlinedInputField = false;
    let labelClass = "";
    let labelWhiteSpaceWidth = 0;
    let label = "Label";
    let value = props.formattedValue;
    let valueColor = props.fontColor;
    let textMarginTop = 0;
    let textAlignment = {};
    if (isInput(node)) {
        textAlignment = getAlignment(node.textAlignment?.value);
        multiline = node?.multiline ?? false;
        if (node.label) {
            label = node.label;
        }
        if (node.fontFamily) {
            fontFamily = node.fontFamily;
        }
        inputFieldStyle = getInputFieldStyle(
            node,
            false,
            props.hovered,
            multiline,
        );
        isMDInputFieldTheme =
            node.inputFieldStyle === InputFieldStyle.MDFilled ||
            node.inputFieldStyle === InputFieldStyle.MDOutlined;

        isMDFilledInputField = node.inputFieldStyle === InputFieldStyle.MDFilled;
        isMDOutlinedInputField = node.inputFieldStyle === InputFieldStyle.MDOutlined;
        labelClass = isMDFilledInputField ? "MDFilledInputFieldLabel" : "MDOutlinedInputFieldLabel"
        let textSize = { width: 0, height: 0 };
        if (isMDInputFieldTheme) {
            textSize = getTextSize(
                label,
                fontFamily,
                fontSize,
                "600",
            );
        }
        if (isMDOutlinedInputField) {
            labelWhiteSpaceWidth = textSize.width * 0.6 + 10;
            if (multiline) {
                textMarginTop = textSize.height / 3;
            }
        }
        if (isMDFilledInputField && multiline) {
            textMarginTop = textSize.height / 1.2;
        }
        if (node.inputFieldStyle === InputFieldStyle.Default && !value) {
            value = defaultInputFieldPlaceholder;
            valueColor = "#869aac"
        }
    }
    const hasValue = value !== defaultInputFieldPlaceholder && value !== "";
    return (
        <div
            data-test-id={`${props.rootDataTestId}-textarea`}
            style={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor:
                    isSubmitButton(props.node)
                        ? "pointer"
                        : "default",
                ...inputFieldStyle.containerStyle,
            }}
            onDoubleClick={props.onDblClick}
            onTouchStart={props.onTouchStart}
        >
            {!isMDInputFieldTheme && (
                <span
                    className="content-regular-text unselectable"
                    style={{
                        fontSize,
                        fontFamily,
                        color: valueColor,
                        ...buttonStyle.textStyle,
                        ...inputFieldStyle.textStyle,
                        ...textAlignment,
                    }}
                >
                    {value}
                </span>
            )}
            {isMDInputFieldTheme && (
                <>
                    {(isMDOutlinedInputField && hasValue) && (
                        <div
                            style={{
                                whiteSpace: 'pre-line',
                                width: labelWhiteSpaceWidth,
                                height: 4,
                                position: "absolute",
                                top: -2,
                                left: 15,
                                backgroundColor: "transparent",
                            }}
                        >
                            <div
                                className="MDOutlinedInputFieldWhiteLabelSpace active"
                            ></div>
                        </div>
                    )}
                    <label
                        className={hasValue
                            ? `${labelClass} ${multiline ? "multiline" : ""} active`
                            : `${labelClass} ${multiline ? "multiline" : ""}`
                        }
                        style={{
                            color: valueColor,
                            fontSize,
                            fontFamily,
                        }}
                    >
                        {label}
                    </label>
                    {hasValue && (
                        <span
                            className="content-regular-text unselectable element"
                            style={{
                                whiteSpace: 'pre-line',
                                fontSize: fontSize,
                                fontFamily: fontFamily,
                                color: valueColor,
                                ...buttonStyle.textStyle,
                                ...inputFieldStyle.textStyle,
                                ...textAlignment,
                                marginTop: textMarginTop,
				                height: `calc(100% - ${textMarginTop}px)`
                            }}
                        >
                            {value}
                        </span>
                    )}
                </>
            )}
        </div>
    );
}