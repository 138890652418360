import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ManageTableOperation } from "common/ManageTableOperation";
import axios from "common/ServerConnection";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import styles from "./TrashSection.module.css";
import { stringSessionId } from "common/SessionId";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import MessagePopup from "../MessagePopup";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { Permission } from "common/Permissions";
import { ReactComponent as MagnifierIcon } from "icons/magnifier.svg";
import WarningModalPopup from "common/WarningModal";

function TrashSection(props: {
    setOperationInProgress: (state: boolean) => void;
}) {
    let [searchText, setSearchText] = React.useState<string>("");
    let [
        dataSetDelete,
        setDataSetDelete,
    ] = React.useState<DataScopeOption | null>(null);
    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    const [putBackTrashReplacePopUp, setPutBackTrashReplacePopUp] = useState<
        boolean
    >(false);
    const [trashDataPopUp, setTrashDataPopUp] = useState<boolean>(false);
    const [
        targetDataTableIdxTrashPutBackReplace,
        setTargetDataTableIdxTrashPutBackReplace,
    ] = useState<number>(0);
    const [
        dataScopeOptionTrashPutBack,
        setDataScopeOptionTargetTrashPutBack,
    ] = useState<DataScopeOption>({
        label: "",
        value: 0,
        owner: "",
        permissionType: Permission.ReadWrite,
    });
    const [sortkey, setSortkey] = useState<SortKeys>("uploaded");
    const [sortorder, setSortorder] = useState<SortOrder>("desc");

    type Data = typeof DataScopes.dataScopesTrashOptions;
    type SortKeys = keyof DataScopeOption | null;
    type SortOrder = "ascn" | "desc";

    const makeDefault = () => {
        setDataSetDelete(null);
        setStatusPopup(null);
        setTrashDataPopUp(false);
        setPutBackTrashReplacePopUp(false);
    };

    const beginPutBackProccess = (option: DataScopeOption) => {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                result: number;
            }>("/api/get_data_table_idx", {
                data_table_name: option.label,
                user: option.owner,
            })
            .then((response) => {
                if (response.data.success) {
                    if (response.data.result === -1) {
                        executeOperation(
                            option,
                            ManageTableOperation.RegularTrashPutBack
                        );
                    } else {
                        setDataScopeOptionTargetTrashPutBack(option);
                        setTargetDataTableIdxTrashPutBackReplace(
                            response.data.result
                        );
                        setPutBackTrashReplacePopUp(true);
                    }
                } else {
                    setStatusPopup({
                        status: PopupStatus.Error,
                        message: response.data.error_msg,
                    });
                }
            });
    };

    const executeBackendOperation = (
        requestUrl: string,
        requestArgs: string,
        onSuccess: (() => void) | undefined
    ) => {
        setStatusPopup({
            status: PopupStatus.Success,
            message: "Applying changes...",
        });

        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>(requestUrl, requestArgs)
            .then((response) => {
                if (response.data.success) {
                    makeDefault();
                    setStatusPopup({
                        status: PopupStatus.Success,
                        message: "Changes applied",
                    });
                    onSuccess?.();
                } else {
                    setStatusPopup({
                        status: PopupStatus.Error,
                        message: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                setStatusPopup({
                    status: PopupStatus.Error,
                    message: String(error),
                });
            });
    };

    const executeOperation = (
        dataScopeOption: DataScopeOption,
        operationOption: ManageTableOperation
    ) => {
        let requestUrl: string = "";
        let requestArgs: any = {};
        let onSuccess: (() => void) | undefined = undefined;
        switch (operationOption) {
            case ManageTableOperation.RemoveDataSet:
                requestUrl = "/api/trash_delete_data_tables";
                requestArgs = {
                    data_table_idx: dataScopeOption.value.toString(),
                    update_id: stringSessionId(),
                };
                onSuccess = () => {
                    DataScopes.update();
                    DataScopes.getTrashData();
                };
                break;
            case ManageTableOperation.TrashPutBackIntent:
                beginPutBackProccess(dataScopeOption);
                return;
            case ManageTableOperation.RegularTrashPutBack:
                requestUrl = "/api/pull_from_trash_data_table";
                requestArgs = {
                    data_table_idx: dataScopeOption.value.toString(),
                    update_id: stringSessionId(),
                };
                break;
            case ManageTableOperation.TrashPutBackReplace:
                requestUrl = "/api/trash_pull_back_replace_data_table";
                requestArgs = {
                    from_trash_data_table_idx: dataScopeOption.value.toString(),
                    data_table_idx: targetDataTableIdxTrashPutBackReplace.toString(),
                    update_id: stringSessionId(),
                };
                break;
            case ManageTableOperation.TrashPutBackKeepBoth:
                requestUrl = "/api/trash_pull_back_keep_both_data_table";
                requestArgs = {
                    data_table_idx: dataScopeOption.value.toString(),
                    data_table_name: dataScopeOption.label,
                    owner: dataScopeOption.owner,
                    update_id: stringSessionId(),
                };
                break;
        }
        onSuccess = () => {
            DataScopes.getTrashData();
            DataScopes.update();
        };
        executeBackendOperation(requestUrl, requestArgs, onSuccess);
    };

    useEffect(() => {
        DataScopes.getTrashData();
        DataScopes.update();
        return () => {
            DataScopes.previewState = { headers: null, body: null };
        };
    }, []);

    useEffect(() => {
        props.setOperationInProgress(searchText ? true : false);
    }, [searchText]);

    const headers: { key: SortKeys; label: string }[] = [
        { key: "label", label: "Name" },
        { key: "owner", label: "Owner" },
        { key: "uploaded", label: "Uploaded" },
    ];

    var selectedDataSets = DataScopes.dataScopesTrashOptions
        .filter((option) => option.permissionType === Permission.ReadWrite)
        .filter((option) =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
        );

    function sortData({
        tableData,
        sortkey,
        reverse,
    }: {
        tableData: Data;
        sortkey: SortKeys;
        reverse: boolean;
    }) {
        if (!sortkey) {
            return tableData;
        }

        const sortedData = selectedDataSets.sort((a, b) => {
            if (a[sortkey] && b[sortkey]) {
                return (a[sortkey] || "").toString().toLowerCase() >
                    (b[sortkey] || "").toString().toLowerCase()
                    ? 1
                    : -1;
            } else if ((a[sortkey] ?? "") || (b[sortkey] ?? "")) {
                return (a[sortkey] || "") > (b[sortkey] || "") ? 1 : -1;
            } else {
                return 1;
            }
        });

        if (reverse) {
            return sortedData.reverse();
        } else return sortedData;
    }
    const sortedData = useCallback(
        () =>
            sortData({
                tableData: selectedDataSets,
                sortkey: sortkey,
                reverse: sortorder === "desc" ? true : false,
            }),
        [selectedDataSets, sortkey, sortorder]
    );

    function changeSort(key: SortKeys) {
        setSortorder(sortorder === "ascn" ? "desc" : "ascn");
        setSortkey(key);
    }

    const clearTrashData = async () => {
        await axios.post(`/api/trash_delete_data_tables`, {
            data_table_idx: sortedData().map((item) => item.value.toString()),
        });
        DataScopes.getTrashData();
    };

    return (
        <div
            className={"mainContainer"}
            style={{ padding: 0, minHeight: "85vh" }}
        >
            <div className={styles.toolbarContainer}>
                <div style={{ flexGrow: 1 }}>
                    <span>{trashDataPopUp}</span>
                    <button
                        className={`acceptBtn ${styles.acceptButtonDanger}`}
                        style={{
                            paddingBlock: 8,
                            marginLeft: 20,
                            backgroundColor: "var(--danger-color)",
                        }}
                        onClick={() => setTrashDataPopUp(true)}
                        disabled={!(sortedData().length > 0)}
                    >
                        Clear Trash
                    </button>
                </div>
                <div className={styles.searchContainer}>
                    <input
                        list="datasets"
                        placeholder="Search trash"
                        type="text"
                        value={searchText}
                        onChange={(evt) => setSearchText(evt.target.value)}
                        className={styles.searchInput}
                    />
                    <MagnifierIcon stroke="red" />
                </div>
            </div>

            <div className={styles.customTable}>
                <table className={styles.grid}>
                    <thead>
                        <tr
                            className={styles.gridRow}
                            style={{ backgroundColor: "#f7f7f7" }}
                        >
                            {headers.map((header) => {
                                return (
                                    <td
                                        style={{
                                            width:
                                                header.key === "label"
                                                    ? "30%"
                                                    : "20%",
                                        }}
                                        className={styles.gridTitle}
                                        key={header.key}
                                    >
                                        {header.key === "label" && (
                                            <div style={{ width: 22 }} />
                                        )}
                                        {header.label}
                                        <button
                                            className={`${
                                                sortkey === header.key &&
                                                sortorder === "desc"
                                                    ? styles.sortButton
                                                    : `${styles.sortButton} ${styles.sortReverse}`
                                            }`}
                                            onClick={() =>
                                                changeSort(header.key)
                                            }
                                        >
                                            <ChevronIcon stroke="#555" />
                                        </button>
                                    </td>
                                );
                            })}
                            <th
                                style={{
                                    justifyContent: "center",
                                    fontWeight: "normal",
                                }}
                                className={styles.gridTitle}
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>

                    {DataScopes.dataScopesTrashOptions.length > 0 ? (
                        sortedData().length > 0 ? (
                            sortedData().map((option, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tbody>
                                            <tr
                                                className={styles.gridRow}
                                                style={{
                                                    borderBottom:
                                                        "1px solid #E8E8E8",
                                                    paddingBlock: "3px",
                                                }}
                                            >
                                                <td
                                                    style={{ width: "30%" }}
                                                    className={styles.gridItem}
                                                >
                                                    <span
                                                        style={{
                                                            width:
                                                                "max-content",
                                                            cursor: "pointer",
                                                            paddingLeft: "20px",
                                                        }}
                                                    >
                                                        {option.label}
                                                    </span>
                                                </td>
                                                <td
                                                    style={{ width: "20%" }}
                                                    className={styles.gridItem}
                                                >
                                                    {option.owner}
                                                </td>
                                                <td
                                                    style={{ width: "20%" }}
                                                    className={styles.gridItem}
                                                >
                                                    {option.uploaded}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "12%",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                    className={styles.gridItem}
                                                >
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            setDataSetDelete(
                                                                option
                                                            );
                                                        }}
                                                    >
                                                        Delete
                                                    </span>
                                                    <span
                                                        className="textBtn"
                                                        onClick={() => {
                                                            executeOperation(
                                                                option,
                                                                ManageTableOperation.TrashPutBackIntent
                                                            );
                                                        }}
                                                    >
                                                        Put Back
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <div className={styles.noData}>
                                <h1 style={{ fontSize: 22 }}>
                                    No data set with this name
                                </h1>
                            </div>
                        )
                    ) : (
                        <div className={styles.noData}>
                            <img
                                src="/dist/img/data-exploration/TrashIcon.svg"
                                alt=""
                                style={{
                                    width: "32px",
                                    height: "32px",
                                    marginTop: "5px",
                                }}
                            />
                            <h1 style={{ fontSize: 22, marginLeft: "8px" }}>
                                All tidy up here
                            </h1>
                        </div>
                    )}
                </table>
            </div>

            {putBackTrashReplacePopUp && (
                <WarningModalPopup
                    title="ALERT"
                    text="Dataset with this name already exists. Would you like to replace it?"
                    subText="A dataset with the same name already exists. Replacing it will overwrite its current contents."
                    onReplace={() => {
                        let option = dataScopeOptionTrashPutBack;
                        executeOperation(
                            option,
                            ManageTableOperation.TrashPutBackReplace
                        );
                        setPutBackTrashReplacePopUp(false);
                    }}
                    onKeepBoth={() => {
                        let option = dataScopeOptionTrashPutBack;
                        executeOperation(
                            option,
                            ManageTableOperation.TrashPutBackKeepBoth
                        );
                        setPutBackTrashReplacePopUp(false);
                    }}
                    onReject={() => {
                        makeDefault();
                    }}
                />
            )}

            {trashDataPopUp && (
                <MessagePopup
                    title={
                        <p
                            style={{
                                width: "100%",
                                marginBottom: "20px",
                                textAlign: "center",
                            }}
                        >
                            Clear Trash
                        </p>
                    }
                    danger={true}
                    acceptButtonTitle="Clear Trash"
                    message={`Do you want to remove all the datasets ?`}
                    onAccept={() => {
                        clearTrashData();
                        setTrashDataPopUp(false);
                    }}
                    onReject={() => {
                        makeDefault();
                    }}
                />
            )}

            {dataSetDelete != null && (
                <MessagePopup
                    title={
                        <p
                            style={{
                                width: "100%",
                                marginBottom: "20px",
                                textAlign: "center",
                            }}
                        >
                            Clear Trash
                        </p>
                    }
                    danger={true}
                    acceptButtonTitle="Clear Trash"
                    message={`Do you want to remove "${dataSetDelete.label}" ?`}
                    onAccept={() => {
                        executeOperation(
                            dataSetDelete!,
                            ManageTableOperation.RemoveDataSet
                        );
                    }}
                    onReject={() => {
                        makeDefault();
                    }}
                />
            )}

            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
        </div>
    );
}

export default observer(TrashSection);
