import React, { Component } from "react";
import { Button } from "react-bootstrap";
import TextareaAutosize from "react-autosize-textarea";
import ls from "local-storage";
import {
    retrieveImageFromClipboardAsBlob,
    resizeAndEncodeToBase64,
} from "common/utilities/retrieveImageFromClipboard";
import { mainStyle } from "common/MainStyle";
import { editInsightApi } from "common/UserApi";
import { getInsightUserGroups } from "common/UserGroupsApi";
import FindingItem from "common/insights_components/FindingItem";
import Alert from "common/Alert";
import { renderIcon } from "common/utilities/renderIcon";
import { addJourneyConfigApi } from "common/UserApi";
import { observer } from "mobx-react";
import UserGroupsSelector from "common/UserGroupsSelector";
import { goToInternalLink } from "common/InternalLinksHelper";
import _ from "lodash";

const maxTitleSize = 100;
const maxInsightSize = 250;
const maxRecommendationSize = 1000;

const InsightsCreationCard = observer(
    class InsightsCreationCard extends Component {
        constructor(props, context) {
            super(props, context);
            this.state = {
                title: "",
                titleIsEditing: false,
                insightIsEditing: false,
                recommendationIsEditing: false,
                step: 0,
                insight: _.cloneDeep(this.props.insight),
                selectedUserGroups: [],
                errorMessage: undefined,
                needUpdate: false,
                needUpdateIcon: false,
                imageBlob: null,
            };
            this.wrapper = React.createRef();
            this.doAnalysis = this.doAnalysis.bind(this);
        }

        componentDidMount() {
            getInsightUserGroups(this.state.insight.id).then((groups) => {
                if (groups.length > 0)
                    this.setState({ selectedUserGroups: groups });
            });
        }

        buildTextNotification(text, maxSize) {
            let textLength = text ? text.length : 0;
            return (
                <div
                    className="my-row"
                    style={{ alignSelf: "flex-end", marginBottom: 10 }}
                >
                    <div>
                        <img
                            alt=""
                            src={
                                textLength >= maxSize
                                    ? "/dist/img/insights/cross_icon.png"
                                    : "/dist/img/insights/check_icon.png"
                            }
                        />
                    </div>
                    <span
                        style={{
                            marginLeft: "5px",
                            color: "#98A7B9",
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            fontWeight: 300,
                            lineHeight: "14px",
                        }}
                    >
                        {textLength}/{maxSize} characters used
                    </span>
                </div>
            );
        }
        doAnalysis() {
            let config = undefined;
            if (
                this.state.insight.contents.finding &&
                this.state.insight.contents.finding.config
            ) {
                config = Object.assign(
                    {},
                    this.state.insight.contents.finding.config
                );
            }

            let lastEditInsight = Object.assign({}, this.state.insight);
            lastEditInsight.contents = {
                ...lastEditInsight.contents,
                finding: {},
                imageSrc: "",
            };
            ls.set("lastEditInsight", lastEditInsight);

            if (config) {
                addJourneyConfigApi(config)
                    .then(() => {
                        ls.set(
                            "dataExplorationJourneyName",
                            config.journeyName
                        );
                        goToInternalLink("/data_exploration.html");
                    })
                    .catch((error) => {
                        goToInternalLink("/data_exploration.html");

                        console.log(error);
                    });
            } else {
                goToInternalLink("/data_exploration.html");
            }
        }

        editInsight(value, field, maxSize, withBullets) {
            let insight = Object.assign({}, this.state.insight);

            if (!withBullets) {
                insight.contents[field] = value.slice(0, maxSize);
            } else {
                let vals = value.split(/\r?\n/).filter((val) => val.length > 0);
                vals = vals.map((val) =>
                    val.startsWith("•") ? val : "•".concat(val)
                );
                let newValue = vals.join("\n");
                insight.contents[field] = newValue.slice(0, maxSize);
            }
            this.setState({ insight: insight, needUpdate: true });
        }
        buildBottomBar() {
            return (
                <div
                    className="my-row"
                    style={{
                        alignSelf: "flex-end",
                        marginRight: 20,
                        marginTop: 5,
                        width: "100%",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        marginBottom: 20,
                    }}
                >
                    <div
                        className="my-row"
                        style={{ marginRight: 20, alignItems: "center" }}
                    >
                        <div
                            className="content-regular-text"
                            style={{ marginRight: 20 }}
                        >
                            {"User groups"}
                        </div>
                        <UserGroupsSelector
                            selectedUserGroups={this.state.selectedUserGroups}
                            onChange={(selectedUserGroups) => {
                                this.setState({
                                    needUpdate: true,
                                    selectedUserGroups: selectedUserGroups,
                                });
                            }}
                        />
                    </div>
                    {this.state.errorMessage && (
                        <Alert
                            text={this.state.errorMessage.toString()}
                            className="alert alert-danger alert-dismissible"
                            onClosed={() => {
                                this.setState({ errorMessage: undefined });
                            }}
                            style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                marginTop: "0px",
                                marginBottom: "0px",
                                marginRight: "10px",
                                minHeight: "25px",
                            }}
                        />
                    )}
                    <Button
                        type="button"
                        style={{ width: 200 }}
                        className="btn btn-md btn-primary my-primary"
                        onClick={() => {
                            this.props.beforeInsightUpdate();
                            if (!this.state.needUpdate) {
                                this.props.onHide();
                                return;
                            }
                            if (this.state.needUpdateIcon) {
                                renderIcon(
                                    this.wrapper,
                                    this.state.insight.contents.finding,
                                    200
                                )
                                    .then((dataUrl) => {
                                        let insight = Object.assign(
                                            {},
                                            this.state.insight
                                        );
                                        insight.imageSrc = dataUrl;
                                        editInsightApi(
                                            insight,
                                            this.state.imageBlob,
                                            this.state.selectedUserGroups
                                        )
                                            .then((updated) => {
                                                this.props.afterInsightUpdate(
                                                    insight.id
                                                );
                                                this.props.onHide();
                                            })
                                            .catch((msg) => {
                                                this.setState({
                                                    errorMessage: msg,
                                                });
                                            });
                                    })
                                    .catch((error) => {
                                        editInsightApi(
                                            this.state.insight,
                                            this.state.imageBlob,
                                            this.state.selectedUserGroups
                                        )
                                            .then((updated) => {
                                                this.props.afterInsightUpdate(
                                                    this.state.insight.id
                                                );
                                                this.props.onHide();
                                            })
                                            .catch((msg) => {
                                                this.setState({
                                                    errorMessage: msg,
                                                });
                                            });
                                    });
                            } else {
                                editInsightApi(
                                    this.state.insight,
                                    this.state.imageBlob,
                                    this.state.selectedUserGroups
                                )
                                    .then((updated) => {
                                        this.props.afterInsightUpdate(
                                            this.state.insight.id
                                        );
                                        this.props.onHide();
                                    })
                                    .catch((msg) => {
                                        this.setState({ errorMessage: msg });
                                    });
                            }
                        }}
                    >
                        SAVE AND FINISH
                    </Button>
                </div>
            );
        }
        buildStepFirstCenterComponent() {
            if (!this.state.insight.contents.finding) {
                return (
                    <div
                        style={{
                            height: "80%",

                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            this.setState({ step: 1 });
                        }}
                    >
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--content-primary-text-color"
                                ),
                                fontFamily: "Roboto",
                                fontSize: "17px",
                                fontWeight: 700,
                            }}
                        >
                            Step 1: Click here to add insight graphic
                        </span>
                    </div>
                );
            } else if (this.state.insight.contents.finding.type === "image") {
                return (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                        }}
                        onClick={() => {}}
                    >
                        <FindingItem
                            finding={this.state.insight.contents.finding}
                            imageBlob={this.state.imageBlob}
                            insightId={this.props.insightId}
                            edited={true}
                            onEdit={(finding) => {
                                if (!finding) {
                                    this.setState((state) => ({
                                        insight: {
                                            ...state.insight,
                                            contents: {
                                                ...state.insight.contents,
                                                finding: finding,
                                                imageSrc: "",
                                            },
                                        },
                                        needUpdate: true,
                                        needUpdateIcon: false,
                                        step: 1,
                                        imageBlob: "remove",
                                    }));
                                } else {
                                    this.setState((state) => ({
                                        insight: {
                                            ...state.insight,
                                            contents: {
                                                ...state.insight.contents,
                                                finding: finding,
                                            },
                                        },
                                        needUpdate: true,
                                        needUpdateIcon: true,
                                    }));
                                }
                            }}
                        />
                    </div>
                );
            } else
                return (
                    <div
                        style={{
                            width: "99%",
                            cursor: "pointer",
                            height: this.props.parentContainerHeight * 0.64,
                        }}
                        onClick={() => {
                            this.doAnalysis();
                        }}
                    >
                        <FindingItem
                            finding={this.state.insight.contents.finding}
                            insightId={this.props.insightId}
                        />
                    </div>
                );
        }

        buildStepSecondCenterComponent() {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80%",
                    }}
                >
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--content-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: "17px",
                            fontWeight: 700,
                        }}
                    >
                        How would you like to add the insight graphic?
                    </span>
                    <div
                        className="my-row"
                        style={{ justifyContent: "space-between" }}
                    >
                        <Button
                            type="button"
                            style={{ width: 150 }}
                            className="btn btn-md btn-primary my-primary"
                            onClick={() => {
                                this.doAnalysis();
                            }}
                        >
                            DO ANALYSIS
                        </Button>
                        <Button
                            type="button"
                            style={{ width: 150, marginLeft: 20 }}
                            className="btn btn-md btn-primary my-primary"
                            onClick={() => {
                                this.setState({ step: 2 });
                            }}
                        >
                            TAKE SCREENSHOT
                        </Button>
                    </div>
                </div>
            );
        }

        buildStepThirdCenterComponent() {
            return (
                <div
                    ref="screenshotBox"
                    className="flex-simple-column screenshot-box"
                    style={{
                        border:
                            this.refs.screenshotBox === document.activeElement
                                ? "1px solid #39F"
                                : "none",
                        tabIndex: "0",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80%",
                    }}
                >
                    <div className="flex-simple-column">
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--content-primary-text-color"
                                ),
                                fontFamily: "Roboto",
                                fontSize: "17px",
                                fontWeight: 700,
                            }}
                        >
                            {"Do the following steps:"}
                        </span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--content-primary-text-color"
                                ),
                                fontFamily: "Roboto",
                                fontSize: "17px",
                                fontWeight: 700,
                            }}
                        >
                            1. Go to the program/page/screen that has the image
                            you want to capture
                        </span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--content-primary-text-color"
                                ),
                                fontFamily: "Roboto",
                                fontSize: "17px",
                                fontWeight: 700,
                            }}
                        >
                            2. Use your screenshot tool to capture the image,
                            copy it to clipboard
                        </span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--content-primary-text-color"
                                ),
                                fontFamily: "Roboto",
                                fontSize: "17px",
                                fontWeight: 700,
                            }}
                        >
                            3. Return here and enter [CTRL+V]
                        </span>
                    </div>
                </div>
            );
        }
        buildCenterComponent() {
            switch (this.state.step) {
                case 0:
                    return this.buildStepFirstCenterComponent();
                case 1:
                    return this.buildStepSecondCenterComponent();
                case 2:
                    return this.buildStepThirdCenterComponent();
                default:
                    return null;
            }
        }

        render() {
            return (
                <div
                    ref={this.wrapper}
                    className="flex-simple-column element"
                    style={{
                        overflow: "auto",
                        height: this.props.parentContainerHeight,
                    }}
                    onPaste={(evt) => {
                        if (
                            this.state.step !== 2 ||
                            this.state.titleIsEditing ||
                            this.state.step.insightIsEditing ||
                            this.state.step.recommendationIsEditing ||
                            this.state.step.graphicsTitleIsEditing
                        )
                            return;

                        retrieveImageFromClipboardAsBlob(evt, (imageBlob) => {
                            if (imageBlob != null) {
                                resizeAndEncodeToBase64(
                                    imageBlob,
                                    (imageBase64) => {
                                        let finding = {
                                            type: "image",
                                            content: {},
                                        };
                                        this.setState((state) => ({
                                            insight: {
                                                ...state.insight,
                                                contents: {
                                                    ...state.insight.contents,
                                                    finding: finding,
                                                    imageSrc: imageBase64,
                                                },
                                            },
                                            imageBlob: imageBlob,
                                            step: 0,
                                            needUpdate: true,
                                        }));
                                    },
                                    250
                                );
                            }
                        });
                    }}
                >
                    <div
                        style={{
                            marginTop: 20,
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <TextareaAutosize
                            className="insights-text-area"
                            placeholder="Step 2: Enter card title here"
                            value={this.state.insight.contents.title}
                            onBlur={() => {
                                this.setState({ titleIsEditing: false });
                            }}
                            onFocus={() => {
                                this.setState({ titleIsEditing: true });
                            }}
                            style={{
                                textAlign: "center",
                                backgroundColor: this.state.titleIsEditing
                                    ? mainStyle.getPropertyValue(
                                          "--tertiary-content-color"
                                      )
                                    : "transparent",

                                width: "90%",
                                fontSize: "30px",
                                minHeight: 50,
                                marginLeft: 0,
                                marginRight: 0,
                            }}
                            onInput={(evt) => {
                                this.editInsight(
                                    evt.target.value,
                                    "title",
                                    maxTitleSize,
                                    false
                                );
                            }}
                        />
                    </div>
                    <div
                        className="my-row element"
                        style={{ marginTop: 20, flexGrow: 1 }}
                    >
                        <div
                            className="flex-simple-column"
                            style={{
                                width:
                                    this.props.insight.contents.noInsight &&
                                    this.props.insight.contents.noRecommendation
                                        ? "100%"
                                        : "70%",
                                marginLeft: 30,
                                marginRight: 30,
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <TextareaAutosize
                                className="insights-text-area element"
                                placeholder="Step 3: Enter insight graphic title here"
                                value={
                                    this.state.insight.contents.graphicsTitle
                                }
                                onBlur={() => {
                                    this.setState({
                                        graphicsTitleIsEditing: false,
                                    });
                                }}
                                onFocus={() => {
                                    this.setState({
                                        graphicsTitleIsEditing: true,
                                    });
                                }}
                                style={{
                                    textAlign: "center",
                                    backgroundColor: this.state
                                        .graphicsTitleIsEditing
                                        ? mainStyle.getPropertyValue(
                                              "--tertiary-content-color"
                                          )
                                        : "transparent",
                                    fontSize: "20px",
                                    width: "100%",
                                    minHeight: 47,
                                    marginLeft: 0,
                                    marginRight: 0,
                                }}
                                onInput={(evt) => {
                                    this.editInsight(
                                        evt.target.value,
                                        "graphicsTitle",
                                        100,
                                        false
                                    );
                                }}
                            />
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    marginTop: 10,
                                }}
                            >
                                {this.buildCenterComponent()}
                            </div>
                        </div>
                        {(!this.state.insight.contents.noInsight ||
                            !this.state.insight.contents.noRecommendation) && (
                            <div
                                className="flex-simple-column"
                                style={{
                                    width: "30%",
                                    marginTop: 10,
                                    marginBottom: 10,
                                    marginRight: 20,
                                    justifyContent: "space-between",
                                }}
                            >
                                {!this.state.insight.contents.noInsight && (
                                    <div
                                        className="flex-simple-column"
                                        style={{
                                            alignItems: "center",
                                            minHeight: 150,
                                        }}
                                    >
                                        <div
                                            className="my-row"
                                            style={{
                                                width: "100%",
                                                marginTop: 10,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    textAlign: "left",
                                                    color: mainStyle.getPropertyValue(
                                                        "--secondary-text-color"
                                                    ),
                                                    fontFamily: "Roboto",
                                                    fontSize: "17px",
                                                    fontWeight: 700,
                                                    lineHeight: "20px",
                                                }}
                                            >
                                                Insight
                                            </span>
                                            <Button
                                                style={{ marginLeft: "5px" }}
                                                className="close"
                                                onClick={() => {
                                                    let insight = Object.assign(
                                                        {},
                                                        this.state.insight
                                                    );
                                                    insight.contents.noInsight = true;
                                                    insight.insight = "";
                                                    this.setState({
                                                        insight: insight,
                                                        needUpdate: true,
                                                    });
                                                }}
                                            >
                                                <span
                                                    style={{ color: "white" }}
                                                >
                                                    &times;
                                                </span>
                                            </Button>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <TextareaAutosize
                                                value={
                                                    this.state.insight.contents
                                                        .insight
                                                }
                                                className="insights-text-area"
                                                onBlur={() => {
                                                    this.setState({
                                                        insightIsEditing: false,
                                                    });
                                                }}
                                                onFocus={() => {
                                                    this.setState({
                                                        insightIsEditing: true,
                                                    });
                                                }}
                                                placeholder="Step 4: Enter insight here"
                                                style={{
                                                    backgroundColor: this.state
                                                        .insightIsEditing
                                                        ? mainStyle.getPropertyValue(
                                                              "--tertiary-content-color"
                                                          )
                                                        : "transparent",
                                                    width: "100%",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    minHeight: 100,
                                                    marginTop: 20,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                    marginBottom: 10,
                                                }}
                                                onInput={(evt) => {
                                                    this.editInsight(
                                                        evt.target.value,
                                                        "insight",
                                                        maxInsightSize,
                                                        false
                                                    );
                                                }}
                                            />
                                            {this.state.insightIsEditing
                                                ? this.buildTextNotification(
                                                      this.state.insight
                                                          .contents.insight,
                                                      maxInsightSize
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                )}
                                {!this.state.insight.contents
                                    .noRecommendation && (
                                    <div
                                        className="flex-simple-column"
                                        style={{
                                            alignItems: "center",
                                            minHeight: 150,
                                        }}
                                    >
                                        <div
                                            className="my-row"
                                            style={{
                                                width: "100%",
                                                marginTop: 10,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    textAlign: "left",
                                                    color: mainStyle.getPropertyValue(
                                                        "--secondary-text-color"
                                                    ),
                                                    fontFamily: "Roboto",
                                                    fontSize: "17px",
                                                    fontWeight: 700,
                                                    lineHeight: "20px",
                                                }}
                                            >
                                                Recommendation
                                            </span>
                                            <Button
                                                style={{ marginLeft: "5px" }}
                                                className="close"
                                                onClick={() => {
                                                    let insight = Object.assign(
                                                        {},
                                                        this.state.insight
                                                    );
                                                    insight.contents.noRecommendation = true;
                                                    insight.contents.recommendation =
                                                        "";
                                                    this.setState({
                                                        insight: insight,
                                                        needUpdate: true,
                                                    });
                                                }}
                                            >
                                                <span
                                                    style={{ color: "white" }}
                                                >
                                                    &times;
                                                </span>
                                            </Button>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <TextareaAutosize
                                                value={
                                                    this.state.insight.contents
                                                        .recommendation
                                                }
                                                placeholder="Step 5: Enter action recommendation and expected outcome here"
                                                className="insights-text-area"
                                                style={{
                                                    backgroundColor: this.state
                                                        .recommendationIsEditing
                                                        ? mainStyle.getPropertyValue(
                                                              "--tertiary-content-color"
                                                          )
                                                        : "transparent",
                                                    width: "100%",
                                                    fontSize: "12px",
                                                    fontWeight: 400,
                                                    minHeight: 100,
                                                    marginTop: 20,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                    marginBottom: 10,
                                                }}
                                                onBlur={() => {
                                                    this.setState({
                                                        recommendationIsEditing: false,
                                                    });
                                                }}
                                                onFocus={() => {
                                                    this.setState({
                                                        recommendationIsEditing: true,
                                                    });
                                                }}
                                                onInput={(evt) => {
                                                    this.editInsight(
                                                        evt.target.value,
                                                        "recommendation",
                                                        maxRecommendationSize,
                                                        true
                                                    );
                                                }}
                                                onKeyDown={(evt) => {
                                                    if (
                                                        evt.which === 13 &&
                                                        !evt.shiftKey
                                                    ) {
                                                        evt.preventDefault();
                                                        this.editInsight(
                                                            this.state.insight.contents.recommendation.concat(
                                                                "\n•"
                                                            ),
                                                            "recommendation",
                                                            maxRecommendationSize,
                                                            true
                                                        );
                                                    }
                                                }}
                                            />
                                            {this.state.recommendationIsEditing
                                                ? this.buildTextNotification(
                                                      this.state.insight
                                                          .contents
                                                          .recommendation,
                                                      maxRecommendationSize
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {this.buildBottomBar()}
                </div>
            );
        }
    }
);

export default InsightsCreationCard;
