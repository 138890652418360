import React, {
    useState,
    useEffect,
    useMemo,
    Component,
    CSSProperties,
} from "react";
import ReactDOMServer from "react-dom/server";
import { Button } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Popup from "reactjs-popup";
import "leaflet/dist/leaflet.css";
import Leaflet from "leaflet";
import {
    useMap,
    useMapEvents,
    MapContainer,
    Marker,
    Tooltip,
    GeoJSON,
} from "react-leaflet";
import "leaflet.heat";
import Select, { createFilter } from "react-select";
import elements from "common/CanvasElements";
import {
    TextAlign,
    MapElement,
    MapVariableOption,
    ColorOptions,
} from "common/Canvas";
import { TooltipOptions } from "common/ConfigurableTooltip";
import { mainStyle } from "common/MainStyle";
import QuestionHeaderView from "./question_views/QuestionHeaderView";
import DataScopeAndTableSelectorView from "common/DataScopeAndTableSelectorView";
import { TableOption } from "common/Tables";
import { DataScopeOption } from "common/DataScopes";
import { GroupExtendedPermission } from "common/GroupPermissions";
import CanvasPreventPropagationButton from "./CanvasPreventPropagationButton";
import { goToInternalLink } from "common/InternalLinksHelper";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import LocationSelectorView from "./question_views/LocationSelectorView";
import MapVariablesSelectorView from "./question_views/MapVariablesSelectorView";
import VariablesSelectorView from "common/VariablesSelectorView";
import ColorsSelectorView from "./question_views/ColorsSelectorView";
import axios from "common/ServerConnection";
import {
    dataScienceSelectStyles,
} from "common/SelectStyles";
import {
    ConditionsSelector,
    Condition,
    conditionsToJson,
} from "common/Conditions";
import Variables, { VariableOption } from "common/Variables";
import { Resizable } from "re-resizable";
import Draggable from "react-draggable";
import { GeoJsonObject } from "geojson";
import StringUtils from "common/utilities/StringUtils";
import ColorPicker from "common/ColorPicker";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { getTextSize } from "common/utilities/MeasureText";
import { resizeAndEncodeToBase64 } from "common/utilities/retrieveImageFromClipboard";
import { colorList } from "common/graphics/LineColors";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import processUrl from "common/processUrl";
import remoteModuleId from "common/remoteModuleId";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import CanvasTreeStore from "./CanvasTreeStore";

// Added SVG buttons
import UpButtonBig from "icons/UpButtonBig.svg";
import DownButtonBig from "icons/DownButtonBig.svg";
import TileFilterLayer from "common/leaflet_addons/TileFilterLayer";
import { iconToPath } from "common/MakiIcons";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";

const defaultItemHeight = 50;
const defaultItemWidth = 100;

const markerIcon = (base64Icon?: string | null, color?: string | null) => {
    if (base64Icon != null || color == null) {
        return new Leaflet.Icon({
            iconUrl: base64Icon ?? "/dist/img/canvas/map/marker.png",
            iconRetinaUrl: base64Icon ?? "/dist/img/canvas/map/marker.png",
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
        });
    } else {
        return new Leaflet.DivIcon({
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            // labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        backgroundColor: color,
                        maskImage: "url(/dist/img/canvas/map/marker.png)",
                        WebkitMaskImage: "url(/dist/img/canvas/map/marker.png)",
                        width: 64,
                        height: 64,
                    }}
                />
            ),
        });
    }
};

const markerIconWithSymbol = (
    base64Icon?: string | null,
    color?: string | null,
    symbol?: string | null
) => {
    const defaultIconPath: string =
        symbol != null
            ? "/dist/img/canvas/map/marker_filled.png"
            : "/dist/img/canvas/map/marker.png";
    if (base64Icon != null || color == null) {
        return new Leaflet.DivIcon({
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            // labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        position: "relative",
                        width: 64,
                        height: 64,
                    }}
                >
                    <img
                        alt=""
                        src={base64Icon ?? defaultIconPath}
                        style={{
                            width: 64,
                            height: 64,
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                    />
                    {symbol != null && (
                        <div
                            style={{
                                backgroundColor: "white",
                                maskImage: `url(${iconToPath[symbol]})`,
                                maskSize: "contain",
                                WebkitMaskImage: `url(${iconToPath[symbol]})`,
                                WebkitMaskSize: "contain",
                                width: 28,
                                height: 28,
                                position: "absolute",
                                top: "33%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    )}
                </div>
            ),
        });
    } else {
        return new Leaflet.DivIcon({
            iconAnchor: [32, 64],
            popupAnchor: [0, -42],
            // labelAnchor: [0, -42],
            tooltipAnchor: [0, -42],
            iconSize: new Leaflet.Point(64, 64),
            className: "",
            html: ReactDOMServer.renderToString(
                <div
                    style={{
                        position: "relative",
                        width: 64,
                        height: 64,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: color,
                            maskImage: `url(${defaultIconPath})`,
                            WebkitMaskImage: `url(${defaultIconPath})`,
                            width: 64,
                            height: 64,
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                    />
                    {symbol != null && (
                        <div
                            style={{
                                backgroundColor: "white",
                                maskImage: `url(${iconToPath[symbol]})`,
                                maskSize: "contain",
                                WebkitMaskImage: `url(${iconToPath[symbol]})`,
                                WebkitMaskSize: "contain",
                                width: 28,
                                height: 28,
                                position: "absolute",
                                top: "33%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    )}
                </div>
            ),
        });
    }
};

const markerIconTransparent = new Leaflet.Icon({
    iconUrl: "/dist/img/canvas/map/marker_transparent.png",
    iconRetinaUrl: "/dist/img/canvas/map/marker_transparent.png",
    iconAnchor: [32, 32],
    popupAnchor: [0, 0],
    labelAnchor: [0, 0],
    tooltipAnchor: [0, 0],
    iconSize: new Leaflet.Point(64, 64),
    className: "",
});

enum MarkerColoringType {
    Fixed = 0,
    Varied = 1,
}

interface MarkerColoringOption {
    label: string;
    value: MarkerColoringType;
}

const markerColoringOptions: ReadonlyArray<MarkerColoringOption> = [
    { label: "Use fixed color", value: MarkerColoringType.Fixed },
    { label: "Vary color by variable", value: MarkerColoringType.Varied },
];

interface Props {
    // called when we click on triple-dot button
    onOpenColorOptions: (
        colorOptions: ColorOptions,
        zoomLevel?: number
    ) => void;
    // called when we click on context menu
    onContextMenu: (evt: React.MouseEvent) => void;

    canvasTreeStore: CanvasTreeStore;

    // data layer element from Canvas structure
    mapElement: MapElement;

    // unique identifier of data layer element
    mapElementId: string;

    // height of element (unscaled)
    height: number;

    // if true then we in live mode
    live: boolean;

    // if true than we cannot edit map
    frozen: boolean;

    // scale of presentation
    scale: number;

    // indicates if we are seeing map from shared link
    sharedPolicy: CanvasSharedPolicy;

    // onChange callback
    onChange: (
        mapElement: Partial<MapElement>,
        geoJsonFiles?: MapElement["geoJsonFiles"]
    ) => void;
    // onDelete callback
    onDelete: () => void;
    // id of current presentation
    currentModuleId: number | undefined;
    // track performance callback
    onTrackNewPerformance: (element: string) => void;
}

enum OperationStatus {
    NotStarted = 1,
    Running = 2,
    Success = 3,
    Error = 4,
}

interface State {
    heatMapData: Array<[number, number, number]>;
    updating: boolean;
    step: Step;
    selectedTooltipOption: TooltipOption;
    animationDirection: string;
    operationErrorMessage: string | null;
    operationStatus: OperationStatus;
    newDataSetName: string;
    permissions: (GroupExtendedPermission | null)[];
    data: { [key: string]: Array<number | string | null> } | null;
    popupMarkerIndex: number | null;
    hoverInfo: {
        index: number;
        x: number;
        y: number;
    } | null;
    colorVariableValues: (string | number | null)[];
    colorVariableValueToIndex: Map<string | number | null, number>;
    geoJsonFilesPopupOpen: boolean;
    geoJsonSizes: { [key: string]: number };
    popupStatus: PopupStatus | undefined;
    message: string | undefined;
}

enum TooltipType {
    AsAlways = 1,
    OnClick = 2,
    OnHover = 3,
}
interface TooltipOption {
    label: string;
    value: TooltipType;
}
const tooltipType2Key: { [key: number]: keyof MapElement } = {
    [TooltipType.AsAlways]: "displayAlways",
    [TooltipType.OnHover]: "displayOnHover",
    [TooltipType.OnClick]: "displayOnClick",
};

const tooltipType2OptionsKey: { [key: number]: keyof MapElement } = {
    [TooltipType.AsAlways]: "displayAlwaysOptions",
    [TooltipType.OnHover]: "displayOnHoverOptions",
    [TooltipType.OnClick]: "displayOnClickOptions",
};

const tooltipOptions: ReadonlyArray<TooltipOption> = [
    { label: "Display Always", value: TooltipType.AsAlways },
    { label: "Display On Click", value: TooltipType.OnClick },
    { label: "Display On Hover", value: TooltipType.OnHover },
];

// properties of TooltipItem
interface TooltipItemProps {
    defaultY: number;
    defaultWidth?: number;
    defaultHeight?: number;
    widthOffset: number;
    editable: boolean;
    mapElement?: MapElement;
    mapElementId: string;
    onTrackNewPerformance: (element: string) => void;
    onChange?: (map: MapElement) => void;
    type: TooltipType;
    index: number;
    option: MapVariableOption;
    value: number | string | null;
    scale: number;
}

/*!
 * function tooltipItemPosition calculates position of tooltip item
 * in tooltip
 */
function tooltipItemPosition(
    option: MapVariableOption,
    defaultY: number,
    widthOffset: number
): {
    x: number;
    y: number;
} {
    let position = {
        x: (option.options.x ?? 10) - widthOffset,
        y: option.options.y ?? defaultY,
    };
    return position as {
        x: number;
        y: number;
    };
}

/*!
 * function tooltipItemPosition calculates size of tooltip item
 * in tooltip
 */
function tooltipItemSize(
    option: MapVariableOption,
    defaultWidth?: number,
    defaultHeight?: number
): {
    width: number;
    height: number;
} {
    let itemSize = {
        width: option.options?.width ?? defaultWidth ?? defaultItemWidth,
        height: option.options?.height ?? defaultHeight ?? defaultItemHeight,
    };
    return itemSize;
}

/*!
 * function tooltipItemRect defines scaled
 * rectangle of tooltip item
 */
function tooltipItemRect(props: TooltipItemProps) {
    let option = props.option;
    let isImageUrl = false;
    let isUrl = false;
    if (typeof props.value === "string") {
        isUrl = StringUtils.isValidHttpUrl(props.value as string);
        isImageUrl = StringUtils.isImageUrl(props.value as string);
    }
    let showTitle = option.options.showTitle ?? true;
    let position = tooltipItemPosition(
        props.option,
        props.defaultY,
        props.widthOffset
    );
    position.x = position.x * props.scale;
    position.y = position.y * props.scale;
    let itemSize = tooltipItemSize(
        props.option,
        props.defaultWidth,
        props.defaultHeight
    );
    let targetValue = "";
    if (
        option.options.width == null &&
        option.options.height == null &&
        !props.editable &&
        !isUrl &&
        !isImageUrl
    ) {
        targetValue = showTitle
            ? `${option.variable.label} : ${props.value}`
            : `${props.value}`;
        itemSize = getTextSize(
            targetValue,
            "Roboto",
            option.options.fontSize * props.scale,
            "normal"
        );
        itemSize.width += 5;
    } else {
        itemSize.width = itemSize.width * props.scale;
        itemSize.height = itemSize.height * props.scale;
    }
    return {
        x: position.x,
        y: position.y,
        width: itemSize.width,
        height: itemSize.height,
    };
}

/*!
 * TooltipVideoComponent defines tooltip item with video url
 *
 */

function TooltipVideoComponent({
    src,
    width,
    height,
    iframeTitle,
    style,
}: {
    src: string;
    width: number | string;
    height: number | string;
    iframeTitle: string;
    style?: CSSProperties;
}) {
    let urlString = processUrl(src);
    if (urlString != null) {
        return (
            <iframe
                draggable={false}
                title={iframeTitle}
                frameBorder="0"
                allow=""
                allowFullScreen
                width={width}
                height={height}
                src={urlString}
                style={style}
            />
        );
    } else {
        return (
            <video controls={true} width={width} height={height} style={style}>
                <source src={src} />
            </video>
        );
    }
}

/*!
 * function TooltipItemContent renders tooltip item: regular variable item, image item or video item
 * according to value
 */
function TooltipItemContent(props: TooltipItemProps) {
    let option = props.option;
    let [drag, setDrag] = useState(false);
    let isImageUrl = false;
    let isUrl = false;
    if (typeof props.value === "string") {
        isUrl = StringUtils.isValidHttpUrl(props.value as string);
        isImageUrl = StringUtils.isImageUrl(props.value as string);
    }
    let showTitle = option.options.showTitle ?? true;
    let position = tooltipItemPosition(
        props.option,
        props.defaultY,
        props.widthOffset
    );
    position.x = position.x * props.scale;
    position.y = position.y * props.scale;
    let itemSize = tooltipItemSize(
        props.option,
        props.defaultWidth,
        props.defaultHeight
    );
    let targetValue = "";
    let defaultTextStyle = {};
    if (
        option.options.width == null &&
        option.options.height == null &&
        !props.editable &&
        !isUrl &&
        !isImageUrl
    ) {
        defaultTextStyle = {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap" as "nowrap",
        };
        targetValue = showTitle
            ? `${option.variable.label} : ${props.value}`
            : `${props.value}`;
        itemSize = getTextSize(
            targetValue,
            "Roboto",
            Math.ceil(option.options.fontSize * props.scale),
            "normal"
        );
        itemSize.width += 5;
    } else {
        itemSize.width = itemSize.width * props.scale;
        itemSize.height = itemSize.height * props.scale;
    }
    let textStyle = {
        ...defaultTextStyle,
        textAlign: option.options.textAlign ?? TextAlign.left,
        display: "block",
        width: itemSize.width,
        height: itemSize.height,
        color: option.options.fontColor,
        fontSize: option.options.fontSize * props.scale,
        fontWeight: 400,
    };
    return (
        <Draggable
            key={props.index}
            disabled={!props.editable}
            position={position}
            onMouseDown={(evt) => {
                evt.stopPropagation();
            }}
            onStart={() => {}}
            onDrag={(_evt, _data) => {
                setDrag(true);
            }}
            onStop={(_evt, data) => {
                if (drag) {
                    props.onTrackNewPerformance(elements.map);
                    let x = Math.max(data.x, 0);
                    let y = Math.max(data.y, 0);
                    let mapElement: MapElement = { ...props.mapElement! };
                    (mapElement[
                        tooltipType2Key[props.type]
                    ] as MapVariableOption[])[props.index].options = {
                        ...(mapElement[
                            tooltipType2Key[props.type]
                        ] as MapVariableOption[])[props.index].options,
                        x: x / props.scale,
                        y: y / props.scale,
                    };
                    props.onChange!(mapElement);
                    setDrag(false);
                }
            }}
        >
            <Resizable
                enable={
                    !props.editable
                        ? {
                              top: false,
                              right: false,
                              bottom: false,
                              left: false,
                              topRight: false,
                              bottomRight: false,
                              bottomLeft: false,
                              topLeft: false,
                          }
                        : {
                              top: true,
                              right: true,
                              bottom: true,
                              left: true,
                              topRight: true,
                              bottomRight: true,
                              bottomLeft: true,
                              topLeft: true,
                          }
                }
                onResizeStart={(evt) => {
                    evt.stopPropagation();
                }}
                onResizeStop={(_e, _direction, _ref, d) => {
                    props.onTrackNewPerformance(elements.map);
                    let width = itemSize.width + d.width;
                    let height = itemSize.height + d.height;
                    let mapElement: MapElement = { ...props.mapElement! };
                    (mapElement[
                        tooltipType2Key[props.type]
                    ] as MapVariableOption[])[props.index].options = {
                        ...(mapElement[
                            tooltipType2Key[props.type]
                        ] as MapVariableOption[])[props.index].options,
                        width: width / props.scale,
                        height: height / props.scale,
                    };
                    props.onChange!(mapElement);
                }}
                size={{
                    width: itemSize.width,
                    height: itemSize.height,
                }}
                style={{
                    overflow: "hidden",
                    top: 0,
                    left: 0,
                    border: props.editable ? "1px dashed black" : "none",
                    position: "absolute",
                }}
            >
                {!isUrl && (
                    <span style={textStyle} className="regular-text">
                        {showTitle
                            ? `${option.variable.label} : ${props.value}`
                            : `${props.value}`}
                    </span>
                )}
                {isImageUrl && (
                    <div className="flex-simple-column">
                        {showTitle && (
                            <span
                                style={{
                                    ...textStyle,
                                    display: "inline-block",
                                    height: undefined,
                                }}
                            >
                                {`${option.variable.label}`}
                            </span>
                        )}
                        <img
                            draggable={false}
                            alt=""
                            style={{
                                marginTop: "5px",
                                width: "100%",
                                height: "100%",
                            }}
                            src={props.value as string}
                        />
                    </div>
                )}
                {isUrl && !isImageUrl && (
                    <div className="flex-simple-column">
                        {showTitle && (
                            <span
                                style={{
                                    textAlign:
                                        option.options.textAlign ?? "left",
                                    display: "inline-block",
                                    width: itemSize.width,
                                    color: option.options.fontColor,
                                    fontSize:
                                        option.options.fontSize * props.scale,
                                }}
                                className="regular-text"
                            >
                                {`${option.variable.label}`}
                            </span>
                        )}

                        <TooltipVideoComponent
                            iframeTitle={`map-tooltip-iframe-${props.mapElementId}-${props.type}-${props.index}`}
                            style={{
                                marginTop: "5px",
                            }}
                            width={itemSize.width}
                            height={
                                itemSize.height -
                                5 -
                                option.options.fontSize * props.scale * 1.4
                            }
                            src={props.value as string}
                        />
                    </div>
                )}
            </Resizable>
        </Draggable>
    );
}

// properties for tooltip
interface TooltipProps {
    scale?: number;
    editable?: boolean;
    onTrackNewPerformance: (element: string) => void;
    mapElement?: MapElement;
    mapElementId: string;
    onChange?: (map: Partial<MapElement>) => void;
    options?: TooltipOptions | null;
    variables: (MapVariableOption | null)[];
    type: TooltipType;
    dataIndex: number;
    data: { [key: string]: Array<number | string | null> } | null;
}

/*!
 * function TooltipContent renders content of tooltip according to selected
 * options as an array of TooltipItemContent components
 *
 */
function TooltipContent(props: TooltipProps) {
    function isNullOrEmpty(value: any) {
        return value == null || value === "";
    }
    let editable = props.editable ?? false;
    let scale = props.scale ?? 1;
    let widthOffsets: {
        x: number;
        y: number;
        width: number;
        height: number;
    }[] = [];
    let tooltipSize = {
        width: 0,
        height: 0,
    };
    let widthOffsetsMap: {
        [index: string]: number;
    } = {};
    if (!editable) {
        props.variables.forEach((option, index) => {
            if (option == null) return;
            let value =
                props.data?.[option.variable.value.toString()]?.[
                    props.dataIndex
                ];
            let defaultY = tooltipSize.height / scale;
            let defaultTooltipRect = tooltipItemRect({
                scale: scale,
                editable: false,
                widthOffset: 0,
                defaultY: defaultY,
                index: index,
                type: props.type,
                onChange: props.onChange,
                onTrackNewPerformance: props.onTrackNewPerformance,
                mapElement: props.mapElement,
                mapElementId: props.mapElementId,
                option: option,
                value: isNullOrEmpty(value) ? "value" : value!,
            });
            if (isNullOrEmpty(value)) {
                let width = defaultTooltipRect.width / scale;
                let x = defaultTooltipRect.x / scale;
                let height = defaultTooltipRect.height / scale;
                let y = defaultTooltipRect.y / scale;
                widthOffsets.push({
                    width: width,
                    x: x,
                    height: height,
                    y: y,
                });
                return;
            }
            tooltipSize.width = Math.max(
                tooltipSize.width,
                defaultTooltipRect.x + defaultTooltipRect.width
            );
            tooltipSize.height = Math.max(
                tooltipSize.height,
                defaultTooltipRect.y + defaultTooltipRect.height
            );
        });
        tooltipSize = {
            width: 0,
            height: 0,
        };

        props.variables.forEach((option, index) => {
            if (option == null) return;
            let value =
                props.data?.[option.variable.value.toString()]?.[
                    props.dataIndex
                ];
            if (isNullOrEmpty(value)) return;
            let defaultY = tooltipSize.height / scale;
            let defaultTooltipRect = tooltipItemRect({
                scale: scale,
                editable: false,
                widthOffset: 0,
                defaultY: defaultY,
                index: index,
                type: props.type,
                onChange: props.onChange,
                onTrackNewPerformance: props.onTrackNewPerformance,
                mapElement: props.mapElement,
                mapElementId: props.mapElementId,
                option: option,
                value: value ?? "",
            });
            let xPosition = defaultTooltipRect.x / scale;
            let yPosition = defaultTooltipRect.y / scale;
            let height = defaultTooltipRect.height / scale;
            widthOffsetsMap[index] = widthOffsets
                .filter(
                    (item) =>
                        item.x + item.width < xPosition &&
                        Math.abs(item.y - yPosition) < 5 &&
                        Math.abs(item.height + item.y - (height + yPosition)) <
                            5
                )
                .map((item) => item.width)
                .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                );
            tooltipSize.width = Math.max(
                tooltipSize.width,
                defaultTooltipRect.x + defaultTooltipRect.width
            );
            tooltipSize.height = Math.max(
                tooltipSize.height,
                defaultTooltipRect.y + defaultTooltipRect.height
            );
        });
    }
    tooltipSize = {
        width: 0,
        height: 0,
    };
    let items = props.variables.map((option, index) => {
        if (option == null) return null;
        let value =
            props.data?.[option.variable.value.toString()]?.[props.dataIndex];
        if (editable && isNullOrEmpty(value)) value = "value";
        if (!editable && isNullOrEmpty(value)) return null;
        let defaultY = tooltipSize.height / scale;
        let defaultTooltipRect = tooltipItemRect({
            scale: scale,
            editable: false,
            widthOffset: widthOffsetsMap[index] ?? 0,
            defaultY: defaultY,
            index: index,
            type: props.type,
            onChange: props.onChange,
            onTrackNewPerformance: props.onTrackNewPerformance,
            mapElement: props.mapElement,
            mapElementId: props.mapElementId,
            option: option,
            value: value ?? "",
        });
        let defaultHeight = defaultTooltipRect.height / scale;
        let defaultWidth = defaultTooltipRect.width / scale;
        let tooltipRect = tooltipItemRect({
            defaultHeight: defaultHeight,
            defaultWidth: defaultWidth,
            scale: scale,
            editable: editable,
            widthOffset: widthOffsetsMap[index] ?? 0,
            defaultY: defaultY,
            index: index,
            type: props.type,
            onChange: props.onChange,
            onTrackNewPerformance: props.onTrackNewPerformance,
            mapElement: props.mapElement,
            mapElementId: props.mapElementId,
            option: option,
            value: value ?? "",
        });
        // already scaled;
        tooltipSize.width = Math.max(
            tooltipSize.width,
            tooltipRect.x + tooltipRect.width
        );
        tooltipSize.height = Math.max(
            tooltipSize.height,
            tooltipRect.y + tooltipRect.height
        );
        return (
            <TooltipItemContent
                defaultHeight={defaultHeight}
                defaultWidth={defaultWidth}
                defaultY={defaultY}
                scale={scale}
                key={index}
                editable={editable}
                widthOffset={widthOffsetsMap[index] ?? 0}
                index={index}
                type={props.type}
                onChange={props.onChange}
                onTrackNewPerformance={props.onTrackNewPerformance}
                mapElement={props.mapElement}
                mapElementId={props.mapElementId}
                option={option}
                value={value ?? ""}
            />
        );
    });
    tooltipSize.width += 5;
    tooltipSize.height += 5;
    return (
        <div
            style={{
                minWidth: tooltipSize.width,
                minHeight: tooltipSize.height,
                maxWidth: tooltipSize.width,
                maxHeight: tooltipSize.height,
                backgroundColor: props.options?.fillColor ?? "white",
                position: "relative",
            }}
        >
            {editable && (
                <div
                    style={{
                        position: "absolute",
                        right: -30,
                        top: 0,
                    }}
                >
                    <ColorPicker
                        enableAlpha
                        value={props.options?.fillColor ?? "white"}
                        onChange={(value) => {
                            props.onTrackNewPerformance(elements.map);
                            props.onChange!({
                                ...props.mapElement,
                                [tooltipType2OptionsKey[props.type]]: {
                                    ...(props.mapElement![
                                        tooltipType2OptionsKey[props.type]
                                    ] as TooltipOptions),
                                    fillColor: value,
                                },
                            });
                        }}
                    />
                </div>
            )}
            {items}
        </div>
    );
}
enum Step {
    Initial = 0,
    SelectData = 1,
    SelectVariables = 2,
    SelectDisplayValues = 3,
    HeatMap = 4,
    MarkerIcon = 5,
    SelectConditions = 6,
    Final = 7,
}

const markerQueryLimit = 5000;
const visibleMarkersLimit = 1000;

interface MarkerCluserWrapperProps {
    coordinates: MapElement["coordinates"];
    usesCoordinates: MapElement["usesCoordinates"];
    displayAlways?: (MapVariableOption | null)[] | null;
    displayOnClick?: (MapVariableOption | null)[] | null;
    displayOnHover?: (MapVariableOption | null)[] | null;
    displayAlwaysOptions?: TooltipOptions | null;
    displayOnClickOptions?: TooltipOptions | null;
    displayOnHoverOptions?: TooltipOptions | null;
    markerIcon?: string | null; // base64 image
    markerColor?: string | null;
    markerColorVariableIndex?: number | null;
    varyMarkerColorByVariable?: boolean; // default: false
    heatMap?: VariableOption | null;
    lockMap: boolean;
    minZoomLevel: number;
    data: { [key: string]: Array<number | string | null> } | null;
    colorVariableValueToIndex: Map<string | number | null, number>;
    scale: number;
    onTrackNewPerformance: (element: string) => void;
    onShowPopup: (index: number) => void;
    onShowHoverTooltip: (
        index: number | null,
        point?: {
            x: number;
            y: number;
        }
    ) => void;
    mapElementId: string;
}

// This component is a wrapper for MarkerClusterGroup that
// renders clusters on a map
const MarkerClusterGroupWrapper = React.forwardRef(
    (props: MarkerCluserWrapperProps, ref) => {
        const markerGroup = useMemo(() => {
            if (props.data == null) return null;
            let latIndex: string;
            let lonIndex: string;
            if (props.usesCoordinates) {
                latIndex = props.coordinates!.latitude!.value.toString();
                lonIndex = props.coordinates!.longitude!.value.toString();
            } else {
                latIndex = "%lat";
                lonIndex = "%lon";
            }
            //we prepare marker for each coordinate and
            //wrap markers into MarkerClusterGroup
            //
            let markers = props.data[latIndex]
                ?.slice(0, visibleMarkersLimit)
                .map((_, index) => (
                    <MarkerWrapper
                        {...props}
                        key={index}
                        index={index}
                        latIndex={latIndex}
                        lonIndex={lonIndex}
                    />
                ));
            return (
                <MarkerClusterGroup
                    ref={ref}
                    onClick={(evt: any) => {
                        if (props.lockMap) {
                            if (evt.layer._group._spiderfied != null) {
                                evt.layer.unspiderfy();
                            } else {
                                evt.layer.spiderfy();
                            }
                        }
                    }}
                    spiderfyDistanceMultiplier={3}
                    key={Date.now()}
                    maxClusterRadius={30}
                    zoomToBoundsOnClick={!props.lockMap}
                    showCoverageOnHover={false}
                    removeOutsideVisibleBounds
                    disableClusteringAtZoom={17}
                    chunkedLoading
                >
                    {markers}
                </MarkerClusterGroup>
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            props.data,
            props.coordinates,
            props.displayAlways,
            props.displayOnClick,
            props.displayOnHover,
            props.displayAlwaysOptions,
            props.displayOnClickOptions,
            props.displayOnHoverOptions,
            props.markerIcon,
            props.markerColor,
            props.markerColorVariableIndex,
            props.varyMarkerColorByVariable,
            props.heatMap,
            props.lockMap,
            props.onShowPopup,
            props.onShowHoverTooltip,
            props.onTrackNewPerformance,
            props.colorVariableValueToIndex,
            props.usesCoordinates,
            props.scale,
            props.minZoomLevel,
            ref,
        ]);
        return markerGroup;
    }
);

interface MarkerWrapperProps extends MarkerCluserWrapperProps {
    latIndex: string;
    lonIndex: string;
    index: number;
}

/*!
 * function MarkerWrapper renders marker on a map
 * If we have variables that we need to display always, then tooltip
 * will be rendered within this component;
 * If we have variables displayed on hover or on click, we are using
 * callbacks - onShowPopup or onShowHoverTooltip respectively
 */
function MarkerWrapper(props: MarkerWrapperProps) {
    if (props.data == null) return null;

    let onClick = undefined;
    if (
        props.displayOnClick != null &&
        props.displayOnClick.filter((item) => item != null).length > 0
    ) {
        onClick = {
            click: () => {
                props.onShowPopup(props.index);
            },
        };
    }
    let markerColor: string | undefined | null = undefined;
    if (props.varyMarkerColorByVariable) {
        if (props.markerColorVariableIndex != null) {
            let colorIndex:
                | number
                | undefined = props.colorVariableValueToIndex.get(
                props.data![props.markerColorVariableIndex.toString()][
                    props.index
                ]
            );
            if (colorIndex != null) {
                markerColor = colorList[colorIndex];
            }
        }
    } else {
        markerColor = props.markerColor;
    }
    let position = [
        Number((props.data?.[props.latIndex][props.index] ?? NaN) as number),
        Number((props.data?.[props.lonIndex][props.index] ?? NaN) as number),
    ];
    if (Number.isNaN(position[0]) || Number.isNaN(position[1])) return null;
    return (
        <Marker
            key={props.index}
            eventHandlers={{
                mouseover: (evt) => {
                    if (
                        props.displayOnHover != null &&
                        props.displayOnHover.filter((item) => item != null)
                            .length > 0
                    )
                        props.onShowHoverTooltip(
                            props.index,
                            evt.containerPoint
                        );
                },
                mouseout: () => {
                    if (
                        props.displayOnHover != null &&
                        props.displayOnHover.filter((item) => item != null)
                            .length > 0
                    )
                        props.onShowHoverTooltip(null);
                },
                ...onClick,
            }}
            icon={
                props.heatMap == null
                    ? markerIcon(props.markerIcon, markerColor)
                    : markerIconTransparent
            }
            position={[position[0], position[1]]}
        >
            {props.displayAlways != null &&
                props.displayAlways.filter((item) => item != null).length >
                    0 && (
                    <Tooltip
                        direction="bottom"
                        permanent={true}
                        ref={(ref) => {
                            if (ref != null && ref.getElement() != null) {
                                ref.getElement()!.style.pointerEvents = "auto";
                            }
                        }}
                    >
                        <TooltipContent
                            scale={props.scale}
                            onTrackNewPerformance={props.onTrackNewPerformance}
                            options={props.displayAlwaysOptions}
                            dataIndex={props.index}
                            data={props.data}
                            variables={props.displayAlways ?? []}
                            type={TooltipType.AsAlways}
                            mapElementId={props.mapElementId}
                        />
                    </Tooltip>
                )}
        </Marker>
    );
}

function Header(props: {
    updating: boolean;
    showUpdateButton: boolean;
    frozen: boolean;
    onUpdateData: () => void;
    sharedPolicy: CanvasSharedPolicy;
    onDelete: () => void;
    showEditButton: boolean;
    onOpenColorOptions: () => void;
    onOpenGeoJsonFilePopup: () => void;
    onEditClicked: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
    return (
        <div
            style={{
                height: "30px",
                width: "100%",
                paddingLeft: 30,
                paddingRight: 30,
                marginTop: 10,
            }}
        >
            <div className="my-row" style={{ justifyContent: "space-between" }}>
                {false ? (
                    <div className="my-row" style={{ alignItems: "center" }}>
                        <img
                            alt=""
                            src={"/dist/img/data-exploration/server_purple.png"}
                        />
                        <span
                            style={{
                                marginLeft: 30,
                                fontFamily: "Roboto",
                                fontSize: mainStyle.getPropertyValue(
                                    "--path-header-size"
                                ),
                                color:
                                    dataScienceElementsStyle.primaryTextColor,
                            }}
                        >
                            {"Creating Map Element"}
                        </span>
                    </div>
                ) : (
                    <div />
                )}
                {!props.frozen && (
                    <>
                        <div style={{ flex: 1 }} />
                        {props.showEditButton && (
                            <div
                                style={{
                                    cursor: "pointer",
                                    marginRight: "10px",
                                }}
                                onClick={props.onEditClicked}
                            >
                                <svg
                                    style={{
                                        fill:
                                            dataScienceElementsStyle.secondaryTextColor,
                                    }}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z" />
                                </svg>
                            </div>
                        )}
                        {props.showUpdateButton && (
                            <CanvasPreventPropagationButton>
                                <div
                                    style={{
                                        marginRight: "10px",
                                        opacity: props.updating ? 0.3 : 1,
                                    }}
                                    title={"Update table"}
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        if (
                                            props.sharedPolicy ===
                                            CanvasSharedPolicy.SharedSlideUnAuth
                                        ) {
                                            goToInternalLink("/");
                                            return;
                                        }
                                        props.onUpdateData();
                                    }}
                                >
                                    <img
                                        alt=""
                                        src="/dist/img/insights/insights_reload.png"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            </CanvasPreventPropagationButton>
                        )}

                        <CanvasPreventPropagationButton>
                            <div
                                style={{
                                    marginRight: "10px",
                                }}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    props.onDelete();
                                }}
                            >
                                <img
                                    alt=""
                                    src="/dist/img/insights/insights_remove.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </CanvasPreventPropagationButton>
                        <CanvasPreventPropagationButton>
                            <div
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    props.onOpenGeoJsonFilePopup();
                                }}
                            >
                                <img
                                    alt=""
                                    src="/dist/img/insights/insights_plus.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </CanvasPreventPropagationButton>
                        <CanvasPreventPropagationButton>
                            <div
                                title={"Style"}
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                }}
                                onClick={(_evt) => {
                                    props.onOpenColorOptions();
                                }}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    style={{
                                        fill: "#39F",
                                    }}
                                    viewBox="0 0 24 24"
                                >
                                    <circle cx="10" cy="6" r="2" />
                                    <circle cx="10" cy="14" r="2" />
                                    <circle cx="10" cy="22" r="2" />
                                </svg>
                            </div>
                        </CanvasPreventPropagationButton>
                    </>
                )}
            </div>
        </div>
    );
}

interface ListenerProps {
    mapElement: MapElement;
    onChange: (mapElement: Partial<MapElement>) => void;
}

/*!
 * funciton mapListener checks if center or zoom of map are changed
 * and saves changes to MapElement
 */
function MapListener(props: ListenerProps) {
    const mapEvents = useMapEvents({
        zoomend: () => {
            let zoom = mapEvents.getZoom();
            let center = mapEvents.getCenter();
            props.onChange({
                zoom: zoom,
                center: { lat: center.lat, lng: center.lng },
            });
        },
        moveend: () => {
            let zoom = mapEvents.getZoom();
            let center = mapEvents.getCenter();
            props.onChange({
                zoom: zoom,
                center: { lat: center.lat, lng: center.lng },
            });
        },
    });
    return null;
}

const geoJsonSizeLimit: number = 50 * 1024 * 1024;

function HeatMap(props: { data: Array<[number, number, number]> }) {
    const map = useMap();
    const [heatLayer, setHeatLayer] = useState<Leaflet.HeatLayer>();
    useEffect(() => {
        let newHeatLayer = Leaflet.heatLayer(props.data, {
            minOpacity: 0.7,
            blur: 20,
            gradient: {
                0.0: "lime",
                0.6: "yellow",
                0.9: "red",
            },
        }).addTo(map);
        setHeatLayer(newHeatLayer);
        return () => {
            if (heatLayer != null) {
                heatLayer.remove();
            }
        };
        // heatLayer and map should not be in dependencies
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);
    return null;
}

/*!
 * MapElementView is a root class that renders
 * wizard for creation of map and result map
 */
export default class MapElementView extends Component<Props, State> {
    rootRef: React.RefObject<HTMLDivElement>;
    mapRef: Leaflet.Map | null;
    clusterRef = React.createRef<MarkerClusterGroup>();

    stepHistory: Step[];

    constructor(props: Props) {
        super(props);
        this.state = {
            heatMapData: [],
            updating: false,
            step: Step.Initial,
            animationDirection: "top",
            operationErrorMessage: null,
            operationStatus: OperationStatus.NotStarted,
            newDataSetName: "",
            permissions: [],
            data: null,
            selectedTooltipOption: tooltipOptions[0],
            popupMarkerIndex: null,
            hoverInfo: null,
            colorVariableValues: [],
            colorVariableValueToIndex: new Map(),
            geoJsonFilesPopupOpen: false,
            geoJsonSizes: {},
            popupStatus: undefined,
            message: undefined,
        };
        this.stepHistory = [Step.Initial];
        this.rootRef = React.createRef();
        this.mapRef = null;

        this.prepareHeatMap = this.prepareHeatMap.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.showHoverTooltip = this.showHoverTooltip.bind(this);
    }

    public componentDidMount(): void {
        if (this.props.mapElement.isDone) {
            this.updateData();
        }
        if (
            this.props.mapElement.varyMarkerColorByVariable &&
            this.props.mapElement.markerColorVariableIndex != null &&
            this.props.mapElement.markerIcon == null
        ) {
            this.getColorVariableValues();
        }

        let geoJsonSizes: { [key: string]: number } = {};
        for (let [key, item] of Object.entries(
            this.props.mapElement.geoJsonFiles ?? {}
        )) {
            if (item != null) {
                geoJsonSizes[key] = JSON.stringify(item).length;
            }
        }
        this.setState({
            geoJsonSizes: geoJsonSizes,
        });
    }

    public componentDidUpdate(prevProps: Props) {
        const { canvasViewMode } = this.props.canvasTreeStore;
        // Update geoJsonSizes
        let prevGeoJsonFiles = prevProps.mapElement.geoJsonFiles ?? {};
        let geoJsonSizes: { [key: string]: number } = {};
        let changed = false;
        for (let [key, item] of Object.entries(
            this.props.mapElement.geoJsonFiles ?? {}
        )) {
            let prevItem = prevGeoJsonFiles[key];
            if (item == null && prevItem != null) {
                geoJsonSizes[key] = 0;
                changed = true;
            } else if (item != null && prevItem == null) {
                geoJsonSizes[key] = JSON.stringify(item).length;
                changed = true;
            }
        }
        if (changed) {
            this.setState((state) => ({
                geoJsonSizes: {
                    ...state.geoJsonSizes,
                    ...geoJsonSizes,
                },
            }));
        }

        if (this.state.step === Step.Initial && this.props.mapElement.isDone) {
            // We are invalidating size of leaflet map if parent component is
            // rescaled or resized
            if (
                (this.props.mapElement.nodeSize[canvasViewMode].width !== prevProps.mapElement.nodeSize[canvasViewMode].width ||
                    this.props.mapElement.nodeSize[canvasViewMode].height !==
                        prevProps.mapElement.nodeSize[canvasViewMode].height) &&
                prevProps.scale === this.props.scale
            ) {
                if (this.mapRef != null) {
                    let oldBounds = this.mapRef.getBounds();
                    this.mapRef.invalidateSize();
                    if (oldBounds != null) {
                        this.mapRef.fitBounds(oldBounds);
                    }
                }
                //    this.prepareHeatMap();
            }
            let minZoomLevel =
                this.props.mapElement.colorOptions?.minZoomLevel ?? 0;
            if (this.mapRef != null) {
                this.mapRef.setMinZoom(minZoomLevel);
            }

            if (this.props.live !== prevProps.live) {
                let lockMap =
                    this.props.mapElement.colorOptions?.lockMap ?? false;
                lockMap = lockMap && this.props.live;
                if (this.clusterRef.current != null) {
                    ((this.clusterRef.current as any)
                        .options as any).zoomToBoundsOnClick = !lockMap;
                }
                if (this.mapRef != null) {
                    if (lockMap) {
                        this.mapRef.touchZoom.disable();
                        this.mapRef.doubleClickZoom.disable();
                        this.mapRef.boxZoom.disable();
                        this.mapRef.scrollWheelZoom.disable();
                        this.mapRef.dragging.disable();
                        this.mapRef.tap?.disable();
                        this.mapRef.keyboard.disable();
                        this.mapRef.zoomControl.remove();
                    } else {
                        this.mapRef.touchZoom.enable();
                        this.mapRef.doubleClickZoom.enable();
                        this.mapRef.boxZoom.enable();
                        this.mapRef.scrollWheelZoom.enable();
                        this.mapRef.dragging.enable();
                        this.mapRef.tap?.enable();
                        this.mapRef.keyboard.enable();
                        if (
                            this.mapRef.zoomControl == null ||
                            (this.mapRef.zoomControl as any)._map == null
                        ) {
                            let zoomControl = new Leaflet.Control.Zoom();
                            this.mapRef.zoomControl = zoomControl;
                            this.mapRef.addControl(zoomControl);
                        }
                    }
                }
            }
        }
        if (
            this.props.mapElement.varyMarkerColorByVariable &&
            this.props.mapElement.markerColorVariableIndex != null &&
            this.props.mapElement.markerIcon == null &&
            this.props.mapElement.markerColorVariableIndex !==
                prevProps.mapElement.markerColorVariableIndex
        ) {
            this.getColorVariableValues();
        }
    }

    /*!
     * function getColorVariableValues obtains values of this.props.mapElement.markerColorVariableIndex;
     * Called if this.props.mapElement.varyMarkerColorByVariable is true
     */
    private getColorVariableValues(): void {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                items?: (string | number | null)[];
            }>("/api/e/autocomplete", {
                variable_index: this.props.mapElement.markerColorVariableIndex,
                data_table_idx: this.props.mapElement.dataScope?.value,
                starts_with: "",
                module_id: this.props.currentModuleId ?? remoteModuleId,
            })
            .then((response) => {
                if (response.data.success && response.data.items != null) {
                    let colorVariableValueToIndex: Map<
                        string | number | null,
                        number
                    > = new Map();
                    for (let i = 0; i < response.data.items.length; ++i) {
                        colorVariableValueToIndex.set(
                            response.data.items[i],
                            i
                        );
                    }
                    this.setState(
                        {
                            colorVariableValues: response.data.items,
                            colorVariableValueToIndex: colorVariableValueToIndex,
                        },
                        this.prepareHeatMap
                    );
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /*!
     * function showPopup called if we clock some marker and
     * have variables displayed on click
     */
    private showPopup(index: number) {
        this.setState({ popupMarkerIndex: index });
    }

    /*!
     * function showHoverTooltip called if we hover some marker and
     * have variables displayed on hover
     */
    private showHoverTooltip(
        index: number | null,
        point?: { x: number; y: number }
    ) {
        console.log(point);
        if (index != null) {
            this.setState({
                hoverInfo: {
                    index: index,
                    x: point!.x,
                    y: point!.y,
                },
            });
        } else this.setState({ hoverInfo: null });
    }

    /*!
     * function prepareHeatMap prepares heatmap
     * and saves it into this.state.heatMapData
     */
    private prepareHeatMap(): void {
        let mapElement = this.props.mapElement;
        let heatMapData: Array<[number, number, number]> = [];
        if (mapElement.isDone && this.state.data != null) {
            let latIndex: string;
            let lonIndex: string;
            if (mapElement.usesCoordinates) {
                latIndex = mapElement.coordinates!.latitude!.value.toString();
                lonIndex = mapElement.coordinates!.longitude!.value.toString();
            } else {
                latIndex = "%lat";
                lonIndex = "%lon";
            }
            if (this.state.data[latIndex] != null) {
                let length: number = this.state.data[latIndex].length;

                if (mapElement.heatMap != null) {
                    let numbers = this.state.data[
                        mapElement.heatMap.value.toString()
                    ].filter((item) => typeof item === "number") as number[];
                    let max = Math.max(...numbers);
                    let min = Math.min(...numbers);
                    for (let i = 0; i < length; ++i) {
                        let value = Number(
                            this.state.data[
                                mapElement.heatMap.value.toString()
                            ][i]
                        );
                        let position = [
                            Number(
                                (this.state.data[latIndex][i] ?? NaN) as number
                            ),
                            Number(
                                (this.state.data[lonIndex][i] ?? NaN) as number
                            ),
                        ];
                        if (
                            !Number.isNaN(position[0]) &&
                            !Number.isNaN(position[1]) &&
                            !Number.isNaN(value)
                        ) {
                            heatMapData.push([
                                position[0],
                                position[1],
                                (value - min) / (max - min),
                            ]);
                        }
                    }
                }
            }
            this.setState({ heatMapData: heatMapData });
        }
    }
    /*!
     * function updateData gets data for map from backend and prepares heatmap
     * according to settings and options stored in this.props.mapElement;
     * data is dictionary with index of variable as key and array of values
     * of this variable as value; data is stored in this.state.data
     */
    private updateData(limit?: number): void {
        if (this.state.updating) return;
        this.setState({ updating: true });
        let variableIndices = new Set<number>();
        let location:
            | { [key: string]: string | number }
            | undefined = undefined;
        if (
            this.props.mapElement.usesCoordinates &&
            this.props.mapElement.coordinates != null
        ) {
            for (let option of Object.values(
                this.props.mapElement.coordinates
            )) {
                if (option != null) {
                    variableIndices.add(option.value);
                }
            }
        } else if (
            !this.props.mapElement.usesCoordinates &&
            this.props.mapElement.location != null
        ) {
            for (let option of Object.keys(this.props.mapElement.location)) {
                let value = this.props.mapElement.location[
                    option as keyof MapElement["location"]
                ] as VariableOption;
                if (value != null) {
                    if (location == null) {
                        location = {};
                    }
                    location = {
                        ...location,
                        [option]: value.value,
                    };
                }
            }
        }

        if (this.props.mapElement.heatMap != null) {
            variableIndices.add(this.props.mapElement.heatMap.value);
        }

        let displays = [
            this.props.mapElement.displayAlways,
            this.props.mapElement.displayOnClick,
            this.props.mapElement.displayOnHover,
        ];
        for (let display of displays) {
            if (display != null) {
                for (let option of display) {
                    if (option != null) {
                        variableIndices.add(option.variable.value);
                    }
                }
            }
        }

        if (
            this.props.mapElement.varyMarkerColorByVariable &&
            this.props.mapElement.markerColorVariableIndex != null
        ) {
            variableIndices.add(this.props.mapElement.markerColorVariableIndex);
        }

        let orderBy: number[] = [];
        if (this.props.mapElement.selectOrderBy != null) {
            for (let option of this.props.mapElement.selectOrderBy) {
                if (option != null) {
                    orderBy.push(option.value);
                }
            }
        }

        axios
            .post<{
                success: boolean;
                error_msg: string;
                data?: { [key: string]: Array<number | string | null> };
            }>("/api/e/get_map_variable_values", {
                data_table_idx: this.props.mapElement.dataScope?.value,
                location: location,
                table: this.props.mapElement.tableOption?.value,
                conditions_id: this.props.mapElement.tableOption?.condition_id,
                variable_indices: Array.from(variableIndices),
                order_by_asc_desc: this.props.mapElement.selectLowest
                    ? "asc"
                    : "desc",
                order_by: orderBy,
                limit:
                    limit ??
                    this.props.mapElement.selectLimit ??
                    markerQueryLimit,
                conditions:
                    this.props.mapElement.conditions != null
                        ? conditionsToJson(
                              this.props.mapElement.conditions.filter(
                                  (cond): cond is Condition =>
                                      cond != null &&
                                      cond.variable != null &&
                                      cond.value != null &&
                                      cond.operation != null
                              )
                          )
                        : null,
                module_id: this.props.currentModuleId ?? remoteModuleId,
            })
            .then((response) => {
                if (response.data.success && response.data.data != null) {
                    this.setState(
                        {
                            data: response.data.data,
                            updating: false,
                        },
                        () => {
                            this.prepareHeatMap();
                        }
                    );
                } else {
                    this.setState({ updating: false });
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                this.setState({ updating: false });

                console.log(error);
            });
    }

    /*!
     * function buildHeader renders header component with update button,
     * delete button etc.
     */
    private buildHeader(colorOptions: ColorOptions): JSX.Element {
        if (this.props.live) return <div style={{ minHeight: "40px" }} />;
        return (
            <Header
                onOpenColorOptions={() => {
                    this.props.onOpenColorOptions(
                        colorOptions,
                        this.mapRef?.getZoom()
                    );
                }}
                onOpenGeoJsonFilePopup={() => {
                    this.setState({
                        geoJsonFilesPopupOpen: true,
                    });
                }}
                showUpdateButton={
                    this.state.step === Step.Initial &&
                    this.props.mapElement.isDone
                }
                updating={this.state.updating}
                frozen={this.props.frozen}
                onDelete={() => {
                    this.props.onDelete();
                }}
                sharedPolicy={this.props.sharedPolicy}
                onUpdateData={() => {
                    this.setState(
                        {
                            data: null,
                            heatMapData: [],
                            hoverInfo: null,
                            popupMarkerIndex: null,
                        },
                        () => {
                            this.updateData();
                        }
                    );
                }}
                showEditButton={
                    this.state.step === Step.Initial &&
                    this.props.mapElement.isDone
                }
                onEditClicked={(evt) => {
                    evt.stopPropagation();
                    if (
                        this.props.sharedPolicy === CanvasSharedPolicy.NotShared
                    ) {
                        this.props.onChange({
                            ...this.props.mapElement,
                            isDone: false,
                        });
                        this.setState({
                            data: null,
                            heatMapData: [],
                        });

                        this.stepDown();
                    }
                    if (
                        this.props.sharedPolicy ===
                        CanvasSharedPolicy.SharedSlideUnAuth
                    )
                        goToInternalLink("/");
                }}
            />
        );
    }

    /*!
     * function calculateNextStep calculates next step of wizard
     * according to current step
     */
    private calculateNextStep(step: Step): Step {
        let newStep = step;
        switch (step) {
            case Step.Initial:
                newStep = Step.SelectData;
                break;
            case Step.SelectData:
                if (
                    this.props.mapElement.dataScope != null &&
                    this.props.mapElement.tableOption != null
                ) {
                    newStep = Step.SelectVariables;
                }
                break;
            case Step.SelectVariables:
                if (
                    (this.props.mapElement.usesCoordinates &&
                        this.props.mapElement.coordinates?.latitude != null &&
                        this.props.mapElement.coordinates?.longitude != null) ||
                    (!this.props.mapElement.usesCoordinates &&
                        this.props.mapElement.location?.country != null)
                ) {
                    newStep = Step.SelectDisplayValues;
                }
                break;
            case Step.SelectDisplayValues:
                newStep = Step.HeatMap;
                break;
            case Step.HeatMap:
                if (this.props.mapElement.heatMap != null)
                    newStep = Step.SelectConditions;
                else newStep = Step.MarkerIcon;
                break;
            case Step.MarkerIcon:
                newStep = Step.SelectConditions;
                break;
            case Step.SelectConditions:
                newStep = Step.Final;
                break;
            default:
                break;
        }
        return newStep;
    }

    /*!
     * function stepUp called when we move to a prevous screen of wizard
     */
    private stepUp(): void {
        if (this.state.step === Step.Initial) return;
        else if (this.state.step === Step.Final) {
            if (this.state.operationStatus === OperationStatus.Running) return;
            this.setState({
                operationStatus: OperationStatus.NotStarted,
                operationErrorMessage: null,
            });
            if (this.state.operationStatus === OperationStatus.Success) {
                this.goToInitial();
                return;
            }
        }
        let prevStep = this.stepHistory[this.stepHistory.length - 2];
        this.setState({ animationDirection: "bottom" }, () => {
            this.setState(
                {
                    step: prevStep!,
                },
                () => {
                    this.stepHistory.pop();
                }
            );
        });
    }

    /*!
     * function goToInitial moves to initial screen of wizard
     */
    private goToInitial(): void {
        this.setState(
            {
                animationDirection: "bottom",
                operationStatus: OperationStatus.NotStarted,
            },
            () => {
                this.setState(
                    {
                        step: Step.Initial,
                    },
                    () => {
                        this.stepHistory = [this.state.step];
                    }
                );
            }
        );
    }

    /*!
     * function stepDown called when we move to next screen of wizard
     */
    private stepDown(): void {
        if (this.state.step === Step.Final) return;
        let nextStep = this.calculateNextStep(this.state.step);
        if (nextStep === Step.Final) {
            this.updateData(5);
        }
        if (nextStep === this.state.step) return;

        this.setState({ animationDirection: "top" }, () => {
            this.setState(
                {
                    step: nextStep,
                },
                () => {
                    this.stepHistory.push(nextStep);
                }
            );
        });
        this.rootRef.current?.focus();
    }

    private buildLeftBar(): JSX.Element | null {
        if (this.state.step === Step.Initial) return null;
        return (
            <div
                className="flex-simple-column"
                style={{
                    paddingLeft: 48,
                    minWidth: "116px",
                }}
            >
                {this.state.step > Step.Initial && (
                    <CanvasPreventPropagationButton>
                        <div
                            onClick={(evt) => {
                                evt.stopPropagation();
                                this.stepUp();
                            }}
                        >
                            <img
                                alt=""
                                src={UpButtonBig} //"/dist/img/canvas/up_big.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </CanvasPreventPropagationButton>
                )}
                <div style={{ flexGrow: 1 }}> </div>
                {this.state.step < Step.Final && (
                    <CanvasPreventPropagationButton>
                        <div
                            onClick={(evt) => {
                                evt.stopPropagation();
                                this.stepDown();
                            }}
                        >
                            <img
                                alt=""
                                src={DownButtonBig} //"/dist/img/canvas/down_big.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </CanvasPreventPropagationButton>
                )}
            </div>
        );
    }

    /*!
     * function buildInnerItem renders main view of component according
     * to current step
     */
    private buildInnerItem(): JSX.Element | null {
        const { canvasViewMode } = this.props.canvasTreeStore;
        //If step is initial and map element is done
        //then we render map element
        //If step is initial and map element is not done
        //then we render placeholder screen with edit button
        if (this.state.step === Step.Initial) {
            let lockMap = this.props.mapElement.colorOptions?.lockMap ?? false;
            lockMap = lockMap && this.props.live;
            let minZoomLevel =
                this.props.mapElement.colorOptions?.minZoomLevel ?? 0;

            // To make the map less blurry on higher scales

            const mapViewScale = 1.5;
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{ width: "100%", height: "100%" }}
                    >
                        <div
                            className="flex-simple-column"
                            style={{ alignSelf: "center" }}
                        >
                            {!this.props.mapElement.isDone && (
                                <div
                                    style={{
                                        marginTop: "31px",
                                        alignSelf: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        if (
                                            this.props.sharedPolicy ===
                                            CanvasSharedPolicy.NotShared
                                        )
                                            this.stepDown();
                                        if (
                                            this.props.sharedPolicy ===
                                            CanvasSharedPolicy.SharedSlideUnAuth
                                        )
                                            goToInternalLink("/");
                                    }}
                                >
                                    <svg
                                        style={{
                                            fill:
                                                dataScienceElementsStyle.secondaryTextColor,
                                        }}
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z" />
                                    </svg>
                                </div>
                            )}
                        </div>
                        {this.props.mapElement.isDone && (
                            <div
                                style={{
                                    width: Math.max(
                                        (this.props.mapElement.nodeSize[canvasViewMode].width -
                                            40 / this.props.scale) *
                                            mapViewScale,
                                        1
                                    ),
                                    height: Math.max(
                                        (this.props.mapElement.nodeSize[canvasViewMode].height -
                                            // 30 = top panel height,
                                            // 10 = top panel margin,
                                            // 20 = top and bottom padding
                                            (20 + 30 + 10 + 20) /
                                                this.props.scale) *
                                            mapViewScale,
                                        1
                                    ),
                                    transform: `scale(${
                                        this.props.scale / mapViewScale
                                    })`,
                                    transformOrigin: "top left",
                                    // If position is not absolute, then scaleY
                                    // would consider the parent's height, which
                                    // is NOT the desired behavior
                                    position: "absolute",
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <MapContainer
                                        worldCopyJump
                                        minZoom={minZoomLevel}
                                        tap={!lockMap}
                                        keyboard={!lockMap}
                                        touchZoom={!lockMap}
                                        doubleClickZoom={!lockMap}
                                        scrollWheelZoom={!lockMap}
                                        boxZoom={!lockMap}
                                        dragging={!lockMap}
                                        zoomControl={!lockMap}
                                        whenCreated={(mapInstance) => {
                                            this.mapRef = mapInstance;
                                        }}
                                        className="element-leaflet-map"
                                        center={
                                            this.props.mapElement.center
                                                ? [
                                                      this.props.mapElement
                                                          .center.lat,
                                                      this.props.mapElement
                                                          .center.lng,
                                                  ]
                                                : [0, 0]
                                        }
                                        zoom={this.props.mapElement.zoom ?? 1}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <MapListener
                                            mapElement={this.props.mapElement}
                                            onChange={this.props.onChange}
                                        />
                                        {this.state.heatMapData.length !==
                                            0 && (
                                            <HeatMap
                                                data={this.state.heatMapData}
                                            />
                                        )}
                                        {Object.values(
                                            this.props.mapElement
                                                .geoJsonFiles ?? {}
                                        )
                                            .filter(
                                                (
                                                    item
                                                ): item is {
                                                    name: string;
                                                    contents: GeoJsonObject;
                                                    color?: string;
                                                } => item != null
                                            )
                                            .map(
                                                (
                                                    { contents, color },
                                                    index
                                                ) => {
                                                    return (
                                                        <GeoJSON
                                                            key={`map-geojson-${index}`}
                                                            data={contents}
                                                            style={{
                                                                fillColor:
                                                                    color ??
                                                                    colorList[
                                                                        index %
                                                                            colorList.length
                                                                    ],
                                                                fillOpacity: 0.4,
                                                                color:
                                                                    color ??
                                                                    colorList[
                                                                        index %
                                                                            colorList.length
                                                                    ],
                                                                opacity: 1,
                                                                weight: 2,
                                                            }}
                                                            pointToLayer={(
                                                                feature,
                                                                latlng
                                                            ) => {
                                                                return Leaflet.marker(
                                                                    latlng,
                                                                    {
                                                                        icon: markerIconWithSymbol(
                                                                            this
                                                                                .props
                                                                                .mapElement
                                                                                .markerIcon,
                                                                            feature
                                                                                .properties[
                                                                                "marker-color"
                                                                            ] ??
                                                                                color ??
                                                                                colorList[
                                                                                    index %
                                                                                        colorList.length
                                                                                ],
                                                                            feature
                                                                                .properties[
                                                                                "marker-symbol"
                                                                            ]
                                                                        ),
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }
                                            )}
                                        <TileFilterLayer
                                            attribution='&copy; <a href="http://osm.org/copyright" spellcheck="false">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            filter={
                                                this.props.mapElement
                                                    .colorOptions?.grayscale
                                                    ? [
                                                          "grayscale:100%",
                                                          "invert: 100%",
                                                      ]
                                                    : []
                                            }
                                        />
                                        <MarkerClusterGroupWrapper
                                            mapElementId={
                                                this.props.mapElementId
                                            }
                                            coordinates={
                                                this.props.mapElement
                                                    .coordinates
                                            }
                                            usesCoordinates={
                                                this.props.mapElement
                                                    .usesCoordinates
                                            }
                                            displayAlways={
                                                this.props.mapElement
                                                    .displayAlways
                                            }
                                            displayOnClick={
                                                this.props.mapElement
                                                    .displayOnClick
                                            }
                                            displayOnHover={
                                                this.props.mapElement
                                                    .displayOnHover
                                            }
                                            displayOnHoverOptions={
                                                this.props.mapElement
                                                    .displayOnHoverOptions
                                            }
                                            displayOnClickOptions={
                                                this.props.mapElement
                                                    .displayOnClickOptions
                                            }
                                            displayAlwaysOptions={
                                                this.props.mapElement
                                                    .displayAlwaysOptions
                                            }
                                            markerIcon={
                                                this.props.mapElement.markerIcon
                                            }
                                            markerColor={
                                                this.props.mapElement
                                                    .markerColor
                                            }
                                            markerColorVariableIndex={
                                                this.props.mapElement
                                                    .markerColorVariableIndex
                                            }
                                            varyMarkerColorByVariable={
                                                this.props.mapElement
                                                    .varyMarkerColorByVariable
                                            }
                                            heatMap={
                                                this.props.mapElement.heatMap
                                            }
                                            scale={mapViewScale}
                                            data={this.state.data}
                                            colorVariableValueToIndex={
                                                this.state
                                                    .colorVariableValueToIndex
                                            }
                                            onTrackNewPerformance={
                                                this.props.onTrackNewPerformance
                                            }
                                            onShowPopup={this.showPopup}
                                            onShowHoverTooltip={
                                                this.showHoverTooltip
                                            }
                                            ref={this.clusterRef}
                                            lockMap={lockMap}
                                            minZoomLevel={minZoomLevel}
                                        />
                                    </MapContainer>
                                    {this.state.popupMarkerIndex != null && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "0px",
                                                left: "0px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                height: "100%",
                                                zIndex: 1000,
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                this.setState({
                                                    popupMarkerIndex: null,
                                                });
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                this.setState({
                                                    popupMarkerIndex: null,
                                                });
                                            }}
                                            onDrag={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onDragStart={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onDragEnd={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                        >
                                            <div
                                                style={{
                                                    opacity: 0.9,
                                                    boxShadow:
                                                        "0 1px 3px rgba(0,0,0,.4)",
                                                    padding: "6px",
                                                    borderRadius: "3px",
                                                    borderWidth: "1px",
                                                    borderStyle: "solid",
                                                    borderColor: "white",
                                                    backgroundColor: "white",
                                                    userSelect: "none",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onDrag={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onDragStart={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onDragEnd={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                            >
                                                <TooltipContent
                                                    options={
                                                        this.props.mapElement
                                                            .displayOnClickOptions
                                                    }
                                                    mapElementId={
                                                        this.props.mapElementId
                                                    }
                                                    onTrackNewPerformance={
                                                        this.props
                                                            .onTrackNewPerformance
                                                    }
                                                    scale={mapViewScale}
                                                    onChange={
                                                        this.props.onChange
                                                    }
                                                    mapElement={
                                                        this.props.mapElement
                                                    }
                                                    dataIndex={
                                                        this.state
                                                            .popupMarkerIndex
                                                    }
                                                    data={this.state.data}
                                                    variables={
                                                        this.props.mapElement
                                                            .displayOnClick ??
                                                        []
                                                    }
                                                    type={TooltipType.OnClick}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {this.state.hoverInfo != null && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: this.state.hoverInfo.y,
                                                left: this.state.hoverInfo.x,
                                                zIndex: 1000,
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onDrag={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onDragStart={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onDragEnd={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                        >
                                            <div
                                                style={{
                                                    opacity: 0.9,
                                                    boxShadow:
                                                        "0 1px 3px rgba(0,0,0,.4)",
                                                    padding: "6px",
                                                    borderRadius: "3px",
                                                    borderWidth: "1px",
                                                    borderStyle: "solid",
                                                    borderColor: "white",
                                                    backgroundColor: "white",
                                                    userSelect: "none",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onDrag={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onDragStart={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onDragEnd={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                            >
                                                <TooltipContent
                                                    options={
                                                        this.props.mapElement
                                                            .displayOnHoverOptions
                                                    }
                                                    mapElementId={
                                                        this.props.mapElementId
                                                    }
                                                    onTrackNewPerformance={
                                                        this.props
                                                            .onTrackNewPerformance
                                                    }
                                                    scale={mapViewScale}
                                                    onChange={
                                                        this.props.onChange
                                                    }
                                                    mapElement={
                                                        this.props.mapElement
                                                    }
                                                    dataIndex={
                                                        this.state.hoverInfo
                                                            .index
                                                    }
                                                    data={this.state.data}
                                                    variables={
                                                        this.props.mapElement
                                                            .displayOnHover ??
                                                        []
                                                    }
                                                    type={TooltipType.OnHover}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {this.props.mapElement
                                        .varyMarkerColorByVariable &&
                                        this.state.colorVariableValues
                                            .length !== 0 &&
                                        this.props.mapElement.markerIcon ==
                                            null && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "0px",
                                                    left: "0px",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "flex-start",
                                                    width: "100%",
                                                    height: "100%",
                                                    zIndex: 999,
                                                    pointerEvents: "none",
                                                }}
                                            >
                                                <div
                                                    className="element-leaflet-map"
                                                    style={{
                                                        marginTop: "30px",
                                                        marginRight: "30px",
                                                        maxHeight:
                                                            "calc(100% - 47px)",
                                                        opacity: 0.9,
                                                        boxShadow:
                                                            "0 1px 3px rgba(0,0,0,.4)",
                                                        padding: "6px",
                                                        borderRadius: "3px",
                                                        borderWidth: "1px",
                                                        borderStyle: "solid",
                                                        borderColor: "white",
                                                        backgroundColor:
                                                            "white",
                                                        userSelect: "none",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        overflowY: "auto",
                                                        pointerEvents: "auto",
                                                    }}
                                                >
                                                    {this.state.colorVariableValues.map(
                                                        (value, index) => (
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    marginTop:
                                                                        index !==
                                                                        0
                                                                            ? "5px"
                                                                            : undefined,
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width:
                                                                            "12px",
                                                                        height:
                                                                            "12px",
                                                                        backgroundColor:
                                                                            colorList[
                                                                                index
                                                                            ],
                                                                        borderRadius:
                                                                            "6px",
                                                                    }}
                                                                />
                                                                <span
                                                                    className="regular-text"
                                                                    style={{
                                                                        marginLeft:
                                                                            "5px",
                                                                        color:
                                                                            "black",
                                                                        fontSize:
                                                                            "12px",
                                                                    }}
                                                                >
                                                                    {value}
                                                                </span>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        } else if (this.state.step === Step.SelectData) {
            // here we render step for dataset selection
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"For the data in"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <DataScopeAndTableSelectorView
                        needWritePermissions={false}
                        dataScopeOption={this.props.mapElement.dataScope}
                        tableOption={this.props.mapElement.tableOption}
                        maxHeight={this.props.height * 0.5}
                        onChange={(
                            dataScopeOption: DataScopeOption | null,
                            tableOption: TableOption | null
                        ) => {
                            let mapElement = {
                                ...this.props.mapElement,
                                dataScope: dataScopeOption,
                                usesCoordinates: false,
                                tableOption: tableOption,
                                displayAlways: null,
                                displayOnHover: null,
                                displayOnClick: null,
                                displayAlwaysOptions: null,
                                displayOnHoverOptions: null,
                                displayOnClickOptions: null,
                                coordinates: null,
                                location: null,
                                selectOrderBy: null,
                                heatMap: null,
                                conditions: null,
                                center: null,
                                zoom: null,
                            };
                            if (mapElement.colorOptions != null) {
                                mapElement.colorOptions.lockMap = false;
                                mapElement.colorOptions.minZoomLevel = null;
                            }
                            this.props.onChange(mapElement);
                        }}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        } else if (this.state.step === Step.SelectVariables) {
            // here we render step for selection location variables
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Using the following location variables"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <LocationSelectorView
                        dataScope={this.props.mapElement.dataScope}
                        usesCoordinates={this.props.mapElement.usesCoordinates}
                        country={this.props.mapElement.location?.country}
                        state={this.props.mapElement.location?.state}
                        city={this.props.mapElement.location?.city}
                        address={this.props.mapElement.location?.address}
                        zipcode={this.props.mapElement.location?.zipcode}
                        latitude={this.props.mapElement.coordinates?.latitude}
                        longitude={this.props.mapElement.coordinates?.longitude}
                        maxHeight={this.props.height * 0.5}
                        onChange={(locationChanges, coordinateChanges) => {
                            let mapElement = {
                                ...this.props.mapElement,
                            };
                            if (locationChanges != null) {
                                mapElement.location = {
                                    ...mapElement.location,
                                    ...locationChanges,
                                };
                                mapElement.coordinates = undefined;
                            }
                            if (coordinateChanges != null) {
                                mapElement.coordinates = {
                                    ...mapElement.coordinates,
                                    ...coordinateChanges,
                                };
                                mapElement.location = undefined;
                            }
                            this.props.onChange(mapElement);
                        }}
                        onToggle={(usesCoordinates) => {
                            let mapElement = {
                                ...this.props.mapElement,
                                usesCoordinates: usesCoordinates,
                                location: undefined,
                                coordinates: undefined,
                            };
                            this.props.onChange(mapElement);
                        }}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        } else if (this.state.step === Step.SelectDisplayValues) {
            // here we setup values to display them in popups in a map
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Show the following values on the map"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div
                        className="flex-simple-column element"
                        style={{
                            marginLeft: "90px",
                            overflow: "auto",
                        }}
                    >
                        <span
                            className="regular-text"
                            style={{
                                width: "12em",
                                marginRight: 10,
                                fontSize: "25px",
                                color:
                                    dataScienceElementsStyle.primaryTextColor,
                            }}
                        >
                            Displayed always
                        </span>
                        <MapVariablesSelectorView
                            dataScope={this.props.mapElement.dataScope!}
                            variables={
                                this.props.mapElement.displayAlways ?? [null]
                            }
                            onChange={(newOptions) => {
                                this.props.onChange({
                                    ...this.props.mapElement,
                                    displayAlways: newOptions,
                                });
                            }}
                            currentModuleId={this.props.currentModuleId}
                        />
                        <span
                            className="regular-text"
                            style={{
                                width: "12em",
                                marginRight: 10,
                                fontSize: "25px",
                                color:
                                    dataScienceElementsStyle.primaryTextColor,
                            }}
                        >
                            Display on click
                        </span>
                        <MapVariablesSelectorView
                            dataScope={this.props.mapElement.dataScope!}
                            variables={
                                this.props.mapElement.displayOnClick ?? [null]
                            }
                            onChange={(newOptions) => {
                                this.props.onChange({
                                    ...this.props.mapElement,
                                    displayOnClick: newOptions,
                                });
                            }}
                            currentModuleId={this.props.currentModuleId}
                        />
                        <span
                            className="regular-text"
                            style={{
                                width: "12em",
                                marginRight: 10,
                                fontSize: "25px",
                                color:
                                    dataScienceElementsStyle.primaryTextColor,
                            }}
                        >
                            Display on hover
                        </span>
                        <MapVariablesSelectorView
                            dataScope={this.props.mapElement.dataScope!}
                            variables={
                                this.props.mapElement.displayOnHover ?? [null]
                            }
                            onChange={(newOptions) => {
                                this.props.onChange({
                                    ...this.props.mapElement,
                                    displayOnHover: newOptions,
                                });
                            }}
                            currentModuleId={this.props.currentModuleId}
                        />
                        <div style={{ flex: 1 }} />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                            }}
                        >
                            <span
                                className="regular-text"
                                style={{
                                    marginRight: 10,
                                    fontSize: "25px",
                                    color:
                                        dataScienceElementsStyle.primaryTextColor,
                                }}
                            >
                                [optional] and only show the
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "6em",
                                    marginRight: 10,
                                }}
                            >
                                <div
                                    className="regular-text"
                                    style={{
                                        fontSize: "25px",
                                        color:
                                            dataScienceElementsStyle.secondaryTextColor,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        this.props.onChange({
                                            ...this.props.mapElement,
                                            selectLowest: !this.props.mapElement
                                                .selectLowest,
                                        });
                                    }}
                                >
                                    {this.props.mapElement.selectLowest
                                        ? "lowest"
                                        : "highest"}
                                </div>
                            </div>
                            <Select
                                isClearable
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder=""
                                styles={{
                                    ...dataScienceSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "9em",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 51,
                                    }),
                                }}
                                options={[1, 2, 3, 4, 5, 10, 25, 50, 100].map(
                                    (value) => ({
                                        label: value.toString(),
                                        value: value,
                                    })
                                )}
                                onChange={(newValue) => {
                                    this.props.onChange({
                                        ...this.props.mapElement,
                                        selectLimit:
                                            (newValue as {
                                                label: string;
                                                value: number;
                                            } | null)?.value ?? null,
                                    });
                                }}
                                value={
                                    this.props.mapElement.selectLimit != null
                                        ? {
                                              label: this.props.mapElement.selectLimit.toString(),
                                              value: this.props.mapElement
                                                  .selectLimit,
                                          }
                                        : null
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <span
                                className="regular-text"
                                style={{
                                    marginLeft: 10,
                                    fontSize: "25px",
                                    color:
                                        dataScienceElementsStyle.primaryTextColor,
                                }}
                            >
                                by
                            </span>
                        </div>
                        <VariablesSelectorView
                            dataScope={this.props.mapElement.dataScope!}
                            variables={
                                this.props.mapElement.selectOrderBy ?? [null]
                            }
                            maxHeight={this.props.height * 0.28}
                            onChange={(newOptions) => {
                                this.props.onChange({
                                    ...this.props.mapElement,
                                    selectOrderBy: newOptions,
                                });
                            }}
                            currentModuleId={this.props.currentModuleId}
                        />
                    </div>
                </div>
            );
        } else if (this.state.step === Step.HeatMap) {
            //here we setup heatmap
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"[optional] and show a heat map using"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <Select
                        isClearable
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder="Select variable"
                        styles={{
                            ...dataScienceSelectStyles,
                            container: (base) => ({
                                ...base,
                                height: "35px",
                                width: "100%",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 51,
                            }),
                        }}
                        options={
                            Variables(
                                this.props.mapElement?.dataScope?.value,
                                this.props.currentModuleId ?? remoteModuleId
                            ).variableOptions
                        }
                        onChange={(newValue) => {
                            this.props.onChange({
                                ...this.props.mapElement,
                                heatMap: newValue as VariableOption | null,
                            });
                        }}
                        value={this.props.mapElement.heatMap}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                    <div
                        style={{
                            minHeight: "2px",
                            backgroundColor: "#39F",
                        }}
                    />
                </div>
            );
        } else if (this.state.step === Step.MarkerIcon) {
            // here we select custom marker icon
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"[optional] and use a custom marker icon"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div
                        className="flex-simple-column"
                        style={{
                            alignItems: "center",
                        }}
                    >
                        {(this.props.mapElement.markerIcon != null ||
                            this.props.mapElement.markerColor == null ||
                            this.props.mapElement
                                .varyMarkerColorByVariable) && (
                            <img
                                alt=""
                                src={
                                    this.props.mapElement.markerIcon ??
                                    "/dist/img/canvas/map/marker.png"
                                }
                                width="64px"
                                height="64px"
                            />
                        )}
                        {this.props.mapElement.markerIcon == null &&
                            this.props.mapElement.markerColor != null &&
                            !this.props.mapElement
                                .varyMarkerColorByVariable && (
                                <div
                                    style={{
                                        backgroundColor: this.props.mapElement
                                            .markerColor,
                                        maskImage:
                                            "url(/dist/img/canvas/map/marker.png)",
                                        WebkitMaskImage:
                                            "url(/dist/img/canvas/map/marker.png)",
                                        width: "64px",
                                        height: "64px",
                                    }}
                                />
                            )}
                        <label
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginTop: "5px",
                                width: "120px",
                                height: "38px",
                                lineHeight: "38px",
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                        >
                            <input
                                style={{ display: "none" }}
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                    const files = event?.target?.files ?? [];
                                    if (files.length !== 0) {
                                        const file = files[0];
                                        resizeAndEncodeToBase64(
                                            file,
                                            (base64Image) => {
                                                this.props.onChange({
                                                    ...this.props.mapElement,
                                                    markerIcon: base64Image,
                                                });
                                            },
                                            64,
                                            "image/png",
                                            "center",
                                            "bottom"
                                        );
                                    }
                                }}
                            />
                            CHANGE
                        </label>
                        {this.props.mapElement.markerIcon != null && (
                            <Button
                                className="btn btn-lg btn-primary my-primary"
                                style={{
                                    marginTop: "5px",
                                    width: "120px",
                                    height: "38px",
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                }}
                                onClick={() => {
                                    this.props.onChange({
                                        ...this.props.mapElement,
                                        markerIcon: null,
                                    });
                                }}
                            >
                                RESET
                            </Button>
                        )}
                        {this.props.mapElement.markerIcon == null && (
                            <div
                                style={{
                                    marginTop: "5px",
                                    width: "120px",
                                    height: "38px",
                                }}
                            />
                        )}
                    </div>
                    {this.props.mapElement.markerIcon != null && (
                        <span
                            style={{
                                marginTop: "15px",
                                fontFamily: "Arial",
                                fontSize: "17px",
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                            }}
                        >
                            Can't change color of a custom marker image
                        </span>
                    )}
                    {this.props.mapElement.markerIcon == null && (
                        <>
                            <Select
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder=""
                                styles={{
                                    ...dataScienceSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginTop: "15px",
                                        height: "35px",
                                        width: "20em",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 51,
                                    }),
                                }}
                                options={markerColoringOptions}
                                onChange={(newValue) => {
                                    this.props.onChange({
                                        ...this.props.mapElement,
                                        varyMarkerColorByVariable:
                                            (newValue as MarkerColoringOption)
                                                .value ===
                                            MarkerColoringType.Varied,
                                    });
                                }}
                                value={
                                    this.props.mapElement
                                        .varyMarkerColorByVariable
                                        ? markerColoringOptions[1]
                                        : markerColoringOptions[0]
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <div
                                style={{
                                    minHeight: "2px",
                                    width: "20em",
                                    backgroundColor: "#39F",
                                }}
                            />
                            {!this.props.mapElement
                                .varyMarkerColorByVariable && (
                                <div
                                    className="my-row element-leaflet-map"
                                    style={{
                                        alignItems: "center",
                                        marginTop: "15px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: "Arial",
                                            fontSize: "17px",
                                            color: mainStyle.getPropertyValue(
                                                "--popup-primary-text-color"
                                            ),
                                            width: "120px",
                                        }}
                                    >
                                        {"Marker Color"}
                                    </span>
                                    <ColorPicker
                                        enableAlpha
                                        value={
                                            this.props.mapElement.markerColor ??
                                            "#49A2FE"
                                        }
                                        onChange={(newValue) => {
                                            this.props.onChange({
                                                ...this.props.mapElement,
                                                markerColor: newValue,
                                            });
                                        }}
                                        style={{
                                            marginLeft: "6px",
                                        }}
                                    />
                                    {this.props.mapElement.markerColor !=
                                        null && (
                                        <Button
                                            className="btn btn-lg btn-primary my-primary"
                                            style={{
                                                marginLeft: "15px",
                                                width: "120px",
                                                height: "24px",
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                            }}
                                            onClick={() => {
                                                this.props.onChange({
                                                    ...this.props.mapElement,
                                                    markerColor: null,
                                                });
                                            }}
                                        >
                                            RESET
                                        </Button>
                                    )}
                                </div>
                            )}
                            {this.props.mapElement
                                .varyMarkerColorByVariable && (
                                <div
                                    className="flex-simple-column"
                                    style={{
                                        marginTop: "15px",
                                    }}
                                >
                                    <Select
                                        isClearable={true}
                                        menuPortalTarget={document.body}
                                        menuShouldBlockScroll={true}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder=""
                                        styles={{
                                            ...dataScienceSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                marginTop: "15px",
                                                height: "35px",
                                                width: "20em",
                                            }),
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 51,
                                            }),
                                        }}
                                        options={
                                            Variables(
                                                this.props.mapElement.dataScope
                                                    ?.value,
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            ).variableOptions
                                        }
                                        onChange={(newValue) => {
                                            this.props.onChange({
                                                ...this.props.mapElement,
                                                // Replace undefined with null
                                                markerColorVariableIndex:
                                                    (newValue as VariableOption)
                                                        ?.value ?? null,
                                            });
                                        }}
                                        value={
                                            this.props.mapElement
                                                .markerColorVariableIndex !=
                                            null
                                                ? Variables(
                                                      this.props.mapElement
                                                          .dataScope?.value,
                                                      this.props
                                                          .currentModuleId ??
                                                          remoteModuleId
                                                  ).variableOptions[
                                                      this.props.mapElement
                                                          .markerColorVariableIndex
                                                  ]
                                                : null
                                        }
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                    <div
                                        style={{
                                            minHeight: "2px",
                                            width: "20em",
                                            backgroundColor: "#39F",
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            );
        } else if (this.state.step === Step.SelectConditions) {
            // here we select conditions
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <ConditionsSelector
                        small
                        dataScopeId={this.props.mapElement.dataScope!.value}
                        title={"where"}
                        style={{
                            marginTop: 0,
                        }}
                        value={this.props.mapElement.conditions ?? undefined}
                        onChange={(conditions: Condition[]) => {
                            this.props.onChange({
                                ...this.props.mapElement,
                                conditions: conditions,
                            });
                        }}
                        allVariables={
                            Variables(
                                this.props.mapElement.dataScope!.value,
                                this.props.currentModuleId ?? remoteModuleId
                            ).dataVariables
                        }
                        {...ConditionSelectorStyles}
                    />
                </div>
            );
        } else if (this.state.step === Step.Final) {
            // here we render step where we can setup
            // position, style and size of tooltips
            return (
                <div
                    className="flex-simple-column element"
                    style={{
                        overflow: "auto",
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Set tooltips"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <Select
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder=""
                        styles={{
                            ...dataScienceSelectStyles,
                            container: (base) => ({
                                ...base,
                                marginBottom: "20px",
                                width: "15em",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 51,
                            }),
                        }}
                        options={tooltipOptions}
                        onChange={(newValue) => {
                            this.setState({
                                selectedTooltipOption: newValue as TooltipOption,
                            });
                        }}
                        value={this.state.selectedTooltipOption}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                    {(this.props.mapElement[
                        tooltipType2Key[this.state.selectedTooltipOption.value]
                    ] as (MapVariableOption | null)[])?.filter(
                        (item) => item != null
                    ).length > 0 && (
                        <>
                            <ColorsSelectorView
                                variables={
                                    (this.props.mapElement[
                                        tooltipType2Key[
                                            this.state.selectedTooltipOption
                                                .value
                                        ]
                                    ] as (MapVariableOption | null)[]) ?? []
                                }
                                onChange={(newOptions) => {
                                    this.props.onChange({
                                        ...this.props.mapElement,
                                        [tooltipType2Key[
                                            this.state.selectedTooltipOption
                                                .value
                                        ]]: newOptions,
                                    });
                                }}
                                style={{
                                    flexWrap: "wrap",
                                }}
                                itemStyle={{
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    width: "280px",
                                }}
                            />
                            <TooltipContent
                                scale={this.props.scale}
                                editable
                                onTrackNewPerformance={
                                    this.props.onTrackNewPerformance
                                }
                                onChange={this.props.onChange}
                                mapElement={this.props.mapElement}
                                mapElementId={this.props.mapElementId}
                                variables={
                                    (this.props.mapElement[
                                        tooltipType2Key[
                                            this.state.selectedTooltipOption
                                                .value
                                        ]
                                    ] as (MapVariableOption | null)[]) ?? []
                                }
                                options={
                                    this.props.mapElement[
                                        tooltipType2OptionsKey[
                                            this.state.selectedTooltipOption
                                                .value
                                        ]
                                    ] as TooltipOptions
                                }
                                type={this.state.selectedTooltipOption.value}
                                data={this.state.data}
                                dataIndex={0}
                            />
                        </>
                    )}
                    <Button
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            marginTop: "20px",
                            width: "150px",
                            height: "38px",
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginLeft: "15px",
                        }}
                        onClick={() => {
                            this.props.onChange({
                                ...this.props.mapElement,
                                isDone: true,
                            });
                            this.setState(
                                {
                                    data: null,
                                    heatMapData: [],
                                    hoverInfo: null,
                                    popupMarkerIndex: null,
                                },
                                () => {
                                    this.updateData();
                                }
                            );
                            this.goToInitial();
                        }}
                    >
                        DONE
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    }

    private buildContent(): JSX.Element {
        let isDone =
            this.state.step === Step.Initial && this.props.mapElement.isDone;
        return (
            <div
                className="my-row"
                style={{
                    paddingTop: !isDone ? "50px" : "20px",
                    height: "100%",
                    width: "100%",
                    paddingBottom: !isDone ? "38px" : "20px",
                }}
            >
                {this.buildLeftBar()}
                <div className="flex-simple-column" style={{ width: "100%" }}>
                    <React.Fragment key={this.state.step}>
                        {this.buildInnerItem()}
                    </React.Fragment>
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        let colorOptions: ColorOptions = this.props.mapElement.colorOptions ?? {
            borderShadow: false,
            fillColor: dataScienceElementsStyle.contentColor,
            borderColor: dataScienceElementsStyle.borderColor,
        };
        return (
            <>
                <div
                    ref={this.rootRef}
                    className="dashboard-rect-canvas dashboard-rect-canvas-focus"
                    tabIndex={0}
                    style={{
                        boxShadow: colorOptions.borderShadow
                            ? "0 6px 13px 0 rgba(21, 33, 56, 0.53)"
                            : "none",
                        backgroundColor: colorOptions.fillColor,
                        border: colorOptions.borderColor
                            ? `1px solid ${colorOptions.borderColor}`
                            : undefined,
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                    }}
                    onContextMenu={this.props.onContextMenu}
                    onKeyDown={(evt) => {
                        if (evt.key === "ArrowDown") {
                            evt.stopPropagation();
                            this.stepDown();
                            evt.preventDefault();
                            return;
                        }
                        if (evt.key === "ArrowUp") {
                            evt.stopPropagation();
                            evt.preventDefault();
                            this.stepUp();
                            return;
                        }
                        if (evt.key === "Enter" && evt.shiftKey) {
                            evt.stopPropagation();
                            evt.preventDefault();
                            this.stepDown();
                            return;
                        }
                    }}
                >
                    <div
                        className="flex-column"
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        {this.buildHeader(colorOptions)}

                        <TransitionGroup
                            style={{
                                height: "calc(100% - 40px)",
                                display: "flex",
                            }}
                        >
                            <CSSTransition
                                key={this.state.step}
                                timeout={500}
                                classNames={"journeywizard-".concat(
                                    this.state.animationDirection || ""
                                )}
                            >
                                <div style={{ minWidth: "100%" }}>
                                    {this.buildContent()}
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>
                {this.state.geoJsonFilesPopupOpen && (
                    <Popup
                        arrow={true}
                        contentStyle={{
                            width: 600,
                            minHeight: 600,
                            maxHeight: "100vh",
                            border: "none",
                            backgroundColor: "transparent",
                        }}
                        open={true}
                        onClose={() => {
                            this.setState({
                                geoJsonFilesPopupOpen: false,
                            });
                        }}
                    >
                        <div
                            className="dashboard-rect element"
                            style={{
                                overflowX: "visible",
                                overflowY: "auto",
                                boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                                borderRadius: 0,
                                alignItems: "center",
                                cursor: "pointer",
                                minHeight: 600,
                                maxHeight: "100vh",
                                width: 600,
                                padding: 15,
                            }}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onMouseDown={(evt) => {
                                evt.stopPropagation();
                            }}
                        >
                            <div className="flex-simple-column">
                                {Object.keys(
                                    this.props.mapElement.geoJsonFiles ?? {}
                                ).length === 0 && (
                                    <span
                                        className="regular-text"
                                        style={{
                                            width: "10em",
                                            marginBottom: 10,
                                        }}
                                    >
                                        NOT UPLOADED
                                    </span>
                                )}
                                {Object.entries(
                                    this.props.mapElement.geoJsonFiles ?? {}
                                )
                                    .filter(
                                        (
                                            item
                                        ): item is [
                                            string,
                                            {
                                                name: string;
                                                contents: GeoJsonObject;
                                                color?: string;
                                            }
                                        ] => item[1] != null
                                    )
                                    .map(
                                        (
                                            [index, { name, color }],
                                            mapIndex
                                        ) => (
                                            <div
                                                key={`geojson-file-${name}-${index}`}
                                                className="my-row"
                                                style={{
                                                    marginBottom: 10,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <span
                                                    className="regular-text"
                                                    style={{
                                                        width: "2em",
                                                    }}
                                                >
                                                    {mapIndex + 1}.
                                                </span>
                                                <input
                                                    type="text"
                                                    className="like-select"
                                                    placeholder="NAME"
                                                    style={{
                                                        marginRight: 10,
                                                        flex: 1,
                                                    }}
                                                    defaultValue={name}
                                                    onBlur={(e) => {
                                                        this.props.onChange(
                                                            {},
                                                            {
                                                                [index]: {
                                                                    ...this
                                                                        .props
                                                                        .mapElement
                                                                        .geoJsonFiles![
                                                                        index
                                                                    ]!,
                                                                    name:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                                <ColorPicker
                                                    inPopup
                                                    nested
                                                    value={
                                                        color ??
                                                        colorList[
                                                            mapIndex %
                                                                colorList.length
                                                        ]
                                                    }
                                                    onChange={(newValue) => {
                                                        this.props.onChange(
                                                            {},
                                                            {
                                                                [index]: {
                                                                    ...this
                                                                        .props
                                                                        .mapElement
                                                                        .geoJsonFiles![
                                                                        index
                                                                    ]!,
                                                                    color: newValue,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                    style={{
                                                        marginLeft: "6px",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        marginLeft: "10px",
                                                        width: "19px",
                                                    }}
                                                >
                                                    <Button
                                                        className="close"
                                                        onClick={() => {
                                                            this.props.onChange(
                                                                {},
                                                                {
                                                                    [index]: null,
                                                                }
                                                            );
                                                        }}
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">
                                                            &times;
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    )}
                                <div className="my-row">
                                    <label
                                        className="btn btn-lg btn-primary my-primary"
                                        style={{
                                            marginRight: "10px",
                                            width: "120px",
                                            height: "38px",
                                            lineHeight: "38px",
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        }}
                                    >
                                        <input
                                            style={{ display: "none" }}
                                            type="file"
                                            accept="text/json"
                                            onChange={(event) => {
                                                const files =
                                                    event?.target?.files ?? [];
                                                if (files.length !== 0) {
                                                    let totalSize = 0;
                                                    for (let size of Object.values(
                                                        this.state.geoJsonSizes
                                                    )) {
                                                        totalSize += size;
                                                    }
                                                    if (
                                                        totalSize +
                                                            files[0].size >
                                                        geoJsonSizeLimit
                                                    ) {
                                                        this.setState({
                                                            popupStatus:
                                                                PopupStatus.Error,
                                                            message:
                                                                "Not enough space. All GeoJSON files combined cannot exceed 50 MB.",
                                                        });
                                                        return;
                                                    }

                                                    let reader = new FileReader();
                                                    let key = files[0].name;
                                                    let keyIndex = 0;
                                                    while (
                                                        key in
                                                        (this.props.mapElement
                                                            .geoJsonFiles ?? {})
                                                    ) {
                                                        key = `${keyIndex}_${files[0].name}`;
                                                        keyIndex += 1;
                                                    }
                                                    reader.onload = (e) => {
                                                        this.props.onChange(
                                                            {},
                                                            {
                                                                [key]: {
                                                                    name:
                                                                        files[0]
                                                                            .name,
                                                                    contents: JSON.parse(
                                                                        e.target!
                                                                            .result as string
                                                                    ) as GeoJsonObject,
                                                                    color:
                                                                        colorList[
                                                                            Object.keys(
                                                                                this
                                                                                    .props
                                                                                    .mapElement
                                                                                    .geoJsonFiles ??
                                                                                    {}
                                                                            )
                                                                                .length %
                                                                                colorList.length
                                                                        ],
                                                                },
                                                            }
                                                        );
                                                    };
                                                    reader.readAsText(files[0]);
                                                }
                                            }}
                                        />
                                        ADD
                                    </label>
                                    {/*<Button
                                        type="button"
                                        className="btn btn-lg btn-primary my-primary"
                                        style={{
                                            marginLeft: 10,
                                            width: "120px",
                                            lineHeight: "38px",
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                geoJsonFilesPopupOpen: false,
                                            });
                                        }}
                                    >
                                        CLOSE
                                    </Button>*/}
                                </div>
                            </div>
                            {this.state.popupStatus != null && (
                                <StatusPopup
                                    onClose={() => {
                                        this.setState({
                                            popupStatus: undefined,
                                            message: undefined,
                                        });
                                    }}
                                    status={this.state.popupStatus}
                                    message={this.state.message ?? ""}
                                />
                            )}
                        </div>
                    </Popup>
                )}
            </>
        );
    }
}
