import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import {
    ScatterPlotFinding,
    BubbleFinding,
    Density2dFinding,
    isBubble,
    isDensity2d,
} from "common/Finding";
import { updateOriginalName, updateOriginalNames } from "../../common/Utils";

const journeyNameToTypeMap: Readonly<{ [key: string]: string }> = {
    ScatterplotJourney: "scatterplot",
    ScatterplotD3Journey: "scatterplot",
    BubbleJourney: "bubble",
    Density2dJourney: "density2d",
};

class Api {
    static getDefaultConfig(
        journeyName: string
    ): ScatterPlotFinding["config"] | BubbleFinding["config"] {
        return {
            count: 100,
            random: false,
            journeyName: journeyName,
            version: configVersionV2,
            showXAxisName: true,
            showYAxisName: true,
            showGrid: false,
        };
    }

    static getPreviewFinding(
        journeyName: string
    ): ScatterPlotFinding | BubbleFinding {
        let item = {
            type: journeyNameToTypeMap[journeyName],
            content: {
                data: [
                    {
                        name: "var1",
                        value: [50, 60, 70],
                    },
                    {
                        name: "var2",
                        value: [50, 60, 70],
                    },
                ],
                time: {
                    name: "time",
                    value: [],
                    uniqueValues: [],
                },
            },
            config: Api.getDefaultConfig(journeyName),
        };
        if (journeyName === "BubbleJourney") {
            item.content.data.push({
                name: "value",
                value: [10, 50, 100],
            });
        }
        return item;
    }

    static getData(
        finding: ScatterPlotFinding | BubbleFinding | Density2dFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<ScatterPlotFinding | BubbleFinding | Density2dFinding> {
        let { dataScope, count, selectedTable, conditions, random, trendline } =
            finding.config ?? {};
        let variables = finding.content.data
            .slice(0, 2)
            .filter((item) => item.variableIndex != null);
        if (finding.content.data.length >= 2 && variables.length < 2) {
            // This prevents the warning from appearing when the user dragged
            // one variable, but not the second one
            return Promise.resolve(finding);
        }
        conditions = filterNonEmptyConditions(conditions ?? []);

        let additionalVariableIndices: number[] | undefined = [];
        if (
            isBubble(finding) &&
            finding.content.data[2].variableIndex != null
        ) {
            additionalVariableIndices.push(
                finding.content.data[2].variableIndex
            );
        }
        if (finding.content.time?.variableIndex != null) {
            additionalVariableIndices.push(finding.content.time.variableIndex);
        }
        if (additionalVariableIndices.length === 0) {
            additionalVariableIndices = undefined;
        }

        let requestJson = {
            variable_indices: variables.map(
                (variable) => variable.variableIndex
            ),
            additional_variable_indices: additionalVariableIndices,
            skip_histogram: true,
            count: isDensity2d(finding) ? undefined : count,
            random: random,
            trendline: trendline,
            conditions: conditionsToJson(conditions),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            module_id: moduleId,
        };

        return axios
            .post<{
                data: {
                    [key: string]: (string | number | null)[];
                };
                trendline?: {
                    coef: number;
                    intercept: number;
                };
                success: boolean;
                error_msg: string;
            }>("/api/e/scatterplot_exploration", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: finding.content.data.map((item) => ({
                                ...item,
                            })),
                        },
                    };

                    // Check wherther variables were renamed
                    updateOriginalNames(
                        newFinding.content.data,
                        dataScope.value,
                        moduleId
                    );

                    let newData = response.data.data;
                    let size = undefined;
                    for (let key of Object.keys(newData)) {
                        let dataItems = newFinding.content.data.filter(
                            (item) => item.originalName === key
                        );
                        for (let dataItem of dataItems) {
                            dataItem.value = newData[key] as number[];
                            size = dataItem.value.length;
                        }
                    }
                    if (size != null) {
                        let notInitializedItems = newFinding.content.data.filter(
                            (item) => item.variableIndex == null
                        );
                        for (let notInitializedItem of notInitializedItems) {
                            notInitializedItem.value = new Array(size).fill(0);
                        }
                    }
                    if (finding.content.time?.originalName != null) {
                        newFinding.content.time = {
                            ...newFinding.content.time!,
                            value: newData[finding.content.time.originalName],
                            uniqueValues: Array.from(
                                new Set(
                                    newData[
                                        finding.content.time.originalName
                                    ].filter(
                                        (item): item is string | number =>
                                            item != null
                                    )
                                )
                            ).sort((value1, value2) =>
                                typeof value1 === "number" &&
                                typeof value2 === "number"
                                    ? value1 - value2
                                    : String(value1).localeCompare(
                                          String(value2)
                                      )
                            ),
                        };
                        // Check wherther variables were renamed
                        updateOriginalName(
                            newFinding.content.time,
                            dataScope.value,
                            moduleId
                        );
                    }
                    newFinding.content.trendlineInfo =
                        response.data.trendline ?? null;
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
