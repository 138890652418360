import { DataScopeOption } from "common/DataScopes";
import { TableOption } from "common/Tables";
import { VariableOption } from "common/Variables";
import { GroupExtendedPermission } from "common/GroupPermissions";
import axios from "common/ServerConnection";

export const transformOptions: ReadonlyArray<"pivot" | "unpivot"> = [
    "pivot",
    "unpivot",
];

export interface TransformState {
    sourceDataScope: DataScopeOption | null;
    sourceTable: TableOption | null;
    targetDataScope?: DataScopeOption | null;
    transformType: "pivot" | "unpivot";
    permissions: (GroupExtendedPermission | null)[];
}

export interface PivotState extends TransformState {
    pivotColumns: (VariableOption | null)[];
    pivotIndex: (VariableOption | null)[];
    pivotValues: (VariableOption | null)[];
}

export interface UnpivotState extends TransformState {
    pivotIndex: (VariableOption | null)[];
    variableColumnNames: string[];
    valueColumnNames: string[][];
    values: { variable: VariableOption | null; name: string }[][][];
}

export function isUnPivotState(
    transformState: TransformState
): transformState is UnpivotState {
    return transformState.transformType === "unpivot";
}

export function isPivotState(
    transformState: TransformState
): transformState is PivotState {
    return transformState.transformType === "pivot";
}

export function transformData(
    transformState: TransformState,
    onSuccess: (
        preview:
            | {
                tableHeader: string[];
                tablePreview: any[];
            }
            | undefined,
        roomId: string | undefined
    ) => void,
    onError: (error: string) => void,
    preview: number | undefined = undefined,
    moduleId?: number | string | null
) {
    let json: any = {
        data_table_idx: transformState.sourceTable!.data_table_idx,
        optimized: transformState.sourceTable!.optimized,
        table: transformState.sourceTable!.value,
        condition_id: transformState.sourceTable!.condition_id ?? null,
        operation: transformState.transformType,
        target_data_table_idx:
            preview != null ? undefined : transformState.targetDataScope?.value,
        target_data_table_name:
            preview != null
                ? undefined
                : transformState.targetDataScope?.label ?? "",
        permissions: undefined,
        // transformState.permissions
        //     .filter((permission) => permission != null)
        //     .map((permission) => ({
        //         group_id: permission!.group_id,
        //         permission_type: permission!.permission_type,
        //     })),
        preview: preview,
        module_id: moduleId,
    };
    if (isPivotState(transformState)) {
        json.operation_parameters = {};
        json.operation_parameters.index = transformState.pivotIndex
            .filter((item) => item != null)
            .map((item) => item!.value);
        json.operation_parameters.values = transformState.pivotValues
            .filter((item) => item != null)
            .map((item) => item!.value);
        json.operation_parameters.columns = transformState.pivotColumns
            .filter((item) => item != null)
            .map((item) => item!.value);
    }
    if (isUnPivotState(transformState)) {
        let variableColumnNames = [...transformState.variableColumnNames];
        let valueColumnNames = [...transformState.valueColumnNames];
        let values = [...transformState.values];
        values = values.map((val1) =>
            val1.map((val2) => val2.filter((value) => value.variable != null))
        );

        let hasEmptyArrays = values.flat().findIndex(val1 => val1.length === 0) >= 0;
        if (hasEmptyArrays) {
            onError("You have an empty variables sublist in your request");
            return;
        }
        json.operation_parameters = {};
        json.operation_parameters.index = transformState.pivotIndex
            .filter((item) => item != null)
            .map((item) => item!.value);
        json.operation_parameters.new_variable_column_names = variableColumnNames;
        json.operation_parameters.value_column_names = valueColumnNames;
        json.operation_parameters.values = values.map((val1) =>
            val1.map((val2) => val2.map((item) => item.variable?.value))
        );
        json.operation_parameters.rename_values = values.map((columnGroup) =>
            Object.fromEntries(
                columnGroup
                    .flat()
                    .map((item) => [item.variable!.value, item.name])
            )
        );
    }
    axios
        .post<{
            success: boolean;
            error_msg: string;
            table_header: string[];
            table_preview: any[];
            room_id: string;
        }>("/api/data_set_transform", json)
        .then((response) => {
            if (response.data.success === false) {
                onError(response.data.error_msg);
            } else {
                if (preview != null) {
                    onSuccess(
                        {
                            tableHeader: response.data.table_header,
                            tablePreview: response.data.table_preview,
                        },
                        undefined
                    );
                } else onSuccess(undefined, response.data.room_id);
            }
        })
        .catch((error) => {
            onError(String(error));
        });
}
