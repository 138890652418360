import React from "react";
import Switch from "react-switch";

import { CanvasNode, CanvasSharedToggle } from "common/Canvas";
import { GlobalContextContents } from "GlobalContext";

import styles from "./Settings.module.css";

interface Props {
  node: CanvasSharedToggle;
  shared: boolean;
  globalContext: GlobalContextContents;
  onChange: (changes: Partial<CanvasSharedToggle | CanvasNode>, commit?: boolean) => void;
  onChangeShared: (shared: boolean) => void;
}

function Settings({ node, shared, globalContext, onChange, onChangeShared }: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.switchField}>
        <Switch
          onChange={(checked) => {
            onChange({ hideLabels: !checked });
          }}
          checked={!node.hideLabels}
          width={26}
          height={13}
          offColor="#D1D1D1"
          onColor="#20293C"
          checkedIcon={false}
          uncheckedIcon={false}
          offHandleColor="#FFF"
          onHandleColor="#1F8EFA"
        />
        <span className={styles.switchLabel}>
          Show 0/1
        </span>
      </div>
      <div className={styles.switchField}>
        <Switch
          onChange={(checked) => {
            onChangeShared(checked);
          }}
          checked={shared}
          width={26}
          height={13}
          offColor="#D1D1D1"
          onColor="#20293C"
          checkedIcon={false}
          uncheckedIcon={false}
          offHandleColor="#FFF"
          onHandleColor="#1F8EFA"
        />
        <span className={styles.switchLabel}>
          Linkable from other slides
        </span>
      </div>
      {"CanvasLiveStream" in globalContext.permissions && (
        <div className={styles.switchField}>
          <Switch
            onChange={(checked) => {
              onChange({ liveStreaming: checked });
            }}
            checked={(node as CanvasNode).liveStreaming ?? false}
            width={26}
            height={13}
            offColor="#D1D1D1"
            onColor="#20293C"
            checkedIcon={false}
            uncheckedIcon={false}
            offHandleColor="#FFF"
            onHandleColor="#1F8EFA"
          />
          <span className={styles.switchLabel}>
            Record Metric to Dataset
          </span>
        </div>
      )}
    </div>
  );
}

export default Settings;
