import {
    timeToUTC,
    LiveStreamIntervalOption,
    liveStreamWeekDayOptions,
    LiveStreamWeekDayOption,
} from "common/InputData";
import StringOption from "common/StringOption";
import { GroupExtendedPermission } from "common/GroupPermissions";
import { DataScopeOption } from "common/DataScopes";
import axios from "common/ServerConnection";

export interface LiveStreamingState {
    liveStreaming: boolean;
    liveStreamInterval: LiveStreamIntervalOption;
    liveStreamWeekDay: LiveStreamWeekDayOption;
    liveStreamTime: string;
    liveStreamTimezone: StringOption;
    newDataScope: DataScopeOption | null;
    dataScope: DataScopeOption | undefined;
    permissions: (GroupExtendedPermission | null)[];
}

export function turnOnLiveStreamingApi(
    canvasId: number,
    liveStreamState: LiveStreamingState
): Promise<boolean> {
    let { weekday, timeString } = timeToUTC(
        liveStreamState.liveStreamWeekDay.value,
        liveStreamState.liveStreamTime,
        liveStreamState.liveStreamTimezone.value
    );
    let liveStreamWeekDayOption =
        liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
        liveStreamWeekDayOptions[0];
    let value = liveStreamState.dataScope!.value;
    let json: {
        data_table_idx?: number | string;
        data_table_name?: string;
        canvas_id: number;
        interval: string;
        weekday?: number;
        time: string;
        permissions: any[];
    } = {
        canvas_id: canvasId,
        data_table_idx: !Number.isNaN(value)
            ? liveStreamState.dataScope!.value
            : undefined,
        data_table_name: Number.isNaN(value)
            ? liveStreamState.dataScope!.label
            : undefined,

        interval: liveStreamState.liveStreamInterval.value,
        weekday:
            liveStreamState.liveStreamInterval.value === "week"
                ? liveStreamWeekDayOption.value
                : undefined,
        time: timeString,
        permissions: liveStreamState.permissions
            .filter(
                (permission): permission is GroupExtendedPermission =>
                    permission != null
            )
            .map((permission) => ({
                group_id: permission.group_id,
                permission_type: permission.permission_type,
            })),
    };

    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/add_canvas_live_stream", json, {})
        .then((response) => {
            if (response.data.success === false) {
                return Promise.reject(response.data.error_msg);
            } else {
                return Promise.resolve(true);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export function turnOffLiveStreamingApi(canvasId: number): Promise<boolean> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/delete_canvas_live_stream", {
            canvas_id: canvasId,
        })
        .then((response) => {
            if (response.data.success === false) {
                return Promise.reject(response.data.error_msg);
            } else {
                return Promise.resolve(true);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}
