import { formatDate } from "common/utilities/FormatDate";

export interface KPIStream {
    contents: any; // TODO Replace with a more specific type
    id: number;
    user_data?: { // TODO Check if ? is necessary here
        first_name: string,
        last_name: string,
        icon_url: string,
        is_admin: boolean,
        is_owner: boolean
    };
    creation_time: string;
    update_time: string;
    raw_timestamp: number;
    raw_update_timestamp: number;
}

// KPIStream as it is sent by the server
export interface KPIStreamResponse {
    contents: string; // contents is encoded as string
    id: number;
    user_data: {
        first_name: string,
        last_name: string,
        icon_url: string,
        is_admin: boolean,
        is_owner: boolean
    };
    creation_time: number; // timestamp
    update_time: number; // timestamp
}

export function toKPIStream(card: KPIStreamResponse): KPIStream {
    return {
        contents: JSON.parse(card.contents),
        id: card.id,
        user_data: card.user_data,
        creation_time: formatDate(
            card.creation_time,
            "created"
        ),
        update_time: formatDate(card.update_time, "updated"),
        raw_timestamp: card.creation_time,
        raw_update_timestamp: card.update_time
    }
}
