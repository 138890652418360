import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { MapFinding } from "common/Finding";
import remoteModuleId from "common/remoteModuleId";
import * as d3 from "d3";

const aggregationFunctions: {
    [key: string]: (
        group: MapFinding["content"]["choroplethData"]
    ) => number | undefined;
} = {
    mean: (group) => d3.mean(group ?? [], (d) => d.metric),
    sum: (group) => d3.sum(group ?? [], (d) => d.metric),
    count: (group) => group?.length ?? 0, // All charts threat count_distinct as count
};

class Api {
    static getDefaultConfig(journeyName: string): MapFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            isAdditional: false,
        };
    }

    static getPreviewFinding(journeyName: string): MapFinding {
        let item: MapFinding = {
            type: "maps_choropleth",
            content: {
                data: {},
                heatMapData: [],
                choroplethData: [
                    {
                        country: "Netherlands",
                        metric: 1,
                    },
                    {
                        country: "France",
                        metric: 5,
                    },
                    {
                        country: "Brazil",
                        metric: 10,
                    },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
            additionalMapsFindings: [],
        };
        return item;
    }

    static getData(
        finding: MapFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<MapFinding> {
        if (
            finding.config.dataScope?.value == null ||
            finding.config.choroplethCountryVariableIndex == null
        ) {
            return Promise.resolve(finding);
        }

        let { dataScope, selectedTable, conditions } = finding.config ?? {};
        conditions = filterNonEmptyConditions(conditions ?? []);

        let variableIndices = new Set<number>();
        variableIndices.add(finding.config.choroplethCountryVariableIndex);
        if (finding.config.choroplethMetricVariableIndex != null) {
            variableIndices.add(finding.config.choroplethMetricVariableIndex);
        }

        if (finding.config.tooltipVariables) {
            for (let option of finding.config.tooltipVariables) {
                if (option) {
                    variableIndices.add(option.variable.value);
                }
            }
        }

        const { filterAdditionalMapVariable } = finding.config;
        if (filterAdditionalMapVariable) {
            variableIndices.add(filterAdditionalMapVariable.value);
        }

        if (finding.config.timeVariableIndex != null) {
            variableIndices.add(finding.config.timeVariableIndex);
        }

        let requestJson = {
            variable_indices: Array.from(variableIndices.values()),
            table: selectedTable?.value ?? [],
            condition_id: selectedTable?.condition_id,
            conditions: conditionsToJson(conditions),
            data_table_idx: dataScope == null ? 0 : dataScope.value,
            module_id: moduleId ?? remoteModuleId,
            use_indices_in_current_levels: true,
        };

        return axios
            .post<{
                success: boolean;
                error_msg: string;
                current_levels: {
                    [key: string]: (number | string | null)[];
                };
                row_id: number[];
                count?: number;
            }>("/api/e/get_raw_data", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newData: MapFinding["content"]["choroplethData"] = [];
                    let newFinding: MapFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: response.data.current_levels,
                            heatMapData: [] as Array<[number, number, number]>,
                            choroplethData: newData,
                        },
                    };

                    let country =
                        finding.config.choroplethCountryVariableIndex != null
                            ? (response.data.current_levels[
                                  finding.config.choroplethCountryVariableIndex
                              ] as (string | null)[])
                            : [];

                    let metric =
                        finding.config.choroplethMetricVariableIndex != null
                            ? (response.data.current_levels[
                                  finding.config.choroplethMetricVariableIndex
                              ] as (number | null)[])
                            : [];

                    let time =
                        finding.config.timeVariableIndex != null
                            ? (response.data.current_levels[
                                  finding.config.timeVariableIndex
                              ] as (number | string | null)[])
                            : [];

                    for (let i in country) {
                        if (country[i] != null && metric[i] != null) {
                            newData.push({
                                country: country[i],
                                metric: metric[i],
                                time: time[i],
                            });
                        }
                    }
                    if (
                        finding.config.timeVariableIndex != null &&
                        finding.config.operationVariable != null
                    ) {
                        return Promise.reject(
                            "You can't enable time animation while data aggregation is enabled"
                        );
                    }

                    newData = d3
                        .flatRollup(
                            newData,
                            aggregationFunctions[
                                finding.config.operationVariable ?? "mean"
                            ],
                            (d) => d.country
                        )
                        .map((entry) => ({
                            country: entry[0],
                            metric: entry[1],
                            time: undefined,
                        }));
                    newFinding.content.choroplethData = newData;

                    if (finding.config.timeVariableIndex != null) {
                        newFinding.content.time = Array.from(
                            newData
                                .map((d) => d.time)
                                .filter((t): t is string | number => t != null)
                        ).sort((value1, value2) =>
                            typeof value1 === "number" &&
                            typeof value2 === "number"
                                ? value1 - value2
                                : String(value1).localeCompare(String(value2))
                        );
                    } else {
                        newFinding.content.time = undefined;
                    }
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
