import React, { useState } from "react";
import Switch from "react-switch";
import Accordion from "react-bootstrap/Accordion";
import cx from "classnames";

import { MapColorOptions, MapElement } from "common/Canvas";
import MarkerConfig from "./MarkerConfig";
import TooltipConfig from "./TooltipConfig";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import styles from "./StylingTab.module.css";

enum AccordionMenu {
    None = 0,
    Marker = 1,
    Tooltip = 2,
}

interface Props {
  mapElement: MapElement;
  mapElementId: string;
  onChange: (changes: Partial<MapElement>) => void;
  onTrackNewPerformance: (element: string) => void;
  currentZoomLevel?: number;
  currentModuleId?: number;
  data: { [key: string]: Array<number | string | null> } | null;
}

function StylingTab({ mapElement, mapElementId, onChange, onTrackNewPerformance,
    currentZoomLevel, currentModuleId, data }: Props) {
    const [openedAccordionMenu, setOpenedAccordionMenu] = useState(
        AccordionMenu.None
    );

    return (
        <div>
            <div className={styles.stylesMenu}>
                <div className={styles.stylesMenuOption}>
                    <span className={styles.switchLabel}>
                        Lock Map Area
                    </span>
                    <Switch
                        onChange={(checked) => {
                            let changes: MapColorOptions;

                            if (mapElement.colorOptions) {
                                changes = { ...mapElement.colorOptions };
                            } else {
                                changes = {
                                    borderShadow: false,
                                    fillColor: "",
                                    borderColor: "",
                                };
                            }

                            changes.lockMap = checked;

                            onChange({ colorOptions: changes });
                        }}
                        checked={mapElement.colorOptions?.lockMap ?? false}
                        width={26}
                        height={13}
                        offColor="#C4C4C4"
                        onColor="#3B82C9"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#7B7B7B"
                        onHandleColor="#3B82C9"
                        className={cx({ [styles.switchOn]: mapElement.colorOptions?.lockMap })}
                    />
                </div>
                <div className={styles.stylesMenuOption}>
                    <span className={styles.switchLabel}>
                        Prevent zooming above current level
                    </span>
                    <Switch
                        onChange={(checked) => {
                            let changes: MapColorOptions;

                            if (mapElement.colorOptions) {
                                changes = { ...mapElement.colorOptions };
                            } else {
                                changes = {
                                    borderShadow: false,
                                    fillColor: "",
                                    borderColor: "",
                                };
                            }

                            changes.minZoomLevel = checked ? currentZoomLevel : null;

                            onChange({ colorOptions: changes });
                        }}
                        disabled={currentZoomLevel == null}
                        checked={!!mapElement.colorOptions?.minZoomLevel}
                        width={26}
                        height={13}
                        offColor="#C4C4C4"
                        onColor="#3B82C9"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#7B7B7B"
                        onHandleColor="#3B82C9"
                        className={cx({ [styles.switchOn]: mapElement.colorOptions?.minZoomLevel })}
                    />
                </div>
                <div className={styles.stylesMenuOption}>
                    <span className={styles.switchLabel}>
                        Grayscale
                    </span>
                    <Switch
                        onChange={(checked) => {
                            let changes: MapColorOptions;

                            if (mapElement.colorOptions) {
                                changes = { ...mapElement.colorOptions };
                            } else {
                                changes = {
                                    borderShadow: false,
                                    fillColor: "",
                                    borderColor: "",
                                };
                            }

                            changes.grayscale = checked;

                            onChange({ colorOptions: changes });
                        }}
                        checked={mapElement.colorOptions?.grayscale ?? false}
                        width={26}
                        height={13}
                        offColor="#C4C4C4"
                        onColor="#3B82C9"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#7B7B7B"
                        onHandleColor="#3B82C9"
                        className={cx({ [styles.switchOn]: mapElement.colorOptions?.grayscale })}
                    />
                </div>
            </div>
            <div>
                <Accordion
                    activeKey={String(openedAccordionMenu)}
                    onSelect={(event: any) => {
                        setOpenedAccordionMenu(Number(event));
                    }}
                >
                    <Accordion.Toggle
                        eventKey={String(AccordionMenu.Marker)}
                        className={styles.accordionMenu}
                    >
                        Marker
                        <ChevronIcon
                            className={cx(styles.accordionMenuIcon, {
                                [styles.chevronOpen]:
                                    openedAccordionMenu ===
                                    AccordionMenu.Marker,
                            })}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(AccordionMenu.Marker)}
                    >
                        <MarkerConfig
                            mapElement={mapElement}
                            onChange={onChange}
                            currentModuleId={currentModuleId}
                        />
                    </Accordion.Collapse>
                    <Accordion.Toggle
                        eventKey={String(AccordionMenu.Tooltip)}
                        className={styles.accordionMenu}
                    >
                        Tooltip
                        <ChevronIcon
                            className={cx(styles.accordionMenuIcon, {
                                [styles.chevronOpen]:
                                    openedAccordionMenu ===
                                    AccordionMenu.Tooltip,
                            })}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(AccordionMenu.Tooltip)}
                    >
                        <TooltipConfig
                            mapElement={mapElement}
                            mapElementId={mapElementId}
                            onChange={onChange}
                            onTrackNewPerformance={onTrackNewPerformance}
                            data={data}
                        />
                    </Accordion.Collapse>
                </Accordion>
            </div>
        </div>
    );
}

export default StylingTab;
