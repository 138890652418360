import React, { useState } from "react";
import {
    Cell,
    ErrorBar,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    Label,
    LabelList,
    ComposedChart,
    Area,
} from "recharts";
import CustomizedAxisTick from "../CustomizedAxisTick";
import XAxisItem from "./XAxisItem";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import { getValueFillColor, StatusBarExpression } from "common/Canvas";
// @ts-ignore
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import OutsideAlerter from "common/OutsideAlerter";
import Portal from "common/Portal";
import ColorPicker from "./ChartColorPicker";
import YAxisItem from "./YAxisItem";
import {
    BarChartTheme,
    getGridColorByTheme,
    getDefaultColorsByTheme,
    getOptionsByTheme,
} from "../BarChartTheme";
import _ from "lodash";
import { getValue, TicksAndLabels } from "common/Finding";
import { TooltipStyles } from "../TooltipStyles";
import { calculateChartColorPickerPosition } from "./utils";

interface Props {
    groupNames?: string[];
    showGrid?: boolean; // Default: true
    truncValues?: boolean; // Default: false
    manualTicks?: boolean; // Default: false
    showXAxisName: boolean;
    showYAxisName: boolean;
    yAxisName?: string;
    currentEditVariableIndex?: number;
    editable?: boolean;
    statusExpressions?: StatusBarExpression[];
    barIndices?: number[];
    linesCount?: number;
    minYRange?: number;
    maxYRange?: number;
    axesColor?: string;
    errorBarColor?: string;
    showError?: boolean;
    dotRadius?: number;
    lineColor?: string;
    nameColorMapping?: { [key: string]: string };
    labelMapping?: { [key: string]: string };
    data: Array<
        { name: string } & { [key: string]: number | string | number[] }
    >;
    horizontalLines?: ({
        name: string;
        value: number;
        variableIndex?: number;
        originalName?: string;
    } | null)[];
    barColors: string[];
    colorByGroup?: boolean;
    title?: string;
    maxChartValue?: number;
    watermark?: string;
    barSize?: number;
    barGap?: number;
    barCategoryGap?: number;
    stacked?: boolean;
    showAsDots?: boolean;
    dashLine?: boolean;
    chartTheme?: BarChartTheme;
    axesLinesColor?: string;
    axesNamesColor?: string;
    ticksColor?: string;
    ticksSize?: number;
    ticksAndLabels?: TicksAndLabels;
    baseBackgroundColor?: string;
    tooltipColor?: string;
    tooltipFontSize?: number;
    onLinkVariable: (
        index: number,
        variableName: string,
        variableIndex: number
    ) => void;
    onRenameVariable: (index: number, name: string) => void;
    onConfigChange: (changes: any) => void;
}

interface ColorByNameTooltipProps {
    nameColorMapping?: { [key: string]: string };
    payload:
        | {
              payload: { name: string; variableIndex?: number };
              value: number;
              color: string;
          }[]
        | null;
}

const ColorByNameTooltip = (props: any & ColorByNameTooltipProps) => {
    if (props.active) {
        if (props.payload && props.payload.length > 0) {
            let newPayload: {
                value: number;
                color: string;
                payload: {
                    name: string;
                    index: number;
                    variableIndex?: number;
                };
            }[] = Array.from(props.payload);
            for (let i = 0; i < newPayload.length; ++i) {
                let expressionColor =
                    props.nameColorMapping?.[
                        `${newPayload[i].payload.index}_${i}`
                    ] ?? props.nameColorMapping?.[newPayload[i].payload.index];
                if (props.statusExpressions != null)
                    expressionColor =
                        getValueFillColor(
                            newPayload[i].value,
                            newPayload[i].payload.index,
                            props.statusExpressions
                        ) ?? expressionColor;
                newPayload[i].color =
                    expressionColor ??
                    (newPayload[i].payload.variableIndex == null
                        ? "#D1D1D1"
                        : props.barColors[
                              newPayload[i].payload.index %
                                  props.barColors.length
                          ]);
            }
            return <DefaultTooltipContent {...props} payload={newPayload} />;
        }
    }
    return <DefaultTooltipContent {...props} />;
};

function CustomBarChart(props: Props) {
    let [showZeroLine, setShowZeroLine] = useState<boolean | null>(null);
    let barCategoryGap = props.barCategoryGap ?? 100;
    let barGap = props.barGap ?? 0;
    let barSize = props.barSize ?? 80;
    let groupNames: string[] = props.groupNames || ["value"];
    barSize =
        barSize / (props.stacked && !props.showAsDots ? 1 : groupNames.length);
    let barCountInGroup = props.groupNames?.length ?? 1;
    var barChartWidth: number =
        props.data.length > 0
            ? 100 +
              ((barSize + barGap) * barCountInGroup + barCategoryGap) *
                  props.data.length
            : 800;

    var xAxisFontSize: number =
        props.ticksAndLabels?.x?.size ?? props.ticksSize ??
        parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

    var yAxisFontSize: number =
        props.ticksAndLabels?.y?.size ?? props.ticksSize ??
        parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

    var labelsFontSize: number =
        props.ticksAndLabels?.labels?.size ?? props.ticksSize ??
        parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

    var titleSize: number = 12;
    let defaultColors = getDefaultColorsByTheme(props.chartTheme);
    let themeOptions = getOptionsByTheme(props.chartTheme);
    let gridFillColor = getGridColorByTheme(
        props.chartTheme,
        props.baseBackgroundColor
    );
    let [showBarColorMenu, setShowBarColorMenu] = useState<{
        name: string;
        dataIndex: number;
        index: number;
        variableIndex?: number;
        x: number;
        y: number;
    } | null>(null);
    let [editBarName, setEditBarName] = useState<{
        index: number;
        boundingRect: {
            x: number;
            y: number;
            width: number;
            height: number;
        };
    } | null>(null);
    let [editYAxisName, setEditYAxisName] = useState<{
        boundingRect: {
            x: number;
            y: number;
            width: number;
            height: number;
        };
    } | null>(null);
    let data: any = props.data.map((item, index) => ({
        ..._.cloneDeep(item),
        value: undefined,
        index: index,
    }));

    let dataMin = Infinity;
    let dataMax = -Infinity;
    for (let i = 0; i < data.length; ++i) {
        for (let j = 0; j < groupNames.length; ++j) {
            data[i][groupNames[j]] = getValue(
                props.data[i] as any as { value: number | number[] },
                j
            );
            dataMin = Math.min(dataMin, data[i][groupNames[j]]);
            dataMax = Math.max(dataMax, data[i][groupNames[j]]);
        }
    }
    // If dataMin is infinite, then dataMax is also infinite
    if (!isFinite(dataMin)) {
        dataMin = 0;
        dataMax = 1;
    }

    let minReferenceLine: number = Infinity;
    let maxReferenceLine: number = -Infinity;
    if (props.horizontalLines != null) {
        for (let item of props.horizontalLines) {
            if (item != null) {
                minReferenceLine = Math.min(minReferenceLine, item.value);
                maxReferenceLine = Math.max(maxReferenceLine, item.value);
            }
        }
    }

    let tickProps: {
        tickCount?: number;
        ticks?: number[];
    } = {};

    if (props.manualTicks && props.linesCount != null) {
        let min = props.minYRange ?? Math.min(dataMin, minReferenceLine);
        let max = props.maxYRange ?? Math.max(dataMax, maxReferenceLine);
        let step = (max - min) / (props.linesCount - 1);
        let ticks: number[] = [];
        for (let i = 0; i < props.linesCount; ++i) {
            let value = min + step * i;
            if (props.truncValues) value = Math.trunc(value);
            if (ticks.length === 0 || value !== ticks[ticks.length - 1])
                ticks.push(value);
        }
        tickProps.ticks = ticks;
    } else {
        tickProps.tickCount = props.linesCount;
    }

    /* 100 is the width of the list, 8 is the margin */
    let xAxisWidth = (barChartWidth - 100) / data.length - 8;
    return (
        <div style={{ height: "100%", paddingRight: 75, paddingBottom: 5 }}>
            <span
                style={{
                    fontFamily: "Arial",
                    fontSize: titleSize,
                    color: mainStyle.getPropertyValue(
                        "--exploration-secondary-text-color"
                    ),
                    display: "block",
                    textAlign: "center",
                }}
            >
                {props.title}
            </span>
            <ResponsiveContainer
                width={barChartWidth}
                height={mobileBreakpoint() ? 250 : undefined}
            >
                {!props.showAsDots ? (
                    <BarChart
                        ref={(ref) => {
                            let yAxisMapItem = ref?.state?.yAxisMap?.[0];
                            if (yAxisMapItem != null) {
                                let niceTicks: number[] =
                                    (yAxisMapItem as any)?.niceTicks ?? [];
                                let newShowZeroLine = !niceTicks.includes(0);
                                if (newShowZeroLine !== showZeroLine)
                                    setShowZeroLine(newShowZeroLine);
                            }
                        }}
                        barSize={barSize}
                        barGap={barGap}
                        barCategoryGap={barCategoryGap}
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 20,
                            bottom: 10,
                        }}
                    >
                        {props.watermark != null && (
                            <text
                                x="50%"
                                y="30%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                )}
                                fontSize={
                                    String(Object.keys(props.data).length - 1) +
                                    "rem"
                                }
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {props.watermark}
                            </text>
                        )}
                        {(props.showGrid ?? true) && (
                            <CartesianGrid
                                stroke={
                                    props.axesLinesColor ??
                                    defaultColors.gridColor
                                }
                                strokeDasharray={
                                    themeOptions.dashGrid ? "3 3" : undefined
                                }
                                strokeWidth={1}
                                fill={gridFillColor}
                            />
                        )}

                        {groupNames.map((barKey: string, index: number) => {
                            return (
                                <Bar
                                    isAnimationActive={false}
                                    key={index}
                                    dataKey={barKey}
                                    stackId={props.stacked ? "a" : undefined}
                                >
                                    {data.map(
                                        (entry: any, dataIndex: number) => {
                                            let expressionColor = null;
                                            if (
                                                props.nameColorMapping != null
                                            ) {
                                                expressionColor =
                                                    props.nameColorMapping[
                                                        `${dataIndex}_${index}`
                                                    ] ??
                                                    props.nameColorMapping[
                                                        dataIndex
                                                    ];
                                            }
                                            if (
                                                props.statusExpressions != null
                                            ) {
                                                let value = entry[barKey];
                                                expressionColor =
                                                    getValueFillColor(
                                                        value,
                                                        dataIndex,
                                                        props.statusExpressions
                                                    ) ?? expressionColor;
                                            }
                                            let fillColor =
                                                expressionColor ??
                                                (entry.variableIndex == null
                                                    ? "#D1D1D1"
                                                    : expressionColor ??
                                                      props.barColors[
                                                          (props.colorByGroup
                                                              ? index
                                                              : dataIndex) %
                                                              props.barColors
                                                                  .length
                                                      ]);
                                            return (
                                                <Cell
                                                    key={`cell-${dataIndex}`}
                                                    fill={fillColor}
                                                    stroke={
                                                        dataIndex ===
                                                        props.currentEditVariableIndex
                                                            ? "#8DB8E3"
                                                            : "#D1D1D1"
                                                    }
                                                    onClick={(evt) => {
                                                        const { y } = calculateChartColorPickerPosition(evt);
                                                        if (props.editable)
                                                            setShowBarColorMenu(
                                                                {
                                                                    name: entry.name,
                                                                    dataIndex:
                                                                        dataIndex,
                                                                    index: index,
                                                                    variableIndex:
                                                                        entry.variableIndex,
                                                                    x: evt.clientX,
                                                                    y,
                                                                }
                                                            );
                                                    }}
                                                />
                                            );
                                        }
                                    )}
                                    {!props.stacked && props.showError && (
                                        <ErrorBar
                                            dataKey={"%error_".concat(barKey)}
                                            width={4}
                                            strokeWidth={2}
                                            stroke={
                                                props.errorBarColor ?? "black"
                                            }
                                        />
                                    )}
                                    <LabelList
                                        dataKey={barKey}
                                        position="top"
                                        fill={
                                            props.ticksColor ??
                                            mainStyle.getPropertyValue(
                                                "--graphs-axes-text-color"
                                            )
                                        }
                                        stroke="none"
                                        fontSize={labelsFontSize}
                                        formatter={(value: string | number) => {
                                            if (
                                                typeof value === "string" ||
                                                typeof value === "number"
                                            ) {
                                                let formattedValue =
                                                    formatValue(value, false);
                                                return formattedValue[0].concat(
                                                    formattedValue[1]
                                                );
                                            }
                                        }}
                                    />
                                </Bar>
                            );
                        })}
                        {props.horizontalLines?.map(
                            (item) =>
                                item != null && (
                                    <ReferenceLine
                                        y={item.value}
                                        label={item.name}
                                        stroke="red"
                                        strokeDasharray="3 3"
                                    />
                                )
                        )}
                        <XAxis
                            axisLine={themeOptions.showAxesLines}
                            tickLine={themeOptions.showTickLines}
                            interval={0}
                            tick={
                                props.showXAxisName ? (
                                    <XAxisItem
                                        onEdit={(
                                            index: number,
                                            boundingRect: {
                                                x: number;
                                                y: number;
                                                width: number;
                                                height: number;
                                            }
                                        ) => {
                                            setEditBarName({
                                                index: index,
                                                boundingRect: boundingRect,
                                            });
                                        }}
                                        editable={props.editable}
                                        currentEditVariableIndex={
                                            props.currentEditVariableIndex
                                        }
                                        fontColor={
                                            props.axesNamesColor ?? "#333333"
                                        }
                                        onDrop={props.onLinkVariable}
                                        width={xAxisWidth}
                                    />
                                ) : (
                                    <g />
                                )
                            }
                            height={50}
                            dataKey="name"
                            strokeWidth={4}
                            stroke={
                                props.axesLinesColor ??
                                defaultColors.axesLinesColor
                            }
                        />
                        <YAxis
                            type="number"
                            label={
                                props.showYAxisName ? (
                                    <YAxisItem
                                        fontColor={
                                            props.axesNamesColor ?? "#333333"
                                        }
                                        editable={props.editable}
                                        name={props.yAxisName}
                                        onEdit={(boundingRect) => {
                                            setEditYAxisName({
                                                boundingRect: boundingRect,
                                            });
                                        }}
                                    />
                                ) : undefined
                            }
                            axisLine={themeOptions.showAxesLines}
                            tickLine={themeOptions.showTickLines}
                            {...tickProps}
                            interval={0}
                            domain={[
                                props.minYRange ??
                                    ((dataMin: number) => {
                                        if (Number.isFinite(dataMin)) {
                                            dataMin = Math.min(
                                                dataMin,
                                                minReferenceLine
                                            );
                                            return Math.min(
                                                dataMin -
                                                    Math.abs(dataMin) * 0.1,
                                                0
                                            );
                                        }
                                        return 0;
                                    }),
                                props.maxYRange ??
                                    (props.maxChartValue
                                        ? Math.max(
                                              0,
                                              props.maxChartValue +
                                                  Math.abs(
                                                      props.maxChartValue
                                                  ) *
                                                      0.1
                                          )
                                        : (dataMax: number) => {
                                              if (Number.isFinite(dataMax)) {
                                                  dataMax = Math.max(
                                                      dataMax,
                                                      maxReferenceLine
                                                  );
                                                  return Math.max(
                                                      dataMax +
                                                          Math.abs(dataMax) *
                                                              0.1,
                                                      0
                                                  );
                                              }
                                              return 0;
                                          }),
                            ]}
                            allowDataOverflow={
                                props.minYRange != null ||
                                props.maxYRange != null
                            }
                            tick={
                                <CustomizedAxisTick
                                    axesColor={props.ticksColor}
                                    formatValues={true}
                                    truncValues={props.truncValues ?? false}
                                    fontSize={xAxisFontSize}
                                    dx={0}
                                    dy={0}
                                />
                            }
                            strokeWidth={4}
                            stroke={
                                props.axesLinesColor ??
                                defaultColors.axesLinesColor
                            }
                        />
                        {showZeroLine && (
                            <ReferenceLine
                                y={0}
                                stroke={
                                    props.axesLinesColor ??
                                    defaultColors.axesLinesColor
                                }
                                strokeWidth={2}
                                label={
                                    <Label
                                        dy={-5}
                                        offset={8}
                                        value={"0.00"}
                                        position="left"
                                        fontSize={labelsFontSize}
                                        fill={
                                            props.ticksColor ??
                                            mainStyle.getPropertyValue(
                                                "--graphs-axes-text-color"
                                            )
                                        }
                                    />
                                }
                            />
                        )}
                        <Tooltip
                            labelFormatter={(name) =>
                                props.labelMapping?.[name] ?? name
                            }
                            content={
                                <ColorByNameTooltip
                                    barColors={props.barColors}
                                    statusExpressions={props.statusExpressions}
                                    nameColorMapping={props.nameColorMapping}
                                />
                            }
                            formatter={(value: any, name: any, props: any) => {
                                if (
                                    typeof value === "string" ||
                                    typeof value === "number"
                                ) {
                                    let formattedValue = formatValue(
                                        value,
                                        false
                                    );
                                    return [
                                        formattedValue[0].concat(
                                            formattedValue[1]
                                        ),
                                        name,
                                    ];
                                }
                            }}
                            cursor={false}
                            {...TooltipStyles(
                                props.tooltipColor,
                                props.tooltipFontSize
                            )}
                        />
                    </BarChart>
                ) : (
                    <ComposedChart
                        ref={(ref) => {
                            let yAxisMapItem = ref?.state?.yAxisMap?.[0];
                            if (yAxisMapItem != null) {
                                let niceTicks: number[] =
                                    (yAxisMapItem as any)?.niceTicks ?? [];
                                let newShowZeroLine = !niceTicks.includes(0);
                                if (newShowZeroLine !== showZeroLine)
                                    setShowZeroLine(newShowZeroLine);
                            }
                        }}
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 20,
                            bottom: 10,
                        }}
                    >
                        {props.watermark != null && (
                            <text
                                x="50%"
                                y="30%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                )}
                                fontSize={
                                    String(Object.keys(props.data).length - 1) +
                                    "rem"
                                }
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {props.watermark}
                            </text>
                        )}
                        {(props.showGrid ?? true) && (
                            <CartesianGrid
                                stroke={
                                    props.axesLinesColor ??
                                    defaultColors.gridColor
                                }
                                strokeDasharray={
                                    themeOptions.dashGrid ? "3 3" : undefined
                                }
                                strokeWidth={1}
                                fill={gridFillColor}
                            />
                        )}

                        {groupNames.map((barKey: string, index: number) => {
                            return (
                                <Area
                                    isAnimationActive={false}
                                    key={index}
                                    type="linear"
                                    dataKey={barKey}
                                    dot={(args: any) => {
                                        let entry = args.payload;
                                        let dataIndex = args.index;
                                        let expressionColor = null;
                                        if (props.nameColorMapping != null) {
                                            expressionColor =
                                                props.nameColorMapping[
                                                    `${dataIndex}_${index}`
                                                ] ??
                                                props.nameColorMapping[
                                                    dataIndex
                                                ];
                                        }
                                        if (props.statusExpressions != null) {
                                            let value = entry[barKey];
                                            expressionColor =
                                                getValueFillColor(
                                                    value,
                                                    dataIndex,
                                                    props.statusExpressions
                                                ) ?? expressionColor;
                                        }
                                        let fillColor =
                                            expressionColor ??
                                            (entry.variableIndex == null
                                                ? "#D1D1D1"
                                                : props.barColors[
                                                      dataIndex %
                                                          props.barColors.length
                                                  ]);
                                        return (
                                            <circle
                                                key={
                                                    index * data.length +
                                                    dataIndex
                                                }
                                                cx={args.cx}
                                                cy={args.cy}
                                                r={props.dotRadius ?? 5}
                                                fill={fillColor}
                                            />
                                        );
                                    }}
                                    stroke={
                                        props.lineColor ??
                                        (data[0].variableIndex == null
                                            ? "#D1D1D1"
                                            : props.barColors[0])
                                    }
                                    strokeDasharray={
                                        props.dashLine ? "5 5" : undefined
                                    }
                                    strokeWidth={3}
                                    fillOpacity={1}
                                    fill={`url(#color_variable)`}
                                />
                            );
                        })}
                        {props.horizontalLines?.map(
                            (item) =>
                                item != null && (
                                    <ReferenceLine
                                        y={item.value}
                                        label={item.name}
                                        stroke="red"
                                        strokeDasharray="3 3"
                                    />
                                )
                        )}
                        <XAxis
                            padding={{
                                left: (xAxisWidth + 4) / 2,
                                right: (xAxisWidth + 4) / 2,
                            }}
                            axisLine={themeOptions.showAxesLines}
                            tickLine={themeOptions.showTickLines}
                            interval={0}
                            tick={
                                props.showXAxisName ? (
                                    <XAxisItem
                                        onEdit={(
                                            index: number,
                                            boundingRect: {
                                                x: number;
                                                y: number;
                                                width: number;
                                                height: number;
                                            }
                                        ) => {
                                            setEditBarName({
                                                index: index,
                                                boundingRect: boundingRect,
                                            });
                                        }}
                                        editable={props.editable}
                                        currentEditVariableIndex={
                                            props.currentEditVariableIndex
                                        }
                                        fontColor={
                                            props.axesNamesColor ?? "#333333"
                                        }
                                        onDrop={props.onLinkVariable}
                                        width={xAxisWidth}
                                    />
                                ) : (
                                    <g />
                                )
                            }
                            height={50}
                            dataKey="name"
                            strokeWidth={4}
                            stroke={
                                props.axesLinesColor ??
                                defaultColors.axesLinesColor
                            }
                        />
                        <YAxis
                            type="number"
                            label={
                                props.showYAxisName ? (
                                    <YAxisItem
                                        fontColor={
                                            props.axesNamesColor ?? "#333333"
                                        }
                                        editable={props.editable}
                                        name={props.yAxisName}
                                        onEdit={(boundingRect) => {
                                            setEditYAxisName({
                                                boundingRect: boundingRect,
                                            });
                                        }}
                                    />
                                ) : undefined
                            }
                            axisLine={themeOptions.showAxesLines}
                            tickLine={themeOptions.showTickLines}
                            {...tickProps}
                            interval={0}
                            domain={[
                                props.minYRange ??
                                    ((dataMin: number) => {
                                        if (Number.isFinite(dataMin))
                                            return Math.min(
                                                dataMin -
                                                    Math.abs(dataMin) * 0.1,
                                                0
                                            );
                                        return 0;
                                    }),
                                props.maxYRange ??
                                    (props.maxChartValue
                                        ? Math.max(
                                              0,
                                              props.maxChartValue +
                                                  Math.abs(
                                                      props.maxChartValue
                                                  ) *
                                                      0.1
                                          )
                                        : (dataMax: number) => {
                                              if (Number.isFinite(dataMax))
                                                  return Math.max(
                                                      dataMax +
                                                          Math.abs(dataMax) *
                                                              0.1,
                                                      0
                                                  );
                                              return 0;
                                          }),
                            ]}
                            allowDataOverflow={
                                props.minYRange != null ||
                                props.maxYRange != null
                            }
                            tick={
                                <CustomizedAxisTick
                                    axesColor={props.ticksColor}
                                    formatValues={true}
                                    truncValues={props.truncValues ?? false}
                                    fontSize={yAxisFontSize}
                                    dx={0}
                                    dy={0}
                                />
                            }
                            strokeWidth={4}
                            stroke={
                                props.axesLinesColor ??
                                defaultColors.axesLinesColor
                            }
                        />
                        {showZeroLine && (
                            <ReferenceLine
                                y={0}
                                stroke={
                                    props.axesLinesColor ??
                                    defaultColors.axesLinesColor
                                }
                                strokeWidth={2}
                                label={
                                    <Label
                                        dy={-5}
                                        offset={8}
                                        value={"0.00"}
                                        position="left"
                                        fontSize={yAxisFontSize}
                                        fill={
                                            props.ticksColor ??
                                            mainStyle.getPropertyValue(
                                                "--graphs-axes-text-color"
                                            )
                                        }
                                    />
                                }
                            />
                        )}
                        <Tooltip
                            labelFormatter={(name) =>
                                props.labelMapping?.[name] ?? name
                            }
                            content={
                                <ColorByNameTooltip
                                    barColors={props.barColors}
                                    statusExpressions={props.statusExpressions}
                                    nameColorMapping={props.nameColorMapping}
                                />
                            }
                            formatter={(value: any, name: any, props: any) => {
                                if (
                                    typeof value === "string" ||
                                    typeof value === "number"
                                ) {
                                    let formattedValue = formatValue(
                                        value,
                                        false
                                    );
                                    return [
                                        formattedValue[0].concat(
                                            formattedValue[1]
                                        ),
                                        name,
                                    ];
                                }
                            }}
                            cursor={false}
                            {...TooltipStyles(props.tooltipColor)}
                        />
                    </ComposedChart>
                )}
            </ResponsiveContainer>
            {showBarColorMenu && (
                <Portal rootNode={document.body}>
                    <OutsideAlerter
                        onReject={() => {
                            setShowBarColorMenu(null);
                        }}
                    >
                        <div
                            style={{
                                zIndex: 100000000,
                                position: "absolute",
                                left: showBarColorMenu.x,
                                top: showBarColorMenu.y,
                            }}
                        >
                            <ColorPicker
                                enableAlpha={true}
                                width={"220px"}
                                color={
                                    props.nameColorMapping?.[
                                        `${showBarColorMenu!.dataIndex}_${
                                            showBarColorMenu!.index
                                        }`
                                    ] ??
                                    props.nameColorMapping?.[
                                        showBarColorMenu.dataIndex
                                    ] ??
                                    (showBarColorMenu.variableIndex == null
                                        ? "#D1D1D1"
                                        : props.barColors[
                                              showBarColorMenu.dataIndex %
                                                  props.barColors.length
                                          ])
                                }
                                onChange={(color) => {
                                    let nameColorMapping = {
                                        ...props.nameColorMapping,
                                    };
                                    nameColorMapping[
                                        `${showBarColorMenu!.dataIndex}_${
                                            showBarColorMenu!.index
                                        }`
                                    ] = color;
                                    props.onConfigChange({
                                        nameColorMapping: nameColorMapping,
                                    });
                                }}
                            />
                        </div>
                    </OutsideAlerter>
                </Portal>
            )}
            {editBarName && (
                <Portal rootNode={document.body}>
                    <input
                        style={{
                            zIndex: 1000,
                            position: "absolute",
                            fontFamily: "Roboto",
                            fontSize: "14",
                            fontWeight: 500,
                            textAlign: "center",
                            color: props.axesNamesColor ?? "#333333",
                            outline: "none",
                            border: "1px solid #8DB8E3",
                            left: editBarName.boundingRect.x,
                            top: editBarName.boundingRect.y,
                            width: editBarName.boundingRect.width,
                            height: editBarName.boundingRect.height,
                        }}
                        defaultValue={props.data[editBarName.index].name}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                            if (evt.key === "Enter") {
                                props.onRenameVariable(
                                    editBarName!.index,
                                    evt.currentTarget.value
                                );
                                setEditBarName(null);
                            }
                        }}
                        onBlur={(evt) => {
                            props.onRenameVariable(
                                editBarName!.index,
                                evt.currentTarget.value
                            );

                            setEditBarName(null);
                        }}
                    ></input>
                </Portal>
            )}
            {editYAxisName && (
                <Portal rootNode={document.body}>
                    <input
                        style={{
                            zIndex: 1000,
                            position: "absolute",
                            fontFamily: "Roboto",
                            fontSize: "14",
                            fontWeight: 500,
                            textAlign: "center",
                            color: props.axesNamesColor ?? "#333333",
                            outline: "none",
                            border: "1px solid #8DB8E3",
                            left: editYAxisName.boundingRect.x,
                            top: editYAxisName.boundingRect.y,
                            width: editYAxisName.boundingRect.width,
                            height: editYAxisName.boundingRect.height,
                        }}
                        defaultValue={props.yAxisName ?? "Y Axis"}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                            if (evt.key === "Enter") {
                                props.onConfigChange({
                                    yAxisName: evt.currentTarget.value,
                                });
                                setEditYAxisName(null);
                            }
                        }}
                        onBlur={(evt) => {
                            props.onConfigChange({
                                yAxisName: evt.currentTarget.value,
                            });

                            setEditYAxisName(null);
                        }}
                    ></input>
                </Portal>
            )}
        </div>
    );
}

export default CustomBarChart;
