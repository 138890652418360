import React, { Component } from "react";
import TextareaAutosize from "react-autosize-textarea";
import { getGroupNeighboursUsers } from "common/UserGroupsApi";
import {
	editFeedbackApi,
	deleteFeedbackApi,
	addFeedbackApi,
	getFeedbacksApi,
	sendMessageNotification,
} from "common/FeedbacksApi";
import { mainStyle } from "common/MainStyle";
import OutsideAlerter from "common/OutsideAlerter";
import Instrumentation from "common/Instrumentation";
import { CSSTransition } from "react-transition-group";
import { Dropdown } from "react-bootstrap";
import MentionComponent, { getUserNames } from "common/Mentions";
import { feedbackTypeToSection, feedBackMentionUrl } from "common/Feedback";
export default class FeedbackContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			feedbacks: [],
			newFeedback: "",
			editedFeedback: "",
			editedFeedbackId: undefined,
			neighboursUsers: [],
		};
		this.getData = this.getData.bind(this);
		this.addNewFeedback = this.addNewFeedback.bind(this);
		this.editFeedback = this.editFeedback.bind(this);
		this.buildDropdownMenu = this.buildDropdownMenu.bind(this);

		this.performance = null;
	}
	componentDidMount() {}
	getData() {
		getFeedbacksApi(this.props.cardId, this.props.type)
			.then((feedbacks) => {
				this.setState({ feedbacks: feedbacks }, () => {
					if (this.props.onGetFeedbacks) {
						this.props.onGetFeedbacks(this.props.cardId);
					}
				});
			})
			.catch((error) => {
				console.log(error);
			});
		getGroupNeighboursUsers()
			.then((users) => {
				this.setState({ neighboursUsers: users });
			})
			.catch((error) => {
				console.log(error);
			});
	}
	componentDidUpdate(prevProps) {
		if (this.performance != null) {
			let timeMs = new Date() - this.performance;
			this.performance = null;
			Instrumentation.addInteraction(
				feedbackTypeToSection[this.props.type],
				timeMs
			);
		}
		if (
			(this.props.showFeedbacks && !prevProps.showFeedbacks) ||
			(this.props.showFeedbacks &&
				!prevProps.needUpdate &&
				this.props.needUpdate) ||
			(this.props.showFeedbacks && this.props.cardId !== prevProps.cardId)
		) {
			this.getData();
		}
	}
	buildDropdownMenu(item) {
		if (!item.is_owner) return null;
		return (
			<Dropdown
				style={{
					backgroundColor: "transparent",
					alignSelf: "flex-end",
				}}
			>
				<Dropdown.Toggle
					style={{
						backgroundColor: "transparent",
						border: "0",
					}}
				>
					<svg
						width="12"
						height="10"
						style={{
							fill: mainStyle.getPropertyValue(
								"--content-primary-text-color"
							),
							marginLeft: "5px",
							marginTop: "5px",
						}}
						viewBox="0 0 12 10"
					>
						<circle cx="3" cy="2" r="1.5" />
						<circle cx="3" cy="5" r="1.5" />
						<circle cx="3" cy="8" r="1.5" />
					</svg>
				</Dropdown.Toggle>

				<Dropdown.Menu
					style={{
						backgroundColor: mainStyle.getPropertyValue(
							"--primary-content-color"
						),
						border: "0",
						filter: "drop-shadow(0 8px 8px black)",
						maxWidth: "20px",
					}}
				>
					<Dropdown.Item
						style={{
							display: "block",
							padding: "5px",
							textAlign: "center",
							color: mainStyle.getPropertyValue(
								"--content-primary-text-color"
							),
						}}
						href="#"
						onClick={(evt) => {
							evt.stopPropagation();
							this.setState({
								editedFeedback: item.feedback,
								editedFeedbackId: item.id,
							});
						}}
					>
						Edit
					</Dropdown.Item>
					<Dropdown.Item
						style={{
							display: "block",
							padding: "5px",
							textAlign: "center",
							color: mainStyle.getPropertyValue(
								"--content-primary-text-color"
							),
						}}
						href="#"
						onClick={(evt) => {
							evt.stopPropagation();
							this.performance = new Date();
							deleteFeedbackApi(item.id, this.props.type)
								.then(() => {
									let feedbacks = Array.from(
										this.state.feedbacks
									);
									feedbacks = feedbacks.filter(
										(otherItem) => otherItem.id !== item.id
									);
									this.setState({ feedbacks: feedbacks });
								})
								.catch((error) => {
									console.log(error);
								});
						}}
					>
						Delete
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	}
	editFeedback(item) {
		let editedFeedback = this.state.editedFeedback;
		if (editedFeedback) {
			editFeedbackApi(editedFeedback, item.id, this.props.type)
				.then((id) => {
					let feedbacks = Array.from(this.state.feedbacks);
					let index = feedbacks.findIndex((element, index, array) => {
						return element.id === item.id;
					});
					if (index < 0) return;
					feedbacks[index].feedback = editedFeedback;
					this.setState({
						feedbacks: feedbacks,
					});
				})
				.catch((error) => {
					console.log(error);
				});
			let users = getUserNames(
				editedFeedback,
				this.state.neighboursUsers
			);
			if (users.length > 0) {
				sendMessageNotification(
					this.props.cardId,
					feedBackMentionUrl[this.props.type],
					editedFeedback,
					users
				)
					.then(() => {})
					.catch((error) => {
						console.log(error);
					});
			}
		} else {
			deleteFeedbackApi(item.id, this.props.type)
				.then(() => {
					let feedbacks = Array.from(this.state.feedbacks);
					feedbacks = feedbacks.filter(
						(otherItem) => otherItem.id !== item.id
					);
					this.setState({
						feedbacks: feedbacks,
					});
				})
				.catch((error) => {
					console.log(error);
				});
		}
		this.setState({
			editedFeedback: "",
			editedFeedbackId: undefined,
		});
	}
	addNewFeedback() {
		let newFeedback = this.state.newFeedback;
		addFeedbackApi(newFeedback, this.props.cardId, this.props.type)
			.then((id) => {
				this.setState({ newFeedback: "" });
				this.getData();
			})
			.catch((error) => {
				console.log(error);
			});
		let users = getUserNames(newFeedback, this.state.neighboursUsers);
		if (users.length > 0) {
			sendMessageNotification(
				this.props.cardId,
				feedBackMentionUrl[this.props.type],
				newFeedback,
				users
			)
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		}
	}
	render() {
		return (
			<OutsideAlerter onReject={this.props.onClose}>
				<CSSTransition
					timeout={500}
					in={this.props.showFeedbacks}
					classNames={"scaled-animated-column"}
					unmountOnExit
				>
					<div
						className="scaled-animated-column element"
						style={{
							overflow: "auto",
							backgroundColor: mainStyle.getPropertyValue(
								"--tertiary-content-color"
							),
							zIndex: 999999,
							position: "absolute",
							right: 0,
							top: 5,
							width: this.props.width ?? 300,
							height: "100%",
						}}
					>
						<div className="flex-simple-column">
							<span
								style={{
									marginTop: 20,
									color: mainStyle.getPropertyValue(
										"--content-primary-text-color"
									),
									fontFamily: "Roboto",
									fontSize: 30,
									fontWeight: 300,
									alignSelf: "center",
								}}
							>
								{"Comment"}
							</span>

							<MentionComponent
								value={this.state.newFeedback}
								onChange={(value) => {
									this.setState({
										newFeedback: value,
									});
								}}
								users={this.state.neighboursUsers}
							>
								<TextareaAutosize
									className="insights-text-area element"
									placeholder="Enter comment"
									value={this.state.newFeedback}
									style={{
										textAlign: "center",
										backgroundColor: mainStyle.getPropertyValue(
											"--tertiary-content-color"
										),
										fontSize: "20px",
										minHeight: 50,
										marginTop: 10,
										marginLeft: 10,
										marginRight: 10,
										marginBottom: 10,
									}}
									onChange={(evt) => {
										let newFeedback = evt.target.value;
										this.setState({
											newFeedback: newFeedback,
										});
									}}
									onKeyDown={(evt) => {
										if (
											evt.key === "Enter" &&
											!evt.shiftKey
										) {
											evt.preventDefault();
											if (this.state.newFeedback) {
												evt.target.blur();
												this.performance = new Date();
												this.addNewFeedback();
											}
										}
									}}
								/>
							</MentionComponent>
							{this.state.feedbacks.map((item, index) => {
								let edited =
									item.id === this.state.editedFeedbackId;
								return (
									<div
										key={index}
										className="flex-simple-column"
										style={{ padding: "10px" }}
									>
										{!edited ? (
											<div
												className="my-row"
												style={{ alignItems: "center" }}
											>
												<span
													style={{
														fontFamily: "Roboto",
														color: mainStyle.getPropertyValue(
															"--content-primary-text-color"
														),
														overflowWrap:
															"break-word",
														fontSize: 14,
													}}
												>
													{item.feedback}
												</span>
												<div style={{ flexGrow: 1 }} />
												{this.buildDropdownMenu(item)}
											</div>
										) : (
											<MentionComponent
												value={
													this.state.editedFeedback
												}
												onChange={(value) => {
													this.setState({
														editedFeedback: value,
													});
												}}
												users={
													this.state.neighboursUsers
												}
											>
												<TextareaAutosize
													autoFocus
													className="insights-text-area element"
													placeholder="Enter comment"
													value={
														this.state
															.editedFeedback
													}
													style={{
														textAlign: "center",
														backgroundColor: mainStyle.getPropertyValue(
															"--tertiary-content-color"
														),
														fontSize: "20px",
														minHeight: 50,
														marginTop: 10,
														marginLeft: 10,
														marginRight: 10,
														marginBottom: 10,
													}}
													onKeyDown={(evt) => {
														if (
															evt.key ===
																"Enter" &&
															!evt.shiftKey
														) {
															evt.preventDefault();
															this.performance = new Date();
															this.editFeedback(
																item
															);
														}
													}}
													onChange={(evt) => {
														let editedFeedback =
															evt.target.value;
														this.setState({
															editedFeedback: editedFeedback,
														});
													}}
													onBlur={(evt) => {
														if (
															evt.target.getAttribute(
																"preventBlur"
															) !== "true"
														) {
															this.setState({
																editedFeedback:
																	"",
																editedFeedbackId: undefined,
															});
														}
													}}
												/>
											</MentionComponent>
										)}
										<div
											className="my-row"
											style={{ marginTop: "10px" }}
										>
											<span
												style={{
													fontFamily: "Roboto",
													color: mainStyle.getPropertyValue(
														"--graphs-axes-text-color"
													),
													fontSize: 12,
												}}
											>
												{item.creation_time}
											</span>
											<div style={{ flexGrow: 1 }} />
											<span
												style={{
													fontFamily: "Roboto",
													color: mainStyle.getPropertyValue(
														"--graphs-axes-text-color"
													),
													fontSize: 12,
												}}
											>
												{item.user_name}
											</span>
										</div>
									</div>
								);
							})}
							<div style={{ minHeight: 20 }} />
						</div>
					</div>
				</CSSTransition>
			</OutsideAlerter>
		);
	}
}