import React from "react";
import cx from "classnames";

import styles from "./Skeleton.module.css";

interface Props {
	className?: string
}

function Skeleton({ className = '' }: Props) {
	return <div className={cx(styles.root, { [className]: !!className })} />;
}

export default Skeleton;
