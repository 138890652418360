import React from "react";
import RatiosChart from "common/graphics/RatiosChart";

function MainComponent(props) {
	let finding = props.finding;

	return (
		<div style={{ width: "100%", height: "100%" }}>
			<RatiosChart data={finding.content.data} config={finding.config} />
		</div>
	);
}

export { MainComponent };