import { SearchComponentOption } from "./SearchComponent";


export interface SearchComponentOptionWithMeta extends SearchComponentOption {
    meta?: {
        selectAll: boolean;
    };
}
export const selectAllOptions: SearchComponentOptionWithMeta[] = [
    {
        label: "SELECT ALL",
        value: NaN,
        meta: {
            selectAll: true,
        },
    },
    // {
    //     label: "DESELECT ALL",
    //     value: NaN,
    //     meta: {
    //         selectAll: false,
    //     },
    // },
];
