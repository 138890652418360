import React, { Component } from "react";
import { mainStyle } from "common/MainStyle";
import MobileFindingItemWrapper from "./MobileFindingItemWrapper";
import FindingItem from "./FindingItem";
import { UserIcon } from "common/UserIcon";
import Instrumentation from "common/Instrumentation";
import FeedbacksContent from "./FeedbacksContent";
import { FeedbackType } from "common/Feedback";
import NotificationIcon from "common/NotificationIcon";

class InsightsCard extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            scale: props.scale || 1,
            showFeedbacks: false,
        };
        this.updateBoxes = this.updateBoxes.bind(this);
        this.insightRenderView = React.createRef();
        this.recommRenderView = React.createRef();

        this.performance = null;
    }
    updateBoxes() {
        if (this.props.mobileView && !this.props.landscapeOrientation) {
            if (this.insightRenderView.current)
                this.insightRenderView.current.parentElement.style.marginTop =
                    "60px";
        }
        if (this.props.mobileView) {
            if (this.recommRenderView.current)
                this.recommRenderView.current.style.marginTop =
                    this.insightRenderView.current.clientHeight + "px";
        }
    }
    componentDidMount() {
        this.updateBoxes();
    }
    componentDidUpdate(prevProps) {
        if (this.performance != null) {
            let timeMs = new Date() - this.performance;
            this.performance = null;
            Instrumentation.addInteraction("Insights", timeMs);
        }
        this.updateBoxes();
    }
    showMultilineText(text) {
        return (
            <>
                {text.split("\n").map((line, index) => (
                    <span
                        key={index}
                        style={{
                            textAlign: "left",
                            marginTop: 10,
                            marginLeft: this.props.mobileView ? 0 : 10,
                            wordBreak: "break-word",
                            color: mainStyle.getPropertyValue(
                                "--content-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: this.props.mobileView
                                ? 17
                                : 12 * this.state.scale,
                            display: "block",
                        }}
                    >
                        {line}
                    </span>
                ))}
            </>
        );
    }

    buildInsightRow(insight) {
        if (this.props.mobileView)
            return (
                <MobileFindingItemWrapper
                    finding={insight.contents.finding}
                    storyId={this.props.storyId}
                    slideIndex={this.props.slideIndex}
                    insightId={this.props.insightId}
                />
            );

        return (
            <div
                style={{
                    marginTop: 20,
                    width: "100%",
                    flexGrow: 1,
                    maxHeight:
                        insight.contents.finding?.type === "image"
                            ? "none"
                            : 0.64 * this.props.parentContainerHeight,
                }}
            >
                <FindingItem
                    finding={insight.contents.finding}
                    storyId={this.props.storyId}
                    slideIndex={this.props.slideIndex}
                    insightId={this.props.insightId}
                />
            </div>
        );
    }

    buildStepTitle() {
        return this.props.insight.contents.title;
    }

    buildTopDropdown() {
        if (!this.props.editable && !this.props.canShowFeedbacks) return null;
        return (
            <div
                style={{
                    display: "flex",
                    zIndex: 999999,
                    backgroundColor: "transparent",
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    paddingRight: "10px",
                }}
            >
                {this.props.canShowFeedbacks &&
                    this.props.insight.has_new_feedbacks && (
                        <NotificationIcon />
                    )}
                {this.props.editable && (
                    <div className="center-container">
                        <span
                            title={"Edit"}
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                this.performance = new Date();
                                this.props.onEdit();
                            }}
                        >
                            <svg
                                style={{
                                    fill: mainStyle.getPropertyValue(
                                        "--secondary-content-color"
                                    ),
                                }}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z" />
                            </svg>
                        </span>
                    </div>
                )}
                {this.props.canShowFeedbacks && (
                    <div
                        className="center-container"
                        style={{ marginLeft: "5px" }}
                    >
                        <span
                            title={"Comment"}
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                this.performance = new Date();
                                this.setState({ showFeedbacks: true });
                            }}
                        >
                            <svg
                                style={{
                                    fill: mainStyle.getPropertyValue(
                                        "--secondary-content-color"
                                    ),
                                }}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path d="M21.81,5.28l.77-2.38a1.44,1.44,0,0,0-.48-1.59,1.42,1.42,0,0,0-1.63-.1l-6.6,4.07H1V23H23V5.28ZM21,21H3V7.28H14.44l5.77-3.56L19.06,7.28H21Z" />
                                <path d="M6.4,13a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,6.4,13Z" />
                                <path d="M12,13a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,12,13Z" />
                                <path d="M17.6,13A1.4,1.4,0,1,0,19,14.4,1.4,1.4,0,0,0,17.6,13Z" />
                            </svg>
                        </span>
                    </div>
                )}
            </div>
        );
    }

    buildContent() {
        let hasConfig =
            this.props.insight.contents.finding &&
            this.props.insight.contents.finding.config;
        return (
            <div
                className="my-row"
                style={{
                    width: "100%",

                    height: this.props.mobileView
                        ? this.props.parentContainerHeight * 0.7
                        : "100%",
                    marginTop: this.props.mobileView ? 0 : 20,
                    flexDirection:
                        this.props.mobileView &&
                        !this.props.landscapeOrientation
                            ? "column"
                            : "initial",
                }}
            >
                <div
                    className="flex-simple-column"
                    style={{
                        paddingLeft:
                            this.props.mobileView &&
                            !this.props.landscapeOrientation
                                ? 0
                                : 30,
                        paddingRight:
                            this.props.mobileView &&
                            !this.props.landscapeOrientation
                                ? 0
                                : 30,
                        width:
                            this.props.mobileView &&
                            !this.props.landscapeOrientation
                                ? "100%"
                                : this.props.insight.contents.noInsight &&
                                  this.props.insight.contents.noRecommendation
                                ? "100%"
                                : "70%",
                        height:
                            this.props.mobileView &&
                            !this.props.landscapeOrientation
                                ? this.props.parentContainerHeight * 0.7 * 0.6
                                : null,
                        minHeight: 0,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            marginTop:
                                this.props.mobileView &&
                                !this.props.landscapeOrientation
                                    ? 0
                                    : 30,
                            marginBottom:
                                this.props.mobileView &&
                                !this.props.landscapeOrientation
                                    ? 0
                                    : 10,
                            width: "100%",
                            justifyContent: "center",
                        }}
                    >
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--secondary-text-color"
                                ),
                                fontFamily: "Roboto",
                                fontSize: this.props.mobileView
                                    ? 17
                                    : 17 * this.state.scale,
                                fontWeight: 700,
                            }}
                        >
                            {this.props.insight.contents.graphicsTitle}
                        </span>
                    </div>
                    {this.props.pinnable && (
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-end",
                            }}
                        >
                            <div
                                title={
                                    hasConfig ? "Pin to KPI Stream" : undefined
                                }
                                style={{
                                    opacity: hasConfig ? 1 : 0.3,
                                }}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    if (hasConfig) this.props.onPin();
                                }}
                            >
                                <img
                                    alt=""
                                    src="/dist/img/insights/insights_pin.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    )}
                    {this.buildInsightRow(this.props.insight)}
                </div>
                {this.props.mobileView && !this.props.landscapeOrientation && (
                    <div
                        style={{
                            minHeight: "2px",
                            backgroundColor: mainStyle.getPropertyValue(
                                "--quaternary-content-color"
                            ),
                        }}
                    />
                )}
                {(!this.props.insight.contents.noInsight ||
                    !this.props.insight.contents.noRecommendation) && (
                    <div
                        className="flex-simple-column element"
                        style={{
                            marginRight: this.props.mobileView ? 0 : 30,
                            marginTop: 20,
                            overflow: this.props.mobileView ? "auto" : "hidden",
                            width:
                                this.props.mobileView &&
                                !this.props.landscapeOrientation
                                    ? "100%"
                                    : "30%",
                            height:
                                this.props.mobileView &&
                                !this.props.landscapeOrientation
                                    ? this.props.parentContainerHeight *
                                          0.7 *
                                          0.4 -
                                      60
                                    : null,
                            minHeight: this.props.mobileView ? "initial" : 300,
                            justifyContent: "space-between",
                        }}
                    >
                        {!this.props.insight.contents.noInsight && (
                            <div
                                className="flex-simple-column"
                                style={{
                                    marginTop:
                                        this.props.mobileView &&
                                        !this.props.landscapeOrientation
                                            ? "60px"
                                            : "0px",
                                    minHeight: this.props.mobileView
                                        ? "initial"
                                        : 150,
                                }}
                            >
                                <span
                                    style={{
                                        textAlign: "left",
                                        color: mainStyle.getPropertyValue(
                                            "--secondary-text-color"
                                        ),
                                        fontFamily: "Roboto",
                                        fontSize: this.props.mobileView
                                            ? 17
                                            : 17 * this.state.scale,
                                        fontWeight: 700,
                                        marginTop: 10,
                                    }}
                                >
                                    Insight
                                </span>
                                <div ref={this.insightRenderView}>
                                    {this.showMultilineText(
                                        this.props.insight.contents.insight
                                    )}
                                </div>
                            </div>
                        )}
                        {!this.props.insight.contents.noInsight && (
                            <div
                                style={{
                                    backgroundColor: mainStyle.getPropertyValue(
                                        "--quaternary-content-color"
                                    ),
                                    margin: 10,
                                }}
                            />
                        )}
                        {!this.props.insight.contents.noRecommendation && (
                            <div
                                className="flex-simple-column"
                                ref={this.recommRenderView}
                                style={{
                                    marginTop: this.props.mobileView
                                        ? this.insightRenderView.current
                                            ? this.insightRenderView.current
                                                  .clientHeight + "px"
                                            : 0
                                        : 0,
                                    minHeight: this.props.mobileView
                                        ? "initial"
                                        : 150,
                                }}
                            >
                                <span
                                    style={{
                                        textAlign: "left",
                                        color: mainStyle.getPropertyValue(
                                            "--secondary-text-color"
                                        ),
                                        fontFamily: "Roboto",
                                        fontSize: this.props.mobileView
                                            ? 17
                                            : 17 * this.state.scale,
                                        fontWeight: 700,
                                        marginTop: this.props.mobileView
                                            ? 20
                                            : 0,
                                    }}
                                >
                                    Recommendation
                                </span>
                                {this.showMultilineText(
                                    this.props.insight.contents.recommendation
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    buildFooter() {
        if (this.props.presentation) return null;
        return (
            <div
                style={{
                    marginTop: 10,
                    display: "flex",
                    marginRight: "10px",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginBottom: 14,
                }}
            >
                {this.props.insight.user_data && (
                    <UserIcon
                        width="22px"
                        height="22px"
                        fontSize={10}
                        user={this.props.insight.user_data}
                        iconUrl={this.props.insight.user_data.icon_url}
                    />
                )}
                <div
                    className="flex-simple-column"
                    style={{ marginLeft: "5px" }}
                >
                    <span
                        style={{
                            minHeight: 17 * this.state.scale,
                            color: mainStyle.getPropertyValue(
                                "--content-primary-text-color"
                            ),
                            fontFamily: "Arial",
                            fontSize: this.props.mobileView
                                ? 10
                                : 10 * this.state.scale,
                        }}
                    >
                        {this.props.insight.user_data
                            ? this.props.insight.user_data.first_name
                                  .concat(" ")
                                  .concat(
                                      this.props.insight.user_data.last_name
                                  )
                            : ""}
                    </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--content-secondary-text-color"
                            ),
                            fontFamily: "Arial",
                            fontSize: this.props.mobileView
                                ? 10
                                : 10 * this.state.scale,
                        }}
                    >
                        {this.props.insight.creation_time || ""}
                    </span>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div
                    className="flex-simple-column"
                    style={{
                        width: this.props.mobileView
                            ? this.props.parentContainerWidth
                            : "100%",
                        height: this.props.parentContainerHeight,
                        justifyContent: "space-between",
                    }}
                >
                    <div className="flex-simple-column" style={{ flexGrow: 1 }}>
                        {this.props.mobileView && !this.props.presentation ? (
                            <div
                                style={{
                                    zIndex: 999999,
                                    position: "absolute",
                                    top: 20,
                                    left: 20,
                                }}
                                onClick={() => this.props.onHide()}
                            >
                                <img
                                    alt=""
                                    src="/dist/img/insights/back.png"
                                    style={{
                                        width: 30,
                                        height: 30,
                                    }}
                                />
                            </div>
                        ) : null}

                        <div
                            className="center-container"
                            style={{ minHeight: 30 }}
                        >
                            <span
                                style={{
                                    marginTop: this.props.mobileView ? 0 : 20,
                                    paddingTop: this.props.mobileView ? 30 : 0,
                                    paddingLeft: this.props.mobileView ? 0 : 10,
                                    color: mainStyle.getPropertyValue(
                                        "--content-primary-text-color"
                                    ),
                                    fontFamily: "Roboto",
                                    fontSize: this.props.mobileView
                                        ? 25
                                        : 30 * this.state.scale,
                                    fontWeight: 300,
                                }}
                            >
                                {this.buildStepTitle()}
                            </span>
                            {this.buildTopDropdown()}
                        </div>

                        {this.buildContent()}
                    </div>
                    {this.buildFooter()}
                </div>
                {this.props.canShowFeedbacks && (
                    <FeedbacksContent
                        needUpdate={this.props.insight.has_new_feedbacks}
                        showFeedbacks={this.state.showFeedbacks}
                        onClose={() => {
                            if (this.state.showFeedbacks) {
                                this.performance = new Date();
                                this.setState({ showFeedbacks: false });
                            }
                        }}
                        onGetFeedbacks={this.props.onGetFeedbacks}
                        type={FeedbackType.insight}
                        cardId={this.props.insight.id}
                    />
                )}
            </div>
        );
    }
}

export default InsightsCard;