const countries = [
	"Aland Islands",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Argentina",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bangladesh",
	"Belarus",
	"Belgium",
	"Bermuda",
	"Brazil",
	"Bulgaria",
	"Canada",
	"Chile",
	"Colombia",
	"Costa Rica",
	"Croatia",
	"Cyprus",
	"Czechia",
	"Denmark",
	"Dominican Republic",
	"Estonia",
	"Faroe Islands",
	"Finland",
	"France",
	"French Guiana",
	"Germany",
	"Greenland",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Hungary",
	"Iceland",
	"India",
	"Ireland",
	"Isle of Man",
	"Italy",
	"Japan",
	"Jersey",
	"Latvia",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Malawi",
	"Malaysia",
	"Malta",
	"Marshall Islands",
	"Martinique",
	"Mayotte",
	"Mexico",
	"Micronesia",
	"Moldova",
	"Monaco",
	"Netherlands",
	"New Caledonia",
	"New Zealand",
	"North Macedonia",
	"Northern Mariana Islands",
	"Norway",
	"Pakistan",
	"Palau",
	"Peru",
	"Philippines",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Reunion",
	"Romania",
	"Russia",
	"Saint Pierre and Miquelon",
	"San Marino",
	"Serbia",
	"Singapore",
	"Slovakia",
	"Slovenia",
	"South Africa",
	"South Korea",
	"Spain",
	"Sri Lanka",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Thailand",
	"Turkey",
	"U.S. Virgin Islands",
	"Ukraine",
	"United Kingdom",
	"United States",
	"Uruguay",
	"Vatican",
	"Wallis and Futuna",
];

const countriesOptions = countries.map((country) => ({
	label: country,
	value: country,
}));
export default countriesOptions;