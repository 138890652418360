import React from "react";
import {
	DropdownOptionsAligment,
	InputFieldStyle,
	TextAlign,
} from "common/Canvas";

interface Input {
	inputFieldStyle?: InputFieldStyle;
	textAlignment?: DropdownOptionsAligment;
}

export const getAlignment = (alignment?: string) => {
	let styles = {
		justifyContent: "flex-start",
		textAlign: "left",
	};
	if (alignment === TextAlign.center) {
		styles = {
			textAlign: TextAlign.center,
			justifyContent: TextAlign.center,
		}
	}
	if (alignment === TextAlign.right) {
		styles = {
			textAlign: TextAlign.right,
			justifyContent: "flex-end",
		}
	}
	
	return {
		...styles,
		padding: "0 20px",
	};
}

const commonTextStyle = {
	display: "flex",
	width: "100%",
	height: "100%",
	alignItems: "center",
}
const multilineTextStyle = {
	display: "block",
	overflow: "auto",
	wordBreak: "break-all",
	paddingTop: 0,
}

function getLegacyStyle() {
	return {
		containerStyle: {},
		textStyle: {},
	};
}

function getDefaultStyle(
	edited: boolean,
	hovered: boolean,
) {
	let borderColor = "#acacac";
	if (hovered) borderColor = "#797979";
	if (edited) borderColor = "#3399ff";
	return {
		containerStyle: {
			border: `2px solid ${borderColor}`,
			borderRadius: 8,
		},
		textStyle: commonTextStyle,
	};
}

function getMDFilledStyle(
	hovered: boolean,
	multiline?: boolean,
) {
	return {
		containerStyle: {
			borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
			borderRadius: "4px 4px 0 0",
			background: hovered ? "rgb(231, 231, 231)" : "#f5f5f5",
			justifyContent: "flex-start",
		},
        textStyle: {
			...commonTextStyle,
			...(multiline ? multilineTextStyle : {}),
		} as React.CSSProperties,
	};
}

function getMDOutlinedStyle(
	edited: boolean,
	hovered: boolean,
	multiline?: boolean,
) {
	let borderColor = "#acacac";
	if (hovered) borderColor = "#797979";
	if (edited) borderColor = "#3399ff";
	return {
		containerStyle: {
			border: `1px solid ${borderColor}`,
			borderRadius: 4,
			justifyContent: "flex-start",
		},
        textStyle: {
			...commonTextStyle,
			...(multiline ? multilineTextStyle : {}),
		} as React.CSSProperties,
	};
}

export function getInputFieldStyle(
	input: Input,
	edited: boolean,
	hovered: boolean,
	multiline?: boolean,
): {
	containerStyle: React.CSSProperties;
	textStyle: React.CSSProperties;
} {
	switch (input.inputFieldStyle) {
		case InputFieldStyle.Legacy:
			return getLegacyStyle();
		case InputFieldStyle.Default:
			return getDefaultStyle(edited, hovered);
		case InputFieldStyle.MDFilled:
			return getMDFilledStyle(hovered, multiline);
		case InputFieldStyle.MDOutlined:
			return getMDOutlinedStyle(edited, hovered, multiline);
		default:
			return getLegacyStyle();
	}
}