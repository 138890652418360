import React, { Component } from "react";
import CustomBarChartWithOverlay from "common/graphics/CustomBarChartWithOverlay";
import { Element } from "react-scroll";
import { LegendElement } from "common/graphics/LegendElement";
import OutcomesBlockV3 from "common/graphics/OutcomesBlockV3";
import sections from "sections.json";

function getSumItem(clustItem, leftBarKey, rightBarKey) {
	let projectedSum = clustItem.reduce((accumulator, value) => {
		return accumulator + value[leftBarKey];
	}, 0);
	let optimalSum = clustItem.reduce((accumulator, value) => {
		return accumulator + value[rightBarKey];
	}, 0);
	let units = clustItem.length > 0 ? clustItem[0].units : "";

	return {
		[leftBarKey]: projectedSum,
		[rightBarKey]: optimalSum,
		units: units,
	};
}

class LeversChartsComponentWithOverlay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentVariable: undefined,
			allVariables: [],
			clustVariables: [],
		};
		this.selectPreviousVariable = this.selectPreviousVariable.bind(this);
		this.selectNextVariable = this.selectNextVariable.bind(this);
		this.selectVariable = this.selectVariable.bind(this);
		this.rootRef = null;
		this.prevClientHeight = 0;
	}
	selectVariable(variable, scroll) {
		this.setState({ currentVariable: variable }, () => {
			this.state.clustVariables.forEach((clustVariablesGroup, index) => {
				if (clustVariablesGroup.includes(variable)) {
					this.refs["clustBarChart".concat(index)].selectVariable(
						variable,
						scroll
					);
				} else {
					this.refs["clustBarChart".concat(index)].selectVariable(
						undefined,
						false
					);
				}
			});
		});
	}
	selectPreviousVariable(currentVariable, scroll) {
		let index = this.state.allVariables.findIndex(
			(item) => item === currentVariable
		);
		if (index > 0) {
			index = index - 1;
		} else {
			index = this.state.allVariables.length - 1;
		}
		let newVariable = this.state.allVariables[index];
		this.selectVariable(newVariable, scroll);
	}
	selectNextVariable(currentVariable, scroll) {
		let index = this.state.allVariables.findIndex(
			(item) => item === currentVariable
		);
		if (index < this.state.allVariables.length - 1) {
			index = index + 1;
		} else {
			index = 0;
		}
		let newVariable = this.state.allVariables[index];
		this.selectVariable(newVariable, scroll);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let newState = Object.assign({}, prevState);
		newState.clust = Array.from(nextProps.clust);
		let clustVariables = newState.clust.map((clustItem) =>
			clustItem.map((variableItem) => variableItem.name)
		);

		newState.allVariables = clustVariables.flat();
		newState.clustVariables = clustVariables;
		return newState;
	}

	componentDidUpdate(nextProps) {
		let currentHeight = this.rootRef?.clientHeight ?? 0;
		if (
			currentHeight !== this.prevClientHeight ||
			(this.state.allVariables.length > 0 && !this.state.currentVariable)
		)
			setTimeout(() => {
				this.selectVariable(this.state.allVariables[0]);
			}, 300);
		this.prevClientHeight = currentHeight;
	}

	componentDidMount() {
		if (this.state.allVariables.length > 0 && !this.state.currentVariable)
			setTimeout(() => {
				this.selectVariable(this.state.allVariables[0]);
			}, 300);
	}

	render() {
		let clustNames =
			this.props.clustNames ||
			new Array(this.state.clust.length).fill("");
		let chartWidth =
			this.state.clust.length > 0
				? Math.floor(100 / this.state.clust.length)
				: undefined;
		return (
			<div
				className="flex-simple-column"
				style={{ height: "100%" }}
				ref={(ref) => {
					this.rootRef = ref;
				}}
			>
				<div
					className="my-row center-container"
					style={{ marginLeft: 20, marginRight: 20, width: "100%" }}
				>
					<LegendElement color={"#FFAB4F"} text={"Projected Level"} />
					<LegendElement color={"#05C985"} text={"Optimal Level"} />
				</div>

				<div
					className="my-row element"
					style={{
						paddingBottom: "5px",
						overflowX: "auto",
						height: "100%",
					}}
				>
					{this.state.clust.length !== 0 &&
						clustNames.map((clustName, clustIndex) => (
							<div
								className="flex-simple-column"
								key={clustIndex}
								style={{
									height: "100%",
									minWidth: `calc(${chartWidth}% - 5px)`,
									marginRight: "5px",
								}}
							>
								<Element
									key={clustIndex}
									ref={(el) => {
										this.refs[
											"clustBarChartScrollable".concat(
												clustIndex
											)
										] = el;
									}}
									name="scrollable"
									className="element"
									style={{
										height: "100%",
										overflowX: "auto",
										backgroundColor: "transparent",
										position: "relative",
										overflowY: "hidden",
										marginRight: "5px",
										minWidth: `calc(${chartWidth}% - 5px)`,
									}}
								>
									<CustomBarChartWithOverlay
										ref={"clustBarChart".concat(clustIndex)}
										onSnapToGrid={(variable) => {
											let index = this.state.clust[
												clustIndex
											].findIndex(
												(item) => item.name === variable
											);
											let newClust = Array.from(
												this.state.clust
											);
											newClust[clustIndex][index][
												"projected"
											] =
												newClust[clustIndex][index][
													"optimal"
												];
											this.setState(
												{ clust: newClust },
												() => {
													this.refs[
														"clustBarChart".concat(
															clustIndex
														)
													].selectVariable(
														variable,
														false
													);
												}
											);
										}}
										onVariableChanged={(
											variable,
											maxChartValue,
											levelsCount,
											currentIndex,
											type
										) => {
											let index = this.state.clust[
												clustIndex
											].findIndex(
												(item) => item.name === variable
											);
											let newClust = Array.from(
												this.state.clust
											);
											newClust[clustIndex][index][type] =
												(maxChartValue * currentIndex) /
												levelsCount;

											this.setState({
												clust: newClust,
											});
										}}
										onVariableEntered={(
											variable,
											type,
											value
										) => {
											let index = this.state.clust[
												clustIndex
											].findIndex(
												(item) => item.name === variable
											);
											let newClust = Array.from(
												this.state.clust
											);
											newClust[clustIndex][index][
												type
											] = value;
											this.setState({
												clust: newClust,
											});
										}}
										selectNextVariable={
											this.selectNextVariable
										}
										selectPreviousVariable={
											this.selectPreviousVariable
										}
										selectVariable={this.selectVariable}
										leftBarKey={"projected"}
										leftBarColor={"#FFAB4F"}
										rightBarColor={"#05C985"}
										rightBarKey={"optimal"}
										type={this.props.type}
										data={this.state.clust[clustIndex]}
										scrollableRef={
											this.refs[
												"clustBarChartScrollable".concat(
													clustIndex
												)
											]
										}
										title={
											clustNames
												? clustNames[clustIndex]
												: ""
										}
										watermark={sections._Global?.watermark}
									/>
								</Element>
								<OutcomesBlockV3
									leftTitle={`Projected ${clustName} levers total`}
									rightTitle={`Optimal ${clustName} levers total`}
									item={getSumItem(
										this.state.clust[clustIndex],
										"projected",
										"optimal"
									)}
									leftKey="projected"
									rightKey="optimal"
								/>
							</div>
						))}
				</div>
			</div>
		);
	}
}

export default LeversChartsComponentWithOverlay;