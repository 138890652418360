import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { WordCloudFinding } from "common/Finding";
import { Condition } from "common/Conditions";
import { toInteger } from "lodash";

class Api {
    static getDefaultConfig(journeyName: string): WordCloudFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            count: 25,
            wordLevel: true,
            removeStopWords: false,
            minimumWordCount: 1,
            excludedWords: ""
        };
    }

    static getPreviewFinding(journeyName: string): WordCloudFinding {
        let item = {
            type: "wordcloud",
            content: {
                data: [
                    {
                        text: "knowledge",
                        value: 50,
                    },
                    {
                        text: "education",
                        value: 40,
                    },
                    {
                        text: "inspiration",
                        value: 30,
                    },
                    {
                        text: "science",
                        value: 20,
                    },
                    {
                        text: "learning",
                        value: 10,
                    },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static getData(
        finding: WordCloudFinding,
        findingOptions: any,
        moduleId?: number
    ): Promise<WordCloudFinding> {
        let {
            dataScope,
            count,
            selectedVariableIndex,
            wordLevel,
            removeStopWords,
            minimumWordCount,
            excludedWords
        } = finding.config;

        let conditions: Condition[] | undefined = finding.config.conditions;
        conditions = conditions?.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        let requestJson = {
            variable_index: selectedVariableIndex,
            table: [],
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            conditions:
                conditions != null ? conditionsToJson(conditions) : undefined,
            limit: count || 25,
            word_level: wordLevel,
            remove_stop_words: removeStopWords,
            module_id: moduleId,
            minimum_word_count: toInteger(minimumWordCount),
            excluded_words: excludedWords.split(",").map((w : string) => w.trim().toLowerCase()) ?? []
        };
        return axios
            .post<{
                success: boolean;
                error_msg: string;
                data?: {
                    text: string;
                    value: number;
                }[];
            }>("/api/e/word_cloud", requestJson)
            .then((response) => {
                if (response.data.success && response.data.data != null) {
                    return Promise.resolve({
                        ...finding,
                        content: {
                            ...finding.content,
                            data: response.data.data,
                        },
                    });
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
