import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { CorrelogramFinding } from "common/Finding";
import { updateOriginalName, updateOriginalNames } from "../../common/Utils";

class Api {
    static getDefaultConfig(journeyName: string): CorrelogramFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
        };
    }

    static getPreviewFinding(journeyName: string): CorrelogramFinding {
        let item = {
            type: "correlogram",
            content: {
                data: [
                    {
                        name: "var1",
                        value: [],
                    },
                    {
                        name: "var2",
                        value: [0.7],
                    },
                    {
                        name: "var3",
                        value: [0.01, -0.3],
                    },
                ],
                time: {
                    name: "time",
                    value: [],
                    uniqueValues: [],
                },
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static async getData(
        finding: CorrelogramFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<CorrelogramFinding> {
        let { dataScope, selectedTable, conditions } = finding.config ?? {};
        let variables = finding.content.data.filter(
            (item) => item.variableIndex != null
        );
        if (variables.length <= 1) {
            return Promise.resolve(finding);
        }
        conditions = filterNonEmptyConditions(conditions ?? []);

        let additionalVariableIndices: number[] = [];
        if (finding.content.time?.variableIndex != null) {
            additionalVariableIndices.push(finding.content.time.variableIndex);
        }

        let requestJson = {
            variable_indices: variables.map(
                (variable) => variable.variableIndex
            ),
            additional_variable_indices: additionalVariableIndices,
            pearson_only: true,
            conditions: conditionsToJson(conditions),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            module_id: moduleId,
        };

        return axios
            .post<{
                success: boolean;
                error_msg: string;
                regression_param_names: string[];
                regression_params: (number | null)[][][];
                additional_variables?: (string | number | null)[][];
            }>("/api/e/get_regression", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: finding.content.data.map((item) => ({
                                ...item,
                            })),
                        },
                    };
                    // Check wherther variables were renamed
                    updateOriginalNames(
                        newFinding.content.data,
                        dataScope.value,
                        moduleId
                    );
                    for (
                        let i = 0;
                        i < response.data.regression_params.length;
                        ++i
                    ) {
                        newFinding.content.data[i].value = [];
                        for (let j = 0; j < i; ++j) {
                            newFinding.content.data[i].value.push(
                                response.data.regression_params[i][j][0] ?? 0
                            );
                        }
                    }
                    if (
                        response.data.additional_variables != null &&
                        response.data.additional_variables.length !== 0
                    ) {
                        newFinding.content.time = {
                            ...newFinding.content.time!,
                            value: response.data.additional_variables[0],
                            uniqueValues: Array.from(
                                new Set(
                                    response.data.additional_variables[0].filter(
                                        (value): value is string | number =>
                                            value != null
                                    )
                                )
                            ).sort((value1, value2) =>
                                typeof value1 === "number" &&
                                typeof value2 === "number"
                                    ? value1 - value2
                                    : String(value1).localeCompare(
                                          String(value2)
                                      )
                            ),
                        };
                        // Check wherther variables were renamed
                        updateOriginalName(
                            newFinding.content.time,
                            dataScope.value,
                            moduleId
                        );
                    }
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
