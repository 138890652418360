import axios from "common/ServerConnection";
import {
    CanvasComment,
    NewComment,
    BaseCommentPin,
    CommentPin,
    CommentPinResponse,
    toCommentPin,
} from "./CanvasComments";

export async function getNewCanvasComments(
    moduleId: number
): Promise<NewComment[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            pin_info?: NewComment[];
        }>("/api/canvas_check_new_comments", { module_id: moduleId })
        .then((response) => {
            if (response.data.success && response.data.pin_info != null) {
                return Promise.resolve(response.data.pin_info);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function addPinApi(
    pin: BaseCommentPin,
    canvasId: number,
    updateId?: string
): Promise<number> {
    let requestJson = {
        contents: JSON.stringify(pin),
        canvas_id: canvasId,
        update_id: updateId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/canvas_add_comment_pin", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function editPinApi(
    pinId: number,
    changes: Partial<BaseCommentPin>,
    updateId?: string
): Promise<void> {
    let requestJson = {
        contents: JSON.stringify(changes),
        pin_id: pinId,
        update_id: updateId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/canvas_update_comment_pin", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export function getPinsApi(canvasId: number): Promise<CommentPin[]> {
    let requestJson = {
        canvas_id: canvasId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            pins?: CommentPinResponse[];
        }>("/api/canvas_get_comment_pins", requestJson)
        .then((response) => {
            if (response.data.success && response.data.pins != null) {
                return Promise.resolve(
                    response.data.pins.map(
                        (item: CommentPinResponse): CommentPin =>
                            toCommentPin(item)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}
export async function deletePinApi(
    pinId: number,
    updateId?: string
): Promise<void> {
    let requestJson = {
        pin_id: pinId,
        update_id: updateId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/canvas_remove_comment_pin", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function addCommentApi(
    pinId: number,
    comment: string,
    mentions: string[],
    update_id?: string
): Promise<number> {
    let requestJson = {
        comment: comment,
        mentions: mentions,
        pin_id: pinId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/canvas_comment_pin_add_comment", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function getCommentsApi(
    pinId: number,
    skipMarkAsRead: boolean
): Promise<CanvasComment[]> {
    let requestJson = {
        pin_id: pinId,
        skip_mark_as_read: skipMarkAsRead,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            feedbacks?: CanvasComment[];
        }>("/api/canvas_comment_pin_get_comments", requestJson)
        .then((response) => {
            if (response.data.success && response.data.feedbacks != null) {
                return Promise.resolve(response.data.feedbacks);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function editCommentApi(
    commentId: number,
    comment: string,
    mentions: string[],
    updateId?: string
): Promise<number> {
    let requestJson = {
        comment: comment,
        mentions: mentions,
        comment_id: commentId,
        update_id: updateId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/canvas_comment_pin_edit_comment", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function deleteCommentApi(
    commentId: number,
    updateId?: string
): Promise<void> {
    let requestJson = {
        comment_id: commentId,
        update_id: updateId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/canvas_comment_pin_remove_comment", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function sendMessageNotification(
    canvasId: number,
    message: string,
    pinId: number,
    users: string[]
): Promise<void> {
    let requestJson = {
        id: canvasId,
        message: message,
        pin_id: pinId,
        recipient_user_names: users,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/canvas_mention_notification_send", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}
