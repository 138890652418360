import React from "react";
import Select from "react-select";

import GlobalContext, { GlobalContextContents } from "GlobalContext";
import { MapFinding, NetworkFinding } from "common/Finding";

import styles from "../HeatmapConfig/HeatmapConfig.module.css";

import dataExplorationPageModules from "modules/data_exploration_page/modules/module_list.json";

const options = dataExplorationPageModules
    .find((item) => item.name === "Map")!
    .submodules.map((item) => ({
        label: item.title,
        value: {
            dir: item.dir,
            name: item.name,
            require_permission: item.require_permission,
        },
    }));

const typeToOption = Object.fromEntries(
    options.map((option) => [option.value.dir, option])
);

interface Props {
    mapFinding: MapFinding | NetworkFinding;
    onChange: (
        finding: MapFinding | NetworkFinding,
        updateData?: boolean
    ) => void;
    currentModuleId?: number;
}

class AdditionalMap extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.renderContents = this.renderContents.bind(this);
    }

    public renderContents(globalContext: GlobalContextContents): JSX.Element {
        let { mapFinding, onChange } = this.props;
        let accessibleOptions = options.filter(
            (option) =>
                option.value.require_permission in globalContext.permissions
        );
        const mapsData = [
            {
                label: "Second Map",
                idx: 0,
            },
            {
                label: "Third Map",
                idx: 1,
            },
            {
                label: "Fourth Map",
                idx: 2,
            },
        ];
        
        return (
            <div className={styles.root}>
                {mapsData.map(data => {
                    const mapType = mapFinding.additionalMapsFindings?.[data.idx]?.type;
                    return (
                        <Select
                            isClearable
                            placeholder={data.label}
                            isDisabled={
                                (data.idx === 1 && mapFinding.additionalMapsFindings?.[0]?.type === undefined) ||
                                (data.idx === 2 && mapFinding.additionalMapsFindings?.[1]?.type === undefined)
                            }
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    minWidth: 60,
                                    marginTop: 10,
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    minHeight: 25,
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    zIndex: 100000000,
                                }),
                            }}
                            options={accessibleOptions}
                            onChange={(newValue, oldValue) => {
                                if (newValue != null) {
                                    const { value } = newValue as typeof options[0];
                                    let api = require(`modules/data_exploration_page/modules/${value.dir}/ApiV2`);
                                    const previewFinding:
                                        | MapFinding
                                        | NetworkFinding = api.Api.getPreviewFinding(
                                        value.name
                                    );
                                    previewFinding.config.isAdditional = true;
                                    const additionalMapsFindings = [...(mapFinding.additionalMapsFindings ?? [])];
                                    additionalMapsFindings[data.idx] = previewFinding;
                                    const currentFindingCopy:
                                        | MapFinding
                                        | NetworkFinding = {
                                        ...mapFinding,
                                        // At the moment it only changes the first additional map
                                        additionalMapsFindings: additionalMapsFindings,
                                    };
                                    onChange(currentFindingCopy, true);
                                } else {
                                    const currentFindingCopy:
                                        | MapFinding
                                        | NetworkFinding = {
                                        ...mapFinding,
                                        // At the moment it only changes the first additional map
                                        additionalMapsFindings: [
                                            ...(mapFinding?.additionalMapsFindings || []).filter((finding, idx) =>
                                                data.idx !== idx)
                                        ],
                                    };
                                    onChange(currentFindingCopy, true);
                                }
                            }}
                            value={
                                mapFinding.additionalMapsFindings != null &&
                                mapFinding.additionalMapsFindings.length !== 0 &&
                                mapType
                                    ? typeToOption[mapType]
                                    : null
                            }
                        />
                    )
                })}
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

export default AdditionalMap;
