enum PageType {
	Insights = 1,
	Stories = 2,
	KPIStreams = 3,
	Canvases = 6,
	Modules = 5,
};

export default PageType;

export const pageTypeToSection: Readonly<{[key in PageType]: string}> = {
	[PageType.Insights]: "Insights",
	[PageType.Stories]: "Stories",
	[PageType.KPIStreams]: "KPIStream",
	[PageType.Canvases]: "Canvas",
	[PageType.Modules]: "Modules"
};
