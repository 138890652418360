import React, { useState } from "react";
import {
    Cell,
    ErrorBar,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    Label,
} from "recharts";
import CustomizedAxisTick from "./CustomizedAxisTick";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import { getValueFillColor, StatusBarExpression } from "common/Canvas";
// @ts-ignore
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import {
    BarChartTheme,
    getGridColorByTheme,
    getDefaultColorsByTheme,
    getOptionsByTheme,
} from "./BarChartTheme";
import { TooltipStyles } from "./TooltipStyles";

interface Props {
    statusExpressions?: StatusBarExpression[];
    barIndices?: number[];
    linesCount?: number;
    minYRange?: number;
    maxYRange?: number;
    axesColor?: string;
    errorBarColor?: string;
    showError?: boolean;
    nameMapping?: { [key: string]: string };
    colorMapping?: { [key: string]: string };
    nameColorMapping?: { [key: string]: string };
    labelMapping?: { [key: string]: string };
    data: Array<{ name: string; units?: string } & { [key: string]: number }>;
    barKeys: string[];
    barColors: string[];
    title: string;
    maxChartValue?: number;
    watermark?: string;
    showUnits?: boolean;
    barSize?: number;
    barGap?: number;
    barCategoryGap?: number;
    chartType?: string;
    chartTheme?: BarChartTheme;
    baseBackgroundColor?: string;
}

interface TooltipProps {
    barIndices: number[];
    statusExpressions: StatusBarExpression[];
    payload:
        | {
              value: number;
              color: string;
          }[]
        | null;
}

interface ColorByNameTooltipProps {
    nameColorMapping?: { [key: string]: string };
    payload:
        | {
              payload: { name: string };
              value: number;
              color: string;
          }[]
        | null;
}

const ColorByNameTooltip = (props: any & ColorByNameTooltipProps) => {
    if (props.active) {
        if (props.payload && props.payload.length > 0) {
            let newPayload: {
                value: number;
                color: string;
                payload: { name: string };
            }[] = Array.from(props.payload);
            for (let i = 0; i < newPayload.length; ++i) {
                let expressionColor =
                    props.nameColorMapping[newPayload[i].payload.name];
                newPayload[i].color = expressionColor ?? newPayload[i].color;
            }
            return <DefaultTooltipContent {...props} payload={newPayload} />;
        }
    }
    return <DefaultTooltipContent {...props} />;
};

const CustomTooltip = (props: any & TooltipProps) => {
    if (props.active) {
        if (props.payload && props.payload.length > 0) {
            let newPayload: {
                value: number;
                color: string;
            }[] = Array.from(props.payload);
            for (let i = 0; i < newPayload.length; ++i) {
                let expressionColor = getValueFillColor(
                    newPayload[i].value,
                    props.barIndices[i],
                    props.statusExpressions
                );
                newPayload[i].color = expressionColor ?? newPayload[i].color;
            }
            return <DefaultTooltipContent {...props} payload={newPayload} />;
        }
    }

    return <DefaultTooltipContent {...props} />;
};

function CustomBarChart(props: Props) {
    let [showZeroLine, setShowZeroLine] = useState<boolean | null>(null);
    let barSize = props.barSize ?? 10;
    let barCategoryGap = props.barCategoryGap ?? 120;
    let barGap = props.barGap ?? 4;
    let barCountInGroup =
        props.chartType === "stacked" ? 1 : props.barKeys.length;
    var barChartWidth: number =
        props.data.length > 0
            ? mobileBreakpoint()
                ? window.screen.width * 2 - 20
                : 100 +
                  ((barSize + barGap) * barCountInGroup + barCategoryGap) *
                      props.data.length
            : 800;
    // barChartWidth = Math.max(barChartWidth, 500);
    var axisFontSize: number = 10;
    var titleSize: number = 12;
    let defaultColors = getDefaultColorsByTheme(props.chartTheme);
    let themeOptions = getOptionsByTheme(props.chartTheme);
    let gridFillColor = getGridColorByTheme(
        props.chartTheme,
        props.baseBackgroundColor
    );
    return (
        <div style={{ height: "100%", paddingRight: 75 }}>
            <span
                style={{
                    fontFamily: "Arial",
                    fontSize: titleSize,
                    color: mainStyle.getPropertyValue(
                        "--exploration-secondary-text-color"
                    ),
                    display: "block",
                    textAlign: "center",
                }}
            >
                {props.title}
            </span>
            <ResponsiveContainer
                width={barChartWidth}
                height={mobileBreakpoint() ? 250 : undefined}
            >
                <BarChart
                    ref={(ref) => {
                        let yAxisMapItem = (ref?.state as any)?.yAxisMap?.[0];
                        if (yAxisMapItem != null) {
                            let niceTicks: number[] =
                                (yAxisMapItem as any)?.niceTicks ?? [];
                            let newShowZeroLine = !niceTicks.includes(0);
                            if (newShowZeroLine !== showZeroLine)
                                setShowZeroLine(newShowZeroLine);
                        }
                    }}
                    barSize={barSize}
                    barGap={barGap}
                    barCategoryGap={barCategoryGap}
                    data={props.data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 20,
                        bottom: 10,
                    }}
                >
                    {props.watermark != null && (
                        <text
                            x="50%"
                            y="30%"
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill={mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            )}
                            fontSize={
                                String(Object.keys(props.data).length - 1) +
                                "rem"
                            }
                            style={{
                                opacity: 0.1,
                            }}
                        >
                            {props.watermark}
                        </text>
                    )}
                    <CartesianGrid
                        stroke={defaultColors.gridColor}
                        strokeDasharray={
                            themeOptions.dashGrid ? "3 3" : undefined
                        }
                        strokeWidth={1}
                        fill={gridFillColor}
                    />

                    <XAxis
                        axisLine={themeOptions.showAxesLines}
                        tickLine={themeOptions.showTickLines}
                        interval={0}
                        tick={
                            <CustomizedAxisTick
                                axesColor={props.axesColor}
                                labelMapping={props.labelMapping}
                                formatValues={true}
                                truncValues={false}
                                fontSize={axisFontSize}
                                dx={16}
                                dy={16}
                                maxLength={12}
                                angle={-15}
                            />
                        }
                        height={50}
                        dataKey="name"
                        stroke={defaultColors.axesLinesColor}
                    />
                    <YAxis
                        type="number"
                        axisLine={themeOptions.showAxesLines}
                        tickLine={themeOptions.showTickLines}
                        tickCount={props.linesCount ?? undefined}
                        interval={0}
                        domain={[
                            props.minYRange ??
                                ((dataMin: number) => {
                                    if (Number.isFinite(dataMin))
                                        return Math.min(
                                            dataMin - Math.abs(dataMin) * 0.1,
                                            0
                                        );
                                    return 0;
                                }),
                            props.maxYRange ??
                                (props.maxChartValue
                                    ? Math.max(
                                          0,
                                          props.maxChartValue +
                                              Math.abs(props.maxChartValue) *
                                                  0.1
                                      )
                                    : (dataMax: number) => {
                                          if (Number.isFinite(dataMax))
                                              return Math.max(
                                                  dataMax +
                                                      Math.abs(dataMax) * 0.1,
                                                  0
                                              );
                                          return 0;
                                      }),
                        ]}
                        allowDataOverflow={
                            props.minYRange != null || props.maxYRange != null
                        }
                        tick={
                            <CustomizedAxisTick
                                axesColor={props.axesColor}
                                unit={
                                    props.showUnits && props.data.length > 0
                                        ? props.data[0].units
                                        : undefined
                                }
                                formatValues={true}
                                truncValues={false}
                                fontSize={axisFontSize}
                                dx={0}
                                dy={0}
                            />
                        }
                        stroke={defaultColors.axesLinesColor}
                    />
                    {showZeroLine && (
                        <ReferenceLine
                            y={0}
                            stroke={defaultColors.axesLinesColor}
                            strokeWidth={2}
                            label={
                                <Label
                                    dy={-5}
                                    offset={8}
                                    value={"0.00"}
                                    position="left"
                                    fontSize={axisFontSize}
                                    fill={
                                        props.axesColor ??
                                        mainStyle.getPropertyValue(
                                            "--graphs-axes-text-color"
                                        )
                                    }
                                />
                            }
                        />
                    )}
                    <Tooltip
                        labelFormatter={(name) =>
                            props.labelMapping?.[name] ?? name
                        }
                        content={
                            props.nameColorMapping != null ? (
                                <ColorByNameTooltip
                                    nameColorMapping={props.nameColorMapping}
                                />
                            ) : props.barIndices != null &&
                              props.statusExpressions != null ? (
                                <CustomTooltip
                                    barIndices={props.barIndices}
                                    statusExpressions={props.statusExpressions}
                                />
                            ) : undefined
                        }
                        formatter={(value: any, _name: any, props: any) => {
                            if (
                                typeof value === "string" ||
                                typeof value === "number"
                            ) {
                                let formattedValue = formatValue(value, false);
                                return [
                                    (props.showUnits &&
                                    props.payload.units !== "%"
                                        ? props.payload.units ?? ""
                                        : ""
                                    )
                                        .concat(formattedValue[0])
                                        .concat(formattedValue[1])
                                        .concat(
                                            props.showUnits &&
                                                props.payload.units === "%"
                                                ? props.payload.units ?? ""
                                                : ""
                                        ),
                                    props.nameMapping?.[_name] ?? _name,
                                ];
                            }
                        }}
                        cursor={false}
                        {...TooltipStyles()}
                    />
                    {props.barKeys.map((barKey, index) => {
                        return (
                            <Bar
                                isAnimationActive={false}
                                key={index}
                                dataKey={barKey}
                                stackId={
                                    props.chartType === "stacked"
                                        ? "a"
                                        : undefined
                                }
                                fill={
                                    props.colorMapping?.[barKey] ??
                                    props.barColors[
                                        index % props.barColors.length
                                    ]
                                }
                            >
                                {props.data.map((entry, dataIndex) => {
                                    let expressionColor = null;
                                    if (props.nameColorMapping != null) {
                                        expressionColor =
                                            props.nameColorMapping[entry.name];
                                    }
                                    if (
                                        props.statusExpressions != null &&
                                        props.barIndices != null
                                    ) {
                                        let barIndex = props.barIndices[index];
                                        let value = entry[barKey];
                                        expressionColor = getValueFillColor(
                                            value,
                                            barIndex,
                                            props.statusExpressions
                                        );
                                    }
                                    let fillColor =
                                        expressionColor ??
                                        props.colorMapping?.[barKey] ??
                                        props.barColors[
                                            index % props.barColors.length
                                        ];
                                    return (
                                        <Cell
                                            key={`cell-${dataIndex}`}
                                            fill={fillColor}
                                        />
                                    );
                                })}
                                {props.chartType !== "stacked" &&
                                    props.showError && (
                                        <ErrorBar
                                            dataKey={"%error_".concat(barKey)}
                                            width={4}
                                            strokeWidth={2}
                                            stroke={
                                                props.errorBarColor ?? "black"
                                            }
                                        />
                                    )}
                            </Bar>
                        );
                    })}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default CustomBarChart;
