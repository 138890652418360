import { observable, computed, makeObservable, action } from "mobx";

import axios from "./ServerConnection";

export interface Table {
    table_id: number[];
    name: string[];
    condition_id?: string;
    condition?: string;
}

export interface TableOption {
    label: string;
    value: number[];
    data_table_idx: number | string | undefined;
    optimized: boolean;
    panels?: string[];
    condition_id?: string;
    condition?: string;
}

class TablesInner {
    @observable public aggregateTables: Table[] = [];
    @observable public optimizedTables: Table[] = [];
    @observable public initializedState: boolean = false;
    private dataScopeId: number | string | undefined;
    public loadingState: boolean = false;

    constructor(
        dataScopeId: number | string | undefined,
        moduleId?: number | string | null
    ) {
        makeObservable(this);
        this.dataScopeId = dataScopeId;
        this.update(moduleId);
    }

    @action.bound
    assignTables(aggregateTables: Table[], optimizedTables: Table[]) {
        this.aggregateTables = aggregateTables;
        this.optimizedTables = optimizedTables;
        this.initializedState = true;
        this.loadingState = false;
    }

    public tableToOption(table?: Table): TableOption {
        if (table == null)
            return {
                label: "Base table",
                value: [],
                optimized: false,
                data_table_idx: this.dataScopeId,
            };
        else if (table.table_id.length === 0)
            return {
                label: `Base table${" " + table.condition ?? ""}`,
                value: [],
                optimized: false,
                condition: table.condition,
                condition_id: table.condition_id,
                data_table_idx: this.dataScopeId,
            };
        else {
            return {
                label: `Aggregate by ${table.name.join(", ")} ${
                    table.condition ?? ""
                }`,
                value: table.table_id,
                optimized: false,
                condition: table.condition,
                condition_id: table.condition_id,
                data_table_idx: this.dataScopeId,
            };
        }
    }

    public tableToOptionOptimized(table?: Table): TableOption {
        if (table == null)
            return {
                label: "Base table optimized",
                value: [],
                optimized: true,
                data_table_idx: this.dataScopeId,
            };
        if (table.table_id.length === 0)
            return {
                label: `Base table optimized${" " + table.condition ?? ""}`,
                value: [],
                optimized: true,
                condition: table.condition,
                condition_id: table.condition_id,
                data_table_idx: this.dataScopeId,
            };
        else {
            return {
                label: `Optimized by ${table.name.join(", ")} ${
                    table.condition ?? ""
                }`,
                value: table.table_id,
                optimized: true,
                condition: table.condition,
                condition_id: table.condition_id,
                data_table_idx: this.dataScopeId,
            };
        }
    }
    @computed public get initialized(): boolean {
        return this.initializedState;
    }
    @computed public get optimizedTableOptions(): TableOption[] {
        return this.optimizedTables.map((table) =>
            this.tableToOptionOptimized(table)
        );
    }

    @computed public get aggregateTableOptions(): TableOption[] {
        return this.aggregateTables.map((table) => this.tableToOption(table));
    }

    @computed public get rawAndOptimizedTableOptions(): TableOption[] {
        return this.optimizedTableOptions;
    }

    @computed public get rawAndAggregateTableOptions(): TableOption[] {
        return [this.tableToOption(), ...this.aggregateTableOptions];
    }

    public async update(moduleId?: number | string | null): Promise<void> {
        if (this.dataScopeId != null && this.dataScopeId !== '') {
            this.loadingState = true;
            return axios
                .post<{
                    success: boolean;
                    error_msg: string;
                    aggregate_list?: Table[];
                    optimize_list?: Table[];
                }>("/api/e/get_list_of_aggregate_tables", {
                    data_table_idx: this.dataScopeId,
                    with_optimized: true,
                    with_sliced: true,
                    module_id: moduleId,
                })
                .then((response) => {
                    if (
                        response.data.success &&
                        response.data.aggregate_list != null &&
                        response.data.optimize_list != null
                    ) {
                        this.assignTables(
                            response.data.aggregate_list,
                            response.data.optimize_list
                        );
                    } else {
                        this.loadingState = false;

                        console.log(response.data.error_msg);
                    }
                })
                .catch((error) => {
                    this.loadingState = false;

                    console.log(error);
                });
        } else return Promise.resolve();
    }
}

let tablesMap: {
    [key in number | string]: TablesInner;
} = {};

export default function Tables(
    dataScopeId: number | string,
    moduleId?: number | string | null
): TablesInner {
    if (!(dataScopeId in tablesMap)) {
        tablesMap[dataScopeId] = new TablesInner(dataScopeId, moduleId);
    } else if (
        !tablesMap[dataScopeId].initializedState &&
        !tablesMap[dataScopeId].loadingState
    ) {
        tablesMap[dataScopeId].update(moduleId);
    }
    return tablesMap[dataScopeId];
}
