import {
	CanvasNode,
	isSlider,
	isTextBox,
	isToggle,
	isDropdownSelector,
	isBarcodeReader,
	isProgressElement,
	isRadioButtonsGroup,
	isSurvey,
} from "common/Canvas";
import {
	toggleDefaultWidth,
	tagDefaultHeight,
	tagDefaultWidth,
	dropdownSelectorDefaultLiveHeight,
	dropdownSelectorDefaultWidth,
	barcodeReaderDefaultHeight,
	barcodeReaderDefaultWidth,
	progressElementDefaultHeight,
	progressElementDefaultWidth,
	radioButtonsGroupDefaultWidth,
	radioButtonsGroupDefaultHeight,
	surveyElementDefaultWidth,
	surveyElementDefaultHeight,
} from "../Constants";

function defaultBoxSize() {
	return {
		width: 125,
		height: 46,
	};
}

interface NodeData {
	allowResize: boolean,
	nodeSize: {
		desktop: {
			width: number,
			height: number,
		},
		mobile: {
			width: number,
			height: number,
		}
	}
}

export function getNodeSize(
	node: CanvasNode,
	canvasViewMode: keyof typeof nodeData.nodeSize = "desktop",
): NodeData {

	const nodeData: NodeData = {
		allowResize: true,
		nodeSize: {
			desktop: {
				...defaultBoxSize()
			},
			mobile: {
				...defaultBoxSize()
			}
		}
	}

	const shapeOptions = node.shapeOptions ?? { desktop: {}, mobile: {} };
	let scaleX = shapeOptions[canvasViewMode]?.scaleX ?? 1;
	let scaleY = shapeOptions[canvasViewMode]?.scaleY ?? 1;

	if (
		!isSurvey(node) &&
		!isTextBox(node) &&
		!isDropdownSelector(node) &&
		!isBarcodeReader(node) &&
		!isRadioButtonsGroup(node) &&
		!isProgressElement(node)
	) {
		
		let rectWidth: number = isToggle(node)
			? toggleDefaultWidth
			: defaultBoxSize().width ?? 1;
		let rectHeight: number = defaultBoxSize().height;

		if (isSlider(node) && node.vertical) {
			[rectWidth, rectHeight] = [rectHeight, rectWidth];
		}

		nodeData.nodeSize[canvasViewMode].width = rectWidth * scaleX;
		nodeData.nodeSize[canvasViewMode].height = rectHeight * scaleY;
		return nodeData;
	}
	
	if (isTextBox(node)) {
		let tagNodeSize = node.nodeSize ?? {
			desktop: { width: tagDefaultWidth, height: tagDefaultHeight },
			mobile: { width: tagDefaultWidth, height: tagDefaultHeight },
		}
		nodeData.nodeSize[canvasViewMode].width = tagNodeSize[canvasViewMode].width;
		nodeData.nodeSize[canvasViewMode].height = tagNodeSize[canvasViewMode].height;
		
		return nodeData;
	}
	
	if (isBarcodeReader(node)) {
		nodeData.nodeSize[canvasViewMode].width = barcodeReaderDefaultWidth * scaleX;
		nodeData.nodeSize[canvasViewMode].height = barcodeReaderDefaultHeight * scaleY;
		return nodeData;
	} 

	if (isProgressElement(node)) {
		nodeData.nodeSize[canvasViewMode].width = progressElementDefaultWidth * scaleX;
		nodeData.nodeSize[canvasViewMode].height = progressElementDefaultHeight * scaleY;
		return nodeData;
	} 

	if (isRadioButtonsGroup(node)) {
		nodeData.nodeSize[canvasViewMode].width = radioButtonsGroupDefaultWidth * scaleX;
		nodeData.nodeSize[canvasViewMode].height = radioButtonsGroupDefaultHeight * scaleY;
		return nodeData;
	} 

	if (isSurvey(node)) {
		nodeData.nodeSize[canvasViewMode].width = surveyElementDefaultWidth * scaleX;
		nodeData.nodeSize[canvasViewMode].height = surveyElementDefaultHeight * scaleY;
		return nodeData;
	} 

	let nodeSize = node.nodeSize ?? {
		desktop: { width: dropdownSelectorDefaultWidth, height: dropdownSelectorDefaultLiveHeight },
		mobile: { width: dropdownSelectorDefaultWidth, height: dropdownSelectorDefaultLiveHeight },
	}

	nodeData.nodeSize[canvasViewMode].width = nodeSize[canvasViewMode].width;
	nodeData.nodeSize[canvasViewMode].height = nodeSize[canvasViewMode].height;
	return nodeData;
}

export function getDefaultNodeSize(
	node: CanvasNode
): {
	width: number;
	height: number;
} {
	if (
		!isTextBox(node) &&
		!isDropdownSelector(node) &&
		!isBarcodeReader(node) &&
		!isRadioButtonsGroup(node) &&
		!isProgressElement(node)
	) {
		let rectWidth: number = isToggle(node)
			? toggleDefaultWidth
			: defaultBoxSize().width;
		let rectHeight: number = defaultBoxSize().height;

		if (isSlider(node) && node.vertical) {
			[rectWidth, rectHeight] = [rectHeight, rectWidth];
		}
		return {
			width: rectWidth,
			height: rectHeight,
		};
	} else if (isTextBox(node)) {
		return {
			width: tagDefaultWidth,
			height: tagDefaultHeight,
		};
	} else if (isRadioButtonsGroup(node)) {
		return {
			width: radioButtonsGroupDefaultWidth,
			height: radioButtonsGroupDefaultHeight,
		};
	} else if (isBarcodeReader(node)) {
		return {
			width: barcodeReaderDefaultWidth,
			height: barcodeReaderDefaultHeight,
		};
	} else if (isProgressElement(node)) {
		return {
			width: progressElementDefaultWidth,
			height: progressElementDefaultHeight,
		};
	} else if (isSurvey(node)) {
		return {
			width: surveyElementDefaultWidth,
			height: surveyElementDefaultHeight,
		};
	} else {
		return {
			width: dropdownSelectorDefaultWidth,
			height: dropdownSelectorDefaultLiveHeight,
		};
	}
}