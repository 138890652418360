import React, { Component } from "react";
import { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button } from "react-bootstrap";
import Draggable from "react-draggable";
import Popup from "reactjs-popup";
import { reaction, IReactionDisposer } from "mobx";
import { observer } from "mobx-react";
import {
    CanvasSpreadSheetGrid,
    CanvasSimpleSpreadSheetInput,
} from "common/Canvas";
import CanvasTreeStore from "../CanvasTreeStore";
import customSelectStyles from "common/SelectStyles";
import DataScopes, {
    DataScopeOption,
    DataScopeOptionWithNull,
} from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import { Permission } from "common/Permissions";
import {
    initializeSchema,
    saveSimpleSpreadSheet,
} from "./SpreadSheetStreamApi";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import remoteModuleId from "common/remoteModuleId";
import { lightThemeStyle } from "common/LightThemeStyle";


interface Props {
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
    spreadSheetGrid: CanvasSpreadSheetGrid;
    nodes: CanvasSimpleSpreadSheetInput[];
    onClose: () => void;
}

interface State {
    left: number;
    top: number;
    popupMessage: string;
    popupStatus: PopupStatus | null;
    newDataScope: DataScopeOptionWithNull | null;
    dataScope: DataScopeOptionWithNull | undefined;
    inputValue: string;
    selectCompIsOpened: boolean;
}

@observer
class SpreadSheetSaveDataPopup extends Component<Props, State> {
    dataScopesReaction: IReactionDisposer | null = null;
    drag: boolean = false;

    constructor(props: Props) {
        super(props);
        let currentDataScopeId = this.props.spreadSheetGrid
            ?.fullSpreadSheetBackendOutputOptions?.dataScopeId;
        this.state = {
            left: 0,
            top: 0,
            dataScope:
                currentDataScopeId != null
                    ? {
                          value: currentDataScopeId,
                          label: "",
                          permissionType: Permission.ReadOnly,
                        //   LastUpdated:"",
                        //   Uploaded:"",
                        //   owner:"",
                      }
                    : undefined,
            newDataScope: null,
            popupStatus: null,
            popupMessage: "",
            inputValue: "",
            selectCompIsOpened: false,
        };
        this.createOrReplaceSimpleSpreadSheetClick = this.createOrReplaceSimpleSpreadSheetClick.bind(
            this
        );
    }

    private findDataScope(): void {
        if (this.state.dataScope?.value != null) {
            const dataScopes =
                this.props.currentModuleId == null
                    ? DataScopes
                    : DataScopesForModules(this.props.currentModuleId);
            let dataScopeOption = dataScopes.dataScopesOptions.filter(
                (option) => option.value === this.state.dataScope?.value
            )[0];
            if (dataScopeOption != null) {
                this.setState({ dataScope: dataScopeOption });
            }
        }
    }

    public componentDidMount(): void {
        const dataScopes =
            this.props.currentModuleId == null
                ? DataScopes
                : DataScopesForModules(this.props.currentModuleId);
        this.dataScopesReaction = reaction(
            () => dataScopes.dataScopesOptions,
            () => {
                this.findDataScope();
            }
        );
        this.findDataScope();
    }

    public componentWillUnmount(): void {
        if (this.dataScopesReaction != null) {
            this.dataScopesReaction();
        }
    }

    private createOrReplaceSimpleSpreadSheetClick(): void {
        if (this.state.dataScope == null) return;
        let dataScopeOption: DataScopeOption = {
            label: this.state.dataScope.label,
            value: this.state.dataScope.value ?? NaN,
            permissionType: this.state.dataScope.permissionType,
            // LastUpdated:this.state.dataScope.LastUpdated ?? new Date().toString(),
            // Uploaded:this.state.dataScope.Uploaded ?? new Date().toString(),
            // owner:this.state.dataScope.owner?? "0",
        };
        let schema = initializeSchema(
            this.props.spreadSheetGrid,
            this.props.nodes,
            this.props.canvasTreeStore,
        );
        if (!schema.success) {
            this.setState({
                popupMessage: schema.errorMessage!,
                popupStatus: PopupStatus.Error,
            });
            return;
        }
        saveSimpleSpreadSheet(
            this.props.spreadSheetGrid,
            this.props.nodes,
            schema.names,
            schema.types,
            schema.panels,
            schema.levels,
            schema.formats,
            schema.units,
            dataScopeOption,
            // Do not change permissions for existing data sets,
            // set them to user's personal group for new data sets.
            undefined,
            (dataTableIdx: string | number) => {
                this.props.canvasTreeStore.readDataIntoSpreadSheet(
                    this.props.spreadSheetGrid.id,
                    dataTableIdx,
                    null,
                    this.props.spreadSheetGrid.rows,
                    true,
                    false,
                    undefined,
                    undefined,
                    undefined
                );
                this.setState(
                    {
                        popupMessage: "Data Saved",
                        popupStatus: PopupStatus.Success,
                    },
                    () => {
                        setTimeout(this.props.onClose, 200);
                    }
                );
            },
            (errorMessage) => {
                this.setState({
                    popupMessage: errorMessage.toString(),
                    popupStatus: PopupStatus.Error,
                });
            },
            this.props.currentModuleId ?? remoteModuleId
        );
    }

    private buildInnerView(): JSX.Element {
        const dataScopes =
            this.props.currentModuleId == null
                ? DataScopes
                : DataScopesForModules(this.props.currentModuleId);
        const popup = document.querySelector(".popup-overlay");
        return (
            <div
                className="flex-simple-column"
                style={{
                    padding: "0 16px 21px 28px",
                }}
            >
                <span
                    className="content-regular-text"
                    style={{
                        color: "#4D5966",
                        display: "block",
                        width: "calc(100% + 44px)",
                        fontSize: "18px",
                        padding: "18px 22px 19px 28px",
                        borderBottom: "1px solid #D1D1D1",
                        margin: "0 -16px 0 -28px",
                        backgroundColor: "#fff",
                    }}
                >
                    Name Data Set
                </span>
                <CreatableSelect
                    inputId="select-data-scope"
                    createOptionPosition="first"
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    menuIsOpen={this.state.selectCompIsOpened}
                    onMenuOpen={() => {
                        if (this.state.dataScope) {
                            this.setState({
                                inputValue: this.state.dataScope?.label ?? "",
                            })
                        }
                        if (!this.drag)
                            this.setState({
                                selectCompIsOpened: true,
                            })
                    }}
                    onInputChange={(text) => {
                        this.setState({
                            inputValue: text
                        })
                    }}
                    onMenuClose={() => {
                        this.setState({
                            selectCompIsOpened: false,
                        });
                    }}
                    inputValue={this.state.inputValue}
                    onCreateOption={(option) => {
                        this.setState({
                            newDataScope: {
                                label: option,
                                value: null,
                                permissionType: Permission.ReadWrite,
                                // LastUpdated:"",
                                // Uploaded:"",
                                // owner:"",
                            },
                            dataScope: {
                                label: option,
                                value: null,
                                permissionType: Permission.ReadWrite,
                                // LastUpdated:"",
                                // Uploaded:"",
                                // owner:"",
                            },
                        });
                    }}
                    placeholder={"Create new or replace existing data set"}
                    styles={{
                        ...customSelectStyles,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                            height: "38px",
                            marginTop: "19px",
                            marginBottom: "15px",
                        }),
                        input: (base) => ({
                            ...base,
                            paddingLeft: 8,
                        }),
                        placeholder: (base) => ({
                            ...base,
                            paddingLeft: 8,
                        }),
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 1000,
                        }),
                    }}
                    options={(dataScopes.dataScopesOptions as DataScopeOptionWithNull[])
                        .filter(
                            (option) =>
                                option.permissionType === Permission.ReadWrite
                        )
                        .concat(
                            this.state.newDataScope != null
                                ? [this.state.newDataScope]
                                : []
                        )}
                    onChange={(newValue) => {
                        let stateDiff: any = {
                            dataScope: newValue as DataScopeOptionWithNull,
                            permissions: [],
                            inputValue: "",
                        };

                        this.setState(stateDiff);
                    }}
                    value={this.state.dataScope}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                    menuPortalTarget={popup as HTMLElement}
                />

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        marginTop: "15px",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-lg my-outline"
                        style={{
                            fontSize: "12px",
                            width: "6em",
                            fontWeight: 500,
                            height: "33px",
                            paddingTop: 0,
                            paddingBottom: 0,
                            backgroundColor:
                                lightThemeStyle.cancelButtonBackgroundColor,
                            borderColor: "#E0E0E0",
                            color: "#3474B2",
                        }}
                        onClick={this.props.onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            fontSize: "12px",
                            width: "6em",
                            fontWeight: 500,
                            height: "33px",
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginLeft: "5px",
                        }}
                        disabled={this.state.dataScope == null}
                        onClick={this.createOrReplaceSimpleSpreadSheetClick}
                    >
                        Accept
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const width: number = 479;
        return (
            <>
                <Popup
                    arrow={true}
                    contentStyle={{
                        width: width,
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    open={true}
                    onClose={() => {}}
                    closeOnDocumentClick={false}
                >
                    <Draggable
                        cancel="input"
                        position={{
                            x: this.state.left,
                            y: this.state.top,
                        }}
                        onDrag={() => {
                            this.drag = true;
                            this.setState({
                                selectCompIsOpened: false,
                            })
                        }}
                        onStop={(_evt, data) => {
                            if (this.drag) {
                                this.drag = false;
                                this.setState({
                                    left: data.x,
                                    top: data.y,
                                });
                            }
                        }}
                    >
                        <div
                            className="dashboard-rect element"
                            style={{
                                overflowX: "visible",
                                overflowY: "auto",
                                borderRadius: 6,
                                alignItems: "center",
                                border: "1px solid #D1D1D1",
                                backgroundColor: "#F8F8F8",
                                maxHeight: "100vh",
                                width: width,
                                boxShadow: "none",
                            }}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            {this.buildInnerView()}
                        </div>
                    </Draggable>
                </Popup>
                {this.state.popupStatus != null && (
                    <StatusPopup
                        onClose={() => {
                            this.setState({
                                popupMessage: "",
                                popupStatus: null,
                            });
                        }}
                        status={this.state.popupStatus}
                        message={this.state.popupMessage}
                    />
                )}
            </>
        );
    }
}

export default SpreadSheetSaveDataPopup;
