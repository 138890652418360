import React from "react";
import Select, { createFilter } from "react-select";
import { BubbleFinding } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import styles from "./BubbleSection.module.css";
import ribbonStyles from "../../ChartsRibbon.module.css";
import { Accordion, Button } from "react-bootstrap";
import EditInput from "../EditInput";
import cx from "classnames";
import Switch from "react-switch";
import switchStyleProps from "../../SwitchStyleProps";
import StringUtils from "common/utilities/StringUtils";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import MagicWand from "../MagicWand";
import { DynamicOptionType } from "common/DynamicOptions";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import Variables, { VariableOption } from "common/Variables";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

enum BubbleMenuOptions {
    None = 0,
    Time = 1,
}

interface Props {
    columnDragActive: boolean;
    finding: BubbleFinding;
    onChange: (finding: BubbleFinding, updateData?: boolean) => void;
    onClearEditing: () => void;
    dashboardId: string;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
}

export default function BubbleSection(props: Props) {
    let [menuOptionSelected, setMenuOptionSelected] = React.useState(
        BubbleMenuOptions.None
    );
    let [maxRowCount, setMaxRowCount] = React.useState(3);
    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    let variableOptions: VariableOption[] = [];
    if (props.finding.config.dataScope != null) {
        variableOptions = Variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions;
    }

    let data = props.finding.content.data;
    let currentEditVariableIndex: number | undefined = undefined;
    if (props.finding.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = data.findIndex(
            (item) => item.variableIndex == null
        );
    }
    let xAxisName = props.finding.content.data[0].name;
    let yAxisName = props.finding.content.data[1].name;
    let valueName = props.finding.content.data[2].name;
    let datasetIsConnected = props.finding.config.dataScope != null;
    let restCount = data[0].value.length - maxRowCount;
    let totalCount = data[0].value.length;
    return (
        <div>
            <div className="my-row">
                <MagicWand
                    minCount={2}
                    maxCount={2}
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    allowMultiple={true}
                    title={"Variables"}
                    optionName="data"
                    canvasTreeStore={props.canvasTreeStore}
                    type={DynamicOptionType.DataVariable}
                />
                <div className={styles.dataContainer}>
                    <div className={commonStyles.header}></div>
                    <div
                        className={cx(commonStyles.header, {
                            [commonStyles.highlightValueVerticalTop]:
                                currentEditVariableIndex === 0,
                        })}
                    >
                        {xAxisName}
                    </div>
                    <div
                        className={cx(commonStyles.header, {
                            [commonStyles.highlightValueVerticalTop]:
                                currentEditVariableIndex === 1,
                        })}
                    >
                        {yAxisName}
                    </div>
                    <div
                        className={cx(commonStyles.header, {
                            [commonStyles.highlightValueVerticalTop]:
                                currentEditVariableIndex === 2,
                        })}
                    >
                        {valueName}
                    </div>
                    {(datasetIsConnected
                        ? data[0].value.slice(0, maxRowCount)
                        : data[0].value
                    ).map((_, index, array) => (
                        <React.Fragment key={index}>
                            <EditInput
                                showDeleteButton={
                                    props.finding.config.dataScope == null
                                }
                                disabled={true}
                                value={`Value ${index + 1}`}
                                onDelete={() => {
                                    let finding: BubbleFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: props.finding!.content.data.map(
                                                (item) => ({
                                                    ...item,
                                                    value: [...item.value],
                                                })
                                            ),
                                        },
                                    };
                                    finding.content.data.forEach((item) =>
                                        item.value.splice(index, 1)
                                    );
                                    props.onChange(finding);
                                }}
                            ></EditInput>
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVertical]:
                                        currentEditVariableIndex === 0,
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 0 &&
                                        index === array.length - 1 &&
                                        !(datasetIsConnected && restCount > 0),
                                })}
                                value={data[0].value[index]}
                                onChange={(value) => {
                                    let finding: BubbleFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: props.finding!.content.data.map(
                                                (item) => ({
                                                    ...item,
                                                    value: [...item.value],
                                                })
                                            ),
                                        },
                                    };
                                    finding.content.data[0].value[
                                        index
                                    ] = Number(value);
                                    props.onChange(finding);
                                }}
                            ></EditInput>
                            <EditInput
                                showDeleteButton={false}
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVertical]:
                                        currentEditVariableIndex === 1,
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 1 &&
                                        index === array.length - 1 &&
                                        !(datasetIsConnected && restCount > 0),
                                })}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[1].value[index]}
                                onChange={(value) => {
                                    let finding: BubbleFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: props.finding!.content.data.map(
                                                (item) => ({
                                                    ...item,
                                                    value: [...item.value],
                                                })
                                            ),
                                        },
                                    };
                                    finding.content.data[1].value[
                                        index
                                    ] = Number(value);
                                    props.onChange(finding);
                                }}
                            ></EditInput>
                            <EditInput
                                showDeleteButton={false}
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVertical]:
                                        currentEditVariableIndex === 2,
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 2 &&
                                        index === array.length - 1 &&
                                        !(datasetIsConnected && restCount > 0),
                                })}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[2].value[index]}
                                onChange={(value) => {
                                    let finding: BubbleFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: props.finding!.content.data.map(
                                                (item) => ({
                                                    ...item,
                                                    value: [...item.value],
                                                })
                                            ),
                                        },
                                    };
                                    finding.content.data[2].value[
                                        index
                                    ] = Number(value);
                                    props.onChange(finding);
                                }}
                            ></EditInput>
                        </React.Fragment>
                    ))}
                    {datasetIsConnected && restCount > 0 && (
                        <React.Fragment>
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={`+${restCount} values`}
                            ></EditInput>
                            <EditInput
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 0,
                                })}
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            ></EditInput>
                            <EditInput
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 1,
                                })}
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            ></EditInput>
                            <EditInput
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 2,
                                })}
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            ></EditInput>
                        </React.Fragment>
                    )}
                </div>
            </div>
            {totalCount > 3 && (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "5px 0 0 0",
                    }}
                >
                    <button
                            style={{
                                background: "transparent",
                                border: "none",
                            }}
                            onClick={() => setMaxRowCount(
                                maxRowCount === totalCount ? 3 : totalCount
                            )}
                        >
                            {maxRowCount === totalCount ? "Hide" : "Show more"}
                        </button>
                </div>
            )}
            {datasetIsConnected && (
                <>
                    <div className={styles.optionContainer} style={{margin: totalCount > 3 ? 0 : "20px 0 0 0"}}>
                        <span className={styles.optionName}>Trendline</span>
                        <Switch
                            checked={props.finding.config.trendline ?? false}
                            {...switchStyleProps}
                            onChange={() => {
                                let finding = {
                                    ...props.finding,
                                    config: {
                                        ...props.finding.config,
                                        trendline: !(
                                            props.finding.config.trendline ??
                                            false
                                        ),
                                    },
                                };
                                props.onChange(finding, true);
                            }}
                        />
                    </div>
                    <div className={styles.optionContainer}>
                        <span className={styles.optionName}>Random sample</span>
                        <Switch
                            checked={props.finding.config.random}
                            {...switchStyleProps}
                            onChange={() => {
                                let checked = props.finding.config.random;
                                let finding = {
                                    ...props.finding,
                                    config: {
                                        ...props.finding.config,
                                        random: !checked,
                                    },
                                };
                                props.onChange(finding, true);
                            }}
                        />
                        <EditInput
                            optionalStyles={{ marginLeft: 10, minWidth: 40 }}
                            optionalClassName={styles.input}
                            showDeleteButton={false}
                            disabled={false}
                            value={props.finding.config.count}
                            onChange={(value) => {
                                let finding: BubbleFinding = {
                                    ...props.finding!,
                                };
                                if (StringUtils.isNumber(value)) {
                                    let count = Number(value);
                                    if (count !== finding.config.count) {
                                        finding.config.count = count;
                                        props.onChange(finding, true);
                                    }
                                }
                            }}
                        ></EditInput>
                        <span
                            style={{ marginLeft: 10 }}
                            className={styles.optionName}
                        >
                            data points
                        </span>
                    </div>
                </>
            )}
            {props.finding.config.dataScope == null && (
                <Button
                    style={{ marginTop: 10 }}
                    className={cx(
                        "btn btn-sm btn-primary my-primary",
                        commonStyles.addItemButton
                    )}
                    onClick={() => {
                        let finding: BubbleFinding = {
                            ...props.finding!,
                        };
                        finding.content.data.forEach((item) =>
                            item.value.push(0)
                        );
                        props.onChange(finding);
                    }}
                >
                    {"\uFF0B Add Item"}
                </Button>
            )}
            <div style={{ marginTop: 10 }}>
                <Accordion
                    activeKey={String(menuOptionSelected)}
                    onSelect={(event: any) => {
                        setMenuOptionSelected(Number(event));
                    }}
                >
                    <Accordion.Toggle
                        eventKey={String(BubbleMenuOptions.Time)}
                        className={ribbonStyles.editMenuAdvancedOption}
                    >
                        Animation
                        <ChevronIcon
                            className={cx(
                                ribbonStyles.editMenuAdvancedOptionIcon,
                                {
                                    [ribbonStyles.chevronOpen]:
                                        menuOptionSelected ===
                                        BubbleMenuOptions.Time,
                                }
                            )}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(BubbleMenuOptions.Time)}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Select
                                isClearable
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder="Select variable"
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "100%",
                                        height: "38px",
                                        marginTop: "8px",
                                    }),
                                }}
                                options={variableOptions}
                                onChange={(newValue) => {
                                    let finding: BubbleFinding = {
                                        ...props.finding,
                                        content: {
                                            ...props.finding.content,
                                        },
                                    };
                                    if (
                                        props.finding.content.time != null &&
                                        newValue != null
                                    ) {
                                        finding.content.time = {
                                            ...props.finding.content.time!,
                                            variableIndex: (newValue as VariableOption)
                                                .value,
                                            originalName: (newValue as VariableOption)
                                                .label,
                                            name: (newValue as VariableOption)
                                                .label,
                                        };
                                    } else {
                                        finding.content.time = {
                                            name: "time",
                                            value: [],
                                            uniqueValues: [],
                                            variableIndex: null,
                                            originalName: null,
                                        };
                                    }
                                    props.onChange(finding, true);
                                }}
                                value={
                                    props.finding.content.time?.variableIndex !=
                                    null
                                        ? {
                                              label:
                                                  props.finding.content.time
                                                      .name,
                                              value:
                                                  props.finding.content.time
                                                      .variableIndex,
                                          }
                                        : null
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                    </Accordion.Collapse>
                </Accordion>
            </div>
        </div>
    );
}
