const aggregationOptions = [
    {
        label: "Mean",
        value: "Mean",
    },
    {
        label: "Sum",
        value: "Sum",
    },
    { label: "Count", value: "count" },
    { label: "Std", value: "std" },
    {
        label: "Min",
        value: "min",
    },
    { label: "Max", value: "max" },
];

export const advancedAggregationOptions = [
    ...aggregationOptions,
    {
        label: "Count distinct",
        value: "count_distinct",
    },
    {
        label: "Median",
        value: "median",
    },
    {
        label: "Mode",
        value: "mode",
    },
    {
        label: "List",
        value: "none",
    },
    {
        label: "Percentile",
        value: "percentile",
    },
    {
        label: "Correlation",
        value: "correlation",
    },
];

export default aggregationOptions;
