import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { NetworkFinding } from "common/Finding";
import remoteModuleId from "common/remoteModuleId";

const journeyNameToTypeMap: Readonly<{ [key: string]: string }> = {
    NetworkJourney: "network",
    ArcDiagramJourney: "arcdiagram",
    EdgeBundlingJourney: "edgebundling",
    MapsNetworkOverMapJourney: "maps_networkovermap",
};

class Api {
    static getDefaultConfig(journeyName: string): NetworkFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            timeAnimationSliderPosition: {
                x: 0,
                y: 0,
            },
            isAdditional: false,
        };
    }

    static getPreviewFinding(journeyName: string): NetworkFinding {
        let itemType = journeyNameToTypeMap[journeyName];
        let item = {
            type: itemType,
            content: {
                data:
                    itemType === "maps_networkovermap"
                        ? [
                              {
                                  source: 1,
                                  target: 2,
                                  weight: 1,
                                  y: 40.75918630136985,
                                  x: -73.98172397260265,
                              },
                              {
                                  source: 2,
                                  target: 3,
                                  weight: 1,
                                  y: 48.853399999999986,
                                  x: 2.3487999999999993,
                              },
                              {
                                  source: 3,
                                  target: 3,
                                  weight: 1,
                                  y: 52.365564197530865,
                                  x: 4.892701234567899,
                              },
                          ]
                        : [
                              {
                                  source: 1,
                                  target: 2,
                                  weight: 1,
                              },
                              {
                                  source: 2,
                                  target: 3,
                                  weight: 1,
                              },
                              {
                                  source: 3,
                                  target: 1,
                                  weight: 1,
                              },
                              {
                                  source: 1,
                                  target: 4,
                                  weight: 1,
                              },
                              {
                                  source: 2,
                                  target: 5,
                                  weight: 1,
                              },
                              {
                                  source: 3,
                                  target: 6,
                                  weight: 1,
                              },
                          ],
            },
            config: Api.getDefaultConfig(journeyName),
            additionalMapsFindings: [],
        };
        return item;
    }
    static getData(
        finding: NetworkFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<NetworkFinding> {
        if (
            finding.config.edgeSourceVariableIndex == null ||
            finding.config.edgeTargetVariableIndex == null
        ) {
            return Promise.resolve(finding);
        }

        let { dataScope, selectedTable, conditions } = finding.config ?? {};
        conditions = filterNonEmptyConditions(conditions ?? []);

        let variableIndices: Set<number> = new Set([
            finding.config.edgeSourceVariableIndex,
            finding.config.edgeTargetVariableIndex,
        ]);
        if (finding.config.edgeWeightVariableIndex != null) {
            variableIndices.add(finding.config.edgeWeightVariableIndex);
        }
        if (finding.type === "maps_networkovermap") {
            if (finding.config.xVariableIndex != null) {
                variableIndices.add(finding.config.xVariableIndex);
            }
            if (finding.config.yVariableIndex != null) {
                variableIndices.add(finding.config.yVariableIndex);
            }
        }

        let metrics: {
            name: string;
            variableIndex: number;
            originalName?: string;
        }[] =
            finding.config.metrics?.filter(
                (
                    metric: {
                        name: string;
                        variableIndex: number;
                        originalName?: string;
                    } | null
                ) => metric != null
            ) ?? [];

        for (let metric of metrics) {
            variableIndices.add(metric.variableIndex);
        }

        if (finding.config.timeVariableIndex != null) {
            variableIndices.add(finding.config.timeVariableIndex);
        }

        if (
            finding.config.varyNodeColorByVariable &&
            finding.config.nodeColorVariableIndex != null
        ) {
            variableIndices.add(finding.config.nodeColorVariableIndex);
        }

        if (
            finding.config.varyEdgeColorByVariable &&
            finding.config.edgeColorVariableIndex != null
        ) {
            variableIndices.add(finding.config.edgeColorVariableIndex);
        }

        let requestJson = {
            variable_indices: Array.from(variableIndices.values()),
            table: selectedTable?.value ?? [],
            condition_id: selectedTable?.condition_id,
            conditions: conditionsToJson(conditions),
            data_table_idx: dataScope == null ? 0 : dataScope.value,
            module_id: moduleId ?? remoteModuleId,
            use_indices_in_current_levels: true,
        };

        return axios
            .post<{
                success: boolean;
                error_msg: string;
                current_levels: {
                    [key: string]: (number | string | null)[];
                };
                row_id: number[];
                count?: number;
            }>("/api/e/get_raw_data", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newData: NetworkFinding["content"]["data"] = [];

                    let edgeSources =
                        response.data.current_levels[
                            finding.config.edgeSourceVariableIndex
                        ];
                    let edgeTargets =
                        response.data.current_levels[
                            finding.config.edgeTargetVariableIndex
                        ];
                    let edgeWeights =
                        finding.config.edgeWeightVariableIndex != null
                            ? (response.data.current_levels[
                                  finding.config.edgeWeightVariableIndex
                              ] as number[])
                            : [];

                    let x =
                        finding.config.xVariableIndex != null
                            ? (response.data.current_levels[
                                  finding.config.xVariableIndex
                              ] as number[])
                            : [];

                    let y =
                        finding.config.yVariableIndex != null
                            ? (response.data.current_levels[
                                  finding.config.yVariableIndex
                              ] as number[])
                            : [];

                    let time =
                        finding.config.timeVariableIndex != null
                            ? (response.data.current_levels[
                                  finding.config.timeVariableIndex
                              ] as (number | string)[])
                            : [];

                    let nodeColorKeys =
                        finding.config.varyNodeColorByVariable &&
                        finding.config.nodeColorVariableIndex != null
                            ? (response.data.current_levels[
                                  finding.config.nodeColorVariableIndex
                              ] as (number | string)[])
                            : [];

                    let edgeColorKeys =
                        finding.config.varyEdgeColorByVariable &&
                        finding.config.edgeColorVariableIndex != null
                            ? (response.data.current_levels[
                                  finding.config.edgeColorVariableIndex
                              ] as (number | string)[])
                            : [];

                    for (let i in edgeSources) {
                        newData.push({
                            source: edgeSources[i] as number,
                            target: edgeTargets[i] as number,
                            weight: edgeWeights[i] ?? 1,
                            metrics:
                                metrics.length !== 0
                                    ? Object.fromEntries(
                                          metrics.map((metric) => [
                                              metric.name,
                                              response.data.current_levels[
                                                  metric.variableIndex
                                              ][i],
                                          ])
                                      )
                                    : undefined,
                            time: time[i],
                            nodeColorKey: nodeColorKeys[i],
                            edgeColorKey: edgeColorKeys[i],
                            x: x[i],
                            y: y[i],
                        });
                    }

                    let newFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: newData,
                        },
                    };
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
