import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="15"
			height="24"
			viewBox="0 0 15 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#657D95"
			{...props}
		>
			<path
				d="M5.75 19.625L7.5 21.375L9.25 19.625"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.875 1.875H3.125C2.43464 1.875 1.875 2.43464 1.875 3.125V11.875C1.875 12.5654 2.43464 13.125 3.125 13.125H11.875C12.5654 13.125 13.125 12.5654 13.125 11.875V3.125C13.125 2.43464 12.5654 1.875 11.875 1.875Z"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}