import React, { CSSProperties } from "react";
import { CanvasBackground } from "common/Canvas";
import CanvasTreeStore from "../CanvasTreeStore";
import { MenuProps } from "./FlowChartProps";
import { DropdownMenu, DropdownItem } from "../DropdownMenu";
import { Dropdown } from "react-bootstrap";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import CanvasPreventPropagationButton from "../CanvasPreventPropagationButton";
import styles from "./DropdownMenu.module.css";

import { ReactComponent as HideIcon } from "icons/dropdown_node_menu/hide.svg";
import { ReactComponent as DeleteIcon } from "icons/dropdown_node_menu/delete.svg";

interface Props extends Omit<MenuProps, "onStartAdvancedEditing" | "canWrite"> {
    rootMenuTestId: string;
    canvasTreeStore: CanvasTreeStore;
    customStyle: CSSProperties;
    node: CanvasBackground;
    element: string;
    onToggle: (show: boolean) => void;
}

interface State {
    toggleOpen: boolean;
}

export default class DropdownBackgroundsMenu extends CanvasInteractionComponent<
    Props,
    State
> {
    constructor(props: Props) {
        super(props);
        this.state = {
            toggleOpen: false,
        };
    }

    render() {
        let node = this.props.node;
        return (
            <Dropdown
                data-test-id={this.props.rootMenuTestId}
                title="Options"
                style={{
                    width: 15 * (this.props.scale ?? 1),
                    height: 20 * (this.props.scale ?? 1),
                    backgroundColor: "transparent",
                    ...this.props.customStyle,
                }}
                show={this.state.toggleOpen}
                onToggle={(isOpen: boolean) => {
                    this.setState({ toggleOpen: isOpen });
                    this.props.onToggle(isOpen);
                }}
            >
                <CanvasPreventPropagationButton>
                    <Dropdown.Toggle
                        className={styles.hamburger}
                        data-test-id={`${this.props.rootMenuTestId}-dropdowntoggle`}
                        id={`dropdown_menu_${this.props.element}_${node.id}`}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "transparent",
                            border: "0",
                        }}
                    >
                        <span
                            className={styles.hamburger}
                            style={{
                                fontSize: 14 * (this.props.scale ?? 1),
                            }}
                        >
                            &#8942;
                        </span>
                    </Dropdown.Toggle>
                </CanvasPreventPropagationButton>
                <DropdownMenu noBorder>
                    <CanvasPreventPropagationButton>
                        <DropdownItem
                            dataTestId={`${this.props.rootMenuTestId}-deleteButton`}
                            highlightColor={"#39F"}
                            hoverColor={"#39F"}
                            title="Hide"
                            onClick={() => {
                                this.props.canvasTreeStore.toggleVisibilityBackgroundAction(
                                    node.id,
                                    true
                                );
                            }}
                            icon={
                                <HideIcon style={{ width: 14, height: 14 }} />
                            }
                        />
                    </CanvasPreventPropagationButton>
                    <CanvasPreventPropagationButton>
                        <DropdownItem
                            dataTestId={`${this.props.rootMenuTestId}-resetFillColorButton`}
                            highlightColor={"#39F"}
                            hoverColor={"#39F"}
                            title="Reset fill color"
                            onClick={() => {
                                let styleOptions = node.style_options;
                                if (styleOptions != null)
                                    this.props.canvasTreeStore.updateBackgroundAction(
                                        node.id,
                                        {
                                            style_options: {
                                                ...styleOptions,
                                                fillColor: null,
                                            },
                                        }
                                    );
                                this.setState({toggleOpen: false})
                            }}
                            icon={
                                <DeleteIcon style={{ width: 14, height: 14 }} />
                            }
                        />
                    </CanvasPreventPropagationButton>
                </DropdownMenu>
            </Dropdown>
        );
    }
}
