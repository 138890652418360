import React, {useEffect, useState} from 'react';
import cx from "classnames";
import styles  from './demobot.module.css';  // Use for local development

/* State management using MobX */
import { observer } from 'mobx-react-lite';
import { store } from './stores/Store';

/* Book icon */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faHouse, faUser, faCommentsDollar, faSeedling, faAtom, faSquarePlus } from '@fortawesome/free-solid-svg-icons';

/* Pages */
import Home from './pages/Home';
import CLV from './pages/CLV';
import Challenger from './pages/Challenger';
import Brands from './pages/Brands';
import Atomic from './pages/Atomic';

/* Pull user information */
import CurrentUser from "common/CurrentUser";

const Demobot: React.FC = observer(() => {
    const [backgroundImage, setBackgroundImage] = useState<string>('url("https://images.unsplash.com/photo-1490682143684-14369e18dce8?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")');
    const [activePeriod, setActivePeriod] = useState<string>('Day');
    const [theme, setTheme] = useState<string>('dark'); // Add theme state: either light or dark

    /* handles routing of pages using state management */
    const [activePage, setActivePage] = useState('home');

    const renderPage = () => {
        switch (activePage) {
          case 'home':
            return <Home />;
          case 'clv':
            return <CLV />;
          case 'challenger':
            return <Challenger />;
          case 'brands':
            return <Brands />;
          case 'atomic':
            return <Atomic />;
          default:
            return <Home />;
        }
      };
    

    let first_name = '';
    let last_name = '';

    if(CurrentUser.info !== null) {
        console.log("CurrentUser =", CurrentUser.info.first_name);
        first_name = CurrentUser.info.first_name;
        last_name = CurrentUser.info.last_name;
    }
    /* This causes a bug so that the period clicks do not set the proper initial text anymore. */
    //store.setAnswers(`## Good morning, ${first_name} \n This is what your schedule looks like: \n * 9am: Breakfast with [Dr. Terry Smith](https://riversidehealth.org/) \n * 10:45am: Dr. Jennifer Snyder @ Montefiore \n * 12pm: Lunch with Dr. Tom Burns @ [Weill Cornell](https://maps.app.goo.gl/ZcTLCgXjuCHjECNS7) \n`);
    
    /* Moving images up here to be defined */
    /* Backgrounds that needs dark theme settings */
    const anoopBkImg : string = 'url("https://images.unsplash.com/photo-1490682143684-14369e18dce8?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")';
    const jeffBkImg : string = 'url("https://images.unsplash.com/photo-1439405326854-014607f694d7?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")';    
    const jeff2BkImg : string = 'url("https://images.unsplash.com/photo-1508107222753-0c236c337911?q=80&w=2786&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")';    
    const desertBkImg: string = 'url("https://images.unsplash.com/photo-1541789094913-f3809a8f3ba5?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")';

    /* Backgrounds that need light theme settings */
    const cleeBkImg : string = 'url("https://images.unsplash.com/photo-1518655048521-f130df041f66?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")';

    let userBkImg : string = anoopBkImg;
    switch (first_name) {
        case 'Jeff':
            userBkImg = jeff2BkImg;
            break;
        case 'Clarence':
            userBkImg = desertBkImg;
            break;
        case 'Vicente':
            userBkImg = jeffBkImg;
            break;
        default:
            userBkImg = anoopBkImg;            
    }

    useEffect(() => {
        switch (activePage) {
          case 'home':
            setBackgroundImage(userBkImg);
            setTheme('dark');            
            break;
          case 'clv':
            setBackgroundImage('url("/CLV.jpg")');
            setTheme('light');
            break;
          case 'challenger':
            setBackgroundImage('url("/challenger.jpg")');
            setTheme('light');
            break;
          case 'brands':
            setBackgroundImage('url("/brands.jpg")');
            setTheme('light');
            break;
          case 'atomic':
            setBackgroundImage('url("/atomic.jpg")');
            setTheme('light');
            break;
          default:
            setBackgroundImage(userBkImg);
            setTheme('dark');
            break;
        }
      }, [activePage]);
      
      

    const handlePeriodClick = (period: string) => {
        // TODO: This is a hack to get the new line character to work. Need to change.  
        setActivePeriod(period);        
        if (period === 'Day') {            
            //store.setAnswers(`## Good ${store.getTimeGreeting()}, ${first_name} \n This is what your schedule looks like: \n * 9am: Breakfast with [Dr. Terry Smith](https://riversidehealth.org/) \n * 10:45am: Dr. Jennifer Snyder @ Montefiore \n * 12pm: Lunch with Dr. Tom Burns @ [Weill Cornell](https://maps.app.goo.gl/ZcTLCgXjuCHjECNS7) \n`); 
            store.setAnswers(store.dayMsgInit.replace(/\\n/g, '\n'));
            store.setRenderCustom(false);
        } else if (period === 'Week') {
            //store.setAnswers(`Here are a few of your top priorities this week \n you want me to remind you of. \n 1. Close Dr. Burns \n 2. Cold-call 8 more new contacts \n 3. Prep for route with my sales director.`);
            store.setAnswers(store.weekMsgInit.replace(/\\n/g, '\n'));
            store.setRenderCustom(false);
        } else if (period === 'Month') {
            /*store.setAnswers(`In the previous month, you've discussed the following issues with your sales director: 
            \n 1. [Be ready to show her your coverage in territory](https://app.eisengard.ai/shared_canvas_module.html?remote_moduleid=rE%2BtL8QnQ5ad%2BU5g8RAfKA)
            \n 2. Discuss up-coming issues and problems.
            \n 3. Start applying principles from The Challenger Sale."
            `);*/
            store.setAnswers(store.monthMsgInit.replace(/\\n/g, '\n'));    
            store.setRenderCustom(false);
        } else if (period === 'Quarter') {
            //store.setAnswers(`Your next QBR is scheduled on 9/8/24.  \n Want me to remind you of your progress on that?`);
            store.setAnswers(store.quarterMsgInit.replace(/\\n/g, '\n'));
            store.setRenderCustom(false);
        } else {
            store.setAnswers(`Default answer for ${period}`); // Set the default answer based on the period
            store.setRenderCustom(false);
        }        
    };

    const renderTopCenterContent = () => {                        
        if (activePage === 'home') {
            const periods = ['Day', 'Week', 'Month', 'Quarter'];
            return periods.map((period, index) => (
                    <React.Fragment key={period}>
                    <a
                        key={period}
                        className={activePeriod === period ? styles.active : ''}
                        onClick={() => handlePeriodClick(period)}
                        href="#"
                    >
                        {period}
                    </a>
                    {index < periods.length - 1 && ' | '}
                    </React.Fragment>
            ));        
        } else if (activePage === 'clv') {
            return 'Acquisition | Retention | Expansion';
        }   
        return '';     
    };

    // Utility function to format the number as a currency with K, M notation
    function formatCurrency(value: number): string {
        if (value >= 1_000_000) {
            return `$${(value / 1_000_000).toFixed(2)}M`;
        } else if (value >= 1_000) {
            return `$${(value / 1_000).toFixed(0)}K`;
        } else {
            // Fallback to the default currency format for values less than 1,000
            return new Intl.NumberFormat('en-US', { 
                style: 'currency', 
                currency: 'USD', 
                minimumFractionDigits: 1,
                maximumFractionDigits: 1 
            }).format(value);
        }
    }


    return (    
    <div className={cx(styles.demobotApp, (theme === 'light')? styles.light: styles.dark)} style={{ backgroundImage: `${backgroundImage}` }}>
        <div className={styles.demobotAppHeader}>
            <div className={cx(styles.progress, styles.topLeft)}>
                {/*$980k*/}
                {formatCurrency(store.currProgress)}
                <br />
                <span>Current Progress</span>
            </div>
            <div className={cx(styles.progress, styles.topCenter)}>
            {renderTopCenterContent()}        
            </div>
            <div className={cx(styles.progress, styles.topRight)}>                
                {formatCurrency(store.yearGoal)}
                <br />
                <span>Year End Goal</span>
            </div>
            <div className={styles.dropdown}>
                <button className={styles.dropbtn}>
                    <FontAwesomeIcon icon={faBook} />
                </button>
                <div className={styles.dropdownContent}>
                    <a onClick={() => setActivePage('home')} href="#">
                        <FontAwesomeIcon icon={faHouse} />&thinsp; &thinsp; &thinsp; Our Company Way
                    </a>
                    <a onClick={() => setActivePage('clv')} href="#">
                        <FontAwesomeIcon icon={faUser} />&thinsp; &thinsp; &thinsp; The CLV Framework
                    </a>
                    <a onClick={() => setActivePage('challenger')} href="#">
                        <FontAwesomeIcon icon={faCommentsDollar} />&thinsp; &thinsp; &thinsp; The Challenger Sale
                    </a>
                    <a onClick={() => setActivePage('brands')} href="#">
                        <FontAwesomeIcon icon={faSeedling} />&thinsp; &thinsp; &thinsp; How Brands Grow
                    </a>
                    <a onClick={() => setActivePage('atomic')} href="#">
                        <FontAwesomeIcon icon={faAtom} />&thinsp; &thinsp; &thinsp; Atomic Habits
                    </a>
                    <a onClick={() => setActivePage('custom')} href="#">
                        <FontAwesomeIcon icon={faSquarePlus} />&thinsp; &thinsp; &thinsp; Add Your Own
                    </a>
                </div>
            </div>
        </div>
        {renderPage()}
    </div>
    );
});

export default Demobot;