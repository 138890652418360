import React from "react";
import { Button } from "react-bootstrap";
import { Element } from "react-scroll";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import { defaultStatsmodelsFormula } from "../../../../common/TrainModelStep";
import customSelectStyles from "common/SelectStyles";
import axios from "common/ServerConnection";
import tables from "common/Tables";
import dataScopes from "common/DataScopes";
import variables from "common/Variables";
import models from "common/Models";
import processTab from "common/utilities/processTab";
import { Permission } from "common/Permissions";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                dataScope: undefined,
                expanded: false,
                simplifiedOptimizationPanel: undefined,
                simplifiedModelName: undefined,
                simplifiedOptimizationOutcome: undefined,
                simplifiedOptimalData: null,
                simplifiedOptimizationTable: undefined,
                simplifiedOptimizationTableK: undefined,
                simplifiedOptimizationAdvancedFunctionsExpanded: false,
                simplifiedOptimizationAdvancedFunctions: "",
                functionLeft: "np.log(",
                functionRight: ")",
                formula: "",
                reFormula: "",
                inverseFunction: "lambda x: np.exp(x)",
                killButtonDisabled: true,
            };
            this.dataScopeValue = this.dataScopeValue.bind(this);
        }
        dataScopeValue() {
            if (this.state.dataScope) return this.state.dataScope.value;
            return undefined;
        }

        componentDidMount() {
            if (this.state.dataScope) this.getLastOptimizationFunctions();
            if (this.state.dataScope) this.simplifiedOptimizationStatus();
            this.interval = setInterval(() => {
                if (this.state.stepState === StepState.running) {
                    if (this.state.dataScope)
                        this.simplifiedOptimizationStatus();
                }
            }, 10000);
        }

        componentWillUnmount() {
            clearInterval(this.interval);
        }

        simplifiedOptimizationStart() {
            let json = {
                advanced_code: this.state
                    .simplifiedOptimizationAdvancedFunctions,
                model_name: this.state.simplifiedModelName,
                table_id: this.state.simplifiedOptimizationTable.value,
                data_table_idx: this.state.simplifiedOptimizationTable
                    .data_table_idx,
                k_table_id: this.state.simplifiedOptimizationTableK.value,
                condition_id: this.state.simplifiedOptimizationTable
                    .condition_id,
                k_condition_id: this.state.simplifiedOptimizationTableK
                    .condition_id,
                condition: this.state.simplifiedOptimizationTable.condition,
                k_condition: this.state.simplifiedOptimizationTableK.condition,
                panel_index: this.state.simplifiedOptimizationPanel
                    ? this.state.simplifiedOptimizationPanel.value
                    : undefined,
                outcome_index: this.state.simplifiedOptimizationOutcome.value,
                function_left: this.state.functionLeft,
                function_right: this.state.functionRight,
                formula: this.state.formula,
                random_effects_formula: this.state.reFormula,
                inverse_function: this.state.inverseFunction,
            };

            this.setStepState(StepState.running, "", {
                killButtonDisabled: true,
            });
            axios
                .post("/api/simplified_optimization_start", json, null)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        this.setState({ killButtonDisabled: false });
                    }
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        simplifiedOptimizationStatus() {
            let json = {
                data_table_idx: this.dataScopeValue(),
                panel_index: this.state.simplifiedOptimizationPanel
                    ? this.state.simplifiedOptimizationPanel.value
                    : undefined,
            };
            axios
                .post("/api/simplified_optimization_status", json, null)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        let text = null;
                        if (response.data.status === StepState.error) {
                            text = response.data.result;
                        }
                        let tablePreview = response.data.table_preview || null;
                        this.setStepState(response.data.status, text, {
                            simplifiedOptimalData: tablePreview,
                        });
                        if (response.data.status === StepState.finished)
                            models(this.dataScopeValue()).update();
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 401 && error.response.status !== 403) {
                        this.setStepState(
                            StepState.error,
                            error.response.status.toString()
                        );
                    }
                });
        }

        simplifiedOptimizationKill() {
            axios
                .post("/api/simplified_optimization_kill", null, null)
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        this.setStepState(StepState.not_running);
                    }
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        getLastOptimizationFunctions() {
            axios
                .post(
                    "/api/get_last_optimization_functions",
                    {
                        data_table_idx: this.dataScopeValue(),
                    },
                    null
                )
                .then((response) => {
                    if (response.data.success === false) {
                        console.log(response.data.error_msg);
                    } else {
                        this.setState((state) => ({
                            simplifiedOptimizationAdvancedFunctions:
                                response.data.simplified.advanced_code,
                            simplifiedOptimizationPanel:
                                response.data.simplified.panel_index != null
                                    ? {
                                          label: variables(
                                              this.dataScopeValue()
                                          ).variableNames[
                                              response.data.simplified
                                                  .panel_index
                                          ],
                                          value:
                                              response.data.simplified
                                                  .panel_index,
                                      }
                                    : null,
                            simplifiedOptimizationOutcome:
                                response.data.simplified.outcome_index != null
                                    ? {
                                          label: variables(
                                              this.dataScopeValue()
                                          ).variableNames[
                                              response.data.simplified
                                                  .outcome_index
                                          ],
                                          value:
                                              response.data.simplified
                                                  .outcome_index,
                                      }
                                    : null,
                            simplifiedOptimizationTable: tables(
                                this.dataScopeValue()
                            ).tableToOption(response.data.simplified.table),
                            simplifiedOptimizationTableK: tables(
                                this.dataScopeValue()
                            ).tableToOption(response.data.simplified.table_k),
                        }));
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 401 && error.response.status !== 403)
                        console.log(error);
                });
        }

        render() {
            let simplifiedOptimalDataTable;
            if (
                this.state.expanded &&
                this.state.simplifiedOptimalData !== null
            ) {
                let tableHeader = variables(
                    this.dataScopeValue()
                ).dataVariables.map((value) => value.name);
                let tableContent = [];
                let rowCount = Object.values(
                    this.state.simplifiedOptimalData
                )[0].length;
                for (let i = 0; i < rowCount; ++i) {
                    let row = [];
                    for (let varName of tableHeader) {
                        let column = this.state.simplifiedOptimalData[varName];
                        if (typeof column === "undefined") {
                            row.push("-");
                        } else {
                            row.push(
                                column[i] ||
                                    (typeof column[i] === "number"
                                        ? column[i]
                                        : "-")
                            );
                        }
                    }
                    tableContent.push(row);
                }
                simplifiedOptimalDataTable = (
                    <Element
                        name="scrollable"
                        className="element"
                        style={{
                            overflowX: "scroll",
                            backgroundColor: "transparent",
                            position: "relative",
                            overflowY: "scroll",
                        }}
                    >
                        <AdminTableWithFullFeatures
                            className="processing-page-table"
                            paging={false}
                            tableName="Data"
                            tableHeader={tableHeader}
                            tableContent={tableContent}
                        />
                    </Element>
                );
            } else {
                simplifiedOptimalDataTable = null;
            }

            let rendered = [
                <div className="circle-letter" key={0}>
                    {this.props.index}
                </div>,
                <Button
                    key={1}
                    disabled={this.props.disabled}
                    className="btn-processing-tab"
                    style={{
                        height: "25px",
                    }}
                    onClick={() => {
                        this.setState((state) => ({
                            expanded: !state.expanded,
                        }));
                    }}
                >
                    <span className="content-regular-text">
                        Run simplified optimization
                    </span>
                    <span className="content-regular-text">
                        {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                    </span>
                </Button>,
                this.state.expanded ? (
                    <Button
                        key={2}
                        className="btn btn-lg btn-primary my-primary"
                        onClick={() => {
                            if (this.state.stepState === StepState.running) {
                                this.simplifiedOptimizationKill();
                            } else {
                                this.simplifiedOptimizationStart();
                            }
                        }}
                        style={{
                            fontFamily: "Roboto",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            height: "25px",
                            width: "100%",
                        }}
                        disabled={
                            this.state.stepState === StepState.running
                                ? this.state.killButtonDisabled
                                : false
                        }
                    >
                        {this.state.stepState === StepState.running
                            ? "KILL"
                            : "RUN"}
                    </Button>
                ) : (
                    <div key={2} />
                ),
                <div key={3}>
                    {this.state.expanded && this.renderStepState()}
                </div>,
            ];
            if (this.state.expanded) {
                rendered = rendered.concat([
                    <div />,
                    <div
                        style={{
                            gridColumnStart: 2,
                            gridColumnEnd: 4,
                            minWidth: 0,
                            background: "#3f4b62",
                            padding: "10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {"Enter model name"}
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    marginLeft: "11px",
                                    width: "15em",
                                }}
                                placeholder=""
                                onChange={(e) =>
                                    this.setState({
                                        simplifiedModelName: e.target.value,
                                    })
                                }
                                value={this.state.simlifiedModelName}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                }}
                            >
                                Select data
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={dataScopes.dataScopesOptions.filter(
                                    (option) =>
                                        option.permissionType ===
                                        Permission.ReadWrite
                                )}
                                onChange={(newValue) => {
                                    // let newOutcome = undefined;
                                    // if (
                                    //     variables(newValue.value)
                                    //         .numericVariables.length > 0
                                    // ) {
                                    //     newOutcome = variables(newValue.value)
                                    //         .numericVariables[0];
                                    //     newOutcome = {
                                    //         label: newOutcome.name,
                                    //         value: newOutcome.index,
                                    //     };
                                    // }
                                    this.setState(
                                        {
                                            dataScope: newValue,
                                            simplifiedOptimizationPanel: null,
                                            formula: defaultStatsmodelsFormula(
                                                variables(
                                                    newValue.value
                                                ).numericVariables.map(
                                                    (item) => item.name
                                                )
                                            ),
                                            reFormula: defaultStatsmodelsFormula(
                                                variables(
                                                    newValue.value
                                                ).numericVariables.map(
                                                    (item) => item.name
                                                )
                                            ),
                                        },
                                        () => {
                                            this.getLastOptimizationFunctions();
                                            this.simplifiedOptimizationStatus();
                                        }
                                    );
                                }}
                                value={this.state.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                Train the following model with clustering on
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "15em",
                                        height: "38px",
                                    }),
                                }}
                                isClearable={true}
                                backspaceRemovesValue={true}
                                options={variables(
                                    this.dataScopeValue()
                                ).dataVariables.map((value) => ({
                                    label: value.name,
                                    value: value.index,
                                }))}
                                onChange={(newValue) =>
                                    this.setState(
                                        {
                                            simplifiedOptimizationPanel: newValue,
                                        },
                                        this.simplifiedOptimizationStatus
                                    )
                                }
                                value={this.state.simplifiedOptimizationPanel}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                Using
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={
                                    tables(this.dataScopeValue())
                                        .rawAndAggregateTableOptions
                                }
                                onChange={(newValue) =>
                                    this.setState({
                                        simplifiedOptimizationTable: newValue,
                                    })
                                }
                                value={this.state.simplifiedOptimizationTable}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <span
                                className="content-regular-text"
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                as train data and
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={
                                    tables(this.dataScopeValue())
                                        .rawAndAggregateTableOptions
                                }
                                onChange={(newValue) =>
                                    this.setState({
                                        simplifiedOptimizationTableK: newValue,
                                    })
                                }
                                value={this.state.simplifiedOptimizationTableK}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <span className="content-regular-text">
                                as current table
                            </span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    fontFamily: "monospace",
                                    width: "7em",
                                    textAlign: "right",
                                }}
                            >
                                {this.state.simplifiedOptimizationPanel
                                    ? "smf.mixedlm("
                                    : "smf.ols("}
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    fontFamily: "monospace",
                                    textAlign: "right",
                                    marginLeft: "4px",
                                    marginRight: "4px",
                                    width: "8em",
                                }}
                                placeholder=""
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.setState({
                                        functionLeft: value,
                                    });
                                }}
                                value={this.state.functionLeft}
                            />
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "8em",
                                        height: "38px",
                                    }),
                                }}
                                options={variables(
                                    this.dataScopeValue()
                                ).numericVariables.map((value) => ({
                                    label: value.name,
                                    value: value.index,
                                }))}
                                onChange={(newValue) =>
                                    this.setState({
                                        simplifiedOptimizationOutcome: newValue,
                                    })
                                }
                                value={this.state.simplifiedOptimizationOutcome}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    fontFamily: "monospace",
                                    textAlign: "left",
                                    marginLeft: "4px",
                                    marginRight: "4px",
                                    width: "8em",
                                }}
                                placeholder=""
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.setState({
                                        functionRight: value,
                                    });
                                }}
                                value={this.state.functionRight}
                            />
                            <span className="content-regular-text">~</span>
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    fontFamily: "monospace",
                                    textAlign: "left",
                                    marginLeft: "4px",
                                    marginRight: "4px",
                                    flex: 1,
                                }}
                                placeholder=""
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.setState({
                                        formula: value,
                                    });
                                }}
                                value={this.state.formula}
                            />
                            <span className="content-regular-text">)</span>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    fontFamily: "monospace",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "block",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                Random effects formula: ~
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    fontFamily: "monospace",
                                    textAlign: "left",
                                    marginLeft: "4px",
                                    marginRight: "4px",
                                    flex: 1,
                                }}
                                placeholder=""
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.setState({
                                        reFormula: value,
                                    });
                                }}
                                value={this.state.reFormula}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    fontFamily: "monospace",
                                }}
                            >
                                Inverse function:
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    height: "25px",
                                    fontFamily: "monospace",
                                    textAlign: "left",
                                    marginLeft: "4px",
                                    flex: 1,
                                }}
                                placeholder=""
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.setState({
                                        inverseFunction: value,
                                    });
                                }}
                                value={this.state.inverseFunction}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <span className="content-regular-text">
                                And use it to update corresponding optimal
                                table.
                            </span>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                marginBottom: "10px",
                            }}
                        >
                            <Button
                                className="btn-processing-tab-blue"
                                style={{
                                    height: "25px",
                                    flex: 1,
                                }}
                                onClick={() => {
                                    this.setState((state) => ({
                                        simplifiedOptimizationAdvancedFunctionsExpanded: !state.simplifiedOptimizationAdvancedFunctionsExpanded,
                                    }));
                                }}
                            >
                                <span className="content-regular-text">
                                    Edit simplified optimization code
                                </span>
                                <span className="content-regular-text">
                                    {this.state
                                        .simplifiedOptimizationAdvancedFunctionsExpanded
                                        ? "\uFF0D"
                                        : "\uFF0B"}
                                </span>
                            </Button>
                        </div>
                        {this.state
                            .simplifiedOptimizationAdvancedFunctionsExpanded && (
                            <textarea
                                className="like-select element"
                                style={{
                                    minHeight: "780px",
                                    width: "100%",
                                    fontFamily: "monospace",
                                    resize: "vertical",
                                    whiteSpace: "pre",
                                    marginBottom: "10px",
                                }}
                                spellCheck={false}
                                placeholder=""
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.setState({
                                        simplifiedOptimizationAdvancedFunctions: value,
                                    });
                                }}
                                value={
                                    this.state
                                        .simplifiedOptimizationAdvancedFunctions
                                }
                                ref="simplifiedOptimizationAdvancedFunctionsTextArea"
                                onKeyDown={(e) =>
                                    processTab(
                                        e,
                                        (
                                            newSelectionStart,
                                            newSelectionEnd,
                                            newValue
                                        ) => {
                                            this.setState(
                                                {
                                                    simplifiedOptimizationAdvancedFunctions: newValue,
                                                },
                                                () => {
                                                    this.refs.simplifiedOptimizationAdvancedFunctionsTextArea.selectionStart = newSelectionStart;
                                                    this.refs.simplifiedOptimizationAdvancedFunctionsTextArea.selectionEnd = newSelectionEnd;
                                                }
                                            );
                                        }
                                    )
                                }
                            />
                        )}

                        {simplifiedOptimalDataTable !== null && [
                            <div
                                className="content-regular-text"
                                style={{
                                    marginTop: "20px",
                                    marginBottom: "5px",
                                }}
                            >
                                Optimized table preview for panel{" "}
                                {this.state.simplifiedOptimizationPanel
                                    ? this.state.simplifiedOptimizationPanel
                                          .label
                                    : ""}
                            </div>,
                            simplifiedOptimalDataTable,
                        ]}
                    </div>,
                    <div />,
                ]);
            }
            return rendered;
        }
    }
);

export { MainComponent };
export let requirePermission = "SimplifiedOptimization";
