import React, { Component } from "react";
import Alert from "common/Alert";
import { loadModuleApi, confirmSession } from "common/ModulesApi";
import { goToInternalLink } from "common/InternalLinksHelper";
import { MyStripePaywall } from "common/mystripe_components/MyStripePayWall";
import ScaledPage from "common/ScaledPage";

interface Props {
    moduleId: number;
    sessionId?: string | null;
}

interface State {
    paywall?: {
        title: string;
        client: string;
        description: string;
        price: number;
        logo: string | null;
    };
    errorMessage?: string;
}

class SharedKitPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            paywall: undefined,
            errorMessage: undefined,
        };
    }
    loadModule() {
        loadModuleApi(this.props.moduleId)
            .then((data) => {
                if (data.action_required === "payment") {
                    this.setState({
                        paywall: {
                            title: data.paywall!.title!,
                            price: data.paywall!.price!,
                            client: data.paywall!.client!,
                            description: data.paywall!.description!,
                            logo: data.paywall!.logo!,
                        },
                    });
                } else if (data.action_required === "attach_payment_method") {
                    goToInternalLink(
                        `/attach_and_subscribe.html?shared_module_id=${this.props.moduleId}`
                    );
                } else if (data.redirect_url != null) {
                    window.location.href = data.redirect_url;
                } else {
                    goToInternalLink(
                        `/canvas.html?current_module_id=${data.id}`
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    errorMessage: String(error),
                });
            });
    }
    componentDidMount() {
        if (this.props.sessionId) {
            confirmSession(this.props.sessionId)
                .then(() => {
                    window.history.replaceState(
                        {},
                        document.title,
                        `/shared_module.html?shared_module_id=${this.props.moduleId}`
                    );
                    this.loadModule();
                })
                .catch((error) => {
                    this.setState({
                        errorMessage: error.toString(),
                    });
                });
        } else {
            this.loadModule();
        }
    }

    render() {
        return (
            <ScaledPage>
                <div
                    className="content-wrapper hide-scroll"
                    style={{
                        marginLeft: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <section className="content">
                        <div style={{ width: "500px" }}>
                            {this.state.paywall && (
                                <MyStripePaywall
                                    linkId={this.props.moduleId}
                                    subscribeEndpoint="/api/module_shared_payment_add"
                                    logoUrl={
                                        this.state.paywall?.logo ?? undefined
                                    }
                                    brandName={this.state?.paywall?.title}
                                    price={this.state.paywall?.price ?? 0}
                                    onPayment={() => {
                                        this.loadModule();
                                    }}
                                />
                            )}
                        </div>
                        {this.state.errorMessage != null && (
                            <Alert
                                text={this.state.errorMessage}
                                className="alert alert-danger alert-dismissible"
                                onClosed={() =>
                                    this.setState({
                                        errorMessage: undefined,
                                    })
                                }
                            />
                        )}
                    </section>
                </div>
            </ScaledPage>
        );
    }
}

export default SharedKitPage;
