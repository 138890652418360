import "common/styles/App.css";

import React, { Component } from "react";
import KPIContent from "./KPIContent";
import PagesBar from "common/insights_components/PagesBar";
import axios from "common/ServerConnection";
import ScaledPage from "common/ScaledPage";
import ShareLinkToast from "common/ShareLinkToast";
import {
    deleteKpiStreamApi,
    moveKpiStreamApi,
    getKpiStreamsApi,
    editKpiStreamApi,
    getKpiStreamApi,
} from "common/UserApi";
import { renderIcon } from "common/utilities/renderIcon";
import modules from "../data_exploration_page/modules/module_list.json";
import PageType from "common/PageType";
import Instrumentation from "common/Instrumentation";
import PagesStore from "common/PagesStore";
import _ from "lodash";
import { ReactComponent as ViewStreamIcon } from "icons/left_nav_menu/view_stream.svg";

import styles from "./main.module.css";

class MainComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kpiStreams: [],
            pageId: undefined,
            updatingStreams: [],
        };
        this.tabType = this.props.tabType;
        this.fetchData = this.fetchData.bind(this);
        this.onKpiStreamReload = this.onKpiStreamReload.bind(this);
        this.onKpiStreamDelete = this.onKpiStreamDelete.bind(this);
        this.onKpiStreamTitleUpdate = this.onKpiStreamTitleUpdate.bind(this);
        this.onKpiStreamShare = this.onKpiStreamShare.bind(this);
        this.onKpiStreamsSwap = this.onKpiStreamsSwap.bind(this);
        this.afterKpiStreamUpdate = this.afterKpiStreamUpdate.bind(this);
        this.wrapper = React.createRef();

        this.apis = {};
        modules.forEach((module, index) => {
            module.submodules.forEach((submodule) => {
                try {
                    let api = require(`../data_exploration_page/modules/${submodule.dir}/Api.js`);
                    this.apis[submodule.name] = api.Api;
                } catch (e) {}
            });
        });

        this.performance = null;
        this.mounted = false;
    }
    onKpiStreamTitleUpdate(index, title, update) {
        let kpiStreams = Array.from(this.state.kpiStreams);
        if (kpiStreams) {
            kpiStreams[index].contents.title = title;
            this.setState({ kpiStreams: kpiStreams });
            if (update) {
                let newKpiStream = kpiStreams[index];
                editKpiStreamApi(newKpiStream)
                    .then((updated) => {
                        this.afterKpiStreamUpdate(newKpiStream.id);
                    })
                    .catch((msg) => {
                        this.afterKpiStreamUpdate(newKpiStream.id);
                        console.log(msg);
                    });
            }
        }
    }

    onKpiStreamReload(kpiStream) {
        let oldFinding = kpiStream.contents.finding;
        let api = this.apis[oldFinding.config.journeyName];
        let updatingStreams = Array.from(this.state.updatingStreams);
        updatingStreams.push(kpiStream.id);
        this.setState({ updatingStreams: updatingStreams });
        api.getData(oldFinding.config, oldFinding)
            .then((finding) => {
                let newKpiStream = _.cloneDeep(kpiStream);
                newKpiStream.finding = finding;
                renderIcon(this.wrapper, finding, 200)
                    .then((dataUrl) => {
                        newKpiStream.imageSrc = dataUrl;

                        editKpiStreamApi(newKpiStream)
                            .then((updated) => {
                                this.afterKpiStreamUpdate(newKpiStream.id);
                            })
                            .catch((msg) => {
                                this.afterKpiStreamUpdate(newKpiStream.id);
                                console.log(msg);
                            });
                    })
                    .catch((error) => {
                        newKpiStream.imageSrc = "";
                        editKpiStreamApi(newKpiStream)
                            .then((updated) => {
                                this.afterKpiStreamUpdate(newKpiStream.id);
                            })
                            .catch((msg) => {
                                this.afterKpiStreamUpdate(newKpiStream.id);
                                console.log(msg);
                            });
                    });
            })
            .catch((error) => {
                console.log(error);
                let updatingStreams = Array.from(
                    this.state.updatingStreams
                ).filter((item) => item !== kpiStream.id);
                this.setState({ updatingStreams: updatingStreams });
            });
    }
    onKpiStreamDelete(kpiStream) {
        deleteKpiStreamApi(kpiStream).then(() => {
            let kpiStreams = Array.from(this.state.kpiStreams);
            if (kpiStreams) {
                kpiStreams = kpiStreams.filter(
                    (item) => item.id !== kpiStream.id
                );
                this.setState({ kpiStreams: kpiStreams });
            }
        });
    }
    onKpiStreamsSwap(item, otherItem) {
        moveKpiStreamApi(item.id, otherItem.id).then(() => {
            this.fetchData();
        });
    }

    onKpiStreamShare(kpiStream) {
        let url = new URL(window.location.origin);
        url.pathname = "/view_card.html";
        let contents = {
            finding: kpiStream.contents.finding,
            imageSrc: kpiStream.contents.imageSrc,
            title: kpiStream.contents.title,
            noInsight: true,
            noRecommendation: true,
        };
        let kpiStreamJson = {
            card: JSON.stringify(contents),
        };
        axios
            .post("/api/insights_card_add", kpiStreamJson, null)
            .then((response) => {
                if (response.data.success) {
                    url.searchParams.append(
                        "remote_insightid",
                        response.data.id
                    );
                    this.setState({
                        showShareToast: true,
                        lastLink: url.toString(),
                    });
                } else {
                    console.log(response.data.error_msg);
                    this.setState({
                        showShareToast: true,
                        lastLink: undefined,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    showShareToast: true,
                    lastLink: undefined,
                });
            });
    }
    afterKpiStreamUpdate(kpiStreamId) {
        getKpiStreamApi(kpiStreamId)
            .then((kpiStream) => {
                let kpiStreams = Array.from(this.state.kpiStreams);

                if (kpiStreams) {
                    let index = kpiStreams.findIndex(
                        (element, index, array) => {
                            return String(element.id) === String(kpiStream.id);
                        }
                    );
                    if (index < 0) return;
                    kpiStreams[index] = kpiStream;
                    let updatingStreams = Array.from(
                        this.state.updatingStreams
                    ).filter((item) => item !== kpiStreamId);

                    this.setState({
                        updatingStreams: updatingStreams,
                        kpiStreams: kpiStreams,
                    });
                }
            })
            .catch((error) => {
                let updatingStreams = Array.from(
                    this.state.updatingStreams
                ).filter((item) => item !== kpiStreamId);

                this.setState({
                    updatingStreams: updatingStreams,
                });
            });
    }
    fetchData() {
        getKpiStreamsApi(this.state.pageId).then((kpiStreams) => {
            this.setState({ kpiStreams: kpiStreams });
        });
    }

    buildKpiStreamsContent() {
        return (
            <div className="journey-column">
                <KPIContent
                    updatingStreams={this.state.updatingStreams}
                    onKpiStreamReload={(kpiStream) => {
                        this.performance = new Date();
                        this.onKpiStreamReload(kpiStream);
                    }}
                    onKpiStreamsSwapped={(kpiStream1, kpiStream2) => {
                        this.performance = new Date();
                        this.onKpiStreamsSwap(kpiStream1, kpiStream2);
                    }}
                    onKpiStreamDelete={(kpiStream) => {
                        this.performance = new Date();
                        this.onKpiStreamDelete(kpiStream);
                    }}
                    onKpiStreamShare={(kpiStream) => {
                        this.performance = new Date();
                        this.onKpiStreamShare(kpiStream);
                    }}
                    onKpiStreamTitleUpdate={(index, title, update) => {
                        this.performance = new Date();
                        this.onKpiStreamTitleUpdate(index, title, update);
                    }}
                    permission={PagesStore(PageType.KPIStreams).permission(
                        this.state.pageId
                    )}
                    kpiStreams={this.state.kpiStreams}
                />
            </div>
        );
    }
    componentDidMount() {
        if (this.performance != null) {
            let timeMs = new Date() - this.performance;
            this.performance = null;
            Instrumentation.addInteraction("KPIStream", timeMs);
        }
    }

    componentWillUnmount() {}

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs = new Date() - this.performance;
            this.performance = null;
            Instrumentation.addInteraction("KPIStream", timeMs);
        }
    }

    render() {
        if (!this.mounted) {
            this.performance = new Date();
            this.mounted = true;
        }
        return (
            <ScaledPage>
                <div
                    ref={this.wrapper}
                    id="KpiStreams-page-content-wrapper"
                    className="content-wrapper element"
                    style={{ overflowY: "auto" }}
                >
                    <section className="content">
                        <div style={{ marginTop: 20 }}>
                            <PagesBar
                                type={PageType.KPIStreams}
                                onPageChange={(pageId) => {
                                    this.setState(
                                        { pageId: pageId, kpiStreams: [] },
                                        () => {
                                            this.fetchData();
                                        }
                                    );
                                }}
                            />
                        </div>

                        <div
                            style={{
                                marginTop: "20px",
                                marginLeft: "34px",
                            }}
                        >
                            {this.buildKpiStreamsContent()}
                        </div>
                    </section>

                    {this.state.showShareToast ? (
                        <ShareLinkToast
                            value={this.state.lastLink}
                            onClosed={() => {
                                this.setState({
                                    showShareToast: false,
                                    lastLink: undefined,
                                });
                            }}
                        />
                    ) : null}
                </div>
            </ScaledPage>
        );
    }
}

export { MainComponent };
export let route = "/kpi_stream.html",
    section = "KPIStream",
    leftPanelItem = {
        section: "KPIStream",
        href: "kpi_stream.html",
        icon: <ViewStreamIcon className={styles.icon} />,
    },
    requirePermission = "KPIStream";
