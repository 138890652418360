import React from "react";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";

export default function CustomHistogramTooltip(props) {
	if (!props.active) return null;

	let index = -1;
	for (let i = 0; i < props.axes.length; ++i) {
		if (props.label === props.axes[i]) {
			index = i;
			break;
		}
	}
	if (index >= 0) {
		let newPayload = [
			{
				name: "count",
				value: props.values[index],
			},
		];
		return (
			<DefaultTooltipContent
				{...props}
				label={undefined}
				active
				payload={newPayload}
			/>
		);
	}
	return null;
}