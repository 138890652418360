import React from "react";
import MapElementWrapper from "common/graphics/v2/MapElement";
import { NetworkFinding } from "common/Finding";

interface Props {
    live: boolean;
    scale: number;
    currentModuleId: number | undefined;
    dashboardId: string;
    finding: NetworkFinding;
    onNewFinding?: (finding: NetworkFinding, updateData?: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
    width: number;
    height: number;
}

function MainComponent(props: Props) {
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <MapElementWrapper
                live={props.live}
                scale={props.scale}
                mapId={props.dashboardId}
                editable={props.editable}
                preview={props.preview}
                mapFinding={{
                    type: "maps_networkovermap",
                    config: props.finding.config.mapConfig ?? {},
                    content: {
                        data: {},
                        heatMapData: [],
                    },
                    additionalMapsFindings: props.finding.additionalMapsFindings ?? []
                }}
                networkFinding={props.finding}
                onNetworkChange={props.onNewFinding}
                columnDragActive={props.columnDragActive}
                onChange={(mapFinding) => {
                    props.onNewFinding?.(
                        {
                            ...props.finding,
                            config: {
                              ...props.finding.config,
                              mapConfig: {
                                 ...props.finding.config.mapConfig,
                                 ...mapFinding.config
                              }
                            }
                        },
                        true
                    )
                }}
                currentModuleId={props.currentModuleId}
                width={props.width}
                height={props.height}
            />
        </div>
    );
}

export { MainComponent };
