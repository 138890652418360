import React, { Component } from "react";

import CanvasCard from "./CanvasCard";
import { Canvas } from "common/Canvas";

interface Props {
    links?: Canvas[];
    linkPopups?: Canvas[];
    onLinkClick: (canvas: Canvas) => void;
    onLinkPopupClick: (canvas: Canvas) => void;
}

class PopupCards extends Component<Props> {
    render() {
        return (
            <div className="my-row">
                {(this.props.links?.length ?? 0) +
                    (this.props.linkPopups?.length ?? 0) >
                    0 && (
                    <div
                        className="dashboard-rect flex-simple-column"
                        style={{
                            marginLeft: 20,
                            alignItems: "center",
                            width: 357,
                            height: 324,
                        }}
                    >
                        {this.props.links?.map((canvas) => {
                            return (
                                <CanvasCard
                                    id={canvas.id}
                                    key={canvas.id}
                                    width={300}
                                    title={canvas.title}
                                    subtitle={canvas.page_title}
                                    onClick={() => {
                                        this.props.onLinkClick(canvas);
                                    }}
                                />
                            );
                        })}
                        {this.props.linkPopups?.map((canvas) => {
                            return (
                                <CanvasCard
                                    id={canvas.id}
                                    key={canvas.id}
                                    width={300}
                                    title={canvas.title}
                                    subtitle={canvas.page_title}
                                    onClick={() => {
                                        this.props.onLinkPopupClick(canvas);
                                    }}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

export default PopupCards;
