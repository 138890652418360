import React, { Component } from "react";
import { PropsValue, ActionMeta, StylesConfig, Theme, GroupBase } from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import {
    strftime,
    defaultDate,
    dateFormats,
    timeFormats,
    dateTimeFormats,
} from "common/utilities/TimeFormatUtils";

type SelectProps<Option> = CreatableProps<Option, false, GroupBase<Option>>;

type OptionType = { label: string; value: string };

interface Props
    extends Omit<
        SelectProps<OptionType>,
        "onCreateOption" | "formatCreateLabel" | "value"
    > {
    onCreateOption?: (option: OptionType) => void;
    // You can also specify just the format.
    // The label will be created automatically.
    value?: PropsValue<OptionType> | string;

    // We need to redefine the below types since otherwise typescript will
    // struggle to determine argument types.
    onChange?: (
        value: PropsValue<OptionType>,
        action: ActionMeta<OptionType>
    ) => void;
    styles?: StylesConfig<OptionType, false, GroupBase<OptionType>>;
    theme?: Theme | ((theme: Theme) => Theme);
}

class DateTimeFormatSelect extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onCreateOption = this.onCreateOption.bind(this);
        this.formatCreateLabel = this.formatCreateLabel.bind(this);
    }

    public static defaultOptions(
        date: boolean = true,
        time: boolean = true
    ): OptionType[] {
        let options: OptionType[] = [];
        if (date) {
            options = options.concat(
                dateFormats.map((fmt) => ({
                    label: strftime(fmt, defaultDate),
                    value: fmt,
                }))
            );
        }
        if (time) {
            options = options.concat(
                timeFormats.map((fmt) => ({
                    label: strftime(fmt, defaultDate),
                    value: fmt,
                }))
            );
        }

        if (date && time) {
            options = options.concat(
                dateTimeFormats.map((fmt) => ({
                    label: strftime(fmt, defaultDate),
                    value: fmt,
                }))
            );
        }
        return options;
    }

    private onCreateOption(fmt: string): void {
        if (this.props.onCreateOption != null) {
            this.props.onCreateOption({
                label: strftime(fmt, defaultDate),
                value: fmt,
            });
        }
    }

    private formatCreateLabel(value: string): string {
        return `${value} (Example: ${strftime(value, defaultDate)})`;
    }

    public render(): JSX.Element {
        let value: PropsValue<OptionType> | undefined;
        if (typeof this.props.value === "string") {
            value = {
                label: strftime(this.props.value, defaultDate),
                value: this.props.value,
            };
        } else {
            value = this.props.value;
        }
        return (
            <CreatableSelect
                {...this.props}
                value={value}
                onCreateOption={this.onCreateOption}
                formatCreateLabel={this.formatCreateLabel}
            />
        );
    }
}

export default DateTimeFormatSelect;
export type { Props, OptionType };
