import { action, computed, makeObservable, observable } from "mobx";


interface IPopupData {
    id: string
    row_id: number
}

class PopupData {
    @observable public popupData:IPopupData | null  = null;

    constructor() {
        makeObservable(this);
    }

    @computed public get data(): IPopupData | null {
        return this.popupData;
    }

    @action.bound
    public assignPopupdata(popupData: IPopupData) {
        this.popupData = popupData;
    }

}

export default new PopupData();