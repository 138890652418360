import videoExtensions from "./videoExtensions";

class StringUtils {
    public static capitalize(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    public static decapitalize(string: string): string {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    static normalizeString(str: string) {
        let newStr = str.replaceAll(/[\W]+/g, "_");
        if (newStr.match(/^\d/)) {
            newStr = "_".concat(newStr);
        }
        return newStr;
    }

    static numToAlphabet(num: number): string {
        let s: string = "";
        let t: number = 0;

        while (num > 0) {
            t = (num - 1) % 26;
            s = String.fromCharCode(65 + t) + s;
            num = ((num - t) / 26) | 0;
        }
        return s;
    }
    static isImageUrl(url: string) {
        return (
            StringUtils.isValidHttpUrl(url) &&
            url.match(/\.(jpeg|jpg|gif|png)$/) != null
        );
    }
    static isVideoUrl(string: string) {
        let url: URL;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        let index: number = url.pathname.lastIndexOf("/");
        let filename: string;
        if (index >= 0) {
            filename = url.pathname.slice(index);
        } else {
            filename = url.pathname;
        }
        index = filename.lastIndexOf(".");
        if (index < 0) {
            return false;
        }
        let extension: string = filename.slice(index).toLowerCase();

        return videoExtensions.has(extension);
    }
    static addSchemaToUrl(url: string) {
        if (!/^https?:\/\//i.test(url)) {
            url = "http://" + url;
        }
        return url;
    }
    static isValidHttpUrl(string: string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }
    static alphabetToNum(str: string): number {
        let result = 0;
        for (let i = 0; i < str.length; i += 1) {
            result +=
                (str.charCodeAt(i) - 64) * Math.pow(26, str.length - i - 1);
        }
        return result;
    }
    public static isNumber(input: string | number) {
        if (typeof input === "number") return !isNaN(input as any);
        return input != null && input.length > 0 && !isNaN(input as any);
    }

    public static isHtml(str: string): boolean {
        let doc = new DOMParser().parseFromString(str, "text/html");
        return Array.from(doc.body.childNodes).some(
            (node) => node.nodeType === 1
        );
    }
}
export default StringUtils;
