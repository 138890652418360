import React, { useState } from "react";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";
import AutosizeInput from "react-input-autosize";

import { mainStyle } from "common/MainStyle";
import {
    TemplateSheetQuestion,
    isBox,
    isInput,
    isTextBox,
} from "common/Canvas";
import { dataScienceSelectStyles } from "common/SelectStyles";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import Canvases from "common/Canvases";
import NumericOption from "common/NumericOption";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";

interface Props {
    question: TemplateSheetQuestion;
    frozen: boolean;
    restQuestionsCount: number;
    maxHeight: number;
    onChange: (question: TemplateSheetQuestion) => void;
    onNext: (question: TemplateSheetQuestion) => void;
}

const TemplateSheetQuestionView = observer((props: Props) => {
    const newSheets = useState<NumericOption[]>([])[0];
    let nodeOptions: NumericOption[] = [{ label: "CREATE NEW", value: -1 }];
    if (
        props.question.pageOption !== null &&
        props.question.sheetOption !== null
    ) {
        let canvas = Canvases(
            props.question.pageOption.value
        ).getCachedFullCanvas(props.question.sheetOption.value);
        if (canvas != null)
            nodeOptions = nodeOptions.concat(
                Object.values(canvas.canvas.canvasTreeState ?? {})
                    .filter(
                        (node) =>
                            isTextBox(node) || isBox(node) || isInput(node)
                    )
                    .map((node) => ({
                        label: node.outerId,
                        value: node.id,
                    }))
            );
    }
    let answerOptions = new Array(props.restQuestionsCount)
        .fill(0)
        .map((item, index) => ({
            label: `Answer ${index + 1}`,
            value: index,
        }));
    let [leftSheetTitle, setLeftSheetTitle] = useState(
        props.question.leftSheetTitle
    );
    let [rightSheetTitle, setRightSheetTitle] = useState(
        props.question.rightSheetTitle
    );
    return (
        <div
            className="flex-simple-column"
            style={{
                maxHeight: props.maxHeight,
                height: props.maxHeight,
                marginLeft: "90px",
            }}
        >
            <div
                className="flex-simple-column element"
                style={{
                    overflow: "auto",
                }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                onMouseDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                <Select
                    isDisabled={props.frozen}
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Select section"}
                    styles={{
                        ...dataScienceSelectStyles,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                            height: "35px",
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 51 }),
                    }}
                    options={PagesStore(PageType.Canvases).pageToWriteOptions}
                    onChange={(newValue) => {
                        let newQuestion = Object.assign({}, props.question);
                        newQuestion.pageOption = newValue as NumericOption;
                        newQuestion.sheetOption = null;
                        newQuestion.nodeOptions = {};
                        props.onChange(newQuestion);
                    }}
                    value={props.question.pageOption ?? null}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                <div
                    style={{
                        minHeight: "2px",
                        backgroundColor:
                            dataScienceElementsStyle.secondaryTextColor,
                    }}
                />
                {props.question.pageOption && (
                    <>
                        {/*
                            onCreateOption={(option: string) => {
                                let sheetOption = {
                                    label: option,
                                    value: -(newSheets.length + 1),
                                };
                                setNewSheets(
                                    [...newSheets].concat([sheetOption])
                                );
                                let newQuestion = Object.assign(
                                    {},
                                    props.question
                                );
                                newQuestion.sheetOption = sheetOption;
                                newQuestion.nodeOptions = {};
                                props.onChange(newQuestion);
                            }}
                        */}
                        <div
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            <Select
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"Select slide"}
                                styles={{
                                    ...dataScienceSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginTop: "20px",
                                        width: "100%",
                                        height: "35px",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 51,
                                    }),
                                }}
                                options={Canvases(
                                    props.question.pageOption.value
                                )
                                    .canvases.map((canvas) => ({
                                        label: canvas.title,
                                        value: canvas.id,
                                    }))
                                    .concat(newSheets)}
                                onChange={(newValue) => {
                                    let newQuestion = Object.assign(
                                        {},
                                        props.question
                                    );
                                    newQuestion.sheetOption = newValue as NumericOption;
                                    newQuestion.nodeOptions = {};
                                    props.onChange(newQuestion);
                                }}
                                value={props.question.sheetOption ?? null}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                minHeight: "2px",
                                backgroundColor:
                                    dataScienceElementsStyle.secondaryTextColor,
                            }}
                        />
                    </>
                )}
                {props.question.sheetOption &&
                    props.question.sheetOption.value >= 0 && (
                        <>
                            <div className="my-row" style={{ marginTop: 20 }}>
                                <AutosizeInput
                                    autoFocus
                                    placeholder={"PREFIX"}
                                    inputStyle={{
                                        fontSize: mainStyle.getPropertyValue(
                                            "--primary-path-title-size"
                                        ),
                                        border: "none",
                                        resize: "none",
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        fontFamily: "Roboto",
                                        color:
                                            dataScienceElementsStyle.secondaryTextColor,
                                    }}
                                    value={leftSheetTitle ?? ""}
                                    onMouseDown={(evt) => {
                                        evt.stopPropagation();
                                    }}
                                    onChange={(evt) => {
                                        // We have to read name before setState, because evt.target.value might be undefined during setState
                                        let value = evt.target.value;
                                        setLeftSheetTitle(value);
                                    }}
                                    onBlur={(evt) => {
                                        let newQuestion = Object.assign(
                                            {},
                                            props.question
                                        );
                                        newQuestion.leftSheetTitle = leftSheetTitle;
                                        props.onChange(newQuestion);
                                    }}
                                    onKeyDown={(evt) => {
                                        evt.stopPropagation();
                                        if (evt.key === "Enter") {
                                            evt.preventDefault();
                                            let newQuestion = Object.assign(
                                                {},
                                                props.question
                                            );
                                            newQuestion.leftSheetTitle = leftSheetTitle;
                                            props.onChange(newQuestion);
                                        }
                                    }}
                                />
                                <div
                                    style={{ marginTop: -5, width: "100%" }}
                                    onMouseDown={(evt) => {
                                        evt.stopPropagation();
                                    }}
                                >
                                    <Select
                                        isClearable={true}
                                        menuPortalTarget={document.body}
                                        menuShouldBlockScroll={true}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={`Answer`}
                                        styles={{
                                            ...dataScienceSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                width: "100%",
                                                height: "35px",
                                            }),
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 51,
                                            }),
                                        }}
                                        options={answerOptions}
                                        onChange={(newValue) => {
                                            let newQuestion = Object.assign(
                                                {},
                                                props.question
                                            );
                                            newQuestion.titleSheetPlaceholder = newValue as NumericOption;

                                            props.onChange(newQuestion);
                                        }}
                                        value={
                                            props.question.titleSheetPlaceholder
                                        }
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                                <AutosizeInput
                                    autoFocus
                                    placeholder={"SUFFIX"}
                                    inputStyle={{
                                        fontSize: mainStyle.getPropertyValue(
                                            "--primary-path-title-size"
                                        ),
                                        border: "none",
                                        resize: "none",
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        fontFamily: "Roboto",
                                        color:
                                            dataScienceElementsStyle.secondaryTextColor,
                                    }}
                                    value={rightSheetTitle ?? ""}
                                    onMouseDown={(evt) => {
                                        evt.stopPropagation();
                                    }}
                                    onChange={(evt) => {
                                        // We have to read name before setState, because evt.target.value might be undefined during setState
                                        let value = evt.target.value;
                                        setRightSheetTitle(value);
                                    }}
                                    onBlur={(evt) => {
                                        let newQuestion = Object.assign(
                                            {},
                                            props.question
                                        );
                                        newQuestion.rightSheetTitle = rightSheetTitle;
                                        props.onChange(newQuestion);
                                    }}
                                    onKeyDown={(evt) => {
                                        evt.stopPropagation();
                                        if (evt.key === "Enter") {
                                            evt.preventDefault();
                                            let newQuestion = Object.assign(
                                                {},
                                                props.question
                                            );
                                            newQuestion.rightSheetTitle = rightSheetTitle;
                                            props.onChange(newQuestion);
                                        }
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    height: "2px",
                                    backgroundColor:
                                        dataScienceElementsStyle.secondaryTextColor,
                                }}
                            ></div>
                        </>
                    )}
                {props.question.sheetOption &&
                    props.question.sheetOption.value >= 0 &&
                    [...Array(props.restQuestionsCount)].map((_, index) => (
                        <React.Fragment key={index}>
                            <div
                                onMouseDown={(evt: any) => {
                                    evt.stopPropagation();
                                }}
                            >
                                <Select
                                    isClearable={true}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    onKeyDown={(evt) => {
                                        evt.stopPropagation();
                                    }}
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={`Link answer ${
                                        index + 1
                                    } to item`}
                                    styles={{
                                        ...dataScienceSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            marginTop: "20px",
                                            width: "100%",
                                            height: "35px",
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 51,
                                        }),
                                    }}
                                    options={nodeOptions}
                                    onChange={(newValue) => {
                                        let newQuestion = Object.assign(
                                            {},
                                            props.question
                                        );
                                        if (newValue != null) {
                                            newQuestion.nodeOptions[
                                                index
                                            ] = newValue as NumericOption;
                                        } else {
                                            delete newQuestion.nodeOptions[
                                                index
                                            ];
                                        }
                                        props.onChange(newQuestion);
                                    }}
                                    value={
                                        props.question.nodeOptions[index] ??
                                        null
                                    }
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            <div
                                style={{
                                    minHeight: "2px",
                                    backgroundColor:
                                        dataScienceElementsStyle.secondaryTextColor,
                                }}
                            />
                        </React.Fragment>
                    ))}
            </div>
        </div>
    );
});

export default TemplateSheetQuestionView;
