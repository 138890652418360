import React, { Component } from "react";
import Slider, { SliderTooltip, Handle, Range } from "rc-slider";
import { CanvasSlider, formatNumber, NumberFormat, NumberFormatType } from "common/Canvas";
import "./rc-tooltip-htmlslider.css";
import { mainStyle } from "common/MainStyle";
import { formatValue } from "common/utilities/FormatValue";
import { months } from "../Constants";

interface Props {
    rootDataTestId: string;
    formattedValue: string;
    fontColor: string;
    fontSize: number;
    node: CanvasSlider;
    scale: number;
    onMetricChanged: (metrics: string) => void;
}

interface State {
    value: number;
    rangeValue: number[];
}

function formatSliderValue(format: NumberFormat, value: string | number): string {
    const numberType = format?.numberType;

    if (numberType) {
        if (numberType === NumberFormatType.Month) {
            if (value <= months.length)
                return months[value as number - 1] ?? String(value);
        }
        return formatNumber(value as number, format);
    }
    if (!numberType) {
        return formatValue(value, false).join("");
    }

    return String(value);
}

class HTMLSlider extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: this.getValue(props),
            rangeValue: this.getRangeValue(props),
        };
        this.onChange = this.onChange.bind(this);
        this.onAfterChange = this.onAfterChange.bind(this);
        this.renderHandle = this.renderHandle.bind(this);
    }

    private getValue(props: Props): number {
        if (!isNaN(props.node.value as number)) {
            return props.node.value as number;
        } else {
            const min = props.node.minOutput?.value;
            if (min != null && typeof min === "number" && !isNaN(min)) {
                return min;
            } else {
                return 0;
            }
        }
    }

    private getRangeValue(props: Props): number[] {
        const additionalOutputs = props.node?.additionalOutputs;
        const min = props.node.minOutput?.value as number ?? 0;
        const max = props.node.maxOutput?.value as number ?? 100;
        const value = props.node.value ?? min;
        let secondValue = max;
        if (additionalOutputs) {
            secondValue = additionalOutputs[0].value as number;
        }
        return [Number(value), Number(secondValue)];
    }

    public componentDidUpdate(prevProps: Props): void {
        let value = this.getValue(this.props);
        let prevValue = this.getValue(prevProps);
        if (value !== prevValue && value !== this.state.value) {
            this.setState({ value: value });
        }
    }

    private onChange(value: number | number[], isRange: boolean): void {
        if (!isRange) {
            this.setState({
                value: value as number,
            });
            return;
        }

        this.setState({
            rangeValue: value as number[],
        });
    }

    private onAfterChange(value: number | number[]): void {
        this.props.onMetricChanged(String(value));
    }

    private renderHandle(props: {
        className: string;
        prefixCls?: string;
        vertical?: boolean;
        offset: number;
        value: number;
        dragging?: boolean;
        disabled?: boolean;
        min?: number;
        max?: number;
        reverse?: boolean;
        index: number;
        tabIndex?: number;
        ariaLabel: string;
        ariaLabelledBy: string;
        ariaValueTextFormatter: string;
        style?: React.CSSProperties;
        ref?: React.Ref<any>;
    }): React.ReactElement {
        const { value, index, ariaValueTextFormatter, ...restProps } = props;
        const format = this.props.node?.format;

        let sliderFormatedValue: any = value;
        if (format) sliderFormatedValue = formatSliderValue(format, sliderFormatedValue);
        // Vertical and horizontal sliders have to have different keys,
        // otherwise there will be rendering issues if the vertical prop changes
        return (
            <SliderTooltip
                getTooltipContainer={(node) => node.parentNode as HTMLElement}
                prefixCls="rc-tooltip-htmlslider"
                overlay={sliderFormatedValue}
                visible={true}
                placement={props.vertical ? "right" : "top"}
                key={`slider-${
                    props.vertical ? "vertical" : "horizontal"
                }-${index}`}
                overlayStyle={{
                    fontSize: this.props.fontSize * this.props.scale,
                    color: this.props.fontColor,
                    paddingBottom: props.vertical? '':(this.props.node.lineThickness ?? 1)/2 * this.props.scale,
                    paddingLeft: props.vertical? (this.props.node.lineThickness ?? 1)/2* this.props.scale : '',
                }}
            >
                <Handle
                    data-test-id={`${this.props.rootDataTestId}-handle`}
                    value={value}
                    {...restProps}
                />
            </SliderTooltip>
        );
    }

    public render(): JSX.Element {
        const { node } = this.props;
        const isRange = node?.range ?? false;
        const format = node?.format;
        const handleColor = this.props.node?.colorOptions?.handle ??
            mainStyle.getPropertyValue(
                "--slider-handle-color"
            );
        const railColor = this.props.node?.colorOptions?.rail ??
            mainStyle.getPropertyValue(
                "--slider-rail-color"
            );
        const min = this.props.node.minOutput?.value ?? 0;
        const max = this.props.node.maxOutput?.value ?? 100;

        let minLabel = min;
        let maxLabel = max;

        if (format) {
            minLabel = formatSliderValue(format, min as string | number);
            maxLabel = formatSliderValue(format, max as string | number);
        }

        const handleStyle = {
            width: 20 * this.props.scale,
            height: 20 * this.props.scale,
            borderWidth: 3 * this.props.scale,
            marginTop: this.props.node.vertical
                ? undefined
                : this.props.scale*((2 * (this.props.node.lineThickness ?? 1)/4) -10),
            marginLeft: this.props.node.vertical
                ? this.props.scale*((2 * (this.props.node.lineThickness ?? 1)/4) -10)
                : undefined,
            backgroundColor: handleColor,
            borderColor: handleColor,
        }

        const style = {
            height: this.props.node.vertical ? '' : (this.props.node.lineThickness ?? 3)* this.props.scale, 
            width: this.props.node.vertical ? (this.props.node.lineThickness ?? 3)* this.props.scale : '', 
        }

        return (
            <div
                style={{
                    width: "90%",
                    height: "100%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    flexDirection: this.props.node.vertical ? "row" : "column"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        position:'relative',
                        flexDirection: this.props.node.vertical
                            ? "column"
                            : "row",
                        width: this.props.node.vertical ? undefined : "100%",
                        height: this.props.node.vertical ? "100%" : undefined,
                    }}
                >
                    <span
                        className="content-regular-text unselectable"
                        style={{
                            fontSize: this.props.fontSize * this.props.scale,
                            fontFamily: "Roboto",
                            color: this.props.fontColor,
                            textAlign: "center",
                        }}
                    >
                        &nbsp;
                    </span>
                    <div style={{ flex: 1 }} />
                    <span
                        className="content-regular-text unselectable"
                        style={{
                            fontSize: this.props.fontSize * this.props.scale,
                            fontFamily: "Roboto",
                            color: this.props.fontColor,
                            textAlign: "center",
                        }}
                    >
                        &nbsp;
                    </span>
                </div>
                {!isRange && (
                    <Slider
                        className="cancel-drag"
                        vertical={this.props.node.vertical}
                        min={typeof min === "number" ? min : 0}
                        max={typeof max === "number" ? max : 100}
                        step={this.props.node.stepSize ?? 1}
                        style={style}
                        value={this.state.value}
                        onChange={(value) => this.onChange(value, false)}
                        onAfterChange={this.onAfterChange}
                        handleStyle={handleStyle}
                        trackStyle={{ 
                            height: this.props.node.vertical ? '' : (this.props.node.lineThickness ?? 3)* this.props.scale, 
                            width: this.props.node.vertical ? (this.props.node.lineThickness ?? 3)* this.props.scale : '', 
                            backgroundColor: handleColor,
                            zIndex: -1
                        }}
                        railStyle={{ 
                            height: this.props.node.vertical ? '' : (this.props.node.lineThickness ?? 3)* this.props.scale, 
                            width: this.props.node.vertical ? (this.props.node.lineThickness ?? 3)* this.props.scale : '', 
                            backgroundColor: railColor,
                            zIndex: -1 
                        }}
                        handle={this.renderHandle}
                    />
                )}
                {isRange && (
                    <Range
                        className="cancel-drag"
                        vertical={this.props.node.vertical}
                        min={typeof min === "number" ? min : 0}
                        max={typeof max === "number" ? max : 100}
                        step={this.props.node.stepSize ?? 1}
                        style={style}
                        value={this.state.rangeValue}
                        onChange={(value) => this.onChange(value, true)}
                        onAfterChange={this.onAfterChange}
                        handleStyle={[handleStyle, handleStyle]}
                        trackStyle={[{ backgroundColor: handleColor }, { backgroundColor: handleColor }]}
                        railStyle={{ backgroundColor: railColor }}
                        handle={this.renderHandle}
                    />
                )}
                <div
                    style={{
                        display: "flex",
                        flexDirection: this.props.node.vertical
                            ? "column"
                            : "row",
                        width: this.props.node.vertical ? undefined : "100%",
                        height: this.props.node.vertical ? "100%" : undefined,
                    }}
                >
                    <span
                        className="content-regular-text unselectable"
                        style={{
                            fontSize: this.props.fontSize * this.props.scale,
                            fontFamily: "Roboto",
                            color: this.props.fontColor,
                            textAlign: "center",
                            marginLeft: 5 * this.props.scale,
                            position:'absolute',
                            left: this.props.node.vertical? '' : '10px',
                            bottom: this.props.node.vertical? '' : '0px',
                            right: this.props.node.vertical? '10px' : '',
                        }}
                    >
                        {this.props.node.vertical ? maxLabel : minLabel}
                    </span>
                    <div style={{ flex: 1 }} />
                    <span
                        className="content-regular-text unselectable"
                        style={{
                            fontSize: this.props.fontSize * this.props.scale,
                            fontFamily: "Roboto",
                            color: this.props.fontColor,
                            textAlign: "center",
                            marginRight: 5 * this.props.scale,
                            position:'absolute',
                            right: '10px',
                            bottom: '0px'
                        }}
                    >
                        {this.props.node.vertical ? minLabel : maxLabel}
                    </span>
                </div>
            </div>
        );
    }
}

export default HTMLSlider;
