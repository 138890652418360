import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Popup from "reactjs-popup";
import { observer } from "mobx-react";

import axios from "common/ServerConnection";
import Alert from "common/Alert";
import CanvasTreeStore from "./CanvasTreeStore";
import { mainStyle } from "common/MainStyle";

interface Props {
    canvasTreeStore: CanvasTreeStore;
    scale: number;
    onCreate: (title: string) => void;
    onClose: () => void;
    rootRef: React.Ref<HTMLElement>;
}

enum LoadStatus {
    NotUploaded = 1,
    Uploading = 2,
    Success = 3,
    Error = 4,
}

interface State {
    title: string;
    errorMessage: string | undefined;
    status: LoadStatus;
}

@observer
class CreateNamedVersionPopup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            title: "",
            errorMessage: undefined,
            status: LoadStatus.NotUploaded,
        };
    }

    private createNamedVersion(): void {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                versions: {
                    id: number;
                    created_automatically: boolean;
                    creation_time: number;
                    modification_time: number;
                    title: string | null;
                    thumbnail: string | null;
                }[];
            }>("/api/canvas_create_named_version", {
                canvas_id: this.props.canvasTreeStore.canvasId,
                title: this.state.title,
            })
            .then((response) => {
                if (!response.data.success) {
                    this.setState({
                        status: LoadStatus.Error,
                        errorMessage: response.data.error_msg,
                    });
                } else {
                    this.setState({
                        status: LoadStatus.Success,
                        errorMessage: undefined,
                    });
                    this.props.onCreate(this.state.title);
                    this.props.onClose();
                }
            })
            .catch((error) => {
                this.setState({
                    status: LoadStatus.Error,
                    errorMessage: error.toString(),
                });
            });
    }

    private buildInnerView(): JSX.Element {
        return (
            <div
                className="flex-simple-column"
                style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    overflow: "visible",
                }}
            >
                <span
                    style={{
                        marginRight: "1em",
                        marginTop: "20px",
                        marginBottom: "5px",
                        textAlign: "left",
                        color: mainStyle.getPropertyValue(
                            "--popup-primary-text-color"
                        ),
                        fontFamily: "Roboto",
                        fontSize: 14,
                    }}
                >
                    Create Named Version
                </span>
                <input
                    className="like-select"
                    placeholder={"Enter name"}
                    value={this.state.title}
                    onChange={(e) => {
                        let newTitle = e.target.value;
                        this.setState({ title: newTitle });
                    }}
                />
                <div
                    className="my-row"
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        alignSelf: "flex-end",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Button
                        type="button"
                        disabled={
                            this.state.title.length === 0 ||
                            this.state.status === LoadStatus.Uploading
                        }
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            width: "112px",
                        }}
                        onClick={() => {
                            if (this.state.title.length !== 0) {
                                this.createNamedVersion();
                            }
                        }}
                    >
                        CREATE
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={() => {
                            this.props.onClose();
                        }}
                    >
                        CLOSE
                    </Button>
                </div>
                {this.state.status === LoadStatus.Error && (
                    <Alert
                        text={"Error: ".concat(this.state.errorMessage ?? "")}
                        className="alert alert-danger alert-dismissible"
                        onClosed={() =>
                            this.setState({
                                status: LoadStatus.NotUploaded,
                                errorMessage: undefined,
                            })
                        }
                    />
                )}
                {this.state.status === LoadStatus.Uploading && (
                    <Alert
                        text={"Saving"}
                        className="alert alert-warning"
                        onClosed={() => {}}
                    />
                )}
            </div>
        );
    }

    render() {
        let height = 150;
        let width = 350;
        return (
            <Popup
                arrow={true}
                contentStyle={{
                    width: width,
                    minHeight: height,
                    border: "none",
                    backgroundColor: "transparent",
                }}
                open={true}
                onClose={() => {
                    this.props.onClose();
                }}
                closeOnDocumentClick
            >
                <div
                    className="dashboard-rect"
                    style={{
                        overflow: "visible",
                        boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                        borderRadius: 0,
                        alignItems: "center",
                        cursor: "pointer",
                        minHeight: height,
                        width: width,
                    }}
                >
                    {this.buildInnerView()}
                </div>
            </Popup>
        );
    }
}

export default CreateNamedVersionPopup;
