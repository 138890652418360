import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";

import {
    CorrelogramFinding,
    defaultCorrelogramOneColor,
    defaultCorrelogramZeroColor,
    defaultCorrelogramMinusOneColor,
    defaultCorrelogramVariableNamesColor,
    defaultCorrelogramFontSize,
    defaultCorrelogramMinRadius,
    defaultCorrelogramMaxRadius,
} from "common/Finding";
import * as d3 from "d3";
import { getGridColorByTheme } from "../../BarChartTheme";
import D3ChartBase from "common/graphics/v2/D3ChartBase";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import AnimationSlider from "common/AnimationSlider";

interface Props {
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    content: CorrelogramFinding["content"];
    config: CorrelogramFinding["config"];
    onChangeData?: (
        data: CorrelogramFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: CorrelogramFinding["config"],
        updateData?: boolean
    ) => void;
    onChangeContent?: (
        content: CorrelogramFinding["content"],
        updateData?: boolean
    ) => void;
    width: number;
    height: number;
    scale: number;
}

interface Point {
    i: number;
    j: number;
    value: number;
}

export default function CorrelogramD3(props: Props) {
    let parentRef = React.useRef<HTMLDivElement>(null);
    let parentSvgRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);
    // Circles on the top-right
    let circlesRef = React.useRef<
        d3.Selection<SVGCircleElement, Point, SVGGElement, unknown>
    >();
    // Text on the bottom-left
    let textRef = React.useRef<
        d3.Selection<SVGTextElement, Point, SVGGElement, unknown>
    >();
    // Variable names on the diagonal
    let variableNamesRef = React.useRef<
        d3.Selection<SVGTextElement, string, SVGGElement, unknown>
    >();

    const [errorMessage, setErrorMessage] = useState<{
        message: string;
        status: PopupStatus;
    }>();

    let linkVariable = (
        variableName: string,
        variableIndex: number,
        variableType: string
    ) => {
        if (variableType === "float" || variableType === "int") {
            let newData = Array.from(
                props.content.data.filter((item) => item.variableIndex != null)
            );
            newData.push({
                name: variableName,
                value: [],
                originalName: variableName,
                variableIndex: variableIndex,
            });
            props.onChangeData?.(newData, true);
        } else {
            setErrorMessage({
                message: `${variableName} is not numeric`,
                status: PopupStatus.Error,
            });
        }
    };

    let dropRef = React.useRef<HTMLDivElement>(null);
    const [collected, drop] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                    variableType: string;
                };
            },
            _monitor
        ) {
            linkVariable(
                otherItem.content.variableName,
                otherItem.content.variableIndex,
                otherItem.content.variableType
            );
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    if (props.editable) {
        drop(dropRef);
    }

    useEffect(() => {
        let gridFillColor = getGridColorByTheme(
            props.config.chartTheme,
            props.config.baseBackgroundColor
        );

        let fullHeight = parentSvgRef.current?.clientHeight ?? 0;
        let fullWidth = parentSvgRef.current?.clientWidth ?? 0;
        // set the dimensions and margins of the graph
        let height = fullHeight;
        let width = fullWidth;

        // append the svg object to the body of the page
        d3.select(svgRef.current!).selectAll("*").remove();
        let svg = d3
            .select(svgRef.current!)
            .attr("width", fullWidth)
            .attr("height", fullHeight)
            .append("g")
            .style("cursor", "crosshair");

        let base = new D3ChartBase(svg, width, height);

        base.drawBackground(gridFillColor ?? "transparent");

        let points: Point[] = [];
        for (let i = 1; i < props.content.data.length; ++i) {
            for (let j = 0; j < i; ++j) {
                points.push({
                    i: i,
                    j: j,
                    value: props.content.data[i]?.value[j] ?? 0,
                });
            }
        }

        // Padding is necessary to prevent the text from being cut off
        let padding = 0.2;

        let xScale = d3
            .scalePoint<number>()
            .range([0, width])
            .padding(padding)
            .domain(d3.range(props.content.data.length));

        let yScale = d3
            .scalePoint<number>()
            .range([0, height])
            .padding(padding)
            .domain(d3.range(props.content.data.length));

        let radiusScale = d3
            .scaleSqrt()
            .domain([0, 1])
            .range([
                props.config.minRadius ?? defaultCorrelogramMinRadius,
                props.config.maxRadius ?? defaultCorrelogramMaxRadius,
            ]);

        let colorScale = d3
            .scaleLinear()
            .domain([-1, 0, 1])
            .range([
                props.config.minusOneColor ?? defaultCorrelogramMinusOneColor,
                props.config.zeroColor ?? defaultCorrelogramZeroColor,
                props.config.oneColor ?? defaultCorrelogramOneColor,
            ]);

        circlesRef.current = svg
            .append("g")
            .selectAll<SVGCircleElement, Point>("circle")
            .data(points)
            .join("circle")
            .attr("cx", (d) => xScale(d.i)!)
            .attr("cy", (d) => yScale(d.j)!)
            .attr("r", (d) => radiusScale(Math.abs(d.value)))
            .style("fill", (d) => colorScale(d.value));

        variableNamesRef.current = svg
            .append("g")
            .selectAll<SVGTextElement, string>("text")
            .data(props.content.data.map((item) => item.name))
            .join("text")
            .text((d) => d)
            .attr("x", (_d, i) => xScale(i)!)
            .attr("y", (_d, i) => yScale(i)!)
            .attr("dominant-baseline", "middle")
            .attr("text-anchor", "middle")
            .style(
                "font-size",
                props.config.fontSize ?? defaultCorrelogramFontSize
            )
            .style("text-align", "center")
            .style(
                "fill",
                props.config.variableNamesColor ??
                    defaultCorrelogramVariableNamesColor
            );

        textRef.current = svg
            .append("g")
            .selectAll<SVGTextElement, Point>("text")
            .data(points)
            .join("text")
            .text((d) => d.value.toFixed(2))
            .attr("x", (d) => xScale(d.j)!)
            .attr("y", (d) => yScale(d.i)!)
            .attr("dominant-baseline", "middle")
            .attr("text-anchor", "middle")
            .style(
                "font-size",
                props.config.fontSize ?? defaultCorrelogramFontSize
            )
            .style("text-align", "center")
            .style("fill", (d) => colorScale(d.value));
    }, [
        props.content.data,
        props.content.time,
        props.content.time?.value,
        props.content.time?.uniqueValues,
        props.config.oneColor,
        props.config.zeroColor,
        props.config.minusOneColor,
        props.config.variableNamesColor,
        props.config.fontSize,
        props.config.minRadius,
        props.config.maxRadius,
        props.config.chartTheme,
        props.config.baseBackgroundColor,
        props.preview,
        props.width,
        props.height,
        props.scale,
    ]);

    return (
        <div style={{ height: "100%" }}>
            <div
                ref={parentRef}
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {props.content.time?.variableIndex != null &&
                    props.content.time?.uniqueValues.length !== 0 && (
                        <AnimationSlider
                            sliderStyle={{
                                cursor: "default",
                                width: 165,
                                pointerEvents: "auto",
                            }}
                            values={props.content.time.uniqueValues}
                            onChange={() => {}}
                        />
                    )}
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // Resizing does not work properly
                            // without overflow: "hidden"
                            overflow: "hidden",
                        }}
                    >
                        <div
                            ref={parentSvgRef}
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                position: "relative",
                                // Resizing does not work properly
                                // without overflow: "hidden"
                                overflow: "hidden",
                            }}
                        >
                            <svg ref={svgRef} />
                            <div
                                style={{
                                    left: 0,
                                    top: 0,
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    display: props.columnDragActive
                                        ? "flex"
                                        : "none",
                                }}
                            >
                                <div
                                    ref={dropRef}
                                    style={{
                                        width: "100%",
                                        borderStyle: "dashed",
                                        borderWidth: 1,
                                        borderColor: collected.hover
                                            ? "#36B743"
                                            : "#8DB8E3",
                                        display: "flex",
                                        background: collected.hover
                                            ? "#F4FBF5"
                                            : "#EBF2F9",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <span
                                        className="no-selection"
                                        style={{
                                            backgroundColor: "transparent",
                                            fontFamily: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            textAlign: "center",
                                            color: "#333333",
                                        }}
                                    >
                                        Drop variable here
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {errorMessage != null && (
                <StatusPopup
                    onClose={() => {
                        setErrorMessage(undefined);
                    }}
                    status={errorMessage.status}
                    message={errorMessage.message}
                />
            )}
        </div>
    );
}
