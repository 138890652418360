import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import { mainStyle } from "common/MainStyle";
import { observer } from "mobx-react";
import Select, { createFilter } from "react-select";
import TextareaAutosize from "react-autosize-textarea";
import customSelectStyles from "common/SelectStyles";
import UserGroupsSelector from "common/UserGroupsSelector";
import Alert from "common/Alert";
import { renderCanvas } from "./utilities/renderCanvas";
import { BriefModule, BriefModuleRequest, SpecialTabId } from "common/Module";
import {
    addModuleApi,
    getModuleUserDataSetsApi,
    getSharedModulesSpecialTabApi,
    getSharedModulesWithCategories,
    getTemplateDataSetsApi,
    getTemplateModulesApi,
} from "common/ModulesApi";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import DataSetsSelector, {
    DataSet,
    AccessType,
} from "common/canvas/DataSetsSelector";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import ProductParametersInput, {
    PaymentType,
} from "common/mystripe_components/ProductParametersInput";
import {
    addSelfSignupLinkToModuleApi,
    getModulePaywallApi,
    getSelfSignupLinkToModuleApi,
    PaywallInfo,
    removeSelfSignupLinkFromModuleApi,
} from "common/ModulesPaywallApi";
import { selfSignupModuleLinkIdIdToUrl } from "common/utilities/linkIdToUrl";
import CopyToClipboard from "react-copy-to-clipboard";
import { SelfSignupInfo } from "common/ModulesApi";
import LinkCustomizePopup from "common/canvas/LinkCustomizePopup";
import _ from "lodash";
import { getCategoriesApi } from "common/CategoriesApi";
import NumericOption from "common/NumericOption";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import Dropzone from "react-dropzone";
import CurrentUser from "common/CurrentUser";
import { Permission } from "common/Permissions";
import { resizeBase64Img } from "common/utilities/resizeImage";

interface Props {
    moduleId: number;
    onCancel: () => void;
    rootRef: React.Ref<HTMLElement>;
    canvasTreeStore: CanvasTreeStore;
}

enum UploadStatus {
    NotStarted = 1,
    Started = 2,
    Success = 3,
    Error = 4,
}

interface State {
    hoverKeywordIndex: number | undefined;
    moduleTitle: string;
    moduleThumbnail: string | undefined;
    image: Blob | null;
    moduleDescription: string;
    moduleTitleIsEditing: boolean;
    moduleDescriptionIsEditing: boolean;
    moduleKeywords: string[];
    newKeyword: string;
    selectedUserGroups: string[];
    message: string;
    uploadStatus: UploadStatus;
    selectedDataSet: DataSet | null;
    categories: NumericOption[];
    categoryId?: number;
    selectedDataSets: DataSet[];

    // Put the template into 'Create New' tab -- which is featured on the front page
    intoCreateNew: boolean;

    intoTutorials: boolean;
    intoTutorialsInitialList: boolean;
    publishAsKit: boolean;

    // This is needed to add in a Paywall
    addPayWall: boolean;
    price: string;
    name: string;
    client: string;
    logo: string;
    paymentType: PaymentType;

    templates: BriefModule[];
    selectedTemplate?: BriefModule;

    selfSignupInfo: SelfSignupInfo | null;
    selfSignupLinkId: string | null;
    showSignupPopupInfo: boolean;
}

// Paywall Options: could pull from Stripe API directly.
// const paywallOptions = [
//     { value: "onetime", label: "One Time" },
//     { value: "monthly", label: "Monthly" },
//     { value: "annual", label: "Annual" },
// ];

@observer
class ModuleCreator extends Component<Props, State> {
    private performance: Date | null;
    constructor(props: Props) {
        super(props);
        this.state = {
            hoverKeywordIndex: undefined,
            uploadStatus: UploadStatus.NotStarted,
            message: "",
            moduleTitle: "",
            moduleDescription: "",
            moduleTitleIsEditing: false,
            moduleDescriptionIsEditing: false,
            selectedUserGroups: [],
            newKeyword: "",
            moduleThumbnail: undefined,
            moduleKeywords: [],
            selectedDataSet: null,
            selectedDataSets: [],
            intoCreateNew: false,
            intoTutorials: false,
            intoTutorialsInitialList: false,
            addPayWall: false,
            publishAsKit: false,
            price: "",
            name: "",
            paymentType: "one_time",
            client: "",
            logo: "",
            templates: [],
            selfSignupInfo: null,
            selfSignupLinkId: null,
            showSignupPopupInfo: false,
            categories: [],
            image: null
        };
        this.performance = null;

        this.buildInnerView = this.buildInnerView.bind(this);
    }

    public componentDidMount(): void {
        this.getTemplatesForModule();
        this.getCategories();

        this.getDataSetsSharedInModule()
            .then((dataSets) => {
                this.setState({
                    selectedDataSets: dataSets.map((dataSet) => ({
                        ...dataSet,
                        accessType: AccessType.CreateLocalCopy,
                    })),
                });
            })
            .catch((error) => {
                if (
                    error?.response?.status !== 401 &&
                    error?.response?.status !== 403
                ) {
                    console.log(error);
                }
            });
    }

    private async getCategories() {
        try {
            let categories = await getCategoriesApi();
            this.setState({
                categories: categories.map((category) => ({
                    label: category.title,
                    value: category.id,
                })),
            });
        } catch (error) {
            console.log(String(error));
        }
    }
    private async getTemplatesForModule() {
        try {
            let templates = await getTemplateModulesApi(
                undefined,
                undefined,
                this.props.moduleId
            );
            let specialTabTemplates = await getSharedModulesSpecialTabApi(
                [
                    SpecialTabId.Tutorials,
                    SpecialTabId.TutorialsInitialList,
                    SpecialTabId.CreateNew,
                    SpecialTabId.Kit,
                ],
                undefined,
                undefined,
                this.props.moduleId
            );
            let templatesWithCategories = await getSharedModulesWithCategories(
                undefined,
                undefined,
                this.props.moduleId
            );
            let allTemplates = templates
                .concat(specialTabTemplates)
                .concat(templatesWithCategories);

            this.setState({
                templates: _.uniqBy(allTemplates, "id"),
            });
        } catch (error) {
            console.log(String(error));
        }
    }
    private async getDataSetsSharedInModule(): Promise<DataSet[]> {
        let dataSets = await getModuleUserDataSetsApi(
            this.props.moduleId,
            false
        );
        let selectedDataSets: DataSet[] = [];
        // Only include data sets shared by current user
        for (let dataSet of dataSets) {
            if (dataSet.user === CurrentUser.info?.user_name) {
                selectedDataSets.push({
                    label: dataSet.data_table_name,
                    value: dataSet.data_table_idx,
                    accessType:
                        dataSet.permission_type === Permission.ReadWrite
                            ? AccessType.CanEdit
                            : dataSet.allow_uploading
                            ? AccessType.CanSubmit
                            : AccessType.CanRead,
                });
            }
        }
        return selectedDataSets;
    }

    private buildInnerView(globalContext: GlobalContextContents): JSX.Element {
        const { canvasTreeStore } = this.props;
        return (
            <div
                className="flex-simple-column"
                style={{
                    width: "100%",
                }}
            >
                <span
                    style={{
                        marginTop: 20,
                        color: mainStyle.getPropertyValue(
                            "--popup-primary-text-color"
                        ),
                        fontSize: "17px",
                        fontWeight: 400,
                        fontFamily: "Roboto",
                    }}
                    className="unselectable"
                >
                    Create a new template
                </span>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{ marginTop: 20, width: "calc(50% - 26px)" }}
                    >
                        <span
                            style={{
                                marginLeft: 23,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Title
                        </span>
                        <input
                            className="like-select module-template"
                            style={{ marginTop: 9, marginLeft: 21 }}
                            placeholder="Give the template a name"
                            value={this.state.moduleTitle}
                            onChange={(evt) => {
                                let value = (evt.target as any)?.value;
                                this.setState({ moduleTitle: value });
                            }}
                        />
                        <span
                            style={{
                                marginTop: 15,
                                marginLeft: 23,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Keywords
                        </span>
                        <div
                            className="my-row"
                            style={{ marginLeft: 21, marginTop: 9 }}
                        >
                            <input
                                style={{ width: "100%" }}
                                className="like-select module-template"
                                placeholder="Type to add keyword"
                                onChange={(e) => {
                                    const value: string = e.target.value;
                                    this.setState({ newKeyword: value });
                                }}
                                value={this.state.newKeyword}
                            />
                            <Button
                                disabled={this.state.newKeyword.length === 0}
                                title={"Type to add keyword"}
                                className="btn-small-like-select"
                                style={{
                                    marginLeft: -25,
                                    alignSelf: "center",
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let moduleKeywords = [
                                        ...this.state.moduleKeywords,
                                    ];
                                    moduleKeywords.push(this.state.newKeyword);
                                    this.setState({
                                        moduleKeywords: moduleKeywords,
                                        newKeyword: "",
                                    });
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>
                        </div>
                        <div
                            style={{
                                marginTop: 17,
                                marginLeft: 63,
                                display: "grid",
                                rowGap: "8px",
                                columnGap: "30px",
                                gridTemplateColumns: "repeat(3, 100px)",
                            }}
                        >
                            {this.state.moduleKeywords.map(
                                (keyword, keywordIndex) => (
                                    <div
                                        className="my-row"
                                        key={keywordIndex}
                                        onMouseEnter={() => {
                                            this.setState({
                                                hoverKeywordIndex: keywordIndex,
                                            });
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({
                                                hoverKeywordIndex: undefined,
                                            });
                                        }}
                                    >
                                        <span
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width: "77px",
                                                marginLeft: 2,
                                                color: mainStyle.getPropertyValue(
                                                    "--popup-primary-text-color"
                                                ),
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                fontFamily: "Roboto",
                                            }}
                                            className="unselectable"
                                        >
                                            {keyword}
                                        </span>
                                        {this.state.hoverKeywordIndex ===
                                            keywordIndex && (
                                            <div
                                                className="flex-simple-column"
                                                style={{ marginLeft: 2 }}
                                            >
                                                <Button
                                                    title={"Remove keyword"}
                                                    className="btn-small-like-select"
                                                    style={{
                                                        width: "19px",
                                                        height: "19px",
                                                    }}
                                                    onClick={() =>
                                                        this.setState(
                                                            (state) => {
                                                                let keywords = Array.from(
                                                                    state.moduleKeywords
                                                                );
                                                                keywords.splice(
                                                                    keywordIndex,
                                                                    1
                                                                );
                                                                return {
                                                                    moduleKeywords: keywords,
                                                                };
                                                            }
                                                        )
                                                    }
                                                >
                                                    {"\uFF0D" /* minus */}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                        <span
                            style={{
                                marginLeft: 23,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Thumbnail (leave empty to make default)
                        </span>
                        <div
                            className="manifest-data flex-simple-column"
                            style={{ width: "calc(50% - 26px)" }}
                        >
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    if (acceptedFiles.length > 0) {
                                        const file = acceptedFiles[0];
                                        const { type } = file;
                                        if (
                                            !type.includes("image") ||
                                            type.includes("gif")
                                        ) {
                                            this.setState({
                                                uploadStatus:
                                                    UploadStatus.Error,
                                                message: String(
                                                    "The file should be image type"
                                                ),
                                            });
                                            return;
                                        }
                                        const reader = new FileReader();

                                        reader.onload = () => {
                                            const binaryStr =
                                                typeof reader.result ===
                                                    "string"
                                                    ? reader.result.toString()
                                                    : "";

                                            //generates a 50% smaller image to save as thumbnail
                                            resizeBase64Img(binaryStr, .5).then((res) => {
                                                this.setState({
                                                    moduleThumbnail: res.thumbnail,
                                                    image: file
                                                });
                                            });
                                            
                                          
                                        };

                                        reader.readAsDataURL(acceptedFiles[0]);
                                    }
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section
                                        style={{
                                            display: "flex",
                                            margin: "10px 0 0 21px",
                                            width: '100px',
                                            height: "100px",
                                            textAlign: "center",
                                            borderRadius: "4px",
                                            alignItems: "center",
                                            backgroundColor: "rgb(217, 221, 225)",
                                            color: "#000000",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <div
                                            {...getRootProps()}
                                            style={{
                                                position: "relative",
                                                width: "100%",
                                                height: "100%",
                                                padding: "5px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                display: "flex"
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {!this.state.moduleThumbnail && (
                                                <p>Select File to Upload</p>
                                            )}
                                            <img
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    padding: "2px"
                                                }}
                                                src={this.state.moduleThumbnail}
                                                alt=""
                                            />
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                    <div
                        className="flex-simple-column"
                        style={{
                            marginLeft: 53,
                            marginTop: 20,
                            width: "calc(50% - 26px)",
                        }}
                    >
                        <span
                            style={{
                                marginLeft: 2,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Description
                        </span>
                        <TextareaAutosize
                            className="insights-text-area-popup module-template"
                            placeholder="Elaborate on your template"
                            value={this.state.moduleDescription}
                            style={{
                                paddingLeft: 11,
                                paddingTop: 11,
                                paddingBottom: 11,
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: "Roboto",
                                backgroundColor: mainStyle.getPropertyValue(
                                    "--selectors-background-color"
                                ),
                                minHeight: 181,
                                marginTop: 9,
                                marginLeft: 0,
                                marginRight: 0,
                                marginBottom: 0,
                            }}
                            onChange={(evt) => {
                                let value = (evt.target as any)?.value;
                                this.setState({ moduleDescription: value });
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        marginTop: "13px",
                        height: "1px",
                        backgroundColor: "#CCC",
                        width: "100%",
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{
                            marginTop: 20,
                            width: "calc(50% - 26px)",
                        }}
                    >
                        <span
                            style={{
                                marginLeft: 23,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Access Settings
                        </span>
                        {this.state.selectedTemplate != null && (
                            <div
                                className="my-row"
                                style={{
                                    marginTop: 10,
                                    marginLeft: 16,
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        marginRight: 5,
                                        color:
                                            "var(--popup-primary-text-color)",
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        fontFamily: "Roboto",
                                        width: "17em",
                                    }}
                                    className="unselectable"
                                >
                                    Permanent self-served signup link:
                                </span>
                                {this.state.selfSignupLinkId != null ? (
                                    <>
                                        <CopyToClipboard
                                            text={selfSignupModuleLinkIdIdToUrl(
                                                this.state.selfSignupLinkId
                                            )}
                                            options={{
                                                format: "text/plain",
                                            }}
                                            onCopy={() => {
                                                this.setState({
                                                    uploadStatus:
                                                        UploadStatus.Success,
                                                    message:
                                                        "Self-Served Signup Link Copied to Clipboard",
                                                });
                                            }}
                                        >
                                            <div
                                                className="my-row"
                                                style={{
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    src="/dist/img/canvas/share_module_link.png"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        marginLeft: 5,
                                                        color: mainStyle.getPropertyValue(
                                                            "--popup-secondary-text-color"
                                                        ),
                                                        fontSize: "12px",
                                                        fontWeight: 500,
                                                        fontFamily: "Roboto",
                                                    }}
                                                    className="unselectable"
                                                >
                                                    COPY
                                                </span>
                                            </div>
                                        </CopyToClipboard>
                                        <Button
                                            type="button"
                                            className="btn btn-sm btn-primary my-primary"
                                            style={{
                                                marginLeft: "30px",
                                                width: "112px",
                                                height: "33px",
                                                paddingTop: "6.5px",
                                            }}
                                            onClick={() => {
                                                removeSelfSignupLinkFromModuleApi(
                                                    this.state.selectedTemplate!
                                                        .id
                                                )
                                                    .then(() => {
                                                        this.setState({
                                                            selfSignupLinkId: null,
                                                        });
                                                    })
                                                    .catch((error: string) => {
                                                        this.setState({
                                                            uploadStatus:
                                                                UploadStatus.Error,
                                                            message: String(
                                                                error
                                                            ),
                                                        });
                                                    });
                                            }}
                                        >
                                            REMOVE
                                        </Button>
                                        <Button
                                            type="button"
                                            className="btn btn-sm btn-primary my-primary"
                                            style={{
                                                marginLeft: "5px",
                                                width: "112px",
                                                height: "33px",
                                                paddingTop: "6.5px",
                                                marginRight: "5px",
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    showSignupPopupInfo: true,
                                                });
                                            }}
                                        >
                                            CUSTOMIZE
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{
                                            width: "112px",
                                            height: "33px",
                                            paddingTop: "6.5px",
                                        }}
                                        onClick={() => {
                                            addSelfSignupLinkToModuleApi(
                                                this.state.selectedTemplate!.id,
                                                null,
                                                null
                                            )
                                                .then((selfSignupLinkId) => {
                                                    this.setState({
                                                        selfSignupLinkId: selfSignupLinkId,
                                                    });
                                                })
                                                .catch((error) => {
                                                    this.setState({
                                                        uploadStatus:
                                                            UploadStatus.Error,
                                                        message: String(error),
                                                    });
                                                });
                                        }}
                                    >
                                        CREATE
                                    </Button>
                                )}
                            </div>
                        )}
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 21,
                                marginTop: 9,
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginRight: 5,
                                }}
                                className="unselectable"
                            >
                                Publish as a kit
                            </span>
                            <Switch
                                onChange={(checked) => {
                                    this.setState({
                                        publishAsKit: checked,
                                        intoTutorials: false,
                                        intoCreateNew: false,
                                    });
                                }}
                                checked={this.state.publishAsKit}
                                width={40}
                                height={20}
                                offColor="#CCC"
                                onColor="#CCC"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 21,
                                marginTop: 9,
                                alignItems: "center",
                                display:
                                    "ImportModulesToCreateNew" in
                                    globalContext.permissions
                                        ? "flex"
                                        : "none",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginRight: 5,
                                }}
                                className="unselectable"
                            >
                                Feature on Front Page
                            </span>
                            <Switch
                                onChange={(checked) => {
                                    this.setState({
                                        intoCreateNew: checked,
                                        intoTutorials: false,
                                        publishAsKit: false,
                                    });
                                }}
                                checked={this.state.intoCreateNew}
                                width={40}
                                height={20}
                                offColor="#CCC"
                                onColor="#CCC"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 21,
                                marginTop: 9,
                                alignItems: "center",
                                display:
                                    "ImportModulesToTutorials" in
                                    globalContext.permissions
                                        ? "flex"
                                        : "none",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginRight: 5,
                                }}
                                className="unselectable"
                            >
                                Add to tutorials
                            </span>
                            <Switch
                                onChange={(checked) => {
                                    this.setState((state) => ({
                                        intoTutorials: checked,
                                        intoTutorialsInitialList:
                                            checked &&
                                            state.intoTutorialsInitialList,
                                        intoCreateNew: false,
                                        publishAsKit: false,
                                    }));
                                }}
                                checked={this.state.intoTutorials}
                                width={40}
                                height={20}
                                offColor="#CCC"
                                onColor="#CCC"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                        {this.state.intoTutorials && (
                            <div
                                className="my-row"
                                style={{
                                    marginLeft: 21,
                                    marginTop: 9,
                                    alignItems: "center",
                                    display:
                                        "ImportModulesToTutorials" in
                                        globalContext.permissions
                                            ? "flex"
                                            : "none",
                                }}
                            >
                                <span
                                    style={{
                                        marginLeft: 23,
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontFamily: "Roboto",
                                        marginRight: 5,
                                    }}
                                    className="unselectable"
                                >
                                    Add to tutorials initial list
                                </span>
                                <Switch
                                    onChange={(checked) => {
                                        this.setState({
                                            intoTutorialsInitialList: checked,
                                        });
                                    }}
                                    checked={
                                        this.state.intoTutorialsInitialList
                                    }
                                    width={40}
                                    height={20}
                                    offColor="#CCC"
                                    onColor="#CCC"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        )}
                        {this.state.templates.length > 0 && (
                            <div
                                style={{
                                    marginTop: 9,
                                    marginLeft: 21,
                                }}
                            >
                                <span
                                    style={{
                                        marginLeft: 23,
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontFamily: "Roboto",
                                        marginRight: 5,
                                    }}
                                    className="unselectable"
                                >
                                    Rewrite existing
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    isClearable
                                    placeholder=""
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            marginTop: 5,
                                            marginLeft: 23,
                                            height: "38px",
                                            width: "16em",
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 100000000,
                                        }),
                                    }}
                                    options={this.state.templates.map(
                                        (template) => ({
                                            value: template.id,
                                            label: template.title,
                                            template: template,
                                        })
                                    )}
                                    value={
                                        this.state.selectedTemplate
                                            ? {
                                                  value: this.state
                                                      .selectedTemplate.id,
                                                  label: this.state
                                                      .selectedTemplate.title,
                                              }
                                            : null
                                    }
                                    onChange={async (option) => {
                                        if (option == null) {
                                            this.setState({
                                                selectedTemplate: undefined,
                                            });
                                        } else {
                                            let template = (option as any)
                                                .template as BriefModule;
                                            this.setState({
                                                selectedTemplate: (option as any)
                                                    .template,
                                                categoryId:
                                                    template.category?.id,
                                                moduleTitle: template.title,
                                                moduleThumbnail:
                                                    template.thumbnail ?? "",
                                                moduleKeywords:
                                                    template.keywords ?? [],
                                                moduleDescription:
                                                    template.description ?? "",
                                                intoTutorialsInitialList:
                                                    template.tab_id ===
                                                    SpecialTabId.TutorialsInitialList,
                                                intoTutorials:
                                                    template.tab_id ===
                                                        SpecialTabId.Tutorials ||
                                                    template.tab_id ===
                                                        SpecialTabId.TutorialsInitialList,
                                                intoCreateNew:
                                                    template.tab_id ===
                                                    SpecialTabId.CreateNew,
                                                publishAsKit:
                                                    template.tab_id ===
                                                    SpecialTabId.Kit,
                                            });
                                            try {
                                                let paywallInfo: PaywallInfo | null = null;
                                                let paywallState: Pick<
                                                    State,
                                                    | "addPayWall"
                                                    | "price"
                                                    | "name"
                                                    | "client"
                                                    | "logo"
                                                    | "paymentType"
                                                > = {
                                                    addPayWall: false,
                                                    name: "",
                                                    price: "",
                                                    client: "",
                                                    paymentType: "one_time",
                                                    logo: "",
                                                };
                                                let signupState: Pick<
                                                    State,
                                                    | "selfSignupInfo"
                                                    | "selfSignupLinkId"
                                                > = {
                                                    selfSignupInfo: null,
                                                    selfSignupLinkId: null,
                                                };
                                                this.setState({
                                                    ...signupState,
                                                    ...paywallState,
                                                    selectedDataSets: [],
                                                    selectedDataSet: null,
                                                });
                                                let dataSets = await getTemplateDataSetsApi(
                                                    template.id
                                                );
                                                if (
                                                    template.tab_id ===
                                                    SpecialTabId.Kit
                                                ) {
                                                    try {
                                                        paywallInfo = await getModulePaywallApi(
                                                            template.id
                                                        );
                                                    } catch (error) {
                                                        console.log(
                                                            String(error)
                                                        );
                                                    }
                                                }
                                                try {
                                                    let data = await getSelfSignupLinkToModuleApi(
                                                        template.id
                                                    );
                                                    signupState = {
                                                        selfSignupLinkId:
                                                            data.self_signup_link_id,
                                                        selfSignupInfo:
                                                            data.self_signup_link_id !=
                                                            null
                                                                ? {
                                                                      logo:
                                                                          data.logo ??
                                                                          null,
                                                                      options:
                                                                          data.options !=
                                                                          null
                                                                              ? JSON.parse(
                                                                                    data?.options
                                                                                )
                                                                              : null,
                                                                  }
                                                                : null,
                                                    };
                                                } catch (error) {
                                                    console.log(String(error));
                                                }

                                                let transformedDataSets: DataSet[] = dataSets.map(
                                                    (dataSet) => ({
                                                        label:
                                                            dataSet.data_table_name,
                                                        value:
                                                            dataSet.data_table_idx,
                                                        accessType: dataSet.allow_uploading
                                                            ? AccessType.CanSubmit
                                                            : dataSet.copy_on_load
                                                            ? AccessType.CreateLocalCopy
                                                            : AccessType.CanRead,
                                                    })
                                                );
                                                // Fetch data sets shared in the presentation
                                                let dataSetsSharedInModule: DataSet[] = [];
                                                try {
                                                    dataSetsSharedInModule = await this.getDataSetsSharedInModule();
                                                } catch (error) {
                                                    console.log(String(error));
                                                }
                                                // Merge them with the data sets shared in the template
                                                let dataSetIds = new Set<
                                                    string | number
                                                >(
                                                    transformedDataSets.map(
                                                        (dataSet) =>
                                                            dataSet.value
                                                    )
                                                );
                                                for (let dataSet of dataSetsSharedInModule) {
                                                    if (
                                                        !dataSetIds.has(
                                                            dataSet.value
                                                        )
                                                    ) {
                                                        transformedDataSets.push(
                                                            {
                                                                ...dataSet,
                                                                accessType:
                                                                    AccessType.CreateLocalCopy,
                                                            }
                                                        );
                                                    }
                                                }

                                                this.setState((state) => {
                                                    if (
                                                        template.id ===
                                                        state.selectedTemplate
                                                            ?.id
                                                    ) {
                                                        if (
                                                            paywallInfo != null
                                                        ) {
                                                            paywallState = {
                                                                addPayWall: true,
                                                                price: paywallInfo.price.toString(),
                                                                name:
                                                                    paywallInfo.title,
                                                                client:
                                                                    paywallInfo.client,
                                                                logo:
                                                                    paywallInfo.logo ??
                                                                    "",
                                                                paymentType:
                                                                    paywallInfo.payment_type,
                                                            };
                                                        }
                                                        return {
                                                            selectedDataSets: transformedDataSets,
                                                            ...paywallState,
                                                            ...signupState,
                                                        };
                                                    }
                                                    return null;
                                                });
                                            } catch (error) {
                                                console.log(String(error));
                                            }
                                        }
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                    menuPortalTarget={document.body}
                                />
                            </div>
                        )}
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 21,
                                marginTop: 9,
                                alignItems: "center",
                                display:
                                    "ManageCategories" in
                                    globalContext.permissions
                                        ? "flex"
                                        : "none",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginRight: 5,
                                }}
                                className="unselectable"
                            >
                                Assign category
                            </span>
                            <Select
                                isDisabled={!this.state.intoCreateNew}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                isClearable
                                placeholder=""
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginTop: 5,
                                        marginLeft: 23,
                                        height: "38px",
                                        width: "16em",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={this.state.categories}
                                value={
                                    this.state.categories.find(
                                        (category) =>
                                            category.value ===
                                            this.state.categoryId
                                    ) ?? null
                                }
                                onChange={async (option) => {
                                    this.setState({
                                        categoryId:
                                            (option as NumericOption | null)
                                                ?.value ?? undefined,
                                    });
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                                menuPortalTarget={document.body}
                            />
                        </div>

                        <div
                            className="my-row"
                            style={{
                                marginLeft: 23,
                                marginTop: 10,
                                alignItems: "center",
                                display:
                                    this.state.intoCreateNew ||
                                    this.state.intoTutorials ||
                                    this.state.categoryId != null
                                        ? "flex"
                                        : "none",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 25,
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    color: mainStyle.getPropertyValue(
                                        "--secondary-text-color"
                                    ),
                                }}
                                className="unselectable"
                            >
                                Accessible to everyone
                            </span>
                        </div>
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 35,
                                marginTop: 10,
                                alignItems: "left",
                                fontSize: "12px",
                                display:
                                    this.state.intoCreateNew ||
                                    this.state.intoTutorials ||
                                    this.state.categoryId != null
                                        ? "none"
                                        : "flex",
                            }}
                        >
                            <UserGroupsSelector
                                containerStyle={{
                                    marginTop: 1,
                                    marginBottom: 2,
                                    marginLeft: 20,
                                }}
                                selectedUserGroups={
                                    this.state.selectedUserGroups
                                }
                                onChange={(selectedUserGroups) => {
                                    this.setState({
                                        selectedUserGroups: selectedUserGroups,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className="flex-simple-column"
                        style={{
                            marginTop: 20,
                            width: "calc(50% - 26px)",
                        }}
                    >
                        <span
                            style={{
                                marginTop: 0,
                                marginLeft: 53,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Data Set Access Settings
                        </span>
                        <DataSetsSelector
                            createLocalCopyShown={true}
                            rowDirection={true}
                            menuPortalEnabled={true}
                            className="unselectable"
                            style={{
                                marginLeft: 53,
                                fontSize: "10px",
                            }}
                            onSelectedDataSetsChanged={(
                                selectedDataSets: DataSet[]
                            ) => {
                                this.setState({
                                    selectedDataSets: selectedDataSets,
                                });
                            }}
                            onSelectedDataSetChanged={(
                                selectedDataSet: DataSet | null
                            ) => {
                                this.setState({
                                    selectedDataSet: selectedDataSet,
                                });
                            }}
                            selectedDataSets={this.state.selectedDataSets}
                            selectedDataSet={this.state.selectedDataSet}
                        />
                    </div>
                </div>
                {this.state.publishAsKit && (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                        }}
                    >
                        <div
                            className="flex-simple-column"
                            style={{
                                marginTop: 20,
                                width: "calc(50% - 26px)",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                Paywall Settings
                            </span>
                            <div
                                className="my-row"
                                style={{
                                    marginLeft: 21,
                                    marginTop: 9,
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        marginLeft: 23,
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontFamily: "Roboto",
                                        marginRight: 5,
                                        verticalAlign: "middle",
                                    }}
                                    className="unselectable"
                                >
                                    Add Paywall
                                </span>
                                <Switch
                                    onChange={(checked) => {
                                        this.setState({ addPayWall: checked });
                                    }}
                                    checked={this.state.addPayWall}
                                    width={40}
                                    height={20}
                                    offColor="#CCC"
                                    onColor="#CCC"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                            <div
                                className="my-row"
                                style={{
                                    marginLeft: 21,
                                    marginTop: 15,
                                    alignItems: "center",
                                    display: this.state.addPayWall
                                        ? "flex"
                                        : "none",
                                }}
                            >
                                <ProductParametersInput
                                    client={this.state.client}
                                    name={this.state.name}
                                    paymentType={this.state.paymentType}
                                    price={this.state.price}
                                    logo={this.state.logo}
                                    onChange={(changes) => {
                                        this.setState(
                                            changes as Pick<State, keyof State>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div
                    className="my-row"
                    style={{
                        marginBottom: "24px",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    {this.state.uploadStatus === UploadStatus.Started && (
                        <Alert
                            text={"Creating..."}
                            className="alert alert-warning alert-dismissible"
                            onClosed={() => {
                                this.setState({
                                    uploadStatus: UploadStatus.NotStarted,
                                    message: "",
                                });
                            }}
                            style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                marginTop: "10px",
                                marginBottom: "10px",
                                minHeight: "25px",
                            }}
                        />
                    )}
                    <Button
                        type="button"
                        className="btn btn-sm my-outline"
                        style={{
                            color: "#3b82c9",
                            marginLeft: "27px",
                            marginRight: "10px",
                            backgroundColor: "transparent",
                            border: "none",
                            width: "70px",
                        }}
                        onClick={() => {
                            this.props.onCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        disabled={
                            !this.state.moduleTitle ||
                            this.state.uploadStatus === UploadStatus.Started
                        }
                        style={{
                            width: "112px",
                            background:
                                "linear-gradient(94.78deg, rgb(26, 96, 179) 0%, rgb(19, 82, 155) 100%)",
                        }}
                        className="btn btn-sm btn-primary my-primary"
                        onClick={async () => {
                            this.setState({
                                uploadStatus: UploadStatus.Started,
                            });
                            try {
                                let imageInfo: {
                                    image: Blob | null;
                                    thumbnail?: string;
                                } = {
                                    image: null,
                                    thumbnail: undefined,
                                };
                                let briefModuleRequest: BriefModuleRequest = {
                                    title: this.state.moduleTitle,
                                    description: this.state.moduleDescription,
                                    user_groups: this.state.selectedUserGroups,
                                    thumbnail:
                                        this.state.moduleThumbnail ||
                                        imageInfo.thumbnail,
                                    editable: true,
                                    republish: true,
                                    keywords: this.state.moduleKeywords,
                                    category_id: this.state.categoryId,
                                };
                                let dataSets = this.state.selectedDataSets.map(
                                    (dataSet) => ({
                                        data_table_idx: dataSet.value,
                                        allow_uploading:
                                            dataSet.accessType ===
                                            AccessType.CanSubmit,
                                        copy_on_load:
                                            dataSet.accessType ===
                                            AccessType.CreateLocalCopy,
                                    })
                                );
                                if (this.state.selectedDataSet != null) {
                                    dataSets.push({
                                        data_table_idx: this.state
                                            .selectedDataSet.value,
                                        allow_uploading:
                                            this.state.selectedDataSet
                                                .accessType ===
                                            AccessType.CanSubmit,
                                        copy_on_load:
                                            this.state.selectedDataSet
                                                .accessType ===
                                            AccessType.CreateLocalCopy,
                                    });
                                }
                                if (
                                    this.state.publishAsKit &&
                                    this.state.addPayWall
                                ) {
                                    briefModuleRequest.paywall = {
                                        title: this.state.name,
                                        client: this.state.client,
                                        description: "",
                                        payment_type: this.state.paymentType,
                                        price: Number(this.state.price),
                                        logo: this.state.logo,
                                    };
                                }
                                await addModuleApi(
                                    briefModuleRequest,
                                    this.props.moduleId,
                                    this.state.image,
                                    dataSets,
                                    this.state.publishAsKit
                                        ? SpecialTabId.Kit
                                        : this.state.intoTutorials &&
                                          this.state.intoTutorialsInitialList
                                        ? SpecialTabId.TutorialsInitialList
                                        : this.state.intoTutorials
                                        ? SpecialTabId.Tutorials
                                        : this.state.intoCreateNew
                                        ? SpecialTabId.CreateNew
                                        : undefined,
                                    this.state.selectedTemplate?.id
                                );
                                this.setState(
                                    {
                                        uploadStatus: UploadStatus.Success,
                                        message:
                                            "Template is created successfully",
                                    },
                                    () => {
                                        this.getTemplatesForModule();
                                    }
                                );
                            } catch (exception) {
                                //    unmakeTemporary();
                                console.log(exception);
                                this.setState({
                                    uploadStatus: UploadStatus.Error,
                                    message: String(exception),
                                });
                            }
                        }}
                    >
                        {this.state.selectedTemplate ? "Edit" : "Create"}
                    </Button>
                </div>
                {this.state.showSignupPopupInfo && this.state.selectedTemplate && (
                    <LinkCustomizePopup
                        selfSignupInfo={this.state.selfSignupInfo}
                        onApply={(selfSignupInfo: SelfSignupInfo) => {
                            addSelfSignupLinkToModuleApi(
                                this.state.selectedTemplate!.id,
                                JSON.stringify(selfSignupInfo.options),
                                selfSignupInfo.logo
                            )
                                .then((selfSignupLinkId) => {
                                    this.setState({
                                        selfSignupInfo: selfSignupInfo,
                                        selfSignupLinkId: selfSignupLinkId,
                                        uploadStatus: UploadStatus.Success,
                                        message: "Options applied",

                                        showSignupPopupInfo: false,
                                    });
                                })
                                .catch((error) => {
                                    this.setState({
                                        uploadStatus: UploadStatus.Error,
                                        message: String(error),
                                    });
                                });
                        }}
                        onClose={() => {
                            this.setState({ showSignupPopupInfo: false });
                        }}
                    />
                )}
                {this.state.uploadStatus === UploadStatus.Error && (
                    <StatusPopup
                        status={PopupStatus.Error}
                        message={this.state.message}
                        onClose={() => {
                            this.setState({
                                uploadStatus: UploadStatus.NotStarted,
                                message: "",
                            });
                        }}
                    />
                )}
                {this.state.uploadStatus === UploadStatus.Success && (
                    <StatusPopup
                        status={PopupStatus.Success}
                        message={this.state.message}
                        onClose={() => {
                            this.setState({
                                uploadStatus: UploadStatus.NotStarted,
                                message: "",
                            });
                        }}
                    />
                )}
            </div>
        );
    }

    render() {
        return (
            <GlobalContext.ObserverConsumer>
                {this.buildInnerView}
            </GlobalContext.ObserverConsumer>
        );
    }
}

export default ModuleCreator;
