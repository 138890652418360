import React, { Component } from "react";
import { Button } from "react-bootstrap";
import OutsideAlerter from "common/OutsideAlerter";
import Alert from "common/Alert";
import { Api } from "./Api";
import Variables from "common/Variables";

const UpdateStatus = Object.freeze({
	success: 1,
	loading: 2,
	error: 3,
	notUploaded: 4,
});

class AddVariablePopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columnName: undefined,
			errorMessage: "",
			status: UpdateStatus.notUploaded,
		};
		this.inputRef = React.createRef();
	}

	buildAlert() {
		let alert = <div style={{ height: 72 }} />;
		if (this.state.status === UpdateStatus.success) {
			alert = (
				<Alert
					text="Successully updated."
					className="alert alert-success alert-dismissible"
					onClosed={() =>
						this.setState({
							status: UpdateStatus.notUploaded,
						})
					}
				/>
			);
		} else if (this.state.status === UpdateStatus.error) {
			alert = (
				<Alert
					text={"Update error: ".concat(this.state.errorMessage)}
					className="alert alert-danger alert-dismissible"
					onClosed={() =>
						this.setState({
							status: UpdateStatus.notUploaded,
						})
					}
				/>
			);
		} else if (this.state.status === UpdateStatus.loading) {
			alert = (
				<Alert
					text={"Adding new variable"}
					className="alert alert-warning alert-dismissible"
					onClosed={() =>
						this.setState({
							status: UpdateStatus.notUploaded,
						})
					}
				/>
			);
		}
		return alert;
	}

	componentDidMount() {
		if (this.inputRef.current) this.inputRef.current.focus();
	}
	componentWillUnmount() {}

	buildInnerView() {
		return (
			<div
				className="center-container"
				style={{
					overflow: "visible",
					height: "100%",
					width: "100%",
				}}
				onKeyDown={(evt) => {
					evt.stopPropagation();
				}}
			>
				<div
					className="flex-simple-column"
					style={{
						overflow: "visible",
						marginTop: 20,
						height: "100%",
						width: "90%",
					}}
				>
					<input
						ref={this.inputRef}
						type="text"
						className="like-tab"
						placeholder="Enter variable name"
						style={{
							width: "100%",
							alignSelf: "center",
						}}
						value={this.state.columnName}
						onChange={(evt) => {
							let columnName = evt.target.value;
							this.setState({ columnName: columnName });
						}}
					/>
					<div
						className="my-row"
						style={{
							marginTop: 20,
							marginBottom: 20,
							alignSelf: "flex-end",
							alignItems: "center",
						}}
					>
						<Button
							type="button"
							disabled={!this.state.columnName}
							className="btn btn-sm btn-primary my-primary"
							style={{
								marginLeft: 10,
								width: "112px",
							}}
							onClick={() => {
								this.setState({
									status: UpdateStatus.loading,
								});
								Api.addNewVariable(
									this.props.config,
									this.state.columnName,
									this.props.clusterNames
								)
									.then(() => {
										this.setState({
											status: UpdateStatus.success,
										});
										Variables(
											this.props.config.dataScope.value
										).update();
									})
									.catch((error) => {
										this.setState({
											status: UpdateStatus.error,
											errorMessage: error,
										});
									});
							}}
						>
							SAVE
						</Button>
						<Button
							type="button"
							className="btn btn-sm btn-primary my-primary"
							style={{
								marginLeft: 10,
								width: "112px",
							}}
							onClick={() => {
								this.props.onClose();
							}}
						>
							CLOSE
						</Button>
					</div>
					<div style={{ marginTop: "10px" }}>{this.buildAlert()}</div>
				</div>
			</div>
		);
	}

	render() {
		let height = 150;
		let width = 450;
		return (
			<OutsideAlerter
				onReject={() => {
					this.props.onClose();
				}}
			>
				<div
					className="dashboard-rect"
					style={{
						overflow: "visible",
						position: "absolute",
						border: "1px solid black",
						top: (window.innerHeight - height) / 2,
						alignItems: "center",
						cursor: "pointer",
						left: (window.innerWidth - width) / 2,
						minHeight: height,
						width: width,
						zIndex: "100000000",
					}}
				>
					{this.buildInnerView()}
				</div>
			</OutsideAlerter>
		);
	}
}
export default AddVariablePopup;