import modules from "../data_exploration_page/modules/module_list.json";
import Finding, { MapFinding, NetworkFinding } from "common/Finding";
import { DashboardVersion } from "common/Canvas";

class DashboardUpdater {
    apis: { [key: string]: any } = {};
    apisV2: { [key: string]: any } = {};
    constructor() {
        modules.forEach((module) => {
            module.submodules.forEach((submodule: any) => {
                try {
                    let api = require(`../data_exploration_page/modules/${submodule.dir}/Api`);
                    this.apis[submodule.name] = api.Api;
                } catch (e) {}
                try {
                    let apiV2 = require(`../data_exploration_page/modules/${submodule.dir}/ApiV2`);
                    this.apisV2[submodule.name] = apiV2.Api;
                } catch (e) {}
            });
        });
    }
    public getApiV2(journeyName: string) {
        return this.apisV2[journeyName];
    }

    public async updateDashboardFinding(
        finding: MapFinding | NetworkFinding | undefined | null,
        version: DashboardVersion | undefined,
        moduleId?: number | string | null,
        manual: boolean = false
    ) {
        const newAdditionalFindings: any = await this.updateDashboardAdditionalFindings(
            finding?.additionalMapsFindings ?? [],
            version,
            moduleId,
            manual
        );

        if (finding?.config && finding?.content) {
            newAdditionalFindings.map(async (newFinding: any, idx: number) => {
                finding.additionalMapsFindings![idx] = await newFinding;
            });
        }

        if (finding == null) return finding;
        if (finding.config == null) return finding;
        if (finding.config.cacheData && !manual) return finding;
        if (version === DashboardVersion.Second) {
            if (finding.config.dataScope == null) return finding;
            let api = this.apisV2[finding.config.journeyName];
            // We have to create a shallow copy of finding here to fix the
            // issue that causes graphs without connected variables to load
            // indefinitely:
            // https://eisengardai.atlassian.net/browse/EIS-630?focusedCommentId=13879
            let newFinding = await api.getData({ ...finding }, {}, moduleId);
            return newFinding;
        }
        let api = this.apis[finding.config.journeyName];
        let newFinding = await api.getData(finding.config, finding, moduleId);
        return newFinding;
    }

    public async updateDashboardAdditionalFindings(
        findings: Array<Finding | undefined | null>,
        version: DashboardVersion | undefined,
        moduleId?: number | string | null,
        manual: boolean = false
    ) {
        return findings.map(async (finding) => {
            if (finding == null) return finding;
            if (finding.config == null) return finding;
            if (finding.config.cacheData && !manual) return finding;
            if (version === DashboardVersion.Second) {
                if (finding.config.dataScope == null) return finding;
                let api = this.apisV2[finding.config.journeyName];
                let newFinding = await api.getData(finding, {}, moduleId);
                return newFinding;
            }
        });
    }
}

export default new DashboardUpdater();
