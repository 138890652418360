import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ManageTableOperation } from "common/ManageTableOperation";
import Tables, { TableOption } from "common/Tables";
import axios from "common/ServerConnection";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import { GroupExtendedPermissionWithUserInfo } from "common/GroupPermissions";
import { getDataTablePermissionsApi } from "common/UserGroupsApi";
import { ReactComponent as MagnifierIcon } from "icons/magnifier.svg";
import styles from "./ManageSection.module.css";
import { stringSessionId } from "common/SessionId";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
// import MessagePopup from "common/MessagePopup";
import MessagePopup from "../MessagePopup";
import PermissionControlView from "common/PermissionControlView";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { Permission } from "common/Permissions";

interface Props {
    newDataset: number | string;
    setOperationInProgress: (state: boolean) => void;
}

function ManageSection(props: Props) {
    // const history = useHistory()
    const [datasetUsedIn, setdatasetUsedIn] = useState<Array<{
        id: number;
        title: string;
        image: string | undefined;
    }> | null>(null);
    const [previewHeader, setpreviewHeader] = useState<
        | {
              id: number;
              name: string;
              type: string;
          }[]
        | null
    >(null);
    const [previewBody, setpreviewBody] = useState<Array<
        Array<string | number | null>
    > | null>(null);
    let [searchText, setSearchText] = React.useState<string>("");
    let [expandedDataSet, setExpandedDataset] = React.useState<
        string | number | null | boolean
    >(null);
    let [showAggregateTableInfo, setshowAggregateTableInfo] = React.useState<
        string | number | null | boolean
    >(null);
    let [
        dataSetDelete,
        setDataSetDelete,
    ] = React.useState<DataScopeOption | null>(null);
    let [
        dataSetDeleteForce,
        setDataSetDeleteForce,
    ] = React.useState<DataScopeOption | null>(null);
    let [UsedIn, setUsedIn] = useState<DataScopeOption | null>(null);
    let [permissions, setPermissions] = React.useState<
        GroupExtendedPermissionWithUserInfo[]
    >([]);
    let [
        dataSetEditPermissions,
        setDataSetEditPermissions,
    ] = React.useState<DataScopeOption | null>(null);
    let [dataSetTableDelete, setDataSetTableDelete] = React.useState<{
        dataScope: DataScopeOption;
        table: TableOption;
    } | null>(null);
    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    const [sortkey, setSortkey] = useState<SortKeys>("uploaded");
    const [sortorder, setSortorder] = useState<SortOrder>("desc");
    const [doneButton, setDoneButton] = useState("0");
    let [highlightnewData, sethighlightnewData] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => {
            sethighlightnewData(false);
        }, 10000);
    }, []);

    useEffect(() => {
        props.setOperationInProgress(searchText ? true : false);
    }, [searchText]);

    type Data = typeof DataScopes.dataScopesOptions;
    type SortKeys = keyof DataScopeOption | null;
    type SortOrder = "ascn" | "desc";

    const makeDefault = () => {
        setDataSetDelete(null);
        setDataSetDeleteForce(null);
        setUsedIn(null);
        setDataSetTableDelete(null);
        setDataSetEditPermissions(null);
        setStatusPopup(null);
        setPermissions([]);
    };

    const executeOperation = (
        dataScopeOption: DataScopeOption,
        tableOption: TableOption | undefined,
        operationOption: ManageTableOperation
    ) => {
        let requestUrl: string = "";
        let requestArgs: any = {};
        let onSuccess: (() => void) | undefined = undefined;
        switch (operationOption) {
            case ManageTableOperation.RemoveTable:
                requestUrl = "/api/e/delete_table";
                requestArgs = {
                    data_table_idx: dataScopeOption.value,
                    table: tableOption!.value,
                    condition_id: tableOption!.condition_id,
                    optimized: tableOption!.optimized,
                    update_id: stringSessionId(),
                };
                onSuccess = () => {
                    Tables(dataScopeOption.value).update();
                };
                break;
            case ManageTableOperation.RemoveDataSet:
            case ManageTableOperation.RemoveDataSetForce:
                requestUrl = "/api/move_to_trash_data_table";
                requestArgs = {
                    data_table_name: dataScopeOption.label,
                    data_table_idx: dataScopeOption.value.toString(),
                    force:
                        operationOption ===
                        ManageTableOperation.RemoveDataSetForce,
                    update_id: stringSessionId(),
                };

                onSuccess = async () => {
                    await DataScopes.getTrashData();
                    await DataScopes.update();
                };
                break;

            case ManageTableOperation.EditAccessControl:
                requestUrl = "/api/set_data_table_permissions";
                requestArgs = {
                    data_table_idx: dataScopeOption.value,
                    update_id: stringSessionId(),
                    permissions: permissions
                        .filter(
                            (permission) => permission.permission_type !== -1
                        )
                        .map((permission) => ({
                            group_id: permission.group_id,
                            permission_type: permission.permission_type,
                        })),
                };
                break;
        }
        setStatusPopup({
            status: PopupStatus.Success,
            message: "Applying changes...",
        });

        axios
            .post<{
                success: boolean;
                error_msg: string;
                data_set_in_use?: boolean;
            }>(requestUrl, requestArgs)
            .then((response) => {
                if (response.data.success) {
                    makeDefault();
                    setStatusPopup({
                        status: PopupStatus.Success,
                        message: "Changes applied",
                    });

                    onSuccess?.();
                } else {
                    if (response.data.data_set_in_use) {
                        setDataSetDelete(null);
                        setDataSetDeleteForce(dataScopeOption);
                    } else {
                        setStatusPopup({
                            status: PopupStatus.Error,
                            message: response.data.error_msg,
                        });
                    }
                }
            })
            .catch((error) => {
                setStatusPopup({
                    status: PopupStatus.Error,
                    message: String(error),
                });
            });
    };

    async function Dataset_Preview(id: number | string) {
        await DataScopes.DatasetPreview(id);
        DataScopes.preview?.headers &&
            setpreviewHeader(DataScopes.previewState.headers);
        DataScopes.preview?.body &&
            setpreviewBody(DataScopes.previewState.body);
    }

    useEffect(() => {
        DataScopes.update();
        return () => {
            DataScopes.previewState = { headers: null, body: null };
        };
    }, []);

    function DatasetUsedIn(id: number | string) {
        if (id != null) {
            axios
                .post(`/api/get_dataset_linked_modules`, { data_table_idx: id })
                .then((response: any) => {
                    setdatasetUsedIn(response.data.modules);
                });
        } else {
            setdatasetUsedIn(null);
        }
    }

    const headers: { key: SortKeys; label: string }[] = [
        { key: "label", label: "Name" },
        { key: "owner", label: "Owner" },
        { key: "lastupdated", label: "Last Updated" },
        { key: "uploaded", label: "Uploaded" },
    ];

    var selectedDataSets = DataScopes.dataScopesOptions
        .filter((option) => option.permissionType === Permission.ReadWrite)
        .filter((option) =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
        );

    function sortData({
        tableData,
        sortkey,
        reverse,
    }: {
        tableData: Data;
        sortkey: SortKeys;
        reverse: boolean;
    }) {
        if (!sortkey) {
            return tableData;
        }

        const sortedData = selectedDataSets.sort((a, b) => {
            if (a[sortkey] && b[sortkey]) {
                return (a[sortkey] || "").toString().toLowerCase() >
                    (b[sortkey] || "").toString().toLowerCase()
                    ? 1
                    : -1;
            } else if ((a[sortkey] ?? "") || (b[sortkey] ?? "")) {
                return (a[sortkey] || "") > (b[sortkey] || "") ? 1 : -1;
            } else {
                return 1;
            }
        });

        if (reverse) {
            return sortedData.reverse();
        } else return sortedData;
    }
    const sortedData = useCallback(
        () =>
            sortData({
                tableData: selectedDataSets,
                sortkey: sortkey,
                reverse: sortorder === "desc" ? true : false,
            }),
        [selectedDataSets, sortkey, sortorder]
    );

    function changeSort(key: SortKeys) {
        setSortorder(sortorder === "ascn" ? "desc" : "ascn");
        setSortkey(key);
    }

    return (
        <div className={"mainContainer"} style={{ padding: 0 }}>
            <div className={styles.toolbarContainer}>
                <div style={{ flexGrow: 1 }}></div>
                <div className={styles.searchContainer}>
                    <input
                        list="datasets"
                        placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(evt) => setSearchText(evt.target.value)}
                        className={styles.searchInput}
                    />
                    <MagnifierIcon stroke="red" />
                </div>
            </div>
            <div className={styles.customTable} style={{ minHeight: "70vh" }}>
                <table className={styles.grid}>
                    <thead>
                        <tr
                            className={styles.gridRow}
                            style={{ backgroundColor: "#f7f7f7" }}
                        >
                            {headers.map((header) => {
                                return (
                                    <td
                                        style={{
                                            width:
                                                header.key === "label"
                                                    ? "30%"
                                                    : "20%",
                                        }}
                                        className={styles.gridTitle}
                                        key={header.key}
                                    >
                                        {header.key === "label" && (
                                            <div style={{ width: 22 }} />
                                        )}
                                        {header.label}
                                        <button
                                            className={`${
                                                sortkey === header.key &&
                                                sortorder === "desc"
                                                    ? styles.sortButton
                                                    : `${styles.sortButton} ${styles.sortReverse}`
                                            }`}
                                            onClick={() =>
                                                changeSort(header.key)
                                            }
                                        >
                                            <ChevronIcon stroke="#555" />
                                        </button>
                                    </td>
                                );
                            })}
                            <td
                                style={{ width: "10%" }}
                                className={styles.gridTitle}
                            >
                                Action
                            </td>
                        </tr>
                    </thead>
                    {sortedData().length > 0 ? (
                        sortedData().map((option, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <tbody>
                                        <tr
                                            className={
                                                props.newDataset ===
                                                    option.value &&
                                                highlightnewData
                                                    ? styles.highlightmain
                                                    : styles.gridRow
                                            }
                                            style={{
                                                borderBottom:
                                                    "1px solid #E8E8E8",
                                            }}
                                        >
                                            <td
                                                style={{ width: "30%" }}
                                                className={styles.gridItem}
                                            >
                                                <div
                                                    style={{
                                                        width: 22,
                                                        height: 22,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                    onClick={(evt) => {
                                                        evt.stopPropagation();
                                                        if (
                                                            option.value ===
                                                            showAggregateTableInfo
                                                        ) {
                                                            setshowAggregateTableInfo(
                                                                null
                                                            );
                                                            setExpandedDataset(
                                                                null
                                                            );
                                                        } else
                                                            setshowAggregateTableInfo(
                                                                option.value
                                                            );
                                                    }}
                                                >
                                                    {Tables(option.value)
                                                        .aggregateTableOptions
                                                        .length > 0 ? (
                                                        option.value ===
                                                        showAggregateTableInfo ? (
                                                            <ChevronIcon stroke="#555" />
                                                        ) : (
                                                            <ChevronIcon
                                                                transform="rotate(-90)"
                                                                stroke="#555"
                                                            />
                                                        )
                                                    ) : null}
                                                </div>
                                                <span
                                                    style={{
                                                        width: "max-content",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={(evt) => {
                                                        evt.stopPropagation();
                                                        if (
                                                            expandedDataSet ===
                                                            option.value
                                                        ) {
                                                            setExpandedDataset(
                                                                null
                                                            );
                                                        } else if (
                                                            option.value != null
                                                        ) {
                                                            setExpandedDataset(
                                                                option.value
                                                            );
                                                            Dataset_Preview(
                                                                option.value
                                                            );
                                                        } else {
                                                            setExpandedDataset(
                                                                null
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {option.label}
                                                </span>
                                            </td>
                                            <td
                                                style={{ width: "20%" }}
                                                className={styles.gridItem}
                                            >
                                                {option.owner}
                                            </td>
                                            <td
                                                style={{ width: "20%" }}
                                                className={styles.gridItem}
                                            >
                                                {option.lastupdated}
                                            </td>
                                            <td
                                                style={{ width: "20%" }}
                                                className={styles.gridItem}
                                            >
                                                {option.uploaded}
                                            </td>
                                            <td
                                                style={{ width: "10%" }}
                                                className={styles.gridItem}
                                            >
                                                <div
                                                    className={styles.dropdown}
                                                >
                                                    <div
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                        className={`${styles.dropdownToggle}`}
                                                    >
                                                        <img
                                                            src="/dist/img/data-exploration/dropdown-icon.png"
                                                            alt="logo"
                                                            className={`${styles.dropdownIcon} ${styles.imgWidth}`}
                                                        />
                                                    </div>

                                                    <div
                                                        className={
                                                            styles.dropdownContent
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.dropdownItem
                                                            }
                                                            onClick={() => {
                                                                setUsedIn(
                                                                    option
                                                                );
                                                                DatasetUsedIn(
                                                                    option?.value
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src="/dist/img/data-exploration/used-in.svg"
                                                                alt=""
                                                                className={
                                                                    styles.imgWidth
                                                                }
                                                            />
                                                            Used In
                                                        </div>

                                                        <div
                                                            className={
                                                                styles.dropdownItem
                                                            }
                                                            onClick={(evt) => {
                                                                if (
                                                                    dataSetEditPermissions !=
                                                                        null &&
                                                                    dataSetEditPermissions.value ===
                                                                        option.value
                                                                ) {
                                                                    executeOperation(
                                                                        option,
                                                                        undefined,
                                                                        ManageTableOperation.EditAccessControl
                                                                    );
                                                                } else {
                                                                    setDataSetEditPermissions(
                                                                        option
                                                                    );
                                                                    getDataTablePermissionsApi(
                                                                        option.value,
                                                                        true
                                                                    )
                                                                        .then(
                                                                            (
                                                                                permissions
                                                                            ) => {
                                                                                setPermissions(
                                                                                    permissions
                                                                                );
                                                                            }
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                error
                                                                            ) => {
                                                                                console.log(
                                                                                    error
                                                                                );
                                                                            }
                                                                        );
                                                                }
                                                            }}
                                                        >
                                                            <img
                                                                src="/dist/img/data-exploration/share.svg"
                                                                alt=""
                                                                className={
                                                                    styles.imgWidth
                                                                }
                                                            />
                                                            Share
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.dropdownItem
                                                            }
                                                            onClick={(evt) => {
                                                                setDataSetDelete(
                                                                    option
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src="/dist/img/data-exploration/Trash.svg"
                                                                alt=""
                                                                className={
                                                                    styles.imgWidth
                                                                }
                                                            />
                                                            Delete
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        {option.value ===
                                            showAggregateTableInfo &&
                                            Tables(
                                                showAggregateTableInfo
                                            ).aggregateTableOptions.map(
                                                (table, index) => (
                                                    <tr
                                                        className={
                                                            styles.gridRow
                                                        }
                                                        key={index}
                                                        style={{
                                                            paddingLeft: 53,
                                                            marginTop: 17,
                                                        }}
                                                    >
                                                        <td
                                                            style={{
                                                                width: "46%",
                                                                padding: 15,
                                                                // cursor: 'pointer',
                                                            }}
                                                            className={
                                                                styles.gridItem
                                                            }
                                                            // onClick={(evt) => {
                                                            //     evt.stopPropagation();
                                                            //     if (expandedDataSet === option.value) {
                                                            //         setExpandedDataset(null);
                                                            //     }
                                                            //     else if (!isNaN(Number(option.value))) {
                                                            //         setExpandedDataset((option.value));
                                                            //         Dataset_Preview(option.value);
                                                            //     }
                                                            //     else {
                                                            //         setExpandedDataset(null);
                                                            //     }
                                                            // }}
                                                        >
                                                            {table.label}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: 15,
                                                                cursor:
                                                                    "pointer",
                                                                color:
                                                                    "var(--acceptBtn-color)",
                                                            }}
                                                            onClick={() => {
                                                                setDataSetTableDelete(
                                                                    {
                                                                        dataScope: option,
                                                                        table: table,
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            Remove Table
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        {expandedDataSet === option.value && (
                                            <tr
                                                className={`${styles.customTable} ${styles.customPreview}`}
                                            >
                                                <table
                                                    className={
                                                        styles.previewTable
                                                    }
                                                >
                                                    <thead
                                                        className={
                                                            styles.previewHeader
                                                        }
                                                    >
                                                        <tr
                                                            className={
                                                                styles.previewRow
                                                            }
                                                        >
                                                            {previewHeader?.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <td
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={
                                                                            styles.previewHeaderColumn
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </td>
                                                                )
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody
                                                        className={
                                                            styles.previewBody
                                                        }
                                                    >
                                                        {previewBody?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`${styles.previewRow}`}
                                                                    >
                                                                        {item.map(
                                                                            (
                                                                                data,
                                                                                index
                                                                            ) => (
                                                                                <td
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className={
                                                                                        styles.previewColumn
                                                                                    }
                                                                                >
                                                                                    {" "}
                                                                                    {
                                                                                        data
                                                                                    }
                                                                                </td>
                                                                            )
                                                                        )}
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    </tbody>
                                                </table>
                                            </tr>
                                        )}
                                    </tbody>
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <div className={styles.noData}>
                            <h1 style={{ fontSize: 22 }}>
                                No data set with this name
                            </h1>
                        </div>
                    )}
                </table>
            </div>

            {dataSetEditPermissions != null && (
                <div className="sharepopup">
                    <MessagePopup
                        title={<p>{dataSetEditPermissions.label}</p>}
                        acceptButtonTitle=""
                        rejectButtonTitle=""
                        rejactButton="false"
                        height="auto"
                        width="780px"
                        message={
                            <div className={styles.popup_custom}>
                                <div className={styles.popup_wrapper}>
                                    <div className={styles.popup_content}>
                                        <PermissionControlView
                                            containerStyle={{
                                                marginLeft: 30,
                                                marginBottom: 10,
                                            }}
                                            permissions={permissions}
                                            onPermissionsChange={setPermissions}
                                            doneButton={doneButton}
                                            setDoneButton={setDoneButton}
                                            rejectPopup={makeDefault}
                                        />
                                    </div>
                                    {doneButton === "0" && (
                                        <button
                                            type="button"
                                            className="acceptBtn"
                                            style={{
                                                padding: "8px 25px",
                                                marginLeft: "auto",
                                                marginRight: "0",
                                                marginTop: "15px",
                                                display: "table",
                                            }}
                                            onClick={(evt) => {
                                                if (
                                                    dataSetEditPermissions !=
                                                    null
                                                ) {
                                                    executeOperation(
                                                        dataSetEditPermissions,
                                                        undefined,
                                                        ManageTableOperation.EditAccessControl
                                                    );
                                                } else {
                                                    setDataSetEditPermissions(
                                                        dataSetEditPermissions
                                                    );
                                                }
                                            }}
                                        >
                                            Done
                                        </button>
                                    )}
                                </div>
                            </div>
                        }
                        // onAccept={((evt) => {
                        //     console.log("first")
                        // })}

                        onReject={() => {
                            setDoneButton("0");
                            makeDefault();
                        }}
                    />
                </div>
            )}

            {dataSetDelete != null && (
                <MessagePopup
                    title={
                        <p
                            style={{
                                width: "100%",
                                marginBottom: "20px",
                                textAlign: "center",
                            }}
                        >
                            Move to Trash
                        </p>
                    }
                    danger={true}
                    acceptButtonTitle="Move to Trash"
                    message={`Do you want to remove "${dataSetDelete.label}" ?`}
                    onAccept={() => {
                        executeOperation(
                            dataSetDelete!,
                            undefined,
                            ManageTableOperation.RemoveDataSet
                        );
                    }}
                    onReject={() => {
                        makeDefault();
                    }}
                />
            )}

            {dataSetDeleteForce != null && (
                <MessagePopup
                    title={
                        <p
                            style={{
                                width: "100%",
                                marginBottom: "20px",
                                textAlign: "center",
                            }}
                        >
                            Are you sure?
                        </p>
                    }
                    danger={true}
                    acceptButtonTitle="Move to Trash"
                    message={`The data set "${dataSetDeleteForce.label}" is currenly linked to one or more presentations. Are you sure you want to delete it?`}
                    onAccept={() => {
                        executeOperation(
                            dataSetDeleteForce!,
                            undefined,
                            ManageTableOperation.RemoveDataSetForce
                        );
                    }}
                    onReject={() => {
                        makeDefault();
                    }}
                />
            )}

            {dataSetTableDelete != null && (
                <MessagePopup
                    title="Remove table"
                    danger={true}
                    message={`Do you want to remove ${dataSetTableDelete.table.label}?`}
                    onAccept={() => {
                        executeOperation(
                            dataSetTableDelete!.dataScope,
                            dataSetTableDelete!.table,
                            ManageTableOperation.RemoveTable
                        );
                    }}
                    onReject={() => {
                        makeDefault();
                    }}
                />
            )}
            {UsedIn != null && (
                <MessagePopup
                    title={
                        <p>
                            {UsedIn.label}
                            <span> - Used In</span>
                        </p>
                    }
                    rejactButton="false"
                    height="auto"
                    width="900px"
                    message={
                        <div className={styles.popupCustom}>
                            <div className={styles.popupWrapper}>
                                <div className={styles.popupContent}>
                                    <div className={styles.boxRow}>
                                        {datasetUsedIn &&
                                        datasetUsedIn?.length > 0 ? (
                                            datasetUsedIn?.map((item) => (
                                                <div
                                                    key={item.title}
                                                    className={
                                                        styles.customColumn
                                                    }
                                                >
                                                    <figure>
                                                        <img
                                                            src={
                                                                "/dist/img/data-exploration/screenshot.png"
                                                            }
                                                            alt=""
                                                            className={
                                                                styles.imgWidth
                                                            }
                                                        />
                                                        <span
                                                            className={
                                                                styles.outLink
                                                            }
                                                            onClick={() =>
                                                                window.open(
                                                                    `/canvas.html?current_module_id=${item.id}`,
                                                                    "_blank"
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src="/dist/img/data-exploration/export-icon.png"
                                                                alt=""
                                                                className={
                                                                    styles.imgWidth
                                                                }
                                                            />
                                                        </span>
                                                    </figure>
                                                    <div
                                                        className={
                                                            styles.infoBar
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.linkIcon
                                                            }
                                                        >
                                                            <img
                                                                src="/dist/img/data-exploration/globe.png"
                                                                alt=""
                                                                className={
                                                                    styles.imgWidth
                                                                }
                                                            />
                                                        </span>
                                                        <p>{item.title}</p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <h1 className={styles.noDataFound}>
                                                No Projects Found
                                            </h1>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    onReject={() => {
                        makeDefault();
                    }}
                />
            )}
            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
        </div>
    );
}

export default observer(ManageSection);
