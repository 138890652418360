import React, { Component, CSSProperties } from "react";

interface Props {
    value: string;
    onSave: (newValue: string) => void;
    className?: string;
    autoFocus?: boolean;
    placeholder?: string;
    style?: CSSProperties;
}

class InputAutosave extends Component<Props> {
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.inputRef = React.createRef();

        this.onKeyDown = this.onKeyDown.bind(this);
        this.updateValue = this.updateValue.bind(this);
    }

    private onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            this.inputRef.current?.blur();
            e.preventDefault();
        }
        if (e.key === "Escape") {
            this.setValue(this.props.value);
            this.inputRef.current?.blur();
            e.preventDefault();
        }
        if (e.key === "Tab") {
            this.inputRef.current?.blur();
            e.preventDefault();
        }
    }

    private getValue(): string {
        return this.inputRef.current?.value ?? "";
    }

    private setValue(value: string): void {
        if (this.inputRef.current != null) {
            this.inputRef.current.value = value;
        }
    }

    private updateValue(): void {
        if (this.inputRef.current?.value !== this.props.value) {
            this.props.onSave(this.getValue());
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.value !== this.props.value) {
            this.setValue(this.props.value);
        }
    }

    public render(): JSX.Element {
        return (
            <input
                className={this.props.className}
                ref={this.inputRef}
                onBlur={this.updateValue}
                onKeyDown={this.onKeyDown}
                placeholder={this.props.placeholder}
                defaultValue={this.props.value}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                autoFocus={this.props.autoFocus}
                style={this.props.style}
            />
        );
    }
}

export default InputAutosave;
