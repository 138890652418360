import React, { Component } from "react";

import Select from "react-select";
import Switch from "react-switch";
import customSelectStyles, {
    customSelectStylesWithoutIcons,
} from "common/SelectStyles";
import PredictiveAnalyticsType from "./PredictiveAnalyticsType";
import RunRegressionTable from "./RunRegressionTable";
import { mainStyle } from "common/MainStyle";
import { Variable } from "common/Variables";
import StringOption from "common/StringOption";
import { regressions, links, families } from "common/Regressions";

enum RegressionStatus {
    success = 1,
    loading = 2,
    error = 3,
    notStarted = 4,
}

function RegressionSelect(props: {
    isClearable: boolean;
    currentVariable: string | undefined | null;
    onChange: (option: StringOption | null | undefined) => void;
    variablesForSelection: { name: string }[];
    placeholder: string;
}) {
    let {
        isClearable,
        currentVariable,
        onChange,
        variablesForSelection,
        placeholder,
    } = props;
    return (
        <div
            style={{
                margin: 2,
                width: "80px",
            }}
        >
            <Select
                menuPortalTarget={document.body}
                menuShouldBlockScroll={true}
                placeholder={placeholder}
                isClearable={isClearable}
                backspaceRemovesValue={true}
                styles={{
                    ...customSelectStylesWithoutIcons,
                    menuPortal: (base) => ({
                        ...base,
                        zIndex: 100000000,
                    }),
                }}
                onChange={(value) => {
                    onChange(value as StringOption | null | undefined);
                }}
                value={
                    currentVariable
                        ? {
                              label: currentVariable,
                              value: currentVariable,
                          }
                        : null
                }
                options={variablesForSelection.map((dashboardVariable) => ({
                    label: dashboardVariable.name,
                    value: dashboardVariable.name,
                }))}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        text: "white",
                        primary25: "var(--selectors-background-hover-color)",
                    },
                })}
            />
        </div>
    );
}

interface Props {
    allVariables: Variable[];
    regressionVariables: Variable[];
    onIndependentVariablesChanged: (includeList: string[]) => void;
    onDependentVariableChanged: (name: string | undefined) => void;
    showDynamicColumn: boolean;
    type: PredictiveAnalyticsType;
    dependentVariable: string | undefined;
    independentVariable: string;
    latestRegressionInfo?: State[];
}

interface State {
    selectedRegression: string;
    selectedFamily: string;
    selectedLink: string;
    initialized: boolean;
    include: { [key: string]: boolean };
    interactions: { var1: string; var2: string }[];
    includeList: string[];
    log: { [key: string]: boolean };
    fixed: { [key: string]: boolean };
    dynamic: { [key: string]: boolean };
    defaultValue: { [key: string]: boolean };
    decimals: { [key: string]: number };
    includeIntercept: boolean;
    dependentVariable: string | undefined;
    dependentVariableLog: boolean;
    regressionStatus: RegressionStatus;
    regressionError: string;
    editModel: boolean;
}

class RunHoldOutRegressionComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedRegression: "ols",
            selectedFamily: "gaussian",
            selectedLink: "identity",
            initialized: false,
            include: {},
            includeList: [],
            interactions: [],
            log: {},
            fixed: {},
            dynamic: {},
            defaultValue: {},
            decimals: {},
            includeIntercept: false,
            dependentVariable: undefined,
            dependentVariableLog: false,
            regressionStatus: RegressionStatus.notStarted,
            regressionError: "",
            editModel: false,
        };

        this.handleIncludeSelect = this.handleIncludeSelect.bind(this);
        this.handleIncludeChange = this.handleIncludeChange.bind(this);
        this.handleLogChange = this.handleLogChange.bind(this);
        this.handleDynamicChange = this.handleDynamicChange.bind(this);
        this.handleDefaultValueChange =
            this.handleDefaultValueChange.bind(this);
        this.handleDecimalsChange = this.handleDecimalsChange.bind(this);
        this.setLogChange = this.setLogChange.bind(this);

        this.handleDependentVariableChange =
            this.handleDependentVariableChange.bind(this);
        this.handleLogDependentVariableChange =
            this.handleLogDependentVariableChange.bind(this);
        this.changeEditModel = this.changeEditModel.bind(this);

        this.getRegressionInfo = this.getRegressionInfo.bind(this);
        this.clearRegressionStatus = this.clearRegressionStatus.bind(this);
        this.handleIncludeInterceptChange =
            this.handleIncludeInterceptChange.bind(this);
        this.getFormula = this.getFormula.bind(this);
        this.buildEditModelComponent = this.buildEditModelComponent.bind(this);
    }

    private changeEditModel(): void {
        this.setState((state) => ({ editModel: !state.editModel }));
    }

    private getRegressionInfo() {
        // this method does NOT call setState
        let regressionDict: {
            dv: string | undefined;
            dv_log: boolean;
            iv: {
                main?: string;
                main_fixed?: boolean;
                main_log?: boolean;
                interactions?: { var1: string; var2: string }[];
                other?: string[];
                other_log?: boolean[];
                other_fixed?: boolean[];
            };
            model: {
                name: string;
                family?: string;
                link?: string;
            };
            intercept: boolean;
        } = {
            dv: this.state.dependentVariable,
            dv_log: this.state.dependentVariableLog,
            iv: {},
            model: {
                name: this.state.selectedRegression,
            },
            intercept: this.state.includeIntercept,
        };
        let dynamicDict = Object.assign({}, this.state.dynamic);

        if (this.state.selectedRegression === "glm") {
            regressionDict.model.family = this.state.selectedFamily;
            regressionDict.model.link = this.state.selectedLink;
        }

        let mainIndependentVariable = undefined;
        let otherVariables = undefined;

        if (this.state.includeList.length > 0) {
            mainIndependentVariable = this.state.includeList[0];
            otherVariables = this.state.includeList.slice(1);
        }

        if (mainIndependentVariable) {
            regressionDict.iv.main = mainIndependentVariable;
            regressionDict.iv.main_fixed =
                this.state.fixed[mainIndependentVariable] ?? false;
            regressionDict.iv.main_log =
                this.state.log[mainIndependentVariable] ?? false;
        }
        regressionDict.iv.interactions = this.state.interactions;

        if (otherVariables) {
            regressionDict.iv.other = otherVariables;
            regressionDict.iv.other_log = otherVariables.map(
                (variable) => this.state.log[variable] ?? false
            );
            regressionDict.iv.other_fixed = otherVariables.map(
                (variable) => this.state.fixed[variable] ?? false
            );
        }
        return [
            regressionDict,
            {},
            {},
            Object.assign({}, this.state),
            {},
            dynamicDict,
        ];
    }

    public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (prevState.initialized) {
            return prevState;
        }

        if (!nextProps.latestRegressionInfo) {
            let newState = Object.assign({}, prevState);
            newState.dependentVariable = nextProps.dependentVariable;
            var includeDict: { [key: string]: boolean } = {};
            var logDict: { [key: string]: boolean } = {};
            var dynamicDict: { [key: string]: boolean } = {};
            var defaultValueDict: { [key: string]: boolean } = {};
            var decimalsDict: { [key: string]: number } = {};
            var fixedDict: { [key: string]: boolean } = {};
            var includeList: string[] = [];
            nextProps.allVariables.forEach((item) => {
                includeDict[item.name] =
                    item.name === nextProps.independentVariable;
                dynamicDict[item.name] = includeDict[item.name];
                defaultValueDict[item.name] = false;
                logDict[item.name] = false;
                decimalsDict[item.name] = 1;
                fixedDict[item.name] = !["int", "float"].includes(item.type);
            });
            if (nextProps.independentVariable)
                includeList.push(nextProps.independentVariable);
            newState.include = includeDict;
            newState.includeList = includeList;
            newState.log = logDict;
            newState.dynamic = dynamicDict;
            newState.defaultValue = defaultValueDict;
            newState.decimals = decimalsDict;
            newState.fixed = fixedDict;
            newState.initialized = true;
            return newState;
        } else {
            // regressionDict,
            // expectedEffect,
            // showDict,
            // lastState
            let lastState = nextProps.latestRegressionInfo[3];
            return lastState;
        }
    }

    private clearRegressionStatus(): void {
        this.setState({
            regressionStatus: RegressionStatus.notStarted,
        });
    }

    private handleIncludeSelect(
        oldVariable: string,
        newVariable: Variable
    ): void {
        this.setState(
            (state) => {
                let includeList: string[] = Array.from(state.includeList);
                includeList[state.includeList.indexOf(oldVariable)] =
                    newVariable.name;
                return {
                    include: {
                        ...state.include,
                        [oldVariable]: false,
                        [newVariable.name]: true,
                    },
                    interactions: state.interactions.filter(
                        (interaction) =>
                            interaction.var1 !== oldVariable &&
                            interaction.var2 !== oldVariable
                    ),
                    includeList: includeList,
                    fixed: {
                        ...state.fixed,
                        [newVariable.name]: !["int", "float"].includes(
                            newVariable.type
                        ),
                    },
                };
            },
            () => {
                this.props.onIndependentVariablesChanged(
                    this.state.includeList
                );
            }
        );
    }

    private handleIncludeChange(variable: Variable): void {
        this.setState(
            (state) => {
                let include = Object.assign({}, state.include);
                let includeList = Array.from(state.includeList);
                let dynamic = state.dynamic;
                let defaultValue = state.defaultValue;
                let fixed = state.fixed;
                let interactions = state.interactions;
                include[variable.name] = !include[variable.name];
                if (include[variable.name]) {
                    includeList.push(variable.name);

                    dynamic = {
                        ...dynamic,
                        [variable.name]: true,
                    };
                    defaultValue = {
                        ...defaultValue,
                        [variable.name]: false,
                    };
                    fixed = {
                        ...fixed,
                        [variable.name]: !["int", "float"].includes(
                            variable.type
                        ),
                    };
                } else {
                    includeList.splice(
                        state.includeList.indexOf(variable.name),
                        1
                    );
                    interactions = interactions.filter(
                        (interaction) =>
                            interaction.var1 !== variable.name &&
                            interaction.var2 !== variable.name
                    );
                }
                return {
                    interactions: interactions,
                    include: include,
                    includeList: includeList,
                    fixed: fixed,
                    dynamic: dynamic,
                    defaultValue: defaultValue,
                };
            },
            () => {
                this.props.onIndependentVariablesChanged(
                    this.state.includeList
                );
            }
        );
    }

    private handleIncludeInterceptChange(): void {
        this.setState((state) => ({
            includeIntercept: !state.includeIntercept,
        }));
    }

    private handleLogChange(variable: string): void {
        this.setState((state) => ({
            log: {
                ...state.log,
                [variable]:
                    !state.log[variable] &&
                    state.include[variable] &&
                    !state.fixed[variable],
            },
        }));
    }

    private handleFixedChange(variable: Variable): void {
        this.setState((state) => {
            const newValue =
                (!state.fixed[variable.name] ||
                    !["int", "float"].includes(variable.type)) &&
                state.include[variable.name];
            return {
                fixed: {
                    ...state.fixed,
                    [variable.name]: newValue,
                },
                log: newValue
                    ? {
                          ...state.log,
                          [variable.name]: false,
                      }
                    : state.log,
            };
        });
    }

    private handleDynamicChange(variable: string): void {
        this.setState((state) => ({
            dynamic: {
                ...state.dynamic,
                [variable]: state.include[variable] && !state.dynamic[variable],
            },
        }));
    }
    private handleDefaultValueChange(variable: string): void {
        this.setState((state) => ({
            defaultValue: {
                ...state.defaultValue,
                [variable]:
                    state.include[variable] && !state.defaultValue[variable],
            },
        }));
    }
    private handleDecimalsChange(variable: string, value: number): void {
        this.setState((state) => ({
            decimals: {
                ...state.decimals,
                [variable]: value,
            },
        }));
    }
    private setLogChange(variable: string, log: boolean): void {
        this.setState((state) => ({
            log: {
                ...state.log,
                [variable]: log,
            },
        }));
    }

    private handleDependentVariableChange(variable: StringOption): void {
        this.setState(
            (state) => ({
                dependentVariable: variable.value,
                include: {
                    ...state.include,
                    [variable.value]: false,
                },
            }),
            () => {
                this.props.onDependentVariableChanged(
                    this.state.dependentVariable
                );
            }
        );
    }

    private handleLogDependentVariableChange(): void {
        this.setState((state) => ({
            dependentVariableLog: !state.dependentVariableLog,
        }));
    }

    private buildEditModelComponent(): JSX.Element {
        let index: number = -1;
        let variablesForDependentSelection: Variable[] =
            this.props.regressionVariables.filter(
                (variable) =>
                    this.state.include[variable.name] === false &&
                    variable.name !== this.state.dependentVariable
            );
        let variablesForIndependentSelection: Variable[] =
            this.props.allVariables.filter(
                (variable) =>
                    this.state.include[variable.name] === false &&
                    variable.name !== this.state.dependentVariable
            );
        let regressionsForSelection: {
            name: string;
        }[] = regressions.map((regression) => ({
            name: regression,
        }));
        let familiesForSelection: { name: string }[] = families[
            this.state.selectedRegression
        ].map((family) => ({
            name: family,
        }));
        let linksForSelection: { name: string }[] = links[
            this.state.selectedFamily
        ].map((link) => ({
            name: link,
        }));
        return (
            <div
                className="my-row"
                style={{
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginLeft: "30px",
                }}
            >
                <div className="my-row" style={{ alignItems: "center" }}>
                    <RegressionSelect
                        isClearable={false}
                        placeholder="regression"
                        variablesForSelection={regressionsForSelection}
                        onChange={(value) => {
                            if (value != null)
                                this.setState({
                                    selectedRegression: value.value,
                                });
                        }}
                        currentVariable={this.state.selectedRegression}
                    />
                    {this.state.selectedRegression === "glm" ? (
                        <>
                            <RegressionSelect
                                isClearable={false}
                                placeholder="families"
                                variablesForSelection={familiesForSelection}
                                onChange={(value) => {
                                    if (value != null) {
                                        this.setState({
                                            selectedFamily: value.value,
                                            selectedLink: links[value.value][0],
                                        });
                                    }
                                }}
                                currentVariable={this.state.selectedFamily}
                            />
                            <RegressionSelect
                                isClearable={false}
                                placeholder="links"
                                variablesForSelection={linksForSelection}
                                onChange={(value) => {
                                    if (value != null)
                                        this.setState({
                                            selectedLink: value.value,
                                        });
                                }}
                                currentVariable={this.state.selectedLink}
                            />
                        </>
                    ) : null}

                    <RegressionSelect
                        isClearable={true}
                        placeholder="ln"
                        variablesForSelection={[{ name: "ln" }]}
                        onChange={(value) => {
                            if (value) {
                                this.setState({ dependentVariableLog: true });
                            } else {
                                this.setState({ dependentVariableLog: false });
                            }
                        }}
                        currentVariable={
                            this.state.dependentVariableLog ? "ln" : null
                        }
                    />

                    <RegressionSelect
                        isClearable={false}
                        placeholder=""
                        variablesForSelection={variablesForDependentSelection}
                        onChange={(newValue) => {
                            if (newValue != null)
                                this.handleDependentVariableChange(newValue);
                        }}
                        currentVariable={this.state.dependentVariable}
                    />

                    <span
                        className="text-center"
                        style={{
                            margin: 2,
                            color: mainStyle.getPropertyValue(
                                "--exploration-primary-text-color"
                            ),
                            fontFamily: "Arial",
                            fontSize: "12px",
                        }}
                    >
                        =
                    </span>
                </div>
                <RegressionSelect
                    isClearable={true}
                    placeholder="intercept"
                    variablesForSelection={[{ name: "intercept" }]}
                    onChange={(value) => {
                        if (value) {
                            this.setState({ includeIntercept: true });
                        } else {
                            this.setState({ includeIntercept: false });
                        }
                    }}
                    currentVariable={
                        this.state.includeIntercept ? "intercept" : null
                    }
                />
                <span
                    className="text-center"
                    style={{
                        margin: 2,
                        color: mainStyle.getPropertyValue(
                            "--exploration-primary-text-color"
                        ),
                        fontFamily: "Arial",
                        fontSize: "12px",
                    }}
                >
                    +
                </span>
                {this.state.interactions.map(
                    (interaction, interactionIndex) => {
                        index += 1;
                        let excluded = new Set(
                            this.state.interactions
                                .filter(
                                    (otherInteraction) =>
                                        otherInteraction.var2 ===
                                        interaction.var1
                                )
                                .map(
                                    (otherInteraction) => otherInteraction.var1
                                )
                        );
                        excluded.add(interaction.var1);
                        return (
                            <div
                                className="my-row"
                                key={[interaction.var1, interaction.var2].join(
                                    "_"
                                )}
                                style={{ alignItems: "center" }}
                            >
                                <span
                                    className="text-center"
                                    style={{
                                        margin: 2,
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-primary-text-color"
                                        ),
                                        fontFamily: "Arial",
                                        fontSize: "12px",
                                    }}
                                >
                                    {`b${index} ${
                                        this.state.log[interaction.var1]
                                            ? "ln ".concat(interaction.var1)
                                            : interaction.var1
                                    } ${
                                        this.state.log[interaction.var2]
                                            ? "ln"
                                            : ""
                                    }`}
                                </span>
                                <RegressionSelect
                                    isClearable={false}
                                    placeholder=""
                                    variablesForSelection={this.state.includeList
                                        .filter(
                                            (variable) =>
                                                !excluded.has(variable)
                                        )
                                        .map((variable) => ({
                                            name: variable,
                                        }))}
                                    onChange={(value) => {
                                        let interactions = Array.from(
                                            this.state.interactions
                                        );
                                        interactions[interactionIndex].var2 =
                                            value!.value;
                                        this.setState({
                                            interactions: interactions,
                                        });
                                    }}
                                    currentVariable={interaction.var2}
                                />
                                <span
                                    className="text-center"
                                    style={{
                                        margin: 2,
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-primary-text-color"
                                        ),
                                        fontFamily: "Arial",
                                        fontSize: "12px",
                                    }}
                                >
                                    +
                                </span>
                            </div>
                        );
                    }
                )}

                {this.state.includeList.map((variable) => {
                    index += 1;
                    return (
                        <div
                            className="my-row"
                            key={variable}
                            style={{ alignItems: "center" }}
                        >
                            <span
                                className="text-center"
                                style={{
                                    margin: 2,
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-primary-text-color"
                                    ),
                                    fontFamily: "Arial",
                                    fontSize: "12px",
                                }}
                            >
                                {`b${index}`}
                            </span>
                            {!this.state.fixed[variable] && (
                                <RegressionSelect
                                    isClearable={true}
                                    placeholder="ln"
                                    variablesForSelection={[{ name: "ln" }]}
                                    onChange={(value) => {
                                        if (value) {
                                            this.setLogChange(variable, true);
                                        } else {
                                            this.setLogChange(variable, false);
                                        }
                                    }}
                                    currentVariable={
                                        this.state.log[variable] ? "ln" : null
                                    }
                                />
                            )}
                            <RegressionSelect
                                isClearable={true}
                                placeholder=""
                                variablesForSelection={
                                    variablesForIndependentSelection
                                }
                                onChange={(value) => {
                                    if (value) {
                                        let variableInfo: Variable | undefined =
                                            this.props.allVariables.find(
                                                (v) => v.name === value.value
                                            );
                                        if (variableInfo != null)
                                            this.handleIncludeSelect(
                                                variable,
                                                variableInfo
                                            );
                                    } else {
                                        let variableInfo: Variable | undefined =
                                            this.props.allVariables.find(
                                                (v) => v.name === variable
                                            );
                                        if (variableInfo != null)
                                            this.handleIncludeChange(
                                                variableInfo
                                            );
                                    }
                                }}
                                currentVariable={variable}
                            />

                            <span
                                className="text-center"
                                style={{
                                    margin: 2,
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-primary-text-color"
                                    ),
                                    fontFamily: "Arial",
                                    fontSize: "12px",
                                }}
                            >
                                +
                            </span>
                        </div>
                    );
                })}
                <div className="my-row" style={{ alignItems: "center" }}>
                    <span
                        className="text-center"
                        style={{
                            margin: 2,
                            opacity: 0.5,
                            color: mainStyle.getPropertyValue(
                                "--exploration-primary-text-color"
                            ),
                            fontFamily: "Arial",
                            fontSize: "12px",
                        }}
                    >
                        {`b${index + 1}`}
                    </span>

                    <RegressionSelect
                        placeholder="var"
                        isClearable={true}
                        variablesForSelection={variablesForIndependentSelection}
                        onChange={(value) => {
                            if (value) {
                                let variable: Variable | undefined =
                                    this.props.allVariables.find(
                                        (variable) =>
                                            variable.name === value.value
                                    );
                                if (variable != null)
                                    this.handleIncludeChange(variable);
                            }
                        }}
                        currentVariable={null}
                    />
                </div>
            </div>
        );
    }

    private getFormula(): string {
        if (this.state.dependentVariable == null) return "";
        let formula: string =
            (!this.state.dependentVariableLog
                ? this.state.dependentVariable
                : `ln ${this.state.dependentVariable}`) + " = ";

        let independentVariables = [];
        let index = 0;
        if (this.state.includeIntercept) {
            independentVariables.push("intercept");
        }
        this.state.interactions.forEach((interaction) => {
            independentVariables.push(
                `b${index} ${
                    this.state.log[interaction.var1]
                        ? "ln ".concat(interaction.var1)
                        : interaction.var1
                } ${
                    this.state.log[interaction.var2]
                        ? "ln ".concat(interaction.var2)
                        : interaction.var2
                }`
            );
            index += 1;
        });

        this.state.includeList.forEach((key) => {
            if (this.state.include[key]) {
                let independentVariable = !this.state.log[key]
                    ? key
                    : `ln ${key}`;
                let independentVariableWithConstant = `b${index} ${independentVariable}`;
                independentVariables.push(independentVariableWithConstant);
                index += 1;
            }
        });
        formula = formula + Array.from(independentVariables).join(" + ");
        return formula;
    }

    public render(): JSX.Element {
        var columns: {
            label: string;
            field: string;
            minimal?: string;
        }[] = [
            {
                label: "",
                field: "dashboard_variable",
                minimal: "lg",
            },
            {
                label: "units",
                field: "units",
            },
            {
                label: "include",
                field: "include",
            },
            {
                label: "log",
                field: "log",
            },
            {
                label: "fixed",
                field: "fixed",
            },
            {
                label: "interaction",
                field: "interaction",
            },
            {
                label: "interacts with",
                field: "interacts_with",
            },
            {
                label: "dynamic",
                field: "dynamic",
            },
            {
                label: "predict by mean",
                field: "default_value",
            },
            {
                label: "decimals (0 to 20)",
                field: "decimals",
            },
        ];
        if (!this.props.showDynamicColumn)
            columns = columns.filter((item) => item.field !== "dynamic");

        let filtered = this.props.allVariables.filter(
            (item) => item.name !== this.state.dependentVariable
        );

        let rows = filtered.map((item) => {
            let obj: { [key: string]: string | JSX.Element } = {};
            obj.dashboard_variable = item.name;
            obj.units = item.unit;
            obj.include = (
                <Switch
                    onChange={() => {
                        this.handleIncludeChange(item);
                    }}
                    checked={this.state.include[item.name]}
                    width={26}
                    height={13}
                    offColor="#20293C"
                    onColor="#20293C"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor="#70889E"
                    onHandleColor="#1F8EFA"
                />
            );
            obj.log = (
                <Switch
                    disabled={!this.state.include[item.name]}
                    onChange={() => {
                        this.handleLogChange(item.name);
                    }}
                    checked={
                        this.state.log[item.name] &&
                        this.state.include[item.name]
                    }
                    width={26}
                    height={13}
                    offColor="#20293C"
                    onColor="#20293C"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor="#70889E"
                    onHandleColor="#1F8EFA"
                />
            );
            obj.fixed = (
                <Switch
                    disabled={!this.state.include[item.name]}
                    onChange={() => {
                        this.handleFixedChange(item);
                    }}
                    checked={
                        (this.state.fixed[item.name] ||
                            !["int", "float"].includes(item.type)) &&
                        this.state.include[item.name]
                    }
                    width={26}
                    height={13}
                    offColor="#20293C"
                    onColor="#20293C"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor="#70889E"
                    onHandleColor="#1F8EFA"
                />
            );
            let interactionIndex = this.state.interactions.findIndex(
                (interaction) => interaction.var1 === item.name
            );
            let excluded = new Set(
                this.state.interactions
                    .filter((interaction) => interaction.var2 === item.name)
                    .map((interaction) => interaction.var1)
            );
            excluded.add(item.name);
            let checked = interactionIndex >= 0;
            obj.interaction = (
                <Switch
                    onChange={() => {
                        if (!checked) {
                            let var2 = this.state.includeList.filter(
                                (variable) => !excluded.has(variable)
                            )[0];
                            if (var2 != null) {
                                let interactions = Array.from(
                                    this.state.interactions
                                );
                                interactions.push({
                                    var1: item.name,
                                    var2: var2,
                                });
                                this.setState({ interactions: interactions });
                            }
                        } else {
                            let interactions = Array.from(
                                this.state.interactions
                            );
                            this.setState({
                                interactions: interactions.filter(
                                    (interaction) =>
                                        interaction.var1 !== item.name
                                ),
                            });
                        }
                    }}
                    disabled={!this.state.include[item.name]}
                    checked={
                        this.state.include[item.name] && interactionIndex >= 0
                    }
                    width={26}
                    height={13}
                    offColor="#20293C"
                    onColor="#20293C"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor="#70889E"
                    onHandleColor="#1F8EFA"
                />
            );
            obj.interacts_with = checked ? (
                <RegressionSelect
                    isClearable={false}
                    placeholder=""
                    variablesForSelection={this.state.includeList
                        .filter((variable) => !excluded.has(variable))
                        .map((variable) => ({
                            name: variable,
                        }))}
                    onChange={(value) => {
                        let interactions = Array.from(this.state.interactions);
                        interactions[interactionIndex].var2 = value!.value;
                        this.setState({ interactions: interactions });
                    }}
                    currentVariable={
                        this.state.interactions[interactionIndex].var2
                    }
                />
            ) : (
                <div />
            );
            if (this.props.showDynamicColumn)
                obj.dynamic = (
                    <Switch
                        onChange={() => {
                            this.handleDynamicChange(item.name);
                        }}
                        checked={
                            this.state.dynamic[item.name] &&
                            this.state.include[item.name]
                        }
                        width={26}
                        height={13}
                        offColor="#20293C"
                        onColor="#20293C"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#70889E"
                        onHandleColor="#1F8EFA"
                    />
                );
            obj.default_value = (
                <Switch
                    onChange={() => {
                        this.handleDefaultValueChange(item.name);
                    }}
                    checked={
                        this.state.defaultValue[item.name] &&
                        this.state.include[item.name]
                    }
                    width={26}
                    height={13}
                    offColor="#20293C"
                    onColor="#20293C"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor="#70889E"
                    onHandleColor="#1F8EFA"
                />
            );
            obj.decimals = (
                <input
                    disabled={!this.state.include[item.name]}
                    className="like-select"
                    value={!this.state.include[item.name] ? "" : this.state.decimals[item.name] ?? 1}
                    style={{
                        minHeight: undefined,
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                        height: "20px",
                        fontSize: "10px",
                        width: "50px",
                        textAlign: "left",
                    }}
                    onChange={(e) => {
                        let value = e.target.value;

                        if (!/^[0-9]*$/.test(value)) {
                            e.preventDefault();
                            return;
                        }
                        let numValue = Math.round(Number(value));
                        if (numValue < 0 || numValue > 20) {
                            e.preventDefault();
                            return;
                        }

                        this.handleDecimalsChange(item.name, numValue);
                    }}
                />
            );
            return obj;
        });

        let intercept: { [key: string]: string | JSX.Element } = {};
        intercept.dashboard_variable = "intercept";
        intercept.units = " ";
        intercept.include = (
            <Switch
                onChange={() => {
                    this.handleIncludeInterceptChange();
                }}
                checked={this.state.includeIntercept}
                width={26}
                height={13}
                offColor="#20293C"
                onColor="#20293C"
                checkedIcon={false}
                uncheckedIcon={false}
                offHandleColor="#70889E"
                onHandleColor="#1F8EFA"
            />
        );
        intercept.log = <div />;
        intercept.fixed = <div />;

        if (this.props.showDynamicColumn) intercept.dynamic = <div />;

        rows.unshift(intercept);

        let variablesForSelection = this.props.regressionVariables.filter(
            (variable) =>
                this.state.include[variable.name] === false &&
                variable.name !== this.state.dependentVariable
        );
        return (
            <div
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                className="flex-simple-column"
                style={{ width: "100%", height: "100%", paddingRight: "10px" }}
            >
                <div
                    className="my-row"
                    style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    <div className="my-row" style={{ alignItems: "center" }}>
                        <span
                            className="text-center"
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-primary-text-color"
                                ),
                                fontFamily: "Arial",
                                fontSize: "12px",
                            }}
                        >
                            Current Model
                        </span>
                        {this.state.editModel ? (
                            this.buildEditModelComponent()
                        ) : (
                            <span
                                className="text-center"
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-tertiary-text-color"
                                    ),
                                    fontFamily: "Arial",
                                    fontStyle: "italic",
                                    fontSize: "12px",
                                    marginLeft: "30px",
                                }}
                            >
                                {this.getFormula()}
                            </span>
                        )}
                    </div>

                    <span
                        className="text-center"
                        style={{
                            marginLeft: "30px",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                            fontWeight: 700,
                        }}
                    >
                        press [SHIFT+E] KEY to edit model
                    </span>
                </div>
                <div className="my-row">
                    <div
                        className="flex-simple-column"
                        style={{
                            justifyContent: "left",
                            marginTop: 20,
                            marginBottom: 20,
                        }}
                    >
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-primary-text-color"
                                ),
                                fontFamily: "Arial",
                                fontSize: "12px",
                            }}
                        >
                            Dependent variable
                        </span>
                        <div
                            className="my-row"
                            style={{ marginTop: 20, marginLeft: 20 }}
                        >
                            <div
                                style={{
                                    width: "100px",
                                }}
                            >
                                <Select
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    styles={{
                                        ...customSelectStyles,
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 100000000,
                                        }),
                                    }}
                                    onChange={(newValue) => {
                                        if (newValue != null)
                                            this.handleDependentVariableChange(
                                                newValue as StringOption
                                            );
                                    }}
                                    value={{
                                        label:
                                            this.state.dependentVariable ?? "",
                                        value:
                                            this.state.dependentVariable ?? "",
                                    }}
                                    options={variablesForSelection.map(
                                        (dashboardVariable) => ({
                                            label: dashboardVariable.name,
                                            value: dashboardVariable.name,
                                        })
                                    )}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            <div style={{ marginLeft: 20 }}>
                                <span
                                    className="text-center"
                                    style={{
                                        display: "block",
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-primary-text-color"
                                        ),
                                        fontFamily: "Arial",
                                        fontSize: "10px",
                                    }}
                                >
                                    log
                                </span>
                                <Switch
                                    onChange={
                                        this.handleLogDependentVariableChange
                                    }
                                    checked={this.state.dependentVariableLog}
                                    width={26}
                                    height={13}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        </div>
                    </div>
                    <RunRegressionTable columns={columns} rows={rows} />
                </div>
            </div>
        );
    }
}

export default RunHoldOutRegressionComponent;
