import axios from "common/ServerConnection";
import { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import { GroupExtendedPermission } from "common/GroupPermissions";
import {
    Condition,
    conditionsToJson,
    filterNonEmptyConditions,
} from "common/Conditions";
import { VariableOption } from "common/Variables";
import { NewVariable, EditVariable } from "common/VariableCreator";
import { RenameVariable } from "common/VariableEditor";
import { stringSessionId } from "common/SessionId";
import { NodeValue, SubmitOption } from "./Canvas";
import { Panel, Type } from "./InputData";
import PopupData from "./PopupData";
import tables from "common/Tables";

export async function renameVariablesApi(
    dataScopeId: string | number,
    renameVariables: RenameVariable[],
    moduleId: number | string | null
) {
    let jsonRequest = {
        data_table_idx: dataScopeId,
        rename_variables: renameVariables.filter(
            (variable) => variable.name && variable.new_name
        ),
        new_variables: [],
        drop_variables: [],
        module_id: moduleId ?? undefined,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/e/modify_schema", jsonRequest, {})
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve("success");
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function modifySchemaApi(
    dataScopeId: string | number,
    renameVariables: Partial<EditVariable>[],
    newVariables: NewVariable[],
    dropVariables: number[],
    moduleId?: number | string | null
): Promise<number[]> {
    let jsonRequest = {
        data_table_idx: dataScopeId,
        rename_variables: renameVariables,
        new_variables: newVariables,
        drop_variables: dropVariables,
        module_id: moduleId ?? undefined,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            deleted_indices: number[];
        }>("/api/e/modify_schema", jsonRequest, {})
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve(response.data.deleted_indices);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function sliceData(
    table: TableOption,
    conditions: Condition[],
    preview: boolean
): Promise<
    | {
          tableHeader: string[];
          tablePreview: any[];
      }
    | string
> {
    let conditionsJson = conditionsToJson(filterNonEmptyConditions(conditions));
    let jsonRequest: {
        table: number[];
        data_table_idx: string | number | undefined;
        conditions: typeof conditionsJson;
        preview?: number;
        update_id?: string;
    } = {
        table: table.value,
        data_table_idx: table.data_table_idx,
        conditions: conditionsJson,
        update_id: stringSessionId(),
    };
    if (preview) jsonRequest.preview = 5;

    return axios
        .post<{
            success: boolean;
            error_msg: string;
            table_header: string[];
            table_preview: any[];
            condition_id: string;
        }>("/api/slice_current", jsonRequest)
        .then((response) => {
            if (response.data.success === false) {
                return Promise.reject(response.data.error_msg);
            } else {
                if (preview) {
                    return Promise.resolve({
                        tableHeader: response.data.table_header,
                        tablePreview: response.data.table_preview,
                    } as any);
                } else {
                    return Promise.resolve(response.data.condition_id);
                }
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}

export async function getRawDataApi(
    tableOption: TableOption,
    limit: number | undefined,
    conditions?: Condition[],
    variables?: VariableOption[],
    bottomRows?: boolean,
    moduleId?: number | string | null,
    rowId?: number[],
    getCount?: boolean,
    updateFromDataSource?: boolean
): Promise<{
    currentLevels: { [key: string]: (number | string | null)[] };
    rowId: number[];
    mode: "replace" | "append";
    count?: number;
}> {
    let filteredConditions: Condition[] | undefined =
        conditions != null ? filterNonEmptyConditions(conditions) : undefined;
    let variable_indices =
        variables?.map((option) => option.value) ?? undefined;
    if (variable_indices && variable_indices.length === 0)
        variable_indices = undefined;
    let json = {
        limit: limit,
        data_table_idx: tableOption.data_table_idx,
        table: tableOption.value,
        condition_id: tableOption.condition_id,
        conditions:
            filteredConditions != null
                ? conditionsToJson(filteredConditions)
                : undefined,
        variable_indices: variable_indices,
        bottom_rows: bottomRows,
        module_id: moduleId ?? undefined,
        row_id: rowId,
        get_count: getCount,
        update_from_data_source: updateFromDataSource,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            current_levels: { [key: string]: (number | string | null)[] };
            row_id: number[];
            mode: "replace" | "append";
            count?: number;
        }>("/api/e/get_raw_data", json)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve({
                    currentLevels: response.data.current_levels,
                    rowId: response.data.row_id,
                    mode: response.data.mode,
                    count: response.data.count,
                });
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function copyTableApi(
    tableOption: TableOption,
    dataScopeOption: DataScopeOption,
    permissions: (GroupExtendedPermission | null)[],
    moduleId?: number | string | null
): Promise<void> {
    let value = dataScopeOption!.value;
    let json = {
        data_table_idx: tableOption.data_table_idx,
        table: tableOption.value,
        condition_id: tableOption.condition_id,
        optimized: tableOption.optimized,
        new_data_table_idx: !Number.isNaN(value)
            ? dataScopeOption!.value
            : undefined,
        new_data_table_name: dataScopeOption!.label,
        permissions: permissions
            .filter(
                (permission): permission is GroupExtendedPermission =>
                    permission != null
            )
            .map((permission) => ({
                group_id: permission.group_id,
                permission_type: permission.permission_type,
            })),
        module_id: moduleId,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/e/copy_table", json)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function exportTableToCSVApi(
    tableOption: TableOption,
    moduleId?: number | string | null
): Promise<string> {
    let json = {
        data_table_idx: tableOption.data_table_idx,
        table: tableOption.value,
        condition_id: tableOption.condition_id,
        optimized: tableOption.optimized,
        module_id: moduleId,
    };
    return axios
        .post<string>("/api/e/export_table_to_csv", json)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editCurrentDataApi(
    tableOption: TableOption,
    changes: {
        row_id: number;
        column_index: number;
        value: NodeValue | null;
    }[],
    moduleId?: number | string | null
): Promise<void> {
    let json = {
        data_table_idx: tableOption.data_table_idx,
        changes: changes,
        module_id: moduleId,
        create_time_levels: true,
        create_geography_levels: true,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/e/edit_current_data", json)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function checkLiveStreamModeApi(
    dataScopeId: string | number
): Promise<string> {
    let json = {
        data_table_idx: dataScopeId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            mode: string;
        }>("/api/get_live_stream_mode", json)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.mode);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function dropVariableApi(
    dataScopeId: string | number,
    variableIndex: number,
    moduleId?: number | string | null
): Promise<number[]> {
    return modifySchemaApi(dataScopeId, [], [], [variableIndex], moduleId);
}

export async function insertRows(
    tableOption: TableOption,
    rows: { [key: string]: NodeValue | null }[],
    moduleId?: number | string | null, // string is a shared link id
    addVariableRowLevel: boolean = false
): Promise<number[]> {
    let json = {
        optimized: false,
        data_table_idx: tableOption.data_table_idx,
        table: tableOption.value,
        condition_id: tableOption.condition_id,
        rows: rows,
        module_id: moduleId,
        create_time_levels: true,
        create_geography_levels: true,
        add_variable_row_level: addVariableRowLevel,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            row_ids: number[];
        }>("/api/e/insert_rows", json)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.row_ids);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function updateRows(
    backendOutput: { 
        dataScopeOption: any; tableOption?: TableOption | null; 
        variableOptions?: { contextVariable?: VariableOption | undefined; 
        contextId?: any; update?: boolean | undefined; 
        allowNans?: boolean | undefined; node: SubmitOption | null; 
        variable: VariableOption | null; }[]; mappingItem?: any; 
    },  
    update: any,
    moduleId?: number | string
) {

    const payload: { row_id: number; column_index: number; value: NodeValue | null; }[] = [];

    Object.keys(update).forEach((key) => {
        const variableInfo = backendOutput.variableOptions?.find((opt) => opt.variable?.label === key);
        if(variableInfo?.variable?.value && PopupData.data?.row_id)
            payload.push({
                row_id: PopupData.data?.row_id,
                value: update[key],
                column_index: variableInfo?.variable?.value
            });
    })

    const db = backendOutput.dataScopeOption.value;
    await editCurrentDataApi(
        tables(
            db
        ).tableToOption(),
        payload,
        moduleId
    );
}

export async function deleteRows(
    tableOption: TableOption,
    rowIds: number[],
    moduleId?: number | string | null
): Promise<void> {
    let json = {
        optimized: false,
        data_table_idx: tableOption.data_table_idx,
        table: tableOption.value,
        condition_id: tableOption.condition_id,
        row_ids: rowIds,
        module_id: moduleId,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/e/delete_rows", json)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addVariable(
    table: TableOption,
    code: string | null,
    variables: NewVariable[],
    onSuccess: (
        preview:
            | {
                  tableHeader: string[];
                  tablePreview: (string | number | null)[][];
                  copyDataScopeId?: number;
              }
            | undefined
    ) => void,
    onError: (error: string) => void,
    preview: boolean = false,
    rowLevel: boolean = false,
    geoSourceVariable: VariableOption | undefined = undefined,
    isCallFrom: boolean = false,
    conditions?: Condition[]
) {
    let conditionsJson =
        conditions != null
            ? conditionsToJson(filterNonEmptyConditions(conditions))
            : undefined;
    if (conditionsJson?.length === 0) {
        conditionsJson = undefined;
    }
    let jsonRequest = {
        table: table.value,
        data_table_idx: table.data_table_idx,
        condition_id: table.condition_id,
        generic_variables: variables.filter(
            (variable) => variable.name && variable.type
        ),
        code: code,
        preview: preview ? 5 : undefined,
        row_level: rowLevel,
        update_id: stringSessionId(),
        geo_source_index: geoSourceVariable?.value,
        conditions: conditionsJson,
    };
    axios
        .post<{
            success: boolean;
            error_msg: string;
            table_header: string[];
            table_preview: (string | number | null)[][];
        }>("/api/e/add_variable", jsonRequest)
        .then((response) => {
            if (response.data.success === false) {
                onError(response.data.error_msg);
            } else {
                if (preview && !isCallFrom) {
                    onSuccess({
                        tableHeader: response.data.table_header,
                        tablePreview: response.data.table_preview,
                    });
                } else onSuccess(undefined);
                if (isCallFrom) {
                    DataScopes.previewState = { headers: null, body: null };
                    if (!isNaN(Number(table.data_table_idx))) {
                        DataScopes.DatasetPreview(Number(table.data_table_idx));
                    }
                }
            }
        })
        .catch((error) => {
            onError(String(error));
        });
}

export async function modelPredictAddVariable(
    table: TableOption,
    variable: {
        name: string;
        unit: string | null;
        type: Type;
        level: string | null;
        format: string | null;
        panel: Panel;
    },
    modelId: number,
    moduleId?: string | number,
    preview: boolean = false
): Promise<
    | {
          tableHeader: string[];
          tablePreview: (string | number | null)[][];
      }
    | undefined
> {
    let jsonRequest = {
        model_id: modelId,
        data_table_idx: table.data_table_idx,
        table: table.value,
        condition_id: table.condition_id,
        generic_variable: variable,
        preview: preview ? 5 : undefined,
        module_id: moduleId,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            table_header?: string[];
            table_preview?: (string | number | null)[][];
        }>("/api/model_predict_add_variable", jsonRequest)
        .then((response) => {
            if (response.data.success === false) {
                return Promise.reject(response.data.error_msg);
            } else {
                if (
                    preview &&
                    response.data.table_header != null &&
                    response.data.table_preview != null
                ) {
                    return Promise.resolve({
                        tableHeader: response.data.table_header,
                        tablePreview: response.data.table_preview,
                    });
                } else {
                    return Promise.resolve(undefined);
                }
            }
        });
}

export async function deleteTable(
    tableOption: TableOption,
    moduleId?: number | string | null // string is a shared link id
): Promise<void> {
    let json = {
        optimized: tableOption.optimized,
        data_table_idx: tableOption.data_table_idx,
        table: tableOption.value,
        condition_id: tableOption.condition_id,
        module_id: moduleId,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/e/delete_table", json)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteDataSet(
    dataScopeId: string | number,
    force?: boolean,
    moduleId?: number | string | null
): Promise<void> {
    let json = {
        data_table_idx: dataScopeId,
        module_id: moduleId,
        force: force,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/e/delete_data_tables", json)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
