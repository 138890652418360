import { makeAutoObservable, runInAction } from "mobx";
import stringSimilarity from "string-similarity";

import {
    addEmptyUserModuleApi,
    addUserPageToModuleApi,
    getUserModulesApi,
    getCommonUserModulesApi,
    getSharedModulesSpecialTabApi,
    moveTrashModuleApi,
    deleteModuleApi,
    getTemplateModulesApi,
    getSharedModuleAppsSpecialTabApi,
    getLastAccessedAppsApi,
    getSharedModuleAppsWithCategory,
    getSharedModulesWithCategories,
} from "common/ModulesApi";
import { addCanvasApi } from "common/CanvasUserApi";
import { defaultCanvas } from "common/Canvas";
import Presentation, { PresentationType } from "state/models/Presentation";
import PresentationFilterDropdownOption from "state/models/PresentationFilterDropdownOptions";
import { BriefModule, SpecialTabId } from "common/Module";
import {
    deleteFolder,
    Folder,
    folderAddModule,
    folderDeleteModule,
    FolderType,
    getModuleFolders,
} from "common/FoldersApi";
import _ from "lodash";

const queryLimit = 6;

export const similarity = (
    presentation: Presentation,
    searchText: string,
    keywords?: string[]
) => {
    // search criteria
    // title
    let metrics = 0;
    if (presentation.title) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                presentation.title.toLowerCase(),
                searchText.toLowerCase()
            ) * 0.4;
    }
    let maxKeywordsMetrics = 0;
    if (
        (presentation.keywords ?? []).length > 0 &&
        keywords != null &&
        keywords.length > 0
    ) {
        let a = new Set(presentation.keywords ?? []);
        let b = new Set(keywords);
        let intersect = new Set([...a].filter((i) => b.has(i)));
        metrics += intersect.size * 0.2;
    }
    if (presentation.keywords && keywords == null) {
        for (let keyword of presentation.keywords) {
            maxKeywordsMetrics = Math.max(
                maxKeywordsMetrics,
                stringSimilarity.compareTwoStrings(
                    keyword.toLowerCase(),
                    searchText.toLowerCase()
                )
            );
        }
        metrics += maxKeywordsMetrics * 0.4;
    }

    //subtitle
    if (presentation.description) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                presentation.description.toLowerCase(),
                searchText.toLowerCase()
            ) * 0.2;
    }

    return metrics;
};

class PresentationStore {
    private _presentations: Array<Presentation> = [];

    private _fetchedSharedQueue: Array<Presentation> = [];
    private _fetchedUserQueue: Array<Presentation> = [];
    private _templateFolders: Array<Folder> = [];
    private _tutorialFolders: Array<Folder> = [];
    private _presentationFolders: Array<Folder> = [];

    private _templates: Array<Presentation> = [];
    private _trash: Array<Presentation> = [];
    private _tutorials: Array<Presentation> = [];
    private _apps: Array<Presentation> = [];

    private _searchText: string = "";
    private _currentFolder: Folder | null = null;
    private _filter: PresentationFilterDropdownOption =
        PresentationFilterDropdownOption.ALL;

    fetchingPresentations: boolean = false;
    fetchingUserPresentations: boolean = false;
    fetchingSharedPresentations: boolean = false;
    fetchingTemplates: boolean = false;
    fetchingTrash: boolean = false;
    fetchingTutorials: boolean = false;
    fetchingApps: boolean = false;
    allAppKeywords: string[] = [];
    appSelectedKeywords: string[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    private _searchPresentations = (
        presentations: Presentation[],
        keywords?: string[]
    ) => {
        if (this._searchText.length === 1) {
            return presentations
                .filter((pres) => pres.title.toLowerCase().includes(this._searchText.toLowerCase()));
        } else {
            return presentations
                .map((pres) => ({
                    presentation: pres,
                    similarity: similarity(pres, this._searchText, keywords),
                }))
                .filter((pres) => pres.similarity > 0.01)
                .sort((p1, p2) => {
                    if (p1.similarity < p2.similarity) return 1;
                    if (p1.similarity > p2.similarity) return -1;
                    return 0;
                })
                .map((pres) => pres.presentation);
        }
    };

    private _fetchAllPresentations = async (
        inTrash: boolean,
        offset: number,
        limit: number,
        appendFunction: { (data: Presentation[], shared: boolean): void }
    ) => {
        const fetchAllPresentationsInner = async (
            offset: number,
            limit: number
        ) => {
            const result = await getUserModulesApi(inTrash, offset, limit);
            if (!result.length) {
                this.fetchingUserPresentations = false;
                runInAction(() => {
                    appendFunction([], false);
                });
                return;
            }

            const parsedResult = result.map(
                (presentationJson) =>
                    new Presentation(
                        presentationJson,
                        PresentationType.RegularPresentation,
                        inTrash
                    )
            );

            runInAction(() => {
                appendFunction(parsedResult, false);
            });

            await fetchAllPresentationsInner(offset + limit, limit);
        };
        this.fetchingUserPresentations = true;

        await fetchAllPresentationsInner(offset, limit);
    };

    private _fetchAllCreateNewApps = async (offset: number, limit: number) => {
        const fetchAllCreateNewAppsInner = async (
            offset: number,
            limit: number
        ) => {
            const result = await getSharedModuleAppsSpecialTabApi(
                SpecialTabId.CreateNew,
                offset,
                limit
            );
            if (!result.length) return;
            const parsedResult = result.map(
                (presentationJson) =>
                    new Presentation(presentationJson, PresentationType.App)
            );
            runInAction(() => {
                this._apps = this._apps.concat(
                    parsedResult.filter(
                        (item) => !this._apps.find((app) => item.id === app.id)
                    )
                );
            });

            await fetchAllCreateNewAppsInner(offset + limit, limit);
        };

        await fetchAllCreateNewAppsInner(offset, limit);
    };
    private _fetchAllCategoryApps = async (offset: number, limit: number) => {
        const fetchAllCategoryAppsInner = async (
            offset: number,
            limit: number
        ) => {
            const result = await getSharedModuleAppsWithCategory(
                offset,
                limit
            );
            if (!result.length) return;
            const parsedResult = result.map(
                (presentationJson) =>
                    new Presentation(presentationJson, PresentationType.App)
            );
            runInAction(() => {
                this._apps = this._apps.concat(
                    parsedResult.filter(
                        (item) => !this._apps.find((app) => item.id === app.id)
                    )
                );
            });

            await fetchAllCategoryAppsInner(offset + limit, limit);
        };

        await fetchAllCategoryAppsInner(offset, limit);
    };
    private _fetchLastAccessedApps = async (offset: number, limit: number) => {
        const fetchLastAccessedAppsInner = async (
            offset: number,
            limit: number
        ) => {
            const result = await getLastAccessedAppsApi(offset, limit);
            if (!result.length) return;
            const parsedResult = result.map(
                (presentationJson) =>
                    new Presentation(presentationJson, PresentationType.App)
            );
            runInAction(() => {
                this._apps = this._apps.concat(
                    parsedResult.filter(
                        (item) => !this._apps.find((app) => item.id === app.id)
                    )
                );
            });

            await fetchLastAccessedAppsInner(offset + limit, limit);
        };

        await fetchLastAccessedAppsInner(offset, limit);
    };

    private _fetchSharedPresentations = async (
        offset: number,
        limit: number,
        appendFunction: { (data: Presentation[], shared: boolean): void }
    ) => {
        const fetchAllPresentationsInner = async (
            offset: number,
            limit: number
        ) => {
            const result = await getCommonUserModulesApi(offset, limit);
            if (!result.length) {
                this.fetchingSharedPresentations = false;
                runInAction(() => {
                    appendFunction([], true);
                });
                return;
            }

            const parsedResult = result.map(
                (presentationJson) =>
                    new Presentation(
                        presentationJson,
                        PresentationType.RegularPresentation
                    )
            );

            runInAction(() => {
                appendFunction(parsedResult, true);
            });
            this.fetchingSharedPresentations = true;
            await fetchAllPresentationsInner(offset + limit, limit);
        };

        await fetchAllPresentationsInner(offset, limit);
    };

    private _fetchAllTemplates = async (offset: number, limit: number) => {
        const fetchAllTemplatesInner = async (
            offset: number,
            limit: number
        ) => {
            const result = await getTemplateModulesApi(offset, limit);
            if (!result.length) return;

            const parsedResult = result.map(
                (presentationJson) =>
                    new Presentation(
                        presentationJson,
                        PresentationType.Template
                    )
            );

            runInAction(() => {
                this._templates = this._templates.concat(
                    parsedResult.filter(
                        (item) =>
                            !this._templates.find(
                                (template) => template.id === item.id
                            )
                    )
                );
            });

            await fetchAllTemplatesInner(offset + limit, limit);
        };

        await fetchAllTemplatesInner(offset, limit);
    };

    private _fetchAllSpecialTabTemplates = async (
        offset: number,
        limit: number,
        tabId: SpecialTabId
    ) => {
        const fetchAllSpecialTabTemlatesInner = async (
            offset: number,
            limit: number,
            tabId: SpecialTabId
        ) => {
            const result = await getSharedModulesSpecialTabApi(
                [tabId],
                offset,
                limit
            );
            if (!result.length) return;
            const parsedResult = result.map(
                (presentationJson) =>
                    new Presentation(
                        presentationJson,
                        PresentationType.Template
                    )
            );

            runInAction(() => {
                this._templates = this._templates.concat(
                    parsedResult.filter(
                        (item) =>
                            !this._templates.find(
                                (template) => template.id === item.id
                            )
                    )
                );
            });

            await fetchAllSpecialTabTemlatesInner(offset + limit, limit, tabId);
        };

        await fetchAllSpecialTabTemlatesInner(offset, limit, tabId);
    };

    private _fetchAllTemplatesWithCategories = async (
        offset: number,
        limit: number,
    ) => {
        const fetchAllTemplatesWithCategoriesInner = async (
            offset: number,
            limit: number
        ) => {
            const result = await getSharedModulesWithCategories(
                offset,
                limit
            );
            if (!result.length) return;
            const parsedResult = result.map(
                (presentationJson) =>
                    new Presentation(
                        presentationJson,
                        PresentationType.Template
                    )
            );

            runInAction(() => {
                this._templates = this._templates.concat(
                    parsedResult.filter(
                        (item) =>
                            !this._templates.find(
                                (template) => template.id === item.id
                            )
                    )
                );
            });

            await fetchAllTemplatesWithCategoriesInner(offset + limit, limit);
        };

        await fetchAllTemplatesWithCategoriesInner(offset, limit);
    };

    _fetchPresentationFolders = async () => {
        let folders = await getModuleFolders(FolderType.Presentations);
        runInAction(() => {
            this._presentationFolders = folders;
        });
    };

    _fetchTemplateFolders = async () => {
        let folders = await getModuleFolders(FolderType.Templates);
        runInAction(() => {
            this._templateFolders = folders;
        });
    };

    _fetchTutorialFolders = async () => {
        let folders = await getModuleFolders(FolderType.Tutorials);
        runInAction(() => {
            this._tutorialFolders = folders;
        });
    };
    appendPresentation(presentationJson: BriefModule) {
        let presentation = new Presentation(
            presentationJson,
            PresentationType.RegularPresentation,
            false
        );
        runInAction(() => {
            this._presentations = this._presentations.concat(presentation);
        });
    }

    fetchPresentations = async () => {
        this.fetchingPresentations = true;
        this._presentations = [];
        const appendFunction = (
            result: Array<Presentation>,
            shared: boolean
        ) => {
            if (result.length > 0) {
                // Do not use _applyOrder here because it would filter out
                // all presentations where folderId != null
                if (shared) {
                    this._fetchedSharedQueue = PresentationStore.sortByUpdateTime(
                        this._fetchedSharedQueue.concat(result)
                    );
                } else {
                    this._fetchedUserQueue = PresentationStore.sortByUpdateTime(
                        this._fetchedUserQueue.concat(result)
                    );
                }
            }

            if (
                this.fetchingSharedPresentations ||
                this.fetchingUserPresentations
            ) {
                let presentationsForAppend: Presentation[] = [];
                let sharedOffset = 0;
                let userOffset = 0;
                if (
                    this.fetchingSharedPresentations &&
                    this._fetchedSharedQueue.length === 0
                )
                    return;
                if (
                    this.fetchingUserPresentations &&
                    this._fetchedUserQueue.length === 0
                )
                    return;
                while (true) {
                    let lastShared = this._fetchedSharedQueue[sharedOffset];
                    let lastUser = this._fetchedUserQueue[userOffset];
                    if (lastShared == null || lastUser == null) {
                        break;
                    }

                    // Do not use _applyOrder here because it would filter out
                    // all presentations where folderId != null
                    let ordered = PresentationStore.sortByUpdateTime([
                        lastShared,
                        lastUser,
                    ]);
                    presentationsForAppend.unshift(ordered[0]);
                    if (ordered[0].id === lastShared.id) {
                        userOffset += 1;
                    } else {
                        sharedOffset += 1;
                    }
                }
                presentationsForAppend = _.uniqBy(
                    presentationsForAppend,
                    function (e) {
                        return e.id;
                    }
                );
                this._presentations = this._presentations.concat(
                    presentationsForAppend.filter(
                        (item) =>
                            !this._presentations.find(
                                (presentation) => presentation.id === item.id
                            )
                    )
                );
                this._fetchedUserQueue = this._fetchedUserQueue.filter(
                    (presentation) =>
                        presentationsForAppend.find(
                            (appended) => appended.id === presentation.id
                        ) == null
                );
                this._fetchedSharedQueue = this._fetchedSharedQueue.filter(
                    (presentation) =>
                        presentationsForAppend.find(
                            (appended) => appended.id === presentation.id
                        ) == null
                );
            } else {
                // Do not use _applyOrder here because it would filter out
                // all presentations where folderId != null
                let presentationsForAppend = PresentationStore.sortByUpdateTime(
                    this._fetchedSharedQueue.concat(this._fetchedUserQueue)
                );

                presentationsForAppend = _.uniqBy(
                    presentationsForAppend,
                    function (e) {
                        return e.id;
                    }
                );
                this._presentations = this._presentations.concat(
                    presentationsForAppend.filter(
                        (item) =>
                            !this._presentations.find(
                                (presentation) => presentation.id === item.id
                            )
                    )
                );
                this._fetchedUserQueue = [];
                this._fetchedSharedQueue = [];
                this.fetchingPresentations = false;
            }
        };

        try {
            await this._fetchPresentationFolders();
            this._fetchAllPresentations(false, 0, queryLimit, appendFunction);
            this._fetchSharedPresentations(0, queryLimit, appendFunction);
        } catch (error) {
            console.log(error);
        }
    };

    folderCollectionByType(
        type: FolderType
    ): "_templateFolders" | "_presentationFolders" | "_tutorialFolders" {
        switch (type) {
            case FolderType.Presentations:
                return "_presentationFolders";
            case FolderType.Templates:
                return "_templateFolders";
            case FolderType.Tutorials:
                return "_tutorialFolders";
        }
    }

    folderSubcollectionByType(
        type: FolderType
    ): "_templates" | "_presentations" | "_tutorials" {
        switch (type) {
            case FolderType.Presentations:
                return "_presentations";
            case FolderType.Templates:
                return "_templates";
            case FolderType.Tutorials:
                return "_tutorials";
        }
    }

    updateFolder(
        title: string,
        editFolderId: number | undefined,
        type: FolderType
    ) {
        runInAction(() => {
            let folders = Array.from(this[this.folderCollectionByType(type)]);
            let folder = folders.find((folder) => folder.id === editFolderId);
            if (folder != null) {
                folder.title = title;
            }
            this[this.folderCollectionByType(type)] = folders;
        });
    }

    putPresentationIntoFolder = async (
        presentationId: number,
        folderId: number,
        folderType: FolderType
    ) => {
        try {
            await folderAddModule(presentationId, folderId, folderType);
            runInAction(() => {
                this[this.folderSubcollectionByType(folderType)] = this[
                    this.folderSubcollectionByType(folderType)
                ].map((presentation) => {
                    if (presentation.id === presentationId)
                        presentation.folderId = folderId;
                    return presentation;
                }) as Array<Presentation>;
            });
        } catch (error) {
            console.log(String(error));
        }
    };

    removePresentationFromFolder = async (
        presentationId: number,
        folderId: number,
        folderType: FolderType
    ) => {
        try {
            await folderDeleteModule(presentationId, folderId, folderType);
            runInAction(() => {
                this[this.folderSubcollectionByType(folderType)] = this[
                    this.folderSubcollectionByType(folderType)
                ].map((presentation) => {
                    if (presentation.id === presentationId)
                        presentation.folderId = undefined;
                    return presentation;
                }) as Array<Presentation>;
            });
        } catch (error) {
            console.log(String(error));
        }
    };

    addFolder(title: string, editFolderId: number, type: FolderType) {
        runInAction(() => {
            let folders = Array.from(this[this.folderCollectionByType(type)]);
            let newFolder = {
                title: title,
                id: editFolderId,
                type: type,
            };
            folders.push(newFolder);
            this[this.folderCollectionByType(type)] = folders;
        });
    }

    deleteFolder = async (folder: Folder) => {
        try {
            await deleteFolder(folder.id, folder.type);
            runInAction(() => {
                this[this.folderCollectionByType(folder.type)] = this[
                    this.folderCollectionByType(folder.type)
                ].filter((otherFolder) => otherFolder.id !== folder.id);
                this[this.folderSubcollectionByType(folder.type)] = this[
                    this.folderSubcollectionByType(folder.type)
                ].map((presentation) => {
                    if (presentation.folderId === folder.id)
                        presentation.folderId = undefined;
                    return presentation;
                }) as Array<Presentation>;
            });
        } catch (error) {
            console.log(String(error));
        }
    };

    fetchTemplates = async () => {
        this.fetchingTemplates = true;
        this._templates = [];
        try {
            await this._fetchTemplateFolders();
            await this._fetchAllSpecialTabTemplates(
                0,
                queryLimit,
                SpecialTabId.CreateNew
            );
            await this._fetchAllSpecialTabTemplates(
                0,
                queryLimit,
                SpecialTabId.Kit
            );
            await this._fetchAllTemplatesWithCategories(0, queryLimit);
            await this._fetchAllTemplates(0, queryLimit);
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.fetchingTemplates = false;
            });
        }
    };

    fetchApps = async () => {
        this.fetchingApps = true;
        this._apps = [];
        try {
            await this._fetchAllCreateNewApps(0, queryLimit);
            await this._fetchLastAccessedApps(0, queryLimit);
            await this._fetchAllCategoryApps(0, queryLimit);
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.allAppKeywords = Array.from(
                    new Set(this._apps.map((app) => app.keywords ?? []).flat())
                );
                this.fetchingApps = false;
            });
        }
    };

    fetchTrash = async () => {
        this.fetchingTrash = true;

        const appendFunction = (result: Array<Presentation>) => {
            this._trash = this._trash.concat(result);
        };

        try {
            await this._fetchAllPresentations(
                true,
                0,
                queryLimit,
                appendFunction
            );
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.fetchingTrash = false;
            });
        }
    };
    private _fetchAllTutorials = async (offset: number, limit: number) => {
        const fetchAllTutorialsInner = async (
            offset: number,
            limit: number,
            tabIds: SpecialTabId[]
        ) => {
            const result = await getSharedModulesSpecialTabApi(
                tabIds,
                offset,
                limit
            );
            if (!result.length) return;
            const parsedResult = result.map(
                (presentationJson) =>
                    new Presentation(
                        presentationJson,
                        PresentationType.Template
                    )
            );

            runInAction(() => {
                this._tutorials = this._tutorials.concat(parsedResult);
            });

            await fetchAllTutorialsInner(offset + limit, limit, tabIds);
        };

        await fetchAllTutorialsInner(offset, limit, [
            SpecialTabId.TutorialsInitialList,
            SpecialTabId.Tutorials,
        ]);
    };
    fetchTutorials = async () => {
        this.fetchingTutorials = true;
        this._tutorials = [];
        try {
            await this._fetchTutorialFolders();
            await this._fetchAllTutorials(0, queryLimit);
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.fetchingTutorials = false;
            });
        }
    };

    movePresentationToTrash = async (presentationId: number) => {
        const presentation = this._presentations.find(
            (p) => p.id === presentationId
        );

        if (!presentation) return;

        await moveTrashModuleApi(presentation, true);

        this._presentations = this._presentations.filter(
            (p) => p.id !== presentationId
        );
        let trash = this._trash.slice();

        trash.push(presentation);
        runInAction(() => {
            this._trash = trash;
        });
    };

    deletePresentationFromTrash = async (presentationId: number) => {
        const presentation = this._trash.find((p) => p.id === presentationId);

        if (!presentation) return;

        await deleteModuleApi(
            presentation as {
                id: number;
                isShared: boolean;
            }
        );
        runInAction(() => {
            this._trash = this._trash.filter((p) => p.id !== presentationId);
        });
    };
    deletePresentationFromTemplates = async (presentationId: number) => {
        const template = this._templates.find((p) => p.id === presentationId);

        if (!template) return;

        await deleteModuleApi(
            template as {
                id: number;
                isShared: boolean;
            },
            true
        );
        runInAction(() => {
            this._templates = this._templates.filter(
                (p) => p.id !== presentationId
            );
        });
    };

    deletePresentationFromTutorials = async (presentationId: number) => {
        const tutorial = this._tutorials.find((p) => p.id === presentationId);

        if (!tutorial) return;

        await deleteModuleApi(
            tutorial as {
                id: number;
                isShared: boolean;
            },
            true
        );
        runInAction(() => {
            this._tutorials = this._tutorials.filter(
                (p) => p.id !== presentationId
            );
        });
    };

    restorePresentationFromTrash = async (presentationId: number) => {
        const presentation = this._trash.find((p) => p.id === presentationId);

        if (!presentation) return;

        await moveTrashModuleApi(presentation, false);
        let presentations = this._presentations.slice();
        presentations.push(presentation);
        runInAction(() => {
            this._presentations = presentations;
            this._trash = this._trash.filter((p) => p.id !== presentationId);
        });
    };

    searchPresentations = (searchText: string) => {
        this._searchText = searchText;
    };

    setCurrentFolder = (folder: Folder | null) => {
        this._currentFolder = folder;
    };

    filterPresentations = (value: PresentationFilterDropdownOption) => {
        this._filter = value;
    };

    createBlankPresentation = async () => {
        const presentationId = await addEmptyUserModuleApi();
        const pageId = await addUserPageToModuleApi(
            "Section Name 1",
            presentationId
        );
        await addCanvasApi(pageId, "1", defaultCanvas(), [], null, []);
        return presentationId;
    };

    applyFilter(presentations: Presentation[], keywords?: string[]) {
        let presentationsArray =
            this._searchText || (keywords && keywords?.length > 0)
                ? this._searchPresentations(presentations, keywords)
                : presentations;

        if (
            this._filter.value ===
            PresentationFilterDropdownOption.CREATED_BY_ME.value
        ) {
            presentationsArray = presentationsArray.filter((p) => !p.isShared);
        }
        if (
            this._filter.value ===
            PresentationFilterDropdownOption.SHARED_WITH_ME.value
        ) {
            presentationsArray = presentationsArray.filter((p) => p.isShared);
        }
        if (this._searchText) {
            return presentationsArray;
        } else {
            return PresentationStore.sortByUpdateTime(presentationsArray);
        }
    }
    static sortByUpdateTime(presentations: Presentation[]) {
        return presentations.slice().sort((a, b) => {
            return b.rawUpdateTime - a.rawUpdateTime;
        });
    }
    get presentations() {
        return this._applyOrder(this._presentations, this._currentFolder);
    }
    _applyOrder(presentations: Presentation[], currentFolder: Folder | null) {
        return this.applyFilter(
            presentations.filter(
                (presentation) =>
                    (currentFolder == null && presentation.folderId == null) ||
                    (currentFolder?.type === FolderType.Presentations &&
                        presentation.folderId === currentFolder.id)
            )
        );
    }

    get tutorials() {
        return this.applyFilter(
            this._tutorials.filter(
                (tutorial) =>
                    (this._currentFolder == null &&
                        tutorial.folderId == null) ||
                    (this._currentFolder?.type === FolderType.Tutorials &&
                        tutorial.folderId === this._currentFolder.id)
            )
        );
    }

    get templates() {
        return this.applyFilter(
            this._templates.filter(
                (template) =>
                    (this._currentFolder == null &&
                        template.folderId == null) ||
                    (this._currentFolder?.type === FolderType.Templates &&
                        template.folderId === this._currentFolder.id)
            )
        );
    }

    get presentationFolders() {
        if (this._searchText) return [];
        return this._presentationFolders;
    }

    get templateFolders() {
        if (this._searchText) return [];

        return this._templateFolders;
    }

    get tutorialFolders() {
        return this._tutorialFolders;
    }

    get apps() {
        return this.applyFilter(this._apps, this.appSelectedKeywords);
    }

    get createNewTemplates() {
        return this._templates.filter((template) =>
            template.isCreateNewTemplate()
        );
    }

    get trash() {
        return this.applyFilter(this._trash);
    }

    get currentFolder() {
        return this._currentFolder;
    }
}

export default PresentationStore;
