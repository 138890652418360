export async function toBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
}

export default function retrieveImageFromClipboardAsBase64(
    pasteEvent: ClipboardEvent,
    callback: (
        base64: string | undefined,
        naturalWidth: number | undefined,
        naturalHeight: number | undefined
    ) => void,
    imageFormat?: string
): void {
    // Image width and height cannot exceed 3000 px
    const maxSideSize = 1000;

    if (pasteEvent.clipboardData == null) {
        if (typeof callback === "function") {
            callback(undefined, undefined, undefined);
        }
        return;
    }
    var items: DataTransferItemList = pasteEvent.clipboardData.items;

    if (items == null) {
        if (typeof callback == "function") {
            callback(undefined, undefined, undefined);
        }
        return;
    }

    for (let i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") === -1) continue;
        // Retrieve image on clipboard as blob
        let blob = items[i].getAsFile();

        // Create an abstract canvas and get context
        let mycanvas = document.createElement("canvas");
        let ctx = mycanvas.getContext("2d");
        if (ctx == null) {
            console.error("retrieveImageFromClipboardAsBase64: ctx is null");
            continue;
        }

        // Create an image
        let img = new Image();

        // Once the image loads, render the img on the canvas
        img.onload = function () {
            // Update dimensions of the canvas with the dimensions of the image
            let width, height;
            if (img.width > maxSideSize || img.height > maxSideSize) {
                const ratio = Math.max(
                    img.width / maxSideSize,
                    img.height / maxSideSize
                );
                width = Math.ceil(img.width / ratio);
                height = Math.ceil(img.height / ratio);
            } else {
                width = img.width;
                height = img.height;
            }

            mycanvas.width = width;
            mycanvas.height = height;

            // Draw the image
            ctx!.drawImage(img, 0, 0, width, height);

            // Execute callback with the base64 URI of the image
            if (typeof callback === "function") {
                callback(
                    mycanvas.toDataURL(imageFormat || "image/png"),
                    img.naturalWidth,
                    img.naturalHeight
                );
                return;
            }
        };

        // Crossbrowser support for URL
        var URLObj = window.URL || window.webkitURL;

        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        img.src = URLObj.createObjectURL(blob);
        return;
    }
    callback(undefined, undefined, undefined);
}

export function retrieveImageFromClipboardAsBlob(
    pasteEvent: ClipboardEvent,
    callback: (image: Blob | undefined) => void
) {
    if (pasteEvent.clipboardData == null) {
        if (typeof callback === "function") {
            callback(undefined);
        }
        return;
    }

    var items = pasteEvent.clipboardData.items;

    if (items == null) {
        if (typeof callback == "function") {
            callback(undefined);
        }
        return;
    }

    for (let i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") === -1) continue;
        // Retrieve image on clipboard as blob
        let blob: Blob | null = items[i].getAsFile();

        // Execute callback with the base64 URI of the image
        if (typeof callback === "function") {
            callback(blob != null ? blob : undefined);
            return;
        }
    }
}

export async function resizeAndEncodeToBase64(
    imageBlob: Blob,
    callback: (image: string) => void,
    maxSideSize: number = 250,
    imageFormat?: string,
    // If both horizontalAlignment and verticalAlignment are omitted, then
    // the image will have the aspect ratio of the resized image.
    // Otherwise its size will be maxSideSize x maxSideSize with the image
    // aligned as specified.
    horizontalAlignment?: "left" | "center" | "right",
    verticalAlignment?: "top" | "center" | "bottom"
) {
    // Create an abstract canvas and get context
    let mycanvas = document.createElement("canvas");
    let ctx = mycanvas.getContext("2d");
    if (ctx == null) {
        console.error("resizeAndEncodeToBase64: ctx is null");
        return;
    }

    // Create an image
    let img = new Image();

    // Once the image loads, render the img on the canvas
    img.onload = function () {
        // Update dimensions of the canvas with the dimensions of the image
        let width, height;
        if (img.width > maxSideSize || img.height > maxSideSize) {
            const ratio = Math.max(
                img.width / maxSideSize,
                img.height / maxSideSize
            );
            width = Math.ceil(img.width / ratio);
            height = Math.ceil(img.height / ratio);
        } else {
            width = img.width;
            height = img.height;
        }

        if (horizontalAlignment != null || verticalAlignment != null) {
            mycanvas.width = maxSideSize;
            mycanvas.height = maxSideSize;
        } else {
            mycanvas.width = width;
            mycanvas.height = height;
        }

        let x: number = 0;
        let y: number = 0;
        if (horizontalAlignment === "center") {
            x = Math.floor(maxSideSize / 2 - width / 2);
        } else if (horizontalAlignment === "right") {
            x = maxSideSize - width;
        }
        if (verticalAlignment === "center") {
            y = Math.floor(maxSideSize / 2 - height / 2);
        } else if (verticalAlignment === "bottom") {
            y = maxSideSize - height;
        }

        // Draw the image
        ctx!.drawImage(img, x, y, width, height);

        // Execute callback with the base64 URI of the image
        if (typeof callback === "function") {
            callback(mycanvas.toDataURL(imageFormat || "image/png"));
            return;
        }
    };

    // Crossbrowser support for URL
    var URLObj = window.URL || window.webkitURL;

    // Creates a DOMString containing a URL representing the object given in the parameter
    // namely the original Blob
    img.src = URLObj.createObjectURL(imageBlob);
}

export function checkIfClipboardItemIsImage(item: any): { hasImage: boolean, imageType: string } {
    let hasImage = false;
    let imageType = undefined;

    for (let type of item.types) {
        if (type.indexOf("image") !== -1) {
            hasImage = true;
            imageType = type;
            break;
        }
    }

    return { hasImage, imageType };
}

export async function retrieveImageFromClipboardAsBase64V2(
    items: ClipboardItems,
    callback: (
        base64: string | undefined,
        naturalWidth: number | undefined,
        naturalHeight: number | undefined
    ) => void,
    imageFormat: string,
    fullResolution?: boolean
): Promise<void> {
    // Image width and height cannot exceed 3000 px
    const maxSideSize = 1000;
    if (items == null) {
        if (typeof callback == "function") {
            callback(undefined, undefined, undefined);
        }
        return;
    }

    for (let i = 0; i < items.length; i++) {
        // Skip content if not image

        // Retrieve image on clipboard as blob
        try {
            let item = items[i];
            const { hasImage, imageType } = checkIfClipboardItemIsImage(item);
            if (!hasImage) continue;

            const blob = await item.getType(imageType);

            // Create an abstract canvas and get context
            let mycanvas = document.createElement("canvas");
            let ctx = mycanvas.getContext("2d");
            if (ctx == null) {
                console.error(
                    "retrieveImageFromClipboardAsBase64V2: ctx is null"
                );
                continue;
            }
            // Create an image
            let img = new Image();

            // Once the image loads, render the img on the canvas
            img.onload = function () {
                // Update dimensions of the canvas with the dimensions of the image
                let width, height;
                if ((img.width > maxSideSize || img.height > maxSideSize) && !fullResolution) {
                    const ratio = Math.max(
                        img.width / maxSideSize,
                        img.height / maxSideSize
                    );
                    width = Math.ceil(img.width / ratio);
                    height = Math.ceil(img.height / ratio);
                } else {
                    width = img.width;
                    height = img.height;
                }

                mycanvas.width = width;
                mycanvas.height = height;

                // Draw the image
                ctx!.drawImage(img, 0, 0, width, height);

                // Execute callback with the base64 URI of the image
                if (typeof callback === "function") {
                    callback(
                        mycanvas.toDataURL(imageFormat || "image/png"),
                        img.naturalWidth,
                        img.naturalHeight
                    );
                    return;
                }
            };

            // Crossbrowser support for URL
            var URLObj = window.URL || window.webkitURL;

            // Creates a DOMString containing a URL representing the object given in the parameter
            // namely the original Blob
            img.src = URLObj.createObjectURL(blob);
            return;
        } catch (exception) {
            console.log(exception);
            callback(undefined, undefined, undefined);
            return;
        }
    }
    callback(undefined, undefined, undefined);
}

export async function retrieveImageFromFileAsBase64(
    file: File,
    imageFormat: string
): Promise<{
    backgroundUrl: string;
    naturalWidth: number;
    naturalHeight: number;
}> {
    // Image width and height cannot exceed 3000 px

    let url: string = await toBase64(file);

    return new Promise((resolve, reject) => {
        const maxSideSize = 1000;
        let mycanvas = document.createElement("canvas");
        let ctx = mycanvas.getContext("2d");
        if (ctx == null) {
            reject("retrieveImageFromClipboardAsBase64V2: ctx is null");
        }
        let img = new Image();
        img.onload = function () {
            let width, height;
            if (img.width > maxSideSize || img.height > maxSideSize) {
                const ratio = Math.max(
                    img.width / maxSideSize,
                    img.height / maxSideSize
                );
                width = Math.ceil(img.width / ratio);
                height = Math.ceil(img.height / ratio);
            } else {
                width = img.width;
                height = img.height;
            }

            mycanvas.width = width;
            mycanvas.height = height;

            // Draw the image
            ctx!.drawImage(img, 0, 0, width, height);
            return resolve({
                backgroundUrl: mycanvas.toDataURL(imageFormat || "image/png"),
                naturalWidth: img.naturalWidth,
                naturalHeight: img.naturalHeight,
            });
        };
        img.onerror = (error) => reject(error);
        img.src = url;
    });
}
