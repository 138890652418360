import React from "react";
import { defaultGanttChartFilter, GanttChartFinding } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import styles from "./GanttChartSection.module.css";
import { Button } from "react-bootstrap";
import EditInput from "../EditInput";
import cx from "classnames";
import StringUtils from "common/utilities/StringUtils";
import Select, { createFilter } from "react-select";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import { VariableOption } from "common/Variables";
import { observer } from "mobx-react";
import variables from "common/Variables";
import { strptime, strftime } from "common/utilities/TimeFormatUtils";
import { displayModeToDiffType } from "common/graphics/TimeChartUtils";
import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import moment from "moment";
import ribbonStyles from "../../ChartsRibbon.module.css";
import { Accordion } from "react-bootstrap";
import { GanttChartDateIntervalType } from "common/Canvas";

enum GanttChartOptions {
    None = 0,
    DateFilter = 1,
}

interface Props {
    columnDragActive: boolean;
    currentModuleId?: number;
    finding: GanttChartFinding;
    onChange: (finding: GanttChartFinding, updateData?: boolean) => void;
}

export default observer(function GanttChartSection(props: Props) {
    let [optionSelected, setOptionSelected] = React.useState(
        GanttChartOptions.None
    );
    let selectStyles = getCustomSelectStyleForDataSection(14, false);

    const { filter } = props.finding.config;
    let data = props.finding.content.data;
    let currentEditVariableIndex: number | undefined = undefined;
    if (props.finding.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = data.findIndex(
            (item) => item.variableIndex == null
        );
    }
    let xAxisName = props.finding.content.data[0].name;
    let yAxisName = props.finding.content.data[1].name;
    let zAxisName = props.finding.content.data[2].name;

    let datasetIsConnected = props.finding.config.dataScope != null;
    let maxRowCount = 3;
    let restCount = data[0].value.length - maxRowCount;
    let variableOptions: VariableOption[] = [];
    let ganttChartDateIntervalTypes = [
        {
            label: "Day",
            value: GanttChartDateIntervalType.Day,
        },
        {
            label: "Week",
            value: GanttChartDateIntervalType.Week,
        },
        {
            label: "Month",
            value: GanttChartDateIntervalType.Month,
        },
        {
            label: "Quarter",
            value: GanttChartDateIntervalType.Quarter,
        },
        {
            label: "Year",
            value: GanttChartDateIntervalType.Year,
        },
    ];

    if (datasetIsConnected) {
        variableOptions = variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions.filter(
            (item) => item.panel !== "time" && item.type !== "datetime"
        );
    }

    let displayModeOptions = Object.keys(displayModeToDiffType)
        .concat("Date")
        .map((item) => ({
            label: item,
            value: item,
        }));

    return (
        <div>
            <div className={styles.dataContainer}>
                <div
                    className={cx(commonStyles.header, {
                        [commonStyles.highlightValueVerticalTop]:
                            currentEditVariableIndex === 0,
                    })}
                >
                    {xAxisName}
                </div>
                <div
                    className={cx(commonStyles.header, {
                        [commonStyles.highlightValueVerticalTop]:
                            currentEditVariableIndex === 1,
                    })}
                >
                    {yAxisName}
                </div>
                <div
                    className={cx(commonStyles.header, {
                        [commonStyles.highlightValueVerticalTop]:
                            currentEditVariableIndex === 2,
                    })}
                >
                    {zAxisName}
                </div>
                {(datasetIsConnected
                    ? data[0].value.slice(0, maxRowCount)
                    : data[0].value
                ).map((_, index, array) => (
                    <React.Fragment key={index}>
                        {data.map((_item, dataIndex) => {
                            const inputValue =
                                data[dataIndex].value[index] ?? "";
                            return (
                                <EditInput
                                    key={dataIndex}
                                    showDeleteButton={
                                        index > 0 &&
                                        dataIndex === 0 &&
                                        props.finding.config.dataScope == null
                                    }
                                    onDelete={() => {
                                        let finding: GanttChartFinding = {
                                            ...props.finding!,
                                        };
                                        finding.content.data.forEach((item) =>
                                            item.value.splice(index, 1)
                                        );
                                        props.onChange(finding);
                                    }}
                                    disabled={
                                        props.finding!.config.dataScope != null
                                    }
                                    optionalClassName={cx({
                                        [commonStyles.highlightValueVertical]:
                                            currentEditVariableIndex ===
                                            dataIndex,
                                        [commonStyles.highlightValueVerticalBottom]:
                                            currentEditVariableIndex ===
                                                dataIndex &&
                                            index === array.length - 1 &&
                                            !(
                                                datasetIsConnected &&
                                                restCount > 0
                                            ),
                                    })}
                                    value={
                                        dataIndex === 0
                                            ? inputValue
                                            : typeof data[dataIndex].value[
                                                  index
                                              ] === "number"
                                            ? strftime(
                                                  "%Y %b %d",
                                                  new Date(
                                                      (data[dataIndex].value[
                                                          index
                                                      ] as number) * 1000
                                                  )
                                              )?.toUpperCase()
                                            : inputValue
                                    }
                                    onChange={(value) => {
                                        let finding: GanttChartFinding = {
                                            ...props.finding!,
                                        };
                                        if (dataIndex > 0) {
                                            let time = strptime(
                                                "%Y %b %d",
                                                (value as string).toUpperCase()
                                            );
                                            if (time != null)
                                                value = time.getTime() / 1000;
                                            finding.content.data[
                                                dataIndex
                                            ].value[index] = value;
                                        } else {
                                            finding.content.data[
                                                dataIndex
                                            ].value[index] = value;
                                        }
                                        props.onChange(finding);
                                    }}
                                ></EditInput>
                            );
                        })}
                    </React.Fragment>
                ))}
                {datasetIsConnected && restCount > 0 && (
                    <React.Fragment>
                        <EditInput
                            optionalClassName={cx({
                                [commonStyles.highlightValueVerticalBottom]:
                                    currentEditVariableIndex === 0,
                            })}
                            showDeleteButton={false}
                            disabled={true}
                            value={`+${restCount} values`}
                        ></EditInput>
                        <EditInput
                            optionalClassName={cx({
                                [commonStyles.highlightValueVerticalBottom]:
                                    currentEditVariableIndex === 1,
                            })}
                            showDeleteButton={false}
                            disabled={true}
                            value={"..."}
                        ></EditInput>
                        <EditInput
                            optionalClassName={cx({
                                [commonStyles.highlightValueVerticalBottom]:
                                    currentEditVariableIndex === 2,
                            })}
                            showDeleteButton={false}
                            disabled={true}
                            value={"..."}
                        ></EditInput>
                    </React.Fragment>
                )}
            </div>
            {!datasetIsConnected && (
                <Button
                    style={{ marginTop: 10 }}
                    className={cx(
                        "btn btn-sm btn-primary my-primary",
                        commonStyles.addItemButton
                    )}
                    onClick={() => {
                        let finding: GanttChartFinding = {
                            ...props.finding!,
                        };
                        finding.content.data.forEach((item, index) => {
                            let currentTime = moment().unix();
                            if (index === 0) {
                                item.value.push(
                                    `Variable ${item.value.length + 1}`
                                );
                            } else {
                                item.value.push(currentTime);
                            }
                        });
                        props.onChange(finding);
                    }}
                >
                    {"\uFF0B Add Item"}
                </Button>
            )}
            {datasetIsConnected && (
                <>
                    <div className={commonStyles.thinOptionName}>Color by</div>

                    <div
                        className="my-row"
                        style={{ alignItems: "center", marginTop: "8px" }}
                    >
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            isClearable
                            placeholder={"Select  variable"}
                            styles={{
                                ...selectStyles,
                                container: (base) => ({
                                    ...base,
                                    width: "100%",
                                    height: "38px",
                                }),
                            }}
                            options={variableOptions}
                            onChange={(newValue) => {
                                let finding: GanttChartFinding = {
                                    ...props.finding!,
                                };
                                finding.config.colorVariable = newValue ?? null;
                                finding.content.colorBy = null;
                                props.onChange(finding, true);
                            }}
                            value={props.finding.config.colorVariable}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                </>
            )}

            <div className={commonStyles.thinOptionName}>Time Axis</div>

            <div
                className="my-row"
                style={{ alignItems: "center", marginTop: "8px" }}
            >
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Select time axis"}
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                            height: "38px",
                        }),
                    }}
                    options={displayModeOptions}
                    onChange={(newValue) => {
                        let finding: GanttChartFinding = {
                            ...props.finding!,
                        };
                        finding.config.displayMode = (newValue as any).value;
                        props.onChange(finding, true);
                    }}
                    value={
                        displayModeOptions.find(
                            (option) =>
                                option.value ===
                                props.finding.config.displayMode
                        ) ?? displayModeOptions[displayModeOptions.length - 1]
                    }
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            {datasetIsConnected && (
                <>
                    <div className={commonStyles.thinOptionName}>
                        Date line pos
                    </div>

                    <div
                        className="my-row"
                        style={{ alignItems: "center", marginTop: "8px" }}
                    >
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            isClearable
                            placeholder={"Select  variable"}
                            styles={{
                                ...selectStyles,
                                container: (base) => ({
                                    ...base,
                                    width: "100%",
                                    height: "38px",
                                }),
                            }}
                            options={ganttChartDateIntervalTypes}
                            onChange={(newValue) => {
                                const newFinding = {
                                    ...props.finding,
                                    config: {
                                        ...props.finding.config,
                                        filter: {
                                            ...(props.finding.config?.filter ??
                                                {}),
                                            dateIntervalType: newValue,
                                        },
                                    },
                                };

                                props.onChange(newFinding, true);
                            }}
                            value={
                                props.finding.config?.filter
                                    ?.dateIntervalType ??
                                defaultGanttChartFilter.dateIntervalType
                            }
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>

                    <Accordion
                        activeKey={String(optionSelected)}
                        onSelect={(event: any) => {
                            setOptionSelected(Number(event));
                        }}
                    >
                        <Accordion.Toggle
                            eventKey={String(GanttChartOptions.DateFilter)}
                            className={ribbonStyles.editMenuAdvancedOption}
                        >
                            Filter date
                            <ChevronIcon
                                className={cx(
                                    ribbonStyles.editMenuAdvancedOptionIcon,
                                    {
                                        [ribbonStyles.chevronOpen]:
                                            optionSelected ===
                                            GanttChartOptions.DateFilter,
                                    }
                                )}
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse
                            eventKey={String(GanttChartOptions.DateFilter)}
                        >
                            <>
                                <div className={styles.optionContainer}>
                                    <span className={styles.optionName}>
                                        From
                                    </span>
                                    <input
                                        type="date"
                                        value={filter?.date?.start ?? ""}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (!value) return;

                                            const newFinding = {
                                                ...props.finding,
                                                config: {
                                                    ...props.finding.config,
                                                    filter: {
                                                        ...(props.finding.config
                                                            ?.filter ?? {}),
                                                        date: {
                                                            ...(props.finding
                                                                .config.filter
                                                                ?.date ?? {}),
                                                            start: value,
                                                        },
                                                    },
                                                },
                                            };

                                            props.onChange(newFinding, true);
                                        }}
                                    />
                                    <button
                                        type="button"
                                        className={styles.clearDateFilterButton}
                                        onClick={() => {
                                            if (
                                                !props.finding.config?.filter
                                                    ?.date?.start
                                            )
                                                return;

                                            const newFinding = {
                                                ...props.finding,
                                            };
                                            newFinding.config.filter.date.start =
                                                "";
                                            props.onChange(newFinding, true);
                                        }}
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>

                                <div className={styles.optionContainer}>
                                    <span className={styles.optionName}>
                                        To
                                    </span>
                                    <input
                                        type="date"
                                        value={filter?.date?.end ?? ""}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (!value) return;

                                            const newFinding = {
                                                ...props.finding,
                                                config: {
                                                    ...props.finding.config,
                                                    filter: {
                                                        ...(props.finding.config
                                                            ?.filter ?? {}),
                                                        date: {
                                                            ...(props.finding
                                                                .config.filter
                                                                ?.date ?? {}),
                                                            end: value,
                                                        },
                                                    },
                                                },
                                            };

                                            props.onChange(newFinding, true);
                                        }}
                                    />
                                    <button
                                        type="button"
                                        className={styles.clearDateFilterButton}
                                        onClick={() => {
                                            if (
                                                !props.finding.config?.filter
                                                    ?.date?.end
                                            )
                                                return;

                                            const newFinding = {
                                                ...props.finding,
                                            };
                                            newFinding.config.filter.date.end =
                                                "";
                                            props.onChange(newFinding, true);
                                        }}
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>
                            </>
                        </Accordion.Collapse>
                    </Accordion>

                    <div className={styles.optionContainer}>
                        <span className={styles.optionName}>Limit</span>
                        <EditInput
                            optionalStyles={{ marginLeft: 10 }}
                            optionalClassName={styles.input}
                            showDeleteButton={false}
                            disabled={false}
                            value={props.finding.config.count}
                            onChange={(value) => {
                                let finding: GanttChartFinding = {
                                    ...props.finding!,
                                };
                                if (StringUtils.isNumber(value)) {
                                    let count = Number(value);
                                    if (count !== finding.config.count) {
                                        finding.config.count = count;
                                        props.onChange(finding, true);
                                    }
                                }
                            }}
                        ></EditInput>
                    </div>
                </>
            )}
        </div>
    );
});
