import React, { useEffect } from 'react'
import styles from './AnalyzeSection.module.css'
// import { observable, toJS } from 'mobx';
// import { DataSectionProps } from '../index';
import Select from 'react-select';
import { getCustomSelectStyleForDataSection, leftAlignAndScrollBarCSS } from 'common/SelectStyles';
import Finding, { isMap, isLeverOutcomeChart, isHoldOutPredictionChart, isLinePlot, isBarChart, isLeversBenchmarking, isPieChart } from '../../../../../../../common/Finding';
import commonStyles from '../DataSection.module.css'
// import { ReactComponent as EyeOpenIcon } from "../../../../../../../icons/eye-line.svg";
// import { ReactComponent as EyeCloseIcon } from "../../../../../../../icons/eye-close-line.svg";
// import { ReactComponent as UnlinkIcon } from "icons/canvas/exploration/unlink.svg";
import { createFilter } from 'react-select';
// import MagicWand from '../MagicWand';
// import { DynamicOptionType } from "common/DynamicOptions";
import { ConditionsSelector } from 'modules/data_hub_page/CleanSection/conditionSelector';
// import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { getTargetValue } from 'common/Canvas';
import { NodeLinkOption } from 'common/Conditions';
import SharedBoxesStore from 'modules/canvas_page/SharedBoxesStore';
import Variables from 'common/Variables';
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import LeverOutcomeSection from './LeverOutcomeSection';
import { Accordion } from 'react-bootstrap';
import cx from 'classnames';
// import { Observer } from 'mobx-react';
import DataScopes from "common/DataScopes";
import Tables from 'common/Tables';
import DataScopesForModules from "common/DataScopesForModules";
import LinePlotSideBySideSection from "../LinePlotSection/SideBySideSection/index"
import { preview } from 'modules/data_hub_page/MergeSection';
// import axios from 'axios';
// import { PopupStatus } from 'common/StatusPopup';
import SideBySideSection from '../SideBySideSection';
import { SideBySideSectionProps } from '../SideBySideSection';
import SelectedOption from 'modules/data_hub_page/SelectedOption';



function getSideBySideSection(
    finding: Finding
): React.ComponentType<SideBySideSectionProps> {
    if (isBarChart(finding) || isPieChart(finding) || isLinePlot(finding)) {
        return LinePlotSideBySideSection;
    } else {
        return SideBySideSection;
    }
}

enum DataMenuOptions {
    None = 0,
    SideBySide = 1,
}

const AnalyzeSectionComponent = (props: any) => {
    let [dataMenuOptionSelected, setDataMenuOptionSelected] = React.useState(
        DataMenuOptions.None
    );

    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    const SideBySideSection = getSideBySideSection(props.finding);

    useEffect(() => {
        return () => {
            DataScopes.previewState = { headers: null, body: null }
        }
    }, [])



    if (props.finding == null) return null;
    const dataScopes =
        props.currentModuleId == null
            ? DataScopes
            : DataScopesForModules(props.currentModuleId);
    const tableOptions =
        props.finding?.config.dataScope != null
            ? Tables(
                props.finding.config.dataScope.value,
                props.currentModuleId
            ).rawAndAggregateTableOptions
            : [];



    return (
        <div >
            {(props.connectDataset || props.finding.config.dataScope) &&
                !isMap(props.finding) && (
                    <React.Fragment>
                        <div style={{ display: 'flex', }}>
                            <div>
                                <h3 className={styles.textItem} style={{ marginTop: 0 }}>Dataset</h3>
                                <div
                                    className="my-row"
                                    style={{ alignItems: "center", marginTop: "5px", width: '305px', height: '40px' }}
                                >
                                    <Select
                                        menuPlacement="auto"
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={"Select dataset"}
                                        styles={{
                                            ...selectStyles,
                                            ...leftAlignAndScrollBarCSS,
                                            container: (base) => ({
                                                ...base,
                                                width: "100%",
                                                height: "38px",
                                            }),
                                        }}
                                        options={dataScopes.dataScopesOptions}
                                        onChange={async (e) => {
                                            DataScopes.previewState = { headers: null, body: null }
                                            if (e?.value != null) {
                                                await DataScopes.DatasetPreview(e?.value);
                                            }
                                            props.onChangeDataScope(e);

                                            SelectedOption.UpdateaskSwitchTab(false);
                                            SelectedOption.UpdateSwitchTab(false);
                                        }}
                                        value={props.finding.config.dataScope}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            {/* table selection */}
                            <div style={{ marginLeft: '30px', height: '40px', width: '305px' }}>

                                {(props.finding?.config.dataScope != null && tableOptions.length > 1) && (
                                    <>
                                        <h3 className={styles.textItem} style={{ marginTop: 0 }}>  Table</h3>
                                        <div
                                            className="my-row"
                                            style={{
                                                alignItems: "center",
                                                marginTop: "5px",
                                            }}
                                        >
                                            <Select
                                                menuPlacement="auto"
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={"Select table"}
                                                styles={{
                                                    ...selectStyles,
                                                    ...leftAlignAndScrollBarCSS,
                                                    container: (base) => ({
                                                        ...base,
                                                        width: "100%",
                                                        height: "38px",
                                                    }),
                                                }}
                                                options={tableOptions}
                                                onChange={props.onChangeSelectedTable}
                                                value={
                                                    props.finding.config.selectedTable
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>


                        {
                            DataScopes.preview?.headers != null &&
                            <div className={"customPreview"}>
                                {preview(DataScopes.preview?.headers, DataScopes.preview?.body, 1, true)}
                            </div>
                        }

                        {/* {
                            previewHeader1 != null &&
                            <div className={"customPreview"}>
                                {preview(previewHeader1, previewBody1, 1, true)}
                            </div>
                        } */}
                    </React.Fragment>
                )}
            {isLeverOutcomeChart(props.finding) && (
                <LeverOutcomeSection
                    finding={props.finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            <div >
                <div>
                    {
                        props.finding?.config.dataScope != null &&
                        <div>

                            {/* <div className={styles.filterContainer}>
                                <MagicWand
                                    onClearEditing={props.onClearEditing}
                                    title={"Filter"}
                                    dashboardId={props.dashboardId}
                                    allowMultiple={false}
                                    canvasTreeStore={props.canvasTreeStore}
                                    type={DynamicOptionType.Filter}
                                    optionName={"conditions"}
                                ></MagicWand>
                                <span
                                    style={{ marginTop: 0 }}
                                    className={styles.thinOptionName}
                                >
                                    Filter
                                </span>
                            </div> */}
                            {/* <ConditionsSelector
                                style={{ marginTop: 5 }}
                                key={props.finding.config.dataScope?.value}
                                currentModuleId={props.currentModuleId}
                                small
                                dataScopeId={
                                    props.finding.config.dataScope?.value
                                }
                                value={
                                    props.finding.config.conditions &&
                                        props.finding.config.conditions.length >
                                        0
                                        ? props.finding.config.conditions
                                        : ConditionsSelector.defaultValue
                                }
                                allowLinks
                                nodeLinkOptions={Array.from(
                                    props.canvasTreeStore.canvasTreeState.values()
                                )
                                    .map(
                                        (item: any) =>
                                        ({
                                            label: item?.outerId,
                                            value: item?.id,
                                            isCloneInput: false,
                                            target: getTargetValue(
                                                item
                                            ),
                                        } as NodeLinkOption)
                                    )
                                    .concat(
                                        SharedBoxesStore.sharedBoxes(
                                            props.canvasTreeStore.canvasId!
                                        ).map((item) => ({
                                            label: item.label,
                                            value: item.value,
                                            isCloneInput: true,
                                            target: getTargetValue(
                                                item.item.box
                                            ),
                                        }))
                                    )}
                                title={""}
                                onChange={props.onChangeConditions}
                                allVariables={
                                    Variables(
                                        props.finding.config.dataScope?.value,
                                        props.currentModuleId
                                    ).dataVariables
                                }
                                {...ConditionSelectorStyles}
                            /> */}
                        </div>
                    }
                </div>


                {props.finding.config.dataScope &&
                    (isLeverOutcomeChart(props.finding) ||
                        isHoldOutPredictionChart(props.finding) ||
                        isLinePlot(props.finding) ||
                        isBarChart(props.finding) ||
                        isPieChart(props.finding) ||
                        isLeversBenchmarking(props.finding)) && (

                        <div className={styles.advancedOptionsWrapper}>
                            <Accordion
                                activeKey={String(dataMenuOptionSelected)}
                                onSelect={(event: any) => {
                                    setDataMenuOptionSelected(Number(event));
                                }}
                            >
                                <Accordion.Toggle
                                    eventKey={String(DataMenuOptions.SideBySide)}
                                    className={cx(commonStyles.editMenuAdvancedOption, styles.advancedOptions)}
                                >
                                    <div>
                                        <h3 className={styles.textItem} style={{ margin: 0 }}> Advanced options</h3>
                                        <div className={styles.advancedOptionsIcon}
                                            style={{ transform: dataMenuOptionSelected !== DataMenuOptions.SideBySide ? "rotate(90deg)" : 'rotate(0deg)' }}>
                                            <img src='dist/img/data-exploration/dropdown.svg' alt="" />
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse
                                    eventKey={String(DataMenuOptions.SideBySide)}
                                    className={styles.collapsible}
                                >
                                    <div>
                                        <div className={styles.advancedOptionsCollapsed} >
                                            <div style={{ width: '40%' }}>
                                                <SideBySideSection
                                                    maxValuesCount={
                                                        isLeversBenchmarking(props.finding) ? 1 : 2
                                                    }
                                                    finding={props.finding}
                                                    onChange={props.onChange}
                                                    currentModuleId={props.currentModuleId}
                                                />
                                            </div>

                                            <div style={{ width: '60%' }}>
                                                <h3
                                                    style={{ marginTop: 0, marginBottom: 9, paddingLeft: 10 }}
                                                    className={styles.textItem}
                                                >
                                                    Filter by
                                                </h3>
                                                <ConditionsSelector
                                                    key={props.finding.config.dataScope?.value}
                                                    currentModuleId={props.currentModuleId}
                                                    small
                                                    dataScopeId={
                                                        props.finding.config.dataScope?.value
                                                    }
                                                    value={
                                                        props.finding.config.conditions &&
                                                            props.finding.config.conditions.length >
                                                            0
                                                            ? props.finding.config.conditions
                                                            : ConditionsSelector.defaultValue
                                                    }
                                                    allowLinks
                                                    nodeLinkOptions={Array.from(
                                                        props.canvasTreeStore.canvasTreeState.values()
                                                    )
                                                        .map(
                                                            (item: any) =>
                                                            ({
                                                                label: item?.outerId,
                                                                value: item?.id,
                                                                isCloneInput: false,
                                                                target: getTargetValue(
                                                                    item
                                                                ),
                                                            } as NodeLinkOption)
                                                        )
                                                        .concat(
                                                            SharedBoxesStore.sharedBoxes(
                                                                props.canvasTreeStore.canvasId!
                                                            ).map((item) => ({
                                                                label: item.label,
                                                                value: item.value,
                                                                isCloneInput: true,
                                                                target: getTargetValue(
                                                                    item.item.box
                                                                ),
                                                            }))
                                                        )}
                                                    title={""}
                                                    onChange={props.onChangeConditions}
                                                    allVariables={
                                                        Variables(
                                                            props.finding.config.dataScope?.value,
                                                            props.currentModuleId
                                                        ).dataVariables
                                                    }
                                                    {...ConditionSelectorStyles}
                                                    asWizard={false}
                                                    switch={false}
                                                />
                                            </div>

                                        </div>

                                        <div className={styles.buttonsContainer}>
                                            <button
                                                type="button"
                                                className="acceptBtn"
                                                style={{paddingBlock:8}}
                                                onClick={() => {
                                                    props.onChange({ ...props.finding }, true);
                                                }}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>

                                </Accordion.Collapse>
                                {/* </div> */}

                            </Accordion>
                        </div>
                    )}
            </div>
        </div >
    )
}

export default AnalyzeSectionComponent
