import React, { Component, CSSProperties } from "react";
import ExportPopup from "common/canvas/ExportPopup";
import { toPdf } from "./utilities/PdfGenerator";
import Canvases from "common/Canvases";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import CurrentModulesStore from "common/CurrentModulesStore";

interface Props {
    rootRef: React.RefObject<HTMLElement>;
    onClose: () => void;
    overlayStyle?: CSSProperties;
    currentModuleId: number;
}

class ExportPdfPopup extends Component<Props> {
    mounted: boolean = false;
    constructor(props: Props) {
        super(props);
        this.exportPdf = this.exportPdf.bind(this);
    }
    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }
    private downloadExportedFile(blob: Blob, title: string): void {
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = `${title}`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    private async exportPdf(title: string) {
        let currentModule = CurrentModulesStore.getModule(
            this.props.currentModuleId
        );
        if (currentModule == null) throw new Error("Presentation not found");
        let slides = PagesStore(PageType.Canvases)
            .pages.map((page) =>
                Canvases(page.id).canvases.filter(
                    (canvas) => !canvas.hide_in_slideshow && !canvas.layer_for_canvas_id // HERE
                )
            )
            .flat();
        let data = await toPdf(
            this.props.rootRef,
            slides,
            currentModule,
            this.props.currentModuleId
        );
        if (this.mounted) this.downloadExportedFile(data, title);
    }

    public render(): JSX.Element {
        let currentModule = CurrentModulesStore.getModule(
            this.props.currentModuleId
        );
        return (
            <ExportPopup
                defaultTitle={currentModule?.title}
                overlayStyle={this.props.overlayStyle}
                onClose={this.props.onClose}
                exportFunction={this.exportPdf}
            />
        );
    }
}
export default ExportPdfPopup;
