import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

import axios from "common/ServerConnection";
import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";
import { userTypesOrdered } from "../../common/UserType";
import { mainStyle } from "common/MainStyle";

// Key description
interface Description {
    display_name?: string;
    description?: string;
}

interface State {
    status: UpdateStatus;
    errorMessage: string;
    descriptions: { [key: string]: Description };
    featureLimits: { [key: string]: { [key: number]: number | null } };
    expanded: boolean;
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            descriptions: {},
            featureLimits: {},
            expanded: false,
        };
        this.performance = null;

        this.getFeatureLimits = this.getFeatureLimits.bind(this);
        this.setFeatureLimits = this.setFeatureLimits.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    private getFeatureLimits(): void {
        this.performance = new Date();
        axios
            .post<{
                success: boolean;
                error_msg: string;
                feature_limits: {
                    [key: string]: { [key: number]: number | null };
                };
                descriptions: { [key: string]: Description };
            }>("/api/get_feature_limits", {})
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        descriptions: response.data.descriptions,
                        featureLimits: response.data.feature_limits,
                        status: UpdateStatus.NotUploaded,
                        errorMessage: "",
                    });
                } else {
                    this.setState({
                        status: UpdateStatus.Error,
                        errorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    status: UpdateStatus.Error,
                    errorMessage: `${error.response.status} ${error.response.statusText}`,
                });
            });
    }

    private setFeatureLimits(): void {
        this.performance = new Date();
        this.setState({
            status: UpdateStatus.Loading,
            errorMessage: "",
        });
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/set_feature_limits", {
                feature_limits: this.state.featureLimits,
            })
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        status: UpdateStatus.Success,
                        errorMessage: "",
                    });
                } else {
                    this.setState({
                        status: UpdateStatus.Error,
                        errorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    status: UpdateStatus.Error,
                    errorMessage: `${error.response.status} ${error.response.statusText}`,
                });
            });
    }

    private toggleExpanded(): void {
        this.setState((state) => ({
            expanded: !state.expanded,
        }));
    }

    componentDidMount() {
        let performance = this.performance;
        this.getFeatureLimits();
        this.performance = performance; // We don't need to track performance of the initial request
    }

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    render() {
        return (
            <div style={{ marginTop: 20 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                    }}
                    onClick={this.toggleExpanded}
                >
                    <div
                        className="regular-text"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 25,
                        }}
                    >
                        <i
                            className={`fa fa-2x fa-angle-${
                                this.state.expanded ? "down" : "right"
                            }`}
                        />
                    </div>
                    <span className="big-title-span">Feature Limits</span>
                </div>
                {this.state.expanded && (
                    <div
                        style={{ marginTop: 10, width: 500 }}
                        className="flex-simple-column"
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                className="regular-text"
                                style={{
                                    marginLeft: "19px",
                                    paddingLeft: "15px",
                                    marginRight: "15px",
                                    minWidth: "20em",
                                }}
                            />
                            {userTypesOrdered.map((userType) => (
                                <div
                                    key={`${userType}_header`}
                                    className="regular-text"
                                    style={{
                                        marginRight: "15px",
                                        minWidth: "4em",
                                    }}
                                >
                                    {userType}
                                </div>
                            ))}
                        </div>
                        {Object.entries(this.state.featureLimits).map(
                            ([key, limits], index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "19px",
                                            paddingLeft: "15px",
                                            marginRight: "15px",
                                            minWidth: "20em",
                                        }}
                                    >
                                        <div className="regular-text">
                                            {this.state.descriptions[key]
                                                ?.display_name ?? key}
                                        </div>
                                        {this.state.descriptions[key]
                                            ?.description != null && (
                                            <>
                                                <span
                                                    className="regular-text unselectable"
                                                    data-tip={
                                                        this.state.descriptions[
                                                            key
                                                        ]?.description
                                                    }
                                                    data-for={`tooltip-${key}`}
                                                    data-event="click"
                                                    data-eventOff="scroll mousewheel"
                                                    data-effect="solid"
                                                    data-isCapture={true}
                                                    style={{
                                                        color: mainStyle.getPropertyValue(
                                                            "--secondary-text-color"
                                                        ),
                                                        textDecoration:
                                                            "underline",
                                                        marginLeft: 1,
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    ?
                                                </span>
                                                <ReactTooltip
                                                    id={`tooltip-${key}`}
                                                />
                                            </>
                                        )}
                                    </div>
                                    {userTypesOrdered.map((userType, userIndex) => (
                                        <div
                                            key={`${userType}_${index}`}
                                            title={userType}
                                            className="regular-text"
                                            style={{
                                                marginRight: "15px",
                                                minWidth: "4em",
                                            }}
                                        >
                                            <input
                                                className="like-select"
                                                style={{
                                                    width: "4em",
                                                }}
                                                value={limits[userIndex] ?? ""}
                                                placeholder="NONE"
                                                onChange={(e) => {
                                                    if (
                                                        !/^[0-9]*$/.test(
                                                            e.target.value
                                                        )
                                                    ) {
                                                        e.preventDefault();
                                                        return;
                                                    }
                                                    const value =
                                                        e.target.value
                                                            .length === 0
                                                            ? null
                                                            : Number(
                                                                  e.target.value
                                                              );
                                                    this.setState((state) => ({
                                                        featureLimits: {
                                                            ...state.featureLimits,
                                                            [key]: {
                                                                ...state
                                                                    .featureLimits[
                                                                    key
                                                                ],
                                                                [userIndex]: value,
                                                            },
                                                        },
                                                    }));
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )
                        )}
                        <Button
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginLeft: "19px",
                                marginBottom: 10,
                                width: 200,
                            }}
                            onClick={this.setFeatureLimits}
                        >
                            Update
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginLeft: "19px",
                                marginBottom: 10,
                                width: 200,
                            }}
                            onClick={this.getFeatureLimits}
                        >
                            Reset
                        </Button>
                        <UpdateStatusAlert
                            value={this.state.status}
                            onChange={(status) => {
                                this.setState({ status: status });
                            }}
                            errorMessage={this.state.errorMessage}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export { MainComponent };
export let requirePermission = "GetSetFeatureLimits";
