import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
    CanvasSpreadSheetGrid,
    CanvasElement,
    isDateFormat,
    isNumberFormat,
} from "common/Canvas";
import CanvasTreeStore from "../CanvasTreeStore";
import "common/styles/rotate.css";
import Portal from "common/Portal";
import OutsideAlerter from "common/OutsideAlerter";
import CanvasIconsContainter from "../CanvasIconsContainer";
import CanvasPreventPropagationButton from "../CanvasPreventPropagationButton";
import FormatColumnPopup from "common/graphics/GraphicsComponents/FormatColumnPopup";

function FormatDropdown(props: {
    rootDataTestId: string;
    grid: CanvasSpreadSheetGrid;
    canvasTreeStore: CanvasTreeStore;
    sortedNodes: CanvasElement[];
    scale: number;
    col: number;
    openedFormatDropdownCol: number;
    allowLocationGeneration?: boolean;
    onGenerateLocation?: () => void;
    onApply: () => void;
    setOpenedFormatDropdownCol: (col: number) => void;
}) {
    let [show, setShow] = useState(false);
    let [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    let width = 12.5 * props.scale;
    let height = 12.5 * props.scale;

    useEffect(() => {
        if (props.col !== props.openedFormatDropdownCol) {
            setShow(false);
        }
    }, [props.openedFormatDropdownCol])

    return (
        <Dropdown
            data-test-id={`${props.rootDataTestId}-formatDropdown`}
            show={show}
            title="Options"
            style={{
                position: "absolute",
                right: 10 * props.scale,
                width: width,
                height: height,
                backgroundColor: "transparent",
            }}
            onToggle={(isOpen: boolean, evt: any) => {
                props.setOpenedFormatDropdownCol(props.col);
                if (isOpen) {
                    let x = evt.clientX;
                    let y = evt.clientY;
                    setPosition({
                        x: x,
                        y: y,
                    });
                }
                setShow(!show);
            }}
        >
            <CanvasPreventPropagationButton>
                <Dropdown.Toggle
                    data-test-id={`${props.rootDataTestId}-formatDropdown-toggle`}
                    id={`header_column_${props.col}_${props.grid.id}`}
                    style={{
                        padding: 0,
                        display: "flex",
                        alignItems: "center",
                        width: width,
                        height: height,
                        backgroundColor: show ? "#EEEEEE" : "transparent",
                        border: "0",
                    }}
                >
                    <CanvasIconsContainter
                        type={"FormatMenu"}
                        options={{
                            width: width,
                            height: height,
                        }}
                    />
                </Dropdown.Toggle>
            </CanvasPreventPropagationButton>

            {show && (
                <Portal rootNode={document.body}>
                    <OutsideAlerter
                        onReject={() => {
                            setShow(false);
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                left: position.x + 5,
                                top: position.y + 5,
                                // + 500 to make it be over SelectionArea
                                zIndex: (props.grid.zIndex ?? 50) + 500,
                            }}
                        >
                            <FormatColumnPopup
                                rootDataTestId={`${props.rootDataTestId}-formatDropdown`}
                                allowLocationGeneration={
                                    props.allowLocationGeneration
                                }
                                onGenerateLocation={
                                    props.allowLocationGeneration
                                        ? () => {
                                              setShow(false);
                                              props.onGenerateLocation?.();
                                          }
                                        : undefined
                                }
                                columnFormat={
                                    props.grid.headers?.[props.col]
                                        ?.columnFormat ?? undefined
                                }
                                onClose={(apply, columnFormat) => {
                                    const { canvasTreeStore } = props;
                                    let column = props.col;
                                    if (apply) {
                                        let oldFormat =
                                            props.grid.headers?.[column]
                                                ?.columnFormat ?? undefined;
                                        canvasTreeStore.updateGridHeaderNodeAction(
                                            props.grid.id,
                                            column,
                                            {
                                                columnFormat: columnFormat,
                                            }
                                        );
                                        if (
                                            (!isDateFormat(oldFormat) &&
                                                isDateFormat(columnFormat)) ||
                                            (isDateFormat(oldFormat) &&
                                                isDateFormat(columnFormat) &&
                                                oldFormat.format !==
                                                    columnFormat.format)
                                        ) {
                                            canvasTreeStore.convertSpreadSheetDateMetricColumnAction(
                                                props.sortedNodes.filter(
                                                    (node) => node.nodePosition[canvasTreeStore.canvasViewMode].x === column
                                                ),
                                                columnFormat,
                                                oldFormat,
                                            );
                                        }
                                        if (
                                            (!isNumberFormat(oldFormat) &&
                                                isNumberFormat(columnFormat)) ||
                                            (isNumberFormat(oldFormat) &&
                                                isNumberFormat(columnFormat) &&
                                                oldFormat.numberType !==
                                                    columnFormat.numberType)
                                        ) {
                                            canvasTreeStore.convertSpreadSheetNumberMetricColumnAction(
                                                props.sortedNodes.filter(
                                                    (node) => node.nodePosition[canvasTreeStore.canvasViewMode].x === column
                                                ),
                                                columnFormat
                                            );
                                        }
                                        // props.onApply();
                                    }
                                    setShow(false);
                                }}
                            />
                        </div>
                    </OutsideAlerter>
                </Portal>
            )}
        </Dropdown>
    );
}

export default FormatDropdown;