import React from "react";
import Select from "react-select";
import { observer } from "mobx-react";
import StringOption from "common/StringOption";
import { DataScopeOption } from "common/DataScopes";
import Variables, { VariableOption } from "common/Variables";
import { MapDataVariableOption, MapDataVariableValue } from "common/Canvas";
import countriesOptions from "common/Countries";
import remoteModuleId from "common/remoteModuleId";

import styles from "./RevampedLocationSelectorView.module.css";

interface LocationOptions {
    country?: VariableOption | StringOption;
    state?: VariableOption;
    city?: VariableOption;
    address?: VariableOption;
    zipcode?: VariableOption;
}

interface CoordinateOptions {
    latitude?: VariableOption;
    longitude?: VariableOption;
}

interface Props extends LocationOptions, CoordinateOptions {
    dataScope: DataScopeOption | null;
    usesCoordinates?: boolean;
    dataVariableOption?: MapDataVariableOption;
    maxHeight: number;
    onChange: (
        locationChanges?: LocationOptions,
        coordinateChanges?: CoordinateOptions
    ) => void;
    onToggle: (newOption: MapDataVariableOption) => void;
    currentModuleId?: number;
}

const locationRows: ReadonlyArray<{
    title: string;
    option: keyof LocationOptions;
    isClearable: boolean;
}> = [
    {
        title: "Country*",
        option: "country",
        isClearable: false,
    },
    {
        title: "State/Province",
        option: "state",
        isClearable: true,
    },
    {
        title: "City",
        option: "city",
        isClearable: true,
    },
    {
        title: "Postal Code",
        option: "zipcode",
        isClearable: true,
    },
];

const coordinateRows: ReadonlyArray<{
    title: string;
    option: keyof CoordinateOptions;
    isClearable: boolean;
}> = [
    {
        title: "Latitude",
        option: "latitude",
        isClearable: false,
    },
    {
        title: "Longitude",
        option: "longitude",
        isClearable: false,
    },
];

const selectPinOptions: Array<MapDataVariableOption> = [
    { label: "GPS Coordinates", value: MapDataVariableValue.LatitudeLongitude },
    { label: "Select country from Geonames", value: MapDataVariableValue.CountryList },
    { label: "Select country from dataset", value: MapDataVariableValue.CountryColumn }
];

const LocationSelectorView = observer((props: Props) => {
    let usesCoordinates = props.usesCoordinates;
    let variableOptions = Variables(
        props.dataScope?.value,
        props.currentModuleId ?? remoteModuleId
    ).variableOptions;
    let rows: ReadonlyArray<{
        title: string;
        option: keyof LocationOptions | keyof CoordinateOptions;
        isClearable: boolean;
    }> = usesCoordinates ? coordinateRows : locationRows;

    return (
        <div className={styles.root}>
            <Select
                options={selectPinOptions}
                value={props.dataVariableOption}
                defaultValue={selectPinOptions[2]}
                isSearchable={false}
                onChange={(newValue) => {
                    if (!newValue) {
                        return;
                    }

                    props.onToggle((newValue as MapDataVariableOption));
                }}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        minWidth: 60,
                    }),
                    control: (provided) => ({
                        ...provided,
                        minHeight: 25,
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        display: 'none',
                    }),
                }}
            />
            <div className={styles.fields}>
                {rows.map((row) => (
                    <div className={styles.field}>
                        <span className={styles.label}>
                            {row.title}
                        </span>
                        <div
                            className={styles.select}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            <Select
                                isClearable={row.isClearable}
                                placeholder=""
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: 4,
                                    }),
                                    container: (base) => ({
                                        ...base,
                                        flexGrow: 1,
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: 'none',
                                    }),
                                }}
                                options={row.option === "country" && props.dataVariableOption?.value === MapDataVariableValue.CountryList
                                    ? countriesOptions
                                    : variableOptions
                                }
                                onChange={(newValue) => {
                                    let changes = {
                                        [row.option]: newValue as
                                            | VariableOption
                                            | StringOption,
                                    };
                                    if (usesCoordinates) {
                                        props.onChange(undefined, changes);
                                    } else {
                                        props.onChange(changes);
                                    }
                                }}
                                value={props[row.option] ?? null}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default LocationSelectorView;
