import React, { Component } from "react";

import Alert from "common/Alert";
import Instrumentation from "common/Instrumentation";

export const StepState = Object.freeze({
    notRunning: "not_running",
    running: "running",
    finished: "finished",
    error: "error"
});

export default class BaseStepModule extends Component {
    constructor(props) {
        if(new.target === BaseStepModule)
            throw new TypeError("Cannot construct BaseStepModule instances directly");
        super(props);

        this.state = {
            stepState: StepState.notRunning,
            stepText: null
        };

        this.tempPerformance = null;
        this.performance = null;
    }

    componentDidUpdate() {
        if(this.performance != null) {
            let timeMs = new Date() - this.performance;
            this.performance = null;
            Instrumentation.addInteraction("Processing", timeMs);
        }
    }

    renderStepState() {
        let stepState = this.state.stepState;
        if (stepState === StepState.running) {
            let stepText = "Running";
            if (this.state.stepText !== null) {
                stepText += ": ";
                stepText += this.state.stepText;
            }
            return (
                <Alert
                    text={stepText}
                    className="alert alert-warning alert-dismissible"
                    onClosed={() =>
                        this.setStepState(StepState.notRunning)
                    }
                    style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        marginTop: "0px",
                        marginBottom: "0px",
                        minHeight: "25px"
                    }}
                />
            );
        } else if (stepState === StepState.finished) {
            let stepText = "Finished";
            if (this.state.stepText !== null) {
                stepText += ": ";
                stepText += this.state.stepText;
            }
            return (
                <Alert
                    text={stepText}
                    className="alert alert-success alert-dismissible"
                    style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        marginTop: "0px",
                        marginBottom: "0px",
                        minHeight: "25px"
                    }}
                    onClosed={() =>
                        this.setStepState(StepState.notRunning)
                    }
                />
            );
        } else if (stepState === StepState.error) {
            let stepText = "Error";
            if (this.state.stepText !== null) {
                stepText += ": ";
                stepText += this.state.stepText;
            }
            return (
                <Alert
                    text={stepText}
                    className="alert alert-danger alert-dismissible"
                    onClosed={() =>
                        this.setStepState(StepState.notRunning)
                    }
                    style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        marginTop: "0px",
                        marginBottom: "0px",
                        minHeight: "25px"
                    }}
                />
            );
        } else {
            return null;
        }
    }

    setStepState(stepState, stepText = null, additionalChanges = {}) {
        if(stepState === StepState.running) {
            this.tempPerformance = new Date();
        }
        else if(stepState === StepState.error || stepState === StepState.finished) {
            this.performance = this.tempPerformance;
            this.tempPerformance = null;
        }
        this.setState(state => {
            let rest;
            if (additionalChanges instanceof Function)
                rest = additionalChanges(state);
            else rest = additionalChanges;
            return {
                stepState: stepState,
                stepText: stepText,
                ...rest
            };
        });
    }
}
