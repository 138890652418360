import React from "react";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";

import Tables, { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import { Permission } from "common/Permissions";
import remoteModuleId from "common/remoteModuleId";

import styles from "./RevampedDataScopeAndTableSelectorView.module.css";

interface Props {
    dataScopeOption: DataScopeOption | null;
    dataScopePlaceholder?: string;
    tableOption: TableOption | null;
    tableOptionPlaceholder?: string;
    tableOptionClearable?: boolean;
    needWritePermissions: boolean;
    maxHeight: number;
    onChange: (
        dataScopeOption: DataScopeOption | null,
        tableOption: TableOption | null
    ) => void;
    currentModuleId?: number;
}

const RevampedDataScopeAndTableSelectorView = observer((props: Props) => {
    let dataScopes =
        props.currentModuleId != null
            ? DataScopesForModules(props.currentModuleId)
            : DataScopes;
    return (
        <div className={styles.root}>
            <label className={styles.label}>Data</label>
            <Select
                menuShouldBlockScroll={true}
                menuPlacement="auto"
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                filterOption={createFilter({
                    ignoreAccents: false,
                })}
                placeholder="Select data set"
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderRadius: "4px",
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none",
                    }),
                    menuPortal: (base) => ({
                        ...base,
                        zIndex: 100000000,
                    }),
                }}
                options={dataScopes.dataScopesOptions.filter((option) => {
                    if (props.needWritePermissions)
                        return option.permissionType === Permission.ReadWrite;
                    else return option.permissionType >= Permission.ReadOnly;
                })}
                onChange={(newValue) => {
                    props.onChange(
                        newValue as DataScopeOption,
                        Tables(
                            (newValue as DataScopeOption).value,
                            props.currentModuleId ?? remoteModuleId
                        ).tableToOption()
                    );
                }}
                value={props.dataScopeOption}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        text: "white",
                        primary25: "var(--selectors-background-hover-color)",
                    },
                })}
            />
        </div>
    );
});

export default RevampedDataScopeAndTableSelectorView;
