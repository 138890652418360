export const notebookSample =
    "#Examples for matplotlib and seaborn\n" +
    "\n" +
    "import numpy as np\n" +
    "import seaborn as sns\n" +
    "import matplotlib.pyplot as plt\n" +
    "sns.set(style='ticks')\n" +
    "df = sns.load_dataset('anscombe')\n" +
    "plt.rcParams['axes.facecolor']='None'\n" +
    "plt.rcParams['savefig.facecolor']='None'\n" +
    "plt.rcParams['axes.edgecolor']='black'\n" +
    "plt.rcParams['axes.linewidth']=0.5\n" +
    "plt.rcParams['xtick.color']='#869AAC'\n" +
    "plt.rcParams['ytick.color']='#869AAC'\n" +
    "plt.rcParams['axes.labelcolor']='white'\n" +
    "plt.rcParams['axes.grid'] = True\n" +
    "plt.rcParams['grid.alpha'] = 0.5\n" +
    "plt.rcParams['grid.color'] = 'black'\n" +
    "plt.rcParams['grid.linestyle'] = 'dashed'\n" +
    "g = sns.lmplot(x='x', y='y', col='dataset', hue='dataset', data=df," +
    "col_wrap=2, ci=None, palette='muted', height=4," +
    "scatter_kws={'s': 50, 'alpha': 1})\n" +
    "g.set_titles(color='white')\n" +
    "show_plot()\n" +
    "\n" +
    "fig = plt.figure()\n" +
    "plt.plot(range(10), range(10), color='#00C687', marker='.', linestyle='dashed', figure=fig)\n" +
    "show_plot(fig)";

export const variableCreatorSample =
    "#Examples: where 'x' is the variable being created, a, b, c, are variables in the dataset\n" +
    "\n" +
    "#data.loc[data['a'] < 10, 'x'] = data['b'].mean()\n" +
    "#data.loc[data['b'].str.contains('hello'),'x'] = 'awesome'\n" +
    "#data['x'] = data['c'].max()\n";

export const variableCreatorRowLevelSample =
    "#Examples: where 'x' is the variable being created, a, b, c, are variables in the dataset\n" +
    "\n" +
    "#row['x'] = row['a'] * row['b'] + row['c']";
