class AsyncAction<Args extends any[], RetValAsync extends any[], RetValAction> {
    public asyncPart: (...args: Args) => Promise<RetValAsync>;
    public actionPart: (...args: RetValAsync) => RetValAction;

    constructor(
        asyncPart: (...args: Args) => Promise<RetValAsync>,
        actionPart: (...args: RetValAsync) => RetValAction
    ) {
        this.asyncPart = asyncPart;
        this.actionPart = actionPart;
    }

    public async bothParts(...args: Args): Promise<RetValAction> {
        return this.actionPart(...(await this.asyncPart(...args)));
    };
}

const asyncAction = <
    Args extends any[],
    RetValAsync extends any[],
    RetValAction
>(
    asyncPart: (...args: Args) => Promise<RetValAsync>,
    actionPart: (...args: RetValAsync) => RetValAction
): AsyncAction<Args, RetValAsync, RetValAction> => {
    return new AsyncAction(asyncPart, actionPart);
};

export { AsyncAction };
export default asyncAction;
