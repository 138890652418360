import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Tables, { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import styles from "./CleanSection.module.css";
import DataScopeAndTableSelectorView from "common/DataScopeAndTableSelectorView";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import Variables from "common/Variables";
import { Condition, ConditionsSelector } from "./conditionSelector";
import { sliceData } from "common/DataApi";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import { preview } from "../MergeSection";
import OutsideAlerter from "common/OutsideAlerter";
import SliceSection from "../SliceSection";
import remoteModuleId from "common/remoteModuleId";
import SelectedOption from "../SelectedOption";
import TabSwitchConfirmationPopup from "common/TabSwitchConfirmationPopup";

function CleanSection(props: { setOperationInProgress: (state: boolean) => void; }) {
    const executeSlice = (
        tableOption: TableOption,
        conditions: Condition[],
        preview: boolean
    ) => {
        setSlicing(true);
        if (preview) {
            setPreviewInfo(null);
        }
        setStatusPopup({
            status: PopupStatus.Success,
            message: "Slicing data...",
        });
        sliceData(tableOption, conditions, preview)
            .then(async (result) => {
                setSlicing(false);

                if (!preview) {
                    if (tableOption.data_table_idx != null) {
                        await Tables(tableOption.data_table_idx).update();

                        let a = Tables(
                            dataset ? dataset?.value : "",
                            remoteModuleId
                        ).rawAndAggregateTableOptions;

                        if (a.length > 0) {
                            a.map((item) => {
                                item.condition_id === result &&
                                    setStatusPopup({
                                        status: PopupStatus.Success,
                                        message: `New data table is now saved as ${item.label}`,
                                    });
                                setTimeout(() => {
                                    setStatusPopup(null);
                                }, 3000);
                                return null;
                            });
                        }
                    }
                } else {
                    setPreviewInfo(
                        result as {
                            tableHeader: string[];
                            tablePreview: (string | number | null)[][];
                        }
                    );
                    setStatusPopup({
                        status: PopupStatus.Success,
                        message: `Done`,
                    });
                    setTimeout(() => {
                        setStatusPopup(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setSlicing(false);
                setStatusPopup({
                    status: PopupStatus.Error,
                    message: String(error),
                });
            });
    };
    let [dataset, setDataset] = React.useState<DataScopeOption | null>(null);
    let [table, setTable] = React.useState<TableOption | null>(null);

    React.useEffect(() => {
        props.setOperationInProgress((dataset)? true : false);
    }, [dataset]);
    
    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    let [previewInfo, setPreviewInfo] = React.useState<{
        tableHeader: string[];
        tablePreview: (string | number | null)[][];
    } | null>(null);
    let [slicing, setSlicing] = React.useState<boolean>(false);
    let [conditions, setConditions] = React.useState<Condition[]>(
        ConditionsSelector.defaultValue
    );

    let [showClean, setShowClean] = React.useState<{
        id: number;
        name: string;
        type: string;
        Xcoord: number;
        Ycoord: number;
    } | null>(null);

    useEffect(() => {
        return () => {
            DataScopes.previewState = { headers: null, body: null };
        };
    }, []);

    return (
        <div className={"mainContainer"} style={{ paddingBlock: 0 }}>
            <div className={styles.datasetsContainer}>
                <div className={styles.textItem}>Source</div>
                <DataScopeAndTableSelectorView
                    dataHubMode
                    dataScopeOption={dataset}
                    tableOption={table}
                    onChange={(dataScopeOption, tableOption) => {
                        setDataset(dataScopeOption);
                        setTable(tableOption);
                        setConditions(ConditionsSelector.defaultValue);

                        setPreviewInfo(null);
                        DataScopes.previewState = { headers: null, body: null };
                        if (dataScopeOption?.value != null) {
                            DataScopes.DatasetPreview(
                                Number(dataScopeOption?.value),
                                undefined,
                                tableOption ?? undefined
                            );
                        }
                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(false);
                    }}
                    dataScopePlaceholder={"Select dataset"}
                    tableOptionPlaceholder={"Select table"}
                    needWritePermissions={true}
                    hideTable={dataset === null}
                />
            </div>
            {DataScopes.preview?.headers != null && (
                <div
                    className={"customPreview"}
                    style={{ position: "relative" }}
                >
                    {preview(
                        DataScopes.preview?.headers,
                        DataScopes.preview?.body,
                        1,
                        true,
                        "CleanSection",
                        dataset,
                        setShowClean
                    )}
                    {showClean != null && (
                        <div
                            className={`${styles.EditPopup} `}
                            style={{
                                width: "max-content",
                                height: "374px",
                                maxHeight: "400px",
                                position: "absolute",
                                top: 45,
                                left: `${
                                    showClean.Xcoord < 1100
                                        ? showClean.Xcoord - 125
                                        : showClean.Xcoord - 328
                                }px`,
                                background: "transparent",
                                overflow: "hidden",
                                zIndex: 8,
                            }}
                        >
                            <OutsideAlerter
                                onReject={() => {
                                    setShowClean(null);
                                }}
                            >
                                <SliceSection
                                    dataset={dataset}
                                    selectedVariable={showClean}
                                    showClean={setShowClean}
                                    setStatusPopup={setStatusPopup}
                                    table={table}
                                />
                            </OutsideAlerter>
                        </div>
                    )}
                </div>
            )}

            <div
                className={styles.textItem}
                style={{ marginTop: 30, paddingLeft: 3, marginBottom: 7 }}
            >
                Filter by...
            </div>
            <div className={styles.variablesContainer}>
                <div className={styles.variablesWrapper}>
                    <ConditionsSelector
                        style={{ width: "380px" }}
                        key={dataset?.value}
                        small
                        dataScopeId={dataset?.value}
                        value={conditions}
                        title={""}
                        onChange={setConditions}
                        allVariables={Variables(dataset?.value).dataVariables}
                        {...ConditionSelectorStyles}
                        asWizard={false}
                        logicalButtons={true}
                        switch={true}
                        tab="Slice"
                    />
                </div>
            </div>

            <div className={"buttonsContainer"}>
                <button
                    className={"cancelBtn"}
                    type="button"
                    onClick={() => {
                        setConditions(ConditionsSelector.defaultValue);
                    }}
                    disabled={
                        conditions.length === 1 &&
                        !conditions[0].leftBracket &&
                        !conditions[0].operation &&
                        !conditions[0].rightBracket &&
                        !conditions[0].value &&
                        !conditions[0].variable
                    }
                >
                    {"Reset"}
                </button>
                <button
                    className={"acceptBtn"}
                    type="button"
                    disabled={slicing || dataset == null || table == null}
                    onClick={() => {
                        executeSlice(table!, conditions, true);
                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(false);
                    }}
                >
                    {"Preview"}
                </button>
            </div>

            <div className={styles.mergedPreview}>
                <div
                    className={"previewContainer"}
                    style={{ padding: "10px 15px" }}
                >
                    {previewInfo != null ? (
                        <div className={`mergedTableWrapper scrollBar`}>
                            <AdminTableWithFullFeatures
                                tableName="SliceTable"
                                className="import-table"
                                paging={false}
                                small={false}
                                tableHeader={previewInfo.tableHeader}
                                tableContent={previewInfo.tablePreview}
                            />
                        </div>
                    ) : (
                        <div className={styles.textItem}>Preview</div>
                    )}
                </div>
            </div>

            <div className={"pageEndbuttons"}>
                <button
                    className={"cancelBtn"}
                    // style={{ paddingBlock: 7 }}
                    type="button"
                    disabled={
                        slicing ||
                        dataset == null ||
                        table == null ||
                        !previewInfo
                    }
                    onClick={() => {
                        setPreviewInfo(null);
                    }}
                >
                    {"Reset"}
                </button>
                <button
                    className={"acceptBtn"}
                    // style={{ paddingBlock: 7 }}
                    type="button"
                    disabled={slicing || dataset == null || table == null}
                    onClick={() => {
                        executeSlice(table!, conditions, false);
                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(true);
                    }}
                >
                    {"Clean data"}
                </button>
            </div>

            {SelectedOption.getAskSwitchTab && dataset !== null && (
                <TabSwitchConfirmationPopup />
            )}

            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
        </div>
    );
}

export default observer(CleanSection);
