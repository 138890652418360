import React from "react";
import Select, { createFilter } from "react-select";
import Finding, {
    LeversOutcomePredictionFinding,
    LeversOutcomeType,
} from "common/Finding";
import commonStyles from "../../DataSection.module.css";
import { getCustomSelectStyleForDataSection, leftAlignAndScrollBarCSS } from "common/SelectStyles";
import { VariableOption } from "common/Variables";
import variables from "common/Variables";
import { observer } from "mobx-react";
import StringOption from "common/StringOption";
import {
    statsmodelsRegressions as regressions,
    families,
    links,
} from "common/Regressions";
import RegressionOptionsSelector from "../RegressionOptionsSelector";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import Switch from "react-switch";
import { greyColor } from "common/Constants";

const analysisOptions = [
    { label: "Coefficients", value: LeversOutcomeType.Bars },
    { label: "Effect Curves", value: LeversOutcomeType.Curve },
    { label: "Marginal Effect Curves", value: LeversOutcomeType.MarginalCurve },
    { label: "Raw Numerical Output", value: LeversOutcomeType.Raw },
];

interface Props {
    finding: LeversOutcomePredictionFinding;
    currentModuleId?: number;
    onChange: (finding: Finding, updateData?: boolean) => void;
}

export default observer(function LeverOutcomeSection(props: Props) {
    let datasetIsConnected = props.finding.config.dataScope != null;
    if (!datasetIsConnected) return null;
    let selectStyles = getCustomSelectStyleForDataSection(14, false);

    let variableOptions: VariableOption[] = [];
    let numericOptions: VariableOption[] = [];

    variableOptions = variables(
        props.finding.config.dataScope.value,
        props.currentModuleId
    ).variableOptions;
    numericOptions = variables(
        props.finding.config.dataScope.value,
        props.currentModuleId
    ).variableOptions.filter(
        (item) => ["int", "float"].includes(item.type) && item.panel !== "time"
    );

    let reggressionOptions = regressions.map((item) => ({
        label: item,
        value: item,
    }));
    let { selectedRegression, selectedFamily, selectedLink } =
        props.finding.config;
    let familyOptions: StringOption[] = [];
    let linkOptions: StringOption[] = [];
    if (selectedRegression != null) {
        familyOptions = families[selectedRegression].map((item) => ({
            label: item,
            value: item,
        }));
    }
    if (selectedFamily != null) {
        linkOptions = links[selectedFamily].map((item) => ({
            label: item,
            value: item,
        }));
    }
    const demo = () => {
        let newFinding = {
            ...props.finding,
        };
        newFinding.config.dvLog = !newFinding.config.dvLog;
        props.onChange(newFinding, false);
    }
    return (
        <div>
            <div className="my-row" style={{ display: 'flex' }}>
                <div style={{ marginRight: '30px' }}>
                    <h3
                        className={commonStyles.textItem}
                        style={{ width: "200px" }}
                    >
                        Current model
                    </h3>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={"Select"}
                        styles={{
                            ...selectStyles,
                            ...leftAlignAndScrollBarCSS,
                            container: (base) => ({
                                ...base,
                                marginTop: "10px",
                                width: "305px",
                                height: "40px",
                            }),
                        }}
                        options={reggressionOptions}
                        onChange={(newValue) => {
                            let newFinding = { ...props.finding };

                            newFinding.config.selectedRegression = (
                                newValue as StringOption
                            ).label;
                            newFinding.config.selectedFamily = null;
                            newFinding.config.selectedLink = null;
                            props.onChange(newFinding, false);
                        }}
                        value={
                            selectedRegression != null
                                ? {
                                    label: selectedRegression,
                                    value: selectedRegression,
                                }
                                : null
                        }
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                </div>


                {familyOptions.length > 0 && (
                    <div style={{ marginRight: '30px' }}>
                        <h3
                            className={commonStyles.textItem}
                            style={{ width: "200px" }}
                        >
                            Family
                        </h3>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Select"}
                            styles={{
                                ...selectStyles,
                                ...leftAlignAndScrollBarCSS,
                                container: (base) => ({
                                    ...base,
                                    marginTop: "10px",
                                    width: "305px",
                                    height: "40px",
                                }),
                            }}
                            options={familyOptions}
                            onChange={(newValue) => {
                                let newFinding = { ...props.finding };
                                newFinding.config.selectedFamily = (
                                    newValue as StringOption
                                ).label;
                                newFinding.config.selectedLink = null;
                                props.onChange(newFinding, false);
                            }}
                            value={
                                selectedFamily != null
                                    ? {
                                        label: selectedFamily,
                                        value: selectedFamily,
                                    }
                                    : null
                            }
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                )}
                {linkOptions.length > 0 && (
                    <div style={{ width: '350px' }}>
                        <h3
                            className={commonStyles.textItem}
                            style={{ width: "200px" }}
                        >
                            Link function
                        </h3>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Select"}
                            styles={{
                                ...selectStyles,
                                ...leftAlignAndScrollBarCSS,
                                container: (base) => ({
                                    ...base,
                                    marginTop: "10px",
                                    width: "100%",
                                    height: "38px",
                                }),
                            }}
                            options={linkOptions}
                            onChange={(newValue) => {
                                let newFinding = { ...props.finding };
                                newFinding.config.selectedLink = (
                                    newValue as StringOption
                                ).label;
                                props.onChange(newFinding, false);
                            }}
                            value={
                                selectedLink != null
                                    ? {
                                        label: selectedLink,
                                        value: selectedLink,
                                    }
                                    : null
                            }
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                )}
            </div>

            <h3 className={commonStyles.textItem}>
                Dependent variable
            </h3>
            <div style={{ display: 'flex', }}>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Dependent variable"}
                    styles={{
                        ...selectStyles,
                        ...leftAlignAndScrollBarCSS,
                        container: (base) => ({
                            ...base,
                            width: "305px",
                            height: "40px",
                        }),
                    }}
                    options={numericOptions}
                    onChange={(newValue) => {
                        let newFinding = { ...props.finding };
                        newFinding.config.dependentVariable = {
                            label: newValue.label,
                            value: newValue.value,
                        };
                        newFinding.config.independentVariables[newValue.value] =
                            null;
                        props.onChange(newFinding, false);
                    }}
                    value={props.finding.config.dependentVariable}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25: "var(--selectors-background-hover-color)",
                        },
                    })}
                />

                <div
                    className={commonStyles.optionContainer}
                    style={{ marginLeft: '30px', marginTop:0 }}
                >
                    <div className="pretty p-default" contentEditable={false}
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                    >
                        <div className={commonStyles.textItem} style={{ marginRight: 5, marginBottom:0, marginTop:-3 }}>
                            Log
                        </div>
                        <Switch
                            onChange={demo}
                            checked={props.finding.config.dvLog}
                            className={commonStyles.switch}
                            width={34}
                            height={16 }
                            handleDiameter={14}
                            borderRadius={8}
                            activeBoxShadow="none"
                            offColor="#CCC"
                            onColor="#3474B2"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#70889E"
                            onHandleColor="#fff"
                        />
                    </div>
                </div>
            </div>

            <h3
                className={commonStyles.textItem}
                style={{ marginBottom: 10 }}
            >
                Independent variables
            </h3>
            <RegressionOptionsSelector
                currentModuleId={props.currentModuleId}
                finding={props.finding}
                onChange={props.onChange}
                variableOptions={variableOptions}
            />
            {props.finding.content.regressionInfo != null &&
                !props.finding.config.hideOutputTypeChange && (
                    <>
                        <h3 className={commonStyles.textItem}>
                            Analysis type
                        </h3>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Select"}
                            styles={{
                                ...selectStyles,
                                container: (base) => ({
                                    ...base,
                                    marginTop: "10px",
                                    width: "100%",
                                    height: "38px",
                                }),
                                singleValue: (base) => ({
                                    ...base,
                                    textAlign: 'left',
                                    paddingLeft: '5px',

                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    paddingLeft: '5px',
                                    textAlign: 'left'
                                }),
                                option: ((base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected ? greyColor : "white",
                                    color: "black",
                                    "&:hover": {
                                        backgroundColor: state.isSelected ? greyColor : greyColor
                                    }
                                }))
                            }}
                            options={analysisOptions}
                            value={analysisOptions.find(
                                (item) =>
                                    item.value ===
                                    props.finding.config.outputType
                            )}
                            onChange={(newValue) => {
                                let newFinding = { ...props.finding };
                                newFinding.config.outputType = (
                                    newValue as {
                                        label: string;
                                        value: LeversOutcomeType;
                                    }
                                ).value;
                                props.onChange(newFinding, false);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </>
                )}
        </div>
    );
});
