import axios from "common/ServerConnection";
import { UserType } from "common/UserInfo";

export const selfSignupTypes = [
    "shared_module",
    "app_self_signup_link",
    "app_email_invitation",
    "user_module_email_invitation",
] as const;

export type SelfSignupType = typeof selfSignupTypes[number];

export async function getSelfSignupDefaultUserType(
    selfSignupTypes: readonly SelfSignupType[]
): Promise<{ [key in SelfSignupType]: UserType }> {
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
            default_user_types: { [key in SelfSignupType]: UserType };
        }>("/api/self_signup_default_user_type_get", {
            self_signup_types: selfSignupTypes,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.default_user_types);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function setSelfSignupDefaultUserType(
    userType: UserType,
    selfSignupType: SelfSignupType
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
        }>("/api/self_signup_default_user_type_set", {
            default_user_type: userType,
            self_signup_type: selfSignupType,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
