import * as d3 from "d3";

function kernelDensityEstimator<TObject>(
    kernel: (v: number) => number,
    x: number[],
    accessor: (obj: TObject) => number
) {
    return (v: TObject[]) =>
        x.map((x): [number, number] => [
            x,
            d3.mean(v, (v) => kernel(x - accessor(v)))!,
        ]);
}

function kernelEpanechnikov(k: number): (v: number) => number {
    return (v: number) =>
        Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0;
}

export function epanechnikovKDE<TObject>(
    k: number,
    x: number[],
    accessor: (obj: TObject) => number
) {
    return kernelDensityEstimator(kernelEpanechnikov(k), x, accessor);
}
