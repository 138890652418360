import React, { Component } from "react";
import Instrumentation from "common/Instrumentation";
import { updateUserInfo } from "./UserApi";
import FieldEditor, { Field } from "modules/settings_page/common/FieldEditor";
import sections from "sections.json";
import styles from "main_pages/RegisterPageV1/RegisterPage.module.css";
import UpdateStatus from "modules/settings_page/common/UpdateStatus";
import CurrentUser from "common/CurrentUser";

interface State {
    status: UpdateStatus;
    errorMessage: string;
    value: Field[];
}

interface Props {
    onClose: () => void;
    section?: keyof typeof sections;
}

class UserInfoForm extends Component<Props, State> {
    private performance: Date | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            value: [
                {
                    name: "first_name",
                    label: "First Name",
                    value: CurrentUser.info?.first_name ?? "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "last_name",
                    label: "Last Name",
                    value: CurrentUser.info?.last_name ?? "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "organization",
                    label: "Organization",
                    value: CurrentUser.info?.organization ?? "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "position",
                    label: "Position",
                    value: CurrentUser.info?.position ?? "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "phone_number",
                    label: "Phone Number",
                    value: CurrentUser.info?.phone_number ?? "",
                    editable: true,
                    hidden: false,
                    add_mock_after: true,
                },
                {
                    name: "icon_url",
                    label: "Icon",
                    value: CurrentUser.info?.icon_url ?? "",
                    icon: true,
                    hidden: false,
                },
                {
                    name: "user_time_zone",
                    label: "Timezone",
                    value: CurrentUser.info?.user_time_zone ?? "",
                    timezoneSelect: true,
                },
            ],
        };

        this.performance = null;
        this.updateUserInfo = this.updateUserInfo.bind(this);
    }

    componentDidUpdate(): void {
        if (this.props.section != null && this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction(this.props.section, timeMs);
        }
    }

    private updateUserInfo(): void {
        let localPerformance: Date = new Date();

        this.setState({
            status: UpdateStatus.Loading,
            errorMessage: "",
        });
        let config: { [key: string]: string | string[] } = {};
        this.state.value.forEach((field: Field) => {
            config[field.name] = field.value;
        });
        updateUserInfo(config)
            .then(() => {
                if (this.props.section != null) {
                    Instrumentation.addInteraction(
                        this.props.section,
                        new Date().getTime() - localPerformance.getTime()
                    );
                }
                CurrentUser.update(false);
                this.setState({
                    status: UpdateStatus.Success,
                    errorMessage: "",
                });
            })
            .catch((error) => {
                this.performance = localPerformance;
                this.setState({
                    status: UpdateStatus.Error,
                    errorMessage: String(error),
                });
            });
    }
     render(): JSX.Element {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <FieldEditor
                    fieldsContainerClassName={styles.fieldsContainer}
                    buttonsContainerClassName={styles.buttonsContainer}
                    titleClassName={styles.title}
                    buttonTitle="Save"
                    title=""
                    value={this.state.value}
                    onChange={(value) => this.setState({ value: value })}
                    onUpdate={this.updateUserInfo}
                    onCancel={() => this.props.onClose()}
                    status={this.state.status}
                    onStatusChange={(status: UpdateStatus) =>
                        this.setState({ status: status })
                    }
                    errorMessage={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default UserInfoForm;
