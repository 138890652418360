import { observable, computed, makeObservable } from "mobx";
import axios from "./ServerConnection";

export interface Model {
	id: number;
	name: string;
	tag: string;
	type: string;
	panel_index: number;
	outcome_index: number;
}

class ModelsInner {
	private dataScopeId: number | string | undefined;
	@observable public modelsState: Model[] = [];

	constructor(dataScopeId: number | string | undefined) {
		makeObservable(this);
		this.dataScopeId = dataScopeId;
		this.update();
	}
	@computed public get models() {
		return this.modelsState;
	}
	public get modelsOptions() {
		return this.modelsState.map((model) => ({
			label: model.name,
			value: model.id,
			info: model
		}));
	}

	public async update(): Promise<void> {
		if (typeof this.dataScopeId !== "undefined") {
			return axios
				.post<{
					success: boolean;
					error_msg: string;
					models: Model[];
				}>("/api/get_models", {
					data_table_idx: this.dataScopeId,
				})
				.then((response) => {
					if (response.data.success && response.data.models != null) {
						this.modelsState = response.data.models;
					} else {
						console.log(response.data.error_msg);
					}
				})
				.catch((error) => {
					console.log(error);
					return Promise.reject(error);
				});
		} else return Promise.resolve();
	}
}

let modelsMaps: { [key in number | string]: ModelsInner } = {};
let modelsUndefined: ModelsInner = new ModelsInner(undefined);

export default function Variables(
	dataScopeId: number | string | undefined
): ModelsInner {
	if (dataScopeId != null) {
		if (!(dataScopeId in modelsMaps)) {
			modelsMaps[dataScopeId] = new ModelsInner(dataScopeId);
		}
		return modelsMaps[dataScopeId];
	} else {
		return modelsUndefined;
	}
}
