// import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
// import React from 'react';
// import axios from "common/ServerConnection";
// import { AiCopilotFinding } from 'common/Finding';

// interface IProps {
//   selected: boolean
//   finding: AiCopilotFinding
// }

// function AiChatbot(props : IProps) {

//   let finding = props.finding;
//   const [name, setName] = React.useState({n: ""});
//   const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     let text = name.n
//     // alert(`The text you entered was: ${text}`)
//     setName({ n: ""});
//     const newMessage = {
//       message: text,
//       sender: "user",
//       direction: "outgoing"
//     }

//     const newMessages = [...messages, newMessage]
//     setMessages(newMessages)
//     setTyping(true);
//     // await delay(5000);
//     //Need to add the connection to backend here, get the response, and add it in place of "Response"

//     let requestJson = {
//       data_table_idx: props.finding.config.selectedTable.data_table_idx,
//       message: text
//     }

//     axios.post<{
//                 message: string;
//             }>("/api/e/send_message", requestJson).then((response) => {
//               console.log(response.data.message)
//               const res = {
//                 message: response.data.message,
//                 sentTime: "just now",
//                 sender: "ChatGPT"
//               }
//               const withResponse = [...newMessages, res]
//               setMessages(withResponse)
//               setTyping(false);
//               })

//   }

// 	const [typing, setTyping] = React.useState(false)
//   const [messages, setMessages] = React.useState<any[]>([
//       {
//         message: "I am your personal chatbot, how can I help?",
//         sentTime: "just now",
//         sender: "ChatGPT"
//       },

//   ]);

//   // Old Flask Backend for reference. Will delete once completed

//   // const sendHandler = async(message: string) => {
//   //   const newMessage = {
//   //     message: message,
//   //     sender: "user",
//   //     direction: "outgoing"
//   //   }

//   //   const newMessages = [...messages, newMessage]

//   //   setMessages(newMessages);
//   //   setTyping(true);

//   //   await convertToChatGPTFormat(newMessages);
//   // }

//   // async function convertToChatGPTFormat(chatMessages: any) {
//   //   console.log(chatMessages[chatMessages.length - 1].message)
//   //   var messageText = chatMessages[chatMessages.length - 1].message
//   //   await fetch(`/chat/${messageText}`).then(
//   //       (response: any) => {
//   //     setMessages(
//   //       [...chatMessages, {
//   //         message: response.data,
//   //         sender: "ChatGPT"
//   //       }]
//   //     );
//   //     setTyping(false);
//   //   })
//   // }

//   return (
//     <div><div className="Wrapper">
//       <div
// 					// style={{
// 					// 	width: props.width,
// 					// 	height: props.height
// 					// }}
// 				>
//         <MainContainer>
//             <ChatContainer>
//               <MessageList
//                 scrollBehavior="smooth"
//                 typingIndicator={typing ? <TypingIndicator content="Eisengard is typing" /> : null}
//               >
//                 {messages.map((message, i) => {
//                   // console.log(message)
//                   return <Message key={i} model={message} />
//                 })}
//               </MessageList>

//             </ChatContainer>
//           </MainContainer>
//         </div>
//       </div>
//       <div>
//         {/* <MessageInput placeholder="Type message here" onSend={sendHandler} /> */}

//     <form id="inp" onSubmit={handleSubmit}>
//       <label>Enter your question:
//         <input
//           type="text"
//           value={name.n}
//           onKeyDown={(evt) => evt.stopPropagation()}
//           onChange={(e) => setName({n: e.target.value})}
//         />
//       </label>
//       <input type="submit" />
//     </form>

//       </div>
//     </div>
//   )

// }

// export default AiChatbot;
import React, { useMemo } from "react";
import {
    AiCopilotFinding,
    defaultAiCopilotBackgroundColor,
    defaultAiCopilotBannerBgColor,
} from "common/Finding";

//import React from 'react';
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import "./react-chatbot-styles.css";
import "./index.css";

import configForFinding from "./config";
import ActionProviderForFinding from "./ActionProvider";
import MessageParser from "./MessageParser";

interface Props {
    finding: AiCopilotFinding;
}

function AiChatbot(props: Props) {
    // axios.post<{
    //                   message: string;
    //               }>("/api/e/send_message", requestJson).then((response) => {
    //                 console.log(response.data.message)
    //                 const res = {
    //                   message: response.data.message,
    //                   sentTime: "just now",
    //                   sender: "ChatGPT"
    //                 }
    //                 const withResponse = [...newMessages, res]
    //                 setMessages(withResponse)
    //                 setTyping(false);
    //                 })
    /* To remove the header text in Chatbot you have to specify a space. Empty
     string does not work */

    //    setGetMessage({
    //     "status": "Success",
    //     "type": "Test",
    //     "message": "Test"
    // })

    const ActionProvider = useMemo(
        () => ActionProviderForFinding(props.finding),
        [props.finding]
    );

    const config = useMemo(() => configForFinding(props.finding), [
        props.finding,
    ]);

    return (
        <div
            className="Wrapper"
            style={{
                height: "100%",
                /*borderRadius: 5,*/
                backgroundColor:
                    props.finding.config.backgroundColor ??
                    defaultAiCopilotBackgroundColor,
            }}
            onKeyDown={(evt) => evt.stopPropagation()}
        >
            {/* //   <header className="App-header" onKeyDown={(evt) => evt.stopPropagation()}> */}
            <Chatbot
                config={config}
                actionProvider={ActionProvider}
                messageParser={MessageParser}
                headerText=" "
            />

            {/* //   </header> */}
        </div>
    );
}

export default AiChatbot;
