/*
  REST Text2Speech component, gets a sentance 
  and plays the answer on the browser 
 */

import React from "react";

/* for backend API calls */
import axios from "common/ServerConnection";

const getApiKey = async () => {
    //const myDGKey = process.env.REACT_APP_DG_KEY;
    const response = await axios.get<any>('/api/e/call_get_DGKey');        
    console.log("resonse=", response);
    const myDGKey = response.data.dg_key;    
    
    console.log("DeepGram key used:", myDGKey);
    return myDGKey;     // Got rid of hard-coding of the API Key.  TODO: need to protect this key by extracting from backend.   
};

interface IText2Speech {
    text: string;
}
  
const Text2Speech: React.FC<IText2Speech> = ({ text }) => {
  
    const [audioUrl, setAudioUrl]:any = React.useState(null);
    const audioRef = React.useRef(null);
  
  
    React.useEffect(() => {
      text2Speech(text);  
    }, [text]);
  
    const text2Speech = (text: string) => {      
        const url = "https://api.deepgram.com/v1/speak?model=aura-athena-en";
        //const url = "https://api.deepgram.com/v1/speak?model=aura-helios-en";
      
        //const apiKey = getApiKey(); 
        //console.log("apiKey=", apiKey);
        getApiKey().then(apiKey => {
            console.log("apiKey=", apiKey);

            const body = JSON.stringify({
                text: text,
              });
          
              const headers = {
                Authorization: `Token ${apiKey}`,
                "Content-Type": "application/json",
              };
          
              const options = {
                method: "POST",
                headers: headers,
                body: body,
              };
          
              fetch(url, options)
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Failed to make request");
                  }
                  return response.blob();
                })
                .then((blob) => {
                  const url = URL.createObjectURL(blob);
          
                  setAudioUrl(url);
                })
                .catch((error) => {
                  console.error("Error:", error);
                });

            // Use the apiKey in the rest of your text2Speech function logic
            // e.g., passing it to another API call or using it in your logic

        }).catch(error => {
            console.error("Failed to get API key:", error);
            // Handle the error appropriately
        });      
    }
  
    return (
      <div>
        {audioUrl ? (
          <audio
            ref={audioRef}
            src={audioUrl}
            autoPlay
            style={{ display: 'none' }}
          />
        ) : null}
      </div>
    );
  }
  
  export default Text2Speech;