import React from "react";
import cx from "classnames";
import stringSimilarity from "string-similarity";
import styles from "./DefaultTable.module.css";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

interface Column<Row extends object> {
    objectName: keyof Row;
    headerName: string;
    AltRender?: React.FC<{
        row: Row;
        rowIndex: number;
        column: Column<Row>;
        columnIndex: number;
    }>;
    formatter?: (
        row: Row,
        rowIndex: number,
        column: Column<Row>,
        columnIndex: number
    ) => string;
    sortable: boolean;
    width?: number;
    align?: string;
    colState?: boolean;
}

interface IProps<Row extends object> {
    columns: Column<Row>[];
    data: Row[];
    initialSort?: {
        columnIndex: number;
        direction: "asc" | "desc";
    };
    search?: {
        columnIndex: number;
        searchText: string;
    };
    textIfDataEmpty?: string;
}

function DefaultTable<Row extends object>({
    columns,
    data,
    initialSort,
    search,
    textIfDataEmpty,
}: IProps<Row>) {
    const [sortBy, setSortBy] = React.useState<
        | {
              columnIndex: number;
              direction: "asc" | "desc";
          }
        | undefined
    >(initialSort);

    const sortedRowIndices = React.useMemo(() => {
        let sortedRowIndices = data.map((_, rowIndex) => rowIndex);
        if (search != null) {
            let key = columns[search.columnIndex].objectName;
            if (search.searchText.length === 1) {
                return sortedRowIndices.filter((rowIndex) =>
                    String(data[rowIndex][key])
                        .toLowerCase()
                        .includes(search.searchText.toLowerCase())
                );
            } else {
                return sortedRowIndices
                    .map((rowIndex) => ({
                        rowIndex: rowIndex,
                        similarity: stringSimilarity.compareTwoStrings(
                            String(data[rowIndex][key]).toLowerCase(),
                            search.searchText.toLowerCase()
                        ),
                    }))
                    .filter((item) => item.similarity > 0.01)
                    .sort((item1, item2) =>
                        item1.similarity > item2.similarity
                            ? -1
                            : item1.similarity === item2.similarity
                            ? 0
                            : 1
                    )
                    .map((item) => item.rowIndex);
            }
        } else if (sortBy != null) {
            let key = columns[sortBy.columnIndex].objectName;
            if (sortBy.direction === "asc") {
                sortedRowIndices.sort((rowIndex1, rowIndex2) =>
                    data[rowIndex1][key] < data[rowIndex2][key]
                        ? -1
                        : data[rowIndex1][key] === data[rowIndex2][key]
                        ? 0
                        : 1
                );
            } else {
                sortedRowIndices.sort((rowIndex1, rowIndex2) =>
                    data[rowIndex1][key] > data[rowIndex2][key]
                        ? -1
                        : data[rowIndex1][key] === data[rowIndex2][key]
                        ? 0
                        : 1
                );
            }
            return sortedRowIndices;
        } else {
            return sortedRowIndices;
        }
    }, [sortBy, data, columns, search]);

    const THeaderRender = () => {
        return (
            <thead>
                <tr className={styles.headerRow}>
                    {columns.map((column: Column<Row>, columnIndex: number) => (
                        <td
                            key={`header-${columnIndex}`}
                            style={{
                                width: `${
                                    column.width
                                        ? column.width
                                        : 100 / columns.length
                                }%`,
                                justifyContent: `${
                                    column.align ? column.align : "left"
                                }`,
                            }}
                            className={styles.gridTitle}
                        >
                            {column.headerName}
                            {column.sortable && (
                                <button
                                    className={cx(styles.sortButton)}
                                    onClick={() => {
                                        if (search == null) {
                                            setSortBy((sortBy) => {
                                                if (
                                                    sortBy == null ||
                                                    sortBy.columnIndex !==
                                                        columnIndex
                                                ) {
                                                    return {
                                                        columnIndex: columnIndex,
                                                        direction: "asc",
                                                    };
                                                } else {
                                                    return {
                                                        columnIndex: columnIndex,
                                                        direction:
                                                            sortBy.direction ===
                                                            "asc"
                                                                ? "desc"
                                                                : "asc",
                                                    };
                                                }
                                            });
                                        }
                                    }}
                                >
                                    <ChevronIcon
                                        className={cx({
                                            [styles.sortInactive]:
                                                search != null ||
                                                sortBy?.columnIndex !==
                                                    columnIndex,
                                            [styles.sortReverse]:
                                                search == null &&
                                                sortBy?.columnIndex ===
                                                    columnIndex &&
                                                sortBy?.direction === "desc",
                                        })}
                                        stroke="#555"
                                    />
                                </button>
                            )}
                        </td>
                    ))}
                </tr>
            </thead>
        );
    };

    const TBodyRender = (sortedRowIndices: number[]) => {
        if (!sortedRowIndices.length) {
            return (
                <tbody>
                    <tr className={styles.gridRowIfDataEmpty}>
                        <td className={styles.gridItem}>{textIfDataEmpty}</td>
                    </tr>
                </tbody>
            );
        }

        return (
            <tbody>
                {sortedRowIndices.map((rowIndex) => (
                    <TRRender rowIndex={rowIndex} row={data[rowIndex]} />
                ))}
            </tbody>
        );
    };

    const TRRender = (props: { rowIndex: number; row: Row }) => {
        const { rowIndex, row } = props;
        return (
            <tr className={styles.gridRow}>
                {columns.map((column, columnIndex) => {
                    // const { value, AltRender, width, align } = item;
                    return (
                        <td
                            key={`body-${rowIndex}-${columnIndex}`}
                            style={{
                                width: `${
                                    column.width
                                        ? column.width
                                        : 100 / columns.length
                                }%`,
                                justifyContent: `${
                                    column.align ? column.align : "left"
                                }`,
                            }}
                            className={styles.gridItem}
                        >
                            {column.AltRender ? (
                                <column.AltRender
                                    row={row}
                                    rowIndex={rowIndex}
                                    column={column}
                                    columnIndex={columnIndex}
                                />
                            ) : (
                                <p className={styles.text}>
                                    {column.formatter
                                        ? column.formatter(
                                              row,
                                              rowIndex,
                                              column,
                                              columnIndex
                                          )
                                        : row[column.objectName]}
                                </p>
                            )}
                        </td>
                    );
                })}
            </tr>
        );
    };

    return (
        <table className={styles.grid}>
            {THeaderRender()}
            {TBodyRender(sortedRowIndices)}
        </table>
    );
}

export default DefaultTable;
export type { Column };
