import React from "react";
import CustomizedAxisTick from "../../CustomizedAxisTick";
import { LegendElement } from "../../LegendElement";
import { formatValue } from "common/utilities/FormatValue";
import ReferenceLabel from "../../ReferenceLabel";

import {
    LineChart,
    Line,
    ReferenceLine,
    ReferenceDot,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";
import { LeversOutcomePredictionFinding } from "common/Finding";
import { AxisDomain } from "recharts/types/util/types";
import { TooltipStyles } from "common/graphics/TooltipStyles";

const colors = [
    ["#09e398", "#FFAB4F"],
    ["#057e55", "#d6760c"],
];

function targetKey(showMarginals: boolean) {
    return showMarginals ? "y_marginal" : "y";
}

interface InnerProps {
    axesLinesColor?: string;
    ticksColor?: string;
    ticksSize?: number;
    axesNamesColor?: string;
    showXAxisName?: boolean;
    showYAxisName?: boolean;
    maxYRange?: number;
    minYRange?: number;
    mainFixed: boolean;
    dependentVariable: string;
    independentVariable: string;
    interactionVariable?: string;
    tooltipColor?: string;
    tooltipFontSize?: number;
    showMarginals: boolean;
    chunkIndex: number;
    means: {
        x: number;
        y: number;
        y_marginal: number;
        z?: number;
    }[];
    series: {
        where?: string;
        name: string;
        origin: string;
        color: string;
        data: {
            x: number;
            y: number;
        }[];
    }[];
}

function OutcomeCurveInner(props: InnerProps) {
    const {
        mainFixed,
        dependentVariable,
        independentVariable,
        interactionVariable,
        series,
        means,
        showMarginals,
        axesLinesColor,
        ticksColor,
        ticksSize,
        tooltipColor,
        tooltipFontSize,
        axesNamesColor,
        showXAxisName,
        showYAxisName,
        minYRange,
        maxYRange,
        chunkIndex,
    } = props;
    var axisFontSize = ticksSize ?? "10px";
    let domain: AxisDomain = [
        (dataMin: number) => minYRange ?? dataMin - Math.abs(dataMin) * 0.001,
        (dataMax: number) => maxYRange ?? dataMax + Math.abs(dataMax) * 0.001,
    ];
    let allowDataOverflow = minYRange != null || maxYRange != null;
    // if (maxY != null && minY != null) {
    // 	domain = [minY - (maxY - minY) * 0.001, maxY + (maxY - minY) * 0.001];
    // }
    let showMean =
        means &&
        means.length > 0 &&
        series.length > 0 &&
        series[0].data.length > 0;
    let showEachMean = means.map(
        (mean: { x: number }) =>
            showMean &&
            mean &&
            mean.x >= series[0].data[0].x &&
            mean.x <= series[0].data[series[0].data.length - 1].x
    );
    return (
        <div
            className="my-row center-container"
            style={{ width: "100%", height: "100%" }}
        >
            {chunkIndex === 0 && showYAxisName && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <span
                        style={{
                            textAlign: "right",
                            color:
                                axesNamesColor ??
                                mainStyle.getPropertyValue(
                                    "--graphs-axes-text-color"
                                ),
                            fontFamily: "Arial",
                            fontSize:
                                mainStyle.getPropertyValue(
                                    "--graphs-axes-size"
                                ),
                        }}
                    >
                        {dependentVariable}
                    </span>
                </div>
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: `calc(100% - ${showXAxisName ? 20 : 0}px)`,
                }}
            >
                <ResponsiveContainer>
                    <LineChart
                        margin={{
                            top: 5,
                            bottom: 5,
                        }}
                    >
                        {sections._Global.watermark != null && (
                            <text
                                x="50%"
                                y="30%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fill={mainStyle.getPropertyValue(
                                    "--content-secondary-text-color"
                                )}
                                fontSize={"4rem"}
                                style={{
                                    opacity: 0.1,
                                }}
                            >
                                {sections._Global.watermark}
                            </text>
                        )}
                        <CartesianGrid
                            stroke={
                                axesLinesColor ??
                                mainStyle.getPropertyValue(
                                    "--graphs-stroke-color"
                                )
                            }
                            strokeWidth={1}
                        />
                        <XAxis
                            axisLine={false}
                            tick={
                                <CustomizedAxisTick
                                    formatValues={true}
                                    truncValues={false}
                                    fontSize={axisFontSize}
                                    axesColor={ticksColor}
                                    dx={10}
                                    dy={16}
                                />
                            }
                            dataKey="x"
                            type={mainFixed ? "category" : "number"}
                            domain={
                                !mainFixed ? ["dataMin", "dataMax"] : undefined
                            }
                            allowDuplicatedCategory={false}
                            stroke={mainStyle.getPropertyValue(
                                "--graphs-stroke-color"
                            )}
                        />
                        <YAxis
                            allowDataOverflow={allowDataOverflow}
                            type="number"
                            dataKey="y"
                            domain={domain}
                            axisLine={false}
                            tickLine={false}
                            tick={
                                <CustomizedAxisTick
                                    axesColor={ticksColor}
                                    formatValues={true}
                                    truncValues={false}
                                    fontSize={axisFontSize}
                                    dx={0}
                                    dy={0}
                                />
                            }
                        />
                        {series.map((item) => (
                            <Line
                                data={item.data}
                                dataKey="y"
                                name={item.name}
                                key={item.name}
                                isAnimationActive={false}
                                type="monotone"
                                stroke={item.color}
                                strokeWidth={3}
                                activeDot={false}
                                dot={false}
                            />
                        ))}
                        {showMean &&
                            means.map((mean, index) => {
                                return (
                                    showEachMean[index] && (
                                        <ReferenceLine
                                            key={"meanBackground".concat(
                                                String(index)
                                            )}
                                            x={mean.x}
                                            type="monotone"
                                            stroke={"#212A3D88"}
                                            strokeWidth={60}
                                        />
                                    )
                                );
                            })}
                        {showMean &&
                            means.map((mean, index) => {
                                return (
                                    showEachMean[index] && (
                                        <ReferenceLine
                                            key={"meanLine".concat(
                                                String(index)
                                            )}
                                            x={mean.x}
                                            type="monotone"
                                            stroke={"#39F"}
                                            strokeWidth={2}
                                        />
                                    )
                                );
                            })}
                        {showMean &&
                            means.map((mean, index) => {
                                if (!showEachMean[index]) return null;
                                let where = series[index].where ?? "";
                                let interactionLegend = "";
                                if (interactionVariable)
                                    interactionLegend = `${interactionVariable}: ${formatValue(
                                        mean.z!
                                    ).join("")}, `;
                                let meanLegend = `${where} ${independentVariable}: ${formatValue(
                                    mean.x
                                ).join(
                                    ""
                                )}, ${interactionLegend}${dependentVariable}: ${formatValue(
                                    mean[targetKey(showMarginals)]
                                ).join("")}`;

                                return (
                                    showEachMean[index] && (
                                        <ReferenceDot
                                            key={"meanDot".concat(
                                                String(index)
                                            )}
                                            label={
                                                <ReferenceLabel
                                                    dx={-15}
                                                    dy={20}
                                                    fontSize={mainStyle.getPropertyValue(
                                                        "--graphs-axes-text-size"
                                                    )}
                                                    fillText={mainStyle.getPropertyValue(
                                                        "--graphs-axes-text-color"
                                                    )}
                                                    fillBackground={"#20293C88"}
                                                    value={meanLegend}
                                                    textAnchor={"start"}
                                                />
                                            }
                                            x={mean.x}
                                            y={mean[targetKey(showMarginals)]}
                                            r={9}
                                            fill="#000000"
                                            strokeWidth={3}
                                            stroke="#39F"
                                        />
                                    )
                                );
                            })}
                        <RechartsTooltip
                            {...TooltipStyles(tooltipColor, tooltipFontSize)}
                            formatter={(value: number, name: string) => {
                                let formattedValue =
                                    typeof value === "number"
                                        ? value.toFixed(3)
                                        : value;
                                return formattedValue;
                            }}
                            labelFormatter={(label) => {
                                let formattedValue = independentVariable
                                    .concat(": ")
                                    .concat(
                                        typeof label === "number"
                                            ? label.toFixed(3)
                                            : label
                                    );
                                return formattedValue;
                            }}
                        />
                    </LineChart>
                </ResponsiveContainer>
                {showXAxisName && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <span
                            style={{
                                color:
                                    axesNamesColor ??
                                    mainStyle.getPropertyValue(
                                        "--graphs-axes-text-color"
                                    ),
                                fontFamily: "Arial",
                                fontSize:
                                    mainStyle.getPropertyValue(
                                        "--graphs-axes-size"
                                    ),
                            }}
                        >
                            {props.independentVariable}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}

interface Props {
    data: LeversOutcomePredictionFinding["content"]["data"];
    config: LeversOutcomePredictionFinding["config"];
    regressionInfo: LeversOutcomePredictionFinding["content"]["regressionInfo"];
    groupNames?: string[];
    showMarginals: boolean;
}

export default function OutcomeCurve(props: Props) {
    const getData = (
        data: LeversOutcomePredictionFinding["content"]["data"],
        curveNames: ("effectCurve" | "effectCurveHigh" | "effectCurveLow")[],
        groupNames: string[] | undefined,
        legendDict: { [key: string]: string },
        showMarginals: boolean
    ): {
        where?: string;
        name: string;
        origin: string;
        color: string;
        data: {
            x: number;
            y: number;
        }[];
    }[] => {
        if (data.length === 0) return [];
        let series: {
            where?: string;
            name: string;
            origin: string;
            color: string;
            data: {
                x: number;
                y: number;
            }[];
        }[] = [];
        data.forEach((dataItem, index) => {
            for (let i = 0; i < curveNames.length; i += 1) {
                let whereString = groupNames?.[index] ?? "";
                if (whereString) whereString = " for ".concat(whereString);
                let serie = {
                    where: groupNames?.[index],
                    name: legendDict[curveNames[i]].concat(whereString),
                    origin: legendDict[curveNames[i]].concat(whereString),
                    color: colors[index][i],
                    dot: false,
                    data: dataItem.regressionCurves[curveNames[i]]!.x.map(
                        (x_item, index2) => {
                            return {
                                x: x_item,
                                y: dataItem.regressionCurves[curveNames[i]]![
                                    targetKey(showMarginals)
                                ][index2],
                            };
                        }
                    ),
                };
                series.push(serie);
            }
        });
        return series;
    };
    let legendDict = {
        effectCurve: props.regressionInfo!.dv!,
        effectCurveHigh: `${props.regressionInfo!.dv!} for High ${
            props.regressionInfo!.iv?.interacts_with
        }`,
        effectCurveLow: `${props.regressionInfo!.dv!} for Low ${
            props.regressionInfo!.iv?.interacts_with
        }`,
    };
    let withInteraction = props.regressionInfo?.iv.interacts_with != null;
    let series = React.useMemo(
        () =>
            getData(
                props.data,
                withInteraction
                    ? ["effectCurveLow", "effectCurveHigh"]
                    : ["effectCurve"],
                props.groupNames,
                legendDict,
                props.showMarginals
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.data, props.groupNames, props.showMarginals]
    );

    if (props.data.length === 0) return null;
    let chunkSeries = [];
    while (series.length > 0) {
        chunkSeries.push(series.slice(0, 2));
        series = series.slice(2);
    }
    let showLegend = props.config?.showLegend ?? true;
    let legendSize = props.config?.legendSize;
    return (
        <div
            className="my-row"
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            {chunkSeries.map((chunk, index) => (
                <div
                    className="flex-simple-column"
                    key={index}
                    style={{
                        width: `calc(100%/${chunkSeries.length})`,
                        height: "100%",
                    }}
                >
                    {showLegend && (
                        <div
                            className="my-row center-container"
                            style={{
                                paddingLeft: 20,
                                paddingRight: 20,
                                marginBottom: 10,
                                width: "100%",
                            }}
                        >
                            {!withInteraction && (
                                <>
                                    {props.groupNames == null ? (
                                        <LegendElement
                                            textSize={legendSize}
                                            color={colors[0][0]}
                                            text={legendDict["effectCurve"]}
                                        />
                                    ) : (
                                        props.groupNames.map(
                                            (groupName, index) => {
                                                let legendTitle = `${legendDict["effectCurve"]} for ${groupName}`;
                                                return (
                                                    <LegendElement
                                                        textSize={legendSize}
                                                        color={colors[index][0]}
                                                        text={legendTitle}
                                                    />
                                                );
                                            }
                                        )
                                    )}
                                </>
                            )}
                            {withInteraction && (
                                <>
                                    <LegendElement
                                        textSize={legendSize}
                                        color={colors[index][0]}
                                        text={`${legendDict["effectCurveLow"]}${
                                            props.groupNames
                                                ? " for ".concat(
                                                      props.groupNames[index]
                                                  )
                                                : ""
                                        }`}
                                    />
                                    <LegendElement
                                        textSize={legendSize}
                                        color={colors[index][1]}
                                        text={`${
                                            legendDict["effectCurveHigh"]
                                        }${
                                            props.groupNames
                                                ? " for ".concat(
                                                      props.groupNames[index]
                                                  )
                                                : ""
                                        }`}
                                    />
                                </>
                            )}
                        </div>
                    )}
                    <OutcomeCurveInner
                        mainFixed={props.regressionInfo!.iv.main_fixed!}
                        showMarginals={false}
                        means={props.data.map(
                            (item) => item.regressionCurves.effectCurveMean!
                        )}
                        axesLinesColor={props.config.axesLinesColor}
                        axesNamesColor={props.config.axesNamesColor}
                        ticksColor={props.config.ticksColor}
                        ticksSize={props.config.ticksSize}
                        tooltipColor={props.config.tooltipColor}
                        tooltipFontSize={props.config.tooltipFontSize}
                        showXAxisName={props.config.showXAxisName}
                        showYAxisName={props.config.showYAxisName}
                        maxYRange={props.config.maxYRange}
                        minYRange={props.config.minYRange}
                        dependentVariable={props.regressionInfo!.dv!}
                        independentVariable={props.regressionInfo!.iv.main}
                        series={chunk}
                        chunkIndex={index}
                    />
                </div>
            ))}
        </div>
    );
}
