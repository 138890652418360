import { observable, makeObservable, runInAction } from "mobx";

import {
	ModuleGroupExtendedPermission,
	getUserModulePermissionsApi,
	getModuleUsers,
} from "common/ModulesApi";
import UserInfo from "common/UserInfo";

class ModuleUserGroupsStoreInner {
	@observable public userGroupsState: ModuleGroupExtendedPermission[] = [];
	@observable public activeUsers: Set<string> = new Set<string>();
	@observable public usersState: UserInfo[] = [];
	private moduleId: number;
	public initialized: boolean;
	constructor(moduleId: number) {
		makeObservable(this);
		this.moduleId = moduleId;
		this.initialized = false;
		this.updateUserGroups();
	}

	public async updateUserGroups() {
		try {
			let result = await getUserModulePermissionsApi(this.moduleId);

			let invitationsPermissions: ModuleGroupExtendedPermission[] = result.invitations.map(
				(invitation) => ({
					personal: true,
					invited: true,
					user_info: {
						id: -1,
						user_name: invitation.email,
						first_name: invitation.email,
						icon_url: "",
						verified: false,
						last_name: "",
					},
					group_name: "",
					permission_type: invitation.permission_type,
					group_id: -1,
				})
			);
			let selectedUserGroups = result.permissions.concat(
				invitationsPermissions
			);
			runInAction(() => {
				this.userGroupsState = selectedUserGroups;
			});
			let usersState = await getModuleUsers(this.moduleId);
			runInAction(() => {
				this.usersState = usersState;
			});
			this.initialized = true;
		} catch (error) {
			console.log(String(error));
		}
	}
}

let moduleUserGroupsStores: { [key: string]: ModuleUserGroupsStoreInner } = {};

export default function ModuleUserGroupsStore(
	moduleId: number
): ModuleUserGroupsStoreInner {
	if (moduleUserGroupsStores[moduleId] == null) {
		moduleUserGroupsStores[moduleId] = new ModuleUserGroupsStoreInner(
			moduleId
		);
	}
	return moduleUserGroupsStores[moduleId];
}