import React, { useRef, Component } from "react";
import {
    editUserPageApi,
    deleteUserPageApi,
    moveUserPageApi,
} from "common/UserApi";
import { Button } from "react-bootstrap";
import AutosizeInput from "react-input-autosize";
import { reaction } from "mobx";
import ls from "local-storage";
import { useDrag, useDrop } from "react-dnd";
import { observer } from "mobx-react";
import Cookies from "universal-cookie";
import AddPagePopup from "common/AddPagePopup";
import { mainStyle } from "common/MainStyle";
import MessagePopup from "common/MessagePopup";
import PagesStore from "common/PagesStore";
import { Permission } from "common/Permissions";
import PageType, { pageTypeToSection } from "common/PageType";
import Instrumentation from "common/Instrumentation";
import {
    editUserPageModuleApi,
    deleteUserPageModuleApi,
    moveUserPageModuleApi,
} from "common/ModulesApi";

const cookies = new Cookies();

function DraggablePage(props) {
    const pageId = props.pageId;
    const ref = useRef(null);

    const drag = useDrag({
        type: "page",
        item: {
            content: pageId,
        },
        end: (item, monitor) => {},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })[1];

    const drop = useDrop({
        accept: "page",
        drop(otherItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = otherItem.content;
            const hoverIndex = pageId;
            if (dragIndex === hoverIndex) {
                return;
            }
            props.onPagesSwap(pageId, otherItem.content);
        },
    })[1];
    if (pageId) drag(drop(ref));
    return <div ref={ref}>{props.children}</div>;
}

const PagesBar = observer(
    class PagesBar extends Component {
        constructor(props) {
            super(props);
            this.state = {
                pages: [],
                selectedPageId: ls.get(this.localStorageName()),
                selectedPageInitialized: false,
                editedPageId: undefined,
                needScroll: false,
                removingPopupShowed: false,
                addPagePopupOptions: undefined,
            };
            this.pagesRef = React.createRef();

            this.performance = null;
            this.swapPages = this.swapPages.bind(this);
        }
        canEditPage(pageId) {
            return (
                !this.props.frozen &&
                PagesStore(this.props.type).permission(pageId) >
                    Permission.ReadOnly
            );
        }
        localStorageName() {
            return "lastPageId".concat(String(this.props.type));
        }

        reloadPages(pages, additionalPages) {
            let lastPageId = this.state.selectedPageId;
            pages = additionalPages.concat(pages);
            if (pages.length === 0) {
                this.setState({ pages: [], selectedPageId: undefined }, () => {
                    ls.remove(this.localStorageName());
                    this.props.onPageChange(this.state.selectedPageId);
                });
                return;
            } else {
                let pageExists = pages
                    .map((page) => page.id)
                    .includes(lastPageId);
                this.setState(
                    {
                        pages: pages,
                        selectedPageId: pageExists ? lastPageId : pages[0].id,
                    },
                    () => {
                        if (
                            !this.state.selectedPageInitialized ||
                            !pageExists
                        ) {
                            ls.set(
                                this.localStorageName(),
                                this.state.selectedPageId
                            );
                            this.props.onPageChange(this.state.selectedPageId);
                            this.setState({ selectedPageInitialized: true });
                        }
                    }
                );
            }
        }

        selectPage(pageId, propagateSelection = true) {
            if (this.state.selectedPageId !== pageId) {
                this.performance = new Date();
                this.setState({ selectedPageId: pageId });
                if (!this.props.custom) ls.set(this.localStorageName(), pageId);
                if (propagateSelection) this.props.onPageChange(pageId);
            }
        }

        swapPages(pageId, swapId) {
            this.performance = new Date();
            if (pageId > 0 && swapId > 0) {
                let promise;
                if (
                    PagesStore(PageType.Canvases).module(pageId) != null ||
                    PagesStore(PageType.Canvases).module(swapId) != null
                ) {
                    // This is not a typo, page ids should be in this order
                    promise = moveUserPageModuleApi(swapId, pageId);
                } else {
                    promise = moveUserPageApi(pageId, swapId);
                }
                promise
                    .then(() => {
                        PagesStore(this.props.type).updatePages();
                    })
                    .catch((error) => {
                        if (this.props.onError != null) {
                            this.props.onError(error);
                        }
                    });
            }
        }

        componentDidMount() {
            if (!this.props.custom) {
                let additionalPages = this.props.additionalPages ?? [];
                this.pagesReaction = reaction(
                    () => PagesStore(this.props.type).pages,
                    (pages) => {
                        this.reloadPages(
                            pages.filter(
                                (page) =>
                                    this.props.currentModuleId == null ||
                                    page.module_id ===
                                        this.props.currentModuleId
                            ),
                            additionalPages
                        );
                    }
                );
                if (PagesStore(this.props.type).initialized)
                    this.reloadPages(
                        PagesStore(this.props.type).pages.filter(
                            (page) =>
                                this.props.currentModuleId == null ||
                                page.module_id === this.props.currentModuleId
                        ),
                        additionalPages
                    );
            } else {
                this.setState(
                    {
                        pages: this.props.additionalPages,
                        selectedPageId: this.props.additionalPages[0]?.id,
                    },
                    () => {
                        this.props.onPageChange(this.state.selectedPageId);
                    }
                );
            }
            window.addEventListener("resize", this.updateDimensions);
        }

        componentWillUnmount() {
            if (this.pagesReaction)
            this.pagesReaction();
            window.removeEventListener("resize", this.updateDimensions);
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (this.performance != null) {
                let timeMs = new Date() - this.performance;
                this.performance = null;
                Instrumentation.addInteraction(
                    pageTypeToSection[this.props.type],
                    timeMs
                );
            }
        }

        updateDimensions = () => {
            if (this.pagesRef.current)
                this.setState({
                    needScroll:
                        this.pagesRef.current.scrollWidth >
                        this.pagesRef.current.clientWidth,
                });
        };
        editPage(title, pageId) {
            let newPages = Array.from(this.state.pages);
            let index = newPages.findIndex((element, index, array) => {
                return String(element.id) === String(pageId);
            });
            if (index < 0) return;
            newPages[index].title = title;
            this.setState({ newPages: newPages });
        }

        updatePage(title, pageId, moduleId) {
            if (title.length > 0) {
                if (moduleId == null) {
                    editUserPageApi(title, pageId)
                        .then(() => {
                            this.setState({ editedPageId: undefined }, () => {
                                PagesStore(this.props.type).updatePages();
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({ editedPageId: undefined }, () => {
                                PagesStore(this.props.type).updatePages();
                            });
                        });
                } else {
                    editUserPageModuleApi(
                        title,
                        pageId,
                        cookies.get("instrumentation_session_id")
                    )
                        .then(() => {
                            this.setState({ editedPageId: undefined }, () => {
                                PagesStore(this.props.type).updatePages();
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({ editedPageId: undefined }, () => {
                                PagesStore(this.props.type).updatePages();
                            });
                        });
                }
            } else {
                this.setState({ editedPageId: undefined }, () => {
                    PagesStore(this.props.type).updatePages();
                });
            }
        }

        buildItem(title, pageId, moduleId, isCanvasPage) {
            let selected = pageId === this.state.selectedPageId;
            let editMode = pageId === this.state.editedPageId;
            let hoveredMode = pageId === this.state.hoveredPageId;
            let readOnly = !editMode || !this.canEditPage(pageId);
            // let isFirefox =
            //     navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

            return (
                <DraggablePage
                    key={pageId}
                    pageId={pageId}
                    onPagesSwap={this.swapPages}
                >
                    <div
                        style={{
                            cursor: "pointer",
                            marginRight: "10px", // "24px",
                        }}
                        className="my-row"
                        onClick={(evt) => {
                            if (!editMode) {
                                this.selectPage(pageId);
                            }
                        }}
                        onMouseLeave={(evt) => {
                            this.setState({
                                hoveredPageId: undefined,
                            });
                        }}
                        onMouseEnter={(evt) => {
                            this.setState({
                                hoveredPageId: pageId,
                            });
                        }}
                    >
                        <div
                            className="flex-simple-column"
                            style={{ width: "100%" }}
                        >
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "flex-end",
                                    height: "24px",
                                }}
                            >
                                <div
                                    style={
                                        {
                                            /*marginRight: isFirefox ? "-12px" : "0px",*/
                                        }
                                    }
                                >
                                    <AutosizeInput
                                        readOnly={readOnly}
                                        placeholder={"Enter page name"}
                                        inputClassName="tab-page"
                                        inputStyle={{
                                            maxWidth: "300px",
                                            textTransform: "uppercase",
                                            color: selected
                                                ? mainStyle.getPropertyValue(
                                                      "--secondary-text-color"
                                                  )
                                                : mainStyle.getPropertyValue(
                                                      "--primary-text-color"
                                                  ),
                                        }}
                                        onClick={(evt) => {
                                            if (evt.detail === 1) {
                                                if (editMode) {
                                                    evt.stopPropagation();
                                                }
                                            } else if (evt.detail === 2) {
                                                if (!this.props.frozen)
                                                    this.setState({
                                                        editedPageId: pageId,
                                                    });
                                            }
                                        }}
                                        value={title}
                                        onChange={(evt) => {
                                            if (!this.props.frozen)
                                                this.editPage(
                                                    evt.target.value,
                                                    pageId
                                                );
                                        }}
                                        onBlur={(evt) => {
                                            if (
                                                !this.props.frozen &&
                                                editMode
                                            ) {
                                                this.performance = new Date();
                                                this.updatePage(
                                                    evt.target.value,
                                                    pageId,
                                                    moduleId
                                                );
                                            }
                                        }}
                                        onKeyDown={(evt) => {
                                            // 13 === enter
                                            if (
                                                evt.which === 13 &&
                                                !evt.shiftKey
                                            ) {
                                                this.performance = new Date();
                                                evt.preventDefault();
                                                if (
                                                    !this.props.frozen &&
                                                    editMode
                                                )
                                                    this.updatePage(
                                                        evt.target.value,
                                                        pageId,
                                                        moduleId
                                                    );
                                            }
                                        }}
                                    />
                                </div>

                                {pageId &&
                                this.canEditPage(pageId) &&
                                hoveredMode ? (
                                    <>
                                        <span
                                            title={"Edit"}
                                            style={{
                                                cursor: "pointer",
                                                transform: "scale(0.5)",
                                                display: "flex",
                                                alignItems: "flex-end",
                                            }}
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                this.setState({
                                                    addPagePopupOptions: {
                                                        moduleId: moduleId,
                                                        title: title,
                                                        pageId: pageId,
                                                        type: this.props.type,
                                                    },
                                                });
                                            }}
                                        >
                                            <svg
                                                style={{
                                                    fill: mainStyle.getPropertyValue(
                                                        "--secondary-content-color"
                                                    ),
                                                }}
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z" />
                                            </svg>
                                        </span>
                                        <Button
                                            title={`Delete ${
                                                isCanvasPage
                                                    ? "section"
                                                    : "page"
                                            }`}
                                            style={{
                                                /* marginLeft: isFirefox ? "12px" : 0, */
                                                width: "14px",
                                                height: "24px",
                                            }}
                                            className="close"
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                this.setState({
                                                    removingPageId: pageId,
                                                    removingPopupShowed: true,
                                                });
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "21px",
                                                    color: mainStyle.getPropertyValue(
                                                        "--secondary-text-color"
                                                    ),
                                                }}
                                            >
                                                &times;
                                            </span>
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <span
                                            style={{ transform: "scale(0.5)" }}
                                        >
                                            <div
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                }}
                                            />
                                        </span>
                                        <div
                                            style={{
                                                /* marginLeft: isFirefox ? "12px" : 0, */
                                                width: "14px",
                                                height: "24px",
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                            {selected && (
                                <div
                                    style={{
                                        height: 4,
                                        width: "calc(100% - 24px - 14px)",
                                        backgroundColor: mainStyle.getPropertyValue(
                                            "--secondary-content-color"
                                        ),
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </DraggablePage>
            );
        }

        buildAddPagePopup() {
            if (!this.state.addPagePopupOptions) return null;
            return (
                <AddPagePopup
                    onClose={() => {
                        this.setState({ addPagePopupOptions: undefined });
                    }}
                    {...this.state.addPagePopupOptions}
                />
            );
        }
        buildPageRemovePopup(isCanvasPage) {
            if (!this.state.removingPopupShowed) return null;

            let deleteApi =
                PagesStore(PageType.Canvases).module(
                    this.state.removingPageId
                ) == null
                    ? deleteUserPageApi
                    : deleteUserPageModuleApi;
            return (
                <MessagePopup
                    danger
                    acceptButtonTitle={"Delete"}
                    title={`Delete ${
                        isCanvasPage ? "section" : "page"
                    }`}
                    message={`Do you want to delete this ${
                        isCanvasPage ? "section" : "page"
                    }?`}
                    onAccept={() => {
                        this.performance = new Date();
                        deleteApi(
                            this.state.removingPageId,
                            cookies.get("instrumentation_session_id")
                        )
                            .then(() => {
                                if (
                                    this.state.removingPageId ===
                                    this.state.selectedPageId
                                ) {
                                    let index = this.state.pages.findIndex(
                                        (element, index, array) => {
                                            return (
                                                element.id ===
                                                this.state.removingPageId
                                            );
                                        }
                                    );
                                    if (index > -1) {
                                        if (
                                            index <
                                            this.state.pages.length - 1
                                        ) {
                                            index = index + 1;
                                        } else {
                                            index = index - 1;
                                        }
                                        if (index > -1) {
                                            let pageId = this.state.pages[index]
                                                .id;
                                            this.setState({
                                                selectedPageId: pageId,
                                            });
                                            ls.set(
                                                this.localStorageName(),
                                                pageId
                                            );
                                            this.props.onPageChange(pageId);
                                        } else {
                                            ls.remove(this.localStorageName());
                                            this.props.onPageChange(undefined);
                                        }
                                    }
                                }
                                PagesStore(this.props.type).updatePages();
                                this.dismissPageRemovePopup();
                            })
                            .catch((error) => {
                                console.log(error);
                                this.dismissPageRemovePopup();
                            });
                    }}
                    onReject={() => {
                        this.dismissPageRemovePopup();
                    }}
                />
            );
        }
        dismissPageRemovePopup() {
            this.setState({
                removingPopupShowed: false,
                removingPageId: undefined,
            });
        }
        render() {
            let topButtonsMargin = 3;
            let needScroll = false;
            if (this.pagesRef.current) {
                needScroll =
                    this.pagesRef.current.scrollWidth >
                    this.pagesRef.current.clientWidth;
            }
            let isCanvasPage = this.props.type === PageType.Canvases;
            let justifyCenter = this.props.justifyCenter ?? false;
            return (
                <div
                    className="my-row"
                    style={{
                        alignItems: "flex-start",
                        justifyContent: justifyCenter ? "center" : "flex-start",
                    }}
                >
                    {needScroll && (
                        <Button
                            className="btn-small-like-select"
                            style={{
                                marginTop: topButtonsMargin,
                                width: "25px",
                                height: "25px",
                                marginRight: 10,
                                backgroundColor: "transparent",
                                color: mainStyle.getPropertyValue(
                                    "--primary-text-color"
                                ),
                            }}
                            onClick={(evt) => {
                                this.pagesRef.current.scrollLeft -= 200;
                            }}
                        >
                            {"<<"}
                        </Button>
                    )}
                    <div
                        className="my-row hide-scroll"
                        ref={this.pagesRef}
                        style={{
                            overflow: "auto hidden",
                            alignItems: "center",
                        }}
                    >
                        {this.state.pages.map((item) => {
                            return this.buildItem(
                                item.title,
                                item.id,
                                item.module_id,
                                isCanvasPage
                            );
                        })}
                    </div>
                    {needScroll && (
                        <Button
                            className="btn-small-like-select"
                            style={{
                                marginTop: topButtonsMargin,
                                marginLeft: 10,
                                width: "25px",
                                height: "25px",
                                marginRight: 10,
                                backgroundColor: "transparent",
                                color: mainStyle.getPropertyValue(
                                    "--primary-text-color"
                                ),
                            }}
                            onClick={(evt) => {
                                this.pagesRef.current.scrollLeft += 200;
                            }}
                        >
                            {">>"}
                        </Button>
                    )}
                    {!this.props.frozen &&
                        (this.props.type !== PageType.Canvases ||
                            this.props.currentModuleId != null) && (
                            <Button
                                title={`Add ${
                                    isCanvasPage ? "section" : "page"
                                }`}
                                className="btn-small-like-select"
                                style={{
                                    marginTop: topButtonsMargin,
                                    backgroundColor: "transparent",
                                    width: "25px",
                                    height: "25px",
                                    color: mainStyle.getPropertyValue(
                                        "--secondary-text-color"
                                    ),
                                }}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    this.setState({
                                        addPagePopupOptions: {
                                            moduleId: this.props
                                                .currentModuleId,
                                            title: "",
                                            pageId: null,
                                            type: this.props.type,
                                        },
                                    });
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>
                        )}
                    {this.buildPageRemovePopup(isCanvasPage)}
                    {this.buildAddPagePopup()}
                </div>
            );
        }
    }
);

export default PagesBar;
