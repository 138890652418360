import React, { Component } from "react";
import {
    DropTarget,
    DropTargetMonitor,
    DropTargetConnector,
    ConnectDropTarget,
} from "react-dnd";

interface Props {
    position: string;
    hovered: boolean;
    isDropTarget: boolean;
    connectDropTarget: ConnectDropTarget;
    onClick: () => void;
    onDoubleClick?: () => void;
    onSlideCardDropAreaEnter?: (
        position: string,
    ) => void;
}

class SlideCardDropArea extends Component<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.hovered !== this.props.hovered && this.props.hovered) {
            this.props.onSlideCardDropAreaEnter?.(this.props.position);
        }
    }

    public render(): JSX.Element | null {
        const { connectDropTarget, position } = this.props;
        const styles = position === "top"
            ? {
                top: 0,
                borderTop: this.props.hovered ? "2px solid #629BD4" : "2px solid transparent",
            }
            : {
                bottom: 0,
                borderBottom: this.props.hovered ? "2px solid #629BD4" : "2px solid transparent",
            };

        if (this.props.isDropTarget) {
            return connectDropTarget(
                <div
                    style={{
                        width: "100%",
                        height: "50%",
                        position: "absolute",
                        zIndex: 99,
                        ...styles,
                    }}
                    onClick={this.props.onClick}
                ></div>
            );
        } else {
            return (
                <div
                    style={{
                        width: "100%",
                        height: "15px",
                        position: "absolute",
                        zIndex: 99,
                        ...styles,
                    }}
                    onDoubleClick={this.props.onDoubleClick}
                ></div>
            );
        }
    }
}

const collect = (connect: DropTargetConnector, monitor: DropTargetMonitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        hovered: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
    }
}

export default DropTarget("slide", {}, collect)(SlideCardDropArea);
