import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import NotificationIcon from "common/NotificationIcon";
import { UserIcon } from "common/UserIcon";
import { mainStyle } from "common/MainStyle";
import { Story } from "./Story";

interface Props {
	story: Story;
	canEdit?: boolean;
	frozen: boolean;
	acceptDrag: string;
	acceptDrop: string | string[];
	onCardClicked: (story: Story) => void;
	onShareClicked: (story: Story) => void;
	onCrossClicked: (story: Story) => void;
	onCardsSwapped: (story1: Story, story2: Story) => void;
}

export default function StoriesGridCard(props: Props) {
	let {
		story,
		onCardClicked,
		onShareClicked,
		onCrossClicked,
		canEdit,
		frozen,
		onCardsSwapped,
		acceptDrag,
		acceptDrop,
	} = props;
	let insights = story.insights.filter(
		(insight) => insight.contents.type !== "subtitle"
	);
	const ref = useRef(null);
	// [{ isDragging }, drag]

	const drag = useDrag({
		item: {
			content: story,
		},
		end: (_item, _monitor) => {},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		type: acceptDrag,
	})[1];

	const drop = useDrop<
		{
			type: string;
			content: Story;
		},
		void,
		void
	>({
		accept: acceptDrop,
		drop(otherItem, _monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = otherItem.content.id;
			const hoverIndex = story.id;
			if (dragIndex === hoverIndex) {
				return;
			}
			onCardsSwapped(story, otherItem.content);
		},
	})[1];
	drag(drop(ref));

	let hasThumbnail = insights.length > 0 && insights[0].contents.imageSrc;
	return (
		<div
			ref={ref}
			key={story.id}
			className="dashboard-rect-card flex-column"
			onClick={(_evt) => {
				onCardClicked(story);
			}}
			style={{
				width: 357,
				minHeight: 324,
				cursor: "pointer",
				position: "relative",
			}}
		>
			{story.has_new_feedbacks && <NotificationIcon />}
			<div className="flex-simple-column" style={{ height: "40px" }}>
				<div
					style={{
						justifyContent: "flex-end",
						display: "flex",
						height: "16px",
						width: "100%",
					}}
				/>
				{canEdit && (
					<div
						title={"Delete story"}
						style={{
							alignSelf: "flex-end",
							marginRight: "18px",
						}}
						onClick={(evt) => {
							evt.stopPropagation();
							onCrossClicked(story);
						}}
					>
						<img
							alt=""
							src="/dist/img/insights/insights_remove.png"
							style={{ cursor: "pointer" }}
						/>
					</div>
				)}
			</div>
			<div
				className="flex-column"
				style={{
					alignItems: "center",
					margin: "20px",
					flexGrow: 1,
				}}
			>
				{hasThumbnail && (
					<img
						alt=""
						src={insights[0].contents.imageSrc}
						style={{
							width: "250px",
							height: "125px",
							objectFit: "contain",
						}}
					/>
				)}
				<span
					className="line-clamp"
					style={{
						maxHeight: "45px",
						marginLeft: 10,
						marginRight: 10,
						width: "100%",
						marginTop: hasThumbnail ? "28px" : "0px",
						color: story.title
							? mainStyle.getPropertyValue(
									"--content-primary-text-color"
							  )
							: "#20293C",
						fontFamily: "Roboto",
						fontSize: "20px",
						fontWeight: 700,
						lineHeight: "23px",
						textAlign: "center",
					}}
				>
					{story.title}
				</span>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginLeft: "21px",
					marginBottom: "14px",
				}}
			>
				{story.user_data && (
					<UserIcon
						width={22}
						height={22}
						fontSize={10}
						user={story.user_data}
						iconUrl={story.user_data.icon_url}
					/>
				)}
				<div
					className="flex-simple-column"
					style={{ marginLeft: "5px" }}
				>
					<span
						style={{
							minHeight: "14px",
							color: mainStyle.getPropertyValue(
								"--content-primary-text-color"
							),
							fontFamily: "Arial",
							fontSize: 10,
						}}
					>
						{story.user_data
							? story.user_data.first_name
									.concat(" ")
									.concat(story.user_data.last_name)
							: ""}
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--content-secondary-text-color"
							),
							fontFamily: "Arial",
							fontSize: 10,
						}}
					>
						{story.creation_time || ""}
					</span>
				</div>
				<div style={{ flexGrow: 1 }} />
				{!frozen && (
					<div
						title={"Share story"}
						style={{ marginRight: "18px" }}
						onClick={(evt) => {
							evt.stopPropagation();
							onShareClicked(story);
						}}
					>
						<img
							alt=""
							src="/dist/img/insights/insights_share.png"
							style={{ cursor: "pointer" }}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
