import React, { useState, useEffect } from 'react';
import CursorSVG from './CursorSVG';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { render } from 'react-dom';

interface ShowTypingProps {
  msg: string;
  txt: string;
  pic: string;
  vid: string;
  custom?: () => JSX.Element;
  renderCustom?: boolean;
  loading?: boolean;
}

const ShowTyping: React.FC<ShowTypingProps> = ({ msg , txt, pic, vid, custom, renderCustom, loading}) => {  
  const [displayResponse, setDisplayResponse] = useState("");
  const [completedTyping, setCompletedTyping] = useState(false);

  useEffect(() => {
    setCompletedTyping(false);

    let i = 0;
    const stringResponse = msg + txt;
    
    const intervalId = setInterval(() => {
      setDisplayResponse(stringResponse.slice(0, i));
      i++;

      if (i > stringResponse.length) {
        clearInterval(intervalId);
        setCompletedTyping(true);        
      }
    }, 35);
    
    console.log(stringResponse);
    
    return () => clearInterval(intervalId);
  }, [msg]);
  
  // Custom Link component styled as a button
  type LinkProps = {
    href: string;
    children: React.ReactNode;
  };

  const components = {
    h2: ({node, ...props}: any) => <h2 className="center-align" {...props} />,
    h3: ({node, ...props}: any) => <h3 className="center-align" {...props} />,
    a: ({node, ...props}: any) => <a target="_blank" {...props} />,
  };

  return (
    (loading)?
      <CursorSVG /> :    
      <>      
        <ReactMarkdown
          //remarkPlugins={[remarkGfm]}
          components={/*{
            h2: ({node, ...props}) => <h2 className="center-align" {...props} />,
            h3: ({node, ...props}) => <h3 className="center-align" {...props} />,
          }*/
          components
        }
        >
          {displayResponse}
        </ReactMarkdown>
        {displayResponse === "" && <CursorSVG />}
        {!completedTyping && <CursorSVG />}
        
        {/* Conditional rendering based on txt, pic, and vid props */}    
        {/*completedTyping && txt && <p>{txt}</p>*/}
        {completedTyping && pic && (pic !== "nan") && <p><img src={pic} alt="This is a picture!" /></p>}
        {completedTyping && vid && (vid !== "nan") && (
          <p>
            <video src={vid} controls>
            Your browser does not support the video tag.
            </video>
          </p>
        )}
        {renderCustom && custom &&
          <React.Fragment key="custom-component">
            {custom()}
          </React.Fragment>
        }
      </>
  );

};

export default ShowTyping;