import React from "react";
import Preset from "../Preset";
import {
    Entry,
    guidanceWizardEntryGetApi,
} from "../../Api";
import styles from "./ShareSession.module.css";
import Alert from "common/Alert";

interface Props {
    entryId: number;
}

const EditSession: React.FC<Props> = (props) => {
    const [entry, setEntry] = React.useState<Entry | undefined>(undefined);
    const [error, setError] = React.useState<string | undefined>(undefined);

    // Fetch the entry
    React.useEffect(() => {
        if (props.entryId != null) {
            guidanceWizardEntryGetApi(props.entryId)
                .then(setEntry)
                .catch((error) => {
                    console.log(error);
                    setError(String(error));
                });
        }
    }, [props.entryId]);
    return (
        <div className={styles.fullScreenContainer}>
            {entry != null && (
                <div className={styles.previewContainer}>
                    <Preset
                        entryId={entry.id}
                        dataScopeId={entry.dataScopeId}
                        title={entry.title}
                        logo={entry.logo}
                    />
                </div>
            )}
            {entry == null && error != null && (
                <Alert
                    text={"Error: ".concat(error)}
                    className="alert alert-danger"
                />
            )}
        </div>
    );
};

export default EditSession;
