import React from "react";
import { mainStyle } from "common/MainStyle";
import SectionIconsContainer from "common/SectionIconsContainer";

interface Props {
    width?: number;
    title: string;
    id: number;
    subtitle?: string;
    onClick: () => void;
}

export default function CanvasCard(props: Props) {
    return (
        <div
            className="dashboard-rect"
            style={{
                width: props.width ?? 407,
                height: 71,
                marginTop: 10,
                marginBottom: 10,
                cursor: "pointer",
            }}
            onClick={() => {
                props.onClick();
            }}
        >
            <div style={{ position: "relative", height: "100%" }}>
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <div
                        className="my-row"
                        style={{
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <div
                            style={{
                                marginLeft: 30,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <SectionIconsContainer
                                options={{
                                    className: "sidebar-icon",
                                    style: { fill: "#39F", stroke: "#39F" },
                                }}
                                type={"Canvas"}
                            />
                        </div>
                        <span
                            className="line-clamp"
                            style={{
                                marginLeft: 30,
                                fontFamily: "Roboto",
                                fontSize: mainStyle.getPropertyValue(
                                    "--path-header-size"
                                ),
                                color: mainStyle.getPropertyValue(
                                    "--primary-text-color"
                                ),
                                fontWeight: 300,
                            }}
                        >
                            {props.title}
                        </span>
                        <div style={{ flexGrow: 1 }} />

                        <img
                            alt=""
                            src="/dist/img/data-exploration/chevron.png"
                            style={{
                                marginRight: 10,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
