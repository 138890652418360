/**
 * This is a custom hook to lock scrolling into only one direction 
 * for a given html element
 * @param ref reference to html element to be locked
 * If this causes any performance issues refer to:
 * https://stackoverflow.com/questions/42112158/is-there-a-way-to-detect-horizontal-scroll-only-without-triggering-a-browser-ref
 */

import React from "react";


export function useLockScrollDirection(ref: any) {
    let scrollLeft = 0;
    let scrollTop = 0;
    
    React.useEffect(() => {
        function checkScrollDirection(event: any) {
            const container = ref.current as any;
            if (container.scrollTop !== scrollTop) {
                // Vertical scrolling
                container.style.overflowY = 'auto';
                container.style.overflowX = 'hidden';
                scrollTop = container.scrollTop;
            }
             else if (container.scrollLeft !== scrollLeft) {
                // Horizontal scrolling
                container.style.overflowX = 'auto';
                container.style.overflowY = 'hidden';
                scrollLeft = container.scrollLeft;
            }
        }

        function resetScroll(event: any) {
            const container = ref.current as any;

            container.style.overflowY = 'auto';
            container.style.overflowX = 'auto';

        }
 
        if (ref.current) {
            (ref.current as any).addEventListener('scroll', checkScrollDirection);
            (ref.current as any).addEventListener('scrollend', resetScroll);

        }

        return () => {
            if (ref.current) {
                (ref.current as any).removeEventListener('scroll', checkScrollDirection);
                (ref.current as any).removeEventListener('scrollend', resetScroll);
            }
        };
    }, [ref]);
}