import React, { Component } from "react";
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { Resizable } from "re-resizable";
import elements from "common/CanvasElements";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import GraphElementView from "../GraphElementView";
import {
    GraphElement,
    GraphNode,
    GraphEdge,
    InnerCanvasChanges,
} from "common/Canvas";
import GraphEditPopup from "../GraphEditPopup";
import HtmlElementProps from "./HtmlElementProps";
import {
    getNewSizeAfterResize,
    changeElementWhenResize,
} from "../BaseCanvasResizableFunctions";
import axios from "common/ServerConnection";
import remoteModuleId from "common/remoteModuleId";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../Snap";

const typeName = "graphElementsState";
interface InnerProps extends HtmlElementProps {
    graphId: string;
    rootDataTestId: string;
    htmlElementsRootRef: HTMLDivElement | undefined;
}

interface InnerState {
    showEditPopupForGraphId: string | undefined;
    hovered: boolean;
}

@observer
class GraphElementWrapper extends CanvasInteractionComponent<
    InnerProps,
    InnerState
> {
    drag: boolean;
    constructor(props: InnerProps) {
        super(props);
        this.state = {
            showEditPopupForGraphId: undefined,
            hovered: false,
        };
        this.drag = false;
        this.trackNewPerformance = this.trackNewPerformance.bind(this);
        this.changeGraphElement = this.changeGraphElement.bind(this);
    }
    changeGraphElement(graphElement: Partial<GraphElement>) {
        this.trackNewPerformance(elements.graph);
        this.props.canvasTreeStore.updateGraphElementAction(
            this.props.graphId,
            graphElement as GraphElement
        );
    }

    render() {
        let graphElement = this.props.canvasTreeStore.graphElementsState.get(
            this.props.graphId
        )!;
        let graphElementSize = {
            height: graphElement.height * this.props.scale,
            width: graphElement.width * this.props.scale,
        };

        return (
            <>
                <DraggableWithSnapping
                    key={this.props.graphId}
                    disabled={this.props.live || !this.props.canWrite}
                    cancel=".element-graph"
                    position={{
                        x: graphElement.x * this.props.scale,
                        y: graphElement.y * this.props.scale,
                    }}
                    onDrag={(_evt, _data) => {
                        this.drag = true;
                        let nearestPoints = this.props.onRebuildSnapLine(
                            {
                                x: _data.x,
                                y: _data.y,
                                width: graphElementSize.width,
                                height: graphElementSize.height,
                            },
                            {
                                type: typeName,
                                id: this.props.graphId,
                                groupId: graphElement.groupId,
                            }
                        );
                        let newPosition = snapElementToPoints(
                            graphElementSize.width,
                            graphElementSize.height,
                            nearestPoints
                        );
                        if (newPosition.x != null || newPosition.y != null) {
                            // Snap to this position
                            return newPosition;
                        }
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            this.props.onDeleteSnapLine();
                            this.trackNewPerformance(elements.graph);
                            let x = Math.max(data.x / this.props.scale, 0);
                            let y = Math.max(data.y / this.props.scale, 0);
                            let deltaX = x - graphElement.x;
                            let deltaY = y - graphElement.y;

                            let changes: InnerCanvasChanges = {};
                            this.props.canvasTreeStore.updateGraphElementAction(
                                this.props.graphId,
                                {
                                    x: x,
                                    y: y,
                                    width: graphElement.width,
                                    height: graphElement.height,
                                },
                                changes
                            );
                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                x: x,
                                y: y,
                                width: graphElement.width,
                                height: graphElement.height,
                            });
                            this.props.onMoveGroupSelection(
                                deltaX,
                                deltaY,
                                {
                                    id: this.props.graphId,
                                    type: typeName,
                                    groupId: graphElement.groupId,
                                },
                                false,
                                changes
                            );
                            this.props.canvasTreeStore.saveChangesAction(
                                changes,
                                true,
                                true,
                                false,
                                this.props.canvasTreeStore.backgroundsState.toJSON(),
                                BackgroundMode.Update,
                                false
                            );
                            this.drag = false;
                        }
                    }}
                >
                    <div
                        onContextMenu={(evt) => {
                            this.props.onContextMenu(
                                evt,
                                {
                                    id: this.props.graphId,
                                    type: typeName,
                                },
                                true
                            );
                        }}
                        style={{
                            top: 0,
                            left: 0,
                            position: "absolute",
                            zIndex: graphElement.zIndex ?? 50,
                        }}
                        onMouseEnter={() => {
                            this.setState({ hovered: true });
                        }}
                        onMouseLeave={() => {
                            this.setState({ hovered: false });
                        }}
                    >
                        <Resizable
                            className="selectable-by-pointer"
                            ref={(ref) => {
                                let innerRef = ref?.resizable;
                                if (innerRef != null) {
                                    innerRef.setAttribute("type", typeName);
                                    if (graphElement.groupId != null)
                                        innerRef.setAttribute(
                                            "groupId",
                                            graphElement.groupId
                                        );
                                    else {
                                        innerRef.removeAttribute("groupId");
                                    }
                                    innerRef.setAttribute(
                                        "id",
                                        String(this.props.graphId)
                                    );
                                    innerRef.setAttribute(
                                        "data-test-id",
                                        this.props.rootDataTestId
                                    );
                                }
                            }}
                            enable={
                                this.props.live || !this.props.canWrite
                                    ? {
                                          top: false,
                                          right: false,
                                          bottom: false,
                                          left: false,
                                          topRight: false,
                                          bottomRight: false,
                                          bottomLeft: false,
                                          topLeft: false,
                                      }
                                    : {
                                          top: true,
                                          right: true,
                                          bottom: true,
                                          left: true,
                                          topRight: true,
                                          bottomRight: true,
                                          bottomLeft: true,
                                          topLeft: true,
                                      }
                            }
                            onResizeStart={(evt) => {
                                evt.stopPropagation();
                            }}
                            onResize={(_e, _direction, _ref, d) => {
                                changeElementWhenResize(
                                    graphElement,
                                    this.props.scale,
                                    _direction,
                                    d,
                                    _ref
                                );
                            }}
                            onResizeStop={(_e, _direction, _ref, d) => {
                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;
                                this.trackNewPerformance(elements.graph);
                                let newSize = getNewSizeAfterResize(
                                    graphElement,
                                    this.props.scale,
                                    _direction,
                                    d
                                );
                                this.props.canvasTreeStore.updateGraphElementAction(
                                    this.props.graphId,
                                    newSize.size[canvasViewMode]
                                );
                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        x: newSize.x,
                                        y: newSize.y,
                                        ...newSize.size[canvasViewMode],
                                    }
                                );
                                this.props.onResize();
                            }}
                            size={graphElementSize}
                        >
                            <GraphElementView
                                onContextMenu={(evt) => {
                                    this.props.onContextMenu(
                                        evt,
                                        {
                                            id: this.props.graphId,
                                            type: typeName,
                                        },
                                        true
                                    );
                                }}
                                scale={this.props.scale}
                                sharedPolicy={this.props.sharedPolicy}
                                frozen={!this.props.canWrite}
                                height={graphElementSize.height}
                                live={this.props.live}
                                graphElement={graphElement}
                                graphElementId={this.props.graphId}
                                onChange={this.changeGraphElement}
                                onTrackNewPerformance={this.trackNewPerformance}
                                onDelete={() => {
                                    this.trackNewPerformance(elements.graph);
                                    this.props.showDeletePopup(() => {
                                        this.props.onClearEditing();
                                        this.props.canvasTreeStore.deleteGraphElementAction(
                                            this.props.graphId
                                        );
                                    });
                                }}
                                currentModuleId={this.props.currentModuleId}
                                hovered={this.state.hovered}
                                loading={false}
                                toggleOpenEditMenu={(graphId) => {
                                    this.setState({
                                        showEditPopupForGraphId: graphId,
                                    });
                                }}
                            />
                        </Resizable>
                    </div>
                </DraggableWithSnapping>
                {this.state.showEditPopupForGraphId != null && (
                    <GraphEditPopup
                        graphElement={
                            this.props.canvasTreeStore.graphElementsState.get(
                                this.state.showEditPopupForGraphId
                            )!
                        }
                        onClose={(apply, changes) => {
                            if (apply && changes != null) {
                                if (
                                    changes.dataSet != null &&
                                    changes.dataSet.dataScope != null &&
                                    changes.dataSet.tableOption != null &&
                                    changes.dataSet.nodeId != null &&
                                    changes.dataSet.x != null &&
                                    changes.dataSet.y != null &&
                                    changes.dataSet.adjacency != null
                                ) {
                                    axios
                                        .post<{
                                            success: boolean;
                                            error_msg: string;
                                            current_levels: {
                                                [key: string]: (
                                                    | number
                                                    | string
                                                    | null
                                                )[];
                                            };
                                            row_id: number[];
                                            count?: number;
                                        }>("/api/e/get_raw_data", {
                                            variable_indices: [
                                                ...new Set([
                                                    changes.dataSet.nodeId
                                                        .value,
                                                    changes.dataSet.x.value,
                                                    changes.dataSet.y.value,
                                                    changes.dataSet.adjacency
                                                        .value,
                                                    ...changes.dataSet.metrics.map(
                                                        (option) => option.value
                                                    ),
                                                ]),
                                            ],
                                            table: [],
                                            data_table_idx:
                                                changes.dataSet.dataScope.value,
                                            module_id:
                                                this.props.currentModuleId ??
                                                remoteModuleId,
                                        })
                                        .then((response) => {
                                            if (response.data.success) {
                                                const nodeIds =
                                                    response.data
                                                        .current_levels[
                                                        changes.dataSet!.nodeId!
                                                            .label
                                                    ];
                                                const x =
                                                    response.data
                                                        .current_levels[
                                                        changes.dataSet!.x!
                                                            .label
                                                    ];
                                                const y =
                                                    response.data
                                                        .current_levels[
                                                        changes.dataSet!.y!
                                                            .label
                                                    ];
                                                const adjacency =
                                                    response.data
                                                        .current_levels[
                                                        changes.dataSet!
                                                            .adjacency!.label
                                                    ];

                                                let nodes: {
                                                    [key: string]: GraphNode;
                                                } = {};
                                                let edges: {
                                                    [key: string]: GraphEdge;
                                                } = {};
                                                let edgeId = 0;
                                                for (
                                                    let i = 0;
                                                    i < nodeIds.length;
                                                    ++i
                                                ) {
                                                    if (
                                                        nodeIds[i] != null &&
                                                        x[i] != null &&
                                                        y[i] != null
                                                    ) {
                                                        let nodeId = nodeIds[
                                                            i
                                                        ] as number;
                                                        nodes[
                                                            nodeId.toString()
                                                        ] = {
                                                            id: nodeId,
                                                            x: x[i] as number,
                                                            y: y[i] as number,
                                                            title: "",
                                                            type: "defaultNode",
                                                        };
                                                        if (
                                                            changes.dataSet!
                                                                .metrics
                                                                .length !== 0
                                                        ) {
                                                            nodes[
                                                                nodeId.toString()
                                                            ].metrics = {};
                                                            for (let metric of changes.dataSet!
                                                                .metrics) {
                                                                nodes[
                                                                    nodeId.toString()
                                                                ].metrics![
                                                                    metric.label
                                                                ] =
                                                                    response.data.current_levels[
                                                                        metric.label
                                                                    ][i];
                                                            }
                                                        }
                                                        if (
                                                            adjacency[i] !=
                                                                null &&
                                                            adjacency[i] !== ""
                                                        ) {
                                                            for (let target of (adjacency[
                                                                i
                                                            ] as string).split(
                                                                ","
                                                            )) {
                                                                edges[
                                                                    edgeId.toString()
                                                                ] = {
                                                                    source: nodeId,
                                                                    target: Number(
                                                                        target
                                                                    ),
                                                                    type:
                                                                        "defaultEdge",
                                                                };
                                                                edgeId += 1;
                                                            }
                                                        }
                                                    }
                                                }
                                                this.props.canvasTreeStore.updateGraphElementAction(
                                                    this.props.graphId,
                                                    {
                                                        ...changes,
                                                        nodes: nodes,
                                                        edges: edges,
                                                    }
                                                );
                                            } else {
                                                console.log(
                                                    response.data.error_msg
                                                );
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                } else {
                                    this.props.canvasTreeStore.updateGraphElementAction(
                                        this.props.graphId,
                                        changes
                                    );
                                }
                            }
                            this.setState({
                                showEditPopupForGraphId: undefined,
                            });
                        }}
                    />
                )}
            </>
        );
    }
}

interface WrapperProps extends HtmlElementProps {
    htmlElementsRootRef: HTMLDivElement | undefined;
}

@observer
class GraphElements extends Component<WrapperProps> {
    public render(): JSX.Element {
        let graphUIs: JSX.Element[] = [];
        for (let graphId of this.props.canvasTreeStore.graphElementsState.keys()) {
            graphUIs.push(
                <GraphElementWrapper
                    rootDataTestId={`mapElementV1-${graphUIs.length + 1}`}
                    key={graphId}
                    graphId={graphId}
                    {...this.props}
                />
            );
        }
        return <>{graphUIs}</>;
    }
}

export default GraphElements;
