import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Popup from "reactjs-popup";
import Cookies from "universal-cookie";
import Select, { createFilter } from "react-select";
import { CSSTransition } from "react-transition-group";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Switch from "react-switch";
import { observer } from "mobx-react";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import { mainStyle } from "common/MainStyle";
import { GroupExtendedPermission } from "common/GroupPermissions";
import {
    permissionOptions,
    Permission,
    PermissionNames,
    PermissionOptionType,
} from "common/Permissions";
import UserGroupsStore, { UserGroupOption } from "common/UserGroupsStore";

import {
    customSelectStylesForSharePopup,
    customSelectStylesForSharePopupNoBorder,
} from "common/SelectStyles";
import {
    getModuleUserDataSetsApi,
    getModuleUserSharedLinkDataSetsApi,
    addOrEditModuleUserDataSetApi,
    deleteModuleUserDataSetApi,
    transferModuleUserOwnershipApi,
} from "common/ModulesApi";
import DataScopesForModules from "common/DataScopesForModules";
import DataSetsSelector, {
    DataSet,
    AccessType,
} from "common/canvas/DataSetsSelector";
import UserInviter from "common/canvas/UserInviter";
import {
    ModuleGroupExtendedPermission,
    editModuleApi,
} from "common/ModulesApi";
import { UserIcon, GroupIcon } from "common/UserIcon";
import CurrentModulesStore from "common/CurrentModulesStore";
import CurrentUser from "common/CurrentUser";
import StringUtils from "common/utilities/StringUtils";
import { shareModuleLink } from "common/ModulesApi";
import ModuleCreator from "./ModuleCreator";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import { addEmailInvitation } from "common/EmailInvitation";
import Instrumentation from "common/Instrumentation";
import AppCreator from "common/canvas/AppCreator";
import linkIdToUrl from "common/utilities/linkIdToUrl";
import { reaction } from "mobx";
import ModuleUserGroupsStore from "common/ModuleUserGroupsStore";
import MessagePopup from "common/MessagePopup";
import Portal from "common/Portal";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

const cookies = new Cookies();

const RemovePermission = -1;
const MakeOwner = -2;

export enum ShareType {
    Regular = 1,
    Template = 2,
    Link = 3,
    App = 4,
}

function linkIdToIFrame(
    requireAuthentication: boolean,
    linkId: string
): string {
    let url = linkIdToUrl(requireAuthentication, linkId);
    let iFrame = `<iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="${url}" allowfullscreen></iframe>`;
    return iFrame;
}

const checkIfEmailInString = (text: string) => {
    let re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    return re.test(text);
};

const shareTypes: { [key: string]: string } = {
    [ShareType.Regular]: "Share",
    [ShareType.Link]: "Publish to link",
    [ShareType.Template]: "Publish to template",
    [ShareType.App]: "Publish to app",
};

interface ShareProps {
    moduleId: number;
    onClose?: () => void;
}
interface RegularShareState {
    popupStatus: PopupStatus | undefined;
    newGroupInput: string;
    selectedNewGroup: UserGroupOption | null;
    selectedPermission: Permission | typeof MakeOwner;
    selectedUserGroups: ModuleGroupExtendedPermission[];
    selectedDataSetsByOtherUsers: DataSet[];
    selectedDataSets: DataSet[];
    selectedDataSet: DataSet | null;
    notSharedPreviously: boolean;
    message: string | undefined;
    sharedWithExpanded: boolean;
    // paywallOptionsExpanded: boolean;
    needSave: boolean;
    transferOwnershipPopup: ModuleGroupExtendedPermission | null;
}

@observer
class RegularShareComponent extends Component<ShareProps, RegularShareState> {
    moduleUserGroupsReaction: any;
    constructor(props: ShareProps) {
        super(props);
        this.state = {
            newGroupInput: "",
            needSave: false,
            popupStatus: undefined,
            message: undefined,
            selectedDataSetsByOtherUsers: [],
            selectedDataSets: [],
            selectedDataSet: null,
            selectedUserGroups: [],
            selectedPermission: Permission.ReadWrite,
            selectedNewGroup: null,
            notSharedPreviously: false,
            sharedWithExpanded: false,
            // paywallOptionsExpanded: false,
            transferOwnershipPopup: null,
        };
        this.inviteUser = this.inviteUser.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    private async inviteUser(): Promise<void> {
        let currentModule = CurrentModulesStore.getModule(this.props.moduleId)!;
        let newUserGroup: GroupExtendedPermission | null =
            this.state.selectedNewGroup != null
                ? {
                      group_name: this.state.selectedNewGroup.label,
                      group_id: this.state.selectedNewGroup.id,
                      permission_type: this.state.selectedPermission,
                  }
                : null;
        let selectedUserGroups: GroupExtendedPermission[] = this.state
            .selectedUserGroups;
        let containsNewGroup = true;
        if (newUserGroup != null) {
            containsNewGroup =
                selectedUserGroups.find(
                    (item) => item.group_id === newUserGroup!.group_id
                ) != null;
            if (!containsNewGroup)
                selectedUserGroups = selectedUserGroups.concat(newUserGroup);
        }
        if (!containsNewGroup) {
            if (this.state.selectedPermission === MakeOwner) {
                this.setState((state) => ({
                    transferOwnershipPopup: {
                        group_name: state.selectedNewGroup!.userInfo!.user_name,
                        group_id: state.selectedNewGroup!.userInfo!.group_id,
                        permission_type: Permission.ReadWrite,
                        personal: true,
                        user_info: state.selectedNewGroup!.userInfo,
                        invited: false,
                    },
                }));
                return;
            }
            try {
                await editModuleApi(
                    this.props.moduleId,
                    undefined,
                    selectedUserGroups
                        .filter(
                            (permission) =>
                                permission != null &&
                                permission.permission_type !== RemovePermission
                        )
                        .map((permission) => ({
                            group_id: permission.group_id,
                            permission_type: permission.permission_type,
                        })),
                    cookies.get("instrumentation_session_id")
                );
                this.setState({
                    selectedNewGroup: null,
                    message: "Accessed user groups changed",
                    popupStatus: PopupStatus.Success,
                });
            } catch (error) {
                this.setState({
                    popupStatus: PopupStatus.Error,
                    message: String(error),
                });
                return;
            }
        } else if (this.state.newGroupInput) {
            if (this.state.selectedPermission === MakeOwner) {
                this.setState({
                    popupStatus: PopupStatus.Error,
                    message: "Can't make the invited user the owner",
                });
            }
            try {
                if (checkIfEmailInString(this.state.newGroupInput)) {
                    await addEmailInvitation(
                        this.props.moduleId,
                        undefined,
                        this.state.selectedPermission,
                        this.state.newGroupInput
                    );
                    this.setState({
                        newGroupInput: "",
                        message: "Invite sent",
                        popupStatus: PopupStatus.Success,
                    });
                } else {
                    this.setState({
                        newGroupInput: "",
                        message:
                            "Please enter a valid user name or email address",
                        popupStatus: PopupStatus.Error,
                    });
                }
            } catch (error) {
                this.setState({
                    popupStatus: PopupStatus.Error,
                    message: String(error),
                });
                return;
            }
        }
        ModuleUserGroupsStore(this.props.moduleId).updateUserGroups();
        if (this.state.notSharedPreviously) {
            Instrumentation.addModuleShared(currentModule.title);
            this.setState({
                notSharedPreviously: false,
            });
        }
    }

    private async saveChanges() {
        let selectedUserGroups: GroupExtendedPermission[] = this.state
            .selectedUserGroups;
        let currentModule = CurrentModulesStore.getModule(this.props.moduleId)!;
        try {
            await editModuleApi(
                this.props.moduleId,
                undefined,
                selectedUserGroups
                    .filter(
                        (permission) =>
                            permission != null &&
                            permission.permission_type !== RemovePermission
                    )
                    .map((permission) => ({
                        group_id: permission.group_id,
                        permission_type: permission.permission_type,
                    })),
                cookies.get("instrumentation_session_id")
            );
            if (this.state.notSharedPreviously) {
                Instrumentation.addModuleShared(currentModule.title);
                this.setState({
                    notSharedPreviously: false,
                });
            }
            this.setState({
                needSave: false,
                popupStatus: PopupStatus.Success,
                message: "Changes applied",
            });
            ModuleUserGroupsStore(this.props.moduleId).updateUserGroups();
        } catch (error) {
            this.setState({
                popupStatus: PopupStatus.Error,
                message: String(error),
            });
        }
    }

    private async addOrEditDataSet(
        dataScopeId: number | string,
        accessType: AccessType
    ): Promise<void> {
        let permissionType =
            accessType === AccessType.CanEdit
                ? Permission.ReadWrite
                : Permission.ReadOnly;
        let allowUploading =
            accessType === AccessType.CanSubmit ||
            accessType === AccessType.CanEdit;
        let updateId = new Cookies().get("instrumentation_session_id");
        try {
            await addOrEditModuleUserDataSetApi(
                this.props.moduleId,
                dataScopeId,
                permissionType,
                allowUploading,
                true,
                false,
                undefined,
                updateId
            );
            this.setState({
                popupStatus: PopupStatus.Success,
                message: "Dataset added successfully",
            });
        } catch (error) {
            this.setState({
                popupStatus: PopupStatus.Error,
                message: String(error),
            });
            return;
        }
        DataScopesForModules(this.props.moduleId).update();
    }

    private async deleteDataSet(dataScopeId: number | string): Promise<void> {
        let updateId = new Cookies().get("instrumentation_session_id");
        try {
            await deleteModuleUserDataSetApi(
                this.props.moduleId,
                dataScopeId,
                false,
                undefined,
                updateId
            );
            this.setState({
                popupStatus: PopupStatus.Success,
                message: "Dataset removed successfully",
            });
        } catch (error) {
            this.setState({
                popupStatus: PopupStatus.Error,
                message: String(error),
            });
            return;
        }
        DataScopesForModules(this.props.moduleId).update();
    }

    private assignUserGroups(
        userGroups: ModuleGroupExtendedPermission[]
    ): void {
        this.setState({
            selectedUserGroups: userGroups,
            notSharedPreviously: userGroups.length === 0,
        });
    }
    private updateDataSets(): void {
        getModuleUserDataSetsApi(this.props.moduleId, false)
            .then((dataSets) => {
                let selectedDataSets: DataSet[] = [];
                let selectedDataSetsByOtherUsers: DataSet[] = [];
                for (let dataSet of dataSets) {
                    if (dataSet.user === CurrentUser.info?.user_name) {
                        selectedDataSets.push({
                            label: dataSet.data_table_name,
                            value: dataSet.data_table_idx,
                            accessType:
                                dataSet.permission_type === Permission.ReadWrite
                                    ? AccessType.CanEdit
                                    : dataSet.allow_uploading
                                    ? AccessType.CanSubmit
                                    : AccessType.CanRead,
                        });
                    } else {
                        selectedDataSetsByOtherUsers.push({
                            label: dataSet.data_table_name,
                            value: dataSet.data_table_idx,
                            accessType:
                                dataSet.permission_type === Permission.ReadWrite
                                    ? AccessType.CanEdit
                                    : dataSet.allow_uploading
                                    ? AccessType.CanSubmit
                                    : AccessType.CanRead,
                        });
                    }
                }
                this.setState({
                    selectedDataSets: selectedDataSets,
                    selectedDataSetsByOtherUsers: selectedDataSetsByOtherUsers,
                });
            })
            .catch((error) => {
                if (
                    error?.response?.status !== 401 &&
                    error?.response?.status !== 403
                ) {
                    console.log(error);
                }
            });
    }

    public componentDidMount(): void {
        UserGroupsStore.init();
        if (ModuleUserGroupsStore(this.props.moduleId).initialized) {
            this.assignUserGroups(
                ModuleUserGroupsStore(this.props.moduleId).userGroupsState
            );
        }
        this.moduleUserGroupsReaction = reaction(
            () => ModuleUserGroupsStore(this.props.moduleId).userGroupsState,
            (userGroups) => {
                this.assignUserGroups(userGroups);
            }
        );
        this.updateDataSets();
    }

    public componentWillUnmount() {
        this.moduleUserGroupsReaction();
    }

    public render(): JSX.Element {
        let currentModule = CurrentModulesStore.getModule(this.props.moduleId);
        let allowUserGroups = this.state.newGroupInput.length > 2;
        let selectedUserGroupsSet = new Set<number>(
            this.state.selectedUserGroups.map((item) => item.group_id)
        );
        let options = UserGroupsStore.userGroupsOptionsForSharing(
            allowUserGroups
        ).filter((item) => !selectedUserGroupsSet.has(item.id));

        let selectedUserGroups = this.state.sharedWithExpanded
            ? this.state.selectedUserGroups
            : this.state.selectedUserGroups.slice(0, 2);

        let permissionOptionsEx = permissionOptions
            .map((option) => ({
                ...option,
                label: StringUtils.capitalize(option.label.toLowerCase()),
            }))
            .concat({
                label: "Remove",
                value: RemovePermission,
            });

        if (currentModule?.user_data?.is_owner) {
            permissionOptionsEx = permissionOptionsEx.concat({
                label: "Owner",
                value: MakeOwner,
            });
        }

        return (
            <div className="flex-simple-column">
                <UserInviter
                    options={options}
                    newGroupInput={this.state.newGroupInput}
                    selectedNewGroup={this.state.selectedNewGroup}
                    showPermissions
                    selectedPermission={this.state.selectedPermission}
                    onInviteUser={this.inviteUser}
                    onChange={(changes) => {
                        this.setState(changes as RegularShareState);
                    }}
                    showMakeOwner={currentModule?.user_data?.is_owner}
                />
                <div
                    className="my-row"
                    style={{ marginTop: 20, marginLeft: 16, flex: 1 }}
                >
                    <span
                        style={{
                            marginTop: 10,
                            marginRight: 30,
                            color: "var(--popup-primary-text-color)",
                            fontSize: "16px",
                            fontWeight: 500,
                            fontFamily: "Roboto",
                        }}
                        className="unselectable"
                    >
                        Include data set <br /> (for collaborators)
                    </span>
                    <DataSetsSelector
                        addButton
                        showSharedByOthers
                        canEditShown={true}
                        rowDirection={false}
                        menuPortalEnabled={true}
                        className="unselectable"
                        onSelectedDataSetsChanged={(
                            selectedDataSets,
                            change
                        ) => {
                            if (change.operation === "add_or_edit") {
                                this.addOrEditDataSet(
                                    selectedDataSets[change.index].value,
                                    selectedDataSets[change.index].accessType
                                );
                            } else {
                                // "delete"
                                this.deleteDataSet(
                                    this.state.selectedDataSets[change.index]
                                        .value
                                );
                            }
                            this.setState({
                                selectedDataSets: selectedDataSets,
                            });
                        }}
                        onSelectedDataSetChanged={(
                            selectedDataSet: DataSet | null
                        ) => {
                            this.setState({
                                selectedDataSet: selectedDataSet,
                            });
                        }}
                        selectedDataSetsByOtherUsers={
                            this.state.selectedDataSetsByOtherUsers
                        }
                        selectedDataSets={this.state.selectedDataSets}
                        selectedDataSet={this.state.selectedDataSet}
                    />
                </div>
                <div
                    style={{
                        marginTop: "21px",
                        height: "1px",
                        backgroundColor: "#ccc",
                        width: "100%",
                    }}
                />
                <div className="flex-simple-column">
                    <div
                        className="my-row"
                        style={{
                            marginTop: "13px",
                            marginLeft: "9px",
                            justifyContent: "space-between",
                        }}
                        onClick={() => {
                            this.setState((state) => ({
                                sharedWithExpanded: !state.sharedWithExpanded,
                            }));
                        }}
                    >
                        <span
                            style={{
                                color: "var(--popup-primary-text-color)",
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Shared With
                        </span>
                        <div
                            className="unselectable"
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: 15,
                                height: 15,
                            }}
                        >
                            <ChevronIcon
                                transform={
                                    !this.state.sharedWithExpanded
                                        ? "rotate(90)"
                                        : "none"
                                }
                                stroke={mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                )}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: 14.25,
                            marginLeft: 25,
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 280px)",
                            gridRowGap: "10px",
                            gridColumnGap: "10px",
                        }}
                    >
                        {currentModule && (
                            <div
                                className="my-row"
                                style={{ alignItems: "center" }}
                            >
                                <UserIcon
                                    width={25}
                                    height={25}
                                    fontSize={8}
                                    user={currentModule.user_data}
                                />

                                <span
                                    style={{
                                        marginLeft: 20,
                                        width: "60px",
                                        color:
                                            "var(--popup-primary-text-color)",
                                        fontSize: "11px",
                                        fontWeight: 400,
                                        fontFamily: "Roboto",
                                    }}
                                    className="unselectable"
                                >
                                    {currentModule.user_data
                                        ? currentModule.user_data.first_name
                                              .concat(" ")
                                              .concat(
                                                  currentModule.user_data
                                                      .last_name
                                              )
                                        : ""}
                                </span>
                                <span
                                    style={{
                                        marginLeft: 20,
                                        width: "60px",
                                        color:
                                            "var(--popup-quaternary-text-color)",
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        fontFamily: "Roboto",
                                    }}
                                    className="unselectable"
                                >
                                    {"Owner"}
                                </span>
                            </div>
                        )}
                        {selectedUserGroups.map((group, index) => (
                            <div
                                key={index}
                                className="my-row"
                                style={{ alignItems: "center" }}
                            >
                                {!group.personal && (
                                    <>
                                        <GroupIcon
                                            fontSize={8}
                                            groupName={group.group_name}
                                        />
                                        <span
                                            style={{
                                                marginLeft: 10,
                                                width: "60px",
                                                color:
                                                    "var(--popup-primary-text-color)",
                                                fontSize: "11px",
                                                fontWeight: 400,
                                                fontFamily: "Roboto",
                                            }}
                                            className="unselectable"
                                        >
                                            {group.group_name}
                                        </span>
                                    </>
                                )}
                                {group.personal && !group.invited && (
                                    <>
                                        <UserIcon
                                            width={25}
                                            height={25}
                                            fontSize={8}
                                            user={group.user_info}
                                        />

                                        <span
                                            style={{
                                                marginLeft: 20,
                                                width: "60px",
                                                color:
                                                    "var(--popup-primary-text-color)",
                                                fontSize: "11px",
                                                fontWeight: 400,
                                                fontFamily: "Roboto",
                                            }}
                                            className="unselectable"
                                        >
                                            {group.user_info
                                                ? group.user_info.first_name
                                                      .concat(" ")
                                                      .concat(
                                                          group.user_info
                                                              .last_name
                                                      )
                                                      .trim() ||
                                                  group.user_info.user_name
                                                : ""}
                                        </span>
                                    </>
                                )}
                                {group.personal && group.invited && (
                                    <>
                                        <UserIcon
                                            opacity={0.7}
                                            width={25}
                                            height={25}
                                            fontSize={8}
                                            user={group.user_info}
                                        />

                                        <span
                                            title={
                                                group.user_info?.user_name ?? ""
                                            }
                                            style={{
                                                opacity: 0.7,
                                                marginLeft: 20,
                                                width: "60px",
                                                color:
                                                    "var(--popup-primary-text-color)",
                                                fontSize: "11px",
                                                fontWeight: 400,
                                                fontFamily: "Roboto",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                            className="unselectable"
                                        >
                                            {group.user_info?.user_name ?? ""}
                                        </span>
                                    </>
                                )}

                                <Select
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isDisabled={group.invited}
                                    placeholder={"Enter permissions"}
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    styles={{
                                        ...customSelectStylesForSharePopupNoBorder,
                                        container: (base) => ({
                                            ...base,
                                            marginLeft: "15px",
                                            width: "85px",
                                        }),
                                        dropdownIndicator: (provided) => {
                                            if (group.invited)
                                                return {
                                                    ...provided,
                                                    display: "none",
                                                };
                                            else return provided;
                                        },
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 100000000,
                                        }),
                                    }}
                                    options={permissionOptionsEx}
                                    value={{
                                        value: group.permission_type,
                                        label:
                                            group.permission_type ===
                                            RemovePermission
                                                ? "Remove"
                                                : StringUtils.capitalize(
                                                      PermissionNames[
                                                          group.permission_type as Permission
                                                      ].toLowerCase()
                                                  ),
                                    }}
                                    onChange={(newValue) => {
                                        let permissionType = (newValue as PermissionOptionType)
                                            .value as
                                            | Permission
                                            | typeof MakeOwner;
                                        if (permissionType === MakeOwner) {
                                            if (!group.personal) {
                                                this.setState({
                                                    popupStatus:
                                                        PopupStatus.Error,
                                                    message:
                                                        "Can't transfer ownership to a group",
                                                });
                                            } else if (group.invited) {
                                                this.setState({
                                                    popupStatus:
                                                        PopupStatus.Error,
                                                    message:
                                                        "Can't transfer ownership to an invited user",
                                                });
                                            } else {
                                                this.setState({
                                                    transferOwnershipPopup: group,
                                                });
                                            }
                                        } else {
                                            let selectedUserGroups = [
                                                ...this.state
                                                    .selectedUserGroups,
                                            ];
                                            selectedUserGroups[
                                                index
                                            ].permission_type = permissionType;
                                            this.setState({
                                                needSave: true,
                                                selectedUserGroups: selectedUserGroups,
                                            });
                                        }
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                    })}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {/* <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: "27px",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    width: "70px",
                                }}
                                onClick={() => {
                                    this.setState({
                                        password: "",
                                        confirmPassword: "",
                                        passwordProtection: false,
                                        requireSignIn:
                                            currentModule!.shared_link
                                                ?.require_authentication ??
                                            false,
                                    });
                                }}
                            >
                                CANCEL
                            </Button>*/}

                {/*<div
                                className="my-row"
                                style={{
                                    marginTop: "13px",
                                    marginBottom: "13px",
                                    marginLeft: "4px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span
                                    style={{
                                        color: "#FFFFFF",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontFamily: "Roboto",
                                    }}
                                    className="unselectable"
                                >
                                    PAYWALL OPTIONS
                                </span>
                                <div
                                    className="unselectable"
                                    style={{
                                        cursor: "pointer",
                                        transform: !this.state
                                            .paywallOptionsExpanded
                                            ? "rotate(90deg)"
                                            : "none",
                                    }}
                                    onClick={() => {
                                        this.setState((state) => ({
                                            paywallOptionsExpanded: !state.paywallOptionsExpanded,
                                        }));
                                    }}
                                >
                                    <img
                                        alt=""
                                        src="/dist/img/canvas/arrow.png"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                            </div>
                            {this.state.paywallOptionsExpanded &&
                                this.state.shareLink != null && (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "13px",
                                        }}
                                    >
                                        <CreateProduct
                                            linkId={
                                                linkIdFromUrl(
                                                    this.state.shareLink
                                                ) ?? ""
                                            }
                                            style={{ width: "50%" }}
                                            onMessage={(status, message) => {
                                                this.setState({
                                                    popupStatus: status,
                                                    message: message,
                                                });
                                            }}
                                        />
                                    </div>
                                )}*/}

                <Button
                    type="button"
                    disabled={!this.state.needSave}
                    className="btn btn-sm btn-primary my-primary"
                    style={{
                        alignSelf: "flex-end",
                        width: "70px",
                        marginBottom: "10px",
                        background:
                            "linear-gradient(94.78deg, #1A60B3 0%, #13529B 100%)",
                    }}
                    onClick={this.saveChanges}
                >
                    Save
                </Button>
                {this.state.popupStatus != null && (
                    <StatusPopup
                        onClose={() => {
                            this.setState({
                                popupStatus: undefined,
                                message: undefined,
                            });
                        }}
                        status={this.state.popupStatus}
                        message={this.state.message ?? ""}
                    />
                )}
                {this.state.transferOwnershipPopup != null && (
                    <Portal rootNode={document.body}>
                        <MessagePopup
                            title="Make Owner"
                            message={`Are you sure you want to make ${
                                this.state.transferOwnershipPopup.user_info
                                    ? this.state.transferOwnershipPopup.user_info.first_name
                                          .concat(" ")
                                          .concat(
                                              this.state.transferOwnershipPopup
                                                  .user_info.last_name
                                          )
                                          .trim() ||
                                      this.state.transferOwnershipPopup
                                          .user_info.user_name
                                    : ""
                            } the owner?`}
                            onAccept={() => {
                                transferModuleUserOwnershipApi(
                                    this.props.moduleId,
                                    // Group name is the same as user name since
                                    // this is a personal group
                                    this.state.transferOwnershipPopup!
                                        .group_name
                                )
                                    .then(() => {
                                        this.setState({
                                            popupStatus: PopupStatus.Success,
                                            message: "Success",
                                            transferOwnershipPopup: null,
                                        });
                                        CurrentModulesStore.updateCurrentModules(
                                            this.props.moduleId
                                        );
                                        ModuleUserGroupsStore(
                                            this.props.moduleId
                                        ).updateUserGroups();
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            popupStatus: PopupStatus.Error,
                                            message: error.toString(),
                                            transferOwnershipPopup: null,
                                            selectedNewGroup: null,
                                            selectedPermission:
                                                Permission.ReadWrite,
                                        });
                                    });
                            }}
                            acceptButtonTitle="Yes"
                            onReject={() => {
                                this.setState({
                                    transferOwnershipPopup: null,
                                });
                            }}
                        />
                    </Portal>
                )}
            </div>
        );
    }
}

interface LinkShareState {
    popupStatus: PopupStatus | undefined;
    selectedDataSetsLink: DataSet[];
    selectedDataSetLink: DataSet | null;
    allowAccessToAllDataSets: boolean;
    message: string | undefined;
    requireSignIn: boolean;
    passwordInputReadOnly: boolean;
    passwordProtection: boolean;
    password: string;
    confirmPassword: string;
    advancedOptionsExpanded: boolean;
    // paywallOptionsExpanded: boolean;
    shareLink: string | null;
    shareIframe: string | null;
    showAsSlideShow: boolean;
    needSave: boolean;
    editableByCollaborators: boolean;
    dataSetOptions: DataSet[];
}

const linkModeOptions = [
    {
        label: "App",
        value: "App",
    },
    {
        label: "Slideshow",
        value: "Slideshow",
    },
];

@observer
class LinkShareComponent extends Component<ShareProps, LinkShareState> {
    constructor(props: ShareProps) {
        super(props);
        let currentModule = CurrentModulesStore.getModule(this.props.moduleId)!;
        this.state = {
            needSave: false,
            showAsSlideShow:
                currentModule.shared_link != null
                    ? currentModule.shared_link.show_as_slideshow
                    : false,
            popupStatus: undefined,
            message: undefined,
            selectedDataSetsLink: [],
            selectedDataSetLink: null,
            allowAccessToAllDataSets: true,
            passwordInputReadOnly: true,
            password: "",
            confirmPassword: "",
            passwordProtection:
                currentModule.shared_link?.password_protection ?? false,
            requireSignIn:
                currentModule.shared_link?.require_authentication ?? false,
            advancedOptionsExpanded: false,
            // paywallOptionsExpanded: false,
            shareLink:
                currentModule.shared_link != null
                    ? linkIdToUrl(
                          currentModule.shared_link.require_authentication,
                          currentModule.shared_link.link_id
                      )
                    : null,
            shareIframe:
                currentModule.shared_link != null
                    ? linkIdToIFrame(
                          currentModule.shared_link.require_authentication,
                          currentModule.shared_link.link_id
                      )
                    : null,
            editableByCollaborators:
                currentModule.shared_link != null
                    ? currentModule.shared_link.editable_by_collaborators
                    : true,
            dataSetOptions: [],
        };
        this.saveChanges = this.saveChanges.bind(this);
    }

    private async saveChanges() {
        if (
            this.state.passwordProtection &&
            this.state.password !== this.state.confirmPassword
        ) {
            this.setState({
                popupStatus: PopupStatus.Error,
                message: "Passwords didn't match",
            });
            return;
        }
        let currentModule = CurrentModulesStore.getModule(this.props.moduleId)!;
        let dataSetsLink: {
            data_table_idx: string | number;
            allow_uploading?: boolean;
        }[] = this.state.selectedDataSetsLink.map((dataSet) => ({
            data_table_idx: dataSet.value,
            allow_uploading:
                dataSet.accessType === AccessType.CanSubmit ||
                dataSet.accessType === AccessType.CanEdit,
        }));

        if (
            this.state.selectedDataSetLink != null &&
            dataSetsLink.find(
                (dataSet) =>
                    dataSet.data_table_idx ===
                    this.state.selectedDataSetLink!.value
            ) == null
        ) {
            dataSetsLink.push({
                data_table_idx: this.state.selectedDataSetLink.value,
                allow_uploading:
                    this.state.selectedDataSetLink.accessType ===
                        AccessType.CanSubmit ||
                    this.state.selectedDataSetLink.accessType ===
                        AccessType.CanEdit,
            });
        }
        try {
            let linkId = await shareModuleLink(
                this.props.moduleId,
                this.state.requireSignIn,
                this.state.passwordProtection
                    ? this.state.password.length !== 0
                        ? this.state.password
                        : // undefined = do not change the password
                          undefined
                    : // null = remove the password if it is set
                      null,
                this.state.showAsSlideShow,
                this.state.editableByCollaborators,
                this.state.allowAccessToAllDataSets,
                dataSetsLink
            );
            this.setState({
                popupStatus: PopupStatus.Success,
                message: "Changes applied",
                shareLink: linkIdToUrl(this.state.requireSignIn, linkId),
                shareIframe: linkIdToIFrame(this.state.requireSignIn, linkId),
            });

            if (currentModule.shared_link == null) {
                Instrumentation.addModuleSharedLink(currentModule.title);
            }
            CurrentModulesStore.updateCurrentModules(this.props.moduleId);
        } catch (error) {
            this.setState({
                popupStatus: PopupStatus.Error,
                message: String(error),
            });
        }
    }

    private updateDataSetsLink(): void {
        let currentModule = CurrentModulesStore.getModule(this.props.moduleId)!;

        getModuleUserDataSetsApi(this.props.moduleId, false)
            .then((dataSets) => {
                this.setState(
                    {
                        dataSetOptions: dataSets.map((dataSet) => ({
                            label: dataSet.data_table_name,
                            value: dataSet.data_table_idx,
                            accessType:
                                dataSet.permission_type === Permission.ReadWrite
                                    ? AccessType.CanEdit
                                    : dataSet.allow_uploading
                                    ? AccessType.CanSubmit
                                    : AccessType.CanRead,
                        })),
                    },
                    () => {
                        if (currentModule.shared_link?.link_id == null) {
                            this.setState((state) => {
                                return {
                                    allowAccessToAllDataSets: true,
                                    selectedDataSetsLink: state.dataSetOptions.map(
                                        (dataSet) => ({
                                            ...dataSet,
                                            accessType:
                                                dataSet.accessType ===
                                                AccessType.CanRead
                                                    ? AccessType.CanRead
                                                    : AccessType.CanSubmit,
                                        })
                                    ),
                                };
                            });
                            return;
                        }
                        getModuleUserSharedLinkDataSetsApi(
                            currentModule.shared_link!.link_id
                        )
                            .then(({ allowAccessToAllDataSets, dataSets }) => {
                                this.setState((state) => {
                                    let availableDataSets = new Set(
                                        state.dataSetOptions.map(
                                            (dataSet) => dataSet.value
                                        )
                                    );
                                    return {
                                        allowAccessToAllDataSets: allowAccessToAllDataSets,
                                        selectedDataSetsLink: allowAccessToAllDataSets
                                            ? state.dataSetOptions.map(
                                                  (dataSet) => ({
                                                      ...dataSet,
                                                      accessType:
                                                          dataSet.accessType ===
                                                          AccessType.CanRead
                                                              ? AccessType.CanRead
                                                              : AccessType.CanSubmit,
                                                  })
                                              )
                                            : dataSets
                                                  .filter((dataSet) =>
                                                      availableDataSets.has(
                                                          dataSet.data_table_idx
                                                      )
                                                  )
                                                  .map((dataSet) => ({
                                                      label:
                                                          dataSet.data_table_name,
                                                      value:
                                                          dataSet.data_table_idx,
                                                      accessType:
                                                          dataSet.permission_type ===
                                                          Permission.ReadWrite
                                                              ? AccessType.CanEdit
                                                              : dataSet.allow_uploading
                                                              ? AccessType.CanSubmit
                                                              : AccessType.CanRead,
                                                  })),
                                    };
                                });
                            })
                            .catch((error) => {
                                if (
                                    error?.response?.status !== 401 &&
                                    error?.response?.status !== 403
                                ) {
                                    console.log(error);
                                }
                            });
                    }
                );
            })
            .catch((error) => {
                if (
                    error?.response?.status !== 401 &&
                    error?.response?.status !== 403
                ) {
                    console.log(error);
                }
            });
    }

    public componentDidMount(): void {
        this.updateDataSetsLink();
    }

    public render(): JSX.Element {
        let currentModule = CurrentModulesStore.getModule(this.props.moduleId);
        let canEditSharedLink =
            currentModule?.user_data?.is_owner ||
            (currentModule?.permission_type === Permission.ReadWrite &&
                (currentModule.shared_link == null ||
                    currentModule.shared_link.editable_by_collaborators));

        return (
            <div className="flex-simple-column">
                {this.state.shareLink != null &&
                    this.state.shareIframe != null && (
                        <>
                            <div
                                className="my-row"
                                style={{
                                    marginTop: "13px",
                                    marginLeft: "9px",
                                    alignItems: "center",
                                }}
                            >
                                <CopyToClipboard
                                    text={this.state.shareLink ?? ""}
                                    options={{
                                        format: "text/plain",
                                    }}
                                    onCopy={() => {
                                        this.setState({
                                            popupStatus: PopupStatus.Success,
                                            message:
                                                "Presentation Link Copied to Clipboard",
                                        });
                                    }}
                                >
                                    <div
                                        className="my-row"
                                        style={{ alignItems: "center" }}
                                    >
                                        <img
                                            alt=""
                                            src="/dist/img/canvas/share_module_link.png"
                                            style={{ cursor: "pointer" }}
                                        />
                                        <span
                                            style={{
                                                marginLeft: 5,
                                                color:
                                                    "var(--popup-quaternary-text-color)",
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                fontFamily: "Roboto",
                                            }}
                                            className="unselectable"
                                        >
                                            {"COPY PRESENTATION LINK"}
                                        </span>
                                    </div>
                                </CopyToClipboard>
                                <div style={{ flexGrow: 1 }} />
                                <CopyToClipboard
                                    text={this.state.shareIframe ?? ""}
                                    options={{
                                        format: "text/plain",
                                    }}
                                    onCopy={() => {
                                        this.setState({
                                            popupStatus: PopupStatus.Success,
                                            message:
                                                "Embed Code Copied to Clipboard",
                                        });
                                    }}
                                >
                                    <div
                                        className="my-row"
                                        style={{ alignItems: "center" }}
                                    >
                                        <img
                                            alt=""
                                            src="/dist/img/canvas/embed_code.png"
                                            style={{ cursor: "pointer" }}
                                        />
                                        <span
                                            style={{
                                                marginLeft: 5,
                                                color:
                                                    "var(--popup-quaternary-text-color)",
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                fontFamily: "Roboto",
                                            }}
                                            className="unselectable"
                                        >
                                            {"EMBED CODE"}
                                        </span>
                                    </div>
                                </CopyToClipboard>
                            </div>
                            <div
                                style={{
                                    marginTop: "13px",
                                    height: "1px",
                                    backgroundColor: "#242E42",
                                    width: "100%",
                                }}
                            />
                        </>
                    )}
                {this.state.shareLink == null && !canEditSharedLink && (
                    <>
                        <div
                            className="my-row"
                            style={{
                                marginTop: "13px",
                                marginLeft: "9px",
                                alignItems: "center",
                            }}
                        >
                            <div
                                className="my-row"
                                style={{ alignItems: "center" }}
                            >
                                <span
                                    style={{
                                        marginLeft: 5,
                                        color:
                                            "var(--popup-quaternary-text-color)",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontFamily: "Roboto",
                                    }}
                                    className="unselectable"
                                >
                                    {"NO LINK"}
                                </span>
                            </div>
                            <div style={{ flexGrow: 1 }} />
                        </div>
                        <div
                            style={{
                                marginTop: "13px",
                                height: "1px",
                                backgroundColor: "#242E42",
                                width: "100%",
                            }}
                        />
                    </>
                )}
                {canEditSharedLink && (
                    <div className="flex-simple-column">
                        <div
                            className="my-row"
                            style={{ marginTop: 20, marginLeft: 16 }}
                        >
                            <span
                                style={{
                                    marginTop: 10,
                                    marginRight: 30,
                                    color: "var(--popup-primary-text-color)",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                Include data set <br /> (for shared link)
                            </span>
                            <div
                                className="flex-simple-column"
                                style={{ justifyContent: "center" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: 5,
                                    }}
                                >
                                    <span
                                        style={{
                                            marginRight: 30,
                                            color:
                                                "var(--popup-primary-text-color)",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            fontFamily: "Roboto",
                                        }}
                                        className="unselectable"
                                    >
                                        Include all data sets for link
                                    </span>
                                    <Switch
                                        onChange={(checked) => {
                                            this.setState((state) => {
                                                if (checked) {
                                                    return {
                                                        needSave: true,
                                                        allowAccessToAllDataSets: checked,
                                                        selectedDataSetLink: null,
                                                        selectedDataSetsLink: state.dataSetOptions.map(
                                                            (dataSet) => ({
                                                                ...dataSet,
                                                                accessType:
                                                                    dataSet.accessType ===
                                                                    AccessType.CanRead
                                                                        ? AccessType.CanRead
                                                                        : AccessType.CanSubmit,
                                                            })
                                                        ),
                                                    };
                                                } else {
                                                    return {
                                                        needSave: true,
                                                        allowAccessToAllDataSets: checked,
                                                        selectedDataSetLink:
                                                            state.selectedDataSetLink,
                                                        selectedDataSetsLink:
                                                            state.selectedDataSetsLink,
                                                    };
                                                }
                                            });
                                        }}
                                        checked={
                                            this.state.allowAccessToAllDataSets
                                        }
                                        width={40}
                                        height={20}
                                        offColor="#CCC"
                                        onColor="#CCC"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1a60b2"
                                    />
                                </div>
                                {!this.state.allowAccessToAllDataSets && (
                                    <DataSetsSelector
                                        addButton
                                        sharedByYouTitle="SHARED IN THIS PRESENTATION"
                                        rowDirection={true}
                                        menuPortalEnabled={true}
                                        className="unselectable"
                                        style={{
                                            fontSize: "10px",
                                        }}
                                        onSelectedDataSetsChanged={(
                                            selectedDataSetsLink: DataSet[]
                                        ) => {
                                            this.setState({
                                                needSave: true,
                                                selectedDataSetsLink: selectedDataSetsLink,
                                            });
                                        }}
                                        onSelectedDataSetChanged={(
                                            selectedDataSetLink: DataSet | null
                                        ) => {
                                            this.setState({
                                                needSave: true,
                                                selectedDataSetLink: selectedDataSetLink,
                                            });
                                        }}
                                        selectedDataSets={
                                            this.state.selectedDataSetsLink
                                        }
                                        selectedDataSet={
                                            this.state.selectedDataSetLink
                                        }
                                        dataSetOptions={
                                            this.state.dataSetOptions
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: "21px",
                                height: "1px",
                                backgroundColor: "#ccc",
                                width: "100%",
                            }}
                        />
                        <div
                            className="my-row"
                            style={{ marginTop: 20, marginLeft: 16 }}
                        >
                            <span
                                style={{
                                    marginTop: 10,
                                    marginRight: 30,
                                    color: "var(--popup-primary-text-color)",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                Link mode
                            </span>
                            <Select
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                placeholder={"Select link mode"}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                styles={{
                                    ...customSelectStylesForSharePopup,
                                    container: (base) => ({
                                        ...base,
                                        height: "32px",
                                        width: "140px",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={linkModeOptions}
                                value={
                                    this.state.showAsSlideShow
                                        ? linkModeOptions[1]
                                        : linkModeOptions[0]
                                }
                                onChange={(newValue) => {
                                    if (
                                        (newValue as {
                                            label: string;
                                            value: string;
                                        }).value === "App"
                                    ) {
                                        this.setState({
                                            showAsSlideShow: false,
                                        });
                                    } else
                                        this.setState({
                                            showAsSlideShow: true,
                                        });
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                            />
                        </div>
                        {currentModule?.user_data?.is_owner && (
                            <div
                                className="my-row"
                                style={{
                                    marginTop: 20,
                                    marginLeft: 16,
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        marginRight: 30,
                                        color:
                                            "var(--popup-primary-text-color)",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        fontFamily: "Roboto",
                                    }}
                                    className="unselectable"
                                >
                                    Editable by collaborators
                                </span>
                                <div style={{ marginLeft: 19 }}>
                                    <Switch
                                        onChange={(checked) => {
                                            this.setState({
                                                needSave: true,
                                                editableByCollaborators: checked,
                                            });
                                        }}
                                        checked={
                                            this.state.editableByCollaborators
                                        }
                                        width={40}
                                        height={20}
                                        offColor="#CCC"
                                        onColor="#CCC"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1a60b2"
                                    />
                                </div>
                            </div>
                        )}
                        <div
                            className="my-row"
                            style={{
                                marginTop: "13px",
                                marginBottom: "13px",
                                marginLeft: "4px",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                            onClick={() => {
                                this.setState((state) => ({
                                    advancedOptionsExpanded: !state.advancedOptionsExpanded,
                                }));
                            }}
                        >
                            <span
                                style={{
                                    color: "var(--popup-primary-text-color)",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                ADVANCED OPTIONS
                            </span>
                            <div
                                className="unselectable"
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 15,
                                    height: 15,
                                }}
                            >
                                <ChevronIcon
                                    transform={
                                        !this.state.advancedOptionsExpanded
                                            ? "rotate(90)"
                                            : "none"
                                    }
                                    stroke={mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    )}
                                />
                            </div>
                        </div>
                        {this.state.advancedOptionsExpanded && (
                            <div
                                className="my-row"
                                style={{
                                    height: "40px",
                                    marginBottom: "13px",
                                    marginLeft: "4px",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        marginLeft: "29px",
                                        color:
                                            "var(--popup-primary-text-color)",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontFamily: "Roboto",
                                    }}
                                    className="unselectable"
                                >
                                    Require sign-in
                                </span>
                                <div style={{ marginLeft: 19 }}>
                                    <Switch
                                        onChange={() => {
                                            this.setState((state) => {
                                                return {
                                                    needSave: true,
                                                    requireSignIn: !state.requireSignIn,
                                                };
                                            });
                                        }}
                                        checked={this.state.requireSignIn}
                                        width={40}
                                        height={20}
                                        offColor="#CCC"
                                        onColor="#CCC"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1a60b2"
                                    />
                                </div>
                                <span
                                    style={{
                                        marginLeft: "59px",
                                        color:
                                            "var(--popup-primary-text-color)",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        fontFamily: "Roboto",
                                    }}
                                    className="unselectable"
                                >
                                    Password protection
                                </span>
                                <div style={{ marginLeft: 19 }}>
                                    <Switch
                                        onChange={() => {
                                            this.setState((state) => {
                                                return {
                                                    needSave: true,
                                                    passwordProtection: !state.passwordProtection,
                                                };
                                            });
                                        }}
                                        checked={this.state.passwordProtection}
                                        width={40}
                                        height={20}
                                        offColor="#CCC"
                                        onColor="#CCC"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1a60b2"
                                    />
                                </div>
                                <CSSTransition
                                    in={this.state.passwordProtection}
                                    timeout={500}
                                    classNames={"share-fade"}
                                    unmountOnExit
                                >
                                    <div className="my-row share-fade">
                                        <input
                                            type={"password"}
                                            autoComplete="off"
                                            readOnly={
                                                this.state.passwordInputReadOnly
                                            }
                                            onFocus={() =>
                                                this.setState({
                                                    passwordInputReadOnly: false,
                                                })
                                            }
                                            placeholder={
                                                currentModule?.shared_link
                                                    ?.password_protection
                                                    ? "********"
                                                    : "Enter password"
                                            }
                                            className="like-select"
                                            style={{
                                                width: 140,
                                                marginLeft: 22,
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                            }}
                                            onChange={(e) => {
                                                let newValue: string =
                                                    e.target.value;
                                                this.setState({
                                                    needSave: true,
                                                    password: newValue,
                                                });
                                            }}
                                            value={this.state.password}
                                        />
                                        <input
                                            type={"password"}
                                            autoComplete="off"
                                            readOnly={
                                                this.state.passwordInputReadOnly
                                            }
                                            onFocus={() =>
                                                this.setState({
                                                    passwordInputReadOnly: false,
                                                })
                                            }
                                            placeholder={
                                                currentModule?.shared_link
                                                    ?.password_protection &&
                                                this.state.password === ""
                                                    ? "********"
                                                    : "Confirm password"
                                            }
                                            className="like-select"
                                            style={{
                                                width: 140,
                                                marginLeft: 21,
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                            }}
                                            onChange={(e) => {
                                                let newValue: string =
                                                    e.target.value;
                                                this.setState({
                                                    needSave: true,
                                                    confirmPassword: newValue,
                                                });
                                            }}
                                            value={this.state.confirmPassword}
                                        />
                                    </div>
                                </CSSTransition>
                            </div>
                        )}
                    </div>
                )}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        alignSelf: "flex-end",
                        marginBottom: "10px",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-sm my-outline"
                        style={{
                            color: "#3b82c9",
                            backgroundColor: "transparent",
                            border: "none",
                        }}
                        onClick={() => {
                            this.props.onClose?.();
                        }}
                    >
                        Cancel
                    </Button>
                    {canEditSharedLink && (
                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                width: "112px",
                                background:
                                    "linear-gradient(94.78deg, rgb(26, 96, 179) 0%, rgb(19, 82, 155) 100%)"
                            }}
                            onClick={this.saveChanges}
                        >
                            {currentModule!.shared_link == null
                                ? "Create"
                                : "Update Link"}
                        </Button>
                    )}
                </div>
                {this.state.popupStatus != null && (
                    <StatusPopup
                        onClose={() => {
                            this.setState({
                                popupStatus: undefined,
                                message: undefined,
                            });
                        }}
                        status={this.state.popupStatus}
                        message={this.state.message ?? ""}
                    />
                )}
            </div>
        );
    }
}

interface Props {
    rootRef: React.Ref<HTMLElement>;
    sharePopupType: number;
    moduleId: number;
    canvasTreeStore: CanvasTreeStore;
    onClose: () => void;
}

interface State {
    shareType: ShareType;
}

@observer
class SharePopup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            shareType: this.props.sharePopupType ?? ShareType.Link,
        };
        this.renderContents = this.renderContents.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.sharePopupType !== prevProps.sharePopupType) {
            this.setState({
                shareType: this.props.sharePopupType,
            });
        }
    }

    buildPageItem(shareType: ShareType) {
        let selected = shareType === this.state.shareType;
        return (
            <div
                className="flex-simple-column"
                style={{ marginRight: "28px" }}
                onClick={(_evt) => {
                    this.setState({ shareType: shareType });
                }}
            >
                <span
                    className="unselectable"
                    style={{
                        fontFamily: "Roboto",
                        fontWeight: selected ? 500 : "normal",
                        fontSize: 17,
                        color: selected
                            ? mainStyle.getPropertyValue(
                                  "--popup-quaternary-text-color"
                              )
                            : "var(--popup-primary-text-color)",
                        padding: "10px 0",
                    }}
                >
                    {shareTypes[shareType]}
                </span>
                {selected && (
                    <div
                        style={{
                            height: 2,
                            width: "100%",
                            backgroundColor: mainStyle.getPropertyValue(
                                "--popup-quaternary-text-color"
                            ),
                        }}
                    />
                )}
            </div>
        );
    }

    private buildInnerView(globalContext: GlobalContextContents): JSX.Element {
        let currentModule = CurrentModulesStore.getModule(this.props.moduleId);
        let canEditSharedLink =
            currentModule?.user_data?.is_owner ||
            (currentModule?.permission_type === Permission.ReadWrite &&
                (currentModule.shared_link == null ||
                    currentModule.shared_link.editable_by_collaborators));
        return (
            <div
                className="element"
                style={{
                    maxHeight: "calc(100vh - 120px)",
                    overflow: "auto",
                    paddingTop: "20px"
                }}
            >
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    {/* This gives the page titles */}
                    {this.props.sharePopupType === ShareType.Regular && (
                        <>
                            <div className="my-row">
                                {this.buildPageItem(ShareType.Regular)}
                            </div>
                            <div
                                style={{
                                    height: "1px",
                                    backgroundColor: "rgba(49, 54, 58, 0.1)",
                                    width: "100%",
                                }}
                            />
                        </>
                    )}
                    {this.props.sharePopupType !== ShareType.Regular && (
                        <>
                            <div className="my-row">
                                {"ShareLinkUserModules" in
                                    globalContext.permissions &&
                                    this.buildPageItem(ShareType.Link)}
                                {currentModule?.user_data?.is_owner &&
                                    "ShareModules" in
                                        globalContext.permissions &&
                                    this.buildPageItem(ShareType.Template)}
                                {canEditSharedLink &&
                                    "PublishToApp" in
                                        globalContext.permissions &&
                                    this.buildPageItem(ShareType.App)}
                            </div>
                            <div
                                style={{
                                    height: "1px",
                                    backgroundColor: "rgba(49, 54, 58, 0.1)",
                                    width: "100%",
                                }}
                            />
                        </>
                    )}
                    {/* This is where we include all fo the different tabs */}
                    {/* Default Share Component */}
                    {this.props.sharePopupType === ShareType.Regular && (
                        <RegularShareComponent moduleId={this.props.moduleId} />
                    )}
                    {this.state.shareType === ShareType.Link &&
                        "ShareLinkUserModules" in globalContext.permissions && (
                            <LinkShareComponent
                                moduleId={this.props.moduleId}
                                onClose={this.props.onClose}
                            />
                        )}

                    {/* Create Template Component */}
                    {currentModule?.user_data?.is_owner &&
                        this.state.shareType === ShareType.Template &&
                        "ShareModules" in globalContext.permissions && (
                            <ModuleCreator
                                moduleId={this.props.moduleId}
                                onCancel={this.props.onClose}
                                rootRef={this.props.rootRef}
                                canvasTreeStore={this.props.canvasTreeStore}
                            />
                        )}

                    {/* Create App Component */}
                    {canEditSharedLink &&
                        this.state.shareType === ShareType.App &&
                        "PublishToApp" in globalContext.permissions && (
                            <AppCreator
                                moduleId={this.props.moduleId}
                                onCancel={this.props.onClose}
                                rootRef={this.props.rootRef}
                            />
                        )}
                </div>
            </div>
        );
    }

    private renderContents(globalContext: GlobalContextContents): JSX.Element {
        const height: number = 380;
        const width: number = 977;
        return (
            <Popup
                arrow={true}
                contentStyle={{
                    width: width,
                    minHeight: height,
                    border: "none",
                    backgroundColor: "transparent",
                }}
                open={true}
                onClose={() => {
                    this.props.onClose();
                }}
            >
                <div
                    className="dashboard-rect"
                    style={{
                        boxShadow: "0px 6px 13px rgba(21, 33, 56, 0.533345)",
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        minHeight: height,
                        maxHeight: "100vh",
                        width: width,
                    }}
                >
                    {this.buildInnerView(globalContext)}
                </div>
            </Popup>
        );
    }

    public render(): JSX.Element {
        return (
            <GlobalContext.ObserverConsumer>
                {this.renderContents}
            </GlobalContext.ObserverConsumer>
        );
    }
}
export default SharePopup;
