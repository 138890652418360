import UserInfo from "common/UserInfo";

export interface NewComment {
    id: number; // pin_id;
    canvas_id: number;
}

export interface BaseCommentPin {
    x: number;
    y: number;
}

export interface CommentPin extends BaseCommentPin {
    id: number;
}

export interface CommentPinResponse {
    id: number;
    contents: string;
}

export interface CanvasComment {
    id: number;
    user_info: UserInfo;
    feedback: string;
    card_id: number;
    creation_time: number;
    is_owner: boolean;
}

export function toCommentPin(
    commentPinResponse: CommentPinResponse
): CommentPin {
    return {
        id: commentPinResponse.id,
        ...(JSON.parse(commentPinResponse.contents) as BaseCommentPin),
    };
}