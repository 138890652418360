import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { MapFinding } from "common/Finding";
import remoteModuleId from "common/remoteModuleId";
import { VariableOption } from "common/Variables";
import { Condition } from "common/Conditions";

const journeyNameToTypeMap: Readonly<{ [key: string]: string }> = {
    MapsPinsJourney: "maps_pins",
    MapsHeatmapJourney: "maps_heatmap",
    MapsBubbleJourney: "maps_bubble",
};

const markerQueryLimit = 5000;

class Api {
    static getDefaultConfig(journeyName: string): MapFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            isAdditional: false,
            tooltipActionButtonText: "ENHANCE",
        };
    }

    static getPreviewFinding(journeyName: string): MapFinding {
        let item: MapFinding = {
            type: journeyNameToTypeMap[journeyName],
            content: {
                data: {
                    "%lat": [
                        40.75918630136985,
                        48.853399999999986,
                        52.365564197530865,
                    ],
                    "%lon": [
                        -73.98172397260265,
                        2.3487999999999993,
                        4.892701234567899,
                    ],
                },
                heatMapData: [
                    [40.75918630136985, -73.98172397260265, 0],
                    [48.853399999999986, 2.3487999999999993, 0.5],
                    [52.365564197530865, 4.892701234567899, 1],
                ],
            },
            config: Api.getDefaultConfig(journeyName),
            additionalMapsFindings: [],
        };

        return item;
    }

    static async getData(
        finding: MapFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<MapFinding> {
        if (finding.config.dataScope?.value == null) {
            return Promise.resolve(finding);
        }

        let variableIndices = new Set<number>();
        let location:
            | { [key: string]: string | number }
            | undefined = undefined;
        if (
            finding.config.usesCoordinates &&
            finding.config.coordinates != null
        ) {
            for (let option of Object.values(
                finding.config.coordinates
            ) as VariableOption[]) {
                if (option != null) {
                    variableIndices.add(option.value);
                }
            }
        } else if (
            !finding.config.usesCoordinates &&
            finding.config.location != null
        ) {
            for (let option of Object.keys(finding.config.location)) {
                let value = finding.config.location[
                    option as keyof MapFinding["config"]["location"]
                ] as VariableOption;
                if (value != null) {
                    if (location == null) {
                        location = {};
                    }
                    location = {
                        ...location,
                        [option]: value.value,
                    };
                }
            }
        }

        if (finding.config.heatMap != null) {
            variableIndices.add(finding.config.heatMap.value);
        }

        if (finding.config.tooltipVariables) {
            for (let option of finding.config.tooltipVariables) {
                if (option) {
                    variableIndices.add(option.variable.value);
                    if (option.linkVariable != null) {
                        variableIndices.add(option.linkVariable.value);
                    }
                }
            }
        }

        const flagData = finding.config.flags?.flagData;
        if (flagData) {
            for (let option of flagData) {
                if (option) {
                    variableIndices.add(option.variable.value);
                    if (option.linkVariable != null) {
                        variableIndices.add(option.linkVariable.value);
                    }
                }
            }
        }

        const { filterAdditionalMapVariable } = finding.config;
        if (filterAdditionalMapVariable) {
            variableIndices.add(filterAdditionalMapVariable.value);
        }

        if (finding.config.timeVariableIndex != null) {
            variableIndices.add(finding.config.timeVariableIndex);
        }

        const countryIdx = finding.config?.choroplethCountryVariableIndex;
        if (!isNaN(countryIdx) && finding.config?.showBoundaries) {
            if (!variableIndices.has(countryIdx)) {
                variableIndices.add(countryIdx);
            }
        }

        if (
            finding.config.varyMarkerColorByVariable &&
            finding.config.markerColorVariableIndex != null
        ) {
            variableIndices.add(finding.config.markerColorVariableIndex);
        }
        if (finding.config.centerVariableIndex != null) {
            variableIndices.add(finding.config.centerVariableIndex);
        }

        let orderBy: number[] = [];
        if (finding.config.selectOrderBy != null) {
            for (let option of finding.config.selectOrderBy) {
                if (option != null) {
                    orderBy.push(option.value);
                }
            }
        }

        const conditions =
            finding.config.conditions != null
                ? conditionsToJson(
                      finding.config.conditions.filter(
                          (cond: Condition | null): cond is Condition =>
                              cond != null &&
                              cond.variable != null &&
                              cond.value != null &&
                              cond.operation != null
                      )
                  )
                : null;

        return axios
            .post<{
                success: boolean;
                error_msg: string;
                data?: { [key: string]: Array<number | string | null> };
            }>("/api/e/get_map_variable_values", {
                data_table_idx: finding.config.dataScope?.value,
                location: location,
                table: finding.config.tableOption?.value,
                conditions_id: finding.config.tableOption?.condition_id,
                variable_indices: Array.from(variableIndices),
                order_by_asc_desc: finding.config.selectLowest ? "asc" : "desc",
                order_by: orderBy,
                limit: finding.config.selectLimit ?? markerQueryLimit,
                conditions: conditions,
                module_id: moduleId ?? remoteModuleId,
                aggregation:
                    finding.type === "maps_heatmap"
                        ? finding.config.operationVariable === "count_distinct"
                            ? "count"
                            : finding.config.operationVariable ?? "mean"
                        : undefined,
            })
            .then((response) => {
                if (response.data.success && response.data.data != null) {
                    let newFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: response.data.data,
                            heatMapData: [] as Array<[number, number, number]>,
                        },
                    };
                    let heatMap = Api.prepareHeatMap(
                        newFinding,
                        findingOptions,
                        moduleId
                    );
                    newFinding.content.heatMapData = heatMap.heatMapData;
                    newFinding.content.heatMapTime = heatMap.heatMapTime;
                    if (finding.config.timeVariableIndex != null) {
                        newFinding.content.time = Array.from(
                            new Set<number | string>(
                                response.data.data[
                                    finding.config.timeVariableIndex.toString()
                                ].filter(
                                    (item): item is string | number =>
                                        item != null
                                )
                            )
                        ).sort((value1, value2) =>
                            typeof value1 === "number" &&
                            typeof value2 === "number"
                                ? value1 - value2
                                : String(value1).localeCompare(String(value2))
                        );
                    }
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    static prepareHeatMap(
        finding: MapFinding,
        findingOptions: any,
        moduleId: number
    ): {
        heatMapData: Array<[number, number, number]>;
        heatMapTime?: Array<number | string | null>;
    } {
        let heatMapData: Array<[number, number, number]> = [];
        let heatMapTime: Array<number | string | null> = [];

        if (finding.config.timeVariableIndex != null) {
            heatMapTime = [];
        }

        if (
            !finding.content.data ||
            (finding.config.usesCoordinates &&
                (!finding.config.coordinates?.latitude ||
                    !finding.config.coordinates?.longitude))
        ) {
            return { heatMapData: [] };
        }

        let latIndex: string;
        let lonIndex: string;
        if (finding.config.usesCoordinates) {
            latIndex = finding.config.coordinates!.latitude!.value.toString();
            lonIndex = finding.config.coordinates!.longitude!.value.toString();
        } else {
            latIndex = "%lat";
            lonIndex = "%lon";
        }
        if (finding.content.data[latIndex] != null) {
            let length: number = finding.content.data[latIndex].length;

            if (finding.config.heatMap != null) {
                let numbers: number[] = [];
                let data =
                    finding.content.data[
                        finding.config.heatMap.value.toString()
                    ];
                for (let i = 0; i < data.length; ++i) {
                    if (typeof data[i] === "number") {
                        numbers.push(data[i] as number);
                        if (finding.config.timeVariableIndex != null) {
                            heatMapTime.push(
                                finding.content.data[
                                    finding.config.timeVariableIndex.toString()
                                ][i]
                            );
                        }
                    }
                }
                let max = Math.max(...numbers);
                let min = Math.min(...numbers);
                for (let i = 0; i < length; ++i) {
                    let value = Number(
                        finding.content.data[
                            finding.config.heatMap.value.toString()
                        ][i]
                    );
                    let position = [
                        Number(
                            (finding.content.data[latIndex][i] ?? NaN) as number
                        ),
                        Number(
                            (finding.content.data[lonIndex][i] ?? NaN) as number
                        ),
                    ];
                    if (
                        !Number.isNaN(position[0]) &&
                        !Number.isNaN(position[1]) &&
                        !Number.isNaN(value)
                    ) {
                        heatMapData.push([
                            position[0],
                            position[1],
                            (value - min) / (max - min),
                        ]);
                    }
                }
            }
        }
        return {
            heatMapData: heatMapData,
            heatMapTime:
                finding.config.timeVariableIndex != null
                    ? heatMapTime
                    : undefined,
        };
    }
}

export { Api };
