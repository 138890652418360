const iconKeys: ReadonlyArray<string> = [
    "aerialway",
    "airfield",
    "airport",
    "alcohol-shop",
    "american-football",
    "amusement-park",
    "animal-shelter",
    "aquarium",
    "arrow",
    "art-gallery",
    "attraction",
    "bakery",
    "bank-JP",
    "bank",
    "bar",
    "barrier",
    "baseball",
    "basketball",
    "bbq",
    "beach",
    "beer",
    "bicycle-share",
    "bicycle",
    "blood-bank",
    "bowling-alley",
    "bridge",
    "building-alt1",
    "building",
    "bus",
    "cafe",
    "campsite",
    "car-rental",
    "car-repair",
    "car",
    "casino",
    "castle-JP",
    "castle",
    "caution",
    "cemetery-JP",
    "cemetery",
    "charging-station",
    "cinema",
    "circle-stroked",
    "circle",
    "city",
    "clothing-store",
    "college-JP",
    "college",
    "commercial",
    "communications-tower",
    "confectionery",
    "construction",
    "convenience",
    "cricket",
    "cross",
    "dam",
    "danger",
    "defibrillator",
    "dentist",
    "diamond",
    "doctor",
    "dog-park",
    "drinking-water",
    "elevator",
    "embassy",
    "emergency-phone",
    "entrance-alt1",
    "entrance",
    "farm",
    "fast-food",
    "fence",
    "ferry-JP",
    "ferry",
    "fire-station-JP",
    "fire-station",
    "fitness-centre",
    "florist",
    "fuel",
    "furniture",
    "gaming",
    "garden-centre",
    "garden",
    "gift",
    "globe",
    "golf",
    "grocery",
    "hairdresser",
    "harbor",
    "hardware",
    "heart",
    "heliport",
    "highway-rest-area",
    "home",
    "horse-riding",
    "hospital-JP",
    "hospital",
    "hot-spring",
    "ice-cream",
    "industry",
    "information",
    "jewelry-store",
    "karaoke",
    "landmark-JP",
    "landmark",
    "landuse",
    "laundry",
    "library",
    "lighthouse-JP",
    "lighthouse",
    "lodging",
    "logging",
    "marker-stroked",
    "marker",
    "mobile-phone",
    "monument-JP",
    "monument",
    "mountain",
    "museum",
    "music",
    "natural",
    "observation-tower",
    "optician",
    "paint",
    "park-alt1",
    "park",
    "parking-garage",
    "parking",
    "pharmacy",
    "picnic-site",
    "pitch",
    "place-of-worship",
    "playground",
    "police-JP",
    "police",
    "post-JP",
    "post",
    "prison",
    "racetrack-boat",
    "racetrack-cycling",
    "racetrack-horse",
    "racetrack",
    "rail-light",
    "rail-metro",
    "rail",
    "ranger-station",
    "recycling",
    "religious-buddhist",
    "religious-christian",
    "religious-jewish",
    "religious-muslim",
    "religious-shinto",
    "residential-community",
    "restaurant-bbq",
    "restaurant-noodle",
    "restaurant-pizza",
    "restaurant-seafood",
    "restaurant-sushi",
    "restaurant",
    "road-accident",
    "roadblock",
    "rocket",
    "school-JP",
    "school",
    "scooter",
    "shelter",
    "shoe",
    "shop",
    "skateboard",
    "skiing",
    "slaughterhouse",
    "slipway",
    "snowmobile",
    "soccer",
    "square-stroked",
    "square",
    "stadium",
    "star-stroked",
    "star",
    "suitcase",
    "swimming",
    "table-tennis",
    "teahouse",
    "telephone",
    "tennis",
    "theatre",
    "toilet",
    "town-hall",
    "town",
    "triangle-stroked",
    "triangle",
    "veterinary",
    "viewpoint",
    "village",
    "volcano",
    "volleyball",
    "warehouse",
    "waste-basket",
    "watch",
    "water",
    "waterfall",
    "watermill",
    "wetland",
    "wheelchair",
    "windmill",
    "zoo",
];

const iconToPath: Readonly<{ [key: string]: string }> = Object.fromEntries(
    iconKeys.map((icon) => {
        return [
            icon,
            `/dist/img/canvas/map/icons/${icon}.svg`
        ];
    })
);

export { iconKeys, iconToPath };
