import axios from "./ServerConnection";

export function addEmailApi(email: string): Promise<void> {
	let requestJson: {
		email: string;
	} = {
		email: email,
	};
	return axios
		.post<{
			success: boolean;
			error_msg: string;
		}>("/api/add_email", requestJson)
		.then((response) => {
			if (response.data.success) {
				return Promise.resolve();
			} else {
				console.log(response.data.error_msg);
				return Promise.reject(response.data.error_msg);
			}
		})
		.catch((error) => {
			console.log(error);
			return Promise.reject(error);
		});
}

export function verifyEmailApi(id: string) {
	let requestJson: {
		id: string;
	} = {
		id: id,
	};
	return axios
		.post<{
			success: boolean;
			error_msg: string;
		}>("/api/verify_email", requestJson)
		.then((response) => {
			if (response.data.success) {
				return Promise.resolve();
			} else {
				console.log(response.data.error_msg);
				return Promise.reject(response.data.error_msg);
			}
		})
		.catch((error) => {
			console.log(error);
			return Promise.reject(error);
		});
}

export function getEmailApi(): Promise<{ email: string; verified: boolean }> {
	return axios
		.post<{
			success: boolean;
			error_msg: string;
			email: string;
			verified: boolean;
		}>("/api/get_email", {})
		.then((response) => {
			if (response.data.success) {
				return Promise.resolve({
					email: response.data.email,
					verified: response.data.verified,
				});
			} else {
				console.log(response.data.error_msg);
				return Promise.reject(response.data.error_msg);
			}
		})
		.catch((error) => {
			console.log(error);
			return Promise.reject(error);
		});
}

export function initiatePasswordResetApi(userName: string): Promise<void> {
	let requestJson: {
		user_name: string;
	} = {
		user_name: userName,
	};
	return axios
		.post<{
			success: boolean;
			error_msg: string;
		}>("/api/e/initiate_password_reset", requestJson)
		.then((response) => {
			if (response.data.success) {
				return Promise.resolve();
			} else {
				console.log(response.data.error_msg);
				return Promise.reject(response.data.error_msg);
			}
		})
		.catch((error) => {
			console.log(error);
			return Promise.reject(error);
		});
}

export function passwordResetApi(id: string, password: string): Promise<void> {
	let requestJson: {
		id: string;
		password: string;
	} = {
		id: id,
		password: password,
	};
	return axios
		.post<{
			success: boolean;
			error_msg: string;
		}>("/api/e/reset_password", requestJson)
		.then((response) => {
			if (response.data.success) {
				return Promise.resolve();
			} else {
				console.log(response.data.error_msg);
				return Promise.reject(response.data.error_msg);
			}
		})
		.catch((error) => {
			console.log(error);
			return Promise.reject(error);
		});
}
