import {
    Canvas,
    CanvasGrid,
    CanvasDashboard,
    CanvasNode,
    CanvasElement,
    CanvasTextBox,
    isBox,
    isSimpleSpreadSheetInput,
    isSlider,
    isTextBox,
    isDropdownSelector,
    generateRawMetric,
    InnerCanvasChanges,
    StatusExpression,
    NotificationExpression,
    PrintExpression,
    MapElement,
    isProgressElement,
} from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import { Condition } from "common/Conditions";
import _ from "lodash";

export function clearCanvasCalculatedValues(
    slide: InnerCanvasChanges,
    clearSpreadSheetMetricsForDatasetSpreadSheets = false
) {
    for (let node of Object.values(slide.canvasTreeState ?? {})) {
        if (node != null) clearNodeCalculatedValues(node);
    }
    for (let grid of Object.values(slide.gridsState ?? {})) {
        if (grid != null) {
            clearGridCalculatedValues(grid);
            if (
                clearSpreadSheetMetricsForDatasetSpreadSheets &&
                grid.fullSpreadSheetBackendOutputOptions != null
            ) {
                let nodes = Object.values(slide.canvasTreeState ?? {}).filter(
                    (node) =>
                        node != null &&
                        isSimpleSpreadSheetInput(node) &&
                        node.gridId === grid!.id
                );
                for (let node of nodes) {
                    node!.metric = "";
                }
            }
        }
    }
    for (let dashboard of Object.values(slide.dashboardsState ?? {})) {
        if (dashboard != null) clearDashboardCalculatedValues(dashboard);
    }
    for (let mapElement of Object.values(slide.mapElementsState ?? {})) {
        if (mapElement != null) clearMapCalculatedValues(mapElement);
    }
}

function mergeConditions(
    newConditions: Condition[] | undefined | null,
    oldConditions: Condition[] | undefined | null
) {
    if (newConditions == null || oldConditions == null) return;
    for (let i = 0; i < newConditions.length; ++i) {
        let condition = newConditions[i];
        let oldCondition = oldConditions[i];
        if (
            condition?.isInput &&
            oldCondition?.isInput &&
            condition.value != null &&
            oldCondition.value != null
        ) {
            (condition.value as NodeLinkOption).target = (
                oldCondition.value as NodeLinkOption
            ).target;
        }
    }
}

function mergeStatusExpressions(
    newStatusExpressions:
        | StatusExpression[]
        | NotificationExpression[]
        | PrintExpression[]
        | undefined,
    oldStatusExpressions:
        | StatusExpression[]
        | NotificationExpression[]
        | PrintExpression[]
        | undefined
) {
    if (newStatusExpressions == null || oldStatusExpressions == null) return;
    for (let i = 0; i < newStatusExpressions.length; ++i) {
        let subExpressions = newStatusExpressions[i].subexpressions;
        for (let j = 0; j < subExpressions.length; ++j) {
            let newSubExpression = subExpressions[j];
            let oldSubExpression = oldStatusExpressions[i]?.subexpressions[j];
            if (
                newSubExpression?.isInput &&
                oldSubExpression?.isInput &&
                newSubExpression?.value != null &&
                oldSubExpression?.value != null
            ) {
                (newSubExpression.value as NodeLinkOption).target = (
                    oldSubExpression.value as NodeLinkOption
                ).target;
            }
        }
    }
}

export function mergeNodes(
    oldNode: any,
    newNode: any,
    name: keyof Canvas["canvas"]
): any {
    let mergedNode = {
        ...oldNode,
        ...newNode,
    };
    if (name === "dashboardsState") {
        let castedOldNode = oldNode as CanvasDashboard;
        let castedMergedNode = mergedNode as CanvasDashboard;
        if (
            castedMergedNode.finding != null &&
            castedMergedNode.version == null
        )
            castedMergedNode.finding.content =
                castedOldNode.finding?.content ?? null;
        mergeConditions(
            castedMergedNode.finding?.config?.conditions,
            castedOldNode.finding?.config?.conditions
        );
        mergeStatusExpressions(
            castedMergedNode.finding?.config?.statusExpressions,
            castedOldNode.finding?.config?.statusExpressions
        );
    }
    if (name === "gridsState") {
        let castedOldNode = oldNode as CanvasGrid;
        let castedMergedNode = mergedNode as CanvasGrid;
        mergeConditions(
            castedMergedNode.fullSpreadSheetBackendOutputOptions?.conditions,
            castedOldNode.fullSpreadSheetBackendOutputOptions?.conditions
        );
    }

    if (name === "canvasTreeState") {
        let castedOldNode = oldNode as CanvasNode;
        let castedMergedNode = mergedNode as CanvasNode;
        if (
            (isTextBox(castedOldNode) || isBox(castedOldNode)) &&
            (isTextBox(castedMergedNode) || isBox(castedMergedNode))
        ) {
            for (
                let i = 0;
                i <
                (
                    (castedMergedNode as CanvasElement).dataTableInputDetails ??
                    []
                ).length;
                ++i
            ) {
                let newConditions = (castedMergedNode as CanvasElement)
                    .dataTableInputDetails?.[i].conditions;
                let oldConditions = (castedOldNode as CanvasElement)
                    .dataTableInputDetails?.[i].conditions;
                mergeConditions(newConditions, oldConditions);
            }
        }
        if (
            isDropdownSelector(castedOldNode) &&
            isDropdownSelector(castedMergedNode)
        ) {
            mergeConditions(
                castedMergedNode.conditions,
                castedOldNode.conditions
            );
        }

        if (
            (isTextBox(castedOldNode) || isBox(castedOldNode)) &&
            (isTextBox(castedMergedNode) || isBox(castedMergedNode))
        ) {
            mergeStatusExpressions(
                castedMergedNode.statusExpressions,
                castedOldNode.statusExpressions
            );
            mergeStatusExpressions(
                castedMergedNode.notificationExpressions,
                castedOldNode.notificationExpressions
            );
        }
        if (isTextBox(castedOldNode) && isTextBox(castedMergedNode)) {
            mergeStatusExpressions(
                castedMergedNode.printExpressions,
                castedOldNode.printExpressions
            );
        }
    }
    return mergedNode;
}

export function argsAreEquals(oldNode: CanvasNode, newNode: CanvasNode) {
    let result = true;
    if (
        (isTextBox(oldNode) || isBox(oldNode)) &&
        (isTextBox(newNode) || isBox(newNode))
    ) {
        result =
            result &&
            _.isEqual(
                oldNode.dataTableInputDetails,
                newNode.dataTableInputDetails
            );
    }
    return result;
}

function clearNodeCalculatedValues(node: CanvasNode) {
    if (isBox(node) || isTextBox(node)) {
        node.value = NaN;
        for (let additionalOutput of (node as CanvasElement | CanvasTextBox)
            .additionalOutputs) {
            additionalOutput.value = NaN;
        }
        if (isTextBox(node) && node.rawMetric != null) {
            node.rawMetric = generateRawMetric(
                node as CanvasTextBox,
                node.rawMetric,
                true
            );
        }
    }
    if (isSimpleSpreadSheetInput(node) || isProgressElement(node)) {
        node.value = NaN;
    }
    if (isSlider(node) || isProgressElement(node)) {
        if (node.maxOutput != null) node.maxOutput.value = NaN;
        if (node.minOutput != null) node.minOutput.value = NaN;
    }
    if (isProgressElement(node) && node.errorOutput != null) {
        node.errorOutput.value = NaN;
    }

    if (
        (isTextBox(node) ||
            isBox(node) ||
            isSlider(node) ||
            isProgressElement(node)) &&
        node.dataTableInputDetails != null &&
        node.dataTableInputDetails.length !== 0
    ) {
        for (let dataTableInput of (node as CanvasElement)
            .dataTableInputDetails ?? []) {
            for (let condition of dataTableInput.conditions) {
                if (condition.isInput && condition.value != null) {
                    (condition.value as NodeLinkOption).target = NaN;
                }
            }
        }
    }
    if (isDropdownSelector(node)) {
        for (let condition of node.conditions ?? []) {
            if (condition.isInput && condition.value != null) {
                (condition.value as NodeLinkOption).target = NaN;
            }
        }
    }
    if (
        (isTextBox(node) || isBox(node)) &&
        node.statusExpressions != null &&
        node.statusExpressions.length !== 0
    ) {
        for (let statusExpression of (node as CanvasElement)
            .statusExpressions ?? []) {
            for (let statusSubExpression of statusExpression.subexpressions ??
                []) {
                if (
                    statusSubExpression.isInput &&
                    statusSubExpression.value != null
                ) {
                    (statusSubExpression.value as NodeLinkOption).target = NaN;
                }
            }
        }
    }
    if (
        (isTextBox(node) || isBox(node)) &&
        node.notificationExpressions != null &&
        node.notificationExpressions.length !== 0
    ) {
        for (let notificationExpression of (node as CanvasElement)
            .notificationExpressions ?? []) {
            for (let statusSubExpression of notificationExpression.subexpressions ??
                []) {
                if (
                    statusSubExpression.isInput &&
                    statusSubExpression.value != null
                ) {
                    (statusSubExpression.value as NodeLinkOption).target = NaN;
                }
            }
        }
    }
    if (
        isTextBox(node) &&
        node.printExpressions != null &&
        node.printExpressions.length !== 0
    ) {
        for (let printExpression of node.printExpressions ?? []) {
            for (let statusSubExpression of printExpression.subexpressions ??
                []) {
                if (
                    statusSubExpression.isInput &&
                    statusSubExpression.value != null
                ) {
                    (statusSubExpression.value as NodeLinkOption).target = NaN;
                }
            }
        }
    }
}

function clearDashboardCalculatedValues(item: CanvasDashboard) {
    if (
        item.version == null &&
        item.finding != null &&
        !item.finding.config?.cacheData
    ) {
        item.finding.content = null;
    }

    for (let condition of item.finding?.config?.conditions ?? []) {
        if (condition.isInput && condition.value != null) {
            (condition.value as NodeLinkOption).target = NaN;
        }
    }
    for (let statusExpression of item.finding?.config?.statusExpressions ??
        []) {
        for (let statusSubExpression of statusExpression.subexpressions ?? []) {
            if (
                statusSubExpression.isInput &&
                statusSubExpression.value != null
            ) {
                (statusSubExpression.value as NodeLinkOption).target = NaN;
            }
        }
    }
}

function clearMapCalculatedValues(item: MapElement) {
    for (let condition of item.conditions ?? []) {
        if (condition.isInput && condition.value != null) {
            (condition.value as NodeLinkOption).target = NaN;
        }
    }
}

function clearGridCalculatedValues(item: CanvasGrid) {
    for (let condition of item.fullSpreadSheetBackendOutputOptions
        ?.conditions ?? []) {
        if (condition.isInput && condition.value != null) {
            (condition.value as NodeLinkOption).target = NaN;
        }
    }
}
