import React from "react";
import { PieChartFinding } from "common/Finding";
import PieChartD3 from "common/graphics/v2/D3Charts/PieChartD3";

function MainComponent(props: {
    finding: PieChartFinding;
    onNewFinding?: (finding: PieChartFinding, updateData?: boolean) => void;
    preview?: boolean;
    columnDragActive?: boolean;
    editable?: boolean;
    currentModuleId?: number;
}) {
    let finding = props.finding;

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <PieChartD3
                data={
                    finding.content.data ??
                    [] /* data an be null https://eisengardai.atlassian.net/browse/EIS-414 */
                }
                additionalVaryByData={finding.content.additionalVaryByData}
                varyByData={finding.content.varyByData}
                groupNames={finding.content.groupNames}
                config={finding.config}
                columnDragActive={props.columnDragActive}
                preview={props.preview}
                editable={props.editable}
                onChangeData={
                    props.onNewFinding
                        ? (data: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.content.data = data;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeConfig={
                    props.onNewFinding
                        ? (config: any, updateData?: boolean) => {
                              let finding = { ...props.finding };
                              finding.config = config;
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                currentModuleId={props.currentModuleId}
            />
        </div>
    );
}

export { MainComponent };
