import { formatDate } from "common/utilities/FormatDate";

export interface Insight {
    contents: {
        title: string,
        insight: string,
        imageSrc: string,
        recommendation: string,
        finding: {
            type: string,
            // TODO Replace any with a more specific type
            contents: any,
            config?: any
        }
    };
    id: number;
    tab_id: number;
    user_data?: { // TODO Check if ? is necessary here
        user_name: string,
        first_name: string,
        last_name: string,
        icon_url: string,
        is_admin: boolean,
        is_owner: boolean
    };
    creation_time: string;
    update_time: string;
    raw_timestamp: number;
    raw_update_timestamp: number;
    has_new_feedbacks: boolean;
    has_updates?: boolean; // TODO Check if ? is necessary here
}

// Insight as it is sent by the server
export interface InsightResponse {
    contents: string; // contents is encoded as string
    id: number;
    tab_id: number;
    user_data: {
        user_name: string,
        first_name: string,
        last_name: string,
        icon_url: string,
        is_admin: boolean,
        is_owner: boolean
    };
    creation_time: number; // timestamp
    update_time: number; // timestamp
    has_new_feedbacks: boolean;
    has_updates?: boolean;
}

export function toInsight(card: InsightResponse): Insight {
    return {
        contents: JSON.parse(card.contents),
        id: card.id,
        tab_id: card.tab_id,
        user_data: card.user_data,
        creation_time: formatDate(
            card.creation_time,
            "created"
        ),
        update_time: formatDate(card.update_time, "updated"),
        raw_timestamp: card.creation_time,
        raw_update_timestamp: card.update_time,
        has_new_feedbacks: card.has_new_feedbacks,
        has_updates: card.has_updates
    }
}
