import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Switch from "react-switch";
import cx from "classnames";

import { CanvasElement, CanvasTextBox } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import UserInfo from "common/UserInfo";
import UserDelegate from "common/UserDelegate";
import ConditionalFormatting from "../../common/ConditionalFormatting";
import SmartNotifications from "../../common/SmartNotifications";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import styles from "./Settings.module.css";
import PrintConditions from "./PrintConditions";

interface Props {
  node: CanvasTextBox;
  delegate?: UserInfo;
  userOptions: UserInfo[];
  nodeLinkOptions: NodeLinkOption[];
  shared: boolean;
  onChange: (changes: Partial<CanvasElement>, commit?: boolean) => void;
  onChangeDelegate: (user?: UserInfo) => void;
  onChangeShared: (shared: boolean) => void;
}

enum CollapsableMenuOptions {
  None = 0,
  ConditionalFormatting = 1,
  SmartNotifications = 2,
  PrintConditions = 3,
}

function Settings({ node, delegate, userOptions, shared, nodeLinkOptions,
  onChange, onChangeDelegate, onChangeShared
}: Props) {
  const [currentOpenedMenu, setCurrentOpenedMenu] = useState(
    CollapsableMenuOptions.None
  );

  return (
    <div className={styles.root}>
      <div className={styles.userSelect}>
        <label
          htmlFor="advanced-menu-box-user-select"
          className={styles.inputLabel}
        >
          Assign to
        </label>
        <UserDelegate
          id="advanced-menu-box-user-select"
          value={delegate}
          options={userOptions}
          onChange={onChangeDelegate}
        />
      </div>
      <div className={styles.switchField}>
        <Switch
          onChange={(checked) => {
            onChangeShared(checked);
          }}
          checked={shared}
          width={26}
          height={13}
          offColor="#D1D1D1"
          onColor="#20293C"
          checkedIcon={false}
          uncheckedIcon={false}
          offHandleColor="#FFF"
          onHandleColor="#1F8EFA"
        />
        <span className={styles.switchLabel}>
          Linkable
        </span>
      </div>
      <div className={styles.switchField}>
        <Switch
          onChange={(checked) => {
            onChange({ liveStreaming: checked });
          }}
          checked={node.liveStreaming ?? false}
          width={26}
          height={13}
          offColor="#D1D1D1"
          onColor="#20293C"
          checkedIcon={false}
          uncheckedIcon={false}
          offHandleColor="#FFF"
          onHandleColor="#1F8EFA"
        />
        <span className={styles.switchLabel}>
          Stream Metric to Dataset
        </span>
      </div>
      <Accordion
        activeKey={String(currentOpenedMenu)}
        onSelect={(event: any) => {
          setCurrentOpenedMenu(Number(event));
        }}
      >
        <Accordion.Toggle
          eventKey={String(CollapsableMenuOptions.ConditionalFormatting)}
          className={styles.editMenuAdvancedOption}
        >
          Conditional Formatting
          <ChevronIcon
            className={cx(styles.editMenuAdvancedOptionIcon, {
              [styles.chevronOpen]:
                currentOpenedMenu ===
                CollapsableMenuOptions.ConditionalFormatting,
            })}
          />
        </Accordion.Toggle>
        <Accordion.Collapse
          eventKey={String(CollapsableMenuOptions.ConditionalFormatting)}
        >
          <ConditionalFormatting
            node={node}
            nodeLinkOptions={nodeLinkOptions}
            onChange={onChange}
          />
        </Accordion.Collapse>
        <Accordion.Toggle
          eventKey={String(CollapsableMenuOptions.SmartNotifications)}
          className={styles.editMenuAdvancedOption}
        >
          Smart Notifications
          <ChevronIcon
            className={cx(styles.editMenuAdvancedOptionIcon, {
              [styles.chevronOpen]:
                currentOpenedMenu ===
                CollapsableMenuOptions.SmartNotifications,
            })}
          />
        </Accordion.Toggle>
        <Accordion.Collapse
          eventKey={String(CollapsableMenuOptions.SmartNotifications)}
        >
          <SmartNotifications
            node={node}
            nodeLinkOptions={nodeLinkOptions}
            userOptions={userOptions}
            onChange={onChange}
          />
        </Accordion.Collapse>
        <Accordion.Toggle
          eventKey={String(CollapsableMenuOptions.PrintConditions)}
          className={styles.editMenuAdvancedOption}
        >
          Print Conditions
          <ChevronIcon
            className={cx(styles.editMenuAdvancedOptionIcon, {
              [styles.chevronOpen]:
                currentOpenedMenu ===
                CollapsableMenuOptions.PrintConditions,
            })}
          />
        </Accordion.Toggle>
        <Accordion.Collapse
          eventKey={String(CollapsableMenuOptions.PrintConditions)}
        >
          <PrintConditions
            node={node}
            nodeLinkOptions={nodeLinkOptions}
            onChange={onChange}
          />
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
}

export default Settings;
