import React from "react";
import { ButtonStyle } from "common/Canvas";
import { defaultFontSize } from "../Constants";
import {
	RGBAToHexA,
	hexToRGBComponents,
	RGBAStrToRGBAComponents,
} from "common/utilities/ColorUtils";

interface Button {
	borderShadow?: boolean;
	fillColor?: string | null;
	fontColor?: string | null;
	fontSize?: number;
	buttonStyle?: ButtonStyle;
}
const hoverOffset = 20;
const mouseDownOffset = -20;

function getModifiedColor(color: string, hovered: boolean, mouseDown: boolean) {
	if (!hovered && !mouseDown) return color;
	let offset = mouseDown ? mouseDownOffset : hovered ? hoverOffset : 0;
	let colorComponents = [];

	if (color === "transparent") {
		colorComponents = [0, 0, 0, 0];
	} else if (color.startsWith("#")) {
		colorComponents = hexToRGBComponents(color);
	} else {
		colorComponents = RGBAStrToRGBAComponents(color);
	}
	for (let i = 0; i < 3; ++i) {
		colorComponents[i] = Math.max(
			0,
			Math.min(255, colorComponents[i] + offset)
		);
	}
	return RGBAToHexA(
		colorComponents[0],
		colorComponents[1],
		colorComponents[2],
		colorComponents[3] ?? 1
	);
}

function getDefaultStyle(
	button: Button,
	scale: number,
	hovered: boolean,
	mouseDown: boolean
) {
	let containerStyle: React.CSSProperties = {
		background: button.fillColor ?? "#1F8EFA",
		borderRadius: 0,
		border: "none",
	};
	containerStyle.background = getModifiedColor(
		containerStyle.background as string,
		hovered,
		mouseDown
	);
	let textStyle: React.CSSProperties = {
		color: button.fontColor ?? "#FFFFFF",
		fontWeight: 500,
		fontSize: (button.fontSize ?? defaultFontSize) * scale,
		textTransform: "uppercase",
	};
	return {
		containerStyle: containerStyle,
		textStyle: textStyle,
	};
}

function getRefineStyle(
	button: Button,
	scale: number,
	hovered: boolean,
	mouseDown: boolean
) {
	let defaultFontColor = button.fontColor ?? "#3399FF";
	let colorComponents = [];
	if (defaultFontColor === "transparent") {
		colorComponents = [0, 0, 0, 0];
	} else if (defaultFontColor.startsWith("#")) {
		colorComponents = hexToRGBComponents(defaultFontColor);
	} else {
		colorComponents = RGBAStrToRGBAComponents(defaultFontColor);
	}
	let originalColor = RGBAToHexA(
		colorComponents[0],
		colorComponents[1],
		colorComponents[2],
		1
	);
	let opacityColor = RGBAToHexA(
		colorComponents[0],
		colorComponents[1],
		colorComponents[2],
		0.6
	);

	originalColor = getModifiedColor(
		originalColor as string,
		hovered,
		mouseDown
	);
	opacityColor = getModifiedColor(opacityColor as string, hovered, mouseDown);

	let containerStyle: React.CSSProperties = {
		background: button.fillColor ?? "#FFFFFF",
		borderRadius: !button.borderShadow ? 0 : 1,
		border: `1px solid ${opacityColor}`,
	};
	let textStyle: React.CSSProperties = {
		fontWeight: 500,
		fontSize: (button.fontSize ?? defaultFontSize) * scale,
		color: originalColor,
		textTransform: "uppercase",
	};
	return {
		containerStyle: containerStyle,
		textStyle: textStyle,
	};
}

function getProStyle(
	button: Button,
	scale: number,
	hovered: boolean,
	mouseDown: boolean
) {
	let fillColor = button.fillColor ?? "#3198FF";
	let colorComponents = [];
	if (fillColor === "transparent") {
		colorComponents = [0, 0, 0, 0];
	} else if (fillColor.startsWith("#")) {
		colorComponents = hexToRGBComponents(fillColor);
	} else {
		colorComponents = RGBAStrToRGBAComponents(fillColor);
	}
	let originalColor = RGBAToHexA(
		colorComponents[0],
		colorComponents[1],
		colorComponents[2],
		1
	);
	let opacityColor = RGBAToHexA(
		colorComponents[0],
		colorComponents[1],
		colorComponents[2],
		0.8
	);
	originalColor = getModifiedColor(
		originalColor as string,
		hovered,
		mouseDown
	);
	opacityColor = getModifiedColor(opacityColor as string, hovered, mouseDown);
	let containerStyle: React.CSSProperties = {
		background: `linear-gradient(273.12deg, ${originalColor}, ${opacityColor})`,
		borderRadius: 4,
		border: "none",
	};
	let textStyle: React.CSSProperties = {
		color: button.fontColor ?? "#FFFFFF",
		fontWeight: 500,
		fontSize: (button.fontSize ?? defaultFontSize) * scale,
		textTransform: "uppercase",
	};
	return {
		containerStyle: containerStyle,
		textStyle: textStyle,
	};
}

export function getButtonStyle(
	button: Button,
	scale: number,
	hovered: boolean = false,
	mouseDown: boolean = false
): {
	containerStyle: React.CSSProperties;
	textStyle: React.CSSProperties;
} {
	switch (button.buttonStyle) {
		case ButtonStyle.Pro:
			return getProStyle(button, scale, hovered, mouseDown);
		case ButtonStyle.Refine:
			return getRefineStyle(button, scale, hovered, mouseDown);
		case ButtonStyle.Default:
			return getDefaultStyle(button, scale, hovered, mouseDown);
		default:
			return getDefaultStyle(button, scale, hovered, mouseDown);
	}
}