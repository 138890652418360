import { Component } from "react";
import countryData from "../../countries.json";
import statesUsData from "../../states_us.json";
import * as d3 from "d3";
import Leaflet from "leaflet";
import { ChoroplethDataInterface, DashboardMap } from "../types";
import { getPlaceBoundaries, getPlacePathData } from "../helpers";
import {
    ChoroplethLevel,
    defaultHeatmapGradient,
    GradientColorPointer,
    defaultChoroplethOpacity,
    ChoroplethBorder,
} from "../../Finding";
import {
    isNumberFormat,
    formatNumber,
    MapVariableViewOptions,
} from "../../Canvas";

interface IProps {
    handleLoaded: (state: boolean) => void;
    data: ChoroplethDataInterface[];
    currentTimeValue?: number | string;
    gradient: Array<GradientColorPointer>;
    opacity: number;
    showBorders: boolean;
    map: Leaflet.Map;
    level: ChoroplethLevel;
    tooltipMapLevel: number;
    clickedTileData: ChoroplethDataInterface | null;
    maps?: Array<DashboardMap>;
    choroplethMetricVariableOptions: MapVariableViewOptions;
    choroplethBorder: ChoroplethBorder;
    onShowChoroplethPopup: (
        choroplethData: ChoroplethDataInterface,
        dataIndex: number,
        tooltipMapLevel: number
    ) => void;
}

export class ChoroplethMap extends Component<IProps> {
    static defaultProps = {
        gradient: defaultHeatmapGradient,
        opacity: defaultChoroplethOpacity,
        showBorders: false,
        choroplethBorder: {
            thickness: 1,
            color: null,
        },
    };

    private data: ChoroplethDataInterface[];

    private colorScale: d3.ScaleLinear<string, string, string> | null;

    private d3Objects:
        | {
              svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
              rootG: d3.Selection<SVGGElement, unknown, null, undefined>;
              paths: d3.Selection<
                  SVGPathElement,
                  ChoroplethDataInterface,
                  SVGGElement,
                  undefined
              >;
          }
        | undefined;

    public boundaries: {
        [key: string]: number[][] | number[][][];
    };

    constructor(props: IProps) {
        super(props);

        this.data = [];
        this.boundaries = {};
        this.colorScale = null;

        this.getPathData = this.getPathData.bind(this);
        this.getPathMetric = this.getPathMetric.bind(this);
        this.getPathColor = this.getPathColor.bind(this);
        this.getPathHidden = this.getPathHidden.bind(this);
        this.onMapViewReset = this.onMapViewReset.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    private getBoundaries(): void {
        this.props.handleLoaded(true);
        let uniqueValues = Array.from(
            new Set(this.data.map((item) => String(item.country)))
        );

        getPlaceBoundaries(uniqueValues, this.props.level, (data) => {
            this.props.handleLoaded(false);
            this.boundaries = data.boundaries;
            this.d3Objects?.paths.attr("d", this.getPathData);
        });
    }

    public componentDidMount(): void {
        this.updateDataFromProps();

        let svg = d3
            .select(this.props.map.getPane("overlayPane")!)
            .select<SVGSVGElement>("svg");
        // In new versions of Leaflet the svg already has a g appended to it
        let rootG = svg.select<SVGGElement>("g").append("g");

        let paths = this.createPaths(
            rootG
                .selectAll<SVGPathElement, ChoroplethDataInterface[]>("path")
                .data(this.data)
        );
        this.d3Objects = {
            svg: svg,
            rootG: rootG,
            paths: paths,
        };

        this.props.map.on("viewreset", this.onMapViewReset);
        this.props.map.on("zoom", this.onMapViewReset);

        if (
            this.props.level === "zipcode_us" ||
            this.props.level === "county_us"
        ) {
            this.getBoundaries();
        }
    }

    public componentDidUpdate(prevProps: IProps): void {
        if (prevProps.data !== this.props.data) {
            this.updateDataFromProps();
            // Update the data
            if (this.d3Objects != null) {
                this.d3Objects.paths = this.createPaths(
                    this.d3Objects.rootG
                        .selectAll<SVGPathElement, ChoroplethDataInterface[]>(
                            "path"
                        )
                        .data(this.data)
                );
            }
        }
        if (
            prevProps.data !== this.props.data ||
            prevProps.gradient !== this.props.gradient ||
            prevProps.opacity !== this.props.opacity ||
            prevProps.showBorders !== this.props.showBorders ||
            prevProps.level !== this.props.level
        ) {
            this.updateDataFromProps();
            // Update the data
            if (this.d3Objects != null) {
                this.d3Objects.paths = this.updatePaths(
                    this.d3Objects.rootG
                        .selectAll<SVGPathElement, ChoroplethDataInterface[]>(
                            "path"
                        )
                        .data(this.data)
                );
            }
        }
        if (
            // If we don't check for length !== 0, it might trigger an infinite
            // loop: https://eisengardai.atlassian.net/browse/EIS-368
            this.props.data.length !== 0 &&
            prevProps.data !== this.props.data &&
            (this.props.level === "zipcode_us" ||
                this.props.level === "county_us")
        ) {
            this.getBoundaries();
        }
        if (prevProps.currentTimeValue !== this.props.currentTimeValue) {
            this.d3Objects?.paths.attr("hidden", this.getPathHidden);
        }
    }

    public componentWillUnmount(): void {
        // In new versions of Leaflet the svg already has a g appended to it
        let rootG = this.d3Objects?.rootG;

        rootG
            ?.selectAll<SVGPathElement, ChoroplethDataInterface[]>("path")
            .remove();
    }

    public render(): JSX.Element | null {
        const format = this.props.choroplethMetricVariableOptions?.format;
        let extent = d3.extent(this.props.data, this.getPathMetric);
        if (extent[0] == null) {
            return null;
        }
        let legendMarginRight = 30;
        for (let map of this.props.maps ?? []) {
            if (map.finding.config.varyMarkerColorByVariable) {
                legendMarginRight += 130;
            }
        }
        let legendValues: (string | number)[] = [
            extent[0],
            (extent[1] + extent[0]) / 2,
            extent[1],
        ];

        if (isNumberFormat(format)) {
            legendValues = legendValues.map((val) =>
                formatNumber(val as number, format)
            );
        }

        const gradient = this.props.gradient
            .map((item) => item.color)
            .reverse()
            .join(",");
        return (
            <div
                style={{
                    position: "absolute",
                    bottom: "0px",
                    left: "0px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    width: "100%",
                    height: "100%",
                    zIndex: 999,
                    pointerEvents: "none",
                }}
            >
                <div
                    style={{
                        marginTop: "30px",
                        marginRight: legendMarginRight,
                        maxHeight: "calc(100% - 65px)",
                        opacity: 0.9,
                        boxShadow: "0 1px 3px rgba(0,0,0,.4)",
                        paddingLeft: "6px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        paddingRight: "8px",
                        borderRadius: "3px",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "white",
                        backgroundColor: "white",
                        userSelect: "none",
                        display: "flex",
                        flexDirection: "column",
                        overflowY: "auto",
                        pointerEvents: "auto",
                        scrollbarWidth: "thin",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                backgroundImage: `linear-gradient(to bottom, ${gradient})`,
                                width: "12px",
                                height: "54px",
                            }}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                marginLeft: "5px",
                            }}
                        >
                            <span
                                className="regular-text"
                                style={{
                                    color: "black",
                                    fontSize: "12px",
                                }}
                            >
                                {legendValues[2]}
                            </span>
                            <span
                                className="regular-text"
                                style={{
                                    color: "black",
                                    fontSize: "12px",
                                }}
                            >
                                {legendValues[1]}
                            </span>
                            <span
                                className="regular-text"
                                style={{
                                    color: "black",
                                    fontSize: "12px",
                                }}
                            >
                                {legendValues[0]}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private updateDataFromProps(): void {
        this.data = this.props.data;
        let extent = d3.extent(this.data, this.getPathMetric);
        const gradient = this.props.gradient.map((item) => item.color);
        const minColor = gradient[0];

        if (extent[0] != null) {
            let minValue = extent[0];
            let maxValue = extent[1] ?? minValue;
            const values = this.props.gradient.map((item) => {
                return maxValue * item.value;
            });
            this.colorScale = d3
                .scaleLinear(values, gradient)
                .unknown(minColor);
        } else {
            this.colorScale = null;
        }
    }

    private getPathData(data: ChoroplethDataInterface): string {
        if (data.country == null) {
            return "";
        }

        let dataSource: {
            [key: string]: number[][] | number[][][];
        };
        switch (this.props.level) {
            case "state_us":
                dataSource = statesUsData;
                break;
            case "zipcode_us":
            case "county_us":
                dataSource = this.boundaries;
                break;
            default:
                dataSource = countryData;
                break;
        }

        let polygon: number[][] | number[][][] | undefined =
            dataSource[String(data.country).toLowerCase()];

        return getPlacePathData(this.props.map, polygon);
    }

    private getPathMetric(
        data: ChoroplethDataInterface
    ): number | undefined | null {
        return data.metric;
    }

    private getPathColor(data: ChoroplethDataInterface): string {
        if (this.colorScale != null && data.metric != null) {
            return this.colorScale(data.metric);
        } else {
            return this.props.gradient[0].color;
        }
    }

    private getPathHidden(data: ChoroplethDataInterface): true | null {
        return this.props.currentTimeValue == null ||
            data.time == null ||
            data.time <= this.props.currentTimeValue
            ? null
            : true;
    }

    private onClick(event: MouseEvent, data: ChoroplethDataInterface) {
        const dataIndex = this.props.data.findIndex(
            (item) => item.country === data.country
        );
        this.props.onShowChoroplethPopup(
            data,
            dataIndex,
            this.props.tooltipMapLevel
        );
    }
    private updatePaths(
        updateSelection: d3.Selection<
            SVGPathElement,
            ChoroplethDataInterface,
            SVGGElement,
            undefined
        >
    ): d3.Selection<
        SVGPathElement,
        ChoroplethDataInterface,
        SVGGElement,
        undefined
    > {
        const {
            showBorders,
            clickedTileData,
            opacity,
            choroplethBorder,
        } = this.props;

        return updateSelection.join((update) =>
            update
                .append("path")
                .style("pointer-events", "auto")
                .merge(updateSelection)
                .attr("fill-opacity", (d) => {
                    if (opacity <= 0.1) return opacity;
                    const lessOpacity = opacity - 0.1;
                    return d.country === clickedTileData?.country
                        ? lessOpacity
                        : opacity;
                })
                .attr("class", "choropleth-map")
                .attr("fill", this.getPathColor)
                .attr("stroke", (d) => {
                    const { color } = choroplethBorder;
                    if (showBorders || d.country === clickedTileData?.country) {
                        return color ?? this.getPathColor(d);
                    }
                    return "transparent";
                })
                .attr("stroke-width", (d) => {
                    const { thickness } = choroplethBorder;
                    if (d.country === clickedTileData?.country) {
                        return thickness > 4 ? thickness + 3 : 4;
                    }
                    return thickness;
                })
                .attr("hidden", this.getPathHidden)
                .on("click", this.onClick)
        );
    }

    private createPaths(
        updateSelection: d3.Selection<
            SVGPathElement,
            ChoroplethDataInterface,
            SVGGElement,
            undefined
        >
    ): d3.Selection<
        SVGPathElement,
        ChoroplethDataInterface,
        SVGGElement,
        undefined
    > {
        const {
            showBorders,
            clickedTileData,
            opacity,
            choroplethBorder,
        } = this.props;

        return updateSelection.join((update) =>
            update
                .append("path")
                .style("pointer-events", "auto")
                .merge(updateSelection)
                .attr("fill-opacity", (d) => {
                    if (opacity <= 0.1) return opacity;
                    const lessOpacity = opacity - 0.1;
                    return d.country === clickedTileData?.country
                        ? lessOpacity
                        : opacity;
                })
                .attr("class", "choropleth-map")
                .attr("d", this.getPathData)
                .attr("id", (d) => {
                    return d.country === clickedTileData?.country
                        ? "highlighted-tile"
                        : "";
                })
                .attr("fill", this.getPathColor)
                .attr("stroke", (d) => {
                    const { color } = choroplethBorder;
                    if (showBorders || d.country === clickedTileData?.country) {
                        return color ?? this.getPathColor(d);
                    }
                    return "transparent";
                })
                .attr("stroke-width", (d) => {
                    const { thickness } = choroplethBorder;
                    if (d.country === clickedTileData?.country) {
                        return thickness > 4 ? thickness + 3 : 4;
                    }
                    return thickness;
                })
                .attr("hidden", this.getPathHidden)
                .on("click", this.onClick)
        );
    }

    // Update positions on the map
    private onMapViewReset(): void {
        if (this.d3Objects != null) {
            this.d3Objects.paths.attr("d", this.getPathData);
        }
    }
}
