import React from "react";
import Select, { createFilter } from "react-select";
import { LeadersLaggersFinding } from "common/Finding";
import styles from "../StylingSection.module.css";
import leadersStyles from "../../DataSection/LeadersLaggersSection/LeadersLaggersSection.module.css";
import ColorPicker from "common/ColorPicker";
import Switch from "react-switch";
import StringUtils from "common/utilities/StringUtils";
import switchStyleProps from "../../SwitchStyleProps";
import Input from "../Input";
import { getCustomSelectStyleLight } from "common/SelectStyles";
import { defaultFontFamily, fontFamilies } from "modules/canvas_page/Constants";
import { dropdownOptionsAligmentOptions } from "common/Canvas";
import StringOption from "common/StringOption";

interface Props {
    finding: LeadersLaggersFinding;
    onChange: (
        config: LeadersLaggersFinding["config"],
        updateData?: boolean
    ) => void;
    currentModuleId?: number;
}

function CustomInput(props: {
    value: string | number;
    containerStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    onChange: (value: string | number) => void;
}) {
    let [value, setValue] = React.useState(props.value);

    return (
        <div className="my-row" style={props.containerStyle}>
            <input
                style={props.style}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                    if (evt.key === "Enter") {
                        props.onChange(value);
                    }
                }}
                className={leadersStyles.input}
                value={value}
                onChange={(evt) => {
                    let newValue = evt.target.value;
                    setValue(newValue);
                }}
                onBlur={() => {
                    props.onChange(value);
                }}
            />
        </div>
    );
}

function LeaderboardSection(props: Props) {
    let { config } = props.finding;
    let selectStyles = getCustomSelectStyleLight(14, false);

    return (
        <>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Top color</span>
                <ColorPicker
                    value={config?.colorMapping?.Leaders || "#39F"}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...config,
                            colorMapping: {
                                ...(config.colorMapping ?? []),
                                Leaders: newValue,
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Bottom color</span>
                <ColorPicker
                    value={config?.colorMapping?.Laggers || "#EE423D"}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...config,
                            colorMapping: {
                                ...(config.colorMapping ?? []),
                                Laggers: newValue,
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Top aliase</span>
                <ColorPicker
                    value={config?.aliaseTextColor?.Leaders || "#39F"}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...config,
                            aliaseTextColor: {
                                ...(config.aliaseTextColor ?? []),
                                Leaders: newValue,
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Bottom aliase</span>
                <ColorPicker
                    value={config?.aliaseTextColor?.Laggers || "#EE423D"}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...config,
                            aliaseTextColor: {
                                ...(config.aliaseTextColor ?? []),
                                Laggers: newValue,
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Title color</span>
                <ColorPicker
                    value={config?.fontStyle?.title?.color || "#3399ff"}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...config,
                            fontStyle: {
                                ...(config.fontStyle ?? {}),
                                title: {
                                    ...config.fontStyle?.title,
                                    color: newValue,
                                },
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Title size</span>
                <Input
                    value={config?.fontStyle?.title?.fontSize || "12"}
                    onChange={(value: string | number) => {
                        const newConfig = {
                            ...config,
                            fontStyle: {
                                ...(config.fontStyle ?? {}),
                                title: {
                                    ...config.fontStyle?.title,
                                    fontSize: StringUtils.isNumber(value)
                                        ? Number(value)
                                        : null,
                                },
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Title font</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={""}
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                        }),
                        option: (base, state) => ({
                            ...base,
                            fontFamily: state.data.value,
                            textAlign: "center",
                        }),
                        singleValue: (base, state) => ({
                            ...selectStyles.singleValue?.(base, state),
                            fontFamily: state.data.value,
                        }),
                    }}
                    options={fontFamilies.map((family) => {
                        return {
                            label: family,
                            value: family,
                        };
                    })}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...config,
                            fontStyle: {
                                ...(config.fontStyle ?? {}),
                                title: {
                                    ...config.fontStyle?.title,
                                    fontFamily: (newValue as StringOption)
                                        .value,
                                },
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                    value={{
                        label:
                            config.fontStyle.title.fontFamily ??
                            defaultFontFamily.label,
                        value:
                            config.fontStyle.title.fontFamily ??
                            defaultFontFamily.value,
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Text color</span>
                <ColorPicker
                    value={config?.fontStyle?.text?.color || "#000"}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...config,
                            fontStyle: {
                                ...(config.fontStyle ?? {}),
                                text: {
                                    ...config.fontStyle?.text,
                                    color: newValue,
                                },
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Text size</span>
                <Input
                    value={config?.fontStyle?.text?.fontSize || "12"}
                    onChange={(value: string | number) => {
                        const newConfig = {
                            ...config,
                            fontStyle: {
                                ...config.fontStyle,
                                text: {
                                    ...config.fontStyle.text,
                                    fontSize: StringUtils.isNumber(value)
                                        ? Number(value)
                                        : null,
                                },
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Text font</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={""}
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                        }),
                        option: (base, state) => ({
                            ...base,
                            fontFamily: state.data.value,
                            textAlign: "center",
                        }),
                        singleValue: (base, state) => ({
                            ...selectStyles.singleValue?.(base, state),
                            fontFamily: state.data.value,
                        }),
                    }}
                    options={fontFamilies.map((family) => {
                        return {
                            label: family,
                            value: family,
                        };
                    })}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...config,
                            fontStyle: {
                                ...(config.fontStyle ?? {}),
                                text: {
                                    ...config.fontStyle?.text,
                                    fontFamily: (newValue as StringOption)
                                        .value,
                                },
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                    value={{
                        label:
                            config.fontStyle.text.fontFamily ??
                            defaultFontFamily.label,
                        value:
                            config.fontStyle.text.fontFamily ??
                            defaultFontFamily.value,
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Aliase size</span>
                <Input
                    value={config?.fontStyle?.aliase?.fontSize || "10"}
                    onChange={(value: string | number) => {
                        const newConfig = {
                            ...config,
                            fontStyle: {
                                ...(config.fontStyle ?? {}),
                                aliase: {
                                    ...config.fontStyle?.aliase,
                                    fontSize: StringUtils.isNumber(value)
                                        ? Number(value)
                                        : null,
                                },
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Aliase font</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={""}
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                        }),
                        option: (base, state) => ({
                            ...base,
                            fontFamily: state.data.value,
                            textAlign: "center",
                        }),
                        singleValue: (base, state) => ({
                            ...selectStyles.singleValue?.(base, state),
                            fontFamily: state.data.value,
                        }),
                    }}
                    options={fontFamilies.map((family) => {
                        return {
                            label: family,
                            value: family,
                        };
                    })}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...config,
                            fontStyle: {
                                ...(config.fontStyle ?? {}),
                                aliase: {
                                    ...config.fontStyle?.aliase,
                                    fontFamily: (newValue as StringOption)
                                        .value,
                                },
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                    value={{
                        label:
                            config.fontStyle.aliase?.fontFamily ??
                            defaultFontFamily.label,
                        value:
                            config.fontStyle.aliase?.fontFamily ??
                            defaultFontFamily.value,
                    }}
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Text alignment</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={""}
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                        }),
                        option: (base, state) => ({
                            ...base,
                            textAlign: "center",
                        }),
                    }}
                    options={dropdownOptionsAligmentOptions}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...config,
                            fontStyle: {
                                ...(config.fontStyle ?? {}),
                                alignment: newValue,
                            },
                        };
                        props.onChange(newConfig, true);
                    }}
                    value={
                        config.fontStyle.alignment ?? {
                            label: "Left",
                            value: "left",
                        }
                    }
                />
            </div>

            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Show rank</span>
                <Switch
                    checked={config?.showRankNumber ?? true}
                    {...switchStyleProps}
                    onChange={() => {
                        let checked = !(config.showRankNumber ?? true);
                        let newConfig = {
                            ...config,
                            showRankNumber: checked,
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>
                    Show legend
                </span>
                <Switch
                    checked={config?.showLegend ?? true}
                    {...switchStyleProps}
                    onChange={() => {
                        let checked = !(config.showLegend ?? true);
                        let newConfig = {
                            ...config,
                            showLegend: checked,
                        };
                        props.onChange(newConfig, true);
                    }}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                    className={leadersStyles.optionName}
                    style={{ marginTop: 20 }}
                >
                    {"Top alias"}
                </span>
                <CustomInput
                    containerStyle={{ marginTop: 7 }}
                    style={{ width: "100%" }}
                    value={config.labelMapping?.["Top"]}
                    onChange={(value) => {
                        props.onChange(
                            {
                                ...config,
                                labelMapping: {
                                    ...props.finding.config.labelMapping,
                                    Leaders: value,
                                },
                            },
                            true
                        );
                    }}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                    className={leadersStyles.optionName}
                    style={{ marginTop: 20 }}
                >
                    {"Bottom alias"}
                </span>
                <CustomInput
                    containerStyle={{ marginTop: 7 }}
                    style={{ width: "100%" }}
                    value={config.labelMapping?.["Bottom"]}
                    onChange={(value) => {
                        props.onChange(
                            {
                                ...config,
                                labelMapping: {
                                    ...props.finding.config.labelMapping,
                                    Laggers: value,
                                },
                            },
                            true
                        );
                    }}
                />
            </div>
        </>
    );
}

export default LeaderboardSection;
