import React from "react";
import { Button } from "react-bootstrap";
import Slider from "rc-slider";
import Draggable from "react-draggable";
import { ReactComponent as PlayIcon } from "icons/animation_slider/play.svg";
import { ReactComponent as PauseIcon } from "icons/animation_slider/pause.svg";
import { ReactComponent as DragIcon } from "icons/animation_slider/drag.svg";

interface AnimationSliderProps {
    values: (number | string)[];
    onChange: (index: number, previousIndex?: number) => void;
    formatter: (value: string | number) => string;
    sliderIntervalTime: number;
    withTranstion: boolean;
    style?: React.CSSProperties;
    sliderStyle?: React.CSSProperties;
    onChangeConfig?: (config: any, updateData?: boolean) => void;
    config?: any;
}

interface AnimationSliderState {
    previousIndex: number | undefined;
    index: number;
    started: boolean;
    dragIsDisabled: boolean;
    sliderPosition: {
        x: number;
        y: number;
    }
}

class AnimationSlider extends React.Component<
    AnimationSliderProps,
    AnimationSliderState
> {
    static defaultProps = {
        formatter: (value: number | string) => value.toString(),
        sliderIntervalTime: 100,
        withTranstion: false,
    };

    private stepInterval: NodeJS.Timer | null;

    constructor(props: AnimationSliderProps) {
        super(props);

        this.state = {
            previousIndex: undefined,
            index: 0,
            started: false,
            dragIsDisabled: true,
            sliderPosition: {
                x: 0,
                y: 0,
            },
        };

        this.stepInterval = null;

        this.step = this.step.bind(this);
    }

    public componentWillUnmount(): void {
        this.stop();
    }

    public componentDidUpdate(prevProps: AnimationSliderProps): void {
        if (prevProps.values !== this.props.values) {
            this.stop();
            this.setState({
                previousIndex: undefined,
                index: 0,
            });
        }
    }

    public render(): JSX.Element {
        return (
            <Draggable
                position={this.props?.config?.timeAnimationSliderPosition ?? this.state.sliderPosition}
                disabled={this.state.dragIsDisabled}
                onMouseDown={(evt) => {
                    evt.stopPropagation();
                }}
                onStart={() => {}}
                onDrag={(_evt, _data) => {
                }}
                onStop={(_evt, data) => {
                    this.setState({
                        sliderPosition: {
                            x: data.x,
                            y: data.y
                        }
                    })
                    if (this.props.config) {
                        this.props.onChangeConfig?.({
                            ...this.props.config,
                            timeAnimationSliderPosition: this.state.sliderPosition,
                        }, true);
                    }
                }}
            >
                <div
                    className="my-row"
                    style={{
                        alignItems: "center",
                        ...this.props.style,
                        position: "absolute",
                        zIndex: 9999,
                        border: "1px solid #e2e2e2",
                        borderRadius: 30,
                        padding: "15px 15px",
                        backgroundColor: "#fff",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginRight: "15px",
                            width: "33px",
                            height: "33px",
                            borderRadius: "50%",
                            padding: "0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                        }}
                        onClick={() => {
                            if (this.stepInterval != null) {
                                this.stop();
                            } else {
                                this.start();
                            }
                        }}
                    >
                        {this.state.started ? <PauseIcon /> : <PlayIcon />}
                    </Button>
                    <Slider
                        className="cancel-drag"
                        style={{
                            flex: 1,
                            ...this.props.sliderStyle,
                        }}
                        trackStyle={{
                            background: "#7e8a99",
                            height: 8,
                        }}
                        handleStyle={{
                            background: "#3b82c9",
                            borderRadius: 10,
                            border: "2px solid #fff",
                            height: 12,
                            width: 17,
                            marginTop: -2,
                        }}
                        railStyle={{
                            background: "#cfd7db",
                            height: 8,
                        }}
                        min={0}
                        max={this.props.values.length - 1}
                        step={1}
                        onChange={(value: number) => {
                            this.stop();
                            this.setState(
                                (state) => ({
                                    previousIndex: state.index,
                                    index: value,
                                }),
                                () => {
                                    this.props.onChange(
                                        this.state.index,
                                        this.state.previousIndex
                                    );
                                }
                            );
                        }}
                        value={this.state.index}
                    />
                    <span className="regular-text" style={{
                        marginLeft: 10,
                        background: "#efefef",
                        padding: "5px 15px",
                        borderRadius: 15,
                    }}>
                        {this.state.index < this.props.values.length
                            ? this.props.formatter(
                                this.props.values[this.state.index]
                            )
                            : ""}
                    </span>
                    <button
                        style={{
                            marginLeft: 10,
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "grab",
                            display: "flex",
                            alignItems: "center",
                        }}
                        onMouseOver={() => {
                            this.setState({
                                dragIsDisabled: false,
                            })
                        }}
                        onMouseLeave={() => {
                            this.setState({
                                dragIsDisabled: true,
                            })
                        }}
                    >
                        <DragIcon />
                    </button>
                </div>
            </Draggable>
        );
    }

    private start(): void {
        if (this.stepInterval == null) {
            if (this.props.withTranstion) this.step();
            this.stepInterval = setInterval(this.step, this.props.sliderIntervalTime);
            this.props.onChange(this.state.index, this.state.previousIndex);
            this.setState({ started: true });
        }
    }

    private stop(): void {
        if (this.stepInterval != null) {
            clearInterval(this.stepInterval);
            this.stepInterval = null;
            this.setState({ started: false });
        }
    }

    private step(): void {
        if (this.state.index >= this.props.values.length - 1) {
            this.stop();
        } else {
            this.setState(
                (state) => ({
                    previousIndex: state.index,
                    index: state.index + 1,
                }),
                () => {
                    this.props.onChange(
                        this.state.index,
                        this.state.previousIndex
                    );
                }
            );
        }
    }
}

export default AnimationSlider;
