import React, { Component } from "react";
import { observer } from "mobx-react";
import Select, { createFilter } from "react-select";

import { GroupExtendedPermissionWithUserInfo } from "common/GroupPermissions";
import UserGroupsStore, { UserGroupOption } from "common/UserGroupsStore";
import {
    customSelectStylesForSharePopupNoBorder,
    leftAlignAndScrollBarCSS,
} from "common/SelectStyles";
import {
    permissionOptions,
    Permission,
    PermissionNames,
    PermissionOptionType,
} from "common/Permissions";
import { UserIcon, GroupIcon } from "common/UserIcon";
import StringUtils from "common/utilities/StringUtils";

const RemovePermission = -1;

interface Props {
    maxHeight?: number;
    permissions: GroupExtendedPermissionWithUserInfo[];
    onPermissionsChange: (value: GroupExtendedPermissionWithUserInfo[]) => void;
    errorMessage?: string;
    containerStyle?: React.CSSProperties;
    doneButton?: string;
    setDoneButton?: (params: any) => any;
    rejectPopup?: any;
}

interface State {
    newGroupFocused: boolean;
    selectedNewGroup: UserGroupOption | null;
    newGroupInput: string;
    selectedPermission: Permission;
    addShare: string;
}

@observer
class PermissionControlView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            newGroupFocused: false,
            selectedNewGroup: null,
            newGroupInput: "",
            selectedPermission: Permission.ReadWrite,
            addShare: "0",
        };
        this.addUser = this.addUser.bind(this);
        this.updateState = this.updateState.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount() {
        UserGroupsStore.init();
    }

    public render(): JSX.Element {
        let allowUserGroups = this.state.newGroupInput.length > 2;
        let options =
            UserGroupsStore.userGroupsOptionsForSharing(allowUserGroups);

        if (this.state.addShare === "0") {
            var iconLabel = (
                <>
                    <img style={{ cursor: "default" }}
                        src="/dist/img/data-exploration/link-icon.png"
                        alt=""
                    />
                </>
            );

            var buttonGrid = <></>;

            var permissionSelect = (
                <>
                    <span className="can_edit"></span>
                </>
            );
        } else {
            var iconLabel = (
                <>
                    <img
                        src="/dist/img/data-exploration/back-arrow.png"
                        alt=""
                        onClick={this.updateState}
                    />
                </>
            );

            var buttonGrid = (
                <div>
                    <button
                        type="button"
                        className="cancelBtn"
                        onClick={this.closePopup}
                    >
                        Cancel
                    </button>

                    <button
                        type="button"
                        className="acceptBtn"
                        style={{
                            padding: "8px 25px",
                        }}
                        onClick={this.addUser}
                    >
                        Send
                    </button>
                </div>
            );

            var permissionSelect = (
                <>
                    <span
                        className="can_edit"
                        style={{
                            fontSize: "14px",
                            color: "#5C5C5C",
                            position: "absolute",
                            right: "0",
                            top: "0",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "10px",
                            width: "91px",
                        }}
                    >
                        <Select
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            placeholder={"Enter permissions"}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            onFocus={() => {
                                this.setState({ newGroupFocused: true });
                            }}
                            onBlur={() => {
                                this.setState({ newGroupFocused: false });
                            }}
                            styles={{
                                ...customSelectStylesForSharePopupNoBorder,
                                container: (base) => ({
                                    ...base,
                                    width: "140px",
                                    overflowX: "hidden"
                                }),
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 100000000,
                                }),
                            }}
                            options={permissionOptions}
                            value={{
                                value: this.state.selectedPermission,
                                label: PermissionNames[
                                    this.state.selectedPermission
                                ],
                            }}
                            onChange={(newValue) => {
                                let permissionType = (
                                    newValue as PermissionOptionType
                                ).value as Permission;
                                this.setState({
                                    selectedPermission: permissionType,
                                });
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                        />
                    </span>
                </>
            );
        }

        return (
            <div
                className="search_row"
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                onMouseDown={(evt: any) => {
                    evt.stopPropagation();
                }}
            >
                <div className="custom_column">
                    <div
                        className="popup_sub_hdr"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <span
                            style={{ marginRight: "15px", cursor: "pointer" }}
                        >
                            {" "}
                            {iconLabel}{" "}
                        </span>
                        <p style={{ textAlign: "left", fontWeight: 300 }}>
                            Share with People and Group{" "}
                        </p>
                    </div>

                    <div className="add_search">
                        <span className="fields">
                            <Select
                                menuPortalTarget={document.body}
                                placeholder={
                                    "Type user name, email, or group ..."
                                }
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                onFocus={() => {
                                    this.setState({ newGroupFocused: true });
                                }}
                                onInputChange={(input, { action }) => {
                                    if (action === "input-change") {
                                        this.setState({
                                            newGroupInput: input,
                                            addShare: "1",
                                        });
                                        this.props.setDoneButton?.("1");
                                    }
                                    if (!input == null) {
                                        this.setState({
                                            addShare: "0",
                                        });
                                        this.props.setDoneButton?.("0");
                                    }
                                }}
                                onBlur={() => {
                                    this.setState({ newGroupFocused: false });
                                }}
                                styles={{
                                    ...customSelectStylesForSharePopupNoBorder,
                                    ...leftAlignAndScrollBarCSS,
                                    container: (base) => ({
                                        ...base,
                                        backgroundColor: "#EAEAEA",
                                        width: "100%",
                                        height: "40px",
                                        border: "navajowhite",
                                        marginTop: "15px",
                                        padding: "0 0 0 0",
                                        justifyContent: "left",
                                        fontSize: "14px",
                                        lineHeight: "30px",
                                        marginBottom: "20px",
                                        borderRadius: "6px",

                                    }),
                                    dropdownIndicator: (_base) => ({
                                        display: "none",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                    control: (base) => ({
                                        ...base,
                                        borderRadius: 6,
                                        "&:active": {
                                            boxShadow: '0 0 0 1px #2684ff',
                                        }
                                    }),
                                    input: (base => ({
                                        ...base,
                                        paddingLeft: 5,
                                        overflowX: "hidden",
                                        width: "450px"
                                    }))
                                }}
                                options={options.filter(
                                    (option) =>
                                        this.props.permissions.find(
                                            (selectedOption) =>
                                                selectedOption.group_id ===
                                                option.id
                                        ) == null
                                )}
                                value={this.state.selectedNewGroup}
                                onChange={(newValue) => {
                                    this.setState({
                                        selectedNewGroup:
                                            newValue as UserGroupOption,
                                    }, this.addUser);
                                    // this.props.setDoneButton?.('1')
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                            />
                            {permissionSelect}
                        </span>
                    </div>

                    {this.state.addShare === "0" && (
                        <div
                            className="shared_with"
                            style={{
                                marginTop: "13px",
                                justifyContent: "space-between",
                            }}
                        >
                            {this.props.permissions.length > 0 && (
                                <p
                                    style={{
                                        textAlign: "left",
                                        fontWeight: 300,
                                    }}
                                >
                                    Shared With
                                </p>
                            )}
                            <div className="shared_list scrollBar" style={{
                                overflow: "auto",
                                height: "auto",
                                maxHeight: "157px",
                            }}>
                                {this.props.permissions.map(
                                    (permission, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="item"
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    alignItems: "center",
                                                    borderBottom:
                                                        "1px solid #D9D9D9",
                                                    paddingBlock: "9px",
                                                }}
                                            >
                                                {!permission.personal && (
                                                    <>
                                                        <GroupIcon
                                                            fontSize={25}
                                                            groupName={
                                                                permission.group_name
                                                            }
                                                        />
                                                        <span
                                                            style={{
                                                                padding:
                                                                    "5px 0px",
                                                                color: "#5C5C5C",
                                                                fontSize:
                                                                    "14px",
                                                            }}
                                                            className="unselectable"
                                                        >
                                                            {
                                                                permission.group_name
                                                            }
                                                        </span>
                                                    </>
                                                )}
                                                {permission.personal && (
                                                    <>
                                                        <UserIcon
                                                            width={35}
                                                            height={35}
                                                            fontSize={15}
                                                            user={
                                                                permission.user_info
                                                            }
                                                        />

                                                        <span
                                                            style={{
                                                                padding:
                                                                    "5px 0px",
                                                                color: "#5C5C5C",
                                                                fontSize:
                                                                    "14px",
                                                                marginLeft:
                                                                    "10px",
                                                            }}
                                                            className="unselectable"
                                                        >
                                                            {permission.user_info
                                                                ? permission.user_info.first_name
                                                                    .concat(
                                                                        " "
                                                                    )
                                                                    .concat(
                                                                        permission
                                                                            .user_info
                                                                            .last_name
                                                                    )
                                                                : ""}
                                                        </span>
                                                    </>
                                                )}

                                                <Select
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    menuShouldBlockScroll={true}
                                                    placeholder={
                                                        "Enter permissions"
                                                    }
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    styles={{
                                                        ...customSelectStylesForSharePopupNoBorder,
                                                        container: (base) => ({
                                                            ...base,
                                                            marginLeft: "auto",
                                                            width: "110px",
                                                            float: "right",
                                                        }),
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 100000000,
                                                        }),
                                                    }}
                                                    options={permissionOptions
                                                        .map((option) => ({
                                                            ...option,
                                                            label: StringUtils.capitalize(
                                                                option.label.toLowerCase()
                                                            ),
                                                        }))
                                                        .concat({
                                                            label: "Remove",
                                                            value: RemovePermission,
                                                        })}
                                                    value={{
                                                        value: permission.permission_type,
                                                        label:
                                                            permission.permission_type ===
                                                                RemovePermission
                                                                ? "Remove"
                                                                : StringUtils.capitalize(
                                                                    PermissionNames[
                                                                        permission.permission_type as Permission
                                                                    ].toLowerCase()
                                                                ),
                                                    }}
                                                    onChange={(newValue) => {
                                                        let permissionType = (
                                                            newValue as PermissionOptionType
                                                        ).value as Permission;
                                                        let permissions = [
                                                            ...this.props
                                                                .permissions,
                                                        ];
                                                        permissions[index] = {
                                                            ...permissions[
                                                            index
                                                            ],
                                                            permission_type:
                                                                permissionType,
                                                        };
                                                        this.props.onPermissionsChange(
                                                            permissions
                                                        );
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                    })}
                                                />
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        className="button"
                        style={{
                            textAlign: "right",
                        }}
                    >
                        {buttonGrid}
                    </div>
                </div>
            </div>
        );
    }

    private addUser(): void {
        if (this.state.selectedNewGroup != null) {
            let personalInfo = UserGroupsStore.userPersonalGroupsState.find(
                (group) => group.group_id === this.state.selectedNewGroup!.id
            );
            let newPermission: GroupExtendedPermissionWithUserInfo;
            if (personalInfo == null) {
                newPermission = {
                    group_id: this.state.selectedNewGroup.id,
                    group_name: this.state.selectedNewGroup.label,
                    permission_type: this.state.selectedPermission,
                    personal: false,
                };
            } else {
                newPermission = {
                    group_id: this.state.selectedNewGroup.id,
                    group_name: this.state.selectedNewGroup.label,
                    permission_type: this.state.selectedPermission,
                    personal: true,
                    user_info: {
                        id: personalInfo.id,
                        user_name: personalInfo.user_name,
                        first_name: personalInfo.first_name,
                        last_name: personalInfo.last_name,
                        icon_url: personalInfo.icon_url,
                        is_admin: personalInfo.is_admin,
                    },
                };
            }
            this.setState({
                selectedNewGroup: null,
                selectedPermission: Permission.ReadWrite,
                addShare: "0",
            });
            this.props.onPermissionsChange([
                ...this.props.permissions,
                newPermission,
            ]);
            this.props.setDoneButton?.("0");
        }
    }

    private updateState(): void {
        this.setState({
            addShare: "0",
        });
        this.props.setDoneButton?.("0");
    }
    private closePopup(): void {
        this.setState({
            addShare: "0",
        });
        this.props.setDoneButton?.("0");
        this.props.rejectPopup();
    }
}

export default PermissionControlView;