import "common/styles/App.css";

import React, { Component } from "react";
import ScrollableRow from "common/insights_components/ScrollableRow";
import StoriesGridCard from "common/insights_components/StoriesGridCard";
import { Permission } from "common/Permissions";

class StoriesContent extends Component {
	constructor(props) {
		super(props);
		this.buildStoriesGrid = this.buildStoriesGrid.bind(this);
	}

	componentDidMount() {}

	componentWillUnmount() {}

	buildStoriesGrid() {
		if (typeof this.props.stories === "undefined") return null;
		let canWrite = this.props.permission > Permission.ReadOnly;

		let grid = this.props.stories.map((item, index) => {
			return (
				<div
					style={{ marginLeft: index === 0 ? "0px" : "20px" }}
					key={item.id}
				>
					<StoriesGridCard
						acceptDrag={"story"}
						acceptDrop={"story"}
						key={item.id}
						story={item}
						onCrossClicked={(item) => {
							this.props.onStoryDelete(item, this.props.tabId);
						}}
						onCardsSwapped={(item, other) => {
							this.props.onStoriesSwap(item, other);
						}}
						onCardClicked={() => {
							this.props.onStoryOpen(item);
						}}
						onShareClicked={(item) => {
							this.props.onStoryShare(item);
						}}
						canEdit={canWrite}
					/>
				</div>
			);
		});
		return grid;
	}

	render() {
		return (
			<ScrollableRow>
				<>{this.buildStoriesGrid()}</>
			</ScrollableRow>
		);
	}
}

export default StoriesContent;
