import React, { useState } from "react";
import { observer } from "mobx-react";
import CurrentModulesStore from "common/CurrentModulesStore";
import headerStyles from "common/HeaderBarRevamped/HeaderBar.module.css";
import AutosizeInput from "react-input-autosize";
import moduleTitleStyles from "./ModuleTitle.module.css";
import { editModuleApi } from "common/ModulesApi";
import Cookies from "universal-cookie";

const cookies = new Cookies();

interface Props {
    moduleId: number;
}

const ModuleTitle = observer((props: Props) => {
    let currentModule = CurrentModulesStore.getModule(props.moduleId);
    let isOwner = currentModule?.user_data?.is_owner ?? false;
    let title = CurrentModulesStore.getModule(props.moduleId)?.title;
    let [editedTitle, setEditedTitle] = useState(title);
    let [inputRef, setInputRef] = React.useState<HTMLInputElement | null>();
    return isOwner ? (
        <AutosizeInput
            inputRef={setInputRef}
            placeholder={"Module name"}
            inputClassName={moduleTitleStyles.moduleInput}
            onChange={(evt) => {
                setEditedTitle(evt.target.value);
            }}
            value={editedTitle}
            onClick={() => {
                if (title === "New Preso") {
                    inputRef?.setSelectionRange(0, title.length);
                }
            }}
            onBlur={() => {
                editModuleApi(
                    props.moduleId,
                    editedTitle,
                    undefined,
                    cookies.get("instrumentation_session_id")
                )
                    .then((_id) => {
                        CurrentModulesStore.updateModule(props.moduleId, {
                            title: editedTitle,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }}
            onKeyDown={(evt) => {
                evt.stopPropagation();
                if (evt.key === "Enter" && !evt.shiftKey) {
                    evt.preventDefault();
                    inputRef?.blur();
                }
            }}
        />
    ) : (
        <h1
            className={headerStyles.canvasTitle}
            style={{ minWidth: "100px", minHeight: "15px", cursor: "text" }}
        >
            {title}
        </h1>
    );
});

export default ModuleTitle;
