import React from "react";
import { Button } from "react-bootstrap";
import { mainStyle } from "common/MainStyle";
import StringUtils from "common/utilities/StringUtils";
import Switch from "react-switch";
import ColorPicker from "common/ColorPicker";

interface Line {
	color: string;
	value: number | string;
	horizontal: boolean;
}

interface Props {
	onClearLines: () => void;
	onNewLine: (line: Line) => void;
}

export default function LinesSelector(props: Props) {
	let [line, setLine] = React.useState<Line>({
		color: "#000000",
		value: 0,
		horizontal: true,
	});
	return (
		<div
			className="flex-simple-column"
			style={{ padding: "5px" }}
			onMouseDown={(evt) => {
				evt.stopPropagation();
			}}
		>
			<div className="my-row" style={{ alignItems: "center" }}>
				<span
					style={{
						fontFamily: "Arial",
						fontSize: "10px",
						color: mainStyle.getPropertyValue(
							"--exploration-primary-text-color"
						),
					}}
				>
					{"Add line: "}
				</span>
				<ColorPicker
					enableAlpha
					value={line.color}
					onChange={(newValue) => {
						setLine({ ...line, color: newValue });
					}}
					style={{
						marginLeft: "6px",
					}}
				/>
			</div>
			<input
				onKeyDown={(evt) => {
					evt.stopPropagation();
				}}
				style={{
					marginTop: "10px",
					width: "100px",
				}}
				className="like-select"
				onChange={(evt) => {
					let value = evt.target.value;

					setLine({ ...line, value: value });
				}}
				value={line.value}
			/>
			<div
				className="my-row"
				style={{
					marginTop: "10px",
					alignItems: "center",
					width: "100px",
				}}
			>
				<span
					style={{
						fontFamily: "Arial",
						fontSize: "10px",
						color: mainStyle.getPropertyValue(
							"--exploration-primary-text-color"
						),
					}}
				>
					{"H"}
				</span>
				<div style={{ marginLeft: 6 }}>
					<Switch
						onChange={() => {
							setLine({ ...line, horizontal: !line.horizontal });
						}}
						checked={!line.horizontal}
						width={26}
						height={13}
						offColor="#20293C"
						onColor="#20293C"
						checkedIcon={false}
						uncheckedIcon={false}
						offHandleColor="#1F8EFA"
						onHandleColor="#1F8EFA"
					/>
				</div>
				<span
					style={{
						marginLeft: "6px",
						fontFamily: "Arial",
						fontSize: "10px",
						color: mainStyle.getPropertyValue(
							"--exploration-primary-text-color"
						),
					}}
				>
					{"V"}
				</span>
			</div>
			<Button
				type="button"
				style={{
					marginTop: "10px",
					width: "100px",
				}}
				className="btn btn-xs btn-primary my-primary"
				onClick={() => {
					let newLine = { ...line };
					if (StringUtils.isNumber(newLine.value)) {
						newLine.value = Number(newLine.value);
					}
					props.onNewLine(line);
				}}
			>
				{"\uFF0B" /* plus */}
			</Button>
			<Button
				type="button"
				style={{
					marginTop: "10px",
					width: "100px",
				}}
				className="btn btn-xs btn-primary my-primary"
				onClick={props.onClearLines}
			>
				{"CLEAR ALL"}
			</Button>
		</div>
	);
}