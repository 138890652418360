import React from "react";
import Finding, {
    isBarChart,
    isScatterPlot,
    isBubble,
    isDensity2d,
    isCorrelogram,
    isHeatmap,
    isHistogram,
    isDensityPlot,
    isBoxPlot,
    isViolinPlot,
    isRidgeline,
    isPieChart,
    isTreemap,
    isDendrogram,
    isWordCloud,
    isFunnelPlot,
    isLinePlot,
    isLeadersLaggers,
    isGanttChart,
    isLeversBenchmarking,
    isKanbanBoard,
    isLeverOutcomeChart,
    isNetwork,
    isHoldOutPredictionChart,
    isMap,
    isNetworkOverMap,
    MapFinding,
    NetworkFinding,
    isRadar,
    isAiCopilot,
} from "common/Finding";
import styles from "./DataSection.module.css";
import { getCanvasesApi } from "common/CanvasUserApi";
import BarChartSideBySideSection from "./BarChartSection/SideBySideSection";
import BarChartSection from "./BarChartSection";
import ScatterPlotSection from "./ScatterPlotSection";
import CorrelogramSection from "./CorrelogramSection";
import BubbleSection from "./BubbleSection";
import HeatmapSection from "./HeatmapSection";
import HistogramSection from "./HistogramSection";
import DensityPlotSection from "./DensityPlotSection";
import BoxViolinRidgelineSection from "./BoxViolinRidgelineSection";
import AggregateSection from "./AggregateSection";
import WordCloudSection from "./WordCloudSection";
import CommonSideBySideSection, {
    SideBySideSectionProps,
} from "./SideBySideSection";
import LinePlotSideBySideSection from "./LinePlotSection/SideBySideSection";
import LinePlotSection from "./LinePlotSection";
import LeadersLaggersSection from "./LeadersLaggersSection";
import LeversBenchmarkingSection from "./LeversBenchmarkingSection";
import { Accordion, Button } from "react-bootstrap";
import cx from "classnames";
import Select, { createFilter } from "react-select";
import { ReactComponent as UnlinkIcon } from "icons/canvas/exploration/unlink.svg";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import mapStyles from "../DataSection/MapSection/MapSection.module.css";
import commonStyles from "../ChartsRibbon.module.css";
import GanttChartSection from "./GanttChartSection";
import KanbanBoardSection from "./KanbanBoardSection";
import LeverOutcomeSection from "./LeverOutcomeSection";
import NetworkSection from "./NetworkSection";
import HoldOutSection from "./HoldOutSection";
import MapSection from "./MapSection";
import NetworkOverMapSection from "./NetworkOverMapSection";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import Tables, { TableOption } from "common/Tables";
import { observer } from "mobx-react";
import {
    Condition,
    ConditionsSelector,
    NodeLinkOption,
} from "common/Conditions";
import { Canvas, defaultErrorColors, getTargetValue, isSlider } from "common/Canvas";
import SharedBoxesStore from "modules/canvas_page/SharedBoxesStore";
import Variables, { VariableOption } from "common/Variables";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import { DynamicOptionType } from "common/DynamicOptions";
import MagicWand from "./MagicWand";
import { ReactComponent as EyeOpenIcon } from "../../../../../../icons/eye-line.svg";
import { ReactComponent as EyeCloseIcon } from "../../../../../../icons/eye-close-line.svg";
import AdditionalMap from "./MapSection/AdditionalMap/AdditionalMap";
import ErrorColorsChanger from "modules/canvas_page/canvas_elements/NewDashboards/ChartsRibbon/DataSection/LeadersLaggersSection/ErrorColorsChanger";
import { SearchComponentValue } from "common/SearchComponent";
import axios from "common/ServerConnection";
import { colorList } from "common/graphics/LineColors";
import LinkOptionsPopup from "modules/canvas_page/link_components/LinkOptionsPopup";

interface DataSectionProps {
    dashboardId: string;
    updatingDashboard: boolean;
    canvasTreeStore: CanvasTreeStore;
    finding?: Finding | null;
    columnDragActive: boolean;
    connectDataset: boolean;
    currentModuleId?: number;
    onChange: (finding: Finding, updateData?: boolean) => void;
    onClearEditing: () => void;
    onConnectDataset: (connectDataset: boolean) => void;
    onChangeConditions: (conditions: Condition[]) => void;
    onChangeSelectedTable: (selectedTable: TableOption) => void;
    onChangeDataScope: (
        dataScope: DataScopeOption,
        currentFinding: Finding
    ) => void;
    onToogleTablePreview?: (value: boolean) => void;
    toogleTablePreview?: boolean;
}

enum DataMenuOptions {
    None = 0,
    SideBySide = 1,
    AdditionalMap = 2,
    ArrowIndicator = 3,
}

function getSideBySideSection(
    finding: Finding
): React.ComponentType<SideBySideSectionProps> {
    if (isBarChart(finding)) {
        return BarChartSideBySideSection;
    } else if (
        isPieChart(finding) ||
        isTreemap(finding) ||
        isDendrogram(finding) ||
        isRadar(finding) ||
        isLinePlot(finding)
    ) {
        return LinePlotSideBySideSection;
    } else {
        return CommonSideBySideSection;
    }
}

function loadLogos(
    data: {
        searchIndex: number;
        dataScopeId: number;
        currentModuleId?: number;
    },
    callback: (logos: string[]) => void
): void {
    const { searchIndex, dataScopeId, currentModuleId } = data;
    axios
        .post<{
            success: boolean;
            error_msg: string;
            items?: SearchComponentValue[];
        }>("/api/e/autocomplete", {
            variable_index: searchIndex,
            starts_with: "",
            data_table_idx: dataScopeId,
            conditions: null,
            module_id: currentModuleId,
        })
        .then((response: any) => {
            if (response.data.success && response.data.items != null) {
                const uniqueLogos = [
                    ...new Set(
                        response.data.items
                            .filter((item: string) => item)
                            .flatMap((item: string) =>
                                item.replaceAll(/\s/g, "").split(",")
                            )
                    ),
                ] as string[];

                callback(uniqueLogos);
            } else {
                console.log(response.data.error_msg);
            }
        })
        .catch((error: any) => {
            console.log(error);
        });
}

function AdditionalMapSections(props: DataSectionProps): JSX.Element | null {
    if (
        props.finding == null ||
        (!isMap(props.finding) && !isNetworkOverMap(props.finding))
    )
        return null;
    let parentFinding = props.finding;

    return (
        <>
            {parentFinding.additionalMapsFindings?.map(
                (finding: MapFinding | NetworkFinding, index: number) => {
                    let onChange = (
                        newAdditionalFinding: Finding,
                        updateData?: boolean
                    ) => {
                        const newFinding = {
                            ...parentFinding,
                            additionalMapsFindings: [
                                ...(parentFinding.additionalMapsFindings ?? []),
                            ],
                        };
                        newFinding.additionalMapsFindings[
                            index
                        ] = newAdditionalFinding;
                        props.onChange(newFinding, updateData);
                    };

                    let parentMapVariableOptions: VariableOption[] = [];
                    let additionalMapVariableOptions: VariableOption[] = [];

                    if (parentFinding.config.dataScope != null) {
                        parentMapVariableOptions = Variables(
                            parentFinding?.config.dataScope.value,
                            props.currentModuleId
                        ).variableOptions;
                    }

                    if (finding.config.dataScope != null) {
                        additionalMapVariableOptions = Variables(
                            finding?.config.dataScope.value,
                            props.currentModuleId
                        ).variableOptions;
                    }

                    const excludedFields = new Set([
                        parentMapVariableOptions,
                        ...additionalMapVariableOptions.map(
                            ({ label }) => label
                        ),
                    ]);

                    if (isMap(finding)) {
                        return (
                            <MapSection
                                columnDragActive={props.columnDragActive}
                                finding={finding}
                                innerJoinVariableOptions={parentMapVariableOptions.filter(
                                    ({ label }) => excludedFields.has(label)
                                )}
                                onChange={onChange}
                                currentModuleId={props.currentModuleId}
                                canvasTreeStore={props.canvasTreeStore}
                                additionalMapFindingIdx={index}
                            />
                        );
                    } else {
                        return (
                            <NetworkOverMapSection
                                columnDragActive={props.columnDragActive}
                                finding={finding}
                                onChange={onChange}
                                currentModuleId={props.currentModuleId}
                                canvasTreeStore={props.canvasTreeStore}
                            />
                        );
                    }
                }
            )}
        </>
    );
}

export default observer(function DataSection(props: DataSectionProps) {
    let numericalVariableOptions: VariableOption[] = [];
    let activeVariableOptions: VariableOption[] = [];
    let variableOptions: VariableOption[] = [];
    let ascDescOptions: {label: string, value: string}[] = [
        {
            label: 'Ascending order',
            value: 'asc'
        },
        {
            label: 'Descending order',
            value: 'desc'
        }
    ]
    let pages: {
        label: string;
        value: number;
    }[] = [];
    
    let [dataMenuOptionSelected, setDataMenuOptionSelected] = React.useState(
        DataMenuOptions.None
    );
    let [canvases, setCanvases] = React.useState<Canvas[]>([]);


    React.useEffect(() => {
        getCanvasesApi(props.currentModuleId)
            .then((canvases) => {
                setCanvases(canvases)
            })
            .catch((_error) => {});
    }, []);

    pages = canvases.map((canvas) => {
                return {
                    value: canvas.id,
                    label: canvas.page_title
                        .concat(" >> ")
                        .concat(canvas.title),
                };
            });

    variableOptions = Variables(
        props.finding?.config.dataScope?.value,
        props.currentModuleId
    ).variableOptions;

    if (props?.finding && isLeadersLaggers(props.finding)) {
        numericalVariableOptions = Variables(
            props.finding?.config.dataScope?.value,
            props.currentModuleId
        ).variableOptions.filter((item) => {
            if (
                item.type !== "str" &&
                (props.finding?.config.shownVariableIndices.includes(
                    item.value
                ) ||
                    props.finding?.config.targetVariableIndex === item.value)
            ) {
                return true;
            }
            return false;
        });

        activeVariableOptions = Variables(
            props.finding?.config.dataScope?.value,
            props.currentModuleId
        ).variableOptions.filter((item) => {
            if (
                (props.finding?.config.shownVariableIndices.includes(
                    item.value
                ) ||
                    props.finding?.config.targetVariableIndex === item.value)
            ) {
                return true;
            }
            return false;
        });
    }
    const stringVariableOptions = Variables(
        props.finding?.config.dataScope?.value,
        props.currentModuleId
    ).variableOptions.filter((item) => item.type === "str");

    if (props.finding == null) return null;
    const dataScopes =
        props.currentModuleId == null
            ? DataScopes
            : DataScopesForModules(props.currentModuleId);
    const tableOptions =
        props.finding?.config.dataScope != null
            ? Tables(
                  props.finding.config.dataScope.value,
                  props.currentModuleId
              ).rawAndAggregateTableOptions
            : [];
    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    const SideBySideSection = getSideBySideSection(props.finding);

    let finding = props.finding;

    const elements = Array.from(props.canvasTreeStore.canvasTreeState.values());
    const elementsWithoutSliders = elements
        .filter((element) => !isSlider(element))
        .map(
            (item) =>
                ({
                    label: item.outerId,
                    value: item.id,
                    isCloneInput: false,
                    target: getTargetValue(item),
                } as NodeLinkOption)
        )
        .concat(
            SharedBoxesStore.sharedBoxes(props.canvasTreeStore.canvasId!).map(
                (item) => ({
                    label: item.label,
                    value: item.value,
                    isCloneInput: true,
                    target: getTargetValue(item.item.box),
                })
            )
        );
    const sliderElements = elements
        .filter((element) => isSlider(element))
        .flatMap((element: any) => {
            const mainResult = {
                label: element.outerId,
                value: element.id,
                isCloneInput: false,
                target: getTargetValue(element),
            };
            if (element.range && element?.additionalOutputs) {
                const additionalOutput = element.additionalOutputs[0];
                return [
                    mainResult,
                    {
                        label: `${element.outerId}_2`,
                        value: element.id,
                        isCloneInput: false,
                        target: getTargetValue(additionalOutput),
                    },
                ];
            }

            return [mainResult];
        });

    const nodeLinkOptions = [...elementsWithoutSliders, ...sliderElements];

    return (
        <div className={styles.root}>
            {(isPieChart(finding) ||
                isTreemap(finding) ||
                isRadar(finding) ||
                isDendrogram(finding) ||
                isBarChart(finding)) && (
                <BarChartSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {(isScatterPlot(finding) ||
                isFunnelPlot(finding) ||
                isDensity2d(finding)) && (
                <ScatterPlotSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {isCorrelogram(props.finding) && (
                <CorrelogramSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {isBubble(finding) && (
                <BubbleSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {isHeatmap(finding) && (
                <HeatmapSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {isHistogram(finding) && (
                <HistogramSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {isDensityPlot(finding) && (
                <DensityPlotSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {(isBoxPlot(finding) ||
                isViolinPlot(finding) ||
                isRidgeline(finding)) && (
                <BoxViolinRidgelineSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {isLinePlot(finding) && (
                <LinePlotSection
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    currentModuleId={props.currentModuleId}
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                />
            )}
            {isGanttChart(finding) && (
                <GanttChartSection
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                />
            )}
            {isWordCloud(finding) && (
                <WordCloudSection finding={finding} onChange={props.onChange} />
            )}
            {isLeadersLaggers(finding) && (
                <LeadersLaggersSection
                    columnDragActive={props.columnDragActive}
                    onClearEditing={props.onClearEditing}
                    finding={finding}
                    canvasTreeStore={props.canvasTreeStore}
                    currentModuleId={props.currentModuleId}
                    dashboardId={props.dashboardId}
                    onChange={props.onChange}
                />
            )}
            {isLeversBenchmarking(finding) && (
                <LeversBenchmarkingSection
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {isKanbanBoard(finding) && (
                <KanbanBoardSection
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {isLeverOutcomeChart(finding) && (
                <LeverOutcomeSection
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {isNetwork(finding) && (
                <NetworkSection
                    columnDragActive={props.columnDragActive}
                    finding={finding}
                    onChange={props.onChange}
                    currentModuleId={props.currentModuleId}
                />
            )}
            {isHoldOutPredictionChart(finding) && (
                <HoldOutSection
                    updatingDashboard={props.updatingDashboard}
                    finding={finding}
                    onChange={props.onChange}
                    canvasTreeStore={props.canvasTreeStore}
                    currentModuleId={props.currentModuleId}
                    dashboardId={props.dashboardId}
                />
            )}

            {(isMap(finding) || isNetworkOverMap(finding)) && (
                <>
                    {isMap(finding) && (
                        <MapSection
                            columnDragActive={props.columnDragActive}
                            finding={finding}
                            onChange={props.onChange}
                            currentModuleId={props.currentModuleId}
                            canvasTreeStore={props.canvasTreeStore}
                        />
                    )}
                    {isNetworkOverMap(finding) && (
                        <NetworkOverMapSection
                            columnDragActive={props.columnDragActive}
                            finding={finding}
                            onChange={props.onChange}
                            currentModuleId={props.currentModuleId}
                            canvasTreeStore={props.canvasTreeStore}
                        />
                    )}
                    <Accordion
                        activeKey={String(dataMenuOptionSelected)}
                        onSelect={(event: any) => {
                            setDataMenuOptionSelected(Number(event));
                        }}
                    >
                        <Accordion.Toggle
                            eventKey={String(DataMenuOptions.AdditionalMap)}
                            className={mapStyles.editMenuAdvancedOption}
                        >
                            Add additional map type
                            <ChevronIcon
                                className={cx(
                                    mapStyles.editMenuAdvancedOptionIcon,
                                    {
                                        [mapStyles.chevronOpen]:
                                            dataMenuOptionSelected ===
                                            DataMenuOptions.AdditionalMap,
                                    }
                                )}
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse
                            eventKey={String(DataMenuOptions.AdditionalMap)}
                        >
                            <AdditionalMap
                                mapFinding={finding}
                                onChange={props.onChange}
                                currentModuleId={props.currentModuleId}
                            />
                        </Accordion.Collapse>
                    </Accordion>
                    <AdditionalMapSections {...props} />
                </>
            )}

            {isPieChart(finding) && (
                <AggregateSection
                    currentModuleId={props.currentModuleId}
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    finding={finding}
                    onChange={props.onChange}
                />
            )}

            {!(finding?.config.dataScope != null) &&
                !isMap(finding) &&
                !isNetworkOverMap(finding) && (
                    <Button
                        style={{ marginTop: 30 }}
                        className={cx(
                            "btn btn-sm my-outline btn-primary",
                            styles.connectButton
                        )}
                        onClick={() => {
                            props.onConnectDataset(true);
                        }}
                    >
                        {"Connect data set"}
                    </Button>
                )}
            {(isBarChart(finding) ||
                isTreemap(finding) ||
                isRadar(finding) ||
                isDendrogram(finding)) && (
                <AggregateSection
                    currentModuleId={props.currentModuleId}
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    canvasTreeStore={props.canvasTreeStore}
                    finding={finding}
                    onChange={props.onChange}
                />
            )}
            {finding.config.dataScope &&
                (isLeverOutcomeChart(finding) ||
                    isHoldOutPredictionChart(finding) ||
                    isLinePlot(finding) ||
                    isBarChart(finding) ||
                    isPieChart(finding) ||
                    isTreemap(finding) ||
                    isDendrogram(finding) ||
                    isRadar(finding) ||
                    isLeversBenchmarking(finding)) && (
                    <Accordion
                        activeKey={String(dataMenuOptionSelected)}
                        onSelect={(event: any) => {
                            setDataMenuOptionSelected(Number(event));
                        }}
                    >
                        <Accordion.Toggle
                            eventKey={String(DataMenuOptions.SideBySide)}
                            className={commonStyles.editMenuAdvancedOption}
                        >
                            Variations
                            <ChevronIcon
                                className={cx(
                                    commonStyles.editMenuAdvancedOptionIcon,
                                    {
                                        [commonStyles.chevronOpen]:
                                            dataMenuOptionSelected ===
                                            DataMenuOptions.SideBySide,
                                    }
                                )}
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse
                            eventKey={String(DataMenuOptions.SideBySide)}
                        >
                            <SideBySideSection
                                maxValuesCount={
                                    isLeversBenchmarking(finding) ? 1 : 2
                                }
                                finding={finding}
                                onChange={props.onChange}
                                currentModuleId={props.currentModuleId}
                            />
                        </Accordion.Collapse>
                    </Accordion>
                )}
            {(props.connectDataset || finding.config.dataScope) &&
                !isMap(finding) && (
                    <React.Fragment>
                        <span
                            className={styles.thinOptionName}
                            style={{ marginTop: 30 }}
                        >
                            Dataset
                        </span>
                        <div
                            className="my-row"
                            style={{
                                alignItems: "center",
                                marginTop: "5px",
                            }}
                        >
                            <Select
                                menuPlacement="auto"
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"Select dataset"}
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "100%",
                                        height: "38px",
                                    }),
                                }}
                                options={dataScopes.dataScopesOptions}
                                onChange={(dataScope) =>
                                    props.onChangeDataScope(dataScope, finding)
                                }
                                value={finding.config.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <div
                                style={{ marginLeft: 4 }}
                                onClick={() => {
                                    props.onConnectDataset(false);
                                }}
                            >
                                <UnlinkIcon />
                            </div>
                        </div>
                        {finding?.config.dataScope != null && (
                            <>
                                {!isAiCopilot(finding) && (
                                    <>
                                        <span
                                            className={styles.thinOptionName}
                                            style={{ marginTop: 10 }}
                                        >
                                            Table
                                        </span>
                                        <div
                                            className="my-row"
                                            style={{
                                                alignItems: "center",
                                                marginTop: "5px",
                                            }}
                                        >
                                            <Select
                                                menuPlacement="auto"
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={"Select table"}
                                                styles={{
                                                    ...selectStyles,
                                                    container: (base) => ({
                                                        ...base,
                                                        width: "100%",
                                                        height: "38px",
                                                    }),
                                                }}
                                                options={tableOptions}
                                                onChange={
                                                    props.onChangeSelectedTable
                                                }
                                                value={
                                                    finding.config.selectedTable
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                            {props.toogleTablePreview ? (
                                                <EyeOpenIcon
                                                    style={{
                                                        width: 43,
                                                        fill: "#333333",
                                                        fillOpacity: 0.8,
                                                    }}
                                                    onClick={() =>
                                                        props.onToogleTablePreview?.(
                                                            false
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <EyeCloseIcon
                                                    style={{
                                                        width: 43,
                                                        fill: "#333333",
                                                        fillOpacity: 0.8,
                                                    }}
                                                    onClick={() =>
                                                        props.onToogleTablePreview?.(
                                                            true
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                )}

                                {isLeadersLaggers(finding) && (
                                    <>
                                        <Accordion
                                            activeKey={String(
                                                dataMenuOptionSelected
                                            )}
                                            onSelect={(event: any) => {
                                                setDataMenuOptionSelected(
                                                    Number(event)
                                                );
                                            }}
                                        >
                                            <Accordion.Toggle
                                                eventKey={String(
                                                    DataMenuOptions.ArrowIndicator
                                                )}
                                                className={
                                                    commonStyles.editMenuAdvancedOption
                                                }
                                            >
                                                Change Indicator
                                                <ChevronIcon
                                                    className={cx(
                                                        commonStyles.editMenuAdvancedOptionIcon,
                                                        {
                                                            [commonStyles.chevronOpen]:
                                                                dataMenuOptionSelected ===
                                                                DataMenuOptions.ArrowIndicator,
                                                        }
                                                    )}
                                                />
                                            </Accordion.Toggle>
                                            <Accordion.Collapse
                                                eventKey={String(
                                                    DataMenuOptions.ArrowIndicator
                                                )}
                                            >
                                                <>
                                                    <div
                                                        className="my-row"
                                                        style={{
                                                            alignItems:
                                                                "center",
                                                            marginTop: "10px",
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        <Select
                                                            filterOption={createFilter(
                                                                {
                                                                    ignoreAccents: false,
                                                                }
                                                            )}
                                                            placeholder="Select indicator"
                                                            styles={{
                                                                control: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    borderRadius: 4,
                                                                }),
                                                                container: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    flexGrow: 1,
                                                                }),
                                                                indicatorSeparator: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    display:
                                                                        "none",
                                                                }),
                                                            }}
                                                            options={
                                                                numericalVariableOptions
                                                            }
                                                            onChange={(
                                                                newValue
                                                            ) => {
                                                                if (
                                                                    !props.finding
                                                                )
                                                                    return;

                                                                const newFinding: Finding = {
                                                                    ...props.finding,
                                                                    config: {
                                                                        ...props
                                                                            .finding
                                                                            ?.config,
                                                                        arrowIndicatorColumn: {
                                                                            value:
                                                                                newValue?.value,
                                                                            sign:
                                                                                "",
                                                                            isAllowed: true,
                                                                            errorColors: defaultErrorColors,
                                                                        },
                                                                    },
                                                                };
                                                                props.onChange(
                                                                    newFinding,
                                                                    true
                                                                );
                                                            }}
                                                            value={
                                                                props.finding
                                                                    .config
                                                                    .arrowIndicatorColumn !=
                                                                null
                                                                    ? Variables(
                                                                          props
                                                                              .finding
                                                                              ?.config
                                                                              .dataScope
                                                                              ?.value,
                                                                          props.currentModuleId
                                                                      )
                                                                          .variableOptions[
                                                                          props
                                                                              .finding
                                                                              .config
                                                                              .arrowIndicatorColumn
                                                                              .value
                                                                      ]
                                                                    : null
                                                            }
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 0,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    text:
                                                                        "white",
                                                                    primary25:
                                                                        "var(--selectors-background-hover-color)",
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                    <ErrorColorsChanger
                                                        onChange={
                                                            props.onChange
                                                        }
                                                        finding={props.finding}
                                                    />
                                                </>
                                            </Accordion.Collapse>
                                        </Accordion>
                                        <span
                                            className={styles.thinOptionName}
                                            style={{ marginTop: 30 }}
                                        >
                                            Sort by
                                        </span>
                                        <div
                                            className="my-row"
                                            style={{
                                                alignItems: "center",
                                                marginTop: "5px",
                                            }}
                                        >
                                            <Select
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder="Select variable"
                                                styles={{
                                                    ...selectStyles,
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: 4,
                                                    }),
                                                    container: (base) => ({
                                                        ...base,
                                                        flexGrow: 1,
                                                        width: '50%',
                                                    }),
                                                    indicatorSeparator: (
                                                        provided
                                                    ) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                }}
                                                options={
                                                    activeVariableOptions
                                                }
                                                onChange={(newValue) => {
                                                    const newFinding: Finding = {
                                                        ...(props.finding as Finding),
                                                        config: {
                                                            ...props.finding
                                                                ?.config,
                                                            sortBy: {
                                                                label:
                                                                    newValue?.label ??
                                                                    "",
                                                                value:
                                                                    newValue?.value ??
                                                                    null,
                                                                type:
                                                                    newValue?.type ??
                                                                    null,
                                                            },
                                                        },
                                                    };
                                                    props.onChange(
                                                        newFinding,
                                                        true
                                                    );
                                                }}
                                                value={
                                                    props.finding.config
                                                        ?.sortBy ?? null
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                            {(!numericalVariableOptions.map(x => x.label)
                                                .includes(props.finding.config?.sortBy?.label))?
                                                <Select
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    placeholder="Order"
                                                    styles={{
                                                        ...selectStyles,
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: 4,
                                                        }),
                                                        container: (base) => ({
                                                            ...base,
                                                            flexGrow: 1,
                                                            width: '50%',
                                                            paddingLeft: 10
                                                        }),
                                                        indicatorSeparator: (
                                                            provided
                                                        ) => ({
                                                            ...provided,
                                                            display: "none",
                                                        }),
                                                    }}
                                                    options={
                                                        ascDescOptions
                                                    }
                                                    onChange={(newValue) => {
                                                        const newFinding: Finding = {
                                                            ...(props.finding as Finding),
                                                            config: {
                                                                ...props.finding
                                                                    ?.config,
                                                                sortByAscDesc: {
                                                                    label:
                                                                        newValue?.label ??
                                                                        "",
                                                                    value:
                                                                        newValue?.value ??
                                                                        null,
                                                                },
                                                            },
                                                        };
                                                        props.onChange(
                                                            newFinding,
                                                            true
                                                        );
                                                    }}
                                                    value={
                                                        props.finding.config
                                                            ?.sortByAscDesc ?? null
                                                    }
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                /> : null
                                            }
                                        </div>
                                        <span
                                            className={styles.thinOptionName}
                                            style={{ marginTop: 30 }}
                                        >
                                            Second sorting criteria
                                        </span>
                                        <div
                                            className="my-row"
                                            style={{
                                                alignItems: "center",
                                                marginTop: "5px",
                                            }}
                                        >
                                            <Select
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder="Select variable"
                                                styles={{
                                                    ...selectStyles,
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: 4,
                                                    }),
                                                    container: (base) => ({
                                                        ...base,
                                                        flexGrow: 1,
                                                        width: '50%'

                                                    }),
                                                    indicatorSeparator: (
                                                        provided
                                                    ) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                }}
                                                options={
                                                    activeVariableOptions
                                                }
                                                onChange={(newValue) => {
                                                    const newFinding: Finding = {
                                                        ...(props.finding as Finding),
                                                        config: {
                                                            ...props.finding
                                                                ?.config,
                                                                sortBySecondary: {
                                                                label:
                                                                    newValue?.label ??
                                                                    "",
                                                                value:
                                                                    newValue?.value ??
                                                                    null,
                                                                type:
                                                                    newValue?.type ??
                                                                    null,
                                                            },
                                                        },
                                                    };
                                                    props.onChange(
                                                        newFinding,
                                                        true
                                                    );
                                                }}
                                                value={
                                                    props.finding.config
                                                        ?.sortBySecondary ?? null
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />

                                            {(!numericalVariableOptions.map(x => x.label)
                                                .includes(props.finding.config?.sortBySecondary?.label))? 
                                                <Select
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    placeholder="Order"
                                                    styles={{
                                                        ...selectStyles,
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: 4,
                                                        }),
                                                        container: (base) => ({
                                                            ...base,
                                                            flexGrow: 1,
                                                            width: '50%',
                                                            paddingLeft: 10
                                                        }),
                                                        indicatorSeparator: (
                                                            provided
                                                        ) => ({
                                                            ...provided,
                                                            display: "none",
                                                        }),
                                                    }}
                                                    options={
                                                        ascDescOptions
                                                    }
                                                    onChange={(newValue) => {
                                                        const newFinding: Finding = {
                                                            ...(props.finding as Finding),
                                                            config: {
                                                                ...props.finding
                                                                    ?.config,
                                                                sortByAscDescSecondary: {
                                                                    label:
                                                                        newValue?.label ??
                                                                        "",
                                                                    value:
                                                                        newValue?.value ??
                                                                        null,
                                                                },
                                                            },
                                                        };
                                                        props.onChange(
                                                            newFinding,
                                                            true
                                                        );
                                                    }}
                                                    value={
                                                        props.finding.config
                                                            ?.sortByAscDescSecondary ?? null
                                                    }
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                /> : null }
                                        </div>
                                    </>
                                )}
                                {!isAiCopilot(finding) && (
                                    <>
                                        <div className={styles.filterContainer}>
                                            <MagicWand
                                                onClearEditing={
                                                    props.onClearEditing
                                                }
                                                title={"Filter"}
                                                dashboardId={props.dashboardId}
                                                allowMultiple={false}
                                                canvasTreeStore={
                                                    props.canvasTreeStore
                                                }
                                                type={DynamicOptionType.Filter}
                                                optionName={"conditions"}
                                            ></MagicWand>
                                            <span
                                                style={{ marginTop: 0 }}
                                                className={
                                                    styles.thinOptionName
                                                }
                                            >
                                                Filter
                                            </span>
                                        </div>
                                        <ConditionsSelector
                                            style={{ marginTop: 5 }}
                                            key={finding.config.dataScope.value}
                                            currentModuleId={
                                                props.currentModuleId
                                            }
                                            small
                                            dataScopeId={
                                                finding.config.dataScope.value
                                            }
                                            value={
                                                finding.config.conditions &&
                                                finding.config.conditions
                                                    .length > 0
                                                    ? finding.config.conditions
                                                    : ConditionsSelector.defaultValue
                                            }
                                            allowLinks
                                            nodeLinkOptions={nodeLinkOptions}
                                            title={""}
                                            onChange={props.onChangeConditions}
                                            allVariables={
                                                Variables(
                                                    finding.config.dataScope
                                                        .value,
                                                    props.currentModuleId
                                                ).dataVariables
                                            }
                                            {...ConditionSelectorStyles}
                                        />
                                    </>
                                )}

                                {isAiCopilot(finding) && (
                                    <>
                                        <span
                                            className={styles.thinOptionName}
                                            style={{ marginTop: 50 }}
                                        >
                                            Large Language Model Engine
                                        </span>
                                        <div
                                            className="my-row"
                                            style={{
                                                alignItems: "center",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <Select
                                                menuPlacement="auto"
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={"Choose your LLM"}
                                                styles={{
                                                    ...selectStyles,
                                                    container: (base) => ({
                                                        ...base,
                                                        width: "100%",
                                                        height: "38px",
                                                    }),
                                                }}
                                                options={[
                                                    {
                                                        value: "option1",
                                                        label:
                                                            "OpenAI ChatGPT 3.5",
                                                    },
                                                    {
                                                        value: "option2",
                                                        label: "Llama3 (Coming Soon)",
                                                    },
                                                    {
                                                        value: "option3",
                                                        label:
                                                            "Mistral (Coming Soon)",
                                                    },
                                                    {
                                                        value: "option3",
                                                        label: "Your Own (Coming Soon)",
                                                    },
                                                ]}
                                                // onChange={
                                                //     props.onChangeSelectedTable
                                                // }
                                                // value={finding.config.selectedTable}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        </div>
                                    </>
                                )}

                            {isLeadersLaggers(props.finding) && (
                                <>
                                    <div>
                                        <span
                                            className={styles.thinOptionName}
                                            style={{ marginTop: 30 }}
                                        >
                                            Logo (values must be in comma
                                            delimeted list)
                                        </span>
                                        <div
                                            className="my-row"
                                            style={{
                                                alignItems: "center",
                                                marginTop: "5px",
                                            }}
                                        >
                                            <Select
                                                isClearable={true}
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                menuPlacement="auto"
                                                placeholder="Select variable"
                                                styles={{
                                                    ...selectStyles,
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: 4,
                                                    }),
                                                    container: (base) => ({
                                                        ...base,
                                                        flexGrow: 1,
                                                    }),
                                                    indicatorSeparator: (
                                                        provided
                                                    ) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                }}
                                                options={stringVariableOptions}
                                                onChange={(newValue) => {
                                                    if (!props.finding) return;
                                                    if (!newValue) {
                                                        const newFinding: Finding = {
                                                            ...(props.finding as Finding),
                                                            config: {
                                                                ...(props
                                                                    .finding
                                                                    ?.config ??
                                                                    {}),
                                                                logoColumn: {
                                                                    label: null,
                                                                    value: null,
                                                                },
                                                            },
                                                        };
                                                        props.onChange(
                                                            newFinding,
                                                            true
                                                        );
                                                        return;
                                                    }

                                                    loadLogos(
                                                        {
                                                            currentModuleId:
                                                                props.currentModuleId, // RemoteModuleId
                                                            searchIndex:
                                                                newValue?.value,
                                                            dataScopeId:
                                                                props.finding
                                                                    .config
                                                                    ?.dataScope
                                                                    ?.value,
                                                        },
                                                        (logos) => {
                                                            let colorIdx = 0;
                                                            const logosColumnData = logos.map(
                                                                (
                                                                    logo: string,
                                                                    idx: number
                                                                ) => {
                                                                    if (
                                                                        colorIdx ===
                                                                        colorList.length
                                                                    ) {
                                                                        colorIdx = 0;
                                                                        const data = {
                                                                            label: logo,
                                                                            background:
                                                                                colorList[
                                                                                colorIdx
                                                                                ],
                                                                            color:
                                                                                "#fff",
                                                                            textLogo:
                                                                                logo[0],
                                                                        };
                                                                        colorIdx++;
                                                                        return data;
                                                                    }
                                                                    const data = {
                                                                        label: logo,
                                                                        background:
                                                                            colorList[
                                                                            colorIdx
                                                                            ],
                                                                        color:
                                                                            "#fff",
                                                                        textLogo:
                                                                            logo[0],
                                                                    };
                                                                    colorIdx++;
                                                                    return data;
                                                                }
                                                            );

                                                            const newFinding: Finding = {
                                                                ...(props.finding as Finding),
                                                                config: {
                                                                    ...(props
                                                                        .finding
                                                                        ?.config ??
                                                                        {}),
                                                                    logoColumn: newValue,
                                                                    logosColumnData,
                                                                },
                                                            };
                                                            props.onChange(
                                                                newFinding,
                                                                true
                                                            );
                                                        }
                                                    );
                                                }}
                                                value={
                                                    props.finding?.config
                                                        ?.logoColumn ?? null
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="my-row"
                                        style={{
                                            alignItems: "center",
                                            marginTop: "5px",
                                        }}
                                    >
                                        <span
                                            className={styles.thinOptionName}
                                            style={{ marginTop: 30 }}
                                        >
                                            Enable Download
                                        </span>

                                        <div
                                            className={commonStyles.optionContainer}
                                            style={{ marginTop: 30, marginLeft: 15 }}
                                        >
                                            <div className="pretty p-default" contentEditable={false}>
                                                <input
                                                    type="checkbox"
                                                    checked={props.finding.config.download}
                                                    onChange={() => {
                                                        let newFinding = {
                                                            ...props.finding,
                                                        };
                                                        if(!newFinding.config.navigation)
                                                            newFinding.config.download = true
                                                        newFinding.config.download = !newFinding.config.download;
                                                        props.onChange(newFinding as any, false);
                                                    }}
                                                />
                                                <div className="state p-primary">
                                                    <label className={commonStyles.optionCheckboxName}>
                                                        {props.finding.config.download ? "Active" : "Inactive"}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="my-row"
                                        style={{
                                            alignItems: "center",
                                            marginTop: "5px",
                                        }}
                                    >
                                        <span
                                            className={styles.thinOptionName}
                                            style={{ marginTop: 30 }}
                                        >
                                            Navigation
                                        </span>

                                        <div
                                            className={commonStyles.optionContainer}
                                            style={{ marginTop: 30, marginLeft: 15 }}
                                        >
                                            <div className="pretty p-default" contentEditable={false}>
                                                <input
                                                    type="checkbox"
                                                    checked={props.finding.config.navigation?.status}
                                                    onChange={() => {
                                                        let newFinding = {
                                                            ...props.finding,
                                                        };
                                                        if(!newFinding.config.navigation)
                                                            newFinding.config.navigation = {}
                                                        newFinding.config.navigation.status = !newFinding.config.navigation?.status ?? true;
                                                        props.onChange(newFinding as any, false);

                                                    }}
                                                />
                                                <div className="state p-primary">
                                                    <label className={commonStyles.optionCheckboxName}>
                                                        {props.finding.config.navigation?.status ? "Active" : "Inactive"}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(props.finding.config.navigation?.status) && (
                                        <div
                                            className="my-column"
                                            style={{
                                                alignItems: "center",
                                                marginTop: "5px",
                                            }}
                                        >
                                            <div
                                                className={commonStyles.optionContainer}
                                                style={{ marginTop: "15px" }}
                                            >
                                                <div className="pretty p-default" contentEditable={false}>
                                                    <input
                                                        type="checkbox"
                                                        checked={props.finding.config.navigation.internal}
                                                        onChange={() => {
                                                            let newFinding = {
                                                                ...props.finding,
                                                            };
                                                            newFinding.config.navigation.internal = !newFinding.config.navigation.internal;
                                                            props.onChange(newFinding as any, false);

                                                        }}
                                                    />
                                                    <div className="state p-primary">
                                                        <label className={commonStyles.optionCheckboxName}>
                                                            {props.finding.config.navigation.internal ? "Internal" : "External"}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{ marginTop: 15 }}
                                            >
                                                <span
                                                    className={styles.thinOptionName}
                                                >
                                                    Link Column
                                                </span>
                                                <div
                                                    className="my-row"
                                                    style={{
                                                        alignItems: "center",
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    <Select
                                                        isClearable={true}
                                                        filterOption={createFilter({
                                                            ignoreAccents: false,
                                                        })}
                                                        menuPlacement="auto"
                                                        placeholder="Select variable"
                                                        styles={{
                                                            ...selectStyles,
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: 4,
                                                            }),
                                                            container: (base) => ({
                                                                ...base,
                                                                flexGrow: 1,
                                                            }),
                                                            indicatorSeparator: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                display: "none",
                                                            }),
                                                        }}
                                                        options={variableOptions}
                                                        onChange={(newValue) => {
                                                            if (!props.finding) return;

                                                            const newFinding: Finding = finding;
                                                            newFinding.config.navigation.column =  {
                                                                label: newValue?.label ?? null,
                                                                value: newValue?.value ?? null,
                                                            };
                                                            props.onChange(
                                                                newFinding,
                                                                true
                                                            );
                                                            return;
                                                        }}
                                                        value={
                                                            props.finding?.config
                                                                ?.navigation.column ?? null
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            {(!props.finding.config.navigation.internal) && (
                                                <div
                                                    style={{ marginTop: 15 }}
                                                >
                                                    <span
                                                        className={styles.thinOptionName}
                                                    >
                                                        Dataset link column
                                                    </span>
                                                    <div
                                                        className="my-row"
                                                        style={{
                                                            alignItems: "center",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        <Select
                                                            isClearable={true}
                                                            filterOption={createFilter({
                                                                ignoreAccents: false,
                                                            })}
                                                            menuPlacement="auto"
                                                            placeholder="Select variable"
                                                            styles={{
                                                                ...selectStyles,
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: 4,
                                                                }),
                                                                container: (base) => ({
                                                                    ...base,
                                                                    flexGrow: 1,
                                                                }),
                                                                indicatorSeparator: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    display: "none",
                                                                }),
                                                            }}
                                                            options={variableOptions}
                                                            onChange={(newValue) => {
                                                                if (!props.finding) return;
                                                                const newFinding: any = finding;
                                                                newFinding.config.navigation.externalLinkColumn = {
                                                                    label: newValue?.label ?? null,
                                                                    value: newValue?.value ?? null,
                                                                };
                                                                props.onChange(
                                                                    newFinding,
                                                                    true
                                                                );
                                                                return;
                                                            }}
                                                            value={
                                                                props.finding?.config
                                                                    ?.navigation?.externalLinkColumn ?? null
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {(props.finding.config.navigation.internal) && (
                                                <div
                                                    style={{ marginTop: 15 }}
                                                >
                                                    <span
                                                        className={styles.thinOptionName}
                                                    >
                                                        Popup Page
                                                    </span>
                                                    <div
                                                        className="my-row"
                                                        style={{
                                                            alignItems: "center",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        <Select
                                                            isClearable={true}
                                                            filterOption={createFilter({
                                                                ignoreAccents: false,
                                                            })}
                                                            menuPlacement="auto"
                                                            placeholder="Select variable"
                                                            styles={{
                                                                ...selectStyles,
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderRadius: 4,
                                                                }),
                                                                container: (base) => ({
                                                                    ...base,
                                                                    flexGrow: 1,
                                                                }),
                                                                indicatorSeparator: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    display: "none",
                                                                }),
                                                            }}
                                                            options={pages}
                                                            onChange={(newValue) => {
                                                                if (!props.finding) return;
                                                                const newFinding: any = finding;
                                                                newFinding.config.navigation.popupSlide =  {
                                                                    label: newValue?.label ?? null,
                                                                    value: newValue?.value ?? null,
                                                                };
                                                                props.onChange(
                                                                    newFinding,
                                                                    true
                                                                );
                                                                return;
                                                            }}
                                                            value={
                                                                props.finding?.config
                                                                    ?.navigation?.popupSlide ?? null
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                        )}
                    </React.Fragment>
                )}
        </div>
    );
});
