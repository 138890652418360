import { useEffect, useState } from "react";
import {
    getModulePaywallApi,
    addOrEditModulePaywallApi,
    removeModulePaywallApi,
    addSelfSignupLinkToModuleApi,
    getSelfSignupLinkToModuleApi,
    removeSelfSignupLinkFromModuleApi,
} from "common/ModulesPaywallApi";
import ProductParametersInput, {
    PaymentType,
} from "common/mystripe_components/ProductParametersInput";
import { SelfSignupInfo, updateSharedModuleImageAndThumbnail } from "common/ModulesApi";
import { mainStyle } from "common/MainStyle";
import Switch from "react-switch";
import { selfSignupModuleLinkIdIdToUrl } from "common/utilities/linkIdToUrl";
import OutsideAlerter from "common/OutsideAlerter";
import styles from "./ManageModuleCard.module.css";
import cx from "classnames";
import { Button } from "react-bootstrap";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LinkCustomizePopup from "common/canvas/LinkCustomizePopup";
import Dropzone from "react-dropzone";
import { resizeBase64Img } from "common/utilities/resizeImage";

interface Props {
    moduleId: number;
    isKit: boolean;
    isShared: boolean;
    onClose: () => void;
}

interface PaywallEditInfo {
    name: string;
    client: string;
    paymentType: PaymentType;
    price: string;
    logo: string;
}

export default function ManageModuleCard(props: Props) {
    useEffect(() => {
        getModulePaywallApi(props.moduleId)
            .then((paywallInfo) => {
                if (paywallInfo != null) {
                    setAddPaywall(true);
                    setPaywallInfo({
                        name: paywallInfo.title,
                        client: paywallInfo.client,
                        paymentType: paywallInfo.payment_type,
                        price: paywallInfo.price.toString(),
                        logo: paywallInfo.logo ?? "",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
        getSelfSignupLinkToModuleApi(props.moduleId)
            .then((data) => {
                setSelfSignupLinkId(data.self_signup_link_id);
                setSelfSignupInfo(
                    data.self_signup_link_id != null
                        ? {
                              logo: data.logo ?? null,
                              options:
                                  data.options != null
                                      ? JSON.parse(data?.options)
                                      : null,
                          }
                        : null
                );
            })
            .catch((error) => {
                console.log(error);
            });
    }, [props.moduleId]);
    let [showSelfSignupInfoPopup, setShowSignupInfoPopup] = useState<boolean>(
        false
    );
    let [addPayWall, setAddPaywall] = useState<boolean>(false);
    let [paywallInfo, setPaywallInfo] = useState<PaywallEditInfo | null>(null);
    let [popupInfo, setPopupInfo] = useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    let [selfSignupLinkId, setSelfSignupLinkId] = useState<string | null>(null);
    let [selfSignupInfo, setSelfSignupInfo] = useState<SelfSignupInfo | null>(
        null
    );
    let [moduleThumbnail, setModuleThumbnail] = useState<string | undefined>(undefined);
    let [imgBlob, setImgBlob] = useState<Blob | null>(null);
   
    return (
        <OutsideAlerter onReject={props.onClose}>
            <div
                className={cx("dashboard-rect flex-simple-column", styles.root)}
            >
                <span
                    style={{
                        marginTop: 20,
                        marginLeft: 16,
                        color: mainStyle.getPropertyValue(
                            "--popup-primary-text-color"
                        ),
                        fontSize: "20px",
                        fontWeight: 400,
                        fontFamily: "Roboto",
                    }}
                    className="unselectable"
                >
                    Access Settings
                </span>
                <div
                    className="my-row"
                    style={{
                        marginTop: 10,
                        marginLeft: 16,
                        alignItems: "center",
                    }}
                >
                     <span
                        style={{
                            marginRight: 5,
                            color: "var(--popup-primary-text-color)",
                            fontSize: "12px",
                            fontWeight: 400,
                            fontFamily: "Roboto",
                            width: "17em",
                        }}
                        className="unselectable"
                    >
                        Replace thumbnail:
                    </span>
                    <div
                        style={{
                            paddingLeft: 0,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "20px"
                        }}
                    >
                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                if (acceptedFiles.length > 0) {
                                    const file = acceptedFiles[0];
                                    setImgBlob(file);
                                    const { type } = file;
                                    if (
                                        !type.includes("image") ||
                                        type.includes("gif")
                                    ) {
                                        return;
                                    }

                                    const reader = new FileReader();
                                    reader.onload = () => {
                                        const binaryStr =
                                            typeof reader.result ===
                                                "string"
                                                ? reader.result.toString()
                                                : "";

                                        //generates a 50% smaller image to save as thumbnail
                                        resizeBase64Img(binaryStr, .5).then((res) => {
                                            setModuleThumbnail(res.thumbnail);
                                        });
                                    };

                                    reader.readAsDataURL(acceptedFiles[0]);
                                }
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section
                                    style={{
                                        display: "flex",
                                        width: '100px',
                                        height: "100px",
                                        textAlign: "center",
                                        borderRadius: "4px",
                                        alignItems: "center",
                                        backgroundColor: "rgb(217, 221, 225)",
                                        color: "#000000",
                                        overflow: "hidden"
                                    }}
                                >
                                    <div
                                        {...getRootProps()}
                                        style={{
                                            position: "relative",
                                            width: "100%",
                                            height: "100%",
                                            padding: "5px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            display: "flex"
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        {!moduleThumbnail && (
                                            <p>Select File to Upload</p>
                                        )}
                                        <img
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                padding: "2px"
                                            }}
                                            src={moduleThumbnail}
                                            alt=""
                                        />
                                    </div>
                                </section>
                            )}
                        </Dropzone>

                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                marginTop: "5px",
                                width: "100px",
                                height: "33px",
                                paddingTop: "6.5px",
                            }}
                            onClick={() => {
                                updateSharedModuleImageAndThumbnail(
                                    props.moduleId,
                                    moduleThumbnail ?? '',
                                    imgBlob
                                ).then(() => {
                                    props.onClose();
                                });
                            }}
                        >
                            REPLACE
                        </Button>

                    </div>
                    
                    




                </div>
                <div
                    className="my-row"
                    style={{
                        marginTop: 10,
                        marginLeft: 16,
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            marginRight: 5,
                            color: "var(--popup-primary-text-color)",
                            fontSize: "12px",
                            fontWeight: 400,
                            fontFamily: "Roboto",
                            width: "17em",
                        }}
                        className="unselectable"
                    >
                        Permanent self-served signup link:
                    </span>
                    {selfSignupLinkId != null ? (
                        <>
                            <CopyToClipboard
                                text={selfSignupModuleLinkIdIdToUrl(
                                    selfSignupLinkId
                                )}
                                options={{
                                    format: "text/plain",
                                }}
                                onCopy={() => {
                                    setPopupInfo({
                                        status: PopupStatus.Success,
                                        message:
                                            "Self-Served Signup Link Copied to Clipboard",
                                    });
                                }}
                            >
                                <div
                                    className="my-row"
                                    style={{
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        alt=""
                                        src="/dist/img/canvas/share_module_link.png"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />
                                    <span
                                        style={{
                                            marginLeft: 5,
                                            color: mainStyle.getPropertyValue(
                                                "--popup-secondary-text-color"
                                            ),
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            fontFamily: "Roboto",
                                        }}
                                        className="unselectable"
                                    >
                                        COPY
                                    </span>
                                </div>
                            </CopyToClipboard>
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: "30px",
                                    width: "112px",
                                    height: "33px",
                                    paddingTop: "6.5px",
                                }}
                                onClick={() => {
                                    removeSelfSignupLinkFromModuleApi(
                                        props.moduleId
                                    )
                                        .then(() => {
                                            setSelfSignupLinkId(null);
                                        })
                                        .catch((error: string) => {
                                            setPopupInfo({
                                                status: PopupStatus.Error,
                                                message: error,
                                            });
                                        });
                                }}
                            >
                                REMOVE
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    marginLeft: "5px",
                                    width: "112px",
                                    height: "33px",
                                    paddingTop: "6.5px",
                                    marginRight: "5px",
                                }}
                                onClick={() => {
                                    setShowSignupInfoPopup(true);
                                }}
                            >
                                CUSTOMIZE
                            </Button>
                        </>
                    ) : (
                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                width: "112px",
                                height: "33px",
                                paddingTop: "6.5px",
                            }}
                            onClick={() => {
                                addSelfSignupLinkToModuleApi(
                                    props.moduleId,
                                    null,
                                    null
                                )
                                    .then((selfSignupLinkId) => {
                                        setSelfSignupLinkId(selfSignupLinkId);
                                    })
                                    .catch((error) => {
                                        setPopupInfo({
                                            status: PopupStatus.Error,
                                            message: error,
                                        });
                                    });
                            }}
                        >
                            CREATE
                        </Button>
                    )}
                </div>
                {props.isKit && !props.isShared && (
                    <>
                        <span
                            style={{
                                marginTop: 20,
                                marginLeft: 16,
                                color: mainStyle.getPropertyValue(
                                    "--popup-primary-text-color"
                                ),
                                fontSize: "20px",
                                fontWeight: 400,
                                fontFamily: "Roboto",
                            }}
                            className="unselectable"
                        >
                            Paywall Settings
                        </span>
                        <div
                            className="my-row"
                            style={{
                                marginLeft: 21,
                                marginTop: 9,
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 23,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                    marginRight: 5,
                                    verticalAlign: "middle",
                                }}
                                className="unselectable"
                            >
                                Add Paywall
                            </span>
                            <Switch
                                onChange={(checked) => {
                                    setAddPaywall(checked);
                                    setPaywallInfo({
                                        price: "",
                                        name: "",
                                        paymentType: "one_time",
                                        client: "",
                                        logo: "",
                                    });
                                }}
                                checked={addPayWall}
                                width={26}
                                height={13}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                        {addPayWall && paywallInfo && (
                            <div
                                className="my-row"
                                style={{
                                    marginLeft: 21,
                                    marginTop: 15,
                                    alignItems: "center",
                                }}
                            >
                                <ProductParametersInput
                                    client={paywallInfo.client}
                                    name={paywallInfo.name}
                                    paymentType={paywallInfo.paymentType}
                                    price={paywallInfo.price}
                                    logo={paywallInfo.logo}
                                    onChange={(changes) => {
                                        let newPaywallInfo: PaywallEditInfo = {
                                            ...paywallInfo,
                                            ...changes,
                                        } as PaywallEditInfo;
                                        setPaywallInfo(newPaywallInfo);
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
                <div style={{ flexGrow: 1 }} />
                {props.isKit && !props.isShared && (
                    <Button
                        type="button"
                        style={{
                            alignSelf: "flex-end",
                            width: 100,
                            marginBottom: "20px",
                            marginRight: "20px",
                        }}
                        className="btn btn-md btn-primary my-primary"
                        onClick={() => {
                            setPopupInfo({
                                status: PopupStatus.Success,
                                message: "Updating paywall...",
                            });
                            if (addPayWall && paywallInfo != null) {
                                addOrEditModulePaywallApi(props.moduleId, {
                                    title: paywallInfo.name,
                                    client: paywallInfo.client,
                                    price: Number(paywallInfo.price),
                                    logo: paywallInfo.logo,
                                    payment_type: paywallInfo.paymentType,
                                    description: "",
                                })
                                    .then(() => {
                                        setPopupInfo({
                                            status: PopupStatus.Success,
                                            message: "Paywall is updated",
                                        });
                                    })
                                    .catch((error) => {
                                        setPopupInfo({
                                            status: PopupStatus.Error,
                                            message: error,
                                        });
                                    });
                            }
                            if (!addPayWall) {
                                removeModulePaywallApi(props.moduleId)
                                    .then(() => {
                                        setPopupInfo({
                                            status: PopupStatus.Success,
                                            message: "Paywall is removed",
                                        });
                                    })
                                    .catch((error) => {
                                        setPopupInfo({
                                            status: PopupStatus.Error,
                                            message: error,
                                        });
                                    });
                            }
                        }}
                    >
                        {"SAVE"}
                    </Button>
                )}
                {popupInfo && (
                    <StatusPopup
                        status={popupInfo.status}
                        message={popupInfo.message}
                        onClose={() => {
                            setPopupInfo(null);
                        }}
                    />
                )}
                {showSelfSignupInfoPopup && (
                    <LinkCustomizePopup
                        selfSignupInfo={selfSignupInfo}
                        onApply={(selfSignupInfo: SelfSignupInfo) => {
                            addSelfSignupLinkToModuleApi(
                                props.moduleId!,
                                JSON.stringify(selfSignupInfo.options),
                                selfSignupInfo.logo
                            )
                                .then((selfSignupLinkId) => {
                                    setSelfSignupInfo(selfSignupInfo);
                                    setSelfSignupLinkId(selfSignupLinkId);
                                    setPopupInfo({
                                        message: "Options applied",
                                        status: PopupStatus.Success,
                                    });
                                    setShowSignupInfoPopup(false);
                                })
                                .catch((error) => {
                                    setPopupInfo({
                                        status: PopupStatus.Error,
                                        message: String(error),
                                    });
                                });
                        }}
                        onClose={() => {
                            setShowSignupInfoPopup(false);
                        }}
                    />
                )}
            </div>
        </OutsideAlerter>
    );
}
