const monthName: ReadonlyArray<string> = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
];

const monthNameShort: ReadonlyArray<string> = monthName.map((month) =>
    month.slice(0, 3)
);

const monthKeyboard: ReadonlyArray<string> = [
    "j",
    "f",
    "m",
    "a",
    "m",
    "j",
    "j",
    "a",
    "s",
    "o",
    "n",
    "d",
];
const monthToIndex: { [key: string]: number } = monthName.reduce(
    (acc: any, month: string, index: number) => {
        acc[month] = index + 1;
        return acc;
    },
    {}
);

export function dateToUtc(date: Date): Date {
    let timeZoneOffsetInMs: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeZoneOffsetInMs);
    return date;
}

function getLabelByTime(time: number, timeType: string | null): string {
    if (isNaN(time)) return "NULL";
    if (timeType == null || timeType === "datetime") {
        const resultingDate = dateToUtc(new Date(time * 1000));
        return `${resultingDate.getFullYear()} ${
            monthNameShort[resultingDate.getMonth()]
        }\n${resultingDate.getDate()}`;
    } else {
        const year = new Date().getFullYear();
        const day = 1 + (time - 1) * 7; // 1st of January + 7 days for each week
        const resultingDate = new Date(year, 0, day);
        let month = resultingDate.getMonth();
        if (!month) return "";
        return `${monthNameShort[month]}\n${resultingDate.getDate()}`;
    }
}

export {
    monthName,
    monthNameShort,
    monthKeyboard,
    monthToIndex,
    getLabelByTime,
};
