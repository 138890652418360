import { NodeValue } from "common/Canvas";
import CurrentUser from "common/CurrentUser";
import * as d3 from "d3-time-format";
import moment from "moment";

export const defaultDate: Date = new Date("2022-01-01T02:18:46");

export enum FormatType {
    Date = 1,
    Time = 2,
    DateTime = 3,
}

const dateLetters = ["m", "d", "y", "Y", "b", "B", "A"];
const timeLetters = ["I", "M", "S", "p", "H"];

export const dateFormats = [
    "%m/%d/%Y",
    "%m/%d/%y",
    "%m/%d",
    "%B %d, %Y",
    "%b %d, %Y",
    "%d/%m/%Y",
    "%Y-%m-%d",
    "%Y",
    "%B",
    "%A",
];
export const timeFormats = ["%I:%M %p", "%I:%M:%S %p", "%H:%M:%S", "%H:%M"];

export const dateTimeFormats = [
    "%m/%d/%Y %I:%M %p",
    "%m/%d/%Y %I:%M:%S %p",
    "%m/%d/%Y %H:%M:%S",
    "%m/%d/%Y %H:%M",
    "%m/%d/%y %I:%M %p",
    "%m/%d/%y %I:%M:%S %p",
    "%m/%d/%y %H:%M:%S",
    "%m/%d/%y %H:%M",
    "%Y-%m-%dT%H:%M:%S",
    "%Y-%m-%dT%H:%M:%SZ",
];

export const dateTimeFormatOptionsForLevels: Readonly<{
    [key: string]: { label: string; value: string }[];
}> = {
    year: [
        {
            label: "2022",
            value: "%Y",
        },
        {
            label: "22",
            value: "%y",
        },
    ],
    month: [
        {
            label: "1/22",
            value: "%-m/%y",
        },
        {
            label: "Jan, 2022",
            value: "%b %Y",
        },
        {
            label: "Jan",
            value: "%b",
        },
    ],
    day: [
        {
            label: "1/16",
            value: "%-m/%d",
        },
        {
            label: "Jan 16, 2022",
            value: "%b %-d, %Y",
        },
        {
            label: "1/16/22",
            value: "%-m/%d/%y",
        },
        {
            label: "Sun",
            value: "%a",
        },
    ],
    hour: [
        {
            label: "1/16 11 PM",
            value: "%-m/%d %-H %p",
        },
        {
            label: "11 PM",
            value: "%-H %p",
        },
    ],
    minute: [
        {
            label: "11:15 PM",
            value: "%-H:%M %p",
        },
        {
            label: "1/16 11:15 PM",
            value: "%-m/%d %-H:%M %p",
        },
        {
            label: "15",
            value: "%M",
        },
    ],
};

function detectFormatType(format: string): FormatType {
    let terms = format.split("%");
    terms.shift();
    if (terms.length === 0) return FormatType.DateTime;
    terms = terms.map((term) => (term.length > 0 ? term[0] : ""));
    let dateLettersCount = 0;
    let timeLettersCount = 0;
    for (let term of terms) {
        if (dateLetters.includes(term)) dateLettersCount += 1;
        if (timeLetters.includes(term)) timeLettersCount += 1;
    }
    if (dateLettersCount === terms.length) return FormatType.Date;
    if (timeLettersCount === terms.length) return FormatType.Time;
    return FormatType.DateTime;
}

export function getFormatType(format: string): FormatType {
    if (dateFormats.includes(format)) return FormatType.Date;
    if (timeFormats.includes(format)) return FormatType.Time;
    if (dateTimeFormats.includes(format)) return FormatType.DateTime;
    return detectFormatType(format);
}

export function valueToDateInput(
    inputValue: NodeValue | null,
    formatType: FormatType,
    utc: boolean = true
): string | undefined {
    let value = undefined;
    if (
        inputValue != null &&
        typeof inputValue === "number" &&
        !Number.isNaN(inputValue as number)
    ) {
        let offset: number = 0;
        if (
            !utc &&
            (formatType === FormatType.DateTime ||
                formatType === FormatType.Date)
        ) {
            let userTimezone =
                CurrentUser?.info?.user_time_zone ?? moment.tz.guess();
            offset =
                moment
                    .tz((inputValue as number) * 1000, userTimezone)
                    ?.utcOffset() ?? 0;
        }
        let date = new Date((inputValue as number) * 1000 + offset * 60 * 1000);
        let isoDateTime = date.toISOString();
        if (formatType === FormatType.Date) value = isoDateTime.slice(0, 10);
        else if (formatType === FormatType.Time) {
            value = isoDateTime.slice(11, 19);
        } else value = isoDateTime.slice(0, 19);
    }
    return value;
}

export function dateInputToValue(
    dateInput: string,
    format: string,
    formatType: FormatType,
    utc: boolean = true
): { metric: string; value: number } {
    let date: Date | null = null;
    if (formatType === FormatType.Time) {
        if (dateInput.length === 5) date = strptime("%H:%M", dateInput, true);
        else date = strptime("%H:%M:%S", dateInput, true);
    } else date = new Date(dateInput);
    let offset: number = 0;
    if (!utc) {
        let userTimezone =
            CurrentUser?.info?.user_time_zone ?? moment.tz.guess();
        let defaultTimezome = moment.tz.guess();
        if (formatType === FormatType.DateTime) {
            offset =
                (moment.tz(date, userTimezone)?.utcOffset() ?? 0) -
                (moment.tz(date, defaultTimezome)?.utcOffset() ?? 0);
        } else if (formatType === FormatType.Date) {
            offset = moment.tz(date, userTimezone)?.utcOffset() ?? 0;
        }
    }
    date = new Date(date!.getTime() - offset * 60 * 1000);
    return {
        value: date!.getTime() / 1000,
        metric: strftime(format, date!, true),
    };
}

export function formatTypeToInputType(formatType: FormatType) {
    let inputType = "datetime-local";
    if (formatType === FormatType.Date) {
        inputType = "date";
    }
    if (formatType === FormatType.Time) {
        inputType = "time";
    }
    return inputType;
}

export function strftime(
    format: string,
    date: Date,
    utc: boolean = true
): string {
    let formatTime = utc ? d3.utcFormat(format) : d3.timeFormat(format);
    return formatTime(date);
}

export function strptime(
    format: string,
    timeString: string,
    utc: boolean = true
): Date | null {
    let parseTime = utc ? d3.utcParse(format) : d3.timeParse(format);
    let time = parseTime(timeString);
    return time;
}

export function formatTimestamp(
    timestamp: number,
    format: string,
    utc: boolean = true
) {
    return strftime(format, new Date(timestamp * 1000), utc);
}

export function formatTimeForCharts(timestamp: number): string {
    if (typeof timestamp !== "number") return timestamp;
    if (Number.isNaN(timestamp)) return "NaN";
    return moment
        .utc(timestamp * 1000)
        .format("YYYY MMM DD")
        .toUpperCase();
}
