import React from "react";
import Select from "react-select";
import cx from "classnames";

import { UserIcon } from "common/UserIcon";
import UserInfo from "common/UserInfo";

import styles from "./UserDelegate.module.css";

interface Props {
  id?: string;
  value?: UserInfo;
  options: UserInfo[];
  onChange: (value?: UserInfo) => void;
}

function SelectPlaceholder() {
  return (
    <div className={styles.placeholder}>
      <img
        alt="user icon placeholder"
        src="dist/img/user/select-user.png"
        className={styles.placeholderImage}
      />
      <span className={styles.placeholderLabel}>None</span>
    </div>
  );
}

function UserDelegate({ id, value, options, onChange }: Props) {
  function formatOptionLabel(option: UserInfo, meta: any): React.ReactNode {
    return (
      <div
        className={cx(
          styles.selectOption,
          { [styles.selected]: meta.context === "value" && meta.selectValue.length && meta.selectValue[0].id === option.id }
        )}
      >
        <UserIcon
          width={32}
          height={32}
          fontSize={14}
          user={option}
          iconUrl={option.icon_url}
        />
        <span className={styles.selectOptionLabel}>
          {option.first_name} {option.last_name}
        </span>
      </div>
    );
  }

  function getOptionValue(option: UserInfo): string {
    return String(option.id);
  }

  return (
    <Select
      id={id}
      options={options}
      value={value}
      onChange={(newValue) => {
        onChange(newValue as UserInfo);
      }}
      styles={{
        container: (provided) => ({
          ...provided,
          flex: "1 1 auto"
        }),
        control: (provided) => ({
          ...provided,
          borderRadius: "4px",
          minWidth: "140px"
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        option: (provided) => ({
          ...provided,
          padding: 5,
        }),
      }}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={getOptionValue}
      placeholder={<SelectPlaceholder />}
      isSearchable={false}
      onMenuClose={() => {}}
    />
  );
}

export default UserDelegate;

