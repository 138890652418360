import React from "react";
import cx from "classnames";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import Finding, { KanbanBoardFinding } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import styles from "./KanbanBoardSection.module.css";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import { VariableOption } from "common/Variables";
import variables from "common/Variables";
import { observer } from "mobx-react";
import StringOption from "common/StringOption";
import EditInput from "../EditInput";
import "pretty-checkbox/dist/pretty-checkbox.min.css";

const aggregationFunctions = [
    { value: "mean", label: "Average" },
    { value: "count_distinct", label: "Count" },
    { value: "sum", label: "Sum" },
];

interface Props {
    finding: KanbanBoardFinding;
    currentModuleId?: number;
    onChange: (finding: Finding, updateData?: boolean) => void;
}

export default observer(function KanbanBoardSection(props: Props) {
    let datasetIsConnected = props.finding.config.dataScope != null;
    if (!datasetIsConnected) return null;
    let selectStyles = getCustomSelectStyleForDataSection(14, false);

    let variableOptions: VariableOption[] = [];
    let numericOptions: VariableOption[] = [];

    if (datasetIsConnected) {
        variableOptions = variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions;
        numericOptions = variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions.filter((item) =>
            ["int", "float"].includes(item.type)
        );
    }
    let findOption = (variableIndex: number | undefined) => {
        return (
            variableOptions.find((option) => option.value === variableIndex) ??
            null
        );
    };

    let buildArrayOfVariables = (
        propName:
            | "selectedTargetVariablesIndices"
            | "selectedSummaryVariablesIndices",
        options: VariableOption[],
        title: string,
        labelsPropName?: string
    ) => {
        let getLabels = (newSelectedIndices: (number | undefined)[]) => {
            return newSelectedIndices
                .filter((item) => item != null)
                .map(
                    (item) =>
                        variableOptions.find((option) => option.value === item)
                            ?.label
                )
                .filter((item) => item != null);
        };
        let selectedIndices: (number | undefined)[] =
            props.finding.config[propName];
        if (selectedIndices == null || selectedIndices.length === 0)
            selectedIndices = [undefined];

        return (
            <>
                <div className={commonStyles.thinOptionName}>{title}</div>
                {selectedIndices.map(
                    (item: number | undefined, index: number) => (
                        <div
                            key={index}
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "8px" }}
                        >
                            <Select
                                key={index}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={"Variable"}
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "100%",
                                        height: "38px",
                                        marginRight: index === 0 ? 30 : 0,
                                    }),
                                }}
                                options={options}
                                onChange={(newValue) => {
                                    let newSelectedIndices: (
                                        | number
                                        | undefined
                                    )[] = Array.from(selectedIndices);
                                    newSelectedIndices[index] = (
                                        newValue as VariableOption
                                    ).value;
                                    let newFinding = { ...props.finding };
                                    newFinding.config[propName] =
                                        newSelectedIndices;
                                    if (labelsPropName != null) {
                                        props.finding.config[labelsPropName] =
                                            getLabels(newSelectedIndices);
                                    }
                                    props.onChange(newFinding, true);
                                }}
                                value={findOption(item)}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            {index > 0 && (
                                <Button
                                    title="Remove"
                                    className={cx(
                                        "btn btn-sm btn-primary my-primary",
                                        commonStyles.addColumnButton,
                                        commonStyles.deleteColumnButton
                                    )}
                                    onClick={() => {
                                        let newSelectedIndices =
                                            Array.from(selectedIndices);
                                        newSelectedIndices.splice(index, 1);
                                        let newFinding = { ...props.finding };

                                        newFinding.config[propName] =
                                            newSelectedIndices;
                                        if (labelsPropName != null) {
                                            props.finding.config[
                                                labelsPropName
                                            ] = getLabels(newSelectedIndices);
                                        }
                                        props.onChange(newFinding, true);
                                    }}
                                >
                                    {"\u2715"}
                                </Button>
                            )}
                        </div>
                    )
                )}
                <Button
                    style={{ marginTop: 10, width: "120px" }}
                    className={cx(
                        "btn btn-sm btn-primary my-primary",
                        commonStyles.addItemButton
                    )}
                    onClick={() => {
                        let newSelectedIndices = Array.from(selectedIndices);
                        newSelectedIndices.push(undefined);
                        let newFinding = { ...props.finding };
                        newFinding.config[propName] = newSelectedIndices;
                        if (labelsPropName != null) {
                            props.finding.config[labelsPropName] =
                                getLabels(newSelectedIndices);
                        }
                        props.onChange(newFinding, true);
                    }}
                >
                    {"\uFF0B Add variable"}
                </Button>
            </>
        );
    };

    return (
        <div>
            <div className={commonStyles.thinOptionName}>Board Title</div>

            <EditInput
                optionalStyles={{ marginTop: "10px" }}
                optionalClassName={styles.input}
                showDeleteButton={false}
                disabled={false}
                value={props.finding.config.title}
                onChange={(value) => {
                    let finding: KanbanBoardFinding = {
                        ...props.finding!,
                    };

                    finding.config.title = value;
                    props.onChange(finding, true);
                }}
            ></EditInput>
            <div className={commonStyles.thinOptionName}>Get lanes from</div>
            <Select
                filterOption={createFilter({
                    ignoreAccents: false,
                })}
                placeholder={"Lanes"}
                styles={{
                    ...selectStyles,
                    container: (base) => ({
                        ...base,
                        marginTop: "10px",
                        width: "100%",
                        height: "38px",
                    }),
                }}
                options={variableOptions}
                onChange={(newValue) => {
                    let newFinding = { ...props.finding };
                    newFinding.config.categoryVariableIndex = (
                        newValue as VariableOption
                    ).value;
                    newFinding.config.categoryVariable = (
                        newValue as VariableOption
                    ).label;
                    props.onChange(newFinding, true);
                }}
                value={findOption(props.finding.config.categoryVariableIndex)}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        text: "white",
                        primary25: "var(--selectors-background-hover-color)",
                    },
                })}
            />
            <div className={commonStyles.thinOptionName}>Get cards from</div>
            <Select
                filterOption={createFilter({
                    ignoreAccents: false,
                })}
                placeholder={"Cards"}
                styles={{
                    ...selectStyles,
                    container: (base) => ({
                        ...base,
                        marginTop: "10px",
                        width: "100%",
                        height: "38px",
                    }),
                }}
                options={variableOptions}
                onChange={(newValue) => {
                    let newFinding = { ...props.finding };
                    newFinding.config.titleVariableIndex = (
                        newValue as VariableOption
                    ).value;
                    newFinding.config.titleVariable = (
                        newValue as VariableOption
                    ).label;
                    props.onChange(newFinding, true);
                }}
                value={findOption(props.finding.config.titleVariableIndex)}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        text: "white",
                        primary25: "var(--selectors-background-hover-color)",
                    },
                })}
            />
            {buildArrayOfVariables(
                "selectedTargetVariablesIndices",
                variableOptions,
                "Include in each card"
            )}

            <div
                className={commonStyles.optionContainer}
                style={{ marginTop: "10px" }}
            >
                <div className="pretty p-default" contentEditable={false}>
                    <input
                        type="checkbox"
                        checked={props.finding.config.operationVariable != null}
                        onChange={() => {
                            let newFinding = { ...props.finding };
                            if (newFinding.config.operationVariable == null) {
                                newFinding.config.operationVariable =
                                    aggregationFunctions[0].value;
                            } else {
                                newFinding.config.operationVariable = null;
                                newFinding.config.selectedSummaryVariablesIndices =
                                    null;
                            }
                            props.onChange(newFinding, true);
                        }}
                    />
                    <div className="state p-primary">
                        <label className={commonStyles.optionCheckboxName}>
                            Display summary in cards
                        </label>
                    </div>
                </div>
            </div>
            {props.finding.config.operationVariable != null && (
                <>
                    <div className={commonStyles.optionContainer}>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Aggregation"}
                            styles={{
                                ...selectStyles,
                                container: (base) => ({
                                    ...base,
                                    marginTop: "20px",
                                    width: "120px",
                                    height: "38px",
                                }),
                            }}
                            options={aggregationFunctions}
                            onChange={(newValue) => {
                                let newFinding = { ...props.finding };
                                newFinding.config.operationVariable = (
                                    newValue as StringOption
                                ).value;
                                props.onChange(newFinding, true);
                            }}
                            value={aggregationFunctions.find(
                                (option) =>
                                    option.value ===
                                    props.finding.config.operationVariable
                            )}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <div
                            style={{ marginLeft: 5 }}
                            className={commonStyles.thinOptionName}
                        >
                            Of
                        </div>
                    </div>
                    {buildArrayOfVariables(
                        "selectedSummaryVariablesIndices",
                        numericOptions,
                        "Summary variables",
                        "selectedSummaryVariables"
                    )}
                </>
            )}
        </div>
    );
});
