import React, { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import TabTitle from "common/TabTitle";
import TabContent from "common/home_components/PresentationTabs/TabContent";
import FilterDropdown from "common/home_components/PresentationTabs/FilterDropdown";
import StoreContext from "state/StoreContext";
import FilterDropdownOption from "state/models/PresentationFilterDropdownOptions";
import ViewMode from "./ViewMode";

import { ReactComponent as PresentationIcon } from "icons/play.svg";
import { ReactComponent as AppsIcon } from "icons/monitor.svg";
import { ReactComponent as TemplateIcon } from "icons/template.svg";
import { ReactComponent as TrashIcon } from "icons/trash.svg";
import { ReactComponent as GridViewIcon } from "icons/grid_view.svg";
import { ReactComponent as ListViewIcon } from "icons/list_view.svg";

import styles from "./PresentationTabs.module.css";
import commonStyles from "modules/home_page/home_page.module.css";
import { SpecialTabId } from "common/Module";
import cx from "classnames";
import { FolderMenu } from "../FolderMenu";
import { Folder } from "common/FoldersApi";
import { CategoryData } from "state/models/Presentation";
import { defaultSelectedCategoryData } from "modules/home_page/main";

interface Props {
    onClickPresentation: Function;
    onDeletePresentation: Function;
    onCreateFolder: (key: string) => void;
    onRenameFolder: (folder: Folder) => void;
    onDeleteFolder: (folder: Folder) => void;
    changeViewMode: (viewMode: ViewMode) => void;
    viewMode: ViewMode;
    isViewer: boolean;
}

function PresentationTabs(props: Props) {
    const { viewMode } = props;
    const store = useContext(StoreContext);
    let currentTab = props.isViewer ? "apps" : "presentations";
    const [selectedTab, setSelectedTab] = useState(currentTab);

    useEffect(() => {
        if (
            selectedTab === "presentations" &&
            !store.presentationStore.presentations.length
        ) {
            store.presentationStore.fetchPresentations();
        }
        if (
            selectedTab === "templates" &&
            !store.presentationStore.templates.length
        ) {
            store.presentationStore.fetchTemplates();
        }

        if (
            selectedTab === "archive" &&
            !store.presentationStore.trash.length
        ) {
            store.presentationStore.fetchTrash();
        }
        if (selectedTab === "apps" && !store.presentationStore.apps.length) {
            store.presentationStore.fetchApps();
        }
    }, [store.presentationStore, selectedTab]);

    function selectTab(tabKey: string | null) {
        if (tabKey == null) return;
        setSelectedTab(tabKey);
        store.presentationStore.setCurrentFolder(null);
    }

    function filter(value: FilterDropdownOption) {
        store.presentationStore.filterPresentations(value);
    }

    function setGridViewMode() {
        props.changeViewMode(ViewMode.Grid);
    }

    function setListViewMode() {
        props.changeViewMode(ViewMode.List);
    }

    return (
        <>
            <div className={styles.root}>
                {(selectedTab === "presentations" ||
                    selectedTab === "templates") && (
                    <FolderMenu
                        menuId="presentations"
                        onCreateFolder={() => {
                            props.onCreateFolder(selectedTab);
                        }}
                    />
                )}

                <h3
                    className={cx(commonStyles.smallTitle, styles.presentationsTitle)}
                >PICK UP WHERE YOU LEFT OFF</h3>

                <section className={styles.tabContentHeader}>
                    <p className={commonStyles.bigText}>Your projects</p>

                    <div style={{ display: "flex" }}>
                        <FilterDropdown
                            id="presentations-dropdown-filter"
                            options={FilterDropdownOption.OPTIONS}
                            filterFunction={filter}
                        />
                        {viewMode === ViewMode.List && <button
                            type="button"
                            onClick={setGridViewMode}
                            className={cx(styles.actionButton, styles.activeButton, "tour-step-2")}
                        >
                            <GridViewIcon />
                        </button>}
                        {viewMode === ViewMode.Grid && <button
                            type="button"
                            onClick={setListViewMode}
                            className={cx(styles.actionButton, styles.activeButton)}
                        >
                            <ListViewIcon />
                        </button>}
                        <button
                            type="button"
                            onClick={() => selectTab("archive")}
                            className={cx(styles.actionButton)}
                        >
                            <TrashIcon />
                        </button>
                    </div>
                </section>

                <Tabs
                    id="home-presentation-tabs"
                    activeKey={selectedTab}
                    onSelect={selectTab}
                    className={styles.nav}
                >
                    <Tab
                        eventKey="presentations"
                        title={
                            <TabTitle
                                title="Presentations"
                                icon={
                                    <PresentationIcon
                                        className={styles.presentationIcon}
                                    />
                                }
                                selected={selectedTab === "presentations"}
                            />
                        }
                        className={styles.tab}
                    >
                        <TabContent
                            clickPresentation={(id: number) =>
                                props.onClickPresentation("presentations", id)
                            }
                            presentations={
                                store.presentationStore.presentations
                            }
                            currentFolder={
                                store.presentationStore.currentFolder ??
                                undefined
                            }
                            folders={
                                store.presentationStore.presentationFolders
                            }
                            loading={false}
                            showSkeleton={store.presentationStore.fetchingPresentations}
                            deletePresentation={(id: number) => {
                                props.onDeletePresentation("presentations", id);
                            }}
                            renameFolder={(folder) => {
                                props.onRenameFolder(folder);
                            }}
                            deleteFolder={(folder) => {
                                props.onDeleteFolder(folder);
                            }}
                            viewMode={props.viewMode}
                        />
                    </Tab>

                    <Tab
                        eventKey="templates"
                        title={
                            <TabTitle
                                title="Templates"
                                icon={
                                    <TemplateIcon
                                        className={styles.templateIcon}
                                    />
                                }
                                selected={selectedTab === "templates"}
                            />
                        }
                        className={styles.tab}
                    >
                        <TabContent
                            clickPresentation={(id: number) =>
                                props.onClickPresentation("templates", id)
                            }
                            currentFolder={
                                store.presentationStore.currentFolder ??
                                undefined
                            }
                            folders={store.presentationStore.templateFolders}
                            presentations={store.presentationStore.templates.filter(item => !item.categoryId)}
                            loading={store.presentationStore.fetchingTemplates}
                            deletePresentation={(id: number) => {
                                props.onDeletePresentation("templates", id);
                            }}
                            renameFolder={(folder) => {
                                props.onRenameFolder(folder);
                            }}
                            deleteFolder={(folder) => {
                                props.onDeleteFolder(folder);
                            }}
                            viewMode={props.viewMode}
                        />
                    </Tab>
                    <Tab
                        eventKey="apps"
                        title={
                            <TabTitle
                                title="Apps"
                                icon={<AppsIcon className={styles.appsIcon} />}
                                selected={selectedTab === "apps"}
                            />
                        }
                        className={styles.tab}
                    >
                        {
                            (store.presentationStore.apps.filter(
                                (app) => app.tabId !== SpecialTabId.CreateNew
                            ).length) ?
                                <>
                                    <div className={styles.searchContainer}>
                                        {store.presentationStore.allAppKeywords.map(
                                            (keyword: string, index: number) => (
                                                <button
                                                    key={index}
                                                    style={{
                                                        marginLeft: 18,
                                                        width: 86,
                                                        height: 37,
                                                    }}
                                                    className={cx(
                                                        store.presentationStore.appSelectedKeywords.includes(
                                                            keyword
                                                        )
                                                            ? styles.loadButton
                                                            : styles.publishButton,
                                                        "unselectable"
                                                    )}
                                                    type="button"
                                                    onClick={() => {
                                                        let selectedKeywords =
                                                            store.presentationStore
                                                                .appSelectedKeywords;

                                                        if (
                                                            selectedKeywords.includes(
                                                                keyword
                                                            )
                                                        ) {
                                                            store.presentationStore.appSelectedKeywords =
                                                                selectedKeywords.filter(
                                                                    (item) =>
                                                                        item !== keyword
                                                                );
                                                            return;
                                                        }
                                                        store.presentationStore.appSelectedKeywords =
                                                            [...selectedKeywords, keyword];
                                                    }}
                                                >
                                                    {keyword}
                                                </button>
                                            )
                                        )}
                                    </div>
                                    <div className={styles.yourApps}>Your Apps</div>

                                    <TabContent
                                        clickPresentation={(id: number) =>
                                            props.onClickPresentation("apps", id)
                                        }
                                        presentations={store.presentationStore.apps.filter(
                                            (app) => app.tabId !== SpecialTabId.CreateNew
                                        )}
                                        loading={store.presentationStore.fetchingApps}
                                        deletePresentation={(id: number) => {
                                            props.onDeletePresentation("apps", id);
                                        }}
                                        viewMode={props.viewMode}
                                    />
                                </> : <></>
                        }
                    </Tab>
                </Tabs>
                {selectedTab === "archive" && (
                    <TabContent
                        clickPresentation={(id: number) =>
                            props.onClickPresentation("archive", id)
                        }
                        presentations={store.presentationStore.trash}
                        loading={store.presentationStore.fetchingTrash}
                        deletePresentation={(id: number) => {
                            props.onDeletePresentation("archive", id);
                        }}
                        viewMode={props.viewMode}
                    />
                )}
            </div>
        </>
    );
}

export default observer(PresentationTabs);
