import React, { Component } from "react";

import GlobalContext, { GlobalContextContents } from "GlobalContext";
import styles from "../Filters.module.css";
import cx from "classnames";
import { ReactComponent as ArrowIcon } from "icons/canvas/exploration/arrow_left.svg";
import {
    Collection,
} from "common/MediaCollectionsApi";


interface ChartFilterProps {
    filterIndex: number | undefined;
    modules: Collection[];
    onChangeFilterIndex: (filterIndex: number | undefined) => void;
}

interface ChartFilterState {
    showLeftArrow: boolean;
    showRightArrow: boolean;
}

class ElementsMenuFilter extends Component<ChartFilterProps, ChartFilterState> {
    private scrollRef: React.RefObject<HTMLDivElement>;

    constructor(props: ChartFilterProps) {
        super(props);

        this.state = {
            showLeftArrow: false,
            showRightArrow: true,
        };

        this.scrollRef = React.createRef();

        this.renderContents = this.renderContents.bind(this);
    }

    showArrow(): void {
        this.scrollRef.current!.scrollLeft += 200;
        this.setState({
            showLeftArrow:
                this.scrollRef.current!.scrollLeft > 0,
            showRightArrow:
                this.scrollRef.current!.scrollLeft <
                this.scrollRef.current!.scrollWidth -
                    this.scrollRef.current!.clientWidth,
        });
    }

    private renderContents(globalContext: GlobalContextContents) {
        let selectedStyles = cx(
            styles.filterOption,
            styles.filterOptionSelected
        );
        return (
            <div className={styles.filterRoot}>
                {this.state.showLeftArrow && (
                    <div
                        className={styles.filterButton}
                        onClick={() => {
                            this.scrollRef.current!.scrollLeft -= 200;
                            this.setState({
                                showLeftArrow:
                                    this.scrollRef.current!.scrollLeft > 0,
                                showRightArrow:
                                    this.scrollRef.current!.scrollLeft <
                                    this.scrollRef.current!.scrollWidth -
                                        this.scrollRef.current!.clientWidth,
                            });
                        }}
                    >
                        <ArrowIcon
                            transform="rotate(180)"
                            width={22}
                            height={22}
                        />
                    </div>
                )}
                <div
                    ref={this.scrollRef}
                    className="my-row hide-scroll"
                    style={{
                        overflow: "auto hidden",
                    }}
                >
                    <span
                        style={{ marginLeft: "5px" }}
                        className={
                            this.props.filterIndex === undefined
                                ? selectedStyles
                                : styles.filterOption
                        }
                        onClick={() => {
                            this.props.onChangeFilterIndex(undefined);
                        }}
                    >
                        All Templates
                    </span>
                    {this.props.modules.map((module, index) => {
                        return (
                            <span
                                key={index}
                                style={{ marginLeft: "5px" }}
                                className={
                                    this.props.filterIndex === module.id
                                        ? selectedStyles
                                        : styles.filterOption
                                }
                                onClick={() => {
                                    this.props.onChangeFilterIndex(module.id);
                                }}
                            >
                                {module.title}
                            </span>
                        );
                    })}
                </div>
                {this.state.showRightArrow && (
                    <div
                        className={styles.filterButton}
                        onClick={() => {
                            this.showArrow();
                        }}
                    >
                        <ArrowIcon width={22} height={22} />
                    </div>
                )}
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

export default ElementsMenuFilter;