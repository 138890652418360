import {
    CanvasSurvey,
    SurveyQuestionType,
    SurveyQuestion,
} from "common/Canvas";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import React from "react";
import styles from "./Questions.module.css";
import { modifySchemaApi } from "common/DataApi";
import { EditVariable, NewVariable } from "common/VariableCreator";
import { Panel, Type } from "common/InputData";
import RenderQuestion from "./Question";
import remoteModuleId from "common/remoteModuleId";
import _ from "lodash";
import Portal from "common/Portal";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import Variables from "common/Variables";

const QUESTIONS_LIMIT = 25;

interface QuestionsProps {
    canvasId: number;
    node: CanvasSurvey;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
    onChange: (
        changes: Partial<CanvasSurvey>,
        commit?: boolean,
        skipHistory?: boolean
    ) => void;
}

const Questions: React.FC<QuestionsProps> = ({
    node,
    onChange,
    currentModuleId,
    canvasTreeStore,
}) => {
    const [disabled, setDisabled] = React.useState(false);
    const [streamStatus, setStreamStatus] = React.useState<PopupStatus | null>(
        null
    );
    const [streamMessage, setStreamMessage] = React.useState<string | null>(
        null
    );
    const debounceClick = React.useRef(
        _.debounce(() => {
            setDisabled(false);
        }, 500)
    ).current;

    const modifyDbSchema = (
        newVariable: NewVariable | null,
        dropVariable: number | null,
        editVariable: EditVariable | null,
        previousState?: SurveyQuestion,
        callback?: () => void
    ) => {
        if (node.backendOutput.tableOption?.data_table_idx) {
            if (editVariable) editVariable.index = editVariable.index + 1;
            modifySchemaApi(
                node.backendOutput.tableOption?.data_table_idx,
                editVariable ? [editVariable] : [],
                newVariable ? [newVariable] : [],
                dropVariable !== null ? [dropVariable + 1] : [],
                remoteModuleId ?? currentModuleId
            )
                .then(() => {
                    if (callback) callback();
                    Variables(
                        node.backendOutput.tableOption?.data_table_idx
                    ).update(currentModuleId ?? remoteModuleId);
                    canvasTreeStore.updateAllAsyncAction.bothParts(
                        false,
                        canvasTreeStore.canvasTreeState
                    );
                })
                .catch(() => {
                    if (editVariable != null && previousState != null) {
                        let questions = [...node.questions];
                        questions[editVariable.index - 1] = previousState;
                        onChange({
                            questions: questions,
                        });
                    }
                    if (newVariable != null) {
                        setStreamStatus(PopupStatus.Error);
                        setStreamMessage(
                            `Question ${
                                node.questions.length + 1
                            }... already exists`
                        );
                    }
                });
        }
    };

    const _addSurveyQuestion = () => {
        if (disabled) return;

        let noRuleQuestions = node.questions.filter(
            (x) => x.type !== SurveyQuestionType.Rule
        );

        if (noRuleQuestions.length < QUESTIONS_LIMIT) {
            let newQuestionId = node.questions[node.questions.length - 1].id;
            let newQuestionName: string = "";
            const questions = node.questions.map((q) => q.question);

            do {
                newQuestionId++;
                newQuestionName = `Question ${newQuestionId}...`;
            } while (questions.includes(newQuestionName) === true);

            let newVariable: NewVariable = {
                name: newQuestionName,
                unit: null,
                type: Type.Str,
                level: null,
                format: null,
                panel: Panel.Regular,
            };

            if (node.backendOutput.tableOption?.data_table_idx) {
                modifyDbSchema(newVariable, null, null, undefined, () => {
                    onChange(
                        {
                            questions: [
                                ...node.questions,
                                {
                                    question: newQuestionName,
                                    type: SurveyQuestionType.Text,
                                    id: newQuestionId,
                                    options: [],
                                },
                            ],
                        },
                        true,
                        false
                    );
                });
            }
        }
        setDisabled(true);
        debounceClick();
    };

    const renderQuestions = (): JSX.Element[] => {
        let questions: JSX.Element[] = [];
        for (let i = 0; i < node.questions.length; i++) {
            if (node.questions[i].type !== SurveyQuestionType.Rule) {
                questions.push(
                    <RenderQuestion
                        key={node.questions[i].id}
                        modifyDbSchema={modifyDbSchema}
                        questionIndex={i}
                        onChange={onChange}
                        node={node}
                    />
                );
            }
        }

        return questions;
    };
    return (
        <div className={styles.questionsContainer}>
            {streamStatus && (
                <Portal rootNode={document.body}>
                    <StatusPopup
                        status={streamStatus!}
                        message={streamMessage!}
                        onClose={() => {
                            setStreamStatus(null);
                            setStreamMessage(null);
                        }}
                    />
                </Portal>
            )}
            <p className={styles.questionsHeader}>Questions</p>
            <p
                className={styles.add}
                onClick={() => {
                    if (!disabled) _addSurveyQuestion();
                }}
            >
                + Add
            </p>
            {renderQuestions()}
        </div>
    );
};

export default Questions;
