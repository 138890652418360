import React, { FunctionComponent } from "react";
import { Dropdown } from "react-bootstrap";
import { mainStyle } from "common/MainStyle";

import styles from "./DropdownMenu.module.css";

export function DropdownItem(props: {
  dataTestId?: string;
  title: string;
  hoverColor: string;
  highlight?: boolean;
  highlightColor?: string;
  onClick: (evt: any) => void;
  icon?: React.ReactElement;
}) {
  return (
    <div
      data-test-id={props.dataTestId}
      style={{
        width: "100%",
        cursor: "pointer",
      }}
      onClick={(evt: any) => {
        props.onClick(evt);
      }}
    >
      <div className={styles.innerContainer}>
        {props.icon && <span className={styles.iconWrapper}>{props.icon}</span>}
        <span
          className="unselectable"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {props.title}
        </span>
      </div>
    </div>
  );
}

interface MenuProps {
  noBorder?: boolean;
  style?: React.CSSProperties;
  popperConfig?: object;
  className?: string;
}

export const DropdownMenu: FunctionComponent<MenuProps> = (props) => {
  return (
    <Dropdown.Menu
      style={{
        backgroundColor: mainStyle.getPropertyValue(
          "--canvas-dropdown-menu-background-color"
        ),
        borderRadius: 6,
        border: "1px solid #eaeaea",
        boxShadow: mainStyle
          .getPropertyValue("--canvas-dropdown-box-shadow")
          .trim(),
        minWidth: "50px",
        ...props.style,
      }}
      popperConfig={props.popperConfig}
      className={props.className}
    >
      {props.children}
    </Dropdown.Menu>
  );
};
