import axios from "./ServerConnection";
import {
    Insight,
    InsightResponse,
    toInsight,
} from "common/insights_components/Insight";
import {
    KPIStream,
    KPIStreamResponse,
    toKPIStream,
} from "common/insights_components/KPIStream";
import {
    Story,
    StoryResponse,
    toStory,
} from "common/insights_components/Story";

import { JourneyConfig } from "./JourneyConfig";
import { Recipe, RecipeResponse, RecipeTab, toRecipe } from "./Recipe";
import { Page } from "./Page";
import { PageTab } from "./PageTab";
import PageType from "./PageType";
import { GroupPermission } from "common/GroupPermissions";

export async function addInsightApi(
    contents: Insight["contents"],
    image: Blob | null,
    tabId: number,
    userGroups?: (string | null)[]
): Promise<number> {
    let insightJson: string = JSON.stringify(contents);
    let requestJson: {
        tab_id: number;
        card: string;
        user_groups?: string[];
    } = {
        tab_id: tabId,
        card: insightJson,
    };
    if (userGroups != null) {
        requestJson.user_groups = userGroups.filter(
            (value: string | null): value is string => value != null
        );
    }

    let formData = new FormData();
    formData.append("metadata", JSON.stringify(requestJson));
    if (image != null && contents.finding.type === "image") {
        formData.append("image", image, "image");
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/insights_card_user_add", formData)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

async function getUserContentImage(url: string): Promise<Blob | null> {
    let response = await fetch(url);
    if (response.status === 401 || response.status === 403) return null;
    else if (response.status !== 200) return Promise.reject(response);
    else return response.blob();
}

export async function getInsightImageApi(
    insightId: number | string
): Promise<Blob | null> {
    let imageSrc;
    if (typeof insightId === "string")
        imageSrc = `/insights_shared/${encodeURIComponent(insightId)}/image`;
    else imageSrc = `/insights/${insightId}/image`;
    try {
        return await getUserContentImage(imageSrc);
    } catch (exception) {
        return null;
    }
}

export async function getAllStoryImagesApi(
    story: Story
): Promise<{ [slideIndex: number]: Blob }> {
    let images: { [slideIndex: number]: Blob } = {};
    let imageSrc: string;
    let image: Blob | null = null;
    for (let i = 0; i < story.insights.length; ++i) {
        if (isNaN(story.id))
            imageSrc = `/stories_shared/${encodeURIComponent(
                story.id
            )}/${i}/image`;
        else imageSrc = `/stories/${story.id}/${i}/image`;
        image = await getUserContentImage(imageSrc);
        if (image != null) {
            images[i] = image;
        }
    }

    return images;
}

export async function getStoryImageApi(
    storyId: number | string,
    slideIndex: number
): Promise<Blob | null> {
    let imageSrc: string;
    if (isNaN(Number(storyId)))
        imageSrc = `/stories_shared/${encodeURIComponent(
            storyId
        )}/${slideIndex}/image`;
    else imageSrc = `/stories/${storyId}/${slideIndex}/image`;

    return await getUserContentImage(imageSrc);
}

export async function addKpiStreamApi(
    contents: KPIStream["contents"],
    pageId: number,
    userGroups?: (string | null)[]
): Promise<number> {
    let kpiJson: string = JSON.stringify(contents);
    let requestJson: {
        page_id: number;
        card: string;
        user_groups?: string[];
    } = {
        page_id: pageId,
        card: kpiJson,
    };
    if (userGroups != null) {
        requestJson.user_groups = userGroups.filter(
            (value: string | null): value is string => value != null
        );
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/kpi_card_user_add", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function moveInsightApi(
    insightId: number,
    swapId: number,
    tabId: number
): Promise<void> {
    let requestJson = {
        id: insightId,
        swap_id: swapId,
        tab_id: tabId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
        }>("/api/insights_card_user_move", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function moveKpiStreamApi(
    kpiStreamId: number,
    swapId: number
): Promise<void> {
    let requestJson = {
        id: kpiStreamId,
        swap_id: swapId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
        }>("/api/kpi_card_user_move", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function moveStoryApi(
    storyId: number,
    swapId: number,
    tabId: number
): Promise<void> {
    let requestJson = {
        id: storyId,
        swap_id: swapId,
        tab_id: tabId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
        }>("/api/story_user_move", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editInsightApi(
    insight: Insight,
    image: Blob | "remove" | null,
    userGroups?: (string | null)[]
): Promise<void> {
    let isOwner: boolean = insight.user_data?.is_owner ?? false;
    let insightJson = JSON.stringify(insight.contents);
    let requestJson: {
        id: number;
        card: string;
        user_groups?: string[];
        remove_image?: boolean;
    } = {
        id: insight.id,
        card: insightJson,
    };
    if (userGroups != null) {
        requestJson.user_groups = userGroups.filter(
            (value: string | null): value is string => value != null
        );
    }
    let formData = new FormData();
    formData.append("metadata", JSON.stringify(requestJson));
    if (image === "remove") {
        requestJson.remove_image = true;
    } else if (image != null && insight.contents.finding.type === "image") {
        formData.append("image", image, "image");
    }
    let route: string = isOwner
        ? "insights_card_user_update_own"
        : "insights_card_user_update";
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
        }>(`/api/${route}`, formData)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editKpiStreamApi(
    kpiStream: KPIStream,
    userGroups?: (string | null)[]
): Promise<void> {
    let kpiJson: string = JSON.stringify(kpiStream.contents);
    let requestJson: {
        id: number;
        card: string;
        user_groups?: string[];
    } = {
        id: kpiStream.id,
        card: kpiJson,
    };
    if (userGroups != null) {
        requestJson.user_groups = userGroups.filter(
            (value: string | null): value is string => value != null
        );
    }
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
        }>("/api/kpi_card_user_update", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getInsightsApi(
    tabId: number,
    offset: number,
    limit: number
): Promise<Insight[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
            cards?: InsightResponse[];
        }>("/api/insights_cards_get_by_user", {
            tab_id: tabId,
            offset: offset,
            limit: limit,
        })
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map((card) => toInsight(card))
                );
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getKpiStreamsApi(pageId: number): Promise<KPIStream[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
            cards?: KPIStreamResponse[];
        }>("/api/kpi_cards_get_by_user", {
            page_id: pageId,
        })
        .then((response) => {
            if (response.data.success && response.data.cards != null) {
                return Promise.resolve(
                    response.data.cards.map((card: KPIStreamResponse) =>
                        toKPIStream(card)
                    )
                );
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getInsightApi(insightId: number): Promise<Insight> {
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
            card?: InsightResponse;
        }>("/api/insights_card_user_get", {
            id: insightId,
        })
        .then((response) => {
            if (response.data.success && response.data.card != null) {
                return Promise.resolve(toInsight(response.data.card));
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getKpiStreamApi(kpiId: number): Promise<KPIStream> {
    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
            card?: InsightResponse;
        }>("/api/kpi_card_user_get", {
            id: kpiId,
        })
        .then((response) => {
            if (response.data.success && response.data.card != null) {
                return Promise.resolve(toKPIStream(response.data.card));
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteInsightApi(insight: Insight): Promise<void> {
    let isOwner: boolean = insight.user_data?.is_owner ?? false;
    let route: string = isOwner
        ? "insights_card_user_remove_own"
        : "insights_card_user_remove";

    return axios
        .post<{
            success: boolean;
            error_msg: boolean;
        }>(`/api/${route}`, { id: insight.id })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteKpiStreamApi(kpiStream: KPIStream): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/kpi_card_user_remove", { id: kpiStream.id })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addStoryApi(
    story: Story,
    images: { [slideIndex: number]: Blob },
    tabId: number,
    userGroups: (string | null)[] = []
): Promise<number> {
    let storyJson = {
        tab_id: tabId,
        title: story.title,
        cards: story.insights.map((insight) =>
            JSON.stringify(insight.contents)
        ),
        user_groups: userGroups.filter(
            (value: string | null): value is string => value != null
        ),
    };
    let formData = new FormData();
    formData.append("metadata", JSON.stringify(storyJson));
    for (let [i, image] of Object.entries(images)) {
        i = String(i);
        formData.append(i, image, i);
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/story_user_add", formData)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getStoryApi(storyId: number): Promise<Story> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            stories?: StoryResponse[];
        }>("/api/stories_get_by_user", { id: storyId })
        .then((response) => {
            if (response.data.success && response.data.stories != null) {
                if (response.data.stories.length > 0)
                    return Promise.resolve(toStory(response.data.stories[0]));
                else return Promise.reject("Story not found");
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editStoryApi(
    story: Story,
    images: { [slideIndex: number]: Blob },
    userGroups?: (string | null)[]
): Promise<void> {
    let isOwner = story.user_data?.is_owner ?? false;
    let route = isOwner ? "story_user_update_own" : "story_user_update";

    let storyJson = {
        title: story.title,
        cards: story.insights.map((insight) =>
            JSON.stringify(insight.contents)
        ),
        id: story.id,
        user_groups:
            userGroups != null
                ? userGroups.filter(
                      (value: string | null): value is string => value != null
                  )
                : undefined,
    };
    let formData = new FormData();
    formData.append("metadata", JSON.stringify(storyJson));
    for (let [i, image] of Object.entries(images)) {
        i = String(i);
        formData.append(i, image, i);
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>(`/api/${route}`, formData)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getStoriesApi(
    tabId: number,
    offset: number,
    limit: number
): Promise<Story[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            stories?: StoryResponse[];
        }>("/api/stories_get_by_user", {
            tab_id: tabId,
            offset: offset,
            limit: limit,
        })
        .then((response) => {
            if (response.data.success && response.data.stories != null) {
                return Promise.resolve(
                    response.data.stories.map(
                        (story: StoryResponse): Story => toStory(story)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteStoryApi(story: Story): Promise<void> {
    let isOwner: boolean = story.user_data?.is_owner ?? false;
    let route: string = isOwner ? "story_user_remove_own" : "story_user_remove";
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>(`/api/${route}`, { id: story.id })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addRecipesTabApi(title: string): Promise<number> {
    let requestJson = {
        title: title,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/add_recipes_tab", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editRecipesTabApi(
    title: string,
    tabId: number
): Promise<void> {
    let requestJson = {
        title: title,
        id: tabId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/edit_recipes_tab", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteRecipesTabApi(tabId: number): Promise<void> {
    let requestJson = {
        id: tabId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/remove_recipes_tab", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getRecipesTabsApi(): Promise<RecipeTab[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            tabs?: RecipeTab[];
        }>("/api/get_recipes_tabs")
        .then((response) => {
            if (response.data.success && response.data.tabs != null) {
                return Promise.resolve(response.data.tabs);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addJourneyConfigApi(
    config: JourneyConfig
): Promise<void> {
    let requestJson = {
        name: config.journeyName,
        config: JSON.stringify(config),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/add_journey_config", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getJourneyConfigApi(
    journeyName: string
): Promise<JourneyConfig> {
    let requestJson = {
        name: journeyName,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            config?: string;
        }>("/api/get_journey_config", requestJson)
        .then((response) => {
            if (response.data.success && response.data.config != null) {
                let config: JourneyConfig = JSON.parse(response.data.config);
                return Promise.resolve(config);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addRecipesConfigApi(
    title: string,
    tabId: number,
    config: Recipe["config"]
): Promise<number> {
    let requestJson = {
        tab_id: tabId,
        title: title,
        name: config.journeyName,
        config: JSON.stringify(config),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/add_recipes_config", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editRecipesConfigApi(item: Recipe): Promise<void> {
    const requestJson = {
        config: JSON.stringify(item.config),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/update_recipes_config", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteRecipesConfigApi(item: Recipe): Promise<void> {
    let requestJson = {
        id: item.id,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/remove_recipes_config", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getRecipesConfigsApi(tabId: number): Promise<Recipe[]> {
    let requestJson = {
        tab_id: tabId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            recipes_configs?: RecipeResponse[];
        }>("/api/get_recipes_configs", requestJson)
        .then((response) => {
            if (
                response.data.success &&
                response.data.recipes_configs != null
            ) {
                return Promise.resolve(
                    response.data.recipes_configs.map((item) => toRecipe(item))
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addUserPageApi(
    title: string,
    type: number,
    permissions: GroupPermission[] = []
): Promise<number> {
    let requestJson = {
        title: title,
        type: type,
        permissions: permissions,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/add_user_page", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function editUserPageApi(
    title: string,
    pageId: number,
    permissions?: GroupPermission[]
): Promise<void> {
    let requestJson = {
        title: title,
        id: pageId,
        permissions: permissions,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/edit_user_page", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function deleteUserPageApi(pageId: number): Promise<void> {
    let requestJson = {
        id: pageId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/remove_user_page", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function getUserPagesApi(type: PageType): Promise<Page[]> {
    let requestJson = { type: type };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            pages?: Page[];
        }>("/api/get_user_pages", requestJson)
        .then((response) => {
            if (response.data.success && response.data.pages != null) {
                return Promise.resolve(response.data.pages);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function getCanvasPagesForLoadedModules(
    moduleId: number
): Promise<Page[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            pages?: Page[];
        }>("/api/get_canvas_pages_for_loaded_modules", {
            module_id: moduleId,
        })
        .then((response) => {
            if (response.data.success && response.data.pages != null) {
                return Promise.resolve(response.data.pages);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function moveUserPageApi(
    pageId: number,
    swapId: number
): Promise<void> {
    let requestJson = { id: pageId, swap_id: swapId };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/move_user_page", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addUserPageTabApi(
    title: string,
    pageId: number,
    type: PageType
): Promise<number> {
    let requestJson = {
        title: title,
        page_id: pageId,
        type: type,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/add_user_page_tab", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function editUserPageTabApi(item: PageTab): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/edit_user_page_tab", item)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function deleteUserPageTabApi(tabId: number): Promise<void> {
    let requestJson = {
        id: tabId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/remove_user_page_tab", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function getUserPageTabsApi(
    pageId: number,
    type: PageType
): Promise<PageTab[]> {
    let requestJson = { page_id: pageId, type: type };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            tabs: PageTab[];
        }>("/api/get_user_page_tabs", requestJson)
        .then((response) => {
            if (response.data.success && response.data.tabs != null) {
                return Promise.resolve(response.data.tabs);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function checkNewFeedbacks(): Promise<boolean> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            has_feedbacks?: boolean;
        }>("/api/check_new_feedbacks", {})
        .then((response) => {
            if (response.data.success && response.data.has_feedbacks != null) {
                return Promise.resolve(response.data.has_feedbacks);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function updateInsightAccessTimeApi(
    cardId: number
): Promise<void> {
    let requestJson = {
        id: cardId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/insights_card_user_update_access_time", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function updateStoryAccessTimeApi(cardId: number): Promise<void> {
    let requestJson = {
        id: cardId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/story_user_update_access_time", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function updateUserInfo(userInfo: {
    first_name?: string;
    last_name?: string;
    icon_url?: string;
    organization?: string;
    position?: string;
    phone_number?: string;
    interest_1?: string;
    interest_2?: string;
    interest_3?: string;
    familiar_with_data?: string;
}): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/update_user_info", userInfo)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}
