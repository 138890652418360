import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";
import { getLabelByTime } from "common/MonthData";

class Api {
	static getFinding(props) {
		let { data, config } = props;
		let item = {
			type: "3",
			content: data,
			config: config,
		};
		return item;
	}

	static responseToData(response) {
		let allData = [];
		let referenceLineIndex;
		for(let trend of response.data.trends) {
			let data = [];
			const rows = trend.time.length;
			referenceLineIndex = trend.trend1.indexOf(null) - 1;
			for (let i = 0; i < rows; ++i) {
				data.push({
					label: getLabelByTime(trend.time[i]),
					time: trend.time[i],
					value: trend.value[i],
					trend1: trend.trend1[i],
					trend2: trend.trend2[i]
				});
			}
			allData.push({
				plot: data,
				where: trend.where
			});
		}
		return {
			data: allData,
			referenceLineIndex: referenceLineIndex,
		};
	}

	static getData(config, findingOptions, moduleId) {
		let {
			timeParameterIndex,
			dataScope,
			aggregateVariable,
			timeSplit,
			count,
			targetVariable,
			targetVariableIndex,
			selectedTable,
			conditions,
			additionalParameter,
			additionalParameterIndex,
			leftAdditionalOperator,
			leftAdditionalValue,
			rightAdditionalValue,
			rightAdditionalOperator
		} = config;
		if (!count) count = 1;
		let dataTableIdx =
			typeof dataScope === "undefined" ? 0 : dataScope.value;
		conditions = conditions.filter(
			(condition) =>
				condition.variable && condition.value && condition.operation
		);
		conditions = conditionsToJson(conditions);
		let json_request = {
			count: count,
			time_variable_index: timeParameterIndex,
			variable_index: targetVariableIndex,
			table: selectedTable.value,
			data_table_idx: dataTableIdx,
			condition_id: selectedTable.condition_id,
			conditions: conditions,
			module_id: moduleId,
		};
		if (aggregateVariable != null) json_request.scale = aggregateVariable;
		if (timeSplit != null) {
			json_request.scale = undefined;
			json_request.time_split = timeSplit;
		}

		if (
			additionalParameter &&
			(leftAdditionalValue || rightAdditionalValue)
		) {
			json_request.where = {
				group_index: additionalParameterIndex,
				expressions: [],
			};
			if (leftAdditionalValue) {
				json_request.where.expressions.push({
					operation: leftAdditionalOperator?.value ?? "=",
					value: leftAdditionalValue.value
				});
			}
			if (rightAdditionalValue) {
				json_request.where.expressions.push({
					operation: rightAdditionalOperator?.value ?? "=",
					value: rightAdditionalValue.value
				});
			}
		}
		return axios
			.post("/api/e/get_time_trend", json_request)
			.then((response) => {
				if (response.data.success) {
					let data = {
						...Api.responseToData(response),
						scale: aggregateVariable,
						count: count,
						variable: targetVariable
					};
					if (timeSplit != null) {
						data.timeSplit = getLabelByTime(timeSplit)
					}
					return Promise.resolve(
						Api.getFinding({ config: config, data: data })
					);
				} else {
					return Promise.reject(response.data.error_msg);
				}
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	}
}

export { Api };
