export enum SnapDirection {
    X = 1,
    Y = 2,
}

export enum SnapPosition {
    Start = 0,
    Middle = 1,
    End = 2,
}

export interface SnapPoint {
    point: number;
    snapDirection: SnapDirection;
    snapPosition: SnapPosition;
}

/**
 * Calculates how to change the position of the element to make it snap to the points
 *
 * snapPoints has to be sorted by point
 */
export function snapElementToPoints(
    elementWidth: number,
    elementHeight: number,
    snapPoints: SnapPoint[]
): { x?: number; y?: number } {
    let newPosition: { x?: number; y?: number } = {};

    // snapPoints is sorted
    let firstX = snapPoints.find(
        (item) => item.snapDirection === SnapDirection.X
    );
    let firstY = snapPoints.find(
        (item) => item.snapDirection === SnapDirection.Y
    );

    let points: SnapPoint[] = [];
    if (firstX != null) {
        points.push(firstX);
    }
    if (firstY != null) {
        points.push(firstY);
    }

    for (let snapPoint of points) {
        let field: "x" | "y";
        let size: number;
        let value: number;
        // nearestPoints is already sorted
        if (snapPoint.snapDirection === SnapDirection.Y) {
            field = "x";
            size = elementWidth;
        } else {
            field = "y";
            size = elementHeight;
        }

        if (snapPoint.snapPosition === SnapPosition.Start) {
            value = snapPoint.point;
        } else if (snapPoint.snapPosition === SnapPosition.Middle) {
            value = snapPoint.point - size / 2;
        } else {
            value = snapPoint.point - size;
        }
        newPosition[field] = value;
    }

    return newPosition;
}
