import React from "react";
import { Button } from "react-bootstrap";
import { Element } from "react-scroll";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import customSelectStyles from "common/SelectStyles";
import variables from "common/Variables";
import tables from "common/Tables";
import dataScopes from "common/DataScopes";
import { Permission } from "common/Permissions";
import { sliceData } from "common/DataApi";

import { ConditionsSelector } from "common/Conditions";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
                sliceCurrentTableHeader: null,
                sliceCurrentTablePreview: null,
                dataScope: null,
                sliceDataTable: null,
                conditions: ConditionsSelector.defaultValue,
            };
            this.dataScopeValue = this.dataScopeValue.bind(this);
        }
        dataScopeValue() {
            if (this.state.dataScope) return this.state.dataScope.value;
            return undefined;
        }

        sliceCurrent(preview = false) {
            if (!this.state.sliceDataTable) {
                this.setStepState(StepState.error, "Table is not selected");
                return;
            }
            if (!preview) this.setStepState(StepState.running);
            sliceData(this.state.sliceDataTable, this.state.conditions, preview)
                .then((result) => {
                    console.log(result);
                    if (preview) {
                        this.setState({
                            sliceCurrentTableHeader: result.tableHeader,
                            sliceCurrentTablePreview: result.tablePreview,
                        });
                    } else {
                        this.setStepState(StepState.finished);
                        tables(this.dataScopeValue()).update();
                    }
                })
                .catch((error) => {
                    this.setStepState(StepState.error, String(error));
                });
        }

        renderSliceCurrent() {
            if (!this.state.expanded) return null;
            let tablePreview;
            if (this.state.sliceCurrentTablePreview !== null) {
                let tableContent = this.state.sliceCurrentTablePreview.map(
                    (row) =>
                        row.map(
                            (element) =>
                                element ||
                                (typeof element === "number" ? element : "-")
                        )
                );

                tablePreview = (
                    <Element
                        name="scrollable"
                        className="element"
                        style={{
                            overflowX: "scroll",
                            backgroundColor: "transparent",
                            position: "relative",
                            overflowY: "scroll",
                        }}
                    >
                        <AdminTableWithFullFeatures
                            className="processing-page-table"
                            paging={false}
                            tableName="Data"
                            tableHeader={this.state.sliceCurrentTableHeader}
                            tableContent={tableContent}
                        />
                    </Element>
                );
            } else {
                tablePreview = null;
            }
            return (
                <>
                    <div />
                    <div
                        style={{
                            gridColumnStart: 2,
                            gridColumnEnd: 4,
                            minWidth: 0,
                            background: "#3f4b62",
                            paddingLeft: "10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                }}
                            >
                                Select data
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={dataScopes.dataScopesOptions.filter(
                                    (option) =>
                                        option.permissionType ===
                                        Permission.ReadWrite
                                )}
                                onChange={(newValue) =>
                                    this.setState({
                                        sliceDataTable: tables(newValue.value)
                                            .rawAndAggregateTableOptions[0],
                                        dataScope: newValue,
                                    })
                                }
                                value={this.state.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                }}
                            >
                                Select table
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={tables(
                                    this.dataScopeValue()
                                ).rawAndAggregateTableOptions.filter(
                                    (item) => !item.condition_id
                                )}
                                onChange={(newValue) =>
                                    this.setState({
                                        sliceDataTable: newValue,
                                    })
                                }
                                value={this.state.sliceDataTable}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <ConditionsSelector
                            dataScopeId={this.dataScopeValue()}
                            title={"Conditions"}
                            style={{
                                marginLeft: 0,
                                marginRight: 100,
                                minHeight: 100,
                            }}
                            value={this.state.conditions}
                            onChange={(conditions) =>
                                this.setState({ conditions: conditions })
                            }
                            allVariables={
                                variables(this.dataScopeValue()).dataVariables
                            }
                        />
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                            onClick={() => this.sliceCurrent(true)}
                        >
                            <span className="content-regular-text">
                                {this.state.sliceCurrentTablePreview === null
                                    ? "PREVIEW"
                                    : "UPDATE PREVIEW"}
                            </span>
                        </Button>
                        {tablePreview}
                    </div>
                    <div />
                </>
            );
        }

        render() {
            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        className="btn-processing-tab"
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState((state) => ({
                                expanded: !state.expanded,
                            }));
                        }}
                    >
                        <span className="content-regular-text">
                            Slice table
                        </span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    {this.state.expanded ? (
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            onClick={() => {
                                this.sliceCurrent(false);
                            }}
                            style={{
                                fontFamily: "Roboto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: "25px",
                                width: "100%",
                                minWidth: "110px",
                            }}
                        >
                            UPDATE
                        </Button>
                    ) : (
                        <div style={{ minWidth: "110px" }} />
                    )}
                    <div>{this.state.expanded && this.renderStepState()}</div>
                    {this.renderSliceCurrent()}
                </>
            );
        }
    }
);

export { MainComponent };
