import React from "react";
import cx from "classnames";

import User from "state/models/User";

import styles from "./Avatar.module.css";
import { getUserTitle } from "common/UserIcon";
import ReactTooltip from "react-tooltip";

interface Props {
    image?: string;
    user?: User;
    className?: string;
    inGridView?: boolean;
}

function Avatar({ image, user, className, inGridView }: Props) {
    return (
        <div data-tip={user?.userName ?? ""} data-for={inGridView ? "grid-view-tooltip" : undefined}>
            <span className={cx(styles.root, className)}>
                {image ? (
                    <img alt="user" src={image} className={styles.image} />
                ) : (
                    <span className={styles.textAvatar}>
                        {user &&
                            getUserTitle({
                                user_name: user.userName,
                                first_name: user.firstName,
                                last_name: user.lastName,
                            })}
                    </span>
                )}
                {!inGridView && (
                    <ReactTooltip effect="solid" backgroundColor="#464646" />
                )}
            </span>
        </div>
    );
}

export default Avatar;
