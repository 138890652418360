import React from "react";
import { DensityPlotFinding } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import styles from "./DensityPlotSection.module.css";
import { Button } from "react-bootstrap";
import EditInput from "../EditInput";
import cx from "classnames";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

interface Props {
    columnDragActive: boolean;
    finding: DensityPlotFinding;
    onChange: (finding: DensityPlotFinding, updateData?: boolean) => void;
    onClearEditing: () => void;
    dashboardId: string;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
}

export default function ScatterPlotSection(props: Props) {
    let data = props.finding.content.data;
    let currentEditVariableIndex: number | undefined = undefined;
    if (props.finding.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex =
            data[0].variableIndex == null ? 0 : undefined;
    }
    let xAxisName = props.finding.content.data[0].name;
    let yAxisName = props.finding.content.densityAxisName;
    let datasetIsConnected = props.finding.config.dataScope != null;
    let maxRowCount = 3;
    let restCount = data[0].value[0].length - maxRowCount;
    return (
        <div>
            <div className="my-row">
                <div className={styles.dataContainer}>
                    <div className={commonStyles.header}></div>
                    <div
                        className={cx(commonStyles.header, {
                            [commonStyles.highlightValueVerticalTop]:
                                currentEditVariableIndex === 0,
                        })}
                    >
                        {xAxisName}
                    </div>
                    <div
                        className={cx(commonStyles.header, {
                            [commonStyles.highlightValueVerticalTop]:
                                currentEditVariableIndex === 1,
                        })}
                    >
                        {yAxisName}
                    </div>
                    {(datasetIsConnected
                        ? data[0].value[0].slice(0, maxRowCount)
                        : data[0].value[0]
                    ).map((_, index, array) => (
                        <React.Fragment key={index}>
                            <EditInput
                                showDeleteButton={
                                    props.finding.config.dataScope == null
                                }
                                disabled={true}
                                value={`Value ${index + 1}`}
                                onDelete={() => {
                                    let finding: DensityPlotFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: [
                                                {
                                                    ...props.finding!.content
                                                        .data[0],
                                                    value: [
                                                        [
                                                            ...props.finding!
                                                                .content.data[0]
                                                                .value[0],
                                                        ],
                                                        [
                                                            ...props.finding!
                                                                .content.data[0]
                                                                .value[1],
                                                        ],
                                                    ],
                                                },
                                            ],
                                        },
                                    };
                                    finding.content.data.forEach((item) => {
                                        item.value[0].splice(index, 1);
                                        item.value[1].splice(index, 1);
                                    });
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVertical]:
                                        currentEditVariableIndex === 0,
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 0 &&
                                        index === array.length - 1 &&
                                        !(datasetIsConnected && restCount > 0),
                                })}
                                value={data[0].value[0][index]}
                                onChange={(value) => {
                                    let finding: DensityPlotFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: [
                                                {
                                                    ...props.finding!.content
                                                        .data[0],
                                                    value: [
                                                        [
                                                            ...props.finding!
                                                                .content.data[0]
                                                                .value[0],
                                                        ],
                                                        [
                                                            ...props.finding!
                                                                .content.data[0]
                                                                .value[1],
                                                        ],
                                                    ],
                                                },
                                            ],
                                        },
                                    };
                                    finding.content.data[0].value[0][
                                        index
                                    ] = Number(value);
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVertical]:
                                        currentEditVariableIndex === 1,
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 1 &&
                                        index === array.length - 1 &&
                                        !(datasetIsConnected && restCount > 0),
                                })}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[0].value[1][index]}
                                onChange={(value) => {
                                    let finding: DensityPlotFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: [
                                                {
                                                    ...props.finding!.content
                                                        .data[0],
                                                    value: [
                                                        [
                                                            ...props.finding!
                                                                .content.data[0]
                                                                .value[0],
                                                        ],
                                                        [
                                                            ...props.finding!
                                                                .content.data[0]
                                                                .value[1],
                                                        ],
                                                    ],
                                                },
                                            ],
                                        },
                                    };
                                    finding.content.data[0].value[1][
                                        index
                                    ] = Number(value);
                                    props.onChange(finding);
                                }}
                            />
                        </React.Fragment>
                    ))}
                    {datasetIsConnected && restCount > 0 && (
                        <React.Fragment>
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={`+${restCount} values`}
                            />
                            <EditInput
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 0,
                                })}
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                            <EditInput
                                optionalClassName={cx({
                                    [commonStyles.highlightValueVerticalBottom]:
                                        currentEditVariableIndex === 1,
                                })}
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
            {props.finding.config.dataScope == null && (
                <Button
                    style={{ marginTop: 10 }}
                    className={cx(
                        "btn btn-sm btn-primary my-primary",
                        commonStyles.addItemButton
                    )}
                    onClick={() => {
                        let finding: DensityPlotFinding = {
                            ...props.finding!,
                            content: {
                                ...props.finding!.content,
                                data: [
                                    {
                                        ...props.finding!.content.data[0],
                                        value: [
                                            [
                                                ...props.finding!.content
                                                    .data[0].value[0],
                                            ],
                                            [
                                                ...props.finding!.content
                                                    .data[0].value[1],
                                            ],
                                        ],
                                    },
                                ],
                            },
                        };
                        let lastValue =
                            finding.content.data[0].value[0][
                                finding.content.data[0].value[0].length - 1
                            ] ?? 0;
                        let step =
                            finding.content.data[0].value[0].length <= 1
                                ? 10
                                : lastValue -
                                  finding.content.data[0].value[0][
                                      finding.content.data[0].value[0].length -
                                          2
                                  ];
                        finding.content.data.forEach((item) => {
                            item.value[0].push(lastValue + step);
                            item.value[1].push(0);
                        });
                        props.onChange(finding);
                    }}
                >
                    {"\uFF0B Add Item"}
                </Button>
            )}
        </div>
    );
}
