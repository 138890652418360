import { defaultErrorColors } from "common/Canvas";
import { ArrowIndicatorColumn } from "common/Finding";
import MobileDetect from "mobile-detect";

const md = new MobileDetect(window.navigator.userAgent);

export default function mobileBreakpoint(): boolean {
    let tablet = md.tablet();
    if (tablet != null && tablet.length > 0) return false;
    let mobile = md.mobile();
    return mobile != null && mobile.length > 0;
}

export function mobileAndTabletBreakpoint(): boolean {
    if (isIpad()) return true;
    let device = md.mobile() || md.tablet();
    return device != null && device.length > 0;
}

export function tabletBreakpoint(): boolean {
    if (isIpad()) return true;
    let device = md.tablet();
    return device != null && device.length > 0;
}

export function isPWA() {
    return (window.matchMedia('(display-mode: standalone)').matches) || document.referrer.includes('android-app://');
}

export function isSafari() {
    return md.match("Safari");
}

export function isIpad() {
    let isIpad =
        md.match("Macintosh") &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 1;
    return isIpad;
}

export function dpr(): number {
    return window.devicePixelRatio ?? 1;
}

export function getWidth() {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    );
}

export function getHeight() {
    return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
    );
}

export function calcScale(supportsMobileView: boolean = false): void {
    if (supportsMobileView && mobileBreakpoint()) {
        document
            .querySelector("meta[name=viewport]")
            ?.setAttribute("content", "width=device-width, initial-scale=1");
    } else {
        let w = window.screen.availWidth;
        let scale = w / 1800;
        document
            .querySelector("meta[name=viewport]")
            ?.setAttribute(
                "content",
                `width=device-width, initial-scale=${scale}, minimum-scale=0.9, maximum-scale=2, user-scalable=yes`
            );
    }
}

export function isLandscape(): boolean {
    return window.matchMedia("(orientation: landscape)").matches;
}

export function toggleFullScreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }
}

export function isFullScreen() {
    return document.fullscreenElement;
}

let lastPress = 0;
export const onDoubleTouch = (callback: () => void) => {
    const time = new Date().getTime();
    const delta = time - lastPress;

    const DOUBLE_PRESS_DELAY = 400;
    if (delta < DOUBLE_PRESS_DELAY) {
        callback();
    }
    lastPress = time;
};

export const openDelayedNodeMenuAfterTouch = (callback: () => void, timeOutCallback: () => void) => {
    callback();
    setTimeout(() => {
        timeOutCallback();
    }, 3000);
}

let timer: any = null;
export const startLongPressTimer = (callback: Function) => {
    clearLongPressTimer();
    timer = setTimeout(() => {
        callback()
    }, 700);
}

export const clearLongPressTimer = () => {
    if (timer) {
        clearTimeout(timer);
        timer = null;
    }
}

export const getValueSignStyle = (sign: string, errorColors: ArrowIndicatorColumn["errorColors"]): Record<string, string | number> => {
    if (sign === "neutral") {
        return {
            width: 10,
            height: 2,
            backgroundColor: errorColors[sign],
        };
    }
    if (sign === "positive") {
        return {
            width: 0,
            height: 0,
            borderLeft: `${6}px solid transparent`,
            borderRight: `${6}px solid transparent`,
            borderBottom: `${12}px solid ${
                errorColors[sign]
            }`,
        };
    }
    if (sign === "negative") {
        return {
            width: 0,
            height: 0,
            borderLeft: `${6}px solid transparent`,
            borderRight: `${6}px solid transparent`,
            borderTop: `${12}px solid ${
                defaultErrorColors[sign]
            }`,
        };
    }
    return {};
}
