import { Component } from "react";
import ReactDOM from "react-dom";
import axios from "common/ServerConnection";

interface Props {
    doReload: boolean;
    src: string;
    inFinding?: boolean;
    inStory?: boolean;
    inEdit?: boolean;
    childSize?: { width: number; height: number };
    onSizeObtained?: (size: { width: number; height: number }) => void;
}

interface State {
    src: string;
}

export default class ImageIFrame extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            src: "",
        };
    }

    async componentDidMount() {
        await this._updateImgFile();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.doReload !== prevProps.doReload)
            this._updateImgFile();
    }

    _updateImgFile = async () => {
        try {
            let response: {
                data: string;
                headers: { [key: string]: string };
            } = await axios.get(this.props.src, {
                responseType: "arraybuffer",
            });

            let base64String = Buffer.from(response.data, "binary").toString(
                "base64"
            );

            if (!base64String) return;
            base64String = `data:${response.headers["content-type"]};base64,${base64String}`;
            this.setState({ src: base64String });
        } catch (error) {
            console.log(String(error));
        }
    }

    render() {
        let inFinding = this.props.inFinding ?? false;
        let inStory = this.props.inStory ?? false;

        let containerHeight = "calc(100% - 26px)";
        let childSize = null;
        if (this.props.childSize != null) {
            childSize = {
                width: this.props.childSize.width * (inStory ? 1.3 : 1),
                height: this.props.childSize.height * (inStory ? 1.3 : 1),
            };
        }
        let containerWidth =
            childSize == null ? "100%" : `min(${childSize.width}px, 100%)`;
        let sizeString = inFinding
            ? childSize == null
                ? "object-fit: contain;"
                : `width: ${childSize.width}px;height: ${childSize.height}px`
            : "width: 100%; height: 100vh; object-fit: contain;";
        // IMPORTANT: height needs to be 100vh in the iframe, not 100%
        return (
            <iframe
                onLoad={(evt) => {
                    const obj: any = ReactDOM.findDOMNode(this);
                    let imageHeight =
                        obj.contentWindow.document.body.firstChild.scrollHeight;
                    let imageWidth =
                        obj.contentWindow.document.body.firstChild.scrollWidth;
                    if (this.props.onSizeObtained != null)
                        this.props.onSizeObtained({
                            height: imageHeight,
                            width: imageWidth,
                        });
                }}
                frameBorder="0"
                marginWidth={0}
                marginHeight={0}
                seamless={true}
                scrolling="no"
                srcDoc={
                    this.state.src
                        ? `<img alt="" style="${sizeString}" src="${this.state.src}" />`
                        : `<div style="${sizeString};background-color: white;"/>`
                }
                name="insightimage"
                id="insightimage"
                title="insightimage"
                style={{
                    width: containerWidth,
                    height: containerHeight,
                }}
            >
                <span className="regular-text">
                    Please update your browser to view this content
                </span>
            </iframe>
        );
    }
}
