import React from "react";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import styles from "./DropdownItem.module.css";
import submenuStyles from "./DropdownSubmenu.module.css";
import menuStyles from "../DropdownMenu/DropdownMenu.module.css";
import cx from "classnames";

interface Props {
	children: React.ReactNode;
	className?: string;
	submenu?: React.ReactNode;
	leftIcon?: React.ReactNode;
	rightIcon?: React.ReactNode;
	shortcut?: string;
	premium?: boolean;
	onClick?: (_evt: any) => void;
	onMouseEnter?: (_evt: any) => void;
	onMouseLeave?: (_evt: any) => void;
}

function DropdownItem({
	children,
	className,
	submenu,
	leftIcon,
	rightIcon,
	shortcut,
	premium,
	onClick,
	onMouseEnter,
	onMouseLeave,
}: Props) {
	let [showSubmenu, setShowsubmenu] = React.useState(false);
	return (
		<div
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<BootstrapDropdown.Item
				//	as="button"
				className={cx(styles.root, className)}
				onClick={(evt) => {
					if(!premium) {
						showSubmenu ? setShowsubmenu(false) : onClick?.(evt);
					}
				}}
			>
				<div
					className={styles.left}
				>
					{leftIcon}
					{children}
					<div style={{ flexGrow: 1 }} />
					{premium && <div className={styles.premium}>PREMIUM</div>}
					{shortcut}
					{rightIcon && (
						<>
							<div
								onMouseEnter={() => {
									if (submenu != null) setShowsubmenu(true);
								}}
								className={styles.right}
							>
								{showSubmenu
									? React.cloneElement(
											rightIcon as React.ReactElement,
											{
												stroke: "#3C83CA",
											}
									)
									: rightIcon}
							</div>
						</>
					)}
				</div>
				{showSubmenu && (
					<div
						className={cx(
							submenuStyles["dropdown-submenu"],
							menuStyles.root
						)}
					>
						{submenu}
					</div>
				)}
			</BootstrapDropdown.Item>
		</div>
	);
}

export default DropdownItem;