import React, { Component } from "react";
import FindingHandler from "common/FindingHandler";
import Dock from "react-dock";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import Alert from "common/Alert";
import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";

export enum PortalType {
    Exploration = 2,
    ViewData = 3, // "VIEW EXISTING DATA" in spreadsheet
    ImportData = 4, // "IMPORT DATA FROM" in spreadsheet
    CloudStorage = 5, // "CLOUD STORAGE" in text box
    Marketplace = 6,
}

interface Props {
    options?: any;
    portalType: PortalType;
    onReturnToCanvas: (options?: any) => void;
}

interface State {
    dockSize: number;
}

export default class SectionPortal extends Component<Props, State> {
    rootComponent: Component | undefined = undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            dockSize: window.innerHeight * 0.9, // Size of the window
        };
        this.renderContents = this.renderContents.bind(this);
    }

    handleDockSizeChange = (size: number) => {
        this.setState({ dockSize: size });
    }
 
    importMainComponent(globalContext: GlobalContextContents) {
        if (this.rootComponent != null) return this.rootComponent;
        let permissions = globalContext.permissions;
        if (this.props.portalType === PortalType.ImportData) {
            let mod = require(`modules/input_page/main`);
            // The user can access the input page in the portal even if
            // it's not displayed in the top-left menu.
            if ("InputData" in permissions)
                this.rootComponent = mod.MainComponent;
        } else if (this.props.portalType === PortalType.Exploration) {
            let mod = require(`modules/data_exploration_page/main`);
            if ("ExplorationPaths" in permissions)
                this.rootComponent = mod.MainComponent;
        } else if (this.props.portalType === PortalType.ViewData) {
            let mod = require(`./ViewDataPage`);
            if (
                mod.requirePermission == null ||
                mod.requirePermission in permissions
            )
                this.rootComponent = mod.MainComponent;
        } else if (this.props.portalType === PortalType.CloudStorage) {
            let mod = require(`./CloudStoragePage`);
            if (
                mod.requirePermission == null ||
                mod.requirePermission in permissions
            )
                this.rootComponent = mod.MainComponent;
        } else if (this.props.portalType === PortalType.Marketplace) {
            let mod = require(`modules/marketplace_page/main`);
            if ("Marketplace" in permissions)
                this.rootComponent = mod.MainComponent;
        }
        return this.rootComponent;
    }

    renderContents(globalContext: GlobalContextContents) {
        let MainComponent = this.importMainComponent(globalContext) as any;
        return (
            <Dock
                dockStyle={{
                    backgroundColor: "#FFFFFF",
                    overflow:
                        this.props.portalType !== PortalType.ViewData
                            ? "auto"
                            : undefined,
                }}
                position="bottom"
                isVisible={true}
                fluid={false}
                dimMode="none"
                size={this.state.dockSize}
                onSizeChange={this.handleDockSizeChange.bind(this)}
            >
                {this.props.portalType === PortalType.Exploration && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: 10,
                        }}
                    >
                        <div
                            title={"Close"}
                            onClick={this.props.onReturnToCanvas}
                        >
                            <img
                                alt=""
                                src="/dist/img/insights/insights_remove.png"
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>
                )}
                {MainComponent != null ? (
                    <div
                        className="element"
                        style={{
                            overflow:
                                this.props.portalType !== PortalType.ViewData &&
                                this.props.portalType !== PortalType.Marketplace &&
                                this.props.portalType !== PortalType.ImportData &&
                                this.props.portalType !== PortalType.CloudStorage
                                    ? "auto"
                                    : undefined,
                            width: "100%",
                            height:
                                this.props.portalType !== PortalType.ViewData &&
                                this.props.portalType !== PortalType.Marketplace &&
                                this.props.portalType !== PortalType.ImportData &&
                                this.props.portalType !== PortalType.CloudStorage
                                    ? this.state.dockSize - 44
                                    : this.state.dockSize,
                        }}
                    >
                        <FindingHandler>
                            <>
                                <MainComponent
                                    onReturnToCanvas={this.props.onReturnToCanvas}
                                    positionStyle={{
                                        marginRight: 30,
                                    }}
                                    handleDockSizeChange={this.handleDockSizeChange}
                                    dockSize={this.state.dockSize}
                                    {...this.props.options}
                                />
                                {this.props.portalType === PortalType.Marketplace && (
                                    <button
                                        style={{
                                            position: "absolute",
                                            top: 20,
                                            right: 20,
                                            backgroundColor: "transparent",
                                            border: "none",
                                        }}
                                        onClick={this.props.onReturnToCanvas}
                                    >
                                        <CloseIcon />
                                    </button>
                                )}
                            </>
                        </FindingHandler>
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Alert
                            text="Permission Denied"
                            className="alert alert-danger alert-dismissible"
                            onClosed={this.props.onReturnToCanvas}
                        />
                    </div>
                )}
            </Dock>
        );
    }
    render() {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}
