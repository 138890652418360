import {
    CanvasSubmitButton,
    castedTargetValue,
    isDropdownSelector,
    isTextBox,
    isSimpleSpreadSheetInput,
    isBox,
    CanvasSpreadSheetGrid,
    CanvasNode,
    DefaultValueType,
    CanvasTextBox,
    SubmitType,
    isInput,
    isDateFormat,
} from "common/Canvas";
import CanvasTreeStore from "./CanvasTreeStore";
import { insertRows, updateRows } from "common/DataApi";
import { Type } from "common/InputData";
import { getGlobalInputValue } from "common/GlobalInputs";
import Variables from "common/Variables";
import { ContentState, convertToRaw } from "draft-js";
import SharedBoxesStore from "./SharedBoxesStore";
import { strftime } from "common/utilities/TimeFormatUtils";
import DateTimeFormatSelect from "common/DateTimeFormatSelect";
import PopupData from "common/PopupData";

export default async function submitData(
    canvasTreeStore: CanvasTreeStore,
    submitButton: Pick<CanvasSubmitButton, "backendOutput">,
    moduleId?: number | string
) {
    let row: { [key: string]: string | number | null } = {};
    let update: { [key: string]: string | number | null } = {};

    let backendOutput = submitButton.backendOutput;
    if (backendOutput.dataScopeOption == null) {
        throw new Error("Dataset is not selected");
    }
    if (backendOutput.tableOption == null) {
        throw new Error("Table is not selected");
    }
    let mapping = backendOutput.variableOptions.filter(
        (option) => option.node != null && option.variable != null
    );
    if (mapping.length === 0) {
        throw new Error("Variable mapping is not specified");
    }

    for (let mappingItem of mapping) {
        let allowNans = mappingItem.allowNans ?? true;
        let nodeId = mappingItem.node!.value;
        let outerId =
            mappingItem.node!.type === SubmitType.Linked
                ? mappingItem.node!.outerId
                : mappingItem.node!.label;
        let variable = mappingItem.variable!.value;
        let node = canvasTreeStore.canvasTreeState.get(nodeId);
        if (node == null && mappingItem.node!.type === SubmitType.Regular) {
            throw new Error(`Element ${outerId} does not exist`);
        }
        let name = "";
        let type = "";
        let variables = Variables(
            backendOutput.dataScopeOption.value,
            moduleId
        );
        if (variables.initialized) {
            let variableInfo = variables.getVariableByIndex(variable);
            if (variableInfo == null) throw new Error("Variable not found");
            name = variableInfo.name;
            type = variableInfo.type;
        } else {
            try {
                await variables.update(moduleId);
            } catch (error) {
                throw new Error(error);
            }
            let variableInfo = variables.getVariableByIndex(variable);
            if (variableInfo == null) throw new Error("Variable not found");
            name = variableInfo.name;
            type = variableInfo.type;
        }

        if (mappingItem.node!.type === SubmitType.Global) {
            let value = getGlobalInputValue(nodeId, type === Type.Datetime);
            row[name] = value ?? null;
        } else if (mappingItem.node!.type === SubmitType.Regular) {
            let format = undefined;
            if (isDropdownSelector(node!) || isBox(node!) || isTextBox(node!)) {
                format = node!.format;
            }
            if (isSimpleSpreadSheetInput(node!)) {
                let grid = canvasTreeStore.gridsState.get(
                    node.gridId
                ) as CanvasSpreadSheetGrid;
                format =
                    grid?.headers?.[
                        node.nodePosition[canvasTreeStore.canvasViewMode].x
                    ]?.columnFormat ?? node.format;
            }
            let value = castedTargetValue(node!, type as Type, format) as
                | string
                | number;
            row[name] = value ?? null;
        } else {
            const sharedBox = SharedBoxesStore.getBox(nodeId);
            if (sharedBox != null) {
                let format = undefined;
                if (
                    isDropdownSelector(sharedBox.box) ||
                    isBox(sharedBox.box) ||
                    isTextBox(sharedBox.box)
                ) {
                    format = sharedBox.box.format;
                }
                let value = castedTargetValue(
                    sharedBox.box!,
                    type as Type,
                    format
                ) as string | number;
                row[name] = value ?? null;
            } else {
                row[name] = null;
            }
        }
        if (
            !allowNans &&
            (row[name] == null || row[name] === "" || Number.isNaN(row[name]))
        ) {
            throw new Error(`${name} cannot be null, NaN or empty`);
        }
        if (mappingItem.update && PopupData.data?.row_id) {
            update[name] = row[name];
            delete row[name];
        } 
    }
    if(Object.keys(update).length !== 0)
        await updateRows(backendOutput, update, moduleId );

    if(Object.keys(row).length !== 0)
        await insertRows(backendOutput.tableOption, [row], moduleId, true);
    
    let changes: { [nodeId: number]: Partial<CanvasNode> } = {};
    for (let mappingItem of mapping) {
        let nodeId = mappingItem.node!.value;
        let node = canvasTreeStore.canvasTreeState.get(nodeId);
        if (
            node != null &&
            node.defaultValueType != null &&
            node.defaultValueType !== DefaultValueType.LastTextAdded
        ) {
            if (!isTextBox(node)) {
                let metric: string | undefined;
                let value: number;
                switch (node.defaultValueType) {
                    case DefaultValueType.ClearEverySession:
                        metric = "";
                        value = NaN;
                        break;
                    case DefaultValueType.CurrentDateTime:
                        let date = new Date();
                        metric = strftime(
                            isInput(node) && isDateFormat(node.format)
                                ? node.format.format
                                : DateTimeFormatSelect.defaultOptions()[0]
                                    .value,
                            date
                        );
                        value = date.getTime() / 1000;
                        break;
                    default:
                        metric = node.defaultText;
                        value = NaN;
                        break;
                }
                changes[nodeId] = {
                    value: value,
                    metric: metric ?? "",
                };
            } else {
                let contentState = ContentState.createFromText(
                    (node.defaultValueType ===
                        DefaultValueType.ClearEverySession
                        ? ""
                        : node.defaultText) ?? ""
                );
                changes[nodeId] = {
                    value: NaN,
                    metric: "",
                    additionalOutputs: [],
                    rawMetric: convertToRaw(contentState),
                } as Partial<CanvasTextBox>;
            }
        }
    }
    if (Object.keys(changes).length > 0) {
        canvasTreeStore.updateNodesAction(changes);
    }
}