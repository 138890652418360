import React from "react";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import { mainStyle } from "common/MainStyle";

interface ErrorOption {
	label: string;
	value: string | null;
}
const Options: ErrorOption[] = [
	{
		label: "Standard Error",
		value: "se",
	},
	{
		label: "Standard Deviation",
		value: "sd",
	},
	{
		label: "95% Confidence Interval",
		value: "conf_int",
	},
	{
		label: "OFF",
		value: null,
	},
];

interface Props {
	value: string | null;
	onChange: (value: string | null) => void;
}

export default function ErrorBarSelector(props: Props) {
	return (
		<div className="my-row" style={{ alignItems: "center" }}>
			<span
				style={{
					whiteSpace: "nowrap",
					fontFamily: "Roboto",
					fontSize: "10px",
					color: mainStyle.getPropertyValue("--exploration-tertiary-text-color"),
					fontWeight: 300,
				}}
			>
				{"Error Bars Format:"}
			</span>
			<Select
				filterOption={createFilter({
					ignoreAccents: false,
				})}
				styles={{
					...customSelectStyles,
					container: (base) => ({
						...base,
						width: "150px",
						marginLeft: "5px",
						height: "38px",
					}),
				}}
				options={Options}
				value={Options.find((option) => option.value === props.value)}
				onChange={(newValue) => {
					props.onChange((newValue as ErrorOption).value);
				}}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						text: "white",
						primary25: "var(--selectors-background-hover-color)",
					},
				})}
			/>
		</div>
	);
}