/*

React function component positioning and previewing new card on flow

Base cases:

           ┌──────┐
           │      │
           └──▲───┘
              │
┌──────┐   ┌──┴───┐    ┌──────┐
│      ◄───┤      ├───►│      │
└──────┘   └──┬───┘    └──────┘
              │
              ▼
           ┌──────┐
           │      │
           └──────┘

Downwards balancing:

                            ┌──────────┐
                            │     0    │
                            │          │
     ┌─────────────┬────────┴────┬─────┴────────┬────────────┐
     │             │             │              │            │
     ▼             │             ▼              ▼            │
┌─────────┐   ┌────▼────┐   ┌──────────┐  ┌─────────┐  ┌─────▼────┐
│    5    │   │    3    │   │    1     │  │    2    │  │     4    │
│         │   │         │   │          │  │         │  │          │
└─────────┘   └─────────┘   └──────────┘  └─────────┘  └──────────┘

*/
import { ArrowPosition, CanvasElement } from "common/Canvas";
import { mainStyle } from "common/MainStyle";
import React from "react";
import ArcherElement from "common/canvas/archer/ArcherElement";
import { Relation } from "react-archer";

interface FlowPreviewProps {
    arrowPosition: ArrowPosition,
    scale: number,
    node: CanvasElement,
    shift: number
}

const FlowChartPreview: React.FC<FlowPreviewProps> = ({arrowPosition, scale, node, shift}) => {


    let relations: Relation[] = [];

    const distanceX = 210;
    const distanceY = 203;

    
    const _positioningStyles = function(position: ArrowPosition): React.CSSProperties {
        switch(position) {
            case ArrowPosition.Top:
                return {
                    top: 0,
                    left: '50%',
                    transform: `translate(${(102 * shift) - 50}%, -${distanceX}%)`
                }
            case ArrowPosition.Right:
                return {
                    right: 0,
                    top: '50%',
                    transform: `translate(${distanceY}%, ${(105 * shift) - 50}%)`
                }

            case ArrowPosition.Bottom:
                return {
                    bottom: 0,
                    right: '50%',
                    transform: `translate(${(102 * shift) + 50}% , ${distanceX}%)`
                }

            case ArrowPosition.Left:
                return {
                    left: 0,
                    bottom: '50%',
                    transform: `translate(-${distanceY}%, ${(105 * shift) + 50}%)`
                }

            default:
                break;
        }
        
        return {}
    }

    return(
        <ArcherElement         
            id="archer-element-node-preview"
            relations={relations}
        >
            <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",

                backgroundColor: "#F8F8F8",
                color: mainStyle.getPropertyValue("--slide-flowchart-label-color"),
                position:"absolute",
                borderRadius:"15px",
                border:"2px solid #F0F0F0",
                textAlign:"center",
                ..._positioningStyles(arrowPosition),
            }}>
                <span
                    className="content-regular-text unselectable"
                    style={{
                        fontSize: 8 * scale,
                        fontFamily: "Roboto",
                        color: "#70889E",
                        textAlign: "center",
                    }}
                >
                    Enter Title
                </span>

                <span
                    className="content-regular-text unselectable"
                    style={{
                        fontSize: 8 * scale,
                        fontFamily: "Roboto",
                        color: "#70889E",
                        position:"absolute",
                        left:"4px",
                        top:"4px"
                    }}
                >
                    ABC
                </span>
            </div>
        </ArcherElement>
    )
}

export default FlowChartPreview