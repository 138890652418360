import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import {
    PieChartFinding,
    TreemapFinding,
    DendrogramFinding,
    RadarFinding,
} from "common/Finding";
import { SearchComponentOption } from "common/SearchComponent";
import StringOption from "common/StringOption";
import {
    additionalBarChartAndPieChartOperations,
    aggregationFunctions,
    aggregationToAdditionalMap,
} from "common/AggregationFunctionsv2";
import { updateOriginalNames } from "../../common/Utils";

const journeyNameToTypeMap: Readonly<{ [key: string]: string }> = {
    CompareRatiosJourney: "piechart",
    TreemapJourney: "treemap",
    DendrogramJourney: "dendrogram",
    RadarJourney: "radar",
};

class Api {
    static getDefaultConfig(journeyName: string) {
        let baseConfig = {
            journeyName: journeyName,
            version: configVersionV2,
            operationVariable: aggregationFunctions[0].value,
            showLabels: true,
            showLegend: true,
            showLabelInBoxes: false,
            showTotals: true,
            showVariableLabels: true,
            labelValuesType: "percentages",
        };
        if (journeyName === "CompareRatiosJourney")
            return {
                ...baseConfig,
                innerRadius: 80,
                outerRadius: 100,
            };
        if (
            journeyName === "TreemapJourney" ||
            journeyName === "DendrogramJourney" ||
            journeyName === "RadarJourney"
        ) {
            return baseConfig;
        }
    }

    static getPreviewFinding(
        journeyName: string
    ): PieChartFinding | TreemapFinding | DendrogramFinding | RadarFinding {
        let item = {
            type: journeyNameToTypeMap[journeyName],
            content: {
                data: [
                    {
                        name: "var1",
                        value: 50,
                    },
                    {
                        name: "var2",
                        value: 50,
                    },
                    {
                        name: "var3",
                        value: 50,
                    },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static getData(
        finding: PieChartFinding | TreemapFinding | DendrogramFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<PieChartFinding | TreemapFinding | DendrogramFinding> {
        let {
            dataScope,
            operationVariable,
            selectedTable,
            conditions,
            additionalOperators,
            sideBySideVariableIndex,
            additionalValues,
            groupByAll,
        } = finding.config ?? {};
        let variables = finding.content.data.filter(
            (item) => item.variableIndex != null
        );
        if (groupByAll == null) groupByAll = variables.length === 1;
        additionalOperators = (additionalOperators ?? []).filter(
            (operator: StringOption, index: number) =>
                additionalValues[index]?.value != null
        );

        additionalValues = (additionalValues ?? [])
            .filter((value: SearchComponentOption) => value?.value != null)
            .map((value: SearchComponentOption) => value.value);
        conditions = filterNonEmptyConditions(conditions ?? []);
        let requestJson: any = {
            variable_indices: variables.map(
                (variable) => variable.variableIndex
            ),
            conditions: conditionsToJson(conditions),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            module_id: moduleId,
        };
        if (
            !groupByAll &&
            sideBySideVariableIndex != null &&
            additionalValues.length > 0
        ) {
            requestJson.where = {
                group_index: sideBySideVariableIndex,
                expressions: additionalValues.map(
                    (value: SearchComponentOption, index: number) => ({
                        operation: additionalOperators[index]?.value ?? "=",
                        value: value,
                    })
                ),
            };
        }
        if (groupByAll && sideBySideVariableIndex != null) {
            // requestJson.where = {
            //     group_index: sideBySideVariableIndex,
            //     expressions: [],
            // };
            requestJson.column_by = sideBySideVariableIndex;
            operationVariable =
                aggregationToAdditionalMap[operationVariable as string] ??
                operationVariable;
        }
        requestJson.group_operation = operationVariable;
        return axios
            .post<{
                compare_data: {
                    values?: { [key: string | number]: number };
                    where?: {
                        value?: string | number;
                        group_name?: string;
                        operation?: string;
                    };
                }[][];
                success: boolean;
                error_msg: string;
            }>("/api/e/compare_variables", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newFinding:
                        | PieChartFinding
                        | TreemapFinding
                        | DendrogramFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: [...finding.content.data],
                            varyByData:
                                finding.content.varyByData != null
                                    ? [...finding.content.varyByData]
                                    : null,
                        },
                    };

                    // Check wherther variables were renamed
                    updateOriginalNames(
                        newFinding.content.data,
                        dataScope.value,
                        moduleId
                    );
                    if (newFinding.content.varyByData != null) {
                        updateOriginalNames(
                            newFinding.content.varyByData!,
                            dataScope.value,
                            moduleId
                        );
                    }

                    let compareDatas = response.data.compare_data;
                    let firstItem = compareDatas[0];

                    newFinding.content.groupNames = firstItem.map((item) =>
                        item.where
                            ? `${item.where.group_name} ${item.where.operation} ${item.where.value}`
                            : ""
                    );
                    if (
                        additionalBarChartAndPieChartOperations.has(
                            operationVariable
                        )
                    ) {
                        newFinding.content.data = newFinding.content.data.map(
                            (item) => ({
                                ...item,
                                value: [],
                            })
                        );
                        newFinding.content.additionalVaryByData = null;
                        for (let i = 0; i < compareDatas.length; ++i) {
                            let compareData = compareDatas[i];
                            let allKeys: string[] = [];
                            compareData.forEach((item) => {
                                if (item.values != null) {
                                    let keys = Object.keys(item.values);
                                    allKeys = allKeys.concat(keys);
                                }
                            });
                            allKeys = [...new Set(allKeys)];
                            let data: {
                                name: string;
                                originalName: string;
                                value: number[];
                                variableIndex: number;
                            }[] = allKeys.map((item) => ({
                                name: item,
                                variableIndex: variables[i].variableIndex!,
                                originalName: item,
                                value: [],
                            }));
                            compareData.forEach((item) => {
                                if (item.values != null) {
                                    allKeys.forEach((key, valIndex) => {
                                        data[valIndex].value.push(
                                            item.values![key] ?? 0
                                        );
                                    });
                                }
                            });
                            if (i === 0) {
                                newFinding.content.varyByData = data;
                            } else {
                                if (
                                    newFinding.content.additionalVaryByData ==
                                    null
                                ) {
                                    newFinding.content.additionalVaryByData = [];
                                }
                                newFinding.content.additionalVaryByData.push(
                                    data
                                );
                            }
                        }
                    } else {
                        let compareData = compareDatas[0];
                        newFinding.content.varyByData = null;
                        newFinding.content.additionalVaryByData = null;

                        let dataItems = newFinding.content.data.filter(
                            (item) => item.variableIndex != null
                        );
                        for (let dataItem of dataItems) {
                            dataItem.value = [];
                        }
                        for (let newData of compareData) {
                            if (newData.values != null) {
                                for (let dataItem of newFinding.content.data) {
                                    let key = dataItem.variableIndex;
                                    if (key == null) continue;
                                    (dataItem.value as number[]).push(
                                        newData.values[key]
                                    );
                                }
                            }
                        }
                    }
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
