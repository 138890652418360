import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { TableOption } from "common/Tables";
import DataScopes, { DataScope, DataScopeOption } from "common/DataScopes";
import styles from "./MergeSection.module.css";
import DataScopeAndTableSelectorView from "common/DataScopeAndTableSelectorView";
import DataScopeCreatableSelectorView from "common/DataScopeCreatableSelectorView";
import {
    mergeData,
    mergeDataCheckMatch,
    mergeOptions,
    MergeState,
} from "common/MergeData";
import cx from "classnames";
import Switch from "react-switch";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import StringOption from "common/StringOption";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import { getDataTablePermissionsApi } from "common/UserGroupsApi";
import { GroupExtendedPermission } from "common/GroupPermissions";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { VariableOption } from "common/Variables";
import MessagePopup from "../MessagePopup";
import Select from "react-select";
import DragAndDropStates from "common/DragAndDropStates";
import ReactTooltip from "react-tooltip";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { renameVariablesApi } from "common/DataApi";
import { RenameVariable } from "common/VariableEditor";
import { Panel, Type } from "common/InputData";
import { leftAlignAndScrollBarCSS } from "common/SelectStyles";
import SelectedOption from "../SelectedOption";
import TabSwitchConfirmationPopup from "common/TabSwitchConfirmationPopup";

interface Props {
    id: any;
    name: PreviewHeader;
    databody: Array<string | number | Date> | null;
    dataSetNumber: number | null;
    disableDrag?: boolean | null;
    tab?: any;
    dataset?: DataScopeOption | null;
    setShowEdit?: (showEdit: any) => void;
}

export interface PreviewHeader {
    id: number;
    name: string;
    type: string;
}

export interface SelectedVariable {
    leftVariable: VariableOption | null;
    rightVariable: VariableOption | null;
}

export interface Item {
    id: number;
    text: string;
}

export interface ContainerState {
    cards: Item[];
}

export interface CardProps {
    id: any;
    text: string;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number, dataset: number) => void;
    dataset: number;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

export enum DragItemType {
    table = "table",
}

export const ColData = (props: Props) => {
    const [renameInput, setRenameInput] = useState(props.name.name);
    const [toggleEdit, settoggleEdit] = useState<boolean>(true);
    const [statusPopup, setStatusPopup] = useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    let [renameVariablePopup, setrenameVariablePopup] = useState<boolean>(
        false
    );

    const [{ isDragging }, drag, dragging] = useDrag(() => ({
        type: "image",
        item: {
            name: props.name,
            dataSetNumber: props.dataSetNumber,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const getType = (type: string) => {
        switch (type) {
            case "text":
                return Type.Str;
            case "double":
                return Type.Float;
            case "date":
                return "date";
            default:
                return type;
        }
    };
    const renameFunction = async () => {
        const renamevariable: RenameVariable = {
            name: props.name.name,
            new_name: renameInput,
            panel: Panel.Regular,
            type: getType(props.name.type),
            level: null,
            unit: "",
            index: props.name.id,
        };

        let data: any = null;
        if (props?.dataset) {
            try {
                data = await renameVariablesApi(
                    props?.dataset?.value,
                    [renamevariable],
                    null
                );
            } catch (error) {
                setRenameInput(props.name.name);
                setStatusPopup({
                    status: PopupStatus.Error,
                    message: String(error),
                });
            }
        }

        if (data) {
            setStatusPopup({
                status: PopupStatus.Success,
                message: "Success",
            });
        }

        if (props.dataset?.value != null) {
            DataScopes.DatasetPreview(props.dataset?.value);
        }
    };
    const OpenEditMenu = (e: any) => {
        const xdistance = e.clientX;
        const ydistance = e.clientY;
        settoggleEdit(!toggleEdit);

        const showedit = {
            ...props.name,
            Xcoord: xdistance,
            Ycoord: ydistance,
        };
        if (props.setShowEdit) {
            toggleEdit && props.setShowEdit(showedit);
        }
    };

    return (
        <td
            className={styles.previewColumn}
            onMouseOver={() => {
                DragAndDropStates.datasetUpdate(props.id, props.dataSetNumber);
            }}
            onMouseLeave={() => {
                DragAndDropStates.datasetUpdate(null, null);
            }}
        >
            <table
                className={styles.dragableTable}
                style={{
                    width: "100%",
                    background:
                        !props.disableDrag &&
                            DragAndDropStates.getshowDrag === props.id &&
                            DragAndDropStates.getdataset === props.dataSetNumber
                            ? "#EBF2F9"
                            : props.disableDrag
                                ? "transparent"
                                : " transparent",
                    position: "relative",
                }}
                ref={props.disableDrag === true ? null : drag}
            >
                <tr
                    className={styles.dragableTableRow}
                    style={{
                        width: "100%",
                        outline:
                            !props.disableDrag &&
                                DragAndDropStates.getshowDrag === props.id &&
                                DragAndDropStates.getdataset === props.dataSetNumber
                                ? "2px solid #3b83c9 "
                                : props.disableDrag
                                    ? ""
                                    : " ",
                        position: "relative",
                        outlineOffset: "-2px",
                    }}
                // ref={props.disableDrag == true ? null : drag}
                >
                    {!props.disableDrag && (
                        <div className={styles.dragableHeader}>
                            <div className={styles.hoverOverflow}>
                                {DragAndDropStates.getshowDrag === props.id &&
                                    DragAndDropStates.getdataset ===
                                    props.dataSetNumber && (
                                        <p
                                            className={styles.showDrag}
                                            style={{
                                                backgroundColor: "#3b83c9",
                                                textAlign: "center",
                                                color: "white",
                                                fontWeight: 400,
                                                borderTopLeftRadius: 5,
                                                borderTopRightRadius: 5,
                                                paddingBlock: 1,
                                                marginBottom: 0,
                                                fontSize: 12,
                                            }}
                                        >
                                            <img
                                                style={{
                                                    margin: "0px 8px 1px 0px",
                                                    width: 10,
                                                }}
                                                src={`/dist/img/data-exploration/drag-icon.svg`}
                                                alt=""
                                            />
                                            Drag
                                        </p>
                                    )}
                            </div>
                        </div>
                    )}
                    <td className={styles.dragableTableHeader} ref={dragging}>
                        <div className={styles.headerName}>
                            {props.tab === "createVariable" ? (
                                <input
                                    type="text"
                                    value={renameInput}
                                    className={styles.editInput}
                                    onChange={(e) => {
                                        setRenameInput(e.target.value);
                                    }}
                                    onKeyPress={(e) => {
                                        if (props.name.name !== renameInput) {
                                            if (e.key === "Enter") {
                                                setrenameVariablePopup(true);
                                                e.preventDefault();
                                            }
                                        }
                                    }}
                                    onBlur={(e) =>
                                        props.name.name !== renameInput &&
                                        setrenameVariablePopup(true)
                                    }
                                />
                            ) : (
                                props.name.name
                            )}
                        </div>
                        {props?.tab === "createVariable" ||
                            (props.tab === "CleanSection" &&
                                props?.name?.type === "str") ? (
                            <div
                                style={{
                                    width: 20,
                                    textAlign: "center",
                                    position: "relative",
                                    right: 0,
                                }}
                                onClick={OpenEditMenu}
                            >
                                <ChevronIcon stroke="#555" />
                            </div>
                        ) : null}
                    </td>
                </tr>
                {props.databody &&
                    props.databody.map((row, index) => {
                        if (props.tab !== "Reshape") {
                            if (index > 3) return;
                        }
                        return (
                            <tr
                                key={index}
                                className={styles.dragableTableRow}
                                style={{
                                    borderBottom: "1px solid var(--greyColor)",
                                }}
                            >
                                <td
                                    className={styles.dragableTableColumn}
                                    style={{ marginInline: 2 }}
                                >
                                    <div className={styles.headerName}>
                                        {row}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
            </table>

            {renameVariablePopup && (
                <MessagePopup
                    title={<p></p>}
                    message={`Are you sure you want to Rename this Variable?`}
                    width={426}
                    small={true}
                    onAccept={() => {
                        renameFunction();
                        setrenameVariablePopup(false);
                    }}
                    onReject={() => {
                        setrenameVariablePopup(false);
                        setRenameInput(props.name.name);
                    }}
                />
            )}

            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
        </td>
    );
};

export function preview(
    previewHeader: PreviewHeader[] | null,
    previewBody: Array<Array<string | number | null>> | null,
    dataSetNumber: number | null,
    disableDrag?: boolean | null,
    tab?: string,
    dataset?: DataScopeOption | null,
    setShowEdit?: (props: any) => void,
    showdummy?: boolean
) {
    let dataBody: any;
    if (previewBody !== null && previewBody.length > 0) {
        dataBody = previewBody[0].map((col, c) =>
            previewBody.map((row, r) => previewBody[r][c])
        );
    }

    return (
        <div
            className={`${styles.scrollbar} scrollBar`}
            style={{ maxHeight: tab === "Reshape" ? 186 : "" }}
        >
            <table
                className={styles.previewTable}
                style={{ marginTop: disableDrag ? "1px" : "16px" }}
            >
                <tr className={styles.previewRow}>
                    {
                        // ((tab === "createVariable" || showdummy) && DataScopes.preview.headers !== null) &&
                        ((tab === "createVariable" || showdummy) && ((disableDrag && DataScopes.preview.headers === null) || (!disableDrag && DataScopes.preview.headers !== null))) &&
                        <td className={styles.previewColumn} style={{ minWidth: !disableDrag ? "250px" : "", width: "30%", textAlign: 'center', verticalAlign: 'middle' }}>
                            <table style={{ width: '100%', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "10px 8% 10px 18px" }}>
                                <div style={{ width: '100%', height: '100%', backgroundColor: 'var(--greyColor)', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 6 }}>
                                    <b>+<br />Define new data <br />column</b>
                                </div>
                            </table>
                        </td>
                    }
                    {dataBody != null &&
                        previewHeader?.map((item, index) => {
                            return (
                                <ColData
                                    key={index}
                                    id={index}
                                    name={item}
                                    databody={dataBody[index]}
                                    dataSetNumber={dataSetNumber}
                                    disableDrag={disableDrag}
                                    tab={tab ?? null}
                                    dataset={dataset ?? null}
                                    setShowEdit={setShowEdit ?? undefined}
                                />
                            );
                        })}
                </tr>
            </table>
        </div>
    );
}

const Card = ({ id, text, index, moveCard, dataset }: CardProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: "card",
        drop: (item: any) => {
            moveCard(item.index, index, dataset);
        },
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "card",
        item: () => {
            return { id, index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));
    return (
        <div
            ref={ref}
            className={styles.mergeRow}
            data-handler-id={handlerId}
            style={{ opacity: isDragging ? 0 : 1 }}
        >
            <div className={styles.mergeColumn}>
                <span className={styles.mergefild}>
                    {text}
                    <button

                        className={styles.removeBtn}
                        onClick={() => removeitem(index, dataset)}
                    >
                        <img
                            src="dist/img/data-exploration/minus.png"
                            alt=""
                            style={{ width: "100%" }}
                        />
                    </button>
                </span>
            </div>
        </div>
    );
};

const Container = (props: { dataset: number }) => {
    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number, dataset: number) => {
            if (dataset === 1) {
                let item = DragAndDropStates.selected1[dragIndex];
                DragAndDropStates.selected1.splice(dragIndex, 1);
                DragAndDropStates.selected1.splice(hoverIndex, 0, item);
                DragAndDropStates.ReUpdateSelected(
                    DragAndDropStates.selected1,
                    1
                );
            } else if (dataset === 2) {
                let item = DragAndDropStates.selected2[dragIndex];
                DragAndDropStates.selected2.splice(dragIndex, 1);
                DragAndDropStates.selected2.splice(hoverIndex, 0, item);
                DragAndDropStates.ReUpdateSelected(
                    DragAndDropStates.selected2,
                    2
                );
            }
        },
        []
    );

    const renderCard = useCallback((card: PreviewHeader, index: number) => {
        return (
            <Card
                key={card.id}
                index={index}
                id={card.id}
                text={card.name}
                moveCard={moveCard}
                dataset={props.dataset}
            />
        );
    }, []);

    return (
        <div className={styles.dropletItem}>
            {props.dataset === 1
                ? DragAndDropStates.getSelected1.map((card, i) =>
                    renderCard(card, i)
                )
                : DragAndDropStates.getSelected2.map((card, i) =>
                    renderCard(card, i)
                )}
        </div>
    );
};

export function removeitem(id: number, datasetNumber: number) {
    if (datasetNumber === 1) {
        DragAndDropStates.selected1.splice(id, 1);
        DragAndDropStates.ReUpdateSelected(DragAndDropStates.selected1, 1);
    } else if (datasetNumber === 2) {
        DragAndDropStates.selected2.splice(id, 1);
        DragAndDropStates.ReUpdateSelected(DragAndDropStates.selected2, 2);
    }
}

function MergeSection(props: { setOperationInProgress: (state: boolean) => void; }) {
    function executeMerge(mergeState: MergeState, preview: boolean) {
        let headersToStoreLeft: string[] = [];
        let headersToStoreRight: string[] = [];

        previewHeader1?.map((data) => {
            if (previewInfo?.tableHeader[0] === data?.name) {
                headersToStoreLeft.push(previewInfo.tableHeader[0]);
                previewInfo?.tableHeader.splice(0, 1);
            }
        });

        headersToStoreRight = previewInfo?.tableHeader ?? [];
        headersToStoreRight = headersToStoreRight.map(
            (header) => previewInfo?.rightRenameMapping?.[header] ?? header
        );
        const moduleId = null;

        setMerging(true);
        if (preview) {
            setPreviewInfo(null);
            // setshowPreviewInfo(null)
        }
        setStatusPopup({
            status: PopupStatus.Success,
            message: "Merging data...",
        });

        mergeData(
            mergeState,
            (previewData) => {
                setMerging(false);
                setStatusPopup({
                    status: PopupStatus.Success,
                    message: "Merged",
                });

                setTimeout(() => {
                    setStatusPopup(null)
                }, 3000);

                if (!preview) {
                    DataScopes.update();
                } else {
                    setPreviewInfo(previewData ?? null);
                }
            },
            (errorMessage: string) => {
                setMerging(false);

                setStatusPopup({
                    status: PopupStatus.Error,
                    message: errorMessage,
                });
            },
            preview ? 5 : undefined,
            moduleId,
            true,
            !preview ? headersToStoreLeft : undefined,
            !preview ? headersToStoreRight : undefined
        );
    }

    let [leftDataset, setLeftDataset] = useState<DataScopeOption | null>(null);
    let [mergeType, setMergeType] = useState<StringOption>(mergeOptions[0]);
    let [rightDataset, setRightDataset] = useState<DataScopeOption | null>(
        null
    );
    let [targetDataset, setTargetDataset] = useState<DataScopeOption | null>(
        null
    );
    let [leftTable, setLeftTable] = useState<TableOption | null>(null);
    let [rightTable, setRightTable] = useState<TableOption | null>(null);
    let [statusPopup, setStatusPopup] = useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);
    let [dropMissingLeft, setDropMissingLeft] = useState<boolean>(false);
    let [dropMissingRight, setDropMissingRight] = useState<boolean>(false);
    let [previewInfo, setPreviewInfo] = useState<{
        tableHeader: string[];
        tablePreview: (string | number | null)[][];
        rightRenameMapping: { [key: string]: string };
        dropped: number | undefined;
    } | null>(null);
    // let [showPreviewInfo, setshowPreviewInfo] = useState<{
    //     tableHeader: string[];
    //     tablePreview: any[];
    //     dropped: number | undefined;
    // } | null>(null);
    let [merging, setMerging] = useState<boolean>(false);
    let [permissions, setPermissions] = useState<
        (GroupExtendedPermission | null)[]
    >([]);
    const [previewHeader1, setpreviewHeader1] = useState<
        PreviewHeader[] | null
    >(null);
    const [previewBody1, setpreviewBody1] = useState<Array<
        Array<string | number | null>
    > | null>(null);
    const [previewHeader2, setpreviewHeader2] = useState<
        PreviewHeader[] | null
    >(null);
    const [previewBody2, setpreviewBody2] = useState<Array<
        Array<string | number | null>
    > | null>(null);
    const [showRemoveInPreview, setShowRemoveInPreview] = useState<boolean>(
        false
    );
    const [variableMatching, setVariableMatching] = useState<
        { icon: string; text: string }[]
    >([]);
    const [mergableVariable, setMergableVariable] = useState<
        SelectedVariable[]
    >([]);
    const [saveDataset, setSaveDataset] = useState<boolean>(false);

    async function getDatasetPreview(
        id: string | number,
        setHeader: (params: any) => any,
        setbody: (params: any) => any
    ) {
        try {
            let result = await DataScopes.getDataSetPreview(id);
            setHeader(result.headers);
            setbody(result.body);
        } catch (error) {
            setHeader(null);
            setbody(null);
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    }

    useEffect(() => {
        return () => {
            DragAndDropStates.makedefault();
        };
    }, []);

    useEffect(() => {
        props.setOperationInProgress((leftDataset || rightDataset)? true : false);
    }, [leftDataset, rightDataset]);
    
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: "image",
            drop: (item: any) => {
                DragAndDropStates.updateSelected(item);
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }),
        }),
        [DragAndDropStates.selected1, DragAndDropStates.selected2]
    );

    async function validateColumns() {
        let selected1 = DragAndDropStates.selected1;
        let selected2 = DragAndDropStates.selected2;
        let ad: { icon: string; text: string };
        let selected: PreviewHeader[] | [];
        if (selected1.length <= selected2.length) {
            selected = selected1;
        } else {
            selected = selected2;
        }
        let match: "valid" | "invalid" | "possible";
        if (selected.length > 0) {
            for (let i = 0; i < selected.length; ++i) {
                if (selected1[i]?.type !== selected2[i]?.type) {
                    ad = { icon: "remove.svg", text: "Invalid Match" };
                } else {
                    try {
                        match = await mergeDataCheckMatch(
                            {
                                leftDataScope: leftDataset!,
                                rightDataScope: rightDataset!,
                                leftTableOption: leftTable!,
                                rightTableOption: rightTable!,
                                dropMissingLeft: dropMissingLeft,
                                dropMissingRight: dropMissingRight,
                                targetDataScope: null,
                                variableJoins: mergableVariable,
                                permissions: [],
                                mergeType: mergeType,
                            },
                            selected1[i]!.id,
                            selected2[i]!.id,
                            null // moduleId
                        );
                        if (match === "valid") {
                            ad = {
                                icon: "green-tick.svg",
                                text: "Successful match",
                            };
                        } else if (match === "possible") {
                            ad = {
                                icon: "warning-sign.svg",
                                text: "Possible match",
                            };
                        } else {
                            // match === "invalid"
                            ad = {
                                icon: "remove.svg",
                                text: "No matches found",
                            };
                        }
                    } catch (error) {
                        console.log(error);
                        ad = { icon: "remove.svg", text: "No matches found" };
                    }
                }
                setVariableMatching((variableMatching) => {
                    let newVariableMatching = Array.from(variableMatching);
                    newVariableMatching[i] = ad;
                    if (newVariableMatching.length > selected.length) {
                        for (let a = i; a < newVariableMatching.length; a++) {
                            newVariableMatching[i] = ad;
                            newVariableMatching[a] = { icon: "", text: "" };
                        }
                    }
                    return newVariableMatching;
                });
            }
        } else {
            setVariableMatching([{ icon: "", text: "" }]);
        }

        let selectedF: PreviewHeader[] | [];
        if (selected1.length <= selected2.length) {
            selectedF = selected2;
        } else {
            selectedF = selected1;
        }
        if (selectedF.length > 0) {
            selectedF.forEach((_, i) => {
                let leftVariable: VariableOption | null = null;
                let rightVariable: VariableOption | null = null;
                if (match === "valid") {
                    leftVariable = {
                        label: selected1[i].name,
                        panel: "regular",
                        type: selected1[i].type,
                        value: selected1[i].id,
                    };
                    rightVariable = {
                        label: selected2[i].name,
                        panel: "regular",
                        type: selected2[i].type,
                        value: selected2[i].id,
                    };
                }

                if (leftVariable && rightVariable) {
                    let pre = mergableVariable.filter(
                        (item) =>
                            item.leftVariable?.label === leftVariable?.label
                    );
                    if (mergableVariable.length > 0) {
                        if (pre.length === 0) {
                            mergableVariable.push({
                                leftVariable: leftVariable,
                                rightVariable: rightVariable,
                            });
                            setMergableVariable([...mergableVariable]);
                        } else if (pre.length > 0) {
                            setMergableVariable([...mergableVariable]);
                        }
                    } else {
                        mergableVariable.push({
                            leftVariable: leftVariable,
                            rightVariable: rightVariable,
                        });
                        setMergableVariable([...mergableVariable]);
                    }
                } else if (leftVariable == null || rightVariable == null) {
                    mergableVariable.forEach((item, idx) => {
                        if (selectedF[i]?.id === item.leftVariable?.value) {
                            mergableVariable.splice(idx, 1);
                            setMergableVariable([...mergableVariable]);
                        } else {
                            setMergableVariable((prev) => [...prev]);
                        }
                    });
                }
            });
        }
    }

    useEffect(() => {
        validateColumns();
    }, [DragAndDropStates.selected1, DragAndDropStates.selected2, mergeType]);

    const hotAreaStyle = (dataset: number) => {
        return {
            border:
                DragAndDropStates.getdataset === dataset && isOver
                    ? "2px dotted var(--hot-area-greenBorder)"
                    : DragAndDropStates.getdataset === dataset && canDrop
                        ? "2px dotted var(--hot-area-blueBorder)"
                        : "2px dotted transparent",
            backgroundColor:
                DragAndDropStates.getdataset === dataset && isOver
                    ? "var(--hot-area-greenBg)"
                    : DragAndDropStates.getdataset === dataset && canDrop
                        ? "var(--hot-area-blueBg)"
                        : "#fff",
            outline: "1px solid #fff",
        };
    };



    return (
        <div className={"mainContainer"}>
            <h1 style={{ display: "none" }}> {DragAndDropStates.showDrag}</h1>
            <h1 style={{ display: "none" }}> {DragAndDropStates.datasett}</h1>

            <div className={styles.datasetsContainer}>
                <div className={styles.dataset}>
                    <div className={styles.datasetSelector}>
                        <h3
                            className={styles.textItem}
                            style={{ marginTop: 0 }}
                        >
                            Dataset 1
                        </h3>
                        <DataScopeAndTableSelectorView
                            dataHubMode
                            dataScopeOption={leftDataset}
                            tableOption={leftTable}
                            onChange={(dataScopeOption, tableOption) => {
                                setLeftDataset(dataScopeOption);
                                setLeftTable(tableOption);
                                if (dataScopeOption?.value != null)
                                    getDatasetPreview(
                                        dataScopeOption?.value,
                                        setpreviewHeader1,
                                        setpreviewBody1
                                    );
                                setPreviewInfo(null);
                                DragAndDropStates.ReUpdateSelected([], 1);
                                setMergableVariable([]);
                                setpreviewHeader1(null);

                                SelectedOption.UpdateaskSwitchTab(false);
                                SelectedOption.UpdateSwitchTab(false);
                            }}
                            dataScopePlaceholder={"Select dataset"}
                            tableOptionPlaceholder={"Select table"}
                            needWritePermissions={false}
                            hideTable={true}
                        ></DataScopeAndTableSelectorView>
                        <div style={{ marginBottom: "15px" }}></div>
                    </div>
                    {previewHeader1 != null && (
                        <div
                            className={"customPreview"}
                            style={{
                                maxWidth: 570,
                                padding: "0px 15px 10px 15px",
                            }}
                        >
                            {preview(previewHeader1, previewBody1, 1)}
                        </div>
                    )}
                </div>
                <div className={styles.dataset} style={{ marginLeft: 50 }}>
                    <div className={styles.datasetSelector}>
                        <h3
                            className={styles.textItem}
                            style={{ marginTop: 0 }}
                        >
                            Dataset 2
                        </h3>
                        <DataScopeAndTableSelectorView
                            dataHubMode
                            dataScopeOption={rightDataset}
                            tableOption={rightTable}
                            onChange={(dataScopeOption, tableOption) => {
                                setRightDataset(dataScopeOption);
                                setRightTable(tableOption);
                                if (dataScopeOption?.value != null)
                                    getDatasetPreview(
                                        dataScopeOption?.value,
                                        setpreviewHeader2,
                                        setpreviewBody2
                                    );
                                setPreviewInfo(null);
                                DragAndDropStates.ReUpdateSelected([], 2);
                                setMergableVariable([]);
                                setpreviewHeader2(null);

                                SelectedOption.UpdateaskSwitchTab(false);
                                SelectedOption.UpdateSwitchTab(false);
                            }}
                            dataScopePlaceholder={"Select dataset"}
                            tableOptionPlaceholder={"Select table"}
                            needWritePermissions={false}
                            hideTable={true}
                        ></DataScopeAndTableSelectorView>
                    </div>
                    {previewHeader2 != null && (
                        <div
                            className={"customPreview"}
                            style={{
                                maxWidth: 570,
                                padding: "0px 15px 10px 15px",
                            }}
                        >
                            {preview(previewHeader2, previewBody2, 2)}
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.mergeCustomSec}>
                <div className={styles.mergeSecWrapper}>
                    {leftDataset &&
                        rightDataset &&
                        DragAndDropStates.selected2.length === 0 ? (
                        <h3 className={styles.textItem}>Selected variables</h3>
                    ) : (
                        <h3 className={styles.textItem}>
                            Select dataset to drop variables
                        </h3>
                    )}
                    <div className={styles.mainMergeSec} ref={drop}>
                        {previewHeader1 != null && previewHeader2 != null && (
                            <div className={styles.mergeSecContent}>
                                <div className={styles.mergeSecColumn}>
                                    <h3 className={styles.textItem}>
                                        {leftDataset?.label}
                                    </h3>
                                    {DragAndDropStates.selected1?.length !==
                                        0 ? (
                                        <div>
                                            <div
                                                className={styles.dropCustombx}
                                            >
                                                <Container dataset={1} />
                                                <div
                                                    className={styles.mergeimg}
                                                >
                                                    {DragAndDropStates.selected2
                                                        .length > 0 &&
                                                        DragAndDropStates
                                                            .selected1.length >
                                                        0 &&
                                                        Array.from(
                                                            Array(
                                                                Math.min(
                                                                    DragAndDropStates
                                                                        .selected1
                                                                        .length,
                                                                    DragAndDropStates
                                                                        .selected2
                                                                        .length
                                                                )
                                                            )
                                                        ).map(() => (
                                                            <span
                                                                className={
                                                                    styles.mergeIcon
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        "/dist/img/data-exploration/merge.svg"
                                                                    }
                                                                    alt=""
                                                                />
                                                            </span>
                                                        ))}
                                                </div>
                                            </div>
                                            <div
                                                className={styles.dropCustombx}
                                            >
                                                <div className={styles.hhhh}>
                                                    <p
                                                        className={
                                                            styles.previewNull
                                                        }
                                                        style={hotAreaStyle(1)}
                                                    >
                                                        Drop column here to
                                                        merge
                                                    </p>
                                                </div>
                                                <p
                                                    className={styles.mergeimg}
                                                ></p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                styles.previewNullContainer
                                            }
                                        >
                                            <p
                                                className={styles.previewNull}
                                                style={hotAreaStyle(1)}
                                            >
                                                Drop column here to merge
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.mergeSecColumn}>
                                    <h3 className={styles.textItem}>
                                        {rightDataset?.label}
                                    </h3>
                                    {DragAndDropStates.selected2?.length !==
                                        0 ? (
                                        <div>
                                            <div
                                                className={styles.dropCustombx}
                                            >
                                                <Container dataset={2} />
                                                <div
                                                    className={styles.mergeimg}
                                                >
                                                    {variableMatching.length >
                                                        0 &&
                                                        variableMatching.map(
                                                            (item, i) => (
                                                                <div
                                                                    className={
                                                                        styles.mergeIcon
                                                                    }
                                                                >
                                                                    {item.icon !==
                                                                        "" && (
                                                                            <span
                                                                                className={
                                                                                    styles.mergeIcon
                                                                                }
                                                                            >
                                                                                <img
                                                                                    data-tip={
                                                                                        item.text
                                                                                    }
                                                                                    data-for={
                                                                                        "variableStatus"
                                                                                    }
                                                                                    src={`/dist/img/data-exploration/${item.icon}`}
                                                                                    alt=""
                                                                                />
                                                                                <ReactTooltip
                                                                                    id="variableStatus"
                                                                                    effect="solid"
                                                                                    backgroundColor="#464646"
                                                                                    className={
                                                                                        styles.tooltip
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                            <div
                                                className={styles.dropCustombx}
                                            >
                                                <div className={styles.hhhh}>
                                                    <p
                                                        className={
                                                            styles.previewNull
                                                        }
                                                        style={hotAreaStyle(2)}
                                                    >
                                                        Drop column here to
                                                        merge
                                                    </p>
                                                </div>
                                                <p
                                                    className={styles.mergeimg}
                                                ></p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                styles.previewNullContainer
                                            }
                                        >
                                            <p
                                                className={styles.previewNull}
                                                style={hotAreaStyle(2)}
                                            >
                                                Drop column here to merge
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.mergeAndTargetContainer}>
                    <div className={styles.mergeTypeSelector}>
                        <h3
                            className={styles.textItem}
                            style={{ marginTop: 10 }}
                        >
                            Merge type
                        </h3>
                        <Select
                            styles={{
                                ...leftAlignAndScrollBarCSS,
                                container: (base) => ({
                                    ...base,
                                    backgroundColor: "transparent",
                                    width: "120px",
                                    border: "navajowhite",
                                    padding: "0 0 0 5px",
                                    justifyContent: "center",
                                }),
                            }}
                            components={{IndicatorSeparator:null}}
                            defaultValue={mergeOptions[0]}
                            options={mergeOptions}
                            onChange={(e: any) => {
                                setMergeType(e);
                            }}
                        />
                    </div>

                    <div className={styles.dropMissingContainer}>
                        <span className={styles.dropMissingItem}>
                            <h3
                                className={styles.textItem}
                                style={{ marginTop: 10 }}
                            >
                                {`Drop missing${mergeType.value === "inner" ? "" : " left"
                                    } variable`}
                            </h3>
                            <Switch
                                onChange={(checked) => {
                                    setDropMissingLeft(checked);
                                    if (mergeType.value === "inner") {
                                        setDropMissingRight(checked);
                                    }
                                }}
                                checked={
                                    mergeType.value === "inner"
                                        ? dropMissingLeft || dropMissingRight
                                        : dropMissingLeft
                                }
                                className={styles.switch}
                                width={40}
                                height={20}
                                offColor="#CCC"
                                onColor="#CCC"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </span>
                        {mergeType.value !== "inner" && (
                            <span
                                className={styles.dropMissingItem}
                                style={{ marginLeft: 22 }}
                            >
                                <div className={styles.textItem}>
                                    Drop missing right variable
                                </div>
                                <Switch
                                    onChange={(checked) => {
                                        setDropMissingRight(checked);
                                    }}
                                    checked={dropMissingRight}
                                    className={styles.switch}
                                    width={40}
                                    height={15}
                                    handleDiameter={20}
                                    activeBoxShadow={"none"}
                                    offColor="#CCC"
                                    onColor="#CCC"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.previewbtnContainer}>
                <button
                    className={"acceptBtn"}
                    // className={cx(styles.previewBtn, "unselectable")}
                    type="button"
                    disabled={
                        merging ||
                        leftDataset == null ||
                        rightDataset == null ||
                        leftTable == null ||
                        rightTable == null ||
                        mergableVariable.length === 0
                    }
                    onClick={() => {
                        let mergeState: MergeState = {
                            leftDataScope: leftDataset!,
                            rightDataScope: rightDataset!,
                            leftTableOption: leftTable!,
                            rightTableOption: rightTable!,
                            dropMissingLeft: dropMissingLeft,
                            dropMissingRight: dropMissingRight,
                            targetDataScope: null,
                            variableJoins: mergableVariable,
                            permissions: [],
                            mergeType: mergeType,
                        };
                        executeMerge(mergeState, true);

                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(false);
                    }}
                >
                    {"Preview"}
                </button>
            </div>

            <div className={styles.mergedPreview}>
                <div className={styles.previewContainer}>
                    {previewInfo != null ? (
                        <>
                            <div className={`mergedTableWrapper scrollBar`}>
                                <AdminTableWithFullFeatures
                                    tableName="MergeTable"
                                    // className="import-table"
                                    className={`${styles.mergedPreviewTable} import-table`}
                                    paging={false}
                                    small={false}
                                    tableHeader={previewInfo.tableHeader}
                                    tableContent={previewInfo.tablePreview}
                                    leftDataset={leftDataset! as DataScopeOption}
                                    rightDataset={rightDataset! as DataScopeOption}
                                    // tableHeader={showPreviewInfo.tableHeader}
                                    // tableContent={showPreviewInfo.tablePreview}
                                    deleteColumnOption={showRemoveInPreview}
                                    mergableVariables={mergableVariable}
                                    // setshowPreviewInfo={setshowPreviewInfo}
                                    setPreviewInfo={setPreviewInfo}
                                    tab="mergeSection"
                                />
                            </div>
                            <div
                                className="my-row"
                                style={{
                                    marginRight: "20px",
                                    marginLeft: "20px",
                                    justifyContent: "center",
                                }}
                            >
                                {previewInfo.dropped != null && (
                                    <div className={styles.textItem}>
                                        {`${previewInfo.dropped} will be dropped`}
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <p
                            className={styles.textItem}
                            style={{ marginTop: 80, textAlign: "center" }}
                        >
                            Preview
                        </p>
                    )}
                </div>
                {previewInfo != null && (
                    <div className={"buttonsContainer"}>
                        <button
                            className={"acceptBtn"}
                            style={{ width: "200px", paddingBlock: 7 }}
                            onClick={() => {
                                setShowRemoveInPreview(!showRemoveInPreview);
                            }}
                        >
                            {showRemoveInPreview
                                ? "Apply Changes"
                                : " Remove Variables"}
                        </button>
                    </div>
                )}
            </div>
            <div className={"pageEndbuttons"}>
                <button
                    className={"cancelBtn"}
                    type="button"
                    onClick={() => {
                        setLeftDataset(null);
                        setRightDataset(null);
                        setLeftTable(null);
                        setRightTable(null);
                        setMergableVariable([]);
                        setPreviewInfo(null);
                        setpreviewHeader1(null);
                        setpreviewHeader2(null);
                        setpreviewBody1(null);
                        setpreviewBody2(null);
                        DragAndDropStates.ReUpdateSelected([], 1);
                        DragAndDropStates.ReUpdateSelected([], 2);

                        SelectedOption.UpdateSwitchTab(true)
                    }}
                >
                    {"Reset"}
                </button>
                <button
                    className={"acceptBtn"}
                    style={{ marginLeft: "16px" }}
                    type="button"
                    disabled={
                        merging ||
                        leftDataset == null ||
                        rightDataset == null ||
                        leftTable == null ||
                        rightTable == null ||
                        mergableVariable.length === 0
                    }
                    onClick={() => {
                        setSaveDataset(true);
                        setTargetDataset(null);
                    }}
                >
                    {"Save"}
                </button>
            </div>

            {SelectedOption.getAskSwitchTab &&
                (leftDataset != null || rightDataset != null) && (
                    <TabSwitchConfirmationPopup />
                )}

            {saveDataset && (
                <MessagePopup
                    title={<p>Name data set</p>}
                    acceptButtonTitle="Save"
                    crossIcon="false"
                    message={
                        <div className={`${styles.datasetSelector}`}>
                            <DataScopeCreatableSelectorView
                                dataHubMode
                                value={targetDataset ?? undefined}
                                onChange={(dataScopeOption) => {
                                    setTargetDataset(dataScopeOption);
                                    if (Number.isNaN(dataScopeOption.value)) {
                                        setPermissions([]);
                                    } else {
                                        setPermissions([]);
                                        getDataTablePermissionsApi(
                                            dataScopeOption.value
                                        )
                                            .then((permissions) => {
                                                setPermissions(permissions);
                                            })
                                            .catch((_error) => {
                                                setPermissions([]);
                                            });
                                    }
                                }}
                                permissions={permissions}
                                onPermissionsChange={setPermissions}
                            ></DataScopeCreatableSelectorView>
                        </div>
                    }
                    disabled={targetDataset == null || targetDataset.label == ""}
                    onAccept={() => {
                        let mergeState: MergeState = {
                            leftDataScope: leftDataset!,
                            rightDataScope: rightDataset!,
                            leftTableOption: leftTable!,
                            rightTableOption: rightTable!,
                            dropMissingLeft: dropMissingLeft,
                            dropMissingRight: dropMissingRight,
                            targetDataScope: targetDataset,
                            variableJoins: mergableVariable,
                            permissions: permissions,
                            mergeType: mergeType,
                        };
                        executeMerge(mergeState, false);
                        setSaveDataset(false);
                        setLeftDataset(null);
                        setRightDataset(null);
                        setLeftTable(null);
                        setRightTable(null);
                        setMergableVariable([]);
                        setPreviewInfo(null);
                        setpreviewHeader1(null);
                        setpreviewHeader2(null);
                        setpreviewBody1(null);
                        setpreviewBody2(null);
                        DragAndDropStates.ReUpdateSelected([], 1);
                        DragAndDropStates.ReUpdateSelected([], 2);

                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(true);
                    }}
                    onReject={() => {
                        setSaveDataset(false);
                    }}
                />
            )}

            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}

            {((previewHeader1 === null && leftDataset != null) ||
                (previewHeader2 === null && rightDataset != null)) && (
                    <StatusPopup
                        status={PopupStatus.Error}
                        message={"Loading Data"}
                        onClose={() => {
                            // console.log("popup closed")
                        }}
                    />
                )}
        </div>
    );
}

export default observer(MergeSection);
