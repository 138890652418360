import React, { useState, useRef } from "react";
import cx from "classnames";
import { ReactComponent as MagnifierIcon } from "icons/magnifier.svg";
import styles from "./Toolbar.module.css";
import commonStyles from "modules/home_page/home_page.module.css";

interface Props {
    title: string;
    onChangeSearchText: Function;
    createPresentation: React.MouseEventHandler;
}

function Toolbar({
    title,
    onChangeSearchText
}: Props) {
    const [searchText, setSearchText] = useState("");

    const searchInputRef = useRef<HTMLInputElement>(null);

    function onFocusSearchButton() {
        searchInputRef.current?.focus();
    }

    function changeSearchText(event: React.FormEvent<HTMLInputElement>) {
        onChangeSearchText(event.currentTarget.value);
        setSearchText(event.currentTarget.value);
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
        }}>
            <h4 className={commonStyles.smallTitle}>{title.toUpperCase()}</h4>

            <div className={styles.searchContainer}>
                <button
                    type="button"
                    onClick={onFocusSearchButton}
                    className={styles.searchButton}
                >
                    <MagnifierIcon />
                </button>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchText}
                    onChange={changeSearchText}
                    onFocus={onFocusSearchButton}
                    className={cx(styles.searchInput, commonStyles.middleText)}
                    ref={searchInputRef}
                />
            </div>
        </div>
    );
}

export default Toolbar;
