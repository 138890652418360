import React, { useState } from "react";
import { mainStyle } from "common/MainStyle";
import { DateQuestion } from "common/Canvas";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";

interface Props {
	question: DateQuestion;
	maxHeight: number;
	live: boolean;
	frozen: boolean;
	onChange: (question: DateQuestion) => void;
	onNext: (question: DateQuestion) => void;
}

export default function DateQuestionView(props: Props) {
	let [answer, setAnswer] = useState(props.question.answer);
	let dateOnly = props.question.dateOnly ?? false;
	return (
		<div
			className="flex-simple-column"
			style={{ maxHeight: props.maxHeight, marginLeft: "90px" }}
		>
			<input
				type={!dateOnly ? "datetime-local" : "date"}
				autoFocus
				placeholder={props.live ? "Type your response" : ""}
				disabled={!props.live || props.question.currentDate}
				style={{
					fontSize: mainStyle.getPropertyValue(
						"--primary-path-title-size"
					),
					border: "none",
					resize: "none",
					outline: "none",
					backgroundColor: "transparent",
					fontFamily: "Roboto",
					color: dataScienceElementsStyle.secondaryTextColor,
				}}
				value={
					!props.question.currentDate
						? answer
						: dateOnly
						? new Date().toISOString().slice(0, 10)
						: new Date().toISOString().slice(0, 19)
				}
				onMouseDown={(evt) => {
					evt.stopPropagation();
				}}
				onChange={(evt) => {
					// We have to read name before setState, because evt.target.value might be undefined during setState
					let value = evt.currentTarget.value;
					setAnswer(value);
				}}
				onBlur={(evt) => {
					if (evt.target.getAttribute("preventBlur") !== "true") {
						let newQuestion = Object.assign({}, props.question);
						newQuestion.answer = answer;
						props.onChange(newQuestion);
					} else {
						evt.target.removeAttribute("preventBlur");
					}
				}}
				onKeyDown={(evt) => {
					evt.stopPropagation();
					if (evt.key === "Enter" && evt.shiftKey) {
						evt.preventDefault();
						evt.currentTarget.setAttribute("preventBlur", "true");
						let newQuestion = Object.assign({}, props.question);
						newQuestion.answer = answer;
						if (props.live) props.onNext(newQuestion);
					}
				}}
			/>
			<div
				style={{
					height: "2px",
					backgroundColor:
						dataScienceElementsStyle.secondaryTextColor,
				}}
			></div>
			<div
				className="flex-simple-column"
				style={{ alignSelf: "flex-end" }}
			>
				<div className="pretty p-default" style={{ marginTop: "10px" }}>
					<input
						type="checkbox"
						checked={props.question.currentDate}
						onChange={() => {
							props.onChange({
								...props.question,
								answer: "",
								currentDate: !props.question.currentDate,
							});
						}}
					/>
					<div className="state">
						<label
							style={{
								fontSize: 14,

								fontFamily: "Roboto",
								color:
									dataScienceElementsStyle.secondaryTextColor,
							}}
						>
							Current Date
						</label>
					</div>
				</div>
				{!props.live && (
					<div
						className="pretty p-default"
						style={{ marginTop: "10px" }}
					>
						<input
							disabled={props.frozen}
							type="checkbox"
							checked={dateOnly}
							onChange={() => {
								props.onChange({
									...props.question,
									answer: "",
									dateOnly: !dateOnly,
								});
							}}
						/>
						<div className="state">
							<label
								style={{
									fontSize: 14,

									fontFamily: "Roboto",
									color:
										dataScienceElementsStyle.secondaryTextColor,
								}}
							>
								Date Only
							</label>
						</div>
					</div>
				)}
			</div>
			{props.live && (
				<>
					<span
						style={{
							marginTop: 10,
							alignSelf: "flex-end",
							cursor: "pointer",
							fontFamily: "Roboto",
							fontSize: "12px",
							lineHeight: "14px",
							color: dataScienceElementsStyle.secondaryTextColor,
						}}
					>
						[ ENTER ] to make line break
					</span>
					<span
						style={{
							marginTop: 10,
							alignSelf: "flex-end",
							cursor: "pointer",
							fontFamily: "Roboto",
							fontSize: "12px",
							lineHeight: "14px",
							color: dataScienceElementsStyle.secondaryTextColor,
						}}
					>
						[ SHIFT ] + [ ENTER ] for next question
					</span>
				</>
			)}
		</div>
	);
}