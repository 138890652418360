import React, { Component } from "react";
import { MapFinding, NetworkFinding } from "common/Finding";
import AsyncLock from "common/AsyncLock";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import { observer } from "mobx-react";
import Variables from "common/Variables";
import { CSVRow } from "common/CSVTypes";
import MapElementView from "common/MapElementView";

interface Props {
    live: boolean;
    mapFinding: MapFinding;
    networkFinding?: NetworkFinding;
    onNetworkChange?: (
        networkFinding: NetworkFinding,
        updateData?: boolean
    ) => void;
    mapId: string;
    onChange: (finding: MapFinding, update?: boolean) => void;
    currentModuleId: number | undefined;
    scale: number;
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
    width: number;
    height: number;
}

interface State {
    currentZoomLevel: number | undefined;
    advancedOptionSelected: AdvancedMenuOptions;
    updating: boolean;
    data: { [key: string]: Array<number | string | null> } | null;
    heatMapData: Array<[number, number, number]>;
    geoJsonPopupIsOpen: boolean;
}

enum AdvancedMenuOptions {
    None = 0,
    Pins = 1,
    Heatmap = 2,
    MapBoundaries = 3,
}

@observer
class MapElementWrapper extends Component<Props, State> {
    drag: boolean;
    updateMapLock: AsyncLock;

    constructor(props: Props) {
        super(props);
        this.state = {
            currentZoomLevel: undefined,
            advancedOptionSelected: AdvancedMenuOptions.None,
            updating: false,
            heatMapData: [],
            data: null,
            geoJsonPopupIsOpen: false,
        };

        this.drag = false;
        this.updateMapLock = new AsyncLock();
        this.openGeoJsonPopup = this.openGeoJsonPopup.bind(this);
    }

    openGeoJsonPopup() {
        this.setState({ geoJsonPopupIsOpen: true });
    }

    render() {
        let showTable = this.props.mapFinding.config.showTable;
        let tableHeader: string[] = [];
        let tableValues: CSVRow[] = [];
        let dataScopeValue = this.props.mapFinding.config?.dataScope?.value;
        if (
            dataScopeValue != null &&
            this.props.mapFinding.content.data != null
        ) {
            let keys = Object.keys(this.props.mapFinding.content.data);
            tableHeader = keys.map(
                (key) =>
                    Variables(
                        dataScopeValue,
                        this.props.currentModuleId
                    ).getVariableByIndex(Number(key))?.name ?? key
            );
            if (tableHeader.length > 0) {
                let rowCount =
                    this.props.mapFinding.content.data[keys[0]].length;
                for (let i = 0; i < rowCount; ++i) {
                    tableValues.push(
                        keys.map(
                            (key) => this.props.mapFinding.content.data![key][i]
                        )
                    );
                }
            }
        }
        return (
            <div
                style={{ position: "relative", width: "100%", height: "100%" }}
            >
                <MapElementView
                    scale={this.props.scale}
                    live={this.props.live}
                    mapFinding={this.props.mapFinding}
                    networkFinding={this.props.networkFinding}
                    onNetworkChange={this.props.onNetworkChange}
                    mapId={this.props.mapId}
                    onChange={this.props.onChange}
                    currentModuleId={this.props.currentModuleId}
                    loading={this.state.updating}
                    editable={this.props.editable}
                    centerVariableValue={
                        (this.props.networkFinding ?? this.props.mapFinding)
                            .config.centerVariableValue
                    }
                    preview={this.props.preview}
                    columnDragActive={this.props.columnDragActive}
                    width={this.props.width}
                    height={this.props.height}
                />
                {showTable &&
                    tableHeader.length > 0 &&
                    tableValues.length > 0 && (
                        <div style={{marginTop: "20px"}}>
                        <AdminTableWithFullFeatures
                            className="import-table"
                            tableName={`map_${this.props.mapId}`}
                            tableHeader={tableHeader}
                            tableContent={tableValues}
                        />
                        </div>
                    )}
            </div>
        );
    }
}

export default MapElementWrapper;
