import axios from "./ServerConnection";

export async function setStripeKeysApi(
    publicKey: string,
    secretKey: string
): Promise<void> {
    let requestJson = {
        public: publicKey,
        secret: secretKey,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/set_stripe_keys", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function checkStripeKeysApi(): Promise<boolean> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            keys_exist?: boolean;
        }>("/api/check_stripe_keys")
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.keys_exist ?? false);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function getStripePublicKeyApi(): Promise<string | null> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            public: string | null;
        }>("/api/get_stripe_public_key")
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.public);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}
