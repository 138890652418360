import React from "react";
import cx from "classnames";
import { Button } from "react-bootstrap";
import {
    MapFinding,
    ChoroplethLevel,
    choroplethLevelLabel,
} from "common/Finding";
import EditInput from "../../EditInput";
import styles from "./ChoroplethDataTable.module.css";
import commonStyles from "../../DataSection.module.css";
import countryData from "common/countries.json";
import statesUsData from "common/states_us.json";

const countries = Object.keys(countryData);
const states_us = Object.keys(statesUsData);

interface Props {
    mapFinding: MapFinding;
    onChange: (finding: MapFinding, updateData?: boolean) => void;
    currentModuleId?: number;
    style?: React.CSSProperties;
}

function ChoroplethDataTable(props: Props): JSX.Element {
    let cols = 2;
    return (
        <div className="my-row" style={props.style}>
            <div
                className={styles.dataContainer}
                style={{
                    width: "calc(100% - 30px)",
                    gridTemplateColumns: `repeat(${cols}, calc(100%/${cols}))`,
                }}
            >
                <div className={commonStyles.header}>
                    {
                        choroplethLevelLabel[
                            (props.mapFinding.config.choroplethLevel ??
                                "country") as ChoroplethLevel
                        ]
                    }
                </div>
                <div className={commonStyles.header}>Metric</div>
                {props.mapFinding.content.choroplethData?.map((item, index) => (
                    <>
                        <EditInput
                            showDeleteButton={false}
                            disabled={false}
                            value={item.country ?? ""}
                            onChange={(value) => {
                                let finding: MapFinding = {
                                    ...props.mapFinding,
                                    content: {
                                        ...props.mapFinding.content,
                                        choroplethData: [
                                            ...(props.mapFinding.content
                                                .choroplethData ?? []),
                                        ],
                                    },
                                };
                                finding.content.choroplethData![index] = {
                                    ...finding.content.choroplethData![index],
                                    country: value as string,
                                };
                                props.onChange(finding);
                            }}
                        />
                        <EditInput
                            disabled={false}
                            showDeleteButton={true}
                            value={item.metric ?? 0}
                            onChange={(value) => {
                                let finding: MapFinding = {
                                    ...props.mapFinding,
                                    content: {
                                        ...props.mapFinding.content,
                                        choroplethData: [
                                            ...(props.mapFinding.content
                                                .choroplethData ?? []),
                                        ],
                                    },
                                };
                                finding.content.choroplethData![index] = {
                                    ...finding.content.choroplethData![index],
                                    metric: Number(value),
                                };
                                props.onChange(finding);
                            }}
                            onDelete={() => {
                                let finding: MapFinding = {
                                    ...props.mapFinding,
                                    content: {
                                        ...props.mapFinding.content,
                                        choroplethData: [
                                            ...(props.mapFinding.content
                                                .choroplethData ?? []),
                                        ],
                                    },
                                };
                                finding.content.choroplethData!.splice(
                                    index,
                                    1
                                );
                                props.onChange(finding);
                            }}
                        />
                    </>
                ))}
            </div>
            <Button
                className={cx(
                    "btn btn-sm btn-primary my-primary",
                    commonStyles.addColumnButton
                )}
                onClick={() => {
                    let finding: MapFinding = {
                        ...props.mapFinding,
                        content: {
                            ...props.mapFinding.content,
                            choroplethData: [
                                ...(props.mapFinding.content.choroplethData ??
                                    []),
                            ],
                        },
                    };
                    let country: string;
                    switch (props.mapFinding.config.choroplethLevel) {
                        case "state_us":
                            country =
                                states_us[
                                    Math.floor(Math.random() * countries.length)
                                ];
                            break;
                        case "zipcode_us":
                            country = Math.floor(
                                Math.random() * (14925 - 10001) + 10001
                            ).toString();
                            break;
                        case "county_us":
                            country = "";
                            break;
                        default:
                            country =
                                countries[
                                    Math.floor(Math.random() * countries.length)
                                ];
                            break;
                    }
                    finding.content.choroplethData!.push({
                        country: country,
                        metric: 1,
                    });
                    props.onChange(finding);
                }}
            >
                {"\uFF0B"}
            </Button>
        </div>
    );
}

export default ChoroplethDataTable;
