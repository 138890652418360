import React from "react";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";

function PredictBlock(props) {
	let { name, unit, value, predictInterval, sampleSize, baseColor } = props;

	let title = `${name.toUpperCase()}`;

	let formattedValue = formatValue(value, false);

	baseColor = baseColor ?? "#05C985";
	return (
		<div className="flex-column">
			<span
				style={{
					fontFamily: "Arial",
					fontSize: "10px",
					color: mainStyle.getPropertyValue("--graphs-axes-text-color"),
					letterSpacing: "0.7px",
				}}
			>
				{title}
			</span>
			<div
				className="my-row"
				style={{
					marginTop: 5,
					justifyContent: "space-between",
				}}
			>
				<span
					style={{
						fontFamily: "Roboto",
						fontSize: "12px",
						color: baseColor,
						lineHeight: "14px",
					}}
				>
					{unit}
				</span>
				<span
					title="Click to Recenter Scale."
					style={{
						cursor: "pointer",
						fontFamily: "Arial",
						fontSize: "50px",
						color: baseColor,
						lineHeight: "40px",
					}}
					onClick={props.onCenterItemClick}
				>
					{formattedValue[0]}
				</span>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "middle",
						justifyContent: "space-between",
					}}
				>
					<span
						style={{
							textAlign: "center",
							fontFamily: "Roboto",
							fontSize: "12px",
							lineHeight: "12px",
							color: baseColor,
						}}
					>
						{formattedValue[1]}
					</span>
				</div>
			</div>
			<div
				className="my-row"
				style={{ marginTop: "5px", justifyContent: "space-between" }}
			>
				<span
					style={{
						marginTop: 5,
						marginLeft: 5,
						fontFamily: "Roboto",
						fontSize: "12px",
						color: mainStyle.getPropertyValue("--exploration-tertiary-text-color"),
					}}
				>
					Sample Size
				</span>
				<span
					style={{
						marginTop: 5,
						marginLeft: 5,
						fontFamily: "Roboto",
						fontSize: "12px",
						color: baseColor,
					}}
				>
					{sampleSize}
				</span>
			</div>
			<span
				style={{
					marginTop: 5,
					marginLeft: 5,
					fontFamily: "Roboto",
					fontSize: "12px",
					color: mainStyle.getPropertyValue("--exploration-tertiary-text-color"),
				}}
			>
				Pred. Interval
			</span>
			<span
				style={{
					marginTop: 5,
					marginLeft: 5,
					fontFamily: "Roboto",
					fontSize: "16px",
					color: baseColor,
					fontWeight: 400,
				}}
			>
				{`[${formatValue(predictInterval[0])[0]}, ${
					formatValue(predictInterval[1])[0]
				}]`}
			</span>
		</div>
	);
}

export default PredictBlock;