import React from "react";
import { observer } from "mobx-react";
import Tables, { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import styles from "./SliceSection.module.css";
import  { PopupStatus } from "common/StatusPopup";
import Variables from "common/Variables";
import { NewVariable } from "common/VariableCreator";
import { addVariable } from "common/DataApi";
import Select, { createFilter } from "react-select";
import {
    getCustomSelectStyleForDataSection,
    leftAlignAndScrollBarCSS,
    Styles,
} from "common/SelectStyles";
import { CleanOption, cleanOptions, delimeters, options, presets } from "./SliceApi";
import {  PreviewHeader } from "../MergeSection";
import SelectedOption from "../SelectedOption";


interface props {
    dataset?: DataScopeOption | null;
    selectedVariable: PreviewHeader;
    showClean: any;
    setStatusPopup: any;
    table: TableOption | null;
}

function SliceSection(props: props) {

    const makeAction = async (
        table: TableOption,
        creatorCode: string | null,
        genericVariables: NewVariable[],
        onSuccess: (
            preview:
                | {
                    tableHeader: string[];
                    tablePreview: any[];
                    copyDataScopeId?: number;
                }
                | undefined
        ) => void,
        onError: (error: string) => void,
        preview: boolean = false,
        rowLevel: boolean = false,
    ) => {
        await addVariable(
            table!,
            creatorCode,
            genericVariables,
            (previewData) => {
                props.setStatusPopup({
                    status: PopupStatus.Success,
                    message: "Success",
                });
                Tables(table.data_table_idx!).update();
                Variables(table.data_table_idx!).update();
            },
            (errorMessage: string) => {
                props.setStatusPopup({
                    status: PopupStatus.Error,
                    message: errorMessage,
                });
            },
            preview,
            rowLevel, undefined, true
        );

        DataScopes.previewState = { headers: null, body: null }

        if (props.dataset?.value != null) {
            await DataScopes.DatasetPreview(props.dataset?.value)
        }
    }

    const executeCreateVariables = (
        table: TableOption,
        creatorCode: string,
        genericVariables: NewVariable[],
        rowLevel: boolean,
        preview: boolean,
    ) => {
        props.setStatusPopup({
            status: PopupStatus.Success,
            message: "Generating data...",
        });
        makeAction(
            table!,
            creatorCode,
            genericVariables,
            (previewData) => {
                props.setStatusPopup({
                    status: PopupStatus.Success,
                    message: "Success",
                });
                Tables(table.data_table_idx!).update();
                Variables(table.data_table_idx!).update();
            },
            (errorMessage: string) => {
                props.setStatusPopup({
                    status: PopupStatus.Error,
                    message: errorMessage,
                });
            },
            preview,
            rowLevel
        );


    };
    let [selectedOption, setSelectedOption] = React.useState<CleanOption>(
        cleanOptions[0]
    );
    let [creatorCode, setCreatorCode] = React.useState<string>(presets[cleanOptions[0].value](props.selectedVariable.name, ""));
    let [showDelimeterInput, setShowDelimeterInput] = React.useState<boolean>(false);



    let optionStyles: Styles = {
        ...getCustomSelectStyleForDataSection(14, false),
        ...leftAlignAndScrollBarCSS,
        container: (base) => ({
            ...base,
            height: "26px",
            width: "100%",
            borderRadius: 4
        }),
        control: (base => ({
            ...base,
            minHeight: 26,
            height: 28,
            borderRadius: 4,
        })),
        valueContainer: (base => ({
            ...base,
            padding: "0px 0px !important",
        })),
        singleValue: (base => ({
            ...base,
            fontSize: 14,
            paddingLeft: 5,
            color: '#555',
            opacity: '80%'
        })),
        input:(base=>({
            ...base,
            paddingLeft:5,
            paddingTop:0
        })),
    };



    return (
        <div className={styles.mainContainer}>
            <div className={styles.variablesContainer}>
                <div className={"textItem"} style={{ marginBottom: 0 }}>Operation</div>
                <Select
                    menuShouldBlockScroll={false}
                    onKeyDown={(evt) => { evt.stopPropagation(); }}
                    filterOption={createFilter({ ignoreAccents: false, })}
                    placeholder={"Select Operation"}
                    styles={optionStyles}
                    options={cleanOptions}
                    onChange={(newValue) => {
                        setSelectedOption(newValue as CleanOption);
                        setCreatorCode(
                            presets[(newValue as CleanOption).value](
                                props.selectedVariable?.name ?? "variable", (newValue as CleanOption).value === 4 ? "," : ""
                            )
                        );
                        setShowDelimeterInput(false)
                    }}
                    value={selectedOption}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />

                {
                    selectedOption.label === "Split By Delimiter" &&
                    <Select
                        options={delimeters}
                        defaultValue={delimeters[0]}
                        styles={optionStyles}
                        onChange={(newValue) => {
                            if ((newValue as options).label === 'Other') {
                                setShowDelimeterInput(true)
                            } else {
                                setShowDelimeterInput(false)
                                setCreatorCode(presets[selectedOption.value](
                                    props.selectedVariable?.name, (newValue as options).label
                                ))
                            }
                        }}
                    // value={delimeters[0]}
                    />
                }
                {
                    (selectedOption.label === "Add Prefix" || selectedOption.label === "Add Suffix" || showDelimeterInput) &&
                    <div className={`${styles.decimalContainer} hideIcon`} >
                        <input
                            className="like-select"
                            style={{
                                height: "26px",
                                fontFamily: "roboto",
                                textAlign: "left",
                                border: '1px solid #ccc',
                                color: "#555",
                                padding: "0 0 0 6px",
                                minHeight: 26,
                                width: "100%"
                            }}
                            type="text"
                            placeholder={selectedOption.label === "Add Prefix" ? "Type your Prefix" : selectedOption.label === "Add Suffix" ? "Type your Suffix" : ""}
                            onChange={(e) => {
                                let value = e.target.value;
                                setCreatorCode(presets[selectedOption.value](
                                    props.selectedVariable?.name, value
                                ))
                            }}
                        />
                    </div>
                }

                <button
                    className={"acceptBtn"}
                    // className={cx(styles.bottomBarButton, "unselectable")}
                    type="button"
                    onClick={() => {
                        executeCreateVariables(
                            props.table!,
                            creatorCode,
                            [],
                            false,
                            false
                        );
                        props.showClean(null);
                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(true);
                    }}
                >
                    {"Create"}
                </button>
            </div>

        </div>
    );
}

export default observer(SliceSection);
