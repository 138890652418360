import axios from "common/ServerConnection";


//These keys are using now not for maps only, but for any google service.
export async function setGeodataKeyApi(
    secretKey: string
): Promise<void> {
    let requestJson = {
        secret: secretKey,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/set_geodata_key", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function checkGeodataKeyApi(): Promise<boolean> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            key_exists?: boolean;
        }>("/api/check_geodata_key")
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.key_exists ?? false);
            } else {
                console.log(response.data.error_msg);
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}
