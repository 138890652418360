// adapted from https://github.com/tajo/react-portal/blob/55ed77ab823b03d1d4c45b950ba26ea5d687e85c/src/LegacyPortal.js

import React from "react";
import ReactDOM from "react-dom";

interface Props {
    rootNode: Element | undefined | null;
}

export default class Portal extends React.Component<Props> {
    private defaultNode: Element | null = null;

    public componentDidMount(): void {
        this.renderPortal();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.rootNode !== this.props.rootNode) {
            this.destroyPortal(prevProps);
        }
        this.renderPortal();
    }

    private destroyPortal(props: Props): void {
        if (this.defaultNode != null) {
            ReactDOM.unmountComponentAtNode(this.defaultNode);
            props.rootNode?.removeChild(this.defaultNode);
        }
        this.defaultNode = null;
    }

    public componentWillUnmount(): void {
        this.destroyPortal(this.props);
    }

    private renderPortal(): void {
        if (this.defaultNode == null && this.props.rootNode != null) {
            this.defaultNode = document.createElement("div");
            this.props.rootNode.appendChild(this.defaultNode);
        }
        let children = this.props.children;
        // https://gist.github.com/jimfb/d99e0678e9da715ccf6454961ef04d1b
        if (
            React.isValidElement(children) &&
            typeof children.type === "function"
        ) {
            children = React.cloneElement(children);
        }

        ReactDOM.render(children as JSX.Element, this.defaultNode);
    }

    render() {
        return null;
    }
}
