import React from "react";

export function MySvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      {...props}
    >
      <defs>
        <path
          id="path-1-recipes"
          fillRule="evenodd"
          d="M14 0l2.29 2.29-4.88 4.88-4-4L0 10.59 1.41 12l6-6 4 4 6.3-6.29L20 6V0h-6z"
        />
        <mask
          id="mask-2-recipes"
          maskContentUnits="userSpaceOnUse"
          maskUnits="userSpaceOnUse"
        >
          <rect width="20" height="12" x="0" y="0" fill="black" />
          <use fill="white" xlinkHref="#path-1-recipes" />
        </mask>
      </defs>
      <g>
        <use xlinkHref="#path-1-recipes" />
        <g mask="url(#mask-2-recipes)">
          <rect
            width="78"
            height="78"
            x="0"
            y="0"
            fill="transparent"
            rx="0"
            ry="0"
            transform="translate(-2 -6)"
          />
        </g>
      </g>
    </svg>
  );
}