import { Options as WordCloudOptions } from "react-wordcloud";
import { range } from "d3-array";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { GeoJsonObject } from "geojson";
import { DataScopeOption } from "common/DataScopes";
import { Condition } from "./Conditions";
import {
    StatusBarExpression,
    MapDataVariableOption,
    MapTooltipDisplayModeOption,
    MapVariableOption,
    MapColorOptions,
    MapVersion,
    LeadersLaggersFontStyle,
    TimeAnimationSliderPosition,
    NumberFormat,
    GanttChartDateIntervalType,
    MapVariableViewOptions,
    ColumnFormat,
} from "./Canvas";
import { TooltipConfig, TooltipOptions } from "common/ConfigurableTooltip";
import modules from "modules/data_exploration_page/modules/module_list.json";
import { SearchComponentOption } from "./RevampedSearchComponent";
import StringOption from "common/StringOption";
import { TableOption } from "./Tables";
import ViewOption from "common/graphics/ViewOption";
import { displayModeToDiffType } from "./graphics/TimeChartUtils";
import { MapTooltipDisplayMode } from "common/Canvas";
import { VariableOption } from "common/Variables";
import _ from "lodash";
import { OptionType } from "./DateTimeFormatSelect";

export default interface Finding {
    type: string;
    content: any;
    config:
        | (any & {
              version: number;
              selectedTable?: TableOption;
              dataScope?: DataScopeOption | null;
              journeyName: string;
              conditions?: Condition[] | null;
              statusExpressions?: StatusBarExpression[] | null;
              tooltipColor?: string;
              tooltipFontSize?: number;
              sideBySideVariableIndex?: number;
              sideBySideVariable?: string;
              additionalOperators?: StringOption[];
              additionalValues?: (SearchComponentOption | null)[];
          })
        | null;
}

export enum TickLabelOrientation {
    Horizonal = 0,
    Slanted = 1,
    Vertical = 2,
}

export interface TickLabelOrientationOption {
    label: string;
    value: TickLabelOrientation;
}

export const tickLabelOrientationOptions: ReadonlyArray<TickLabelOrientationOption> = [
    {
        label: "Horizonal",
        value: TickLabelOrientation.Horizonal,
    },
    {
        label: "Slanted",
        value: TickLabelOrientation.Slanted,
    },
    {
        label: "Vertical",
        value: TickLabelOrientation.Vertical,
    },
];

export interface TicksAndLabels {
    x: {
        size: number;
        interval: number;
        intervalUnit?: moment.unitOfTime.DurationConstructor;
        labelOrientation?: TickLabelOrientation;
    };
    y: {
        size: number;
        interval: number;
        intervalUnit?: moment.unitOfTime.DurationConstructor;
        labelOrientation?: TickLabelOrientation;
    };
    labels: {
        size: number;
    };
}

export enum LineType {
    Solid = 0,
    Dashed = 1,
}

export interface LineTypeOption {
    label: string;
    value: LineType;
}

export const lineTypeOptions: ReadonlyArray<LineTypeOption> = [
    {
        label: "Solid",
        value: LineType.Solid,
    },
    {
        label: "Dashed",
        value: LineType.Dashed,
    },
];

export enum AreaType {
    None = 0,
    Fill = 1,
    Gradient = 2,
}

export interface AreaTypeOption {
    label: string;
    value: AreaType;
}

export const areaTypeOptions: ReadonlyArray<AreaTypeOption> = [
    {
        label: "No Area",
        value: AreaType.None,
    },
    {
        label: "Fill Area",
        value: AreaType.Fill,
    },
    {
        label: "Gradient Area",
        value: AreaType.Gradient,
    },
];

export interface DateTimeIntervalOption {
    label: string;
    value: moment.unitOfTime.DurationConstructor;
}

export const dateTimeIntervalOptions: ReadonlyArray<DateTimeIntervalOption> = [
    {
        label: "Year",
        value: "year",
    },
    {
        label: "Month",
        value: "month",
    },
    {
        label: "Day",
        value: "day",
    },
    {
        label: "Hour",
        value: "hour",
    },
    {
        label: "Minute",
        value: "minute",
    },
];

export interface BarChartFinding extends Finding {
    config: Finding["config"] & {
        operationVariable?: string;
        barSize?: number;
        barGap?: number;
        barCategoryGap?: number;
        axesNamesColor?: string;
        ticksColor?: string;
        maxYRange?: number;
        minYRange?: number;
        linesCount?: number;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        sortYAxis?: boolean;
        yAxisSortType?: string;
        nameColorMapping?: { [key: string]: string };
        groupByAll?: boolean;
        stacked?: boolean;
        showLegend?: boolean;
        showAsDots?: boolean;
        ticksFontSize?: number;
        legendSize?: number;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        lineColor?: string;
        dotRadius?: number;
        dashLine?: boolean;
        transpose?: boolean;
        flip?: boolean;
        columnByVariableIndex?: number | null;
        columnByVariable?: string | null;
    };
    content: {
        groupNames?: string[];
        groupInfo?: {
            name: string;
            operation: string;
            value: string;
        }[];
        data: {
            name: string;
            value: number | number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        varyByData?:
            | {
                  name: string;
                  value: number | number[];
                  variableIndex?: number;
                  originalName?: string;
              }[]
            | null;
        varyByIndices?: number[] | null; // For reordering
        // For backward compatibility we are adding separate field
        additionalVaryByData?:
            | {
                  name: string;
                  value: number | number[];
                  variableIndex?: number;
                  originalName?: string;
              }[][]
            | null;
        horizontalLines?: ({
            name: string;
            value: number;
            variableIndex?: number;
            originalName?: string;
        } | null)[];
    };
}

export interface FunnelPlotFinding extends Finding {
    config: Finding["config"] & {
        labelsColor?: string;
        valuesColor?: string;
        funnelColor?: string;
        labelsFontSize?: number;
        valuesFontSize?: number;
    };
    content: {
        data: {
            name: string;
            value: (number | string)[];
            variableIndex?: number;
            originalName?: string;
        }[];
    };
}

interface EffectCurve {
    x: number[];
    y: number[];
    y_marginal: number[];
}

interface EffectCurveMean {
    x: number;
    y: number;
    y_marginal: number;
    z?: number;
}

export enum LeversOutcomeType {
    Bars = 1,
    Curve = 2,
    MarginalCurve = 3,
    Raw = 4,
}

export interface LeversOutcomePredictionFinding extends Finding {
    loading?: boolean;
    config: Finding["config"] & {
        dependentVariable?: StringOption;
        dvLog: boolean;
        independentVariables: {
            [key: number]: {
                label: string;
                value: number;
                log: boolean;
                fixed: boolean;
                show: boolean;
                sig: boolean;
                main?: boolean;
                interact_with?: boolean;
            } | null;
        };
        intercept?: {
            show: boolean;
            sig: boolean;
        } | null;
        selectedRegression?: string;
        selectedFamily?: string | null;
        selectedLink?: string | null;
        outputType: LeversOutcomeType;
        hideOutputTypeChange?: boolean;
        ticksColor?: string;
        ticksSize?: number;
        maxYRange?: number;
        minYRange?: number;
        showXAxisName: boolean;
        showYAxisName: boolean;
        axesNamesColor?: string;
        axesLinesColor?: string;
        cacheData: boolean;
        legendSize?: number;
        showLegend?: boolean;
        showModel?: boolean;
    };
    content: {
        groupNames?: string[] | null;
        nativeData?: any;
        regressionInfo?: {
            dv: string | undefined;
            dv_log: boolean;
            iv: {
                main: string;
                main_fixed: boolean;
                main_log: boolean;
                interacts_with?: string | null;
                interacts_with_log?: boolean | null;
                other: string[];
                other_log: boolean[];
                other_fixed: boolean[];
            };
            model: {
                name: string;
                family?: string | null;
                link?: string | null;
            };
            intercept: boolean;
            predict_interval: {
                count: number;
                fix?: {
                    iv: string;
                    percentiles: number[];
                } | null;
            };
        };
        data: {
            regressionBars: {
                name: string;
                show: boolean;
                coefficient: number;
                significance: number;
            }[];
            summary: string;
            regressionCurves: {
                effectCurve?: EffectCurve;
                effectCurveLow?: EffectCurve;
                effectCurveHigh?: EffectCurve;
                effectCurveMean?: EffectCurveMean;
            };
        }[];
    };
}

export const defaultAiCopilotTitle = "GENERATIVE CONVERSATIONAL AI";
export const defaultAiCopilotQuestionOne = "Describe this dataset";
export const defaultAiCopilotQuestionTwo = "What was maximum sales?";
export const defaultAiCopilotQuestionThree = "Who had minimum sales?";
export const defaultAiCopilotBackgroundColor = "white";
export const defaultAiCopilotBannerBgColor = "#333333";
export const defaultAiCopilotBotMsgBgColor = "#D8EBFF80";
export const defaultAiCopilotCustomFirstMessageBtnColor = "#00000010";

export interface AiCopilotFinding extends Finding {
    config: Finding["config"] & {
        title?: string | null;
        questionOne?: string | null;
        questionTwo?: string | null;
        questionThree?: string | null;
        backgroundColor?: string | null;
        bannerBgColor?: string | null;
        botMsgBgColor?: string | null;
        customFirstMessageBtnColor?: string | null;
    };
    content: {};
}

export interface HoldOutPredictionFinding extends Finding {
    config: Finding["config"] & {
        dependentVariable?: StringOption;
        dvLog: boolean;
        independentVariables: {
            [key: number]: {
                label: string;
                value: number;
                log: boolean;
                fixed: boolean;
                show: boolean;
            } | null;
        };
        interactions: { [key: number]: number | null };
        intercept: boolean;
        selectedRegression?: string;
        selectedFamily?: string | null;
        selectedLink?: string | null;
        cacheData: boolean;
        datasetFraction: number;
        confidenceLevel: number;
        showLeverBars: boolean;
        showTrueValue: boolean;
        ticksSize?: number;
        ticksColor?: string;
        axesNamesColor?: string;
        axesLinesColor?: string;
        legendSize?: number;
        showLegend?: boolean;
        showModel?: boolean;
    };
    content: {
        groupNames?: string[] | null;
        nativeData?: any;
        regressionInfo?: {
            dv: string | undefined;
            dv_log: boolean;
            iv: {
                main: string;
                main_fixed: boolean;
                main_log: boolean;
                other: string[];
                other_log: boolean[];
                other_fixed: boolean[];
                interactions: {
                    var1: string;
                    var2: string;
                }[];
            };
            model: {
                name: string;
                family?: string | null;
                link?: string | null;
            };
            intercept: boolean;
            predict_split: {
                train_size: number;
                confidence_level: number;
            };
        };
        data: {
            userValues?: {
                [key: number]: {
                    [key: string]: number | string;
                };
            } | null;
            ivMainNames: string[];
            ivOtherNames: string[];
            predictedBars: {
                confidence_interval: number[];
                chart_interval?: number[] | null;
                max_interval?: number | null;
                confidence_level: number;
                mean_iv: number[];
                mean_predicted: number;
                mean_true: number;
                show_intervals: boolean;
                model_id: number;
                sample_size: number;
            }[];
        };
    };
}

export interface GanttChartFilter {
    date?: {
        start: string;
        end: string;
    };
    dateIntervalType?: {
        label: string;
        value: number;
    };
}

export const defaultGanttChartFilter = {
    date: {
        start: "",
        end: "",
    },
    dateIntervalType: {
        label: "Day",
        value: GanttChartDateIntervalType.Day,
    },
};

export interface GanttChartFinding extends Finding {
    config: Finding["config"] & {
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        axesNamesColor?: string;
        axesLinesColor?: string;
        ticksColor?: string;
        labelsColor?: string;
        colorVariable?: {
            label: string;
            value: number;
        };
        ticksSize?: number;
        displayMode?: keyof typeof displayModeToDiffType | "Date";
        nameColorMapping?: { [key: string]: string };
        showCurrentDateLine?: boolean;
        filter?: GanttChartFilter;
    };
    content: {
        colorBy?: (number | string)[] | null;
        data: {
            name: string;
            value: (number | string)[];
            variableIndex?: number;
            originalName?: string;
        }[];
    };
}

export function getValue(
    item: {
        value: number | number[] | null;
    },
    index: number
): number {
    if (item?.value == null) return 0;
    else if (typeof item.value === "number") return item.value;
    else return item.value[index];
}

export function formatValues(
    value: string | number | number[] | number[][] | null
): string {
    if (value == null) return "0";
    else if (typeof value === "string") return value;
    else if (typeof value === "number") return value.toFixed(2);
    else
        return value
            .flat()
            .map((valueItem) =>
                typeof valueItem === "number" ? valueItem.toFixed(2) : valueItem
            )
            .join(",");
}

export interface PieChartFinding extends Finding {
    config: Finding["config"] & {
        operationVariable?: string;
        nameColorMapping?: { [key: string]: string };
        ticksColor?: string;
        innerRadius: number;
        outerRadius: number;
        showLegend?: boolean;
        showVariableLabels?: boolean;
        showLabels?: boolean;
        labelValuesType?: string;
        legendSize?: number;
        columnByVariableIndex?: number | null;
        columnByVariable?: string | null;
        showTotals?: boolean;
    };
    content: {
        groupNames?: string[];
        data: {
            name: string;
            value: number | number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        varyByData?:
            | {
                  name: string;
                  value: number | number[];
                  variableIndex?: number;
                  originalName?: string;
              }[]
            | null;
        // For backward compatibility we are adding separate field
        additionalVaryByData?:
            | {
                  name: string;
                  value: number | number[];
                  variableIndex?: number;
                  originalName?: string;
              }[][]
            | null;
    };
}

export interface TreemapFinding extends Finding {
    config: Finding["config"] & {
        operationVariable?: string;
        nameColorMapping?: { [key: string]: string };
        ticksColor?: string;
        innerRadius: number;
        outerRadius: number;
        showLegend?: boolean;
        showVariableLabels?: boolean;
        showLabels?: boolean;
        showLabelInBoxes?: boolean;
        labelValuesType?: string;
        legendSize?: number;
        titleSize?: number;
        labelSize?: number;
        columnByVariableIndex?: number | null;
        columnByVariable?: string | null;
    };
    content: {
        groupNames?: string[];
        data: {
            name: string;
            value: number | number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        varyByData?:
            | {
                  name: string;
                  value: number | number[];
                  variableIndex?: number;
                  originalName?: string;
              }[]
            | null;
        // For backward compatibility we are adding separate field
        additionalVaryByData?:
            | {
                  name: string;
                  value: number | number[];
                  variableIndex?: number;
                  originalName?: string;
              }[][]
            | null;
    };
}

export interface RadarFinding extends Finding {
    config: Finding["config"] & {
        operationVariable?: string;
        nameColorMapping?: { [key: string]: string };
        ticksColor?: string;
        roundStrokes?: boolean;
        showLegend?: boolean;
        showVariableLabels?: boolean;
        showLabels?: boolean;
        labelValuesType?: string;
        legendSize?: number;
        columnByVariableIndex?: number | null;
        columnByVariable?: string | null;
        linear?: boolean;
    };
    content: {
        groupNames?: string[];
        data: {
            name: string;
            value: number | number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        varyByData?:
            | {
                  name: string;
                  value: number | number[];
                  variableIndex?: number;
                  originalName?: string;
              }[]
            | null;
        // For backward compatibility we are adding separate field
        additionalVaryByData?:
            | {
                  name: string;
                  value: number | number[];
                  variableIndex?: number;
                  originalName?: string;
              }[][]
            | null;
    };
}

export const defaultDendrogramEdgeColor = "#CCCCCC";
export const defaultDendrogramNodeRadius = 7;

export interface DendrogramFinding extends Finding {
    config: Finding["config"] & {
        operationVariable?: string;
        nameColorMapping?: { [key: string]: string };
        ticksColor?: string;
        innerRadius: number;
        outerRadius: number;
        showLegend?: boolean;
        showVariableLabels?: boolean;
        showLabels?: boolean;
        labelValuesType?: string;
        legendSize?: number;
        columnByVariableIndex?: number | null;
        columnByVariable?: string | null;
        edgeColor?: string;
        nodeRadius?: number;
    };
    content: {
        groupNames?: string[];
        data: {
            name: string;
            value: number | number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        varyByData?:
            | {
                  name: string;
                  value: number | number[];
                  variableIndex?: number;
                  originalName?: string;
              }[]
            | null;
        // For backward compatibility we are adding separate field
        additionalVaryByData?:
            | {
                  name: string;
                  value: number | number[];
                  variableIndex?: number;
                  originalName?: string;
              }[][]
            | null;
    };
}

export interface ScatterPlotFinding extends Finding {
    config: Finding["config"] & {
        maxYRange?: number;
        minYRange?: number;
        maxXRange?: number;
        minXRange?: number;
        linesCount?: number;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        count: number;
        trendline?: boolean;
        random: boolean;
        trendlineColor?: string;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        showGrid?: boolean;
    };
    content: {
        trendlineInfo?: {
            coef: number;
            intercept: number;
        } | null;
        data: {
            name: string;
            value: number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        time?: {
            name: string;
            value: (string | number | null)[];
            uniqueValues: (string | number)[];
            variableIndex?: number | null;
            originalName?: string | null;
        };
    };
}

export const defaultBubbleD3MinRadius = 10;
export const defaultBubbleD3MaxRadius = 30;
export const defaultBubbleBoundariesColor = "gray";

export interface BubbleFinding extends Finding {
    config: Finding["config"] & {
        maxYRange?: number;
        minYRange?: number;
        maxXRange?: number;
        minXRange?: number;
        linesCount?: number;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        count: number;
        trendline?: boolean;
        random: boolean;
        trendlineColor?: string;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        showGrid?: boolean;
        minRadius?: number;
        maxRadius?: number;
        showBoundaries?: boolean;
        boundariesColor?: string;
    };
    content: {
        trendlineInfo?: {
            coef: number;
            intercept: number;
        } | null;
        data: {
            name: string;
            value: number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        time?: {
            name: string;
            value: (string | number | null)[];
            uniqueValues: (string | number)[];
            variableIndex?: number | null;
            originalName?: string | null;
        };
    };
}

export const defaultDensity2dBinSize = 18;
export const defaultDensity2dMinColor = "transparent";
export const defaultDensity2dMaxColor = "#69B3A2";
export type Density2dType =
    | "hexagonal"
    | "rectangular"
    | "contour"
    | "contour_shade";
export const defaultDensity2dType: Density2dType = "hexagonal";
export interface Density2dTypeOption {
    label: string;
    value: Density2dType;
}
export const density2dTypeOptions = [
    {
        label: "Hexagonal",
        value: "hexagonal",
    },
    {
        label: "Rectangular",
        value: "rectangular",
    },
    {
        label: "Contour",
        value: "contour",
    },
    {
        label: "Contour with shade",
        value: "contour_shade",
    },
];

export interface Density2dFinding extends Finding {
    config: Finding["config"] & {
        maxYRange?: number;
        minYRange?: number;
        maxXRange?: number;
        minXRange?: number;
        linesCount?: number;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        count: number;
        trendline?: boolean;
        random: boolean;
        trendlineColor?: string;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        showGrid?: boolean;
        binSize?: number;
        minColor?: string;
        maxColor?: string;
        minValue?: number;
        maxValue?: number;
        density2dType?: Density2dType;
    };
    content: {
        trendlineInfo?: {
            coef: number;
            intercept: number;
        } | null;
        data: {
            name: string;
            value: number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        time?: {
            name: string;
            value: (string | number | null)[];
            uniqueValues: (string | number)[];
            variableIndex?: number | null;
            originalName?: string | null;
        };
    };
}

export const defaultCorrelogramOneColor = "#000080";
export const defaultCorrelogramZeroColor = "#BBBBBB";
export const defaultCorrelogramMinusOneColor = "#B22222";
export const defaultCorrelogramVariableNamesColor = "#55697D";
export const defaultCorrelogramFontSize = 11;
export const defaultCorrelogramMinRadius = 0;
export const defaultCorrelogramMaxRadius = 9;

export interface CorrelogramFinding extends Finding {
    config: Finding["config"] & {
        oneColor?: string;
        zeroColor?: string;
        minusOneColor?: string;
        variableNamesColor?: string;
        fontSize?: number;
        minRadius?: number;
        maxRadius?: number;
    };
    content: {
        data: {
            name: string;
            value: number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        time?: {
            name: string;
            value: (string | number | null)[];
            uniqueValues: (string | number)[];
            variableIndex?: number | null;
            originalName?: string | null;
        };
    };
}

export const defaultHeatmapD3MinColor = "transparent";
export const defaultHeatmapD3MaxColor = "#69B3A2";

export interface HeatmapFinding extends Finding {
    config: Finding["config"] & {
        maxYRange?: number;
        minYRange?: number;
        maxXRange?: number;
        minXRange?: number;
        linesCount?: number;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        trendlineColor?: string;
        ticksSize?: number;
        minColor?: string;
        maxColor?: string;
        minValue?: number;
        maxValue?: number;
    };
    content: {
        data: {
            name: string;
            value: (string | number | null)[];
            variableIndex?: number;
            originalName?: string;
        }[];
        time?: {
            name: string;
            value: (string | number | null)[];
            uniqueValues: (string | number)[];
            variableIndex?: number | null;
            originalName?: string | null;
        };
    };
}

export interface HistogramFinding extends Finding {
    config: Finding["config"] & {
        maxYRange?: number;
        maxXRange?: number;
        minXRange?: number;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        showGrid?: boolean;
        bins?: number;
        axesNamesColor?: string;
        ticksColor?: string;
    };
    content: {
        isDateTime?: boolean;
        groupNames?: string[];
        data: {
            name: string;
            value: number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        horizontalLines?: ({
            name: string;
            value: number;
            variableIndex?: number;
            originalName?: string;
        } | null)[];
    };
}

export const defaultDensityChartColor = "#69B3A2";
export const defaultDensityStrokeColor = "#000000";

export interface DensityPlotFinding extends Finding {
    config: Finding["config"] & {
        axesNamesColor?: string;
        ticksColor?: string;
        chartColor?: string;
        strokeColor?: string;
        nameColorMapping?: { [key: string]: string };
        groupByAll?: boolean;
        maxYRange?: number;
        minYRange?: number;
        maxXRange?: number;
        minXRange?: number;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        showGrid?: boolean;
    };
    content: {
        isDateTime?: boolean;
        densityAxisName: string;
        groupNames?: string[];
        isTimeSeries: boolean;
        data: {
            name: string;
            value: number[][];
            variableIndex?: number;
            originalName?: string;
        }[];
    };
}

export const defaultBoxPlotChartColor = "#69B3A2";
export const defaultBoxPlotStrokeColor = "#000000";
export const defaultBoxPlotOutlierCount = 10;

export interface BoxPlotFinding extends Finding {
    config: Finding["config"] & {
        axesNamesColor?: string;
        ticksColor?: string;
        chartColor?: string;
        strokeColor?: string;
        groupByAll?: boolean;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        showGrid?: boolean;
        outlierCount?: number;
    };
    content: {
        data: [
            {
                name: string;
                value: (number | string)[];
                variableIndex?: number;
                originalName?: string;
            },
            {
                name: string;
                value: number[];
                variableIndex?: number;
                originalName?: string;
            }
        ];
        time?: {
            name: string;
            value: (string | number | null)[];
            uniqueValues: (string | number)[];
            variableIndex?: number | null;
            originalName?: string | null;
        };
    };
}

export const defaultViolinPlotChartColor = "#69B3A2";
export const defaultViolinPlotStrokeColor = "#000000";
export const defaultViolinPlotBins = 20;

export interface ViolinPlotFinding extends Finding {
    config: Finding["config"] & {
        axesNamesColor?: string;
        ticksColor?: string;
        chartColor?: string;
        strokeColor?: string;
        groupByAll?: boolean;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        showGrid?: boolean;
        bins?: number;
    };
    content: {
        data: [
            {
                name: string;
                value: (number | string)[];
                variableIndex?: number;
                originalName?: string;
            },
            {
                name: string;
                value: number[];
                variableIndex?: number;
                originalName?: string;
            }
        ];
        time?: {
            name: string;
            value: (string | number | null)[];
            uniqueValues: (string | number)[];
            variableIndex?: number | null;
            originalName?: string | null;
        };
    };
}

export const defaultRidgelineChartColor = "#69B3A2";
export const defaultRidgelineStrokeColor = "#000000";
export const defaultRidgelineBins = 20;

export interface RidgelineFinding extends Finding {
    config: Finding["config"] & {
        axesNamesColor?: string;
        ticksColor?: string;
        chartColor?: string;
        strokeColor?: string;
        groupByAll?: boolean;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        showGrid?: boolean;
        bins?: number;
    };
    content: {
        data: [
            {
                name: string;
                value: number[];
                variableIndex?: number;
                originalName?: string;
            },
            {
                name: string;
                value: (number | string)[];
                variableIndex?: number;
                originalName?: string;
            }
        ];
        time?: {
            name: string;
            value: (string | number | null)[];
            uniqueValues: (string | number)[];
            variableIndex?: number | null;
            originalName?: string | null;
        };
    };
}

export interface LinePlotFinding extends Finding {
    config: Finding["config"] & {
        operationVariable?: string;
        axesNamesColor?: string;
        axesNamesFontFamily?: string;
        ticksColor?: string;
        nameColorMapping?: { [key: string]: string };
        groupNameColorMapping?: { [key: string]: { [key: string]: string } };
        groupByAll?: boolean;
        maxYRange?: number;
        minYRange?: number;
        maxXRange?: number;
        minXRange?: number;
        linesCount?: number;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        count: number;
        showLegend?: boolean;
        dotRadius?: number;
        legendSize?: number;
        random: boolean;
        showGrid: boolean;
        isArea?: boolean;
        stacked?: boolean;
        gradientArea?: boolean;
        lineType?: LineType;
        // Chart index is for side-by-side comparison
        dotColors?: {
            [chartIndex: string]: {
                [lineIndex: string]: { [dotIndex: string]: string };
            };
        };
        formatAxises?: {
            xAxis: NumberFormat;
            xAxisDate: OptionType;
            yAxis: NumberFormat;
        };
        tooltipConfig?: TooltipConfig | null;
    };
    content: {
        groupNames?: string[];
        groupInfo?: {
            name: string;
            operation: string;
            value: string;
        }[];
        isTimeSeries: boolean;
        data: {
            name: string;
            value: (number | string)[][];
            variableIndex?: number;
            originalName?: string;
        }[];
        time?: {
            name: string;
            value: (string | number | null)[];
            uniqueValues: (string | number)[];
            variableIndex?: number | null;
            originalName?: string | null;
        } | null;
    };
}

export interface WordCloudFinding extends Finding {
    config: Finding["config"] & {
        selectedVariableIndex?: number;
        count: number;
        wordLevel: boolean;
        removeStopWords: boolean;
        minimumWordCount: number;
        wordCloudOptions?: Partial<WordCloudOptions>;
        excludedWords: string;
    };
    content: {
        data: {
            text: string;
            value: number;
        }[];
    };
}

export interface SentimentAnalysisFinding extends Finding {
    config: Finding["config"] & {
        showEmotions: boolean;
        selectedVariableIndex?: number;
        barColor?: string | null;
        nameColorMapping?: { [key: string]: string } | null;
        barSize?: number | null;
        barGap?: number | null;
        barCategoryGap?: number | null;
        xAxisName?: string | null;
        yAxisName?: string | null;
        axesNamesColor?: string | null;
        ticksColor?: string | null;
        ticksSize?: number;
        ticksAndLabels?: TicksAndLabels;
        maxYRange?: number | null;
        minYRange?: number | null;
        linesCount?: number | null;
        showXAxisName?: boolean | null;
        showYAxisName?: boolean | null;
    };
    content: {
        data: {
            name: string;
            value: number;
            variableIndex?: number;
            originalName?: string;
        }[];
    };
}

export interface ArrowIndicatorColumn {
    value: number;
    sign: string;
    isAllowed: boolean;
    errorColors: {
        positive: string;
        negative: string;
        neutral: string;
    };
}

interface LeadersLaggersColorOptions {
    borderShadow: string;
}

export interface LeadersLaggersLogosColumnData {
    label: string;
    background: string;
    color: string;
    textLogo: string;
}

export interface LeadersLaggersLegendPosition {
    x: number;
    y: number;
}

export interface LeadersLaggersColumns {
    // Column resizing should always be enabled now
    // enableResizing: boolean;
    width: { [key: string | number]: string | number | null };
}

interface NavigationOptions {
    status: boolean;
    internal: boolean;
}

export interface LeadersLaggersFinding extends Finding {
    config: Finding["config"] & {
        targetVariableSubTitle?: string;
        targetVariableFormat?: NumberFormat | null;
        targetVariableIndex?: number;
        shownVariableIndices?: (number | null)[] | null;
        shownVariableSubTitles?: string[];
        shownVariableFormats?: ColumnFormat[] | null;
        aggregateVariableIndex?: number | null;
        variableAliases?: { [key: number]: string | null };
        viewOption?: ViewOption;
        count: number;
        colorMapping: {
            Leaders: string;
            Laggers: string;
        };
        aliaseTextColor: {
            Leaders: string;
            Laggers: string;
        };
        fontStyle: LeadersLaggersFontStyle;
        showRankNumber: boolean;
        showLegend: boolean;
        legendPosition?: LeadersLaggersLegendPosition;
        arrowIndicatorColumn?: ArrowIndicatorColumn;
        colorOptions?: LeadersLaggersColorOptions;
        logoColumn?: VariableOption;
        logosColumnData?: LeadersLaggersLogosColumnData[];
        sortBy?: {
            label: string;
            value: number;
        };
        columnsProps?: LeadersLaggersColumns;
        navigation: NavigationOptions;
    };
    content: {
        data: {
            leaders?: { [key: string | number]: string | number | null }[];
            laggers?: { [key: string | number]: string | number | null }[];
            geographyLevels?: { [key: number]: string } | null;
            compareInfo?:
                | [
                      {
                          group: string;
                          operator: string;
                          label: string;
                          leaders: { [key: number]: string | number | null }[];
                          laggers: { [key: number]: string | number | null }[];
                      },
                      {
                          group: string;
                          operator: string;
                          label: string;
                          leaders: { [key: number]: string | number | null }[];
                          laggers: { [key: number]: string | number | null }[];
                      }
                  ]
                | null;
            units?: string | null;
            showMap?: boolean;
            frozen?: boolean;
        };
    };
}

export interface KanbanBoardFinding extends Finding {
    config: Finding["config"] & {
        title?: string;
        operationVariable?: string;
        selectedTargetVariablesIndices?: (number | undefined)[];
        selectedSummaryVariablesIndices?: (number | undefined)[];
        selectedSummaryVariables?: string[];
        categoryVariableIndex?: number[];
        titleVariableIndex?: [];
        titleVariable?: string;
        categoryVariable?: string;
    };
    content: {
        data: {
            lanes: any;
            metadata: any;
        };
    };
}

export interface LeversBenchmarkingFinding extends Finding {
    config: Finding["config"] & {
        targetVariable?: string;
        targetVariableIndex?: number;
        variableIndices?: number[];
        aggregateVariableIndex?: number;
        viewOption?: ViewOption;
        operationVariable?: string;
        barSize?: number;
        barGap?: number;
        barCategoryGap?: number;
        axesNamesColor?: string;
        ticksColor?: string;
        maxYRange?: number;
        minYRange?: number;
        linesCount?: number;
        showXAxisName?: boolean;
        showYAxisName?: boolean;
        nameColorMapping?: { [key: string]: string };
        groupByAll?: boolean;
        stacked?: boolean;
        showLegend?: boolean;
        showAsDots?: boolean;
        legendSize?: number;
        groupOperation: "best" | "worst" | "average";
        compareOperation: "best" | "worst" | "average";
    };
    content: {
        groupValue: string | number;
        compareValue: string | number;
        aggregateVariable: string;
        data: {
            name: string;
            units: string;
            value: number[];
            variableIndex?: number;
            originalName?: string;
        }[];
        groupOperation: "best" | "worst" | "average";
        compareOperation: "best" | "worst" | "average";
    };
}

export interface NetworkFinding extends Finding {
    config: Finding["config"] & {
        edgeSourceVariable?: string | null;
        edgeTargetVariable?: string | null;
        edgeWeightVariable?: string | null;
        timeVariable?: string | null;
        edgeSourceVariableIndex?: number | null;
        edgeTargetVariableIndex?: number | null;
        edgeWeightVariableIndex?: number | null;
        timeVariableIndex?: number | null;
        centerVariableIndex?: number | null;
        centerVariable?: string | null;
        centerVariableValue?: SearchComponentOption | null;

        // metrics: ({
        //     name: string;
        //     variableIndex: number;
        //     originalName?: string;
        // } | null)[];
        // true = attache to target node, false = attach to source nodes
        attachMetricsToTarget?: boolean;

        edgeColor?: string;
        varyEdgeColorByVariable?: boolean;
        edgeColorVariableIndex?: number | null;

        nodeColor?: string;
        varyNodeColorByVariable?: boolean;
        nodeColorVariableIndex?: number | null;
        // true = attache to target node, false = attach to source nodes
        attachNodeColorToTarget?: boolean;

        nodeLabelsDisplayMode?: MapTooltipDisplayMode;
        nodeLabelsDisplayPosition?: "top" | "bottom" | "left" | "right";

        xVariable?: string | null;
        xVariableIndex?: number | null;
        yVariable?: string | null;
        yVariableIndex?: number | null;

        mapConfig?: MapFinding["config"];

        timeAnimationSliderPosition?: TimeAnimationSliderPosition | null;
    };
    content: {
        data: {
            source: number;
            target: number;
            weight: number;
            metrics?: { [key: string]: string | number | null };
            time?: number | string | null;
            nodeColorKey?: number | string;
            edgeColorKey?: number | string;
            x?: number;
            y?: number;
        }[];
    };
    additionalMapsFindings?: Finding[];
}

export const defaultChoroplethOpacity = 0.7;
export const defaultChoroplethMinColor = "#69B3A2";
export const defaultChoroplethMiddleColor = "#4A8576";
export const defaultChoroplethMaxColor = "#115F4E";

export const defaultHeatmapMinColor = "lime";
export const defaultHeatmapMiddleColor = "yellow";
export const defaultHeatmapMaxColor = "red";
export const defaultHeatmapMinOpacity = 0.7;
export const defaultHeatmapBlur = 20;
export const defaultHeatmapRadius = 20;
export const defaultHeatmapMaxPointIntensity = 1;
export const defaultHeatmapMaxZoom = 12;
export const defaultHeatmapGradient = [
    {
        label: "from",
        color: defaultHeatmapMinColor,
        value: 0,
        positionX: 0,
    },
    {
        label: "middle",
        color: defaultHeatmapMiddleColor,
        value: 0.5,
        positionX: 0,
    },
    {
        label: "to",
        color: defaultHeatmapMaxColor,
        value: 1,
        positionX: 0,
    },
];

export interface GradientColorPointer {
    label: string;
    color: string;
    value: number;
    positionX: number;
}

export interface ChoroplethBorder {
    thickness: number;
    color: string | null;
}

export enum FlagType {
    Pins = 1,
    Classic = 2,
    Marker = 3,
}

export interface FlagOptions {
    label: string;
    value: FlagType;
}

export interface Flags {
    type: VariableOption;
    flagData: (MapVariableOption | null)[];
    flagColor: string;
    textColor: string;
    textBackgroundColor: string;
}

export interface MapFinding extends Finding {
    config: Finding["config"] & {
        usesCoordinates?: boolean;
        dataVariableOption?: MapDataVariableOption;
        location?: {
            country?: VariableOption | StringOption;
            state?: VariableOption;
            city?: VariableOption;
            address?: VariableOption;
            zipcode?: VariableOption;
        } | null;
        coordinates?: {
            latitude?: VariableOption;
            longitude?: VariableOption;
        } | null;
        displayMode?: MapTooltipDisplayModeOption;
        tooltipVariables?: (MapVariableOption | null)[];
        tooltipOptions?: TooltipOptions | null;
        tooltipActionButtonText?: string | null;
        tooltipBorderRadius?: number;
        displayAlways?: (MapVariableOption | null)[] | null;
        displayOnClick?: (MapVariableOption | null)[] | null;
        displayOnHover?: (MapVariableOption | null)[] | null;
        displayAlwaysOptions?: TooltipOptions | null;
        displayOnClickOptions?: TooltipOptions | null;
        displayOnHoverOptions?: TooltipOptions | null;
        selectLowest?: boolean;
        selectLimit?: number | null;
        selectOrderBy?: (VariableOption | null)[] | null;
        conditions?: Condition[] | null;
        zoom?: number | null;
        center?: {
            lat: number;
            lng: number;
        } | null;
        heatMap?: VariableOption | null;
        colorOptions?: MapColorOptions | null;
        markerIcon?: string | null; // base64 image
        markerLogoIcon?: string | null; // base64 image
        markerColor?: string | null;
        markerClusterColor?: string | null;
        markerBorderColor?: string | null;
        markerColorVariableIndex?: number | null;
        varyMarkerColorByVariable?: boolean; // default: false
        hideVaryMarkerColorLegend?: boolean; // default: false

        markerValueToColor?: { [key: string]: string } | null;
        geoJsonFiles?: {
            [key: string]: {
                name: string;
                contents: GeoJsonObject;
                color?: string;
            } | null;
        };
        timeVariable?: string | null;
        timeVariableIndex?: number | null;
        centerVariableIndex?: number | null;
        centerVariable?: string | null;
        centerVariableValue?: SearchComponentOption | null;

        choroplethOpacity?: number;
        choroplethMinColor?: string | null;
        choroplethMiddleColor?: string | null;
        choroplethMaxColor?: string | null;
        choroplethCountryVariable?: string | null;
        choroplethCountryVariableIndex?: number | null;
        choroplethMetricVariable?: string | null;
        choroplethMetricVariableIndex?: number | null;
        choroplethLevel?: ChoroplethLevel;
        choroplethMetricVatiableOptions?: MapVariableViewOptions | null;
        choroplethLevelVariableOptions?: MapVariableViewOptions | null;
        choroplethShowBorders?: boolean;
        choroplethBorder?: ChoroplethBorder;

        heatmapMinOpacity?: number;
        heatmapBlur?: number;
        heatmapRadius?: number;
        heatmapMaxPointIntensity?: number;
        heatmapMaxZoom?: number;
        heatmapGradient?: Array<GradientColorPointer>;

        showTable?: boolean;
        flags?: Flags;

        version?: MapVersion;
        isAdditional?: boolean;
        isAdditionalMapHiddenBehindMenu?: boolean;
        // filterAdditionalMapVariable - Responsible for "Hide Map Behind Menu" feature, for what dataset column to use to filter additional map
        filterAdditionalMapVariable?: VariableOption | null;

        // Aggregation
        operationVariable?: StringOption;
    };
    content: {
        data?: { [key: string]: Array<number | string | null> } | null;
        heatMapData?: Array<[number, number, number]>;
        heatMapTime?: Array<number | string | null> | null;
        choroplethData?: {
            country: string | null;
            metric?: number | null;
            time?: string | number | null;
        }[];
        time?: Array<number | string> | null;
    };
    additionalMapsFindings?: Finding[];
}

export function choroplethVariablesToTooltipVariables(
    finding: MapFinding
): MapVariableOption[] {
    return [
        {
            variable: {
                label: finding.config.choroplethMetricVariable ?? "metric",
                value: finding.config.choroplethMetricVariableIndex ?? 0,
                type: "",
                panel: "",
            },
            options: finding.config.choroplethMetricVariableOptions ?? {},
        },
        {
            variable: {
                label: finding.config.choroplethCountryVariable ?? "country",
                value: finding.config.choroplethCountryVariableIndex ?? 0,
                type: "",
                panel: "",
            },
            options: finding.config.choroplethCountryVariableOptions ?? {},
        },
    ];
}

export type ChoroplethLevel =
    | "country"
    | "state_us"
    | "zipcode_us"
    | "county_us";

export const choroplethLevelLabel: Readonly<
    {
        [key in ChoroplethLevel]: string;
    }
> = {
    country: "Country",
    state_us: "US State",
    zipcode_us: "US Zipcode",
    county_us: "US County",
};

const compatiblePairs: { [key: string]: string[] } = {
    CompareVariablesJourney: [
        "CompareRatiosJourney",
        "TreemapJourney",
        "DendrogramJourney",
        "RadarJourney",
    ],
    CompareRatiosJourney: [
        "CompareVariablesJourney",
        "TreemapJourney",
        "DendrogramJourney",
        "RadarJourney",
    ],
    TreemapJourney: [
        "CompareVariablesJourney",
        "CompareRatiosJourney",
        "DendrogramJourney",
        "RadarJourney",
    ],
    DendrogramJourney: [
        "CompareVariablesJourney",
        "CompareRatiosJourney",
        "TreemapJourney",
        "RadarJourney",
    ],
    RadarJourney: [
        "CompareVariablesJourney",
        "CompareRatiosJourney",
        "DendrogramJourney",
        "TreemapJourney",
    ],
    ScatterplotD3Journey: ["BubbleJourney"],
    BubbleJourney: ["ScatterplotD3Journey"],
    NetworkJourney: ["ArcDiagramJourney", "EdgeBundlingJourney"],
    ArcDiagramJourney: ["NetworkJourney", "EdgeBundlingJourney"],
    EdgeBundlingJourney: ["NetworkJourney", "ArcDiagramJourney"],
};

export const defaultWordCloudOptions: Partial<WordCloudOptions> = {
    colors: range(10)
        .map((number) => number.toString())
        .map(scaleOrdinal(schemeCategory10)),
    spiral: "archimedean",
    scale: "sqrt",
    deterministic: true,
    padding: 2,
    rotations: 0,
    fontFamily: "Oswald",
    fontWeight: "bold",
    rotationAngles: [-90, 90],
    transitionDuration: 350,
    fontSizes: [6, 100],
    tooltipOptions: {
        theme: "content-primary",
    },
};

export function getApiByName(journeyName: string) {
    let charts = modules.map((module) => module.submodules).flat();
    let chart = charts.find((chart) => chart.name === journeyName);
    if (chart != null) {
        let api = require(`modules/data_exploration_page/modules/${chart.dir}/ApiV2`);
        return api;
    }
    return null;
}

export function getData(
    findingData: Finding["content"]["data"],
    findingVaryByData: Finding["content"]["varyByData"],
    additionalVaryByData: Finding["content"]["varyByData"],
    sortYAxis: boolean,
    yAxisSortType: string,
    varyByIndices?: number[] | null
): BarChartFinding["content"]["data"][] {
    let data: Finding["content"]["data"][] = [];
    if (findingVaryByData != null) {
        data.push(_.cloneDeep(findingVaryByData));
        if (additionalVaryByData != null) {
            data = data.concat(_.cloneDeep(additionalVaryByData));
        }
    } else {
        data.push(_.cloneDeep(findingData));
    }

    if (sortYAxis) {
        if (yAxisSortType === "ascending") {
            for (let item of data) {
                item.sort((a: any, b: any) => {
                    if (Array.isArray(a.value)) {
                        return a.value[0] - b.value[0];
                    }
                    return a.value - b.value;
                });
            }
        } else {
            for (let item of data) {
                item.sort((a: any, b: any) => {
                    if (Array.isArray(a.value)) {
                        return b.value[0] - a.value[0];
                    }
                    return b.value - a.value;
                });
            }
        }
    } else if (varyByIndices != null) {
        for (let i = 0; i < data.length; ++i) {
            data[i] = varyByIndices.map((index) => data[i][index]);
        }
    }

    return data;
}

export function getCompatibleCharts(journeyName: string) {
    return compatiblePairs?.[journeyName] ?? [];
}

export function convertFinding(journeyName: string, finding: Finding): Finding {
    let currentFindingJourneyName = finding.config.journeyName;
    if (!compatiblePairs[currentFindingJourneyName].includes(journeyName))
        return finding;
    let api = getApiByName(journeyName);
    let previewFinding = api?.Api?.getPreviewFinding?.(journeyName);
    if (
        (isBarChart(finding) &&
            (isPieChart(previewFinding) ||
                isTreemap(previewFinding) ||
                isRadar(previewFinding) ||
                isDendrogram(previewFinding))) ||
        (isBarChart(previewFinding) &&
            (isPieChart(finding) ||
                isTreemap(finding) ||
                isRadar(finding) ||
                isDendrogram(finding)))
    ) {
        previewFinding.content = finding.content;
        for (let key in finding.config) {
            if (!(key in previewFinding.config))
                (previewFinding.config as any)[key] = null;
        }
        previewFinding.config.selectedTable = finding.config.selectedTable;
        previewFinding.config.operationVariable =
            finding.config.operationVariable;
        previewFinding.config.dataScope = finding.config.dataScope;
        previewFinding.config.conditions = finding.config.conditions;
        previewFinding.config.statusExpressions =
            finding.config.statusExpressions;
        previewFinding.config.tooltipColor = finding.config.tooltipColor;
        previewFinding.config.nameColorMapping =
            finding.config.nameColorMapping;
        previewFinding.config.ticksColor = finding.config.ticksColor;
        previewFinding.config.additionalOperators = finding.config.additionalOperators?.slice(
            0,
            2
        );
        previewFinding.config.additionalValues = finding.config.additionalValues?.slice(
            0,
            2
        );
        previewFinding.config.sideBySideVariableIndex =
            finding.config.sideBySideVariableIndex;
    } else if (
        (isPieChart(finding) ||
            isTreemap(finding) ||
            isDendrogram(finding) ||
            isRadar(finding)) &&
        (isPieChart(previewFinding) ||
            isTreemap(previewFinding) ||
            isDendrogram(previewFinding) ||
            isRadar(previewFinding))
    ) {
        previewFinding.content = finding.content;
        previewFinding.config = {
            ...finding.config,
            journeyName: previewFinding.config.journeyName,
        };
    } else if (isNetwork(finding) && isNetwork(previewFinding)) {
        previewFinding.content = finding.content;
        for (let key in finding.config) {
            if (!(key in previewFinding.config))
                (previewFinding.config as any)[key] = null;
        }
        previewFinding.config.selectedTable = finding.config.selectedTable;
        previewFinding.config.operationVariable =
            finding.config.operationVariable;
        previewFinding.config.dataScope = finding.config.dataScope;
        previewFinding.config.conditions = finding.config.conditions;

        previewFinding.config.edgeSourceVariable =
            finding.config.edgeSourceVariable;
        previewFinding.config.edgeTargetVariable =
            finding.config.edgeTargetVariable;
        previewFinding.config.edgeWeightVariable =
            finding.config.edgeWeightVariable;
        previewFinding.config.timeVariable = finding.config.timeVariable;
        previewFinding.config.edgeSourceVariableIndex =
            finding.config.edgeSourceVariableIndex;
        previewFinding.config.edgeTargetVariableIndex =
            finding.config.edgeTargetVariableIndex;
        previewFinding.config.edgeWeightVariableIndex =
            finding.config.edgeWeightVariableIndex;
        previewFinding.config.timeVariableIndex =
            finding.config.timeVariableIndex;

        previewFinding.config.attachMetricsToTarget =
            finding.config.attachMetricsToTarget;
        previewFinding.config.edgeColor = finding.config.edgeColor;
        previewFinding.config.varyEdgeColorByVariable =
            finding.config.varyEdgeColorByVariable;
        previewFinding.config.edgeColorVariableIndex =
            finding.config.edgeColorVariableIndex;

        previewFinding.config.nodeColor = finding.config.nodeColor;
        previewFinding.config.varyNodeColorByVariable =
            finding.config.varyNodeColorByVariable;
        previewFinding.config.nodeColorVariableIndex =
            finding.config.nodeColorVariableIndex;
        previewFinding.config.attachNodeColorToTarget =
            finding.config.attachNodeColorToTarget;

        previewFinding.config.nodeLabelsDisplayMode =
            finding.config.nodeLabelsDisplayMode;
        previewFinding.config.nodeLabelsDisplayPosition =
            finding.config.nodeLabelsDisplayPosition;
    } else if (
        (isScatterPlot(finding) || isBubble(finding)) &&
        (isScatterPlot(previewFinding) || isBubble(previewFinding))
    ) {
        previewFinding.content = finding.content;
        previewFinding.config = { ...previewFinding.config, ...finding.config };
        previewFinding.config.journeyName = journeyName;
        if (isBubble(previewFinding)) {
            previewFinding.content.data = [
                ...previewFinding.content.data,
                {
                    name: "value",
                    value: [],
                },
            ];
        }
    }
    return previewFinding;
}

export function isBarChart(finding: Finding): finding is BarChartFinding {
    return (
        finding.config.journeyName === "CompareVariablesJourney" ||
        finding.config.journeyName === "CompareVariablesD3Journey"
    );
}

export function isPieChart(finding: Finding): finding is PieChartFinding {
    return finding.config.journeyName === "CompareRatiosJourney";
}

export function isTreemap(finding: Finding): finding is TreemapFinding {
    return finding.config.journeyName === "TreemapJourney";
}

export function isDendrogram(finding: Finding): finding is DendrogramFinding {
    return finding.config.journeyName === "DendrogramJourney";
}

export function isRadar(finding: Finding): finding is RadarFinding {
    return finding.config.journeyName === "RadarJourney";
}

export function isScatterPlot(finding: Finding): finding is ScatterPlotFinding {
    return (
        finding.config.journeyName === "ScatterplotJourney" ||
        finding.config.journeyName === "ScatterplotD3Journey"
    );
}

export function isBubble(finding: Finding): finding is BubbleFinding {
    return finding.config.journeyName === "BubbleJourney";
}

export function isDensity2d(finding: Finding): finding is Density2dFinding {
    return finding.config.journeyName === "Density2dJourney";
}

export function isCorrelogram(finding: Finding): finding is CorrelogramFinding {
    return finding.config.journeyName === "CorrelogramJourney";
}

export function isHeatmap(finding: Finding): finding is HeatmapFinding {
    return finding.config.journeyName === "HeatmapJourney";
}

export function isHistogram(finding: Finding): finding is HistogramFinding {
    return finding.config.journeyName === "HistogramJourney";
}

export function isDensityPlot(finding: Finding): finding is DensityPlotFinding {
    return finding.config.journeyName === "DensityPlotJourney";
}

export function isBoxPlot(finding: Finding): finding is BoxPlotFinding {
    return finding.config.journeyName === "BoxPlotJourney";
}

export function isViolinPlot(finding: Finding): finding is ViolinPlotFinding {
    return finding.config.journeyName === "ViolinPlotJourney";
}

export function isRidgeline(finding: Finding): finding is RidgelineFinding {
    return finding.config.journeyName === "RidgelineJourney";
}

export function isWordCloud(finding: Finding): finding is WordCloudFinding {
    return finding.config.journeyName === "WordCloudJourney";
}

export function isFunnelPlot(finding: Finding): finding is FunnelPlotFinding {
    return finding.config.journeyName === "FunnelPlotJourney";
}

export function isGanttChart(finding: Finding): finding is GanttChartFinding {
    return finding.config.journeyName === "GanttChartJourney";
}

export function isLinePlot(finding: Finding): finding is LinePlotFinding {
    return (
        finding.config.journeyName === "LineplotJourney" ||
        finding.config.journeyName === "LineplotD3Journey"
    );
}

export function isSentimentAnalysis(
    finding: Finding
): finding is SentimentAnalysisFinding {
    return finding.config.journeyName === "SentimentAnalysisJourney";
}

export function isLeverOutcomeChart(
    finding: Finding
): finding is LeversOutcomePredictionFinding {
    return (
        finding.config.journeyName === "AnalyzeLeverOutcomeRelationshipJourney"
    );
}

export function isHoldOutPredictionChart(
    finding: Finding
): finding is HoldOutPredictionFinding {
    return finding.config.journeyName === "HoldOutPredictionJourney";
}

export function isLeadersLaggers(
    finding: Finding
): finding is LeadersLaggersFinding {
    return finding.config.journeyName === "LeadersLaggardsJourney";
}

export function isLeversBenchmarking(
    finding: Finding
): finding is LeversBenchmarkingFinding {
    return finding.config.journeyName === "LeversBenchmarkingJourney";
}

export function isKanbanBoard(finding: Finding): finding is KanbanBoardFinding {
    return finding.config.journeyName === "KanbanBoardJourney";
}

export function isNetwork(finding: Finding): finding is NetworkFinding {
    return (
        finding.config.journeyName === "NetworkJourney" ||
        finding.config.journeyName === "ArcDiagramJourney" ||
        finding.config.journeyName === "EdgeBundlingJourney"
    );
}

export function isMap(finding: Finding): finding is MapFinding {
    return (
        finding.config.journeyName === "MapsPinsJourney" ||
        finding.config.journeyName === "MapsHeatmapJourney" ||
        finding.config.journeyName === "MapsBubbleJourney" ||
        finding.config.journeyName === "MapsChoroplethJourney"
    );
}

export function isNetworkOverMap(finding: Finding): finding is NetworkFinding {
    return finding.config.journeyName === "MapsNetworkOverMapJourney";
}

export function isAiCopilot(finding: Finding): finding is AiCopilotFinding {
    return finding.config.journeyName === "aiCopilot";
}

export function canUpdateFinding(finding: Finding): boolean {
    let canUpdate = finding.config.dataScope != null;
    if (isBarChart(finding) || isPieChart(finding)) {
        canUpdate = canUpdate && finding.config.operationVariable != null;
    } else if (isScatterPlot(finding)) {
        canUpdate =
            canUpdate &&
            finding.content.data.find(
                (item: { variableIndex?: number }) => item.variableIndex != null
            ) != null;
    }
    return canUpdate;
}
