import {
    CanvasNode,
    DefaultValueType,
    isDateFormat,
    isDropdownSelector,
    isInput,
    isProgressElement,
    isSlider,
} from "common/Canvas";
import styles from "./DefaultValueOption.module.css";
import selectStyles from "../../common/SelectStyles";
import Select, { createFilter } from "react-select";

interface DefaultValueOption {
    label: string;
    value: DefaultValueType;
}

const defaultValueOptionsForTextElements: ReadonlyArray<DefaultValueOption> = [
    { label: "Clear every session", value: DefaultValueType.ClearEverySession },
    { label: "Last value added", value: DefaultValueType.LastTextAdded },
    { label: "Custom text", value: DefaultValueType.CustomText },
];

const defaultValueOptionsForDateInput: ReadonlyArray<DefaultValueOption> = [
    ...defaultValueOptionsForTextElements,
    { label: "Current Date/Time", value: DefaultValueType.CurrentDateTime },
];

const defaultValueOptionsForNonTextElements: ReadonlyArray<DefaultValueOption> = defaultValueOptionsForTextElements.slice(
    0,
    2
);

interface Props {
    node: CanvasNode;
    onChange: (changes: Partial<CanvasNode>, commit?: boolean) => void;
}

export default function DefaultValueOptionView(props: Props) {
    let defaultValueOptions: ReadonlyArray<DefaultValueOption>;
    if (isInput(props.node)) {
        if (isDateFormat(props.node.format)) {
            defaultValueOptions = defaultValueOptionsForDateInput;
        } else {
            defaultValueOptions = defaultValueOptionsForTextElements;
        }
    } else if (
        isProgressElement(props.node) ||
        isSlider(props.node) ||
        isDropdownSelector(props.node)
    ) {
        defaultValueOptions = defaultValueOptionsForNonTextElements;
    } else {
        defaultValueOptions = defaultValueOptionsForTextElements;
    }
    return (
        <div className={styles.root}>
            <div>
                <span className={styles.selectLabel}>Default value</span>

                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Default value"}
                    styles={selectStyles}
                    options={defaultValueOptions}
                    value={
                        defaultValueOptions.find(
                            (option) =>
                                option.value === props.node.defaultValueType
                        ) ?? defaultValueOptions[1]
                    }
                    onChange={(newValue) => {
                        props.onChange({
                            defaultValueType: (newValue as DefaultValueOption)
                                .value,
                        });
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />

                {props.node.defaultValueType ===
                    DefaultValueType.CustomText && (
                    <input
                        type="text"
                        className="like-select"
                        placeholder="Type custom text..."
                        style={{
                            width: "100%",
                            marginTop: "10px",
                        }}
                        value={props.node.defaultText}
                        onChange={(evt) => {
                            let newValue: string = evt.target.value;
                            props.onChange({ defaultText: newValue });
                        }}
                    />
                )}
            </div>
        </div>
    );
}
