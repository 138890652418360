import "common/styles/App.css";
import Switch from "react-switch";
import React, { Component } from "react";
import { observer } from "mobx-react";
import TableSelector from "./TableSelector";
import DataScopesSelector from "./DataScopesSelector";
import MultiCountModeVariablesSelector from "./MultiCountModeVariablesSelector";

import VariablesSelector from "./VariablesSelector";
import MultiVariablesSelector from "./MultiVariablesSelector";
import AdditionalParameterSelector from "./AdditionalParameterSelector";
import MultiAdditionalParameterSelector from "./MultiAdditionalParameterSelector";

import MonthSelector, { selectMonthByKeyboard } from "./MonthSelector";

import { Header, Subtitle } from "./JourneyFunctions";
import { mainStyle } from "common/MainStyle";
import tables from "common/Tables";
import dataScopes from "common/DataScopes";
import dataScopesForModules from "common/DataScopesForModules";
import variables from "common/Variables";
import { ConditionsSelector } from "common/Conditions";
import ColorExpressionsSelector, {
    defaultExpressions,
} from "../../canvas_page/ColorExpressionsSelector";

import {
    addJourneyConfigApi,
    getJourneyConfigApi,
    addRecipesConfigApi,
} from "common/UserApi";
import Instrumentation from "common/Instrumentation";
import AddToRecipesPopup from "./AddToRecipesPopup";
import DynamicOptionType from "common/DynamicOptionType";
import { isValidConfig } from "common/PathConfigVersion";
import { numericOperators } from "common/ValueWithOperator";
import { Permission } from "common/Permissions";
import SharedBoxesStore from "modules/canvas_page/SharedBoxesStore";
import { getTargetValue } from "common/Canvas";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";

// Added SVG buttons
import UpButtonBig from "icons/UpButtonBig.svg";
import DownButtonBig from "icons/DownButtonBig.svg";

/*!
 * BaseJourney is a base class for each exporation path
 */
const BaseJourney = observer(
    class BaseJourney extends Component {
        constructor(props) {
            super(props);
            this.state = {
                dynamicOptions: {},
            };
            this.showRecipesPopup = this.props.config
                ? () => {}
                : () => {
                      this.setState({
                          showRecipesPopup: true,
                      });
                  };
            this.updateAdditionalValues =
                this.updateAdditionalValues.bind(this);
            this.selectMonth = this.selectMonth.bind(this);
            this.back = this.back.bind(this);
            this.saveFinding = this.saveFinding.bind(this);
            this.onNewFinding = this.onNewFinding.bind(this);

            this.dataScopeValue = this.dataScopeValue.bind(this);

            this.performance = null;

            let oldStepUp = this.stepUp.bind(this);
            this.stepUp = function () {
                this.performance = new Date();
                oldStepUp();
            }.bind(this);

            let oldStepDown = this.stepDown.bind(this);
            this.stepDown = function () {
                this.performance = new Date();
                oldStepDown();
            }.bind(this);
        }
        onNewFinding(finding) {
            this.setState({ finding: finding });
        }

        /*!
         * function clearOptionalArgs clears optional args of pathes
         * when we switch between datascopes
         *
         */
        clearOptionalArgs() {
            this.setState({
                selectedTable: this.defaultTable(),
                conditions: ConditionsSelector.defaultValue,
                additionalParameter: null,
                additionalParameterIndex: null,
                leftAdditionalOperator: null,
                leftAdditionalValue: null,
                rightAdditionalOperator: null,
                rightAdditionalValue: null,
            });
        }
        /*!
         * getVariables returns set of variables for selected dataset
         */
        getVariables() {
            return variables(this.dataScopeValue(), this.props.currentModuleId);
        }

        /*!
         * function prepareDynamicOptions applies special rules for
         * already selected dynamic fields. E.g., if side-by-side
         * variable is dynamic option, then side-by-side values will
         * also be dynamic options
         */
        prepareDynamicOptions(dynamicOptions, config) {
            if (dynamicOptions["additionalParameter"] != null) {
                let newDynamicOptions = Object.assign({}, dynamicOptions);
                if (this.props.journeyName === "CompareVariablesJourney") {
                    newDynamicOptions["additionalValues,additionalOperators"] =
                        {
                            name: "additionalValues,additionalOperators",
                            label: "Data",
                            multiselection: true,
                            type: DynamicOptionType.valueWithOperator,
                            options: undefined,
                        };
                } else {
                    newDynamicOptions[
                        "leftAdditionalValue,leftAdditionalOperator"
                    ] = {
                        name: "leftAdditionalValue,leftAdditionalOperator",
                        label: "Data",
                        multiselection: false,
                        type: DynamicOptionType.valueWithOperator,
                        options: undefined,
                    };
                    newDynamicOptions[
                        "rightAdditionalValue,rightAdditionalOperator"
                    ] = {
                        name: "rightAdditionalValue,rightAdditionalOperator",
                        label: "Data",
                        multiselection: false,
                        type: DynamicOptionType.valueWithOperator,
                        options: undefined,
                    };
                }

                return newDynamicOptions;
            } else {
                return dynamicOptions;
            }
        }

        componentDidUpdate() {
            if (this.performance != null) {
                let timeMs = new Date() - this.performance;
                this.performance = null;
                Instrumentation.addInteraction(this.props.section, timeMs);
            }
        }

        /*!
         * function dataScopeValue returns identifier of selected dataset
         */
        dataScopeValue() {
            if (this.state.dataScope) return this.state.dataScope.value;
            return undefined;
        }

        /*!
         * function defaultTableValue returns default selected table
         */
        defaultTable() {
            return tables(
                this.dataScopeValue(),
                this.props.currentModuleId
            ).tableToOption();
        }
        /*!
         * function buildErrorScreen renders error screen according to
         * errorMessage argument
         */
        buildErrorScreen(errorMessage) {
            return (
                <div
                    className="my-row"
                    style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <div
                        onClick={() => {
                            this.back();
                        }}
                        style={{
                            width: 100,
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            alt=""
                            src="/dist/img/data-exploration/chevron_back.png"
                        />
                    </div>
                    <span
                        style={{
                            marginLeft: 10,
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "red",
                        }}
                    >
                        {errorMessage}
                    </span>

                    <div
                        style={{
                            width: 100,
                            height: "100%",
                        }}
                    />
                </div>
            );
        }

        /*!
         * function updateAdditionalValues called by MonthSelector or AdditionalParameterSelector
         * when we select side-by-side values and its operators;
         * additionalValues is a list of selected values;
         * dual indicates that we select pair of values, else single value;
         * additionalValuesSelected uses only by MonthSelector, it keeps highlighed variables after
         * we click Enter
         */
        updateAdditionalValues(
            additionalValues,
            dual,
            additionalValuesSelected = [undefined, undefined]
        ) {
            if (!dual)
                this.setState({
                    leftAdditionalValue: additionalValues[0],
                    leftAdditionalValueSelected: additionalValuesSelected[0],
                });
            else {
                this.setState({
                    leftAdditionalValue: additionalValues[0],
                    leftAdditionalValueSelected: additionalValuesSelected[0],
                    rightAdditionalValue: additionalValues[1],
                    rightAdditionalValueSelected: additionalValuesSelected[1],
                });
            }
        }
        /*!
         * function updateAdditionalOperators called by MonthSelector or AdditionalParameterSelector
         * when we select side-by-side values and its operators;
         * additionalOperators is a list of selected operators;
         * dual indicates that we select pair of values, else single value
         */
        updateAdditionalOperators(additionalOperators, dual) {
            if (!dual)
                this.setState({
                    leftAdditionalOperator: additionalOperators[0],
                });
            else {
                this.setState({
                    leftAdditionalOperator: additionalOperators[0],
                    rightAdditionalOperator: additionalOperators[1],
                });
            }
        }

        /*!
         * function selectMonth called when month selector is
         * active. It selects month according to keyboard key and saves it in
         * this.state.leftAdditionalValue if this.state.leftAdditionalValueSelected
         * is false or in this.state.rightAdditionalValue otherwise;
         * If dual is false it works only with this.state.leftAdditionalValue
         */
        selectMonth(key, dual) {
            let additionalValues = dual
                ? [
                      this.state.leftAdditionalValue,
                      this.state.rightAdditionalValue,
                  ]
                : [this.state.leftAdditionalValue];
            let additionalValuesSelected = dual
                ? [
                      this.state.leftAdditionalValueSelected,
                      this.state.rightAdditionalValueSelected,
                  ]
                : [this.state.leftAdditionalValueSelected];
            selectMonthByKeyboard(
                key,
                additionalValues,
                additionalValuesSelected,
                (additionalValues, additionalValuesSelected) => {
                    this.updateAdditionalValues(
                        additionalValues,
                        dual,
                        additionalValuesSelected
                    );
                }
            );
        }

        /*!
         * function back called when we move back from final screen
         */
        back() {
            this.setState({ animationDirection: "bottom" }, () => {
                this.setState((state) => ({
                    step: state.previousStep,
                    finding: undefined,
                    errorMessage: undefined,
                    leftAdditionalValueSelected: false,
                    rightAdditionalValueSelected: false,
                }));
            });
        }
        /*!
         * function saveFinding saves final data into insights or into dashboard element
         */
        saveFinding() {
            if (this.state.finding) this.props.onNewFinding(this.state.finding);
        }

        /*!
         * function buildMultiAdditionalValueSelector renders component that
         * allows to select multiple side-by-side variables and operators;
         * Selected values and operators stored in this.state.additionalValues
         * and this.state.additionalOperators
         */
        buildMultiAdditionalValueSelector(title, allowGroupByAll) {
            return (
                <div className="my-row" style={{ height: "100%" }}>
                    {this.buildLeftBar()}
                    <div
                        className="flex-simple-column element"
                        style={{
                            height: 325,
                            marginRight: 20,
                            overflow: "auto",
                            paddingRight: 5,
                            width: "100%",
                        }}
                    >
                        {allowGroupByAll && (
                            <div
                                className="my-row"
                                style={{
                                    marginLeft: 5,
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-primary-text-color"
                                        ),
                                        fontFamily: "Arial",
                                        fontSize: "12px",
                                    }}
                                >
                                    Group by all
                                </span>
                                <div style={{ marginLeft: 5 }}>
                                    <Switch
                                        onChange={(checked) => {
                                            this.setState((state) => ({
                                                groupByAll: !state.groupByAll,
                                            }));
                                        }}
                                        checked={this.state.groupByAll}
                                        width={26}
                                        height={13}
                                        offColor="#20293C"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1F8EFA"
                                    />
                                </div>
                            </div>
                        )}
                        {!this.state.groupByAll && (
                            <>
                                <MultiAdditionalParameterSelector
                                    title={title}
                                    dataScopeId={this.dataScopeValue()}
                                    currentModuleId={this.props.currentModuleId}
                                    additionalOperators={
                                        this.state.additionalOperators
                                    }
                                    additionalValues={
                                        this.state.additionalValues
                                    }
                                    additionalParameterIndex={this.getVariables().variableNames.indexOf(
                                        this.state.additionalParameter
                                    )}
                                    journeyComponent={this}
                                    onChange={(
                                        additionalOperators,
                                        additionalValues
                                    ) => {
                                        this.setState({
                                            additionalOperators:
                                                additionalOperators,
                                            additionalValues: additionalValues,
                                        });
                                    }}
                                />
                                {this.buildAddToCanvasToggle(
                                    ["additionalValues,additionalOperators"],
                                    undefined,
                                    true,
                                    DynamicOptionType.valueWithOperator
                                )}
                            </>
                        )}
                        {this.state.groupByAll && (
                            <ConditionsSelector
                                currentModuleId={this.props.currentModuleId}
                                dataScopeId={this.dataScopeValue()}
                                value={this.state.havingConditions}
                                title={"and under the having conditions"}
                                onChange={(conditions) => {
                                    this.setState({
                                        havingConditions: conditions,
                                    });
                                }}
                                allVariables={this.getVariables().dataVariables}
                                {...ConditionSelectorStyles}
                                titleStyle={{
                                    ...ConditionSelectorStyles.titleStyle,
                                    background: "transparent",
                                }}
                            />
                        )}
                    </div>
                </div>
            );
        }

        /*!
         * function buildMultiCountModeVariablesSelector renders special component that
         * allows to select multiple values for count-mode for Bar Chart, Pie Chart and Line Plot;
         * Selected values stored in this.state.targetValues
         */
        buildMultiCountModeVariablesSelector(title, targetVariable) {
            return (
                <div className="my-row" style={{ height: "100%" }}>
                    {this.buildLeftBar()}
                    <div
                        className="flex-simple-column element"
                        style={{
                            height: 325,
                            marginRight: "20px",
                            width: "100%",
                            overflow: "auto",
                        }}
                    >
                        <MultiCountModeVariablesSelector
                            title={title}
                            dataScopeId={this.dataScopeValue()}
                            currentModuleId={this.props.currentModuleId}
                            targetValues={this.state.targetValues}
                            targetParameterIndex={this.getVariables().variableNames.indexOf(
                                targetVariable
                            )}
                            onChange={(targetValues) => {
                                this.setState({
                                    targetValues: targetValues,
                                });
                            }}
                        />
                    </div>
                </div>
            );
        }

        /*!
         * function buildCustomVariablesSelector renders custom variables
         * selector;
         * variableName is a name of property in state where
         * selected variable is stored;
         * anotherVariableNames is a list of
         * properties of state where some already selected variables are stored cannot be
         * selected;
         * variablesName is a name of property in state where selected options are stored;
         * This function calles selectVariable function which is redefined in each module
         */
        buildCustomVariablesSelector(
            title,
            variableName,
            anotherVariableNames,
            variablesName
        ) {
            let anotherVariables = anotherVariableNames
                .map((name) => this.state[name])
                .flat();

            return (
                <div className="my-row">
                    {this.buildLeftBar()}
                    <div
                        className="flex-simple-column element"
                        style={{
                            marginTop: 25,
                            height: 300,
                            marginLeft: 100,
                            overflow: "auto",
                            marginRight: 20,
                        }}
                    >
                        <div
                            className="my-row"
                            style={{ alignItems: "center" }}
                        >
                            <span className="exploration-big-title-span">
                                {title}
                            </span>
                        </div>
                        <div
                            style={{
                                marginTop: 20,
                                display: "grid",
                                gridTemplateColumns: "repeat(4, 1fr)",
                                gridRowGap: "20px",
                                gridColumnGap: "20px",
                            }}
                        >
                            {this.state[variablesName].map(
                                (variable, index) => {
                                    let freezed =
                                        anotherVariables.includes(variable);
                                    let selected =
                                        variable === this.state[variableName];
                                    let journeyVariableStyle =
                                        "journey-variable"
                                            .concat(selected ? "-selected" : "")
                                            .concat(
                                                freezed
                                                    ? "-selected-freezed"
                                                    : ""
                                            );
                                    let journeyVariableSmallStyle =
                                        "journey-small-variable".concat(
                                            selected ? "-selected" : ""
                                        );

                                    return (
                                        <div
                                            onClick={() => {
                                                if (freezed) return;
                                                this.selectVariable(
                                                    variableName,
                                                    variablesName,
                                                    index
                                                );
                                            }}
                                            key={index}
                                            className={"my-row ".concat(
                                                journeyVariableStyle
                                            )}
                                            style={{
                                                justifyContent: "space-between",
                                                height: "56px",
                                                width: "250px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <div
                                                className="my-row"
                                                style={{ alignItems: "center" }}
                                            >
                                                <div
                                                    className={
                                                        journeyVariableSmallStyle
                                                    }
                                                    style={{
                                                        margin: 10,
                                                        height: "28px",
                                                        width: "26px",
                                                    }}
                                                >
                                                    <div
                                                        className="center-container"
                                                        style={{
                                                            height: "100%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontFamily:
                                                                    "Roboto",
                                                                fontSize:
                                                                    "20px",
                                                                lineHeight:
                                                                    "23px",
                                                                color: selected
                                                                    ? "#20293C"
                                                                    : mainStyle.getPropertyValue(
                                                                          "--selectors-text-color"
                                                                      ),
                                                                fontWeight: 300,
                                                            }}
                                                        >
                                                            {String.fromCharCode(
                                                                65 + index
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <span
                                                    style={{
                                                        textAlign: "center",
                                                        wordBreak: "break-word",
                                                        fontFamily: "Roboto",
                                                        fontSize: "20px",
                                                        lineHeight: "23px",
                                                        color: selected
                                                            ? "#39F"
                                                            : mainStyle.getPropertyValue(
                                                                  "--selectors-text-color"
                                                              ),
                                                        fontWeight: 300,
                                                        display: "block",
                                                    }}
                                                >
                                                    {variable}
                                                </span>
                                            </div>
                                            {selected ? (
                                                <div
                                                    style={{
                                                        margin: 20,
                                                        alignItems: "center",
                                                        display: "flex",
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        src="/dist/img/data-exploration/icon_checkbox.png"
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{ height: "27px" }}
                                                />
                                            )}
                                        </div>
                                    );
                                }
                            )}
                        </div>
                        <span
                            style={{
                                marginTop: 5,
                                alignSelf: "flex-end",
                                cursor: "pointer",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                                fontWeight: 700,
                            }}
                        >
                            press key or click to select
                        </span>
                    </div>
                </div>
            );
        }

        /*!
         * function buildAdditionalValueSelector renders selector for side-by-side values for
         * the case if we select one or two values and operators. If variable is a month variable then it returns
         * MonthSelector else regular AdditionalParameterSelector;
         * dual indicates that we select pair of values, else single value;
         * equalsOnly indicates that we can select only value, not operator (it will be equality operator);
         * First value is stored in this.state.leftAdditionalValue, second in this.state.rightAdditionalValue;
         * First operator is stored in this.state.leftAdditionalOperator, second in this.state.rightAdditionalOperator;
         */
        buildAdditionalValueSelector(title, dual = false, equalsOnly = false) {
            let additionalOperators = dual
                ? [
                      this.state.leftAdditionalOperator,
                      this.state.rightAdditionalOperator,
                  ]
                : [this.state.leftAdditionalOperator];
            let additionalValues = dual
                ? [
                      this.state.leftAdditionalValue,
                      this.state.rightAdditionalValue,
                  ]
                : [this.state.leftAdditionalValue];
            let additionalValuesSelected = dual
                ? [
                      this.state.leftAdditionalValueSelected,
                      this.state.rightAdditionalValueSelected,
                  ]
                : [this.state.leftAdditionalValueSelected];
            if (
                this.getVariables().isMonthVariable(
                    this.state.additionalParameter
                )
            ) {
                return (
                    <div className="my-row" style={{ height: "100%" }}>
                        {this.buildLeftBar()}
                        <div className="flex-simple-column">
                            <MonthSelector
                                title={title}
                                additionalValues={additionalValues}
                                additionalValuesSelected={
                                    additionalValuesSelected
                                }
                                onChange={(
                                    additionalValues,
                                    additionalValuesSelected
                                ) => {
                                    let monthAdditionalOperators = dual
                                        ? [
                                              numericOperators[0],
                                              numericOperators[0],
                                          ]
                                        : [numericOperators[0]];
                                    this.updateAdditionalOperators(
                                        monthAdditionalOperators,
                                        dual
                                    );
                                    this.updateAdditionalValues(
                                        additionalValues,
                                        dual,
                                        additionalValuesSelected
                                    );
                                }}
                            />
                            {this.buildAddToCanvasToggle(
                                dual
                                    ? [
                                          "leftAdditionalValue,leftAdditionalOperator",
                                          "rightAdditionalValue,rightAdditionalOperator",
                                      ]
                                    : [
                                          "leftAdditionalValue,leftAdditionalOperator",
                                      ],
                                undefined,
                                false,
                                DynamicOptionType.valueWithOperator
                            )}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="my-row" style={{ height: "100%" }}>
                        {this.buildLeftBar()}
                        <div className="flex-simple-column">
                            <AdditionalParameterSelector
                                title={title}
                                currentModuleId={this.props.currentModuleId}
                                dataScopeId={this.dataScopeValue()}
                                additionalOperators={additionalOperators}
                                additionalParameterIndex={variables(
                                    this.dataScopeValue()
                                ).variableNames.indexOf(
                                    this.state.additionalParameter
                                )}
                                additionalValues={additionalValues}
                                onChange={(
                                    additionalOperators,
                                    additionalValues
                                ) => {
                                    this.updateAdditionalOperators(
                                        additionalOperators,
                                        dual
                                    );
                                    this.updateAdditionalValues(
                                        additionalValues,
                                        dual
                                    );
                                }}
                                equalsOnly={equalsOnly}
                            />
                            {this.buildAddToCanvasToggle(
                                dual
                                    ? [
                                          "leftAdditionalValue,leftAdditionalOperator",
                                          "rightAdditionalValue,rightAdditionalOperator",
                                      ]
                                    : [
                                          "leftAdditionalValue,leftAdditionalOperator",
                                      ],
                                undefined,
                                false,
                                DynamicOptionType.valueWithOperator
                            )}
                        </div>
                    </div>
                );
            }
        }

        /*!
         * function buildTableSelector allows to select derived table or
         * list of custom tables if customTableList is defined;
         * Selected option stored in this.state.selectedTable
         */
        buildTableSelector(customTableList = undefined) {
            return (
                <div className="my-row" style={{ height: "100%" }}>
                    {this.buildLeftBar()}
                    <TableSelector
                        currentModuleId={this.props.currentModuleId}
                        dataScopeId={this.dataScopeValue()}
                        onChange={(table) => {
                            this.setState({ selectedTable: table });
                        }}
                        value={this.state.selectedTable}
                        customOptions={
                            customTableList
                                ? customTableList.map((option) =>
                                      tables(
                                          this.dataScopeValue(),
                                          this.props.currentModuleId
                                      ).tableToOption(option)
                                  )
                                : undefined
                        }
                        title={"For the data in"}
                    />
                </div>
            );
        }

        /*!
         * function buildDataScopesSelector allows to select dataset;
         * If requireWriteAccess is true then we сhoose only from datasets
         * for which user has write access;
         * Selected option stored in this.state.dataScope
         */
        buildDataScopesSelector(requireWriteAcess = false) {
            let options =
                this.props.currentModuleId != null
                    ? dataScopesForModules(this.props.currentModuleId)
                          .dataScopesOptions
                    : dataScopes.dataScopesOptions;
            if (requireWriteAcess)
                options = options.filter(
                    (option) => option.permissionType === Permission.ReadWrite
                );
            return (
                <div className="my-row" style={{ height: "100%" }}>
                    {this.buildLeftBar()}
                    <DataScopesSelector
                        options={options}
                        onChange={(scope) => {
                            this.setState({ dataScope: scope }, () => {
                                this.clearOptionalArgs();
                                setTimeout(() => {
                                    this.switchVariables();
                                }, 0);
                            });
                        }}
                        value={this.state.dataScope}
                        title={"For the data in"}
                    />
                </div>
            );
        }

        /*!
         * function buildConditionsSelector renders Conditions screen;
         * Selected conditions stored in this.state.conditions field
         */
        buildConditionsSelector() {
            return (
                <div className="my-row" style={{ height: "100%" }}>
                    {this.buildLeftBar()}
                    <div
                        className="flex-simple-column"
                        style={{
                            height: "100%",
                            width: "100%",
                            marginRight: 20,
                        }}
                    >
                        <div
                            className="flex-simple-column element"
                            style={{
                                height: 300,
                                overflow: "auto",
                                paddingRight: 5,
                                width: "100%",
                            }}
                        >
                            <ConditionsSelector
                                allowLinks={this.props.addToCanvas}
                                nodeLinkOptions={
                                    this.props.addToCanvas
                                        ? Array.from(
                                              this.props.canvasTreeStore?.canvasTreeState.values()
                                          )
                                              .map((item) => ({
                                                  label: item.outerId,
                                                  value: item.id,
                                                  isCloneInput: false,
                                                  target: getTargetValue(item),
                                              }))
                                              .concat(
                                                  SharedBoxesStore.sharedBoxes(
                                                      this.props.canvasTreeStore
                                                          ?.canvasId
                                                  ).map((item) => ({
                                                      label: item.label,
                                                      value: item.value,
                                                      isCloneInput: true,
                                                      target: getTargetValue(
                                                          item.item.box
                                                      ),
                                                  }))
                                              )
                                        : []
                                }
                                currentModuleId={this.props.currentModuleId}
                                dataScopeId={this.dataScopeValue()}
                                value={this.state.conditions}
                                title={"and under the conditions"}
                                onChange={(conditions) => {
                                    this.setState({ conditions: conditions });
                                }}
                                allVariables={this.getVariables().dataVariables}
                                {...ConditionSelectorStyles}
                                titleStyle={{
                                    ...ConditionSelectorStyles.titleStyle,
                                    background: "transparent",
                                }}
                            />
                        </div>
                        {this.buildAddToCanvasToggle(
                            ["conditions"],
                            undefined,
                            false,
                            DynamicOptionType.conditions
                        )}
                    </div>
                </div>
            );
        }

        /*!
         * function  buildColorExpressionsSelector renders screen for conditional
         * colorizing of chart
         * variables is a list of selected variables as structure
         * {label: name_of_variable, value: index_of_variable}
         * Selected conditions stored in this.state.statusExpressions field
         */
        buildColorExpressionsSelector(variables) {
            return (
                <div className="my-row" style={{ minHeight: "300px" }}>
                    {this.buildLeftBar()}
                    <div
                        className="flex-simple-column element"
                        style={{
                            marginTop: 25,
                            height: 300,
                            marginLeft: 100,
                            marginRight: 100,
                            width: "100%",
                            overflow: "auto",
                        }}
                    >
                        <span
                            className="exploration-big-title-span"
                            style={{ marginBottom: 10 }}
                        >
                            {"And colorize"}
                        </span>
                        <ColorExpressionsSelector
                            variables={variables}
                            nodeLinkOptions={
                                this.props.addToCanvas
                                    ? Array.from(
                                          this.props.canvasTreeStore?.canvasTreeState.values()
                                      )
                                          .map((item) => ({
                                              label: item.outerId,
                                              value: item.id,
                                              isCloneInput: false,
                                              target: getTargetValue(item),
                                          }))
                                          .concat(
                                              SharedBoxesStore.sharedBoxes(
                                                  this.props.canvasTreeStore
                                                      ?.canvasId
                                              ).map((item) => ({
                                                  label: item.label,
                                                  value: item.value,
                                                  isCloneInput: true,
                                                  target: getTargetValue(
                                                      item.item.box
                                                  ),
                                              }))
                                          )
                                    : []
                            }
                            statusExpressions={
                                this.state.statusExpressions ??
                                defaultExpressions
                            }
                            onChange={(expressions) => {
                                this.setState({
                                    statusExpressions: expressions,
                                });
                            }}
                        />
                    </div>
                </div>
            );
        }

        /*!
         * function buildLeftBar builds left bar with up and down arrows
         */
        buildLeftBar(showBottomArrow = true) {
            return (
                <div
                    className="flex-simple-column"
                    style={{ justifyContent: "space-between", marginLeft: 100 }}
                >
                    <div
                        onClick={() => {
                            this.stepUp();
                        }}
                    >
                        <img
                            alt=""
                            src={UpButtonBig} //"/dist/img/canvas/up_big.png"
                            style={{ cursor: "pointer" }}
                        />
                    </div>

                    {showBottomArrow ? (
                        <div
                            onClick={() => {
                                this.stepDown();
                            }}
                        >
                            <img
                                alt=""
                                src={DownButtonBig} //"/dist/img/canvas/down_big.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    ) : null}
                </div>
            );
        }

        /*!
         * function buildAddToCanvasToggle builds bottom panel with
         * static-dynamic toggle. It is shown only when we open exploration page
         * from canvas.
         */
        buildAddToCanvasToggle(
            listOfVariables,
            variablesName,
            multiselection,
            type,
            indexVariableName,
            maxCount = undefined
        ) {
            if (
                [
                    "LeversOptimizerJourney",
                    "OutcomesSimulatorJourney",
                    "KanbanBoardJourney",
                ].includes(this.props.journeyName)
            )
                return null;
            return this.props.addToCanvas ? (
                <div
                    className="my-row"
                    style={{
                        marginLeft: 5,
                        alignItems: "center",
                    }}
                >
                    <span
                        className="canvas-switch"
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-primary-text-color"
                            ),
                        }}
                    >
                        STATIC
                    </span>
                    <div style={{ marginLeft: 5 }}>
                        <Switch
                            onChange={(checked) => {
                                let dynamicOptions = Object.assign(
                                    {},
                                    this.state.dynamicOptions
                                );
                                listOfVariables.forEach((variableName) => {
                                    if (!checked) {
                                        if (variableName in dynamicOptions) {
                                            dynamicOptions[variableName] = null;
                                        }
                                    } else {
                                        if (
                                            !(variableName in dynamicOptions) ||
                                            dynamicOptions[variableName] == null
                                        ) {
                                            let options = [
                                                DynamicOptionType.variable,
                                                DynamicOptionType.additionalParameter,
                                            ].includes(type)
                                                ? this.state[variablesName].map(
                                                      (item) => ({
                                                          label: item,
                                                          value: this.getVariables().variableToIndex(
                                                              item
                                                          ),
                                                      })
                                                  )
                                                : this.state[variablesName];
                                            dynamicOptions[variableName] = {
                                                label: "Data",
                                                name: variableName,
                                                indexName: indexVariableName,
                                                multiselection: multiselection,
                                                options: options,
                                                type: type,
                                                maxCount: maxCount,
                                            };
                                        }
                                    }
                                });
                                this.setState({
                                    dynamicOptions: dynamicOptions,
                                });
                            }}
                            checked={listOfVariables.reduce(
                                (acc, variableName) =>
                                    acc &&
                                    variableName in this.state.dynamicOptions &&
                                    this.state.dynamicOptions[variableName] !=
                                        null,
                                true
                            )}
                            width={26}
                            height={13}
                            offColor="#20293C"
                            onColor="#20293C"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#70889E"
                            onHandleColor="#1F8EFA"
                        />
                    </div>
                    <span
                        className="canvas-switch"
                        style={{
                            marginLeft: 5,
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        DYNAMIC
                    </span>
                </div>
            ) : null;
        }

        /*!
         * function buildVariablesSelector renders common variables
         * selector;
         * variableName is a name of property in state where
         * selected variable is stored;
         * variablesName is a name of property
         * in state where options for selection are stored;
         * If multiselection is true then we can select multiple variables;
         * type is type of dynamic option;
         *
         * If type is DynamicOptionType.variable or DynamicOptionType.additionalParameter
         * we also keep index of selected variables in indexVariableName property of
         * this.state;
         *
         * If grid is true then selector will shown as grid;
         *
         * aliases is a dictionary where key is original option name, value is an alias.
         * If for some option this dictionary has value then value will shown instead
         * of original option;
         *
         * This function calls selectVariable function which is redefined in each module.
         */
        buildVariablesSelector(
            title,
            variableName,
            variablesName,
            multiselection = false,
            type = DynamicOptionType.regular,
            indexVariableName = undefined,
            grid = false,
            aliases = undefined,
            optional = false
        ) {
            return (
                <div className="my-row">
                    {this.buildLeftBar()}
                    <div
                        className="flex-simple-column element"
                        style={{
                            marginTop: 25,
                            height: 300,
                            marginLeft: 100,
                            marginRight: 100,
                            overflow: "auto",
                        }}
                    >
                        <div
                            className="my-row"
                            style={{ alignItems: "center" }}
                        >
                            <span className="exploration-big-title-span">
                                {title}
                            </span>
                            {variableName in this.state.dynamicOptions &&
                                this.state[variableName] && (
                                    <div
                                        className="journey-variable-selected"
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <span
                                            style={{
                                                padding: 10,
                                                color: "#39F",
                                            }}
                                            className="exploration-big-title-span"
                                        >
                                            {this.state[variableName]}
                                        </span>
                                    </div>
                                )}
                        </div>

                        <div className="flex-column">
                            <VariablesSelector
                                grid={grid}
                                aliases={aliases}
                                multiselection={multiselection}
                                value={this.state[variableName]}
                                values={this.state[variablesName]}
                                variableName={variableName}
                                variablesName={variablesName}
                                onChange={this.selectVariable.bind(this)}
                                optional={optional}
                            />
                            {this.buildAddToCanvasToggle(
                                [variableName],
                                variablesName,
                                multiselection,
                                type,
                                indexVariableName
                            )}
                            <span
                                style={{
                                    alignSelf: "flex-end",
                                    cursor: "pointer",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                    fontWeight: 700,
                                }}
                            >
                                press key or click to select
                            </span>
                        </div>
                    </div>
                </div>
            );
        }

        /*!
         * function buildInput renders screen with some input field
         * between leftTitle and rightTitle;
         * Result is stored in this.state[variableName] property;
         * If numeric is true we can input only numbers;
         * children is optional array of child components
         */
        buildInput(
            leftTitle,
            rightTitle,
            variableName,
            numeric = false,
            children = []
        ) {
            return (
                <div
                    className="my-row"
                    style={{
                        height: "100%",
                        minHeight: "300px",
                    }}
                >
                    {this.buildLeftBar()}
                    <div
                        className="flex-simple-column element"
                        style={{
                            marginTop: 25,
                            marginLeft: 100,
                            marginRight: 100,
                            overflow: "auto",
                        }}
                    >
                        <div
                            className="my-row"
                            style={{
                                marginTop: "50px",
                            }}
                        >
                            {leftTitle != null && leftTitle.length !== 0 && (
                                <span
                                    className="exploration-big-title-span"
                                    style={{
                                        marginRight: "5px",
                                    }}
                                >
                                    {leftTitle}
                                </span>
                            )}
                            <input
                                className="like-select"
                                value={this.state[variableName]}
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    height: "28px",
                                    width: "200px",
                                    textAlign: "left",
                                }}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (numeric) {
                                        if (!/^[0-9]*$/.test(value)) {
                                            e.preventDefault();
                                            return;
                                        }
                                        value = Number(value);
                                    }
                                    this.setState({
                                        [variableName]: value,
                                    });
                                }}
                            />
                            {rightTitle != null && rightTitle.length !== 0 && (
                                <span
                                    className="exploration-big-title-span"
                                    style={{
                                        marginLeft: "5px",
                                    }}
                                >
                                    {rightTitle}
                                </span>
                            )}
                        </div>
                        {children}
                    </div>
                </div>
            );
        }
        /*!
         * function buildHeader builds title and subtitle of exploration path
         */
        buildHeader() {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        marginLeft: 30,
                        marginRight: 30,
                        marginTop: 30,
                        marginBottom: 10,
                    }}
                >
                    <div
                        className="my-row"
                        style={{ justifyContent: "space-between" }}
                    >
                        <div
                            className="my-row"
                            style={{ alignItems: "center" }}
                        >
                            <img alt="" src={this.props.imageSource} />
                            <Header title={this.props.title} />
                        </div>
                        <div
                            onClick={() => {
                                this.props.onClose();
                            }}
                        >
                            <img
                                alt=""
                                src="/dist/img/insights/insights_remove.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </div>
                    {this.getSubtitle && <Subtitle text={this.getSubtitle()} />}
                </div>
            );
        }

        /*!
         * function buildMultiVariablesSelector renders multi variables
         * selector;
         * variableName is a name of property in state where
         * selected variables is stored (as list);
         * variablesName is a name of property
         * in state where options for selection are stored;
         *
         * If type is DynamicOptionType.variable or DynamicOptionType.additionalParameter
         * we also keep index of selected variables in indexVariableName property of
         * this.state;
         * maxCount is max count of selected variables;
         *
         * This function calles selectVariable function which is redefined in each module.
         */
        buildMultiVariablesSelector(
            title,
            variableName,
            variablesName,
            type,
            indexVariableName,
            maxCount = undefined,
            customDynamicView = null
        ) {
            return (
                <div className="my-row">
                    {this.buildLeftBar()}
                    <div
                        className="flex-simple-column element"
                        style={{
                            marginTop: 25,
                            height: 300,
                            marginLeft: 100,
                            overflow: "auto",
                            marginRight: 20,
                        }}
                    >
                        <div
                            className="my-row"
                            style={{ alignItems: "center" }}
                        >
                            <span className="exploration-big-title-span">
                                {title}
                            </span>
                        </div>
                        <MultiVariablesSelector
                            variableName={variableName}
                            variablesName={variablesName}
                            selectedVariables={this.state[variableName]}
                            allVariables={this.state[variablesName]}
                            onChange={(variableName, variablesName, index) => {
                                this.selectVariable(
                                    variableName,
                                    variablesName,
                                    index,
                                    maxCount
                                );
                            }}
                        />
                        {customDynamicView ??
                            this.buildAddToCanvasToggle(
                                [variableName],
                                variablesName,
                                true,
                                type,
                                indexVariableName,
                                maxCount
                            )}
                        <span
                            style={{
                                marginTop: 5,
                                alignSelf: "flex-end",
                                cursor: "pointer",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                lineHeight: "14px",
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                                fontWeight: 700,
                            }}
                        >
                            press key or click to select
                        </span>
                    </div>
                </div>
            );
        }

        /*!
         * function getLastJourneyConfig loads last path configuration
         * from backend or from props
         */
        getLastJourneyConfig() {
            if (!this.props.config) {
                getJourneyConfigApi(this.props.journeyName)
                    .then((config) => {
                        if (isValidConfig(config)) {
                            config.dynamicOptions = {};
                            this.setState(config, () => {
                                this.switchVariables();
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                if (
                    isValidConfig(this.props.config) &&
                    this.props.config.journeyName === this.props.journeyName
                ) {
                    this.setState(this.props.config, () => {
                        this.switchVariables();
                    });
                }
            }
        }

        /*!
         * function saveNewJorneyConfig saves current path configuration to backend
         */
        saveNewJourneyConfig() {
            if (!this.props.config && !this.props.addToCanvas) {
                addJourneyConfigApi(this.getConfig())
                    .then(() => {})
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
        /*!
         * function buildAddToRecipesPopup renders popup for adding path to recipes
         */
        buildAddToRecipesPopup() {
            return this.state.showRecipesPopup ? (
                <AddToRecipesPopup
                    title={this.props.title}
                    onSave={(title, tabId) => {
                        addRecipesConfigApi(title, tabId, this.getConfig())
                            .then((id) => {
                                this.setState({ showRecipesPopup: false });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }}
                    onClose={() => {
                        this.setState({ showRecipesPopup: false });
                    }}
                />
            ) : null;
        }

        /*!
         * function buildInitial renders initial screen with title
         */
        buildInitial(title) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }}
                >
                    <div className="flex-simple-column">
                        <span
                            className="exploration-big-title-span"
                            style={{
                                alignSelf: "center",
                            }}
                        >
                            {title}
                        </span>
                        <div
                            className="my-row"
                            style={{
                                marginTop: 10,
                                alignSelf: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    cursor: "pointer",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                    fontWeight: 700,
                                }}
                            >
                                CLICK to continue
                            </span>
                            <img
                                style={{ marginLeft: 10 }}
                                alt=""
                                src="/dist/img/data-exploration/chevron_transformed.png"
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }
);

export default BaseJourney;
