import React from "react";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";
import Select, { createFilter } from "react-select";

import BaseStepModule from "../../../../common/BaseStepModule";
import customSelectStyles from "common/SelectStyles";
import tables from "common/Tables";
import dataScopes from "common/DataScopes";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
            };
        }

        renderSelectTable() {
            if (!this.state.expanded) return null;
            else {
                return (
                    <>
                        <div />
                        <div
                            style={{
                                gridColumnStart: 2,
                                gridColumnEnd: 3,
                                background: "#3f4b62",
                                padding: "10px",
                                display: "flex",
                                alignItems: "left",
                                flexDirection: "column",
                            }}
                        >
                            <div
                                className="my-row"
                                style={{ alignItems: "center" }}
                            >
                                <span
                                    className="content-regular-text"
                                    style={{
                                        marginRight: "5px",
                                    }}
                                >
                                    Select data
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "25em",
                                            height: "38px",
                                            marginRight: "5px",
                                        }),
                                    }}
                                    options={dataScopes.dataScopesOptions}
                                    onChange={(newValue) => {
                                        this.props.commonState.setViewDataScope(
                                            newValue
                                        );
                                    }}
                                    value={this.props.commonState.viewDataScope}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            {this.props.commonState.viewDataScope && (
                                <div
                                    className="my-row"
                                    style={{
                                        marginTop: "5px",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        className="content-regular-text"
                                        style={{
                                            marginRight: "5px",
                                        }}
                                    >
                                        Select table
                                    </span>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                width: "25em",
                                                height: "38px",
                                                marginRight: "5px",
                                            }),
                                        }}
                                        options={
                                            tables(
                                                this.props.commonState
                                                    .viewDataScopeId
                                            ).rawAndAggregateTableOptions
                                        }
                                        onChange={(newValue) => {
                                            this.props.commonState.setViewDataTable(
                                                newValue
                                            );
                                        }}
                                        value={
                                            this.props.commonState.viewDataTable
                                        }
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25: "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            )}
                        </div>
                        <div />
                        <div />
                    </>
                );
            }
        }

        render() {
            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        disabled={this.props.disabled}
                        className="btn-processing-tab"
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState((state) => ({
                                expanded: !state.expanded,
                            }));
                        }}
                    >
                        <span className="content-regular-text">Select table</span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    <div />
                    <div />
                    {this.renderSelectTable()}
                </>
            );
        }
    }
);

export { MainComponent };
