// let svgns = "http://www.w3.org/2000/svg";

export function getTransformList(transformStr: string): {[key: string]: string} {
    return Array.from(transformStr.matchAll(/(\w+)\((.+?)\)/gm)).reduce(
        (agg, [, fn, val]) => ({
            ...agg,
            [fn]: val,
        }),
        {}
    );
}
