import React, { Component, CSSProperties } from "react";
import { Button } from "react-bootstrap";
import cx from "classnames";
import Select, { createFilter, StylesConfig, GroupBase } from "react-select";
import {
    customFilterStyles,
    leftAlignAndScrollBarCSS,
} from "common/SelectStyles";
import SearchComponent, { SearchComponentOption } from "common/SearchComponent";
import { Variable, VariableOption } from "common/Variables";
import StringOption from "common/StringOption";
import { mainStyle } from "common/MainStyle";
import { ReactComponent as CrossIcon } from "icons/popups/cross.svg";
import styles from "./CleanSection.module.css";
import { NodeValue } from "common/Canvas";

export interface NodeLinkOption {
    label: string;
    value: number;
    target: string | number | undefined | (NodeValue | undefined)[];
    isCloneInput: boolean;
    isGlobalInput?: boolean;
}

type NullableOption =
    | StringOption
    | {
          label: string;
          value: null;
      };

const comparisonOperations: ReadonlyArray<StringOption> = [
    {
        label: ">",
        value: ">",
    },
    {
        label: "<",
        value: "<",
    },
    {
        label: ">=",
        value: ">=",
    },
    {
        label: "<=",
        value: "<=",
    },
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
];

const comparisonPanelOperations: ReadonlyArray<StringOption> = [
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
    {
        label: "in",
        value: "in",
    },
    {
        label: "not in",
        value: "not in",
    },
];

const linkOptions: ReadonlyArray<StringOption> = [
    {
        label: "Value",
        value: "value",
    },
    {
        label: "Link",
        value: "link",
    },
];

const logicalOperations: ReadonlyArray<StringOption> = [
    {
        label: "Or",
        value: "or",
    },
    {
        label: "And",
        value: "and",
    },
];

interface SwitchProps {
    offColor: string;
    onColor: string;
    offHandleColor: string;
    onHandleColor: string;
}

export interface Condition {
    variable: VariableOption | null;
    operation: StringOption | null;
    value:
        | SearchComponentOption
        | NodeLinkOption
        | null
        | SearchComponentOption[]
        | NodeLinkOption[];
    logical: StringOption | null;
    leftBracket: StringOption | null;
    rightBracket: StringOption | null;
    isInput?: boolean;
}

export function getSharedIdsList(condition: Condition): number[] {
    let newSharedBoxIds: number[] = [];
    if (
        !isMultiCondition(condition) &&
        condition.isInput &&
        condition.value != null &&
        (condition.value as NodeLinkOption).isCloneInput
    ) {
        newSharedBoxIds.push((condition.value as NodeLinkOption).value);
    }
    if (
        isMultiCondition(condition) &&
        condition.isInput &&
        Array.isArray(condition.value) &&
        condition.value.length > 0
    ) {
        for (let subValue of condition.value) {
            if (subValue != null && (subValue as NodeLinkOption).isCloneInput) {
                newSharedBoxIds.push((subValue as NodeLinkOption).value);
            }
        }
    }
    return newSharedBoxIds;
}

export function filterNonEmptyConditions(conditions: Condition[]) {
    return conditions.filter(
        (condition) =>
            condition.variable != null &&
            condition.operation != null &&
            condition.value != null &&
            (!Array.isArray(condition.value) || condition.value.length > 0)
    );
}

export function isEmptyVariableCondition(condition: Condition) {
    return condition.variable == null;
}

export function isEmptyConditions(conditions?: Condition[]) {
    let filteredConditions: Condition[] | undefined = undefined;
    if (conditions != null) {
        filteredConditions = conditions.filter(
            (condition) =>
                condition.variable != null &&
                condition.operation != null &&
                condition.value != null &&
                (!Array.isArray(condition.value) || condition.value.length > 0)
        );
        return filteredConditions.length === 0;
    }
    return true;
}

export function isMultiCondition(condition: Condition): boolean {
    return (
        condition.operation != null &&
        ["in", "not in"].includes(condition.operation.value)
    );
}

export function getConditionValue(
    condition: Condition
): (NodeValue | null | undefined)[] | NodeValue | null | undefined {
    if (isMultiCondition(condition)) {
        return condition.isInput
            ? (condition.value as NodeLinkOption[])
                  ?.map((value) => value.target)
                  .flat()
            : (condition.value as SearchComponentOption[])?.map(
                  (value) => value.value
              );
    } else {
        return condition.isInput
            ? (condition.value as NodeLinkOption)?.target
            : (condition.value as SearchComponentOption)?.value;
    }
}

export function getConditionLabel(condition: Condition): string[] | string {
    if (isMultiCondition(condition)) {
        return condition.isInput
            ? (condition.value as NodeLinkOption[])?.map((value) => value.label)
            : (condition.value as SearchComponentOption[])?.map(
                  (value) => value.label
              );
    } else {
        return condition.isInput
            ? (condition.value as NodeLinkOption)?.label
            : (condition.value as SearchComponentOption)?.label;
    }
}

function conditionsToJson(conditions: Condition[]) {
    return conditions.map((condition: Condition) => ({
        operation: condition.operation?.value,
        logical: condition.logical?.value,
        variable: condition.variable?.value,
        value: getConditionValue(condition),
        leftBracket: condition.leftBracket?.value,
        rightBracket: condition.rightBracket?.value,
    }));
}

function conditionsToString(conditions: Condition[]): string {
    conditions = conditions.filter(
        (condition) =>
            condition.variable != null &&
            condition.value != null &&
            condition.operation != null
    );
    let str = conditions.reduce((accumulator, currentValue) => {
        let currentString = `${
            currentValue.logical != null
                ? " ".concat(currentValue.logical.value)
                : ""
        } ${currentValue.leftBracket?.value ?? ""}${
            currentValue.variable?.label ?? ""
        }${currentValue.operation?.value ?? ""}${
            getConditionLabel(currentValue) ?? ""
        }${currentValue.rightBracket?.value ?? ""}`;
        return accumulator.concat(currentString);
    }, "");
    if (str.length > 20) return str.slice(0, 20) + "...";
    return str;
}

interface Props {
    single?: boolean;
    dataScopeId: number | string | undefined;
    currentModuleId?: number;
    allVariables: Variable[];
    onChange: (conditions: Condition[]) => void;
    title: string;
    value?: Condition[];
    style?: CSSProperties;
    small?: boolean;
    titleStyle?: CSSProperties;
    linkElementStyle?: CSSProperties;
    allowLinks?: boolean;
    nodeLinkOptions?: NodeLinkOption[];
    rowStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    rowStyleWithoutPlusMinus?:
        | CSSProperties
        | ((rowIndex: number) => CSSProperties);
    inputStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    selectStyle?: StylesConfig | ((rowIndex: number) => StylesConfig);
    auxiliarySelectorsStyle?:
        | StylesConfig
        | ((rowIndex: number) => StylesConfig);
    buttonsStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    linkSwitchStyle?: Partial<SwitchProps>;
    rowWithoutPlusMinusClassName?: string;
    asWizard?: boolean;
    logicalButtons?: boolean;
    switch?: boolean;
    tab?: string;
}

interface FocusProps {
    onNext: () => void;
    onPrevious: () => void;
    children: JSX.Element;
}

function FocusIterable(props: FocusProps) {
    return React.cloneElement(React.Children.only(props.children), {
        onKeyDown: (evt: any) => {
            evt.stopPropagation();
            let value = (evt.target as any).value;
            if (evt.key === "Enter") {
                props.onNext();
            }
            if (evt.key === "Backspace" && value.length === 0) {
                props.onPrevious();
            }
        },
    });
}

interface State {
    emptyFocused: Set<number>;
    conditions: Condition[];
}

class ConditionsSelector extends Component<Props, State> {
    selectGrid: {
        [key: string]: {
            [key: string]: any;
        };
    };
    public static get defaultValue(): Condition[] {
        return [
            {
                variable: null,
                operation: null,
                value: null,
                logical: logicalOperations[0],
                leftBracket: null,
                rightBracket: null,
            },
        ];
    }

    public static isDefaultValue(conditions: Condition[]): boolean {
        return (
            conditions.length === 1 &&
            conditions[0].variable == null &&
            conditions[0].operation == null &&
            conditions[0].value == null &&
            conditions[0].logical === logicalOperations[0] &&
            conditions[0].leftBracket == null &&
            conditions[0].rightBracket == null &&
            conditions[0].isInput == null
        );
    }

    constructor(props: Props) {
        super(props);
        this.selectGrid = {};
        this.state = {
            emptyFocused: new Set(),
            conditions: [],
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.value != null) {
            // Reset input to empty string once the "Reset" button is clicked
            if (
                !ConditionsSelector.isDefaultValue(prevProps.value) &&
                (this.props.value == null ||
                    ConditionsSelector.isDefaultValue(this.props.value))
            ) {
                this.selectGrid = { 0: this.selectGrid[0] };
                if (this.selectGrid[0]["6"] != null) {
                    this.selectGrid[0]["6"].value = "";
                }
            } else if (
                this.props.value != null &&
                prevProps.value.length > this.props.value.length
            ) {
                // Reset input values if an entry was deleted
                for (let i = 0; i < this.props.value.length; ++i) {
                    let condition = this.props.value[i];
                    if (!condition.isInput) {
                        this.selectGrid[i]["6"].value =
                            (getConditionValue(condition) as
                                | string
                                | number
                                | null
                                | undefined) ?? "";
                    }
                }
            }
        }
    }

    private deleteCondition(index: number) {
        this.setState((state) => {
            state.emptyFocused.delete(index);
            return state;
        });
        let lastIndex = (this.props.value ?? []).length - 1;
        this.changeConditions((conditions) => {
            let newConditions = Array.from(conditions);
            newConditions.splice(index, 1);
            if (newConditions.length === 0)
                newConditions.push({
                    variable: null,
                    operation: null,
                    value: null,
                    logical: logicalOperations[0],
                    leftBracket: null,
                    rightBracket: null,
                });
            return newConditions;
        });
        setTimeout(() => {
            delete this.selectGrid[lastIndex];
        }, 0);
    }

    private addCondition(index: number) {
        this.changeConditions((conditions) => {
            let newConditions = Array.from(conditions);
            newConditions.splice(index + 1, 0, {
                variable: null,
                operation: null,
                value: null,
                leftBracket: null,
                rightBracket: null,
                logical: logicalOperations[0],
            });
            return newConditions;
        });
    }

    private changeConditions(func: (conditions: Condition[]) => Condition[]) {
        const conditions: Condition[] = func(this.props.value ?? []);
        this.props.onChange(conditions);
    }

    private selectNextFocusItem(index: number, key: string): void {
        let keys = Object.keys(this.selectGrid[index] ?? {}).sort();
        let currentSubIndex = keys.findIndex((value) => value === key);
        if (currentSubIndex === -1) return;
        if (currentSubIndex < keys.length - 1) {
            this.selectGrid[index][keys[currentSubIndex + 1]]?.focus();
        } else {
            if (index < Object.keys(this.selectGrid).length - 1) {
                let newIndex = index + 1;
                let keys = Object.keys(this.selectGrid[newIndex]).sort();
                this.selectGrid[newIndex][keys[0]]?.focus();
            } else if (this.props.single) {
                this.addCondition(index);
                setTimeout(() => {
                    this.selectNextFocusItem(index, key);
                }, 0);
            }
        }
    }

    // private showPlaceholder(condition: Condition, index: number) {
    //     if (
    //         isEmptyVariableCondition(condition) &&
    //         !this.state.emptyFocused.has(index) &&
    //         this.props.asWizard
    //     )
    //         return true;
    //     return false;
    // }

    private selectPreviousFocusItem(index: number, key: string): void {
        let keys = Object.keys(this.selectGrid[index] ?? {}).sort();
        let currentSubIndex = keys.findIndex((value) => value === key);
        if (currentSubIndex === -1) return;
        if (currentSubIndex > 0) {
            this.selectGrid[index][keys[currentSubIndex - 1]]?.focus();
        } else {
            if (index > 0) {
                setTimeout(() => {
                    this.deleteCondition(index);
                    let newIndex = index - 1;
                    let keys = Object.keys(this.selectGrid[newIndex]).sort();
                    this.selectGrid[newIndex][keys[keys.length - 1]]?.focus();
                }, 0);
            }
        }
    }

    private toFunction<T>(
        value: T | ((rowIndex: number) => T) | undefined | null,
        defaultValue: T
    ): (rowIndex: number) => T {
        if (value == null) {
            return () => defaultValue;
        } else if (typeof value !== "function") {
            return () => value;
        } else {
            return value as (rowIndex: number) => T;
        }
    }

    public render(): JSX.Element {
        const conditionVariablesForSelection: VariableOption[] = this.props.allVariables.map(
            (variable, index) => ({
                label: variable.name,
                value: index,
                type: variable.type,
                panel: variable.panel,
            })
        );
        const leftBrackets: NullableOption[] = [
            {
                label: "(",
                value: "(",
            },
            {
                label: "\u200B",
                value: null,
            },
        ];
        const rightBrackets: NullableOption[] = [
            {
                label: ")",
                value: ")",
            },
            {
                label: "\u200B",
                value: null,
            },
        ];

        // const fieldStyle = () => {
        //     return {
        //         singleValue: (base) => ({
        //             ...base,
        //             paddingLeft: 0
        //         })
        //     }
        // }

        const conditions: Condition[] =
            this.props.value ?? ConditionsSelector.defaultValue;

        const small: boolean = this.props.small ?? false;

        const rowStyle = this.toFunction(this.props.rowStyle, {});
        const rowStyleWithoutPlusMinus = this.toFunction(
            this.props.rowStyleWithoutPlusMinus,
            {}
        );
        const inputStyle = this.toFunction(this.props.inputStyle, {});
        const selectStyle = this.toFunction(this.props.selectStyle, {});
        const buttonsStyle = this.toFunction(this.props.buttonsStyle, {});
        const auxiliarySelectorsStyle = this.toFunction(
            this.props.auxiliarySelectorsStyle,
            {}
        );

        const changeLogicalCondition = (
            newValue: StringOption | null,
            index: number
        ) => {
            if (!conditions[index + 1]) {
                this.addCondition(index);
            }
            this.changeConditions((conditions) => {
                let newConditions: Condition[] = Array.from(conditions);
                newConditions[index + 1] = {
                    ...newConditions[index + 1],
                    logical: (newValue ?? null) as StringOption | null,
                };
                return newConditions;
            });
        };

        const titleStyle = {
            fontFamily: "Roboto",
            paddingLeft: 2,
            marginBottom: 5,
        };

        return (
            <div
                className={"flex-simple-column"}
                tabIndex={0}
                style={{
                    marginTop: small ? 0 : 25,
                    marginLeft: small ? undefined : 100,
                    minHeight: small ? undefined : 300,
                    ...this.props.style,
                    position: "relative",
                    width: "100%",
                    padding: !this.props.tab ? 0 : 10,
                    flexGrow: 1,
                    // overflowX: 'auto',
                    // overflowY: 'hidden'
                }}
                onKeyDown={(evt) => {
                    if (
                        evt.key === "ArrowDown" ||
                        evt.key === "ArrowUp" ||
                        evt.key === "ArrowRight"
                    )
                        evt.stopPropagation();
                }}
            >
                {small && (
                    <span
                        className="exploration-big-title-span"
                        style={this.props.titleStyle}
                    >
                        {this.props.title}
                    </span>
                )}

                {conditions.map((condition: Condition, index: number) => {
                    return (
                        <>
                            <div
                                className={"my-row"}
                                key={index}
                                style={{
                                    marginTop: index > 0 ? 5 : 0,
                                    backgroundColor: mainStyle.getPropertyValue(
                                        "--filters-background-color"
                                    ),
                                    ...rowStyle(index),
                                    gap: this.props.logicalButtons
                                        ? "14px"
                                        : "",
                                    width: "100%",
                                    alignItems: "end",
                                }}
                            >
                                <div
                                    className={cx("my-row")}
                                    key={`${index}_withoutPlusMinus`}
                                    style={
                                        this.props.logicalButtons
                                            ? {
                                                  ...rowStyleWithoutPlusMinus(
                                                      index
                                                  ),
                                                  border: "none",
                                                  gap: "14px",
                                                  width: "83%",
                                                  padding: 0,
                                              }
                                            : {
                                                  flexGrow: 1,
                                                  background: "#f8f8f8",
                                                  padding: "8px",
                                                  borderRadius: 4,
                                              }
                                    }
                                >
                                    {/* Logical */}

                                    {index > 0 && !this.props.logicalButtons && (
                                        <div style={{ width: 50 }}>
                                            <FocusIterable
                                                onNext={() => {
                                                    this.selectNextFocusItem(
                                                        index,
                                                        "0"
                                                    );
                                                }}
                                                onPrevious={() => {
                                                    this.selectPreviousFocusItem(
                                                        index,
                                                        "0"
                                                    );
                                                }}
                                            >
                                                <Select
                                                    isClearable={true}
                                                    menuPlacement="auto"
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    ref={(ref) => {
                                                        this.selectGrid[
                                                            index
                                                        ] = {
                                                            ...this.selectGrid[
                                                                index
                                                            ],
                                                            "0": ref,
                                                        };
                                                    }}
                                                    placeholder={"Logical"}
                                                    styles={
                                                        {
                                                            ...customFilterStyles,
                                                            container: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                height: "38px",
                                                            }),
                                                            ...selectStyle(
                                                                index
                                                            ),
                                                            ...auxiliarySelectorsStyle(
                                                                index
                                                            ),
                                                            menuPortal: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                zIndex: 100000000,
                                                            }),
                                                        } as StylesConfig<
                                                            StringOption,
                                                            false,
                                                            GroupBase<
                                                                StringOption
                                                            >
                                                        >
                                                    }
                                                    options={logicalOperations}
                                                    value={condition.logical}
                                                    onChange={(newValue) => {
                                                        // changeLogicalCondition(newValue, index)\
                                                        this.changeConditions(
                                                            (conditions) => {
                                                                let newConditions: Condition[] = Array.from(
                                                                    conditions
                                                                );
                                                                newConditions[
                                                                    index
                                                                ] = {
                                                                    ...newConditions[
                                                                        index
                                                                    ],
                                                                    logical: (newValue ??
                                                                        null) as StringOption | null,
                                                                };
                                                                return newConditions;
                                                            }
                                                        );
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                />
                                            </FocusIterable>
                                        </div>
                                    )}

                                    {small && index === 0 && this.props.title && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span
                                                className="regular-text"
                                                style={{
                                                    width: "50px",
                                                    textAlign: "center",
                                                    ...this.props.titleStyle,
                                                }}
                                            >
                                                {this.props.title}
                                            </span>
                                        </div>
                                    )}
                                    {((small && !this.props.title) || !small) &&
                                        index === 0 &&
                                        !this.props.logicalButtons &&
                                        this.props.value &&
                                        this.props.value.length > 1 && (
                                            <div
                                                style={{
                                                    width: "50px",
                                                    ...this.props.titleStyle,
                                                }}
                                            ></div>
                                        )}

                                    {/* First Bracket */}
                                    {!this.props.single &&
                                        (!this.props.asWizard ||
                                            condition.variable != null) && (
                                            <div
                                                style={{
                                                    width: "15%",
                                                    marginInline: 1,
                                                    alignSelf: "end",
                                                }}
                                            >
                                                {this.props.tab === "Slice" &&
                                                    index === 0 && (
                                                        <div
                                                            style={{
                                                                ...titleStyle,
                                                            }}
                                                        >
                                                            Bracket
                                                        </div>
                                                    )}
                                                <FocusIterable
                                                    onNext={() => {
                                                        this.selectNextFocusItem(
                                                            index,
                                                            "2"
                                                        );
                                                    }}
                                                    onPrevious={() => {
                                                        this.selectPreviousFocusItem(
                                                            index,
                                                            "2"
                                                        );
                                                    }}
                                                >
                                                    <Select
                                                        filterOption={createFilter(
                                                            {
                                                                ignoreAccents: false,
                                                            }
                                                        )}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "2": ref,
                                                            };
                                                        }}
                                                        placeholder={"Bracket"}
                                                        styles={
                                                            {
                                                                ...customFilterStyles,
                                                                ...selectStyle(
                                                                    index
                                                                ),
                                                                ...auxiliarySelectorsStyle(
                                                                    index
                                                                ),
                                                                ...leftAlignAndScrollBarCSS,
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 100000000,
                                                                }),
                                                                container: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    border:
                                                                        "1px solid lightgrey",
                                                                    // First Bracket
                                                                    width:
                                                                        "100%",
                                                                    // height: "30px",
                                                                    borderRadius: 4,
                                                                    marginInline: 0.5,
                                                                    fontSize: 13,
                                                                }),
                                                                control: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    backgroundColor:
                                                                        "#fff",
                                                                    height: 28,
                                                                    minHeight: 28,
                                                                    border:
                                                                        "none",
                                                                }),
                                                                dropdownIndicator: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    padding: 0,
                                                                }),
                                                                valueContainer: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    paddingLeft: 0,
                                                                }),
                                                                singleValue: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    paddingLeft: 5,
                                                                }),
                                                                placeholder: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    color:
                                                                        "#333",
                                                                    fontSize: 13,
                                                                    paddingLeft: 5,
                                                                }),
                                                            } as StylesConfig<
                                                                NullableOption,
                                                                false,
                                                                GroupBase<
                                                                    NullableOption
                                                                >
                                                            >
                                                        }
                                                        isClearable={true}
                                                        options={leftBrackets}
                                                        value={
                                                            condition.leftBracket
                                                        }
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions: Condition[] = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        leftBracket:
                                                                            newValue?.value !=
                                                                            null
                                                                                ? (newValue as StringOption)
                                                                                : null,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: "white",
                                                                primary25:
                                                                    "var(--selectors-background-hover-color)",
                                                            },
                                                        })}
                                                    />
                                                </FocusIterable>
                                            </div>
                                        )}
                                    {/* Variable */}

                                    <div
                                        style={{
                                            width: "28%",
                                            marginInline: 1,
                                            alignSelf: "end",
                                        }}
                                    >
                                        {this.props.tab === "Slice" &&
                                            index === 0 && (
                                                <div style={{ ...titleStyle }}>
                                                    Variable
                                                </div>
                                            )}
                                        {this.props.logicalButtons &&
                                            index > 0 && (
                                                <div
                                                    className={
                                                        condition.logical
                                                            ?.label === "OR"
                                                            ? "OROperator"
                                                            : "ANDOperator"
                                                    }
                                                >
                                                    {condition.logical?.value}
                                                </div>
                                            )}
                                        <FocusIterable
                                            onNext={() => {
                                                this.selectNextFocusItem(
                                                    index,
                                                    "3"
                                                );
                                            }}
                                            onPrevious={() => {
                                                this.selectPreviousFocusItem(
                                                    index,
                                                    "3"
                                                );
                                            }}
                                        >
                                            <Select
                                                isClearable={true}
                                                menuPlacement="auto"
                                                menuPortalTarget={document.body}
                                                ref={(ref) => {
                                                    this.selectGrid[index] = {
                                                        ...this.selectGrid[
                                                            index
                                                        ],
                                                        "3": ref,
                                                    };
                                                }}
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={"Variable"}
                                                styles={
                                                    {
                                                        ...customFilterStyles,
                                                        ...selectStyle(index),
                                                        ...leftAlignAndScrollBarCSS,
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 100000000,
                                                            // 11 - fontSize, 8 - average number of letters, 100 - extra padding
                                                            minWidth:
                                                                11 * 8 + 100,
                                                        }),
                                                        container: (base) => ({
                                                            ...base,
                                                            border:
                                                                "1px solid lightgrey",
                                                            width: "100%",
                                                            // Varaible
                                                            // width: '140px',
                                                            // height: "30px",
                                                            marginInline: 0.5,
                                                            borderRadius: 4,
                                                            fontSize: 13,
                                                        }),
                                                        valueContainer: (
                                                            base
                                                        ) => ({
                                                            ...base,
                                                            paddingLeft: 0,
                                                        }),
                                                        singleValue: (
                                                            base
                                                        ) => ({
                                                            ...base,
                                                            paddingLeft: 5,
                                                        }),
                                                        control: (base) => ({
                                                            ...base,
                                                            backgroundColor:
                                                                "#fff",
                                                            height: 28,
                                                            minHeight: 28,
                                                            border: "none",
                                                        }),
                                                        dropdownIndicator: (
                                                            base
                                                        ) => ({
                                                            ...base,
                                                            padding: 0,
                                                        }),
                                                        placeholder: (
                                                            base
                                                        ) => ({
                                                            ...base,
                                                            color: "#333",
                                                            fontSize: 13,
                                                            paddingLeft: 5,
                                                        }),
                                                    } as StylesConfig<
                                                        VariableOption,
                                                        false,
                                                        GroupBase<
                                                            VariableOption
                                                        >
                                                    >
                                                }
                                                options={
                                                    conditionVariablesForSelection
                                                }
                                                value={condition.variable}
                                                onChange={(newValue) => {
                                                    this.changeConditions(
                                                        (conditions) => {
                                                            let newConditions = Array.from(
                                                                conditions
                                                            );
                                                            newConditions[
                                                                index
                                                            ] = {
                                                                ...newConditions[
                                                                    index
                                                                ],
                                                                variable: (newValue ??
                                                                    null) as VariableOption | null,
                                                                value: null,
                                                                operation: null,
                                                            };
                                                            return newConditions;
                                                        }
                                                    );
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        </FocusIterable>
                                    </div>

                                    {/* OPerations */}
                                    {(!this.props.asWizard ||
                                        condition.variable != null) && (
                                        <div
                                            style={{
                                                width: "19%",
                                                marginInline: 1,
                                                alignSelf: "end",
                                            }}
                                        >
                                            {this.props.tab === "Slice" &&
                                                index === 0 && (
                                                    <div
                                                        style={{
                                                            ...titleStyle,
                                                        }}
                                                    >
                                                        Operations
                                                    </div>
                                                )}
                                            <FocusIterable
                                                onNext={() => {
                                                    this.selectNextFocusItem(
                                                        index,
                                                        "4"
                                                    );
                                                }}
                                                onPrevious={() => {
                                                    this.selectPreviousFocusItem(
                                                        index,
                                                        "4"
                                                    );
                                                }}
                                            >
                                                <Select
                                                    isClearable={true}
                                                    menuPlacement="auto"
                                                    menuPortalTarget={
                                                        document.body
                                                    }
                                                    ref={(ref) => {
                                                        this.selectGrid[
                                                            index
                                                        ] = {
                                                            ...this.selectGrid[
                                                                index
                                                            ],
                                                            "4": ref,
                                                        };
                                                    }}
                                                    filterOption={createFilter({
                                                        ignoreAccents: false,
                                                    })}
                                                    placeholder={"Operation"}
                                                    styles={
                                                        {
                                                            ...customFilterStyles,
                                                            ...selectStyle(
                                                                index
                                                            ),
                                                            ...leftAlignAndScrollBarCSS,
                                                            container: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                border:
                                                                    "1px solid lightgrey",
                                                                width: "100%",
                                                                // operation
                                                                // width: '100px',
                                                                // height: "30px",
                                                                marginInline: 0.5,
                                                                borderRadius: 4,
                                                                fontSize: 13,
                                                            }),
                                                            control: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                backgroundColor:
                                                                    "#fff",
                                                                height: 28,
                                                                minHeight: 28,
                                                                border: "none",
                                                            }),
                                                            valueContainer: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                paddingLeft: 0,
                                                            }),
                                                            singleValue: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                paddingLeft: 5,
                                                            }),
                                                            menuPortal: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                zIndex: 100000000,
                                                            }),
                                                            dropdownIndicator: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                padding: 0,
                                                            }),
                                                            placeholder: (
                                                                base
                                                            ) => ({
                                                                ...base,
                                                                color: "#333",
                                                                fontSize: 13,
                                                                paddingLeft: 5,
                                                            }),
                                                        } as StylesConfig<
                                                            StringOption,
                                                            false,
                                                            GroupBase<
                                                                StringOption
                                                            >
                                                        >
                                                    }
                                                    options={
                                                        condition.variable !=
                                                            null &&
                                                        ![
                                                            "int",
                                                            "float",
                                                            "datetime",
                                                        ].includes(
                                                            condition.variable
                                                                .type
                                                        )
                                                            ? comparisonPanelOperations
                                                            : comparisonOperations
                                                    }
                                                    onChange={(newValue) => {
                                                        this.changeConditions(
                                                            (conditions) => {
                                                                let newConditions = Array.from(
                                                                    conditions
                                                                );
                                                                newConditions[
                                                                    index
                                                                ] = {
                                                                    ...newConditions[
                                                                        index
                                                                    ],
                                                                    operation: (newValue ??
                                                                        null) as StringOption | null,
                                                                };
                                                                let condition =
                                                                    newConditions[
                                                                        index
                                                                    ];
                                                                if (
                                                                    isMultiCondition(
                                                                        condition
                                                                    )
                                                                ) {
                                                                    if (
                                                                        !Array.isArray(
                                                                            condition.value
                                                                        )
                                                                    ) {
                                                                        condition.value =
                                                                            condition.value !=
                                                                            null
                                                                                ? [
                                                                                      condition.value,
                                                                                  ]
                                                                                : null;
                                                                    }
                                                                } else {
                                                                    if (
                                                                        Array.isArray(
                                                                            condition.value
                                                                        )
                                                                    ) {
                                                                        condition.value =
                                                                            condition
                                                                                .value?.[0] ??
                                                                            null;
                                                                    }
                                                                }
                                                                return newConditions;
                                                            }
                                                        );
                                                    }}
                                                    value={condition.operation}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                />
                                            </FocusIterable>
                                        </div>
                                    )}

                                    {/* Value */}
                                    {this.props.allowLinks &&
                                        (!this.props.asWizard ||
                                            (condition.variable != null &&
                                                condition.operation !=
                                                    null)) && (
                                            <div
                                                style={{
                                                    width: "15%",
                                                    marginInline: 1,
                                                    alignSelf: "end",
                                                    // marginLeft: 5
                                                }}
                                            >
                                                <FocusIterable
                                                    onNext={() => {
                                                        this.selectNextFocusItem(
                                                            index,
                                                            "5"
                                                        );
                                                    }}
                                                    onPrevious={() => {
                                                        this.selectPreviousFocusItem(
                                                            index,
                                                            "5"
                                                        );
                                                    }}
                                                >
                                                    <Select
                                                        isClearable={true}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "5": ref,
                                                            };
                                                        }}
                                                        filterOption={createFilter(
                                                            {
                                                                ignoreAccents: false,
                                                            }
                                                        )}
                                                        placeholder={""}
                                                        styles={
                                                            {
                                                                ...customFilterStyles,
                                                                ...selectStyle(
                                                                    index
                                                                ),
                                                                ...leftAlignAndScrollBarCSS,
                                                                container: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    border:
                                                                        "1px solid lightgrey",
                                                                    backgroundColor:
                                                                        "transparent",
                                                                    width:
                                                                        "100%",
                                                                    // value
                                                                    borderRadius: 4,
                                                                    // width: '82px',

                                                                    marginInline: 0.5,
                                                                    fontSize: 13,
                                                                    // paddingLeft: 5
                                                                }),
                                                                control: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    backgroundColor:
                                                                        "#fff",
                                                                    height: 28,
                                                                    minHeight: 28,
                                                                    border:
                                                                        "none",
                                                                    paddingLeft: 5,
                                                                }),
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 100000000,
                                                                    minWidth: 50,
                                                                }),
                                                                dropdownIndicator: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    padding: 0,
                                                                }),
                                                                placeholder: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    color:
                                                                        "#333",
                                                                    fontSize: 13,
                                                                    marginLeft: 5,
                                                                    paddingLeft: 5,
                                                                }),
                                                                valueContainer: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    paddingLeft: 0,
                                                                }),
                                                                singleValue: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    textAlign:
                                                                        "left",
                                                                    fontSize: 13,
                                                                    paddingLeft: 5,
                                                                }),
                                                            } as StylesConfig<
                                                                StringOption,
                                                                false,
                                                                GroupBase<
                                                                    StringOption
                                                                >
                                                            >
                                                        }
                                                        options={linkOptions}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            let isInput =
                                                                newValue?.value ===
                                                                "link";
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        isInput: isInput,
                                                                        value: null,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                        value={
                                                            condition.isInput
                                                                ? linkOptions[1]
                                                                : linkOptions[0]
                                                        }
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: "white",
                                                                primary25:
                                                                    "var(--selectors-background-hover-color)",
                                                            },
                                                        })}
                                                    />
                                                </FocusIterable>
                                            </div>
                                        )}

                                    {/* Select Somethining */}
                                    {(!this.props.asWizard ||
                                        (condition.variable != null &&
                                            condition.operation != null)) && (
                                        <div
                                            style={{
                                                width: "30%",
                                                marginInline: 1,
                                                alignSelf: "end",
                                            }}
                                        >
                                            {this.props.tab === "Slice" &&
                                                index === 0 && (
                                                    <div
                                                        style={{
                                                            ...titleStyle,
                                                        }}
                                                    >
                                                        Value
                                                    </div>
                                                )}
                                            <FocusIterable
                                                onNext={() => {
                                                    this.selectNextFocusItem(
                                                        index,
                                                        "6"
                                                    );
                                                }}
                                                onPrevious={() => {
                                                    this.selectPreviousFocusItem(
                                                        index,
                                                        "6"
                                                    );
                                                }}
                                            >
                                                {!condition.isInput &&
                                                condition.variable != null &&
                                                (condition.variable.panel !==
                                                    "regular" ||
                                                    condition.variable.type ===
                                                        "str") ? (
                                                    <SearchComponent
                                                        isMulti={isMultiCondition(
                                                            condition
                                                        )}
                                                        allowNewOption={
                                                            condition.variable
                                                                .panel ===
                                                            "time"
                                                        }
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        currentModuleId={
                                                            this.props
                                                                .currentModuleId
                                                        }
                                                        clearable={true}
                                                        switch={
                                                            this.props.switch
                                                        }
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "6": ref,
                                                            };
                                                        }}
                                                        dataScopeId={
                                                            this.props
                                                                .dataScopeId
                                                        }
                                                        customStyles={
                                                            {
                                                                ...customFilterStyles,
                                                                ...selectStyle(
                                                                    index
                                                                ),
                                                                ...leftAlignAndScrollBarCSS,
                                                                container: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    border:
                                                                        "1px solid lightgrey",
                                                                    backgroundColor:
                                                                        "transparent",
                                                                    width:
                                                                        "100%",
                                                                    // select something
                                                                    borderRadius: 4,
                                                                    // width: '147px',
                                                                    // height: "30px",
                                                                    marginInline: 0.5,
                                                                    fontSize: 13,
                                                                }),
                                                                control: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    backgroundColor:
                                                                        "#fff",
                                                                    height: 28,
                                                                    minHeight: 28,
                                                                    border:
                                                                        "none",
                                                                    borderRadius: 4,
                                                                    flexWrap:
                                                                        "nowrap",
                                                                }),
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 100000000,
                                                                }),
                                                                dropdownIndicator: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    padding: 0,
                                                                }),
                                                                placeholder: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    overflow:
                                                                        "hidden",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                    fontSize: 13,
                                                                    color:
                                                                        "#333",
                                                                    paddingLeft: 5,
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }),
                                                                singleValue: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    fontSize: 13,
                                                                    paddingLeft: 5,
                                                                }),
                                                                input: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    paddingLeft: 5,
                                                                    gridArea:
                                                                        isMultiCondition(
                                                                            condition
                                                                        ) &&
                                                                        conditions[0]
                                                                            .value
                                                                            ? "auto"
                                                                            : "1/1/2/3",
                                                                }),
                                                                valueContainer: (
                                                                    base
                                                                ) =>
                                                                    isMultiCondition(
                                                                        condition
                                                                    ) &&
                                                                    conditions[0]
                                                                        .value
                                                                        ? {
                                                                              ...base,
                                                                              display:
                                                                                  "grid",
                                                                              gridAutoFlow:
                                                                                  "column",
                                                                              justifyContent:
                                                                                  "flex-start",
                                                                          }
                                                                        : {
                                                                              ...base,
                                                                          },
                                                                multiValue: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    backgroundColor:
                                                                        "transparent",
                                                                }),
                                                            } as StylesConfig<
                                                                SearchComponentOption,
                                                                false,
                                                                GroupBase<
                                                                    SearchComponentOption
                                                                >
                                                            >
                                                        }
                                                        initialValue={
                                                            condition.value
                                                        }
                                                        searchIndex={
                                                            condition.variable
                                                                .value
                                                        }
                                                        onOptionSelected={(
                                                            selectedValue
                                                        ) => {
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    if (
                                                                        isMultiCondition(
                                                                            condition
                                                                        ) &&
                                                                        Array.isArray(
                                                                            selectedValue
                                                                        ) &&
                                                                        selectedValue.length ===
                                                                            0
                                                                    ) {
                                                                        selectedValue = null;
                                                                    }
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        value: selectedValue,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                    />
                                                ) : !condition.isInput ? (
                                                    <input
                                                        onMouseDown={(evt) => {
                                                            evt.stopPropagation();
                                                        }}
                                                        className={`${styles.inputOutline} like-select`}
                                                        // className="like-select"
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "6": ref,
                                                            };
                                                        }}
                                                        style={{
                                                            color: mainStyle.getPropertyValue(
                                                                "--filters-text-color"
                                                            ),
                                                            ...inputStyle(
                                                                index
                                                            ),
                                                            ...(this.props
                                                                .asWizard &&
                                                            !getConditionValue(
                                                                condition
                                                            )
                                                                ? {
                                                                      backgroundColor:
                                                                          "transparent",
                                                                  }
                                                                : undefined),
                                                            border:
                                                                "1px solid lightgrey",
                                                            borderRadius: 4,
                                                            height: "30px",
                                                            // width: '147px',
                                                            width: "100%",
                                                            marginInline: 0.5,
                                                            textAlign: "left",
                                                            fontSize: 13,
                                                            backgroundColor:
                                                                "#fff",
                                                        }}
                                                        placeholder=""
                                                        onKeyDown={(e) => {
                                                            e.stopPropagation();
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                const value: string =
                                                                    e
                                                                        .currentTarget
                                                                        .value;
                                                                this.changeConditions(
                                                                    (
                                                                        conditions
                                                                    ) => {
                                                                        let newConditions = Array.from(
                                                                            conditions
                                                                        );
                                                                        newConditions[
                                                                            index
                                                                        ] = {
                                                                            ...newConditions[
                                                                                index
                                                                            ],
                                                                            value: {
                                                                                label: value,
                                                                                value: value,
                                                                            },
                                                                        };
                                                                        return newConditions;
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            const value: string =
                                                                e.target.value;
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        value: {
                                                                            label: value,
                                                                            value: value,
                                                                        },
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                        defaultValue={
                                                            (getConditionValue(
                                                                condition
                                                            ) as
                                                                | string
                                                                | number
                                                                | null
                                                                | undefined) ??
                                                            ""
                                                        }
                                                    />
                                                ) : (
                                                    <Select
                                                        menuPlacement="auto"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        isMulti={isMultiCondition(
                                                            condition
                                                        )}
                                                        isClearable={true}
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "6": ref,
                                                            };
                                                        }}
                                                        filterOption={createFilter(
                                                            {
                                                                ignoreAccents: false,
                                                            }
                                                        )}
                                                        placeholder={""}
                                                        styles={
                                                            {
                                                                ...customFilterStyles,
                                                                ...selectStyle(
                                                                    index
                                                                ),
                                                                ...leftAlignAndScrollBarCSS,
                                                                container: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    width:
                                                                        "100%",
                                                                    // Select date
                                                                    borderRadius: 4,
                                                                    // width: '147px',
                                                                    height:
                                                                        "30px",
                                                                    marginInline: 0.5,
                                                                    border:
                                                                        "1px solid lightgrey",
                                                                    fontSize: 13,
                                                                }),
                                                                control: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    backgroundColor:
                                                                        "#fff",
                                                                    height: 28,
                                                                    minHeight: 28,
                                                                    border:
                                                                        "none",
                                                                }),
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 100000000,
                                                                    minWidth:
                                                                        11 *
                                                                            (condition
                                                                                .variable
                                                                                ?.label
                                                                                .length ??
                                                                                1) + // 11 - fontSize, 60 - extra padding
                                                                        60,
                                                                }),

                                                                placeholder: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    color:
                                                                        "#333",
                                                                    paddingLeft: 5,
                                                                    fontSize: 13,
                                                                }),
                                                                singleValue: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    fontSize: 13,
                                                                    paddingLeft: 5,
                                                                }),
                                                                input: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    maxWidth: 138,
                                                                }),
                                                            } as StylesConfig<
                                                                | NodeLinkOption
                                                                | SearchComponentOption,
                                                                boolean,
                                                                GroupBase<
                                                                    | NodeLinkOption
                                                                    | SearchComponentOption
                                                                >
                                                            >
                                                        }
                                                        options={
                                                            this.props
                                                                .nodeLinkOptions
                                                        }
                                                        value={condition.value}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    if (
                                                                        isMultiCondition(
                                                                            condition
                                                                        ) &&
                                                                        Array.isArray(
                                                                            newValue
                                                                        ) &&
                                                                        newValue.length ===
                                                                            0
                                                                    ) {
                                                                        newValue = null;
                                                                    }
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        value: newValue as
                                                                            | NodeLinkOption
                                                                            | NodeLinkOption[]
                                                                            | null,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: "white",
                                                                primary25:
                                                                    "var(--selectors-background-hover-color)",
                                                            },
                                                        })}
                                                    />
                                                )}
                                            </FocusIterable>
                                        </div>
                                    )}
                                    {/* Last Brackeet */}

                                    {!this.props.single &&
                                        (!this.props.asWizard ||
                                            condition.variable != null) && (
                                            <div
                                                style={{
                                                    width: "15%",
                                                    marginInline: 1.5,
                                                    alignSelf: "end",
                                                }}
                                            >
                                                {this.props.tab === "Slice" &&
                                                    index === 0 && (
                                                        <div
                                                            style={{
                                                                ...titleStyle,
                                                            }}
                                                        >
                                                            Bracket
                                                        </div>
                                                    )}
                                                <FocusIterable
                                                    onNext={() => {
                                                        this.selectNextFocusItem(
                                                            index,
                                                            "7"
                                                        );
                                                    }}
                                                    onPrevious={() => {
                                                        this.selectPreviousFocusItem(
                                                            index,
                                                            "7"
                                                        );
                                                    }}
                                                >
                                                    <Select
                                                        isClearable={true}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        ref={(ref) => {
                                                            this.selectGrid[
                                                                index
                                                            ] = {
                                                                ...this
                                                                    .selectGrid[
                                                                    index
                                                                ],
                                                                "7": ref,
                                                            };
                                                        }}
                                                        filterOption={createFilter(
                                                            {
                                                                ignoreAccents: false,
                                                            }
                                                        )}
                                                        placeholder={"Bracket"}
                                                        styles={
                                                            {
                                                                ...customFilterStyles,
                                                                ...selectStyle(
                                                                    index
                                                                ),
                                                                ...auxiliarySelectorsStyle(
                                                                    index
                                                                ),
                                                                ...leftAlignAndScrollBarCSS,
                                                                menuPortal: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    zIndex: 100000000,
                                                                }),
                                                                valueContainer: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    paddingLeft: 0,
                                                                }),
                                                                singleValue: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    paddingLeft: 5,
                                                                }),
                                                                container: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    //    Last-Bracket
                                                                    borderRadius: 4,
                                                                    // width: '85px',
                                                                    width:
                                                                        "100%",
                                                                    // height: "30px",
                                                                    border:
                                                                        "1px solid lightgrey",
                                                                    fontSize: 13,
                                                                }),
                                                                control: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    backgroundColor:
                                                                        "#fff",
                                                                    height: 28,
                                                                    minHeight: 28,
                                                                    border:
                                                                        "none",
                                                                }),
                                                                dropdownIndicator: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    padding: 0,
                                                                }),
                                                                placeholder: (
                                                                    base
                                                                ) => ({
                                                                    ...base,
                                                                    color:
                                                                        "#333",
                                                                    fontSize: 13,
                                                                    paddingLeft: 5,
                                                                }),
                                                            } as StylesConfig<
                                                                NullableOption,
                                                                false,
                                                                GroupBase<
                                                                    NullableOption
                                                                >
                                                            >
                                                        }
                                                        options={rightBrackets}
                                                        value={
                                                            condition.rightBracket
                                                        }
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            this.changeConditions(
                                                                (
                                                                    conditions
                                                                ) => {
                                                                    let newConditions = Array.from(
                                                                        conditions
                                                                    );
                                                                    newConditions[
                                                                        index
                                                                    ] = {
                                                                        ...newConditions[
                                                                            index
                                                                        ],
                                                                        rightBracket:
                                                                            newValue?.value !=
                                                                            null
                                                                                ? (newValue as StringOption)
                                                                                : null,
                                                                    };
                                                                    return newConditions;
                                                                }
                                                            );
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                text: "white",
                                                                primary25:
                                                                    "var(--selectors-background-hover-color)",
                                                            },
                                                        })}
                                                    />
                                                </FocusIterable>
                                            </div>
                                        )}
                                </div>
                                {/* // )} */}

                                {this.props.logicalButtons ? (
                                    <div
                                        style={{ margin: "0px 5px 0px auto " }}
                                    >
                                        {this.props.tab === "Slice" &&
                                            index === 0 && (
                                                <div
                                                    style={{ height: 23 }}
                                                ></div>
                                            )}
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                height: 29,
                                                fontSize: 13,
                                            }}
                                        >
                                            {logicalOperations.map(
                                                (item, i) =>
                                                    conditions[index + 1]
                                                        ?.logical?.label !==
                                                        item.label && (
                                                        <button
                                                            className="maareBtn"
                                                            onClick={() => {
                                                                changeLogicalCondition(
                                                                    item,
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            {item.label}
                                                        </button>
                                                    )
                                            )}
                                            <CrossIcon
                                                stroke="var(--acceptBtn-color)"
                                                className="test"
                                                onClick={() => {
                                                    this.deleteCondition(index);
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    !this.props.single && (
                                        <div
                                            className="flex-simple-column"
                                            style={{
                                                marginLeft: 5,
                                                padding: "0 5px",
                                            }}
                                        >
                                            <Button
                                                className="btn-small-like-select"
                                                style={{
                                                    backgroundColor: mainStyle.getPropertyValue(
                                                        "--filters-background-color"
                                                    ),
                                                    color: mainStyle.getPropertyValue(
                                                        "--filters-text-color"
                                                    ),
                                                    width: "19px",
                                                    height: "19px",
                                                    ...buttonsStyle(index),
                                                }}
                                                onClick={() => {
                                                    this.addCondition(index);
                                                }}
                                            >
                                                {"\uFF0B" /* plus */}
                                            </Button>
                                            <Button
                                                className="btn-small-like-select"
                                                style={{
                                                    backgroundColor: mainStyle.getPropertyValue(
                                                        "--filters-background-color"
                                                    ),
                                                    color: mainStyle.getPropertyValue(
                                                        "--filters-text-color"
                                                    ),
                                                    width: "19px",
                                                    height: "19px",
                                                    ...buttonsStyle(index),
                                                }}
                                                onClick={() => {
                                                    this.deleteCondition(index);
                                                }}
                                            >
                                                {"\uFF0D" /* minus */}
                                            </Button>
                                        </div>
                                    )
                                )}
                            </div>
                        </>
                    );
                })}
            </div>
        );
    }
}

export { conditionsToString, conditionsToJson, ConditionsSelector };
