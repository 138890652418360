import React from "react";
import Select, { createFilter } from "react-select";
import {
    dataScienceSelectStyles,
    Styles,
    getCustomSelectStyleForDataSection,
} from "common/SelectStyles";
import { DataScopeOption } from "common/DataScopes";
import { VariableJoin } from "common/MergeData";
import { observer } from "mobx-react";
import Variables, { VariableOption } from "common/Variables";
import { Button } from "react-bootstrap";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";

interface Props {
    dataHubMode?: boolean;
    leftDataScope: DataScopeOption | null;
    rightDataScope: DataScopeOption | null;
    variableJoins: VariableJoin[];
    onChange: (variableJoins: VariableJoin[]) => void;
    maxHeight?: number;
    currentModuleId?: number;
}

const VariablePairQuestionView = observer(function VariablePairQuestionView(
    props: Props
) {
    let leftVariableOptions =
        props.leftDataScope == null
            ? []
            : Variables(
                  props.leftDataScope.value,
                  props.currentModuleId ?? remoteModuleId
              ).variableOptions;
    let rightVariableOptions =
        props.rightDataScope == null
            ? []
            : Variables(
                  props.rightDataScope.value,
                  props.currentModuleId ?? remoteModuleId
              ).variableOptions;
    let dataHubStyles: Styles | undefined = props.dataHubMode
        ? {
              ...getCustomSelectStyleForDataSection(14, false),
              container: (base) => ({
                  ...base,
                  width: 270,
                  height: "35px",
              }),
          }
        : undefined;
    return (
        <div
            className="flex-simple-column"
            style={{
                maxHeight: props.maxHeight,
                height: props.maxHeight,
                marginLeft: props.dataHubMode ? "0px" : "90px",
            }}
        >
            {props.variableJoins.map((value, index) => (
                <React.Fragment key={index}>
                    <div
                        className="my-row"
                        style={{
                            marginTop: props.dataHubMode && index > 0 ? 10 : 0,
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt) => {
                            evt.stopPropagation();
                        }}
                    >
                        <Select
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Select variable"}
                            styles={
                                dataHubStyles ?? {
                                    ...dataScienceSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "50%",
                                        height: "35px",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 51,
                                    }),
                                }
                            }
                            options={leftVariableOptions}
                            onChange={(newValue) => {
                                let variableJoins = Array.from(
                                    props.variableJoins
                                );
                                variableJoins[index].leftVariable =
                                    newValue as VariableOption;
                                props.onChange(variableJoins);
                            }}
                            value={value?.leftVariable}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <div style={{width: 40}}></div>
                        <Select
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Select variable"}
                            styles={
                                dataHubStyles ?? {
                                    ...dataScienceSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginLeft: 5,
                                        width: "50%",
                                        height: "35px",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 51,
                                    }),
                                }
                            }
                            options={rightVariableOptions}
                            onChange={(newValue) => {
                                let variableJoins = Array.from(
                                    props.variableJoins
                                );
                                variableJoins[index].rightVariable =
                                    newValue as VariableOption;
                                props.onChange(variableJoins);
                            }}
                            value={value?.rightVariable}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <div style={{ flexGrow: 1 }} />
                        <div
                            className="flex-simple-column"
                            style={{ marginLeft: 5 }}
                        >
                            <Button
                                className={"btn-small-questionnaire"}
                                title={"Add join"}
                                style={{
                                    width: "19px",
                                    height: "19px",
                                    border: props.dataHubMode
                                        ? "none"
                                        : undefined,
                                }}
                                onClick={() => {
                                    let variableJoins = Array.from(
                                        props.variableJoins
                                    );
                                    variableJoins.push({
                                        leftVariable: null,
                                        rightVariable: null,
                                    });
                                    props.onChange(variableJoins);
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>
                            {props.variableJoins.length > 1 && (
                                <Button
                                    className={"btn-small-questionnaire"}
                                    title={"Remove join"}
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                        border: props.dataHubMode
                                            ? "none"
                                            : undefined,
                                    }}
                                    onClick={() => {
                                        let variableJoins = Array.from(
                                            props.variableJoins
                                        );
                                        variableJoins.splice(index, 1);
                                        props.onChange(variableJoins);
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            )}
                        </div>
                    </div>
                    {!props.dataHubMode && (
                        <div
                            style={{
                                height: "2px",
                                backgroundColor:
                                    dataScienceElementsStyle.secondaryTextColor,
                                marginBottom: "20px",
                            }}
                        />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
});
export default VariablePairQuestionView;
