// Processes Tab and Shift+Tab for <textarea> code editors

function processTab(
    e: React.KeyboardEvent<HTMLTextAreaElement>,
    onChangeState: (
        newSelectionStart: number,
        newSelectionEnd: number,
        newValue: string
    ) => void
) {
    const tabSize = 4;
    const tab = " ".repeat(tabSize);
    if (e.key === "Tab" && !e.shiftKey) {
        e.preventDefault();
        const value = e.currentTarget.value;
        const selectionStart = e.currentTarget.selectionStart!;
        const selectionEnd = e.currentTarget.selectionEnd!;

        let newValue;
        let newSelectionStart;
        let newSelectionEnd;
        if (selectionStart === selectionEnd) {
            newValue =
                value.substring(0, selectionStart) +
                tab +
                value.substring(selectionEnd);
            newSelectionStart = selectionStart + tabSize;
            newSelectionEnd = selectionEnd + tabSize;
        } else {
            const previousLineIndex =
                value.substring(0, selectionStart).lastIndexOf("\n") + 1;
            const beforeLines = value.substring(0, previousLineIndex);
            const afterLines = value.substring(selectionEnd);
            const lines = value.substring(previousLineIndex, selectionEnd);

            newValue =
                beforeLines +
                tab +
                lines.replace(/[\n]/g, `\n${tab}`) +
                afterLines;
            newSelectionStart = selectionStart + tabSize;
            newSelectionEnd = selectionEnd + newValue.length - value.length;
        }
        onChangeState(newSelectionStart, newSelectionEnd, newValue);
    } else if (e.key === "Tab" && e.shiftKey) {
        e.preventDefault();
        const value = e.currentTarget.value;
        const selectionStart = e.currentTarget.selectionStart!;
        const selectionEnd = e.currentTarget.selectionEnd!;

        const previousLineIndex =
            value.substring(0, selectionStart).lastIndexOf("\n") + 1;
        const beforeLines = value.substring(0, previousLineIndex);
        const afterLines = value.substring(selectionEnd);
        const lines = value.substring(previousLineIndex, selectionEnd);
        let newLines = lines.split("\n");
        const previousFirstLineLength = newLines[0].length;
        newLines = newLines.map((value: string) =>
            value.replace(new RegExp(`^ {1,${tabSize}}`), "")
        );

        const newSelectionStart = Math.max(
            selectionStart - (previousFirstLineLength - newLines[0].length),
            previousLineIndex
        );
        let newLinesString = newLines.join("\n");

        const newValue = beforeLines + newLinesString + afterLines;
        const newSelectionEnd = selectionEnd - (value.length - newValue.length);
        onChangeState(newSelectionStart, newSelectionEnd, newValue);
    }
}

export default processTab;