import StatusPopup, { PopupStatus } from "common/StatusPopup";
import React, { useEffect, useState } from "react";
import {
    CurrentPlan,
    getCurrentPremiumPlan,
    getPremiumPlans,
    PremiumPlan,
    premiumPlanSubscribe,
    premiumPlanUnsubscribe,
    sendEnterpriseNotification,
} from "../PremiumPlansApi";
import cx from "classnames";
import styles from "./styles.module.css";
import { ReactComponent as CrossIcon } from "icons/upgrade_plan/cross.svg";
import { ReactComponent as Circle } from "icons/upgrade_plan/feature_circle.svg";
import { goToInternalLink } from "common/InternalLinksHelper";
import { UserTypeIndex } from "modules/settings_page/common/UserType";

function applyUnselectable(style: string) {
    return cx(style, "unselectable");
}

const ENTERPRISE_ID = -10;
const FREE_ID = -1;

const enterprisePremiumPlan: PremiumPlan = {
    name: "Enterprise",
    price: 0,
    payment_type: "yearly",
    id: ENTERPRISE_ID,
    description: "Best-in-class support tailored to your needs",
    features: ["Custom Data", "Custom Builds", "White Glove Support"],
    is_enterprise: true,
};

const UpgradeToPremium: React.FC<Props> = ({ onClose }) => {
    const [premiumPlans, setPremiumPlans] = useState<PremiumPlan[]>([
        // {
        //     name: "",
        //     price: 0,
        //     payment_type: "monthly",
        //     id: -1,
        //     description: "",
        //     features: [],
        // },
        // {
        //     name: "",
        //     price: 0,
        //     payment_type: "monthly",
        //     id: -1,
        //     description: "",
        //     features: [],
        // },
        // {
        //     name: "",
        //     price: 0,
        //     payment_type: "monthly",
        //     id: -1,
        //     description: "",
        //     features: [],
        // },
    ]);

    const [currentPlan, setCurrentPlan] = useState<CurrentPlan | null>(null);
    const [subscribing, setSubscribing] = useState<boolean>(false);
    const [status, setStatus] = useState<{
        message: string;
        status: PopupStatus;
    } | null>(null);
    useEffect(() => {
        getCurrentPremiumPlan()
            .then((premiumPlan) => {
                setCurrentPlan(premiumPlan);
                getPremiumPlans()
                    .then((premiumPlans) => {
                        setPremiumPlans(premiumPlans);
                    })
                    .catch((error) => {
                        setStatus({
                            message: String(error),
                            status: PopupStatus.Error,
                        });
                    });
            })
            .catch((error) => {
                setStatus({
                    message: String(error),
                    status: PopupStatus.Error,
                });
            });
    }, []);
    let activePlan: CurrentPlan | null = null;
    let isSpecialCase = false;
    if (currentPlan != null) {
        if (
            currentPlan.user_type != null &&
            currentPlan.user_type <= UserTypeIndex.Creator &&
            currentPlan.id === FREE_ID
        ) {
            isSpecialCase = true;
            let specialCasePlan = premiumPlans.find((plan) => plan.id > 0);
            if (specialCasePlan != null) {
                activePlan = {
                    id: specialCasePlan.id,
                    active: true,
                };
            }
        } else {
            activePlan = currentPlan;
        }
    }

    return (
        <>
            <div className={styles.background} />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.topContainer}>
                        <div className={applyUnselectable(styles.title)}>
                            Upgrade plan
                        </div>
                        <div
                            style={{
                                cursor: "pointer",
                                marginTop: -10,
                            }}
                            onClick={onClose}
                        >
                            <CrossIcon />
                        </div>
                    </div>
                    <div className={styles.cardsContainer}>
                        {premiumPlans
                            .concat([enterprisePremiumPlan])
                            .map((item, index) => (
                                <div
                                    className={cx(
                                        styles.cardContainer,
                                        item.id === activePlan?.id &&
                                            activePlan?.active &&
                                            styles.cardContainerCurrent
                                    )}
                                    key={index}
                                >
                                    <div
                                        className={applyUnselectable(
                                            styles.cardTitle
                                        )}
                                    >
                                        {item.name}
                                    </div>
                                    <div
                                        className={applyUnselectable(
                                            styles.cardSubtitle
                                        )}
                                    >
                                        {item.description}
                                    </div>
                                    {(item.price === 0 && !item?.is_enterprise) ? <div className={styles.oneLineSeparator}></div> : <div className={styles.twoLineSeparator}></div>}
                                    <div
                                        className={styles.pricePaymentContainer}
                                    >
                                        <div
                                            className={applyUnselectable(
                                                styles.price
                                            )}
                                        >
                                            {item?.is_enterprise
                                                ? "Custom Pricing"
                                                : item.price === 0 ? "Free" : `$${item.price}`}
                                        </div>
                                        <div
                                            className={applyUnselectable(
                                                styles.paymentType
                                            )}
                                        >
                                            {item?.is_enterprise
                                                ? ""
                                                : item.price === 0 ? "" : `/${item.payment_type}`}
                                        </div>
                                    </div>
                                    {item.features.map((feature, index) => (
                                        <div
                                            className={applyUnselectable(
                                                styles.feature
                                            )}
                                            key={index}
                                        >
                                            <Circle />
                                            <div style={{ width: 9.25 }}></div>
                                            {feature}
                                        </div>
                                    ))}
                                    <div style={{ flexGrow: 1 }} />
                                    <button
                                        className={styles.selectButton}
                                        disabled={
                                            subscribing ||
                                            (isSpecialCase &&
                                                item.id === FREE_ID)
                                        }
                                        onClick={() => {
                                            if (item.is_enterprise) {
                                                setSubscribing(true);
                                                setStatus({
                                                    message:
                                                        "Sending information...",
                                                    status: PopupStatus.Success,
                                                });
                                                sendEnterpriseNotification()
                                                    .then(() => {
                                                        setSubscribing(false);
                                                        setStatus({
                                                            message:
                                                                "Thank you. We'll contact you soon",
                                                            status: PopupStatus.Success,
                                                        });
                                                    })
                                                    .catch((error) => {
                                                        setSubscribing(false);
                                                        setStatus({
                                                            message:
                                                                String(error),
                                                            status: PopupStatus.Error,
                                                        });
                                                    });
                                                return;
                                            }

                                            let current =
                                                item.id === activePlan?.id &&
                                                activePlan?.active;
                                            if (current) return;

                                            if (item.id === FREE_ID) {
                                                setSubscribing(true);
                                                setStatus({
                                                    message:
                                                        "Changing subscription...",
                                                    status: PopupStatus.Success,
                                                });
                                                premiumPlanUnsubscribe()
                                                    .then(() => {
                                                        setSubscribing(false);
                                                        setStatus({
                                                            message:
                                                                "Your subscription will be cancelled by the end of period",
                                                            status: PopupStatus.Success,
                                                        });
                                                    })
                                                    .catch((error) => {
                                                        setSubscribing(false);
                                                        setStatus({
                                                            message:
                                                                String(error),
                                                            status: PopupStatus.Error,
                                                        });
                                                    });
                                            } else {
                                                setSubscribing(true);
                                                setStatus({
                                                    message:
                                                        "Changing subscription...",
                                                    status: PopupStatus.Success,
                                                });
                                                premiumPlanSubscribe(item.id)
                                                    .then((redirectUrl) => {
                                                        if (!redirectUrl) {
                                                            setSubscribing(
                                                                false
                                                            );
                                                            setStatus({
                                                                message:
                                                                    "Your account was upgraded. Page will be reloaded",
                                                                status: PopupStatus.Success,
                                                            });
                                                            setCurrentPlan({
                                                                id: item.id,
                                                                active: true,
                                                            });
                                                            setTimeout(() => {
                                                                window.location.reload();
                                                            }, 1000);
                                                        } else {
                                                            goToInternalLink(
                                                                redirectUrl
                                                            );
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        setSubscribing(false);
                                                        setStatus({
                                                            message:
                                                                String(error),
                                                            status: PopupStatus.Error,
                                                        });
                                                    });
                                            }
                                        }}
                                    >
                                        {item?.is_enterprise
                                            ? "Contact Us"
                                            : item.id === activePlan?.id &&
                                              activePlan?.active
                                            ? "Сurrent plan"
                                            : item?.id === FREE_ID
                                            ? "Downgrade"
                                            : "Select"}
                                    </button>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            {status != null && (
                <StatusPopup
                    status={status.status}
                    message={status.message}
                    onClose={() => {
                        setStatus(null);
                    }}
                />
            )}
        </>
    );
};

interface Props {
    onClose: () => void;
}

export default UpgradeToPremium;
