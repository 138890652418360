import React from "react";
import { colorList } from "./LineColors";
import { formatValue } from "common/utilities/FormatValue";
import CustomizedAxisTick from "./CustomizedAxisTick";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";
import CustomHistogramTooltip from "./CustomHistogramTooltip";

import {
    Bar,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    ReferenceLine,
} from "recharts";
import ReferenceLabel from "./ReferenceLabel";
import { TooltipStyles } from "./TooltipStyles";
import { formatTimestamp } from "common/utilities/TimeFormatUtils";

export interface Histogram {
    "%hist": number[];
    "%bin_edges": number[];
}

interface Props {
    nameMapping?: { [key: string]: string };
    data: Histogram[];
    variables: string[];
    units: string[];
    additionalData?: number[];
    axesColor?: string;
    chartColor?: string;
    linesCount?: number | null;
    maxYRange?: number | null;
    minYRange?: number | null;
    barSize?: number | null;
    dateFormat?: string;
    angle?: number;
    chartMargin?: {
        left?: number;
        right?: number;
        top?: number;
        bottom?: number;
    }
}

export function HistogramChartInner(props: {
    hideWatermark?: boolean;
    data: Histogram;
    variable: string;
    unit: string;
    additionalLine?: number;
    nameMapping?: { [key: string]: string };
    axesColor?: string;
    chartColor?: string;
    linesCount?: number | null;
    maxYRange?: number | null;
    minYRange?: number | null;
    barSize?: number | null;
    optionalStyles?: React.CSSProperties;
    dateFormat?: string;
    angle?: number;
    chartMargin?: {
        left?: number;
        right?: number;
        top?: number;
        bottom?: number;
    }
}) {
    let {
        data,
        barSize,
        variable,
        unit,
        additionalLine,
        nameMapping,
        axesColor,
        chartColor,
        minYRange,
        maxYRange,
        linesCount,
    } = props;
    let x = new Array(data["%bin_edges"].length - 1).fill(0);
    for (let i = 0; i < data["%bin_edges"].length - 1; ++i) {
        x[i] = (data["%bin_edges"][i] + data["%bin_edges"][i + 1]) / 2;
    }
    let minX = 0;
    let maxX = 0;
    if (data["%bin_edges"].length > 0) {
        minX = data["%bin_edges"][0];
        maxX = data["%bin_edges"][data["%bin_edges"].length - 1];
    }

    let modifiedData = data["%hist"].map((_, index) => ({
        x: x[index],
        y: data["%hist"][index],
    }));
    barSize = barSize ?? 50;
    let binCount = modifiedData.length;

    let plot = (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                height: "100%",
                paddingRight: 75,
                ...props.optionalStyles
            }}
        >
            <ResponsiveContainer height="100%" width={barSize * binCount}>
                <BarChart
                    barSize={barSize}
                    barGap={1}
                    data={modifiedData}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 20,
                        bottom: 5,
                        ...props.chartMargin
                    }}
                >
                    {!props.hideWatermark && sections._Global.watermark != null && (
                        <text
                            x="50%"
                            y="30%"
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill={mainStyle.getPropertyValue(
                                "--content-secondary-text-color"
                            )}
                            fontSize={"4rem"}
                            style={{
                                opacity: 0.1,
                            }}
                        >
                            {sections._Global.watermark}
                        </text>
                    )}
                    <CartesianGrid
                        stroke={mainStyle.getPropertyValue(
                            "--graphs-stroke-color"
                        )}
                        vertical={false}
                        strokeWidth={1}
                    />
                    <XAxis
                        interval={0}
                        ticks={data["%bin_edges"]}
                        tick={
                            <CustomizedAxisTick
                                customFormatFunction={props.dateFormat != null ? (value: string | number)=>{
                                    return formatTimestamp(value as number, props.dateFormat!)
                                } : undefined}
                                unit={unit}
                                axesColor={axesColor}
                                formatValues={true}
                                truncValues={false}
                                fontSize={10}
                                dx={16}
                                dy={16}
                                angle={props.angle}
                            />
                        }
                        dataKey={"x"}
                        type="number"
                        domain={[
                            (dataMin: number) => minX,
                            (dataMax: number) => maxX,
                        ]}
                        allowDuplicatedCategory={false}
                        stroke={mainStyle.getPropertyValue(
                            "--graphs-stroke-color"
                        )}
                    />
                    <YAxis
                        interval={0}
                        allowDataOverflow={
                            minYRange != null || maxYRange != null
                        }
                        type="number"
                        dataKey={"y"}
                        tickCount={linesCount ?? undefined}
                        domain={[
                            minYRange ?? "dataMin",
                            maxYRange ?? "dataMax",
                        ]}
                        axisLine={false}
                        tickLine={false}
                        tick={
                            <CustomizedAxisTick
                                axesColor={axesColor}
                                formatValues={true}
                                truncValues={false}
                                fontSize={10}
                                dx={0}
                                dy={0}
                            />
                        }
                    />

                    <Tooltip
                        wrapperStyle={{
                            visibility: "visible",
                        }}
                        cursor={false}
                        content={
                            <CustomHistogramTooltip
                                axes={x}
                                values={data["%hist"]}
                            />
                        }
                        formatter={(value: any, name: string, props: any) => {
                            let formattedValue = formatValue(
                                value as string,
                                false
                            );
                            let result = formattedValue[0].concat(
                                formattedValue[1]
                            );

                            return [result, nameMapping?.[name] ?? name];
                        }}
                        {...TooltipStyles()}
                    />
                    <Bar dataKey="y" fill={chartColor ?? colorList[1]} />
                    {additionalLine && (
                        <ReferenceLine
                            label={
                                <ReferenceLabel
                                    dx={-15}
                                    dy={20}
                                    fontSize={mainStyle.getPropertyValue(
                                        "--graphs-axes-text-size"
                                    )}
                                    fillText={
                                        axesColor ??
                                        mainStyle.getPropertyValue(
                                            "--graphs-axes-text-color"
                                        )
                                    }
                                    fillBackground={"transparent"}
                                    value={String(additionalLine)}
                                    textAnchor={"start"}
                                />
                            }
                            x={additionalLine}
                            stroke={"#39F"}
                            strokeWidth={2}
                        />
                    )}
                </BarChart>
            </ResponsiveContainer>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <span
                    style={{
                        color:
                            axesColor ??
                            mainStyle.getPropertyValue(
                                "--graphs-axes-text-color"
                            ),
                        fontFamily: "Arial",
                        fontSize:
                            mainStyle.getPropertyValue("--graphs-axes-size"),
                    }}
                >
                    {props.nameMapping?.[variable] ?? variable}
                </span>
            </div>
        </div>
    );
    return plot;
}

export default function HistogramChart(props: Props) {
    let {
        data,
        variables,
        units,
        additionalData,
        nameMapping,
        axesColor,
        chartColor,
        linesCount,
        maxYRange,
        minYRange,
        barSize,
    } = props;
    if (data.length === 0) return null;
    let histogramDatas = data;
    return (
        <div
            className="flex-simple-column"
            style={{ width: "100%", height: "100%", margin: "0 auto" }}
        >
            <div
                className="my-row"
                style={{
                    marginTop: 10,
                    width: "100%",
                    height: "calc(100% - 40px)",
                }}
            >
                {histogramDatas.map((histogramData, groupIndex) => {
                    let title = "";
                    // if (data[groupIndex].where) {
                    // 	title = `${
                    // 		data[groupIndex].where.group ||
                    // 		data[groupIndex].where.group_name
                    // 	} ${data[groupIndex].where.operation ?? "="} ${
                    // 		data[groupIndex].where.value
                    // 	}`;
                    // }
                    return (
                        <div
                            style={{
                                width: `calc(100%/${histogramDatas.length})`,
                                height: "100%",
                            }}
                            key={groupIndex}
                        >
                            {false ? (
                                <div
                                    className="center-container"
                                    style={{ marginBottom: 10 }}
                                >
                                    <span
                                        style={{
                                            color: mainStyle.getPropertyValue(
                                                "--exploration-tertiary-text-color"
                                            ),
                                            fontFamily: "Arial",
                                            fontSize: "12px",
                                            lineHeight: "13px",
                                        }}
                                    >
                                        {title}
                                    </span>
                                </div>
                            ) : null}
                            <div
                                className="element"
                                style={{
                                    padding: "5px",
                                    height: "100%",
                                    width: "100%",
                                    overflow: "auto",
                                }}
                            >
                                <HistogramChartInner
                                    barSize={barSize}
                                    axesColor={axesColor}
                                    chartColor={chartColor}
                                    nameMapping={nameMapping}
                                    maxYRange={maxYRange}
                                    minYRange={minYRange}
                                    linesCount={linesCount}
                                    data={histogramData}
                                    variable={variables[0]}
                                    unit={units?.[0]}
                                    additionalLine={
                                        additionalData
                                            ? additionalData[0]
                                            : undefined
                                    }
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
