import React, { Component } from "react";
import Select, { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button } from "react-bootstrap";
import Switch from "react-switch";

import axios from "common/ServerConnection";
import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";
import FieldEditor, { Field } from "../../common/FieldEditor";
import customSelectStyles from "common/SelectStyles";
import UserGroupsStore, { UserGroupOption } from "common/UserGroupsStore";
import { UserButton } from "common/UserIcon";
import PasswordChangePopup from "common/PasswordChangePopup";
import { Group } from "common/UserGroupsApi";
import stringSimilarity from "string-similarity";
import ReactPaginate from "react-paginate";

import { replaceSchema } from "common/InputData";
import { insertRows } from "common/DataApi";
import tables from "common/Tables";

//import { Permission } from "common/Permissions";  // Un-comment when I can find the GroupIds

interface ExtendedUserInfo {
    id: number;
    user_name: string;
    first_name: string;
    last_name: string;
    icon_url: string;
    position: string;
    organization: string;
    phone_number: string;
    apache_group: string | null;
    email: string;
    user_groups?: (Group | null)[];
    internal_user: boolean;
    fee_percent: number;
}

const similarity = (userInfo: ExtendedUserInfo, searchText: string) => {
    // search criteria
    //title
    let metrics = 0;
    let weight = 1;
    if (userInfo.first_name) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                userInfo.first_name.toLowerCase(),
                searchText.toLowerCase()
            ) * 0.2;
        weight = weight - 0.2;
    }
    if (userInfo.last_name) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                userInfo.last_name.toLowerCase(),
                searchText.toLowerCase()
            ) * 0.2;
        weight = weight - 0.2;
    }
    if (userInfo.email) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                userInfo.email.toLowerCase(),
                searchText.toLowerCase()
            ) * 0.3;
        weight = weight - 0.3;
    }
    if (userInfo.user_name) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                userInfo.user_name.toLowerCase(),
                searchText.toLowerCase()
            ) * weight;
    }
    return metrics;
};

const userQueryLimit = 5;
const maxUsersPageCount = 5;

let prevSearchText = "";

const searchUsers = (
    users: (ExtendedUserInfo & { index: number })[],
    searchText: string
): (ExtendedUserInfo & { index: number })[] => {
    const filteredUsers = users
        .map((user) => ({
            user: user,
            similarity: similarity(user, searchText),
        }))
        .filter((user) => user.similarity > 0.01)

    if (prevSearchText !== searchText) {
        filteredUsers.sort((u1, u2) => {
            if (u1.similarity < u2.similarity) return 1;
            if (u1.similarity > u2.similarity) return -1;
            return 0;
        })
    }

    prevSearchText = searchText;
    return filteredUsers.map((user) => user.user);
};

interface ExtendedUpdateUserInfo {
    id: number;
    user_name: string;
    first_name: string;
    last_name: string;
    icon_url: string;
    position: string;
    organization: string;
    phone_number: string;
    apache_group: string | null;
    email: string;
    user_groups?: (string | null)[];
    internal_user: boolean;
    fee_percent: number;
}

interface State {
    status: UpdateStatus;
    errorMessage: string;
    userListStatus: UpdateStatus;
    userListErrorMessage: string;
    value: Field[];
    userGroups: (Group | null)[];
    users: (ExtendedUserInfo & { index: number })[];
    createdUserGroups: string[];
    showPasswordResetPopup: boolean;
    userNameForResetPassword: string;
    userSearchInput: string;
    userSearchTemp: string;
    internalUser: boolean;
    pageCount: number;
    itemOffset: number;
    sfDataTableIdx: string | number;
    toDataTableIdx: string | number;
    snDataTableIdx: string | number;
    mrDataTableIdx: string | number;
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;    

    constructor(props: {}) {
        super(props);
        this.state = {
            userSearchInput: "",
            userSearchTemp: "",            
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            userListStatus: UpdateStatus.NotUploaded,
            userListErrorMessage: "",
            value: [
                {
                    name: "user_name",
                    label: "Username",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "password",
                    label: "Password",
                    value: "",
                    editable: true,
                    hidden: true,
                    passwordStrength: true,
                },
                {
                    name: "confirm_password",
                    label: "Confirm",
                    value: "",
                    editable: true,
                    hidden: true,
                },
                {
                    name: "first_name",
                    label: "First Name",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "last_name",
                    label: "Last Name",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "organization",
                    label: "Organization",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "position",
                    label: "Position",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "phone_number",
                    label: "Phone Number",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "email",
                    label: "Email",
                    value: "",
                    editable: true,
                    hidden: false,
                },
                {
                    name: "icon_url",
                    label: "Icon",
                    value: "",
                    icon: true,
                    hidden: false,
                },
                {
                    name: "apache_group",
                    label: "Apache group",
                    value: "",
                    select: true,
                    options: [],
                },
            ],
            userGroups: [null],
            users: [],
            pageCount: 0,
            itemOffset: 0,
            showPasswordResetPopup: false,
            userNameForResetPassword: "",
            createdUserGroups: [],
            internalUser: false,
            sfDataTableIdx: 0,
            toDataTableIdx: 0,
            snDataTableIdx: 0,
            mrDataTableIdx: 0
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.performance = null;     
    }

    componentDidMount() {
        UserGroupsStore.init();

        /*  QQQ: this returns an empty list. Will need to debug and fix if I want to be able to use to to pull up group_ids.  
        const userNames = UserGroupsStore.userPersonalGroupsState.map((group) => group.user_name);
        console.log(userNames);
        */

        axios
            .post<{
                success: boolean;
                error_msg: string;
                apache_groups?: string[];
            }>("/api/get_apache_groups", {})
            .then((response) => {
                if (
                    response.data.success &&
                    response.data.apache_groups != null
                ) {
                    this.setState((state) => {
                        let value = Array.from(state.value);
                        value[value.length - 1] = {
                            ...value[value.length - 1],
                            options: response.data.apache_groups,
                        };
                        return {
                            value: value,
                        };
                    });
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        this.getUsers();
    }

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    private createNewUser(): void {
        this.setState({
            status: UpdateStatus.Loading,
            errorMessage: "",
        });
        let config: { [key: string]: string | string[] | boolean } = {};
        this.state.value.forEach((field: Field) => {
            config[field.name] = field.value;
        });
        config["user_groups"] = this.state.userGroups
            .filter((value: Group | null): value is Group => value != null)
            .map((value): string => value.name);
        if (config["password"] !== config["confirm_password"]) {
            this.setState({
                status: UpdateStatus.Error,
                errorMessage: "Passwords didn't match",
            });
            return;
        }
        config["internal_user"] = this.state.internalUser;
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/create_new_user", config)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        status: UpdateStatus.Success,
                        createdUserGroups: [],
                    });
                    this.getUsers();
                    UserGroupsStore.updateUserGroups();
                } else {
                    console.log(response.data.error_msg);
                    this.setState({
                        status: UpdateStatus.Error,
                        errorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    status: UpdateStatus.Error,
                });
            });
    }

    private getUsers(): void {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                users?: ExtendedUserInfo[];
            }>("/api/get_users", {})
            .then((response) => {
                if (response.data.success && response.data.users != null) {
                    this.setState({
                        users: response.data.users.map((user, index) => ({
                            ...user,
                            index: Number(index),
                        })),
                        pageCount: Math.ceil(
                            response.data.users.length / maxUsersPageCount
                        ),
                    });
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private buildUserGroupsInner(
        userGroups: (Group | null)[],
        updateStateField: (userGroups: (Group | null)[]) => void
    ): JSX.Element {
        return (
            <div className="flex-simple-column" style={{ marginTop: "5px" }}>
                {userGroups.map((userGroup, index) => (
                    <div
                        className="my-row"
                        key={index}
                        style={{ marginTop: "5px" }}
                    >
                        <CreatableSelect
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={false}
                            placeholder={"Enter group"}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    height: "38px",
                                    width: "140px",
                                }),
                            }}
                            options={UserGroupsStore.userGroupsOptions.concat(
                                this.state.createdUserGroups.map(
                                    (item, index) => ({
                                        label: item,
                                        value: item,
                                        id: -index - 1,
                                    })
                                )
                            )}
                            value={
                                userGroup
                                    ? {
                                          label: userGroup.name,
                                          value: userGroup.name,
                                          id: userGroup.id,
                                      }
                                    : null
                            }
                            onChange={(newValue) => {
                                let newUserGroups = Array.from(userGroups);
                                newUserGroups[index] = {
                                    name: (newValue as UserGroupOption).value,
                                    id: (newValue as UserGroupOption).id,
                                };
                                updateStateField(newUserGroups);
                            }}
                            onCreateOption={(option) => {
                                let createdUserGroups = Array.from(
                                    this.state.createdUserGroups
                                );
                                createdUserGroups.push(option);
                                this.setState({
                                    createdUserGroups: createdUserGroups,
                                });
                                let newUserGroups = Array.from(userGroups);
                                newUserGroups[index] = {
                                    id: -createdUserGroups.length,
                                    name: option,
                                };
                                updateStateField(newUserGroups);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <div
                            className="flex-simple-column"
                            style={{ marginLeft: 5 }}
                        >
                            <Button
                                className="btn-small-like-select"
                                style={{
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let newUserGroups = Array.from(userGroups);
                                    newUserGroups.splice(index + 1, 0, null);
                                    updateStateField(newUserGroups);
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>
                            <Button
                                className="btn-small-like-select"
                                style={{
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let newUserGroups = Array.from(userGroups);
                                    newUserGroups.splice(index, 1);
                                    if (newUserGroups.length === 0)
                                        newUserGroups.push(null);
                                    updateStateField(newUserGroups);
                                }}
                            >
                                {"\uFF0D" /* minus */}
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    private handlePageClick(event: { selected: number }): void {
        const newOffset =
            (event.selected * maxUsersPageCount) % this.state.users.length;
        this.setState({ itemOffset: newOffset });
    }

    private buildAdditionalSelectors(): JSX.Element {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        className="regular-text"
                        style={{
                            marginLeft: "19px",
                            paddingLeft: "15px",
                            marginRight: "15px",
                            minWidth: "8em",
                        }}
                    >
                        {"User groups"}
                    </div>
                    {this.buildUserGroupsInner(
                        this.state.userGroups,
                        (userGroups) => {
                            this.setState({
                                userGroups: userGroups,
                            });
                        }
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        className="regular-text"
                        style={{
                            marginLeft: "19px",
                            paddingLeft: "15px",
                            marginRight: "15px",
                            minWidth: "8em",
                        }}
                    >
                        {"Internal user"}
                    </div>
                    <Switch
                        onChange={(checked) => {
                            this.setState({
                                internalUser: checked,
                            });
                        }}
                        checked={this.state.internalUser}
                        width={26}
                        height={13}
                        offColor="#20293C"
                        onColor="#20293C"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#70889E"
                        onHandleColor="#1F8EFA"
                    />
                </div>
            </>
        );
    }

    private buildUserListSection(): JSX.Element {
        let filterUsers = this.state.userSearchInput
            ? searchUsers(this.state.users, this.state.userSearchInput).slice(
                  0,
                  userQueryLimit
              )
            : this.state.users.slice(
                  this.state.itemOffset,
                  this.state.itemOffset + maxUsersPageCount
              );
        return (
            <div style={{ marginTop: 20, overflow: "auto" }}>                
                <span className="big-title-span">{"Users"}</span>
                <div
                    style={{ marginTop: 10, minWidth: 300 }}
                    className="flex-simple-column"
                >
                    <input
                        value={this.state.userSearchTemp}
                        className="like-select module-template"
                        type="text"
                        placeholder="Press [enter] to search for a user"
                        onChange={(evt) => {
                            let value = evt.target.value;
                            /*  Dont' do this -- it literally calls the search function every time we type something.  Very jarring.
                            this.setState({
                                userSearchInput: value,
                            });                            
                            */
                           // This will create a temporary search variable and we will set it to the actual search term once we press enter.  
                            this.setState({
                                userSearchTemp: value,
                            });                                                        
                        }}                        
                        onKeyDown={(evt) => {
                            if (evt.key === "Enter") {
                                // Update the state only when the "Enter" key is pressed
                                this.setState({
                                    userSearchInput: this.state.userSearchTemp,
                                });
                            }
                        }}                        
                    />
                </div>
                <div
                    style={{ marginTop: 10, minWidth: 1650 }}
                    className="flex-simple-column"
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                        }}
                    >
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Username"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Apache groups"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "165px",
                            }}
                        >
                            {"User groups"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"First name"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Last name"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Organization"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Position"}
                        </div>

                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Phone number"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Email"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "50px",
                            }}
                        >
                            {"Fee Percent"}
                        </div>
                        <div
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                minWidth: "140px",
                            }}
                        >
                            {"Icon"}
                        </div>
                    </div>
                    {filterUsers.map((user) => {
                        let userGroups: (Group | null)[] = Array.from(
                            user.user_groups ?? []
                        );
                        let index = user.index;
                        if (userGroups.length === 0) userGroups.push(null);
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "10px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <div
                                        className="regular-text"
                                        style={{
                                            minWidth: "140px",
                                        }}
                                    >
                                        {user.user_name}
                                    </div>
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "2px",
                                        }}
                                    >
                                        <Switch
                                            onChange={(checked) => {
                                                this.setState((state) => {
                                                    let users = Array.from(
                                                        state.users
                                                    );
                                                    users[index].internal_user =
                                                        checked;
                                                    return {
                                                        users: users,
                                                    };
                                                });
                                            }}
                                            checked={user.internal_user}
                                            width={26}
                                            height={13}
                                            offColor="#20293C"
                                            onColor="#20293C"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            offHandleColor="#70889E"
                                            onHandleColor="#1F8EFA"
                                        />
                                        <div
                                            className="regular-text"
                                            style={{
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {"Internal"}
                                        </div>
                                    </div>
                                </div>
                                <Select
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            marginTop: "10px",
                                            marginLeft: "20px",
                                            height: "38px",
                                            minWidth: "140px",
                                        }),
                                    }}
                                    options={this.state.value[
                                        this.state.value.length - 1
                                    ].options?.map((option) => ({
                                        label: option,
                                        value: option,
                                    }))}
                                    value={{
                                        label: user.apache_group,
                                        value: user.apache_group,
                                    }}
                                    onChange={(newValue) => {
                                        let users = Array.from(
                                            this.state.users
                                        );
                                        users[index].apache_group = (
                                            newValue as {
                                                label: string;
                                                value: string;
                                            }
                                        ).value;
                                        this.setState({ users: users });
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                <div style={{ marginLeft: "20px" }}>
                                    {this.buildUserGroupsInner(
                                        userGroups,
                                        (userGroups) => {
                                            this.setState((state) => {
                                                let users = Array.from(
                                                    state.users
                                                );
                                                users[index] = {
                                                    ...users[index],
                                                    user_groups: userGroups,
                                                };
                                                return { users: users };
                                            });
                                        }
                                    )}
                                </div>
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                first_name: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.first_name}
                                />
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                last_name: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.last_name}
                                />
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                organization: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.organization ?? ""}
                                />
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                position: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.position ?? ""}
                                />

                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                phone_number: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.phone_number ?? ""}
                                />
                                <input
                                    type={"text"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "140px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                email: value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.email ?? ""}
                                />
                                <input
                                    type={"number"}
                                    className="like-select"
                                    style={{
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        width: "50px",
                                    }}
                                    placeholder=""
                                    onChange={(e) => {
                                        let value: string = e.target.value;
                                        this.setState((state) => {
                                            let users = Array.from(state.users);
                                            users[index] = {
                                                ...users[index],
                                                fee_percent: +value,
                                            };
                                            return { users: users };
                                        });
                                    }}
                                    value={user.fee_percent ?? 15}
                                />
                                <div
                                    style={{
                                        marginLeft: "20px",
                                        width: "90px",
                                    }}
                                >
                                    <UserButton
                                        user={user}
                                        identifier={user.id.toString()}
                                        iconUrl={user.icon_url}
                                        onLoad={(result) => {
                                            if (typeof result === "string") {
                                                this.setState((state) => {
                                                    let users = Array.from(
                                                        state.users
                                                    );
                                                    users[index] = {
                                                        ...users[index],
                                                        icon_url: result,
                                                    };
                                                    return { users: users };
                                                });
                                            }
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: "10px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{ 
                                            marginTop: "0px",
                                            width: "120px",
                                        }}
                                        onClick={() => {
                                            this.performance = new Date();
                                            this.updateUser(user);
                                        }}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{ 
                                            marginTop: "5px",
                                            width: "120px",
                                        }}
                                        onClick={() => {
                                            this.performance = new Date();
                                            this.deleteUser(user);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{                                            
                                            marginTop: "5px",
                                            width: "120px",
                                        }}
                                        onClick={() => {
                                            this.performance = new Date();
                                            this.setState({
                                                userNameForResetPassword:
                                                    user.user_name,
                                                showPasswordResetPopup: true,
                                            });
                                        }}
                                    >
                                        Reset Password
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{                                            
                                            marginTop: "5px",
                                            width: "120px",
                                            fontSize: "8pt",
                                        }}
                                        onClick={() => {
                                            this.performance = new Date();                                            
                                            this.initUserAITables(user);
                                        }}
                                    >
                                        Init AI Tables (First)
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{                                            
                                            marginTop: "5px",
                                            width: "120px",
                                        }}
                                        onClick={() => {
                                            this.performance = new Date();                                            
                                            this.initUserAIMasterTable(user);
                                        }}
                                    >
                                        Init AI Master Table
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                    {this.state.userListStatus !== UpdateStatus.NotUploaded && (
                        <UpdateStatusAlert
                            value={this.state.userListStatus}
                            onChange={(status: UpdateStatus) =>
                                this.setState({ userListStatus: status })
                            }
                            errorMessage={this.state.userListErrorMessage}
                        />
                    )}
                </div>
                {this.state.showPasswordResetPopup ? (
                    <PasswordChangePopup
                        section="Settings"
                        userName={this.state.userNameForResetPassword}
                        reset
                        onClose={() => {
                            this.setState({
                                showPasswordResetPopup: false,
                                userNameForResetPassword: "",
                            });
                        }}
                    />
                ) : null}
            </div>
        );
    }

    private deleteUser(user: ExtendedUserInfo): void {
        this.setState({
            userListStatus: UpdateStatus.Loading,
            userListErrorMessage: "",
        });

        let json = {
            id: user.id,
            user_name: user.user_name,
        };
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/delete_user", json)
            .then((response) => {
                if (response.data.success) {
                    this.setState((state) => {
                        let users = state.users.filter(
                            (item) => item.id !== user.id
                        );
                        return {
                            users: users,
                            userListStatus: UpdateStatus.Success,
                        };
                    });
                } else {
                    console.log(response.data.error_msg);
                    this.setState({
                        userListStatus: UpdateStatus.Error,
                        userListErrorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((_error) => {
                this.setState({
                    userListStatus: UpdateStatus.Error,
                });
            });
    }

    private updateUser(user: ExtendedUserInfo): void {
        this.setState({
            userListStatus: UpdateStatus.Loading,
            userListErrorMessage: "",
        });

        let json: ExtendedUpdateUserInfo & { update?: boolean } = Object.assign(
            {},
            { ...user, user_groups: [], index: undefined }
        );

        json.user_groups = user.user_groups
            ?.filter((group: Group | null): group is Group => group != null)
            .map((group) => group.name);
        json.update = true;

        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/create_new_user", json)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        userListStatus: UpdateStatus.Success,
                        createdUserGroups: [],
                    });
                    this.getUsers();
                    UserGroupsStore.updateUserGroups();
                } else {
                    console.log(response.data.error_msg);
                    this.setState({
                        userListStatus: UpdateStatus.Error,
                        userListErrorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((_error) => {
                this.setState({
                    userListStatus: UpdateStatus.Error,
                });
            });
    }

    private initUserAITables(user: ExtendedUserInfo): void {
        this.setState({
            userListStatus: UpdateStatus.Loading,
            userListErrorMessage: "",
        });        

        // QQQ: there is a bug rigth now where this line below returns undefined because it does NOT find the user_name
        //const personalGroup = UserGroupsStore.userPersonalGroupsState.find((group) => group.user_name === user.user_name);        
        console.log("user's user_name=", user.user_name);
        //console.log("personalGroup user_name=", personalGroup?.user_name);
        //console.log("group array=", personalGroup);


        
        // 1. Add in schedule_framework        
        let sf_dataScope = {
            'label': 'schedule_framework', 
            'value': NaN, 
            'permissionType': 10    // 10 is read & write, while 1 is read-only
        }        

        let sf_json_values = 
        [
            {
                "Day": "This is what your schedule looks like:\\n * 9am: Breakfast with [Dr. Terry Trueman](https://riversidehealth.org/) \\n * 10:45am: Dr. Jennifer Snyder @ Montefiore \\n * 12pm: Lunch with Dr. Tom Burns @ [Weill Cornell](https://maps.app.goo.gl/ZcTLCgXjuCHjECNS7) \\n",
                "Week": "Here are a few of your top priorities this week \\n you want me to remind you of. \\n 1. Close Dr. Burns \\n 2. Cold-call 8 more new contacts \\n 3. Prep for route with my sales director.",
                "Month": "In the previous month, you've discussed the following issues with your sales director: \\n 1. [Be ready to show her your coverage in territory](https://app.eisengard.ai/shared_canvas_module.html?remote_moduleid=rE%2BtL8QnQ5ad%2BU5g8RAfKA)             \\n 2. Discuss up-coming issues and problems.             \\n 3. Start applying principles from The Challenger Sale.",
                "Quarter": "Your next QBR is scheduled on 12/8/24.  \\n Want me to remind you of your progress on that?",
                "Progress": 995000,
                "Goal": 1550000
            }
        ]       
        
        /*
        let permissions_obj;
        if (personalGroup) {
            permissions_obj = [
                {
                    group_id: personalGroup.group_id,  // Still need to get this in order for this to work.  
                    group_name: personalGroup.user_name,
                    permission_type: Permission.ReadWrite
                }
            ];
        } else {
            permissions_obj = undefined;
        }
        */
        // Can delete following line when I have above bug fixed.  
        let permissions_obj = undefined;

        //let sfDataTableIdx: string | number = 0;                
        replaceSchema(
            ['Day', 'Week', 'Month', 'Quarter', 'Progress', 'Goal'],  // names: column names
            ['str', 'str', 'str', 'str', 'float', 'float'], // types: value types of each column
            ['', '', '', '', '', ''],  // units
            ['regular', 'regular', 'regular', 'regular', 'regular', 'regular'], // panels
            [null, null, null, null, null, null],  // formats
            [null, null, null, null, null, null], // levels
            [true, true, true, true, true, true], // columnsEnabled
            sf_dataScope, 
            false,
            permissions_obj,
            (dataTableIdx) => {                                
                let tableOption = tables(dataTableIdx).tableToOption();
                //sfDataTableIdx = dataTableIdx;
                this.setState({
                    sfDataTableIdx: dataTableIdx                    
                })

                // insertRows takes:
                //  1. tableOption
                //  2. rows
                //  3. moduleId (optional)
                //  4. addVariableRowLevel (false by default)
                insertRows(tableOption, sf_json_values, undefined)
                    .then(() => {
                        //onSuccess(dataTableIdx);                        
                        console.log("sfDataTableIdx=", this.state.sfDataTableIdx);

                        return {
                            dataTableIdx: dataTableIdx,
                            userListStatus: UpdateStatus.Success,
                        };
                    })
                    .catch((error) => {
                        //onError(String(error));
                    });
            },    
            (error) => {                
                this.setState({
                    userListStatus: UpdateStatus.Error,
                    userListErrorMessage: String(error),
                });
            },
            undefined,
            undefined, // Can add the moduleId of the preso here later.  
        );

                

        // 2. Add in scratch_notes        
        let sn_dataScope = {
            'label': 'scratch_notes', 
            'value': NaN, 
            'permissionType': 10
        }        

        let sn_json_values = [
            {
                "Date": 1725408000,
                "Notes": "These are my scratch notes from 9.4.24. Automatically generated."
            }
        ]       

        //let snDataTableIdx: string | number = 0;        
        
        replaceSchema(
            ['Date', 'Notes'],  // names: column names
            ['datetime', 'str'], // types: value types of each column
            ['', ''],  // units
            ['time', 'regular'], // panels
            ["%m/%d/%Y", null],  // formats
            ['datetime', null], // levels
            [true, true], // columnsEnabled
            sn_dataScope, 
            false,
            permissions_obj,
            (dataTableIdx) => {                                
                let tableOption = tables(dataTableIdx).tableToOption();
                //snDataTableIdx = dataTableIdx;
                this.setState({
                    snDataTableIdx: dataTableIdx                    
                })

                // insertRows takes:
                //  1. tableOption
                //  2. rows
                //  3. moduleId (optional)
                //  4. addVariableRowLevel (false by default)
                insertRows(tableOption, sn_json_values, undefined)
                    .then(() => {
                        //onSuccess(dataTableIdx);                        
                        console.log("snDataTableIdx=", this.state.snDataTableIdx);
                        
                        return {
                            dataTableIdx: dataTableIdx,
                            userListStatus: UpdateStatus.Success,
                        };
                    })
                    .catch((error) => {
                        //onError(String(error));
                        console.log("Something is wrong with snDataTableIdx=", this.state.snDataTableIdx, "error=", error);
                    });
            },    
            (error) => {                
                this.setState({
                    userListStatus: UpdateStatus.Error,
                    userListErrorMessage: String(error),
                });
            },
            undefined,
            undefined, // Can add the moduleId of the preso here later.  
        );        

        // 3. Add in top_opportunities        
        let to_dataScope = {
            'label': 'top_opportunities', 
            'value': NaN, 
            'permissionType': 10
        }        

        let to_json_values = [
            {
                "Name": "Jamie Goodman",
                "Strategy": "Expansion",
                "Success": 0.95,
                "CLV": 91000,
                "City": "Albany"
            },
            {
                "Name": "Tony Jorgensen MD",
                "Strategy": "Retention",
                "Success": 0.93,
                "CLV": 85000,
                "City": "Albany"
            },
            {
                "Name": "Natasha Kristoff",
                "Strategy": "Expansion",
                "Success": 0.89,
                "CLV": 95000,
                "City": "Albany"
            },
            {
                "Name": "Jeff Gundersen",
                "Strategy": "Acquisition",
                "Success": 0.85,
                "CLV": 61000,
                "City": "Albany"
            },
            {
                "Name": "Jay Gupta MD",
                "Strategy": "Expansion",
                "Success": 0.83,
                "CLV": 84000,
                "City": "Albany"
            },
            {
                "Name": "Bilbo Baggins",
                "Strategy": "Retention",
                "Success": 0.88,
                "CLV": 20000,
                "City": "Shire"
            },
            {
                "Name": "Sam Gamgee",
                "Strategy": "Retention",
                "Success": 0.94,
                "CLV": 20000,
                "City": "Shire"
            },
            {
                "Name": "Pippen Took",
                "Strategy": "Retention",
                "Success": 0.43,
                "CLV": 20000,
                "City": "Shire"
            }
        ]       

        //let toDataTableIdx: string | number = 0;        

        replaceSchema(
            [
                "Name",
                "Strategy",
                "Success",
                "CLV",
                "City"
            ],  // names: column names
            [
                "str",
                "str",
                "float",
                "int",
                "str"
            ], // types: value types of each column
            ['', '', '', '', ''],  // units
            [
                "regular",
                "regular",
                "regular",
                "regular",
                "regular"
            ], // panels
            [
                null,
                null,
                null,
                null,
                null
            ],  // formats
            [
                null,
                null,
                null,
                null,
                null
            ], // levels
            [true, true, true, true, true], // columnsEnabled
            to_dataScope, 
            false,
            permissions_obj,
            (dataTableIdx) => {                                
                let tableOption = tables(dataTableIdx).tableToOption();
                //toDataTableIdx = dataTableIdx;
                this.setState({
                    toDataTableIdx: dataTableIdx                    
                })

                // insertRows takes:
                //  1. tableOption
                //  2. rows
                //  3. moduleId (optional)
                //  4. addVariableRowLevel (false by default)
                insertRows(tableOption, to_json_values, undefined)
                    .then(() => {
                        //onSuccess(dataTableIdx);                        
                        console.log("Success: toDataTableIdx=", this.state.toDataTableIdx);
                        
                        return {
                            dataTableIdx: dataTableIdx,
                            userListStatus: UpdateStatus.Success,
                        };
                    })
                    .catch((error) => {
                        //onError(String(error));
                        console.log("Something is wrong with toDataTableIdx=", this.state.toDataTableIdx, "error=", error);
                    });
            },    
            (error) => {                
                this.setState({
                    userListStatus: UpdateStatus.Error,
                    userListErrorMessage: String(error),
                });
            },
            undefined,
            undefined, // Can add the moduleId of the preso here later.  
        );

        // 4. Add in my_recipes        
        let mr_dataScope = {
            'label': 'my_recipes', 
            'value': NaN, 
            'permissionType': 10
        }        

        let mr_json_values = [
            {
                "Date": 1725408000,
                "DishName": "Stir Fry Kale",
                "Instructions": "1. Put wok on medium heat. 2. Put in pre-washed kale (a wok full) 3. sprinkle a teaspoon of garlic salt, a tablespoon of onion powder.  4. stirfry for 3 minutes, covered.  "
            },
            {
                "Date": 1725235200,
                "DishName": "Pork Ribs",
                "Instructions": "1. Marinate ribs for 24 overnight with soy sauce, syrup, ginger & salt.  2. Put in electric oven for 30m on 350 degrees."
            },
            {
                "Date": 1726099200,
                "DishName": "Yummy soup",
                "Instructions": "1. boil water"
            },
            {
                "Date": 1726185600,
                "DishName": "Crack fries",
                "Instructions": "\n1. Get fries from McDonalds. \\n 2. The end."
            },
            {
                "Date": 1726185600,
                "DishName": "NY Times Rice & FIsh",
                "Instructions": "\n1. Make rice /n 2. Add 1 tablespoon of mayo \\n 3. Add 1 teaspoon of sesame oil \\n 4. Add 1/2 teaspoon of soy sauce.\\n"
            }
        ]       

        //let mrDataTableIdx: string | number = 0;        

        replaceSchema(
            [
                "Date",
                "DishName",
                "Instructions"
            ],  // names: column names
            [
                "datetime",
                "str",
                "str"
            ], // types: value types of each column
            ['', '', ''],  // units
            [
                "time",
                "regular",
                "regular"
            ], // panels
            [
                "%m/%d/%Y",
                null,
                null
            ],  // formats
            [
                "datetime",
                null,
                null
            ], // levels
            [true, true, true], // columnsEnabled
            mr_dataScope, 
            false,
            permissions_obj,
            (dataTableIdx) => {                                
                let tableOption = tables(dataTableIdx).tableToOption();
                //mrDataTableIdx = dataTableIdx;
                this.setState({
                    mrDataTableIdx: dataTableIdx                    
                })

                // insertRows takes:
                //  1. tableOption
                //  2. rows
                //  3. moduleId (optional)
                //  4. addVariableRowLevel (false by default)
                insertRows(tableOption, mr_json_values, undefined)
                    .then(() => {
                        //onSuccess(dataTableIdx);                        
                        console.log("Success: mrDataTableIdx=", this.state.mrDataTableIdx);
                        
                        return {
                            dataTableIdx: dataTableIdx,
                            userListStatus: UpdateStatus.Success,
                        };
                    })
                    .catch((error) => {
                        //onError(String(error));
                        console.log("Something is wrong with mrDataTableIdx=", this.state.mrDataTableIdx, "error=", error);
                    });
            },    
            (error) => {                
                this.setState({
                    userListStatus: UpdateStatus.Error,
                    userListErrorMessage: String(error),
                });
            },
            undefined,
            undefined, // Can add the moduleId of the preso here later.  
        );        

    }

    private initUserAIMasterTable(user: ExtendedUserInfo): void {
        this.setState({
            userListStatus: UpdateStatus.Loading,
            userListErrorMessage: "",
        });

        let json = {
            id: user.id,
            user_name: user.user_name,
        };

        // 5. admin_mastertable
        let am_dataScope = {
            'label': user.user_name + '_mastertable', 
            'value': NaN, 
            'permissionType': 10
        }        

        let am_json_values = [
            {
                "DocName": "schedule_framework",
                "datatable_idx": this.state.sfDataTableIdx,
                "Description": "This list sets the default text for the day, week, month, quarterly tabs.",
                "writable_columns_idx": null,
                "prompt_questions": null,
                "vectorize_colnames": null,
                "display_result_type": "best_result"
            },
            {
                "DocName": "top_opportunities",
                "datatable_idx": this.state.toDataTableIdx,
                "Description": "This is the list of all of the top sales opportunities that the user should look at with top priority.",
                "writable_columns_idx": null,
                "prompt_questions": null,
                "vectorize_colnames": "[\"Name\"]",
                "display_result_type": "table"
            },
            {
                "DocName": "scratch_notes",
                "datatable_idx": this.state.snDataTableIdx,
                "Description": "This is the list of scratch notes.  Whenever the user prompts to jot down a quick note, write into here.",
                "writable_columns_idx": "{1}",
                "prompt_questions": "{What do you want to jot down?}",
                "vectorize_colnames": "[\"Notes\"]",
                "display_result_type": "table"
            },
            {
                "DocName": "my_recipes",
                "datatable_idx": this.state.mrDataTableIdx,
                "Description": "List of all of the users' recipes",
                "writable_columns_idx": "{1,2}",
                "prompt_questions": "{What do you call your dish?, How do you make it?}",
                "vectorize_colnames": "[\"DishName\"]",
                "display_result_type": "best_result"
            }
        ]       

        let amDataTableIdx: string | number = 0;        
        
        replaceSchema(
            [
                "DocName",
                "datatable_idx",
                "Description",
                "writable_columns_idx",
                "prompt_questions",
                "vectorize_colnames",
                "display_result_type"
            ],  // names: column names
            [
                "str",
                "int",
                "str",
                "str",
                "str",
                "str",
                "str"
            ], // types: value types of each column
            [
                "",
                "",
                "",
                "",
                "",
                "",
                ""
            ],  // units
            [
                "regular",
                "regular",
                "regular",
                "regular",
                "regular",
                "regular",
                "regular"
            ], // panels
            [
                null,
                null,
                null,
                null,
                null,
                null,
                null
            ],  // formats
            [
                null,
                null,
                null,
                null,
                null,
                null,
                null
            ], // levels
            [true, true, true, true, true, true, true], // columnsEnabled
            am_dataScope, 
            false,
            undefined,
            (dataTableIdx) => {                                
                let tableOption = tables(dataTableIdx).tableToOption();
                amDataTableIdx = dataTableIdx;

                // insertRows takes:
                //  1. tableOption
                //  2. rows
                //  3. moduleId (optional)
                //  4. addVariableRowLevel (false by default)
                insertRows(tableOption, am_json_values, undefined)
                    .then(() => {
                        //onSuccess(dataTableIdx);                        
                        console.log("Success: amDataTableIdx=", amDataTableIdx);
                        
                        return {
                            dataTableIdx: dataTableIdx,
                            userListStatus: UpdateStatus.Success,
                        };
                    })
                    .catch((error) => {
                        //onError(String(error));
                        console.log("Something is wrong with amDataTableIdx=", amDataTableIdx, "error=", error);
                    });
            },    
            (error) => {                
                this.setState({
                    userListStatus: UpdateStatus.Error,
                    userListErrorMessage: String(error),
                });
            },
            undefined,
            undefined, // Can add the moduleId of the preso here later.  
        );


    }

    render() {
        return (
            <>
                <FieldEditor
                    title="Create New User"
                    value={this.state.value}
                    onChange={(value) => this.setState({ value: value })}
                    onUpdate={() => this.createNewUser()}
                    afterInputs={this.buildAdditionalSelectors()}
                    status={this.state.status}
                    onStatusChange={(status: UpdateStatus) =>
                        this.setState({ status: status })
                    }
                    errorMessage={this.state.errorMessage}
                />
                {this.buildUserListSection()}
                {!this.state.userSearchInput && (
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={maxUsersPageCount}
                        pageCount={this.state.pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={undefined}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                    />
                )}
            </>
        );
    }
}

export { MainComponent };
export let requirePermission = "ManageUsers";
