import React from "react";
import styles from "./StylingSection.module.css";
import commonStyles from "../ChartsRibbon.module.css";
import StringUtils from "common/utilities/StringUtils";

function Input(props: {
    value: string | number;
    onChange: (value: string | number) => void;
    step?: number;
    maxValue?: number;
}) {
    const step = props.step ?? 1;
    const { maxValue } = props;
    let [value, setValue] = React.useState(props.value);

    return (
        <div className="my-row">
            <input
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                    if (evt.key === "Enter") {
                        props.onChange(value);
                    }
                }}
                style={{ padding: "0 0 0 10px" }}
                className={styles.input}
                value={value}
                onChange={(evt) => {
                    let newValue: string | number = evt.target.value;
                    if (maxValue && StringUtils.isNumber(newValue))
                        newValue = Math.min(maxValue, Number(newValue));
                    setValue(newValue);
                }}
                onBlur={() => {
                    props.onChange(value);
                }}
            ></input>
            <div className="flex-simple-column">
                <div
                    className={commonStyles.upDownButtons}
                    onClick={() => {
                        if (StringUtils.isNumber(value)) {
                            let newValue: string | number = 0;
                            if (maxValue)
                                newValue = (Math.min(maxValue, Number(value) + step)).toFixed(1);
                            else
                                newValue = (Number(value) + step).toFixed(1);
                            setValue(newValue);
                            props.onChange(newValue);
                        }
                    }}
                >
                    {"\uFF0B"}
                </div>
                <div
                    className={commonStyles.upDownButtons}
                    onClick={() => {
                        if (StringUtils.isNumber(value)) {
                            let newValue = (Math.max(0, Number(value) - step)).toFixed(1);
                            setValue(newValue);
                            props.onChange(newValue);
                        }
                    }}
                >
                    {"\uFF0D"}
                </div>
            </div>
        </div>
    );
}

export default Input;
