import React, { useEffect, useRef, useState } from "react";
import { GradientColorPointer, MapFinding } from "common/Finding";
import styles from "./GradientPicker.module.css";
import Draggable from "react-draggable";
import CustomSketchPicker from "common/custom_sketch_picker/CustomSketchPicker";
import OutsideAlerter from "common/OutsideAlerter";

interface Props {
    finding: MapFinding;
    onChange: (config: MapFinding["config"], updateData?: boolean) => void;
    defaultGradient: Array<GradientColorPointer>;
}

const getDefaultColorPointertData = (
    value: number,
    label: string,
    positionX: number
): GradientColorPointer => {
    return {
        label,
        color: "#acacac",
        value,
        positionX,
    };
};

const convertPositionToGradientValue = (value: number): string => {
    return `${value * 100}%`;
};

const convertPositionToGradientFloatValue = (
    positionX: number,
    containerWidth: number
): number => {
    return Number((positionX / containerWidth).toFixed(2));
};

function GradientPicker({ finding, onChange, defaultGradient }: Props) {
    // Refs
    const gradientPickerContainer = useRef<any>(null);

    // States
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [currentPointer, setCurrentPointer] = useState<{
        color: string;
        idx: number | null;
    }>({
        color: "#fff",
        idx: null,
    });

    const containerWidthError = 20;
    let gradient: GradientColorPointer[] =
        finding.config.heatmapGradient ?? defaultGradient;

    // Functions
    function updateGradient(idx: number, newData: any) {
        let newGradientData = [...gradient];
        newGradientData[idx] = {
            ...newGradientData[idx],
            ...newData,
        };

        newGradientData = newGradientData.sort((a, b) => a.value - b.value);

        let newConfig = {
            ...finding.config,
            heatmapGradient: newGradientData,
        };

        onChange(newConfig);
    }

    function createNewColorPointer(positionX: number, containerWidth: number) {
        const newGradientData = [...gradient];
        const value = convertPositionToGradientFloatValue(
            positionX,
            containerWidth
        );

        let colorIdxPosition = -1;

        // This array is already sorted by value
        for (let i = 0; i < gradient.length; ++i) {
            if (gradient[i].value > value) {
                colorIdxPosition = i;
                break;
            }
        }
        if (colorIdxPosition === -1) {
            colorIdxPosition = gradient.length;
        }

        newGradientData.splice(
            colorIdxPosition,
            0,
            getDefaultColorPointertData(
                value,
                colorIdxPosition.toString(),
                positionX
            )
        );

        let newConfig = {
            ...finding.config,
            heatmapGradient: newGradientData,
        };
        onChange(newConfig);
    }

    function deleteColorPointer(idx: number) {
        const newGradientData = [...gradient];
        newGradientData.splice(idx, 1);
        let newConfig = {
            ...finding.config,
            heatmapGradient: newGradientData,
        };
        onChange(newConfig);
    }

    useEffect(() => {
        // Set color pointers positions if it's not set (backward compatibility)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        gradient = gradient.map((color: GradientColorPointer) => {
            if (!color.positionX && color.label !== "from") {
                color.positionX =
                    color.value *
                    (gradientPickerContainer.current?.clientWidth ?? 1);
            }
            return color;
        });

        // Set the position for last gradient picker pointer
        if (gradientPickerContainer.current) {
            updateGradient(gradient.length - 1, {
                positionX:
                    gradientPickerContainer.current.clientWidth -
                    containerWidthError,
            });
        }
    }, [gradientPickerContainer]);

    // Build gradient color data
    let gradientColor = "";
    gradient.forEach((item: any) => {
        gradientColor +=
            item.color + ` ${convertPositionToGradientValue(item.value)}, `;
    });
    gradientColor = gradientColor.substring(0, gradientColor.length - 2);

    return (
        <div
            className={styles.gradientPickerContainer}
            ref={gradientPickerContainer}
        >
            <button
                className={styles.gradientPreviewLine}
                style={{
                    background: `linear-gradient(90deg, ${gradientColor}`,
                }}
                onContextMenu={(evt) => {
                    evt.preventDefault();
                    const clickPosition = evt.clientX;
                    const clickPositionError = 5;

                    createNewColorPointer(
                        clickPosition -
                            clickPositionError -
                            containerWidthError,
                        gradientPickerContainer.current.clientWidth -
                            containerWidthError
                    );
                }}
            ></button>

            <div style={{ display: "flex" }}>
                {gradient.map((color: GradientColorPointer, idx: number) => {
                    return (
                        <Draggable
                            position={{
                                x: color.positionX,
                                y: 0,
                            }}
                            axis="x"
                            disabled={false}
                            bounds={"parent"}
                            onStart={() => {}}
                            onDrag={(evt, data) => {
                                if (!isDragging) {
                                    setIsDragging(true);
                                }
                            }}
                            onStop={(evt, data) => {
                                const value = convertPositionToGradientFloatValue(
                                    data.x,
                                    gradientPickerContainer.current
                                        .clientWidth - containerWidthError
                                );
                                updateGradient(idx, {
                                    value,
                                    positionX: data.x,
                                });
                                setTimeout(() => {
                                    setIsDragging(false);
                                }, 100);
                            }}
                        >
                            <div style={{ position: "absolute" }}>
                                <div
                                    className={styles.colorPointerTriangle}
                                ></div>
                                <button
                                    className={styles.colorPointerFlask}
                                    style={{
                                        backgroundColor: color.color,
                                    }}
                                    onClick={(evt) => {
                                        evt.preventDefault();
                                        evt.stopPropagation();
                                        if (!isDragging) {
                                            if (!openColorPicker) {
                                                setCurrentPointer({
                                                    color: color.color,
                                                    idx,
                                                });
                                            }
                                            setOpenColorPicker(
                                                !openColorPicker
                                            );
                                        }
                                    }}
                                    onContextMenu={(evt) => {
                                        evt.preventDefault();
                                        if (gradient.length === 2) return;
                                        deleteColorPointer(idx);
                                    }}
                                ></button>
                            </div>
                        </Draggable>
                    );
                })}
            </div>

            {openColorPicker && (
                <OutsideAlerter
                    onReject={() => {
                        setOpenColorPicker(false);
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            bottom: 40,
                            left: 50,
                            zIndex: 9,
                        }}
                    >
                        <CustomSketchPicker
                            color={currentPointer.color}
                            onChange={(color) => {
                                const { rgb } = color;
                                const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
                                
                                setCurrentPointer({
                                    ...currentPointer,
                                    color: rgba,
                                });
                            }}
                            onChangeComplete={(color) => {
                                const { rgb } = color;
                                const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`

                                updateGradient(currentPointer.idx!, {
                                    color: rgba,
                                });
                            }}
                        />
                    </div>
                </OutsideAlerter>
            )}
        </div>
    );
}

export default GradientPicker;
