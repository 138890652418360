import React, { CSSProperties } from "react";
import CanvasTreeStore from "../CanvasTreeStore";
import { DropdownMenu, DropdownItem } from "../DropdownMenu";
import { Dropdown } from "react-bootstrap";
import { CanvasGrid, isSpreadSheetGrid } from "common/Canvas";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import CanvasPreventPropagationButton from "../CanvasPreventPropagationButton";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import { PortalType } from "../SectionPortal";
import styles from "./DropdownMenu.module.css";

interface Props {
    canvasTreeStore: CanvasTreeStore;
    customStyle: CSSProperties;
    sharedPolicy: CanvasSharedPolicy;
    scale?: number;
    grid: CanvasGrid;
    element: string;
    onClearEditing: () => void;
    moduleTitle: string;
    onSaveData?: () => void;
    onDeleteGrid: (gridId: string) => void;
    onOpenColorOptions: () => void;
    onToggle: (show: boolean) => void;
    onOpenBottomPortal?: (portalType: PortalType, options: any) => void;
    currentModuleId?: number;
    rootMenuTestId: string;
}

interface State {
    toggleOpen: boolean;
}

export default class DropdownGridMenu extends CanvasInteractionComponent<
    Props,
    State
> {
    constructor(props: Props) {
        super(props);
        this.state = {
            toggleOpen: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(isOpen: boolean) {
        this.setState({ toggleOpen: isOpen });
        this.props.onToggle(isOpen);
    }

    render() {
        let grid = this.props.grid;
        return (
            <Dropdown
                data-test-id={this.props.rootMenuTestId}
                title="Options"
                style={{
                    width: 20 * (this.props.scale ?? 1),
                    height: 20 * (this.props.scale ?? 1),
                    backgroundColor: "transparent",
                    ...this.props.customStyle,
                }}
                show={this.state.toggleOpen}
                onToggle={this.toggle}
            >
                <CanvasPreventPropagationButton>
                    <Dropdown.Toggle
                        data-test-id={`${this.props.rootMenuTestId}-dropdowntoggle`}
                        className={styles.hamburger}
                        id={`dropdown_menu_${this.props.element}_${grid.id}`}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width:
                                this.props.customStyle?.width ??
                                20 * (this.props.scale ?? 1),
                            height:
                                this.props.customStyle?.height ??
                                20 * (this.props.scale ?? 1),
                            backgroundColor: this.state.toggleOpen
                                ? "#EEEEEE"
                                : "transparent",
                            borderRadius: "50%",
                            border: "0",
                        }}
                    >
                        <span
                            className={styles.hamburger}
                            style={{
                                fontSize: 14 * (this.props.scale ?? 1),
                            }}
                        >
                            &#8942;
                        </span>
                    </Dropdown.Toggle>
                </CanvasPreventPropagationButton>
                <DropdownMenu noBorder>
                    <CanvasPreventPropagationButton>
                        <DropdownItem
                            dataTestId={`${this.props.rootMenuTestId}-deleteButton`}
                            highlightColor={"#39F"}
                            hoverColor={"#39F"}
                            title={"Delete"}
                            onClick={() => {
                                this.toggle(false);
                                this.trackNewPerformance(this.props.element);
                                this.props.onClearEditing();
                                this.props.onDeleteGrid(grid.id);
                            }}
                        />
                    </CanvasPreventPropagationButton>
                    <CanvasPreventPropagationButton>
                        <DropdownItem
                            dataTestId={`${this.props.rootMenuTestId}-hideButton`}
                            highlightColor={"#39F"}
                            hoverColor={"#39F"}
                            title={"Hide"}
                            onClick={() => {
                                this.props.canvasTreeStore.toggleVisibilityGridAction(grid.id, true);
                            }}
                        />
                    </CanvasPreventPropagationButton>
                    <CanvasPreventPropagationButton>
                        <DropdownItem
                            dataTestId={`${this.props.rootMenuTestId}-lockButton`}
                            highlightColor={"#39F"}
                            hoverColor={"#39F"}
                            title={grid.unlocked ? "Lock" : "Unlock"}
                            onClick={() => {
                                this.trackNewPerformance(this.props.element);
                                this.props.canvasTreeStore.updateGridAction(
                                    grid.id,
                                    {
                                        unlocked: !grid.unlocked,
                                    }
                                );
                            }}
                        />
                    </CanvasPreventPropagationButton>

                    {isSpreadSheetGrid(grid) && (
                        <>
                            <CanvasPreventPropagationButton>
                                <DropdownItem
                                    dataTestId={`${this.props.rootMenuTestId}-saveDataButton`}
                                    highlightColor={"#39F"}
                                    hoverColor={"#39F"}
                                    title={"Save data"}
                                    onClick={(_evt) => {
                                        this.trackNewPerformance(
                                            this.props.element
                                        );
                                        this.toggle(false);
                                        if (this.props.onSaveData != null)
                                            this.props.onSaveData();
                                    }}
                                />
                            </CanvasPreventPropagationButton>
                            <CanvasPreventPropagationButton>
                                <DropdownItem
                                    dataTestId={`${this.props.rootMenuTestId}-importDataButton`}
                                    highlightColor={"#39F"}
                                    hoverColor={"#39F"}
                                    title={"Import data from"}
                                    onClick={(_evt) => {
                                        this.trackNewPerformance(
                                            this.props.element
                                        );
                                        this.toggle(false);
                                        if (
                                            this.props.onOpenBottomPortal !=
                                            null
                                        ) {
                                            this.props.onOpenBottomPortal(
                                                PortalType.ImportData,
                                                {
                                                    currentModuleId: this.props
                                                        .currentModuleId,
                                                    spreadsheetId: grid.id,
                                                }
                                            );
                                        }
                                    }}
                                />
                            </CanvasPreventPropagationButton>
                            <CanvasPreventPropagationButton>
                                <DropdownItem
                                    dataTestId={`${this.props.rootMenuTestId}-viewExistingDataButton`}
                                    highlightColor={"#39F"}
                                    hoverColor={"#39F"}
                                    title={"View existing data"}
                                    onClick={(_evt) => {
                                        this.trackNewPerformance(
                                            this.props.element
                                        );
                                        this.toggle(false);
                                        if (
                                            this.props.onOpenBottomPortal !=
                                            null
                                        ) {
                                            this.props.onOpenBottomPortal(
                                                PortalType.ViewData,
                                                {
                                                    currentModuleId: this.props
                                                        .currentModuleId,
                                                    spreadsheetId: grid.id,
                                                }
                                            );
                                        }
                                    }}
                                />
                            </CanvasPreventPropagationButton>
                            {grid.fullSpreadSheetBackendOutputOptions
                                ?.dataScopeId != null && (
                                <CanvasPreventPropagationButton>
                                    <DropdownItem
                                        dataTestId={`${this.props.rootMenuTestId}-stopStreamButton`}
                                        highlightColor={"#39F"}
                                        hoverColor={"#39F"}
                                        title={"Stop stream"}
                                        onClick={(_evt) => {
                                            this.trackNewPerformance(
                                                this.props.element
                                            );

                                            let streamingOff =
                                                grid
                                                    .fullSpreadSheetBackendOutputOptions
                                                    ?.dataScopeId == null;
                                            if (!streamingOff) {
                                                this.props.canvasTreeStore.updateGridAction(
                                                    grid.id,
                                                    {
                                                        fullSpreadSheetBackendOutputOptions: null,
                                                    }
                                                );
                                            }
                                        }}
                                    />
                                </CanvasPreventPropagationButton>
                            )}
                        </>
                    )}
                    {/*isSpreadSheetGrid(grid) && (
                        <CanvasPreventPropagationButton>
                            <DropdownItem
                                highlightColor={"#39F"}
                                hoverColor={"#39F"}
                                title={"RESHAPE"}
                                onClick={(evt) => {
                                    this.trackNewPerformance(
                                        this.props.element
                                    );
                                    CanvasTreeStore().reshapeGrid(grid.id);
                                }}
                            />
                        </CanvasPreventPropagationButton>
                    ) */}
                </DropdownMenu>
            </Dropdown>
        );
    }
}
