import "common/styles/App.css";
import React, { Component, useContext } from "react";
import { observer } from "mobx-react";
import { getWidth, getHeight } from "common/utilities/UIResponsiveManager";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import StoreContext from "state/StoreContext";
import RootStore from "state/store";
import { PopupStatus } from "common/StatusPopup";
import styles from "./main.module.css";
import Instrumentation from "common/Instrumentation";
import { ReactComponent as InputIcon } from "icons/left_nav_menu/input.svg";
import LeftSideSection, { Option } from "./LeftSideSection";
import cx from "classnames";
import { ReactComponent as TopBarIcon } from "icons/data_hub/topbar.svg";
import ManageSection from "./ManageSection";
import MergeSection from "./MergeSection";
// import { MainComponent as ImportDataPage } from "modules/input_page/ImportDataPage";
import { MainComponent as ImportDataPage } from "./ImportSection";
import AggregateSection from "./AggregateSection";
import ExportSection from "./ExportSection";
import CreateVariablesSection from "./CreateVariablesSection";
import CleanSection from "./CleanSection";
import AnalyzeSection from "./AnalyzeSection";
import PivotSection from "./PivotSection";
import SelectedOption from "./SelectedOption";
import Visualize from "./Visualize";
//import TrashSection from "./TrashSection";
import TrashSection from "./TrashSection";

interface Props {
  store: RootStore;
  globalContext: GlobalContextContents;
}

const progressSteps = [
  {
    label: "Extract & Load",
    discription: "Select & load data sources",
    value: Option.Import,
  },
  {
    label: "Transform",
    discription: "Transform your data sets using common operations",
    value: Option.Merge,
  },
  {
    label: "Analyze",
    discription: "Run statistical & machine learning models",
    value: Option.Analyze,
  },
  {
    label: "Visualize",
    discription: "View results in dashboards or presentations",
    value: Option.Visualize,
  },
];

const transform = [
  {
    label: "Merge 2 data sets",
    value: Option.Merge,
  },
  {
    label: "Aggregate across geography & time",
    value: Option.Aggregate,
  },
  {
    label: "Clean existing data columns",
    value: Option.Clean,
  },
  {
    label: "Create new variables",
    value: Option.Create_Variable,
  },
  {
    label: "Reshape data by pivoting & unpivoting ",
    value: Option.Reshape,
  },
];

interface State {
  innerWidth: number;
  innerHeight: number;
  popupWidth: number;
  popupHeight: number;
  errorInfo:
  | {
    status: PopupStatus;
    message: string;
  }
  | undefined;
  // selectedOption: Option;
  newDatasetId?: number | string;
  hoverEffect: number | null;
  dropHoverEffect: number | null;
  dropActiveEffect: number | null;
  operationInProgress: boolean;
  // showOptions: boolean
}

function defaultState() {
  return {
    innerWidth: getWidth(),
    innerHeight: getHeight(),
    popupHeight: 0,
    popupWidth: 0,
    deleteInfo: undefined,
    errorInfo: undefined,
    currentTutorial: null,
    selectedOption: SelectedOption.seletedOptionValue,
    hoverEffect: null,
    dropHoverEffect: null,
    dropActiveEffect: null,
    showOptions: false,
    operationInProgress: false
  };
}

const MainComponent = observer(
  class MainComponent extends Component<Props, State> {
    performance: Date | null;
    mounted: boolean;
    constructor(props: Props) {
      super(props);
      this.state = defaultState();
      this.performance = null;
      this.mounted = false;
      this.selectOption = this.selectOption.bind(this);
    }

    componentDidMount() {
      if (this.performance != null) {
        let timeMs = new Date().getTime() - this.performance.getTime();
        this.performance = null;
        Instrumentation.addInteraction("DataHub", timeMs);
      }
    }

    componentDidUpdate(prevProps: Props, prevState: State ) {
      if (this.state.operationInProgress !== prevState.operationInProgress) {
        if(this.state.operationInProgress) {
            window.onbeforeunload = function (e) {
                return "";
              }         
        } else {
            window.onbeforeunload = null;
        }
      } 
      if (this.performance != null) {
        let timeMs = new Date().getTime() - this.performance.getTime();
        this.performance = null;
        Instrumentation.addInteraction("DataHub", timeMs);
      }
    }

    selectOption(option: Option, id?: number | string) {
      SelectedOption.UpdateOption(option);
      SelectedOption.getSwitchTab && SelectedOption.setSelectedOption();
      id && this.setState({ newDatasetId: id });
    }

    private _setOperationInProgress = (state: boolean) => {
        this.setState({
            operationInProgress: state
        });
    }

    render() {
      if (!this.mounted) {
        this.performance = new Date();
        this.mounted = true;
      }

      let highlightTop =
        SelectedOption.seletedOptionValue === Option.Merge ||
        SelectedOption.seletedOptionValue === Option.Aggregate ||
        SelectedOption.seletedOptionValue === Option.Clean ||
        SelectedOption.seletedOptionValue === Option.Create_Variable ||
        SelectedOption.seletedOptionValue === Option.Reshape;

      return (
        <>
          <div
            className="content-wrapper"
            id="mainWrapper"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className={styles.content}>
              <section
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className={cx(styles.topBar, "unselectable")}>
                  <TopBarIcon />
                  <div className={styles.topBarLabel}>
                    {"Data Hub / "}
                    {SelectedOption.seletedOption &&
                      Option[SelectedOption.seletedOption].replace("_", " ")}
                  </div>
                </div>

                <div className={styles.container}>
                  <LeftSideSection
                    onSelectOption={this.selectOption}
                    selectedOption={SelectedOption.seletedOption!}
                  />

                  <div
                    className={"mainContainer"}
                    style={{ outline: "1px solid #e8e8e8", padding: 0 }}
                  >

                    {(defaultState().selectedOption && SelectedOption.seletedOption === 4) && (
                      <div className={styles.header}>
                        <h2 style={{ fontWeight: 500 }}>
                          INTRODUCTION TO{" "}
                          <span style={{ fontWeight: "bold" }}>
                            EISENGARD DATA HUB
                          </span>
                        </h2>
                        <p>
                          Learn how to use our Data Hub to
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            extract, load{" "}
                          </span>
                          and
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            transform{" "}
                          </span>
                          your data.
                        </p>
                      </div>
                    )}
                    <div
                      className={styles.landing_guide}
                      style={{
                        minHeight: SelectedOption.getshowOptions ? 260 : 195,
                      }}
                    >
                      <div className={styles.guide_content}>
                        <div className={styles.row_first}>
                          <div className={styles.step_hdng}>
                            {progressSteps.map((item, index) => {
                              let activeEffect =
                                SelectedOption.seletedOptionValue ===
                                item.value;
                              let hoverEffect =
                                this.state.hoverEffect === index;
                              return (
                                <div
                                  className={
                                    activeEffect ||
                                      (index === 1 && highlightTop)
                                      ? `${styles.steps} ${styles.active}`
                                      : index === 0 &&
                                        (this.state.hoverEffect === 1 ||
                                          this.state.hoverEffect === 2 ||
                                          this.state.hoverEffect === 3)
                                        ? `${styles.step_one} ${styles.steps}`
                                        : index === 1 &&
                                          (this.state.hoverEffect === 1 ||
                                            this.state.hoverEffect === 2 ||
                                            this.state.hoverEffect === 3)
                                          ? ` ${styles.step_two} ${styles.steps}`
                                          : index === 2 &&
                                            (this.state.hoverEffect === 2 ||
                                              this.state.hoverEffect === 3)
                                            ? ` ${styles.step_three} ${styles.steps}`
                                            : index === 3 &&
                                              this.state.hoverEffect === 3
                                              ? `${styles.step_four} ${styles.steps}`
                                              : styles.steps
                                  }
                                  onMouseOver={() => {
                                    this.setState({ hoverEffect: index });
                                  }}
                                  onMouseLeave={() => {
                                    this.setState({ hoverEffect: null });
                                  }}
                                  onClick={(evt) => {
                                    evt.stopPropagation();
                                    this.selectOption(item.value);
                                    SelectedOption.seletedOptionValue !==
                                      item.value &&
                                      SelectedOption.UpdateaskSwitchTab(true);

                                    index === 1 &&
                                      !SelectedOption.getAskSwitchTab &&
                                      SelectedOption.changeshowOptions(true);

                                    index !== 1 &&
                                      !SelectedOption.getAskSwitchTab &&
                                      SelectedOption.changeshowOptions(false);
                                  }}
                                >
                                  <p>
                                    {item.label}
                                    {index === 1 &&
                                      (SelectedOption.getshowOptions &&
                                        highlightTop) && (
                                        <span className={styles.add_new}>
                                          {progressSteps[1].discription.slice(
                                            10
                                          )}
                                        </span>
                                      )}
                                  </p>
                                  <div className={styles.step_circle}>
                                    <span className={styles.inner}></span>
                                  </div>
                                  {
                                    (index === 1 && (SelectedOption.getshowOptions && highlightTop)) && (
                                      <div className={styles.pop_listing}>
                                        <div
                                          className={styles.listing_content}
                                        >
                                          <ul className={styles.list}>
                                            {transform.map((option, ind) => {
                                              return (
                                                <li
                                                  className={
                                                    SelectedOption.seletedOptionValue ===
                                                      option.value
                                                      ? styles.active
                                                      : ""
                                                  }
                                                  onMouseOver={() =>
                                                    this.setState({
                                                      dropHoverEffect: ind,
                                                    })
                                                  }
                                                  onMouseLeave={() =>
                                                    this.setState({
                                                      dropHoverEffect: null,
                                                    })
                                                  }
                                                  onClick={(evt) => {
                                                    evt.stopPropagation();
                                                    this.setState({
                                                      dropActiveEffect: ind,
                                                    });
                                                    this.selectOption(
                                                      option.value
                                                    );
                                                    SelectedOption.seletedOptionValue !==
                                                      option.value &&
                                                      SelectedOption.UpdateaskSwitchTab(
                                                        true
                                                      );
                                                    // this.setState({ showOptions: true })
                                                  }}
                                                >
                                                  <span>
                                                    {this.state
                                                      .dropHoverEffect ===
                                                      ind ||
                                                      SelectedOption.seletedOptionValue ===
                                                      option.value ? (
                                                      <img src="/dist/img/data-exploration/list-point-active.svg" />
                                                    ) : (
                                                      <img src="/dist/img/data-exploration/list-point.svg" />
                                                    )}
                                                  </span>
                                                  {option.label}
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  }
                                  {
                                    <p className={styles.sub_hdng}>
                                      {index === 1 &&
                                        (SelectedOption.getshowOptions &&
                                          highlightTop)
                                        ? ""
                                        : item.discription}
                                    </p>
                                  }
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {SelectedOption.seletedOption === Option.Manage && (
                    <ManageSection newDataset={this.state.newDatasetId!} setOperationInProgress={this._setOperationInProgress} />
                  )}
                  {SelectedOption.seletedOption === Option.Merge && (
                    <MergeSection setOperationInProgress={this._setOperationInProgress} />
                  )}
                  {SelectedOption.seletedOption === Option.Import && (
                    <ImportDataPage dataHubMode onSuccess={this.selectOption} setOperationInProgress={this._setOperationInProgress} />
                  )}
                  {SelectedOption.seletedOption === Option.Aggregate && (
                    <AggregateSection setOperationInProgress={this._setOperationInProgress} />
                  )}
                  {SelectedOption.seletedOption === Option.Export && (
                    <ExportSection setOperationInProgress={this._setOperationInProgress} />
                  )}
                  {SelectedOption.seletedOption === Option.Create_Variable && (
                    <CreateVariablesSection setOperationInProgress={this._setOperationInProgress} />
                  )}
                  {SelectedOption.seletedOption === Option.Clean && (
                    <CleanSection setOperationInProgress={this._setOperationInProgress} />
                  )}
                  {SelectedOption.seletedOption === Option.Analyze && (
                    <AnalyzeSection setOperationInProgress={this._setOperationInProgress} />
                  )}
                  {SelectedOption.seletedOption === Option.Reshape && (
                    <PivotSection setOperationInProgress={this._setOperationInProgress} />
                  )}
                  {SelectedOption.seletedOption === Option.Visualize && (
                    <Visualize />
                  )}

                  {SelectedOption.seletedOption === Option.Trash && (
                    <TrashSection setOperationInProgress={this._setOperationInProgress}/>
                  )}

                </div>
              </section>
            </div>
          </div>
        </>
      );
    }
  }
);

const ConnectedComponent = () => {
  const store = useContext(StoreContext);
  const globalContext = useContext(GlobalContext);

  return <MainComponent store={store} globalContext={globalContext} />;
};

export { ConnectedComponent as MainComponent };
export let route = "/data_hub.html",
  section = "DataHub",
  leftPanelItem = {
    section: "Data Hub",
    href: "data_hub.html",
    icon: <InputIcon className={styles.icon} />,
  },
  requirePermission = "DataHub";
