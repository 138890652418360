enum UserType {
    Admin = "admin",
    Creator = "creator",
    User1 = "user1",
    User2 = "user2",
    Viewer = "viewer"
}

export enum UserTypeIndex {
    Admin = 0,
    Creator = 1,
    User1 = 2,
    User2 = 3,
    Viewer = 4
}

export interface Permission {
    [UserType.Admin]: AccessType;
    [UserType.Creator]: AccessType;
    [UserType.User1]: AccessType;
    [UserType.User2]: AccessType;
    [UserType.Viewer]: AccessType;
}

export enum AccessType {
    NO_ACCESS = 0,
    ACCESS = 1,
    VIEW = -1
}

// Determines the order of columns of access control editor
const userTypesOrdered: ReadonlyArray<UserType> = [
    UserType.Admin,
    UserType.Creator,
    UserType.User1,
    UserType.User2,
    UserType.Viewer,
];

export { UserType, userTypesOrdered };