import { Component } from "react";
import Instrumentation from "common/Instrumentation";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";

interface Props {
	sharedPolicy: CanvasSharedPolicy;
	moduleTitle: string;
}

export default class CanvasInteractionComponent<
	T extends Props,
	T1
> extends Component<T, T1> {
	performance: Date | null;
	performanceElement: string;
	constructor(props: T) {
		super(props);
		this.performance = null;
		this.performanceElement = "";
	}
	componentDidMount() {
		if (
			this.props.sharedPolicy === CanvasSharedPolicy.NotShared &&
			this.performance != null
		) {
			let timeMs = new Date().getTime() - this.performance.getTime();
			Instrumentation.addInteraction(
				"Canvas",
				timeMs,
				this.performanceElement,
				this.props.moduleTitle
			);
			this.performance = null;
			this.performanceElement = "";
		}
	}
	
	trackNewPerformance(element: string) {
		this.performance = new Date();
		this.performanceElement = element;
	}

	componentDidUpdate(_prevProps: T, _prevState: T1) {
		if (this.props.sharedPolicy === CanvasSharedPolicy.NotShared && this.performance != null) {
			let timeMs = new Date().getTime() - this.performance.getTime();
			Instrumentation.addInteraction(
				"Canvas",
				timeMs,
				this.performanceElement,
				this.props.moduleTitle
			);
			this.performance = null;
			this.performanceElement = "";
		}
	}
}
