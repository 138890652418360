import React from "react";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { ArrowPosition, CanvasElement } from "common/Canvas"
import FlowChartPreview from "./components/FlowChartPreview";
import { AnchorPosition, Relation } from "react-archer";


interface FlowChartAddProps {
    node: CanvasElement
    scale: number
    backgroundColor: string
    linkedParent: boolean
    element: string
    position: ArrowPosition
    rootDataTestId: any
    trackNewPerformance: (element: any) => void
    focused: boolean
    hovered: boolean
    onClearEditing: any
    canvasTreeStore: any
    linkedParentNode: any
    onStartLinkedParent: any
    onConnectionIntention: ((state: boolean) => void) | undefined;
    addLinkingArrow: (relation: Relation, origin: number, clear: boolean) => void;
    clearArrows: (previewOnly: boolean) => void;
    arrowConnectionIntention: boolean | undefined

}

const FlowChartAdd: React.FC<FlowChartAddProps> = 
    ({ node, 
       scale, 
       backgroundColor, 
       linkedParent, 
       element, 
       position, 
       rootDataTestId,
       trackNewPerformance,
       focused,
       hovered,
       onClearEditing,
       canvasTreeStore,
       addLinkingArrow,
       linkedParentNode,
       onStartLinkedParent,
       onConnectionIntention,
       arrowConnectionIntention,
       clearArrows }) => {

    const [shiftMultiplier, setShiftMultiplier] = React.useState(1);
    const [hover, setHover] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);
   
    React.useEffect(() => {
        setShiftMultiplier(_calculatePositionMultiplier(node));
    }, [node]);
   
    React.useEffect(() => {
        if(!arrowConnectionIntention)
            setClicked(false)
    }, [arrowConnectionIntention]);
    const arrowPositionToAnchor = function (position: ArrowPosition): AnchorPosition {
        switch (position) {
            case ArrowPosition.Left:
                return "left";
            case ArrowPosition.Right:
                return "right";
            case ArrowPosition.Bottom:
                return "bottom";
            case ArrowPosition.Top:
                return "top";
            default:
                return "bottom";
        }
    }

    const arrowPositionToAnchorInvert = function (position: ArrowPosition): AnchorPosition {
        switch (position) {
            case ArrowPosition.Left:
                return "right";
            case ArrowPosition.Right:
                return "left";
            case ArrowPosition.Bottom:
                return "top";
            case ArrowPosition.Top:
                return "bottom";
            default:
                return "bottom";
        }
    }

    const _handlePreviewArrow = () => {
        if(linkedParent !== false) {
            setHover(true)
            addLinkingArrow({
                targetId: "archer-element-node-preview",
                sourceAnchor: arrowPositionToAnchor(position),
                targetAnchor: arrowPositionToAnchorInvert(position),
            }, node.id ,false);
        } else {
            clearArrows(true);
        }
    }

    const _calculatePositionMultiplier = (node: CanvasElement) => {
        let childsOnRegion
            = node.parentIds.filter((x) => x.childPosition === position);

        if((childsOnRegion.length % 2) === 0) {
            return (childsOnRegion.length/2)*(-1) 
        } else {
            return(childsOnRegion.length + 1)/2
        }        
    }

    const _startNewConnection = (evt:any) => {    
        setHover(false);
        trackNewPerformance(element);
        evt.stopPropagation();
        evt.preventDefault();
        if (linkedParent) {
            onClearEditing();    
        } else if (linkedParentNode != null) {
            clearArrows(false);
            let child = linkedParentNode.childrenIds.includes(
                node.id
            );
            if (!child) {                            
                canvasTreeStore.addEdgeAction(
                    linkedParentNode.id,
                    node.id,
                    linkedParentNode.position,
                    position
                );
                onClearEditing();
            } else {
                let edge = node.parentIds.find(
                    (edge) =>
                        edge.id === linkedParentNode.id
                )!;
                canvasTreeStore.deleteEdgeAction(
                    linkedParentNode.id,
                    node.id
                );
                if (
                    edge.parentPosition !==
                        linkedParentNode.position ||
                    edge.childPosition !== position
                ) {
                    canvasTreeStore.addEdgeAction(
                        linkedParentNode.id,
                        node.id,
                        linkedParentNode.position,
                        position
                    );
                }    
                onClearEditing();
            }
            // return;
        } else {
            onClearEditing();
            onStartLinkedParent({
                ...(node as CanvasElement),
                position: position,
            });
            addLinkingArrow({
                targetId: `archer-element-node-${node.id}`,
                sourceAnchor: arrowPositionToAnchorInvert(position),
                targetAnchor: arrowPositionToAnchor(position),
            },node.id, false);
        }  
    }

    let style: React.CSSProperties = {};
    let styleHover: React.CSSProperties = {};

    let transform = "";
    switch (position) {
        case ArrowPosition.Left:
            style = {
                left: 0,
                top: "50%",
                transform: "translate(-160%, -50%)",
            };
            styleHover = {
                left: 0,
                top: "50%",
                transform: "translate(0%, -50%)",
                width: scale*5,
                height: scale*15
            }
            transform = "rotate(90)";

            break;
        case ArrowPosition.Bottom:
            style = {
                bottom: 0,
                left: "50%",
                transform: "translate(-50%, 160%)",
            };
            styleHover = {
                bottom: 0,
                left: "50%",
                transform: "translate(-50%, 0%)",
                width: scale*15,
                height: scale*5
            };
            transform = "";

            break;
        case ArrowPosition.Right:
            style = {
                right: 0,
                top: "50%",
                transform: "translate(160%, -50%)",
            };
            styleHover = {
                right: 0,
                top: "50%",
                transform: "translate(0%, -50%)",
                width: scale*5,
                height: scale*15
            };
            transform = "rotate(-90)";
            break;
        case ArrowPosition.Top:
            style = {
                top: 0,
                left: "50%",
                transform: "translate(-50%, -160%)",
            };
            styleHover = {
                top: 0,
                left: "50%",
                transform: "translate(-50%, 0%)",
                width: scale*15,
                height: scale*5
            };
            transform = "rotate(180)";
            break;
        default:
            break;
    }

    return (focused || hovered)? (
        <>
            <div
                data-test-id={`${rootDataTestId}-linkarrow-${ArrowPosition[position]}`}
                className="not-selectable-by-pointer"
                onMouseEnter={() => { 
                    if(focused)
                       _handlePreviewArrow();
                }}
                onMouseLeave={() => {
                    setHover(false)
                   clearArrows(true)
                }}
                style={{
                    position: "absolute",
                    width: 24 * scale,
                    height: 24 * scale,
                    borderRadius: (hovered&&!focused)? "10%":"50%",
                    borderWidth: 3 * scale,
                    backgroundColor: (hovered&&!focused)? "#3C83CA":backgroundColor,
                    borderStyle: "solid",
                    borderColor: "#3C83CA",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: (hovered&&!focused)? "crosshair":"pointer",
                    zIndex: 1,
                    ...((hovered&&!focused)? styleHover : style),
                }}

                onMouseDown={(evt) =>{
                    evt.stopPropagation();
                    evt.preventDefault();

                    if(!focused) {
                        setClicked(true);
                        onConnectionIntention?.(true);
                        _startNewConnection(evt);
                    } 
                }}
                onMouseUp={async (evt) => {
                    console.log('mouseup')
                    if(!clicked && !focused) {
                        _startNewConnection(evt)
                        onConnectionIntention?.(false);
                    }
                }}
                onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();

                    if(focused) {
                        trackNewPerformance(element);
                        evt.stopPropagation();
                        evt.preventDefault();
                        onClearEditing();
                        let newPosition = ArrowPosition.Left;
                        if (position === ArrowPosition.Left)
                            newPosition = ArrowPosition.Right;
                        if (position === ArrowPosition.Top)
                            newPosition = ArrowPosition.Bottom;
                        if (position === ArrowPosition.Bottom)
                            newPosition = ArrowPosition.Top;
                        if (position === ArrowPosition.Right)
                            newPosition = ArrowPosition.Left;
                        canvasTreeStore.addNodeAction(
                            {
                                id: node.id,
                                parentPosition: position,
                                childPosition: newPosition,
                                shift: shiftMultiplier
                            },
                            {}
                        );
                        clearArrows(false);
                        _handlePreviewArrow();
                    }
                }}
            >
            {
              (hovered&&!focused)? 
                <></> : 
                <ChevronIcon
                    style={{
                        pointerEvents: "none",
                    }}
                    transform={transform}
                    stroke="#1e2ff1"
                    width={6 * scale}
                    height={2.4 * scale}
                />
            }
           
            </div>
            {
                (hover&&focused)? 
                <FlowChartPreview 
                    shift={shiftMultiplier}
                    scale={scale}
                    node={node}
                    arrowPosition={position}/> : <></>
            }

        </>
    ) : <></>;
}

export default FlowChartAdd