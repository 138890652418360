import Variables from "common/Variables";
import axios from "common/ServerConnection";

class Api {
    static getFinding(props) {
        let { clust, outcome, config } = props;
        let item = {
            type: "7",
            content: {
                clust: clust,
                outcome: outcome,
                frozen: true,
            },
            config: config,
        };
        return item;
    }

    static getData(config, findingOptions, moduleId) {
        let {
            dataScope,
            targetVariableIndex,
            additionalParameterIndex,
            variableIndices,
            variables,
            selectedTable,
            leftAdditionalValue,
        } = config;
        let dataTableIdx =
            typeof dataScope === "undefined" ? 0 : dataScope.value;
        let request = {
            data_table_idx: dataTableIdx,
            variable_indices: variableIndices,
            outcome_index: targetVariableIndex,
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            panel_index: additionalParameterIndex,
            panel_value: leftAdditionalValue
                ? leftAdditionalValue.value
                : undefined,
            module_id: moduleId,
        };

        return axios
            .post("/api/e/get_levers_optimizer_data", request)
            .then((response) => {
                if (response.data.success) {
                    let clust = [
                        variables.map((item) => ({
                            name: item.toUpperCase(),
                            units: Variables(dataTableIdx, moduleId).getUnits(item),
                            current: response.data.current_levels[item] || 0,
                            projected:
                                response.data.projected_levels[item] || 0,
                        })),
                    ];
                    let responseOutcome = response.data.outcome;

                    let newOutcome = {};
                    newOutcome.current = responseOutcome.current_level;
                    newOutcome.projected = responseOutcome.projected_level;
                    newOutcome.units = Variables(dataTableIdx, moduleId).getUnits(
                        responseOutcome.name
                    );
                    newOutcome.variable = responseOutcome.name;
                    newOutcome.name = responseOutcome.name.toUpperCase();
                    return Promise.resolve(
                        Api.getFinding({
                            outcome: newOutcome,
                            clust: clust,
                            config: config,
                        })
                    );
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
