import React from "react";
import { observer } from "mobx-react-lite";
import Select, { createFilter } from "react-select";
import Switch from "react-switch";
import "rc-slider/assets/index.css";
import switchStyleProps from "../../../SwitchStyleProps";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import variables, { Variable } from "common/Variables";
import styles from "./SideBySideSection.module.css";
import StringOption from "common/StringOption";
import { SearchComponentOption } from "common/SearchComponent";
import ValueWithOperator from "common/ValueWithOperator";
import commonStyles from "../../DataSection.module.css";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { SideBySideSectionProps } from "../../SideBySideSection";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import { BarChartFinding } from "common/Finding";
import SortableList from "common/SortableList";

interface NullableNumberOption {
    label: string;
    value: number | null;
}

interface Props extends SideBySideSectionProps {
    finding: BarChartFinding;
}

export default observer(function SideBySideSection(props: Props) {
    let dataScopeId = props.finding.config.dataScope.value;
    let sideBySideVariableIndex = props.finding.config.sideBySideVariableIndex;
    let variableOptions = variables(dataScopeId, props.currentModuleId)
        .variableOptions;
    let options: NullableNumberOption[] = [
        {
            label: "None",
            value: null,
        } as NullableNumberOption,
    ].concat(
        variableOptions.map((option) => ({
            label: option.label,
            value: option.value,
        }))
    );
    let additionalValues: (SearchComponentOption | null)[] =
        props.finding.config.additionalValues ?? [];
    if (additionalValues.length === 0) {
        additionalValues.push(null);
    }
    let additionalOperators: StringOption[] =
        props.finding.config.additionalOperators ?? [];
    if (additionalOperators.length === 0) {
        additionalOperators.push({
            label: "=",
            value: "=",
        });
    }
    let variableInfo: Variable | undefined = undefined;
    const dataVariables = variables(dataScopeId, props.currentModuleId)
        .dataVariables;
    if (
        sideBySideVariableIndex != null &&
        sideBySideVariableIndex < dataVariables.length
    )
        variableInfo = dataVariables[sideBySideVariableIndex];
    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    let updateFinding = true;
    let groupByAll: boolean = props.finding.config.groupByAll ?? true;
    let linkedVariables = props.finding.content.data.filter(
        (item: any) => item.variableIndex != null
    );
    let groupByAllMaxLength = undefined;
    if (groupByAll == null) groupByAll = linkedVariables.length === 1;
    let groupByAllDisabled =
        groupByAllMaxLength != null &&
        linkedVariables.length > groupByAllMaxLength;
    return (
        <div className={styles.container}>
            <span className={commonStyles.thinOptionName}>{"Group by"}</span>
            <div className={commonStyles.optionContainer}>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Select variable"}
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                            height: "38px",
                        }),
                    }}
                    options={options}
                    onChange={(newValue) => {
                        let newFinding = {
                            ...props.finding,
                            config: {
                                ...props.finding.config,
                                sideBySideVariableIndex: (newValue as NullableNumberOption)
                                    .value,
                                sideBySideVariable: (newValue as NullableNumberOption)
                                    .label,
                                groupByAll:
                                    props.finding.config.groupByAll ?? true,
                                additionalOperators: null,
                                additionalValues: null,
                            },
                            content: {
                                ...props.finding.content,
                                varyByIndices: null,
                            },
                        };
                        props.onChange(newFinding, updateFinding);
                    }}
                    value={options.find(
                        (option) =>
                            option.value ===
                            props.finding.config.sideBySideVariableIndex
                    )}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            {props.finding.config.sideBySideVariableIndex != null && (
                <div
                    className="my-row"
                    style={{ alignItems: "center", marginTop: 10 }}
                >
                    <span className={commonStyles.optionName}>
                        Side by side
                    </span>
                    <Switch
                        checked={!groupByAll || groupByAllDisabled}
                        disabled={groupByAllDisabled}
                        {...switchStyleProps}
                        onChange={(checked) => {
                            let newFinding = {
                                ...props.finding,
                                config: {
                                    ...props.finding.config,
                                    groupByAll: !checked,
                                },
                            };
                            props.onChange(newFinding, updateFinding);
                        }}
                    />
                </div>
            )}

            {(!groupByAll || groupByAllDisabled) &&
                variableInfo != null &&
                additionalValues.map(
                    (value: SearchComponentOption | null, index: number) => (
                        <div
                            className="my-row"
                            key={index}
                            style={{ alignItems: "center", marginTop: 10 }}
                        >
                            <ValueWithOperator
                                nospace
                                smallText
                                small
                                selectStyles={selectStyles}
                                currentModuleId={props.currentModuleId}
                                dataScopeId={dataScopeId}
                                operator={additionalOperators[index]}
                                value={value}
                                searchKey={variableInfo!.name}
                                searchIndex={
                                    props.finding.config.sideBySideVariableIndex
                                }
                                numeric={
                                    variableInfo!.type === "float" ||
                                    variableInfo!.type === "int"
                                }
                                equalsOnly={false}
                                onOperatorChange={(newOperator) => {
                                    let newAdditionalOperators: StringOption[] = Array.from(
                                        additionalOperators
                                    );
                                    newAdditionalOperators[index] = newOperator;
                                    let newFinding = {
                                        ...props.finding,
                                        config: {
                                            ...props.finding.config,
                                            additionalOperators: newAdditionalOperators,
                                        },
                                    };
                                    props.onChange(newFinding, true);
                                }}
                                onValueChange={(newValue) => {
                                    let newAdditionalValues: (SearchComponentOption | null)[] = Array.from(
                                        additionalValues
                                    );
                                    newAdditionalValues[index] = newValue;
                                    let newFinding = {
                                        ...props.finding,
                                        config: {
                                            ...props.finding.config,
                                            additionalValues: newAdditionalValues,
                                        },
                                    };
                                    props.onChange(newFinding, updateFinding);
                                }}
                                clearable={true}
                            />
                            {index === 0 && props.maxValuesCount > 1 && (
                                <Button
                                    title="Add value"
                                    className={cx(
                                        "btn btn-sm btn-primary my-primary",
                                        commonStyles.addColumnButton
                                    )}
                                    onClick={() => {
                                        if (
                                            additionalValues.length >=
                                            props.maxValuesCount
                                        ) {
                                            return;
                                        }
                                        let newAdditionalValues = Array.from(
                                            additionalValues
                                        );

                                        newAdditionalValues.splice(
                                            index + 1,
                                            0,
                                            null
                                        );
                                        let newAdditionalOperators = Array.from(
                                            additionalOperators
                                        );
                                        newAdditionalOperators.splice(
                                            index + 1,
                                            0,
                                            {
                                                label: "=",
                                                value: "=",
                                            }
                                        );

                                        let newFinding = {
                                            ...props.finding,
                                            config: {
                                                ...props.finding.config,
                                                additionalValues: newAdditionalValues,
                                                additionalOperators: newAdditionalOperators,
                                            },
                                        };
                                        props.onChange(newFinding);
                                    }}
                                >
                                    {"\uFF0B"}
                                </Button>
                            )}
                            {index === 1 && (
                                <Button
                                    title="Remove value"
                                    className={cx(
                                        "btn btn-sm btn-primary my-primary",
                                        commonStyles.addColumnButton,
                                        commonStyles.deleteColumnButton
                                    )}
                                    onClick={() => {
                                        let newAdditionalValues = Array.from(
                                            additionalValues
                                        );
                                        newAdditionalValues.splice(index, 1);
                                        if (newAdditionalValues.length === 0) {
                                            newAdditionalValues.push(null);
                                        }
                                        let newAdditionalOperators = Array.from(
                                            additionalOperators
                                        );
                                        newAdditionalOperators.splice(index, 1);
                                        if (
                                            newAdditionalOperators.length === 0
                                        ) {
                                            newAdditionalOperators.push({
                                                label: "=",
                                                value: "=",
                                            });
                                        }
                                        let newFinding = {
                                            ...props.finding,
                                            config: {
                                                ...props.finding.config,
                                                additionalValues: newAdditionalValues,
                                                additionalOperators: newAdditionalOperators,
                                            },
                                        };
                                        props.onChange(
                                            newFinding,
                                            updateFinding
                                        );
                                    }}
                                >
                                    {"\u2715"}
                                </Button>
                            )}
                        </div>
                    )
                )}
            {props.finding.config.sideBySideVariableIndex != null &&
                props.finding.config.groupByAll &&
                props.finding.content.varyByData != null && (
                    <>
                        <span className={commonStyles.thinOptionName}>
                            {"Reorder values"}
                        </span>
                        {props.finding.config.sortYAxis && (
                            <span
                                className={commonStyles.thinOptionName}
                                style={{ marginLeft: 10 }}
                            >
                                {'Disable "Sort Y Axis" to reorder values'}
                            </span>
                        )}
                        {!props.finding.config.sortYAxis && (
                            <SortableList
                                elements={props.finding.content.varyByData.map(
                                    (item) => item.name
                                )}
                                indices={
                                    props.finding.content.varyByIndices ??
                                    props.finding.content.varyByData.map(
                                        (_, index) => index
                                    )
                                }
                                onDragEnd={(newIndices) => {
                                    let newFinding = {
                                        ...props.finding,
                                        content: {
                                            ...props.finding.content,
                                            varyByIndices: newIndices,
                                        },
                                    };
                                    props.onChange(newFinding, false);
                                }}
                                style={{
                                    width: "100%",
                                }}
                            />
                        )}
                    </>
                )}
        </div>
    );
});
