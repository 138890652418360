import "common/styles/App.css";
import React from "react";
import { reaction } from "mobx";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import Switch from "react-switch";
import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import ScatterExplorationChart from "common/graphics/ScatterExplorationChart";
import BaseJourney from "../../common/BaseJourney";
import { Api } from "./Api";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";
import PointsSelector from "common/graphics/PointsSelector";
import RangeSelector from "common/graphics/RangeSelector";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import BarSizeSlider from "../../common/BarSizeSlider";
import "pretty-checkbox/dist/pretty-checkbox.min.css";

const Step = Object.freeze({
    initial: 0,
    selectDataScope: 1,
    selectTable: 2,
    selectConditions: 3,
    selectTarget: 4,
    selectLabels: 5,
    selectHover: 6,
    selectCount: 7,
    // selectAdditionalParameter: 5,
    // selectAdditionalValue: 6,
    final: 8,
});

function StepFinal(props) {
    let {
        onBack,
        onShowRecipesPopup,
        onSaveFinding,
        finding,
        loading,
        onNewFinding,

        // data
    } = props;
    return (
        <>
            <div
                className="my-row"
                style={{
                    justifyContent: "space-between",
                    marginTop: 10,
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    onClick={() => {
                        onBack();
                    }}
                    style={{
                        minWidth: 100,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/data-exploration/chevron_back.png"
                    />
                </div>
                {loading ? (
                    <span className="exploration-big-title-span">
                        {"LOADING"}
                    </span>
                ) : (
                    finding && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "calc(100% - 200px)",
                                height: "100%",
                            }}
                        >
                            {finding.content && (
                                <div
                                    className="my-row"
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                    }}
                                >
                                    {finding.content.variables.length === 1 && (
                                        <>
                                            <BarSizeSlider
                                                defaultValue={50}
                                                onNewFinding={onNewFinding}
                                                finding={finding}
                                            />
                                            <div style={{ width: "10px" }} />
                                        </>
                                    )}

                                    <div
                                        className="pretty p-default"
                                        contentEditable={false}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={
                                                props.finding.config
                                                    ?.addLineOrDot ?? true
                                            }
                                            onChange={() => {
                                                let newFinding = {
                                                    ...props.finding,
                                                };
                                                newFinding.config.addLineOrDot =
                                                    !(
                                                        newFinding.config
                                                            ?.addLineOrDot ??
                                                        true
                                                    );
                                                onNewFinding(newFinding);
                                            }}
                                        />
                                        <div className="state p-primary">
                                            <label></label>
                                        </div>
                                    </div>
                                    <PointsSelector
                                        disabled={
                                            !(
                                                props.finding.config
                                                    ?.addLineOrDot ?? true
                                            )
                                        }
                                        finding={finding}
                                        onNewFinding={onNewFinding}
                                    />

                                    {finding.content.variables.length === 1 && (
                                        <RangeSelector
                                            value={finding}
                                            onChange={onNewFinding}
                                        />
                                    )}
                                </div>
                            )}

                            <div
                                style={{
                                    alignSelf: "center",
                                    width: "100%",
                                    height: "300px",
                                }}
                            >
                                <ErrorBoundary>
                                    <ScatterExplorationChart
                                        onFinishEditConfig={(config) => {
                                            let newFinding = {
                                                ...finding,
                                                config: config,
                                            };
                                            onNewFinding(newFinding);
                                        }}
                                        editable={true}
                                        config={finding.config}
                                        data={finding.content.data}
                                        label={finding.content.label}
                                        hoverVariables={
                                            finding.content.hoverVariables
                                        }
                                        type={finding.content.type}
                                        variables={finding.content.variables}
                                        additionalData={
                                            finding.content.additionalData
                                        }
                                        units={finding.content.units}
                                    />
                                </ErrorBoundary>
                            </div>

                            <FinalStepBottomBar
                                onShowRecipesPopup={onShowRecipesPopup}
                                onSaveFinding={onSaveFinding}
                                topMargin={20}
                            />
                        </div>
                    )
                )}
                <div
                    style={{
                        minWidth: 100,
                        height: "100%",
                    }}
                />
            </div>
        </>
    );
}

class MainComponent extends BaseJourney {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            previousStep: 0,
            step: 0,
            animationDirection: "top",
            selectedTargetVariables: [],
            selectedHoverVariables: [],
            targetVariables: [],
            allVariables: [],
            count: 100,
            finding: undefined,
            additionalParameter: undefined,
            leftAdditionalValue: undefined,
            rightAdditionalValue: undefined,
            additionalParameters: [],
            selectedTable: undefined,
            step2VariablesMap: {
                [Step.selectTarget]: [
                    "selectedTargetVariables",
                    "targetVariables",
                ],
                [Step.selectHover]: ["selectedHoverVariables", "allVariables"],
                // [Step.selectAdditionalParameter]: [
                // 	"additionalParameter",
                // 	"additionalParameters",
                // ],
            },
            addLineOrDot: true,
            labelsOn: false,
            selectedLabel: undefined,
            conditions: [
                {
                    variable: null,
                    operation: null,
                    value: null,
                    logical: null,
                    leftBracket: null,
                    rightBracket: null,
                },
            ],
        };
        this.getData = this.getData.bind(this);
        this.initializeVariables = this.initializeVariables.bind(this);
        this.switchVariables = this.switchVariables.bind(this);
    }

    clearOptionalArgs() {
        this.setState({
            addLineOrDot: true,
            selectedTargetVariables: [],
            selectedTargetVariablesIndices: [],
            selectedHoverVariables: [],
            selectedHoverVariablesIndices: [],
            additionalParameter: null,
            additionalParameterIndex: null,
            leftAdditionalOperator: null,
            leftAdditionalValue: null,
            rightAdditionalOperator: null,
            rightAdditionalValue: null,
            selectedTable: this.defaultTable(),
            selectedLabel: null,
            conditions: ConditionsSelector.defaultValue,
            dynamicOptions: {},
            nameMapping: null,
            axesColor: null,
            chartColor: null,
            maxYRange: null,
            minYRange: null,
            linesCount: null,
            barSize: null,
        });
    }
    getConfig() {
        let config = {
            version: configVersion,
            dataScope: this.state.dataScope ?? null,
            selectedTable: this.state.selectedTable ?? null,
            addLineOrDot: this.state.addLineOrDot ?? null,
            conditions: this.state.conditions ?? null,
            count: this.state.count || 100,
            selectedTargetVariables: this.state.selectedTargetVariables ?? null,
            selectedTargetVariablesIndices:
                this.state.selectedTargetVariables.map((variable) =>
                    this.getVariables().variableToIndex(variable)
                ) ?? null,
            selectedHoverVariables: this.state.selectedHoverVariables ?? null,
            selectedHoverVariablesIndices:
                this.state.selectedHoverVariables.map((variable) =>
                    this.getVariables().variableToIndex(variable)
                ) ?? null,
            additionalParameters: this.state.additionalParameters ?? null,
            leftAdditionalOperator: this.state.leftAdditionalOperator ?? null,
            rightAdditionalOperator: this.state.rightAdditionalOperator ?? null,
            leftAdditionalValue: this.state.leftAdditionalValue ?? null,
            rightAdditionalValue: this.state.rightAdditionalValue ?? null,
            additionalParameter: this.state.additionalParameter ?? null,
            additionalParameterIndex:
                this.getVariables().variableToIndex(
                    this.state.additionalParameter
                ) ?? null,
            journeyName: this.props.journeyName,
            labelsOn: this.state.labelsOn ?? null,
            selectedLabel: this.state.selectedLabel ?? null,
            nameMapping: this.state.nameMapping ?? null,
            axesColor: this.state.axesColor ?? null,
            chartColor: this.state.chartColor ?? null,
            maxYRange: this.state.maxYRange ?? null,
            minYRange: this.state.minYRange ?? null,
            linesCount: this.state.linesCount ?? null,
            barSize: this.state.barSize ?? null,
        };
        if (this.props.addToCanvas) {
            config.dynamicOptions = this.prepareDynamicOptions(
                this.state.dynamicOptions,
                config
            );
        }
        return config;
    }

    buildLabelSelector() {
        let options = this.getVariables().variableOptions.filter(
            (option) => option.type === "str"
        );
        return (
            <div
                className="my-row"
                style={{
                    height: "100%",
                    minHeight: "300px",
                }}
            >
                {this.buildLeftBar()}
                <div
                    className="flex-simple-column element"
                    style={{
                        height: "100%",
                        width: "100%",
                        marginTop: 25,
                        marginLeft: 100,
                        marginRight: 100,
                        overflow: "auto",
                    }}
                >
                    <span
                        className="exploration-big-title-span"
                        style={{
                            marginRight: "5px",
                        }}
                    >
                        {"Labels"}
                    </span>
                    <div
                        className="my-row"
                        style={{
                            width: "100%",
                            alignItems: "flex-end",
                        }}
                    >
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: "20px" }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-primary-text-color"
                                    ),
                                }}
                            >
                                {"On"}
                            </span>
                            <div style={{ marginLeft: 6 }}>
                                <Switch
                                    onChange={() => {
                                        this.setState((state) => ({
                                            labelsOn: !state.labelsOn,
                                            selectedLabel: undefined,
                                        }));
                                    }}
                                    checked={
                                        this.state.labelsOn &&
                                        this.state.selectedTargetVariables
                                            .length > 1
                                    }
                                    width={26}
                                    height={13}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        </div>
                        {this.state.labelsOn &&
                            this.state.selectedTargetVariables.length > 1 && (
                                <div style={{ width: 150, marginLeft: 20 }}>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                height: "38px",
                                            }),
                                        }}
                                        options={options}
                                        value={this.state.selectedLabel}
                                        onChange={(newValue) => {
                                            this.setState({
                                                selectedLabel: newValue,
                                            });
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }

    getData() {
        this.setState({ loading: true });

        Api.getData(this.getConfig(), undefined, this.props.currentModuleId)
            .then((finding) => {
                this.setState({ finding: finding, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error);
            });
    }

    buildCustomDynamicView(
        variableName,
        variablesName,
        multiselection,
        type,
        indexVariableName,
        maxCount = undefined
    ) {
        let selectedVariables = this.state[variableName];
        return this.props.addToCanvas
            ? selectedVariables?.map((variable, index) => (
                  <div
                      key={index}
                      className="my-row"
                      style={{
                          marginLeft: 5,
                          alignItems: "center",
                      }}
                  >
                      <span
                          className="canvas-switch"
                          style={{
                              color: mainStyle.getPropertyValue(
                                  "--exploration-primary-text-color"
                              ),
                          }}
                      >
                          {`${variable.toUpperCase()}: `}
                      </span>
                      <span
                          className="canvas-switch"
                          style={{
                              color: mainStyle.getPropertyValue(
                                  "--exploration-primary-text-color"
                              ),
                          }}
                      >
                          STATIC
                      </span>
                      <div style={{ marginLeft: 5 }}>
                          <Switch
                              onChange={(checked) => {
                                  let dynamicOptions = Object.assign(
                                      {},
                                      this.state.dynamicOptions
                                  );
                                  if (!checked) {
                                      if (variableName in dynamicOptions) {
                                          let locked =
                                              dynamicOptions[variableName]
                                                  .locked ?? {};
                                          locked[index] = true;
                                          let allLocked =
                                              selectedVariables.reduce(
                                                  (acc, _value, valIndex) =>
                                                      acc && locked[valIndex],
                                                  true
                                              );

                                          if (allLocked)
                                              dynamicOptions[variableName] =
                                                  null;
                                          else
                                              dynamicOptions[
                                                  variableName
                                              ].locked = locked;
                                      }
                                  } else {
                                      if (
                                          !(variableName in dynamicOptions) ||
                                          dynamicOptions[variableName] == null
                                      ) {
                                          let options = this.state[
                                              variablesName
                                          ].map((item) => ({
                                              label: item,
                                              value: this.getVariables().variableToIndex(
                                                  item
                                              ),
                                          }));
                                          let locked = {};
                                          selectedVariables.forEach(
                                              (_val, valIndex) => {
                                                  locked[valIndex] =
                                                      valIndex !== index;
                                              }
                                          );
                                          dynamicOptions[variableName] = {
                                              label: "Data",
                                              name: variableName,
                                              indexName: indexVariableName,
                                              multiselection: multiselection,
                                              options: options,
                                              type: type,
                                              maxCount: maxCount,
                                              locked: locked,
                                          };
                                      } else {
                                          let locked =
                                              dynamicOptions[variableName]
                                                  .locked;
                                          if (locked != null)
                                              delete locked[index];
                                      }
                                  }
                                  this.setState({
                                      dynamicOptions: dynamicOptions,
                                  });
                              }}
                              checked={
                                  variableName in this.state.dynamicOptions &&
                                  this.state.dynamicOptions[variableName] !=
                                      null &&
                                  !this.state.dynamicOptions[variableName]
                                      .locked?.[index]
                              }
                              width={26}
                              height={13}
                              offColor="#20293C"
                              onColor="#20293C"
                              checkedIcon={false}
                              uncheckedIcon={false}
                              offHandleColor="#70889E"
                              onHandleColor="#1F8EFA"
                          />
                      </div>
                      <span
                          className="canvas-switch"
                          style={{
                              marginLeft: 5,
                              color: mainStyle.getPropertyValue(
                                  "--exploration-secondary-text-color"
                              ),
                          }}
                      >
                          DYNAMIC
                      </span>
                  </div>
              ))
            : null;
    }

    buildContent() {
        switch (this.state.step) {
            case Step.initial:
                return this.buildInitial(
                    "How do you want to examine your variables?"
                );
            case Step.selectDataScope:
                return this.buildDataScopesSelector();
            case Step.selectTable:
                return this.buildTableSelector();
            case Step.selectConditions:
                return this.buildConditionsSelector();
            case Step.selectTarget:
                return this.buildMultiVariablesSelector(
                    "I want to examine",
                    "selectedTargetVariables",
                    "targetVariables",
                    DynamicOptionType.variable,
                    "selectedTargetVariablesIndices",
                    3,
                    this.buildCustomDynamicView(
                        "selectedTargetVariables",
                        "targetVariables",
                        true,
                        DynamicOptionType.variable,
                        "selectedTargetVariablesIndices",
                        3
                    )
                );
            case Step.selectLabels:
                return this.buildLabelSelector();
            case Step.selectHover:
                return this.buildMultiVariablesSelector(
                    "[+] show on hover",
                    "selectedHoverVariables",
                    "allVariables",
                    DynamicOptionType.variable,
                    "selectedHoverVariablesIndices",
                    5
                );
            case Step.selectCount:
                return this.buildInput(
                    "Randomly sample",
                    "points",
                    "count",
                    true
                );
            // case Step.selectAdditionalParameter:
            // 	return this.buildVariablesSelector(
            // 		"[+] and how they vary by",
            // 		"additionalParameter",
            // 		"additionalParameters",
            // 		false,
            // 		DynamicOptionType.additionalParameter,
            // 		"additionalParameterIndex"
            // 	);
            // case Step.selectAdditionalValue:
            // 	return this.buildAdditionalValueSelector(
            // 		`[+] and how they vary by ${this.state.additionalParameter}`,
            // 		true
            // 	);

            case Step.final:
                return (
                    <StepFinal
                        onShowRecipesPopup={this.showRecipesPopup}
                        loading={this.state.loading}
                        finding={this.state.finding}
                        onSaveFinding={this.saveFinding}
                        onNewFinding={this.onNewFinding}
                        onBack={this.back}
                    />
                );

            default:
                return <div />;
        }
    }
    buildLeftBar() {
        return super.buildLeftBar(this.state.step < Step.final - 1);
    }
    initializeVariables() {
        let scopeVariables = this.getVariables();
        let newState = Object.assign({}, this.state);
        newState.additionalParameters = [null].concat(
            scopeVariables.dataVariables.map((item) => item.name)
        );
        newState.targetVariables = scopeVariables.numericVariables.map(
            (item) => item.name
        );
        newState.allVariables = scopeVariables.dataVariables.map(
            (item) => item.name
        );
        this.setState(newState);
    }
    switchVariables() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
        this.initializeVariables();
        this.initializeVariablesReaction = reaction(
            () => this.getVariables().dataVariables,
            () => {
                this.initializeVariables();
            }
        );
    }

    componentDidMount() {
        this.getLastJourneyConfig();
    }
    componentWillUnmount() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
    }
    showFinalStep() {
        if (
            this.state.dataScope &&
            this.state.selectedTable &&
            this.state.selectedTargetVariables &&
            this.state.step > Step.initial &&
            this.state.step < Step.final
        )
            this.setState({ animationDirection: "top" }, () => {
                this.setState(
                    (state) => ({
                        previousStep: state.step,
                        step: Step.final,
                    }),
                    () => {
                        this.getData();
                        this.saveNewJourneyConfig();
                    }
                );
            });
    }

    selectVariable(variableName, variablesName, index, maxCount = undefined) {
        let stateDiff = {};
        let multiselection = Array.isArray(this.state[variableName]);
        if (!multiselection) {
            stateDiff = {
                [variableName]: this.state[variablesName][index],
            };
        } else {
            let selectedVariables = Array.from(this.state[variableName]);
            let newVariable = this.state[variablesName][index];
            if (selectedVariables.includes(newVariable)) {
                selectedVariables = selectedVariables.filter(
                    (item) => item !== newVariable
                );
            } else {
                selectedVariables.push(newVariable);
            }
            let allVariables = Array.from(this.state[variablesName]);
            selectedVariables = selectedVariables.filter((variable) =>
                allVariables.includes(variable)
            );
            if (maxCount != null) {
                if (selectedVariables.length > maxCount) {
                    selectedVariables = selectedVariables.slice(
                        selectedVariables.length - maxCount
                    );
                }
            }
            stateDiff = {
                [variableName]: selectedVariables,
            };
        }
        // if (this.state.step === Step.selectAdditionalParameter) {
        // 	stateDiff.leftAdditionalValue = undefined;
        // 	stateDiff.rightAdditionalValue = undefined;
        // }

        this.setState(stateDiff, () => {
            if (
                this.state.step !== Step.selectTarget &&
                this.state.step !== Step.selectHover
            )
                setTimeout(() => {
                    this.stepDown();
                }, 100);
        });
    }

    getSubtitle() {
        let conditionsString = conditionsToString(
            Array.from(this.state.conditions)
        );
        if (
            this.state.step === Step.selectTable ||
            this.state.step === Step.selectDataScope
        )
            return "How do you want to examine your variables?";
        if (
            this.state.step > Step.selectTable &&
            this.state.step < Step.final
        ) {
            let subtitle = `For the data in ${
                this.state.dataScope ? this.state.dataScope.label : ""
            } ${this.state.selectedTable.label}`;
            if (this.state.step > Step.selectConditions) {
                if (conditionsString.length > 0)
                    subtitle += ` and under the conditions ${conditionsString}`;
            }
            if (this.state.step > Step.selectTarget)
                subtitle += ` I want to examine ${this.state.selectedTargetVariables.join(
                    ", "
                )},`;
            // if (this.state.step > Step.selectAdditionalParameter)
            // 	subtitle += ` and how they vary by ${
            // 		this.state.additionalParameter || ""
            // 	}`;

            return subtitle;
        }

        if (this.state.step === Step.final) {
            return (
                <>
                    <span>For the data </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.dataScope
                            ? this.state.dataScope.label.concat(" ")
                            : ""}
                    </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedTable.label}
                    </span>
                    {conditionsString.length > 0 && (
                        <>
                            <span> and under the conditions </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {conditionsString}
                            </span>
                        </>
                    )}
                    <span> I want to examine </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedTargetVariables.join(", ")}
                    </span>
                    {this.state.additionalParameter &&
                    [
                        this.state.leftAdditionalValue,
                        this.state.rightAdditionalValue,
                    ]
                        .filter((item) => item)
                        .filter((item) => item.value).length > 0 ? (
                        <>
                            <span> and how they vary by </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {(this.state.leftAdditionalValue?.value != null
                                    ? ` ${this.state.additionalParameter} ${
                                          this.state.leftAdditionalOperator
                                              ?.value ?? "="
                                      } ${this.state.leftAdditionalValue.value}`
                                    : "") +
                                    (this.state.rightAdditionalValue?.value !=
                                    null
                                        ? (this.state.leftAdditionalValue
                                              ?.value != null
                                              ? ", "
                                              : " ") +
                                          `${this.state.additionalParameter} ${
                                              this.state.rightAdditionalOperator
                                                  ?.value ?? "="
                                          } ${
                                              this.state.rightAdditionalValue
                                                  .value
                                          }`
                                        : "")}
                            </span>
                        </>
                    ) : null}
                    <span>.</span>
                </>
            );
        }
        return "";
    }
    getFooterTitle() {
        switch (this.state.step) {
            case Step.initial:
                return "... For the data in...";
            case Step.selectTable:
                return "... and under the conditions...";
            case Step.selectConditions:
                return "... I want to examine ...";
            case Step.selectTarget:
                return "";
            case Step.selectLabels:
                return "... [+] show on hover ...";
            default:
                return "";
        }
    }
    stepDown() {
        let delta = 1;
        if (this.state.step === Step.selectTable && !this.state.selectedTable) {
            return;
        }
        if (
            this.state.step === Step.selectTarget &&
            this.state.selectedTargetVariables.length === 1
        ) {
            delta = 4;
        }
        if (this.state.step + delta < Step.final) {
            this.setState((state) => ({
                previousStep: state.step,
                step: state.step + delta,
            }));
        } else {
            this.showFinalStep();
        }
    }

    stepUp() {
        let step = this.state.step;

        if (step > Step.initial)
            this.setState({ animationDirection: "bottom" }, () => {
                this.setState({
                    step: step - 1,
                });
            });
    }
    render() {
        return (
            <div
                className="dashboard-rect-journey-focus"
                tabIndex="0"
                style={{
                    height: 520,
                    overflow: "hidden",
                    display: !this.props.collapsed ? "block" : "none",
                }}
                onClick={() => {
                    if (this.state.step === Step.initial) this.stepDown();
                }}
                onKeyDown={(evt) => {
                    if (evt.key === "Escape") {
                        this.props.onClose();
                    }
                    if (evt.key === "ArrowDown") {
                        if (
                            this.state.step > Step.initial &&
                            this.state.step < Step.final
                        )
                            this.stepDown();
                        evt.preventDefault();
                    }
                    if (evt.key === "ArrowLeft") {
                        if (this.state.step === Step.final) {
                            this.back();
                        }
                    }
                    if (evt.key === "ArrowRight") {
                        this.showFinalStep();
                    }
                    if (evt.key === "ArrowUp") {
                        if (this.state.step < Step.final) this.stepUp();
                        evt.preventDefault();
                    }
                    if (evt.key === "p") {
                        if (this.state.step === Step.final) {
                            this.saveFinding();
                        }
                    }
                    if (evt.key === "d") {
                        if (this.state.step === Step.final) {
                            evt.preventDefault();
                            this.showRecipesPopup();
                        }
                    }
                    let variableInfo =
                        this.state.step2VariablesMap[this.state.step];
                    if (variableInfo) {
                        let variablesLength =
                            this.state[variableInfo[1]].length;
                        let variableIndex = evt.keyCode - 65;
                        if (
                            variableIndex >= 0 &&
                            variableIndex < variablesLength
                        ) {
                            this.selectVariable(
                                variableInfo[0],
                                variableInfo[1],
                                variableIndex
                            );
                        }
                    }
                    if (evt.key === "Enter") {
                        // if (
                        // 	this.state.step === Step.selectAdditionalValue &&
                        // 	Variables(this.dataScopeValue()).isMonthVariable(
                        // 		this.state.additionalParameter
                        // 	) &&
                        // 	this.state.leftAdditionalValue &&
                        // 	!this.state.leftAdditionalValueSelected
                        // ) {
                        // 	this.setState({
                        // 		leftAdditionalValueSelected: true,
                        // 	});
                        // 	return;
                        // }
                        // if (
                        // 	this.state.step === Step.selectAdditionalValue &&
                        // 	Variables(this.dataScopeValue()).isMonthVariable(
                        // 		this.state.additionalParameter
                        // 	) &&
                        // 	this.state.leftAdditionalValueSelected &&
                        // 	this.state.rightAdditionalValue
                        // ) {
                        // 	this.setState(
                        // 		{
                        // 			rightAdditionalValueSelected: true,
                        // 		},
                        // 		() => {
                        // 			this.showFinalStep();
                        // 		}
                        // 	);
                        // 	return;
                        // }
                    }
                    // if (
                    // 	this.state.step === Step.selectAdditionalValue &&
                    // 	Variables(this.dataScopeValue()).isMonthVariable(
                    // 		this.state.additionalParameter
                    // 	)
                    // ) {
                    // 	this.selectMonth(evt.key, true);
                    // }
                }}
            >
                <div
                    className="my-row"
                    style={{ justifyContent: "space-between", height: "100%" }}
                >
                    <div
                        className="flex-column"
                        style={{
                            height: "100%",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {this.buildHeader()}

                        <TransitionGroup
                            style={{
                                minHeight: "inherit",
                            }}
                        >
                            <CSSTransition
                                style={{
                                    height: "100%",
                                }}
                                key={this.state.step}
                                timeout={500}
                                classNames={"journeywizard-".concat(
                                    this.state.animationDirection || ""
                                )}
                            >
                                <div
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    {this.buildContent()}
                                </div>
                            </CSSTransition>
                        </TransitionGroup>

                        <Footer
                            showArrow={
                                this.state.step > Step.initial &&
                                this.state.step < Step.final
                            }
                            title={this.getFooterTitle()}
                        />
                    </div>
                </div>
                {this.buildAddToRecipesPopup()}
            </div>
        );
    }
}

export { MainComponent };
