import React from "react";
import Leaflet from "leaflet";
import AnimationSlider from "common/AnimationSlider";
import { colorList } from "common/graphics/LineColors";
import { MapTooltipDisplayMode } from "common/Canvas";

import * as d3 from "d3";
import { NetworkFinding } from "./Finding";

interface GraphNode {
    id: number;
    x: number;
    y: number;
    metrics?: { [key: string]: string | number | null };
    nodeColorKey?: string | number | null;

    index?: number;

    // Forces
    fx?: number;
    fy?: number;
    vx?: number;
    vy?: number;
}

interface GraphEdge {
    source: GraphNode;
    target: GraphNode;
    weight: number | string;
    timeValue?: number | string | null;
    edgeColorKey?: string | number;
}

interface Props {
    networkId: string;
    edgeList: {
        source: number;
        target: number;
        weight?: number | string;
        time?: number | string | null;
        nodeColorKey?: string | number;
        edgeColorKey?: string | number;
        x?: number;
        y?: number;
    }[];
    attachNodeColorToTarget?: boolean;
    onNodeClick?: (nodeId: number) => void;
    onChangeConfig?: (config: NetworkFinding["config"], updateData?: boolean) => void;
    config?: NetworkFinding["config"];
    edgeColor: string;
    nodeColor: string;
    baseEdgeThickness: number;
    nodeRadius: number;
    dragActive: boolean;
    nodePlacement: "force" | "arc" | "edgebundling" | "map";
    nodeLabelsDisplayMode?: MapTooltipDisplayMode;
    nodeLabelsDisplayPosition?: "top" | "bottom" | "left" | "right";
    mapRef?: Leaflet.Map;
}

interface State {
    time: (number | string)[];
    nodeColorKeyToIndex: { [key: string | number]: number };
    edgeColorKeyToIndex: { [key: string | number]: number };
}

export const defaultEdgeColor = "#AAAAAA";
export const defaultNodeColor = "#69B3A2";
export const defaultBaseEdgeThickness = 1;

class Network extends React.Component<Props, State> {
    static defaultProps = {
        edgeColor: defaultEdgeColor,
        nodeColor: defaultNodeColor,
        baseEdgeThickness: 1,
        nodeRadius: 15,
        nodePlacement: "force",
    };

    private svgRef: React.RefObject<SVGSVGElement>;
    private rootGRef: React.RefObject<SVGGElement>;
    private edgeGRef: React.RefObject<SVGGElement>;
    private edgeLabelGRef: React.RefObject<SVGGElement>;
    private nodeGRef: React.RefObject<SVGGElement>;
    private nodeLabelGRef: React.RefObject<SVGGElement>;

    private edges: GraphEdge[];
    private nodes: GraphNode[];

    private d3Objects:
        | {
              svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
              rootG: d3.Selection<SVGGElement, unknown, null, undefined>;
              edgeG: d3.Selection<SVGGElement, unknown, null, undefined>;
              edgeLabelG: d3.Selection<SVGGElement, unknown, null, undefined>;
              nodeG: d3.Selection<SVGGElement, unknown, null, undefined>;
              nodeLabelG: d3.Selection<SVGGElement, unknown, null, undefined>;
              edges: d3.Selection<
                  SVGPathElement,
                  GraphEdge,
                  SVGGElement,
                  unknown
              >;
              edgeLabels: d3.Selection<
                  SVGTextElement,
                  GraphEdge,
                  SVGGElement,
                  unknown
              >;
              nodes: d3.Selection<
                  SVGCircleElement,
                  GraphNode,
                  SVGGElement,
                  unknown
              >;
              nodeLabels: d3.Selection<
                  SVGTextElement,
                  GraphNode,
                  SVGGElement,
                  unknown
              >;
              force: d3.Simulation<GraphNode, undefined>;
              zoom: d3.ZoomBehavior<SVGSVGElement, unknown>;
              drag: d3.DragBehavior<SVGCircleElement, GraphNode, GraphNode>;
          }
        | undefined;

    constructor(props: Props) {
        super(props);

        this.svgRef = React.createRef();
        this.rootGRef = React.createRef();
        this.edgeGRef = React.createRef();
        this.edgeLabelGRef = React.createRef();
        this.nodeGRef = React.createRef();
        this.nodeLabelGRef = React.createRef();

        this.edges = [];
        this.nodes = [];

        this.getEdgeId = this.getEdgeId.bind(this);
        this.getEdgeIdSearchString = this.getEdgeIdSearchString.bind(this);
        this.getEdgeLabel = this.getEdgeLabel.bind(this);
        this.getEdgeColor = this.getEdgeColor.bind(this);
        this.getNodeX = this.getNodeX.bind(this);
        this.getNodeY = this.getNodeY.bind(this);
        this.getNodeLabelX = this.getNodeLabelX.bind(this);
        this.getNodeLabelY = this.getNodeLabelY.bind(this);
        this.getNodeLabelText = this.getNodeLabelText.bind(this);
        this.getNodeLabelTextAnchor = this.getNodeLabelTextAnchor.bind(this);
        this.getNodeColor = this.getNodeColor.bind(this);
        this.drawEdge = this.drawEdge.bind(this);
        this.calculateStrokeWidth = this.calculateStrokeWidth.bind(this);
        this.onNodeClick = this.onNodeClick.bind(this);
        this.onNodeMouseOver = this.onNodeMouseOver.bind(this);
        this.onNodeMouseOut = this.onNodeMouseOut.bind(this);
        this.onNodeDragStart = this.onNodeDragStart.bind(this);
        this.onNodeDrag = this.onNodeDrag.bind(this);
        this.onNodeDragEnd = this.onNodeDragEnd.bind(this);
        this.onAnimationSliderChange = this.onAnimationSliderChange.bind(this);
        this.onMapViewReset = this.onMapViewReset.bind(this);

        let data = this.updateGraphFromProps();
        this.state = {
            time: data.time,
            nodeColorKeyToIndex: data.nodeColorKeyToIndex,
            edgeColorKeyToIndex: data.edgeColorKeyToIndex,
        };
    }

    public componentDidMount(): void {
        let svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
        let rootG: d3.Selection<SVGGElement, unknown, null, undefined>;
        let edgeG: d3.Selection<SVGGElement, unknown, null, undefined>;
        let edgeLabelG: d3.Selection<SVGGElement, unknown, null, undefined>;
        let nodeG: d3.Selection<SVGGElement, unknown, null, undefined>;
        let nodeLabelG: d3.Selection<SVGGElement, unknown, null, undefined>;

        if (this.props.mapRef != null) {
            svg = d3
                .select(this.props.mapRef.getPane("overlayPane")!)
                .select("svg");
            // In new versions of Leaflet the svg already has a g appended to it
            rootG = svg.select("g");
            edgeG = rootG.append("g");
            edgeLabelG = rootG.append("g");
            nodeG = rootG.append("g");
            nodeLabelG = rootG.append("g");
        } else {
            svg = d3.select(this.svgRef.current!);
            rootG = d3.select(this.rootGRef.current!);
            edgeG = d3.select(this.edgeGRef.current!);
            edgeLabelG = d3.select(this.edgeLabelGRef.current!);
            nodeG = d3.select(this.nodeGRef.current!);
            nodeLabelG = d3.select(this.nodeLabelGRef.current!);
        }

        svg.style("pointer-events", this.props.dragActive ? "all" : "none");
        this.initViewBox(svg);

        let drag = d3
            .drag<SVGCircleElement, GraphNode, GraphNode>()
            .on("start", this.onNodeDragStart)
            .on("drag", this.onNodeDrag)
            .on("end", this.onNodeDragEnd);

        let edges = this.updateEdges(
            edgeG
                .selectAll<SVGPathElement, GraphEdge[]>("path")
                .data(this.edges)
        );

        let edgeLabels = this.updateEdgeLabels(
            edgeLabelG
                .selectAll<SVGTextElement, GraphEdge[]>("text")
                .data(this.edges)
        );

        let nodes = this.updateNodes(
            nodeG
                .selectAll<SVGCircleElement, GraphNode[]>("circle")
                .data(this.nodes),
            drag
        );

        let nodeLabels = this.updateNodeLabels(
            nodeLabelG
                .selectAll<SVGTextElement, GraphNode[]>("text")
                .data(this.nodes)
        );

        // Automatic node placement
        // This needs to be initialized regardless of this.props.nodePlacement
        // to allow changing nodePlacement at runtime
        let force = d3
            .forceSimulation(this.nodes)
            .force(
                "link",
                d3.forceLink<GraphNode, GraphEdge>(this.edges).id((d) => {
                    return d.id;
                })
            )
            .force("charge", d3.forceManyBody().strength(-800))
            .force("center", d3.forceCenter())
            .force("collide", d3.forceCollide())
            .on("tick", () => {
                if (this.d3Objects != null) {
                    this.d3Objects.edges.attr("d", this.drawEdge);

                    this.d3Objects.nodes
                        .attr("cx", this.getNodeX)
                        .attr("cy", this.getNodeY);

                    this.d3Objects.nodeLabels
                        .attr("x", this.getNodeLabelX)
                        .attr("y", this.getNodeLabelY);

                    this.rotateEdgeLabels(edgeLabels)
                }
            });

        // Zooming and panning
        let zoom = d3.zoom<SVGSVGElement, unknown>().on("zoom", (e) => {
            if (this.props.dragActive) {
                rootG.attr("transform", e.transform);
            }
        });
        if (this.props.nodePlacement !== "map") {
            svg.call(zoom);
        }

        this.d3Objects = {
            svg: svg,
            rootG: rootG,
            edgeG: edgeG,
            edgeLabelG: edgeLabelG,
            nodeG: nodeG,
            nodeLabelG: nodeLabelG,
            edges: edges,
            edgeLabels: edgeLabels,
            nodes: nodes,
            nodeLabels: nodeLabels,
            zoom: zoom,
            force: force,
            drag: drag,
        };

        // Place the nodes
        if (this.props.nodePlacement === "force") {
            force.tick(300);
        } else {
            force.stop();
        }

        if (this.props.nodePlacement === "map") {
            this.props.mapRef?.on("viewreset", this.onMapViewReset);
            this.props.mapRef?.on("zoom", this.onMapViewReset);
            this.onMapViewReset();
        }

        // Zoom to fit initially
        this.zoomToFit();

        this.initializeTimeAnimation();
    }

    private initViewBox(svg: d3.Selection<SVGSVGElement, unknown, null, undefined>): void {
        if (this.props.nodePlacement === "map") {
            return;
        }

        let viewBox = '0 0 0 0';
        const w = this.svgRef.current!.clientWidth;
        const h = this.svgRef.current!.clientHeight;
        const min = Math.min(w, h);
        const max = Math.max(w, h);
        if (w > h) viewBox = `0 0 ${max} ${min}`
        else viewBox = `0 0 ${min} ${max}`
        svg.attr("viewBox", viewBox);
    }

    public componentWillUnmount() {
        this.d3Objects?.edgeG.remove();
        this.d3Objects?.edgeLabelG.remove();
        this.d3Objects?.nodeG.remove();
        this.d3Objects?.nodeLabelG.remove();
    }

    public componentDidUpdate(prevProps: Props, prevState: State): void {
        if (
            prevProps.edgeList !== this.props.edgeList ||
            prevProps.nodeLabelsDisplayMode !==
                this.props.nodeLabelsDisplayMode ||
            prevProps.nodeLabelsDisplayPosition !==
                this.props.nodeLabelsDisplayPosition ||
            prevProps.nodePlacement !== this.props.nodePlacement
        ) {
            let data = this.updateGraphFromProps();
            this.setState({
                time: data.time,
                nodeColorKeyToIndex: data.nodeColorKeyToIndex,
                edgeColorKeyToIndex: data.edgeColorKeyToIndex,
            });
            // Update the data
            if (this.d3Objects != null) {
                // Update edges
                this.d3Objects.edges = this.updateEdges(
                    this.d3Objects.edgeG
                        .selectAll<SVGPathElement, GraphEdge[]>("path")
                        .data(this.edges)
                );

                // Update edge labels
                this.d3Objects.edgeLabels = this.updateEdgeLabels(
                    this.d3Objects.edgeLabelG
                        .selectAll<SVGTextElement, GraphEdge[]>("text")
                        .data(this.edges)
                );

                // Update nodes
                this.d3Objects.nodes = this.updateNodes(
                    this.d3Objects.nodeG
                        .selectAll<SVGCircleElement, GraphNode[]>("circle")
                        .data(this.nodes)
                );

                // Update labels
                this.d3Objects.nodeLabels = this.updateNodeLabels(
                    this.d3Objects.nodeLabelG
                        .selectAll<SVGTextElement, GraphNode[]>("text")
                        .data(this.nodes)
                );

                // Update forces
                if (this.props.nodePlacement === "force") {
                    this.d3Objects.force.alpha(1).restart().nodes(this.nodes);
                    (this.d3Objects.force.force("link") as d3.ForceLink<
                        GraphNode,
                        GraphEdge
                    >).links(this.edges);
                    this.d3Objects.force.tick(300);
                } else {
                    this.d3Objects.force.stop();
                }

                if (prevProps.nodePlacement !== this.props.nodePlacement) {
                    this.zoomToFit();
                }
            }
        }
        if (
            prevProps.edgeColor !== this.props.edgeColor ||
            prevState.edgeColorKeyToIndex !== this.state.edgeColorKeyToIndex
        ) {
            this.d3Objects?.edges.style("stroke", this.getEdgeColor);
            this.d3Objects?.edgeLabels.attr("fill", this.getEdgeColor);
        }
        if (
            prevProps.nodeColor !== this.props.nodeColor ||
            prevState.nodeColorKeyToIndex !== this.state.nodeColorKeyToIndex
        ) {
            this.d3Objects?.nodes.style("fill", this.getNodeColor);
        }
        if (prevProps.baseEdgeThickness !== this.props.baseEdgeThickness) {
            this.d3Objects?.edges.style(
                "stroke-width",
                this.calculateStrokeWidth
            );
        }
        if (prevProps.dragActive !== this.props.dragActive) {
            this.d3Objects?.svg.style(
                "pointer-events",
                this.props.dragActive ? "all" : "none"
            );
        }
        if (prevProps.nodeRadius !== this.props.nodeRadius) {
            this.d3Objects?.nodes.attr("r", this.props.nodeRadius);
        }
        if (prevState.time !== this.state.time) {
            this.initializeTimeAnimation();
        }
    }

    private renderOverlays(): JSX.Element {
        let nodeColors = Object.entries(this.state.nodeColorKeyToIndex);
        let edgeColors = Object.entries(this.state.edgeColorKeyToIndex);
        let animationSliderStyle: React.CSSProperties;
        let legendAlignment: React.CSSProperties;
        let legendMargins: React.CSSProperties;
        if (this.props.mapRef != null) {
            animationSliderStyle = {
                position: "absolute",
                left: 60,
                top: 11,
                pointerEvents: "auto",
                zIndex: 999,
                backgroundColor: "white",
                borderRadius: "3px",
                opacity: 0.9,
                boxShadow: "0 1px 3px rgba(0,0,0,.4)",
                padding: "4px",
            };
            legendAlignment = {
                justifyContent: "flex-end",
                alignItems: "flex-start",
            };
            legendMargins = {
                marginTop: "30px",
                marginRight: "30px",
            };
        } else {
            animationSliderStyle = {
                pointerEvents: "auto",
            };
            legendAlignment = {
                justifyContent: "flex-start",
                alignItems: "flex-end",
            };
            legendMargins = {
                marginBottom: "30px",
                marginLeft: "30px",
            };
        }
        return (
            <>
                {this.state.time.length !== 0 && (
                    <AnimationSlider
                        style={animationSliderStyle}
                        sliderStyle={{
                            cursor: "default",
                            width: 165,
                            pointerEvents: "auto",
                        }}
                        values={this.state.time}
                        onChange={this.onAnimationSliderChange}
                        onChangeConfig={this.props.onChangeConfig}
                        config={this.props.config}
                    />
                )}
                {(nodeColors.length !== 0 || edgeColors.length !== 0) && (
                    <div
                        style={{
                            position: "absolute",
                            bottom: "0px",
                            left: "0px",
                            display: "flex",
                            ...legendAlignment,
                            width: "100%",
                            height: "100%",
                            zIndex: 999,
                            pointerEvents: "none",
                        }}
                    >
                        <div
                            style={{
                                ...legendMargins,
                                maxHeight: "calc(100% - 65px)",
                                opacity: 0.9,
                                boxShadow: "0 1px 3px rgba(0,0,0,.4)",
                                paddingLeft: "6px",
                                paddingTop: "6px",
                                paddingBottom: "6px",
                                paddingRight: "8px",
                                borderRadius: "3px",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: "white",
                                backgroundColor: "white",
                                userSelect: "none",
                                display: "flex",
                                flexDirection: "column",
                                overflowY: "auto",
                                pointerEvents: "auto",
                                scrollbarWidth: "thin",
                            }}
                        >
                            {nodeColors.map(([colorKey, colorIndex], index) => (
                                <div
                                    style={{
                                        display: "flex",
                                        marginTop:
                                            index !== 0 ? "5px" : undefined,
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "12px",
                                            height: "12px",
                                            minWidth: "12px",
                                            minHeight: "12px",
                                            backgroundColor:
                                                colorList[
                                                    colorIndex %
                                                        colorList.length
                                                ],
                                            borderRadius: "50%",
                                        }}
                                    />
                                    <span
                                        className="regular-text"
                                        style={{
                                            marginLeft: "5px",
                                            color: "black",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {colorKey}
                                    </span>
                                </div>
                            ))}
                            {edgeColors.map(([colorKey, colorIndex], index) => (
                                <div
                                    style={{
                                        display: "flex",
                                        marginTop:
                                            index !== 0 ? "5px" : undefined,
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "12px",
                                            height: "12px",
                                            minWidth: "12px",
                                            minHeight: "12px",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "12px",
                                                height: "4px",
                                                minWidth: "12px",
                                                minHeight: "4px",
                                                backgroundColor:
                                                    colorList[
                                                        colorIndex %
                                                            colorList.length
                                                    ],
                                            }}
                                        />
                                    </div>
                                    <span
                                        className="regular-text"
                                        style={{
                                            marginLeft: "5px",
                                            color: "black",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {colorKey}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </>
        );
    }

    public render(): JSX.Element {
        if (this.props.mapRef != null) {
            return this.renderOverlays();
        }
        return (
            <div
                style={{ position: "relative", width: "100%", height: "100%" }}
            >
                <svg
                    ref={this.svgRef}
                    style={{ position: "absolute", left: 0, top: 0 }}
                    width={"100%"}
                    height={"100%"}
                >
                    <g ref={this.rootGRef}>
                        <g ref={this.edgeGRef} />
                        <g ref={this.edgeLabelGRef} />
                        <g ref={this.nodeGRef} />
                        <g ref={this.nodeLabelGRef} />
                    </g>
                </svg>
                {this.renderOverlays()}
            </div>
        );
    }

    private getEdgeId(_edge: GraphEdge, index: number): string {
        return `${this.props.networkId}-edge-${index}`;
    }

    private getEdgeIdSearchString(edge: GraphEdge, index: number): string {
        return `#${this.getEdgeId(edge, index)}`;
    }

    private getEdgeLabel(edge: GraphEdge): string {
        if (typeof edge.weight === "string") {
            return edge.weight;
        } else {
            return "";
        }
    }

    private getEdgeColor(edge: GraphEdge): string {
        if (edge.edgeColorKey != null) {
            return colorList[
                this.state.edgeColorKeyToIndex[edge.edgeColorKey] %
                    colorList.length
            ];
        } else {
            return this.props.edgeColor;
        }
    }

    private getNodeX(node: GraphNode): number {
        if (this.props.nodePlacement === "map") {
            return this.props.mapRef!.latLngToLayerPoint(
                Leaflet.latLng(node.y, node.x)
            ).x;
        } else {
            return node.x;
        }
    }

    private getNodeY(node: GraphNode): number {
        if (this.props.nodePlacement === "map") {
            return this.props.mapRef!.latLngToLayerPoint(
                Leaflet.latLng(node.y, node.x)
            ).y;
        } else {
            return node.y;
        }
    }

    private getNodeLabelX(node: GraphNode): number {
        let x: number;
        if (this.props.nodePlacement === "map") {
            x = this.props.mapRef!.latLngToLayerPoint(
                Leaflet.latLng(node.y, node.x)
            ).x;
        } else {
            x = node.x;
        }
        if (this.props.nodeLabelsDisplayPosition === "left") {
            return x - this.props.nodeRadius - 10;
        } else if (this.props.nodeLabelsDisplayPosition === "right") {
            return x + this.props.nodeRadius + 10;
        } else {
            return x;
        }
    }

    private getNodeLabelY(node: GraphNode): number {
        let y: number;
        if (this.props.nodePlacement === "map") {
            y = this.props.mapRef!.latLngToLayerPoint(
                Leaflet.latLng(node.y, node.x)
            ).y;
        } else {
            y = node.y;
        }

        if (this.props.nodeLabelsDisplayPosition === "top") {
            return y - this.props.nodeRadius - 10;
        } else if (this.props.nodeLabelsDisplayPosition === "bottom") {
            return y + this.props.nodeRadius + 10;
        } else {
            return y;
        }
    }

    private getNodeLabelText(node: GraphNode): string {
        return node.id.toString();
    }

    private getNodeLabelTextAnchor(): string {
        if (this.props.nodeLabelsDisplayPosition === "left") {
            return "end";
        } else if (this.props.nodeLabelsDisplayPosition === "right") {
            return "start";
        } else {
            return "middle";
        }
    }

    private getNodeColor(node: GraphNode): string {
        if (node.nodeColorKey != null) {
            return colorList[
                this.state.nodeColorKeyToIndex[node.nodeColorKey] %
                    colorList.length
            ];
        } else {
            return this.props.nodeColor;
        }
    }

    private updateEdges(
        updateSelection: d3.Selection<
            SVGPathElement,
            GraphEdge,
            SVGGElement,
            unknown
        >
    ): d3.Selection<SVGPathElement, GraphEdge, SVGGElement, unknown> {
        updateSelection.exit().remove();
        return updateSelection
            .enter()
            .append("path")
            .style("fill", "transparent")
            .attr("id", this.getEdgeId)
            .merge(updateSelection)
            .attr("d", this.drawEdge)
            .style("stroke", this.getEdgeColor)
            .style("stroke-width", this.calculateStrokeWidth);
    }

    private drawEdge(edge: GraphEdge): string {
        let path = d3.path();
        if (edge.source.id !== edge.target.id) {
            if (this.props.nodePlacement === "force") {
                path.moveTo(edge.source.x, edge.source.y);
                path.lineTo(edge.target.x, edge.target.y);
            } else if (this.props.nodePlacement === "arc") {
                path.arc(
                    (edge.source.x + edge.target.x) / 2,
                    0,
                    Math.abs(edge.source.x - edge.target.x) / 2,
                    0,
                    Math.PI,
                    true
                );
            } else if (this.props.nodePlacement === "edgebundling") {
                path.moveTo(edge.source.x, edge.source.y);
                path.quadraticCurveTo(0, 0, edge.target.x, edge.target.y);
            } else if (this.props.nodePlacement === "map") {
                let source = this.props.mapRef!.latLngToLayerPoint(
                    Leaflet.latLng(edge.source.y, edge.source.x)
                );
                let target = this.props.mapRef!.latLngToLayerPoint(
                    Leaflet.latLng(edge.target.y, edge.target.x)
                );
                path.moveTo(source.x, source.y);
                path.lineTo(target.x, target.y);
            }
        }
        return path.toString();
    }

    private updateEdgeLabels(
        updateSelection: d3.Selection<
            SVGTextElement,
            GraphEdge,
            SVGGElement,
            unknown
        >
    ): d3.Selection<SVGTextElement, GraphEdge, SVGGElement, unknown> {
        updateSelection.exit().remove();
        let enterSelection = updateSelection
            .enter()
            .append("text")
            .style("pointer-events", "none")
            .attr("font-size", 10)

        enterSelection
            .append("textPath")
            .attr("xlink:href", this.getEdgeIdSearchString)
            .style("text-anchor", "middle")
            .style("pointer-events", "none")
            .attr("startOffset", "50%")
        let mergeSelection = enterSelection.merge(updateSelection);

        mergeSelection
            .attr("fill", this.getEdgeColor)
            .select<SVGTextPathElement>("textPath")
            .text(this.getEdgeLabel)

        this.rotateEdgeLabels(mergeSelection);

        return mergeSelection;
    }

    private rotateEdgeLabels(selection: d3.Selection<SVGTextElement, GraphEdge, SVGGElement, unknown>): void {
        const rotator = (context: SVGTextElement) => {
            const bbox = context.getBBox();
            const rx = bbox.x + bbox.width / 2;
            const ry = bbox.y + bbox.height / 2;
            return `rotate(180 ${rx} ${ry})`;
        }

        if (this.props.nodePlacement === "arc") {
            selection.attr('transform', function () {
                return rotator(this)
            });

            return;
        }

        selection.attr('transform', function (d) {
            if (d.target.x < d.source.x){
                return rotator(this)
            } else {
                return 'rotate(0)';
            }
        });
    }

    private updateNodes(
        updateSelection: d3.Selection<
            SVGCircleElement,
            GraphNode,
            SVGGElement,
            unknown
        >,
        drag?: d3.DragBehavior<SVGCircleElement, GraphNode, GraphNode>
    ): d3.Selection<SVGCircleElement, GraphNode, SVGGElement, unknown> {
        updateSelection.exit().remove();
        return updateSelection
            .enter()
            .append("circle")
            .style("pointer-events", "auto")
            .on("click", this.onNodeClick)
            .on("mouseover", this.onNodeMouseOver)
            .on("mouseout", this.onNodeMouseOut)
            .call(drag ?? this.d3Objects!.drag)
            .merge(updateSelection)
            .attr("r", this.props.nodeRadius)
            .attr("cx", this.getNodeX)
            .attr("cy", this.getNodeY)
            .style("fill", this.getNodeColor);
    }

    private updateNodeLabels(
        updateSelection: d3.Selection<
            SVGTextElement,
            GraphNode,
            SVGGElement,
            unknown
        >
    ): d3.Selection<SVGTextElement, GraphNode, SVGGElement, unknown> {
        updateSelection.exit().remove();
        let enterSelection = updateSelection
            .enter()
            .append("text")
            .style("pointer-events", "none")
            .style("dominant-baseline", "middle")
            .attr("font-size", 10);

        let mergeSelection = enterSelection.merge(updateSelection);

        mergeSelection
            .attr("fill", this.getNodeColor)
            .style("text-anchor", this.getNodeLabelTextAnchor())
            .attr(
                "hidden",
                this.props.nodeLabelsDisplayMode ===
                    MapTooltipDisplayMode.always
                    ? null
                    : true
            )
            .attr("x", this.getNodeLabelX)
            .attr("y", this.getNodeLabelY)
            .text(this.getNodeLabelText);

        return mergeSelection;
    }

    /// Updates this.edges and this.nodes based on this.props.edgeList. Returns time values.
    private updateGraphFromProps(): {
        time: (number | string)[];
        nodeColorKeyToIndex: { [key: string | number]: number };
        edgeColorKeyToIndex: { [key: string | number]: number };
    } {
        let nodesMap: { [key: string]: GraphNode } = {};
        let nodeColorIndex = 0;
        let nodeColorKeyToIndex: { [key: string | number]: number } = {};
        let edgeColorIndex = 0;
        let edgeColorKeyToIndex: { [key: string | number]: number } = {};

        this.nodes = [];
        this.edges = [];

        let time = new Set<string | number>();

        for (let edge of this.props.edgeList) {
            if (nodesMap[edge.source] == null) {
                let node: GraphNode = {
                    id: edge.source,
                    x: 0,
                    y: 0,
                };
                if (
                    edge.nodeColorKey != null &&
                    !this.props.attachNodeColorToTarget
                ) {
                    node.nodeColorKey = edge.nodeColorKey;
                    if (!(edge.nodeColorKey in nodeColorKeyToIndex)) {
                        nodeColorKeyToIndex[edge.nodeColorKey] = nodeColorIndex;
                        nodeColorIndex += 1;
                    }
                }
                nodesMap[edge.source] = node;
                this.nodes.push(node);
            }
            if (nodesMap[edge.target] == null) {
                let node: GraphNode = {
                    id: edge.target,
                    x: 0,
                    y: 0,
                };
                nodesMap[edge.target] = node;
                this.nodes.push(node);
            }
            if (edge.nodeColorKey != null) {
                let node: GraphNode;
                if (this.props.attachNodeColorToTarget) {
                    node = nodesMap[edge.target];
                } else {
                    node = nodesMap[edge.source];
                }
                node.nodeColorKey = edge.nodeColorKey;
                if (!(edge.nodeColorKey in nodeColorKeyToIndex)) {
                    nodeColorKeyToIndex[edge.nodeColorKey] = nodeColorIndex;
                    nodeColorIndex += 1;
                }
            }
            if (this.props.nodePlacement === "map") {
                let node: GraphNode = nodesMap[edge.source];
                node.x = edge.x ?? 0;
                node.y = edge.y ?? 0;
            }
            this.edges.push({
                source: nodesMap[edge.source],
                target: nodesMap[edge.target],
                weight: edge.weight ?? 1,
                timeValue: edge.time,
                edgeColorKey: edge.edgeColorKey,
            });
            if (edge.edgeColorKey != null) {
                if (!(edge.edgeColorKey in edgeColorKeyToIndex)) {
                    edgeColorKeyToIndex[edge.edgeColorKey] = edgeColorIndex;
                    edgeColorIndex += 1;
                }
            }

            if (edge.time != null) {
                time.add(edge.time);
            }
        }

        // Rescale weights to range [1, 10] linearly
        if (this.edges.length !== 0) {
            let weightExtent = d3.extent(this.edges, (d) =>
                typeof d.weight === "number" ? d.weight : undefined
            );
            if (
                weightExtent[0] != null &&
                weightExtent[0] !== weightExtent[1]
            ) {
                for (let edge of this.edges) {
                    if (typeof edge.weight === "number") {
                        edge.weight =
                            ((edge.weight - weightExtent[0]) /
                                (weightExtent[1] - weightExtent[0])) *
                                9 +
                            1;
                    }
                }
            } else {
                for (let edge of this.edges) {
                    edge.weight = edge.weight ?? 1;
                }
            }
        }

        // Initial positions of nodes
        if (this.props.nodePlacement === "force") {
            // Some forces don't work correctly if all nodes are in the same
            // position, so we need to make their inital positions different
            // (overlaps are fine)
            if (this.nodes.length !== 0) {
                const step = (2 * Math.PI) / this.nodes.length;
                for (let i = 0; i < this.nodes.length; ++i) {
                    this.nodes[i].x = 20 * Math.cos(i * step);
                    this.nodes[i].y = 20 * Math.sin(i * step);
                }
            }
        } else if (this.props.nodePlacement === "arc") {
            if (this.nodes.length !== 0) {
                const margin = 15;
                const step = Math.max(this.props.nodeRadius, 20) * 2 + margin;
                for (let i = 0; i < this.nodes.length; ++i) {
                    this.nodes[i].x = i * step;
                    this.nodes[i].y = 0;
                }
            }
        } else if (this.props.nodePlacement === "edgebundling") {
            if (this.nodes.length !== 0) {
                const radius = Math.max(
                    (this.nodes.length * (this.props.nodeRadius * 2 + 10)) /
                        Math.PI /
                        2,
                    80
                );
                const step = (2 * Math.PI) / this.nodes.length;
                for (let i = 0; i < this.nodes.length; ++i) {
                    this.nodes[i].x = radius * Math.cos(i * step);
                    this.nodes[i].y = radius * Math.sin(i * step);
                }
            }
        }

        return {
            time: Array.from(time).sort((value1, value2) =>
                typeof value1 === "number" && typeof value2 === "number"
                    ? value1 - value2
                    : String(value1).localeCompare(String(value2))
            ),
            nodeColorKeyToIndex: nodeColorKeyToIndex,
            edgeColorKeyToIndex: edgeColorKeyToIndex,
        };
    }

    private zoomToFit(): void {
        if (this.d3Objects == null || this.props.nodePlacement === "map") {
            return;
        }

        let xExtent = d3.extent(this.nodes, (d) => d.x);
        let yExtent = d3.extent(this.nodes, (d) => d.y);
        // It is enough to check just one coorditnate. The other one won't be
        // null as well in this case.
        if (
            xExtent[0] != null &&
            yExtent[0] != null &&
            (Math.abs(xExtent[1] - xExtent[0]) > 1e-8 ||
                Math.abs(yExtent[1] - yExtent[0]) > 1e-8)
        ) {
            let width = this.svgRef.current!.clientWidth;
            let height = this.svgRef.current!.clientHeight;
            let scale: number;
            if (Math.abs(xExtent[1] - xExtent[0]) <= 1e-8) {
                scale = (0.5 * height) / (yExtent[1] - yExtent[0]);
            } else if (Math.abs(yExtent[1] - yExtent[0]) <= 1e-8) {
                scale = (0.5 * width) / (xExtent[1] - xExtent[0]);
            } else {
                scale =
                    0.5 *
                    Math.min(
                        width / (xExtent[1] - xExtent[0]),
                        height / (yExtent[1] - yExtent[0])
                    );
            }

            let transform = d3.zoomIdentity
                .translate(width / 2, height / 2)
                .scale(scale)
                .translate(
                    -(xExtent[0] + xExtent[1]) / 2,
                    -(yExtent[0] + yExtent[1]) / 2
                );
            this.d3Objects.svg.call(this.d3Objects.zoom.transform, transform);
            if (!this.props.dragActive) {
                // zoomToFit still needs to work even if drag is disabled
                this.d3Objects.rootG.attr("transform", transform.toString());
            }
        }
    }

    private calculateStrokeWidth(edge: GraphEdge): number {
        if (typeof edge.weight === "number") {
            return edge.weight * this.props.baseEdgeThickness;
        } else {
            return this.props.baseEdgeThickness;
        }
    }

    private onNodeClick(event: MouseEvent, node: GraphNode): void {
        // Event will be prevented if the user is panning, not clicking
        if (event.defaultPrevented) {
            return;
        }
        this.props.onNodeClick?.(node.id);

        if (
            this.props.nodeLabelsDisplayMode === MapTooltipDisplayMode.onClick
        ) {
            this.d3Objects?.nodeLabels.attr("hidden", (n, i, elem) =>
                n.id === node.id
                    ? elem[i].getAttribute("hidden")
                        ? null
                        : true
                    : true
            );
        }
    }

    private onNodeMouseOver(_event: MouseEvent, node: GraphNode): void {
        if (this.d3Objects == null) {
            return;
        }

        let highlightedIds = new Set([node.id]);

        // Highlight edges
        this.d3Objects.edges.style("stroke-opacity", (edge) => {
            if (edge.source.id === node.id) {
                highlightedIds.add(edge.target.id);
                return 1;
            } else if (edge.target.id === node.id) {
                highlightedIds.add(edge.source.id);
                return 1;
            } else {
                return 0.2;
            }
        });

        // Highlight labels
        this.d3Objects.edgeLabels.style("fill-opacity", (edge) => {
            if (edge.source.id === node.id || edge.target.id === node.id) {
                return 1;
            } else {
                return 0.2;
            }
        });

        // Highlight nodes
        this.d3Objects.nodes.style("opacity", (node) =>
            highlightedIds.has(node.id) ? 1 : 0.2
        );

        if (
            this.props.nodeLabelsDisplayMode === MapTooltipDisplayMode.onHover
        ) {
            this.d3Objects.nodeLabels.attr("hidden", (n) =>
                n.id === node.id ? null : true
            );
        }
    }

    private onNodeMouseOut(): void {
        if (this.d3Objects == null) {
            return;
        }

        this.d3Objects.edges.style("stroke-opacity", 1);
        this.d3Objects.edgeLabels.style("fill-opacity", 1);
        this.d3Objects.nodes.style("opacity", 1);

        if (
            this.props.nodeLabelsDisplayMode === MapTooltipDisplayMode.onHover
        ) {
            this.d3Objects.nodeLabels.attr("hidden", true);
        }
    }

    private onNodeDragStart(
        e: d3.D3DragEvent<SVGCircleElement, GraphNode, GraphNode>
    ): void {
        if (this.props.dragActive && this.props.nodePlacement === "force") {
            if (!e.active) this.d3Objects!.force.alphaTarget(0.3).restart();
            e.subject.fx = e.subject.x;
            e.subject.fy = e.subject.y;
        }
    }

    private onNodeDrag(
        e: d3.D3DragEvent<SVGCircleElement, GraphNode, GraphNode>
    ): void {
        if (this.props.dragActive && this.props.nodePlacement === "force") {
            e.subject.fx = e.x;
            e.subject.fy = e.y;
        }
    }

    private onNodeDragEnd(
        e: d3.D3DragEvent<SVGCircleElement, GraphNode, GraphNode>
    ): void {
        if (this.props.dragActive && this.props.nodePlacement === "force") {
            if (!e.active) this.d3Objects!.force.alphaTarget(0);
            e.subject.fx = undefined;
            e.subject.fy = undefined;
        }
    }

    private onAnimationSliderChange(index: number): void {
        if (this.d3Objects == null || this.state.time == null) {
            return;
        }

        let value = this.state.time![index];

        let nodeIndices = new Set<string | number>();
        let condition: (edge: GraphEdge) => true | null = (edge) =>
            edge.timeValue == null || edge.timeValue <= value ? null : true;
        let wrappedCondition: (edge: GraphEdge) => true | null = (edge) => {
            let retVal = condition(edge);
            if (!retVal) {
                nodeIndices.add(edge.source.id);
                nodeIndices.add(edge.target.id);
            }
            return retVal;
        };

        this.d3Objects.edges.attr("hidden", wrappedCondition);
        this.d3Objects.edgeLabels.attr("hidden", condition);
        this.d3Objects.nodes.attr("hidden", (node) =>
            nodeIndices.has(node.id) ? null : true
        );
        if (this.props.nodeLabelsDisplayMode === MapTooltipDisplayMode.always) {
            this.d3Objects.nodeLabels.attr("hidden", (node) =>
                nodeIndices.has(node.id) ? null : true
            );
        }
    }

    private initializeTimeAnimation(): void {
        if (this.d3Objects == null) {
            return;
        }

        let nodeIndices = new Set<string | number>();
        let condition: (edge: GraphEdge) => true | null = (edge) =>
            edge.timeValue == null || edge.timeValue <= this.state.time[0]
                ? null
                : true;

        let wrappedCondition: (edge: GraphEdge) => true | null = (edge) => {
            let retVal = condition(edge);
            if (!retVal) {
                nodeIndices.add(edge.source.id);
                nodeIndices.add(edge.target.id);
            }
            return retVal;
        };

        this.d3Objects.edges.attr("hidden", wrappedCondition);
        this.d3Objects.edgeLabels.attr("hidden", condition);
        this.d3Objects.nodes.attr("hidden", (node) =>
            nodeIndices.has(node.id) ? null : true
        );
        if (this.props.nodeLabelsDisplayMode === MapTooltipDisplayMode.always) {
            this.d3Objects.nodeLabels.attr("hidden", (node) =>
                nodeIndices.has(node.id) ? null : true
            );
        }
    }

    // Update positions on the map
    private onMapViewReset(): void {
        if (this.d3Objects != null) {
            this.d3Objects.edges.attr("d", this.drawEdge);
            this.d3Objects.nodes
                .attr("cx", this.getNodeX)
                .attr("cy", this.getNodeY);
            this.d3Objects.nodeLabels
                .attr("x", this.getNodeLabelX)
                .attr("y", this.getNodeLabelY);
        }
    }
}

export default Network;
