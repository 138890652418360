import React from "react";
import cx from "classnames";
import { Accordion, Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { useDrop } from "react-dnd";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import { NetworkFinding } from "common/Finding";
import commonStyles from "../DataSection.module.css";
import ribbonStyles from "../../ChartsRibbon.module.css";
import DataConfig from "../MapSection/DataConfig";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import styles from "./NetworkOverMapSection.module.css";
import EditInput from "../EditInput";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import Variables, { VariableOption } from "common/Variables";
import {
    MapTooltipDisplayMode,
    MapTooltipDisplayModeOption,
} from "common/Canvas";

const options: Array<{
    label: string;
    value: MapTooltipDisplayMode | undefined;
}> = [
    { label: "click", value: MapTooltipDisplayMode.onClick },
    { label: "hover", value: MapTooltipDisplayMode.onHover },
    { label: "always", value: MapTooltipDisplayMode.always },
    { label: "never", value: undefined },
];

interface Props {
    canvasTreeStore: CanvasTreeStore;
    columnDragActive: boolean;
    finding: NetworkFinding;
    onChange: (finding: NetworkFinding, updateData?: boolean) => void;
    currentModuleId?: number;
}

enum NetworkMenuOptions {
    None = 0,
    Popup = 1,
    Time = 2,
}

const nodeLabelPositionOptions: {
    label: string;
    value: "top" | "bottom" | "left" | "right";
}[] = [
    {
        label: "top",
        value: "top",
    },
    {
        label: "bottom",
        value: "bottom",
    },
    {
        label: "left",
        value: "left",
    },
    {
        label: "right",
        value: "right",
    },
];

export default function NetworkOverMapSection(props: Props) {
    const ref1 = React.useRef(null);
    const ref2 = React.useRef(null);
    const refLat = React.useRef(null);
    const refLng = React.useRef(null);
    const refWeight = React.useRef(null);
    const [collected1, drop1] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            let newFinding = {
                ...props.finding,
                config: {
                    ...props.finding.config,
                    edgeSourceVariable: otherItem.content.variableName,
                    edgeSourceVariableIndex: otherItem.content.variableIndex,
                },
            };
            props.onChange(newFinding, true);
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    const [collected2, drop2] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            let newFinding = {
                ...props.finding,
                config: {
                    ...props.finding.config,
                    edgeTargetVariable: otherItem.content.variableName,
                    edgeTargetVariableIndex: otherItem.content.variableIndex,
                },
            };
            props.onChange(newFinding, true);
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    const [collectedLat, dropLat] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            let newFinding = {
                ...props.finding,
                config: {
                    ...props.finding.config,
                    yVariable: otherItem.content.variableName,
                    yVariableIndex: otherItem.content.variableIndex,
                },
            };
            props.onChange(newFinding, true);
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    const [collectedLng, dropLng] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            let newFinding = {
                ...props.finding,
                config: {
                    ...props.finding.config,
                    xVariable: otherItem.content.variableName,
                    xVariableIndex: otherItem.content.variableIndex,
                },
            };
            props.onChange(newFinding, true);
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    const [collectedWeight, dropWeight] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            let newFinding = {
                ...props.finding,
                config: {
                    ...props.finding.config,
                    edgeWeightVariable: otherItem.content.variableName,
                    edgeWeightVariableIndex: otherItem.content.variableIndex,
                },
            };
            props.onChange(newFinding, true);
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    drop1(ref1);
    drop2(ref2);
    dropLat(refLat);
    dropLng(refLng);
    dropWeight(refWeight);

    let [menuOptionSelected, setMenuOptionSelected] = React.useState(
        NetworkMenuOptions.None
    );

    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    let variableOptions: VariableOption[] = [];
    if (props.finding.config.dataScope != null) {
        variableOptions = Variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions;
    }

    let data = props.finding.content.data;
    let edgeSourceVariable =
        props.finding.config.edgeSourceVariable ?? "Node 1";
    let edgeTargetVariable =
        props.finding.config.edgeTargetVariable ?? "Node 2";
    let yVariable = props.finding.config.yVariable ?? "Latitude";
    let xVariable = props.finding.config.xVariable ?? "Longitude";
    let edgeWeightVariable =
        props.finding.config.edgeWeightVariable ?? "Weight";
    let datasetIsConnected = props.finding.config.dataScope != null;
    let maxRowCount = 10;
    let restCount = data.length - maxRowCount;

    let metrics: ({
        name: string;
        variableIndex: number;
        originalName?: string;
    } | null)[] = [null];
    metrics = props.finding.config.metrics ?? [null];
    if (metrics.length === 0) {
        metrics = [null];
    }

    return (
        <div className={styles.root}>
            <div
                style={{
                    position: "relative",
                    height:
                        48 +
                        24 *
                            (Math.min(data.length, maxRowCount) +
                                Number(datasetIsConnected && restCount > 0)),
                }}
            >
                <div className={styles.dataContainer}>
                    <div className={commonStyles.header}></div>
                    <div className={commonStyles.header}>
                        {edgeSourceVariable}
                    </div>
                    <div className={commonStyles.header}>
                        {edgeTargetVariable}
                    </div>
                    <div className={commonStyles.header}>{yVariable}</div>
                    <div className={commonStyles.header}>{xVariable}</div>
                    <div className={commonStyles.header}>
                        {edgeWeightVariable}
                    </div>
                    {(datasetIsConnected
                        ? data.slice(0, maxRowCount)
                        : data
                    ).map((_, index, array) => (
                        <React.Fragment key={index}>
                            <EditInput
                                showDeleteButton={
                                    props.finding.config.dataScope == null
                                }
                                disabled={true}
                                value={`Edge ${index + 1}`}
                                onDelete={() => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: Array.from(
                                                props.finding!.content.data
                                            ),
                                        },
                                    };
                                    finding.content.data.splice(index, 1);
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[index].source}
                                onChange={(value) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: Array.from(
                                                props.finding!.content.data
                                            ),
                                        },
                                    };
                                    finding.content.data[index] = {
                                        ...finding.content.data[index],
                                        source: Number(value),
                                    };
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[index].target}
                                onChange={(value) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: Array.from(
                                                props.finding!.content.data
                                            ),
                                        },
                                    };
                                    finding.content.data[index] = {
                                        ...finding.content.data[index],
                                        target: Number(value),
                                    };
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[index].y ?? 0}
                                onChange={(value) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: Array.from(
                                                props.finding!.content.data
                                            ),
                                        },
                                    };
                                    finding.content.data[index] = {
                                        ...finding.content.data[index],
                                        y: Number(value),
                                    };
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[index].x ?? 0}
                                onChange={(value) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: Array.from(
                                                props.finding!.content.data
                                            ),
                                        },
                                    };
                                    finding.content.data[index] = {
                                        ...finding.content.data[index],
                                        x: Number(value),
                                    };
                                    props.onChange(finding);
                                }}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={
                                    props.finding!.config.dataScope != null
                                }
                                value={data[index].weight}
                                onChange={(value) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        content: {
                                            ...props.finding!.content,
                                            data: Array.from(
                                                props.finding!.content.data
                                            ),
                                        },
                                    };
                                    finding.content.data[index] = {
                                        ...finding.content.data[index],
                                        weight: Number(value),
                                    };
                                    props.onChange(finding);
                                }}
                            />
                        </React.Fragment>
                    ))}
                    {datasetIsConnected && restCount > 0 && (
                        <React.Fragment>
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={`+${restCount} values`}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                            <EditInput
                                showDeleteButton={false}
                                disabled={true}
                                value={"..."}
                            />
                        </React.Fragment>
                    )}
                </div>
                {!props.finding.config.isAdditional && (
                    <div
                        className={styles.dataContainerOverlay}
                        style={{
                            display: props.columnDragActive ? "grid" : "none",
                            height:
                                48 +
                                24 *
                                    (Math.min(data.length, maxRowCount) +
                                        Number(
                                            datasetIsConnected && restCount > 0
                                        )),
                        }}
                    >
                        <div />
                        <div
                            ref={ref1}
                            style={{
                                opacity: 0.7,
                                width: "100%",
                                height: "100%",
                                borderStyle: "dashed",
                                borderWidth: 1,
                                borderColor: collected1.hover
                                    ? "#36B743"
                                    : "#8DB8E3",
                                display: "flex",
                                background: collected1.hover
                                    ? "#F4FBF5"
                                    : "#EBF2F9",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                className="no-selection"
                                style={{
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    color: "#333333",
                                }}
                            >
                                Drop
                            </span>
                        </div>
                        <div
                            ref={ref2}
                            style={{
                                opacity: 0.7,
                                width: "100%",
                                height: "100%",
                                borderStyle: "dashed",
                                borderWidth: 1,
                                borderColor: collected2.hover
                                    ? "#36B743"
                                    : "#8DB8E3",
                                display: "flex",
                                background: collected2.hover
                                    ? "#F4FBF5"
                                    : "#EBF2F9",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                className="no-selection"
                                style={{
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    color: "#333333",
                                }}
                            >
                                Drop
                            </span>
                        </div>
                        <div
                            ref={refLat}
                            style={{
                                opacity: 0.7,
                                width: "100%",
                                height: "100%",
                                borderStyle: "dashed",
                                borderWidth: 1,
                                borderColor: collectedLat.hover
                                    ? "#36B743"
                                    : "#8DB8E3",
                                display: "flex",
                                background: collectedLat.hover
                                    ? "#F4FBF5"
                                    : "#EBF2F9",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                className="no-selection"
                                style={{
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    color: "#333333",
                                }}
                            >
                                Drop
                            </span>
                        </div>
                        <div
                            ref={refLng}
                            style={{
                                opacity: 0.7,
                                width: "100%",
                                height: "100%",
                                borderStyle: "dashed",
                                borderWidth: 1,
                                borderColor: collectedLng.hover
                                    ? "#36B743"
                                    : "#8DB8E3",
                                display: "flex",
                                background: collectedLng.hover
                                    ? "#F4FBF5"
                                    : "#EBF2F9",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                className="no-selection"
                                style={{
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    color: "#333333",
                                }}
                            >
                                Drop
                            </span>
                        </div>
                        <div
                            ref={refWeight}
                            style={{
                                opacity: 0.7,
                                width: "100%",
                                height: "100%",
                                borderStyle: "dashed",
                                borderWidth: 1,
                                borderColor: collectedWeight.hover
                                    ? "#36B743"
                                    : "#8DB8E3",
                                display: "flex",
                                background: collectedWeight.hover
                                    ? "#F4FBF5"
                                    : "#EBF2F9",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <span
                                className="no-selection"
                                style={{
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    color: "#333333",
                                }}
                            >
                                Drop
                            </span>
                        </div>
                    </div>
                )}
            </div>
            {props.finding.config.dataScope == null && (
                <Button
                    style={{ marginTop: 10 }}
                    className={cx(
                        "btn btn-sm btn-primary my-primary",
                        commonStyles.addItemButton
                    )}
                    onClick={() => {
                        let finding: NetworkFinding = {
                            ...props.finding!,
                            content: {
                                ...props.finding!.content,
                                data: Array.from(props.finding!.content.data),
                            },
                        };
                        finding.content.data.push({
                            source: 1,
                            target: 2,
                            weight: 1,
                        });
                        props.onChange(finding);
                    }}
                >
                    {"\uFF0B Add Item"}
                </Button>
            )}
            <DataConfig
                currentModuleId={props.currentModuleId}
                finding={props.finding}
                onChange={props.onChange}
                canvasTreeStore={props.canvasTreeStore}
                style={{ marginTop: 20 }}
                resetFields={(newFinding) => {
                    newFinding.config.edgeSourceVariable = null;
                    newFinding.config.edgeTargetVariable = null;
                    newFinding.config.edgeWeightVariable = null;
                    newFinding.config.timeVariable = null;
                    newFinding.config.edgeSourceVariableIndex = null;
                    newFinding.config.edgeTargetVariableIndex = null;
                    newFinding.config.edgeWeightVariableIndex = null;
                    newFinding.config.timeVariableIndex = null;
                    newFinding.config.centerVariableIndex = null;
                    newFinding.config.centerVariable = null;
                    newFinding.config.centerVariableValue = null;
                    newFinding.config.edgeColorVariableIndex = null;
                    newFinding.config.nodeColorVariableIndex = null;
                    newFinding.config.xVariable = null;
                    newFinding.config.xVariableIndex = null;
                    newFinding.config.yVariable = null;
                    newFinding.config.yVariableIndex = null;
                    newFinding.config.timeAnimationSliderPosition = null;

                    newFinding.config.mapConfig = {
                        ...newFinding.config.mapConfig,
                        tooltipVariables: undefined,
                        coordinates: null,
                        location: null,
                        selectOrderBy: null,
                        heatMap: null,
                        conditions: null,
                        center: null,
                        zoom: null,

                        markerColorVariableIndex: null,
                        timeVariable: null,
                        timeVariableIndex: null,
                        centerVariableIndex: null,
                        centerVariableValue: null,
                        centerVariable: null,
                        choroplethCountryVariable: null,
                        choroplethCountryVariableIndex: null,
                        choroplethMetricVariable: null,
                        choroplethMetricVariableIndex: null,
                    };

                    if (newFinding.config.mapConfig.colorOptions != null) {
                        newFinding.config.mapConfig.colorOptions.lockMap = false;
                        newFinding.config.mapConfig.colorOptions.minZoomLevel = null;
                    }
                }}
            />
            <div style={{ marginTop: 10 }}>
                <Accordion
                    activeKey={String(menuOptionSelected)}
                    onSelect={(event: any) => {
                        setMenuOptionSelected(Number(event));
                    }}
                >
                    <Accordion.Toggle
                        eventKey={String(NetworkMenuOptions.Time)}
                        className={ribbonStyles.editMenuAdvancedOption}
                    >
                        Network Over Map
                        <ChevronIcon
                            className={cx(
                                ribbonStyles.editMenuAdvancedOptionIcon,
                                {
                                    [ribbonStyles.chevronOpen]:
                                        menuOptionSelected ===
                                        NetworkMenuOptions.Time,
                                }
                            )}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(NetworkMenuOptions.Time)}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 10,
                            }}
                        >
                            <div className={styles.configFields}>
                                <div className={styles.configField}>
                                    <span className={styles.configLabel}>
                                        Node 1
                                    </span>
                                    <div
                                        className={styles.configSelect}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        onMouseDown={(evt: any) => {
                                            evt.stopPropagation();
                                        }}
                                    >
                                        <Select
                                            placeholder=""
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: 4,
                                                }),
                                                container: (base) => ({
                                                    ...base,
                                                    flexGrow: 1,
                                                }),
                                                indicatorSeparator: (
                                                    provided
                                                ) => ({
                                                    ...provided,
                                                    display: "none",
                                                }),
                                            }}
                                            options={variableOptions}
                                            onChange={(newValue) => {
                                                let newFinding = {
                                                    ...props.finding,
                                                    config: {
                                                        ...props.finding.config,
                                                        edgeSourceVariable: (newValue as VariableOption)
                                                            ?.label,
                                                        edgeSourceVariableIndex: (newValue as VariableOption)
                                                            ?.value,
                                                    },
                                                };
                                                props.onChange(
                                                    newFinding,
                                                    true
                                                );
                                            }}
                                            value={variableOptions.find(
                                                (item) =>
                                                    item.value ===
                                                    props.finding.config
                                                        .edgeSourceVariableIndex
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={styles.configField}>
                                    <span className={styles.configLabel}>
                                        Node 2
                                    </span>
                                    <div
                                        className={styles.configSelect}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        onMouseDown={(evt: any) => {
                                            evt.stopPropagation();
                                        }}
                                    >
                                        <Select
                                            placeholder=""
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: 4,
                                                }),
                                                container: (base) => ({
                                                    ...base,
                                                    flexGrow: 1,
                                                }),
                                                indicatorSeparator: (
                                                    provided
                                                ) => ({
                                                    ...provided,
                                                    display: "none",
                                                }),
                                            }}
                                            options={variableOptions}
                                            onChange={(newValue) => {
                                                let newFinding = {
                                                    ...props.finding,
                                                    config: {
                                                        ...props.finding.config,
                                                        edgeTargetVariable: (newValue as VariableOption)
                                                            ?.label,
                                                        edgeTargetVariableIndex: (newValue as VariableOption)
                                                            ?.value,
                                                    },
                                                };
                                                props.onChange(
                                                    newFinding,
                                                    true
                                                );
                                            }}
                                            value={variableOptions.find(
                                                (item) =>
                                                    item.value ===
                                                    props.finding.config
                                                        .edgeTargetVariableIndex
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={styles.configField}>
                                    <span className={styles.configLabel}>
                                        Latitude
                                    </span>
                                    <div
                                        className={styles.configSelect}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        onMouseDown={(evt: any) => {
                                            evt.stopPropagation();
                                        }}
                                    >
                                        <Select
                                            placeholder=""
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: 4,
                                                }),
                                                container: (base) => ({
                                                    ...base,
                                                    flexGrow: 1,
                                                }),
                                                indicatorSeparator: (
                                                    provided
                                                ) => ({
                                                    ...provided,
                                                    display: "none",
                                                }),
                                            }}
                                            options={variableOptions}
                                            onChange={(newValue) => {
                                                let newFinding = {
                                                    ...props.finding,
                                                    config: {
                                                        ...props.finding.config,
                                                        yVariable: (newValue as VariableOption)
                                                            ?.label,
                                                        yVariableIndex: (newValue as VariableOption)
                                                            ?.value,
                                                    },
                                                };
                                                props.onChange(
                                                    newFinding,
                                                    true
                                                );
                                            }}
                                            value={variableOptions.find(
                                                (item) =>
                                                    item.value ===
                                                    props.finding.config
                                                        .yVariableIndex
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={styles.configField}>
                                    <span className={styles.configLabel}>
                                        Longitude
                                    </span>
                                    <div
                                        className={styles.configSelect}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        onMouseDown={(evt: any) => {
                                            evt.stopPropagation();
                                        }}
                                    >
                                        <Select
                                            placeholder=""
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: 4,
                                                }),
                                                container: (base) => ({
                                                    ...base,
                                                    flexGrow: 1,
                                                }),
                                                indicatorSeparator: (
                                                    provided
                                                ) => ({
                                                    ...provided,
                                                    display: "none",
                                                }),
                                            }}
                                            options={variableOptions}
                                            onChange={(newValue) => {
                                                let newFinding = {
                                                    ...props.finding,
                                                    config: {
                                                        ...props.finding.config,
                                                        xVariable: (newValue as VariableOption)
                                                            ?.label,
                                                        xVariableIndex: (newValue as VariableOption)
                                                            ?.value,
                                                    },
                                                };
                                                props.onChange(
                                                    newFinding,
                                                    true
                                                );
                                            }}
                                            value={variableOptions.find(
                                                (item) =>
                                                    item.value ===
                                                    props.finding.config
                                                        .xVariableIndex
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={styles.configField}>
                                    <span className={styles.configLabel}>
                                        Edge Weight/Label
                                    </span>
                                    <div
                                        className={styles.configSelect}
                                        onKeyDown={(evt) => {
                                            evt.stopPropagation();
                                        }}
                                        onMouseDown={(evt: any) => {
                                            evt.stopPropagation();
                                        }}
                                    >
                                        <Select
                                            isClearable
                                            placeholder=""
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: 4,
                                                }),
                                                container: (base) => ({
                                                    ...base,
                                                    flexGrow: 1,
                                                }),
                                                indicatorSeparator: (
                                                    provided
                                                ) => ({
                                                    ...provided,
                                                    display: "none",
                                                }),
                                            }}
                                            options={variableOptions}
                                            onChange={(newValue) => {
                                                let newFinding = {
                                                    ...props.finding,
                                                    config: {
                                                        ...props.finding.config,
                                                        // We need specifically null, not undefined to clear the weight
                                                        edgeWeightVariable:
                                                            (newValue as VariableOption)
                                                                ?.label ?? null,
                                                        edgeWeightVariableIndex:
                                                            (newValue as VariableOption)
                                                                ?.value ?? null,
                                                    },
                                                };
                                                props.onChange(
                                                    newFinding,
                                                    true
                                                );
                                            }}
                                            value={variableOptions.find(
                                                (item) =>
                                                    item.value ===
                                                    props.finding.config
                                                        .edgeWeightVariableIndex
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Accordion.Collapse>
                </Accordion>
            </div>
            <div style={{ marginTop: 10 }}>
                <Accordion
                    activeKey={String(menuOptionSelected)}
                    onSelect={(event: any) => {
                        setMenuOptionSelected(Number(event));
                    }}
                >
                    <Accordion.Toggle
                        eventKey={String(NetworkMenuOptions.Popup)}
                        className={ribbonStyles.editMenuAdvancedOption}
                    >
                        Node Labels
                        <ChevronIcon
                            className={cx(
                                ribbonStyles.editMenuAdvancedOptionIcon,
                                {
                                    [ribbonStyles.chevronOpen]:
                                        menuOptionSelected ===
                                        NetworkMenuOptions.Popup,
                                }
                            )}
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={String(NetworkMenuOptions.Popup)}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: 10,
                            }}
                        >
                            <span className={styles.title}>Show label on</span>
                            <Select
                                isClearable
                                options={options}
                                value={options.find(
                                    (option) =>
                                        option.value ===
                                        props.finding.config
                                            .nodeLabelsDisplayMode
                                )}
                                isSearchable={false}
                                onChange={(newValue) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        config: {
                                            ...props.finding!.config,
                                            nodeLabelsDisplayMode: (newValue as MapTooltipDisplayModeOption)
                                                .value,
                                        },
                                    };
                                    props.onChange(finding);
                                }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: 60,
                                        marginLeft: 5,
                                        marginRight: 5,
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: 25,
                                    }),
                                    indicatorsContainer: (_provided) => ({
                                        display: "none",
                                    }),
                                }}
                            />
                            <span className={styles.title}>on</span>
                            <Select
                                isClearable
                                options={nodeLabelPositionOptions}
                                value={nodeLabelPositionOptions.find(
                                    (option) =>
                                        option.value ===
                                            props.finding.config
                                                .nodeLabelsDisplayPosition ??
                                        nodeLabelPositionOptions[0]
                                )}
                                isSearchable={false}
                                onChange={(newValue) => {
                                    let finding: NetworkFinding = {
                                        ...props.finding!,
                                        config: {
                                            ...props.finding!.config,
                                            nodeLabelsDisplayPosition: (newValue as typeof nodeLabelPositionOptions[0])
                                                .value,
                                        },
                                    };
                                    props.onChange(finding);
                                }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: 60,
                                        marginLeft: 5,
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: 25,
                                    }),
                                    indicatorsContainer: (_provided) => ({
                                        display: "none",
                                    }),
                                }}
                            />
                        </div>
                    </Accordion.Collapse>
                </Accordion>
            </div>
            {!props.finding.config.isAdditional && (
                <div style={{ marginTop: 10 }}>
                    <Accordion
                        activeKey={String(menuOptionSelected)}
                        onSelect={(event: any) => {
                            setMenuOptionSelected(Number(event));
                        }}
                    >
                        <Accordion.Toggle
                            eventKey={String(NetworkMenuOptions.Time)}
                            className={ribbonStyles.editMenuAdvancedOption}
                        >
                            Animation
                            <ChevronIcon
                                className={cx(
                                    ribbonStyles.editMenuAdvancedOptionIcon,
                                    {
                                        [ribbonStyles.chevronOpen]:
                                            menuOptionSelected ===
                                            NetworkMenuOptions.Time,
                                    }
                                )}
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse
                            eventKey={String(NetworkMenuOptions.Time)}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Select
                                    isClearable
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder="Select variable"
                                    styles={{
                                        ...selectStyles,
                                        container: (base) => ({
                                            ...base,
                                            width: "100%",
                                            height: "38px",
                                            marginTop: "8px",
                                        }),
                                    }}
                                    options={variableOptions}
                                    onChange={(newValue) => {
                                        let finding: NetworkFinding = {
                                            ...props.finding!,
                                            config: {
                                                ...props.finding!.config,
                                            },
                                        };
                                        if (newValue == null) {
                                            finding.config.timeVariable = null;
                                            finding.config.timeVariableIndex = null;
                                        } else {
                                            finding.config.timeVariable = (newValue as VariableOption).label;
                                            finding.config.timeVariableIndex = (newValue as VariableOption).value;
                                        }
                                        props.onChange(finding, true);
                                    }}
                                    value={
                                        props.finding.config
                                            .timeVariableIndex != null
                                            ? {
                                                  label:
                                                      props.finding.config
                                                          .timeVariable,
                                                  value:
                                                      props.finding.config
                                                          .timeVariableIndex,
                                              }
                                            : null
                                    }
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                </div>
            )}
        </div>
    );
}
