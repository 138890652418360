import React, { useEffect } from "react";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import cx from "classnames";
import ReactTooltip from "react-tooltip";

import styles from "./DropdownCanvasButton.module.css";

interface Props {
	id: string;
	children: React.ReactNode;
	className?: string;
	opened?: boolean;
	active?: boolean;
	style?: React.CSSProperties;
	onClick?: () => void;
	title?: string;
}

function DropdownCanvasButton({
	id,
	children,
	className,
	style,
	opened,
	active,
	onClick,
	title,
}: Props) {
	const buttonClasses = {
		[styles.root]: true,
	};

	if (className) {
		buttonClasses[className] = true;
	}
	if (opened) {
		buttonClasses[styles.rootOpen] = true;
	}
	if (active) {
		buttonClasses[styles.rootActive] = true;
	}
	let additionalProps = {};
	if (onClick != null) {
		additionalProps = {
			onClick: () => {
				onClick?.();
			},
		};
	}

	useEffect(() => {
		ReactTooltip.rebuild();
	}, [title]);

	return (
		<>
			<div data-tip={title} data-for={`${id}-tip`} onClick={() => ReactTooltip.hide()}>
				<BootstrapDropdown.Toggle
						id={id}
						className={cx(buttonClasses)}
						style={style}
						{...additionalProps}
					>
						{children}
					</BootstrapDropdown.Toggle>
			</div>
			<style>{`
				.__react_component_tooltip.show {
					opacity: 1 !important;
					z-index: 999999999;
				}	
			`}</style>
			<ReactTooltip id={`${id}-tip`} effect="solid" backgroundColor="#1B1B1B" />
		</>
	);
}

export default DropdownCanvasButton;
