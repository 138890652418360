import React from "react";

import BaseSubmodule from "../../common/BaseSubmodule";

import modules from "./modules/module_list.json";

class MainComponent extends BaseSubmodule {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            submodules: []
        };
    }

    static getNumberOfSteps() {
        return modules.length;
    }

    static getTitle() {
        return "Aggregate";
    }

    componentDidMount() {
        let submodules = [];
        modules.forEach(dir => {
            let mod = require(`./modules/${dir}/main.js`);
            submodules.push(mod.MainComponent);
        });

        this.setState({submodules: submodules});
    }

    render() {
        return (
            <>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 13fr 1fr 3fr",
                        gridRowGap: "1em",
                        gridColumnGap: "1em",
                        alignItems: "center"
                    }}
                >
                    {this.state.submodules.map((MainComponent, index) => (
                        <MainComponent
                            index={this.props.index + index}
                        />
                    ))}
                </div>
            </>
        );
    }
}

export { MainComponent };
export let requirePermission = "AggregateCurrent";
