import axios from "common/ServerConnection";

export async function sendErrorReport(errorMessage: string) {
    let jsonRequest = {
        error_message: errorMessage,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/send_bug_report", jsonRequest, {})
        .then((response) => {
            if (response.data.success === true) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}
