import React, { useRef, useState } from "react";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";
import Switch from "react-switch";
import StringOption from "common/StringOption";
import { DataScopeOption } from "common/DataScopes";
import { dataScienceSelectStyles } from "common/SelectStyles";
import Variables, { VariableOption } from "common/Variables";
import countriesOptions from "common/Countries";
import ReactTooltip from "react-tooltip";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";

interface LocationOptions {
    country?: VariableOption | StringOption;
    state?: VariableOption;
    city?: VariableOption;
    address?: VariableOption;
    zipcode?: VariableOption;
}

interface CoordinateOptions {
    latitude?: VariableOption;
    longitude?: VariableOption;
}

interface Props extends LocationOptions, CoordinateOptions {
    dataScope: DataScopeOption | null;
    usesCoordinates?: boolean;
    maxHeight: number;
    onChange: (
        locationChanges?: LocationOptions,
        coordinateChanges?: CoordinateOptions
    ) => void;
    onToggle: (usesCoordinates: boolean) => void;
    currentModuleId?: number;
}

const locationRows: ReadonlyArray<{
    title: string;
    option: keyof LocationOptions;
    isClearable: boolean;
}> = [
    {
        title: "Country (required)",
        option: "country",
        isClearable: false,
    },
    {
        title: "State/Province (optional)",
        option: "state",
        isClearable: true,
    },
    {
        title: "City (optional)",
        option: "city",
        isClearable: true,
    },
    {
        title: "Postal Code (optional)",
        option: "zipcode",
        isClearable: true,
    },
];

const coordinateRows: ReadonlyArray<{
    title: string;
    option: keyof CoordinateOptions;
    isClearable: boolean;
}> = [
    {
        title: "Latitude",
        option: "latitude",
        isClearable: false,
    },
    {
        title: "Longitude",
        option: "longitude",
        isClearable: false,
    },
];

const LocationSelectorView = observer((props: Props) => {
    let usesCoordinates = props.usesCoordinates;
    let variableOptions = Variables(
        props.dataScope?.value,
        props.currentModuleId ?? remoteModuleId
    ).variableOptions;
    let rows: ReadonlyArray<{
        title: string;
        option: keyof LocationOptions | keyof CoordinateOptions;
        isClearable: boolean;
    }> = usesCoordinates ? coordinateRows : locationRows;
    let [countryVariable, setCountryVariable] = useState(
        typeof props.country?.value === "number"
    );
    let hintRef = useRef(null);
    return (
        <div
            className="flex-simple-column"
            style={{
                maxHeight: props.maxHeight,
                height: props.maxHeight,
                marginLeft: "90px",
                overflow: "auto",
            }}
        >
            {rows.map((row, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        overflow: "hidden",
                        width: "100%",
                    }}
                >
                    <span
                        className="regular-text"
                        style={{
                            width: "12em",
                            marginRight: 10,
                            fontSize: "25px",
                            color: dataScienceElementsStyle.primaryTextColor,
                        }}
                    >
                        {row.title}
                    </span>
                    <div
                        className="flex-simple-column element"
                        style={{ overflow: "hidden", flex: 1 }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt) => {
                            evt.stopPropagation();
                        }}
                    >
                        <Select
                            isClearable={row.isClearable}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder=""
                            styles={{
                                ...dataScienceSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    height: "35px",
                                }),
                                menuPortal: (base) => ({ ...base, zIndex: 51 }),
                            }}
                            options={
                                row.option === "country" && !countryVariable
                                    ? countriesOptions
                                    : variableOptions
                            }
                            onChange={(newValue) => {
                                let changes = {
                                    [row.option]: newValue as
                                        | VariableOption
                                        | StringOption,
                                };
                                if (usesCoordinates) {
                                    props.onChange(undefined, changes);
                                } else {
                                    props.onChange(changes);
                                }
                            }}
                            value={props[row.option] ?? null}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <div
                            style={{
                                minHeight: "2px",
                                backgroundColor:
                                    dataScienceElementsStyle.secondaryTextColor,
                            }}
                        />
                    </div>
                </div>
            ))}
            <div style={{ flex: 1 }} />
            <div
                style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    overflow: "auto",
                    width: "100%",
                }}
            >
                <span
                    className="regular-text"
                    style={{
                        marginRight: 10,
                        color: dataScienceElementsStyle.secondaryTextColor,
                    }}
                >
                    Use location coordinates
                </span>

                <Switch
                    onChange={props.onToggle}
                    checked={props.usesCoordinates ?? false}
                    width={26}
                    height={13}
                    offColor="#20293C"
                    onColor="#20293C"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offHandleColor="#70889E"
                    onHandleColor="#1F8EFA"
                />
                {props.usesCoordinates && (
                    <img
                        onClick={() => {
                            ReactTooltip.show(hintRef.current!);
                        }}
                        ref={hintRef}
                        data-tip="Use coordinates in decimal format"
                        alt=""
                        src="/dist/img/warning.png"
                        style={{
                            cursor: "pointer",
                            marginLeft: 10,
                        }}
                    />
                )}
                {!props.usesCoordinates && (
                    <>
                        <span
                            className="regular-text"
                            style={{
                                marginLeft: 10,
                                marginRight: 10,
                                color:
                                    dataScienceElementsStyle.secondaryTextColor,
                            }}
                        >
                            Use country variable
                        </span>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Switch
                                onChange={(checked) => {
                                    props.onChange({
                                        country: undefined,
                                    });
                                    setCountryVariable(checked);
                                }}
                                checked={countryVariable}
                                width={26}
                                height={13}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                        <span
                            className="regular-text"
                            style={{
                                marginLeft: "20px",
                                color:
                                    dataScienceElementsStyle.secondaryTextColor,
                            }}
                        >
                            <a href="https://www.geonames.org/">
                                Uses data from GeoNames
                            </a>
                        </span>
                    </>
                )}
            </div>
            <ReactTooltip />
        </div>
    );
});

export default LocationSelectorView;
