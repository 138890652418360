import React, { Component } from "react";
import IconContainer from "common/IconContainer";
import stringSimilarity from "string-similarity";
import { mainStyle } from "common/MainStyle";

function filter(input, searchString) {
    let output = [];
    if (input) {
        if (!searchString) return undefined;
        input.slice().forEach((elem) => {
            if (similarity(elem.contents, searchString) > 0.01) {
                output.push(elem);
            }
        });
        output.sort((e1, e2) => {
            let sim_s1 = similarity(e1.contents, searchString);
            let sim_s2 = similarity(e2.contents, searchString);
            if (sim_s1 < sim_s2) {
                return 1;
            }
            if (sim_s1 > sim_s2) {
                return -1;
            }
            return 0;
        });
    }
    return output;
}

function similarity(card, searchString) {
    // search criteria
    //title
    let metrics = 0;
    if (card.title) {
        metrics +=
            stringSimilarity.compareTwoStrings(card.title, searchString) * 0.7;
    }

    //subtitle
    if (card.graphicsTitle) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                card.graphicsTitle,
                searchString
            ) * 0.1;
    }
    //insight
    if (card.insight) {
        metrics +=
            stringSimilarity.compareTwoStrings(card.insight, searchString) *
            0.1;
    }
    //recommendation
    if (card.recommendation) {
        metrics +=
            stringSimilarity.compareTwoStrings(
                card.recommendation,
                searchString
            ) * 0.1;
    }
    return metrics;
}

class InsightsSearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: "",
            expanded: false,
        };
    }
    render() {
        return (
            <div
                style={{
                    display: "flex",
                    height: 40,
                    alignItems: "center",
                    backgroundColor: mainStyle.getPropertyValue(
                        "--search-items-background-color"
                    ),
                }}
            >
                <div
                    style={{
                        display: "flex",
                        marginLeft: 0,
                        height: 40,
                        width: 40,
                        borderRadius: "5px 0px 0px 5px",
                        alignItems: "center",
                    }}
                >
                    <svg
                        style={{
                            marginLeft: "10px",
                            fill: "#6a7997",
                            width: 22,
                            height: 22,
                        }}
                        viewBox="0 0 24 24"
                    >
                        <IconContainer type="Search" />
                    </svg>
                </div>
                <input
                    style={{
                        backgroundColor: mainStyle.getPropertyValue(
                            "--search-items-background-color"
                        ),
                        color: mainStyle.getPropertyValue(
                            "--search-items-text-color"
                        ),
                        height: 40,
                        width: 200,
                        transition: "width .5s",
                        border: "none",
                        outline: 0,
                        borderRadius: "0px 5px 5px 0px",
                    }}
                    value={this.state.searchString}
                    type="text"
                    placeholder="Search Insights"
                    onChange={(value) => {
                        this.setState(
                            { searchString: value.target.value },
                            () => {
                                this.props.output(
                                    filter(
                                        this.props.input,
                                        this.state.searchString
                                    )
                                );
                            }
                        );
                    }}
                />
                <div
                    style={{
                        marginRight: "10px",
                    }}
                    onClick={(evt) => {
                        this.setState({ searchString: "" }, () => {
                            this.props.output(undefined);
                        });
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/insights/insights_remove_small.png"
                        style={{ cursor: "pointer" }}
                    />
                </div>
            </div>
        );
    }
}

export default InsightsSearchBar;
