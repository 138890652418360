const urlParams = new URLSearchParams(window.location.search);

function getTutorialRemoteId() {
    return `t~${urlParams.get("tutorial_id")}`;
}

const remoteModuleId =
    window.location.pathname === "/tutorial.html"
        ? getTutorialRemoteId()
        : window.location.pathname.startsWith("/app/")
        ? `~${window.location.pathname.slice("/app/".length)}`
        : urlParams.get("remote_moduleid");

export default remoteModuleId;
