import React, { Component } from "react";
import CustomBarChartWithOverlay from "common/graphics/CustomBarChartWithOverlay";
import { mainStyle } from "common/MainStyle";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";
import StringUtils from "common/utilities/StringUtils";
import sections from "sections.json";
import { ClustItem } from "./ClustItem";
import styles from "./HoldOutPredictionChart.module.css";

interface LeverInputProps {
    decimals?: number;
    name: string;
    value: number;
    onChange: (value: number) => void;
}

interface LeverInputState {
    value: string;
}

class LeverInput extends Component<LeverInputProps, LeverInputState> {
    constructor(props: LeverInputProps) {
        super(props);
        let decimals = props.decimals ?? 1;
        this.state = {
            value: props.value?.toFixed(decimals) ?? "",
        };
    }
    componentDidUpdate(prevProps: LeverInputProps) {
        if (prevProps.value !== this.props.value) {
            let decimals = this.props.decimals ?? 1;

            let numValue = NaN;
            if (StringUtils.isNumber(this.state.value)) {
                numValue = Number(this.state.value);
            }
            if (Number.isNaN(this.props.value) && Number.isNaN(numValue))
                return;
            if (this.props.value !== numValue) {
                this.setState({
                    value: this.props.value?.toFixed(decimals) ?? "",
                });
            }
        } else if (prevProps.decimals !== this.props.decimals) {
            this.setState({
                value:
                    this.props.value?.toFixed(this.props.decimals ?? 1) ?? "",
            });
        }
    }

    render() {
        return (
            <div
                className="flex-simple-column"
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                <input
                    style={{
                        marginTop: "5px",
                        marginLeft: "5px",
                        marginRight: "5px",
                        width: "100px",
                    }}
                    className="like-select"
                    value={this.state.value}
                    onChange={(e) => {
                        let newValue = e.target.value;
                        this.setState({ value: newValue });
                        if (StringUtils.isNumber(newValue)) {
                            let numValue = Number(newValue);
                            this.props.onChange(numValue);
                        } else this.props.onChange(NaN);
                    }}
                ></input>

                <span
                    style={{
                        width: "100%",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: mainStyle.getPropertyValue(
                            "--graphs-axes-text-color"
                        ),
                        display: "block",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {this.props.name}
                </span>
            </div>
        );
    }
}

interface Props {
    continuousVariables: string[];
    categoricalVariables: string[];
    clust: ClustItem[][];
    categoriesClust: ClustItem[][];
    onClustChange: (clust: ClustItem[][]) => void;
    onCategoriesClustChange: (clust: ClustItem[][]) => void;
    showLeverBars: boolean;
    ticksColor?: string;
    axesLinesColor?: string;
    ticksSize?: number;
}
interface State {
    currentVariable?: {
        name: string;
        clustIndex: number;
    };
}

class HoldOutPredictionParametersChart extends Component<Props, State> {
    prevClientHeight: number = 0;
    customRefs: { [key: string]: any } = {};
    rootRef: any = null;
    constructor(props: Props) {
        super(props);
        this.state = {
            currentVariable: undefined,
        };
        this.selectPreviousVariable = this.selectPreviousVariable.bind(this);
        this.selectNextVariable = this.selectNextVariable.bind(this);
        this.selectVariable = this.selectVariable.bind(this);
    }
    selectVariable(variable: string, scroll: boolean, index: number) {
        this.setState(
            {
                currentVariable: {
                    name: variable,
                    clustIndex: index,
                },
            },
            () => {
                this.props.clust.forEach((clust, clustIndex) => {
                    let key = "clustBarChart".concat(String(clustIndex));
                    if (index === clustIndex) {
                        if (key in this.customRefs)
                            this.customRefs[
                                "clustBarChart".concat(String(clustIndex))
                            ]?.selectVariable(variable, scroll);
                    } else {
                        if (key in this.customRefs)
                            this.customRefs[
                                "clustBarChart".concat(String(clustIndex))
                            ]?.selectVariable(undefined, false);
                    }
                });
            }
        );
    }
    selectPreviousVariable(
        currentVariable: string,
        scroll: boolean,
        clustIndex: number
    ) {
        let index = this.props.continuousVariables.findIndex(
            (item) => item === currentVariable
        );
        let newClustIndex = clustIndex;
        if (index > 0) {
            index = index - 1;
        } else {
            newClustIndex = (clustIndex - 1) % this.props.clust.length;
            if (newClustIndex < 0) newClustIndex = this.props.clust.length - 1;
            index = this.props.continuousVariables.length - 1;
        }
        let newVariable = this.props.continuousVariables[index];
        this.selectVariable(newVariable, scroll, newClustIndex);
    }
    selectNextVariable(
        currentVariable: string,
        scroll: boolean,
        clustIndex: number
    ) {
        let index = this.props.continuousVariables.findIndex(
            (item) => item === currentVariable
        );
        let newClustIndex = clustIndex;
        if (index < this.props.continuousVariables.length - 1) {
            index = index + 1;
        } else {
            newClustIndex = (clustIndex + 1) % this.props.clust.length;
            index = 0;
        }
        let newVariable = this.props.continuousVariables[index];
        this.selectVariable(newVariable, scroll, newClustIndex);
    }

    buildLeversInRow(clustIndex: number) {
        let clustItem = this.props.clust[clustIndex];
        let categoriesClustItem = this.props.categoriesClust[clustIndex];
        return (
            <div
                className="my-row"
                style={{ height: "250px", flexWrap: "wrap", overflow: "auto" }}
            >
                {clustItem.map((item, index) => {
                    return (
                        <LeverInput
                            key={index}
                            name={item.name}
                            value={item.value as number}
                            onChange={(value) => {
                                let clust = Array.from(this.props.clust);
                                clust[clustIndex][index].value = value;
                                this.props.onClustChange(clust);
                            }}
                        />
                    );
                })}
                {categoriesClustItem.map((item, index) => {
                    return this.buildCategoryItem(item, clustIndex, index);
                })}
            </div>
        );
    }
    buildCategoryItem(item: ClustItem, clustIndex: number, index: number) {
        return (
            <div
                key={index}
                className="flex-simple-column"
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
            >
                <Select
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    styles={{
                        ...customSelectStyles,
                        container: (base) => ({
                            ...base,
                            height: "38px",
                            width: "100px",
                            marginTop: "5px",
                            marginLeft: "5px",
                            marginRight: "5px",
                        }),
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 100000000,
                        }),
                    }}
                    options={item.values!.map((value) => ({
                        label: value,
                        value: value,
                    }))}
                    value={{
                        label: item.value,
                        value: item.value,
                    }}
                    onChange={(newValue) => {
                        let categoriesClust = Array.from(
                            this.props.categoriesClust
                        );
                        categoriesClust[clustIndex][index].value = (
                            newValue as {
                                label: string;
                                value: string;
                            }
                        ).value;
                        this.props.onCategoriesClustChange(categoriesClust);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                <span
                    style={{
                        width: "100%",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        color: mainStyle.getPropertyValue(
                            "--graphs-axes-text-color"
                        ),
                        display: "block",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {item.name}
                </span>
            </div>
        );
    }

    buildCategoricalColumns(clustIndex: number) {
        let subcategoriesClust = this.props.categoriesClust[clustIndex];
        return (
            <div className="my-row">
                {subcategoriesClust.map((item, index) => {
                    return this.buildCategoryItem(item, clustIndex, index);
                })}
            </div>
        );
    }

    componentDidUpdate(_nextProps: Props) {
        let currentHeight = this.rootRef?.clientHeight ?? 0;
        if (this.props.showLeverBars) {
            if (
                currentHeight !== this.prevClientHeight ||
                (!this.state.currentVariable &&
                    this.props.continuousVariables.length > 0) ||
                (this.state.currentVariable != null &&
                    this.props.continuousVariables.length > 0 &&
                    !this.props.continuousVariables.includes(
                        this.state.currentVariable.name
                    ))
            ) {
                setTimeout(() => {
                    this.selectVariable(
                        this.props.continuousVariables[0],
                        false,
                        0
                    );
                }, 100);
            }
        }
        this.prevClientHeight = currentHeight;
    }

    componentDidMount() {
        if (
            this.props.showLeverBars &&
            !this.state.currentVariable &&
            this.props.continuousVariables.length > 0
        ) {
            setTimeout(() => {
                this.selectVariable(
                    this.props.continuousVariables[0],
                    false,
                    0
                );
            }, 300);
        }
    }

    render() {
        if (
            this.props.categoricalVariables.length +
                this.props.continuousVariables.length ===
            0
        )
            return null;
        let chartWidth =
            this.props.clust.length > 0
                ? Math.floor(100 / this.props.clust.length)
                : undefined;

        return (
            <div
                ref={(ref) => {
                    this.rootRef = ref;
                }}
                className="flex-simple-column"
                style={{
                    height: "100%",
                }}
            >
                <span
                    className={styles.title}
                    style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 30,
                        display: "block",
                        textAlign: "left",
                    }}
                >
                    {`Levers`}
                </span>
                <div
                    className="my-row"
                    style={{
                        height: "100%",
                    }}
                >
                    {this.props.clust.length !== 0 &&
                        this.props.clust.map((clustItem, clustIndex) => {
                            return (
                                <div
                                    key={clustIndex}
                                    ref={(el) => {
                                        this.customRefs[
                                            "clustBarChartScrollable".concat(
                                                String(clustIndex)
                                            )
                                        ] = el;
                                    }}
                                    style={{
                                        overflow: "auto hidden",
                                        position: "relative",
                                        height: "100%",
                                        minWidth: `calc(${chartWidth}% - 5px)`,
                                        marginRight: "5px",
                                    }}
                                >
                                    {this.props.showLeverBars && (
                                        <div style={{ height: "190px" }}>
                                            {clustItem.length > 0 && (
                                                <CustomBarChartWithOverlay
                                                    axesLinesColor={
                                                        this.props
                                                            .axesLinesColor
                                                    }
                                                    ticksColor={
                                                        this.props.ticksColor
                                                    }
                                                    ticksSize={
                                                        this.props.ticksSize
                                                    }
                                                    ref={(el) => {
                                                        this.customRefs[
                                                            "clustBarChart".concat(
                                                                String(
                                                                    clustIndex
                                                                )
                                                            )
                                                        ] = el;
                                                    }}
                                                    onSnapToGrid={() => {}}
                                                    onVariableChanged={(
                                                        variable,
                                                        maxChartValue,
                                                        levelsCount,
                                                        currentIndex,
                                                        _type
                                                    ) => {
                                                        let index =
                                                            clustItem.findIndex(
                                                                (item) =>
                                                                    item.name ===
                                                                    variable
                                                            );
                                                        let newClust =
                                                            Array.from(
                                                                this.props.clust
                                                            );
                                                        newClust[clustIndex][
                                                            index
                                                        ].value =
                                                            (maxChartValue *
                                                                currentIndex) /
                                                            levelsCount;
                                                        this.props.onClustChange(
                                                            newClust
                                                        );
                                                    }}
                                                    onVariableEntered={(
                                                        variable,
                                                        _type,
                                                        value
                                                    ) => {
                                                        let index =
                                                            clustItem.findIndex(
                                                                (item) =>
                                                                    item.name ===
                                                                    variable
                                                            );
                                                        let newClust =
                                                            Array.from(
                                                                this.props.clust
                                                            );
                                                        newClust[clustIndex][
                                                            index
                                                        ].value = value;
                                                        this.props.onClustChange(
                                                            newClust
                                                        );
                                                    }}
                                                    selectNextVariable={(
                                                        variable,
                                                        scroll
                                                    ) =>
                                                        this.selectNextVariable(
                                                            variable!,
                                                            scroll,
                                                            clustIndex
                                                        )
                                                    }
                                                    selectPreviousVariable={(
                                                        variable,
                                                        scroll
                                                    ) =>
                                                        this.selectPreviousVariable(
                                                            variable!,
                                                            scroll,
                                                            clustIndex
                                                        )
                                                    }
                                                    selectVariable={(
                                                        variable,
                                                        scroll
                                                    ) =>
                                                        this.selectVariable(
                                                            variable!,
                                                            scroll,
                                                            clustIndex
                                                        )
                                                    }
                                                    leftBarKey={"value"}
                                                    leftBarColor={"#FFAB4F"}
                                                    data={
                                                        clustItem as any as ({
                                                            name: string;
                                                        } & {
                                                            [
                                                                key: string
                                                            ]: number;
                                                        })[]
                                                    }
                                                    scrollableRef={
                                                        this.customRefs[
                                                            "clustBarChartScrollable".concat(
                                                                String(
                                                                    clustIndex
                                                                )
                                                            )
                                                        ]
                                                    }
                                                    title={""}
                                                    watermark={
                                                        sections._Global
                                                            ?.watermark
                                                    }
                                                />
                                            )}
                                        </div>
                                    )}
                                    {!this.props.showLeverBars &&
                                        this.buildLeversInRow(clustIndex)}
                                    {this.props.showLeverBars &&
                                        this.buildCategoricalColumns(
                                            clustIndex
                                        )}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

export default HoldOutPredictionParametersChart;
