interface StringOption {
    label: string;
    value: string;
}

const ColorOptions: ReadonlyArray<StringOption> = [
    { label: "Neutral", value: "transparent" },
    {
        label: "Red",
        value: "#EE423D",
    },
    {
        label: "Amber",
        value: "#FFAB4F",
    },
    {
        label: "Green",
        value: "#05C985",
    },
];

export default ColorOptions;