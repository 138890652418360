import React from "react";
import { mainStyle } from "common/MainStyle";
import CanvasVersion from "./CanvasVersion";

interface Props {
    highlight: boolean;
    version: CanvasVersion;
    canWrite: boolean;
    onClick: () => void;
    onDelete: () => void;
    onEdit: () => void;
}

export default function VersionCard(props: Props) {
    let hasThumbnail = props.version.thumbnail != null;
    return (
        <div
            style={{
                position: "relative",
                width: 185,
                height: 124,
                cursor: "pointer",
                backgroundColor: props.highlight
                    ? mainStyle.getPropertyValue(
                          "--sheet-ribbon-highlight-color"
                      )
                    : "transparent",
            }}
            onClick={(_evt) => {
                props.onClick();
            }}
        >
            <div style={{ position: "relative", height: "100%" }}>
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            marginTop: 6,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                boxSizing: "border-box",
                                boxShadow: "0px 0px 4px 0px #242E424D",
                                width: 144,
                                height: 84,
                                background: "transparent",
                            }}
                        >
                            {hasThumbnail && (
                                <img
                                    alt=""
                                    src={props.version.thumbnail!}
                                    style={{
                                        width: "144px",
                                        height: "84px",
                                        objectFit: "contain",
                                    }}
                                />
                            )}
                            {!hasThumbnail && props.highlight && (
                                <div
                                    className="regular-text"
                                    style={{
                                        width: "144px",
                                        height: "84px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    CURRENT VERSION
                                </div>
                            )}
                        </div>
                    </div>
                    <span
                        className="line-clamp"
                        style={{
                            marginTop: 3,
                            marginBottom: 3,
                            fontFamily: "Roboto",
                            fontSize: 9,
                            color: props.highlight
                                ? "var(--sheet-ribbon-slide-title-highlight-color)"
                                : "var(--sheet-ribbon-slide-title-color)",
                            fontWeight: props.highlight ? "bold" : "normal",
                            width: 144,
                            lineHeight: "11px",
                        }}
                    >
                        {props.version.title ??
                            props.version.creationTime.toDateString() +
                                " " +
                                props.version.creationTime
                                    .getHours()
                                    .toString()
                                    .padStart(2, "0") +
                                ":" +
                                props.version.creationTime
                                    .getMinutes()
                                    .toString()
                                    .padStart(2, "0") +
                                ":" +
                                props.version.creationTime
                                    .getSeconds()
                                    .toString()
                                    .padStart(2, "0")}
                    </span>
                </div>
            </div>
        </div>
    );
}
