import React from "react";

export function MySvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      {...props}
    >
      <defs>
        <ellipse id="ellipse-1-kpistream" cx="12" cy="12" rx="11" ry="11" />
        <mask
          id="mask-2-kpistream"
          x="0"
          y="0"
          maskContentUnits="userSpaceOnUse"
          maskUnits="userSpaceOnUse"
        >
          <rect
            width="25"
            height="25"
            x="0"
            y="0"
            fill="black"
            stroke="transparent"
          />
          <use fill="white" xlinkHref="#ellipse-1-kpistream" />
        </mask>
        <path
          id="path-3-kpistream"
          fillRule="evenodd"
          d="M2 7.5H0L4 0v5.5h2L2 13V7.5z"
        />
        <mask
          id="mask-4-kpistream"
          maskContentUnits="userSpaceOnUse"
          maskUnits="userSpaceOnUse"
        >
          <rect
            width="25"
            height="25"
            x="0"
            y="0"
            fill="black"
            stroke="transparent"
          />
          <use fill="white" xlinkHref="#path-3-kpistream" />
        </mask>
      </defs>
      <g>
        <use
          fillOpacity="0"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="4"
          mask="url(#mask-2-kpistream)"
          xlinkHref="#ellipse-1-kpistream"
        />
        <g transform="rotate(25.000009 -5.02974785 30.6784725)">
          <use fill="rgb(255,255,255)" xlinkHref="#path-3-kpistream" />
          <g mask="url(#mask-4-kpistream)">
            <rect
              width="78"
              height="78"
              x="0"
              y="0"
              rx="0"
              ry="0"
              transform="translate(-7 -2)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}