import { observable, makeObservable, runInAction } from "mobx";
import opentype from "opentype.js";
import {
    CollectionType,
    getAllCollectionsApi,
    getCollectionsApi,
    getFilesFromCollection,
} from "./MediaCollectionsApi";
import { imageUrlPrefix } from "./ServerConnection";

class FontLoader {
    admin: boolean = false;
    @observable public fontsMap = observable.map<number, string>(
        {},
        { deep: false }
    );
    @observable public fonts: string[] = [];

    constructor(admin: boolean) {
        makeObservable(this);
        this.admin = admin;
    }

    async addFont(fontInfo: opentype.Font, newFileId: number) {
        let url = `${imageUrlPrefix()}/media/${newFileId}/content`;
        try {
            let fontName = `${fontInfo.names.fontFamily.en} ${fontInfo.names.fontSubfamily.en}`;
            let fontFace = new FontFace(fontName, `url(${url})`);
            fontFace.load().then(
                (f) => {
                    document.fonts.add(f);
                },
                (error) => {
                    console.log(String(error));
                }
            );
            this.fontsMap.set(newFileId, fontName);
        } catch (error) {
            console.log(String(error));
        }
    }

    public async update(): Promise<void> {
        try {
            let collections = this.admin
                ? await getAllCollectionsApi(CollectionType.Font)
                : await getCollectionsApi(CollectionType.Font);
            let entries: Array<[number, string]> = [];

            for (let collection of collections) {
                try {
                    let collectionFiles = await getFilesFromCollection(
                        collection.id,
                        this.admin
                    );
                    for (let file of collectionFiles) {
                        let url = `${imageUrlPrefix()}/media/${file.id}/content`;
                        try {
                            let loadedFont = await opentype.load(url);
                            let fontName = `${loadedFont.names.fontFamily.en} ${loadedFont.names.fontSubfamily.en}`;
                            let fontFace = new FontFace(
                                fontName,
                                `url(${url})`
                            );
                            fontFace.load().then(
                                (f) => {
                                    document.fonts.add(f);
                                },
                                (error) => {
                                    console.log(String(error));
                                }
                            );
                            entries.push([file.id, fontName]);
                        } catch (error) {
                            console.log(String(error));
                        }
                    }
                } catch (error) {
                    console.log(String(error));
                }
            }
            runInAction(() => {
                this.fontsMap.merge(entries);
                this.fonts = entries.map((entry) => entry[1]);
            });
        } catch (error) {
            return;
        }
    }
}

export default new FontLoader(false);
export const FontLoaderAdmin = new FontLoader(true);
