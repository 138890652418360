import React from "react";
import {
    numberFormatOptions,
    NumberFormatOption,
    NumberFormatType,
} from "common/Canvas";
import Finding, {
    TickLabelOrientation,
    TickLabelOrientationOption,
    tickLabelOrientationOptions,
} from "common/Finding";
import { mainStyle } from "common/MainStyle";
import { getSmallSelectStyle } from "common/SelectStyles";
import StringUtils from "common/utilities/StringUtils";
import Select, { createFilter } from "react-select";
import Switch from "react-switch";
import styles from "../StylingSection.module.css";
import { dateTimeFormatOptionsForLevels } from "common/utilities/TimeFormatUtils";

interface Props {
    finding: Finding;
    axis: string;
    axisType: string;
    onChangeConfig: (config: Finding["config"], updateData?: boolean) => void;
}

function LinePlotSection(props: Props) {
    const { axis, axisType } = props;
    const fontSize = 10;
    let customSelectStylesSmall = getSmallSelectStyle(fontSize);
    let textStyle = {
        fontWeight: 300,
        fontFamily: "Roboto",
        fontSize: fontSize,
        color: mainStyle.getPropertyValue("--popup-primary-text-color"),
    };

    const dateTimeFormatOptions =
        dateTimeFormatOptionsForLevels[
            props.finding.config.ticksAndLabels?.x?.intervalUnit ?? "day"
        ];

    return (
        <>
            {axisType === "datetime" && (
                <div className={styles.optionContainer}>
                    <span className={styles.optionName}>Format</span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        isClearable={false}
                        styles={{
                            ...customSelectStylesSmall,
                            container: (base) => ({
                                ...base,
                                width: "100%",
                            }),
                        }}
                        options={dateTimeFormatOptions}
                        onChange={(newValue) => {
                            const newConfig = {
                                ...props.finding.config,
                                formatAxises: {
                                    ...props.finding.config.formatAxises,
                                    xAxisDate: (newValue as
                                        | { label: string; value: string }
                                        | null
                                        | undefined)?.value
                                        ? {
                                              label: (newValue as
                                                  | {
                                                        label: string;
                                                        value: string;
                                                    }
                                                  | null
                                                  | undefined)?.label,
                                              value: (newValue as
                                                  | {
                                                        label: string;
                                                        value: string;
                                                    }
                                                  | null
                                                  | undefined)?.value,
                                          }
                                        : null,
                                },
                            };
                            props.onChangeConfig(newConfig, true);
                        }}
                        value={
                            props.finding.config.formatAxises?.xAxisDate ??
                            dateTimeFormatOptions[0]
                        }
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                </div>
            )}
            {(axisType === "int" || axisType === "float") && (
                <>
                    <div className={styles.optionContainer}>
                        <span className={styles.optionName}>Format</span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            isClearable={true}
                            placeholder={"Number format"}
                            styles={{
                                ...customSelectStylesSmall,
                                container: (base) => ({
                                    ...base,
                                    width: "100%",
                                }),
                            }}
                            options={numberFormatOptions}
                            onChange={(newValue) => {
                                const newConfig = {
                                    ...props.finding.config,
                                    formatAxises: {
                                        ...props.finding.config.formatAxises,
                                        [axis]: {
                                            ...(props.finding.config
                                                .formatAxises?.[axis] ?? {}),
                                            numberType:
                                                (newValue as
                                                    | NumberFormatOption
                                                    | null
                                                    | undefined)?.value ?? null,
                                        },
                                    },
                                };
                                props.onChangeConfig(newConfig);
                            }}
                            value={{
                                label:
                                    NumberFormatType[
                                        props.finding.config?.formatAxises?.[
                                            axis
                                        ]?.numberType
                                    ] ?? "Select...",
                                value:
                                    props.finding.config?.formatAxises?.[axis]
                                        ?.numberType ?? null,
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>

                    <div
                        className="my-row"
                        style={{
                            marginTop: "10px",
                            alignItems: "center",
                            alignSelf: "flex-start",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <span style={textStyle}>{"Decimals"}</span>
                        <input
                            className="like-select"
                            style={{
                                ...textStyle,
                                fontWeight: 400,
                                minHeight: "18px",
                                width: "70px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                fontFamily: "monospace",
                                textAlign: "right",
                            }}
                            placeholder=""
                            onChange={(e) => {
                                let value = e.target.value;
                                let decimalPoints: number = 0;
                                if (StringUtils.isNumber(value)) {
                                    decimalPoints = Math.min(
                                        Math.max(0, Math.trunc(Number(value))),
                                        20
                                    );
                                }

                                const newConfig = {
                                    ...props.finding.config,
                                    formatAxises: {
                                        ...props.finding.config.formatAxises,
                                        [axis]: {
                                            ...props.finding.config
                                                .formatAxises[axis],
                                            decimalPoints,
                                        },
                                    },
                                };
                                props.onChangeConfig(newConfig);
                            }}
                            defaultValue={
                                props.finding.config?.formatAxises?.[axis]
                                    ?.decimalPoints ?? ""
                            }
                        />
                    </div>
                    <div
                        className="my-row"
                        style={{
                            marginTop: "10px",
                            alignItems: "center",
                            alignSelf: "flex-start",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <span style={textStyle}>{"Use Abbreviation"}</span>
                        <div style={{ marginLeft: 5 }}>
                            <Switch
                                onChange={(checked) => {
                                    const newConfig = {
                                        ...props.finding.config,
                                        formatAxises: {
                                            ...props.finding.config
                                                .formatAxises,
                                            [axis]: {
                                                ...(props.finding.config
                                                    .formatAxises?.[axis] ??
                                                    {}),
                                                useAbbreviation: checked,
                                            },
                                        },
                                    };
                                    props.onChangeConfig(newConfig);
                                }}
                                checked={
                                    props.finding.config?.formatAxises?.[axis]
                                        ?.useAbbreviation ?? false
                                }
                                width={16}
                                height={7}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                    </div>
                    <div
                        className="my-row"
                        style={{
                            marginTop: "10px",
                            alignItems: "center",
                            alignSelf: "flex-start",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <span style={textStyle}>{"Use 1000 separator(,)"}</span>
                        <div style={{ marginLeft: 5 }}>
                            <Switch
                                onChange={(checked) => {
                                    const newConfig = {
                                        ...props.finding.config,
                                        formatAxises: {
                                            ...props.finding.config
                                                .formatAxises,
                                            [axis]: {
                                                ...(props.finding.config
                                                    .formatAxises?.[axis] ??
                                                    {}),
                                                useCommaSeparator: checked,
                                            },
                                        },
                                    };
                                    props.onChangeConfig(newConfig);
                                }}
                                checked={
                                    props.finding.config?.formatAxises?.[axis]
                                        ?.useCommaSeparator ?? true
                                }
                                width={16}
                                height={7}
                                offColor="#20293C"
                                onColor="#20293C"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                offHandleColor="#70889E"
                                onHandleColor="#1F8EFA"
                            />
                        </div>
                    </div>
                </>
            )}
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>
                    {axis[0].toUpperCase()} tick orientation
                </span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={""}
                    styles={{
                        ...customSelectStylesSmall,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                        }),
                    }}
                    options={tickLabelOrientationOptions}
                    onChange={(newValue) => {
                        const newConfig = {
                            ...props.finding.config,
                            ticksAndLabels: {
                                ...(props.finding.config.ticksAndLabels ?? {}),
                                [axis[0]]: {
                                    ...(props.finding.config.ticksAndLabels?.[
                                        axis[0]
                                    ] ?? {}),
                                    labelOrientation: (newValue as TickLabelOrientationOption)
                                        .value,
                                },
                            },
                        };
                        props.onChangeConfig(newConfig);
                    }}
                    value={tickLabelOrientationOptions.find(
                        (option) =>
                            option.value ===
                            (props.finding.config.ticksAndLabels?.[axis[0]]
                                ?.labelOrientation ??
                                TickLabelOrientation.Horizonal)
                    )}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
        </>
    );
}

export default LinePlotSection;
