import "common/styles/App.css";
import React from "react";
import { reaction } from "mobx";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import KanbanBoard from "common/graphics/KanbanBoard";

import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";
import BaseJourney from "../../common/BaseJourney";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import { AggregateFunctionsAliases } from "common/AggregateFunctionsAliases";
import { Api } from "./Api";

const Step = Object.freeze({
    initial: 0,
    selectDataScope: 1,
    selectTable: 2,
    selectConditions: 3,
    selectCardTitle: 4,
    selectCardContents: 5,
    selectCategories: 6,
    selectSummaryStatisticsOperation: 7,
    selectSummaryStatisticsVariables: 8,
    final: 9,
});

function StepFinal(props) {
    let {
        onNewFinding,
        onBack,
        onShowRecipesPopup,
        onSaveFinding,
        finding,
        loading,
        // data
    } = props;
    return (
        <>
            <div
                className="my-row"
                style={{
                    justifyContent: "space-between",
                    marginTop: 10,
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    onClick={() => {
                        onBack();
                    }}
                    style={{
                        minWidth: 100,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/data-exploration/chevron_back.png"
                    />
                </div>
                {loading ? (
                    <span className="exploration-big-title-span">
                        {"LOADING"}
                    </span>
                ) : (
                    finding && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "calc(100% - 200px)",
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "center",
                                    width: "100%",
                                    height: "380px",
                                }}
                            >
                                <KanbanBoard
                                    onDataChanged={(newData) => {
                                        if (onNewFinding != null) {
                                            onNewFinding({
                                                ...finding,
                                                content: {
                                                    ...finding.content,
                                                    data: {
                                                        ...finding.content.data,
                                                        lanes: newData.lanes,
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                    editable={onNewFinding != null}
                                    data={finding.content.data}
                                    config={finding.config}
                                />
                            </div>
                            <FinalStepBottomBar
                                onShowRecipesPopup={onShowRecipesPopup}
                                onSaveFinding={onSaveFinding}
                                topMargin={20}
                            />
                        </div>
                    )
                )}
                <div
                    style={{
                        minWidth: 100,
                        height: "100%",
                    }}
                />
            </div>
        </>
    );
}

class MainComponent extends BaseJourney {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            previousStep: 0,
            step: 0,
            animationDirection: "top",
            selectedTargetVariables: [],
            targetVariables: [],
            selectedSummaryVariables: [],
            summaryVariables: [],
            finding: undefined,
            selectedTable: undefined,
            operationVariable: undefined,
            operationVariables: ["mean", "sum", "count", "count_distinct"],
            categoryVariable: undefined,
            titleVariable: undefined,
            conditions: [
                {
                    variable: null,
                    operation: null,
                    value: null,
                    logical: null,
                    leftBracket: null,
                    rightBracket: null,
                },
            ],
            step2VariablesMap: {
                [Step.selectCardTitle]: ["titleVariable", "targetVariables"],
                [Step.selectCardContents]: [
                    "targetVariable",
                    "targetVariables",
                ],
                [Step.selectCategories]: [
                    "categoryVariable",
                    "targetVariables",
                ],
                [Step.selectSummaryStatisticsOperation]: [
                    "operationVariable",
                    "operationVariables",
                ],
                [Step.selectSummaryStatisticsVariables]: [
                    "selectedSummaryVariables",
                    "targetVariables",
                ],
            },
        };
        this.getData = this.getData.bind(this);
        this.initializeVariables = this.initializeVariables.bind(this);
        this.switchVariables = this.switchVariables.bind(this);
    }

    selectVariablesByOperation(operationName) {
        if (operationName === "count" || operationName === "count_distinct") {
            return this.getVariables().dataVariables.map((item) => item.name);
        } else {
            return this.getVariables().numericVariables.map(
                (item) => item.name
            );
        }
    }

    switchVariables() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
        this.initializeVariables();
        this.initializeVariablesReaction = reaction(
            () => this.getVariables().dataVariables,
            () => {
                this.initializeVariables();
            }
        );
    }

    clearOptionalArgs() {
        this.setState({
            selectedTable: this.defaultTable(),
            conditions: ConditionsSelector.defaultValue,
            selectedTargetVariables: [],
            targetVariables: [],
            selectedSummaryVariables: [],
            summaryVariables: [],
            operationVariable: undefined,
            categoryVariable: undefined,
            dynamicOptions: {},
        });
    }

    // We need to convert undefined to null to remove field if used in canvas page
    getConfig() {
        let config = {
            version: configVersion,
            dataScope: this.state.dataScope ?? null,
            selectedTable: this.state.selectedTable ?? null,
            conditions: this.state.conditions ?? null,
            selectedTargetVariables: this.state.selectedTargetVariables ?? null,
            selectedTargetVariablesIndices:
                this.state.selectedTargetVariables.map((item) =>
                    this.getVariables().variableToIndex(item)
                ) ?? null,
            targetValues: this.state.targetValues ?? null,
            selectedSummaryVariables:
                this.state.selectedSummaryVariables ?? null,
            selectedSummaryVariablesIndices:
                this.state.selectedSummaryVariables.map((item) =>
                    this.getVariables().variableToIndex(item)
                ) ?? null,
            operationVariable: this.state.operationVariable ?? null,
            categoryVariable: this.state.categoryVariable ?? null,
            categoryVariableIndex: this.state.categoryVariable
                ? this.getVariables().variableToIndex(
                      this.state.categoryVariable
                  )
                : null,
            titleVariable: this.state.titleVariable ?? null,
            titleVariableIndex: this.state.titleVariable
                ? this.getVariables().variableToIndex(this.state.titleVariable)
                : null,
            journeyName: this.props.journeyName,
        };
        if (this.props.addToCanvas) {
            config.dynamicOptions = this.prepareDynamicOptions(
                this.state.dynamicOptions,
                config
            );
        }
        return config;
    }
    getData() {
        this.setState({ loading: true });

        Api.getData(this.getConfig(), undefined, this.props.currentModuleId)
            .then((finding) => {
                this.setState({ finding: finding, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error);
            });
    }
    buildContent() {
        switch (this.state.step) {
            case Step.initial:
                return this.buildInitial(
                    "How do you want to examine your variables?"
                );
            case Step.selectDataScope:
                return this.buildDataScopesSelector();
            case Step.selectTable:
                return this.buildTableSelector();
            case Step.selectConditions:
                return this.buildConditionsSelector();
            case Step.selectCardTitle:
                return this.buildVariablesSelector(
                    "I want to create cards with title",
                    "titleVariable",
                    "targetVariables",
                    false,
                    DynamicOptionType.variable,
                    undefined,
                    false
                );
            case Step.selectCardContents:
                return this.buildMultiVariablesSelector(
                    "and include the following in each card",
                    "selectedTargetVariables",
                    "targetVariables",
                    DynamicOptionType.variable,
                    "selectedTargetVariablesIndices"
                );
            case Step.selectCategories:
                return this.buildVariablesSelector(
                    "and split cards into categories by",
                    "categoryVariable",
                    "targetVariables",
                    false,
                    DynamicOptionType.variable,
                    undefined,
                    false
                );
            case Step.selectSummaryStatisticsOperation:
                return this.buildVariablesSelector(
                    "(optional) and display a summary in each board",
                    "operationVariable",
                    "operationVariables",
                    false,
                    DynamicOptionType.variable,
                    undefined,
                    false,
                    AggregateFunctionsAliases
                );
            case Step.selectSummaryStatisticsVariables:
                return this.buildMultiVariablesSelector(
                    "of",
                    "selectedSummaryVariables",
                    "targetVariables",
                    DynamicOptionType.variable,
                    "selectedTargetVariablesIndices",
                    this.state.operationVariable === "count" ? 1 : 5
                );

            case Step.final:
                return (
                    <StepFinal
                        onShowRecipesPopup={this.showRecipesPopup}
                        loading={this.state.loading}
                        finding={this.state.finding}
                        onSaveFinding={this.saveFinding}
                        onBack={this.back}
                        onNewFinding={this.onNewFinding}
                    />
                );

            default:
                return <div />;
        }
    }
    buildLeftBar() {
        return super.buildLeftBar(this.state.step < Step.final - 1);
    }
    initializeVariables() {
        let targetVariables = this.getVariables().dataVariables.map(
            (item) => item.name
        );
        this.setState({
            targetVariables: targetVariables,
        });
    }
    componentDidMount() {
        this.getLastJourneyConfig();
    }

    componentWillUnmount() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
    }
    showFinalStep() {
        if (
            this.state.dataScope &&
            this.state.selectedTable &&
            this.state.selectedTargetVariables &&
            this.state.categoryVariable &&
            this.state.step > Step.initial &&
            this.state.step < Step.final
        )
            this.setState({ animationDirection: "top" }, () => {
                this.setState(
                    (state) => ({
                        previousStep: state.step,
                        step: Step.final,
                    }),
                    () => {
                        this.getData();
                        this.saveNewJourneyConfig();
                    }
                );
            });
    }

    selectVariable(variableName, variablesName, index, maxCount = undefined) {
        let stateDiff = {};
        let multiselection = Array.isArray(this.state[variableName]);
        if (!multiselection) {
            stateDiff = {
                [variableName]: this.state[variablesName][index],
            };
        } else {
            let selectedVariables = Array.from(this.state[variableName]);
            let newVariable = this.state[variablesName][index];
            if (selectedVariables.includes(newVariable)) {
                selectedVariables = selectedVariables.filter(
                    (item) => item !== newVariable
                );
            } else {
                selectedVariables.push(newVariable);
            }
            let allVariables = Array.from(this.state[variablesName]);
            selectedVariables = selectedVariables.filter((variable) =>
                allVariables.includes(variable)
            );
            if (maxCount != null) {
                if (selectedVariables.length > maxCount) {
                    selectedVariables = selectedVariables.slice(
                        selectedVariables.length - maxCount
                    );
                }
            }
            stateDiff = {
                [variableName]: selectedVariables,
            };
        }

        this.setState(stateDiff, () => {
            if (
                this.state.step !== Step.selectCardContents &&
                this.state.step !== Step.selectSummaryStatisticsVariables
            ) {
                setTimeout(() => {
                    this.stepDown();
                }, 100);
            }
        });
    }

    getSubtitle() {
        let conditionsString = conditionsToString(
            Array.from(this.state.conditions)
        );
        if (
            this.state.step === Step.selectTable ||
            this.state.step === Step.selectDataScope
        )
            return "How do you want to examine your variables?";
        if (
            this.state.step > Step.selectTable &&
            this.state.step < Step.final
        ) {
            let subtitle = `For the data in ${
                this.state.dataScope ? this.state.dataScope.label : ""
            } ${this.state.selectedTable.label}`;
            if (this.state.step > Step.selectConditions) {
                if (conditionsString.length > 0)
                    subtitle += ` and under the conditions ${conditionsString}`;
            }
            if (this.state.step > Step.selectCardTitle)
                subtitle += ` I want to create cards with title: ${this.state.titleVariable}`;
            if (this.state.step > Step.selectCardContents)
                subtitle += ` and include the following in each card: ${this.state.selectedTargetVariables.join(
                    ", "
                )}`;
            if (this.state.step > Step.selectCategories)
                subtitle += ` and split cards into categories by ${this.state.categoryVariable}`;
            if (
                this.state.step > Step.selectSummaryStatisticsOperation &&
                this.state.operationVariable != null
            )
                subtitle += ` and display a summary in each board: ${this.state.operationVariable},`;
            if (this.state.step > Step.selectSummaryStatisticsVariables)
                subtitle += `${this.state.selectedSummaryVariables.join(", ")}`;

            return subtitle;
        }

        if (this.state.step === Step.final) {
            return (
                <>
                    <span>For the data </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.dataScope
                            ? this.state.dataScope.label.concat(" ")
                            : ""}
                    </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedTable.label}
                    </span>
                    {conditionsString.length > 0 && (
                        <>
                            <span> and under the conditions </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {conditionsString}
                            </span>
                        </>
                    )}
                    <span> I want to create cards with title: </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.titleVariable ?? ""}
                    </span>
                    <span> and include the following in each card </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedTargetVariables.join(", ")}
                    </span>
                    <span> and split cards into categories by </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.categoryVariable}
                    </span>
                    {this.state.operationVariable != null &&
                        this.state.summaryVariables != null &&
                        this.state.summaryVariables.length !== 0 && (
                            <>
                                <span>
                                    {" "}
                                    and display a summary in each board:{" "}
                                </span>
                                <span
                                    style={{
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-secondary-text-color"
                                        ),
                                    }}
                                >
                                    {AggregateFunctionsAliases[
                                        this.state.operationVariable
                                    ] ?? ""}
                                </span>
                                <span
                                    style={{
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-secondary-text-color"
                                        ),
                                    }}
                                >
                                    {this.state.summaryVariables.join(", ")}
                                </span>
                            </>
                        )}
                    <span>.</span>
                </>
            );
        }
        return "";
    }
    getFooterTitle() {
        switch (this.state.step) {
            case Step.initial:
                return "... For the data in...";
            case Step.selectTable:
                return "... and under the conditions...";
            case Step.selectConditions:
                return "... I want to create cards with title ...";
            case Step.selectCardTitle:
                return "... and include the following in each card ...";
            case Step.selectCardContents:
                return "... and split cards into categories by ...";
            case Step.selectCategories:
                return "... (optional) and display a summary in each board ...";
            case Step.selectSummaryStatisticsOperation:
                return "... of ...";
            default:
                return "";
        }
    }
    stepDown() {
        if (this.state.step === Step.selectTable && !this.state.selectedTable) {
            return;
        }
        if (
            this.state.step === Step.selectCardContents &&
            !this.state.selectedTargetVariables
        ) {
            return;
        }
        let delta = 1;
        if (
            this.state.step === Step.selectSummaryStatisticsOperation &&
            !this.state.summaryVariables
        )
            delta = 2;
        if (this.state.step + delta < Step.final) {
            this.setState((state) => ({
                previousStep: state.step,
                step: state.step + delta,
            }));
        } else {
            this.showFinalStep();
        }
    }

    stepUp() {
        let step = this.state.step;
        let delta = 1;
        if (step > Step.initial)
            this.setState({ animationDirection: "bottom" }, () => {
                this.setState({
                    step: step - delta,
                });
            });
    }

    render() {
        return (
            <div
                className="dashboard-rect-journey-focus"
                tabIndex="0"
                style={{
                    height: 570,
                    overflow: "hidden",
                    display: !this.props.collapsed ? "block" : "none",
                }}
                onClick={() => {
                    if (this.state.step === Step.initial) this.stepDown();
                }}
                onKeyDown={(evt) => {
                    if (evt.key === "Escape") {
                        this.props.onClose();
                    }
                    if (evt.key === "ArrowDown") {
                        if (
                            this.state.step > Step.initial &&
                            this.state.step < Step.final
                        )
                            this.stepDown();
                        evt.preventDefault();
                    }
                    if (evt.key === "ArrowLeft") {
                        if (this.state.step === Step.final) {
                            this.back();
                        }
                    }
                    if (evt.key === "ArrowRight") {
                        this.showFinalStep();
                    }
                    if (evt.key === "ArrowUp") {
                        if (this.state.step < Step.final) this.stepUp();
                        evt.preventDefault();
                    }
                    if (evt.key === "p") {
                        if (this.state.step === Step.final) {
                            this.saveFinding();
                        }
                    }
                    if (evt.key === "d") {
                        if (this.state.step === Step.final) {
                            evt.preventDefault();
                            this.showRecipesPopup();
                        }
                    }

                    let variableInfo = this.state.step2VariablesMap[
                        this.state.step
                    ];
                    if (variableInfo) {
                        let variablesLength = this.state[variableInfo[1]]
                            .length;
                        let variableIndex = evt.keyCode - 65;
                        if (
                            variableIndex >= 0 &&
                            variableIndex < variablesLength
                        ) {
                            this.selectVariable(
                                variableInfo[0],
                                variableInfo[1],
                                variableIndex
                            );
                        }
                    }
                }}
            >
                <div
                    className="my-row"
                    style={{ justifyContent: "space-between", height: "100%" }}
                >
                    <div
                        className="flex-column"
                        style={{
                            height: "100%",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {this.buildHeader()}

                        <TransitionGroup
                            style={{
                                minHeight: "inherit",
                            }}
                        >
                            <CSSTransition
                                style={{
                                    height: "100%",
                                }}
                                key={this.state.step}
                                timeout={500}
                                classNames={"journeywizard-".concat(
                                    this.state.animationDirection || ""
                                )}
                            >
                                <div
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    {this.buildContent()}
                                </div>
                            </CSSTransition>
                        </TransitionGroup>

                        <Footer
                            showArrow={
                                this.state.step > Step.initial &&
                                this.state.step < Step.final
                            }
                            title={this.getFooterTitle()}
                        />
                    </div>
                </div>
                {this.buildAddToRecipesPopup()}
            </div>
        );
    }
}

export { MainComponent };
