const switchStyleProps = {
	width: 48,
	height: 24,
	offColor: "#999999",
	onColor: "#3474B2",
	offHandleColor: "#FFFFFF",
	onHandleColor: "#FFFFFF",
	checkedIcon: false,
	uncheckedIcon: false,
};

export default switchStyleProps;