import React from "react";
import elements from "common/CanvasElements";
import SpreadSheetElement, {
    calculateSpreadSheetGridSize,
} from "./SpreadSheetElement";
import CanvasExtendedGridHeader from "./CanvasExtendedGridHeader";
import FlowChartProps, { CanvasEditedElement } from "./FlowChartProps";
import {
    CanvasNode,
    GridColorOptions,
    SelectionArea,
    CanvasSpreadSheetGrid,
    CanvasElement,
    isSpreadSheetGrid,
    CanvasSimpleSpreadSheetInput,
    CanvasGridHeader,
    NodeSize,
    ItemMetadata,
    InnerCanvasChanges,
    CanvasSubmitButton,
} from "common/Canvas";
import { observer } from "mobx-react";
import { defaultFontSize, defaultHeaderSize } from "../Constants";
import { mainStyle } from "common/MainStyle";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import DropdownGridMenu from "./DropdownGridMenu";
import ColorOptionsPopup from "../ColorOptionsPopup";
import SpreadSheetSaveDataPopup from "./SpreadSheetSaveDataPopup";
import { PortalType } from "../SectionPortal";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import Portal from "common/Portal";
import { isSimpleSpreadSheetInput, isBox } from "common/Canvas";
import mobileBreakpoint, {
    mobileAndTabletBreakpoint,
    openDelayedNodeMenuAfterTouch,
} from "common/utilities/UIResponsiveManager";
import cx from "classnames";
import styles from "./Moveable.module.css";
import { getTransformList } from "common/utilities/parseTransformString";

import {
    makeMoveable,
    DraggableProps,
    ResizableProps,
    RotatableProps,
    Rotatable,
    Draggable,
    Resizable,
    OnDrag,
} from "react-moveable";
import OutsideAlerter from "common/OutsideAlerter";
import { BackgroundMode } from "common/CanvasUserApi";
import SpreadSheetElementHeader from "./SpreadSheetElementHeader";
import { snapElementToPoints } from "../Snap";

const Moveable = makeMoveable<DraggableProps & ResizableProps & RotatableProps>(
    [Draggable, Resizable, Rotatable]
);

const defaultDivider = mainStyle
    .getPropertyValue("--slide-spreadsheet-divider")
    .trim()
    .split(" ");
const defaultGridOptions: GridColorOptions = {
    borderShadow: false,
    fillColor: mainStyle
        .getPropertyValue("--slide-spreadsheet-content-color")
        .trim(),
    textColor: mainStyle
        .getPropertyValue("--slide-spreadsheet-default-text-color")
        .trim(),
    borderColor: defaultDivider[defaultDivider.length - 1],
};

interface CommonSpreadSheetProps extends FlowChartProps {
    selectionArea: SelectionArea | undefined;
    onSelectArea: (
        area: SelectionArea | undefined,
        allowFontColorChanging: boolean
    ) => void;
    scale: number;
    editedGridHeader: CanvasExtendedGridHeader | undefined;
    currentSimpleEdit: any;
    onChangeEditedNode: (node: CanvasEditedElement) => void;
    onKeepSimpleEditChanges: () => void;
    safeSelectByMetadata: (metadata: ItemMetadata, focus?: boolean) => void;
    onItemContextMenu: (evt: any, node: CanvasElement) => void;
    onHeaderContextMenu: (
        evt: any,
        row: number,
        col: number,
        gridId: string
    ) => void;
    onHideContextMenu: () => void;
    onStartEditHeader: (header: CanvasExtendedGridHeader) => void;
    onDeleteGrid: (gridId: string) => void;
    onOpenBottomPortal: (portalType: PortalType, options: any) => void;
    onExpandSpreadSheetNode: (
        node: CanvasElement | CanvasSubmitButton,
        evt: MouseEvent
    ) => void;
}

interface SpreadSheetProps extends CommonSpreadSheetProps {
    gridId: string;
    moduleTitle: string;
    rootDataTestId: string;
}

interface State {
    dropdownOpened: boolean;
    showStreamButtonsAdvancedOptions: boolean;
    drag: boolean;
    hovered: boolean;
    focused: boolean;
    saveDataSpreadSheetGrid: CanvasSpreadSheetGrid | undefined;
    saveDataSpreadSheetNodes: CanvasSimpleSpreadSheetInput[];
    showColorOptions: GridColorOptions | undefined;
    popupMessage: string;
    popupStatus: PopupStatus | null;
}

@observer
class SpreadSheetElementWrapper extends CanvasInteractionComponent<
    SpreadSheetProps,
    State
> {
    private moveableRef = React.createRef<any>();
    private innerRef = React.createRef<HTMLDivElement>();

    constructor(props: SpreadSheetProps) {
        super(props);
        this.state = {
            dropdownOpened: false,
            showStreamButtonsAdvancedOptions: false,
            showColorOptions: undefined,
            drag: false,
            hovered: false,
            focused: false,
            saveDataSpreadSheetGrid: undefined,
            saveDataSpreadSheetNodes: [],
            popupStatus: null,
            popupMessage: "",
        };
        this.onChangeEditedNode = this.onChangeEditedNode.bind(this);
        this.afterApplyFormat = this.afterApplyFormat.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSimpleEditInsertOuterId = this.onSimpleEditInsertOuterId.bind(
            this
        );
        this.onItemContextMenu = this.onItemContextMenu.bind(this);
        this.onNodeClick = this.onNodeClick.bind(this);
        this.onHeaderContextMenu = this.onHeaderContextMenu.bind(this);
        this.onStartEditHeader = this.onStartEditHeader.bind(this);
        this.onStartEditNode = this.onStartEditNode.bind(this);
        this.onChangeEditedHeader = this.onChangeEditedHeader.bind(this);
    }

    private onChangeEditedNode(node: CanvasElement): void {
        this.trackNewPerformance(elements.spreadsheet);
        this.props.onStartSimpleEditing(node);
    }

    private afterApplyFormat() {
        this.setState({
            popupStatus: PopupStatus.Success,
        });
    }
    private onSort(column: number) {
        let element = elements.spreadsheet;
        let gridElement = this.props.canvasTreeStore.gridsState.get(
            this.props.gridId
        )! as CanvasSpreadSheetGrid;
        this.trackNewPerformance(element);

        this.props.onClearEditing();
        this.props.canvasTreeStore.sortGridAction(
            gridElement.id,
            column,
            gridElement.lastSortedColumn === column &&
                gridElement.lastSortDirection != null
                ? -1 * gridElement.lastSortDirection
                : -1
        );
    }
    private onChange(props: Partial<CanvasSpreadSheetGrid>) {
        let element = elements.spreadsheet;
        let gridElement = this.props.canvasTreeStore.gridsState.get(
            this.props.gridId
        )! as CanvasSpreadSheetGrid;
        this.trackNewPerformance(element);
        this.props.canvasTreeStore.updateGridAction(
            gridElement.id,
            props as any
        );
    }

    private onSimpleEditInsertOuterId(node: CanvasNode) {
        let element = elements.spreadsheet;

        this.trackNewPerformance(element);
        this.props.onSimpleEditInsertOuterId(node as CanvasElement);
    }

    private onItemContextMenu(evt: any, node: CanvasElement) {
        let element = elements.spreadsheet;

        this.trackNewPerformance(element);
        this.props.onItemContextMenu(evt, node);
    }
    private onNodeClick(evt: any, node: CanvasElement) {
        let element = elements.spreadsheet;

        this.trackNewPerformance(element);
        let connectedCards = node.popups || [];
        let connectedLinks = node.links || [];
        if (
            this.props.live &&
            (connectedCards.length > 0 || connectedLinks.length > 0)
        ) {
            this.props.onExpandSpreadSheetNode(node, evt);
            return;
        }
    }
    private onHeaderContextMenu(evt: number, row: number, col: number) {
        let element = elements.spreadsheet;
        let gridElement = this.props.canvasTreeStore.gridsState.get(
            this.props.gridId
        )! as CanvasSpreadSheetGrid;
        this.trackNewPerformance(element);
        this.props.onHeaderContextMenu(evt, row, col, gridElement.id);
    }
    private startSelectArea(
        row: number,
        col: number,
        gridElement: CanvasSpreadSheetGrid
    ): void {
        this.props.onSelectArea(
            {
                top: row,
                bottom: row,
                left: col,
                right: col,
                gridId: gridElement.id,
            },
            this.props.canWrite &&
                (!this.props.live || (gridElement.unlocked ?? false))
        );
    }

    private onStartEditNode(
        node: CanvasElement,
        focus?: boolean,
        internalEdit?: boolean
    ): void {
        let gridElement = this.props.canvasTreeStore.gridsState.get(
            this.props.gridId
        )! as CanvasSpreadSheetGrid;
        let colorOptions = this.colorOptions(gridElement);
        this.trackNewPerformance(elements.spreadsheet);
        this.props.onClearEditing();
        this.props.onStartSimpleEditing(node, focus, internalEdit);
        this.props.onChangeSelector(
            {
                color: node.fontColor || colorOptions.textColor,
                size: node.fontSize ?? colorOptions.fontSize ?? defaultFontSize,
            },
            this.props.selectionArea?.gridId === gridElement.id
        );
    }

    private onStartEditHeader(header: CanvasExtendedGridHeader): void {
        let gridElement = this.props.canvasTreeStore.gridsState.get(
            this.props.gridId
        )! as CanvasSpreadSheetGrid;
        let colorOptions = this.colorOptions(gridElement);
        this.trackNewPerformance(elements.spreadsheet);
        this.props.onClearEditing();
        this.props.onStartEditHeader(header);
        this.props.onChangeSelector(
            {
                color: header.fontColor || colorOptions.textColor,
                size:
                    header.fontSize ??
                    colorOptions.fontSize ??
                    defaultHeaderSize,
            },
            this.props.selectionArea?.gridId === gridElement.id
        );
    }
    private onChangeEditedHeader(header: CanvasExtendedGridHeader) {
        this.trackNewPerformance(elements.spreadsheet);
        this.props.onStartEditHeader(header);
    }
    private moveSelection(
        evt: React.KeyboardEvent<HTMLDivElement>,
        rowIncrement: number,
        colIncrement: number,
        gridElement: CanvasSpreadSheetGrid,
        _colorOptions: GridColorOptions,
        nodes: CanvasElement[]
    ) {
        evt.preventDefault();
        const { editedNode, canvasTreeStore } = this.props;
        this.props.onKeepSimpleEditChanges();
        let row: number;
        let col: number;
        if (editedNode != null) {
            row = Math.max(
                editedNode.nodePosition[canvasTreeStore.canvasViewMode].y +
                    rowIncrement,
                -1
            );
            col = Math.max(
                editedNode.nodePosition[canvasTreeStore.canvasViewMode].x +
                    colIncrement,
                -1
            );
        } else if (this.props.editedGridHeader != null) {
            row = Math.max(this.props.editedGridHeader.row + rowIncrement, -1);
            col = Math.max(this.props.editedGridHeader.col + colIncrement, -1);
        } else {
            return;
        }
        if (row < 0 && col < 0) {
            return;
        } else if (row >= 0 && col >= 0) {
            let node = nodes.find(
                (node) =>
                    node.nodePosition[canvasTreeStore.canvasViewMode].x ===
                        col &&
                    node.nodePosition[canvasTreeStore.canvasViewMode].y === row
            );
            if (node != null) {
                this.startSelectArea(row, col, gridElement);
                if (
                    this.props.canWrite &&
                    (!this.props.live || gridElement.unlocked)
                )
                    this.onStartEditNode(node, false, false);
            }
        } else {
            this.startSelectArea(row, col, gridElement);
            if (
                this.props.canWrite &&
                (!this.props.live || gridElement.unlocked)
            ) {
                let header: CanvasGridHeader | undefined;
                if (row < 0) {
                    header = gridElement.headers?.[col];
                } else {
                    header = gridElement.leftHeaders?.[row];
                }
                if (header != null) {
                    this.onStartEditHeader({
                        row: row,
                        col: col,
                        gridId: gridElement.id,
                        text: header.text ?? "",
                        fontColor: header.fontColor ?? undefined,
                        fontSize:
                            header.fontSize != null
                                ? Number(header.fontSize)
                                : undefined,
                    });
                }
            }
        }
    }

    private onKeyDown(
        evt: React.KeyboardEvent<HTMLDivElement>,
        gridElement: CanvasSpreadSheetGrid,
        colorOptions: GridColorOptions,
        nodes: CanvasElement[]
    ): void {
        if (evt.ctrlKey || evt.metaKey) {
            return;
        }
        evt.stopPropagation();
        if (
            evt.key === "Escape" ||
            evt.key === "Delete" ||
            evt.key === "Backspace"
        ) {
            evt.currentTarget.blur();
            if (!this.props.selectionArea) return;
            const { top, bottom, left, right } = this.props.selectionArea;

            this.props.canvasTreeStore.clearBySelectionAreaAction(
                this.props.selectionArea
            );

            if (top !== bottom || left !== right) {
                this.trackNewPerformance(elements.spreadsheet);
                this.props.onChangeEditedNode({
                    ...this.props.editedNode,
                    metric: "",
                } as CanvasElement);
            } else {
                this.onChangeEditedNode({
                    ...this.props.editedNode,
                    metric: "",
                } as CanvasElement);
            }
            return;
        }
        if (evt.key === "Enter" && !evt.shiftKey) {
            const { editedNode } = this.props;
            if (!editedNode) return;
            evt.preventDefault();

            let gridElement = this.props.canvasTreeStore.gridsState.get(
                this.props.gridId
            )! as CanvasSpreadSheetGrid;

            let rowShift = 1;
            let colShift = 0;

            let currentRow = editedNode!.nodePosition[
                this.props.canvasTreeStore.canvasViewMode
            ].y;

            if (currentRow >= gridElement.rows - 1) {
                colShift += 1;
                rowShift -= gridElement.rows;
            }

            this.moveSelection(
                evt,
                rowShift,
                colShift,
                gridElement,
                colorOptions,
                nodes
            );

            return;
            // this.props.onKeepSimpleEditChanges();

            // let row = this.props.editedNode!.y;
            // let col = this.props.editedNode!.x;
            // if (row < this.props.spreadSheetGrid.rows - 1) {
            //     row += 1;
            // } else if (col < this.props.spreadSheetGrid.cols - 1) {
            //     col += 1;
            //     row = 0;
            // }
            // if (
            //     col === this.props.editedNode!.x &&
            //     row === this.props.editedNode!.y
            // ) {
            //     evt.currentTarget.blur();
            //     this.props.onClearEditing();
            //     return;
            // }
            // let node = this.props.nodes.find(
            //     (node) => node.x === col && node.y === row
            // );
            // if (node != null) {
            //     this.startSelectArea(row, col);

            //     this.props.onStartEditNode(node);
            // }
        }
        switch (evt.key) {
            case "ArrowUp":
                this.moveSelection(
                    evt,
                    -1,
                    0,
                    gridElement,
                    colorOptions,
                    nodes
                );
                break;
            case "ArrowDown":
                this.moveSelection(evt, 1, 0, gridElement, colorOptions, nodes);
                break;
            case "ArrowLeft":
                this.moveSelection(
                    evt,
                    0,
                    -1,
                    gridElement,
                    colorOptions,
                    nodes
                );
                break;
            case "ArrowRight":
                this.moveSelection(evt, 0, 1, gridElement, colorOptions, nodes);
                break;
            case "Tab":
                this.moveSelection(
                    evt,
                    0,
                    evt.shiftKey ? -1 : 1,
                    gridElement,
                    colorOptions,
                    nodes
                );
                break;
            case "Control":
                break;
            case "Enter":
                break;
            case "Shift":
                break;
            default:
                if (this.props.editedNode) {
                    let editedNode = Object.assign({}, this.props.editedNode);
                    editedNode.metric = evt.key.length === 1 ? evt.key : "";
                    editedNode.focus = true;
                    editedNode.internalEdit = true;
                    this.onChangeEditedNode(editedNode);
                    setTimeout(() => {
                        let currentSimpleEdit = this.props.currentSimpleEdit
                            .current;
                        if (currentSimpleEdit != null) {
                            currentSimpleEdit.focus({
                                preventScroll: true,
                            });
                            if (currentSimpleEdit.selectionStart != null)
                                currentSimpleEdit.selectionStart =
                                    editedNode.metric.length;
                            if (currentSimpleEdit.selectionEnd != null)
                                currentSimpleEdit.selectionEnd =
                                    editedNode.metric.length;
                        }
                    }, 0);
                }
                break;
        }
    }

    private colorOptions(gridElement: CanvasSpreadSheetGrid) {
        return gridElement.colorOptions ?? defaultGridOptions;
    }

    componentDidMount(): void {
        let gridElement = this.props.canvasTreeStore.gridsState.get(
            this.props.gridId
        )! as CanvasSpreadSheetGrid;
        let innerRef = this.innerRef.current;
        if (innerRef != null) {
            innerRef.setAttribute("type", "gridsState");
            if (gridElement.groupId != null)
                innerRef.setAttribute("groupId", gridElement.groupId);
            else {
                innerRef.removeAttribute("groupId");
            }
            innerRef.setAttribute("id", String(this.props.gridId));
            innerRef.setAttribute("data-test-id", this.props.rootDataTestId);
        }
    }

    componentDidUpdate(_prevProps: SpreadSheetProps): void {
        this.moveableRef.current?.updateRect();
    }

    render() {
        const { canvasTreeStore } = this.props;
        const {
            canvasViewMode,
            mobileViewWasEdited,
            slideWidthRatio,
        } = canvasTreeStore;
        let element = elements.spreadsheet;
        let gridElement = this.props.canvasTreeStore.gridsState.get(
            this.props.gridId
        )! as CanvasSpreadSheetGrid;
        const selected =
            this.props.selectedMetadata?.length === 1 &&
            this.props.selectedMetadata?.[0]?.type === "gridsState" &&
            this.props.selectedMetadata?.[0]?.id === this.props.gridId;

        let spreadSheetContainerSize: NodeSize | undefined;
        if (gridElement.containerSize != null) {
            spreadSheetContainerSize = gridElement.containerSize;
        } else {
            spreadSheetContainerSize = calculateSpreadSheetGridSize(
                gridElement,
                canvasTreeStore
            );
        }
        let spreadSheetContainerScaledSize = {
            height:
                spreadSheetContainerSize[canvasViewMode].height *
                this.props.scale,
            width:
                spreadSheetContainerSize[canvasViewMode].width *
                this.props.scale,
        };

        let position = {
            x: gridElement.nodePosition[canvasViewMode].x * this.props.scale,
            y: gridElement.nodePosition[canvasViewMode].y * this.props.scale,
        };

        let sortedNodes = this.props.canvasTreeStore.getSortedGridNodes(
            gridElement.id
        );

        if (sortedNodes.length < gridElement.rows * gridElement.cols) {
            return null;
        }

        let colorOptions: GridColorOptions = this.colorOptions(gridElement);
        return (
            <OutsideAlerter
                onReject={() => {
                    if (
                        this.props.editedNode?.gridId === this.props.gridId ||
                        this.props.selectionArea?.gridId === this.props.gridId
                    ) {
                        setTimeout(() => {
                            this.props.safeSelectByMetadata({
                                groupId: null,
                                id: "",
                                type: "gridsState",
                            });
                            this.props.onSelectArea(undefined, false);
                        }, 0);
                    }
                    this.setState({
                        focused: false,
                    });
                }}
            >
                <>
                    <div
                        onMouseEnter={() => {
                            this.setState({ hovered: true });
                        }}
                        onMouseLeave={(evt) => {
                            let preventMouseLeave = (evt.relatedTarget as any)?.getAttribute?.(
                                "prevent-mouse-leave"
                            );
                            if (preventMouseLeave !== "true") {
                                this.setState({ hovered: false });
                            }
                        }}
                        onTouchStart={() => {
                            openDelayedNodeMenuAfterTouch(
                                () => {
                                    this.setState({ focused: true });
                                },
                                () => {
                                    this.setState({ focused: false });
                                }
                            );
                        }}
                        onMouseDown={(e: any) => {
                            this.setState({ focused: true });
                            if (
                                this.props.editedNode ||
                                !this.props.selectedMetadata
                            ) {
                                if (this.props.editedGridHeader) return;
                                this.props.onClearEditing(true, true);
                                this.props.safeSelectByMetadata({
                                    groupId: null,
                                    id: this.props.gridId,
                                    type: "gridsState",
                                });
                                this.props.onSelectArea(undefined, false);
                            }
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                left: position.x,
                                top: position.y - 45,
                                width: spreadSheetContainerScaledSize.width,
                                height: spreadSheetContainerScaledSize.height,
                                zIndex: gridElement.zIndex ?? 50,
                            }}
                        >
                            <div
                                data-test-id={`${this.props.rootDataTestId}-menucontainer`}
                                style={{
                                    display:
                                        !this.state.drag && this.props.canWrite
                                            ? "block"
                                            : "none",
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <SpreadSheetElementHeader
                                    rootDataTestId={this.props.rootDataTestId}
                                    canvasTreeStore={this.props.canvasTreeStore}
                                    hovered={this.state.hovered}
                                    currentModuleId={this.props.currentModuleId}
                                    colorOptions={colorOptions}
                                    selectionArea={
                                        this.props.selectionArea?.gridId ===
                                        gridElement.id
                                            ? this.props.selectionArea
                                            : undefined
                                    }
                                    scale={this.props.scale}
                                    spreadSheetGrid={gridElement}
                                    nodes={sortedNodes}
                                    live={this.props.live}
                                    sharedPolicy={this.props.sharedPolicy}
                                    setShowStreamButtonsAdvancedOptions={() => {
                                        this.setState({
                                            showStreamButtonsAdvancedOptions: !this
                                                .state
                                                .showStreamButtonsAdvancedOptions,
                                        });
                                    }}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                left: position.x,
                                top: position.y,
                                width: spreadSheetContainerScaledSize.width,
                                height: spreadSheetContainerScaledSize.height,
                                zIndex: gridElement.zIndex ?? 50,
                            }}
                        >
                            {!this.state.drag &&
                                (this.state.focused ||
                                    this.state.dropdownOpened ||
                                    // The hamburger menu has to appear as soon as the node gets created
                                    selected) &&
                                !this.props.live &&
                                this.props.canWrite && (
                                    <Portal
                                        rootNode={
                                            this.props.htmlElementsRootRef
                                        }
                                    >
                                        <div
                                            data-test-id={`${this.props.rootDataTestId}-menucontainer`}
                                        >
                                            <DropdownGridMenu
                                                rootMenuTestId={`${this.props.rootDataTestId}-menu`}
                                                {...this.props}
                                                onToggle={(show) => {
                                                    this.setState({
                                                        dropdownOpened: show,
                                                    });
                                                }}
                                                sharedPolicy={
                                                    this.props.sharedPolicy
                                                }
                                                grid={gridElement}
                                                customStyle={{
                                                    zIndex: 999,
                                                    left:
                                                        position.x +
                                                        spreadSheetContainerScaledSize.width,
                                                    top: position.y,
                                                    position: "absolute",
                                                    width:
                                                        20 * this.props.scale,
                                                    height:
                                                        20 * this.props.scale,
                                                }}
                                                element={element}
                                                onSaveData={() => {
                                                    this.setState({
                                                        saveDataSpreadSheetGrid: gridElement,
                                                        saveDataSpreadSheetNodes: sortedNodes as CanvasSimpleSpreadSheetInput[],
                                                    });
                                                }}
                                                onOpenColorOptions={() => {
                                                    this.setState({
                                                        showColorOptions: colorOptions,
                                                    });
                                                }}
                                                onOpenBottomPortal={
                                                    this.props
                                                        .onOpenBottomPortal
                                                }
                                                onDeleteGrid={
                                                    this.props.onDeleteGrid
                                                }
                                                onClearEditing={
                                                    this.props.onClearEditing
                                                }
                                            />
                                        </div>
                                    </Portal>
                                )}
                        </div>
                        <div
                            style={{
                                zIndex: gridElement.zIndex ?? 50,
                                width: spreadSheetContainerScaledSize.width + 5,
                                height:
                                    spreadSheetContainerScaledSize.height + 5,
                                top: position.y,
                                left: position.x,
                                position: "absolute",
                                border: this.state.hovered
                                    ? "1.5px solid #39F"
                                    : "1.5px solid transparent",
                                boxShadow: colorOptions.borderShadow
                                    ? "0 6px 13px 0 rgba(21, 33, 56, 0.53)"
                                    : "none",
                            }}
                            ref={this.innerRef}
                            className="selectable-by-pointer dashboard-rect-spreadsheet"
                            onClick={(_evt) => {
                                if (this.state.drag) {
                                    this.setState({ drag: false });
                                }
                            }}
                            onContextMenu={(evt) => {
                                this.props.onContextMenu(evt, {
                                    id: gridElement.id,
                                    type: "gridsState",
                                });
                            }}
                            onKeyDown={(e) =>
                                this.onKeyDown(
                                    e,
                                    gridElement,
                                    colorOptions,
                                    sortedNodes
                                )
                            }
                        >
                            <SpreadSheetElement
                                rootDataTestId={this.props.rootDataTestId}
                                canvasTreeStore={this.props.canvasTreeStore}
                                showStreamButtonsAdvancedOptions={
                                    this.state.showStreamButtonsAdvancedOptions
                                }
                                moveSelection={this.moveSelection.bind(this)}
                                hovered={this.state.hovered}
                                onApplyFormat={this.afterApplyFormat}
                                currentModuleId={this.props.currentModuleId}
                                colorOptions={colorOptions}
                                selectionArea={
                                    this.props.selectionArea?.gridId ===
                                    gridElement.id
                                        ? this.props.selectionArea
                                        : undefined
                                }
                                onResize={this.props.onResize}
                                currentSimpleEdit={this.props.currentSimpleEdit}
                                moduleTitle={this.props.moduleTitle}
                                scale={this.props.scale}
                                spreadSheetGrid={gridElement}
                                nodes={sortedNodes}
                                live={this.props.live}
                                sharedPolicy={this.props.sharedPolicy}
                                canWrite={this.props.canWrite}
                                editedGridHeader={this.props.editedGridHeader}
                                editedNode={this.props.editedNode}
                                onSelectArea={this.props.onSelectArea}
                                onClearEditing={this.props.onClearEditing}
                                onChange={this.onChange}
                                onSort={this.onSort}
                                onSimpleEditInsertOuterId={
                                    this.onSimpleEditInsertOuterId
                                }
                                onItemContextMenu={this.onItemContextMenu}
                                onNodeClick={this.onNodeClick}
                                onHeaderContextMenu={this.onHeaderContextMenu}
                                onStartAdvancedEditing={
                                    this.props.onStartAdvancedEditing
                                }
                                onKeepSimpleEditChanges={
                                    this.props.onKeepSimpleEditChanges
                                }
                                onChangeEditedNode={this.onChangeEditedNode}
                                onChangeEditedHeader={this.onChangeEditedHeader}
                                onHideContextMenu={this.props.onHideContextMenu}
                                onStartEditHeader={this.onStartEditHeader}
                                onStartEditNode={this.onStartEditNode}
                            />
                        </div>

                        {this.state.saveDataSpreadSheetGrid &&
                            this.state.saveDataSpreadSheetNodes && (
                                <SpreadSheetSaveDataPopup
                                    canvasTreeStore={this.props.canvasTreeStore}
                                    currentModuleId={this.props.currentModuleId}
                                    spreadSheetGrid={
                                        this.state.saveDataSpreadSheetGrid
                                    }
                                    nodes={this.state.saveDataSpreadSheetNodes}
                                    onClose={() => {
                                        this.setState({
                                            saveDataSpreadSheetGrid: undefined,
                                            saveDataSpreadSheetNodes: [],
                                        });
                                    }}
                                />
                            )}
                        {this.state.showColorOptions != null && (
                            <ColorOptionsPopup
                                isSpreadSheet={true}
                                options={this.state.showColorOptions}
                                onClose={(apply, options) => {
                                    if (apply) {
                                        this.props.canvasTreeStore.updateGridAction(
                                            this.props.gridId,
                                            {
                                                colorOptions: options as GridColorOptions,
                                            }
                                        );
                                    }
                                    this.setState({
                                        showColorOptions: undefined,
                                    });
                                }}
                            />
                        )}
                        {this.state.popupStatus != null && (
                            <StatusPopup
                                onClose={() => {
                                    this.setState({
                                        popupMessage: "",
                                        popupStatus: null,
                                    });
                                }}
                                status={this.state.popupStatus}
                                message={this.state.popupMessage}
                            />
                        )}
                    </div>
                    {(!this.props.live || gridElement.unlocked) &&
                        this.props.canWrite && (
                            <Moveable
                                className={cx(
                                    styles.moveable,
                                    !selected && styles.moveableNotSelected
                                )}
                                checkInput={true}
                                key={this.props.gridId}
                                ref={this.moveableRef}
                                draggable={
                                    (!this.props.live &&
                                        !gridElement.unlocked) ||
                                    this.props.canWrite ||
                                    !mobileBreakpoint()
                                }
                                throttleDrag={0}
                                onDragStart={(e: any) => {
                                    const clickedElementClassName =
                                        e.inputEvent.target.className;
                                    const clickedElementTag =
                                        e.inputEvent.target.tagName;
                                    const parentNodeClassName =
                                        e.inputEvent.target.parentNode
                                            .parentNode.className;
                                    if (
                                        clickedElementTag === "SPAN" ||
                                        clickedElementClassName?.includes?.(
                                            "cancel-drag"
                                        ) ||
                                        parentNodeClassName?.includes?.(
                                            "cancel-drag"
                                        )
                                    ) {
                                        e.stopDrag();
                                    }
                                }}
                                onDrag={({
                                    target,
                                    beforeDelta,
                                    beforeDist,
                                    left,
                                    top,
                                    right,
                                    bottom,
                                    delta,
                                    dist,
                                    transform,
                                    clientX,
                                    clientY,
                                }: OnDrag) => {
                                    target!.style.left = `${left}px`;
                                    target!.style.top = `${top}px`;
                                    if (!this.state.drag)
                                        this.setState({ drag: true });
                                    let nearestPoints = this.props.onRebuildSnapLine(
                                        {
                                            x: left,
                                            y: top,
                                            width:
                                                spreadSheetContainerScaledSize.width,
                                            height:
                                                spreadSheetContainerScaledSize.height,
                                        },
                                        {
                                            type: "gridsState",
                                            id: gridElement.id,
                                            groupId: gridElement.groupId,
                                        }
                                    );
                                    let newPosition = snapElementToPoints(
                                        target.clientWidth,
                                        target.clientHeight,
                                        nearestPoints
                                    );
                                    if (newPosition.x != null) {
                                        target!.style.left = `${newPosition.x}px`;
                                    }
                                    if (newPosition.y != null) {
                                        target!.style.top = `${newPosition.y}px`;
                                    }
                                    this.props.onUpdateSelectionBounds?.();
                                }}
                                onDragEnd={({ target }) => {
                                    if (this.state.drag) {
                                        this.trackNewPerformance(element);
                                        this.props.onDeleteSnapLine();
                                        let x =
                                            parseFloat(target.style.left) /
                                            this.props.scale;

                                        let y =
                                            parseFloat(target.style.top) /
                                            this.props.scale;

                                        let deltaX =
                                            x -
                                            gridElement.nodePosition[
                                                canvasViewMode
                                            ].x;
                                        let deltaY =
                                            y -
                                            gridElement.nodePosition[
                                                canvasViewMode
                                            ].y;

                                        const newNodePosition = {
                                            ...gridElement.nodePosition,
                                            [canvasViewMode]: {
                                                x,
                                                y,
                                            },
                                        };

                                        if (!mobileViewWasEdited) {
                                            if (canvasViewMode === "desktop")
                                                newNodePosition["mobile"] = {
                                                    x:
                                                        (x * slideWidthRatio) /
                                                        2,
                                                    y,
                                                };
                                            else
                                                this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                        }

                                        let changes: InnerCanvasChanges = {};
                                        this.props.canvasTreeStore.updateGridAction(
                                            this.props.gridId,
                                            {
                                                nodePosition: newNodePosition,
                                            },
                                            changes
                                        );
                                        this.props.canvasTreeStore.updateCanvasSizeAction(
                                            {
                                                x: x,
                                                y: y,
                                                width: spreadSheetContainerSize![
                                                    canvasViewMode
                                                ]!.width,
                                                height: spreadSheetContainerSize![
                                                    canvasViewMode
                                                ]!.height,
                                            }
                                        );
                                        this.props.onMoveGroupSelection(
                                            deltaX,
                                            deltaY,
                                            {
                                                id: this.props.gridId,
                                                type: "gridsState",
                                                groupId: gridElement.groupId,
                                            },
                                            false,
                                            changes
                                        );
                                        this.props.canvasTreeStore.saveChangesAction(
                                            changes,
                                            true,
                                            true,
                                            false,
                                            this.props.canvasTreeStore.backgroundsState.toJSON(),
                                            BackgroundMode.Update,
                                            false
                                        );
                                        this.setState({ focused: true });
                                        if (mobileAndTabletBreakpoint()) {
                                            openDelayedNodeMenuAfterTouch(
                                                () => {
                                                    this.setState({
                                                        focused: true,
                                                        drag: false,
                                                    });
                                                },
                                                () => {
                                                    this.setState({
                                                        focused: false,
                                                    });
                                                }
                                            );
                                        } else {
                                            this.setState({ drag: false });
                                        }
                                    }
                                }}
                                target={this.innerRef}
                                resizable={
                                    ((this.props.live &&
                                        gridElement.unlocked) ||
                                        this.props.canWrite) &&
                                    selected
                                }
                                rotatable={false}
                                onResize={(e) => {
                                    e.target.style.width = `${e.width}px`;
                                    e.target.style.height = `${e.height}px`;
                                    e.target.style.transform = e.afterTransform;
                                }}
                                onResizeEnd={({ target }) => {
                                    this.trackNewPerformance(element);
                                    const {
                                        canvasViewMode,
                                    } = this.props.canvasTreeStore;

                                    let transfromOptions = getTransformList(
                                        target.style.transform
                                    );
                                    let translatePosition = (
                                        transfromOptions["translate"] ??
                                        "0px, 0px"
                                    )
                                        .split(",")
                                        .map(
                                            (item) =>
                                                parseFloat(item) /
                                                this.props.scale
                                        );

                                    const x =
                                        parseFloat(target.style.left) /
                                            this.props.scale +
                                        translatePosition[0];
                                    const y =
                                        parseFloat(target.style.top) /
                                            this.props.scale +
                                        translatePosition[1];

                                    if (!mobileViewWasEdited) {
                                        this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                    }

                                    let newSize = {
                                        x,
                                        y,
                                        nodePosition: {
                                            ...gridElement.nodePosition,
                                            [canvasViewMode]: {
                                                x,
                                                y,
                                            },
                                        },
                                        nodeSize: {
                                            ...spreadSheetContainerSize,
                                            [canvasViewMode]: {
                                                width:
                                                    parseFloat(
                                                        target.style.width
                                                    ) / this.props.scale,
                                                height:
                                                    parseFloat(
                                                        target.style.height
                                                    ) / this.props.scale,
                                            },
                                        },
                                    };

                                    target.style.transform = "none";

                                    this.props.canvasTreeStore.updateGridAction(
                                        this.props.gridId,
                                        {
                                            ...newSize.nodePosition[
                                                canvasViewMode
                                            ],
                                            nodePosition: newSize.nodePosition,
                                            containerSize: {
                                                ...(newSize.nodeSize as NodeSize),
                                            },
                                        }
                                    );

                                    this.props.canvasTreeStore.updateCanvasSizeAction(
                                        {
                                            ...newSize.nodePosition[
                                                canvasViewMode
                                            ],
                                            width: newSize.nodeSize[
                                                canvasViewMode
                                            ]!.width,
                                            height: newSize.nodeSize[
                                                canvasViewMode
                                            ]!.height,
                                        }
                                    );
                                    this.props.onResize();
                                }}
                            ></Moveable>
                        )}
                </>
            </OutsideAlerter>
        );
    }
}

export default observer(function SpreadSheetElements(
    props: CommonSpreadSheetProps
) {
    let grids: JSX.Element[] = [];
    for (let key of props.canvasTreeStore.gridsState.keys()) {
        let gridUi: JSX.Element | null = null;
        let grid = props.canvasTreeStore.gridsState.get(key)!;

        let isHidden = false;
        props.canvasTreeStore.canvasTreeState.forEach((elem) => {
            if (
                (isSimpleSpreadSheetInput(elem) || isBox(elem)) &&
                elem.gridId === grid.id
            ) {
                if (
                    elem.nodeIsHidden &&
                    elem.nodeIsHidden[props.canvasTreeStore.canvasViewMode]
                ) {
                    isHidden = true;
                }
            }
        });
        if (isHidden) continue;

        if (isSpreadSheetGrid(props.canvasTreeStore.gridsState.get(key)!)) {
            gridUi = (
                <SpreadSheetElementWrapper
                    rootDataTestId={`spreadSheet-${grids.length + 1}`}
                    key={grid.id}
                    gridId={grid.id}
                    {...props}
                />
            );
            grids.push(gridUi);
        }
    }

    return <>{grids}</>;
});
