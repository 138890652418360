export function RGBAToHexA(r: number, g: number, b: number, a: number) {
    let rS = r.toString(16);
    let gS = g.toString(16);
    let bS = b.toString(16);
    let aS = Math.round(a * 255).toString(16);

    if (rS.length === 1) rS = "0" + rS;
    if (gS.length === 1) gS = "0" + gS;
    if (bS.length === 1) bS = "0" + bS;
    if (aS.length === 1) aS = "0" + aS;

    return "#" + rS + gS + bS + aS;
}

export function RGBAStrToRGBAComponents(rgba: string) {
    let sep = rgba.indexOf(",") > -1 ? "," : " ";
    let rgbaList = rgba.substr(5).split(")")[0].split(sep);
    let rgbaNumberList = new Array(rgbaList.length).fill(0);
    // Strip the slash if using space-separated syntax
    if (rgbaList.indexOf("/") > -1) rgbaList.splice(3, 1);

    for (let R in rgbaList) {
        let r = rgbaList[R];
        let castR = Number(R);
        let p = Number(r.substr(0, r.length));
        rgbaNumberList[castR] = p;
        
    }
    return rgbaNumberList;
}

export function hexToRGBComponents(h: string) {
    let r = "",
        g = "",
        b = "";

    // 3 digits
    if (h.length === 4 || h.length === 5) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];

        // 6 digits
    } else if (h.length === 7 || h.length === 9) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
    }

    return [Number(r), Number(g), Number(b)];
}