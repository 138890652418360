import { ReactComponent as Mic } from '../../assets/mic.svg';
import { ReactComponent as Keyboard } from '../../assets/keyboard.svg';
import { ReactComponent as Mail } from '../../assets/mail.svg';

import styles from './floatingButton.module.css';
import React from 'react';



interface IFloatingButton {
  micOn: boolean;
  toggle: () => void;
  onSubmit?: undefined | (() => void);
  waitingResponse: boolean;
}

const FloatingButton: React.FC<IFloatingButton> = ({micOn, toggle, onSubmit, waitingResponse}) => {  

  const renderButton = () => {
    if(waitingResponse)
      return 
    
    if(onSubmit) {
     return(
      <div className={styles.floatingBtnWrapperSend} onClick={onSubmit}>
        <Mail  width='1.5rem' height='1.5rem' />
      </div>
     )
    } else if(micOn){
      return(
        <div className={styles.floatingBtnWrapper} onClick={toggle}>
          <Keyboard width='1.5rem' height='1.5rem' />
        </div>
      )
    } else {
      return(
        <div className={styles.floatingBtnWrapper} onClick={toggle}>
          <Mic width='1.5rem' height='1.5rem' />
        </div>
      )
    }
  }

  return(
    <>
      {renderButton()}
    </>
  )
}

export default FloatingButton