import React, { Component } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceDot,
    ReferenceLine,
} from "recharts";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import CustomizedAxisTick from "./CustomizedAxisTick";
import axios from "common/ServerConnection";
import { mainStyle } from "common/MainStyle";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import HoldOutPredictionParametersChart from "common/graphics/HoldOutPredictionParametersChart";
import HoldOutPredictionStaticParametersChart from "common/graphics/HoldOutPredictionStaticParametersChart";
import { HorizontalBarsAxisTick } from "common/graphics/AxesTicks";
import { formatValue } from "common/utilities/FormatValue";
import { LegendElement } from "./LegendElement";
import PredictBlock from "./PredictBlock";
import { Button, ButtonGroup } from "react-bootstrap";
import { indexOfBiggest } from "common/utilities/NumericUtils";
import remoteModuleId from "common/remoteModuleId";
import _ from "lodash";
import { TooltipStyles } from "./TooltipStyles";

const defaultHeight = 525;
const defaultWidth = 576;

const TooltipType = Object.freeze({
    hidden: 0,
    regular: 1,
    lowerInterwal: 2,
    higherInterval: 3,
    meanPredicted: 4,
    meanTrue: 5,
});

const ScaleButtonDirection = Object.freeze({
    hidden: 0,
    left: 1,
    right: 2,
    both: 3,
});

const clustDiff = (clust1, clust2) => {
    const diffs = new Set();
    if (clust1 != null && clust2 != null) {
        clust1.forEach((clustItem, clustIndex) => {
            clustItem.forEach((item, index) => {
                let value = item.value;
                let otherValue = clust2[clustIndex]?.[index]?.value;
                if (value !== otherValue) diffs.add(item.name);
            });
        });
    }
    return diffs;
};

const CustomTooltip = (props) => {
    if (props.type === TooltipType.hidden) return null;
    if (props.item != null) {
        let newPayload;
        if (props.type === TooltipType.lowerInterwal) {
            newPayload = [
                {
                    name: "Lower Prediction Interval : ",
                    value: props.item.confidence_interval[0],
                },
            ];
        }
        if (props.type === TooltipType.higherInterval) {
            newPayload = [
                {
                    name: "Higher Prediction Interval : ",
                    value: props.item.confidence_interval[1],
                },
            ];
        }
        if (props.type === TooltipType.meanPredicted) {
            newPayload = [
                {
                    name: "Predicted Mean : ",
                    value: props.item.mean_predicted,
                },
                {
                    name: "Click to Recenter Scale.",
                },
            ];
        }
        if (props.type === TooltipType.meanTrue) {
            newPayload = [
                {
                    name: "True Mean : ",
                    value: props.item.mean_true,
                },
            ];
        }
        return (
            <DefaultTooltipContent
                {...props}
                active
                separator={""}
                label={undefined}
                payload={newPayload}
                wrapperStyle={{ ...props.wrapperStyle, visibility: "visible" }}
            />
        );
    }
    return <DefaultTooltipContent {...props} />;
};

class HoldOutPredictionBarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipType: TooltipType.hidden,
            errorMessages: {},
            independentVariables: [],
            continuousVariables: [],
            categoricalVariables: [],
            ivNames: [],
            independentVariablesFixed: [],
            clust: undefined,
            categoriesClust: undefined,
            hiddenContinuousVariables: [],
            hiddenCategoricalVariables: [],
            hiddenClust: undefined,
            hiddenCategoriesClust: undefined,
            ...this.initialState(),
        };
        this.updateUserValues = this.updateUserValues.bind(this);
    }
    buildSignificanceLevelBlock(clustIndex) {
        let confidenceLevels = this.props.data.map((item) => {
            if (item.confidence_level == null)
                return this.props.confidenceLevel;
            return item.confidence_level;
        });
        let showIntervals = this.props.data.map((item) => {
            if (item.show_intervals == null) return this.props.showIntervals;
            return item.show_intervals;
        });
        let buttonFirstSelected =
            confidenceLevels[clustIndex] === 99 && showIntervals[clustIndex];
        let buttonSecondSelected =
            confidenceLevels[clustIndex] === 95 && showIntervals[clustIndex];
        let buttonThirdSelected =
            confidenceLevels[clustIndex] === 90 && showIntervals[clustIndex];
        let buttonForthSelected = !showIntervals[clustIndex];
        return (
            <div
                className="my-row"
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <span
                    style={{
                        fontFamily: "Arial",
                        fontSize: 12,
                        color: mainStyle.getPropertyValue(
                            "--exploration-tertiary-text-color"
                        ),
                        display: "block",
                        textAlign: "left",
                    }}
                >
                    {`Significance Level`}
                </span>
                <div
                    style={{
                        backgroundColor: "transparent",
                        marginLeft: "10px",
                        padding: "5px",
                    }}
                >
                    <ButtonGroup>
                        <Button
                            className={"btn-small-significance".concat(
                                buttonFirstSelected ? "-selected" : ""
                            )}
                            onClick={() => {
                                this.props.onConfidenceLevelChange(
                                    99,
                                    clustIndex
                                );
                                if (!showIntervals[clustIndex])
                                    this.props.onShowIntervalsChange(
                                        true,
                                        clustIndex
                                    );
                            }}
                        >
                            99%
                        </Button>
                        <Button
                            className={"btn-small-significance".concat(
                                buttonSecondSelected ? "-selected" : ""
                            )}
                            onClick={() => {
                                this.props.onConfidenceLevelChange(
                                    95,
                                    clustIndex
                                );

                                if (!showIntervals[clustIndex])
                                    this.props.onShowIntervalsChange(
                                        true,
                                        clustIndex
                                    );
                            }}
                        >
                            95%
                        </Button>
                        <Button
                            className={"btn-small-significance".concat(
                                buttonThirdSelected ? "-selected" : ""
                            )}
                            onClick={() => {
                                this.props.onConfidenceLevelChange(
                                    90,
                                    clustIndex
                                );

                                if (!showIntervals[clustIndex])
                                    this.props.onShowIntervalsChange(
                                        true,
                                        clustIndex
                                    );
                            }}
                        >
                            90%
                        </Button>
                        <Button
                            className={"btn-small-significance".concat(
                                buttonForthSelected ? "-selected" : ""
                            )}
                            onClick={() => {
                                if (showIntervals[clustIndex])
                                    this.props.onShowIntervalsChange(
                                        false,
                                        clustIndex
                                    );
                            }}
                        >
                            Off
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        );
    }
    static pointInInterval(point, interval) {
        return point >= interval[0] && point <= interval[1];
    }
    calculateChartInterval(item) {
        let minChartValue = Math.min(
            item.confidence_interval[0],
            item.confidence_interval[1],
            item.mean_predicted
        );
        let maxChartValue = Math.max(
            item.confidence_interval[0],
            item.confidence_interval[1],
            item.mean_predicted
        );
        if (this.props.showTrueValue) {
            minChartValue = Math.min(minChartValue, item.mean_true);
            maxChartValue = Math.max(maxChartValue, item.mean_true);
        }
        let maxInterval = item.max_interval;
        if (maxInterval != null) {
            maxChartValue = Math.max(
                maxChartValue,
                item.mean_predicted + maxInterval
            );
            minChartValue = Math.min(
                minChartValue,
                item.mean_predicted - maxInterval
            );
        }
        maxChartValue =
            maxChartValue + Math.abs(maxChartValue - minChartValue) * 0.1;
        minChartValue =
            minChartValue - Math.abs(maxChartValue - minChartValue) * 0.1;
        return [minChartValue, maxChartValue];
    }
    runPredict(clustIndex) {
        const urlParams = new URLSearchParams(window.location.search);
        let currentModuleId = urlParams.get("current_module_id");
        let errorMessages = Object.assign({}, this.state.errorMessages);
        errorMessages[clustIndex] = undefined;
        this.setState({ errorMessages: errorMessages });
        let ivNames = this.state.ivNames;
        let independentVariablesFixed = this.state.independentVariablesFixed;
        let item = this.props.data[clustIndex];
        let request = {
            data_table_idx: this.props.dataScopeId,
            model_id: item.model_id,
            module_id:
                currentModuleId != null
                    ? Number(currentModuleId)
                    : remoteModuleId,
        };
        let parameters = [];
        if (this.props.regressionOptions.intercept) parameters.push(1);
        let logs = [this.props.regressionOptions.iv.main_log].concat(
            this.props.regressionOptions.iv.other_log
        );
        let interactions = this.props.regressionOptions.iv.interactions;
        let allParameterNames = [this.props.regressionOptions.iv.main].concat(
            this.props.regressionOptions.iv.other
        );

        let restParametersOriginal = allParameterNames.map(
            (name, paramIndex) => {
                let value = 0;
                if (this.state.continuousVariables.includes(name)) {
                    value =
                        this.state.clust[clustIndex][
                            this.state.continuousVariables.indexOf(name)
                        ].value;
                } else if (this.state.categoricalVariables.includes(name)) {
                    let categoricalItem =
                        this.state.categoriesClust[clustIndex][
                            this.state.categoricalVariables.indexOf(name)
                        ];
                    value = categoricalItem.values.map((value) =>
                        value === categoricalItem.value ? 1 : 0
                    );
                } else if (
                    this.state.hiddenContinuousVariables.includes(name)
                ) {
                    value =
                        this.state.hiddenClust[clustIndex][
                            this.state.hiddenContinuousVariables.indexOf(name)
                        ].value;
                } else if (
                    this.state.hiddenCategoricalVariables.includes(name)
                ) {
                    let categoricalItem =
                        this.state.hiddenCategoriesClust[clustIndex][
                            this.state.hiddenCategoricalVariables.indexOf(name)
                        ];
                    value = categoricalItem.values.map((value) =>
                        value === categoricalItem.value ? 1 : 0
                    );
                } else {
                    let fixed = independentVariablesFixed[paramIndex];
                    if (!fixed) {
                        let valueIndex = this.props.ivNames.indexOf(name);
                        value = item.mean_iv[valueIndex];
                    } else {
                        let rawValues = ivNames.filter((otherName) =>
                            otherName.startsWith(`${name}%`)
                        );
                        let valuesFirstIndex = ivNames.indexOf(rawValues[0]);

                        value = item.mean_iv.slice(
                            valuesFirstIndex,
                            valuesFirstIndex + rawValues.length
                        );
                    }
                }
                if (Array.isArray(value)) return value;
                else return [value];
            }
        );
        let restParameters = restParametersOriginal.map((value, paramIndex) =>
            logs[paramIndex] ? value.map((item) => Math.log(item)) : value
        );
        if (interactions != null) {
            for (let interaction of interactions) {
                let firstIndex = allParameterNames.indexOf(interaction.var1);
                let secondIndex = allParameterNames.indexOf(interaction.var2);
                if (firstIndex >= 0 && secondIndex >= 0) {
                    for (let i = 0; i < restParameters[firstIndex].length; ++i)
                        for (
                            let j = 0;
                            j < restParameters[secondIndex].length;
                            ++j
                        ) {
                            restParameters.push(
                                restParameters[firstIndex][i] *
                                    restParameters[secondIndex][j]
                            );
                        }
                }
            }
        }
        restParametersOriginal = restParametersOriginal.flat();
        restParameters = restParameters.flat();

        parameters = parameters.concat(restParameters);
        request.parameters = parameters;
        request.one_hot_encode = false;
        if (item.confidence_level != null) {
            request.confidence_level = item.confidence_level / 100;
        } else {
            request.confidence_level = this.props.confidenceLevel / 100;
        }
        axios
            .post("/api/e/model_predict", request)
            .then((result) => {
                if (!result.data.success) {
                    let errorMessages = Object.assign(
                        {},
                        this.state.errorMessages
                    );
                    errorMessages[clustIndex] = result.data.error_msg;
                    this.setState({ errorMessages: errorMessages });
                } else {
                    let newUpdate = {
                        mean_iv: restParametersOriginal,
                        mean_predicted: this.props.regressionOptions.dv_log
                            ? Math.exp(result.data.result)
                            : result.data.result,
                    };
                    if (result.data.max_interval != null) {
                        newUpdate.max_interval = this.props.regressionOptions
                            .dv_log
                            ? Math.exp(result.data.max_interval)
                            : result.data.max_interval;
                    }
                    if (result.data.confidence_interval != null) {
                        newUpdate.confidence_interval = this.props
                            .regressionOptions.dv_log
                            ? result.data.confidence_interval.map((item) =>
                                  Math.exp(item)
                              )
                            : result.data.confidence_interval;
                    }
                    if (
                        newUpdate.confidence_interval == null &&
                        newUpdate.max_interval == null
                    ) {
                        item.chart_interval = this.calculateChartInterval({
                            ...item,
                            mean_predicted: newUpdate.mean_predicted,
                        });
                    }
                    if (
                        item.chart_interval == null &&
                        newUpdate.confidence_interval != null &&
                        newUpdate.max_interval != null
                    ) {
                        let minChartValue = Math.min(
                            newUpdate.confidence_interval[0] -
                                newUpdate.max_interval,
                            newUpdate.confidence_interval[1] +
                                newUpdate.max_interval,
                            newUpdate.mean_predicted
                        );
                        let maxChartValue = Math.max(
                            newUpdate.confidence_interval[0] -
                                newUpdate.max_interval,
                            newUpdate.confidence_interval[1] +
                                newUpdate.max_interval,
                            newUpdate.mean_predicted
                        );
                        if (this.props.showTrueValue) {
                            minChartValue = Math.min(
                                minChartValue,
                                item.mean_true
                            );
                            maxChartValue = Math.max(
                                maxChartValue,
                                item.mean_true
                            );
                        }
                        maxChartValue =
                            maxChartValue +
                            Math.abs(maxChartValue - minChartValue) * 0.1;
                        minChartValue =
                            minChartValue -
                            Math.abs(maxChartValue - minChartValue) * 0.1;

                        newUpdate.chart_interval = [
                            minChartValue,
                            maxChartValue,
                        ];
                    }
                    this.props.onNewResult(newUpdate, clustIndex);
                }
            })
            .catch((error) => {
                let errorMessages = Object.assign({}, this.state.errorMessages);
                errorMessages[clustIndex] = String(error);
                this.setState({ errorMessages: errorMessages });
                console.log(error);
            });
    }

    initialState(prevProps = undefined) {
        let newState = {};
        let regressionOptions = this.props.regressionOptions;
        let ivNames = this.props.ivNames;

        if (ivNames == null) {
            ivNames = [regressionOptions.iv.main]
                .concat(regressionOptions.iv.other)
                .filter((item) => item != null);
        }
        let independentVariablesFixed = [];
        if (
            regressionOptions.iv.main_fixed != null &&
            regressionOptions.iv.other_fixed != null
        )
            independentVariablesFixed = [regressionOptions.iv.main_fixed]
                .concat(regressionOptions.iv.other_fixed)
                .filter((item) => item != null);
        else independentVariablesFixed = new Array(ivNames.length).fill(false);
        let independentVariables = [regressionOptions.iv.main]
            .concat(regressionOptions.iv.other)
            .filter((item) => item != null);
        // if (this.props.showDynamicOptions != null) {
        //     independentVariablesFixed = independentVariablesFixed.filter(
        //         (item, index) =>
        //             this.props.showDynamicOptions[independentVariables[index]]
        //     );
        //     independentVariables = independentVariables.filter(
        //         (item) => this.props.showDynamicOptions[item]
        //     );
        // }
        let continuousVariables = independentVariables.filter(
            (item, index) =>
                !independentVariablesFixed[index] &&
                (this.props.showDynamicOptions == null ||
                    this.props.showDynamicOptions[item])
        );
        let categoricalVariables = independentVariables.filter(
            (item, index) =>
                independentVariablesFixed[index] &&
                (this.props.showDynamicOptions == null ||
                    this.props.showDynamicOptions[item])
        );
        let hiddenContinuousVariables = independentVariables.filter(
            (item, index) =>
                !independentVariablesFixed[index] &&
                this.props.showDynamicOptions != null &&
                !this.props.showDynamicOptions[item]
        );
        let hiddenCategoricalVariables = independentVariables.filter(
            (item, index) =>
                independentVariablesFixed[index] &&
                this.props.showDynamicOptions != null &&
                !this.props.showDynamicOptions[item]
        );
        if (
            prevProps == null ||
            !_.isEqual(prevProps.data, this.props.data) ||
            !_.isEqual(prevProps.userValues, this.props.userValues) ||
            !_.isEqual(prevProps.defaultValue, this.props.defaultValue) ||
            !_.isEqual(
                prevProps.showDynamicOptions,
                this.props.showDynamicOptions
            )
        ) {
            let [clust, hiddenClust] = [
                continuousVariables,
                hiddenContinuousVariables,
            ].map((variables) =>
                this.props.data.map((item, clustIndex) => {
                    let result = [];
                    variables.forEach((variable, index) => {
                        let valueIndex = ivNames.indexOf(variable);
                        let userValue = !this.props.defaultValue?.[variable]
                            ? this.props.userValues?.[clustIndex]?.[variable]
                            : undefined;
                        result.push({
                            name: variable,
                            value: userValue ?? item.mean_iv[valueIndex] ?? 0,
                        });
                    });

                    return result.filter((item) => item.name != null);
                })
            );
            let [categoriesClust, hiddenCategoriesClust] = [
                categoricalVariables,
                hiddenCategoricalVariables,
            ].map((variables) =>
                this.props.data.map((item, clustIndex) => {
                    let result = [];
                    variables.forEach((variable, index) => {
                        let rawValues = ivNames.filter((otherName) =>
                            otherName.startsWith(`${variable}%`)
                        );

                        let values = rawValues.map((value) => {
                            let percentIndex = value.indexOf("%");
                            if (percentIndex >= 0) {
                                value = value.slice(percentIndex + 1);
                            }
                            return JSON.parse(value);
                        });
                        if (values.length === 0) return;
                        let valuesFirstIndex = ivNames.indexOf(rawValues[0]);
                        let valueIndex = indexOfBiggest(
                            item.mean_iv.slice(
                                valuesFirstIndex,
                                valuesFirstIndex + values.length
                            )
                        );
                        let userValue = !this.props.defaultValue?.[variable]
                            ? this.props.userValues?.[clustIndex]?.[variable]
                            : undefined;
                        if (!values.includes(userValue)) userValue = undefined;
                        result.push({
                            name: variable,
                            values: values,
                            value: userValue ?? values[valueIndex],
                        });
                    });

                    return result.filter((item) => item.name != null);
                })
            );
            newState.clust = clust;
            newState.categoriesClust = categoriesClust;
            newState.hiddenClust = hiddenClust;
            newState.hiddenCategoriesClust = hiddenCategoriesClust;
            newState.currentVariable = undefined;
        }
        newState.independentVariables = independentVariables;
        newState.independentVariablesFixed = independentVariablesFixed;
        newState.ivNames = ivNames;
        newState.continuousVariables = continuousVariables;
        newState.categoricalVariables = categoricalVariables;
        newState.hiddenContinuousVariables = hiddenContinuousVariables;
        newState.hiddenCategoricalVariables = hiddenCategoricalVariables;
        return newState;
    }
    componentDidUpdate(prevProps) {
        let newState = this.initialState(prevProps);

        this.setState((state) => {
            if (!_.isMatch(state, newState)) {
                return newState;
            }
        });
    }
    updateUserValues(diff) {
        let userValuesDict = {};
        let clusters = [
            this.state.clust,
            this.state.categoriesClust,
            this.state.hiddenClust,
            this.state.hiddenCategoriesClust,
        ];
        for (let clust of clusters) {
            if (clust != null) {
                clust.forEach((clustItem, index) => {
                    let reducedClust = clustItem.reduce((result, item) => {
                        result[item.name] = item.value;
                        return result;
                    }, {});
                    userValuesDict[index] = {
                        ...userValuesDict[index],
                        ...reducedClust,
                    };
                });
            }
        }
        this.props.onNewUserValues(userValuesDict, diff);
    }

    buildHoldOutPredictionParametersChart() {
        if (this.props.dynamic)
            return (
                <HoldOutPredictionParametersChart
                    decimals={this.props.decimals}
                    categoriesClust={_.cloneDeep(this.state.categoriesClust)}
                    clust={_.cloneDeep(this.state.clust)}
                    continuousVariables={this.state.continuousVariables}
                    categoricalVariables={this.state.categoricalVariables}
                    onCategoriesClustChange={(categoriesClust) => {
                        const diff = clustDiff(
                            categoriesClust,
                            this.state.categoriesClust
                        );
                        this.setState(
                            { categoriesClust: categoriesClust },
                            () => {
                                this.updateUserValues(diff);
                            }
                        );
                    }}
                    onClustChange={(clust) => {
                        const diff = clustDiff(clust, this.state.clust);
                        this.setState({ clust: clust }, () => {
                            this.updateUserValues(diff);
                        });
                    }}
                    data={this.props.data}
                    showLeverBars={this.props.showLeverBars}
                />
            );
        else
            return (
                <HoldOutPredictionStaticParametersChart
                    decimals={this.props.decimals}
                    categoriesClust={this.state.categoriesClust}
                    clust={this.state.clust}
                    continuousVariables={this.state.continuousVariables}
                    categoricalVariables={this.state.categoricalVariables}
                    data={this.props.data}
                    showLeverBars={this.props.showLeverBars}
                />
            );
    }
    render() {
        var titleSize = "15px";
        var axisFontSize = "10px";
        let maxYaxisWidth = 60;
        if (this.props.data) {
            let lengths = this.props.data.map((item) => item.name.length);
            maxYaxisWidth = Math.max(maxYaxisWidth, Math.max(...lengths) * 10);
        }
        let transformedData = this.props.data.map((item) => {
            let transformedItem = Object.assign({}, item);

            if (transformedItem.chart_interval == null) {
                transformedItem.chart_interval =
                    this.calculateChartInterval(item);
            }
            return transformedItem;
        });
        let maxChartInterval = transformedData[0].chart_interval;
        for (let i = 1; i < transformedData.length; ++i) {
            maxChartInterval[0] = Math.min(
                maxChartInterval[0],
                transformedData[i].chart_interval[0]
            );
            maxChartInterval[1] = Math.max(
                maxChartInterval[1],
                transformedData[1].chart_interval[1]
            );
        }
        transformedData = transformedData.map((item) => {
            item.chart_interval = maxChartInterval;
            item.bar_interval = [
                Math.max(item.chart_interval[0], item.confidence_interval[0]),
                Math.min(item.chart_interval[1], item.confidence_interval[1]),
            ];
            item.show_bar =
                HoldOutPredictionBarChart.pointInInterval(
                    item.confidence_interval[0],
                    item.chart_interval
                ) ||
                HoldOutPredictionBarChart.pointInInterval(
                    item.confidence_interval[1],
                    item.chart_interval
                ) ||
                (item.confidence_interval[0] <= item.chart_interval[0] &&
                    item.confidence_interval[1] >= item.chart_interval[1]);
            item.show_scale_button =
                !HoldOutPredictionBarChart.pointInInterval(
                    item.confidence_interval[0],
                    item.chart_interval
                ) ||
                !HoldOutPredictionBarChart.pointInInterval(
                    item.confidence_interval[1],
                    item.chart_interval
                );
            if (item.show_scale_button) {
                if (
                    item.confidence_interval[0] <= item.chart_interval[0] &&
                    item.confidence_interval[1] >= item.chart_interval[1]
                )
                    item.scale_button_direction = ScaleButtonDirection.both;
                else
                    item.scale_button_direction =
                        item.confidence_interval[0] < item.chart_interval[0]
                            ? ScaleButtonDirection.left
                            : ScaleButtonDirection.right;
            } else {
                item.scale_button_direction = ScaleButtonDirection.hidden;
            }
            return item;
        });
        let chartWidth =
            transformedData.length > 0
                ? Math.floor(100 / transformedData.length)
                : undefined;
        let showIntervals = this.props.data.map((item) => {
            if (item.show_intervals == null) return this.props.showIntervals;
            return item.show_intervals;
        });
        return (
            <div
                className="element"
                style={{
                    overflowX: mobileBreakpoint() ? "visible" : "auto",
                    backgroundColor: "transparent",
                    position: "relative",
                    height: "100%",
                    //   overflowY: "hidden",
                    //  overflowY: mobileBreakpoint() ? "visible" : "auto",
                }}
                ref={(ref) => {
                    if (ref != null) {
                        let heightScale =
                            ref.parentElement.clientHeight / defaultHeight;

                        let widthScale =
                            ref.parentElement.clientWidth / defaultWidth;

                        let minScale = Math.min(heightScale, widthScale);
                        if (minScale < 1) {
                            ref.style.height = null;
                        } else {
                            ref.style.height = "100%";
                        }
                        for (let child of ref.childNodes) {
                            child.style.transform = `scale(${minScale})`;
                            child.style.transformOrigin = "left top";
                            let attribute =
                                child.getAttribute("graphicsContainer");
                            if (attribute != null) {
                                child.style.width = `calc(100%*${
                                    1 / minScale
                                })`;
                            }
                        }
                    }
                }}
            >
                <div
                    ref={(ref) => {
                        if (ref != null) {
                            ref.setAttribute("graphicsContainer", "true");
                        }
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Arial",
                            fontSize: titleSize,
                            color: mainStyle.getPropertyValue(
                                "--exploration-tertiary-text-color"
                            ),
                            paddingLeft: 30,
                            paddingBottom: 10,
                            display: "block",
                            textAlign: "left",
                        }}
                    >
                        Prediction
                    </span>
                    <div style={{ width: "100%", height: "100%" }}>
                        <div
                            className="my-row"
                            style={{
                                width: "100%",
                                height: "179px",
                            }}
                        >
                            {transformedData.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex-simple-column"
                                        style={{
                                            width: `calc(${chartWidth}%)`,
                                        }}
                                    >
                                        <div
                                            className="my-row"
                                            style={{ alignItems: "center" }}
                                        >
                                            <div
                                                style={{
                                                    width: "calc(100% - 200px)",
                                                }}
                                            >
                                                {item.show_scale_button &&
                                                    (item.scale_button_direction ===
                                                        ScaleButtonDirection.left ||
                                                        item.scale_button_direction ===
                                                            ScaleButtonDirection.both) && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "flex-start",
                                                            }}
                                                        >
                                                            <div
                                                                title="Click to Recenter Scale."
                                                                onClick={() => {
                                                                    this.props.onCenterItem(
                                                                        index,
                                                                        this.calculateChartInterval(
                                                                            item
                                                                        )
                                                                    );
                                                                }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={
                                                                        "/dist/img/data-exploration/chevron_green_back.png"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                {item.show_scale_button &&
                                                    (item.scale_button_direction ===
                                                        ScaleButtonDirection.right ||
                                                        item.scale_button_direction ===
                                                            ScaleButtonDirection.both) && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "flex-end",
                                                            }}
                                                        >
                                                            <div
                                                                title="Click to Recenter Scale."
                                                                onClick={() => {
                                                                    this.props.onCenterItem(
                                                                        index,
                                                                        this.calculateChartInterval(
                                                                            item
                                                                        )
                                                                    );
                                                                }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={
                                                                        "/dist/img/data-exploration/chevron_green.png"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                <div
                                                    className="my-row center-container"
                                                    style={{
                                                        width: "100%",
                                                        paddingBottom: "10px",
                                                    }}
                                                >
                                                    {this.props
                                                        .showTrueValue && (
                                                        <LegendElement
                                                            color={"#e24245"}
                                                            text={
                                                                "Actual Level"
                                                            }
                                                        />
                                                    )}
                                                    <LegendElement
                                                        color={"#05C985"}
                                                        text={"Predicted Level"}
                                                    />
                                                </div>
                                                <ResponsiveContainer
                                                    height={50}
                                                    key={index}
                                                >
                                                    <BarChart
                                                        data={[item]}
                                                        barSize={10}
                                                        layout="vertical"
                                                    >
                                                        <XAxis
                                                            domain={
                                                                item.chart_interval
                                                            }
                                                            stroke={mainStyle.getPropertyValue(
                                                                "--graphs-stroke-color"
                                                            )}
                                                            orientation="bottom"
                                                            type="number"
                                                            interval={0}
                                                            tickLine={true}
                                                            axisLine={true}
                                                            tick={
                                                                <CustomizedAxisTick
                                                                    formatValues={
                                                                        true
                                                                    }
                                                                    truncValues={
                                                                        false
                                                                    }
                                                                    fontSize={
                                                                        axisFontSize
                                                                    }
                                                                    dx={0}
                                                                    dy={8}
                                                                />
                                                            }
                                                        />
                                                        <YAxis
                                                            type="category"
                                                            width={
                                                                maxYaxisWidth
                                                            }
                                                            dataKey="name"
                                                            axisLine={false}
                                                            tickLine={false}
                                                            tick={
                                                                <HorizontalBarsAxisTick
                                                                    dx={0}
                                                                    dy={0}
                                                                />
                                                            }
                                                        />
                                                        <CartesianGrid
                                                            stroke={mainStyle.getPropertyValue(
                                                                "--graphs-stroke-color"
                                                            )}
                                                            horizontal={false}
                                                            strokeWidth={1}
                                                        />
                                                        <Tooltip
                                                            wrapperStyle={{
                                                                zIndex:
                                                                    10 - index,
                                                            }}
                                                            content={
                                                                <CustomTooltip
                                                                    type={
                                                                        this
                                                                            .state
                                                                            .tooltipType
                                                                    }
                                                                    item={item}
                                                                />
                                                            }
                                                            formatter={(
                                                                value,
                                                                name,
                                                                props
                                                            ) => {
                                                                return typeof value ===
                                                                    "number"
                                                                    ? formatValue(
                                                                          value
                                                                      ).join("")
                                                                    : value;
                                                            }}
                                                            cursor={false}
                                                            {...TooltipStyles()}
                                                        />

                                                        <Bar
                                                            fill={
                                                                showIntervals[
                                                                    index
                                                                ] &&
                                                                item.show_bar
                                                                    ? "#05C985"
                                                                    : "transparent"
                                                            }
                                                            fillOpacity={0.3}
                                                            strokeOpacity={0.3}
                                                            stroke={
                                                                showIntervals[
                                                                    index
                                                                ] &&
                                                                item.show_bar
                                                                    ? "#05C985"
                                                                    : "transparent"
                                                            }
                                                            barSize={4}
                                                            dataKey="bar_interval"
                                                        />
                                                        {HoldOutPredictionBarChart.pointInInterval(
                                                            item
                                                                .confidence_interval[0],
                                                            item.chart_interval
                                                        ) &&
                                                            showIntervals[
                                                                index
                                                            ] && (
                                                                <ReferenceLine
                                                                    x={
                                                                        item
                                                                            .confidence_interval[0]
                                                                    }
                                                                    strokeWidth={
                                                                        3
                                                                    }
                                                                    stroke="#05C985"
                                                                    onMouseOver={() => {
                                                                        this.setState(
                                                                            {
                                                                                tooltipType:
                                                                                    TooltipType.lowerInterwal,
                                                                            }
                                                                        );
                                                                    }}
                                                                    onMouseOut={() => {
                                                                        this.setState(
                                                                            {
                                                                                tooltipType:
                                                                                    TooltipType.hidden,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        {HoldOutPredictionBarChart.pointInInterval(
                                                            item
                                                                .confidence_interval[1],
                                                            item.chart_interval
                                                        ) &&
                                                            showIntervals[
                                                                index
                                                            ] && (
                                                                <ReferenceLine
                                                                    x={
                                                                        item
                                                                            .confidence_interval[1]
                                                                    }
                                                                    strokeWidth={
                                                                        3
                                                                    }
                                                                    stroke="#05C985"
                                                                    onMouseOver={() => {
                                                                        this.setState(
                                                                            {
                                                                                tooltipType:
                                                                                    TooltipType.higherInterval,
                                                                            }
                                                                        );
                                                                    }}
                                                                    onMouseOut={() => {
                                                                        this.setState(
                                                                            {
                                                                                tooltipType:
                                                                                    TooltipType.hidden,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        {HoldOutPredictionBarChart.pointInInterval(
                                                            item.mean_predicted,
                                                            item.chart_interval
                                                        ) && (
                                                            <ReferenceDot
                                                                x={
                                                                    item.mean_predicted
                                                                }
                                                                y={item.name}
                                                                r={9}
                                                                fill="#20293C"
                                                                strokeWidth={3}
                                                                stroke="#05C985"
                                                                onClick={() => {
                                                                    this.props.onCenterItem(
                                                                        index,
                                                                        this.calculateChartInterval(
                                                                            item
                                                                        )
                                                                    );
                                                                }}
                                                                onMouseOver={() => {
                                                                    this.setState(
                                                                        {
                                                                            tooltipType:
                                                                                TooltipType.meanPredicted,
                                                                        }
                                                                    );
                                                                }}
                                                                onMouseOut={() => {
                                                                    this.setState(
                                                                        {
                                                                            tooltipType:
                                                                                TooltipType.hidden,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        )}

                                                        {this.props
                                                            .showTrueValue &&
                                                            HoldOutPredictionBarChart.pointInInterval(
                                                                item.mean_true,
                                                                item.chart_interval
                                                            ) && (
                                                                <ReferenceDot
                                                                    x={
                                                                        item.mean_true
                                                                    }
                                                                    y={
                                                                        item.name
                                                                    }
                                                                    r={9}
                                                                    fill="#20293C"
                                                                    strokeWidth={
                                                                        3
                                                                    }
                                                                    stroke="#e24245"
                                                                    onMouseOver={() => {
                                                                        this.setState(
                                                                            {
                                                                                tooltipType:
                                                                                    TooltipType.meanTrue,
                                                                            }
                                                                        );
                                                                    }}
                                                                    onMouseOut={() => {
                                                                        this.setState(
                                                                            {
                                                                                tooltipType:
                                                                                    TooltipType.hidden,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                    </BarChart>
                                                </ResponsiveContainer>
                                                {this.props.dynamic &&
                                                    this.buildSignificanceLevelBlock(
                                                        index
                                                    )}
                                            </div>
                                            <div
                                                style={{
                                                    paddingLeft: "30px",
                                                    paddingRight: "30px",
                                                }}
                                            >
                                                <PredictBlock
                                                    name={
                                                        this.props
                                                            .dependentVariable
                                                    }
                                                    sampleSize={
                                                        item.sample_size
                                                    }
                                                    value={item.mean_predicted}
                                                    unit={item.units}
                                                    predictInterval={
                                                        item.confidence_interval
                                                    }
                                                    onCenterItemClick={() => {
                                                        this.props.onCenterItem(
                                                            index,
                                                            this.calculateChartInterval(
                                                                item
                                                            )
                                                        );
                                                    }}
                                                />
                                                {this.props.dynamic && (
                                                    <>
                                                        <Button
                                                            type="button"
                                                            className="btn btn-md btn-primary my-primary"
                                                            style={{
                                                                marginTop:
                                                                    "10px",
                                                                width: "112px",
                                                            }}
                                                            onClick={() => {
                                                                this.runPredict(
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            RUN
                                                        </Button>
                                                        {this.state
                                                            .errorMessages[
                                                            index
                                                        ] ? (
                                                            <span
                                                                style={{
                                                                    marginLeft: 10,
                                                                    fontFamily:
                                                                        "Roboto",
                                                                    fontSize:
                                                                        "12px",
                                                                    lineHeight:
                                                                        "14px",
                                                                    color: "red",
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .errorMessages[
                                                                        index
                                                                    ]
                                                                }
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {this.buildHoldOutPredictionParametersChart()}
                    </div>
                </div>
            </div>
        );
    }
}

export default HoldOutPredictionBarChart;
