import React, { Component } from "react";
import { mainStyle } from "common/MainStyle";
import { CanvasElement } from "common/Canvas";
import CanvasTreeStore from "../CanvasTreeStore";
import { defaultLabelSize, defaultFontSize } from "../Constants";

interface Props {
    rootDataTestId: string;
    node: CanvasElement;
    fontColor: string;
    scale: number;
    live: boolean;
    onDblClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onTouchStart?: (event: React.TouchEvent<HTMLDivElement>) => void;
}

class HTMLFlowchart extends Component<Props> {
    public render(): JSX.Element {
        let value = CanvasTreeStore.getValue(
            this.props.node,
            true,
            true
        );
        for (let output of this.props.node.additionalOutputs) {
            value += "\n";
            value += CanvasTreeStore.getValue(
                output as CanvasElement,
                true,
                true
            );
        }
        return (
            <div
                data-test-id={`${this.props.rootDataTestId}-textarea`}
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                }}
                onDoubleClick={this.props.onDblClick}
                onTouchStart={this.props.onTouchStart}
            >
                <span
                    className="content-regular-text unselectable"
                    style={{
                        fontSize:
                            (this.props.node.labelSize || defaultLabelSize) *
                            this.props.scale,
                        fontFamily: "Roboto",
                        color: this.props.node.name
                            ? this.props.node.labelColor ||
                              mainStyle.getPropertyValue(
                                  "--slide-flowchart-label-color"
                              )
                            : "#70889E",
                        textAlign: "center",
                    }}
                >
                    {this.props.node.name ||
                        (!this.props.live ? "Enter Title" : "")}
                </span>
                <span
                    className="content-regular-text unselectable"
                    style={{
                        fontSize:
                            (this.props.node.fontSize || defaultFontSize) *
                            this.props.scale,
                        fontFamily: "Roboto",
                        color: this.props.node.metric
                            ? this.props.fontColor
                            : "#70889E",
                        textAlign: "center",
                        whiteSpace: "pre-wrap",
                        lineHeight: 1,
                    }}
                >
                    {value}
                </span>
            </div>
        );
    }
}

export default HTMLFlowchart;