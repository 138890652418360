import React from "react";
import { SingleLineAxisTick } from "../../AxesTicks";
import CustomizedAxisTick from "../../CustomizedAxisTick";
import { colorList } from "../../LineColors";
import { formatValue } from "common/utilities/FormatValue";
import sections from "sections.json";
import {
    ComposedChart,
    Area,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";
import { mainStyle } from "common/MainStyle";
import {
    timeLabelFormatter,
    displayModeToDiffType,
} from "../../TimeChartUtils";
import { previewColors } from "../previewColors";
import { TooltipStyles } from "common/graphics/TooltipStyles";
import { TimeChartProps, TimeChartDot } from "./index";

export function TimeChart(props: TimeChartProps) {
    let { config, data, isTimeSeries, index, onDotClick } = props;
    let {
        ticksColor,
        axesLinesColor,
        nameColorMapping,
        linesCount,
        minYRange,
        maxYRange,
        tooltipColor,
        tooltipFontSize,
        ticksSize,
        ticksAndLabels,
    } = config;
    var xAxisFontSize: number =
        ticksAndLabels?.x?.size ?? ticksSize ??
        parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));
            
    var yAxisFontSize: number =
        ticksAndLabels?.y?.size ?? ticksSize ??
        parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

    let displayMode: keyof typeof displayModeToDiffType | "Date" = "Date";
    let dateFormat = {
        year: true,
        month: true,
        day: true,
    };

    let firstRawDate = data[0]?.value?.[index][0] ?? 0;
    const bgDarkColor =
        axesLinesColor ?? mainStyle.getPropertyValue("--graphs-stroke-color");
    let lines = null; // Plot lines
    let type: "number" | "category" = "category";
    if (
        data.length > 0 &&
        data[0].value?.[index].length > 0 &&
        isTimeSeries != null
    ) {
        if (typeof firstRawDate === "number") type = "number";
    }
    let timeKey = data[0]?.name;
    let yVariables = data.slice(1);

    let length = data[0]?.value?.[index].length ?? 0;
    let modifiedData = [];
    for (let i = 0; i < length; ++i) {
        let item: { [key: string]: string | number } = {};
        item[timeKey] = data[0].value?.[index][i];
        for (let j = 0; j < yVariables.length; ++j) {
            item[yVariables[j].name] = data?.[j + 1].value?.[index]?.[i];
        }
        modifiedData.push(item);
    }

    if (yVariables.length !== 0) {
        lines = [
            <XAxis
                key={0}
                ticks={data[0].value?.[index]}
                domain={["dataMin", "dataMax"]}
                type={type}
                tickLine={false}
                axisLine={false}
                dataKey={timeKey}
                tick={
                    <SingleLineAxisTick
                        formatValue={(value: number) => {
                            return timeLabelFormatter(
                                value,
                                isTimeSeries,
                                dateFormat,
                                displayMode,
                                firstRawDate as number
                            );
                        }}
                        fontSize={xAxisFontSize}
                        axesColor={ticksColor}
                        dx={8}
                        dy={8}
                        angle={0}
                    />
                }
            />,
            <YAxis
                domain={[minYRange ?? "dataMin", maxYRange ?? "dataMax"]}
                interval={0}
                key={1}
                tickCount={linesCount ?? undefined}
                allowDataOverflow={minYRange != null || maxYRange != null}
                tick={
                    <CustomizedAxisTick
                        axesColor={ticksColor}
                        formatValues={true}
                        truncValues={false}
                        fontSize={yAxisFontSize}
                        dx={0}
                        dy={0}
                        angle={0}
                    />
                }
                tickLine={false}
                axisLine={false}
            />,
        ];
    }

    let dot: (color: string) => any = (_color: string) => false;
    if (length === 1) {
        dot = (color) => ({
            fill: color,
            strokeWidth: 0,
            r: 5,
        });
        yVariables.forEach((variable, index) => {
            lines.push(
                <Area
                    isAnimationActive={false}
                    key={index + 2}
                    type="monotone"
                    dataKey={variable.name}
                    dot={dot(
                        props.preview ||
                            (variable.variableIndex == null &&
                                nameColorMapping?.[index] == null)
                            ? previewColors[index % previewColors.length]
                            : nameColorMapping?.[index] ??
                                  colorList[index % colorList.length]
                    )}
                    stroke={
                        props.preview ||
                        (variable.variableIndex == null &&
                            nameColorMapping?.[index] == null)
                            ? previewColors[index % previewColors.length]
                            : nameColorMapping?.[index] ??
                              colorList[index % colorList.length]
                    }
                    strokeWidth={3}
                    fillOpacity={1}
                    fill={`url(#color_variable)`}
                />
            );
        });
    } else {
        yVariables.forEach((variable, index) => {
            if (data[index + 1].value?.[props.index].length > 0) {
                lines.push(
                    <Line
                        type="linear"
                        key={index + 2}
                        strokeWidth={2}
                        isAnimationActive={false}
                        dataKey={variable.name}
                        activeDot={{
                            // The type of payload is specified incorrectly, so we
                            // have to cast it to any
                            onClick: (_event, payload) => {
                                onDotClick(index, (payload as any).index);
                            },
                        }}
                        dot={
                            <TimeChartDot
                                config={config}
                                lineIndex={index}
                                chartIndex={props.index}
                            />
                        }
                        stroke={
                            props.preview ||
                            (variable.variableIndex == null &&
                                nameColorMapping?.[index] == null)
                                ? previewColors[index % previewColors.length]
                                : nameColorMapping?.[index] ??
                                  colorList[index % colorList.length]
                        }
                    />
                );
            }
        });
    }
    let plot = (
        <ResponsiveContainer height="100%">
            <ComposedChart
                margin={{
                    top: 10,
                    bottom: 10,
                    left: 20,
                    right: 20,
                }}
                data={modifiedData}
            >
                {sections._Global.watermark != null && (
                    <text
                        x="50%"
                        y="30%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill={mainStyle.getPropertyValue(
                            "--content-secondary-text-color"
                        )}
                        fontSize={"4rem"}
                        style={{
                            opacity: 0.1,
                        }}
                    >
                        {sections._Global.watermark}
                    </text>
                )}
                <CartesianGrid
                    strokeDasharray="3 3"
                    stroke={bgDarkColor}
                    strokeWidth="2"
                />
                <RechartsTooltip
                    labelFormatter={(value) => {
                        return timeLabelFormatter(
                            value,
                            isTimeSeries,
                            dateFormat,
                            displayMode,
                            firstRawDate as number
                        );
                    }}
                    formatter={(value: number, name: string, _props: any) => {
                        let formattedValue = formatValue(value, false);
                        return [
                            formattedValue[0].concat(formattedValue[1]),
                            name,
                        ];
                    }}
                    {...TooltipStyles(tooltipColor, tooltipFontSize)}
                />
                {lines}
                {yVariables.length === 0 && (
                    <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="white"
                        fontSize="2rem"
                    >
                        NO DATA FOR SELECTED FILTERS
                    </text>
                )}
            </ComposedChart>
        </ResponsiveContainer>
    );
    return plot;
}
