import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="14"
			height="23"
			viewBox="0 0 14 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#657D95"
			{...props}
		>
			<path
				d="M11 1H2.25C1.55964 1 1 1.55964 1 2.25V11C1 11.6904 1.55964 12.25 2.25 12.25H11C11.6904 12.25 12.25 11.6904 12.25 11V2.25C12.25 1.55964 11.6904 1 11 1Z"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.57129 10V3.60156H6.54004C7.10254 3.60156 7.60498 3.729 8.04736 3.98389C8.49268 4.23584 8.83984 4.59619 9.08887 5.06494C9.33789 5.53076 9.4624 6.06104 9.4624 6.65576V6.9502C9.4624 7.54492 9.33936 8.07373 9.09326 8.53662C8.8501 8.99951 8.50586 9.3584 8.06055 9.61328C7.61523 9.86816 7.11279 9.99707 6.55322 10H4.57129ZM5.88965 4.66943V8.94092H6.52686C7.04248 8.94092 7.43652 8.77246 7.70898 8.43555C7.98145 8.09863 8.12061 7.6167 8.12646 6.98975V6.65137C8.12646 6.00098 7.9917 5.50879 7.72217 5.1748C7.45264 4.83789 7.05859 4.66943 6.54004 4.66943H5.88965Z"
				fill="#657D95"
			/>
			<path
				d="M4.75 18.625L6.5 20.375L8.25 18.625"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
