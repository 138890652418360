import React from "react";
import { Spiral, Scale, Options as WordCloudOptions } from "react-wordcloud";
import cx from "classnames";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import { WordCloudFinding, defaultWordCloudOptions } from "common/Finding";
import SliderInput from "../SliderInput";
import styles from "../StylingSection.module.css";
import wordCloudStyles from "./WordCloudSection.module.css";
import ColorPicker from "common/ColorPicker";

interface Props {
    finding: WordCloudFinding;
    onChange: (config: WordCloudFinding["config"]) => void;
}

const layoutOptions: { label: string; value: Spiral }[] = [
    { label: "Archimedean", value: "archimedean" },
    { label: "Rectangular", value: "rectangular" },
];

const scaleOptions: { label: string; value: Scale }[] = [
    { label: "Linear", value: "linear" },
    { label: "Log", value: "log" },
    { label: "Square Root", value: "sqrt" },
];

const fontWeightOptions: { label: string; value: string }[] = [
    { label: "Lighter", value: "lighter" },
    { label: "Normal", value: "normal" },
    { label: "Bold", value: "bold" },
    { label: "Bolder", value: "bolder" },
];

function WordCloudSection(props: Props) {
    let wordCloudOptions: Partial<WordCloudOptions> =
        props.finding.config.wordCloudOptions ?? defaultWordCloudOptions;
    return (
        <>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Layout</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    styles={{
                        ...getCustomSelectStyleForDataSection(14, false),
                        container: (base) => ({
                            ...base,
                            width: "150px",
                            height: "38px",
                        }),
                    }}
                    options={layoutOptions}
                    value={layoutOptions.find(
                        (option) => option.value === wordCloudOptions.spiral
                    )}
                    onChange={(newValue) => {
                        let value = (
                            newValue as {
                                label: string;
                                value: Spiral;
                            }
                        ).value;
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                spiral: value,
                            },
                        };
                        props.onChange(newConfig);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Word Scaling</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    styles={{
                        ...getCustomSelectStyleForDataSection(14, false),
                        container: (base) => ({
                            ...base,
                            width: "150px",
                            height: "38px",
                        }),
                    }}
                    options={scaleOptions}
                    value={scaleOptions.find(
                        (option) => option.value === wordCloudOptions.scale!
                    )}
                    onChange={(newValue) => {
                        let value = (
                            newValue as {
                                label: string;
                                value: Scale;
                            }
                        ).value;
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                scale: value,
                            },
                        };
                        props.onChange(newConfig);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Font Family</span>
                <input
                    className={styles.input}
                    style={{
                        paddingLeft: "10px",
                        borderRadius: "3px",
                        flex: 1,
                    }}
                    value={wordCloudOptions.fontFamily!}
                    onChange={(evt) => {
                        let value = evt.target.value;
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                fontFamily: value.toString(),
                            },
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Font Weight</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    styles={{
                        ...getCustomSelectStyleForDataSection(14, false),
                        container: (base) => ({
                            ...base,
                            width: "150px",
                            height: "38px",
                        }),
                    }}
                    options={fontWeightOptions}
                    value={fontWeightOptions.find(
                        (option) =>
                            option.value === wordCloudOptions.fontWeight!
                    )}
                    onChange={(newValue) => {
                        let value = (
                            newValue as {
                                label: string;
                                value: Scale;
                            }
                        ).value;
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                fontWeight: value,
                            },
                        };
                        props.onChange(newConfig);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            <div className={styles.verticalOptionContainer}>
                <span className={styles.optionName}>Colors</span>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                    }}
                >
                    {wordCloudOptions.colors!.map((color, index) => (
                        <div style={{ marginTop: "5px", marginRight: "5px" }}>
                            <ColorPicker
                                key={index}
                                inPopup
                                enableAlpha
                                value={color}
                                onChange={(newValue) => {
                                    let colors = Array.from(
                                        wordCloudOptions.colors!
                                    );
                                    colors[index] = newValue;
                                    let newConfig = {
                                        ...props.finding.config,
                                        wordCloudOptions: {
                                            ...wordCloudOptions,
                                            colors: colors,
                                        },
                                    };
                                    props.onChange(newConfig);
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div style={{ display: "flex", marginTop: "5px" }}>
                    <div style={{ marginRight: "5px" }}>
                        <ColorPicker
                            key={"plus"}
                            inPopup
                            enableAlpha
                            tooltip="Add Color"
                            value={"#118811"}
                            customView={
                                <span
                                    className={wordCloudStyles.addColorButton}
                                >
                                    {"\uFF0B"}
                                </span>
                            }
                            onChange={(newValue) => {
                                let colors = Array.from(
                                    wordCloudOptions.colors!
                                );
                                colors[colors.length - 1] = newValue;
                                let newConfig = {
                                    ...props.finding.config,
                                    wordCloudOptions: {
                                        ...wordCloudOptions,
                                        colors: colors,
                                    },
                                };
                                props.onChange(newConfig);
                            }}
                            onOpen={() => {
                                let colors = Array.from(
                                    wordCloudOptions.colors!
                                );
                                colors.push("#118811");
                                let newConfig = {
                                    ...props.finding.config,
                                    wordCloudOptions: {
                                        ...wordCloudOptions,
                                        colors: colors,
                                    },
                                };
                                props.onChange(newConfig);
                            }}
                        />
                    </div>
                    {wordCloudOptions.colors!.length > 1 && (
                        <span className={wordCloudStyles.addColorButton}>
                            <Button
                                title="Remove Color"
                                className={cx(
                                    "btn btn-sm btn-primary my-primary",
                                    wordCloudStyles.addColorButton,
                                    wordCloudStyles.deleteColumnButton
                                )}
                                onClick={() => {
                                    let colors = Array.from(
                                        wordCloudOptions.colors!
                                    );
                                    colors.pop();
                                    let newConfig = {
                                        ...props.finding.config,
                                        wordCloudOptions: {
                                            ...wordCloudOptions,
                                            colors: colors,
                                        },
                                    };
                                    props.onChange(newConfig);
                                }}
                            >
                                {"\u2715"}
                            </Button>
                        </span>
                    )}
                </div>
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Rotations</span>
                <SliderInput
                    minValue={0}
                    maxValue={30}
                    value={wordCloudOptions.rotations!}
                    onChange={(value: number) => {
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                rotations: value,
                            },
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Min Rotation Angle</span>
                <SliderInput
                    minValue={-90}
                    maxValue={wordCloudOptions.rotationAngles![1]}
                    value={wordCloudOptions.rotationAngles![0]}
                    onChange={(value: number) => {
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                rotationAngles: [
                                    value,
                                    wordCloudOptions.rotationAngles![1],
                                ] as [number, number],
                            },
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Max Rotation Angle</span>
                <SliderInput
                    minValue={wordCloudOptions.rotationAngles![0]}
                    maxValue={90}
                    value={wordCloudOptions.rotationAngles![1]}
                    onChange={(value: number) => {
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                rotationAngles: [
                                    wordCloudOptions.rotationAngles![0],
                                    value,
                                ] as [number, number],
                            },
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Min Font Size</span>
                <SliderInput
                    minValue={1}
                    maxValue={wordCloudOptions.fontSizes![1]}
                    value={wordCloudOptions.fontSizes![0]}
                    onChange={(value: number) => {
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                fontSizes: [
                                    value,
                                    wordCloudOptions.fontSizes![1],
                                ] as [number, number],
                            },
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Max Font Size</span>
                <SliderInput
                    minValue={wordCloudOptions.fontSizes![0]}
                    maxValue={100}
                    value={wordCloudOptions.fontSizes![1]}
                    onChange={(value: number) => {
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                fontSizes: [
                                    wordCloudOptions.fontSizes![0],
                                    value,
                                ] as [number, number],
                            },
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Word Padding</span>
                <SliderInput
                    minValue={0}
                    maxValue={20}
                    value={wordCloudOptions.padding!}
                    onChange={(value: number) => {
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                padding: value,
                            },
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>
                    Transition Duration (ms)
                </span>
                <SliderInput
                    minValue={0}
                    maxValue={10000}
                    value={wordCloudOptions.transitionDuration!}
                    onChange={(value: number) => {
                        let newConfig = {
                            ...props.finding.config,
                            wordCloudOptions: {
                                ...wordCloudOptions,
                                transitionDuration: value,
                            },
                        };
                        props.onChange(newConfig);
                    }}
                />
            </div>
        </>
    );
}

export default WordCloudSection;
