import "common/styles/App.css";
import React from "react";
import { Button } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reaction } from "mobx";
import { mainStyle } from "common/MainStyle";
import { conditionsToString } from "common/Conditions";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import BaseJourney from "../../common/BaseJourney";
import PredictiveAnalyticsType from "../../common/PredictiveAnalyticsType";
import RunRegressionComponent from "../../common/RunRegressionComponent";
import RegressionResultsComponent from "common/graphics/RegressionResultsComponent";
import OutcomeCurveComponent from "common/graphics/OutcomeCurveComponent";
import { Api } from "./Api";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";
import { ConditionsSelector } from "common/Conditions";
import SaveRegressionResultsPopup from "common/SaveRegressionResultsPopup";

function StepFinal2(props) {
    let {
        onShowSaveRegressionResultsPopup,
        finding,
        parent,
        loading,
        showMarginals,
    } = props;
    let placeholder1 = 0;
    let placeholder2 = 0;
    let regressionResult = undefined;
    if (finding) {
        regressionResult = finding.content.regressionResult;
        if (regressionResult && !finding.config.regressionInfo[0].iv.main_fixed)
            if (regressionResult.regressionCurves.length > 0) {
                let x = Object.values(regressionResult.regressionCurves[0])[0]
                    .x;
                if (x.length > 0) {
                    placeholder1 = x[0].toFixed(2);
                    placeholder2 = x[x.length - 1].toFixed(2);
                }
            }
    }
    return (
        <>
            <div
                className="my-row"
                style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <div
                    onClick={() => {
                        parent.back();
                    }}
                    style={{
                        width: 100,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/data-exploration/chevron_back.png"
                    />
                </div>
                {loading ? (
                    <span className="exploration-big-title-span">
                        {"LOADING"}
                    </span>
                ) : (
                    regressionResult && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "calc(100% - 200px)",
                                height: "100%",
                            }}
                        >
                            {!finding.config.regressionInfo[0].iv
                                .main_fixed && (
                                <div
                                    className="my-row center-container"
                                    style={{ alignItems: "center" }}
                                >
                                    <span
                                        style={{
                                            fontFamily: "Arial",
                                            fontSize: "10px",
                                            color: mainStyle.getPropertyValue(
                                                "--exploration-primary-text-color"
                                            ),
                                        }}
                                    >
                                        {"Specify range: "}
                                    </span>
                                    <input
                                        style={{ marginLeft: 10 }}
                                        className="input-range"
                                        type="number"
                                        size="5"
                                        placeholder={placeholder1}
                                        onChange={(evt) => {
                                            parent.setState({
                                                leftRangeValue:
                                                    evt.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            parent.state.leftRangeValue
                                        }
                                    />
                                    <span
                                        style={{
                                            marginLeft: 10,
                                            fontFamily: "Arial",
                                            fontSize: "10px",
                                            color: mainStyle.getPropertyValue(
                                                "--exploration-primary-text-color"
                                            ),
                                        }}
                                    >
                                        {"-"}
                                    </span>
                                    <input
                                        style={{ marginLeft: 10 }}
                                        className="input-range"
                                        type="number"
                                        size="5"
                                        placeholder={placeholder2}
                                        onChange={(evt) => {
                                            parent.setState({
                                                rightRangeValue:
                                                    evt.target.value,
                                            });
                                        }}
                                        defaultValue={
                                            parent.state.rightRangeValue
                                        }
                                    />
                                    <Button
                                        disabled={
                                            parent.state.leftRangeValue ==
                                                null ||
                                            parent.state.rightRangeValue == null
                                        }
                                        type="button"
                                        style={{
                                            width: "112px",
                                            marginLeft: 10,
                                        }}
                                        className="btn btn-xs btn-primary my-primary"
                                        onClick={() => {
                                            parent.getData();
                                        }}
                                    >
                                        SUBMIT
                                    </Button>
                                </div>
                            )}
                            <div
                                style={{
                                    marginTop: 20,
                                    width: "100%",
                                    height: showMarginals ? "300px" : "280px",
                                }}
                            >
                                <ErrorBoundary>
                                    <OutcomeCurveComponent
                                        showMarginals={showMarginals}
                                        regressionResult={regressionResult}
                                    />
                                </ErrorBoundary>
                            </div>
                            <FinalStepBottomBar
                                onShowRecipesPopup={parent.showRecipesPopup}
                                onSaveFinding={parent.saveFinding}
                                onSaveRegressionResults={
                                    onShowSaveRegressionResultsPopup
                                }
                                topMargin={10}
                            />
                            {!showMarginals && (
                                <span
                                    style={{
                                        marginTop: 10,
                                        alignSelf: "flex-end",
                                        cursor: "pointer",
                                        fontFamily: "Roboto",
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-secondary-text-color"
                                        ),
                                        fontWeight: 700,
                                    }}
                                >
                                    press [&rarr;] Key to see marginal effect
                                    curve
                                </span>
                            )}
                        </div>
                    )
                )}
                <div
                    style={{
                        width: 100,
                        height: "100%",
                    }}
                />
            </div>
        </>
    );
}

function StepFinal1(props) {
    let {
        finding,
        onNewFinding,
        onShowRecipesPopup,
        onShowSaveRegressionResultsPopup,
        onBack,
        onSaveFinding /*, step*/,
        showLegend,
        loading,
    } = props;
    let regressionResult = undefined;
    if (finding) {
        regressionResult = finding.content.regressionResult;
        regressionResult.showLegend = showLegend;
    }
    return (
        <>
            <div
                className="my-row"
                style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <div
                    onClick={() => {
                        onBack();
                    }}
                    style={{
                        width: 100,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/data-exploration/chevron_back.png"
                    />
                </div>
                {loading ? (
                    <span className="exploration-big-title-span">
                        {"LOADING"}
                    </span>
                ) : (
                    regressionResult && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "calc(100% - 300px)",
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    marginTop: 20,
                                    width: "100%",
                                    height: "300px",
                                }}
                            >
                                <ErrorBoundary>
                                    <RegressionResultsComponent
                                        onRegressionBarsChanged={(
                                            regressionBars
                                        ) => {
                                            let newFinding = Object.assign(
                                                {},
                                                finding
                                            );
                                            newFinding.content.regressionResult.regressionBars =
                                                regressionBars;

                                            if (onNewFinding) {
                                                onNewFinding(newFinding);
                                            }
                                        }}
                                        lastCustomRegressionResults={
                                            regressionResult
                                        }
                                        showLegend={showLegend}
                                    />
                                </ErrorBoundary>
                            </div>
                            <FinalStepBottomBar
                                onSaveFinding={onSaveFinding}
                                onShowRecipesPopup={onShowRecipesPopup}
                                onSaveRegressionResults={
                                    onShowSaveRegressionResultsPopup
                                }
                                topMargin={10}
                            />
                            <span
                                style={{
                                    marginTop: 10,
                                    alignSelf: "flex-end",
                                    cursor: "pointer",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                    fontWeight: 700,
                                }}
                            >
                                press [&rarr;] Key to see response curve
                            </span>
                        </div>
                    )
                )}

                <div
                    style={{
                        width: 100,
                        height: "100%",
                    }}
                />
            </div>
        </>
    );
}

const StepSimple = Object.freeze({
    initial: 0,
    selectDataScope: 1,
    selectTable: 2,
    selectConditions: 3,
    selectIndependent: 4,
    selectDependent: 5,
    selectOptional: 6,
    selectAdditionalParameter: 7,
    selectAdditionalValue: 8,
    finalFirst: 9,
    finalSecond: 10,
    finalThird: 11,
});

const StepInteraction = Object.freeze({
    initial: 0,
    selectDataScope: 1,
    selectTable: 2,
    selectConditions: 3,
    selectIndependent: 4,
    selectInteraction: 5,
    selectDependent: 6,
    selectOptional: 7,
    selectAdditionalParameter: 8,
    selectAdditionalValue: 9,
    finalFirst: 10,
    finalSecond: 11,
    finalThird: 12,
});

class MainComponent extends BaseJourney {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            step: 0,
            loading: false,
            errorMessage: undefined,
            animationDirection: "top",
            regressionVariables: [],
            allVariables: [],
            allVariablesWithUnits: [],
            variablesWithUnits: [],
            selectedDependentVariable: undefined,
            selectedIndependentVariable: undefined,
            interactionIndependentVariable: undefined,
            restIndependentVariables: [],
            finding: undefined,
            showRecipesPopup: false,
            showSaveRegressionResultsPopup: false,
            regressionInfo: undefined,
            leftAdditionalValue: undefined,
            rightAdditionalValue: undefined,
            additionalParameters: [],
            additionalParameter: undefined,
            selectedTable: undefined,
            conditions: ConditionsSelector.defaultValue,
        };
        this.steps =
            this.props.type === PredictiveAnalyticsType.simple
                ? StepSimple
                : StepInteraction;
        this.back = this.back.bind(this);
        this.saveFinding = this.saveFinding.bind(this);
        this.showSaveRegressionResultsPopup =
            this.showSaveRegressionResultsPopup.bind(this);

        this.initializeVariables = this.initializeVariables.bind(this);
        this.switchVariables = this.switchVariables.bind(this);
    }

    showSaveRegressionResultsPopup() {
        this.setState({ showSaveRegressionResultsPopup: true });
    }
    clearOptionalArgs() {
        this.setState({
            selectedIndependentVariable: null,
            selectedDependentVariable: null,
            interactionIndependentVariable: null,
            restIndependentVariables: [],
            additionalParameter: null,
            additionalParameterIndex: null,
            leftAdditionalOperator: null,
            leftAdditionalValue: null,
            rightAdditionalOperator: null,
            rightAdditionalValue: null,
            selectedTable: this.defaultTable(),
            conditions: ConditionsSelector.defaultValue,
            regressionInfo: null,
            dynamicOptions: {},
        });
    }
    getConfig() {
        let config = {
            version: configVersion,
            dataScope: this.state.dataScope ?? null,
            selectedDependentVariable:
                this.state.selectedDependentVariable ?? null,
            selectedIndependentVariable:
                this.state.selectedIndependentVariable ?? null,
            interactionIndependentVariable:
                this.props.type === PredictiveAnalyticsType.simple
                    ? null
                    : this.state.interactionIndependentVariable,
            restIndependentVariables:
                this.state.restIndependentVariables ?? null,
            regressionInfo: this.state.regressionInfo ?? null,
            leftAdditionalOperator: this.state.leftAdditionalOperator ?? null,
            rightAdditionalOperator: this.state.rightAdditionalOperator ?? null,
            leftAdditionalValue: this.state.leftAdditionalValue ?? null,
            rightAdditionalValue: this.state.rightAdditionalValue ?? null,
            leftRangeValue: this.state.leftRangeValue ?? null,
            rightRangeValue: this.state.rightRangeValue ?? null,
            additionalParameter: this.state.additionalParameter ?? null,
            additionalParameters: this.state.additionalParameters ?? null,
            selectedTable: this.state.selectedTable ?? null,
            conditions: this.state.conditions ?? null,
            journeyName: this.props.journeyName,
        };
        if (this.props.addToCanvas) {
            config.dynamicOptions = this.prepareDynamicOptions(
                this.state.dynamicOptions,
                config
            );
        }
        return config;
    }
    back() {
        this.setState({ animationDirection: "bottom" }, () => {
            this.setState((state) => ({
                step:
                    state.step >= this.steps.finalSecond
                        ? state.step - 1
                        : state.previousStep,
                finding:
                    state.step >= this.steps.finalSecond
                        ? state.finding
                        : undefined,
                leftAdditionalValueSelected:
                    state.step >= this.steps.finalSecond
                        ? state.leftAdditionalValueSelected
                        : false,
                rightAdditionalValueSelected:
                    state.step >= this.steps.finalSecond
                        ? state.rightAdditionalValueSelected
                        : false,
            }));
        });
    }
    showLegend() {
        for (var i in this.state.regressionInfo[4]) {
            if (this.state.regressionInfo[4][i]) {
                return true;
            }
        }
        return false;
    }
    buildContent() {
        switch (this.state.step) {
            case this.steps.initial:
                return this.buildInitial(
                    "How do you want to examine your data?"
                );
            case this.steps.selectDataScope:
                return this.buildDataScopesSelector();
            case this.steps.selectTable:
                return this.buildTableSelector();
            case this.steps.selectConditions:
                return this.buildConditionsSelector();
            case this.steps.selectIndependent:
                return this.buildStepIndependentVariableSelector();
            case this.steps.selectInteraction:
                return this.buildStepInteractionVariableSelector();
            case this.steps.selectDependent:
                return this.buildStepDependentVariableSelector();
            case this.steps.selectOptional:
                return this.buildStepOptionalSelector();
            case this.steps.selectAdditionalParameter:
                return this.buildVariablesSelector(
                    "by comparing the effect across",
                    "additionalParameter",
                    "additionalParameters",
                    false,
                    DynamicOptionType.additionalParameter,
                    "additionalParameterIndex"
                );
            case this.steps.selectAdditionalValue:
                return this.buildAdditionalValueSelector(
                    "and how they vary between",
                    true
                );
            case this.steps.finalFirst:
                return !this.state.errorMessage ? (
                    <StepFinal1
                        onShowSaveRegressionResultsPopup={
                            this.showSaveRegressionResultsPopup
                        }
                        onShowRecipesPopup={this.showRecipesPopup}
                        loading={this.state.loading}
                        parent={this}
                        onBack={this.back}
                        onNewFinding={this.onNewFinding}
                        onSaveFinding={this.saveFinding}
                        finding={this.state.finding}
                        showLegend={this.showLegend()}
                    />
                ) : (
                    this.buildErrorScreen(this.state.errorMessage)
                );
            case this.steps.finalSecond:
                return !this.state.errorMessage ? (
                    <StepFinal2
                        onShowSaveRegressionResultsPopup={
                            this.showSaveRegressionResultsPopup
                        }
                        loading={this.state.loading}
                        showMarginals={false}
                        parent={this}
                        onBack={this.back}
                        onSaveFinding={this.saveFinding}
                        finding={this.state.finding}
                    />
                ) : (
                    this.buildErrorScreen(this.state.errorMessage)
                );
            case this.steps.finalThird:
                return !this.state.errorMessage ? (
                    <StepFinal2
                        onShowRegressionResultsPopup={
                            this.showRegressionResultsPopup
                        }
                        loading={this.state.loading}
                        parent={this}
                        showMarginals={true}
                        onBack={this.back}
                        onSaveFinding={this.saveFinding}
                        finding={this.state.finding}
                    />
                ) : (
                    this.buildErrorScreen(this.state.errorMessage)
                );

            default:
                return <div />;
        }
    }

    buildLeftBar() {
        return super.buildLeftBar(this.state.step < this.steps.finalFirst);
    }
    getData() {
        let config = this.getConfig();
        this.setState({ loading: true, errorMessage: undefined });
        Api.getData(config, undefined, this.props.currentModuleId, true)
            .then((item) => {
                this.setState({
                    finding: item,
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({ loading: false, errorMessage: String(error) });
            });
    }

    buildRightBar() {
        if (
            this.state.step >= this.steps.finalFirst &&
            this.state.step < this.steps.finalThird
        )
            return (
                <div
                    onClick={() => {
                        if (this.state.step < this.steps.finalFirst)
                            this.showFinalStep(this.steps.finalFirst);
                        else this.showFinalStep(this.state.step + 1);
                    }}
                    style={{
                        width: 52,
                        height: "100%",
                        backgroundColor: mainStyle.getPropertyValue(
                            "--content-background-color"
                        ),
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                    }}
                >
                    <img alt="" src="/dist/img/data-exploration/chevron.png" />
                </div>
            );

        return null;
    }

    initializeVariables() {
        let scopeVariables = this.getVariables();
        let allVariablesWithUnits = scopeVariables.dataVariables;
        let regressorVariables = scopeVariables.numericVariables;
        this.setState({
            additionalParameters: [null].concat(
                scopeVariables.dataVariables.map((item) => item.name)
            ),
            allVariables: allVariablesWithUnits.map((item) => item.name),
            allVariablesWithUnits: allVariablesWithUnits,
            regressionVariables: regressorVariables.map((item) => item.name),
            variablesWithUnits: regressorVariables,
        });
    }

    switchVariables() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
        this.initializeVariables();
        this.initializeVariablesReaction = reaction(
            () => this.getVariables().dataVariables,
            () => {
                this.initializeVariables();
            }
        );
    }

    componentDidMount() {
        this.getLastJourneyConfig();
    }
    componentWillUnmount() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
    }
    selectVariable(variableName, variablesName, index) {
        if (index >= this.state[variablesName].length) return;
        if (index < 0) return;
        let stateDiff = {
            [variableName]: this.state[variablesName][index],
        };
        if (this.state.step === this.steps.selectAdditionalParameter) {
            stateDiff.leftAdditionalValue = undefined;
            stateDiff.rightAdditionalValue = undefined;
        }
        if (this.state.step < this.steps.selectOptional) {
            stateDiff.regressionInfo = undefined;
        }
        this.setState(stateDiff, () => {
            setTimeout(() => {
                this.stepDown();
            }, 100);
        });
    }

    getSubtitle() {
        let conditionsString = conditionsToString(
            Array.from(this.state.conditions)
        );
        if (
            this.state.step === this.steps.selectDataScope ||
            this.state.step === this.steps.selectTable
        )
            return "How do you want to examine your levers data?";

        if (this.props.type === PredictiveAnalyticsType.simple) {
            if (
                this.state.step > this.steps.selectTable &&
                this.state.step < this.steps.selectOptional
            ) {
                let subtitle = `For the data in ${
                    this.state.dataScope ? this.state.dataScope.label : ""
                } ${this.state.selectedTable.label}`;
                if (this.state.step > this.steps.selectConditions) {
                    if (conditionsString.length > 0)
                        subtitle += ` and under the conditions ${conditionsString}`;
                }
                if (this.state.step > this.steps.selectIndependent)
                    subtitle += ` I want to see the effect of an increase of ${
                        this.state.selectedIndependentVariable || ""
                    }`;
                return subtitle;
            }
            if (this.state.step > this.steps.selectDependent) {
                return (
                    <>
                        <span>For the data </span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                            }}
                        >
                            {this.state.dataScope
                                ? this.state.dataScope.label.concat(" ")
                                : ""}
                        </span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                            }}
                        >
                            {this.state.selectedTable.label}
                        </span>
                        {conditionsString.length > 0 && (
                            <>
                                <span> and under the conditions </span>
                                <span
                                    style={{
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-secondary-text-color"
                                        ),
                                    }}
                                >
                                    {conditionsString}
                                </span>
                            </>
                        )}
                        <span>
                            {" I want to see the effect of an increase of "}
                        </span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                            }}
                        >
                            {this.state.selectedIndependentVariable || ""}
                        </span>
                        <span>{" leading to change in "}</span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                            }}
                        >
                            {this.state.selectedDependentVariable || ""}
                        </span>
                        {this.state.restIndependentVariables.length > 0 ? (
                            <>
                                <span>{" while controlling for "}</span>
                                <span
                                    style={{
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-secondary-text-color"
                                        ),
                                    }}
                                >
                                    {this.state.restIndependentVariables.join(
                                        ", "
                                    )}
                                </span>
                            </>
                        ) : null}
                        {this.state.additionalParameter ? (
                            <>
                                <span>
                                    {" by comparing the effect across "}
                                </span>
                                <span
                                    style={{
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-secondary-text-color"
                                        ),
                                    }}
                                >
                                    {this.state.additionalParameter}
                                </span>
                                <span>{" and how they vary between "}</span>
                                {this.state.leftAdditionalValue ? (
                                    <span
                                        style={{
                                            color: mainStyle.getPropertyValue(
                                                "--exploration-secondary-text-color"
                                            ),
                                        }}
                                    >
                                        {`${this.state.additionalParameter} ${
                                            this.state.leftAdditionalOperator
                                                ?.label ?? "="
                                        } ${
                                            this.state.leftAdditionalValue.label
                                        }`}
                                    </span>
                                ) : null}
                                {this.state.leftAdditionalValue &&
                                this.state.rightAdditionalValue ? (
                                    <span>{" and "}</span>
                                ) : null}
                                {this.state.rightAdditionalValue ? (
                                    <span
                                        style={{
                                            color: mainStyle.getPropertyValue(
                                                "--exploration-secondary-text-color"
                                            ),
                                        }}
                                    >
                                        {`${this.state.additionalParameter} ${
                                            this.state.rightAdditionalOperator
                                                ?.label ?? "="
                                        } ${
                                            this.state.rightAdditionalValue
                                                .label
                                        }`}
                                    </span>
                                ) : null}
                            </>
                        ) : null}
                        {this.state.step >= this.steps.finalFirst && (
                            <span>.</span>
                        )}
                    </>
                );
            }
        }

        if (this.props.type === PredictiveAnalyticsType.interaction) {
            if (
                this.state.step > this.steps.selectTable &&
                this.state.step < this.steps.selectOptional
            ) {
                let subtitle = `For the data in ${this.state.selectedTable.label}`;
                if (this.state.step > this.steps.selectConditions) {
                    if (conditionsString.length > 0)
                        subtitle += ` and under the conditions ${conditionsString}`;
                }
                if (this.state.step > this.steps.selectIndependent)
                    subtitle += ` I want to see the interaction effect of ${
                        this.state.selectedIndependentVariable || ""
                    }`;
                if (this.state.step > this.steps.selectInteraction)
                    subtitle += ` and ${
                        this.state.interactionIndependentVariable || ""
                    }`;
                return subtitle;
            }
            if (this.state.step >= this.steps.selectOptional) {
                return (
                    <>
                        <span>For the data </span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                            }}
                        >
                            {this.state.dataScope
                                ? this.state.dataScope.label.concat(" ")
                                : ""}
                        </span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                            }}
                        >
                            {this.state.selectedTable.label}
                        </span>
                        {conditionsString.length > 0 && (
                            <>
                                <span> and under the conditions </span>
                                <span
                                    style={{
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-secondary-text-color"
                                        ),
                                    }}
                                >
                                    {conditionsString}
                                </span>
                            </>
                        )}
                        <span>
                            {" I want to see the interaction effect of "}
                        </span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                            }}
                        >
                            {this.state.selectedIndependentVariable || ""}
                        </span>
                        <span>
                            {this.state.interactionIndependentVariable
                                ? " and "
                                : ""}
                        </span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                            }}
                        >
                            {this.state.interactionIndependentVariable || ""}
                        </span>

                        <span>{" leading to change in "}</span>
                        <span
                            style={{
                                color: mainStyle.getPropertyValue(
                                    "--exploration-secondary-text-color"
                                ),
                            }}
                        >
                            {this.state.selectedDependentVariable || ""}
                        </span>
                        {this.state.restIndependentVariables.length > 0 ? (
                            <>
                                <span> while controlling for </span>
                                <span
                                    style={{
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-secondary-text-color"
                                        ),
                                    }}
                                >
                                    {this.state.restIndependentVariables.join(
                                        ", "
                                    )}
                                </span>
                            </>
                        ) : null}
                        {this.state.additionalParameter ? (
                            <>
                                <span>
                                    {" by comparing the effect across "}
                                </span>
                                <span
                                    style={{
                                        color: mainStyle.getPropertyValue(
                                            "--exploration-secondary-text-color"
                                        ),
                                    }}
                                >
                                    {this.state.additionalParameter}
                                </span>
                                <span>{" and how they vary between "}</span>
                                {this.state.leftAdditionalValue ? (
                                    <span
                                        style={{
                                            color: mainStyle.getPropertyValue(
                                                "--exploration-secondary-text-color"
                                            ),
                                        }}
                                    >
                                        {`${this.state.additionalParameter} ${
                                            this.state.leftAdditionalOperator
                                                ?.label ?? "="
                                        } ${
                                            this.state.leftAdditionalValue.label
                                        }`}
                                    </span>
                                ) : null}
                                {this.state.leftAdditionalValue &&
                                this.state.rightAdditionalValue ? (
                                    <span>{" and "}</span>
                                ) : null}
                                {this.state.rightAdditionalValue ? (
                                    <span
                                        style={{
                                            color: mainStyle.getPropertyValue(
                                                "--exploration-secondary-text-color"
                                            ),
                                        }}
                                    >
                                        {`${this.state.additionalParameter} ${
                                            this.state.rightAdditionalOperator
                                                ?.label ?? "="
                                        } ${
                                            this.state.rightAdditionalValue
                                                .label
                                        }`}
                                    </span>
                                ) : null}
                            </>
                        ) : null}
                        {this.state.step >= this.steps.finalFirst && (
                            <span>.</span>
                        )}
                    </>
                );
            }
        }
    }

    showFinalStep(step) {
        let regressionInfo = this.refs.runRegressionComponent
            ? this.refs.runRegressionComponent.getRegressionInfo()
            : this.state.regressionInfo;
        if (!regressionInfo) return;
        this.setState({ animationDirection: "top" }, () => {
            this.setState(
                (state) => ({
                    regressionInfo: regressionInfo,
                    previousStep:
                        step === this.steps.finalFirst
                            ? state.step
                            : state.previousStep,
                    step: step,
                }),
                () => {
                    if (step === this.steps.finalFirst) {
                        this.getData();
                        this.saveNewJourneyConfig();
                    }
                }
            );
        });
    }

    getFooterTitle() {
        switch (this.state.step) {
            case this.steps.initial:
                return "... For the data in...";
            case this.steps.selectTable:
                return "... and under the conditions...";
            case this.steps.selectConditions:
                return "... I want to see the effect of an increase of ...";
            case this.steps.selectIndependent:
                return this.props.type === PredictiveAnalyticsType.simple
                    ? "... leading to change in ..."
                    : "... and ...";
            case this.steps.selectInteraction:
                return "... leading to change in ...";
            case this.steps.selectDependent:
                return "";
            case this.steps.selectOptional:
                return "[optional]... by comparing the effect across ...";
            case this.steps.selectAdditionalParameter:
                return "... and how they vary between ...";
            default:
                return "";
        }
    }

    buildStepIndependentVariableSelector() {
        return this.buildCustomVariablesSelector(
            this.props.type === PredictiveAnalyticsType.simple
                ? "I want to see the effect of an increase of"
                : "I want to see the interaction effect of",
            "selectedIndependentVariable",
            [undefined],
            "allVariables"
        );
    }

    buildStepInteractionVariableSelector() {
        return this.buildCustomVariablesSelector(
            "and",
            "interactionIndependentVariable",
            ["selectedIndependentVariable"],
            "allVariables"
        );
    }

    buildStepDependentVariableSelector() {
        let selectedNames =
            this.props.type === PredictiveAnalyticsType.simple
                ? ["selectedIndependentVariable"]
                : [
                      "selectedIndependentVariable",
                      "interactionIndependentVariable",
                  ];

        return this.buildCustomVariablesSelector(
            "leading to change in",
            "selectedDependentVariable",
            selectedNames,
            "regressionVariables"
        );
    }
    buildStepOptionalSelector() {
        return (
            <div
                className="my-row"
                style={{
                    width: "100%",
                }}
            >
                {this.buildLeftBar()}
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{
                            width: "100%",
                            marginLeft: 20,
                            marginRight: 20,
                        }}
                    >
                        <div
                            className="element"
                            style={{
                                width: "100%",
                                height: "350px",
                                overflowY: "auto",
                            }}
                        >
                            <RunRegressionComponent
                                ref="runRegressionComponent"
                                type={this.props.type}
                                latestRegressionInfo={this.state.regressionInfo}
                                allVariables={this.state.allVariablesWithUnits}
                                regressionVariables={
                                    this.state.variablesWithUnits
                                }
                                dependentVariable={
                                    this.state.selectedDependentVariable
                                }
                                independentVariable={
                                    this.state.selectedIndependentVariable
                                }
                                interactionVariable={
                                    this.state.interactionIndependentVariable
                                }
                                onDependentVariableChanged={(
                                    dependentVariable
                                ) => {
                                    this.setState({
                                        selectedDependentVariable:
                                            dependentVariable,
                                    });
                                }}
                                onIndependentVariablesChanged={(
                                    independentVariables
                                ) => {
                                    if (
                                        this.props.type ===
                                        PredictiveAnalyticsType.simple
                                    ) {
                                        this.setState({
                                            selectedIndependentVariable:
                                                independentVariables[0],
                                            restIndependentVariables:
                                                independentVariables.slice(1),
                                        });
                                    } else {
                                        this.setState({
                                            selectedIndependentVariable:
                                                independentVariables[0],
                                            interactionIndependentVariable:
                                                independentVariables[1],
                                            restIndependentVariables:
                                                independentVariables.slice(2),
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    saveFinding() {
        if (!this.state.finding) return;
        let item = {};
        if (this.state.step === this.steps.finalFirst)
            item = Api.getFinding(
                {
                    regressionResult:
                        this.state.finding.content.regressionResult,
                    config: this.state.finding.config,
                },
                {
                    type: "5",
                }
            );
        else if (this.state.step === this.steps.finalSecond)
            item = Api.getFinding(
                {
                    regressionResult:
                        this.state.finding.content.regressionResult,
                    config: this.state.finding.config,
                },
                {
                    type: "9",
                    content: {
                        showMarginals: false,
                    },
                }
            );
        else if (this.state.step === this.steps.finalThird)
            item = Api.getFinding(
                {
                    regressionResult:
                        this.state.finding.content.regressionResult,
                    config: this.state.finding.config,
                },
                {
                    type: "9",
                    content: {
                        showMarginals: true,
                    },
                }
            );
        this.props.onNewFinding(item);
    }

    stepDown() {
        if (
            this.state.step === this.steps.selectTable &&
            !this.state.selectedTable
        ) {
            return;
        }

        if (
            this.state.step === this.steps.selectAdditionalParameter &&
            !this.state.additionalParameter
        ) {
            this.showFinalStep(this.steps.finalFirst);
            return;
        }
        if (this.state.step === this.steps.selectAdditionalValue) {
            this.showFinalStep(this.steps.finalFirst);
        } else if (this.state.step === this.steps.finalFirst) {
            this.showFinalStep(this.steps.finalSecond);
        } else if (this.state.step === this.steps.finalSecond) {
            this.showFinalStep(this.steps.finalThird);
        } else {
            this.setState({ animationDirection: "top" }, () => {
                let regressionInfo = this.refs.runRegressionComponent
                    ? this.refs.runRegressionComponent.getRegressionInfo()
                    : this.state.regressionInfo;
                this.setState({
                    regressionInfo: regressionInfo,
                    previousStep: this.state.step,
                    step: this.state.step + 1,
                });
            });
        }
    }
    buildSaveRegressionResultsPopup() {
        if (!this.state.showSaveRegressionResultsPopup) return null;
        return (
            <SaveRegressionResultsPopup
                regressionResults={this.state.finding?.config?.nativeData}
                currentModuleId={this.props.currentModuleId}
                onClose={() => {
                    this.setState({ showSaveRegressionResultsPopup: false });
                }}
            />
        );
    }
    stepUp() {
        if (this.state.step > this.steps.initial)
            this.setState({ animationDirection: "bottom" }, () => {
                this.setState(
                    (state) => ({
                        step: state.step - 1,
                    }),
                    () => {}
                );
            });
    }

    render() {
        let rightBar = this.buildRightBar();
        return (
            <div
                className="dashboard-rect-journey-focus"
                tabIndex="0"
                style={{
                    height: 520,
                    overflow: "hidden",
                    display: !this.props.collapsed ? "block" : "none",
                }}
                onClick={() => {
                    if (this.state.step === 0) this.stepDown();
                }}
                onKeyDown={(evt) => {
                    if (evt.key === "Escape") {
                        this.props.onClose();
                        return;
                    }

                    if (evt.key === "ArrowDown") {
                        if (
                            this.state.step > 0 &&
                            this.state.step < this.steps.finalSecond
                        )
                            this.stepDown();
                        evt.preventDefault();
                        return;
                    }
                    if (evt.key === "ArrowUp") {
                        if (this.state.step < this.steps.finalFirst)
                            this.stepUp();
                        evt.preventDefault();
                        return;
                    }
                    if (evt.key === "E") {
                        if (this.state.step === this.steps.selectOptional) {
                            if (this.refs.runRegressionComponent) {
                                this.refs.runRegressionComponent.changeEditModel();
                            }
                        }
                    }

                    if (evt.key === "p") {
                        if (this.state.step >= this.steps.finalFirst) {
                            this.saveFinding();
                        }
                    }
                    if (evt.key === "d") {
                        if (this.state.step >= this.steps.finalFirst) {
                            evt.preventDefault();
                            this.showRecipesPopup();
                        }
                    }
                    if (evt.key === "ArrowLeft") {
                        if (this.state.step >= this.steps.finalFirst) {
                            this.back();
                        }
                    }
                    if (evt.key === "ArrowRight") {
                        if (
                            this.state.step > this.steps.initial &&
                            this.state.step < this.steps.finalFirst
                        ) {
                            this.showFinalStep(this.steps.finalFirst);
                        }
                        if (this.state.step === this.steps.finalFirst)
                            this.showFinalStep(this.steps.finalSecond);
                        if (this.state.step === this.steps.finalSecond)
                            this.showFinalStep(this.steps.finalThird);
                    }

                    let charCode = evt.key.charCodeAt(0) - "a".charCodeAt(0);
                    if (this.state.step === this.steps.selectIndependent) {
                        if (
                            charCode >= 0 &&
                            charCode < this.state.regressionVariables.length
                        ) {
                            this.selectVariable(
                                "selectedIndependentVariable",
                                "regressionVariables",
                                charCode
                            );
                        }
                    }
                    if (this.state.step === this.steps.selectInteraction) {
                        let independentVariableIndex =
                            this.state.regressionVariables.indexOf(
                                this.state.selectedIndependentVariable
                            );

                        if (charCode === independentVariableIndex) return;
                        if (
                            charCode >= 0 &&
                            charCode < this.state.regressionVariables.length
                        ) {
                            this.selectVariable(
                                "interactionIndependentVariable",
                                "regressionVariables",
                                charCode
                            );
                        }
                    }
                    if (this.state.step === this.steps.selectDependent) {
                        let independentVariableIndex =
                            this.state.regressionVariables.indexOf(
                                this.state.selectedIndependentVariable
                            );
                        let independentVariableIndex2 =
                            this.state.regressionVariables.indexOf(
                                this.state.interactionIndependentVariable
                            );
                        if (
                            charCode === independentVariableIndex ||
                            charCode === independentVariableIndex2
                        )
                            return;
                        if (
                            charCode >= 0 &&
                            charCode < this.state.regressionVariables.length
                        ) {
                            this.selectVariable(
                                "selectedDependentVariable",
                                "regressionVariables",
                                charCode
                            );
                        }
                    }
                    if (
                        this.state.step === this.steps.selectAdditionalParameter
                    ) {
                        this.selectVariable(
                            "additionalParameter",
                            "additionalParameters",
                            charCode
                        );
                    }
                    if (
                        this.state.step === this.steps.selectAdditionalValue &&
                        this.getVariables().isMonthVariable(
                            this.state.additionalParameter
                        )
                    ) {
                        this.selectMonth(evt.key, true);
                    }
                    if (evt.key === "Enter") {
                        if (
                            this.state.step ===
                                this.steps.selectAdditionalValue &&
                            this.getVariables().isMonthVariable(
                                this.state.additionalParameter
                            ) &&
                            this.state.leftAdditionalValue &&
                            !this.state.leftAdditionalValueSelected
                        ) {
                            this.setState({
                                leftAdditionalValueSelected: true,
                            });
                            return;
                        }
                        if (
                            this.state.step ===
                                this.steps.selectAdditionalValue &&
                            this.getVariables().isMonthVariable(
                                this.state.additionalParameter
                            ) &&
                            this.state.leftAdditionalValueSelected &&
                            this.state.rightAdditionalValue
                        ) {
                            this.setState(
                                {
                                    rightAdditionalValueSelected: true,
                                },
                                () => {
                                    this.showFinalStep(this.steps.finalFirst);
                                }
                            );
                            return;
                        }
                    }
                }}
            >
                <div
                    className="my-row"
                    style={{ justifyContent: "space-between", height: "100%" }}
                >
                    <div
                        className="flex-column"
                        style={{
                            height: "100%",
                            justifyContent: "space-between",
                            width: rightBar ? "calc(100% - 52px)" : "100%",
                        }}
                    >
                        {this.buildHeader()}
                        <TransitionGroup
                            style={{
                                minHeight: "inherit",
                            }}
                        >
                            <CSSTransition
                                style={{
                                    height: "100%",
                                }}
                                key={this.state.step}
                                timeout={500}
                                classNames={"journeywizard-".concat(
                                    this.state.animationDirection || ""
                                )}
                            >
                                <div
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    {this.buildContent()}
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                        <Footer
                            showArrow={
                                this.state.step > this.steps.initial &&
                                this.state.step < this.steps.finalFirst
                            }
                            title={this.getFooterTitle()}
                        />
                    </div>
                    {rightBar}
                </div>
                {this.buildAddToRecipesPopup()}
                {this.buildSaveRegressionResultsPopup()}
            </div>
        );
    }
}

export { MainComponent };
