import inline from "./Inline";
import blockType from "./BlockType";
import fontSize from "./FontSize";
import lineHeight from "./LineHeight";
import letterSpacing from "./LetterSpacing";
import fontFamily from "./FontFamily";
import list from "./List";
import textAlign from "./TextAlign";
import colorPicker from "./ColorPicker";
import link from "./Link";
import embedded from "./Embedded";
import emoji from "./Emoji";
import image from "./Image";
import remove from "./Remove";
import history from "./History";

const defaultExport = {
    inline,
    blockType,
    fontSize,
    lineHeight,
    letterSpacing,
    fontFamily,
    list,
    textAlign,
    colorPicker,
    link,
    embedded,
    emoji,
    image,
    remove,
    history,
};

export default defaultExport;
