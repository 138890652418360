import React, { Component } from "react";
import { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button } from "react-bootstrap";
import Draggable from "react-draggable";
import Popup from "reactjs-popup";
import { observer } from "mobx-react";

import customSelectStyles from "common/SelectStyles";

import StatusPopup, { PopupStatus } from "common/StatusPopup";
import { BriefCanvas, SlideTemplate } from "common/Canvas";
import SlideTemplates from "common/SlideTemplates";
import {
    addNewCollectionTemplateApi,
    createCanvasTemplate,
} from "common/CanvasUserApi";
import CanvasTreeStore from "./CanvasTreeStore";

interface Props {
    briefCanvas?: BriefCanvas;
    onClose: (newSlide?: SlideTemplate | number) => void;
}

interface State {
    left: number;
    top: number;
    popupMessage: string;
    popupStatus: PopupStatus | null;
    newTemplateCollection: {
        label: string;
        value: number | null;
    } | null;
    templateCollection:
        | {
              label: string;
              value: number | null;
          }
        | undefined;
}

@observer
class SaveTemplatePopup extends Component<Props, State> {
    drag: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            left: 0,
            top: 0,
            newTemplateCollection: null,
            templateCollection: undefined,
            popupStatus: null,
            popupMessage: "",
        };

        this.publishToTemplates = this.publishToTemplates.bind(this);
    }

    public componentDidMount(): void {
        SlideTemplates.updateCollections();
    }

    public componentWillUnmount(): void {}

    private async publishToTemplates() {
        try {
            let collectionId = this.state.templateCollection!.value;
            this.setState({
                popupStatus: PopupStatus.Success,
                popupMessage: "Creating template...",
            });
            if (collectionId == null) {
                collectionId = await addNewCollectionTemplateApi(
                    this.state.templateCollection!.label
                );
            }
            if (this.props.briefCanvas != null) {
                let slideId = await createCanvasTemplate(
                    this.props.briefCanvas.title,
                    collectionId,
                    this.props.briefCanvas.id
                );

                SlideTemplates.update();
                this.props.onClose(slideId);

            } else {
                let innerCanvas = new CanvasTreeStore(true).serialize();
                let slideId = await createCanvasTemplate(
                    "New Theme",
                    collectionId,
                    undefined,
                    innerCanvas,
                    []
                );
                SlideTemplates.update();

                let newSlide = {
                    collection_id: collectionId,
                    id: slideId,
                    title: "New Theme",
                    canvas: innerCanvas,
                    backgrounds: [],
                };
                this.setState({
                    popupStatus: PopupStatus.Success,
                    popupMessage: "New theme is added",
                });
                this.props.onClose(newSlide);
            }

        } catch (error) {
            this.setState({
                popupStatus: PopupStatus.Error,
                popupMessage: String(error),
            });
        }
    }

    private buildInnerView(): JSX.Element {
        return (
            <div
                className="flex-simple-column"
                style={{
                    padding: "20px",
                }}
            >
                <span
                    className="content-regular-text"
                    style={{
                        color: "#75869a",
                        fontSize: "18px",
                        fontWeight: 500,
                    }}
                >
                    THEME COLLECTION CATEGORY
                </span>
                <CreatableSelect
                    inputId="select-data-scope"
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    onCreateOption={(option) => {
                        this.setState({
                            newTemplateCollection: {
                                label: option,
                                value: null,
                            },
                            templateCollection: {
                                label: option,
                                value: null,
                            },
                        });
                    }}
                    placeholder={"Select Categories (preset in Settings page)"}
                    styles={{
                        ...customSelectStyles,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                            height: "38px",
                            marginTop: "10px",
                            marginBottom: "15px",
                        }),
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 1000,
                        }),
                    }}
                    options={(
                        SlideTemplates.slideCollection.map((collection) => ({
                            label: collection.title,
                            value: collection.id,
                        })) as {
                            label: string;
                            value: number | null;
                        }[]
                    ).concat(
                        this.state.newTemplateCollection != null
                            ? [this.state.newTemplateCollection]
                            : []
                    )}
                    onChange={(newValue) => {
                        this.setState({
                            templateCollection: newValue as {
                                label: string;
                                value: number | null;
                            },
                        });
                    }}
                    value={this.state.templateCollection}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        marginTop: "15px",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            width: "6em",
                            height: "33px",
                            paddingTop: 0,
                            paddingBottom: 0,
                            backgroundColor: "white",
                            borderColor: "1px solid #3B82C9",
                            fontSize: 16,
                            color: "#3B82C9",
                            borderRadius: 6,
                            fontWeight: 500,
                        }}
                        onClick={()=>{this.props.onClose()}}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            width: "6em",
                            height: "33px",
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginLeft: "5px",
                            backgroundColor: "#3B82C9",
                            fontSize: 16,
                            borderRadius: 6,
                            fontWeight: 500,
                        }}
                        disabled={this.state.templateCollection == null}
                        onClick={this.publishToTemplates}
                    >
                        Save
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const width: number = 500;
        return (
            <>
                <div
                    style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#00000022",
                        zIndex: 999,
                    }}
                ></div>
                <Popup
                    arrow={true}
                    contentStyle={{
                        width: width,
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    open={true}
                    onClose={() => {}}
                    closeOnDocumentClick={false}
                >
                    <Draggable
                        cancel="input"
                        position={{
                            x: this.state.left,
                            y: this.state.top,
                        }}
                        onDrag={() => {
                            this.drag = true;
                        }}
                        onStop={(_evt, data) => {
                            if (this.drag) {
                                this.drag = false;
                                this.setState({
                                    left: data.x,
                                    top: data.y,
                                });
                            }
                        }}
                    >
                        <div
                            className="dashboard-rect element"
                            style={{
                                overflowX: "visible",
                                overflowY: "auto",
                                boxShadow: "0 12px 24px 0 rgba(0,0,0,0.1)",
                                borderRadius: 12,
                                alignItems: "center",
                                cursor: "pointer",
                                maxHeight: "100vh",
                                width: width,
                            }}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            {this.buildInnerView()}
                        </div>
                    </Draggable>
                </Popup>
                {this.state.popupStatus != null && (
                    <StatusPopup
                        onClose={() => {
                            this.setState({
                                popupMessage: "",
                                popupStatus: null,
                            });
                        }}
                        status={this.state.popupStatus}
                        message={this.state.popupMessage}
                    />
                )}
            </>
        );
    }
}

export default SaveTemplatePopup;
