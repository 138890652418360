const validReferralLinks = [
    "product_hunt",
    "challenge",
    "course",
    "test"
];

function referralLinkIsValid(referralLink: string): boolean  {
    return validReferralLinks.includes(referralLink);
}

export default referralLinkIsValid;
