import React, { Component } from "react";
import { observer } from "mobx-react";
import { mainStyle } from "common/MainStyle";
import "common/styles/App.css";
import axios from "common/ServerConnection";
import CanvasContent from "modules/canvas_page/CanvasContent";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import HeaderBarRevamped from "common/HeaderBarRevamped";
import headerStyles from "common/HeaderBarRevamped/HeaderBar.module.css";
import RightTopBar from "modules/canvas_page/RightTopBar";
import BottomBar from "modules/canvas_page/BottomBar";
import LoadSharedModulePopup from "common/LoadSharedModulePopup";
import LoadSharedSlidePopup from "modules/canvas_page/LoadSharedSlidePopup";
import PopupCardsContent from "modules/canvas_page/PopupCardsContent";
import OutsideAlerter from "common/OutsideAlerter";
import ContactUserPopup from "modules/canvas_page/ContactUserPopup";
import mobileBreakpoint, {
    calcScale,
    isLandscape,
    getWidth,
    getHeight,
    mobileAndTabletBreakpoint,
    tabletBreakpoint,
} from "common/utilities/UIResponsiveManager";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import LinkSlidePopup from "modules/canvas_page/LinkSlidePopup";
import StringUtils from "common/utilities/StringUtils";
import { isSubmitButton, isBox, SharedLinkScrollOption } from "common/Canvas";
import remoteModuleId from "common/remoteModuleId";
import { CSSTransition } from "react-transition-group";
import { Ouroboro } from "react-spinners-css";
import ManageAppPopup from "common/canvas/ManageAppPopup";
import FadeStyles from "modules/canvas_page/transitions/FadeTransition.module.css";
import _ from "lodash";
import { appGetPermissions } from "common/ModulesApi";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { visibilityChangeProps } from "common/utilities/VisibilityChange";
import Instrumentation, { InstrumentationStatus } from "common/Instrumentation";
import FontLoader from "common/FontLoader";
import FloatingMessage from "common/FloatingMessage";
import styles from "./CanvasModuleSharedPage.module.css";
import TermsOfServiceModal from "common/TermsOfServiceModal";
import TosContext, { TosProvider } from "contexts/TosContext";

const fadeTimeout = 100;

const visibilityProps = visibilityChangeProps(document);

const CanvasModuleSharedPageWrapper = (props) => {

    if (props.requireAuth) {
        props.getAccessTokenSilently().then((accessToken) => {

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${accessToken}`;

            // decode auth0 access token to get the username
            const base64Url = accessToken.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function (c) {
                        return (
                            "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                    })
                    .join("")
            );            
            let jsonPayloadObj = JSON.parse(jsonPayload);

            // set username in local storage
            let username = jsonPayloadObj.sub.split("|").pop();
            localStorage.setItem('username', username);
        });
        return (
            <TosProvider>
                <CanvasModuleSharedPage {...props} />
            </TosProvider>
        );
    } else {
        console.log('aew free')
        return <CanvasModuleSharedPage {...props} />;
    }
};

const CanvasModuleSharedPage = observer(
    class CanvasModuleSharedPage extends Component {
        static contextType = TosContext;

        constructor(props) {
            const urlParams = new URLSearchParams(window.location.search);
            let hideTop = urlParams.get("hide_top") === "1";
            let hideLinks = urlParams.get("hide_links") === "1";
            let sessionId = urlParams.get("session_id");
            super(props);
            this.canvasTreeStore = new CanvasTreeStore();
            this.canvasTreeStore.shared = true;

            if (tabletBreakpoint() && !isLandscape()) {
                this.canvasTreeStore.canvasViewMode = "mobile";
            } else if (mobileBreakpoint()) {
                this.canvasTreeStore.canvasViewMode = "mobile";
            }

            this.state = {
                showManageAppMenuOption: false,
                appPermissions: [],
                showManageAppPopup: false,
                fadeActive: false,
                currentNodePopup: undefined,
                remoteModuleId: remoteModuleId,
                loaded: false,
                showLoadPopup: false,
                sharedModule: undefined,
                landscapeOrientation: isLandscape(),
                innerWidth: getWidth(),
                innerHeight: getHeight(),
                linkSlideCanvas: null,
                hideTop: hideTop,
                hideLinks: hideLinks,
                sessionId: sessionId,
            };
            this.rootRef = React.createRef();
            this.rootSectionRef = React.createRef();
            this.updateDimensions = this.updateDimensions.bind(this);
            this.canvasContentRef = React.createRef();
            this.initialSize = this.initialSize.bind(this);
            this.fitScale = this.fitScale.bind(this);
            this.changeScale = this.changeScale.bind(this);
            this.loadCanvasClick = this.loadCanvasClick.bind(this);
            this.loadCanvas = this.loadCanvas.bind(this);
            this.onCanvasClick = this.onCanvasClick.bind(this);
            this.expandCard = this.expandCard.bind(this);
            this.expandUserCard = this.expandUserCard.bind(this);
            this.updateSharedBox = this.updateSharedBox.bind(this);
            this.canvasTreeStore.onUpdateSharedBoxInModule = this.updateSharedBox;
            this.updateSharedCanvas = this.updateSharedCanvas.bind(this);
            this.canvasTreeStore.onUpdateSharedCanvasInModule = this.updateSharedCanvas;
            this.goToCanvas = this.goToCanvas.bind(this);
            this.scrollToTop = this.scrollToTop.bind(this);
            this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
            this.onWindowFocusChanged = this.onWindowFocusChanged.bind(this);
            this.logout = this.logout.bind(this);
            this.acceptTermsOfService = this.acceptTermsOfService.bind(this);

            window.addEventListener(
                "orientationchange",
                (e) => {
                    const orientation = e.target.screen.orientation.angle;
                    if (orientation === 0 || orientation === 180) {
                        // Portrait
                        this.canvasTreeStore.canvasViewMode = "mobile";
                    } else {
                        // Landscape
                        this.canvasTreeStore.canvasViewMode = "desktop";
                    }
                },
                false
            );

            this.windowIsFocused = true;
            this.lastInstrumentationStatus = InstrumentationStatus.Inactive;
        }

        logout() {
            Instrumentation.close().then(() => {
                this.props.auth0Logout({
                    returnTo: this.props.auth0LogoutRedirectTo,
                });
            });
        }

        acceptTermsOfService() {
            axios.post("/api/tos_save_agree", {}).then((response) => {
                if (response.data.success) {
                    this.context.setAgreed(true);
                }
            });
        }

        onVisibilityChanged() {
            // No need to check getInternalLinkActive
            // if (getInternalLinkActive()) return;
            if (this.props.requireAuth) {
                if (document[visibilityProps.hidden] || !this.windowIsFocused) {
                    if (
                        this.lastInstrumentationStatus !==
                        InstrumentationStatus.Inactive
                    ) {
                        Instrumentation.setStatus(
                            InstrumentationStatus.Inactive
                        );
                        this.lastInstrumentationStatus =
                            InstrumentationStatus.Inactive;
                    }
                } else {
                    if (
                        this.lastInstrumentationStatus !==
                        InstrumentationStatus.Active
                    ) {
                        Instrumentation.setStatus(InstrumentationStatus.Active);
                        this.lastInstrumentationStatus =
                            InstrumentationStatus.Active;
                    }
                }
            }
        }

        onWindowFocusChanged(event) {
            this.windowIsFocused = event.type === "focus";
            this.onVisibilityChanged();
        }

        scrollToTop() {
            if (this.rootSectionRef.current)
                this.rootSectionRef.current.scrollTop = 0;
        }
        componentDidMount() {
            FontLoader.update();
            this.setAppHeight();
            if (visibilityProps.supported) {
                window.addEventListener("blur", this.onWindowFocusChanged);
                window.addEventListener("focus", this.onWindowFocusChanged);
                document.addEventListener(
                    visibilityProps.visibilityChange,
                    this.onVisibilityChanged,
                    false
                );
            }

            this.updateDimensions();
            window.addEventListener("resize", this.updateDimensions);
            if (this.rootRef.current != null)
                this.rootRef.current.focus({ preventScroll: true });
            if (remoteModuleId != null && this.props.requireAuth) {
                appGetPermissions(remoteModuleId)
                    .then((permissions) => {
                        this.setState({
                            showManageAppMenuOption: true,
                            appPermissions: permissions,
                        });
                    })
                    .catch((error) => {});
            }
            if (this.props.requireAuth) {
                // Add section visit
                Instrumentation.addSectionVisit("Canvas");

                // Check whether  the user agreed to TOS
                axios.get("/api/tos_check_user_accept").then((response) => {
                    this.context.setAgreed(response.data.agree_flag);
                });
            }
        }
        async loadCanvas(canvas) {
            const innerLoading = () => {
                this.canvasTreeStore.isLoadingState = true;

                if (this.canvasContentRef.current != null)
                    this.canvasContentRef.current.clearEditing();
                this.setState({
                    currentNodePopup: undefined,
                });
                let pageSwitched =
                    canvas.page_id !== this.canvasTreeStore.canvasPageId;
                if (pageSwitched) {
                    this.canvasTreeStore.canvasPageId = canvas.page_id;
                
                }
                this.canvasTreeStore.deserializeFromSharedModuleAsyncAction.bothParts(
                    canvas,
                    this.state.sharedModule,
                    this.props.requireAuth
                );
                
                this.canvasTreeStore.canvasId = canvas.id;
                this.canvasTreeStore.isLoadingState = false;
                if (this.rootRef.current != null)
                    this.rootRef.current.focus({ preventScroll: true });
                this.fitScale();
            };
            if (this.state.sharedModule?.show_as_slideshow) {
                this.setState({ fadeActive: true }, () => {
                    this.setState({ fadeActive: false }, () =>
                        setTimeout(innerLoading, fadeTimeout)
                    );
                });
            } else {
                innerLoading();
            }
        }
        updateSharedBox(sharedId, sharedNode) {
            let sharedModule = _.cloneDeep(this.state.sharedModule);
            for (let page of sharedModule.pages) {
                for (let sheet of page.sheets) {
                    for (let sharedBox of sheet.shared_boxes ?? []) {
                        if (sharedBox.id === sharedId) {
                            sharedBox.box = sharedNode;
                        }
                    }
                }
            }
            this.setState({ sharedModule: sharedModule });
        }
        updateSharedCanvas(canvasId, canvas, backgrounds) {
            let sharedModule = _.cloneDeep(this.state.sharedModule);
            for (let page of sharedModule.pages) {
                for (let sheet of page.sheets) {
                    if (sheet.id === canvasId) {
                        sheet.canvas = canvas;
                        sheet.backgrounds = backgrounds;
                        break;
                    }
                }
            }
            this.setState({ sharedModule: sharedModule });
        }
        allCanvases() {
            return [...this.state.sharedModule.pages.values()]
                .map((item) => item.sheets)
                .flat();
        }
        popupHeight() {
            return this.state.innerHeight * 0.7;
        }
        popupWidth() {
            return this.state.innerWidth * 0.7;
        }
        onCanvasClick(canvas) {
            if (this.state.hideLinks) return;
            this.setState({
                linkSlideCanvas: canvas,
                currentNodePopup: null,
            });
        }
        goToCanvas(canvas) {
            if (this.state.hideLinks) return;
            this.loadCanvas(canvas);
        }
        expandCard(node, popup) {
            if (this.state.hideLinks) return;
            if ((isBox(node) || isSubmitButton(node)) && node.external) {
                if (node.externalLink)
                    window.open(
                        StringUtils.addSchemaToUrl(node.externalLink),
                        "_blank"
                    );
                return;
            }
            let canvases = this.allCanvases();
            if (
                node.links.length === 1 &&
                (node.linkPopups == null || node.linkPopups.length === 0)
            ) {
                let canvas = canvases.find(
                    (canvas) => canvas.id === node.links[0]
                );

                if (canvas != null) {
                    if (
                        isSubmitButton(node) &&
                        (node.sharedLinkScrollOption == null ||
                            node.sharedLinkScrollOption ===
                                SharedLinkScrollOption.ScrollToTop)
                    ) {
                        this.scrollToTop();
                    }
                    this.goToCanvas(canvas);
                }
            } else if (
                node.linkPopups != null &&
                node.linkPopups.length === 1 &&
                node.links.length === 0
            ) {
                let canvas = canvases.find(
                    (canvas) => canvas.id === node.linkPopups[0]
                );
                if (canvas != null) {
                    this.setState({
                        linkSlideCanvas: canvas,
                    });
                }
            } else {
                this.setState({
                    currentNodePopup: node,
                });
            }
            if (popup != null) {
                this.setState({
                    popupStatus: popup.status,
                    popupMessage: popup.message,
                });
            }
        }
        expandUserCard(node) {
            this.setState({
                currentUserNodePopup: node,
            });
        }
        componentWillUnmount() {
            window.removeEventListener("resize", this.updateDimensions);

            if (visibilityProps.supported) {
                window.removeEventListener("blur", this.onWindowFocusChanged);
                window.removeEventListener("focus", this.onWindowFocusChanged);
                document.removeEventListener(
                    visibilityProps.visibilityChange,
                    this.onVisibilityChanged
                );
            }
        }

        setAppHeight() {
            let vh = window.innerHeight;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        }

        updateDimensions() {
            calcScale();
            this.setAppHeight();
            let innerWidth = getWidth();
            let innerHeight = getHeight();
            this.setState(
                {
                    landscapeOrientation: isLandscape(),
                    innerWidth: innerWidth,
                    innerHeight: innerHeight,
                },
                () => {
                    this.canvasTreeStore.setInitialSize(this.initialSize());
                    this.fitScale();
                }
            );
        }

        async changeSlideShowSlide(sign) {
            let canvases = this.allCanvases().filter(
                (canvas) =>
                    !canvas.hide_in_slideshow && !canvas.layer_for_canvas_id
            );
            let index = canvases.findIndex(
                (canvas) => canvas.id === this.canvasTreeStore.canvasId
            );
            if (index === -1) {
                if (canvases.length > 0) await this.loadCanvas(canvases[0]);
                return;
            }
            index = index + sign;
            if (index >= 0 && index < canvases.length)
                await this.loadCanvas(canvases[index]);
        }

        initialSize() {
            let initialSize = {
                width: this.state.innerWidth,
                height: this.state.innerHeight,
            };
            return initialSize;
        }
        changeScale(scale) {
            this.canvasTreeStore.scale = scale;
        }
        fitScale() {
            this.canvasTreeStore.calculateFitScale(
                true,
                this.state.showLoadPopup,
                this.state.sharedModule?.show_as_slideshow
            );
        }
        loadCanvasClick() {
            this.setState(
                (state) => ({ showLoadPopup: !state.showLoadPopup }),
                () => {
                    //    this.fitScale();
                }
            );
        }
        render() {
            // We don't need to check visibility once here since we already
            // added a section visit in componentDidMount

            let showAsSlideShow =
                this.state.loaded && this.state.sharedModule?.show_as_slideshow;
            let wrapperStyle = {
                marginLeft: 0,
                outline: "none",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                backgroundColor: showAsSlideShow ? "black" : undefined,
            };
            let pages = this.state.sharedModule?.pages.map((page) => ({
                id: page.pageId,
                title: page.pageId,
                module_id: NaN,
            }));
            return (
                <DndProvider backend={HTML5Backend}>
                    {this.state.loaded ? (
                        <>
                            {!showAsSlideShow && !this.state.hideTop && (
                                <HeaderBarRevamped
                                    hideUserMenu={!this.props.requireAuth}
                                    additionalUserMenuOptions={
                                        this.state.showManageAppMenuOption
                                            ? [
                                                  {
                                                      name: "Edit permissions",
                                                      handler: () => {
                                                          this.setState({
                                                              showManageAppPopup: true,
                                                          });
                                                      },
                                                  },
                                              ]
                                            : []
                                    }
                                    hideNotifications
                                    hideNavigationMenu
                                    hideAdvancedUserMenu
                                    shared={true}
                                    hoverMode={this.canvasTreeStore.live}
                                    centerComponent={
                                        <div
                                            className={
                                                headerStyles.canvasBarContainer
                                            }
                                        >
                                            <div style={{ flex: 0.5 }} />
                                            <h1
                                                className={
                                                    headerStyles.canvasTitle
                                                }
                                            >
                                                {this.state.sharedModule.title}
                                            </h1>
                                            <div style={{ flex: 0.5 }} />
                                            <RightTopBar
                                                showShareButton={false}
                                                forceCanOpenRibbon={false}
                                                show={true}
                                                live={this.canvasTreeStore.live}
                                                scale={
                                                    this.canvasTreeStore.scale
                                                }
                                                ribbonOpened={
                                                    this.state.showLoadPopup
                                                }
                                                onLiveChange={() => {}}
                                                onOpenRibbonChange={
                                                    this.loadCanvasClick
                                                }
                                                onShowPageBar={this.showPageBar}
                                                onShare={this.shareClick}
                                                onZoomFit={this.fitScale}
                                                onZoomChange={this.changeScale}
                                            />
                                        </div>
                                    }
                                ></HeaderBarRevamped>
                            )}

                            <div
                                className="content-wrapper no-top-padding"
                                style={wrapperStyle}
                                ref={this.rootRef}
                                tabIndex={0}
                                onKeyDown={async (evt) => {
                                    if (
                                        this.state.sharedModule
                                            ?.show_as_slideshow
                                    ) {
                                        if (
                                            evt.key === "ArrowLeft" ||
                                            evt.key === "ArrowRight"
                                        ) {
                                            evt.stopPropagation();
                                            evt.preventDefault();
                                            this.changeSlideShowSlide(
                                                evt.key === "ArrowLeft"
                                                    ? -1
                                                    : 1
                                            );
                                        }
                                    }
                                }}
                            >
                                <section
                                    ref={this.rootSectionRef}
                                    className="content element"
                                    style={{
                                        ...this.props.positionStyle,
                                        flexGrow: 1,
                                        width: "100%",
                                        overflow: "hidden auto",
                                        paddingLeft: "0px",
                                        paddingBottom: "0px",
                                        paddingTop: "0px",
                                        paddingRight: "0px",
                                    }}
                                >
                                    <CSSTransition
                                        in={this.state.fadeActive}
                                        timeout={fadeTimeout}
                                        classNames={{
                                            enterActive:
                                                FadeStyles[
                                                    "fade-enter-active"
                                                ],
                                            enterDone:
                                                FadeStyles["fade-enter"],
                                            exitActive:
                                                FadeStyles[
                                                    "fade-exit-active"
                                                ],
                                            exitDone:
                                                FadeStyles["fade-exit"],
                                        }}
                                    >
                                        <>
                                            <CanvasContent
                                                onScrollToTop={
                                                    this.scrollToTop
                                                }
                                                pages={pages}
                                                canvasTreeStore={
                                                    this.canvasTreeStore
                                                }
                                                showAsSlideShow={
                                                    showAsSlideShow
                                                }
                                                ribbonIsOpen={false}
                                                sharedPolicy={
                                                    CanvasSharedPolicy.SharedModuleUnAuth
                                                }
                                                onZoomChange={
                                                    this.changeScale
                                                }
                                                onOpenBottomPortal={() => {}}
                                                hidden={
                                                    this.canvasTreeStore
                                                        .isLoadingState
                                                }
                                                canWrite={true}
                                                scale={
                                                    this.canvasTreeStore
                                                        .scale
                                                }
                                                ref={this.canvasContentRef}
                                                live={true}
                                                selectionIsActive={false}
                                                showSlideNumbers={
                                                    this.state.sharedModule
                                                        ?.options
                                                        ?.slideNumberOptions
                                                        ?.show
                                                }
                                                showPageBar={
                                                    !this.canvasTreeStore
                                                        .hidePagesBar &&
                                                    (this.state.sharedModule
                                                        ?.page_bar_info
                                                        ?.show ??
                                                        false) &&
                                                    !(
                                                        this.state
                                                            .sharedModule
                                                            ?.page_bar_info
                                                            ?.hiddenOnPage ??
                                                        false
                                                    )
                                                }
                                                pageBarInfo={
                                                    this.state.sharedModule
                                                        ?.page_bar_info
                                                }
                                                sharedModule={
                                                    this.state.sharedModule
                                                }
                                                onExpandCard={
                                                    this.expandCard
                                                }
                                                onExpandUserCard={
                                                    this.props.requireAuth
                                                        ? this
                                                                .expandUserCard
                                                        : (node) => {}
                                                }
                                                disableZoomWheel={
                                                    this.state
                                                        .linkSlideCanvas !=
                                                    null
                                                }
                                            />
                                            {this.canvasTreeStore
                                                .isLoadingState && (
                                                <div
                                                    style={{
                                                        top: 0,
                                                        left: 0,
                                                        position:
                                                            "absolute",
                                                        width: "100%",
                                                        height: 300,
                                                        display: "flex",
                                                        alignItems:
                                                            "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <span
                                                        className="text-center"
                                                        style={{
                                                            color: mainStyle.getPropertyValue(
                                                                "--primary-text-color"
                                                            ),
                                                            fontFamily:
                                                                "Roboto",
                                                            fontSize:
                                                                "25px",
                                                        }}
                                                    >
                                                        <Ouroboro color="#323232" />
                                                    </span>
                                                </div>
                                            )}
                                        </>
                                    </CSSTransition>
                                </section>
                                {(!this.state.sharedModule
                                    ?.show_as_slideshow ||
                                    !this.canvasTreeStore.live) && (
                                    <BottomBar
                                        live={true}
                                        time={
                                            this.state.sharedModule
                                                .creation_time
                                        }
                                        viewCount={`views: ${this.state.sharedModule.view_count}`}
                                        onCommandPaletteClick={() => {}}
                                    />
                                )}
                            </div>
                            {this.canvasTreeStore.canvasPageId != null &&
                                this.state.showLoadPopup && (
                                    <LoadSharedSlidePopup
                                        sharedModule={this.state.sharedModule}
                                        onCanvasClick={this.onCanvasClick}
                                        canvasId={this.canvasTreeStore.canvasId}
                                        pageId={
                                            this.canvasTreeStore.canvasPageId
                                        }
                                    />
                                )}
                            {this.props.requireAuth &&
                                this.state.currentUserNodePopup && (
                                    <ContactUserPopup
                                        onReject={() => {
                                            this.setState({
                                                currentUserNodePopup: undefined,
                                            });
                                        }}
                                        left={Math.min(
                                            this.state.currentUserNodePopup.x +
                                                250,
                                            Math.max(
                                                this.state.innerWidth - 500,
                                                0
                                            )
                                        )}
                                        top={Math.min(
                                            this.state.currentUserNodePopup.y +
                                                200,
                                            Math.max(
                                                this.state.innerHeight - 500,
                                                0
                                            )
                                        )}
                                        userInfo={this.canvasTreeStore.connectedUsersState.get(
                                            this.state.currentUserNodePopup
                                                .delegate
                                        )}
                                    />
                                )}
                            {this.state.currentNodePopup && (
                                <OutsideAlerter
                                    onReject={() => {
                                        this.setState({
                                            currentNodePopup: undefined,
                                        });
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: Math.min(
                                                this.state.currentNodePopup
                                                    .nodePosition[
                                                    this.canvasTreeStore
                                                        .canvasViewMode
                                                ].y,
                                                Math.max(
                                                    this.state.innerHeight -
                                                        500,
                                                    0
                                                )
                                            ),
                                            cursor: "pointer",
                                            left: Math.min(
                                                this.state.currentNodePopup
                                                    .nodePosition[
                                                    this.canvasTreeStore
                                                        .canvasViewMode
                                                ].x,
                                                Math.max(
                                                    this.state.innerWidth - 500,
                                                    0
                                                )
                                            ),
                                            zIndex: 998,
                                            maxWidth:
                                                this.state.innerWidth -
                                                Math.min(
                                                    this.state.currentNodePopup
                                                        .nodePosition[
                                                        this.canvasTreeStore
                                                            .canvasViewMode
                                                    ].x,
                                                    Math.max(
                                                        this.state.innerWidth -
                                                            500,
                                                        0
                                                    )
                                                ),
                                        }}
                                    >
                                        <PopupCardsContent
                                            onLinkClick={this.goToCanvas}
                                            onLinkPopupClick={
                                                this.onCanvasClick
                                            }
                                            links={(
                                                this.state.currentNodePopup
                                                    .links || []
                                            )
                                                .map((link) =>
                                                    this.allCanvases().find(
                                                        (canvas) =>
                                                            canvas.id === link
                                                    )
                                                )
                                                .filter((item) => item)}
                                            linkPopups={(
                                                this.state.currentNodePopup
                                                    .linkPopups || []
                                            )
                                                .map((link) =>
                                                    this.allCanvases().find(
                                                        (canvas) =>
                                                            canvas.id === link
                                                    )
                                                )
                                                .filter((item) => item != null)}
                                            cards={
                                                this.props.requireAuth
                                                    ? this.state.currentNodePopup.popups
                                                          .map((popup) => ({
                                                              type: popup.type,
                                                              hash: popup.hash,
                                                              item: this.canvasTreeStore.connectedCardsState.get(
                                                                  popup.hash
                                                              ),
                                                          }))
                                                          .filter(
                                                              (item) =>
                                                                  item.item
                                                          )
                                                    : []
                                            }
                                        />
                                    </div>
                                </OutsideAlerter>
                            )}
                            {this.state.linkSlideCanvas != null && (
                                <LinkSlidePopup
                                    windowSize={this.initialSize()}
                                    onDblClick={() => {
                                        let canvas = this.state.linkSlideCanvas;
                                        this.setState({
                                            linkSlideCanvas: null,
                                        });
                                        this.loadCanvas(canvas);
                                    }}
                                    onClose={() => {
                                        this.canvasTreeStore.updateAllAsyncAction.bothParts(
                                            false,
                                            this.canvasTreeStore.canvasTreeState
                                        );
                                        this.setState({
                                            linkSlideCanvas: null,
                                        });
                                        this.rootRef.current?.focus({
                                            preventScroll: true,
                                        });
                                    }}
                                    moduleId={this.state.remoteModuleId}
                                    requireAuthentication={
                                        this.props.requireAuth
                                    }
                                    canvas={this.state.linkSlideCanvas}
                                    sharedModule={this.state.sharedModule}
                                />
                            )}
                            {this.state.showManageAppPopup && (
                                <ManageAppPopup
                                    initialSelectedUserGroups={
                                        this.state.appPermissions
                                    }
                                    onClose={() => {
                                        this.setState({
                                            showManageAppPopup: false,
                                        });
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <LoadSharedModulePopup
                            requireAuth={this.props.requireAuth}
                            isTutorial={this.props.isTutorial}
                            linkId={this.state.remoteModuleId}
                            sessionId={this.state.sessionId}
                            onLoad={(sharedModule) => {
                                this.setState(
                                    {
                                        sharedModule: sharedModule,
                                        loaded: true,
                                    },
                                    () => {
                                        const urlParams = new URLSearchParams(
                                            window.location.search
                                        );
                                        let canvasId = urlParams.get(
                                            "canvas_id"
                                        );
                                        if (canvasId != null) {
                                            canvasId = Number(canvasId);
                                            for (let page of this.state
                                                .sharedModule.pages) {
                                                let canvas = page?.sheets.find(
                                                    (sheet) =>
                                                        sheet.id === canvasId
                                                );
                                                if (canvas != null) {
                                                    this.loadCanvas(canvas);
                                                    break;
                                                }
                                            }
                                        } else {
                                            let allCanvases = this.allCanvases();
                                            if (
                                                this.state.sharedModule
                                                    ?.show_as_slideshow
                                            )
                                                allCanvases = allCanvases.filter(
                                                    (canvas) =>
                                                        !canvas.hide_in_slideshow
                                                );
                                            if (
                                                allCanvases.length > 0 &&
                                                allCanvases[0] != null
                                            ) {
                                                this.loadCanvas(allCanvases[0]);
                                            }
                                        }
                                    }
                                );
                            }}
                        />
                    )}
                    {/* ESC does not do anything in shared links
                        !tabletBreakpoint() && (
                        <FloatingMessage show={showAsSlideShow} timeout={4000}>
                            <h4>
                                Press <span>ESC</span> to exit presentation mode
                            </h4>
                        </FloatingMessage>
                    )*/}
                    {!tabletBreakpoint() && (
                        <FloatingMessage
                            show={showAsSlideShow}
                            timeout={4000}
                            className={styles.slideshowArrowHint}
                        >
                            <h4>
                                Press <span>&#8594;</span> to move to next slide
                            </h4>
                        </FloatingMessage>
                    )}
                    {this.props.requireAuth && (
                        <TermsOfServiceModal
                            show={!this.context.agreed}
                            cancel={this.logout}
                            confirm={this.acceptTermsOfService}
                            dialogClassName={styles.tosDialog}
                            style={{ zIndex: 100000001 }}
                        />
                    )}
                </DndProvider>
            );
        }
    }
);

export default CanvasModuleSharedPageWrapper;
