import React from "react";
import MapElementWrapper from "common/graphics/v2/MapElement";
import { MapFinding } from "common/Finding";

interface Props {
    live: boolean;
    scale: number;
    currentModuleId: number | undefined;
    dashboardId: string;
    finding: MapFinding;
    onNewFinding?: (finding: MapFinding, updateData?: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
    width: number;
    height: number;
}

function MainComponent(props: Props) {
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <MapElementWrapper
                live={props.live}
                scale={props.scale}
                mapId={props.dashboardId}
                editable={props.editable}
                preview={props.preview}
                mapFinding={props.finding}
                columnDragActive={props.columnDragActive}
                onChange={props.onNewFinding ?? (() => {})}
                currentModuleId={props.currentModuleId}
                width={props.width}
                height={props.height}
            />
        </div>
    );
}

export { MainComponent };
