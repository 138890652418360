// Modified version of https://github.com/fabid/d3-rectbin
// import * as d3 from "d3";

export default function Component () {
    var rectbinX = function (d) {
            return d[0];
        },
        rectbinY = function (d) {
            return d[1];
        };

    var dx = 0.1,
        dy = 0.1,
        x = rectbinX,
        y = rectbinY;

    function rectbin(points) {
        var binsById = {};
        // var xExtent = d3.extent(points, function (d, i) {
        //     return x.call(rectbin, d, i);
        // });
        // var yExtent = d3.extent(points, function (d, i) {
        //     return y.call(rectbin, d, i);
        // });
        //
        // d3.range(yExtent[0], yExtent[1] + dx, dy).forEach(function (Y) {
        //     d3.range(xExtent[0], xExtent[1] + dx, dx).forEach(function (X) {
        //         var py = Y / dy;
        //         var pj = trunc(py);
        //         var px = X / dx;
        //         var pi = trunc(px);
        //         var id = pi + "-" + pj;
        //         var bin = (binsById[id] = []);
        //         bin.i = pi;
        //         bin.j = pj;
        //         bin.x = pi * dx;
        //         bin.y = pj * dy;
        //     });
        // });
        points.forEach(function (point, i) {
            var py = y.call(rectbin, point, i) / dy;
            var pj = trunc(py);
            var px = x.call(rectbin, point, i) / dx;
            var pi = trunc(px);

            var id = pi + "-" + pj;
            if (binsById[id] == null) {
                binsById[id] = [];
                binsById[id].i = pi;
                binsById[id].j = pj;
                binsById[id].x = pi * dx;
                binsById[id].y = pj * dy;
            }
            var bin = binsById[id];
            bin.push(point);
        });
        return Object.values(binsById);
    }

    rectbin.x = function (_) {
        if (!arguments.length) return x;
        x = _;
        return rectbin;
    };

    rectbin.y = function (_) {
        if (!arguments.length) return y;
        y = _;
        return rectbin;
    };

    rectbin.dx = function (_) {
        if (!arguments.length) return dx;
        dx = _;
        return rectbin;
    };

    rectbin.dy = function (_) {
        if (!arguments.length) return dy;
        dy = _;
        return rectbin;
    };

    return rectbin;
}

function trunc(x) {
    return x < 0 ? Math.ceil(x) : Math.floor(x);
}
