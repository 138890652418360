import React from "react";
import Select, { createFilter } from "react-select";
import { dataScienceSelectStyles } from "common/SelectStyles";
import { DataScopeOption } from "common/DataScopes";
import { observer } from "mobx-react";
import Variables, { VariableOption } from "common/Variables";
import { Button } from "react-bootstrap";
import { MapVariableOption } from "common/Canvas";
import { defaultMapColorOptions } from "../Constants";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";

interface Props {
    dataScope: DataScopeOption;
    variables: (MapVariableOption | null)[];
    onChange: (variableOptions: (MapVariableOption | null)[]) => void;
    currentModuleId?: number;
}

const MapVariablesSelectorView = observer(function MapVariablesSelectorView(
    props: Props
) {
    let values = props.variables.map((item) => item?.variable.value ?? null);
    let variableOptions = Variables(
        props.dataScope.value,
        props.currentModuleId ?? remoteModuleId
    ).variableOptions.filter((item) => !values.includes(item.value));

    return (
        <div
            className="flex-simple-column"
            style={{
                marginLeft: "90px",
            }}
        >
            {props.variables.map((value, index) => (
                <React.Fragment key={index}>
                    <div
                        style={{ alignItems: "center" }}
                        className="my-row"
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt) => {
                            evt.stopPropagation();
                        }}
                    >
                        <Select
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={"Select variable"}
                            styles={{
                                ...dataScienceSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    width: "100%",
                                    height: "35px",
                                }),
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 51,
                                }),
                            }}
                            options={variableOptions}
                            onChange={(newValue) => {
                                let variables = Array.from(props.variables);
                                if (variables[index] != null) {
                                    variables[
                                        index
                                    ]!.variable = newValue as VariableOption;
                                } else {
                                    variables[index] = {
                                        variable: newValue as VariableOption,
                                        options: { ...defaultMapColorOptions },
                                    };
                                }
                                props.onChange(variables);
                            }}
                            value={value?.variable ?? null}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25: "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <div style={{ flexGrow: 1 }} />

                        <div
                            className="flex-simple-column"
                            style={{ marginLeft: 5 }}
                        >
                            <Button
                                className={"btn-small-questionnaire"}
                                title={"Add variable"}
                                style={{
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let variables = Array.from(props.variables);
                                    variables.push(null);
                                    props.onChange(variables);
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>

                            <Button
                                className={"btn-small-questionnaire"}
                                title={"Remove variable"}
                                style={{
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let variables = Array.from(props.variables);
                                    variables.splice(index, 1);
                                    if (variables.length === 0) {
                                        variables.push(null);
                                    }
                                    props.onChange(variables);
                                }}
                            >
                                {"\uFF0D" /* minus */}
                            </Button>
                        </div>
                    </div>
                    <div
                        style={{
                            height: "2px",
                            backgroundColor: dataScienceElementsStyle.secondaryTextColor,
                            marginBottom: "20px",
                        }}
                    />
                </React.Fragment>
            ))}
        </div>
    );
});
export default MapVariablesSelectorView;
