import React, { Component, CSSProperties } from "react";
import Select, { createFilter } from "react-select";

import {
    typeParameters,
    sectionParametersOf,
    elementParametersOf,
    variableParametersOf,
    TypeParameter,
    SectionParameter,
    ElementParameter,
    VariableParameter,
} from "./Parameters";
import customSelectStyles from "common/SelectStyles";

interface Props {
    type: TypeParameter | null;
    section: SectionParameter | null;
    element: ElementParameter | null;
    variable: VariableParameter | null;
    onChange: (
        type: TypeParameter | null,
        section: SectionParameter | null,
        element: ElementParameter | null,
        variable: VariableParameter | null
    ) => void;
    style?: CSSProperties;
}

class ParameterSelector extends Component<Props> {
    render() {
        let sectionOptions =
            this.props.type != null ? sectionParametersOf(this.props.type) : [];
        let elementOptions =
            this.props.type != null && this.props.section != null
                ? elementParametersOf(this.props.type, this.props.section)
                : [];
        let variableOptions =
            this.props.type != null
                ? variableParametersOf(this.props.type)
                : [];
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    ...this.props.style,
                }}
            >
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={""}
                    styles={{
                        ...customSelectStyles,
                        container: (base) => ({
                            ...base,
                            height: "38px",
                            width: "200px",
                        }),
                    }}
                    options={typeParameters}
                    value={this.props.type}
                    onChange={(newValue) => {
                        const newType = newValue as TypeParameter | null;
                        let newSection: SectionParameter | null = null;
                        let newElement: ElementParameter | null = null;
                        let newVariable: VariableParameter | null = null;
                        if (newType != null) {
                            if (sectionParametersOf(newType).length !== 0) {
                                newSection = sectionParametersOf(newType)[0];
                                if (
                                    elementParametersOf(newType, newSection)
                                        .length !== 0
                                ) {
                                    newElement = elementParametersOf(
                                        newType,
                                        newSection
                                    )[0];
                                }
                            }
                            if (variableParametersOf(newType).length !== 0) {
                                newVariable = variableParametersOf(newType)[0];
                            }
                        }
                        this.props.onChange(
                            newType,
                            newSection,
                            newElement,
                            newVariable
                        );
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25: "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                {variableOptions.length !== 0 && (
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                height: "38px",
                                width: "200px",
                                marginLeft: "5px",
                            }),
                        }}
                        options={variableOptions}
                        value={this.props.variable}
                        onChange={(newValue) => {
                            this.props.onChange(
                                this.props.type,
                                this.props.section,
                                this.props.element,
                                newValue as VariableParameter | null
                            );
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25: "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                )}
                {sectionOptions.length !== 0 && (
                    <>
                        <span
                            className="regular-text"
                            style={{
                                marginLeft: "5px",
                            }}
                        >
                            for section
                        </span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={""}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    height: "38px",
                                    width: "200px",
                                    marginLeft: "5px",
                                }),
                            }}
                            options={sectionOptions}
                            value={this.props.section}
                            onChange={(newValue) => {
                                let newSection = newValue as SectionParameter | null;
                                let newElement: ElementParameter | null = null;
                                if (newSection != null) {
                                    if (
                                        elementParametersOf(
                                            this.props.type!,
                                            newSection
                                        ).length !== 0
                                    ) {
                                        newElement = elementParametersOf(
                                            this.props.type!,
                                            newSection
                                        )[0];
                                    }
                                }
                                this.props.onChange(
                                    this.props.type,
                                    newSection,
                                    newElement,
                                    this.props.variable
                                );
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25: "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </>
                )}
                {elementOptions.length !== 0 && (
                    <>
                        <span
                            className="regular-text"
                            style={{
                                marginLeft: "5px",
                            }}
                        >
                            and element
                        </span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={""}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    height: "38px",
                                    width: "200px",
                                    marginLeft: "5px",
                                }),
                            }}
                            options={elementOptions}
                            value={this.props.element}
                            onChange={(newValue) => {
                                this.props.onChange(
                                    this.props.type,
                                    this.props.section,
                                    newValue as ElementParameter | null,
                                    this.props.variable
                                );
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25: "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default ParameterSelector;
