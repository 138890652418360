import React from "react";
import UserInfo from "common/UserInfo";
import ReactTooltip from "react-tooltip";

// TODO Unify user info
export function getUserTitle(
    user: Pick<UserInfo, "user_name" | "first_name" | "last_name">
): string {
    let title = "";
    if (user.first_name && user.first_name.length > 0)
        title = title.concat(user.first_name[0]);
    if (user.last_name && user.last_name.length > 0)
        title = title.concat(user.last_name[0]);
    if (!title && user.user_name.length > 0) title = user.user_name[0];
    return title;
}

function getGroupTitle(groupTitle: string): string {
    let tokens = groupTitle.split(" ");
    if (tokens.length === 1) return tokens[0].slice(0, 2).toUpperCase();
    else return tokens[0][0].concat(tokens[1][0]).toUpperCase();
}

interface UserButtonProps {
    identifier: string;
    onLoad: (result: string | ArrayBuffer | null) => void;
    iconUrl?: string;
    // TODO Unify user info
    user?: Pick<
        UserInfo,
        "user_name" | "first_name" | "last_name" | "icon_url"
    >;
}

export function UserButton(props: UserButtonProps) {
    return (
        <label htmlFor={"icon-selector".concat(props.identifier)}>
            <input
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={(evt) => {
                    if (
                        evt.target.files != null &&
                        evt.target.files.length !== 0
                    ) {
                        const file: File = evt.target.files[0];
                        const fileType: string = file.type;
                        const validImageTypes: string[] = [
                            "image/jpeg",
                            "image/png",
                        ];
                        if (validImageTypes.includes(fileType)) {
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = function () {
                                props.onLoad(reader.result);
                            };
                            reader.onerror = function (_error) { };
                        }
                    }
                }}
                id={"icon-selector".concat(props.identifier)}
            />
            <UserIcon
                width={90}
                height={90}
                fontSize={20}
                iconUrl={props.iconUrl}
                user={props.user}
            />
        </label>
    );
}

interface UserIconProps {
    iconUrl?: string;
    // TODO Unify user info
    user?: Pick<
        UserInfo,
        "user_name" | "first_name" | "last_name" | "icon_url"
    >;
    width: number;
    height: number;
    fontSize: number;
    opacity?: number;
    customStyle?: React.CSSProperties;
    showBorder?: boolean;
}

export function UserIcon(props: UserIconProps) {
    let showBorderStyle = props.showBorder
        ? {
            border: "2px solid #FF00FF",
        }
        : {};
    return props.iconUrl ? (
        <div data-tip={props.user?.user_name ?? ""}>
            <img
                alt=""
                src={props.iconUrl}
                style={{
                    opacity: props.opacity ?? 1,
                    borderRadius: "50%",
                    cursor: "pointer",
                    width: props.width,
                    height: props.height,
                    ...props.customStyle,
                    ...showBorderStyle,
                }}
            />
            <ReactTooltip effect="solid" backgroundColor="#464646" />
        </div>
    ) : (
        <div
            data-tip={props.user?.user_name ?? ""}
            style={{
                opacity: props.opacity ?? 1,
                backgroundColor: "#05C985",
                borderRadius: "50%",
                cursor: "pointer",
                width: props.width,
                height: props.height,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...props.customStyle,
                ...showBorderStyle,
            }}
        >
            {props.user && (
                <span
                    style={{
                        textAlign: "center",
                        color: "#FFF",
                        fontFamily: "Arial",
                        fontSize: props.fontSize,
                    }}
                >
                    {getUserTitle(props.user)}
                </span>
            )}
            <ReactTooltip effect="solid" backgroundColor="#464646" />
        </div>
    );
}

export function GroupIcon(props: { groupName: string; fontSize: number }) {
    let backgroundColor = "#BB6BD9";
    let border = "1px solid #242E42";
    return (
        <div
            data-tip={props.groupName}
            style={{
                width: 35,
                height: 30,
                position: "relative",
            }}
        >
            <div
                style={{
                    border: border,

                    position: "absolute",
                    left: 0,
                    top: 0,
                    backgroundColor: backgroundColor,
                    borderRadius: "50%",
                    cursor: "pointer",
                    width: 23,
                    height: 23,
                }}
            ></div>
            <div
                style={{
                    border: border,
                    position: "absolute",
                    left: 10,
                    top: 0,
                    backgroundColor: backgroundColor,
                    borderRadius: "50%",
                    cursor: "pointer",
                    width: 23,
                    height: 23,
                }}
            ></div>
            <div
                style={{
                    border: border,
                    position: "absolute",
                    left: 4,
                    top: 7,
                    backgroundColor: backgroundColor,
                    borderRadius: "50%",
                    cursor: "pointer",
                    width: 23,
                    height: 23,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <span
                    style={{
                        textAlign: "center",
                        color: "#FFF",
                        fontFamily: "Arial",
                        fontSize: props.fontSize,
                    }}
                >
                    {getGroupTitle(props.groupName)}
                </span>
            </div>{" "}
            <ReactTooltip effect="solid" backgroundColor="#464646" />
        </div>
    );
}
