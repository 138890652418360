import { useEffect, useRef } from "react";

export const useUpdateEffect = (callback: () => void, dependencies: Array<any>) => {
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            return callback();
        }
    }, dependencies);
}