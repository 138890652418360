import axios from "./ServerConnection";
import { stringSessionId } from "common/SessionId";
import { RawDraftContentState } from "draft-js";
import { NodeSize } from "./Canvas";

export enum CollectionType {
    Image = 0,
    Font = 1,
    Text = 2,
}

export const CollectionTypeNames = {
    [CollectionType.Image]: "Images",
    [CollectionType.Font]: "Fonts",
    [CollectionType.Text]: "Text",
};

export const typeOptions = [
    {
        label: CollectionTypeNames[CollectionType.Image],
        value: CollectionType.Image,
    },
    {
        label: CollectionTypeNames[CollectionType.Font],
        value: CollectionType.Font,
    },
    {
        label: CollectionTypeNames[CollectionType.Text],
        value: CollectionType.Text,
    },
];

export interface Collection {
    id: number;
    title: string;
    type: CollectionType;
    hash: number;
}

export interface MediaFile {
    id: number;
    filename: string;
}

export interface PresetContent {
    rawMetric: RawDraftContentState;
    nodeSize: NodeSize;
}

interface PresetResponse {
    id: number;
    preset: string;
}

export interface Preset {
    id: number;
    preset: PresetContent;
}

export function collectionHash(
    collectionId: number,
    collectionType: CollectionType
) {
    return (
        ((collectionId + collectionType) *
            (collectionId + collectionType + 1)) /
            2 +
        collectionId
    );
}

export interface AdminCollection extends Collection {
    hidden?: boolean;
}

export async function addNewPresetApi(
    textPreset: PresetContent,
    collectionId: number
): Promise<number> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/preset_add", {
            preset: JSON.stringify(textPreset),
            update_id: stringSessionId(),
            collection_id: collectionId,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.id!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deletePresetApi(preset_id: number): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/preset_delete", {
            id: preset_id,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getPresetsApi(
    collectionId: number,
    admin: boolean
): Promise<Preset[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            presets?: PresetResponse[];
        }>(admin ? "/api/presets_get_all" : "/api/presets_get", {
            collection_id: collectionId,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(
                    response.data.presets!.map(
                        (presetResponse) =>
                            ({
                                id: presetResponse.id,
                                preset: JSON.parse(presetResponse.preset),
                            } as Preset)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addNewCollectionApi(
    title: string,
    collectionType: CollectionType
): Promise<number> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/collection_add", {
            title: title,
            type: collectionType,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.id!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteCollectionApi(
    collectionId: number,
    collectionType: CollectionType
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/collection_delete", {
            id: collectionId,
            type: collectionType,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editCollectionApi(
    collectionId: number,
    collectionType: CollectionType,
    title?: string,
    hidden?: boolean
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/collection_edit", {
            title: title,
            id: collectionId,
            type: collectionType,
            hidden: hidden,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getCollectionsApi(
    type?: CollectionType
): Promise<Collection[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            collections?: Collection[];
        }>("/api/e/collections_get", {
            type: type,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(
                    response.data.collections!.map((collection) => ({
                        ...collection,
                        hash: collectionHash(collection.id, collection.type),
                    }))
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getAllCollectionsApi(
    type?: CollectionType
): Promise<AdminCollection[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            collections?: AdminCollection[];
        }>("/api/collections_get_all", {
            type: type,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(
                    response.data.collections!.map((collection) => ({
                        ...collection,
                        hash: collectionHash(collection.id, collection.type),
                    }))
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getFilesFromCollection(
    collectionId: number,
    admin: boolean
): Promise<MediaFile[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            files?: MediaFile[];
        }>(
            admin
                ? "/api/get_files_by_collection_all"
                : "/api/e/get_files_by_collection",
            {
                collection_id: collectionId,
            }
        )
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.files!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function collectionAddFileApi(
    collectionId: number,
    file: Blob,
    filename: string
): Promise<number> {
    let formData = new FormData();
    let requestJson = {
        collection_id: collectionId,
        filename: filename,
    };
    formData.append("content", file);
    formData.append("metadata", JSON.stringify(requestJson));
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/add_file_to_collection", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.id!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteFileFromCollectionApi(
    fileId: number
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/delete_file_from_collection", {
            id: fileId,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
