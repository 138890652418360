// react-switch with resizing issue fixed

import React, { Component, CSSProperties } from "react";
import {
    checkedIcon as defaultCheckedIcon,
    uncheckedIcon as defaultUncheckedIcon,
} from "./icons";
import getBackgroundColor from "./getBackgroundColor";

type Props = typeof ReactSwitch.defaultProps & {
    checked: boolean;
    onChange: (
        checked: boolean,
        evt: React.SyntheticEvent | MouseEvent,
        id?: string
    ) => void;
    disabled?: boolean;
    offColor?: string;
    onColor?: string;
    offHandleColor?: string;
    onHandleColor?: string;
    handleDiameter?: number;
    uncheckedIcon?: JSX.Element | boolean;
    checkedIcon?: JSX.Element | boolean;
    boxShadow?: string;
    borderRadius?: number;
    activeBoxShadow?: string;
    uncheckedHandleIcon?: JSX.Element;
    checkedHandleIcon?: JSX.Element;
    height?: number;
    width?: number;
    id?: string;
    className?: string;
    dataTestId?: string;
};

interface State {
    pos: number;
    startX: number;
    hasOutline: boolean;
    dragStartingTime: number;
    isDragging: boolean;
}

class ReactSwitch extends Component<Props, State> {
    static defaultProps: {
        disabled: boolean;
        offColor: string;
        onColor: string;
        offHandleColor: string;
        onHandleColor: string;
        uncheckedIcon: JSX.Element | boolean;
        checkedIcon: JSX.Element | boolean;
        activeBoxShadow: string;
        height: number;
        width: number;
    } = {
        disabled: false,
        offColor: "#888",
        onColor: "#080",
        offHandleColor: "#fff",
        onHandleColor: "#fff",
        uncheckedIcon: defaultUncheckedIcon,
        checkedIcon: defaultCheckedIcon,
        activeBoxShadow: "0 0 2px 3px #3bf",
        height: 28,
        width: 56,
    };

    private lastDragAt: number;
    private lastKeyUpAt: number;
    private mounted: boolean;
    private inputRef: HTMLInputElement | null | undefined;

    constructor(props: Props) {
        super(props);
        const { height, width, checked } = props;
        let handleDiameter = props.handleDiameter || height - 2;
        let pos: number;
        if (checked) {
            pos = Math.max(
                width - height,
                width - (height + handleDiameter) / 2
            );
        } else {
            pos = Math.max(0, (height - handleDiameter) / 2);
        }
        this.state = {
            pos: pos,
            startX: 0,
            hasOutline: false,
            isDragging: false,
            dragStartingTime: 0,
        };
        this.lastDragAt = 0;
        this.lastKeyUpAt = 0;
        this.mounted = false;

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onClick = this.onClick.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.setHasOutline = this.setHasOutline.bind(this);
        this.unsetHasOutline = this.unsetHasOutline.bind(this);
        this.getInputRef = this.getInputRef.bind(this);
    }

    public componentDidMount(): void {
        this.mounted = true;
    }

    public componentDidUpdate(prevProps: Props): void {
        if (
            prevProps.checked === this.props.checked &&
            prevProps.width === this.props.width
        ) {
            return;
        }
        const {
            width,
            height,
            checked,
            handleDiameter: handleDiameterProp,
        } = this.props;
        let handleDiameter = handleDiameterProp || height - 2;
        let pos: number;
        if (checked) {
            pos = Math.max(
                width - height,
                width - (height + handleDiameter) / 2
            );
        } else {
            pos = Math.max(0, (height - handleDiameter) / 2);
        }
        this.setState({ pos });
    }

    public componentWillUnmount(): void {
        this.mounted = false;
    }

    private onDragStart(clientX: number): void {
        this.inputRef?.focus();
        this.setState({
            startX: clientX,
            hasOutline: true,
            dragStartingTime: Date.now(),
        });
    }

    private onDrag(clientX: number): void {
        const { startX, isDragging, pos } = this.state;
        const {
            width,
            height,
            handleDiameter: handleDiameterProp,
            checked,
        } = this.props;
        let handleDiameter = handleDiameterProp || height - 2;
        let checkedPos = Math.max(
            width - height,
            width - (height + handleDiameter) / 2
        );
        let uncheckedPos = Math.max(0, (height - handleDiameter) / 2);
        const startPos = checked ? checkedPos : uncheckedPos;
        const mousePos = startPos + clientX - startX;
        // We need this check to fix a windows glitch where onDrag is triggered onMouseDown in some cases
        if (!isDragging && clientX !== startX) {
            this.setState({ isDragging: true });
        }
        const newPos = Math.min(checkedPos, Math.max(uncheckedPos, mousePos));
        // Prevent unnecessary rerenders
        if (newPos !== pos) {
            this.setState({ pos: newPos });
        }
    }

    private onDragStop(event: MouseEvent | React.SyntheticEvent): void {
        const { pos, isDragging, dragStartingTime } = this.state;
        const {
            width,
            height,
            handleDiameter: handleDiameterProp,
            checked,
        } = this.props;
        let handleDiameter = handleDiameterProp || height - 2;
        let checkedPos = Math.max(
            width - height,
            width - (height + handleDiameter) / 2
        );
        let uncheckedPos = Math.max(0, (height - handleDiameter) / 2);
        const halfwayCheckpoint = (checkedPos + uncheckedPos) / 2;

        /*
      Set position state back to the previous position even if user drags the switch with intention to change the state.
      This is to prevent the switch from getting stuck in the middle if the event isn't handled in the onChange callback.
    */
        const prevPos = checked ? checkedPos : uncheckedPos;
        this.setState({ pos: prevPos });

        // Act as if the user clicked the handle if they didn't drag it _or_ the dragged it for less than 250ms
        const timeSinceStart = Date.now() - dragStartingTime;
        const isSimulatedClick = !isDragging || timeSinceStart < 250;

        // Handle when the user has dragged the switch more than halfway from either side
        const isDraggedHalfway =
            (checked && pos <= halfwayCheckpoint) ||
            (!checked && pos >= halfwayCheckpoint);

        if (isSimulatedClick || isDraggedHalfway) {
            this.onChange(event);
        }

        if (this.mounted) {
            this.setState({ isDragging: false, hasOutline: false });
        }
        this.lastDragAt = Date.now();
    }

    private onMouseDown(event: React.MouseEvent<HTMLDivElement>): void {
        event.preventDefault();
        // Ignore right click and scroll
        if (typeof event.button === "number" && event.button !== 0) {
            return;
        }

        this.onDragStart(event.clientX);
        window.addEventListener("mousemove", this.onMouseMove);
        window.addEventListener("mouseup", this.onMouseUp);
    }

    private onMouseMove(event: MouseEvent): void {
        event.preventDefault();
        this.onDrag(event.clientX);
    }

    private onMouseUp(event: MouseEvent): void {
        this.onDragStop(event);
        window.removeEventListener("mousemove", this.onMouseMove);
        window.removeEventListener("mouseup", this.onMouseUp);
    }

    private onTouchStart(event: React.TouchEvent<HTMLDivElement>): void {
        this.onDragStart(event.touches[0].clientX);
    }

    private onTouchMove(event: React.TouchEvent<HTMLDivElement>): void {
        this.onDrag(event.touches[0].clientX);
    }

    private onTouchEnd(event: React.TouchEvent<HTMLDivElement>): void {
        event.preventDefault();
        this.onDragStop(event);
    }

    private onInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
        // This condition is unfortunately needed in some browsers where the input's change event might get triggered
        // right after the dragstop event is triggered (occurs when dropping over a label element)
        if (Date.now() - this.lastDragAt > 50) {
            this.onChange(event);
            // Prevent clicking label, but not key activation from setting outline to true - yes, this is absurd
            if (Date.now() - this.lastKeyUpAt > 50) {
                if (this.mounted) {
                    this.setState({ hasOutline: false });
                }
            }
        }
    }

    private onKeyUp(): void {
        this.lastKeyUpAt = Date.now();
    }

    private setHasOutline(): void {
        this.setState({ hasOutline: true });
    }

    private unsetHasOutline(): void {
        this.setState({ hasOutline: false });
    }

    private getInputRef(el: HTMLInputElement | null | undefined): void {
        this.inputRef = el;
    }

    private onClick(event: React.MouseEvent<HTMLDivElement>): void {
        event.preventDefault();
        this.inputRef?.focus();
        this.onChange(event);
        if (this.mounted) {
            this.setState({ hasOutline: false });
        }
    }

    private onChange(event: React.SyntheticEvent | MouseEvent): void {
        const { checked, onChange, id } = this.props;
        onChange(!checked, event, id);
    }

    public render(): JSX.Element {
        const {
            checked,
            disabled,
            className,
            offColor,
            onColor,
            offHandleColor,
            onHandleColor,
            checkedIcon,
            uncheckedIcon,
            checkedHandleIcon,
            uncheckedHandleIcon,
            boxShadow,
            activeBoxShadow,
            height,
            width,
            borderRadius,
            handleDiameter: handleDiameterProp, // just to filter this prop out
            ...rest
        } = this.props;

        const { pos, isDragging, hasOutline } = this.state;
        let handleDiameter = handleDiameterProp || height - 2;
        let checkedPos = Math.max(
            width - height,
            width - (height + handleDiameter) / 2
        );
        let uncheckedPos = Math.max(0, (height - handleDiameter) / 2);

        const rootStyle: CSSProperties = {
            position: "relative",
            display: "inline-block",
            textAlign: "left",
            opacity: disabled ? 0.5 : 1,
            direction: "ltr",
            borderRadius: height / 2,
            WebkitTransition: "opacity 0.25s",
            MozTransition: "opacity 0.25s",
            transition: "opacity 0.25s",
            touchAction: "none",
            WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
        };

        const backgroundStyle: CSSProperties = {
            height,
            width,
            margin: Math.max(0, (handleDiameter - height) / 2),
            position: "relative",
            background: getBackgroundColor(
                pos,
                checkedPos,
                uncheckedPos,
                offColor,
                onColor
            ),
            borderRadius:
                typeof borderRadius === "number" ? borderRadius : height / 2,
            cursor: disabled ? "default" : "pointer",
            WebkitTransition: isDragging ? undefined : "background 0.25s",
            MozTransition: isDragging ? undefined : "background 0.25s",
            transition: isDragging ? undefined : "background 0.25s",
        };

        const checkedIconStyle: CSSProperties = {
            height,
            width: Math.min(
                height * 1.5,
                width - (handleDiameter + height) / 2 + 1
            ),
            position: "relative",
            opacity: (pos - uncheckedPos) / (checkedPos - uncheckedPos),
            pointerEvents: "none",
            WebkitTransition: isDragging ? undefined : "opacity 0.25s",
            MozTransition: isDragging ? undefined : "opacity 0.25s",
            transition: isDragging ? undefined : "opacity 0.25s",
        };

        const uncheckedIconStyle: CSSProperties = {
            height,
            width: Math.min(
                height * 1.5,
                width - (handleDiameter + height) / 2 + 1
            ),
            position: "absolute",
            opacity: 1 - (pos - uncheckedPos) / (checkedPos - uncheckedPos),
            right: 0,
            top: 0,
            pointerEvents: "none",
            WebkitTransition: isDragging ? undefined : "opacity 0.25s",
            MozTransition: isDragging ? undefined : "opacity 0.25s",
            transition: isDragging ? undefined : "opacity 0.25s",
        };

        const handleStyle: CSSProperties = {
            height: handleDiameter,
            width: handleDiameter,
            background: getBackgroundColor(
                pos,
                checkedPos,
                uncheckedPos,
                offHandleColor,
                onHandleColor
            ),
            display: "inline-block",
            cursor: disabled ? "default" : "pointer",
            borderRadius:
                typeof borderRadius === "number" ? borderRadius - 1 : "50%",
            position: "absolute",
            transform: `translateX(${pos}px)`,
            top: Math.max(0, (height - handleDiameter) / 2),
            outline: 0,
            boxShadow: hasOutline ? activeBoxShadow : boxShadow,
            border: 0,
            WebkitTransition: isDragging
                ? undefined
                : "background-color 0.25s, transform 0.25s, box-shadow 0.15s",
            MozTransition: isDragging
                ? undefined
                : "background-color 0.25s, transform 0.25s, box-shadow 0.15s",
            transition: isDragging
                ? undefined
                : "background-color 0.25s, transform 0.25s, box-shadow 0.15s",
        };

        const uncheckedHandleIconStyle: CSSProperties = {
            height: handleDiameter,
            width: handleDiameter,
            opacity: Math.max(
                (1 - (pos - uncheckedPos) / (checkedPos - uncheckedPos) - 0.5) *
                    2,
                0
            ),
            position: "absolute",
            left: 0,
            top: 0,
            pointerEvents: "none",
            WebkitTransition: isDragging ? undefined : "opacity 0.25s",
            MozTransition: isDragging ? undefined : "opacity 0.25s",
            transition: isDragging ? undefined : "opacity 0.25s",
        };

        const checkedHandleIconStyle: CSSProperties = {
            height: handleDiameter,
            width: handleDiameter,
            opacity: Math.max(
                ((pos - uncheckedPos) / (checkedPos - uncheckedPos) - 0.5) * 2,
                0
            ),
            position: "absolute",
            left: 0,
            top: 0,
            pointerEvents: "none",
            WebkitTransition: isDragging ? undefined : "opacity 0.25s",
            MozTransition: isDragging ? undefined : "opacity 0.25s",
            transition: isDragging ? undefined : "opacity 0.25s",
        };

        const inputStyle: CSSProperties = {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            width: 1,
        };

        return (
            <div className={className} style={rootStyle}>
                <div
                    className="react-switch-bg"
                    style={backgroundStyle}
                    onClick={disabled ? undefined : this.onClick}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    {checkedIcon && (
                        <div style={checkedIconStyle}>{checkedIcon}</div>
                    )}
                    {uncheckedIcon && (
                        <div style={uncheckedIconStyle}>{uncheckedIcon}</div>
                    )}
                </div>
                <div
                    className="react-switch-handle"
                    style={handleStyle}
                    onClick={(e) => e.preventDefault()}
                    onMouseDown={disabled ? undefined : this.onMouseDown}
                    onTouchStart={disabled ? undefined : this.onTouchStart}
                    onTouchMove={disabled ? undefined : this.onTouchMove}
                    onTouchEnd={disabled ? undefined : this.onTouchEnd}
                    onTouchCancel={disabled ? undefined : this.unsetHasOutline}
                >
                    {uncheckedHandleIcon && (
                        <div style={uncheckedHandleIconStyle}>
                            {uncheckedHandleIcon}
                        </div>
                    )}
                    {checkedHandleIcon && (
                        <div style={checkedHandleIconStyle}>
                            {checkedHandleIcon}
                        </div>
                    )}
                </div>
                <input
                    data-test-id={this.props.dataTestId}
                    type="checkbox"
                    role="switch"
                    aria-checked={checked}
                    checked={checked}
                    disabled={disabled}
                    style={inputStyle}
                    {...rest}
                    /* anything below should NOT get overriden by ...rest */
                    ref={this.getInputRef}
                    onFocus={this.setHasOutline}
                    onBlur={this.unsetHasOutline}
                    onKeyUp={this.onKeyUp}
                    onChange={this.onInputChange}
                />
            </div>
        );
    }
}

export default ReactSwitch;
