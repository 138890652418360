import React from "react";
import { StatusSubExpression } from "common/Canvas";
import Select, { createFilter } from "react-select";
import customSelectStyles, { Styles } from "common/SelectStyles";
import { mainStyle } from "common/MainStyle";
import { Button } from "react-bootstrap";
import { CanvasElement, CanvasTextBox } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import Switch from "react-switch";
import cx from "classnames";

interface StringOption {
    label: string;
    value: string;
}

interface NumberOption {
    label: string;
    value: number;
}

const comparisonOperations: ReadonlyArray<StringOption> = [
    {
        label: ">",
        value: ">",
    },
    {
        label: "<",
        value: "<",
    },
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
];

interface Props {
    spanStyle?: React.CSSProperties;
    selectStyles?: Styles;
    upDownButtonsClass?: string;
    node?: CanvasElement | CanvasTextBox;
    nodeLinkOptions: NodeLinkOption[];
    subexpressions: StatusSubExpression[];
    onChange: (subexpressions: StatusSubExpression[]) => void;
}

export default class StatusSubExpressionsSelector extends React.Component<Props> {
    static getOutputValue(
        node: CanvasElement | CanvasTextBox,
        subexpression: StatusSubExpression
    ): NumberOption | null {
        if (subexpression.index === 1 || subexpression.index == null)
            return {
                label: "Output#1",
                value: 1,
            };
        if (subexpression.index > 1) {
            if (node.additionalOutputs.length > subexpression.index - 2)
                return {
                    label: `Output#${subexpression.index}`,
                    value: subexpression.index,
                };
        }
        return null;
    }

    render() {
        return (
            <div className="flex-simple-column" style={{ marginLeft: "5px" }}>
                {this.props.subexpressions.map(
                    (subexpression, subexpressionIndex) => (
                        <div
                            className="my-row"
                            key={subexpressionIndex}
                            style={{ marginTop: "2px" }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    height: "38px",
                                    width: "30px",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        width: "100%",
                                        textAlign: "right",
                                        fontFamily: "Arial",
                                        fontSize: "17px",
                                        color: mainStyle.getPropertyValue(
                                            "--popup-primary-text-color"
                                        ),
                                        ...this.props.spanStyle,
                                    }}
                                >
                                    {subexpressionIndex === 0 ? "If" : "And"}
                                </span>
                            </div>
                            {this.props.node != null && (
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...customSelectStyles,
                                        container: (base) => ({
                                            ...base,
                                            height: "38px",
                                            width: "120px",
                                            marginLeft: "5px",
                                        }),
                                        ...this.props.selectStyles,
                                    }}
                                    options={[
                                        {
                                            label: "Output#1",
                                            value: 1,
                                        },
                                    ].concat(
                                        this.props.node.additionalOutputs?.map(
                                            (_output, outputIndex) => ({
                                                label: `Output#${
                                                    outputIndex + 2
                                                }`,
                                                value: outputIndex + 2,
                                            })
                                        )
                                    )}
                                    onChange={(newValue) => {
                                        let newSubExpressions = Array.from(
                                            this.props.subexpressions
                                        );
                                        newSubExpressions[
                                            subexpressionIndex
                                        ].index = (
                                            newValue as NumberOption
                                        ).value;

                                        this.props.onChange(newSubExpressions);
                                    }}
                                    value={StatusSubExpressionsSelector.getOutputValue(
                                        this.props.node,
                                        subexpression
                                    )}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            )}
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        height: "38px",
                                        marginLeft: "5px",
                                    }),
                                    ...this.props.selectStyles,
                                }}
                                options={comparisonOperations}
                                onChange={(newValue) => {
                                    let newSubExpressions = Array.from(
                                        this.props.subexpressions
                                    );
                                    newSubExpressions[
                                        subexpressionIndex
                                    ].operation = (
                                        newValue as StringOption
                                    ).value;

                                    this.props.onChange(newSubExpressions);
                                }}
                                value={{
                                    label: subexpression.operation,
                                    value: subexpression.operation,
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <div className="flex-simple-column">
                                {!subexpression.isInput && (
                                    <input
                                        className="like-select"
                                        style={{
                                            width: 100,
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                            height: "25px",
                                            fontFamily: "monospace",
                                            textAlign: "left",
                                            marginLeft: "4px",
                                            marginRight: "4px",
                                            flex: 1,
                                        }}
                                        placeholder=""
                                        onChange={(e) => {
                                            const value: string =
                                                e.target.value;
                                            let newSubExpressions = Array.from(
                                                this.props.subexpressions
                                            );
                                            newSubExpressions[
                                                subexpressionIndex
                                            ].value = value;
                                            this.props.onChange(
                                                newSubExpressions
                                            );
                                        }}
                                        value={
                                            (subexpression.value as string) ??
                                            ""
                                        }
                                        onKeyDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                )}
                                {subexpression.isInput && (
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                width: "100px",
                                                marginLeft: "4px",
                                                marginRight: "4px",
                                                height: "38px",
                                            }),
                                            ...this.props.selectStyles,
                                        }}
                                        options={this.props.nodeLinkOptions}
                                        value={
                                            subexpression.value as NodeLinkOption | null
                                        }
                                        onChange={(newValue) => {
                                            let newSubExpressions = Array.from(
                                                this.props.subexpressions
                                            );
                                            newSubExpressions[
                                                subexpressionIndex
                                            ].value = newValue as NodeLinkOption;

                                            this.props.onChange(
                                                newSubExpressions
                                            );
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                )}
                                <div
                                    className="my-row"
                                    style={{
                                        marginTop: 2,
                                        alignSelf: "center",
                                        marginLeft: 1,
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        className="regular-text"
                                        style={{
                                            color: "var(--popup-primary-text-color)",
                                            fontSize: "10px",
                                            marginRight: "5px",
                                        }}
                                    >
                                        {"Link element"}
                                    </span>
                                    <Switch
                                        onChange={() => {
                                            let newSubExpressions = Array.from(
                                                this.props.subexpressions
                                            );
                                            newSubExpressions[
                                                subexpressionIndex
                                            ].isInput = !(
                                                newSubExpressions[
                                                    subexpressionIndex
                                                ].isInput ?? false
                                            );
                                            newSubExpressions[
                                                subexpressionIndex
                                            ].value = null;

                                            this.props.onChange(
                                                newSubExpressions
                                            );
                                        }}
                                        checked={subexpression.isInput ?? false}
                                        width={26}
                                        height={13}
                                        offColor="#20293C"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1F8EFA"
                                    />
                                </div>
                            </div>
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 5 }}
                            >
                                <Button
                                    className={
                                        this.props.upDownButtonsClass != null
                                            ? cx(
                                                  "btn-small",
                                                  this.props.upDownButtonsClass
                                              )
                                            : "btn-small-like-select"
                                    }
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let newSubExpressions = Array.from(
                                            this.props.subexpressions
                                        );
                                        newSubExpressions.push({
                                            operation: "",
                                            value: "",
                                        });

                                        this.props.onChange(newSubExpressions);
                                    }}
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>
                                <Button
                                    className={
                                        this.props.upDownButtonsClass != null
                                            ? cx(
                                                  "btn-small",
                                                  this.props.upDownButtonsClass
                                              )
                                            : "btn-small-like-select"
                                    }
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        let newSubExpressions = Array.from(
                                            this.props.subexpressions
                                        );
                                        newSubExpressions.splice(
                                            subexpressionIndex,
                                            1
                                        );

                                        if (newSubExpressions.length === 0) {
                                            newSubExpressions.push({
                                                operation: "",
                                                value: "",
                                            });
                                        }

                                        this.props.onChange(newSubExpressions);
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    }
}
