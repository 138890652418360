import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { observer } from "mobx-react";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import DropdownCanvasButton from "common/HeaderBarRevamped/Dropdown/DropdownCanvasButton";
import DropdownMenu from "common/HeaderBarRevamped/Dropdown/DropdownMenu";
import DropdownItem from "common/HeaderBarRevamped/Dropdown/DropdownItem";
import { Button } from "react-bootstrap";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { ReactComponent as LiveIcon } from "icons/canvas_header/live.svg";
import { ReactComponent as SharePresoIcon } from "icons/canvas_header/share_preso.svg";
import headerStyles from "common/HeaderBarRevamped/HeaderBar.module.css";
import cx from "classnames";
import { ShareType } from "./SharePopup";

interface TopBarProps {
    showShareButton?: boolean;
    live: boolean;
    show: boolean;
    shared?: boolean;
    scale: number;
    forceCanOpenRibbon?: boolean;
    ribbonOpened: boolean;
    onOpenRibbonChange: () => void;
    onLiveChange: (slideShow?: boolean, liveOnly?: boolean, fromCurrentSlide?: boolean) => void;
    onShare: (e: React.MouseEvent<HTMLHeadingElement>, sharePopupType?: number) => void;
    onZoomChange: (zoom: number) => void;
    onZoomFit: () => void;
}

enum DropdownType {
    Zoom = 1,
    Share = 2,
    Live = 3,
}

interface State {
    toggledDropdown: DropdownType | undefined;
}

function getZoomValue(value: string) {
    let intValue = parseInt(value);
    if (isNaN(intValue)) {
        intValue = 1;
    } else {
        intValue = intValue / 100;
    }
    return intValue;
}

export const RightTopBar = observer(
    class RightTopBar extends Component<TopBarProps, State> {
        zoomInputRef = React.createRef<HTMLInputElement>();
        constructor(props: TopBarProps) {
            super(props);
            this.state = {
                toggledDropdown: undefined,
            };

            this.renderContents = this.renderContents.bind(this);
        }
        updateInputZoomValue() {
            setTimeout(() => {
                if (this.zoomInputRef.current != null)
                    this.zoomInputRef.current.value = `${Math.round(
                        100 * this.props.scale
                    )}%`;
            }, 0);
        }

        private renderContents(
            globalContext: GlobalContextContents
        ): JSX.Element | null {
            if (!this.props.show) return null;
            let shared = this.props.shared ?? false;
            let showShareButton = this.props.showShareButton ?? false;
            return (
                <div
                    className="my-row"
                    style={{
                        alignItems: "center",
                    }}
                >
                    {showShareButton && !this.props.live &&
                        !shared &&
                        ("ShareLinkUserModules" in globalContext.permissions ||
                            "ShareModules" in globalContext.permissions) && (
                                <>
                                    <Button
                                        type="button"
                                        className={cx(headerStyles.shareButton, "btn btn-sm")}
                                        onClick={(e: React.MouseEvent<HTMLHeadingElement>) => this.props.onShare(e, ShareType.Regular)}
                                    >
                                        <SharePresoIcon />
                                    </Button>
                                    <Button
                                        type="button"
                                        className="btn btn-sm btn-primary my-primary"
                                        style={{
                                            width: 79,
                                            height: 37,
                                            fontSize: 14,
                                            fontWeight: 500,
                                        }}
                                        onClick={(e: React.MouseEvent<HTMLHeadingElement>) => this.props.onShare(e, ShareType.Link)}
                                    >
                                        Publish
                                    </Button>
                                </>
                        )}

                    {!shared && !this.props.live && (
                        <>
                            <DropdownCanvasButton
                                title="Live"
                                id="live-button"
                                style={{
                                    display: "flex",
                                    marginLeft: 10,
                                    width: 32,
                                    height: 32,
                                    justifyContent: "center",
                                }}
                                onClick={() => this.props.onLiveChange(false)}
                            >
                                <LiveIcon />
                            </DropdownCanvasButton>
                            <Dropdown
                                title="Live"
                                onToggle={(show: boolean) => {
                                    if (show) {
                                        this.setState({
                                            toggledDropdown: DropdownType.Live,
                                        });
                                    } else {
                                        if (
                                            this.state.toggledDropdown ===
                                            DropdownType.Live
                                        )
                                            this.setState({
                                                toggledDropdown: undefined,
                                            });
                                    }
                                }}
                            >
                                <DropdownCanvasButton
                                    title="Live Options"
                                    id="live-options-button"
                                    style={{
                                        marginLeft: 0,
                                        padding: 5,
                                        width: 22,
                                        height: 32,
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <ChevronIcon />
                                </DropdownCanvasButton>
                                <DropdownMenu>
                                    <DropdownItem
                                        leftIcon={null}
                                        rightIcon={null}
                                        onClick={() =>
                                            this.props.onLiveChange(false)
                                        }
                                    >
                                        Present as App
                                    </DropdownItem>
                                    <DropdownItem
                                        leftIcon={null}
                                        rightIcon={null}
                                        onClick={() =>
                                            this.props.onLiveChange(true)
                                        }
                                    >
                                        Present as Slideshow
                                    </DropdownItem>
                                    <DropdownItem
                                        leftIcon={null}
                                        rightIcon={null}
                                        onClick={() =>
                                            this.props.onLiveChange(true, false, true)
                                        }
                                    >
                                        Slideshow from current
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </>
                    )}

                    {/* {((!shared && !this.props.live) ||
                        this.props.forceCanOpenRibbon) && (
                        <div
                            title="Slide ribbon"
                            style={{
                                boxShadow: this.props.ribbonOpened
                                    ? "inset 0 3px #1F8EFA"
                                    : "none",
                                width: "40px",
                                height: "43px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                            }}
                            onClick={this.props.onOpenRibbonChange}
                        >
                            <CanvasIconsContainer
                                options={
                                    this.props.ribbonOpened
                                        ? highlightOptions
                                        : {}
                                }
                                type={"ShowRibbon"}
                            />
                            {!shared &&
                                CanvasTreeStore().canvasPageId != null &&
                                PagesStore(PageType.Canvases).hasNewFeedbacks(
                                    CanvasTreeStore().canvasPageId! as number
                                ) && <NotificationIcon hideBorder />}
                        </div>
                    )}*/}
                    <Dropdown
                        onToggle={(show: boolean) => {
                            if (show) {
                                this.setState({
                                    toggledDropdown: DropdownType.Zoom,
                                });
                            } else {
                                if (
                                    this.state.toggledDropdown ===
                                    DropdownType.Zoom
                                )
                                    this.setState({
                                        toggledDropdown: undefined,
                                    });
                            }
                        }}
                    >
                        <DropdownCanvasButton
                            id="zoom-options"
                            title="Zoom"
                            opened={
                                this.state.toggledDropdown === DropdownType.Zoom
                            }
                            style={{ width: 72, height: 32 }}
                        >
                            <span className={headerStyles.zoomTitle}>
                                {`${Math.round(100 * this.props.scale)}%`}
                            </span>
                            <ChevronIcon />
                        </DropdownCanvasButton>

                        <DropdownMenu>
                            <input
                                ref={this.zoomInputRef}
                                type={"text"}
                                className="like-select zoom-canvas"
                                style={{
                                    fontFamily: "Roboto",
                                    fontSize: "10px",
                                    padding: "0 5 0 0",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                    width: "150px",
                                    minHeight: "23px",
                                    height: "23px",
                                }}
                                placeholder=""
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                    if (evt.key === "Enter") {
                                        evt.preventDefault();
                                        evt.currentTarget.setAttribute(
                                            "preventBlur",
                                            "true"
                                        );
                                        this.props.onZoomChange(
                                            getZoomValue(
                                                evt.currentTarget.value
                                            )
                                        );
                                        this.updateInputZoomValue();
                                    }
                                }}
                                defaultValue={`${Math.round(
                                    100 * this.props.scale
                                )}%`}
                            />
                            <DropdownItem
                                leftIcon={null}
                                rightIcon={null}
                                onClick={() => {
                                    this.props.onZoomChange(
                                        this.props.scale + 0.2
                                    );
                                    this.updateInputZoomValue();
                                }}
                            >
                                Zoom in
                            </DropdownItem>
                            <DropdownItem
                                leftIcon={null}
                                rightIcon={null}
                                onClick={() => {
                                    this.props.onZoomChange(
                                        this.props.scale - 0.2
                                    );
                                    this.updateInputZoomValue();
                                }}
                            >
                                Zoom out
                            </DropdownItem>
                            <DropdownItem
                                leftIcon={null}
                                rightIcon={null}
                                onClick={() => {
                                    this.props.onZoomFit();
                                    this.updateInputZoomValue();
                                }}
                            >
                                Zoom to fit
                            </DropdownItem>
                            <DropdownItem
                                leftIcon={null}
                                rightIcon={null}
                                onClick={() => {
                                    this.props.onZoomChange(0.5);
                                    this.updateInputZoomValue();
                                }}
                            >
                                Zoom to 50%
                            </DropdownItem>
                            <DropdownItem
                                leftIcon={null}
                                rightIcon={null}
                                onClick={() => {
                                    this.props.onZoomChange(1);
                                    this.updateInputZoomValue();
                                }}
                            >
                                Zoom to 100%
                            </DropdownItem>
                            <DropdownItem
                                leftIcon={null}
                                rightIcon={null}
                                onClick={() => {
                                    this.props.onZoomChange(2);
                                    this.updateInputZoomValue();
                                }}
                            >
                                Zoom to 200%
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    {!shared && this.props.live && (
                        <DropdownCanvasButton
                            title="Stop"
                            active={this.props.live}
                            id="live-button"
                            style={{
                                margin: '0 10px',
                                padding: '5px 20px',
                                justifyContent: "center",
                            }}
                            onClick={() => this.props.onLiveChange(false)}
                        >
                            EDIT
                        </DropdownCanvasButton>
                    )}
                </div>
            );
        }

        render() {
            return (
                <GlobalContext.ObserverConsumer>
                    {this.renderContents}
                </GlobalContext.ObserverConsumer>
            );
        }
    }
);

export default RightTopBar;
