import React from "react";
import Select from "react-select";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import cx from "classnames";

import { CanvasElement, CanvasProgressElement, CanvasTextBox, StatusSubExpression } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";

import styles from "./StatusSubExpressionsSelector.module.css";

interface StringOption {
    label: string;
    value: string;
}

interface NumberOption {
    label: string;
    value: number;
}

const comparisonOperations: ReadonlyArray<StringOption> = [
    {
        label: ">",
        value: ">",
    },
    {
        label: "<",
        value: "<",
    },
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
];

interface Props {
    node?: CanvasElement | CanvasTextBox | CanvasProgressElement;
    nodeLinkOptions: NodeLinkOption[];
    subexpressions: StatusSubExpression[];
    onChange: (subexpressions: StatusSubExpression[]) => void;
}

export default class StatusSubExpressionsSelector extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.renderRow = this.renderRow.bind(this);
    }

    static getOutputValue(
        node: CanvasElement | CanvasTextBox | CanvasProgressElement,
        subexpression: StatusSubExpression
    ): NumberOption | null {
        if (subexpression.index === 1 || subexpression.index == null)
            return {
                label: "Output#1",
                value: 1,
            };
        if (subexpression.index > 1) {
            if (node.additionalOutputs.length > subexpression.index - 2)
                return {
                    label: `Output#${subexpression.index}`,
                    value: subexpression.index,
                };
        }
        return null;
    }

    renderRow(subexpression: StatusSubExpression, subexpressionIndex: number) {
        return (
            <div
                className={cx(styles.expressionRow)}
                key={subexpressionIndex}
            >
                <div className={styles.expressionCondition}>
                    <span className={styles.conditionText}>
                        { subexpressionIndex > 0 ? "and" : "if" }
                    </span>
                    <div className={styles.expressionConditionWrapper}>
                        {this.props.node != null && (
                            <Select
                                placeholder=""
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: "4px",
                                        borderColor: "transparent",
                                        marginRight: "2px",
                                        "&:hover": {
                                            borderColor: "rgb(204, 204, 204)"
                                        }
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: 'none',
                                    }),
                                }}
                                options={[
                                    {
                                        label: "Output#1",
                                        value: 1,
                                    },
                                ].concat(
                                    this.props.node.additionalOutputs?.map(
                                        (_output, outputIndex) => ({
                                            label: `Output#${
                                                outputIndex + 2
                                            }`,
                                            value: outputIndex + 2,
                                        })
                                    )
                                )}
                                onChange={(newValue) => {
                                    let newSubExpressions = Array.from(
                                        this.props.subexpressions
                                    );
                                    newSubExpressions[
                                        subexpressionIndex
                                    ].index = (newValue as NumberOption).value;

                                    this.props.onChange(newSubExpressions);
                                }}
                                value={StatusSubExpressionsSelector.getOutputValue(
                                    this.props.node,
                                    subexpression
                                )}
                            />
                        )}
                        <Select
                            placeholder=""
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    borderRadius: "4px",
                                    borderColor: "transparent",
                                    marginRight: "2px",
                                    "&:hover": {
                                        borderColor: "rgb(204, 204, 204)"
                                    }
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: 'none',
                                }),
                            }}
                            options={comparisonOperations}
                            onChange={(newValue) => {
                                let newSubExpressions = Array.from(
                                    this.props.subexpressions
                                );
                                newSubExpressions[
                                    subexpressionIndex
                                ].operation = (newValue as StringOption).value;

                                this.props.onChange(newSubExpressions);
                            }}
                            value={{
                                label: subexpression.operation,
                                value: subexpression.operation,
                            }}
                        />
                        {subexpression.isInput ? (
                            <Select
                                placeholder=""
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        flex: "1 1 auto"
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: "4px",
                                        borderColor: "transparent",
                                        marginRight: "2px",
                                        "&:hover": {
                                            borderColor: "rgb(204, 204, 204)"
                                        }
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: 'none',
                                    }),
                                }}
                                options={this.props.nodeLinkOptions}
                                value={
                                    subexpression.value as NodeLinkOption | null
                                }
                                onChange={(newValue) => {
                                    let newSubExpressions = Array.from(
                                        this.props.subexpressions
                                    );
                                    newSubExpressions[
                                        subexpressionIndex
                                    ].value = newValue as NodeLinkOption;

                                    this.props.onChange(
                                        newSubExpressions
                                    );
                                }}
                            />
                        ) : (
                            <input
                                className={styles.input}
                                placeholder=""
                                onChange={(e) => {
                                    const value: string =
                                        e.target.value;
                                    let newSubExpressions = Array.from(
                                        this.props.subexpressions
                                    );
                                    newSubExpressions[
                                        subexpressionIndex
                                    ].value = value;
                                    this.props.onChange(
                                        newSubExpressions
                                    );
                                }}
                                value={
                                    (subexpression.value as string) ??
                                    ""
                                }
                            />
                        )}
                    </div>
                    <div
                        className="flex-simple-column"
                        style={{ marginLeft: 5 }}
                    >
                        <Button
                            className={styles.addRemoveButton}
                            onClick={() => {
                                let newSubExpressions = Array.from(
                                    this.props.subexpressions
                                );
                                newSubExpressions.push({
                                    operation: "",
                                    value: "",
                                });

                                this.props.onChange(newSubExpressions);
                            }}
                        >
                            {"\uFF0B" /* plus */}
                        </Button>
                        <Button
                            className={styles.addRemoveButton}
                            disabled={subexpressionIndex === 0}
                            onClick={() => {
                                let newSubExpressions = Array.from(
                                    this.props.subexpressions
                                );
                                newSubExpressions.splice(
                                    subexpressionIndex,
                                    1
                                );

                                if (newSubExpressions.length === 0) {
                                    newSubExpressions.push({
                                        operation: "",
                                        value: "",
                                    });
                                }

                                this.props.onChange(newSubExpressions);
                            }}
                        >
                            {"\uFF0D" /* minus */}
                        </Button>
                    </div>
                </div>
                <div className={styles.expressionLink}>
                    <Switch
                        onChange={() => {
                            let newSubExpressions = Array.from(
                                this.props.subexpressions
                            );
                            newSubExpressions[
                                subexpressionIndex
                            ].isInput = !(
                                newSubExpressions[
                                    subexpressionIndex
                                ].isInput ?? false
                            );
                            newSubExpressions[
                                subexpressionIndex
                            ].value = null;

                            this.props.onChange(
                                newSubExpressions
                            );
                        }}
                        checked={subexpression.isInput ?? false}
                        width={26}
                        height={13}
                        offColor="#D1D1D1"
                        onColor="#20293C"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#FFF"
                        onHandleColor="#1F8EFA"
                    />
                    <span className={styles.label}>
                        Link element
                    </span>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.props.subexpressions.map(
                    (subexpression, subexpressionIndex) => this.renderRow(subexpression, subexpressionIndex)
                )}
            </div>
        );
    }
}
