import React from "react";
// import { mainStyle } from "common/MainStyle";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";

// Custom styling
import Select from "react-select";
import { getCustomSelectStyleLight } from "common/SelectStyles";
import axios from "common/ServerConnection";

// Added for TypeScript 4 upgrade
type ApiResponse = {
    success: boolean
}

type BigQueryProject = {
    id: string;
    numericId: string;
    friendlyName: string;
}

type ProjectsApiResponse = ApiResponse & { projects: BigQueryProject[] }

// type BigQueryDataset = {
//     id: string;
//     name: string;
// }

// type DatasetApiResponse = ApiResponse & { datasets: BigQueryDataset[] }
///

/*
interface HSOptions {
    //apikey: string;
    apiselect: Object;
    submitColumns: string[];
}*/

interface Options {
    value: string,
    label: string
}

interface State {
    projects: Options[];
    datasets: Options[];
    tables: Options[];
}

export default class BIGQUERY extends React.Component<APIComponentProps, State> {
    constructor(props: APIComponentProps) {
        super(props);
        this.state = {
            projects: [],
            datasets: [],
            tables: []
        }

        this.props.onOptionsChange({
            id: ""
        });
    }

    componentDidMount() {
        this.setState({
            projects: [],
            datasets: [],
            tables: []
        })

        axios.get<ProjectsApiResponse>("/api/bigquery/projects").then(({ data }: { data : ProjectsApiResponse }) => {
        //axios.get<any>("/api/bigquery/projects").then(({ data }) => {
            if (data.success) {
                this.setState({
                    //projects: data.projects.map((p: any) => ({
                    projects: data.projects.map((p) => ({
                        value: p.id,
                        label: p.friendlyName
                    })),
                });
            }
        });
    }

    onProjectChange(evt: any) {
        this.setState({
            datasets: [],
            tables: []
        })

        // TODO: need to specify the type for data below
        axios.get<any>(`/api/bigquery/projects/${evt.value}/datasets`).then(({ data }) => {
            if (data.success) {
                this.setState({
                    datasets: data.datasets.map((d: any) => ({
                        value: d.id,
                        label: d.datasetReference.datasetId
                    })),
                });
            }
        });
    }

    onDatasetChange(evt: any) {
        this.setState({
            tables: []
        })

        const [projectId, datasetId] = evt.value.split(":");

        axios.get<any>(`/api/bigquery/projects/${projectId}/datasets/${datasetId}/tables`).then(({ data }) => {
            if (data.success) {
                this.setState({
                    tables: data.tables.map((t: any) => ({
                        value: t.id,
                        label: t.tableReference.tableId
                    })),
                });
            }
        });
    }

    onTableChange(evt: any) {
        let options = { ...this.props.options };
        this.props.onOptionsChange({
            ...options,
            id: evt.value,
        });
    }

    render() {
        let selectStyles = getCustomSelectStyleLight(14, false);
        // let contentStyle: React.CSSProperties = {
        //     fontFamily: "Roboto",
        //     fontSize: 16,
        //     fontWeight: 400,
        //     color: mainStyle.getPropertyValue("--content-primary-text-color"),
        // };
        // let titleStyle = {
        //     ...contentStyle,
        //     color: "#39F",
        // };
        return (
            <div
                className="flex-simple-column"
                style={this.props.containerStyle}
            >
                <BaseAPIComponent {...this.props} />
                <div className="flex-simple-column" style={{ marginTop: "23px" }}>
                        {
                            this.state.projects.length > 0 && (
                                <div className="my-row" style={{ marginLeft: 0, marginTop: 9 }} >
                                    <div style={{ width: "100%" }}>
                                        <label>Projects</label>
                                        <Select name="projects-option"
                                            options={this.state.projects}
                                            styles={{
                                                ...selectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    height: "40px",
                                                    width: "40%",
                                                    marginLeft: "0px",
                                                    marginRight: "10px",
                                                }),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 2,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25: "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                            onChange={(e) => this.onProjectChange(e)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {
                            this.state.datasets.length > 0 && (
                                <div className="my-row" style={{ marginLeft: 0, marginTop: 9 }} >
                                    <div style={{ width: "100%" }}>
                                        <label>Datasets</label>
                                        <Select name="projects-option"
                                            options={this.state.datasets}
                                            styles={{
                                                ...selectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    height: "40px",
                                                    width: "40%",
                                                    marginLeft: "0px",
                                                    marginRight: "10px",
                                                }),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 2,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25: "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                            onChange={(e) => this.onDatasetChange(e)}
                                        ></Select>
                                    </div>
                                </div>
                            )
                        }
                        {
                            this.state.tables.length > 0 && (
                                <div className="my-row" style={{ marginLeft: 0, marginTop: 9 }} >
                                    <div style={{ width: "100%" }}>
                                        <label>Tables</label>
                                        <Select name="projects-option"
                                            options={this.state.tables}
                                            styles={{
                                                ...selectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    height: "40px",
                                                    width: "40%",
                                                    marginLeft: "0px",
                                                    marginRight: "10px",
                                                }),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 2,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25: "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                            onChange={(e) => this.onTableChange(e)}
                                        ></Select>
                                    </div>
                                </div>
                            )
                        }
                </div>
            </div>
        );
    }
}

export { BIGQUERY as MainComponent };
