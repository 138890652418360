import "core-js/features/array/flat";

import React from "react";
import ReactDOM from "react-dom";
import amplitude from "amplitude-js";
import checkIsSharedPage from "main_pages/SharedPageChecker";
import ErrorBoundary from "common/ErrorBoundary";
import * as serviceWorker from "serviceWorker";
import "common/styles/index.css";
import Auth0ProviderWrapper from "Auth0ProviderWrapper";
import axios from "common/ServerConnection";


const AMPLITUDE_DEV_KEY = "15db850a8012e6b4d66b410e22ff7ac3";
const AMPLITUDE_PROD_KEY = "7a8e0607d0bd81194a99dba756cf98a5";
const AMPLITUDE_TEST_KEY = "39483a990e6e952d876d81b945fc327e";

amplitude.getInstance().init(
    window.location.host.startsWith("dev")
        ? AMPLITUDE_DEV_KEY
        : process.env.NODE_ENV === "production"
        ? AMPLITUDE_PROD_KEY
        : AMPLITUDE_TEST_KEY,
    undefined,
    {
        plan: {
            branch: "main",
            source: "web",
            // version and versionId are arbitrary. Feel free to change them
            // if that's necessary.
            version: "1",
            versionId: "6bd8c498-ada7-4176-a01b-a0e91ad67def",
        },
    }
);

axios.get("/api/auth0_config").then((response) => {
    if (!checkIsSharedPage()) {
        ReactDOM.render(
            <ErrorBoundary>
                <Auth0ProviderWrapper
                    modulePath={"App"}
                    auth0_domain={response.data.AUTH0_DOMAIN}
                    auth0_clientId={response.data.AUTH0_CLIENT_ID}
                    auth0_audience={response.data.AUTH0_API_IDENTIFIER}
                />
            </ErrorBoundary>,
            document.getElementById("root")
        );
    } else {
        const renderSharedPages =
            require("main_pages/SharedPagesRenderer").default;
        renderSharedPages({
            auth0_domain: response.data.AUTH0_DOMAIN,
            auth0_clientId: response.data.AUTH0_CLIENT_ID,
            auth0_audience: response.data.AUTH0_API_IDENTIFIER,
        });
    }
});

serviceWorker.register();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
