import React from "react";
import { observer } from "mobx-react";
import { getTargetValue } from "common/Canvas";

import { ConditionsSelector } from "common/ConditionalsEditor";
import Variables from "common/Variables";
import remoteModuleId from "common/remoteModuleId";
import { lightThemeStyle } from "common/LightThemeStyle";
import { Condition, NodeLinkOption } from "common/Conditions";
import SharedBoxesStore from "modules/canvas_page/SharedBoxesStore";
import GlobalInputs, { getGlobalInputValue } from "common/GlobalInputs";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import Finding from "common/Finding";

interface Props<F extends Finding> {
    canvasTreeStore: CanvasTreeStore;
    finding: F;
    onChange: (finding: F, update?: boolean) => void;
    currentModuleId?: number;
}

function ConditionsConfig<F extends Finding>({
    finding,
    onChange,
    currentModuleId,
    canvasTreeStore,
}: Props<F>) {
    if (!finding.config.dataScope) {
        return null;
    }
    let nodeLinkOptions = Array.from(canvasTreeStore.canvasTreeState.values())
        .map(
            (item) =>
                ({
                    label: item.outerId,
                    value: item.id,
                    isCloneInput: false,
                    target: getTargetValue(item),
                } as NodeLinkOption)
        )
        .concat(
            SharedBoxesStore.sharedBoxes(canvasTreeStore.canvasId!).map(
                (item) => ({
                    label: item.label,
                    value: item.value,
                    isCloneInput: true,
                    target: getTargetValue(item.item.box),
                })
            )
        )
        .concat(
            GlobalInputs.map((input) => ({
                label: input.label,
                value: input.value,
                isCloneInput: false,
                target: getGlobalInputValue(input.value, true),
                isGlobalInput: true,
            }))
        );

    return (
        <div>
            <ConditionsSelector
                small
                allowLinks
                nodeLinkOptions={nodeLinkOptions}
                dataScopeId={finding.config.dataScope!.value}
                currentModuleId={currentModuleId}
                title="where"
                style={{
                    marginTop: 0,
                }}
                value={finding.config.conditions ?? undefined}
                onChange={(conditions: Condition[]) => {
                    onChange(
                        {
                            ...finding,
                            config: {
                                ...finding.config,
                                conditions: conditions,
                            },
                        },
                        true
                    );
                }}
                allVariables={
                    Variables(
                        finding.config.dataScope!.value,
                        currentModuleId ?? remoteModuleId
                    ).dataVariables
                }
                titleStyle={{
                    color: lightThemeStyle.primaryTextColor,
                    width:
                        finding.config.conditions != null &&
                        finding.config.conditions.length > 1
                            ? "70px"
                            : undefined,
                }}
                rowStyle={(_index) => ({
                    backgroundColor: "transparent",
                    alignItems: "center",
                })}
                linkSwitchStyle={{
                    offColor: lightThemeStyle.switchOffColor,
                    onColor: lightThemeStyle.switchOnColor,
                    offHandleColor: lightThemeStyle.switchOffHandleColor,
                    onHandleColor: lightThemeStyle.switchOnHandleColor,
                }}
                buttonsStyle={{
                    backgroundColor: "transparent",
                    color: lightThemeStyle.secondaryTextColor,
                    fontFamily: "Roboto",
                    fontSize: "12px",
                }}
            />
        </div>
    );
}

export default observer(ConditionsConfig);
