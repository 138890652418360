import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { ManageTableElement, QuestionType, TextQuestion } from "common/Canvas";
import { mainStyle } from "common/MainStyle";
import MultipleChoiceQuestionView from "./question_views/MultipleChoiceQuestionView";
import QuestionHeaderView from "./question_views/QuestionHeaderView";
import DataScopeAndTableSelectorView from "common/DataScopeAndTableSelectorView";
import TextQuestionView from "./question_views/TextQuestionView";
import PermissionControlView from "common/PermissionControlView";
import Tables, { TableOption } from "common/Tables";
import {
    ManageTableOperation,
    manageTableOperationOptions,
} from "common/ManageTableOperation";
import axios from "common/ServerConnection";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import { GroupExtendedPermissionWithUserInfo } from "common/GroupPermissions";
import { getDataTablePermissionsApi } from "common/UserGroupsApi";
import CanvasPreventPropagationButton from "./CanvasPreventPropagationButton";
import { goToInternalLink } from "common/InternalLinksHelper";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";

// Added SVG buttons
import UpButtonBig from "icons/UpButtonBig.svg";
import DownButtonBig from "icons/DownButtonBig.svg";
import { stringSessionId } from "common/SessionId";

interface Props {
    onContextMenu: (evt: React.MouseEvent) => void;
    manageTableElement: ManageTableElement;
    manageTableElementId: string;
    height: number;
    live: boolean;
    frozen: boolean;
    sharedPolicy: CanvasSharedPolicy;
    onChange: (manageTableElement: ManageTableElement) => void;
    onDelete: () => void;
    currentModuleId: number | undefined;
}

enum OperationStatus {
    NotStarted = 1,
    Running = 2,
    Success = 3,
    Error = 4,
}

interface State {
    step: Step;
    animationDirection: string;
    operationErrorMessage: string | null;
    operationStatus: OperationStatus;
    newDataSetName: string;
    permissions: GroupExtendedPermissionWithUserInfo[];
}

enum Step {
    Initial = 0,
    SelectData = 1,
    SelectOperation = 2,
    Final = 3,
}

function Header(props: { frozen: boolean; onDelete: () => void }) {
    return (
        <div
            style={{
                minHeight: "90px",
                width: "100%",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 30,
            }}
        >
            <div className="my-row" style={{ justifyContent: "space-between" }}>
                {false ? (
                    <div className="my-row" style={{ alignItems: "center" }}>
                        <img
                            alt=""
                            src={"/dist/img/data-exploration/server_purple.png"}
                        />
                        <span
                            style={{
                                marginLeft: 30,
                                fontFamily: "Roboto",
                                fontSize: mainStyle.getPropertyValue(
                                    "--path-header-size"
                                ),
                                color:
                                    dataScienceElementsStyle.primaryTextColor,
                            }}
                        >
                            {"Creating Manage Tables Element"}
                        </span>
                    </div>
                ) : (
                    <div />
                )}
                {!props.frozen && (
                    <CanvasPreventPropagationButton>
                        <div
                            onClick={(evt) => {
                                evt.stopPropagation();
                                props.onDelete();
                            }}
                        >
                            <img
                                alt=""
                                src="/dist/img/insights/insights_remove.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </CanvasPreventPropagationButton>
                )}
            </div>
        </div>
    );
}

export default class ManageTableView extends Component<Props, State> {
    rootRef: React.RefObject<HTMLDivElement>;
    stepHistory: Step[];

    constructor(props: Props) {
        super(props);
        this.state = {
            step: Step.Initial,
            animationDirection: "top",
            operationErrorMessage: null,
            operationStatus: OperationStatus.NotStarted,
            newDataSetName: "",
            permissions: [],
        };
        this.stepHistory = [Step.Initial];
        this.rootRef = React.createRef();
    }

    private buildHeader(): JSX.Element {
        if (this.props.live) return <div style={{ minHeight: "90px" }} />;
        return (
            <Header
                frozen={this.props.frozen}
                onDelete={() => {
                    this.props.onDelete();
                }}
            />
        );
    }

    private calculateNextStep(step: Step): Step {
        let newStep = step;
        switch (step) {
            case Step.Initial:
                newStep = Step.SelectData;
                break;
            case Step.SelectData:
                // Table can be NULL if we only want to manage the dataset
                if (this.props.manageTableElement.dataScope != null)
                    newStep = Step.SelectOperation;
                break;
            case Step.SelectOperation:
                if (this.props.manageTableElement.operationOption != null)
                    newStep = Step.Final;
                break;
            default:
                break;
        }
        return newStep;
    }

    private stepUp(): void {
        if (this.state.step === Step.Initial) return;
        else if (this.state.step === Step.Final) {
            if (this.state.operationStatus === OperationStatus.Running) return;
            this.setState({
                operationStatus: OperationStatus.NotStarted,
                operationErrorMessage: null,
            });
            if (this.state.operationStatus === OperationStatus.Success) {
                this.goToInitial();
                return;
            }
        }
        let prevStep = this.stepHistory[this.stepHistory.length - 2];
        this.setState({ animationDirection: "bottom" }, () => {
            this.setState(
                {
                    step: prevStep!,
                },
                () => {
                    this.stepHistory.pop();
                }
            );
        });
    }

    private goToInitial(): void {
        this.setState(
            {
                animationDirection: "bottom",
                operationStatus: OperationStatus.NotStarted,
            },
            () => {
                this.setState(
                    {
                        step: Step.Initial,
                    },
                    () => {
                        this.stepHistory = [this.state.step];
                    }
                );
            }
        );
    }

    private stepDown(): void {
        if (this.state.step === Step.Final) return;
        let nextStep = this.calculateNextStep(this.state.step);
        if (nextStep === this.state.step) return;
        if (
            nextStep === Step.Final &&
            this.props.manageTableElement.dataScope != null &&
            this.props.manageTableElement.operationOption?.value ===
                ManageTableOperation.EditAccessControl
        ) {
            getDataTablePermissionsApi(
                this.props.manageTableElement.dataScope.value,
                true
            )
                .then((permissions) => {
                    this.setState({
                        permissions: permissions,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        this.setState({ animationDirection: "top" }, () => {
            this.setState(
                {
                    step: nextStep,
                },
                () => {
                    this.stepHistory.push(nextStep);
                }
            );
        });
        this.rootRef.current?.focus();
    }

    private buildLeftBar(): JSX.Element {
        return (
            <div
                className="flex-simple-column"
                style={{
                    paddingLeft: 48,
                    minWidth: "116px",
                }}
            >
                {this.state.step > Step.Initial && (
                    <CanvasPreventPropagationButton>
                        <div
                            onClick={(evt) => {
                                evt.stopPropagation();
                                this.stepUp();
                            }}
                        >
                            <img
                                alt=""
                                src={UpButtonBig} //"/dist/img/canvas/up_big.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </CanvasPreventPropagationButton>
                )}
                <div style={{ flexGrow: 1 }}> </div>
                {this.state.step !== Step.Initial &&
                    this.state.step < Step.Final && (
                        <CanvasPreventPropagationButton>
                            <div
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    this.stepDown();
                                }}
                            >
                                <img
                                    alt=""
                                    src={DownButtonBig} //"/dist/img/canvas/down_big.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </CanvasPreventPropagationButton>
                    )}
            </div>
        );
    }

    private buildInnerItem(): JSX.Element | null {
        if (this.state.step === Step.Initial) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        paddingBottom: "52px",
                        paddingRight: "116px",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{ width: "100%", alignItems: "center" }}
                    >
                        <div className="flex-simple-column">
                            <span
                                style={{
                                    textAlign: "center",
                                    fontSize: mainStyle.getPropertyValue(
                                        "--primary-path-title-size"
                                    ),
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    color:
                                        dataScienceElementsStyle.primaryTextColor,
                                }}
                            >
                                {"Manage your tables"}
                            </span>
                            <div
                                style={{
                                    marginTop: "31px",
                                    alignSelf: "center",
                                }}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    if (
                                        this.props.sharedPolicy ===
                                        CanvasSharedPolicy.NotShared
                                    )
                                        this.stepDown();
                                    if (
                                        this.props.sharedPolicy ===
                                        CanvasSharedPolicy.SharedSlideUnAuth
                                    )
                                        goToInternalLink("/");
                                }}
                            >
                                <img
                                    alt=""
                                    src="/dist/img/data/add_data_small.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <span
                                style={{
                                    alignSelf: "center",
                                    marginTop: 10,
                                    cursor: "pointer",
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    color:
                                        dataScienceElementsStyle.secondaryTextColor,
                                }}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    if (
                                        this.props.sharedPolicy ===
                                        CanvasSharedPolicy.NotShared
                                    )
                                        this.stepDown();
                                    if (
                                        this.props.sharedPolicy ===
                                        CanvasSharedPolicy.SharedSlideUnAuth
                                    )
                                        goToInternalLink("/");
                                }}
                            >
                                {"Click to proceed"}
                            </span>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.step === Step.SelectData) {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Select a data set and (optionally) a table"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <DataScopeAndTableSelectorView
                        needWritePermissions={true}
                        dataScopeOption={
                            this.props.manageTableElement.dataScope
                        }
                        tableOption={this.props.manageTableElement.tableOption}
                        tableOptionClearable
                        maxHeight={this.props.height * 0.5}
                        onChange={(
                            dataScopeOption: DataScopeOption | null,
                            tableOption: TableOption | null
                        ) => {
                            let mergeDataElement = {
                                ...this.props.manageTableElement,
                                dataScope: dataScopeOption,
                                tableOption: tableOption,
                            };
                            this.props.onChange(mergeDataElement);
                        }}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        } else if (this.state.step === Step.SelectOperation) {
            let options = manageTableOperationOptions;
            if (this.props.manageTableElement.tableOption == null) {
                options = options.filter((option) => !option.isTableOperation);
            }
            let question = {
                question: "Do you want to",
                type: QuestionType.MultipleChoice,
                options: options.map((option) => ({
                    selected:
                        option.value ===
                        this.props.manageTableElement.operationOption?.value,
                    option: option.label,
                })),
            };
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={question.question}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <MultipleChoiceQuestionView
                        frozen={true}
                        forceRowRepresentation={true}
                        multiselection={false}
                        live={true}
                        maxHeight={this.props.height * 0.5}
                        question={question}
                        onChange={(question) => {
                            let selectedIndex = question.options.findIndex(
                                (item: { option: string; selected: boolean }) =>
                                    item.selected
                            );
                            let mergeDataElement = Object.assign(
                                {},
                                this.props.manageTableElement
                            );
                            mergeDataElement.operationOption =
                                options[selectedIndex];
                            this.props.onChange(mergeDataElement);
                        }}
                        fontStyle={{
                            fontSize: "22px",
                        }}
                    />
                </div>
            );
        } else if (this.state.step === Step.Final) {
            let title: string = "";
            let color = dataScienceElementsStyle.primaryTextColor;
            if (this.state.operationStatus === OperationStatus.Running) {
                title = "Running...";
            } else if (this.state.operationStatus === OperationStatus.Success) {
                title = "Success!";
            } else {
                switch (this.props.manageTableElement.operationOption?.value) {
                    case ManageTableOperation.RemoveTable:
                        title = `Are you sure you want to remove "${this.props.manageTableElement.tableOption?.label}" table from data set "${this.props.manageTableElement.dataScope?.label}"?`;
                        break;
                    case ManageTableOperation.RemoveDataSet:
                        title = `Are you sure you want to remove data set "${this.props.manageTableElement.dataScope?.label}"?`;
                        break;
                    // case ManageTableOperation.RenameDataSet:
                    //     title = "What do you want to rename this data set to?";
                    //     break;
                    case ManageTableOperation.EditAccessControl:
                        title = "Edit Access Control";
                        break;
                    default:
                        break;
                }
            }

            const operationOption = this.props.manageTableElement
                .operationOption?.value;

            if (
                // operationOption === ManageTableOperation.RenameDataSet  &&
                this.state.operationStatus !== OperationStatus.Success
            ) {
                let question: TextQuestion = {
                    question: "",
                    type: QuestionType.Text,
                    answer: this.state.newDataSetName,
                };
                return (
                    <div
                        className="flex-simple-column"
                        style={{
                            height: "100%",
                            width: "100%",
                            paddingRight: "109px",
                        }}
                    >
                        <QuestionHeaderView
                            question={title}
                            live={true}
                            frozen={true}
                            onChange={() => {}}
                        />
                        <TextQuestionView
                            singleLine
                            hideTextUnder
                            question={question}
                            live={true}
                            frozen={false}
                            maxHeight={this.props.height * 0.5}
                            onChange={(newValue) => {
                                this.setState({
                                    newDataSetName: newValue.answer,
                                });
                            }}
                            onNext={() => {}}
                        />
                    </div>
                );
            } else if (
                operationOption === ManageTableOperation.EditAccessControl &&
                this.state.operationStatus !== OperationStatus.Success
            ) {
                return (
                    <div
                        className="flex-simple-column"
                        style={{
                            height: "100%",
                            width: "100%",
                            paddingRight: "109px",
                        }}
                    >
                        <QuestionHeaderView
                            question={title}
                            live={true}
                            frozen={true}
                            onChange={() => {}}
                        />
                        <PermissionControlView
                            maxHeight={this.props.height * 0.5}
                            permissions={this.state.permissions}
                            onPermissionsChange={(permissions) => {
                                this.setState({
                                    permissions: permissions,
                                });
                            }}
                        />
                    </div>
                );
            } else {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            width: "100%",
                            paddingBottom: "52px",
                            paddingRight: "116px",
                        }}
                    >
                        <span
                            style={{
                                textAlign: "center",
                                fontSize: mainStyle.getPropertyValue(
                                    "--primary-path-title-size"
                                ),
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                fontFamily: "Roboto",
                                color: color,
                            }}
                        >
                            {title}
                        </span>
                    </div>
                );
            }
        } else {
            return null;
        }
    }

    private buildBottomBar(): JSX.Element | null {
        if (this.state.step === Step.Final) {
            let buttonText: string = "";
            if (this.state.operationStatus === OperationStatus.Success) {
                buttonText = "DONE";
            } else {
                switch (this.props.manageTableElement.operationOption?.value) {
                    case ManageTableOperation.RemoveTable:
                    case ManageTableOperation.RemoveDataSet:
                        buttonText = "REMOVE";
                        break;
                    // case ManageTableOperation.RenameDataSet:
                    //     buttonText = "RENAME";
                    //     break;
                    case ManageTableOperation.EditAccessControl:
                        buttonText = "UPDATE";
                        break;
                    default:
                        break;
                }
            }
            return (
                <div
                    className="my-row"
                    style={{
                        alignSelf: "flex-end",
                        paddingRight: "109px",
                        marginTop: "10px",
                    }}
                >
                    {this.state.operationErrorMessage != null && (
                        <div
                            className="my-row"
                            style={{
                                alignSelf: "flex-end",
                                paddingRight: "109px",
                                marginTop: "10px",
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    color: "red",
                                }}
                            >
                                {this.state.operationErrorMessage}
                            </span>
                        </div>
                    )}
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            width: "200px",
                        }}
                        onClick={(evt: any) => {
                            evt.stopPropagation();
                            if (
                                this.state.operationStatus !==
                                OperationStatus.Success
                            ) {
                                this.executeOperation();
                            } else {
                                this.setState({
                                    operationStatus: OperationStatus.NotStarted,
                                    operationErrorMessage: null,
                                });
                                this.goToInitial();
                            }
                        }}
                        disabled={
                            this.state.operationStatus ===
                            OperationStatus.Running
                        }
                    >
                        {buttonText}
                    </Button>
                </div>
            );
        }
        return null;
    }

    private executeOperation(): void {
        if (
            this.props.manageTableElement.dataScope == null ||
            this.props.manageTableElement.operationOption == null ||
            (this.props.manageTableElement.operationOption.value ===
                ManageTableOperation.RemoveTable &&
                this.props.manageTableElement.tableOption == null)
        )
            return;
        this.setState({
            operationStatus: OperationStatus.Running,
            operationErrorMessage: null,
        });
        const dataScope: number | string = this.props.manageTableElement
            .dataScope.value;
        const tableOption: TableOption = this.props.manageTableElement
            .tableOption!;
        let requestUrl: string = "";
        let requestArgs: any = {};
        let onSuccess: (() => void) | undefined = undefined;
        switch (this.props.manageTableElement.operationOption?.value) {
            case ManageTableOperation.RemoveTable:
                requestUrl = "/api/e/delete_table";
                requestArgs = {
                    data_table_idx: dataScope,
                    table: tableOption.value,
                    condition_id: tableOption.condition_id,
                    optimized: tableOption.optimized,
                    module_id: this.props.currentModuleId ?? remoteModuleId,
                    update_id: stringSessionId(),
                };
                onSuccess = () => {
                    Tables(dataScope).update(
                        this.props.currentModuleId ?? remoteModuleId
                    );
                };
                break;
            case ManageTableOperation.RemoveDataSet:
                requestUrl = "/api/e/delete_data_tables";
                requestArgs = {
                    data_table_idx: dataScope,
                    module_id: this.props.currentModuleId ?? remoteModuleId,
                    update_id: stringSessionId(),
                };
                onSuccess = () => {
                    DataScopes.update();
                    if (this.props.currentModuleId != null) {
                        DataScopesForModules(
                            this.props.currentModuleId
                        ).update();
                    }
                };
                break;
            // case ManageTableOperation.RenameDataSet:
            //     requestUrl = "/api/e/rename_data_tables";
            //     requestArgs = {
            //         data_table_idx: dataScope,
            //         data_table_name: this.state.newDataSetName,
            //         module_id: this.props.currentModuleId ?? remoteModuleId,
            //         update_id: stringSessionId(),
            //     };
            //     onSuccess = () => {
            //         DataScopes.update();
            //         DataScopesForModules(this.props.currentModuleId).update();
            //     };
            //     break;
            case ManageTableOperation.EditAccessControl:
                requestUrl = "/api/set_data_table_permissions";
                requestArgs = {
                    data_table_idx: dataScope,
                    // module_id is not supported here
                    // module_id: this.props.currentModuleId ?? remoteModuleId,
                    update_id: stringSessionId(),
                    permissions: this.state.permissions
                        .filter(
                            (permission) => permission.permission_type !== -1
                        )
                        .map((permission) => ({
                            group_id: permission.group_id,
                            permission_type: permission.permission_type,
                        })),
                };
                break;
        }
        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>(requestUrl, requestArgs)
            .then((response) => {
                if (response.data.success) {
                    this.setState(
                        {
                            operationStatus: OperationStatus.Success,
                            operationErrorMessage: null,
                            newDataSetName: "",
                        },
                        onSuccess
                    );
                    let manageTableElement: ManageTableElement = {
                        ...this.props.manageTableElement,
                        dataScope: null,
                        tableOption: null,
                        operationOption: null,
                    };
                    this.props.onChange(manageTableElement);
                } else {
                    this.setState({
                        operationStatus: OperationStatus.Error,
                        operationErrorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    operationStatus: OperationStatus.Error,
                    operationErrorMessage: String(error),
                });
            });
    }

    private buildContent(): JSX.Element {
        return (
            <div
                className="my-row"
                style={{ height: "100%", width: "100%", paddingBottom: "38px" }}
            >
                {this.buildLeftBar()}
                <div className="flex-simple-column" style={{ width: "100%" }}>
                    <React.Fragment key={this.state.step}>
                        {this.buildInnerItem()}
                    </React.Fragment>
                    {this.buildBottomBar()}
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <div
                ref={this.rootRef}
                className="dashboard-rect-canvas dashboard-rect-canvas-focus"
                tabIndex={0}
                style={{
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                }}
                onContextMenu={this.props.onContextMenu}
                onKeyDown={(evt) => {
                    if (evt.key === "ArrowDown") {
                        evt.stopPropagation();
                        this.stepDown();
                        evt.preventDefault();
                        return;
                    }
                    if (evt.key === "ArrowUp") {
                        evt.stopPropagation();
                        evt.preventDefault();
                        this.stepUp();
                        return;
                    }
                    if (evt.key === "Enter" && evt.shiftKey) {
                        evt.stopPropagation();
                        evt.preventDefault();
                        this.stepDown();
                        return;
                    }

                    let charCode = evt.key.charCodeAt(0) - "a".charCodeAt(0);
                    if (this.state.step === Step.SelectOperation) {
                        let options = manageTableOperationOptions;
                        if (this.props.manageTableElement.tableOption == null) {
                            options = options.filter(
                                (option) => !option.isTableOperation
                            );
                        }
                        if (charCode >= 0 && charCode <= options.length - 1) {
                            evt.stopPropagation();
                            evt.preventDefault();
                            let manageTableElement = {
                                ...this.props.manageTableElement,
                                mergeType: options[charCode],
                            };
                            this.props.onChange(manageTableElement);
                        }
                    }
                }}
            >
                <div
                    className="flex-column"
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                >
                    {this.buildHeader()}

                    <TransitionGroup
                        style={{
                            height: "100%",
                            display: "flex",
                        }}
                    >
                        <CSSTransition
                            key={this.state.step}
                            timeout={500}
                            classNames={"journeywizard-".concat(
                                this.state.animationDirection || ""
                            )}
                        >
                            <div style={{ minWidth: "100%" }}>
                                {this.buildContent()}
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}
