import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="15"
			height="15"
			viewBox="0 0 15 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.75 6.2499L4.63125 5.36865L7.5 8.23115L10.3688 5.36865L11.25 6.2499L7.5 9.9999L3.75 6.2499Z"
				fill="black"
				fillOpacity="0.7"
			/>
			<mask
				id="mask0"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="3"
				y="5"
				width="9"
				height="5"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.75 6.2499L4.63125 5.36865L7.5 8.23115L10.3688 5.36865L11.25 6.2499L7.5 9.9999L3.75 6.2499Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0)">
				<rect
					y="15"
					width="15"
					height="15"
					transform="rotate(-90 0 15)"
					fill="#657D95"
				/>
			</g>
		</svg>
	);
}