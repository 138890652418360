import React, { Component } from "react";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";

import { Field } from "modules/settings_page/common/FieldEditor";
import { PermanentLinkType, SelfSignupInfo } from "common/ModulesApi";
import cx from "classnames";
import mds from "../RegisterPageV1/MaterialDashboard.module.css";
import styles from "../RegisterPageV1/RegisterPage.module.css";
import Alert from "common/Alert";
import { initiatePasswordResetApi } from "common/EmailApi";

function toMdsModuleClassName(className: string) {
    let mdsStyles = className.split(" ").map((item) => mds[item]);
    return cx(mdsStyles);
}

enum Status {
    Running = "running",
    Finished = "finished",
    Error = "error",
}

const statusToClassName: Readonly<{ [key in Status]: string }> = {
    [Status.Running]: "alert-warning",
    [Status.Finished]: "alert-success",
    [Status.Error]: "alert-danger",
};

interface Props {
    linkId: string;
    permanentLinkType?: PermanentLinkType;
}
interface State {
    focusField?: string;
    verified: boolean | undefined;
    email: string;
    value: Field[];
    selfSignupInfo: SelfSignupInfo | null;
    status: Status | null;
    error: string | null;
}

export default class RegisterPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        document.children[0]!.setAttribute("style", "font-size:20px");
        let value: Field[] = [
            {
                name: "httpd_username",
                label: "Username or email",
                value: "",
                editable: true,
                required: true,
                hidden: false,
                add_mock_after: true,
            },
        ];
        this.state = {
            selfSignupInfo: null,
            status: null,
            error: null,
            email: "",
            verified: undefined,
            focusField: undefined,
            value: value,
        };
    }

    render() {
        let statusText: string = "";
        if (this.state.status === Status.Running) {
            statusText = "Running";
        } else if (this.state.status === Status.Finished) {
            statusText = "Success: check your email";
        } else {
            statusText = `Error: ${this.state.error}`;
        }

        let backgroundStyle: React.CSSProperties = {
            backgroundImage: "url(/dist/img/register/splash2.png)",
        };
        if (this.state.selfSignupInfo?.options?.backgroundColor) {
            backgroundStyle = {
                background: this.state.selfSignupInfo?.options?.backgroundColor,
            };
        }
        if (this.state.selfSignupInfo?.options?.backgroundImage) {
            backgroundStyle = {
                backgroundImage: `url(${this.state.selfSignupInfo?.options?.backgroundImage})`,
            };
        }
        return (
            <main className={toMdsModuleClassName("main-content  mt-0")}>
                <div
                    className={toMdsModuleClassName(
                        "page-header align-items-start min-vh-100"
                    )}
                    style={backgroundStyle}
                >
                    <span className={styles.bgGradient} />
                    <div className={toMdsModuleClassName("container my-auto")}>
                        <div className={toMdsModuleClassName("row")}>
                            <div
                                className={toMdsModuleClassName(
                                    "col-lg-4 col-md-8 mx-auto"
                                )}
                            >
                                <div
                                    className={toMdsModuleClassName(
                                        "card z-index-0 fadeIn3 fadeInBottom"
                                    )}
                                >
                                    <div
                                        className={cx(
                                            toMdsModuleClassName(
                                                "border-radius-lg"
                                            ),
                                            styles.logoContainer
                                        )}
                                        style={{
                                            background:
                                                this.state.selfSignupInfo
                                                    ?.options?.logoColor ||
                                                undefined,
                                        }}
                                    >
                                        <span className={styles.title}>
                                            Forgot password
                                        </span>
                                    </div>
                                    <div
                                        className={toMdsModuleClassName(
                                            "card-body"
                                        )}
                                    >
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    status: Status.Running,
                                                });
                                                initiatePasswordResetApi(
                                                    this.state.value[0].value
                                                )
                                                    .then(() => {
                                                        this.setState({
                                                            status:
                                                                Status.Finished,
                                                        });
                                                    })
                                                    .catch((error) => {
                                                        this.setState({
                                                            status:
                                                                Status.Error,
                                                            error: String(
                                                                error
                                                            ),
                                                        });
                                                    });
                                            }}
                                        >
                                            {this.state.value.map(
                                                (item, index) => (
                                                    <div
                                                        key={index}
                                                        className={
                                                            styles.fieldsContainer
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.fieldLabel
                                                            }
                                                        >
                                                            {item.label}
                                                        </span>
                                                        <input
                                                            disabled={
                                                                !item.editable
                                                            }
                                                            type={
                                                                item.hidden
                                                                    ? "password"
                                                                    : "text"
                                                            }
                                                            className={
                                                                styles.field
                                                            }
                                                            placeholder={`Enter ${item.label.toLowerCase()}`}
                                                            onFocus={() => {
                                                                this.setState({
                                                                    focusField:
                                                                        item.name,
                                                                });
                                                            }}
                                                            onBlur={() => {
                                                                this.setState({
                                                                    focusField: undefined,
                                                                });
                                                            }}
                                                            value={item.value}
                                                            onChange={(e) => {
                                                                let currentValue = Array.from(
                                                                    this.state
                                                                        .value
                                                                );
                                                                currentValue[
                                                                    index
                                                                ].value =
                                                                    e.target.value;
                                                                this.setState({
                                                                    value: currentValue,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            )}
                                            <div
                                                className={toMdsModuleClassName(
                                                    "text-center"
                                                )}
                                            >
                                                <button
                                                    type="submit"
                                                    className={cx(
                                                        toMdsModuleClassName(
                                                            "btn w-100"
                                                        ),
                                                        styles.btnBlue
                                                    )}
                                                    style={{
                                                        background:
                                                            this.state
                                                                .selfSignupInfo
                                                                ?.options
                                                                ?.createButtonColor ||
                                                            undefined,
                                                    }}
                                                    disabled={
                                                        !this.state.value[0]
                                                            .value
                                                    }
                                                >
                                                    Send restore link
                                                </button>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "absolute",
                                                        bottom: "-250px",
                                                        width: 340,
                                                    }}
                                                    src="/dist/img/logo/logo_with_name_white.svg"
                                                    alt="Logo"
                                                />
                                            </div>
                                            {this.state.status !== null && (
                                                <Alert
                                                    text={statusText}
                                                    className={cx(
                                                        "alert " +
                                                        statusToClassName[
                                                            this.state.status
                                                        ],
                                                        styles.errorMessage
                                                    )}
                                                    onClosed={() => {
                                                        this.setState({
                                                            status: null,
                                                        });
                                                    }}
                                                    style={{
                                                        marginTop: "20px",
                                                        marginBottom: "0px",
                                                    }}
                                                />
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
