import React from "react";

import { CanvasElement, CanvasProgressElement } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import { DataTableInput } from "modules/canvas_page/DataTableInputSelector";
import InputsConfig from "../../common/InputsConfig";
import OutputsConfig from "../../common/OutputsConfig";
import DefaultValueOption from "../../common/DefaultValueOption";
import SliderOutputsSelector from "modules/canvas_page/SilderOutputsSelector";

interface Props {
    canvasId: number;
    node: CanvasProgressElement;
    currentModuleId?: number;
    nodeLinkOptions: NodeLinkOption[];
    dataTableInput: DataTableInput[];
    onChange: (
        changes: Partial<CanvasProgressElement>,
        commit?: boolean
    ) => void;
    onChangeDataTableInput: (newValue: DataTableInput[]) => void;
}

function Data({
    canvasId,
    node,
    currentModuleId,
    nodeLinkOptions,
    dataTableInput,
    onChange,
    onChangeDataTableInput,
}: Props) {
    return (
        <>
            <InputsConfig
                canvasId={canvasId}
                node={node as any as CanvasElement}
                currentModuleId={currentModuleId}
                nodeLinkOptions={nodeLinkOptions}
                dataTableInput={dataTableInput}
                onChange={
                    onChange as (
                        changes: Partial<CanvasElement>,
                        commit?: boolean
                    ) => void
                }
                onChangeDataTableInput={onChangeDataTableInput}
            />
            <OutputsConfig
                node={node as any as CanvasElement}
                onChange={
                    onChange as (
                        changes: Partial<CanvasElement>,
                        commit?: boolean
                    ) => void
                }
            />
            <SliderOutputsSelector value={node} onChange={onChange} />
            <DefaultValueOption node={node} onChange={onChange} />
        </>
    );
}

export default Data;
