import "common/styles/App.css";

import React, { Component } from "react";
import JourneyCard from "common/JourneyCard";
import modules from "./modules/module_list.json";

import { CSSTransition } from "react-transition-group";
import ls from "local-storage";
//import { mainStyle } from "common/MainStyle";
import CollapseExtendButton from "common/CollapseExtendButton";
import ScaledPage from "common/ScaledPage";
import Instrumentation from "common/Instrumentation";
import currentUser from "common/CurrentUser";
import { reaction } from "mobx";
import GlobalContext from "GlobalContext";
import { lightThemeStyle } from "common/LightThemeStyle";
import { ReactComponent as ExploreIcon } from "icons/left_nav_menu/explore.svg";

import styles from "./main.module.css";

class MainComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: {},
            moduleNames: [],
            modulesMap: {},
            submodulesExpanded: {},
        };
        // if this.props.onReturnToCanvas is not null then we'll return to canvas
        // after we save graphics
        this.addToCanvas = this.props.onReturnToCanvas != null;
        this.performance = null;
        this.mounted = false;

        this.renderContents = this.renderContents.bind(this);
    }

    componentDidMount() {
        this.userReaction = reaction(
            () => currentUser.info,
            (pages) => {
                this.initializePage();
            }
        );
        this.initializePage();
    }

    componentWillUnmount() {
        this.userReaction();
    }

    initializePage() {
        if (currentUser.info == null) return;
        if (this.performance != null) {
            let timeMs = new Date() - this.performance;
            this.performance = null;
            Instrumentation.addInteraction("Exploration", timeMs);
        }
        let dataExplorationJourneyName =
            this.props.dashboardConfig?.journeyName ??
            ls.get("dataExplorationJourneyName");

        let sections = {};
        let modulesMap = {};
        let submodulesExpanded = {};
        let moduleNames = [];
        //here we populate content of page according to list of exploration modules
        modules.forEach((module, index) => {
            if (!module.v2Only) {
                let moduleName = module.name;
                moduleNames.push(moduleName);
                sections[index] = false;
                let submodules = module.submodules;
                modulesMap[index] = [];
                submodulesExpanded[index] = [];
                submodules.forEach((submodule) => {
                    if (!submodule.v2Only) {
                        let mod = require(`./modules/${submodule.dir}/main.js`);
                        submodule.mod = mod.MainComponent;
                        modulesMap[index].push(submodule);
                        submodulesExpanded[index].push(
                            dataExplorationJourneyName === submodule.name
                        );
                        sections[index] =
                            sections[index] ||
                            dataExplorationJourneyName === submodule.name;
                    }
                });
            }
        });
        this.setState({
            sections: sections,
            moduleNames: moduleNames,
            modulesMap: modulesMap,
            submodulesExpanded: submodulesExpanded,
        });
        ls.remove("dataExplorationJourneyName");
    }

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs = new Date() - this.performance;
            this.performance = null;
            Instrumentation.addInteraction("Exploration", timeMs);
        }
    }

    buildModuleColumn(index, globalContext) {
        let items = [];
        let submodule;
        for (let submoduleIndex in this.state.modulesMap[index]) {
            submodule = this.state.modulesMap[index][submoduleIndex];
            if (
                !("require_permission" in submodule) ||
                submodule.require_permission in globalContext.permissions
            ) {
                items.push(
                    <>
                        {this.state.submodulesExpanded[index][
                            submoduleIndex
                        ] === false ? (
                            <JourneyCard
                                imageSource={submodule.image_source}
                                title={submodule.title}
                                subtitle={submodule.subtitle}
                                onClick={() => {
                                    this.setState((state) => {
                                        let submodulesExpanded = Object.assign(
                                            {},
                                            state.submodulesExpanded
                                        );
                                        let submoduleExpanded = Array.from(
                                            submodulesExpanded[index]
                                        );
                                        submoduleExpanded[
                                            submoduleIndex
                                        ] = true;
                                        submodulesExpanded[
                                            index
                                        ] = submoduleExpanded;
                                        return {
                                            submodulesExpanded: submodulesExpanded,
                                        };
                                    });
                                }}
                            />
                        ) : null}

                        <submodule.mod
                            {...submodule.additional_props}
                            currentModuleId={this.props.currentModuleId}
                            section="Exploration"
                            imageSource={submodule.image_source}
                            title={submodule.title}
                            journeyName={submodule.name}
                            key={
                                this.state.submodulesExpanded[index][
                                    submoduleIndex
                                ]
                                    ? submodule.name.concat("Expanded")
                                    : submodule.name.concat("Collapsed")
                            }
                            collapsed={
                                !this.state.submodulesExpanded[index][
                                    submoduleIndex
                                ]
                            }
                            config={this.props.dashboardConfig}
                            addToCanvas={this.addToCanvas}
                            canvasTreeStore={this.props.canvasTreeStore}
                            onNewFinding={(finding, image = null) => {
                                //This called when we click save in final
                                //screen of exploration path
                                if (!this.addToCanvas) {
                                    //If we are using exploration page directly,
                                    //we save or update an insight
                                    this.props.onNewFinding(finding, image);
                                } else {
                                    //else we save it in dashboard component on a slide
                                    //with identifier that stored in this.props.dashboardIndex

                                    finding.config = finding.config ?? null;
                                    finding.content = finding.content ?? null;
                                    let oldFinding = this.props.canvasTreeStore?.dashboardsState.get(
                                        this.props.dashboardIndex
                                    )?.finding;
                                    if (oldFinding != null) {
                                        if (
                                            finding.config != null &&
                                            oldFinding.config != null
                                        ) {
                                            for (let key in oldFinding.config) {
                                                if (!(key in finding.config))
                                                    finding.config[key] = null;
                                            }
                                        }
                                        if (
                                            finding.content != null &&
                                            oldFinding.content != null
                                        ) {
                                            for (let key in oldFinding.content) {
                                                if (!(key in finding.content))
                                                    finding.content[key] = null;
                                            }
                                        }
                                    }
                                    if (image == null) {
                                        this.props.canvasTreeStore?.updateDashboardAction(
                                            this.props.dashboardIndex,
                                            {
                                                finding: finding,
                                            }
                                        );
                                    } else {
                                        let reader = new FileReader();
                                        reader.readAsDataURL(image);
                                        reader.onload = function () {
                                            let base64 = reader.result;
                                            let finding = {
                                                type: "image",
                                                content: {
                                                    imageSrc: base64,
                                                },
                                            };
                                            this.props.canvasTreeStore?.updateDashboardAction(
                                                this.props.dashboardIndex,
                                                {
                                                    finding: finding,
                                                }
                                            );
                                        }.bind(this);
                                    }
                                    this.props.onReturnToCanvas();
                                }
                            }}
                            onClose={() => {
                                this.setState((state) => {
                                    let submodulesExpanded = Object.assign(
                                        {},
                                        state.submodulesExpanded
                                    );
                                    let submoduleExpanded = Array.from(
                                        submodulesExpanded[index]
                                    );
                                    submoduleExpanded[submoduleIndex] = false;
                                    submodulesExpanded[
                                        index
                                    ] = submoduleExpanded;
                                    return {
                                        submodulesExpanded: submodulesExpanded,
                                    };
                                });
                            }}
                        />
                    </>
                );
            }
        }
        if (items.length === 0) {
            return null;
        } else {
            return (
                <div
                    className="journey-column flex-simple-column"
                    style={{
                        display: this.state.sections[index] ? "block" : "none",
                    }}
                >
                    {items}
                </div>
            );
        }
    }

    renderContents(globalContext) {
        if (!this.mounted) {
            this.performance = new Date();
            this.mounted = true;
        }
        let style = this.props.onReturnToCanvas
            ? {
                  backgroundColor: lightThemeStyle.backgroundColor,
                  height: "100%",
              }
            : { backgroundColor: lightThemeStyle.backgroundColor };

        let items = [];
        let moduleName;
        let subItems;
        for (let index in this.state.moduleNames) {
            index = Number(index);
            moduleName = this.state.moduleNames[index];
            subItems = this.buildModuleColumn(index, globalContext);
            if (
                (!("require_permission" in modules[index]) ||
                    modules[index].require_permission in
                        globalContext.permissions) &&
                this.state.modulesMap[index].length !== 0 &&
                subItems != null
            ) {
                items.push(
                    <>
                        <CollapseExtendButton
                            caption={moduleName}
                            expanded={this.state.sections[index]}
                            onClick={() => {
                                this.performance = new Date();
                                this.setState((state) => {
                                    let sections = Object.assign(
                                        {},
                                        state.sections
                                    );
                                    Object.keys(sections)
                                        .filter(
                                            (key) =>
                                                String(key) !== String(index)
                                        )
                                        .forEach((key) => {
                                            sections[key] = false;
                                        });
                                    sections[index] = !sections[index];
                                    return {
                                        sections: sections,
                                    };
                                });
                            }}
                        />
                        <CSSTransition
                            timeout={500}
                            in={this.state.sections[index]}
                            classNames={"journey-column"}
                        >
                            {subItems}
                        </CSSTransition>
                    </>
                );
            }
        }

        return (
            <ScaledPage>
                <div className="content-wrapper hide-scroll" style={style}>
                    <section
                        className="content"
                        style={this.props.positionStyle}
                    >
                        <div>{items}</div>
                        <div style={{ height: 500 }}> </div>
                    </section>
                </div>
            </ScaledPage>
        );
    }

    render() {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

export { MainComponent };
export let route = "/data_exploration.html",
    section = "Exploration",
    leftPanelItem = {
        section: "Exploration",
        href: "data_exploration.html",
        icon: <ExploreIcon className={styles.icon} />,
    },
    requirePermission = "Exploration";
