import React from "react";
import Split from "react-split";
import Draggable from "react-draggable"; // required for dashboard options
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { Resizable } from "re-resizable";
import { observer } from "mobx-react";
import Select, { createFilter } from "react-select";

import FindingItem from "common/insights_components/FindingItem";
import { isValidConfig } from "common/PathConfigVersion";
import { DashboardDropdown } from "./DashboardDropdown";
import elements from "common/CanvasElements";
import { mainStyle } from "common/MainStyle";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import { goToInternalLink } from "common/InternalLinksHelper";
import CanvasPreventPropagationButton from "../CanvasPreventPropagationButton";
import { PortalType } from "../SectionPortal";
import Tables from "common/Tables";
import DataScopesForModules from "common/DataScopesForModules";
import customSelectStyles from "common/SelectStyles";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import {
    changeElementWhenResize2,
    getNewSizeAfterResize2,
} from "../BaseCanvasResizableFunctions";
import DashboardUpdater from "../DashboardUpdater";
import remoteModuleId from "common/remoteModuleId";
import castToType from "common/utilities/castToType";
import { insertRows, deleteTable } from "common/DataApi";
import DataSyncArrow from "common/DataSyncArrow";
import { BackgroundMode } from "common/CanvasUserApi";
import _ from "lodash";
import { snapElementToPoints } from "../Snap";

const typeName = "dashboardsState";

function DashboardInnerItem(props) {
    let dashboardInnerItem = (
        <div
            style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                paddingBottom: "10px",
            }}
        >
            <FindingItem
                onNewFinding={(finding) => {
                    props.canvasTreeStore.updateDashboardAction(props.index, {
                        finding: finding,
                    });
                }}
                finding={props.dashboard.finding}
            />
        </div>
    );
    if (props.hasDynamicOptions) {
        let relativeCardSize =
            props.dashboard.relativeCardSize != null
                ? props.dashboard.relativeCardSize
                : 50;
        return (
            <Split
                sizes={[relativeCardSize, 100 - relativeCardSize]}
                minSize={0}
                expandToMin={false}
                gutterSize={5 * props.scale}
                gutter={(index, direction) => {
                    const gutter = document.createElement("div");
                    gutter.className = `gutter gutter-${direction}`;
                    gutter.style = `background-color: var(--dashboard-separator-background-color);`;
                    gutter.onmousedown = (evt) => {
                        evt.stopPropagation();
                    };
                    return gutter;
                }}
                style={{ width: "100%" }}
                gutterAlign="center"
                snapOffset={20 * props.scale}
                dragInterval={1}
                direction="vertical"
                cursor="row-resize"
                onDragStart={(evt) => {}}
                onDragEnd={(evt) => {
                    props.onNewPerformance(elements.dashboard);
                    props.canvasTreeStore.updateDashboardAction(props.index, {
                        relativeCardSize: evt[0],
                    });
                }}
            >
                {dashboardInnerItem}
                <div />
            </Split>
        );
    }
    return dashboardInnerItem;
}

export default observer(
    class Dashboards extends CanvasInteractionComponent {
        constructor(props) {
            super(props);
            this.drag = false;
            this.state = {
                updatingDashboardIndices: [],
                hovered: false,
                pushingData: false,
            };
        }
        updateDashboardFromBackend(finding, version, index) {
            let updatingDashboardIndices = Array.from(
                this.state.updatingDashboardIndices
            );
            updatingDashboardIndices.push(index);
            this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                index,
                null
            );
            this.setState(
                (state) => ({
                    updatingDashboardIndices: updatingDashboardIndices,
                }),
                async () => {
                    let oldFinding = finding;
                    try {
                        finding = await DashboardUpdater.updateDashboardFinding(
                            oldFinding,
                            version,
                            this.props.currentModuleId ?? remoteModuleId,
                            true
                        );
                        this.props.canvasTreeStore.updateDashboardAction(
                            index,
                            {
                                finding: finding,
                            }
                        );
                        let updatingDashboardIndices = Array.from(
                            this.state.updatingDashboardIndices
                        ).filter((item) => item !== index);
                        this.setState({
                            updatingDashboardIndices: updatingDashboardIndices,
                        });
                    } catch (error) {
                        console.log(error);
                        let updatingDashboardIndices = Array.from(
                            this.state.updatingDashboardIndices
                        ).filter((item) => item !== index);
                        this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                            index,
                            "Error: This table has a different format from the current table."
                        );

                        this.setState((state) => ({
                            updatingDashboardIndices: updatingDashboardIndices,
                        }));
                    }
                }
            );
        }

        uploadDataToBackend(finding, version, index) {
            if (
                finding.type === "20" &&
                finding.content &&
                finding.content.data
            ) {
                this.setState({ pushingData: true });
                const metadata = finding.content.data.metadata;
                let data = [];
                for (let lane of finding.content.data.lanes) {
                    for (let card of lane.cards) {
                        let row = {
                            [finding.config.categoryVariable]: castToType(
                                lane.title,
                                metadata.laneTitleType,
                                metadata.laneTitleFormat
                            ),
                            [finding.config.titleVariable]: castToType(
                                card.title,
                                metadata.titleType,
                                metadata.titleFormat
                            ),
                        };
                        for (let [name, value] of Object.entries(
                            card.variables
                        )) {
                            row[name] = castToType(
                                value,
                                metadata.variableTypes[name],
                                metadata.variableFormats[name]
                            );
                        }
                        data.push(row);
                    }
                }
                let tableOption = {
                    label: "",
                    value: finding.config.selectedTable.value,
                    data_table_idx: finding.config.dataScope?.value,
                    optimized: false,
                    condition_id: finding.config.selectedTable.condition_id,
                };
                deleteTable(
                    tableOption,
                    this.props.currentModuleId ?? remoteModuleId
                )
                    .then(() => {
                        insertRows(
                            tableOption,
                            data,
                            this.props.currentModuleId ?? remoteModuleId
                        )
                            .then(() => {
                                this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                                    index,
                                    null
                                );

                                this.setState((state) => ({
                                    pushingData: false,
                                }));
                            })
                            .catch((error) => {
                                this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                                    index,
                                    `Error: ${String(error)}`
                                );

                                this.setState((state) => ({
                                    pushingData: false,
                                }));
                            });
                    })
                    .catch((error) => {
                        this.props.canvasTreeStore.canvasDashboardErrorsState.set(
                            index,
                            `Error: ${error}`
                        );

                        this.setState((state) => ({
                            pushingData: false,
                        }));
                    });
            }
        }

        openExplorationPage(index, config) {
            this.props.onOpenBottomPortal(PortalType.Exploration, {
                dashboardIndex: index,
                dashboardConfig: config ? _.cloneDeep(config) : undefined,
                currentModuleId: this.props.currentModuleId,
            });
        }
        render() {
            const { canvasViewMode } = this.props.canvasTreeStore;
            let dashboards = [];
            for (let index of this.props.canvasTreeStore.dashboardsState.keys()) {
                let dashboard = this.props.canvasTreeStore.dashboardsState.get(
                    index
                );
                let rootDataTestId = `dashboardV1-${dashboards.length + 1}`;
                if (!dashboard) continue;
                if (dashboard.version != null) continue;
                let dashboardUnscaledRect = {
                    nodePosition: {
                        ...dashboard.nodePosition,
                        [canvasViewMode]: {
                            x:
                                dashboard.nodePosition[canvasViewMode].x !=
                                    null &&
                                !isNaN(dashboard.nodePosition[canvasViewMode].x)
                                    ? dashboard.nodePosition[canvasViewMode].x
                                    : 0,
                            y:
                                dashboard.nodePosition[canvasViewMode].y !=
                                    null &&
                                !isNaN(dashboard.nodePosition[canvasViewMode].y)
                                    ? dashboard.nodePosition[canvasViewMode].y
                                    : 0,
                        },
                    },
                    nodeSize: {
                        ...dashboard.nodeSize,
                        [canvasViewMode]: {
                            height:
                                dashboard.nodeSize[canvasViewMode].height !=
                                    null &&
                                !isNaN(
                                    dashboard.nodeSize[canvasViewMode].height
                                )
                                    ? dashboard.nodeSize[canvasViewMode].height
                                    : 500,
                            width:
                                dashboard.nodeSize[canvasViewMode].width !=
                                    null &&
                                !isNaN(dashboard.nodeSize[canvasViewMode].width)
                                    ? dashboard.nodeSize[canvasViewMode].width
                                    : 500,
                        },
                    },
                };
                let dashboardSize = {
                    height:
                        dashboardUnscaledRect.nodeSize[canvasViewMode].height *
                        this.props.scale,
                    width:
                        dashboardUnscaledRect.nodeSize[canvasViewMode].width *
                        this.props.scale,
                };
                let uploading = this.state.updatingDashboardIndices.length > 0;
                let hasDynamicOptions =
                    // this.props.sharedPolicy === CanvasSharedPolicy.NotShared &&
                    dashboard.finding &&
                    isValidConfig(dashboard.finding.config) &&
                    dashboard.finding.config.dynamicOptions &&
                    Object.keys(dashboard.finding.config.dynamicOptions)
                        .length > 0 &&
                    Object.values(
                        dashboard.finding.config.dynamicOptions
                    ).filter((item) => item != null).length > 0;
                let position = {
                    x:
                        dashboardUnscaledRect.nodePosition[canvasViewMode].x *
                        this.props.scale,
                    y:
                        dashboardUnscaledRect.nodePosition[canvasViewMode].y *
                        this.props.scale,
                };
                let colorOptions = dashboard.colorOptions ?? {
                    borderShadow: false,
                    fillColor: mainStyle
                        .getPropertyValue(
                            "--slide-data-processing-content-color"
                        )
                        .trim(),
                    borderColor: "",
                };
                let refreshButtonStyles = {};
                if (this.props.live) {
                    refreshButtonStyles = {
                        transition: "opacity .2s ease-out",
                        opacity: this.state.hovered ? (uploading ? 0.3 : 1) : 0,
                        pointerEvents: this.state.hovered ? "auto" : "none",
                    };
                }

                let dashboardUi = (
                    <DraggableWithSnapping
                        cancel="input, textarea, .cancel-drag"
                        key={index}
                        disabled={this.props.live || !this.props.canWrite}
                        position={position}
                        onDrag={(_evt, _data) => {
                            this.drag = true;
                            let nearestPoints = this.props.onRebuildSnapLine(
                                {
                                    x: _data.x,
                                    y: _data.y,
                                    width: dashboardSize.width,
                                    height: dashboardSize.height,
                                },
                                {
                                    type: typeName,
                                    id: index,
                                    groupId: dashboard.groupId,
                                }
                            );
                            this.props.onUpdateSelectionBounds?.();
                            let newPosition = snapElementToPoints(
                                dashboardSize.width,
                                dashboardSize.height,
                                nearestPoints
                            );
                            if (
                                newPosition.x != null ||
                                newPosition.y != null
                            ) {
                                // Snap to this position
                                return newPosition;
                            }
                        }}
                        onStop={(evt, data) => {
                            if (this.drag) {
                                this.props.onDeleteSnapLine();
                                this.trackNewPerformance(elements.dashboard);
                                let x = Math.max(data.x / this.props.scale, 0);
                                let y = Math.max(data.y / this.props.scale, 0);
                                let deltaX =
                                    x -
                                    dashboardUnscaledRect.nodePosition[
                                        canvasViewMode
                                    ].x;
                                let deltaY =
                                    y -
                                    dashboardUnscaledRect.nodePosition[
                                        canvasViewMode
                                    ].y;
                                const nodePosition = {
                                    ...dashboardUnscaledRect.nodePosition,
                                    [canvasViewMode]: {
                                        x,
                                        y,
                                    },
                                };

                                let changes = {};
                                this.props.canvasTreeStore.updateDashboardAction(
                                    index,
                                    { nodePosition },
                                    false,
                                    changes
                                );
                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        x: x,
                                        y: y,
                                        width:
                                            dashboardUnscaledRect.nodeSize[
                                                canvasViewMode
                                            ].width,
                                        height:
                                            dashboardUnscaledRect.nodeSize[
                                                canvasViewMode
                                            ].height,
                                    }
                                );
                                this.props.onMoveGroupSelection(
                                    deltaX,
                                    deltaY,
                                    {
                                        id: index,
                                        type: typeName,
                                        groupId: dashboard.groupId,
                                    },
                                    false,
                                    changes
                                );
                                this.props.canvasTreeStore.saveChangesAction(
                                    changes,
                                    true,
                                    true,
                                    false,
                                    this.props.canvasTreeStore.backgroundsState.toJSON(),
                                    BackgroundMode.Update,
                                    false
                                );
                                this.drag = false;
                            }
                        }}
                    >
                        <div
                            onContextMenu={(evt) => {
                                this.props.onContextMenu(
                                    evt,
                                    {
                                        id: index,
                                        type: typeName,
                                    },
                                    true
                                );
                            }}
                            style={{
                                top: 0,
                                left: 0,
                                position: "absolute",
                                zIndex: dashboard.zIndex ?? 50,
                            }}
                            onMouseEnter={() => {
                                this.setState({ hovered: true });
                            }}
                            onMouseLeave={() => {
                                this.setState({ hovered: false });
                            }}
                        >
                            <Resizable
                                className="selectable-by-pointer"
                                ref={(ref) => {
                                    let innerRef = ref?.resizable;
                                    if (innerRef != null) {
                                        innerRef.setAttribute("type", typeName);
                                        if (dashboard.groupId != null)
                                            innerRef.setAttribute(
                                                "groupId",
                                                dashboard.groupId
                                            );
                                        else {
                                            innerRef.removeAttribute("groupId");
                                        }
                                        innerRef.setAttribute("id", index);
                                        innerRef.setAttribute(
                                            "data-test-id",
                                            rootDataTestId
                                        );
                                    }
                                }}
                                enable={
                                    this.props.live || !this.props.canWrite
                                        ? {
                                              top: false,
                                              right: false,
                                              bottom: false,
                                              left: false,
                                              topRight: false,
                                              bottomRight: false,
                                              bottomLeft: false,
                                              topLeft: false,
                                          }
                                        : {
                                              top: true,
                                              right: true,
                                              bottom: true,
                                              left: true,
                                              topRight: true,
                                              bottomRight: true,
                                              bottomLeft: true,
                                              topLeft: true,
                                          }
                                }
                                onResizeStart={(evt) => {
                                    evt.stopPropagation();
                                }}
                                onResize={(_e, _direction, _ref, d) => {
                                    changeElementWhenResize2(
                                        {
                                            position:
                                                dashboardUnscaledRect.nodePosition,
                                            size:
                                                dashboardUnscaledRect.nodeSize,
                                        },
                                        this.props.scale,
                                        _direction,
                                        d,
                                        _ref,
                                        canvasViewMode
                                    );
                                    this.props.onUpdateSelectionBounds?.();
                                }}
                                onResizeStop={(e, direction, ref, d) => {
                                    this.trackNewPerformance(
                                        elements.dashboard
                                    );
                                    let newSize = getNewSizeAfterResize2(
                                        {
                                            position:
                                                dashboardUnscaledRect.nodePosition,
                                            size:
                                                dashboardUnscaledRect.nodeSize,
                                        },
                                        this.props.scale,
                                        direction,
                                        d,
                                        canvasViewMode
                                    );
                                    let updatingProps = newSize;
                                    if (
                                        dashboard.finding &&
                                        isValidConfig(dashboard.finding.config)
                                    ) {
                                        let newConfig = Object.assign(
                                            {},
                                            dashboard.finding.config
                                        );
                                        if (newConfig.dynamicOptions) {
                                            Object.keys(
                                                newConfig.dynamicOptions
                                            ).forEach((key) => {
                                                let position =
                                                    newConfig.dynamicOptions[
                                                        key
                                                    ].position;
                                                if (position != null)
                                                    newConfig.dynamicOptions[
                                                        key
                                                    ].position = {
                                                        x: Math.max(
                                                            0,
                                                            Math.min(
                                                                position.x,
                                                                newSize
                                                                    .nodeSize[
                                                                    canvasViewMode
                                                                ].width - 250
                                                            )
                                                        ),
                                                        y: Math.max(
                                                            0,
                                                            Math.min(
                                                                position.y,
                                                                newSize
                                                                    .nodeSize[
                                                                    canvasViewMode
                                                                ].height - 50
                                                            )
                                                        ),
                                                    };
                                            });
                                        }
                                        updatingProps = {
                                            ...updatingProps,
                                            finding: {
                                                ...dashboard.finding,
                                                newConfig,
                                            },
                                        };
                                    }
                                    this.props.canvasTreeStore.updateDashboardAction(
                                        index,
                                        updatingProps
                                    );
                                    this.props.canvasTreeStore.updateCanvasSizeAction(
                                        newSize
                                    );
                                    this.props.onResize();
                                }}
                                size={dashboardSize}
                            >
                                <div
                                    className="dashboard-rect-canvas"
                                    style={{
                                        boxShadow: colorOptions.borderShadow
                                            ? //? "2px 4px 12px 0 rgba(21, 33, 56, 0.40)"
                                              mainStyle.getPropertyValue(
                                                  "--card-box-shadow"
                                              ) // Modified shadow behavior so I can use a tag in Constants.css & DefaultConstants.css?
                                            : "none",
                                        backgroundColor: colorOptions.fillColor,
                                        border: colorOptions.borderColor
                                            ? `2px solid ${colorOptions.borderColor}`
                                            : "none",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    {hasDynamicOptions &&
                                        Object.values(
                                            dashboard.finding.config
                                                .dynamicOptions
                                        )
                                            .filter((item) => item != null)
                                            .map((item, innerIndex) => {
                                                if (item.position != null)
                                                    return item;
                                                else
                                                    return {
                                                        ...item,
                                                        position: {
                                                            x: 50,
                                                            y: Math.min(
                                                                (dashboardSize.height /
                                                                    this.props
                                                                        .scale) *
                                                                    0.5 +
                                                                    // 10 = margin for the scroll bar
                                                                    10 +
                                                                    innerIndex *
                                                                        50,
                                                                dashboardSize.height /
                                                                    this.props
                                                                        .scale -
                                                                    50
                                                            ),
                                                        },
                                                    };
                                            })
                                            .sort((a, b) => {
                                                return (
                                                    b.position.y - a.position.y
                                                );
                                            })
                                            .map(
                                                (
                                                    dynamicOption,
                                                    innerIndex,
                                                    arr
                                                ) => {
                                                    let position =
                                                        dynamicOption.position;
                                                    return (
                                                        <Draggable
                                                            disabled={
                                                                this.props
                                                                    .live ||
                                                                !this.props
                                                                    .canWrite
                                                            }
                                                            position={{
                                                                x:
                                                                    position.x *
                                                                    this.props
                                                                        .scale,
                                                                y:
                                                                    position.y *
                                                                    this.props
                                                                        .scale,
                                                            }}
                                                            onStart={(
                                                                evt,
                                                                data
                                                            ) => {
                                                                evt.stopPropagation();
                                                            }}
                                                            onStop={(
                                                                evt,
                                                                data
                                                            ) => {
                                                                this.trackNewPerformance(
                                                                    elements.dashboard
                                                                );
                                                                evt.stopPropagation();
                                                                evt.preventDefault();
                                                                let newConfig = Object.assign(
                                                                    {},
                                                                    dashboard
                                                                        .finding
                                                                        .config
                                                                );
                                                                newConfig.dynamicOptions[
                                                                    dynamicOption.name
                                                                ].position = {
                                                                    x: Math.max(
                                                                        0,
                                                                        Math.min(
                                                                            data.x /
                                                                                this
                                                                                    .props
                                                                                    .scale,
                                                                            dashboardUnscaledRect
                                                                                .nodeSize[
                                                                                canvasViewMode
                                                                            ]
                                                                                .width -
                                                                                250
                                                                        )
                                                                    ),
                                                                    y: Math.max(
                                                                        0,
                                                                        Math.min(
                                                                            data.y /
                                                                                this
                                                                                    .props
                                                                                    .scale,
                                                                            dashboardUnscaledRect
                                                                                .nodeSize[
                                                                                canvasViewMode
                                                                            ]
                                                                                .height -
                                                                                50
                                                                        )
                                                                    ),
                                                                };
                                                                this.props.canvasTreeStore.updateDashboardAction(
                                                                    index,
                                                                    {
                                                                        finding: {
                                                                            ...dashboard.finding,
                                                                            newConfig,
                                                                        },
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    top: 0,
                                                                    left: 0,
                                                                    position:
                                                                        "absolute",
                                                                    zIndex: 60,
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        transformOrigin:
                                                                            "left top",
                                                                        transform: `scale(${
                                                                            this
                                                                                .props
                                                                                .scale *
                                                                            0.7
                                                                        })`,
                                                                    }}
                                                                >
                                                                    <DashboardDropdown
                                                                        config={
                                                                            dashboard
                                                                                .finding
                                                                                .config
                                                                        }
                                                                        dynamicOption={
                                                                            dynamicOption
                                                                        }
                                                                        canvasTreeStore={
                                                                            this
                                                                                .props
                                                                                .canvasTreeStore
                                                                        }
                                                                        onChange={(
                                                                            config
                                                                        ) => {
                                                                            this.trackNewPerformance(
                                                                                elements.dashboard
                                                                            );

                                                                            this.props.canvasTreeStore.updateDashboardAction(
                                                                                index,
                                                                                {
                                                                                    finding: {
                                                                                        ...dashboard.finding,
                                                                                        config,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                        currentModuleId={
                                                                            this
                                                                                .props
                                                                                .currentModuleId
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Draggable>
                                                    );
                                                }
                                            )}
                                    <div
                                        className="flex-simple-column"
                                        style={{ height: "100%" }}
                                    >
                                        <div
                                            className="my-row"
                                            style={{
                                                alignItems: "center",
                                                width: "100%",
                                                justifyContent: "flex-end",
                                                marginTop:
                                                    10 * this.props.scale,
                                            }}
                                        >
                                            {dashboard.finding &&
                                                !this.props.live &&
                                                this.props.canWrite && (
                                                    <div
                                                        title={"Edit"}
                                                        style={{
                                                            width:
                                                                24 *
                                                                0.7 *
                                                                this.props
                                                                    .scale,
                                                            height:
                                                                24 *
                                                                0.7 *
                                                                this.props
                                                                    .scale,

                                                            marginRight:
                                                                10 *
                                                                this.props
                                                                    .scale,
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            let config =
                                                                dashboard
                                                                    ?.finding
                                                                    ?.config;
                                                            this.openExplorationPage(
                                                                index,
                                                                config
                                                            );
                                                        }}
                                                    >
                                                        <svg
                                                            style={{
                                                                transformOrigin:
                                                                    "left top",
                                                                transform: `scale(${
                                                                    0.7 *
                                                                    this.props
                                                                        .scale
                                                                })`,
                                                                fill: mainStyle.getPropertyValue(
                                                                    "--secondary-content-color"
                                                                ),
                                                            }}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z" />
                                                        </svg>
                                                    </div>
                                                )}
                                            {dashboard.finding &&
                                                isValidConfig(
                                                    dashboard.finding.config
                                                ) && (
                                                    <CanvasPreventPropagationButton>
                                                        <div
                                                            style={{
                                                                width:
                                                                    24 *
                                                                    0.7 *
                                                                    this.props
                                                                        .scale,
                                                                height:
                                                                    24 *
                                                                    0.7 *
                                                                    this.props
                                                                        .scale,
                                                                marginRight:
                                                                    10 *
                                                                    this.props
                                                                        .scale,
                                                                opacity: uploading
                                                                    ? 0.3
                                                                    : 1,
                                                                ...refreshButtonStyles,
                                                            }}
                                                            title={
                                                                dashboard
                                                                    .finding
                                                                    .type ===
                                                                "20"
                                                                    ? "Download and replace data"
                                                                    : "Update dashboard"
                                                            }
                                                            onClick={(evt) => {
                                                                evt.stopPropagation();
                                                                if (
                                                                    this.props
                                                                        .shared
                                                                ) {
                                                                    goToInternalLink(
                                                                        "/"
                                                                    );
                                                                    return;
                                                                }
                                                                if (uploading)
                                                                    return;
                                                                this.updateDashboardFromBackend(
                                                                    dashboard.finding,
                                                                    dashboard.version,
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            <DataSyncArrow
                                                                pull
                                                                active={
                                                                    uploading
                                                                }
                                                            />
                                                        </div>
                                                    </CanvasPreventPropagationButton>
                                                )}
                                            {dashboard.finding &&
                                                isValidConfig(
                                                    dashboard.finding.config
                                                ) &&
                                                dashboard.finding.content !=
                                                    null &&
                                                dashboard.finding.content
                                                    .data != null &&
                                                dashboard.finding.type ===
                                                    "20" && (
                                                    <CanvasPreventPropagationButton>
                                                        <div
                                                            style={{
                                                                width:
                                                                    24 *
                                                                    0.7 *
                                                                    this.props
                                                                        .scale,
                                                                height:
                                                                    24 *
                                                                    0.7 *
                                                                    this.props
                                                                        .scale,
                                                                marginRight:
                                                                    10 *
                                                                    this.props
                                                                        .scale,
                                                                opacity:
                                                                    uploading ||
                                                                    !(
                                                                        "metadata" in
                                                                        dashboard
                                                                            .finding
                                                                            .content
                                                                            .data
                                                                    )
                                                                        ? 0.3
                                                                        : 1,
                                                                ...refreshButtonStyles,
                                                            }}
                                                            title={
                                                                "metadata" in
                                                                dashboard
                                                                    .finding
                                                                    .content
                                                                    .data
                                                                    ? "Upload data"
                                                                    : "Uploading not supported. Please refresh the data."
                                                            }
                                                            onClick={(evt) => {
                                                                evt.stopPropagation();
                                                                if (
                                                                    this.props
                                                                        .shared
                                                                ) {
                                                                    goToInternalLink(
                                                                        "/"
                                                                    );
                                                                    return;
                                                                }
                                                                if (
                                                                    !(
                                                                        "metadata" in
                                                                        dashboard
                                                                            .finding
                                                                            .content
                                                                            .data
                                                                    ) ||
                                                                    uploading
                                                                )
                                                                    return;
                                                                this.uploadDataToBackend(
                                                                    dashboard.finding,
                                                                    dashboard.version,
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            <DataSyncArrow
                                                                push
                                                                active={
                                                                    this.state
                                                                        .pushingData
                                                                }
                                                            />
                                                        </div>
                                                    </CanvasPreventPropagationButton>
                                                )}
                                            {dashboard.finding &&
                                                isValidConfig(
                                                    dashboard.finding.config
                                                ) &&
                                                !this.props.live && (
                                                    <CanvasPreventPropagationButton>
                                                        <div
                                                            style={{
                                                                height:
                                                                    38 *
                                                                    0.7 *
                                                                    this.props
                                                                        .scale,
                                                                width:
                                                                    38 *
                                                                    0.7 *
                                                                    this.props
                                                                        .scale,
                                                                marginRight:
                                                                    10 *
                                                                    this.props
                                                                        .scale,
                                                            }}
                                                        >
                                                            <Select
                                                                isDisabled={
                                                                    uploading
                                                                }
                                                                filterOption={createFilter(
                                                                    {
                                                                        ignoreAccents: false,
                                                                    }
                                                                )}
                                                                placeholder={""}
                                                                styles={{
                                                                    ...customSelectStyles,
                                                                    container: (
                                                                        base
                                                                    ) => ({
                                                                        ...base,
                                                                        width:
                                                                            "15em",
                                                                    }),
                                                                    input: (
                                                                        base
                                                                    ) => ({
                                                                        ...base,
                                                                        display:
                                                                            "none",
                                                                    }),
                                                                    singleValue: (
                                                                        base
                                                                    ) => ({
                                                                        ...base,
                                                                        display:
                                                                            "none",
                                                                    }),
                                                                    control: (
                                                                        base
                                                                    ) => ({
                                                                        ...base,
                                                                        ...customSelectStyles.control(
                                                                            base
                                                                        ),
                                                                        width: 38,
                                                                        height: 38,
                                                                        transform: `scale(${
                                                                            0.7 *
                                                                            this
                                                                                .props
                                                                                .scale
                                                                        })`,
                                                                        transformOrigin:
                                                                            "left top",
                                                                    }),
                                                                }}
                                                                options={
                                                                    DataScopesForModules(
                                                                        this
                                                                            .props
                                                                            .currentModuleId ??
                                                                            remoteModuleId
                                                                    )
                                                                        .dataScopesOptions
                                                                }
                                                                onChange={(
                                                                    newValue
                                                                ) => {
                                                                    let config = {
                                                                        ...dashboard
                                                                            .finding
                                                                            .config,
                                                                    };
                                                                    config.dataScope = newValue;
                                                                    config.selectedTable = Tables(
                                                                        newValue.value,
                                                                        this
                                                                            .props
                                                                            .currentModuleId ??
                                                                            remoteModuleId
                                                                    ).tableToOption();

                                                                    this.trackNewPerformance(
                                                                        elements.dashboard
                                                                    );
                                                                    let finding = {
                                                                        ...dashboard.finding,
                                                                        config,
                                                                    };
                                                                    this.updateDashboardFromBackend(
                                                                        finding,
                                                                        dashboard.version,
                                                                        index
                                                                    );
                                                                }}
                                                                value={
                                                                    dashboard
                                                                        .finding
                                                                        .config
                                                                        .dataScope
                                                                }
                                                                theme={(
                                                                    theme
                                                                ) => ({
                                                                    ...theme,
                                                                    borderRadius: 0,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        text:
                                                                            "white",
                                                                        primary25:
                                                                            "var(--selectors-background-hover-color)",
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </CanvasPreventPropagationButton>
                                                )}
                                            {this.props.canvasTreeStore.canvasDashboardErrorsState.get(
                                                index
                                            ) && (
                                                <img
                                                    title={this.props.canvasTreeStore.canvasDashboardErrorsState.get(
                                                        index
                                                    )}
                                                    src="/dist/img/error.png"
                                                    alt=""
                                                    style={{
                                                        marginRight:
                                                            10 *
                                                            this.props.scale,
                                                        transform: `scale(${
                                                            0.7 *
                                                            this.props.scale
                                                        })`,
                                                        transformOrigin:
                                                            "left top",
                                                    }}
                                                />
                                            )}
                                            {!this.props.live &&
                                                this.props.canWrite && (
                                                    <CanvasPreventPropagationButton>
                                                        <div
                                                            title={"Delete"}
                                                            style={{
                                                                width:
                                                                    24 *
                                                                    0.7 *
                                                                    this.props
                                                                        .scale,
                                                                height:
                                                                    24 *
                                                                    0.7 *
                                                                    this.props
                                                                        .scale,
                                                                marginRight:
                                                                    10 *
                                                                    this.props
                                                                        .scale,

                                                                opacity: uploading
                                                                    ? 0.3
                                                                    : 1,
                                                            }}
                                                            onClick={(evt) => {
                                                                this.trackNewPerformance(
                                                                    elements.dashboard
                                                                );
                                                                evt.stopPropagation();
                                                                if (uploading)
                                                                    return;
                                                                this.props.showDeletePopup(
                                                                    () => {
                                                                        this.props.onClearEditing();
                                                                        this.props.canvasTreeStore.deleteDashboardAction(
                                                                            index
                                                                        );
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                src="/dist/img/insights/insights_remove.png"
                                                                style={{
                                                                    cursor:
                                                                        "pointer",
                                                                    transform: `scale(${
                                                                        0.7 *
                                                                        this
                                                                            .props
                                                                            .scale
                                                                    })`,
                                                                    transformOrigin:
                                                                        "left top",
                                                                }}
                                                            />
                                                        </div>
                                                    </CanvasPreventPropagationButton>
                                                )}
                                        </div>
                                        {dashboard.finding ? (
                                            <div
                                                className="element"
                                                style={{
                                                    padding:
                                                        15 * this.props.scale,
                                                    overflow: "auto",
                                                    justifyContent:
                                                        "flex-start",
                                                    display: "flex",
                                                    height: "100%",
                                                    width: "100%",
                                                }}
                                            >
                                                {this.state.updatingDashboardIndices.includes(
                                                    index
                                                ) ||
                                                dashboard.finding.content ==
                                                    null ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <span className="big-title-span">
                                                            {"LOADING"}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <DashboardInnerItem
                                                        canvasTreeStore={
                                                            this.props
                                                                .canvasTreeStore
                                                        }
                                                        dashboard={dashboard}
                                                        scale={this.props.scale}
                                                        onNewPerformance={this.trackNewPerformance.bind(
                                                            this
                                                        )}
                                                        index={index}
                                                        hasDynamicOptions={
                                                            hasDynamicOptions
                                                        }
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            !this.props.live &&
                                            this.props.canWrite && (
                                                <>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            height: "100%",
                                                            width: "100%",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <span
                                                            title={"Edit"}
                                                            style={{
                                                                transformOrigin:
                                                                    "center top",
                                                                transform: `scale(${
                                                                    0.7 *
                                                                    this.props
                                                                        .scale
                                                                })`,
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                            onClick={() => {
                                                                this.openExplorationPage(
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            <svg
                                                                style={{
                                                                    fill:
                                                                        dataScienceElementsStyle.secondaryTextColor,
                                                                }}
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{
                                                            height:
                                                                34 *
                                                                this.props
                                                                    .scale,
                                                        }}
                                                    />
                                                </>
                                            )
                                        )}
                                    </div>
                                </div>
                            </Resizable>
                        </div>
                    </DraggableWithSnapping>
                );

                dashboards.push(dashboardUi);
            }
            return <>{dashboards}</>;
        }
    }
);
