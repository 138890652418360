import React from "react";
import { Button } from "react-bootstrap";
import { mainStyle } from "common/MainStyle";
import currentUser from "common/CurrentUser";
import { observer } from "mobx-react";

const FinalStepBottomBar = observer(function FinalStepBottomBar(props) {
	return (
		<div
			className="my-row"
			style={{
				marginTop: props.topMargin,
				alignSelf: "flex-end",
				alignItems: "center",
			}}
		>
			<div className="flex-simple-column">
				<span
					style={{
						cursor: "pointer",
						fontFamily: "Roboto",
						fontSize: "12px",
						lineHeight: "14px",
						color: mainStyle.getPropertyValue(
							"--exploration-secondary-text-color"
						),
						fontWeight: 700,
					}}
				>
					{"press KEY [P] to save"}
				</span>
				{currentUser.isRoot && props.onShowRecipesPopup && (
					<span
						style={{
							cursor: "pointer",
							fontFamily: "Roboto",
							fontSize: "12px",
							lineHeight: "14px",
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
							fontWeight: 700,
						}}
					>
						{"press KEY [D] to save as custom path"}
					</span>
				)}
			</div>
			<Button
				type="button"
				className="btn btn-sm btn-primary my-primary"
				style={{
					marginLeft: 10,
					width: "112px",
				}}
				onClick={props.onSaveFinding}
			>
				SAVE
			</Button>
			{currentUser.isRoot && props.onShowRecipesPopup && (
				<Button
					type="button"
					className="btn btn-sm btn-primary my-primary"
					style={{
						marginLeft: 10,
					}}
					onClick={props.onShowRecipesPopup}
				>
					CUSTOM PATH
				</Button>
			)}
			{props.onSaveRegressionResults && (
				<Button
					type="button"
					className="btn btn-sm btn-primary my-primary"
					style={{
						marginLeft: 10,
					}}
					onClick={props.onSaveRegressionResults}
				>
					SAVE REGRESSION
				</Button>
			)}
		</div>
	);
});

function Subtitle(props) {
	return (
		<span
			style={{
				marginLeft: 50,
				fontFamily: "Roboto",
				fontSize: mainStyle.getPropertyValue("--path-subtitle-size"),
				color: mainStyle.getPropertyValue(
					"--exploration-tertiary-text-color"
				),
				fontWeight: 300,
				marginTop: 10,
			}}
		>
			{props.text}
		</span>
	);
}

function Header(props) {
	return (
		<span
			style={{
				marginLeft: 30,
				fontFamily: "Roboto",
				fontSize: mainStyle.getPropertyValue("--path-header-size"),
				color: mainStyle.getPropertyValue(
					"--exploration-primary-text-color"
				),
				fontWeight: 300,
			}}
		>
			{props.title}
		</span>
	);
}

function Footer(props) {
	return (
		<div style={{ display: "flex", justifyContent: "space-between" }}>
			<span
				style={{
					marginLeft: 80,
					fontFamily: "Roboto",
					fontSize: mainStyle.getPropertyValue("--path-footer-size"),
					color: mainStyle.getPropertyValue(
						"--exploration-tertiary-text-color"
					),
					fontWeight: 300,
					marginBottom: 20,
				}}
			>
				{props.title}
			</span>
			{props.showArrow && (
				<span
					style={{
						marginRight: 10,
						marginBottom: 20,
						alignSelf: "flex-end",
						cursor: "pointer",
						fontFamily: "Roboto",
						fontSize: "12px",
						lineHeight: "14px",
						color: mainStyle.getPropertyValue(
							"--exploration-secondary-text-color"
						),
						fontWeight: 700,
					}}
				>
					Press [&rarr;] to proceed to final screen with default
					choices
				</span>
			)}
		</div>
	);
}

export { Header, Footer, Subtitle, FinalStepBottomBar };