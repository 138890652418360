import React, { Component } from "react";
import elements from "common/CanvasElements";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import EmbedUrlElementView from "../EmbedUrlElementView";
import {
    EmbedUrlElement,
    ColorOptions,
    ItemMetadata,
    DefaultCreatedNodePosition,
    InnerCanvasChanges,
} from "common/Canvas";
import HtmlElementProps from "./HtmlElementProps";
import cx from "classnames";
import moveableStyles from "./Moveable.module.css";
import {
    makeMoveable,
    DraggableProps,
    ResizableProps,
    RotatableProps,
    Rotatable,
    Draggable as RMDraggable,
    Resizable as RMResizable,
    OnDrag,
} from "react-moveable";
import { getTransformList } from "common/utilities/parseTransformString";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../Snap";

const typeName = "embedUrlElementsState";
interface InnerProps extends HtmlElementProps {
    elementId: string;
    rootDataTestId: string;
    selectedMetadata?: ItemMetadata[];
}

interface State {
    showColorOptions: ColorOptions | null;
}

const Moveable = makeMoveable<DraggableProps & ResizableProps & RotatableProps>(
    [RMDraggable, RMResizable, Rotatable]
);

@observer
class EmbedUrlElementWrapper extends CanvasInteractionComponent<
    InnerProps,
    State
> {
    drag: boolean;
    private moveableRef = React.createRef<any>();
    private innerRef = React.createRef<HTMLDivElement>();
    constructor(props: InnerProps) {
        super(props);
        this.state = {
            showColorOptions: null,
        };
        this.drag = false;
    }

    componentDidMount(): void {
        let element = this.props.canvasTreeStore.embedUrlElementsState.get(
            this.props.elementId
        )!;
        let innerRef = this.innerRef.current;
        if (innerRef != null) {
            innerRef.setAttribute("type", typeName);
            if (element.groupId != null)
                innerRef.setAttribute("groupId", element.groupId);
            else {
                innerRef.removeAttribute("groupId");
            }
            innerRef.setAttribute("id", String(this.props.elementId));
            innerRef.setAttribute("data-test-id", this.props.rootDataTestId);
        }
    }

    componentDidUpdate(prev: InnerProps) {
        if (prev.selectedMetadata !== this.props.selectedMetadata) {
            let element = this.props.canvasTreeStore.embedUrlElementsState.get(
                this.props.elementId
            )!;
            let innerRef = this.innerRef.current;
            if (innerRef != null) {
                if (element.groupId != null)
                    innerRef.setAttribute("groupId", element.groupId);
                else {
                    innerRef.removeAttribute("groupId");
                }
            }
        }
        this.moveableRef.current?.updateRect();
    }

    render() {
        const {
            canvasViewMode,
            mobileViewWasEdited,
        } = this.props.canvasTreeStore;
        let element = this.props.canvasTreeStore.embedUrlElementsState.get(
            this.props.elementId
        )!;

        let selected =
            this.props.selectedMetadata?.length === 1 &&
            this.props.selectedMetadata?.[0]?.id === this.props.elementId;

        if (!element.url && !element.urls && this.props.live) return null;

        let elementSize = {
            height: element.nodeSize[canvasViewMode].height * this.props.scale,
            width: element.nodeSize[canvasViewMode].width * this.props.scale,
        };

        let elementPosition = {
            x: element.nodePosition[canvasViewMode].x * this.props.scale,
            y: element.nodePosition[canvasViewMode].y * this.props.scale,
        };

        return (
            <>
                <div
                    onContextMenu={(evt) => {
                        this.props.onContextMenu(
                            evt,
                            {
                                id: this.props.elementId,
                                type: typeName,
                            },
                            true
                        );
                    }}
                    style={{
                        position: "absolute",
                        left: elementPosition.x,
                        top: elementPosition.y,
                        width: elementSize.width,
                        height: elementSize.height,
                        zIndex: element.zIndex ?? 50,
                    }}
                    className="selectable-by-pointer"
                    ref={this.innerRef}
                >
                    <EmbedUrlElementView
                        onOpenColorOptions={(options) => {
                            this.setState({
                                showColorOptions: options,
                            });
                        }}
                        onContextMenu={(evt) => {
                            this.props.onContextMenu(
                                evt,
                                {
                                    id: this.props.elementId,
                                    type: typeName,
                                },
                                true
                            );
                        }}
                        scale={this.props.scale}
                        sharedPolicy={this.props.sharedPolicy}
                        frozen={!this.props.canWrite}
                        height={elementSize.height}
                        live={this.props.live}
                        element={element}
                        elementId={this.props.elementId}
                        onChange={(element) => {
                            this.trackNewPerformance(elements.embedUrl);
                            this.props.canvasTreeStore.updateEmbedUrlElementAction(
                                this.props.elementId,
                                element as EmbedUrlElement
                            );
                        }}
                        onTrackNewPerformance={this.trackNewPerformance.bind(
                            this
                        )}
                        onDelete={() => {
                            this.trackNewPerformance(elements.embedUrl);
                            this.props.showDeletePopup(() => {
                                this.props.onClearEditing();
                                this.props.canvasTreeStore.deleteEmbedUrlElementAction(
                                    this.props.elementId
                                );
                            });
                        }}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>

                {!this.props.live && this.props.canWrite && (
                    <Moveable
                        className={cx(
                            moveableStyles.moveable,
                            !selected && moveableStyles.moveableNotSelected
                        )}
                        checkInput={true}
                        key={this.props.elementId}
                        ref={this.moveableRef}
                        draggable={!this.props.live || this.props.canWrite}
                        throttleDrag={0}
                        preventClickEventOnDrag={true}
                        onDrag={({
                            target,
                            beforeDelta,
                            beforeDist,
                            left,
                            top,
                            right,
                            bottom,
                            delta,
                            dist,
                            transform,
                            clientX,
                            clientY,
                        }: OnDrag) => {
                            target!.style.left = `${left}px`;
                            target!.style.top = `${top}px`;
                            this.drag = true;
                            let nearestPoints = this.props.onRebuildSnapLine(
                                {
                                    x: left,
                                    y: top,
                                    width: elementSize.width,
                                    height: elementSize.height,
                                },
                                {
                                    type: typeName,
                                    id: this.props.elementId,
                                    groupId: element.groupId,
                                }
                            );
                            let newPosition = snapElementToPoints(
                                target.clientWidth,
                                target.clientHeight,
                                nearestPoints
                            );
                            if (newPosition.x != null) {
                                target!.style.left = `${newPosition.x}px`;
                            }
                            if (newPosition.y != null) {
                                target!.style.top = `${newPosition.y}px`;
                            }
                            this.props.onUpdateSelectionBounds?.();
                        }}
                        onDragEnd={({ target }) => {
                            if (this.drag) {
                                this.trackNewPerformance(elements.embedUrl);
                                this.props.onDeleteSnapLine();
                                let x =
                                    parseFloat(target.style.left) /
                                    this.props.scale;

                                let y =
                                    parseFloat(target.style.top) /
                                    this.props.scale;

                                let deltaX = x - elementPosition.x;
                                let deltaY = y - elementPosition.y;

                                const newNodePosition = {
                                    ...element.nodePosition,
                                    [canvasViewMode]: {
                                        x,
                                        y,
                                    },
                                };

                                if (!mobileViewWasEdited) {
                                    if (canvasViewMode === "desktop") {
                                        const desktopSlideWidth = this.props
                                            .canvasTreeStore.slideWidth[
                                            "desktop"
                                        ];
                                        const relativeLeftPoint =
                                            x / desktopSlideWidth;
                                        newNodePosition["mobile"] = {
                                            x:
                                                DefaultCreatedNodePosition *
                                                relativeLeftPoint,
                                            y,
                                        };
                                    } else
                                        this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                }

                                let changes: InnerCanvasChanges = {};
                                this.props.canvasTreeStore.updateEmbedUrlElementAction(
                                    this.props.elementId,
                                    {
                                        nodePosition: newNodePosition,
                                    },
                                    changes
                                );
                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        x: x,
                                        y: y,
                                        ...elementSize,
                                    }
                                );

                                this.props.onMoveGroupSelection(
                                    deltaX,
                                    deltaY,
                                    {
                                        id: this.props.elementId,
                                        type: typeName,
                                        groupId: element.groupId,
                                    },
                                    false,
                                    changes
                                );
                                this.props.canvasTreeStore.saveChangesAction(
                                    changes,
                                    true,
                                    true,
                                    false,
                                    this.props.canvasTreeStore.backgroundsState.toJSON(),
                                    BackgroundMode.Update,
                                    false
                                );
                            }
                        }}
                        target={this.innerRef}
                        resizable={
                            (!this.props.live || this.props.canWrite) &&
                            selected
                        }
                        rotatable={false}
                        onResize={(e) => {
                            e.target.style.width = `${e.width}px`;
                            e.target.style.height = `${e.height}px`;
                            e.target.style.transform = e.afterTransform;
                        }}
                        onResizeEnd={({ target }) => {
                            this.trackNewPerformance(elements.embedUrl);
                            const {
                                canvasViewMode,
                            } = this.props.canvasTreeStore;

                            let transfromOptions = getTransformList(
                                target.style.transform
                            );
                            let translatePosition = (
                                transfromOptions["translate"] ?? "0px, 0px"
                            )
                                .split(",")
                                .map(
                                    (item) =>
                                        parseFloat(item) / this.props.scale
                                );

                            const x =
                                parseFloat(target.style.left) /
                                    this.props.scale +
                                translatePosition[0];
                            const y =
                                parseFloat(target.style.top) /
                                    this.props.scale +
                                translatePosition[1];

                            let newSize = {
                                x,
                                y,
                                nodePosition: {
                                    ...element.nodePosition,
                                    [canvasViewMode]: {
                                        x,
                                        y,
                                    },
                                },
                                nodeSize: {
                                    ...element.nodeSize,
                                    [canvasViewMode]: {
                                        width:
                                            parseFloat(target.style.width) /
                                            this.props.scale,
                                        height:
                                            parseFloat(target.style.height) /
                                            this.props.scale,
                                    },
                                },
                            };

                            target.style.transform = "none";

                            if (!mobileViewWasEdited) {
                                this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                            }

                            this.props.canvasTreeStore.updateEmbedUrlElementAction(
                                this.props.elementId,
                                {
                                    ...newSize,
                                }
                            );

                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                ...newSize.nodePosition[canvasViewMode],
                                ...newSize.nodeSize[canvasViewMode],
                            });
                            this.props.onResize();
                        }}
                    ></Moveable>
                )}
            </>
        );
    }
}

@observer
class EmbedUrlElements extends Component<HtmlElementProps> {
    public render(): JSX.Element {
        let elementUIs: JSX.Element[] = [];
        for (let elementId of this.props.canvasTreeStore.embedUrlElementsState.keys())
            elementUIs.push(
                <EmbedUrlElementWrapper
                    rootDataTestId={`embedUrlElement-${elementUIs.length + 1}`}
                    key={elementId}
                    elementId={elementId}
                    {...this.props}
                />
            );
        return <>{elementUIs}</>;
    }
}

export default EmbedUrlElements;
