import React from "react";
import ReactWordcloud, { Word } from "react-wordcloud";
// import "common/styles/tippy.css";
// import "common/styles/tippy.js/animations/scale.css";

interface Props {
    data: Word[];
}

const wordcloudOptions: any = {
    spiral: "archimedean",
    deterministic: true,
    padding: 2,
    rotations: 0,
    fontFamily: "Oswald",
    fontWeight: "bold",
    rotationAngles: [-90, 90],
    transitionDuration: 500,
    fontSizes: [6, 100],
    tooltipOptions: {
        theme: "content-primary",
    },
};

export default function WordCloud(props: Props): JSX.Element | null {
    return (
        <div style={{
            overflow: "hidden",
            width: "100%",
            height: "100%",
        }}>
        <div
            style={{
                minHeight: 100,
                minWidth: 100,
                width: "100%",
                height: "100%",
            }}
        >
            <ReactWordcloud options={wordcloudOptions} words={props.data} />
        </div>
        </div>
    );
}
