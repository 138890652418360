import StringUtils from "common/utilities/StringUtils";

let internalLinkActive = false;

export function getInternalLinkActive(): boolean {
	return internalLinkActive;
}

export function goToInternalLink(link: string) {
	// order makes sense
	internalLinkActive = true;
	if (!StringUtils.isValidHttpUrl(link) && !link.startsWith("/"))
		link = "/".concat(link);
	window.location.href = link;
}