import React from "react";
import cx from "classnames";
import { Button } from "react-bootstrap";
import { MapFinding } from "common/Finding";
import EditInput from "../../EditInput";
import styles from "./PinsDataTable.module.css";
import commonStyles from "../../DataSection.module.css";

interface Props {
    mapFinding: MapFinding;
    onChange: (finding: MapFinding, updateData?: boolean) => void;
    currentModuleId?: number;
    style?: React.CSSProperties;
}

function PinsDataTable(props: Props): JSX.Element {
    let cols = 2;
    return (
        <div className="my-row" style={props.style}>
            <div
                className={styles.dataContainer}
                style={{
                    width: "calc(100% - 30px)",
                    gridTemplateColumns: `repeat(${cols}, calc(100%/${cols}))`,
                }}
            >
                <div className={commonStyles.header}>Latitude</div>
                <div className={commonStyles.header}>Longitude</div>
                {props.mapFinding.content.data?.["%lat"].map((_, index) => (
                    <>
                        <EditInput
                            showDeleteButton={false}
                            disabled={false}
                            value={
                                props.mapFinding.content.data!["%lat"][index] ??
                                0
                            }
                            onChange={(value) => {
                                let finding: MapFinding = {
                                    ...props.mapFinding,
                                    content: {
                                        ...props.mapFinding.content,
                                        data: {
                                            ...props.mapFinding.content.data,
                                            "%lat": [
                                                ...(props.mapFinding.content
                                                    .data?.["%lat"] ?? []),
                                            ],
                                        },
                                    },
                                };
                                finding.content.data!["%lat"][
                                    index
                                ] = Number(value);
                                props.onChange(finding);
                            }}
                        />
                        <EditInput
                            disabled={false}
                            showDeleteButton={true}
                            value={
                                props.mapFinding.content.data!["%lon"][index] ??
                                0
                            }
                            onChange={(value) => {
                                let finding: MapFinding = {
                                    ...props.mapFinding,
                                    content: {
                                        ...props.mapFinding.content,
                                        data: {
                                            ...props.mapFinding.content.data,
                                            "%lon": [
                                                ...(props.mapFinding.content
                                                    .data?.["%lon"] ?? []),
                                            ],
                                        },
                                    },
                                };
                                finding.content.data!["%lon"][
                                    index
                                ] = Number(value);
                                props.onChange(finding);
                            }}
                            onDelete={() => {
                                let finding: MapFinding = {
                                    ...props.mapFinding,
                                    content: {
                                        ...props.mapFinding.content,
                                        data: {
                                            "%lat": [
                                                ...(props.mapFinding.content
                                                    .data?.["%lat"] ?? []),
                                            ],
                                            "%lon": [
                                                ...(props.mapFinding.content
                                                    .data?.["%lon"] ?? []),
                                            ],
                                        },
                                    },
                                };
                                finding.content.data!["%lat"].splice(index, 1);
                                finding.content.data!["%lon"].splice(index, 1);
                                props.onChange(finding);
                            }}
                        />
                    </>
                ))}
            </div>
            <Button
                className={cx(
                    "btn btn-sm btn-primary my-primary",
                    commonStyles.addColumnButton
                )}
                onClick={() => {
                    let finding: MapFinding = {
                        ...props.mapFinding,
                        content: {
                            ...props.mapFinding.content,
                            data: {
                                "%lat": [
                                    ...(props.mapFinding.content.data?.[
                                        "%lat"
                                    ] ?? []),
                                ],
                                "%lon": [
                                    ...(props.mapFinding.content.data?.[
                                        "%lon"
                                    ] ?? []),
                                ],
                            },
                        },
                    };
                    finding.content.data!["%lat"].push(0);
                    finding.content.data!["%lon"].push(0);
                    props.onChange(finding);
                }}
            >
                {"\uFF0B"}
            </Button>
        </div>
    );
}

export default PinsDataTable;
