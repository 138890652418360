import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Alert from "./Alert";
import {
    getSharedModuleByLink,
    getSharedModuleByLinkAuth,
    confirmSession,
    getTutorial,
} from "common/ModulesApi";
import { SharedModule } from "common/Module";
import { goToInternalLink } from "common/InternalLinksHelper";
import { mainStyle } from "common/MainStyle";
import { MyStripePaywall } from "common/mystripe_components/MyStripePayWall";
import StringUtils from "./utilities/StringUtils";

// const openOptions = ["new", "keep", "replace"];

enum UpdateStatus {
    success = 1,
    loading = 2,
    error = 3,
    notUploaded = 4,
}

interface Props {
    isTutorial: boolean;
    requireAuth: boolean;
    linkId: string;
    onLoad: (module: SharedModule) => void;
    sessionId?: string | null;
}

interface State {
    authModuleId: number | undefined;
    showOpenModuleOptions: boolean;
    needPassword: boolean;
    password: string;
    status: UpdateStatus;
    errorMessage: string;
    paymentInfo?: {
        title: string;
        description: string;
        keywords: string[];
        thumbnail: string;
        paywall?: {
            title: string;
            client: string;
            description: string;
            payment_type: string;
            price: number;
            logo: string | null;
        };
    };
}

class LoadSharedModulePopup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            needPassword: false,
            password: "",
            status: UpdateStatus.notUploaded,
            errorMessage: "",
            authModuleId: undefined,
            showOpenModuleOptions: false,
            paymentInfo: undefined,
        };
    }
    loadAuthModule(id: number, updated: boolean) {
        if (updated) {
            this.setState({
                status: UpdateStatus.notUploaded,
                authModuleId: id,
                showOpenModuleOptions: true,
            });
        } else {
            goToInternalLink(`canvas.html?current_module_id=${id}`);
        }
    }

    componentDidMount() {
        if (this.props.isTutorial) {
            if (this.props.linkId.startsWith("t~")) {
                let tutorialId = this.props.linkId.slice(2);
                if (StringUtils.isNumber(tutorialId)) {
                    getTutorial(Number(tutorialId))
                        .then((sharedModule: SharedModule) => {
                            this.props.onLoad(sharedModule);
                        })
                        .catch((error) => {
                            this.setState({
                                status: UpdateStatus.error,
                                errorMessage: String(error),
                            });
                        });
                    return;
                }
            }
            this.setState({
                status: UpdateStatus.error,
                errorMessage: "Tutorial not found",
            });
        }
        if (this.props.requireAuth) {
            if (this.props.sessionId) {
                confirmSession(this.props.sessionId)
                    .then(() => {
                        window.history.replaceState(
                            {},
                            document.title,
                            `/shared_auth_canvas_module.html?remote_moduleid=${encodeURIComponent(
                                this.props.linkId
                            )}`
                        );
                        this.getSharedModuleByLinkAuth();
                    })
                    .catch((error) => {
                        this.setState({
                            status: UpdateStatus.error,
                            errorMessage: error.toString(),
                        });
                    });
            } else {
                this.getSharedModuleByLinkAuth();
            }
        } else {
            getSharedModuleByLink(this.props.linkId)
                .then((sharedModule: SharedModule) => {
                    this.props.onLoad(sharedModule);
                })
                .catch((_error) => {
                    this.setState({ needPassword: true });
                });
        }
    }

    private getSharedModuleByLinkAuth(mode?: string): void {
        getSharedModuleByLinkAuth(this.props.linkId, this.state.password, mode)
            .then((sharedModule) => {
                if ("id" in sharedModule) {
                    this.setState({
                        status: UpdateStatus.success,
                    });
                    this.loadAuthModule(sharedModule.id, sharedModule.updated);
                } else if ("action_required" in sharedModule) {
                    if (sharedModule.action_required === "payment") {
                        // remove action_required
                        let { action_required, ...rest } = sharedModule;
                        this.setState({
                            paymentInfo: rest,
                        });
                    } else {
                        // "attach_payment_method"
                        goToInternalLink(
                            `/attach_and_subscribe.html?app_id=${encodeURIComponent(
                                this.props.linkId
                            )}`
                        );
                    }
                } else if ("redirect_url" in sharedModule) {
                    window.location.href = sharedModule.redirect_url;
                } else {
                    this.props.onLoad(sharedModule);
                }
            })
            .catch((error) => {
                this.setState({
                    status: UpdateStatus.error,
                    errorMessage: String(error),
                });
            });
    }

    private buildAlert(): JSX.Element | null {
        let alert: JSX.Element | null = null;
        if (this.state.status === UpdateStatus.success) {
            alert = (
                <Alert
                    text="Successully loaded."
                    className="alert alert-success alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.error) {
            alert = (
                <Alert
                    text={"Loading error: ".concat(this.state.errorMessage)}
                    className="alert alert-danger alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.loading) {
            alert = (
                <Alert
                    text={"Loading"}
                    className="alert alert-warning alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        }
        return alert;
    }

    private showMultilineText(text: string): JSX.Element {
        return (
            <>
                {text.split("\n").map((line, index) => (
                    <span
                        key={index}
                        style={{
                            textAlign: "left",
                            marginTop: 10,
                            marginLeft: 10,
                            wordBreak: "break-word",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: 12,
                            display: "block",
                        }}
                    >
                        {line}
                    </span>
                ))}
            </>
        );
    }

    private buildModuleRow(): JSX.Element {
        return (
            <div
                style={{
                    marginTop: 20,
                    width: "100%",
                }}
            >
                <MyStripePaywall
                    linkId={this.props.linkId}
                    subscribeEndpoint="/api/module_user_share_link_payment_add"
                    logoUrl={this.state.paymentInfo?.paywall?.logo ?? undefined}
                    brandName={this.state.paymentInfo?.paywall?.title}
                    price={this.state.paymentInfo?.paywall?.price ?? 0}
                    onPayment={() => {
                        this.getSharedModuleByLinkAuth();
                    }}
                />
            </div>
        );
    }

    private buildModuleContent(): JSX.Element | null {
        if (this.state.paymentInfo == null) return null;
        return (
            <div
                className="my-row"
                style={{
                    width: "100%",
                    height: "100%",
                    marginTop: 20,
                }}
            >
                <div
                    style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        width: "75%",
                        height: "100%",
                        minHeight: 0,
                    }}
                >
                    {this.buildModuleRow()}
                </div>
                <div
                    className="flex-simple-column"
                    style={{
                        width: "25%",
                        paddingRight: 30,
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{
                            width: "100%",
                            minHeight: 300,
                        }}
                    >
                        <span
                            style={{
                                textAlign: "left",
                                color: mainStyle.getPropertyValue(
                                    "--popup-secondary-text-color"
                                ),
                                fontFamily: "Roboto",
                                fontSize: 17,
                                fontWeight: 700,
                                marginTop: 10,
                            }}
                        >
                            Description
                        </span>
                        <div>
                            {this.showMultilineText(
                                this.state.paymentInfo.description ?? ""
                            )}
                        </div>
                    </div>
                    <div
                        className="flex-simple-column"
                        style={{
                            width: "100%",
                            minHeight: 300,
                        }}
                    >
                        <span
                            style={{
                                textAlign: "left",
                                color: mainStyle.getPropertyValue(
                                    "--popup-secondary-text-color"
                                ),
                                fontFamily: "Roboto",
                                fontSize: 17,
                                fontWeight: 700,
                                marginTop: 10,
                            }}
                        >
                            Keywords
                        </span>
                        <div>
                            {this.showMultilineText(
                                this.state.paymentInfo.keywords?.join("\n") ??
                                    ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private buildInnerView(): JSX.Element {
        return (
            <div
                className="center-container"
                style={{
                    overflow: "visible",
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    className="flex-simple-column"
                    style={{
                        overflow: "visible",
                        marginTop: 20,
                        height: "100%",
                        width: "90%",
                    }}
                >
                    {this.state.needPassword && (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    width: "90%",
                                    marginBottom: "15px",
                                }}
                            >
                                <span
                                    className="popup-regular-text"
                                    style={{
                                        width: 70,
                                    }}
                                >
                                    Password:
                                </span>
                                <input
                                    className="like-select"
                                    type="password"
                                    style={{
                                        width: 200,
                                        marginLeft: "15px",
                                    }}
                                    value={this.state.password}
                                    onChange={(e) => {
                                        const text = e.target.value;
                                        this.setState({
                                            password: text,
                                        });
                                    }}
                                />
                            </div>

                            <Button
                                type="button"
                                className="btn btn-sm btn-primary my-primary"
                                style={{
                                    alignSelf: "center",
                                    width: "112px",
                                }}
                                onClick={() => {
                                    this.setState({
                                        status: UpdateStatus.loading,
                                    });
                                    if (!this.props.requireAuth) {
                                        getSharedModuleByLink(
                                            this.props.linkId,
                                            this.state.password
                                        )
                                            .then(
                                                (
                                                    sharedModule: SharedModule
                                                ) => {
                                                    this.setState({
                                                        status: UpdateStatus.success,
                                                    });
                                                    this.props.onLoad(
                                                        sharedModule
                                                    );
                                                }
                                            )
                                            .catch((error) => {
                                                this.setState({
                                                    status: UpdateStatus.error,
                                                    errorMessage: String(error),
                                                });
                                            });
                                    } else {
                                        this.getSharedModuleByLinkAuth();
                                    }
                                }}
                            >
                                LOAD
                            </Button>
                        </>
                    )}
                    {this.state.showOpenModuleOptions && (
                        <>
                            <span className="popup-regular-text">
                                Source user module have been updated. How do you
                                want to open it?
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    marginTop: "15px",
                                }}
                            >
                                <Button
                                    type="button"
                                    className="btn btn-sm btn-primary my-primary"
                                    style={{
                                        width: "112px",
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            status: UpdateStatus.loading,
                                        });
                                        this.getSharedModuleByLinkAuth("new");
                                    }}
                                >
                                    CREATE NEW
                                </Button>
                                <Button
                                    type="button"
                                    className="btn btn-sm btn-primary my-primary"
                                    style={{
                                        width: "70px",
                                    }}
                                    onClick={() => {
                                        goToInternalLink(
                                            `canvas.html?current_module_id=${this.state.authModuleId}`
                                        );
                                    }}
                                >
                                    KEEP
                                </Button>
                                <Button
                                    type="button"
                                    className="btn btn-sm btn-primary my-primary"
                                    style={{
                                        width: "70px",
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            status: UpdateStatus.loading,
                                        });
                                        this.getSharedModuleByLinkAuth(
                                            "replace"
                                        );
                                    }}
                                >
                                    REPLACE
                                </Button>
                            </div>
                        </>
                    )}
                    {this.state.paymentInfo != null && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <div className="flex-simple-column">
                                <div
                                    className="center-container"
                                    style={{ minHeight: 30 }}
                                >
                                    <span
                                        style={{
                                            marginTop: 20,
                                            paddingTop: 0,
                                            paddingLeft: 10,
                                            color: "#0097A7",
                                            fontFamily: "Roboto",
                                            fontSize: 30,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {this.state.paymentInfo.title}
                                    </span>
                                </div>

                                {this.buildModuleContent()}
                            </div>
                        </div>
                    )}
                    <div style={{ marginTop: "10px" }}>{this.buildAlert()}</div>
                </div>
            </div>
        );
    }

    render() {
        let height = this.state.paymentInfo != null ? 712 : 60;
        let width = this.state.paymentInfo != null ? 1000 : 300;
        return this.state.needPassword ||
            this.state.errorMessage ||
            this.state.showOpenModuleOptions ||
            this.state.paymentInfo != null ? (
            <div
                className="dashboard-rect"
                style={{
                    overflow: "visible",
                    position: "absolute",
                    border: "1px solid black",
                    top: (window.innerHeight - height) / 2,
                    alignItems: "center",
                    cursor: "pointer",
                    left: (window.innerWidth - width) / 2,
                    width: width,
                    zIndex: 100000000,
                }}
            >
                {this.buildInnerView()}
            </div>
        ) : null;
    }
}

export default LoadSharedModulePopup;
