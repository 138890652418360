import React from "react";

export function MySvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.959"
      height="19"
      viewBox="0 0 20.959 19"
      {...props}
    >
      <g transform="translate(.69390778)">
        <path
          fill="none"
          strokeDasharray="0 0 0 0"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="1"
          d="M11.47657273 5.20326364l2.95085454-2.68458791 3.43112728 2.31089972-3.06883637 2.63472546-3.31314545-2.26103727z"
        />
        <g>
          <path
            fill="none"
            strokeDasharray="0 0 0 0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M1.7067479 5.21426818l2.99721846-2.63268163L8.0942991 4.95201636 4.97989 7.53278273 1.7067479 5.21426818z"
          />
          <path
            fill="none"
            strokeDasharray="0 0 0 0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M1.65840727 13.38508182l2.95081364-2.68461818 3.43119273 2.31095454-3.06890182 2.6347091-3.31310455-2.26104546z"
          />
          <path
            fill="none"
            d="M11.47657273 5.20326364l2.95085454-2.68458791 3.43112728 2.31089972-3.06883637 2.63472546-3.31314545-2.26103727z"
          />
          <path
            fill="none"
            strokeDasharray="0 0 0 0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M6.79801273 9.11241364l2.99719636-2.63268L13.18559091 8.85017l-3.1144091 2.58076636-3.27316908-2.31852272z"
          />
          <path
            fill="none"
            strokeDasharray="0 0 0 0"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M11.52492727 13.39604545l2.99724546-2.63266363 3.3903 2.37043636-3.1144091 2.58079091-3.27313636-2.31856364z"
          />
        </g>
      </g>
    </svg>
  );
}