import React, { useState } from "react";
import { mainStyle } from "common/MainStyle";
import AutosizeInput from "react-input-autosize";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";

interface Props {
	question: string;
	frozen: boolean;
	live: boolean;
	underline?: boolean;
	onChange: (question: string) => void;
}

export default function QuestionHeaderView(props: Props) {
	let [question, setQuestion] = useState(props.question);
	let underline = props.underline ?? false;
	let innerItem = (
		<AutosizeInput
			placeholder={props.live ? "" : "Add question text here..."}
			disabled={props.live || props.frozen}
			autoFocus
			inputStyle={{
				fontSize: mainStyle.getPropertyValue(
					"--primary-path-title-size"
				),
				border: "none",
				outline: "none",
				backgroundColor: "transparent",
				fontFamily: "Roboto",
				color: !props.underline
					? dataScienceElementsStyle.primaryTextColor
					: dataScienceElementsStyle.secondaryTextColor,
			}}
			value={question}
			onMouseDown={(evt) => {
				evt.stopPropagation();
			}}
			onChange={(evt) => {
				// We have to read name before setState, because evt.target.value might be undefined during setState
				let value = evt.target.value;
				setQuestion(value);
			}}
			onBlur={(_evt) => {
				props.onChange(question);
			}}
			onKeyDown={(evt) => {
				evt.stopPropagation();
				if (evt.key === "Enter") {
					evt.preventDefault();
					props.onChange(question);
				}
			}}
		/>
	);
	if (!underline) {
		return innerItem;
	}
	return (
		<div className="flex-simple-column">
			{innerItem}

			<div
				style={{
					height: "2px",
					backgroundColor:
						dataScienceElementsStyle.secondaryTextColor,
				}}
			></div>
		</div>
	);
}