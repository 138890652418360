import React from "react";

export function MySvg(props) {
	return (
		
		<svg
			width="15"
			height="23"
			viewBox="0 0 15 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#657D95"
			{...props}
		>
			<path
				d="M11.875 1.625H3.125C2.43464 1.625 1.875 2.11002 1.875 2.70833V10.2917C1.875 10.89 2.43464 11.375 3.125 11.375H11.875C12.5654 11.375 13.125 10.89 13.125 10.2917V2.70833C13.125 2.11002 12.5654 1.625 11.875 1.625Z"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.625 1.625V11.375"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.75 18.625L7.5 20.375L9.25 18.625"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>

		/*
		<svg width="20" height="20"
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M13 10V14H19V10H13ZM11 10H5V14H11V10ZM13 19H19V16H13V19ZM11 19V16H5V19H11ZM13 5V8H19V5H13ZM11 5H5V8H11V5ZM19 3C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H19Z"
		fill="#657D95"//"#2E3A59"
		/>
		<path
			d="M5.75 18.625L7.5 20.375L9.25 18.625"
			strokeWidth="1.66667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		</svg>
		*/


	);
}
