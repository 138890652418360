import React from "react";
import _ from "lodash";
import CustomBarChart from "./CustomBarChart";
import {
    getContainerStyleByTheme,
    getDefaultColorsByTheme,
} from "../BarChartTheme";
import { Element } from "react-scroll";
import Finding, { BarChartFinding, getData } from "common/Finding";
import { LegendEditElement } from "../LegendEditElement";
import CustomBarChartD3 from "./D3Charts/CustomBarChartD3";

interface Props {
    groupNames: BarChartFinding["content"]["groupNames"];
    groupInfo: BarChartFinding["content"]["groupInfo"];
    data: BarChartFinding["content"]["data"];
    varyByData?: BarChartFinding["content"]["varyByData"];
    varyByIndices?: BarChartFinding["content"]["varyByIndices"];
    additionalVaryByData?: BarChartFinding["content"]["additionalVaryByData"];
    horizontalLines?: BarChartFinding["content"]["horizontalLines"];
    config: BarChartFinding["config"];
    selected: boolean;
    scale: number;
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
    onChangeData?: (
        data: BarChartFinding["content"]["data"],
        updateFinding?: boolean
    ) => void;
    onChangeConfig?: (
        config: BarChartFinding["config"],
        updateFinding?: boolean
    ) => void;
    showGrid?: boolean;
    truncValues?: boolean;
    manualTicks?: boolean;
}

function CompareVariablesChart(props: Props) {
    let {
        data,
        varyByData,
        varyByIndices,
        additionalVaryByData,
        horizontalLines,
        groupNames,
        groupInfo,
        config,
        editable,
        preview,
        //width
        onChangeData,
        onChangeConfig,
        showGrid,
        selected,
        truncValues,
        manualTicks,
        scale,
    } = props;
    let {
        dataScope,
        barSize,
        barGap,
        barCategoryGap,
        showLegend,
        legendSize,
        labelMapping,
        nameColorMapping,
        maxYRange,
        minYRange,
        linesCount,
        statusExpressions,
        selectedTargetVariablesIndices,
        chartTheme,
        baseBackgroundColor,
        showXAxisName,
        showYAxisName,
        sortYAxis,
        yAxisSortType,
        yAxisName,
        axesLinesColor,
        ticksColor,
        labelsColor,
        axesNamesColor,
        tooltipColor,
        stacked,
        circular,
        showAsDots,
        ticksSize,
        ticksAndLabels,
        tooltipFontSize,
        lineColor,
        dotRadius,
        flip,
        dashLine,
        sideBySideVariableIndex,
    } = config;
    let currentEditVariableIndex: number | undefined = undefined;
    if (dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = data.findIndex(
            (item) => item.variableIndex == null
        );
    }
    let defaultOptions = React.useMemo(
        () => getDefaultColorsByTheme(chartTheme),
        [chartTheme]
    );
    let containerStyle = getContainerStyleByTheme(
        chartTheme,
        baseBackgroundColor ?? defaultOptions.baseBackgroundColor
    );
    let d3: boolean = config.journeyName === "CompareVariablesD3Journey";
    let sideBySide = d3 && (groupNames ?? []).length > 1 && varyByData == null;
    const sortedData = React.useMemo(
        () =>
            getData(
                data,
                varyByData,
                additionalVaryByData,
                sortYAxis,
                yAxisSortType,
                varyByIndices
            ),
        [
            data,
            varyByData,
            additionalVaryByData,
            sortYAxis,
            yAxisSortType,
            varyByIndices,
        ]
    );
    
    let sortedOriginalData = React.useMemo(() => {
        let dataCopy: Finding["content"]["data"] | null = null;
        if (sortYAxis) {
            dataCopy = Array.from(data);
            type DataType = Finding["content"]["data"];
            if (yAxisSortType === "ascending") {
                dataCopy.sort((a: DataType, b: DataType) => {
                    if (Array.isArray(a.value)) {
                        return a.value[0] - b.value[0]
                    }
                    return a.value - b.value
                });
            } else {
                dataCopy.sort((a: DataType, b: DataType) => {
                    if (Array.isArray(a.value)) {
                        return b.value[0] - a.value[0]
                    }
                    return b.value - a.value
                });
            }
        }
        
        return dataCopy;
    }, [sortYAxis, yAxisSortType, data]);
    
    const barColors = React.useMemo(
        () => (preview ? ["#FFFFFF"] : defaultOptions.barColors),
        [preview, defaultOptions.barColors]
    );

    return (
        <div
            className="flex-simple-column"
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            <div
                className="my-row"
                style={{
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                <Element
                    name="scrollable"
                    className="element"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: flip ? undefined : "100%",
                        overflowX: flip ? "hidden" : "auto",
                        overflowY: flip ? "auto" : "hidden",
                        width: flip ? "100%" : undefined,
                        position: "relative",
                        ...containerStyle,
                    }}
                >
                    {(showLegend ?? true) && (
                        <div
                            className="my-row"
                            style={{
                                justifyContent: "center",
                                flexWrap: "wrap",
                                minHeight: 16,
                            }}
                        >
                            {sortedData[0].map((item, index) => (
                                <LegendEditElement
                                    key={index}
                                    textSize={legendSize}
                                    textColor={
                                        config.axesNamesColor ?? "#333333"
                                    }
                                    color={
                                        nameColorMapping?.[index] ??
                                        (item.variableIndex == null
                                            ? "#D1D1D1"
                                            : defaultOptions.barColors[
                                                  index %
                                                      defaultOptions.barColors
                                                          .length
                                              ])
                                    }
                                    onFinishColor={(color) => {
                                        config.nameColorMapping = {
                                            ...config.nameColorMapping,
                                            [index]: color,
                                        };
                                        props.onChangeConfig?.(config, false);
                                    }}
                                    allowColorChange={editable}
                                    disallowTextChange
                                    text={item.name}
                                />
                            ))}
                        </div>
                    )}
                    <div
                        style={{
                            height:
                                showLegend ?? true
                                    ? "calc(100% - 16px)"
                                    : "100%",
                        }}
                    >
                        {d3 ? (
                            !sideBySide ? (
                                <CustomBarChartD3
                                    flip={flip}
                                    selected={selected}
                                    tooltipFontSize={tooltipFontSize}
                                    ticksSize={ticksSize}
                                    ticksAndLabels={ticksAndLabels}
                                    showXAxisName={showXAxisName}
                                    showYAxisName={showYAxisName}
                                    sortYAxis={sortYAxis}
                                    yAxisSortType={yAxisSortType}
                                    scale={scale}
                                    tooltipColor={tooltipColor}
                                    yAxisName={yAxisName}
                                    editable={editable}
                                    chartTheme={chartTheme}
                                    currentEditVariableIndex={
                                        currentEditVariableIndex
                                    }
                                    transpose={varyByData != null}
                                    baseBackgroundColor={
                                        baseBackgroundColor ??
                                        defaultOptions.baseBackgroundColor
                                    }
                                    circular={circular}
                                    statusExpressions={statusExpressions}
                                    barIndices={selectedTargetVariablesIndices}
                                    minYRange={minYRange}
                                    maxYRange={maxYRange}
                                    axesLinesColor={axesLinesColor}
                                    axesNamesColor={axesNamesColor}
                                    ticksColor={ticksColor}
                                    labelsColor={labelsColor}
                                    nameColorMapping={nameColorMapping}
                                    labelMapping={labelMapping}
                                    barSize={barSize}
                                    barGap={barGap}
                                    barCategoryGap={barCategoryGap}
                                    groupNames={groupNames}
                                    groupInfo={groupInfo}
                                    barColors={barColors}
                                    lineColor={lineColor}
                                    dotRadius={dotRadius}
                                    dashLine={dashLine}
                                    data={sortedData}
                                    originalData={sortedOriginalData ?? data}
                                    varyByData={varyByData ?? undefined}
                                    horizontalLines={horizontalLines}
                                    stacked={stacked}
                                    showAsDots={showAsDots}
                                    title={""}
                                    onLinkVariable={(
                                        index,
                                        variableName,
                                        variableIndex
                                    ) => {
                                        let newData = Array.from(data);
                                        newData[index].name = variableName;
                                        newData[
                                            index
                                        ].originalName = variableName;
                                        newData[
                                            index
                                        ].variableIndex = variableIndex;
                                        onChangeData?.(newData, true);
                                    }}
                                    onRenameVariable={(index, name) => {
                                        let newData = Array.from(data);
                                        newData[index].name = name;
                                        onChangeData?.(newData);
                                    }}
                                    onConfigChange={(changes) => {
                                        let newConfig = {
                                            ...config,
                                            ...changes,
                                        };
                                        onChangeConfig?.(newConfig);
                                    }}
                                    showGrid={showGrid}
                                    isSideBySide={
                                        sideBySideVariableIndex != null
                                    }
                                />
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        height: "100%",
                                        width: "100%",
                                    }}
                                >
                                    {groupNames!.map((groupName, index) => (
                                        <div
                                            key={groupName}
                                            style={{
                                                height: "100%",
                                                width: `calc(100%/${
                                                    groupNames!.length
                                                })`,
                                            }}
                                        >
                                            <CustomBarChartD3
                                                groupIndex={index}
                                                tooltipFontSize={
                                                    tooltipFontSize
                                                }
                                                selected={selected}
                                                originalData={sortedOriginalData ?? data}
                                                ticksSize={ticksSize}
                                                scale={scale}
                                                ticksAndLabels={ticksAndLabels}
                                                showXAxisName={showXAxisName}
                                                showYAxisName={showYAxisName}
                                                sortYAxis={sortYAxis}
                                                flip={flip}
                                                yAxisSortType={yAxisSortType}
                                                tooltipColor={tooltipColor}
                                                yAxisName={yAxisName}
                                                editable={editable}
                                                chartTheme={chartTheme}
                                                currentEditVariableIndex={
                                                    currentEditVariableIndex
                                                }
                                                transpose={varyByData != null}
                                                baseBackgroundColor={
                                                    baseBackgroundColor ??
                                                    defaultOptions.baseBackgroundColor
                                                }
                                                circular={circular}
                                                statusExpressions={
                                                    statusExpressions
                                                }
                                                barIndices={
                                                    selectedTargetVariablesIndices
                                                }
                                                minYRange={minYRange}
                                                maxYRange={maxYRange}
                                                axesLinesColor={axesLinesColor}
                                                axesNamesColor={axesNamesColor}
                                                labelsColor={labelsColor}
                                                nameColorMapping={
                                                    nameColorMapping
                                                }
                                                labelMapping={labelMapping}
                                                barSize={barSize}
                                                barGap={barGap}
                                                barCategoryGap={barCategoryGap}
                                                groupNames={groupNames}
                                                groupInfo={groupInfo}
                                                barColors={barColors}
                                                lineColor={lineColor}
                                                dotRadius={dotRadius}
                                                dashLine={dashLine}
                                                data={sortedData}
                                                varyByData={
                                                    varyByData ?? undefined
                                                }
                                                horizontalLines={
                                                    horizontalLines
                                                }
                                                stacked={stacked}
                                                showAsDots={showAsDots}
                                                title={""}
                                                onLinkVariable={(
                                                    index,
                                                    variableName,
                                                    variableIndex
                                                ) => {
                                                    let newData = Array.from(
                                                        data
                                                    );
                                                    newData[
                                                        index
                                                    ].name = variableName;
                                                    newData[
                                                        index
                                                    ].originalName = variableName;
                                                    newData[
                                                        index
                                                    ].variableIndex = variableIndex;
                                                    onChangeData?.(
                                                        newData,
                                                        true
                                                    );
                                                }}
                                                onRenameVariable={(
                                                    index,
                                                    name
                                                ) => {
                                                    let newData = Array.from(
                                                        data
                                                    );
                                                    newData[index].name = name;
                                                    onChangeData?.(newData);
                                                }}
                                                onConfigChange={(changes) => {
                                                    let newConfig = {
                                                        ...config,
                                                        ...changes,
                                                    };
                                                    onChangeConfig?.(newConfig);
                                                }}
                                                showGrid={showGrid}
                                                isSideBySide={
                                                    sideBySideVariableIndex !=
                                                    null
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            )
                        ) : (
                            <CustomBarChart
                                tooltipFontSize={tooltipFontSize}
                                ticksSize={ticksSize}
                                ticksAndLabels={ticksAndLabels}
                                showXAxisName={showXAxisName}
                                showYAxisName={showYAxisName}
                                tooltipColor={tooltipColor}
                                yAxisName={yAxisName}
                                editable={editable}
                                chartTheme={chartTheme}
                                currentEditVariableIndex={
                                    currentEditVariableIndex
                                }
                                baseBackgroundColor={
                                    baseBackgroundColor ??
                                    defaultOptions.baseBackgroundColor
                                }
                                statusExpressions={statusExpressions}
                                barIndices={selectedTargetVariablesIndices}
                                minYRange={minYRange}
                                linesCount={linesCount}
                                maxYRange={maxYRange}
                                axesLinesColor={axesLinesColor}
                                axesNamesColor={axesNamesColor}
                                ticksColor={ticksColor}
                                nameColorMapping={nameColorMapping}
                                labelMapping={labelMapping}
                                barSize={barSize}
                                barGap={barGap}
                                barCategoryGap={barCategoryGap}
                                groupNames={groupNames}
                                barColors={barColors}
                                lineColor={lineColor}
                                dotRadius={dotRadius}
                                dashLine={dashLine}
                                data={data}
                                horizontalLines={horizontalLines}
                                stacked={stacked}
                                showAsDots={showAsDots}
                                title={""}
                                onLinkVariable={(
                                    index,
                                    variableName,
                                    variableIndex
                                ) => {
                                    let newData = Array.from(data);
                                    newData[index].name = variableName;
                                    newData[index].originalName = variableName;
                                    newData[
                                        index
                                    ].variableIndex = variableIndex;
                                    onChangeData?.(newData, true);
                                }}
                                onRenameVariable={(index, name) => {
                                    let newData = Array.from(data);
                                    newData[index].name = name;
                                    onChangeData?.(newData);
                                }}
                                onConfigChange={(changes) => {
                                    let newConfig = { ...config, ...changes };
                                    onChangeConfig?.(newConfig);
                                }}
                                showGrid={showGrid}
                                truncValues={truncValues}
                                manualTicks={manualTicks}
                            />
                        )}
                    </div>
                </Element>
            </div>
        </div>
    );
}

export default CompareVariablesChart;
