import React from "react";
import cx from "classnames";

import Skeleton from "common/Skeleton";
import PresentationListItem from "common/home_components/PresentationListItem";
import { AbstractPresentationModel } from "state/models/Presentation";

import styles from "./ListView.module.css";

interface Props {
    presentations: Array<AbstractPresentationModel>;
    loading: boolean;
    clickPresentation: Function;
}

function ListView({ presentations, loading, clickPresentation }: Props) {
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <span className={cx(styles.columnHeader, styles.title)}>
                    Name
                </span>
                <span className={cx(styles.columnHeader, styles.owner)}>
                    Owner
                </span>
                <span className={cx(styles.columnHeader, styles.lastUpdatedAt)}>
                    Last Modified
                </span>
            </div>
            <div className={styles.rows}>
                {!loading &&
                    presentations &&
                    presentations.map((presentation) => (
                        <PresentationListItem
                            key={presentation.id}
                            id={presentation.id}
                            title={presentation.title}
                            lastUpdatedAt={presentation.lastUpdated}
                            owner={presentation.owner}
                            onClick={clickPresentation}
                        />
                    ))}
            </div>
            {loading && <Skeleton className={styles.skeleton} />}
        </div>
    );
}

export default ListView;
