import React, { useState, useCallback } from "react";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Dock from "react-dock";
import cx from "classnames";

import { MapElement } from "common/Canvas";
import DataConfig from "./DataConfig";
import HeatmapConfig from "./HeatmapConfig";
import PinsConfig from "./PinsConfig";
import TabTitle from "common/TabTitle";
import TooltipConfig from "./TooltipConfig";
import { headerBarHeight, mapAdvancedEditMenuWidth } from "modules/canvas_page/Constants";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";

import styles from "./EditMenu.module.css";
import StylingTab from "./StylingTab";
import GeoJsonMenu from "../GeoJsonMenu";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

interface Props {
    mapElement: MapElement;
    canvasTreeStore: CanvasTreeStore;
    mapElementId: string;
    onTrackNewPerformance: (element: string) => void;
    scale: number;
    visible: boolean;
    closeEditMenu: () => void;
    onChange: (
        mapElement: Partial<MapElement>,
        geoJsonFiles?: MapElement["geoJsonFiles"]
    ) => void;
    currentModuleId?: number;
    data: { [key: string]: Array<number | string | null> } | null;
}

enum CollapsableMenuOptions {
    None = 0,
    Pins = 1,
    Heatmap = 2,
    MapBoundaries = 3,
}

enum SettingsTab {
    Data = 0,
    Styling = 1,
}

function EditMenu({
    canvasTreeStore,
    mapElement,
    mapElementId,
    onTrackNewPerformance,
    scale,
    visible,
    closeEditMenu,
    onChange,
    currentModuleId,
    data,
}: Props) {
    const [selectedTab, setSelectedTab] = useState(SettingsTab.Data);
    const [currentOpenedMenu, setCurrentOpenedMenu] = useState(
        CollapsableMenuOptions.None
    );

    const mapElementSize = {
        height: mapElement.nodeSize[canvasTreeStore.canvasViewMode].height * scale,
        width: mapElement.nodeSize[canvasTreeStore.canvasViewMode].width * scale,
    };

    function selectTab(tabKey: string | null) {
        if (tabKey == null) return;
        setSelectedTab(Number(tabKey) as SettingsTab);
    }

    const applyChanges = useCallback(
        (changes: Partial<MapElement>) => {
            onChange({
                ...mapElement,
                ...changes,
            });
        },
        [mapElement, onChange]
    );

    return (
        <Dock
            dockStyle={{
                backgroundColor: "#FFFFFF",
                top: `${headerBarHeight}px`,
            }}
            position="left"
            isVisible={visible}
            fluid={false}
            dimMode="none"
            size={mapAdvancedEditMenuWidth}
        >
            {visible && (
                <div>
                    <div className={styles.editMenuHeader}>
                        <h6 className={styles.editMenuHeaderTitle}>
                            Map Settings
                        </h6>
                        <button
                            type="button"
                            onClick={closeEditMenu}
                            className={styles.editMenuCloseButton}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div className={styles.editArea}>
                        <Tabs
                            id="map-settings"
                            activeKey={String(selectedTab)}
                            onSelect={selectTab}
                            className={styles.tabs}
                        >
                            <Tab
                                eventKey={String(SettingsTab.Data)}
                                title={
                                    <TabTitle
                                        title="Data"
                                        selected={
                                            selectedTab === SettingsTab.Data
                                        }
                                        className={styles.tabTitle}
                                    />
                                }
                                className={styles.tab}
                            >
                                <div className={styles.editMenuBody}>
                                    <DataConfig
                                        currentModuleId={currentModuleId}
                                        canvasTreeStore={canvasTreeStore}
                                        mapElement={mapElement}
                                        height={mapElementSize.height}
                                        onChange={applyChanges}
                                    />
                                    <TooltipConfig
                                        dataScope={mapElement.dataScope}
                                        variables={
                                            mapElement.tooltipVariables ?? [
                                                null,
                                            ]
                                        }
                                        displayMode={mapElement.displayMode}
                                        onChange={applyChanges}
                                        currentModuleId={currentModuleId}
                                    />
                                    <Accordion
                                        activeKey={String(currentOpenedMenu)}
                                        onSelect={(event: any) => {
                                            setCurrentOpenedMenu(Number(event));
                                        }}
                                    >
                                        <Accordion.Toggle
                                            eventKey={String(
                                                CollapsableMenuOptions.Pins
                                            )}
                                            className={
                                                styles.editMenuAdvancedOption
                                            }
                                        >
                                            Location Pins
                                            <ChevronIcon
                                                className={cx(
                                                    styles.editMenuAdvancedOptionIcon,
                                                    {
                                                        [styles.chevronOpen]:
                                                            currentOpenedMenu ===
                                                            CollapsableMenuOptions.Pins,
                                                    }
                                                )}
                                            />
                                        </Accordion.Toggle>
                                        <Accordion.Collapse
                                            eventKey={String(
                                                CollapsableMenuOptions.Pins
                                            )}
                                        >
                                            <PinsConfig
                                                mapElement={mapElement}
                                                height={mapElementSize.height}
                                                onChange={applyChanges}
                                                currentModuleId={
                                                    currentModuleId
                                                }
                                            />
                                        </Accordion.Collapse>
                                        <Accordion.Toggle
                                            eventKey={String(
                                                CollapsableMenuOptions.Heatmap
                                            )}
                                            className={
                                                styles.editMenuAdvancedOption
                                            }
                                        >
                                            Heatmap
                                            <ChevronIcon
                                                className={cx(
                                                    styles.editMenuAdvancedOptionIcon,
                                                    {
                                                        [styles.chevronOpen]:
                                                            currentOpenedMenu ===
                                                            CollapsableMenuOptions.Heatmap,
                                                    }
                                                )}
                                            />
                                        </Accordion.Toggle>
                                        <Accordion.Collapse
                                            eventKey={String(
                                                CollapsableMenuOptions.Heatmap
                                            )}
                                        >
                                            <HeatmapConfig
                                                mapElement={mapElement}
                                                onChange={applyChanges}
                                                currentModuleId={
                                                    currentModuleId
                                                }
                                            />
                                        </Accordion.Collapse>
                                        <Accordion.Toggle
                                            eventKey={String(
                                                CollapsableMenuOptions.MapBoundaries
                                            )}
                                            className={
                                                styles.editMenuAdvancedOption
                                            }
                                        >
                                            Map Boundaries
                                            <ChevronIcon
                                                className={cx(
                                                    styles.editMenuAdvancedOptionIcon,
                                                    {
                                                        [styles.chevronOpen]:
                                                            currentOpenedMenu ===
                                                            CollapsableMenuOptions.MapBoundaries,
                                                    }
                                                )}
                                            />
                                        </Accordion.Toggle>
                                        <Accordion.Collapse
                                            eventKey={String(
                                                CollapsableMenuOptions.MapBoundaries
                                            )}
                                        >
                                            <GeoJsonMenu
                                                mapElement={mapElement}
                                                onChange={onChange}
                                            />
                                        </Accordion.Collapse>
                                    </Accordion>
                                </div>
                            </Tab>
                            <Tab
                                eventKey={String(SettingsTab.Styling)}
                                title={
                                    <TabTitle
                                        title="Styling"
                                        selected={
                                            selectedTab === SettingsTab.Styling
                                        }
                                        className={styles.tabTitle}
                                    />
                                }
                                className={styles.tab}
                            >
                                <div className={styles.editMenuBody}>
                                    <StylingTab
                                        mapElement={mapElement}
                                        mapElementId={mapElementId}
                                        onChange={(changes) => {
                                            onChange({
                                                ...mapElement,
                                                ...changes,
                                            });
                                        }}
                                        onTrackNewPerformance={
                                            onTrackNewPerformance
                                        }
                                        currentModuleId={currentModuleId}
                                        data={data}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            )}
        </Dock>
    );
}

export default EditMenu;
