import sections from "sections.json";
import canvasElements, {
    elementLabels as canvasElementLabels,
} from "common/CanvasElements";

export interface TypeParameter {
    label: string;
    value: string;
    hasSection: boolean;
    hasElement: boolean;
    hasCount: boolean;
    hasTime: boolean;
}

export const typeParameters: ReadonlyArray<TypeParameter> = [
    {
        label: "Login",
        value: "login",
        hasSection: false,
        hasElement: false,
        hasCount: true,
        hasTime: true,
    },
    {
        label: "Interaction",
        value: "interaction",
        hasSection: true,
        hasElement: true,
        hasCount: true,
        hasTime: true,
    },
    {
        label: "Section Visit",
        value: "section_visit",
        hasSection: true,
        hasElement: false,
        hasCount: true,
        hasTime: false,
    },
    {
        label: "Modules Viewed",
        value: "module_visit",
        hasSection: false,
        hasElement: false,
        hasCount: true,
        hasTime: false,
    },
    {
        label: "Modules Shared",
        value: "module_shared",
        hasSection: false,
        hasElement: false,
        hasCount: true,
        hasTime: false,
    },
    {
        label: "Modules Shared Link",
        value: "module_shared_link",
        hasSection: false,
        hasElement: false,
        hasCount: true,
        hasTime: false,
    },
];

type Section = Exclude<keyof typeof sections, "_Global">;

export interface SectionParameter {
    label: string;
    value: Section | "";
}

export const sectionParameters: ReadonlyArray<SectionParameter> = Object.keys(
    sections
)
    .filter((key): key is Section => !key.startsWith("_"))
    .map((key) => ({
        label: sections[key].name,
        value: key,
    }));

export function sectionParametersOf(
    typeParameter: TypeParameter
): ReadonlyArray<SectionParameter> {
    if (typeParameter.hasSection) {
        return sectionParameters;
    } else {
        return [];
    }
}

export interface ElementParameter {
    label: string;
    value: string;
}

export function elementParametersOf(
    typeParameter: TypeParameter,
    sectionParameter: SectionParameter
): ReadonlyArray<ElementParameter> {
    if (typeParameter.hasElement && sectionParameter.value === "Canvas") {
        let elementParameters: ElementParameter[] = [
            {
                label: "No Element",
                value: "",
            },
        ];
        elementParameters = elementParameters.concat(
            Object.keys(canvasElements).map((key) => ({
                label: canvasElementLabels[key],
                value: canvasElements[key],
            }))
        );
        return elementParameters;
    } else {
        return [];
    }
}

export interface VariableParameter {
    label: string;
    value: string;
}

export function variableParametersOf(
    typeParameter: TypeParameter
): ReadonlyArray<VariableParameter> {
    let variableParameters: VariableParameter[] = [];
    if (typeParameter.hasCount) {
        variableParameters.push({
            label: "Count",
            value: "count",
        });
    }
    if (typeParameter.hasTime) {
        variableParameters.push({
            label: "Total Time (ms)",
            value: "total_time",
        });
        if (typeParameter.hasCount) {
            variableParameters.push({
                label: "Average Time (ms)",
                value: "average_time",
            });
        }
    }
    return variableParameters;
}
