import React from "react";
import { Button } from "react-bootstrap";
import { mainStyle } from "common/MainStyle";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";
import { ReactComponent as PlusIcon } from "icons/canvas/plus.svg";
import { ReactComponent as MinusIcon } from "icons/canvas/minus.svg";

// Custom styling
import Select from "react-select";
import { getCustomSelectStyleLight } from "common/SelectStyles";

// interface HSOptions {
//     //apikey: string;
//     apiselect: Object;
//     submitColumns: string[];
// }

interface State {
    hoverColumnIndex: number | undefined;
    newColumn: string;
    allColumns: string[];
}

export default class HUBSPOT extends React.Component<APIComponentProps, State> {
    constructor(props: APIComponentProps){
      super(props);
      this.state = {
        hoverColumnIndex: undefined,
        newColumn: "",
        allColumns: [],
      }
      this.props.onOptionsChange({
         apiselect: {value: 'deals', label: 'Deals API'},
         submitColumns: []
      });
    }

    render() {
        let selectStyles = getCustomSelectStyleLight(14, false);
        // let contentStyle: React.CSSProperties = {
        //     fontFamily: "Roboto",
        //     fontSize: 16,
        //     fontWeight: 400,
        //     color: mainStyle.getPropertyValue("--content-primary-text-color"),
        // };
        // let titleStyle = {
        //     ...contentStyle,
        //     color: "#39F",
        // };
        return (
            <div
                  className="flex-simple-column"
                  style={this.props.containerStyle}
            >
              <BaseAPIComponent {...this.props} />
                <div className="flex-simple-column" style={{ marginTop: "23px" }}>
                    {/* <span style={titleStyle}>Account API Key</span>
                    <input
                        style={{
                            ...contentStyle,
                            backgroundColor: mainStyle.getPropertyValue("--tertiary-content-color"),
                            marginTop: 10,
                            width: "300px",
                            borderColor: "transparent",
                            padding: "5px 5px",
                        }}
                        value={this.props.options?.apikey ?? ""}
                        onChange={(evt) => {
                            let apikeyval = evt.target.value;
                            this.props.onOptionsChange({
                                apikey: apikeyval,
                            });
                        }}
                    ></input>
                    */}
                      <div
                          className="my-row"
                          style={{ marginLeft: 0, marginTop: 9 }}
                      >

                        <Select name="hubspot-option"
                          options={
                            [
                              {value: 'deals', label: 'Deals API'},
                              {value: 'contacts', label: 'Contacts API'},
                            ]
                          }
                          defaultValue={{value: 'deals', label: 'Deals API'}}
                          styles={{
                            ...selectStyles,
                            container: (base) => ({
                                ...base,
                                height: "40px",
                                width: "40%",
                                marginLeft: "0px",
                                marginRight: "10px",
                            }),
                          }}
                          theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                  ...theme.colors,
                                  text: "white",
                                  primary25: "var(--selectors-background-hover-color)",
                              },
                          })}
                          onChange={(evt) => {
                            let options = { ...this.props.options };
                            this.props.onOptionsChange({
                              ...options,
                               apiselect: evt,
                            });
                          }}
                        />

                      </div>
                      <div
                          className="my-row"
                          style={{ marginLeft: 0, marginTop: 9 }}
                      >
                          <input
                              style={{ width: "40%" }}
                              className="like-select module-template"
                              placeholder="Add custom properties as columns"
                              onChange={(e) => {
                                  const value: string = e.target.value;
                                   this.setState({ newColumn: value });
                              }}
                              value={this.state.newColumn}
                          />
                          <Button
                              disabled={this.state.newColumn.length === 0}
                              title={"Add custom properties that you may have defined in Hubspot"}
                              className="btn-small-like-select"
                              style={{
                                marginLeft: -30,
                                alignSelf: "center",
                                width: "20px",
                                height: "20px",
                              }}
                              onClick={() => {
                                  let options = { ...this.props.options };
                                  let allColumns = [
                                      ...this.state.allColumns,
                                  ];
                                  allColumns.push(this.state.newColumn);
                                  this.setState({
                                      allColumns: allColumns,
                                      newColumn: "",
                                  });
                                  this.props.onOptionsChange({
                                      ...options,
                                      submitColumns: allColumns,
                                  });
                              }}
                          >
                            <div
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
																filter: this.state.newColumn.length > 0 ? "" : "invert(10%) sepia(16%) saturate(10%) brightness(100%)",
															}}
                            >
															<PlusIcon fill="red" stroke="red" />
                            </div>
                          </Button>
                      </div>
                      <div
                          style={{
                              marginTop: 17,
                              display: "grid",
                              rowGap: "8px",
                              columnGap: "30px",
                              gridTemplateColumns: "repeat(3, 100px)",
                          }}
                      >
                          {this.state.allColumns.map(
                              (datacolumn, datacolumnIndex) => (
                                  <div className="my-row"
                                      key={datacolumnIndex}
                                      onMouseEnter={() => {
                                          this.setState({
                                              hoverColumnIndex: datacolumnIndex,
                                          });
                                      }}
                                      onMouseLeave={() => {
                                          this.setState({
                                              hoverColumnIndex: undefined,
                                          });
                                      }}
                                  >
                                      <span
                                          style={{
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              width: "77px",
                                              marginLeft: 2,
                                              color: mainStyle.getPropertyValue("--popup-primary-text-color"),
                                              fontSize: "14px",
                                              fontWeight: 400,
                                              fontFamily: "Roboto",
                                          }}
                                          className="unselectable"
                                      >
                                          {datacolumn}
                                      </span>
																			<div
																					className="flex-simple-column"
																					style={{ marginLeft: 2 }}
																			>
																					<Button
																							title={"Remove Column"}
																							className="btn-small-like-select"
																							style={{
																								display: "flex",
																								justifyContent: "center",
																								alignItems: "center",
																								width: "19px",
																								height: "19px",
																							}}
																							onClick={() =>
																									this.setState(
																											(state) => {
																													let options = { ...this.props.options };
																													let datacolumns = Array.from(
																															state.allColumns
																													);
																													datacolumns.splice(
																															datacolumnIndex,
																															1
																													);
																													this.props.onOptionsChange({
																															...options,
																															submitColumns: datacolumns,
																													});

																													return {
																															allColumns: datacolumns,
																													};
																											}
																									)
																							}
																					>
																						<MinusIcon />
																					</Button>
																			</div>
                                  </div>
                              )
                          )}
                    </div>
                </div>
            </div>
        );
    }
}

export { HUBSPOT as MainComponent };
