export enum Permission {
	ReadOnly = 1,
	ReadWrite = 10,
}

export interface PermissionOptionType {
	label: string;
	value: Permission;
}

export const PermissionNames: {
	[key in Permission.ReadOnly | Permission.ReadWrite]: string;
} = {
	[Permission.ReadOnly]: "Can read",
	[Permission.ReadWrite]: "Can edit",
};

export const permissionOptions: ReadonlyArray<PermissionOptionType> = [
	{
		label: PermissionNames[Permission.ReadOnly],
		value: Permission.ReadOnly,
	},
	{
		label: PermissionNames[Permission.ReadWrite],
		value: Permission.ReadWrite,
	},
];