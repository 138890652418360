export const AggregateFunctionsAliases = {
    mean: "Means of different columns",
    sum: "Sums of different columns",
    count: "Counts of different values within a column",
    count_distinct: "Number of unique values in different columns",
    mean_column: "Means within a column",
    sum_column: "Sums within a column",
} as const;

export const AggregateFunctionsAliasesLinePlot = {
    mean: "Means for different values of X-axis",
    sum: "Sums for different values of X-axis",
    count: " Counts for different values of X-axis",
    count_distinct: "Number of unique values for different values of X-axis",
    mean_column: "Means within a column",
    sum_column: "Sums within a column",
} as const;

export const AggregateFunctions = {
    mean: (array: number[]) => array.reduce((x, y) => x + y, 0) / array.length,
    sum: (array: number[]) => array.reduce((x, y) => x + y, 0),
    count: (array: number[]) => array.length,
    count_distinct: (array: number[]) => new Set(array).size,
} as const;
