import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";

import styles from "./FloatingMessage.module.css";

interface Props {
	children: React.ReactElement;
	show: boolean;
	timeout: number;
	className?: string;
}

const ANIMATION_STATES = {
	SHOW_START: 'showStart',
	SHOW_END: 'showEnd',
	HIDE_START: 'hideStart',
	HIDE_END: 'hideEnd',
	IDLE: 'idle'
};

function FloatingMessage({ children, show, timeout, className }: Props) {
	const [animationState, setAnimationState] = useState(ANIMATION_STATES.IDLE);

	const currentTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

	async function transitionAnimation(animationState: string, timeout: number) {
		return new Promise<void>((resolve) => {
			currentTimer.current = setTimeout(() => {
				setAnimationState(animationState);
				resolve();
			}, timeout);
		});
	}

	useEffect(() => {
		async function triggerAnimations() {
			setAnimationState(ANIMATION_STATES.SHOW_START);
	
			await transitionAnimation(ANIMATION_STATES.SHOW_END, 200);
			await transitionAnimation(ANIMATION_STATES.HIDE_START, Math.max(timeout - 400, 0));
			await transitionAnimation(ANIMATION_STATES.HIDE_END, 200);
			await transitionAnimation(ANIMATION_STATES.IDLE, 0);

			currentTimer.current = null;
		}

		function stopAnimations() {
			if (currentTimer.current) {
				clearTimeout(currentTimer.current);
				transitionAnimation(ANIMATION_STATES.IDLE, 0);
			}
		}

		if (!show) {
			stopAnimations();
		} else {
			triggerAnimations();
		}
	}, [show, timeout]);

	return (
		<div className={cx(styles.root, styles[animationState], className)}>
			{ children }
		</div>
	);
}

export default FloatingMessage;
