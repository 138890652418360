import React, { Component } from "react";
import { observer } from "mobx-react";
import elements from "common/CanvasElements";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import HtmlElementProps from "./HtmlElementProps";
import ShapeElementView from "../ShapeElementView";
import {
    ShapeElement,
    ColorOptions,
    ShapeType,
    getNodeTranslation,
    getNodeTranslationString,
    InnerCanvasChanges,
    getBoundingBoxOfRotatedRectangle,
} from "common/Canvas";

import {
    makeMoveable,
    DraggableProps,
    ResizableProps,
    RotatableProps,
    Rotatable,
    Draggable,
    Resizable,
    OnDrag,
} from "react-moveable";
import MoveableHelper from "moveable-helper";
import { getTransformList } from "common/utilities/parseTransformString";
import styles from "./Moveable.module.css";
import cx from "classnames";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../Snap";
// In order to use only some able, make a component with makeMoveable function.
const Moveable = makeMoveable<DraggableProps & ResizableProps & RotatableProps>(
    [Draggable, Resizable, Rotatable]
);

interface InnerProps extends HtmlElementProps {
    shapeId: string;
    rootDataTestId: string;
}

interface InnerState {
    showColorOptions: ColorOptions | undefined;
    moveableHelper: MoveableHelper;
}

const typeName = "shapeElementsState";

@observer
class ShapeElementWrapper extends CanvasInteractionComponent<
    InnerProps,
    InnerState
> {
    private drag: boolean;
    private innerRef = React.createRef<HTMLDivElement>();
    private moveableRef = React.createRef<any>();

    constructor(props: InnerProps) {
        super(props);
        this.state = {
            showColorOptions: undefined,
            moveableHelper: new MoveableHelper(),
        };
        this.drag = false;
    }

    componentDidMount(): void {
        let shapeElement = this.props.canvasTreeStore.shapeElementsState.get(
            this.props.shapeId
        )!;
        let innerRef = this.innerRef.current;
        if (innerRef != null) {
            innerRef.setAttribute("type", typeName);
            if (shapeElement.groupId != null)
                innerRef.setAttribute("groupId", shapeElement.groupId);
            else {
                innerRef.removeAttribute("groupId");
            }
            innerRef.setAttribute("id", String(this.props.shapeId));
            innerRef.setAttribute("data-test-id", this.props.rootDataTestId);
        }
    }
    componentDidUpdate(prev: InnerProps): void {
        if (prev.selectedMetadata !== this.props.selectedMetadata) {
            let shapeElement = this.props.canvasTreeStore.shapeElementsState.get(
                this.props.shapeId
            )!;
            let innerRef = this.innerRef.current;
            if (innerRef != null) {
                if (shapeElement.groupId != null)
                    innerRef.setAttribute("groupId", shapeElement.groupId);
                else {
                    innerRef.removeAttribute("groupId");
                }
            }
        }
        this.moveableRef.current?.updateRect();
    }

    public render(): JSX.Element {
        const {
            canvasViewMode,
            mobileViewWasEdited,
            slideWidthRatio,
        } = this.props.canvasTreeStore;
        const selected =
            this.props.selectedMetadata?.length === 1 &&
            this.props.selectedMetadata?.[0]?.id === this.props.shapeId;
        let shapeElement = this.props.canvasTreeStore.shapeElementsState.get(
            this.props.shapeId
        )!;
        let shapeElementSize = {
            height:
                shapeElement.nodeSize[canvasViewMode].height * this.props.scale,
            width:
                shapeElement.nodeSize[canvasViewMode].width * this.props.scale,
        };
        let borderRadius: string | number | undefined = undefined;
        if (shapeElement.shapeStyle?.borderShadow) {
            if (shapeElement.shapeType === ShapeType.Circle) {
                borderRadius = "50%";
            } else {
                borderRadius = shapeElement.shapeStyle.borderRadius;
            }
        }
        let nodeTranslation = getNodeTranslation(shapeElement);
        let nodeTranslationString = getNodeTranslationString(
            nodeTranslation,
            this.props.scale,
            canvasViewMode
        );

        const boundingBox = getBoundingBoxOfRotatedRectangle(
            {
                left:
                    (shapeElement.nodePosition[canvasViewMode].x +
                        nodeTranslation[canvasViewMode].x) *
                    this.props.scale,
                top:
                    (shapeElement.nodePosition[canvasViewMode].y +
                        nodeTranslation[canvasViewMode].y) *
                    this.props.scale,
                right:
                    (shapeElement.nodePosition[canvasViewMode].x +
                        nodeTranslation[canvasViewMode].x) *
                        this.props.scale +
                    shapeElementSize.width,
                bottom:
                    (shapeElement.nodePosition[canvasViewMode].y +
                        nodeTranslation[canvasViewMode].y) *
                        this.props.scale +
                    shapeElementSize.height,
            },
            nodeTranslation[canvasViewMode].angle
        );

        return (
            <>
                <div
                    className="selectable-by-pointer"
                    style={{
                        zIndex: shapeElement.zIndex ?? 50,
                        left:
                            shapeElement.nodePosition[canvasViewMode].x *
                            this.props.scale,
                        top:
                            shapeElement.nodePosition[canvasViewMode].y *
                            this.props.scale,
                        position: "absolute",
                        border: selected ? "1px solid #3B82C9" : "none",
                        boxShadow: shapeElement.shapeStyle?.borderShadow
                            ? "0 6px 13px 0 rgba(21, 33, 56, 0.53)"
                            : "none",
                        borderRadius: borderRadius,
                        ...shapeElementSize,
                        transform: nodeTranslationString,
                    }}
                    onContextMenu={(evt) => {
                        this.props.onContextMenu(
                            evt,
                            {
                                id: this.props.shapeId,
                                type: typeName,
                            },
                            true
                        );
                    }}
                    ref={this.innerRef}
                >
                    <ShapeElementView
                        onContextMenu={(evt) => {
                            this.props.onContextMenu(
                                evt,
                                {
                                    id: this.props.shapeId,
                                    type: typeName,
                                },
                                true
                            );
                        }}
                        scale={this.props.scale}
                        sharedPolicy={this.props.sharedPolicy}
                        frozen={!this.props.canWrite}
                        width={shapeElementSize.width}
                        height={shapeElementSize.height}
                        live={this.props.live}
                        shapeElement={shapeElement}
                        shapeElementId={this.props.shapeId}
                        onChange={(shapeElement) => {
                            this.trackNewPerformance(elements.shape);
                            this.props.canvasTreeStore.updateShapeElementAction(
                                this.props.shapeId,
                                shapeElement as ShapeElement
                            );
                        }}
                        onTrackNewPerformance={this.trackNewPerformance.bind(
                            this
                        )}
                        onDelete={() => {
                            this.trackNewPerformance(elements.shape);
                            this.props.onClearEditing();
                            this.props.canvasTreeStore.deleteShapeElementAction(
                                this.props.shapeId
                            );
                        }}
                        currentModuleId={this.props.currentModuleId}
                        onClick={(_evt) => {
                            if (!this.drag && !this.props.live) {
                                this.trackNewPerformance(elements.shape);
                                //    this.props.onClearEditing();

                                return;
                            }
                            this.drag = false;
                        }}
                    />
                </div>
                {!this.props.live && this.props.canWrite && (
                    <Moveable
                        className={cx(
                            styles.moveable,
                            !selected && styles.moveableNotSelected
                        )}
                        ref={this.moveableRef}
                        draggable={!this.props.live && this.props.canWrite}
                        throttleDrag={0}
                        onDragStart={({ target, clientX, clientY }) => {}}
                        onDrag={({
                            target,
                            beforeDelta,
                            beforeDist,
                            left,
                            top,
                            right,
                            bottom,
                            delta,
                            dist,
                            transform,
                            clientX,
                            clientY,
                        }: OnDrag) => {
                            target!.style.left = `${left}px`;
                            target!.style.top = `${top}px`;
                            this.drag = true;
                            let nearestPoints = this.props.onRebuildSnapLine(
                                {
                                    x: boundingBox.left + dist[0],
                                    y: boundingBox.top + dist[1],
                                    width: boundingBox.right - boundingBox.left,
                                    height:
                                        boundingBox.bottom - boundingBox.top,
                                },
                                {
                                    type: typeName,
                                    id: this.props.shapeId,
                                    groupId: shapeElement.groupId,
                                }
                            );
                            let newPosition = snapElementToPoints(
                                boundingBox.right - boundingBox.left,
                                boundingBox.bottom - boundingBox.top,
                                nearestPoints
                            );
                            if (newPosition.x != null) {
                                target!.style.left = `${
                                    newPosition.x +
                                    (left - boundingBox.left - dist[0])
                                }px`;
                            }
                            if (newPosition.y != null) {
                                target!.style.top = `${
                                    newPosition.y +
                                    (top - boundingBox.top - dist[1])
                                }px`;
                            }
                            this.props.onUpdateSelectionBounds?.();
                        }}
                        onDragEnd={({ target, isDrag, clientX, clientY }) => {
                            if (this.drag) {
                                this.props.onDeleteSnapLine();
                                this.trackNewPerformance(elements.shape);
                                let x =
                                    parseFloat(target.style.left) /
                                    this.props.scale;

                                let y =
                                    parseFloat(target.style.top) /
                                    this.props.scale;

                                let deltaX =
                                    x -
                                    shapeElement.nodePosition[canvasViewMode].x;
                                let deltaY =
                                    y -
                                    shapeElement.nodePosition[canvasViewMode].y;
                                const newNodePosition = {
                                    ...shapeElement.nodePosition,
                                    [canvasViewMode]: {
                                        x,
                                        y,
                                    },
                                };

                                if (!mobileViewWasEdited) {
                                    if (canvasViewMode === "desktop")
                                        newNodePosition["mobile"] = {
                                            x: x * slideWidthRatio,
                                            y,
                                        };
                                    else
                                        this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                }

                                let changes: InnerCanvasChanges = {};
                                this.props.canvasTreeStore.updateShapeElementAction(
                                    this.props.shapeId,
                                    {
                                        nodePosition: newNodePosition,
                                        width:
                                            shapeElement.nodeSize[
                                                canvasViewMode
                                            ].width,
                                        height:
                                            shapeElement.nodeSize[
                                                canvasViewMode
                                            ].height,
                                    },
                                    changes
                                );
                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        x: x,
                                        y: y,
                                        width: shapeElement.width,
                                        height: shapeElement.width,
                                    }
                                );
                                this.props.onMoveGroupSelection(
                                    deltaX,
                                    deltaY,
                                    {
                                        id: this.props.shapeId,
                                        type: typeName,
                                        groupId: shapeElement.groupId,
                                    },
                                    false,
                                    changes
                                );
                                this.props.canvasTreeStore.saveChangesAction(
                                    changes,
                                    true,
                                    true,
                                    false,
                                    this.props.canvasTreeStore.backgroundsState.toJSON(),
                                    BackgroundMode.Update,
                                    false
                                );
                            }
                        }}
                        target={this.innerRef}
                        resizable={
                            !this.props.live && this.props.canWrite && selected
                        }
                        rotatable={
                            !this.props.live && this.props.canWrite && selected
                        }
                        onResize={(e) => {
                            e.target.style.width = `${e.width}px`;
                            e.target.style.height = `${e.height}px`;
                            e.target.style.transform = e.afterTransform;
                        }}
                        onResizeEnd={({ target, isDrag, clientX, clientY }) => {
                            this.trackNewPerformance(elements.shape);
                            const {
                                canvasViewMode,
                            } = this.props.canvasTreeStore;
                            let transfromOptions = getTransformList(
                                target.style.transform
                            );
                            let angle = parseFloat(
                                transfromOptions["rotate"] ?? "0"
                            );
                            let translatePosition = (
                                transfromOptions["translate"] ?? "0px, 0px"
                            )
                                .split(",")
                                .map(
                                    (item) =>
                                        parseFloat(item) / this.props.scale
                                );
                            let newTranslation = {
                                ...nodeTranslation,
                                [canvasViewMode]: {
                                    angle: angle,
                                    x: translatePosition[0],
                                    y: translatePosition[1],
                                },
                            };
                            let newSize = {
                                nodeTranslation: newTranslation,
                                nodePosition: {
                                    ...shapeElement.nodePosition,
                                    [canvasViewMode]: {
                                        x:
                                            parseFloat(target.style.left) /
                                            this.props.scale,
                                        y:
                                            parseFloat(target.style.top) /
                                            this.props.scale,
                                    },
                                },
                                nodeSize: {
                                    ...shapeElement.nodeSize,
                                    [canvasViewMode]: {
                                        width:
                                            parseFloat(target.style.width) /
                                            this.props.scale,
                                        height:
                                            parseFloat(target.style.height) /
                                            this.props.scale,
                                    },
                                },
                            };

                            if (!mobileViewWasEdited) {
                                this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                            }

                            this.props.canvasTreeStore.updateShapeElementAction(
                                this.props.shapeId,
                                newSize
                            );

                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                ...newSize.nodePosition[canvasViewMode],
                                ...newSize.nodeSize[canvasViewMode],
                            });
                            this.props.onResize();
                        }}
                        //    onRotateStart={this.state.moveableHelper.onRotateStart}
                        onRotate={this.state.moveableHelper.onRotate}
                        throttleRotate={0}
                        onRotateEnd={({ target, isDrag, clientX, clientY }) => {
                            this.trackNewPerformance(elements.shape);
                            let transfromOptions = getTransformList(
                                target.style.transform
                            );
                            let angle = parseFloat(
                                transfromOptions["rotate"] ?? "0"
                            );
                            let translatePosition = (
                                transfromOptions["translate"] ?? "0px, 0px"
                            )
                                .split(",")
                                .map(
                                    (item) =>
                                        parseFloat(item) / this.props.scale
                                );
                            let newTranslation = {
                                ...nodeTranslation,
                                [canvasViewMode]: {
                                    angle: angle,
                                    // x: angle === 0 ? 0 : translatePosition[0],
                                    // y: angle === 0 ? 0 : translatePosition[1],
                                    x: translatePosition[0],
                                    y: translatePosition[1],
                                },
                            };
                            // let nodePosition = shapeElement.nodePosition;
                            // if (angle === 0) {
                            //     nodePosition[canvasViewMode].x +=
                            //         nodeTranslation[canvasViewMode].x;
                            //     nodePosition[canvasViewMode].y +=
                            //         nodeTranslation[canvasViewMode].y;
                            // }
                            this.props.canvasTreeStore.updateShapeElementAction(
                                this.props.shapeId,
                                {
                                    // nodePosition: nodePosition,
                                    nodeTranslation: newTranslation,
                                }
                            );
                        }}
                    ></Moveable>
                )}
            </>
        );
    }
}

@observer
class ShapeElements extends Component<HtmlElementProps> {
    public render(): JSX.Element {
        let shapeUIs: JSX.Element[] = [];
        for (let shapeId of this.props.canvasTreeStore.shapeElementsState.keys())
            shapeUIs.push(
                <ShapeElementWrapper
                    rootDataTestId={`shape-${shapeUIs.length + 1}`}
                    key={shapeId}
                    shapeId={shapeId}
                    {...this.props}
                />
            );
        return <>{shapeUIs}</>;
    }
}

export default ShapeElements;
