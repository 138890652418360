import React from "react";
import cx from "classnames";
import { Button } from "react-bootstrap";
import { MapFinding } from "common/Finding";
import EditInput from "../../EditInput";
import styles from "./HeatmapDataTable.module.css";
import commonStyles from "../../DataSection.module.css";

interface Props {
    mapFinding: MapFinding;
    onChange: (finding: MapFinding, updateData?: boolean) => void;
    currentModuleId?: number;
    style?: React.CSSProperties;
}

function HeatmapDataTable(props: Props): JSX.Element {
    let cols = 3;
    return (
        <div className="my-row" style={props.style}>
            <div
                className={styles.dataContainer}
                style={{
                    width: "calc(100% - 30px)",
                    gridTemplateColumns: `repeat(${cols}, calc(100%/${cols}))`,
                }}
            >
                <div className={commonStyles.header}>Latitude</div>
                <div className={commonStyles.header}>Longitude</div>
                <div className={commonStyles.header}>Metric</div>
                {props.mapFinding.content.heatMapData?.map((item, index) => (
                    <>
                        <EditInput
                            showDeleteButton={false}
                            disabled={false}
                            value={item[0]}
                            onChange={(value) => {
                                let finding: MapFinding = {
                                    ...props.mapFinding,
                                    content: {
                                        ...props.mapFinding.content,
                                        heatMapData: [
                                            ...(props.mapFinding.content
                                                .heatMapData ?? []),
                                        ],
                                    },
                                };
                                finding.content.heatMapData![index] = [
                                    Number(value),
                                    finding.content.heatMapData![index][1],
                                    finding.content.heatMapData![index][2],
                                ];
                                props.onChange(finding);
                            }}
                        />
                        <EditInput
                            disabled={false}
                            showDeleteButton={false}
                            value={item[1]}
                            onChange={(value) => {
                                let finding: MapFinding = {
                                    ...props.mapFinding,
                                    content: {
                                        ...props.mapFinding.content,
                                        heatMapData: [
                                            ...(props.mapFinding.content
                                                .heatMapData ?? []),
                                        ],
                                    },
                                };
                                finding.content.heatMapData![index] = [
                                    finding.content.heatMapData![index][0],
                                    Number(value),
                                    finding.content.heatMapData![index][2],
                                ];
                                props.onChange(finding);
                            }}
                        />
                        <EditInput
                            disabled={false}
                            showDeleteButton={true}
                            value={item[2]}
                            onChange={(value) => {
                                let finding: MapFinding = {
                                    ...props.mapFinding,
                                    content: {
                                        ...props.mapFinding.content,
                                        heatMapData: [
                                            ...(props.mapFinding.content
                                                .heatMapData ?? []),
                                        ],
                                    },
                                };
                                finding.content.heatMapData![index] = [
                                    finding.content.heatMapData![index][0],
                                    finding.content.heatMapData![index][1],
                                    Number(value),
                                ];
                                props.onChange(finding);
                            }}
                            onDelete={() => {
                                let finding: MapFinding = {
                                    ...props.mapFinding,
                                    content: {
                                        ...props.mapFinding.content,
                                        heatMapData: [
                                            ...(props.mapFinding.content
                                                .heatMapData ?? []),
                                        ],
                                    },
                                };
                                finding.content.heatMapData!.splice(index, 1);
                                props.onChange(finding);
                            }}
                        />
                    </>
                ))}
            </div>
            <Button
                className={cx(
                    "btn btn-sm btn-primary my-primary",
                    commonStyles.addColumnButton
                )}
                onClick={() => {
                    let finding: MapFinding = {
                        ...props.mapFinding,
                        content: {
                            ...props.mapFinding.content,
                            heatMapData: [
                                ...(props.mapFinding.content.heatMapData ?? []),
                            ],
                        },
                    };
                    finding.content.heatMapData!.push([0, 0, 1]);
                    props.onChange(finding);
                }}
            >
                {"\uFF0B"}
            </Button>
        </div>
    );
}

export default HeatmapDataTable;
