import React, { useEffect, useState } from "react";
import { Column } from "common/graphics/MetricsTable";
import styles from "./MetricsTable.module.css";
import { LeadersLaggersFinding } from "common/Finding";
import { LeadersLaggersFontStyle } from "common/Canvas";

interface Props {
    column: Column;
    config: LeadersLaggersFinding["config"];
    currentEditableVariableIndex: number | null;
    setCurrentEditableVariableIndex: React.Dispatch<
        React.SetStateAction<number | null>
    >;
    onChangeConfig?: (config: any, updateFinding?: boolean) => void;
    fontStyle?: LeadersLaggersFontStyle;
    editable?: boolean;
    noHeaderField?: boolean;
}

function SubTitle(props: Props) {
    const {
        editable,
        noHeaderField,
        column,
        config,
        onChangeConfig,
        fontStyle,
        currentEditableVariableIndex,
        setCurrentEditableVariableIndex,
    } = props;
    const [value, setValue] = useState(column.subTitle);

    useEffect(() => {
        setValue(column.subTitle);
    }, [column.subTitle]);

    return (
        <input
            disabled={!editable || noHeaderField}
            placeholder={editable && !noHeaderField ? "Sub Title" : ""}
            value={value}
            type="text"
            className={styles.subTitle}
            style={
                !noHeaderField
                    ? {
                          width: "100%",
                          height: 20,
                          marginLeft: 0,
                          ...fontStyle?.title,
                          fontSize: 12,
                          fontWeight: 100,
                      }
                    : {
                          width: 20,
                          border: "none",
                          backgroundColor: "transparent",
                      }
            }
            onFocus={() => {
                setCurrentEditableVariableIndex(column.index);
            }}
            onChange={(e) => {
                setValue(e.target.value);
            }}
            onBlur={(e) => {
                if (currentEditableVariableIndex === null) return;
                let newConfig = {
                    ...config,
                };

                if (currentEditableVariableIndex === -1) {
                    newConfig.targetVariableSubTitle = value;
                } else {
                    newConfig.showedVariablesSubTitles[
                        currentEditableVariableIndex
                    ] = value;
                }

                onChangeConfig?.(newConfig, true);
            }}
            onKeyDown={(e) => {
                e.stopPropagation();
            }}
        />
    );
}

export default SubTitle;
