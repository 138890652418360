import Variables from "common/Variables";
import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";

class Api {
    static getFinding(props) {
        let { data, config, dataKeys, error } = props;
        let item = {
            type: "10",
            content: {
                data: data,
                error: error,
                dataKeys: dataKeys,
            },
            config: config,
        };
        return item;
    }
    static getData(config, findingOptions, moduleId) {
        let {
            groupByAll,
            dataScope,
            errorFormat,
            selectedTargetVariables,
            additionalOperators,
            additionalValues,
            selectedTargetVariablesIndices,
            additionalParameterIndex,
            conditions,
            havingConditions,
            operationVariable,
            selectedTable,
            targetValues,
            additionalParameter,
        } = config;

        additionalOperators = additionalOperators.filter(
            (_, index) => additionalValues[index]?.value != null
        );

        additionalValues = additionalValues
            .filter((value) => value?.value != null)
            .map((value) => value.value);
        if (targetValues != null) {
            targetValues = targetValues
                .filter((value) => value?.value != null)
                .map((value) => value.value);
        }

        conditions = filterNonEmptyConditions(conditions ?? []);
        havingConditions = filterNonEmptyConditions(havingConditions ?? []);
        if (targetValues != null) {
            for (let [index, targetValue] of targetValues.entries()) {
                conditions.push({
                    operation: {
                        value: "=",
                    },
                    leftBracket:
                        index === 0
                            ? {
                                  value: "(",
                              }
                            : null,
                    rightBracket:
                        index === targetValues.length - 1
                            ? {
                                  value: ")",
                              }
                            : null,
                    logical: { value: index === 0 ? "and" : "or" },
                    value: { value: targetValue },
                    variable: { value: selectedTargetVariablesIndices[0] },
                });
            }
        }
        conditions = conditionsToJson(conditions);
        let requestJson = {
            group_operation: operationVariable,
            variable_indices: selectedTargetVariablesIndices,
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            conditions: conditions,
            error_format: errorFormat ?? undefined,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            module_id: moduleId,
        };
        if (
            additionalParameter &&
            (additionalValues.length > 0 || groupByAll)
        ) {
            if (groupByAll) {
                requestJson.having_conditions = conditionsToJson(havingConditions);
            }
            requestJson.where = {
                group_index: additionalParameterIndex,
                expressions: groupByAll
                    ? []
                    : additionalValues.map((value, index) => ({
                          operation: additionalOperators[index]?.value ?? "=",
                          value: value,
                      })),
            };
        }
        return axios
            .post("/api/e/compare_variables", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let dataComparison = response.data.compare_data[0];
                    let barKeys = dataComparison.map((item) =>
                        item.where
                            ? //    ? `${item.where.group_name} ${item.where.operation} ${item.where.value}`
                              `${item.where.value}`
                            : "overall"
                    );
                    let data;
                    let error;
                    if (operationVariable === "count_column") {
                        let allKeys = [];
                        dataComparison.forEach((item, index) => {
                            let keys = Object.keys(item.values);
                            allKeys = allKeys.concat(keys);
                        });
                        allKeys = [...new Set(allKeys)];
                        data = allKeys.map((item) => ({
                            name: item.toUpperCase(),
                            units: "",
                        }));
                        dataComparison.forEach((item, index) => {
                            allKeys.forEach((key, valIndex) => {
                                data[valIndex][barKeys[index]] =
                                    item.values[key] ?? 0;
                            });
                        });
                    } else if (operationVariable === "mean") {
                        data = selectedTargetVariables.map((item) => ({
                            name: item.toUpperCase(),
                            units: Variables(
                                requestJson.data_table_idx,
                                moduleId
                            ).getUnits(item),
                        }));
                        error = selectedTargetVariables.map((item) => ({
                            name: item.toUpperCase(),
                        }));

                        dataComparison.forEach((item, index) => {
                            selectedTargetVariablesIndices.forEach(
                                (variableIndex, valIndex) => {
                                    data[valIndex][barKeys[index]] =
                                        item.values[variableIndex];
                                    if (item.errors != null) {
                                        error[valIndex][barKeys[index]] = [
                                            item.errors[variableIndex],
                                            item.errors[variableIndex],
                                        ];
                                    } else {
                                        error = undefined;
                                    }
                                }
                            );
                        });
                    } else {
                        data = selectedTargetVariables.map((item) => ({
                            name: item.toUpperCase(),
                            units: Variables(
                                requestJson.data_table_idx,
                                moduleId
                            ).getUnits(item),
                        }));

                        dataComparison.forEach((item, index) => {
                            selectedTargetVariablesIndices.forEach(
                                (variableIndex, valIndex) => {
                                    data[valIndex][barKeys[index]] =
                                        item.values[variableIndex];
                                }
                            );
                        });
                    }
                    let finding = Api.getFinding({
                        dataKeys: barKeys,
                        data: data,
                        error: error,
                        config: config,
                    });
                    return Promise.resolve(finding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
