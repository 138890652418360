import axios from "common/ServerConnection";
import { UserType } from "common/UserInfo";

export async function getTosText(
    userType: UserType
): Promise<{
    tosText: string | null;
    lastUpdated: Date | null;
}> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            tos_text: string | null;
            last_updated: Date | null;
        }>("/api/tos_text_get", { user_type: userType })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            } else {
                return {
                    tosText: response.data.tos_text,
                    lastUpdated: response.data.last_updated,
                };
            }
        });
}

export async function getTosTextForCurrentUser(): Promise<{
    tosText: string | null;
    lastUpdated: Date | null;
}> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            tos_text: string | null;
            last_updated: Date | null;
        }>("/api/tos_text_get_for_current_user")
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            } else {
                return {
                    tosText: response.data.tos_text,
                    lastUpdated: response.data.last_updated,
                };
            }
        });
}

export async function changeOrDeleteTosText(
    userType: UserType,
    tosText?: File
): Promise<void> {
    let formData = new FormData();
    formData.append("metadata", JSON.stringify({ user_type: userType }));
    if (tosText != null) {
        formData.append("tos_text", tosText);
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/tos_text_change_or_delete", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function checkTosUploaded(): Promise<Set<UserType>> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            user_types: number[];
        }>("/api/tos_text_check_uploaded")
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            } else {
                return Promise.resolve(new Set(response.data.user_types));
            }
        });
}
