import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

import axios from "common/ServerConnection";
import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";
import { Permission, UserType, AccessType, userTypesOrdered } from "../../common/UserType";
import { mainStyle } from "common/MainStyle";

// Key description
interface Description {
    display_name?: string;
    description?: string;
    requires?: Requirement;
}

// Key requirements
type Requirement =
    | null
    | undefined
    | string
    | { type: string; permissions: Requirement[] };

interface State {
    status: UpdateStatus;
    errorMessage: string;
    descriptions: { [key: string]: Description };
    permissions: { [key: string]: Permission };
    renderingOrder: { indent: number; key: string }[];
    keyExpanded: Set<string>;
    expanded: boolean;
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
            descriptions: {},
            permissions: {},
            renderingOrder: [],
            keyExpanded: new Set<string>(),
            expanded: false,
        };
        this.performance = null;

        this.getPermissions = this.getPermissions.bind(this);
        this.setPermissions = this.setPermissions.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    private checkRequirement(
        requirement: Requirement,
        userType: UserType
    ): boolean {
        if (typeof requirement === "string") {  
            return (
                this.state.permissions[requirement][userType] === AccessType.ACCESS &&
                this.checkRequirement(
                    this.state.descriptions[requirement]?.requires,
                    userType
                    )
                    );
                } else if (requirement != null) {
                    let f =
                requirement.type === "Any"
                    ? Array.prototype.some
                    : Array.prototype.every;
            return f.call(requirement.permissions, (permission) =>
                this.checkRequirement(permission, userType)
            );
        } else {
            return true;
        }
    }

    private requirementToString(
        requirement: Requirement,
        descriptions: {
            [key: string]: Description;
        }
    ): string {
        if (requirement == null) {
            return "";
        } else if (typeof requirement === "string") {
            return descriptions[requirement]?.display_name ?? requirement;
        } else {
            return `(${requirement.permissions
                .map((permission) =>
                    this.requirementToString(permission, descriptions)
                )
                .join(requirement.type === "All" ? " and " : " or ")})`;
        }
    }

    private getRenderingOrderInner(
        key: string,
        adjacency: { [key: string]: string[] },
        renderingOrder: { indent: number; key: string }[],
        indent: number
    ): void {
        renderingOrder.push({ indent: indent, key: key });
        for (let nextKey of adjacency[key]) {
            this.getRenderingOrderInner(
                nextKey,
                adjacency,
                renderingOrder,
                indent + 1
            );
        }
    }

    private getRenderingOrder(descriptions: {
        [key: string]: Description;
    }): { indent: number; key: string }[] {
        // Reverse edges in the graph
        let adjacency: { [key: string]: string[] } = {};
        let rootNodes: string[] = [];
        for (let [key, description] of Object.entries(descriptions)) {
            adjacency[key] = adjacency[key] ?? [];
            if (description.requires == null) {
                rootNodes.push(key);
            } else if (typeof description.requires === "string") {
                adjacency[description.requires] =
                    adjacency[description.requires] ?? [];
                adjacency[description.requires].push(key);
            } else {
                let str = this.requirementToString(
                    description.requires,
                    descriptions
                );
                // Remove parentheses
                str = str.slice(1, str.length - 1);
                if (!(str in adjacency)) {
                    adjacency[str] = [];
                    rootNodes.push(str);
                }
                adjacency[str].push(key);
            }
        }
        rootNodes.sort();
        // Convert it to array
        let renderingOrder: { indent: number; key: string }[] = [];
        for (let key of rootNodes) {
            this.getRenderingOrderInner(key, adjacency, renderingOrder, 0);
        }
        return renderingOrder;
    }
    
    private getPermissions(): void {
        this.performance = new Date();
        axios
            .post<{
                success: boolean;
                error_msg: string;
                permissions: { [key: string]: Permission };
                descriptions: { [key: string]: Description };
            }>("/api/get_permissions", {})
            .then((response) => {
                if (response.data.success) {
                    let renderingOrder = this.getRenderingOrder(
                        response.data.descriptions
                    );
                    this.setState({
                        descriptions: response.data.descriptions,
                        permissions: response.data.permissions,
                        renderingOrder: renderingOrder,
                        status: UpdateStatus.NotUploaded,
                        errorMessage: "",
                    });
                } else {
                    this.setState({
                        status: UpdateStatus.Error,
                        errorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    status: UpdateStatus.Error,
                    errorMessage: `${error.response.status} ${error.response.statusText}`,
                });
            });
    }

    private setPermissions(): void {
        
        this.performance = new Date();
        this.setState({
            status: UpdateStatus.Loading,
            errorMessage: "",
        });

        const permissions = Object.keys(this.state.permissions)
            .reduce((newObj, permKey) =>
                ({...newObj, [permKey]: [UserType.Admin, UserType.Creator, UserType.User1, UserType.User2, UserType.Viewer]
                    .map(group => ({group, access_type: this.state.permissions[permKey][group] || 0}))
                }), {});

        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/set_permissions", { permissions })
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        status: UpdateStatus.Success,
                        errorMessage: "",
                    });
                } else {
                    this.setState({
                        status: UpdateStatus.Error,
                        errorMessage: response.data.error_msg,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    status: UpdateStatus.Error,
                    errorMessage: `${error.response.status} ${error.response.statusText}`,
                });
            });
    }

    private renderItems(): JSX.Element[] {
        let items: JSX.Element[] = [];
        let index: number = 0;
        let prevIndex: number = 0;
        while (index < this.state.renderingOrder.length) {
            let { indent, key } = this.state.renderingOrder[index];
            let { indent: prevIndent, key: prevKey } = this.state
                .renderingOrder[prevIndex] ?? { indent: 0, key: undefined };
            if (indent > prevIndent && !this.state.keyExpanded.has(prevKey)) {
                while (
                    indent > prevIndent &&
                    index < this.state.renderingOrder.length
                ) {
                    index += 1;
                    indent = this.state.renderingOrder[index]?.indent;
                }
            } else {
                let expandable: boolean =
                    (this.state.renderingOrder[index + 1]?.indent ?? indent) >
                    indent;
                let expanded: boolean = this.state.keyExpanded.has(key);
                items.push(
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            className="regular-text"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "19px",
                                paddingLeft: 15 * (indent + 1),
                                marginRight: "15px",
                                minWidth: "20em",
                                cursor: expandable ? "pointer" : undefined,
                            }}
                            onClick={() => {
                                if (expandable) {
                                    this.setState((state) => {
                                        let newSet = new Set<string>(
                                            state.keyExpanded
                                        );
                                        if (newSet.has(key)) {
                                            newSet.delete(key);
                                        } else {
                                            newSet.add(key);
                                        }
                                        return {
                                            keyExpanded: newSet,
                                        };
                                    });
                                }
                            }}
                        >
                            <div
                                style={{
                                    width: 15 * indent,
                                    minWidth: 15 * indent,
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 15,
                                    minWidth: 15,
                                }}
                            >
                                {expandable && (
                                    <i
                                        className={`fa fa-angle-${
                                            expanded ? "down" : "right"
                                        }`}
                                    />
                                )}
                            </div>
                            <span className="regular-text">
                                {this.state.descriptions[key]?.display_name ??
                                    key}
                            </span>
                            {this.state.descriptions[key]?.description !=
                                null && (
                                <>
                                    <div
                                        className="regular-text unselectable"
                                        data-tip={
                                            this.state.descriptions[key]
                                                ?.description
                                        }
                                        data-for={`tooltip-${key}`}
                                        data-event="click"
                                        data-eventOff="scroll mousewheel"
                                        data-effect="solid"
                                        data-isCapture={true}
                                        style={{
                                            color: mainStyle.getPropertyValue(
                                                "--secondary-text-color"
                                            ),
                                            textDecoration: "underline",
                                            marginLeft: 1,
                                            cursor: "pointer",
                                        }}
                                    >
                                        ?
                                    </div>
                                    <ReactTooltip id={`tooltip-${key}`} />
                                </>
                            )}
                        </div>
                        {key in this.state.permissions &&
                            this.renderItemToggles(key, index)}
                    </div>
                );
                prevIndex = index;
                index += 1;
            }
        }
        return items;
    }

    private renderItemToggles(key: string, index: number): JSX.Element[] {
        let toggles: JSX.Element[] = [];
        for (let userType of userTypesOrdered) {
            toggles.push(
                <div
                    key={`${userType}_${index}`}
                    title={userType}
                    className="regular-text"
                    style={{
                        marginRight: "15px",
                        minWidth: "10em",
                    }}
                >
                    <select
                        value={this.state.permissions[key][userType]}
                        disabled={!this.checkRequirement(
                            this.state.descriptions[key].requires,
                            userType
                        )}
                        style={{
                            padding: 10,
                            width: '100%',
                            borderRadius: 4,
                            border: '1px solid #CCC',
                            backgroundColor: this.state.permissions[key][userType] === AccessType.ACCESS ? '#B4D3B2' : this.state.permissions[key][userType] === AccessType.VIEW ? '#80CEE1' : '#FF6961'
                        }}
                        onChange={(e) => {
                            this.setState((state) => {
                                return {
                                    permissions: {
                                        ...Object.keys(state.permissions).reduce((obj, k) => {
                                            if(this.state.descriptions[k].requires === key) {
                                                return {...obj, [k]: {...state.permissions[k], [userType]: Number.parseInt(e.target.value) > 0 ? 1 : 0}}
                                            }
                                            return obj;
                                        }, state.permissions),
                                        [key]: {
                                            ...this.state.permissions[key],
                                            [userType]: Number.parseInt(e.target.value)
                                        },
                                    },
                                };
                            });
                        }}
                    >
                        <option style={{backgroundColor: '#FF6961'}} label="No Access" value={AccessType.NO_ACCESS} />
                        <option style={{backgroundColor: '#B4D3B2'}} label="Access" value={AccessType.ACCESS} />
                        <option style={{backgroundColor: '#80CEE1'}} label="View" value={AccessType.VIEW} />
                    </select>
                    {/* <Switch
                        onChange={(checked) => {
                            this.setState((state) => {
                                let changedPermission = new Set(
                                    state.permissions[key]
                                );
                                if (checked) changedPermission.add(userType);
                                else changedPermission.delete(userType);
                                return {
                                    permissions: {
                                        ...state.permissions,
                                        [key]: changedPermission,
                                    },
                                };
                            });
                        }}
                        checked={
                            this.state.permissions[key].has(userType) &&
                            this.checkRequirement(
                                this.state.descriptions[key].requires,
                                userType
                            )
                        }
                        width={40}
                        height={20}
                        offColor="#c3c3c3"
                        onColor="#ccc"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offHandleColor="#70889E"
                        onHandleColor="#1a60b2"
                    /> */}
                </div>
            );
        }
        return toggles;
    }

    private toggleExpanded(): void {
        this.setState((state) => ({
            expanded: !state.expanded,
        }));
    }

    componentDidMount() {
        let performance = this.performance;
        this.getPermissions();
        this.performance = performance; // We don't need to track performance of the initial request
    }

    componentDidUpdate() {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    render() {
        return (
            <div style={{ marginTop: 20 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                    }}
                    onClick={this.toggleExpanded}
                >
                    <div
                        className="regular-text"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 25,
                        }}
                    >
                        <i
                            className={`fa fa-2x fa-angle-${
                                this.state.expanded ? "down" : "right"
                            }`}
                        />
                    </div>
                    <span className="big-title-span">Access Control</span>
                </div>
                {this.state.expanded && (
                    <div
                        style={{ marginTop: 10, width: 500 }}
                        className="flex-simple-column"
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                className="regular-text"
                                style={{
                                    marginLeft: "19px",
                                    paddingLeft: "15px",
                                    marginRight: "15px",
                                    minWidth: "20em",
                                }}
                            />
                            {userTypesOrdered.map((userType) => (
                                <div
                                    key={`${userType}_header`}
                                    className="regular-text"
                                    style={{
                                        marginRight: "15px",
                                        minWidth: "10em",
                                    }}
                                >
                                    {userType}
                                </div>
                            ))}
                        </div>
                        {this.renderItems()}
                        <Button
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginLeft: "19px",
                                marginBottom: 10,
                                width: 200,
                            }}
                            onClick={this.setPermissions}
                        >
                            Update
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginLeft: "19px",
                                marginBottom: 10,
                                width: 200,
                            }}
                            onClick={this.getPermissions as () => void}
                        >
                            Reset
                        </Button>
                        <UpdateStatusAlert
                            value={this.state.status}
                            onChange={(status) => {
                                this.setState({ status: status });
                            }}
                            errorMessage={this.state.errorMessage}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export { MainComponent };
export let requirePermission = "GetSetPermissions";
