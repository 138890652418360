import React, { useEffect, useState } from "react";
import styles from "./main.module.css";
import cx from "classnames";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import MessagePopup from "common/MessagePopup";
import {
    addCategoryApi,
    editCategoryApi,
    deleteCategoryApi,
    getCategoriesApi,
    Category,
} from "common/CategoriesApi";
import {
    getCustomSelectStyleForDataSection,
    Styles,
} from "common/SelectStyles";
import Dropzone from "react-dropzone";
import { toBase64 } from "common/utilities/retrieveImageFromClipboard";
import { ReactComponent as ReorderArrowIcon } from "icons/reorder-category-arrow.svg";

interface DropFileComponentProps {
    disabled: boolean;
    url: string;
    onDrop: (url: string) => void;
}

function DropFileComponent(props: DropFileComponentProps) {
    return (
        <div className={styles.dropzoneContainer}>
            <Dropzone
                disabled={props.disabled}
                onDrop={async (acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                        let url = await toBase64(acceptedFiles[0]);
                        props.onDrop(url);
                    }
                }}
            >
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                        {...getRootProps()}
                        className={cx(
                            styles.fileContainer,
                            styles.fileContainerDragArea
                        )}
                        style={{ position: "relative" }}
                    >
                        {props.url && (
                            <img
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                }}
                                alt=""
                                src={props.url}
                            ></img>
                        )}
                        <div
                            className="flex-simple-column"
                            style={{
                                zIndex: 1,
                                alignItems: "center",
                            }}
                        >
                            {!isDragActive && (
                                <>
                                    <span
                                        style={{
                                            cursor: "default",
                                            color: "#a3a3a3",
                                            fontSize: "20px",
                                            marginBottom: 10,
                                        }}
                                    >
                                        Drop files
                                    </span>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: 1,
                                                width: 40,
                                                backgroundColor: "#a3a3a3",
                                            }}
                                        />
                                        <span
                                            style={{
                                                margin: "0 15px",
                                                color: "#a3a3a3",
                                            }}
                                        >
                                            or
                                        </span>
                                        <div
                                            style={{
                                                height: 1,
                                                width: 40,
                                                backgroundColor: "#a3a3a3",
                                            }}
                                        />
                                    </div>
                                    <span
                                        style={{
                                            margin: "0 15px",
                                            color: "#a3a3a3",
                                        }}
                                    >
                                        click
                                    </span>
                                </>
                            )}
                            {isDragActive && (
                                <span
                                    style={{
                                        cursor: "pointer",
                                        marginTop: "6px",
                                        color: "#869AAC",
                                        fontSize: "15px",
                                    }}
                                >
                                    Drop File Here
                                </span>
                            )}
                        </div>
                        <input {...getInputProps()} />
                    </div>
                )}
            </Dropzone>
            <span
                style={{
                    marginTop: "10px",
                    color: "#a3a3a3",
                }}
            >
                or past an url
            </span>
            <div
                className={styles.searchContainer}
                style={{ marginTop: "10px" }}
            >
                <input
                    placeholder="Icon url"
                    type="text"
                    value={props.url}
                    onChange={(evt) => {
                        let value = evt.target.value;
                        props.onDrop(value);
                    }}
                    className={styles.searchInput}
                />
            </div>
        </div>
    );
}

function MainComponent() {
    async function addNewCategory() {
        try {
            let newCollectionId = await addCategoryApi(
                newCategoryText,
                newCategoryThumbnail
            );
            setCategories((categories) => [
                ...categories,
                {
                    id: newCollectionId,
                    title: newCategoryText,
                    thumbnail: newCategoryThumbnail,
                    position: categories.length,
                },
            ]);
            setNewCategoryText("");
            setNewCategoryThumbnail("");

            setStatusPopup({
                status: PopupStatus.Success,
                message: "New category is added",
            });
        } catch (error) {
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    }

    async function renameCategory(
        categoryId: number,
        newTitle?: string,
        newThumbnail?: string
    ) {
        try {
            let newTitleNotEmpty = newTitle || undefined;
            let newThumbnailNotEmpty = newThumbnail || undefined;
            await editCategoryApi(
                categoryId,
                newTitleNotEmpty,
                newThumbnailNotEmpty
            );
            setCategories((categories) => {
                let newCategories = Array.from(categories);
                let index = categories.findIndex(
                    (item) => item.id === categoryId
                );
                if (index !== -1) {
                    if (newTitleNotEmpty != null)
                        newCategories[index].title = newTitleNotEmpty;
                    if (newThumbnailNotEmpty != null)
                        newCategories[index].thumbnail = newThumbnailNotEmpty;
                }
                return newCategories;
            });
            setCategoryRename(null);
            setStatusPopup({
                status: PopupStatus.Success,
                message: "Changes are applied",
            });
        } catch (error) {
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    }

    async function deleteCategory(categoryId: number) {
        try {
            await deleteCategoryApi(categoryId);
            setCategoryRemove(null);
            setCategories((categories) =>
                categories.filter((item) => item.id !== categoryId)
            );

            setStatusPopup({
                status: PopupStatus.Success,
                message: "Collection is deleted",
            });
        } catch (error) {
            setCategoryRemove(null);
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    }

    async function reorderCategory(
        category: Category,
        neighborCategory: Category,
        positionModifier: number,
        neighborPositionModifier: number,
    ) {
        await editCategoryApi(
            category.id,
            category.title,
            category.thumbnail,
            category.position - positionModifier,
        );
        await editCategoryApi(
            neighborCategory.id,
            neighborCategory.title,
            neighborCategory.thumbnail,
            neighborCategory.position - neighborPositionModifier,
        );
        getCategories();
    }

    let mediaCollectionsSelectorStyles: Styles = {
        ...getCustomSelectStyleForDataSection(14, false),
        container: (base) => ({
            ...base,
            marginLeft: "10px",
            width: "120px",
            height: "35px",
        }),
    };
    async function getCategories() {
        try {
            let categories = await getCategoriesApi();
            setCategories(categories);
        } catch (e) {
            console.log(e);
        }
    }
    let [categories, setCategories] = useState<Category[]>([]);
    useEffect(() => {
        getCategories();
    }, []);
    let [newCategoryText, setNewCategoryText] = useState<string>("");
    let [newCategoryThumbnail, setNewCategoryThumbnail] = useState<string>("");

    let [uploading, setUploading] = useState<boolean>(false);

    let [categoryRemove, setCategoryRemove] = useState<Category | null>(null);
    let [categoryRename, setCategoryRename] = React.useState<{
        id: number;
        newTitle: string;
        newThumbnail: string;
    } | null>(null);

    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);

    let editInputRef = React.useRef<HTMLInputElement>(null);
    const makeDefault = () => {
        setCategoryRemove(null);
        setCategoryRename(null);
        setStatusPopup(null);
    };

    return (
        <div style={{ flexGrow: 1 }}>
            <span className="big-title-span">Home Categories</span>
            <div className={styles.toolbarContainer}>
                <div className={styles.gridTitle}>Name</div>
                <div className={styles.searchContainer}>
                    <input
                        placeholder="New category"
                        type="text"
                        value={newCategoryText}
                        onChange={(evt) => {
                            let value = evt.target.value;
                            setNewCategoryText(value);
                        }}
                        className={styles.searchInput}
                    />
                </div>
                <div className={styles.gridTitle}>Thumbnail</div>
                <DropFileComponent
                    disabled={uploading}
                    url={newCategoryThumbnail}
                    onDrop={(url) => {
                        setNewCategoryThumbnail(url);
                    }}
                />
                <button
                    disabled={!newCategoryText || !newCategoryThumbnail}
                    className={styles.manageButton}
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                    onClick={() => {
                        addNewCategory();
                    }}
                >
                    Create
                </button>

                <div style={{ flexGrow: 1 }}></div>
            </div>
            <div className={styles.borderLine}></div>
            <div className={styles.grid}>
                <div className={styles.gridRow} style={{ paddingLeft: 53 }}>
                    <div style={{ width: "30%" }} className={styles.gridTitle}>
                        Name
                    </div>
                    <div style={{ width: "30%" }} className={styles.gridTitle}>
                        Thumbnail
                    </div>
                </div>
                {categories.map((category, index) => (
                    <React.Fragment key={index}>
                        <div style={{ display: "flex", width: "100%" }}>
                            <div
                                style={{
                                    width: 53,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            ></div>
                            <div className={styles.gridRow}>
                                <div
                                    style={{ width: "30%" }}
                                    className={styles.gridItem}
                                >
                                    {categoryRename?.id === category.id ? (
                                        <input
                                            ref={editInputRef}
                                            className={styles.editInput}
                                            onChange={(evt) => {
                                                evt.stopPropagation();
                                                let newTitle = evt.target.value;
                                                setCategoryRename(
                                                    (categoryRename) => ({
                                                        ...categoryRename!,
                                                        newTitle: newTitle,
                                                    })
                                                );
                                            }}
                                            value={categoryRename.newTitle}
                                            onKeyDown={(evt) => {
                                                if (evt.key === "Escape") {
                                                    evt.preventDefault();
                                                    evt.stopPropagation();
                                                    makeDefault();
                                                }
                                            }}
                                        ></input>
                                    ) : (
                                        category.title
                                    )}
                                </div>
                                <div
                                    style={{ width: "30%" }}
                                    className={styles.gridItem}
                                >
                                    {categoryRename?.id === category.id ? (
                                        <DropFileComponent
                                            disabled={uploading}
                                            url={categoryRename.newThumbnail}
                                            onDrop={(url) => {
                                                setCategoryRename(
                                                    (categoryRename) => ({
                                                        ...categoryRename!,
                                                        newThumbnail: url,
                                                    })
                                                );
                                            }}
                                        />
                                    ) : (
                                        <div className={styles.fileContainer}>
                                            <img
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                                alt=""
                                                src={category.thumbnail}
                                            ></img>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.manageButtonContainer}>
                                    <button
                                        disabled={
                                            categoryRemove != null ||
                                            categoryRename != null
                                        }
                                        className={styles.manageButton}
                                        onClick={(evt) => {
                                            setCategoryRemove(category);
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                                <div className={styles.manageButtonContainer}>
                                    <button
                                        disabled={
                                            categoryRemove != null ||
                                            (categoryRename != null &&
                                                categoryRename.id !==
                                                    category.id)
                                        }
                                        className={styles.manageButton}
                                        onClick={(evt) => {
                                            if (
                                                categoryRename != null &&
                                                categoryRename.id ===
                                                    category.id
                                            ) {
                                                renameCategory(
                                                    categoryRename!.id,
                                                    categoryRename!.newTitle,
                                                    categoryRename!.newThumbnail
                                                );
                                            } else {
                                                makeDefault();
                                                setCategoryRename({
                                                    id: category.id,
                                                    newTitle: category.title,
                                                    newThumbnail:
                                                        category.thumbnail,
                                                });
                                                setTimeout(() => {
                                                    editInputRef.current?.focus();
                                                }, 0);
                                            }
                                        }}
                                    >
                                        {categoryRename?.id === category.id
                                            ? "Apply"
                                            : "Edit"}
                                    </button>
                                </div>
                                <div className={styles.reorderCategory}>
                                    {index !== 0 && (
                                        <button
                                            className={cx(styles.reorderCategoryArrow, styles.reorderCategoryArrowUp)}
                                            onClick={async () => {
                                                const neighborCategory = categories[index - 1];
                                                reorderCategory(category, neighborCategory, 1, -1);
                                            }}
                                        >
                                            <ReorderArrowIcon />
                                        </button>
                                    )}
                                    {index !== categories.length - 1 && (
                                        <button
                                            className={styles.reorderCategoryArrow}
                                            onClick={async () => {
                                                const neighborCategory = categories[index + 1];
                                                reorderCategory(category, neighborCategory, -1, 1);
                                            }}
                                        >
                                            <ReorderArrowIcon />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                        {index < categories.length - 1 && (
                            <div className={styles.borderLine}></div>
                        )}
                    </React.Fragment>
                ))}
            </div>
            {categoryRemove != null && (
                <MessagePopup
                    title="Remove category"
                    message={`Do you want to remove ${categoryRemove.title}?`}
                    onAccept={() => {
                        deleteCategory(categoryRemove!.id);
                    }}
                    onReject={() => {
                        makeDefault();
                    }}
                />
            )}
            {statusPopup != null && (
                <StatusPopup
                    status={statusPopup.status}
                    message={statusPopup.message}
                    onClose={() => {
                        setStatusPopup(null);
                    }}
                />
            )}
        </div>
    );
}

export { MainComponent };
export let requirePermission = "ManageColorThemes";
