import React, {useEffect, useState, useRef} from 'react';
import ShowTyping from "../components/ShowTyping"; 

import { observer } from 'mobx-react-lite';
import { store } from '../stores/BrandsStore';

import cx from "classnames";
import styles  from '../demobot.module.css';  

const Brands: React.FC = observer(() => {
    const inputRef = useRef<HTMLInputElement>(null);
    
    /* Slide out Frame conditionally */
    const [isIframeVisible, setIsIframeVisible] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const [isIframeRendered, setIsIframeRendered] = useState(false);
    const [chatClass, setChatClass] = useState('');
    const [glassClass, setGlassClass] = useState('');

    const handleToggleIframe = () => {
      setIsIframeVisible(!isIframeVisible);
    };
  
    useEffect(() => {
      // Programmatically focus the input field when the component mounts
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const closeIframe = () => {
      setIsIframeVisible(false);
  };

    useEffect(() => {
      // When the input changes, set the animation class to slide in or out based on whether the input is empty or not
     if (isIframeVisible) {
        setIsIframeRendered(true);
        setTimeout(() => {
          setChatClass('move-left');
          setAnimationClass('slide-in');
          setGlassClass('with-shadow');
        },50);
     } else {
        setAnimationClass('slide-out');
        setChatClass('');
        setGlassClass('');
        setTimeout(() => {
          setIsIframeRendered(false);
        },500);
     }
    }, [isIframeVisible]);
  
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        store.setUserInput(event.target.value);
    };
  
    const handleSubmit = async (event: React.KeyboardEvent<HTMLInputElement>) => { 
      if (event.key === 'Enter' && store.userInput.trim()) {
        console.log(store.userInput);
        const userInput = store.userInput;
        store.setUserInput(''); // Clear input field after submitting      
        store.addPrompt(userInput);
        
        if (userInput === 'Show me my expansion priorities' || userInput === 'OK thanks!') {
          handleToggleIframe()
        } else {
          const ollamaResponse = await store.sendMessageToFlask(userInput);    
          console.log("msg=", ollamaResponse.response);
          store.addAnswer(ollamaResponse.response);
          store.setText(ollamaResponse.text);
          console.log("txt=", ollamaResponse.text);
          store.setPic(ollamaResponse.pic);
          store.setVid(ollamaResponse.vid);  
        }          
      }   
    };

    
  
    return (
      <div className={styles.demobotAppContent}>        
        <div className={styles.mainContainer}>
          <div className={cx(styles.chatContainer, styles[chatClass])}>
            <div className={styles.prompt}>
              {store.prompts[store.prompts.length -1 ]}
            </div>        
            <div className={styles.glassTransparent}>        
              { <ShowTyping 
                  msg={ store.answers[store.answers.length -1 ] }
                  txt={ store.textDisplay }
                  pic={ store.picURL }
                  vid={ store.vidURL }
              /> }                           
            </div>        
            <p>
              <input className={styles.slickPrompt}
                  type="text"
                  placeholder="Help me apply this."              
                  value={store.userInput}
                  onChange={handleInputChange}
                  onKeyDown={handleSubmit} // This triggers when we submit or Enter              
                  ref={inputRef}  // Attach the ref to the input to autoFocus programmatically.
              />
            </p>
          </div>
        {isIframeRendered && (        
          <div className={cx(styles.iframeContainer, styles[animationClass])}>
            <button className={styles.closeButton} onClick={closeIframe}>×</button>
            <iframe 
                style={{ width: '100%', height: '100%', border: 'none' }}
                allowFullScreen={true}
                src="https://app.eisengard.ai/shared_canvas_module.html?remote_moduleid=Y9aLusfeTNKkL97a%2BA7pPQ">        
          </iframe>
          </div>
        )}
        </div>        
      </div>        
    );
  
});

export default Brands;