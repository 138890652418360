import React, { Component } from "react";
// import { useDrag, useDrop } from "react-dnd";
import { observer } from "mobx-react";
import Cookies from "universal-cookie";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import Instrumentation from "common/Instrumentation";
import { moveUserPageModuleApi } from "common/ModulesApi";
import styles from "./CanvasPageBarVertical.module.css";
import cx from "classnames";
import { PageBarInfo } from "common/Canvas";
import { ExtendedPagesBarProps } from "../CanvasPageBarWrapper";
import {
    defaultSlideWidth,
    defaultMobileSlideWidth,
    verticalSectionBarFontSize,
    verticalSectionBarItemSize,
    verticalSectionBarItemSizePadding,
} from "../Constants";
import { ReactComponent as ExpandIcon } from "icons/canvas/page_bar/expand.svg";
import { ReactComponent as CollapseIcon } from "icons/canvas/page_bar/collapse.svg";
import Portal from "common/Portal";

const cookies = new Cookies();

// interface DraggableProps {
//     pageId: number | string;
//     onPagesSwap: (pageId: number | string, swapPageId: number | string) => void;
// }

// interface DragContent {
//     type: string;
//     content: number | string;
// }

const type = "pageBar";

// const DraggablePage: React.FunctionComponent<DraggableProps> = (props) => {
//     const pageId = props.pageId;
//     const ref = useRef(null);

//     const drag = useDrag({
//         item: {
//             content: pageId,
//         },
//         end: (item, monitor) => {},
//         collect: (monitor) => ({
//             isDragging: monitor.isDragging(),
//         }),
//         type: "page",
//     })[1];

//     const drop = useDrop({
//         accept: "page",
//         drop(otherItem: DragContent, monitor) {
//             if (!ref.current) {
//                 return;
//             }
//             const dragIndex = otherItem.content;
//             const hoverIndex = pageId;
//             if (dragIndex === hoverIndex) {
//                 return;
//             }
//             props.onPagesSwap(pageId, otherItem.content);
//         },
//     })[1];
//     if (pageId) drag(drop(ref));
//     return <div ref={ref}>{props.children}</div>;
// };

interface PagesBarState {
    editedTitle: string;
    hoveredPageId?: number;
    editedPageId?: number;
    removingPageId?: number;
    removingPopupShowed: boolean;
}

const PageBar = observer(
    class PageBar extends Component<ExtendedPagesBarProps, PagesBarState> {
        private pagesRef: HTMLDivElement | null = null;
        private performance: Date | null;

        constructor(props: ExtendedPagesBarProps) {
            super(props);
            this.state = {
                editedTitle: "",
                editedPageId: undefined,
                hoveredPageId: undefined,
                removingPopupShowed: false,
                removingPageId: undefined,
            };
            this.performance = null;
            this.swapPages = this.swapPages.bind(this);
        }

        swapPages(pageId: number | string, swapId: number | string) {
            if (this.props.sharedModule != null) return;
            pageId = pageId as number;
            swapId = swapId as number;
            this.performance = new Date();
            if (pageId > 0 && swapId > 0) {
                moveUserPageModuleApi(
                    swapId,
                    pageId,
                    cookies.get("instrumentation_session_id")
                )
                    .then(() => {
                        PagesStore(PageType.Canvases).updatePages();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }

        componentDidUpdate(
            prevProps: ExtendedPagesBarProps,
            prevState: PagesBarState
        ) {
            if (this.performance != null) {
                let timeMs = new Date().getTime() - this.performance.getTime();
                this.performance = null;
                Instrumentation.addInteraction("Canvas", timeMs);
            }
        }
        buildItem(
            title: string,
            pageId: string | number,
            moduleId: number | undefined,
            pageBarInfo: PageBarInfo
        ) {
            let selected =
                pageId === this.props.currentPageId && !this.props.collapsed;
            let editMode = pageId === this.state.editedPageId;
            // let readOnly = !editMode;
            // let isFirefox =
            //     navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
            const sectionNameStyles = {
                [styles.sectionName]: true,
            };
            if (selected) {
                sectionNameStyles[styles.sectionNameActive] = true;
            }
            const sectionContainerStyles = {
                [styles.sectionContainer]: true,
            };

            return (
                <div className={cx(sectionContainerStyles)}>
                    {/* <DraggablePage
                        key={pageId}
                        pageId={pageId}
                        onPagesSwap={this.swapPages}
                    > */}
                    <div
                        className={styles.sectionRow}
                        onClick={(evt) => {
                            if (!editMode) {
                                if (selected) {
                                    this.props.onCurrentPageChange(undefined);
                                } else this.props.onCurrentPageChange(pageId);
                            }
                        }}
                    >
                        <span
                            className={cx(sectionNameStyles)}
                            style={{
                                fontSize:
                                    pageBarInfo.accordionFontSize ||
                                    verticalSectionBarFontSize,
                                color: pageBarInfo.fontColor,
                            }}
                        >
                            {title}
                        </span>
                        {selected ? <CollapseIcon /> : <ExpandIcon />}
                    </div>
                    {/* </DraggablePage> */}
                </div>
            );
        }

        dismissPageRemovePopup() {
            this.setState({
                removingPopupShowed: false,
                removingPageId: undefined,
            });
        }
        render(): JSX.Element | null {
            let defaultWidth = this.props.viewMode === "mobile" ? defaultMobileSlideWidth : defaultSlideWidth;
            let barContainerStyles = {
                [styles.barContainer]: true,
            };
            let pageBarInfo = this.props.pageBarInfo;
            let pages: {
                id: string | number;
                title: string;
                module_id?: number;
            }[] = [];
            if (this.props.sharedModule == null) {
                pages = PagesStore(PageType.Canvases).pages;
            } else {
                pages = this.props.sharedModule.pages.map((page) => ({
                    id: page.pageId,
                    title: page.pageId,
                    module_id: NaN,
                }));
            }
            let selectedPageIndex = pages.findIndex(
                (item) =>
                    item.id === this.props.currentPageId &&
                    !this.props.collapsed
            );
            let topPages: typeof pages = [];
            let bottomPages: typeof pages = [];
            if (selectedPageIndex >= 0) {
                topPages = pages.slice(0, selectedPageIndex + 1);
                bottomPages = pages.slice(selectedPageIndex + 1);
            } else {
                topPages = pages;
                bottomPages = [];
            }
            let containerStyle: React.CSSProperties = {
                position: "absolute",
                zIndex: 990,
                transformOrigin: "left top",
                transform: `scale(${this.props.scale})`,
                backgroundColor: pageBarInfo.fillColor,
                boxShadow: !pageBarInfo.borderShadow ? "none" : undefined,
                display: "flex",
                justifyContent: "center",
            };
            const padding = this.props.live
                ? 0
                : verticalSectionBarItemSizePadding;
            return (
                <>
                    <Portal rootNode={this.props.rootRef}>
                        <div
                            className={cx(
                                barContainerStyles,
                                "selectable-by-pointer"
                            )}
                            ref={(ref) => {
                                if (ref != null) {
                                    ref.setAttribute("type", type);
                                    ref.setAttribute("id", type);
                                }
                            }}
                            style={{
                                ...containerStyle,
                                left: this.props.layerRect.x * this.props.scale,
                                top: padding * this.props.scale,
                                width: defaultWidth,
                                height:
                                    verticalSectionBarItemSize *
                                    topPages.length,
                            }}
                        >
                            <div
                                className="flex-simple-column"
                                style={{
                                    width: "100%",
                                    alignItems: "center",
                                }}
                            >
                                {topPages.map((item) => {
                                    return this.buildItem(
                                        item.title,
                                        item.id,
                                        item.module_id,
                                        pageBarInfo!
                                    );
                                })}
                            </div>
                        </div>
                    </Portal>
                    {bottomPages.length > 0 && (
                        <Portal rootNode={this.props.rootRef}>
                            <div
                                className={cx(barContainerStyles)}
                                style={{
                                    ...containerStyle,
                                    left:
                                        this.props.layerRect.x *
                                        this.props.scale,
                                    top:
                                        (this.props.layerRect.y +
                                            padding +
                                            this.props.layerRect.height) *
                                        this.props.scale,
                                    width: defaultWidth,
                                    height:
                                        verticalSectionBarItemSize *
                                        bottomPages.length,
                                }}
                            >
                                <div
                                    className="flex-simple-column"
                                    style={{
                                        width: "100%",
                                        alignItems: "center",
                                    }}
                                >
                                    {bottomPages.map((item) => {
                                        return this.buildItem(
                                            item.title,
                                            item.id,
                                            item.module_id,
                                            pageBarInfo!
                                        );
                                    })}
                                </div>
                            </div>
                        </Portal>
                    )}
                </>
            );
        }
    }
);

export default PageBar;
