export const dataOperations = new Set([
	"edit_current_data",
	"insert_rows",
	"delete_rows",
	"update_live_stream",
	"canvas_update",
]);

export const variableOperations = new Set([
	"modify_schema",
	"replace_current_data",
	"add_variable",
	"drop_variable",
	"create_roe",
	"generate_new_variables_schema",
	"add_cluster_variable",
	"rename_variables",
	"merge_tables",
	"copy_table",
	"regression_summary",
]);

export const specialDatasetOperations = new Set([
	"replace_module_data",
	"update_user_sharing_stats",
	"update_user_daily_stats",
	"update_raw_instrumentation",
	"aggregate_instrumentation",
]);

export const globalDatasetOperations = new Set([
	"rename_data_tables",
	"delete_data_tables",
	"publish_data_tables"
]);

export const tableOperations = new Set([
	"slice_current",
	"update_current_aggregate",
	"delete_table",
]);