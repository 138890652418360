import React, { Component } from "react";
import Switch from "react-switch";
import { mainStyle } from "common/MainStyle";
import { Button } from "react-bootstrap";
import {
    ColumnFormatType,
    columnFormatOptions,
    ColumnFormatOption,
    ColumnFormat,
    isNumberFormat,
    isGeographyFormat,
    isListFormat,
    isDateFormat,
    NumberFormat,
    ListFormat,
    DateFormat,
    GeographyFormat,
    NumberFormatType,
    numberFormatOptions,
    NumberFormatOption,
} from "common/Canvas";
import {
    geographyLevelsOptions,
    geographyLevelsSelectOptions,
} from "common/InputData";
import Select, { createFilter } from "react-select";
import { getSmallSelectStyle } from "common/SelectStyles";
import StringUtils from "common/utilities/StringUtils";
import DateTimeFormatSelect, {
    OptionType as DateTimeOptionType,
} from "common/DateTimeFormatSelect";
import TextareaAutosize from "react-autosize-textarea";
import StringOption from "common/StringOption";

interface Props {
    rootDataTestId?: string;
    allowLocationGeneration?: boolean;
    onGenerateLocation?: () => void;
    columnFormat: ColumnFormat | undefined;
    options?: ReadonlyArray<ColumnFormatOption>;
    onClose: (apply: boolean, options?: ColumnFormat, width?: number) => void;
    allowWidthInput?: boolean;
    width?: number;
    fitToContent?: () => void;
}
interface State {
    columnFormat: ColumnFormat | undefined;
    width: number | undefined;
    formatOptions: DateTimeOptionType[];
}

class FormatColumnPopup extends Component<Props, State> {
    drag: boolean = false;
    constructor(props: Props) {
        super(props);
        this.state = {
            columnFormat: this.props.columnFormat,
            width: this.props.width,
            formatOptions: DateTimeFormatSelect.defaultOptions(),
        };
    }

    private buildInnerView(): JSX.Element {
        let fontSize = 10;
        let customSelectStylesSmall = getSmallSelectStyle(fontSize);
        let textStyle = {
            fontWeight: 300,
            fontFamily: "Roboto",
            fontSize: fontSize,
            color: mainStyle.getPropertyValue("--popup-primary-text-color"),
        };
        let buttonStyle = {
            width: "102px",
            height: "17px",
            fontSize: fontSize,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        };
        return (
            <div
                data-test-id={
                    this.props.rootDataTestId
                        ? `${this.props.rootDataTestId}-columnTypeSelector`
                        : undefined
                }
                prevent-mouse-leave="true"
                className="flex-simple-column"
                style={{
                    paddingTop: 18,
                    paddingLeft: 11,
                    paddingRight: 11,
                    paddingBottom: 18,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {this.props.allowWidthInput && (
                    <div
                        // className="my-row"
                        style={{
                            marginBottom: "10px",
                            alignItems: "center",
                            alignSelf: "flex-start",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <div style={textStyle}>{"Width"}</div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <input
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-widthInput`
                                        : undefined
                                }
                                className="like-select"
                                style={{
                                    ...textStyle,
                                    fontWeight: 400,
                                    minHeight: "18px",
                                    width: "100%",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    fontFamily: "monospace",
                                    textAlign: "right",
                                    flexBasis: "40%",
                                }}
                                placeholder="Width"
                                onChange={(e) => {
                                    let value = e.target.value;
                                    let width: number = 0;
                                    if (StringUtils.isNumber(value)) {
                                        width = Math.max(0, Number(value));
                                    }
                                    this.setState({
                                        width: width,
                                    });
                                }}
                                defaultValue={this.state.width?.toFixed(0)}
                            />
                            <Button
                                prevent-mouse-leave="true"
                                type="button"
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-fitToWidthButton`
                                        : undefined
                                }
                                className="btn btn-sm btn-primary btn-small-formats my-primary"
                                style={{
                                    ...buttonStyle,
                                    marginLeft: "4px",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    minHeight: "18px",
                                }}
                                onClick={() => {
                                    this.props.fitToContent?.();
                                    this.props.onClose(false);
                                }}
                            >
                                FIT TO WIDTH
                            </Button>
                        </div>
                    </div>
                )}
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Column type"}
                    styles={{
                        ...customSelectStylesSmall,
                        container: (base) => ({
                            ...base,
                            width: "100%",
                        }),
                    }}
                    options={this.props.options ?? columnFormatOptions}
                    onChange={(newValue) => {
                        let type = (newValue as ColumnFormatOption).value;
                        switch (type) {
                            case ColumnFormatType.Enum: {
                                let columnFormat: ListFormat = {
                                    type: type,
                                    allowMultipleSelection: false,
                                    options: [],
                                };
                                this.setState({
                                    columnFormat: columnFormat,
                                });
                                break;
                            }
                            case ColumnFormatType.Number: {
                                let columnFormat: NumberFormat = {
                                    type: type,
                                    decimalPoints: 2,
                                    useCommaSeparator: true,
                                    useAbbreviation: false,
                                    numberType: NumberFormatType.Number,
                                };
                                this.setState({
                                    columnFormat: columnFormat,
                                });
                                break;
                            }
                            case ColumnFormatType.Text:
                            case ColumnFormatType.Month:
                                {
                                    let columnFormat: ColumnFormat = {
                                        type: type,
                                    };
                                    this.setState({
                                        columnFormat: columnFormat,
                                    });
                                }
                                break;
                            case ColumnFormatType.Date:
                                {
                                    let columnFormat: DateFormat = {
                                        type: type,
                                        format: this.state.formatOptions[0]
                                            .value,
                                    };
                                    this.setState({
                                        columnFormat: columnFormat,
                                    });
                                }

                                break;
                            case ColumnFormatType.Geography:
                                {
                                    let columnFormat: GeographyFormat = {
                                        type: type,
                                        level: geographyLevelsOptions[0],
                                    };
                                    this.setState({
                                        columnFormat: columnFormat,
                                    });
                                }
                                break;
                            default:
                                // code...
                                break;
                        }
                    }}
                    value={
                        this.state.columnFormat != null
                            ? columnFormatOptions.find(
                                  (item) =>
                                      item.value ===
                                      this.state.columnFormat!.type
                              )
                            : null
                    }
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                {this.state.columnFormat &&
                    isListFormat(this.state.columnFormat) && (
                        <>
                            <span
                                style={{
                                    ...textStyle,
                                    alignSelf: "flex-start",
                                    marginTop: "13px",
                                }}
                            >
                                {"Selectable items"}
                            </span>
                            <TextareaAutosize
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-selectableItemsTextArea`
                                        : undefined
                                }
                                className="like-select"
                                style={{
                                    ...textStyle,
                                    fontWeight: 400,
                                    paddingTop: "11px",
                                    width: "107px",
                                    marginTop: "5px",
                                    minHeight: "51px",
                                    resize: "none",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    fontSize: "10px",
                                }}
                                rows={
                                    this.state.columnFormat.options.length + 1
                                }
                                placeholder="Enter options, delimited by comma"
                                onChange={(e) => {
                                    let value = e.currentTarget.value;
                                    this.setState((state) => ({
                                        columnFormat: {
                                            ...(state.columnFormat as ListFormat),
                                            options: value
                                                .split(",")
                                                .map((item) => item.trim())
                                                .filter((item) => item),
                                        },
                                    }));
                                }}
                                defaultValue={(this.state
                                    .columnFormat as ListFormat).options.join(
                                    ",\n"
                                )}
                                onKeyUp={(e) => {
                                    let key = e.key;
                                    if (key === ",") {
                                        let last =
                                            e.currentTarget.selectionStart ===
                                                e.currentTarget.selectionEnd &&
                                            e.currentTarget.selectionStart ===
                                                e.currentTarget.value.length;
                                        if (last) {
                                            e.currentTarget.value =
                                                e.currentTarget.value + "\n";
                                        }
                                    }
                                }}
                            />
                            <div
                                className="my-row"
                                style={{
                                    marginTop: "5px",
                                    alignItems: "center",
                                    alignSelf: "flex-start",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-allowMultipleSelectionSwitch`
                                        : undefined
                                }
                            >
                                <span style={textStyle}>
                                    {"Allow multiple selection"}
                                </span>
                                <div style={{ marginLeft: 5 }}>
                                    <Switch
                                        onChange={(checked) => {
                                            this.setState((state) => ({
                                                columnFormat: {
                                                    ...(state.columnFormat as ListFormat),
                                                    allowMultipleSelection: checked,
                                                },
                                            }));
                                        }}
                                        checked={
                                            (this.state
                                                .columnFormat as ListFormat)
                                                .allowMultipleSelection
                                        }
                                        width={16}
                                        height={7}
                                        offColor="#20293C"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1F8EFA"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                {this.state.columnFormat &&
                    isNumberFormat(this.state.columnFormat) && (
                        <>
                            <div
                                // className="my-row"
                                style={{
                                    marginTop: "10px",
                                    alignItems: "center",
                                    alignSelf: "flex-start",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-numberFormatSelector`
                                        : undefined
                                }
                            >
                                <div style={textStyle}>{"Format"}</div>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={"Number format"}
                                    styles={{
                                        ...customSelectStylesSmall,
                                        container: (base) => ({
                                            ...base,
                                            width: "100%",
                                        }),
                                    }}
                                    options={numberFormatOptions}
                                    onChange={(newValue) => {
                                        let type = (newValue as NumberFormatOption)
                                            .value;
                                        this.setState((state) => ({
                                            columnFormat: {
                                                ...(state.columnFormat as NumberFormat),
                                                numberType: type,
                                            },
                                        }));
                                    }}
                                    value={numberFormatOptions.find(
                                        (option) =>
                                            option.value ===
                                            (this.state
                                                .columnFormat as NumberFormat)
                                                .numberType
                                    )}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            <div
                                // className="my-row"
                                style={{
                                    marginTop: "10px",
                                    alignItems: "center",
                                    alignSelf: "flex-start",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <div style={textStyle}>{"Decimals"}</div>
                                <input
                                    data-test-id={
                                        this.props.rootDataTestId
                                            ? `${this.props.rootDataTestId}-decimalsInput`
                                            : undefined
                                    }
                                    className="like-select"
                                    style={{
                                        ...textStyle,
                                        fontWeight: 400,
                                        minHeight: "18px",
                                        width: "100%",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        fontFamily: "monospace",
                                        textAlign: "right",
                                    }}
                                    placeholder="Decimal places"
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        let decimalPoints: number = 0;
                                        if (StringUtils.isNumber(value)) {
                                            decimalPoints = Math.min(
                                                Math.max(
                                                    0,
                                                    Math.trunc(Number(value))
                                                ),
                                                20
                                            );
                                        }
                                        this.setState((state) => ({
                                            columnFormat: {
                                                ...(state.columnFormat as NumberFormat),
                                                decimalPoints: decimalPoints,
                                            },
                                        }));
                                    }}
                                    defaultValue={String(
                                        (this.state
                                            .columnFormat as NumberFormat)
                                            .decimalPoints
                                    )}
                                />
                            </div>

                            <div
                                className="my-row"
                                style={{
                                    marginTop: "10px",
                                    alignItems: "center",
                                    alignSelf: "flex-start",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-useAbbreviationSwitch`
                                        : undefined
                                }
                            >
                                <span style={textStyle}>
                                    {"Use Abbreviation"}
                                </span>
                                <div style={{ marginLeft: 5 }}>
                                    <Switch
                                        onChange={(checked) => {
                                            this.setState((state) => ({
                                                columnFormat: {
                                                    ...(state.columnFormat as NumberFormat),
                                                    useAbbreviation: checked,
                                                },
                                            }));
                                        }}
                                        checked={
                                            (this.state
                                                .columnFormat as NumberFormat)
                                                .useAbbreviation ?? false
                                        }
                                        width={16}
                                        height={7}
                                        offColor="#20293C"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1F8EFA"
                                    />
                                </div>
                            </div>
                            <div
                                className="my-row"
                                style={{
                                    marginTop: "10px",
                                    alignItems: "center",
                                    alignSelf: "flex-start",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-useThousandSeparatorSwitch`
                                        : undefined
                                }
                            >
                                <span style={textStyle}>
                                    {`Use 1000 separator(,)`}
                                </span>
                                <div style={{ marginLeft: 5 }}>
                                    <Switch
                                        onChange={(checked) => {
                                            this.setState((state) => ({
                                                columnFormat: {
                                                    ...(state.columnFormat as NumberFormat),
                                                    useCommaSeparator: checked,
                                                },
                                            }));
                                        }}
                                        checked={
                                            (this.state
                                                .columnFormat as NumberFormat)
                                                .useCommaSeparator ?? true
                                        }
                                        width={16}
                                        height={7}
                                        offColor="#20293C"
                                        onColor="#20293C"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        offHandleColor="#70889E"
                                        onHandleColor="#1F8EFA"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                {this.state.columnFormat &&
                    isGeographyFormat(this.state.columnFormat) && (
                        <>
                            <div
                                // className="my-row"
                                style={{
                                    marginTop: "10px",
                                    alignItems: "center",
                                    alignSelf: "flex-start",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                                data-test-id={
                                    this.props.rootDataTestId
                                        ? `${this.props.rootDataTestId}-geographyLevelSelector`
                                        : undefined
                                }
                            >
                                <div style={textStyle}>{"Level"}</div>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={"Geography level"}
                                    styles={{
                                        ...customSelectStylesSmall,
                                        container: (base) => ({
                                            ...base,
                                            width: "100%",
                                        }),
                                    }}
                                    options={geographyLevelsSelectOptions}
                                    onChange={(newValue) => {
                                        let level = (newValue as StringOption)
                                            .value;
                                        this.setState((state) => ({
                                            columnFormat: {
                                                ...(state.columnFormat as GeographyFormat),
                                                level: level,
                                            },
                                        }));
                                    }}
                                    value={geographyLevelsSelectOptions.find(
                                        (option) =>
                                            option.value ===
                                            (this.state
                                                .columnFormat as GeographyFormat)
                                                .level
                                    )}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                        </>
                    )}
                {this.state.columnFormat &&
                    isDateFormat(this.state.columnFormat) && (
                        <>
                            <span
                                style={{
                                    ...textStyle,
                                    alignSelf: "flex-start",
                                    marginTop: "13px",
                                }}
                            >
                                {"Date format"}
                            </span>
                            <DateTimeFormatSelect
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                onCreateOption={(option) => {
                                    this.setState((state) => ({
                                        columnFormat: {
                                            ...(state.columnFormat as NumberFormat),
                                            format: option.value,
                                        },
                                        formatOptions: [
                                            option,
                                            ...state.formatOptions,
                                        ],
                                    }));
                                }}
                                styles={{
                                    ...customSelectStylesSmall,
                                    container: (base) => ({
                                        ...base,
                                        marginTop: "7px",
                                        width: "100%",
                                    }),
                                }}
                                options={this.state.formatOptions}
                                onChange={(newValue) => {
                                    this.setState((state) => ({
                                        columnFormat: {
                                            ...(state.columnFormat as NumberFormat),
                                            format: (newValue as DateTimeOptionType)
                                                .value,
                                        },
                                    }));
                                }}
                                value={this.state.columnFormat.format ?? ""}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </>
                    )}
                {this.props.allowLocationGeneration && (
                    <Button
                        data-test-id={
                            this.props.rootDataTestId
                                ? `${this.props.rootDataTestId}-generateCoordinatesButton`
                                : undefined
                        }
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            ...buttonStyle,
                            marginTop: "16px",
                            fontSize: "9px",
                        }}
                        onClick={this.props.onGenerateLocation}
                    >
                        Generate Coordinates
                    </Button>
                )}
                <Button
                    type="button"
                    data-test-id={
                        this.props.rootDataTestId
                            ? `${this.props.rootDataTestId}-saveButton`
                            : undefined
                    }
                    className="btn btn-lg btn-primary my-primary"
                    style={{ 
                        ...buttonStyle, 
                        marginTop: "16px", 
                        fontSize: 14,
                        width:"100%",
                        fontWeight: 500,
                        backgroundColor: "#3b82c9",
                    }}
                    onClick={() => {
                        this.props.onClose(
                            true,
                            this.state.columnFormat,
                            this.state.width
                        );
                    }}
                >
                    Save
                </Button>
                <Button
                    prevent-mouse-leave="true"
                    type="button"
                    data-test-id={
                        this.props.rootDataTestId
                            ? `${this.props.rootDataTestId}-closeButton`
                            : undefined
                    }
                    className="btn btn-lg my-outline"
                    style={{
                        ...buttonStyle,
                        marginTop: "4px",
                        fontSize: 14,
                        width:"100%",
                        fontWeight: 500,
                        backgroundColor: "white",
                        border: "1px solid #e0e0e0",
                        color: "#3b82c9",
                    }}
                    onClick={() => {
                        this.props.onClose(false);
                    }}
                >
                    Cancel
                </Button>
            </div>
        );
    }

    render() {
        return (
            <div
                prevent-mouse-leave="true"
                className="dashboard-rect flex-simple-column"
                style={{
                    width: "160px",
                    alignItems: "center",
                    boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                    borderRadius: 0,
                    cursor: "pointer",
                }}
                onContextMenu={(evt) => {
                    evt.stopPropagation();
                }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                onMouseDown={(evt: any) => {
                    evt.stopPropagation();
                }}
            >
                {this.buildInnerView()}
            </div>
        );
    }
}
export default FormatColumnPopup;
