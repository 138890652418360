import Leaflet from "leaflet";
import ReactDOMServer from "react-dom/server";



export const createClusterCustomIcon = (cluster: any, color: string) => {
  return Leaflet.divIcon({
    html: ReactDOMServer.renderToString(
      <div style={{
          background: color.replace(",1)", ",0.6)"),
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "Center",
          alignItems: "center",
          borderRadius: "50%",
      }}>
          <span
              style={{
                  background: color.replace(",1)", ",0.8)"),
                  width: 30,
                  height: 30,
                  display: "flex",
                  justifyContent: "Center",
                  alignItems: "center",
                  borderRadius: "50%",
              }}
          >
              {cluster.getChildCount()}
          </span>
      </div>
    ),
    className: 'marker-cluster-custom',
    iconSize: Leaflet.point(40, 40, true),
  });
}
