import React from "react";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";
import { getCustomSelectStyleForDataSection } from "common/SelectStyles";
import { VariableOption } from "common/Variables";
import { LeversBenchmarkingFinding } from "common/Finding";
import Variables from "common/Variables";
import commonStyles from "../DataSection.module.css";
import styles from "./LeversBenchmarkingSection.module.css";
import cx from "classnames";
import EditInput from "../EditInput";

interface OperationOption {
    label: string;
    value: "best" | "worst" | "average";
}

const operationOptions: OperationOption[] = [
    {
        label: "best",
        value: "best",
    },
    {
        label: "average",
        value: "average",
    },
    {
        label: "worst",
        value: "worst",
    },
];

interface Props {
    columnDragActive: boolean;
    finding: LeversBenchmarkingFinding;
    onChange: (
        finding: LeversBenchmarkingFinding,
        updateData?: boolean
    ) => void;
    currentModuleId?: number;
}

function LeversBenchmarkingSection(props: Props) {
    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    let variableOptions: VariableOption[] = [];
    let numericOptions: VariableOption[] = [];
    if (props.finding.config.dataScope != null) {
        variableOptions = Variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions;
        numericOptions = Variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).numericVariableOptions;
    }

    let data: {
        name: string;
        value: number[];
        variableIndex?: number;
    }[] = props.finding.content.data;
    let currentEditVariableIndex: number | undefined = undefined;
    if (props.finding.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = data.findIndex(
            (item) => item.variableIndex == null
        );
    }
    return (
        <div>
            <div className={styles.dataContainer}>
                <div className={commonStyles.header}>Variables</div>
                <div className={commonStyles.header}>Left Value</div>
                <div className={commonStyles.header}>Right Value</div>
                {data.map((item, index) => (
                    <React.Fragment key={index}>
                        <EditInput
                            showDeleteButton={true}
                            disabled={false}
                            optionalClassName={
                                index === currentEditVariableIndex
                                    ? commonStyles.highlightVariable
                                    : undefined
                            }
                            value={item.name}
                            onChange={(value) => {
                                let finding = {
                                    ...props.finding!,
                                };
                                finding.content.data[
                                    index
                                ].name = value as string;

                                props.onChange(finding);
                            }}
                            onDelete={() => {
                                let finding = {
                                    ...props.finding!,
                                };
                                finding.content.data.splice(index, 1);

                                props.onChange(finding);
                            }}
                        />

                        <EditInput
                            showDeleteButton={false}
                            optionalClassName={
                                index === currentEditVariableIndex
                                    ? commonStyles.highlightValue
                                    : undefined
                            }
                            disabled={
                                props.finding.content.data[index]
                                    .variableIndex != null
                            }
                            onChange={(value) => {
                                let finding = {
                                    ...props.finding!,
                                };
                                finding.content.data[index].value[0] = Number(
                                    value
                                );
                                props.onChange(finding);
                            }}
                            value={String(item.value[0])}
                        />

                        <EditInput
                            showDeleteButton={false}
                            optionalClassName={
                                index === currentEditVariableIndex
                                    ? commonStyles.highlightValue
                                    : undefined
                            }
                            disabled={
                                props.finding.content.data[index]
                                    .variableIndex != null
                            }
                            onChange={(value) => {
                                let finding = {
                                    ...props.finding!,
                                };
                                finding.content.data[index].value[1] = Number(
                                    value
                                );
                                props.onChange(finding);
                            }}
                            value={String(item.value[1])}
                        />
                    </React.Fragment>
                ))}
            </div>

            <Button
                style={{ marginTop: 10 }}
                className={cx(
                    "btn btn-sm btn-primary my-primary",
                    commonStyles.addItemButton
                )}
                onClick={() => {
                    let finding = { ...props.finding! };
                    finding.content.data.push({
                        name: "new var",
                        units: "",
                        value: [10, 5],
                    });
                    props.onChange(finding);
                }}
            >
                {"\uFF0B Add Item"}
            </Button>

            <div
                className="my-row"
                style={{
                    alignItems: "center",
                    marginTop: 10,
                }}
            >
                <span className={commonStyles.optionTitle}>Compare</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder="Select variable"
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            marginLeft: 5,
                            marginRight: 5,
                            flex: 1,
                            height: "38px",
                        }),
                    }}
                    options={operationOptions}
                    onChange={(newValue) => {
                        let finding: LeversBenchmarkingFinding = {
                            ...props.finding!,
                            config: {
                                ...props.finding!.config,
                            },
                        };
                        finding.config.groupOperation = (newValue as OperationOption).value;
                        props.onChange(finding, true);
                    }}
                    value={{
                        label: props.finding.config.groupOperation,
                        value: props.finding.config.groupOperation,
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                <span className={commonStyles.optionTitle}>to</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder="Select variable"
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            marginLeft: 5,
                            flex: 1,
                            height: "38px",
                        }),
                    }}
                    options={operationOptions}
                    onChange={(newValue) => {
                        let finding: LeversBenchmarkingFinding = {
                            ...props.finding!,
                            config: {
                                ...props.finding!.config,
                            },
                        };
                        finding.config.compareOperation = (newValue as OperationOption).value;
                        props.onChange(finding, true);
                    }}
                    value={{
                        label: props.finding.config.compareOperation,
                        value: props.finding.config.compareOperation,
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            <div
                className="my-row"
                style={{
                    alignItems: "center",
                    marginTop: 10,
                }}
            >
                <span className={commonStyles.optionTitle}>values of</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder="Select variable"
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            flex: 1,
                            marginLeft: 5,
                            height: "38px",
                        }),
                    }}
                    options={variableOptions}
                    onChange={(newValue) => {
                        let finding: LeversBenchmarkingFinding = {
                            ...props.finding!,
                            config: {
                                ...props.finding!.config,
                            },
                        };
                        finding.config.aggregateVariable = (newValue as VariableOption).label;
                        finding.config.aggregateVariableIndex = (newValue as VariableOption).value;
                        props.onChange(finding, true);
                    }}
                    value={
                        props.finding.config.aggregateVariableIndex == null
                            ? null
                            : variableOptions.find(
                                  (item) =>
                                      item.value ===
                                      props.finding.config
                                          .aggregateVariableIndex
                              )
                    }
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
            <div
                className="my-row"
                style={{
                    alignItems: "center",
                    marginTop: 10,
                }}
            >
                <span className={commonStyles.optionTitle}>as measured by</span>
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder="Select variable"
                    styles={{
                        ...selectStyles,
                        container: (base) => ({
                            ...base,
                            flex: 1,
                            marginLeft: 5,
                            height: "38px",
                        }),
                    }}
                    options={numericOptions}
                    onChange={(newValue) => {
                        let finding: LeversBenchmarkingFinding = {
                            ...props.finding!,
                            config: {
                                ...props.finding!.config,
                            },
                        };
                        finding.config.targetVariable = (newValue as VariableOption).label;
                        finding.config.targetVariableIndex = (newValue as VariableOption).value;
                        props.onChange(finding, true);
                    }}
                    value={
                        props.finding.config.targetVariableIndex == null
                            ? null
                            : numericOptions.find(
                                  (item) =>
                                      item.value ===
                                      props.finding.config.targetVariableIndex
                              )
                    }
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
            </div>
        </div>
    );
}

export default observer(LeversBenchmarkingSection);
