import React, { Component, CSSProperties } from "react";
import { SubmitButton, ResetButton } from "./MyStripeForm";
import { PopupStatus } from "common/StatusPopup";
import axios from "common/ServerConnection";
import ProductParametersInput, { PaymentType } from "./ProductParametersInput";

interface Props {
    linkId: string;
    onMessage: (status: PopupStatus, message: string) => void;
    style?: CSSProperties;
}

interface State {
    client: string;
    name: string;
    paymentType: PaymentType;
    price: string;
    logo: string;
    created: boolean;
    processing: boolean;
}

class CreateProduct extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            client: "",
            name: "",
            paymentType: "one_time",
            price: "",
            created: false,
            processing: false,
            logo: "",
        };

        this.onCreated = this.onCreated.bind(this);
    }

    public componentDidMount(): void {
        // Fetch the current paywall parameters
        let request = {
            id: this.props.linkId,
        };
        axios
            .post<{
                success: boolean;
                error_msg: string;
                paywall?: {
                    title: string;
                    description: string;
                    price: number;
                    payment_type: PaymentType;
                    logo: string | null;
                };
            }>("/api/module_user_share_link_paywall_get", request)
            .then((response) => {
                if (response.data.success) {
                    // If it's null, then there is no paywall
                    if (response.data.paywall != null) {
                        this.setState({
                            created: true,
                            name: response.data.paywall.title,
                            client: response.data.paywall.description,
                            price: response.data.paywall.price.toString(),
                            paymentType: response.data.paywall.payment_type,
                            logo: response.data.paywall.logo ?? "",
                        });
                    }
                } else {
                    this.props.onMessage(
                        PopupStatus.Error,
                        `Could not fetch paywall options: ${response.data.error_msg}`
                    );
                }
            })
            .catch((error) => {
                this.props.onMessage(
                    PopupStatus.Error,
                    `Could not fetch paywall options: ${error.toString()}`
                );
                console.log(error);
            });
    }

    private resetFields(): void {
        this.setState({
            name: "",
            price: "",
            paymentType: "one_time",
            client: "",
        });
    }

    private onCreated(event: React.SyntheticEvent): void {
        event.preventDefault();
        const request = {
            id: this.props.linkId,
            title: this.state.name,
            client: this.state.client,
            description: "",
            price: Number(this.state.price),
            payment_type: this.state.paymentType,
        };
        this.setState({ processing: true });

        axios
            .post<{
                success: boolean;
                error_msg: string;
            }>("/api/module_user_share_link_paywall_add_or_edit", request)
            .then((response) => {
                if (response.data.success) {
                    this.resetFields();
                    this.props.onMessage(
                        PopupStatus.Success,
                        "Paywall created"
                    );
                    this.setState({
                        created: true,
                        processing: false,
                    });
                } else {
                    this.props.onMessage(
                        PopupStatus.Error,
                        response.data.error_msg
                    );
                    this.setState({ processing: false });
                }
            })
            .catch((error) => {
                this.props.onMessage(PopupStatus.Error, error.toString());
                this.setState({ processing: false });
                console.log(error);
            });
    }

    public render(): JSX.Element {
        return this.state.created ? (
            <div className="Result" style={this.props.style}>
                <div>
                    <div className="ResultTitle" role="alert">
                        Product created successfully
                    </div>
                    <div className="ResultMessage">
                        Product created successfully. Click bellow if you want
                        to add another product
                        <br />
                    </div>
                    <ResetButton
                        onClick={() => this.setState({ created: false })}
                    />
                </div>
            </div>
        ) : (
            <div>
                <ProductParametersInput
                    client={this.state.client}
                    name={this.state.name}
                    paymentType={this.state.paymentType}
                    price={this.state.price}
                    logo={this.state.logo}
                    onChange={(changes) => {
                        this.setState(changes as Pick<State, keyof State>);
                    }}
                />
                <SubmitButton
                    error={null}
                    processing={this.state.processing}
                    disabled={false}
                    onClick={(event) => {
                        if (!this.state.processing) {
                            this.onCreated(event);
                        }
                    }}
                >
                    Create
                </SubmitButton>
            </div>
        );
    }
}

export default CreateProduct;
