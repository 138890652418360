import React from "react";
import { Button } from "react-bootstrap";
import { Element } from "react-scroll";
import Select, { createFilter } from "react-select";
import Switch from "react-switch";
import { reaction } from "mobx";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import customSelectStyles from "common/SelectStyles";
import tables from "common/Tables";
import variables from "common/Variables";
import dataScopes from "common/DataScopes";
import { Permission } from "common/Permissions";
import {
    defaultAggregateOptions,
    aggregationOptions,
    defaultAggregateState,
    updateCurrentAggregate,
} from "common/AggregateTable";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
                ...defaultAggregateState(),
                updateCurrentTableHeader: null,
                updateCurrentTablePreview: null,
                updateCurrentDroppedValuesCount: -1,
            };
            this.dataScopeValue = this.dataScopeValue.bind(this);
        }
        dataScopeValue() {
            if (this.state.dataScope) return this.state.dataScope.value;
            return undefined;
        }
        updateCurrentAggregate(preview = false, dropMissing = false) {
            if (!this.state.aggregateDataTable) {
                this.setStepState(StepState.error, "Table is not selected");
                return;
            }
            this.setStepState(StepState.running);
            updateCurrentAggregate(
                this.state,
                (resultPreview) => {
                    if (preview) {
                        this.setStepState(StepState.finished, null, {
                            updateCurrentDroppedValuesCount:
                                resultPreview.droppedValuesCount,
                            updateCurrentTableHeader: resultPreview.tableHeader,
                            updateCurrentTablePreview:
                                resultPreview.tablePreview,
                        });
                    } else {
                        this.setStepState(StepState.finished);
                        tables(this.dataScopeValue()).update();
                    }
                },
                (errorMessage) => {
                    this.setStepState(StepState.error, errorMessage);
                },
                preview,
                dropMissing
            );
        }

        renderUpdateCurrent() {
            if (!this.state.expanded) return null;
            let tablePreview;
            if (this.state.updateCurrentTablePreview !== null) {
                let tableContent = this.state.updateCurrentTablePreview.map(
                    (row) =>
                        row.map(
                            (element) =>
                                element ||
                                (typeof element === "number" ? element : "-")
                        )
                );

                tablePreview = (
                    <Element
                        name="scrollable"
                        className="element"
                        style={{
                            overflowX: "scroll",
                            backgroundColor: "transparent",
                            position: "relative",
                            overflowY: "scroll",
                        }}
                    >
                        <AdminTableWithFullFeatures
                            className="processing-page-table"
                            paging={false}
                            tableName="Data"
                            tableHeader={this.state.updateCurrentTableHeader}
                            tableContent={tableContent}
                        />
                    </Element>
                );
            } else {
                tablePreview = null;
            }
            return (
                <>
                    <div />
                    <div
                        style={{
                            gridColumnStart: 2,
                            gridColumnEnd: 4,
                            minWidth: 0,
                            background: "#3f4b62",
                            paddingLeft: "10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                }}
                            >
                                Select data
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={dataScopes.dataScopesOptions.filter(
                                    (option) =>
                                        option.permissionType ===
                                        Permission.ReadWrite
                                )}
                                onChange={(newValue) =>
                                    this.setState({
                                        dataScope: newValue,
                                        aggregateDataTable: tables(
                                            newValue.value
                                        ).rawAndAggregateTableOptions[0],
                                        updateCurrentBy: [null],
                                        updateCurrentVariableAggregation: defaultAggregateOptions(
                                            newValue.value
                                        ),
                                    })
                                }
                                value={this.state.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                }}
                            >
                                Select table
                            </span>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "5px",
                                    }),
                                }}
                                options={
                                    tables(this.dataScopeValue())
                                        .rawAndAggregateTableOptions
                                }
                                onChange={(newValue) =>
                                    this.setState({
                                        aggregateDataTable: newValue,
                                    })
                                }
                                value={this.state.aggregateDataTable}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                                marginBottom: "10px",
                                flexWrap: "wrap",
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    width: "10em",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "10px",
                                }}
                            >
                                By
                            </span>
                            {this.state.updateCurrentBy.map((item, index) => (
                                <div
                                    key={index}
                                    className="my-row"
                                    style={{ marginRight: "10px" }}
                                >
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        styles={{
                                            ...customSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                width: "10em",
                                                height: "38px",
                                                marginRight: "5px",
                                            }),
                                        }}
                                        options={variables(
                                            this.dataScopeValue()
                                        ).variableOptions.filter(
                                            (item) =>
                                                !this.state.updateCurrentBy
                                                    .map((item) => item?.value ?? null)
                                                    .includes(item.value)
                                        )}
                                        onChange={(newValue) => {
                                            this.setState((state) => {
                                                let newUpdateCurrentBy = [
                                                    ...state.updateCurrentBy,
                                                ];
                                                newUpdateCurrentBy[
                                                    index
                                                ] = newValue;
                                                let globalIndex =
                                                    newValue.value;
                                                let newUpdateCurrentVariableAggregation = [
                                                    ...state.updateCurrentVariableAggregation,
                                                ];
                                                newUpdateCurrentVariableAggregation[
                                                    globalIndex
                                                ] = aggregationOptions[6];
                                                return {
                                                    updateCurrentVariableAggregation: newUpdateCurrentVariableAggregation,
                                                    updateCurrentBy: newUpdateCurrentBy,
                                                };
                                            });
                                        }}
                                        value={
                                            this.state.updateCurrentBy[index]
                                        }
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25: "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                    {this.state.updateCurrentBy.length > 1 && (
                                        <Button
                                            className="btn-small-like-select"
                                            style={{
                                                width: "19px",
                                                height: "19px",
                                            }}
                                            onClick={() => {
                                                this.setState((state) => {
                                                    let newUpdateCurrentBy = [
                                                        ...state.updateCurrentBy,
                                                    ];
                                                    newUpdateCurrentBy.splice(
                                                        index,
                                                        1
                                                    );
                                                    return {
                                                        updateCurrentBy: newUpdateCurrentBy,
                                                    };
                                                });
                                            }}
                                        >
                                            {"\uFF0D" /* minus */}
                                        </Button>
                                    )}
                                </div>
                            ))}

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {this.state.updateCurrentBy.length <
                                variables(this.dataScopeValue()).dataVariables
                                    .length ? (
                                    <Button
                                        className="btn-small-like-select"
                                        style={{
                                            width: "19px",
                                            height: "19px",
                                        }}
                                        onClick={() => {
                                            this.setState((state) => ({
                                                updateCurrentBy: state.updateCurrentBy.concat(
                                                    [null]
                                                ),
                                            }));
                                        }}
                                    >
                                        {"\uFF0B" /* plus */}
                                    </Button>
                                ) : (
                                    <div
                                        style={{
                                            width: "19px",
                                            height: "19px",
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        {variables(this.dataScopeValue()).dataVariables.map(
                            (variable, index) => {
                                let isDisabled = this.state.updateCurrentBy
                                    .map((item) => item?.value ?? null)
                                    .includes(variable.index);
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            className="content-regular-text"
                                            style={{
                                                width: "10em",
                                                display: "flex",
                                                alignItems: "center",
                                                marginRight: "10px",
                                            }}
                                        >
                                            {variable.name}, {variable.unit}
                                        </span>

                                        <Select
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            isDisabled={isDisabled}
                                            placeholder={""}
                                            styles={{
                                                ...customSelectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    width: "10em",
                                                    height: "38px",
                                                    marginRight: "10px",
                                                }),
                                                dropdownIndicator: (
                                                    provided
                                                ) => {
                                                    if (isDisabled)
                                                        return {
                                                            ...provided,
                                                            display: "none",
                                                        };
                                                    else return provided;
                                                },
                                            }}
                                            options={
                                                ["int", "float"].includes(
                                                    variable.type
                                                )
                                                    ? aggregationOptions
                                                    : aggregationOptions.slice(
                                                          4
                                                      )
                                            }
                                            onChange={(newValue) => {
                                                this.setState((state) => {
                                                    let newUpdateCurrentVariableAggregation = [
                                                        ...state.updateCurrentVariableAggregation,
                                                    ];
                                                    newUpdateCurrentVariableAggregation[
                                                        index
                                                    ] = newValue;
                                                    return {
                                                        updateCurrentVariableAggregation: newUpdateCurrentVariableAggregation,
                                                    };
                                                });
                                            }}
                                            value={
                                                this.state
                                                    .updateCurrentVariableAggregation[
                                                    index
                                                ]
                                            }
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25: "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </div>
                                );
                            }
                        )}
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginBottom: "10px",
                            }}
                            onClick={() =>
                                this.updateCurrentAggregate(
                                    true,
                                    this.state.updateCurrentDropMissing
                                )
                            }
                        >
                            <span className="content-regular-text">
                                {this.state.updateCurrentTablePreview === null
                                    ? "PREVIEW"
                                    : "UPDATE PREVIEW"}
                            </span>
                        </Button>
                        <div className="my-row">
                            <span className="content-regular-text">
                                {"Drop missing"}
                            </span>
                            <div style={{ marginLeft: "5px" }}>
                                <Switch
                                    onChange={() => {
                                        this.setState(
                                            (state) => ({
                                                updateCurrentDropMissing: !state.updateCurrentDropMissing,
                                            }),
                                            () => {
                                                this.updateCurrentAggregate(
                                                    true,
                                                    this.state
                                                        .updateCurrentDropMissing
                                                );
                                            }
                                        );
                                    }}
                                    checked={
                                        this.state.updateCurrentDropMissing
                                    }
                                    width={40}
                                    height={20}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                            {this.state.updateCurrentDroppedValuesCount !==
                                -1 && (
                                <span
                                    style={{ marginLeft: "5px" }}
                                    className="content-regular-text"
                                >
                                    {`${this.state.updateCurrentDroppedValuesCount} will be dropped`}
                                </span>
                            )}
                        </div>
                        {tablePreview}
                    </div>
                    <div />
                </>
            );
        }

        componentDidMount() {
            this.dataVariablesReaction = reaction(
                () => variables(this.dataScopeValue()).dataVariables,
                () => {
                    this.setState({
                        updateCurrentVariableAggregation: defaultAggregateOptions(
                            this.dataScopeValue()
                        ),
                    });
                }
            );
        }
        componentWillUnmount() {
            this.dataVariablesReaction();
        }

        render() {
            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        className="btn-processing-tab"
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState((state) => ({
                                expanded: !state.expanded,
                            }));
                        }}
                    >
                        <span className="content-regular-text">
                            Update current aggregate tables
                        </span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    {this.state.expanded ? (
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            onClick={() => {
                                this.updateCurrentAggregate(
                                    false,
                                    this.state.updateCurrentDropMissing
                                );
                            }}
                            style={{
                                fontFamily: "Roboto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: "25px",
                                width: "100%",
                                minWidth: "110px",
                            }}
                        >
                            UPDATE
                        </Button>
                    ) : (
                        <div style={{ minWidth: "110px" }} />
                    )}
                    <div>{this.state.expanded && this.renderStepState()}</div>
                    {this.renderUpdateCurrent()}
                </>
            );
        }
    }
);

export { MainComponent };