import React, { Component, CSSProperties } from "react";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import cx from "classnames";

import SearchComponent, {
    SearchComponentOption,
} from "common/RevampedSearchComponent";
import { Variable, VariableOption } from "common/Variables";
import StringOption from "common/StringOption";
import Switch from "react-switch";
import { Condition, NodeLinkOption } from "common/Conditions";

import styles from "./ConditionalsEditor.module.css";

const comparisonOperations: ReadonlyArray<StringOption> = [
    {
        label: ">",
        value: ">",
    },
    {
        label: "<",
        value: "<",
    },
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
];

const comparisonPanelOperations: ReadonlyArray<StringOption> = [
    {
        label: "=",
        value: "=",
    },
    {
        label: "!=",
        value: "!=",
    },
];

const logicalOperations: ReadonlyArray<StringOption> = [
    {
        label: "OR",
        value: "or",
    },
    {
        label: "AND",
        value: "and",
    },
];

interface SwitchProps {
    offColor: string;
    onColor: string;
    offHandleColor: string;
    onHandleColor: string;
}

export function getSharedIdsList(condition: Condition): number[] {
    let newSharedBoxIds: number[] = [];
    if (
        !isMultiCondition(condition) &&
        condition.isInput &&
        condition.value != null &&
        (condition.value as NodeLinkOption).isCloneInput
    ) {
        newSharedBoxIds.push((condition.value as NodeLinkOption).value);
    }
    if (
        isMultiCondition(condition) &&
        condition.isInput &&
        Array.isArray(condition.value) &&
        condition.value.length > 0
    ) {
        for (let subValue of condition.value) {
            if (subValue != null && (subValue as NodeLinkOption).isCloneInput) {
                newSharedBoxIds.push((subValue as NodeLinkOption).value);
            }
        }
    }
    return newSharedBoxIds;
}
export function isEmptyConditions(conditions?: Condition[]) {
    let filteredConditions: Condition[] | undefined = undefined;
    if (conditions != null) {
        filteredConditions = conditions.filter(
            (condition) =>
                condition.variable != null &&
                condition.operation != null &&
                condition.value != null &&
                (!Array.isArray(condition.value) || condition.value.length > 0)
        );
        return filteredConditions.length === 0;
    }
    return true;
}

export function isMultiCondition(condition: Condition): boolean {
    return (
        condition.operation != null &&
        ["in", "not in"].includes(condition.operation.value)
    );
}

export function getConditionValue(condition: Condition): any {
    if (isMultiCondition(condition)) {
        return condition.isInput
            ? (condition.value as NodeLinkOption[])?.map(
                  (value) => value.target
              )
            : (condition.value as SearchComponentOption[])?.map(
                  (value) => value.value
              );
    } else {
        return condition.isInput
            ? (condition.value as NodeLinkOption)?.target
            : (condition.value as SearchComponentOption)?.value;
    }
}

export function getConditionLabel(condition: Condition): any {
    if (isMultiCondition(condition)) {
        return condition.isInput
            ? (condition.value as NodeLinkOption[])?.map((value) => value.label)
            : (condition.value as SearchComponentOption[])?.map(
                  (value) => value.label
              );
    } else {
        return condition.isInput
            ? (condition.value as NodeLinkOption)?.label
            : (condition.value as SearchComponentOption)?.label;
    }
}

function conditionsToJson(conditions: Condition[]) {
    return conditions.map((condition: Condition) => ({
        operation: condition.operation?.value,
        logical: condition.logical?.value,
        variable: condition.variable?.value,
        value: getConditionValue(condition),
        leftBracket: condition.leftBracket?.value,
        rightBracket: condition.rightBracket?.value,
    }));
}

function conditionsToString(conditions: Condition[]): string {
    conditions = conditions.filter(
        (condition) =>
            condition.variable != null &&
            condition.value != null &&
            condition.operation != null
    );
    let str = conditions.reduce((accumulator, currentValue) => {
        let currentString = `${
            currentValue.logical != null
                ? " ".concat(currentValue.logical.value)
                : ""
        } ${currentValue.leftBracket?.value ?? ""}${
            currentValue.variable?.label ?? ""
        }${currentValue.operation?.value ?? ""}${
            getConditionLabel(currentValue) ?? ""
        }${currentValue.rightBracket?.value ?? ""}`;
        return accumulator.concat(currentString);
    }, "");
    if (str.length > 20) return str.slice(0, 20) + "...";
    return str;
}

interface Props {
    dataScopeId: number | string | undefined;
    currentModuleId?: number;
    allVariables: Variable[];
    onChange: (conditions: Condition[]) => void;
    title: string;
    value?: Condition[];
    style?: CSSProperties;
    small?: boolean;
    titleStyle?: CSSProperties;
    linkElementStyle?: CSSProperties;
    allowLinks?: boolean;
    nodeLinkOptions?: NodeLinkOption[];
    rowStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    buttonsStyle?: CSSProperties | ((rowIndex: number) => CSSProperties);
    linkSwitchStyle?: Partial<SwitchProps>;
}

interface FocusProps {
    onNext: () => void;
    onPrevious: () => void;
    children: JSX.Element;
}

function FocusIterable(props: FocusProps) {
    return React.cloneElement(React.Children.only(props.children), {
        onKeyDown: (evt: any) => {
            evt.stopPropagation();
            let value = (evt.target as any).value;
            if (evt.key === "Enter") {
                props.onNext();
            }
            if (evt.key === "Backspace" && value.length === 0) {
                props.onPrevious();
            }
        },
    });
}

class ConditionsSelector extends Component<Props> {
    selectGrid: {
        [key: string]: {
            [key: string]: any;
        };
    };
    public static get defaultValue() {
        return [
            {
                variable: null,
                operation: null,
                value: null,
                logical: logicalOperations[0],
                leftBracket: null,
                rightBracket: null,
            },
        ];
    }

    constructor(props: Props) {
        super(props);

        this.selectGrid = {};
    }
    private deleteCondition(index: number) {
        let lastIndex = (this.props.value ?? []).length - 1;
        this.changeConditions((conditions) => {
            let newConditions = Array.from(conditions);
            newConditions.splice(index, 1);
            if (newConditions.length === 0)
                newConditions.push({
                    variable: null,
                    operation: null,
                    value: null,
                    logical: logicalOperations[0],
                    leftBracket: null,
                    rightBracket: null,
                });
            return newConditions;
        });
        setTimeout(() => {
            delete this.selectGrid[lastIndex];
        }, 0);
    }

    private addCondition(index: number) {
        this.changeConditions((conditions) => {
            let newConditions = Array.from(conditions);
            newConditions.splice(index + 1, 0, {
                variable: null,
                operation: null,
                value: null,
                leftBracket: null,
                rightBracket: null,
                logical: logicalOperations[0],
            });
            return newConditions;
        });
    }

    private changeConditions(func: (conditions: Condition[]) => Condition[]) {
        const conditions: Condition[] = func(this.props.value ?? []);
        this.props.onChange(conditions);
    }

    private selectNextFocusItem(index: number, key: string): void {
        let keys = Object.keys(this.selectGrid[index] ?? {}).sort();
        let currentSubIndex = keys.findIndex((value) => value === key);
        if (currentSubIndex === -1) return;
        if (currentSubIndex < keys.length - 1) {
            this.selectGrid[index][keys[currentSubIndex + 1]]?.focus();
        } else {
            if (index < Object.keys(this.selectGrid).length - 1) {
                let newIndex = index + 1;
                let keys = Object.keys(this.selectGrid[newIndex]).sort();
                this.selectGrid[newIndex][keys[0]]?.focus();
            } else {
                this.addCondition(index);
                setTimeout(() => {
                    this.selectNextFocusItem(index, key);
                }, 0);
            }
        }
    }

    private selectPreviousFocusItem(index: number, key: string): void {
        let keys = Object.keys(this.selectGrid[index] ?? {}).sort();
        let currentSubIndex = keys.findIndex((value) => value === key);
        if (currentSubIndex === -1) return;
        if (currentSubIndex > 0) {
            this.selectGrid[index][keys[currentSubIndex - 1]]?.focus();
        } else {
            if (index > 0) {
                setTimeout(() => {
                    this.deleteCondition(index);
                    let newIndex = index - 1;
                    let keys = Object.keys(this.selectGrid[newIndex]).sort();
                    this.selectGrid[newIndex][keys[keys.length - 1]]?.focus();
                }, 0);
            }
        }
    }

    private toFunction<T>(
        value: T | ((rowIndex: number) => T) | undefined | null,
        defaultValue: T
    ): (rowIndex: number) => T {
        if (value == null) {
            return () => defaultValue;
        } else if (typeof value !== "function") {
            return () => value;
        } else {
            return value as (rowIndex: number) => T;
        }
    }

    public render(): JSX.Element {
        const conditionVariablesForSelection: VariableOption[] = this.props.allVariables.map(
            (variable, index) => ({
                label: variable.name,
                value: index,
                type: variable.type,
                panel: variable.panel,
            })
        );

        const conditions: Condition[] =
            this.props.value ?? ConditionsSelector.defaultValue;

        const small: boolean = this.props.small ?? false;
        return (
            <div
                className="flex-simple-column"
                style={{
                    marginTop: small ? 5 : 25,
                    marginLeft: small ? undefined : 100,
                    minHeight: small ? undefined : 300,
                    ...this.props.style,
                }}
                onKeyDown={(evt) => {
                    if (
                        evt.key === "ArrowDown" ||
                        evt.key === "ArrowUp" ||
                        evt.key === "ArrowRight"
                    )
                        evt.stopPropagation();
                }}
            >
                {conditions.map((condition: Condition, index: number) => (
                    <div className={styles.expressionRow} key={index}>
                        {small && index === 0 && (
                            <span className={styles.title}>
                                {this.props.title}
                            </span>
                        )}
                        <div
                            className={styles.expression}
                            style={{
                                border: "1px solid rgb(204, 204, 204)",
                                borderRadius: "4px",
                                padding: "2px",
                            }}
                        >
                            {index > 0 && (
                                <div
                                    style={{
                                        width: 80,
                                        borderRight: "1px solid #E2E2E2",
                                    }}
                                >
                                    <FocusIterable
                                        onNext={() => {
                                            this.selectNextFocusItem(
                                                index,
                                                "0"
                                            );
                                        }}
                                        onPrevious={() => {
                                            this.selectPreviousFocusItem(
                                                index,
                                                "0"
                                            );
                                        }}
                                    >
                                        <Select
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            ref={(ref) => {
                                                this.selectGrid[index] = {
                                                    ...this.selectGrid[index],
                                                    "0": ref,
                                                };
                                            }}
                                            placeholder=""
                                            styles={{
                                                control: (provided): any => ({
                                                    ...provided,
                                                    borderRadius: "4px",
                                                    borderColor: "transparent",
                                                    "&:hover": {
                                                        borderColor:
                                                            "rgb(204, 204, 204)",
                                                    },
                                                }),
                                                indicatorSeparator: (
                                                    provided
                                                ): any => ({
                                                    ...provided,
                                                    display: "none",
                                                }),
                                            }}
                                            options={logicalOperations}
                                            value={condition.logical}
                                            onChange={(newValue) => {
                                                this.changeConditions(
                                                    (conditions) => {
                                                        let newConditions: Condition[] = Array.from(
                                                            conditions
                                                        );
                                                        newConditions[index] = {
                                                            ...newConditions[
                                                                index
                                                            ],
                                                            logical: (newValue ??
                                                                null) as StringOption | null,
                                                        };
                                                        return newConditions;
                                                    }
                                                );
                                            }}
                                        />
                                    </FocusIterable>
                                </div>
                            )}
                            <div
                                className={cx(
                                    styles.parenthesisField,
                                    styles.leftParenthesis
                                )}
                            >
                                (
                            </div>
                            <div
                                className={styles.field}
                                style={{ flex: "1 1 auto" }}
                            >
                                <FocusIterable
                                    onNext={() => {
                                        this.selectNextFocusItem(index, "3");
                                    }}
                                    onPrevious={() => {
                                        this.selectPreviousFocusItem(
                                            index,
                                            "3"
                                        );
                                    }}
                                >
                                    <Select
                                        ref={(ref) => {
                                            this.selectGrid[index] = {
                                                ...this.selectGrid[index],
                                                "3": ref,
                                            };
                                        }}
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder=""
                                        styles={{
                                            control: (provided): any => ({
                                                ...provided,
                                                borderRadius: "4px",
                                                borderColor: "transparent",
                                                "&:hover": {
                                                    borderColor:
                                                        "rgb(204, 204, 204)",
                                                },
                                            }),
                                            indicatorSeparator: (
                                                provided
                                            ): any => ({
                                                ...provided,
                                                display: "none",
                                            }),
                                        }}
                                        options={conditionVariablesForSelection}
                                        value={condition.variable}
                                        onChange={(newValue) => {
                                            this.changeConditions(
                                                (conditions) => {
                                                    let newConditions = Array.from(
                                                        conditions
                                                    );
                                                    newConditions[index] = {
                                                        ...newConditions[index],
                                                        variable: (newValue ??
                                                            null) as VariableOption | null,
                                                        value: null,
                                                        operation: null,
                                                    };
                                                    return newConditions;
                                                }
                                            );
                                        }}
                                    />
                                </FocusIterable>
                            </div>
                            <div className={styles.field} style={{ width: 95 }}>
                                <FocusIterable
                                    onNext={() => {
                                        this.selectNextFocusItem(index, "4");
                                    }}
                                    onPrevious={() => {
                                        this.selectPreviousFocusItem(
                                            index,
                                            "4"
                                        );
                                    }}
                                >
                                    <Select
                                        ref={(ref) => {
                                            this.selectGrid[index] = {
                                                ...this.selectGrid[index],
                                                "4": ref,
                                            };
                                        }}
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder=""
                                        styles={{
                                            control: (provided): any => ({
                                                ...provided,
                                                borderRadius: "4px",
                                                borderColor: "transparent",
                                                "&:hover": {
                                                    borderColor:
                                                        "rgb(204, 204, 204)",
                                                },
                                            }),
                                            indicatorSeparator: (
                                                provided
                                            ): any => ({
                                                ...provided,
                                                display: "none",
                                            }),
                                        }}
                                        options={
                                            condition.variable != null &&
                                            ![
                                                "int",
                                                "float",
                                                "datetime",
                                            ].includes(condition.variable.type)
                                                ? comparisonPanelOperations
                                                : comparisonOperations
                                        }
                                        onChange={(newValue) => {
                                            this.changeConditions(
                                                (conditions) => {
                                                    let newConditions = Array.from(
                                                        conditions
                                                    );
                                                    newConditions[index] = {
                                                        ...newConditions[index],
                                                        operation: (newValue ??
                                                            null) as StringOption | null,
                                                    };
                                                    let condition =
                                                        newConditions[index];
                                                    if (
                                                        isMultiCondition(
                                                            condition
                                                        )
                                                    ) {
                                                        if (
                                                            !Array.isArray(
                                                                condition.value
                                                            )
                                                        ) {
                                                            condition.value =
                                                                condition.value !=
                                                                null
                                                                    ? [
                                                                          condition.value,
                                                                      ]
                                                                    : [];
                                                        }
                                                    } else {
                                                        if (
                                                            Array.isArray(
                                                                condition.value
                                                            )
                                                        ) {
                                                            condition.value =
                                                                condition
                                                                    .value?.[0] ??
                                                                null;
                                                        }
                                                    }
                                                    return newConditions;
                                                }
                                            );
                                        }}
                                        value={condition.operation}
                                    />
                                </FocusIterable>
                            </div>
                            <div
                                className={cx(
                                    styles.conditionField,
                                    styles.field
                                )}
                            >
                                {!condition.isInput &&
                                condition.variable != null &&
                                (condition.variable.panel !== "regular" ||
                                    condition.variable.type === "str" ||
                                    condition.variable.type === "datetime") ? (
                                    <div>
                                        <FocusIterable
                                            onNext={() => {
                                                this.selectNextFocusItem(
                                                    index,
                                                    "5"
                                                );
                                            }}
                                            onPrevious={() => {
                                                this.selectPreviousFocusItem(
                                                    index,
                                                    "5"
                                                );
                                            }}
                                        >
                                            <SearchComponent
                                                isMulti={isMultiCondition(
                                                    condition
                                                )}
                                                allowNewOption={
                                                    condition.variable.panel ===
                                                    "time"
                                                }
                                                currentModuleId={
                                                    this.props.currentModuleId
                                                }
                                                ref={(ref) => {
                                                    this.selectGrid[index] = {
                                                        ...this.selectGrid[
                                                            index
                                                        ],
                                                        "5": ref,
                                                    };
                                                }}
                                                dataScopeId={
                                                    this.props.dataScopeId
                                                }
                                                initialValue={condition.value}
                                                searchIndex={
                                                    condition.variable.value
                                                }
                                                onOptionSelected={(
                                                    selectedValue
                                                ) => {
                                                    this.changeConditions(
                                                        (conditions) => {
                                                            let newConditions = Array.from(
                                                                conditions
                                                            );
                                                            newConditions[
                                                                index
                                                            ] = {
                                                                ...newConditions[
                                                                    index
                                                                ],
                                                                value: selectedValue,
                                                            };
                                                            return newConditions;
                                                        }
                                                    );
                                                }}
                                            />
                                        </FocusIterable>
                                    </div>
                                ) : !condition.isInput ? (
                                    <FocusIterable
                                        onNext={() => {
                                            this.selectNextFocusItem(
                                                index,
                                                "5"
                                            );
                                        }}
                                        onPrevious={() => {
                                            this.selectPreviousFocusItem(
                                                index,
                                                "5"
                                            );
                                        }}
                                    >
                                        <input
                                            className={styles.input}
                                            ref={(ref) => {
                                                this.selectGrid[index] = {
                                                    ...this.selectGrid[index],
                                                    "5": ref,
                                                };
                                            }}
                                            placeholder=""
                                            onChange={(e) => {
                                                const value: string =
                                                    e.target.value;
                                                this.changeConditions(
                                                    (conditions) => {
                                                        let newConditions = Array.from(
                                                            conditions
                                                        );
                                                        newConditions[index] = {
                                                            ...newConditions[
                                                                index
                                                            ],
                                                            value: {
                                                                label: value,
                                                                value: value,
                                                            },
                                                        };
                                                        return newConditions;
                                                    }
                                                );
                                            }}
                                            value={getConditionValue(condition)}
                                        />
                                    </FocusIterable>
                                ) : (
                                    <div>
                                        <FocusIterable
                                            onNext={() => {
                                                this.selectNextFocusItem(
                                                    index,
                                                    "5"
                                                );
                                            }}
                                            onPrevious={() => {
                                                this.selectPreviousFocusItem(
                                                    index,
                                                    "5"
                                                );
                                            }}
                                        >
                                            <Select
                                                isMulti={isMultiCondition(
                                                    condition
                                                )}
                                                ref={(ref) => {
                                                    this.selectGrid[index] = {
                                                        ...this.selectGrid[
                                                            index
                                                        ],
                                                        "5": ref,
                                                    };
                                                }}
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder=""
                                                styles={{
                                                    control: (
                                                        provided
                                                    ): any => ({
                                                        ...provided,
                                                        borderRadius: "4px",
                                                        borderColor:
                                                            "transparent",
                                                        "&:hover": {
                                                            borderColor:
                                                                "rgb(204, 204, 204)",
                                                        },
                                                    }),
                                                    indicatorSeparator: (
                                                        provided
                                                    ): any => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                }}
                                                options={
                                                    this.props.nodeLinkOptions
                                                }
                                                value={condition.value}
                                                onChange={(newValue) => {
                                                    this.changeConditions(
                                                        (conditions) => {
                                                            let newConditions = Array.from(
                                                                conditions
                                                            );
                                                            newConditions[
                                                                index
                                                            ] = {
                                                                ...newConditions[
                                                                    index
                                                                ],
                                                                value: newValue as NodeLinkOption,
                                                            };
                                                            return newConditions;
                                                        }
                                                    );
                                                }}
                                            />
                                        </FocusIterable>
                                    </div>
                                )}
                                {this.props.allowLinks && (
                                    <div className={styles.linkElement}>
                                        <Switch
                                            onChange={() => {
                                                this.changeConditions(
                                                    (conditions) => {
                                                        let newConditions = Array.from(
                                                            conditions
                                                        );
                                                        newConditions[index] = {
                                                            ...newConditions[
                                                                index
                                                            ],
                                                            isInput: !(
                                                                condition.isInput ??
                                                                false
                                                            ),
                                                            value: isMultiCondition(
                                                                condition
                                                            )
                                                                ? []
                                                                : null,
                                                        };
                                                        return newConditions;
                                                    }
                                                );
                                            }}
                                            checked={condition.isInput ?? false}
                                            width={26}
                                            height={13}
                                            offColor="#20293C"
                                            onColor="#20293C"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            offHandleColor="#70889E"
                                            onHandleColor="#1F8EFA"
                                            {...this.props.linkSwitchStyle}
                                        />
                                        <span
                                            className={styles.linkElementLabel}
                                        >
                                            Link element
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div
                                className={cx(
                                    styles.parenthesisField,
                                    styles.rightParenthesis
                                )}
                            >
                                )
                            </div>
                        </div>
                        <div className="flex-simple-column">
                            <Button
                                className="btn-small-like-select"
                                style={{
                                    width: "19px",
                                    height: "19px",
                                    background: "transparent",
                                    color: "rgba(36, 33, 36, 0.7)",
                                }}
                                onClick={() => {
                                    this.addCondition(index);
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>
                            <Button
                                className="btn-small-like-select"
                                style={{
                                    width: "19px",
                                    height: "19px",
                                    background: "transparent",
                                    color: "rgba(36, 33, 36, 0.7)",
                                }}
                                onClick={() => {
                                    this.deleteCondition(index);
                                }}
                            >
                                {"\uFF0D" /* minus */}
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export { conditionsToString, conditionsToJson, ConditionsSelector };
