import "common/styles/App.css";
import React from "react";
import { reaction } from "mobx";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";

import { getLabelByTime } from "common/MonthData";
import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";
import axios from "common/ServerConnection";
import TimePatternsChart from "common/graphics/TimePatternsChart";
import "rc-slider/assets/index.css";
import BaseJourney from "../../common/BaseJourney";
import { Api } from "./Api";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";
import RangeSelector from "common/graphics/RangeSelector";

const Slider = require("rc-slider");
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const Step = Object.freeze({
    initial: 0,
    selectDataScope: 1,
    selectTable: 2,
    selectConditions: 3,
    selectTarget: 4,
    selectTimeParameter: 5,
    selectTimeRange: 6,
    selectDisplayMode: 7,
    selectDateFormat: 8,
    selectAdditionalParameter: 9,
    selectAdditionalValue: 10,
    final: 11,
});

function StepFinal(props) {
    let {
        onBack,
        onNewFinding,
        onShowRecipesPopup,
        onSaveFinding,
        finding,
        loading,
        // data
    } = props;
    return (
        <>
            <div
                className="my-row"
                style={{
                    justifyContent: "space-between",
                    marginTop: 10,
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    onClick={() => {
                        onBack();
                    }}
                    style={{
                        minWidth: 100,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/data-exploration/chevron_back.png"
                    />
                </div>
                {loading ? (
                    <span className="exploration-big-title-span">
                        {"LOADING"}
                    </span>
                ) : (
                    finding && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "calc(100% - 200px)",
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "center",
                                    width: "100%",
                                    height: "380px",
                                }}
                            >
                                <ErrorBoundary>
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: 10,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <RangeSelector
                                            value={finding}
                                            onChange={onNewFinding}
                                        />
                                    </div>
                                    <div style={{ height: "330px" }}>
                                        <TimePatternsChart
                                            isTimeSeries={
                                                finding.content.isTimeSeries
                                            }
                                            onNewLine={(line) => {
                                                let newFinding = { ...finding };
                                                if (
                                                    newFinding.content
                                                        .additionalData == null
                                                ) {
                                                    newFinding.content.additionalData = [];
                                                }
                                                newFinding.content.additionalData.push(
                                                    line
                                                );
                                                onNewFinding(newFinding);
                                            }}
                                            onClearLines={() => {
                                                let newFinding = { ...finding };
                                                newFinding.content.additionalData = [];
                                                onNewFinding(newFinding);
                                            }}
                                            additionalData={
                                                finding.content.additionalData
                                            }
                                            data={finding.content.data}
                                            timeKey={finding.content.timeKey}
                                            displayMode={
                                                finding.config.displayMode
                                            }
                                            dateFormat={
                                                finding.config.dateFormat
                                            }
                                            config={finding.config}
                                            editable
                                            onConfigChange={(field, value) => {
                                                let newFinding = { ...finding };
                                                newFinding.config[
                                                    field
                                                ] = value;
                                                onNewFinding(newFinding);
                                            }}
                                            onFinishEdit={(
                                                serieIndex,
                                                barKey,
                                                label
                                            ) => {
                                                let newFinding = { ...finding };
                                                if (
                                                    newFinding.config
                                                        .nameMapping == null
                                                )
                                                    newFinding.config.nameMapping = {};
                                                newFinding.config.nameMapping[
                                                    serieIndex
                                                ] = {
                                                    ...finding.config
                                                        .nameMapping[
                                                        serieIndex
                                                    ],
                                                    [barKey]: label,
                                                };
                                                onNewFinding(newFinding);
                                            }}
                                            onFinishEditLabel={(
                                                barKey,
                                                label
                                            ) => {
                                                let newFinding = { ...finding };
                                                newFinding.config.labelMapping = {
                                                    ...finding.config
                                                        .labelMapping,
                                                    [barKey]: label,
                                                };
                                                onNewFinding(newFinding);
                                            }}
                                            onFinishColor={(
                                                serieIndex,
                                                barKey,
                                                color
                                            ) => {
                                                let newFinding = { ...finding };
                                                if (
                                                    newFinding.config
                                                        .colorMapping == null
                                                )
                                                    newFinding.config.colorMapping = {};
                                                newFinding.config.colorMapping[
                                                    serieIndex
                                                ] = {
                                                    ...finding.config
                                                        .colorMapping[
                                                        serieIndex
                                                    ],
                                                    [barKey]: color,
                                                };
                                                onNewFinding(newFinding);
                                            }}
                                        />
                                    </div>
                                </ErrorBoundary>
                            </div>

                            <FinalStepBottomBar
                                onShowRecipesPopup={onShowRecipesPopup}
                                onSaveFinding={onSaveFinding}
                                topMargin={20}
                            />
                        </div>
                    )
                )}
                <div
                    style={{
                        minWidth: 100,
                        height: "100%",
                    }}
                />
            </div>
        </>
    );
}

class MainComponent extends BaseJourney {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            previousStep: 0,
            step: 0,
            animationDirection: "top",
            selectedTargetVariables: [],
            targetVariables: [],
            finding: undefined,
            timeParameter: undefined,
            timeParameters: [],
            timeRange: [],
            additionalParameter: undefined,
            additionalParameters: [],
            selectedTable: undefined,
            step2VariablesMap: {
                [Step.selectTarget]: [
                    "selectedTargetVariables",
                    "targetVariables",
                ],
                [Step.selectTimeParameter]: ["timeParameter", "timeParameters"],
                [Step.selectAdditionalParameter]: [
                    "additionalParameter",
                    "additionalParameters",
                ],
                [Step.selectDisplayMode]: ["displayMode", "displayModes"],
            },
            conditions: [
                {
                    variable: null,
                    operation: null,
                    value: null,
                    logical: null,
                    leftBracket: null,
                    rightBracket: null,
                },
            ],
            displayMode: undefined,
            displayModes: ["Date", "Year Count", "Month Count", "Day Count"],
            dateFormat: [],
            dateFormats: ["Year", "Month", "Day"],
        };
        this.getData = this.getData.bind(this);
        this.getTimeRange = this.getTimeRange.bind(this);
        this.initializeVariables = this.initializeVariables.bind(this);
        this.switchVariables = this.switchVariables.bind(this);
    }
    switchVariables() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
        this.initializeVariables();
        this.initializeVariablesReaction = reaction(
            () => this.getVariables().dataVariables,
            () => {
                this.initializeVariables();
            }
        );
    }
    clearOptionalArgs() {
        this.setState({
            selectedTargetVariables: [],
            selectedTargetVariablesIndices: [],
            additionalParameter: null,
            additionalParameterIndex: null,
            leftAdditionalOperator: null,
            leftAdditionalValue: null,
            rightAdditionalOperator: null,
            rightAdditionalValue: null,
            selectedTable: this.defaultTable(),
            conditions: ConditionsSelector.defaultValue,
            dynamicOptions: {},
            leftTimeValue: null,
            rightTimeValue: null,
            timeParameter: null,
            timeParameterIndex: null,
            nameMapping: null,
            labelMapping: null,
            colorMapping: null,
            legendsColor: null,
            titlesColor: null,
            axesColor: null,
            maxYRange: null,
            minYRange: null,
            linesCount: null,
            yAxisName: null,
        });
    }
    // We need to convert undefined to null to remove field if used in canvas page
    getConfig() {
        let config = {
            version: configVersion,
            dataScope: this.state.dataScope ?? null,
            selectedTable: this.state.selectedTable ?? null,
            conditions: this.state.conditions ?? null,
            selectedTargetVariables: this.state.selectedTargetVariables ?? null,
            selectedTargetVariablesIndices:
                this.state.selectedTargetVariables.map((item) =>
                    this.getVariables().variableToIndex(item)
                ) ?? null,
            additionalParameters: this.state.additionalParameters ?? null,
            leftAdditionalOperator: this.state.leftAdditionalOperator ?? null,
            rightAdditionalOperator: this.state.rightAdditionalOperator ?? null,
            leftAdditionalValue: this.state.leftAdditionalValue ?? null,
            rightAdditionalValue: this.state.rightAdditionalValue ?? null,
            leftTimeValue: this.state.leftTimeValue ?? null,
            rightTimeValue: this.state.rightTimeValue ?? null,
            additionalParameter: this.state.additionalParameter ?? null,
            additionalParameterIndex:
                this.getVariables().variableToIndex(
                    this.state.additionalParameter
                ) ?? null,
            timeParameter: this.state.timeParameter ?? null,
            timeParameterIndex:
                this.getVariables().variableToIndex(this.state.timeParameter) ??
                null,
            displayMode: this.state.displayMode ?? "Date",
            dateFormat: this.state.dateFormat ?? ["Year", "Month", "Day"],
            journeyName: this.props.journeyName,
            nameMapping: this.state.nameMapping ?? null,
            labelMapping: this.state.labelMapping ?? null,
            colorMapping: this.state.colorMapping ?? null,
            legendsColor: this.state.legendsColor ?? null,
            titlesColor: this.state.titlesColor ?? null,
            axesColor: this.state.axesColor ?? null,
            maxYRange: this.state.maxYRange ?? null,
            minYRange: this.state.minYRange ?? null,
            linesCount: this.state.linesCount ?? null,
            yAxisName: this.state.yAxisName ?? null,
        };
        if (this.props.addToCanvas) {
            config.dynamicOptions = this.prepareDynamicOptions(
                this.state.dynamicOptions,
                config
            );
        }
        return config;
    }
    getTimeRange() {
        if (this.state.timeParameter) {
            axios
                .post(
                    "/api/e/autocomplete",
                    {
                        data_table_idx: this.dataScopeValue(),
                        variable_index: this.getVariables().variableToIndex(
                            this.state.timeParameter
                        ),
                        starts_with: "",
                        filter_missing: true,
                        module_id: this.props.currentModuleId,
                    },
                    null
                )
                .then((response) => {
                    if (response.data.success)
                        this.setState((state) => ({
                            timeRange: response.data.items,
                            leftTimeValue:
                                !state.leftTimeValue &&
                                response.data.items.length > 0
                                    ? response.data.items[0]
                                    : state.leftTimeValue,
                            rightTimeValue:
                                !state.rightTimeValue &&
                                response.data.items.length > 0
                                    ? response.data.items[
                                          response.data.items.length - 1
                                      ]
                                    : state.rightTimeValue,
                        }));
                })
                .catch((error) => {
                    console.log(String(error));
                });
        }
    }
    getData() {
        this.setState({ loading: true });

        Api.getData(this.getConfig(), undefined, this.props.currentModuleId)
            .then((finding) => {
                this.setState({ finding: finding, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error);
            });
    }
    buildTimeRangeSelector() {
        if (this.state.timeRange == null || this.state.timeRange.length === 0)
            return null;
        return (
            <div className="my-row" style={{ height: "100%" }}>
                {this.buildLeftBar()}
                <div
                    className="flex-simple-column"
                    style={{
                        marginTop: 25,
                        marginLeft: 100,
                        width: "100%",
                    }}
                >
                    <span className="exploration-big-title-span">
                        {"Over the time range"}
                    </span>
                    {this.state.timeRange.length > 0 && (
                        <div style={{ width: "calc(100% - 100px)" }}>
                            <Range
                                tipFormatter={(value) => {
                                    return getLabelByTime(value);
                                }}
                                min={this.state.timeRange[0]}
                                max={
                                    this.state.timeRange[
                                        this.state.timeRange.length - 1
                                    ]
                                }
                                marks={this.state.timeRange.reduce(function (
                                    map,
                                    obj
                                ) {
                                    map[obj] = {};
                                    return map;
                                },
                                {})}
                                step={null}
                                onChange={(values) => {
                                    this.setState({
                                        leftTimeValue: values[0],
                                        rightTimeValue: values[1],
                                    });
                                }}
                                defaultValue={[
                                    this.state.leftTimeValue,
                                    this.state.rightTimeValue,
                                ]}
                            />
                        </div>
                    )}
                    <div style={{ height: 250 }} />
                </div>
            </div>
        );
    }

    buildContent() {
        switch (this.state.step) {
            case Step.initial:
                return this.buildInitial(
                    "How do you want to examine the time patterns of your variables?"
                );
            case Step.selectDataScope:
                return this.buildDataScopesSelector();
            case Step.selectTable:
                return this.buildTableSelector();
            case Step.selectConditions:
                return this.buildConditionsSelector();
            case Step.selectTarget:
                return this.buildMultiVariablesSelector(
                    "I want to examine",
                    "selectedTargetVariables",
                    "targetVariables",
                    DynamicOptionType.variable,
                    "selectedTargetVariablesIndices"
                );
            case Step.selectTimeParameter:
                return this.buildVariablesSelector(
                    "by time",
                    "timeParameter",
                    "timeParameters",
                    false,
                    DynamicOptionType.variable,
                    "timeParameterIndex"
                );
            case Step.selectTimeRange:
                return this.buildTimeRangeSelector();
            case Step.selectDisplayMode:
                return this.buildVariablesSelector(
                    "and display",
                    "displayMode",
                    "displayModes"
                );
            case Step.selectDateFormat:
                return this.buildVariablesSelector(
                    "and display date",
                    "dateFormat",
                    "dateFormats",
                    true
                );
            case Step.selectAdditionalParameter:
                return this.buildVariablesSelector(
                    "[+] and how they vary by",
                    "additionalParameter",
                    "additionalParameters",
                    false,
                    DynamicOptionType.additionalParameter,
                    "additionalParameterIndex"
                );
            case Step.selectAdditionalValue:
                return this.buildAdditionalValueSelector(
                    `[+] and how they vary by ${this.state.additionalParameter}`,
                    true
                );
            case Step.final:
                return (
                    <StepFinal
                        onShowRecipesPopup={this.showRecipesPopup}
                        loading={this.state.loading}
                        finding={this.state.finding}
                        onSaveFinding={this.saveFinding}
                        onBack={this.back}
                        onNewFinding={this.onNewFinding}
                    />
                );

            default:
                return <div />;
        }
    }
    buildLeftBar() {
        return super.buildLeftBar(this.state.step < Step.final - 1);
    }
    initializeVariables() {
        let scopeVariables = this.getVariables();
        let targetVariables = scopeVariables.numericVariables;
        this.setState(
            {
                timeParameters: scopeVariables.dataVariables
                    .filter((item) => item.type === "datetime")
                    .map((item) => item.name),
                additionalParameters: [null].concat(
                    scopeVariables.dataVariables
                        .filter((item) => item.panel !== "time")
                        .map((item) => item.name)
                ),
                targetVariables: targetVariables.map((item) => item.name),
            },
            () => {
                this.getTimeRange();
            }
        );
    }
    componentDidMount() {
        this.getLastJourneyConfig();
    }

    componentWillUnmount() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
    }
    showFinalStep() {
        if (
            this.state.dataScope &&
            this.state.selectedTable &&
            this.state.selectedTargetVariables &&
            this.state.step > Step.initial &&
            this.state.step < Step.final
        )
            this.setState({ animationDirection: "top" }, () => {
                this.setState(
                    (state) => ({
                        previousStep: state.step,
                        step: Step.final,
                    }),
                    () => {
                        this.getData();
                        this.saveNewJourneyConfig();
                    }
                );
            });
    }

    selectVariable(variableName, variablesName, index) {
        let stateDiff = {};
        let multiselection = Array.isArray(this.state[variableName]);
        if (!multiselection) {
            stateDiff = {
                [variableName]: this.state[variablesName][index],
            };
        } else {
            let selectedVariables = Array.from(this.state[variableName]);
            let newVariable = this.state[variablesName][index];
            if (selectedVariables.includes(newVariable)) {
                selectedVariables = selectedVariables.filter(
                    (item) => item !== newVariable
                );
            } else {
                selectedVariables.push(newVariable);
            }
            let allVariables = Array.from(this.state[variablesName]);
            selectedVariables = selectedVariables.filter((variable) =>
                allVariables.includes(variable)
            );
            stateDiff = {
                [variableName]: selectedVariables,
            };
        }
        if (this.state.step === Step.selectAdditionalParameter) {
            stateDiff.leftAdditionalValue = undefined;
            stateDiff.rightAdditionalValue = undefined;
        }

        this.setState(stateDiff, () => {
            if (this.state.step === Step.selectTimeParameter) {
                this.getTimeRange();
            }
            if (
                this.state.step !== Step.selectTarget &&
                this.state.step !== Step.selectDateFormat
            )
                setTimeout(() => {
                    this.stepDown();
                }, 100);
        });
    }

    getSubtitle() {
        let conditionsString = conditionsToString(
            Array.from(this.state.conditions)
        );
        const displayMode = this.state.displayMode ?? "Date";
        const dateFormat = this.state.dateFormat ?? ["Year", "Month", "Day"];
        if (
            this.state.step === Step.selectTable ||
            this.state.step === Step.selectDataScope
        )
            return "How do you want to examine your variables?";
        if (
            this.state.step > Step.selectTable &&
            this.state.step < Step.final
        ) {
            let subtitle = `For the data in ${
                this.state.dataScope ? this.state.dataScope.label : ""
            } ${this.state.selectedTable.label}`;
            if (this.state.step > Step.selectConditions) {
                if (conditionsString.length > 0)
                    subtitle += ` and under the conditions ${conditionsString}`;
            }
            if (this.state.step > Step.selectTarget)
                subtitle += ` I want to examine ${this.state.selectedTargetVariables.join(
                    ", "
                )},`;
            if (this.state.step > Step.selectTimeParameter)
                subtitle += ` by time ${this.state.timeParameter || ""}`;
            if (this.state.step > Step.selectTimeRange)
                subtitle += ` over the time range ${
                    getLabelByTime(this.state.leftTimeValue) || ""
                } - ${getLabelByTime(this.state.rightTimeValue) || ""}`;
            if (this.state.step > Step.selectDisplayMode)
                subtitle += ` and display ${displayMode.toLowerCase()}`;
            if (
                this.state.step > Step.selectDateFormat &&
                displayMode === "Date"
            )
                subtitle += ` (${dateFormat
                    .map((f) => f.toLowerCase())
                    .join(", ")})`;
            if (this.state.step > Step.selectAdditionalParameter)
                subtitle += ` and how they vary by ${
                    this.state.additionalParameter || ""
                }`;

            return subtitle;
        }

        if (this.state.step === Step.final) {
            return (
                <>
                    <span>For the data </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.dataScope
                            ? this.state.dataScope.label.concat(" ")
                            : ""}
                    </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedTable.label}
                    </span>
                    {conditionsString.length > 0 && (
                        <>
                            <span> and under the conditions </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {conditionsString}
                            </span>
                        </>
                    )}
                    <span> I want to examine </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedTargetVariables.join(", ")}
                    </span>
                    <span> by time </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.timeParameter || ""}
                    </span>
                    <span> over the time range </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {`${getLabelByTime(this.state.leftTimeValue) || ""} - ${
                            getLabelByTime(this.state.rightTimeValue) || ""
                        }`}
                    </span>
                    {this.state.additionalParameter &&
                    [
                        this.state.leftAdditionalValue,
                        this.state.rightAdditionalValue,
                    ]
                        .filter((item) => item)
                        .filter((item) => item.value).length > 0 ? (
                        <>
                            <span> and how they vary by </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {(this.state.leftAdditionalValue?.label != null
                                    ? ` ${this.state.additionalParameter} ${
                                          this.state.leftAdditionalOperator
                                              ?.label ?? "="
                                      } ${this.state.leftAdditionalValue.label}`
                                    : "") +
                                    (this.state.rightAdditionalValue?.label !=
                                    null
                                        ? (this.state.leftAdditionalValue
                                              ?.label != null
                                              ? ", "
                                              : " ") +
                                          `${this.state.additionalParameter} ${
                                              this.state.rightAdditionalOperator
                                                  ?.label ?? "="
                                          } ${
                                              this.state.rightAdditionalValue
                                                  .label
                                          }`
                                        : "")}
                            </span>
                        </>
                    ) : null}
                    <span>.</span>
                </>
            );
        }
        return "";
    }
    getFooterTitle() {
        switch (this.state.step) {
            case Step.initial:
                return "... For the data in ...";
            case Step.selectTable:
                return "... and under the conditions ...";
            case Step.selectConditions:
                return "... I want to examine ...";
            case Step.selectTarget:
                return "... by time ...";
            case Step.selectTimeParameter:
                return "... over the time range ...";
            case Step.selectTimeRange:
                return "... and display ...";
            case Step.selectDisplayMode:
                return "... [optional] for ...";
            case Step.selectDateFormat:
                return "... [optional] for ...";
            default:
                return "";
        }
    }
    stepDown() {
        let delta = 1;
        if (
            (this.state.step === Step.selectTable &&
                !this.state.selectedTable) ||
            (this.state.step === Step.selectDisplayMode &&
                !this.state.displayMode) ||
            (this.state.step === Step.selectDateFormat &&
                this.state.dateFormat.length === 0)
        ) {
            return;
        }
        if (
            this.state.step === Step.selectAdditionalParameter &&
            !this.state.additionalParameter
        )
            delta = 2;
        else if (
            this.state.step === Step.selectDisplayMode &&
            this.state.displayMode !== "Date"
        )
            delta = 2;
        if (this.state.step + delta < Step.final) {
            this.setState((state) => ({
                previousStep: state.step,
                step: state.step + delta,
            }));
        } else {
            this.showFinalStep();
        }
    }

    stepUp() {
        let step = this.state.step;
        let delta = 1;
        if (
            step === Step.selectAdditionalParameter &&
            this.state.displayMode !== "Date"
        )
            delta = 2;
        if (step > Step.initial)
            this.setState({ animationDirection: "bottom" }, () => {
                this.setState({
                    step: step - delta,
                });
            });
    }

    render() {
        return (
            <div
                className="dashboard-rect-journey-focus"
                tabIndex="0"
                style={{
                    height: 570,
                    overflow: "hidden",
                    display: !this.props.collapsed ? "block" : "none",
                }}
                onClick={() => {
                    if (this.state.step === Step.initial) this.stepDown();
                }}
                onKeyDown={(evt) => {
                    if (evt.key === "Escape") {
                        this.props.onClose();
                    }
                    if (evt.key === "ArrowDown") {
                        if (
                            this.state.step > Step.initial &&
                            this.state.step < Step.final
                        )
                            this.stepDown();
                        evt.preventDefault();
                    }
                    if (evt.key === "ArrowLeft") {
                        if (this.state.step === Step.final) {
                            this.back();
                        }
                    }
                    if (evt.key === "ArrowRight") {
                        this.showFinalStep();
                    }
                    if (evt.key === "ArrowUp") {
                        if (this.state.step < Step.final) this.stepUp();
                        evt.preventDefault();
                    }
                    if (evt.key === "p") {
                        if (this.state.step === Step.final) {
                            this.saveFinding();
                        }
                    }
                    if (evt.key === "d") {
                        if (this.state.step === Step.final) {
                            evt.preventDefault();
                            this.showRecipesPopup();
                        }
                    }

                    let variableInfo = this.state.step2VariablesMap[
                        this.state.step
                    ];
                    if (variableInfo) {
                        let variablesLength = this.state[variableInfo[1]]
                            .length;
                        let variableIndex = evt.keyCode - 65;
                        if (
                            variableIndex >= 0 &&
                            variableIndex < variablesLength
                        ) {
                            this.selectVariable(
                                variableInfo[0],
                                variableInfo[1],
                                variableIndex
                            );
                        }
                    }
                }}
            >
                <div
                    className="my-row"
                    style={{ justifyContent: "space-between", height: "100%" }}
                >
                    <div
                        className="flex-column"
                        style={{
                            height: "100%",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {this.buildHeader()}

                        <TransitionGroup
                            style={{
                                minHeight: "inherit",
                            }}
                        >
                            <CSSTransition
                                style={{
                                    height: "100%",
                                }}
                                key={this.state.step}
                                timeout={500}
                                classNames={"journeywizard-".concat(
                                    this.state.animationDirection || ""
                                )}
                            >
                                <div
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    {this.buildContent()}
                                </div>
                            </CSSTransition>
                        </TransitionGroup>

                        <Footer
                            showArrow={
                                this.state.step > Step.initial &&
                                this.state.step < Step.final
                            }
                            title={this.getFooterTitle()}
                        />
                    </div>
                </div>
                {this.buildAddToRecipesPopup()}
            </div>
        );
    }
}

export { MainComponent };
