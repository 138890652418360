import "common/styles/App.css";
import React from "react";
import { reaction } from "mobx";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import WordCloud from "common/graphics/WordCloud";
import BaseJourney from "../../common/BaseJourney";
import { Api } from "./Api";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import Switch from "react-switch";

const Step = Object.freeze({
    initial: 0,
    selectDataScope: 1,
    selectConditions: 2,
    selectTarget: 3,
    selectCount: 4,
    final: 5,
});

function StepFinal(props) {
    let { onBack, onShowRecipesPopup, onSaveFinding, finding, loading } = props;
    return (
        <>
            <div
                className="my-row"
                style={{
                    justifyContent: "space-between",
                    marginTop: 10,
                    height: "100%",
                    width: "100%",
                }}
            >
                <div
                    onClick={() => {
                        onBack();
                    }}
                    style={{
                        minWidth: 100,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/data-exploration/chevron_back.png"
                    />
                </div>
                {loading ? (
                    <span className="exploration-big-title-span">
                        {"LOADING"}
                    </span>
                ) : (
                    finding && (
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "calc(100% - 200px)",
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "center",
                                    width: "100%",
                                    height: "300px",
                                }}
                            >
                                <WordCloud data={finding.content.data} />
                            </div>

                            <FinalStepBottomBar
                                onShowRecipesPopup={onShowRecipesPopup}
                                onSaveFinding={onSaveFinding}
                                topMargin={20}
                            />
                        </div>
                    )
                )}
                <div
                    style={{
                        minWidth: 100,
                        height: "100%",
                    }}
                />
            </div>
        </>
    );
}

class MainComponent extends BaseJourney {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            previousStep: 0,
            step: 0,
            animationDirection: "top",
            targetVariables: [],
            selectedVariable: null,
            wordLevel: false,
            removeStopWords: false,
            minimumWordCount: 1,
            excludedWors: [],
            count: 100,
            finding: undefined,
            step2VariablesMap: {
                [Step.selectTarget]: ["selectedVariable", "targetVariables"],
            },
            conditions: [
                {
                    variable: null,
                    operation: null,
                    value: null,
                    logical: null,
                    leftBracket: null,
                    rightBracket: null,
                },
            ],
        };
        this.getData = this.getData.bind(this);
        this.initializeVariables = this.initializeVariables.bind(this);
        this.switchVariables = this.switchVariables.bind(this);
    }
    clearOptionalArgs() {
        this.setState({
            selectedVariable: null,
            selectedVariableIndex: null,
            selectedTable: this.defaultTable(),
            conditions: ConditionsSelector.defaultValue,
            dynamicOptions: {},
        });
    }
    getConfig() {
        let config = {
            version: configVersion,
            dataScope: this.state.dataScope,
            conditions: this.state.conditions,
            count: this.state.count || 100,
            selectedVariable: this.state.selectedVariable,
            targetVariables: this.state.targetVariables,
            selectedVariableIndex: this.getVariables().variableToIndex(
                this.state.selectedVariable
            ),
            wordLevel: this.state.wordLevel,
            removeStopWords: this.state.removeStopWords,
            journeyName: this.props.journeyName,
        };
        if (this.props.addToCanvas) {
            config.dynamicOptions = this.prepareDynamicOptions(
                this.state.dynamicOptions,
                config
            );
        }
        return config;
    }

    getData() {
        this.setState({ loading: true });

        Api.getData(this.getConfig(), undefined, this.props.currentModuleId)
            .then((finding) => {
                this.setState({ finding: finding, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error);
            });
    }

    buildContent() {
        switch (this.state.step) {
            case Step.initial:
                return this.buildInitial(
                    "How do you want to examine your variables?"
                );
            case Step.selectDataScope:
                return this.buildDataScopesSelector();
            case Step.selectConditions:
                return this.buildConditionsSelector();
            case Step.selectTarget:
                return this.buildVariablesSelector(
                    "I want to examine",
                    "selectedVariable",
                    "targetVariables",
                    false,
                    DynamicOptionType.variable,
                    "selectedVariableIndex",
                    true
                );
            case Step.selectCount:
                let wordLevelComponent = (
                    <div className="flex-simple-column">
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: 20 }}
                        >
                            <span
                                className="text-center"
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-primary-text-color"
                                    ),
                                    fontFamily: "Arial",
                                    fontSize: "12px",
                                }}
                            >
                                Word level
                            </span>
                            <div
                                style={{
                                    marginLeft: 10,
                                }}
                            >
                                <Switch
                                    onChange={(checked) => {
                                        this.setState({
                                            wordLevel: checked,
                                        });
                                    }}
                                    checked={this.state.wordLevel}
                                    width={26}
                                    height={13}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        </div>
                        <div
                            className="my-row"
                            style={{ alignItems: "center", marginTop: 10 }}
                        >
                            <span
                                className="text-center"
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-primary-text-color"
                                    ),
                                    fontFamily: "Arial",
                                    fontSize: "12px",
                                }}
                            >
                                Remove stop words
                            </span>
                            <div
                                style={{
                                    marginLeft: 10,
                                }}
                            >
                                <Switch
                                    onChange={(checked) => {
                                        this.setState({
                                            removeStopWords: checked,
                                        });
                                    }}
                                    checked={this.state.removeStopWords}
                                    width={26}
                                    height={13}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        </div>
                    </div>
                );
                return this.buildInput(
                    "Display",
                    "most frequent words",
                    "count",
                    true,
                    [wordLevelComponent]
                );

            case Step.final:
                return (
                    <StepFinal
                        onShowRecipesPopup={this.showRecipesPopup}
                        loading={this.state.loading}
                        finding={this.state.finding}
                        onSaveFinding={this.saveFinding}
                        onNewFinding={this.onNewFinding}
                        onBack={this.back}
                    />
                );

            default:
                return <div />;
        }
    }

    buildLeftBar() {
        return super.buildLeftBar(this.state.step < Step.final - 1);
    }
    initializeVariables() {
        let scopeVariables = this.getVariables();
        this.setState({
            targetVariables: Array.from(scopeVariables.variableNames),
        });
    }
    switchVariables() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
        this.initializeVariables();
        this.initializeVariablesReaction = reaction(
            () => this.getVariables().dataVariables,
            () => {
                this.initializeVariables();
            }
        );
    }

    componentDidMount() {
        this.getLastJourneyConfig();
    }

    componentWillUnmount() {
        if (this.initializeVariablesReaction)
            this.initializeVariablesReaction();
    }

    showFinalStep() {
        if (
            this.state.dataScope &&
            this.state.selectedVariable &&
            this.state.step > Step.initial &&
            this.state.step < Step.final
        )
            this.setState({ animationDirection: "top" }, () => {
                this.setState(
                    (state) => ({
                        previousStep: state.step,
                        step: Step.final,
                    }),
                    () => {
                        this.getData();
                        this.saveNewJourneyConfig();
                    }
                );
            });
    }

    selectVariable(variableName, variablesName, index, maxCount = undefined) {
        let stateDiff = {};
        let multiselection = Array.isArray(this.state[variableName]);
        if (!multiselection) {
            stateDiff = {
                [variableName]: this.state[variablesName][index],
            };
        } else {
            let selectedVariables = Array.from(this.state[variableName]);
            let newVariable = this.state[variablesName][index];
            if (selectedVariables.includes(newVariable)) {
                selectedVariables = selectedVariables.filter(
                    (item) => item !== newVariable
                );
            } else {
                selectedVariables.push(newVariable);
            }
            let allVariables = Array.from(this.state[variablesName]);
            selectedVariables = selectedVariables.filter((variable) =>
                allVariables.includes(variable)
            );
            if (maxCount != null) {
                if (selectedVariables.length > maxCount) {
                    selectedVariables = selectedVariables.slice(
                        selectedVariables.length - maxCount
                    );
                }
            }
            stateDiff = {
                [variableName]: selectedVariables,
            };
        }

        this.setState(stateDiff, () => {
            if (this.state.step === Step.selectTarget)
                setTimeout(() => {
                    this.stepDown();
                }, 100);
        });
    }

    getSubtitle() {
        let conditionsString = conditionsToString(
            Array.from(this.state.conditions)
        );
        if (
            this.state.step === Step.selectTable ||
            this.state.step === Step.selectDataScope
        )
            return "How do you want to examine your variables?";
        if (
            this.state.step > Step.selectTable &&
            this.state.step < Step.final
        ) {
            let subtitle = `For the data in ${
                this.state.dataScope ? this.state.dataScope.label : ""
            }`;
            if (this.state.step > Step.selectConditions) {
                if (conditionsString.length > 0)
                    subtitle += ` and under the conditions ${conditionsString}`;
            }
            if (this.state.step > Step.selectTarget)
                subtitle += ` I want to examine ${this.state.selectedVariable},`;
            // if (this.state.step > Step.selectAdditionalParameter)
            //     subtitle += ` and how they vary by ${
            //         this.state.additionalParameter || ""
            //     }`;

            return subtitle;
        }

        if (this.state.step === Step.final) {
            return (
                <>
                    <span>For the data </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.dataScope
                            ? this.state.dataScope.label.concat(" ")
                            : ""}
                    </span>
                    {conditionsString.length > 0 && (
                        <>
                            <span> and under the conditions </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {conditionsString}
                            </span>
                        </>
                    )}
                    <span> I want to examine </span>
                    <span
                        style={{
                            color: mainStyle.getPropertyValue(
                                "--exploration-secondary-text-color"
                            ),
                        }}
                    >
                        {this.state.selectedVariable}
                    </span>
                    {this.state.additionalParameter &&
                    [
                        this.state.leftAdditionalValue,
                        this.state.rightAdditionalValue,
                    ]
                        .filter((item) => item)
                        .filter((item) => item.value).length > 0 ? (
                        <>
                            <span> and how they vary by </span>
                            <span
                                style={{
                                    color: mainStyle.getPropertyValue(
                                        "--exploration-secondary-text-color"
                                    ),
                                }}
                            >
                                {(this.state.leftAdditionalValue?.value != null
                                    ? ` ${this.state.additionalParameter} ${
                                          this.state.leftAdditionalOperator
                                              ?.value ?? "="
                                      } ${this.state.leftAdditionalValue.value}`
                                    : "") +
                                    (this.state.rightAdditionalValue?.value !=
                                    null
                                        ? (this.state.leftAdditionalValue
                                              ?.value != null
                                              ? ", "
                                              : " ") +
                                          `${this.state.additionalParameter} ${
                                              this.state.rightAdditionalOperator
                                                  ?.value ?? "="
                                          } ${
                                              this.state.rightAdditionalValue
                                                  .value
                                          }`
                                        : "")}
                            </span>
                        </>
                    ) : null}
                    <span>.</span>
                </>
            );
        }
        return "";
    }
    getFooterTitle() {
        switch (this.state.step) {
            case Step.initial:
                return "... For the data in...";
            case Step.selectTable:
                return "... and under the conditions...";
            case Step.selectConditions:
                return "... I want to examine ...";
            case Step.selectTarget:
                return "";
            default:
                return "";
        }
    }
    stepDown() {
        if (this.state.step === Step.selectDataScope && !this.state.dataScope) {
            return;
        }
        let delta = 1;
        if (this.state.step + delta < Step.final) {
            this.setState((state) => ({
                previousStep: state.step,
                step: state.step + delta,
            }));
        } else {
            this.showFinalStep();
        }
    }

    stepUp() {
        let step = this.state.step;

        if (step > Step.initial)
            this.setState({ animationDirection: "bottom" }, () => {
                this.setState({
                    step: step - 1,
                });
            });
    }
    render() {
        return (
            <div
                className="dashboard-rect-journey-focus"
                tabIndex="0"
                style={{
                    height: 520,
                    overflow: "hidden",
                    display: !this.props.collapsed ? "block" : "none",
                }}
                onClick={() => {
                    if (this.state.step === Step.initial) this.stepDown();
                }}
                onKeyDown={(evt) => {
                    if (evt.key === "Escape") {
                        this.props.onClose();
                    }
                    if (evt.key === "ArrowDown") {
                        if (
                            this.state.step > Step.initial &&
                            this.state.step < Step.final
                        )
                            this.stepDown();
                        evt.preventDefault();
                    }
                    if (evt.key === "ArrowLeft") {
                        if (this.state.step === Step.final) {
                            this.back();
                        }
                    }
                    if (evt.key === "ArrowRight") {
                        this.showFinalStep();
                    }
                    if (evt.key === "ArrowUp") {
                        if (this.state.step < Step.final) this.stepUp();
                        evt.preventDefault();
                    }
                    if (evt.key === "p") {
                        if (this.state.step === Step.final) {
                            this.saveFinding();
                        }
                    }
                    if (evt.key === "d") {
                        if (this.state.step === Step.final) {
                            evt.preventDefault();
                            this.showRecipesPopup();
                        }
                    }
                    let variableInfo = this.state.step2VariablesMap[
                        this.state.step
                    ];
                    if (variableInfo) {
                        let variablesLength = this.state[variableInfo[1]]
                            .length;
                        let variableIndex = evt.keyCode - 65;
                        if (
                            variableIndex >= 0 &&
                            variableIndex < variablesLength
                        ) {
                            this.selectVariable(
                                variableInfo[0],
                                variableInfo[1],
                                variableIndex
                            );
                        }
                    }
                    if (evt.key === "Enter") {
                        // if (
                        //     this.state.step === Step.selectAdditionalValue &&
                        //     Variables(this.dataScopeValue()).isMonthVariable(
                        //         this.state.additionalParameter
                        //     ) &&
                        //     this.state.leftAdditionalValue &&
                        //     !this.state.leftAdditionalValueSelected
                        // ) {
                        //     this.setState({
                        //         leftAdditionalValueSelected: true,
                        //     });
                        //     return;
                        // }
                        // if (
                        //     this.state.step === Step.selectAdditionalValue &&
                        //     Variables(this.dataScopeValue()).isMonthVariable(
                        //         this.state.additionalParameter
                        //     ) &&
                        //     this.state.leftAdditionalValueSelected &&
                        //     this.state.rightAdditionalValue
                        // ) {
                        //     this.setState(
                        //         {
                        //             rightAdditionalValueSelected: true,
                        //         },
                        //         () => {
                        //             this.showFinalStep();
                        //         }
                        //     );
                        //     return;
                        // }
                    }
                    // if (
                    //     this.state.step === Step.selectAdditionalValue &&
                    //     Variables(this.dataScopeValue()).isMonthVariable(
                    //         this.state.additionalParameter
                    //     )
                    // ) {
                    //     this.selectMonth(evt.key, true);
                    // }
                }}
            >
                <div
                    className="my-row"
                    style={{ justifyContent: "space-between", height: "100%" }}
                >
                    <div
                        className="flex-column"
                        style={{
                            height: "100%",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {this.buildHeader()}

                        <TransitionGroup
                            style={{
                                minHeight: "inherit",
                            }}
                        >
                            <CSSTransition
                                style={{
                                    height: "100%",
                                }}
                                key={this.state.step}
                                timeout={500}
                                classNames={"journeywizard-".concat(
                                    this.state.animationDirection || ""
                                )}
                            >
                                <div
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    {this.buildContent()}
                                </div>
                            </CSSTransition>
                        </TransitionGroup>

                        <Footer
                            showArrow={
                                this.state.step > Step.initial &&
                                this.state.step < Step.final
                            }
                            title={this.getFooterTitle()}
                        />
                    </div>
                </div>
                {this.buildAddToRecipesPopup()}
            </div>
        );
    }
}

export { MainComponent };
