import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="15"
			height="22"
			viewBox="0 0 15 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#657D95"
			{...props}
		>
			<rect
				x="1"
				y="1"
				width="13"
				height="9"
				strokeWidth="2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.0085 6.46155L11.4443 4.61539L8.77767 4.61539L10.0085 6.46155Z"
				fill="#657D95"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.0085 6.46155L11.4443 4.61539L8.77767 4.61539L10.0085 6.46155Z"
			/>
			<path
				d="M5.75 17.625L7.5 19.375L9.25 17.625"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}