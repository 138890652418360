import { createLayerComponent, LayerProps } from "@react-leaflet/core";
import { ReactNode } from "react";
// @ts-ignore: no types for TileColorLayer
import TileLayerColorFilter from "./TileLayerColorFilter";
import _ from "lodash";

interface TileFilterProps extends LayerProps {
    children?: ReactNode;
    url: string;
    filter: string[];
}

const createTileLayer = (props: TileFilterProps, context: any) => {
    const instance = new TileLayerColorFilter(props.url, { ...props });
    return { instance, context };
};

const updateTileLayer = (
    instance: any,
    props: TileFilterProps,
    prevProps: TileFilterProps
) => {
    if (prevProps.url !== props.url) {
        if (instance.setUrl) instance.setUrl(props.url);
    }
    if (!_.isEqual(prevProps.filter, props.filter)) {
        instance.updateFilter(props.filter);
    }
};

const TileFilterLayer = createLayerComponent(createTileLayer, updateTileLayer);
export default TileFilterLayer;
