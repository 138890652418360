import Cookies from "universal-cookie";
const cookies = new Cookies();

function stringSessionId(): string {
	return cookies.get("instrumentation_session_id");
}

function sessionId(): number {
	return parseInt(stringSessionId());
}
export { stringSessionId };
export default sessionId;