import React, { useState } from "react";
import styles from "./LinkDropup.module.css";
import cx from "classnames";
import { ReactComponent as LinkIcon } from "icons/link.svg";
import OutsideAlerter from "common/OutsideAlerter";

interface Props {
    onOpen: (evt: React.MouseEvent, external?: boolean) => void;
}

export default function LinkDropup(props: Props) {
    let [open, setOpen] = useState(false);

    const menuClass = `dropdown-menu${open ? " show" : ""}`;
    return (
        <div className={cx("btn-group dropup", styles.container)}>
            <button
                type="button"
                onClick={(evt) => {
                    props.onOpen(evt);
                }}
                className={cx("btn", styles.linkButton)}
            >
                <LinkIcon width={20} height={20} />
            </button>
            <button
                onClick={() => {
                    setOpen(!open);
                }}
                type="button"
                className={cx("btn", "dropdown-toggle", styles.linkButton)}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <span className={cx("caret", styles.caret)}></span>
            </button>
            <OutsideAlerter onReject={() => setOpen(false)}>
                <ul className={menuClass}>
                    <li>
                        <a
                            className={styles.hrefStyle}
                            onClick={(evt) => {
                                evt.preventDefault();
                                setOpen(false);
                                props.onOpen(evt, false);
                            }}
                            href="/#"
                        >
                            Internal
                        </a>
                        <a
                            className={styles.hrefStyle}
                            onClick={(evt) => {
                                evt.preventDefault();
                                setOpen(false);
                                props.onOpen(evt, true);
                            }}
                            href="/#"
                        >
                            External
                        </a>
                    </li>
                </ul>
            </OutsideAlerter>
        </div>
    );
}