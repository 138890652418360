import {
    observable,
    computed,
    makeObservable,
    action,
    runInAction,
} from "mobx";
import { SlideTemplate } from "common/Canvas";
import {
    getCollectionsTemplateApi,
    getSlideTemplatesApi,
} from "common/CanvasUserApi";
import { SocketIOInstance } from "./ServerConnection";
import { stringSessionId } from "./SessionId";
import { Collection } from "./MediaCollectionsApi";

class SlideTemplates {
    @observable public templateState: {
        [key: number]: SlideTemplate[];
    } = [];
    @observable public slideCollection: Collection[] = [];
    public initialized: boolean = false;

    constructor() {
        makeObservable(this);
        this.updateTemplatesByWebsocket =
            this.updateTemplatesByWebsocket.bind(this);
        SocketIOInstance?.on(
            "module_user_update_templates",
            this.updateTemplatesByWebsocket
        );
    }
    @computed public get slides() {
        return this.templateState;
    }

    @action.bound
    private assignTemplates(
        slideCollection: Collection[],
        slideTemplates: {
            [key: number]: SlideTemplate[];
        }
    ) {
        this.templateState = slideTemplates;
        this.slideCollection = slideCollection;
        this.initialized = true;
    }

    @action.bound
    private assignCollections(slideCollection: Collection[]) {
        this.slideCollection = slideCollection;
    }
    public allSlides() {
        return this.slideCollection
            .map((collection) => this.slides[collection.id])
            .flat();
    }

    updateCollectionTitle(collectionId: number, title: string) {
        let collections = Array.from(this.slideCollection);
        let collection = collections.find(
            (collection) => collection.id === collectionId
        );
        if (collection != null) {
            collection.title = title;
            runInAction(() => {
                this.slideCollection = collections;
            });
        }
    }

    updateTitle(slideId: number, collectionId: number, title: string) {
        let templateState = { ...this.templateState };
        let template = templateState[collectionId].find(
            (template) => template.id === slideId
        );
        if (template != null) {
            template.title = title;
            runInAction(() => {
                this.templateState = templateState;
            });
        }
    }

    updateThumbnail(slideId: number, collectionId: number, thumbnail: string) {
        let templateState = { ...this.templateState };
        let template = templateState[collectionId].find(
            (template) => template.id === slideId
        );
        if (template != null) {
            template.thumbnail = thumbnail;
            runInAction(() => {
                this.templateState = templateState;
            });
        }
    }

    deleteSlide(slideId: number, collectionId: number) {
        let templateState = { ...this.templateState };
        templateState[collectionId] = templateState[collectionId].filter(
            (template) => template.id !== slideId
        );

        runInAction(() => {
            this.templateState = templateState;
        });
    }
    deleteCollection(collectionId: number) {
        let templates = { ...this.templateState };
        let slideCollection = Array.from(this.slideCollection);
        delete templates[collectionId];

        slideCollection = slideCollection.filter(
            (collection) => collection.id !== collectionId
        );

        runInAction(() => {
            this.templateState = templates;
            this.slideCollection = slideCollection;
        });
    }

    public async updateCollections() {
        try {
            let collections = await getCollectionsTemplateApi();
            this.assignCollections(collections);
        } catch (exception) {
            console.log(String(exception));
        }
    }

    public async update() {
        const limit = 2;

        try {
            let collections = await getCollectionsTemplateApi();
            let templateState: {
                [key: number]: SlideTemplate[];
            } = {};
            this.assignTemplates(collections, templateState);
            for (let collection of collections) {
                templateState[collection.id] = [];
                let offset = 0;
                while (true) {
                    let slideTemplates = await getSlideTemplatesApi(
                        collection.id,
                        offset,
                        limit,
                        true
                    );
                    offset += limit;
                    if (slideTemplates.length === 0) break;
                    templateState[collection.id] =
                        templateState[collection.id].concat(slideTemplates);
                    this.assignTemplates(collections, templateState);
                    if (slideTemplates.length < limit) break;
                }
                templateState[collection.id].filter(item => !item.layer_for_canvas_id)
            }
        } catch (exception) {
            console.log(String(exception));
        }
    }
    updateTemplatesByWebsocket(content: {
        data: {
            update_id?: string;
        };
    }) {
        if (content.data.update_id !== stringSessionId() && this.initialized) {
            this.update();
        }
    }
}

export default new SlideTemplates();
