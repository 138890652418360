import React from "react";
import { NetworkFinding } from "common/Finding";
import styles from "../StylingSection.module.css";
import { defaultBaseEdgeThickness } from "common/Network";
import Input from "../Input";
import StringUtils from "common/utilities/StringUtils";
import ColorConfig from "./ColorConfig";

interface Props {
    finding: NetworkFinding;
    onChange: (config: NetworkFinding["config"], updateData?: boolean) => void;
    currentModuleId?: number;
}

function NetworkSection(props: Props) {
    return (
        <>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Edge color</span>
                <div />
            </div>
            <ColorConfig
                type="edges"
                config={props.finding.config}
                onChange={props.onChange}
                currentModuleId={props.currentModuleId}
            />
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Base edge thickness</span>
                <Input
                    value={
                        props.finding.config.baseEdgeThickness ??
                        defaultBaseEdgeThickness
                    }
                    onChange={(value) => {
                        let newConfig = {
                            ...props.finding.config,
                        };
                        if (StringUtils.isNumber(value)) {
                            newConfig.baseEdgeThickness = Number(value);
                        } else {
                            newConfig.baseEdgeThickness = null;
                        }
                        props.onChange(newConfig);
                    }}
                />
            </div>
            <div className={styles.optionContainer}>
                <span className={styles.optionName}>Node color</span>
                <div />
            </div>
            <ColorConfig
                type="nodes"
                config={props.finding.config}
                onChange={props.onChange}
                currentModuleId={props.currentModuleId}
            />
        </>
    );
}

export default NetworkSection;
