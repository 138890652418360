import React from "react";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";
import { AxiosResponse } from "axios";

import "common/styles/App.css";
import "common/styles/div_span.css";
import Alert from "common/Alert";
import axios from "common/ServerConnection";
import customSelectStyles from "common/SelectStyles";
import { colorList } from "common/graphics/LineColors";
import Instrumentation from "common/Instrumentation";
import BaseSubmodule from "../../common/BaseSubmodule";
import TimePlot, { formatTimePlot } from "../../common/TimePlot";
import ParameterSelector from "../../common/ParameterSelector";
import {
    TypeParameter,
    SectionParameter,
    ElementParameter,
    VariableParameter,
} from "../../common/Parameters";
import { mainStyle } from "common/MainStyle";

interface Props {
    visible: boolean;
}

interface State {
    currentPlottedVariable: string | null;
    allUsers: string[];
    users: {
        label: string;
        value: string;
    }[];
    currentPlottedUsers: string[];
    error: string | null;
    timeUserPlot: Array<{ [key: string]: number } & { name: string }> | null;
    serverDown: boolean[] | null;
    type: TypeParameter | null;
    section: SectionParameter | null;
    element: ElementParameter | null;
    variable: VariableParameter | null;
}

@observer
class MainComponent extends BaseSubmodule<Props, State> {
    private performance: Date | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            currentPlottedVariable: null,
            allUsers: [],
            users: [],
            currentPlottedUsers: [],
            error: null,
            timeUserPlot: null,
            serverDown: null,

            type: null,
            section: null,
            element: null,
            variable: null,
        };

        this.performance = null;
    }

    public componentDidMount(): void {
        axios
            .post<{
                success: boolean;
                error_msg: string;
                user_names?: string[];
            }>("/api/instrumentation_get_user_names")
            .then((response) => {
                if (
                    !response.data.success ||
                    response.data.user_names == null
                ) {
                    this.setState({
                        error: `Couldn't get user names: ${response.data.error_msg}`,
                    });
                } else {
                    if (response.data.user_names.length === 0) {
                        this.setState({ error: "Data set is empty" });
                    } else {
                        this.setState({
                            allUsers: response.data.user_names,
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    error: `Couldn't get user names: ${error}`,
                });
            });
    }

    public componentDidUpdate(): void {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Usage", timeMs);
        }
    }

    public static getTitle(): string {
        return "Mean Across Time For Each User";
    }

    private async instrumentationMeanPlots(): Promise<void> {
        if (this.state.users.length === 0) {
            this.setState({ error: "Please select at least one user" });
            return;
        }
        if (this.state.type == null || this.state.variable == null) return;
        const type = this.state.type?.value;
        const variable = this.state.variable?.value;
        const section = this.state.type.hasSection
            ? this.state.section?.value
            : undefined;
        const element = this.state.type.hasElement
            ? this.state.element?.value
            : undefined;
        const users = this.state.users.map((user) => user.value);
        let response1: AxiosResponse<{
            success: boolean;
            error_msg: string;
            time_user_plot?: Array<[number, string, number]>;
        }>;
        // Get the mean plot
        try {
            response1 = await axios.post(
                "/api/instrumentation_mean_plot_time_user",
                {
                    type: type,
                    section: section,
                    element: element,
                    variable: variable,
                    user_names: users,
                }
            );
        } catch (error) {
            console.log(error);
            this.setState({
                error: String(error),
            });
            return;
        }
        if (!response1.data.success || response1.data.time_user_plot == null) {
            this.setState({ error: response1.data.error_msg });
            return;
        }
        if (response1.data.time_user_plot.length === 0) {
            this.setState({
                error: "No data for selected filters",
                currentPlottedVariable: null,
                currentPlottedUsers: [],
                timeUserPlot: null,
                serverDown: null,
            });
            return;
        }

        // Get server uptime
        let response2: AxiosResponse<{
            success: boolean;
            error_msg: string;
            uptime?: number[];
        }>;
        try {
            response2 = await axios.post("/api/get_server_uptime", {
                start_time: response1.data.time_user_plot[0][0],
                end_time:
                    response1.data.time_user_plot[
                        response1.data.time_user_plot.length - 1
                    ][0],
            });
        } catch (error) {
            console.log(error);
            this.setState({
                error: error.response.status.toString(),
            });
            return;
        }
        if (!response2.data.success || response2.data.uptime == null) {
            this.setState({
                error: response2.data.error_msg,
            });
            return;
        }

        // Format time plot
        let userToIndex: { [key: string]: number } = {};
        users.forEach((user, index) => {
            userToIndex[user] = index + 1;
        });
        let timeUserPlotData: number[][] = [];
        let previousDate: number = response1.data.time_user_plot[0][0];
        const zeros: number[] = users.map(() => 0);
        let row: number[] = [previousDate, ...zeros];
        for (let [date, user, value] of response1.data.time_user_plot) {
            if (date !== previousDate) {
                timeUserPlotData.push(row);
                row = [date, ...zeros];
                previousDate = date;
            }
            row[userToIndex[user]] = value;
        }
        timeUserPlotData.push(row);
        let { timePlot, serverDown } = formatTimePlot(
            timeUserPlotData,
            response2.data.uptime,
            users
        );
        this.setState({
            error: null,
            currentPlottedVariable: variable,
            currentPlottedUsers: users,
            timeUserPlot: timePlot,
            serverDown: serverDown,
        });
    }

    private renderTimeUserPlot(): JSX.Element | null {
        if (
            this.state.serverDown == null ||
            this.state.currentPlottedUsers == null ||
            this.state.timeUserPlot == null
        ) {
            return null;
        }
        return (
            <div
                style={{
                    height: 320,
                    overflowX: "scroll",
                    overflowY: "hidden",
                }}
            >
                <TimePlot
                    lineColors={colorList.slice(
                        0,
                        this.state.timeUserPlot?.length ?? 0
                    )}
                    lineKeys={this.state.currentPlottedUsers}
                    areaColor="#EE4344"
                    serverDown={this.state.serverDown}
                    data={this.state.timeUserPlot}
                    title={`Mean ${this.state.currentPlottedVariable} Across Time For Each User`}
                />
            </div>
        );
    }

    public render(): JSX.Element | null {
        if (!this.props.visible) return null;
        return (
            <div style={{ marginTop: 20 }}>
                <div style={{ marginTop: 10 }} className="flex-simple-column">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                        }}
                    >
                        <span
                            className="regular-text"
                            style={{
                                marginRight: "15px",
                            }}
                        >
                            Plot mean
                        </span>
                        <ParameterSelector
                            type={this.state.type}
                            section={this.state.section}
                            element={this.state.element}
                            variable={this.state.variable}
                            onChange={(type, section, element, variable) => {
                                this.setState({
                                    type: type,
                                    section: section,
                                    element: element,
                                    variable: variable,
                                });
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                        }}
                    >
                        <span
                            className="regular-text"
                            style={{
                                marginRight: "15px",
                            }}
                        >
                            across time for each user
                        </span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={""}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    height: "38px",
                                    flex: 1,
                                }),
                            }}
                            isMulti
                            options={this.state.allUsers.map((user) => ({
                                label: user,
                                value: user,
                            }))}
                            value={this.state.users}
                            onChange={(newValue) => {
                                this.setState({
                                    users: newValue as State["users"],
                                });
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: mainStyle.getPropertyValue(
                                        "--selectors-text-color"
                                    ),
                                    primary25: "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <Button
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                width: 150,
                                height: "38px",
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginLeft: "15px",
                            }}
                            onClick={() => {
                                this.performance = new Date();
                                this.instrumentationMeanPlots();
                            }}
                        >
                            UPDATE
                        </Button>
                    </div>
                    {this.state.error != null && (
                        <Alert
                            text={`Error: ${this.state.error}`}
                            className="alert alert-danger alert-dismissible"
                            onClosed={() => this.setState({ error: null })}
                            style={{
                                width: "100%",
                            }}
                        />
                    )}
                    {this.state.timeUserPlot != null &&
                        this.renderTimeUserPlot()}
                </div>
            </div>
        );
    }
}

export { MainComponent };
export let requirePermission = "MeanAcrossTimeForEachUser";
