import React from "react";
import { Button } from "react-bootstrap";
import {
    BarChartFinding,
    formatValues,
    PieChartFinding,
    isBarChart,
} from "common/Finding";
import { useDrop } from "react-dnd";
import commonStyles from "../DataSection.module.css";
import styles from "./BarChartSection.module.css";
import cx from "classnames";
import EditInput from "../EditInput";
import MagicWand from "../MagicWand";
import { DynamicOptionType } from "common/DynamicOptions";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { additionalBarChartAndPieChartOperations } from "common/AggregationFunctionsv2";

interface Props {
    columnDragActive: boolean;
    finding: BarChartFinding | PieChartFinding;
    dashboardId: string;
    canvasTreeStore: CanvasTreeStore;
    onChange: (
        finding: BarChartFinding | PieChartFinding,
        updateData?: boolean
    ) => void;
    onClearEditing: () => void;
    currentModuleId?: number;
}

interface DropProps {
    index: number;
    onDrop: (
        index: number,
        variableName: string,
        variableIndex: number
    ) => void;
    optionalClassName?: string;
}

const DropArea: React.FC<DropProps> = (props) => {
    const [collected, drop] = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            props.onDrop(
                props.index,
                otherItem.content.variableName,
                otherItem.content.variableIndex
            );
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    return (
        <div
            className={props.optionalClassName}
            style={{
                backgroundColor: collected.hover ? "#F4FBF5" : undefined,
            }}
            ref={drop}
        >
            {props.children}
        </div>
    );
};

export default function BarChartSection(props: Props) {
    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData = Array.from(props.finding.content.data);
        let newFinding = { ...props.finding };
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        newFinding.content.data = newData;
        props.onChange(newFinding, true);
    };

    let data: {
        name: string;
        value: number | number[];
        variableIndex?: number;
    }[] = props.finding.content.data;
    // let currentEditVariableIndex: number | undefined = undefined;
    // if (props.finding.config.dataScope != null && props.columnDragActive) {
    //     currentEditVariableIndex = data.findIndex(
    //         (item) => item.variableIndex == null
    //     );
    // }
    let horizontalLines: ({
        name: string;
        value: number;
        variableIndex?: number;
        originalName?: string;
    } | null)[] = [null];
    if (isBarChart(props.finding)) {
        horizontalLines = props.finding.content.horizontalLines ?? [null];
        if (horizontalLines.length === 0) {
            horizontalLines = [null];
        }
    }
    return (
        <div>
            <div className="my-row">
                <MagicWand
                    onClearEditing={props.onClearEditing}
                    dashboardId={props.dashboardId}
                    allowMultiple={true}
                    title={"Variables"}
                    optionName="data"
                    canvasTreeStore={props.canvasTreeStore}
                    type={DynamicOptionType.DataVariable}
                />
                <div className={styles.dataContainer}>
                    <div className={commonStyles.header}>Variables</div>
                    <div className={commonStyles.header}>Values</div>
                    {data.map((item, index) => (
                        <React.Fragment key={index}>
                            <DropArea
                                optionalClassName={
                                    props.columnDragActive
                                        ? commonStyles.highlightVariable
                                        : undefined
                                }
                                onDrop={linkVariable}
                                index={index}
                            >
                                <EditInput
                                    showDeleteButton={true}
                                    disabled={false}
                                    value={item.name}
                                    onChange={(value) => {
                                        let finding = {
                                            ...props.finding!,
                                        };
                                        let data = [...finding.content.data];
                                        data[index].name = value as string;
                                        finding.content.data = data;
                                        props.onChange(finding);
                                    }}
                                    onDelete={() => {
                                        let finding = {
                                            ...props.finding!,
                                        };
                                        let data = [...finding.content.data];
                                        data.splice(index, 1);
                                        finding.content.data = data;
                                        props.onChange(
                                            finding,
                                            item.variableIndex != null
                                        );
                                    }}
                                ></EditInput>
                            </DropArea>
                            <DropArea
                                onDrop={linkVariable}
                                index={index}
                                optionalClassName={
                                    props.columnDragActive
                                        ? commonStyles.highlightValue
                                        : undefined
                                }
                            >
                                <EditInput
                                    showDeleteButton={false}
                                    disabled={
                                        props.finding.content.data[index]
                                            .variableIndex != null
                                    }
                                    onChange={(value) => {
                                        let finding = {
                                            ...props.finding!,
                                        };
                                        let data = [...finding.content.data];
                                        data[index].value = Number(value);
                                        finding.content.data = data;

                                        props.onChange(finding);
                                    }}
                                    value={
                                        props.finding.content.data[index]
                                            .variableIndex == null
                                            ? String(item.value)
                                            : formatValues(item.value)
                                    }
                                ></EditInput>
                            </DropArea>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <Button
                style={{ marginTop: 10 }}
                className={cx(
                    "btn btn-sm btn-primary my-primary",
                    commonStyles.addItemButton
                )}
                disabled={
                    additionalBarChartAndPieChartOperations.has(
                        props.finding.config.operationVariable
                    ) && props.finding.content.data.length > 0
                }
                onClick={() => {
                    let finding = { ...props.finding! };
                    let data = [...finding.content.data];
                    data.push({
                        name: "new var",
                        value: 0,
                    });
                    finding.content.data = data;
                    props.onChange(finding);
                }}
            >
                {"\uFF0B Add Item"}
            </Button>
            {/* {isBarChart(props.finding) && (
                <div
                    className={commonStyles.optionContainer}
                    style={{ marginTop: "20px" }}
                >
                    <Switch
                        checked={
                            props.finding.config.transpose ||
                            additionalBarChartAndPieChartOperations.has(
                                props.finding.config.operationVariable
                            )
                        }
                        disabled={additionalBarChartAndPieChartOperations.has(
                            props.finding.config.operationVariable
                        )}
                        {...switchStyleProps}
                        onChange={() => {
                            let newFinding = { ...props.finding };
                            newFinding.config.transpose = !(
                                newFinding.config.transpose ?? false
                            );
                            props.onChange(newFinding, false);
                        }}
                    />
                    <span
                        style={{ marginLeft: "10px" }}
                        className={styles.optionName}
                    >
                        Transpose
                    </span>
                </div>
            )} */}
        </div>
    );
}
