import "common/styles/App.css";
import React from "react";
import { reaction } from "mobx";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Footer, FinalStepBottomBar } from "../../common/JourneyFunctions";
import { mainStyle } from "common/MainStyle";
import { conditionsToString, ConditionsSelector } from "common/Conditions";
import CompareVariablesChart from "common/graphics/CompareVariablesChart";
import BaseJourney from "../../common/BaseJourney";
import { Api } from "./Api";
import DynamicOptionType from "common/DynamicOptionType";
import { configVersion } from "common/PathConfigVersion";
import ErrorBoundary from "common/ErrorBoundary";
import BarSizeSlider from "../../common/BarSizeSlider";
import { AggregateFunctionsAliases } from "common/AggregateFunctionsAliases";
import ErrorBarSelector from "./ErrorBarSelector";
import ThemeSelector from "../../../../common/graphics/ThemeSelector";
import { BarChartTheme } from "common/graphics/BarChartTheme";

import RangeSelector from "common/graphics/RangeSelector";

//Here we define steps for each subscreen
const Step = Object.freeze({
	initial: 0,
	selectDataScope: 1,
	selectTable: 2,
	selectConditions: 3,
	selectOperation: 4,
	selectTarget: 5,
	selectTargetValues: 6,
	selectChartType: 7,
	selectColorExpressions: 8,
	selectAdditionalParameter: 9,
	selectAdditionalValue: 10,
	final: 11,
});

//This is a function that renders final graphics component on last subscreen
function StepFinal(props) {
	let {
		onUpdateData,
		onBack,
		onNewFinding,
		onShowRecipesPopup,
		onSaveFinding,
		finding,
		loading,
		// data
	} = props;
	return (
		<>
			<div
				className="my-row"
				style={{
					justifyContent: "space-between",
					marginTop: 10,
					height: "100%",
					width: "100%",
				}}
			>
				<div
					onClick={() => {
						onBack();
					}}
					style={{
						minWidth: 80,
						height: "100%",
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
						justifyContent: "center",
					}}
				>
					<img
						alt=""
						src="/dist/img/data-exploration/chevron_back.png"
					/>
				</div>
				{loading ? (
					<span className="exploration-big-title-span">
						{"LOADING"}
					</span>
				) : (
					finding && (
						<div
							className="flex-simple-column"
							style={{
								width: "calc(100% - 180px)",
								height: "100%",
							}}
						>
							<div
								style={{
									alignSelf: "center",
									width: "100%",
									height: "370px",
								}}
							>
								<ErrorBoundary>
									<div
										className="my-row"
										style={{ alignItems: "center" }}
									>
										<div className="flex-simple-column">
											<BarSizeSlider
												optionName={"barSize"}
												title={"Change bar width:"}
												onNewFinding={onNewFinding}
												finding={finding}
											/>
											<BarSizeSlider
												optionName={"barGap"}
												title={
													"Change gap between bars:"
												}
												defaultValue={4}
												onNewFinding={onNewFinding}
												finding={finding}
											/>
											<BarSizeSlider
												optionName={"barCategoryGap"}
												min={10}
												max={200}
												title={
													"Change gap between categories:"
												}
												defaultValue={120}
												onNewFinding={onNewFinding}
												finding={finding}
											/>
										</div>
										<ThemeSelector
											style={{ marginLeft: "20px" }}
											value={
												finding.config.chartTheme ??
												null
											}
											onChange={(value) => {
												let oldTheme =
													finding.config.chartTheme ??
													null;
												let newFinding = {
													...finding,
												};
												if (oldTheme !== value) {
													newFinding.config.chartTheme = value;
													newFinding.config.colorMapping = null;
													newFinding.config.errorBarColor = null;
													newFinding.config.axesColor = null;
													newFinding.config.baseBackgroundColor = null;
													onNewFinding(newFinding);
												}
											}}
										/>
										{finding.config.operationVariable ===
											"mean" && (
											<div style={{ marginLeft: "20px" }}>
												<ErrorBarSelector
													value={
														finding.config
															.errorFormat ?? null
													}
													onChange={(value) => {
														let newFinding = {
															...finding,
														};
														newFinding.config.errorFormat = value;
														onUpdateData(
															newFinding.config
														);
													}}
												/>
											</div>
										)}
										<RangeSelector
											value={finding}
											onChange={onNewFinding}
										/>
									</div>
									<CompareVariablesChart
										data={finding.content.data}
										error={finding.content.error}
										dataKeys={finding.content.dataKeys}
										config={finding.config}
										editable
										onConfigChange={(field, value) => {
											let newFinding = { ...finding };
											newFinding.config[field] = value;
											onNewFinding(newFinding);
										}}
										onFinishEdit={(barKey, label) => {
											let newFinding = { ...finding };
											newFinding.config.nameMapping = {
												...finding.config.nameMapping,
												[barKey]: label,
											};
											onNewFinding(newFinding);
										}}
										onFinishEditLabel={(barKey, label) => {
											let newFinding = { ...finding };
											newFinding.config.labelMapping = {
												...finding.config.labelMapping,
												[barKey]: label,
											};
											onNewFinding(newFinding);
										}}
										onFinishColor={(barKey, color) => {
											let newFinding = { ...finding };
											newFinding.config.colorMapping = {
												...finding.config.colorMapping,
												[barKey]: color,
											};
											onNewFinding(newFinding);
										}}
									/>
								</ErrorBoundary>
							</div>

							<FinalStepBottomBar
								onShowRecipesPopup={onShowRecipesPopup}
								onSaveFinding={onSaveFinding}
								topMargin={100}
							/>
						</div>
					)
				)}
				<div
					style={{
						minWidth: 100,
						height: "100%",
					}}
				/>
			</div>
		</>
	);
}

class MainComponent extends BaseJourney {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			previousStep: 0,
			step: 0,
			operationVariable: undefined,
			operationVariables: ["mean", "sum", "count", "count_distinct"],
			chartType: "regular",
			chartTypes: ["regular", "stacked"],
			animationDirection: "top",
			selectedTargetVariables: [],
			targetVariables: [],
			finding: undefined,
			additionalParameter: undefined,
			additionalOperators: new Array(5).fill({ label: "=", value: "=" }),
			additionalValues: new Array(5).fill(null),
			targetValues: new Array(5).fill(null),
			additionalParameters: [],
			selectedTable: undefined,
			aggregateTableList: [],
			// We use step2VariablesMap structure to activate selection
			// from keyboard for screens that correspond key steps
			step2VariablesMap: {
				[Step.selectOperation]: [
					"operationVariable",
					"operationVariables",
				],
				[Step.selectChartType]: ["chartType", "chartTypes"],
				[Step.selectTarget]: [
					"selectedTargetVariables",
					"targetVariables",
				],
				[Step.selectAdditionalParameter]: [
					"additionalParameter",
					"additionalParameters",
				],
			},
			conditions: ConditionsSelector.defaultValue,
			chartTheme: BarChartTheme.Default,
		};
		this.getData = this.getData.bind(this);
		this.updateData = this.updateData.bind(this);
		this.initializeVariables = this.initializeVariables.bind(this);
		this.switchVariables = this.switchVariables.bind(this);
	}

	/*!
	 * function clearOptionalArgs clears optional args of pathes
	 * when we switch between datascopes
	 *
	 */
	clearOptionalArgs() {
		this.setState({
			selectedTable: this.defaultTable(),
			conditions: ConditionsSelector.defaultValue,
			havingConditions: ConditionsSelector.defaultValue,
			selectedTargetVariables: [],
			selectedTargetVariablesIndices: [],
			additionalParameterIndex: null,
			additionalParameter: null,
			additionalOperators: new Array(5).fill({ label: "=", value: "=" }),
			additionalValues: new Array(5).fill(null),
			targetValues: new Array(5).fill(null),
			groupByAll: false,
			dynamicOptions: {},
			operationVariable: null,
			colorMapping: null,
			labelMapping: null,
			nameMapping: null,
			xAxisName: null,
			yAxisName: null,
			errorBarColor: null,
			axesColor: null,
			legendsColor: null,
			barSize: null,
			barGap: null,
			barCategoryGap: null,
			errorFormat: null,
			linesCount: null,
			minYRange: null,
			maxYRange: null,
			statusExpressions: null,
			chartTheme: BarChartTheme.Default,
			baseBackgroundColor: null,
		});
	}

	// We need to convert undefined to null to remove field if used in canvas page
	/*!
	 * function getConfig prepares a config for request to get graphics of this path
	 */
	getConfig() {
		let config = {
			version: configVersion,
			dataScope: this.state.dataScope ?? null,
			selectedTable: this.state.selectedTable ?? null,
			conditions: this.state.conditions ?? null,
			havingConditions: this.state.havingConditions ?? null,
			selectedTargetVariables: this.state.selectedTargetVariables ?? null,
			selectedTargetVariablesIndices:
				this.state.selectedTargetVariables.map((variable) =>
					this.getVariables().variableToIndex(variable)
				) ?? null,
			targetValues: this.state.targetValues ?? null,
			chartType: this.state.chartType ?? null,
			additionalParameters: this.state.additionalParameters ?? null,
			additionalOperators: this.state.additionalOperators ?? null,
			additionalValues: this.state.additionalValues ?? null,
			operationVariable: this.state.operationVariable ?? null,
			additionalParameter: this.state.additionalParameter ?? null,
			additionalParameterIndex:
				this.getVariables().variableToIndex(
					this.state.additionalParameter
				) ?? null,
			journeyName: this.props.journeyName,
			colorMapping: this.state.colorMapping ?? null,
			labelMapping: this.state.labelMapping ?? null,
			nameMapping: this.state.nameMapping ?? null,
			xAxisName: this.state.xAxisName ?? null,
			yAxisName: this.state.yAxisName ?? null,
			errorBarColor: this.state.errorBarColor ?? null,
			axesColor: this.state.axesColor ?? null,
			legendsColor: this.state.legendsColor ?? null,
			barSize: this.state.barSize ?? null,
			barGap: this.state.barGap ?? null,
			barCategoryGap: this.state.barCategoryGap ?? null,
			errorFormat: this.state.errorFormat ?? null,
			linesCount: this.state.linesCount ?? null,
			minYRange: this.state.minYRange ?? null,
			maxYRange: this.state.maxYRange ?? null,
			statusExpressions: this.state.statusExpressions ?? null,
			chartTheme: this.state.chartTheme ?? null,
			baseBackgroundColor: this.state.baseBackgroundColor ?? null,
			groupByAll: this.state.groupByAll ?? false,
		};
		if (this.props.addToCanvas) {
			config.dynamicOptions = this.prepareDynamicOptions(
				this.state.dynamicOptions,
				config
			);
		}
		return config;
	}

	/*!
	 * function getData returns data needed to prepare
	 * graphics for this path according to config
	 */
	getData() {
		this.setState({ loading: true });

		Api.getData(this.getConfig(), undefined, this.props.currentModuleId)
			.then((finding) => {
				this.setState({ finding: finding, loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}

	/*!
	 * function updateData updates data
	 * acording to modified config
	 */
	updateData(config) {
		this.setState({ loading: true });

		Api.getData(config, undefined, this.props.currentModuleId)
			.then((finding) => {
				this.setState((state) => ({
					finding: {
						...finding,
						content: {
							...state.finding.content,
							...finding.content,
						},
					},
					loading: false,
				}));
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}
	/*!
	 * buildContent called in render function and defines what
	 * component will be rendered in center of subscreen according to
	 * current step
	 */
	buildContent() {
		switch (this.state.step) {
			case Step.initial:
				return this.buildInitial(
					"How do you want to examine your variables?"
				);
			case Step.selectDataScope:
				//	here we return dataset selector (function defined in BaseJourney)
				return this.buildDataScopesSelector();
			case Step.selectTable:
				//	here we return table selector (function defined in BaseJourney)
				return this.buildTableSelector();
			case Step.selectConditions:
				//	here we return conditions selector (function defined in BaseJourney)
				return this.buildConditionsSelector();
			case Step.selectTarget:
				//	here we return multi-variables selector (function defined in BaseJourney)
				//  with ability to select multiple options for request. This function
				//  calls callback selectVariable, defined in this file.
				//  Selected options are stored in this.state.selectedTargetVariables,
				//  options for selections are stored in this.state.targetVariables
				return this.buildMultiVariablesSelector(
					"I want to examine",
					"selectedTargetVariables",
					"targetVariables",
					DynamicOptionType.variable,
					"selectedTargetVariablesIndices",
					this.state.operationVariable === "count" ? 1 : undefined
				);
			case Step.selectOperation:
				//	here we return variables selector (function defined in BaseJourney)
				//  with ability to select single option for request. This function
				//  calls callback selectVariable, defined in this file
				//  Selected option is stored in this.state.operationVariable,
				//  options for selection are stored in this.state.operationVariables
				return this.buildVariablesSelector(
					"By comparing",
					"operationVariable",
					"operationVariables",
					false,
					DynamicOptionType.regular,
					undefined,
					false,
					AggregateFunctionsAliases
				);
			case Step.selectChartType:
				// here we return variables selector. Selected option is stored in this.state.chartType,
				// options for selection are stored in this.state.chartTypes
				return this.buildVariablesSelector(
					"And output as",
					"chartType",
					"chartTypes"
				);

			case Step.selectColorExpressions:
				// here we return selector for expressions for colorizing.
				// Function is defined in BaseJourney.js
				let options = this.state.selectedTargetVariables.map(
					(variable) => ({
						label: variable,
						value: this.getVariables().variableToIndex(variable),
					})
				);
				return this.buildColorExpressionsSelector(options);
			case Step.selectTargetValues:
				// If "count" mode is selected then
				// here we return special variables selector that allows us
				// to select arbitrary values of selected variable
				return this.buildMultiCountModeVariablesSelector(
					"And show count for",
					this.state.selectedTargetVariables[0]
				);
			case Step.selectAdditionalParameter:
				// Here we select side-by-side parameter variable
				// Selected option is stored this.state.additionalParameter
				// option. Options for selection are stored in this.state.additionalParameters
				return this.buildVariablesSelector(
					"[+] and how they vary by",
					"additionalParameter",
					"additionalParameters",
					false,
					DynamicOptionType.additionalParameter,
					"additionalParameterIndex"
				);
			case Step.selectAdditionalValue:
				// Here we select side-by-side values (function defined in BaseJourney)
				// Selected values are stored in this.state.additionalValues
				return this.buildMultiAdditionalValueSelector(
					`[+] and how they vary by ${this.state.additionalParameter}`,
					true
				);

			case Step.final:
				// Here we are rendering result graphics
				return (
					<StepFinal
						onShowRecipesPopup={this.showRecipesPopup}
						loading={this.state.loading}
						finding={this.state.finding}
						onSaveFinding={this.saveFinding}
						onNewFinding={this.onNewFinding}
						onUpdateData={this.updateData}
						onBack={this.back}
					/>
				);

			default:
				return <div />;
		}
	}
	buildLeftBar() {
		return super.buildLeftBar(this.state.step < Step.final - 1);
	}

	selectVariablesByOperation(operationName) {
		if (operationName === "count_distinct" || operationName === "count") {
			return this.getVariables().dataVariables.map((item) => item.name);
		} else {
			return this.getVariables().numericVariables.map(
				(item) => item.name
			);
		}
	}

	/*!
	 * function initializeVariables initializes
	 * lists of options according to selected dataset
	 */
	initializeVariables() {
		let scopeVariables = this.getVariables();
		let newState = Object.assign({}, this.state);

		newState.targetVariables = this.selectVariablesByOperation(
			newState.operationVariable
		);
		newState.additionalParameters = [null].concat(
			scopeVariables.dataVariables.map((item) => item.name)
		);

		this.setState(newState);
	}

	componentDidMount() {
		this.getLastJourneyConfig();
	}

	/*!
	 * function switchVariables modifies state according to
	 * selected dataset
	 */
	switchVariables() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
		this.initializeVariables();
		this.initializeVariablesReaction = reaction(
			() => this.getVariables().dataVariables,
			() => {
				this.initializeVariables();
			}
		);
	}
	componentWillUnmount() {
		if (this.initializeVariablesReaction)
			this.initializeVariablesReaction();
	}

	/*!
	 * function showFinalStep shows final graphics
	 */
	showFinalStep() {
		if (
			this.state.selectedTargetVariables &&
			this.state.dataScope &&
			this.state.selectedTable &&
			this.state.operationVariable &&
			this.state.step > Step.initial &&
			this.state.step < Step.final
		)
			this.setState({ animationDirection: "top" }, () => {
				this.setState(
					(state) => ({
						previousStep: state.step,
						step: Step.final,
					}),
					() => {
						this.getData();
						this.saveNewJourneyConfig();
					}
				);
			});
	}

	/*!
	 * function selectVariable is callback for screens where we select value or multiple values
	 * from options;
	 * variableName is a name of state property where selected option (or options) is stored;
	 * variablesName is a name of state property where options for selection are stored;
	 * index is an index of new option in this.state[variablesName];
	 * maxCount is max count of selected options
	 */
	selectVariable(variableName, variablesName, index, maxCount = undefined) {
		let stateDiff = {};
		let multiselection = Array.isArray(this.state[variableName]);
		if (!multiselection) {
			stateDiff = {
				[variableName]: this.state[variablesName][index],
			};
		} else {
			let selectedVariables = Array.from(this.state[variableName]);
			let newVariable = this.state[variablesName][index];
			if (selectedVariables.includes(newVariable)) {
				selectedVariables = selectedVariables.filter(
					(item) => item !== newVariable
				);
			} else {
				selectedVariables.push(newVariable);
			}
			let allVariables = Array.from(this.state[variablesName]);
			selectedVariables = selectedVariables.filter((variable) =>
				allVariables.includes(variable)
			);
			if (maxCount != null) {
				if (selectedVariables.length > maxCount) {
					selectedVariables = selectedVariables.slice(
						selectedVariables.length - maxCount
					);
				}
			}
			stateDiff = {
				[variableName]: selectedVariables,
			};
		}
		if (this.state.step === Step.selectOperation) {
			stateDiff.targetVariables = this.selectVariablesByOperation(
				stateDiff[variableName]
			);
			stateDiff.selectedTargetVariables = [];
			stateDiff.errorFormat = null;
		}
		if (
			this.state.step === Step.selectOperation ||
			this.state.step === Step.selectTarget
		) {
			stateDiff.targetValues = new Array(5).fill(null);
		}
		if (this.state.step === Step.selectAdditionalParameter) {
			stateDiff.additionalValues = new Array(5).fill(null);
			stateDiff.havingConditions = ConditionsSelector.defaultValue;
			stateDiff.groupByAll = false;
		}
		this.setState(stateDiff, () => {
			if (this.state.step !== Step.selectTarget)
				setTimeout(() => {
					this.stepDown();
				}, 100);
		});
	}
	/*!
	 * function getSubtitle prepares subtitle above graphis according
	 * to step and selected arguments
	 */
	getSubtitle() {
		let conditionsString = conditionsToString(
			Array.from(this.state.conditions)
		);
		if (
			this.state.step === Step.selectTable ||
			this.state.step === Step.selectDataScope
		)
			return "How do you want to examine your variables?";
		if (
			this.state.step > Step.selectTable &&
			this.state.step < Step.final
		) {
			let subtitle = `For the data in ${
				this.state.dataScope ? this.state.dataScope.label : ""
			} ${this.state.selectedTable.label}`;
			if (this.state.step > Step.selectConditions) {
				if (conditionsString.length > 0)
					subtitle += ` and under the conditions ${conditionsString}`;
			}
			if (this.state.step > Step.selectOperation)
				subtitle += ` By comparing ${
					AggregateFunctionsAliases[this.state.operationVariable] ||
					""
				}`;
			if (this.state.step > Step.selectTarget)
				subtitle += ` I want to examine ${this.state.selectedTargetVariables.join(
					", "
				)},`;
			if (
				this.state.step > Step.selectTargetValues &&
				this.state.operationVariable === "count" &&
				this.state.targetValues.filter((item) => item?.value != null)
					.length > 0
			)
				subtitle += ` and show count for ${this.state.targetValues
					.filter((value) => value?.value != null)
					.map(
						(value, index) =>
							`${this.state.selectedTargetVariables[0]}=${value.label}`
					)
					.join(", ")},`;
			if (this.state.step > Step.selectChartType)
				subtitle += ` and output as ${this.state.chartType || ""}`;
			if (this.state.step > Step.selectAdditionalParameter)
				subtitle += ` and how they vary by ${
					this.state.additionalParameter || ""
				}`;

			return subtitle;
		}

		if (this.state.step === Step.final) {
			return (
				<>
					<span>For the data </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.dataScope
							? this.state.dataScope.label.concat(" ")
							: ""}
					</span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedTable.label}
					</span>
					{conditionsString.length > 0 && (
						<>
							<span> and under the conditions </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{conditionsString}
							</span>
						</>
					)}
					<span> I want to examine </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.selectedTargetVariables.join(", ")}
					</span>
					<span> by comparing </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{AggregateFunctionsAliases[
							this.state.operationVariable
						] || ""}
					</span>
					{this.state.operationVariable === "count" &&
					this.state.targetValues.filter(
						(item) => item?.value != null
					).length > 0 ? (
						<>
							<span> and show count for </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{this.state.targetValues
									.filter((value) => value?.value != null)
									.map(
										(value, index) =>
											`${this.state.selectedTargetVariables[0]}=${value.label}`
									)
									.join(", ")}
							</span>
						</>
					) : null}
					<span> and output as </span>
					<span
						style={{
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
						}}
					>
						{this.state.chartType || ""}
					</span>
					{this.state.additionalParameter &&
					this.state.additionalValues.filter(
						(item) => item?.value != null
					).length > 0 ? (
						<>
							<span> and how they vary by </span>
							<span
								style={{
									color: mainStyle.getPropertyValue(
										"--exploration-secondary-text-color"
									),
								}}
							>
								{this.state.additionalValues
									.filter((value) => value?.value != null)
									.map(
										(value, index) =>
											`${
												this.state.additionalParameter
											} ${
												this.state.additionalOperators[
													index
												]?.value ?? "="
											} ${value.label}`
									)
									.join(", ")}
							</span>
						</>
					) : null}
					<span>.</span>
				</>
			);
		}
		return "";
	}

	/*!
	 * function getFooterTitle prepares footer below graphis according to step
	 */
	getFooterTitle() {
		switch (this.state.step) {
			case Step.initial:
				return "... For the data in ...";
			case Step.selectTable:
				return "... and under the conditions ...";
			case Step.selectConditions:
				return "... by comparing ...";
			case Step.selectOperation:
				return "... I want to examine ...";
			case Step.selectTarget:
				if (this.state.operationVariable === "count")
					return "... and show count of ...";
				else return "... and output as  ...";
			case Step.selectTargetValues:
				return "... and output as  ...";
			case Step.selectChartType:
				return "... and colorize ...";
			case Step.selectColorExpressions:
				return "... [optional] for ...";
			default:
				return "";
		}
	}
	/*!
	 * function stepDown defines next screen step
	 */
	stepDown() {
		let delta = 1;
		if (this.state.step === Step.selectTable && !this.state.selectedTable) {
			return;
		}
		if (
			this.state.step === Step.selectTarget &&
			this.state.selectedTargetVariables.length === 0
		) {
			return;
		}
		if (
			this.state.step === Step.selectOperation &&
			!this.state.operationVariable
		) {
			return;
		}
		if (
			this.state.step === Step.selectTarget &&
			this.state.operationVariable !== "count"
		)
			delta = 2;
		if (
			this.state.step === Step.selectAdditionalParameter &&
			!this.state.additionalParameter
		)
			delta = 2;
		this.setState({ animationDirection: "top" }, () => {
			if (this.state.step + delta < Step.final) {
				this.setState((state) => ({
					previousStep: state.step,
					step: state.step + delta,
				}));
			} else {
				this.showFinalStep();
			}
		});
	}

	/*!
	 * function stepUp defines previous screen step
	 */
	stepUp() {
		let step = this.state.step;
		let delta = 1;
		if (
			this.state.step === Step.selectChartType &&
			this.state.operationVariable !== "count"
		)
			delta = 2;
		if (step > Step.initial)
			this.setState({ animationDirection: "bottom" }, () => {
				this.setState({
					step: step - delta,
				});
			});
	}

	render() {
		return (
			<div
				className="dashboard-rect-journey-focus"
				tabIndex="0"
				style={{
					height: 620,
					overflow: "hidden",
					display: !this.props.collapsed ? "block" : "none",
				}}
				onClick={() => {
					if (this.state.step === Step.initial) this.stepDown();
				}}
				onKeyDown={(evt) => {
					if (evt.key === "Escape") {
						this.props.onClose();
					}
					if (evt.key === "ArrowDown") {
						if (
							this.state.step > Step.initial &&
							this.state.step < Step.final
						)
							this.stepDown();
						evt.preventDefault();
					}
					if (evt.key === "ArrowLeft") {
						if (this.state.step === Step.final) {
							this.back();
						}
					}
					if (evt.key === "ArrowRight") {
						this.showFinalStep();
					}
					if (evt.key === "ArrowUp") {
						if (this.state.step < Step.final) this.stepUp();
						evt.preventDefault();
					}
					if (evt.key === "p") {
						if (this.state.step === Step.final) {
							this.saveFinding();
						}
					}
					if (evt.key === "d") {
						if (this.state.step === Step.final) {
							evt.preventDefault();
							this.showRecipesPopup();
						}
					}

					let variableInfo = this.state.step2VariablesMap[
						this.state.step
					];
					// here we select variable from keyboard from screens
					// defined in this.state.step2VariablesMap
					if (variableInfo) {
						let variablesLength = this.state[variableInfo[1]]
							.length;
						let variableIndex = evt.keyCode - 65;
						if (
							variableIndex >= 0 &&
							variableIndex < variablesLength
						) {
							this.selectVariable(
								variableInfo[0],
								variableInfo[1],
								variableIndex
							);
						}
					}
				}}
			>
				<div
					className="my-row"
					style={{ justifyContent: "space-between", height: "100%" }}
				>
					<div
						className="flex-column"
						style={{
							height: "100%",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						{this.buildHeader()}

						<TransitionGroup
							style={{
								minHeight: "inherit",
							}}
						>
							<CSSTransition
								style={{
									height: "100%",
								}}
								key={this.state.step}
								timeout={500}
								classNames={"journeywizard-".concat(
									this.state.animationDirection || ""
								)}
							>
								<div
									style={{
										height: "100%",
									}}
								>
									{this.buildContent()}
								</div>
							</CSSTransition>
						</TransitionGroup>

						<Footer
							showArrow={
								this.state.step > Step.initial &&
								this.state.step < Step.final
							}
							title={this.getFooterTitle()}
						/>
					</div>
				</div>
				{this.buildAddToRecipesPopup()}
			</div>
		);
	}
}

export { MainComponent };