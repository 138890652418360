import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
    CanvasElementOutput,
    SpreadSheetColumnType,
    spreadSheetColumnOptionsForTextBox,
    SpreadSheetColumnOption,
    ColumnFormat,
    isNumberFormat,
    isDateFormat,
    NumberFormat,
    DateFormat,
    NumberFormatType,
    numberFormatOptions,
    NumberFormatOption,
    CanvasTextBox,
    CanvasDataTableInputDetails,
    isTextBox,
} from "common/Canvas";
import Select, { createFilter } from "react-select";
import { customSelectStylesLight } from "common/SelectStyles";
import StringUtils from "common/utilities/StringUtils";
import DateTimeFormatSelect, {
    OptionType as DateTimeOptionType,
} from "common/DateTimeFormatSelect";
import CanvasTreeStore from "./CanvasTreeStore";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { observer } from "mobx-react";
import GlobalInputs from "common/GlobalInputs";
import OutsideAlerter from "common/OutsideAlerter";
import FormulaInformationStore, {
    InputType,
    InputOption,
} from "./FormulaInformationStore";
import SharedBoxesStore from "./SharedBoxesStore";
import _ from "lodash";
import {
    DataTableInput,
    toCanvasDataTableInputDetails,
} from "./DataTableInputSelector";
import { Expression, Parser } from "./Parser";

import { ReactComponent as LinkIcon } from "../../icons/link-45deg.svg";
import { ReactComponent as CalendarIcon } from "../../icons/calendar-date.svg";
import { ReactComponent as ClockIcon } from "../../icons/clock.svg";
import { ReactComponent as PersonIcon } from "../../icons/person-id.svg";
import { ReactComponent as HdIcon } from "../../icons/database.svg";
import Draggable from "react-draggable";
import { lightThemeStyle } from "common/LightThemeStyle";

/*!
 * CloudStorageProps are used when we click Cloud Storage.
 * These props are sent to CloudStoragePage;
 * callback is called after we click INSERT VALUE or CANCEL on CloudStoragePage;
 * lastConfiguration is last configuration of cloud storage input;
 * addNewElement indicates if we are adding a new cloud storage input
 */
export interface CloudStorageProps {
    callback: (success: boolean, dataTableInput?: DataTableInput) => void;
    lastConfiguration: CanvasDataTableInputDetails | undefined;
    addNewElement: boolean;
}

/*!
 * canvasTreeStore is a store that keeps all structures of current slide
 * onOpenCloudStorage called when we add a new cloud storage input or
 * edit existing;
 * outputIndex is an index of output (1 - default output, 2, 3.. - additional outputs)
 * tag is a current textbox;
 * currentModuleId is an identifier of curren presentation;
 * onAccept called after Enter key or Save button are clicked;
 * onReject called after Cancel button is clicked or outside is clicked;
 */
interface Props {
    left: number;
    top: number;
    canvasTreeStore: CanvasTreeStore;
    onOpenCloudStorage: (cloudStorageProps: CloudStorageProps) => void;
    outputIndex: number;
    tag: CanvasTextBox;
    currentModuleId: number | undefined;
    onAccept: (tag: CanvasTextBox, hidePopup: boolean) => void;
    onReject: () => void;
}

enum Tab {
    Formula = 1,
    Format = 2,
}

const TabNames: { [key: string]: string } = {
    [Tab.Formula]: "FORMULA",
    [Tab.Format]: "FORMAT",
};

const containersStyle = {
    backgroundColor: "#FFFFFF",
    borderRadius: "3px",
    border: "1px solid #D1D1D1",
};

const textFormulaStyle = {
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#657D95",
};

const textFormatStyle = {
    marginLeft: 5,
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#7d7d7d",
};

const hintStyle = {
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: 8,
    color: "#75869a",
    letterSpacing: "0.1em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #75869a",
    borderRadius: 4,
};

const buttonStyle = {
    width: "102px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
    fontWeight: 500,
};

interface State {
    left: number;
    top: number;
    tab: Tab;
    currentOptionIndex: number | undefined;
    tag: CanvasTextBox;
    variables: Set<string>;
    formatOptions: DateTimeOptionType[];
    showCloudStorage: boolean;
}

/*!
 * function normalizeLabel is normalizing input option to use it in formula
 */
function normalizeLabel(option: InputOption) {
    if (option.type === InputType.Linked) {
        return StringUtils.normalizeString(option.label);
    }
    return option.label;
}

@observer
class FormulaPopup extends Component<Props, State> {
    drag: boolean = false;
    selectedCloudStorageOption: InputOption | undefined = undefined;
    inputRef = React.createRef<HTMLInputElement>();
    constructor(props: Props) {
        super(props);
        let tag: CanvasTextBox = _.cloneDeep(this.props.tag);
        let outputIndex = this.props.outputIndex;

        // If we are creating a new output we are adding additional output to textbox structure
        if (outputIndex > 1 && tag.additionalOutputs.length < outputIndex - 1) {
            tag.additionalOutputs.push({
                metric: "=",
                value: NaN,
                unit: "",
            });
        } else {
            if (!tag.metric.startsWith("=")) tag.metric = "=";
        }
        this.state = {
            left: this.props.left,
            top: this.props.top,
            showCloudStorage: false,
            currentOptionIndex: undefined,
            tag: tag,
            variables:
                this.getVariables(
                    props.outputIndex === 1
                        ? tag.metric
                        : tag.additionalOutputs[props.outputIndex - 2].metric
                ) ?? new Set(),
            tab: Tab.Formula,
            formatOptions: DateTimeFormatSelect.defaultOptions(),
        };
        this.onDataInputAdd = this.onDataInputAdd.bind(this);
    }
    onDataInputAdd(success: boolean, dataTableInput?: DataTableInput) {
        // This callback is called when we add a new cloud storage input
        this.setState({ showCloudStorage: false });
        if (success && dataTableInput != null) {
            let metric = "";
            let newSelectionEnd = 0;
            let newDataTableInput = dataTableInput!;
            let term = newDataTableInput.variables[0].alias;

            // If we not edit existing cloud storage input we insert
            // cloud storage alias into metric
            if (!this.selectedCloudStorageOption) {
                let result = this.insertTermIntoMetric(term.concat(" "));
                metric = result.metric;
                newSelectionEnd = result.newSelectionEnd;
            } else {
                // Else we are replacing old alias by new
                metric = this.getMetric();
                metric = ` ${metric.slice(1)} `;
                let oldTerm = ` ${this.selectedCloudStorageOption.label} `;
                let newTerm = ` ${term} `;
                metric = metric.replaceAll(oldTerm, newTerm);
                metric = "=".concat(metric);
            }
            let canvasDataTableInput = toCanvasDataTableInputDetails([
                newDataTableInput,
            ]);
            let tag = { ...this.state.tag };
            if (tag.dataTableInputDetails == null) {
                tag.dataTableInputDetails = [];
            }
            if (this.selectedCloudStorageOption) {
                // If we are editing cloud storage input we replace its
                // dataTableInput
                let value = this.selectedCloudStorageOption.value as number[];
                tag.dataTableInputDetails[value[0]] = canvasDataTableInput[0];
            }
            // else we are adding a new dataTableInput
            else
                tag.dataTableInputDetails = tag.dataTableInputDetails.concat(
                    canvasDataTableInput
                );
            let output;
            // If output index > 1 then we need to update metric in additional
            // outputs else we need to update default metric
            if (this.props.outputIndex > 1) {
                output = tag.additionalOutputs[this.props.outputIndex - 2];
            } else output = tag;
            output.metric = metric;

            this.setState({ tag: tag }, () => {
                this.inputRef.current?.focus({ preventScroll: true });
                if (this.selectedCloudStorageOption == null)
                    this.inputRef.current?.setSelectionRange(
                        newSelectionEnd,
                        newSelectionEnd
                    );
            });
        } else {
            this.inputRef.current?.focus({ preventScroll: true });
        }
        this.selectedCloudStorageOption = undefined;
    }
    /*!
     * function getMetric returns default metric if outputIndex is 1,
     * or metric from corresponding additionalOutput
     */
    getMetric(): string {
        if (this.props.outputIndex === 1) {
            return this.state.tag.metric;
        } else
            return this.state.tag.additionalOutputs[this.props.outputIndex - 2]
                .metric;
    }

    /*!
     * function getVariables returns a set of variables in the metric
     */
    getVariables(metric: string): Set<string> | null {
        let expression: Expression;
        try {
            expression = Parser.parse(metric.substring(1));
        } catch (error) {
            return null;
        }

        return new Set<string>(
            expression.variables().map((v) => v.toLowerCase())
        );
    }

    /*!
     * function getColumnFormat returns format if outputIndex is 1,
     * or format from corresponding additionalOutput
     */
    getColumnFormat() {
        if (this.props.outputIndex === 1) {
            return this.state.tag.format;
        } else
            return this.state.tag.additionalOutputs[this.props.outputIndex - 2]
                .format;
    }
    /*!
     * function setMetric called when we change input metric
     * from keyboard or when we select new input option
     */
    setMetric(metric: string, option?: InputOption) {
        if (!metric.startsWith("=")) metric = "=".concat(metric);
        if (metric === "= ") metric = metric.replace(/\s/g, "");
        this.setState(
            (state) => {
                let tag = { ...state.tag };
                let output =
                    this.props.outputIndex === 1
                        ? tag
                        : tag.additionalOutputs[this.props.outputIndex - 2];
                output.metric = metric;
                let variables = this.getVariables(metric);
                // User is currently editing the formula, and it's not valid yet
                if (variables == null) {
                    variables = state.variables;
                }
                // If we add regular node input
                if (option?.type === InputType.Regular) {
                    if (output.childrenIds == null) output.childrenIds = [];
                    let childId = output.childrenIds.find(
                        (childId) => childId === option.value
                    );
                    if (childId == null)
                        output.childrenIds.push(option.value as number);
                }
                // If we add global input
                if (option?.type === InputType.Global) {
                    if (output.globalInputIds == null)
                        output.globalInputIds = [];
                    let globalInput = output.globalInputIds.find(
                        (globalId) => globalId.value === option.value
                    );
                    if (globalInput == null)
                        output.globalInputIds.push({
                            label: normalizeLabel(option),
                            value: option.value as number,
                        });
                }
                // If we add shared input
                if (option?.type === InputType.Linked) {
                    if (output.childrenSharedIds == null)
                        output.childrenSharedIds = [];
                    let sharedId = output.childrenSharedIds.find(
                        (sharedId) => sharedId.value === option.value
                    );
                    if (sharedId == null)
                        output.childrenSharedIds.push({
                            label: normalizeLabel(option),
                            value: option.value as number,
                        });
                }

                if (output.childrenIds != null)
                    output.childrenIds = output.childrenIds.filter((id) => {
                        let node = this.props.canvasTreeStore.canvasTreeState.get(
                            id
                        );
                        return node && metric.includes(node.outerId);
                    });
                if (output.childrenSharedIds != null)
                    output.childrenSharedIds = output.childrenSharedIds.filter(
                        (option) => metric.includes(option.label)
                    );
                if (output.globalInputIds != null) {
                    output.globalInputIds = output.globalInputIds.filter(
                        (option) => metric.includes(option.label)
                    );
                }
                return { tag: tag, variables: variables };
            },
            () => {
                let output =
                    this.props.outputIndex === 1
                        ? this.state.tag
                        : this.state.tag.additionalOutputs[
                              this.props.outputIndex - 2
                          ];
                // FormulaInformationStore is mobx component used
                // for highlighting current output
                //
                FormulaInformationStore.editOutput = _.cloneDeep(
                    output
                ) as CanvasElementOutput;
                this.inputRef.current?.focus({ preventScroll: true });
            }
        );
    }
    /*!
     * function setColumnFormat called when we modify Format of formula
     */
    setColumnFormat(format: ColumnFormat) {
        this.setState((state) => {
            let tag = { ...state.tag };
            if (this.props.outputIndex === 1) {
                tag.format = format;
            } else {
                tag.additionalOutputs[
                    this.props.outputIndex - 2
                ].format = format;
            }
            return { tag: tag };
        });
    }

    /*!
     * function insertTermIntoMetric inserts normalized term of selected
     * option into input metric
     */
    insertTermIntoMetric(term: string) {
        let metric = this.getMetric();
        let currentSelectionEnd =
            this.inputRef.current?.selectionEnd ?? metric.length - 1;
        let metricList = metric.slice(1, currentSelectionEnd).split(" ");
        let metricSuffix = metricList[metricList.length - 1];
        let newMetric =
            metric.slice(0, currentSelectionEnd - metricSuffix.length) +
            term +
            metric.slice(currentSelectionEnd);
        return {
            metric: newMetric,
            newSelectionEnd:
                currentSelectionEnd + term.length - metricSuffix.length,
        };
    }

    /*!
     * function addOption called of we select new option from list below
     * metric input
     */
    addOption(option: InputOption) {
        let term = `${normalizeLabel(option)} `;
        let { metric, newSelectionEnd } = this.insertTermIntoMetric(term);
        this.setMetric(metric, option);
        setTimeout(() => {
            this.inputRef.current?.setSelectionRange(
                newSelectionEnd,
                newSelectionEnd
            );
        }, 0);
    }
    componentDidMount() {
        FormulaInformationStore.highlightItems = true;
        FormulaInformationStore.editItemId = this.state.tag.id;
        FormulaInformationStore.outputIndex = this.props.outputIndex;
        let tag = this.state.tag;
        let output =
            this.props.outputIndex === 1
                ? tag
                : tag.additionalOutputs[this.props.outputIndex - 2];
        FormulaInformationStore.editOutput = _.cloneDeep(
            output
        ) as CanvasElementOutput;
        setTimeout(() => {
            this.inputRef.current?.focus({ preventScroll: true });
        }, 0);
    }
    componentWillUnmount() {
        // Reset highlight information
        FormulaInformationStore.initDefault();
    }
    componentDidUpdate(prevProps: Props) {
        if (
            !_.isEqual(prevProps.tag, this.props.tag) &&
            !_.isEqual(this.state.tag, this.props.tag)
        ) {
            let tag = _.cloneDeep(this.props.tag);
            if (
                this.props.outputIndex > 1 &&
                tag.additionalOutputs.length < this.props.outputIndex - 1
            ) {
                tag.additionalOutputs.push({
                    metric: "=",
                    value: NaN,
                    unit: "",
                });
            } else {
                if (!tag.metric.startsWith("=")) tag.metric = "=";
            }
            this.setState({
                tag: tag,
                variables: this.getVariables(this.getMetric()) ?? new Set(),
            });
        }
    }

    buildPageItem(tab: Tab) {
        let selected = tab === this.state.tab;
        return (
            <div
                className="flex-simple-column"
                style={{ marginRight: "11px" }}
                onClick={(_evt) => {
                    this.setState({ tab: tab }, () => {
                        FormulaInformationStore.isFormatting =
                            this.state.tab === Tab.Format;
                        if (this.state.tab === Tab.Formula) {
                            this.inputRef.current?.focus({
                                preventScroll: true,
                            });
                        }
                    });
                }}
            >
                <span
                    className="unselectable"
                    style={{
                        fontFamily: "Roboto",
                        fontWeight: selected ? 700 : 400,
                        fontSize: 10,
                        color: "#657D95",
                    }}
                >
                    {TabNames[tab]}
                </span>
                {selected && (
                    <div
                        style={{
                            height: 2,
                            width: "100%",
                            backgroundColor: "#657D95",
                        }}
                    />
                )}
            </div>
        );
    }

    handleOption(option: InputOption | null) {
        if (option != null) {
            if (option.type === InputType.Cloud) {
                this.openCloudStorage();
                return;
            }
            let sameElement =
                option.type === InputType.Regular &&
                option.value === this.state.tag.id;
            if (!sameElement) this.addOption(option);
        }
    }

    /*!
     * function getOption returns list of all options:
     * regular nodes, global inputs, shared nodes,
     * cloud storage
     */
    getOptions(): InputOption[] {
        let options: InputOption[] = [];
        for (let item of this.props.canvasTreeStore.canvasTreeState.values()) {
            options.push({
                label: item.outerId,
                value: item.id,
                type: InputType.Regular,
            });
            if (isTextBox(item)) {
                if (item.metric.length !== 0) {
                    options.push({
                        label: `${item.outerId}_1`,
                        value: item.id,
                        type: InputType.Regular,
                        outputIndex: 1,
                    });
                }
                for (let i = 0; i < item.additionalOutputs.length; ++i) {
                    options.push({
                        label: `${item.outerId}_${i + 2}`,
                        value: item.id,
                        type: InputType.Regular,
                        outputIndex: i + 2,
                    });
                }
            }
        }
        options = options
            .concat(
                SharedBoxesStore.sharedBoxes(
                    this.props.canvasTreeStore.canvasId!
                ).map((item) => ({
                    label: item.label,
                    value: item.value,
                    type: InputType.Linked,
                    canvasId: item.canvasId,
                    outerId: item.outerId,
                }))
            )
            .concat(
                GlobalInputs.map((globalInput) => ({
                    ...globalInput,
                    type: InputType.Global,
                }))
            );
        let metric = this.getMetric();
        let currentSelectionEnd =
            this.inputRef.current?.selectionEnd ?? metric.length - 1;
        let metricList = metric.slice(1, currentSelectionEnd).split(" ");
        let metricSuffix = metricList[metricList.length - 1].toLowerCase();
        options = options.filter(
            (option) =>
                option.label.toLowerCase().startsWith(metricSuffix) ||
                StringUtils.normalizeString(
                    option.label.toLowerCase()
                ).startsWith(metricSuffix)
        );
        options.push({
            label: "CONNECT TO DATA",
            value: NaN,
            type: InputType.Cloud,
        });
        let output = this.state.tag;
        if (output.dataTableInputDetails != null) {
            let dataTableInputOptions = output.dataTableInputDetails.map(
                (item, dataTableIndex) => ({
                    label: item.variables[0].alias,
                    value: [dataTableIndex, 0],
                    type: InputType.CloudInput,
                })
            );
            dataTableInputOptions = dataTableInputOptions.sort((a, b) => {
                return a.label > b.label ? 1 : -1;
            });
            options = options.concat(dataTableInputOptions);
        }

        options = options.sort((a, b) => {
            return a.type < b.type ? 1 : -1;
        });

        return options;
    }

    /*!
     * function buildFormatView renders "Format" section in popup
     */
    private buildFormatView(): JSX.Element | null {
        let columnFormat = this.getColumnFormat();
        return (
            <div
                prevent-mouse-leave="true"
                className="flex-simple-column"
                style={{
                    paddingTop: 11,
                    paddingLeft: 11,
                    paddingRight: 11,
                    paddingBottom: 3,
                    width: "50%",
                }}
            >
                <span
                    style={{
                        ...textFormatStyle,
                        fontSize: 12,
                        textAlign: "start",
                        width: "100%",
                    }}
                >
                    {"Format"}
                </span>

                <Select
                    inputId="format-select"
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={"Format type"}
                    styles={{
                        ...customSelectStylesLight,
                        container: (base) => ({
                            ...base,
                            marginTop: 0,
                            width: "100%",
                        }),
                    }}
                    options={spreadSheetColumnOptionsForTextBox}
                    onChange={(newValue) => {
                        let type = (newValue as SpreadSheetColumnOption).value;
                        switch (type) {
                            case SpreadSheetColumnType.Number: {
                                let columnFormat: NumberFormat = {
                                    type: type,
                                    decimalPoints: 2,
                                    useCommaSeparator: true,
                                    useAbbreviation: false,
                                    numberType: NumberFormatType.Number,
                                };
                                this.setColumnFormat(columnFormat);
                                break;
                            }
                            case SpreadSheetColumnType.Text:
                            case SpreadSheetColumnType.Month:
                                {
                                    let columnFormat: ColumnFormat = {
                                        type: type,
                                    };
                                    this.setColumnFormat(columnFormat);
                                }
                                break;
                            case SpreadSheetColumnType.Date:
                                {
                                    let columnFormat: DateFormat = {
                                        type: type,
                                        format: this.state.formatOptions[0]
                                            .value,
                                    };
                                    this.setColumnFormat(columnFormat);
                                }

                                break;
                            default:
                                // code...
                                break;
                        }
                    }}
                    value={
                        columnFormat != null
                            ? spreadSheetColumnOptionsForTextBox.find(
                                  (item) => item.value === columnFormat!.type
                              )
                            : null
                    }
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                {columnFormat && isNumberFormat(columnFormat) && (
                    <>
                        <div
                            className="my-row"
                            style={{
                                marginTop: "10px",
                                alignItems: "center",
                                alignSelf: "flex-start",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <span style={textFormatStyle}>{"Type"}</span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={"Number format"}
                                    styles={{
                                        ...customSelectStylesLight,
                                        container: (base) => ({
                                            ...base,
                                            width: "135px",
                                        }),
                                    }}
                                    options={numberFormatOptions}
                                    onChange={(newValue) => {
                                        let type = (newValue as NumberFormatOption)
                                            .value;
                                        this.setColumnFormat({
                                            ...columnFormat,
                                            numberType: type,
                                        } as NumberFormat);
                                    }}
                                    value={numberFormatOptions.find(
                                        (option) =>
                                            option.value ===
                                            (columnFormat as NumberFormat)
                                                .numberType
                                    )}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                onKeyDown={(evt) => {
                                    switch (evt.key) {
                                        case "Enter":
                                            // Accept changes if Enter clicked
                                            evt.stopPropagation();
                                            evt.preventDefault();
                                            this.acceptChanges(true);
                                            break;
                                    }
                                }}
                            >
                                <span style={textFormatStyle}>
                                    {"Decimals"}
                                </span>
                                <input
                                    autoFocus
                                    style={{
                                        ...containersStyle,
                                        ...textFormatStyle,
                                        height: "26px",
                                        width: "65px",
                                        outline: "none",
                                        textAlign: "center",
                                    }}
                                    placeholder="Decimal places"
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        let decimalPoints: number = 0;
                                        if (StringUtils.isNumber(value)) {
                                            decimalPoints = Math.min(
                                                Math.max(
                                                    0,
                                                    Math.trunc(Number(value))
                                                ),
                                                20
                                            );
                                        }

                                        this.setColumnFormat({
                                            ...columnFormat,
                                            decimalPoints: decimalPoints,
                                        } as NumberFormat);
                                    }}
                                    defaultValue={String(
                                        columnFormat.decimalPoints
                                    )}
                                />
                            </div>
                        </div>
                        <div
                            className="my-row"
                            style={{
                                marginTop: "10px",
                                alignItems: "center",
                                alignSelf: "flex-start",
                                width: "100%",
                            }}
                        >
                            <input
                                type="checkbox"
                                style={{ margin: 0 }}
                                onChange={(e) => {
                                    this.setColumnFormat({
                                        ...columnFormat,
                                        useAbbreviation: e.target.checked,
                                    } as NumberFormat);
                                }}
                                checked={columnFormat.useAbbreviation ?? false}
                                width={24}
                                height={10}
                            />
                            <span style={{ ...textFormatStyle, fontSize: 10 }}>
                                {"Use Abbreviation"}
                            </span>
                        </div>
                        <div
                            className="my-row"
                            style={{
                                marginTop: "10px",
                                alignItems: "center",
                                alignSelf: "flex-start",
                                width: "100%",
                            }}
                        >
                            <input
                                type="checkbox"
                                style={{ margin: 0 }}
                                onChange={(e) => {
                                    this.setColumnFormat({
                                        ...columnFormat,
                                        useCommaSeparator: e.target.checked,
                                    } as NumberFormat);
                                }}
                                checked={
                                    (columnFormat as NumberFormat)
                                        .useCommaSeparator ?? true
                                }
                                width={24}
                                height={10}
                            />
                            <span style={{ ...textFormatStyle, fontSize: 10 }}>
                                {"Use 1000 separator(,)"}
                            </span>
                        </div>
                    </>
                )}
                {columnFormat && isDateFormat(columnFormat) && (
                    <div
                        onKeyDown={(evt) => {
                            switch (evt.key) {
                                case "Enter":
                                    // Accept changes if Enter clicked
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                    this.acceptChanges(true);
                                    break;
                            }
                        }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                            width: "100%",
                        }}
                    >
                        <span
                            style={{
                                ...textFormatStyle,
                            }}
                        >
                            {"Date format"}
                        </span>
                        <DateTimeFormatSelect
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            onCreateOption={(option) => {
                                this.setColumnFormat({
                                    ...columnFormat,
                                    format: option.value,
                                } as DateFormat);
                                this.setState((state) => ({
                                    formatOptions: [
                                        option,
                                        ...state.formatOptions,
                                    ],
                                }));
                            }}
                            styles={{
                                ...customSelectStylesLight,
                                container: (base) => ({
                                    ...base,
                                    width: "100%",
                                }),
                            }}
                            options={this.state.formatOptions}
                            onChange={(newValue) => {
                                this.setColumnFormat({
                                    ...columnFormat,
                                    format: (newValue as DateTimeOptionType)
                                        .value,
                                } as DateFormat);
                            }}
                            value={columnFormat.format ?? ""}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                    </div>
                )}
            </div>
        );
    }

    /*!
     * acceptChanges called when we click Enter key when input metric
     * is focused or click Save button in Format section
     */
    private acceptChanges(hidePopup: boolean) {
        this.props.onAccept(this.state.tag, hidePopup);
    }

    private applyCurrentIndex(
        currentOptionIndex: number,
        options: InputOption[]
    ) {
        this.setState({ currentOptionIndex: currentOptionIndex }, () => {
            FormulaInformationStore.currentItem = options[currentOptionIndex];
        });
    }

    /*!
     * function openCloudStorage called when
     * we are creating a new cloud storage input or
     * editing existing
     */
    private openCloudStorage() {
        this.setState({ showCloudStorage: true }, () => {
            let lastConfiguration = undefined;
            let dataTableInputDetails =
                this.state.tag.dataTableInputDetails ?? [];
            // If we edit cloud storage input then the last configuration is
            // its configuration, else last configuration is configuration of
            // last added cloud storage input
            if (this.selectedCloudStorageOption) {
                let value = this.selectedCloudStorageOption.value as number[];
                lastConfiguration = dataTableInputDetails[value[0]];
            } else
                lastConfiguration =
                    dataTableInputDetails[dataTableInputDetails.length - 1];

            this.props.onOpenCloudStorage({
                callback: this.onDataInputAdd,
                lastConfiguration: lastConfiguration,
                addNewElement: this.selectedCloudStorageOption == null,
            });
        });
    }

    /*!
     * function buildFormulaView renders "Formula" section in popup
     */
    private buildFormulaView(): JSX.Element {
        let options = this.getOptions();
        let currentOption =
            this.state.currentOptionIndex != null
                ? options[this.state.currentOptionIndex]
                : null;
        return (
            <>
                <div
                    className="my-row"
                    style={{
                        ...containersStyle,
                        width: "100%",
                        padding: "2px 0px 2px 0px",
                        alignItems: "center",
                    }}
                >
                    <input
                        ref={this.inputRef}
                        style={{
                            ...textFormulaStyle,
                            flex: 1,
                            marginLeft: "8px",
                            border: "none",
                            outline: "none",
                        }}
                        value={this.getMetric()}
                        onChange={(evt) => {
                            let metric = evt.target.value;
                            this.setMetric(metric);
                        }}
                        onMouseUp={(evt) => {
                            let selectionStart = this.inputRef.current
                                ?.selectionStart;
                            let selectionEnd = this.inputRef.current
                                ?.selectionEnd;
                            if (
                                selectionStart != null &&
                                selectionEnd != null
                            ) {
                                let metric = this.getMetric().slice(1);
                                metric = ` ${metric} `;

                                let cloudOptions = options.filter(
                                    (option) =>
                                        option.type === InputType.CloudInput
                                );
                                for (let cloudOption of cloudOptions) {
                                    let label = ` ${cloudOption.label} `;
                                    let index = 0;
                                    while (index !== -1) {
                                        index = metric.indexOf(label, index);
                                        if (
                                            index >= 0 &&
                                            selectionStart >= index &&
                                            selectionEnd >= index &&
                                            selectionStart <
                                                index + label.length &&
                                            selectionEnd < index + label.length
                                        ) {
                                            this.selectedCloudStorageOption = cloudOption;
                                            this.openCloudStorage();
                                            break;
                                        }
                                        if (index !== -1) {
                                            index += label.length;
                                        }
                                    }
                                }
                            }
                        }}
                        onKeyDown={(evt) => {
                            switch (evt.key) {
                                // Select option by arrows
                                case "ArrowUp":
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    if (this.state.currentOptionIndex == null)
                                        this.applyCurrentIndex(
                                            options.length - 1,
                                            options
                                        );
                                    else {
                                        let currentOptionIndex =
                                            this.state.currentOptionIndex! - 1;
                                        if (currentOptionIndex < 0)
                                            currentOptionIndex =
                                                options.length - 1;
                                        this.applyCurrentIndex(
                                            currentOptionIndex,
                                            options
                                        );
                                    }

                                    break;
                                case "ArrowDown":
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                    if (this.state.currentOptionIndex == null)
                                        this.applyCurrentIndex(0, options);
                                    else {
                                        let currentOptionIndex =
                                            (this.state.currentOptionIndex! +
                                                1) %
                                            options.length;
                                        this.applyCurrentIndex(
                                            currentOptionIndex,
                                            options
                                        );
                                    }
                                    break;
                                case "Tab":
                                case "Enter":
                                    // Accept changes if Enter clicked
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                    let inputValue = this.inputRef.current?.value.trim();

                                    if (
                                        inputValue !== "=" &&
                                        inputValue ===
                                            inputValue?.toUpperCase() &&
                                        inputValue?.split(" ").length === 1
                                    ) {
                                        this.acceptChanges(false);
                                        this.setState(
                                            { tab: Tab.Format },
                                            () => {
                                                FormulaInformationStore.isFormatting = true;
                                            }
                                        );
                                        document
                                            .getElementById("format-select")
                                            ?.focus();
                                    } else {
                                        if (
                                            this.state.currentOptionIndex !=
                                            null
                                        ) {
                                            let option =
                                                options[
                                                    this.state
                                                        .currentOptionIndex
                                                ];
                                            this.handleOption(option);
                                        }
                                    }
                                    break;
                                case "Escape":
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                    this.props.onReject();
                                    break;
                                case "Backspace":
                                    if (this.inputRef.current?.value === "=") {
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                        this.props.onReject();
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }}
                    />
                    <span
                        className="unselectable"
                        style={{ ...hintStyle, marginRight: 5, padding: 2 }}
                    >
                        ENTER
                    </span>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        border: "1px solid #D1D1D1",
                        marginTop: 10,
                        width: "100%",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{
                            padding: 5,
                            borderRight: "1px solid #D1D1D1",
                            backgroundColor: "white",
                            width: "50%",
                        }}
                    >
                        <div className="flex-simple-column">
                            <ListGroup
                                style={{
                                    maxHeight: 200,
                                    overflow: "auto",
                                    marginTop: 3,
                                    marginLeft: 1,
                                    marginRight: 1,
                                }}
                            >
                                {options.map((option, index) => {
                                    let output =
                                        this.props.outputIndex === 1
                                            ? this.state.tag
                                            : this.state.tag.additionalOutputs[
                                                  this.props.outputIndex - 2
                                              ];

                                    // Check if option is already selected
                                    let selected: boolean = false;
                                    if (option.type === InputType.Regular) {
                                        let node = this.props.canvasTreeStore.canvasTreeState.get(
                                            option.value as number
                                        );
                                        // For text boxes, we need to only
                                        // highlight outputs that were selected
                                        if (node != null && isTextBox(node)) {
                                            let outputId = (option.outputIndex ==
                                            null
                                                ? node.outerId
                                                : `${node.outerId}_${option.outputIndex}`
                                            ).toLowerCase();
                                            selected =
                                                (output?.childrenIds?.includes(
                                                    option.value as number
                                                ) ??
                                                    false) &&
                                                this.state.variables.has(
                                                    outputId
                                                );
                                        } else {
                                            selected =
                                                output?.childrenIds?.includes(
                                                    option.value as number
                                                ) ?? false;
                                        }
                                    } else if (
                                        option.type === InputType.Global
                                    ) {
                                        selected =
                                            output.globalInputIds?.find(
                                                (globalInput) =>
                                                    globalInput.value ===
                                                    option.value
                                            ) != null;
                                    } else if (
                                        option.type === InputType.Linked
                                    ) {
                                        selected =
                                            output.childrenSharedIds?.find(
                                                (sharedInput) =>
                                                    sharedInput.value ===
                                                    option.value
                                            ) != null;
                                    }

                                    const optionColor =
                                        selected ||
                                        index === this.state.currentOptionIndex
                                            ? "#39F"
                                            : textFormulaStyle.color;
                                    return (
                                        <ListGroupItem
                                            key={index}
                                            style={{
                                                padding: 0,
                                                border: "none",
                                            }}
                                            onClick={() => {
                                                this.handleOption(option);
                                            }}
                                            onMouseEnter={() => {
                                                // Highlight hovered item
                                                FormulaInformationStore.currentItem = option;
                                            }}
                                            onMouseLeave={() => {
                                                FormulaInformationStore.currentItem = currentOption;
                                            }}
                                        >
                                            <div
                                                className="my-row hover-div"
                                                style={{
                                                    paddingLeft: "17px",
                                                    height: "26px",
                                                    width: "100%",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                        this.state
                                                            .currentOptionIndex ===
                                                        index
                                                            ? "rgba(101, 125, 149, 0.1)"
                                                            : undefined,
                                                }}
                                            >
                                                {option.type === 1 && (
                                                    <LinkIcon
                                                        width={14}
                                                        height={14}
                                                        style={{
                                                            fill: optionColor,
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                )}
                                                {option.value === 2 &&
                                                    option.type === 3 && (
                                                        <CalendarIcon
                                                            width={14}
                                                            height={14}
                                                            style={{
                                                                fill: optionColor,
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                    )}
                                                {option.value === 3 &&
                                                    option.type === 3 && (
                                                        <ClockIcon
                                                            width={14}
                                                            height={14}
                                                            style={{
                                                                fill: optionColor,
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                    )}
                                                {option.value === 1 &&
                                                    option.type === 3 && (
                                                        <PersonIcon
                                                            width={14}
                                                            height={14}
                                                            style={{
                                                                fill: optionColor,
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                    )}
                                                {option.type === 4 && (
                                                    <HdIcon
                                                        width={14}
                                                        height={14}
                                                        style={{
                                                            fill: optionColor,
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                )}

                                                <span
                                                    style={{
                                                        ...textFormulaStyle,
                                                        color:
                                                            selected ||
                                                            index ===
                                                                this.state
                                                                    .currentOptionIndex
                                                                ? "#39F"
                                                                : textFormulaStyle.color,
                                                        opacity:
                                                            option.type ===
                                                                InputType.Regular &&
                                                            option.value ===
                                                                this.state.tag
                                                                    .id
                                                                ? 0.5
                                                                : 1,
                                                    }}
                                                    className="unselectable"
                                                >
                                                    {option.label ===
                                                        "CURRENT_DATE" &&
                                                        "Current date"}
                                                    {option.label ===
                                                        "CURRENT_TIME" &&
                                                        "Current time"}
                                                    {option.label ===
                                                        "USER_NAME" &&
                                                        "User name"}
                                                    {option.label ===
                                                        "CONNECT TO DATA" &&
                                                        "Connect to Data"}
                                                    {![
                                                        "CURRENT_DATE",
                                                        "CURRENT_TIME",
                                                        "USER_NAME",
                                                        "CONNECT TO DATA",
                                                    ].includes(option.label) &&
                                                        option.label}
                                                </span>
                                                {option.type ===
                                                    InputType.CloudInput && (
                                                    <Button
                                                        className="btn-small-like-select"
                                                        style={{
                                                            marginLeft: "10px",
                                                            backgroundColor:
                                                                "transparent",
                                                            color: "#39F",
                                                            width: "19px",
                                                            height: "19px",
                                                        }}
                                                        onClick={(evt) => {
                                                            // remove cloud storage input
                                                            evt.stopPropagation();
                                                            let indices = option.value as number[];
                                                            this.setState(
                                                                (state) => {
                                                                    let tag = {
                                                                        ...state.tag,
                                                                    };
                                                                    if (
                                                                        tag.dataTableInputDetails !=
                                                                        null
                                                                    ) {
                                                                        let dataTableInputDetail =
                                                                            tag
                                                                                .dataTableInputDetails[
                                                                                indices[0]
                                                                            ];
                                                                        if (
                                                                            dataTableInputDetail !=
                                                                            null
                                                                        ) {
                                                                            dataTableInputDetail.variables.splice(
                                                                                indices[1],
                                                                                1
                                                                            );
                                                                            if (
                                                                                dataTableInputDetail
                                                                                    .variables
                                                                                    .length ===
                                                                                0
                                                                            ) {
                                                                                tag.dataTableInputDetails.splice(
                                                                                    indices[0],
                                                                                    1
                                                                                );
                                                                            }
                                                                        }
                                                                    }
                                                                    return {
                                                                        tag: tag,
                                                                    };
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        {"\uFF0D" /* minus */}
                                                    </Button>
                                                )}
                                                <div style={{ flex: 1 }} />
                                                {index ===
                                                    this.state
                                                        .currentOptionIndex && (
                                                    <span
                                                        className="unselectable"
                                                        style={{
                                                            ...hintStyle,
                                                            marginRight: "20px",
                                                        }}
                                                    >
                                                        +TAB
                                                    </span>
                                                )}
                                            </div>
                                        </ListGroupItem>
                                    );
                                })}
                            </ListGroup>
                        </div>
                    </div>
                    {this.buildFormatView()}
                </div>
                <div
                    className="my-row"
                    style={{
                        marginTop: "10px",
                        alignItems: "center",
                        alignSelf: "flex-start",
                        justifyContent: "flex-end",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            height: "18px",
                            width: "50%",
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                        }}
                    >
                        <span
                            className="unselectable"
                            style={{
                                fontWeight: 400,
                                fontFamily: "Roboto",
                                fontSize: 12,
                                color: lightThemeStyle.primaryTextColor,
                                letterSpacing: "normal",
                            }}
                        >
                            {this._renderLabel(currentOption?.type)}
                        </span>
                    </div>

                    <Button
                        type="button"
                        className="btn btn-sm btn-primary btn-small-formats my-primary"
                        style={{
                            ...buttonStyle,
                            backgroundColor: "transparent",
                            border: "1px solid #e0e0e0",
                            color: "#3B82C9",
                        }}
                        onClick={() => {
                            this.props.onReject();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{ ...buttonStyle, marginLeft: 10 }}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                            this.acceptChanges(true);
                        }}
                    >
                        Insert
                    </Button>
                </div>
            </>
        );
    }

    private _renderLabel(type: InputType | undefined): React.ReactNode {
        switch (type) {
            case InputType.Global:
                return "Global Variables";
            case InputType.Regular:
                return "On Slide";
            case InputType.Cloud:
            case InputType.CloudInput:
                return "Connect to data in the cloud";

            default:
                return "Other Slides";
        }
    }

    private buildInnerView(): JSX.Element {
        return (
            <div
                prevent-mouse-leave="true"
                className="flex-simple-column"
                style={{
                    paddingTop: 11,
                    paddingLeft: 11,
                    paddingRight: 11,
                    paddingBottom: 13,
                    width: "483px",
                    justifyContent: "center",
                    backgroundColor: "#f9f9f9",
                    borderRadius: 6,
                }}
            >
                <span style={textFormatStyle}>Formula</span>
                {this.buildFormulaView()}
            </div>
        );
    }

    render() {
        return (
            <OutsideAlerter
                onReject={
                    this.state.showCloudStorage ? () => {} : this.props.onReject
                }
            >
                <Draggable
                    cancel="input"
                    position={{
                        x: this.state.left,
                        y: this.state.top,
                    }}
                    onDrag={() => {
                        this.drag = true;
                    }}
                    onStop={(evt, data) => {
                        if (this.drag) {
                            this.drag = false;
                            this.setState({
                                left: data.x,
                                top: data.y,
                            });
                        }
                    }}
                >
                    <div
                        tabIndex={0}
                        className="flex-simple-column"
                        style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            zIndex: 1000,
                            opacity: this.state.showCloudStorage ? 0.5 : 1,
                            pointerEvents: this.state.showCloudStorage
                                ? "none"
                                : "auto",

                            backgroundColor: "#FFFFFF",
                            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: "3px",
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "row",
                        }}
                        onContextMenu={(evt) => {
                            evt.stopPropagation();
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                            switch (evt.key) {
                                case "Escape":
                                    evt.preventDefault();
                                    this.props.onReject();
                                    break;
                                default:
                                    break;
                            }
                        }}
                        onMouseDown={(evt) => {
                            evt.stopPropagation();
                        }}
                    >
                        {this.buildInnerView()}
                    </div>
                </Draggable>
            </OutsideAlerter>
        );
    }
}
export default FormulaPopup;
