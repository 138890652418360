/* @flow */

import React from "react";
import "./styles.css";

const Component = () => (
    <div className="rdw-spinner">
        <div className="rdw-bounce1" />
        <div className="rdw-bounce2" />
        <div className="rdw-bounce3" />
    </div>
);

export default Component;
