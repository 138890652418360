import React, { useState, useEffect } from "react";

import {
    Density2dFinding,
    defaultDensity2dBinSize,
    defaultDensity2dMinColor,
    defaultDensity2dMaxColor,
    defaultDensity2dType,
} from "common/Finding";
import EditableAxisItem from "../EditableAxisItem";
import * as d3 from "d3";
import * as d3hexbin from "d3-hexbin";
import * as d3rectbin from "common/D3Rectbin";
import * as d3contour from "d3-contour";
import { TooltipStyles } from "../../TooltipStyles";
import { mainStyle } from "common/MainStyle";
import {
    getGridColorByTheme,
    getDefaultColorsByTheme,
} from "../../BarChartTheme";
import D3ChartBase, { AxisType } from "common/graphics/v2/D3ChartBase";
import Portal from "common/Portal";
import { colorList } from "common/graphics/LineColors";
import { ReactComponent as SwapIcon } from "icons/canvas/exploration/swap_axes.svg";
import AnimationSlider from "common/AnimationSlider";
import ColorPicker from "../ChartColorPicker";
import Popup from "reactjs-popup";
import { chartColorPickerPopupStyles } from "common/Constants";
import { getTextSize } from "common/utilities/MeasureText";
import { useUpdateEffect } from "common/CustomHooks";
import { calculateChartColorPickerPosition, getLongestString } from "../utils";

interface Props {
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    content: Density2dFinding["content"];
    trendlineInfo: Density2dFinding["content"]["trendlineInfo"];
    config: Density2dFinding["config"];
    onChangeData?: (
        data: Density2dFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: Density2dFinding["config"],
        updateData?: boolean
    ) => void;
    onChangeContent?: (
        content: Density2dFinding["content"],
        updateData?: boolean
    ) => void;
    width: number;
    height: number;
    scale: number;
    selected: boolean;
}

interface Point {
    x: number;
    y: number;
    index: number;
    time?: string | number | null;
}

interface Bin extends Array<Point> {
    x: number;
    y: number;
    index: number;
}

function countByTime(
    points: Point[],
    time: string | number | null | undefined
): number {
    if (time == null) {
        return points.length;
    }
    let length = 0;
    for (let item of points) {
        if (item.time == null || item.time <= time) {
            length += 1;
        }
    }
    return length;
}

export default function Density2d(props: Props) {
    let [showTooltipInfo, setShowTooltipInfo] = useState<{
        x: number;
        y: number;
        bin?: Bin;
        contour?: d3.ContourMultiPolygon;
    } | null>(null);
    let [longestYAxisValue, setLongestYAxisValue] = useState<string>("0");
    let [colorPickerIsEnabled, setColorPickerIsEnabled] = useState<boolean>(
        false
    );
    let [axisItemStyles, setAxisItemStyles] = useState<Record<any, number>>({
        xAxisWidth: 0,
        yAxisHeight: 0,
        xAxisOffset: 0,
        yAxisOffset: 0,
    });
    let [showColorPicker, setShowColorPicker] = useState<{
        x: number;
        y: number;
    } | null>(null);

    let parentRef = React.useRef<HTMLDivElement>(null);
    let parentSvgRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);
    let densityRef = React.useRef<
        | d3.Selection<SVGPathElement, Bin, SVGGElement, unknown>
        | d3.Selection<
              SVGPathElement,
              d3.ContourMultiPolygon,
              SVGGElement,
              unknown
          >
    >();
    let colorScaleRef = React.useRef<d3.ScaleLinear<number, number>>();
    let timeValueRef = React.useRef<string | number | null>();
    let timeAnimationTickRef = React.useRef<() => void>();

    let currentEditVariableIndex: number | undefined = undefined;
    if (props.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = props.content.data.findIndex(
            (item) => item.variableIndex == null
        );
    }

    let onChangeName = (index: number, value: string) => {
        let newData = Array.from(props.content.data);
        newData[index].name = value;
        props.onChangeData?.(newData);
    };

    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData = Array.from(props.content.data);
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        props.onChangeData?.(newData, true);
    };

    useUpdateEffect(() => {
        setTimeout(() => {
            setColorPickerIsEnabled(props.selected);
        }, 100);
    }, [props.selected]);

    useEffect(() => {
        let xAxisFontSize: number =
            props.config.ticksAndLabels?.x?.size ??
            props.config.ticksSize ??
            parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

        let yAxisFontSize: number =
            props.config.ticksAndLabels?.y?.size ??
            props.config.ticksSize ??
            parseInt(mainStyle.getPropertyValue("--graphs-axes-size"));

        let defaultColors = getDefaultColorsByTheme(props.config.chartTheme);
        // let themeOptions = getOptionsByTheme(props.config.chartTheme);
        let gridFillColor = getGridColorByTheme(
            props.config.chartTheme,
            props.config.baseBackgroundColor
        );

        let [minX, maxX] = d3.extent(props.content.data[0].value);
        minX = minX ?? 0;
        maxX = maxX ?? 10;
        let [minY, maxY] = d3.extent(props.content.data[1].value);
        minY = minY ?? 0;
        maxY = maxY ?? 10;

        let minXRange = props.config.minXRange ?? minX!;
        let maxXRange = props.config.maxXRange ?? maxX!;
        let minYRange = props.config.minYRange ?? minY!;
        let maxYRange = props.config.maxYRange ?? maxY!;

        const yAxisExtraSpace =
            getTextSize(longestYAxisValue, "Roboto", yAxisFontSize, "normal")
                .width + 7;

        const xAxisExtraSpace =
            getTextSize("0", "Roboto", xAxisFontSize, "normal").height / 2 - 21;

        // Padding is necessary to prevent tick text from being cut off
        let fullHeight = parentSvgRef.current?.clientHeight ?? 0;
        let fullWidth = parentSvgRef.current?.clientWidth ?? 0;
        let yAxisWidth = 50;
        let xAxisHeight = 30;
        // set the dimensions and margins of the graph
        let height = fullHeight - xAxisHeight;
        // We subtract yAxisExtraSpace to prevent the chart from shifting to
        // the right and cutting off axis label
        // https://eisengardai.atlassian.net/browse/EIS-259?focusedCommentId=12079
        let width = fullWidth - yAxisWidth - yAxisExtraSpace;

        setAxisItemStyles({
            xAxisWidth: width,
            yAxisHeight: height + 2,
            xAxisOffset: yAxisExtraSpace,
            yAxisOffset: xAxisExtraSpace,
        });

        // append the svg object to the body of the page
        d3.select(svgRef.current!).selectAll("*").remove();
        let svg = d3
            .select(svgRef.current!)
            .attr("width", fullWidth)
            .attr("height", fullHeight)
            .append("g")
            .style("cursor", "crosshair")
            .attr(
                "transform",
                `translate(${yAxisExtraSpace}, ${-xAxisExtraSpace})`
            );

        let base = new D3ChartBase(svg, width, height);

        base.drawBackground(gridFillColor ?? "transparent");

        const axisAbsolutePadding =
            props.config.binSize ?? defaultDensity2dBinSize;

        // Calculate ticks
        let {
            ticks: xTicks,
            decimals: xDecimals,
        } = D3ChartBase.calculateLinearTicks(
            minXRange,
            maxXRange,
            props.config?.ticksAndLabels?.x?.interval
        );
        let {
            ticks: yTicks,
            decimals: yDecimals,
        } = D3ChartBase.calculateLinearTicks(
            minYRange,
            maxYRange,
            props.config?.ticksAndLabels?.y?.interval
        );

        let xTickFormat = (tick: number) => tick.toFixed(xDecimals);
        let yTickFormat = (tick: number) => tick.toFixed(yDecimals);

        // X Axis
        let { axis: xAxis } = base.drawLinearAxis(
            AxisType.XAxis,
            {
                color:
                    props.config.axesLinesColor ?? defaultColors.axesLinesColor,
            },
            {
                color:
                    props.config.ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: xAxisFontSize,
                fontFamily: "Open Sans",
                tickSize: 0,
                tickValues: xTicks,
                tickFormat: xTickFormat,
            },
            [minXRange, maxXRange],
            undefined,
            {
                start: axisAbsolutePadding,
                end: axisAbsolutePadding,
            }
        );
        let xScale = xAxis.scale<d3.ScaleLinear<number, number>>();
        // Y Axis
        let { axis: yAxis } = base.drawLinearAxis(
            AxisType.YAxis,
            {
                color:
                    props.config.axesLinesColor ?? defaultColors.axesLinesColor,
            },
            {
                color:
                    props.config.ticksColor ??
                    mainStyle.getPropertyValue("--graphs-axes-text-color"),
                fontSize: yAxisFontSize,
                fontFamily: "Open Sans",
                tickSize: 0,
                tickValues: yTicks,
                tickFormat: yTickFormat,
            },
            [minYRange, maxYRange],
            undefined,
            {
                start: axisAbsolutePadding,
                end: axisAbsolutePadding,
            }
        );
        let yScale = yAxis.scale<d3.ScaleLinear<number, number>>();

        const longestYValue = getLongestString(yTicks.map(yTickFormat));

        if (longestYAxisValue !== longestYValue) {
            setLongestYAxisValue(longestYValue);
        }

        // Grid
        if (props.config.showGrid) {
            base.drawGrid(
                xTicks,
                yTicks,
                props.config.axesLinesColor ?? defaultColors.gridColor,
                xScale,
                yScale
            );
        }

        let points: Point[] = props.content.data[0].value.map((x, index) => ({
            x: x,
            y: props.content.data[1].value[index],
            index: index,
            time: props.content.time?.value[index],
        }));

        let density2dType = props.config.density2dType ?? defaultDensity2dType;

        timeValueRef.current = props.content.time!.uniqueValues[0];

        let plotData: Bin[];
        let pathFunction: (d: Bin) => string;

        if (density2dType === "rectangular" || density2dType === "hexagonal") {
            if (density2dType === "rectangular") {
                let rectbin = d3rectbin
                    .rectbin<Point>()
                    .x((d) => xScale(d.x))
                    .y((d) => yScale(d.y))
                    .dx(props.config.binSize ?? defaultDensity2dBinSize)
                    .dy(props.config.binSize ?? defaultDensity2dBinSize);
                plotData = rectbin(points).map((bin, index) => {
                    (bin as any).index = index;
                    return bin as Bin;
                });
                pathFunction = (d) =>
                    `M${d.x},${d.y} ${d.x + rectbin.dx()},${d.y} ${
                        d.x + rectbin.dx()
                    },${d.y + rectbin.dy()} ${d.x},${d.y + rectbin.dy()}`;
            } else {
                // "hexagonal"
                let hexbin = d3hexbin
                    .hexbin<Point>()
                    .x((d) => xScale(d.x))
                    .y((d) => yScale(d.y))
                    .radius(
                        (props.config.binSize ?? defaultDensity2dBinSize) / 2
                    )
                    .extent([
                        [xScale(minXRange), yScale(minYRange)],
                        [xScale(maxXRange), yScale(maxYRange)],
                    ]);

                plotData = hexbin(points).map((bin, index) => {
                    (bin as any).index = index;
                    return bin as Bin;
                });

                pathFunction = (d) => `M${d.x},${d.y}${hexbin.hexagon()}`;
            }

            colorScaleRef.current = d3
                .scaleLinear()
                .domain([
                    props.config.minValue ?? 0,
                    props.config.maxValue ??
                        d3.max(plotData, (d) => d.length) ??
                        100,
                ])
                .range([
                    props.config.minColor ?? defaultDensity2dMinColor,
                    props.config.maxColor ?? defaultDensity2dMaxColor,
                ]);

            densityRef.current = svg
                .append("g")
                .selectAll<SVGPathElement, Bin>("path")
                .data(plotData)
                .join("path")
                .attr("d", pathFunction)
                .attr("stroke", "black")
                .attr("stroke-width", 0.1);

            timeAnimationTickRef.current = () => {
                (densityRef.current! as d3.Selection<
                    SVGPathElement,
                    Bin,
                    SVGGElement,
                    unknown
                >).each(function (d) {
                    let count = countByTime(d, timeValueRef.current);
                    d3.select(this)
                        .attr("fill", colorScaleRef.current!(count))
                        .attr("hidden", count === 0 ? true : null);
                });
            };

            timeAnimationTickRef.current();

            densityRef
                .current!.on("mouseenter", (_event: MouseEvent, bin: Bin) => {
                    let rect = (densityRef.current!.nodes()[
                        bin.index
                    ] as SVGCircleElement).getBoundingClientRect();
                    setShowTooltipInfo({
                        x: (rect.left + rect.right) / 2,
                        y: (rect.top + rect.bottom) / 2,
                        bin: bin,
                    });
                })
                .on("mouseleave", (_event: MouseEvent) => {
                    setShowTooltipInfo(null);
                })
                .on("click", (evt) => {
                    const { y } = calculateChartColorPickerPosition(evt);
                    if (colorPickerIsEnabled) {
                        setShowColorPicker({
                            x: evt.clientX + 5,
                            y: y + 5,
                        });
                    }
                });
        } else {
            // contour and contour_shade
            let contour = d3contour
                .contourDensity<Point>()
                .x((d) => xScale(d.x))
                .y((d) => yScale(d.y))
                .size([xScale(maxXRange), yScale(maxYRange)])
                .cellSize(props.config.binSize ?? defaultDensity2dBinSize);

            let plotData = contour(points);

            colorScaleRef.current = d3
                .scaleLinear()
                .domain([
                    props.config.minValue ??
                        d3.min(plotData, (d) => d.value) ??
                        100,
                    props.config.maxValue ??
                        d3.max(plotData, (d) => d.value) ??
                        100,
                ])
                .range([
                    props.config.minColor ?? defaultDensity2dMinColor,
                    props.config.maxColor ?? defaultDensity2dMaxColor,
                ]);

            densityRef.current = svg
                .append("g")
                .selectAll<SVGPathElement, d3.ContourMultiPolygon>("path");

            timeAnimationTickRef.current = () => {
                // colorScaleRef should not be changed here
                let plotData: d3.ContourMultiPolygon[];
                if (timeValueRef.current == null) {
                    plotData = contour(points);
                } else {
                    plotData = contour(
                        points.filter(
                            (point) =>
                                point.time != null &&
                                point.time <= timeValueRef.current!
                        )
                    );
                }

                densityRef.current = (densityRef.current as d3.Selection<
                    SVGPathElement,
                    d3.ContourMultiPolygon,
                    SVGGElement,
                    unknown
                >)
                    .data(plotData)
                    .join("path")
                    .attr("d", d3.geoPath())
                    .attr("stroke-linejoin", "round");

                if (density2dType === "contour") {
                    densityRef.current
                        // fill should be "transparent" and not "none", because
                        // otherwise the tooltip would only show up on lines
                        .attr("fill", "transparent")
                        .attr("stroke", (d) => colorScaleRef.current!(d.value));
                } else {
                    // contour_shade
                    densityRef.current.attr("fill", (d) =>
                        colorScaleRef.current!(d.value)
                    );
                }

                densityRef
                    .current!.on(
                        "mouseenter",
                        (
                            event: MouseEvent,
                            contour: d3.ContourMultiPolygon
                        ) => {
                            setShowTooltipInfo({
                                x: event.x,
                                y: event.y,
                                contour: contour,
                            });
                        }
                    )
                    .on(
                        "mousemove",
                        (
                            event: MouseEvent,
                            contour: d3.ContourMultiPolygon
                        ) => {
                            setShowTooltipInfo((showTooltipInfo) => {
                                if (showTooltipInfo == null) return null;
                                return {
                                    x: event.x,
                                    y: event.y,
                                    contour: contour,
                                };
                            });
                        }
                    )
                    .on("mouseleave", (_event: MouseEvent) => {
                        setShowTooltipInfo(null);
                    });
            };
        }

        timeAnimationTickRef.current();

        if (props.trendlineInfo != null) {
            svg.append("g")
                .append("line")
                .attr("x1", xScale(minX))
                .attr("x2", xScale(maxX))
                .attr(
                    "y1",
                    yScale(
                        props.trendlineInfo.coef * minX +
                            props.trendlineInfo.intercept
                    )
                )
                .attr(
                    "y2",
                    yScale(
                        props.trendlineInfo.coef * maxX +
                            props.trendlineInfo.intercept
                    )
                )
                .attr("stroke", props.config.trendlineColor ?? colorList[0])
                .attr("stroke-width", 2);
        }
    }, [
        props.content.data,
        props.content.time,
        props.content.time?.value,
        props.content.time?.uniqueValues,
        props.config.ticksAndLabels,
        props.config.chartTheme,
        props.config.baseBackgroundColor,
        props.config.maxYRange,
        props.config.minYRange,
        props.config.maxXRange,
        props.config.minXRange,
        props.config.linesCount,
        props.config.showXAxisName,
        props.config.showYAxisName,
        props.config.count,
        props.config.trendline,
        props.config.random,
        props.config.trendlineColor,
        props.config.axesLinesColor,
        props.config.showGrid,
        props.config.ticksColor,
        props.config.ticksSize,
        props.config.binSize,
        props.config.minColor,
        props.config.maxColor,
        props.config.minValue,
        props.config.maxValue,
        props.config.density2dType,
        props.trendlineInfo,
        props.trendlineInfo?.coef,
        props.trendlineInfo?.intercept,
        props.preview,
        props.width,
        props.height,
        props.scale,
        longestYAxisValue,
        colorPickerIsEnabled,
    ]);

    let tooltipStyle = {
        ...TooltipStyles(
            props.config.tooltipColor,
            props.config.tooltipFontSize
        ),
    };
    return (
        <div style={{ height: "100%" }}>
            <div
                ref={parentRef}
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {props.content.time?.variableIndex != null &&
                    props.content.time?.uniqueValues.length !== 0 && (
                        <AnimationSlider
                            sliderStyle={{
                                cursor: "default",
                                width: 165,
                                pointerEvents: "auto",
                            }}
                            values={props.content.time.uniqueValues}
                            onChange={(index) => {
                                timeValueRef.current = props.content.time!.uniqueValues[
                                    index
                                ];
                                timeAnimationTickRef.current!();
                            }}
                        />
                    )}
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "space-between",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <div
                            className="flex-simple-column"
                            style={{ width: "48px", height: "100%" }}
                        >
                            <div
                                style={{
                                    height: axisItemStyles.yAxisHeight,
                                    position: "relative",
                                    top: -axisItemStyles.yAxisOffset - 1,
                                }}
                            >
                                {props.config.showYAxisName && (
                                    <EditableAxisItem
                                        onChange={(value) => {
                                            onChangeName(1, value);
                                        }}
                                        color={props.config.axesNamesColor}
                                        index={1}
                                        currentEditVariableIndex={
                                            currentEditVariableIndex
                                        }
                                        vertical
                                        name={props.content.data[1].name}
                                        onDrop={linkVariable}
                                        editable={props.editable}
                                    />
                                )}
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            <div
                                style={{
                                    width: 48,
                                    minHeight: 48,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {props.editable && (
                                    <div
                                        style={{
                                            width: 32,
                                            height: 32,
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            let data = Array.from(
                                                props.content.data
                                            );
                                            [data[0], data[1]] = [
                                                data[1],
                                                data[0],
                                            ];
                                            props.onChangeContent?.(
                                                {
                                                    ...props.content,
                                                    data: data,
                                                },
                                                false
                                            );
                                        }}
                                    >
                                        <SwapIcon />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // Resizing does not work properly
                            // without overflow: "hidden"
                            overflow: "hidden",
                        }}
                    >
                        <div
                            ref={parentSvgRef}
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                position: "relative",
                                // Resizing does not work properly
                                // without overflow: "hidden"
                                overflow: "hidden",
                            }}
                        >
                            {showTooltipInfo && (
                                <Portal rootNode={document.body}>
                                    <div
                                        style={{
                                            zIndex: 100000,
                                            position: "absolute",
                                            top: showTooltipInfo.y,
                                            left: showTooltipInfo.x,
                                            display: "flex",
                                            pointerEvents: "none",
                                            transform: "translateY(-50%)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: 10,
                                                    height: 10,
                                                    transform:
                                                        "translateX(50%) rotate(45deg)",
                                                    ...tooltipStyle.contentStyle,
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px",
                                                ...tooltipStyle.contentStyle,
                                                display: "flex",
                                                flexDirection: "column",
                                                zIndex: 100001,
                                            }}
                                        >
                                            {showTooltipInfo.bin != null && (
                                                <span
                                                    style={{
                                                        ...tooltipStyle.itemStyle,
                                                        color:
                                                            "rgb(85, 105, 125)",
                                                    }}
                                                    className="unselectable"
                                                >{`count: ${countByTime(
                                                    showTooltipInfo.bin,
                                                    timeValueRef.current
                                                )}`}</span>
                                            )}
                                            {showTooltipInfo.contour !=
                                                null && (
                                                <span
                                                    style={{
                                                        ...tooltipStyle.itemStyle,
                                                        color:
                                                            "rgb(85, 105, 125)",
                                                    }}
                                                    className="unselectable"
                                                >{`threshold: ${showTooltipInfo.contour.value}`}</span>
                                            )}
                                        </div>
                                    </div>
                                </Portal>
                            )}
                            <svg ref={svgRef} />
                        </div>
                        <div
                            className="my-row"
                            style={{
                                // height: "48px" would not be enough since
                                // its size would change when x axis name is
                                // hidden. We have to set min and max height.
                                minHeight: "48px",
                                maxHeight: "48px",
                                position: "relative",
                                width: axisItemStyles.xAxisWidth,
                                left: axisItemStyles.xAxisOffset,
                            }}
                        >
                            {props.config.showXAxisName && (
                                <EditableAxisItem
                                    onChange={(value) => {
                                        onChangeName(0, value);
                                    }}
                                    color={props.config.axesNamesColor}
                                    currentEditVariableIndex={
                                        currentEditVariableIndex
                                    }
                                    editable={props.editable}
                                    index={0}
                                    vertical={false}
                                    name={props.content.data[0].name}
                                    onDrop={linkVariable}
                                    inputStyle={{
                                        width: "100%",
                                    }}
                                />
                            )}
                            <div style={{ flexGrow: 1 }} />
                        </div>
                    </div>
                </div>
            </div>
            {showColorPicker && (
                <Popup
                    arrow={true}
                    contentStyle={{
                        ...chartColorPickerPopupStyles,
                        left: showColorPicker.x,
                        top: showColorPicker.y,
                    }}
                    open={true}
                    onClose={() => {
                        setShowColorPicker(null);
                    }}
                    nested={true}
                    closeOnDocumentClick
                >
                    <ColorPicker
                        enableAlpha={true}
                        width={"220px"}
                        color={props.config.maxColor ?? colorList[0]}
                        onChange={(color) => {
                            let newConfig = {
                                ...props.config,
                                maxColor: color,
                            };
                            props.onChangeConfig?.(newConfig);
                        }}
                    />
                </Popup>
            )}
        </div>
    );
}
