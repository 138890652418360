import React from "react";
import Select from "react-select";

import {
    MapElement,
    MapTooltipDisplayMode,
    MapTooltipDisplayModeOption,
    MapVariableOption,
} from "common/Canvas";
import { DataScopeOption } from "common/DataScopes";
import MapVariablesSelectorView from "modules/canvas_page/question_views/RevampedMapVariablesSelectorView";

import styles from "./TooltipConfig.module.css";

const options: Array<MapTooltipDisplayModeOption> = [
    { label: "click", value: MapTooltipDisplayMode.onClick },
    { label: "hover", value: MapTooltipDisplayMode.onHover },
    { label: "always", value: MapTooltipDisplayMode.always },
];

interface Props {
    dataScope: DataScopeOption | null;
    variables: (MapVariableOption | null)[];
    displayMode?: MapTooltipDisplayModeOption;
    onChange: (value: Partial<MapElement>) => void;
    currentModuleId?: number;
}

function TooltipConfig({
    dataScope,
    variables,
    currentModuleId,
    displayMode,
    onChange,
}: Props) {
    return (
        <div className={styles.root}>
            <div className={styles.displayMode}>
                <span className={styles.title}>Show value on</span>
                <Select
                    options={options}
                    value={displayMode}
                    isSearchable={false}
                    onChange={(newValue) => {
                        onChange({
                            displayMode: newValue as MapTooltipDisplayModeOption,
                        });
                    }}
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            minWidth: 60,
                        }),
                        control: (provided) => ({
                            ...provided,
                            minHeight: 25,
                        }),
                        indicatorsContainer: (provided) => ({
                            display: "none",
                        }),
                    }}
                />
            </div>
            <MapVariablesSelectorView
                dataScope={dataScope!}
                variables={variables}
                onChange={(newOptions) => {
                    onChange({
                        tooltipVariables: newOptions,
                    });
                }}
                currentModuleId={currentModuleId}
            />
        </div>
    );
}

export default TooltipConfig;
