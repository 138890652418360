import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
    StripeCardElementChangeEvent,
    StripeCardElementOptions,
    StripeError,
    PaymentMethod,
} from "@stripe/stripe-js";
import axios from "common/ServerConnection";

const CARD_OPTIONS: StripeCardElementOptions = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "var(--popup-primary-text-color)",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#fce883",
            },
            "::placeholder": {
                color: "#03b3e4",
            },
        },
        invalid: {
            iconColor: "#cc94bb", //"#de003b",
            color: "#cc94bb",  //"#de003b",
        },
    },
};

const CardField = ({
    onChange,
}: {
    onChange: (e: StripeCardElementChangeEvent) => any;
}) => (
    <div className="FormRow">
        <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
);

export const Field = ({
    label,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange,
}: {
    label: string;
    id: string;
    type: string;
    placeholder: string;
    required: boolean;
    autoComplete: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
    <div className="FormRow">
        <label htmlFor={id} className="FormRowLabel" style={{ width: "11em" }}>
            {label}
        </label>
        <input
            className="like-select FormRowInput"
            id={id}
            placeholder={placeholder}
            required={required}
            autoComplete={autoComplete}
            value={value}
            onChange={(e) => {
                // type="number" only affects this, and not the actual
                // type of the input, since there are styling issues with it.
                if (
                    type === "number" &&
                    !/^[0-9]*\.?[0-9]*$/.test(e.target.value)
                ) {
                    e.preventDefault();
                    return;
                }
                onChange(e);
            }}
            style={{ flex: 1 }}
        />
    </div>
);

export const SubmitButton = ({
    processing,
    error,
    children,
    disabled,
    onClick,
}: {
    processing: boolean;
    error: StripeError | string | null;
    children: React.ReactNode;
    disabled: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
    <button
        className={`btn btn-sm btn-primary my-primary SubmitButton ${
            error ? "SubmitButton--error" : ""
        }`}
        type="submit"
        disabled={processing || disabled}
        onClick={onClick}
    >
        {processing ? "Processing..." : children}
    </button>
);

const ErrorMessage = ({ children }: { children: React.ReactNode }) => (
    <div className="ErrorMessage" role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);

export const ResetButton = ({ onClick }: { onClick: () => void }) => (
    <button type="button" className="ResetButton" onClick={onClick}>
        <svg width="32px" height="32px" viewBox="0 0 32 32">
            <path
                fill="#FFF"
                d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
            />
        </svg>
    </button>
);

interface CheckoutProps {
    linkId: string | number;
    subscribeEndpoint: string;
    ComponentIfSubscribed?: React.ReactNode;
    setParentSubscribed: (isSubscribed: boolean) => void;
    price: number;
    onPayment?: () => void;
}

const MyStripeForm = ({
    linkId,
    subscribeEndpoint,
    ComponentIfSubscribed,
    setParentSubscribed,
    price,
    onPayment,
}: CheckoutProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState<StripeError | string | null | any>(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
        null
    );
    const [billingDetails, setBillingDetails] = useState({
        email: "",
        phone: "",
        name: "",
    });
    const [subscribed, setSubscribed] = useState(false);

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (elements === null) {
            return;
        }

        if (stripe === null) {
            return;
        }

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const card = elements.getElement("card");

        if (error && card !== null) {
            card.focus();
            return;
        }

        if (cardComplete) {
            setProcessing(true);
        }

        const cardElement = elements.getElement(CardElement);

        if (cardElement === null) {
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
        });

        setError(null);
        setProcessing(false);

        if (payload.error) {
            setError(payload.error);
        } else {
            setProcessing(true);
            try {
                let result = await axios.post<{
                    success: boolean;
                    error_msg: boolean;
                    requires_action?: boolean;
                    payment_intent_client_secret?: string;
                }>(subscribeEndpoint, {
                    id: linkId,
                    payment_method_id: payload.paymentMethod.id,
                });
                if (!result.data.success) {
                    setError(result.data.error_msg);
                    console.log(result.data.error_msg);
                } else if (result.data.payment_intent_client_secret != null) {
                    let cardActionResult = await stripe.handleCardAction(
                        result.data.payment_intent_client_secret
                    );
                    if (cardActionResult.error != null) {
                        setError(cardActionResult.error);
                        console.log(cardActionResult.error);
                    } else {
                        let result2 = await axios.post<{
                            success: boolean;
                            error_msg: boolean;
                            requires_action?: boolean;
                            payment_intent_client_secret?: string;
                        }>(subscribeEndpoint, {
                            id: linkId,
                            payment_intent_id:
                                cardActionResult.paymentIntent.id,
                        });
                        if (!result2.data.success) {
                            setError(result.data.error_msg);
                            console.log(result.data.error_msg);
                        } else {
                            setPaymentMethod(payload.paymentMethod);
                            setSubscribed(true);
                            setParentSubscribed(true);
                            if (onPayment != null) {
                                onPayment();
                            }
                        }
                    }
                } else {
                    setPaymentMethod(payload.paymentMethod);
                    setSubscribed(true);
                    setParentSubscribed(true);
                    if (onPayment != null) {
                        onPayment();
                    }
                }
            } catch (error) {
                setError(error.toString());
                console.log(error);
            } finally {
                setProcessing(false);
            }
        }
    };

    const reset = () => {
        setError(null);
        setProcessing(false);
        setPaymentMethod(null);
        setBillingDetails({
            email: "",
            phone: "",
            name: "",
        });
    };

    return paymentMethod && paymentMethod != null ? (
        <div className="Result">
            {subscribed ? (
                <div>{ComponentIfSubscribed}</div>
            ) : (
                <div>
                    <div className="ResultTitle" role="alert">
                        Payment successful
                    </div>
                    <div className="ResultMessage">
                        Thank you for your purchase. You have gained access to
                        our premium features!
                        <br />
                        PaymentMethod ID: {paymentMethod.id}
                    </div>
                    <ResetButton onClick={reset} />
                </div>
            )}
        </div>
    ) : (
        <form className="Form" onSubmit={handleSubmit}>
            <fieldset
                className="FormGroup"
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Field
                    label="Subscriber Name"
                    id="name"
                    type="text"
                    placeholder="Sam Gamgee"
                    required
                    autoComplete="name"
                    value={billingDetails.name}
                    onChange={(e) => {
                        setBillingDetails({
                            ...billingDetails,
                            name: e.target.value,
                        });
                    }}
                />

                <Field
                    label="Email (for support)"
                    id="email"
                    type="email"
                    placeholder="sam.gee@eisengard.ai"
                    required
                    autoComplete="email"
                    value={billingDetails.email}
                    onChange={(e) => {
                        setBillingDetails({
                            ...billingDetails,
                            email: e.target.value,
                        });
                    }}
                />
                <Field
                    label="Phone"
                    id="phone"
                    type="tel"
                    placeholder="(123)456-7890"
                    required
                    autoComplete="tel"
                    value={billingDetails.phone}
                    onChange={(e) => {
                        setBillingDetails({
                            ...billingDetails,
                            phone: e.target.value,
                        });
                    }}
                />
            </fieldset>
            <fieldset
                className="FormGroup"
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <CardField
                    onChange={(e) => {
                        setError(e.error);
                        setCardComplete(e.complete);
                    }}
                />
            </fieldset>
            {error != null && (
                <ErrorMessage>
                    {typeof error === "string" ? error : error.message}
                </ErrorMessage>
            )}
            <SubmitButton
                processing={processing}
                error={error}
                disabled={!stripe}
            >
                {`Pay $${price}`}
            </SubmitButton>
        </form>
    );
};

export default MyStripeForm;
