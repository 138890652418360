import React, { Component } from "react";
import Select, { createFilter } from "react-select";
import { Button, AlertProps } from "react-bootstrap";
import { observer } from "mobx-react";
import "common/styles/App.css";
import {
    getCustomSelectStyleLight,
    leftAlignAndScrollBarCSS,
} from "common/SelectStyles";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Variables from "common/Variables";
import {
    NumberFormat,
    ColumnFormat,
    SpreadSheetColumnType,
    NumberFormatType,
} from "common/Canvas";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import Dropzone from "react-dropzone";
import {
    Panel,
    Type,
    InputMode,
    InputBaseState,
    defaultInputBaseState,
    realTimeLiveStreamSlugs,
    liveStreamUpdateModeOptions,
    liveStreamIntervalOptions,
    liveStreamIntervalOptionsRealTime,
    liveStreamWeekDayOptions,
    LiveStreamWeekDayOption,
    LiveStreamUpdateModeOption,
    LiveStreamIntervalOption,
    parseCsvFile,
    LoadFileStatus,
    checkLiveStreamCsv,
    checkLiveStreamEndpoint,
    checkLiveStreamMySql,
    checkLiveStreamOracleDB,
    timeToUTC,
    uploadOptions,
    UploadType,
    replaceSchema,
    uploadData,
    UploadFormatError,
    cancelUploadData,
    SchemaOptions,
    defaultSchemaOptions,
    applyColumnFormatsToSchemaOptions,
    uploadFileToBackend,
    maxUploadFileSize,
    maxChunkSize,
    getLiveStreamSheets,
    Sheet,
    liveStreamUpdateModeOptionsWithoutAppend,
} from "common/InputData";
import TimezoneSelect from "react-timezone-select";
import StringOption from "common/StringOption";
import encodings from "common/encodings";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import { Element } from "react-scroll";
import { Permission } from "common/Permissions";
import Switch from "react-switch";
import FormatDropdown from "./FormatDropdown";
// import FormatDropdown from "modules/canvas_page/FormatDropdown";
import Alert from "common/Alert";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import LinearProgress from "@mui/material/LinearProgress";
import { lightThemeStyle } from "common/LightThemeStyle";
// For API's
import { APIUpdateEndpoint } from "modules/canvas_page/api_page/APIInterface";
import CanvasIconsContainer from "modules/canvas_page/CanvasIconsContainer";
import MessagePopup from "../MessagePopup";
import remoteModuleId from "common/remoteModuleId";
import { SocketIOInstance } from "common/ServerConnection";
import axios from "common/ServerConnection";
import { sendErrorReport } from "common/ErrorReportingApi";
import { spreadSheetColumnOptions } from "common/Canvas";
import styles from "./ImportDataPage.module.css";
import cx from "classnames";
import { ReactComponent as ImportIcon } from "icons/data_hub/import.svg";
import MarketplaceComponent from "modules/marketplace_page/MarketplaceComponent";
import ReactTooltip from "react-tooltip";
import WarningModalPopup from "common/WarningModal";

interface IEntriesPerPage {
    value: number;
    label: string;
}

interface Message {
    text: string;
    show: boolean;
    setShow: (show: boolean) => void;
    variant?: AlertProps["variant"];
}

interface Option {
    label: string;
    value: InputMode;
    icon?: string;
}

interface Props {
    dataHubMode?: boolean;
    canvasTreeStore?: CanvasTreeStore;
    currentModuleId?: number;
    dockSize?: number;
    spreadsheetId?: string;
    onReturnToCanvas?: (options?: Record<string, any>) => void;
    onSuccess?: (option: any, id: number | string) => void;
    setOperationInProgress: (state: boolean) => void;
}

interface State extends InputBaseState {
    schemaOptions: SchemaOptions;
    columnFormats: ColumnFormat[];
    columnsEnabled: boolean[];
    inputLabel: string;
    advancedOptionsExpanded: boolean;
    uploadMessageError: string | null;
    uploadStatus: UploadStatus;
    uploadFormatError: UploadFormatError | null;
    uploadFormatErrorForce: {
        forceDateToNans: boolean;
        forceNumbersToNull: boolean;
    } | null;
    endpoints: APIUpdateEndpoint[];
    selected: APIUpdateEndpoint[];
    alerts: Message[];
    sheets: Sheet[];
    errorAPI?: string;
    selectedEndpointOption?: APIUpdateEndpointOption;
    dataScopeText?: string;
    showDataMarketplace: boolean;
    streamStatus: number | null;
    streamMessage: string | null;
    saveDataset: boolean;
    replaceDataset: boolean;
    overwriteDatasetModal: boolean;
    newDatasetName: string;
    selectSqlTable: boolean;
    sqlTable: StringOption[] | [];
    sqlTableType: "mysql" | "oracledb";
    rowCount: number;
    entriesPerPage: IEntriesPerPage;
}

const messageForUploadFormatError: Readonly<
    { [key in UploadFormatError]: string }
> = {
    date:
        "You have rows that have date values that are not the right format.  Do you want to change these values to NA?",
    number:
        "You have rows that have non-numeric values in numeric columns.  Do you want to change these values to NA?",
};

const options: ReadonlyArray<Option> = [
    { label: "CSV", value: InputMode.LocalCsv, icon: "" },
    { label: "Dropbox", value: InputMode.LiveStreamCsv, icon: "dropbox.svg" },
    {
        label: "Google Drive",
        value: InputMode.LiveStreamCsv,
        icon: "Google_Drive.svg",
    },
    {
        label: "Google Sheets",
        value: InputMode.LiveStreamCsv,
        icon: "Google_Sheets.svg",
    },
    { label: "MySQL", value: InputMode.LiveStreamMySQL, icon: "" },
    { label: "OracleDB", value: InputMode.LiveStreamOracleDB, icon: "" },
    { label: "API", value: InputMode.LiveStreamAPI, icon: "" }, // For COVID APi's for a new section
];

enum UploadStatus {
    NotUploaded = 1,
    ReplacingSchema = 2,
    UploadingFile = 3,
    UploadingData = 4,
    Success = 5,
    Error = 6,
}

interface APIUpdateEndpointOption extends APIUpdateEndpoint {
    label: string;
    value: string;
}

function defaultState(): State {
    return {
        ...defaultInputBaseState(),
        inputMode: InputMode.LocalCsv,
        inputLabel: options[0].label,
        schemaOptions: defaultSchemaOptions(),
        advancedOptionsExpanded: false,
        columnsEnabled: [],
        columnFormats: [],
        uploadMessageError: null,
        uploadStatus: UploadStatus.NotUploaded,
        endpoints: [],
        selected: [],
        alerts: [],
        uploadFormatError: null,
        uploadFormatErrorForce: null,
        sheets: [],
        showDataMarketplace: false,
        streamStatus: null,
        streamMessage: null,
        saveDataset: false,
        replaceDataset: false,
        overwriteDatasetModal: false,
        newDatasetName: "",
        selectSqlTable: false,
        sqlTable: [],
        sqlTableType: "mysql",
        rowCount: 0,
        entriesPerPage: { value: 10, label: "10"},
    };
}

@observer
class MainComponent extends Component<Props, State> {
    private uploadProcessInfo:
        | {
              roomId: string;
              name2ColumnFormat: { [name: string]: ColumnFormat };
              dataTableIdx: string | number;
          }
        | undefined = undefined;

    private abortController: AbortController | undefined = undefined;

    constructor(props: Props) {
        super(props);
        this.state = defaultState();

        this.createMessage = this.createMessage.bind(this);
        this.renderContents = this.renderContents.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);

        this.uploadProcess = this.uploadProcess.bind(this);
        this.cancelUploadProcess = this.cancelUploadProcess.bind(this);
        this.uploadData = this.uploadData.bind(this);
        this.subscribeHandler = this.subscribeHandler.bind(this);
        this.loadMarketplaceToSpreadsheet = this.loadMarketplaceToSpreadsheet.bind(
            this
        );
    }
    // For API so I can call list_apis
    componentDidMount() {
        // Get the list of COVID APIs that can be updated
        axios
        .get<{
            success: boolean;
            error_msg: string;
            apis: APIUpdateEndpoint[];
        }>("/api/list_apis")
        .then((response) => {
            if (!response.data.success) {
                this.setState({
                    errorAPI: response.data.error_msg,
                });
            } else {
                this.setState({
                    endpoints: response.data.apis,
                });
            }
        });
        this.subscribeToDatasetUpdates();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
            if(prevState !== this.state) {
                let workInProgress =
                    this.state.liveStreamMySQLHost !== '' ||
                    this.state.liveStreamMySQLDatabase !== ''  ||
                    this.state.liveStreamMySQLLogin !== ''  ||
                    this.state.liveStreamMySQLPassword !== ''  ||
                    this.state.liveStreamOracleDBHost !== '' ||
                    this.state.liveStreamOracleDBLogin!== ''  ||
                    this.state.liveStreamOracleDBServiceName !== ''  ||
                    this.state.liveStreamOracleDBSchema !== ''  ||
                    this.state.liveStreamOracleDBPassword !== '' ||
                    this.state.liveStreamCsvLink !== '' ||
                    this.state.selectedEndpointOption !== undefined;

            this.props.setOperationInProgress(workInProgress);
        }
    }
    componentWillUnmount() {
        SocketIOInstance?.off(
            "dataset_uploading_changes",
            this.subscribeHandler
        );
        if (this.uploadProcessInfo != null) {
            this.leaveChangesRoom(this.uploadProcessInfo.roomId);
        }
    }

    // For API messaging: L&V, how do I modify this so I can use the Alerts you've already created?
    setMessageShow(i: number, show: boolean) {
        let newAlerts = [...this.state.alerts];
        let alert = { ...newAlerts[i] };
        alert.show = show;
        newAlerts[i] = alert;
        this.setState({ alerts: newAlerts });
    }

    createMessage(
        text: string,
        variant?: AlertProps["variant"],
        show: boolean = true
    ) {
        const id = this.state.alerts.length;
        let newAlerts: Message[] = [...this.state.alerts];
        const newAlert: Message = {
            text: text,
            variant: variant,
            show: show,
            setShow: this.setMessageShow.bind(this, id),
        };

        newAlerts.push(newAlert);

        this.setState({
            alerts: newAlerts,
        });
    }
    //

    private handleFileUpload(file: File): void {
        this.setState(
            {
                loadFileStatus: LoadFileStatus.Loading,
                liveStreamConfigValid: false,
            },
            () => {
                this.prepareCsvFile(file);
            }
        );
    }
    private handleLiveStreamCsvLink(loadSheets: boolean): void {
        let inputData = Object.assign({}, this.state);

        let { weekday, timeString } = timeToUTC(
            inputData.liveStreamWeekDay.value,
            inputData.liveStreamTime,
            inputData.liveStreamTimezone.value
        );
        inputData = {
            ...inputData,
            loadFileStatus: LoadFileStatus.Loading,
            liveStreamConfigValid: false,
            liveStreamSelectedUpdateMode: inputData.liveStreamUpdateMode,
            liveStreamSelectedInterval: inputData.liveStreamInterval,
            liveStreamSelectedWeekDay:
                liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
                liveStreamWeekDayOptions[0],
            liveStreamSelectedTime: timeString,
            liveStreamSelectedCsvLink: inputData.liveStreamCsvLink,
        };
        this.setState(inputData, () => {
            checkLiveStreamCsv(
                inputData,
                (preview) => {
                    this.setState({ error: null });
                    this.handleFileUpload(preview);
                    if (loadSheets)
                        getLiveStreamSheets(inputData.liveStreamCsvLink)
                            .then((sheets) => {
                                this.setState((state) => {
                                    if (
                                        state.liveStreamCsvLink ===
                                        inputData.liveStreamCsvLink
                                    )
                                        return { sheets: sheets };
                                    return null;
                                });
                            })
                            .catch((error) => {
                                console.log(String(error));
                            });
                },
                (error) => {
                    this.setState({
                        error: error,
                        loadFileStatus: LoadFileStatus.NotUploaded,
                    });
                }
            );
        });
    }

    private handleEndpoint(): void {
        let inputData = Object.assign({}, this.state);

        let { weekday, timeString } = timeToUTC(
            inputData.liveStreamWeekDay.value,
            inputData.liveStreamTime,
            inputData.liveStreamTimezone.value
        );
        inputData = {
            ...inputData,
            loadFileStatus: LoadFileStatus.Loading,
            liveStreamConfigValid: false,
            liveStreamSelectedUpdateMode: inputData.liveStreamUpdateMode,
            liveStreamSelectedInterval: inputData.liveStreamInterval,
            liveStreamSelectedWeekDay:
                liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
                liveStreamWeekDayOptions[0],
            liveStreamSelectedTime: timeString,
            liveStreamSelectedEndpointSlug: inputData.liveStreamEndpointSlug,
        };

        let isRealTimeSlug =
            realTimeLiveStreamSlugs.indexOf(
                inputData.liveStreamEndpointSlug
            ) !== -1;
        if (isRealTimeSlug) {
            inputData = {
                ...inputData,
                liveStreamUpdateMode: liveStreamUpdateModeOptions[2],
                liveStreamInterval: liveStreamIntervalOptionsRealTime[0],
            };
        }

        this.setState(inputData, () => {
            checkLiveStreamEndpoint(
                inputData,
                (preview) => {
                    this.setState({ error: null });
                    this.handleFileUpload(preview);
                },
                (error) => {
                    this.setState({
                        error: error,
                        loadFileStatus: LoadFileStatus.NotUploaded,
                    });
                }
            );
        });

        if (this.state.error == null && isRealTimeSlug) {
            this.setState({ liveStreamConfigValid: true });
        }
    }

    private handleLiveStreamMySQLLink(): void {
        let inputData = Object.assign({}, this.state);

        let { weekday, timeString } = timeToUTC(
            inputData.liveStreamWeekDay.value,
            inputData.liveStreamTime,
            inputData.liveStreamTimezone.value
        );
        inputData = {
            ...inputData,
            loadFileStatus: LoadFileStatus.Loading,
            liveStreamConfigValid: false,
            liveStreamSelectedUpdateMode: inputData.liveStreamUpdateMode,
            liveStreamSelectedInterval: inputData.liveStreamInterval,
            liveStreamSelectedWeekDay:
                liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
                liveStreamWeekDayOptions[0],
            liveStreamSelectedTime: timeString,

            liveStreamSelectedMySQLPort: inputData.liveStreamMySQLPort,
            liveStreamSelectedMySQLTable: inputData.liveStreamMySQLTable,
            liveStreamSelectedMySQLDatabase: inputData.liveStreamMySQLDatabase,
            liveStreamSelectedMySQLLogin: inputData.liveStreamMySQLLogin,
            liveStreamSelectedMySQLPassword: inputData.liveStreamMySQLPassword,
            liveStreamSelectedMySQLHost: inputData.liveStreamMySQLHost,
            // liveStreamSelectedMySQLHost: "eisengard-mysql",
            // liveStreamSelectedMySQLDatabase: "turtle_pt",
            // liveStreamSelectedMySQLLogin: "turtle_pt_user",
            // liveStreamSelectedMySQLPassword: "eisengard",
        };
        this.setState(inputData, () => {
            checkLiveStreamMySql(
                inputData,
                (preview) => {
                    this.setState({ error: null });
                    this.handleFileUpload(preview);
                },
                (error) => {
                    this.setState({
                        error: error,
                        loadFileStatus: LoadFileStatus.NotUploaded,
                    });
                }
            );
        });
    }

    private handleLiveStreamOracleDBLink(): void {
        let inputData = Object.assign({}, this.state);

        let { weekday, timeString } = timeToUTC(
            inputData.liveStreamWeekDay.value,
            inputData.liveStreamTime,
            inputData.liveStreamTimezone.value
        );
        inputData = {
            ...inputData,
            loadFileStatus: LoadFileStatus.Loading,
            liveStreamConfigValid: false,
            liveStreamSelectedUpdateMode: inputData.liveStreamUpdateMode,
            liveStreamSelectedInterval: inputData.liveStreamInterval,
            liveStreamSelectedWeekDay:
                liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
                liveStreamWeekDayOptions[0],
            liveStreamSelectedTime: timeString,

            liveStreamSelectedOracleDBConnectionMode:
                inputData.liveStreamSelectedOracleDBConnectionMode,
            liveStreamSelectedOracleDBDsn:
                inputData.liveStreamSelectedOracleDBDsn,

            liveStreamSelectedOracleDBPort: inputData.liveStreamOracleDBPort,
            liveStreamSelectedOracleDBTable: inputData.liveStreamOracleDBTable,
            liveStreamSelectedOracleDBServiceName:
                inputData.liveStreamOracleDBServiceName,
            liveStreamSelectedOracleDBSchema:
                inputData.liveStreamOracleDBSchema,
            liveStreamSelectedOracleDBLogin: inputData.liveStreamOracleDBLogin,
            liveStreamSelectedOracleDBPassword:
                inputData.liveStreamOracleDBPassword,
            liveStreamSelectedOracleDBHost: inputData.liveStreamOracleDBHost,
        };
        this.setState(inputData, () => {
            checkLiveStreamOracleDB(
                inputData,
                (preview) => {
                    this.setState({ error: null });
                    this.handleFileUpload(preview);
                },
                (error) => {
                    this.setState({
                        error: error,
                        loadFileStatus: LoadFileStatus.NotUploaded,
                    });
                }
            );
        });
    }

    private prepareCsvFile(file: File | string, encoding?: StringOption): void {
        if (file == null) return;
        parseCsvFile(
            file,
            (csvHeader, csvData, _csvTypes, encoding, rowCount) => {
                if (csvHeader.length !== _csvTypes.length) {
                    csvHeader = [];
                    _csvTypes = [];
                    csvData = [];
                }
                let columnCount = csvHeader.length;
                let schemaOptions: SchemaOptions = {
                    names: csvHeader,
                    types: _csvTypes as Type[],
                    units: new Array(columnCount).fill(""),
                    panels: new Array(columnCount).fill(Panel.Regular),
                    levels: new Array(columnCount).fill(null),
                    formats: new Array(columnCount).fill(null),
                    namesToIndices: {},
                };
                let columnsEnabled = new Array(columnCount).fill(true);
                let columnFormats: ColumnFormat[] = [];
                for (let type of schemaOptions.types) {
                    if (type === Type.Float || type === Type.Int) {
                        let format: NumberFormat = {
                            type: SpreadSheetColumnType.Number,
                            numberType: NumberFormatType.Number,
                            useCommaSeparator: false,
                            useAbbreviation: false,
                            decimalPoints: type === Type.Float ? 2 : 0,
                        };
                        columnFormats.push(format);
                    } else {
                        let format: ColumnFormat = {
                            type: SpreadSheetColumnType.Text,
                        };
                        columnFormats.push(format);
                    }
                }
                csvHeader.forEach((name, index) => {
                    schemaOptions.namesToIndices[name] = index;
                });
                this.setState({
                    selectedFile: file,
                    csvData: csvData,
                    rowCount: (rowCount -1),
                    csvHeader: csvHeader,
                    encoding: encoding,
                    loadFileStatus: LoadFileStatus.Success,
                    liveStreamConfigValid: true,
                    schemaOptions: schemaOptions,
                    columnsEnabled: columnsEnabled,
                    columnFormats: columnFormats,
                    error: null,
                });
            },
            async (errors) => {
                this.setState({
                    selectedFile: null,
                    loadFileStatus: LoadFileStatus.NotUploaded,
                    error:
                        errors[0]?.code === "Custom"
                            ? errors[0].message
                            : "Failed to parse data",
                });
                await sendErrorReport(
                    JSON.stringify({
                        name: "Parse csv error",
                        errors: errors,
                    })
                );
            },
            100,
            encoding
        );
    }
    private joinChangesRoom(roomId: string) {
        SocketIOInstance?.emit("dataset_uploading_changes_join", {
            room: roomId,
        });
    }

    private leaveChangesRoom(roomId: string) {
        SocketIOInstance?.emit("dataset_uploading_changes_leave", {
            room: roomId,
        });
    }

    private loadMarketplaceToSpreadsheet(dataTableIdx: string | number) {
        if (this.props.canvasTreeStore != null) {
            let spreadsheetId = this.props.spreadsheetId;
            if (this.props.spreadsheetId == null) {
                spreadsheetId = this.props.canvasTreeStore.addSpreadSheetGridAction(
                    3,
                    3,
                    false,
                    true,
                    true,
                    {}
                );
            }
            if (spreadsheetId != null) {
                this.props.canvasTreeStore
                    .readDataIntoSpreadSheet(
                        spreadsheetId!,
                        dataTableIdx,
                        null,
                        10,
                        true, // Set to true as we pull data when click on stream button
                        false,
                        [],
                        undefined,
                        false
                    )
                    .then(() => {
                        this.props.onReturnToCanvas?.();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.props.onReturnToCanvas?.();
            }
        } else {
            this.props.onReturnToCanvas?.();
        }
    }
    private onSuccess() {
        this.setState(
            {
                uploadStatus: UploadStatus.Success,
            },
            () => {
                let dataTableIdx = this.uploadProcessInfo!.dataTableIdx;
                let name2ColumnFormat = this.uploadProcessInfo!
                    .name2ColumnFormat;
                this.leaveChangesRoom(this.uploadProcessInfo!.roomId);
                this.uploadProcessInfo = undefined;
                if (
                    this.props.spreadsheetId != null &&
                    this.props.canvasTreeStore != null
                ) {
                    this.props.canvasTreeStore
                        .readDataIntoSpreadSheet(
                            this.props.spreadsheetId,
                            dataTableIdx,
                            null,
                            10,
                            true, // Set to true as we pull data when click on stream button
                            false,
                            [],
                            undefined,
                            false,
                            name2ColumnFormat
                        )
                        .then(() => {
                            this.props.onReturnToCanvas?.();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.props.onReturnToCanvas?.();
                }
                if (this.props.onSuccess) {
                    setTimeout(() => {
                        this.props.onSuccess?.(1, dataTableIdx);
                    }, 1000);
                }
            }
        );
    }

    subscribeHandler = (response: {
        data: {
            success: boolean;
            error_msg: string;
            data: {
                format_error?: UploadFormatError;
            };
        };
    }) => {
        if (response.data.success) {
            this.onSuccess();
        } else {
            this.onError(
                response.data.error_msg,
                response.data.data.format_error
            );
        }
    };

    subscribeToDatasetUpdates() {
        SocketIOInstance?.on(
            "dataset_uploading_changes",
            this.subscribeHandler
        );
    }

    private onError(error: string, formatError?: UploadFormatError) {
        this.leaveChangesRoom(this.uploadProcessInfo!.roomId);
        this.uploadProcessInfo = undefined;
        this.setState({
            uploadFormatError: formatError ?? null,
            uploadMessageError: error,
            uploadStatus: UploadStatus.Error,
        });
    }

    private async uploadFile(
        dataTableIdx: string | number,
        file: File
    ): Promise<string | undefined> {
        let chunkSize = maxChunkSize;
        let offset = 0;
        let uploadSize = 0;
        let fileId: string | undefined = undefined;
        while (offset <= file.size) {
            let blob = file.slice(offset, offset + chunkSize);
            offset += chunkSize;
            let result: {
                fileId: string;
                uploadSize: number;
            } = await uploadFileToBackend(dataTableIdx, blob, fileId);
            fileId = result.fileId;
            uploadSize = result.uploadSize;
        }
        if (uploadSize !== file.size) {
            throw new Error("Upload file error");
        }
        return fileId;
    }
    private uploadDataInner(
        dataTableIdx: string | number,
        forceDateToNans?: boolean,
        forceNumbersToNull?: boolean
    ) {
        let name2ColumnFormat: { [name: string]: ColumnFormat } = {};
        for (let index in this.state.schemaOptions.names) {
            name2ColumnFormat[
                this.state.schemaOptions.names[index]
            ] = this.state.columnFormats[index];
        }

        let { weekday, timeString } = timeToUTC(
            this.state.liveStreamWeekDay.value,
            this.state.liveStreamTime,
            this.state.liveStreamTimezone.value
        );
        let inputData = {
            ...this.state,
            loadFileStatus: LoadFileStatus.Loading,
            liveStreamConfigValid: false,
            liveStreamSelectedUpdateMode: this.state.liveStreamUpdateMode,
            liveStreamSelectedInterval: this.state.liveStreamInterval,
            liveStreamSelectedWeekDay:
                liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
                liveStreamWeekDayOptions[0],
            liveStreamSelectedTime: timeString,
            liveStreamSelectedCsvLink: this.state.liveStreamCsvLink,
        };
        this.setState({
            uploadStatus: UploadStatus.UploadingData,
            uploadFormatErrorForce: {
                forceDateToNans: forceDateToNans ?? false,
                forceNumbersToNull: forceNumbersToNull ?? false,
            },
        });
        uploadData(
            dataTableIdx,
            inputData,
            (roomId) => {
                this.uploadProcessInfo = {
                    roomId: roomId,
                    dataTableIdx: dataTableIdx,
                    name2ColumnFormat: name2ColumnFormat,
                };
                this.joinChangesRoom(roomId);
            },
            (error, formatError) => {
                this.setState({
                    uploadFormatError: formatError ?? null,
                    uploadFormatErrorForce: {
                        forceDateToNans: forceDateToNans ?? false,
                        forceNumbersToNull: forceNumbersToNull ?? false,
                    },
                    uploadMessageError: error,
                    uploadStatus: UploadStatus.Error,
                });
            },
            this.state.columnsEnabled,
            forceDateToNans,
            forceNumbersToNull
        );
    }

    private async uploadData(
        forceDateToNans?: boolean,
        forceNumbersToNull?: boolean
    ) {
        let dataTableIdx = this.state.dataScope!.value;

        if (
            this.state.inputMode === InputMode.LocalCsv &&
            //            this.state.uploadFileId == null &&
            this.state.selectedFile != null &&
            (this.state.selectedFile as File).size > maxUploadFileSize
        ) {
            this.setState({
                uploadStatus: UploadStatus.UploadingFile,
            });
            try {
                let uploadFileId = await this.uploadFile(
                    dataTableIdx,
                    this.state.selectedFile as File
                );
                this.setState({ uploadFileId: uploadFileId }, () => {
                    this.uploadDataInner(
                        dataTableIdx,
                        forceDateToNans,
                        forceNumbersToNull
                    );
                });
            } catch (error) {
                if (error.name !== "AbortError") {
                    this.setState({
                        uploadFormatError: null,
                        uploadMessageError: String(error),
                        uploadStatus: UploadStatus.Error,
                    });
                }
                return;
            }
        } else {
            this.uploadDataInner(
                dataTableIdx,
                forceDateToNans,
                forceNumbersToNull
            );
        }
    }

    private checkLiveStream(
        inputState: InputBaseState,
        onSuccess: (preview: any) => void,
        onError: (error: string) => void
    ): void {
        if (
            inputState.inputMode === InputMode.LocalCsv ||
            inputState.inputMode === InputMode.None
        ) {
            onSuccess(undefined);
            return;
        } else {
            let checkLiveStream: (
                inputState: InputBaseState,
                onSuccess: (preview: any) => void,
                onError: (error: string) => void
            ) => void;

            if (inputState.inputMode === InputMode.LiveStreamCsv) {
                checkLiveStream = checkLiveStreamCsv;
            } else if (inputState.inputMode === InputMode.LiveStreamMySQL) {
                checkLiveStream = checkLiveStreamMySql;
            } else if (inputState.inputMode === InputMode.LiveStreamOracleDB) {
                checkLiveStream = checkLiveStreamOracleDB;
            } else {
                checkLiveStream = checkLiveStreamEndpoint;
            }

            checkLiveStream(inputState, onSuccess, onError);
        }
    }

    private uploadProcess(): void {
        let schemaOptions = applyColumnFormatsToSchemaOptions(
            this.state.columnFormats,
            this.state.schemaOptions
        );

        if (this.state.uploadOption.value === UploadType.Replace) {
            let { weekday, timeString } = timeToUTC(
                this.state.liveStreamWeekDay.value,
                this.state.liveStreamTime,
                this.state.liveStreamTimezone.value
            );
            let inputData = {
                ...this.state,
                loadFileStatus: LoadFileStatus.Loading,
                liveStreamConfigValid: false,
                liveStreamSelectedUpdateMode: this.state.liveStreamUpdateMode,
                liveStreamSelectedInterval: this.state.liveStreamInterval,
                liveStreamSelectedWeekDay:
                    liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
                    liveStreamWeekDayOptions[0],
                liveStreamSelectedTime: timeString,
                liveStreamSelectedCsvLink: this.state.liveStreamCsvLink,
            };

            this.checkLiveStream(
                inputData,
                () => {
                    this.abortController = new AbortController();
                    this.setState({
                        uploadStatus: UploadStatus.ReplacingSchema,
                    });
                    replaceSchema(
                        schemaOptions.names,
                        schemaOptions.types,
                        schemaOptions.units,
                        schemaOptions.panels,
                        schemaOptions.formats,
                        schemaOptions.levels,
                        this.state.columnsEnabled,
                        this.state.dataScope!,
                        false,
                        undefined,
                        (dataTableIdx) => {
                            DataScopes.update();
                            Variables(
                                dataTableIdx,
                                this.props.currentModuleId ?? remoteModuleId
                            ).update(
                                this.props.currentModuleId ?? remoteModuleId
                            );
                            this.setState(
                                (state) => ({
                                    dataScope: {
                                        ...state.dataScope!,
                                        value: dataTableIdx,
                                    },
                                }),
                                () => {
                                    this.uploadData();
                                }
                            );
                        },
                        (error) => {
                            this.setState({
                                uploadMessageError: error,
                                uploadStatus: UploadStatus.Error,
                            });
                        },
                        this.abortController,
                        this.props.currentModuleId ?? remoteModuleId
                    );
                },
                (error) => {
                    this.setState({
                        uploadMessageError: error,
                        uploadStatus: UploadStatus.Error,
                    });
                }
            );
        }
    }

    private cancelUploadProcess(): void {
        const popupData = {
            streamStatus: PopupStatus.Error,
            streamMessage: "Upload cancelled",
        };
        let uploadProcessInfo = this.uploadProcessInfo;
        this.uploadProcessInfo = undefined;
        this.abortController?.abort();
        this.abortController = undefined;
        cancelUploadData();
        if (uploadProcessInfo != null) {
            this.leaveChangesRoom(uploadProcessInfo.roomId);
        }
        this.props.onReturnToCanvas != null
            ? this.props.onReturnToCanvas(popupData)
            : this.setState({
                ...defaultState(),
                selectedEndpointOption: undefined,
                endpoints: this.state.endpoints,
            });
        DataScopes.update();

        this.setState(popupData);
    }

    private getDataScopes() {
        return this.props.currentModuleId == null
            ? DataScopes
            : DataScopesForModules(this.props.currentModuleId);
    }

    private getDataTypeLabel(type: any) {
        if (!type) {
            return null;
        }
        return spreadSheetColumnOptions.find((item) => item.value === type)!
            .label;
    }

    async mySqlTables() {
        const jData = {
            // mysql: {
            //     host: "eisengard-mysql",
            //     port: 3306,
            //     database: "turtle_pt",
            //     login: "turtle_pt_user",
            //     password: "eisengard",
            // },
            mysql: {
                host: this.state.liveStreamMySQLHost,
                port: parseInt(this.state.liveStreamMySQLPort),
                database: this.state.liveStreamMySQLDatabase,
                login: this.state.liveStreamMySQLLogin,
                password: this.state.liveStreamMySQLPassword,
            },
        };
        await axios
            .post("/api/list_tables_in_database", jData)
            .then((resp: any) => {
                if (resp.data.success) {
                    let arr: any = [];
                    // let arr: StringOption[] | [] = [];
                    resp.data.table_names.forEach((item: string) => {
                        arr.push({
                            label: item,
                            value: item,
                        });
                    });
                    this.setState({
                        sqlTable: [...arr],
                        selectSqlTable: true,
                        sqlTableType: "mysql",
                    });
                } else this.setState({ error: resp.data.error_msg });
            });
    }

    async oracleDBTables() {
        const jData = {
            oracledb: {
                mode: this.state.liveStreamSelectedOracleDBConnectionMode,
                dsn: this.state.liveStreamOracleDBDsn,
                host: this.state.liveStreamOracleDBHost,
                port: parseInt(this.state.liveStreamOracleDBPort),
                service_name: this.state.liveStreamOracleDBServiceName,
                schema: this.state.liveStreamOracleDBSchema || undefined,
                login: this.state.liveStreamOracleDBLogin,
                password: this.state.liveStreamOracleDBPassword,
            },
        };
        await axios
            .post("/api/list_tables_in_database", jData)
            .then((resp: any) => {
                if (resp.data.success) {
                    let arr: any = [];
                    resp.data.table_names.forEach((item: string) => {
                        arr.push({
                            label: item,
                            value: item,
                        });
                    });
                    this.setState({
                        sqlTable: [...arr],
                        selectSqlTable: true,
                        sqlTableType: "oracledb",
                    });
                } else this.setState({ error: resp.data.error_msg });
            });
    }

    private renderContents(globalContext: GlobalContextContents): JSX.Element {
        let isRealTimeSlug =
            realTimeLiveStreamSlugs.indexOf(
                this.state.liveStreamEndpointSlug
            ) !== -1;
        if (this.state.showDataMarketplace)
            return (
                <div style={{ height: this.props.dockSize ?? "100%" }}>
                    <MarketplaceComponent
                        canvasTreeStore={this.props.canvasTreeStore}
                        onLoad={this.loadMarketplaceToSpreadsheet}
                        onClose={() => {
                            this.setState({ showDataMarketplace: false });
                        }}
                    ></MarketplaceComponent>
                </div>
            );
        const dataScopes = this.getDataScopes();
        let selectStyles = getCustomSelectStyleLight(14, false);
        let dataIsUploading =
            this.state.uploadStatus === UploadStatus.ReplacingSchema ||
            this.state.uploadStatus === UploadStatus.UploadingFile ||
            this.state.uploadStatus === UploadStatus.UploadingData;

        let dataSetIsNotSelected = this.state.dataScope == null;

        let { weekday, timeString } = timeToUTC(
            this.state.liveStreamWeekDay.value,
            this.state.liveStreamTime,
            this.state.liveStreamTimezone.value
        );
        let APIComponent;
        try {
            if (this.state.selectedEndpointOption != null) {
                let mod = require(`modules/canvas_page/api_page/api_components/${this.state.selectedEndpointOption.slug}.tsx`);
                APIComponent = mod.MainComponent;
            }
        } catch (exception) {}

        // Create API page component
        // const apiComponents: JSX.Element[] = this.state.endpoints.map(
        //     (endpoint, index) => {
        //         const eProps: APIButtonProps = {
        //             ...endpoint,
        //             createMessage: this.createMessage,
        //             key: index,
        //         };
        //         return React.createElement(
        //             APIConversion[endpoint.slug],
        //             eProps
        //         );
        //     }
        // );

        //// End API
        // if (document.getElementById("mainWrapper") !== null)
        //     document.getElementById("mainWrapper")!.style.overflow = this.state.selectMySqlTable ? "hidden" : ""

        const body = document.getElementById("mainWrapper");
        if (body !== null) {
            body!.style.overflow = this.state.selectSqlTable
                ? "hidden"
                : "auto";
            body!.style.position = this.state.selectSqlTable
                ? "fixed"
                : "revert";
        }

        let weekdayOption =
            liveStreamWeekDayOptions.find((o) => o.value === weekday) ??
            liveStreamWeekDayOptions[0];

        let liveStreamConfigChanged: boolean = false;
        // Is there a change in the liveStream Configuration options?
        // This option is triggered for "Dropbox, Google Drive, Google Sheets, MySQL"
        if (this.state.inputMode !== InputMode.LocalCsv) {
            liveStreamConfigChanged =
                this.state.liveStreamUpdateMode.value !==
                    this.state.liveStreamSelectedUpdateMode?.value ||
                this.state.liveStreamInterval.value !==
                    this.state.liveStreamSelectedInterval?.value ||
                weekdayOption.value !==
                    this.state.liveStreamSelectedWeekDay?.value ||
                timeString !== this.state.liveStreamSelectedTime;
            if (this.state.inputMode === InputMode.LiveStreamCsv) {
                liveStreamConfigChanged =
                    liveStreamConfigChanged ||
                    this.state.liveStreamCsvLink !==
                        this.state.liveStreamSelectedCsvLink;
            } else if (this.state.inputMode === InputMode.LiveStreamMySQL) {
                liveStreamConfigChanged =
                    liveStreamConfigChanged ||
                    this.state.liveStreamMySQLHost !==
                        this.state.liveStreamSelectedMySQLHost ||
                    this.state.liveStreamMySQLPort !==
                        this.state.liveStreamSelectedMySQLPort ||
                    this.state.liveStreamMySQLDatabase !==
                        this.state.liveStreamSelectedMySQLDatabase ||
                    this.state.liveStreamMySQLTable !==
                        this.state.liveStreamSelectedMySQLTable ||
                    this.state.liveStreamMySQLLogin !==
                        this.state.liveStreamSelectedMySQLLogin ||
                    this.state.liveStreamMySQLPassword !==
                        this.state.liveStreamSelectedMySQLPassword;
            } else if (this.state.inputMode === InputMode.LiveStreamOracleDB) {
                liveStreamConfigChanged =
                    liveStreamConfigChanged ||
                    this.state.liveStreamOracleDBHost !==
                        this.state.liveStreamSelectedOracleDBHost ||
                    this.state.liveStreamOracleDBPort !==
                        this.state.liveStreamSelectedOracleDBPort ||
                    this.state.liveStreamOracleDBServiceName !==
                        this.state.liveStreamSelectedOracleDBServiceName ||
                    this.state.liveStreamOracleDBSchema !==
                        this.state.liveStreamSelectedOracleDBSchema ||
                    this.state.liveStreamOracleDBTable !==
                        this.state.liveStreamSelectedOracleDBTable ||
                    this.state.liveStreamOracleDBLogin !==
                        this.state.liveStreamSelectedOracleDBLogin ||
                    this.state.liveStreamOracleDBPassword !==
                        this.state.liveStreamSelectedOracleDBPassword ||
                    this.state.liveStreamOracleDBDsn !==
                        this.state.liveStreamSelectedOracleDBDsn;
            } else if (this.state.inputMode === InputMode.LiveStreamAPI) {
                liveStreamConfigChanged =
                    liveStreamConfigChanged ||
                    this.state.liveStreamEndpointSlug !==
                        this.state.liveStreamSelectedEndpointSlug;
            }
        }
        let liveStreamConfigChangedOrNotValid =
            liveStreamConfigChanged || !this.state.liveStreamConfigValid;
        // This displays the Table, assuming it's possible load the file.
        let adminTableWithFullFeatures: JSX.Element | null = null;

        if (
            this.state.selectedFile &&
            this.state.loadFileStatus === LoadFileStatus.Success
        ) {
            adminTableWithFullFeatures = (
                <div
                    style={{
                        marginLeft: "10px",
                        marginRight: "17px",
                        display: "flex",
                        justifyContent: "center",
                        overflow: "hidden",
                        boxShadow: "0px 0px 12px -3px rgba(0,0,0,0.75)",
                        borderRadius: 8,
                    }}
                >
                    {/* <div style={{overflow:'auto', backgroundColor:'var(--greyColor)'}}> */}
                    <Element
                        name="scrollable"
                        className={`import_cstom_table element`}
                        // className="element"
                        style={{
                            padding: "15px 15px 0 15px",
                            overflow: "auto",
                            backgroundColor: "var(--greyColor)",
                            borderRadius: "8px",
                            flex: 1,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "5px"
                            }}
                        >
                            <span>Show entries</span>
                            <Select
                                styles={{
                                    ...selectStyles,
                                    ...leftAlignAndScrollBarCSS,
                                    container: (base) => ({
                                        ...base,
                                        paddingLeft: "10px",
                                        width: "90px",
                                    }),
                                }}
                                options={
                                    [
                                        { value: 10, label: "10"},
                                        { value: 20, label: "20"},
                                        { value: 50, label: "50"},
                                        { value: 100, label: "100"},
                                    ]
                                }
                                value={this.state.entriesPerPage}
                                onChange={(opt) => {
                                    this.setState({
                                        entriesPerPage: opt as IEntriesPerPage
                                    })
                                }}
                            />
                        </div>
                        <AdminTableWithFullFeatures
                            paging={false}
                            headerRenderer={(item, index) => (
                                <div className="flex-simple-column" key={index}>
                                    <div
                                        className="my-row"
                                        style={{
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: "Open Sans",
                                                fontWeight: 600,
                                                fontSize: "10px",
                                                color: "black",
                                            }}
                                        >
                                            {item}
                                            {this.getDataTypeLabel(
                                                this.state.columnFormats[index]!
                                                    .type
                                            ) && (
                                                <span
                                                    style={{
                                                        fontSize: "8px",
                                                        paddingLeft: "10px",
                                                    }}
                                                >
                                                    (
                                                    {this.getDataTypeLabel(
                                                        this.state
                                                            .columnFormats[
                                                            index
                                                        ]!.type
                                                    )}
                                                    )
                                                </span>
                                            )}
                                        </span>
                                        <FormatDropdown
                                            onChangeFormat={(format) => {
                                                let columnFormats = [
                                                    ...this.state.columnFormats,
                                                ];
                                                columnFormats[index] = format;
                                                this.setState({
                                                    columnFormats: columnFormats,
                                                });
                                            }}
                                            column={index}
                                            format={
                                                this.state.columnFormats[index]
                                            }
                                        />
                                    </div>
                                    <div
                                        className="my-row"
                                        style={{
                                            alignItems: "center",
                                            alignSelf: "flex-start",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#000000",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                            }}
                                        >
                                            {"include"}
                                        </span>
                                        <div
                                            style={{
                                                marginLeft: 5,
                                                paddingRight: 3,
                                            }}
                                        >
                                            <Switch
                                                onChange={(
                                                    checked: boolean
                                                ) => {
                                                    let columnsEnabled = [
                                                        ...this.state
                                                            .columnsEnabled,
                                                    ];
                                                    columnsEnabled[
                                                        index
                                                    ] = checked;
                                                    this.setState({
                                                        columnsEnabled: columnsEnabled,
                                                    });
                                                }}
                                                checked={
                                                    this.state.columnsEnabled[
                                                        index
                                                    ]
                                                }
                                                width={20}
                                                height={10}
                                                offColor="#999999"
                                                onColor="#3b82c9"
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                offHandleColor="#fff"
                                                onHandleColor="#fff"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            className={`import-table importSectionPreviewTable`}
                            tableName="Data"
                            tableHeader={this.state.csvHeader}
                            tableContent={this.state.csvData.slice(0, this.state.entriesPerPage.value -1)}
                        />
                        <div
                            style={{
                                margin: "10px 0px 10px 0px"
                            }}
                        >
                            <span>
                                Showing {Math.min(this.state.entriesPerPage.value, this.state.rowCount)} of {this.state.rowCount} entries
                            </span>
                        </div>

                    </Element>
                    {/* </div> */}
                </div>
            );
        }

        // This is checked when the file is selected and also for all LiveStream options.  Returns a "liveStreamConfig" object that is basically the tags that should be rendered.
        let liveStreamSheetsConfig: JSX.Element | null = null;

        if (
            this.state.selectedFile != null &&
            this.state.inputMode === InputMode.LiveStreamCsv &&
            this.state.sheets.length > 0
        ) {
            liveStreamSheetsConfig = (
                <div style={{ width: "100%" }}>
                    <div
                        style={{
                            // color: lightThemeStyle.primaryTextColor,
                            paddingBlock: 10,
                            color: "rgba(0,0,0,0.7)",
                            fontSize: 14,
                        }}
                    >
                        Select sheet:
                    </div>
                    <Select
                        inputId="select-character-encoding"
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            // ...smallSelectStyles,
                            ...selectStyles,
                            ...leftAlignAndScrollBarCSS,
                            container: (base) => ({
                                ...base,
                                width: "100%",
                                height: 38,
                            }),
                        }}
                        options={this.state.sheets}
                        onChange={(sheet) => {
                            this.setState(
                                { liveStreamSheetId: (sheet as Sheet).value },
                                () => {
                                    this.handleLiveStreamCsvLink(false);
                                }
                            );
                        }}
                        value={
                            this.state.liveStreamSheetId == null
                                ? this.state.sheets[0]
                                : this.state.sheets.find(
                                      (item) =>
                                          item.value ===
                                          this.state.liveStreamSheetId
                                  ) ?? null
                        }
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                </div>
            );
        }

        let liveStreamConfig: JSX.Element | null = null;
        if (this.state.inputMode === InputMode.LiveStreamCsv) {
            liveStreamConfig = (
                <div
                    className="center-container"
                    style={{
                        justifyContent: "flex-start",
                        width: "812px",
                        marginBlock: "15px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        {/* <span
                            className="content-regular-text"
                            style={{
                                color: lightThemeStyle.primaryTextColor,
                            }}
                        >
                            Link:
                        </span> */}
                        <input
                            className={`${styles.url} like-select`}
                            style={{
                                flex: 1,
                                backgroundColor:
                                    lightThemeStyle.backgroundColor,
                                color: "var(--selectors-text-color)",
                                marginLeft: "10px",
                                height: 46,
                                borderRadius: 6,
                                padding: "2px 49px 2px 15px",
                            }}
                            value={this.state.liveStreamCsvLink}
                            onChange={(e) => {
                                const text = e.target.value;
                                this.setState({
                                    liveStreamCsvLink: text,
                                });
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    if (
                                        this.state.inputMode ===
                                        InputMode.LiveStreamCsv
                                    ) {
                                        this.handleLiveStreamCsvLink(true);
                                    } else if (
                                        this.state.inputMode ===
                                        InputMode.LiveStreamMySQL
                                    ) {
                                        this.handleLiveStreamMySQLLink();
                                    } else if (
                                        this.state.inputMode ===
                                        InputMode.LiveStreamOracleDB
                                    ) {
                                        this.handleLiveStreamOracleDBLink();
                                    } else if (
                                        this.state.inputMode ===
                                        InputMode.LiveStreamAPI
                                    ) {
                                        this.handleEndpoint();
                                    }
                                }
                            }}
                            placeholder="Enter a URL"
                        />
                        <button
                            className={`${styles.linkIcon}`}
                            onClick={() => {
                                if (
                                    this.state.inputMode ===
                                    InputMode.LiveStreamCsv
                                ) {
                                    this.handleLiveStreamCsvLink(true);
                                } else if (
                                    this.state.inputMode ===
                                    InputMode.LiveStreamMySQL
                                ) {
                                    this.handleLiveStreamMySQLLink();
                                } else if (
                                    this.state.inputMode ===
                                    InputMode.LiveStreamOracleDB
                                ) {
                                    this.handleLiveStreamOracleDBLink();
                                } else if (
                                    this.state.inputMode ===
                                    InputMode.LiveStreamAPI
                                ) {
                                    this.handleEndpoint();
                                }
                            }}
                            disabled={this.state.liveStreamCsvLink === ""}
                        >
                            <img
                                src="/dist/img/canvas/Link.svg"
                                alt=""
                                draggable="false"
                            />
                        </button>
                    </div>
                </div>
            );
        } else if (this.state.inputMode === InputMode.LiveStreamMySQL) {
            liveStreamConfig = (
                <div style={{ marginBlock: 15, marginLeft: 10 }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            width: "90%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "fit-content",
                                marginBottom: "15px",
                            }}
                        >
                            <input
                                className={`${styles.valueBox} like-select`}
                                style={{
                                    width: 380,
                                }}
                                value={this.state.liveStreamMySQLHost}
                                placeholder="Host"
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLHost: text,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.mySqlTables();
                                    }
                                }}
                            />
                            <input
                                className={`${styles.valueBox} like-select`}
                                style={{
                                    width: 110,
                                    marginLeft: "15px",
                                }}
                                placeholder="Port"
                                value={this.state.liveStreamMySQLPort}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLPort: text,
                                    });
                                }}
                                onKeyDown={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.mySqlTables();
                                    }
                                }}
                            />
                            <input
                                className={`${styles.valueBox} like-select`}
                                style={{
                                    width: 280,
                                    marginLeft: "15px",
                                    backgroundColor:
                                        lightThemeStyle.backgroundColor,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                                placeholder="Database"
                                value={this.state.liveStreamMySQLDatabase}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLDatabase: text,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.mySqlTables();
                                    }
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "fit-content",
                            }}
                        >
                            <input
                                className={`${styles.valueBox} like-select`}
                                style={{
                                    width: 380,
                                }}
                                placeholder="Login"
                                autoComplete="off"
                                value={this.state.liveStreamMySQLLogin}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLLogin: text,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.mySqlTables();
                                    }
                                }}
                            />
                            <input
                                className={`${styles.valueBox} like-select`}
                                type="password"
                                style={{
                                    width: 346,
                                    marginLeft: "15px",
                                }}
                                placeholder="Password"
                                autoComplete="new-password"
                                value={this.state.liveStreamMySQLPassword}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamMySQLPassword: text,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.mySqlTables();
                                    }
                                }}
                            />
                            <button
                                className={`${styles.linkIcon}`}
                                style={{ position: "unset", marginLeft: 15 }}
                                onClick={() => {
                                    this.mySqlTables();
                                }}
                                disabled={
                                    this.state.liveStreamMySQLDatabase === "" ||
                                    this.state.liveStreamMySQLPassword === "" ||
                                    this.state.liveStreamMySQLPort === "" ||
                                    this.state.liveStreamMySQLLogin === "" ||
                                    this.state.liveStreamMySQLHost === ""
                                }
                            >
                                <img
                                    src="/dist/img/canvas/Link.svg"
                                    alt=""
                                    draggable="false"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.inputMode === InputMode.LiveStreamOracleDB) {
            liveStreamConfig = (
                <div style={{ marginBlock: 15, marginLeft: 10 }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "start",
                            width: "90%",
                            margin: "10px",
                        }}
                    >
                        <input
                            type="radio"
                            value="zconnect"
                            checked={
                                this.state
                                    .liveStreamSelectedOracleDBConnectionMode ===
                                "zconnect"
                            }
                            style={{
                                display: "inline-block",
                                transform: "translate(0, 0)",
                                margin: 0,
                            }}
                            onChange={(e) => {
                                const text = e.target.value;
                                this.setState({
                                    liveStreamSelectedOracleDBConnectionMode: text,
                                });
                            }}
                        />
                        <label
                            style={{
                                display: "inline-block",
                                margin: "0 10px",
                            }}
                        >
                            EZConnect
                        </label>
                        <input
                            type="radio"
                            value="dsn"
                            checked={
                                this.state
                                    .liveStreamSelectedOracleDBConnectionMode ===
                                "dsn"
                            }
                            style={{
                                display: "inline-block",
                                transform: "translate(0, 0)",
                                margin: 0,
                            }}
                            onChange={(e) => {
                                const text = e.target.value;
                                this.setState({
                                    liveStreamSelectedOracleDBConnectionMode: text,
                                });
                            }}
                        />{" "}
                        <label
                            style={{
                                display: "inline-block",
                                margin: "0 0 0 10px",
                            }}
                        >
                            DSN
                        </label>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            width: "90%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "fit-content",
                                marginBottom: "15px",
                            }}
                        >
                            {this.state
                                .liveStreamSelectedOracleDBConnectionMode ===
                                "dsn" && (
                                <>
                                    <textarea
                                        className={`${styles.valueBox} like-select`}
                                        style={{
                                            width: 580,
                                        }}
                                        value={this.state.liveStreamOracleDBDsn}
                                        placeholder="Dsn"
                                        onChange={(e) => {
                                            const text = e.target.value;
                                            this.setState({
                                                liveStreamOracleDBDsn: text,
                                            });
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.oracleDBTables();
                                            }
                                        }}
                                    />
                                </>
                            )}

                            {this.state
                                .liveStreamSelectedOracleDBConnectionMode ===
                                "zconnect" && (
                                <>
                                    <input
                                        className={`${styles.valueBox} like-select`}
                                        style={{
                                            width: 380,
                                        }}
                                        value={
                                            this.state.liveStreamOracleDBHost
                                        }
                                        placeholder="Host"
                                        onChange={(e) => {
                                            const text = e.target.value;
                                            this.setState({
                                                liveStreamOracleDBHost: text,
                                            });
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.oracleDBTables();
                                            }
                                        }}
                                    />
                                    <input
                                        className={`${styles.valueBox} like-select`}
                                        style={{
                                            width: 110,
                                            marginLeft: "15px",
                                        }}
                                        placeholder="Port"
                                        value={
                                            this.state.liveStreamOracleDBPort
                                        }
                                        onChange={(e) => {
                                            const text = e.target.value;
                                            this.setState({
                                                liveStreamOracleDBPort: text,
                                            });
                                        }}
                                        onKeyDown={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.oracleDBTables();
                                            }
                                        }}
                                    />
                                    <input
                                        className={`${styles.valueBox} like-select`}
                                        style={{
                                            width: 280,
                                            marginLeft: "15px",
                                            backgroundColor:
                                                lightThemeStyle.backgroundColor,
                                            color:
                                                lightThemeStyle.primaryTextColor,
                                        }}
                                        placeholder="Service or PDB name"
                                        value={
                                            this.state
                                                .liveStreamOracleDBServiceName
                                        }
                                        onChange={(e) => {
                                            const text = e.target.value;
                                            this.setState({
                                                liveStreamOracleDBServiceName: text,
                                            });
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.oracleDBTables();
                                            }
                                        }}
                                    />
                                    <input
                                        className={`${styles.valueBox} like-select`}
                                        style={{
                                            width: 280,
                                            marginLeft: "15px",
                                            backgroundColor:
                                                lightThemeStyle.backgroundColor,
                                            color:
                                                lightThemeStyle.primaryTextColor,
                                        }}
                                        placeholder="Schema (optional)"
                                        value={
                                            this.state.liveStreamOracleDBSchema
                                        }
                                        onChange={(e) => {
                                            const text = e.target.value;
                                            this.setState({
                                                liveStreamOracleDBSchema: text,
                                            });
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.oracleDBTables();
                                            }
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                width: "fit-content",
                            }}
                        >
                            <input
                                className={`${styles.valueBox} like-select`}
                                style={{
                                    width: 505,
                                }}
                                placeholder="Login"
                                autoComplete="off"
                                value={this.state.liveStreamOracleDBLogin}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamOracleDBLogin: text,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.oracleDBTables();
                                    }
                                }}
                            />
                            <input
                                className={`${styles.valueBox} like-select`}
                                type="password"
                                style={{
                                    width: 516,
                                    marginLeft: "15px",
                                }}
                                placeholder="Password"
                                autoComplete="new-password"
                                value={this.state.liveStreamOracleDBPassword}
                                onChange={(e) => {
                                    const text = e.target.value;
                                    this.setState({
                                        liveStreamOracleDBPassword: text,
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.oracleDBTables();
                                    }
                                }}
                            />
                            <button
                                className={`${styles.linkIcon}`}
                                style={{ position: "unset", marginLeft: 15 }}
                                onClick={() => {
                                    this.oracleDBTables();
                                }}
                                disabled={
                                    (this.state
                                        .liveStreamOracleDBServiceName === "" &&
                                        this.state
                                            .liveStreamSelectedOracleDBConnectionMode ===
                                            "zconnect") ||
                                    (this.state.liveStreamOracleDBDsn === "" &&
                                        this.state
                                            .liveStreamSelectedOracleDBConnectionMode ===
                                            "dsn") ||
                                    this.state.liveStreamOracleDBPassword ===
                                        "" ||
                                    (this.state.liveStreamOracleDBPort === "" &&
                                        this.state
                                            .liveStreamSelectedOracleDBConnectionMode ===
                                            "zconnect") ||
                                    this.state.liveStreamOracleDBLogin === "" ||
                                    (this.state.liveStreamOracleDBHost === "" &&
                                        this.state
                                            .liveStreamSelectedOracleDBConnectionMode ===
                                            "zconnect")
                                    // schema is optional
                                }
                            >
                                <img
                                    src="/dist/img/canvas/Link.svg"
                                    alt=""
                                    draggable="false"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        // This is activate when we change "Update Mode" to anything that is not "none"
        let liveStreamIntervalConfig: JSX.Element | null = null;
        if (
            (this.state.inputMode === InputMode.LiveStreamAPI &&
                this.state.selectedFile != null) ||
            this.state.inputMode === InputMode.LiveStreamCsv ||
            this.state.inputMode === InputMode.LiveStreamMySQL ||
            this.state.inputMode === InputMode.LiveStreamOracleDB
        ) {
            liveStreamIntervalConfig = (
                <div className="center-container">
                    <div style={{ width: "100%" }}>
                        <div
                            className="content-regular-text"
                            style={{
                                minWidth: "max-content",
                                // color: lightThemeStyle.primaryTextColor,
                                paddingBlock: 10,
                                color: "rgba(0,0,0,0.7)",
                                fontSize: 14,
                            }}
                        >
                            Update mode:
                        </div>
                        <div style={{ display: "flex" }}>
                            <Select
                                isDisabled={isRealTimeSlug}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                menuPortalTarget={document.body}
                                placeholder={""}
                                styles={{
                                    ...selectStyles,
                                    ...leftAlignAndScrollBarCSS,
                                    container: (base) => ({
                                        ...base,
                                        height: "38px",
                                        width: "100%",
                                        marginRight: "10px",
                                    }),
                                    control: (base) => ({
                                        ...base,
                                        minHeight: 36,
                                        borderRadius: 4,
                                        backgroundColor: "#FFFFFF",
                                        borderColor: "#D1D1D1",
                                        marginInline: "1%",
                                    }),
                                    input: (base) => ({
                                        ...base,
                                        paddingLeft: 4,
                                    }),
                                }}
                                options={
                                    this.state.inputMode ===
                                        InputMode.LiveStreamAPI ||
                                    this.state.inputMode ===
                                        InputMode.LiveStreamOracleDB ||
                                    this.state.inputMode ===
                                        InputMode.LiveStreamCsv
                                        ? liveStreamUpdateModeOptionsWithoutAppend
                                        : liveStreamUpdateModeOptions
                                }
                                value={this.state.liveStreamUpdateMode}
                                onChange={(option) => {
                                    this.setState({
                                        liveStreamUpdateMode: option as LiveStreamUpdateModeOption,
                                        // this should NOT change upload option
                                    });
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                            <img
                                data-tip={
                                    this.state.liveStreamUpdateMode.value ===
                                    "none"
                                        ? "Insert the data once and never update it"
                                        : this.state.liveStreamUpdateMode
                                              .value === "append"
                                        ? "Append new rows. Old rows should not be changed or deleted"
                                        : "Delete all old data and insert all new data on every update"
                                }
                                data-for="functionsList"
                                src="/dist/img/data-exploration/question.svg"
                                alt=""
                                style={{
                                    marginLeft: 5,
                                }}
                            />
                            <ReactTooltip
                                effect="solid"
                                id="functionsList"
                                multiline={true}
                            />
                        </div>

                        {/* none append replace */}
                    </div>
                    {this.state.liveStreamUpdateMode.value !== "none" && (
                        <div
                            style={{ position: "absolute", left: 0, top: 103 }}
                        >
                            {/* <div > */}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    marginBottom: "15px",
                                }}
                            >
                                <span
                                    className="content-regular-text"
                                    style={{
                                        color: lightThemeStyle.primaryTextColor,
                                    }}
                                >
                                    Every
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...selectStyles,
                                        ...leftAlignAndScrollBarCSS,
                                        container: (base) => ({
                                            ...base,
                                            height: "38px",
                                            width: 100,
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                        }),
                                    }}
                                    options={liveStreamIntervalOptions}
                                    value={this.state.liveStreamInterval}
                                    onChange={(option) => {
                                        this.setState({
                                            liveStreamInterval: option as LiveStreamIntervalOption,
                                        });
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                {this.state.liveStreamInterval.value ===
                                    "week" && (
                                    <>
                                        <span
                                            className="content-regular-text"
                                            style={{
                                                color:
                                                    lightThemeStyle.primaryTextColor,
                                            }}
                                        >
                                            on
                                        </span>
                                        <Select
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            placeholder={""}
                                            styles={{
                                                ...selectStyles,
                                                ...leftAlignAndScrollBarCSS,
                                                container: (base) => ({
                                                    ...base,
                                                    height: "38px",
                                                    width: 100,
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                }),
                                            }}
                                            options={liveStreamWeekDayOptions}
                                            value={this.state.liveStreamWeekDay}
                                            onChange={(option) => {
                                                this.setState({
                                                    liveStreamWeekDay: option as LiveStreamWeekDayOption,
                                                });
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25:
                                                        "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </>
                                )}
                                <span
                                    className="content-regular-text"
                                    style={{
                                        color: lightThemeStyle.primaryTextColor,
                                    }}
                                >
                                    at
                                </span>
                                <input
                                    className={`${styles.inputBorder} like-select`}
                                    value={this.state.liveStreamTime}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        this.setState({
                                            liveStreamTime: value,
                                        });
                                    }}
                                    style={{
                                        backgroundColor:
                                            lightThemeStyle.backgroundColor,
                                        color: lightThemeStyle.primaryTextColor,
                                        width: 100,
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                    }}
                                />
                                <TimezoneSelect
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={""}
                                    styles={{
                                        ...selectStyles,
                                        ...leftAlignAndScrollBarCSS,
                                        container: (base) => ({
                                            ...base,
                                            height: "38px",
                                            width: 500,
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                        }),
                                    }}
                                    value={this.state.liveStreamTimezone}
                                    onChange={(option) => {
                                        this.setState({
                                            liveStreamTimezone: option as StringOption,
                                        });
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    marginBottom: "15px",
                                }}
                            >
                                <img
                                    src="/dist/img/warning.png"
                                    alt=""
                                    style={{
                                        marginRight: 5,
                                    }}
                                />
                                <span
                                    className="content-regular-text"
                                    style={{
                                        color: lightThemeStyle.primaryTextColor,
                                    }}
                                >
                                    <b>WARNING:</b> Do not change the order of
                                    the columns in the source data, or updates
                                    will not work
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            );
        }

        // This handles the various options of uploadStatus, otherwise returns the success message.
        let uploadAlert: JSX.Element | null = null;

        if (this.state.error != null) {
            uploadAlert = (
                <div>
                    <Alert
                        text={"Error while parsing data: " + this.state.error}
                        className="alert alert-danger alert-dismissible"
                        onClosed={() =>
                            this.setState({
                                error: null,
                            })
                        }
                    />
                </div>
            );
        } else if (this.state.uploadStatus === UploadStatus.Success) {
            uploadAlert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text="Successully uploaded data"
                        className="alert alert-success alert-dismissible"
                        onClosed={() =>
                            this.setState({
                                uploadStatus: UploadStatus.NotUploaded,
                            })
                        }
                    />
                </div>
            );
        } else if (this.state.uploadStatus === UploadStatus.Error) {
            uploadAlert = (
                <div>
                    <Alert
                        text={
                            "Error while uploading data: " +
                            this.state.uploadMessageError
                        }
                        className="alert alert-danger alert-dismissible"
                        onClosed={() =>
                            this.setState({
                                uploadStatus: UploadStatus.NotUploaded,
                            })
                        }
                    />
                </div>
            );
        } else if (this.state.uploadStatus === UploadStatus.ReplacingSchema) {
            uploadAlert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text={"Replacing schema"}
                        className="alert alert-warning"
                    />
                </div>
            );
        } else if (this.state.uploadStatus === UploadStatus.UploadingData) {
            uploadAlert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text={"Uploading data"}
                        className="alert alert-warning"
                    />
                </div>
            );
        } else if (this.state.uploadStatus === UploadStatus.UploadingFile) {
            uploadAlert = (
                <div style={{ margin: 10 }}>
                    <Alert
                        text={"Uploading file"}
                        className="alert alert-warning alert-dismissible"
                        onClosed={this.cancelUploadProcess}
                    />
                </div>
            );
        }
        let filterOptions = options.filter(
            (option) =>
                option.value !== InputMode.LiveStreamAPI ||
                "APIs" in globalContext.permissions
        );
        return (
            <div
                className="mainContainer"
                // style={{
                //     height: (this.state.selectedFile) ? "" : "100%",
                //     paddingBottom: 30,
                // }}
            >
                <div
                    className="flex-simple-column"
                    style={{
                        boxShadow: "none",
                        backgroundColor: this.props.dataHubMode
                            ? "transparent"
                            : "#f7f7f7",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            marginTop: "20px",
                            position: "relative",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: this.props.dataHubMode
                                ? 0
                                : this.state.selectedFile == null
                                ? "57px"
                                : "38px",
                        }}
                    >
                        {/* <span className={styles.dataHubTitle}>
                            {this.state.selectedFile != null
                                ? "Import data"
                                : "Import data from"}
                        </span>
                         When data is selected, give the title
                        {this.state.selectedFile != null && (
                            <Select
                                inputId="select-data-scope"
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={
                                    "Enter new name or replace an existing data set"
                                }
                                styles={{
                                    ...selectStyles,
                                    container: (base) => ({
                                        ...base,
                                        marginLeft: "15px",
                                        width: "25em",
                                        height: "38px",
                                        marginRight: "10px",
                                    }),
                                }}
                                options={dataScopes.dataScopesOptions
                                    .filter(
                                        (option) =>
                                            option.permissionType ===
                                            Permission.ReadWrite
                                    )
                                    .concat(
                                        this.state.newDataScope != null
                                            ? [this.state.newDataScope]
                                            : []
                                    )}
                                onChange={(newValue, p2) => {
                                    let stateDiff: any = {
                                        dataScope: newValue as DataScopeOption,
                                    };
                                    stateDiff.uploadOption = uploadOptions[0];
                                    this.setState(stateDiff);
                                }}
                                inputValue={this.state.dataScopeText}
                                onInputChange={(newValue, { action }) => {
                                    if (
                                        ["input-blur"].includes(action) &&
                                        (this.state.dataScopeText?.length ||
                                            0) > 0
                                    ) {
                                        this.setState({
                                            newDataScope: {
                                                label:
                                                    this.state.dataScopeText ||
                                                    "",
                                                value: NaN,
                                                permissionType:
                                                    Permission.ReadWrite,
                                            },
                                            dataScope: {
                                                label:
                                                    this.state.dataScopeText ||
                                                    "",
                                                value: NaN,
                                                permissionType:
                                                    Permission.ReadWrite,
                                            },
                                            uploadOption: uploadOptions[0],
                                        });
                                    }
                                    this.setState({ dataScopeText: newValue });
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        (e.target as HTMLElement).blur();
                                    }
                                }}
                                value={this.state.dataScope}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        )} */}
                        {/* When data is Not selected, render elements  */}
                        {
                            // this.state.selectedFile == null &&
                            <div className="my-row">
                                <div
                                    className={styles.dataHubButtonsContainer}
                                    style={{
                                        marginLeft: 10,
                                        height: 45,
                                        borderRadius: 6,
                                        background: "transparent",
                                    }}
                                >
                                    {filterOptions.map((option) => (
                                        <Button
                                            key={option.label}
                                            type="button"
                                            className={cx(
                                                styles.dataHubButton,
                                                option.label ===
                                                    this.state.inputLabel &&
                                                    styles.dataHubButtonSelected
                                            )}
                                            style={{
                                                width: "100%",
                                                padding: "20px 30px",
                                                borderRadius: 6,
                                                outline: "none",
                                                boxShadow: "none",
                                            }}
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    ...defaultState(),
                                                    endpoints: state.endpoints,
                                                    inputMode: option.value,
                                                    inputLabel: option.label,
                                                }));
                                            }}
                                        >
                                            {option.icon !== "" && (
                                                <img
                                                    style={{ marginRight: 5 }}
                                                    alt=""
                                                    src={`/dist/img/data-exploration/${option.icon}`}
                                                />
                                            )}
                                            {option.label}
                                        </Button>
                                    ))}
                                </div>
                                {this.props.onReturnToCanvas && (
                                    <Button
                                        type="button"
                                        className={cx(styles.dataHubButton)}
                                        style={{
                                            width: "100%",
                                            marginLeft: 15,
                                            border: "1px solid #D1D1D1",
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingLeft: 14,
                                            paddingRight: 14,
                                            height: 45,
                                            borderRadius: 6,
                                            background: "transparent",
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                showDataMarketplace: true,
                                            });
                                        }}
                                    >
                                        {"Data Marketplace"}
                                    </Button>
                                )}
                            </div>
                        }
                    </div>
                    {/* begin body of pane */}
                    {/* CSV option, but havne't uploaded data */}
                    {this.state.selectedFile == null &&
                        this.state.inputMode === InputMode.LocalCsv && (
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    if (acceptedFiles.length > 0)
                                        this.handleFileUpload(acceptedFiles[0]);

                                    // SelectedOption.UpdateaskSwitchTab(false);
                                    // SelectedOption.UpdateSwitchTab(false);
                                }}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                }) => (
                                    <div
                                        {...getRootProps()}
                                        style={{
                                            display: "flex",
                                            marginTop: this.props.dataHubMode
                                                ? "20px"
                                                : "50px",
                                            marginLeft: this.props.dataHubMode
                                                ? 0
                                                : "55px",
                                            marginRight: this.props.dataHubMode
                                                ? 0
                                                : "55px",
                                            marginBottom: "0px",
                                            height: "100%",
                                            borderRadius: "4px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: "2px dashed #a3a3a3",
                                            minHeight: 400,
                                        }}
                                    >
                                        <div
                                            className="flex-simple-column"
                                            style={{ alignItems: "center" }}
                                        >
                                            {!isDragActive && (
                                                <>
                                                    <span
                                                        className="content-regular-text"
                                                        style={{
                                                            cursor: "default",
                                                            fontSize: "20px",
                                                            color:
                                                                lightThemeStyle.primaryTextColor,
                                                            marginBottom: 10,
                                                        }}
                                                    >
                                                        Drop a file
                                                    </span>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 1,
                                                                width: 80,
                                                                backgroundColor:
                                                                    "#a3a3a3",
                                                            }}
                                                        />
                                                        <span
                                                            style={{
                                                                margin:
                                                                    "0 15px",
                                                                color:
                                                                    "#a3a3a3",
                                                            }}
                                                        >
                                                            or
                                                        </span>
                                                        <div
                                                            style={{
                                                                height: 1,
                                                                width: 80,
                                                                backgroundColor:
                                                                    "#a3a3a3",
                                                            }}
                                                        />
                                                    </div>
                                                    <button
                                                        style={{
                                                            border:
                                                                "2px solid #3b82c9",
                                                            borderRadius: 4,
                                                            color: "#3b82c9",
                                                            fontWeight: 600,
                                                            padding: "5px 10px",
                                                            fontSize: 16,
                                                            marginTop: 15,
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <ImportIcon
                                                            style={{
                                                                fill: "#3b82c9",
                                                                stroke:
                                                                    "#3b82c9",
                                                                marginRight: 15,
                                                            }}
                                                        />
                                                        Import from device
                                                    </button>
                                                </>
                                            )}
                                            {isDragActive && (
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                        marginTop: "6px",
                                                        color: "#869AAC",
                                                        fontSize: "15px",
                                                    }}
                                                >
                                                    Drop File Here
                                                </span>
                                            )}
                                        </div>
                                        <input {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                        )}
                    {/* Added this display table for API' option. */}
                    {this.state.inputMode === InputMode.LiveStreamAPI && (
                        <>
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    marginLeft: 10,
                                    marginRight: 163,
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    className="content-regular-text"
                                    style={{
                                        fontSize: 20,
                                        fontFamily: "Roboto",
                                        color: lightThemeStyle.primaryTextColor,
                                    }}
                                >
                                    API Source
                                </span>
                                <Select
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    placeholder={"Select API source"}
                                    isDisabled={this.state.selectedFile != null}
                                    styles={{
                                        ...selectStyles,
                                        ...leftAlignAndScrollBarCSS,
                                        container: (base) => ({
                                            ...base,
                                            height: "38px",
                                            flex: 1,
                                            marginLeft: "41px",
                                            marginRight: "24px",
                                        }),
                                    }}
                                    options={this.state.endpoints.map(
                                        (endpoint) => ({
                                            ...endpoint,
                                            label: endpoint.name,
                                            value: endpoint.slug,
                                        })
                                    )}
                                    value={this.state.selectedEndpointOption}
                                    onChange={(option) => {
                                        let newOption = option as APIUpdateEndpointOption;
                                        this.setState({
                                            selectedEndpointOption: newOption,
                                            liveStreamEndpointSlug:
                                                newOption.slug,
                                        });
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25:
                                                "var(--selectors-background-hover-color)",
                                        },
                                    })}
                                />
                                {this.state.selectedEndpointOption != null && (
                                    <button
                                        type="button"
                                        className="acceptBtn"
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            height: "33px",
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            backgroundColor: "#3b82c9",
                                        }}
                                        onClick={() => {
                                            if (this.state.selectedFile == null)
                                                this.handleEndpoint();
                                            else {
                                                this.setState((state) => ({
                                                    ...defaultState(),
                                                    liveStreamEndpointSlug:
                                                        state.liveStreamSelectedEndpointSlug,
                                                    liveStreamEndpointOptions:
                                                        state.liveStreamEndpointOptions,
                                                    endpoints: state.endpoints,
                                                    inputMode: state.inputMode,
                                                    inputLabel:
                                                        state.inputLabel,
                                                }));
                                            }
                                        }}
                                    >
                                        {this.state.selectedFile == null
                                            ? "Connect"
                                            : "Disconnect"}
                                    </button>
                                )}
                            </div>
                            {this.state.selectedFile == null &&
                                this.state.selectedEndpointOption != null && (
                                    <APIComponent
                                        onOptionsChange={(options: any) => {
                                            this.setState({
                                                liveStreamEndpointOptions: options,
                                            });
                                        }}
                                        options={
                                            this.state.liveStreamEndpointOptions
                                        }
                                        containerStyle={{
                                            marginLeft: 10,
                                            marginTop: 36,
                                        }}
                                        endpoint={
                                            this.state.selectedEndpointOption
                                        }
                                    />
                                )}
                        </>
                    )}

                    {liveStreamConfig}

                    {/* Selected file, but advanced options collapsed */}
                    {this.state.selectedFile != null && (
                        <div>
                            <div
                                style={{
                                    borderBottom:
                                        this.state.inputMode ===
                                        InputMode.LiveStreamMySQL
                                            ? "1px solid #d1d1d1"
                                            : "",
                                    marginRight: 20,
                                    marginLeft: 10,
                                }}
                            ></div>
                            <div
                                className="my-row"
                                style={{
                                    margin: "20px 43px 20px 10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ width: 400 }}>
                                    {this.state.liveStreamMySQLTable !== "" && (
                                        <Select
                                            options={this.state.sqlTable}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                            styles={{
                                                ...leftAlignAndScrollBarCSS,
                                                singleValue: (base) => ({
                                                    ...base,
                                                    paddingLeft: 8,
                                                }),
                                                input: (base) => ({
                                                    ...base,
                                                    paddingLeft: 8,
                                                }),
                                                control: (base) => ({
                                                    ...base,
                                                    border: "1px solid #d1d1d1",
                                                    borderRadius: 6,
                                                }),
                                            }}
                                            isMulti={false}
                                            value={{
                                                label: this.state
                                                    .liveStreamMySQLTable,
                                                value: this.state
                                                    .liveStreamMySQLTable,
                                            }}
                                            onChange={async (item) => {
                                                await new Promise((res) => {
                                                    this.setState((state) => {
                                                        if (
                                                            state.sqlTableType ===
                                                            "mysql"
                                                        ) {
                                                            return {
                                                                liveStreamMySQLTable: item!
                                                                    .label,
                                                                liveStreamOracleDBTable:
                                                                    state.liveStreamOracleDBTable,
                                                            };
                                                        } else {
                                                            return {
                                                                liveStreamMySQLTable:
                                                                    state.liveStreamMySQLTable,
                                                                liveStreamOracleDBTable: item!
                                                                    .label,
                                                            };
                                                        }
                                                    });
                                                    res("ok");
                                                });
                                                if (
                                                    this.state.sqlTableType ===
                                                    "mysql"
                                                ) {
                                                    this.handleLiveStreamMySQLLink();
                                                } else {
                                                    this.handleLiveStreamOracleDBLink();
                                                }
                                                this.setState({
                                                    selectSqlTable: false,
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            // color: this.state.advancedOptionsExpanded
                                            //     ? lightThemeStyle.secondaryTextColor
                                            //     : lightThemeStyle.primaryTextColor,
                                            color: "var(--acceptBtn-color)",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            fontFamily: "Roboto",
                                            cursor: "pointer",
                                        }}
                                        className="unselectable"
                                        onClick={() => {
                                            this.setState((state) => ({
                                                advancedOptionsExpanded: !state.advancedOptionsExpanded,
                                            }));
                                        }}
                                    >
                                        Advanced Options
                                    </div>
                                    <div
                                        className="unselectable"
                                        style={{
                                            transform: this.state
                                                .advancedOptionsExpanded
                                                ? "none"
                                                : "rotate(90deg)",
                                            opacity: this.state
                                                .advancedOptionsExpanded
                                                ? 1
                                                : 0.5,
                                            width: 20,
                                            height: 20,
                                        }}
                                    >
                                        <CanvasIconsContainer
                                            options={{
                                                fill: this.state
                                                    .advancedOptionsExpanded
                                                    ? lightThemeStyle.secondaryTextColor
                                                    : lightThemeStyle.primaryTextColor,
                                            }}
                                            type={"Arrow"}
                                        ></CanvasIconsContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Advanced options when expanded */}
                    {this.state.advancedOptionsExpanded &&
                        this.state.selectedFile != null && (
                            <div
                                className="my-row"
                                style={{
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    marginInline: 15,
                                    border: "1px solid #D0D0D0",
                                    borderRadius: 6,
                                    marginBottom: 25,
                                    paddingBlock:
                                        this.state.liveStreamUpdateMode
                                            .value !== "none"
                                            ? "15px 130px"
                                            : "15px 25px",
                                }}
                            >
                                <div
                                    className={styles.advancedOptionsChild}
                                    style={{ position: "relative" }}
                                >
                                    {
                                        <div style={{ width: "100%" }}>
                                            {/* {liveStreamConfig} */}
                                            {liveStreamIntervalConfig}
                                        </div>
                                    }
                                </div>

                                <div className={styles.advancedOptionsChild}>
                                    {liveStreamSheetsConfig}
                                </div>

                                <div className={styles.advancedOptionsChild}>
                                    <div style={{ width: "100%" }}>
                                        <div style={{ paddingBlock: 10 }}>
                                            <span
                                                style={{
                                                    fontFamily: "Roboto",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    color: "rgba(0,0,0,0.7)",
                                                }}
                                            >
                                                Character encoding
                                            </span>
                                            <span
                                                style={{
                                                    marginLeft: "6px",
                                                    fontFamily: "Roboto",
                                                    fontWeight: 300,
                                                    fontSize: "12px",
                                                    color:
                                                        "var(--acceptBtn-color)",
                                                }}
                                            >
                                                detected automatically
                                            </span>
                                        </div>
                                        <Select
                                            inputId="select-character-encoding"
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            placeholder={""}
                                            styles={{
                                                ...selectStyles,
                                                ...leftAlignAndScrollBarCSS,
                                                container: (base) => ({
                                                    ...base,
                                                    width: "100%",
                                                    height: 38,
                                                }),
                                                input: (base) => ({
                                                    ...base,
                                                    paddingLeft: 4,
                                                }),
                                            }}
                                            options={encodings}
                                            onChange={(newEncoding) => {
                                                if (
                                                    this.state.selectedFile !=
                                                    null
                                                )
                                                    this.prepareCsvFile(
                                                        this.state.selectedFile,
                                                        newEncoding as StringOption
                                                    );
                                            }}
                                            value={this.state.encoding}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25:
                                                        "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                    {/* when the file is actually selected, and then need to show the entire table */}
                    {this.state.selectedFile != null && (
                        <>{adminTableWithFullFeatures}</>
                    )}

                    {this.state.selectedFile === null &&
                        (this.state.inputMode === InputMode.LiveStreamMySQL ||
                            this.state.inputMode ===
                                InputMode.LiveStreamOracleDB ||
                            this.state.inputMode ===
                                InputMode.LiveStreamCsv) && (
                            <div className={styles.previewContainer}>
                                Preview
                            </div>
                        )}

                    {/* file is not selected, but need to show the streaming options */}
                    {/* {liveStreamConfig} */}
                    {this.state.loadFileStatus === LoadFileStatus.Loading && (
                        <div
                            style={{
                                marginTop: "20px",
                                marginLeft: 138,
                                marginRight: 138,
                            }}
                        >
                            <LinearProgress />
                        </div>
                    )}

                    <div style={{ flex: 1 }} />

                    {/* TODO: need to make this work for LiveStream API */}
                    <div
                        style={{
                            marginBlock: "18px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        {this.state.selectedFile && (
                            <Button
                                type="button"
                                className={`${styles.backBtn} btn btn-lg btn-primary my-primary`}
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    height: 40,
                                    padding: "0 20px",
                                    marginLeft: 10,
                                    backgroundColor: "#a3a3a3",
                                    border: "none",
                                }}
                                onClick={() => this.setState(defaultState())}
                            >
                                Back to initial menu
                            </Button>
                        )}
                        <div style={{ flex: 1 }} />
                        {uploadAlert}
                        <button
                            type="button"
                            className="cancelBtn"
                            onClick={this.cancelUploadProcess}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="acceptBtn"
                            title={
                                dataIsUploading
                                    ? "Data is already uploading"
                                    : liveStreamConfigChangedOrNotValid
                                    ? "Your configuration changed. Please click SELECT before uploading the data"
                                    : dataSetIsNotSelected
                                    ? "You need to select dataset before uploading the data."
                                    : ""
                            }
                            disabled={
                                this.state.csvData.length === 0 ||
                                dataIsUploading
                            }
                            onClick={() => {
                                this.setState({
                                    saveDataset: !this.state.saveDataset,
                                    dataScope: undefined,
                                });
                            }}
                            style={{ marginRight: 15, paddingBlock: 8 }}
                        >
                            Save dataset
                            {/* {this.state.dataScope?.value == null ||
                                Number.isNaN(this.state.dataScope?.value)
                                ? "Save dataset"
                                : "Replace"} */}
                        </button>
                    </div>
                </div>

                {this.state.saveDataset && (
                    <MessagePopup
                        title={"Import data"}
                        message={
                            <div>
                                <input
                                    placeholder="Name your dataset"
                                    className={styles.nameDataset}
                                    onChange={(e) => {
                                        this.setState({
                                            newDatasetName: e.target.value,
                                        });
                                    }}
                                    onBlur={() => {
                                        if (this.state.newDatasetName) {
                                            this.setState({
                                                newDataScope: {
                                                    label:
                                                        this.state
                                                            .dataScopeText ||
                                                        "",
                                                    value: NaN,
                                                    permissionType:
                                                        Permission.ReadWrite,
                                                },
                                                uploadOption: uploadOptions[0],
                                            });
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (this.state.newDatasetName) {
                                            if (e.key === "Enter") {
                                                this.setState({
                                                    newDataScope: {
                                                        label:
                                                            this.state
                                                                .dataScopeText ||
                                                            "",
                                                        value: NaN,
                                                        permissionType:
                                                            Permission.ReadWrite,
                                                    },
                                                    uploadOption:
                                                        uploadOptions[0],
                                                });
                                            }
                                        }
                                    }}
                                />
                            </div>
                        }
                        acceptButtonTitle="Save"
                        onAccept={() => {
                            let dataScopeNames = dataScopes.dataScopesOptions.filter((option) =>
                                option.permissionType === Permission.ReadWrite).map((d) => d.label);

                            if(dataScopeNames.includes(this.state.newDatasetName)) {
                                this.setState({
                                    overwriteDatasetModal: true
                                })
                            } else {
                                this.setState(
                                    {
                                        newDataScope: {
                                            label: this.state.newDatasetName || "",
                                            value: NaN,
                                            permissionType: Permission.ReadWrite,
                                        },
                                        dataScope: {
                                            label: this.state.newDatasetName || "",
                                            value: NaN,
                                            permissionType: Permission.ReadWrite,
                                        },
                                        uploadOption: uploadOptions[0],
                                    },
                                    this.uploadProcess
                                );
                                this.setState((state) => ({
                                    newDatasetName: "",
                                }));
                            }
                        }}
                        onReject={() => {
                            if(!this.state.overwriteDatasetModal)
                                this.setState(() => ({
                                    dataScope: undefined,
                                    replaceDataset: false,
                                    saveDataset: false
                                }));
                        }}
                        disabled={
                            this.state.newDatasetName === "" ||
                            dataIsUploading
                        }
                        disableReplace={!this.state.dataScope}
                        updateBtn={this.state.replaceDataset ? true : false}
                    />
                )}
                {this.state.overwriteDatasetModal &&
                    <WarningModalPopup
                        onReplace={() => {
                            const newValue = dataScopes.dataScopesOptions.filter(
                                    (option) =>
                                        option.permissionType === Permission.ReadWrite &&
                                        option.label === this.state.newDatasetName
                            )[0]

                            let stateDiff: any = {
                                dataScope: newValue as DataScopeOption,
                            };
                            stateDiff.uploadOption =
                                uploadOptions[0];
                            this.setState(stateDiff);
                            this.setState(
                                {
                                    uploadOption: uploadOptions[0],
                                    overwriteDatasetModal: false,
                                    saveDataset: false
                                },
                                () => {
                                    this.uploadProcess();
                                }
                            );
                            this.setState((state) => ({
                                newDatasetName: "",
                            }));
                        }}
                        onReject={() => {
                            this.setState({
                                overwriteDatasetModal: false
                            })
                        }}
                        title="Alert"
                        text={"Dataset with this name already exists. Would you like to replace it?"}
                        subText={"A dataset with the same name already exists. Replacing it will overwrite its current contents."}
                    />
                }

                {this.state.selectSqlTable && (
                    <MessagePopup
                        title={
                            <p style={{ paddingLeft: 12 }}>select a table</p>
                        }
                        className={styles.hideOverflow}
                        message={
                            <div className={`${styles.tableList} scrollBar`}>
                                {this.state.sqlTable.length > 0 &&
                                    this.state.sqlTable.map((item) => (
                                        <p
                                            className={styles.tableName}
                                            onClick={async () => {
                                                await new Promise((res) => {
                                                    this.setState((state) => {
                                                        if (
                                                            state.sqlTableType ===
                                                            "mysql"
                                                        ) {
                                                            return {
                                                                liveStreamMySQLTable: item!
                                                                    .label,
                                                                liveStreamOracleDBTable:
                                                                    state.liveStreamOracleDBTable,
                                                            };
                                                        } else {
                                                            return {
                                                                liveStreamMySQLTable:
                                                                    state.liveStreamMySQLTable,
                                                                liveStreamOracleDBTable: item!
                                                                    .label,
                                                            };
                                                        }
                                                    });
                                                    res("ok");
                                                });
                                                if (
                                                    this.state.sqlTableType ===
                                                    "mysql"
                                                ) {
                                                    this.handleLiveStreamMySQLLink();
                                                } else {
                                                    this.handleLiveStreamOracleDBLink();
                                                }
                                                this.setState({
                                                    selectSqlTable: false,
                                                });
                                            }}
                                        >
                                            {item.label}
                                        </p>
                                    ))}
                            </div>
                        }
                        rejactButton="false"
                        onReject={() => {
                            this.setState({ selectSqlTable: false });
                        }}
                    />
                )}

                {this.state.uploadFormatError != null && (
                    <MessagePopup
                        title={"Change to NA"}
                        message={
                            messageForUploadFormatError[
                                this.state.uploadFormatError
                            ]
                        }
                        rejectButtonTitle="No"
                        acceptButtonTitle="Yes"
                        onAccept={() => {
                            let forceDateToNans =
                                this.state.uploadFormatError === "date" ||
                                this.state.uploadFormatErrorForce
                                    ?.forceDateToNans;
                            let forceNumbersToNull =
                                this.state.uploadFormatError === "number" ||
                                this.state.uploadFormatErrorForce
                                    ?.forceNumbersToNull;
                            this.setState({ uploadFormatError: null }, () => {
                                this.uploadData(
                                    forceDateToNans,
                                    forceNumbersToNull
                                );
                            });
                        }}
                        onReject={() => {
                            this.setState({ uploadFormatError: null });
                        }}
                    />
                )}
                {this.state.streamStatus != null && (
                    <StatusPopup
                        status={this.state.streamStatus}
                        message={this.state.streamMessage!}
                        onClose={() => {
                            this.setState({
                                streamStatus: null,
                                streamMessage: null,
                            });
                        }}
                    />
                )}
            </div>
        );
    }
    render() {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

export { MainComponent };
