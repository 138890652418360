import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { HeatmapFinding } from "common/Finding";
import remoteModuleId from "common/remoteModuleId";
import { updateOriginalName, updateOriginalNames } from "../../common/Utils";

class Api {
    static getDefaultConfig(journeyName: string): HeatmapFinding["config"] {
        return {
            count: 100,
            random: false,
            journeyName: journeyName,
            version: configVersionV2,
            showXAxisName: true,
            showYAxisName: true,
            showGrid: false,
            tooltipActionButtonText: "ENHANCE",
        };
    }

    static getPreviewFinding(journeyName: string): HeatmapFinding {
        let item = {
            type: "heatmap",
            content: {
                data: [
                    {
                        name: "var1",
                        value: ["A", "A", "B", "B"],
                    },
                    {
                        name: "var2",
                        value: ["1", "2", "1", "2"],
                    },
                    {
                        name: "value",
                        value: [4, 0, 10, 5.5],
                    },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
        };

        return item;
    }
    static async getData(
        finding: HeatmapFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<HeatmapFinding> {
        let { dataScope, selectedTable, conditions } = finding.config ?? {};
        let variables = finding.content.data.filter(
            (item) => item.variableIndex != null
        );
        if (variables.length !== 3) return Promise.resolve(finding);
        conditions = filterNonEmptyConditions(conditions ?? []);

        let variableIndices = new Set(
            variables.map((variable) => variable.variableIndex)
        );

        if (finding.content.time?.variableIndex != null) {
            variableIndices.add(finding.content.time.variableIndex);
        }

        let requestJson = {
            variable_indices: Array.from(variableIndices),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            conditions: conditionsToJson(conditions),
            data_table_idx: dataScope == null ? 0 : dataScope.value,
            module_id: moduleId ?? remoteModuleId,
            use_indices_in_current_levels: true,
        };

        return axios
            .post<{
                success: boolean;
                error_msg: string;
                current_levels: {
                    [key: string]: (number | string | null)[];
                };
                row_id: number[];
                count?: number;
            }>("/api/e/get_raw_data", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newData: HeatmapFinding["content"]["data"] = Array.from(
                        finding.content.data
                    );

                    for (let i = 0; i < 3; ++i) {
                        newData[i] = {
                            ...newData[i],
                            value:
                                response.data.current_levels[
                                    variables[i].variableIndex!
                                ],
                        };
                    }

                    let newFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: newData,
                            time: finding.content.time,
                        },
                    };

                    // Check wherther variables were renamed
                    updateOriginalNames(
                        newFinding.content.data,
                        dataScope.value,
                        moduleId
                    );

                    if (finding.content.time?.variableIndex != null) {
                        newFinding.content.time = {
                            ...finding.content.time,
                            value:
                                response.data.current_levels[
                                    finding.content.time.variableIndex
                                ],
                            uniqueValues: Array.from(
                                new Set(
                                    response.data.current_levels[
                                        finding.content.time.variableIndex
                                    ].filter(
                                        (item): item is string | number =>
                                            item != null
                                    )
                                )
                            ).sort((value1, value2) =>
                                typeof value1 === "number" &&
                                typeof value2 === "number"
                                    ? value1 - value2
                                    : String(value1).localeCompare(
                                          String(value2)
                                      )
                            ),
                        };
                        // Check wherther variables were renamed
                        updateOriginalName(
                            newFinding.content.time,
                            dataScope.value,
                            moduleId
                        );
                    }
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
