import React, { Component } from "react";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import { Field } from "modules/settings_page/common/FieldEditor";
import UpdateStatus from "modules/settings_page/common/UpdateStatus";
import { emailInvitationVerify } from "common/EmailInvitation";
import {
    PermanentLinkType,
    verifySelfSignupLinkApi,
    selfSignupRegisterApi,
    SelfSignupInfo,
    defaultCustomFields,
    registrationFields,
} from "common/ModulesApi";
import Instrumentation from "common/Instrumentation";
import axios from "common/ServerConnection";
import linkIdToUrl, {
    sharedModuleIdToUrl,
    tutorialIdToUrl,
} from "common/utilities/linkIdToUrl";
import styles from "./RegisterPage.module.css";
import cx from "classnames";
import mds from "./MaterialDashboard.module.css";
import Alert from "common/Alert";
import auth0 from "auth0-js";
import auth0Scope from "common/Auth0Scope";
import { detectIncognito } from "detect-incognito";

function toMdsModuleClassName(className: string) {
    let mdsStyles = className.split(" ").map((item) => mds[item]);
    return cx(mdsStyles);
}

interface Props {
    linkId: string;
    permanentLinkType?: PermanentLinkType;
}
interface State {
    focusField?: string;
    verified: boolean | undefined;
    email: string;
    status: UpdateStatus;
    error: string;
    value: Field[];
    selfSignupInfo: SelfSignupInfo | null;
    isTutorial: boolean;
    isPrivate: boolean;
}

export default class RegisterPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        document.children[0]!.setAttribute("style", "font-size:20px");
        let value: Field[] = [
            {
                name: "email",
                label: "Email",
                value: "",
                editable: true,
                required: true,
                hidden: false,
                add_mock_after: true,
            },
            {
                name: "password",
                label: "Password",
                value: "",
                editable: true,
                required: true,
                hidden: true,
                passwordStrength: true,
            },
            {
                name: "confirm_password",
                label: "Confirm password",
                value: "",
                editable: true,
                required: true,
                hidden: true,
                passwordStrength: true,
            },
        ];
        if (this.props.permanentLinkType == null) {
            /*
            value = value.concat([
                {
                    name: "phone_number",
                    label: "Phone Number",
                    value: "",
                    editable: true,
                    hidden: false,
                },
            ]);
            */
        }
        this.state = {
            selfSignupInfo: null,
            status: UpdateStatus.NotUploaded,
            error: "",
            email: "",
            verified: undefined,
            isTutorial: false,
            focusField: undefined,
            value: value,
            isPrivate: false,
        };
    }

    componentDidMount() {
        if (
            this.props.permanentLinkType !== PermanentLinkType.Vanilla &&
            this.props.permanentLinkType !== PermanentLinkType.Referral
        ) {
            if (this.props.permanentLinkType != null) {
                verifySelfSignupLinkApi(
                    this.props.linkId,
                    this.props.permanentLinkType
                )
                    .then((data) => {
                        let selfSignupInfo = {
                            logo: data.logo ?? null,
                            options:
                                data.options != null
                                    ? (JSON.parse(
                                          data?.options
                                      ) as SelfSignupInfo["options"])
                                    : null,
                        };
                        this.setState((state) => {
                            let value = Array.from(state.value);
                            let customFields =
                                selfSignupInfo.options?.customFields?.sort(
                                    (a, b) => {
                                        return (
                                            registrationFields.findIndex(
                                                (item) => item.name === a.name
                                            ) -
                                            registrationFields.findIndex(
                                                (item) => item.name === b.name
                                            )
                                        );
                                    }
                                ) ?? defaultCustomFields;
                            for (let customField of customFields) {
                                value.push({
                                    name: customField.name,
                                    label: customField.label,
                                    value: "",
                                    required: customField.required,
                                    editable: true,
                                    hidden: false,
                                });
                            }
                            return {
                                isTutorial: data.is_tutorial ?? false,
                                value: value,
                                verified: true,
                                selfSignupInfo: selfSignupInfo,
                            };
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            verified: false,
                            status: UpdateStatus.Error,
                            error: String(error),
                        });
                    });
            } else {
                emailInvitationVerify(this.props.linkId)
                    .then((email) => {
                        this.setState({
                            email: email,
                            verified: true,
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            verified: false,
                            status: UpdateStatus.Error,
                            error: String(error),
                        });
                    });
            }
        } else {
            this.setState((state) => {
                let value = Array.from(state.value);
                /*
                let customFields = defaultCustomFields;
                for (let customField of customFields) {
                    value.push({
                        name: customField.name,
                        label: customField.label,
                        value: "",
                        required: customField.required,
                        editable: true,
                        hidden: false,
                    });
                }
                */
                return {
                    isTutorial: false,
                    value: value,
                    verified: true,
                };
            });
        }
        detectIncognito().then((result) => {
            this.setState({ isPrivate: result.isPrivate });
        });
    }

    private login(user_name: string, password: string, redirectUri: string) {
        const pathNameAndSearch = redirectUri.split("?");
        const after = {
            pathname: new URL(redirectUri).pathname,
            search: "?" + decodeURIComponent(pathNameAndSearch[1] ?? ""),
        };
        const afterString = JSON.stringify(after);
        let redirectUriWithOrigin = `${
            window.location.origin
        }/redirect.html?after=${encodeURIComponent(afterString)}`;

        axios.get<any>("/api/auth0_config").then((response) => {
            let webAuth = new auth0.WebAuth({
                redirectUri: redirectUriWithOrigin,
                domain: response.data.AUTH0_DOMAIN!,
                clientID: response.data.AUTH0_CLIENT_ID!,
                audience: response.data.AUTH0_API_IDENTIFIER,
                scope: auth0Scope,
            });
            webAuth.login(
                {
                    realm: response.data.AUTH0_REALM,
                    username: user_name.toLowerCase(),
                    password: password,
                    responseType: "code",
                },
                (error) => {
                    if (error != null) {
                        this.setState({
                            status: UpdateStatus.Error,
                            error: error.description ?? error.error,
                        });
                    }
                }
            );
        });
    }

    private getRedirectUrl(
        moduleId?: string | number,
        entryId?: number
    ): string {
        if (
            moduleId != null &&
            this.props.permanentLinkType !== PermanentLinkType.Referral
        ) {
            if (this.props.permanentLinkType != null) {
                if (
                    this.props.permanentLinkType === PermanentLinkType.App ||
                    this.props.permanentLinkType === PermanentLinkType.Vanilla
                ) {
                    return linkIdToUrl(true, moduleId as string);
                } else {
                    if (this.state.isTutorial)
                        return tutorialIdToUrl(moduleId as number);
                    return sharedModuleIdToUrl(moduleId as number);
                }
            } else {
                if (typeof moduleId === "number")
                    return `${window.location.origin}/canvas.html?current_module_id=${moduleId}`;
                else {
                    return linkIdToUrl(true, moduleId);
                }
            }
        } else if (entryId != null) {
            return `${window.location.origin}/wizard.html?entry_id=${entryId}`;
        }
        return `${window.location.origin}/`;
    }

    private getExistingAccountUrl(): string {
        let url = new URL(window.location.origin);
        url.pathname = "/invitation.html";
        if (this.props.permanentLinkType != null) {
            if (this.props.permanentLinkType === PermanentLinkType.App) {
                url.searchParams.append("app_invitation_id", this.props.linkId);
            } else {
                url.searchParams.append(
                    "shared_module_invitation_id",
                    this.props.linkId
                );
            }
        } else {
            url.searchParams.append("module_invitation_id", this.props.linkId);
        }
        return url.toString();
    }

    private createNewUser(): void {
        this.setState({
            status: UpdateStatus.Loading,
            error: "",
        });
        let config: { [key: string]: string | string[] } = {};
        for (let field of this.state.value) {
            config[field.name] = field.value;
            if (field.required && !field.value) {
                this.setState({
                    error: `${field.label} is required`,
                    status: UpdateStatus.Error,
                });
                return;
            }
        }
        if (config["password"] !== config["confirm_password"]) {
            this.setState({
                error: "Passwords do not match",
                status: UpdateStatus.Error,
            });
            return;
        }

        if (this.props.permanentLinkType != null) {
            config.self_signup_link_id = this.props.linkId;
        } else {
            config.id = this.props.linkId;
        }
        if (this.state.email) {
            config["email"] = this.state.email;
        }

        if (this.props.permanentLinkType != null) {
            selfSignupRegisterApi(config, this.props.permanentLinkType)
                .then((linkId) => {
                    if (this.props.permanentLinkType !== undefined) {
                        Instrumentation.addRegistration(
                            this.props.permanentLinkType,
                            this.props.linkId
                        );
                    }
                    this.login(
                        config["email"] as string,
                        config["password"] as string,
                        this.getRedirectUrl(linkId)
                    );
                })
                .catch((error) => {
                    this.setState({
                        error: String(error),
                        status: UpdateStatus.Error,
                    });
                });
        } else {
            axios
                .post<{
                    success: boolean;
                    error_msg: string;
                    module_ids: number[];
                    link_ids: string[];
                    entry_ids: number[];
                }>("/api/e/email_invitation_register", {
                    id: config["id"],
                    password: config["password"],
                    email: config["email"],
                })
                .then((response) => {
                    if (response.data.success) {
                        let moduleIds = response.data.module_ids;
                        let linkIds = response.data.link_ids;
                        let entryIds = response.data.entry_ids;
                        this.login(
                            config["email"] as string,
                            config["password"] as string,
                            this.getRedirectUrl(
                                moduleIds.length !== 0
                                    ? moduleIds[0]
                                    : linkIds[0],
                                entryIds[0]
                            )
                        );
                    } else {
                        this.setState({
                            status: UpdateStatus.Error,
                            error: response.data.error_msg,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        error: String(error),
                        status: UpdateStatus.Error,
                    });
                });
        }
    }

    render() {
        if (this.state.verified == null) return null;
        if (this.state.verified === false) {
            return (
                <Alert
                    text={this.state.error}
                    className={"alert alert-danger"}
                />
            );
        }
        let backgroundStyle: React.CSSProperties = {
            backgroundImage: "url(/dist/img/register/splash2.png)",
        };
        if (this.state.selfSignupInfo?.options?.backgroundColor) {
            backgroundStyle = {
                background: this.state.selfSignupInfo?.options?.backgroundColor,
            };
        }
        if (this.state.selfSignupInfo?.options?.backgroundImage) {
            backgroundStyle = {
                backgroundImage: `url(${this.state.selfSignupInfo?.options?.backgroundImage})`,
            };
        }
        return (
            <main className={toMdsModuleClassName("main-content  mt-0")}>
                {this.state.isPrivate && (
                    <Alert
                        text={
                            "You are in incognito mode. To continue please check that 3rd party cookies are enabled"
                        }
                        className="alert alert-warning alert-dismissible"
                        style={{
                            position: "absolute",
                            top: 10,
                            left: "50%",
                            transform: "translate(-50%, 0)",
                            zIndex: 100,
                        }}
                    />
                )}
                <div
                    className={toMdsModuleClassName(
                        "page-header align-items-start min-vh-100"
                    )}
                    style={backgroundStyle}
                >
                    <span className={styles.bgGradient} />
                    <div className={toMdsModuleClassName("container my-auto")}>
                        <div className={toMdsModuleClassName("row")}>
                            <div
                                className={toMdsModuleClassName(
                                    "col-lg-4 col-md-8 mx-auto"
                                )}
                            >
                                <div
                                    className={toMdsModuleClassName(
                                        "card z-index-0 fadeIn3 fadeInBottom"
                                    )}
                                >
                                    <div
                                        className={cx(
                                            toMdsModuleClassName(
                                                "border-radius-lg"
                                            ),
                                            styles.logoContainer
                                        )}
                                        style={{
                                            background:
                                                this.state.selfSignupInfo
                                                    ?.options?.logoColor ||
                                                undefined,
                                        }}
                                    >
                                        <span className={styles.title}>
                                            {this.state.selfSignupInfo?.options
                                                ?.registerText || "Register"}
                                        </span>
                                        {this.state.selfSignupInfo?.logo && (
                                            <img
                                                className={styles.logo}
                                                src={
                                                    this.state.selfSignupInfo
                                                        ?.logo
                                                }
                                                alt=""
                                            />
                                        )}
                                    </div>
                                    <div
                                        className={toMdsModuleClassName(
                                            "card-body"
                                        )}
                                    >
                                        <form>
                                            {this.state.value.map(
                                                (item, index) => (
                                                    <div
                                                        key={index}
                                                        className={
                                                            styles.fieldsContainer
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                styles.fieldLabel
                                                            }
                                                        >
                                                            {`${item.label}${
                                                                !item.required
                                                                    ? " (optional)"
                                                                    : ""
                                                            }`}
                                                        </span>
                                                        <input
                                                            disabled={
                                                                !item.editable
                                                            }
                                                            type={
                                                                item.hidden
                                                                    ? "password"
                                                                    : "text"
                                                            }
                                                            className={
                                                                styles.field
                                                            }
                                                            placeholder={
                                                                item.name !==
                                                                "confirm_password"
                                                                    ? `Enter ${item.label.toLowerCase()}${
                                                                          !item.required
                                                                              ? " (optional)"
                                                                              : ""
                                                                      }`
                                                                    : `Confirm password`
                                                            }
                                                            onFocus={() => {
                                                                this.setState({
                                                                    focusField:
                                                                        item.name,
                                                                });
                                                            }}
                                                            onBlur={() => {
                                                                this.setState({
                                                                    focusField:
                                                                        undefined,
                                                                });
                                                            }}
                                                            value={item.value}
                                                            onChange={(e) => {
                                                                let currentValue =
                                                                    Array.from(
                                                                        this
                                                                            .state
                                                                            .value
                                                                    );
                                                                currentValue[
                                                                    index
                                                                ].value =
                                                                    e.target.value;
                                                                this.setState({
                                                                    value: currentValue,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            )}
                                            <div
                                                className={toMdsModuleClassName(
                                                    "text-center"
                                                )}
                                            >
                                                <button
                                                    type="button"
                                                    className={cx(
                                                        toMdsModuleClassName(
                                                            "btn w-100"
                                                        ),
                                                        styles.btnBlue
                                                    )}
                                                    style={{
                                                        background:
                                                            this.state
                                                                .selfSignupInfo
                                                                ?.options
                                                                ?.createButtonColor ||
                                                            undefined,
                                                    }}
                                                    onClick={() => {
                                                        // avoid double click
                                                        if (
                                                            this.state
                                                                .status ===
                                                            UpdateStatus.Loading
                                                        )
                                                            return;

                                                        this.createNewUser();
                                                    }}
                                                >
                                                    {this.state.status ===
                                                    UpdateStatus.Loading ? (
                                                        <>Signing up...</>
                                                    ) : (
                                                        <>Sign up</>
                                                    )}
                                                </button>
                                            </div>
                                            {this.state.status ===
                                                UpdateStatus.Error && (
                                                <p
                                                    className={cx(
                                                        toMdsModuleClassName(
                                                            "p text-sm mt-3"
                                                        ),
                                                        styles.errorMessage
                                                    )}
                                                    style={{ color: "#cc0000" }}
                                                >
                                                    {this.state.error}
                                                </p>
                                            )}
                                        </form>
                                    </div>
                                    <div className={styles.footer}>
                                        <span className={styles.footerText}>
                                            {"Already have an account?"}
                                            <a
                                                href={this.getExistingAccountUrl()}
                                                className={styles.footerLink}
                                            >
                                                Log In!
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
        // return (
        //     <ScaledPage>
        //         <div
        //             className="content-wrapper hide-scroll"
        //             style={{
        //                 marginLeft: 0,
        //                 background:
        //                     this.state.selfSignupInfo?.options
        //                         ?.backgroundColor || undefined,
        //             }}
        //         >
        //             <section className={cx("content", styles.sectionContainer)}>
        //                 <div className={styles.contentContainer}>
        //                     {this.state.verified && (
        //                         <div
        //                             className={cx(
        //                                 "big-title-span",
        //                                 styles.title
        //                             )}
        //                             style={{
        //                                 color:
        //                                     this.state.selfSignupInfo?.options
        //                                         ?.textColor || undefined,
        //                             }}
        //                         >
        //                             {"CREATE NEW USER"}
        //                         </div>
        //                     )}
        //                     {this.state.selfSignupInfo?.logo && (
        //                         <img
        //                             src={this.state.selfSignupInfo?.logo}
        //                             alt=""
        //                         />
        //                     )}
        //                     {this.state.selfSignupInfo?.options
        //                         ?.description && (
        //                         <div
        //                             className={styles.description}
        //                             style={{
        //                                 color:
        //                                     this.state.selfSignupInfo?.options
        //                                         ?.textColor || undefined,
        //                             }}
        //                         >
        //                             {
        //                                 this.state.selfSignupInfo?.options
        //                                     ?.description
        //                             }
        //                         </div>
        //                     )}
        //                     {this.state.verified && (
        //                         <>
        //                             <FieldEditor
        //                                 fieldsContainerClassName={
        //                                     styles.fieldsContainer
        //                                 }
        //                                 buttonsContainerClassName={
        //                                     styles.buttonsContainer
        //                                 }
        //                                 buttonColor={
        //                                     this.state.selfSignupInfo?.options
        //                                         ?.createButtonColor || undefined
        //                                 }
        //                                 inputFieldColor={
        //                                     this.state.selfSignupInfo?.options
        //                                         ?.inputFieldColor || undefined
        //                                 }
        //                                 inputFieldTextColor={
        //                                     this.state.selfSignupInfo?.options
        //                                         ?.inputFieldTextColor ||
        //                                     undefined
        //                                 }
        //                                 textColor={
        //                                     this.state.selfSignupInfo?.options
        //                                         ?.textColor || undefined
        //                                 }
        //                                 buttonTitle="Create"
        //                                 title=""
        //                                 value={this.state.value}
        //                                 onChange={(value) =>
        //                                     this.setState({ value: value })
        //                                 }
        //                                 onUpdate={() => this.createNewUser()}
        //                                 status={this.state.status}
        //                                 onStatusChange={(
        //                                     status: UpdateStatus
        //                                 ) => this.setState({ status: status })}
        //                                 errorMessage={this.state.error}
        //                             />
        //                             <div style={{ display: "flex" }}>
        //                                 <span
        //                                     className="regular-text"
        //                                     style={{ marginRight: 5 }}
        //                                 >
        //                                     Already have an account?&nbsp;
        //                                     <a
        //                                         href={this.getExistingAccountUrl()}
        //                                     >
        //                                         Log in here
        //                                     </a>
        //                                 </span>
        //                             </div>
        //                         </>
        //                     )}
        //                     {!this.state.verified &&
        //                         this.state.status === UpdateStatus.Error && (
        //                             <Alert
        //                                 text={this.state.error}
        //                                 className={"alert alert-danger"}
        //                                 onClosed={() => {
        //                                     this.setState({
        //                                         status:
        //                                             UpdateStatus.NotUploaded,
        //                                     });
        //                                 }}
        //                                 style={{
        //                                     marginTop: "20px",
        //                                     marginBottom: "0px",
        //                                 }}
        //                             />
        //                         )}
        //                 </div>
        //             </section>
        //         </div>
        //     </ScaledPage>
        // );
    }
}
