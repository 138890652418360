import { mainStyle } from "common/MainStyle";

export const dataScienceElementsStyle = {
    contentColor: mainStyle
        .getPropertyValue("--slide-data-processing-content-color")
        .trim(),
    primaryTextColor: mainStyle
        .getPropertyValue("--slide-data-processing-primary-text-color")
        .trim(),
    secondaryTextColor: mainStyle
        .getPropertyValue("--slide-data-processing-secondary-text-color")
        .trim(),
    boxShadow: mainStyle.getPropertyValue("--card-box-shadow").trim(),
    borderColor: mainStyle
        .getPropertyValue("--slide-data-processing-border-color")
        .trim(),
};