import { SurveyQuestionType } from "common/Canvas";
import { Type } from "common/InputData";

export const typeOptions: ReadonlyArray<{
    label: string;
    value: SurveyQuestionType;
    type: Type;
}> = [
    {
        label: "Text entry",
        value: SurveyQuestionType.Text,
        type: Type.Str,
    },
    {
        label: "Long text",
        value: SurveyQuestionType.LongText,
        type: Type.Str,
    },
    {
        label: "Dropdown",
        value: SurveyQuestionType.Dropdown,
        type: Type.Str,
    },
    {
        label: "Slider",
        value: SurveyQuestionType.Slider,
        type: Type.Float,
    },
];

export function shortenColumnName(
    name: string,
    variableNames: string[]
): string {
    if (name.length > 64) {
        let newName = `${name.slice(0, 61)}...`;
        let index = 1;
        while (variableNames.includes(newName)) {
            let stringIndex = index.toString();
            newName = `${name.slice(
                0,
                59 - stringIndex.length
            )}...(${stringIndex})`;
        }
        return newName;
    } else {
        return name;
    }
}
