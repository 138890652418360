import React from "react";

import { CanvasElement, CanvasTextBox } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import { DataTableInput } from "modules/canvas_page/DataTableInputSelector";
import SharedBoxesStore from "modules/canvas_page/SharedBoxesStore";
import InputsSelector from "modules/canvas_page/RevampedInputsSelector";
import GlobalInputsSelector from "modules/canvas_page/RevampedGlobalInputsSelector";
import DataTableInputSelector from "modules/canvas_page/RevampedDataTableInputSelector";

import styles from "./InputsConfig.module.css";

interface Props {
  canvasId: number;
  node: CanvasElement | CanvasTextBox;
  currentModuleId?: number;
  nodeLinkOptions: NodeLinkOption[];
  dataTableInput: DataTableInput[];
  onChange: (changes: Partial<CanvasElement>, commit?: boolean) => void;
  onChangeDataTableInput: (newValue: DataTableInput[]) => void;
}

function InputsConfig({ canvasId, node, currentModuleId, nodeLinkOptions, dataTableInput,
  onChange, onChangeDataTableInput,
}: Props) {
  return (
    <div className={styles.root}>
      <h6 className={styles.title}>Inputs</h6>
      <div className={styles.inputDropdown}>
        <InputsSelector
          allSharedBoxes={SharedBoxesStore.sharedBoxes(canvasId)}
          value={node}
          onChange={onChange}
        />
      </div>
      <div className={styles.inputDropdown}>
        <GlobalInputsSelector
          value={node}
          onChange={onChange}
        />
      </div>
      <div className={styles.inputSelector}></div>
      <DataTableInputSelector
        nodeLinkOptions={nodeLinkOptions}
        value={dataTableInput}
        onChange={onChangeDataTableInput}
        currentModuleId={currentModuleId}
      />
    </div>
  );
}

export default InputsConfig;
