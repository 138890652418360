import React from "react";
import { AiCopilotFinding } from "common/Finding";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./main.css";
import AiChatbot from "common/ai/chatbot";

interface IProps {
    finding: AiCopilotFinding;
    onNewFinding?: (finding: AiCopilotFinding, updateData?: boolean) => void;
    preview?: boolean;
    selected: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
    width: number;
    height: number;
    scale: number;
}

function MainComponent(props: IProps) {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <AiChatbot finding={props.finding} />
        </div>
    );
}

export { MainComponent };
