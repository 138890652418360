import axios from "common/ServerConnection";

export interface AppInfo {
    module_id: number;
    link_id: string;
    title: string;
    paywall?: {
        title: string;
        client: string;
        payment_type: string;
        price: number;
        revenue: number;
    };
}

export async function listAppsApi(): Promise<AppInfo[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            apps?: AppInfo[];
        }>("/api/module_user_share_link_app_list")
        .then((response) => {
            if (response.data.success && response.data.apps != null) {
                return response.data.apps;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function listAppsForUserApi(userName: string): Promise<AppInfo[]> {
    let jsonRequest = {
        user_name: userName,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            apps?: AppInfo[];
        }>("/api/module_user_share_link_app_list_for_user", jsonRequest)
        .then((response) => {
            if (response.data.success && response.data.apps != null) {
                return response.data.apps;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
