import React, { useEffect, useState } from "react";
import styles from "./CategoriesBar.module.css";
import Tab from "./Tab";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Category, getCategoriesApi } from "common/CategoriesApi";
import { CategoryData } from "state/models/Presentation";
import cx from "classnames";
import {
    checkScrollPosition,
    gatherScrollData,
    ScrollInfo,
    useHorizontalScroll,
} from "common/utilities/scrollUtils";
import { mobileAndTabletBreakpoint } from "common/utilities/UIResponsiveManager";
import { ReactComponent as CategoriesScrollArrow } from "icons/categories-scroll-arrow.svg";

interface Props {
    category: CategoryData;
    setSelectedCategory: (category: CategoryData) => void;
}

function CategoriesBar({ category, setSelectedCategory }: Props) {
    const [scrollInfo, setScrollInfo] = useState<ScrollInfo>({
        scrollWidth: 0,
        offsetWidth: 0,
        scrollLeft: 0,
        inTheBeginning: true,
        inTheEnd: false,
    });
    const scrollRef = useHorizontalScroll((info: ScrollInfo) => {
        setScrollInfo(info);
    });
    const [data, setData] = useState<Category[]>([]);
    const settings = {
        className: "slider variable-width",
        focusOnSelect: false,
        arrows: false,
        infinite: false,
        slidesToScroll: 1,
        speed: 500,
        variableWidth: true,
        swipeToSlide: true,
    };

    async function fetchCategories() {
        const categories = await getCategoriesApi();
        setData(categories);
        if (categories.length !== 0) {
            setSelectedCategory(categories[0]);
        }
    }

    useEffect(() => {
        fetchCategories();
    }, []);

    function scrollArrowOnClick(scrollRange: number) {
        scrollRef.current.scrollBy({
            top: 0,
            left: scrollRange,
            behavior: "smooth",
        });
        setTimeout(() => {
            const scrollData = gatherScrollData(scrollRef.current);
            setScrollInfo({
                ...scrollData,
                ...checkScrollPosition(scrollData),
            });
        }, 400);
    }

    return (
        <div className={styles.content}>
            {!mobileAndTabletBreakpoint() && (
                <div
                    ref={scrollRef}
                    className={cx(styles.scrollableTabsWrapper)}
                >
                    {!scrollInfo.inTheBeginning && (
                        <button
                            onClick={() => scrollArrowOnClick(-500)}
                            className={styles.scrollArrow}
                        >
                            <CategoriesScrollArrow />
                        </button>
                    )}
                    {data.map((item, idx) => {
                        return (
                            <Tab
                                key={idx}
                                category={item}
                                setSelectedCategory={setSelectedCategory}
                                isActive={category.id === item.id}
                            />
                        );
                    })}
                    {!scrollInfo.inTheEnd && (
                        <button
                            onClick={() => scrollArrowOnClick(500)}
                            className={cx(
                                styles.scrollArrow,
                                styles.scrollArrowRight
                            )}
                        >
                            <CategoriesScrollArrow />
                        </button>
                    )}
                </div>
            )}

            {mobileAndTabletBreakpoint() && (
                <Slider {...settings}>
                    {data.map((item, idx) => {
                        return (
                            <Tab
                                key={idx}
                                category={item}
                                setSelectedCategory={setSelectedCategory}
                                isActive={category.id === item.id}
                            />
                        );
                    })}
                </Slider>
            )}
        </div>
    );
}

export default CategoriesBar;
