import React from "react";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";
import Tables, { TableOption } from "common/Tables";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import {
    dataScienceSelectStyles,
    getCustomSelectStyleForDataSection,
    leftAlignAndScrollBarCSS,
    Styles,
} from "common/SelectStyles";
import { Permission } from "common/Permissions";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";
// import { ReactComponent as CrossIcon } from "icons/popups/cross.svg";
import { ReactComponent as UnlinkIcon } from "icons/canvas/exploration/unlink.svg";

interface Props {
    dataHubMode?: boolean;
    dataScopeOption: DataScopeOption | null;
    dataScopePlaceholder?: string;
    tableOption: TableOption | null;
    tableOptionPlaceholder?: string;
    tableOptionClearable?: boolean;
    needWritePermissions: boolean;
    maxHeight?: number;
    width?: number | string;
    hideTable?: boolean;
    onChange: (
        dataScopeOption: DataScopeOption | null,
        tableOption: TableOption | null
    ) => void;
    currentModuleId?: number;
    tab?: string;
    ChangeDataset?: any;
    onFocus?: any;
    onReset?: () => void;
}

const DataScopeAndTableSelectorView = observer((props: Props) => {
    let dataHubStyles: Styles | undefined = props.dataHubMode
        ? {
              ...getCustomSelectStyleForDataSection(14, false),
              ...leftAlignAndScrollBarCSS,
              container: (base) => ({
                  ...base,
                  width: "100%",
                  height: "35px",
                  justifyContent: "left",
              }),
          }
        : undefined;
    let dataScopes =
        props.currentModuleId != null
            ? DataScopesForModules(props.currentModuleId)
            : DataScopes;

    return (
        <div
            className="flex-simple-columns"
            style={{
                maxHeight: props.maxHeight,
                height: props.maxHeight,
                marginLeft: props.dataHubMode ? "0px" : "90px",
                display: "flex",
            }}
        >
            <div
                className="flex-simple-columns element"
                style={{
                    overflow: props.dataHubMode ? "visible" : "auto",
                    width: props.width ?? "50%",
                    display: "flex",
                }}
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                }}
                onMouseDown={(evt: any) => {
                    evt.stopPropagation();
                }}
            >
                <Select
                    // menuPortalTarget={document.body}
                    // menuShouldBlockScroll={true}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    placeholder={
                        props.dataScopePlaceholder ?? "Select data set"
                    }
                    styles={
                        dataHubStyles ?? {
                            ...dataScienceSelectStyles,
                            ...leftAlignAndScrollBarCSS,
                            container: (base) => ({
                                ...base,
                                width: "100%",
                                height: "35px",
                                justifyContent: "left",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 51,
                            }),
                        }
                    }
                    options={dataScopes.dataScopesOptions.filter((option) => {
                        if (props.needWritePermissions)
                            return (
                                option.permissionType === Permission.ReadWrite
                            );
                        else
                            return option.permissionType >= Permission.ReadOnly;
                    })}
                    onChange={(newValue) => {
                        props.onChange(
                            newValue as DataScopeOption,
                            Tables(
                                (newValue as DataScopeOption).value,
                                props.currentModuleId ?? remoteModuleId
                            ).tableToOption()
                        );
                    }}
                    onFocus={() => {
                        props.onFocus && props.onFocus();
                    }}
                    value={props.dataScopeOption}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                />
                {!props.dataHubMode && (
                    <div
                        style={{
                            minHeight: "2px",
                            backgroundColor:
                                dataScienceElementsStyle.secondaryTextColor,
                        }}
                    />
                )}
            </div>

            {(props.dataScopeOption || props.dataHubMode) &&
                !props.hideTable &&
                Tables(
                    props.dataScopeOption?.value
                        ? props.dataScopeOption.value
                        : "",
                    props.currentModuleId ?? remoteModuleId
                ).rawAndAggregateTableOptions.length > 1 && (
                    <>
                        <div
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                            style={
                                props.dataHubMode
                                    ? { width: "46%", marginLeft: 20 }
                                    : undefined
                            }
                        >
                            <Select
                                isDisabled={props.dataScopeOption == null}
                                isClearable={props.tableOptionClearable}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={
                                    props.tableOptionPlaceholder ??
                                    "Select table"
                                }
                                styles={
                                    dataHubStyles ?? {
                                        ...dataScienceSelectStyles,
                                        ...leftAlignAndScrollBarCSS,
                                        container: (base) => ({
                                            ...base,
                                            marginTop: "20px",
                                            width: "100%",
                                            height: "35px",
                                        }),
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 51,
                                        }),
                                    }
                                }
                                options={
                                    props.dataScopeOption == null
                                        ? []
                                        : Tables(
                                              props.dataScopeOption.value,
                                              props.currentModuleId ??
                                                  remoteModuleId
                                          ).rawAndAggregateTableOptions
                                }
                                onChange={(newValue) => {
                                    props.onChange(
                                        props.dataScopeOption,
                                        newValue as TableOption
                                    );
                                }}
                                value={props.tableOption}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        {!props.dataHubMode && (
                            <div
                                style={{
                                    minHeight: "2px",
                                    backgroundColor:
                                        dataScienceElementsStyle.secondaryTextColor,
                                }}
                            />
                        )}
                    </>
                )}

            {props.tab === "export" && (
                <div>
                    <button
                        className={"acceptBtn"}
                        style={{ paddingBlock: 7, marginLeft: 18 }}
                        type="button"
                        disabled={
                            props.dataScopeOption == null ||
                            props.tableOption == null
                        }
                        onClick={() => {
                            if (props.dataScopeOption?.value != null) {
                                DataScopes.DatasetPreview(
                                    props.dataScopeOption?.value,
                                    undefined,
                                    props.tableOption ?? undefined
                                );
                            }
                        }}
                    >
                        {"Preview"}
                    </button>
                </div>
            )}

            {props.tab === "Reshape" && props.dataScopeOption != null && (
                <button
                    style={{
                        marginLeft: 4,
                        border: "none",
                        backgroundColor: "transparent",
                        padding: 9,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 50,
                        height: 35,
                        marginTop: 1,
                    }}
                    onClick={() => {
                        props.onReset!();
                        DataScopes.previewState = { headers: null, body: null };
                    }}
                >
                    <UnlinkIcon />
                </button>
            )}
        </div>
    );
});

export default DataScopeAndTableSelectorView;
