import Variables from "common/Variables";
import axios from "common/ServerConnection";

class Api {
	static getProjectedVariables(clust) {
		let currentVariables = {};
		clust.forEach((clustGroup) => {
			clustGroup.forEach((item) => {
				currentVariables[item.variable] = [item.projected];
			});
		});
		return currentVariables;
	}
	static getOptimalVariables(clust) {
		let currentVariables = {};
		clust.forEach((clustGroup) => {
			clustGroup.forEach((item) => {
				currentVariables[item.variable] = [item.optimal];
			});
		});
		return currentVariables;
	}

	static getFinding(props) {
		let { clust, outcome, k_count, count, config } = props;
		let item = {
			type: "8",
			content: {
				clust: clust,
				outcome: outcome,
				count: count,
				k_count: k_count,
			},
			config: config,
		};
		return item;
	}

	static getData(config, previousFinding) {
		let {
			dataScope,
			targetVariable,
			modelId,
			leftAdditionalValue,
			selectedTable,
		} = config;
		let dataTableIdx =
			typeof dataScope === "undefined" ? 0 : dataScope.value;
		let requestJson = {
			data_table_idx: dataTableIdx,
			table: selectedTable.value,
			model_id: modelId,
			condition_id: selectedTable.condition_id,
			panel_value: leftAdditionalValue
				? leftAdditionalValue.value
				: undefined,
		};
		if (previousFinding) {
			requestJson.count = previousFinding.content.count;
			requestJson.k_count = previousFinding.content.k_count;
			requestJson.current_values = Api.getProjectedVariables(
				previousFinding.content.clust
			);
			requestJson.optimal_values = Api.getOptimalVariables(
				previousFinding.content.clust
			);
		}
		return axios
			.post("/api/predict_outcome", requestJson, null)
			.then((response) => {
				if (response.data.success) {
					let clust = [];
					if (!previousFinding) {
						let clustItem = Object.keys(
							response.data.current_values
						).map((item) => ({
							variable: item,
							units: Variables(dataTableIdx).getUnits(item),
							name: item.toUpperCase(),
							projected:
								response.data.current_values[item][0] || 0,
							optimal: response.data.optimal_values[item][0] || 0,
						}));

						clust = [clustItem];
					} else clust = previousFinding.content.clust;
					let outcome = {
						variable: targetVariable,
						name: targetVariable.toUpperCase(),
						projected: response.data.current_prediction[0] || 0,
						optimal: previousFinding
							? previousFinding.content.outcome.optimal
							: response.data.optimal_prediction[0] || 0,
						units: Variables(dataTableIdx).getUnits(targetVariable),
					};
					let k_count = !previousFinding
						? response.data.k_count
						: previousFinding.content.k_count;
					let count = !previousFinding
						? response.data.count
						: previousFinding.content.count;
					return Promise.resolve(
						Api.getFinding({
							outcome: outcome,
							config: config,
							count: count,
							k_count: k_count,
							clust: clust,
						})
					);
				} else {
					return Promise.reject(response.data.error_msg);
				}
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	}
}

export { Api };