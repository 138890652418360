import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  toggleCustomInlineStyle,
  getSelectionCustomInlineStyle,
  keepInlineStyles,
} from 'common/draft_utils/patchedInlineUtils';

import LayoutComponent from './Component';

export default class LetterSpacing extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    editorState: PropTypes.object,
    modalHandler: PropTypes.object,
    config: PropTypes.object,
    translations: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { editorState, modalHandler } = props;
    this.state = {
      expanded: undefined,
      currentLetterSpacing: editorState
        ? getSelectionCustomInlineStyle(editorState, ['LETTERSPACING']).LETTERSPACING
        : undefined,
    };
    modalHandler.registerCallBack(this.expandCollapse);
  }

  componentDidUpdate(prevProps) {
    const { editorState } = this.props;
    if (editorState && editorState !== prevProps.editorState) {
      this.setState({
        currentLetterSpacing: getSelectionCustomInlineStyle(editorState, [
          'LETTERSPACING',
        ]).LETTERSPACING,
      });
    }
  }

  componentWillUnmount() {
    const { modalHandler } = this.props;
    modalHandler.deregisterCallBack(this.expandCollapse);
  }

  onExpandEvent = () => {
    const expanded = !this.state.expanded;
    this.signalExpanded = expanded;
    this.setState({
      expanded,
    });
  };

  expandCollapse = (event, isEditorClicked) => {
    this.setState({
      expanded: isEditorClicked ? false : this.signalExpanded,
    });
    this.signalExpanded = false;
  };

  doExpand = () => {
    this.setState({
      expanded: true,
    });
  };

  doCollapse = () => {
    this.setState({
      expanded: false,
    });
    this.signalExpanded = false;
  };

  toggleLetterSpacing = letterSpacing => {
    const { editorState, onChange } = this.props;
    let newState = toggleCustomInlineStyle(editorState, 'letterSpacing', `${letterSpacing}px`);
    newState = keepInlineStyles(
      newState,
      editorState
    );
    if (newState) {
      onChange(newState, 'LETTERSPACING');
    }
  };

  render() {
    const { config, translations } = this.props;
    const { expanded, currentLetterSpacing } = this.state;
    const LetterSpacingComponent = config.component || LayoutComponent;
    const letterSpacing = currentLetterSpacing && Number(currentLetterSpacing.substring(14, currentLetterSpacing.length - 2));
    return (
      <LetterSpacingComponent
        config={config}
        translations={translations}
        currentState={{ letterSpacing }}
        onChange={this.toggleLetterSpacing}
        expanded={expanded}
        onExpandEvent={this.onExpandEvent}
        doExpand={this.doExpand}
        doCollapse={this.doCollapse}
      />
    );
  }
}
