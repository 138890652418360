import React from "react";
import { mainStyle } from "./MainStyle";
import { Button } from "react-bootstrap";

function JourneyCard(props) {
	return (
		<div
			className="dashboard-rect-journey"
			style={{
				width: 407,
				height: 71,
				marginTop: 10,
				marginBottom: 10,
				cursor: "pointer",
			}}
			onClick={props.onClick}
		>
			<div
				className="my-row"
				style={{
					alignItems: "center",
					justifyContent: "space-between",
					height: "100%",
				}}
			>
				<div className="my-row" style={{ alignItems: "center" }}>
					<img
						alt=""
						src={props.imageSource}
						style={{ margin: 30 }}
					/>
					<div className="flex-simple-column">
						<span
							style={{
								fontFamily: "Roboto",
								fontSize: mainStyle.getPropertyValue(
									"--path-header-size"
								),
								color: mainStyle.getPropertyValue(
									"--exploration-primary-text-color"
								),
								fontWeight: 300,
							}}
						>
							{props.title}
						</span>
						<span
							style={{
								marginTop: 10,
								fontFamily: "Roboto",
								fontSize: "10px",
								color: mainStyle.getPropertyValue(
									"--exploration-tertiary-text-color"
								),
								fontWeight: 300,
							}}
						>
							{props.subtitle}
						</span>
					</div>
				</div>
				<img
					alt=""
					src="/dist/img/data-exploration/chevron.png"
					style={{ marginRight: 10 }}
				/>
			</div>
		</div>
	);
}

function JourneyRecipesCard(props) {
	return (
		<div
			className="dashboard-rect-journey"
			style={{
				width: 407,
				height: 71,
				marginTop: 10,
				marginBottom: 10,
				cursor: "pointer",
			}}
			onClick={props.onClick}
		>
			<div
				className="my-row"
				style={{
					alignItems: "center",
					justifyContent: "space-between",
					height: "100%",
				}}
			>
				<div className="my-row" style={{ alignItems: "center" }}>
					<img
						alt=""
						src={props.imageSource}
						style={{ margin: 30 }}
					/>
					<div className="flex-simple-column">
						<input
							className="like-path-header"
							value={props.title}
							onClick={(evt) => {
								evt.stopPropagation();
							}}
							onChange={(evt) => {
								let newItem = Object.assign({}, props.item);
								newItem.title = evt.target.value;
								props.onEdit(newItem, false);
							}}
							onBlur={(evt) => {
								let newItem = Object.assign({}, props.item);
								newItem.title = evt.target.value;
								props.onEdit(newItem, true);
							}}
							onKeyDown={(evt) => {
								if (evt.which === 13 && !evt.shiftKey) {
									evt.preventDefault();
									let newItem = Object.assign({}, props.item);
									newItem.title = evt.target.value;
									props.onEdit(newItem, true);
								}
							}}
						/>
						<span
							style={{
								marginTop: 10,
								fontFamily: "Roboto",
								fontSize: "10px",
								color: mainStyle.getPropertyValue(
									"--tertiary-text-color"
								),
								fontWeight: 300,
							}}
						>
							{props.subtitle}
						</span>
					</div>
				</div>
				<div className="my-row" style={{ alignItems: "center" }}>
					<Button
						style={{
							marginRight: 10,
							alignSelf: "center",
						}}
						className="close"
						onClick={(evt) => {
							evt.stopPropagation();
							props.onDelete(props.item);
						}}
					>
						<span className="sections-title-span">&times;</span>
					</Button>
					<Button
						style={{
							marginRight: 10,
							alignSelf: "center",
						}}
						className="close"
						onClick={(evt) => {
							evt.stopPropagation();
							props.onCopy(props.item);
						}}
					>
						<span className="sections-title-span">+</span>
					</Button>
					<img
						alt=""
						src="/dist/img/data-exploration/chevron.png"
						style={{ marginRight: 10 }}
					/>
				</div>
			</div>
		</div>
	);
}

export default JourneyCard;
export { JourneyRecipesCard };