import React, { Component, CSSProperties } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { observer } from "mobx-react";
import Select from "react-select";
import cx from "classnames";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Tables, { TableOption } from "common/Tables";
import Variables from "common/Variables";
import { Condition, ConditionsSelector } from "common/Conditions";
import { CanvasDataTableInputDetails } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import { mainStyle } from "common/MainStyle";
import aggregationOptions from "../AggregationOptions";
import { DataTableInput, VariableInfo } from "../DataTableInputSelector";
import remoteModuleId from "common/remoteModuleId";
import StringUtils from "common/utilities/StringUtils";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import styles from "./RevampedDataTableInputSelector.module.css";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";

export function toCanvasDataTableInputDetails(
    dataTableInput: DataTableInput[]
): CanvasDataTableInputDetails[] {
    let result: CanvasDataTableInputDetails[] = [];
    for (let input of dataTableInput) {
        if (input.dataScope != null && input.table != null) {
            let variables: CanvasDataTableInputDetails["variables"] =
                input.variables.filter(
                    (variableInfo: VariableInfo) => variableInfo.index != null
                ) as CanvasDataTableInputDetails["variables"];
            if (variables.length !== 0) {
                let conditions: Condition[] = input.conditions.filter(
                    (condition) =>
                        condition.variable != null &&
                        condition.operation != null &&
                        condition.value != null
                );
                result.push({
                    data_table_idx: input.dataScope.value,
                    optimized: input.table.optimized,
                    table: input.table.value,
                    condition_id: input.table.condition_id,
                    conditions: conditions,
                    update_time: 0,
                    variables: variables,
                    zero_if_no_data: input.zeroIfNoData,
                });
            }
        }
    }
    return result;
}

export async function fromCanvasDataTableInputDetails(
    dataTableInputDetails: CanvasDataTableInputDetails[],
    currentModuleId?: number
): Promise<DataTableInput[]> {
    let dataScopes =
        currentModuleId != null
            ? DataScopesForModules(currentModuleId)
            : DataScopes;

    let result: DataTableInput[] = [];
    let processedDataTableIdx: Set<number | string> = new Set();
    for (let item of dataTableInputDetails) {
        if (item.data_table_idx != null) {
            if (!processedDataTableIdx.has(item.data_table_idx)) {
                await Tables(
                    item.data_table_idx,
                    currentModuleId ?? remoteModuleId
                ).update(currentModuleId ?? remoteModuleId);
                await Variables(
                    item.data_table_idx,
                    currentModuleId ?? remoteModuleId
                ).update(currentModuleId ?? remoteModuleId);
                processedDataTableIdx.add(item.data_table_idx);
            }
            const table: TableOption | null =
                (item.optimized
                    ? Tables(
                          item.data_table_idx,
                          currentModuleId ?? remoteModuleId
                      ).rawAndOptimizedTableOptions
                    : Tables(
                          item.data_table_idx,
                          currentModuleId ?? remoteModuleId
                      ).rawAndAggregateTableOptions
                ).find(
                    (option) =>
                        option.value.join("_") === item.table.join("_") &&
                        option.condition_id === item.condition_id
                ) ?? null;
            let conditions: Condition[] =
                item.conditions.length !== 0
                    ? item.conditions
                    : ConditionsSelector.defaultValue;
            result.push({
                dataScope:
                    dataScopes.dataScopesOptions.find(
                        (option) => option.value === item.data_table_idx
                    ) ?? null,
                table: table,
                conditions: conditions,
                variables: item.variables.map((variable): VariableInfo => {
                    if (variable.name != null) {
                        const variableIndex: number = Variables(
                            item.data_table_idx,
                            currentModuleId ?? remoteModuleId
                        ).variableNames.indexOf(variable.name);
                        return {
                            index: variableIndex < 0 ? 0 : variableIndex,
                            aggregation: variable.aggregation,
                            alias: variable.alias,
                        };
                    } else {
                        return variable;
                    }
                }),
                zeroIfNoData: item.zero_if_no_data,
            });
        } else {
            result.push(emptyInput);
        }
    }
    return result;
}

interface Props {
    style?: CSSProperties;
    value: DataTableInput[];
    onChange: (dataTableInput: DataTableInput[]) => void;
    nodeLinkOptions: NodeLinkOption[];
    currentModuleId?: number;
}

interface State {
    collapsedRows: any;
}

const emptyVariables: VariableInfo[] = [
    {
        index: null,
        aggregation: "Mean",
        alias: "",
    },
];

const emptyInput: DataTableInput = {
    dataScope: null,
    table: null,
    conditions: ConditionsSelector.defaultValue,
    variables: emptyVariables,
};

const DataTableInputSelector = observer(
    class DataTableInputSelector extends Component<Props, State> {
        constructor(props: Props) {
            super(props);

            this.state = {
                collapsedRows: {},
            };
        }

        private renderVariableSelectorError(
            variableInfo: VariableInfo,
            aliasCounts: { [key: string]: number }
        ): JSX.Element | null {
            let error: string | null = null;
            if (variableInfo.index != null) {
                if (variableInfo.alias.length === 0)
                    error = "Alias can't be empty";
                else if (aliasCounts[variableInfo.alias] > 1) {
                    error = "Aliases cannot repeat";
                }
            }

            if (error != null) {
                return (
                    <span
                        style={{
                            flex: 1,
                            marginLeft: 10,
                            textAlign: "left",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: 14,
                        }}
                    >
                        <img
                            src="/dist/img/error.png"
                            alt=""
                            style={{
                                marginRight: 5,
                            }}
                        />
                        {error}
                    </span>
                );
            } else {
                return null;
            }
        }

        private renderVariableSelector(
            value: DataTableInput[],
            item: DataTableInput,
            index: number
        ): JSX.Element[] {
            if (item.dataScope == null && item.table == null) return [];
            const allVariableInfo: VariableInfo[] =
                item.variables.length !== 0 ? item.variables : emptyVariables;
            const aliasCounts: { [key: string]: number } = {};
            for (let variableInfo of allVariableInfo) {
                aliasCounts[variableInfo.alias] =
                    (aliasCounts[variableInfo.alias] ?? 0) + 1;
            }

            return allVariableInfo.map(
                (variableInfo: VariableInfo, variableIndex: number) => (
                    <React.Fragment key={variableIndex}>
                        <div>
                            <div className={styles.selectTitle}>
                                {variableIndex === 0 ? "Select" : ""}
                            </div>
                            <div className={styles.selectExpression}>
                                <Select
                                    styles={{
                                        container: (provided) => ({
                                            ...provided,
                                            flex: "1 1 auto",
                                            marginRight: 5,
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: "4px",
                                            minWidth: "140px",
                                        }),
                                        indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none",
                                        }),
                                    }}
                                    options={aggregationOptions}
                                    value={{
                                        label: variableInfo.aggregation,
                                        value: variableInfo.aggregation,
                                    }}
                                    onChange={(option) => {
                                        const newValue: DataTableInput[] =
                                            Array.from(value);
                                        const newVariables: VariableInfo[] =
                                            Array.from(
                                                newValue[index].variables
                                            );
                                        let aggregation = (
                                            option as {
                                                label: string;
                                                value: string;
                                            }
                                        ).value;
                                        let currentVariableIndex =
                                            newVariables[variableIndex].index;
                                        let variableName = "";
                                        if (currentVariableIndex != null) {
                                            variableName = Variables(
                                                item.dataScope!.value,
                                                this.props.currentModuleId ??
                                                    remoteModuleId
                                            ).variableNames[
                                                currentVariableIndex
                                            ];
                                        }
                                        newVariables[variableIndex] = {
                                            ...newVariables[variableIndex],
                                            aggregation: aggregation,
                                            alias: StringUtils.normalizeString(
                                                `${aggregation} ${
                                                    variableName ?? ""
                                                }`
                                            ).toUpperCase(),
                                        };
                                        newValue[index] = {
                                            ...newValue[index],
                                            variables: newVariables,
                                        };
                                        this.props.onChange(newValue);
                                    }}
                                />
                                <Select
                                    placeholder=""
                                    styles={{
                                        container: (provided) => ({
                                            ...provided,
                                            flex: "1 1 auto",
                                            marginRight: 5,
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: "4px",
                                            minWidth: "140px",
                                        }),
                                        indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none",
                                        }),
                                    }}
                                    options={Variables(
                                        item.dataScope!.value,
                                        this.props.currentModuleId ??
                                            remoteModuleId
                                    ).variableNames.map((name, index) => ({
                                        label: name,
                                        value: index,
                                    }))}
                                    value={{
                                        label:
                                            variableInfo.index != null
                                                ? Variables(
                                                      item.dataScope!.value,
                                                      this.props
                                                          .currentModuleId ??
                                                          remoteModuleId
                                                  ).variableNames[
                                                      variableInfo.index
                                                  ]
                                                : "",
                                        value: variableInfo.index!,
                                    }}
                                    onChange={(option) => {
                                        const newValue: DataTableInput[] =
                                            Array.from(value);
                                        const newVariables: VariableInfo[] =
                                            Array.from(
                                                newValue[index].variables
                                            );
                                        const newOption = option as {
                                            label: string;
                                            value: number;
                                        };
                                        const newIndex = newOption.value;
                                        newVariables[variableIndex] = {
                                            ...newVariables[variableIndex],
                                            index: newIndex,
                                            alias: StringUtils.normalizeString(
                                                `${newVariables[variableIndex].aggregation} ${newOption.label}`
                                            ).toUpperCase(),
                                        };
                                        newValue[index] = {
                                            ...newValue[index],
                                            variables: newVariables,
                                        };
                                        this.props.onChange(newValue);
                                    }}
                                />
                                <span className={styles.selectLabel}>as</span>
                                <input
                                    className={styles.selectInput}
                                    style={{
                                        width: 100,
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        height: "38px",
                                        textAlign: "left",
                                    }}
                                    placeholder="alias"
                                    onChange={(e) => {
                                        const newValue: DataTableInput[] =
                                            Array.from(value);
                                        const newVariables: VariableInfo[] =
                                            Array.from(
                                                newValue[index].variables
                                            );
                                        const newAlias = e.target.value;
                                        newVariables[variableIndex] = {
                                            ...newVariables[variableIndex],
                                            alias: newAlias.toUpperCase(),
                                        };
                                        newValue[index] = {
                                            ...newValue[index],
                                            variables: newVariables,
                                        };
                                        this.props.onChange(newValue);
                                    }}
                                    value={variableInfo.alias}
                                />
                                <div
                                    className="flex-simple-column"
                                    style={{ marginLeft: 5 }}
                                >
                                    <Button
                                        className="btn-small-like-select"
                                        style={{
                                            width: "19px",
                                            height: "19px",
                                            background: "transparent",
                                        }}
                                        onClick={() => {
                                            const newValue: DataTableInput[] =
                                                Array.from(value);
                                            const newVariables: VariableInfo[] =
                                                Array.from(
                                                    newValue[index].variables
                                                );
                                            newVariables.push(
                                                emptyVariables[0]
                                            );
                                            newValue[index] = {
                                                ...newValue[index],
                                                variables: newVariables,
                                            };
                                            this.props.onChange(newValue);
                                        }}
                                    >
                                        {"\uFF0B" /* plus */}
                                    </Button>
                                    <Button
                                        className="btn-small-like-select"
                                        style={{
                                            width: "19px",
                                            height: "19px",
                                            background: "transparent",
                                        }}
                                        onClick={() => {
                                            const newValue: DataTableInput[] =
                                                Array.from(value);
                                            const newVariables: VariableInfo[] =
                                                Array.from(
                                                    newValue[index].variables
                                                );
                                            newVariables.splice(
                                                variableIndex,
                                                1
                                            );
                                            newValue[index] = {
                                                ...newValue[index],
                                                variables: newVariables,
                                            };
                                            this.props.onChange(newValue);
                                        }}
                                    >
                                        {"\uFF0D" /* minus */}
                                    </Button>
                                </div>
                                {this.renderVariableSelectorError(
                                    variableInfo,
                                    aliasCounts
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )
            );
        }

        render() {
            let dataScopes =
                this.props.currentModuleId != null
                    ? DataScopesForModules(this.props.currentModuleId)
                    : DataScopes;
            const value: DataTableInput[] =
                this.props.value.length !== 0 ? this.props.value : [emptyInput];
            return (
                <div>
                    {value.map((item: DataTableInput, index: number) => (
                        <div key={index} className={styles.collapsableLine}>
                            <div className={styles.lineButtons}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        this.setState((prev) => {
                                            const collapsedRowsCopy = {
                                                ...prev.collapsedRows,
                                            };

                                            const key = String(index);
                                            if (key in collapsedRowsCopy) {
                                                delete collapsedRowsCopy[key];
                                            } else {
                                                collapsedRowsCopy[key] = true;
                                            }

                                            return {
                                                collapsedRows:
                                                    collapsedRowsCopy,
                                            };
                                        });
                                    }}
                                    className={styles.expressionCollapseButton}
                                >
                                    <ChevronIcon
                                        className={cx(
                                            styles.expressionCollapseButtonIcon,
                                            {
                                                [styles.chevronOpen]: !(
                                                    String(index) in
                                                    this.state.collapsedRows
                                                ),
                                            }
                                        )}
                                    />
                                    Input {index + 1}
                                </button>
                                {index === 0 ? (
                                    <button
                                        className={styles.addRemoveButton}
                                        type="button"
                                        onClick={() => {
                                            const newValue: DataTableInput[] =
                                                Array.from(value);
                                            newValue.push(emptyInput);
                                            this.props.onChange(newValue);
                                        }}
                                    >
                                        {"\uFF0B"} Add
                                    </button>
                                ) : (
                                    <button
                                        className={styles.addRemoveButton}
                                        type="button"
                                        onClick={() => {
                                            const newValue: DataTableInput[] =
                                                Array.from(value);
                                            newValue.splice(index, 1);
                                            this.props.onChange(newValue);
                                        }}
                                    >
                                        {"\u2715"}
                                    </button>
                                )}
                            </div>
                            <Collapse
                                in={
                                    !(String(index) in this.state.collapsedRows)
                                }
                            >
                                <div>
                                    <div className={styles.field}>
                                        <span className={styles.label}>
                                            Dataset
                                        </span>
                                        <Select
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    flex: "1 1 auto",
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "4px",
                                                    minWidth: "140px",
                                                }),
                                                indicatorSeparator: (
                                                    provided
                                                ) => ({
                                                    ...provided,
                                                    display: "none",
                                                }),
                                            }}
                                            options={
                                                dataScopes.dataScopesOptions
                                            }
                                            onChange={(option) => {
                                                const newValue: DataTableInput[] =
                                                    Array.from(value);
                                                newValue[index] = {
                                                    ...emptyInput,
                                                    dataScope:
                                                        option as DataScopeOption | null,
                                                    table: Tables(
                                                        (
                                                            option as DataScopeOption
                                                        ).value,
                                                        this.props
                                                            .currentModuleId ??
                                                            remoteModuleId
                                                    ).tableToOption(),
                                                };
                                                this.props.onChange(newValue);
                                            }}
                                            value={item.dataScope}
                                        />
                                    </div>
                                    {item.dataScope != null && (
                                        <div className={styles.field}>
                                            <span className={styles.label}>
                                                Table
                                            </span>
                                            <Select
                                                styles={{
                                                    container: (provided) => ({
                                                        ...provided,
                                                        flex: "1 1 auto",
                                                    }),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: "4px",
                                                        minWidth: "140px",
                                                    }),
                                                    indicatorSeparator: (
                                                        provided
                                                    ) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                }}
                                                options={Tables(
                                                    item.dataScope.value,
                                                    this.props
                                                        .currentModuleId ??
                                                        remoteModuleId
                                                ).rawAndAggregateTableOptions.concat(
                                                    Tables(
                                                        item.dataScope.value,
                                                        this.props
                                                            .currentModuleId ??
                                                            remoteModuleId
                                                    )
                                                        .rawAndOptimizedTableOptions
                                                )}
                                                onChange={(option) => {
                                                    const newValue: DataTableInput[] =
                                                        Array.from(value);
                                                    newValue[index] = {
                                                        ...newValue[index],
                                                        table: option as TableOption | null,
                                                    };
                                                    this.props.onChange(
                                                        newValue
                                                    );
                                                }}
                                                value={item.table}
                                            />
                                        </div>
                                    )}
                                    {item.dataScope != null &&
                                        item.table != null && (
                                            <div className={styles.field}>
                                                <span className={styles.label}>
                                                    Filter
                                                </span>
                                                <ConditionsSelector
                                                    title=""
                                                    allowLinks
                                                    nodeLinkOptions={
                                                        this.props
                                                            .nodeLinkOptions
                                                    }
                                                    currentModuleId={
                                                        this.props
                                                            .currentModuleId
                                                    }
                                                    small
                                                    dataScopeId={
                                                        item.dataScope.value
                                                    }
                                                    style={{
                                                        marginTop: 0,
                                                        backgroundColor:
                                                            "transparent",
                                                    }}
                                                    value={item.conditions}
                                                    onChange={(
                                                        conditions: Condition[]
                                                    ) => {
                                                        const newValue: DataTableInput[] =
                                                            Array.from(value);
                                                        newValue[index] = {
                                                            ...newValue[index],
                                                            conditions:
                                                                conditions,
                                                        };
                                                        this.props.onChange(
                                                            newValue
                                                        );
                                                    }}
                                                    allVariables={
                                                        Variables(
                                                            item.dataScope
                                                                .value,
                                                            this.props
                                                                .currentModuleId ??
                                                                remoteModuleId
                                                        ).dataVariables
                                                    }
                                                    {...ConditionSelectorStyles}
                                                />
                                                {this.renderVariableSelector(
                                                    value,
                                                    item,
                                                    index
                                                )}
                                            </div>
                                        )}
                                </div>
                            </Collapse>
                        </div>
                    ))}
                </div>
            );
        }
    }
);

export default DataTableInputSelector;
