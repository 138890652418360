import React from "react";
import Select, { createFilter } from "react-select";
import cx from "classnames";
import { Accordion } from "react-bootstrap";
import { MapFinding, NetworkFinding } from "common/Finding";
import Variables, { VariableOption } from "common/Variables";
import styles from "./MapSection.module.css";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";
import DataConfig from "./DataConfig";
import TooltipConfig from "./TooltipConfig";
import PinsConfig from "./PinsConfig";
import HeatmapConfig from "./HeatmapConfig";
import ChoroplethConfig from "./ChoroplethConfig";
import GeoJsonMenu from "./GeoJsonMenu";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import PinsDataTable from "./PinsDataTable";
import HeatmapDataTable from "./HeatmapDataTable";
import ChoroplethDataTable from "./ChoroplethDataTable";
import Switch from "react-switch";
import switchStyleProps from "modules/canvas_page/canvas_elements/NewDashboards/ChartsRibbon/SwitchStyleProps";
import optionsStyles from "modules/canvas_page/canvas_elements/NewDashboards/ChartsRibbon/StylingSection/StylingSection.module.css";
import AggregateSection from "../AggregateSection";

enum CollapsableMenuOptions {
    None = 0,
    Pins = 1,
    Heatmap = 2,
    MapBoundaries = 3,
    Time = 4,
    Choropleth = 5,
}

interface Props {
    canvasTreeStore: CanvasTreeStore;
    columnDragActive: boolean;
    finding: MapFinding;
    onChange: (
        finding: MapFinding | NetworkFinding,
        updateData?: boolean
    ) => void;
    currentModuleId?: number;
    innerJoinVariableOptions?: VariableOption[];
    additionalMapFindingIdx?: number;
}

export default function MapSection(props: Props) {
    const [currentOpenedMenu, setCurrentOpenedMenu] = React.useState(
        CollapsableMenuOptions.None
    );

    let variableOptions: VariableOption[] = [];
    if (props.finding.config.dataScope != null) {
        variableOptions = Variables(
            props.finding.config.dataScope.value,
            props.currentModuleId
        ).variableOptions;
    }
    return (
        <div>
            <div className={styles.editMenuBody}>
                {props.finding.config.dataScope == null && (
                    <>
                        {props.finding.type === "maps_pins" && (
                            <PinsDataTable
                                currentModuleId={props.currentModuleId}
                                mapFinding={props.finding}
                                onChange={props.onChange}
                                style={{ marginBottom: 20 }}
                            />
                        )}
                        {(props.finding.type === "maps_heatmap" ||
                            props.finding.type === "maps_bubble") && (
                            <HeatmapDataTable
                                currentModuleId={props.currentModuleId}
                                mapFinding={props.finding}
                                onChange={props.onChange}
                                style={{ marginBottom: 20 }}
                            />
                        )}
                        {props.finding.type === "maps_choropleth" && (
                            <ChoroplethDataTable
                                currentModuleId={props.currentModuleId}
                                mapFinding={props.finding}
                                onChange={props.onChange}
                                style={{ marginBottom: 20 }}
                            />
                        )}
                    </>
                )}
                <DataConfig
                    currentModuleId={props.currentModuleId}
                    finding={props.finding}
                    onChange={props.onChange}
                    canvasTreeStore={props.canvasTreeStore}
                    resetFields={(newFinding) => {
                        newFinding.config.tooltipVariables = undefined;
                        newFinding.config.coordinates = null;
                        newFinding.config.location = null;
                        newFinding.config.selectOrderBy = null;
                        newFinding.config.heatMap = null;
                        newFinding.config.conditions = null;
                        newFinding.config.center = null;
                        newFinding.config.zoom = null;

                        newFinding.config.markerColorVariableIndex = null;
                        newFinding.config.timeVariable = null;
                        newFinding.config.timeVariableIndex = null;
                        newFinding.config.centerVariableIndex = null;
                        newFinding.config.centerVariableValue = null;
                        newFinding.config.centerVariable = null;
                        newFinding.config.choroplethCountryVariable = null;
                        newFinding.config.choroplethCountryVariableIndex = null;
                        newFinding.config.choroplethMetricVariable = null;
                        newFinding.config.choroplethMetricVariableIndex = null;

                        if (newFinding.config.colorOptions != null) {
                            newFinding.config.colorOptions.lockMap = false;
                            newFinding.config.colorOptions.minZoomLevel = null;
                        }
                    }}
                />
                {(props.finding.type === "maps_heatmap" ||
                    props.finding.type === "maps_choropleth") && (
                    <AggregateSection
                        currentModuleId={props.currentModuleId}
                        onClearEditing={() => {}}
                        dashboardId={""}
                        canvasTreeStore={props.canvasTreeStore}
                        finding={props.finding}
                        onChange={props.onChange}
                    />
                )}
                {props.finding.type !== "maps_choropleth" && (
                    <TooltipConfig
                        mapFinding={props.finding}
                        onChange={props.onChange}
                        currentModuleId={props.currentModuleId}
                    />
                )}
                <Accordion
                    activeKey={String(currentOpenedMenu)}
                    onSelect={(event: any) => {
                        setCurrentOpenedMenu(Number(event));
                    }}
                >
                    {props.finding.type === "maps_pins" && (
                        <>
                            <Accordion.Toggle
                                eventKey={String(CollapsableMenuOptions.Pins)}
                                className={styles.editMenuAdvancedOption}
                            >
                                Location Pins
                                <ChevronIcon
                                    className={cx(
                                        styles.editMenuAdvancedOptionIcon,
                                        {
                                            [styles.chevronOpen]:
                                                currentOpenedMenu ===
                                                CollapsableMenuOptions.Pins,
                                        }
                                    )}
                                />
                            </Accordion.Toggle>
                            <Accordion.Collapse
                                eventKey={String(CollapsableMenuOptions.Pins)}
                            >
                                <PinsConfig
                                    mapFinding={props.finding}
                                    onChange={props.onChange}
                                    currentModuleId={props.currentModuleId}
                                />
                            </Accordion.Collapse>
                        </>
                    )}
                    {(props.finding.type === "maps_heatmap" ||
                        props.finding.type === "maps_bubble") && (
                        <>
                            <Accordion.Toggle
                                eventKey={String(
                                    CollapsableMenuOptions.Heatmap
                                )}
                                className={styles.editMenuAdvancedOption}
                            >
                                {props.finding.type === "maps_heatmap"
                                    ? "Heatmap"
                                    : "Bubble Map"}
                                <ChevronIcon
                                    className={cx(
                                        styles.editMenuAdvancedOptionIcon,
                                        {
                                            [styles.chevronOpen]:
                                                currentOpenedMenu ===
                                                CollapsableMenuOptions.Heatmap,
                                        }
                                    )}
                                />
                            </Accordion.Toggle>
                            <Accordion.Collapse
                                eventKey={String(
                                    CollapsableMenuOptions.Heatmap
                                )}
                            >
                                <HeatmapConfig
                                    mapFinding={props.finding}
                                    onChange={props.onChange}
                                    currentModuleId={props.currentModuleId}
                                />
                            </Accordion.Collapse>
                        </>
                    )}
                    {props.finding.type === "maps_choropleth" && (
                        <>
                            <Accordion.Toggle
                                eventKey={String(
                                    CollapsableMenuOptions.Choropleth
                                )}
                                className={styles.editMenuAdvancedOption}
                            >
                                Choropleth
                                <ChevronIcon
                                    className={cx(
                                        styles.editMenuAdvancedOptionIcon,
                                        {
                                            [styles.chevronOpen]:
                                                currentOpenedMenu ===
                                                CollapsableMenuOptions.Choropleth,
                                        }
                                    )}
                                />
                            </Accordion.Toggle>
                            <Accordion.Collapse
                                eventKey={String(
                                    CollapsableMenuOptions.Choropleth
                                )}
                            >
                                <ChoroplethConfig
                                    mapFinding={props.finding}
                                    onChange={props.onChange}
                                    currentModuleId={props.currentModuleId}
                                />
                            </Accordion.Collapse>
                        </>
                    )}
                    {!props.finding.config.isAdditional && (
                        <>
                            <Accordion.Toggle
                                eventKey={String(CollapsableMenuOptions.Time)}
                                className={styles.editMenuAdvancedOption}
                            >
                                Animation
                                <ChevronIcon
                                    className={cx(
                                        styles.editMenuAdvancedOptionIcon,
                                        {
                                            [styles.chevronOpen]:
                                                currentOpenedMenu ===
                                                CollapsableMenuOptions.Time,
                                        }
                                    )}
                                />
                            </Accordion.Toggle>
                            <Accordion.Collapse
                                eventKey={String(CollapsableMenuOptions.Time)}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Select
                                        isClearable
                                        placeholder="Select variable"
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                minWidth: 60,
                                                marginTop: 10,
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                minHeight: 25,
                                            }),
                                            indicatorSeparator: (provided) => ({
                                                ...provided,
                                                display: "none",
                                            }),
                                            menu: (base) => ({
                                                ...base,
                                                zIndex: 100000000,
                                            }),
                                        }}
                                        options={variableOptions}
                                        onChange={(newValue) => {
                                            let finding: MapFinding = {
                                                ...props.finding!,
                                                config: {
                                                    ...props.finding!.config,
                                                },
                                                content: {
                                                    ...props.finding!.content,
                                                    // We need to reset these arrays, otherwise we
                                                    // will sometimes get a TypeError after changing
                                                    // the time animation variable
                                                    time: null,
                                                    heatMapTime: null,
                                                },
                                            };
                                            if (newValue == null) {
                                                finding.config.timeVariable = null;
                                                finding.config.timeVariableIndex = null;
                                            } else {
                                                finding.config.timeVariable = (newValue as VariableOption).label;
                                                finding.config.timeVariableIndex = (newValue as VariableOption).value;
                                            }
                                            props.onChange(finding, true);
                                        }}
                                        value={
                                            props.finding.config
                                                .timeVariableIndex != null
                                                ? {
                                                      label:
                                                          props.finding.config
                                                              .timeVariable,
                                                      value:
                                                          props.finding.config
                                                              .timeVariableIndex,
                                                  }
                                                : null
                                        }
                                    />
                                </div>
                            </Accordion.Collapse>
                            <Accordion.Toggle
                                eventKey={String(
                                    CollapsableMenuOptions.MapBoundaries
                                )}
                                className={styles.editMenuAdvancedOption}
                            >
                                Map Boundaries
                                <ChevronIcon
                                    className={cx(
                                        styles.editMenuAdvancedOptionIcon,
                                        {
                                            [styles.chevronOpen]:
                                                currentOpenedMenu ===
                                                CollapsableMenuOptions.MapBoundaries,
                                        }
                                    )}
                                />
                            </Accordion.Toggle>
                            <Accordion.Collapse
                                eventKey={String(
                                    CollapsableMenuOptions.MapBoundaries
                                )}
                            >
                                <GeoJsonMenu
                                    mapFinding={props.finding}
                                    onChange={props.onChange}
                                />
                            </Accordion.Collapse>
                        </>
                    )}
                </Accordion>
                {props.finding.config.isAdditional && (
                    <>
                        <div className={optionsStyles.optionContainer}>
                            <span className={optionsStyles.optionName}>
                                Hide Map Behind Menu
                            </span>
                            <Switch
                                checked={
                                    props.finding.config
                                        ?.isAdditionalMapHiddenBehindMenu ??
                                    false
                                }
                                {...switchStyleProps}
                                onChange={() => {
                                    let checked = !(
                                        props.finding.config
                                            ?.isAdditionalMapHiddenBehindMenu ??
                                        false
                                    );

                                    let newFinding: MapFinding = {
                                        ...props.finding!,
                                        config: {
                                            ...props.finding!.config,
                                            isAdditionalMapHiddenBehindMenu: checked,
                                            filterAdditionalMapVariable: null,
                                        },
                                    };
                                    props.onChange(newFinding);
                                }}
                            />
                        </div>
                        <div className={optionsStyles.optionContainer}>
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                menuPlacement="auto"
                                placeholder="Select variable"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: 4,
                                    }),
                                    container: (base) => ({
                                        ...base,
                                        flexGrow: 1,
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none",
                                    }),
                                }}
                                options={props?.innerJoinVariableOptions ?? []}
                                onChange={(newValue) => {
                                    const newFinding = {
                                        ...props.finding,
                                        config: {
                                            ...props.finding.config,
                                            filterAdditionalMapVariable: newValue as VariableOption,
                                        },
                                    };

                                    props.onChange(newFinding, true);
                                }}
                                value={
                                    props.finding.config
                                        ?.filterAdditionalMapVariable ?? null
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            className={optionsStyles.optionContainer}
                            style={{
                                display: "block",
                            }}
                        >
                            <span className={optionsStyles.optionName}>
                                Tooltip Button Text
                            </span>
                            <input
                                text-transform="lowercase"
                                type="text"
                                name={"TooltipBtn"}
                                defaultValue={
                                    props.finding.config.tooltipActionButtonText
                                }
                                id={"TooltipBtn"}
                                placeholder={"Tooltip Button Text"}
                                className="like-select FormRowInput"
                                style={{ marginTop: 5 }}
                                onBlur={(e) => {
                                    if (!e.target.value) return;

                                    const newFinding = {
                                        ...props.finding,
                                        config: {
                                            ...props.finding.config,
                                            tooltipActionButtonText:
                                                e.target.value,
                                        },
                                    };

                                    props.onChange(newFinding, true);
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
