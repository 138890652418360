import React, { ErrorInfo } from "react";
import { sendErrorReport } from "common/ErrorReportingApi";
import StackTrace from "stacktrace-js";

interface Props {
    customErrorMessage?: string;
}

interface State {
    hasError: boolean;
    errorMessage: string;
}

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false, errorMessage: "" };
    }

    static getDerivedStateFromError(_error: Error) {
        return { hasError: true };
    }

    async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        let errorMessage = String(error);
        this.setState({ errorMessage: errorMessage });
        try {
            let errorReport: {
                name: string;
                message: string;
                stacktrace: StackTrace.StackFrame[];
            } = {
                name: error.name,
                message: error.message,
                stacktrace: [],
            };
            let frames = await StackTrace.fromError(error);
            errorReport.stacktrace = frames;
            await sendErrorReport(JSON.stringify(errorReport));
        } catch (err) {}
    }

    render() {
        if (this.state.hasError) {
            return (
                <h1>
                    {this.props.customErrorMessage ?? this.state.errorMessage}
                </h1>
            );
        }

        return this.props.children;
    }
}