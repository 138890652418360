export const configVersion = 1;

export const configVersionV2 = 2;

export function isValidConfig(config: any): boolean {
	if (config == null)
		return false;
	if (config.version == null)
		return false;
	return true;
}