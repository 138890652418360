import axios from "common/ServerConnection";

// CLIENT API:
// const apiKey = "";
// let geocoder: google.maps.Geocoder | undefined = undefined;

// export function loadGoogleMapsApi(callback?: () => void) {
//     const existingScript = document.getElementById("googleMaps");
//     if (!existingScript) {
//         const script = document.createElement("script");
//         script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
//         script.id = "googleMaps";
//         script.async = true;
//         script.defer = true;
//         document.body.appendChild(script);
//         script.onload = () => {
//             if (callback) callback();
//         };
//     }
//     if (existingScript && callback) callback();
// }

// function initGeocoder(): void {
//     if (!geocoder) geocoder = new google.maps.Geocoder();
// }

// export async function geocodeAddress(
//     address: string
// ): Promise<{
//     lat: number;
//     lng: number;
// }> {
//     initGeocoder();
//     try {
//         let response = await geocoder!.geocode({ address: address });
//         let results = response.results;
//         if (results.length > 0) {
//             let location = results[0].geometry.location;
//             return {
//                 lat: location.lat(),
//                 lng: location.lng(),
//             };
//         }
//         return {
//             lat: NaN,
//             lng: NaN,
//         };
//     } catch (exception) {
//         return {
//             lat: NaN,
//             lng: NaN,
//         };
//     }
// }

export async function geocodeAddresses(
    addresses: string[]
): Promise<
    {
        latitude: number | null;
        longitude: number | null;
    }[]
> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            locations: {
                latitude: number | null;
                longitude: number | null;
            }[];
        }>("/api/geocode", {
            addresses: addresses,
        })
        .then((response) => {
            if (response.data.success === false) {
                return Promise.reject(response.data.error_msg);
            } else {
                return Promise.resolve(response.data.locations);
            }
        })
        .catch((error) => {
            return Promise.reject(String(error));
        });
}
