import React from "react";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";
import styles from "./CsvUpload.module.css";
import {
    createEmptyDataSet,
    uploadGuidanceWizardData,
} from "modules/guidance_wizard/GuidanceWizardDataSet";
import { guidanceWizardEntryAddApi } from "modules/guidance_wizard/Api";
import OutsideAlerter from "common/OutsideAlerter";
import { useDropzone } from "react-dropzone";
import { SocketIOInstance } from "common/ServerConnection";
import DataScopes from "common/DataScopes";
import { UploadFormatError, uploadFileToBackend } from "common/InputData";

interface Props {
    onSuccess: (entryId: number) => void;
    onError: (errorMessage: string) => void;
    onClose: () => void;
}

interface State {
    roomId: string | undefined;
    dataScopeId: number | undefined;
}

function MyDropzone(props: { onDrop: (file: File) => void }) {
    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            props.onDrop(acceptedFiles?.[0]);
        },
        [props]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    return (
        <div className={styles.dropArea} {...getRootProps()}>
            <input {...getInputProps()} />
            <p className={styles.dropAreaText}>Drag completed CSV</p>
            {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <Button>Browse</Button>
            )}
        </div>
    );
}

@observer
class CsvUploadModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            roomId: undefined,
            dataScopeId: undefined,
        };

        this.fileUpload = this.fileUpload.bind(this);
        this.subscribeHandler = this.subscribeHandler.bind(this);
    }

    public componentDidMount(): void {
        DataScopes.update();
    }

    public componentWillUnmount(): void {
        SocketIOInstance?.off(
            "dataset_uploading_changes",
            this.subscribeHandler
        );
        if (this.state.roomId != null) {
            this.leaveChangesRoom(this.state.roomId);
        }
    }

    private joinChangesRoom(roomId: string): void {
        SocketIOInstance?.emit("dataset_uploading_changes_join", {
            room: roomId,
        });
    }

    private leaveChangesRoom(roomId: string): void {
        SocketIOInstance?.emit("dataset_uploading_changes_leave", {
            room: roomId,
        });
    }

    private async subscribeHandler(response: {
        data: {
            success: boolean;
            error_msg: string;
            data: {
                format_error?: UploadFormatError;
            };
        };
    }): Promise<void> {
        if (response.data.success) {
            if (this.state.dataScopeId != null) {
                try {
                    const entryId = await guidanceWizardEntryAddApi(
                        this.state.dataScopeId,
                        "Your presentation"
                    );
                    this.props.onSuccess(entryId);
                } catch (error) {
                    this.props.onError(
                        `Couldn't create guidance wizard entry: ${response.data.error_msg}`
                    );
                }
            } else {
                this.props.onError("Invalid data set ID");
            }
        } else {
            this.props.onError(`Data upload error: ${response.data.error_msg}`);
        }
        SocketIOInstance?.off(
            "dataset_uploading_changes",
            this.subscribeHandler
        );
        if (this.state.roomId != null) {
            this.leaveChangesRoom(this.state.roomId);
            this.setState({ roomId: undefined });
        }
    }

    private async fileUpload(file: File): Promise<void> {
        SocketIOInstance?.on(
            "dataset_uploading_changes",
            this.subscribeHandler
        );

        let dataScopeId: number | undefined = undefined;
        let uploadFile: File | undefined = undefined;
        let uploadFileId: string | undefined = undefined;

        try {
            dataScopeId = await createEmptyDataSet();
        } catch (error) {
            this.props.onError(`Data set creation error: ${error}`);
            return;
        }

        this.setState({ dataScopeId: dataScopeId });

        if (file.size / (1024 * 1024) > 80) {
            try {
                let response = await uploadFileToBackend(dataScopeId, file);
                uploadFileId = response.fileId;
            } catch (error) {
                this.props.onError(`File upload error: ${error}`);
                return;
            }
        } else {
            uploadFile = file;
        }

        try {
            const roomId = await uploadGuidanceWizardData(
                dataScopeId,
                uploadFile,
                uploadFileId
            );
            this.setState({ roomId: roomId });
            this.joinChangesRoom(roomId);
        } catch (error) {
            this.props.onError(`Data upload couldn't start: ${error}`);
        }
    }

    public render(): JSX.Element {
        return (
            <OutsideAlerter onReject={this.props.onClose}>
                <div className={styles.modalContainer}>
                    <Button className={styles.downloadButton}>Download</Button>
                    <div className={styles.centralize}>
                        <p className={styles.header}>Connect data source</p>
                    </div>
                    <p className={styles.subHeader}>1. Download Template</p>
                    <p>You must use this template to upload your data.</p>
                    <p className={styles.subHeader}>2. Fill in template</p>
                    <p>
                        Fill in the template with your customer revenue,
                        expenses, cash balance and custom customer attributes.
                        Comments in the file will guide you through updating
                        your data. All gray cells are headers or aggregation
                        rows and should not be edited.Having trouble uploading
                        data? Download an example completed template as a
                        reference.
                    </p>
                    <p className={styles.subHeader}>
                        3. Upload completed template
                    </p>
                    <MyDropzone onDrop={this.fileUpload} />
                </div>
            </OutsideAlerter>
        );
    }
}

export default CsvUploadModal;
