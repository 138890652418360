import React from "react";
import styles from "./ChartsRibbon.module.css";
import modules from "modules/data_exploration_page/modules/module_list.json";
import cx from "classnames";
import { ReactComponent as ArrowIcon } from "icons/canvas/exploration/arrow_left.svg";
import Finding, { getCompatibleCharts, convertFinding } from "common/Finding";
import DataSection from "./DataSection";
import StylingSection from "./StylingSection";
import ChartSelector from "./ChartSelector";
import ChartIcon from "./ChartIcon";
import GlobalContext, { GlobalContextContents } from "GlobalContext";
import CanvasTreeStore from "../../../CanvasTreeStore";
import { Condition } from "common/Conditions";
import { TableOption } from "common/Tables";
import { DataScopeOption } from "common/DataScopes";
import ReactTooltip from "react-tooltip";

interface ChartFilterProps {
    filterIndex: number | undefined;
    onChangeFilterIndex: (filterIndex: number | undefined) => void;
}

interface ChartFilterState {
    showLeftArrow: boolean;
    showRightArrow: boolean;
}

enum EditSections {
    Data = "Data",
    Styling = "Styling",
}

class ChartFilter extends React.Component<ChartFilterProps, ChartFilterState> {
    private scrollRef: React.RefObject<HTMLDivElement>;

    constructor(props: ChartFilterProps) {
        super(props);

        this.state = {
            showLeftArrow: false,
            showRightArrow: true,
        };

        this.scrollRef = React.createRef();

        this.renderContents = this.renderContents.bind(this);
    }

    private renderContents(globalContext: GlobalContextContents) {
        let selectedStyles = cx(
            styles.filterOption,
            styles.filterOptionSelected
        );
        return (
            <div className={styles.filterRoot}>
                {this.state.showLeftArrow && (
                    <div
                        className={styles.filterButton}
                        onClick={() => {
                            this.scrollRef.current!.scrollLeft -= 200;
                            this.setState({
                                showLeftArrow:
                                    this.scrollRef.current!.scrollLeft > 0,
                                showRightArrow:
                                    this.scrollRef.current!.scrollLeft <
                                    this.scrollRef.current!.scrollWidth -
                                        this.scrollRef.current!.clientWidth,
                            });
                        }}
                    >
                        <ArrowIcon
                            transform="rotate(180)"
                            width={22}
                            height={22}
                        />
                    </div>
                )}
                <div
                    ref={this.scrollRef}
                    className="my-row hide-scroll"
                    style={{
                        overflow: "auto hidden",
                    }}
                >
                    <span
                        className={
                            this.props.filterIndex == null
                                ? selectedStyles
                                : styles.filterOption
                        }
                        onClick={() => {
                            this.props.onChangeFilterIndex(undefined);
                        }}
                    >
                        All Charts
                    </span>
                    {modules.map((module, index) => {
                        if (
                            globalContext.permissions[module.require_permission]
                                ?.access_type === 1 &&
                            module.submodules.some(
                                (submodule) =>
                                    submodule.require_permission in
                                    globalContext.permissions
                            )
                        ) {
                            return (
                                <span
                                    key={index}
                                    style={{ marginLeft: "5px" }}
                                    className={
                                        this.props.filterIndex === index
                                            ? selectedStyles
                                            : styles.filterOption
                                    }
                                    onClick={() => {
                                        this.props.onChangeFilterIndex(index);
                                    }}
                                >
                                    {module.name}
                                </span>
                            );
                        }
                        return null;
                    })}
                </div>
                {this.state.showRightArrow && (
                    <div
                        className={styles.filterButton}
                        onClick={() => {
                            this.scrollRef.current!.scrollLeft += 200;
                            this.setState({
                                showLeftArrow:
                                    this.scrollRef.current!.scrollLeft > 0,
                                showRightArrow:
                                    this.scrollRef.current!.scrollLeft <
                                    this.scrollRef.current!.scrollWidth -
                                        this.scrollRef.current!.clientWidth,
                            });
                        }}
                    >
                        <ArrowIcon width={22} height={22} />
                    </div>
                )}
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

interface ChartItemProps {
    title: string;
    name: string;
    premium?: boolean;
    onHover: () => void;
    onClick: () => void;
}

function ChartItem(props: ChartItemProps) {
    return (
        <div
            data-cy={props.name}
            className={styles.chartCard}
            onMouseEnter={props.onHover}
            onClick={props.onClick}
        >
            <div style={{ position: "relative", width: 111, height: 65 }}>
                <div
                    style={{
                        zIndex: 1,
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <ChartIcon name={props.name} />
                </div>
            </div>
            <span style={{ marginTop: "6px", fontSize: "13px" }}>
                {props.title}
            </span>
            {props.premium && (
                <div className={styles.chartCardPremiumBagdge}>Premium</div>
            )}
        </div>
    );
}

interface ChartsRibbonProps {
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
    updatingDashboard: boolean;
    columnDragActive: boolean;
    finding?: Finding | null;
    dashboardId: string;
    filterIdInitializer?: number;
    onNewFinding: (
        finding: Finding,
        updateData?: boolean,
        preview?: boolean
    ) => void;
    onChangeConditions: (conditions: Condition[]) => void;
    onChangeSelectedTable: (selectedTable: TableOption) => void;
    onChangeDataScope: (
        dataScope: DataScopeOption,
        currentFinding: Finding
    ) => void;
    connectDataset: boolean;
    onConnectDataset: (connect: boolean) => void;
    onClearEditing: () => void;
    onUpdateSelectionBounds: () => void;
    onToogleTablePreview: (value: boolean) => void;
    toogleTablePreview: boolean;
}

interface ChartsRibbonState {
    filterIndex: number | undefined;
    currentSection: string;
}

class ChartsRibbon extends React.Component<
    ChartsRibbonProps,
    ChartsRibbonState
> {
    constructor(props: ChartsRibbonProps) {
        super(props);
        this.state = {
            filterIndex:
                this.props.filterIdInitializer !== undefined &&
                this.props.filterIdInitializer >= 0
                    ? this.props.filterIdInitializer
                    : undefined,
            currentSection: EditSections.Data,
        };
        this.setFilterIndex = this.setFilterIndex.bind(this);
        this.renderContents = this.renderContents.bind(this);
    }

    private setFilterIndex(filterIndex: number | undefined): void {
        this.setState({ filterIndex: filterIndex });
    }

    private renderContents(globalContext: GlobalContextContents): JSX.Element {
        let charts: {
            dir: string;
            name: string;
            title: string;
            subtitle: string;
            image_source: string;
            require_permission: string;
            description: string;
            initial_size?: {
                width: number;
                height: number;
            };
        }[] =
            this.state.filterIndex == null || this.state.filterIndex === -1
                ? modules
                      .filter(
                          (module) =>
                              module.require_permission in
                              globalContext.permissions
                      )
                      .map((module) => module.submodules)
                      .flat()
                : modules[this.state.filterIndex].submodules;
        charts = charts.filter(
            (submodule) =>
                submodule.require_permission in globalContext.permissions &&
                !(submodule as any).v1Only
        );
        return (
            <div className={styles.root}>
                <div className={styles.editMenuHeader}>
                    {this.props.finding == null ? null : (
                        <ChartSelector
                            onClick={(chartName) => {
                                let newFinding = convertFinding(
                                    chartName,
                                    this.props.finding!
                                );
                                this.props.onNewFinding(
                                    newFinding,
                                    false,
                                    false
                                );
                            }}
                            selectedChart={charts.find(
                                (chart) =>
                                    chart.name ===
                                    this.props.finding!.config.journeyName
                            )}
                            charts={charts.filter((chart) =>
                                getCompatibleCharts(
                                    this.props.finding!.config.journeyName
                                ).includes(chart.name)
                            )}
                        />
                    )}
                </div>
                {this.props.finding == null && (
                    <>
                        <ChartFilter
                            filterIndex={this.state.filterIndex}
                            onChangeFilterIndex={this.setFilterIndex}
                        />
                        <div className={styles.chartsGrid}>
                            {charts.map((chart, index) => (
                                <>
                                    <div
                                        data-tip={chart.description ?? ""}
                                        data-for={`${index}-tip`}
                                        onMouseEnter={() =>
                                            ReactTooltip.rebuild()
                                        }
                                        onMouseLeave={() => ReactTooltip.hide()}
                                    >
                                        <ChartItem
                                            key={index}
                                            premium={
                                                globalContext.permissions[
                                                    chart.require_permission
                                                ]?.access_type === -1
                                            }
                                            onClick={() => {
                                                if (
                                                    globalContext.permissions[
                                                        chart.require_permission
                                                    ]?.access_type === -1
                                                ) {
                                                    return;
                                                }

                                                try {
                                                    let api = require(`modules/data_exploration_page/modules/${chart.dir}/ApiV2`);
                                                    let previewFinding = api?.Api?.getPreviewFinding?.(
                                                        chart.name
                                                    );
                                                    this.props.onNewFinding(
                                                        previewFinding,
                                                        false,
                                                        false
                                                    );
                                                    let {
                                                        width,
                                                        height,
                                                    } = this.props.canvasTreeStore.dashboardsState.get(
                                                        this.props.dashboardId
                                                    )?.nodeSize.desktop ?? {
                                                        width: 400,
                                                        height: 200,
                                                    };
                                                    let newWidth =
                                                        chart.initial_size
                                                            ?.width ?? 400;
                                                    let newHeight =
                                                        chart.initial_size
                                                            ?.height ?? 200;
                                                    if (
                                                        width !== newWidth ||
                                                        height !== newHeight
                                                    ) {
                                                        this.props.canvasTreeStore.updateDashboardAction(
                                                            this.props
                                                                .dashboardId,
                                                            {
                                                                nodeSize: {
                                                                    desktop: {
                                                                        width: newWidth,
                                                                        height: newHeight,
                                                                    },
                                                                    mobile: {
                                                                        width: newWidth,
                                                                        height: newHeight,
                                                                    },
                                                                },
                                                            }
                                                        );
                                                        // We use setTimeout because we need the chart
                                                        // to re-render before calling onUpdateSelectionBounds
                                                        setTimeout(this.props.onUpdateSelectionBounds, 1);
                                                    }
                                                } catch (error) {}
                                            }}
                                            title={chart.title}
                                            name={chart.name}
                                            onHover={() => {
                                                try {
                                                    let api = require(`modules/data_exploration_page/modules/${chart.dir}/ApiV2`);
                                                    let previewFinding = api?.Api?.getPreviewFinding?.(
                                                        chart.name
                                                    );
                                                    this.props.onNewFinding(
                                                        previewFinding,
                                                        false,
                                                        true
                                                    );
                                                    let {
                                                        width,
                                                        height,
                                                    } = this.props.canvasTreeStore.dashboardsState.get(
                                                        this.props.dashboardId
                                                    )?.nodeSize.desktop ?? {
                                                        width: 400,
                                                        height: 200,
                                                    };
                                                    let newWidth =
                                                        chart.initial_size
                                                            ?.width ?? 400;
                                                    let newHeight =
                                                        chart.initial_size
                                                            ?.height ?? 200;
                                                    if (
                                                        width !== newWidth ||
                                                        height !== newHeight
                                                    ) {
                                                        this.props.canvasTreeStore.updateDashboardAction(
                                                            this.props
                                                                .dashboardId,
                                                            {
                                                                nodeSize: {
                                                                    desktop: {
                                                                        width: newWidth,
                                                                        height: newHeight,
                                                                    },
                                                                    mobile: {
                                                                        width: newWidth,
                                                                        height: newHeight,
                                                                    },
                                                                },
                                                            }
                                                        );
                                                        // We use setTimeout because we need the chart
                                                        // to re-render before calling onUpdateSelectionBounds
                                                        setTimeout(this.props.onUpdateSelectionBounds, 1);
                                                    }
                                                } catch (error) {}
                                            }}
                                        />
                                    </div>
                                    <ReactTooltip
                                        className={styles.chartTooltip}
                                        id={`${index}-tip`}
                                        effect="solid"
                                        backgroundColor="#4e4c4c"
                                        place="bottom"
                                        scrollHide={true}
                                    />
                                </>
                            ))}
                        </div>
                    </>
                )}
                {this.props.finding != null && (
                    <div className={styles.sectionBar}>
                        {Object.keys(EditSections).map(
                            (sectionName: string, index) => {
                                let containerStyle =
                                    styles.sectionTitleContainer;
                                let titleStyle = styles.sectionTitle;

                                if (sectionName === this.state.currentSection) {
                                    containerStyle = cx(
                                        containerStyle,
                                        styles.sectionTitleContainerSelected
                                    );
                                    titleStyle = cx(
                                        titleStyle,
                                        styles.sectionTitleSelected
                                    );
                                }
                                return (
                                    <div
                                        key={index}
                                        className={containerStyle}
                                        onClick={() => {
                                            this.setState({
                                                currentSection:
                                                    EditSections[
                                                        sectionName as keyof typeof EditSections
                                                    ],
                                            });
                                        }}
                                    >
                                        <span className={titleStyle}>
                                            {sectionName}
                                        </span>
                                    </div>
                                );
                            }
                        )}
                    </div>
                )}
                {this.props.finding != null &&
                    this.state.currentSection === EditSections.Data && (
                        <DataSection
                            updatingDashboard={this.props.updatingDashboard}
                            onClearEditing={this.props.onClearEditing}
                            dashboardId={this.props.dashboardId}
                            canvasTreeStore={this.props.canvasTreeStore}
                            columnDragActive={this.props.columnDragActive}
                            currentModuleId={this.props.currentModuleId}
                            connectDataset={this.props.connectDataset}
                            onConnectDataset={this.props.onConnectDataset}
                            onChange={this.props.onNewFinding}
                            finding={this.props.finding}
                            onChangeConditions={this.props.onChangeConditions}
                            onChangeSelectedTable={
                                this.props.onChangeSelectedTable
                            }
                            onChangeDataScope={this.props.onChangeDataScope}
                            toogleTablePreview={this.props.toogleTablePreview}
                            onToogleTablePreview={
                                this.props.onToogleTablePreview
                            }
                        />
                    )}
                {this.props.finding != null &&
                    this.state.currentSection === EditSections.Styling && (
                        <StylingSection
                            onClearEditing={this.props.onClearEditing}
                            currentModuleId={this.props.currentModuleId}
                            canvasTreeStore={this.props.canvasTreeStore}
                            finding={this.props.finding!}
                            onChange={this.props.onNewFinding}
                            onAddHorizontalLines={this.props.onNewFinding}
                            dashboardId={this.props.dashboardId}
                        />
                    )}
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <GlobalContext.Consumer>
                {this.renderContents}
            </GlobalContext.Consumer>
        );
    }
}

export default ChartsRibbon;
