import React, { Component } from "react";
import { CanvasTextBox } from "common/Canvas";
import LinkDropup from "../../link_components/LinkDropup";
import LinkOptionsPopup from "../../link_components/LinkOptionsPopup";
import CanvasTreeStore from "../../CanvasTreeStore";
import {
    getSelectionText,
    getEntityRange,
    getSelectionEntity,
} from "draftjs-utils";
import { RichUtils, EditorState, Modifier } from "draft-js";
import linkifyIt from "linkify-it";

const linkify = linkifyIt();
const linkifyLink = (link: string) => {
    const links = linkify.match(link);
    return (links && links[0] && links[0].url) || link;
};

interface CustomLinkOptionProps {
    canvasTreeStore: CanvasTreeStore;
    tag: CanvasTextBox;
    editorState: EditorState;
    onChange: (editorState: EditorState) => void;
    onPopupOpened: (opened: boolean) => void;
    currentModuleId: number | undefined;
}

interface CustomLinkOptionState {
    showLinkPopupOptions: {
        x: number;
        external: boolean;
    } | null;
    currentEntity: string | undefined;
}

export default class CustomLinkOption extends Component<
    CustomLinkOptionProps,
    CustomLinkOptionState
> {
    constructor(props: CustomLinkOptionProps) {
        super(props);
        this.state = {
            currentEntity: this.props.editorState
                ? getSelectionEntity(this.props.editorState)
                : undefined,
            showLinkPopupOptions: null,
        };
    }
    componentDidUpdate(prevProps: CustomLinkOptionProps) {
        const { editorState } = this.props;
        if (editorState && editorState !== prevProps.editorState) {
            this.setState({ currentEntity: getSelectionEntity(editorState) });
        }
    }
    getCurrentValues() {
        const { editorState } = this.props;
        const { currentEntity } = this.state;
        const contentState = editorState.getCurrentContent();
        const currentValues = {
            link: {
                title: "",
                target: "",
                targetOption: "_blank",
                external: true,
                internalLink: null,
                inPopup: false,
            },
            selectionText: "",
        };
        if (
            currentEntity &&
            contentState.getEntity(currentEntity).getType() === "LINK"
        ) {
            const entityRange =
                currentEntity && getEntityRange(editorState, currentEntity);
            currentValues.link.target =
                currentEntity &&
                contentState.getEntity(currentEntity).getData().url;
            currentValues.link.targetOption =
                currentEntity &&
                contentState.getEntity(currentEntity).getData().targetOption;
            currentValues.link.title = entityRange && entityRange.text;
            currentValues.link.external =
                currentEntity &&
                (contentState.getEntity(currentEntity).getData().external ??
                    true);
            currentValues.link.inPopup =
                currentEntity &&
                (contentState.getEntity(currentEntity).getData().inPopup ??
                    false);
            currentValues.link.internalLink =
                currentEntity &&
                contentState.getEntity(currentEntity).getData().internalLink;
        }
        currentValues.selectionText = getSelectionText(editorState);
        return currentValues;
    }
    removeLink = () => {
        const { editorState, onChange } = this.props;
        const { currentEntity } = this.state;
        let selection = editorState.getSelection();
        if (currentEntity) {
            const entityRange = getEntityRange(editorState, currentEntity);
            const isBackward = selection.getIsBackward();
            if (isBackward) {
                selection = selection.merge({
                    anchorOffset: entityRange.end,
                    focusOffset: entityRange.start,
                });
            } else {
                selection = selection.merge({
                    anchorOffset: entityRange.start,
                    focusOffset: entityRange.end,
                });
            }
            onChange(RichUtils.toggleLink(editorState, selection, null));
        }
    };

    addLink(
        linkTitle: string,
        external: boolean,
        linkTarget?: string,
        internalLink?: number,
        inPopup?: boolean
    ) {
        const { editorState, onChange } = this.props;
        const { currentEntity } = this.state;
        let selection = editorState.getSelection();

        if (currentEntity) {
            const entityRange = getEntityRange(editorState, currentEntity);
            const isBackward = selection.getIsBackward();
            if (isBackward) {
                selection = selection.merge({
                    anchorOffset: entityRange.end,
                    focusOffset: entityRange.start,
                });
            } else {
                selection = selection.merge({
                    anchorOffset: entityRange.start,
                    focusOffset: entityRange.end,
                });
            }
        }
        const entityKey = editorState
            .getCurrentContent()
            .createEntity(
                "LINK",
                "MUTABLE",
                external
                    ? {
                          url: linkTarget,
                          targetOption: "_blank",
                          external: true,
                          internalLink: null,
                          inPopup: false,
                      }
                    : {
                          url: "#",
                          targetOption: "",
                          internalLink: internalLink,
                          external: false,
                          inPopup: inPopup ?? false,
                      }
            )
            .getLastCreatedEntityKey();

        let contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            selection,
            `${linkTitle}`,
            editorState.getCurrentInlineStyle(),
            entityKey
        );
        let newEditorState = EditorState.push(
            editorState,
            contentState,
            "insert-characters"
        );

        // insert a blank space after link
        selection = newEditorState.getSelection().merge({
            anchorOffset: selection.get("anchorOffset") + linkTitle.length,
            focusOffset: selection.get("anchorOffset") + linkTitle.length,
        });
        newEditorState = EditorState.acceptSelection(newEditorState, selection);
        contentState = Modifier.insertText(
            newEditorState.getCurrentContent(),
            selection,
            " ",
            newEditorState.getCurrentInlineStyle(),
            undefined
        );
        onChange(
            EditorState.push(newEditorState, contentState, "insert-characters")
        );
    }

    render() {
        const { link, selectionText } = this.getCurrentValues();
        let internalLinks: number[] =
            link.internalLink != null ? [link.internalLink! as number] : [];
        return (
            <>
                <div className="rdw-link-wrapper">
                    <LinkDropup
                        onOpen={(evt, external) => {
                            this.props.onPopupOpened(true);
                            this.setState({
                                showLinkPopupOptions: {
                                    x: 200,
                                    external: external ?? link.external ?? true,
                                },
                            });
                        }}
                    />
                    ;
                </div>
                {this.state.showLinkPopupOptions && (
                    <LinkOptionsPopup
                        canvasTreeStore={this.props.canvasTreeStore}
                        asModalPopup={false}
                        explicitExternal={
                            this.state.showLinkPopupOptions.external
                        }
                        explicitExternalLink={link.target}
                        explicitInPopup={link.inPopup}
                        currentModuleId={this.props.currentModuleId}
                        node={{
                            ...(this.props.tag as CanvasTextBox),
                            links: internalLinks,
                        }}
                        x={this.state.showLinkPopupOptions!.x}
                        onClose={() => {
                            this.setState({
                                showLinkPopupOptions: null,
                            });
                            this.props.onPopupOpened(false);
                        }}
                        onNodeSave={(
                            node,
                            explicitExternal,
                            explicitExternalLink,
                            explicitInPopup
                        ) => {
                            if (explicitExternal) {
                                if (explicitExternalLink) {
                                    const linkified = linkifyLink(
                                        explicitExternalLink
                                    );
                                    this.addLink(
                                        selectionText,
                                        true,
                                        linkified
                                    );
                                } else {
                                    this.removeLink();
                                }
                            } else {
                                let links = node.links;
                                if (links.length === 1) {
                                    this.addLink(
                                        selectionText,
                                        false,
                                        undefined,
                                        links[0],
                                        explicitInPopup
                                    );
                                } else {
                                    this.removeLink();
                                }
                            }
                        }}
                    />
                )}
            </>
        );
    }
}
