import React, { Component } from "react";
import CanvasTreeStore from "./CanvasTreeStore";
import { isSimpleSpreadSheetInput, getTargetValue } from "common/Canvas";
import {
    ConditionsSelector,
    Condition,
    NodeLinkOption,
} from "common/Conditions";
import Variables, { VariableOption } from "common/Variables";
import SharedBoxesStore from "./SharedBoxesStore";
import remoteModuleId from "common/remoteModuleId";
import { lightThemeStyle } from "common/LightThemeStyle";
import { getCustomSelectStyleLight } from "common/SelectStyles";
import Select, {
    createFilter,
    components,
    DropdownIndicatorProps,
    GroupBase,
} from "react-select";
import ConditionSelectorStyles from "common/ConditionSelectorStyles";
import Tables, { TableOption } from "common/Tables";
import GlobalInputs, { getGlobalInputValue } from "common/GlobalInputs";
import { observer } from "mobx-react";

const topBottomOptions = [
    {
        label: "top",
        value: 0,
    },
    {
        label: "bottom",
        value: 1,
    },
];

const DropdownIndicator = <
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
>(
    props: DropdownIndicatorProps<Option, IsMulti, Group>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <span
                className="content-regular-text"
                style={{
                    width: "20px",
                    textAlign: "center",
                }}
            >
                +
            </span>
        </components.DropdownIndicator>
    );
};

interface Changes {
    limitText?: string;
    conditions?: Condition[];
    bottomRows?: boolean;
    columnFilter?: VariableOption[];
    table?: TableOption;
}

interface Props {
    hideTitles?: boolean;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
    dataScopeId: string | number | undefined;
    table: TableOption | null;
    conditions: Condition[];
    bottomRows: boolean;
    columnFilter: VariableOption[];
    limitText: string;
    spreadsheetId: string;
    onChange: (changes: Changes) => void;
}

interface State {
    limitText: string;
}

export default observer(
    class DatasetFilterToolbar extends Component<Props, State> {
        constructor(props: Props) {
            super(props);
            this.state = {
                limitText: props.limitText,
            };
        }
        private nodeLinkOptions() {
            return Array.from(
                this.props.canvasTreeStore.canvasTreeState.values()
            )
                .filter(
                    (node) =>
                        !isSimpleSpreadSheetInput(node) ||
                        node.gridId !== this.props.spreadsheetId
                )
                .map(
                    (item) =>
                        ({
                            label: item.outerId,
                            value: item.id,
                            isCloneInput: false,
                            target: getTargetValue(item),
                        } as NodeLinkOption)
                )
                .concat(
                    SharedBoxesStore.sharedBoxes(
                        this.props.canvasTreeStore.canvasId!
                    ).map((item) => ({
                        label: item.label,
                        value: item.value,
                        isCloneInput: true,
                        target: getTargetValue(item.item.box),
                    }))
                )
                .concat(
                    GlobalInputs.map((input) => ({
                        label: input.label,
                        value: input.value,
                        isCloneInput: false,
                        target: getGlobalInputValue(input.value, true),
                        isGlobalInput: true,
                    }))
                );
        }

        private renderConditionsSelector(): JSX.Element {
            return (
                <ConditionsSelector
                    style={{ marginTop: 0 }}
                    key={this.props.dataScopeId}
                    currentModuleId={this.props.currentModuleId}
                    small
                    dataScopeId={this.props.dataScopeId}
                    value={this.props.conditions}
                    allowLinks
                    nodeLinkOptions={this.nodeLinkOptions()}
                    title={""}
                    onChange={(conditions) => {
                        this.props.onChange({
                            conditions: conditions,
                        });
                    }}
                    allVariables={
                        Variables(
                            this.props.dataScopeId,
                            this.props.currentModuleId ?? remoteModuleId
                        ).dataVariables
                    }
                    {...ConditionSelectorStyles}
                />
            );
        }
        render() {
            let selectStyles = getCustomSelectStyleLight(14, false);

            let smallSelectStyles = getCustomSelectStyleLight(10, false);
            return (
                <div>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        menuPortalTarget={document.body}
                        placeholder={""}
                        styles={{
                            ...selectStyles,
                            container: (base) => ({
                                ...base,
                                marginTop: "5px",
                                width: "30em",
                                height: "38px",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={
                            this.props.dataScopeId != null
                                ? Tables(
                                      this.props.dataScopeId,
                                      this.props.currentModuleId
                                  ).rawAndAggregateTableOptions
                                : []
                        }
                        onChange={(newValue) => {
                            this.props.onChange({
                                table: newValue as TableOption,
                                conditions: ConditionsSelector.defaultValue,
                                columnFilter: [],
                            });
                        }}
                        value={this.props.table}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                    />
                    {!this.props.hideTitles && (
                        <span
                            className="content-regular-text"
                            style={{
                                display: "block",
                                marginTop: 15,
                                paddingTop: 15,
                                paddingBottom: 15,
                                borderBottom: "1px solid #d1d1d1",
                                borderTop: "1px solid #d1d1d1",
                                color: lightThemeStyle.primaryTextColor,
                            }}
                        >
                            Display in spreadsheet
                        </span>
                    )}
                    <div
                        style={{
                            marginTop: "5px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    marginTop: 10,
                                    marginLeft: 10,
                                    marginBottom: 5,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Show
                            </span>
                            <style>{`
                                .show-select:focus-visible {
                                    outline: 2px solid #2684ff !important;
                                }
                            `}</style>
                            <Select
                                className="show-select"
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                menuPortalTarget={document.body}
                                placeholder={""}
                                styles={{
                                    ...selectStyles,
                                    control: (base, props) => ({
                                        ...(selectStyles.control?.(
                                            base,
                                            props
                                        ) ?? base),
                                        minHeight: 33,
                                        height: 33,
                                        minWidth: 113,
                                        width: 113,
                                    }),
                                }}
                                options={topBottomOptions}
                                onChange={(newValue) => {
                                    this.props.onChange({
                                        bottomRows:
                                            (newValue as {
                                                label: string;
                                                value: number;
                                            }).value === 1,
                                    });
                                }}
                                value={
                                    this.props.bottomRows
                                        ? topBottomOptions[1]
                                        : topBottomOptions[0]
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                        <div
                            style={{
                                width: 1,
                                height: 40,
                                border: "1px solid #d1d1d1",
                                marginLeft: 20,
                                marginRight: 20,
                                marginTop: 27,
                            }}
                        />
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    marginTop: 10,
                                    marginLeft: 10,
                                    marginBottom: 5,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Rows
                            </span>
                            <input
                                className="like-select"
                                style={{
                                    color: lightThemeStyle.primaryTextColor,
                                    width: "5em",
                                    minHeight: "0px",
                                    textAlign: "center",
                                    padding: "6px 5px 5px 5px",
                                    borderRadius: 4,
                                }}
                                placeholder=""
                                onChange={(e) => {
                                    const value: string = e.target.value;
                                    if (!/^[0-9]*\.?[0-9]*$/.test(value)) {
                                        e.preventDefault();
                                    } else {
                                        this.setState({ limitText: value });
                                    }
                                }}
                                onBlur={() => {
                                    this.props.onChange({
                                        limitText: this.state.limitText,
                                    });
                                }}
                                value={this.state.limitText}
                            />
                        </div>
                        <div
                            style={{
                                width: 1,
                                height: 40,
                                border: "1px solid #d1d1d1",
                                marginLeft: 20,
                                marginRight: 20,
                                marginTop: 27,
                            }}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    marginTop: 10,
                                    marginLeft: 10,
                                    marginBottom: 5,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Row Filter
                            </span>
                            {this.renderConditionsSelector()}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                            }}
                        >
                            <span
                                className="content-regular-text"
                                style={{
                                    marginTop: 10,
                                    marginLeft: 10,
                                    marginBottom: 5,
                                    color: lightThemeStyle.primaryTextColor,
                                }}
                            >
                                Column Filter
                            </span>
                            <Select
                                menuPortalTarget={document.body}
                                components={{
                                    DropdownIndicator,
                                }}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder={""}
                                styles={{
                                    ...smallSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        height: "38px",
                                        minWidth: "100px",
                                        flex: 1,
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                isMulti
                                options={
                                    Variables(
                                        this.props.dataScopeId,
                                        this.props.currentModuleId ??
                                            remoteModuleId
                                    ).variableOptions
                                }
                                value={this.props.columnFilter}
                                onChange={(newValue) => {
                                    this.props.onChange({
                                        columnFilter: (newValue ??
                                            []) as VariableOption[],
                                    });
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,

                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }
);
