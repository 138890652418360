import Cookies from "universal-cookie";

import axios from "common/ServerConnection";
import {
    Canvas,
    InnerCanvasChanges,
    CanvasResponse,
    toCanvas,
    CanvasBackground,
    CanvasSharedBoxElement,
    CanvasSharedBoxElementResponse,
    toCanvasSharedBoxElement,
    CanvasDataTableInputDetails,
    toTemplate,
    SlideTemplate,
    TemplateResponse,
    serializeBackground,
    CanvasBackgroundResponse,
} from "./Canvas";
import { getConditionValue } from "common/Conditions";
import { stringSessionId } from "./SessionId";
import { AdminCollection, Collection } from "./MediaCollectionsApi";

export async function getCanvasesApi(
    moduleId?: number,
    pageId?: number,
    offset?: number,
    limit?: number,
    onlyHeaders?: boolean
): Promise<Canvas[]> {
    let only_headers = onlyHeaders ?? true;
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            canvases?: CanvasResponse[];
        }>("/api/get_canvases", {
            module_id: moduleId,
            page_id: pageId,
            offset: offset,
            limit: limit,
            only_headers: only_headers,
        })
        .then((response) => {
            if (response.data.success && response.data.canvases != null) {
                return Promise.resolve(
                    response.data.canvases.map((item) =>
                        toCanvas(item, only_headers)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function sendConditionalNotification(
    canvasId: number,
    title: string,
    message: string,
    users: string[]
): Promise<void> {
    let requestJson = {
        id: canvasId,
        title: title,
        message: message,
        recipient_user_names: users,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/canvas_conditional_notification_send", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function getCanvasApi(
    canvasId: number,
    onlyHeaders?: boolean
): Promise<Canvas> {
    let only_headers = onlyHeaders ?? false;
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            canvas?: CanvasResponse;
        }>("/api/get_canvas", {
            id: canvasId,
            only_headers: only_headers,
        })
        .then((response) => {
            if (response.data.success && response.data.canvas != null) {
                return Promise.resolve(
                    toCanvas(response.data.canvas, only_headers)
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getLastAccessedCanvasApi(
    pageId: number
): Promise<Canvas> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            canvas?: CanvasResponse;
        }>("/api/get_last_accessed_canvas", { page_id: pageId })
        .then((response) => {
            if (response.data.success && response.data.canvas != null) {
                return Promise.resolve(toCanvas(response.data.canvas));
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function setLastAccessedCanvasApi(pageId: number): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/set_last_accessed_canvas", { canvas_id: pageId })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function moveCanvasApi(
    canvasId: number | null,
    position: string | undefined, 
    swapId: number | null,
    pageId?: number,
    updateId?: string
): Promise<void> {
    let requestJson = {
        id: canvasId,
        position: position,
        swap_id: swapId,
        page_id: pageId,
        update_id: updateId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/move_canvas", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function addCanvasApi(
    pageId: number,
    title: string,
    canvas: Canvas["canvas"],
    backgrounds: CanvasBackground[],
    thumbnail: string | null,
    userGroups?: (string | null)[],
    insertAfterId?: number,
    updateId?: string
): Promise<number> {
    let requestJson: {
        page_id: number;
        title: string;
        canvas: string;
        backgrounds: CanvasBackgroundResponse[];
        thumbnail: string | null;
        user_groups?: string[];
        insert_after_id?: number;
        layer_for_canvas_id?: number;
        update_id?: string;
    } = {
        page_id: pageId,
        title: title,
        canvas: JSON.stringify(canvas),
        thumbnail: thumbnail,
        backgrounds: backgrounds.map((background) =>
            serializeBackground(background)
        ),
        insert_after_id: insertAfterId,
    };
    if (updateId != null) {
        requestJson.update_id = updateId;
    }
    if (userGroups != null) {
        requestJson.user_groups = userGroups.filter(
            (value: string | null): value is string => value != null
        );
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/add_canvas", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function copyCanvasApi(
    pageId: number,
    title: string,
    copyId: number,
    userGroups?: (string | null)[],
    updateId?: string,
): Promise<number> {
    let requestJson: {
        page_id: number;
        title: string;
        copy_id: number;
        user_groups?: string[];
        update_id?: string;
        layer_for_canvas_id?: number;
    } = {
        page_id: pageId,
        title: title,
        copy_id: copyId,
    };
    if (updateId != null) {
        requestJson.update_id = updateId;
    }
    if (userGroups != null) {
        requestJson.user_groups = userGroups.filter(
            (value: string | null): value is string => value != null
        );
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/add_canvas", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export interface BriefEditCanvasItem {
    canvasId: number;
    title: string;
}

export interface EditCanvasItem {
    pageId: number;
    canvasId: number;
    canvas: Canvas["canvas"];
    backgrounds: CanvasBackground[];
    userGroups?: (string | null)[];
    thumbnail: string | null;
    mergeTemplatesTitle?: string;
    mergeTemplatesUpdateEditingCanvas?: boolean;
}

export interface EditCanvasRequest {
    sheets: EditCanvasItem[];
    mergeTemplates?: boolean;
}

export interface BriefEditCanvasRequest {
    sheets: BriefEditCanvasItem[];
}

interface BriefEditCanvasRequestJson {
    id: number;
    title: string;
}

interface EditCanvasRequestJson {
    id: number;
    page_id: number;
    canvas: string;
    backgrounds: CanvasBackgroundResponse[];
    thumbnail: string | null;
    user_groups?: string[];
    merge_templates?: boolean;
    merge_templates_title?: string;
    merge_templates_update_editing_canvas?: boolean;
}

export async function editCanvasTitle(
    editCanvasRequest: BriefEditCanvasRequest,
    updateId?: string
): Promise<void> {
    let requestJson: {
        sheets: BriefEditCanvasRequestJson[];
        update_id?: string;
    } = {
        sheets: [],
    };
    if (updateId != null) {
        requestJson.update_id = updateId;
    }
    for (let editCanvasItem of editCanvasRequest.sheets) {
        let canvasJson: BriefEditCanvasRequestJson = {
            id: editCanvasItem.canvasId,
            title: editCanvasItem.title,
        };
        requestJson.sheets.push(canvasJson);
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            ids?: number[];
        }>("/api/update_canvas_title", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function hideInSlideShow(
    id: number,
    hideInSlideShow: boolean,
    updateId?: string
): Promise<void> {
    let requestJson: {
        id: number;
        hide_in_slideshow: boolean;
        update_id?: string;
    } = {
        id: id,
        hide_in_slideshow: hideInSlideShow,
    };
    if (updateId != null) {
        requestJson.update_id = updateId;
    }

    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/hide_canvas_in_slideshow", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function editCanvasApi(
    editCanvasRequest: EditCanvasRequest
): Promise<number[]> {
    let requestJson: {
        sheets: EditCanvasRequestJson[];
        merge_templates: boolean;
        update_id: string | undefined;
    } = {
        sheets: [],
        merge_templates: false,
        update_id: new Cookies().get("instrumentation_session_id"),
    };
    if (editCanvasRequest.mergeTemplates != null) {
        requestJson.merge_templates = editCanvasRequest.mergeTemplates;
    }
    for (let editCanvasItem of editCanvasRequest.sheets) {
        let canvasJson: EditCanvasRequestJson = {
            id: editCanvasItem.canvasId,
            page_id: editCanvasItem.pageId,
            canvas: JSON.stringify(editCanvasItem.canvas),
            backgrounds: editCanvasItem.backgrounds.map((background) =>
                serializeBackground(background)
            ),
            thumbnail: editCanvasItem.thumbnail,
        };
        if (editCanvasItem.mergeTemplatesTitle != null) {
            canvasJson.merge_templates_title =
                editCanvasItem.mergeTemplatesTitle;
        }
        if (editCanvasItem.mergeTemplatesUpdateEditingCanvas != null) {
            canvasJson.merge_templates_update_editing_canvas =
                editCanvasItem.mergeTemplatesUpdateEditingCanvas;
        }
        if (editCanvasItem.userGroups != null) {
            canvasJson.user_groups = editCanvasItem.userGroups.filter(
                (value: string | null): value is string => value != null
            );
        }
        requestJson.sheets.push(canvasJson);
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            ids?: number[];
        }>("/api/update_canvas", requestJson)
        .then((response) => {
            if (response.data.success && response.data.ids != null) {
                return Promise.resolve(response.data.ids);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export enum BackgroundMode {
    Ignore = 1,
    Replace = 2,
    Append = 3,
    Update = 4,
    Delete = 5,
}

export interface EditNotSavedCanvasResponse {
    success: boolean;
    id?: number;
    background_ids?: number[];
    error_msg?: string;
    prohibited_items?: { [key: string]: string[] };
}

export async function editNotSavedCanvasApi(
    canvas: InnerCanvasChanges,
    canvasId: number,
    backgrounds: CanvasBackground[] = [],
    backgroundMode: BackgroundMode = BackgroundMode.Ignore,
    forceReplace: boolean = false,
    thumbnail?: string,
    updateId?: string,
    isTemplate?: boolean
): Promise<EditNotSavedCanvasResponse> {
    let requestJson: {
        canvas: string;
        canvas_id: number;
        backgrounds: CanvasBackgroundResponse[];
        background_mode: BackgroundMode;
        replace: boolean;
        thumbnail?: string;
        update_id?: string;
    } = {
        canvas: JSON.stringify(canvas),
        canvas_id: canvasId,
        backgrounds: backgrounds.map((background) =>
            serializeBackground(background)
        ),
        background_mode: backgroundMode,
        replace: forceReplace,
    };
    if (thumbnail != null) {
        requestJson.thumbnail = thumbnail;
    }
    if (updateId != null) {
        requestJson.update_id = updateId;
    }
    return axios
        .post<{
            success: boolean;
            error_msg?: string;
            id?: number;
            background_ids?: number[];
            prohibited_items?: { [key: string]: string[] };
        }>(
            isTemplate
                ? "/api/update_editing_canvas_template"
                : "/api/update_editing_canvas",
            requestJson
        )
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editNotSavedCanvasThumbnailApi(
    canvasId: number,
    thumbnail: string,
    updateId?: string
): Promise<void> {
    let requestJson: {
        canvas_id: number;
        thumbnail: string;
        update_id?: string;
    } = {
        canvas_id: canvasId,
        thumbnail: thumbnail,
    };
    if (updateId != null) {
        requestJson.update_id = updateId;
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/update_editing_canvas_thumbnail", requestJson)
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function updateCanvasAccessTimeApi(
    canvasId: number
): Promise<void> {
    let requestJson = {
        id: canvasId,
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/canvas_update_access_time", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function deleteCanvasApi(
    canvasId: number,
    updateId?: string
): Promise<void> {
    let requestJson: {
        id: number;
        update_id?: string;
    } = {
        id: canvasId,
    };
    if (updateId != null) {
        requestJson.update_id = updateId;
    }
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/delete_canvas", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function addSharedBoxApi(
    canvasId: number,
    box: CanvasSharedBoxElement["box"],
    isTemplate: boolean
): Promise<number> {
    let requestJson = {
        canvas_id: canvasId,
        box: JSON.stringify(box),
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>(
            isTemplate ? "/api/add_template_shared_box" : "/api/add_shared_box",
            requestJson
        )
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function updateSharedBoxApi(
    sharedId: number,
    box: CanvasSharedBoxElement["box"],
    isTemplate: boolean
): Promise<void> {
    let requestJson = {
        id: sharedId,
        box: JSON.stringify(box),
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>(
            isTemplate
                ? "/api/update_template_shared_box"
                : "/api/update_shared_box",
            requestJson
        )
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function deleteSharedBoxApi(
    boxId: number,
    isTemplate: boolean
): Promise<void> {
    let requestJson = {
        id: boxId,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>(
            isTemplate
                ? "/api/delete_template_shared_box"
                : "/api/delete_shared_box",
            requestJson
        )
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getSharedBoxesApi(
    moduleId: number
): Promise<CanvasSharedBoxElement[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            boxes?: CanvasSharedBoxElementResponse[];
        }>("/api/get_shared_boxes", {
            module_id: moduleId,
        })
        .then((response) => {
            if (response.data.success && response.data.boxes != null) {
                return Promise.resolve(
                    response.data.boxes.map((box) =>
                        toCanvasSharedBoxElement(box)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function getSharedBoxApi(
    boxId: number
): Promise<CanvasSharedBoxElement> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            box?: CanvasSharedBoxElementResponse;
        }>("/api/get_shared_box", { id: boxId })
        .then((response) => {
            if (response.data.success && response.data.box != null) {
                return Promise.resolve(
                    toCanvasSharedBoxElement(response.data.box)
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function selectDataIfUpdated(
    dataTableInputDetails: CanvasDataTableInputDetails[],
    force: boolean = false,
    moduleId?: number | string | null
): Promise<{
    new_update_times: number[];
    result: { [key: string]: number | string | null };
    errors?: string[];
} | null> {
    const resetUpdateTime = force ? { update_time: 0 } : {};
    let select = dataTableInputDetails.map((item) => ({
        ...item,
        zero_if_no_data: undefined,
        conditions: item.conditions.map((condition) => ({
            variable: condition.variable?.value,
            operation: condition.operation?.value,
            value: getConditionValue(condition),
            logical: condition.logical?.value,
            leftBracket: condition.leftBracket?.value,
            rightBracket: condition.rightBracket?.value,
        })),
        ...resetUpdateTime,
    }));
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            new_update_times?: number[];
            errors?: string[];
            result?: { [key: string]: number | string };
        }>("/api/e/select_data_if_updated", {
            select: select,
            module_id: moduleId,
        })
        .then((response) => {
            if (response.data.success) {
                if (
                    response.data.new_update_times != null &&
                    response.data.result != null
                ) {
                    let result = response.data.result;
                    for (let key of Object.keys(result)) {
                        if (Array.isArray(result[key])) {
                            result[key] = ((result[key] as any) as Array<
                                any
                            >).join(",");
                        }
                    }
                    return Promise.resolve({
                        new_update_times: response.data.new_update_times,
                        result: response.data.result,
                        errors: response.data.errors,
                    });
                } else {
                    // null means that there is no need to update the data
                    return Promise.resolve(null);
                }
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            if (
                error.response != null &&
                error.response.status !== 401 &&
                error.response.status !== 403
            ) {
                console.log(error);
                if (error.response != null)
                    return Promise.reject(
                        `${error.response.status} ${error.response.statusText}`
                    );
                else return Promise.reject(error.message);
            } else {
                return Promise.resolve(null);
            }
        });
}

export async function createCanvasTemplate(
    title: string,
    collectionId: number,
    copyId?: number,
    canvas?: Canvas["canvas"],
    backgrounds?: CanvasBackground[],
): Promise<number> {
    let requestJson = {
        copy_id: copyId,
        title: title,
        collection_id: collectionId,
        canvas: JSON.stringify(canvas),
        backgrounds: backgrounds?.map((background) =>
            serializeBackground(background)
        ),
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/create_canvas_template", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export async function editSlideTemplateTitle(
    slideId: number,
    title: string
): Promise<void> {
    let updateId = stringSessionId();
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/update_canvas_template_title", {
            update_id: updateId,
            id: slideId,
            title: title,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function editSlideTemplateThumbnail(
    slideId: number,
    thumbnail: string
): Promise<void> {
    let updateId = stringSessionId();
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/update_canvas_template_thumbnail", {
            update_id: updateId,
            id: slideId,
            thumbnail: thumbnail,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function getSlideTemplatesApi(
    collection_id: number,
    offset?: number,
    limit?: number,
    onlyHeaders?: boolean
): Promise<SlideTemplate[]> {
    let only_headers = onlyHeaders ?? false;
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            canvases?: TemplateResponse[];
        }>("/api/get_canvas_templates", {
            offset: offset,
            limit: limit,
            collection_id: collection_id,
            only_headers: only_headers
        })
        .then((response) => {
            if (response.data.success && response.data.canvases != null) {
                return Promise.resolve(
                    response.data.canvases.map((item) =>
                        toTemplate(item, only_headers)
                    )
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function getSlideTemplateApi(
    slideId: number,
    onlyHeaders?: boolean
): Promise<SlideTemplate> {
    let only_headers = onlyHeaders ?? false;
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            canvas?: TemplateResponse;
        }>("/api/get_canvas_template", {
            id: slideId,
            only_headers: only_headers,
        })
        .then((response) => {
            if (response.data.success && response.data.canvas != null) {
                return Promise.resolve(
                    toTemplate(response.data.canvas, only_headers)
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

export async function deleteCanvasTemplate(templateId: number): Promise<void> {
    let requestJson: {
        id: number;
        update_id?: string;
    } = {
        id: templateId,
    };
    requestJson.update_id = stringSessionId();
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/delete_canvas_template", requestJson)
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
}

interface ReplaceCanvasByTemplateResponse {
    id?: number,
    background_layer_id?: number;
}

export async function replaceCanvasByTemplate(
    templateId: number,
    canvasId: number
): Promise<ReplaceCanvasByTemplateResponse> {
    let requestJson = {
        template_id: templateId,
        canvas_id: canvasId,
        update_id: stringSessionId(),
    };
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
            background_layer_id?: number;
        }>("/api/replace_canvas_by_template", requestJson)
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return Promise.resolve(response.data);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function addNewCollectionTemplateApi(title: string): Promise<number> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/collection_template_add", {
            title: title,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.id!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function deleteCollectionTemplateApi(
    collectionId: number
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/collection_template_delete", {
            id: collectionId,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function editCollectionTemplateApi(
    collectionId: number,
    title?: string,
    hidden?: boolean
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/collection_template_edit", {
            title: title,
            id: collectionId,
            hidden: hidden,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getCollectionsTemplateApi(): Promise<Collection[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            collections?: Collection[];
        }>("/api/collections_template_get")
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.collections!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function getAllCollectionsTemplateApi(): Promise<AdminCollection[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            collections?: AdminCollection[];
        }>("/api/collections_template_get_all")
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.collections!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
