import React, { useContext } from "react";
import GlobalContext from "GlobalContext";
import Skeleton from "common/Skeleton";
import PresentationCard from "common/home_components/PresentationCard";
import {
    AbstractPresentationModel,
    PresentationType,
} from "state/models/Presentation";
import { useDrag, useDrop } from "react-dnd";
import styles from "./GridView.module.css";
import { Folder, FolderType } from "common/FoldersApi";
import FolderCard from "common/home_components/FolderCard";
import StoreContext from "state/StoreContext";
import ButtonCard from "common/home_components/ButtonCard";

interface Props {
    presentations: Array<AbstractPresentationModel>;
    folders?: Array<Folder>;
    currentFolder?: Folder;
    loading: boolean;
    showSkeleton?: boolean;
    deletePresentation: Function;
    clickPresentation: Function;
    deleteFolder?: Function;
    renameFolder?: Function;
    showViewAll: boolean;
    clickViewAll: React.MouseEventHandler;
}

interface DropProps {
    folderId: number;
    folderType: FolderType;
}

interface DragProps {
    presentationId: number;
}

const DragPresentationArea: React.FC<DragProps> = (props) => {
    const [, drag] = useDrag({
        item: {
            content: {
                presentationId: props.presentationId,
                type: "presentation",
            },
        },
        end: (item, monitor) => {},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        type: "presentation",
    });
    return (
        <div key={props.presentationId} ref={drag}>
            {props.children}
        </div>
    );
};

const DropFolderArea: React.FC<DropProps> = (props) => {
    const store = useContext(StoreContext);
    const [collected, drop] = useDrop({
        accept: "presentation",
        drop(
            otherItem: {
                content: {
                    presentationId: number;
                };
            },
            monitor
        ) {
            store.presentationStore.putPresentationIntoFolder(
                otherItem.content.presentationId,
                props.folderId,
                props.folderType
            );
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    });
    return (
        <div
            style={{
                outline: collected.hover ? "1px solid #39F" : undefined,
            }}
            ref={drop}
        >
            {props.children}
        </div>
    );
};

function GridView({
    presentations,
    folders,
    currentFolder,
    loading,
    showSkeleton,
    deletePresentation,
    clickPresentation,
    deleteFolder,
    renameFolder,
    showViewAll,
    clickViewAll,
}: Props) {
    const globalContext = useContext(GlobalContext);
    const store = useContext(StoreContext);

    return (
        <div className={styles.root}>
            {currentFolder != null && (
                <ButtonCard
                    text={"Back"}
                    onClick={() => {
                        store.presentationStore.setCurrentFolder(null);
                    }}
                />
            )}
            {currentFolder == null &&
                !loading &&
                folders &&
                folders.map((folder) => (
                    <DropFolderArea
                        folderId={folder.id}
                        key={"folder" + folder.id}
                        folderType={folder.type}
                    >
                        <FolderCard
                            folder={folder}
                            onClick={() => {
                                store.presentationStore.setCurrentFolder(
                                    folder
                                );
                            }}
                            onDelete={() => {
                                deleteFolder?.(folder);
                            }}
                            onRename={() => {
                                renameFolder?.(folder);
                            }}
                        />
                    </DropFolderArea>
                ))}
            {!loading &&
                presentations &&
                presentations.map((presentation) => (
                    <DragPresentationArea
                        presentationId={presentation.id as number}
                        key={"presentation" + presentation.id}
                    >
                        <PresentationCard
                            type={presentation.type}
                            folderId={(presentation as any).folderId}
                            folderType={
                                presentation.isTutorial()
                                    ? FolderType.Tutorials
                                    : presentation.type ===
                                      PresentationType.RegularPresentation
                                    ? FolderType.Presentations
                                    : FolderType.Templates
                            }
                            id={presentation.id}
                            title={presentation.title}
                            lastUpdatedAt={presentation.lastUpdated}
                            thumbnail={presentation.thumbnail}
                            owner={presentation.owner}
                            isKit={presentation.isKit()}
                            onClickDelete={
                                presentation.type !== PresentationType.App &&
                                (!presentation.isShared ||
                                    (presentation.isTutorial() &&
                                        "ImportModulesToTutorials" in
                                            globalContext.permissions) ||
                                    (presentation.type ===
                                        PresentationType.Template &&
                                        "ModifyAnyModule" in
                                            globalContext.permissions))
                                    ? deletePresentation
                                    : undefined
                            }
                            onClickCard={clickPresentation}
                        />
                    </DragPresentationArea>
                ))}
            {(loading || showSkeleton) && <Skeleton />}
            {!loading && showViewAll && (
                <ButtonCard text={"View all"} onClick={clickViewAll} />
            )}
        </div>
    );
}

export default GridView;
