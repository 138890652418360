import React, { useCallback, Component } from "react";

import { colorList } from "./LineColors";
import { mainStyle } from "common/MainStyle";
import Plotly from "plotly.js-gl3d-dist-min";
import { ScatterChartType } from "common/ScatterChartType";
import { TicksAndLabels } from "common/Finding";

//const Plot = createPlotlyComponent(Plotly);

export interface PlotlyScatterWithLabel {
    [name: string]: (number | string)[];
}

export interface PlotlyScatter {
    [name: string]: number[];
}

interface Props {
    data: PlotlyScatter[];
    label?: string;
    labels?: string[][];
    variables: string[];
    hoverVariables?: string[];
    units: string[];
    type: ScatterChartType;
    additionalData?: number[];
    nameMapping?: { [key: string]: string };
    axesColor?: string;
    chartColor?: string;
    strokeColor?: string;
    linesCount?: number | null;
    maxYRange?: number | null;
    minYRange?: number | null;
}

interface InnerProps {
    data: PlotlyScatter;
    label?: string;
    variables: string[];
    hoverVariables?: string[];
    labels?: string[];
    units: string[];
    type: ScatterChartType;
    additionalData?: number[];
    nameMapping?: { [key: string]: string };
    axesColor?: string;
    scatterStrokeColor?: string;
    axesLinesColor?: string;
    trendlineColor?: string;
    chartColor?: string;
    strokeColor?: string;
    linesCount?: number | null;
    maxYRange?: number | null;
    minYRange?: number | null;
    maxXRange?: number | null;
    minXRange?: number | null;
    size?: number;
    hideAxes?: boolean;
    hideTicks?: boolean;
    hideGrid?: boolean;
    hideAxesNames?: boolean;
    tooltipColor?: string;
    tooltipFontSize?: number;
    ticksSize?: number;
    ticksAndLabels?: TicksAndLabels;
    trendlineInfo?: {
        coef: number;
        intercept: number;
    } | null;
}

function ParentPlotDiv(props: { onInitialize: (node: any) => void }) {
    const rootRef = useCallback(
        (node) => {
            if (node !== null) {
                props.onInitialize(node);
            }
        },
        [props]
    );
    return (
        <div
            ref={rootRef}
            style={{
                width: "100%",
                height: "100%",
            }}
        ></div>
    );
}

export class SingleScatterChart extends Component<InnerProps> {
    private plotInstance: any;
    private rootNode: any;
    private resizeObserver: ResizeObserver;
    private lastWidth: { height: number; width: number } = {
        height: 0,
        width: 0,
    };

    constructor(props: InnerProps) {
        super(props);

        this.plotInstance = null;
        this.rootNode = null;
        this.resizeObserver = new ResizeObserver(
            (entries: any, _observer: any) => {
                let newRect = entries[0].contentRect;
                if (
                    Math.abs(newRect.width - this.lastWidth.width) > 5 ||
                    Math.abs(newRect.height - this.lastWidth.height) > 5
                ) {
                    this.lastWidth.height = newRect.height;
                    this.lastWidth.width = newRect.width;
                    this.initializePlot();
                }
            }
        );
    }
    componentDidUpdate() {}
    initializePlot() {
        if (this.rootNode === null) return;
        var xAxisFontSize =
            this.props.ticksAndLabels?.x?.size ?? this.props.ticksSize ??
            mainStyle.getPropertyValue("--graphs-axes-size").trim();
                
        var yAxisFontSize =
            this.props.ticksAndLabels?.y?.size ?? this.props.ticksSize ??
            mainStyle.getPropertyValue("--graphs-axes-size").trim();

        let {
            data,
            variables,
            hoverVariables,
            units,
            type,
            additionalData,
            labels,
            label,
            nameMapping,
            chartColor,
            axesColor,
            minYRange,
            maxYRange,
            minXRange,
            maxXRange,
            scatterStrokeColor,
            axesLinesColor,
            tooltipColor,
            tooltipFontSize,
            trendlineInfo,
            trendlineColor,
        } = this.props;
        let strokeColor =
            axesLinesColor ??
            mainStyle.getPropertyValue("--graphs-stroke-color").trim();
        let fontColor =
            axesColor ??
            mainStyle.getPropertyValue("--graphs-axes-text-color").trim();
        let plotData: { [name: string]: number[] } = {};
        let customData: any[] = [];
        let axesNames: { [name: string]: string } = {
            0: "x",
            1: "y",
            2: "z",
        };
        let hovertemplate = `${
            nameMapping?.[variables[0]] ?? variables[0]
        }: %{x}<br>${nameMapping?.[variables[1]] ?? variables[1]}: %{y}`;
        if (variables.length === 3) {
            hovertemplate += `<br>${
                nameMapping?.[variables[2]] ?? variables[2]
            }: %{z}`;
        }
        let additionalhovertemplate = hovertemplate;
        if (labels != null && label != null) {
            hovertemplate += `<br>${nameMapping?.[label] ?? label}: %{text}`;
        }
        if (hoverVariables != null) {
            for (let i in hoverVariables) {
                hovertemplate += `<br>${hoverVariables[i]}: %{customdata[${i}]}`;
            }
        }
        hovertemplate += "<extra></extra>";
        additionalhovertemplate += "<extra></extra>";
        let layout: any = {
            margin: {
                l: 25,
                r: 25,
                b: 25,
                t: 25,
            },
            showlegend: false,
            plot_bgcolor: "transparent",
            paper_bgcolor: "transparent",
        };
        let fontOptions = {
            color: fontColor,
            size: xAxisFontSize,
            fontFamily: "Arial",
        };
        let layoutOptions = {
            xaxis: {
                showgrid: this.props.hideGrid ? false : undefined,
                showline: this.props.hideAxes ? false : undefined,
                zeroline: this.props.hideGrid ? false : undefined,
                gridcolor: strokeColor,
                linecolor: strokeColor,
                tickfont: {
                    ...fontOptions,
                    size: xAxisFontSize,
                },
                zerolinecolor: strokeColor,
                ticksuffix: units[0],
                range: [minXRange, maxXRange],
                title: this.props.hideAxesNames
                    ? undefined
                    : {
                          text: nameMapping?.[variables[0]] ?? variables[0],
                          font: {
                            ...fontOptions,
                            size: xAxisFontSize,
                        },
                      },
                automargin: true,
            },
            yaxis: {
                showgrid: this.props.hideGrid ? false : undefined,
                showline: this.props.hideAxes ? false : undefined,
                zeroline: this.props.hideGrid ? false : undefined,
                gridcolor: strokeColor,
                zerolinecolor: strokeColor,
                linecolor: strokeColor,
                tickfont: {
                    ...fontOptions,
                    size: yAxisFontSize,
                },
                ticksuffix: units[1],
                range: [minYRange, maxYRange],

                title: this.props.hideAxesNames
                    ? undefined
                    : {
                          text: nameMapping?.[variables[1]] ?? variables[1],
                          font: {
                            ...fontOptions,
                            size: yAxisFontSize,
                        },
                      },
                automargin: true,
            },
            zaxis: {
                showgrid: this.props.hideGrid ? false : undefined,
                showline: this.props.hideAxes ? false : undefined,
                zeroline: this.props.hideGrid ? false : undefined,
                gridcolor: strokeColor,
                zerolinecolor: strokeColor,
                linecolor: strokeColor,
                tickfont: fontOptions,
                ticksuffix: units.length === 3 ? units[2] : "",
                title: this.props.hideAxesNames
                    ? undefined
                    : {
                          text:
                              variables.length === 3
                                  ? nameMapping?.[variables[2]] ?? variables[2]
                                  : "",
                          font: fontOptions,
                      },
                automargin: true,
            },
        };
        if (type === ScatterChartType.TwoD) {
            layout = {
                ...layout,
                ...layoutOptions,
            };
        } else {
            layout.scene = layoutOptions;
        }
        variables.forEach((variable, index) => {
            plotData[axesNames[index]] = data[variable];
        });
        let dataLength = data[variables[0]].length;
        for (let i = 0; i < dataLength; ++i) {
            let customDataPoint: any[] = [];
            hoverVariables?.forEach((variable) => {
                customDataPoint.push(data[variable][i]);
            });
            customData.push(customDataPoint);
        }

        let config = { displayModeBar: false, responsive: true };
        let plotlyData: Partial<Plotly.PlotData>[] = [
            {
                ...plotData,
                type: type === ScatterChartType.TwoD ? "scatter" : "scatter3d",
                mode: "text+markers",
                marker: {
                    line: scatterStrokeColor
                        ? {
                              color: scatterStrokeColor,
                              width: 1,
                          }
                        : undefined,
                    color: chartColor ?? colorList[1],
                    size: this.props.size,
                },
                hovertemplate: hovertemplate,
                hoverlabel: {
                    font: {
                        family: fontOptions.fontFamily,
                        size: tooltipFontSize ?? 14,
                        color: fontOptions.color,
                    },
                    bgcolor:
                        tooltipColor ??
                        mainStyle.getPropertyValue(
                            "--charts-tooltip-background-color"
                        ),
                },
                text: labels,
                customdata: customData,
                textposition: "top center",
                textfont: {
                    family: fontOptions.fontFamily,
                    size: Number(fontOptions.size),
                    color: fontOptions.color,
                },
            },
        ];
        if (trendlineInfo != null) {
            let x = plotData[axesNames[0]];
            let y = x.map(
                (item) => item * trendlineInfo!.coef + trendlineInfo!.intercept
            );
            let trendline: Partial<Plotly.PlotData> = {
                type: "scatter",
                x: x,
                y: y,
                mode: "lines",
                name: "Trendline",
                line: {
                    color: trendlineColor ?? colorList[0],
                    width: 2,
                },
            };
            plotlyData.push(trendline);
        }
        if (additionalData != null) {
            let additionalPlotData: { [name: string]: number[] } = {};
            additionalData.forEach((value, index) => {
                additionalPlotData[axesNames[index]] = [value];
            });
            plotlyData.push({
                ...additionalPlotData,
                type: type === ScatterChartType.TwoD ? "scatter" : "scatter3d",
                mode: "markers",
                marker: { color: "#39F" },
                hovertemplate: additionalhovertemplate,
            });
        }
        this.plotInstance = Plotly.newPlot(
            this.rootNode,
            plotlyData,
            layout,
            config
        );
    }

    render() {
        let plot = (
            <ParentPlotDiv
                onInitialize={(node) => {
                    this.rootNode = node;
                    this.resizeObserver.observe(this.rootNode);
                    this.initializePlot();
                }}
            />
        );

        return plot;
    }
}

export default function TwoDScatterChar(props: Props) {
    let {
        data,
        variables,
        hoverVariables,
        units,
        type,
        additionalData,
        labels,
        label,
        nameMapping,
        axesColor,
        chartColor,
        linesCount,
        maxYRange,
        minYRange,
    } = props;
    let scatterDatas = data;
    return (
        <div
            className="flex-simple-column"
            style={{ width: "100%", height: "100%" }}
        >
            <div
                className="my-row"
                style={{
                    marginTop: 10,
                    width: "100%",
                    height: "calc(100% - 40px)",
                }}
            >
                {scatterDatas.map((scatterData, groupIndex) => {
                    let title = "";
                    // if (data[groupIndex].where) {
                    // 	title = `${
                    // 		data[groupIndex].where.group ||
                    // 		data[groupIndex].where.group_name
                    // 	} ${data[groupIndex].where.operation ?? "="} ${
                    // 		data[groupIndex].where.value
                    // 	}`;
                    // }
                    return (
                        <div
                            style={{
                                width: `calc(100%/${scatterDatas.length})`,
                                height: "100%",
                            }}
                            key={groupIndex}
                            onMouseDown={(evt) => {
                                evt.stopPropagation();
                            }}
                        >
                            {data[groupIndex].where ? (
                                <div
                                    className="center-container"
                                    style={{ marginBottom: 10 }}
                                >
                                    <span
                                        style={{
                                            color: mainStyle.getPropertyValue(
                                                "--exploration-tertiary-text-color"
                                            ),
                                            fontFamily: "Arial",
                                            fontSize: "12px",
                                            lineHeight: "13px",
                                        }}
                                    >
                                        {title}
                                    </span>
                                </div>
                            ) : null}
                            <div
                                className="my-row center-container"
                                style={{
                                    flexWrap: "wrap",
                                    marginLeft: 20,
                                    marginRight: 20,
                                    width: "100%",
                                    height: "16",
                                }}
                            ></div>

                            <SingleScatterChart
                                labels={
                                    labels != null
                                        ? labels[groupIndex]
                                        : undefined
                                }
                                hoverVariables={hoverVariables}
                                chartColor={chartColor}
                                axesColor={axesColor}
                                maxYRange={maxYRange}
                                minYRange={minYRange}
                                linesCount={linesCount}
                                nameMapping={nameMapping}
                                label={label}
                                data={scatterData}
                                variables={variables}
                                units={units}
                                type={type}
                                additionalData={additionalData}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
