import React from "react";
import TimePatternsChart from "common/graphics/v2/TimePatternsChart";
import { LinePlotFinding } from "common/Finding";

interface Props {
    dashboardId: string;
    finding: LinePlotFinding;
    selected: boolean;
    onNewFinding?: (finding: LinePlotFinding, updateData?: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
}

function MainComponent(props: Props) {
    let finding = props.finding;
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <TimePatternsChart
                dashboardId={props.dashboardId}
                columnDragActive={props.columnDragActive}
                selected={props.selected}
                editable={props.editable}
                preview={props.preview}
                config={finding.config}
                content={finding.content}
                onChangeData={
                    props.onNewFinding
                        ? (
                              data: LinePlotFinding["content"]["data"],
                              updateData?: boolean
                          ) => {
                              let finding = {
                                  ...props.finding,
                                  content: {
                                      ...props.finding.content,
                                      data: data,
                                  },
                              };
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
                onChangeConfig={
                    props.onNewFinding
                        ? (
                              config: LinePlotFinding["config"],
                              updateData?: boolean
                          ) => {
                              let finding = {
                                  ...props.finding,
                                  config: config,
                              };
                              props.onNewFinding!(finding, updateData);
                          }
                        : undefined
                }
            />
        </div>
    );
}

export { MainComponent };
