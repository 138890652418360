import React, { useEffect } from "react";
import { observer } from "mobx-react";
import styles from "./AnalyzeSection.module.css";
import {
    LeversOutcomePredictionFinding,
    LeversOutcomeType,
} from "common/Finding";
import { Api } from "modules/data_exploration_page/modules/analyze_lever_outcome_relationship/ApiV2";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import Tables from "common/Tables";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import AnalyzeSectionComponent from 'modules/canvas_page/canvas_elements/NewDashboards/ChartsRibbon/DataSection/AnalyzeSection'
import SaveRegressionResultsPopup from "common/SaveRegressionResultsPopup";
import DataScopes from "common/DataScopes";
import SelectedOption from "../SelectedOption";
import TabSwitchConfirmationPopup from "common/TabSwitchConfirmationPopup";
// import { dataScopes } from 'common/DataScopes';

const journeyName = "AnalyzeLeverOutcomeRelationshipJourney";
const mockCanvasTreeStore = new CanvasTreeStore();
function AnalyzeSection(props: { setOperationInProgress: (state: boolean) => void; }) {

    const executeRegression = async (
        finding: LeversOutcomePredictionFinding
    ) => {
        setProcessing(true);

        setStatusPopup({
            status: PopupStatus.Success,
            message: "Processing...",
        });
        try {
            let newFinding = await Api.getData(finding, undefined, undefined);
            setProcessing(false);
            setStatusPopup({
                status: PopupStatus.Success,
                message: "Success",
            });
            setTimeout(() => {
                setStatusPopup(null)
            }, 3000);
            setFinding(newFinding);
        } catch (error) {
            setProcessing(false);
            setStatusPopup({
                status: PopupStatus.Error,
                message: String(error),
            });
        }
    };
    let [finding, setFinding] = React.useState<LeversOutcomePredictionFinding>({
        ...Api.getPreviewFinding(journeyName),
        config: {
            ...Api.getPreviewFinding(journeyName).config,
            outputType: LeversOutcomeType.Raw,
            hideOutputTypeChange: true,
        },
    });

    let [statusPopup, setStatusPopup] = React.useState<{
        status: PopupStatus;
        message: string;
    } | null>(null);

    let [processing, setProcessing] = React.useState<boolean>(false);

    useEffect(() => {
        DataScopes.previewState = { headers: null, body: null }
        return () => {
            setFinding({
                ...Api.getPreviewFinding(journeyName),
                config: {
                    ...Api.getPreviewFinding(journeyName).config,
                    outputType: LeversOutcomeType.Raw,
                    hideOutputTypeChange: true,
                },
            })
            DataScopes.previewState = { headers: null, body: null }
        }
    }, [])


    // useEffect(() => {
    //     setFinding((oldFinding) => {
    //         let newFinding =
    //             Api.getPreviewFinding(journeyName);
    //         console.log(oldFinding)
    //         console.log(newFinding);
    //         for (let key in oldFinding.content) {
    //             (oldFinding?.content as any)[key] = null;
    //         }
    //         for (let key in oldFinding.config) {
    //             (oldFinding.config as any)[key] = null;
    //         }
    //         newFinding.content = {
    //             ...oldFinding.content,
    //             ...newFinding.content,
    //         };
    //         newFinding.config = {
    //             ...oldFinding.config,
    //             ...newFinding.config,
    //         };
    //         const dataScopedemo = {
    //             LastUpdated: "23/06/2022",
    //             Uploaded: "23/06/2022",
    //             label: "check 2",
    //             owner: "admin",
    //             permissionType: 10,
    //             value: "2",
    //         }
    //         return {
    //             ...newFinding,
    //             config: {
    //                 ...newFinding.config,
    //                 outputType: LeversOutcomeType.Raw,
    //                 hideOutputTypeChange: true,
    //                 dataScope: dataScopedemo,
    //                 selectedTable: Tables(
    //                     dataScopedemo.value
    //                 ).tableToOption(),
    //             },
    //         };
    //     });
    // }, [])
    const [showSaveRegressionPopup, setShowRegressionPopup] =
        React.useState(false);

    React.useEffect(() => {
        props.setOperationInProgress((finding)? true : false);
    }, [finding])

    return (
        <div className={"mainContainer"} style={{ padding: 0 }}>
            <div className={styles.datasetsContainer}>
                <div className={styles.datasetSelector}>

                    <AnalyzeSectionComponent
                        updatingDashboard={processing}
                        dashboardId={""}
                        canvasTreeStore={mockCanvasTreeStore}
                        finding={finding}
                        columnDragActive={false}
                        connectDataset={true}
                        onClearEditing={() => { }}
                        onChangeDataScope={(dataScope: any) => {
                            setFinding((oldFinding) => {
                                let newFinding =
                                    Api.getPreviewFinding(journeyName);
                                for (let key in oldFinding.content) {
                                    (oldFinding?.content as any)[key] = null;
                                }
                                for (let key in oldFinding.config) {
                                    (oldFinding.config as any)[key] = null;
                                }
                                newFinding.content = {
                                    ...oldFinding.content,
                                    ...newFinding.content,
                                };
                                newFinding.config = {
                                    ...oldFinding.config,
                                    ...newFinding.config,
                                };

                                return {
                                    ...newFinding,
                                    config: {
                                        ...newFinding.config,
                                        outputType: LeversOutcomeType.Raw,
                                        hideOutputTypeChange: true,
                                        dataScope: dataScope,
                                        selectedTable: Tables(
                                            dataScope.value
                                        ).tableToOption(),
                                    },
                                };
                            });
                        }}
                        onChangeConditions={(conditions: any) => {
                            setFinding((oldFinding) => {
                                return {
                                    ...oldFinding,
                                    config: {
                                        ...oldFinding.config,
                                        conditions: conditions,
                                    },
                                };
                            });
                        }}
                        onChangeSelectedTable={(selectedTable: any) => {
                            setFinding((oldFinding) => {
                                return {
                                    ...oldFinding,
                                    config: {
                                        ...oldFinding.config,
                                        selectedTable: selectedTable,
                                        conditions: null,
                                    },
                                };
                            });
                        }}
                        onChange={(finding: any, updateData: any) => {
                            if (updateData && processing) return;
                            setFinding(finding);
                            if (updateData) {
                                setProcessing(true);
                                executeRegression(finding);
                            }
                        }}
                        onConnectDataset={() => { }}
                    />

                    <h3 className={styles.textItem}>
                        Regression result
                    </h3>

                    <div className={styles.mainPreviewContainer} style={{
                        display: finding.content?.data.length > 1 ? "grid" : "flex"
                    }}>
                        {finding.content?.data?.map((item: any, index: any) => (
                            <div style={{
                                width: '100%',
                                height: '236px',
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: '6px',
                            }}>
                                <div
                                    className={styles.modelContainer}
                                    style={{
                                        width: '100%',
                                        height: '229px',
                                        padding: '15px',
                                        background: "var(--greyColor)",
                                        borderRadius: '6px',
                                    }}
                                >
                                    {
                                        finding.content?.data[0]?.summary ?
                                            <textarea
                                                disabled
                                                className={`${styles.summaryArea} scrollBar`}
                                                style={{
                                                    resize: "none",
                                                    height: "100%",
                                                    width: "100%",
                                                    border: 'none',
                                                    backgroundColor: 'white',
                                                    padding: '5px',
                                                    paddingLeft: 12,
                                                    borderRadius: 5,
                                                    fontStyle: 'normal',
                                                    fontSize: 13,
                                                    color: "#000",
                                                }}
                                                onKeyDown={(evt) => {
                                                    evt.stopPropagation();
                                                }}
                                                onMouseDown={(evt) => {
                                                    evt.stopPropagation();
                                                }}
                                                value={finding.content?.data[0]?.summary}
                                            ></textarea>
                                            : <p style={{ textAlign: 'center', width: '100%' }}>Preview</p>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {
                        finding?.config.dataScope != null &&
                        <div className="pageEndbuttons" style={{ marginBottom: 0 }}>
                            <button
                                type="button"
                                disabled={finding?.content.nativeData == null}
                                className="acceptBtn"
                                style={{
                                    paddingBlock: 7,
                                    paddingInline: 10,
                                }}
                                onClick={() => {
                                    setShowRegressionPopup(true);
                                }}
                            >
                                Save regression to dataset
                            </button>
                        </div>
                    }
                </div>
            </div>



            {
                (SelectedOption.getAskSwitchTab && finding?.config.dataScope != null) &&
                <TabSwitchConfirmationPopup />
            }

            {
                statusPopup != null && (
                    <StatusPopup
                        status={statusPopup.status}
                        message={statusPopup.message}
                        onClose={() => {
                            setStatusPopup(null);
                        }}
                    />
                )
            }

            {
                showSaveRegressionPopup && (
                    <SaveRegressionResultsPopup
                        regressionResults={finding?.content.nativeData}
                        // currentModuleId={currentModuleId}
                        onClose={() => {
                            setShowRegressionPopup(false);
                        }}
                    />
                )
            }
        </div >
    );
}

export default observer(AnalyzeSection);
