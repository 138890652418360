import { StylesConfig, GroupBase } from "react-select";

export function getSpreadSheetDropdownSelectStyle<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    fontSize: number,
    hover: boolean
): StylesConfig<Option, IsMulti, Group> {
    return {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? "#657D951A"
                : state.isSelected
                    ? "rgba(101, 125, 149, 0.1)"
                    : "#FFFFFF",
            color: state.isSelected ? "#1F8EFA" : "#657D95",
            fontSize: fontSize,
            textAlign: "center",
            fontFamily: "Roboto",
        }),
        control: (provided, _state) => {
            let borderOptions = {
                borderRadius: "4px",
                border: "none",
            };
            return {
                ...provided,
                backgroundColor: hover ? "#1F8EFA33" : "#FFFFFF",
                minHeight: 18,
                ...borderOptions,
            };
        },
        singleValue: (provided, state) => ({
            ...provided,
            color: "#000000",
            fontSize: fontSize,
            width: "100%",
            textAlign: "center",
            fontFamily: "Roboto",
            display: state.selectProps.menuIsOpen ? "none" : "block",
        }),
        input: (provided, _state) => ({
            ...provided,
            backgroundColor: "transparent",
            border: 0,
            textAlign: "center",
            width: "100%",
            color: "#1F8EFA",
            fontSize: fontSize,
            fontFamily: "Roboto",
        }),
        valueContainer: (
            provided,
            _state
        ) => ({
            ...provided,
            padding: 0,
        }),
        menu: (provided, _state) => ({
            ...provided,
            backgroundColor: "#FFFFFF",
            borderRadius: 3,
        }),
        placeholder: (provided, _state) => ({
            ...provided,
            fontSize: fontSize,
            opacity: 0.3,
        }),
        indicatorsContainer: (
            provided,
            _state
        ) => {
            if (!hover)
                return {
                    ...provided,
                    visibility: "hidden",
                };
            return provided;
        },
        indicatorSeparator: (
            provided,
            _state
        ) => ({
            ...provided,
            width: 0,
        }),
        dropdownIndicator: (
            provided,
            _state
        ) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            color: "#39F",
            transformOrigin: "right center",
            transform: "scale(0.5)",
        }),
    };
}
