import React, { useEffect } from "react";
import { LeversOutcomePredictionFinding } from "common/Finding";
import { VariableOption } from "common/Variables";
import SaveRegressionResultsPopup from "common/SaveRegressionResultsPopup";
import commonStyles from "../../DataSection.module.css";
import styles from "./RegressionOptionsSelector.module.css";
import cx from "classnames";
import Select, { createFilter } from "react-select";
import {
    getCustomSelectStyleForDataSection,
    getCustomSelectStyleForRegressionOptions,
} from "common/SelectStyles";
import "pretty-checkbox/dist/pretty-checkbox.min.css";

function Checkbox(props: {
    value: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
}) {
    return (
        <div
            className="pretty p-default"
            contentEditable={false}
            style={{ marginRight: 0, marginTop: -4 }}
        >
            <input
                disabled={props.disabled}
                type="checkbox"
                checked={props.value}
                onChange={() => {
                    props.onChange(!props.value);
                }}
            />
            <div className="state p-primary">
                <label className={commonStyles.optionCheckboxName}></label>
            </div>
        </div>
    );
}

interface Props {
    finding: LeversOutcomePredictionFinding;
    currentModuleId?: number;
    variableOptions: VariableOption[];
    onChange: (
        finding: LeversOutcomePredictionFinding,
        updateData?: boolean
    ) => void;
}

export default function RegressionOptionsSelector(props: Props) {
    let variables = props.variableOptions.filter(
        (item) => item.value !== props.finding.config.dependentVariable?.value
    );
    let selectStyles = getCustomSelectStyleForDataSection(14, false);
    let regressionStyles = getCustomSelectStyleForRegressionOptions(14, false);

    const [showSaveRegressionPopup, setShowRegressionPopup] =
        React.useState(false);
    const allIncluded =
        props.finding.config.intercept != null &&
        variables.reduce(
            (acc, item) =>
                acc &&
                props.finding.config.independentVariables[item.value] != null,
            true
        );
    const allCategorical = variables.reduce(
        (acc, item) =>
            (acc &&
                props.finding.config.independentVariables[item.value] ==
                    null) ||
            (item.type !== "int" && item.type !== "float"),
        true
    );
    const allExcluded = variables.reduce(
        (acc, item) =>
            acc &&
            props.finding.config.independentVariables[item.value] == null,
        true
    );
    const allShowed =
        !(props.finding.config.intercept == null && allExcluded) &&
        (props.finding.config.intercept?.show ?? true) &&
        Object.values(props.finding.config.independentVariables).reduce(
            (acc, item) => acc && (item == null || (item as any)!.show),
            true
        );
    const allFixed =
        !allExcluded &&
        variables.reduce(
            (acc, item) =>
                acc &&
                (props.finding.config.independentVariables[item.value] ==
                    null ||
                    props.finding.config.independentVariables[item.value]
                        .fixed),
            true
        );
    const allLogs =
        !allCategorical &&
        !allExcluded &&
        variables.reduce(
            (acc, item) =>
                acc &&
                (props.finding.config.independentVariables[item.value] ==
                    null ||
                    (item.type !== "int" && item.type !== "float") ||
                    props.finding.config.independentVariables[item.value].log),
            true
        );

    const includeAll = (include: boolean) => {
        let newFinding = { ...props.finding };
        if (include) {
            if (newFinding.config.intercept == null) {
                newFinding.config.intercept = {
                    show: true,
                    sig: true,
                };
            }
            variables.forEach((item) => {
                if (
                    newFinding.config.independentVariables[item.value] == null
                ) {
                    newFinding.config.independentVariables[item.value] = {
                        sig: true,
                        show: true,
                        log: false,
                        fixed: item.type !== "int" && item.type !== "float",
                        label: item.label,
                        value: item.value,
                    };
                }
            });
        } else {
            newFinding.config.intercept = null;
            for (let key in newFinding.config.independentVariables) {
                newFinding.config.independentVariables[key] = null;
            }
        }
        props.onChange(newFinding);
    };
    const logAll = (log: boolean) => {
        let newFinding = { ...props.finding };
        variables.forEach((item) => {
            if (
                newFinding.config.independentVariables[item.value] != null &&
                (item.type === "float" || item.type === "int")
            ) {
                newFinding.config.independentVariables[item.value].log = log;
            }
        });

        props.onChange(newFinding);
    };
    const fixAll = (fix: boolean) => {
        let newFinding = { ...props.finding };
        variables.forEach((item) => {
            if (
                newFinding.config.independentVariables[item.value] != null &&
                (item.type === "float" || item.type === "int")
            ) {
                newFinding.config.independentVariables[item.value].fixed = fix;
            }
        });
        props.onChange(newFinding);
    };
    const showAll = (show: boolean) => {
        let newFinding = { ...props.finding };
        if (newFinding.config.intercept != null) {
            newFinding.config.intercept.show = show;
        }
        Object.values(newFinding.config.independentVariables).forEach(
            (item) => {
                if (item != null) {
                    (item as any).show = show;
                }
            }
        );
        props.onChange(newFinding);
    };
    let independentVariables: LeversOutcomePredictionFinding["config"]["independentVariables"] =
        props.finding.config.independentVariables;
    let mainVariable: LeversOutcomePredictionFinding["config"]["independentVariables"][number] =
        Object.values(props.finding.config.independentVariables).find(
            (item: any) => item != null && (item as any).main
        ) ??
        Object.values(props.finding.config.independentVariables).find(
            (item: any) => item != null
        ) ??
        null;
    useEffect(() => {
        if (mainVariable != null && !mainVariable.main) {
            let newFinding = { ...props.finding };
            newFinding.config.independentVariables[mainVariable.value].main =
                true;
            props.onChange(newFinding);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainVariable]);

    return (
        <div>
            <div className={styles.dataContainer}>
                <div className={styles.headerItem} />
                <div className={cx(styles.checkboxColumn, styles.headerItem)} style={{ height: 50 }}>
                    <div
                        className={styles.optionName}
                        style={{ marginBottom: 4 }}
                    >
                        include
                    </div>
                    <Checkbox
                        value={allIncluded}
                        onChange={() => {
                            includeAll(!allIncluded);
                        }}
                    />
                </div>
                <div className={cx(styles.checkboxColumn, styles.headerItem)} style={{ height: 50 }}>
                    <div
                        className={styles.optionName}
                        style={{ marginBottom: 4 }}
                    >
                        log
                    </div>
                    <Checkbox
                        value={allLogs}
                        onChange={() => {
                            logAll(!allLogs);
                        }}
                    />
                </div>
                <div className={cx(styles.checkboxColumn, styles.headerItem)} style={{ height: 50 }}>
                    <div
                        className={styles.optionName}
                        style={{ marginBottom: 4 }}
                    >
                        fixed
                    </div>
                    <Checkbox
                        value={allFixed}
                        onChange={() => {
                            fixAll(!allFixed);
                        }}
                    />
                </div>
                <div
                    className={cx(styles.optionName, styles.headerItem)}
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    interact with
                </div>

                <div className={cx(styles.checkboxColumn, styles.headerItem)} style={{ height: 50 }}>
                    <div
                        className={styles.optionName}
                        style={{ marginBottom: 4 }}
                    >
                        show
                    </div>
                    <Checkbox
                        value={allShowed}
                        onChange={() => {
                            showAll(!allShowed);
                        }}
                    />
                </div>

                <div className={cx(styles.optionName, styles.mediumOptionName)}>
                    intercept
                </div>
                <div className={styles.checkboxColumn}>
                    <Checkbox
                        value={props.finding.config.intercept != null}
                        onChange={() => {
                            let newFinding = { ...props.finding };
                            newFinding.config.intercept =
                                newFinding.config.intercept == null
                                    ? {
                                          show: true,
                                          sig: true,
                                      }
                                    : null;
                            props.onChange(newFinding);
                        }}
                    />
                </div>
                <div />

                <div />
                <div />
                <div className={styles.checkboxColumn}>
                    <Checkbox
                        disabled={props.finding.config.intercept == null}
                        value={props.finding.config.intercept?.show}
                        onChange={() => {
                            let newFinding = { ...props.finding };
                            newFinding.config.intercept.show =
                                !newFinding.config.intercept.show;
                            props.onChange(newFinding);
                        }}
                    />
                </div>
                {variables.map((item, index) => (
                    <React.Fragment key={index}>
                        <div
                            className={cx(
                                styles.optionName,
                                styles.mediumOptionName
                            )}
                        >
                            {item.label}
                        </div>
                        <div className={styles.checkboxColumn}>
                            <Checkbox
                                value={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ] != null
                                }
                                onChange={() => {
                                    let newFinding = { ...props.finding };
                                    if (
                                        props.finding.config
                                            .independentVariables[item.value] ==
                                        null
                                    ) {
                                        newFinding.config.independentVariables[
                                            item.value
                                        ] = {
                                            sig: true,
                                            show: true,
                                            log: false,
                                            fixed:
                                                item.type !== "int" &&
                                                item.type !== "float",
                                            label: item.label,
                                            value: item.value,
                                        };
                                    } else {
                                        newFinding.config.independentVariables[
                                            item.value
                                        ] = null;
                                        if (
                                            item.value === mainVariable?.value
                                        ) {
                                            for (let value of Object.values(
                                                newFinding.config
                                                    .independentVariables
                                            )) {
                                                if (value != null) {
                                                    (
                                                        value as any
                                                    ).interacts_with = false;
                                                }
                                            }
                                        }
                                    }

                                    props.onChange(newFinding);
                                }}
                            />
                        </div>
                        <div className={styles.checkboxColumn}>
                            <Checkbox
                                value={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ]?.log
                                }
                                disabled={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ] == null ||
                                    (item.type !== "int" &&
                                        item.type !== "float")
                                }
                                onChange={() => {
                                    let newFinding = { ...props.finding };
                                    newFinding.config.independentVariables[
                                        item.value
                                    ].log =
                                        !newFinding.config.independentVariables[
                                            item.value
                                        ].log;
                                    props.onChange(newFinding);
                                }}
                            />
                        </div>
                        <div className={styles.checkboxColumn}>
                            <Checkbox
                                value={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ]?.fixed &&
                                    !props.finding.config.independentVariables[
                                        item.value
                                    ]?.interacts_with
                                }
                                disabled={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ] == null ||
                                    props.finding.config.independentVariables[
                                        item.value
                                    ]?.interacts_with ||
                                    (item.type !== "int" &&
                                        item.type !== "float")
                                }
                                onChange={() => {
                                    let newFinding = { ...props.finding };
                                    newFinding.config.independentVariables[
                                        item.value
                                    ].fixed =
                                        !newFinding.config.independentVariables[
                                            item.value
                                        ].fixed;
                                    props.onChange(newFinding);
                                }}
                            />
                        </div>
                        {mainVariable?.value === item.value ? (
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                isClearable
                                backspaceRemovesValue
                                menuPortalTarget={document.body}
                                placeholder={"Select"}
                                styles={{
                                    ...regressionStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={Object.values(
                                    independentVariables
                                ).filter(
                                    (
                                        item: LeversOutcomePredictionFinding["config"]["independentVariables"][number]
                                    ) =>
                                        item != null &&
                                        item.value !== mainVariable?.value &&
                                        ["int", "float"].includes(
                                            variables.find(
                                                (variable) =>
                                                    variable.value ===
                                                    item.value
                                            )?.type ?? ""
                                        )
                                )}
                                onChange={(newValue) => {
                                    let newFinding = { ...props.finding };
                                    for (let value of Object.values(
                                        independentVariables
                                    )) {
                                        if (value != null) {
                                            if (newValue != null) {
                                                (value as any).interacts_with =
                                                    (newValue as any).value ===
                                                    (value as any).value;
                                            } else {
                                                (value as any).interacts_with =
                                                    false;
                                            }
                                        }
                                    }
                                    newFinding.config.independentVariables =
                                        independentVariables;
                                    props.onChange(newFinding);
                                }}
                                value={
                                    Object.values(
                                        props.finding.config
                                            .independentVariables
                                    ).find(
                                        (item: any) =>
                                            item != null &&
                                            (item as any).interacts_with
                                    ) ?? null
                                }
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                            />
                        ) : (
                            <div />
                        )}
                        <div className={styles.checkboxColumn}>
                            <Checkbox
                                disabled={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ] == null
                                }
                                value={
                                    props.finding.config.independentVariables[
                                        item.value
                                    ]?.show
                                }
                                onChange={() => {
                                    let newFinding = { ...props.finding };
                                    newFinding.config.independentVariables[
                                        item.value
                                    ].show =
                                        !newFinding.config.independentVariables[
                                            item.value
                                        ].show;
                                    props.onChange(newFinding);
                                }}
                            />
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className={commonStyles.thinOptionName}>Main variable</div>
            <Select
                filterOption={createFilter({
                    ignoreAccents: false,
                })}
                placeholder={"Main variable"}
                styles={{
                    ...selectStyles,
                    container: (base) => ({
                        ...base,
                        marginTop: "10px",
                        width: "100%",
                        height: "38px",
                    }),
                }}
                options={Object.values(independentVariables).filter(
                    (item) => item != null
                )}
                onChange={(newValue) => {
                    let newFinding = { ...props.finding };
                    for (let value of Object.values(independentVariables)) {
                        if (value != null) {
                            (value as any).main =
                                (newValue as any).value ===
                                (value as any).value;
                            (value as any).interacts_with = false;
                        }
                    }
                    newFinding.config.independentVariables =
                        independentVariables;
                    props.onChange(newFinding);
                }}
                value={mainVariable}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        text: "white",
                        primary25: "var(--selectors-background-hover-color)",
                    },
                })}
            />
            <div className={styles.buttonsContainer}>
                <button
                    type="button"
                    disabled={
                        allExcluded ||
                        props.finding.config.dependentVariable == null || 
                        props.finding.loading
                    }
                    className="acceptBtn"
                    style={{
                        width: "62px",
                        height: "35px",
                    }}
                    onClick={() => {
                        props.finding.loading = true
                        props.onChange({ ...props.finding }, true);
                    }}
                >
                    Run
                </button>
                <button
                    type="button"
                    disabled={props.finding?.content.nativeData == null}
                    className="acceptBtn"
                    style={{
                        marginLeft: "10px",
                        width: "200px",
                        height: "35px",
                    }}
                    onClick={() => {
                        setShowRegressionPopup(true);
                    }}
                >
                    Save regression to dataset
                </button>
            </div>
            {showSaveRegressionPopup && (
                <SaveRegressionResultsPopup
                    regressionResults={props.finding?.content.nativeData}
                    currentModuleId={props.currentModuleId}
                    onClose={() => {
                        setShowRegressionPopup(false);
                    }}
                />
            )}
        </div>
    );
}
