import { observable, computed, makeObservable, action } from "mobx";

import {
    PersonalGroup,
    getPersonalUserGroupsAndEmails,
    Group,
    getUserGroups,
} from "common/UserGroupsApi";

export interface UserGroupOption {
    label: string;
    value: string;
    id: number;
    personal?: boolean;
    userInfo?: PersonalGroup;
}

class UserGroupsStore {
    @observable public userGroupsState: Group[] = [];
    @observable public isLoaded = false;
    @observable public userPersonalGroupsState: PersonalGroup[] = [];

    private initialized = false;

    constructor() {
        makeObservable(this);
    }

    init() {
        if (!this.initialized) {
            this.initialized = true;
            this.updateUserGroups();
        }
    }

    @action.bound
    private assignUserGroups(userGroups: Group[]) {
        this.userGroupsState = userGroups;
    }

    @action.bound
    private assignPersonalUserGroups(userGroups: PersonalGroup[]) {
        this.userPersonalGroupsState = userGroups;
        this.isLoaded = true;
    }

    public updateUserGroups(): void {
        getUserGroups()
            .then((userGroups) => {
                this.assignUserGroups(userGroups);
                getPersonalUserGroupsAndEmails()
                    .then((userGroups) => {
                        this.assignPersonalUserGroups(userGroups);
                    })
                    .catch((error) => {
                        // We should not print the error if the user is unauthorized
                        if (error.response.status !== 401) {
                            console.log(error);
                        }
                    });
            })
            .catch((error) => {
                if (!error?.response) return;
                // We should not print the error if the user is unauthorized
                if (error.response.status !== 401) {
                    console.log(error);
                }
            });
    }
    @computed public get userGroupsOptions(): UserGroupOption[] {
        // let userGroupsState = this.userGroupsState.filter(
        // 	(userGroup) =>
        // 		this.userPersonalGroupsState.findIndex(
        // 			(personalGroup) => personalGroup.group_id === userGroup.id
        // 		) === -1
        // );
        return this.userGroupsState.map((userGroup) => {
            return {
                id: userGroup.id,
                label: userGroup.name,
                value: userGroup.name,
            };
        });
        // .concat(
        // 	this.userPersonalGroupsState.map((userGroup) => {
        // 		return {
        // 			id: userGroup.group_id,
        // 			label: userGroup.user_name,
        // 			value: userGroup.user_name,
        // 		};
        // 	})
        // );
    }

    public userGroupsOptionsForSharing(
        allowPersonal: boolean = true
    ): UserGroupOption[] {
        let username = window.localStorage.getItem("user")?.substring(6);

        let userGroupsState = this.userGroupsState.filter(
            (userGroup) =>
                this.userPersonalGroupsState.findIndex(
                    (personalGroup) => personalGroup.group_id === userGroup.id
                ) === -1 &&
                userGroup.name != username
        );
        let result = userGroupsState.map((userGroup) => {
            return {
                id: userGroup.id,
                label: userGroup.name,
                value: userGroup.name,
            };
        });
        if (allowPersonal)
            result = result.concat(
                this.userPersonalGroupsState.map((userGroup) => {
                    return {
                        id: userGroup.group_id,
                        label: `${userGroup.user_name}${
                            userGroup.emails.length > 0
                                ? " ".concat(userGroup.emails[0])
                                : ""
                        }`,
                        value: userGroup.user_name,
                        userInfo: userGroup,
                        personal: true,
                    };
                })
            );
        return result;
    }
}

export default new UserGroupsStore();
