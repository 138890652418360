import {
    CanvasElement,
    CanvasSpreadSheetGrid,
    CanvasSimpleSpreadSheetInput,
    castedTargetValue,
    getNodeValueType,
    getOutputTargetValue,
    ColumnFormat,
    OperationType,
    AddVariableOperation,
    DeleteVariableOperation,
    DeleteRowOperation,
    EditVariableOperation,
    NodeValue,
} from "common/Canvas";
import {
    applyColumnFormat,
    applyColumnFormatsToSchemaOptions,
    SchemaOptions,
    Panel,
    Type,
    replaceSchema,
} from "common/InputData";
import Variables from "common/Variables";
import tables from "common/Tables";
import { GroupExtendedPermission } from "common/GroupPermissions";
import dataScopes, { DataScopeOption } from "common/DataScopes";
import {
    insertRows,
    deleteRows,
    modifySchemaApi,
    editCurrentDataApi,
} from "common/DataApi";
import { NewVariable, EditVariable } from "common/VariableCreator";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";

interface SchemaStatusOptions extends SchemaOptions {
    success: boolean;
    errorMessage?: string;
}

export function initializeSchema(
    spreadSheetGrid: CanvasSpreadSheetGrid,
    _nodes: (CanvasElement | CanvasSimpleSpreadSheetInput)[],
    canvasTreeStore: CanvasTreeStore
): SchemaStatusOptions {
    let schemaOptions: SchemaStatusOptions = {
        names: [],
        types: [],
        units: [],
        panels: [],
        levels: [],
        formats: [],
        namesToIndices: {},
        success: true,
    };
    let columnFormats: (ColumnFormat | undefined)[] = [];
    for (let j = 0; j < spreadSheetGrid.cols; ++j) {
        let header = spreadSheetGrid.headers?.[j] ?? undefined;
        let columnNodes = _nodes.filter((node) => node.x === j);
        let types = columnNodes.map((node) => {
            let outputValue = getOutputTargetValue(node);
            if (outputValue == null || Number.isNaN(outputValue)) return null;
            else return getNodeValueType(node);
        });
        types = types.filter((type) => type != null);
        let type = Type.Str;
        if (types.length > 0 && !types.includes("string")) type = Type.Float;
        if (header == null || !header.text) continue;
        let name = header.text.trim();
        if (schemaOptions.names.includes(name)) {
            return {
                names: [],
                types: [],
                panels: [],
                levels: [],
                formats: [],
                units: [],
                namesToIndices: {},
                success: false,
                errorMessage: `Column ${name} is not unique`,
            };
        }
        columnFormats.push(header?.columnFormat ?? undefined);
        schemaOptions.names.push(name);
        schemaOptions.types.push(type);
        schemaOptions.levels.push(null);
        schemaOptions.formats.push(null);
        schemaOptions.panels.push(Panel.Regular);
        schemaOptions.units.push("");
    }
    if (schemaOptions.names.length === 0) {
        return {
            names: [],
            types: [],
            levels: [],
            panels: [],
            formats: [],
            units: [],
            namesToIndices: {},
            success: false,
            errorMessage: `No named columns`,
        };
    }
    if (columnFormats != null)
        schemaOptions = {
            ...applyColumnFormatsToSchemaOptions(columnFormats, schemaOptions),
            success: true,
        };
    return schemaOptions;
}

export async function saveSimpleSpreadSheetChanges(
    spreadSheetGrid: CanvasSpreadSheetGrid,
    nodes: CanvasSimpleSpreadSheetInput[] | CanvasElement[],
    onSuccess: (spreadSheetGrid: CanvasSpreadSheetGrid) => void,
    onPartialSuccess: (
        spreadSheetGrid: CanvasSpreadSheetGrid,
        message: string
    ) => void,
    onError: (errorMessage: string) => void,
    moduleId?: number | string | null
) {
    let changes =
        spreadSheetGrid.fullSpreadSheetBackendOutputOptions?.tableChanges ?? [];
    let subsetInfo =
        spreadSheetGrid.fullSpreadSheetBackendOutputOptions?.subsetInfo;
    let newVariables: NewVariable[] = [];
    let renameVariables: EditVariable[] = [];
    let dropIndices: number[] = [];
    let rowsForDelete: number[] = [];
    let rowsForAdd: string[] = [];
    let mapTemporaryIndexToName: { [key: string]: string } = {};
    if (subsetInfo != null) {
        for (let operation of changes) {
            switch (operation.type) {
                case OperationType.AddVariable: {
                    let castOperation = operation as AddVariableOperation;
                    let name = castOperation.name.trim();
                    if (name.length === 0) continue;
                    mapTemporaryIndexToName[castOperation.variableIndex] = name;
                    let newVariable: NewVariable = {
                        type: Type.Str,
                        panel: Panel.Regular,
                        unit: "",
                        name: name,
                        level: null,
                        format: null,
                    };
                    newVariable = applyColumnFormat(
                        castOperation.format,
                        newVariable
                    );
                    newVariables.push(newVariable);
                    break;
                }
                case OperationType.EditVariable: {
                    let castOperation = operation as EditVariableOperation;
                    let newName = castOperation.name.trim();
                    if (newName.length === 0) continue;
                    let renameVariable: EditVariable = {
                        index: castOperation.variableIndex as number,
                        type: Type.Str,
                        panel: Panel.Regular,
                        unit: "",
                        new_name: newName,
                        level: null,
                        format: null,
                    };
                    renameVariable = applyColumnFormat(
                        castOperation.format,
                        renameVariable
                    );
                    renameVariables.push(renameVariable);
                    break;
                }
                case OperationType.DeleteVariable: {
                    dropIndices.push(
                        (operation as DeleteVariableOperation)
                            .variableIndex as number
                    );
                    break;
                }
                case OperationType.DeleteRow: {
                    rowsForDelete.push(
                        (operation as DeleteRowOperation).rowId as number
                    );

                    break;
                }
                case OperationType.AddRow: {
                    rowsForAdd.push(
                        (operation as DeleteRowOperation).rowId as string
                    );

                    break;
                }
                default:
                    break;
            }
        }
        try {
            if (
                renameVariables.length > 0 ||
                dropIndices.length > 0 ||
                newVariables.length > 0
            ) {
                dropIndices = await modifySchemaApi(
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                        .dataScopeId,
                    renameVariables,
                    newVariables,
                    dropIndices,
                    moduleId
                );
                if (
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                        .variables != null
                ) {
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.variables = spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.variables.filter(
                        (variable) => !dropIndices.includes(variable.value)
                    );
                }
                if (
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                        .conditions != null
                ) {
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.conditions = spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.conditions.filter(
                        (condition) =>
                            condition.variable == null ||
                            !dropIndices.includes(condition.variable.value)
                    );
                }

                await Variables(
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                        .dataScopeId
                ).update(moduleId);
                let variableIndices: (string | number)[] = subsetInfo!
                    .variableIndices;
                for (let i = 0; i < variableIndices.length; ++i) {
                    if (dropIndices.includes(variableIndices[i] as number))
                        variableIndices[i] = -1;
                }
                let derivedVariableIndices: number[] = [];
                for (let i = 0; i < variableIndices.length; ++i) {
                    let variableIndex = variableIndices[i];
                    if (variableIndex in mapTemporaryIndexToName) {
                        let name = mapTemporaryIndexToName[variableIndex];
                        let variable = Variables(
                            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                                .dataScopeId,
                            moduleId
                        ).getVariableByName(name);
                        if (variable != null) {
                            variableIndices[i] = variable.index!;
                            let derivedGroup = Variables(
                                spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                                    .dataScopeId,
                                moduleId
                            ).derivedGroup(variable.index!);
                            derivedVariableIndices = derivedVariableIndices.concat(
                                derivedGroup.map(
                                    (variable) => variable.index
                                ) as number[]
                            );
                        }
                    } else {
                        if (
                            typeof variableIndex === "number" &&
                            variableIndex >= 0
                        ) {
                            let offset = 0;
                            for (let dropIndex of dropIndices) {
                                if (variableIndex > dropIndex) offset += 1;
                            }
                            variableIndices[i] = variableIndex - offset;
                            // let derivedGroup = Variables(
                            //     spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                            //         .dataScopeId,
                            //     moduleId
                            // )
                            //     .derivedGroup(variableIndex)
                            //     .map((variable) => variable.index) as number[];
                            // for (let derivedIndex of derivedGroup) {
                            //     derivedVariableIndices.push(derivedIndex);
                            // }
                        }
                    }
                }
                derivedVariableIndices = [
                    ...new Set(
                        derivedVariableIndices.filter(
                            (index) => !variableIndices.includes(index)
                        )
                    ),
                ];
                variableIndices = variableIndices.concat(
                    derivedVariableIndices
                );
                spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.subsetInfo!.variableIndices = variableIndices;

                changes = changes.filter(
                    (operation) =>
                        operation.type !== OperationType.AddVariable &&
                        operation.type !== OperationType.EditVariable &&
                        operation.type !== OperationType.DeleteVariable
                );
                spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.tableChanges = changes;
                onPartialSuccess(spreadSheetGrid, "Schema is modified...");
            } else {
                await Variables(
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                        .dataScopeId
                ).update(moduleId);
            }
            if (rowsForDelete.length > 0) {
                await deleteRows(
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                        .tableOption ??
                        tables(
                            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                                .dataScopeId
                        ).tableToOption(),
                    rowsForDelete,
                    moduleId
                );
                changes = changes.filter(
                    (operation) => operation.type !== OperationType.DeleteRow
                );
                spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.tableChanges = changes;
                onPartialSuccess(spreadSheetGrid, "Rows are deleted...");
            }
            await insertChanges(spreadSheetGrid, nodes, moduleId);
            onPartialSuccess(spreadSheetGrid, "Cell changes are inserted...");

            let oldRowIdToNewRowIdMap = await insertNewRows(
                spreadSheetGrid,
                nodes,
                rowsForAdd,
                moduleId
            );
            let rowId: (string | number)[] = subsetInfo!.rowId;
            for (let j = 0; j < rowId.length; ++j) {
                let rowIdItem = rowId[j];
                if (
                    typeof rowIdItem === "string" &&
                    rowIdItem in oldRowIdToNewRowIdMap
                ) {
                    rowId[j] = oldRowIdToNewRowIdMap[rowIdItem];
                }
            }
            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.subsetInfo!.rowId = rowId;
            changes = changes.filter(
                (operation) => operation.type !== OperationType.AddRow
            );
            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.tableChanges = changes;
            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.subsetInfo!.variableIndices = spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.subsetInfo!.variableIndices.filter(
                (index) => index !== -1
            );
            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.subsetInfo!.variableIndices = [
                ...new Set(
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.subsetInfo!.variableIndices
                ),
            ];
            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.limit = Math.max(
                spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.limit ??
                    10,
                spreadSheetGrid.rows
            );
            onSuccess(spreadSheetGrid);
            return;
        } catch (error) {
            console.log(error);
            onError(String(error));
            return;
        }
    } else {
        onError("Partial streaming is not supported for this spreadsheet");
        return;
    }
}

export async function insertChanges(
    spreadSheetGrid: CanvasSpreadSheetGrid,
    nodes: CanvasSimpleSpreadSheetInput[] | CanvasElement[],
    moduleId?: number | string | null
) {
    let subsetInfo = spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
        .subsetInfo!;
    let changes: {
        row_id: number;
        column_index: number;
        value: NodeValue | null;
    }[] = [];
    for (let j = 0; j < spreadSheetGrid.cols; ++j) {
        let variables = Variables(
            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.dataScopeId,
            moduleId
        );
        let variableIndex = subsetInfo.variableIndices[j];
        if (typeof variableIndex === "number" && variableIndex >= 0) {
            let variable = variables.getVariableByIndex(
                subsetInfo.variableIndices[j] as number
            );
            if (variable != null) {
                for (let i = 0; i < spreadSheetGrid.rows; ++i) {
                    let rowIndex = subsetInfo.rowId[i];
                    if (typeof rowIndex === "number") {
                        const nodeIndex: number = i * spreadSheetGrid.cols + j;
                        let node = nodes[nodeIndex];
                        let columnFormat =
                            spreadSheetGrid?.headers?.[j]?.columnFormat;
                        let value = castedTargetValue(
                            node,
                            variable.type as Type,
                            columnFormat ?? node.format
                        );
                        changes.push({
                            row_id: rowIndex,
                            value: value ?? null,
                            column_index: variableIndex as number,
                        });
                    }
                }
            }
        }
    }
    if (changes.length > 0)
        await editCurrentDataApi(
            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.tableOption ??
                tables(
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                        .dataScopeId
                ).tableToOption(),
            changes,
            moduleId
        );
}

export async function insertNewRows(
    spreadSheetGrid: CanvasSpreadSheetGrid,
    nodes: CanvasSimpleSpreadSheetInput[] | CanvasElement[],
    rowIds: string[],
    moduleId?: number | string | null
) {
    let subsetInfo = spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
        .subsetInfo!;
    let rows: {
        [key: number]: { [key: string]: NodeValue | null };
    } = {};
    let oldRowIdToNewRowIdMap: {
        [key: string]: number;
    } = {};
    let oldRowIdOrder: string[] = [];
    for (let j = 0; j < spreadSheetGrid.cols; ++j) {
        let variables = Variables(
            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.dataScopeId,
            moduleId
        );
        let variableIndex = subsetInfo.variableIndices[j];
        if (typeof variableIndex === "number" && variableIndex >= 0) {
            let variable = variables.getVariableByIndex(
                subsetInfo.variableIndices[j] as number
            );
            if (variable != null) {
                for (let i = 0; i < spreadSheetGrid.rows; ++i) {
                    let rowIndex = subsetInfo.rowId[i];
                    if (
                        typeof rowIndex === "string" &&
                        rowIds.includes(rowIndex)
                    ) {
                        const nodeIndex: number = i * spreadSheetGrid.cols + j;
                        let node = nodes[nodeIndex];
                        let columnFormat =
                            spreadSheetGrid?.headers?.[j]?.columnFormat;
                        let value = castedTargetValue(
                            node,
                            variable.type as Type,
                            columnFormat ?? node.format
                        );
                        oldRowIdOrder.push(rowIndex);
                        rows[i] = {
                            ...rows[i],
                            [variable.name]: value ?? null,
                        };
                    }
                }
            }
        }
    }
    let rowsArray = Object.values(rows);
    if (rowsArray.length > 0) {
        let newRowIds = await insertRows(
            spreadSheetGrid.fullSpreadSheetBackendOutputOptions!.tableOption ??
                tables(
                    spreadSheetGrid.fullSpreadSheetBackendOutputOptions!
                        .dataScopeId
                ).tableToOption(),
            rowsArray,
            moduleId
        );
        for (let i = 0; i < newRowIds.length; ++i) {
            oldRowIdToNewRowIdMap[oldRowIdOrder[i] as string] = newRowIds[i];
        }
    }
    return oldRowIdToNewRowIdMap;
}

export function saveSimpleSpreadSheet(
    spreadSheetGrid: CanvasSpreadSheetGrid,
    nodes: CanvasSimpleSpreadSheetInput[] | CanvasElement[],
    names: string[],
    types: Type[],
    panels: Panel[],
    levels: (string | null)[],
    formats: (string | null)[],
    units: string[],
    dataScope: DataScopeOption,
    permissions: (GroupExtendedPermission | null)[] | undefined,
    onSuccess: (dataTableIdx: string | number) => void,
    onError: (errorMessage: string) => void,
    moduleId?: number | string | null
) {
    names = names.map((name) => name.trim());
    let rows: {
        [key: number]: { [key: string]: NodeValue | null };
    } = {};
    let columnsEnabled = [];
    let skippedCount = 0;
    for (let j = 0; j < spreadSheetGrid.cols; ++j) {
        let header = spreadSheetGrid.headers?.[j] ?? undefined;
        if (header == null || !header.text) {
            skippedCount += 1;
            continue;
        }
        columnsEnabled.push(true);
        for (let i = 0; i < spreadSheetGrid.rows; ++i) {
            const nodeIndex: number = i * spreadSheetGrid.cols + j;
            let node = nodes[nodeIndex];
            let type = types[j - skippedCount];
            let name = names[j - skippedCount];
            let columnFormat = spreadSheetGrid?.headers?.[j]?.columnFormat;
            let value = castedTargetValue(
                node,
                type,
                columnFormat ?? node.format
            );
            rows[i] = {
                ...rows[i],
                [name]: value ?? null,
            };
        }
    }
    if (names.length === 0) {
        onError("No named columns");
        return;
    }

    console.log("saveSimpleSpreadSheet \n names: ", names, "\n");
    console.log("types: ", types, "\n");
    console.log("units: ", units, "\n");
    console.log("panels: ", panels, "\n");
    console.log("formats: ", formats, "\n");
    console.log("levels: ", levels, "\n");
    console.log("columnsEnabled: ", columnsEnabled, "\n");
    console.log("dataScope: ", dataScope, "\n");
    console.log("permissions: ", permissions, "\n");
    console.log("permissions: ", permissions, "\n");    
    replaceSchema(
        names,
        types,
        units,
        panels,
        formats,
        levels,
        columnsEnabled,
        dataScope,
        false,
        permissions?.filter(
            (permission): permission is GroupExtendedPermission =>
                permission != null
        ),
        (dataTableIdx) => {
            dataScopes.update();
            Variables(dataTableIdx).update(moduleId);            
            let tableOption = tables(dataTableIdx).tableToOption();
            console.log("tableOption: ", tableOption, "\n");            
            insertRows(tableOption, Object.values(rows), moduleId)
                .then(() => {
                    console.log("Object.values(rows): ", Object.values(rows), "\n");
                    onSuccess(dataTableIdx);
                })
                .catch((error) => {
                    onError(String(error));
                });
        },

        (error) => {
            onError(String(error));
        },
        undefined,
        moduleId
    );
}
