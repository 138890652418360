import L from "leaflet";

L.TileLayer.ColorFilter = L.TileLayer.extend({
	intialize: function (url, options) {
		L.TileLayer.prototype.initialize.call(this, url, options);
	},
	colorFilter: function () {
		let VALIDFILTERS = [
			"blur:px",
			"brightness:%",
			"bright:brightness:%",
			"bri:brightness:%",
			"contrast:%",
			"con:contrast:%",
			"grayscale:%",
			"gray:grayscale:%",
			"hue-rotate:deg",
			"hue:hue-rotate:deg",
			"hue-rotation:hue-rotate:deg",
			"invert:%",
			"inv:invert:%",
			"opacity:%",
			"op:opacity:%",
			"saturate:%",
			"saturation:saturate:%",
			"sat:saturate:%",
			"sepia:%",
			"sep:sepia:%",
		];

		let colorFilterOptions = this.options.filter ? this.options.filter : [];
		let filterSettings = colorFilterOptions
			.map((opt) => {
				let filter = opt.toLowerCase().split(":");
				if (filter.length === 2) {
					let match = VALIDFILTERS.find((vf) => {
						return vf.split(":")[0] === filter[0];
					});
					if (match) {
						match = match.split(":");
						filter[1] += /^\d+$/.test(filter[1])
							? match[match.length - 1]
							: "";
						return `${match[match.length - 2]}(${filter[1]})`;
					}
				}
				return "";
			})
			.join(" ");
		return filterSettings;
	},
	_initContainer: function () {
		L.TileLayer.prototype._initContainer.call(this);
		this._container.style.filter = this.colorFilter();
	},
	updateFilter: function (newFilter) {
		this.options.filter = newFilter;
		if (this._container) {
			this._container.style.filter = this.colorFilter();
		}
	},
});

export default L.TileLayer.ColorFilter;