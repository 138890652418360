export function getTextHeight(
    text: string,
    width: number,
    fontFamily: string,
    fontSize: number
): number {
    let textElement = document.createElement("span");
    document.body.appendChild(textElement);

    textElement.style.font = fontFamily;
    textElement.style.fontSize = fontSize + "px";
    textElement.style.height = "auto";
    textElement.style.width = width + "px";
    textElement.style.position = "absolute";
    textElement.style.overflowWrap = "break-word";
    textElement.style.textOverflow = "ellipsis";
    textElement.innerHTML = text;

    let height = Math.ceil(textElement.clientHeight);

    document.body.removeChild(textElement);
    return height;
}

export function getTextSize(
    text: string,
    fontFamily: string,
    fontSize: number,
    fontWeight: string
): {
    width: number;
    height: number;
} {
    let textElement = document.createElement("span");
    document.body.appendChild(textElement);
    textElement.style.font = fontFamily;
    textElement.style.fontWeight = fontWeight;
    textElement.style.fontSize = fontSize + "px";
    textElement.style.height = "auto";
    textElement.style.width = "auto";
    textElement.style.position = "absolute";
    textElement.style.whiteSpace = "no-wrap";
    textElement.innerHTML = text;

    let height = Math.ceil(textElement.clientHeight);
    let width = Math.ceil(textElement.clientWidth);

    document.body.removeChild(textElement);
    return {
        height: height,
        width: width,
    };
}

export function getTextSizeWithParent(
    text: string,
    fontFamily: string,
    fontSize: number,
    fontWeight: string,
    parent: HTMLElement
): {
    width: number;
    height: number;
} {
    let textElement = document.createElement("span");
    parent.appendChild(textElement);
    textElement.style.fontFamily = fontFamily;
    textElement.style.fontWeight = fontWeight;
    textElement.style.fontSize = fontSize + "px";
    textElement.style.color = "transparent";
    textElement.style.height = "auto";
    textElement.style.width = "auto";
    textElement.style.position = "absolute";
    textElement.style.whiteSpace = "no-wrap";
    textElement.innerHTML = text;

    let height = Math.ceil(textElement.clientHeight);
    let width = Math.ceil(textElement.clientWidth);

    parent.removeChild(textElement);
    return {
        height: height,
        width: width,
    };
}

export function getTextWidthCanvas(
    text: string,
    fontFamily: string,
    fontSize: number | string,
    fontWeight: string
): number {
    const canvas = document.createElement("canvas");

    const ctx = canvas.getContext("2d");
    let result = 0;
    if (ctx != null) {
        ctx.textAlign = "left";
        ctx.textBaseline = "top"; // important!
        ctx.font = `${fontWeight} ${fontSize} ${fontFamily}`;
        ctx.fillText(text, 0, 0);
        let metric = ctx.measureText(text);
        result = metric.width;
    }
    canvas.remove();
    return result;
}
