import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { BarChartFinding } from "common/Finding";
import { SearchComponentOption } from "common/SearchComponent";
import StringOption from "common/StringOption";
import {
    additionalBarChartAndPieChartOperations,
    aggregationFunctions,
    aggregationToAdditionalMap,
} from "common/AggregationFunctionsv2";
import { updateOriginalNames } from "../../common/Utils";

class Api {
    static getDefaultConfig(journeyName: string) {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            barSize: 100,
            barGap: 8,
            barCategoryGap: 60,
            linesCount: 4,
            showXAxisName: true,
            showYAxisName: false,
            axesLinesColor: "#E9E9E9",
            operationVariable: aggregationFunctions[0].value,
        };
    }

    static getPreviewFinding(journeyName: string): BarChartFinding {
        let item = {
            type: "barchart",
            content: {
                data: [
                    {
                        name: "Variable 1",
                        value: 12,
                    },
                    {
                        name: "Variable 2",
                        value: 8,
                    },
                    {
                        name: "Variable 3",
                        value: 5,
                    },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static async getData(
        finding: BarChartFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<BarChartFinding> {
        let {
            dataScope,
            operationVariable,
            selectedTable,
            conditions,
            additionalOperators,
            additionalValues,
            sideBySideVariableIndex,
            groupByAll,
            //     columnByVariableIndex,
        } = finding.config ?? {};
        let variables = finding.content.data.filter(
            (item) => item.variableIndex != null
        );
        if (groupByAll == null) groupByAll = variables.length === 1;
        let horizontalLines =
            finding.content.horizontalLines?.filter(
                (
                    item
                ): item is {
                    name: string;
                    value: number;
                    variableIndex?: number;
                    originalName?: string;
                } => item?.variableIndex != null
            ) ?? [];
        if (variables.length === 0 && horizontalLines.length === 0) {
            return Promise.resolve(finding);
        }

        conditions = filterNonEmptyConditions(conditions ?? []);

        additionalOperators = (additionalOperators ?? []).filter(
            (operator: StringOption, index: number) =>
                additionalValues[index]?.value != null
        );

        additionalValues = (additionalValues ?? [])
            .filter((value: SearchComponentOption) => value?.value != null)
            .map((value: SearchComponentOption) => value.value);
        let requestJson: any = {
            variable_indices: variables.map(
                (variable) => variable.variableIndex
            ),
            horizontal_lines_variable_indices: horizontalLines.map(
                (variable) => variable.variableIndex
            ),
            conditions: conditionsToJson(conditions),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            module_id: moduleId,
        };
        if (
            !groupByAll &&
            sideBySideVariableIndex != null &&
            additionalValues.length > 0
        ) {
            requestJson.where = {
                group_index: sideBySideVariableIndex,
                expressions: additionalValues.map(
                    (value: SearchComponentOption, index: number) => ({
                        operation: additionalOperators[index]?.value ?? "=",
                        value: value,
                    })
                ),
            };
        }
        if (groupByAll && sideBySideVariableIndex != null) {
            requestJson.column_by = sideBySideVariableIndex;
            operationVariable =
                aggregationToAdditionalMap[operationVariable as string] ??
                operationVariable;
            if (operationVariable === "count") {
                requestJson.where = {
                    group_index: sideBySideVariableIndex,
                    expressions: [],
                };
            }
        }
        if (groupByAll && operationVariable === "count_distinct") {
            operationVariable = "count";
        }
        requestJson.group_operation = operationVariable;

        return axios
            .post<{
                compare_data: {
                    values?: { [key: string | number]: number };
                    where?: {
                        value?: string | number;
                        group_name?: string;
                        operation?: string;
                    };
                }[][];
                horizontal_lines: { [key: number]: number };
                success: boolean;
                error_msg: string;
            }>("/api/e/compare_variables", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newFinding: BarChartFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: [...finding.content.data],
                            varyByData:
                                finding.content.varyByData != null
                                    ? [...finding.content.varyByData]
                                    : null,
                            horizontalLines: [
                                ...(finding.content.horizontalLines ?? []),
                            ],
                        },
                    };

                    // Check wherther variables were renamed
                    updateOriginalNames(
                        newFinding.content.data,
                        dataScope.value,
                        moduleId
                    );
                    if (newFinding.content.varyByData != null) {
                        updateOriginalNames(
                            newFinding.content.varyByData!,
                            dataScope.value,
                            moduleId
                        );
                    }
                    if (newFinding.content.horizontalLines != null) {
                        updateOriginalNames(
                            newFinding.content.horizontalLines!,
                            dataScope.value,
                            moduleId
                        );
                    }

                    let horizontalLinesItems =
                        newFinding.content.horizontalLines;

                    let compareDatas = response.data.compare_data;

                    let firstItem = compareDatas[0];
                    newFinding.content.groupNames = firstItem.map((item) =>
                        item.where
                            ? `${item.where.group_name} ${item.where.operation} ${item.where.value}`
                            : "value"
                    );
                    newFinding.content.groupInfo = firstItem.map((item) =>
                        item.where
                            ? {
                                  name: item.where.group_name ?? "",
                                  operation: item.where.operation ?? "",
                                  value: `${item.where.value}`,
                              }
                            : {
                                  name: "",
                                  value: "value",
                                  operation: "",
                              }
                    );

                    if (
                        additionalBarChartAndPieChartOperations.has(
                            operationVariable
                        )
                    ) {
                        newFinding.content.data = newFinding.content.data.map(
                            (item) => ({
                                ...item,
                                value: [],
                            })
                        );
                        newFinding.content.additionalVaryByData = null;
                        for (let i = 0; i < compareDatas.length; ++i) {
                            let compareData = compareDatas[i];
                            let allKeys: string[] = [];
                            compareData.forEach((item, index) => {
                                if (item.values != null) {
                                    let keys = Object.keys(item.values);
                                    allKeys = allKeys.concat(keys);
                                }
                            });
                            allKeys = [...new Set(allKeys)];
                            let data: {
                                name: string;
                                originalName: string;
                                value: number[];
                                variableIndex: number;
                            }[] = allKeys.map((item, index) => ({
                                name: item,
                                variableIndex: variables[i].variableIndex!,
                                originalName: item,
                                value: [],
                            }));

                            if (
                                finding.config.sideBySideVariableIndex !==
                                    null &&
                                finding.config.operationVariable ===
                                    "count_distinct"
                            ) {
                                compareData.forEach((item, idx) => {
                                    if (item.values != null) {
                                        data[idx].value.push(
                                            item.values[allKeys[idx]] ?? 0
                                        );
                                    }
                                });
                            } else {
                                compareData.forEach((item) => {
                                    allKeys.forEach((key, valIndex) => {
                                        if (item.values != null) {
                                            data[valIndex].value.push(
                                                item.values[key] ?? 0
                                            );
                                        }
                                    });
                                });
                            }
                            if (i === 0) {
                                newFinding.content.varyByData = data;
                            } else {
                                if (
                                    newFinding.content.additionalVaryByData ==
                                    null
                                ) {
                                    newFinding.content.additionalVaryByData = [];
                                }
                                newFinding.content.additionalVaryByData.push(
                                    data
                                );
                            }
                        }
                    } else {
                        let compareData = compareDatas[0];
                        newFinding.content.varyByData = null;
                        newFinding.content.additionalVaryByData = null;

                        let dataItems = newFinding.content.data.filter(
                            (item) => item.variableIndex != null
                        );
                        for (let dataItem of dataItems) {
                            dataItem.value = [];
                        }
                        for (let newData of compareData) {
                            if (newData.values != null) {
                                for (let dataItem of newFinding.content.data) {
                                    let key = dataItem.variableIndex;
                                    if (key == null) continue;
                                    (dataItem.value as number[]).push(
                                        newData.values[key]
                                    );
                                }
                            }
                        }
                    }
                    if (horizontalLinesItems != null) {
                        for (let i = 0; i < horizontalLinesItems.length; ++i) {
                            if (
                                horizontalLinesItems[i]?.variableIndex != null
                            ) {
                                horizontalLinesItems[i] = {
                                    ...horizontalLinesItems[i]!,
                                    value:
                                        response.data.horizontal_lines[
                                            horizontalLinesItems[i]!
                                                .variableIndex!
                                        ],
                                };
                            }
                        }
                    }
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
