import React, { Component } from "react";
import { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";

import OutsideAlerter from "common/OutsideAlerter";
import { getRecipesTabsApi, addRecipesTabApi } from "common/UserApi";
import customSelectStyles from "common/SelectStyles";

class AddToRecipesPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: this.props.title,
			recipesTab: undefined,
			recipesOptions: [],
		};
		this.inputRef = React.createRef();
	}

	componentDidMount() {
		getRecipesTabsApi().then((recipesTabs) => {
			let recipesOptions = recipesTabs.map((recipesTab) => {
				return {
					label: recipesTab.title,
					value: recipesTab.id,
				};
			});
			this.setState({ recipesOptions: recipesOptions });
		});
		if (this.inputRef.current) this.inputRef.current.focus();
	}

	buildInnerView() {
		return (
			<div
				className="center-container"
				style={{
					overflow: "visible",
					height: "100%",
					width: "100%",
				}}
				onKeyDown={(evt) => {
					evt.stopPropagation();
				}}
			>
				<div
					className="flex-simple-column"
					style={{
						overflow: "visible",
						marginTop: 20,
						height: "100%",
						width: "90%",
					}}
				>
					<input
						ref={this.inputRef}
						type="text"
						className="like-tab"
						placeholder="Enter name"
						style={{
							width: "100%",
							alignSelf: "center",
						}}
						value={this.state.title}
						onChange={(evt) => {
							this.setState({ title: evt.target.value });
						}}
					/>
					<div
						style={{
							width: "100%",
							marginTop: 20,
							overflow: "visible",
						}}
					>
						<CreatableSelect
							onCreateOption={(option) => {
								addRecipesTabApi(option).then((id) => {
									let recipesOptions = Array.from(
										this.state.recipesOptions
									);
									recipesOptions.unshift({
										label: option,
										value: id,
									});
									this.setState({
										recipesOptions: recipesOptions,
									});
								});
							}}
							filterOption={createFilter({
								ignoreAccents: false,
							})}
							placeholder={"Select tab"}
							styles={{
								...customSelectStyles,
								container: (base) => ({
									...base,
									height: "38px",
								}),
							}}
							options={this.state.recipesOptions}
							value={this.state.recipesTab}
							onChange={(newValue) => {
								this.setState({ recipesTab: newValue });
							}}
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								colors: {
									...theme.colors,
									text: "white",
									primary25: "var(--selectors-background-hover-color)",
								},
							})}
						/>
					</div>
					<div
						className="my-row"
						style={{
							marginTop: 20,
							alignSelf: "flex-end",
							alignItems: "center",
						}}
					>
						<Button
							type="button"
							disabled={
								this.state.title.length === 0 ||
								!this.state.recipesTab
							}
							className="btn btn-sm btn-primary my-primary"
							style={{
								marginLeft: 10,
								width: "112px",
							}}
							onClick={() => {
								this.props.onSave(
									this.state.title,
									this.state.recipesTab.value
								);
							}}
						>
							SAVE
						</Button>
						<Button
							type="button"
							className="btn btn-sm btn-primary my-primary"
							style={{
								marginLeft: 10,
								width: "112px",
							}}
							onClick={() => {
								this.props.onClose();
							}}
						>
							CLOSE
						</Button>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<OutsideAlerter
				onReject={() => {
					this.props.onClose();
				}}
			>
				<div
					className="dashboard-rect"
					style={{
						transform: "translate(-50%, -50%)",
						left: "50%",
						top: "50%",
						position: "absolute",
						overflow: "visible",
						border: "1px solid black",
						alignItems: "center",
						cursor: "pointer",
						height: 200,
						width: 450,
						zIndex: 100000000,
					}}
				>
					{this.buildInnerView()}
				</div>
			</OutsideAlerter>
		);
	}
}

export default AddToRecipesPopup;