import React from "react";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";

import BaseStepModule, { StepState } from "../../../../common/BaseStepModule";
import axios from "common/ServerConnection";
import models from "common/Models";
import variables from "common/Variables";
import { Permission } from "common/Permissions";

const MainComponent = observer(
    class MainComponent extends BaseStepModule {
        constructor(props) {
            super(props);

            this.state = {
                ...this.state,
                expanded: false,
            };
        }

        renderModels() {
            if (!this.state.expanded) return null;

            let variableNames = variables(this.props.commonState.viewDataScopeId).variableNames;

            let graphicsModels = (
                <div
                    className="flex-simple-column"
                    style={{ background: "#3f4b62" }}
                >
                    {models(this.props.commonState.viewDataScopeId).models
                        .filter((item) => item.type === "smf")
                        .map((item, index) => {
                            let modelName = `${index + 1}. Model: ${item.name}, Outcome: ${variableNames[item.outcome_index]}`;
                            if(item.panel_index != null) {
                                modelName += `, Panel: ${variableNames[item.panel_index]}`
                            }
                            return (
                                <div className="my-row" key={index}>
                                    <span
                                        style={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                        }}
                                        className="content-regular-text"
                                    >
                                        {modelName}
                                    </span>
                                    {this.props.commonState.viewDataScope?.permissionType === Permission.ReadWrite &&
                                        <div style={{ alignSelf: "center" }}>
                                            <Button
                                                className="close"
                                                onClick={() => {
                                                    this.deleteModel(item);
                                                }}
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </Button>
                                        </div>
                                    }
                                </div>
                            );
                        }
                    )}
                </div>
            );
            return (
                <>
                    <div />
                    {graphicsModels}
                    <div />
                    <div />
                </>
            );
        }

        deleteModel(item) {
            this.setStepState(StepState.running);
            axios
                .post(
                    "/api/delete_model",
                    {
                        data_table_idx: this.props.commonState.viewDataScopeId,
                        model_id: item.id,
                    },
                    null
                )
                .then((response) => {
                    if (response.data.success === false) {
                        this.setStepState(
                            StepState.error,
                            response.data.error_msg
                        );
                    } else {
                        models(this.props.commonState.viewDataScopeId)
                            .update()
                            .then(() => {
                                this.setStepState(StepState.notRunning);
                            })
                            .catch((error) => {
                                this.setStepState(
                                    StepState.error,
                                    String(error)
                                );
                            });
                    }
                })
                .catch((error) => {
                    this.setStepState(
                        StepState.error,
                        error.response.status.toString()
                    );
                });
        }

        render() {
            return (
                <>
                    <div className="circle-letter">{this.props.index}</div>
                    <Button
                        disabled={this.props.disabled}
                        className="btn-processing-tab"
                        style={{
                            height: "25px",
                        }}
                        onClick={() => {
                            this.setState({ expanded: !this.state.expanded });
                        }}
                    >
                        <span className="content-regular-text">Models</span>
                        <span className="content-regular-text">
                            {this.state.expanded ? "\uFF0D" : "\uFF0B"}
                        </span>
                    </Button>
                    {this.state.expanded ? (
                        <Button
                            className="btn btn-lg btn-primary my-primary"
                            onClick={() => {
                                this.setStepState(StepState.running);
                                models(this.props.commonState.viewDataScopeId)
                                    .update()
                                    .then(() => {
                                        this.setStepState(StepState.notRunning);
                                    })
                                    .catch((error) => {
                                        this.setStepState(
                                            StepState.error,
                                            String(error)
                                        );
                                    });
                            }}
                            style={{
                                fontFamily: "Roboto",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                height: "25px",
                                width: "100%",
                                minWidth: "110px",
                            }}
                        >
                            REFRESH
                        </Button>
                    ) : (
                        <div style={{ minWidth: "110px" }} />
                    )}
                    <div>{this.state.expanded && this.renderStepState()}</div>
                    {this.renderModels()}
                </>
            );
        }
    }
);
export { MainComponent };
export let requirePermission = "DataSetDeleteModels";
