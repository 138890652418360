import { CanvasType } from "common/Canvas";

const elements: Readonly<{ [key: string]: string }> = {
    submitButton: "submit_button",
    dataInputButton: "data_input_button",
    linkButton: "link_button",
    background: "background",
    flowchart: "flowchart",
    textBox: "text_box",
    numericInput: "numeric_input",
    sliderInput: "slider_input",
    toggleInput: "toggle_input",
    dropdownInput: "dropdown_input",
    table: "table",
    spreadsheet: "spreadsheet",
    shape: "shape",
    squareShape: "square_shape",
    parallelogramShape: "parallelogram_shape",
    circleShape: "circle_shape",
    lineShape: "line_shape",
    arrowShape: "arrow_shape",
    dashboard: "dashboard",
    backendTable: "backend_table",
    questionnaire: "questionnaire",
    inputData: "input_data",
    mergeDataProcessing: "merge_data_processing",
    aggregateDataProcessing: "aggregate_data_processing",
    manageTablesDataProcessing: "manage_tables_data_processing",
    map: "map",
    embedUrl: "embedUrl",
    barcodeReader: "barcode_reader",
    progressElement: "progress_element",
    graph: "graph",
    filterElement: "filter",
    survey: "survey"
};

const elementLabels: Readonly<{ [key: string]: string }> = {
    dataInputButton: "Data Input Button",
    submitButton: "Submit Button",
    linkButton: "Link Button",
    background: "Background",
    flowchart: "Flowchart",
    textBox: "Text Box",
    numericInput: "Input: Numeric",
    sliderInput: "Input: Slider",
    toggleInput: "Input: Toggle",
    dropdownInput: "Input: Dropdown",
    barcodeReader: "Input: Barcode Reader",
    progressElement: "Input: Progress Element",
    table: "Table",
    spreadsheet: "Spreadsheet",
    squareShape: "Shape: Square",
    parallelogramShape: "Shape: Parallelogram",
    circleShape: "Shape: Circle",
    lineShape: "Shape: Line",
    arrowShape: "Shape: arrow",
    dashboard: "Dashboard",
    backendTable: "Backend Table",
    questionnaire: "Questionnaire",
    inputData: "Data Input Element",
    embedUrl: "Data Science: Embed Url",
    mergeDataProcessing: "Data Science: Merge Data",
    aggregateDataProcessing: "Data Science: Aggregate Data",
    manageTablesDataProcessing: "Data Science: Manage Tables",
    map: "Map",
    graph: "Graph",
    filterElement: "Filter",
    survey: "Survey"
};

const canvasTypeToElement: Readonly<{ [key: string]: string }> = {
    [CanvasType.Input]: elements.numericInput,
    [CanvasType.Box]: elements.flowchart,
    [CanvasType.Slider]: elements.sliderInput,
    [CanvasType.Toggle]: elements.toggleInput,
    [CanvasType.DropdownSelector]: elements.dropdownInput,
    [CanvasType.LinkButton]: elements.linkButton,
    [CanvasType.SubmitButton]: elements.submitButton,
    [CanvasType.SimpleSpreadSheetInput]: elements.spreadsheet,
    [CanvasType.BarcodeReader]: elements.barcodeReader,
    [CanvasType.ProgressElement]: elements.progressElement,
    [CanvasType.TextBox]: elements.textBox,
    [CanvasType.Survey]: elements.survey
};

export default elements;
export { elementLabels, canvasTypeToElement };
