import React, { CSSProperties } from "react";

interface Props {
	style?: CSSProperties;
	hideBorder?: boolean;
}

export default function NotificationIcon(props: Props) {
	let hideBorder = props.hideBorder ?? false;
	let size = hideBorder ? "12px" : "18px";
	return (
		<div
			style={{
				height: size,
				boxSizing: "border-box",
				position: "absolute",
				top: 0,
				right: 0,
				border: hideBorder ? "none" : "3px solid #242E42",
				width: size,
				backgroundColor: "#05C985",
				borderRadius: "50%",
				...props.style,
			}}
		/>
	);
}