import "common/styles/App.css";
import modules from "../data_exploration_page/modules/module_list.json";
import ScaledPage from "common/ScaledPage";
import React, { Component } from "react";
import JourneyCard from "common/JourneyCard";
import Instrumentation from "common/Instrumentation";
import { ReactComponent as TrendingUpIcon } from "icons/left_nav_menu/trending_up.svg";

import styles from "./main.module.css";

class MainComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			outcomesSimulatorOpen: true,
		};
		this.submodules = {};
		modules.forEach((module, index) => {
			module.submodules.forEach((submodule) => {
				let mod = require(`../data_exploration_page/modules/${
					submodule.dir
				}/main.js`);
				submodule.mod = mod.MainComponent;
				this.submodules[submodule.name] = submodule;
			});
		});

		this.performance = null;
		this.mounted = false;
	}

	componentDidMount() {
		if(this.performance != null) {
			let timeMs = new Date() - this.performance;
			this.performance = null;
			Instrumentation.addInteraction("Forecasting", timeMs);
		}
	}

	componentWillUnmount() {}

	componentDidUpdate() {
		if(this.performance != null) {
			let timeMs = new Date() - this.performance;
			this.performance = null;
			Instrumentation.addInteraction("Forecasting", timeMs);
		}
	}

	render() {
		if(!this.mounted) {
			this.performance = new Date();
			this.mounted = true;
		}
		let outcomesSimulatorSubmodule = this.submodules[
			"OutcomesSimulatorJourney"
		];
		if (!outcomesSimulatorSubmodule) return null;
		return (
			<ScaledPage>
				<div className="content-wrapper hide-scroll">
					<section
						className="content"
						style={this.props.positionStyle}
					>
						<div className="flex-simple-column">
							{this.state.outcomesSimulatorOpen === false ? (
								<JourneyCard
									imageSource="/dist/img/data-exploration/outcomes_simulator_icon.png"
									title="Outcomes Simulator"
									subtitle="I want to test the different kinds of lever configuration."
									onClick={() => {
										this.setState({
											outcomesSimulatorOpen: true,
										});
									}}
								/>
							) : null}
							<outcomesSimulatorSubmodule.mod
								section="Forecasting"
								title={outcomesSimulatorSubmodule.title}
								journeyName={outcomesSimulatorSubmodule.name}
								imageSource={
									outcomesSimulatorSubmodule.image_source
								}
								key={
									this.state.outcomesSimulatorOpen
										? "OutcomesSimulatorJourneyExpanded"
										: "OutcomesSimulatorJourneyCollapsed"
								}
								onNewFinding={this.props.onNewFinding}
								collapsed={!this.state.outcomesSimulatorOpen}
								onClose={() => {
									this.setState({
										outcomesSimulatorOpen: false,
									});
								}}
							/>
						</div>
					</section>
				</div>
			</ScaledPage>
		);
	}
}

export { MainComponent };
export let route = "/forecasting.html",
	section = "Forecasting",
	leftPanelItem = {
		section: "Forecasting",
		href: "forecasting.html",
		icon: <TrendingUpIcon className={styles.icon} />,
	},
	requirePermission = "Forecasting";
