import StringOption from "common/StringOption";

const encodings: ReadonlyArray<StringOption> = [
    {
        label: "UTF-8",
        value: "utf-8",
    },
    {
        label: "UTF-16",
        value: "utf-16",
    },
    {
        label: "UTF-16BE",
        value: "utf-16be",
    },
    {
        label: "Macintosh",
        value: "macintosh",
    },
    {
        label: "MacCyrillic",
        value: "x-mac-cyrillic",
    },
    {
        label: "Windows-874",
        value: "windows-874",
    },
    {
        label: "Windows-1250",
        value: "windows-1250",
    },
    {
        label: "Windows-1251",
        value: "windows-1251",
    },
    {
        label: "Windows-1252",
        value: "windows-1252",
    },
    {
        label: "Windows-1253",
        value: "windows-1253",
    },
    {
        label: "Windows-1254",
        value: "windows-1254",
    },
    {
        label: "Windows-1255",
        value: "windows-1255",
    },
    {
        label: "Windows-1256",
        value: "windows-1256",
    },
    {
        label: "Windows-1257",
        value: "windows-1257",
    },
    {
        label: "Windows-1258",
        value: "windows-1258",
    },
    {
        label: "GBK",
        value: "gbk",
    },
    {
        label: "GB18030",
        value: "gb18030",
    },
    {
        label: "Big5",
        value: "big5",
    },
    {
        label: "EUC-JP",
        value: "euc-jp",
    },
    {
        label: "Shift-JIS",
        value: "shift-jis",
    },
    {
        label: "EUC-KR",
        value: "euc-kr",
    },
    {
        label: "ISO-2022-JP",
        value: "iso-2022-jp",
    },
    {
        label: "ISO-2022-KR",
        value: "iso-2022-kr",
    },
    {
        label: "IBM866",
        value: "ibm866",
    },
    {
        label: "ISO-8859-2",
        value: "iso-8859-2",
    },
    {
        label: "ISO-8859-3",
        value: "iso-8859-3",
    },
    {
        label: "ISO-8859-4",
        value: "iso-8859-4",
    },
    {
        label: "ISO-8859-5",
        value: "iso-8859-5",
    },
    {
        label: "ISO-8859-6",
        value: "iso-8859-6",
    },
    {
        label: "ISO-8859-7",
        value: "iso-8859-7",
    },
    {
        label: "ISO-8859-8",
        value: "iso-8859-8",
    },
    {
        label: "ISO-8859-8-I",
        value: "iso-8859-8-i",
    },
    {
        label: "ISO-8859-10",
        value: "iso-8859-10",
    },
    {
        label: "ISO-8859-13",
        value: "iso-8859-13",
    },
    {
        label: "ISO-8859-14",
        value: "iso-8859-14",
    },
    {
        label: "ISO-8859-15",
        value: "iso-8859-15",
    },
    {
        label: "ISO-8859-16",
        value: "iso-8859-16",
    },
    {
        label: "KOI8-R",
        value: "koi8-r",
    },
    {
        label: "KOI8-U",
        value: "koi8-u",
    },
];

export default encodings;
