import {
    observable,
    computed,
    toJS,
    IComputedValue,
    makeObservable,
    action,
    ObservableMap,
    runInAction,
} from "mobx";
import "core-js/modules/esnext.string.replace-all";
import Cookies from "universal-cookie";
import { getMaxCommonRect, haveIntersection } from "./SelectUtils";
import { Parser, Expression } from "./Parser";
import {
    addSharedBoxApi,
    updateSharedBoxApi,
    deleteSharedBoxApi,
    getLastAccessedCanvasApi,
    editNotSavedCanvasApi,
    editNotSavedCanvasThumbnailApi,
    getCanvasesApi,
    selectDataIfUpdated,
    sendConditionalNotification,
    BackgroundMode,
    EditNotSavedCanvasResponse,
    getCanvasApi,
} from "common/CanvasUserApi";
import { defaultAggregateState } from "common/AggregateTable";
import { getRawDataApi } from "common/DataApi";
import {
    addOrEditModuleUserDataSetApi,
    deleteModuleUserDataSetApi,
    getModuleUsers,
} from "common/ModulesApi";
import { formatDate } from "common/utilities/FormatDate";
import { calculateSpreadSheetGridSize } from "./canvas_elements/SpreadSheetElement";
import { renderHtmlRef } from "common/utilities/renderIcon";
import Canvases from "common/Canvases";
import { Condition } from "common/Conditions";
import { TableOption } from "common/Tables";
import { Variable, VariableOption } from "common/Variables";
import CurrentModulesStore from "common/CurrentModulesStore";
import { mainStyle } from "common/MainStyle";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import {
    ArrowPosition,
    Edge,
    PageBarInfo,
    ModuleOptions,
    getCurrentNotificationExpression,
    DataScienceElementKey,
    parseNumber,
    formatNumber,
    isNumberFormat,
    generateRawMetric,
    getPrettyPrintValue,
    getPrettyPrintFormatValue,
    getTargetValue,
    getOutputTargetValue,
    CanvasButton,
    CanvasButtonType,
    CanvasProgressElement,
    SpreadSheetNode,
    SimpleSpreadSheetInput,
    InnerCanvasChanges,
    CanvasType,
    CanvasInput,
    CanvasDropdownSelector,
    CanvasToggle,
    CanvasSharedTextBox,
    CanvasSlider,
    CanvasSubmitButton,
    Canvas,
    CanvasSimpleSpreadSheetInput,
    SubCanvas,
    CanvasBackground,
    CanvasDashboard,
    CanvasNode,
    CanvasElement,
    CanvasLegacyTagElement,
    CanvasTable,
    CanvasSharedBoxElement,
    CanvasPopupElement,
    CanvasGrid,
    CanvasGridType,
    CanvasSpreadSheetGrid,
    CanvasGridHeader,
    CanvasSharedNode,
    CanvasSharedBox,
    CanvasSharedSlider,
    CanvasSharedInput,
    CanvasSharedDropdownSelector,
    CanvasSharedToggle,
    QuestionnaireElement,
    QuestionnaireOutputType,
    MapElement,
    EmbedUrlElement,
    ShapeElement,
    ShapeType,
    hasAdditionalOutputs,
    isBox,
    isSimpleSpreadSheetInput,
    isSlider,
    isInput,
    isProgressElement,
    isTextBox,
    isSpreadSheetGrid,
    isDropdownSelector,
    isToggle,
    outerNodeId,
    outerSpreadSheetId,
    questionToAnswer,
    Node,
    Grid,
    SpreadSheetGrid,
    isTemplateSheetQuestion,
    MergeDataElement,
    ManageTableElement,
    AggregateTableElement,
    SelectionArea,
    SharedCanvas,
    ConnectedBackendTable,
    getNodeValueType,
    extractOuterId,
    extractOuterIdAndIndex,
    SharedModuleCanvas,
    CanvasTextBox,
    isDateFormat,
    isListFormat,
    isTextFormat,
    NumberFormat,
    DateFormat,
    ColumnFormat,
    ItemMetadata,
    CanvasBarcodeReader,
    pushTableChange,
    AddVariableOperation,
    EditVariableOperation,
    DeleteVariableOperation,
    AddRowOperation,
    DeleteRowOperation,
    OperationType,
    MapTooltipDisplayMode,
    calculateExpression,
    StatusExpression,
    NotificationExpression,
    PrintExpression,
    GraphElement,
    NodePosition,
    NodeIsHidden,
    SlideHeight,
    CanvasFilter,
    isFilter,
    NodeSize,
    SlideWidth,
    backwardCompatibilityForSlideHeight,
    addBackwardCompatibilityForAllCanvasItems,
    isSubmitButton,
    CanvasSharedProgressElement,
    InnerCanvas,
    SharedBoxOption,
    getBackgroundPlacementData,
    InputFieldStyle,
    CanvasRadioButtonsGroup,
    PlacementToolbar,
    CanvasElementOutput,
    SubmitType,
    isRadioButtonsGroup,
    NodeValue,
    CanvasElementType,
    CanvasSurvey,
    SurveyQuestionType,
    isSurvey,
    isBarcodeReader,
    isDropdownQuestion,
} from "common/Canvas";
import UserInfo from "common/UserInfo";
import { nanoid } from "nanoid";
import AsyncLock from "common/AsyncLock";
import Variables from "common/Variables";
import StringUtils from "common/utilities/StringUtils";
import getImageNaturalSize from "common/utilities/getImageNaturalSize";
import {
    sheetRibbonWidth,
    defaultSlideHeight,
    defaultMobileSlideHeight,
    defaultSlideWidth,
    canvasEditModeMargin,
    verticalSectionBarItemSize,
    verticalSectionBarItemSizePadding,
    verticalSectionBarFontSize,
    defaultMobileSlideWidth,
    headerBarHeight,
    desktopMobileButtonHeight,
    defaultInputFieldPlaceholder,
} from "./Constants";
import { SocketIOInstance } from "common/ServerConnection";
import CurrentUser from "common/CurrentUser";
import { SharedModule } from "common/Module";
import GlobalInputs, {
    GlobalInputType,
    GlobalInputsMap,
    getGlobalInputValue,
    getGlobalInputFormat,
} from "common/GlobalInputs";
import { reaction } from "mobx";
import { isMultiCondition, NodeLinkOption } from "common/Conditions";
import PageType from "common/PageType";
import PagesStore from "common/PagesStore";
import DataScopesForModules from "common/DataScopesForModules";
import { getNodeSize } from "./canvas_elements/getNodeSize";
import { strptime, strftime } from "common/utilities/TimeFormatUtils";
import { variableToColumnFormat } from "common/InputData";
import _ from "lodash";
import canvasSizeLimit from "./canvasSizeLimit";
import ModuleUserGroupsStore from "common/ModuleUserGroupsStore";
import PinStore from "./comments/PinStore";
import PinInformationStore from "./comments/PinInformationStore";
import NewCommentsStore from "./comments/NewCommentsStore";
import DashboardUpdater from "./DashboardUpdater";
import SharedBoxesStore, { migrateSharedBox } from "./SharedBoxesStore";
import remoteModuleId from "common/remoteModuleId";
import LastNodeOptionsStorage from "./LastNodeOptionsStorage";
import asyncAction, { AsyncAction } from "common/AsyncAction";
import { SearchComponentOption } from "common/SearchComponent";
import {
    dataOperations,
    globalDatasetOperations,
    specialDatasetOperations,
    variableOperations,
} from "common/DatasetOperations";
import { trackDataSetChanges } from "common/DatasetChangesTracking";
import {
    clearCanvasCalculatedValues,
    mergeNodes,
    argsAreEquals,
} from "./utilities/CalculatedValuesUtils";
import { convertFromRaw, EditorState, RawDraftContentState } from "draft-js";
import {
    jsonDiffPatchBlocks,
    jsonDiffPatchEntityMap,
} from "common/draft_utils/JsonDiffs";
import { MapFinding, NetworkFinding } from "common/Finding";
import { getTextSize } from "common/utilities/MeasureText";
import { Value } from "expr-eval";
import { Permission } from "common/Permissions";
import { modifySchemaApi } from "common/DataApi";
import { EditVariable, NewVariable } from "common/VariableCreator";

export const SPECIAL_TEMPLATE_PAGE = "Template Page";

const cookies = new Cookies();

interface Token {
    type: string;
    value: string | Token[];
    needDelete?: boolean;
}

var saveChangesLock = new AsyncLock({ timeout: 5000 });

interface CanvasTreeValueChanges {
    [key: string]: CanvasNode | null;
}

interface SlideState {
    backgrounds: CanvasBackground[];
    canvas: Canvas["canvas"];
}

export type CanvasViewMode = "desktop" | "mobile";

type ParserValue = string | number;

(Parser as any).binaryOps["=="] = (a: ParserValue, b: ParserValue) => {
    if (Array.isArray(b)) return b.includes(a);
    return a === b;
};

(Parser as any).binaryOps["!="] = (a: ParserValue, b: ParserValue) => {
    if (Array.isArray(b)) return !b.includes(a);
    return a === b;
};

/*!
 * Reactive component that manages state of current canvas slide
 * CanvasTreeStoreInner represents all data contained in
 * loaded slide.
 */
class CanvasTreeStoreInner {
    /*!
     * history keeps previous states for Ctrl+Z undo feature
     *
     */
    private history: { [key: number]: SlideState[] } = {};
    /*!
     * Index of the next entry that would be inserted into history
     *
     */
    private historyNextIndex: { [key: number]: number } = {};
    /*!
     * currentSlideState is a field that keeps current slide state to push it
     * into history
     *
     */
    private currentSlideState: { [key: number]: SlideState | undefined } = {};
    private temporary: boolean;
    private shared: boolean = false;
    private thumbnailRenderingTimeout: NodeJS.Timeout | null = null;

    public onUpdateSharedBoxInModule = (
        sharedId: number,
        box: CanvasSharedNode
    ) => {};

    public onError = (error: string) => {};

    public onUpdateSharedCanvasInModule = (
        canvasId: number,
        canvas: Canvas["canvas"],
        backgrounds: CanvasBackground[]
    ) => {};

    @observable public scale: number = 1;
    @observable public isTemplate: boolean = false;
    @observable public collapsed: boolean = false;
    @observable public live: boolean = true;
    @observable public canvasViewMode: CanvasViewMode = "desktop";
    @observable public nodePosition: NodePosition = {
        desktop: {
            x: 100,
            y: 100,
        },
        mobile: {
            x: 100,
            y: 100,
        },
    };
    @observable public nodeIsHidden: NodeIsHidden = {
        desktop: false,
        mobile: false,
    };
    @observable public slideHeight: SlideHeight = {
        desktop: defaultSlideHeight,
        mobile: defaultMobileSlideHeight,
    };
    @observable public slideWidth: SlideWidth = {
        desktop: defaultSlideWidth,
        mobile: defaultMobileSlideWidth,
    };

    // @observable public slideScrollTop: SlideScrollLeft =

    // @observable public slideScrollLeft:
    @observable public slideWidthRatio: number =
        Number(
            (this.slideWidth["mobile"] / this.slideWidth["desktop"]).toFixed(1)
        ) - 0.1;
    @observable public hidePagesBar = false;
    @observable public slideColor: string | undefined = undefined;
    @observable public connectedBackendTablesState = observable.map<
        string,
        ConnectedBackendTable
    >({}, { deep: false });
    @observable public connectedUsersState = observable.map<number, UserInfo>(
        {},
        { deep: false }
    );
    /*!
     * canvasTreeState stores all nodes for value calculations
     */
    @observable public canvasTreeState = observable.map<number, CanvasNode>(
        {},
        { deep: false }
    );
    public canvasAutoIncrementId: number = 0;
    public nodeSpreadSheetAutoIncrementId: number = 0;
    @observable public isLoadingState: boolean = true;
    @observable public mobileViewWasEdited: boolean | undefined = false;
    @observable public canvasWasEdited: boolean = true;
    @observable public placementToolbar: PlacementToolbar | undefined = {
        position: {
            x: 0,
            y: 0,
        },
    };
    @observable public revisionNumber: number = 1;
    @observable public canvasUpdateTimeState: number = 0;
    // canvasPageId can be string in shared module
    @observable public canvasPageId: number | undefined | string;
    @observable public canvasId: number | undefined;

    public delegateId: number | undefined;
    @observable public backgroundsState = observable.array<CanvasBackground>(
        [],
        { deep: false }
    );
    @observable public dashboardsState = observable.map<
        string,
        CanvasDashboard
    >({}, { deep: false });
    @observable public questionnaireElementsState = observable.map<
        string,
        QuestionnaireElement
    >({}, { deep: false });
    @observable public mapElementsState = observable.map<string, MapElement>(
        {},
        { deep: false }
    );
    @observable public graphElementsState = observable.map<
        string,
        GraphElement
    >({}, { deep: false });
    @observable public embedUrlElementsState = observable.map<
        string,
        EmbedUrlElement
    >({}, { deep: false });
    @observable public shapeElementsState = observable.map<
        string,
        ShapeElement
    >({}, { deep: false });
    // Deprecated
    // @observable public inputDataElementsState = observable.map<
    //     string,
    //     InputDataElement
    // >({}, { deep: false });
    @observable public mergeDataElementsState = observable.map<
        string,
        MergeDataElement
    >({}, { deep: false });
    @observable public manageTableElementsState = observable.map<
        string,
        ManageTableElement
    >({}, { deep: false });
    @observable public aggregateTableElementsState = observable.map<
        string,
        AggregateTableElement
    >({}, { deep: false });
    @observable public backendTablesState = observable.map<string, CanvasTable>(
        {},
        { deep: false }
    );
    @observable public buttonsState = observable.map<string, CanvasButton>(
        {},
        { deep: false }
    );
    @observable public canvasSizeState: { width: number; height: number } = {
        width: 0,
        height: 0,
    };
    @observable public gridsState = observable.map<string, CanvasGrid>(
        {},
        { deep: false }
    );
    public moduleId: number | undefined;
    public lastRequestedPageId: number | undefined;
    public canvasTreeArgs: {
        [key: number]: {
            new_update_times: number[];
            result: { [key: string]: number | string | null };
        } | null;
    } = {};
    @observable public canvasTreeRequestErrorsState = observable.map<
        number,
        string | null
    >({}, { deep: false });
    @observable public canvasOutdatedMaps = observable.set<string>();
    @observable public canvasTreeParseErrorsState = observable.map<
        number,
        string | null
    >({}, { deep: false });
    @observable public canvasDashboardErrorsState = observable.map<
        string,
        string | null
    >({}, { deep: false });
    private initialSize: { width: number; height: number };
    currentUserReaction: any;
    constructor(temporary: boolean = false) {
        makeObservable(this);
        this.initialSize = {
            width: 0,
            height: 0,
        };
        this.initDefaultAction();
        this.temporary = temporary;
        if (!temporary) {
            SocketIOInstance?.on(
                "module_user_shared_canvas",
                (content: {
                    data: {
                        operation: string;
                        [key: string]: any;
                    };
                }) => {
                    this.updateSharedCanvasAsyncAction.bothParts(content);
                }
            );
            SocketIOInstance?.on(
                "dataset_changes",
                (content: {
                    data: {
                        user_name?: string;
                        data_table_idx: string | number;
                        operation: string;
                        update_id?: string;
                    };
                }) => {
                    trackDataSetChanges(content.data, this.moduleId);
                    this.updateLinkedDatasetItemsAsyncAction.bothParts(content);
                }
            );
            this.currentUserReaction = reaction(
                () => CurrentUser.infoState,
                () => {
                    let changes = this.calculateValuesAction();
                    // this.saveChangesAction({ canvasTreeState: changes });
                    this.updateNodesByLinkedInputsAction({
                        canvasTreeState: changes,
                    });
                }
            );
        }
        this.updateComments = this.updateComments.bind(this);
        this.updateSharedBoxes = this.updateSharedBoxes.bind(this);

        this.updateSharedCanvasOperations = {
            update_editing_canvas: this
                .updateSharedCanvasUpdateEditingCanvasAction,
            update_editing_canvas_thumbnail: this
                .updateSharedCanvasUpdateEditingCanvasThumbnailAction,
            add_or_delete_canvas: this
                .updateSharedCanvasAddOrDeleteCanvasAsyncAction,
            move_canvas: this.updateMoveCanvasAction,
            add_or_delete_page: this
                .updateSharedCanvasAddOrDeletePageAsyncAction,
            move_page: this.updateSharedCanvasMovePageAction,
            edit_page: this.updateSharedCanvasEditPageAction,
            update_canvas_title: this.updateSharedCanvasUpdateCanvasTitleAction,
            update_canvas_hide_in_slideshow: this
                .updateSharedCanvasHideInSlideShowAction,
            edit_user_module: this.updateSharedCanvasEditUserModuleAction,
            update_page_bar_user_module: this.updatePageBarUserModuleAction,
            update_options_user_module: this.updateOptionsUserModuleAction,

            edit_user_module_permissions: this.updateUserModulePermissions,
            update_shared_boxes: this.updateSharedBoxes,
            add_or_delete_data_sets: this
                .updateSharedCanvasAddOrDeleteDataSetsAction,
            add_or_edit_data_set: this
                .updateSharedCanvasAddOrDeleteDataSetsAction,
            change_current_active_users: this.updateCurrentActiveUsersAction,
            delete_data_set: this.updateSharedCanvasAddOrDeleteDataSetsAction,
            comment_pin_add_comment: this.updateComments,
            comment_pin_edit_comment: this.updateComments,
            comment_pin_delete_comment: this.updateComments,
            add_comment_pin: this.updatePins,
            update_comment_pin: this.updatePins,
            delete_comment_pin: this.updatePins,
        };
        this.zIndexSortFunction = this.zIndexSortFunction.bind(this);
    }

    /*! List of all objects
     */
    private getObjects() {
        const objects: ReadonlyArray<{
            ref: ObservableMap<number | string, any>;
            name: keyof Canvas["canvas"];
            numericKey: boolean;
        }> = [
            {
                ref: this.canvasTreeState,
                name: "canvasTreeState",
                numericKey: true,
            },
            {
                ref: this.questionnaireElementsState,
                name: "questionnaireElementsState",
                numericKey: false,
            },
            {
                ref: this.mapElementsState,
                name: "mapElementsState",
                numericKey: false,
            },
            {
                ref: this.graphElementsState,
                name: "graphElementsState",
                numericKey: false,
            },
            {
                ref: this.embedUrlElementsState,
                name: "embedUrlElementsState",
                numericKey: false,
            },
            {
                ref: this.shapeElementsState,
                name: "shapeElementsState",
                numericKey: false,
            },
            // Deprecated
            // {
            //     ref: this.inputDataElementsState,
            //     name: "inputDataElementsState",
            //     numericKey: false,
            // },
            {
                ref: this.mergeDataElementsState,
                name: "mergeDataElementsState",
                numericKey: false,
            },
            {
                ref: this.manageTableElementsState,
                name: "manageTableElementsState",
                numericKey: false,
            },
            {
                ref: this.aggregateTableElementsState,
                name: "aggregateTableElementsState",
                numericKey: false,
            },
            {
                ref: this.backendTablesState,
                name: "backendTablesState",
                numericKey: false,
            },
            {
                ref: this.gridsState,
                name: "gridsState",
                numericKey: false,
            },
            {
                ref: this.buttonsState,
                name: "buttonsState",
                numericKey: false,
            },
            {
                ref: this.dashboardsState,
                name: "dashboardsState",
                numericKey: false,
            },
        ];
        return objects;
    }

    private extractDataScopeIdsFromNode(node: CanvasNode): (number | string)[] {
        // Connect or disconnect data sets
        if (
            isBox(node) ||
            isTextBox(node) ||
            isProgressElement(node) ||
            isSlider(node)
        ) {
            if (node.dataTableInputDetails != null) {
                return node.dataTableInputDetails.map(
                    (dataTableInput) => dataTableInput.data_table_idx
                );
            }
        } else if (
            isDropdownSelector(node) ||
            isRadioButtonsGroup(node) ||
            isFilter(node)
        ) {
            if (node.dataScopeOption?.value != null) {
                return [node.dataScopeOption?.value];
            }
        } else if (
            isSubmitButton(node) ||
            isSurvey(node) ||
            isBarcodeReader(node)
        ) {
            if (node.backendOutput.tableOption?.data_table_idx != null) {
                return [node.backendOutput.tableOption?.data_table_idx];
            } else if (node.backendOutput.dataScopeOption?.value != null) {
                return [node.backendOutput.dataScopeOption?.value];
            }
        }
        return [];
    }

    public isDataScopeInUse(dataScopeId: number | string): boolean {
        for (let node of this.canvasTreeState.values()) {
            let dataScopeIds = this.extractDataScopeIdsFromNode(node);
            if (dataScopeIds.includes(dataScopeId)) {
                return true;
            }
        }

        for (let grid of this.gridsState.values()) {
            if (
                grid.fullSpreadSheetBackendOutputOptions?.dataScopeId ===
                    dataScopeId ||
                grid.fullSpreadSheetBackendOutputOptions?.tableOption
                    ?.data_table_idx === dataScopeId
            ) {
                return true;
            }
        }
        for (let dashboard of this.dashboardsState.values()) {
            if (
                dashboard.finding?.config?.dataScope?.value === dataScopeId ||
                dashboard.finding?.config?.selectedTable?.data_table_idx ===
                    dataScopeId
            ) {
                return true;
            }
        }

        for (let backendTable of this.backendTablesState.values()) {
            if (
                backendTable.dataScopeOption?.value === dataScopeId ||
                backendTable.tableOption?.data_table_idx === dataScopeId
            ) {
                return true;
            }
        }

        for (let mapElement of this.mapElementsState.values()) {
            if (
                mapElement.dataScope?.value === dataScopeId ||
                mapElement.tableOption?.data_table_idx === dataScopeId
            ) {
                return true;
            }
        }

        for (let questionnaireElement of this.questionnaireElementsState.values()) {
            if (
                questionnaireElement.backendOutput?.dataScopeOption?.value ===
                    dataScopeId ||
                questionnaireElement.backendOutput?.tableOption
                    ?.data_table_idx === dataScopeId
            ) {
                return true;
            }

            for (let question of questionnaireElement.questions) {
                if (
                    isDropdownQuestion(question) &&
                    (question.dataScopeOption?.value === dataScopeId ||
                        question.tableOption?.data_table_idx === dataScopeId)
                ) {
                    return true;
                }
            }
        }

        for (let aggregateTableElement of this.aggregateTableElementsState.values()) {
            if (
                aggregateTableElement.dataScope?.value === dataScopeId ||
                aggregateTableElement.aggregateDataTable?.data_table_idx ===
                    dataScopeId
            ) {
                return true;
            }
        }

        for (let aggregateTableElement of this.manageTableElementsState.values()) {
            if (
                aggregateTableElement.dataScope?.value === dataScopeId ||
                aggregateTableElement.tableOption?.data_table_idx ===
                    dataScopeId
            ) {
                return true;
            }
        }

        for (let mergeDataElement of this.mergeDataElementsState.values()) {
            if (
                mergeDataElement.leftDataScope?.value === dataScopeId ||
                mergeDataElement.rightDataScope?.value === dataScopeId ||
                mergeDataElement.targetDataScope?.value === dataScopeId ||
                mergeDataElement.leftTableOption?.data_table_idx ===
                    dataScopeId ||
                mergeDataElement.rightTableOption?.data_table_idx ===
                    dataScopeId
            ) {
                return true;
            }
        }

        return false;
    }

    public onDataSetConnected(dataScopeId: number | string): void {
        if (this.moduleId != null) {
            addOrEditModuleUserDataSetApi(
                this.moduleId,
                dataScopeId,
                Permission.ReadWrite,
                true,
                false,
                true,
                this.canvasId,
                cookies.get("instrumentation_session_id")
            ).catch((error) => {
                console.log(error);
            });
        }
    }

    public onDataSetDisconnected(dataScopeId: number | string): void {
        if (this.moduleId != null && !this.isDataScopeInUse(dataScopeId)) {
            deleteModuleUserDataSetApi(
                this.moduleId,
                dataScopeId,
                true,
                this.canvasId,
                cookies.get("instrumentation_session_id")
            ).catch((error) => {
                console.log(error);
            });
        }
    }

    private async fixSurveyStateMismatch(): Promise<void> {
        for (let node of this.canvasTreeState.values()) {
            if (isSurvey(node)) {
                if (node.backendOutput.tableOption?.data_table_idx != null) {
                    let dataVariables = Variables(
                        node.backendOutput.tableOption?.data_table_idx,
                        this.moduleId
                    ).dataVariables;
                    if (dataVariables.length === 0) {
                        await Variables(
                            node.backendOutput.tableOption?.data_table_idx,
                            this.moduleId
                        ).update(this.moduleId);
                        dataVariables = Variables(
                            node.backendOutput.tableOption?.data_table_idx,
                            this.moduleId
                        ).dataVariables;
                    }
                    let editVariables: EditVariable[] = [];
                    if (node.questions.length + 1 === dataVariables.length) {
                        // If a column was renamed
                        for (let i = 0; i < node.questions.length; ++i) {
                            if (
                                node.questions[i].question !==
                                dataVariables[i + 1].name
                            ) {
                                editVariables.push({
                                    index: i + 1,
                                    new_name: node.questions[i].question,
                                });
                            }
                        }

                        try {
                            await modifySchemaApi(
                                node.backendOutput.tableOption?.data_table_idx,
                                editVariables,
                                [],
                                [],
                                this.moduleId
                            );
                        } catch (error) {
                            console.log(error);
                        }
                        await Variables(
                            node.backendOutput.tableOption?.data_table_idx
                        ).update(this.moduleId);
                    }
                }
            }
        }
    }

    private async undoCallback(): Promise<void> {
        this.fixSurveyStateMismatch();
    }

    private async redoCallback(): Promise<void> {
        this.fixSurveyStateMismatch();
    }

    @action.bound
    private updateNodesByLinkedSharedInputs() {
        for (let sharedBox of SharedBoxesStore.sharedBoxesState) {
            this.updateNodesByLinkedSharedInputAction(sharedBox.item.id, true);
        }
    }

    @action.bound
    private updateSharedCanvasUpdateEditingCanvasAction(
        data: {
            operation: "update_editing_canvas";
            user_name: string;
            background_mode: BackgroundMode;
            backgrounds: CanvasBackground[];
            canvas: string;
            replace: boolean;
            canvas_id: number;
            thumbnail?: string;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        if (data.canvas_id !== this.canvasId) {
            return;
        }

        let canvas: Canvas["canvas"] = JSON.parse(data.canvas);
        let atomicFields: (
            | "canvasAutoIncrementId"
            | "slideHeight"
            | "slideColor"
            | "hidePagesBar"
            | "canvasUpdateTimeState"
            | "canvasAutoIncrementId"
            | "nodeSpreadSheetAutoIncrementId"
        )[] = [
            "canvasAutoIncrementId",
            "slideHeight",
            "slideColor",
            "hidePagesBar",
            "canvasUpdateTimeState",
            "canvasAutoIncrementId",
            "nodeSpreadSheetAutoIncrementId",
        ];
        for (let atomicField of atomicFields) {
            if (atomicField in canvas) {
                (this as any)[atomicField] = canvas[atomicField]!;
            }
        }

        const objects: ReadonlyArray<{
            ref: ObservableMap<number | string, any>;
            name: keyof Canvas["canvas"];
            numericKey: boolean;
        }> = this.getObjects();
        let needCalculate = false;
        let needUpdateArgs = false;
        let dashboardIds: string[] = [];
        for (let { ref, name, numericKey } of objects) {
            if (data.replace) {
                if (canvas[name] != null) {
                    if (numericKey) {
                        ref.replace(
                            Object.entries(
                                canvas[name] ?? {}
                            ).map(([key, value]) => [Number(key), value])
                        );
                    } else {
                        ref.replace(canvas[name]);
                    }
                } else {
                    ref.clear();
                }
            } else if (canvas[name] != null) {
                if (name === "canvasTreeState") needCalculate = true;

                let oldNode: any | undefined;
                let key: number | string;
                for (let [keyStr, node] of Object.entries(
                    canvas[name] as object
                )) {
                    if (numericKey) {
                        key = Number(keyStr);
                    } else {
                        key = keyStr;
                    }

                    if (node == null) {
                        ref.delete(key);
                    } else {
                        oldNode = ref.get(key);
                        if (name === "dashboardsState") {
                            needCalculate = true;
                            dashboardIds.push(key as string);
                        }
                        if (oldNode == null) {
                            ref.set(key, node);
                        } else {
                            let mergedNode = mergeNodes(oldNode, node, name);

                            ref.set(key, mergedNode);
                            if (name === "canvasTreeState") {
                                needUpdateArgs =
                                    needUpdateArgs ||
                                    !argsAreEquals(oldNode, mergedNode);
                            }
                        }
                    }
                }
            }
        }
        switch (data.background_mode) {
            case BackgroundMode.Replace:
                this.backgroundsState.replace(data.backgrounds);
                break;
            case BackgroundMode.Append:
                for (let background of data.backgrounds) {
                    this.backgroundsState.push(background);
                }
                break;
            case BackgroundMode.Update:
                let backgrounds: {
                    [key: number]: CanvasBackground;
                } = {};
                for (let background of data.backgrounds) {
                    backgrounds[background.id] = background;
                }
                for (let i = 0; i < this.backgroundsState.length; ++i) {
                    let background = this.backgroundsState[i];
                    if (background.id in backgrounds)
                        this.backgroundsState[i] = backgrounds[background.id];
                }
                break;
            case BackgroundMode.Delete:
                let backgroundIds = new Set<number>();
                for (let background of data.backgrounds) {
                    backgroundIds.add(background.id);
                }
                this.backgroundsState.replace(
                    this.backgroundsState.filter(
                        (background) => !backgroundIds.has(background.id)
                    )
                );
                break;
            default:
                break;
        }
        if (needUpdateArgs) {
            this.updateArgsAsyncAction
                .bothParts(true, this.canvasTreeState)
                .then(() => {
                    if (data.canvas_id !== this.canvasId) return;
                    this.calculateValuesAction();
                    this.updateNodesByLinkedInputsAction({
                        canvasTreeState: Object.fromEntries(
                            this.canvasTreeState
                        ),
                    });
                });
        }
        if (!needUpdateArgs && needCalculate) {
            this.calculateValuesAction();
            this.updateNodesByLinkedInputsAction({
                canvasTreeState: Object.fromEntries(this.canvasTreeState),
            });
        }
        if (dashboardIds.length > 0) {
            this.updateDashboards(dashboardIds);
        }
        if (data.thumbnail != null && this.canvasPageId != null) {
            this.updateSharedCanvasThumbnailAction(data.thumbnail);
        }
    }

    private updatePins(data: {
        operation: string;
        canvas_id: number;
        pin_id: number;
    }) {
        PinStore(data.canvas_id).updatePins();
    }

    private async updateComments(data: {
        operation: string;
        canvas_id: number;
        pin_id: number;
    }) {
        try {
            await PinStore(data.canvas_id).updateComments(
                data.pin_id,
                PinInformationStore.expandedPinId !== data.pin_id
            );
            if (this.moduleId != null) NewCommentsStore(this.moduleId).update();
        } catch (exception) {
            console.log(String(exception));
        }
    }

    @action.bound
    private updateSharedCanvasUpdateEditingCanvasThumbnailAction(
        data: {
            operation: "update_editing_canvas_thumbnail";
            user_name: string;
            thumbnail: string;
            canvas_id: number;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        if (data.canvas_id !== this.canvasId) {
            return;
        }

        this.updateSharedCanvasThumbnailAction(data.thumbnail!);
    }

    private updateSharedCanvasAddOrDeleteCanvasAsyncAction = asyncAction(
        async (
            data: {
                operation: "add_or_delete_canvas";
                user_name: string;
                page_id: number;
                canvas_id: number;
                update_id?: string;
            },
            _instrumentationId?: string
        ): Promise<
            [
                number | undefined,
                Canvas | undefined,
                () => void,
                (
                    | [
                          Canvas["canvas"],
                          {
                              canvasId: number | undefined;
                              backgrounds: CanvasBackground[];
                              delegateId?: number;
                          },
                          CanvasBackground[],
                          CanvasBackground[],
                          InnerCanvasChanges,
                          [
                              Array<
                                  [
                                      (
                                          | CanvasTextBox
                                          | CanvasElement
                                          | CanvasSlider
                                      ),
                                      {
                                          new_update_times: number[];
                                          result: {
                                              [key: string]:
                                                  | number
                                                  | string
                                                  | null;
                                          };
                                      } | null,
                                      string | null
                                  ]
                              >,
                              boolean
                          ]
                      ]
                    | null
                )
            ]
        > => {
            let pageId = data.page_id;
            Canvases(pageId).update();
            if (data.canvas_id === this.canvasId) {
                return this.restoreFromPageAsyncAction.asyncPart(
                    pageId,
                    () => {}
                );
            }
            return [-1, undefined, () => {}, null];
        },
        action(
            (
                pageId: number | undefined,
                canvas: Canvas | undefined,
                onRestore: () => void,
                deserializeData:
                    | [
                          Canvas["canvas"],
                          {
                              canvasId: number | undefined;
                              backgrounds: CanvasBackground[];
                              delegateId?: number;
                          },
                          CanvasBackground[],
                          CanvasBackground[],
                          InnerCanvasChanges,
                          [
                              Array<
                                  [
                                      (
                                          | CanvasTextBox
                                          | CanvasElement
                                          | CanvasSlider
                                      ),
                                      {
                                          new_update_times: number[];
                                          result: {
                                              [key: string]:
                                                  | number
                                                  | string
                                                  | null;
                                          };
                                      } | null,
                                      string | null
                                  ]
                              >,
                              boolean
                          ]
                      ]
                    | null
            ) => {
                if (pageId !== -1) {
                    this.restoreFromPageAsyncAction.actionPart(
                        pageId,
                        canvas,
                        onRestore,
                        deserializeData
                    );
                }
            }
        )
    );

    @action.bound
    private updateMoveCanvasAction(
        data: {
            operation: "move_canvas";
            user_name: string;
            page_id: number;
            swap_page_id: number;
            canvas_id: number;
            swap_id: number;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        let page_id = data.page_id;
        let swap_page_id = data.swap_page_id;
        let swap_id = data.swap_id;
        Canvases(page_id).update();
        if (page_id !== swap_page_id) {
            Canvases(swap_page_id).update();
            if (this.canvasId === swap_id) {
                let oldPageId = this.canvasPageId;
                this.joinPageRoom(oldPageId, page_id);
                this.canvasPageId = page_id;
            }
        }
    }
    @action.bound
    private updateSharedCanvasUpdateCanvasTitleAction(
        data: {
            operation: "update_canvas_title";
            user_name: string;
            page_id: number;
            canvas_id: number;
            title: string;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        let pageId = data.page_id;

        let index = Canvases(pageId).canvasesState.findIndex(
            (canvas) => canvas.id === data.canvas_id
        );
        if (index !== -1) {
            let canvasesState = Array.from(Canvases(pageId).canvasesState);
            canvasesState[index] = {
                ...canvasesState[index],
                title: data.title,
            };
            Canvases(pageId).canvasesState = canvasesState;
        }
    }
    @action.bound
    private updateSharedCanvasHideInSlideShowAction(
        data: {
            operation: "update_canvas_hide_in_slideshow";
            user_name: string;
            page_id: number;
            canvas_id: number;
            hide_in_slideshow: boolean;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        let pageId = data.page_id;

        Canvases(pageId).hideInSlideShow(
            data.canvas_id,
            data.hide_in_slideshow
        );
    }

    private updateSharedCanvasAddOrDeletePageAsyncAction = asyncAction(
        async (
            data: {
                operation: "add_or_delete_page";
                user_name: string;
                page_id: number;
                update_id?: string;
            },
            _instrumentationId?: string
        ): Promise<
            [
                number | undefined,
                Canvas | undefined,
                () => void,
                (
                    | [
                          Canvas["canvas"],
                          {
                              canvasId: number | undefined;
                              backgrounds: CanvasBackground[];
                              delegateId?: number;
                          },
                          CanvasBackground[],
                          CanvasBackground[],
                          InnerCanvasChanges,
                          [
                              Array<
                                  [
                                      (
                                          | CanvasTextBox
                                          | CanvasElement
                                          | CanvasSlider
                                      ),
                                      {
                                          new_update_times: number[];
                                          result: {
                                              [key: string]:
                                                  | number
                                                  | string
                                                  | null;
                                          };
                                      } | null,
                                      string | null
                                  ]
                              >,
                              boolean
                          ]
                      ]
                    | null
                )
            ]
        > => {
            PagesStore(PageType.Canvases).updatePages();
            if (data.page_id === this.canvasPageId) {
                return this.restoreFromPageAsyncAction.asyncPart(
                    undefined,
                    () => {}
                );
            }
            return [-1, undefined, () => {}, null];
        },
        action(
            (
                pageId: number | undefined,
                canvas: Canvas | undefined,
                onRestore: () => void,
                deserializeData:
                    | [
                          Canvas["canvas"],
                          {
                              canvasId: number | undefined;
                              backgrounds: CanvasBackground[];
                              delegateId?: number;
                          },
                          CanvasBackground[],
                          CanvasBackground[],
                          InnerCanvasChanges,
                          [
                              Array<
                                  [
                                      (
                                          | CanvasTextBox
                                          | CanvasElement
                                          | CanvasSlider
                                      ),
                                      {
                                          new_update_times: number[];
                                          result: {
                                              [key: string]:
                                                  | number
                                                  | string
                                                  | null;
                                          };
                                      } | null,
                                      string | null
                                  ]
                              >,
                              boolean
                          ]
                      ]
                    | null
            ) => {
                if (pageId !== -1) {
                    this.restoreFromPageAsyncAction.actionPart(
                        pageId,
                        canvas,
                        onRestore,
                        deserializeData
                    );
                }
            }
        )
    );

    @action.bound
    private updateSharedCanvasMovePageAction(
        _data: {
            operation: "move_page";
            user_name: string;
            page_id: number;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        PagesStore(PageType.Canvases).updatePages();
    }

    @action.bound
    private updateSharedCanvasEditPageAction(
        data: {
            operation: "edit_page";
            user_name: string;
            page_id: number;
            title: string;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        let index = PagesStore(PageType.Canvases).pagesState.findIndex(
            (page) => page.id === data.page_id
        );
        if (index !== -1) {
            let pagesState = Array.from(
                PagesStore(PageType.Canvases).pagesState
            );
            pagesState[index] = {
                ...pagesState[index],
                title: data.title,
            };
            PagesStore(PageType.Canvases).pagesState = pagesState;
        }
    }

    @action.bound
    private updateSharedCanvasEditUserModuleAction(
        data: {
            operation: "edit_user_module";
            user_name: string;
            title: string;
            module_id: number;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        let index = CurrentModulesStore.modulesState.findIndex(
            (module) => module.id === data.module_id
        );
        if (index !== -1) {
            let modulesState = Array.from(CurrentModulesStore.modulesState);
            modulesState[index] = {
                ...modulesState[index],
                title: data.title,
            };
            CurrentModulesStore.modulesState = modulesState;
        }
    }

    private updateUserModulePermissions(
        data: {
            operation: "edit_user_module_permissions";
            user_name: string;
            module_id: number;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        if (data.module_id === this.moduleId)
            ModuleUserGroupsStore(data.module_id).updateUserGroups();
    }

    @action.bound
    private updatePageBarUserModuleAction(
        data: {
            operation: "update_page_bar_user_module";
            user_name: string;
            page_bar_info: PageBarInfo;
            module_id: number;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        let index = CurrentModulesStore.modulesState.findIndex(
            (module) => module.id === data.module_id
        );
        if (index !== -1) {
            let modulesState = Array.from(CurrentModulesStore.modulesState);
            modulesState[index] = {
                ...modulesState[index],
                page_bar_info: data.page_bar_info,
            };
            CurrentModulesStore.modulesState = modulesState;
        }
    }
    @action.bound
    private updateOptionsUserModuleAction(
        data: {
            operation: "update_options_user_module";
            user_name: string;
            options: ModuleOptions;
            module_id: number;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        let index = CurrentModulesStore.modulesState.findIndex(
            (module) => module.id === data.module_id
        );
        if (index !== -1) {
            let modulesState = Array.from(CurrentModulesStore.modulesState);
            modulesState[index] = {
                ...modulesState[index],
                options: data.options,
            };
            CurrentModulesStore.modulesState = modulesState;
        }
    }

    @action.bound
    private updateCurrentActiveUsersAction(
        data: {
            operation: "change_current_active_users";
            current_users: string[];
            module_id: number;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        ModuleUserGroupsStore(data.module_id).activeUsers = new Set(
            data.current_users
        );
    }

    private async updateSharedBoxes(
        data: {
            operation: "update_shared_boxes";
            module_id: number;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        await SharedBoxesStore.updateSharedBoxes(data.module_id);
        runInAction(() => {
            let changes = this.calculateValuesAction();
            this.saveChangesAction({ canvasTreeState: changes }, false, false);
            this.updateNodesByLinkedSharedInputs();
        });
    }

    @action.bound
    private updateSharedCanvasAddOrDeleteDataSetsAction(
        data: {
            operation: "add_or_delete_data_sets";
            user_name: string;
            module_id: number;
            update_id?: string;
        },
        _instrumentationId?: string
    ) {
        DataScopesForModules(data.module_id).update();
    }

    private updateSharedCanvasOperations: Readonly<{
        [key: string]:
            | ((data: any, instrumentationId?: string) => void)
            | AsyncAction<[any, string], any, void>;
    }>;

    private updateSharedCanvasAsyncAction = asyncAction(
        async (content: {
            data: {
                operation: string;
                [key: string]: any;
            };
        }): Promise<
            [
                boolean,
                string | undefined,
                {
                    operation: string;
                    [key: string]: any;
                },
                any
            ]
        > => {
            let data = content.data;
            let instrumentationId = cookies.get("instrumentation_session_id");
            if (
                data.update_id != null &&
                instrumentationId != null &&
                data.user_name?.toLowerCase() ===
                    CurrentUser.infoState?.user_name?.toLowerCase() &&
                data.update_id === instrumentationId
            ) {
                return [false, instrumentationId, data, undefined];
            }

            let handler = this.updateSharedCanvasOperations[data.operation];
            if (handler != null) {
                if (typeof handler !== "function") {
                    return [
                        true,
                        instrumentationId,
                        data,
                        await handler.asyncPart(data, instrumentationId),
                    ];
                } else {
                    return [true, instrumentationId, data, undefined];
                }
            } else {
                // The default handler
                if (typeof this.canvasPageId !== "string") {
                    return [
                        true,
                        instrumentationId,
                        data,
                        await this.restoreFromPageAsyncAction.asyncPart(
                            this.canvasPageId,
                            () => {},
                            this.live,
                            this.canvasId
                        ),
                    ];
                }
            }
            return [false, instrumentationId, data, undefined];
        },
        action(
            (
                called: boolean,
                instrumentationId: string | undefined,
                data: {
                    operation: string;
                    [key: string]: any;
                },
                args: any
            ) => {
                if (called) {
                    let handler = this.updateSharedCanvasOperations[
                        data.operation
                    ];
                    if (handler != null) {
                        if (typeof handler !== "function") {
                            handler.actionPart(...args);
                        } else {
                            handler(data, instrumentationId);
                        }
                    } else {
                        if (typeof this.canvasPageId !== "string") {
                            this.restoreFromPageAsyncAction.actionPart(
                                ...(args as [
                                    number | undefined,
                                    Canvas | undefined,
                                    () => void,
                                    (
                                        | [
                                              Canvas["canvas"],
                                              {
                                                  canvasId: number | undefined;
                                                  backgrounds: CanvasBackground[];
                                                  delegateId?: number;
                                              },
                                              CanvasBackground[],
                                              CanvasBackground[],
                                              InnerCanvasChanges,
                                              [
                                                  Array<
                                                      [
                                                          (
                                                              | CanvasTextBox
                                                              | CanvasElement
                                                              | CanvasSlider
                                                          ),
                                                          {
                                                              new_update_times: number[];
                                                              result: {
                                                                  [
                                                                      key: string
                                                                  ]:
                                                                      | number
                                                                      | string;
                                                              };
                                                          } | null,
                                                          string | null
                                                      ]
                                                  >,
                                                  boolean
                                              ]
                                          ]
                                        | null
                                    )
                                ])
                            );
                        }
                    }
                }
            }
        )
    );

    private updateLinkedDatasetItemsAsyncAction = asyncAction(
        async (content: {
            data: {
                user_name?: string;
                data_table_idx: string | number;
                operation: string;
                update_id?: string;
            };
        }): Promise<
            [
                boolean,
                string | undefined,
                {
                    user_name?: string;
                    data_table_idx: string | number;
                    operation: string;
                    update_id?: string;
                },
                any
            ]
        > => {
            let data = content.data;
            let instrumentationId = cookies.get("instrumentation_session_id");
            // We don't check whether instrumentation ID is the same
            // because if the user changed the data set using one element,
            // then other elements need to react to this change
            let operation = data.operation;
            let called =
                dataOperations.has(operation) ||
                globalDatasetOperations.has(operation) ||
                variableOperations.has(operation) ||
                specialDatasetOperations.has(operation);
            return [called, instrumentationId, data, undefined];
        },
        action(
            (
                called: boolean,
                instrumentationId: string | undefined,
                data: {
                    user_name?: string;
                    data_table_idx: string | number;
                    operation: string;
                    update_id?: string;
                },
                args: any
            ) => {
                if (called) {
                    this.updateNodesByDatasetAction(data.data_table_idx);
                }
            }
        )
    );

    @action.bound
    private updateSharedCanvasThumbnailAction(thumbnail: string): void {
        let index = Canvases(
            this.canvasPageId as number
        ).canvasesState.findIndex((item) => item.id === this.canvasId);
        Canvases(this.canvasPageId as number).canvasesState[index] = {
            ...Canvases(this.canvasPageId as number).canvasesState[index],
            thumbnail: thumbnail!,
        };
    }

    public undoAsyncAction = asyncAction(
        async (): Promise<
            [
                SlideState | undefined,
                (
                    | [
                          Canvas["canvas"],
                          {
                              canvasId: number | undefined;
                              backgrounds: CanvasBackground[];
                              delegateId?: number;
                          },
                          CanvasBackground[],
                          CanvasBackground[],
                          InnerCanvasChanges,
                          [
                              Array<
                                  [
                                      (
                                          | CanvasTextBox
                                          | CanvasElement
                                          | CanvasSlider
                                      ),
                                      {
                                          new_update_times: number[];
                                          result: {
                                              [key: string]:
                                                  | number
                                                  | string
                                                  | null;
                                          };
                                      } | null,
                                      string | null
                                  ]
                              >,
                              boolean
                          ]
                      ]
                    | null
                )
            ]
        > => {
            if (
                this.canvasId != null &&
                this.historyNextIndex[this.canvasId] != null &&
                this.historyNextIndex[this.canvasId] > 0
            ) {
                let previousState: SlideState;
                if (
                    this.historyNextIndex[this.canvasId] ===
                        this.history[this.canvasId].length &&
                    this.currentSlideState[this.canvasId] != null
                ) {
                    // We need to push the current state to make redo work
                    this.history[this.canvasId].push(
                        this.currentSlideState[this.canvasId]!
                    );
                }
                this.historyNextIndex[this.canvasId] -= 1;
                previousState = this.history[this.canvasId][
                    this.historyNextIndex[this.canvasId]
                ];
                if (previousState != null) {
                    return [
                        previousState,
                        await this.deserializeAsyncAction.asyncPart(
                            previousState.canvas,
                            {
                                canvasId: this.canvasId,
                                backgrounds: previousState.backgrounds,
                                delegateId: this.delegateId,
                            }
                        ),
                    ];
                } else {
                    return [undefined, null];
                }
            } else {
                return [undefined, null];
            }
        },
        action(
            (
                previousState: SlideState | undefined,
                deserializeData:
                    | [
                          Canvas["canvas"],
                          {
                              canvasId: number | undefined;
                              backgrounds: CanvasBackground[];
                              delegateId?: number;
                          },
                          CanvasBackground[],
                          CanvasBackground[],
                          InnerCanvasChanges,
                          [
                              Array<
                                  [
                                      (
                                          | CanvasTextBox
                                          | CanvasElement
                                          | CanvasSlider
                                      ),
                                      {
                                          new_update_times: number[];
                                          result: {
                                              [key: string]:
                                                  | number
                                                  | string
                                                  | null;
                                          };
                                      } | null,
                                      string | null
                                  ]
                              >,
                              boolean
                          ]
                      ]
                    | null
            ) => {
                if (previousState != null && deserializeData != null) {
                    this.deserializeAsyncAction.actionPart(...deserializeData);
                    this.undoCallback();
                    this.saveChangesAction(
                        previousState.canvas,
                        true,
                        true,
                        true,
                        previousState.backgrounds,
                        BackgroundMode.Replace,
                        true,
                        ({ background_ids }) => {
                            // background ids will change in replace mode, so
                            // we have to update them
                            for (
                                let i = 0;
                                i < this.backgroundsState.length &&
                                i < background_ids.length;
                                ++i
                            ) {
                                this.backgroundsState[i].id = background_ids[i];
                            }
                        }
                    );
                }
            }
        )
    );

    public redoAsyncAction = asyncAction(
        async (): Promise<
            [
                SlideState | undefined,
                (
                    | [
                          Canvas["canvas"],
                          {
                              canvasId: number | undefined;
                              backgrounds: CanvasBackground[];
                              delegateId?: number;
                          },
                          CanvasBackground[],
                          CanvasBackground[],
                          InnerCanvasChanges,
                          [
                              Array<
                                  [
                                      (
                                          | CanvasTextBox
                                          | CanvasElement
                                          | CanvasSlider
                                      ),
                                      {
                                          new_update_times: number[];
                                          result: {
                                              [key: string]:
                                                  | number
                                                  | string
                                                  | null;
                                          };
                                      } | null,
                                      string | null
                                  ]
                              >,
                              boolean
                          ]
                      ]
                    | null
                )
            ]
        > => {
            if (
                this.canvasId != null &&
                this.history[this.canvasId] != null &&
                this.historyNextIndex[this.canvasId] <
                    this.history[this.canvasId].length
            ) {
                this.historyNextIndex[this.canvasId] += 1;
                let nextState = this.history[this.canvasId][
                    this.historyNextIndex[this.canvasId]
                ];
                if (
                    this.historyNextIndex[this.canvasId] ===
                    this.history[this.canvasId].length - 1
                ) {
                    // We need to remove the latest state to avoid a duplicate
                    // after another undoAsyncAction
                    this.history[this.canvasId].pop();
                }
                if (nextState != null) {
                    return [
                        nextState,
                        await this.deserializeAsyncAction.asyncPart(
                            nextState.canvas,
                            {
                                canvasId: this.canvasId,
                                backgrounds: nextState.backgrounds,
                                delegateId: this.delegateId,
                            }
                        ),
                    ];
                } else {
                    return [undefined, null];
                }
            } else {
                return [undefined, null];
            }
        },
        action(
            (
                nextState: SlideState | undefined,
                deserializeData:
                    | [
                          Canvas["canvas"],
                          {
                              canvasId: number | undefined;
                              backgrounds: CanvasBackground[];
                              delegateId?: number;
                          },
                          CanvasBackground[],
                          CanvasBackground[],
                          InnerCanvasChanges,
                          [
                              Array<
                                  [
                                      (
                                          | CanvasTextBox
                                          | CanvasElement
                                          | CanvasSlider
                                      ),
                                      {
                                          new_update_times: number[];
                                          result: {
                                              [key: string]:
                                                  | number
                                                  | string
                                                  | null;
                                          };
                                      } | null,
                                      string | null
                                  ]
                              >,
                              boolean
                          ]
                      ]
                    | null
            ) => {
                if (nextState != null && deserializeData != null) {
                    this.deserializeAsyncAction.actionPart(...deserializeData);
                    this.redoCallback();
                    this.saveChangesAction(
                        nextState.canvas,
                        true,
                        true,
                        true,
                        nextState.backgrounds,
                        BackgroundMode.Replace,
                        true,
                        ({ background_ids }) => {
                            // background ids will change in replace mode, so
                            // we have to update them
                            for (
                                let i = 0;
                                i < this.backgroundsState.length &&
                                i < background_ids.length;
                                ++i
                            ) {
                                this.backgroundsState[i].id = background_ids[i];
                            }
                        }
                    );
                }
            }
        )
    );

    @action.bound
    public clearAllFieldsAction() {
        this.history = {};
        this.historyNextIndex = {};
        this.currentSlideState = {};
        this.isLoadingState = false;
        this.revisionNumber += 1;
        this.connectedBackendTablesState.clear();
        this.connectedUsersState.clear();
        this.canvasTreeState.clear();
        this.canvasAutoIncrementId = 0;
        this.slideHeight = {
            desktop: defaultSlideHeight,
            mobile: defaultMobileSlideHeight,
        };
        this.slideColor = undefined;
        this.mobileViewWasEdited = false;
        this.canvasWasEdited = true;
        this.placementToolbar = undefined;
        this.hidePagesBar = false;
        this.nodeSpreadSheetAutoIncrementId = 0;
        this.canvasUpdateTimeState = 0;
        this.canvasId = undefined;
        this.collapsed = false;
        this.delegateId = undefined;
        this.backgroundsState.clear();
        this.gridsState.clear();
        this.buttonsState.clear();
        this.dashboardsState.clear();
        this.backendTablesState.clear();
        //    this.inputDataElementsState.clear();
        this.mergeDataElementsState.clear();
        this.manageTableElementsState.clear();
        this.aggregateTableElementsState.clear();
        this.questionnaireElementsState.clear();
        this.mapElementsState.clear();
        this.graphElementsState.clear();
        this.embedUrlElementsState.clear();
        this.shapeElementsState.clear();
        this.canvasTreeArgs = {};
        this.canvasTreeRequestErrorsState.clear();
        this.canvasTreeParseErrorsState.clear();
        this.canvasSizeState = this.minCanvasSize();
    }

    @action.bound
    private initDefaultAction(): void {
        this.canvasPageId = undefined;
        this.lastRequestedPageId = undefined;
        this.clearAllFieldsAction();
        this.isLoadingState = true;
    }

    public static nodeForSharing(
        node: CanvasNode
    ): CanvasSharedBoxElement["box"] {
        if (isBox(node)) {
            return {
                canvasType: CanvasType.Box,
                additionalOutputs: node.additionalOutputs,
                shapeOptions: node.shapeOptions,
                statusColor: node.statusColor,
                statusExpressions: node.statusExpressions,
                fontColor: node.fontColor,
                fillColor: node.fillColor,
                fontSize: node.fontSize,
                labelColor: node.labelColor,
                labelSize: node.labelSize,
                outerId: node.outerId,
                name: node.name,
                decimalPoints: node.decimalPoints,
                metric: node.metric,
                value: node.value,
                subtitle: node.subtitle,
                popups: node.popups,
                leftUnit: node.leftUnit,
                unit: node.unit,
                links: node.links,
                borderShadow: node.borderShadow,
                format: node.format,
            } as CanvasSharedBox;
        } else if (isSlider(node)) {
            return {
                canvasType: CanvasType.Slider,
                fontColor: node.fontColor,
                fontSize: node.fontSize,
                outerId: node.outerId,
                metric: node.metric,
                value: node.value,
                minOutput: node.minOutput,
                maxOutput: node.maxOutput,
                vertical: node.vertical,
                shapeOptions: node.shapeOptions,
                stepSize: node.stepSize,
            } as CanvasSharedSlider;
        } else if (isProgressElement(node)) {
            return {
                canvasType: CanvasType.ProgressElement,
                fontColor: node.fontColor,
                fontSize: node.fontSize,
                outerId: node.outerId,
                metric: node.metric,
                value: node.value,
                minOutput: node.minOutput,
                maxOutput: node.maxOutput,
                errorOutput: node.errorOutput,
                shapeOptions: node.shapeOptions,
            } as CanvasSharedProgressElement;
        } else if (isInput(node)) {
            return {
                canvasType: node.canvasType,
                fontColor: node.fontColor,
                fillColor: node.fillColor,
                fontSize: node.fontSize,
                outerId: node.outerId,
                metric: node.metric,
                value: node.value,
                shapeOptions: node.shapeOptions,
                borderShadow: node.borderShadow,
            } as CanvasSharedInput;
        } else if (isDropdownSelector(node)) {
            return {
                canvasType: node.canvasType,
                fontColor: node.fontColor,
                fontSize: node.fontSize,
                outerId: node.outerId,
                metric: node.metric,
                value: node.value,
                shapeOptions: node.shapeOptions,
                dataScopeOption: node.dataScopeOption,
                tableOption: node.tableOption,
                variableOption: node.variableOption,
                additionalOutputs: node.additionalOutputs,
                fillColor: node.fillColor,
                borderShadow: node.borderShadow,
                format: node.format,
            } as CanvasSharedDropdownSelector;
        } else if (isToggle(node)) {
            return {
                canvasType: node.canvasType,
                fontColor: node.fontColor,
                fontSize: node.fontSize,
                outerId: node.outerId,
                metric: node.metric,
                value: node.value,
                shapeOptions: node.shapeOptions,
                hideLabels: node.hideLabels,
            } as CanvasSharedToggle;
        }
        if (isTextBox(node)) {
            return {
                canvasType: CanvasType.TextBox,
                fontColor: node.fontColor,
                fontSize: node.fontSize,
                outerId: node.outerId,
                metric: node.metric,
                value: node.value,
                html: node.html,
                //    raw: node.raw,
                rawMetric: node.rawMetric,
                text: node.text,
                shapeOptions: node.shapeOptions,
                additionalOutputs: node.additionalOutputs,
                format: node.format,
            } as CanvasSharedTextBox;
        } else
            return {
                canvasType: node.canvasType,
                fontColor: node.fontColor,
                fontSize: node.fontSize,
                outerId: node.outerId,
                metric: node.metric,
                value: node.value,
                shapeOptions: node.shapeOptions,
            };
    }

    @action.bound public makeShareableAction(
        nodeId: number,
        share: boolean
    ): void {
        let node: Readonly<CanvasNode> | undefined = this.canvasTreeState.get(
            nodeId
        );
        if (node == null) return;
        //    if (!isBox(node)) return;
        if (share && node.sharedId != null) return;
        if (!share && node.sharedId == null) return;
        if (!share) {
            if (node.sharedId != null) {
                deleteSharedBoxApi(node.sharedId, this.isTemplate)
                    .then(() => {
                        let node = this.canvasTreeState.get(nodeId);
                        if (node == null) return;
                        node.sharedId = null;
                        this.canvasTreeState.set(nodeId, node);
                        this.saveChangesAction({
                            canvasTreeState: {
                                [nodeId]: node,
                            },
                        });
                        SharedBoxesStore.updateSharedBoxes(this.moduleId!);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } else if (this.canvasId != null) {
            addSharedBoxApi(
                this.canvasId,
                CanvasTreeStoreInner.nodeForSharing(node),
                this.isTemplate
            )
                .then((sharedId) => {
                    let node = this.canvasTreeState.get(nodeId);
                    if (node == null) return;
                    node.sharedId = sharedId;
                    this.canvasTreeState.set(nodeId, node);
                    this.saveChangesAction({
                        canvasTreeState: {
                            [nodeId]: node,
                        },
                    });
                    SharedBoxesStore.updateSharedBoxes(this.moduleId!);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    public minCanvasSize() {
        return {
            width:
                this.initialSize.width +
                2 * canvasEditModeMargin +
                sheetRibbonWidth,
            height: this.initialSize.height - 110,
        };
    }

    @action.bound public updateCanvasSizeAction(size: {
        x: number;
        y: number;
        width: number;
        height: number;
    }): void {
        let canvasSizeState = {
            width: Math.min(
                Math.max(size.x + size.width + 50, this.canvasSizeState.width),
                canvasSizeLimit
            ),
            height: Math.min(
                Math.max(
                    size.y + size.height + 50,
                    this.canvasSizeState.height
                ),
                canvasSizeLimit
            ),
        };
        if (
            canvasSizeState.width > this.canvasSizeState.width ||
            canvasSizeState.height > this.canvasSizeState.height
        )
            this.canvasSizeState = canvasSizeState;
    }

    @action.bound private resetCanvasSizeAction() {
        this.canvasSizeState = this.minCanvasSize();
    }

    @action.bound public calculateCanvasSizeAction(): void {
        let minCanvasSize = this.minCanvasSize();
        let width: number = minCanvasSize.width;
        let height: number = Math.max(
            minCanvasSize.height,
            this.slideHeight[this.canvasViewMode] + 50
        );
        for (let grid of this.gridsState.values()) {
            if (isSpreadSheetGrid(grid)) {
                let size =
                    grid.containerSize ??
                    calculateSpreadSheetGridSize(grid, this);
                width = Math.max(
                    width,
                    grid.x + size[this.canvasViewMode].width
                );
                height = Math.max(
                    height,
                    grid.y + size[this.canvasViewMode].height
                );
            }
        }
        for (let canvasNode of this.canvasTreeState.values()) {
            if (
                (!isBox(canvasNode) ||
                    (isBox(canvasNode) && canvasNode.gridId == null)) &&
                !isSimpleSpreadSheetInput(canvasNode)
            ) {
                let size = getNodeSize(canvasNode, this.canvasViewMode)
                    .nodeSize[this.canvasViewMode];
                width = Math.max(
                    width,
                    canvasNode.nodePosition[this.canvasViewMode].x + size.width
                );
                height = Math.max(
                    height,
                    canvasNode.nodePosition[this.canvasViewMode].y + size.height
                );
            }
        }
        const allItems = [
            this.dashboardsState.values(),
            this.buttonsState.values(),
            this.backendTablesState.values(),
            this.questionnaireElementsState.values(),
            this.embedUrlElementsState.values(),
            //    this.inputDataElementsState.values(),
            this.mergeDataElementsState.values(),
            this.manageTableElementsState.values(),
            this.aggregateTableElementsState.values(),
        ];
        for (let items of allItems) {
            for (let item of items) {
                let sizeX =
                    item.nodePosition[this.canvasViewMode].x +
                    item.nodeSize[this.canvasViewMode].width;

                let sizeY =
                    item.nodePosition[this.canvasViewMode].y +
                    item.nodeSize[this.canvasViewMode].height;

                if (!isNaN(sizeX) && !isNaN(sizeY)) {
                    width = Math.max(width, sizeX);
                    height = Math.max(height, sizeY);
                }
            }
        }
        this.canvasSizeState = {
            width: width,
            height: height,
        };
    }

    public zIndex(key: string | number, name: string): number | undefined {
        if (name === "backgroundsState") {
            let background = this.backgroundsState.find((bg) => bg.id === key);
            return background?.z_index;
        } else {
            let objects = this.getObjects();
            let collection = objects.find((object) => object.name === name);
            if (collection != null) {
                let collectionRef = collection.ref;
                let element = collectionRef.get(key);
                return element?.zIndex ?? undefined;
            }
            return undefined;
        }
    }

    public maxCommonRect(): {
        x: number;
        y: number;
        width: number;
        height: number;
    } {
        let rects = this.htmlElements().map((item) =>
            this.getRectByMetadataAction({
                id: item.key,
                type: item.name,
            }).get()
        );
        return getMaxCommonRect(rects);
    }

    public maxCommonRectByMetadataList(
        metadata: ItemMetadata[]
    ): {
        x: number;
        y: number;
        width: number;
        height: number;
    } {
        let rects = metadata.map((item) =>
            this.getRectByMetadataAction(item).get()
        );
        return getMaxCommonRect(rects);
    }

    public maxZIndex(): number {
        let maxZIndex = 0;
        let objects = this.getObjects();
        for (let object of objects) {
            if (object != null) {
                let collectionRef = object.ref;
                for (let item of collectionRef.values()) {
                    maxZIndex = Math.max(
                        maxZIndex,
                        item?.zIndex ?? item?.z_index ?? 50
                    );
                }
            }
        }
        return maxZIndex;
    }

    private zIndexSortFunction(
        a: { key: string | number; name: string },
        b: { key: string | number; name: string }
    ): -1 | 0 | 1 {
        let aZindex = this.zIndex(a.key, a.name) ?? 0;
        let bZindex = this.zIndex(b.key, b.name) ?? 0;
        if (aZindex < bZindex) {
            return -1;
        }
        if (aZindex > bZindex) {
            return 1;
        }
        return 0;
    }
    public moveForward(
        key: string | number,
        name: keyof Canvas["canvas"] | "backgroundsState"
    ) {
        let htmlElements: {
            name: keyof Canvas["canvas"] | "backgroundsState";
            key: string | number;
            zIndex?: number;
        }[] = this.htmlElements();
        htmlElements.sort(this.zIndexSortFunction);
        let oldIndex: number = htmlElements.findIndex(
            (element) => element.name === name && element.key === key
        );
        let newIndex: number = -1;
        for (let index = oldIndex + 1; index < htmlElements.length; ++index) {
            if (
                haveIntersection(
                    this.getRectByMetadataAction({
                        id: htmlElements[index].key,
                        type: htmlElements[index].name,
                    }).get(),
                    this.getRectByMetadataAction({
                        id: key,
                        type: name,
                    }).get()
                )
            ) {
                newIndex = index;
                break;
            }
        }
        // If the element does not intersect with any other, then do nothing
        if (newIndex === -1) return;
        htmlElements.splice(oldIndex, 1);
        // We don't need to add 1 to newIndex here since we remove the old element
        htmlElements.splice(newIndex, 0, {
            name: name,
            key: key,
        });
        for (let index = 0; index < htmlElements.length; ++index) {
            htmlElements[index].zIndex = index;
        }
        this.updateOrder(
            htmlElements as {
                name: CanvasElementType;
                key: string | number;
                zIndex: number;
            }[]
        );
    }

    public moveFront(
        key: string | number,
        name: keyof Canvas["canvas"] | "backgroundsState"
    ) {
        let htmlElements: {
            name: keyof Canvas["canvas"] | "backgroundsState";
            key: string | number;
            zIndex?: number;
        }[] = this.htmlElements();
        htmlElements.sort(this.zIndexSortFunction);
        let oldIndex: number = htmlElements.findIndex(
            (element) => element.name === name && element.key === key
        );
        htmlElements.splice(oldIndex, 1);
        htmlElements.push({
            name: name,
            key: key,
        });
        for (let index = 0; index < htmlElements.length; ++index) {
            htmlElements[index].zIndex = index;
        }
        this.updateOrder(
            htmlElements as {
                name: CanvasElementType;
                key: string | number;
                zIndex: number;
            }[]
        );
    }

    public moveBack(
        key: string | number,
        name: keyof Canvas["canvas"] | "backgroundsState"
    ) {
        let htmlElements: {
            name: keyof Canvas["canvas"] | "backgroundsState";
            key: string | number;
            zIndex?: number;
        }[] = this.htmlElements();
        htmlElements.sort(this.zIndexSortFunction);
        let oldIndex: number = htmlElements.findIndex(
            (element) => element.name === name && element.key === key
        );
        htmlElements.splice(oldIndex, 1);
        htmlElements.unshift({
            name: name,
            key: key,
        });
        for (let index = 0; index < htmlElements.length; ++index) {
            htmlElements[index].zIndex = index;
        }
        this.updateOrder(
            htmlElements as {
                name: CanvasElementType;
                key: string | number;
                zIndex: number;
            }[]
        );
    }

    public moveBackward(
        key: string | number,
        name: keyof Canvas["canvas"] | "backgroundsState"
    ) {
        let htmlElements: {
            name: keyof Canvas["canvas"] | "backgroundsState";
            key: string | number;
            zIndex?: number;
        }[] = this.htmlElements();
        htmlElements.sort(this.zIndexSortFunction);
        let oldIndex: number = htmlElements.findIndex(
            (element) => element.name === name && element.key === key
        );
        let newIndex: number = -1;
        for (let index = oldIndex - 1; index >= 0; --index) {
            if (
                haveIntersection(
                    this.getRectByMetadataAction({
                        id: htmlElements[index].key,
                        type: htmlElements[index].name,
                    }).get(),
                    this.getRectByMetadataAction({
                        id: key,
                        type: name,
                    }).get()
                )
            ) {
                newIndex = index;
                break;
            }
        }
        // If the element does not intersect with any other, then do nothing
        if (newIndex === -1) return;
        htmlElements.splice(oldIndex, 1);
        htmlElements.splice(newIndex, 0, {
            name: name,
            key: key,
        });
        for (let index = 0; index < htmlElements.length; ++index) {
            htmlElements[index].zIndex = index;
        }
        this.updateOrder(
            htmlElements as {
                name: CanvasElementType;
                key: string | number;
                zIndex: number;
            }[]
        );
    }

    private updateOrder(
        elements: {
            key: string | number;
            name: CanvasElementType;
            zIndex: number;
        }[]
    ) {
        let objects = this.getObjects();
        let changes: InnerCanvasChanges = {};
        let backgroundChanges: CanvasBackground[] = [];
        for (let element of elements) {
            if (element.name === "backgroundsState") {
                let index = this.backgroundsState.findIndex(
                    (bg) => bg.id === element.key
                );
                if (index >= 0) {
                    this.backgroundsState[index] = {
                        ...this.backgroundsState[index],
                        z_index: element.zIndex,
                    };
                    backgroundChanges.push(this.backgroundsState[index]);
                }
            } else {
                let collection = objects.find(
                    (object) => object.name === element.name
                );
                if (collection != null) {
                    let collectionRef = collection.ref;
                    let object = collectionRef.get(element.key);
                    if (object == null) continue;
                    collectionRef.set(element.key, {
                        ...object,
                        zIndex: element.zIndex,
                    });

                    if (changes[element.name] == null) {
                        changes[element.name] = {};
                    }

                    changes[element.name] = {
                        ...changes[element.name],
                        [element.key]: {
                            ...object,
                            zIndex: element.zIndex,
                        },
                    };
                }
            }
        }
        this.saveChangesAction(
            changes,
            true,
            true,
            false,
            backgroundChanges,
            BackgroundMode.Update
        );
    }

    private htmlElements(): {
        name: keyof Canvas["canvas"] | "backgroundsState";
        key: string | number;
    }[] {
        let htmlElements: {
            name: keyof Canvas["canvas"] | "backgroundsState";
            key: string | number;
        }[] = [];
        for (let node of this.canvasTreeState.values()) {
            htmlElements.push({
                name: "canvasTreeState",
                key: node.id,
            });
        }
        for (let background of this.backgroundsState.values()) {
            htmlElements.push({
                name: "backgroundsState",
                key: background.id,
            });
        }
        for (let spreadSheetGrid of this.gridsState.values()) {
            if (isSpreadSheetGrid(spreadSheetGrid)) {
                htmlElements.push({
                    name: "gridsState",
                    key: spreadSheetGrid.id,
                });
            }
        }
        for (let buttonId of this.buttonsState.keys()) {
            htmlElements.push({
                name: "buttonsState",
                key: buttonId,
            });
        }
        const restItems: (keyof Canvas["canvas"])[] = [
            "dashboardsState",
            "backendTablesState",
            "questionnaireElementsState",
            "mapElementsState",
            "graphElementsState",
            "embedUrlElementsState",
            //    "inputDataElementsState",
            "mergeDataElementsState",
            "manageTableElementsState",
            "aggregateTableElementsState",
            "shapeElementsState",
        ];
        for (let itemsName of restItems) {
            for (let key of (this[itemsName] as ObservableMap<
                string,
                any
            >).keys()) {
                htmlElements.push({
                    name: itemsName,
                    key: key,
                });
            }
        }
        return htmlElements;
    }

    public canvasSize(
        scale: number
    ): IComputedValue<{ width: number; height: number }> {
        return computed(() => {
            let minSize = this.minCanvasSize();
            const canvasWidth = this.canvasSizeState.width || minSize.width;
            const canvasHeight = this.canvasSizeState.height || minSize.height;
            return {
                width: Math.max(canvasWidth * scale, minSize.width),
                height: Math.max(canvasHeight * scale, minSize.height),
            };
        });
    }

    public slideRect(): {
        width: number;
        height: number;
        x: number;
        y: number;
    } {
        let slideHeight = this.slideHeight[this.canvasViewMode];
        let slideWidth = this.slideWidth[this.canvasViewMode];
        let slideRect = {
            x: 0,
            y: 0,
            width: slideWidth,
            height: slideHeight,
        };
        return slideRect;
    }

    public verticalPageBarOffset() {}

    public layerRect(
        scale: number,
        pages?: {
            id: string | number;
        }[],
        initialPageBarInfo?: PageBarInfo
    ): IComputedValue<{ width: number; height: number; x: number; y: number }> {
        return computed(() => {
            let defaultWidth = defaultSlideWidth;
            if (this.canvasViewMode === "mobile")
                defaultWidth = defaultMobileSlideWidth;
            let center =
                (this.initialSize.width -
                    defaultWidth * scale -
                    (this.live ? 0 : sheetRibbonWidth)) /
                2;
            let x = Math.max(center / scale, 0);
            let padding = this.live ? 0 : verticalSectionBarItemSizePadding;
            let offset = 0;
            let pageBarInfo =
                initialPageBarInfo ??
                CurrentModulesStore.getModule(this.moduleId!)?.page_bar_info;
            let accordionFormat =
                this.canvasViewMode === "mobile"
                    ? pageBarInfo?.accordionFormat?.mobile
                    : pageBarInfo?.accordionFormat?.desktop;
            if (
                pageBarInfo != null &&
                pageBarInfo.show &&
                !pageBarInfo.hiddenOnPage &&
                accordionFormat &&
                !this.hidePagesBar &&
                pages
            ) {
                let currentPageIndex = pages.findIndex(
                    (item) => item.id === this.canvasPageId
                );
                if (currentPageIndex >= 0) {
                    offset =
                        (currentPageIndex + 1) * verticalSectionBarItemSize +
                        2 * padding;
                }
            }

            let slideRect = {
                x: x,
                y: offset,
                width: this.slideWidth[this.canvasViewMode],
                height: this.slideHeight[this.canvasViewMode] + 1,
            };
            return slideRect;
        });
    }

    public calculateFitScale(
        live: boolean,
        ribbonIsOpen: boolean,
        asSlideShow: boolean = false
    ) {
        let slideRect = this.slideRect();
        let scaleFirst =
            (this.initialSize.width -
                (!live && ribbonIsOpen
                    ? sheetRibbonWidth + 2 * canvasEditModeMargin
                    : !live
                    ? 2 * canvasEditModeMargin
                    : live && ribbonIsOpen
                    ? sheetRibbonWidth
                    : 0)) /
            slideRect.width;
        if (live && !asSlideShow) {
            this.scale = scaleFirst;

            return;
        }
        let scaleSecond = live
            ? this.initialSize.height / (slideRect.height + 5)
            : (this.initialSize.height -
                  headerBarHeight -
                  desktopMobileButtonHeight -
                  20) /
              defaultSlideHeight;
        this.scale = Math.min(scaleFirst, scaleSecond);
    }

    public separateMobileAndDesktopViewPositioning() {
        if (this.currentSlideState) {
            this.mobileViewWasEdited = true;
            this.saveChangesAction({
                mobileViewWasEdited: this.mobileViewWasEdited,
            });
        }
    }

    public setCanvasWasEditedSate(wasEdited: boolean) {
        this.canvasWasEdited = wasEdited;
    }

    public updatePlacementToolbar(data: PlacementToolbar) {
        this.placementToolbar = {
            ...this.placementToolbar,
            ...data,
        };
    }

    public joinCanvasRoom(
        enter?: number,
        leave?: number,
        enterTemplate?: boolean,
        leaveTemplate?: boolean
    ) {
        if (leave != null) {
            SocketIOInstance?.emit("module_user_shared_canvas_leave", {
                room: leave,
                template: leaveTemplate,
            });
        }
        if (enter != null) {
            SocketIOInstance?.emit("module_user_shared_canvas_join", {
                room: enter,
                template: enterTemplate,
            });
        }
    }

    public joinPageRoom(
        enter?: string | number,
        leave?: string | number
    ): void {
        if (leave != null && leave !== enter) {
            SocketIOInstance?.emit("module_user_shared_page_leave", {
                room: leave,
            });
        }
        if (enter != null) {
            SocketIOInstance?.emit("module_user_shared_page_join", {
                room: enter,
            });
        }
    }

    public restoreFromPageAsyncAction = asyncAction(
        async (
            pageId: number | undefined,
            onRestore: () => void,
            live: boolean = false,
            canvasId: number | undefined = undefined
        ): Promise<
            [
                number | undefined,
                Canvas | undefined,
                () => void,
                (
                    | [
                          Canvas["canvas"],
                          {
                              canvasId: number | undefined;
                              backgrounds: CanvasBackground[];
                              delegateId?: number;
                          },
                          CanvasBackground[],
                          CanvasBackground[],
                          InnerCanvasChanges,
                          [
                              Array<
                                  [
                                      (
                                          | CanvasTextBox
                                          | CanvasElement
                                          | CanvasSlider
                                      ),
                                      {
                                          new_update_times: number[];
                                          result: {
                                              [key: string]:
                                                  | number
                                                  | string
                                                  | null;
                                          };
                                      } | null,
                                      string | null
                                  ]
                              >,
                              boolean
                          ]
                      ]
                    | null
                )
            ]
        > => {
            let canvas: Canvas | undefined = undefined;
            if (pageId != null) {
                if (live) {
                    try {
                        if (canvasId != null) {
                            canvas = await getCanvasApi(canvasId);
                        } else {
                            canvas = (
                                await getCanvasesApi(
                                    undefined,
                                    pageId,
                                    0,
                                    1,
                                    false
                                )
                            )[0];
                        }
                    } catch (error) {
                        console.log(String(error));
                    }
                } else {
                    if (canvasId != null) {
                        try {
                            canvas = await getCanvasApi(canvasId);
                        } catch (error) {
                            console.log(String(error));
                        }
                    } else {
                        try {
                            canvas = await getLastAccessedCanvasApi(pageId);
                        } catch (error) {
                            console.log(String(error));
                            try {
                                canvas = (
                                    await getCanvasesApi(
                                        undefined,
                                        pageId,
                                        0,
                                        1,
                                        false
                                    )
                                )[0];
                            } catch (error) {
                                console.log(String(error));
                            }
                        }
                    }
                }
            }
            let deserializeData:
                | [
                      Canvas["canvas"],
                      {
                          canvasId: number | undefined;
                          backgrounds: CanvasBackground[];
                          delegateId?: number;
                      },
                      CanvasBackground[],
                      CanvasBackground[],
                      InnerCanvasChanges,
                      [
                          Array<
                              [
                                  CanvasTextBox | CanvasElement | CanvasSlider,
                                  {
                                      new_update_times: number[];
                                      result: {
                                          [key: string]: number | string | null;
                                      };
                                  } | null,
                                  string | null
                              ]
                          >,
                          boolean
                      ]
                  ]
                | null = null;
            if (canvas != null) {
                deserializeData = await this.deserializeAsyncAction.asyncPart(
                    canvas.canvas,
                    {
                        canvasId: canvas.id,
                        delegateId: canvas.delegate_id,
                        backgrounds: canvas.backgrounds,
                    }
                );
            }
            return [pageId, canvas, onRestore, deserializeData];
        },
        action(
            (
                pageId: number | undefined,
                canvas: Canvas | undefined,
                onRestore: () => void,
                deserializeData:
                    | [
                          Canvas["canvas"],
                          {
                              canvasId: number | undefined;
                              backgrounds: CanvasBackground[];
                              delegateId?: number;
                          },
                          CanvasBackground[],
                          CanvasBackground[],
                          InnerCanvasChanges,
                          [
                              Array<
                                  [
                                      (
                                          | CanvasTextBox
                                          | CanvasElement
                                          | CanvasSlider
                                      ),
                                      {
                                          new_update_times: number[];
                                          result: {
                                              [key: string]:
                                                  | number
                                                  | string
                                                  | null;
                                          };
                                      } | null,
                                      string | null
                                  ]
                              >,
                              boolean
                          ]
                      ]
                    | null
            ) => {
                this.initDefaultAction();
                if (pageId == null) {
                    this.joinPageRoom(undefined, this.canvasPageId);
                    this.canvasPageId = pageId;
                    onRestore();
                    return;
                }
                this.lastRequestedPageId = pageId;
                this.isLoadingState = true;
                if (pageId !== this.lastRequestedPageId) {
                    return;
                }
                if (canvas != null) {
                    this.joinPageRoom(pageId, this.canvasPageId);
                    this.canvasPageId = pageId;
                    this.deserializeAsyncAction.actionPart(...deserializeData!);
                    onRestore();
                } else {
                    this.joinPageRoom(pageId, this.canvasPageId);
                    this.canvasPageId = pageId;
                    this.clearAllFieldsAction();
                    onRestore();
                }
            }
        )
    );

    public deserializeAsyncAction = asyncAction(
        async (
            canvas: Canvas["canvas"],
            canvasOptions: {
                canvasId: number | undefined;
                backgrounds: CanvasBackground[];
                delegateId?: number;
                isTemplate?: boolean;
            }
        ): Promise<
            [
                Canvas["canvas"],
                {
                    canvasId: number | undefined;
                    backgrounds: CanvasBackground[];
                    delegateId?: number;
                },
                CanvasBackground[],
                CanvasBackground[],
                InnerCanvasChanges,
                [
                    Array<
                        [
                            CanvasTextBox | CanvasElement | CanvasSlider,
                            {
                                new_update_times: number[];
                                result: {
                                    [key: string]: number | string | null;
                                };
                            } | null,
                            string | null
                        ]
                    >,
                    boolean
                ]
            ]
        > => {
            let backgroundsState = canvasOptions.backgrounds ?? [];
            // migrate backgrounds
            let backgroundChanges = await this.migrateBackgrounds(
                backgroundsState
            );
            let migrationData = this.migrateCanvas(canvas);
            canvas = migrationData.canvas;
            let updateAllData = await this.updateAllAsyncAction.asyncPart(
                false,
                new Map(
                    Object.entries(
                        canvas.canvasTreeState ?? {}
                    ).map(([key, value]) => [Number(key), value])
                )
            );
            return [
                canvas,
                canvasOptions,
                backgroundsState,
                backgroundChanges,
                migrationData.changes,
                updateAllData,
            ];
        },
        action(
            (
                canvas: Canvas["canvas"],
                canvasOptions: {
                    canvasId: number | undefined;
                    backgrounds: CanvasBackground[];
                    delegateId?: number;
                    isTemplate?: boolean;
                },
                backgroundsState: CanvasBackground[],
                backgroundChanges: CanvasBackground[],
                canvasChanges: InnerCanvasChanges,
                updateAllData: [
                    Array<
                        [
                            CanvasTextBox | CanvasElement | CanvasSlider,
                            {
                                new_update_times: number[];
                                result: {
                                    [key: string]: number | string | null;
                                };
                            } | null,
                            string | null
                        ]
                    >,
                    boolean
                ]
            ) => {
                this.canvasTreeParseErrorsState.clear();
                this.canvasTreeRequestErrorsState.clear();
                this.connectedBackendTablesState.clear();
                this.connectedUsersState.clear();
                this.canvasUpdateTimeState = canvas.canvasUpdateTimeState || 0;
                this.nodeSpreadSheetAutoIncrementId =
                    canvas.nodeSpreadSheetAutoIncrementId ?? 0;
                this.canvasAutoIncrementId = canvas.canvasAutoIncrementId;
                this.slideHeight = canvas.slideHeight!;
                this.slideColor = canvas.slideColor;
                this.mobileViewWasEdited = canvas.mobileViewWasEdited;
                this.hidePagesBar = canvas.hidePagesBar ?? false;
                this.canvasTreeState.replace(
                    Object.entries(
                        canvas.canvasTreeState ?? {}
                    ).map(([key, value]) => [Number(key), value])
                );
                this.backendTablesState.replace(
                    canvas.backendTablesState ?? {}
                );
                this.gridsState.replace(canvas.gridsState ?? {});
                this.questionnaireElementsState.replace(
                    canvas.questionnaireElementsState ?? {}
                );
                this.mapElementsState.replace(canvas.mapElementsState ?? {});
                this.graphElementsState.replace(
                    canvas.graphElementsState ?? {}
                );
                this.embedUrlElementsState.replace(
                    canvas.embedUrlElementsState ?? {}
                );
                this.shapeElementsState.replace(
                    canvas.shapeElementsState ?? {}
                );
                // this.inputDataElementsState.replace(
                //     canvas.inputDataElementsState ?? {}
                // );
                this.buttonsState.replace(canvas.buttonsState ?? {});
                this.mergeDataElementsState.replace(
                    canvas.mergeDataElementsState ?? {}
                );
                this.manageTableElementsState.replace(
                    canvas.manageTableElementsState ?? {}
                );
                this.aggregateTableElementsState.replace(
                    canvas.aggregateTableElementsState ?? {}
                );
                if (canvasOptions.isTemplate) {
                    if (typeof this.canvasPageId === "number")
                        this.joinPageRoom(undefined, this.canvasPageId);
                    this.canvasPageId = SPECIAL_TEMPLATE_PAGE;
                }
                if (
                    !this.temporary &&
                    (this.canvasId !== canvasOptions.canvasId ||
                        this.isTemplate !== canvasOptions.isTemplate)
                ) {
                    this.joinCanvasRoom(
                        canvasOptions.canvasId,
                        this.canvasId,
                        canvasOptions.isTemplate,
                        this.isTemplate
                    );
                }
                this.isTemplate = canvasOptions.isTemplate ?? false;
                this.canvasId = canvasOptions.canvasId;
                this.collapsed = false;
                this.delegateId = canvasOptions.delegateId;
                if (backgroundChanges.length !== 0) {
                    this.saveChangesAction(
                        {},
                        false,
                        false,
                        false,
                        backgroundChanges,
                        BackgroundMode.Update
                    );
                }
                if (!_.isEmpty(canvasChanges)) {
                    this.saveChangesAction(canvasChanges);
                }
                this.backgroundsState.replace(backgroundsState);

                this.dashboardsState.replace(canvas.dashboardsState ?? {});
                this.updateAllAsyncAction.actionPart(...updateAllData);
                this.revisionNumber += 1;
                this.currentSlideState[this.canvasId!] = {
                    canvas: this.serialize(),
                    backgrounds: this.backgroundsState.toJSON(),
                };
            }
        )
    );

    private static migrateTagsStateToTextBoxNodes(
        canvas: InnerCanvas,
        canvasLegacyTagElements: CanvasLegacyTagElement[]
    ) {
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
            tagsState: null,
        };
        for (let canvasTagElement of canvasLegacyTagElements) {
            canvas.canvasAutoIncrementId += 1;
            canvas.nodeSpreadSheetAutoIncrementId =
                (canvas.nodeSpreadSheetAutoIncrementId ?? 0) + 1;
            let canvasTextBox: CanvasTextBox = {
                ...canvasTagElement,
                popups: [],
                additionalOutputs: [],
                links: [],
                canvasType: CanvasType.TextBox,
                metric: "",
                value: NaN,
                childrenIds: [],
                arrowTexts: [],
                childrenSharedIds: [],
                parentIds: [],
                id: canvas.canvasAutoIncrementId,
                outerId: outerNodeId(canvas.nodeSpreadSheetAutoIncrementId),
            };
            changes.canvasTreeState![canvasTextBox.id] = canvasTextBox;
            canvas.canvasTreeState[canvasTextBox.id] = canvasTextBox;
        }
        changes.canvasAutoIncrementId = canvas.canvasAutoIncrementId;
        changes.nodeSpreadSheetAutoIncrementId =
            canvas.nodeSpreadSheetAutoIncrementId;
        return changes;
    }

    private static migrateDashboardsStateArrayToMap(
        canvas: InnerCanvas,
        dashboardsState: CanvasDashboard[]
    ) {
        let changes: InnerCanvasChanges = {
            dashboardsState: {},
        };
        let viewModes: CanvasViewMode[] = ["desktop", "mobile"];
        for (let dashboard of dashboardsState) {
            let dashboardId = nanoid();
            for (let viewMode of viewModes) {
                dashboard.nodeSize[viewMode].width =
                    dashboard.nodeSize[viewMode].width ?? 400;
                dashboard.nodeSize[viewMode].height =
                    dashboard.nodeSize[viewMode].height ?? 200;
            }

            changes.dashboardsState![dashboardId] = dashboard;
        }
        canvas.dashboardsState = changes.dashboardsState as {
            [key: number]: CanvasDashboard;
        };
        return changes;
    }

    private migrateCanvas(canvas: InnerCanvas) {
        canvas.slideHeight = backwardCompatibilityForSlideHeight(
            canvas.slideHeight
        );
        canvas = addBackwardCompatibilityForAllCanvasItems(canvas);

        // migrate nodes
        Object.keys(canvas.canvasTreeState ?? {}).forEach((strId: string) => {
            const id: number = parseInt(strId);
            let node: CanvasNode = canvas.canvasTreeState[id];
            CanvasTreeStoreInner.migrateNode(node);
        });
        // migrate questionnaires
        if (canvas.questionnaireElementsState != null) {
            Object.keys(canvas.questionnaireElementsState).forEach(
                (strId: string) => {
                    let q = canvas.questionnaireElementsState![strId];
                    let templateQuestion = q.questions.find((question) =>
                        isTemplateSheetQuestion(question)
                    );
                    if (
                        templateQuestion != null &&
                        templateQuestion.liveQuestion == null
                    ) {
                        templateQuestion.liveQuestion =
                            "Enter title for the new slide";
                    }
                }
            );
        }

        let tagsState: CanvasLegacyTagElement[] | null = (canvas as any)
            .tagsState;
        let changes: InnerCanvasChanges = {};
        if (tagsState != null && tagsState.length > 0) {
            changes = {
                ...changes,
                ...CanvasTreeStoreInner.migrateTagsStateToTextBoxNodes(
                    canvas,
                    tagsState
                ),
            };
        }
        if (Array.isArray(canvas.dashboardsState)) {
            changes = {
                ...changes,
                ...CanvasTreeStoreInner.migrateDashboardsStateArrayToMap(
                    canvas,
                    canvas.dashboardsState
                ),
            };
        }
        clearCanvasCalculatedValues(canvas);
        return { canvas: canvas, changes: changes };
    }

    private static migrateNode(node: CanvasNode) {
        if (isSubmitButton(node)) {
            if (node.links == null) {
                node.links = [];
            }
            if (node.backendOutput == null) {
                node.backendOutput = {
                    dataScopeOption: null,
                    tableOption: null,
                    variableOptions: [
                        {
                            node: null,
                            variable: null,
                        },
                    ],
                };
            } else {
                // migration from isGlobal to type
                for (let variableOption of node.backendOutput.variableOptions) {
                    if (
                        variableOption.node != null &&
                        variableOption.node.type == null
                    ) {
                        variableOption.node.type = variableOption.node.isGlobal
                            ? SubmitType.Global
                            : SubmitType.Regular;
                    }
                }
            }
        }
        if (isBox(node)) {
            if (!node.outerId) node.outerId = "";
            if (!node.childrenSharedIds) node.childrenSharedIds = [];
            if (!node.additionalOutputs) node.additionalOutputs = [];
        } else if (isTextBox(node)) {
            if (!node.popups) node.popups = [];
            if (!node.links) node.links = [];
            if (!node.additionalOutputs) node.additionalOutputs = [];
        }
        // migrate sliders
        else if (isSlider(node)) {
            if (node.min != null) {
                node.minOutput = {
                    metric: node.min.toString(),
                    value: node.min,
                    unit: "",
                };
                delete node.min;
            }
            if (node.max != null) {
                node.maxOutput = {
                    metric: node.max.toString(),
                    value: node.max,
                    unit: "",
                };
                delete node.max;
            }
        } else if (isProgressElement(node) && node.errorOutput == null) {
            node.errorOutput = {
                metric: "0",
                value: 0,
                unit: "",
            };
        }
        if (isDropdownSelector(node)) {
            if (node.multipleSelection == null) {
                node.multipleSelection = node.additionalOutputs != null;
            }
            if (node.additionalOutputs == null) node.additionalOutputs = [];
        }
        if (
            node.childrenSharedIds.length > 0 &&
            typeof node.childrenSharedIds[0] === "number"
        ) {
            node.childrenSharedIds = node.childrenSharedIds.map((id) => ({
                label: "",
                value: (id as unknown) as number,
            }));
        }
        if (
            node.globalInputIds != null &&
            node.globalInputIds.length > 0 &&
            typeof node.globalInputIds[0] === "number"
        ) {
            node.globalInputIds = node.globalInputIds.map((id) => ({
                label: GlobalInputsMap[(id as unknown) as GlobalInputType],
                value: (id as unknown) as GlobalInputType,
            }));
        }
        if (
            node.parentIds != null &&
            node.parentIds.length > 0 &&
            typeof node.parentIds[0] === "number"
        ) {
            node.parentIds = node.parentIds.map((id) => ({
                id: (id as unknown) as number,
                parentPosition: ArrowPosition.Bottom,
                childPosition: ArrowPosition.Left,
            }));
        }
    }

    private async migrateBackgrounds(
        backgrounds: CanvasBackground[]
    ): Promise<CanvasBackground[]> {
        let changes: CanvasBackground[] = [];
        for (let img of backgrounds) {
            if (img.natural_width == null || img.natural_height == null) {
                let size = await getImageNaturalSize(img.image_url);
                img.natural_width = size.naturalWidth;
                img.natural_height = size.naturalHeight;
                changes.push(img);
            }
        }
        return changes;
    }

    @action.bound
    private deserializeSharedInnerAction(canvas: Canvas["canvas"]) {
        this.canvasTreeParseErrorsState.clear();
        this.canvasTreeRequestErrorsState.clear();
        this.connectedBackendTablesState.clear();
        this.connectedUsersState.clear();
        this.canvasUpdateTimeState = canvas.canvasUpdateTimeState || 0;
        this.canvasAutoIncrementId = canvas.canvasAutoIncrementId;
        this.nodeSpreadSheetAutoIncrementId =
            canvas.nodeSpreadSheetAutoIncrementId ?? 0;
        this.slideHeight = canvas.slideHeight!;
        this.slideColor = canvas.slideColor;
        this.mobileViewWasEdited = canvas.mobileViewWasEdited;
        this.hidePagesBar = canvas.hidePagesBar ?? false;
        this.canvasTreeState.replace(
            Object.entries(canvas.canvasTreeState ?? {}).map(([key, value]) => [
                Number(key),
                value,
            ])
        );
        this.backendTablesState.replace(canvas.backendTablesState ?? {});
        this.buttonsState.replace(canvas.buttonsState ?? {});
        this.gridsState.replace(canvas.gridsState ?? {});
        this.questionnaireElementsState.replace(
            canvas.questionnaireElementsState ?? {}
        );
        this.questionnaireElementsState.replace(
            canvas.questionnaireElementsState ?? {}
        );
        this.mapElementsState.replace(canvas.mapElementsState ?? {});
        this.graphElementsState.replace(canvas.graphElementsState ?? {});
        this.embedUrlElementsState.replace(canvas.embedUrlElementsState ?? {});
        this.shapeElementsState.replace(canvas.shapeElementsState ?? {});
        // this.inputDataElementsState.replace(
        //     canvas.inputDataElementsState ?? {}
        // );
        this.mergeDataElementsState.replace(
            canvas.mergeDataElementsState ?? {}
        );
        this.manageTableElementsState.replace(
            canvas.manageTableElementsState ?? {}
        );
        this.aggregateTableElementsState.replace(
            canvas.aggregateTableElementsState ?? {}
        );
        this.dashboardsState.replace(canvas.dashboardsState ?? {});
    }

    public deserializeSharedAsyncAction = asyncAction(
        async (
            canvas: SharedCanvas
        ): Promise<[SharedCanvas, CanvasBackground[], CanvasBackground[]]> => {
            let backgroundsState = canvas.backgroundsState ?? [];
            // migrate backgrounds
            let backgroundChanges = await this.migrateBackgrounds(
                backgroundsState
            );
            canvas = this.migrateCanvas(canvas).canvas as SharedCanvas;
            return [canvas, backgroundsState, backgroundChanges];
        },
        action(
            (
                canvas: SharedCanvas,
                backgroundsState: CanvasBackground[],
                backgroundChanges: CanvasBackground[]
            ) => {
                this.shared = true;
                this.deserializeSharedInnerAction(canvas);
                if (backgroundChanges.length !== 0) {
                    this.saveChangesAction(
                        {},
                        false,
                        false,
                        false,
                        backgroundChanges,
                        BackgroundMode.Update
                    );
                }
                this.backgroundsState.replace(backgroundsState);
                this.connectedBackendTablesState.replace(
                    canvas.connectedBackendTablesState ?? []
                );
                this.calculateCanvasSizeAction();
            }
        )
    );

    public deserializeFromSharedModuleAsyncAction = asyncAction(
        async (
            canvas: SharedModuleCanvas,
            fullModule: SharedModule,
            requireAuthentication: boolean
        ): Promise<
            [
                SharedModuleCanvas,
                SharedModule,
                boolean,
                CanvasBackground[],
                CanvasBackground[],
                [
                    Array<
                        [
                            CanvasTextBox | CanvasElement | CanvasSlider,
                            {
                                new_update_times: number[];
                                result: {
                                    [key: string]: number | string | null;
                                };
                            } | null,
                            string | null
                        ]
                    >
                ]
            ]
        > => {
            let backgroundsState = canvas.backgrounds ?? [];
            // migrate backgrounds
            let backgroundChanges = await this.migrateBackgrounds(
                backgroundsState
            );
            canvas.canvas = this.migrateCanvas(canvas.canvas)
                .canvas as SharedCanvas;

            let updateArgsData = await this.updateArgsAsyncAction.asyncPart(
                true,
                new Map(
                    Object.entries(
                        canvas.canvas.canvasTreeState ?? {}
                    ).map(([key, value]) => [Number(key), value])
                )
            );
            return [
                canvas,
                fullModule,
                requireAuthentication,
                backgroundsState,
                backgroundChanges,
                [updateArgsData[0]],
            ];
        },
        action(
            (
                canvas: SharedModuleCanvas,
                fullModule: SharedModule,
                requireAuthentication: boolean,
                backgroundsState: CanvasBackground[],
                backgroundChanges: CanvasBackground[],
                updateArgsData: [
                    Array<
                        [
                            CanvasTextBox | CanvasElement | CanvasSlider,
                            {
                                new_update_times: number[];
                                result: {
                                    [key: string]: number | string | null;
                                };
                            } | null,
                            string | null
                        ]
                    >
                ]
            ) => {
                this.shared = true;
                this.deserializeSharedInnerAction(canvas.canvas);
                let allSheets: { [key: string]: SharedModuleCanvas } = {};
                [...fullModule.pages.values()]
                    .map((item) => item.sheets)
                    .flat()
                    .forEach((sheet) => {
                        allSheets[sheet.id] = sheet;
                    });
                let allSharedBoxes: SharedBoxOption[] = [];
                for (let sheet of Object.values(allSheets)) {
                    for (let fullSharedBox of sheet.shared_boxes) {
                        allSharedBoxes.push({
                            label: "",
                            value: fullSharedBox.id,
                            item: {
                                ...fullSharedBox,
                                box: migrateSharedBox(fullSharedBox.box),
                                page_id: 0,
                                page_title: "",
                                canvas_title: "",
                                canvas_id: 0,
                            },
                            canvasId: 0,
                            outerId: fullSharedBox.box.outerId,
                        });
                    }
                }

                SharedBoxesStore.assignSharedBoxes(allSharedBoxes);
                this.updateNodesByLinkedSharedInputs();
                // this.connectedFullSharedBoxesState.clear();

                // }
                if (backgroundChanges.length !== 0) {
                    this.saveChangesAction(
                        {},
                        false,
                        false,
                        false,
                        backgroundChanges,
                        BackgroundMode.Update
                    );
                }
                this.backgroundsState.replace(backgroundsState);
                this.updateArgsAsyncAction.actionPart(
                    ...updateArgsData,
                    undefined
                );
                this.calculateValuesAction();
                this.updateNodesByLinkedInputsAction(
                    {
                        canvasTreeState: Object.fromEntries(
                            this.canvasTreeState
                        ),
                    },
                    true
                );
                this.updateLinkedData();
                this.revisionNumber += 1;
                this.collapsed = false;
                this.calculateCanvasSizeAction();
            }
        )
    );

    @action.bound public saveChangesAction(
        changes: InnerCanvasChanges,
        resetSynchronized: boolean = true,
        updateTime: boolean = true,
        forceReplace: boolean = false,
        backgrounds: CanvasBackground[] = [],
        backgroundMode: BackgroundMode = BackgroundMode.Ignore,
        skipHistory: boolean = false,
        onFinished: (response: {
            id: number;
            background_ids: number[];
        }) => void = () => {}
    ): void {
        let canvasId = this.canvasId;
        let pageId = this.canvasPageId;
        let isTemplate = this.isTemplate;
        let sharedSlide = this.shared
            ? {
                  canvas: this.serialize(),
                  backgrounds: this.backgroundsState.toJSON(),
              }
            : undefined;
        let time = updateTime ? new Date().getTime() / 1000 : undefined;
        let fn = async () => {
            try {
                if (changes.canvasTreeState != null)
                    await this.sendNotifications(
                        changes.canvasTreeState,
                        canvasId
                    );
                let result = await this.saveChangesInnerAction(
                    changes,
                    resetSynchronized,
                    forceReplace,
                    backgrounds,
                    backgroundMode,
                    pageId as number,
                    canvasId,
                    isTemplate,
                    time,
                    sharedSlide
                );

                if (canvasId !== this.canvasId) return;
                if (result.success)
                    onFinished(
                        result as {
                            id: number;
                            background_ids: number[];
                        }
                    );
                else {
                    this.onError(result.error_msg!);
                    let prohibitedItems = result.prohibited_items;
                    if (prohibitedItems != null) {
                        let objects = this.getObjects();
                        for (let objectName of Object.keys(prohibitedItems)) {
                            let collection = objects.find(
                                (object) => object.name === objectName
                            );
                            if (collection != null) {
                                let collectionRef = collection.ref;

                                for (let deletedId of prohibitedItems[
                                    objectName
                                ]) {
                                    collectionRef.delete(deletedId);
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                this.onError(String(error));
            }
        };
        if (updateTime) this.canvasUpdateTimeState = time!;
        if (!this.shared && !this.temporary) {
            let slideState: SlideState = {
                canvas: this.serialize(),
                backgrounds: this.backgroundsState.toJSON(),
            };
            Canvases(pageId as number).updateFullCanvas(
                canvasId!,
                slideState.canvas,
                slideState.backgrounds
            );
            if (!skipHistory && this.canvasId != null) {
                if (this.currentSlideState[this.canvasId] != null) {
                    if (this.history[this.canvasId] == null) {
                        this.history[this.canvasId] = [];
                    }
                    if (this.historyNextIndex[this.canvasId] == null) {
                        this.historyNextIndex[this.canvasId] = 0;
                    }
                    // Remove entries at and after this.historyNextIndex
                    // and also entries above the limit;
                    this.history[this.canvasId] = this.history[
                        this.canvasId
                    ].slice(
                        Math.max(this.historyNextIndex[this.canvasId] - 25, 0),
                        this.historyNextIndex[this.canvasId]
                    );
                    this.history[this.canvasId].push(
                        this.currentSlideState[this.canvasId]!
                    );
                    this.historyNextIndex[this.canvasId] = this.history[
                        this.canvasId
                    ].length;
                }
                this.currentSlideState[this.canvasId] = slideState;
            }
        }
        try {
            saveChangesLock.acquire("saveChanges", fn, (err, ret) => {}, {
                skipQueue: false,
            });
        } catch (error) {}
    }

    @action.bound private saveChangesInnerAction(
        changes: InnerCanvasChanges,
        resetSynchronized: boolean,
        forceReplace: boolean,
        backgrounds: CanvasBackground[],
        backgroundMode: BackgroundMode,
        pageId: number | undefined,
        canvasId: number | undefined,
        isTemplate: boolean,
        time: number | undefined,
        sharedSlide:
            | {
                  canvas: InnerCanvas;
                  backgrounds: CanvasBackground[];
              }
            | undefined
    ): Promise<EditNotSavedCanvasResponse> {
        // if (this.canvasPageId == null) return Promise.reject();
        //  if (this.canvasPageId !== pageId) return Promise.reject();
        // if (this.canvasId !== canvasId) return Promise.reject();

        let instrumentationId = cookies.get("instrumentation_session_id");
        if (time != null) changes.canvasUpdateTimeState = time;
        if (canvasId != null) {
            if (changes.canvasTreeState != null) {
                for (let node of Object.values(changes.canvasTreeState)) {
                    if (node?.sharedId != null) {
                        let sharedBox = CanvasTreeStoreInner.nodeForSharing(
                            node
                        );
                        if (!this.shared) {
                            updateSharedBoxApi(
                                node.sharedId,
                                sharedBox,
                                isTemplate
                            )
                                .then(() => {
                                    SharedBoxesStore.updateSharedBoxes(
                                        this.moduleId!
                                    );
                                })
                                .catch((error) => {
                                    console.log(String(error));
                                });
                        } else {
                            this.onUpdateSharedBoxInModule(
                                node.sharedId,
                                sharedBox
                            );
                        }
                    }
                }
            }
            if (this.shared) {
                this.onUpdateSharedCanvasInModule(
                    canvasId,
                    sharedSlide!.canvas,
                    sharedSlide!.backgrounds
                );
                return Promise.resolve({
                    success: true,
                    id: canvasId,
                    background_ids: [],
                });
            }
            if (
                !isTemplate &&
                this.canvasPageId === pageId &&
                pageId != null &&
                this.canvasId === canvasId
            )
                this.renderThumbnail(canvasId, pageId);
            let cloneChanges = _.cloneDeep(changes);
            clearCanvasCalculatedValues(cloneChanges);

            if (
                !forceReplace &&
                backgrounds.length === 0 &&
                backgroundMode !== BackgroundMode.Replace &&
                _.isEmpty(cloneChanges)
            )
                return Promise.resolve({ success: true });

            return editNotSavedCanvasApi(
                cloneChanges,
                canvasId,
                backgrounds,
                backgroundMode,
                forceReplace,
                undefined,
                instrumentationId,
                isTemplate
            );
        } else {
            return Promise.reject("Please select a slide first");
        }
    }

    private renderThumbnail(canvasId: number, pageId: number) {
        const timeout: number = 5000; // milliseconds

        if (this.thumbnailRenderingTimeout != null) {
            clearTimeout(this.thumbnailRenderingTimeout);
        }
        this.thumbnailRenderingTimeout = setTimeout(() => {
            let root: HTMLElement | null = document.getElementById(
                "canvas-root-element"
            );
            if (root != null && this.canvasId === canvasId) {
                let cropRect = this.layerRect(
                    this.scale,
                    PagesStore(PageType.Canvases).pages
                ).get();
                cropRect.x = cropRect.x * this.scale;
                cropRect.y = cropRect.y * this.scale;
                cropRect.width = cropRect.width * this.scale;
                cropRect.height = cropRect.height * this.scale;
                renderHtmlRef(
                    { current: root },
                    cropRect.x,
                    cropRect.y,
                    cropRect.width,
                    cropRect.height,
                    true
                )
                    .then((thumbnail: string) => {
                        if (pageId != null) {
                            let index = Canvases(
                                pageId
                            ).canvasesState.findIndex(
                                (item) => item.id === canvasId
                            );
                            Canvases(pageId).canvasesState[index] = {
                                ...Canvases(pageId).canvasesState[index],
                                thumbnail: thumbnail!,
                            };
                        }
                        return editNotSavedCanvasThumbnailApi(
                            canvasId,
                            thumbnail,
                            cookies.get("instrumentation_session_id")
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }, timeout);
    }

    private renderThumbnailHtmlRef(root: HTMLElement): Promise<string> {
        let cropRect = this.layerRect(
            this.scale,
            PagesStore(PageType.Canvases).pages
        ).get();
        cropRect.x = cropRect.x * this.scale;
        cropRect.y = cropRect.y * this.scale;
        cropRect.width = cropRect.width * this.scale;
        cropRect.height = cropRect.height * this.scale;
        return renderHtmlRef(
            { current: root },
            cropRect.x,
            cropRect.y,
            cropRect.width,
            cropRect.height,
            true
        ).catch((error) => {
            console.log(error);
        });
    }

    // Deprecated
    // private static prepareInputDataElement(
    //     inputDataElement: InputDataElement
    // ): InputDataElement {
    //     return {
    //         ...inputDataElement,
    //         inputData: {
    //             ...inputDataElement.inputData,
    //             selectedFile: null,
    //             csvData: [],
    //             error: null,
    //             csvHeader: [],
    //             liveStreamConfigValid: false,
    //             loadFileStatus: LoadFileStatus.NotUploaded,
    //         },
    //         schemaOptions: defaultSchemaOptions(),
    //     };
    // }

    public serialize() {
        // let inputDataElementsState: { [key: string]: InputDataElement } = {};
        // for (let [key, value] of this.inputDataElementsState) {
        //     inputDataElementsState[
        //         key
        //     ] = CanvasTreeStoreInner.prepareInputDataElement(value);
        // }
        return {
            slideHeight: this.slideHeight,
            slideColor: this.slideColor,
            hidePagesBar: this.hidePagesBar,
            mobileViewWasEdited: this.mobileViewWasEdited,
            canvasWasEdited: this.canvasWasEdited,
            placementToolbar: this.placementToolbar,
            canvasUpdateTimeState: toJS(this.canvasUpdateTimeState),
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasTreeState: Object.fromEntries(this.canvasTreeState),
            backendTablesState: Object.fromEntries(this.backendTablesState),
            buttonsState: Object.fromEntries(this.buttonsState),
            dashboardsState: Object.fromEntries(this.dashboardsState),
            gridsState: Object.fromEntries(this.gridsState),
            questionnaireElementsState: Object.fromEntries(
                this.questionnaireElementsState
            ),
            mapElementsState: Object.fromEntries(this.mapElementsState),
            graphElementsState: Object.fromEntries(this.graphElementsState),
            embedUrlElementsState: Object.fromEntries(
                this.embedUrlElementsState
            ),
            shapeElementsState: Object.fromEntries(this.shapeElementsState),
            //    inputDataElementsState: inputDataElementsState,
            mergeDataElementsState: Object.fromEntries(
                this.mergeDataElementsState
            ),
            manageTableElementsState: Object.fromEntries(
                this.manageTableElementsState
            ),
            aggregateTableElementsState: Object.fromEntries(
                this.aggregateTableElementsState
            ),
        };
    }

    @computed public get canvasUpdateTime(): string {
        if (this.canvasUpdateTimeState === 0) return "";
        return formatDate(this.canvasUpdateTimeState, "refreshed");
    }

    public static hasCalculatedValues(node: CanvasElement) {
        if (
            (node.value != null && !Number.isNaN(node.value)) ||
            node.metric.length > 0
        ) {
            return true;
        }
        for (let additionalOutput of node.additionalOutputs) {
            if (
                (additionalOutput.value != null &&
                    !Number.isNaN(additionalOutput.value)) ||
                additionalOutput.metric.length > 0
            ) {
                return true;
            }
        }
        return false;
    }

    public static getValue(
        node: CanvasElement | CanvasTextBox,
        liveMode: boolean,
        withSubtitle: boolean
    ): string {
        return getPrettyPrintValue(node, !liveMode, withSubtitle);
    }

    checkTypes(originalNode: CanvasNode, newNode: CanvasNode): boolean {
        if (isInput(originalNode) || isDropdownSelector(originalNode)) {
            return getNodeValueType(originalNode) === getNodeValueType(newNode);
        }
        if (
            (isBox(originalNode) && isBox(newNode)) ||
            (isTextBox(originalNode) && isTextBox(newNode))
        ) {
            let checked =
                getNodeValueType(originalNode) === getNodeValueType(newNode);
            let minAdditionalOutputLength = Math.min(
                originalNode.additionalOutputs.length,
                newNode.additionalOutputs.length
            );
            for (let i = 0; i < minAdditionalOutputLength; ++i) {
                checked =
                    checked &&
                    getNodeValueType(originalNode.additionalOutputs[i]) ===
                        getNodeValueType(newNode.additionalOutputs[i]);
            }
            return checked;
        }
        return true;
    }

    public static getInputValue(
        node: {
            metric: string | undefined;
            value: NodeValue | undefined;
            format?: ColumnFormat | null;
        },
        liveMode: boolean
    ): string {
        return getPrettyPrintValue(
            node,
            !liveMode,
            false,
            defaultInputFieldPlaceholder
        );
    }

    // This function should NOT be an action
    private async fetchNode(node: CanvasNode): Promise<void> {
        if (isTextBox(node) || isBox(node)) {
            if (node.delegate != null && this.moduleId != null) {
                try {
                    let users: UserInfo[] = await getModuleUsers(
                        this.moduleId,
                        node.delegate
                    );
                    let user = users[0];
                    if (user != null) this.fetchNodeInnerAction(node, user);
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    @action.bound private fetchNodeInnerAction(
        node: CanvasTextBox | CanvasElement,
        user: UserInfo
    ): void {
        this.connectedUsersState.set(node.delegate!, user);
    }

    private async updateLinkedData(): Promise<void> {
        for (let node of this.canvasTreeState.values()) {
            try {
                await this.fetchNode(node);
            } catch (error) {
                break;
            }
        }
        for (let [key, value] of this.backendTablesState) {
            if (value.tableOption != null)
                await this.loadBackendTableAsyncAction(key);
        }
        await this.updateDashboards();
    }

    // This should NOT be an action
    public async loadBackendTableAsyncAction(
        backendTableId: string
    ): Promise<void> {
        try {
            let backendTable = this.backendTablesState.get(backendTableId);
            if (backendTable == null) return;
            let loadedTable = await getRawDataApi(
                backendTable.tableOption!,
                backendTable.rowCount,
                backendTable.conditions ?? undefined,
                undefined, // backendTable.variables is no longer used
                undefined,
                this.moduleId ?? remoteModuleId
            );
            this.loadBackendTableInnerAction(loadedTable, backendTableId);
        } catch (error) {
            console.log(error);
        }
    }

    @action.bound private loadBackendTableInnerAction(
        loadedTable: {
            currentLevels: { [key: string]: (string | number | null)[] };
            rowId: number[];
        },
        backendTableId: string
    ): void {
        this.connectedBackendTablesState.set(backendTableId, {
            rawTable: loadedTable.currentLevels,
            rowId: loadedTable.rowId,
            tableChanges: {},
        });
    }

    // This should NOT be an action
    public async loadBackendTables(
        dataScopeId: string | number
    ): Promise<void> {
        let entries: Array<[string, ConnectedBackendTable]> = [];
        for (let [
            backendTableId,
            backendTable,
        ] of this.backendTablesState.entries()) {
            if (
                backendTable.dataScopeOption != null &&
                backendTable.dataScopeOption.value === dataScopeId
            ) {
                let loadedTable = await getRawDataApi(
                    backendTable.tableOption!,
                    backendTable.rowCount,
                    backendTable.conditions ?? undefined,
                    backendTable.variables ?? undefined,
                    undefined,
                    this.moduleId ?? remoteModuleId
                );
                entries.push([
                    backendTableId,
                    {
                        rawTable: loadedTable.currentLevels,
                        rowId: loadedTable.rowId,
                        tableChanges: {},
                    },
                ]);
            }
        }
        this.loadBackendTablesInnerAction(entries);
    }

    @action.bound private loadBackendTablesInnerAction(
        entries: Array<[string, ConnectedBackendTable]>
    ): void {
        this.connectedBackendTablesState.merge(entries);
    }

    private calculateInputDetailsDepth(
        node: CanvasNode,
        canvasTreeState: Map<number, CanvasNode>,
        visitedNodes: number[] = []
    ): number {
        let depth = 1;
        let maxChildDepth = 0;
        if ("dataTableInputDetails" in node) {
            for (let details of (node as CanvasElement)
                .dataTableInputDetails!) {
                for (let condition of details.conditions) {
                    if (condition.isInput && condition.value != null) {
                        let conditionValue = condition.value as NodeLinkOption;
                        if (!conditionValue.isCloneInput) {
                            let index = conditionValue.value;
                            let node = canvasTreeState.get(index);
                            if (node && "dataTableInputDetails" in node) {
                                if (visitedNodes.includes(index)) {
                                    return Infinity;
                                }
                                visitedNodes.push(index);
                                const childDepth = this.calculateInputDetailsDepth(
                                    node,
                                    canvasTreeState,
                                    visitedNodes
                                );
                                maxChildDepth = Math.max(
                                    maxChildDepth,
                                    childDepth
                                );
                            }
                        }
                    }
                }
            }
        }

        depth = Math.max(depth, 1 + maxChildDepth);
        return depth;
    }

    private updateArgsAsyncAction = asyncAction(
        async (
            force: boolean = false,
            canvasTreeState: Map<number, CanvasNode>,
            dataScopeId: string | number | undefined = undefined
        ): Promise<
            [
                Array<
                    [
                        CanvasTextBox | CanvasElement | CanvasSlider,
                        {
                            new_update_times: number[];
                            result: { [key: string]: number | string | null };
                        } | null,
                        string | null
                    ]
                >,
                string | number | undefined
            ]
        > => {
            let updateData: Array<[
                CanvasTextBox | CanvasElement | CanvasSlider,
                {
                    new_update_times: number[];
                    result: { [key: string]: number | string | null };
                } | null,
                string | null
            ]> = [];
            let nodesForFetch: (
                | CanvasTextBox
                | CanvasElement
                | CanvasSlider
                | CanvasProgressElement
            )[] = [];
            const canvasTreeStateCopy = toJS(canvasTreeState);
            for (let node of canvasTreeStateCopy.values()) {
                if (
                    isTextBox(node) ||
                    isBox(node) ||
                    isSlider(node) ||
                    isProgressElement(node)
                ) {
                    nodesForFetch.push(node);
                }
            }
            nodesForFetch.sort(
                (a, b) =>
                    this.calculateInputDetailsDepth(a, canvasTreeStateCopy) -
                    this.calculateInputDetailsDepth(b, canvasTreeStateCopy)
            );
            let allArgs: CanvasTreeStoreInner["canvasTreeArgs"] = {};
            for (let node of nodesForFetch) {
                try {
                    if (isBox(node) || isTextBox(node)) {
                        force =
                            force ||
                            this.canvasTreeArgs[node.id] == null ||
                            isNaN(node.value as number) ||
                            node.value == null ||
                            node.additionalOutputs.some(
                                (node) =>
                                    isNaN(node.value as number) ||
                                    node.value == null
                            );
                    } else if (isSlider(node) || isProgressElement(node)) {
                        // Slider
                        force =
                            force ||
                            this.canvasTreeArgs[node.id] == null ||
                            isNaN(node.minOutput.value as number) ||
                            node.minOutput.value == null ||
                            isNaN(node.maxOutput.value as number) ||
                            node.maxOutput.value == null;
                        if (isProgressElement(node)) {
                            force =
                                force ||
                                isNaN(node.value as number) ||
                                node.value == null ||
                                isNaN(node.errorOutput.value as number) ||
                                node.errorOutput.value == null;
                        }
                    }
                    let args = null;

                    if (
                        node.dataTableInputDetails != null &&
                        node.dataTableInputDetails.length > 0
                    ) {
                        if (
                            dataScopeId == null ||
                            node.dataTableInputDetails.find(
                                (item) => item.data_table_idx === dataScopeId
                            ) != null
                        ) {
                            let dataTableInputDetails =
                                node.dataTableInputDetails ?? [];
                            if (dataScopeId != null) {
                                dataTableInputDetails = dataTableInputDetails.filter(
                                    (input) =>
                                        input.data_table_idx === dataScopeId
                                );
                            }
                            args = await selectDataIfUpdated(
                                node.dataTableInputDetails ?? [],
                                force,
                                this.moduleId ?? remoteModuleId
                            );
                        }
                    }
                    if (args != null) {
                        for (let inputDetails of node.dataTableInputDetails ??
                            []) {
                            if (inputDetails.zero_if_no_data) {
                                for (let variable of inputDetails.variables) {
                                    if (
                                        args.result[
                                            variable.alias.toLowerCase()
                                        ] == null
                                    ) {
                                        args.result[
                                            variable.alias.toLowerCase()
                                        ] = 0;
                                    }
                                }
                            }
                        }
                    }
                    allArgs[node.id] = args;
                    let changes = this.calculateValuesAction(
                        canvasTreeStateCopy,
                        {
                            // Put this.canvasTreeArgs here to prevent flickering
                            ...this.canvasTreeArgs,
                            ...allArgs,
                        }
                    );
                    this.updateNodesByLinkedInputsAction(
                        {
                            canvasTreeState: changes,
                        },
                        false,
                        canvasTreeStateCopy
                    );
                    // As we modify canvasTreeStateCopy, shallow copies from nodesForFetch will be also modified as well
                    updateData.push([node, args, null]);
                } catch (error) {
                    let args: {
                        new_update_times: number[];
                        result: { [key: string]: number | string };
                    } = {
                        new_update_times: [],
                        result: {},
                    };
                    for (let inputDetails of node.dataTableInputDetails ?? []) {
                        if (inputDetails.zero_if_no_data) {
                            for (let variable of inputDetails.variables) {
                                args.result[variable.alias] = 0;
                            }
                        }
                    }
                    updateData.push([
                        node,
                        args,
                        error ?? "Couldn't select data from tables",
                    ]);
                }
            }
            return [updateData, dataScopeId];
        },
        action(
            (
                updateData: Array<
                    [
                        CanvasTextBox | CanvasElement | CanvasSlider,
                        {
                            new_update_times: number[];
                            result: { [key: string]: number | string | null };
                            errors?: string[];
                        } | null,
                        string | null
                    ]
                >,
                dataScopeId: string | number | undefined
            ) => {
                for (let [node, args, errorMessage] of updateData) {
                    if (
                        dataScopeId != null &&
                        this.canvasTreeArgs[node.id] != null
                    ) {
                        this.canvasTreeArgs[node.id] = {
                            ...this.canvasTreeArgs[node.id]!,
                            ...args,
                        };
                    } else {
                        this.canvasTreeArgs[node.id] = args;
                    }
                    if (errorMessage != null) {
                        this.canvasTreeRequestErrorsState.set(
                            node.id,
                            errorMessage
                        );
                    } else {
                        let errorMessage: string | null = null;
                        if (args?.errors != null && args?.errors.length > 0) {
                            errorMessage = args.errors?.join(", ");
                            this.canvasTreeRequestErrorsState.set(
                                node.id,
                                errorMessage
                            );
                        }
                    }
                }
            }
        )
    );

    public setInitialSize(initialSize: { width: number; height: number }) {
        this.initialSize = initialSize;
    }

    public updateAllAsyncAction = asyncAction(
        async (
            saveChanges: boolean,
            canvasTreeState: Map<number, CanvasNode>
        ): Promise<
            [
                Array<
                    [
                        CanvasTextBox | CanvasElement | CanvasSlider,
                        {
                            new_update_times: number[];
                            result: { [key: string]: number | string | null };
                        } | null,
                        string | null
                    ]
                >,
                boolean
            ]
        > => {
            if (!SharedBoxesStore.initialized)
                await SharedBoxesStore.updateSharedBoxes(this.moduleId!);
            let updateData = (
                await this.updateArgsAsyncAction.asyncPart(
                    true,
                    canvasTreeState
                )
            )[0];
            return [updateData, saveChanges];
        },
        action(
            (
                updateData: Array<
                    [
                        CanvasTextBox | CanvasElement | CanvasSlider,
                        {
                            new_update_times: number[];
                            result: { [key: string]: number | string | null };
                        } | null,
                        string | null
                    ]
                >,
                saveChanges: boolean
            ) => {
                this.updateNodesByLinkedSharedInputs();
                this.updateArgsAsyncAction.actionPart(updateData, undefined);
                let changes = this.calculateValuesAction();
                this.calculateCanvasSizeAction();
                this.isLoadingState = false;
                if (saveChanges)
                    this.saveChangesAction(
                        { canvasTreeState: changes },
                        false,
                        false
                    );
                this.updateNodesByLinkedInputsAction({
                    canvasTreeState: Object.fromEntries(this.canvasTreeState),
                });
                this.updateLinkedData();
            }
        )
    );

    renameSpreadsheetVariables(outerIdMap: {
        [key: string]: string;
    }): {
        [key: number]: CanvasNode;
    } {
        let changes: { [key: number]: CanvasNode } = {};
        for (let node of this.canvasTreeState.values()) {
            let mutableNode = { ...node };
            let changed: boolean = false;
            try {
                if (mutableNode.metric.startsWith("=")) {
                    let expression = Parser.parse(
                        mutableNode.metric.substring(1)
                    );
                    for (let oldOuterId of Object.keys(outerIdMap)) {
                        let newOuterId = outerIdMap[oldOuterId];
                        for (let variable of expression.variables()) {
                            if (
                                variable.toLowerCase() ===
                                oldOuterId.toLowerCase()
                            ) {
                                expression = expression.substitute(
                                    variable,
                                    "tmp_".concat(newOuterId)
                                );
                                changed = true;
                            } else if (
                                variable
                                    .toLowerCase()
                                    .startsWith(
                                        oldOuterId.toLowerCase().concat("_")
                                    )
                            ) {
                                expression = expression.substitute(
                                    variable,
                                    "tmp_".concat(
                                        variable
                                            .toLowerCase()
                                            .replaceAll(
                                                oldOuterId
                                                    .toLowerCase()
                                                    .concat("_"),
                                                newOuterId.concat("_")
                                            )
                                    )
                                );
                                changed = true;
                            }
                        }
                    }
                    if (changed) {
                        for (let variable of expression.variables()) {
                            if (variable.startsWith("tmp_")) {
                                expression = expression.substitute(
                                    variable,
                                    variable.replace("tmp_", "")
                                );
                            }
                        }
                        mutableNode.metric = "=".concat(expression.toString());
                        changes[node.id] = mutableNode;
                    }
                }
            } catch (error) {}
        }
        for (let [newNodeId, newNode] of Object.entries(changes))
            this.canvasTreeState.set(Number(newNodeId), newNode);

        return changes;
    }

    public markReferencesForRemove(tokens: Token[]) {
        for (const [index, token] of tokens.entries()) {
            if (
                token.type === "IVAR" &&
                (token.value as string).startsWith("tmp_")
            ) {
                token.needDelete = true;
                if (index < tokens.length - 1) {
                    if (tokens[index + 1].type === "IOP1") {
                        tokens[index + 1].needDelete = true;
                    }
                }
                for (
                    let opIndex = index + 1;
                    opIndex < tokens.length;
                    ++opIndex
                ) {
                    if (tokens[opIndex].type === "IOP2") {
                        tokens[opIndex].needDelete = true;
                        break;
                    }
                }
            }
            if (token.type === "IEXPR") {
                this.markReferencesForRemove(token.value as Token[]);
            }
        }
    }
    public deleteMarkedTokens(tokens: Token[]): Token[] {
        for (let token of tokens) {
            if (token.type === "IEXPR") {
                token.value = this.deleteMarkedTokens(token.value as Token[]);
                if ((token.value as Token[]).length === 0)
                    token.needDelete = true;
            }
        }
        return tokens.filter((token) => !token.needDelete);
    }

    public removeReferences(outerIds: string[]) {
        let changes: { [key: number]: CanvasNode } = {};
        for (let node of this.canvasTreeState.values()) {
            let mutableNode = { ...node };
            let changed: boolean = false;
            try {
                if (mutableNode.metric.startsWith("=")) {
                    let expression = Parser.parse(
                        mutableNode.metric.substring(1)
                    );
                    for (let oldOuterId of outerIds) {
                        for (let variable of expression.variables()) {
                            if (
                                variable.toLowerCase() ===
                                oldOuterId.toLowerCase()
                            ) {
                                expression = expression.substitute(
                                    variable,
                                    "tmp_".concat(oldOuterId)
                                );
                                changed = true;
                            } else if (
                                variable
                                    .toLowerCase()
                                    .startsWith(
                                        oldOuterId.toLowerCase().concat("_")
                                    )
                            ) {
                                expression = expression.substitute(
                                    variable,
                                    "tmp_".concat(variable)
                                );
                                changed = true;
                            }
                        }
                    }
                    if (changed) {
                        let tokens: Token[] = (expression as any).tokens;
                        this.markReferencesForRemove(tokens);
                        (expression as any).tokens = this.deleteMarkedTokens(
                            tokens
                        );
                        if ((expression as any).tokens.length > 0)
                            mutableNode.metric = "=".concat(
                                expression.toString()
                            );
                        else mutableNode.metric = "=";
                        changes[node.id] = mutableNode;
                    }
                }
            } catch (error) {}
        }
        for (let [newNodeId, newNode] of Object.entries(changes))
            this.canvasTreeState.set(Number(newNodeId), newNode);

        return changes;
    }

    private static migrateConditionInput(
        condition: Condition,
        oldId: number,
        newId: number,
        newOuterId: string
    ) {
        if (
            !isMultiCondition(condition) &&
            condition.isInput &&
            condition.value != null &&
            !(condition.value as NodeLinkOption).isCloneInput &&
            (condition.value as NodeLinkOption).value === oldId
        ) {
            (condition.value as NodeLinkOption).label = newOuterId;
            (condition.value as NodeLinkOption).value = newId;
        }
        if (
            isMultiCondition(condition) &&
            condition.isInput &&
            condition.value != null &&
            Array.isArray(condition.value) &&
            condition.value.length > 0
        ) {
            for (let index in condition.value) {
                let subValue = condition.value[index];
                if (
                    subValue != null &&
                    !(subValue as NodeLinkOption).isCloneInput &&
                    (subValue as NodeLinkOption).value === oldId
                ) {
                    (subValue as NodeLinkOption).label = newOuterId;
                    (subValue as NodeLinkOption).value = newId;
                }
            }
        }
    }
    private static migrateStatusExpressionInput(
        statusExpression:
            | StatusExpression
            | NotificationExpression
            | PrintExpression,
        oldId: number,
        newId: number,
        newOuterId: string
    ) {
        for (let subExpression of statusExpression.subexpressions) {
            if (
                subExpression.isInput &&
                subExpression.value != null &&
                !(subExpression.value as NodeLinkOption).isCloneInput &&
                (subExpression.value as NodeLinkOption).value === oldId
            ) {
                (subExpression.value as NodeLinkOption).value = newId;
                (subExpression.value as NodeLinkOption).label = newOuterId;
            }
        }
    }
    @action.bound public mergeCanvasAction(
        subCanvas: SubCanvas,
        onFinish: (pastedMetadata: ItemMetadata[]) => void
    ): void {
        let pastedMetadata: ItemMetadata[] = [];
        let newBackgrounds: CanvasBackground[] = [];
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
            buttonsState: {},
        };
        // Create new groups and map old group IDs to new ones
        let groupIdMap: { [key: string]: string } = {};
        const replaceGroupId = (
            item: {
                [key in "group_id" | "groupId"]?: string | null;
            }
        ) => {
            if (item.group_id != null) {
                if (!(item.group_id in groupIdMap)) {
                    groupIdMap[item.group_id] = nanoid();
                }
                item.group_id = groupIdMap[item.group_id];
            }
            if (item.groupId != null) {
                if (!(item.groupId in groupIdMap)) {
                    groupIdMap[item.groupId] = nanoid();
                }
                item.groupId = groupIdMap[item.groupId];
            }
        };
        for (let item of subCanvas.backgrounds) {
            item.x = item.x + 5;
            item.y = item.y + 5;
            replaceGroupId(item);
            item.id = NaN;
            newBackgrounds.push(item);
        }
        for (let item of subCanvas.buttons) {
            item.nodePosition[this.canvasViewMode].x += 5;
            item.nodePosition[this.canvasViewMode].y += 5;
            replaceGroupId(item);
            let id = nanoid();
            this.buttonsState.set(id, item);
            changes.buttonsState = {
                ...changes.buttonsState,
                [id]: item,
            };
            pastedMetadata.push({
                type: "buttonsState",
                id: id,
            });
        }
        for (let item of subCanvas.shapes) {
            item.nodePosition[this.canvasViewMode].x += 5;
            item.nodePosition[this.canvasViewMode].y += 5;
            replaceGroupId(item);
            let id = nanoid();
            this.shapeElementsState.set(id, item);
            changes.shapeElementsState = {
                ...changes.shapeElementsState,
                [id]: item,
            };
            pastedMetadata.push({
                type: "shapeElementsState",
                id: id,
            });
        }
        for (let grid of subCanvas.grids) {
            let oldId = grid.id;
            grid.id = nanoid();
            grid.x = grid.x + 5;
            grid.y = grid.y + 5;
            replaceGroupId(grid);
            subCanvas.nodes
                .filter(
                    (node): node is CanvasElement =>
                        (isBox(node) || isSimpleSpreadSheetInput(node)) &&
                        node.gridId === oldId
                )
                .forEach((node) => {
                    node.gridId = grid.id;
                });
            if (isSpreadSheetGrid(grid)) {
                this.nodeSpreadSheetAutoIncrementId += 1;
                grid.index = StringUtils.numToAlphabet(
                    this.nodeSpreadSheetAutoIncrementId
                );
            }

            this.gridsState.set(grid.id, grid);
            changes.gridsState = {
                ...changes.gridsState,
                [grid.id]: grid,
            };
            pastedMetadata.push({
                type: "gridsState",
                id: grid.id,
            });
        }

        //migrate nodes
        let sharedBoxesIds = subCanvas.sharedBoxes.map(
            (sharedBox) => sharedBox.id
        );
        let oldNodeIds = subCanvas.nodes.map((node) => node.id);
        for (let node of subCanvas.nodes) {
            node.parentIds = node.parentIds.filter((edge) =>
                oldNodeIds.includes(edge.id)
            );
            node.childrenIds = node.childrenIds.filter((nodeId) =>
                oldNodeIds.includes(nodeId)
            );
            node.childrenSharedIds = node.childrenSharedIds.filter((nodeId) =>
                sharedBoxesIds.includes(nodeId.value)
            );
            delete node.sharedId;
        }
        for (let node of subCanvas.nodes) {
            let oldId = node.id;
            this.canvasAutoIncrementId += 1;
            let newId = this.canvasAutoIncrementId;
            let oldOuterId = node.outerId;

            let spreadSheetGrid: CanvasSpreadSheetGrid | undefined = undefined;
            if (isBox(node) || isSimpleSpreadSheetInput(node)) {
                let gridId = node.gridId;
                if (gridId != null) {
                    let grid = this.gridsState.get(gridId);
                    if (grid == null) continue;
                    if (isSpreadSheetGrid(grid)) {
                        spreadSheetGrid = grid as CanvasSpreadSheetGrid;
                    }
                }
            }
            let newOuterId = "";
            if (spreadSheetGrid == null) {
                this.nodeSpreadSheetAutoIncrementId += 1;
                newOuterId = outerNodeId(this.nodeSpreadSheetAutoIncrementId);
            } else {
                newOuterId = outerSpreadSheetId(
                    spreadSheetGrid.index,
                    node.nodePosition[this.canvasViewMode].x + 1,
                    node.nodePosition[this.canvasViewMode].y + 1
                );
            }
            let childrens = subCanvas.nodes.filter((anotherNode) =>
                node.childrenIds.includes(anotherNode.id)
            );

            let inputNodes = subCanvas.nodes.filter(
                (item) =>
                    (isBox(item) ||
                        isTextBox(item) ||
                        isProgressElement(item) ||
                        isSlider(item)) &&
                    item.dataTableInputDetails != null &&
                    item.dataTableInputDetails.length !== 0
            );
            let inputDropdownSelectors: CanvasDropdownSelector[] = subCanvas.nodes.filter(
                (item) => isDropdownSelector(item)
            ) as CanvasDropdownSelector[];
            let inputStatusNodes = subCanvas.nodes.filter(
                (item) =>
                    (isBox(item) || isTextBox(item)) &&
                    item.statusExpressions != null &&
                    item.statusExpressions.length !== 0
            );
            let inputNotificationNodes = subCanvas.nodes.filter(
                (item) =>
                    (isBox(item) || isTextBox(item)) &&
                    item.notificationExpressions != null &&
                    item.notificationExpressions.length !== 0
            );
            let inputPrintNodes = subCanvas.nodes.filter(
                (item) =>
                    isTextBox(item) &&
                    item.printExpressions != null &&
                    item.printExpressions.length !== 0
            );
            let inputDashboards =
                subCanvas.otherItems?.["dashboardsState"] ?? [];
            let inputMaps = subCanvas.otherItems?.["mapElementsState"] ?? [];

            for (let dashboard of inputDashboards) {
                for (let condition of dashboard.finding?.config?.conditions ??
                    []) {
                    CanvasTreeStoreInner.migrateConditionInput(
                        condition,
                        oldId,
                        newId,
                        newOuterId
                    );
                }
                for (let statusExpression of dashboard.finding?.config
                    ?.statusExpressions ?? []) {
                    CanvasTreeStoreInner.migrateStatusExpressionInput(
                        statusExpression,
                        oldId,
                        newId,
                        newOuterId
                    );
                }
            }
            for (let map of inputMaps) {
                for (let condition of map?.conditions ?? []) {
                    CanvasTreeStoreInner.migrateConditionInput(
                        condition,
                        oldId,
                        newId,
                        newOuterId
                    );
                }
            }
            for (let grid of subCanvas.grids) {
                let changed = false;
                for (let condition of grid?.fullSpreadSheetBackendOutputOptions
                    ?.conditions ?? []) {
                    CanvasTreeStoreInner.migrateConditionInput(
                        condition,
                        oldId,
                        newId,
                        newOuterId
                    );
                }
                if (changed) {
                    this.gridsState.set(grid.id, grid);
                    changes.gridsState = {
                        ...changes.gridsState,
                        [grid.id]: grid,
                    };
                }
            }
            for (let node of inputNodes) {
                for (let dataTableInput of (node as CanvasElement)
                    .dataTableInputDetails!) {
                    for (let condition of dataTableInput.conditions ?? []) {
                        CanvasTreeStoreInner.migrateConditionInput(
                            condition,
                            oldId,
                            newId,
                            newOuterId
                        );
                    }
                }
            }
            for (let node of inputDropdownSelectors) {
                for (let condition of node.conditions ?? []) {
                    CanvasTreeStoreInner.migrateConditionInput(
                        condition,
                        oldId,
                        newId,
                        newOuterId
                    );
                }
            }
            for (let node of inputStatusNodes) {
                for (let statusExpression of (node as CanvasElement)
                    .statusExpressions ?? []) {
                    CanvasTreeStoreInner.migrateStatusExpressionInput(
                        statusExpression,
                        oldId,
                        newId,
                        newOuterId
                    );
                }
            }

            for (let node of inputNotificationNodes) {
                for (let statusExpression of (node as CanvasElement)
                    .notificationExpressions ?? []) {
                    CanvasTreeStoreInner.migrateStatusExpressionInput(
                        statusExpression,
                        oldId,
                        newId,
                        newOuterId
                    );
                }
            }
            for (let node of inputPrintNodes) {
                for (let printExpression of (node as CanvasTextBox)
                    .printExpressions ?? []) {
                    CanvasTreeStoreInner.migrateStatusExpressionInput(
                        printExpression,
                        oldId,
                        newId,
                        newOuterId
                    );
                }
            }
            for (let children of childrens) {
                children.parentIds = children.parentIds.map((parent) =>
                    parent.id === oldId ? { ...parent, id: newId } : parent
                );
            }
            for (let parent of subCanvas.nodes) {
                parent.childrenIds = parent.childrenIds.map((childrenId) =>
                    childrenId === oldId ? newId : childrenId
                );
                try {
                    if (
                        isTextBox(parent) ||
                        isBox(parent) ||
                        isSimpleSpreadSheetInput(parent)
                    ) {
                        if (parent.metric.startsWith("=")) {
                            let expression = Parser.parse(
                                parent.metric.substring(1)
                            );
                            for (let variable of expression.variables()) {
                                if (variable === oldOuterId)
                                    expression = expression.substitute(
                                        variable,
                                        newOuterId
                                    );
                                else if (
                                    variable.startsWith(oldOuterId.concat("_"))
                                )
                                    expression = expression.substitute(
                                        variable,
                                        variable.replaceAll(
                                            oldOuterId.concat("_"),
                                            newOuterId.concat("_")
                                        )
                                    );
                            }
                            parent.metric = "=".concat(expression.toString());
                        }
                    }
                } catch (error) {}
            }
            node.id = newId;
            node.outerId = newOuterId;
            if (isBox(node) && node.gridId == null) {
                node.nodePosition[this.canvasViewMode].x += 5;
                node.nodePosition[this.canvasViewMode].y += 5;
            }
            if (!isBox(node) && !isSimpleSpreadSheetInput(node)) {
                node.nodePosition[this.canvasViewMode].x += 5;
                node.nodePosition[this.canvasViewMode].y += 5;
            }
        }
        let dashboardIdMap: { [key: string]: string } = {};
        if (subCanvas.otherItems != null) {
            for (let [key, items] of Object.entries(subCanvas.otherItems) as [
                ItemMetadata["type"],
                any[]
            ][]) {
                let objects = this.getObjects();
                let collection = objects.find((object) => object.name === key);
                if (collection != null) {
                    let collectionRef = collection.ref;
                    for (let item of items) {
                        let oldId: string | undefined = undefined;
                        if ("id" in item) {
                            oldId = item.id as string;
                            delete item.id;
                        }
                        let newItem = {
                            ...item,
                            x: item.x + 5,
                            y: item.y + 5,
                        };
                        replaceGroupId(newItem);
                        let id = nanoid();
                        if (oldId != null) {
                            dashboardIdMap[oldId] = id;
                        }
                        collectionRef.set(id, newItem);
                        changes[key as DataScienceElementKey] = {
                            ...changes[key as DataScienceElementKey],
                            [id]: newItem,
                        };
                        pastedMetadata.push({
                            type: key,
                            id: id,
                        });
                    }
                }
            }
        }
        for (let node of subCanvas.nodes) {
            if (isDropdownSelector(node) || isFilter(node)) {
                if (node.dynamicOption != null)
                    node.dynamicOption.dashboardId =
                        dashboardIdMap[node.dynamicOption.dashboardId] ??
                        node.dynamicOption.dashboardId;
            }
            replaceGroupId(node);
            this.canvasTreeState.set(node.id, node);
            if (
                (isBox(node) && !node.gridId) ||
                (!isBox(node) && !isSimpleSpreadSheetInput(node))
            ) {
                pastedMetadata.push({ type: "canvasTreeState", id: node.id });
            }
            changes.canvasTreeState![node.id] = node;
        }

        let newChanges = this.calculateValuesAction();
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...newChanges,
        };
        changes.canvasAutoIncrementId = this.canvasAutoIncrementId;
        changes.nodeSpreadSheetAutoIncrementId = this.nodeSpreadSheetAutoIncrementId;
        this.saveChangesAction(
            changes,
            true,
            true,
            false,
            newBackgrounds,
            BackgroundMode.Append,
            false,
            (response) => {
                response.background_ids.forEach((backgroundId, index) => {
                    pastedMetadata.push({
                        id: backgroundId,
                        type: "backgroundsState",
                    });
                    this.backgroundsState.push({
                        ...newBackgrounds[index],
                        id: backgroundId,
                    });
                });
                // We have to update currentSlideState here, otherwise
                // history will work incorrectly.
                if (this.canvasId != null) {
                    this.currentSlideState[this.canvasId] = {
                        canvas:
                            this.currentSlideState[this.canvasId]?.canvas ??
                            this.serialize(),
                        backgrounds: this.backgroundsState.toJSON(),
                    };
                }
                onFinish(pastedMetadata);
            }
        );
        this.updateNodesByLinkedInputsAction(changes);
        this.updateAllAsyncAction.bothParts(false, this.canvasTreeState);
        // onFinish(pastedMetadata);
    }

    public serializeMetadata(metadata: ItemMetadata[]): SubCanvas {
        let subCanvas: SubCanvas = {
            tags: [],
            nodes: [],
            shapes: [],
            sharedBoxes: [],
            grids: [],
            backgrounds: [],
            buttons: [],
            otherItems: {},
        };
        for (let item of metadata) {
            switch (item.type) {
                case "buttonsState":
                    let button = this.buttonsState.get(item.id as string);
                    if (button != null) subCanvas.buttons.push(toJS(button));
                    break;
                case "canvasTreeState":
                    let node = this.canvasTreeState.get(item.id as number);
                    if (node != null) subCanvas.nodes.push(toJS(node));
                    break;
                case "shapeElementsState":
                    let shape = this.shapeElementsState.get(item.id as string);
                    if (shape != null) subCanvas.shapes.push(toJS(shape));
                    break;
                case "backgroundsState":
                    let index = this.backgroundsState.findIndex(
                        (background) => background.id === item.id
                    );
                    if (index < 0) break;
                    subCanvas.backgrounds.push(
                        toJS(this.backgroundsState[index])
                    );
                    break;
                case "gridsState":
                    let grid = this.gridsState.get(item.id as string);
                    if (grid != null) {
                        subCanvas.grids.push(grid);
                        this.canvasTreeState.forEach((node) => {
                            if (
                                (isBox(node) ||
                                    isSimpleSpreadSheetInput(node)) &&
                                node.gridId === item.id
                            ) {
                                subCanvas.nodes.push(toJS(node));
                            }
                        });
                    }

                    break;
                default:
                    if (subCanvas.otherItems![item.type] == null) {
                        subCanvas.otherItems![item.type] = [];
                    }
                    let objects = this.getObjects();
                    let collection = objects.find(
                        (object) => object.name === item.type
                    );
                    if (collection != null) {
                        let collectionRef = collection.ref;
                        let element = collectionRef.get(item.id);
                        if (element != null)
                            subCanvas.otherItems![item.type]!.push({
                                ...toJS(element),
                                id: item.id,
                            });
                    }
                    break;
            }
        }
        return subCanvas;
    }

    @action.bound public deleteByMetadataAction(
        metadata: ItemMetadata[]
    ): void {
        let nodeIds: number[] = [];
        let deletedButtons: { [key: string]: null } = {};
        let backgroundIds = [];
        let deletedGrids: { [key: string]: null } = {};
        let deletedShapes: { [key: string]: null } = {};
        let otherChanges: InnerCanvasChanges = {};
        for (let item of metadata) {
            switch (item.type) {
                case "canvasTreeState":
                    nodeIds.push(item.id as number);
                    break;
                case "backgroundsState":
                    backgroundIds.push(item.id as number);
                    break;
                case "buttonsState":
                    this.buttonsState.delete(item.id as string);
                    deletedButtons[item.id as string] = null;
                    break;
                case "gridsState":
                    let gridId = item.id as string;
                    this.canvasTreeState.forEach((node) => {
                        if (
                            (isBox(node) || isSimpleSpreadSheetInput(node)) &&
                            node.gridId === gridId
                        ) {
                            nodeIds.push(node.id);
                        }
                    });
                    let grid = this.gridsState.get(gridId);
                    this.gridsState.delete(gridId);
                    if (
                        grid?.fullSpreadSheetBackendOutputOptions
                            ?.dataScopeId != null
                    ) {
                        this.onDataSetDisconnected(
                            grid.fullSpreadSheetBackendOutputOptions.dataScopeId
                        );
                    }
                    deletedGrids[gridId] = null;
                    break;
                case "shapeElementsState":
                    let shapeId = item.id as string;
                    this.shapeElementsState.delete(shapeId);
                    deletedShapes[shapeId] = null;
                    break;
                case "pageBar":
                    break;
                case "slideNumber":
                    break;
                default:
                    if (otherChanges[item.type] == null) {
                        otherChanges[item.type] = {};
                    }
                    let objects = this.getObjects();
                    let collection = objects.find(
                        (object) => object.name === item.type
                    );
                    if (collection != null) {
                        let collectionRef = collection.ref;
                        let dataScopeId:
                            | string
                            | number
                            | undefined = undefined;
                        if (item.type === "dashboardsState") {
                            let dashboard = this.dashboardsState.get(
                                item.id as string
                            );
                            dataScopeId =
                                dashboard?.finding?.config?.dataScope?.value;
                        } else if (item.type === "questionnaireElementsState") {
                            let element = this.questionnaireElementsState.get(
                                item.id as string
                            );
                            dataScopeId =
                                element?.backendOutput?.dataScopeOption?.value;
                        }
                        collectionRef.delete(item.id);
                        if (dataScopeId != null) {
                            this.onDataSetDisconnected(dataScopeId);
                        }
                        otherChanges[item.type]![item.id] = null;
                    }
                    break;
            }
        }
        let changes = this.deleteNodesAction(nodeIds);
        changes.gridsState = deletedGrids;
        changes.buttonsState = deletedButtons;
        changes.shapeElementsState = deletedShapes;
        changes = {
            ...changes,
            ...otherChanges,
        };
        this.saveChangesAction(changes);
        this.deleteBackgroundsAction(backgroundIds);
        this.updateNodesByLinkedInputsAction(changes);
    }

    @action.bound public updateBySelectionAreaAction(
        selectionArea: SelectionArea,
        props: { fontSize?: number; fontColor?: string }
    ): void {
        let changes: InnerCanvasChanges = {};
        let spreadSheetGrid = this.gridsState.get(
            selectionArea.gridId
        ) as CanvasSpreadSheetGrid;
        if (spreadSheetGrid == null) return;
        let nodes = this.getSortedGridNodes(spreadSheetGrid.id);
        for (let j = selectionArea.left; j <= selectionArea.right; ++j) {
            for (let i = selectionArea.top; i <= selectionArea.bottom; ++i) {
                if (i >= 0 && j >= 0) {
                    let node = nodes[i * spreadSheetGrid.cols + j];
                    let sourceNode = this.canvasTreeState.get(
                        node.id as number
                    );
                    if (sourceNode == null) continue;
                    this.canvasTreeState.set(node.id as number, {
                        ...sourceNode,
                        ...props,
                    });
                    if (changes.canvasTreeState == null)
                        changes.canvasTreeState = {};
                    changes.canvasTreeState[node.id] = {
                        ...sourceNode,
                        ...props,
                    };
                } else {
                    if (i === -1 && j >= 0) {
                        if (spreadSheetGrid.headers != null) {
                            spreadSheetGrid.headers![j] = {
                                ...spreadSheetGrid.headers![j],
                                ...props,
                            };
                        }
                    }
                    if (i >= 0 && j === -1) {
                        if (spreadSheetGrid.leftHeaders != null) {
                            spreadSheetGrid.leftHeaders![i] = {
                                ...spreadSheetGrid.leftHeaders![i],
                                ...props,
                            };
                        }
                    }
                }
            }
        }
        this.gridsState.set(spreadSheetGrid.id as string, {
            ...spreadSheetGrid,
        });
        if (changes.gridsState == null) changes.gridsState = {};
        changes.gridsState[spreadSheetGrid.id] = spreadSheetGrid;
        this.saveChangesAction(changes);
    }
    @action.bound public clearBySelectionAreaAction(
        selectionArea: SelectionArea
    ): void {
        let changes: InnerCanvasChanges = {};
        let spreadSheetGrid = this.gridsState.get(
            selectionArea.gridId
        ) as CanvasSpreadSheetGrid;
        if (spreadSheetGrid == null) return;
        let nodes = this.getSortedGridNodes(spreadSheetGrid.id);
        for (let j = selectionArea.left; j <= selectionArea.right; ++j) {
            for (let i = selectionArea.top; i <= selectionArea.bottom; ++i) {
                if (i >= 0 && j >= 0) {
                    let props = {
                        metric: "",
                        value: NaN,
                    };
                    let node = nodes[i * spreadSheetGrid.cols + j];
                    let sourceNode = this.canvasTreeState.get(
                        node.id as number
                    );
                    if (sourceNode == null) continue;
                    this.canvasTreeState.set(node.id as number, {
                        ...sourceNode,
                        ...props,
                    });
                    if (changes.canvasTreeState == null)
                        changes.canvasTreeState = {};
                    changes.canvasTreeState[node.id] = {
                        ...sourceNode,
                        ...props,
                    };
                } else {
                    let props = {
                        text: "",
                    };
                    if (i === -1 && j >= 0) {
                        if (spreadSheetGrid.headers != null) {
                            spreadSheetGrid.headers![j] = {
                                ...spreadSheetGrid.headers![j],
                                ...props,
                            };
                        }
                    }
                    if (i >= 0 && j === -1) {
                        if (spreadSheetGrid.leftHeaders != null) {
                            spreadSheetGrid.leftHeaders![i] = {
                                ...spreadSheetGrid.leftHeaders![i],
                                ...props,
                            };
                        }
                    }
                }
            }
        }
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...this.calculateValuesAction(),
        };
        this.gridsState.set(spreadSheetGrid.id as string, {
            ...spreadSheetGrid,
        });
        if (changes.gridsState == null) changes.gridsState = {};
        changes.gridsState[spreadSheetGrid.id] = spreadSheetGrid;
        this.saveChangesAction(changes);
        this.updateNodesByLinkedInputsAction(changes);
    }

    public getCommonFontPropsFromSelectionArea(
        selectionArea: SelectionArea,
        defaultFontSize: number,
        defaultColor: string,
        defaultTitleSize: number
    ): { size: number | undefined; color: string | undefined } | undefined {
        let fontProps = {
            size: 0,
            color: "#000000",
        };
        let colors = new Set();
        let sizes = new Set();
        let spreadSheetGrid = this.gridsState.get(
            selectionArea.gridId
        ) as CanvasSpreadSheetGrid;
        if (spreadSheetGrid == null) return;
        let nodes = this.getSortedGridNodes(spreadSheetGrid.id);
        for (let j = selectionArea.left; j <= selectionArea.right; ++j) {
            for (let i = selectionArea.top; i <= selectionArea.bottom; ++i) {
                if (i >= 0 && j >= 0) {
                    let node = nodes[i * spreadSheetGrid.cols + j];
                    colors.add(
                        node.fontColor ??
                            spreadSheetGrid.colorOptions?.textColor ??
                            defaultColor
                    );
                    sizes.add(
                        node.fontSize ??
                            spreadSheetGrid.colorOptions?.fontSize ??
                            defaultFontSize
                    );
                } else {
                    if (i === -1 && j >= 0) {
                        if (spreadSheetGrid.headers != null) {
                            let title = spreadSheetGrid.headers![j];
                            colors.add(
                                title?.fontColor ??
                                    spreadSheetGrid.colorOptions?.textColor ??
                                    defaultColor
                            );
                            sizes.add(
                                title?.fontSize ??
                                    spreadSheetGrid.colorOptions?.fontSize ??
                                    defaultTitleSize
                            );
                        }
                    }
                    if (j === -1 && i >= 0) {
                        if (spreadSheetGrid.leftHeaders != null) {
                            let title = spreadSheetGrid.leftHeaders![i];
                            colors.add(
                                title?.fontColor ??
                                    spreadSheetGrid.colorOptions?.textColor ??
                                    defaultColor
                            );
                            sizes.add(
                                title?.fontSize ??
                                    spreadSheetGrid.colorOptions?.fontSize ??
                                    defaultTitleSize
                            );
                        }
                    }
                }
            }
        }
        if (sizes.size === 0 && colors.size === 0) return undefined;
        if (sizes.size === 1) fontProps.size = sizes.values().next().value;
        if (colors.size === 1) fontProps.color = colors.values().next().value;
        return fontProps;
    }

    public getTableBySelectionArea(selectionArea: SelectionArea): string {
        let spreadSheetGrid = this.gridsState.get(
            selectionArea.gridId
        ) as CanvasSpreadSheetGrid;
        if (spreadSheetGrid == null) return "";
        let nodes = this.getSortedGridNodes(spreadSheetGrid.id);
        let table: string[][] = [];

        for (let i = selectionArea.top; i <= selectionArea.bottom; ++i) {
            let row: string[] = [];
            for (let j = selectionArea.left; j <= selectionArea.right; ++j) {
                if (i >= 0 && j >= 0) {
                    let node = nodes[i * spreadSheetGrid.cols + j];
                    let columnFormat =
                        spreadSheetGrid.headers?.[j]?.columnFormat;
                    let value = getPrettyPrintFormatValue(node, columnFormat);
                    row.push(value);
                } else {
                    if (i === -1 && j >= 0) {
                        if (spreadSheetGrid.headers != null) {
                            let title = spreadSheetGrid.headers![j];
                            row.push(title.text);
                        }
                    }
                    if (j === -1 && i >= 0) {
                        if (spreadSheetGrid.leftHeaders != null) {
                            let title = spreadSheetGrid.leftHeaders![i];
                            row.push(title.text);
                        }
                    }
                }
            }
            table.push(row);
        }
        let tableHtml = "";
        for (let row of table) {
            let rowHtml = row.map((item) => `<td>${item}</td>`).join("");
            tableHtml += `<tr>${rowHtml}</tr>`;
        }
        tableHtml = `<canvas-sheet><table>${tableHtml}</table></canvas-sheet>`;
        return tableHtml;
    }

    public getCommonFontPropsFromMetadata(
        metadata: { id: number | string; type: string }[],
        defaultTagSize: number,
        defaultFontSize: number,
        defaultColor: string
    ): { size: number | undefined; color: string | undefined } | undefined {
        let fontProps = {
            size: 0,
            color: "#000000",
        };
        if (metadata.length === 0) return undefined;
        let colors = new Set();
        let sizes = new Set();
        for (let item of metadata) {
            switch (item.type) {
                case "canvasTreeState":
                    let node = this.canvasTreeState.get(
                        item.id as number
                    ) as CanvasElement;
                    if (node != null) {
                        colors.add(node.fontColor || defaultColor);
                        sizes.add(node.fontSize || defaultFontSize);
                    }
                    break;
                case "gridsState":
                    let grid = this.gridsState.get(
                        item.id as string
                    ) as CanvasSpreadSheetGrid;
                    this.canvasTreeState.forEach((node) => {
                        if (
                            (isBox(node) || isSimpleSpreadSheetInput(node)) &&
                            node.gridId === item.id
                        ) {
                            sizes.add(
                                node.fontSize ??
                                    grid.colorOptions?.fontSize ??
                                    defaultFontSize
                            );
                            colors.add(
                                node.fontColor ??
                                    grid.colorOptions?.textColor ??
                                    defaultColor
                            );
                        }
                    });
                    if (grid != null && isSpreadSheetGrid(grid)) {
                        let headers = grid.headers;
                        if (headers != null)
                            headers.forEach((header) => {
                                sizes.add(
                                    header.fontSize ??
                                        grid.colorOptions?.fontSize ??
                                        defaultFontSize
                                );
                                colors.add(
                                    header.fontColor ??
                                        grid.colorOptions?.textColor ??
                                        defaultColor
                                );
                            });
                        headers = grid.leftHeaders;
                        if (headers != null)
                            headers.forEach((header) => {
                                sizes.add(
                                    header.fontSize ??
                                        grid.colorOptions?.fontSize ??
                                        defaultFontSize
                                );
                                colors.add(
                                    header.fontColor ??
                                        grid.colorOptions?.textColor ??
                                        defaultColor
                                );
                            });
                    }

                    break;
                case "shapeElementsState":
                    break; // Shapes do not have font properties
                case "pageBar":
                    let pageBarInfo = CurrentModulesStore.getModule(
                        this.moduleId!
                    )?.page_bar_info;

                    if (pageBarInfo != null) {
                        let accordionFormat =
                            this.canvasViewMode === "mobile"
                                ? pageBarInfo?.accordionFormat?.mobile
                                : pageBarInfo?.accordionFormat?.desktop;
                        sizes.add(
                            accordionFormat
                                ? pageBarInfo.accordionFontSize ||
                                      verticalSectionBarFontSize
                                : pageBarInfo.fontSize || defaultFontSize
                        );
                        colors.add(pageBarInfo.fontColor || defaultColor);
                    }
                    break;
                case "slideNumber":
                    let slideNumberOptions = CurrentModulesStore.getModule(
                        this.moduleId!
                    )?.options?.slideNumberOptions;
                    if (slideNumberOptions != null) {
                        sizes.add(slideNumberOptions.fontSize);
                        colors.add(slideNumberOptions.fontColor);
                    }
                    break;
                default:
                    break;
            }
        }
        if (sizes.size === 0 && colors.size === 0) return undefined;
        if (sizes.size === 1) fontProps.size = sizes.values().next().value;
        if (colors.size === 1) fontProps.color = colors.values().next().value;
        return fontProps;
    }

    @action.bound public updateByMetadataAction(
        metadata: { id: number | string; type: string }[],
        props: { fontSize?: number; fontColor?: string }
    ): void {
        let changes: InnerCanvasChanges = {};
        for (let item of metadata) {
            switch (item.type) {
                case "canvasTreeState":
                    let node = this.canvasTreeState.get(item.id as number);
                    if (node && isDropdownSelector(node) && props.fontSize) {
                        const textSize = getTextSize(
                            node.value
                                ? String(node.value)
                                : `Select ${node.variableOption?.label}`,
                            "Roboto",
                            props.fontSize,
                            "600"
                        );
                        if (node.nodeSize) {
                            // 50, 60 - extra values to count empty space between text and container sides
                            const nodeWidth =
                                node.nodeSize[this.canvasViewMode].width - 50;
                            const nodeHeight =
                                node.nodeSize[this.canvasViewMode].height - 50;
                            if (textSize.width > nodeWidth) {
                                node.nodeSize[this.canvasViewMode].width =
                                    textSize.width + 60;
                            }
                            if (textSize.height > nodeHeight) {
                                node.nodeSize[this.canvasViewMode].height =
                                    textSize.height + 50;
                            }
                        }
                    }
                    if (node != null) {
                        this.canvasTreeState.set(item.id as number, {
                            ...node,
                            ...props,
                        });
                        if (changes.canvasTreeState == null)
                            changes.canvasTreeState = {};
                        changes.canvasTreeState[item.id] = {
                            ...node,
                            ...props,
                        };
                    }
                    break;
                case "backgroundsState":
                    break;
                case "buttonsState":
                    break;
                case "gridsState":
                    let grid = this.gridsState.get(item.id as string);
                    if (grid != null) {
                        if (changes.canvasTreeState == null)
                            changes.canvasTreeState = {};
                        this.canvasTreeState.forEach((node) => {
                            if (
                                (isBox(node) ||
                                    isSimpleSpreadSheetInput(node)) &&
                                node.gridId === item.id
                            ) {
                                this.canvasTreeState.set(node.id, {
                                    ...node,
                                    ...props,
                                });
                                changes.canvasTreeState![node.id] = {
                                    ...node,
                                    ...props,
                                };
                            }
                        });
                        if (isSpreadSheetGrid(grid)) {
                            let headers = grid.headers;
                            if (headers != null)
                                headers.forEach((header, index) => {
                                    headers![index] = {
                                        ...header,
                                        ...props,
                                    };
                                });
                            headers = grid.leftHeaders;
                            if (headers != null)
                                headers.forEach((header, index) => {
                                    headers![index] = {
                                        ...header,
                                        ...props,
                                    };
                                });
                            this.gridsState.set(item.id as string, { ...grid });
                            if (changes.gridsState == null)
                                changes.gridsState = {};
                            changes.gridsState[grid.id] = grid;
                        }
                    }
                    break;
                case "pageBar":
                    let pageBarInfo = CurrentModulesStore.getModule(
                        this.moduleId!
                    )?.page_bar_info;
                    if (pageBarInfo != null) {
                        let accordionFormat =
                            this.canvasViewMode === "mobile"
                                ? pageBarInfo?.accordionFormat?.mobile
                                : pageBarInfo?.accordionFormat?.desktop;
                        if (accordionFormat) {
                            CurrentModulesStore.updatePageBar(this.moduleId!, {
                                fontColor: props.fontColor,
                                accordionFontSize: props.fontSize,
                            });
                        } else {
                            CurrentModulesStore.updatePageBar(
                                this.moduleId!,
                                props
                            );
                        }
                    }
                    break;
                case "slideNumber":
                    let slideNumberOptions = CurrentModulesStore.getModule(
                        this.moduleId!
                    )?.options?.slideNumberOptions;
                    if (slideNumberOptions != null) {
                        CurrentModulesStore.updateModuleOptions(
                            this.moduleId!,
                            {
                                slideNumberOptions: {
                                    ...slideNumberOptions,
                                    ...props,
                                },
                            }
                        );
                    }
                    break;
                case "shapeElementsState":
                    break;
                default:
                    break;
            }
        }
        this.saveChangesAction(changes);
    }

    @action.bound public moveByMetadataAction(
        metadata: ItemMetadata[],
        props: {
            x?: number;
            y?: number;
            mobile_x?: number;
            mobile_y?: number;
        }[]
    ): void {
        let newCanvasWidth = 0;
        let newCanvasHeight = 0;
        let changes: InnerCanvasChanges = {};
        for (const [metadataIndex, item] of metadata.entries()) {
            let itemProps = props[metadataIndex];
            if (itemProps.x != null) itemProps.x = Math.max(0, itemProps.x);
            if (itemProps.y != null) itemProps.y = Math.max(0, itemProps.y);
            if (itemProps.mobile_x != null)
                itemProps.mobile_x = Math.max(0, itemProps.mobile_x);
            if (itemProps.mobile_y != null)
                itemProps.mobile_y = Math.max(0, itemProps.mobile_y);
            switch (item.type) {
                case "canvasTreeState":
                    if (changes.canvasTreeState == null)
                        changes.canvasTreeState = {};
                    let node = this.canvasTreeState.get(item.id as number);
                    if (node != null) {
                        this.canvasTreeState.set(item.id as number, {
                            ...node,
                            ...itemProps,
                            nodePosition: {
                                ...node.nodePosition,
                                [this.canvasViewMode]: {
                                    ...node.nodePosition[this.canvasViewMode],
                                    ...itemProps,
                                },
                            },
                        });
                        node = this.canvasTreeState.get(item.id as number)!;
                        changes.canvasTreeState[item.id] = node;
                        let size = getNodeSize(node, this.canvasViewMode)
                            .nodeSize[this.canvasViewMode];
                        newCanvasWidth = Math.max(
                            newCanvasWidth,
                            node.nodePosition[this.canvasViewMode].x +
                                size.width
                        );
                        newCanvasHeight = Math.max(
                            newCanvasHeight,
                            node.nodePosition[this.canvasViewMode].y +
                                size.height
                        );
                    }
                    break;
                case "backgroundsState":
                    let index = this.backgroundsState.findIndex(
                        (background) => background.id === (item.id as number)
                    );
                    if (index < 0) break;
                    this.backgroundsState[index] = {
                        ...this.backgroundsState[index],
                        ...itemProps,
                    };
                    const background = this.backgroundsState[index];

                    const {
                        positionX,
                        positionY,
                        scaleX,
                        scaleY,
                    } = getBackgroundPlacementData(
                        this.canvasViewMode,
                        background
                    );

                    newCanvasWidth = Math.max(
                        newCanvasWidth,
                        positionX + scaleX * (background.natural_width ?? 0)
                    );
                    newCanvasHeight = Math.max(
                        newCanvasHeight,
                        positionY + scaleY * (background.natural_height ?? 0)
                    );
                    break;
                case "gridsState":
                    if (changes.gridsState == null) changes.gridsState = {};
                    let grid = this.gridsState.get(item.id as string);
                    if (grid != null) {
                        this.gridsState.set(item.id as string, {
                            ...grid,
                            ...itemProps,
                            nodePosition: {
                                ...grid.nodePosition,
                                [this.canvasViewMode]: {
                                    ...grid.nodePosition[this.canvasViewMode],
                                    ...itemProps,
                                },
                            },
                        });
                        grid = this.gridsState.get(item.id as string)!;

                        changes.gridsState[item.id] = grid;
                        let size =
                            grid.containerSize ??
                            calculateSpreadSheetGridSize(grid, this);
                        newCanvasWidth = Math.max(
                            newCanvasWidth,
                            grid.x + size[this.canvasViewMode].width
                        );
                        newCanvasHeight = Math.max(
                            newCanvasHeight,
                            grid.y + size[this.canvasViewMode].height
                        );
                    }
                    break;
                case "pageBar":
                    CurrentModulesStore.updatePageBar(
                        this.moduleId!,
                        itemProps
                    );
                    break;
                case "slideNumber":
                    let slideNumberOptions = CurrentModulesStore.getModule(
                        this.moduleId!
                    )?.options?.slideNumberOptions;
                    if (slideNumberOptions != null) {
                        CurrentModulesStore.updateModuleOptions(
                            this.moduleId!,
                            {
                                slideNumberOptions: {
                                    ...slideNumberOptions,
                                    ...itemProps,
                                    nodePosition: {
                                        ...slideNumberOptions.nodePosition,
                                        [this.canvasViewMode]: {
                                            ...slideNumberOptions.nodePosition[
                                                this.canvasViewMode
                                            ],
                                            ...itemProps,
                                        },
                                    },
                                },
                            }
                        );
                    }
                    break;
                default:
                    let objects = this.getObjects();
                    let collection = objects.find(
                        (object) => object.name === item.type
                    );
                    if (changes[item.type] == null) changes[item.type] = {};
                    if (collection != null) {
                        let collectionRef = collection.ref;
                        let element = collectionRef.get(item.id);
                        if (element != null) {
                            collectionRef.set(item.id, {
                                ...collectionRef.get(item.id),
                                ...itemProps,
                                nodePosition: {
                                    ...element.nodePosition,
                                    [this.canvasViewMode]: {
                                        ...element.nodePosition[
                                            this.canvasViewMode
                                        ],
                                        ...itemProps,
                                    },
                                },
                            });
                            element = collectionRef.get(item.id);
                            changes[item.type]![item.id] = element;
                            newCanvasWidth = Math.max(
                                newCanvasWidth,
                                element.x + element.width
                            );
                            newCanvasHeight = Math.max(
                                newCanvasHeight,
                                element.y + element.height
                            );
                        }
                    }
                    break;
            }
        }
        this.updateCanvasSizeAction({
            x: 0,
            y: 0,
            width: newCanvasWidth,
            height: newCanvasHeight,
        });
        this.saveChangesAction(
            changes,
            true,
            true,
            false,
            this.backgroundsState.toJSON(),
            BackgroundMode.Update
        );
    }

    @action.bound public groupByMetadataAction(
        metadata: ItemMetadata[],
        group: boolean
    ): string | null {
        let changes: InnerCanvasChanges = {};
        let groupId = group ? nanoid() : null;
        for (const item of metadata.values()) {
            switch (item.type) {
                case "backgroundsState":
                    let index = this.backgroundsState.findIndex(
                        (background) => background.id === (item.id as number)
                    );
                    if (index < 0) break;
                    this.backgroundsState[index] = {
                        ...this.backgroundsState[index],
                        group_id: groupId,
                    };
                    break;
                case "pageBar":
                    break;
                case "slideNumber":
                    break;
                default:
                    let objects = this.getObjects();
                    let collection = objects.find(
                        (object) => object.name === item.type
                    );
                    if (changes[item.type] == null) changes[item.type] = {};
                    if (collection != null) {
                        let collectionRef = collection.ref;
                        if (collectionRef.get(item.id) != null) {
                            collectionRef.set(item.id, {
                                ...collectionRef.get(item.id),
                                groupId: groupId,
                            });
                            changes[item.type]![item.id] = collectionRef.get(
                                item.id
                            );
                        }
                    }
                    break;
            }
        }
        this.saveChangesAction(
            changes,
            true,
            true,
            false,
            this.backgroundsState.toJSON(),
            BackgroundMode.Update
        );
        return groupId;
    }

    @action.bound public resizeByMetadataAction(
        metadata: ItemMetadata[],
        props: {
            oldHeight: number;
            newHeight: number;
            oldWidth: number;
            newWidth: number;
        }[]
    ): void {
        let changes: InnerCanvasChanges = {};
        for (const [metadataIndex, item] of metadata.entries()) {
            switch (item.type) {
                case "canvasTreeState":
                    if (changes.canvasTreeState == null)
                        changes.canvasTreeState = {};
                    let node = this.canvasTreeState.get(item.id as number);
                    if (node != null) {
                        if (isTextBox(node) || isDropdownSelector(node)) {
                            this.canvasTreeState.set(
                                item.id as number,
                                {
                                    ...node,
                                    nodeSize: {
                                        ...node.nodeSize,
                                        [this.canvasViewMode]: {
                                            width:
                                                props[metadataIndex].newWidth,
                                            height:
                                                props[metadataIndex].newHeight,
                                        },
                                    },
                                } as CanvasTextBox | CanvasDropdownSelector
                            );
                            changes.canvasTreeState[item.id] = {
                                ...node,
                                nodeSize: {
                                    ...node.nodeSize,
                                    [this.canvasViewMode]: {
                                        width: props[metadataIndex].newWidth,
                                        height: props[metadataIndex].newHeight,
                                    },
                                },
                            } as CanvasTextBox | CanvasDropdownSelector;
                        } else {
                            let shapeOptions = node.shapeOptions ?? {
                                desktop: {},
                                mobile: {},
                            };
                            let oldScaleX =
                                shapeOptions[this.canvasViewMode]?.scaleX ?? 1;
                            let oldScaleY =
                                shapeOptions[this.canvasViewMode]?.scaleY ?? 1;
                            shapeOptions = {
                                ...shapeOptions,
                                [this.canvasViewMode]: {
                                    scaleX:
                                        (oldScaleX *
                                            props[metadataIndex].newWidth) /
                                        props[metadataIndex].oldWidth,
                                    scaleY:
                                        (oldScaleY *
                                            props[metadataIndex].newHeight) /
                                        props[metadataIndex].oldHeight,
                                },
                            };
                            this.canvasTreeState.set(item.id as number, {
                                ...node,
                                shapeOptions: shapeOptions,
                            });
                            changes.canvasTreeState[item.id] = {
                                ...node,
                                shapeOptions: shapeOptions,
                            };
                        }
                    }
                    break;
                case "backgroundsState":
                    let index = this.backgroundsState.findIndex(
                        (background) => background.id === (item.id as number)
                    );
                    if (index < 0) break;
                    let background = this.backgroundsState[index];

                    const { scaleX, scaleY } = getBackgroundPlacementData(
                        this.canvasViewMode,
                        background
                    );
                    const isCanvasDesktopView =
                        this.canvasViewMode === "desktop";

                    let oldScaleX = scaleX;
                    let oldScaleY = scaleY;
                    let newScaleX =
                        (oldScaleX * props[metadataIndex].newWidth) /
                        props[metadataIndex].oldWidth;
                    let newScaleY =
                        (oldScaleY * props[metadataIndex].newHeight) /
                        props[metadataIndex].oldHeight;

                    const scaleChanges = isCanvasDesktopView
                        ? {
                              scale_x: newScaleX,
                              scale_y: newScaleY,
                          }
                        : {
                              mobile_scale_x: newScaleX,
                              mobile_scale_y: newScaleY,
                          };

                    this.backgroundsState[index] = {
                        ...this.backgroundsState[index],
                        ...scaleChanges,
                    };
                    break;
                case "gridsState":
                    if (changes.gridsState == null) changes.gridsState = {};
                    let grid = this.gridsState.get(item.id as string);
                    if (grid != null) {
                        let containerSize =
                            grid.containerSize ??
                            calculateSpreadSheetGridSize(grid, this);
                        containerSize[this.canvasViewMode].width =
                            props[metadataIndex].newWidth;
                        containerSize[this.canvasViewMode].height =
                            props[metadataIndex].newHeight;
                        this.gridsState.set(item.id as string, {
                            ...grid,
                            containerSize: containerSize,
                        });
                        changes.gridsState[item.id] = {
                            ...grid,
                            containerSize: containerSize,
                        };
                    }
                    break;
                case "pageBar":
                    CurrentModulesStore.updatePageBar(this.moduleId!, {
                        width: props[metadataIndex].newWidth,
                        height: props[metadataIndex].newHeight,
                    });
                    break;
                case "slideNumber":
                    let slideNumberOptions = CurrentModulesStore.getModule(
                        this.moduleId!
                    )?.options?.slideNumberOptions;
                    if (slideNumberOptions != null) {
                        CurrentModulesStore.updateModuleOptions(
                            this.moduleId!,
                            {
                                slideNumberOptions: {
                                    ...slideNumberOptions,
                                    nodeSize: {
                                        ...slideNumberOptions.nodeSize,
                                        [this.canvasViewMode]: {
                                            width:
                                                props[metadataIndex].newWidth,
                                            height:
                                                props[metadataIndex].newHeight,
                                        },
                                    },
                                },
                            }
                        );
                    }
                    break;

                default:
                    let objects = this.getObjects();
                    let collection = objects.find(
                        (object) => object.name === item.type
                    );
                    if (changes[item.type] == null) changes[item.type] = {};
                    if (collection != null) {
                        let collectionRef = collection.ref;
                        let element = collectionRef.get(item.id);
                        if (element != null) {
                            let elementChanges = {
                                width: props[metadataIndex].newWidth,
                                height: props[metadataIndex].newHeight,
                            };
                            let newElement = {
                                ...element,
                                ...elementChanges,
                                nodeSize: {
                                    ...element.nodeSize,
                                    [this.canvasViewMode]: {
                                        width: elementChanges.width,
                                        height: elementChanges.height,
                                    },
                                },
                            };
                            collectionRef.set(item.id, newElement);
                            changes[item.type]![item.id] = newElement;
                        }
                    }
                    break;
            }
        }
        this.saveChangesAction(
            changes,
            true,
            true,
            false,
            this.backgroundsState.toJSON(),
            BackgroundMode.Update
        );
    }

    @action.bound public getRectByMetadataAction(item: {
        id: number | string;
        type: string;
    }): IComputedValue<{
        x: number;
        y: number;
        width: number;
        height: number;
        allowResize: boolean;
    }> {
        return computed(() => {
            switch (item.type) {
                case "canvasTreeState":
                    let node = this.canvasTreeState.get(item.id as number);
                    if (node != null) {
                        const size = getNodeSize(node, this.canvasViewMode);
                        return {
                            ...size.nodeSize[this.canvasViewMode],
                            allowResize: size.allowResize,
                            x: node.nodePosition[this.canvasViewMode].x,
                            y: node.nodePosition[this.canvasViewMode].y,
                        };
                    }
                    break;
                case "backgroundsState":
                    let background = this.backgroundsState.find(
                        (background) => background.id === (item.id as number)
                    );

                    if (background != null) {
                        const {
                            positionX,
                            positionY,
                            scaleX,
                            scaleY,
                            translationX,
                            translationY,
                        } = getBackgroundPlacementData(
                            this.canvasViewMode,
                            background
                        );

                        return {
                            width: scaleX * (background.natural_width ?? 10),
                            height: scaleY * (background.natural_height ?? 10),
                            x: positionX + translationX,
                            y: positionY + translationY,
                            allowResize: true,
                        };
                    }
                    break;
                case "buttonsState":
                    let button = this.buttonsState.get(item.id as string);
                    if (button != null)
                        return {
                            x: button.nodePosition[this.canvasViewMode].x,
                            y: button.nodePosition[this.canvasViewMode].y,
                            width: button.nodeSize[this.canvasViewMode].width,
                            height: button.nodeSize[this.canvasViewMode].height,
                            allowResize: false,
                        };
                    break;
                case "pageBar":
                    let pageBarInfo = CurrentModulesStore.getModule(
                        this.moduleId!
                    )?.page_bar_info;
                    if (pageBarInfo != null) {
                        return {
                            x: pageBarInfo.x,
                            y: pageBarInfo.y,
                            width: pageBarInfo.width,
                            height: pageBarInfo.height,
                            allowResize: true,
                        };
                    }
                    break;
                case "slideNumber":
                    let slideNumberOptions = CurrentModulesStore.getModule(
                        this.moduleId!
                    )?.options?.slideNumberOptions;
                    if (slideNumberOptions != null) {
                        return {
                            x:
                                slideNumberOptions.nodePosition[
                                    this.canvasViewMode
                                ].x,
                            y:
                                slideNumberOptions.nodePosition[
                                    this.canvasViewMode
                                ].y,
                            width:
                                slideNumberOptions.nodeSize[this.canvasViewMode]
                                    .width,
                            height:
                                slideNumberOptions.nodeSize[this.canvasViewMode]
                                    .height,
                            allowResize: true,
                        };
                    }
                    break;
                case "gridsState":
                    let grid = this.gridsState.get(item.id as string);
                    if (grid != null) {
                        if (grid.containerSize != null) {
                            return {
                                ...grid.containerSize[this.canvasViewMode],
                                ...grid.nodePosition[this.canvasViewMode],
                                allowResize: true,
                            };
                        } else {
                            let spreadSheetContainerSize = calculateSpreadSheetGridSize(
                                grid,
                                this
                            );
                            return {
                                ...spreadSheetContainerSize[
                                    this.canvasViewMode
                                ],
                                ...grid.nodePosition[this.canvasViewMode],
                                allowResize: true,
                            };
                        }
                    }
                    break;
                case "shapeState":
                    let element = this.shapeElementsState.get(
                        item.id as string
                    );
                    if (element != null) {
                        let position =
                            element.nodePosition[this.canvasViewMode];
                        position.x =
                            position.x +
                            (element.nodeTranslation?.[this.canvasViewMode].x ??
                                0);
                        position.y =
                            position.y +
                            (element.nodeTranslation?.[this.canvasViewMode].y ??
                                0);

                        return {
                            ...position,
                            ...element.nodeSize[this.canvasViewMode],
                            allowResize: true,
                        };
                    }

                    break;
                default:
                    let objects = this.getObjects();
                    let collection = objects.find(
                        (object) => object.name === item.type
                    );
                    if (collection != null) {
                        let collectionRef = collection.ref;
                        let element = collectionRef.get(item.id);
                        if (element != null)
                            return {
                                ...element.nodePosition[this.canvasViewMode],
                                ...element.nodeSize[this.canvasViewMode],
                                allowResize: true,
                            };
                    }
                    break;
            }
            return { width: 0, height: 0, x: 0, y: 0, allowResize: false };
        });
    }

    @action.bound public shiftByMetadataAction(
        metadata: ItemMetadata[],
        props: { deltaX: number; deltaY: number },
        skipHistory?: boolean,
        // If propagatedChanges is set, then updateNodeAction does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let newCanvasWidth = 0;
        let newCanvasHeight = 0;
        let changes: InnerCanvasChanges = propagatedChanges ?? {};
        for (let item of metadata) {
            switch (item.type) {
                case "canvasTreeState":
                    if (changes.canvasTreeState == null)
                        changes.canvasTreeState = {};
                    if (this.canvasTreeState.get(item.id as number) != null) {
                        let node = this.canvasTreeState.get(item.id as number)!;
                        if (node == null) continue;
                        let newNode = {
                            ...node,
                            nodePosition: {
                                ...node.nodePosition,
                                [this.canvasViewMode]: {
                                    x: Math.max(
                                        0,
                                        node.nodePosition[this.canvasViewMode]
                                            .x + props.deltaX
                                    ),
                                    y: Math.max(
                                        0,
                                        node.nodePosition[this.canvasViewMode]
                                            .y + props.deltaY
                                    ),
                                },
                            },
                        };
                        this.canvasTreeState.set(item.id as number, newNode);
                        changes.canvasTreeState[item.id] = newNode;
                        let size = getNodeSize(newNode, this.canvasViewMode)
                            .nodeSize[this.canvasViewMode];
                        newCanvasWidth = Math.max(
                            newCanvasWidth,
                            newNode.nodePosition[this.canvasViewMode].x +
                                size.width
                        );
                        newCanvasHeight = Math.max(
                            newCanvasHeight,
                            newNode.nodePosition[this.canvasViewMode].y +
                                size.height
                        );
                    }
                    break;
                case "backgroundsState":
                    let index = this.backgroundsState.findIndex(
                        (background) => background.id === (item.id as number)
                    );
                    if (index < 0) break;
                    const {
                        positionX,
                        positionY,
                        scaleX,
                        scaleY,
                    } = getBackgroundPlacementData(
                        this.canvasViewMode,
                        this.backgroundsState[index]
                    );

                    this.backgroundsState[index] = {
                        ...this.backgroundsState[index],
                        x: Math.max(0, positionX + props.deltaX),
                        y: Math.max(0, positionY + props.deltaY),
                    };
                    newCanvasWidth = Math.max(
                        newCanvasWidth,
                        positionX +
                            scaleX *
                                (this.backgroundsState[index].natural_width ??
                                    0)
                    );
                    newCanvasHeight = Math.max(
                        newCanvasHeight,
                        positionY +
                            +scaleY *
                                (this.backgroundsState[index].natural_height ??
                                    0)
                    );

                    break;

                case "gridsState":
                    if (changes.gridsState == null) changes.gridsState = {};
                    let grid = this.gridsState.get(item.id as string);
                    if (grid == null) continue;
                    let newGrid = {
                        ...grid,
                        nodePosition: {
                            ...grid.nodePosition,
                            [this.canvasViewMode]: {
                                x: Math.max(
                                    0,
                                    grid.nodePosition[this.canvasViewMode].x +
                                        props.deltaX
                                ),
                                y: Math.max(
                                    0,
                                    grid.nodePosition[this.canvasViewMode].y +
                                        props.deltaY
                                ),
                            },
                        },
                    };
                    this.gridsState.set(item.id as string, newGrid);
                    changes.gridsState[item.id] = newGrid;
                    let size =
                        newGrid.containerSize ??
                        calculateSpreadSheetGridSize(newGrid, this);

                    newCanvasWidth = Math.max(
                        newCanvasWidth,
                        newGrid.nodePosition[this.canvasViewMode].x +
                            size[this.canvasViewMode].width
                    );
                    newCanvasHeight = Math.max(
                        newCanvasHeight,
                        newGrid.nodePosition[this.canvasViewMode].y +
                            size[this.canvasViewMode].height
                    );
                    break;
                case "pageBar":
                    let pageBarInfo = CurrentModulesStore.getModule(
                        this.moduleId!
                    )?.page_bar_info;
                    if (pageBarInfo != null && pageBarInfo.unlocked) {
                        let itemProps = {
                            x:
                                pageBarInfo.nodePosition[this.canvasViewMode]
                                    .x + props.deltaX,
                            y:
                                pageBarInfo.nodePosition[this.canvasViewMode]
                                    .y + props.deltaY,
                        };
                        CurrentModulesStore.updatePageBar(
                            this.moduleId!,
                            itemProps
                        );
                    }
                    break;
                case "slideNumber":
                    let slideNumberOptions = CurrentModulesStore.getModule(
                        this.moduleId!
                    )?.options?.slideNumberOptions;
                    if (slideNumberOptions != null) {
                        CurrentModulesStore.updateModuleOptions(
                            this.moduleId!,
                            {
                                slideNumberOptions: {
                                    ...slideNumberOptions,
                                    nodePosition: {
                                        ...slideNumberOptions.nodePosition,
                                        [this.canvasViewMode]: {
                                            x:
                                                slideNumberOptions.nodePosition[
                                                    this.canvasViewMode
                                                ].x + props.deltaX,
                                            y:
                                                slideNumberOptions.nodePosition[
                                                    this.canvasViewMode
                                                ].y + props.deltaY,
                                        },
                                    },
                                },
                            }
                        );
                    }
                    break;
                default:
                    let objects = this.getObjects();
                    let collection = objects.find(
                        (object) => object.name === item.type
                    );
                    if (changes[item.type] == null) changes[item.type] = {};
                    if (collection != null) {
                        let collectionRef = collection.ref;
                        let element = collectionRef.get(item.id);
                        if (element != null) {
                            let newElement = {
                                ...element,
                                nodePosition: {
                                    ...element.nodePosition,
                                    [this.canvasViewMode]: {
                                        x: Math.max(
                                            0,
                                            element.nodePosition[
                                                this.canvasViewMode
                                            ].x + props.deltaX
                                        ),
                                        y: Math.max(
                                            0,
                                            element.nodePosition[
                                                this.canvasViewMode
                                            ].y + props.deltaY
                                        ),
                                    },
                                },
                            };
                            collectionRef.set(item.id, newElement);
                            changes[item.type]![item.id] = newElement;
                            newCanvasWidth = Math.max(
                                newCanvasWidth,
                                newElement.nodePosition[this.canvasViewMode].x +
                                    newElement.nodeSize[this.canvasViewMode]
                                        .width
                            );
                            newCanvasHeight = Math.max(
                                newCanvasHeight,
                                newElement.nodePosition[this.canvasViewMode].y +
                                    newElement.nodeSize[this.canvasViewMode]
                                        .height
                            );
                        }
                    }
                    break;
            }
        }
        this.updateCanvasSizeAction({
            x: 0,
            y: 0,
            width: newCanvasWidth,
            height: newCanvasHeight,
        });

        if (propagatedChanges == null) {
            this.saveChangesAction(
                changes,
                true,
                true,
                false,
                this.backgroundsState.toJSON(),
                BackgroundMode.Update,
                skipHistory
            );
        }
    }

    /*!
     * determineTraversalOrder defines order of node traversing. It returns list of output ids
     * and detects possible cycles
     * @param outputGraph Dictionary where each outputId is mapped into list of its input outputIds
     * @param nodeByOuterId Structure for fast extraction of node by outerId
     * @param sharedChildrenByOuterId Dictionary where each outerId is mapped
     * into set of shared(linked) and global identifiers
     */
    @action.bound private determineTraversalOrderAction(
        outputGraph: { [outputId: string]: string[] },
        nodeByOuterId: { [outerId: string]: CanvasNode },
        sharedChildrenByOuterId: { [outerId: string]: Set<string> },
        optionalCanvasTreeArgs?: CanvasTreeStoreInner["canvasTreeArgs"]
    ): string[] {
        // Node was visited at some point
        const canvasTreeArgs = optionalCanvasTreeArgs ?? this.canvasTreeArgs;
        let visited: { [outputId: string]: boolean } = {};
        // Node was visited during this iteration
        let visitedNow: { [outputId: string]: boolean } = {};
        // outputIds of nodes that we need to traverse
        let traversalOrder: string[] = [];
        // Returns true if the node is not in a cycle and false otherwise
        const detectCyclesInner = (outputId: string): boolean => {
            let outerId = extractOuterId(outputId);
            let nodeId = nodeByOuterId[outerId].id;
            visited[outputId] = true;
            visitedNow[outputId] = true;
            let notInCycle: boolean = true;
            const children: string[] = outputGraph[outputId];
            for (const childOutputId of children) {
                const childId: string = extractOuterId(childOutputId);
                if (
                    !(childOutputId in outputGraph) &&
                    !sharedChildrenByOuterId[outerId].has(childId) &&
                    !canvasTreeArgs[nodeId]?.result.hasOwnProperty(
                        childOutputId
                    )
                ) {
                    let [outerId, outputIndex] = extractOuterIdAndIndex(
                        outputId
                    );
                    let node = nodeByOuterId[outerId!];
                    if (isBox(node) || isTextBox(node)) {
                        if (outputIndex === "1") node.value = NaN;
                        else
                            node.additionalOutputs[
                                Number(outputIndex) - 2
                            ].value = NaN;
                    }
                    if (!optionalCanvasTreeArgs)
                        this.canvasTreeParseErrorsState.set(
                            nodeByOuterId[outerId!].id,
                            `Parsing error: output ${outputIndex}: undefined variable ${childOutputId}`
                        );
                    outputGraph[outputId] = [];
                    return true;
                }
            }
            for (const childOutputId of children) {
                const childId: string = extractOuterId(childOutputId);
                // Shared nodes and args don't have children, so we don't
                // need to visit them
                if (
                    !sharedChildrenByOuterId[outerId].has(childId) &&
                    !canvasTreeArgs[nodeId]?.result.hasOwnProperty(
                        childOutputId
                    )
                ) {
                    if (!visited[childOutputId]) {
                        notInCycle =
                            notInCycle && detectCyclesInner(childOutputId);
                    } else if (visitedNow[childOutputId]) {
                        // if the node was visited during a different iteration,
                        // then it's not a cycle
                        notInCycle = true;
                    }
                }
            }
            if (notInCycle) {
                traversalOrder.push(outputId);
            } else {
                let [outerId, outputIndex] = extractOuterIdAndIndex(outputId);
                let node = nodeByOuterId[outerId!];
                if (isBox(node) || isTextBox(node)) {
                    if (outputIndex === "1") node.value = NaN;
                    else
                        node.additionalOutputs[
                            Number(outputIndex) - 2
                        ].value = NaN;
                }
                if (!optionalCanvasTreeArgs)
                    this.canvasTreeParseErrorsState.set(
                        nodeByOuterId[outerId!].id,
                        `Output ${outputIndex} of this card is in a cycle`
                    );
            }
            return notInCycle;
        };
        Object.keys(outputGraph).forEach((outputId: string) => {
            visitedNow = {};
            if (!visited[outputId]) detectCyclesInner(outputId);
        });
        return traversalOrder;
    }

    /*!
     * parseAndCalculateOutput function parses expression in
     * an output with outputId of node
     * If expression is not a formula it will be calculated, else
     * outer ids of inputs will be added into output graph,
     * expression will be added into expressions dictionary
     * @param node Input node
     * @param nodeByOuterId Structure for fast extraction of node by outerId
     * @param sharedChildrenByOuterId Dictionary where each outerId is mapping
     * into set of shared(linked) and global identifiers
     * @param outputId Identificator of output of node
     * @param expressions Output parameter. It returns dictionary
     * where each outputId is mapped into parsed expression of this outputId
     * @param outputGraph Output parameter. It returns dictionary
     * where each outputId is mapped into list of its input outputIds
     * @param changes Output parameter. Keeps changes of each node
     */
    @action.bound private parseAndCalculateOutputAction(
        node:
            | CanvasTextBox
            | CanvasElement
            | CanvasSlider
            | CanvasSimpleSpreadSheetInput,
        nodeByOuterId: { [outerId: string]: CanvasNode },
        sharedChildrenByOuterId: { [outerId: string]: Set<string> },
        outputId: string,
        expressions: { [outputId: string]: Expression | null },
        outputGraph: { [outputId: string]: string[] },
        changes: CanvasTreeValueChanges,
        optionalCanvasTreeArgs?: CanvasTreeStoreInner["canvasTreeArgs"]
    ): void {
        const canvasTreeArgs = optionalCanvasTreeArgs ?? this.canvasTreeArgs;
        let [outerId, outputIndex] = extractOuterIdAndIndex(outputId);
        outputIndex = outputIndex ?? "1";
        try {
            let output: { metric: string; value: NodeValue };
            if (outputIndex === "1" || isSimpleSpreadSheetInput(node)) {
                output = node;
            } else if (isSlider(node) || isProgressElement(node)) {
                if (outputIndex === "min") output = node.minOutput;
                else if (outputIndex === "max") output = node.maxOutput;
                else if (isProgressElement(node) && outputIndex === "error")
                    output = node.errorOutput;
                else {
                    if (isProgressElement(node) && outputIndex === "1") {
                        output = node;
                    } else {
                        console.log(
                            `Slider: invalid output index: ${outputIndex}`
                        );
                        return;
                    }
                }
            } else {
                output = node.additionalOutputs[Number(outputIndex) - 2];
            }
            let expr: Expression | null;
            if (
                output.metric.startsWith("=") &&
                output.metric.substring(1).trim().length > 0
            )
                expr = Parser.parse(output.metric.substring(1).toLowerCase());
            else {
                expr = null;
                // Number("") returns 0, which is not the desired behavior
                let oldValue = output.value;
                if (output.metric.length === 0) output.value = NaN;
                else if (
                    isSimpleSpreadSheetInput(node) &&
                    isDateFormat(
                        (this.gridsState.get(
                            node.gridId
                        ) as CanvasSpreadSheetGrid)?.headers?.[node.x!]
                            ?.columnFormat
                    )
                ) {
                    // We should convert dates to a number since the %Y format
                    // (year) can be converted to a number that is then
                    // interpreted as a timestamp. We have to set it to NaN
                    // since otherwise editing wouldn't work because strptime
                    // wouldn't get called later.
                    // https://eisengardai.atlassian.net/browse/EIS-411?focusedCommentId=12752
                    output.value = NaN;
                } else {
                    output.value = Number(output.metric);
                }
                if (oldValue !== output.value) {
                    changes[node.id] = node;
                }
            }
            expressions[outputId] = expr;
            if (expr != null) {
                let hasUndefinedVariable: boolean = false;
                for (let childId of expr.variables()) {
                    let childOutputId = childId;
                    childId = extractOuterId(childId);
                    let childNode = nodeByOuterId[childId];
                    if (
                        childNode == null &&
                        !sharedChildrenByOuterId[outerId].has(childId) &&
                        !canvasTreeArgs[node.id]?.result.hasOwnProperty(
                            childOutputId
                        )
                    ) {
                        let oldValue = output.value;
                        output.value = NaN;
                        if (oldValue !== output.value) {
                            changes[node.id] = node;
                        }
                        if (!optionalCanvasTreeArgs)
                            this.canvasTreeParseErrorsState.set(
                                node.id,
                                `Parsing error: output ${outputIndex}: undefined variable ${childId}`
                            );
                        outputGraph[outputId] = [];
                        hasUndefinedVariable = true;
                        break;
                    }
                }
                if (hasUndefinedVariable) {
                    expressions[outputId] = null;
                    outputGraph[outputId] = [];
                } else {
                    outputGraph[outputId] = [];
                    for (let name of expr.variables()) {
                        name = name.toLowerCase();
                        // We do not need to add "_1" to data table variables
                        let [, index] = extractOuterIdAndIndex(name);
                        if (
                            !canvasTreeArgs[node.id]?.result.hasOwnProperty(
                                name
                            ) &&
                            index == null
                        ) {
                            let childNode = nodeByOuterId[name] as
                                | CanvasNode
                                | undefined;
                            // A text box name without index refers to the whole
                            // contents
                            if (childNode == null || isTextBox(childNode)) {
                                outputGraph[outputId].push(name);
                            } else {
                                outputGraph[outputId].push(name.concat("_1"));
                            }
                        } else {
                            outputGraph[outputId].push(name);
                        }
                    }
                    // remove duplicates
                    outputGraph[outputId] = Array.from(
                        new Set(outputGraph[outputId])
                    );
                }
            } else {
                outputGraph[outputId] = [];
            }

            // A text box name without index refers to the whole
            // contents
            if (outputIndex === "1" && isTextBox(node)) {
                outputGraph[outerId] = [`${outerId}_${outputIndex}`];
                for (let i = 0; i < node.additionalOutputs.length; ++i) {
                    outputGraph[outerId].push(`${outerId}_${i + 2}`);
                }
            }
        } catch (exception) {
            console.log(exception);
            expressions[outputId] = null;
            outputGraph[outputId] = [];
            if (node.metric.length !== 0 && !optionalCanvasTreeArgs) {
                this.canvasTreeParseErrorsState.set(
                    node.id,
                    `Parsing error: output ${outputIndex}: ${
                        exception.message ??
                        "Could not parse the expression in output " +
                            outputIndex
                    }`
                );
            }
        }
    }

    /*!
     * calculateValues
     * This function calculates all expressions for all outputs for
     * all nodes for canvasTreeState
     * It returns dictionary where key of dictionary
     * is a node identifier, value of dictionary is a changes of the node
     */

    @action.bound public calculateValuesAction(
        optionalCanvasTreeState?: Map<number, CanvasNode>,
        optionalCanvasTreeArgs?: CanvasTreeStoreInner["canvasTreeArgs"]
    ): CanvasTreeValueChanges {
        let changes: {
            [key: number]: CanvasNode;
        } = {};
        if (!optionalCanvasTreeState) this.canvasTreeParseErrorsState.clear();
        // Parse all expressions
        // outputId has form "A1_1", "A1_2", etc.
        let expressions: { [outputId: string]: Expression | null } = {};
        // Directed graph (as adjacency list) where each output is its own node.
        let outputGraph: { [outputId: string]: string[] } = {};
        let nodeByOuterId: { [outerId: string]: CanvasNode } = {};

        // sharedClidrenByOuterId is a mapping between outer identifier of node and
        // shared(linked) and global identifiers needed by it.
        let sharedChildrenByOuterId: { [outerId: string]: Set<string> } = {};
        const canvasTreeState = optionalCanvasTreeState ?? this.canvasTreeState;
        const canvasTreeArgs = optionalCanvasTreeArgs ?? this.canvasTreeArgs;
        canvasTreeState.forEach((node) => {
            const outerId: string = node.outerId.toLowerCase();
            nodeByOuterId[outerId] = node;
            let outerIdsFrequency: { [outerId: string]: number } = {};
            let currentOuterIdNumber: { [outerId: string]: number } = {};
            let allChildrenSharedIds = node.childrenSharedIds;
            let allGlobalIds = node.globalInputIds ?? [];

            // Each additional output of text box can have its own global inputs or shared inputs
            if (isTextBox(node)) {
                allChildrenSharedIds = allChildrenSharedIds.concat(
                    node.additionalOutputs
                        ?.map((output) => output.childrenSharedIds ?? [])
                        .flat()
                );
                allGlobalIds = allGlobalIds.concat(
                    node.additionalOutputs
                        ?.map((output) => output.globalInputIds ?? [])
                        .flat()
                );
            }
            allChildrenSharedIds = allChildrenSharedIds.filter(
                (v, i, a) =>
                    a.findIndex(
                        (t) => t.label === v.label && t.value === v.value
                    ) === i
            );
            for (let sharedChildId of allChildrenSharedIds) {
                const sharedBox = SharedBoxesStore.getBox(sharedChildId.value);
                if (sharedBox == null) continue;
                let outerId =
                    sharedChildId.label.toLowerCase() ||
                    sharedBox.box.outerId.toLowerCase();
                outerIdsFrequency[outerId] =
                    outerIdsFrequency[outerId] != null
                        ? outerIdsFrequency[outerId] + 1
                        : 1;
                currentOuterIdNumber[outerId] = 1;
            }

            sharedChildrenByOuterId[outerId] = new Set<string>();
            allChildrenSharedIds.forEach((childId) => {
                let sharedBox = SharedBoxesStore.getBox(childId.value);
                if (sharedBox != null) {
                    let sharedOuterId =
                        childId.label.toLowerCase() ||
                        sharedBox.box.outerId.toLowerCase();

                    if (outerIdsFrequency[sharedOuterId] > 1) {
                        sharedChildrenByOuterId[outerId].add(
                            `${sharedOuterId}_${currentOuterIdNumber[sharedOuterId]}`
                        );
                        sharedChildrenByOuterId[outerId].add(sharedOuterId);
                        currentOuterIdNumber[sharedOuterId] += 1;
                    } else {
                        sharedChildrenByOuterId[outerId].add(sharedOuterId);
                    }
                }
            });
            allGlobalIds.forEach((input) => {
                sharedChildrenByOuterId[outerId].add(input.label.toLowerCase());
            });
        });
        canvasTreeState.forEach((node) => {
            let outputId: string = `${node.outerId}_1`.toLowerCase();
            if (
                isTextBox(node) ||
                isBox(node) ||
                isSimpleSpreadSheetInput(node)
            ) {
                // Here we parse each node and calculate it or change output graph
                this.parseAndCalculateOutputAction(
                    node,
                    nodeByOuterId,
                    sharedChildrenByOuterId,
                    outputId,
                    expressions,
                    outputGraph,
                    changes,
                    canvasTreeArgs
                );
            } else if (isSlider(node) || isProgressElement(node)) {
                let minOutput = `${node.outerId.toLowerCase()}_min`;
                let maxOutput = `${node.outerId.toLowerCase()}_max`;
                // Slide has two special outputs - min and max
                let errorOutput = `${node.outerId.toLowerCase()}_error`;

                if (isProgressElement(node)) {
                    this.parseAndCalculateOutputAction(
                        node,
                        nodeByOuterId,
                        sharedChildrenByOuterId,
                        outputId,
                        expressions,
                        outputGraph,
                        changes,
                        canvasTreeArgs
                    );
                    this.parseAndCalculateOutputAction(
                        node,
                        nodeByOuterId,
                        sharedChildrenByOuterId,
                        errorOutput,
                        expressions,
                        outputGraph,
                        changes,
                        canvasTreeArgs
                    );
                } else {
                    if (hasAdditionalOutputs(node)) {
                        expressions[outputId] = null;
                        outputGraph[outputId] = [];
                        for (let index in node.additionalOutputs) {
                            let optionalOutputId = `${node.outerId.toLowerCase()}_${
                                Number(index) + 2
                            }`;
                            expressions[optionalOutputId] = null;
                            outputGraph[optionalOutputId] = [];
                        }
                    }
                }
                this.parseAndCalculateOutputAction(
                    node,
                    nodeByOuterId,
                    sharedChildrenByOuterId,
                    minOutput,
                    expressions,
                    outputGraph,
                    changes,
                    canvasTreeArgs
                );
                this.parseAndCalculateOutputAction(
                    node,
                    nodeByOuterId,
                    sharedChildrenByOuterId,
                    maxOutput,
                    expressions,
                    outputGraph,
                    changes,
                    canvasTreeArgs
                );
                // Manually add the slider value
                if (isSlider(node)) {
                    expressions[outputId] = null;
                    outputGraph[outputId] = [minOutput, maxOutput];
                } else if (isProgressElement(node)) {
                    outputGraph[outputId] = outputGraph[outputId].concat([
                        minOutput,
                        maxOutput,
                        errorOutput,
                    ]);
                }
            } else {
                expressions[outputId] = null;
                outputGraph[outputId] = [];
                if (isDropdownSelector(node)) {
                    if (hasAdditionalOutputs(node)) {
                        for (let index in node.additionalOutputs) {
                            let optionalOutputId = `${node.outerId.toLowerCase()}_${
                                Number(index) + 2
                            }`;
                            expressions[optionalOutputId] = null;
                            outputGraph[optionalOutputId] = [];
                        }
                    }
                }
            }
            if (isBox(node) || isTextBox(node)) {
                node.additionalOutputs.forEach((_output, index) => {
                    outputId = `${node.outerId}_${index + 2}`.toLowerCase();
                    //Here we parse additional outputs of node and calculate it or change output graph
                    this.parseAndCalculateOutputAction(
                        node as CanvasElement,
                        nodeByOuterId,
                        sharedChildrenByOuterId,
                        outputId,
                        expressions,
                        outputGraph,
                        changes,
                        canvasTreeArgs
                    );
                });
            }
        });
        //Here we define traversal order via outputGraph
        const traversalOrder: string[] = this.determineTraversalOrderAction(
            outputGraph,
            nodeByOuterId,
            sharedChildrenByOuterId,
            canvasTreeArgs
        );
        // args is a dictionary with identifiers of inputs and values
        // It is filled during calculations in traversal order
        let args: { [key: string]: NodeValue } = {};

        // Formats are also traversing in a such way that args. For example, if
        // some of input nodes has date format, then output node will also have
        // date format
        let formats: { [key: string]: ColumnFormat | undefined | null } = {};
        for (const outputId of traversalOrder) {
            let [outerId, outputIndex] = extractOuterIdAndIndex(outputId);
            if (outerId! in nodeByOuterId) {
                // if it's not in nodeByOuterId, then it's a shared box
                if (
                    isInput(nodeByOuterId[outerId!]) ||
                    isTextBox(nodeByOuterId[outerId!]) ||
                    isBox(nodeByOuterId[outerId!]) ||
                    isSlider(nodeByOuterId[outerId!]) ||
                    isSimpleSpreadSheetInput(nodeByOuterId[outerId!]) ||
                    isProgressElement(nodeByOuterId[outerId!])
                ) {
                    let inputs = outputGraph[outputId];
                    //If it is box that can have an inputs then we call calculateValue function
                    this.calculateValueAction(
                        nodeByOuterId[outerId!] as
                            | CanvasElement
                            | CanvasSlider
                            | CanvasSimpleSpreadSheetInput,
                        outputIndex!,
                        expressions[outputId],
                        args,
                        formats,
                        inputs,
                        changes,
                        nodeByOuterId,
                        canvasTreeArgs
                    );
                } else {
                    let node = nodeByOuterId[outerId!];
                    if (node.metric.startsWith("=")) {
                        args[outerId!] = node.value;
                        args[outputId] = node.value;
                    } else {
                        const parsed: number = Number(node.value);
                        if (node.metric.length !== 0 && !isNaN(parsed)) {
                            args[outerId!] = parsed;
                            args[outputId] = parsed;
                        } else {
                            args[outerId!] = node.metric;
                            args[outputId] = node.metric;
                        }
                    }
                    if (isDropdownSelector(node)) {
                        let format = node.format;
                        formats[outerId!] = format;
                        formats[outputId] = format;
                        args[outerId!] = node.value;
                    }
                }
            }
        }
        for (let [key, value] of Object.entries(changes)) {
            if (isTextBox(value) && value.rawMetric != null) {
                value.rawMetric = generateRawMetric(value, value.rawMetric);
            }
            canvasTreeState.set(Number(key), { ...value });
        }
        return changes;
    }

    /*!
     * Calculate value of outputId of node
     * @param node Input node
     * @param outputIndex Index of output of node
     * @param expr Expression parsed in this output
     * @param args Input-output parameter. Dictionary of previously
     * calculated values
     * @param formats Input-output parameter. Dictionary of previously
     * calculated formats
     * @param inputs List of input outputIds for this output of node
     * @param changes Output parameter. Keeps changes of each node
     */
    @action.bound private calculateValueAction(
        node: CanvasElement | CanvasSlider | CanvasSimpleSpreadSheetInput,
        outputIndex: string | undefined,
        expr: Expression | null,
        args: { [key: string]: NodeValue },
        formats: { [key: string]: ColumnFormat | undefined | null },
        inputs: string[],
        changes: CanvasTreeValueChanges,
        nodeByOuterId: { [outerId: string]: CanvasNode },
        optionalCanvasTreeArgs?: CanvasTreeStoreInner["canvasTreeArgs"]
    ): void {
        if (outputIndex == null) {
            // A text box name without index refers to the whole
            // contents
            if (isTextBox(node) && node.rawMetric != null) {
                let rawMetric = generateRawMetric(node, node.rawMetric);
                if (rawMetric != null) {
                    let value: string | number = EditorState.createWithContent(
                        convertFromRaw(rawMetric)
                    )
                        .getCurrentContent()
                        .getPlainText();
                    if (StringUtils.isNumber(value)) {
                        value = Number(value);
                    }
                    args[node.outerId.toLowerCase()] = value;
                }
            }
            // return in either case
            return;
        }

        const canvasTreeArgs = optionalCanvasTreeArgs ?? this.canvasTreeArgs;
        const outputId: string = node.outerId
            .toLowerCase()
            .concat(`_${outputIndex}`);
        let inputArgs: { [key: string]: NodeValue | null } = args;
        let inputFormats: {
            [key: string]: ColumnFormat | undefined | null;
        } = formats;

        let output: {
            metric: string;
            value: NodeValue;
            format?: ColumnFormat | null;
        };
        let allChildrenSharedIds = node.childrenSharedIds;
        let allGlobalIds = node.globalInputIds ?? [];
        if (isTextBox(node)) {
            allChildrenSharedIds = allChildrenSharedIds.concat(
                node.additionalOutputs
                    ?.map((output) => output.childrenSharedIds ?? [])
                    .flat()
            );
            allGlobalIds = allGlobalIds.concat(
                node.additionalOutputs
                    ?.map((output) => output.globalInputIds ?? [])
                    .flat()
            );
        }
        allChildrenSharedIds = allChildrenSharedIds.filter(
            (v, i, a) =>
                a.findIndex(
                    (t) => t.label === v.label && t.value === v.value
                ) === i
        );

        if (allChildrenSharedIds.length !== 0 || allGlobalIds.length !== 0) {
            inputArgs = Object.assign({}, args);
            // Fill input args by global inputs of this node
            allGlobalIds.forEach((input) => {
                inputArgs[input.label.toLowerCase()] = getGlobalInputValue(
                    input.value,
                    true
                );
                inputFormats[input.label.toLowerCase()] = getGlobalInputFormat(
                    input.value
                );
                inputFormats[
                    `${input.label.toLowerCase()}_1`
                ] = getGlobalInputFormat(input.value);
            });
            let outerIdsFrequency: { [outerId: string]: number } = {};
            let currentOuterIdNumber: { [outerId: string]: number } = {};
            for (let sharedChildId of allChildrenSharedIds) {
                const sharedBox = SharedBoxesStore.getBox(sharedChildId.value);
                if (sharedBox == null) continue;
                let outerId =
                    sharedChildId.label.toLowerCase() ||
                    sharedBox.box.outerId.toLowerCase();
                outerIdsFrequency[outerId] =
                    outerIdsFrequency[outerId] != null
                        ? outerIdsFrequency[outerId] + 1
                        : 1;
                currentOuterIdNumber[outerId] = 1;
            }
            // Fill input args by shared(linked) inputs of this node
            for (let sharedChildId of allChildrenSharedIds) {
                const sharedBox = SharedBoxesStore.getBox(sharedChildId.value);
                if (sharedBox == null) continue;
                const originalOuterId =
                    sharedChildId.label.toLowerCase() ||
                    sharedBox.box.outerId.toLowerCase();
                let outerId: string = originalOuterId;
                if (outerIdsFrequency[originalOuterId] > 1) {
                    outerId = `${originalOuterId}_${currentOuterIdNumber[originalOuterId]}`;
                    currentOuterIdNumber[originalOuterId] += 1;
                }
                let value: NodeValue | undefined = getOutputTargetValue(
                    sharedBox.box
                );
                if (value != null) {
                    if (
                        isTextBox(sharedBox.box) &&
                        sharedBox.box.rawMetric != null
                    ) {
                        let rawMetric = generateRawMetric(
                            sharedBox.box,
                            sharedBox.box.rawMetric
                        );
                        if (rawMetric != null) {
                            let value:
                                | string
                                | number = EditorState.createWithContent(
                                convertFromRaw(rawMetric)
                            )
                                .getCurrentContent()
                                .getPlainText();
                            if (StringUtils.isNumber(value)) {
                                value = Number(value);
                            }
                            inputArgs[outerId] = value;
                        }
                    } else {
                        inputArgs[outerId] = value;
                    }
                    inputArgs[`${outerId}_1`] = value;
                    if (
                        isDropdownSelector(sharedBox.box) ||
                        isBox(sharedBox.box) ||
                        isTextBox(sharedBox.box)
                    ) {
                        inputFormats[outerId] = sharedBox.box.format;
                        inputFormats[`${outerId}_1`] = sharedBox.box.format;
                    }
                }
                if (hasAdditionalOutputs(sharedBox.box)) {
                    for (
                        let i = 2;
                        i < sharedBox.box.additionalOutputs.length + 2;
                        ++i
                    ) {
                        value = getOutputTargetValue(
                            sharedBox.box.additionalOutputs[i - 2]
                        );
                        let format = undefined;
                        if (
                            isDropdownSelector(sharedBox.box) ||
                            isBox(sharedBox.box) ||
                            isTextBox(sharedBox.box)
                        ) {
                            format =
                                sharedBox.box.additionalOutputs[i - 2].format;
                        }
                        if (value != null) {
                            inputArgs[`${outerId}_${i}`] = value;
                            inputFormats[`${outerId}_${i}`] = format;
                        }
                    }
                } else if (isSlider(sharedBox.box)) {
                    value = getOutputTargetValue(sharedBox.box.minOutput);
                    if (value != null) inputArgs[`${outerId}_min`] = value;
                    value = getOutputTargetValue(sharedBox.box.maxOutput);
                    if (value != null) inputArgs[`${outerId}_max`] = value;
                }
            }
        }
        if (isSlider(node)) {
            if (outputIndex === "min") output = node.minOutput;
            else if (outputIndex === "max") output = node.maxOutput;
            else {
                // "1"
                // We need to make sure that the value of the slider is between
                // min and max.
                // Min and max were already calculated by now since we added
                // the edges in the output graph
                let min = args[`${node.outerId.toLowerCase()}_min`] as number;
                let max = args[`${node.outerId.toLowerCase()}_max`] as number;
                if (max < min) [max, min] = [min, max];
                let prevValue = node.value;
                if (typeof min === "string" || typeof max === "string")
                    node.value = NaN;
                else {
                    node.value = Math.max(node.value as number, min);
                    node.value = Math.min(node.value as number, max);
                }
                if (prevValue !== node.value) {
                    changes[node.id] = node;
                }
                let value = node.value;
                if (outputIndex === "1") {
                    args[node.outerId.toLowerCase()] = value;
                    args[`${node.outerId.toLowerCase()}_1`] = value;
                } else {
                    if (node.additionalOutputs) {
                        value = Number(
                            node.additionalOutputs[Number(outputIndex) - 2]
                                .value
                        );
                        args[
                            `${node.outerId.toLowerCase()}_${outputIndex}`
                        ] = value;
                    }
                }

                return;
            }
        } else if (isProgressElement(node)) {
            if (outputIndex === "min") output = node.minOutput;
            else if (outputIndex === "max") output = node.maxOutput;
            else if (outputIndex === "error") output = node.errorOutput;
            else output = node;
        } else if (outputIndex === "1" || isSimpleSpreadSheetInput(node))
            output = node;
        else output = node.additionalOutputs[Number(outputIndex) - 2];

        if (!output.metric.startsWith("=")) {
            const parsed: number = Number(output.metric);
            if (output.metric.length !== 0 && !isNaN(parsed))
                args[outputId] = parsed;
            else args[outputId] = output.metric;
            if (outputIndex === "1")
                args[node.outerId.toLowerCase()] = args[outputId];

            if (isSimpleSpreadSheetInput(node) || isInput(node)) {
                let format;
                if (isSimpleSpreadSheetInput(node)) {
                    let grid = this.gridsState.get(
                        node.gridId
                    )! as CanvasSpreadSheetGrid;
                    format = grid?.headers?.[node.x!]?.columnFormat;
                } else {
                    if ("format" in node) format = node.format;
                }
                if (isDateFormat(format)) {
                    // We don't need to recalculate the value if it has
                    // already been calculated
                    if (output.value == null || Number.isNaN(output.value)) {
                        let date = strptime(
                            format.format,
                            String(output.metric),
                            // Spreadsheets display dates in UTC, and
                            // inputs - in local timezone.
                            // This has to be true for spreadsheets and false
                            // for inputs, or it would cause this issue:
                            // https://eisengardai.atlassian.net/browse/EIS-164?focusedCommentId=11288
                            isSimpleSpreadSheetInput(node)
                        );
                        if (date != null) output.value = date.getTime() / 1000;
                        else output.value = NaN;
                    }
                    args[node.outerId.toLowerCase()] = output.value;
                    changes[node.id] = node;
                }
                if (isNumberFormat(format)) {
                    output.value = parseNumber(output.metric);
                    args[node.outerId.toLowerCase()] = output.value;

                    changes[node.id] = node;
                }
                formats[outputId] = format;
            }
            return;
        }
        if (expr == null) {
            args[outputId] = output.value;
            if (outputIndex === "1")
                args[node.outerId.toLowerCase()] = output.value;
            return;
        }

        if (!isSimpleSpreadSheetInput(node)) {
            if (canvasTreeArgs[node.id] != null) {
                inputArgs = {
                    ...inputArgs,
                    ...canvasTreeArgs[node.id]!.result,
                };
                for (let i in node.dataTableInputDetails ?? []) {
                    if (
                        canvasTreeArgs[node.id]!.new_update_times.length !== 0
                    ) {
                        node.dataTableInputDetails![
                            i
                        ].update_time = canvasTreeArgs[
                            node.id
                        ]!.new_update_times[i];
                    }
                }
            } else {
                args[outputId] = output.value;
                if (outputIndex === "1")
                    args[node.outerId.toLowerCase()] = output.value;
            }
        }
        let nulls: string[] = [];
        for (let variable of expr.variables()) {
            if (inputArgs[variable] == null) nulls.push(variable.toUpperCase());
        }
        let oldValue = output.value;
        let oldFormat = { ...output.format };
        if (nulls.length !== 0) {
            output.value = NaN;
            if (!optionalCanvasTreeArgs)
                this.canvasTreeParseErrorsState.set(
                    node.id,
                    `Output ${outputIndex}: values of the following variables are NULL: ${nulls.join(
                        ", "
                    )}`
                );
        } else {
            // Here we are calculating value of output by input args
            let calculatedValue = expr.evaluate(inputArgs as Value);
            // Show 'ALL' text in text field if 'ALL' option in dropdown was chosen
            const nodeOuterId = node.metric
                .trim()
                .replace("=", "")
                .toLowerCase();
            if (
                isTextBox(node) &&
                nodeOuterId in nodeByOuterId &&
                isDropdownSelector(nodeByOuterId[nodeOuterId]) &&
                Array.isArray(args[nodeOuterId]) &&
                outputIndex === "1"
            ) {
                output.value = ["ALL"];
            } else {
                output.value = calculatedValue;
            }
            if (
                isSimpleSpreadSheetInput(node) ||
                isBox(node) ||
                (isTextBox(node) && output.format == null)
            ) {
                // If node hasn't format we use first format of its inputs
                let usedFormats = inputs
                    .map((inputId) => inputFormats[inputId])
                    .filter((format) => format != null);
                output.format = usedFormats[0] ?? null;
            }
            if (output.format != null) {
                formats[outputId] = output.format;
            }
            // If column of spreadsheet has format we use this format
            if (isSimpleSpreadSheetInput(node)) {
                let grid = this.gridsState.get(
                    node.gridId
                )! as CanvasSpreadSheetGrid;
                let format = grid?.headers?.[node.x!]?.columnFormat;
                formats[outputId] = format ?? formats[outputId];
            }

            args[outputId] = calculatedValue;
            if (outputIndex === "1")
                args[node.outerId.toLowerCase()] = calculatedValue;
        }
        if (!_.isEqual(oldFormat, output.format) || oldValue !== output.value) {
            changes[node.id] = node;
        }
    }

    @action.bound public updateNodeAction<
        T extends CanvasNode,
        K extends keyof T
    >(
        nodeId: number,
        props: Pick<T, K>,
        recalculate: boolean = true,
        textBoxDiff: boolean = false,
        skipUpdate?: boolean,
        // If propagatedChanges is set, then updateNodeAction does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): boolean {
        let node = this.canvasTreeState.get(nodeId);
        if (node == null) return false;
        let newNode: CanvasNode = {
            ...node,
            ...props,
        };
        let rawMetricDelta: RawDraftContentState | undefined | null = undefined;
        if (textBoxDiff && isTextBox(node) && isTextBox(newNode)) {
            if ("rawMetric" in props && node.rawMetric != null) {
                let originalMetric = generateRawMetric(
                    node,
                    node.rawMetric,
                    true
                )!;
                let newMetric = generateRawMetric(
                    newNode,
                    newNode.rawMetric!,
                    true
                )!;

                let blocksDiff = jsonDiffPatchBlocks.diff(
                    originalMetric.blocks ?? [],
                    newMetric.blocks ?? []
                );
                let entityMapDiff = jsonDiffPatchEntityMap.diff(
                    originalMetric.entityMap ?? {},
                    newMetric.entityMap ?? {}
                );
                rawMetricDelta = {
                    blocks: blocksDiff,
                    entityMap: entityMapDiff,
                };
            }
        } else if (isTextBox(node) && isTextBox(newNode)) {
            rawMetricDelta = null;
        }
        let canvasId = this.canvasId;

        // Connect or disconnect data sets
        let dataSetsToDelete = new Set<number | string>(
            this.extractDataScopeIdsFromNode(node)
        );
        let dataSetsToAdd = new Set<number | string>();
        for (let dataScopeId of this.extractDataScopeIdsFromNode(newNode)) {
            if (dataSetsToDelete.has(dataScopeId)) {
                dataSetsToDelete.delete(dataScopeId);
            } else {
                dataSetsToAdd.add(dataScopeId);
            }
        }

        this.canvasTreeState.set(nodeId, newNode);

        for (let dataScopeId of dataSetsToDelete) {
            this.onDataSetDisconnected(dataScopeId);
        }
        for (let dataScopeId of dataSetsToAdd) {
            this.onDataSetConnected(dataScopeId);
        }

        if (skipUpdate) return true;
        if ("dataTableInputDetails" in props) {
            // We have to call saveChangesAction and can't propagate changes
            // here. Not doing so would break things.
            this.updateArgsAsyncAction
                .bothParts(true, this.canvasTreeState)
                .then(() => {
                    if (recalculate) {
                        let changes = this.calculateValuesAction();
                        let canvasTreeStateChanges: InnerCanvasChanges = {
                            canvasTreeState: {
                                [nodeId]: newNode,
                                ...changes,
                            },
                        };
                        if (
                            rawMetricDelta != null &&
                            canvasTreeStateChanges.canvasTreeState != null
                        ) {
                            (canvasTreeStateChanges.canvasTreeState[
                                nodeId
                            ] as CanvasTextBox).lastRawMetricDelta = rawMetricDelta;
                        }
                        if (canvasId !== this.canvasId) return;
                        this.saveChangesAction({
                            ...canvasTreeStateChanges,
                        });
                        this.updateNodesByLinkedInputsAction(
                            canvasTreeStateChanges
                        );
                    } else {
                        this.saveChangesAction({
                            canvasTreeState: {
                                [nodeId]: newNode,
                            },
                        });
                    }
                });
        } else if (recalculate) {
            let changes = this.calculateValuesAction();
            let canvasTreeStateChanges: InnerCanvasChanges =
                propagatedChanges ?? {};
            canvasTreeStateChanges.canvasTreeState = {
                ...canvasTreeStateChanges.canvasTreeState,
                [nodeId]: newNode,
                ...changes,
            };
            if (
                rawMetricDelta != null &&
                canvasTreeStateChanges.canvasTreeState != null
            ) {
                (canvasTreeStateChanges.canvasTreeState[
                    nodeId
                ] as CanvasTextBox).lastRawMetricDelta = rawMetricDelta;
            }
            if (propagatedChanges == null) {
                this.saveChangesAction({
                    ...canvasTreeStateChanges,
                });
                this.updateNodesByLinkedInputsAction(canvasTreeStateChanges);
            }
        } else {
            let canvasTreeStateChanges: InnerCanvasChanges =
                propagatedChanges ?? {};
            canvasTreeStateChanges.canvasTreeState = {
                ...canvasTreeStateChanges.canvasTreeState,
                [nodeId]: newNode,
            };
            if (
                rawMetricDelta != null &&
                canvasTreeStateChanges.canvasTreeState != null
            ) {
                (canvasTreeStateChanges.canvasTreeState[
                    nodeId
                ] as CanvasTextBox).lastRawMetricDelta = rawMetricDelta;
            }
            if (propagatedChanges == null) {
                this.saveChangesAction({
                    ...canvasTreeStateChanges,
                });
            }
        }
        return true;
    }

    @action.bound public updateNodesAction<T extends CanvasNode>(
        changes: { [nodeId: number]: Partial<T> },
        recalculate: boolean = true
    ): boolean {
        let canvasId = this.canvasId;
        let updateArgs = false;
        let saveChanges: { [nodeId: number]: CanvasNode } = {};
        let dataSetsToDelete = new Set<number | string>();
        let dataSetsToAdd = new Set<number | string>();
        for (const [nodeStringId, props] of Object.entries(changes)) {
            let nodeId: number = Number(nodeStringId);
            let node = this.canvasTreeState.get(nodeId);
            if (node == null) return false;
            let newNode = {
                ...node,
                ...props,
            };
            // Connect or disconnect data sets
            for (let dataScopeId of this.extractDataScopeIdsFromNode(node)) {
                dataSetsToDelete.add(dataScopeId);
            }
            for (let dataScopeId of this.extractDataScopeIdsFromNode(newNode)) {
                dataSetsToAdd.add(dataScopeId);
            }

            this.canvasTreeState.set(nodeId, newNode);
            saveChanges[node.id] = newNode;
            if ("dataTableInputDetails" in props) updateArgs = true;
        }
        for (let dataScopeId of dataSetsToAdd) {
            if (dataSetsToDelete.has(dataScopeId)) {
                dataSetsToDelete.delete(dataScopeId);
                dataSetsToAdd.delete(dataScopeId);
            }
        }
        for (let dataScopeId of dataSetsToDelete) {
            this.onDataSetDisconnected(dataScopeId);
        }
        for (let dataScopeId of dataSetsToAdd) {
            this.onDataSetConnected(dataScopeId);
        }
        if (updateArgs) {
            this.updateArgsAsyncAction
                .bothParts(true, this.canvasTreeState)
                .then(() => {
                    if (canvasId !== this.canvasId) return;
                    if (recalculate) {
                        let calculationChanges = this.calculateValuesAction();
                        let canvasTreeStateChanges = {
                            canvasTreeState: {
                                ...saveChanges,
                                ...calculationChanges,
                            },
                        };
                        this.saveChangesAction({
                            ...canvasTreeStateChanges,
                        });
                        this.updateNodesByLinkedInputsAction(
                            canvasTreeStateChanges
                        );
                    } else {
                        this.saveChangesAction({
                            canvasTreeState: saveChanges,
                        });
                    }
                });
        } else if (recalculate) {
            let calculationChanges = this.calculateValuesAction();
            let canvasTreeStateChanges = {
                canvasTreeState: {
                    ...saveChanges,
                    ...calculationChanges,
                },
            };
            this.saveChangesAction({
                ...canvasTreeStateChanges,
            });
            this.updateNodesByLinkedInputsAction(canvasTreeStateChanges);
        } else {
            this.saveChangesAction({
                canvasTreeState: saveChanges,
            });
        }
        return true;
    }

    @action.bound public updateGridAction<
        T extends CanvasGrid,
        K extends keyof T
    >(
        gridId: string,
        props: Pick<T, K>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): boolean {
        let grid = this.gridsState.get(gridId)!;
        let newGrid = {
            ...grid,
            ...props,
        };
        this.gridsState.set(gridId, newGrid);
        if (
            grid.fullSpreadSheetBackendOutputOptions?.dataScopeId !==
            newGrid.fullSpreadSheetBackendOutputOptions?.dataScopeId
        ) {
            if (grid.fullSpreadSheetBackendOutputOptions?.dataScopeId != null) {
                this.onDataSetDisconnected(
                    grid.fullSpreadSheetBackendOutputOptions.dataScopeId
                );
            }
            if (
                newGrid.fullSpreadSheetBackendOutputOptions?.dataScopeId != null
            ) {
                this.onDataSetConnected(
                    newGrid.fullSpreadSheetBackendOutputOptions.dataScopeId
                );
            }
        }
        if (propagatedChanges == null) {
            this.saveChangesAction({
                gridsState: {
                    [gridId]: newGrid,
                },
            });
        } else {
            if (propagatedChanges.gridsState == null) {
                propagatedChanges.gridsState = {};
            }
            propagatedChanges.gridsState[gridId] = newGrid;
        }
        return true;
    }

    @action.bound public updateGridHeaderNodeAction<
        T extends keyof CanvasGridHeader
    >(gridId: string, col: number, props: Pick<CanvasGridHeader, T>): boolean {
        let grid = this.gridsState.get(gridId)!;
        if (isSpreadSheetGrid(grid)) {
            let headers: CanvasGridHeader[] =
                grid.headers ?? new Array(grid.cols).fill({ text: "" });
            headers[col] = { ...headers[col], ...props };
            grid = {
                ...grid,
                headers: headers,
            } as CanvasSpreadSheetGrid;
            if (
                ("text" in props || "columnFormat" in props) &&
                grid.fullSpreadSheetBackendOutputOptions?.subsetInfo != null
            ) {
                let variableIndex =
                    grid.fullSpreadSheetBackendOutputOptions.subsetInfo
                        .variableIndices[col];

                let operation: EditVariableOperation = {
                    type: OperationType.EditVariable,
                    variableIndex: variableIndex,
                    format: headers[col].columnFormat,
                    name: headers[col].text,
                };
                pushTableChange(grid, operation);
            }
            this.gridsState.set(gridId, grid);
            this.saveChangesAction({
                gridsState: {
                    [gridId]: grid,
                },
            });
        }
        return true;
    }

    @action.bound public updateGridLeftHeaderNodeAction<
        T extends keyof CanvasGridHeader
    >(gridId: string, row: number, props: Pick<CanvasGridHeader, T>): boolean {
        let grid = this.gridsState.get(gridId)!;
        if (isSpreadSheetGrid(grid)) {
            let leftHeaders =
                grid.leftHeaders ?? new Array(grid.rows).fill({ text: "" });
            leftHeaders[row] = { ...leftHeaders[row], ...props };
            grid = {
                ...grid,
                leftHeaders: leftHeaders,
            } as CanvasSpreadSheetGrid;
            this.gridsState.set(gridId, grid);
            this.saveChangesAction({
                gridsState: {
                    [gridId]: grid,
                },
            });
        }
        return true;
    }

    @action.bound public deletePopupAction(
        nodeId: number,
        popup: CanvasPopupElement
    ): void {
        let node = this.canvasTreeState.get(nodeId)!;
        if (!isBox(node)) return;
        node = {
            ...node,
            popups: node.popups.filter((value) => value.hash !== popup.hash),
        } as CanvasElement;
        this.canvasTreeState.set(nodeId, node);
        this.saveChangesAction({
            canvasTreeState: {
                [nodeId]: node,
            },
        });
    }

    @action.bound public updateDelegateAction(
        nodeId: number,
        delegate: UserInfo
    ): boolean {
        let node = this.canvasTreeState.get(nodeId)!;
        if (!isBox(node)) return false;
        node = {
            ...node,
            delegate: delegate.id,
        } as CanvasElement;
        this.canvasTreeState.set(nodeId, node);
        this.saveChangesAction({
            canvasTreeState: {
                [nodeId]: node,
            },
        });

        this.connectedUsersState.set(delegate.id, delegate);
        return true;
    }

    @action.bound public setDelegateAction(delegate: UserInfo) {
        this.connectedUsersState.set(delegate.id, delegate);
    }

    @action.bound public deleteDelegateAction(nodeId: number): boolean {
        let node = this.canvasTreeState.get(nodeId)!;
        if (!isBox(node)) return false;
        node = { ...node } as CanvasElement;
        delete (node as CanvasElement).delegate;
        this.canvasTreeState.set(nodeId, node);
        this.saveChangesAction({
            canvasTreeState: {
                [nodeId]: node,
            },
        });
        return true;
    }

    @action.bound public addEmptyDashboardAction<
        T extends keyof CanvasDashboard
    >(props: Pick<CanvasDashboard, T>): string | undefined {
        let dashboard = {
            nodePosition: {
                desktop: {
                    x: 100,
                    y: 100,
                },
                mobile: {
                    x: 100,
                    y: 100,
                },
            },
            nodeSize: {
                desktop: {
                    width: 400,
                    height: 200,
                },
                mobile: {
                    width: 400,
                    height: 200,
                },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            ...props,
        };
        let dashboardId = nanoid();
        this.dashboardsState.set(dashboardId, dashboard);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: dashboardId,
                type: "dashboardsState",
            });
            dashboard = this.dashboardsState.get(
                dashboardId
            )! as CanvasDashboard;
        }
        this.updateCanvasSizeAction({
            x: dashboard.nodePosition[this.canvasViewMode].x,
            y: dashboard.nodePosition[this.canvasViewMode].y,
            width: dashboard.nodeSize[this.canvasViewMode].width,
            height: dashboard.nodeSize[this.canvasViewMode].height,
        });
        this.moveFront(dashboardId, "dashboardsState");
        this.saveChangesAction({
            dashboardsState: {
                [dashboardId]: dashboard,
            },
        });
        return dashboardId;
    }

    @action.bound public deleteDashboardAction(dashboardId: string) {
        let dashboard = this.dashboardsState.get(dashboardId);
        this.dashboardsState.delete(dashboardId);
        if (dashboard?.finding?.config?.dataScope?.value != null) {
            this.onDataSetDisconnected(
                dashboard.finding.config.dataScope.value
            );
        }
        this.saveChangesAction({
            dashboardsState: {
                [dashboardId]: null,
            },
        });
    }

    @action.bound public deleteEdgeAction(
        parentId: number,
        childrenId: number,
        targetPosition: any,
        sourcePosition: any
    ): void {
        let parentNode = this.canvasTreeState.get(parentId)!;
        let childNode = this.canvasTreeState.get(childrenId)!;

        parentNode = {
            ...parentNode,
            childrenIds: [
                ...parentNode.childrenIds.filter((id) => id !== childrenId),
                ...parentNode.childrenIds
                    .filter((id) => id === childrenId)
                    .slice(1),
            ],
        };
        childNode = {
            ...childNode,
            parentIds: childNode?.parentIds.filter((parent) => {
                if (
                    parent.id === parentId &&
                    parent.childPosition === +ArrowPosition[sourcePosition] &&
                    parent.parentPosition === +ArrowPosition[targetPosition]
                )
                    return false;

                return true;
            }),
        };

        this.canvasTreeState.set(parentId, parentNode);
        this.canvasTreeState.set(childrenId, childNode);
        this.saveChangesAction({
            canvasTreeState: {
                [parentId]: parentNode,
                [childrenId]: childNode,
            },
        });
    }

    @action.bound public addEdgeAction(
        parentId: number,
        childrenId: number,
        parentPosition: ArrowPosition,
        childPosition: ArrowPosition
    ): void {
        let parentNode = this.canvasTreeState.get(parentId)!;
        let childNode = this.canvasTreeState.get(childrenId)!;
        let element = parentNode.parentIds.find((x) => x.id === childrenId);

        /* test if there is already a connecting arrow,
            if true turn it into a 2 sided arrow */
        if (
            element !== null &&
            element?.parentPosition === childPosition &&
            element?.childPosition === parentPosition
        ) {
            let edge = parentNode.parentIds.find((x) => x.id === childrenId);
            if (edge) {
                parentNode = {
                    ...parentNode,
                    parentIds: [
                        ...parentNode.parentIds.filter(
                            (x) => x.id !== childrenId
                        ),
                        {
                            id: edge.id,
                            childPosition: edge.childPosition,
                            parentPosition: edge.parentPosition,
                            arrowDoubleSided: true,
                        },
                    ],
                };
                this.canvasTreeState.set(parentId, parentNode);
                this.saveChangesAction({
                    canvasTreeState: {
                        [parentId]: parentNode,
                    },
                });
            }
        } else {
            parentNode = {
                ...parentNode,
                childrenIds: [...parentNode.childrenIds, childrenId],
            };
            childNode = {
                ...childNode,
                parentIds: [
                    ...childNode.parentIds,
                    {
                        id: parentId,
                        parentPosition: parentPosition,
                        childPosition: childPosition,
                    },
                ],
            };
            this.canvasTreeState.set(parentId, parentNode);
            this.canvasTreeState.set(childrenId, childNode);
            this.saveChangesAction({
                canvasTreeState: {
                    [parentId]: parentNode,
                    [childrenId]: childNode,
                },
            });
        }
    }

    @action.bound public updateSlideHeightAction(newHeight: number): void {
        this.slideHeight[this.canvasViewMode] = newHeight;
        this.canvasSizeState.height = Math.max(
            newHeight,
            this.slideHeight[this.canvasViewMode] + 50
        );
        this.saveChangesAction({
            slideHeight: {
                ...this.slideHeight,
                [this.canvasViewMode]: newHeight,
            },
        });
    }

    @action.bound public updateSlideColorAction(color: string): void {
        this.slideColor = color;
        this.saveChangesAction({ slideColor: color });
    }

    @action.bound public updateHidePagesBarAction(hidePagesBar: boolean): void {
        this.hidePagesBar = hidePagesBar;
        this.saveChangesAction({ hidePagesBar: hidePagesBar });
    }

    @action.bound public updateDashboardAction<T extends keyof CanvasDashboard>(
        dashboardId: string,
        props: Pick<CanvasDashboard, T>,
        skipUpdate?: boolean,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let dashboard = this.dashboardsState.get(dashboardId);
        if (dashboard == null) return;
        let newDashboard = {
            ...dashboard,
            ...props,
        };
        let oldDataScopeId = dashboard.finding?.config?.dataScope?.value;
        let newDataScopeId = newDashboard.finding?.config?.dataScope?.value;
        this.dashboardsState.set(dashboardId, newDashboard);
        if (oldDataScopeId !== newDataScopeId) {
            if (oldDataScopeId != null) {
                this.onDataSetDisconnected(oldDataScopeId);
            }
            if (newDataScopeId != null) {
                this.onDataSetConnected(newDataScopeId);
            }
        }
        if (skipUpdate) return;
        if (propagatedChanges == null) {
            this.saveChangesAction({
                dashboardsState: {
                    [dashboardId]: newDashboard,
                },
            });
        } else {
            if (propagatedChanges.dashboardsState == null) {
                propagatedChanges.dashboardsState = {};
            }
            propagatedChanges.dashboardsState[dashboardId] = newDashboard;
        }
    }

    private static conditionContainsSharedBox(
        condition: Condition,
        sharedBoxId: number
    ): boolean {
        if (
            !isMultiCondition(condition) &&
            condition.isInput &&
            condition.value != null &&
            (condition.value as NodeLinkOption).isCloneInput &&
            (condition.value as NodeLinkOption).value === sharedBoxId
        ) {
            return true;
        }
        if (
            isMultiCondition(condition) &&
            condition.isInput &&
            condition.value != null &&
            Array.isArray(condition.value) &&
            condition.value.length > 0
        ) {
            for (let index in condition.value) {
                let subValue = condition.value[index];
                if (
                    subValue != null &&
                    (subValue as NodeLinkOption).isCloneInput &&
                    (subValue as NodeLinkOption).value === sharedBoxId
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    private static statusExpressionContainsSharedBox(
        statusExpression:
            | StatusExpression
            | NotificationExpression
            | PrintExpression,
        sharedBoxId: number
    ): boolean {
        for (let subexpression of statusExpression.subexpressions) {
            if (
                subexpression.isInput &&
                subexpression.value != null &&
                (subexpression.value as NodeLinkOption).isCloneInput &&
                (subexpression.value as NodeLinkOption).value === sharedBoxId
            ) {
                return true;
            }
        }
        return false;
    }

    @action.bound public deleteNodeAction(nodeId: number): void {
        let node = this.canvasTreeState.get(nodeId)!;
        for (let childId of node.childrenIds) {
            let childNode = this.canvasTreeState.get(childId);
            if (childNode != null) {
                childNode = {
                    ...childNode,
                    parentIds: childNode.parentIds.filter(
                        (parent) => parent.id !== nodeId
                    ),
                };
                this.canvasTreeState.set(childId, childNode);
            }
        }
        for (let parent of node.parentIds) {
            let parentNode = this.canvasTreeState.get(parent.id)!;
            parentNode = {
                ...parentNode,
                childrenIds: parentNode.childrenIds.filter(
                    (id) => id !== nodeId
                ),
            };
            this.canvasTreeState.set(parent.id, parentNode);
        }
        let sharedId = node.sharedId;
        let dataSetsToDelete = this.extractDataScopeIdsFromNode(node);
        this.canvasTreeState.delete(nodeId);
        for (let dataScopeId of dataSetsToDelete) {
            this.onDataSetDisconnected(dataScopeId);
        }
        let changes = this.calculateValuesAction();
        if (sharedId != null) {
            deleteSharedBoxApi(sharedId, this.isTemplate);
            SharedBoxesStore.updateSharedBoxes(this.moduleId!);
        }
        let canvasChanges = {
            canvasTreeState: { ...changes, [nodeId]: null },
        };
        this.saveChangesAction(canvasChanges);
        this.updateNodesByLinkedInputsAction(canvasChanges);
    }

    @action.bound public toggleVisibilityElementAction(
        nodeId: number,
        isHidden: boolean
    ): void {
        let node = this.canvasTreeState.get(nodeId)!;
        node.nodeIsHidden = {
            ...node.nodeIsHidden,
            [this.canvasViewMode]: isHidden,
        };
        this.updateNodeAction(nodeId, node, false);
    }

    @action.bound public toggleVisibilityBackgroundAction(
        nodeId: number,
        isHidden: boolean
    ): void {
        const changes =
            this.canvasViewMode === "desktop"
                ? {
                      is_hidden: isHidden,
                  }
                : {
                      mobile_is_hidden: isHidden,
                  };
        this.updateBackgroundAction(nodeId, changes);
    }

    @action.bound public toggleVisibilityGridAction(
        gridId: string,
        isHidden: boolean
    ): void {
        let nodeId = null;
        this.canvasTreeState.forEach((node, id) => {
            if (
                (isSimpleSpreadSheetInput(node) || isBox(node)) &&
                node.gridId === gridId
            ) {
                nodeId = id;
            }
        });
        if (!nodeId) return;

        let node = this.canvasTreeState.get(nodeId)!;
        node.nodeIsHidden = {
            ...node.nodeIsHidden,
            [this.canvasViewMode]: isHidden,
        };

        this.updateNodeAction(nodeId, node, false);
    }

    @action.bound private deleteNodesAction(
        nodeIds: number[],
        calculateValues: boolean = true
    ): InnerCanvasChanges {
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        let dataSetsToDelete = new Set<number | string>();
        for (let nodeId of nodeIds) {
            let node = this.canvasTreeState.get(nodeId)!;
            for (let childId of node.childrenIds) {
                let childNode = this.canvasTreeState.get(childId)!;
                if (childNode != null) {
                    childNode = {
                        ...childNode,
                        parentIds: childNode.parentIds.filter(
                            (parent) => parent.id !== nodeId
                        ),
                    };
                    this.canvasTreeState.set(childId, childNode);
                }
            }
            for (let parent of node.parentIds) {
                let parentNode = this.canvasTreeState.get(parent.id)!;
                if (parentNode) {
                    parentNode = {
                        ...parentNode,
                        childrenIds: parentNode.childrenIds.filter(
                            (id) => id !== nodeId
                        ),
                    };
                    this.canvasTreeState.set(parent.id, parentNode);
                }
            }
            let sharedId = node.sharedId;
            if (sharedId != null) {
                deleteSharedBoxApi(sharedId, this.isTemplate);
                SharedBoxesStore.updateSharedBoxes(this.moduleId!);
            }

            for (let dataScopeId of this.extractDataScopeIdsFromNode(node)) {
                dataSetsToDelete.add(dataScopeId);
            }
            this.canvasTreeState.delete(nodeId);
            changes.canvasTreeState![nodeId] = null;
        }
        for (let dataScopeId of dataSetsToDelete) {
            this.onDataSetDisconnected(dataScopeId);
        }
        if (calculateValues) {
            changes.canvasTreeState = {
                ...changes.canvasTreeState,
                ...this.calculateValuesAction(),
            };
        }
        return changes;
    }

    @action.bound public deleteGridAction(gridId: string): void {
        let nodeIds: number[] = [];
        this.canvasTreeState.forEach((node, nodeId) => {
            if (
                (isSimpleSpreadSheetInput(node) || isBox(node)) &&
                node.gridId === gridId
            ) {
                nodeIds.push(nodeId);
            }
        });
        let grid = this.gridsState.get(gridId);
        let changes = this.deleteNodesAction(nodeIds);
        this.gridsState.delete(gridId);
        if (grid?.fullSpreadSheetBackendOutputOptions?.dataScopeId != null) {
            this.onDataSetDisconnected(
                grid.fullSpreadSheetBackendOutputOptions.dataScopeId
            );
        }
        changes.gridsState = {
            [gridId]: null,
        };
        this.saveChangesAction(changes);
        this.updateNodesByLinkedInputsAction(changes);
    }

    @action.bound public deleteQuestionnaireGridAction(
        questionnaireId: string
    ): void {
        for (let grid of this.gridsState.values()) {
            if (grid.questionnaireId === questionnaireId) {
                this.deleteGridAction(grid.id);
                return;
            }
        }
    }

    @action.bound public addNodeAction<T extends keyof CanvasElement>(
        edge: Edge | undefined,
        props: Pick<CanvasElement, T>
    ): number | undefined {
        if (this.canvasPageId == null) return;
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        let newNodeId = this.canvasAutoIncrementId;
        let newNode: CanvasElement;
        // New node creating and set a position with offset to it
        if (edge != null) {
            let initialNode = this.canvasTreeState.get(edge.id)!;

            let x = initialNode.nodePosition[this.canvasViewMode].x;
            let y = initialNode.nodePosition[this.canvasViewMode].y;
            let rect = this.getRectByMetadataAction({
                id: initialNode.id,
                type: "canvasTreeState",
            }).get();

            let shiftValueX = edge.shift ? rect.width * edge.shift : 0;
            let shiftValueY = edge.shift ? rect.height * edge.shift : 0;

            const coordMap: Record<ArrowPosition, { x: number; y: number }> = {
                [ArrowPosition.Top]: { x: shiftValueX, y: rect.height * 2 },
                [ArrowPosition.Right]: { x: -rect.width * 2, y: shiftValueY },
                [ArrowPosition.Bottom]: { x: shiftValueX, y: -rect.height * 2 },
                [ArrowPosition.Left]: { x: rect.width * 2, y: shiftValueY },
            };

            x += coordMap[edge.childPosition].x;
            y += coordMap[edge.childPosition].y;

            let changes = {
                ...initialNode.nodePosition,
            };

            changes[this.canvasViewMode] = {
                x,
                y,
            };

            newNode = Node(
                newNodeId,
                this.nodeSpreadSheetAutoIncrementId,
                undefined,
                this.canvasPageId as number,
                changes
            );
        } else {
            let slideBackground: HTMLElement | null = document.getElementById(
                "main-section"
            );

            let slideRect = this.slideRect();

            let x =
                (slideRect.x +
                    slideRect.width * 0.9 +
                    (slideBackground?.scrollLeft ?? 0) / this.scale) /
                2;
            let y =
                (slideBackground?.scrollTop ?? 0) / this.scale +
                (window.innerHeight * 0.8) / (2 * this.scale);

            const nodePosition = {
                desktop: {
                    x: x,
                    y: y,
                },
                mobile: {
                    x: x,
                    y: y,
                },
            };

            newNode = Node(
                newNodeId,
                this.nodeSpreadSheetAutoIncrementId,
                edge,
                this.canvasPageId as number,
                nodePosition
            );
        }

        newNode = { ...newNode, ...props };
        this.canvasTreeState.set(newNodeId, newNode);
        let initialNode: CanvasNode | null = null;

        // Set the new created node as a parent to the first one it was created from
        if (edge != null) {
            initialNode = this.canvasTreeState.get(edge.id)!;
            initialNode = {
                ...initialNode,
                parentIds: [
                    ...initialNode.parentIds,
                    {
                        id: newNodeId,
                        parentPosition: edge.childPosition, // childPosition here is the position of the new created node
                        childPosition: edge.parentPosition, // parentPosition here is the position of the initial node
                    },
                ],
            };
            newNode.childrenIds = [initialNode.id];

            this.canvasTreeState.set(edge.id, initialNode);
        }

        this.moveFront(newNodeId, "canvasTreeState");

        // Save created node to canvasTreeState
        let changes: InnerCanvasChanges = {
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasTreeState: {
                [newNodeId]: newNode,
            },
        };
        if (edge != null) {
            changes.canvasTreeState![edge.id] = initialNode!;
        }
        this.saveChangesAction(changes);
        return newNodeId;
    }

    private centerObject(item: { id: number | string; type: string }) {
        let slideBackground: HTMLElement | null = document.getElementById(
            "main-section"
        );

        let slideRect = this.slideRect();
        let objectRect = this.getRectByMetadataAction(item).get();

        let center = {
            x:
                (slideRect.x +
                    (slideRect.width - objectRect.width) +
                    (slideBackground?.scrollLeft ?? 0) / this.scale) /
                2,
            y:
                (slideBackground?.scrollTop ?? 0) / this.scale +
                (window.innerHeight * 0.8 - objectRect.height) /
                    (2 * this.scale),
        };

        let objects = this.getObjects();
        let collection = objects.find((object) => object.name === item.type);
        if (collection != null) {
            let collectionRef = collection.ref;
            let element = collectionRef.get(item.id);
            if (element != null) {
                let newElement;
                newElement = {
                    ...element,
                    nodePosition: {
                        desktop: {
                            x: center.x,
                            y: center.y,
                        },
                        mobile: {
                            x: center.x,
                            y: center.y,
                        },
                    },
                };
                collectionRef.set(item.id, newElement);
            }
        }
    }

    private centerSurvey(item: { id: number | string; type: string }) {
        let slideWidthtMobile = this.slideWidth["mobile"];
        let slideWidthDesktop = this.slideWidth["desktop"];
        let objectRect = this.getRectByMetadataAction(item).get();

        let centerDesktop = {
            x: (slideWidthDesktop - objectRect.width) / 2,
            y:
                (window.innerHeight * 0.8 - objectRect.height) /
                (2 * this.scale),
        };

        let centerMobile = {
            x: (slideWidthtMobile - objectRect.width) / 2,
            y:
                (window.innerHeight * 0.8 - objectRect.height) /
                (2 * this.scale),
        };

        let objects = this.getObjects();
        let collection = objects.find((object) => object.name === item.type);
        if (collection != null) {
            let collectionRef = collection.ref;
            let element = collectionRef.get(item.id);
            if (element != null) {
                let newElement;

                newElement = {
                    ...element,
                    nodePosition: {
                        desktop: {
                            x: centerDesktop.x,
                            y: 10,
                        },
                        mobile: {
                            x: centerMobile.x,
                            y: 10,
                        },
                    },
                };
                collectionRef.set(item.id, newElement);
            }
        }
    }

    @action.bound public addNumericInputAction<T extends keyof CanvasInput>(
        props: Pick<CanvasInput, T>
    ): number | undefined {
        if (this.canvasPageId == null) return;
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        let nodeId = this.canvasAutoIncrementId;
        let node: CanvasInput = {
            canvasType: CanvasType.Input,
            id: nodeId,
            x: 100,
            y: 100,
            nodePosition: {
                desktop: { x: 100, y: 100 },
                mobile: { x: 100, y: 100 },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            value: NaN,
            inputFieldStyle: InputFieldStyle.Default,
            metric: "",
            childrenIds: [],
            childrenSharedIds: [],
            parentIds: [],
            fillColor: "#fff",
            fontColor: mainStyle.getPropertyValue("--secondary-text-color"),
            ...props,
        };
        this.canvasTreeState.set(nodeId, node);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: nodeId,
                type: "canvasTreeState",
            });
            node = this.canvasTreeState.get(nodeId)! as CanvasInput;
        }
        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });
        return nodeId;
    }

    @action.bound public addTextBoxAction<T extends keyof CanvasTextBox>(
        props: Pick<CanvasTextBox, T>
    ): number | undefined {
        if (this.canvasPageId == null) return;
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        let nodeId = this.canvasAutoIncrementId;
        let node: CanvasTextBox = {
            additionalOutputs: [],
            text: "",
            canvasType: CanvasType.TextBox,
            id: nodeId,
            x: 100,
            y: 100,
            nodePosition: {
                desktop: { x: 100, y: 100 },
                mobile: { x: 100, y: 100 },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            value: NaN,
            metric: "",
            popups: [],
            links: [],
            childrenIds: [],
            arrowTexts: [],
            childrenSharedIds: [],
            parentIds: [],
            ...props,
        };
        this.canvasTreeState.set(nodeId, node);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: nodeId,
                type: "canvasTreeState",
            });
            node = this.canvasTreeState.get(nodeId)! as CanvasTextBox;
        }
        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });
        return node.id;
    }

    @action.bound public addSubmitButtonAction<
        T extends keyof CanvasSubmitButton
    >(props: Pick<CanvasSubmitButton, T>): number | undefined {
        if (this.canvasPageId == null) return;
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        let nodeId = this.canvasAutoIncrementId;
        let node: CanvasSubmitButton = {
            buttonStyle: LastNodeOptionsStorage.lastButtonsStyle(),
            isTextLink: LastNodeOptionsStorage.lastTextLink(),
            external: LastNodeOptionsStorage.lastExternalLink(),
            links: [],
            canvasType: CanvasType.SubmitButton,
            id: nodeId,
            x: 100,
            y: 100,
            nodePosition: {
                desktop: { x: 100, y: 100 },
                mobile: { x: 100, y: 100 },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            value: NaN,
            metric: "Button",
            childrenIds: [],
            childrenSharedIds: [],
            parentIds: [],
            backendOutput: {
                dataScopeOption: null,
                tableOption: null,
                variableOptions: [
                    {
                        node: null,
                        variable: null,
                    },
                ],
            },
            ...props,
        };
        if (node.isTextLink) {
            node.fontColor = "#39F";
            node.fillColor = "transparent";
        }
        this.canvasTreeState.set(nodeId, node);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: nodeId,
                type: "canvasTreeState",
            });
            node = this.canvasTreeState.get(nodeId)! as CanvasSubmitButton;
        }
        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });
        return nodeId;
    }

    @action.bound public addToggleInputAction<T extends keyof CanvasToggle>(
        props: Pick<CanvasToggle, T>
    ): number | undefined {
        if (this.canvasPageId == null) return;
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        let nodeId = this.canvasAutoIncrementId;
        let node: CanvasToggle = {
            canvasType: CanvasType.Toggle,
            id: nodeId,
            x: 100,
            y: 100,
            nodePosition: {
                desktop: { x: 100, y: 100 },
                mobile: { x: 100, y: 100 },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            metric: "1",
            value: 1,
            childrenIds: [],
            childrenSharedIds: [],
            parentIds: [],
            ...props,
        };
        this.canvasTreeState.set(nodeId, node);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: nodeId,
                type: "canvasTreeState",
            });
            node = this.canvasTreeState.get(nodeId)! as CanvasToggle;
        }
        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });
        return nodeId;
    }

    @action.bound public addRadioButtonsGroupAction<
        T extends keyof CanvasRadioButtonsGroup
    >(props: Pick<CanvasRadioButtonsGroup, T>): number | undefined {
        if (this.canvasPageId == null) return;
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        let nodeId = this.canvasAutoIncrementId;
        let node: CanvasRadioButtonsGroup = {
            canvasType: CanvasType.RadioButtonsGroup,
            id: nodeId,
            tableOption: null,
            dataScopeOption: null,
            variableOption: null,
            imageVariableOption: null,
            filter: null,
            x: 100,
            y: 100,
            nodePosition: {
                desktop: { x: 100, y: 100 },
                mobile: { x: 100, y: 100 },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            metric: "1",
            value: 1,
            childrenIds: [],
            childrenSharedIds: [],
            parentIds: [],
            groupData: [
                {
                    idx: 0,
                    value: "Option 1",
                    thumbnail: "",
                },
                {
                    idx: 1,
                    value: "Option 2",
                    thumbnail: "",
                },
            ],
            update_time: null,
            ...props,
        };
        this.canvasTreeState.set(nodeId, node);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: nodeId,
                type: "canvasTreeState",
            });
            node = this.canvasTreeState.get(nodeId)! as CanvasRadioButtonsGroup;
        }
        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });
        return nodeId;
    }

    @action.bound public updateRadioButtonsGroupAction<
        T extends keyof CanvasRadioButtonsGroup
    >(nodeId: number, props: Pick<CanvasRadioButtonsGroup, T>): void {
        let node = this.canvasTreeState.get(nodeId);
        if (node == null) return;
        let newNode = {
            ...node,
            ...props,
        };

        // Connect or disconnect data sets
        let dataSetsToDelete = new Set<number | string>(
            this.extractDataScopeIdsFromNode(node)
        );
        let dataSetsToAdd = new Set<number | string>();
        for (let dataScopeId of this.extractDataScopeIdsFromNode(newNode)) {
            if (dataSetsToDelete.has(dataScopeId)) {
                dataSetsToDelete.delete(dataScopeId);
            } else {
                dataSetsToAdd.add(dataScopeId);
            }
        }

        this.canvasTreeState.set(nodeId, newNode);

        for (let dataScopeId of dataSetsToDelete) {
            this.onDataSetDisconnected(dataScopeId);
        }
        for (let dataScopeId of dataSetsToAdd) {
            this.onDataSetConnected(dataScopeId);
        }

        this.saveChangesAction({
            canvasTreeState: {
                [nodeId]: newNode,
            },
        });
    }

    @action.bound public addDropdownSelectorInputAction<
        T extends keyof CanvasDropdownSelector
    >(props: Pick<CanvasDropdownSelector, T>): number | undefined {
        if (this.canvasPageId == null) return;
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        let nodeId = this.canvasAutoIncrementId;
        let node: CanvasDropdownSelector = {
            canvasType: CanvasType.DropdownSelector,
            id: nodeId,
            x: 100,
            y: 100,
            nodePosition: {
                desktop: { x: 100, y: 100 },
                mobile: { x: 100, y: 100 },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            metric: "",
            tableValue: null,
            value: NaN,
            childrenIds: [],
            childrenSharedIds: [],
            parentIds: [],
            tableOption: null,
            dataScopeOption: null,
            variableOption: null,
            multipleSelection: false,
            ...props,
        };
        const type = "canvasTreeState";
        this.canvasTreeState.set(nodeId, node);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: nodeId,
                type,
            });
            node = this.canvasTreeState.get(nodeId)! as CanvasDropdownSelector;
        }
        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });

        this.moveFront(node.id, type);
        return nodeId;
    }

    @action.bound public addFilterInputAction<T extends keyof CanvasFilter>(
        props: Pick<CanvasFilter, T>
    ): number | undefined {
        if (this.canvasPageId == null) return;
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        let nodeId = this.canvasAutoIncrementId;
        let node: CanvasFilter = {
            canvasType: CanvasType.Filter,
            id: nodeId,
            x: 100,
            y: 100,
            nodePosition: {
                desktop: { x: 100, y: 100 },
                mobile: { x: 100, y: 100 },
            },
            nodeSize: {
                desktop: {
                    width: 400,
                    height: 100,
                },
                mobile: {
                    width: 400,
                    height: 100,
                },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            metric: "",
            value: NaN,
            childrenIds: [],
            childrenSharedIds: [],
            parentIds: [],
            tableOption: null,
            dataScopeOption: null,
            condition: null,
            ...props,
        };
        this.canvasTreeState.set(nodeId, node);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: nodeId,
                type: "canvasTreeState",
            });
            node = this.canvasTreeState.get(nodeId)! as CanvasFilter;
        }
        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });
        return nodeId;
    }

    @action.bound public addSliderInputAction<T extends keyof CanvasSlider>(
        props: Pick<CanvasSlider, T>
    ): number | undefined {
        if (this.canvasPageId == null) return;
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        let nodeId = this.canvasAutoIncrementId;
        let node: CanvasSlider = {
            canvasType: CanvasType.Slider,
            id: nodeId,
            x: 100,
            y: 100,
            nodePosition: {
                desktop: { x: 100, y: 100 },
                mobile: { x: 100, y: 100 },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            minOutput: {
                metric: "0",
                value: 0,
                unit: "",
            },
            maxOutput: {
                metric: "100",
                value: 100,
                unit: "",
            },
            metric: "",
            value: NaN,
            childrenIds: [],
            childrenSharedIds: [],
            parentIds: [],
            ...props,
        };
        this.canvasTreeState.set(nodeId, node);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: nodeId,
                type: "canvasTreeState",
            });
            node = this.canvasTreeState.get(nodeId)! as CanvasSlider;
        }
        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });
        return nodeId;
    }

    @action.bound public addProgressElementAction<
        T extends keyof CanvasProgressElement
    >(props: Pick<CanvasProgressElement, T>): number | undefined {
        if (this.canvasPageId == null) return;
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        let nodeId = this.canvasAutoIncrementId;
        let node: CanvasProgressElement = {
            canvasType: CanvasType.ProgressElement,
            id: nodeId,
            x: 100,
            y: 100,
            nodePosition: {
                desktop: { x: 100, y: 100 },
                mobile: { x: 100, y: 100 },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            minOutput: {
                metric: "0",
                value: 0,
                unit: "",
            },
            maxOutput: {
                metric: "100",
                value: 100,
                unit: "",
            },
            errorOutput: {
                metric: "",
                value: 0,
                unit: "",
            },
            subtitle: "",
            unit: "",
            metric: "",
            value: NaN,
            childrenIds: [],
            childrenSharedIds: [],
            parentIds: [],
            fontColor: mainStyle.getPropertyValue("--secondary-text-color"),
            additionalOutputs: [],
            ...props,
        };
        this.canvasTreeState.set(nodeId, node);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: nodeId,
                type: "canvasTreeState",
            });
            node = this.canvasTreeState.get(nodeId)! as CanvasProgressElement;
        }
        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });
        return nodeId;
    }

    @action.bound public sortGridAction(
        gridId: string,
        column: number,
        direction: number
    ) {
        let nodes: CanvasNode[] = [];
        this.canvasTreeState.forEach((node) => {
            if (
                (isSimpleSpreadSheetInput(node) || isBox(node)) &&
                node.gridId === gridId
            ) {
                nodes.push(node);
            }
        });
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        let copyNodes: CanvasNode[] = _.cloneDeep(nodes);
        let grid = this.gridsState.get(gridId) as CanvasSpreadSheetGrid;
        let format = grid.headers?.[column]?.columnFormat;

        let originalOrder = Array.from(Array(grid.rows).keys());
        let newOrder = [...originalOrder];

        newOrder.sort((a, b) => {
            let aNode = nodes.find(
                (node) => node.x === column && node.y === a
            )!;
            let bNode = nodes.find(
                (node) => node.x === column && node.y === b
            )!;
            let aValue = getTargetValue(aNode, format);
            let bValue = getTargetValue(bNode, format);
            if (bValue == null) {
                return direction;
            }
            if (typeof bValue === "number" && isNaN(bValue as number))
                return direction;
            if (aValue == null) {
                return -direction;
            }
            if (typeof aValue === "number" && isNaN(aValue as number))
                return -direction;
            return direction * (bValue > aValue ? 1 : -1);
        });
        for (let i = 0; i < nodes.length; ++i) {
            let node = nodes[i];
            let nodeX = node.x;
            let nodeY = node.y;
            let replaceNodeY = newOrder[nodeY!];

            let otherNode = copyNodes.find(
                (node) => node.x === nodeX && node.y === replaceNodeY
            )!;
            node.metric = otherNode.metric;
            node.value = otherNode.value;
            this.canvasTreeState.set(node.id, node);
            changes.canvasTreeState![node.id] = {
                ...node,
                metric: node.metric,
                value: node.value,
            };
        }
        let leftHeaders = grid.leftHeaders;
        if (leftHeaders != null) {
            let copyHeaders = _.cloneDeep(leftHeaders);
            for (let i = 0; i < leftHeaders.length; ++i) {
                let replaceHeader = copyHeaders[newOrder[i]];
                leftHeaders[i] = replaceHeader;
            }
        }
        this.updateGridAction<
            CanvasSpreadSheetGrid,
            "leftHeaders" | "lastSortDirection" | "lastSortedColumn"
        >(gridId, {
            leftHeaders: leftHeaders,
            lastSortDirection: direction,
            lastSortedColumn: column,
        });
        let canvasTreeChanges = this.calculateValuesAction();
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...canvasTreeChanges,
        };
        this.saveChangesAction(changes);
        this.updateNodesByLinkedInputsAction(changes);
    }

    @action.bound public reshapeGridAction(gridId: string) {
        let nodes: CanvasNode[] = [];
        this.canvasTreeState.forEach((node) => {
            if (
                (isBox(node) || isSimpleSpreadSheetInput(node)) &&
                node.gridId === gridId
            ) {
                nodes.push(node);
            }
        });
        let changes: {
            [key: number]: CanvasNode;
        } = {};
        // original order
        nodes.sort((a, b) => {
            const aX = a.x!,
                aY = a.y!,
                bX = b.x!,
                bY = b.y!;

            if (aY < bY) return -1;
            if (aY > bY) return 1;
            if (aX < bX) return -1;
            if (aX > bX) return 1;

            return 0;
        });

        let reshapeNodes: CanvasNode[] = _.cloneDeep(nodes);
        reshapeNodes.sort((a, b) => {
            const aX = a.x!,
                aY = a.y!,
                bX = b.x!,
                bY = b.y!;

            if (aX < bX) return -1;
            if (aX > bX) return 1;
            if (aY < bY) return -1;
            if (aY > bY) return 1;
            return 0;
        });
        for (let i = 0; i < nodes.length; ++i) {
            nodes[i] = {
                ...reshapeNodes[i],
                x: nodes[i].x,
                y: nodes[i].y,
                id: nodes[i].id,
            };
            changes[nodes[i].id] = nodes[i];
        }
        for (let [key, value] of Object.entries(changes)) {
            this.canvasTreeState.set(Number(key), { ...value });
        }
        this.saveChangesAction({ canvasTreeState: changes });
    }

    public calculateSpreadSheetGridContainerSize(
        grid: CanvasSpreadSheetGrid
    ): NodeSize {
        const defaultSize = {
            width: 300,
            height: 200,
        };
        let size = calculateSpreadSheetGridSize(grid, this);
        size[this.canvasViewMode].height += 10;
        size[this.canvasViewMode].width += 10;
        let slideRect = this.slideRect();
        let containerSize = {
            desktop: defaultSize,
            mobile: defaultSize,
            ...grid.containerSize,
            [this.canvasViewMode]: {
                width: Math.min(
                    size[this.canvasViewMode].width,
                    slideRect.width - grid.x + slideRect.x
                ),
                height: Math.min(
                    size[this.canvasViewMode].height,
                    slideRect.height - grid.y + slideRect.y
                ),
            },
        };
        if (
            grid.x >= slideRect.x &&
            grid.x <= slideRect.x + slideRect.width &&
            grid.y >= slideRect.y &&
            grid.y <= slideRect.y + slideRect.height
        ) {
            return containerSize;
        } else {
            return {
                desktop: defaultSize,
                mobile: defaultSize,
                ...grid.containerSize,
                [this.canvasViewMode]: {
                    width: Math.min(
                        slideRect.width,
                        size[this.canvasViewMode].width
                    ),
                    height: Math.min(
                        slideRect.height,
                        size[this.canvasViewMode].height
                    ),
                },
            };
        }
    }

    @action.bound public addSpreadSheetGridAction<
        T extends keyof CanvasSpreadSheetGrid
    >(
        rows: number,
        cols: number,
        rowTitles: boolean,
        colTitles: boolean,
        isSimple: boolean,
        props: Pick<CanvasSpreadSheetGrid, T>
    ): string | undefined {
        if (this.canvasPageId == null) return;
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        this.nodeSpreadSheetAutoIncrementId += 1;
        let gridId = nanoid();
        let grid = SpreadSheetGrid(
            gridId,
            100,
            100,
            rows,
            cols,
            rowTitles,
            colTitles,
            StringUtils.numToAlphabet(this.nodeSpreadSheetAutoIncrementId),
            isSimple
        );

        for (let i = 0; i < rows; ++i)
            for (let j = 0; j < cols; j++) {
                this.canvasAutoIncrementId += 1;
                let nodeId = this.canvasAutoIncrementId;
                let node:
                    | CanvasElement
                    | CanvasSimpleSpreadSheetInput
                    | undefined = undefined;
                if (!isSimple)
                    node = SpreadSheetNode(
                        nodeId,
                        this.canvasPageId as number,
                        j,
                        i,
                        gridId,
                        grid.index
                    );
                else {
                    node = SimpleSpreadSheetInput(
                        nodeId,
                        this.canvasPageId as number,
                        j,
                        i,
                        gridId,
                        grid.index
                    );
                }
                this.canvasTreeState.set(nodeId, node);
                changes.canvasTreeState![nodeId] = node;
            }
        grid = {
            ...grid,
            ...props,
        };
        grid.containerSize = this.calculateSpreadSheetGridContainerSize(grid);

        this.gridsState.set(gridId, grid);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: gridId,
                type: "gridsState",
            });
            grid = this.gridsState.get(gridId)! as CanvasSpreadSheetGrid;
        }
        changes.gridsState = {
            [gridId]: grid,
        };
        changes.nodeSpreadSheetAutoIncrementId = this.nodeSpreadSheetAutoIncrementId;
        changes.canvasAutoIncrementId = this.canvasAutoIncrementId;
        this.updateCanvasSizeAction({
            x: grid.x,
            y: grid.y,
            height: grid.containerSize![this.canvasViewMode]!.height,
            width: grid.containerSize![this.canvasViewMode]!.width,
        });
        this.moveFront(gridId, "gridsState");
        this.saveChangesAction(changes);
        return gridId;
    }

    @action.bound public convertSpreadSheetDateMetricColumnAction(
        columnNodes: CanvasElement[],
        newFormat: DateFormat,
        oldFormat?: ColumnFormat
    ) {
        let needCalculate: boolean = false;
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        for (let node of columnNodes) {
            if (!node.metric.startsWith("=")) {
                let time = node.value;
                if (time != null && !isNaN(time as number)) {
                    if (oldFormat?.type !== newFormat.type && time < 10000) {
                        const strtime = strptime(
                            newFormat.format,
                            node.value.toString()
                        );
                        if (strtime) time = strtime.getTime() / 1000;
                    }

                    node.metric =
                        strftime(
                            newFormat.format,
                            new Date((time as number) * 1000)
                        ) ?? "";
                    node.value = time;
                    changes.canvasTreeState![node.id] = {
                        ...node,
                        metric: node.metric,
                        value: node.value,
                    };
                    this.canvasTreeState.set(node.id, { ...node });
                } else {
                    // Spreadsheets display dates in UTC.
                    // Third parameter has to be true, or it would cause this issue:
                    // https://eisengardai.atlassian.net/browse/EIS-164?focusedCommentId=11288
                    let time = strptime(newFormat.format, node.metric, true);
                    if (time != null) node.value = time.getTime() / 1000;
                    else node.value = NaN;
                    changes.canvasTreeState![node.id] = {
                        ...node,
                        value: node.value,
                    };
                    needCalculate = true;
                    this.canvasTreeState.set(node.id, { ...node });
                }
            }
        }
        if (needCalculate) {
            let canvasTreeChanges = this.calculateValuesAction();
            changes.canvasTreeState = {
                ...changes.canvasTreeState,
                ...canvasTreeChanges,
            };
            this.saveChangesAction(changes);
            this.updateNodesByLinkedInputsAction(changes);
        } else {
            this.saveChangesAction(changes);
        }
    }

    @action.bound public convertSpreadSheetNumberMetricColumnAction(
        columnNodes: CanvasElement[],
        newFormat: NumberFormat
    ) {
        let needCalculate: boolean = false;
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        for (let node of columnNodes) {
            if (!node.metric.startsWith("=")) {
                let value = node.value;
                if (value != null && !isNaN(value as number)) {
                    node.metric = formatNumber(value as number, newFormat);
                    changes.canvasTreeState![node.id] = {
                        ...node,
                        metric: node.metric,
                    };
                    this.canvasTreeState.set(node.id, { ...node });
                } else {
                    let newValue = parseNumber(node.metric);
                    node.value = newValue;
                    changes.canvasTreeState![node.id] = {
                        ...node,
                        value: node.value,
                    };
                    needCalculate = true;
                    this.canvasTreeState.set(node.id, { ...node });
                }
            }
        }
        if (needCalculate) {
            let canvasTreeChanges = this.calculateValuesAction();
            changes.canvasTreeState = {
                ...changes.canvasTreeState,
                ...canvasTreeChanges,
            };
            this.saveChangesAction(changes);
            this.updateNodesByLinkedInputsAction(changes);
        } else {
            this.saveChangesAction(changes);
        }
    }

    static makeSpreadSheetAutocompleteInner(
        prevNode: CanvasNode,
        newNode: CanvasNode,
        variablesMapping: { [key: string]: string }
    ) {
        if (StringUtils.isNumber(prevNode.metric)) {
            newNode.metric = String(Number(prevNode.metric) + 1);
        } else {
            if (prevNode.metric.startsWith("=")) {
                try {
                    let expression = Parser.parse(prevNode.metric.substring(1));
                    for (let variable of expression.variables()) {
                        let normalizedVariable = extractOuterId(variable);
                        let newVariable = variablesMapping[normalizedVariable];
                        if (newVariable == null) {
                            continue;
                        }
                        if (variable === normalizedVariable)
                            expression = expression.substitute(
                                variable,
                                "tmp_".concat(newVariable)
                            );
                        else {
                            expression = expression.substitute(
                                variable,
                                variable.replaceAll(
                                    normalizedVariable.concat("_"),
                                    "tmp_".concat(newVariable.concat("_"))
                                )
                            );
                        }
                    }
                    for (let variable of expression.variables()) {
                        if (variable.startsWith("tmp_")) {
                            expression = expression.substitute(
                                variable,
                                variable.replace("tmp_", "")
                            );
                        }
                    }
                    newNode.metric = "=".concat(expression.toString());
                } catch (exception) {}
            } else {
                newNode.metric = prevNode.metric;
            }
        }
    }

    public getSortedGridNodes(gridId: string) {
        let sortedNodes: CanvasElement[] = [];
        for (let node of this.canvasTreeState.values()) {
            if ((node as CanvasElement).gridId === gridId) {
                sortedNodes.push(node as CanvasElement);
            }
        }
        //  if (!isSimpleSpreadSheet)
        sortedNodes.sort((a, b) => {
            const aX = a.x!,
                aY = a.y!,
                bX = b.x!,
                bY = b.y!;

            if (aY < bY) return -1;
            if (aY > bY) return 1;
            if (aX < bX) return -1;
            if (aX > bX) return 1;

            return 0;
        });
        return sortedNodes;
    }
    /*!
     * makeSpreadSheetAutocomplete implements ability to fill
     * bigger area by small area with some regularity
     * @param gridId Identifier of spreadsheet
     * @param selectionArea Square area of spreadsheet
     * according to that we find regularity
     * @param autoFillArea Square area that is filled according to
     * regularity of selectionArea
     * @param nodes Flat list of nodes of spreadsheet
     */

    @action.bound public makeSpreadSheetAutocompleteAction(
        gridId: string,
        selectionArea: SelectionArea,
        autoFillArea: SelectionArea,
        nodes: CanvasElement[]
    ) {
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        let spreadSheetGrid = this.gridsState.get(gridId)!;
        let outerIds: string[] = [];
        let outerIdsSet: Set<string> = new Set();
        this.canvasTreeState.forEach((node) => {
            if (
                (isSimpleSpreadSheetInput(node) || isBox(node)) &&
                node.gridId === gridId
            ) {
                outerIds.push(node.outerId);
                outerIdsSet.add(node.outerId);
            }
        });
        if (
            autoFillArea.right === selectionArea.right &&
            autoFillArea.bottom > selectionArea.bottom
        ) {
            // bottom case
            let variablesMapping: { [key: string]: string } = {};

            for (let outerId of outerIds) {
                variablesMapping[outerId] = outerId.replace(/\d+$/, function (
                    n
                ) {
                    return String(Number(n) + 1);
                });
            }

            for (let outerId of Object.keys(variablesMapping)) {
                if (!outerIdsSet.has(variablesMapping[outerId])) {
                    delete variablesMapping[outerId];
                }
            }
            for (let j = selectionArea.left; j <= selectionArea.right; ++j) {
                let avgDelta: number | undefined = 0;
                for (
                    let i = selectionArea.top;
                    i <= selectionArea.bottom;
                    ++i
                ) {
                    if (
                        StringUtils.isNumber(
                            nodes[i * spreadSheetGrid.cols + j].metric
                        )
                    ) {
                        if (
                            selectionArea.bottom > selectionArea.top &&
                            i < selectionArea.bottom
                        ) {
                            avgDelta +=
                                Number(
                                    nodes[(i + 1) * spreadSheetGrid.cols + j]
                                        .metric
                                ) -
                                Number(
                                    nodes[i * spreadSheetGrid.cols + j].metric
                                );
                        }
                    } else {
                        avgDelta = undefined;
                        break;
                    }
                }
                if (avgDelta != null && avgDelta !== 0) {
                    avgDelta =
                        avgDelta / (selectionArea.bottom - selectionArea.top);
                }
                for (
                    let i = selectionArea.bottom + 1;
                    i <= autoFillArea.bottom;
                    ++i
                ) {
                    if (avgDelta != null) {
                        let prevId =
                            nodes[(i - 1) * spreadSheetGrid.cols + j].id;
                        let newId = nodes[i * spreadSheetGrid.cols + j].id;
                        let prevNode = this.canvasTreeState.get(prevId)!;
                        let newNode = this.canvasTreeState.get(newId)!;
                        newNode.metric = String(
                            Number(prevNode.metric) + avgDelta
                        );
                    } else {
                        let prevId =
                            nodes[
                                (i -
                                    (1 +
                                        selectionArea.bottom -
                                        selectionArea.top)) *
                                    spreadSheetGrid.cols +
                                    j
                            ].id;
                        let newId = nodes[i * spreadSheetGrid.cols + j].id;
                        let prevNode = this.canvasTreeState.get(prevId)!;
                        let newNode = this.canvasTreeState.get(newId)!;
                        CanvasTreeStoreInner.makeSpreadSheetAutocompleteInner(
                            prevNode,
                            newNode,
                            variablesMapping
                        );
                        changes.canvasTreeState![newId] = newNode;
                    }
                }
            }
        }
        if (
            autoFillArea.bottom === selectionArea.bottom &&
            autoFillArea.right > selectionArea.right
        ) {
            let variablesMapping: { [key: string]: string } = {};

            for (let outerId of outerIds) {
                variablesMapping[outerId] = outerId.replace(
                    /[(A-Z|_)]+/,
                    function (n) {
                        let nSplit = n.split("_");
                        nSplit[nSplit.length - 1] = StringUtils.numToAlphabet(
                            StringUtils.alphabetToNum(
                                nSplit[nSplit.length - 1]
                            ) + 1
                        );
                        return nSplit.join("_");
                    }
                );
            }

            for (let outerId of Object.keys(variablesMapping)) {
                if (!outerIdsSet.has(variablesMapping[outerId])) {
                    delete variablesMapping[outerId];
                }
            }
            for (let i = selectionArea.top; i <= selectionArea.bottom; ++i) {
                let avgDelta: number | undefined = 0;
                for (
                    let j = selectionArea.left;
                    j <= selectionArea.right;
                    ++j
                ) {
                    if (
                        StringUtils.isNumber(
                            nodes[i * spreadSheetGrid.cols + j].metric
                        )
                    ) {
                        if (
                            selectionArea.right > selectionArea.left &&
                            j < selectionArea.right
                        )
                            avgDelta +=
                                Number(
                                    nodes[i * spreadSheetGrid.cols + j + 1]
                                        .metric
                                ) -
                                Number(
                                    nodes[i * spreadSheetGrid.cols + j].metric
                                );
                    } else {
                        avgDelta = undefined;
                        break;
                    }
                }
                if (avgDelta != null && avgDelta !== 0) {
                    avgDelta =
                        avgDelta / (selectionArea.right - selectionArea.left);
                }
                for (
                    let j = selectionArea.right + 1;
                    j <= autoFillArea.right;
                    ++j
                ) {
                    if (avgDelta != null) {
                        let prevId = nodes[i * spreadSheetGrid.cols + j - 1].id;
                        let newId = nodes[i * spreadSheetGrid.cols + j].id;
                        this.canvasTreeState.get(newId)!.metric = String(
                            Number(this.canvasTreeState.get(prevId)!.metric) +
                                avgDelta
                        );
                    } else {
                        let prevId =
                            nodes[
                                i * spreadSheetGrid.cols +
                                    j -
                                    (selectionArea.right -
                                        selectionArea.left +
                                        1)
                            ].id;
                        let newId = nodes[i * spreadSheetGrid.cols + j].id;
                        let prevNode = this.canvasTreeState.get(prevId)!;
                        let newNode = this.canvasTreeState.get(newId)!;
                        CanvasTreeStoreInner.makeSpreadSheetAutocompleteInner(
                            prevNode,
                            newNode,
                            variablesMapping
                        );
                        changes.canvasTreeState = {
                            ...changes.canvasTreeState,
                            [newId]: newNode,
                        };
                    }
                }
            }
        }
        let canvasTreeChanges = this.calculateValuesAction();
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...canvasTreeChanges,
        };
        this.saveChangesAction(changes);
        this.updateNodesByLinkedInputsAction(changes);
    }

    @action.bound public addQuestionnaireGridRowAction(
        questionnaireId: string
    ): void {
        if (this.canvasPageId == null) return;
        let questionnaireElement: QuestionnaireElement = {
            ...this.questionnaireElementsState.get(questionnaireId)!,
            questions: this.questionnaireElementsState
                .get(questionnaireId)!
                .questions.filter(
                    (question) => !isTemplateSheetQuestion(question)
                ),
        };
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        let grid: CanvasGrid | null = null;
        for (let item of this.gridsState.values()) {
            if (item.questionnaireId === questionnaireId) {
                grid = item;
                break;
            }
        }
        let cols = questionnaireElement.questions.length;
        // Grid does not exist
        if (grid == null) {
            let gridId = nanoid();
            let position = {
                x: questionnaireElement.nodePosition[this.canvasViewMode].x,
                y:
                    questionnaireElement.nodePosition[this.canvasViewMode].y +
                    questionnaireElement.nodeSize[this.canvasViewMode].height +
                    20,
            };
            if (
                questionnaireElement.outputType ===
                QuestionnaireOutputType.Sheet
            ) {
                this.nodeSpreadSheetAutoIncrementId += 1;
                grid = SpreadSheetGrid(
                    gridId,
                    position.x,
                    position.y,
                    1,
                    cols,
                    false,
                    true,
                    StringUtils.numToAlphabet(
                        this.nodeSpreadSheetAutoIncrementId
                    ),
                    false
                );
                (grid as CanvasSpreadSheetGrid).headers = questionnaireElement.questions.map(
                    (question) => ({
                        text: question.question,
                    })
                );
                grid.containerSize = this.calculateSpreadSheetGridContainerSize(
                    grid
                );
            } else grid = Grid(gridId, position.x, position.y, 1, cols);
            grid.questionnaireId = questionnaireId;
            this.gridsState.set(gridId, grid);
        } else {
            grid = {
                ...grid!,
                rows: grid!.rows + 1,
            } as CanvasSpreadSheetGrid;
            this.gridsState.set(grid.id, grid);
        }
        changes.gridsState = {
            [grid.id]: grid,
        };
        for (let j = 0; j < cols; j++) {
            let question = questionnaireElement.questions[j];
            this.canvasAutoIncrementId += 1;
            let nodeId = this.canvasAutoIncrementId;

            let node: CanvasElement = SpreadSheetNode(
                nodeId,
                this.canvasPageId as number,
                j,
                grid.rows - 1,
                grid.id,
                (grid as CanvasSpreadSheetGrid).index
            );
            node.metric = questionToAnswer(question);
            this.canvasTreeState.set(nodeId, node);
            changes.canvasTreeState![nodeId] = node;
        }
        let size =
            grid.containerSize ?? calculateSpreadSheetGridSize(grid, this);
        this.updateCanvasSizeAction({
            x: grid.x,
            y: grid.y,
            width: size[this.canvasViewMode].width,
            height: size[this.canvasViewMode].height,
        });
        let canvasTreeChanges = this.calculateValuesAction();
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...canvasTreeChanges,
        };
        changes.nodeSpreadSheetAutoIncrementId = this.nodeSpreadSheetAutoIncrementId;
        changes.canvasAutoIncrementId = this.canvasAutoIncrementId;
        this.saveChangesAction(changes);
    }

    @action.bound public linkCanvasSheetsToQuestionnaireGridAction(
        questionnaireId: string,
        canvasIds: number[]
    ) {
        if (this.canvasPageId == null) return;

        let grid: CanvasGrid | null = null;
        for (let item of this.gridsState.values()) {
            if (item.questionnaireId === questionnaireId) {
                grid = item;
                break;
            }
        }
        if (grid != null) {
            let node: CanvasNode | null = null;
            for (let item of this.canvasTreeState.values()) {
                if (
                    isBox(item) &&
                    item.gridId === grid.id &&
                    item.y === 0 &&
                    item.x === grid.rows - 1
                ) {
                    node = item;
                    break;
                }
            }
            if (node != null && isBox(node)) {
                node = {
                    ...node,
                    links: [...node.links, ...canvasIds],
                } as CanvasElement;
                this.canvasTreeState.set(node.id, node);
                this.saveChangesAction({
                    canvasTreeState: {
                        [node.id]: node,
                    },
                });
            }
        }
    }

    @action.bound public toggleRowHeadersAction(
        gridId: string,
        enabled: boolean
    ): void {
        if (this.canvasPageId == null) return;
        let grid = { ...this.gridsState.get(gridId)! };

        if (isSpreadSheetGrid(grid)) {
            grid.leftHeadersEnabled = enabled;
            if (
                grid.leftHeaders == null ||
                grid.leftHeaders.length !== grid.rows
            ) {
                grid.leftHeaders = new Array(grid.rows);
                for (let i = 0; i < grid.rows; ++i) {
                    grid.leftHeaders[i] = { text: "" };
                }
            }
            this.gridsState.set(gridId, grid);
            this.saveChangesAction({
                gridsState: {
                    [gridId]: grid,
                },
            });
        }
    }

    @action.bound public toggleColumnHeadersAction(
        gridId: string,
        enabled: boolean
    ): void {
        if (this.canvasPageId == null) return;
        let grid = { ...this.gridsState.get(gridId)! };

        if (isSpreadSheetGrid(grid)) {
            grid.headersEnabled = enabled;
            if (grid.headers == null || grid.headers.length !== grid.cols) {
                grid.headers = new Array(grid.cols);
                for (let i = 0; i < grid.cols; ++i) {
                    grid.headers[i] = { text: "" };
                }
            }
            this.gridsState.set(gridId, grid);
            this.saveChangesAction({
                gridsState: {
                    [gridId]: grid,
                },
            });
        }
    }

    @action.bound public insertGridRowAction(
        gridId: string,
        rowIndex: number
    ): void {
        if (this.canvasPageId == null) return;
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        let grid = { ...this.gridsState.get(gridId)! };

        grid.rows += 1;
        if (grid.rowScales != null) {
            grid.rowScales = [...grid.rowScales];
            grid.rowScales.splice(rowIndex, 0, 1);
        }
        if (isSpreadSheetGrid(grid) && grid.leftHeaders != null) {
            grid.leftHeaders.splice(rowIndex, 0, { text: "" });
        }

        let outerIdMap: {
            [key: string]: string;
        } = {};
        this.canvasTreeState.forEach((node) => {
            if (
                (isSimpleSpreadSheetInput(node) || isBox(node)) &&
                node.gridId === gridId &&
                node.y! >= rowIndex
            ) {
                let newNode = {
                    ...node,
                    y: node.y! + 1,
                    outerId: outerSpreadSheetId(
                        (grid as CanvasSpreadSheetGrid).index,
                        node.x! + 1,
                        node.y! + 2
                    ),
                };
                outerIdMap[node.outerId] = newNode.outerId;
                this.canvasTreeState.set(newNode.id, newNode);
                changes.canvasTreeState![newNode.id] = newNode;
            }
        });
        let renameChanges = this.renameSpreadsheetVariables(outerIdMap);
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...renameChanges,
        };
        for (let colIndex = 0; colIndex < grid.cols; ++colIndex) {
            this.canvasAutoIncrementId += 1;
            let nodeId = this.canvasAutoIncrementId;
            let node:
                | CanvasElement
                | CanvasSimpleSpreadSheetInput
                | undefined = undefined;
            if (grid.type === CanvasGridType.SimpleSpreadSheet) {
                node = SimpleSpreadSheetInput(
                    nodeId,
                    this.canvasPageId as number,
                    colIndex,
                    rowIndex,
                    gridId,
                    (grid as CanvasSpreadSheetGrid).index
                );
            } else {
                node = SpreadSheetNode(
                    nodeId,
                    this.canvasPageId as number,
                    colIndex,
                    rowIndex,
                    gridId,
                    (grid as CanvasSpreadSheetGrid).index
                );
            }
            this.canvasTreeState.set(nodeId, node);
            changes.canvasTreeState![nodeId] = node;
        }
        if (grid.fullSpreadSheetBackendOutputOptions?.subsetInfo != null) {
            let rowId = nanoid();
            grid.fullSpreadSheetBackendOutputOptions.subsetInfo.rowId.splice(
                rowIndex,
                0,
                rowId
            );
            let operation: AddRowOperation = {
                type: OperationType.AddRow,
                rowId: rowId,
            };
            pushTableChange(grid, operation);
        }

        changes.canvasAutoIncrementId = this.canvasAutoIncrementId;
        changes.gridsState = {
            [gridId]: grid,
        };

        this.gridsState.set(gridId, grid);
        this.saveChangesAction(changes);
    }

    @action.bound public deleteGridRowAction(
        gridId: string,
        rowIndex: number
    ): void {
        if (this.canvasPageId == null) return;
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        let grid = { ...this.gridsState.get(gridId)! };

        grid.rows -= 1;
        if (grid.rowScales != null) {
            grid.rowScales = [...grid.rowScales];
            grid.rowScales.splice(rowIndex, 1);
        }
        if (isSpreadSheetGrid(grid) && grid.leftHeaders != null) {
            grid.leftHeaders = [...grid.leftHeaders];
            grid.leftHeaders.splice(rowIndex, 1);
        }

        let outerIdMap: {
            [key: string]: string;
        } = {};
        let deletedIdentifiers: string[] = [];
        this.canvasTreeState.forEach((node) => {
            if (
                (isSimpleSpreadSheetInput(node) || isBox(node)) &&
                node.gridId === gridId
            ) {
                if (node.y! > rowIndex) {
                    let newNode = { ...node };
                    newNode.y! -= 1;
                    let oldOuterId = node.outerId;
                    newNode.outerId = outerSpreadSheetId(
                        (grid as CanvasSpreadSheetGrid).index,
                        newNode.x! + 1,
                        newNode.y! + 1
                    );
                    outerIdMap[oldOuterId] = newNode.outerId;
                    this.canvasTreeState.set(newNode.id, newNode);
                    changes.canvasTreeState = {
                        ...changes.canvasTreeState,
                        [newNode.id]: newNode,
                    };
                } else if (node.y === rowIndex) {
                    deletedIdentifiers.push(node.outerId);
                    this.canvasTreeState.delete(node.id);
                    changes.canvasTreeState = {
                        ...changes.canvasTreeState,
                        [node.id]: null,
                    };
                }
            }
        });
        let deleteChanges = this.removeReferences(deletedIdentifiers);
        let renameChanges = this.renameSpreadsheetVariables(outerIdMap);
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...deleteChanges,
            ...renameChanges,
        };
        if (grid.fullSpreadSheetBackendOutputOptions?.subsetInfo != null) {
            let rowId =
                grid.fullSpreadSheetBackendOutputOptions.subsetInfo.rowId[
                    rowIndex
                ];
            grid.fullSpreadSheetBackendOutputOptions.subsetInfo.rowId.splice(
                rowIndex,
                1
            );
            let operation: DeleteRowOperation = {
                type: OperationType.DeleteRow,
                rowId: rowId,
            };
            pushTableChange(grid, operation);
        }
        changes.gridsState = {
            [gridId]: grid,
        };
        this.gridsState.set(gridId, grid);
        let canvasTreeChanges = this.calculateValuesAction();
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...canvasTreeChanges,
        };
        this.saveChangesAction(changes);
        this.updateNodesByLinkedInputsAction(changes);
    }

    @action.bound public insertGridColumnAction(
        gridId: string,
        colIndex: number,
        colName?: string,
        format?: ColumnFormat
    ): void {
        if (this.canvasPageId == null) return;
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        let grid = { ...this.gridsState.get(gridId)! };

        grid.cols += 1;
        if (grid.columnScales != null) {
            grid.columnScales = [...grid.columnScales];
            grid.columnScales.splice(colIndex, 0, 1);
        }
        if (isSpreadSheetGrid(grid) && grid.headers != null) {
            grid.headers.splice(colIndex, 0, {
                text: colName ?? "",
                columnFormat: format,
            });
        }

        let outerIdMap: {
            [key: string]: string;
        } = {};
        this.canvasTreeState.forEach((node) => {
            if (
                (isSimpleSpreadSheetInput(node) || isBox(node)) &&
                node.gridId === gridId &&
                node.x! >= colIndex
            ) {
                let oldOuterId = node.outerId;
                let newNode = {
                    ...node,
                    x: node.x! + 1,
                    outerId: outerSpreadSheetId(
                        (grid as CanvasSpreadSheetGrid).index,
                        node.x! + 2,
                        node.y! + 1
                    ),
                };
                outerIdMap[oldOuterId] = newNode.outerId;
                this.canvasTreeState.set(newNode.id, newNode);
                changes.canvasTreeState![newNode.id] = newNode;
            }
        });
        let renameChanges = this.renameSpreadsheetVariables(outerIdMap);
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...renameChanges,
        };
        for (let rowIndex = 0; rowIndex < grid.rows; ++rowIndex) {
            this.canvasAutoIncrementId += 1;
            let newNodeId = this.canvasAutoIncrementId;
            let node:
                | CanvasElement
                | CanvasSimpleSpreadSheetInput
                | undefined = undefined;
            if (grid.type === CanvasGridType.SimpleSpreadSheet) {
                node = SimpleSpreadSheetInput(
                    newNodeId,
                    this.canvasPageId as number,
                    colIndex,
                    rowIndex,
                    gridId,
                    (grid as CanvasSpreadSheetGrid).index
                );
            } else {
                node = SpreadSheetNode(
                    newNodeId,
                    this.canvasPageId as number,
                    colIndex,
                    rowIndex,
                    gridId,
                    (grid as CanvasSpreadSheetGrid).index
                );
            }
            this.canvasTreeState.set(newNodeId, node);
            changes.canvasTreeState![node.id] = node;
        }
        if (grid.fullSpreadSheetBackendOutputOptions?.subsetInfo != null) {
            let newVariableIdentifier = nanoid();
            grid.fullSpreadSheetBackendOutputOptions.subsetInfo.variableIndices.splice(
                colIndex,
                0,
                newVariableIdentifier
            );
            let operation: AddVariableOperation = {
                type: OperationType.AddVariable,
                name: colName ?? "",
                format: format ?? undefined,
                variableIndex: newVariableIdentifier,
            };
            pushTableChange(grid, operation);
        }
        changes.gridsState = {
            [gridId]: grid,
        };
        changes.canvasAutoIncrementId = this.canvasAutoIncrementId;
        this.gridsState.set(gridId, grid);
        this.saveChangesAction(changes);
    }

    @action.bound public deleteGridColumnAction(
        gridId: string,
        colIndex: number
    ): void {
        if (this.canvasPageId == null) return;
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        let grid = { ...this.gridsState.get(gridId)! };

        grid.cols -= 1;
        if (grid.columnScales != null) {
            grid.columnScales = [...grid.columnScales];
            grid.columnScales?.splice(colIndex, 1);
        }
        if (isSpreadSheetGrid(grid) && grid.headers != null) {
            grid.headers = [...grid.headers];
            grid.headers.splice(colIndex, 1);
        }

        let outerIdMap: {
            [key: string]: string;
        } = {};
        let deletedIdentifiers: string[] = [];
        this.canvasTreeState.forEach((node) => {
            if (
                (isSimpleSpreadSheetInput(node) || isBox(node)) &&
                node.gridId === gridId
            ) {
                if (node.x! > colIndex) {
                    let newNode = { ...node };
                    newNode.x = node.x! - 1;
                    let oldOuterId = node.outerId;
                    newNode.outerId = outerSpreadSheetId(
                        (grid as CanvasSpreadSheetGrid).index,
                        newNode.x + 1,
                        newNode.y! + 1
                    );
                    outerIdMap[oldOuterId] = newNode.outerId;
                    this.canvasTreeState.set(newNode.id, newNode);
                    changes.canvasTreeState = {
                        ...changes.canvasTreeState,
                        [newNode.id]: newNode,
                    };
                } else if (node.x === colIndex) {
                    deletedIdentifiers.push(node.outerId);
                    this.canvasTreeState.delete(node.id);
                    changes.canvasTreeState = {
                        ...changes.canvasTreeState,
                        [node.id]: null,
                    };
                }
            }
        });
        let deleteChanges = this.removeReferences(deletedIdentifiers);
        let renameChanges = this.renameSpreadsheetVariables(outerIdMap);
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...deleteChanges,
            ...renameChanges,
        };
        if (grid.fullSpreadSheetBackendOutputOptions?.subsetInfo != null) {
            let operation: DeleteVariableOperation = {
                type: OperationType.DeleteVariable,
                variableIndex:
                    grid.fullSpreadSheetBackendOutputOptions.subsetInfo
                        .variableIndices[colIndex],
            };
            grid.fullSpreadSheetBackendOutputOptions.subsetInfo.variableIndices.splice(
                colIndex,
                1
            );
            pushTableChange(grid, operation);
        }
        changes.gridsState = {
            [grid.id]: grid,
        };

        this.gridsState.set(gridId, grid);
        let canvasTreeChanges = this.calculateValuesAction();
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...canvasTreeChanges,
        };
        this.saveChangesAction(changes);
        this.updateNodesByLinkedInputsAction(changes);
    }

    @action.bound public addEmptyBackendTableAction<
        T extends keyof CanvasTable
    >(props: Pick<CanvasTable, T>): string | undefined {
        let table: CanvasTable = {
            nodePosition: {
                desktop: {
                    x: 0,
                    y: 0,
                },
                mobile: {
                    x: 0,
                    y: 0,
                },
            },
            rowCount: 100,
            nodeSize: {
                desktop: {
                    width: this.slideWidth.desktop,
                    height: this.slideHeight.desktop,
                },
                mobile: {
                    width: this.slideWidth.mobile,
                    height: this.slideHeight.mobile,
                },
            },
            tableOption: null,
            conditions: null,
            variables: null,
            dataScopeOption: null,
            ...props,
        };
        let tableId = nanoid();
        this.backendTablesState.set(tableId, table);
        this.updateCanvasSizeAction({
            x: table.nodePosition[this.canvasViewMode].x,
            y: table.nodePosition[this.canvasViewMode].y,
            width: table.nodeSize[this.canvasViewMode].width,
            height: table.nodeSize[this.canvasViewMode].height,
        });
        this.moveFront(tableId, "backendTablesState");
        this.saveChangesAction({
            backendTablesState: {
                [tableId]: table,
            },
        });
        return tableId;
    }

    @action.bound public addEmptyQuestionnaireElementAction<
        T extends keyof QuestionnaireElement
    >(props: Pick<QuestionnaireElement, T>): string | undefined {
        let questionnaireElement: QuestionnaireElement = {
            nodePosition: {
                desktop: {
                    x: 100,
                    y: 100,
                },
                mobile: {
                    x: 100,
                    y: 100,
                },
            },
            nodeSize: {
                desktop: {
                    width: 400,
                    height: 200,
                },
                mobile: {
                    width: 400,
                    height: 200,
                },
            },
            outputType: QuestionnaireOutputType.Sheet,
            questions: [],
            isDone: false,
            templateSheetOutputEnabled: false,
        };
        let questionnaireId = nanoid();
        questionnaireElement = { ...questionnaireElement, ...props };
        this.questionnaireElementsState.set(
            questionnaireId,
            questionnaireElement
        );
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: questionnaireId,
                type: "questionnaireElementsState",
            });
            questionnaireElement = this.questionnaireElementsState.get(
                questionnaireId
            )! as QuestionnaireElement;
        }
        this.updateCanvasSizeAction({
            x: questionnaireElement.nodePosition[this.canvasViewMode].x,
            y: questionnaireElement.nodePosition[this.canvasViewMode].y,
            width: questionnaireElement.nodeSize[this.canvasViewMode].width,
            height: questionnaireElement.nodeSize[this.canvasViewMode].height,
        });
        this.moveFront(questionnaireId, "questionnaireElementsState");
        this.saveChangesAction({
            questionnaireElementsState: {
                [questionnaireId]: questionnaireElement,
            },
        });
        return questionnaireId;
    }

    @action.bound public deleteQuestionnaireElementAction(
        questionnaireElementId: string
    ): void {
        let element = this.questionnaireElementsState.get(
            questionnaireElementId
        );
        this.questionnaireElementsState.delete(questionnaireElementId);
        if (element?.backendOutput?.dataScopeOption?.value != null) {
            this.onDataSetDisconnected(
                element.backendOutput.dataScopeOption.value
            );
        }
        this.saveChangesAction({
            questionnaireElementsState: {
                [questionnaireElementId]: null,
            },
        });
    }

    @action.bound public addEmptyMapElementAction<T extends keyof MapElement>(
        props: Pick<MapElement, T>
    ): string | undefined {
        let mapElement: MapElement = {
            nodePosition: {
                desktop: {
                    x: 100,
                    y: 100,
                },
                mobile: {
                    x: 100,
                    y: 100,
                },
            },
            nodeSize: {
                desktop: {
                    width: 400,
                    height: 200,
                },
                mobile: {
                    width: 400,
                    height: 200,
                },
            },
            isDone: false,
            dataScope: null,
            tableOption: null,
            colorOptions: {
                borderShadow: false,
                fillColor: dataScienceElementsStyle.contentColor,
                borderColor: dataScienceElementsStyle.borderColor,
            },
            displayMode: {
                label: "click",
                value: MapTooltipDisplayMode.onClick,
            },
            ...props,
        };
        let mapId = nanoid();
        this.mapElementsState.set(mapId, mapElement);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: mapId,
                type: "mapElementsState",
            });
            mapElement = this.mapElementsState.get(mapId)! as MapElement;
        }
        this.updateCanvasSizeAction({
            x: mapElement.nodePosition[this.canvasViewMode].x,
            y: mapElement.nodePosition[this.canvasViewMode].y,
            width: mapElement.nodeSize[this.canvasViewMode].width,
            height: mapElement.nodeSize[this.canvasViewMode].height,
        });
        this.moveFront(mapId, "mapElementsState");
        this.saveChangesAction({
            mapElementsState: {
                [mapId]: mapElement,
            },
        });
        return mapId;
    }

    @action.bound public deleteMapElementAction(mapElementId: string): void {
        this.mapElementsState.delete(mapElementId);
        this.saveChangesAction({
            mapElementsState: {
                [mapElementId]: null,
            },
        });
    }

    @action.bound public addEmptyGraphElementAction<
        T extends keyof GraphElement
    >(props: Pick<GraphElement, T>): string | undefined {
        let graphElement: GraphElement = {
            x: 100,
            y: 100,
            width: 400,
            height: 200,
            colorOptions: {
                borderShadow: false,
                fillColor: dataScienceElementsStyle.contentColor,
                borderColor: dataScienceElementsStyle.borderColor,
            },
            nodes: {},
            edges: {},
            nextNodeId: 0,
            nextEdgeId: 0,
            ...props,
        };
        let graphId = nanoid();
        this.graphElementsState.set(graphId, graphElement);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: graphId,
                type: "graphElementsState",
            });
            graphElement = this.graphElementsState.get(
                graphId
            )! as GraphElement;
        }
        this.updateCanvasSizeAction({
            x: graphElement.x,
            y: graphElement.y,
            width: graphElement.width,
            height: graphElement.height,
        });
        this.moveFront(graphId, "graphElementsState");
        this.saveChangesAction({
            graphElementsState: {
                [graphId]: graphElement,
            },
        });
        return graphId;
    }

    @action.bound public deleteGraphElementAction(
        graphElementId: string
    ): void {
        this.graphElementsState.delete(graphElementId);
        this.saveChangesAction({
            graphElementsState: {
                [graphElementId]: null,
            },
        });
    }

    @action.bound public addEmptyEmbedUrlElementAction<
        T extends keyof EmbedUrlElement
    >(props: Pick<EmbedUrlElement, T>): string | undefined {
        let element: EmbedUrlElement = {
            nodePosition: {
                desktop: {
                    x: 100,
                    y: 100,
                },
                mobile: {
                    x: 100,
                    y: 100,
                },
            },
            nodeSize: {
                desktop: {
                    width: 400,
                    height: 200,
                },
                mobile: {
                    width: 400,
                    height: 200,
                },
            },
            ...props,
        };
        let elementId = nanoid();
        this.embedUrlElementsState.set(elementId, element);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: elementId,
                type: "embedUrlElementsState",
            });
            element = this.embedUrlElementsState.get(
                elementId
            )! as EmbedUrlElement;
        }
        this.updateCanvasSizeAction({
            x: element.nodePosition[this.canvasViewMode].x,
            y: element.nodePosition[this.canvasViewMode].y,
            width: element.nodeSize[this.canvasViewMode].width,
            height: element.nodeSize[this.canvasViewMode].height,
        });
        this.moveFront(elementId, "embedUrlElementsState");
        this.saveChangesAction({
            embedUrlElementsState: {
                [elementId]: element,
            },
        });
        return elementId;
    }

    @action.bound public deleteEmbedUrlElementAction(elementId: string): void {
        this.embedUrlElementsState.delete(elementId);
        this.saveChangesAction({
            embedUrlElementsState: {
                [elementId]: null,
            },
        });
    }

    @action.bound public addShapeElementAction<T extends keyof ShapeElement>(
        props: Pick<ShapeElement, T>
    ): string | undefined {
        let shapeType: ShapeType;
        if ("shapeType" in props) {
            shapeType = (props as Pick<ShapeElement, "shapeType">).shapeType;
        } else {
            shapeType = ShapeType.Square;
        }
        let height: number = 100;
        let width: number = 100;
        let angle: number = 0;
        switch (shapeType) {
            case ShapeType.Line:
                height = 10;
                width = 200;
                break;
            case ShapeType.Arrow:
                height = 30;
                width = 200;
                break;
            case ShapeType.Parallelogram:
                angle = 45;
                break;
            default:
                break;
        }
        let shapeElement: ShapeElement = {
            nodePosition: {
                desktop: {
                    x: 100,
                    y: 100,
                },
                mobile: {
                    x: 100,
                    y: 100,
                },
            },
            nodeSize: {
                desktop: {
                    width: width,
                    height: height,
                },
                mobile: {
                    width: width,
                    height: height,
                },
            },
            shapeType: shapeType,
            width: width,
            height: height,
            angle: angle,
            ...props,
        };
        let shapeId = nanoid();
        this.shapeElementsState.set(shapeId, shapeElement);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: shapeId,
                type: "shapeElementsState",
            });
            shapeElement = this.shapeElementsState.get(
                shapeId
            )! as ShapeElement;
        }
        this.updateCanvasSizeAction({
            x: shapeElement.nodePosition[this.canvasViewMode].x,
            y: shapeElement.nodePosition[this.canvasViewMode].y,
            width: shapeElement.nodeSize[this.canvasViewMode].width,
            height: shapeElement.nodeSize[this.canvasViewMode].height,
        });
        this.moveFront(shapeId, "shapeElementsState");
        this.saveChangesAction({
            shapeElementsState: {
                [shapeId]: shapeElement,
            },
        });
        return shapeId;
    }

    @action.bound public deleteShapeElementAction(
        shapeElementId: string
    ): void {
        this.shapeElementsState.delete(shapeElementId);
        this.saveChangesAction({
            shapeElementsState: {
                [shapeElementId]: null,
            },
        });
    }

    // @action.bound public addEmptyInputDataElement<
    //     T extends keyof InputDataElement
    // >(props: Pick<InputDataElement, T>): void {
    //     let inputDataElement: InputDataElement = {
    //         x: 100,
    //         y: 100,
    //         width: 400,
    //         height: 200,
    //         inputData: defaultInputBaseState(),
    //         schemaOptions: defaultSchemaOptions(),
    //         ...props,
    //     };
    //     let inputDataId = nanoid();
    //     this.inputDataElementsState.set(inputDataId, inputDataElement);
    //     this.updateCanvasSizeAction({
    //         x: inputDataElement.x,
    //         y: inputDataElement.y,
    //         width: inputDataElement.width,
    //         height: inputDataElement.height,
    //     });
    //     this.saveChanges({
    //         inputDataElementsState: {
    //             [inputDataId]: inputDataElement,
    //         },
    //     });
    // }

    // @action.bound public deleteInputDataElement(
    //     inputDataElementId: string
    // ): void {
    //     this.inputDataElementsState.delete(inputDataElementId);
    //     this.saveChanges({
    //         inputDataElementsState: {
    //             [inputDataElementId]: null,
    //         },
    //     });
    // }

    // @action.bound public updateInputDataElement<
    //     T extends keyof InputDataElement
    // >(inputDataId: string, props: Pick<InputDataElement, T>): void {
    //     let inputDataElement = this.inputDataElementsState.get(inputDataId)!;
    //     inputDataElement = {
    //         ...inputDataElement,
    //         ...props,
    //     };
    //     this.inputDataElementsState.set(inputDataId, inputDataElement);
    //     this.saveChanges({
    //         inputDataElementsState: {
    //             [inputDataId]: CanvasTreeStoreInner.prepareInputDataElement(
    //                 inputDataElement
    //             ),
    //         },
    //     });
    // }

    @action.bound public addCanvasButtonAction<T extends keyof CanvasButton>(
        props: Pick<CanvasButton, T>
    ): string | undefined {
        let canvasButton: CanvasButton = {
            nodePosition: {
                desktop: {
                    x: 100,
                    y: 100,
                },
                mobile: {
                    x: 100,
                    y: 100,
                },
            },
            nodeSize: {
                desktop: {
                    width: 25,
                    height: 25,
                },
                mobile: {
                    width: 25,
                    height: 25,
                },
            },
            buttonType: CanvasButtonType.InputData,
            ...props,
        };
        let buttonId = nanoid();
        this.buttonsState.set(buttonId, canvasButton);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: buttonId,
                type: "buttonsState",
            });
            canvasButton = this.buttonsState.get(buttonId)! as CanvasButton;
        }
        this.updateCanvasSizeAction({
            x: canvasButton.nodePosition[this.canvasViewMode].x,
            y: canvasButton.nodePosition[this.canvasViewMode].y,
            width: canvasButton.nodeSize[this.canvasViewMode].width,
            height: canvasButton.nodeSize[this.canvasViewMode].height,
        });
        this.moveFront(buttonId, "buttonsState");
        this.saveChangesAction({
            buttonsState: {
                [buttonId]: canvasButton,
            },
        });
        return buttonId;
    }

    @action.bound public deleteCanvasButtonAction(
        canvasButtonId: string
    ): void {
        this.buttonsState.delete(canvasButtonId);
        this.saveChangesAction({
            buttonsState: {
                [canvasButtonId]: null,
            },
        });
    }

    @action.bound public updateCanvasButtonAction<T extends keyof CanvasButton>(
        canvasButtonId: string,
        props: Pick<CanvasButton, T>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let canvasButton = this.buttonsState.get(canvasButtonId);
        if (canvasButton == null) return;
        canvasButton = {
            ...canvasButton,
            ...props,
        };
        this.buttonsState.set(canvasButtonId, canvasButton);
        if (propagatedChanges == null) {
            this.saveChangesAction({
                buttonsState: {
                    [canvasButtonId]: canvasButton,
                },
            });
        } else {
            if (propagatedChanges.buttonsState == null) {
                propagatedChanges.buttonsState = {};
            }
            propagatedChanges.buttonsState[canvasButtonId] = canvasButton;
        }
    }

    @action.bound public addEmptyMergeDataElementAction<
        T extends keyof MergeDataElement
    >(props: Pick<MergeDataElement, T>): string | undefined {
        let mergeDataElement: MergeDataElement = {
            nodePosition: {
                desktop: {
                    x: 100,
                    y: 100,
                },
                mobile: {
                    x: 100,
                    y: 100,
                },
            },
            nodeSize: {
                desktop: {
                    width: 400,
                    height: 200,
                },
                mobile: {
                    width: 400,
                    height: 200,
                },
            },
            leftDataScope: null,
            leftTableOption: null,
            rightTableOption: null,
            rightDataScope: null,
            targetDataScope: null,
            mergeType: null,
            variableJoins: [
                {
                    leftVariable: null,
                    rightVariable: null,
                },
            ],
            permissions: [],
            dropMissingLeft: false,
            dropMissingRight: false,
            ...props,
        };
        let mergeDataElementId = nanoid();
        this.mergeDataElementsState.set(mergeDataElementId, mergeDataElement);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: mergeDataElementId,
                type: "mergeDataElementsState",
            });
            mergeDataElement = this.mergeDataElementsState.get(
                mergeDataElementId
            )! as MergeDataElement;
        }
        this.updateCanvasSizeAction({
            x: mergeDataElement.nodePosition[this.canvasViewMode].x,
            y: mergeDataElement.nodePosition[this.canvasViewMode].y,
            width: mergeDataElement.nodeSize[this.canvasViewMode].width,
            height: mergeDataElement.nodeSize[this.canvasViewMode].height,
        });
        this.moveFront(mergeDataElementId, "mergeDataElementsState");
        this.saveChangesAction({
            mergeDataElementsState: {
                [mergeDataElementId]: mergeDataElement,
            },
        });
        return mergeDataElementId;
    }

    @action.bound public deleteMergeDataElementAction(
        mergeDataElementId: string
    ): void {
        this.mergeDataElementsState.delete(mergeDataElementId);
        this.saveChangesAction({
            mergeDataElementsState: {
                [mergeDataElementId]: null,
            },
        });
    }

    @action.bound public updateMergeDataElementAction<
        T extends keyof MergeDataElement
    >(
        mergeDataElementId: string,
        props: Pick<MergeDataElement, T>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let mergeDataElement = this.mergeDataElementsState.get(
            mergeDataElementId
        );
        if (mergeDataElement == null) return;
        mergeDataElement = {
            ...mergeDataElement,
            ...props,
        };
        this.mergeDataElementsState.set(mergeDataElementId, mergeDataElement);
        if (propagatedChanges == null) {
            this.saveChangesAction({
                mergeDataElementsState: {
                    [mergeDataElementId]: mergeDataElement,
                },
            });
        } else {
            if (propagatedChanges.mergeDataElementsState == null) {
                propagatedChanges.mergeDataElementsState = {};
            }
            propagatedChanges.mergeDataElementsState[
                mergeDataElementId
            ] = mergeDataElement;
        }
    }

    @action.bound public addEmptyManageTableElementAction<
        T extends keyof ManageTableElement
    >(props: Pick<ManageTableElement, T>): string | undefined {
        let manageTableElement: ManageTableElement = {
            nodePosition: {
                desktop: {
                    x: 100,
                    y: 100,
                },
                mobile: {
                    x: 100,
                    y: 100,
                },
            },
            nodeSize: {
                desktop: {
                    width: 400,
                    height: 200,
                },
                mobile: {
                    width: 400,
                    height: 200,
                },
            },
            dataScope: null,
            tableOption: null,
            operationOption: null,
            ...props,
        };
        let manageTableElementId = nanoid();
        this.manageTableElementsState.set(
            manageTableElementId,
            manageTableElement
        );
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: manageTableElementId,
                type: "manageTableElementsState",
            });
            manageTableElement = this.manageTableElementsState.get(
                manageTableElementId
            )! as ManageTableElement;
        }
        this.updateCanvasSizeAction({
            x: manageTableElement.nodePosition[this.canvasViewMode].x,
            y: manageTableElement.nodePosition[this.canvasViewMode].y,
            width: manageTableElement.nodeSize[this.canvasViewMode].width,
            height: manageTableElement.nodeSize[this.canvasViewMode].height,
        });
        this.moveFront(manageTableElementId, "manageTableElementsState");
        this.saveChangesAction({
            manageTableElementsState: {
                [manageTableElementId]: manageTableElement,
            },
        });
        return manageTableElementId;
    }

    @action.bound public deleteManageTableElementAction(
        manageTableElementId: string
    ): void {
        this.manageTableElementsState.delete(manageTableElementId);
        this.saveChangesAction({
            manageTableElementsState: {
                [manageTableElementId]: null,
            },
        });
    }

    @action.bound public updateManageTableElementAction<
        T extends keyof ManageTableElement
    >(
        manageTableElementId: string,
        props: Pick<ManageTableElement, T>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let manageTableElement = this.manageTableElementsState.get(
            manageTableElementId
        );
        if (manageTableElement == null) return;
        manageTableElement = {
            ...manageTableElement,
            ...props,
        };
        this.manageTableElementsState.set(
            manageTableElementId,
            manageTableElement
        );
        if (propagatedChanges == null) {
            this.saveChangesAction({
                manageTableElementsState: {
                    [manageTableElementId]: manageTableElement,
                },
            });
        } else {
            if (propagatedChanges.manageTableElementsState == null) {
                propagatedChanges.manageTableElementsState = {};
            }
            propagatedChanges.manageTableElementsState[
                manageTableElementId
            ] = manageTableElement;
        }
    }

    @action.bound public addEmptyAggregateTableElementAction<
        T extends keyof AggregateTableElement
    >(props: Pick<AggregateTableElement, T>): string | undefined {
        let aggregateTableElement: AggregateTableElement = {
            nodePosition: {
                desktop: {
                    x: 100,
                    y: 100,
                },
                mobile: {
                    x: 100,
                    y: 100,
                },
            },
            nodeSize: {
                desktop: {
                    width: 400,
                    height: 200,
                },
                mobile: {
                    width: 400,
                    height: 200,
                },
            },
            ...defaultAggregateState(),
            ...props,
        };
        let aggregateTableElementId = nanoid();
        this.aggregateTableElementsState.set(
            aggregateTableElementId,
            aggregateTableElement
        );
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: aggregateTableElementId,
                type: "aggregateTableElementsState",
            });
            aggregateTableElement = this.aggregateTableElementsState.get(
                aggregateTableElementId
            )! as AggregateTableElement;
        }
        this.updateCanvasSizeAction({
            x: aggregateTableElement.nodePosition[this.canvasViewMode].x,
            y: aggregateTableElement.nodePosition[this.canvasViewMode].y,
            width: aggregateTableElement.nodeSize[this.canvasViewMode].width,
            height: aggregateTableElement.nodeSize[this.canvasViewMode].height,
        });
        this.moveFront(aggregateTableElementId, "aggregateTableElementsState");
        this.saveChangesAction({
            aggregateTableElementsState: {
                [aggregateTableElementId]: aggregateTableElement,
            },
        });
        return aggregateTableElementId;
    }

    @action.bound public deleteAggregateTableElementAction(
        aggregateTableElementId: string
    ): void {
        this.aggregateTableElementsState.delete(aggregateTableElementId);
        this.saveChangesAction({
            aggregateTableElementsState: {
                [aggregateTableElementId]: null,
            },
        });
    }

    @action.bound public updateAggregateTableElementAction<
        T extends keyof AggregateTableElement
    >(
        aggregateTableElementId: string,
        props: Pick<AggregateTableElement, T>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let aggregateTableElement = this.aggregateTableElementsState.get(
            aggregateTableElementId
        );
        if (aggregateTableElement == null) return;
        aggregateTableElement = {
            ...aggregateTableElement,
            ...props,
        };
        this.aggregateTableElementsState.set(
            aggregateTableElementId,
            aggregateTableElement
        );
        if (propagatedChanges == null) {
            this.saveChangesAction({
                aggregateTableElementsState: {
                    [aggregateTableElementId]: aggregateTableElement,
                },
            });
        } else {
            if (propagatedChanges.aggregateTableElementsState == null) {
                propagatedChanges.aggregateTableElementsState = {};
            }
            propagatedChanges.aggregateTableElementsState[
                aggregateTableElementId
            ] = aggregateTableElement;
        }
    }

    @action.bound public updateBackendTableContentAction(tableCell: {
        row_id: number;
        row: number;
        column: number;
        value: any;
        tableId: string;
    }) {
        let table = this.backendTablesState.get(tableCell.tableId);
        if (table == null) return;
        let connectedTable = this.connectedBackendTablesState.get(
            tableCell.tableId
        )!;
        let rawTable = { ...connectedTable.rawTable };

        let key = Object.keys(rawTable)[tableCell.column];
        rawTable[key] = Array.from(rawTable[key]);
        if (
            ["int", "float"].includes(
                Variables(
                    table?.tableOption?.data_table_idx,
                    this.moduleId ?? remoteModuleId
                ).getTypeByIndex(tableCell.column)
            )
        )
            rawTable[key][tableCell.row] = Number(tableCell.value);
        else rawTable[key][tableCell.row] = tableCell.value;
        let tableChanges = { ...connectedTable.tableChanges };
        if (!(tableCell.row_id in tableChanges)) {
            tableChanges[tableCell.row_id] = {};
        }
        tableChanges[tableCell.row_id] = {
            ...tableChanges[tableCell.row_id],
            [tableCell.column]: rawTable[key][tableCell.row],
        };
        this.connectedBackendTablesState.set(tableCell.tableId, {
            ...connectedTable,
            rawTable: rawTable,
            tableChanges: tableChanges,
        });
    }

    @action.bound public clearBackendTableContentChangesAction(
        tableId: string
    ): void {
        let connectedTable = this.connectedBackendTablesState.get(tableId)!;
        connectedTable = {
            ...connectedTable,
            tableChanges: {},
        };
        this.connectedBackendTablesState.set(tableId, connectedTable);
    }

    @action.bound public updateBackendTableAction<T extends keyof CanvasTable>(
        tableId: string,
        props: Pick<CanvasTable, T>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let backendTable = this.backendTablesState.get(tableId);
        if (backendTable == null) return;
        let newBackendTable = {
            ...backendTable,
            ...props,
        };
        this.backendTablesState.set(tableId, newBackendTable);
        if (
            backendTable.dataScopeOption?.value !==
            newBackendTable.dataScopeOption?.value
        ) {
            if (backendTable.dataScopeOption?.value != null) {
                this.onDataSetDisconnected(backendTable.dataScopeOption.value);
            }
            if (newBackendTable.dataScopeOption?.value != null) {
                this.onDataSetConnected(newBackendTable.dataScopeOption.value);
            }
        }
        if ("tableOption" in props) {
            if (
                (props as Pick<CanvasTable, "tableOption">).tableOption == null
            ) {
                this.connectedBackendTablesState.delete(tableId);
            } else {
                this.loadBackendTableAsyncAction(tableId);
            }
        }
        if (propagatedChanges == null) {
            this.saveChangesAction({
                backendTablesState: {
                    [tableId]: newBackendTable,
                },
            });
        } else {
            if (propagatedChanges.backendTablesState == null) {
                propagatedChanges.backendTablesState = {};
            }
            propagatedChanges.backendTablesState[tableId] = newBackendTable;
        }
    }

    @action.bound public updateQuestionnaireElementAction<
        T extends keyof QuestionnaireElement
    >(
        questionnaireId: string,
        props: Pick<QuestionnaireElement, T>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let currentElement = this.questionnaireElementsState.get(
            questionnaireId
        );
        if (currentElement == null) return;
        let newElement: QuestionnaireElement = {
            ...currentElement,
            ...props,
        };
        this.questionnaireElementsState.set(questionnaireId, newElement);

        if (
            currentElement.backendOutput?.dataScopeOption?.value !==
            newElement.backendOutput?.dataScopeOption?.value
        ) {
            if (currentElement.backendOutput?.dataScopeOption?.value != null) {
                this.onDataSetDisconnected(
                    currentElement.backendOutput.dataScopeOption.value
                );
            }
            if (newElement.backendOutput?.dataScopeOption?.value != null) {
                this.onDataSetConnected(
                    newElement.backendOutput.dataScopeOption.value
                );
            }
        }

        if (propagatedChanges == null) {
            this.saveChangesAction({
                questionnaireElementsState: {
                    [questionnaireId]: newElement,
                },
            });
        } else {
            if (propagatedChanges.questionnaireElementsState == null) {
                propagatedChanges.questionnaireElementsState = {};
            }
            propagatedChanges.questionnaireElementsState[
                questionnaireId
            ] = newElement;
        }
    }

    @action.bound public updateMapElementAction<T extends keyof MapElement>(
        mapId: string,
        props: Pick<MapElement, T>,
        geoJsonFiles?: MapElement["geoJsonFiles"],
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let currentElement = this.mapElementsState.get(mapId);
        if (currentElement == null) return;
        let newElement: MapElement = {
            ...currentElement,
            ...props,
        };
        if (geoJsonFiles != null) {
            newElement.geoJsonFiles = {
                ...newElement.geoJsonFiles,
                ...geoJsonFiles,
            };
        }
        this.mapElementsState.set(mapId, newElement);
        if (propagatedChanges == null) {
            this.saveChangesAction({
                mapElementsState: {
                    [mapId]: newElement,
                },
            });
        } else {
            if (propagatedChanges.mapElementsState == null) {
                propagatedChanges.mapElementsState = {};
            }
            propagatedChanges.mapElementsState[mapId] = newElement;
        }
    }

    @action.bound public updateGraphElementAction<T extends keyof GraphElement>(
        graphId: string,
        props: Pick<GraphElement, T>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let currentElement = this.graphElementsState.get(graphId);
        if (currentElement == null) return;
        let newElement: GraphElement = {
            ...currentElement,
            ...props,
        };
        this.graphElementsState.set(graphId, newElement);
        if (propagatedChanges == null) {
            this.saveChangesAction({
                graphElementsState: {
                    [graphId]: newElement,
                },
            });
        } else {
            if (propagatedChanges.graphElementsState == null) {
                propagatedChanges.graphElementsState = {};
            }
            propagatedChanges.graphElementsState[graphId] = newElement;
        }
    }

    @action.bound public updateEmbedUrlElementAction<
        T extends keyof EmbedUrlElement
    >(
        elementId: string,
        props: Pick<EmbedUrlElement, T>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let currentElement = this.embedUrlElementsState.get(elementId);
        if (currentElement == null) return;
        let newElement: EmbedUrlElement = {
            ...currentElement,
            ...props,
        };
        this.embedUrlElementsState.set(elementId, newElement);
        if (propagatedChanges == null) {
            this.saveChangesAction({
                embedUrlElementsState: {
                    [elementId]: newElement,
                },
            });
        } else {
            if (propagatedChanges.embedUrlElementsState == null) {
                propagatedChanges.embedUrlElementsState = {};
            }
            propagatedChanges.embedUrlElementsState[elementId] = newElement;
        }
    }

    @action.bound public updateShapeElementAction<T extends keyof ShapeElement>(
        shapeId: string,
        props: Pick<ShapeElement, T>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        let currentElement = this.shapeElementsState.get(shapeId);
        if (currentElement == null) return;
        let newElement: ShapeElement = {
            ...currentElement,
            ...props,
        };
        this.shapeElementsState.set(shapeId, newElement);
        if (propagatedChanges == null) {
            this.saveChangesAction({
                shapeElementsState: {
                    [shapeId]: newElement,
                },
            });
        } else {
            if (propagatedChanges.shapeElementsState == null) {
                propagatedChanges.shapeElementsState = {};
            }
            propagatedChanges.shapeElementsState[shapeId] = newElement;
        }
    }

    @action.bound public deleteBackendTableAction(tableId: string): void {
        let backendTable = this.backendTablesState.get(tableId);
        this.backendTablesState.delete(tableId);
        this.connectedBackendTablesState.delete(tableId);

        if (backendTable?.dataScopeOption?.value != null) {
            this.onDataSetDisconnected(backendTable.dataScopeOption.value);
        }

        this.saveChangesAction({
            backendTablesState: {
                [tableId]: null,
            },
        });
    }

    @action.bound public addBackgroundAction(
        backgroundUrl: string,
        isRoot: boolean,
        naturalWidth: number,
        naturalHeight: number,
        onFinished: (id: number) => void = () => {}
    ): void {
        if (this.canvasPageId == null) return;
        if (isRoot) {
            this.deleteRootBackgroundAction();
        }
        let slideRect = this.slideRect();
        let backgroundState: CanvasBackground = {
            image_url: backgroundUrl,
            x: (slideRect.width - naturalWidth) / 2,
            y: (slideRect.height - naturalHeight) / 2,
            scale_x: 1,
            scale_y: 1,
            id: NaN,
            is_root: isRoot,
            natural_width: naturalWidth,
            natural_height: naturalHeight,
            mobile_x: (slideRect.width - naturalWidth) / 2,
            mobile_y: (slideRect.height - naturalHeight) / 2,
            mobile_scale_x: 1,
            mobile_scale_y: 1,
        };
        this.saveChangesAction(
            {},
            false,
            false,
            false,
            [backgroundState],
            BackgroundMode.Append,
            false,
            (response) => {
                backgroundState.id = response.background_ids[0];
                this.backgroundsState.push(backgroundState);
                // We have to update currentSlideState here, otherwise
                // history will work incorrectly.
                if (this.canvasId != null) {
                    this.currentSlideState[this.canvasId] = {
                        canvas:
                            this.currentSlideState[this.canvasId]?.canvas ??
                            this.serialize(),
                        backgrounds: this.backgroundsState.toJSON(),
                    };
                }
                onFinished(backgroundState.id);
            }
        );
    }

    @action.bound public addBackgroundsAction(
        backgrounds: {
            backgroundUrl: string;
            naturalWidth: number;
            naturalHeight: number;
            x: number;
            y: number;
        }[],
        onFinished: (ids: number[]) => void = () => {}
    ): void {
        if (this.canvasPageId == null) return;
        let backgroundsState: CanvasBackground[] = [];
        for (let background of backgrounds) {
            backgroundsState.push({
                image_url: background.backgroundUrl,
                x: background.x,
                y: background.y,
                scale_x: 1,
                scale_y: 1,
                id: NaN,
                is_root: false,
                natural_width: background.naturalWidth,
                natural_height: background.naturalHeight,
                mobile_x: background.x,
                mobile_y: background.y,
                mobile_scale_x: 1,
                mobile_scale_y: 1,
            });
        }

        this.saveChangesAction(
            {},
            false,
            false,
            false,
            backgroundsState,
            BackgroundMode.Append,
            false,
            (response) => {
                backgroundsState.forEach((background, index) => {
                    background.id = response.background_ids[index];
                });
                this.backgroundsState.replace(
                    this.backgroundsState.concat(backgroundsState)
                );
                // We have to update currentSlideState here, otherwise
                // history will work incorrectly.
                if (this.canvasId != null) {
                    this.currentSlideState[this.canvasId] = {
                        canvas:
                            this.currentSlideState[this.canvasId]?.canvas ??
                            this.serialize(),
                        backgrounds: this.backgroundsState.toJSON(),
                    };
                }
                onFinished(response.background_ids);
            }
        );
    }

    @action.bound public updateBackgroundAction<
        T extends keyof CanvasBackground
    >(
        id: number,
        props: Pick<CanvasBackground, T>,
        // If propagatedChanges is set, then this method does not call
        // saveChangesAction and writes all changes here instead
        propagatedChanges?: InnerCanvasChanges
    ): void {
        if (this.canvasPageId == null) return;
        let index = this.backgroundsState.findIndex((val) => val.id === id);
        if (index < 0) return;
        this.backgroundsState[index] = {
            ...this.backgroundsState[index],
            ...props,
        };

        if (propagatedChanges == null) {
            this.saveChangesAction(
                {},
                false,
                false,
                false,
                [this.backgroundsState[index]],
                BackgroundMode.Update
            );
        }
        // We don't need to propagate backgrounds
    }

    @action.bound public deleteRootBackgroundAction() {
        let index = this.backgroundsState.findIndex((val) => val.is_root);
        if (index < 0) return;
        let backgroundState = this.backgroundsState[index];
        this.deleteBackgroundAction(backgroundState.id);
    }

    @action.bound public deleteBackgroundAction(id: number): void {
        if (this.canvasPageId == null) return;
        let index = this.backgroundsState.findIndex((val) => val.id === id);
        if (index < 0) return;
        let backgroundState = this.backgroundsState[index];
        this.backgroundsState.splice(index, 1);
        this.saveChangesAction(
            {},
            false,
            false,
            false,
            [backgroundState],
            BackgroundMode.Delete
        );
    }

    @action.bound public deleteBackgroundsAction(ids: number[]): void {
        let idsSet = new Set<number>(ids);
        if (this.canvasPageId == null) return;
        let backgroundsToRemove: CanvasBackground[] = [];
        let newBackgroundsState: CanvasBackground[] = [];
        for (let background of this.backgroundsState) {
            if (idsSet.has(background.id)) {
                backgroundsToRemove.push(background);
            } else {
                newBackgroundsState.push(background);
            }
        }
        if (backgroundsToRemove.length === 0) return;
        this.backgroundsState.replace(newBackgroundsState);
        this.saveChangesAction(
            {},
            false,
            false,
            false,
            backgroundsToRemove,
            BackgroundMode.Delete
        );
    }

    @action.bound public addAdditionalOutputAction(
        nodeId: number,
        index: number
    ) {
        let node = this.canvasTreeState.get(nodeId)!;
        if (!isBox(node) || !isTextBox(node)) return;
        node = {
            ...node,
            additionalOutputs: [...node.additionalOutputs],
        } as CanvasElement;
        (node as CanvasElement).additionalOutputs.splice(index, 0, {
            metric: "",
            value: NaN,
            unit: "",
            decimalPoints: 2,
        });
        this.canvasTreeState.set(nodeId, node);
        this.saveChangesAction({
            canvasTreeState: {
                [nodeId]: node,
            },
        });
    }

    @action.bound public addSurveyElement<T extends keyof CanvasSurvey>(
        props: Pick<CanvasSurvey, T>
    ): number | undefined {
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;
        const nodeId = this.canvasAutoIncrementId;

        let node: CanvasSurvey = {
            id: nodeId,
            x: 200,
            y: 400,
            nodePosition: {
                desktop: { x: 0, y: 0 },
                mobile: { x: 0, y: 0 },
            },
            nodeSize: {
                desktop: {
                    width: 400,
                    height: 200,
                },
                mobile: {
                    width: 400,
                    height: 200,
                },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            childrenIds: [],
            childrenSharedIds: [],
            parentIds: [],
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            value: "",
            metric: "Survey",
            canvasType: CanvasType.Survey,
            backendOutput: {
                dataScopeOption: null,
                tableOption: null,
                variableOptions: [
                    {
                        node: null,
                        variable: null,
                    },
                ],
            },
            questions: [
                {
                    id: 1,
                    type: SurveyQuestionType.Text,
                    question: "Question 1...",
                    options: [],
                    required: false,
                },
                {
                    id: 2,
                    type: SurveyQuestionType.Text,
                    question: "Question 2...",
                    options: [],
                    required: false,
                },
                {
                    id: 3,
                    type: SurveyQuestionType.Text,
                    question: "Question 3...",
                    options: [],
                    required: false,
                },
            ],
            ...props,
        };
        this.canvasTreeState.set(nodeId, node);
        this.centerSurvey({
            id: nodeId,
            type: "canvasTreeState",
        });
        node = this.canvasTreeState.get(nodeId)! as CanvasSurvey;

        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });

        return nodeId;
    }

    @action.bound public addBarcodeReaderInput<
        T extends keyof CanvasBarcodeReader
    >(props: Pick<CanvasBarcodeReader, T>): number | undefined {
        this.canvasAutoIncrementId += 1;
        this.nodeSpreadSheetAutoIncrementId += 1;

        const nodeId = this.canvasAutoIncrementId;

        let node: CanvasBarcodeReader = {
            id: nodeId,
            x: 100,
            y: 100,
            nodePosition: {
                desktop: { x: 100, y: 100 },
                mobile: { x: 100, y: 100 },
            },
            nodeIsHidden: {
                desktop: false,
                mobile: false,
            },
            childrenIds: [],
            childrenSharedIds: [],
            parentIds: [],
            outerId: outerNodeId(this.nodeSpreadSheetAutoIncrementId),
            value: "",
            metric: "BarcodeReader",
            canvasType: CanvasType.BarcodeReader,
            backendOutput: {
                dataScopeOption: null,
                tableOption: null,
                variableOptions: [
                    {
                        node: null,
                        variable: null,
                    },
                ],
            },
            ...props,
        };

        this.canvasTreeState.set(nodeId, node);
        if (!props || (!("x" in props) && !("y" in props))) {
            this.centerObject({
                id: nodeId,
                type: "canvasTreeState",
            });
            node = this.canvasTreeState.get(nodeId)! as CanvasBarcodeReader;
        }
        this.moveFront(nodeId, "canvasTreeState");
        this.saveChangesAction({
            canvasAutoIncrementId: this.canvasAutoIncrementId,
            nodeSpreadSheetAutoIncrementId: this.nodeSpreadSheetAutoIncrementId,
            canvasTreeState: {
                [nodeId]: node,
            },
        });
        return nodeId;
    }

    public async updateOtherElementsByLinkedInputs(
        changes: InnerCanvasChanges,
        updateGrids: boolean = false
    ) {
        let needSave: boolean = false;
        let dashboardChanges: { [key: string]: CanvasDashboard } = {};
        let gridChanges: { [key: string]: CanvasSpreadSheetGrid } = {};
        let mapChanges: { [key: string]: MapElement } = {};
        let canvasId = this.canvasId;
        for (let itemId of Object.keys(changes.canvasTreeState ?? {})) {
            for (let [id, item] of this.dashboardsState.entries()) {
                let findings = [item.finding];
                if (item.finding && "additionalMapsFindings" in item.finding) {
                    findings = [
                        item.finding,
                        ...((item.finding as MapFinding | NetworkFinding)
                            ?.additionalMapsFindings ?? []),
                    ];
                }
                let needDelete = changes.canvasTreeState![itemId] == null;
                let numberId = Number(itemId);
                let changed = false;
                let needUpdateFinding = false;
                for (let finding of findings) {
                    for (let condition of finding?.config?.conditions ?? []) {
                        let localChanged = this.updateConditionInput(
                            condition,
                            numberId,
                            needDelete
                        );
                        changed = changed || localChanged;
                        needSave = needSave || changed;
                        needUpdateFinding = needUpdateFinding || changed;
                    }
                }

                if (changed) {
                    if (needUpdateFinding) {
                        try {
                            let newFinding = await DashboardUpdater.updateDashboardFinding(
                                item.finding,
                                item.version,
                                this.moduleId ?? remoteModuleId
                            );
                            this.canvasDashboardErrorsState.set(id, null);
                            dashboardChanges[id] = {
                                ...item,
                                finding: newFinding,
                            };
                        } catch (error) {
                            this.canvasDashboardErrorsState.set(
                                id,
                                `Error: ${String(error)}`
                            );
                        }
                    } else {
                        dashboardChanges[id] = {
                            ...item,
                            finding: item.finding,
                        };
                    }
                }
            }

            for (let [id, item] of this.gridsState.entries()) {
                let needDelete = changes.canvasTreeState![itemId] == null;
                let numberId = Number(itemId);
                let changed = false;
                for (let condition of item.fullSpreadSheetBackendOutputOptions
                    ?.conditions ?? []) {
                    let localChanged = this.updateConditionInput(
                        condition,
                        numberId,
                        needDelete
                    );
                    changed = changed || localChanged;
                    needSave = needSave || changed;
                }
                if (changed) {
                    gridChanges[id] = {
                        ...item,
                        fullSpreadSheetBackendOutputOptions:
                            item.fullSpreadSheetBackendOutputOptions,
                    };
                }
            }
            for (let [id, item] of this.mapElementsState.entries()) {
                let needDelete = changes.canvasTreeState![itemId] == null;
                let numberId = Number(itemId);
                let changed = false;
                for (let condition of item?.conditions ?? []) {
                    let localChanged = this.updateConditionInput(
                        condition,
                        numberId,
                        needDelete
                    );
                    changed = changed || localChanged;
                    needSave = needSave || changed;
                }
                if (changed) {
                    mapChanges[id] = {
                        ...item,
                        conditions: item.conditions,
                    };
                }
            }
        }
        for (let globalInput of GlobalInputs) {
            for (let [id, item] of this.gridsState.entries()) {
                let changed = false;
                for (let condition of item.fullSpreadSheetBackendOutputOptions
                    ?.conditions ?? []) {
                    let localChanged = this.updateConditionInput(
                        condition,
                        globalInput.value,
                        false,
                        true
                    );
                    changed = changed || localChanged;
                    needSave = needSave || changed;
                }
                if (changed) {
                    gridChanges[id] = {
                        ...item,
                        fullSpreadSheetBackendOutputOptions:
                            item.fullSpreadSheetBackendOutputOptions,
                    };
                }
            }
            for (let [id, item] of this.mapElementsState.entries()) {
                let changed = false;
                for (let condition of item?.conditions ?? []) {
                    let localChanged = this.updateConditionInput(
                        condition,
                        globalInput.value,
                        false,
                        true
                    );
                    changed = changed || localChanged;
                    needSave = needSave || changed;
                }
                if (changed) {
                    mapChanges[id] = {
                        ...item,
                        conditions: item.conditions,
                    };
                }
            }
        }
        if (canvasId !== this.canvasId) return;
        if (needSave) {
            for (let [dashboardId, dashboard] of Object.entries(
                dashboardChanges
            ))
                this.dashboardsState.set(dashboardId, dashboard);
            for (let [gridId, grid] of Object.entries(gridChanges))
                this.gridsState.set(gridId, grid);
            for (let [mapId, map] of Object.entries(mapChanges)) {
                this.mapElementsState.set(mapId, map);
                this.canvasOutdatedMaps.add(mapId);
            }
        }
        if (updateGrids) this.updateGrids(Object.keys(gridChanges));
    }

    public async updateOtherElementsByDataset(dataScopeId: number | string) {
        let needSave: boolean = false;
        let dashboardChanges: { [key: string]: CanvasDashboard } = {};
        let canvasId = this.canvasId;
        for (let [id, item] of this.dashboardsState.entries()) {
            let needUpdateFinding =
                item.finding?.config.dataScope?.value === dataScopeId;
            if (needUpdateFinding) {
                needSave = true;
                try {
                    let newFinding = await DashboardUpdater.updateDashboardFinding(
                        item.finding,
                        item.version,
                        this.moduleId ?? remoteModuleId
                    );
                    dashboardChanges[id] = {
                        ...item,
                        finding: newFinding,
                    };
                    this.canvasDashboardErrorsState.set(id, null);
                } catch (error) {
                    this.canvasDashboardErrorsState.set(
                        id,
                        `Error: ${String(error)}`
                    );
                }
            }
        }
        for (let [id, item] of this.mapElementsState.entries()) {
            if (item.dataScope?.value === dataScopeId) {
                this.canvasOutdatedMaps.add(id);
            }
        }

        if (canvasId !== this.canvasId) return;
        if (needSave) {
            for (let [dashboardId, dashboard] of Object.entries(
                dashboardChanges
            ))
                this.dashboardsState.set(dashboardId, dashboard);
            // this.saveChangesAction({
            //     dashboardsState: dashboardChanges,
            // });
        }
    }

    private updateConditionInput(
        condition: Condition,
        numberId: number,
        needDelete: boolean,
        isGlobalInput?: boolean,
        optionalCanvasTreeState?: Map<number, CanvasNode>
    ): boolean {
        const canvasTreeState = optionalCanvasTreeState ?? this.canvasTreeState;
        const conditionValue = condition.value as NodeLinkOption;
        let changed: boolean = false;
        if (
            !isMultiCondition(condition) &&
            condition.isInput &&
            condition.value != null &&
            !conditionValue.isCloneInput &&
            conditionValue.value === numberId
        ) {
            if (
                conditionValue.isGlobalInput &&
                isGlobalInput &&
                numberId in GlobalInputsMap
            ) {
                let newTarget = getGlobalInputValue(numberId, true);
                if (conditionValue.target !== newTarget) {
                    conditionValue.target = newTarget;
                    changed = true;
                }
            } else if (!conditionValue.isGlobalInput) {
                let linkedInput = canvasTreeState.get(numberId)!;
                if (needDelete) {
                    condition.value = null;
                    changed = true;
                } else {
                    let newTarget;

                    if (conditionValue.label === linkedInput.outerId) {
                        newTarget = getTargetValue(linkedInput);
                    } else {
                        const additionalOutputs = (linkedInput as CanvasSlider)
                            ?.additionalOutputs;
                        if (additionalOutputs) {
                            const output = additionalOutputs.find(
                                (output: CanvasElementOutput) => {
                                    return (
                                        output.outerId === conditionValue.label
                                    );
                                }
                            );

                            if (output) newTarget = getTargetValue(output);
                        }
                    }
                    if (conditionValue.target !== newTarget) {
                        conditionValue.target = newTarget;
                        changed = true;
                    }
                }
            }
        }
        if (
            isMultiCondition(condition) &&
            condition.isInput &&
            condition.value != null &&
            Array.isArray(condition.value) &&
            condition.value.length > 0
        ) {
            let indicesToRemove: number[] = [];
            for (let index in condition.value) {
                let subValue: SearchComponentOption | NodeLinkOption =
                    condition.value[index];
                if (
                    subValue != null &&
                    !(subValue as NodeLinkOption).isCloneInput &&
                    (subValue as NodeLinkOption).value === numberId
                ) {
                    if (
                        (subValue as NodeLinkOption).isGlobalInput &&
                        isGlobalInput &&
                        numberId in GlobalInputsMap
                    ) {
                        let newTarget = getGlobalInputValue(numberId, true);
                        if ((subValue as NodeLinkOption).target !== newTarget) {
                            (subValue as NodeLinkOption).target = newTarget;
                            changed = true;
                        }
                    } else if (!(subValue as NodeLinkOption).isGlobalInput) {
                        let linkedInput = canvasTreeState.get(numberId)!;
                        if (needDelete) {
                            indicesToRemove.push(Number(index));
                            changed = true;
                        } else {
                            if (!isDropdownSelector(linkedInput)) {
                                let newTarget = getTargetValue(linkedInput);
                                if (
                                    (subValue as NodeLinkOption).target !==
                                    newTarget
                                ) {
                                    (subValue as NodeLinkOption).target = newTarget;
                                    changed = true;
                                }
                            } else {
                                let newTarget = [getTargetValue(linkedInput)];
                                if (hasAdditionalOutputs(linkedInput)) {
                                    for (let output of linkedInput.additionalOutputs) {
                                        newTarget.push(
                                            getOutputTargetValue(output)
                                        );
                                    }
                                }
                                if (
                                    !_.isEqual(
                                        (subValue as NodeLinkOption).target,
                                        newTarget
                                    )
                                ) {
                                    (subValue as NodeLinkOption).target = newTarget;
                                    changed = true;
                                }
                            }
                        }
                    }
                }
            }
            condition.value = condition.value.filter(
                (item, index) => !indicesToRemove.includes(index)
            );
        }

        return changed;
    }

    private updateStatusExpressionSharedInput(
        statusExpression:
            | StatusExpression
            | NotificationExpression
            | PrintExpression,
        sharedBox: CanvasSharedBoxElement
    ): boolean {
        let changed: boolean = false;
        for (let subExpression of statusExpression.subexpressions) {
            if (
                subExpression.isInput &&
                subExpression.value != null &&
                (subExpression.value as NodeLinkOption).isCloneInput &&
                (subExpression.value as NodeLinkOption).value === sharedBox.id
            ) {
                let newTarget = getTargetValue(sharedBox.box);
                if (
                    newTarget !== (subExpression.value as NodeLinkOption).target
                ) {
                    changed = true;
                    (subExpression.value as NodeLinkOption).target = newTarget;
                }
            }
        }
        return changed;
    }
    private updateStatusExpressionInput(
        statusExpression:
            | StatusExpression
            | NotificationExpression
            | PrintExpression,
        numberId: number,
        needDelete: boolean,
        optionalCanvasTreeState?: Map<number, CanvasNode>,
        isGlobalInput?: boolean
    ): boolean {
        const canvasTreeState = optionalCanvasTreeState ?? this.canvasTreeState;
        let changed: boolean = false;
        for (let subExpression of statusExpression.subexpressions) {
            if (
                subExpression.isInput &&
                subExpression.value != null &&
                !(subExpression.value as NodeLinkOption).isCloneInput &&
                !(subExpression.value as NodeLinkOption).isGlobalInput &&
                !isGlobalInput &&
                (subExpression.value as NodeLinkOption).value === numberId
            ) {
                let linkedInput = canvasTreeState.get(numberId)!;
                if (needDelete) {
                    subExpression.value = null;
                    changed = true;
                } else {
                    let newTarget = getTargetValue(linkedInput);
                    if (
                        newTarget !==
                        (subExpression.value as NodeLinkOption).target
                    ) {
                        changed = true;
                        (subExpression.value as NodeLinkOption).target = newTarget;
                    }
                }
            }
            if (
                subExpression.isInput &&
                subExpression.value != null &&
                (subExpression.value as NodeLinkOption).isGlobalInput &&
                isGlobalInput &&
                numberId in GlobalInputsMap &&
                (subExpression.value as NodeLinkOption).value === numberId
            ) {
                let newTarget = getGlobalInputValue(numberId, true);
                if (
                    (subExpression.value as NodeLinkOption).target !== newTarget
                ) {
                    (subExpression.value as NodeLinkOption).target = newTarget;
                    changed = true;
                }
            }
        }

        return changed;
    }

    @action.bound public updateNodesByDatasetAction(
        dataScopeId: number | string
    ) {
        let needCalculate = false;
        let canvasId = this.canvasId;

        let inputDataInputNodes: CanvasNode[] = [];
        let changes: InnerCanvasChanges = {};
        this.canvasTreeState.forEach((item) => {
            if (
                (isTextBox(item) ||
                    isBox(item) ||
                    isProgressElement(item) ||
                    isSlider(item)) &&
                item.dataTableInputDetails != null &&
                item.dataTableInputDetails.length !== 0
            ) {
                inputDataInputNodes.push(item);
            } else if (
                isRadioButtonsGroup(item) &&
                item.dataScopeOption?.value === dataScopeId
            ) {
                this.updateNodeAction(
                    item.id,
                    {
                        ...item,
                        update_time: Math.floor(Date.now() / 1000),
                    },
                    true,
                    false,
                    false,
                    changes
                );
            }
        });
        this.gridsState.forEach((grid) => {
            if (
                isSpreadSheetGrid(grid) &&
                grid.fullSpreadSheetBackendOutputOptions != null &&
                grid.fullSpreadSheetBackendOutputOptions.dataScopeId ===
                    dataScopeId
            ) {
                this.readDataIntoSpreadSheet(
                    grid.id,
                    grid.fullSpreadSheetBackendOutputOptions.dataScopeId,
                    grid.fullSpreadSheetBackendOutputOptions.tableOption ??
                        null,
                    grid.fullSpreadSheetBackendOutputOptions.limit ?? 10,
                    true,
                    false,
                    grid.fullSpreadSheetBackendOutputOptions.conditions ?? [],
                    grid.fullSpreadSheetBackendOutputOptions.variables ?? [],
                    grid.fullSpreadSheetBackendOutputOptions!.bottomRows ??
                        false,
                    undefined,
                    undefined,
                    // Reactions should not add a history entry
                    // https://eisengardai.atlassian.net/browse/EIS-369?focusedCommentId=12719
                    true
                );
            }
        });

        if (canvasId != null) {
            this.saveChangesAction(
                changes,
                true,
                true,
                false,
                [],
                BackgroundMode.Ignore,
                // Reactions should not add a history entry
                // https://eisengardai.atlassian.net/browse/EIS-369?focusedCommentId=12719
                true,
                () => {
                    /*needed to comment this out to avoid interuption on survey component
                    need to add on other place latter*/
                    //this.onError("Uploaded Successfully");
                }
            );
        }

        for (let node of inputDataInputNodes) {
            for (let dataTableInput of (node as CanvasElement)
                .dataTableInputDetails ?? []) {
                if (dataTableInput.data_table_idx === dataScopeId)
                    needCalculate = true;
            }
        }

        if (needCalculate) {
            // let changes = this.calculateValues();
            // this.saveChanges({ canvasTreeState: { changes } });
            this.updateArgsAsyncAction
                .bothParts(true, this.canvasTreeState, dataScopeId)
                .then(() => {
                    if (canvasId !== this.canvasId) return;
                    this.calculateValuesAction();
                    // this.saveChangesAction({
                    //     canvasTreeState: changes,
                    // });
                });
        }
        this.updateOtherElementsByDataset(dataScopeId);
        //   this.updateOtherElementsByLinkedInputs(changes);
    }
    public async updateDashboards(ids?: string[]) {
        let needSave: boolean = false;
        let dashboardChanges: { [key: string]: CanvasDashboard } = {};
        let canvasId = this.canvasId;
        for (let [id, item] of this.dashboardsState.entries()) {
            if (ids != null && !ids.includes(id)) continue;
            try {
                let newFinding = await DashboardUpdater.updateDashboardFinding(
                    item.finding,
                    item.version,
                    this.moduleId ?? remoteModuleId
                );
                dashboardChanges[id] = {
                    ...item,
                    finding: newFinding,
                };
                needSave = true;

                this.canvasDashboardErrorsState.set(id, null);
            } catch (error) {
                this.canvasDashboardErrorsState.set(
                    id,
                    `Error: ${String(error)}`
                );
            }
        }

        if (canvasId !== this.canvasId) return;
        if (needSave) {
            for (let [dashboardId, dashboard] of Object.entries(
                dashboardChanges
            ))
                this.dashboardsState.set(dashboardId, dashboard);
        }
    }

    public updateGrids(ids?: string[]) {
        let needUpdateAll: boolean = false;
        let canvasId = this.canvasId;
        Promise.all(
            this.gridsState.toJSON().map(async ([key, item]) => {
                if (item.fullSpreadSheetBackendOutputOptions != null) {
                    await this.readDataIntoSpreadSheet(
                        item.id,
                        item.fullSpreadSheetBackendOutputOptions.dataScopeId,
                        item.fullSpreadSheetBackendOutputOptions!.tableOption ??
                            null,
                        item.fullSpreadSheetBackendOutputOptions!.limit ?? 10,
                        true,
                        false,
                        item.fullSpreadSheetBackendOutputOptions!.conditions ??
                            [],
                        item.fullSpreadSheetBackendOutputOptions!.variables ??
                            [],
                        item.fullSpreadSheetBackendOutputOptions!.bottomRows ??
                            false
                    );
                    needUpdateAll = true;
                }
            })
        )
            .then(() => {
                if (canvasId !== this.canvasId) return;
                if (needUpdateAll)
                    this.updateAllAsyncAction.bothParts(
                        false,
                        this.canvasTreeState
                    );
            })
            .catch((error) => {
                console.log(error);
            });
    }

    @action.bound public updateNodesByLinkedInputsAction(
        changes: InnerCanvasChanges,
        updateGrids: boolean = false,
        optionalCanvasTreeState?: Map<number, CanvasNode>
    ) {
        const canvasTreeState = optionalCanvasTreeState ?? this.canvasTreeState;
        let needCalculate = false;
        let newChanges: { [key: string]: CanvasElement | CanvasTextBox } = {};
        let needSave = false;
        let canvasId = this.canvasId;
        let inputDataInputNodes: CanvasNode[] = [];
        let inputStatusNodes: CanvasNode[] = [];
        let inputNotificationNodes: CanvasNode[] = [];
        let inputPrintNodes: CanvasTextBox[] = [];
        let inputDropdownSelectors: CanvasDropdownSelector[] = [];
        canvasTreeState.forEach((item) => {
            if (
                (isTextBox(item) ||
                    isBox(item) ||
                    isProgressElement(item) ||
                    isSlider(item)) &&
                item.dataTableInputDetails != null &&
                item.dataTableInputDetails.length !== 0
            ) {
                inputDataInputNodes.push(item);
            }
            if (isDropdownSelector(item)) {
                inputDropdownSelectors.push(item);
            }
            if (
                (isTextBox(item) || isBox(item)) &&
                item.statusExpressions != null &&
                item.statusExpressions.length !== 0
            ) {
                inputStatusNodes.push(item);
            }
            if (
                (isTextBox(item) || isBox(item)) &&
                item.notificationExpressions != null &&
                item.notificationExpressions.length !== 0
            ) {
                inputNotificationNodes.push(item);
            }
            if (
                isTextBox(item) &&
                item.printExpressions != null &&
                item.printExpressions.length !== 0
            ) {
                inputPrintNodes.push(item);
            }
        });
        for (let itemId of Object.keys(changes.canvasTreeState ?? {})) {
            let needDelete = changes.canvasTreeState![itemId] == null;
            let numberId = Number(itemId);
            for (let node of inputDataInputNodes) {
                for (let dataTableInput of (node as CanvasElement)
                    .dataTableInputDetails ?? []) {
                    for (let condition of dataTableInput.conditions) {
                        let changed = this.updateConditionInput(
                            condition,
                            numberId,
                            needDelete,
                            false,
                            optionalCanvasTreeState
                        );
                        needCalculate = needCalculate || changed;
                        needSave = needSave || changed;
                        if (changed) {
                            newChanges[node.id] = {
                                ...newChanges[node.id],
                                ...node,
                            };
                        }
                    }
                }
            }
            for (let node of inputDropdownSelectors) {
                for (let condition of node.conditions ?? []) {
                    let changed = this.updateConditionInput(
                        condition,
                        numberId,
                        needDelete,
                        false,
                        optionalCanvasTreeState
                    );
                    needCalculate = needCalculate || changed;
                    needSave = needSave || changed;
                    if (changed) {
                        newChanges[node.id] = {
                            ...newChanges[node.id],
                            ...node,
                        };
                    }
                }
            }
            for (let node of inputStatusNodes) {
                for (let statusExpression of (node as CanvasElement)
                    .statusExpressions ?? []) {
                    let changed = this.updateStatusExpressionInput(
                        statusExpression,
                        numberId,
                        needDelete,
                        optionalCanvasTreeState
                    );
                    needSave = needSave || changed;
                    if (changed) {
                        newChanges[node.id] = {
                            ...newChanges[node.id],
                            ...node,
                        };
                    }
                }
            }
            for (let node of inputNotificationNodes) {
                for (let notificationExpression of (node as CanvasElement)
                    .notificationExpressions ?? []) {
                    let changed = this.updateStatusExpressionInput(
                        notificationExpression,
                        numberId,
                        needDelete,
                        optionalCanvasTreeState
                    );
                    needSave = needSave || changed;
                    if (changed) {
                        newChanges[node.id] = {
                            ...newChanges[node.id],
                            ...node,
                        };
                    }
                }
            }
            for (let node of inputPrintNodes) {
                let needGenerateMetric = false;
                for (let printExpression of node.printExpressions ?? []) {
                    let changed = this.updateStatusExpressionInput(
                        printExpression,
                        numberId,
                        needDelete,
                        optionalCanvasTreeState
                    );
                    needSave = needSave || changed;
                    needGenerateMetric = needGenerateMetric || changed;
                    if (changed) {
                        newChanges[node.id] = {
                            ...newChanges[node.id],
                            ...node,
                        };
                    }
                }
                if (needGenerateMetric && node.rawMetric != null) {
                    node.rawMetric = generateRawMetric(node, node.rawMetric);
                    newChanges[node.id] = {
                        ...newChanges[node.id],
                        ...node,
                    };
                }
            }
        }
        for (let globalInput of GlobalInputs) {
            for (let node of inputDataInputNodes) {
                for (let dataTableInput of (node as CanvasElement)
                    .dataTableInputDetails ?? []) {
                    for (let condition of dataTableInput.conditions) {
                        let changed = this.updateConditionInput(
                            condition,
                            globalInput.value,
                            false,
                            true,
                            optionalCanvasTreeState
                        );
                        needCalculate = needCalculate || changed;
                        needSave = needSave || changed;
                        if (changed) {
                            newChanges[node.id] = {
                                ...newChanges[node.id],
                                ...node,
                            };
                        }
                    }
                }
            }
            for (let node of inputDropdownSelectors) {
                for (let condition of node.conditions ?? []) {
                    let changed = this.updateConditionInput(
                        condition,
                        globalInput.value,
                        false,
                        true,
                        optionalCanvasTreeState
                    );
                    needCalculate = needCalculate || changed;
                    needSave = needSave || changed;
                    if (changed) {
                        newChanges[node.id] = {
                            ...newChanges[node.id],
                            ...node,
                        };
                    }
                }
            }
            for (let node of inputStatusNodes) {
                for (let statusExpression of (node as CanvasElement)
                    .statusExpressions ?? []) {
                    let changed = this.updateStatusExpressionInput(
                        statusExpression,
                        globalInput.value,
                        false,
                        optionalCanvasTreeState,
                        true
                    );
                    needSave = needSave || changed;
                    if (changed) {
                        newChanges[node.id] = {
                            ...newChanges[node.id],
                            ...node,
                        };
                    }
                }
            }
            for (let node of inputNotificationNodes) {
                for (let notificationExpression of (node as CanvasElement)
                    .notificationExpressions ?? []) {
                    let changed = this.updateStatusExpressionInput(
                        notificationExpression,
                        globalInput.value,
                        false,
                        optionalCanvasTreeState,
                        true
                    );
                    needSave = needSave || changed;
                    if (changed) {
                        newChanges[node.id] = {
                            ...newChanges[node.id],
                            ...node,
                        };
                    }
                }
            }
            for (let node of inputPrintNodes) {
                let needGenerateMetric = false;
                for (let printExpression of node.printExpressions ?? []) {
                    let changed = this.updateStatusExpressionInput(
                        printExpression,
                        globalInput.value,
                        false,
                        optionalCanvasTreeState,
                        true
                    );
                    needSave = needSave || changed;
                    needGenerateMetric = needGenerateMetric || changed;
                    if (changed) {
                        newChanges[node.id] = {
                            ...newChanges[node.id],
                            ...node,
                        };
                    }
                }
                if (needGenerateMetric && node.rawMetric != null) {
                    node.rawMetric = generateRawMetric(node, node.rawMetric);
                    newChanges[node.id] = {
                        ...newChanges[node.id],
                        ...node,
                    };
                }
            }
        }
        if (needSave) {
            for (let [newNodeId, newNode] of Object.entries(newChanges))
                canvasTreeState.set(Number(newNodeId), newNode);

            // this.saveChangesAction({
            //     canvasTreeState: newChanges,
            // });
            if (needCalculate && !optionalCanvasTreeState) {
                // let changes = this.calculateValues();
                // this.saveChanges({ canvasTreeState: { changes } });
                this.updateArgsAsyncAction
                    .bothParts(true, this.canvasTreeState)
                    .then(() => {
                        if (canvasId !== this.canvasId) return;
                        this.calculateValuesAction();
                        // this.saveChangesAction({
                        //     canvasTreeState: changes,
                        // });
                    });
            }
        }

        if (!optionalCanvasTreeState)
            this.updateOtherElementsByLinkedInputs(changes, updateGrids);
    }
    public async sendNotifications(
        changes: CanvasTreeValueChanges,
        canvasId: number | undefined
    ) {
        for (let itemId of Object.keys(changes ?? {})) {
            let item = this.canvasTreeState.get(Number(itemId));
            if (item != null) {
                if (isBox(item) || isTextBox(item)) {
                    for (let notificationExpression of item?.notificationExpressions ||
                        []) {
                        let lastExpression =
                            notificationExpression.lastNotificationExpression;
                        let currentExpression = getCurrentNotificationExpression(
                            item,
                            notificationExpression
                        );
                        if (_.isEqual(lastExpression, currentExpression))
                            continue;
                        let result = calculateExpression(
                            item,
                            notificationExpression
                        );
                        let users = notificationExpression.users.filter(
                            (userName) => userName != null
                        );
                        if (result === false) {
                            notificationExpression.lastNotificationExpression = null;
                            changes[itemId] = {
                                ...item,
                                ...changes[itemId],
                                notificationExpressions:
                                    item.notificationExpressions,
                            } as CanvasElement | CanvasTextBox;
                        }
                        if (canvasId != null && result && users.length > 0) {
                            try {
                                await sendConditionalNotification(
                                    canvasId,
                                    notificationExpression.title,
                                    notificationExpression.message,
                                    users as string[]
                                );
                                notificationExpression.lastNotificationExpression = currentExpression;
                                changes[itemId] = {
                                    ...item,
                                    ...changes[itemId],
                                    notificationExpressions:
                                        item.notificationExpressions,
                                } as CanvasElement | CanvasTextBox;
                            } catch (error) {
                                console.log(String(error));
                            }
                        }
                    }
                }
            }
        }
    }
    private updateConditionSharedInput(
        condition: Condition,
        sharedBox: CanvasSharedBoxElement
    ): boolean {
        let changed: boolean = false;
        if (
            !isMultiCondition(condition) &&
            condition.isInput &&
            condition.value != null &&
            (condition.value as NodeLinkOption).isCloneInput &&
            (condition.value as NodeLinkOption).value === sharedBox.id
        ) {
            let newTarget = getTargetValue(sharedBox.box);
            if ((condition.value as NodeLinkOption).target !== newTarget) {
                (condition.value as NodeLinkOption).target = newTarget;
                changed = true;
            }
        }
        if (
            isMultiCondition(condition) &&
            condition.isInput &&
            condition.value != null &&
            Array.isArray(condition.value) &&
            condition.value.length > 0
        ) {
            for (let index in condition.value) {
                let subValue = condition.value[index];
                if (
                    subValue != null &&
                    (subValue as NodeLinkOption).isCloneInput &&
                    (subValue as NodeLinkOption).value === sharedBox.id
                ) {
                    if (!isDropdownSelector(sharedBox.box)) {
                        let newTarget = getTargetValue(sharedBox.box);
                        if ((subValue as NodeLinkOption).target !== newTarget) {
                            changed = true;
                            (subValue as NodeLinkOption).target = newTarget;
                        }
                    } else {
                        let newTarget = [getTargetValue(sharedBox.box)];
                        if (hasAdditionalOutputs(sharedBox.box)) {
                            for (let output of sharedBox.box
                                .additionalOutputs) {
                                newTarget.push(getOutputTargetValue(output));
                            }
                        }
                        if (
                            !_.isEqual(
                                (subValue as NodeLinkOption).target,
                                newTarget
                            )
                        ) {
                            changed = true;
                            (subValue as NodeLinkOption).target = newTarget;
                        }
                    }
                }
            }
        }

        return changed;
    }
    public async updateOtherElementsByLinkedSharedInput(
        sharedBox: CanvasSharedBoxElement
    ) {
        let needSave: boolean = false;
        let dashboardChanges: { [key: string]: CanvasDashboard } = {};
        let gridChanges: { [key: string]: CanvasSpreadSheetGrid } = {};
        let mapChanges: { [key: string]: MapElement } = {};

        let canvasId = this.canvasId;
        for (let [id, item] of this.dashboardsState.entries()) {
            let changed = false;
            let needUpdateFinding = false;
            for (let condition of item.finding?.config?.conditions ?? []) {
                let localChanged = this.updateConditionSharedInput(
                    condition,
                    sharedBox
                );
                changed = changed || localChanged;
                needSave = needSave || changed;
                needUpdateFinding = needUpdateFinding || changed;
            }
            for (let statusExpression of item.finding?.config
                ?.statusExpressions ?? []) {
                let localChanged = this.updateStatusExpressionSharedInput(
                    statusExpression,
                    sharedBox
                );
                changed = changed || localChanged;

                needSave = needSave || changed;
            }
            if (changed) {
                if (needUpdateFinding) {
                    try {
                        let newFinding = await DashboardUpdater.updateDashboardFinding(
                            item.finding,
                            item.version,
                            this.moduleId ?? remoteModuleId
                        );
                        dashboardChanges[id] = {
                            ...item,
                            finding: newFinding,
                        };
                        this.canvasDashboardErrorsState.set(id, null);
                    } catch (error) {
                        this.canvasDashboardErrorsState.set(
                            id,
                            `Error: ${String(error)}`
                        );
                    }
                } else {
                    dashboardChanges[id] = {
                        ...item,
                        finding: item.finding,
                    };
                }
            }
        }
        for (let [id, item] of this.gridsState.entries()) {
            let changed = false;
            for (let condition of item.fullSpreadSheetBackendOutputOptions
                ?.conditions ?? []) {
                let localChanged = this.updateConditionSharedInput(
                    condition,
                    sharedBox
                );
                changed = changed || localChanged;
                needSave = needSave || changed;
            }
            if (changed) {
                gridChanges[id] = {
                    ...item,
                    fullSpreadSheetBackendOutputOptions:
                        item.fullSpreadSheetBackendOutputOptions,
                };
            }
        }
        for (let [id, item] of this.mapElementsState.entries()) {
            let changed = false;
            for (let condition of item.conditions ?? []) {
                let localChanged = this.updateConditionSharedInput(
                    condition,
                    sharedBox
                );
                changed = changed || localChanged;
                needSave = needSave || changed;
            }
            if (changed) {
                mapChanges[id] = {
                    ...item,
                    conditions: item.conditions,
                };
            }
        }
        if (canvasId !== this.canvasId) return;
        if (needSave) {
            for (let [dashboardId, dashboardProps] of Object.entries(
                dashboardChanges
            ))
                this.dashboardsState.set(dashboardId, dashboardProps);
            for (let [gridId, gridProps] of Object.entries(gridChanges))
                this.gridsState.set(gridId, gridProps);
            for (let [mapId, map] of Object.entries(mapChanges))
                this.mapElementsState.set(mapId, map);
            // this.saveChangesAction({
            //     dashboardsState: dashboardChanges,
            //     gridsState: gridChanges,
            // });
        }
    }

    @action.bound public updateNodesByLinkedSharedInputAction(
        sharedBoxId: number,
        updateAfter: boolean = true
    ) {
        let needCalculate = false;
        let newChanges: { [key: string]: CanvasElement } = {};
        let needSave = false;
        let canvasId = this.canvasId;
        let sharedBox = SharedBoxesStore.getBox(sharedBoxId);
        if (sharedBox == null) return;
        let inputDataInputNodes: CanvasNode[] = [];
        let inputStatusNodes: CanvasNode[] = [];
        let inputNotificationNodes: CanvasNode[] = [];
        let inputDropdownSelectors: CanvasDropdownSelector[] = [];
        let inputPrintNodes: CanvasTextBox[] = [];

        this.canvasTreeState.forEach((item) => {
            if (
                (isTextBox(item) ||
                    isBox(item) ||
                    isProgressElement(item) ||
                    isSlider(item)) &&
                item.dataTableInputDetails != null &&
                item.dataTableInputDetails.length !== 0
            ) {
                inputDataInputNodes.push(item);
            }
            if (
                (isTextBox(item) || isBox(item)) &&
                item.statusExpressions != null &&
                item.statusExpressions.length !== 0
            ) {
                inputStatusNodes.push(item);
            }
            if (isDropdownSelector(item)) {
                inputDropdownSelectors.push(item);
            }
            if (
                (isTextBox(item) || isBox(item)) &&
                item.notificationExpressions != null &&
                item.notificationExpressions.length !== 0
            ) {
                inputNotificationNodes.push(item);
            }
            if (
                isTextBox(item) &&
                item.printExpressions != null &&
                item.printExpressions.length !== 0
            ) {
                inputPrintNodes.push(item);
            }
        });
        for (let node of inputDataInputNodes) {
            for (let dataTableInput of (node as CanvasElement)
                .dataTableInputDetails ?? []) {
                for (let condition of dataTableInput.conditions) {
                    let changed = this.updateConditionSharedInput(
                        condition,
                        sharedBox
                    );
                    needSave = changed || needSave;
                    needCalculate = changed || needCalculate;
                    if (changed)
                        newChanges[node.id] = {
                            ...newChanges[node.id],
                            ...node,
                        };
                }
            }
        }
        for (let node of inputDropdownSelectors) {
            for (let condition of node.conditions ?? []) {
                let changed = this.updateConditionSharedInput(
                    condition,
                    sharedBox
                );
                needSave = changed || needSave;
                needCalculate = changed || needCalculate;
                if (changed)
                    newChanges[node.id] = {
                        ...newChanges[node.id],
                        ...node,
                    };
            }
        }
        for (let node of inputStatusNodes) {
            for (let statusExpression of (node as CanvasElement)
                .statusExpressions ?? []) {
                let changed = this.updateStatusExpressionSharedInput(
                    statusExpression,
                    sharedBox
                );
                needSave = changed || needSave;

                if (changed)
                    newChanges[node.id] = {
                        ...newChanges[node.id],
                        ...node,
                    };
            }
        }
        for (let node of inputNotificationNodes) {
            for (let notificationExpression of (node as CanvasElement)
                .notificationExpressions ?? []) {
                let changed = this.updateStatusExpressionSharedInput(
                    notificationExpression,
                    sharedBox
                );
                needSave = changed || needSave;

                if (changed)
                    newChanges[node.id] = {
                        ...newChanges[node.id],
                        ...node,
                    };
            }
        }
        for (let node of inputPrintNodes) {
            let needGenerateMetric = false;
            for (let printExpression of node.printExpressions ?? []) {
                let changed = this.updateStatusExpressionSharedInput(
                    printExpression,
                    sharedBox
                );
                needSave = changed || needSave;
                needGenerateMetric = needGenerateMetric || changed;
                if (changed)
                    newChanges[node.id] = {
                        ...newChanges[node.id],
                        ...node,
                    };
            }
            if (needGenerateMetric && node.rawMetric != null) {
                node.rawMetric = generateRawMetric(node, node.rawMetric);
                newChanges[node.id] = {
                    ...newChanges[node.id],
                    ...node,
                };
            }
        }
        if (needSave) {
            for (let [newNodeId, newNode] of Object.entries(newChanges))
                this.canvasTreeState.set(Number(newNodeId), newNode);
        }
        if (needSave && updateAfter) {
            // this.saveChangesAction({
            //     canvasTreeState: newChanges,
            // });
            if (needCalculate) {
                this.updateArgsAsyncAction
                    .bothParts(true, this.canvasTreeState)
                    .then(() => {
                        if (canvasId !== this.canvasId) return;
                        this.calculateValuesAction();
                        // this.saveChangesAction({ canvasTreeState: changes });
                    });
            }
        }
        this.updateOtherElementsByLinkedSharedInput(sharedBox);
    }

    @action.bound public removeAdditionalOutputAction(
        nodeId: number,
        index: number
    ) {
        let node = this.canvasTreeState.get(nodeId);
        if (node == null) return;
        if (!isBox(node) && !isTextBox(node)) return;
        node = {
            ...node,
            additionalOutputs: [...node.additionalOutputs],
        } as CanvasElement;
        (node as CanvasElement).additionalOutputs.splice(index, 1);
        let changes = this.calculateValuesAction();
        this.saveChangesAction({ canvasTreeState: changes });
        this.updateNodesByLinkedInputsAction({ canvasTreeState: changes });
    }

    // This should NOT be an action
    public async readDataIntoSpreadSheet(
        gridId: string,
        dataScopeId: number | string,
        table: TableOption | null,
        limit: number,
        enableStream: boolean = false,
        pullFrame: boolean = false,
        conditions?: Condition[],
        variables?: VariableOption[],
        bottomRows?: boolean,
        name2ColumnFormat?: { [name: string]: ColumnFormat },
        updateFromDataSource?: boolean,
        skipHistory: boolean = false
    ): Promise<void> {
        let canvasId = this.canvasId;
        let variablesInstance = Variables(
            dataScopeId,
            this.moduleId ?? remoteModuleId
        );
        await variablesInstance.update(this.moduleId ?? remoteModuleId);
        if (
            this.canvasPageId == null ||
            variablesInstance.variableInfoState.length === 0
        ) {
            return;
        }
        let currentVariables: VariableOption[] | undefined = undefined;
        // let rowId: number[] | undefined = undefined;
        if (pullFrame) {
            let grid: CanvasSpreadSheetGrid = this.gridsState.get(gridId)!;
            let variableOptions = variablesInstance.variableOptions;
            if (grid.fullSpreadSheetBackendOutputOptions?.subsetInfo != null) {
                currentVariables = grid.fullSpreadSheetBackendOutputOptions.subsetInfo.variableIndices
                    .filter((index) => typeof index === "number")
                    .map((index) =>
                        variableOptions.find((option) => option.value === index)
                    )
                    .filter((option) => option != null) as VariableOption[];
                // rowId =
                //     grid.fullSpreadSheetBackendOutputOptions.subsetInfo.rowId.filter(
                //         (index) => typeof index === "number"
                //     ) as number[];
            }
        }

        let response = await getRawDataApi(
            table ?? {
                label: "",
                value: [],
                data_table_idx: dataScopeId,
                optimized: false,
            },
            limit,
            conditions,
            currentVariables ?? variables,
            bottomRows,
            this.moduleId ?? remoteModuleId,
            // The spreadsheet should fetch new rows as well, not just current ones
            undefined, // rowId
            undefined, // getCount
            updateFromDataSource
        );
        if (canvasId !== this.canvasId) return;
        this.readDataIntoSpreadSheetInnerAction(
            response,
            gridId,
            dataScopeId,
            table,
            limit,
            enableStream,
            variables,
            conditions,
            bottomRows,
            name2ColumnFormat,
            pullFrame,
            skipHistory
        );
    }

    @action.bound private readDataIntoSpreadSheetInnerAction(
        response: {
            currentLevels: { [key: string]: (string | number | null)[] };
            rowId: number[];
            mode: "replace" | "append";
        },
        gridId: string,
        dataScopeId: number | string,
        table: TableOption | null,
        limit: number,
        enableStream: boolean = false,
        variables?: VariableOption[],
        conditions?: Condition[],
        bottomRows?: boolean,
        name2ColumnFormat?: { [name: string]: ColumnFormat },
        pullFrame: boolean = false,
        skipHistory: boolean = false
    ): void {
        let changes: InnerCanvasChanges = {
            canvasTreeState: {},
        };
        let nodeFontColorOptions: {
            [key: string]: { fontColor?: string | null; fontSize?: number };
        } = {};
        let nodeFormulaMetrics: {
            [key: string]: string;
        } = {};
        let grid: CanvasSpreadSheetGrid = { ...this.gridsState.get(gridId)! };

        let keepOldRows =
            response.mode === "append" &&
            grid.fullSpreadSheetBackendOutputOptions?.dataScopeId ===
                dataScopeId;
        if (!keepOldRows) {
            // Delete old nodes
            let nodeIds: number[] = [];
            this.canvasTreeState.forEach((node, nodeId) => {
                if (
                    (isSimpleSpreadSheetInput(node) || isBox(node)) &&
                    node.gridId === gridId
                ) {
                    nodeIds.push(nodeId);
                    nodeFontColorOptions[node.outerId] = {
                        fontSize: node.fontSize,
                        fontColor: node.fontColor,
                    };
                    if (node.metric.startsWith("=")) {
                        nodeFormulaMetrics[node.outerId] = node.metric;
                    }
                }
            });
            changes = this.deleteNodesAction(nodeIds, false);
        }

        let variableInfo: Variable[] = Variables(
            dataScopeId,
            this.moduleId ?? remoteModuleId
        ).variableInfoState;
        if (
            pullFrame &&
            grid.fullSpreadSheetBackendOutputOptions?.subsetInfo != null
        ) {
            variableInfo = grid
                .fullSpreadSheetBackendOutputOptions!.subsetInfo!.variableIndices.filter(
                    (index) => typeof index === "number"
                )
                .map((index) =>
                    variableInfo.find((option) => option.index === index)
                )
                .filter((option) => option != null) as Variable[];
        } else {
            if (variables != null && variables.length > 0) {
                const variableIndices = new Set(
                    variables.map((varOption) => varOption.value)
                );
                variableInfo = variableInfo.filter(
                    (varInfo) =>
                        varInfo.index != null &&
                        variableIndices.has(varInfo.index)
                );
            }
        }

        // Add the new nodes
        grid.rows = response.rowId.length;
        grid.cols = variableInfo.length;

        grid.headersEnabled = true;
        grid.headers = variableInfo.map((varInfo, index) => {
            let columnFormat: ColumnFormat | undefined =
                name2ColumnFormat?.[varInfo.name];
            let currentHeader = grid.headers?.[index];
            let currentFormat = grid.headers?.[index]?.columnFormat;
            if (columnFormat == null) {
                columnFormat = variableToColumnFormat(varInfo);
            }
            if (isListFormat(currentFormat) && isTextFormat(columnFormat)) {
                columnFormat = currentFormat;
            }
            if (isNumberFormat(currentFormat) && isNumberFormat(columnFormat)) {
                columnFormat.numberType = currentFormat.numberType;
                columnFormat.useAbbreviation = currentFormat.useAbbreviation;
                columnFormat.useCommaSeparator =
                    currentFormat.useCommaSeparator;
                if (
                    varInfo.type === "float" &&
                    currentFormat.decimalPoints > 0
                ) {
                    columnFormat.decimalPoints = currentFormat.decimalPoints;
                }
            }
            let fontColorOptions: {
                fontSize?: number;
                fontColor?: string;
            } = {};
            if (currentHeader != null) {
                fontColorOptions.fontSize = currentHeader.fontSize;
                fontColorOptions.fontColor = currentHeader.fontColor;
            }
            return {
                ...fontColorOptions,
                text: varInfo.name,
                columnFormat: columnFormat,
            };
        });

        let currentNodeIds = new Set<number | string>(
            grid.fullSpreadSheetBackendOutputOptions?.subsetInfo?.rowId
        );

        let entries: Array<[number, CanvasNode]> = [];
        for (let i = 0; i < grid.rows; ++i) {
            if (keepOldRows && currentNodeIds.has(response.rowId[i])) {
                continue;
            }
            for (let j = 0; j < grid.cols; ++j) {
                const variableName = variableInfo[j].name;
                this.canvasAutoIncrementId += 1;
                //    this.nodeSpreadSheetAutoIncrementId += 1;
                let nodeId = this.canvasAutoIncrementId;
                let node = SimpleSpreadSheetInput(
                    nodeId,
                    this.canvasPageId as number,
                    j,
                    i,
                    gridId,
                    grid.index
                );
                if (isDateFormat(grid.headers[j].columnFormat)) {
                    if (response.currentLevels[variableName][i] != null) {
                        node.metric = strftime(
                            (grid.headers[j].columnFormat as DateFormat).format,
                            new Date(
                                (response.currentLevels[variableName][
                                    i
                                ] as number) * 1000
                            )
                        );
                        node.value = response.currentLevels[variableName][
                            i
                        ] as number;
                    }
                } else if (isNumberFormat(grid.headers[j].columnFormat)) {
                    if (response.currentLevels[variableName][i] != null) {
                        node.metric = formatNumber(
                            response.currentLevels[variableName][i] as number,
                            grid.headers[j].columnFormat as NumberFormat
                        );
                        node.value = response.currentLevels[variableName][
                            i
                        ] as number;
                    }
                } else {
                    node.metric =
                        response.currentLevels[variableName][i]?.toString() ??
                        "";
                }
                node = { ...node, ...nodeFontColorOptions[node.outerId] };
                let oldMetric = nodeFormulaMetrics[node.outerId];
                if (oldMetric != null) {
                    node.metric = oldMetric;
                }
                entries.push([nodeId, node]);
                changes.canvasTreeState![nodeId] = node;
            }
        }
        // merge() is much better than multiple set() in terms of performance
        this.canvasTreeState.merge(entries);
        let oldDataScopeId =
            grid.fullSpreadSheetBackendOutputOptions?.dataScopeId;
        if (enableStream) {
            grid.fullSpreadSheetBackendOutputOptions = {
                tableOption: table,
                dataScopeId: dataScopeId,
                conditions: conditions ?? null,
                variables: variables ?? null,
                bottomRows: bottomRows ?? null,
                limit: limit,
                subsetInfo: {
                    rowId: response.rowId,
                    variableIndices: variableInfo.map(
                        (variable) => variable.index!
                    ),
                },
                tableChanges: [],
            };
        }
        this.gridsState.set(gridId, grid);
        if (oldDataScopeId !== dataScopeId) {
            if (oldDataScopeId != null) {
                this.onDataSetDisconnected(oldDataScopeId);
            }
            if (dataScopeId != null) {
                this.onDataSetConnected(dataScopeId);
            }
        }
        changes.gridsState = {
            [gridId]: grid,
        };
        changes.canvasAutoIncrementId = this.canvasAutoIncrementId;
        changes.nodeSpreadSheetAutoIncrementId = this.nodeSpreadSheetAutoIncrementId;
        changes.canvasTreeState = {
            ...changes.canvasTreeState,
            ...this.calculateValuesAction(),
        };
        this.saveChangesAction(
            changes,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            skipHistory
        );
        this.updateNodesByLinkedInputsAction(changes);
    }
}

export default CanvasTreeStoreInner;
