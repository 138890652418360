import { formatDate } from "common/utilities/FormatDate";

export enum FeedbackType {
    insight = 1,
    story = 2,
    module = 5,
}

// This module now only for insights, stories and template modules

export const feedbackTypeToSection = {
    [FeedbackType.insight]: "Insights",
    [FeedbackType.story]: "Stories",
    [FeedbackType.module]: "Modules",
}

export const feedBackMentionUrl = {
    [FeedbackType.insight]: "/api/insights_card_mention_notification_send",
    [FeedbackType.story]: "/api/story_user_mention_notification_send",
    [FeedbackType.module]: "/api/module_shared_mention_notification_send",
};

export interface Feedback {
    id: number;
    user_name: string;
    feedback: string;
    card_id: number;
    creation_time: string;
    is_owner: boolean;
}

export interface FeedbackResponse {
    id: number;
    user_name: string;
    feedback: string;
    card_id: number;
    creation_time: number; // timestamp
    is_owner: boolean;
}

export function toFeedback(feedbackResponse: FeedbackResponse): Feedback {
    return {
        id: feedbackResponse.id,
        user_name: feedbackResponse.user_name,
        feedback: feedbackResponse.feedback,
        card_id: feedbackResponse.card_id,
        creation_time: formatDate(feedbackResponse.creation_time, "added"),
        is_owner: feedbackResponse.is_owner,
    };
}