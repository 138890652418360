import React, { CSSProperties } from "react";
import {
    CanvasNode,
    CanvasElement,
    isSurvey,
    CanvasSurvey,
} from "common/Canvas";
import CanvasTreeStore from "../CanvasTreeStore";
import { MenuProps } from "./FlowChartProps";
import { DropdownMenu, DropdownItem } from "../DropdownMenu";
import { Dropdown } from "react-bootstrap";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import CanvasPreventPropagationButton from "../CanvasPreventPropagationButton";
import styles from "./DropdownMenu.module.css";

import { ReactComponent as SettingsIcon } from "icons/dropdown_node_menu/settings.svg";
import { ReactComponent as DeleteIcon } from "icons/dropdown_node_menu/delete.svg";
import { ReactComponent as UnlockIcon } from "icons/dropdown_node_menu/unlock.svg";
import { ReactComponent as HideIcon } from "icons/dropdown_node_menu/hide.svg";
import { deleteDataSet } from "common/DataApi";

interface Props extends MenuProps {
    disableAdvancedEdit?: boolean;
    canPublishToPresets?: boolean;
    rootMenuTestId: string;
    canvasTreeStore: CanvasTreeStore;
    customStyle: CSSProperties;
    canEdit: boolean;
    canDelete: boolean;
    canLock: boolean;
    node: CanvasNode;
    element: string;
    onPublishToPresets?: () => void;
    onToggle: (show: boolean) => void;
    showDeleteElementWithDatasetPopup: (
        onDelete: () => void,
        onKeepDataSet: () => void,
        elementName: string
    ) => void;
    onError?: (message: string) => void;
}

interface State {
    toggleOpen: boolean;
}

export default class DropdownNodeMenu extends CanvasInteractionComponent<
    Props,
    State
> {
    constructor(props: Props) {
        super(props);
        this.state = {
            toggleOpen: false,
        };
    }

    render() {
        let node = this.props.node;
        return (
            <Dropdown
                data-test-id={this.props.rootMenuTestId}
                title="Options"
                style={{
                    width: 15 * (this.props.scale ?? 1),
                    height: 20 * (this.props.scale ?? 1),
                    backgroundColor: "transparent",
                    ...this.props.customStyle,
                }}
                show={this.state.toggleOpen}
                onToggle={(isOpen: boolean) => {
                    this.setState({ toggleOpen: isOpen });
                    this.props.onToggle(isOpen);
                }}
            >
                <CanvasPreventPropagationButton>
                    <Dropdown.Toggle
                        className={styles.hamburger}
                        data-test-id={`${this.props.rootMenuTestId}-dropdowntoggle`}
                        id={`dropdown_menu_${this.props.element}_${node.id}`}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "transparent",
                            border: "0",
                        }}
                    >
                        <span
                            className={styles.hamburger}
                            style={{
                                fontSize: 14 * (this.props.scale ?? 1),
                            }}
                        >
                            &#8942;
                        </span>
                    </Dropdown.Toggle>
                </CanvasPreventPropagationButton>
                <DropdownMenu noBorder>
                    {this.props.canEdit && (
                        <CanvasPreventPropagationButton>
                            <DropdownItem
                                dataTestId={`${this.props.rootMenuTestId}-advancedEditButton`}
                                highlightColor={
                                    !this.props.disableAdvancedEdit
                                        ? "#39F"
                                        : "#CCCCCC"
                                }
                                hoverColor={
                                    !this.props.disableAdvancedEdit
                                        ? "#39F"
                                        : "#CCCCCC"
                                }
                                title={
                                    isSurvey(node) ? "Edit" : "Advanced edit"
                                }
                                onClick={() => {
                                    if (this.props.disableAdvancedEdit) return;
                                    this.trackNewPerformance(
                                        this.props.element
                                    );
                                    this.props.onClearEditing(true);
                                    this.props.onStartAdvancedEditing(node);
                                    this.setState({ toggleOpen: false });
                                    this.props.onToggle(false);
                                }}
                                icon={<SettingsIcon />}
                            />
                        </CanvasPreventPropagationButton>
                    )}
                    <CanvasPreventPropagationButton>
                        <DropdownItem
                            dataTestId={`${this.props.rootMenuTestId}-deleteButton`}
                            highlightColor={"#39F"}
                            hoverColor={"#39F"}
                            title="Hide"
                            onClick={() => {
                                this.props.canvasTreeStore.toggleVisibilityElementAction(
                                    node.id,
                                    true
                                );
                            }}
                            icon={
                                <HideIcon style={{ width: 14, height: 14 }} />
                            }
                        />
                    </CanvasPreventPropagationButton>
                    {(node as CanvasElement).gridId == null &&
                        this.props.canDelete && (
                            <CanvasPreventPropagationButton>
                                <DropdownItem
                                    dataTestId={`${this.props.rootMenuTestId}-deleteButton`}
                                    highlightColor={"#39F"}
                                    hoverColor={"#39F"}
                                    title="Delete"
                                    onClick={() => {
                                        if (isSurvey(node)) {
                                            this.props.showDeleteElementWithDatasetPopup(
                                                () => {
                                                    if (
                                                        (node as CanvasSurvey)
                                                            .backendOutput
                                                            .tableOption
                                                            ?.data_table_idx !=
                                                        null
                                                    ) {
                                                        deleteDataSet(
                                                            (node as CanvasSurvey)
                                                                .backendOutput
                                                                .tableOption
                                                                ?.data_table_idx!,
                                                            true,
                                                            this.props
                                                                .currentModuleId
                                                        )
                                                            .then(() => {
                                                                this.trackNewPerformance(
                                                                    this.props
                                                                        .element
                                                                );
                                                                this.props.onClearEditing();
                                                                this.props.canvasTreeStore.deleteNodeAction(
                                                                    node.id
                                                                );
                                                            })
                                                            .catch((error) => {
                                                                console.log(
                                                                    error
                                                                );
                                                                this.props.onError?.(
                                                                    String(
                                                                        error
                                                                    )
                                                                );
                                                            });
                                                    }
                                                },
                                                () => {
                                                    this.trackNewPerformance(
                                                        this.props.element
                                                    );
                                                    this.props.onClearEditing();
                                                    this.props.canvasTreeStore.deleteNodeAction(
                                                        node.id
                                                    );
                                                },
                                                "survey"
                                            );
                                        } else {
                                            this.trackNewPerformance(
                                                this.props.element
                                            );
                                            this.props.onClearEditing();
                                            this.props.canvasTreeStore.deleteNodeAction(
                                                node.id
                                            );
                                        }
                                    }}
                                    icon={<DeleteIcon />}
                                />
                            </CanvasPreventPropagationButton>
                        )}
                    {this.props.canLock && (
                        <CanvasPreventPropagationButton>
                            <DropdownItem
                                dataTestId={`${this.props.rootMenuTestId}-lockButton`}
                                highlightColor={"#39F"}
                                hoverColor={"#39F"}
                                title={
                                    node.unlocked
                                        ? "Lock in live mode"
                                        : "Unlock for live mode"
                                }
                                onClick={() => {
                                    this.trackNewPerformance(
                                        this.props.element
                                    );
                                    this.props.canvasTreeStore.updateNodeAction(
                                        node.id,
                                        {
                                            unlocked: !node.unlocked,
                                        }
                                    );
                                }}
                                icon={<UnlockIcon />}
                            />
                        </CanvasPreventPropagationButton>
                    )}
                    {this.props.canPublishToPresets && (
                        <CanvasPreventPropagationButton>
                            <DropdownItem
                                dataTestId={`${this.props.rootMenuTestId}-lockButton`}
                                highlightColor={"#39F"}
                                hoverColor={"#39F"}
                                title={"Publish to presets"}
                                onClick={() => {
                                    this.trackNewPerformance(
                                        this.props.element
                                    );
                                    this.props.onPublishToPresets!();
                                    this.props.onToggle(false);
                                }}
                                icon={<UnlockIcon />}
                            />
                        </CanvasPreventPropagationButton>
                    )}
                </DropdownMenu>
            </Dropdown>
        );
    }
}
