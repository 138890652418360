import React, { Component } from "react";
import Popup from "reactjs-popup";
import Switch from "react-switch";
import Select, { createFilter } from "react-select";
import { observer } from "mobx-react";

import customSelectStyles from "common/SelectStyles";
import { mainStyle } from "common/MainStyle";
import OutsideAlerter from "common/OutsideAlerter";
import ColorPicker from "common/ColorPicker";
import Draggable from "react-draggable";
import { Button } from "react-bootstrap";
import { GraphElement } from "common/Canvas";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import Tables, { TableOption } from "common/Tables";
import Variables, { VariableOption } from "common/Variables";
import remoteModuleId from "common/remoteModuleId";

interface Props {
    currentModuleId?: number;
    graphElement: GraphElement;
    onClose: (
        apply: boolean,
        changes?: Pick<GraphElement, "colorOptions" | "dataSet">
    ) => void;
}

interface State {
    left: number;
    top: number;
    borderShadow: boolean;
    fillColor: string;
    borderColor: string;
    dataSet: {
        dataScope: DataScopeOption | null;
        tableOption: TableOption | null;
        nodeId: VariableOption | null;
        x: VariableOption | null;
        y: VariableOption | null;
        adjacency: VariableOption | null;
        metrics: (VariableOption | null)[];
    };
}

@observer
class GraphEditPopup extends Component<Props, State> {
    drag: boolean = false;
    constructor(props: Props) {
        super(props);
        this.state = {
            left: 0,
            top: 0,
            borderShadow: props.graphElement.colorOptions.borderShadow,
            fillColor: props.graphElement.colorOptions.fillColor,
            borderColor: props.graphElement.colorOptions.borderColor,
            dataSet: props.graphElement.dataSet ?? {
                dataScope: null,
                tableOption: null,
                nodeId: null,
                x: null,
                y: null,
                adjacency: null,
                metrics: [null],
            },
        };
        if (
            this.state.dataSet.metrics == null ||
            this.state.dataSet.metrics.length === 0
        ) {
            this.state.dataSet.metrics = [null];
        }
    }

    private buildInnerView(height: number): JSX.Element {
        let dataScopes =
            this.props.currentModuleId != null
                ? DataScopesForModules(this.props.currentModuleId)
                : DataScopes;
        let variableOptions =
            this.state.dataSet.dataScope != null
                ? Variables(
                      this.state.dataSet.dataScope.value,
                      this.props.currentModuleId ?? remoteModuleId
                  ).variableOptions
                : [];
        return (
            <div
                className="flex-simple-column"
                style={{
                    overflow: "visible",
                    paddingTop: "20px",
                    marginLeft: "38px",
                    height: height,
                }}
            >
                <div
                    className="my-row"
                    style={{
                        width: "200px",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Arial",
                            fontSize: "17px",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            width: "120px",
                        }}
                    >
                        {"Border shadow"}
                    </span>
                    <div style={{ marginLeft: 6 }}>
                        <Switch
                            onChange={(checked) => {
                                this.setState({
                                    borderShadow: checked,
                                });
                            }}
                            checked={this.state.borderShadow ?? false}
                            width={26}
                            height={13}
                            offColor="#20293C"
                            onColor="#20293C"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor="#70889E"
                            onHandleColor="#1F8EFA"
                        />
                    </div>
                </div>
                <div
                    className="my-row"
                    style={{
                        marginTop: "10px",
                        width: "200px",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Arial",
                            fontSize: "17px",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            width: "120px",
                        }}
                    >
                        {"Fill Color"}
                    </span>
                    <ColorPicker
                        enableAlpha
                        value={this.state.fillColor}
                        onChange={(newValue) => {
                            this.setState({
                                fillColor: newValue,
                            });
                        }}
                        style={{
                            marginLeft: "6px",
                        }}
                    />
                </div>
                <div
                    className="my-row"
                    style={{
                        marginTop: "10px",
                        width: "200px",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Arial",
                            fontSize: "17px",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            width: "120px",
                        }}
                    >
                        Border Color
                    </span>
                    <ColorPicker
                        enableAlpha
                        value={this.state.borderColor}
                        onChange={(newValue) => {
                            this.setState({
                                borderColor: newValue,
                            });
                        }}
                        style={{
                            marginLeft: "6px",
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "5px",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            width: 100,
                            textAlign: "left",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: 14,
                        }}
                    >
                        Dataset
                    </span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                width: "200px",
                                height: "38px",
                                marginLeft: 5,
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={dataScopes.dataScopesOptions}
                        onChange={(option) => {
                            this.setState((state) => ({
                                dataSet: {
                                    ...state.dataSet,
                                    dataScope: option as DataScopeOption,
                                },
                            }));
                        }}
                        value={this.state.dataSet.dataScope}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "5px",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            width: 100,
                            textAlign: "left",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: 14,
                        }}
                    >
                        Table
                    </span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                width: "200px",
                                height: "38px",
                                marginLeft: 5,
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={
                            this.state.dataSet.dataScope != null
                                ? Tables(
                                      this.state.dataSet.dataScope.value,
                                      this.props.currentModuleId ??
                                          remoteModuleId
                                  ).rawAndAggregateTableOptions.concat(
                                      Tables(
                                          this.state.dataSet.dataScope.value,
                                          this.props.currentModuleId ??
                                              remoteModuleId
                                      ).rawAndOptimizedTableOptions
                                  )
                                : []
                        }
                        onChange={(option) => {
                            this.setState((state) => ({
                                dataSet: {
                                    ...state.dataSet,
                                    tableOption: option as TableOption,
                                },
                            }));
                        }}
                        value={this.state.dataSet?.tableOption}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "5px",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            width: 100,
                            textAlign: "left",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: 14,
                        }}
                    >
                        Node ID
                    </span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                width: "200px",
                                height: "38px",
                                marginLeft: 5,
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={variableOptions.filter(
                            (option) =>
                                option.type === "int" || option.type === "float"
                        )}
                        onChange={(option) => {
                            this.setState((state) => ({
                                dataSet: {
                                    ...state.dataSet,
                                    nodeId: option as VariableOption,
                                },
                            }));
                        }}
                        value={this.state.dataSet?.nodeId}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "5px",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            width: 100,
                            textAlign: "left",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: 14,
                        }}
                    >
                        x
                    </span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                width: "200px",
                                height: "38px",
                                marginLeft: 5,
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={variableOptions.filter(
                            (option) =>
                                option.type === "int" || option.type === "float"
                        )}
                        onChange={(option) => {
                            this.setState((state) => ({
                                dataSet: {
                                    ...state.dataSet,
                                    x: option as VariableOption,
                                },
                            }));
                        }}
                        value={this.state.dataSet?.x}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "5px",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            width: 100,
                            textAlign: "left",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: 14,
                        }}
                    >
                        y
                    </span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                width: "200px",
                                height: "38px",
                                marginLeft: 5,
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={variableOptions.filter(
                            (option) =>
                                option.type === "int" || option.type === "float"
                        )}
                        onChange={(option) => {
                            this.setState((state) => ({
                                dataSet: {
                                    ...state.dataSet,
                                    y: option as VariableOption,
                                },
                            }));
                        }}
                        value={this.state.dataSet?.y}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "5px",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            width: 100,
                            textAlign: "left",
                            color: mainStyle.getPropertyValue(
                                "--popup-primary-text-color"
                            ),
                            fontFamily: "Roboto",
                            fontSize: 14,
                        }}
                    >
                        Adjacency
                    </span>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                        })}
                        placeholder={""}
                        styles={{
                            ...customSelectStyles,
                            container: (base) => ({
                                ...base,
                                width: "200px",
                                height: "38px",
                                marginLeft: 5,
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 100000000,
                            }),
                        }}
                        options={variableOptions.filter(
                            (option) => option.type === "str"
                        )}
                        onChange={(option) => {
                            this.setState((state) => ({
                                dataSet: {
                                    ...state.dataSet,
                                    adjacency: option as VariableOption,
                                },
                            }));
                        }}
                        value={this.state.dataSet?.adjacency}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                        menuPortalTarget={document.body}
                    />
                </div>
                <span
                    style={{
                        width: 100,
                        textAlign: "left",
                        color: mainStyle.getPropertyValue(
                            "--popup-primary-text-color"
                        ),
                        fontFamily: "Roboto",
                        fontSize: 14,
                        marginTop: "5px",
                        marginBottom: "5px",
                    }}
                >
                    Metrics
                </span>
                {this.state.dataSet.metrics.map((value, index) => (
                    <React.Fragment key={index}>
                        <div
                            style={{
                                alignItems: "center",
                                marginBottom: "5px",
                            }}
                            className="my-row"
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            <Select
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                placeholder="Select variable"
                                styles={{
                                    ...customSelectStyles,
                                    container: (base) => ({
                                        ...base,
                                        width: "200px",
                                        height: "38px",
                                        marginLeft: 5,
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100000000,
                                    }),
                                }}
                                options={variableOptions}
                                onChange={(newValue) => {
                                    let metrics = Array.from(
                                        this.state.dataSet.metrics
                                    );
                                    metrics[index] = newValue as VariableOption;
                                    this.setState((state) => ({
                                        dataSet: {
                                            ...state.dataSet,
                                            metrics: metrics,
                                        },
                                    }));
                                }}
                                value={value}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25:
                                            "var(--selectors-background-hover-color)",
                                    },
                                })}
                                menuPortalTarget={document.body}
                            />
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 5 }}
                            >
                                <Button
                                    type="button"
                                    className="btn btn-sm btn-primary my-primary"
                                    title="Add variable"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                        border: "none",
                                        background: "none",
                                        color: "#000",
                                        padding: 0,
                                    }}
                                    onClick={() => {
                                        let metrics = Array.from(
                                            this.state.dataSet.metrics
                                        );
                                        metrics.push(null);
                                        this.setState((state) => ({
                                            dataSet: {
                                                ...state.dataSet,
                                                metrics: metrics,
                                            },
                                        }));
                                    }}
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>

                                <Button
                                    type="button"
                                    className="btn btn-sm btn-primary my-primary"
                                    title="Remove variable"
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                        border: "none",
                                        background: "none",
                                        color: "#000",
                                        padding: 0,
                                    }}
                                    onClick={() => {
                                        let metrics = Array.from(
                                            this.state.dataSet.metrics
                                        );
                                        metrics.splice(index, 1);
                                        if (metrics.length === 0) {
                                            metrics.push(null);
                                        }
                                        this.setState((state) => ({
                                            dataSet: {
                                                ...state.dataSet,
                                                metrics: metrics,
                                            },
                                        }));
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <div style={{ flexGrow: 1 }} />
                <div
                    className="my-row"
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        marginRight: 20,
                        alignSelf: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={() => {
                            this.props.onClose(false);
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        style={{
                            marginLeft: 10,
                            width: "112px",
                        }}
                        onClick={() => {
                            this.props.onClose(true, {
                                colorOptions: {
                                    borderShadow: this.state.borderShadow,
                                    borderColor: this.state.borderColor,
                                    fillColor: this.state.fillColor,
                                },
                                dataSet: {
                                    dataScope: this.state.dataSet.dataScope,
                                    tableOption: this.state.dataSet.tableOption,
                                    nodeId: this.state.dataSet.nodeId,
                                    x: this.state.dataSet.x,
                                    y: this.state.dataSet.y,
                                    adjacency: this.state.dataSet.adjacency,
                                    metrics: this.state.dataSet.metrics.filter(
                                        (option): option is VariableOption =>
                                            option != null
                                    ),
                                },
                            });
                        }}
                    >
                        DONE
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const height: number = 500;
        const width: number = 950;
        return (
            <Popup
                arrow={true}
                contentStyle={{
                    width: width,
                    minHeight: height,
                    border: "none",
                    backgroundColor: "transparent",
                }}
                open={true}
                onClose={() => {
                    this.props.onClose(false);
                }}
                closeOnDocumentClick
            >
                <Draggable
                    cancel="input"
                    position={{
                        x: this.state.left,
                        y: this.state.top,
                    }}
                    onDrag={() => {
                        this.drag = true;
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            this.drag = false;
                            this.setState({
                                left: data.x,
                                top: data.y,
                            });
                        }
                    }}
                >
                    <div
                        className="dashboard-rect element"
                        style={{
                            overflowX: "visible",
                            overflowY: "auto",
                            boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                            borderRadius: 0,
                            alignItems: "center",
                            cursor: "pointer",
                            height: height,
                            width: width,
                        }}
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt: any) => {
                            evt.stopPropagation();
                        }}
                    >
                        <OutsideAlerter
                            onReject={() => {
                                this.props.onClose(false);
                            }}
                        >
                            {this.buildInnerView(height)}
                        </OutsideAlerter>
                    </div>
                </Draggable>
            </Popup>
        );
    }
}
export default GraphEditPopup;
