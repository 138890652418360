import React, { Component } from "react";
import customSelectStyles from "./SelectStyles";
import { createFilter } from "react-select";
import {
	getUserPageTabsApi,
	addInsightApi,
	addUserPageTabApi,
} from "./UserApi";
import { Button } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { mainStyle } from "./MainStyle";
import OutsideAlerter from "./OutsideAlerter";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import { observer } from "mobx-react";
import Alert from "./Alert";

const AddInsightPopup = observer(
	class AddInsightPopup extends Component {
		constructor(props) {
			super(props);
			this.state = {
				selectedPage: null,
				selectedTab: null,
				errorMessage: undefined,
				tabOptions: [],
			};
			this.getPageTabs = this.getPageTabs.bind(this);
		}

		getPageTabs(pageId) {
			getUserPageTabsApi(pageId, this.props.tabType).then((pageTabs) => {
				let tabOptions = pageTabs.map((pageTab) => {
					return {
						label: pageTab.title,
						value: pageTab.id,
					};
				});
				this.setState({ tabOptions: tabOptions });
			});
		}

		buildInnerView() {
			return (
				<div
					className="center-container"
					style={{
						overflow: "visible",
						height: "100%",
						width: "100%",
					}}
				>
					<div
						className="flex-simple-column"
						style={{
							overflow: "visible",
							marginTop: 20,
							height: "100%",
							width: "90%",
						}}
					>
						<span
							style={{
								fontFamily: "Roboto",
								fontSize: mainStyle.getPropertyValue(
									"--path-header-size"
								),
								color: mainStyle.getPropertyValue(
									"--popup-primary-text-color"
								),
								fontWeight: 300,
							}}
						>
							{"Select Page and Tab"}
						</span>
						<div
							style={{
								width: "100%",
								marginTop: 20,
								overflow: "visible",
							}}
						>
							<Select
								filterOption={createFilter({
									ignoreAccents: false,
								})}
								placeholder={"Select page"}
								styles={{
									...customSelectStyles,
									container: (base) => ({
										...base,
										height: "38px",
									}),
								}}
								options={
									PagesStore(PageType.Insights)
										.pageToWriteOptions
								}
								value={this.state.selectedPage}
								onChange={(newValue) => {
									this.setState(
										{
											selectedTab: null,
											selectedPage: newValue,
										},
										() => {
											this.getPageTabs(
												this.state.selectedPage.value
											);
										}
									);
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 0,
									colors: {
										...theme.colors,
										text: "white",
										primary25: "var(--selectors-background-hover-color)",
									},
								})}
							/>
						</div>
						{this.state.selectedPage && (
							<div
								style={{
									width: "100%",
									marginTop: 20,
									overflow: "visible",
								}}
							>
								<CreatableSelect
									onCreateOption={(option) => {
										addUserPageTabApi(
											option,
											this.state.selectedPage.value,
											this.props.tabType
										).then((id) => {
											let tabOptions = Array.from(
												this.state.tabOptions
											);
											tabOptions.unshift({
												label: option,
												value: id,
											});
											this.setState({
												tabOptions: tabOptions,
											});
										});
									}}
									filterOption={createFilter({
										ignoreAccents: false,
									})}
									placeholder={"Select tab"}
									styles={{
										...customSelectStyles,
										container: (base) => ({
											...base,
											height: "38px",
										}),
									}}
									options={this.state.tabOptions}
									value={this.state.selectedTab}
									onChange={(newValue) => {
										this.setState({
											selectedTab: newValue,
										});
									}}
									theme={(theme) => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											text: "white",
											primary25: "var(--selectors-background-hover-color)",
										},
									})}
								/>
							</div>
						)}
						<div
							className="my-row"
							style={{
								marginTop: 20,
								marginBottom: 20,
								alignSelf: "flex-end",
								alignItems: "center",
							}}
						>
							<Button
								type="button"
								disabled={!this.state.selectedTab}
								className="btn btn-sm btn-primary my-primary"
								style={{
									marginLeft: 10,
									width: "112px",
								}}
								onClick={() => {
									addInsightApi(
										this.props.contents,
										this.props.image,
										this.state.selectedTab.value
									)
										.then((id) => {
											if (this.props.onSave)
												this.props.onSave(
													this.state.selectedPage
														.value,
													this.state.selectedTab
														.value,
													id
												);
											this.props.onClose();
										})
										.catch((error) => {
											this.setState({
												errorMessage: error,
											});
										});
								}}
							>
								SAVE
							</Button>
							<Button
								type="button"
								className="btn btn-sm btn-primary my-primary"
								style={{
									marginLeft: 10,
									width: "112px",
								}}
								onClick={() => {
									this.props.onClose();
								}}
							>
								CLOSE
							</Button>
						</div>
						{this.state.errorMessage && (
							<Alert
								text={"Error: ".concat(this.state.errorMessage)}
								className="alert alert-danger alert-dismissible"
								onClosed={() =>
									this.setState({
										errorMessage: undefined,
									})
								}
							/>
						)}
					</div>
				</div>
			);
		}

		render() {
			let height = 240;
			let width = 450;
			return (
				<OutsideAlerter
					onReject={() => {
						this.props.onClose();
					}}
				>
					<div
						className="dashboard-rect"
						style={{
							overflow: "visible",
							position: "absolute",
							border: "1px solid black",
							top: (window.innerHeight - height) / 2,
							alignItems: "center",
							cursor: "pointer",
							left: (window.innerWidth - width) / 2,
							minHeight: height,
							width: width,
							zIndex: "100000000",
						}}
					>
						{this.buildInnerView()}
					</div>
				</OutsideAlerter>
			);
		}
	}
);
export default AddInsightPopup;
