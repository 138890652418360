import React, { Component } from "react";
import TextareaAutosize from "react-autosize-textarea";
import ls from "local-storage";
import { Button } from "react-bootstrap";
import "./styles/App.css";
import "./styles/div_span.css";

import axios from "./ServerConnection";
import { notebookSample } from "./NotebookSample";
import processTab from "common/utilities/processTab";

const ImageDisplay = ({ base64Image, onNewFinding }) => {
    let imageContent = `data:image/png;base64,${base64Image}`;
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 5,
            }}
        >
            <img src={imageContent} alt="" />
            <Button
                className="btn btn-lg btn-primary my-primary"
                onClick={async () => {
                    let imageBlob = await (await fetch(imageContent)).blob();
                    onNewFinding(
                        {
                            type: "image",
                            content: {},
                        },
                        imageBlob
                    );
                }}
                style={{
                    fontFamily: "Roboto",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    height: "25px",
                    borderRadius: 0,
                }}
            >
                SAVE PLOT TO INSIGHTS
            </Button>
        </div>
    );
};

class CodeNotebookComponent extends Component {
    constructor(props) {
        super(props);
        this.state = ls.get("CodeNotebookComponentState_" + props.tag) || {
            piecesOfCode: this.getDefaultPiecesOfCode(),
            currentPieceOfCode: "",
            currentPieceOfCodeRunning: false,
        };
        this.state.piecesOfCodeRunning = {};
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleHistoryTextChange = this.handleHistoryTextChange.bind(this);
        this.submitNewPiece = this.submitNewPiece.bind(this);
        this.submitHistory = this.submitHistory.bind(this);
        this.evaluatePython = this.evaluatePython.bind(this);
        this.clearNotebook = this.clearNotebook.bind(this);
        this.saveFirstFinding = this.saveFirstFinding.bind(this);
        this.getDefaultPiecesOfCode = this.getDefaultPiecesOfCode.bind(this);
    }
    getDefaultPiecesOfCode() {
        if (this.props.showExamples) {
            return [
                {
                    input: notebookSample,
                    output: "",
                },
            ];
        } else return [];
    }

    evaluatePython(pieceOfCodeForSubmit, index) {
        if (typeof index === "undefined") {
            this.setState({ currentPieceOfCodeRunning: true });
        } else {
            let piecesOfCodeRunning = Object.assign(
                this.state.piecesOfCodeRunning
            );
            piecesOfCodeRunning[index] = true;
            this.setState({ piecesOfCodeRunning: piecesOfCodeRunning });
        }

        axios
            .post(
                "/api/evaluate_python",
                { code: pieceOfCodeForSubmit.input },
                null
            )
            .then((response) => {
                if (response.data.success === true) {
                    let newState = Object.assign({}, this.state);
                    pieceOfCodeForSubmit.output =
                        response.data.output + (response.data.exception || "");
                    pieceOfCodeForSubmit.images = response.data.images;
                    if (typeof index === "undefined") {
                        newState.piecesOfCode.push(pieceOfCodeForSubmit);
                        newState.currentPieceOfCodeRunning = false;
                        newState.currentPieceOfCode = "";
                    } else {
                        newState.piecesOfCode[index] = pieceOfCodeForSubmit;
                        newState.piecesOfCodeRunning[index] = false;
                    }
                    ls.set(
                        "CodeNotebookComponentState_" + this.props.tag,
                        newState
                    );
                    this.setState(newState);
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    clearNotebook() {
        let newState = {
            piecesOfCode: this.getDefaultPiecesOfCode(),
            currentPieceOfCode: "",
            piecesOfCodeRunning: {},
        };
        ls.set("CodeNotebookComponentState_" + this.props.tag, newState);
        this.setState(newState);
    }

    handleTextChange(evt) {
        if (evt.target.value !== "\n") {
            let newState = Object.assign({}, this.state);
            newState.currentPieceOfCode = evt.target.value;

            ls.set("CodeNotebookComponentState_" + this.props.tag, newState);
            this.setState(newState);
        }
    }

    handleHistoryTextChange(evt, index) {
        if (evt.target.value !== "\n") {
            let newState = Object.assign({}, this.state);
            newState.piecesOfCode[index].input = evt.target.value;
            ls.set("CodeNotebookComponentState_" + this.props.tag, newState);
            this.setState(newState);
        }
    }

    submitNewPiece(evt) {
        if (evt.which === 13 && evt.shiftKey) {
            evt.target.blur();
            let pieceOfCodeForSubmit = {
                input: evt.target.value,
                output: "",
            };

            this.evaluatePython(pieceOfCodeForSubmit, undefined);
        } else {
            let target = evt.target;
            processTab(evt, (newSelectionStart, newSelectionEnd, newValue) => {
                let newState = Object.assign({}, this.state);
                newState.currentPieceOfCode = newValue;
                ls.set(
                    "CodeNotebookComponentState_" + this.props.tag,
                    newState
                );
                this.setState(newState, () => {
                    target.selectionStart = newSelectionStart;
                    target.selectionEnd = newSelectionEnd;
                });
            });
        }
    }
    submitHistory(evt, index) {
        if (evt.which === 13 && evt.shiftKey) {
            evt.target.blur();
            this.evaluatePython(this.state.piecesOfCode[index], index);
        } else {
            let target = evt.target;
            processTab(evt, (newSelectionStart, newSelectionEnd, newValue) => {
                let newState = Object.assign({}, this.state);
                newState.piecesOfCode[index].input = newValue;
                ls.set(
                    "CodeNotebookComponentState_" + this.props.tag,
                    newState
                );
                this.setState(newState, () => {
                    target.selectionStart = newSelectionStart;
                    target.selectionEnd = newSelectionEnd;
                });
            });
        }
    }

    async saveFirstFinding() {
        for (let piece of this.state.piecesOfCode) {
            if ("images" in piece) {
                if (piece.images.length > 0) {
                    let imageContent = `data:image/png;base64,${piece.images[0]}`;
                    let imageBlob = await (await fetch(imageContent)).blob();
                    this.props.onNewFinding(
                        {
                            type: "image",
                            content: {},
                        },
                        imageBlob
                    );
                    return;
                }
            }
        }
    }

    render() {
        return (
            <div className="center-container">
                <div className="flex-column" style={{ width: "100%" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            marginBottom: 20,
                            width: "90%",
                        }}
                    >
                        <div
                            className="note-text"
                            style={{
                                whiteSpace: "pre",
                                fontFamily: "monospace",
                                marginLeft: 20,
                            }}
                        >
                            {"Data frames with table data:\n" +
                                "curr_df_raw_0 - current raw data\n" +
                                "optm_df_raw_0 - optimized raw data\n" +
                                "optm_df_raw_unscaled_0 - optimized raw data unscaled\n" +
                                "Aggregate data:\n" +
                                "curr_df_agg_0 - a dictionary mapping aggregate table names to data frames.\n" +
                                "                You can print the list of all aggregate tables with print(curr_df_agg_0.keys())\n" +
                                "optm_df_agg_0 - a dictionary mapping optimized aggregate table names to data frames.\n" +
                                "optm_df_agg_unscaled_0 - a dictionary mapping optimized unscaled aggregate table names to data frames.\n" +
                                "\n" +
                                "Useful functions:\n" +
                                "get_data_table_indices_and_names() - get a dictionary that maps data table indices to names\n" +
                                "help_custom_functions() - print help for all custom functions (such as show_plot)\n" +
                                "help(function) - print help for specific function"}
                        </div>
                        <div>
                            <button
                                style={{ float: "right" }}
                                onClick={this.clearNotebook}
                                className="clear-notebook-button"
                            >
                                Reset Notebook
                            </button>
                        </div>
                    </div>

                    {this.state.piecesOfCode.map((piece, index) => (
                        <div style={{ marginBottom: 30 }} key={index}>
                            <div className="center-container">
                                <div
                                    className="my-row"
                                    style={{ width: "100%" }}
                                >
                                    <span
                                        className="text-left"
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                            marginLeft: 20,
                                            fontFamily: "monospace",
                                            color: "#FFFFFF",
                                            fontSize: "12px",
                                            minWidth: "6em",
                                        }}
                                    >
                                        In[
                                        {this.state.piecesOfCodeRunning[index]
                                            ? "*"
                                            : index + 1}
                                        ]
                                    </span>
                                    <TextareaAutosize
                                        value={piece.input}
                                        className="code-text-area"
                                        placeholder="Insert code"
                                        style={{
                                            width: "90%",
                                            marginBottom: 10,
                                            marginLeft: 0,
                                            fontFamily: "monospace",
                                        }}
                                        spellCheck={false}
                                        onInput={(evt) => {
                                            this.handleHistoryTextChange(
                                                evt,
                                                index
                                            );
                                        }}
                                        onKeyDown={(evt) => {
                                            this.submitHistory(evt, index);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="my-row" style={{ width: "100%" }}>
                                <span
                                    className="text-left"
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        marginLeft: 20,
                                        fontFamily: "monospace",
                                        color: "white",
                                        fontSize: "12px",
                                        minWidth: "6em",
                                    }}
                                >
                                    Out[{index + 1}]
                                </span>
                                <div
                                    className="flex-column"
                                    style={{
                                        alignItems: "flex-start",
                                        justifyContent: "left",
                                    }}
                                >
                                    <span
                                        className="text-left"
                                        style={{
                                            display: "block",
                                            color: "#FFFFFF",
                                            fontFamily: "monospace",
                                            fontSize: "15px",
                                            whiteSpace: "pre-wrap",
                                        }}
                                    >
                                        {piece.output}
                                    </span>
                                    {"images" in piece &&
                                        piece.images.map((base64Image) => (
                                            <ImageDisplay
                                                base64Image={base64Image}
                                                onNewFinding={
                                                    this.props.onNewFinding
                                                }
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="center-container">
                        <div className="my-row" style={{ width: "100%" }}>
                            <span
                                className="text-left"
                                style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    marginLeft: 20,

                                    fontFamily: "monospace",
                                    color: "white",
                                    fontSize: "12px",
                                    minWidth: "6em",
                                }}
                            >
                                In[
                                {this.state.currentPieceOfCodeRunning
                                    ? "*"
                                    : " "}
                                ]
                            </span>
                            <TextareaAutosize
                                value={this.state.currentPieceOfCode}
                                className="code-text-area"
                                style={{
                                    width: "90%",
                                    marginBottom: 20,
                                    marginLeft: 0,
                                    fontFamily: "monospace",
                                }}
                                spellCheck={false}
                                placeholder="Insert code"
                                onKeyDown={this.submitNewPiece}
                                onInput={this.handleTextChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CodeNotebookComponent;
