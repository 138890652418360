import { GroupExtendedPermissionWithUserInfo } from "common/GroupPermissions";
import { Permission } from "common/Permissions";
import axios from "common/ServerConnection";

export interface Entry {
    id: number;
    dataScopeId: number;
    title: string;
    logo: string | null;
    disabled: boolean;
    creationTime: number;
    lastModified: number;
}

export async function guidanceWizardEntryAddApi(
    dataScopeId: number,
    title: string,
    logo?: string | null
): Promise<number> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/guidance_wizard_entry_add", {
            data_table_idx: dataScopeId,
            title: title,
            logo: logo,
        })
        .then((response) => {
            if (response.data.success && response.data.id != null) {
                return response.data.id;
            } else {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function guidanceWizardEntryEditApi(
    entryId: number,
    dataScopeId?: number,
    title?: string,
    logo?: string | null,
    disabled?: boolean
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/guidance_wizard_entry_edit", {
            id: entryId,
            data_table_idx: dataScopeId,
            title: title,
            // Empty string removes the logo
            logo: logo === null ? "" : logo,
            disabled: disabled,
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function guidanceWizardEntryGetApi(
    entryId: number
): Promise<Entry> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            entry?: {
                data_table_idx: number;
                title: string;
                logo: string | null;
                disabled: boolean;
                creation_time: number;
                last_modified: number;
            };
        }>("/api/guidance_wizard_entry_get", {
            id: entryId,
        })
        .then((response) => {
            if (response.data.success && response.data.entry != null) {
                return {
                    id: entryId,
                    dataScopeId: response.data.entry.data_table_idx,
                    title: response.data.entry.title,
                    logo: response.data.entry.logo,
                    disabled: response.data.entry.disabled,
                    creationTime: response.data.entry.creation_time,
                    lastModified: response.data.entry.last_modified,
                };
            } else {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function guidanceWizardEntryListApi(): Promise<Entry[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            entries?: {
                id: number;
                data_table_idx: number;
                title: string;
                logo: string | null;
                disabled: boolean;
                creation_time: number;
                last_modified: number;
            }[];
        }>("/api/guidance_wizard_entry_list")
        .then((response) => {
            if (response.data.success && response.data.entries != null) {
                return response.data.entries.map((item) => ({
                    id: item.id,
                    dataScopeId: item.data_table_idx,
                    title: item.title,
                    logo: item.logo,
                    disabled: item.disabled,
                    creationTime: item.creation_time,
                    lastModified: item.last_modified,
                }));
            } else {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function guidanceWizardEntryRemoveApi(
    entryId: number
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/guidance_wizard_entry_remove", {
            id: entryId,
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function guidanceWizardEntryPermissionsAdd(
    entryId: number,
    groupIds: number[]
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/guidance_wizard_entry_permissions_add", {
            id: entryId,
            group_ids: groupIds,
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function guidanceWizardEntryPermissionsGet(
    entryId: number
): Promise<{
    permissions: GroupExtendedPermissionWithUserInfo[];
    invitations: { email: string; link_id: string }[];
}> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            permissions?: {
                group_id: number;
                group_name: string;
                personal: boolean;
                user_info?: {
                    id: number;
                    user_name: string;
                    first_name: string;
                    last_name: string;
                    icon_url: string;
                    is_admin: boolean;
                };
            }[];
            invitations?: {
                link_id: string;
                email: string;
            }[];
        }>("/api/guidance_wizard_entry_permissions_get", {
            id: entryId,
        })
        .then((response) => {
            if (response.data.success && response.data.permissions != null) {
                return {
                    permissions: response.data.permissions.map((item) => ({
                        ...item,
                        permission_type: Permission.ReadOnly,
                    })),
                    invitations: response.data.invitations ?? [],
                };
            } else {
                return Promise.reject(response.data.error_msg);
            }
        });
}

export async function guidanceWizardEntryPermissionsRemove(
    entryId: number,
    groupIds: number[]
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/guidance_wizard_entry_permissions_remove", {
            id: entryId,
            group_ids: groupIds,
        })
        .then((response) => {
            if (!response.data.success) {
                return Promise.reject(response.data.error_msg);
            }
        });
}
