import React from "react";
import ScaledPage from "common/ScaledPage";
import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";

const Page404 = (): JSX.Element => {
    return (
        <ScaledPage>
            <div className="content-wrapper hide-scroll">
                <section className="content-header">
                    <h3 style={{ fontSize: 72 }}>404</h3>
                </section>
                <section className="content">
                    <span
                        className="regular-text"
                        style={{ fontSize: 32, marginTop: 20 }}
                    >
                        Page Not Found
                    </span>
                </section>
            </div>
        </ScaledPage>
    );
};

export default Page404;
