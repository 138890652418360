class User {
	firstName: string;
	lastName: string;
	userName: string;

	constructor(userJson?: {
		first_name: string;
		last_name: string;
		user_name: string;
	}) {
		this.firstName = userJson?.first_name ?? "";
		this.lastName = userJson?.last_name ?? "";
		this.userName = userJson?.user_name ?? "";
	}
}

export default User;
