import React from "react";
import { mainStyle } from "common/MainStyle";

import { monthName, monthKeyboard, monthToIndex } from "common/MonthData";

export function selectMonthByKeyboard(
	monthLetter,
	additionalValues,
	additionalValuesSelected,
	onChange
) {
	let indices = monthKeyboard
		.map((e, i) => (e === monthLetter ? i : -1))
		.filter((index) => index !== -1);
	let selectedValue = !additionalValuesSelected[0]
		? additionalValues[0]
		: additionalValues[1];
	let selectedValueIndex = !additionalValuesSelected[0] ? 0 : 1;
	let anotherValue = additionalValuesSelected[0]
		? additionalValues[0]
		: additionalValues[1];
	let filteredIndices = indices.filter((index) => {
		if (!anotherValue) return true;
		return monthName.indexOf(anotherValue.label) !== index;
	});
	if (filteredIndices.length === 0) return;

	if (!selectedValue) {
		let month = monthName[filteredIndices[0]];
		let updatedValues = Array.from(additionalValues);
		updatedValues[selectedValueIndex] = {
			label: month,
			value: monthToIndex[month],
		};
		onChange(updatedValues, additionalValuesSelected);
		return;
	} else if (selectedValue) {
		let currentIndex = monthName.indexOf(selectedValue.label);
		let indexInIndices = filteredIndices.indexOf(currentIndex);

		if (indexInIndices !== -1) {
			let newIndex =
				filteredIndices[(indexInIndices + 1) % filteredIndices.length];
			let updatedValues = Array.from(additionalValues);
			updatedValues[selectedValueIndex] = {
				label: monthName[newIndex],
				value: monthToIndex[monthName[newIndex]],
			};
			onChange(updatedValues, additionalValuesSelected);
		} else {
			let month = monthName[filteredIndices[0]];
			let updatedValues = Array.from(additionalValues);

			updatedValues[selectedValueIndex] = {
				label: month,
				value: monthToIndex[month],
			};
			onChange(updatedValues, additionalValuesSelected);
		}
		return;
	}
}

export default function MonthSelector(props) {
	let additionalValues = Array.from(props.additionalValues);
	let multiselection = additionalValues.length > 1;

	let additionalValuesSelected = Array.from(props.additionalValuesSelected);
	return (
		<div
			className="flex-simple-column element"
			style={{ marginTop: 25, marginLeft: 100, overflow: "auto" }}
		>
			<div className="my-row" style={{ alignItems: "center" }}>
				<span
					className="exploration-big-title-span"
					style={{
						marginLeft: 20,
					}}
				>
					{!multiselection
						? `${props.title} ${
								additionalValues[0]
									? additionalValues[0].label
									: ""
						  }`
						: `${props.title} ${
								additionalValues[0]
									? additionalValues[0].label
									: ""
						  } to ${
								additionalValues[1]
									? additionalValues[1].label
									: ""
						  }`}
				</span>
			</div>
			<div
				style={{
					marginTop: 20,
					display: "grid",
					gridTemplateColumns: "repeat(3, 1fr)",
					gridRowGap: "20px",
					gridColumnGap: "20px",
				}}
			>
				{monthName.map((variable, index) => {
					let freezed = false;
					let selected = additionalValues[0]
						? monthToIndex[variable] === additionalValues[0].value
						: false;
					if (selected && additionalValuesSelected[0]) {
						freezed = true;
					}

					if (!selected) {
						selected = additionalValues[1]
							? monthToIndex[variable] ===
							  additionalValues[1].value
							: false;
						if (selected && additionalValuesSelected[1]) {
							freezed = true;
						}
					}

					let journeyVariableStyle = "journey-variable"
						.concat(selected ? "-selected" : "")
						.concat(freezed ? "-freezed" : "");
					let journeyVariableSmallStyle = "journey-small-variable".concat(
						selected && !freezed ? "-selected" : ""
					);

					return (
						<div
							onClick={() => {
								let isLeftAdditionalValue = additionalValues[0]
									? additionalValues[0].value ===
									  monthToIndex[variable]
									: false;

								let isRightAdditionalValue = additionalValues[1]
									? additionalValues[1].value ===
									  monthToIndex[variable]
									: false;
								if (isLeftAdditionalValue) {
									additionalValues[0] = undefined;
									additionalValuesSelected[0] = false;
									props.onChange(
										additionalValues,
										additionalValuesSelected
									);

									return;
								}
								if (isRightAdditionalValue) {
									additionalValues[1] = undefined;
									additionalValuesSelected[1] = false;
									props.onChange(
										additionalValues,
										additionalValuesSelected
									);
									return;
								}

								if (!additionalValuesSelected[0]) {
									additionalValues[0] = {
										label: variable,
										value: monthToIndex[variable],
									};
									props.onChange(
										additionalValues,
										additionalValuesSelected
									);
								} else {
									additionalValues[1] = {
										label: variable,
										value: monthToIndex[variable],
									};
									props.onChange(
										additionalValues,
										additionalValuesSelected
									);
								}
							}}
							key={index}
							className={"my-row ".concat(journeyVariableStyle)}
							style={{
								justifyContent: "space-between",
								height: "56px",
								width: "250px",
								cursor: "pointer",
							}}
						>
							<div
								className="my-row"
								style={{ alignItems: "center" }}
							>
								<div
									className={journeyVariableSmallStyle}
									style={{
										margin: 10,
										height: "28px",
										width: "26px",
									}}
								>
									<div
										className="center-container"
										style={{
											height: "100%",
											alignItems: "center",
										}}
									>
										<span
											style={{
												fontFamily: "Roboto",
												fontSize: "20px",
												lineHeight: "23px",
												color:
													selected && !freezed
														? "#20293C"
														: mainStyle.getPropertyValue(
																"--exploration-primary-text-color"
														  ),
												fontWeight: 300,
											}}
										>
											{variable[0]}
										</span>
									</div>
								</div>
								<span
									style={{
										textAlign: "center",
										wordBreak: "break-word",
										fontFamily: "Roboto",
										fontSize: "20px",
										lineHeight: "23px",
										color:
											selected && !freezed
												? mainStyle.getPropertyValue(
														"--exploration-secondary-text-color"
												  )
												: mainStyle.getPropertyValue(
														"--exploration-primary-text-color"
												  ),
										fontWeight: 300,
										display: "block",
									}}
								>
									{variable.slice(1)}
								</span>
							</div>
							{selected && !freezed ? (
								<div
									style={{
										margin: 20,
										alignItems: "center",
										display: "flex",
									}}
								>
									<img
										alt=""
										src="/dist/img/data-exploration/icon_checkbox.png"
									/>
								</div>
							) : (
								<div style={{ height: "27px" }} />
							)}
						</div>
					);
				})}
			</div>

			{(additionalValues[0] && !multiselection) ||
			(multiselection && additionalValues[0] && additionalValues[1]) ? (
				<span
					style={{
						marginTop: 10,
						alignSelf: "flex-end",
						cursor: "pointer",
						fontFamily: "Roboto",
						fontSize: "12px",
						lineHeight: "14px",
						color: mainStyle.getPropertyValue(
							"--exploration-secondary-text-color"
						),
						fontWeight: 700,
					}}
				>
					press [&darr;] Key to view results
				</span>
			) : (
				<>
					<span
						style={{
							marginTop: 5,
							alignSelf: "flex-end",
							cursor: "pointer",
							fontFamily: "Roboto",
							fontSize: "12px",
							lineHeight: "14px",
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
							fontWeight: 700,
						}}
					>
						press key or click to select
					</span>
					<span
						style={{
							marginTop: 5,
							alignSelf: "flex-end",
							cursor: "pointer",
							fontFamily: "Roboto",
							fontSize: "12px",
							lineHeight: "14px",
							color: mainStyle.getPropertyValue(
								"--exploration-secondary-text-color"
							),
							fontWeight: 700,
						}}
					>
						press [Enter] after selecting a month
					</span>
				</>
			)}
		</div>
	);
}