import "common/styles/App.css";
import React, { Component } from "react";
import ls from "local-storage";
import TabType from "common/TabType";
import { editInsightApi } from "common/UserApi"; // Depends on Axios
import { renderIcon } from "common/utilities/renderIcon";
import AddInsightPopup from "common/AddInsightPopup";
import PageType from "common/PageType";
import { goToInternalLink } from "common/InternalLinksHelper";

class FindingHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddInsightPopup: false,
            currentContents: undefined,
            currentImage: null,
        };
        this.selectTabForInsight = this.selectTabForInsight.bind(this);
        this.handleNewFinding = this.handleNewFinding.bind(this);
        this.wrapper = React.createRef();
    }
    handleNewFinding(
        finding,
        image = null,
        forceNew = false,
        noInsight = false,
        noRecommendation = false
    ) {
        let lastEditInsight = forceNew ? undefined : ls.get("lastEditInsight");
        renderIcon(this.wrapper, finding, 500, image)
            .then(
                function (dataUrl) {
                    if (!lastEditInsight) {
                        let contents = {
                            title: "",
                            insight: "",
                            imageSrc: dataUrl,
                            recommendation: "",
                            finding: finding,
                            noInsight: noInsight,
                            noRecommendation: noRecommendation,
                        };
                        this.selectTabForInsight(contents, image);
                    } else {
                        lastEditInsight.contents.finding = finding;
                        lastEditInsight.contents.imageSrc = dataUrl;
                        lastEditInsight.contents.noInsight = noInsight;
                        lastEditInsight.contents.noRecommendation = noRecommendation;

                        editInsightApi(lastEditInsight, image).then(() => {
                            lastEditInsight.contents = {};
                            ls.set("lastEditInsight", lastEditInsight);
                            goToInternalLink("/insights.html");
                        });
                    }
                }.bind(this)
            )
            .catch(
                function (error) {
                    console.log(error);
                    if (!lastEditInsight) {
                        let contents = {
                            title: "",
                            insight: "",
                            imageSrc: "",
                            recommendation: "",
                            finding: finding,
                            noInsight: noInsight,
                            noRecommendation: noRecommendation,
                        };
                        this.selectTabForInsight(contents, image);
                    } else {
                        lastEditInsight.contents.finding = finding;
                        lastEditInsight.contents.imageSrc = "";
                        lastEditInsight.contents.noInsight = noInsight;
                        lastEditInsight.contents.noRecommendation = noRecommendation;
                        editInsightApi(lastEditInsight, image).then(() => {
                            lastEditInsight.contents = {};
                            ls.set("lastEditInsight", lastEditInsight);
                            goToInternalLink("/insights.html");
                        });
                    }
                    console.error("oops, something went wrong!", error);
                }.bind(this)
            );
    }
    selectTabForInsight(contents, image) {
        this.setState({
            currentContents: contents,
            currentImage: image,
            showAddInsightPopup: true,
        });
    }

    render() {
        let elementWithFindingProp = React.cloneElement(
            React.Children.only(this.props.children),
            {
                onNewFinding: this.handleNewFinding,
            }
        );
        return (
            <div ref={this.wrapper}>
                {elementWithFindingProp}
                {this.state.showAddInsightPopup && (
                    <AddInsightPopup
                        contents={this.state.currentContents}
                        image={this.state.currentImage}
                        tabType={TabType.insights}
                        onClose={() => {
                            this.setState({
                                currentContents: undefined,
                                showAddInsightPopup: false,
                            });
                        }}
                        onSave={(pageId, tabId, insightId) => {
                            let insight = {};
                            insight.id = insightId;
                            ls.set("lastEditInsight", insight);
                            ls.set(
                                "lastPageId".concat(String(PageType.Insights)),
                                pageId
                            );
                            goToInternalLink("/insights.html");
                        }}
                    />
                )}
            </div>
        );
    }
}

export default FindingHandler;
