const colorList = [
    "#42ca82", // Green
    "#f6ab53", // Orange
    "#e24245", // Red
    "#813a9f", // Purple
    "#a65628", // Brown
    "#f781bf", // Pink
    "#28c3d4", // Cyan
    "#ffff33", // Yellow
    "#bcf6c0", // Lime
    "#800000", // Maroon
    "#f032e6", // Magenta
    "#d3d3d3", // Light Gray
    "#ffd8b1", // Coral
    "#808080", // Gray <--
    "#e6beff", // Lavender,
    "#ffd8b1", // Coral
    "#808080", // Gray <--
    "#e6beff" // Lavender
];

export { colorList };
