import axios from "./ServerConnection";
import { stringSessionId } from "common/SessionId";

export enum FolderType {
    Presentations = 1,
    Templates = 2,
    Tutorials = 3,
}

export function addFolder(title: string, type: FolderType): Promise<number> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            id?: number;
        }>("/api/module_folder_add", {
            title: title,
            type: type,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(response.data.id!);
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function deleteFolder(
    folderId: number,
    type: FolderType
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_folder_delete", {
            type: type,
            id: folderId,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function editFolder(
    title: string,
    folderId: number,
    type: FolderType
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_folder_edit", {
            title: title,
            id: folderId,
            type: type,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function folderAddModule(
    moduleId: number,
    folderId: number,
    type: FolderType
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_folder_add_module", {
            module_id: moduleId,
            folder_id: folderId,
            type: type,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function folderDeleteModule(
    moduleId: number,
    folderId: number,
    type: FolderType
): Promise<void> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
        }>("/api/module_folder_delete_module", {
            module_id: moduleId,
            folder_id: folderId,
            type: type,
            update_id: stringSessionId(),
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export interface Folder {
    id: number;
    title: string;
    type: FolderType;
}

export function getModuleFolders(type: FolderType): Promise<Folder[]> {
    return axios
        .post<{
            success: boolean;
            error_msg: string;
            folders?: {
                id: number;
                title: string;
            }[];
        }>("/api/module_folders_get", {
            type: type,
        })
        .then((response) => {
            if (response.data.success) {
                return Promise.resolve(
                    response.data.folders!.map((folder) => ({
                        ...folder,
                        type: type,
                    }))
                );
            } else {
                return Promise.reject(response.data.error_msg);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
