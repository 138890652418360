import { Component } from "react";

export default class BaseSubmodule<
    Props,
    State = {},
    Context = {}
> extends Component<Props, State, Context> {
    constructor(props: Props) {
        if (new.target === BaseSubmodule)
            throw new TypeError(
                "Cannot construct BaseSubmodule instances directly"
            );
        super(props);
    }

    public static getTitle(): string {
        throw new TypeError("Please implement static abstract method getTitle");
    }
}
