import moment from "moment";
import PopupType from "./PopupType";
import { Insight } from "./insights_components/Insight";
import { Story } from "./insights_components/Story";
import { Condition, NodeLinkOption } from "./Conditions";
import { TableOption } from "common/Tables";
import { DataScopeOption } from "common/DataScopes";
import { VariableOption } from "common/Variables";
import NumericOption from "common/NumericOption";
import StringOption from "common/StringOption";
import StringUtils from "common/utilities/StringUtils";
import { InputBaseState, SchemaOptions, Type } from "common/InputData";
import { ManageTableOperationOption } from "common/ManageTableOperation";
import { MergeState } from "common/MergeData";
import { AggregateState } from "common/AggregateTable";
import {
    Modifier,
    SelectionState,
    EditorState,
    ContentState,
    RawDraftContentState,
    convertFromRaw,
    convertToRaw,
} from "draft-js";
import { GeoJsonObject } from "geojson";
import { GlobalInputType } from "common/GlobalInputs";
import { formatValue } from "common/utilities/FormatValue";
import { strftime } from "common/utilities/TimeFormatUtils";
import { mainStyle } from "common/MainStyle";
import { monthName } from "common/MonthData";
import Finding from "common/Finding";
import { DynamicOption } from "./DynamicOptions";
import {
    defaultMobileSlideHeight,
    defaultSlideHeight,
    tagDefaultHeight,
    tagDefaultWidth,
} from "modules/canvas_page/Constants";
import CurrentUser from "./CurrentUser";
import _ from "lodash";
import { dataScienceElementsStyle } from "./DataScienceElementsStyle";
import { TooltipOptions } from "common/ConfigurableTooltip/types";
import { CanvasViewMode } from "modules/canvas_page/CanvasTreeStore";
import { RuleCondition } from "./Survey/RuleCondition";

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export enum OperationType {
    EditVariable = 1,
    DeleteVariable = 2,
    AddVariable = 3,
    AddRow = 4,
    DeleteRow = 5,
}

export interface BaseOperation {
    type: OperationType;
}

export interface NodePosition {
    desktop: {
        x: number;
        y: number;
    };
    mobile: {
        x: number;
        y: number;
    };
}

export interface ShapeOptions {
    desktop: {
        scaleX?: number;
        scaleY?: number;
    };
    mobile: {
        scaleX?: number;
        scaleY?: number;
    };
}

export interface TimeAnimationSliderPosition {
    x: number;
    y: number;
}

export interface SlideRect {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface NodeIsHidden {
    desktop: boolean;
    mobile: boolean;
}

export interface SlideHeight {
    desktop: number;
    mobile: number;
}
export interface SlideWidth {
    desktop: number;
    mobile: number;
}

export interface NodeSize {
    desktop: {
        width: number;
        height: number;
    };
    mobile: {
        width: number;
        height: number;
    };
}

export interface AddVariableOperation extends BaseOperation {
    name: string;
    variableIndex: string;
    format: ColumnFormat | undefined;
}

export interface DeleteVariableOperation extends BaseOperation {
    variableIndex: number | string;
}

export interface EditVariableOperation extends BaseOperation {
    variableIndex: number | string;
    name: string;
    format: ColumnFormat | undefined;
}

export interface AddRowOperation extends BaseOperation {
    rowId: string;
}

export interface DeleteRowOperation extends BaseOperation {
    rowId: number | string;
}

export enum CanvasType {
    Box = 1,
    Input = 2,
    Slider = 3,
    Toggle = 4,
    DropdownSelector = 5,
    Shape = 6, // Deprecated, use ShapeElement instead
    SimpleSpreadSheetInput = 7,
    LinkButton = 8,
    TextBox = 9,
    SubmitButton = 10,
    BarcodeReader = 11,
    ProgressElement = 12,
    Filter = 13,
    RadioButtonsGroup = 14,
    Survey = 15,
}

export enum GanttChartDateIntervalType {
    Day = 1,
    Week = 2,
    Month = 3,
    Quarter = 4,
    Year = 5,
}

export enum CanvasGridType {
    FlowChart = 1,
    SpreadSheet = 2,
    SimpleSpreadSheet = 3,
}

export enum StatusColorType {
    Border = 1,
    Text = 2,
    Fill = 3,
}

export enum ArrowPosition {
    Left = 1,
    Bottom = 2,
    Right = 3,
    Top = 4,
    left = 1,
    bottom = 2,
    right = 3,
    top = 4,
}

export enum DefaultValueType {
    ClearEverySession = 1,
    LastTextAdded = 2,
    CustomText = 3,
    CurrentDateTime = 4,
}

export type DataScienceElementKey =
    | "manageTableElementsState"
    | "mergeDataElementsState"
    | "aggregateTableElementsState"
    | "dashboardsState"
    | "mapElementsState"
    | "graphElementsState"
    | "embedUrlElementsState"
    | "questionnaireElementsState"
    | "backendTablesState";

export interface ItemMetadata {
    id: string | number;
    type:
        | "slideNumber"
        | "pageBar"
        | "canvasTreeState"
        | "backgroundsState"
        | "shapeElementsState"
        | "gridsState"
        | "buttonsState"
        | DataScienceElementKey;
    groupId?: string | null;
}

export enum PageBarStyle {
    Default = 1,
    Pro = 2,
}

export function defaultSlideNumberOptions(
    slideSize: NodeSize
): SlideNumberOptions {
    return {
        nodePosition: {
            desktop: {
                x: slideSize["desktop"].width - 40,
                y: slideSize["desktop"].height - 25,
            },
            mobile: {
                x: slideSize["mobile"].width - 40,
                y: slideSize["mobile"].height - 25,
            },
        },
        nodeSize: {
            desktop: { width: 40, height: 25 },
            mobile: { width: 40, height: 25 },
        },
        show: true,
        fontSize: 10,
        fontColor: mainStyle.getPropertyValue("--canvas-page-bar-text-color"),
    };
}

export const DefaultNodePosition = {
    desktop: { x: 0, y: 0 },
    mobile: { x: 0, y: 0 },
};

export const DefaultCreatedNodePosition = 100;

export const DefaultNodeSize = {
    desktop: { width: 200, height: 100 },
    mobile: { width: 200, height: 100 },
};

export const DefaultTextBoxSize = {
    desktop: { width: tagDefaultWidth, height: tagDefaultHeight },
    mobile: { width: tagDefaultWidth, height: tagDefaultHeight },
};

export interface SlideNumberOptions {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    show: boolean;
    fontColor: string;
    fontSize: number;
}

export interface ModuleOptions {
    slideNumberOptions?: SlideNumberOptions;
}

export interface PageBarInfo {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    show: boolean;
    fontColor: string;
    fontSize: number;
    accordionFontSize?: number;
    fillColor: string;
    borderShadow: boolean;
    unlocked: boolean;
    hiddenOnPage?: boolean;
    style: PageBarStyle;
    userPositionOptions?: boolean;
    dividerColor?: string;
    accordionFormat?: {
        mobile: boolean;
        desktop: boolean;
    };
}

export interface PageBarStyleOption {
    label: string;
    value: PageBarStyle;
}

export const pageBarStyleOptions: ReadonlyArray<PageBarStyleOption> = [
    {
        label: "Default",
        value: PageBarStyle.Default,
    },
    {
        label: "Pro",
        value: PageBarStyle.Pro,
    },
];

export function defaultPageBarInfo(slideWidth: number) {
    let defaultWidth = 200;
    return {
        nodePosition: {
            desktop: { x: (slideWidth - defaultWidth) / 2, y: 0 },
            mobile: { x: (slideWidth - defaultWidth) / 2, y: 0 },
        },
        nodeSize: {
            desktop: { width: defaultWidth, height: 20 },
            mobile: { width: defaultWidth, height: 20 },
        },
        x: (slideWidth - defaultWidth) / 2,
        y: 0,
        show: true,
        width: defaultWidth,
        height: 20,
        fontSize: 10,
        fontColor: mainStyle.getPropertyValue("--canvas-page-bar-text-color"),
        fillColor: mainStyle.getPropertyValue(
            "--canvas-page-bar-background-color"
        ),
        borderShadow: false,
        unlocked: true,
        style: PageBarStyle.Default,
    };
}

export function Grid(
    id: string,
    x: number,
    y: number,
    rows: number,
    cols: number
): CanvasGrid {
    return {
        id: id,
        x: x,
        y: y,
        nodePosition: {
            desktop: {
                x,
                y,
            },
            mobile: {
                x,
                y,
            },
        },
        nodeIsHidden: {
            desktop: false,
            mobile: false,
        },
        rows: rows,
        cols: cols,
        type: CanvasGridType.FlowChart,
    };
}

export function SpreadSheetGrid(
    id: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    rowTitles: boolean,
    colTitles: boolean,
    index: string | undefined,
    isSimple: boolean
): CanvasSpreadSheetGrid {
    return {
        id: id,
        x: x,
        y: y,
        nodePosition: {
            desktop: {
                x,
                y,
            },
            mobile: {
                x,
                y,
            },
        },
        nodeIsHidden: {
            desktop: false,
            mobile: false,
        },
        rows: rows,
        cols: cols,
        type: isSimple
            ? CanvasGridType.SimpleSpreadSheet
            : CanvasGridType.SpreadSheet,
        headers: colTitles
            ? new Array(cols).fill({
                  text: "",
                  fontColor: undefined,
                  fontSize: undefined,
              })
            : undefined,
        headersEnabled: colTitles,
        leftHeadersEnabled: rowTitles,
        index: index,
    };
}

export function Node(
    id: number,
    nodeSpreadSheetId: number,
    parentId: Edge | undefined,
    pageId: number,
    nodePosition: NodePosition = {
        desktop: {
            x: DefaultCreatedNodePosition,
            y: DefaultCreatedNodePosition,
        },
        mobile: {
            x: DefaultCreatedNodePosition,
            y: DefaultCreatedNodePosition,
        },
    },
    nodeIsHidden: NodeIsHidden = { desktop: false, mobile: false },
    gridId: string | undefined = undefined
): CanvasElement {
    return {
        id: id,
        canvasType: CanvasType.Box,
        parentIds: parentId ? [parentId] : [],
        nodePosition,
        nodeIsHidden,
        childrenIds: [],
        arrowTexts: [],
        childrenSharedIds: [],
        name: "",
        metric: "",
        value: NaN,
        unit: "",
        popups: [],
        links: [],
        delegate: undefined,
        sharedId: undefined,
        outerId: outerNodeId(nodeSpreadSheetId),
        decimalPoints: 2,
        additionalOutputs: [],
        gridId: gridId,
    };
}

export function SimpleSpreadSheetInput(
    id: number,
    pageId: number,
    col: number,
    row: number,
    gridId: string,
    gridIndex: string | undefined
): CanvasSimpleSpreadSheetInput {
    let nodeOuterId: string = outerSpreadSheetId(gridIndex, col + 1, row + 1);
    return {
        id: id,
        canvasType: CanvasType.SimpleSpreadSheetInput,
        parentIds: [],
        nodePosition: {
            desktop: {
                x: col,
                y: row,
            },
            mobile: {
                x: col,
                y: row,
            },
        },
        nodeIsHidden: {
            desktop: false,
            mobile: false,
        },
        x: col,
        y: row,
        childrenIds: [],
        childrenSharedIds: [],
        metric: "",
        value: NaN,
        outerId: nodeOuterId,
        gridId: gridId,
    };
}

function isNum(str: string) {
    return /^\d+$/.test(str);
}

export function extractOuterId(fullOuterId: string): string {
    let terms = fullOuterId.split("_");
    let last = terms[terms.length - 1];
    if (isNum(last) || last === "min" || last === "max" || last === "error") {
        terms.pop();
        return terms.join("_");
    } else return fullOuterId;
}

export function extractOuterIdAndIndex(
    fullOuterId: string
): [string, string | undefined] {
    let terms = fullOuterId.split("_");
    let last = terms[terms.length - 1];
    if (isNum(last) || last === "min" || last === "max" || last === "error") {
        terms.pop();
        return [terms.join("_"), last];
    } else return [fullOuterId, undefined];
}

export function SpreadSheetNode(
    id: number,
    pageId: number,
    col: number,
    row: number,
    gridId: string,
    gridIndex: string | undefined
): CanvasElement {
    let nodeOuterId: string = outerSpreadSheetId(gridIndex, col + 1, row + 1);
    return {
        id: id,
        canvasType: CanvasType.Box,
        parentIds: [],
        nodePosition: {
            desktop: {
                x: col,
                y: row,
            },
            mobile: {
                x: col,
                y: row,
            },
        },
        nodeIsHidden: {
            desktop: false,
            mobile: false,
        },
        childrenIds: [],
        childrenSharedIds: [],
        name: "",
        metric: "",
        value: NaN,
        unit: "",
        popups: [],
        links: [],
        delegate: undefined,
        sharedId: undefined,
        outerId: nodeOuterId,
        decimalPoints: 2,
        additionalOutputs: [],
        gridId: gridId,
    };
}

export interface ConnectedBackendTable {
    rawTable: { [key: string]: (number | string | null)[] };
    rowId: number[];
    tableChanges: {
        [row_id: number]: {
            [column_index: number]: number | string | null;
        };
    };
}

export interface ConnectedBackendTables {
    [key: string]: ConnectedBackendTable;
}

export interface CanvasElementOutput {
    decimalPoints?: number;
    metric: string;
    value: NodeValue;
    unit: string;
    leftUnit?: string;
    subtitle?: string;
    format?: ColumnFormat | null;
    outerId?: string;
    childrenIds?: number[];
    childrenSharedIds?: SharedBoxLink[];
    globalInputIds?: GlobalInputLink[];
}

export interface SharedBoxOption {
    label: string;
    value: number; // id
    item: CanvasSharedBoxElement;
    canvasId: number;
    outerId: string;
}

export enum CanvasButtonType {
    InputData = 1,
}

export interface CanvasButton extends Groupable, Ordered {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    buttonType: CanvasButtonType;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
}

export interface StatusSubExpression {
    index?: number;
    operation: string;
    value: string | NodeLinkOption | null;
    isInput?: boolean;
}

export interface StatusExpression {
    subexpressions: StatusSubExpression[];
    color: {
        label: string;
        value: string;
    };
    fill?: boolean; // If true, then change fill color instead of border color
    font?: boolean; // If true, then change font color instead of border color
    borderWidth?: number; // Default: 1
    outputIndex?: number | null;
}

export interface StatusBarExpression extends StatusExpression {
    variable: NumericOption | null;
}

export interface LastNotificationExpression {
    leftValue: NodeValue;
    operation: string;
    rightValue: string | number;
}

export interface PrintExpression {
    subexpressions: StatusSubExpression[];
    outputIndex: number;
    text: string;
}

export interface NotificationExpression {
    lastNotificationExpression?: LastNotificationExpression[] | null;
    subexpressions: StatusSubExpression[];
    title: string;
    message: string;
    users: (string | null)[];
}

export function getCurrentNotificationExpression(
    node: CanvasElement | CanvasTextBox,
    expression: StatusExpression | NotificationExpression
): LastNotificationExpression[] {
    let result: LastNotificationExpression[] = [];
    for (let subExpression of expression.subexpressions) {
        let compareValue = subExpression.value
            ? subExpression.isInput
                ? Number((subExpression.value as NodeLinkOption).target)
                : Number(subExpression.value)
            : NaN;
        let leftValue;
        if (subExpression.index == null || subExpression.index === 1) {
            leftValue = node.value;
        } else {
            if (node.additionalOutputs.length > subExpression.index - 2) {
                leftValue =
                    node.additionalOutputs[subExpression.index - 2].value;
            } else {
                leftValue = "";
            }
        }
        result.push({
            leftValue: leftValue,
            operation: subExpression.operation,
            rightValue: compareValue,
        });
    }
    return result;
}

export function getDefaultNotificationExpression(): NotificationExpression {
    return {
        subexpressions: [{ operation: "", value: "" }],
        title: "",
        message: "",
        users: [null],
        lastNotificationExpression: null,
    };
}

export function getDefaultPrintExpression(): PrintExpression {
    return {
        subexpressions: [{ operation: "", value: "" }],
        text: "",
        outputIndex: 1,
    };
}

export interface SharedBoxLink {
    value: number;
    label: string;
}

export interface GlobalInputLink {
    value: GlobalInputType;
    label: string;
}

export function pushTableChange(
    grid: CanvasSpreadSheetGrid,
    operation: BaseOperation
) {
    if (grid.fullSpreadSheetBackendOutputOptions != null) {
        if (grid.fullSpreadSheetBackendOutputOptions.tableChanges == null)
            grid.fullSpreadSheetBackendOutputOptions.tableChanges = [];
        switch (operation.type) {
            case OperationType.DeleteRow:
                let rowId = (operation as DeleteRowOperation).rowId;
                if (typeof rowId === "string") {
                    grid.fullSpreadSheetBackendOutputOptions.tableChanges = grid.fullSpreadSheetBackendOutputOptions.tableChanges.filter(
                        (tableOperation) =>
                            tableOperation.type !== OperationType.AddRow ||
                            (tableOperation as AddRowOperation).rowId !== rowId
                    );
                    return;
                }
                break;
            case OperationType.AddRow:
            case OperationType.AddVariable:
                break;
            case OperationType.DeleteVariable: {
                let variableIndex = (operation as DeleteVariableOperation)
                    .variableIndex;
                if (typeof variableIndex === "number") {
                    grid.fullSpreadSheetBackendOutputOptions.tableChanges = grid.fullSpreadSheetBackendOutputOptions.tableChanges.filter(
                        (tableOperation) =>
                            tableOperation.type !==
                                OperationType.EditVariable ||
                            (tableOperation as EditVariableOperation)
                                .variableIndex !== variableIndex
                    );
                }
                if (typeof variableIndex === "string") {
                    grid.fullSpreadSheetBackendOutputOptions.tableChanges = grid.fullSpreadSheetBackendOutputOptions.tableChanges.filter(
                        (tableOperation) =>
                            tableOperation.type !== OperationType.AddVariable ||
                            (tableOperation as EditVariableOperation)
                                .variableIndex !== variableIndex
                    );
                    return;
                }
                break;
            }
            case OperationType.EditVariable: {
                let variableIndex = (operation as EditVariableOperation)
                    .variableIndex;
                if (typeof variableIndex === "number") {
                    grid.fullSpreadSheetBackendOutputOptions.tableChanges = grid.fullSpreadSheetBackendOutputOptions.tableChanges.filter(
                        (tableOperation) =>
                            tableOperation.type !==
                                OperationType.EditVariable ||
                            (tableOperation as EditVariableOperation)
                                .variableIndex !== variableIndex
                    );
                }
                if (typeof variableIndex === "string") {
                    grid.fullSpreadSheetBackendOutputOptions.tableChanges = grid.fullSpreadSheetBackendOutputOptions.tableChanges.filter(
                        (tableOperation) =>
                            tableOperation.type !== OperationType.AddVariable ||
                            (tableOperation as AddVariableOperation)
                                .variableIndex !== variableIndex
                    );
                    operation.type = OperationType.AddVariable;
                }
                break;
            }
        }
        grid.fullSpreadSheetBackendOutputOptions.tableChanges.push(operation);
    }
}

export interface SpreadSheetBackendOutputOptions {
    dataScopeId: number | string;
    limit?: number | null;
    variables?: VariableOption[] | null;
    conditions?: Condition[] | null;
    bottomRows?: boolean | null;
    tableOption?: TableOption | null;
    subsetInfo?: {
        rowId: (number | string)[];
        variableIndices: (number | string)[];
    };
    tableChanges?: BaseOperation[];
}

export interface CanvasGrid extends Unlockable, Groupable {
    questionnaireId?: string;
    rows: number;
    cols: number;
    id: string;
    x: number;
    y: number;
    nodePosition: NodePosition;
    nodeIsHidden: NodeIsHidden;
    columnScales?: number[];
    rowScales?: number[];
    leftTitleColumnScale?: number;
    titleRowScale?: number;
    lastSortDirection?: number;
    lastSortedColumn?: number;
    type?: CanvasGridType;
    fullSpreadSheetBackendOutputOptions?: SpreadSheetBackendOutputOptions | null;
    containerSize?: NodeSize;
    colorOptions?: GridColorOptions | null;
}

export enum ColumnFormatType {
    Text = 1,
    Number = 2,
    Enum = 3,
    Date = 4,
    Geography = 5,
    Month = 6,
}

export interface ColumnFormatOption {
    label: string;
    value: ColumnFormatType;
}

export const columnFormatOptions: ReadonlyArray<ColumnFormatOption> = [
    {
        label: "Text",
        value: ColumnFormatType.Text,
    },
    {
        label: "Number",
        value: ColumnFormatType.Number,
    },
    {
        label: "Select List",
        value: ColumnFormatType.Enum,
    },
    {
        label: "Date",
        value: ColumnFormatType.Date,
    },
    {
        label: "Month",
        value: ColumnFormatType.Month,
    },
    {
        label: "Geography",
        value: ColumnFormatType.Geography,
    },
];

export enum SpreadSheetColumnType {
    Text = 1,
    Number = 2,
    Enum = 3,
    Date = 4,
    Geography = 5,
    Month = 6,
}

export interface SpreadSheetColumnOption {
    label: string;
    value: SpreadSheetColumnType;
}

export const spreadSheetColumnOptions: ReadonlyArray<ColumnFormatOption> = [
    {
        label: "Text",
        value: ColumnFormatType.Text,
    },
    {
        label: "Number",
        value: ColumnFormatType.Number,
    },
    {
        label: "Select List",
        value: ColumnFormatType.Enum,
    },
    {
        label: "Date",
        value: ColumnFormatType.Date,
    },
    {
        label: "Month",
        value: ColumnFormatType.Month,
    },
    {
        label: "Geography",
        value: ColumnFormatType.Geography,
    },
];

export const spreadSheetColumnOptionsForImport: ReadonlyArray<ColumnFormatOption> = [
    {
        label: "Text",
        value: ColumnFormatType.Text,
    },
    {
        label: "Number",
        value: ColumnFormatType.Number,
    },
    {
        label: "Geography",
        value: ColumnFormatType.Geography,
    },
    {
        label: "Date",
        value: ColumnFormatType.Date,
    },
];

export const spreadSheetColumnOptionsForTextBox: ReadonlyArray<SpreadSheetColumnOption> = [
    {
        label: "Text",
        value: SpreadSheetColumnType.Text,
    },
    {
        label: "Number",
        value: SpreadSheetColumnType.Number,
    },
    {
        label: "Date",
        value: SpreadSheetColumnType.Date,
    },
    {
        label: "Month",
        value: SpreadSheetColumnType.Month,
    },
];

export const spreadSheetColumnOptionsForInput: ReadonlyArray<SpreadSheetColumnOption> = [
    {
        label: "Text",
        value: SpreadSheetColumnType.Text,
    },
    {
        label: "Number",
        value: SpreadSheetColumnType.Number,
    },
    {
        label: "Date",
        value: SpreadSheetColumnType.Date,
    },
];

export interface ColumnFormat {
    type: SpreadSheetColumnType | ColumnFormatType;
}

export interface ListFormat extends ColumnFormat {
    options: string[];
    allowMultipleSelection: boolean;
}

export interface DateFormat extends ColumnFormat {
    format: string;
}

export interface GeographyFormat extends ColumnFormat {
    level: string;
}

export enum NumberFormatType {
    Number = 1,
    Percent = 2,
    Currency = 3,
    Month = 4,
}

export interface NumberFormatOption {
    label: string;
    value: NumberFormatType;
}

export const numberFormatOptions: ReadonlyArray<NumberFormatOption> = [
    {
        label: "Number",
        value: NumberFormatType.Number,
    },
    {
        label: "Currency",
        value: NumberFormatType.Currency,
    },
    {
        label: "Percent",
        value: NumberFormatType.Percent,
    },
];

export interface NumberFormat extends ColumnFormat {
    numberType: NumberFormatType;
    decimalPoints: number;
    useCommaSeparator: boolean;
    useAbbreviation?: boolean;
}

export function isTextFormat(format: ColumnFormat | undefined | null): boolean {
    return format?.type === ColumnFormatType.Text;
}

export function isListFormat(
    format: ColumnFormat | undefined | null
): format is ListFormat {
    return format?.type === ColumnFormatType.Enum;
}

export function isGeographyFormat(
    format: ColumnFormat | undefined | null
): format is GeographyFormat {
    return format?.type === ColumnFormatType.Geography;
}

export function isDateFormat(
    format: ColumnFormat | undefined | null
): format is DateFormat {
    return format?.type === ColumnFormatType.Date;
}

export function isMonthFormat(
    format: ColumnFormat | undefined | null
): format is DateFormat {
    return format?.type === ColumnFormatType.Month;
}

export function isNumberFormat(
    format: ColumnFormat | undefined | null
): format is NumberFormat {
    return format?.type === ColumnFormatType.Number;
}

export interface CanvasSpreadSheetGrid extends CanvasGrid, Ordered, Groupable {
    headers?: CanvasGridHeader[];
    headersEnabled?: boolean;
    leftHeaders?: CanvasGridHeader[];
    leftHeadersEnabled?: boolean;
    index?: string;
    colorOptions?: GridColorOptions | null;
    viewDataset?: boolean;
}

export interface CanvasDataTableInputDetails {
    data_table_idx: number | string;
    optimized: boolean;
    table: number[];
    condition_id?: string;
    conditions: Condition[];
    update_time: number; // timestamp
    variables: Array<{
        name?: string; // legacy
        index: number;
        aggregation: string;
        percentile?: number;
        correlation_with_index?: number;
        alias: string;
    }>;
    zero_if_no_data?: boolean;
}

export interface CanvasGridHeader {
    text: string;
    fontColor?: string;
    fontSize?: number;
    columnFormat?: ColumnFormat;
}

export type NodeValue = number | string | number[] | string[];

export interface CanvasSharedNode {
    canvasType?: CanvasType;
    outerId: string;
    metric: string;
    value: NodeValue;
    shapeOptions?: ShapeOptions;
    fontColor?: string | null;
    fontSize?: number;
}

export interface Ordered {
    zIndex?: number;
}

export interface Unlockable {
    unlocked?: boolean;
}

export interface Groupable {
    groupId?: string | null;
}

export interface Edge {
    id: number;
    parentPosition: ArrowPosition;
    childPosition: ArrowPosition;
    shift?: number;
    arrowDoubleSided?: boolean;
}

export interface CanvasNode
    extends CanvasSharedNode,
        Unlockable,
        Groupable,
        Ordered {
    id: number;
    nodePosition: NodePosition;
    nodeIsHidden: NodeIsHidden;
    nodeTranslation?: NodeTranslation;
    childrenIds: number[];
    arrowTexts?: {
        id: number;
        label: string | null;
    }[];
    childrenSharedIds: SharedBoxLink[];
    x?: number;
    y?: number;
    globalInputIds?: GlobalInputLink[];
    parentIds: Edge[];
    sharedId?: number | null;
    liveStreaming?: boolean; // Default: false
    defaultValueType?: DefaultValueType;
    defaultText?: string;
    update?: DropdownUpdateOptions | undefined;
}

export function hasAdditionalOutputs(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasSharedBox {
    return "additionalOutputs" in canvasNode;
}

export function isBox(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasElement {
    return (
        canvasNode.canvasType == null ||
        canvasNode.canvasType === CanvasType.Box
    );
}

export function isRadioButtonsGroup(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasRadioButtonsGroup {
    return canvasNode.canvasType === CanvasType.RadioButtonsGroup;
}

export function isSimpleSpreadSheetInput(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasSimpleSpreadSheetInput {
    return canvasNode.canvasType === CanvasType.SimpleSpreadSheetInput;
}

export function isInput(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasInput {
    if (canvasNode) return canvasNode.canvasType === CanvasType.Input;
    return false;
}

export function isSubmitButton(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasSubmitButton {
    if (canvasNode)
        return (
            canvasNode.canvasType === CanvasType.SubmitButton ||
            canvasNode.canvasType === CanvasType.LinkButton
        );
    return false;
}

export function isSlider(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasSlider {
    return canvasNode.canvasType === CanvasType.Slider;
}

export function isProgressElement(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasProgressElement {
    return canvasNode.canvasType === CanvasType.ProgressElement;
}

export function isTextBox(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasTextBox {
    return canvasNode.canvasType === CanvasType.TextBox;
}

export function isToggle(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasToggle {
    return canvasNode.canvasType === CanvasType.Toggle;
}

export function isPureShape(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasShape {
    return canvasNode.canvasType === CanvasType.Shape;
}

export function isDropdownSelector(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasDropdownSelector {
    return canvasNode.canvasType === CanvasType.DropdownSelector;
}

export function isFilter(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasFilter {
    return canvasNode.canvasType === CanvasType.Filter;
}

export function isBarcodeReader(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasBarcodeReader {
    return canvasNode.canvasType === CanvasType.BarcodeReader;
}

export function isSurvey(
    canvasNode: CanvasSharedNode
): canvasNode is CanvasSurvey {
    return canvasNode.canvasType === CanvasType.Survey;
}

export function isFlowChartGrid(canvasGrid: CanvasGrid) {
    return (
        canvasGrid.type == null || canvasGrid.type === CanvasGridType.FlowChart
    );
}

export function isSpreadSheetGrid(
    canvasGrid: CanvasGrid
): canvasGrid is CanvasSpreadSheetGrid {
    return (
        canvasGrid.type === CanvasGridType.SpreadSheet ||
        canvasGrid.type === CanvasGridType.SimpleSpreadSheet
    );
}

export interface CanvasSharedSlider extends CanvasSharedNode {
    minOutput: CanvasElementOutput;
    maxOutput: CanvasElementOutput;
    vertical?: boolean;
    range?: boolean;
    stepSize?: number;
    min?: number; // old min
    max?: number; // old max
    lineThickness?: number;
    dataTableInputDetails?: CanvasDataTableInputDetails[];
    colorOptions?: {
        handle: string;
        rail: string;
    };
    format?: NumberFormat;
    additionalOutputs?: CanvasElementOutput[];
}

export const defaultErrorColors = {
    positive: "#05C985",
    negative: "#EE423D",
    neutral: "#FFAB4F",
};

export interface CanvasSharedProgressElement extends CanvasSharedNode {
    borderShadow?: boolean;
    minOutput: CanvasElementOutput;
    maxOutput: CanvasElementOutput;
    errorOutput: CanvasElementOutput;
    showErrorOutput?: boolean;
    unit: string;
    leftUnit?: string;
    decimalPoints?: number;
    linear?: boolean;
    labelColor?: string;
    labelSize?: number;
    fillColor?: string;
    subtitle: string;
    thickness?: number;
    dataTableInputDetails?: CanvasDataTableInputDetails[];
    errorColors?: {
        positive: string;
        negative: string;
        neutral: string;
    };
    // Conditional Formatting props
    statusColor?: {
        label: string;
        value: string;
    } | null;
    statusExpressions?: StatusExpression[];
    statusFill?: boolean; // If true, then change fill color instead of border color
    statusFont?: boolean; // If true, then change font color instead of border color
    statusBorderWidth?: number; // Default: 1
    // --------------------------------------------
    additionalOutputs: CanvasElementOutput[];
}

export interface CanvasSlider extends CanvasNode, CanvasSharedSlider {}

export interface CanvasProgressElement
    extends CanvasNode,
        CanvasSharedProgressElement {}

export interface CanvasSharedToggle extends CanvasSharedNode {
    hideLabels?: boolean;
}

export interface CanvasShape extends CanvasNode {}

export interface CanvasToggle extends CanvasNode, CanvasSharedToggle {
    colorOptions?: {
        handle: {
            checked: string;
            unchecked: string;
        };
        background: string;
    };
}

export interface CanvasRadioButtonsGroupData {
    idx: number;
    value: string;
    thumbnail?: string | null;
}

export interface CanvasRadioButtonsGroup extends CanvasNode, CanvasSharedNode {
    dataScopeOption: DataScopeOption | null;
    tableOption: TableOption | null;
    variableOption: VariableOption | null;
    imageVariableOption: VariableOption | null;
    format?: ColumnFormat | null;
    groupData?: Array<CanvasRadioButtonsGroupData>;
    chosenButton?: CanvasRadioButtonsGroupData;
    radioButtonsGroupStyle?: RadioButtonsGroupStyle;
    fillColor?: string | null;
    borderShadow?: boolean;
    borderColor?: string;
    fontFamily?: string;
    keyLabelOptions?: {
        fillColor?: string;
        borderColor?: string;
    };
    columns?: {
        label: VariableOption;
        icon: VariableOption;
    };
    update_time?: number | null; // timestamp
}

export interface CanvasSharedInput extends CanvasSharedNode {
    fillColor?: string | null;
    borderShadow?: boolean;
    fontFamily?: string;
}

export enum SharedLinkScrollOption {
    ScrollToTop = 1,
    KeepPosition = 2,
}

export const SharedLinkScrollOptions: ReadonlyArray<{
    label: string;
    value: SharedLinkScrollOption;
}> = [
    {
        label: "Start at top of slide",
        value: SharedLinkScrollOption.ScrollToTop,
    },
    {
        label: "Continue at current slide position",
        value: SharedLinkScrollOption.KeepPosition,
    },
];

export enum SubmitType {
    Regular = 1,
    Linked = 2,
    Global = 3,
}

export interface SubmitOption {
    type: SubmitType;
    label: string;
    value: number;
    outerId?: string;
    // Legacy field - replaced by "type"
    isGlobal?: boolean;
}

export interface CanvasSharedSubmitButton extends CanvasSharedNode {
    fillColor?: string | null;
    borderShadow?: boolean;
    backendOutput: {
        dataScopeOption: DataScopeOption | null;
        tableOption: TableOption | null;
        variableOptions: {
            contextVariable?: VariableOption;
            contextId?: any;
            update?: boolean;
            allowNans?: boolean;
            node: SubmitOption | null;
            variable: VariableOption | null;
        }[];
    };
    links: number[];
    linkPopups?: number[];
    isTextLink?: boolean;
    external?: boolean;
    externalLink?: string;
    sharedLinkScrollOption?: SharedLinkScrollOption;
}

export enum ButtonStyle {
    Default = 1,
    Refine = 2,
    Pro = 3,
}

export enum InputFieldStyle {
    Legacy = 1,
    Default = 2,
    // MD - Material Design
    MDFilled = 3,
    MDOutlined = 4,
}

export enum RadioButtonsGroupStyle {
    Default = 1,
    IconChoice = 2,
}

export interface ButtonStyleOption {
    label: string;
    value: ButtonStyle;
}

export const buttonStyleOptions: ReadonlyArray<ButtonStyleOption> = [
    {
        label: "Default",
        value: ButtonStyle.Default,
    },
    {
        label: "Refine",
        value: ButtonStyle.Refine,
    },
    {
        label: "Pro",
        value: ButtonStyle.Pro,
    },
];

export enum DropdownStyle {
    Default = 1,
    Refine = 2,
}

export interface DropdownStyleOption {
    label: string;
    value: DropdownStyle;
}

export interface InputFieldStyleOption {
    label: string;
    value: InputFieldStyle;
}

export interface RadioButtonsGroupStyleOption {
    label: string;
    value: RadioButtonsGroupStyle;
}

export const dropdownStyleOptions: ReadonlyArray<DropdownStyleOption> = [
    {
        label: "Default",
        value: DropdownStyle.Default,
    },
    {
        label: "Refine",
        value: DropdownStyle.Refine,
    },
];

export const inputFieldStyleOptions: ReadonlyArray<InputFieldStyleOption> = [
    {
        label: "Legacy",
        value: InputFieldStyle.Legacy,
    },
    {
        label: "Default",
        value: InputFieldStyle.Default,
    },
    {
        label: "Material Design - Filled",
        value: InputFieldStyle.MDFilled,
    },
    {
        label: "Material Design - Outlined",
        value: InputFieldStyle.MDOutlined,
    },
];

export const radioButtonStyleOption: ReadonlyArray<RadioButtonsGroupStyleOption> = [
    {
        label: "Default",
        value: RadioButtonsGroupStyle.Default,
    },
    {
        label: "Icon Choice",
        value: RadioButtonsGroupStyle.IconChoice,
    },
];

export interface CanvasNodeButton {
    buttonStyle?: ButtonStyle;
}

export interface CanvasSubmitButton
    extends CanvasNode,
        CanvasSharedSubmitButton,
        CanvasNodeButton {
    fontFamily?: string;
}

export interface CanvasInput extends CanvasNode, CanvasSharedInput {
    format?: ColumnFormat | null;
    inputFieldStyle?: InputFieldStyle;
    label?: string;
    multiline?: boolean;
    textAlignment?: DropdownOptionsAligment;
    dataScopeOption?: DataScopeOption | null;
    tableOption?: TableOption | null;
    variableOption?: VariableOption | null;
    update?: DropdownUpdateOptions | undefined;
}

export interface CanvasBarcodeReader
    extends CanvasNode,
        Omit<
            CanvasSubmitButton,
            | "links"
            | "linkPopups"
            | "isTextLink"
            | "external"
            | "externalLink"
            | "sharedLinkScrollOption"
        >,
        Ordered {}

export interface CanvasSimpleSpreadSheetInput extends CanvasNode {
    gridId: string;
    format?: ColumnFormat | null;
}

export enum TextAlign {
    left = "left",
    center = "center",
    right = "right",
}

export enum SortedOrder {
    Sort = 1,
    Database = 2,
}

export const sortedOptions = [
    {
        label: "Alphabetical",
        value: SortedOrder.Sort,
    },
    {
        label: "Data column order",
        value: SortedOrder.Database,
    },
];

export const dropdownOptionsAligmentOptions = [
    {
        label: "Left",
        value: TextAlign.left,
    },
    {
        label: "Centered",
        value: TextAlign.center,
    },
    {
        label: "Right",
        value: TextAlign.right,
    },
];

export interface CanvasSharedDropdownSelector extends CanvasSharedNode {
    dataScopeOption: DataScopeOption | null;
    tableOption: TableOption | null;
    variableOption: VariableOption | null;
    conditions?: Condition[] | null;
    fillColor?: string | null;
    borderColor?: string | null;
    borderShadow?: boolean;
    format?: ColumnFormat | null;
    nodeSize?: NodeSize;
    additionalOutputs?: CanvasElementOutput[];
    dynamicOption?: DynamicOption | null;
    variableMode?: boolean;
    columnOrder?: boolean;
    allValues?: boolean;
    allowAllValues?: boolean;
    multipleSelection: boolean;
    fontFamily?: string;
}

export interface CanvasSharedFilter extends CanvasSharedNode {
    dataScopeOption?: DataScopeOption | null;
    tableOption: TableOption | null;
    condition: Condition | null;
    fillColor?: string | null;
    borderShadow?: boolean;
    format?: ColumnFormat | null;
    nodeSize?: NodeSize;
    dynamicOption?: DynamicOption | null;
}

export interface DropdownOptionsAligment {
    label: string;
    value: string;
}

export interface CanvasFilter extends CanvasNode, CanvasSharedFilter, Ordered {
    update?: DropdownUpdateOptions | undefined;
}

export interface CanvasDropdownSelector
    extends CanvasNode,
        CanvasSharedDropdownSelector,
        Ordered {
    update?: DropdownUpdateOptions | undefined;
    dropdownStyle?: DropdownStyle;
    dropdownOptionsAligment?: DropdownOptionsAligment;
}

export interface DropdownUpdateOptions {
    active?: boolean;
    dataScopeOption?: DataScopeOption | null;
    variableOption?: VariableOption;
    contextId?: any;
}

export interface CanvasSharedBox extends CanvasSharedNode {
    name: string;
    popups: CanvasPopupElement[];
    unit: string;
    links: number[];
    linkPopups?: number[];
    decimalPoints?: number;
    statusColor?: {
        label: string;
        value: string;
    } | null;
    statusFill?: boolean; // If true, then change fill color instead of border color
    statusFont?: boolean; // If true, then change font color instead of border color
    statusBorderWidth?: number; // Default: 1
    statusExpressions?: StatusExpression[];
    notificationExpressions?: NotificationExpression[];
    borderShadow?: boolean;
    labelColor?: string;
    labelSize?: number;
    leftUnit?: string;
    subtitle?: string;
    additionalOutputs: CanvasElementOutput[];
    fillColor?: string | null;
    format?: ColumnFormat | null;
    external?: boolean;
    externalLink?: string;
}

export interface CanvasElement extends CanvasSharedBox, CanvasNode {
    dataTableInputDetails?: CanvasDataTableInputDetails[];
    delegate?: number;
    collapsed?: boolean;
    gridId?: string;
}

export interface CanvasBackgroundBase {
    group_id?: string | null;
    image_url: string;
    x: number;
    y: number;
    scale_x: number;
    scale_y: number;
    is_root: boolean;
    id: number;
    natural_width?: number;
    natural_height?: number;
    z_index?: number;
    mobile_scale_x: number;
    mobile_scale_y: number;
    mobile_x: number;
    mobile_y: number;
    is_hidden?: boolean;
    mobile_is_hidden?: boolean;
}

export interface CanvasBackground extends CanvasBackgroundBase {
    style_options?: BackgroundShapeStyle;
}

export interface CanvasBackgroundResponse extends CanvasBackgroundBase {
    style_options?: string;
}

export function formatNumber(value: number | null, format: NumberFormat) {
    if (value == null) return "";
    let formattedValue: string = "";
    if (format.numberType === NumberFormatType.Percent) value = value * 100;
    if (format.useAbbreviation ?? false) {
        formattedValue = formatValue(value, false, format.decimalPoints).join(
            ""
        );
    } else {
        if (!(format.useCommaSeparator ?? true)) {
            formattedValue = value.toFixed?.(format.decimalPoints);
        } else {
            formattedValue = value.toLocaleString("en-US", {
                minimumFractionDigits: format.decimalPoints,
                maximumFractionDigits: format.decimalPoints,
            });
        }
    }
    formattedValue = `${
        format.numberType === NumberFormatType.Currency ? "$" : ""
    }${formattedValue}${
        format.numberType === NumberFormatType.Percent ? "%" : ""
    }`;
    return formattedValue;
}

export function getBackgroundPlacementData(
    canvasViewMode: string,
    background: CanvasBackground
): {
    positionX: number;
    positionY: number;
    scaleX: number;
    scaleY: number;
    translationX: number;
    translationY: number;
} {
    const isCanvasDesktopView = canvasViewMode === "desktop";

    const positionX = isCanvasDesktopView ? background.x : background.mobile_x;
    const positionY = isCanvasDesktopView ? background.y : background.mobile_y;

    const translationX =
        (isCanvasDesktopView
            ? background.style_options?.nodeTranslation?.desktop.x
            : background.style_options?.nodeTranslation?.mobile.x) ?? 0;

    const translationY =
        (isCanvasDesktopView
            ? background.style_options?.nodeTranslation?.desktop.y
            : background.style_options?.nodeTranslation?.mobile.y) ?? 0;

    const scaleX = isCanvasDesktopView
        ? background.scale_x
        : background.mobile_scale_x;
    const scaleY = isCanvasDesktopView
        ? background.scale_y
        : background.mobile_scale_y;

    return {
        positionX,
        positionY,
        scaleX,
        scaleY,
        translationX,
        translationY,
    };
}

export function parseNumber(metric: string) {
    let newMetric = metric.replace(/,/g, "");
    let normalizeCoefficient = 1;
    if (newMetric.includes("K")) {
        normalizeCoefficient = Math.pow(10, 3);
    }
    if (newMetric.includes("M")) {
        normalizeCoefficient = Math.pow(10, 6);
    }
    if (newMetric.includes("B")) {
        normalizeCoefficient = Math.pow(10, 9);
    }
    if (newMetric.endsWith("%")) {
        normalizeCoefficient = normalizeCoefficient * 0.01;
    }
    newMetric = newMetric.replace(/[KMB$%]/g, "");
    let number = Number.parseFloat(newMetric);
    if (number != null && !isNaN(number))
        number = number * normalizeCoefficient;
    return number;
}

export interface CanvasSharedTextBox extends CanvasSharedNode {
    rawMetric?: RawDraftContentState;
    lastRawMetricDelta?: RawDraftContentState | null;
    text: string; //legacy
    raw?: RawDraftContentState; //legacy
    html?: string; //legacy
    additionalOutputs: CanvasElementOutput[];
    format?: ColumnFormat | null;
}

export function getPrettyPrintFormatValue(
    node: {
        metric: string | undefined;
        value?: NodeValue | null;
        decimalPoints?: number;
        update?: DropdownUpdateOptions | undefined;
    },
    format: ColumnFormat | undefined | null,
    showPlaceholder: boolean = false,
    defaultText: string = "",
    utc: boolean = false
): string {
    if (isDateFormat(format)) {
        let value = node.value;
        if (value == null || !StringUtils.isNumber(value as number | string))
            return "Invalid date";

        let offset: number = 0;
        if (!utc) {
            let timezone =
                CurrentUser?.info?.user_time_zone ?? moment.tz.guess();
            // We need to set the offset in any case because not only time, but also
            // date might be different in UTC and in local time zone
            offset =
                moment.tz((value as number) * 1000, timezone)?.utcOffset() ?? 0;
        }
        let time = strftime(
            format.format,
            new Date((value as number) * 1000 + offset * 60 * 1000),
            true
        );
        return time;
    }
    if (isNumberFormat(format)) {
        let value = node.value;
        if (value == null || !StringUtils.isNumber(value as number | string))
            return "";
        return formatNumber(value as number, format);
    }
    if (isMonthFormat(format)) {
        return monthName[Number(node.value) - 1] ?? "Invalid month";
    }
    if (isTextFormat(format) || isGeographyFormat(format)) {
        if (node.metric?.startsWith("="))
            return String(node.value) || (!showPlaceholder ? "" : defaultText);
        else return node.metric || (!showPlaceholder ? "" : defaultText);
    }
    let formattedValue: string = "";
    if (node.metric?.startsWith("=")) {
        if (
            node.value != null &&
            StringUtils.isNumber(node.value as number | string)
        ) {
            let formatter = new Intl.NumberFormat("en-us", {
                maximumFractionDigits: node.decimalPoints ?? 2,
                minimumFractionDigits: node.decimalPoints ?? 2,
            });
            formattedValue = formatter.format(node.value as number);
        } else if (node.value != null) {
            return node.value as string;
        } else {
            return !showPlaceholder ? "" : defaultText;
        }
    } else {
        let value = Number(node.metric || NaN);
        if (!isNaN(value)) {
            let formatter = new Intl.NumberFormat("en-us", {
                maximumFractionDigits: node.decimalPoints ?? 2,
                minimumFractionDigits: node.decimalPoints ?? 2,
            });
            formattedValue = formatter.format(value);
        } else {
            formattedValue =
                node.metric || (!showPlaceholder ? "" : defaultText);
        }
    }
    return formattedValue;
}

export function getPrettyPrintValue(
    node: {
        metric: string | undefined;
        value: NodeValue | undefined;
        decimalPoints?: number;
        leftUnit?: string;
        unit?: string;
        subtitle?: string;
        format?: ColumnFormat | null;
    },
    showPlaceholder: boolean,
    withSubtitle: boolean,
    defaultText: string = "Enter Number"
): string {
    let formattedValue: string = getPrettyPrintFormatValue(
        node,
        node.format,
        showPlaceholder,
        defaultText
    );
    let result = (node.leftUnit || "")
        .concat(node.leftUnit ? " ".concat(formattedValue) : formattedValue)
        .concat(node.unit ? " ".concat(node.unit) : "");
    if (!withSubtitle) return result;
    result = (node.subtitle || "").concat(
        node.subtitle ? " ".concat(result) : result
    );
    return result;
}

export function getCanvasTextBoxOutput(
    canvasTextBox: CanvasTextBox,
    outputIndex: number
) {
    let output: CanvasTextBox | CanvasElementOutput | undefined;
    if (outputIndex === 1) output = canvasTextBox;
    if (outputIndex > 1)
        output = canvasTextBox.additionalOutputs[outputIndex - 2];
    return getPrettyPrintFormatValue(
        output as CanvasElementOutput,
        output!.format
    );
}

export function getAnimateNumberFormatOutput(
    canvasTextBox: CanvasTextBox,
    animateNumber: number,
    outputIndex: number
) {
    let output: CanvasTextBox | CanvasElementOutput | undefined;
    if (outputIndex === 1) output = canvasTextBox;
    if (outputIndex > 1)
        output = canvasTextBox.additionalOutputs[outputIndex - 2];
    return getPrettyPrintFormatValue(
        {
            metric: String(animateNumber),
            value: animateNumber,
        },
        output!.format
    );
}

export function getFormulaTextEntities(
    contentState: ContentState
): {
    key: string;
    blockKey: string;
    start: number;
    end: number;
    outputIndex: number;
}[] {
    let result: {
        key: string;
        blockKey: string;
        start: number;
        end: number;
        outputIndex: number;
    }[] = [];
    let blocks = contentState.getBlocksAsArray();
    for (let block of blocks) {
        for (let i = 0; i < block.getLength(); ++i) {
            let entityKey = block.getEntityAt(i);
            if (entityKey != null) {
                let entity = contentState.getEntity(entityKey);
                if (entity.getType() === "FORMULA") {
                    let entityResult = result.find(
                        (item) => item.key === entityKey
                    );
                    if (entityResult == null) {
                        result.push({
                            key: entityKey,
                            start: i,
                            end: i + 1,
                            outputIndex: entity.getData().outputIndex,
                            blockKey: block.getKey(),
                        });
                    } else {
                        entityResult.end = i + 1;
                    }
                }
            }
        }
    }
    return result;
}

export function generateRawMetric(
    textBox: CanvasTextBox,
    rawMetric: RawDraftContentState,
    hideOutputs: boolean = false
): RawDraftContentState | undefined {
    let editorState = EditorState.createWithContent(convertFromRaw(rawMetric));
    let contentState = editorState.getCurrentContent();

    let currentEntities = getFormulaTextEntities(contentState);
    for (let i = 0; i < currentEntities.length; ++i) {
        let currentEntity = currentEntities[i];
        const inlineStyles = contentState
            .getBlockForKey(currentEntity.blockKey)
            .getInlineStyleAt(currentEntity.start);
        let selectionState = new SelectionState({
            anchorKey: currentEntity.blockKey,
            anchorOffset: currentEntity.start,
            focusKey: currentEntity.blockKey,
            focusOffset: currentEntity.end,
        });
        if (hideOutputs) {
            contentState = Modifier.replaceText(
                contentState,
                selectionState,
                "-",
                inlineStyles,
                currentEntity.key
            );
        } else {
            let printText = getPrintText(textBox, currentEntity.outputIndex);
            contentState = Modifier.replaceText(
                contentState,
                selectionState,
                (printText ??
                    getCanvasTextBoxOutput(
                        textBox,
                        currentEntity.outputIndex
                    )) ||
                    "-",
                inlineStyles,
                currentEntity.key
            );
        }
        currentEntities = getFormulaTextEntities(contentState);
        let spaceSelectionState = new SelectionState({
            anchorKey: currentEntity.blockKey,
            anchorOffset: currentEntity.end,
            focusKey: currentEntity.blockKey,
            focusOffset: currentEntity.end,
        });
        let lastItem =
            contentState.getBlockAfter(currentEntity.blockKey) == null &&
            contentState.getBlockForKey(currentEntity.blockKey).getLength() ===
                currentEntity.end;
        if (lastItem) {
            contentState = Modifier.replaceText(
                contentState,
                spaceSelectionState,
                " ",
                undefined,
                undefined
            );
            currentEntities = getFormulaTextEntities(contentState);
        }
    }
    editorState = EditorState.push(
        editorState,
        contentState,
        "change-block-data"
    );
    return convertToRaw(editorState.getCurrentContent());
}

export function checkTextBoxHasSingleOutput(textBox: CanvasTextBox): boolean {
    if (textBox.rawMetric == null) return false;
    let editorState = EditorState.createWithContent(
        convertFromRaw(textBox.rawMetric)
    );
    let contentState = editorState.getCurrentContent();

    let currentEntities = getFormulaTextEntities(contentState);
    if (currentEntities.length !== 1) return false;

    let currentEntity = currentEntities[0];
    let blocks = contentState.getBlocksAsArray();
    if (blocks.length === 1) {
        let block = blocks[0];
        let blockText = block.getText().trim();
        if (blockText.length === currentEntity.end - currentEntity.start)
            return true;
    }

    return false;
}

export interface CanvasTextBox
    extends CanvasNode,
        CanvasSharedTextBox,
        Ordered {
    nodeSize?: NodeSize;
    dataTableInputDetails?: CanvasDataTableInputDetails[];
    decimalPoints?: number;
    fillColor?: string | null;
    borderColor?: string | null;
    borderRadius?: number | null;
    borderShadow?: boolean;
    leftUnit?: string;
    subtitle?: string;
    unit?: string;
    popups: CanvasPopupElement[];
    links: number[];
    linkPopups?: number[];
    delegate?: number;
    statusColor?: {
        label: string;
        value: string;
    };
    lastTextEditSessionId?: number | null;
    statusFill?: boolean; // If true, then change fill color instead of border color
    statusFont?: boolean; // If true, then change font color instead of border color
    statusBorderWidth?: number; // Default: 1
    statusExpressions?: StatusExpression[];
    notificationExpressions?: NotificationExpression[];
    printExpressions?: PrintExpression[];
}

export interface CanvasLegacyTagElement extends Unlockable {
    html?: string;
    raw?: RawDraftContentState;
    id: string;
    nodePosition: NodePosition;
    nodeIsHidden: NodeIsHidden;
    width?: number;
    height?: number;
    text: string;
    fillColor?: string;
    borderShadow?: boolean;
}

export interface CanvasPopupElement {
    id: number;
    type: PopupType;
    hash: string;
    item: Insight | Story;
}

export interface CanvasSharedBoxElement {
    id: number;
    box: CanvasSharedNode;
    page_id: number;
    page_title: string;
    canvas_title: string;
    canvas_id: number;
}

export interface CanvasSharedBoxHeader {
    id: number;
    hidden?: boolean;
}

export enum DashboardVersion {
    Second = 2,
}

export enum MapVersion {
    Second = 2,
}

export interface CanvasDashboard extends Ordered, Groupable {
    // TODO Fill this
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    nodeIsHidden: NodeIsHidden;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    colorOptions?: ColorOptions | null;
    finding?: Finding | null;
    version?: DashboardVersion;
    filterIndexInitializer?: number;
    linkPopups?: any;
    links?: any;
}

export interface CanvasTable extends Ordered, Groupable {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    dataScopeOption: DataScopeOption | null;
    tableOption: TableOption | null;
    conditions: Condition[] | null;
    variables: VariableOption[] | null;
    rowCount: number;
    sort?: { columnIndex: number; direction: "asc" | "desc" } | null;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    colorOptions?: ColorOptions | null;
    columnScales?: { [key: number]: number } | null;
}

export interface InnerCanvas {
    slideHeight?: SlideHeight;
    slideColor?: string;
    hidePagesBar?: boolean;
    mobileViewWasEdited?: boolean;
    canvasUpdateTimeState: number; // timestamp
    canvasAutoIncrementId: number;
    nodeSpreadSheetAutoIncrementId?: number;
    canvasTreeState: {
        [key: string]: CanvasNode;
    };
    gridsState?: {
        [key: string]: CanvasGrid;
    };
    backendTablesState?: {
        [key: string]: CanvasTable;
    };
    questionnaireElementsState?: {
        [key: string]: QuestionnaireElement;
    };
    mapElementsState?: {
        [key: string]: MapElement;
    };
    graphElementsState?: {
        [key: string]: GraphElement;
    };
    embedUrlElementsState?: {
        [key: string]: EmbedUrlElement;
    };
    shapeElementsState?: {
        [key: string]: ShapeElement;
    };
    // Deprecated
    // inputDataElementsState?: {
    //     [key: string]: InputDataElement;
    // };
    mergeDataElementsState?: {
        [key: string]: MergeDataElement;
    };
    manageTableElementsState?: {
        [key: string]: ManageTableElement;
    };
    aggregateTableElementsState?: {
        [key: string]: AggregateTableElement;
    };
    buttonsState?: {
        [key: string]: CanvasButton;
    };
    dashboardsState?: {
        [key: string]: CanvasDashboard;
    };
}

export type CanvasElementType =
    | "canvasTreeState"
    | "backgroundsState"
    | "shapeElementsState"
    | "gridsState"
    | "buttonsState"
    | DataScienceElementKey;

export function defaultCanvas(): InnerCanvas {
    return {
        canvasUpdateTimeState: 0,
        canvasAutoIncrementId: 0,
        nodeSpreadSheetAutoIncrementId: 0,
        canvasTreeState: {},
    };
}

export interface SubCanvas {
    tags: CanvasLegacyTagElement[];
    sharedBoxes: CanvasSharedBoxHeader[];
    nodes: CanvasNode[];
    shapes: ShapeElement[];
    grids: CanvasGrid[];
    backgrounds: CanvasBackground[];
    buttons: CanvasButton[];
    otherItems?: Partial<
        {
            [key in ItemMetadata["type"]]: any[];
        }
    >;
}

export interface InnerCanvasChanges {
    slideHeight?: SlideHeight;
    slideColor?: string;
    hidePagesBar?: boolean;
    mobileViewWasEdited?: boolean;
    canvasUpdateTimeState?: number; // timestamp
    canvasAutoIncrementId?: number;
    nodeSpreadSheetAutoIncrementId?: number;
    canvasTreeState?: {
        [key: string]: CanvasNode | null;
    };
    tagsState?: CanvasLegacyTagElement[] | null;
    gridsState?: {
        [key: string]: CanvasGrid | null;
    };
    backendTablesState?: {
        [key: string]: CanvasTable | null;
    };
    questionnaireElementsState?: {
        [key: string]: QuestionnaireElement | null;
    };
    mapElementsState?: {
        [key: string]: MapElement | null;
    };
    graphElementsState?: {
        [key: string]: GraphElement | null;
    };
    embedUrlElementsState?: {
        [key: string]: EmbedUrlElement | null;
    };
    shapeElementsState?: {
        [key: string]: ShapeElement | null;
    };
    // Deprecated
    // inputDataElementsState?: {
    //     [key: string]: Partial<InputDataElement> | null;
    // };
    mergeDataElementsState?: {
        [key: string]: MergeDataElement | null;
    };
    manageTableElementsState?: {
        [key: string]: ManageTableElement | null;
    };
    aggregateTableElementsState?: {
        [key: string]: AggregateTableElement | null;
    };
    buttonsState?: {
        [key: string]: CanvasButton | null;
    };
    dashboardsState?: {
        [key: string]: CanvasDashboard | null;
    };
}

export interface LiveStreamInfo {
    exists: boolean;
    data_table_idx?: string | number;
    update_interval?: string;
    weekday?: string;
    time?: string;
}

export interface BriefCanvas {
    id: number;
    delegate_id: number;
    title: string;
    has_new_notifications: boolean;
    page_id: number;
    page_title: string;
    backgrounds: CanvasBackground[];
    live_streaming: LiveStreamInfo;
    thumbnail: string | null;
    hide_in_slideshow: boolean;
    layer_for_canvas_id?: number;
}

export interface ModuleSharedBoxElement {
    id: number;
    box: CanvasSharedNode;
}

export interface SharedModuleCanvas {
    id: number;
    page_id: string;
    layer_for_canvas_id?: number;
    title: string;
    thumbnail: string | null;
    canvas: InnerCanvas;
    backgrounds: CanvasBackground[];
    shared_boxes: ModuleSharedBoxElement[];
    hide_in_slideshow: boolean;
}

export interface PlacementToolbar {
    position: {
        x: number;
        y: number;
    };
}

export interface Canvas {
    id: number;
    delegate_id: number;
    title: string;
    canvas: InnerCanvas;
    has_new_notifications: boolean;
    page_id: number;
    page_title: string;
    backgrounds: CanvasBackground[];
    live_streaming: LiveStreamInfo;
    thumbnail: string | null;
    hide_in_slideshow: boolean;
    placementToolbar: PlacementToolbar;
    layer_for_canvas_id?: number;
}

export interface SlideTemplate {
    id: number;
    title: string;
    collection_id: number;
    canvas?: InnerCanvas;
    backgrounds: CanvasBackground[];
    thumbnail?: string;
    layer_for_canvas_id?: number;
}

export interface SharedCanvas extends InnerCanvas {
    backgroundsState: CanvasBackground[];
    connectedBackendTablesState: ConnectedBackendTables;
}

// CanvasSharedBoxElement as it is sent from the server
export interface CanvasSharedBoxElementResponse {
    id: number;
    box: string;
    page_id: number;
    page_title: string;
    canvas_title: string;
    canvas_id: number;
}

// Canvas as it is sent from the server
export interface CanvasResponse {
    id: number;
    delegate_id: number;
    title: string;
    canvas: string; // content is encoded as string
    page_id: number;
    page_title: string;
    has_new_notifications: boolean;
    live_streaming: LiveStreamInfo;
    backgrounds: CanvasBackgroundResponse[];
    thumbnail: string | null;
    hide_in_slideshow: boolean;
    placementToolbar: PlacementToolbar;
    layer_for_canvas_id?: number;
}

export interface TemplateResponse {
    id: number;
    title: string;
    canvas: string; // content is encoded as string
    backgrounds: CanvasBackgroundResponse[];
    collection_id: number;
    thumbnail?: string;
    layer_for_canvas_id?: string;
}

export enum QuestionType {
    Text = 1,
    MultipleChoice = 2,
    Dropdown = 3,
    Date = 4,
    TemplateSheet = 5,
    File = 6,
}

export enum SurveyQuestionType {
    Text = 1,
    MultipleChoice = 2,
    Dropdown = 3,
    Slider = 7,
    Rule = 8,
    LongText = 9,
}

export enum QuestionnaireOutputType {
    Sheet = 2,
    BackendTable = 3,
}

export interface SurveyDropdownOption {
    value: string;
    label?: string;
}
export interface SurveyQuestion {
    id: number;
    question: string;
    columnName?: string;
    type: SurveyQuestionType;
    options: SurveyDropdownOption[];
    min?: string;
    max?: string;
    required?: boolean;
}

export interface RuleSurveyQuestion extends SurveyQuestion {
    linkedToId: number | null;
    rules: {
        ruleConditions: RuleCondition[];
        value: string;
    }[];
}

export function isRuleSurveyQuestion(
    surveyQuestion: SurveyQuestion
): surveyQuestion is RuleSurveyQuestion {
    return surveyQuestion.type === SurveyQuestionType.Rule;
}

export interface Question {
    question: string; // Question text for edit mode
    liveQuestion?: string; // Question text for live mode
    type: QuestionType;
}
export interface CanvasSurvey
    extends CanvasNode,
        Omit<
            CanvasSubmitButton,
            | "links"
            | "linkPopups"
            | "isTextLink"
            | "external"
            | "externalLink"
            | "sharedLinkScrollOption"
        >,
        Ordered {
    nodeSize: NodeSize;
    questions: SurveyQuestion[];
    links?: number[];
}

export interface TemplateSheetQuestion extends Question {
    pageOption: NumericOption | null;
    sheetOption: NumericOption | null;
    leftSheetTitle: string | null;
    titleSheetPlaceholder: NumericOption | null;
    rightSheetTitle: string | null;
    nodeOptions: { [key: number]: NumericOption };
}

export interface TextQuestion extends Question {
    answer: string;
}

export interface FileQuestion extends Question {
    answer: string | File | null;
}

export interface DateQuestion extends Question {
    answer: string;
    currentDate: boolean;
    dateOnly?: boolean;
}

export interface MultipleChoiceQuestion extends Question {
    options: {
        option: string;
        selected: boolean;
    }[];
}

export interface DropdownQuestion extends Question {
    tableValue: string | number | null;
    dataScopeOption: DataScopeOption | null;
    tableOption: TableOption | null;
    variableOption: VariableOption | null;
}

export interface ColorOptions {
    borderShadow: boolean;
    fillColor: string;
    borderColor: string;
    borderRadius?: number;
}

export const defaultColorOptions = {
    borderShadow: false,
    fillColor: dataScienceElementsStyle.contentColor,
    borderColor: dataScienceElementsStyle.borderColor,
    borderRadius: 0,
};

export interface LeadersLaggersFontStyle {
    alignment: { label: string; value: TextAlign };
    title: {
        fontSize: number;
        color: string;
        fontFamily: string;
    };
    text: {
        fontSize: number;
        color: string;
        fontFamily: string;
    };
    aliase: {
        fontSize: number;
        fontFamily: number;
    };
}

export interface GridColorOptions extends ColorOptions {
    textColor: string;
    fontSize?: number;
}

export interface MapColorOptions extends ColorOptions {
    lockMap?: boolean;
    grayscale?: boolean;
    minZoomLevel?: number | null;
}

export interface QuestionnaireElement extends Ordered, Groupable {
    outputType: QuestionnaireOutputType;
    templateSheetOutputEnabled: boolean;
    questions: Question[];
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    introTitle?: string;
    introSubtitle?: string;
    endTitle?: string;
    thankYouTitle?: string;
    isDone: boolean;
    colorOptions?: ColorOptions | null;
    backendOutput?: {
        dataScopeOption: DataScopeOption | null;
        tableOption: TableOption | null;
        variableOptions: { [key: number]: VariableOption };
    };
}

export enum MapTooltipDisplayMode {
    onClick = 0,
    onHover = 1,
    always = 2,
}
export interface MapTooltipDisplayModeOption {
    label: string;
    value: MapTooltipDisplayMode;
}

export interface MapVariableViewOptions {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    fontSize: number;
    fontColor: string;
    textAlign?: TextAlign;
    showTitle?: boolean; // Default: true
    format?: ColumnFormat;
}

export interface MapVariableOption {
    variable: VariableOption;
    options: MapVariableViewOptions;
    linkVariable?: VariableOption | null;
}

export interface MapDataVariableOption {
    label: string;
    value: MapDataVariableValue;
}

export enum MapDataVariableValue {
    LatitudeLongitude = 0,
    CountryList = 1,
    CountryColumn = 2,
}

export interface MapElement extends Ordered, Groupable {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    dataScope: DataScopeOption | null;
    tableOption: TableOption | null;
    usesCoordinates?: boolean;
    dataVariableOption?: MapDataVariableOption;
    location?: {
        country?: VariableOption | StringOption;
        state?: VariableOption;
        city?: VariableOption;
        address?: VariableOption;
        zipcode?: VariableOption;
    } | null;
    coordinates?: {
        latitude?: VariableOption;
        longitude?: VariableOption;
    } | null;
    displayMode?: MapTooltipDisplayModeOption;
    tooltipVariables?: (MapVariableOption | null)[];
    tooltipOptions?: TooltipOptions | null;
    displayAlways?: (MapVariableOption | null)[] | null;
    displayOnClick?: (MapVariableOption | null)[] | null;
    displayOnHover?: (MapVariableOption | null)[] | null;
    displayAlwaysOptions?: TooltipOptions | null;
    displayOnClickOptions?: TooltipOptions | null;
    displayOnHoverOptions?: TooltipOptions | null;
    selectLowest?: boolean;
    selectLimit?: number | null;
    selectOrderBy?: (VariableOption | null)[] | null;
    conditions?: Condition[] | null;
    zoom?: number | null;
    center?: {
        lat: number;
        lng: number;
    } | null;
    heatMap?: VariableOption | null;
    colorOptions?: MapColorOptions | null;
    markerIcon?: string | null; // base64 image
    markerColor?: string | null;
    markerColorVariableIndex?: number | null;
    varyMarkerColorByVariable?: boolean; // default: false
    geoJsonFiles?: {
        [key: string]: {
            name: string;
            contents: GeoJsonObject;
            color?: string;
        } | null;
    };
    isDone: boolean; // backward compatibility
    version?: MapVersion;
}

export interface GraphNode {
    id: number;
    title: string;
    x: number;
    y: number;
    type: string;
    metrics?: { [key: string]: string | number | null };
}

export interface GraphEdge {
    source: number;
    target: number;
    type: string;
}

export interface GraphElement extends Ordered, Groupable {
    x: number;
    y: number;
    width: number;
    height: number;
    colorOptions: ColorOptions;
    nodes: { [key: string]: GraphNode };
    edges: { [key: string]: GraphEdge };
    nextNodeId: number;
    nextEdgeId: number;
    dataSet?: {
        dataScope: DataScopeOption | null;
        tableOption: TableOption | null;
        nodeId: VariableOption | null;
        x: VariableOption | null;
        y: VariableOption | null;
        adjacency: VariableOption | null;
        metrics: VariableOption[];
    };
}

export interface EmbedUrlElement extends Ordered, Groupable {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    url?: string | null;
    urls?: string[];
    iframe?: boolean;
}

export interface InputDataElement extends Ordered {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    inputData: InputBaseState;
    schemaOptions: SchemaOptions;
}

export interface MergeDataElement extends MergeState, Ordered, Groupable {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
}

export interface AggregateTableElement
    extends AggregateState,
        Ordered,
        Groupable {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
}

export interface ManageTableElement extends Ordered, Groupable {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    dataScope: DataScopeOption | null;
    tableOption: TableOption | null;
    operationOption: ManageTableOperationOption | null;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
}

export interface ShapeStyle {
    fillColor?: string | null;
    borderColor?: string | null;
    borderWidth?: number;
    borderRadius?: number;
    borderShadow?: boolean;
}

export interface BackgroundShapeStyle {
    fillColor?: string | null;
    borderColor?: string | null;
    borderWidth?: number;
    borderRadius?: number;
    borderShadow?: boolean;
    angle?: number; //deprecated;
    nodeTranslation?: NodeTranslation;
}

export enum ShapeType {
    Arrow = 0,
    Circle = 1,
    Line = 2,
    Parallelogram = 3,
    Square = 4,
    Triangle = 5,
}

export function getDefaultShapeColor(shapeType: ShapeType): string {
    return mainStyle.getPropertyValue("--secondary-content-color");
}

export interface NodeTranslation {
    desktop: {
        x: number;
        y: number;
        angle: number;
    };
    mobile: {
        x: number;
        y: number;
        angle: number;
    };
}

const defaultNodeTranslation = {
    desktop: {
        x: 0,
        y: 0,
        angle: 0,
    },
    mobile: {
        x: 0,
        y: 0,
        angle: 0,
    },
};

export function getNodeTranslation(element: ShapeElement): NodeTranslation {
    if (element.nodeTranslation != null) return element.nodeTranslation;
    let result = defaultNodeTranslation;
    if ("angle" in element) {
        result.desktop.angle = element.angle ?? 0;
        result.mobile.angle = element.angle ?? 0;
    }
    return result;
}

export function getNodeTranslationFromBackground(
    background: CanvasBackground
): NodeTranslation {
    if (background.style_options?.nodeTranslation != null)
        return background.style_options.nodeTranslation;
    let result = defaultNodeTranslation;
    result.desktop.angle = background.style_options?.angle ?? 0;
    result.mobile.angle = background.style_options?.angle ?? 0;
    return result;
}

export function getNodeTranslationString(
    nodeTranslation: NodeTranslation,
    scale: number,
    viewMode: CanvasViewMode
): string {
    let nodeTranslationScreen = nodeTranslation[viewMode];
    return `translate(${nodeTranslationScreen.x * scale}px, ${
        nodeTranslationScreen.y * scale
    }px) rotate(${nodeTranslationScreen.angle}deg)`;
}

export function getBoundingBoxOfRotatedRectangle(
    rectangle: { left: number; top: number; right: number; bottom: number },
    angleDeg: number
): { left: number; top: number; right: number; bottom: number } {
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;

    const angleRad = angleDeg * (Math.PI / 180);

    const points = [
        { x: rectangle.left, y: rectangle.top },
        { x: rectangle.left, y: rectangle.bottom },
        { x: rectangle.right, y: rectangle.top },
        { x: rectangle.right, y: rectangle.bottom },
    ];

    // Rotation center
    const x0 = (rectangle.left + rectangle.right) / 2;
    const y0 = (rectangle.top + rectangle.bottom) / 2;

    for (let { x, y } of points) {
        let x1 =
            x0 + (x - x0) * Math.cos(angleRad) + (y - y0) * Math.sin(angleRad);
        minX = Math.min(minX, x1);
        maxX = Math.max(maxX, x1);
        let y1 =
            y0 - (x - x0) * Math.sin(angleRad) + (y - y0) * Math.cos(angleRad);
        minY = Math.min(minY, y1);
        maxY = Math.max(maxY, y1);
    }

    return {
        left: minX,
        right: maxX,
        top: minY,
        bottom: maxY,
    };
}

export interface ShapeElement extends Ordered, Groupable {
    nodePosition: NodePosition;
    nodeSize: NodeSize;
    nodeTranslation?: NodeTranslation;
    width: number;
    height: number;
    shapeType: ShapeType;
    angle?: number; // DEPRECATED
    shapeStyle?: ShapeStyle;
}

export interface SelectionArea {
    top: number;
    left: number;
    bottom: number;
    right: number;
    gridId: string;
}

export function isTextQuestion(question: Question): question is TextQuestion {
    return question.type === QuestionType.Text;
}

export function isTemplateSheetQuestion(
    question: Question
): question is TemplateSheetQuestion {
    return question.type === QuestionType.TemplateSheet;
}

export function isDateQuestion(question: Question): question is DateQuestion {
    return question.type === QuestionType.Date;
}

export function isMultipleChoiceQuestion(
    question: Question
): question is MultipleChoiceQuestion {
    return question.type === QuestionType.MultipleChoice;
}

export function isDropdownQuestion(
    question: Question
): question is DropdownQuestion {
    return question.type === QuestionType.Dropdown;
}

export function toCanvasSharedBoxElement(
    box: CanvasSharedBoxElementResponse
): CanvasSharedBoxElement {
    return {
        id: box.id,
        box: JSON.parse(box.box),
        page_id: box.page_id,
        page_title: box.page_title,
        canvas_title: box.canvas_title,
        canvas_id: box.canvas_id,
    };
}

export function getTargetValue(
    node: CanvasSharedNode | CanvasElementOutput,
    format?: ColumnFormat | null,
    checkTextBoxSingleOutput?: boolean
): NodeValue | undefined {
    checkTextBoxSingleOutput = checkTextBoxSingleOutput ?? false;
    const sharedNode = node as CanvasSharedNode;
    if (isDropdownSelector(sharedNode) || isBarcodeReader(sharedNode)) {
        return node.value;
    }
    if (
        checkTextBoxSingleOutput &&
        isTextBox(sharedNode) &&
        !checkTextBoxHasSingleOutput(sharedNode)
    ) {
        if (sharedNode.rawMetric != null) {
            let text = convertFromRaw(sharedNode.rawMetric)
                .getPlainText()
                .trim();
            if (StringUtils.isNumber(text)) return Number(text);
            else return text || NaN;
        }
        return NaN;
    } else return getOutputTargetValue(node, format);
}

export function getOutputTargetValue(
    node: {
        metric: string;
        value: NodeValue | undefined;
    },
    format?: ColumnFormat | null
) {
    if (
        !node.metric.startsWith("=") &&
        (node.value == null || Number.isNaN(node.value)) &&
        !isNumberFormat(format) &&
        !isDateFormat(format)
    ) {
        return node.metric || NaN;
    } else {
        if (typeof node.value === "string") return node.value || NaN;
        return node.value;
    }
}

export function castedTargetValue(
    node: CanvasSharedNode,
    type: Type,
    format?: ColumnFormat | null
): NodeValue | undefined {
    let value = getTargetValue(node, format, true);
    if (isTextBox(node) && !checkTextBoxHasSingleOutput(node)) return value;

    if (isBarcodeReader(node)) {
        return value;
    }
    if (type === Type.Float || type === Type.Int) {
        if (typeof value === "string") {
            value = Number(value || NaN);
        } else if (typeof value != "number") value = NaN;
    }
    if (type === Type.Int && typeof value === "number") {
        value = Math.round(value);
    }
    if (
        type === Type.Str ||
        (!isDateFormat(format) && type === Type.Datetime)
    ) {
        value = getPrettyPrintFormatValue(node, format);
        if (!value) value = NaN;
    }
    return value;
}

export function getNodeValueType(node: {
    metric: string;
    value: NodeValue;
}): string {
    if (node.metric?.startsWith("=") || !Number.isNaN(node.value)) {
        if (StringUtils.isNumber(node.value as number | string))
            return "number";
        return "string";
    } else {
        if (StringUtils.isNumber(node.metric)) return "number";
        return "string";
    }
}

export function outerNodeId(id: number): string {
    return StringUtils.numToAlphabet(id);
}

export function outerSpreadSheetId(
    index: string | undefined,
    col: number,
    row: number
): string {
    let outerId = StringUtils.numToAlphabet(col).concat(String(row));
    if (index != null) {
        return index.concat("_").concat(outerId);
    }
    return outerId;
}

export function questionToAnswer(question: Question) {
    let answer = "";
    if (isTextQuestion(question)) {
        answer = question.answer;
    }
    if (isDateQuestion(question)) {
        let dateOnly = question.dateOnly ?? false;
        answer = question.currentDate
            ? !dateOnly
                ? new Date().toLocaleString()
                : new Date().toLocaleDateString()
            : !dateOnly
            ? moment.utc(question.answer).toLocaleString()
            : moment.utc(question.answer).toDate().toLocaleDateString();
    }
    if (isMultipleChoiceQuestion(question)) {
        let selected = question.options
            .filter((option) => option.selected)
            .map((option) => option.option);
        answer = selected.join(" ");
    }
    if (isDropdownQuestion(question)) {
        answer = String(question.tableValue ?? "");
    }
    if (isTemplateSheetQuestion(question)) {
        answer = String(question.sheetOption?.label ?? "");
    }
    return answer;
}

export function createTemplateNodesCanvas(
    questionnaireElement: QuestionnaireElement,
    optionQuestion: TemplateSheetQuestion,
    _pageId: number
): Canvas["canvas"] {
    let questions = questionnaireElement.questions.filter(
        (question) => !isTemplateSheetQuestion(question)
    );
    let newCanvas: Canvas["canvas"] = {
        canvasUpdateTimeState: new Date().getTime() / 1000,
        canvasTreeState: {},
        gridsState: {},
        backendTablesState: {},
        dashboardsState: {},
        questionnaireElementsState: {},
        //    inputDataElementsState: {},
        mergeDataElementsState: {},
        canvasAutoIncrementId: 0,
        buttonsState: {},
    };
    if (optionQuestion == null) return newCanvas;

    let canvasTreeState: { [key: number]: CanvasNode } = {};
    let countOfNewItems = 0;
    for (let index of Object.keys(optionQuestion.nodeOptions)) {
        let option = optionQuestion.nodeOptions[Number(index)];
        if (option.value === -1) {
            let node: CanvasElement = {
                name: "",
                unit: "",
                id: option.value - countOfNewItems,
                outerId: "",
                nodePosition: {
                    desktop: {
                        x: DefaultCreatedNodePosition,
                        y: DefaultCreatedNodePosition * Number(index),
                    },
                    mobile: {
                        x: DefaultCreatedNodePosition,
                        y: DefaultCreatedNodePosition * Number(index),
                    },
                },
                nodeIsHidden: {
                    desktop: false,
                    mobile: false,
                },
                parentIds: [],
                childrenSharedIds: [],
                childrenIds: [],
                metric: questionToAnswer(questions[Number(index)]),
                value: NaN,
                popups: [],
                links: [],
                additionalOutputs: [],
            };
            countOfNewItems += 1;
            canvasTreeState[node.id] = node;
        } else {
            let node: CanvasNode = {
                id: option.value,
                outerId: option.label,
                nodePosition: {
                    desktop: {
                        x: DefaultCreatedNodePosition,
                        y: DefaultCreatedNodePosition * Number(index),
                    },
                    mobile: {
                        x: DefaultCreatedNodePosition,
                        y: DefaultCreatedNodePosition * Number(index),
                    },
                },
                nodeIsHidden: {
                    desktop: false,
                    mobile: false,
                },
                parentIds: [],
                childrenSharedIds: [],
                childrenIds: [],
                metric: questionToAnswer(questions[Number(index)]),
                value: NaN,
            };
            canvasTreeState[node.id] = node;
        }
    }

    newCanvas.canvasTreeState = canvasTreeState;
    return newCanvas;
}

export function parseBackground(
    canvasBackground: CanvasBackgroundResponse
): CanvasBackground {
    let styleOptions: BackgroundShapeStyle | undefined;
    try {
        if (canvasBackground.style_options != null)
            styleOptions = JSON.parse(canvasBackground.style_options);
    } catch (error) {}
    return {
        ...canvasBackground,
        style_options: styleOptions,
    };
}

export function serializeBackground(
    canvasBackground: CanvasBackground
): CanvasBackgroundResponse {
    let styleOptions: string | undefined = undefined;
    try {
        if (canvasBackground.style_options != null)
            styleOptions = JSON.stringify(canvasBackground.style_options);
    } catch (error) {}
    return {
        ...canvasBackground,
        style_options: styleOptions,
    };
}

export function toCanvas(
    canvasResponse: CanvasResponse,
    onlyHeaders = false
): Canvas {
    return {
        live_streaming: canvasResponse.live_streaming,
        id: canvasResponse.id,
        delegate_id: canvasResponse.delegate_id,
        title: canvasResponse.title,
        canvas: !onlyHeaders ? JSON.parse(canvasResponse.canvas) : undefined,
        page_id: canvasResponse.page_id,
        page_title: canvasResponse.page_title,
        backgrounds: !onlyHeaders
            ? canvasResponse.backgrounds.map((background) =>
                  parseBackground(background)
              )
            : [],
        has_new_notifications: canvasResponse.has_new_notifications,
        thumbnail: canvasResponse.thumbnail,
        hide_in_slideshow: canvasResponse.hide_in_slideshow,
        layer_for_canvas_id: canvasResponse.layer_for_canvas_id,
        placementToolbar: canvasResponse.placementToolbar,
    };
}

export function toTemplate(
    templateResponse: TemplateResponse,
    onlyHeaders = false
): SlideTemplate {
    return {
        id: templateResponse.id,
        collection_id: templateResponse.collection_id,
        thumbnail: templateResponse.thumbnail,
        title: templateResponse.title,
        canvas: !onlyHeaders ? JSON.parse(templateResponse.canvas) : undefined,
        backgrounds: !onlyHeaders
            ? templateResponse.backgrounds.map((background) =>
                  parseBackground(background)
              )
            : [],
    };
}

export function calculateExpression(
    node: {
        value: NodeValue;
        additionalOutputs?: {
            value: NodeValue;
        }[];
    },
    expression: StatusExpression | NotificationExpression | PrintExpression
): boolean {
    let result = true;
    for (let subExpression of expression.subexpressions) {
        let compareValue = subExpression.value
            ? subExpression.isInput
                ? Number((subExpression.value as NodeLinkOption).target)
                : Number(subExpression.value)
            : NaN;
        let leftValue;
        if (subExpression.index == null || subExpression.index === 1) {
            leftValue = node.value;
        } else {
            if (
                node.additionalOutputs != null &&
                node.additionalOutputs.length > subExpression.index - 2
            ) {
                leftValue =
                    node.additionalOutputs[subExpression.index - 2].value;
            } else {
                return false;
            }
        }
        if (!Number.isNaN(compareValue)) {
            switch (subExpression.operation) {
                case ">":
                    result = result && leftValue > compareValue;
                    break;
                case "<":
                    result = result && leftValue < compareValue;
                    break;
                case "=":
                    result = result && leftValue === compareValue;
                    break;
                case "!=":
                    result = result && leftValue !== compareValue;
                    break;
                default:
                    result = false;
                    break;
            }
            if (!result) return result;
        } else {
            return false;
        }
    }
    return result;
}

export function getStatusColor(
    node: CanvasElement | CanvasTextBox | CanvasProgressElement,
    mode: StatusColorType,
    outputIndex: number | null = null
): {
    color: string;
    borderWidth: number;
} {
    if (mode === StatusColorType.Fill && node.statusFill)
        if (node.statusColor != null)
            return {
                color: node.statusColor.value,
                borderWidth: node.statusBorderWidth ?? 1,
            };
    if (mode === StatusColorType.Text && node.statusFont)
        if (node.statusColor != null)
            return {
                color: node.statusColor.value,
                borderWidth: node.statusBorderWidth ?? 1,
            };
    if (mode === StatusColorType.Border && !node.statusFont && !node.statusFill)
        if (node.statusColor != null)
            return {
                color: node.statusColor.value,
                borderWidth: node.statusBorderWidth ?? 1,
            };
    if (node.value != null && node.statusExpressions != null) {
        let statusExpressions = node.statusExpressions;
        if (mode === StatusColorType.Border)
            statusExpressions = statusExpressions.filter(
                (expression) => !expression.fill && !expression.font
            );
        if (mode === StatusColorType.Fill)
            statusExpressions = statusExpressions.filter(
                (expression) => expression.fill
            );
        if (mode === StatusColorType.Text)
            statusExpressions = statusExpressions.filter(
                (expression) => expression.font
            );
        statusExpressions = statusExpressions.filter((expression) => {
            if (outputIndex == null) {
                return expression.outputIndex == null;
            } else return expression.outputIndex === outputIndex;
        });
        for (let statusExpression of statusExpressions) {
            if (calculateExpression(node, statusExpression))
                return {
                    color: statusExpression.color.value,
                    borderWidth: statusExpression.borderWidth ?? 1,
                };
        }
    }
    return {
        color: "transparent",
        borderWidth: 1,
    };
}

export function getPrintText(
    node: CanvasTextBox,
    outputIndex: number | null = null
): string | null {
    if (node.printExpressions != null) {
        let printExpressions = node.printExpressions;

        printExpressions = printExpressions.filter(
            (expression) => expression.outputIndex === outputIndex
        );
        for (let printExpression of printExpressions) {
            if (calculateExpression(node, printExpression))
                return printExpression.text;
        }
    }
    return null;
}

export function getValueFillColor(
    value: string | number,
    index: number,
    statusExpressions: StatusBarExpression[]
): string | null {
    statusExpressions = statusExpressions.filter(
        (expression) => expression.variable?.value === index
    );
    for (let statusExpression of statusExpressions) {
        if (calculateExpression({ value: value }, statusExpression))
            return statusExpression.color.value;
    }
    return null;
}

export function getSharedIdsListFromExpression(
    expression: StatusExpression | NotificationExpression | PrintExpression
): number[] {
    let newSharedBoxIds: number[] = [];
    for (let subexpression of expression.subexpressions) {
        if (
            subexpression.isInput &&
            subexpression.value != null &&
            (subexpression.value as NodeLinkOption).isCloneInput
        ) {
            newSharedBoxIds.push((subexpression.value as NodeLinkOption).value);
        }
    }
    return newSharedBoxIds;
}

export function addBackwardCompatibilityForPositionAndSize(item: any) {
    if (
        !item.nodePosition ||
        !item.nodePosition?.mobile ||
        !item.nodePosition?.desktop
    ) {
        if (typeof item.x === "number" && typeof item.y === "number") {
            item.nodePosition = {
                desktop: { x: item.x, y: item.y },
                mobile: { x: item.x, y: item.y },
            };
        } else {
            item.nodePosition = DefaultNodePosition;
        }
    }

    if (!item.nodeSize || !item.nodeSize?.mobile || !item.nodeSize?.desktop) {
        if (typeof item.width === "number" && typeof item.height === "number") {
            item.nodeSize = {
                desktop: { width: item.width, height: item.height },
                mobile: { width: item.width, height: item.height },
            };
        } else {
            if (isTextBox(item)) {
                item.nodeSize = DefaultTextBoxSize;
            } else {
                item.nodeSize = DefaultNodeSize;
            }
        }
    }

    if (item.containerSize && !item.containerSize.desktop) {
        if (
            typeof item.containerSize.width === "number" &&
            typeof item.containerSize.height === "number"
        ) {
            item.containerSize = {
                desktop: {
                    width: item.containerSize.width,
                    height: item.containerSize.height,
                },
                mobile: {
                    width: item.containerSize.width,
                    height: item.containerSize.height,
                },
            };
        } else {
            item.containerSize = DefaultNodeSize;
        }
    }

    if (item.shapeOptions && !item.shapeOptions.desktop) {
        if (
            typeof item.shapeOptions.scaleX === "number" &&
            typeof item.shapeOptions.scaleY === "number"
        ) {
            item.shapeOptions = {
                desktop: {
                    scaleX: item.shapeOptions.scaleX,
                    scaleY: item.shapeOptions.scaleY,
                },
                mobile: {
                    scaleX: item.shapeOptions.scaleX,
                    scaleY: item.shapeOptions.scaleY,
                },
            };
        }
    }

    if (!item.nodeIsHidden) {
        item.nodeIsHidden = {
            mobile: false,
            desktop: false,
        };
    }

    if (!item.is_hidden || !item.mobile_is_hidden) {
        item.is_hidden = false;
        item.mobile_is_hidden = false;
    }

    return item;
}

export function addBackwardCompatibilityForAllCanvasItems(
    canvas: Canvas["canvas"]
): Canvas["canvas"] {
    canvas = _.cloneDeep(canvas); // deep copy
    const allItems = [
        canvas.gridsState ?? {},
        canvas.mapElementsState ?? {},
        canvas.dashboardsState ?? {},
        canvas.buttonsState ?? {},
        canvas.backendTablesState ?? {},
        canvas.graphElementsState ?? {},
        canvas.shapeElementsState ?? {},
        canvas.questionnaireElementsState ?? {},
        canvas.embedUrlElementsState ?? {},
        canvas.mergeDataElementsState ?? {},
        canvas.manageTableElementsState ?? {},
        canvas.aggregateTableElementsState ?? {},
    ];
    const canvasNodes = canvas.canvasTreeState ?? {};

    for (let items of allItems) {
        Object.keys(items ?? {}).forEach((item) => {
            items[item] = addBackwardCompatibilityForPositionAndSize(
                items[item]
            );
        });
    }

    Object.keys(canvasNodes).forEach((key) => {
        if (
            (!isBox(canvasNodes[key]) ||
                (isBox(canvasNodes[key]) &&
                    (canvasNodes[key] as CanvasElement).gridId == null)) &&
            !isSimpleSpreadSheetInput(canvasNodes[key])
        ) {
            canvasNodes[key] = addBackwardCompatibilityForPositionAndSize(
                canvasNodes[key]
            );
        }
    });

    return canvas;
}

export function backwardCompatibilityForSlideHeight(
    slideHeight: number | undefined | SlideHeight
): SlideHeight {
    if (slideHeight == null) {
        return {
            desktop: defaultSlideHeight,
            mobile: defaultMobileSlideHeight,
        };
    }
    if (typeof slideHeight === "number") {
        return {
            desktop: slideHeight,
            mobile: defaultMobileSlideHeight,
        };
    }
    return slideHeight;
}
