import React from "react";
import QuestionnaireView from "../QuestionnaireView";
import elements from "common/CanvasElements";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import ColorOptionsPopup from "../ColorOptionsPopup";
import {
    ColorOptions,
    DefaultCreatedNodePosition,
    InnerCanvasChanges,
    ItemMetadata,
} from "common/Canvas";
import HtmlElementProps from "./HtmlElementProps";
import cx from "classnames";
import moveableStyles from "./Moveable.module.css";
import {
    makeMoveable,
    DraggableProps,
    ResizableProps,
    RotatableProps,
    Rotatable,
    Draggable as RMDraggable,
    Resizable as RMResizable,
    OnDrag,
} from "react-moveable";
import { getTransformList } from "common/utilities/parseTransformString";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../Snap";

interface InnerProps extends HtmlElementProps {
    questionnaireId: string;
    rootDataTestId: string;
    selectedMetadata?: ItemMetadata[];
}

interface InnerState {
    showColorOptions: ColorOptions | undefined;
}
const typeName = "questionnaireElementsState";

const Moveable = makeMoveable<DraggableProps & ResizableProps & RotatableProps>(
    [RMDraggable, RMResizable, Rotatable]
);

const QuestionnaireElementsWrapper = observer(
    class QuestionnaireElementWrapper extends CanvasInteractionComponent<
        InnerProps,
        InnerState
    > {
        drag: boolean;
        private moveableRef = React.createRef<any>();
        private innerRef = React.createRef<HTMLDivElement>();
        dragRef = React.createRef<HTMLElement>();
        constructor(props: InnerProps) {
            super(props);
            this.drag = false;
            this.state = {
                showColorOptions: undefined,
            };
        }

        componentDidMount(): void {
            let questionnaire = this.props.canvasTreeStore.questionnaireElementsState.get(
                this.props.questionnaireId
            )!;
            let innerRef = this.innerRef.current;
            if (innerRef != null) {
                innerRef.setAttribute("type", typeName);
                if (questionnaire.groupId != null)
                    innerRef.setAttribute("groupId", questionnaire.groupId);
                else {
                    innerRef.removeAttribute("groupId");
                }
                innerRef.setAttribute("id", String(this.props.questionnaireId));
                innerRef.setAttribute(
                    "data-test-id",
                    this.props.rootDataTestId
                );
            }
        }

        componentDidUpdate(prev: InnerProps) {
            if (prev.selectedMetadata !== this.props.selectedMetadata) {
                let questionnaire = this.props.canvasTreeStore.questionnaireElementsState.get(
                    this.props.questionnaireId
                )!;
                let innerRef = this.innerRef.current;
                if (innerRef != null) {
                    if (questionnaire.groupId != null)
                        innerRef.setAttribute("groupId", questionnaire.groupId);
                    else {
                        innerRef.removeAttribute("groupId");
                    }
                }
            }
            this.moveableRef.current?.updateRect();
        }

        render() {
            const {
                canvasViewMode,
                mobileViewWasEdited,
            } = this.props.canvasTreeStore;
            let questionnaireElement = this.props.canvasTreeStore.questionnaireElementsState.get(
                this.props.questionnaireId
            )!;

            let selected =
                this.props.selectedMetadata?.length === 1 &&
                this.props.selectedMetadata?.[0]?.id ===
                    this.props.questionnaireId;

            if (!questionnaireElement.isDone && this.props.live) return null;

            let questionnaireSize = {
                height:
                    questionnaireElement.nodeSize[canvasViewMode].height *
                    this.props.scale,
                width:
                    questionnaireElement.nodeSize[canvasViewMode].width *
                    this.props.scale,
            };

            let questionnairePosition = {
                x:
                    questionnaireElement.nodePosition[canvasViewMode].x *
                    this.props.scale,
                y:
                    questionnaireElement.nodePosition[canvasViewMode].y *
                    this.props.scale,
            };

            return (
                <>
                    <div
                        onContextMenu={(evt) => {
                            this.props.onContextMenu(
                                evt,
                                {
                                    id: this.props.questionnaireId,
                                    type: typeName,
                                },
                                true
                            );
                        }}
                        style={{
                            position: "absolute",
                            left: questionnairePosition.x,
                            top: questionnairePosition.y,
                            width: questionnaireSize.width,
                            height: questionnaireSize.height,
                            zIndex: questionnaireElement.zIndex ?? 50,
                        }}
                        className="selectable-by-pointer"
                        ref={this.innerRef}
                    >
                        <QuestionnaireView
                            canvasTreeStore={this.props.canvasTreeStore}
                            onOpenColorOptions={(options) => {
                                this.setState({
                                    showColorOptions: options,
                                });
                            }}
                            onContextMenu={(evt) => {
                                this.props.onContextMenu(
                                    evt,
                                    {
                                        id: this.props.questionnaireId,
                                        type: typeName,
                                    },
                                    true
                                );
                            }}
                            frozen={!this.props.canWrite}
                            height={questionnaireSize.height}
                            live={this.props.live}
                            questionnaireElement={questionnaireElement}
                            questionnaireElementId={this.props.questionnaireId}
                            onChange={(questionnaireElement) => {
                                this.trackNewPerformance(
                                    elements.questionnaire
                                );
                                this.props.canvasTreeStore.updateQuestionnaireElementAction(
                                    this.props.questionnaireId,
                                    questionnaireElement
                                );
                            }}
                            onLinkCanvasSheets={(canvasIds: number[]) => {
                                this.props.canvasTreeStore.linkCanvasSheetsToQuestionnaireGridAction(
                                    this.props.questionnaireId,
                                    canvasIds
                                );
                            }}
                            onAddGridRow={() => {
                                this.props.canvasTreeStore.addQuestionnaireGridRowAction(
                                    this.props.questionnaireId
                                );
                            }}
                            onDeleteGrid={() => {
                                this.props.canvasTreeStore.deleteQuestionnaireGridAction(
                                    this.props.questionnaireId
                                );
                            }}
                            onDelete={() => {
                                this.trackNewPerformance(
                                    elements.questionnaire
                                );
                                this.props.showDeletePopup(() => {
                                    this.props.onClearEditing();
                                    this.props.canvasTreeStore.deleteQuestionnaireElementAction(
                                        this.props.questionnaireId
                                    );
                                });
                            }}
                            currentModuleId={this.props.currentModuleId}
                        />
                    </div>

                    {!this.props.live && this.props.canWrite && (
                        <Moveable
                            className={cx(
                                moveableStyles.moveable,
                                !selected && moveableStyles.moveableNotSelected
                            )}
                            checkInput={true}
                            key={this.props.questionnaireId}
                            ref={this.moveableRef}
                            draggable={!this.props.live || this.props.canWrite}
                            throttleDrag={0}
                            preventClickEventOnDrag={true}
                            onDrag={({
                                target,
                                beforeDelta,
                                beforeDist,
                                left,
                                top,
                                right,
                                bottom,
                                delta,
                                dist,
                                transform,
                                clientX,
                                clientY,
                            }: OnDrag) => {
                                target!.style.left = `${left}px`;
                                target!.style.top = `${top}px`;
                                this.drag = true;
                                let nearestPoints = this.props.onRebuildSnapLine(
                                    {
                                        x: left,
                                        y: top,
                                        width: questionnaireSize.width,
                                        height: questionnaireSize.height,
                                    },
                                    {
                                        type: typeName,
                                        id: this.props.questionnaireId,
                                        groupId: questionnaireElement.groupId,
                                    }
                                );
                                let newPosition = snapElementToPoints(
                                    target.clientWidth,
                                    target.clientHeight,
                                    nearestPoints
                                );
                                if (newPosition.x != null) {
                                    target!.style.left = `${newPosition.x}px`;
                                }
                                if (newPosition.y != null) {
                                    target!.style.top = `${newPosition.y}px`;
                                }
                                this.props.onUpdateSelectionBounds?.();
                            }}
                            onDragEnd={({ target }) => {
                                if (this.drag) {
                                    this.trackNewPerformance(
                                        elements.questionnaire
                                    );
                                    this.props.onDeleteSnapLine();
                                    let x =
                                        parseFloat(target.style.left) /
                                        this.props.scale;

                                    let y =
                                        parseFloat(target.style.top) /
                                        this.props.scale;

                                    let deltaX = x - questionnairePosition.x;
                                    let deltaY = y - questionnairePosition.y;

                                    const newNodePosition = {
                                        ...questionnaireElement.nodePosition,
                                        [canvasViewMode]: {
                                            x,
                                            y,
                                        },
                                    };

                                    if (!mobileViewWasEdited) {
                                        if (canvasViewMode === "desktop") {
                                            const desktopSlideWidth = this.props
                                                .canvasTreeStore.slideWidth[
                                                "desktop"
                                            ];
                                            const relativeLeftPoint =
                                                x / desktopSlideWidth;
                                            newNodePosition["mobile"] = {
                                                x:
                                                    DefaultCreatedNodePosition *
                                                    relativeLeftPoint,
                                                y,
                                            };
                                        } else
                                            this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                    }

                                    let changes: InnerCanvasChanges = {};
                                    this.props.canvasTreeStore.updateQuestionnaireElementAction(
                                        this.props.questionnaireId,
                                        {
                                            nodePosition: newNodePosition,
                                        },
                                        changes
                                    );
                                    this.props.canvasTreeStore.updateCanvasSizeAction(
                                        {
                                            x: x,
                                            y: y,
                                            ...questionnaireSize,
                                        }
                                    );
                                    this.props.onMoveGroupSelection(
                                        deltaX,
                                        deltaY,
                                        {
                                            id: this.props.questionnaireId,
                                            type: typeName,
                                            groupId:
                                                questionnaireElement.groupId,
                                        },
                                        false,
                                        changes
                                    );
                                    this.props.canvasTreeStore.saveChangesAction(
                                        changes,
                                        true,
                                        true,
                                        false,
                                        this.props.canvasTreeStore.backgroundsState.toJSON(),
                                        BackgroundMode.Update,
                                        false
                                    );
                                    this.drag = false;
                                }
                            }}
                            target={this.innerRef}
                            rotatable={false}
                            resizable={
                                (!this.props.live || this.props.canWrite) &&
                                selected
                            }
                            onResize={(e) => {
                                e.target.style.width = `${e.width}px`;
                                e.target.style.height = `${e.height}px`;
                                e.target.style.transform = e.afterTransform;
                            }}
                            onResizeEnd={({ target }) => {
                                this.trackNewPerformance(
                                    elements.questionnaire
                                );
                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;

                                let transfromOptions = getTransformList(
                                    target.style.transform
                                );
                                let translatePosition = (
                                    transfromOptions["translate"] ?? "0px, 0px"
                                )
                                    .split(",")
                                    .map(
                                        (item) =>
                                            parseFloat(item) / this.props.scale
                                    );

                                const x =
                                    parseFloat(target.style.left) /
                                        this.props.scale +
                                    translatePosition[0];
                                const y =
                                    parseFloat(target.style.top) /
                                        this.props.scale +
                                    translatePosition[1];

                                let newSize = {
                                    x,
                                    y,
                                    nodePosition: {
                                        ...questionnaireElement.nodePosition,
                                        [canvasViewMode]: {
                                            x,
                                            y,
                                        },
                                    },
                                    nodeSize: {
                                        ...questionnaireElement.nodeSize,
                                        [canvasViewMode]: {
                                            width:
                                                parseFloat(target.style.width) /
                                                this.props.scale,
                                            height:
                                                parseFloat(
                                                    target.style.height
                                                ) / this.props.scale,
                                        },
                                    },
                                };

                                target.style.transform = "none";

                                if (!mobileViewWasEdited) {
                                    this.props.canvasTreeStore.separateMobileAndDesktopViewPositioning();
                                }

                                this.props.canvasTreeStore.updateQuestionnaireElementAction(
                                    this.props.questionnaireId,
                                    {
                                        ...newSize,
                                    }
                                );

                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        ...newSize.nodePosition[canvasViewMode],
                                        ...newSize.nodeSize[canvasViewMode],
                                    }
                                );
                                this.props.onResize();
                            }}
                        ></Moveable>
                    )}

                    {this.state.showColorOptions != null && (
                        <ColorOptionsPopup
                            options={this.state.showColorOptions}
                            onClose={(apply, options) => {
                                if (apply) {
                                    this.props.canvasTreeStore.updateQuestionnaireElementAction(
                                        this.props.questionnaireId,
                                        {
                                            colorOptions: options,
                                        }
                                    );
                                }
                                this.setState({
                                    showColorOptions: undefined,
                                });
                            }}
                        />
                    )}
                </>
            );
        }
    }
);

export default observer(function QuestionnaireElements(
    props: HtmlElementProps
) {
    let questionnareUIs: JSX.Element[] = [];
    for (let questionnaireId of props.canvasTreeStore.questionnaireElementsState.keys())
        questionnareUIs.push(
            <QuestionnaireElementsWrapper
                rootDataTestId={`questionnaireElement-${
                    questionnareUIs.length + 1
                }`}
                key={questionnaireId}
                questionnaireId={questionnaireId}
                {...props}
            />
        );
    return <>{questionnareUIs}</>;
});
