import React from "react";
import Select, { createFilter } from "react-select";
import { DataScopeOption } from "common/DataScopes";
import { observer } from "mobx-react";
import Variables, { VariableOption } from "common/Variables";
import { Button } from "react-bootstrap";
import { MapVariableOption } from "common/Canvas";
import { defaultMapColorOptions } from "../../Constants";
import remoteModuleId from "common/remoteModuleId";

import styles from "./RevampedMapVariablesSelectorView.module.css";
import { getCustomSelectStyleLight } from "common/SelectStyles";

interface Props {
    dataScope?: DataScopeOption;
    variables: (MapVariableOption | null)[];
    onChange: (variableOptions: (MapVariableOption | null)[]) => void;
    currentModuleId?: number;
}

const MapVariablesSelectorView = observer(function MapVariablesSelectorView(
    props: Props
) {
    let values = props.variables.map((item) => item?.variable.value ?? null);
    let variableOptions = Variables(
        props.dataScope?.value,
        props.currentModuleId ?? remoteModuleId
    ).variableOptions.filter((item) => !values.includes(item.value));

    return (
        <div>
            {props.variables.map((value, index) => (
                <React.Fragment key={index}>
                    <div
                        style={{ alignItems: "center", marginBottom: "5px" }}
                        className="my-row"
                        onKeyDown={(evt) => {
                            evt.stopPropagation();
                        }}
                        onMouseDown={(evt: any) => {
                            evt.stopPropagation();
                        }}
                    >
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder="Select variable"
                            styles={{
                                ...getCustomSelectStyleLight(
                                    14,
                                    false
                                ),
                                control: (provided) => ({
                                    ...provided,
                                    borderRadius: 4,
                                }),
                                container: (base) => ({
                                    ...base,
                                    flexGrow: 1,
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: 'none',
                                }),
                            }}
                            options={variableOptions}
                            onChange={(newValue) => {
                                let variables = Array.from(props.variables);
                                if (variables[index] != null) {
                                    variables[
                                        index
                                    ]!.variable = newValue as VariableOption;
                                } else {
                                    variables[index] = {
                                        variable: newValue as VariableOption,
                                        options: { ...defaultMapColorOptions },
                                    };
                                }
                                props.onChange(variables);
                            }}
                            value={value?.variable ?? null}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25: "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <div
                            className="flex-simple-column"
                            style={{ marginLeft: 5 }}
                        >
                            <Button
                                className={styles.button}
                                title="Add variable"
                                style={{
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let variables = Array.from(props.variables);
                                    variables.push(null);
                                    props.onChange(variables);
                                }}
                            >
                                {"\uFF0B" /* plus */}
                            </Button>

                            <Button
                                className={styles.button}
                                title="Remove variable"
                                style={{
                                    width: "19px",
                                    height: "19px",
                                }}
                                onClick={() => {
                                    let variables = Array.from(props.variables);
                                    variables.splice(index, 1);
                                    if (variables.length === 0) {
                                        variables.push(null);
                                    }
                                    props.onChange(variables);
                                }}
                            >
                                {"\uFF0D" /* minus */}
                            </Button>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
});
export default MapVariablesSelectorView;
