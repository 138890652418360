import React, { Component } from "react";
import { Button } from "react-bootstrap";
import YouTube from "react-youtube";
import { EmbedUrlElement, ColorOptions } from "common/Canvas";
import InputAutosave from "common/InputAutosave";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import CanvasPreventPropagationButton from "./CanvasPreventPropagationButton";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import { processUrlId } from "common/processUrl";
import UpButtonBig from "icons/UpButtonBig.svg";
import DownButtonBig from "icons/DownButtonBig.svg";
import StringUtils from "common/utilities/StringUtils";
import { openDelayedNodeMenuAfterTouch } from "common/utilities/UIResponsiveManager";

const DragAreaWrapper: React.FunctionComponent<{
    live: boolean;
}> = (props) => {
    if (props.live) return props.children as React.ReactElement;
    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                cursor: "pointer",
                position: "relative",
            }}
        >
            <div
                style={{
                    background: "#CCCCCC",
                    opacity: 0.8,
                    fontFamily: "Roboto",
                    fontSize: "15px",
                    color: "#39F",
                    position: "absolute",
                    height: "40px",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                }}
            >
                DRAG USING THIS AREA
            </div>
            {props.children}
        </div>
    );
};

interface Props {
    onOpenColorOptions: (colorOptions: ColorOptions) => void;
    onContextMenu: (evt: React.MouseEvent) => void;
    element: EmbedUrlElement;
    elementId: string;
    height: number;
    live: boolean;
    frozen: boolean;
    scale: number;
    sharedPolicy: CanvasSharedPolicy;
    onChange: (element: Partial<EmbedUrlElement>) => void;
    onDelete: () => void;
    currentModuleId: number | undefined;
    onTrackNewPerformance: (element: string) => void;
}

interface State {
    editedUrls: string[] | null;
    currentIndex: number;
    autoplay: boolean;
    hovered: boolean;
}

export default class EmbedUrlElementView extends Component<Props, State> {
    private rootRef: React.RefObject<HTMLDivElement>;
    private videoRef: React.RefObject<HTMLVideoElement>;
    private youTubeRef: React.RefObject<YouTube>;

    constructor(props: Props) {
        super(props);
        this.state = {
            editedUrls: null,
            currentIndex: 0,
            autoplay: false,
            hovered: false,
        };
        this.rootRef = React.createRef();
        this.videoRef = React.createRef();
        this.youTubeRef = React.createRef();
        this.finishEditing = this.finishEditing.bind(this);
    }

    public componentDidUpdate(prevProps: Props, prevState: State): void {
        if (this.props.live !== prevProps.live) {
            if (this.videoRef.current != null) {
                this.videoRef.current.pause();
                this.videoRef.current.currentTime = 0;
            }
            if (this.youTubeRef.current != null) {
                this.youTubeRef.current.getInternalPlayer().stopVideo();
            }
            this.setState({
                currentIndex: 0,
                autoplay: false,
            });
        }
        if (
            this.props.live &&
            this.state.autoplay &&
            this.state.currentIndex !== prevState.currentIndex
        ) {
            // <video> tag only supports autoplay if the video is muted,
            // so we're using this instead
            if (this.videoRef.current != null) {
                this.videoRef.current.play();
            }
        }
    }

    private finishEditing(): void {
        if (
            this.state.editedUrls != null &&
            this.state.editedUrls.length !== 0
        ) {
            this.props.onChange({
                url: undefined,
                urls: this.state.editedUrls,
            });
            this.setState({
                editedUrls: null,
            });
        }
    }

    private renderContent(): JSX.Element {
        let url =
            this.props.element.urls != null
                ? this.props.element.urls[this.state.currentIndex]
                : this.props.element.url;
        if (url == null || this.state.editedUrls != null) {
            let editedUrls = this.state.editedUrls ?? [""];
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {editedUrls.map((url, index) => (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        >
                            <div
                                className="flex-simple-column"
                                style={{ flex: 1 }}
                            >
                                <input
                                    placeholder="URL"
                                    disabled={this.props.live}
                                    style={{
                                        fontSize: "14px",
                                        border: "none",
                                        resize: "none",
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        fontFamily: "Roboto",
                                        color:
                                            dataScienceElementsStyle.secondaryTextColor,
                                    }}
                                    value={url}
                                    onMouseDown={(evt) => {
                                        evt.stopPropagation();
                                    }}
                                    onChange={(evt) => {
                                        // We have to read name before setState,
                                        // because evt.target.value might be undefined
                                        // during setState
                                        let value = evt.currentTarget.value;
                                        this.setState((state) => {
                                            let newEditedUrls = Array.from(
                                                state.editedUrls ?? [""]
                                            );
                                            newEditedUrls[index] = value;
                                            return {
                                                editedUrls: newEditedUrls,
                                            };
                                        });
                                    }}
                                    onKeyDown={(evt) => {
                                        evt.stopPropagation();
                                        if (evt.key === "Enter") {
                                            evt.preventDefault();
                                            this.finishEditing();
                                        }
                                    }}
                                />
                                <div
                                    style={{
                                        height: "2px",
                                        backgroundColor:
                                            dataScienceElementsStyle.secondaryTextColor,
                                    }}
                                />
                            </div>
                            <div
                                className="flex-simple-column"
                                style={{ marginLeft: 5 }}
                            >
                                <Button
                                    className={"btn-small-questionnaire"}
                                    title={"Add variable"}
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        this.setState((state) => {
                                            let newEditedUrls = Array.from(
                                                state.editedUrls ?? [""]
                                            );
                                            newEditedUrls.splice(
                                                index + 1,
                                                0,
                                                ""
                                            );
                                            return {
                                                editedUrls: newEditedUrls,
                                            };
                                        });
                                    }}
                                >
                                    {"\uFF0B" /* plus */}
                                </Button>
                                <Button
                                    className={"btn-small-questionnaire"}
                                    title={"Remove variable"}
                                    style={{
                                        width: "19px",
                                        height: "19px",
                                    }}
                                    onClick={() => {
                                        this.setState((state) => {
                                            let newEditedUrls = Array.from(
                                                state.editedUrls ?? [""]
                                            );
                                            if (newEditedUrls.length <= 1) {
                                                newEditedUrls = [""];
                                            } else {
                                                newEditedUrls.splice(index, 1);
                                            }
                                            return {
                                                editedUrls: newEditedUrls,
                                            };
                                        });
                                    }}
                                >
                                    {"\uFF0D" /* minus */}
                                </Button>
                            </div>
                            {index === 0 ? (
                                <Button
                                    className="btn btn-lg btn-primary my-primary"
                                    style={{
                                        marginLeft: "5px",
                                        width: "58px",
                                        height: "38px",
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                    onClick={() => {
                                        this.finishEditing();
                                    }}
                                >
                                    OK
                                </Button>
                            ) : (
                                <div
                                    style={{
                                        marginLeft: "5px",
                                        width: "58px",
                                        height: "38px",
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            );
        } else {
            if (this.props.element.iframe) {
                if (StringUtils.isHtml(url)) {
                    return (
                        <DragAreaWrapper live={this.props.live}>
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                                dangerouslySetInnerHTML={{ __html: url }}
                            ></div>
                        </DragAreaWrapper>
                    );
                }

                //Add Image Functionality
                if (StringUtils.isImageUrl(url)) {
                    return (
                        <DragAreaWrapper live={this.props.live}>
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                         >
                            <img alt="" title={this.props.elementId} width="100%" height="100%" src={url} />
                        </div>
                    </DragAreaWrapper>
                    );
                }

                let validUrl = url;
                if (!StringUtils.isValidHttpUrl(url))
                    validUrl = StringUtils.addSchemaToUrl(url);

                return (
                    <DragAreaWrapper live={this.props.live}>
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <iframe title={this.props.elementId} width="100%" height="100%" src={validUrl} />
                        </div>
                    </DragAreaWrapper>
                );
            }
            let urlString = processUrlId(url);
            if (urlString != null) {
                return (
                    <DragAreaWrapper live={this.props.live}>
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <YouTube
                                containerClassName="height-100"
                                ref={this.youTubeRef}
                                videoId={urlString}
                                id={`embed-url-element-${this.props.elementId}`}
                                onEnd={() => {
                                    this.setState((state, props) => {
                                        if (
                                            state.currentIndex + 1 <
                                            (props.element.urls?.length ?? 1)
                                        ) {
                                            return {
                                                currentIndex:
                                                    (state.currentIndex + 1) %
                                                    (props.element.urls
                                                        ?.length ?? 1),
                                                autoplay: true,
                                            };
                                        } else {
                                            return {
                                                currentIndex:
                                                    state.currentIndex,
                                                autoplay: state.autoplay,
                                            };
                                        }
                                    });
                                }}
                                opts={{
                                    width: "100%",
                                    height: "100%",
                                    playerVars: {
                                        autoplay: this.state.autoplay ? 1 : 0,
                                    },
                                }}
                            />
                        </div>
                    </DragAreaWrapper>
                );
            } else {
                return (
                    <DragAreaWrapper live={this.props.live}>
                        <video
                            ref={this.videoRef}
                            controls={true}
                            width="100%"
                            height="100%"
                            onEnded={() => {
                                this.setState((state, props) => {
                                    if (
                                        state.currentIndex + 1 <
                                        (props.element.urls?.length ?? 1)
                                    ) {
                                        return {
                                            currentIndex:
                                                (state.currentIndex + 1) %
                                                (props.element.urls?.length ??
                                                    1),
                                            autoplay: true,
                                        };
                                    } else {
                                        return {
                                            currentIndex: state.currentIndex,
                                            autoplay: state.autoplay,
                                        };
                                    }
                                });
                            }}
                        >
                            <source src={url} />
                        </video>
                    </DragAreaWrapper>
                );
            }
        }
    }

    

    public render(): JSX.Element {
                
        return (
            <div
                ref={this.rootRef}
                className="dashboard-rect-canvas dashboard-rect-canvas-focus"
                tabIndex={0}
                style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                    backgroundColor: dataScienceElementsStyle.contentColor,
                    borderRadius: 0,
                }}
                onContextMenu={this.props.onContextMenu}
                onMouseEnter={() => {
                    this.setState({ hovered: true });
                }}
                onMouseLeave={() => {
                    this.setState({ hovered: false });
                }}
                onTouchStart={() => {
                    openDelayedNodeMenuAfterTouch(() => {
                        this.setState({ hovered: true });
                    }, () => {
                        this.setState({ hovered: false });
                    });
                }}
            >
                {this.renderContent()}
                {!this.props.live && !this.props.frozen && (
                    <div
                        style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            zIndex: 1,
                        }}
                    >
                        <CanvasPreventPropagationButton>
                            <div
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    this.props.onDelete();
                                }}
                            >
                                <img
                                    alt=""
                                    src="/dist/img/insights/insights_remove.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </CanvasPreventPropagationButton>
                    </div>
                )}
                {this.state.hovered && !this.props.frozen && (
                    <div
                        style={{
                            position: "absolute",
                            top: this.props.live ? 0 : 40,
                            left: "50%",
                            zIndex: 1,
                            display: "flex",
                            opacity: 0.7,
                            backgroundColor: "var(--content-background-color)",
                            borderRadius: 5,
                            alignItems: "center",
                            transform: "translateX(-50%)",
                        }}
                        onKeyDown={(evt) => evt.stopPropagation()}
                    >
                        <CanvasPreventPropagationButton>
                            <div
                                className="unselectable"
                                onClick={() => {
                                    this.setState((state, props) => {
                                        let urlsLength =
                                            props.element.urls?.length ?? 1;
                                        let currentIndex =
                                            state.currentIndex - 1;
                                        if (currentIndex < 0) {
                                            currentIndex = urlsLength - 1;
                                        }
                                        return {
                                            currentIndex: currentIndex,
                                            autoplay: false,
                                        };
                                    });
                                }}
                                style={{
                                    transform: "rotate(-90deg)",
                                }}
                            >
                                <img
                                    alt=""
                                    src={UpButtonBig}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </CanvasPreventPropagationButton>
                        <InputAutosave
                            className="like-select"
                            style={{
                                padding: "1px",
                                height: "37px",
                                minHeight: "37px",
                                width: "25px",
                                textAlign: "center",
                            }}
                            value={(this.state.currentIndex + 1).toString()}
                            onSave={(newValue) => {
                                let value = Number(newValue);
                                if (
                                    !isNaN(value) &&
                                    value >= 1 &&
                                    value <=
                                        (this.props.element.urls?.length ?? 1)
                                ) {
                                    this.setState({
                                        currentIndex: value - 1,
                                    });
                                }
                            }}
                        />
                        <span className="regular-text">
                            / {this.props.element.urls?.length ?? 1}
                        </span>
                        <CanvasPreventPropagationButton>
                            <div
                                className="unselectable"
                                onClick={() => {
                                    this.setState((state, props) => {
                                        let urlsLength =
                                            props.element.urls?.length ?? 1;
                                        let currentIndex =
                                            state.currentIndex + 1;
                                        if (currentIndex >= urlsLength) {
                                            currentIndex = 0;
                                        }
                                        return {
                                            currentIndex: currentIndex,
                                            autoplay: false,
                                        };
                                    });
                                }}
                                style={{
                                    transform: "rotate(-90deg)",
                                    opacity: 0.7,
                                }}
                            >
                                <img
                                    alt=""
                                    src={DownButtonBig}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </CanvasPreventPropagationButton>
                    </div>
                )}
                {!this.props.live &&
                    !this.props.frozen &&
                    this.state.editedUrls == null && (
                        <div
                            style={{
                                position: "absolute",
                                bottom: 10,
                                right: 10,
                                zIndex: 2,
                            }}
                        >
                            <CanvasPreventPropagationButton>
                                <div
                                    style={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                    }}
                                    onClick={() => {
                                        this.setState((_state, props) => ({
                                            editedUrls:
                                                props.element.urls != null
                                                    ? props.element.urls
                                                    : [props.element.url ?? ""],
                                        }));
                                    }}
                                >
                                    <svg
                                        style={{
                                            fill:
                                                dataScienceElementsStyle.secondaryTextColor,
                                        }}
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M18 14.45v6.55h-16v-12h6.743l1.978-2h-10.721v16h20v-10.573l-2 2.023zm1.473-10.615l1.707 1.707-9.281 9.378-2.23.472.512-2.169 9.292-9.388zm-.008-2.835l-11.104 11.216-1.361 5.784 5.898-1.248 11.103-11.218-4.536-4.534z" />
                                    </svg>
                                </div>
                            </CanvasPreventPropagationButton>
                        </div>
                    )}
            </div>
        );
    }
}
