import React, { Component } from "react";
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { Resizable } from "re-resizable";
import elements from "common/CanvasElements";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import MapElementView from "../MapElementViewOld";
import {
    MapElement,
    ColorOptions,
    MapVersion,
    InnerCanvasChanges,
} from "common/Canvas";
import ColorOptionsPopup from "../ColorOptionsPopup";
import HtmlElementProps from "./HtmlElementProps";
import {
    changeElementWhenResize2,
    getNewSizeAfterResize2,
} from "../BaseCanvasResizableFunctions";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../Snap";

const typeName = "mapElementsState";
interface InnerProps extends HtmlElementProps {
    mapId: string;
    rootDataTestId: string;
}

interface InnerState {
    showColorOptions: ColorOptions | undefined;
    currentZoomLevel: number | undefined;
    hovered: boolean;
}

@observer
class MapElementWrapper extends CanvasInteractionComponent<
    InnerProps,
    InnerState
> {
    drag: boolean;
    constructor(props: InnerProps) {
        super(props);
        this.state = {
            currentZoomLevel: undefined,
            showColorOptions: undefined,
            hovered: false,
        };
        this.drag = false;
        this.trackNewPerformance = this.trackNewPerformance.bind(this);
        this.changeMapElement = this.changeMapElement.bind(this);
    }
    changeMapElement(
        mapElement: Partial<MapElement>,
        geoJsonFiles?: MapElement["geoJsonFiles"]
    ) {
        this.trackNewPerformance(elements.map);
        this.props.canvasTreeStore.updateMapElementAction(
            this.props.mapId,
            mapElement as MapElement,
            geoJsonFiles
        );
    }

    render() {
        const { canvasViewMode } = this.props.canvasTreeStore;
        let mapElement = this.props.canvasTreeStore.mapElementsState.get(
            this.props.mapId
        )!;
        let mapElementSize = {
            height:
                mapElement.nodeSize[canvasViewMode].height * this.props.scale,
            width: mapElement.nodeSize[canvasViewMode].width * this.props.scale,
        };

        return (
            <>
                <DraggableWithSnapping
                    key={this.props.mapId}
                    disabled={this.props.live || !this.props.canWrite}
                    cancel=".element-leaflet-map"
                    position={{
                        x:
                            mapElement.nodePosition[canvasViewMode].x *
                            this.props.scale,
                        y:
                            mapElement.nodePosition[canvasViewMode].y *
                            this.props.scale,
                    }}
                    onDrag={(_evt, _data) => {
                        this.drag = true;
                        let nearestPoints = this.props.onRebuildSnapLine(
                            {
                                x: _data.x,
                                y: _data.y,
                                width: mapElementSize.width,
                                height: mapElementSize.height,
                            },
                            {
                                type: typeName,
                                id: this.props.mapId,
                                groupId: mapElement.groupId,
                            }
                        );
                        this.props.onUpdateSelectionBounds?.();
                        let newPosition = snapElementToPoints(
                            mapElementSize.width,
                            mapElementSize.height,
                            nearestPoints
                        );
                        if (newPosition.x != null || newPosition.y != null) {
                            // Snap to this position
                            return newPosition;
                        }
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            this.props.onDeleteSnapLine();
                            this.trackNewPerformance(elements.map);
                            let x = Math.max(data.x / this.props.scale, 0);
                            let y = Math.max(data.y / this.props.scale, 0);
                            let deltaX =
                                x - mapElement.nodePosition[canvasViewMode].x;
                            let deltaY =
                                y - mapElement.nodePosition[canvasViewMode].y;

                            let changes: InnerCanvasChanges = {};
                            this.props.canvasTreeStore.updateMapElementAction(
                                this.props.mapId,
                                {
                                    nodePosition: {
                                        ...mapElement.nodePosition,
                                        [canvasViewMode]: {
                                            x: x,
                                            y: y,
                                        },
                                    },
                                    nodeSize: {
                                        ...mapElement.nodeSize,
                                        [canvasViewMode]: {
                                            width:
                                                mapElement.nodeSize[
                                                    canvasViewMode
                                                ].width,
                                            height:
                                                mapElement.nodeSize[
                                                    canvasViewMode
                                                ].height,
                                        },
                                    },
                                },
                                undefined,
                                changes
                            );
                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                x: x,
                                y: y,
                                width:
                                    mapElement.nodeSize[canvasViewMode].width,
                                height:
                                    mapElement.nodeSize[canvasViewMode].height,
                            });
                            this.props.onMoveGroupSelection(
                                deltaX,
                                deltaY,
                                {
                                    id: this.props.mapId,
                                    type: typeName,
                                    groupId: mapElement.groupId,
                                },
                                false,
                                changes
                            );
                            this.props.canvasTreeStore.saveChangesAction(
                                changes,
                                true,
                                true,
                                false,
                                this.props.canvasTreeStore.backgroundsState.toJSON(),
                                BackgroundMode.Update,
                                false
                            );
                            this.drag = false;
                        }
                    }}
                >
                    <div
                        onContextMenu={(evt) => {
                            this.props.onContextMenu(
                                evt,
                                {
                                    id: this.props.mapId,
                                    type: typeName,
                                },
                                true
                            );
                        }}
                        style={{
                            top: 0,
                            left: 0,
                            position: "absolute",
                            zIndex: mapElement.zIndex ?? 50,
                        }}
                        onMouseEnter={() => {
                            this.setState({ hovered: true });
                        }}
                        onMouseLeave={() => {
                            this.setState({ hovered: false });
                        }}
                    >
                        <Resizable
                            className="selectable-by-pointer"
                            ref={(ref) => {
                                let innerRef = ref?.resizable;
                                if (innerRef != null) {
                                    innerRef.setAttribute("type", typeName);
                                    if (mapElement.groupId != null)
                                        innerRef.setAttribute(
                                            "groupId",
                                            mapElement.groupId
                                        );
                                    else {
                                        innerRef.removeAttribute("groupId");
                                    }
                                    innerRef.setAttribute(
                                        "id",
                                        String(this.props.mapId)
                                    );
                                    innerRef.setAttribute(
                                        "data-test-id",
                                        this.props.rootDataTestId
                                    );
                                }
                            }}
                            enable={
                                this.props.live || !this.props.canWrite
                                    ? {
                                          top: false,
                                          right: false,
                                          bottom: false,
                                          left: false,
                                          topRight: false,
                                          bottomRight: false,
                                          bottomLeft: false,
                                          topLeft: false,
                                      }
                                    : {
                                          top: true,
                                          right: true,
                                          bottom: true,
                                          left: true,
                                          topRight: true,
                                          bottomRight: true,
                                          bottomLeft: true,
                                          topLeft: true,
                                      }
                            }
                            onResizeStart={(evt) => {
                                evt.stopPropagation();
                            }}
                            onResize={(_e, _direction, _ref, d) => {
                                changeElementWhenResize2(
                                    {
                                        position: mapElement.nodePosition,
                                        size: mapElement.nodeSize,
                                    },
                                    this.props.scale,
                                    _direction,
                                    d,
                                    _ref,
                                    canvasViewMode
                                );
                                this.props.onUpdateSelectionBounds?.();
                            }}
                            onResizeStop={(_e, _direction, _ref, d) => {
                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;
                                this.trackNewPerformance(elements.map);
                                let newSize = getNewSizeAfterResize2(
                                    {
                                        position: mapElement.nodePosition,
                                        size: mapElement.nodeSize,
                                    },
                                    this.props.scale,
                                    _direction,
                                    d,
                                    canvasViewMode
                                );
                                this.props.canvasTreeStore.updateMapElementAction(
                                    this.props.mapId,
                                    {
                                        nodePosition: newSize.nodePosition,
                                        nodeSize: newSize.nodeSize,
                                    }
                                );
                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        x:
                                            newSize.nodePosition[canvasViewMode]
                                                .x,
                                        y:
                                            newSize.nodePosition[canvasViewMode]
                                                .y,
                                        ...newSize.nodeSize[canvasViewMode],
                                    }
                                );
                                this.props.onResize();
                            }}
                            size={mapElementSize}
                        >
                            <MapElementView
                                onOpenColorOptions={(
                                    options,
                                    zoomLevel?: number
                                ) => {
                                    this.setState({
                                        showColorOptions: options,
                                        currentZoomLevel: zoomLevel,
                                    });
                                }}
                                onContextMenu={(evt) => {
                                    this.props.onContextMenu(
                                        evt,
                                        {
                                            id: this.props.mapId,
                                            type: typeName,
                                        },
                                        true
                                    );
                                }}
                                canvasTreeStore={this.props.canvasTreeStore}
                                scale={this.props.scale}
                                sharedPolicy={this.props.sharedPolicy}
                                frozen={!this.props.canWrite}
                                height={mapElementSize.height}
                                live={this.props.live}
                                mapElement={mapElement}
                                mapElementId={this.props.mapId}
                                onChange={this.changeMapElement}
                                onTrackNewPerformance={this.trackNewPerformance}
                                onDelete={() => {
                                    this.trackNewPerformance(elements.map);
                                    this.props.showDeletePopup(() => {
                                        this.props.onClearEditing();
                                        this.props.canvasTreeStore.deleteMapElementAction(
                                            this.props.mapId
                                        );
                                    });
                                }}
                                currentModuleId={this.props.currentModuleId}
                                //hovered={this.state.hovered}
                            />
                        </Resizable>
                    </div>
                </DraggableWithSnapping>
                {this.state.showColorOptions != null && (
                    <ColorOptionsPopup
                        isOldMap
                        currentZoomLevel={this.state.currentZoomLevel}
                        options={this.state.showColorOptions}
                        onClose={(apply, options) => {
                            if (apply) {
                                this.props.canvasTreeStore.updateMapElementAction(
                                    this.props.mapId,
                                    {
                                        colorOptions: options,
                                    }
                                );
                            }
                            this.setState({
                                currentZoomLevel: undefined,
                                showColorOptions: undefined,
                            });
                        }}
                    />
                )}
            </>
        );
    }
}

@observer
class MapElements extends Component<HtmlElementProps> {
    public render(): JSX.Element {
        let mapUIs: JSX.Element[] = [];
        for (let mapId of this.props.canvasTreeStore.mapElementsState.keys()) {
            let mapElement = this.props.canvasTreeStore.mapElementsState.get(
                mapId
            )!;
            if (mapElement.version === MapVersion.Second) continue;
            mapUIs.push(
                <MapElementWrapper
                    rootDataTestId={`mapElementV1-${mapUIs.length + 1}`}
                    key={mapId}
                    mapId={mapId}
                    {...this.props}
                />
            );
        }
        return <>{mapUIs}</>;
    }
}

export default MapElements;
