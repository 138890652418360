import React from "react";

export function MySvg(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23"
			height="22"
			viewBox="0 0 23 22"
			{...props}
		>
			<g>
				<rect
					width="2"
					height="22"
					x="0"
					y="0"
					fill="rgb(134,154,172)"
				/>
				<rect
					width="2"
					height="22"
					x="11"
					y="10"
					fill="rgb(134,154,172)"
					transform="rotate(90.000032 12 21)"
				/>
				<rect
					width="2"
					height="15"
					x="8.768"
					y="2.732"
					fill="rgb(134,154,172)"
					transform="rotate(45.000016 9.76776695 10.23223305)"
				/>
				<ellipse
					cx="5.5"
					cy="4.5"
					fill="rgb(134,154,172)"
					rx="1.5"
					ry="1.5"
				/>
				<ellipse
					cx="18.5"
					cy="13.5"
					fill="rgb(134,154,172)"
					rx="1.5"
					ry="1.5"
				/>
				<ellipse
					cx="18.5"
					cy="5.5"
					fill="rgb(134,154,172)"
					rx="1.5"
					ry="1.5"
				/>
				<ellipse
					cx="11.5"
					cy="16.5"
					fill="rgb(134,154,172)"
					rx="1.5"
					ry="1.5"
				/>
			</g>
		</svg>
	);
}