import React, { Component } from "react";
import stringSimilarity from "string-similarity";
import StoreContext from "state/StoreContext";
import { similarity } from "state/store/PresentationStore";
import Presentation from "state/models/Presentation";
import { observer } from "mobx-react";
import { SharedModule, SpecialTabId } from "common/Module";
import styles from "./CommandPalette.module.css";
import cx from "classnames";
import { ReactComponent as SearchIcon } from "icons/canvas_header/search.svg";
import { getTutorial } from "common/ModulesApi";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
import { ReactComponent as ExpandIcon } from "icons/canvas/page_bar/expand.svg";
import { ReactComponent as CollapseIcon } from "icons/canvas/page_bar/collapse.svg";
import LinkSlidePopup from "../LinkSlidePopup";
import { defaultSlideHeight, defaultSlideWidth } from "../Constants";
import OutsideAlerter from "common/OutsideAlerter";

interface FeatureOption {
    label: string;
    presentation: Presentation;
}

function tutorialToOption(presentation: Presentation): FeatureOption {
    return {
        label: presentation.title,
        presentation: presentation,
    };
}
function filterOptions(str: string, options: FeatureOption[]) {
    if (!str)
        return options.filter(
            (tutorial) =>
                tutorial?.presentation?.tabId ===
                SpecialTabId.TutorialsInitialList
        );

    let optionsScores: {
        option: FeatureOption;
        similarity: number;
    }[] = options.map((option) => ({
        option: option,
        similarity: option.presentation
            ? similarity(option.presentation, str.toLowerCase())
            : stringSimilarity.compareTwoStrings(
                  option.label.toLowerCase(),
                  str.toLowerCase()
              ),
    }));
    optionsScores = optionsScores.filter(
        (optionsScore) => optionsScore.similarity > 0
    );
    optionsScores.sort((e1, e2) => {
        if (e1.similarity < e2.similarity) {
            return 1;
        }
        if (e1.similarity > e2.similarity) {
            return -1;
        }
        return 0;
    });
    return optionsScores.map((option) => option.option);
}

interface Props {
    x: number;
    y: number;
    onClose: () => void;
}

interface State {
    inputString: string;
    tutorialOptions: FeatureOption[];
    selectedIndex: number;
    selectedPage?: SharedModule["pages"][number];
    currentTutorial: FeatureOption | undefined;
    loading: boolean;
    errorMessage: string | undefined;
    sharedModule: SharedModule | undefined;
}

interface PropsWithTutorials extends Props {
    tutorialOptions: FeatureOption[];
}

const paletteWidth = 600;
const paletteHeight = 500;
class CommandPalette extends Component<PropsWithTutorials, State> {
    constructor(props: PropsWithTutorials) {
        super(props);
        this.state = {
            loading: false,
            errorMessage: undefined,
            inputString: "",
            tutorialOptions: props.tutorialOptions.filter(
                (tutorial) =>
                    tutorial.presentation?.tabId ===
                    SpecialTabId.TutorialsInitialList
            ),
            sharedModule: undefined,
            selectedIndex: 0,
            currentTutorial: undefined,
        };
    }
    componentDidUpdate(prevProps: PropsWithTutorials, prevState: State) {
        if (
            prevProps.tutorialOptions.length !==
                this.props.tutorialOptions.length &&
            !prevState.inputString
        ) {
            this.setState({
                selectedIndex: 0,
                tutorialOptions: this.props.tutorialOptions.filter(
                    (tutorial) =>
                        tutorial.presentation?.tabId ===
                        SpecialTabId.TutorialsInitialList
                ),
            });
        }
    }
    catchKeyDown(evt: any) {
        evt.stopPropagation();
        let overallLength = this.state.tutorialOptions.length;
        if (evt.key === "ArrowDown") {
            evt.preventDefault();
            let selectedIndex = this.state.selectedIndex;
            if (overallLength > 0) {
                selectedIndex = (selectedIndex + 1) % overallLength;
                this.setState({ selectedIndex: selectedIndex });
            }
        }
        if (evt.key === "ArrowUp") {
            evt.preventDefault();
            let selectedIndex = this.state.selectedIndex;
            if (overallLength > 0) {
                selectedIndex = (selectedIndex - 1) % overallLength;
                if (selectedIndex < 0) {
                    selectedIndex = overallLength + selectedIndex;
                }
                this.setState({ selectedIndex: selectedIndex });
            }
        }
        if (evt.key === "Enter") {
            evt.preventDefault();
            evt.stopPropagation();
            const overallArray = this.state.tutorialOptions;

            if (overallArray.length > 0) {
                let option = overallArray[this.state.selectedIndex];
                this.callOption(option);
            }
        }
        if (evt.key === "Escape") {
            evt.preventDefault();
            evt.stopPropagation();
            this.props.onClose();
        }
    }
    callOption(option: FeatureOption) {
        this.setState(
            { currentTutorial: option, errorMessage: undefined, loading: true },
            () => {
                getTutorial(option.presentation.id as number)
                    .then((sharedModule) => {
                        this.setState({
                            sharedModule: sharedModule,
                            loading: false,
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                            errorMessage: String(error),
                        });
                    });
            }
        );

        // goToInternalLink(
        //     `tutorials.html?tutorial_id=${option.presentation!.id}`
        // );
    }
    buildOption(option: FeatureOption, index?: number) {
        let selected = index === this.state.selectedIndex;
        let frozen =
            this.state.currentTutorial != null &&
            this.state.currentTutorial.presentation.id ===
                option.presentation.id;

        selected = selected || frozen;
        return (
            <div
                key={index}
                className={styles.itemContainer}
                onMouseOver={() => {
                    if (index != null)
                        this.setState({
                            selectedIndex: index,
                        });
                }}
                onClick={(evt) => {
                    evt.stopPropagation();
                    if (!frozen) this.callOption(option);
                }}
            >
                <span
                    className={cx(
                        "unselectable",
                        styles.item,
                        selected && styles.itemMedium
                    )}
                >
                    {option.label}
                </span>
            </div>
        );
    }

    render() {
        return (
            <>
                <div
                    className={styles.container}
                    tabIndex={0}
                    style={{
                        zIndex: 9999999999,
                        left: this.props.x,
                        top: this.props.y,
                        position: "absolute",
                        height: paletteHeight,
                        width: paletteWidth,
                    }}
                    onMouseDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    onKeyDown={(evt) => {
                        this.catchKeyDown(evt);
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{ height: "100%" }}
                    >
                        <div
                            className={cx("my-row", styles.searchContainer)}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "30px",
                            }}
                        >
                            <div
                                style={{
                                    marginLeft: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <SearchIcon />
                            </div>
                            <input
                                autoFocus
                                onKeyDown={(evt) => {
                                    this.catchKeyDown(evt);
                                }}
                                className={styles.searchInput}
                                style={{
                                    marginLeft: "11px",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    outline: "none",
                                    width: "100%",
                                }}
                                value={this.state.inputString}
                                type="text"
                                placeholder="Search for a feature..."
                                onChange={(evt) => {
                                    let value = evt.target.value;
                                    let tutorialOptions = filterOptions(
                                        value,
                                        this.props.tutorialOptions
                                    );
                                    this.setState({
                                        currentTutorial: undefined,
                                        selectedPage: undefined,
                                        sharedModule: undefined,
                                        loading: false,
                                        errorMessage: undefined,
                                        selectedIndex: 0,
                                        inputString: value,
                                        tutorialOptions: tutorialOptions,
                                    });
                                }}
                            />
                            <div
                                className={styles.searchInput}
                                style={{ padding: "6px", cursor: "pointer" }}
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    this.props.onClose();
                                }}
                            >
                                &times;
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            {this.state.currentTutorial == null && (
                                <div className={styles.backButton}>
                                    <div></div>&nbsp;
                                </div>
                            )}
                            {this.state.currentTutorial == null &&
                                !this.state.loading &&
                                this.state.tutorialOptions.map(
                                    (option, index) => {
                                        return this.buildOption(option, index);
                                    }
                                )}
                            {this.state.currentTutorial != null && (
                                <div>
                                    <div className={styles.backButton}>
                                        <div
                                            onClick={(evt) => {
                                                evt.stopPropagation();
                                                this.setState({
                                                    currentTutorial: undefined,
                                                    selectedPage: undefined,
                                                    sharedModule: undefined,
                                                });
                                            }}
                                        >
                                            back
                                        </div>
                                    </div>
                                    {this.state.currentTutorial && (
                                        <>
                                            {this.buildOption(
                                                this.state.currentTutorial
                                            )}
                                        </>
                                    )}
                                    {this.state.sharedModule &&
                                        this.state.sharedModule.pages.map(
                                            (page) => {
                                                return (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.itemContainer
                                                            }
                                                            onClick={(evt) => {
                                                                evt.stopPropagation();

                                                                this.setState(
                                                                    (state) => {
                                                                        if (
                                                                            state
                                                                                .selectedPage
                                                                                ?.pageId ===
                                                                            page.pageId
                                                                        )
                                                                            return {
                                                                                selectedPage:
                                                                                    undefined,
                                                                            };
                                                                        return {
                                                                            selectedPage:
                                                                                page,
                                                                        };
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <div
                                                                className={cx(
                                                                    styles.item,
                                                                    styles.pageItem,
                                                                    "unselectable"
                                                                )}
                                                            >
                                                                {page.pageId}
                                                                {page.pageId ===
                                                                this.state
                                                                    .selectedPage
                                                                    ?.pageId ? (
                                                                    <CollapseIcon />
                                                                ) : (
                                                                    <ExpandIcon />
                                                                )}
                                                            </div>
                                                        </div>
                                                        {this.state
                                                            .currentTutorial !=
                                                            null &&
                                                            this.state
                                                                .sharedModule !=
                                                                null &&
                                                            this.state
                                                                .selectedPage
                                                                ?.pageId ===
                                                                page.pageId && (
                                                                <LinkSlidePopup
                                                                    inPopup={
                                                                        false
                                                                    }
                                                                    containerStyle={{
                                                                        maxHeight: undefined,
                                                                        boxShadow:
                                                                            "none",
                                                                        borderRadius: 0,
                                                                        marginTop:
                                                                            "10px",
                                                                        border: "none",
                                                                        padding:
                                                                            "0px 5px 0px 5px",
                                                                        backgroundColor:
                                                                            "white",
                                                                    }}
                                                                    canvas={
                                                                        this
                                                                            .state
                                                                            .selectedPage
                                                                            .sheets[0]
                                                                    }
                                                                    moduleId={`t~${this.state.currentTutorial?.presentation.id}`}
                                                                    allowCache={
                                                                        false
                                                                    }
                                                                    requireAuthentication
                                                                    sharedModule={
                                                                        this
                                                                            .state
                                                                            .sharedModule
                                                                    }
                                                                    windowSize={{
                                                                        width:
                                                                            paletteWidth -
                                                                            30,
                                                                        height:
                                                                            ((paletteWidth -
                                                                                30) *
                                                                                (this
                                                                                    .state
                                                                                    .selectedPage
                                                                                    .sheets[0]
                                                                                    ?.canvas
                                                                                    ?.slideHeight
                                                                                    ?.desktop ??
                                                                                    defaultSlideHeight)) /
                                                                            defaultSlideWidth,
                                                                    }}
                                                                />
                                                            )}
                                                    </>
                                                );
                                            }
                                        )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {this.state.errorMessage && (
                    <StatusPopup
                        status={PopupStatus.Error}
                        message={this.state.errorMessage}
                        onClose={() => {
                            this.setState({ errorMessage: undefined });
                        }}
                    />
                )}
            </>
        );
    }
}

export default observer(function CommandPaletteWrapper(props: Props) {
    const store = React.useContext(StoreContext);
    React.useEffect(() => {
        store.presentationStore.fetchTutorials();
        // store.presentationStore should not be in dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <OutsideAlerter onReject={props.onClose}>
            <CommandPalette
                {...props}
                tutorialOptions={store.presentationStore.tutorials.map(
                    (tutorial) => tutorialToOption(tutorial)
                )}
            />
        </OutsideAlerter>
    );
});
