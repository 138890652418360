enum ItemInitializer {
	FlowChart = 1,
	Tag = 2,
	Input = 3,
	Slider = 4,
	Toggle = 5,
	DropdownSelector = 6,
	Grid = 7,
	SpreadSheetGrid = 8,
	Shape = 9,
	Dashboard = 10,
	BackendTable = 11,
	Questionnaire = 12,
	MergeData = 14,
	AggregateData = 15,
	ManageData = 16,
	SimpleSpreadSheetGrid = 17,
	DataInputButton = 19,
	SubmitButton = 20,
	Map = 21,
	EmbedUrl = 22,
	Pin = 23,
	BarcodeReader = 24,
	ProgressElement = 25,
    Graph = 26,
	Filter = 27,
	RadioButtonsGroup = 28,
	Survey = 29
}

export enum DataScienceMenuItems {
	Basic = 1,
	Leaderboard = 2,
	Map = 3,
	Workflow = 4,
	TextAnalytics = 5,
	MachineLearning = 6,
	NetworkGraph = 7,
}

export default ItemInitializer;
