import React, { Component } from "react";
import { Navbar } from "react-bootstrap";

import "common/styles/App.css";
import "common/styles/div_span.css";
import "common/styles/buttons.css";
import { AppModuleProps } from "AppModule";
import Alert from "common/Alert";
import ScaledPage from "common/ScaledPage";
import axios from "common/ServerConnection";

enum Status {
    Running = "running",
    Finished = "finished",
    Error = "error"
};

const statusToClassName: Readonly<{[key in Status]: string}> = {
    [Status.Running]: "alert-warning",
    [Status.Finished]: "alert-success",
    [Status.Error]: "alert-danger"
};

interface State {
    status: Status;
    error: string | null;
}

class MainComponent extends Component<AppModuleProps, State> {
    constructor(props: AppModuleProps) {
        super(props);
        this.state = {
            status: Status.Running,
            error: null
        };
    }

    componentDidMount() {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        axios
            .post<{
                success: boolean,
                error_msg: string
            }>("/api/verify_email", {id: params.get("id") ?? ""})
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        status: Status.Finished,
                        error: null
                    });
                } else {
                    this.setState({
                        status: Status.Error,
                        error: response.data.error_msg
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    status: Status.Error,
                    error: error.response.status.toString()
                });
            });
    }

    render() {
        let statusText: string = "";
        if(this.state.status === Status.Running) {
            statusText = "Running";
        } else if(this.state.status === Status.Finished) {
            statusText = "Success";
        } else {
            statusText = `Error: ${this.state.error}`;
        }
        return (
            <ScaledPage>
                <div className="content-wrapper hide-scroll">
                    <section className="content-header">
                        <h3>Email Verification</h3>
                    </section>
                    <section
                        className="content"
                        style={this.props.positionStyle}
                    >
                        <Navbar className="bg-light justify-content-between">
                            <Alert
                                text={statusText}
                                className={"alert " + statusToClassName[this.state.status]}
                                onClosed={() => {}}
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "0px"
                                }}
                            />
                        </Navbar>
                    </section>
                </div>
            </ScaledPage>
        );
    }
}

export { MainComponent };
export let route = "/v.html";
