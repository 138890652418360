import React, { Component, CSSProperties } from "react";
import zxcvbn from "zxcvbn";

import SegmentedProgressBar from "common/SegmentedProgressBar";

interface Props {
    password: string;
    style?: CSSProperties;
    leftText?: string;
    leftTextStyle?: CSSProperties;
    rightTextStyle?: CSSProperties;
    progressBarStyle?: CSSProperties;
    swapProgressBarAndRightText?: boolean;
}

class PasswordStrength extends Component<Props> {
    public render() {
        const score: number =
            this.props.password.length !== 0
                ? zxcvbn(this.props.password).score
                : -1;
        let strengthText: string = "";
        let color: string = "";
        switch (score) {
            case -1:
                strengthText = "";
                color = "#dd4b39";
                break;
            case 0:
                strengthText = "(very weak)";
                color = "#dd4b39";
                break;
            case 1:
                strengthText = "(weak)";
                color = "#dd4b39";
                break;
            case 2:
                strengthText = "(medium)";
                color = "#f39c12";
                break;
            case 3:
                strengthText = "(strong)";
                color = "#00a65a";
                break;
            case 4:
                strengthText = "(very strong)";
                color = "#00a65a";
                break;
            default:
                return null;
        }
        const leftText: string =
            this.props.leftText != null
                ? this.props.leftText
                : "Password strength:";
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    ...this.props.style,
                }}
            >
                <span className="regular-text" style={this.props.leftTextStyle}>
                    {leftText}
                </span>
                {this.props.swapProgressBarAndRightText && (
                    <span
                        className="regular-text"
                        style={{
                            marginLeft: 5,
                            ...this.props.rightTextStyle,
                        }}
                    >
                        {strengthText}
                    </span>
                )}
                <SegmentedProgressBar
                    segmentColor={color}
                    style={{
                        marginLeft: 5,
                        ...this.props.progressBarStyle,
                    }}
                    maxProgress={5}
                    progress={score + 1}
                />
                {!this.props.swapProgressBarAndRightText && (
                    <span
                        className="regular-text"
                        style={{
                            marginLeft: 5,
                            ...this.props.rightTextStyle,
                        }}
                    >
                        {strengthText}
                    </span>
                )}
            </div>
        );
    }
}

export default PasswordStrength;
