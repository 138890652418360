import React from "react";
import Select from "react-select";

import {
    MapTooltipDisplayMode,
    MapTooltipDisplayModeOption,
} from "common/Canvas";
import { MapFinding } from "common/Finding";
import MapVariablesSelectorView from "modules/canvas_page/question_views/RevampedMapVariablesSelectorView";

import styles from "./TooltipConfig.module.css";

const options: Array<MapTooltipDisplayModeOption> = [
    { label: "click", value: MapTooltipDisplayMode.onClick },
    { label: "hover", value: MapTooltipDisplayMode.onHover },
    { label: "always", value: MapTooltipDisplayMode.always },
];

interface Props {
    mapFinding: MapFinding;
    onChange: (finding: MapFinding, update?: boolean) => void;
    currentModuleId?: number;
    additionalMapIndex?: number;
}

function TooltipConfig({
    currentModuleId,
    mapFinding,
    onChange,
    additionalMapIndex,
}: Props) {
    let variables = mapFinding.config.tooltipVariables ?? [null];
    return (
        <div className={styles.root}>
            <div className={styles.displayMode}>
                <span className={styles.title}>Show value on</span>
                <Select
                    options={options}
                    value={mapFinding.config.displayMode ?? options[0]}
                    isSearchable={false}
                    onChange={(newValue) => {
                        const newFinding = {
                            ...mapFinding,
                            config: {
                                ...mapFinding.config,
                                displayMode: newValue as MapTooltipDisplayModeOption,
                            },
                        };
                        onChange(newFinding, true);
                    }}
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            minWidth: 70,
                        }),
                        control: (provided) => ({
                            ...provided,
                            minHeight: 25,
                        }),
                        indicatorsContainer: (provided) => ({
                            display: "none",
                        }),
                        menu: (base) => ({
                            ...base,
                            zIndex: 100000000,
                        }),
                    }}
                />
            </div>
            <MapVariablesSelectorView
                dataScope={mapFinding.config.dataScope!}
                variables={variables}
                onChange={(newOptions) => {
                    const newFinding = {
                        ...mapFinding,
                        config: {
                            ...mapFinding.config,
                            tooltipVariables: newOptions,
                        },
                    };
                    onChange(newFinding, true);
                }}
                currentModuleId={currentModuleId}
            />
        </div>
    );
}

export default TooltipConfig;
