import React from "react";
import Select from "react-select";

import { MapElement } from "common/Canvas";
import ColorPicker from "common/ColorPicker";
import remoteModuleId from "common/remoteModuleId";
import Variables, { VariableOption } from "common/Variables";
import { resizeAndEncodeToBase64 } from "common/utilities/retrieveImageFromClipboard";

import styles from "./MarkerConfig.module.css";

enum MarkerColoringType {
    Fixed = 0,
    Varied = 1,
}

interface MarkerColoringOption {
    label: string;
    value: MarkerColoringType;
}

const markerColoringOptions: ReadonlyArray<MarkerColoringOption> = [
    { label: "Use fixed color", value: MarkerColoringType.Fixed },
    { label: "Vary color by variable", value: MarkerColoringType.Varied },
];

interface Props {
  mapElement: MapElement;
  onChange: (value: Partial<MapElement>) => void;
  currentModuleId?: number;
}

function MarkerConfig({ mapElement, onChange, currentModuleId }: Props) {
  return (
    <div className={styles.colorMenu}>
      <div className={styles.colorMenuFirstRow}>
        <div className={styles.markerMenu}>
          <div className={styles.markerArea}>
            {(mapElement.markerIcon != null ||
                mapElement.markerColor == null ||
                mapElement.varyMarkerColorByVariable) && (
                  <img
                    alt=""
                    src={
                      mapElement.markerIcon ??
                      "/dist/img/canvas/map/marker.png"
                    }
                    width="32px"
                    height="32px"
                    className={styles.markerIcon}
                  />
            )}
            {mapElement.markerIcon == null &&
              mapElement.markerColor != null &&
              !mapElement.varyMarkerColorByVariable && (
                <div
                  className={styles.markerIcon}
                  style={{
                    backgroundColor: mapElement.markerColor,
                    maskImage: "url(/dist/img/canvas/map/marker.png)",
                    maskSize: "32px 32px",
                    WebkitMaskImage: "url(/dist/img/canvas/map/marker.png)",
                    WebkitMaskSize: "32px 32px",
                    width: "32px",
                    height: "32px",
                  }}
                />
            )}
          </div>
          <label className={styles.changeIconButton}>
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              onChange={(event) => {
                const files = event?.target?.files ?? [];
                if (files.length !== 0) {
                  const file = files[0];
                  resizeAndEncodeToBase64(
                    file,
                    (base64Image) => {
                      onChange({
                        markerIcon: base64Image,
                      });
                    },
                    64,
                    "image/png",
                    "center",
                    "bottom"
                  );
                }
              }}
            />
            Change icon
          </label>
        </div>
        <div className={styles.colorOptionsMenu}>
          <div className={styles.colorSelect}>
            <Select
              placeholder=""
              styles={{
                container: (provided) => ({
                  ...provided,
                  minWidth: 60,
                }),
                control: (provided) => ({
                  ...provided,
                  minHeight: 25,
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
              }}
              options={markerColoringOptions}
              onChange={(newValue) => {
                onChange({
                  varyMarkerColorByVariable:
                    (newValue as MarkerColoringOption).value ===
                    MarkerColoringType.Varied,
                });
              }}
              value={
                mapElement.varyMarkerColorByVariable
                  ? markerColoringOptions[1]
                  : markerColoringOptions[0]
              }
            />
          </div>
          {!mapElement.varyMarkerColorByVariable && (
            <ColorPicker
              enableAlpha
              value={mapElement.markerColor ?? "#49A2FE"}
              onChange={(newValue) => {
                onChange({ markerColor: newValue });
              }}
              style={{
                marginLeft: "8px",
                borderRadius: "4px",
                width: "32px",
                height: "32px"
              }}
              inPopup
            />
          )}
        </div>
        </div>
      <div className={styles.colorMenuSecondRow}>
          {mapElement.varyMarkerColorByVariable && (
              <div
                  className="flex-simple-column"
                  style={{
                      marginTop: "15px",
                  }}
              >
                  <Select
                      isClearable={true}
                      placeholder=""
                      styles={{
                          container: (provided) => ({
                              ...provided,
                              minWidth: 60,
                          }),
                          control: (provided) => ({
                              ...provided,
                              minHeight: 25,
                          }),
                          indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                          }),
                      }}
                      options={
                          Variables(
                              mapElement.dataScope?.value,
                              currentModuleId ?? remoteModuleId
                          ).variableOptions
                      }
                      onChange={(newValue) => {
                          onChange({
                              markerColorVariableIndex:
                                  (newValue as VariableOption)
                                      ?.value ?? null,
                          });
                      }}
                      value={
                          mapElement.markerColorVariableIndex != null
                              ? Variables(
                                    mapElement.dataScope?.value,
                                    currentModuleId ?? remoteModuleId
                                ).variableOptions[
                                    mapElement
                                        .markerColorVariableIndex
                                ]
                              : null
                      }
                  />
              </div>
          )}
      </div>
    </div>
  );
}

export default MarkerConfig;
