import React, { Component } from "react";
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { Resizable } from "re-resizable";
import elements from "common/CanvasElements";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "../CanvasInteractionComponent";
import MapElementView from "../../MapElementView";
import { InnerCanvasChanges, MapElement, MapVersion } from "common/Canvas";
import HtmlElementProps from "../HtmlElementProps";
import {
    getNewSizeAfterResize2,
    changeElementWhenResize2,
} from "../../BaseCanvasResizableFunctions";
import { VariableOption } from "common/Variables";
import axios from "common/ServerConnection";
import { Condition, conditionsToJson } from "common/Conditions";
import remoteModuleId from "common/remoteModuleId";
import AsyncLock from "common/AsyncLock";
import TablePreview from "./TablePreview";
import EditMenu from "./EditMenu";
import {
    mobileAndTabletBreakpoint,
    openDelayedNodeMenuAfterTouch,
} from "common/utilities/UIResponsiveManager";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "modules/canvas_page/Snap";

const typeName = "mapElementsState";
interface InnerProps extends HtmlElementProps {
    outdated: boolean;
    mapId: string;
    rootDataTestId: string;
    editMenuIsOpen: boolean;
    toggleOpenEditMenu: (mapId: string) => void;
    htmlElementsRootRef: HTMLDivElement | undefined;
}

interface InnerState {
    currentZoomLevel: number | undefined;
    hovered: boolean;
    advancedOptionSelected: AdvancedMenuOptions;
    updating: boolean;
    data: { [key: string]: Array<number | string | null> } | null;
    heatMapData: Array<[number, number, number]>;
    geoJsonPopupIsOpen: boolean;
}

enum AdvancedMenuOptions {
    None = 0,
    Pins = 1,
    Heatmap = 2,
    MapBoundaries = 3,
}

const markerQueryLimit = 5000;

@observer
class MapElementWrapper extends CanvasInteractionComponent<
    InnerProps,
    InnerState
> {
    drag: boolean;
    updateMapLock: AsyncLock;

    constructor(props: InnerProps) {
        super(props);
        this.state = {
            currentZoomLevel: undefined,
            hovered: false,
            advancedOptionSelected: AdvancedMenuOptions.None,
            updating: false,
            heatMapData: [],
            data: null,
            geoJsonPopupIsOpen: false,
        };

        this.drag = false;
        this.updateMapLock = new AsyncLock();
        this.trackNewPerformance = this.trackNewPerformance.bind(this);
        this.changeMapElement = this.changeMapElement.bind(this);
        this.changeMapConfig = this.changeMapConfig.bind(this);
        this.openGeoJsonPopup = this.openGeoJsonPopup.bind(this);
        this.closeEditMenu = this.closeEditMenu.bind(this);
    }

    componentDidMount() {
        this.updateData();
    }
    componentDidUpdate(_prevProps: InnerProps): void {
        if (this.props.outdated) {
            this.props.canvasTreeStore.canvasOutdatedMaps.delete(
                this.props.mapId
            );
            this.updateData();
        }
    }

    changeMapElement(
        mapElement: Partial<MapElement>,
        geoJsonFiles?: MapElement["geoJsonFiles"]
    ) {
        this.trackNewPerformance(elements.map);
        this.props.canvasTreeStore.updateMapElementAction(
            this.props.mapId,
            mapElement as MapElement,
            geoJsonFiles
        );
    }

    changeMapConfig(
        mapElement: Partial<MapElement>,
        geoJsonFiles?: MapElement["geoJsonFiles"]
    ) {
        this.changeMapElement(mapElement, geoJsonFiles);
        this.updateData();
    }

    prepareHeatMap(): void {
        const mapElement = this.props.canvasTreeStore.mapElementsState.get(
            this.props.mapId
        )!;
        let heatMapData: Array<[number, number, number]> = [];

        if (
            !this.state.data ||
            !mapElement ||
            (mapElement.usesCoordinates &&
                (!mapElement.coordinates?.latitude ||
                    !mapElement.coordinates?.longitude))
        ) {
            return;
        }

        let latIndex: string;
        let lonIndex: string;
        if (mapElement.usesCoordinates) {
            latIndex = mapElement.coordinates!.latitude!.value.toString();
            lonIndex = mapElement.coordinates!.longitude!.value.toString();
        } else {
            latIndex = "%lat";
            lonIndex = "%lon";
        }
        if (this.state.data[latIndex] != null) {
            let length: number = this.state.data[latIndex].length;

            if (mapElement.heatMap != null) {
                let numbers = this.state.data[
                    mapElement.heatMap.value.toString()
                ].filter((item) => typeof item === "number") as number[];
                let max = Math.max(...numbers);
                let min = Math.min(...numbers);
                for (let i = 0; i < length; ++i) {
                    let value = Number(
                        this.state.data[mapElement.heatMap.value.toString()][i]
                    );
                    let position = [
                        Number((this.state.data[latIndex][i] ?? NaN) as number),
                        Number((this.state.data[lonIndex][i] ?? NaN) as number),
                    ];
                    if (
                        !Number.isNaN(position[0]) &&
                        !Number.isNaN(position[1]) &&
                        !Number.isNaN(value)
                    ) {
                        heatMapData.push([
                            position[0],
                            position[1],
                            (value - min) / (max - min),
                        ]);
                    }
                }
            }
        }
        this.setState({ heatMapData: heatMapData });
    }

    updateData(limit?: number): void {
        const mapElement = this.props.canvasTreeStore.mapElementsState.get(
            this.props.mapId
        )!;

        if (mapElement.dataScope?.value == null) return;

        let fn = () => {
            this.setState({ updating: true });
            let variableIndices = new Set<number>();
            let location:
                | { [key: string]: string | number }
                | undefined = undefined;
            if (mapElement.usesCoordinates && mapElement.coordinates != null) {
                for (let option of Object.values(mapElement.coordinates)) {
                    if (option != null) {
                        variableIndices.add(option.value);
                    }
                }
            } else if (
                !mapElement.usesCoordinates &&
                mapElement.location != null
            ) {
                for (let option of Object.keys(mapElement.location)) {
                    let value = mapElement.location[
                        option as keyof MapElement["location"]
                    ] as VariableOption;
                    if (value != null) {
                        if (location == null) {
                            location = {};
                        }
                        location = {
                            ...location,
                            [option]: value.value,
                        };
                    }
                }
            }

            if (mapElement.heatMap != null) {
                variableIndices.add(mapElement.heatMap.value);
            }

            if (mapElement.tooltipVariables) {
                for (let option of mapElement.tooltipVariables) {
                    if (option) {
                        variableIndices.add(option.variable.value);
                    }
                }
            }

            if (
                mapElement.varyMarkerColorByVariable &&
                mapElement.markerColorVariableIndex != null
            ) {
                variableIndices.add(mapElement.markerColorVariableIndex);
            }

            let orderBy: number[] = [];
            if (mapElement.selectOrderBy != null) {
                for (let option of mapElement.selectOrderBy) {
                    if (option != null) {
                        orderBy.push(option.value);
                    }
                }
            }

            axios
                .post<{
                    success: boolean;
                    error_msg: string;
                    data?: { [key: string]: Array<number | string | null> };
                }>("/api/e/get_map_variable_values", {
                    data_table_idx: mapElement.dataScope?.value,
                    location: location,
                    table: mapElement.tableOption?.value,
                    conditions_id: mapElement.tableOption?.condition_id,
                    variable_indices: Array.from(variableIndices),
                    order_by_asc_desc: mapElement.selectLowest ? "asc" : "desc",
                    order_by: orderBy,
                    limit: limit ?? mapElement.selectLimit ?? markerQueryLimit,
                    conditions:
                        mapElement.conditions != null
                            ? conditionsToJson(
                                  mapElement.conditions.filter(
                                      (cond): cond is Condition =>
                                          cond != null &&
                                          cond.variable != null &&
                                          cond.value != null &&
                                          cond.operation != null
                                  )
                              )
                            : null,
                    module_id: this.props.currentModuleId ?? remoteModuleId,
                })
                .then((response) => {
                    if (response.data.success && response.data.data != null) {
                        this.setState(
                            {
                                data: response.data.data,
                                // We have to clear the heatMapData here, otherwise
                                // the previous value would stay whenever we
                                // delete the heat map
                                heatMapData: [],
                                updating: false,
                            },
                            () => {
                                this.prepareHeatMap();
                            }
                        );
                    } else {
                        this.setState({ updating: false });
                        console.log(response.data.error_msg);
                    }
                })
                .catch((error) => {
                    this.setState({ updating: false });
                    console.log(error);
                });
        };
        try {
            this.updateMapLock.acquire("updateMap", fn, (err, ret) => {}, {
                skipQueue: true,
            });
        } catch (error) {}
    }

    toggleOpenEditMenu(mapId: string, zoomLevel?: number) {
        this.setState({ currentZoomLevel: zoomLevel });
        this.props.toggleOpenEditMenu(mapId);
    }

    openGeoJsonPopup() {
        this.setState({ geoJsonPopupIsOpen: true });
    }

    closeEditMenu() {
        this.props.toggleOpenEditMenu(this.props.mapId);
    }

    render() {
        const mapElement = this.props.canvasTreeStore.mapElementsState.get(
            this.props.mapId
        )!;
        const { canvasViewMode } = this.props.canvasTreeStore;
        const mapElementSize = {
            height:
                mapElement.nodeSize[canvasViewMode].height * this.props.scale,
            width: mapElement.nodeSize[canvasViewMode].width * this.props.scale,
        };

        return (
            <>
                <DraggableWithSnapping
                    key={this.props.mapId}
                    disabled={this.props.live || !this.props.canWrite}
                    cancel=".element-leaflet-map"
                    position={{
                        x:
                            mapElement.nodePosition[canvasViewMode].x *
                            this.props.scale,
                        y:
                            mapElement.nodePosition[canvasViewMode].y *
                            this.props.scale,
                    }}
                    onDrag={(_evt, _data) => {
                        this.drag = true;
                        let nearestPoints = this.props.onRebuildSnapLine(
                            {
                                x: _data.x,
                                y: _data.y,
                                width: mapElementSize.width,
                                height: mapElementSize.height,
                            },
                            {
                                type: typeName,
                                id: this.props.mapId,
                                groupId: mapElement.groupId,
                            }
                        );
                        this.props.onUpdateSelectionBounds?.();
                        let newPosition = snapElementToPoints(
                            mapElementSize.width,
                            mapElementSize.height,
                            nearestPoints
                        );
                        if (newPosition.x != null || newPosition.y != null) {
                            // Snap to this position
                            return newPosition;
                        }
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            this.props.onDeleteSnapLine();
                            this.trackNewPerformance(elements.map);
                            let x = Math.max(data.x / this.props.scale, 0);
                            let y = Math.max(data.y / this.props.scale, 0);
                            let deltaX =
                                x - mapElement.nodePosition[canvasViewMode].x;
                            let deltaY =
                                y - mapElement.nodePosition[canvasViewMode].y;

                            let changes: InnerCanvasChanges = {};
                            this.props.canvasTreeStore.updateMapElementAction(
                                this.props.mapId,
                                {
                                    nodePosition: {
                                        ...mapElement.nodePosition,
                                        [canvasViewMode]: {
                                            x: x,
                                            y: y,
                                        },
                                    },
                                    nodeSize: {
                                        ...mapElement.nodeSize,
                                        [canvasViewMode]: {
                                            width:
                                                mapElement.nodeSize[
                                                    canvasViewMode
                                                ].width,
                                            height:
                                                mapElement.nodeSize[
                                                    canvasViewMode
                                                ].height,
                                        },
                                    },
                                },
                                undefined,
                                changes
                            );
                            this.props.canvasTreeStore.updateCanvasSizeAction({
                                x: x,
                                y: y,
                                width:
                                    mapElement.nodeSize[canvasViewMode].width,
                                height:
                                    mapElement.nodeSize[canvasViewMode].height,
                            });
                            this.props.onMoveGroupSelection(
                                deltaX,
                                deltaY,
                                {
                                    id: this.props.mapId,
                                    type: typeName,
                                    groupId: mapElement.groupId,
                                },
                                false,
                                changes
                            );
                            this.props.canvasTreeStore.saveChangesAction(
                                changes,
                                true,
                                true,
                                false,
                                this.props.canvasTreeStore.backgroundsState.toJSON(),
                                BackgroundMode.Update,
                                false
                            );
                            this.drag = false;
                            if (mobileAndTabletBreakpoint()) {
                                openDelayedNodeMenuAfterTouch(
                                    () => {
                                        this.setState({ hovered: true });
                                    },
                                    () => {
                                        this.setState({ hovered: false });
                                    }
                                );
                            }
                        }
                    }}
                >
                    <div
                        onContextMenu={(evt) => {
                            this.props.onContextMenu(
                                evt,
                                {
                                    id: this.props.mapId,
                                    type: typeName,
                                },
                                true
                            );
                        }}
                        style={{
                            top: 0,
                            left: 0,
                            position: "absolute",
                            zIndex: mapElement.zIndex ?? 50,
                        }}
                        onMouseEnter={() => {
                            this.setState({ hovered: true });
                        }}
                        onMouseLeave={() => {
                            this.setState({ hovered: false });
                        }}
                        onTouchStart={() => {
                            openDelayedNodeMenuAfterTouch(
                                () => {
                                    this.setState({ hovered: true });
                                },
                                () => {
                                    this.setState({ hovered: false });
                                }
                            );
                        }}
                    >
                        <Resizable
                            className="selectable-by-pointer"
                            ref={(ref) => {
                                let innerRef = ref?.resizable;
                                if (innerRef != null) {
                                    innerRef.setAttribute("type", typeName);
                                    if (mapElement.groupId != null)
                                        innerRef.setAttribute(
                                            "groupId",
                                            mapElement.groupId
                                        );
                                    else {
                                        innerRef.removeAttribute("groupId");
                                    }
                                    innerRef.setAttribute(
                                        "id",
                                        String(this.props.mapId)
                                    );
                                    innerRef.setAttribute(
                                        "rootDataTestId",
                                        this.props.rootDataTestId
                                    );
                                }
                            }}
                            enable={
                                this.props.live || !this.props.canWrite
                                    ? {
                                          top: false,
                                          right: false,
                                          bottom: false,
                                          left: false,
                                          topRight: false,
                                          bottomRight: false,
                                          bottomLeft: false,
                                          topLeft: false,
                                      }
                                    : {
                                          top: true,
                                          right: true,
                                          bottom: true,
                                          left: true,
                                          topRight: true,
                                          bottomRight: true,
                                          bottomLeft: true,
                                          topLeft: true,
                                      }
                            }
                            onResizeStart={(evt) => {
                                evt.stopPropagation();
                            }}
                            onResize={(_e, _direction, _ref, d) => {
                                changeElementWhenResize2(
                                    {
                                        position: mapElement.nodePosition,
                                        size: mapElement.nodeSize,
                                    },
                                    this.props.scale,
                                    _direction,
                                    d,
                                    _ref,
                                    canvasViewMode
                                );
                                this.props.onUpdateSelectionBounds?.();
                            }}
                            onResizeStop={(_e, _direction, _ref, d) => {
                                const {
                                    canvasViewMode,
                                } = this.props.canvasTreeStore;
                                this.trackNewPerformance(elements.map);
                                let newSize = getNewSizeAfterResize2(
                                    {
                                        position: mapElement.nodePosition,
                                        size: mapElement.nodeSize,
                                    },
                                    this.props.scale,
                                    _direction,
                                    d,
                                    canvasViewMode
                                );
                                this.props.canvasTreeStore.updateMapElementAction(
                                    this.props.mapId,
                                    {
                                        nodePosition: newSize.nodePosition,
                                        nodeSize: newSize.nodeSize,
                                    }
                                );
                                this.props.canvasTreeStore.updateCanvasSizeAction(
                                    {
                                        x:
                                            newSize.nodePosition[canvasViewMode]
                                                .x,
                                        y:
                                            newSize.nodePosition[canvasViewMode]
                                                .y,
                                        ...newSize.nodeSize[canvasViewMode],
                                    }
                                );
                                this.props.onResize();
                            }}
                            size={mapElementSize}
                        >
                            <MapElementView
                                onContextMenu={(evt) => {
                                    this.props.onContextMenu(
                                        evt,
                                        {
                                            id: this.props.mapId,
                                            type: typeName,
                                        },
                                        true
                                    );
                                }}
                                canvasTreeStore={this.props.canvasTreeStore}
                                scale={this.props.scale}
                                sharedPolicy={this.props.sharedPolicy}
                                frozen={!this.props.canWrite}
                                height={mapElementSize.height}
                                live={this.props.live}
                                mapElement={mapElement}
                                mapElementId={this.props.mapId}
                                onChange={this.changeMapElement}
                                onTrackNewPerformance={this.trackNewPerformance}
                                onDelete={() => {
                                    this.trackNewPerformance(elements.map);
                                    this.props.showDeletePopup(() => {
                                        this.props.onClearEditing();
                                        this.props.canvasTreeStore.deleteMapElementAction(
                                            this.props.mapId
                                        );
                                    });
                                }}
                                currentModuleId={this.props.currentModuleId}
                                toggleOpenEditMenu={
                                    this.props.toggleOpenEditMenu
                                }
                                htmlElementsRootRef={
                                    this.props.htmlElementsRootRef
                                }
                                hovered={this.state.hovered}
                                data={this.state.data}
                                heatMapData={this.state.heatMapData}
                                loading={this.state.updating}
                            />
                        </Resizable>
                    </div>
                </DraggableWithSnapping>
                {!this.props.live && (
                    <>
                        <EditMenu
                            canvasTreeStore={this.props.canvasTreeStore}
                            currentModuleId={this.props.currentModuleId}
                            mapElement={mapElement}
                            mapElementId={this.props.mapId}
                            onTrackNewPerformance={this.trackNewPerformance}
                            scale={this.props.scale}
                            visible={this.props.editMenuIsOpen}
                            closeEditMenu={() => {
                                this.props.toggleOpenEditMenu(this.props.mapId);
                            }}
                            onChange={this.changeMapConfig}
                            data={this.state.data}
                        />
                        <TablePreview
                            mapElement={mapElement}
                            currentModuleId={this.props.currentModuleId}
                            editMenuIsOpen={this.props.editMenuIsOpen}
                            fetchingData={this.state.updating}
                        />
                    </>
                )}
            </>
        );
    }
}

interface WrapperState {
    currentEditedMapId?: string;
}

interface WrapperProps extends HtmlElementProps {
    htmlElementsRootRef: HTMLDivElement | undefined;
}

@observer
class MapElements extends Component<WrapperProps, WrapperState> {
    constructor(props: InnerProps) {
        super(props);

        this.state = {
            currentEditedMapId: undefined,
        };
    }

    toggleOpenEditMenu = (mapId: string) => {
        this.setState((prev) => ({
            currentEditedMapId:
                prev.currentEditedMapId && mapId === prev.currentEditedMapId
                    ? undefined
                    : mapId,
        }));
    };

    public render(): JSX.Element {
        const { currentEditedMapId } = this.state;

        let mapUIs: JSX.Element[] = [];
        for (let mapId of this.props.canvasTreeStore.mapElementsState.keys()) {
            let mapElement = this.props.canvasTreeStore.mapElementsState.get(
                mapId
            )!;
            if (mapElement.version !== MapVersion.Second) continue;
            mapUIs.push(
                <MapElementWrapper
                    outdated={this.props.canvasTreeStore.canvasOutdatedMaps.has(
                        mapId
                    )}
                    rootDataTestId={`mapElementV2-${mapUIs.length + 1}`}
                    key={mapId}
                    mapId={mapId}
                    toggleOpenEditMenu={this.toggleOpenEditMenu}
                    editMenuIsOpen={currentEditedMapId === mapId}
                    {...this.props}
                />
            );
        }
        return <>{mapUIs}</>;
    }
}

export default MapElements;
