import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import Instrumentation from "common/Instrumentation";
import UpdateStatusAlert from "../../common/UpdateStatusAlert";
import UpdateStatus from "../../common/UpdateStatus";
import {
    SelfSignupType,
    selfSignupTypes,
    setSelfSignupDefaultUserType,
    getSelfSignupDefaultUserType,
} from "./api";
import { UserType, UserTypeOptions } from "common/UserInfo";
import customSelectStyles from "common/SelectStyles";

const selfSignupTypeToLabel: Readonly<{ [key in SelfSignupType]: string }> = {
    shared_module: "Kits",
    app_self_signup_link: "Permanent Self-Signup Links for Apps",
    app_email_invitation: "Email Invitations to Apps",
    user_module_email_invitation: "Email Invitations to Presentations",
};

interface State {
    status: UpdateStatus;
    errorMessage: string;
    selfSignupUserType: {
        [key in SelfSignupType]: UserType | null;
    };
}

class MainComponent extends Component<{}, State> {
    private performance: Date | null;

    constructor(props: {}) {
        super(props);
        this.state = {
            selfSignupUserType: Object.fromEntries(
                selfSignupTypes.map((selfSignupType): [
                    SelfSignupType,
                    UserType | null
                ] => [selfSignupType, null])
            ) as State["selfSignupUserType"],
            status: UpdateStatus.NotUploaded,
            errorMessage: "",
        };
        this.performance = null;
        this.setUserType = this.setUserType.bind(this);
    }

    public componentDidMount(): void {
        getSelfSignupDefaultUserType(selfSignupTypes)
            .then((defaultUserTypes) => {
                this.setState((state) => ({
                    selfSignupUserType: {
                        ...state.selfSignupUserType,
                        ...defaultUserTypes,
                    },
                }));
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    status: UpdateStatus.Error,
                    errorMessage: error.toString(),
                });
            });
    }

    public componentDidUpdate(): void {
        if (this.performance != null) {
            let timeMs: number =
                new Date().getTime() - this.performance.getTime();
            this.performance = null;
            Instrumentation.addInteraction("Settings", timeMs);
        }
    }

    private setUserType(selfSignupType: SelfSignupType): void {
        let newValue = this.state.selfSignupUserType[selfSignupType];
        if (newValue != null) {
            this.performance = new Date();
            setSelfSignupDefaultUserType(newValue, selfSignupType)
                .then(() => {
                    this.setState({
                        status: UpdateStatus.Success,
                        errorMessage: "Default type has been changed",
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        status: UpdateStatus.Error,
                        errorMessage: error.toString(),
                    });
                });
        }
    }

    public render(): JSX.Element {
        return (
            <div style={{ marginTop: 20 }} className="flex-simple-column">
                <span className="big-title-span">Default User Types</span>
                {selfSignupTypes.map((selfSignupType) => (
                    <div
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span className="regular-text" style={{ width: 250 }}>
                            {selfSignupTypeToLabel[selfSignupType]}
                        </span>
                        <Select
                            filterOption={createFilter({
                                ignoreAccents: false,
                            })}
                            placeholder={""}
                            styles={{
                                ...customSelectStyles,
                                container: (base) => ({
                                    ...base,
                                    marginLeft: "20px",
                                    height: "38px",
                                    width: "140px",
                                }),
                            }}
                            options={UserTypeOptions}
                            value={UserTypeOptions.find(
                                (item) =>
                                    item.value ===
                                    this.state.selfSignupUserType[
                                        selfSignupType
                                    ]
                            )}
                            onChange={(newValue) => {
                                let newType = (newValue as {
                                    label: string;
                                    value: UserType;
                                }).value;
                                this.setState((state) => ({
                                    selfSignupUserType: {
                                        ...state.selfSignupUserType,
                                        [selfSignupType]: newType,
                                    },
                                }));
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    text: "white",
                                    primary25:
                                        "var(--selectors-background-hover-color)",
                                },
                            })}
                        />
                        <Button
                            disabled={this.state.selfSignupUserType == null}
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                marginLeft: 10,
                                width: 100,
                                height: 38,
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                            onClick={() => this.setUserType(selfSignupType)}
                        >
                            Change
                        </Button>
                    </div>
                ))}

                <UpdateStatusAlert
                    value={this.state.status}
                    onChange={(status) => {
                        this.setState({ status: status });
                    }}
                    errorMessage={this.state.errorMessage}
                />
            </div>
        );
    }
}

export { MainComponent };
export let requirePermission = "ChangeDefaultUserTypeForKit";
