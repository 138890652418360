import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { HistogramFinding } from "common/Finding";
import { updateOriginalNames } from "../../common/Utils";

class Api {
    static getDefaultConfig(journeyName: string): HistogramFinding["config"] {
        return {
            count: 100,
            random: false,
            journeyName: journeyName,
            version: configVersionV2,
            showXAxisName: true,
            showYAxisName: true,
            showGrid: false,
        };
    }

    static getPreviewFinding(journeyName: string): HistogramFinding {
        let item = {
            type: "histogram",
            content: {
                data: [
                    {
                        name: "value",
                        value: [0, 10, 20, 30, 40, 50],
                    },
                    {
                        name: "count",
                        value: [50, 60, 70, 50, 40],
                    },
                ],
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }
    static getData(
        finding: HistogramFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<HistogramFinding> {
        let { dataScope, bins, selectedTable, conditions } =
            finding.config ?? {};
        conditions = filterNonEmptyConditions(conditions ?? []);

        let requestJson = {
            variable_indices: [finding.content.data[0].variableIndex],
            bins: bins ?? 10,
            conditions: conditionsToJson(conditions),
            table: selectedTable.value,
            condition_id: selectedTable.condition_id,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope.value,
            module_id: moduleId,
        };

        return axios
            .post<{
                data: {
                    "%bin_edges": number[];
                    "%hist": number[];
                    "%is_datetime": boolean;
                };
                success: boolean;
                error_msg: string;
            }>("/api/e/scatterplot_exploration", requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newFinding = {
                        ...finding,
                        content: {
                            ...finding.content,
                            data: [...finding.content.data],
                        },
                    };
                    newFinding.content.data[0] = {
                        ...newFinding.content.data[0],
                        value: response.data.data["%bin_edges"],
                    };
                    newFinding.content.data[1] = {
                        ...newFinding.content.data[1],
                        value: response.data.data["%hist"],
                    };
                    newFinding.content.isDateTime =
                        response.data.data["%is_datetime"];

                    // Check wherther variables were renamed
                    updateOriginalNames(
                        newFinding.content.data,
                        dataScope.value,
                        moduleId
                    );
                    if (newFinding.content.horizontalLines != null) {
                        updateOriginalNames(
                            newFinding.content.horizontalLines!,
                            dataScope.value,
                            moduleId
                        );
                    }

                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

export { Api };
