import React from "react";
import Select, { createFilter } from "react-select";
import customSelectStyles from "common/SelectStyles";


export default function DataScopesSelector(props) {
	let options = props.options;
	return (
		<div
			className="flex-simple-column"
			style={{ marginTop: 25, marginLeft: 100 }}
			onKeyDown={(evt) => {
				evt.stopPropagation();
			}}
		>
			<div className="my-row">
				<span className="exploration-big-title-span">{props.title}</span>
				<div style={{ width: 200, marginLeft: 20 }}>
					<Select
						filterOption={createFilter({
							ignoreAccents: false,
						})}
						placeholder={""}
						styles={{
							...customSelectStyles,
							container: (base) => ({
								...base,
								width: "25em",
								height: "38px",
								marginRight: "5px",
							}),
						}}
						options={options}
						onChange={(newValue) => props.onChange(newValue)}
						value={props.value}
						theme={(theme) => ({
							...theme,
							borderRadius: 0,
							colors: {
								...theme.colors,
								text: "white",
								primary25: "var(--selectors-background-hover-color)",
							},
						})}
					/>
				</div>
				<div style={{ height: 250 }} />
			</div>
		</div>
	);
}