import React, { Component } from "react";

import {
    Area,
    ComposedChart,
    Legend,
    Line,
    ReferenceArea,
    ReferenceLine,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";
import { mainStyle } from "common/MainStyle";
import {
    SingleLineAxisTick,
    MultiLineAxisTick,
} from "common/graphics/AxesTicks";
import { TooltipStyles } from "common/graphics/TooltipStyles";

interface Props {
    data: Array<{ [key: string]: number } & { name: string }>;
    lineKeys: string[];
    lineColors: string[];
    serverDown: boolean[];
    areaColor: string;
    title: string;
}

class TimePlot extends Component<Props> {
    private buildTimeTrendPlot(): JSX.Element {
        let lines: JSX.Element[] | null = null; // Plot lines
        let noDataText: JSX.Element | null = null; // Will be not null if no data selected

        if (this.props.data.length === 0 || this.props.lineKeys.length === 0) {
            noDataText = (
                <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill="white"
                    fontSize="2rem"
                >
                    NO DATA FOR SELECTED FILTERS
                </text>
            );
        }
        // If data is not empty, then add lines to the plot
        else {
            // scale="point" is necessary to align Line and ReferenceArea
            lines = [
                <XAxis
                    tickLine={false}
                    axisLine={false}
                    dataKey="name"
                    tick={<MultiLineAxisTick dx={16} dy={8} angle={0} />}
                    scale="point"
                />,
                <YAxis
                    tick={<SingleLineAxisTick dx={0} dy={0} angle={0} />}
                    tickLine={false}
                    axisLine={false}
                />,
            ];

            // domain={[
            //     (dataMin) => Math.round(dataMin - dataMin * 0.2),
            //     (dataMax) => Math.round(dataMax + dataMax * 0.2),
            // ]}

            // If there is only 1 row, then show dots instead of areas
            let dot: (color: string) =>
                | boolean
                | {
                      fill: string;
                      strokeWidth: number;
                      r: number;
                  } = (_color) => false;
            if (this.props.data.length === 1) {
                dot = (color) => ({
                    fill: color,
                    strokeWidth: 0,
                    r: 5,
                });
            }

            for (let i = 0; i < this.props.lineKeys.length; ++i) {
                lines.push(
                    <Line
                        type="monotone"
                        legendType="plainline"
                        isAnimationActive={false}
                        dataKey={this.props.lineKeys[i]}
                        dot={dot(this.props.lineColors[i])}
                        stroke={this.props.lineColors[i]}
                        strokeWidth="2"
                    />
                );
            }
            // strokeDasharray="5 5"

            let beginIndex: number | null = null;
            this.props.serverDown.forEach((value, index) => {
                if (beginIndex == null) {
                    if (value) {
                        // type="monotone"
                        // isAnimationActive={false}
                        beginIndex = index;
                        if (index === this.props.serverDown.length - 1) {
                            lines!.push(
                                <ReferenceLine
                                    stroke={this.props.areaColor}
                                    fill={this.props.areaColor}
                                    x={this.props.data[beginIndex].name}
                                    strokeWidth="2"
                                    alwaysShow
                                />
                            );
                        }
                    }
                } else {
                    if (!value) {
                        // type="monotone"
                        // isAnimationActive={false}
                        if (index - 1 === beginIndex) {
                            lines!.push(
                                <ReferenceLine
                                    stroke={this.props.areaColor}
                                    fill={this.props.areaColor}
                                    x={this.props.data[beginIndex].name}
                                    strokeWidth="2"
                                    alwaysShow
                                />
                            );
                        } else {
                            // type="monotone"
                            // isAnimationActive={false}
                            lines!.push(
                                <ReferenceArea
                                    stroke={this.props.areaColor}
                                    fill={this.props.areaColor}
                                    x1={this.props.data[beginIndex].name}
                                    x2={this.props.data[index - 1].name}
                                    alwaysShow
                                />
                            );
                        }
                        beginIndex = null;
                    } else if (index === this.props.serverDown.length - 1) {
                        lines!.push(
                            <ReferenceArea
                                stroke={this.props.areaColor}
                                fill={this.props.areaColor}
                                x1={this.props.data[beginIndex].name}
                                alwaysShow
                            />
                        );
                        beginIndex = null;
                    }
                }
            });
        }
        const titleSize: string = "12px";
        let plot = (
            <div
                className="flex-column"
                style={{ alignItems: "center", height: "100%" }}
            >
                <span
                    style={{
                        fontFamily: "Arial",
                        fontSize: titleSize,
                        color: mainStyle.getPropertyValue(
                            "--secondary-text-color"
                        ),
                        display: "block",
                        textAlign: "center",
                    }}
                >
                    {this.props.title}
                </span>
                <div
                    className="my-row center-container"
                    style={{ width: "100%", height: "100%" }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <ResponsiveContainer height="100%">
                            <ComposedChart data={this.props.data}>
                                <CartesianGrid
                                    strokeDasharray="3 3"
                                    stroke={mainStyle.getPropertyValue(
                                        "--graphs-stroke-color"
                                    )}
                                    strokeWidth="2"
                                />
                                <RechartsTooltip {...TooltipStyles()} />
                                {noDataText}
                                {lines}
                                {/* This area is here because ReferenceArea cannot have a legend entry */}
                                <Area
                                    legendType="rect"
                                    isAnimationActive={false}
                                    dataKey=""
                                    name="Server Down"
                                    stroke={this.props.areaColor}
                                    fill={this.props.areaColor}
                                />
                                <Legend
                                    formatter={(value, _entry) => (
                                        <span
                                            style={{
                                                color: mainStyle.getPropertyValue(
                                                    "--primary-text-color"
                                                ),
                                            }}
                                        >
                                            {value}
                                        </span>
                                    )}
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        );
        return plot;
    }

    public render(): JSX.Element {
        return this.buildTimeTrendPlot();
    }
}

export function toISODate(date: Date): string {
    let str: string = date.toISOString();
    return str.substring(0, str.indexOf("T"));
}

export function formatTimePlot(
    timePlotData: number[][],
    uptimeData: number[],
    variables: string[]
): {
    timePlot: Array<{ [key: string]: number } & { name: string }>;
    serverDown: boolean[];
} {
    let timePlot: Array<{ [key: string]: number } & { name: string }> = [];
    let serverDown: boolean[] = [];
    let zeros: { [key: string]: number } = {};
    for (let variable of variables) zeros[variable] = 0;
    if (timePlotData.length > 0) {
        let date: Date = new Date();
        let currentDate: Date = new Date();
        date.setTime(Math.floor(timePlotData[0][0] * 1000) || 0);
        let currentIndex: number = 0;
        let currentUptimeIndex: number = 0;
        let currentUptime: Date = new Date();
        currentUptime.setTime(
            Math.floor(uptimeData[currentUptimeIndex] * 1000) || 0
        );
        let currentTimestamp: number;
        let currentValues: number[];
        let currentValuesObject: { [key: string]: number };

        const pushDate = (values: { [key: string]: number }) => {
            if (
                currentUptimeIndex < uptimeData.length &&
                toISODate(date) === toISODate(currentUptime)
            ) {
                serverDown.push(false);
                currentUptimeIndex += 1;
                currentUptime.setTime(
                    Math.floor(uptimeData[currentUptimeIndex] * 1000) || 0
                );
            } else {
                serverDown.push(true);
            }

            timePlot.push({
                ...values,
                name: toISODate(date),
            } as { [key: string]: number } & { name: string });
            // Add 1 day
            date.setDate(date.getDate() + 1);
        };
        while (currentIndex < timePlotData.length) {
            [currentTimestamp, ...currentValues] = timePlotData[currentIndex];
            currentValuesObject = {};
            for (let i in variables) {
                currentValuesObject[variables[i]] = currentValues[i];
            }
            currentDate.setTime(Math.floor(currentTimestamp * 1000) || 0);
            while (toISODate(date) !== toISODate(currentDate)) {
                pushDate(zeros);
            }
            pushDate(currentValuesObject);
            currentIndex += 1;
        }
        return {
            timePlot: timePlot,
            serverDown: serverDown,
        };
    } else {
        return {
            timePlot: [],
            serverDown: [],
        };
    }
}

export default TimePlot;
