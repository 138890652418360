import { action, makeObservable, observable } from "mobx";
import { Collection, CollectionType, getCollectionsApi, getPresetsApi, Preset } from "./MediaCollectionsApi";

class Presets {
    @observable public presets: {[key: number]: Preset[]} = {};
    @observable public presetCollections: Collection[] = [];

    constructor() {
        makeObservable(this);
    }

    @action.bound
    assignPresets(presets:  {[key: number]: Preset[]}, collections: Collection[]) {
        this.presets = presets;
        this.presetCollections = collections;
    }
    @action.bound
    deletePreset(presetId: number, collectionId: number) {
        let presets = {...this.presets};
        presets[collectionId] = presets[collectionId].filter(preset => preset.id !== presetId);
        this.presets = presets;
    }

    public async update(): Promise<void> {
        try {
            let allPresets: {[key: number]: Preset[]} = {};
            let presetCollections = await getCollectionsApi(CollectionType.Text);
            for (let presetCollection of presetCollections) {
                let presets = await getPresetsApi(presetCollection.id, false);
                allPresets[presetCollection.id] = presets;
            }
            this.assignPresets(allPresets, presetCollections);
        } catch (error) {
            return;
        }
    }
}

export default new Presets();
