import React from "react";
import DraggableWithSnapping from "common/DraggableWithSnapping";
import { observer } from "mobx-react";
import CanvasInteractionComponent from "./CanvasInteractionComponent";
import elements from "common/CanvasElements";
import { CanvasButtonType, InnerCanvasChanges } from "common/Canvas";
import HtmlElementProps from "./HtmlElementProps";
import { BackgroundMode } from "common/CanvasUserApi";
import { snapElementToPoints } from "../Snap";

const typeName = "buttonsState";

interface ButtonProps extends HtmlElementProps {
    onButtonClick: (buttonType: CanvasButtonType) => void;
}

interface Props extends ButtonProps {
    buttonId: string;
    rootDataTestId: string;
}
interface State {}

@observer
class CanvasButtonUi extends CanvasInteractionComponent<Props, State> {
    private drag: boolean;

    constructor(props: Props) {
        super(props);
        this.drag = false;
    }

    public render(): JSX.Element {
        const { canvasViewMode } = this.props.canvasTreeStore;
        let button = this.props.canvasTreeStore.buttonsState.get(
            this.props.buttonId
        )!;
        return (
            <DraggableWithSnapping
                key={this.props.buttonId}
                disabled={this.props.live || !this.props.canWrite}
                position={{
                    x: button.nodePosition[canvasViewMode].x * this.props.scale,
                    y: button.nodePosition[canvasViewMode].y * this.props.scale,
                }}
                onDrag={(_evt, _data) => {
                    this.drag = true;
                    let width =
                        button.nodeSize[canvasViewMode].width *
                        this.props.scale;
                    let height =
                        button.nodeSize[canvasViewMode].height *
                        this.props.scale;
                    let nearestPoints = this.props.onRebuildSnapLine(
                        {
                            x: _data.x,
                            y: _data.y,
                            width: width,
                            height: height,
                        },
                        {
                            type: typeName,
                            id: this.props.buttonId,
                            groupId: button.groupId,
                        }
                    );
                    this.props.onUpdateSelectionBounds?.();
                    let newPosition = snapElementToPoints(
                        width,
                        height,
                        nearestPoints
                    );
                    if (newPosition.x != null || newPosition.y != null) {
                        // Snap to this position
                        return newPosition;
                    }
                }}
                onStop={(_evt, data) => {
                    if (this.drag) {
                        this.props.onDeleteSnapLine();
                        this.trackNewPerformance(elements.map);
                        let x = Math.max(data.x / this.props.scale, 0);
                        let y = Math.max(data.y / this.props.scale, 0);
                        let deltaX = x - button.nodePosition[canvasViewMode].x;
                        let deltaY = y - button.nodePosition[canvasViewMode].y;

                        const nodePosition = {
                            ...button.nodePosition,
                            [canvasViewMode]: {
                                x,
                                y,
                            },
                        };

                        let changes: InnerCanvasChanges = {};
                        this.props.canvasTreeStore.updateCanvasButtonAction(
                            this.props.buttonId,
                            { nodePosition },
                            changes
                        );
                        this.props.onMoveGroupSelection(
                            deltaX,
                            deltaY,
                            {
                                id: this.props.buttonId,
                                type: typeName,
                                groupId: button.groupId,
                            },
                            false,
                            changes
                        );
                        this.props.canvasTreeStore.saveChangesAction(
                            changes,
                            true,
                            true,
                            false,
                            this.props.canvasTreeStore.backgroundsState.toJSON(),
                            BackgroundMode.Update,
                            false
                        );
                        this.drag = false;
                    }
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        zIndex: button.zIndex ?? 50,
                        width:
                            button.nodeSize[canvasViewMode].width *
                            this.props.scale,
                        height:
                            button.nodeSize[canvasViewMode].height *
                            this.props.scale,
                    }}
                    onContextMenu={(evt) => {
                        this.props.onContextMenu(
                            evt,
                            {
                                id: this.props.buttonId,
                                type: typeName,
                            },
                            true
                        );
                    }}
                    className="selectable-by-pointer"
                    ref={(ref) => {
                        let innerRef = ref;
                        if (innerRef != null) {
                            innerRef.setAttribute("type", typeName);
                            if (button.groupId != null)
                                innerRef.setAttribute(
                                    "groupId",
                                    button.groupId
                                );
                            else {
                                innerRef.removeAttribute("groupId");
                            }
                            innerRef.setAttribute(
                                "id",
                                String(this.props.buttonId)
                            );
                            innerRef.setAttribute(
                                "data-test-id",
                                this.props.rootDataTestId
                            );
                        }
                    }}
                    onClick={(e) => {
                        if (this.props.live) {
                            this.trackNewPerformance(elements.dataInputButton);
                            this.props.onButtonClick(button.buttonType);
                            return;
                        }
                        if (!this.props.canWrite) return;
                        this.trackNewPerformance(elements.dataInputButton);
                    }}
                >
                    <img
                        alt=""
                        src="/dist/img/data/add_data_floating.png"
                        width="100%"
                        height="100%"
                        style={{
                            pointerEvents: "none",
                        }}
                    />
                </div>
            </DraggableWithSnapping>
        );
    }
}

export default observer(function CanvasButtons(props: ButtonProps) {
    let buttonUis: JSX.Element[] = [];
    for (let buttonId of props.canvasTreeStore.buttonsState.keys()) {
        buttonUis.push(
            <CanvasButtonUi
                rootDataTestId={`inputButton-${buttonUis.length + 1}`}
                key={buttonId}
                buttonId={buttonId}
                {...props}
            />
        );
    }
    return <>{buttonUis}</>;
});
