import React from "react";

import { MapElement } from "common/Canvas";
import { TooltipContent } from "modules/canvas_page/MapElementView";
import VariablesStyleConfig from "./VariablesStyleConfig";

import styles from "./TooltipConfig.module.css";

interface Props {
  mapElement: MapElement;
  mapElementId: string;
  onChange: (value: Partial<MapElement>) => void;
  onTrackNewPerformance: (element: string) => void;
  data: { [key: string]: Array<number | string | null> } | null;
}

function TooltipConfig({ mapElement, mapElementId, onChange, onTrackNewPerformance, data }: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.tooltipArea}>
        <TooltipContent
          scale={1.5}
          editable
          onTrackNewPerformance={onTrackNewPerformance}
          onChange={onChange}
          mapElement={mapElement}
          mapElementId={mapElementId}
          variables={mapElement.tooltipVariables ?? []}
          options={mapElement.tooltipOptions}
          data={data}
          dataIndex={0}
          className={styles.tooltipEdit}
        />
      </div>
      <VariablesStyleConfig
        variables={mapElement.tooltipVariables ?? []}
        onChange={(newOptions) => {
          onChange({ tooltipVariables: newOptions });
        }}
      />
    </div>
  );
}

export default TooltipConfig;
