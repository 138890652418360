import React from "react";

import {
    choroplethVariablesToTooltipVariables,
    MapFinding,
} from "common/Finding";
import VariablesStyleConfig from "./VariablesStyleConfig";

import styles from "./TooltipConfig.module.css";
import commonStyles from "../../StylingSection.module.css";
import { VariableOption } from "common/Variables";
import Input from "../../Input";
import ColorPicker from "common/ColorPicker";
import { TooltipContent } from "common/MapElementView/components/TooltipContent";

interface Props {
    variableOptions: VariableOption[];
    mapFinding: MapFinding;
    mapId: string;
    onChange: (config: MapFinding["config"], update?: boolean) => void;
}

function TooltipConfig({
    mapFinding,
    onChange,
    mapId,
    variableOptions,
}: Props) {
    let isChoropleth = mapFinding.type === "maps_choropleth";
    let customTooltipVariables = undefined;
    if (isChoropleth) {
        customTooltipVariables = {
            tooltipVariables: choroplethVariablesToTooltipVariables(mapFinding),
        };
    }

    return (
        <div className={styles.root}>
            <div className={styles.tooltipArea}>
                <TooltipContent
                    scale={1.5}
                    editable
                    onChange={(newFinding) => {
                        if (!isChoropleth) {
                            onChange(newFinding.config);
                        } else {
                            let updatedConfig = newFinding.config;
                            let newConfig = {
                                ...mapFinding.config,
                            };
                            newConfig.choroplethMetricVariableOptions =
                                updatedConfig.tooltipVariables[0].options;
                            newConfig.choroplethCountryVariableOptions =
                                updatedConfig.tooltipVariables[1].options;
                            newConfig.tooltipOptions =
                                updatedConfig.tooltipOptions;
                            onChange(newConfig);
                        }
                    }}
                    mapFinding={{
                        ...mapFinding,
                        config: {
                            ...mapFinding.config,
                            ...customTooltipVariables,
                        },
                    }}
                    mapId={mapId}
                    variables={
                        isChoropleth
                            ? choroplethVariablesToTooltipVariables(mapFinding)
                            : mapFinding.config.tooltipVariables ?? []
                    }
                    options={mapFinding.config.tooltipOptions}
                    data={isChoropleth ? {} : mapFinding.content.data ?? {}}
                    dataIndex={0}
                    className={styles.tooltipEdit}
                />
            </div>
            <div className={commonStyles.optionContainer}>
                <span
                    className={commonStyles.optionName}
                    style={{ margin: "0 10px 0 0" }}
                >Tooltip corner radius</span>
                <Input
                    value={
                        mapFinding.config.tooltipBorderRadius ?? 0
                    }
                    onChange={(value: string | number) => {
                        value = Number(value);
                        let newConfig = {
                            ...mapFinding.config,
                            tooltipBorderRadius: value,
                        };
                        onChange(newConfig);
                    }}
                />
                <div
                    className={commonStyles.optionContainer}
                    style={{ margin: "0 0 0 5px" }}
                >
                    <span
                        className={commonStyles.optionName}
                        style={{ minWidth: "auto", margin: "0 10px 0 0" }}
                    >Background color</span>
                    <ColorPicker
                        inPopup
                        enableAlpha
                        value={mapFinding.config.tooltipOptions?.fillColor ?? "white"}
                        onChange={(value) => {
                            onChange({
                                ...mapFinding!.config,
                                tooltipOptions: {
                                    fillColor: value,
                                },
                            });
                        }}
                    />
                </div>
            </div>
            
            <VariablesStyleConfig
                variableOptions={variableOptions}
                isChoropleth={isChoropleth}
                variables={
                    isChoropleth
                        ? choroplethVariablesToTooltipVariables(mapFinding)
                        : mapFinding.config.tooltipVariables ?? []
                }
                onChange={(newOptions) => {
                    let changes = {};
                    if (isChoropleth) {
                        let choroplethMetricOptions = newOptions[0]?.options;
                        let choroplethCountryOptions = newOptions[1]?.options;
                        changes = {
                            choroplethMetricVariableOptions:
                                choroplethMetricOptions,
                            choroplethCountryVariableOptions:
                                choroplethCountryOptions,
                        };
                    } else {
                        changes = {
                            tooltipVariables: newOptions,
                        };
                    }

                    onChange(
                        {
                            ...mapFinding.config,
                            ...changes,
                        },
                        true
                    );
                }}
            />
        </div>
    );
}

export default TooltipConfig;
