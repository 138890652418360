import React from "react";
import { mainStyle } from "common/MainStyle";
import StringUtils from "common/utilities/StringUtils";

interface Finding {
	config: {
		linesCount?: number | null;
		maxYRange?: number | null;
		minYRange?: number | null;
	};
}
interface Props {
	value: Finding;
	onChange: (value: Finding) => void;
}

export default function RangeSelector(props: Props) {
	return (
		<React.Fragment>
			<span
				style={{
					marginLeft: "20px",
					whiteSpace: "nowrap",
					fontFamily: "Roboto",
					fontSize: "10px",
					color: mainStyle.getPropertyValue("--exploration-tertiary-text-color"),
					fontWeight: 300,
				}}
			>
				{"Specify Y-range:"}
			</span>
			<input
				style={{ marginLeft: 10, width: "100px" }}
				className="like-select"
				type="number"
				size={5}
				onChange={(evt) => {
					let newFinding = { ...props.value };
					let value = evt.target.value;
					newFinding.config.minYRange = StringUtils.isNumber(value)
						? Number(value)
						: null;
					props.onChange(newFinding);
				}}
				value={props.value.config.minYRange ?? ""}
			/>
			<span
				style={{
					marginLeft: 10,
					fontFamily: "Arial",
					fontSize: "10px",
					color: mainStyle.getPropertyValue(
						"--exploration-primary-text-color"
					),
				}}
			>
				{"-"}
			</span>
			<input
				style={{ marginLeft: 10, width: "100px" }}
				className="like-select"
				type="number"
				size={5}
				onChange={(evt) => {
					let newFinding = { ...props.value };
					let value = evt.target.value;
					newFinding.config.maxYRange = StringUtils.isNumber(value)
						? Number(value)
						: null;
					props.onChange(newFinding);
				}}
				value={props.value.config.maxYRange ?? ""}
			/>
			{/* <span
				style={{
					marginLeft: "20px",
					whiteSpace: "nowrap",
					fontFamily: "Roboto",
					fontSize: "10px",
					color: mainStyle.getPropertyValue("--exploration-tertiary-text-color"),
					fontWeight: 300,
				}}
			>
				{"Lines count:"}
			</span>
			<input
				style={{ marginLeft: 10, width: "100px" }}
				className="like-select"
				type="number"
				size={5}
				onChange={(evt) => {
					let newFinding = { ...props.value };
					let value = evt.target.value;
					newFinding.config.linesCount = StringUtils.isNumber(value)
						? Number(value)
						: null;
					props.onChange(newFinding);
				}}
				value={props.value.config.linesCount ?? ""}
			/> */}
		</React.Fragment>
	);
}