import React from "react";
import { mainStyle } from "common/MainStyle";
import sections from "sections.json";
import CustomBarChart from "./CustomBarChart";
import { Element } from "react-scroll";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import { LegendEditElement } from "./LegendEditElement";

let defaultColors = ["#FFAB4F"];
type AnalysisItem = Array<{ name: string } & { [key: string]: number }>;

interface Props {
	data: AnalysisItem;
	config: {
		xAxisName?: string;
		yAxisName?: string;
		axesColor?: string;
		barColor?: string;
		chartColor?: string;
		linesCount?: number;
		maxYRange?: number;
		minYRange?: number;
		barSize?: number;
		colorMapping?: { [key: string]: string };
	};
	editable?: boolean;
	onConfigChange?: (key: string, value: any) => void;
}

const sentimentsMapping: { [key: string]: string } = {
	neu: "Neutral",
	neg: "Negative",
	pos: "Positive",
	compound: "Compound",
};
export default function SentimentAnalysisChart(props: Props) {
	let { data, config, editable } = props;
	let {
		colorMapping,
		barColor,
		barSize,
		xAxisName,
		yAxisName,
		maxYRange,
		minYRange,
		linesCount,
		axesColor,
	} = config;
	return (
		<div
			className="flex-simple-column"
			style={{ width: "100%", height: "100%", margin: "0 auto" }}
		>
			{editable && (
				<div
					className="my-row"
					style={{
						flexWrap: "wrap",
						justifyContent: "center",
						marginLeft: 20,
						marginRight: 20,
						marginTop: 10,
						width: "100%",
						minHeight: 16,
					}}
				>
					{editable && (
						<LegendEditElement
							allowColorChange
							disallowTextChange
							onFinishColor={(color: string) => {
								props.onConfigChange?.("axesColor", color);
							}}
							color={
								axesColor ??
								mainStyle
									.getPropertyValue(
										"--graphs-axes-text-color"
									)
									.trim()
							}
							text={"Axes Color"}
						/>
					)}
					{editable && (
						<LegendEditElement
							allowColorChange
							disallowTextChange
							onFinishColor={(color: string) => {
								props.onConfigChange?.("barColor", color);
							}}
							color={barColor ?? defaultColors[0]}
							text={"Bar color"}
						/>
					)}
					{editable &&
						data.length > 0 &&
						data.map((item, index) => (
							<LegendEditElement
								key={index}
								allowColorChange
								disallowTextChange
								onFinishColor={(color: string) => {
									let newColorMapping = {
										...colorMapping,
										[item.name]: color,
									};
									props.onConfigChange?.(
										"colorMapping",
										newColorMapping
									);
								}}
								color={
									colorMapping?.[item.name] ??
									defaultColors[0]
								}
								text={`${
									sentimentsMapping[item.name] ?? item.name
								} color`}
							/>
						))}
				</div>
			)}
			<div
				className="my-row"
				style={{
					justifyContent: "center",
					height: "100%",
					width: "100%",
				}}
			>
				<Element
					name="scrollable"
					className="element"
					style={{
						height: "100%",
						paddingTop: 10,
						overflowX: mobileBreakpoint() ? "hidden" : "auto",
						overflowY: "hidden",
						backgroundColor: "transparent",
						position: "relative",
					}}
				>
					<div className="my-row" style={{ height: "100%" }}>
						{editable ? (
							<input
								defaultValue={yAxisName}
								onKeyDown={(evt) => {
									evt.stopPropagation();
								}}
								placeholder="Enter Y-Axis Name"
								onBlur={(e) => {
									e.preventDefault();
									props.onConfigChange?.(
										"yAxisName",
										e.target.value
									);
								}}
								style={{
									width: "110px",
									alignSelf: "center",
									backgroundColor: "transparent",
									outline: "none",
									color:
										axesColor ??
										mainStyle.getPropertyValue(
											"--graphs-axes-text-color"
										),
									fontFamily: "Arial",
									fontSize: mainStyle.getPropertyValue(
										"--graphs-axes-size"
									),
								}}
							></input>
						) : yAxisName != null ? (
							<span
								style={{
									alignSelf: "center",
									textAlign: "right",
									color:
										axesColor ??
										mainStyle.getPropertyValue(
											"--graphs-axes-text-color"
										),
									fontFamily: "Arial",
									fontSize: mainStyle.getPropertyValue(
										"--graphs-axes-size"
									),
								}}
							>
								{yAxisName}
							</span>
						) : null}
						<div
							className="flex-simple-column"
							style={{ height: "100%" }}
						>
							<CustomBarChart
								data={data}
								barKeys={["value"]}
								barColors={
									barColor ? [barColor] : defaultColors
								}
								labelMapping={sentimentsMapping}
								nameColorMapping={colorMapping}
								axesColor={axesColor}
								title=""
								minYRange={minYRange}
								maxYRange={maxYRange}
								linesCount={linesCount}
								barSize={barSize}
								watermark={sections._Global?.watermark}
							/>
							{editable ? (
								<input
									defaultValue={xAxisName}
									onKeyDown={(evt) => {
										evt.stopPropagation();
									}}
									placeholder="Enter X-Axis Name"
									onBlur={(e) => {
										e.preventDefault();
										props.onConfigChange?.(
											"xAxisName",
											e.target.value
										);
									}}
									style={{
										marginTop: "5px",
										width: "110px",
										alignSelf: "center",
										backgroundColor: "transparent",
										outline: "none",
										color:
											axesColor ??
											mainStyle.getPropertyValue(
												"--graphs-axes-text-color"
											),
										fontFamily: "Arial",
										fontSize: mainStyle.getPropertyValue(
											"--graphs-axes-size"
										),
									}}
								></input>
							) : xAxisName != null ? (
								<span
									style={{
										alignSelf: "center",
										textAlign: "right",
										color:
											axesColor ??
											mainStyle.getPropertyValue(
												"--graphs-axes-text-color"
											),
										fontFamily: "Arial",
										fontSize: mainStyle.getPropertyValue(
											"--graphs-axes-size"
										),
									}}
								>
									{xAxisName}
								</span>
							) : null}
						</div>
					</div>
				</Element>
			</div>
		</div>
	);
}