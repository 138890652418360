import React from "react";
import Dropzone from "react-dropzone";
import Preset from "../Preset";
import {
    Entry,
    guidanceWizardEntryGetApi,
    guidanceWizardEntryEditApi,
} from "../../Api";
import "../../main.module.css";
import styles from "./EditSession.module.css";
import { ReactComponent as UploadIcon } from "icons/guidance_wizard/upload.svg";
import { ReactComponent as CrossIcon } from "icons/canvas/themes/cross.svg";
import { resizeBase64Logo } from "common/utilities/resizeImage";

interface Props {
    entryId: number | undefined;
    preview: boolean;
}

const EditSession: React.FC<Props> = (props) => {
    const [entry, setEntry] = React.useState<Entry | undefined>(undefined);

    // Fetch the entry
    React.useEffect(() => {
        if (props.entryId != null) {
            guidanceWizardEntryGetApi(props.entryId)
                .then(setEntry)
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [props.entryId]);

    if (props.preview) {
        return (
            <div className={styles.fullScreenContainer}>
                {entry != null && (
                    <div className={styles.previewContainer}>
                        <Preset
                            entryId={entry.id}
                            dataScopeId={entry.dataScopeId}
                            title={entry.title}
                            logo={entry.logo}
                        />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={styles.fullScreenContainer}>
            <div className={styles.container}>
                <div className={styles.leftPanel}>
                    <span className={styles.leftPanelOptionTitle}>Title</span>
                    <div key={entry?.title}>
                        <input
                            placeholder=""
                            className={styles.leftPanelOptionInput}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                            }}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onKeyUp={(evt) => {
                                evt.preventDefault();
                            }}
                            onBlur={(e) => {
                                let val = e.target.value.trim();
                                if (entry != null) {
                                    setEntry((entry) => ({
                                        ...entry!,
                                        title: val,
                                    }));
                                    guidanceWizardEntryEditApi(
                                        entry.id,
                                        undefined,
                                        val
                                    );
                                }
                            }}
                            defaultValue={entry?.title}
                        />
                    </div>
                    <span className={styles.leftPanelOptionTitle}>Logo</span>
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            if (acceptedFiles.length > 0) {
                                const file = acceptedFiles[0];
                                const { type } = file;
                                if (
                                    !type.includes("image") ||
                                    type.includes("gif")
                                ) {
                                    return;
                                }
                                const reader = new FileReader();

                                reader.onload = () => {
                                    const binaryStr =
                                        typeof reader.result === "string"
                                            ? reader.result.toString()
                                            : "";

                                    //generates a 50% smaller image to save as thumbnail
                                    resizeBase64Logo(binaryStr, 100).then(
                                        (res) => {
                                            setEntry((entry) => ({
                                                ...entry!,
                                                logo: res.logo,
                                            }));
                                            guidanceWizardEntryEditApi(
                                                entry!.id,
                                                undefined,
                                                undefined,
                                                res.logo
                                            );
                                        }
                                    );
                                };

                                reader.readAsDataURL(acceptedFiles[0]);
                            }
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className={styles.logoCenterContainer}>
                                <div
                                    className={styles.logoContainer}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    {(entry?.logo == null ||
                                        entry?.logo.length === 0) && (
                                        <>
                                            <UploadIcon />
                                            <span
                                                className={
                                                    styles.leftPanelOptionTitle
                                                }
                                            >
                                                Upload Logo
                                            </span>
                                        </>
                                    )}
                                    {entry?.logo != null &&
                                        entry?.logo.length !== 0 && (
                                            <>
                                                <img
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        padding: "2px",
                                                    }}
                                                    src={entry.logo}
                                                    alt=""
                                                />
                                                <div
                                                    className={
                                                        styles.logoCrossContainer
                                                    }
                                                    onClick={(evt) => {
                                                        evt.stopPropagation();
                                                        setEntry((entry) => ({
                                                            ...entry!,
                                                            logo: null,
                                                        }));
                                                        guidanceWizardEntryEditApi(
                                                            entry!.id,
                                                            undefined,
                                                            undefined,
                                                            ""
                                                        );
                                                    }}
                                                >
                                                    <CrossIcon />
                                                </div>
                                            </>
                                        )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
                <div className={styles.monitorContainer}>
                    <div className={styles.monitor}>
                        {entry != null && (
                            <div className={styles.monitorInnerContainer}>
                                <Preset
                                    entryId={entry.id}
                                    dataScopeId={entry.dataScopeId}
                                    title={entry.title}
                                    logo={entry.logo}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.monitorStand} />
                </div>
            </div>
        </div>
    );
};

export default EditSession;
