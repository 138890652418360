import React from "react";
import OutcomesBlockV3 from "common/graphics/OutcomesBlockV3";
import LeversChartsComponent from "common/graphics/LeversChartsComponent";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";

function MainComponent(props) {
	let finding = props.finding;

	return (
		<div
			className="flex-simple-column"
			style={{
				width: "100%",
				height: mobileBreakpoint()
					? finding.content.clust.length * 300 + 50
					: "100%",
			}}
		>
			<div style={{ width: "100%", height: "100%" }}>
				<LeversChartsComponent
					clust={finding.content.clust}
					clustNames={finding.content.clustNames}
					leftBarKey="projected"
					rightBarKey="optimal"
				/>
			</div>
			<div style={{ width: "100%" }}>
				<OutcomesBlockV3
					item={finding.content.outcome}
					leftTitle="Simulated"
					rightTitle="Optimized"
					leftKey="projected"
					rightKey="optimal"
				/>
			</div>
		</div>
	);
}

export { MainComponent };