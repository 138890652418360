import React, { useEffect, useState, useMemo } from "react";

import { colorList } from "../../LineColors";
import { formatValue } from "common/utilities/FormatValue";
import { mainStyle } from "common/MainStyle";
import { TreemapFinding, getValue } from "common/Finding";
import OutsideAlerter from "common/OutsideAlerter";
import Portal from "common/Portal";
import ColorPicker from "../ChartColorPicker";
import { previewColors } from "../previewColors";
import { TooltipStyles } from "../../TooltipStyles";
import { LegendEditElement } from "../../LegendEditElement";
import { valueToTitle } from "common/AggregationFunctionsv2";
import EditableAxisItem from "../EditableAxisItem";
import * as d3 from "d3";
import {
    calculateChartColorPickerPosition,
    clipText,
    getTextWidth,
} from "../utils";

interface Datum {
    id: string;
    name: string;
    groupName?: string;
    groupIndex?: number;
    value: number | number[];
    index: number;
    globalIndex: number;
    fill: string;
    variableIndex?: number;
    sum: number;
}

const getVaryBySum = (
    datasWithIndexAndColor: {
        name: string;
        value: number | number[];
        index: number;
        fill: string;
        variableIndex?: number;
    }[][],
    operationVariable: string
) => {
    let sum = datasWithIndexAndColor
        .map((dataItem) => {
            let sum = dataItem.reduce((accumulator, item) => {
                return accumulator + getValue(item, 0);
            }, 0);
            return sum;
        })
        .reduce((accumulator, item) => {
            return accumulator + item;
        }, 0);
    let sumValue = formatValue(sum, false);
    let sumLabel = `Total ${operationVariable}:`;
    return [sumLabel, sumValue.join("")].join(" ");
};

const getNumericalLabel = (
    item: {
        name: string;
        value: number | number[];
        sum: number;
    },
    labelValuesType: string
) => {
    const isPercentages = labelValuesType === "percentages";
    let value = (isPercentages
        ? (getValue(item, 0) / item.sum) * 100
        : getValue(item, 0)
    )
        .toFixed(0)
        .toString();
    if (isPercentages) value += "%";

    return value;
};

const getLegendLabel = (
    item: {
        name: string;
        value: number | number[];
        sum: number;
    },
    showLegend: boolean,
    showLabelInBoxes: boolean
) => {
    let name =
        item && item.name.length !== 0
            ? item.name[0].toUpperCase() + item.name.slice(1, item.name.length)
            : "";
    name = `${name}: `;
    if (showLegend && !showLabelInBoxes) name = "";
    if (!showLegend && !showLabelInBoxes) name = "";

    return name;
};

interface InnerChartProps {
    config: TreemapFinding["config"];
    data: TreemapFinding["content"]["data"];
    groupNames: TreemapFinding["content"]["groupNames"];
    dataItem: {
        name: string;
        groupName?: string;
        groupIndex?: number;
        value: number | number[];
        index: number;
        globalIndex: number;
        fill: string;
        variableIndex?: number;
    }[][];
    isVaryByData?: boolean;
    editable?: boolean;
    onShowBarColorMenu: React.Dispatch<
        React.SetStateAction<{
            name: string;
            index: number;
            x: number;
            y: number;
        } | null>
    >;
}

interface TooltipInfo {
    x: number;
    y: number;
    value: number | number[];
    name: string;
    fill: string;
    sum: number;
}

function addLabels(
    labelValuesType: string,
    showLegend: boolean,
    showLabelInBoxes: boolean,
    svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
    leaves: d3.HierarchyRectangularNode<Datum>[],
    fontSize: {
        title: number;
        label: number;
    }
): void {
    const texts = svg.selectAll("text").data(leaves).enter();

    let label = "";

    const titleFontSize =
        fontSize.title ??
        parseInt(mainStyle.getPropertyValue("--treemap-label-size"));

    const numericalLabelFontSize =
        fontSize.label ??
        parseInt(mainStyle.getPropertyValue("--treemap-label-size"));

    texts
        .append("text")
        .attr("x", (d) => d.x0 + 5) // +10 to adjust position (more right)
        .attr("y", (d) => d.y0 + titleFontSize + 4) // +20 to adjust position (lower)
        .attr("font-size", titleFontSize)
        .text((d) => {
            label = getLegendLabel(d.data, showLegend, showLabelInBoxes);
            const labelWidth = getTextWidth(label, `${titleFontSize}px`);
            const newLabel = clipText(
                label.trim(),
                labelWidth,
                d.x1 - d.x0 - titleFontSize - 15,
                "..."
            );

            return newLabel;
        });

    const numericalLabelYOffset = label.length
        ? numericalLabelFontSize + titleFontSize - 10
        : 0;

    texts
        .append("text")
        .attr("x", (d) => d.x0 + 5) // +10 to adjust position (more right)
        .attr("y", (d) => d.y0 + 25 + numericalLabelYOffset) // +20 to adjust position (lower)
        .attr("font-size", numericalLabelFontSize)
        .text((d) => {
            const label = getNumericalLabel(d.data, labelValuesType);
            const labelWidth = getTextWidth(
                label,
                `${numericalLabelFontSize}px`
            );
            const newLabel = clipText(
                label.trim(),
                labelWidth,
                d.x1 - d.x0 - numericalLabelFontSize,
                "..."
            );
            return newLabel;
        });
}

const InnerChart = (props: InnerChartProps) => {
    let [showTooltipInfo, setShowTooltipInfo] = useState<TooltipInfo | null>(
        null
    );
    const tooltipFormat = (tooltipInfo: TooltipInfo): string => {
        let value = getValue(tooltipInfo, 0);
        let formattedValue = formatValue(value, false);
        let formattedValueSum = formatValue(
            (value * 100) / tooltipInfo.sum,
            false
        );

        let result = formattedValue[0].concat(formattedValue[1]);
        result = result
            .concat(", ")
            .concat(formattedValueSum[0])
            .concat(formattedValueSum[1])
            .concat("%");
        return result;
    };
    const mouseenter = (
        event: MouseEvent,
        d: d3.HierarchyRectangularNode<Datum>
    ) => {
        // Do not show the tooltip for root and group nodes
        if (d.data.id !== "%root") {
            setShowTooltipInfo({
                x: event.x,
                y: event.y,
                value: d.data.value,
                name: d.data.name,
                fill: d.data.fill,
                sum: d.data.sum,
            });
        }
    };
    const mousemove = (event: MouseEvent) => {
        setShowTooltipInfo((showTooltipInfo) => {
            if (showTooltipInfo == null) {
                return null;
            } else {
                return {
                    ...showTooltipInfo,
                    x: event.x + 5,
                    y: event.y + 5,
                };
            }
        });
    };
    const mouseleave = () => {
        setShowTooltipInfo(null);
    };
    let parentSvgRef = React.useRef<HTMLDivElement>(null);
    let svgRef = React.useRef<SVGSVGElement>(null);
    let tooltipStyle = {
        ...TooltipStyles(
            props.config.tooltipColor,
            props.config.tooltipFontSize
        ),
    };

    const onShowBarColorMenu = props.onShowBarColorMenu;

    useEffect(() => {
        const clickSector = (
            event: MouseEvent,
            d: d3.HierarchyRectangularNode<Datum>
        ) => {
            const { y } = calculateChartColorPickerPosition(event);
            if (props.editable && d.data.id !== "%root") {
                onShowBarColorMenu({
                    name: d.data.name,
                    index: d.data.globalIndex,
                    x: event.clientX,
                    y,
                });
            }
        };

        let containerHeight = parentSvgRef.current?.clientHeight ?? 0;
        let containerWidth = parentSvgRef.current?.clientWidth ?? 0;
        d3.select(svgRef.current!).selectAll("*").remove();

        let svg = d3
            .select(svgRef.current!)
            .attr("width", containerWidth)
            .attr("height", containerHeight)
            .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`);

        if (props.isVaryByData && props.dataItem.length > 1) {
            let dataByValuesLength = props.dataItem[0].length;
            let dataByVariablesLength = props.dataItem.length;
            let data: {
                name: string;
                groupName?: string;
                groupIndex?: number;
                value: number | number[];
                index: number;
                globalIndex: number;
                fill: string;
                variableIndex?: number;
            }[] = [];
            const groupColors: string[] = props.dataItem[0].map(
                (_, groupIndex) => {
                    let globalIndex =
                        dataByVariablesLength * dataByVariablesLength +
                        groupIndex;
                    return (
                        props.config.nameColorMapping?.[globalIndex] ??
                        colorList[globalIndex % colorList.length]
                    );
                }
            );
            let groupData = props.dataItem[0].map((group, groupIndex) => ({
                id: `%%${groupIndex}`,
                name: group.name,
                value: 0,
                index: -1,
                globalIndex:
                    dataByVariablesLength * dataByVariablesLength + groupIndex,
                fill: groupColors[groupIndex],
                sum: 0,
            }));

            let sum = 0;
            for (let i = 0; i < dataByValuesLength; ++i) {
                for (let j = 0; j < dataByVariablesLength; ++j) {
                    data.push(props.dataItem[j][i]);
                    let value = getValue(props.dataItem[j][i], 0);
                    sum += value;
                    groupData[i].value += value;
                }
            }
            for (let i = 0; i < dataByValuesLength; ++i) {
                groupData[i].sum = sum;
            }
            let stratify = d3
                .stratify<Datum>()
                .id((d) => d.id)
                .parentId((d) =>
                    d.id === "%root"
                        ? null
                        : d.groupIndex == null
                        ? "%root"
                        : `%%${d.groupIndex}`
                );

            let treeData = stratify([
                {
                    id: "%root",
                    name: "root",
                    value: 0,
                    index: -1,
                    globalIndex: -1,
                    fill: "transparent",
                    sum: sum,
                },
                ...groupData,
                ...data.map((element, index) => ({
                    ...element,
                    id: index.toString(),
                    sum: sum,
                })),
            ]).sum((subItem) =>
                // root and group nodes have to have value = 0
                subItem.index >= 0 ? getValue(subItem, 0) : 0
            );
            let treemap = d3
                .treemap<Datum>()
                .size([containerWidth, containerHeight])
                .padding(4)
                .paddingTop(6)
                .paddingBottom(6)
                .paddingLeft(6)
                .paddingRight(6);

            let dataReady = treemap(treeData);
            svg.selectAll<SVGRectElement, d3.HierarchyRectangularNode<Datum>>(
                "rect"
            )
                .data(dataReady.descendants())
                .enter()
                .append("rect")
                .attr("x", (d) => d.x0)
                .attr("y", (d) => d.y0)
                .attr("width", (d) => d.x1 - d.x0)
                .attr("height", (d) => d.y1 - d.y0)
                .style("stroke", (d) =>
                    d.data.id === "%root" ? null : "black"
                )
                .style("fill", (d) => d.data.fill)
                .on("mouseenter", mouseenter)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)
                .on("click", clickSector);
            if (props.config.showLabels ?? true) {
                addLabels(
                    props.config.labelValuesType ?? "percentages",
                    props.config.showLegend ?? true,
                    props.config.showLabelInBoxes ?? false,
                    svg,
                    dataReady.leaves(),
                    {
                        title: props.config.titleSize,
                        label: props.config.labelSize,
                    }
                );
            }
        } else {
            for (let i = 0; i < props.dataItem.length; ++i) {
                let item = props.dataItem[i];
                let sum = item.reduce(
                    (accumulator, subItem) =>
                        accumulator + getValue(subItem, 0),
                    0
                );

                let stratify = d3
                    .stratify<Datum>()
                    .id((d) => d.id)
                    .parentId((d) => (d.id === "%root" ? null : "%root"));
                let treeData = stratify([
                    {
                        id: "%root",
                        name: "root",
                        value: 0,
                        index: -1,
                        globalIndex: -1,
                        fill: "transparent",
                        sum: sum,
                    },
                    ...item.map((element, index) => ({
                        ...element,
                        id: index.toString(),
                        sum: sum,
                    })),
                ]).sum((subItem) => getValue(subItem, 0));
                let treemap = d3
                    .treemap<Datum>()
                    .size([containerWidth, containerHeight])
                    .padding(4)
                    .paddingTop(0);

                let dataReady = treemap(treeData);
                svg.selectAll<
                    SVGRectElement,
                    d3.HierarchyRectangularNode<Datum>
                >("rect")
                    .data(dataReady.leaves())
                    .enter()
                    .append("rect")
                    .attr("class", "cancel-drag")
                    .attr("x", (d) => d.x0)
                    .attr("y", (d) => d.y0)
                    .attr("width", (d) => d.x1 - d.x0)
                    .attr("height", (d) => d.y1 - d.y0)
                    .style("stroke", "transparent")
                    .style("fill", (d) => d.data.fill)
                    .on("mouseenter", mouseenter)
                    .on("mousemove", mousemove)
                    .on("mouseleave", mouseleave)
                    .on("click", clickSector);

                if (props.config.showLabels ?? true) {
                    addLabels(
                        props.config.labelValuesType ?? "percentages",
                        props.config.showLegend ?? true,
                        props.config.showLabelInBoxes ?? false,
                        svg,
                        dataReady.leaves(),
                        {
                            title: props.config.titleSize,
                            label: props.config.labelSize,
                        }
                    );
                }
            }
        }
    }, [
        parentSvgRef.current?.clientHeight,
        parentSvgRef.current?.clientWidth,
        props.data,
        props.dataItem,
        props.config,
        props.config.tooltipColor,
        props.config.tooltipFontSize,
        props.config.nameColorMapping,
        props.config.labelValuesType,
        props.config.showLegend,
        props.config.showLabels,
        props.isVaryByData,
        props.editable,
        onShowBarColorMenu,
    ]);

    return (
        <div
            ref={parentSvgRef}
            style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                // Resizing does not work properly
                // without overflow: "hidden"
                overflow: "hidden",
            }}
        >
            {showTooltipInfo && (
                <Portal rootNode={document.body}>
                    <div
                        style={{
                            zIndex: 100000,
                            position: "absolute",
                            top: showTooltipInfo.y,
                            left: showTooltipInfo.x + 5,
                            padding: "10px",
                            ...tooltipStyle.contentStyle,
                            display: "flex",
                            flexDirection: "column",
                            pointerEvents: "none",
                        }}
                    >
                        <span
                            className="unselectable"
                            style={{
                                ...tooltipStyle.itemStyle,
                                color: showTooltipInfo.fill,
                            }}
                        >
                            {`${showTooltipInfo.name} : ${tooltipFormat(
                                showTooltipInfo
                            )}`}
                        </span>
                    </div>
                </Portal>
            )}
            <svg ref={svgRef} />
        </div>
    );
};

interface Props {
    data: TreemapFinding["content"]["data"];
    varyByData: TreemapFinding["content"]["varyByData"];
    additionalVaryByData: TreemapFinding["content"]["additionalVaryByData"];
    config: TreemapFinding["config"];
    groupNames: TreemapFinding["content"]["groupNames"];
    editable?: boolean;
    columnDragActive?: boolean;
    preview?: boolean;
    onChangeData?: (
        data: TreemapFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: TreemapFinding["config"],
        updateData?: boolean
    ) => void;
}

export default function TreemapD3(props: Props) {
    let transformedDataLength: number = useMemo(() => {
        if (props.varyByData != null) {
            return 1 + (props.additionalVaryByData?.length ?? 0);
        } else {
            return 1;
        }
    }, [props.varyByData, props.additionalVaryByData]);
    let { config } = props;

    let [showBarColorMenu, setShowBarColorMenu] = React.useState<{
        name: string;
        index: number;
        x: number;
        y: number;
    } | null>(null);

    let legendsColor =
        config.ticksColor ??
        mainStyle.getPropertyValue("--graphs-axes-text-color");
    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData = Array.from(props.data);
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        props.onChangeData?.(newData, true);
    };
    let linkedVariables = props.data.filter(
        (item) => item.variableIndex != null
    );
    let currentEditVariableIndex = props.data.findIndex(
        (item) => item.variableIndex == null
    );
    let datasWithIndexAndColor: {
        index: number;
        globalIndex: number;
        fill: string;
        name: string;
        groupName?: string;
        groupIndex?: number;
        value: number | number[];
        variableIndex?: number;
        originalName?: string;
    }[][] = useMemo(() => {
        let transformedData: Exclude<
            TreemapFinding["content"]["additionalVaryByData"],
            null | undefined
        >;
        if (props.varyByData != null) {
            if (props.additionalVaryByData != null) {
                transformedData = [props.varyByData].concat(
                    props.additionalVaryByData
                );
            } else {
                transformedData = [props.varyByData];
            }
        } else {
            transformedData = [props.data];
        }
        let groupNames = props.groupNames ?? [""];
        if (props.varyByData)
            return transformedData.map((dataItem, groupIndex) =>
                dataItem.map((item, index) => {
                    let globalIndex =
                        transformedData.length === 1
                            ? groupIndex * dataItem.length + index
                            : groupIndex;
                    return {
                        ...item,
                        index: index,
                        globalIndex: globalIndex,
                        groupIndex: index,
                        fill:
                            config.nameColorMapping?.[globalIndex] ??
                            colorList[globalIndex % colorList.length],
                    };
                })
            );
        else
            return groupNames.map((groupName, groupIndex) =>
                transformedData[0].map((item, index) => {
                    let sectorIsNotInitialized =
                        props.editable &&
                        config.dataScope != null &&
                        item.variableIndex == null;
                    return {
                        ...item,
                        index: index,
                        groupName: groupName,
                        value: getValue(item, groupIndex),
                        globalIndex:
                            groupIndex * transformedData[0].length + index,
                        fill: props.preview
                            ? previewColors[index % previewColors.length]
                            : sectorIsNotInitialized
                            ? "#EFF5FA"
                            : config.nameColorMapping?.[
                                  groupIndex * transformedData[0].length + index
                              ] ??
                              (item.variableIndex == null
                                  ? previewColors[
                                        ((groupIndex *
                                            transformedData[0].length +
                                            index) %
                                            previewColors.length) %
                                            previewColors.length
                                    ]
                                  : colorList[
                                        (groupIndex *
                                            transformedData[0].length +
                                            index) %
                                            colorList.length
                                    ]),
                    };
                })
            );
    }, [
        props.data,
        props.varyByData,
        props.additionalVaryByData,
        props.groupNames,
        props.editable,
        props.preview,
        config.nameColorMapping,
        config.dataScope,
    ]);
    let plot = (
        <div
            style={{
                display: "flex",
                height: "100%",
                width: "100%",
            }}
        >
            {props.varyByData == null &&
                datasWithIndexAndColor.map((dataItem, groupIndex) => {
                    let sum = dataItem.reduce((accumulator, item) => {
                        return accumulator + getValue(item, 0);
                    }, 0);
                    let sumValue = formatValue(sum, false);
                    let sumLabel = `Total ${
                        valueToTitle[props.config.operationVariable]
                    }:`;
                    return (
                        <div
                            style={{
                                overflow: "hidden",
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                                height: "100%",
                                width: `calc(100%/${datasWithIndexAndColor.length})`,
                            }}
                        >
                            {(config.showLegend ?? true) && (
                                <div
                                    className="my-row"
                                    style={{
                                        width: "100%",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        margin: "0 0 4px 0",
                                        // 150 - editable axis width
                                        paddingLeft: 150,
                                    }}
                                >
                                    {dataItem.map((item, index) => (
                                        <LegendEditElement
                                            allowColorChange={props.editable}
                                            disallowTextChange
                                            textSize={config.legendSize}
                                            text={item.name}
                                            color={item.fill}
                                            textColor={legendsColor}
                                            onFinishColor={(color) => {
                                                let nameColorMapping = {
                                                    ...config.nameColorMapping,
                                                };
                                                nameColorMapping[
                                                    groupIndex *
                                                        dataItem.length +
                                                        index
                                                ] = color;
                                                props.onChangeConfig?.({
                                                    ...config,
                                                    nameColorMapping: nameColorMapping,
                                                });
                                            }}
                                        />
                                    ))}
                                </div>
                            )}

                            <div
                                style={{
                                    overflow: "hidden",
                                    display: "flex",
                                    height: "100%",
                                }}
                            >
                                {props.config.showVariableLabels && (
                                    <div className="flex-simple-column">
                                        {props.data.map((item, index) => {
                                            return (
                                                <div
                                                    style={{ marginBottom: 18 }}
                                                >
                                                    <EditableAxisItem
                                                        width={150}
                                                        onChange={(value) => {
                                                            let newData = Array.from(
                                                                props.data
                                                            );
                                                            newData[
                                                                index
                                                            ].name = value;
                                                            props.onChangeData?.(
                                                                newData,
                                                                true
                                                            );
                                                        }}
                                                        color={"#333333"}
                                                        currentEditVariableIndex={
                                                            currentEditVariableIndex
                                                        }
                                                        editable={
                                                            props.editable
                                                        }
                                                        index={index}
                                                        vertical={false}
                                                        name={item.name}
                                                        onDrop={linkVariable}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                {/* NEVER pass a lambda function as onShowBarColorMenu - it will cause the chart to re-render every time*/}
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "100%",
                                        width: "calc(100% - 150px)",
                                    }}
                                >
                                    <InnerChart
                                        onShowBarColorMenu={setShowBarColorMenu}
                                        data={props.data}
                                        config={props.config}
                                        groupNames={props.groupNames}
                                        dataItem={[dataItem]}
                                        editable={props.editable}
                                    />
                                    {props.data.filter(
                                        (item) => item.variableIndex != null
                                    ).length > 0 && (
                                        <span
                                            style={{
                                                width: "100%",
                                                color: legendsColor,
                                                margin: 10,
                                                textAlign: "center",
                                                fontFamily: "Roboto",
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                                wordWrap: "break-word",
                                            }}
                                        >
                                            {[
                                                props.groupNames?.[
                                                    groupIndex
                                                ] ?? "",
                                                sumLabel,
                                                sumValue.join(""),
                                            ].join(" ")}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            {props.varyByData != null && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: `100%`,
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    {(config.showLegend ?? true) && (
                        <div
                            className="my-row"
                            style={{
                                width: "100%",
                                flexWrap: "wrap",
                                justifyContent: "center",
                            }}
                        >
                            {transformedDataLength === 1 &&
                                datasWithIndexAndColor.map(
                                    (dataItem, groupIndex) =>
                                        dataItem.map((item, index) => (
                                            <LegendEditElement
                                                allowColorChange={
                                                    props.editable
                                                }
                                                disallowTextChange
                                                textSize={config.legendSize}
                                                text={item.name}
                                                color={item.fill}
                                                textColor={legendsColor}
                                                onFinishColor={(color) => {
                                                    let nameColorMapping = {
                                                        ...config.nameColorMapping,
                                                    };
                                                    let globalIndex =
                                                        transformedDataLength ===
                                                        1
                                                            ? groupIndex *
                                                                  dataItem.length +
                                                              index
                                                            : groupIndex;
                                                    nameColorMapping[
                                                        globalIndex
                                                    ] = color;
                                                    props.onChangeConfig?.({
                                                        ...config,
                                                        nameColorMapping: nameColorMapping,
                                                    });
                                                }}
                                            />
                                        ))
                                )}
                            {transformedDataLength > 1 &&
                                linkedVariables.map((linkedVariable, index) => (
                                    <LegendEditElement
                                        allowColorChange={props.editable}
                                        disallowTextChange
                                        textSize={config.legendSize}
                                        text={linkedVariable.name}
                                        color={
                                            props.config?.nameColorMapping?.[
                                                index
                                            ] ??
                                            colorList[index % colorList.length]
                                        }
                                        textColor={legendsColor}
                                        onFinishColor={(color) => {
                                            let nameColorMapping = {
                                                ...config.nameColorMapping,
                                            };
                                            let globalIndex = index;
                                            nameColorMapping[
                                                globalIndex
                                            ] = color;
                                            props.onChangeConfig?.({
                                                ...config,
                                                nameColorMapping: nameColorMapping,
                                            });
                                        }}
                                    />
                                ))}
                            {transformedDataLength > 1 &&
                                datasWithIndexAndColor[0].map(
                                    (variable, index) => {
                                        let globalIndex =
                                            transformedDataLength *
                                                datasWithIndexAndColor.length +
                                            index;
                                        return (
                                            <LegendEditElement
                                                allowColorChange={
                                                    props.editable
                                                }
                                                disallowTextChange
                                                textSize={config.legendSize}
                                                text={variable.name}
                                                color={
                                                    props.config
                                                        ?.nameColorMapping?.[
                                                        globalIndex
                                                    ] ?? colorList[globalIndex]
                                                }
                                                textColor={legendsColor}
                                                onFinishColor={(color) => {
                                                    let nameColorMapping = {
                                                        ...config.nameColorMapping,
                                                    };
                                                    nameColorMapping[
                                                        globalIndex
                                                    ] = color;
                                                    props.onChangeConfig?.({
                                                        ...config,
                                                        nameColorMapping: nameColorMapping,
                                                    });
                                                }}
                                            />
                                        );
                                    }
                                )}
                        </div>
                    )}
                    {/* NEVER pass a lambda function as onShowBarColorMenu - it will cause the chart to re-render every time*/}
                    <InnerChart
                        onShowBarColorMenu={setShowBarColorMenu}
                        data={props.data}
                        config={props.config}
                        isVaryByData
                        groupNames={props.groupNames}
                        dataItem={datasWithIndexAndColor}
                        editable={props.editable}
                    />
                    {props.data.filter((item) => item.variableIndex != null)
                        .length > 0 && (
                        <span
                            style={{
                                width: "100%",
                                color: legendsColor,
                                margin: 10,
                                textAlign: "center",
                                fontFamily: "Roboto",
                                fontWeight: "bold",
                                fontSize: "12px",
                                wordWrap: "break-word",
                            }}
                        >
                            {getVaryBySum(
                                datasWithIndexAndColor,
                                props.config.operationVariable!
                            )}
                        </span>
                    )}
                </div>
            )}

            {showBarColorMenu && (
                <Portal rootNode={document.body}>
                    <OutsideAlerter
                        onReject={() => {
                            setShowBarColorMenu(null);
                        }}
                    >
                        <div
                            style={{
                                zIndex: 100000000,
                                position: "absolute",
                                left: showBarColorMenu.x,
                                top: showBarColorMenu.y,
                            }}
                        >
                            <ColorPicker
                                enableAlpha={true}
                                width={"220px"}
                                color={
                                    config.nameColorMapping?.[
                                        showBarColorMenu.index
                                    ] ??
                                    colorList[
                                        showBarColorMenu.index %
                                            colorList.length
                                    ]
                                }
                                onChange={(color) => {
                                    let nameColorMapping = {
                                        ...config.nameColorMapping,
                                    };
                                    nameColorMapping[
                                        showBarColorMenu!.index
                                    ] = color;
                                    props.onChangeConfig?.({
                                        ...config,
                                        nameColorMapping: nameColorMapping,
                                    });
                                }}
                            />
                        </div>
                    </OutsideAlerter>
                </Portal>
            )}
        </div>
    );
    return plot;
}
