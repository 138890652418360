import CustomSketchPicker from "common/custom_sketch_picker/CustomSketchPicker";
import recentColors from "common/RecentColors";

interface ColorPickerProps {
	width: string;
	enableAlpha: boolean;
	color: string;
	onChange: (color: string) => void;
}

export default function ColorPicker(props: ColorPickerProps) {
	return (
		<div
			onMouseDown={(evt) => {
				evt.stopPropagation();
			}}
		>
			<CustomSketchPicker
				width={props.width}
				disableAlpha={!props.enableAlpha}
				color={props.color}
				onChange={(color) => {
					let newColor = props.enableAlpha
						? `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${
								color.rgb.a ?? 1
						  })`
						: color.hex;
					props.onChange(newColor);
				}}
				presetColorsTitle={"Recent Colors"}
				presetColors={recentColors.recentColors}
			/>
		</div>
	);
}