import React from "react";

import styles from "./ButtonCard.module.css";

interface Props {
	text: string;
	onClick: React.MouseEventHandler
}

function ButtonCard({ onClick, text }: Props) {
	return (
		<button type="button" onClick={onClick} className={styles.rootButton}>
			<div className={styles.root}>
				<p className={styles.text}>{text}</p>
			</div>
		</button>
	);
}

export default ButtonCard;
