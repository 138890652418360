import { action, makeAutoObservable } from "mobx";
import { getSharedBoxesApi } from "common/CanvasUserApi";
import {
    CanvasSharedNode,
    isBox,
    isDropdownSelector,
    isTextBox,
    SharedBoxOption,
} from "common/Canvas";

export function migrateSharedBox(box: CanvasSharedNode) {
    if (isBox(box) || isTextBox(box) || isDropdownSelector(box)) {
        if (box.additionalOutputs == null) box.additionalOutputs = [];
    }
    return box;
}

class SharedBoxesStore {
    sharedBoxesState: SharedBoxOption[];
    initialized: boolean;
    constructor() {
        makeAutoObservable(this);

        this.sharedBoxesState = [];
        this.initialized = false;
    }
    @action.bound assignSharedBoxes(sharedBoxes: SharedBoxOption[]) {
        this.sharedBoxesState = sharedBoxes;
        this.initialized = true;
    }
    sharedBoxes(currentCanvasId: number): SharedBoxOption[] {
        return this.sharedBoxesState.filter(
            (item) => item.canvasId !== currentCanvasId
        );
    }
    getBox(id: number) {
        return this.sharedBoxesState.find((item) => item.item.id === id)?.item;
    }
    async updateSharedBoxes(currentModuleId: number) {
        try {
            let sharedBoxes = await getSharedBoxesApi(currentModuleId);
            this.assignSharedBoxes(
                sharedBoxes.map((item) => ({
                    label: item.canvas_title
                        .concat(": ")
                        .concat(item.box.outerId),
                    value: item.id,
                    item: {
                        ...item,
                        box: migrateSharedBox(item.box)
                    },
                    canvasId: item.canvas_id,
                    outerId: item.box.outerId,
                }))
            );
        } catch (error) {
            console.log(String(error));
        }
    }
}

export default new SharedBoxesStore();
