import React, { useRef, Component } from "react";
import { useDrag, useDrop } from "react-dnd";
import { observer } from "mobx-react";
import Cookies from "universal-cookie";
import PagesStore from "common/PagesStore";
import PageType from "common/PageType";
import Instrumentation from "common/Instrumentation";
import {
    editUserPageModuleApi,
    moveUserPageModuleApi,
} from "common/ModulesApi";
import styles from "./CanvasPageBarHorizontal.module.css";
import cx from "classnames";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import CurrentModulesStore from "common/CurrentModulesStore";
import { PageBarInfo } from "common/Canvas";
import {
    getNewSizeAfterResize2,
    changeElementWhenResize2,
} from "../BaseCanvasResizableFunctions";
import { defaultSlideWidth, defaultMobileSlideWidth } from "../Constants";
import { Button } from "react-bootstrap";
import { ExtendedPagesBarProps } from "../CanvasPageBarWrapper";
import { addBackwardCompatibilityForPositionAndSize } from "common/Canvas";

const cookies = new Cookies();

interface DraggableProps {
    pageId: number | string;
    onPagesSwap: (pageId: number | string, swapPageId: number | string) => void;
}

interface DragContent {
    type: string;
    content: number | string;
}

const type = "pageBar";

const DraggablePage: React.FunctionComponent<DraggableProps> = (props) => {
    const pageId = props.pageId;
    const ref = useRef(null);

    const drag = useDrag({
        item: {
            content: pageId,
        },
        end: (item, monitor) => {},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        type: "page",
    })[1];

    const drop = useDrop({
        accept: "page",
        drop(otherItem: DragContent, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = otherItem.content;
            const hoverIndex = pageId;
            if (dragIndex === hoverIndex) {
                return;
            }
            props.onPagesSwap(pageId, otherItem.content);
        },
    })[1];
    if (pageId) drag(drop(ref));
    return <div ref={ref}>{props.children}</div>;
};

interface PagesBarState {
    editedTitle: string;
    needScroll: boolean;
    hoveredPageId?: number;
    editedPageId?: number;
    removingPageId?: number;
    removingPopupShowed: boolean;
}

const PageBar = observer(
    class PageBar extends Component<ExtendedPagesBarProps, PagesBarState> {
        private pagesRef: HTMLDivElement | null = null;
        private performance: Date | null;
        private drag: boolean = false;

        constructor(props: ExtendedPagesBarProps) {
            super(props);
            this.state = {
                editedTitle: "",
                needScroll: false,
                editedPageId: undefined,
                hoveredPageId: undefined,
                removingPopupShowed: false,
                removingPageId: undefined,
            };
            this.performance = null;
            this.swapPages = this.swapPages.bind(this);
            this.updateDimensions = this.updateDimensions.bind(this);
        }

        swapPages(pageId: number | string, swapId: number | string) {
            if (this.props.sharedModule != null) return;
            pageId = pageId as number;
            swapId = swapId as number;
            this.performance = new Date();
            if (pageId > 0 && swapId > 0) {
                moveUserPageModuleApi(
                    swapId,
                    pageId,
                    cookies.get("instrumentation_session_id")
                )
                    .then(() => {
                        PagesStore(PageType.Canvases).updatePages();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
        componentDidMount() {
            this.updateDimensions();
            window.addEventListener("resize", this.updateDimensions);
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.updateDimensions);
        }

        componentDidUpdate(
            prevProps: ExtendedPagesBarProps,
            prevState: PagesBarState
        ) {
            if (this.performance != null) {
                let timeMs = new Date().getTime() - this.performance.getTime();
                this.performance = null;
                Instrumentation.addInteraction("Canvas", timeMs);
            }
        }
        updateDimensions() {
            if (this.pagesRef != null)
                this.setState({
                    needScroll:
                        this.pagesRef.scrollWidth > this.pagesRef.clientWidth,
                });
        }

        updatePage(title: string, pageId: number, moduleId: number) {
            if (title.length > 0) {
                editUserPageModuleApi(
                    title,
                    pageId,
                    cookies.get("instrumentation_session_id")
                )
                    .then(() => {
                        this.setState(
                            { editedPageId: undefined, editedTitle: "" },
                            () => {
                                PagesStore(PageType.Canvases).updatePages();
                            }
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState(
                            { editedPageId: undefined, editedTitle: "" },
                            () => {
                                PagesStore(PageType.Canvases).updatePages();
                            }
                        );
                    });
            } else {
                this.setState(
                    { editedPageId: undefined, editedTitle: "" },
                    () => {
                        PagesStore(PageType.Canvases).updatePages();
                    }
                );
            }
        }

        buildItem(
            title: string,
            pageId: string | number,
            moduleId: number | undefined,
            first: boolean,
            pageBarInfo: PageBarInfo
        ) {
            let selected = pageId === this.props.currentPageId;
            let editMode = pageId === this.state.editedPageId;
            // let readOnly = !editMode;
            // let isFirefox =
            //     navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
            const sectionNameStyles = {
                [styles.sectionName]: true,
            };
            if (selected) {
                sectionNameStyles[styles.sectionNameActive] = true;
            }
            const sectionContainerStyles = {
                [styles.sectionContainer]: true,
            };
            if (first) {
                sectionContainerStyles[styles.sectionContainerFirst] = true;
            }
            let containerStyle: React.CSSProperties = {};
            if (!first) {
                if (pageBarInfo.dividerColor != null) {
                    containerStyle.borderLeft = `1px solid ${pageBarInfo.dividerColor}`;
                }
            }
            return (
                <div
                    className={cx(sectionContainerStyles)}
                    style={containerStyle}
                    onClick={(evt) => {
                        if (!editMode && !this.drag) {
                            this.props.onCurrentPageChange(pageId);
                        }
                    }}
                >
                    <DraggablePage
                        key={pageId}
                        pageId={pageId}
                        onPagesSwap={this.swapPages}
                    >
                        <span
                            className={cx(sectionNameStyles)}
                            style={{
                                fontSize: pageBarInfo.fontSize,
                                color: pageBarInfo.fontColor,
                            }}
                        >
                            {title}
                        </span>
                    </DraggablePage>
                </div>
            );
        }

        dismissPageRemovePopup() {
            this.setState({
                removingPopupShowed: false,
                removingPageId: undefined,
            });
        }
        render(): JSX.Element | null {
            const { viewMode } = this.props;
            let needScroll = false;
            let defaultWidth =
                this.props.viewMode === "mobile"
                    ? defaultMobileSlideWidth
                    : defaultSlideWidth;
            if (this.pagesRef) {
                needScroll =
                    this.pagesRef.scrollWidth > this.pagesRef.clientWidth;
            }

            let barContainerStyles = {
                [styles.barContainer]: true,
            };
            let pageBarInfo = this.props.pageBarInfo;
            pageBarInfo = addBackwardCompatibilityForPositionAndSize(
                pageBarInfo
            );

            let userPositionOptions = pageBarInfo.userPositionOptions ?? false;
            needScroll = needScroll && userPositionOptions;
            let nonScaledSize = {
                width: pageBarInfo.width,
                height: pageBarInfo.height,
                nodeSize: pageBarInfo.nodeSize,
            };
            let scaledSize = {
                width:
                    nonScaledSize.nodeSize[viewMode].width * this.props.scale,
                height:
                    nonScaledSize.nodeSize[viewMode].height * this.props.scale,
            };
            let buttonStyles = {
                [styles.buttonsStyle]: true,
            };
            let pages: {
                id: string | number;
                title: string;
                module_id?: number;
            }[] = [];
            if (this.props.sharedModule == null) {
                pages = PagesStore(PageType.Canvases).pages;
            } else {
                pages = this.props.sharedModule.pages.map((page) => ({
                    id: page.pageId,
                    title: page.pageId,
                    module_id: NaN,
                }));
            }
            return (
                <Draggable
                    disabled={this.props.frozen || !pageBarInfo.unlocked}
                    cancel="div[draggable='true']"
                    position={{
                        x:
                            pageBarInfo.nodePosition[viewMode].x *
                            this.props.scale,
                        y:
                            pageBarInfo.nodePosition[viewMode].y *
                            this.props.scale + 23, // 23 - View mode switcher height
                    }}
                    onDrag={(_evt, _data) => {
                        this.drag = true;
                    }}
                    onStop={(_evt, data) => {
                        if (this.drag) {
                            let x = data.x / this.props.scale;
                            let y = data.y / this.props.scale;
                            let deltaX =
                                x - pageBarInfo!.nodePosition[viewMode].x;
                            let deltaY =
                                y - pageBarInfo!.nodePosition[viewMode].y;
                            let changes = {
                                nodePosition: pageBarInfo.nodePosition,
                            };

                            changes.nodePosition[viewMode] = {
                                x,
                                y,
                            };
                            if (this.props.currentModuleId != null) {
                                CurrentModulesStore.updatePageBar(
                                    this.props.currentModuleId,
                                    {
                                        ...changes,
                                        userPositionOptions: true,
                                    }
                                );
                            }
                            this.props.onMoveGroupSelection(
                                deltaX,
                                deltaY,
                                {
                                    id: type,
                                    type: type,
                                    groupId: null,
                                },
                                false
                            );
                        }
                    }}
                >
                    <Resizable
                        className="selectable-by-pointer"
                        ref={(ref) => {
                            let innerRef = ref?.resizable;
                            if (innerRef != null) {
                                innerRef.setAttribute("type", type);
                                innerRef.setAttribute("id", type);
                            }
                        }}
                        enable={
                            this.props.frozen || !pageBarInfo.unlocked
                                ? {
                                      top: false,
                                      right: false,
                                      bottom: false,
                                      left: false,
                                      topRight: false,
                                      bottomRight: false,
                                      bottomLeft: false,
                                      topLeft: false,
                                  }
                                : {
                                      top: true,
                                      right: true,
                                      bottom: true,
                                      left: true,
                                      topRight: true,
                                      bottomRight: true,
                                      bottomLeft: true,
                                      topLeft: true,
                                  }
                        }
                        onResizeStart={(evt) => {
                            evt.stopPropagation();
                        }}
                        onResize={(_e, _direction, _ref, d) => {
                            changeElementWhenResize2(
                                {
                                    position: pageBarInfo!.nodePosition,
                                    size: pageBarInfo!.nodeSize,
                                },
                                this.props.scale,
                                _direction,
                                d,
                                _ref,
                                viewMode
                            );
                        }}
                        onResizeStop={(_e, _direction, _ref, d) => {
                            let newSize = getNewSizeAfterResize2(
                                {
                                    position: pageBarInfo!.nodePosition,
                                    size: pageBarInfo!.nodeSize,
                                },
                                this.props.scale,
                                _direction,
                                d,
                                viewMode
                            );
                            if (this.props.currentModuleId != null) {
                                CurrentModulesStore.updatePageBar(
                                    this.props.currentModuleId,
                                    {
                                        ...newSize,
                                        userPositionOptions: true,
                                    }
                                );
                            }
                            this.props.onResize();
                        }}
                        size={scaledSize}
                        style={{ zIndex: 990, position: "absolute" }}
                    >
                        <div
                            onClick={() => {
                                this.drag = false;
                            }}
                            className={cx(barContainerStyles)}
                            style={{
                                overflow: "hidden",
                                width: `calc(100% / ${this.props.scale})`,
                                height: `calc(100% / ${this.props.scale})`,
                                transformOrigin: "left top",
                                transform: `scale(${this.props.scale})`,
                                backgroundColor: pageBarInfo.fillColor,
                                boxShadow: !pageBarInfo.borderShadow
                                    ? "none"
                                    : undefined,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {needScroll && (
                                <Button
                                    className={cx(buttonStyles)}
                                    style={{
                                        marginLeft: 10,
                                        marginRight: 10,
                                        fontSize: pageBarInfo.fontSize,
                                        color: pageBarInfo.fontColor,
                                    }}
                                    onClick={(evt: any) => {
                                        if (this.pagesRef != null)
                                            this.pagesRef.scrollLeft -=
                                                nonScaledSize.nodeSize[viewMode]
                                                    .width / 2;
                                    }}
                                >
                                    {"<<"}
                                </Button>
                            )}

                            <div
                                className="my-row"
                                ref={(ref) => {
                                    this.pagesRef = ref;
                                    if (ref != null) {
                                        if (!userPositionOptions) {
                                            let width = ref.scrollWidth;
                                            if (
                                                Math.abs(
                                                    pageBarInfo!.nodeSize[
                                                        viewMode
                                                    ].width - width
                                                ) > 5
                                            )
                                                if(this.props.currentModuleId != null) {
                                                    CurrentModulesStore.updatePageBar(
                                                        this.props.currentModuleId,
                                                        {
                                                            nodePosition: {
                                                                ...pageBarInfo.nodePosition,
                                                                [viewMode]: {
                                                                    x:
                                                                        (defaultWidth -
                                                                            width) /
                                                                        2,
                                                                    y:
                                                                        pageBarInfo
                                                                            .nodePosition[
                                                                            viewMode
                                                                        ].y,
                                                                },
                                                            },
                                                            nodeSize: {
                                                                ...pageBarInfo.nodeSize,
                                                                [viewMode]: {
                                                                    width,
                                                                    height:
                                                                        pageBarInfo
                                                                            .nodeSize[
                                                                            viewMode
                                                                        ].height,
                                                                },
                                                            },
                                                        },
                                                        false
                                                    );
                                                }
                                        }
                                    }
                                }}
                                style={{
                                    overflow: "hidden",
                                    alignItems: "center",
                                }}
                            >
                                {pages.map((item, index) => {
                                    return this.buildItem(
                                        item.title,
                                        item.id,
                                        item.module_id,
                                        index === 0,
                                        pageBarInfo!
                                    );
                                })}
                            </div>
                            {needScroll && (
                                <Button
                                    className={cx(buttonStyles)}
                                    style={{
                                        marginLeft: 10,
                                        marginRight: 10,
                                        fontSize: pageBarInfo.fontSize,
                                        color: pageBarInfo.fontColor,
                                    }}
                                    onClick={(evt: any) => {
                                        if (this.pagesRef)
                                            this.pagesRef.scrollLeft +=
                                                nonScaledSize.nodeSize[viewMode]
                                                    .width / 2;
                                    }}
                                >
                                    {">>"}
                                </Button>
                            )}
                        </div>
                    </Resizable>
                </Draggable>
            );
        }
    }
);

export default PageBar;
