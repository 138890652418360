import React from "react";
import UserGroupsStore, { UserGroupOption } from "common/UserGroupsStore";
import Popup from "reactjs-popup";
import { Button } from "react-bootstrap";
import AppUserInviter from "common/canvas/AppUserInviter";
import { observer } from "mobx-react";
import { mainStyle } from "common/MainStyle";
import {
    AppGroupExtendedPermission,
    appSetPermissions,
} from "common/ModulesApi";
import remoteModuleId from "common/remoteModuleId";

interface Props {
    onClose: () => void;
    initialSelectedUserGroups?: AppGroupExtendedPermission[];
}

interface State {
    selectedNewGroup: UserGroupOption | null;
    selectedUserGroups: AppGroupExtendedPermission[];
}

@observer
class ManageAppPopup extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedNewGroup: null,
            selectedUserGroups: this.props.initialSelectedUserGroups ?? [],
        };
        this.addUser = this.addUser.bind(this);
        this.deleteUserGroup = this.deleteUserGroup.bind(this);
    }

    componentDidMount() {
        UserGroupsStore.init();
    }

    addUser() {
        this.setState((state) => {
            if (state.selectedNewGroup == null) return null;
            let selectedUserGroups = state.selectedUserGroups;
            selectedUserGroups.push({
                group_name: state.selectedNewGroup.value,
                group_id: state.selectedNewGroup.id,
                can_edit: false,
                personal: state.selectedNewGroup.personal ?? false,
                user_info: state.selectedNewGroup.userInfo,
            });
            return {
                selectedNewGroup: null,
                selectedUserGroups: selectedUserGroups,
            };
        });
    }
    deleteUserGroup(groupId: number) {
        this.setState((state) => {
            let selectedUserGroups = state.selectedUserGroups.filter(
                (group) => group.group_id !== groupId
            );
            return {
                selectedUserGroups: selectedUserGroups,
            };
        });
    }

    render() {
        let options = UserGroupsStore.userGroupsOptionsForSharing(true);

        return (
            <Popup
                arrow={true}
                contentStyle={{
                    width: 977,
                    minHeight: 380,
                    border: "none",
                    backgroundColor: "transparent",
                }}
                open={true}
                closeOnDocumentClick={false}
            >
                <div
                    className="dashboard-rect"
                    style={{
                        boxShadow: "0px 6px 13px rgba(21, 33, 56, 0.533345)",
                        borderRadius: 10,
                        alignItems: "center",
                        cursor: "pointer",
                        minHeight: 380,
                        maxHeight: "100vh",
                        width: 977,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{
                            width: "100%",
                            flexGrow: 1,
                        }}
                    >
                        <div
                            className="my-row"
                            style={{
                                width: "100%",
                                justifyContent: "space-between",
                                paddingRight: 8,
                                alignItems: "center",
                                paddingTop: 9,
                            }}
                        >
                            <span
                                style={{
                                    marginLeft: 40,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                Manage user access
                            </span>
                            <div
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    this.props.onClose();
                                }}
                            >
                                <img
                                    alt=""
                                    src="/dist/img/insights/insights_remove.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>
                        <div
                            className="flex-simple-column"
                            style={{
                                width: "100%",
                                paddingLeft: 40,
                                paddingRight: 40,
                                flexGrow: 1,
                            }}
                        >
                            <span
                                style={{
                                    marginTop: 20,
                                    marginBottom: 20,
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Roboto",
                                }}
                                className="unselectable"
                            >
                                Add users to the app
                            </span>
                            <AppUserInviter
                                usersSectionTitle={
                                    "USERS WITH ACCESS TO THE APP"
                                }
                                buttonTitle={"ADD USER"}
                                options={options}
                                selectedUserGroups={
                                    this.state.selectedUserGroups
                                }
                                onAddUser={this.addUser}
                                onDeleteUserGroup={this.deleteUserGroup}
                                onChange={(changes) => {
                                    this.setState(changes as State);
                                }}
                                selectedNewGroup={this.state.selectedNewGroup}
                                cantRemoveEditors={true}
                            />
                            <div style={{ flexGrow: 1 }} />
                            <div
                                style={{
                                    marginTop: "13px",
                                    height: "1px",
                                    backgroundColor: "#242E42",
                                    width: "100%",
                                }}
                            />
                            <div
                                className="my-row"
                                style={{
                                    marginTop: "15px",
                                    marginBottom: "24px",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    type="button"
                                    className="btn btn-sm btn-primary my-primary"
                                    style={{
                                        color: "#000000",
                                        marginLeft: "27px",
                                        backgroundColor: "transparent",
                                        border: "none",
                                        width: "70px",
                                    }}
                                    onClick={this.props.onClose}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    type="button"
                                    style={{
                                        width: "112px",
                                    }}
                                    className="btn btn-sm btn-primary my-primary"
                                    onClick={() => {
                                        if (remoteModuleId != null) {
                                            appSetPermissions(
                                                remoteModuleId,
                                                this.state.selectedUserGroups.map(
                                                    (group) => ({
                                                        group_id:
                                                            group.group_id,
                                                        can_edit: false,
                                                    })
                                                )
                                            )
                                                .then(this.props.onClose)
                                                .catch((error) =>
                                                    console.log(error)
                                                );
                                        }
                                    }}
                                >
                                    EDIT
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default ManageAppPopup;
