import React from "react";
import Slider from "rc-slider";
import StringUtils from "common/utilities/StringUtils";
import styles from "./StylingSection.module.css";

function SliderInput(props: {
    value: number;
    minValue?: number;
    maxValue?: number;
    onChange: (value: number) => void;
}) {
    let [value, setValue] = React.useState(String(props.value));

    return (
        <div className="my-row" style={{ alignItems: "center" }}>
            <Slider
                style={{ width: "100px" }}
                trackStyle={{ background: "#3B82C9" }}
                handleStyle={{
                    background: "#FFFFFF",
                    borderRadius: 4,
                    border: "2px solid #D1D1D1",
                }}
                min={props.minValue ?? 0}
                max={props.maxValue ?? 100}
                step={1}
                onChange={(value: number) => {
                    setValue(String(value));
                    props.onChange(value);
                }}
                value={props.value}
            />
            <input
                onKeyDown={(evt) => {
                    evt.stopPropagation();
                    if (evt.key === "Enter") {
                        if (StringUtils.isNumber(value))
                            props.onChange(Number(value));
                    }
                }}
                className={styles.input}
                style={{ marginLeft: "10px", padding: "0 0 0 10px" }}
                value={value}
                onChange={(evt) => {
                    let newValue = evt.target.value;
                    setValue(newValue);
                }}
                onBlur={() => {
                    if (StringUtils.isNumber(value))
                        props.onChange(Number(value));
                }}
            ></input>
        </div>
    );
}

export default SliderInput;
