import React, { CSSProperties } from "react";
import OutsideAlerter from "common/OutsideAlerter";
import {ReactComponent as CheckIcon} from "icons/check_icon.svg";

export enum PopupStatus {
    Success = 1,
    Error = 2,
}

export default function StatusPopup(props: {
    status: PopupStatus;
    message: string;
    onClose: () => void;
    style?: CSSProperties;
    closeAfter?: number;
}) {

    if(props.closeAfter)
        setTimeout(() => {
            props.onClose()
        }, props.closeAfter);

    return (
        <OutsideAlerter
            onReject={() => {
                props.onClose();
            }}
        >
            <div
                className="dashboard-rect"
                style={{
                    display: "flex",
                    backgroundColor: "#535353",
                    transform: "translate(-50%, 0%)",
                    left: "50%",
                    top: 100,
                    position: "fixed",
                    overflow: "visible",
                    border: `2px solid #535353`,
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    minHeight: 55,
                    minWidth: 300,
                    zIndex: 100000000,
                    ...props.style,
                }}
            >
                <div
                    className="my-row"
                    style={{
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {props.status === PopupStatus.Success && (
                        <CheckIcon
                            stroke="#EEEEEE"
                            fill="#EEEEEE"
                        />
                    )}
                    <span
                        style={{
                            marginLeft: 6,
                            marginRight: 6,
                            color: "#EEEEEE",
                            fontSize: "12px",
                            fontWeight: 400,
                            fontFamily: "Roboto",
                        }}
                        className="unselectable"
                    >
                        {props.message}
                    </span>
                </div>
            </div>
        </OutsideAlerter>
    );
}
