import React, { Component } from "react";
import "common/styles/App.css";
import { ColumnFormat, ColumnFormatOption, SpreadSheetColumnOption, spreadSheetColumnOptionsForImport } from "common/Canvas";
import SpreadSheetFormatOptionsPopup from "./SpreadSheetFormatOptionsPopup";
import CanvasIconsContainter from "modules/canvas_page/CanvasIconsContainer";

interface Props {
    format: ColumnFormat;
    topAppearance?: boolean;
    column?: number;
    onChangeFormat: (format: ColumnFormat) => void;
    customSpreadSheetOptions?: SpreadSheetColumnOption[];
}

interface State {
    show: boolean;
}

export default class FormatDropdown extends Component<Props, State> {
    private buttonRef: React.RefObject<HTMLDivElement>;
    private menuRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            show: false,
        };
        this.closeMenu = this.closeMenu.bind(this);
        this.buttonRef = React.createRef();
        this.menuRef = React.createRef();
    }

    public componentDidMount(): void {
        document.addEventListener("mousedown", this.closeMenu);
    }

    public componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.closeMenu);
    }

    

    private closeMenu(event: MouseEvent): void {
        if (
            this.menuRef.current != null &&
            this.buttonRef.current != null &&
            !this.menuRef.current.contains(event.target as Node | null) &&
            !this.buttonRef.current.contains(event.target as Node | null)
        ) {
            this.setState({
                show: false,
            });
        }
    }

    public render(): JSX.Element {
        let width = 15;
        let height = 15;
        let buttonBoundingRect: DOMRect | undefined =
            this.buttonRef.current?.getBoundingClientRect();
        let positionStyles = buttonBoundingRect != null ? !this.props.topAppearance ? {
            top: buttonBoundingRect.top + height + 5,
            left: buttonBoundingRect.left,
        } : {
            bottom: window.innerHeight - buttonBoundingRect.bottom + height,
            left: buttonBoundingRect.left,
        } : {}

        const body = document.getElementById("mainWrapper");
        if(body!==null)
        body!.style.overflow = this.state.show ? "hidden" : "auto";

        return (
            <div
                className="my-row"
                style={{
                    position: "relative",
                    width: width,
                    height: height,
                    backgroundColor: "transparent",
                }}
            >
                <span
                    style={{
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        fontSize: 10,
                        color: "black",
                        flex: 1,
                    }}
                />
                <div
                    ref={this.buttonRef}
                    id={
                        this.props.column != null
                            ? `import_column_${this.props.column}`
                            : undefined
                    }
                    style={{
                        padding: 0,
                        display: "flex",
                        alignItems: "center",
                        width: width,
                        height: height,
                        backgroundColor: this.state.show
                            ? "#EEEEEE"
                            : "transparent",
                        border: "0",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        this.setState((state) => ({
                            show: !state.show,
                        }));
                    }}
                >
                    <CanvasIconsContainter
                        type={"FormatMenu"}
                        options={{
                            width: width,
                            height: height,
                        }}
                    />
                </div>
                {this.state.show && buttonBoundingRect != null && (
                    <div
                        ref={this.menuRef}
                        style={{
                            ...positionStyles,
                            position: "fixed",
                            backgroundColor: "transparent",
                            zIndex: 500,
                        }}
                    >
                        <SpreadSheetFormatOptionsPopup
                            rootDataTestId={undefined}
                            options={(this.props.customSpreadSheetOptions ?? spreadSheetColumnOptionsForImport) as ColumnFormatOption[]}
                            columnFormat={this.props.format}
                            onClose={(apply, columnFormat) => {
                                this.setState({
                                    show: false,
                                });
                                if (apply) {
                                    this.props.onChangeFormat(columnFormat!);
                                }
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
}
