import ConnectCard from "./ConnectCard";
import styles from "./connectSession.module.css";
import { ReactComponent as snowflake } from "icons/guidance_wizard/snowflake.svg";
import { ReactComponent as bigQuery } from "icons/guidance_wizard/big-query.svg";
import { ReactComponent as googleSheets } from "icons/guidance_wizard/google-sheets.svg";
import { ReactComponent as hubspot } from "icons/guidance_wizard/hubspot.svg";
import { ReactComponent as salesforce } from "icons/guidance_wizard/salesforce.svg";
import { ReactComponent as text } from "icons/guidance_wizard/text.svg";
import React from "react";
import CsvUploadModal from "./components/CsvUploadModal";
import StatusPopup, { PopupStatus } from "common/StatusPopup";

const sourceTypes = [
    {
        title: "Snowflake",
        Icon: snowflake,
        enabled: true,
        connected: true,
    },
    {
        title: "Google Sheets",
        Icon: googleSheets,
        enabled: true,
        connected: false,
    },
    {
        title: "CSV",
        Icon: text,
        enabled: true,
        connected: false,
    },
    {
        title: "Google BigQuery",
        Icon: bigQuery,
        enabled: false,
        connected: false,
    },
    {
        title: "HubSpot",
        Icon: hubspot,
        enabled: false,
        connected: false,
    },
    {
        title: "Salesforce",
        Icon: salesforce,
        enabled: false,
        connected: false,
    },
];

interface IProps {
    setEntryId: (id: number) => void;
}
const ConnectSession: React.FC<IProps> = ({ setEntryId }) => {
    const [csvModalOpen, setCsvModalOpen] = React.useState<boolean>(false);
    const [statusPopupData, setStatusPopupData] = React.useState<
        | {
              message: string;
              status: PopupStatus;
          }
        | undefined
    >(undefined);
    return (
        <>
            {csvModalOpen && (
                <CsvUploadModal
                    onSuccess={(id: number) => {
                        setEntryId(id);
                        setStatusPopupData({
                            message: "Data connected successfully",
                            status: PopupStatus.Success,
                        });
                    }}
                    onError={(errorMessage: string) => {
                        setStatusPopupData({
                            message: errorMessage,
                            status: PopupStatus.Error,
                        });
                        console.log(errorMessage);
                    }}
                    onClose={() => setCsvModalOpen(false)}
                />
            )}
            {statusPopupData != null && (
                <StatusPopup
                    status={statusPopupData.status}
                    message={statusPopupData.message}
                    onClose={() => setStatusPopupData(undefined)}
                />
            )}
            <p className={styles.header}>Select data source</p>
            <div className={styles.contentWrapper}>
                <div className={styles.grid}>
                    {sourceTypes.map((sourceType) => {
                        let icon = sourceType.Icon;
                        return (
                            <ConnectCard
                                enabled={sourceType.enabled}
                                icon={icon}
                                title={sourceType.title}
                                connected={sourceType.connected}
                                onClick={() => {
                                    if (sourceType.title === "CSV")
                                        setCsvModalOpen(true);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default ConnectSession;
