import React, { Component } from "react";
import OutsideAlerter from "./OutsideAlerter";

import styles from "./ProfileSettingsPopup.module.css";
import PasswordChangeForm from "./PasswordChangeForm";
import classNames from "classnames";
import UserInfoForm from "./UserInfoForm";
import EmailForm from "./EmailForm";
import currentUser from "common/CurrentUser";

interface Props {
    onClose: () => void;
    emailInfo: {
        email: string;
        verified: boolean;
    };
    onNewEmail: () => void;
    hideAdvancedUserMenu?: boolean;
}

interface State {
    selectedTabIndex: number;
}

class ProfileSettingsPopup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedTabIndex: 0,
        };
    }

    render() {
        // let height = 350;
        let width = 650;

        const top = 60;

        const showEditProfile =
            !this.props.hideAdvancedUserMenu &&
            currentUser.info != null &&
            !currentUser.isRoot;
        const showLinkEmail =
            !this.props.hideAdvancedUserMenu && this.props.emailInfo != null;
        const screens = [];
        let indexes = 0;
        const screenIndexes: number[] = [];
        if (showEditProfile) {
            screens.push(<UserInfoForm {...this.props} />);
            screenIndexes[0] = indexes++;
        } else {
            screenIndexes[0] = -1;
        }
        screens.push(<PasswordChangeForm {...this.props} />);
        screenIndexes[1] = indexes++;

        if (showLinkEmail) {
            screens.push(<EmailForm {...this.props} />);
            screenIndexes[2] = indexes++;
        }
        return (
            <>
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#00000022",
                        zIndex: 999,
                    }}
                />
                <OutsideAlerter
                    onReject={() => {
                        this.props.onClose();
                    }}
                >
                    <div
                        className="dashboard-rect"
                        style={{
                            position: "absolute",
                            top: top,
                            alignItems: "center",
                            cursor: "pointer",
                            left: (window.innerWidth - width) / 2,
                            maxWidth: width,
                            zIndex: 100000000,
                            borderRadius: 12,
                            width: "75vw",
                            height: "75vh",
                            overflowY: "auto"
                        
                        }}
                    >
                        <ul className={styles.tabs}>
                            {showEditProfile && (
                                <li
                                    className={classNames(styles.tab, {
                                        [styles.tabSelected]:
                                            this.state.selectedTabIndex ===
                                            screenIndexes[0],
                                    })}
                                    onClick={() =>
                                        this.setState({
                                            selectedTabIndex: screenIndexes[0],
                                        })
                                    }
                                >
                                    Edit Profile
                                </li>
                            )}
                            <li
                                className={classNames(styles.tab, {
                                    [styles.tabSelected]:
                                        this.state.selectedTabIndex ===
                                        screenIndexes[1],
                                })}
                                onClick={() =>
                                    this.setState({
                                        selectedTabIndex: screenIndexes[1],
                                    })
                                }
                            >
                                Change Password
                            </li>
                            {showLinkEmail && (
                                <li
                                    className={classNames(styles.tab, {
                                        [styles.tabSelected]:
                                            this.state.selectedTabIndex ===
                                            screenIndexes[2],
                                    })}
                                    onClick={() =>
                                        this.setState({
                                            selectedTabIndex: screenIndexes[2],
                                        })
                                    }
                                >
                                    Link Email
                                </li>
                            )}
                        </ul>
                        {screens[this.state.selectedTabIndex]}
                    </div>
                </OutsideAlerter>
            </>
        );
    }
}

export default ProfileSettingsPopup;
