import React from 'react';
import styles  from './promptTemplateBar.module.css';


interface IPromptTemplateBar {
  onOptSelected: (opt:string) => void;
}


const msgs = [
  'Route my morning',
  'Remind me of my routine',
  "What did we last discuss with Dr. Burns?",
  "I'm going to Albany next week, who should I talk to?",
];


const PromptTemplateBar: React.FC<IPromptTemplateBar> = ({ onOptSelected }) => {

  return(
    <div className={styles.carousel}>
      {msgs.map((msg) => (
        <div
          onClick={() => {onOptSelected(msg)}}
          className={styles.item}
          key={msg}
        >
            {msg}
        </div>
      ))}
    </div>
  )
}

export default PromptTemplateBar;