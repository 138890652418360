import React from "react";
import CustomBarChart from "./CustomBarChart";
import { Element } from "react-scroll";
import { LegendElement } from "./LegendElement";
import { LegendEditElement } from "./LegendEditElement";
import { mainStyle } from "common/MainStyle";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import sections from "sections.json";

function LeversBenchmarkingChart(props) {
	let {
		editable,
		groupValue,
		compareValue,
		aggregateVariable,
		dataComparison,
		clustName,
		groupOperation,
		compareOperation,
		config,
		onConfigChange,
	} = props;
	if (!aggregateVariable) return null;
	let {
		maxYRange,
		minYRange,
		linesCount,
		barSize,
		labelMapping,
		colorMapping,
		legendsColor,
		axesColor,
	} = config;

	let legendFirst = groupOperation.concat(" ").concat(aggregateVariable);
	let legendSecond = compareOperation.concat(" ").concat(aggregateVariable);

	if (groupValue) legendFirst = legendFirst.concat(": ").concat(groupValue);
	if (compareValue)
		legendSecond = legendSecond.concat(": ").concat(compareValue);

	return (
		<div
			className="flex-simple-column"
			style={{
				width: "100%",
				height: "100%",
				justifyContent: "center",
			}}
		>
			<div
				className="my-row"
				style={{
					justifyContent: "center",
					marginLeft: 20,
					marginRight: 20,
					width: "100%",
					minHeight: 16,
				}}
			>
				{editable ? (
					<LegendEditElement
						allowColorChange
						onFinishEdit={(label) => {
							let newLabelMapping = {
								...labelMapping,
								[legendFirst]: label,
							};
							let newConfig = {
								...config,
								labelMapping: newLabelMapping,
							};
							onConfigChange(newConfig);
						}}
						onFinishColor={(color) => {
							let newColorMapping = {
								...colorMapping,
								"0": color,
							};
							let newConfig = {
								...config,
								colorMapping: newColorMapping,
							};
							onConfigChange(newConfig);
						}}
						color={colorMapping?.[0] ?? "#39F"}
						text={labelMapping?.[legendFirst] ?? legendFirst}
						textColor={legendsColor}
					/>
				) : (
					<LegendElement
						color={colorMapping?.[0] ?? "#39F"}
						text={labelMapping?.[legendFirst] ?? legendFirst}
						textColor={legendsColor}
					/>
				)}
				{editable ? (
					<LegendEditElement
						allowColorChange
						onFinishEdit={(label) => {
							let newLabelMapping = {
								...labelMapping,
								[legendSecond]: label,
							};
							let newConfig = {
								...config,
								labelMapping: newLabelMapping,
							};
							onConfigChange(newConfig);
						}}
						onFinishColor={(color) => {
							let newColorMapping = {
								...colorMapping,
								"1": color,
							};
							let newConfig = {
								...config,
								colorMapping: newColorMapping,
							};
							onConfigChange(newConfig);
						}}
						color={colorMapping?.[1] ?? "#EE423D"}
						text={labelMapping?.[legendSecond] ?? legendSecond}
						textColor={legendsColor}
					/>
				) : (
					<LegendElement
						color={colorMapping?.[1] ?? "#EE423D"}
						text={labelMapping?.[legendSecond] ?? legendSecond}
						textColor={legendsColor}
					/>
				)}
				{editable && (
					<LegendEditElement
						allowColorChange
						disallowTextChange
						onFinishColor={(color) => {
							let newConfig = {
								...config,
								axesColor: color,
							};
							props.onConfigChange(newConfig);
						}}
						color={
							axesColor ??
							mainStyle
								.getPropertyValue("--graphs-axes-text-color")
								.trim()
						}
						text={"Axes Color"}
					/>
				)}
				{editable && (
					<LegendEditElement
						allowColorChange
						disallowTextChange
						onFinishColor={(color) => {
							let newConfig = {
								...config,
								legendsColor: color,
							};
							props.onConfigChange(newConfig);
						}}
						color={
							legendsColor ??
							mainStyle
								.getPropertyValue("--graphs-legends-text-color")
								.trim()
						}
						text={"Legends Color"}
					/>
				)}
			</div>
			<div
				className="my-row"
				style={{
					justifyContent: "center",
					height: "100%",
					width: "100%",
				}}
			>
				<Element
					name="scrollable"
					className="element"
					style={{
						height: "100%",
						paddingTop: 10,
						overflowX: mobileBreakpoint() ? "hidden" : "auto",
						overflowY: "hidden",
						backgroundColor: "transparent",
						position: "relative",
					}}
				>
					<CustomBarChart
						maxYRange={maxYRange}
						minYRange={minYRange}
						linesCount={linesCount}
						axesColor={axesColor}
						barSize={barSize}
						watermark={sections._Global?.watermark}
						barKeys={[groupOperation, compareOperation]}
						barColors={[
							colorMapping?.[0] ?? "#39F",
							colorMapping?.[1] ?? "#EE423D",
						]}
						data={dataComparison}
						title={clustName}
					/>
				</Element>
			</div>
		</div>
	);
}

export default LeversBenchmarkingChart;