import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import { aggregationFunctions } from "common/AggregationFunctionsv2";
import DashboardUpdater from "modules/canvas_page/DashboardUpdater";
import { CanvasDashboard } from "./Canvas";
import NumericOption from "./NumericOption";
import remoteModuleId from "./remoteModuleId";
import { SearchComponentOption } from "./SearchComponent";
import Variables from "./Variables";

export enum DynamicOptionType {
    Aggregation = 1,
    Variable = 2,
    DataVariable = 3,
    SideBySideVariable = 4,
    SideBySideValue = 5,
    Filter = 6,
    CenterMapByValue = 7,
}

export function getOptions(
    dynamicOption: DynamicOption,
    dashboard: CanvasDashboard,
    moduleId?: number
) {
    if (dynamicOption.type === DynamicOptionType.Aggregation) {
        return aggregationFunctions;
    }
    if (dynamicOption.type === DynamicOptionType.DataVariable) {
        let dataScopeId = dashboard.finding?.config?.dataScope?.value;
        if (dataScopeId == null) return [];
        return Variables(dataScopeId, moduleId ?? remoteModuleId)
            .variableOptions;
    }
}

export function getValue(
    dynamicOption: DynamicOption,
    dashboard: CanvasDashboard
): SearchComponentOption | SearchComponentOption[] | null {
    if (dynamicOption.type === DynamicOptionType.CenterMapByValue) {
        let value = dashboard.finding?.config?.[
            dynamicOption.key
        ] as SearchComponentOption | null;
        if (value == null) return null;
        return value;
    } else if (dynamicOption.type === DynamicOptionType.Aggregation) {
        let value = dashboard.finding?.config?.[dynamicOption.key] as
            | string
            | null;
        if (value == null) return null;
        return (
            aggregationFunctions.find((item) => item.value === value) ?? null
        );
    } else if (dynamicOption.type === DynamicOptionType.DataVariable) {
        if (dashboard.finding?.content?.data != null) {
            return dashboard.finding.content.data.map(
                (
                    item: {
                        originalName?: string;
                        variableIndex?: number;
                        name: string;
                        index: number;
                    },
                    index: number
                ) =>
                    item.variableIndex != null
                        ? {
                              label: item.originalName,
                              value: item.variableIndex,
                          }
                        : {
                              label: item.name,
                              value: -index - 1,
                          }
            );
        }
        return [];
    }
    return null;
}

export function applyValue(
    canvasTreeStore: CanvasTreeStore,
    dynamicOption: DynamicOption,
    dashboard: CanvasDashboard,
    value: any
) {
    let newDashboard = { ...dashboard };
    if (newDashboard.finding?.config) {
        if (dynamicOption.type === DynamicOptionType.Aggregation) {
            newDashboard.finding = {
                ...newDashboard.finding,
                config: { ...newDashboard.finding.config },
            };
            (newDashboard.finding?.config as any)[dynamicOption.key] =
                value.value;
            canvasTreeStore.updateDashboardAction(
                dynamicOption.dashboardId,
                newDashboard
            );
            canvasTreeStore.updateDashboards([dynamicOption.dashboardId]);
        } else if (dynamicOption.type === DynamicOptionType.CenterMapByValue) {
            newDashboard.finding = {
                ...newDashboard.finding,
                config: { ...newDashboard.finding.config },
            };
            (newDashboard.finding?.config as any)[dynamicOption.key] = value;
            canvasTreeStore.updateDashboardAction(
                dynamicOption.dashboardId,
                newDashboard,
                true
            );
        } else if (dynamicOption.type === DynamicOptionType.Filter) {
            newDashboard.finding = {
                ...newDashboard.finding,
                config: { ...newDashboard.finding.config },
            };
            (newDashboard.finding?.config as any)[dynamicOption.key] = value;
            canvasTreeStore.updateDashboardAction(
                dynamicOption.dashboardId,
                newDashboard
            );
            canvasTreeStore.updateDashboards([dynamicOption.dashboardId]);
        } else if (dynamicOption.type === DynamicOptionType.DataVariable) {
            newDashboard.finding = {
                ...newDashboard.finding,
                content: { ...newDashboard.finding.content },
            };
            let data = newDashboard.finding?.content?.data;
            if (data != null) {
                if (value == null || value.length === 0) {
                    data = DashboardUpdater.getApiV2(
                        newDashboard.finding.config.journeyName
                    ).getPreviewFinding(newDashboard.finding.config.journeyName)
                        .content.data;
                    newDashboard.finding.content.data = data;
                    canvasTreeStore.updateDashboardAction(
                        dynamicOption.dashboardId,
                        newDashboard
                    );
                    canvasTreeStore.updateDashboards([
                        dynamicOption.dashboardId,
                    ]);
                } else {
                    if (dynamicOption.minCount != null) {
                        if (value.length < dynamicOption.minCount) {
                            return;
                        }
                    }
                    if (dynamicOption.maxCount != null) {
                        value = value.slice(
                            value.length - dynamicOption.maxCount
                        );
                    }
                    data = value.map((item: NumericOption, index: number) => ({
                        ...data[index],
                        name: item.label,
                        originalName: item.value < 0 ? null : item.label,
                        variableIndex: item.value < 0 ? null : item.value,
                    }));
                }
                newDashboard.finding.content.data = data;
                canvasTreeStore.updateDashboardAction(
                    dynamicOption.dashboardId,
                    newDashboard
                );
                canvasTreeStore.updateDashboards([dynamicOption.dashboardId]);
            }
        }
    }
}

export interface DynamicOption {
    key: string;
    title: string;
    type: DynamicOptionType;
    allowMultiple: boolean;
    dashboardId: string;
    maxCount?: number;
    minCount?: number;
}
