import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { CanvasInput, CanvasNode } from "common/Canvas";
import TabTitle from "common/TabTitle";
import { GlobalContextContents } from "GlobalContext";
import Settings from "./Settings";
import Select, { GroupBase, createFilter } from "react-select";
import { ReactComponent as CloseIcon } from "icons/canvas/map/close.svg";
import styles from "./Toggle.module.css";
import DefaultValueOption from "../common/DefaultValueOption";
import selectStyles from "../../../../common/SelectStyles";
import Variables, { VariableOption } from "common/Variables";
import remoteModuleId from "common/remoteModuleId";
import GlobalInputs from "common/GlobalInputs";
import DataScopesForModules from "common/DataScopesForModules";
import DataScopes, { DataScopeOption } from "common/DataScopes";
import Tables from "common/Tables";


enum InputType {
    Regular = 1,
    Linked = 2,
    Global = 3,
    Cloud = 4,
    CloudInput = 5,
}

interface InputOption {
    label: string;
    value: number | number[];
    type?: InputType;
    outputIndex?: number;
    canvasId?: number;
    outerId?: string;
}

interface Props {
    currentModuleId: number | undefined;
    node: CanvasInput;
    shared: boolean;
    globalContext: GlobalContextContents;
    onChange: (
        changes: Partial<CanvasInput | CanvasNode>,
        commit?: boolean
    ) => void;
    onChangeShared: (shared: boolean) => void;
    onClose: () => void;
}

function Input({
    currentModuleId,
    node,
    shared,
    globalContext,
    onChange,
    onChangeShared,
    onClose,
}: Props) {
    const [selectedTab, setSelectedTab] = useState("settings");
    let [labelText, setLabelText] = React.useState(node?.label ?? "Label");
    let dataScopes =
        currentModuleId != null
            ? DataScopesForModules(currentModuleId)
            : DataScopes;

    function getOptions(): InputOption[] {
        let options: InputOption[] = [];
        options = options
            .concat(
                GlobalInputs.map((globalInput) => ({
                    ...globalInput,
                    type: InputType.Global,
                }))
            );

        return options;
    }

    function selectTab(tabKey: string | null) {
        if (tabKey == null) return;
        setSelectedTab(tabKey);
    }

    const [contextOptions, setContextOptions] = React.useState<InputOption[]>([]);

    React.useEffect(() => {
        let options = getOptions();
        setContextOptions(options);
    }, []);


    return (
        <div>
            <div className={styles.editMenuHeader}>
                <h6 className={styles.editMenuHeaderTitle}>Input Field</h6>
                <button
                    type="button"
                    onClick={onClose}
                    className={styles.editMenuCloseButton}
                >
                    <CloseIcon />
                </button>
            </div>
            <Tabs
                id="advanced-menu-input-tabs"
                activeKey={selectedTab}
                onSelect={selectTab}
                className={styles.tabMenu}
            >
                <Tab
                    eventKey="settings"
                    title={
                        <TabTitle
                            title="Settings"
                            selected={selectedTab === "settings"}
                            className={styles.tabTitle}
                        />
                    }
                    className={styles.tab}
                >
                    <Settings
                        node={node}
                        shared={shared}
                        globalContext={globalContext}
                        onChange={onChange}
                        onChangeShared={onChangeShared}
                    />
                    <DefaultValueOption node={node} onChange={onChange} />
                    <div
                        className={styles.field}
                        style={{
                            padding: "0 20px",
                        }}
                    >
                        <span className={styles.label}>Label text</span>
                        <input
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                                if (evt.key === "Enter") {
                                    onChange({ label: labelText });
                                }
                            }}
                            className={styles.input}
                            value={labelText}
                            onChange={(evt) => {
                                setLabelText(evt.target.value)
                            }}
                            onBlur={() => {
                                onChange({ label: labelText });
                            }}
                        ></input>
                    </div>
                    <div
                        style={{ padding: 20 }}
                    >
                        <div
                            className="my-row"
                            style={{
                                alignItems: "center",
                                marginTop: "5px",
                            }}
                        >
                            <span
                                className={styles.thinOptionName}
                                style={{ marginTop: 30 }}
                            >
                                Update Element
                            </span>

                            <div
                                style={{ marginTop: 30, marginLeft: 15 }}
                            >
                                <div className="pretty p-default" contentEditable={false}>
                                    <input
                                        type="checkbox"
                                        checked={node.update?.active}
                                        onChange={() => {
                                            onChange({
                                                update: {
                                                    ...node.update,
                                                    active: !node.update?.active ?? true
                                                }
                                            });
                                        }}
                                    />
                                    <div className="state p-primary">
                                        <label>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(node.update?.active) && (
                            <>
                                <div className={styles.rowField} style={{ marginTop: "10px" }}>
                                    <span className={styles.label}>Dataset</span>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        styles={selectStyles}
                                        options={dataScopes.dataScopesOptions as DataScopeOption[]}
                                        onChange={(newValue) => {
                                            onChange({
                                                dataScopeOption: newValue as DataScopeOption,
                                                tableOption: Tables(
                                                    (newValue as any).value,
                                                    currentModuleId ?? remoteModuleId
                                                ).tableToOption()
                                            });
                                        }}
                                        value={node.dataScopeOption}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}

                                    />
                                </div>
                                <div className={styles.rowField} style={{ marginTop: "10px" }}>
                                    <span className={styles.label}>Context ID</span>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        styles={selectStyles}
                                        options={contextOptions}
                                        value={node.update?.contextId}
                                        onChange={(newValue) => {
                                            onChange({
                                                update: {
                                                    ...node.update,
                                                    contextId: newValue
                                                }
                                            });
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                                <div
                                    className={styles.rowField}
                                    style={{ marginTop: "10px" }}
                                >
                                    <span className={styles.label}>Context Variable</span>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        styles={{
                                            ...selectStyles,
                                        }}
                                        options={Variables(
                                            node.dataScopeOption?.value,
                                            currentModuleId ?? remoteModuleId
                                        ).dataVariables.map((variable) => ({
                                            label: variable.name,
                                            value: variable.index ?? -1,
                                            type: variable.type,
                                            panel: variable.panel,
                                        }))}
                                        onChange={(newValue) => {
                                            onChange({
                                                update: {
                                                    ...node.update,
                                                    variableOption: newValue as VariableOption
                                                }
                                            });
                                        }}
                                        value={node.update?.variableOption}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                                <div
                                    className={styles.rowField}
                                    style={{ marginTop: "10px" }}
                                >
                                    <span className={styles.label}>Update Column</span>
                                    <Select
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        placeholder={""}
                                        styles={{
                                            ...selectStyles,
                                        }}
                                        options={Variables(
                                            node.dataScopeOption?.value,
                                            currentModuleId ?? remoteModuleId
                                        ).dataVariables.map((variable) => ({
                                            label: variable.name,
                                            value: variable.index ?? -1,
                                            type: variable.type,
                                            panel: variable.panel,
                                        }))}
                                        onChange={(newValue) => {
                                            onChange({
                                                variableOption: newValue as VariableOption
                                            });
                                        }}
                                        value={node.variableOption}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}

export default Input;
