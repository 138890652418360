import { Component } from "react";

export default class BaseSubmodule extends Component {
    constructor(props) {
        if(new.target === BaseSubmodule)
            throw new TypeError("Cannot construct BaseSubmodule instances directly");
        super(props);
    }

    static getNumberOfSteps() {
        throw new TypeError("Please implement static abstract method getNumberOfSteps");
    }

    static getTitle() {
        throw new TypeError("Please implement static abstract method getTitle");
    }
}
