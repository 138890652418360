import React, { Component } from "react";
import { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button } from "react-bootstrap";
import Draggable from "react-draggable";
import Popup from "reactjs-popup";
import { observer } from "mobx-react";
import customSelectStyles, { leftAlignAndScrollBarCSS } from "common/SelectStyles";
import DataScopes, {
    DataScopeOption,
    DataScopeOptionWithNull,
} from "common/DataScopes";
import DataScopesForModules from "common/DataScopesForModules";
import { Permission } from "common/Permissions";
import { mainStyle } from "common/MainStyle";
import StatusPopup, { PopupStatus } from "common/StatusPopup";
// import { lightThemeStyle } from "common/LightThemeStyle";
import remoteModuleId from "common/remoteModuleId";
import { writeCustomRegressionSummary } from "common/InputData";
import Variables from "common/Variables";
import { ReactComponent as CrossIcon } from "icons/popups/cross.svg";
import SelectedOption from "modules/data_hub_page/SelectedOption";


interface Props {
    regressionResults: any;
    currentModuleId?: number;
    onClose: () => void;
}

interface State {
    left: number;
    top: number;
    popupMessage: string;
    popupStatus: PopupStatus | null;
    newDataScope: DataScopeOptionWithNull | null;
    dataScope: DataScopeOptionWithNull | undefined;
    savingData: boolean;
    datasetName: string;
}

@observer
class SaveRegressionResultsPopup extends Component<Props, State> {
    drag: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            left: 0,
            top: 0,
            dataScope: undefined,
            newDataScope: null,
            popupStatus: null,
            popupMessage: "",
            savingData: false,
            datasetName: ""
        };

        this.saveRegressionResults = this.saveRegressionResults.bind(this);
    }

    private saveRegressionResults(): void {
        this.setState({ savingData: true },
            () => {
                if (this.state.dataScope == null) return;
                let dataScopeOption: DataScopeOption = {
                    label: this.state.dataScope.label,
                    value: this.state.dataScope.value ?? NaN,
                    permissionType: this.state.dataScope.permissionType,
                };
                writeCustomRegressionSummary(
                    dataScopeOption,
                    this.props.regressionResults,
                    this.props.currentModuleId
                )
                    .then((dataTableIdx) => {
                        DataScopes.update();
                        Variables(dataTableIdx).update(
                            this.props.currentModuleId ?? remoteModuleId
                        );
                        this.setState({
                            savingData: false,
                            popupMessage: "Data Saved",
                            popupStatus: PopupStatus.Success,
                        });
                        this.props.onClose();

                        SelectedOption.UpdateaskSwitchTab(false);
                        SelectedOption.UpdateSwitchTab(true);
                    })
                    .catch((error) => {
                        this.setState({
                            savingData: false,

                            popupMessage: String(error),
                            popupStatus: PopupStatus.Error,
                        });
                    });
            });
    }

    private buildInnerView(): JSX.Element {
        const dataScopes =
            this.props.currentModuleId == null
                ? DataScopes
                : DataScopesForModules(this.props.currentModuleId);


        return (
            <div
                className="flex-simple-column"
            >
                {/* <p style={{ position: 'absolute', top: 15, right: 10 }} onClick={() => this.props.onClose()}>
                    <CrossIcon stroke='grey' />
                </p> */}
                <div className="saveRegressionHeader">
                    <p>Save regression results</p>
                </div>
                <CreatableSelect
                    filterOption={createFilter({
                        ignoreAccents: false,
                    })}
                    formatCreateLabel={() => undefined}
                    onCreateOption={(option) => {
                        this.setState({
                            newDataScope: {
                                label: option,
                                value: null,
                                permissionType: Permission.ReadWrite,
                            },
                            dataScope: {
                                label: option,
                                value: null,
                                permissionType: Permission.ReadWrite,
                            },
                        });
                    }}
                    placeholder={"Create new or replace existing data set"}
                    styles={{
                        ...customSelectStyles,
                        ...leftAlignAndScrollBarCSS,
                        container: (base) => ({
                            ...base,
                            width: "93%",
                            height: "38px",
                            marginTop: "10px",
                            marginBottom: "20px",
                            marginInline: 'auto',
                        }),
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 100000000,
                        }),
                        menu: (base, state) => ({
                            ...base,
                            display: dataScopes.dataScopesOptions.filter(item => item.label.includes(this.state.datasetName)).length > 0 ? "block" : "none",
                            borderRadius: 6,
                            overflow: 'auto'
                        })
                    }}
                    onInputChange={(e) => {
                        this.setState({ datasetName: e })
                    }}
                    options={(dataScopes.dataScopesOptions as DataScopeOptionWithNull[])
                        .filter(
                            (option) =>
                                option.permissionType === Permission.ReadWrite
                        )
                        .concat(
                            this.state.newDataScope != null
                                ? [this.state.newDataScope]
                                : []
                        )
                    }
                    onChange={(newValue) => {
                        let stateDiff: any = {
                            dataScope: newValue as DataScopeOptionWithNull,
                            permissions: [],
                        };

                        this.setState(stateDiff);
                    }}
                    onBlur={(e) => {
                        e.target.value &&
                            this.setState({
                                newDataScope: {
                                    label: e.target.value,
                                    value: null,
                                    permissionType: Permission.ReadWrite,
                                },
                                dataScope: {
                                    label: e.target.value,
                                    value: null,
                                    permissionType: Permission.ReadWrite,
                                },
                            });
                    }}
                    value={this.state.dataScope}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: "white",
                            primary25:
                                "var(--selectors-background-hover-color)",
                        },
                    })}
                    menuPortalTarget={document.body}
                // menuShouldBlockScroll={true}
                />
                <div
                    className="popupbuttonrow"
                >
                    <button
                        type="button"
                        className="cancelBtn"

                        onClick={() => this.props.onClose()}
                    >
                        CANCEL
                    </button>
                    <button
                        type="button"
                        className="acceptBtn"
                        disabled={
                            this.state.dataScope == null ||
                            this.state.savingData
                        }
                        // onClick={() => { this.saveRegressionResults(); this.props.onClose() }}
                        onClick={() => (
                            this.saveRegressionResults()
                        )}
                    >
                        SAVE
                    </button>
                </div>
            </div >
        );
    }

    render() {
        const width: number = 500;
        return (
            <>
                <Popup
                    arrow={true}
                    contentStyle={{
                        width: width,
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    overlayStyle={{
                        zIndex: 100000000,
                    }}
                    open={true}
                    onClose={() => { }}
                    closeOnDocumentClick={false}
                >
                    <Draggable
                        cancel="input"
                        position={{
                            x: this.state.left,
                            y: this.state.top,
                        }}
                        onDrag={() => {
                            this.drag = true;
                        }}
                        onStop={(_evt, data) => {
                            if (this.drag) {
                                this.drag = false;
                                this.setState({
                                    left: data.x,
                                    top: data.y,
                                });
                            }
                        }}
                    >
                        <div
                            className="dashboard-rect element"
                            style={{
                                overflowX: "visible",
                                overflowY: "auto",
                                boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                                alignItems: "center",
                                cursor: "pointer",
                                maxHeight: "100vh",
                                width: 600,
                                borderRadius: 10
                            }}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            {this.buildInnerView()}
                        </div>
                    </Draggable>
                </Popup>
                {this.state.popupStatus != null && (
                    <StatusPopup
                        onClose={() => {
                            this.setState({
                                popupMessage: "",
                                popupStatus: null,
                            });
                        }}
                        status={this.state.popupStatus}
                        message={this.state.popupMessage}
                    />
                )}
                {this.state.savingData && (
                    <StatusPopup
                        onClose={() => {
                            this.setState({
                                popupMessage: "",
                                popupStatus: null,
                            });
                        }}
                        status={PopupStatus.Success}
                        message={"Saving data..."}
                    />
                )}
            </>
        );
    }
}

export default SaveRegressionResultsPopup;