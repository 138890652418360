import { conditionsToJson, filterNonEmptyConditions } from "common/Conditions";
import axios from "common/ServerConnection";
import { configVersionV2 } from "common/PathConfigVersion";
import { HoldOutPredictionFinding } from "common/Finding";
import {
    isSklearn,
    statsmodelsRegressions as regressions,
} from "common/Regressions";
import { SearchComponentOption } from "common/SearchComponent";
import StringOption from "common/StringOption";

class Api {
    static getDefaultConfig(
        journeyName: string
    ): HoldOutPredictionFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
            dvLog: false,
            intercept: true,
            independentVariables: {},
            interactions: {},
            selectedRegression: regressions[0],
            cacheData: true,
            confidenceLevel: 95,
            datasetFraction: 50,
            showLeverBars: true,
            showTrueValue: true,
        };
    }

    static getPreviewFinding(journeyName: string): HoldOutPredictionFinding {
        let item = {
            type: "holdout",
            content: {
                data: {
                    ivMainNames: [],
                    ivOtherNames: [],
                    predictedBars: [],
                },
            },
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }

    static getData(
        finding: HoldOutPredictionFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<HoldOutPredictionFinding> {
        const getRegressionInfo = (forQuery: boolean) => {
            let regressionInfo: HoldOutPredictionFinding["content"]["regressionInfo"] = {
                dv: dependentVariable!.label,
                dv_log: dvLog,
                iv: {
                    main: ivMain.label,
                    main_log: ivMain.log,
                    main_fixed: ivMain.fixed,
                    other: ivOther.map((item) => item.label),
                    other_log: ivOther.map((item) => item.log),
                    other_fixed: ivOther.map((item) => item.fixed),
                    interactions: Object.keys(interactions)
                        .filter(
                            (key) =>
                                interactions[key] != null &&
                                independentVariables[key] != null &&
                                independentVariables[interactions[key]] != null
                        )
                        .map((key) => ({
                            var1: independentVariables[Number(key)]!.label,
                            var2: independentVariables[interactions[key]]!
                                .label,
                        })),
                },
                intercept: intercept,
                model: {
                    name: selectedRegression!,
                    family: selectedFamily ?? (forQuery ? undefined : null),
                    link: selectedLink ?? (forQuery ? undefined : null),
                },
                predict_split: {
                    train_size: datasetFraction / 100,
                    confidence_level: confidenceLevel / 100,
                },
            };
            return regressionInfo;
        };
        let {
            dataScope,
            selectedTable,
            conditions,
            additionalOperators,
            sideBySideVariableIndex,
            additionalValues,
            dvLog,
            dependentVariable,
            independentVariables,
            interactions,
            intercept,
            selectedFamily,
            selectedLink,
            selectedRegression,
            datasetFraction,
            confidenceLevel,
        } = finding.config ?? {};
        let iv: {
            label: string;
            value: number;
            log: boolean;
            fixed: boolean;
            show: boolean;
        }[] = Object.values(independentVariables).filter(
            (item) => item != null
        ) as any;
        if (iv.length === 0 || dependentVariable == null)
            return Promise.resolve(finding);
        additionalValues = (additionalValues ?? [])
            .filter((value: SearchComponentOption) => value?.value != null)
            .map((value: SearchComponentOption) => value.value);
        additionalOperators = (additionalOperators ?? []).filter(
            (operator: StringOption, index: number) =>
                additionalValues[index]?.value != null
        );

        conditions = filterNonEmptyConditions(conditions ?? []);
        let requestJson: any = {
            conditions: conditionsToJson(conditions),
            table: selectedTable!.value,
            condition_id: selectedTable!.condition_id,
            data_table_idx:
                typeof dataScope === "undefined" ? 0 : dataScope!.value,
            module_id: moduleId,
        };
        let ivMain = iv[0];

        let ivOther = iv.filter((item) => item.value !== ivMain.value);

        let regressionInfo = getRegressionInfo(true);
        let regressionInfoCache = getRegressionInfo(false);
        if (sideBySideVariableIndex != null && additionalValues!.length > 0) {
            requestJson.where = {
                group_index: sideBySideVariableIndex,
                expressions: additionalValues!.map(
                    (value: SearchComponentOption, index: number) => ({
                        operation: additionalOperators[index]?.value ?? "=",
                        value: value,
                    })
                ),
            };
        }
        requestJson = {
            ...requestJson,
            ...regressionInfo,
        };
        let url = "/api/e/get_custom_hold_out_regression";
        if (isSklearn(selectedRegression)) {
            url = "/api/e/get_custom_sklearn_hold_out_regression";
        }
        return axios
            .post<{
                iv_main_names: string[];
                iv_other_names: string[][];
                predictions: {
                    dv: {
                        sample_size: number;
                        mean_predicted: number;
                        mean_true: number[];
                        mean_iv: number[];
                        model_id: number;
                        confidence_interval: number[];
                    };
                }[];
                success: boolean;
                error_msg: string;
            }>(url, requestJson)
            .then((response) => {
                if (response.data.success) {
                    let newFinding = { ...finding };
                    let { success, error_msg, ...nativeData } = response.data;
                    let expressions: {
                        operation: StringOption;
                        value: SearchComponentOption;
                    }[] = [];
                    let predictions = response.data.predictions;
                    newFinding.content.data = {
                        userValues: finding.content.data.userValues,
                        ivMainNames: [],
                        ivOtherNames: [],
                        predictedBars: [],
                    };
                    newFinding.content.data.ivMainNames =
                        response.data.iv_main_names;
                    newFinding.content.data.ivOtherNames = response.data.iv_other_names.flat();
                    let predictedBars = predictions.map((item) => ({
                        confidence_level: confidenceLevel,
                        show_intervals: true,
                        sample_size: item["dv"].sample_size,
                        mean_predicted: item["dv"].mean_predicted,
                        mean_iv: item["dv"].mean_iv,
                        model_id: item["dv"].model_id,
                        confidence_interval: item["dv"]["confidence_interval"],
                        mean_true: item["dv"].mean_true[0],
                    }));
                    newFinding.content.data.predictedBars = predictedBars;
                    if (
                        finding.config.sideBySideVariable != null &&
                        finding.config.additionalValues != null &&
                        finding.config.additionalOperators != null
                    ) {
                        expressions = finding.config.additionalValues.map(
                            (value: SearchComponentOption, index: number) => ({
                                operation:
                                    finding.config.additionalOperators[index],
                                value: value,
                            })
                        );
                    }
                    expressions = expressions.filter(
                        (item) => item.value != null
                    );
                    let groupNames = expressions.map(
                        (expression) =>
                            `${finding.config.sideBySideVariable} ${expression.operation.label} ${expression.value.label}`
                    );
                    newFinding.content.groupNames =
                        groupNames.length > 0 ? groupNames : null;
                    newFinding.content.regressionInfo = regressionInfoCache;
                    newFinding.content.nativeData = nativeData;
                    return Promise.resolve(newFinding);
                } else {
                    return Promise.reject(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
    }
}

export { Api };
