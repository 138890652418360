import { configVersionV2 } from "common/PathConfigVersion";
import { AiCopilotFinding } from "common/Finding";

class Api {
    static getDefaultConfig(journeyName: string): AiCopilotFinding["config"] {
        return {
            journeyName: journeyName,
            version: configVersionV2,
        };
    }

    static getPreviewFinding(journeyName: string): AiCopilotFinding {
        let itemType = "aicopilot";
        let item = {
            type: itemType,
            content: {},
            config: Api.getDefaultConfig(journeyName),
        };
        return item;
    }
    static getData(
        finding: AiCopilotFinding,
        findingOptions: any,
        moduleId: number
    ): Promise<AiCopilotFinding> {
        return Promise.resolve(finding);
    }
}

export { Api };
