import React, { Component } from "react";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";

import customSelectStyles from "common/SelectStyles";
import { UserButton } from "common/UserIcon";
import PasswordStrength from "common/PasswordStrength";
import UpdateStatusAlert from "./UpdateStatusAlert";
import UpdateStatus from "./UpdateStatus";
import ColorPicker from "common/ColorPicker";
import cx from "classnames";
import TimezoneSelect from "react-timezone-select";

export interface Field {
    name: string;
    label: string;
    value: string;
    editable?: boolean;
    hidden?: boolean;
    required?: boolean;
    passwordStrength?: boolean;
    icon?: boolean;
    select?: boolean;
    options?: string[];
    add_mock_after?: boolean;
    timezoneSelect?: boolean;
}

interface Props {
    title: string;
    value: Field[];
    onChange: (value: Field[]) => void;
    onUpdate: () => void;
    onReset?: () => void;
    onCancel?: () => void;
    buttonTitle?: string;
    buttonColor?: string;
    textColor?: string;
    inputFieldTextColor?: string;
    inputFieldColor?: string;
    titleClassName?: string;
    buttonsContainerClassName?: string;
    beforeInputs?: JSX.Element;
    afterInputs?: JSX.Element;
    fieldsContainerClassName?: string;
    status: UpdateStatus;
    onStatusChange: (status: UpdateStatus) => void;
    errorMessage: string;
    updateButtonDisabled?: boolean;
}

class FieldEditor extends Component<Props> {
    render() {
        return (
            <div style={{ marginTop: 20 }}>
                {this.props.title && (
                    <span
                        className={cx(
                            "big-title-span",
                            this.props.titleClassName
                        )}
                        style={{ color: this.props.textColor }}
                    >
                        {this.props.title}
                    </span>
                )}
                <div
                    style={{ marginTop: 10, width: 500 }}
                    className={cx(
                        "flex-simple-column",
                        this.props.fieldsContainerClassName
                    )}
                >
                    {this.props.beforeInputs}
                    {this.props.value.map((field: Field, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div style={{ marginBottom: "10px" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div
                                            className="regular-text"
                                            style={{
                                                marginLeft: "19px",
                                                paddingLeft: "15px",
                                                marginRight: "15px",
                                                minWidth: "8em",
                                                color: this.props.textColor,
                                            }}
                                        >
                                            {field.label}:
                                        </div>
                                        {field.timezoneSelect && (
                                            <TimezoneSelect
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                menuPortalTarget={document.body}
                                                placeholder={""}
                                                styles={{
                                                    ...customSelectStyles,
                                                    container: (base) => ({
                                                        ...base,
                                                        height: "38px",
                                                        width: "140px",
                                                    }),
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 1000000000,
                                                    }),
                                                }}
                                                value={field.value}
                                                onChange={(option) => {
                                                    let newSettings =
                                                        Array.from(
                                                            this.props.value
                                                        );
                                                    newSettings[index] = {
                                                        ...newSettings[index],
                                                        value: (
                                                            option as {
                                                                label: string;
                                                                value: string;
                                                            }
                                                        ).value,
                                                    };
                                                    this.props.onChange(
                                                        newSettings
                                                    );
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        )}
                                        {field.select && (
                                            <Select
                                                filterOption={createFilter({
                                                    ignoreAccents: false,
                                                })}
                                                placeholder={""}
                                                styles={{
                                                    ...customSelectStyles,
                                                    container: (base) => ({
                                                        ...base,
                                                        height: "38px",
                                                        width: "140px",
                                                    }),
                                                }}
                                                options={field.options?.map(
                                                    (option) => ({
                                                        label: option,
                                                        value: option,
                                                    })
                                                )}
                                                value={{
                                                    label: field.value,
                                                    value: field.value,
                                                }}
                                                onChange={(newValue) => {
                                                    let newSettings =
                                                        Array.from(
                                                            this.props.value
                                                        );
                                                    newSettings[index] = {
                                                        ...newSettings[index],
                                                        value: (
                                                            newValue as {
                                                                label: string;
                                                                value: string;
                                                            }
                                                        ).value,
                                                    };
                                                    this.props.onChange(
                                                        newSettings
                                                    );
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: "white",
                                                        primary25:
                                                            "var(--selectors-background-hover-color)",
                                                    },
                                                })}
                                            />
                                        )}
                                        {!field.timezoneSelect &&
                                            !field.select &&
                                            !field.icon &&
                                            (field.name.endsWith("-color") ? (
                                                <ColorPicker
                                                    value={field.value}
                                                    onChange={(newValue) => {
                                                        let newSettings =
                                                            Array.from(
                                                                this.props.value
                                                            );
                                                        newSettings[index] = {
                                                            ...newSettings[
                                                                index
                                                            ],
                                                            value: newValue,
                                                        };
                                                        this.props.onChange(
                                                            newSettings
                                                        );
                                                    }}
                                                    className="like-select"
                                                    style={{
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                        paddingLeft: "8px",
                                                        paddingRight: "8px",
                                                        width: "10em",
                                                        height: "38px",
                                                    }}
                                                />
                                            ) : (
                                                <input
                                                    type={
                                                        field.hidden
                                                            ? "password"
                                                            : "text"
                                                    }
                                                    disabled={!field.editable}
                                                    className="like-select"
                                                    style={{
                                                        backgroundColor:
                                                            this.props
                                                                .inputFieldColor,
                                                        color: this.props
                                                            .inputFieldTextColor,
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                        width: "10em",
                                                    }}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let newSettings =
                                                            Array.from(
                                                                this.props.value
                                                            );
                                                        newSettings[index] = {
                                                            ...newSettings[
                                                                index
                                                            ],
                                                            value: e.target
                                                                .value,
                                                        };
                                                        this.props.onChange(
                                                            newSettings
                                                        );
                                                    }}
                                                    value={field.value}
                                                />
                                            ))}
                                        {field.icon && (
                                            <UserButton
                                                identifier="-1"
                                                iconUrl={field.value}
                                                onLoad={(result) => {
                                                    if (
                                                        typeof result ===
                                                        "string"
                                                    ) {
                                                        let newSettings =
                                                            Array.from(
                                                                this.props.value
                                                            );
                                                        newSettings[index] = {
                                                            ...newSettings[
                                                                index
                                                            ],
                                                            value: result,
                                                        };
                                                        this.props.onChange(
                                                            newSettings
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                    {field.passwordStrength && (
                                        <PasswordStrength
                                            password={field.value}
                                            leftText=""
                                            leftTextStyle={{
                                                flex: 1,
                                                color: this.props.textColor,
                                            }}
                                            rightTextStyle={{
                                                color: this.props.textColor,
                                            }}
                                            progressBarStyle={{
                                                width: "10em",
                                                backgroundColor: "transparent",
                                            }}
                                            swapProgressBarAndRightText={true}
                                        />
                                    )}
                                </div>
                                {field.add_mock_after && <div />}
                            </React.Fragment>
                        );
                    })}
                    {this.props.afterInputs}
                </div>
                <UpdateStatusAlert
                    value={this.props.status}
                    onChange={this.props.onStatusChange}
                    errorMessage={this.props.errorMessage}
                />
                <div
                    className={cx(
                        "flex-simple-column",
                        this.props.buttonsContainerClassName
                    )}
                >
                    {this.props.onCancel &&
                        <Button
                            type="button"
                            className="btn btn-sm"
                            style={{
                                marginLeft: "5px",
                                marginBottom: 20,
                                width: "112px",
                                fontSize: 14,
                                color: '#3b82c9',
                                border: '1px solid #e0e0e0',
                                backgroundColor: 'white'
                            }}
                            onClick={() => {
                                if(this.props.onCancel)
                                    this.props.onCancel();
                            }}
                        >
                            Cancel
                        </Button>
                    }
                    <Button
                        type="button"
                        className="btn btn-sm btn-primary my-primary"
                        disabled={this.props.updateButtonDisabled}
                        style={{
                            marginLeft: "5px",
                            marginBottom: 20,
                            width: "112px",
                            backgroundColor:
                                this.props.buttonColor || "#3b82c9",
                            fontSize: 14,
                        }}
                        onClick={() => {
                            this.props.onUpdate();
                        }}
                    >
                        {this.props.buttonTitle ?? "Update"}
                    </Button>
                    {this.props.onReset != null && (
                        <Button
                            type="button"
                            className="btn btn-lg btn-primary my-primary"
                            style={{
                                backgroundColor: this.props.buttonColor,
                                marginLeft: "19px",
                                marginBottom: 10,
                                width: 200,
                            }}
                            onClick={() => {
                                this.props.onReset!();
                            }}
                        >
                            Reset
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default FieldEditor;
