import React, { PureComponent } from "react";
import { Text } from "recharts";

import "common/styles/div_span.css";
import { mainStyle } from "common/MainStyle";
import { formatValue } from "common/utilities/FormatValue";

interface Props {
	axesColor?: string;
	labelMapping?: { [key: string]: string };
	formatValues: boolean;
	truncValues: boolean;
	fontSize: number | string;
	x?: number;
	y?: number;
	dx: number;
	dy: number;
	angle?: number;
	log?: boolean;
	maxLength?: number;
	payload?: {
		value: string | number;
	};
	unit?: string;
	customFormatFunction?: (value: string | number) => string;
}

class CustomizedAxisTick extends PureComponent<Props> {
	public static formatValue(value: string | number, trunc?: boolean): string {
		let formatted = formatValue(value, trunc);
		return formatted[0] + formatted[1];
	}

	public static defaultFormatValue(
		value: string | number,
		trunc?: boolean
	): string {
		if (typeof value !== "number") return value;
		return trunc ? String(Math.trunc(value)) : value.toFixed(2);
	}

	public render(): JSX.Element | null {
		let maxLength = this.props.maxLength ?? 10;
		let formatValues: boolean = this.props.formatValues || false;
		let angle: number = this.props.angle ?? 0;
		if (isNaN(this.props.x ?? NaN) || isNaN(this.props.y ?? NaN))
			return null;
		let truncValues = this.props.truncValues || false;
		let finalLabel = "";
		if (this.props.customFormatFunction != null) {
			finalLabel = this.props.customFormatFunction(this.props.payload?.value ?? 0)
		} else
		if (this.props.labelMapping != null) {
			finalLabel =
				this.props.labelMapping?.[this.props.payload?.value ?? ""] ??
				this.props.payload?.value;
		} else {
			finalLabel = (this.props.unit && this.props.unit !== "%"
				? this.props.unit
				: ""
			)
				.concat(
					formatValues
						? CustomizedAxisTick.formatValue(
								this.props.payload?.value ?? "",
								truncValues
						  )
						: CustomizedAxisTick.defaultFormatValue(
								this.props.payload?.value ?? "",
								truncValues
						  )
				)
				.concat(
					this.props.unit && this.props.unit === "%"
						? this.props.unit
						: ""
				);
		}
		if (this.props.maxLength != null)
			if (finalLabel.length > maxLength)
				finalLabel = finalLabel.slice(0, maxLength - 3).concat("...");
		return (
			<g
				transform={`translate(${this.props.x}, ${this.props.y}) rotate(${angle})`}
			>
				<Text
					x={0}
					y={0}
					dy={this.props.dy}
					dx={this.props.dx}
					fontFamily="Arial"
					fontSize={this.props.fontSize ?? mainStyle.getPropertyValue("--graphs-axes-size")}
					textAnchor="end"
					fill={this.props.axesColor ?? mainStyle.getPropertyValue(
						"--graphs-axes-text-color"
					)}
					className="no-selection"
				>
					{finalLabel}
				</Text>
			</g>
		);
	}
}

export default CustomizedAxisTick;