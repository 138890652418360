import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface Props {
	value: string;
	onClosed: () => void;
}

interface State {
	copied: boolean;
}

class ShareLinkToast extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			copied: false,
		};
	}

	buildInnerView() {
		return (
			<div
				className="center-container"
				style={{
					height: "100%",
					width: "100%",
				}}
			>
				<div
					className="flex-simple-column"
					style={{
						height: "100%",
						width: "90%",
					}}
				>
					<div
						style={{ marginTop: 10, alignSelf: "flex-end" }}
						onClick={(evt) => {
							evt.stopPropagation();
							this.props.onClosed();
						}}
					>
						<img
							alt=""
							src="/dist/img/insights/insights_remove.png"
							style={{ cursor: "pointer" }}
						/>
					</div>
					{this.props.value ? (
						<div className="flex-simple-column">
							<span
								style={{
									marginTop: 10,
									fontFamily: "Roboto",
									fontSize: "15px",
									color: "#FFFFFF",
									fontWeight: 700,
								}}
							>
								{"Click to copy link to clipboard:"}
							</span>
							<div
								className="my-row"
								style={{
									alignItems: "center",
									marginTop: 10,
								}}
							>
								<img
									alt=""
									src="/dist/img/insights/link_icon.png"
									style={{ cursor: "pointer" }}
								/>

								<span
									style={{
										marginLeft: 10,
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
										fontFamily: "Roboto",
										fontSize: "12px",
										color: "#FFFFFF",
									}}
								>
									{this.props.value}
								</span>
							</div>
							{this.state.copied ? (
								<span
									style={{
										marginTop: 10,
										fontFamily: "Roboto",
										fontSize: "12px",
										color: "#FFFFFF",
										fontWeight: 700,
									}}
								>
									Link copied to clipboard
								</span>
							) : null}
						</div>
					) : (
						<span
							style={{
								fontFamily: "Roboto",
								fontSize: "12px",
								color: "#ffebee",
								fontWeight: 700,
							}}
						>
							Sharing Error
						</span>
					)}
				</div>
			</div>
		);
	}

	render() {
		return (
			<div
				className="dashboard-rect"
				style={{
					position: "fixed",
					border: this.state.copied
						? "1px solid #00e676"
						: "1px solid #f44336",
					transform: "translate(-50%, 0%)",
					left: "50%",
					alignItems: "center",
					cursor: "pointer",
					top: 20,
					height: 130,
					width: 450,
					zIndex: 100000000,
				}}
			>
				{this.props.value ? (
					<CopyToClipboard
						text={this.props.value}
						onCopy={() => this.setState({ copied: true })}
						options={{
							format: "text/plain",
						}}
					>
						{this.buildInnerView()}
					</CopyToClipboard>
				) : (
					this.buildInnerView()
				)}
			</div>
		);
	}
}

export default ShareLinkToast;