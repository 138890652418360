import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import cx from "classnames";

import { CanvasElement, CanvasTextBox, getDefaultNotificationExpression } from "common/Canvas";
import { NodeLinkOption } from "common/Conditions";
import StatusSubExpressionSelector from "common/RevampedStatusSubExpressionsSelector";
import UserInfo from "common/UserInfo";

import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

import styles from "./SmartNotifications.module.css";
import UserSelect from "common/UserSelect";

interface Props {
  node: CanvasElement | CanvasTextBox;
  nodeLinkOptions: NodeLinkOption[];
  userOptions: UserInfo[];
  onChange: (changes: Partial<CanvasElement>, commit?: boolean) => void;
}

function SmartNotifications({ node, nodeLinkOptions, userOptions, onChange }: Props) {
  const [collapsedRows, setCollapsedRows] = useState<any>({});
  const [collapsedConditions, setCollapsedConditions] = useState<any>({});

  const notificationExpressions = node.notificationExpressions ?? [getDefaultNotificationExpression()];

  return (
    <div>
      {notificationExpressions.map((notificationExpression, expressionIndex) => (
        <div key={expressionIndex} className={styles.root}>
          <div className={styles.addRemoveButtonsContainer}>
            <button
              type="button"
              onClick={() => {
                setCollapsedRows((prev: any) => {
                  const collapsedRowsCopy = { ...prev };

                  const key = String(expressionIndex);
                  if (key in collapsedRowsCopy) {
                    delete collapsedRowsCopy[key];
                  } else {
                    collapsedRowsCopy[key] = true;
                  }

                  return collapsedRowsCopy;
                });
              }}
              className={styles.expressionCollapseButton}
            >
              <ChevronIcon
                className={cx(styles.expressionCollapseButtonIcon, {
                  [styles.chevronOpen]: !(String(expressionIndex) in collapsedRows)
                })}
              />
              Notification {expressionIndex + 1}
            </button>
            {expressionIndex === 0 ? (
              <button
                className={styles.addRemoveButton}
                type="button"
                onClick={() => {
                  const notificationExpressionsCopy = Array.from(notificationExpressions);
                  notificationExpressionsCopy.push(getDefaultNotificationExpression());
  
                  onChange({
                    notificationExpressions: notificationExpressionsCopy
                  });
                }}
              >
                  {"\uFF0B"} Add
              </button>
            ) : (
                <button
                    className={styles.addRemoveButton}
                    type="button"
                    onClick={() => {
                      const notificationExpressionsCopy = Array.from(notificationExpressions);
                      notificationExpressionsCopy.splice(expressionIndex, 1);

                      if (notificationExpressionsCopy.length === 0) {
                        notificationExpressionsCopy.push(getDefaultNotificationExpression());
                      }

                      onChange({
                        notificationExpressions: notificationExpressionsCopy
                      });
                    }}
                >
                    {"\u2715"}
                </button>
            )}
          </div>
          <Collapse in={!(String(expressionIndex) in collapsedRows)}>
            <div className={styles.expressionExpanded}>
              <div className={styles.notificationsArea}>
                <UserSelect
                  value={notificationExpression.users}
                  options={userOptions}
                  onChange={(value) => {
                    const newNotificationExpressions = Array.from(
                      notificationExpressions
                    );
                    newNotificationExpressions[expressionIndex].users = value;
                    newNotificationExpressions[expressionIndex].lastNotificationExpression = null;
                    onChange({
                      notificationExpressions: newNotificationExpressions
                    });
                  }}
                />
                <input
                  className={styles.input}
                  placeholder="Subject"
                  value={notificationExpression.title}
                  onChange={(evt) => {
                    const newNotificationExpressions = Array.from(
                      notificationExpressions
                    );
                    newNotificationExpressions[expressionIndex].title = evt.currentTarget.value;
                    newNotificationExpressions[expressionIndex].lastNotificationExpression = null;
                    onChange({
                      notificationExpressions: newNotificationExpressions
                    }, false);
                  }}
                  onBlur={() => {
                    onChange({
                      notificationExpressions
                    });
                  }}
                />
                <textarea
                  className={styles.textarea}
                  placeholder="Message"
                  value={notificationExpression.message}
                  onChange={(evt) => {
                    const newNotificationExpressions = Array.from(
                      notificationExpressions
                    );
                    newNotificationExpressions[expressionIndex].message = evt.currentTarget.value;
                    newNotificationExpressions[expressionIndex].lastNotificationExpression = null;
                    onChange({
                      notificationExpressions: newNotificationExpressions
                    }, false);
                  }}
                  onBlur={() => {
                    onChange({
                      notificationExpressions
                    });
                  }}
                />
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setCollapsedConditions((prev: any) => {
                      const collapsedConditionsCopy = { ...prev };

                      const key = String(expressionIndex);
                      if (key in collapsedConditionsCopy) {
                        delete collapsedConditionsCopy[key];
                      } else {
                        collapsedConditionsCopy[key] = true;
                      }

                      return collapsedConditionsCopy;
                    });
                  }}
                  className={cx(styles.expressionCollapseButton, styles.conditionCollapseButton)}
                >
                  <ChevronIcon
                    className={cx(styles.expressionCollapseButtonIcon,
                      styles.conditionCollapseButtonIcon,
                      { [styles.chevronOpen]: !(String(expressionIndex) in collapsedConditions) }
                    )}
                  />
                  Condition
                </button>
                <Collapse in={!(String(expressionIndex) in collapsedConditions)}>
                  <div>
                    <StatusSubExpressionSelector
                      node={node}
                      nodeLinkOptions={nodeLinkOptions}
                      subexpressions={notificationExpression.subexpressions}
                      onChange={(subexpressions) => {
                        const notificationExpressionsCopy = Array.from(notificationExpressions);
                        notificationExpressions[expressionIndex].subexpressions = subexpressions;
                        notificationExpressions[expressionIndex].lastNotificationExpression = null;

                        onChange({
                          notificationExpressions: notificationExpressionsCopy
                        });
                      }}
                    />
                  </div>
                </Collapse>
              </div>
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
}

export default SmartNotifications;
