import React, { Component } from "react";
import Select from "react-select";
import { mainStyle } from "common/MainStyle";

const SortingPolicy = Object.freeze({
    default: 1,
    recentFirst: 2,
    recentLast: 3,
});

class OrderByDropdown extends Component {
    constructor(props) {
        super(props);
        this.options = [
            { label: "Default", value: SortingPolicy.default },
            { label: "Recent first", value: SortingPolicy.recentFirst },
            { label: "Recent last", value: SortingPolicy.recentLast },
        ];
    }

    render() {
        return (
            <div
                style={{
                    height: 40,
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        color: mainStyle.getPropertyValue(
                            "--search-items-text-color"
                        ),
                    }}
                >
                    Sort by
                </div>
                <Select
                    styles={{
                        placeholder: (base) => ({
                            ...base,
                            color: mainStyle.getPropertyValue(
                                "--search-items-text-color"
                            ),
                        }),
                        container: (base) => ({
                            ...base,
                            marginLeft: 5,
                            height: 40,
                            width: 120,
                        }),
                        dropdownIndicator: (base, state) => ({
                            color: "#3399ff",
                            padding: 5,
                            paddingTop: 10,
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            color: mainStyle.getPropertyValue(
                                "--search-items-text-color"
                            ),
                            fontSize: 15,
                            fontFamily: "Roboto",
                            backgroundColor: state.isFocused
                                ? mainStyle.getPropertyValue(
                                      "--search-items-background-color"
                                  )
                                : mainStyle.getPropertyValue(
                                      "--search-items-background-color"
                                  ),
                            borderRadius: 5,
                        }),
                        control: (base, state) => ({
                            ...base,
                            border: state.isFocused ? 0 : 0,
                            boxShadow: state.isFocused ? 0 : 0,
                            "&:hover": {
                                border: state.isFocused ? 0 : 0,
                            },
                            backgroundColor: mainStyle.getPropertyValue(
                                "--search-items-background-color"
                            ),
                            borderRadius: 5,
                        }),
                        singleValue: (provided, state) => ({
                            ...provided,
                            color: mainStyle.getPropertyValue(
                                "--search-items-text-color"
                            ),
                            fontSize: 15,
                            fontFamily: "Roboto",
                            display: state.selectProps.menuIsOpen
                                ? "none"
                                : "block",
                        }),
                        input: (provided) => ({
                            ...provided,
                            backgroundColor: mainStyle.getPropertyValue(
                                "--search-items-background-color"
                            ),
                            border: 0,
                            color: mainStyle.getPropertyValue(
                                "--search-items-text-color"
                            ),
                            fontSize: 15,
                            fontFamily: "Roboto",
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            backgroundColor: mainStyle.getPropertyValue(
                                "--search-items-background-color"
                            ),
                            borderRadius: 5,
                            outline: 0,
                            border: 0,
                        }),
                    }}
                    components={{ IndicatorSeparator: () => null }}
                    isSearchable={false}
                    placeholder={"Order by"}
                    value={
                        this.options.filter(
                            (option) => option.value === this.props.value
                        )[0]
                    }
                    onChange={(value) => {
                        this.props.onChange(value.value);
                    }}
                    options={this.options}
                />
            </div>
        );
    }
}

export default OrderByDropdown;
export { SortingPolicy };
