import React, { Component } from "react";
import MetricsTable from "./MetricsTable";
import MapComponent from "./MapComponent";
import sections from "sections.json";
import ViewOption from "./ViewOption";

class FinalStep1A extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			editable,
			config,
			onConfigChange,
			leaders,
			laggers,
			showedVariables,
			targetVariable,
			showMap,
			geographyLevels,
			units,
		} = this.props;
		let { labelMapping, colorMapping } = config ?? {};
		let defaultFirstKey = "LEADERS";
		let defaultFirstColor = "#39F";
		let defaultSecondKey = "LAGGARDS";
		let defaultSecondColor = "#EE423D";
		let viewOption = config?.viewOption ?? ViewOption.Both;
		return (
			<div
				className="my-row"
				style={{
					overflow: "auto",
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%",
					height: "100%",
				}}
			>
				{showMap && (
					<>
						<div />
						<div
							className="flex-simple-column"
							style={{
								borderRadius: "8px",
								border: "1px solid #242E42",
								width: "50%",
								maxHeight: 350,
							}}
						>
							<MapComponent
								leadersColor={
									colorMapping?.[defaultFirstKey] ??
									defaultFirstColor
								}
								laggersColor={
									colorMapping?.[defaultSecondKey] ??
									defaultSecondColor
								}
								geographyLevels={geographyLevels}
								leaders={leaders}
								laggers={laggers}
								height={230}
								watermark={sections._Global?.watermark}
							/>
						</div>
					</>
				)}
				{!showMap && <div />}
				<div
					className="flex-simple-column element"
					style={{
						width: "50%",
						marginLeft: 20,
						height: "100%",
						overflow: "auto",
					}}
				>
					{(viewOption === ViewOption.Both ||
						viewOption === ViewOption.Leaders) && (
						<MetricsTable
							editable={editable}
							data={leaders}
							title={
								labelMapping?.[defaultFirstKey] ??
								defaultFirstKey
							}
							color={
								colorMapping?.[defaultFirstKey] ??
								defaultFirstColor
							}
							onColorChange={(color) => {
								let newColorMapping = {
									...colorMapping,
									[defaultFirstKey]: color,
								};
								let newConfig = {
									...config,
									colorMapping: newColorMapping,
								};
								onConfigChange(newConfig);
							}}
							onTitleChange={(title) => {
								let newLabelMapping = {
									...labelMapping,
									[defaultFirstKey]: title,
								};
								let newConfig = {
									...config,
									labelMapping: newLabelMapping,
								};
								onConfigChange(newConfig);
							}}
							showedVariables={showedVariables}
							selectedVariable={targetVariable}
							units={units}
						/>
					)}
					{(viewOption === ViewOption.Both ||
						viewOption === ViewOption.Laggards) && (
						<MetricsTable
							data={laggers}
							editable={editable}
							title={
								labelMapping?.[defaultSecondKey] ??
								defaultSecondKey
							}
							color={
								colorMapping?.[defaultSecondKey] ??
								defaultSecondColor
							}
							onTitleChange={(title) => {
								let newLabelMapping = {
									...labelMapping,
									[defaultSecondKey]: title,
								};
								let newConfig = {
									...config,
									labelMapping: newLabelMapping,
								};
								onConfigChange(newConfig);
							}}
							onColorChange={(color) => {
								let newColorMapping = {
									...colorMapping,
									[defaultSecondKey]: color,
								};
								let newConfig = {
									...config,
									colorMapping: newColorMapping,
								};
								onConfigChange(newConfig);
							}}
							showedVariables={showedVariables}
							selectedVariable={targetVariable}
							units={units}
						/>
					)}
				</div>
				{!showMap && <div />}
			</div>
		);
	}
}

export default FinalStep1A;