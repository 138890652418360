import React, { useContext, useEffect } from "react";

import TabContent from "common/home_components/PresentationTabs/TabContent";
import StoreContext from "state/StoreContext";

import styles from "./styles.module.css";

const WeRecommendModal: React.FC<Props> = ({
  show,
  onSkip,
  onDone,
  onClickPresentation,
}) => {
  const store = useContext(StoreContext);

  useEffect(() => {
    store.presentationStore.fetchTemplates();
  }, [store.presentationStore]);

  return show ? (
    <>
      <div className={styles.background} />
      <div className={styles.container}>
        <div className={styles.content}>
          <h2>We recommend</h2>
          <section>
            <h3 className={styles.sectionTitle}>Start with a template</h3>
            <span className={styles.sectionDesc}>
              Templates allow you to edit and already existing document
            </span>
            <div className={styles.cards}>
              <TabContent
                clickPresentation={(id: number) =>
                  onClickPresentation("templates", id)
                }
                presentations={store.presentationStore.templates.filter(presentation => !presentation.isKit())}
                loading={store.presentationStore.fetchingTemplates}
                viewMode={1}
                deletePresentation={() => null}
              />
            </div>
          </section>
          <section>
            <h3 className={styles.sectionTitle}>Start with a kit</h3>
            <span className={styles.sectionDesc}>
              Kits teach you how to build a functional document
            </span>
            <div className={styles.cards}>
              <TabContent
                clickPresentation={(id: number) => {
                  onClickPresentation("templates", id)
                }}
                presentations={store.presentationStore.templates.filter(presentation => presentation.isKit())}
                loading={store.presentationStore.fetchingTemplates}
                viewMode={1}
                deletePresentation={() => null}
              />
            </div>
          </section>
          <section className={styles.buttonsContainer}>
            <button className={styles.button} onClick={onSkip}>
              Skip
            </button>
            <button className={styles.button2} onClick={onDone}>
              Done
            </button>
          </section>
        </div>
      </div>
    </>
  ) : null;
};

interface Props {
  show: boolean;
  onSkip: () => void;
  onDone: () => void;
  onClickPresentation: (type: string, id: number) => void;
}

export default WeRecommendModal;
