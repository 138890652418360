enum PopupType {
    Insight = 1,
    Story = 2
};

function popupToString(type: PopupType): string {
	if (type === PopupType.Insight) return "card_id";
	if (type === PopupType.Story) return "story_id";
	return "";
}

export default PopupType;
export { popupToString };
