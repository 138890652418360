import React from "react";

import { ScatterPlotFinding } from "common/Finding";
import { SingleScatterChart } from "../../PlotlyScatterChart";
import { ScatterChartType } from "common/ScatterChartType";
import EditableAxisItem from "../EditableAxisItem";
import styles from "./ScatterPlot.module.css";
import { ReactComponent as SwapIcon } from "icons/canvas/exploration/swap_axes.svg";

interface Props {
    editable?: boolean;
    preview?: boolean;
    columnDragActive?: boolean;
    content: ScatterPlotFinding["content"];
    trendlineInfo: ScatterPlotFinding["content"]["trendlineInfo"];
    config: ScatterPlotFinding["config"];
    onChangeData?: (
        data: ScatterPlotFinding["content"]["data"],
        updateData?: boolean
    ) => void;
    onChangeConfig?: (
        config: ScatterPlotFinding["config"],
        updateData?: boolean
    ) => void;
    onChangeContent?: (
        content: ScatterPlotFinding["content"],
        updateData?: boolean
    ) => void;
    width: number;
    height: number;
    scale: number;
}

export default function ScatterPlot(props: Props) {
    let data = Object.fromEntries(
        props.content.data.map((item) => [item.name, item.value])
    );
    let linkVariable = (
        index: number,
        variableName: string,
        variableIndex: number
    ) => {
        let newData = Array.from(props.content.data);
        newData[index].name = variableName;
        newData[index].originalName = variableName;
        newData[index].variableIndex = variableIndex;
        props.onChangeData?.(newData, true);
    };

    let onChangeName = (index: number, value: string) => {
        let newData = Array.from(props.content.data);
        newData[index].name = value;
        props.onChangeData?.(newData);
    };
    let currentEditVariableIndex: number | undefined = undefined;
    if (props.config.dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = props.content.data.findIndex(
            (item) => item.variableIndex == null
        );
    }
    return (
        <div className="my-row" style={{ width: "100%", height: "100%" }}>
            <div
                className="flex-simple-column"
                style={{ width: "48px", height: "100%" }}
            >
                {props.config.showYAxisName && (
                    <EditableAxisItem
                        onChange={(value) => {
                            onChangeName(1, value);
                        }}
                        color={props.config.axesNamesColor}
                        index={1}
                        currentEditVariableIndex={currentEditVariableIndex}
                        vertical
                        name={props.content.data[1].name}
                        onDrop={linkVariable}
                        editable={props.editable}
                    />
                )}
                <div style={{ flexGrow: 1 }} />
                <div className={styles.swapButtonContainer}>
                    {props.editable && (
                        <div
                            className={styles.swapButton}
                            onClick={() => {
                                let data = Array.from(props.content.data);
                                [data[0], data[1]] = [data[1], data[0]];
                                props.onChangeContent?.(
                                    {
                                        ...props.content,
                                        data: data,
                                        trendlineInfo: null,
                                    },
                                    props.trendlineInfo != null
                                );
                            }}
                        >
                            <SwapIcon />
                        </div>
                    )}
                </div>
            </div>
            <div
                className="flex-simple-column"
                style={{ width: "100%", height: "100%" }}
            >
                <SingleScatterChart
                    ticksSize={props.config.ticksSize}
                    ticksAndLabels={props.config.ticksAndLabels}
                    trendlineInfo={props.trendlineInfo}
                    tooltipColor={props.config.tooltipColor}
                    tooltipFontSize={props.config.tooltipFontSize}
                    trendlineColor={props.config.trendlineColor}
                    data={data}
                    units={["", "", ""]}
                    hideGrid={!props.config.showGrid}
                    hideAxesNames
                    size={16}
                    minYRange={props.config.minYRange}
                    maxYRange={props.config.maxYRange}
                    minXRange={props.config.minXRange}
                    maxXRange={props.config.maxXRange}
                    axesColor={props.config.ticksColor}
                    axesLinesColor={props.config.axesLinesColor}
                    scatterStrokeColor={
                        props.preview ? "#D1D1D1" : "transparent"
                    }
                    chartColor={
                        props.preview ? "#FFFFFF" : props.config.chartColor
                    }
                    type={ScatterChartType.TwoD}
                    variables={Object.keys(data)}
                />
                {props.config.showXAxisName && (
                    <EditableAxisItem
                        onChange={(value) => {
                            onChangeName(0, value);
                        }}
                        color={props.config.axesNamesColor}
                        currentEditVariableIndex={currentEditVariableIndex}
                        editable={props.editable}
                        index={0}
                        vertical={false}
                        name={props.content.data[0].name}
                        onDrop={linkVariable}
                    />
                )}
            </div>
        </div>
    );
}
