import React from "react";
import CustomFirstMessageButton from "./components/CustomFirstMessage/CustomFirstMessageButton";
import { defaultAiCopilotTitle, defaultAiCopilotQuestionOne, defaultAiCopilotQuestionTwo, defaultAiCopilotQuestionThree } from "common/Finding";
import { CustomMessageProps } from "./ChatbotTypes";

const CustomFirstMessage = (props: CustomMessageProps): JSX.Element | null => {
    if (props.state.messages.length > 4) return null;
    return (
        <div
            style={{
                minHeight: 83,
                paddingTop: 40,
                paddingLeft: 15,
                paddingRight: 15,
            }}
        >
            <h3
                style={{
                    color: "#8a8888",
                    margin: "0 0 20px 0",
                    textAlign: "center",
                    whiteSpace: "pre-wrap",
                }}
            >
                {props.finding.config.title ?? defaultAiCopilotTitle}
            </h3>

            <div
                style={{
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "space-evenly",
                }}
            >
                <CustomFirstMessageButton
                    text={props.finding.config.questionOne ?? defaultAiCopilotQuestionOne}
                    {...props}
                />
                <CustomFirstMessageButton
                     text={props.finding.config.questionTwo ?? defaultAiCopilotQuestionTwo}
                    {...props}
                />
                <CustomFirstMessageButton 
                     text={props.finding.config.questionThree ?? defaultAiCopilotQuestionThree}
                    {...props} />
            </div>
        </div>
    );
};

export default CustomFirstMessage;
