export default function linkIdToUrl(
    requireAuthentication: boolean,
    linkId: string
): string {
    let url = new URL(window.location.origin);
    url.pathname = !requireAuthentication
        ? "/shared_canvas_module.html"
        : "/shared_auth_canvas_module.html";
    url.searchParams.append("remote_moduleid", linkId);
    return url.toString();
}

export function shortLinkToUrl(shortLink: string): string {
    let url = new URL(window.location.origin);
    url.pathname = `/app/${encodeURIComponent(shortLink)}`;
    return url.toString();
}

export function sharedModuleIdToUrl(sharedModuleId: number): string {
    let url = new URL(window.location.origin);
    url.pathname = "/shared_module.html";
    url.searchParams.append("shared_module_id", sharedModuleId.toString());
    return url.toString();
}

export function tutorialIdToUrl(tutorialId: number): string {
    let url = new URL(window.location.origin);
    url.pathname = "/tutorial.html";
    url.searchParams.append("tutorial_id", tutorialId.toString());
    return url.toString();
}

export function selfSignupLinkIdToUrl(linkId: string): string {
    let url = new URL(window.location.origin);
    url.pathname = "/register.html";
    url.searchParams.append("a", linkId);
    return url.toString();
}

export function selfSignupModuleLinkIdIdToUrl(linkId: string): string {
    let url = new URL(window.location.origin);
    url.pathname = "/register.html";
    url.searchParams.append("m", linkId);
    return url.toString();
}
