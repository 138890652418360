import { observable, computed, makeObservable, action } from "mobx";
import {
	getUserPagesApi,
	getCanvasPagesForLoadedModules,
} from "common/UserApi";
import PageType from "common/PageType";
import { Permission } from "common/Permissions";
import { Page } from "./Page";

export interface PageOption {
	label: string;
	value: number;
}

class PagesStoreInner {
	type: PageType | undefined;
	pagesState: Page[];
	permissions: { [key: string]: Permission };
	modules: { [key: string]: string };
	initialized: boolean;
	currentModuleId: number | undefined;

	constructor(type?: PageType) {
		makeObservable(this, {
			pagesState: observable,
			permissions: observable,
			initialized: observable,
			pages: computed,
			pageOptions: computed,
			pageToWriteOptions: computed,
		});
		this.permissions = {};
		this.pagesState = [];
		this.modules = {};
		this.type = type;
		this.initialized = false;
		if (type === PageType.Canvases) {
			const urlParams = new URLSearchParams(window.location.search);
			let currentModuleId = urlParams.get("current_module_id");
			if (currentModuleId != null) {
				this.currentModuleId = Number(currentModuleId);
			}
		}
		this.updatePages();
	}

	@action.bound
	private initPages(pages: Page[]) {
		for (let page of pages) {
			this.permissions[page.id] = page.permission_type;
			if (page.module_title != null)
				this.modules[page.id] = page.module_title;
		}
		this.pagesState = pages;
		this.initialized = true;
	}

	updatePages() {
		if (this.type == null) return;

		if (this.type === PageType.Canvases) {
			getCanvasPagesForLoadedModules(this.currentModuleId!)
				.then((modulesPages) => {
					this.initPages(modulesPages);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			getUserPagesApi(this.type)
				.then((pages) => {
					this.initPages(pages);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}
	pagesOptionsByModule(moduleId: number) {
		return this.pagesState
			.filter((page) => page.module_id === moduleId)
			.map((page) => {
				return {
					label: page.title,
					value: page.id,
				};
			});
	}

	get pages() {
		return this.pagesState;
	}

	permission(pageId: number): Permission {
		//return Permission.ReadOnly;
		return this.permissions[pageId] ?? Permission.ReadOnly;
	}
	module(pageId: number): string {
		return this.modules[pageId];
	}
	moduleId(): number | undefined {
		return this.currentModuleId;
	}
	get pageOptions(): PageOption[] {
		return this.pagesState.map((page) => {
			return {
				label: page.title,
				value: page.id,
			};
		});
	}
	get pageToWriteOptions() {
		return this.pagesState
			.filter(
				(page) => this.permissions[page.id] === Permission.ReadWrite
			)
			.map((page) => {
				return {
					label: page.title,
					value: page.id,
				};
			});
	}
}

let pageStores: { [key: string]: PagesStoreInner } = {};

export default function PagesStore(type: PageType): PagesStoreInner {
	if (type in PageType) {
		if (pageStores[type] == null) {
			pageStores[type] = new PagesStoreInner(type);
		}
		return pageStores[type];
	}
	return new PagesStoreInner();
}