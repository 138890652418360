import React, { useEffect, useRef, useState } from "react";
import './styles.css';

interface ISpeechAnimation {
  loading: boolean;
  microphone: MediaRecorder;   // MediaRecorder object representing the microphone input
}

const SpeechAnimation: React.FC<ISpeechAnimation> = ({ loading, microphone }) => {
  const [bars, setBars] = useState<number[]>(Array(20).fill(1)); 
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);

  useEffect(() => {
    if (!loading) {
      // Initialize AudioContext and AnalyserNode when loading starts
      audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);

      const mediaStream = microphone.stream;  // Extract MediaStream from MediaRecorder
      const source = audioContextRef.current.createMediaStreamSource(mediaStream);
      source.connect(analyserRef.current);

      const animateBars = () => {
        if (!analyserRef.current || !dataArrayRef.current) return;

        analyserRef.current.getByteFrequencyData(dataArrayRef.current);

        const newBars = Array.from({ length: bars.length }, (_, i) => {
          // Scale the frequency data to a value between 1 and 100
          return (dataArrayRef.current![i] / 255) * 100 || 1;
        });

        setBars(newBars);

        requestAnimationFrame(animateBars);
      };

      animateBars();

      return () => {
        // Cleanup on unmount
        if (audioContextRef.current) {
          audioContextRef.current.close();
        }
      };
    }
  }, [loading, microphone]);

  return (
    <div id="bars">
      {bars.map((bar, idx) => (
        <div
          key={idx}
          className={loading ? "loading" : "bar"}
          style={{
            height: loading ? undefined : `${bar}%`,  // Use the bar value to set the height percentage only when not loading
          }}
        ></div>
      ))}
    </div>
  );
};

export default SpeechAnimation;
