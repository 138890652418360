import React from "react";

export function MySvg(props) {
	return (
		<svg
			width="11"
			height="13"
			viewBox="0 0 11 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#657D95"
			{...props}
		>
			<path
				d="M1.125 0.875L9.875 6.5L1.125 12.125V0.875Z"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}