import React, { Component } from "react";
import Select, { createFilter } from "react-select";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import Popup from "reactjs-popup";
import { reaction } from "mobx";
import { observer } from "mobx-react";
import TimezoneSelect from "react-timezone-select";
import Cookies from "universal-cookie";

import { mainStyle } from "common/MainStyle";
import { BriefCanvas } from "common/Canvas";
import {
    LiveStreamIntervalOption,
    liveStreamIntervalOptions,
    liveStreamWeekDayOptions,
    LiveStreamWeekDayOption,
    uploadOptions,
} from "common/InputData";
import customSelectStyles from "common/SelectStyles";
import StringOption from "common/StringOption";
import moment from "moment-timezone";
import dataScopes, { DataScopeOption } from "common/DataScopes";
import { Permission } from "common/Permissions";
import GroupsPermissionsSelector from "common/GroupsPermissionsSelector";
import { getDataTablePermissionsApi } from "common/UserGroupsApi";
import CreatableSelect from "react-select/creatable";
import {
    turnOnLiveStreamingApi,
    turnOffLiveStreamingApi,
    LiveStreamingState,
} from "common/CanvasLiveStreamingApi";
import Alert from "common/Alert";
import Canvases from "common/Canvases";
import { editCanvasTitle, BriefEditCanvasRequest } from "common/CanvasUserApi";
import GlobalContext, { GlobalContextContents } from "GlobalContext";

const cookies = new Cookies();

interface Props {
    canEditLiveStreaming: boolean;
    canvas: BriefCanvas;
    onClose: () => void;
}

enum UpdateStatus {
    success = 1,
    loading = 2,
    error = 3,
    notUploaded = 4,
}

interface State extends LiveStreamingState {
    title: string;
    status: UpdateStatus;
    errorMessage: string;
}

@observer
class EditCanvasPopup extends Component<Props, State> {
    dataScopesReaction: any;
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        let liveStreamTime = "00:00";
        let liveStreamTimezone = {
            label: "Local Time Zone",
            value: moment.tz.guess(),
        };
        if (this.props.canvas.live_streaming.time != null) {
            liveStreamTime = this.props.canvas.live_streaming.time;
            liveStreamTimezone = {
                label: "(GMT+0:00) Dublin, Edinburgh, Lisbon, London",
                value: "GMT",
            };
        }
        this.state = {
            dataScope:
                this.props.canvas.live_streaming.data_table_idx != null
                    ? {
                          value: this.props.canvas.live_streaming
                              .data_table_idx,
                          label: "",
                          permissionType: Permission.ReadOnly,
                      }
                    : undefined,
            newDataScope: null,
            permissions: [],
            liveStreamInterval:
                this.props.canvas.live_streaming.update_interval == null
                    ? liveStreamIntervalOptions[0]
                    : liveStreamIntervalOptions.filter(
                          (option) =>
                              option.value ===
                              this.props.canvas.live_streaming
                                  .update_interval
                      )[0],
            liveStreamWeekDay:
                this.props.canvas.live_streaming.weekday == null
                    ? liveStreamWeekDayOptions[0]
                    : liveStreamWeekDayOptions.filter(
                          (option) =>
                              String(option.value) ===
                              this.props.canvas.live_streaming.weekday
                      )[0],
            liveStreaming: this.props.canvas.live_streaming.exists,
            liveStreamTime: liveStreamTime,
            liveStreamTimezone: liveStreamTimezone,
            status: UpdateStatus.notUploaded,
            errorMessage: "",
            title: this.props.canvas.title,
        };
        this.renderContents = this.renderContents.bind(this);
        this.inputRef = React.createRef();
    }

    findDataScope() {
        if (this.state.dataScope?.value != null) {
            let dataScopeOption = dataScopes.dataScopesOptions.filter(
                (option) => option.value === this.state.dataScope?.value
            )[0];
            if (dataScopeOption != null) {
                this.setState({ dataScope: dataScopeOption });
            }
        }
    }

    getPermissions(value: string | number) {
        getDataTablePermissionsApi(value)
            .then((permissions) => {
                this.setState({ permissions: permissions });
            })
            .catch((_error) => {
                this.setState({ permissions: [] });
            });
    }

    componentDidMount() {
        if (this.state.dataScope?.value != null)
            this.getPermissions(this.state.dataScope.value);
        this.dataScopesReaction = reaction(
            () => dataScopes.dataScopesOptions,
            () => {
                this.findDataScope();
            }
        );
        this.findDataScope();
    }
    componentWillUnmount() {
        this.dataScopesReaction();
    }

    private buildAlert(): JSX.Element {
        let alert: JSX.Element = <div style={{ height: 72 }} />;
        if (this.state.status === UpdateStatus.success) {
            alert = (
                <Alert
                    text="Successully saved."
                    className="alert alert-success alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.error) {
            alert = (
                <Alert
                    text={"Error: ".concat(this.state.errorMessage)}
                    className="alert alert-danger alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        } else if (this.state.status === UpdateStatus.loading) {
            alert = (
                <Alert
                    text={"Saving"}
                    className="alert alert-warning alert-dismissible"
                    onClosed={() =>
                        this.setState({
                            status: UpdateStatus.notUploaded,
                        })
                    }
                />
            );
        }
        return alert;
    }

    private buildInnerView(
        globalContext: GlobalContextContents,
        height: number
    ): JSX.Element {
        return (
            <div
                className="flex-simple-column"
                style={{
                    overflow: "visible",
                    marginLeft: "38px",
                    marginRight: "38px",
                    height: height,
                }}
            >
                <input
                    ref={this.inputRef}
                    type="text"
                    className="like-tab"
                    placeholder="Enter title"
                    style={{
                        marginTop: "20px",
                        width: "100%",
                        alignSelf: "center",
                    }}
                    value={this.state.title}
                    onChange={(evt) => {
                        let title = evt.target.value;
                        this.setState({ title: title });
                    }}
                />
                {"CanvasLiveStream" in globalContext.permissions && (
                    <>
                        <div
                            className="my-row"
                            style={{
                                alignItems: "center",
                                marginTop: "20px",
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: "Arial",
                                    fontSize: "17px",
                                    color: mainStyle.getPropertyValue(
                                        "--popup-primary-text-color"
                                    ),
                                    width: "150px",
                                }}
                            >
                                {"Live Streaming"}
                            </span>
                            <div style={{ marginLeft: 6 }}>
                                <Switch
                                    onChange={() => {
                                        this.setState((state) => ({
                                            liveStreaming: !state.liveStreaming,
                                        }));
                                    }}
                                    checked={this.state.liveStreaming}
                                    width={26}
                                    height={13}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                        </div>
                        {this.state.liveStreaming && (
                            <div
                                className="center-container"
                                style={{
                                    overflow: "visible",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "start",
                                        justifyContent: "start",
                                        width: "90%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "start",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <span className="popup-regular-text">
                                            Every
                                        </span>
                                        <Select
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            placeholder={""}
                                            styles={{
                                                ...customSelectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    height: "38px",
                                                    width: 100,
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                }),
                                            }}
                                            options={
                                                liveStreamIntervalOptions
                                            }
                                            value={
                                                this.state
                                                    .liveStreamInterval
                                            }
                                            onChange={(option) => {
                                                this.setState({
                                                    liveStreamInterval: option as LiveStreamIntervalOption,
                                                });
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25: "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                        {this.state.liveStreamInterval
                                            .value === "week" && (
                                            <>
                                                <span className="popup-regular-text">
                                                    on
                                                </span>
                                                <Select
                                                    filterOption={createFilter(
                                                        {
                                                            ignoreAccents: false,
                                                        }
                                                    )}
                                                    placeholder={""}
                                                    styles={{
                                                        ...customSelectStyles,
                                                        container: (
                                                            base
                                                        ) => ({
                                                            ...base,
                                                            height: "38px",
                                                            width: 100,
                                                            marginLeft:
                                                                "10px",
                                                            marginRight:
                                                                "10px",
                                                        }),
                                                    }}
                                                    options={
                                                        liveStreamWeekDayOptions
                                                    }
                                                    value={
                                                        this.state
                                                            .liveStreamWeekDay
                                                    }
                                                    onChange={(option) => {
                                                        this.setState({
                                                            liveStreamWeekDay: option as LiveStreamWeekDayOption,
                                                        });
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25:
                                                                "var(--selectors-background-hover-color)",
                                                        },
                                                    })}
                                                />
                                            </>
                                        )}
                                        <span className="popup-regular-text">
                                            at
                                        </span>
                                        <input
                                            className="like-select"
                                            value={
                                                this.state.liveStreamTime
                                            }
                                            onChange={(e) => {
                                                const value =
                                                    e.target.value;
                                                this.setState({
                                                    liveStreamTime: value,
                                                });
                                            }}
                                            style={{
                                                width: 100,
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                            }}
                                        />
                                        <TimezoneSelect
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            placeholder={""}
                                            styles={{
                                                ...customSelectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    height: "38px",
                                                    width: 250,
                                                    marginLeft: "10px",
                                                    marginRight: "10px",
                                                }),
                                            }}
                                            value={
                                                this.state
                                                    .liveStreamTimezone
                                            }
                                            onChange={(option) => {
                                                this.setState({
                                                    liveStreamTimezone: option as StringOption,
                                                });
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25: "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "start",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <img
                                            src="/dist/img/warning.png"
                                            alt=""
                                            style={{
                                                marginRight: 5,
                                            }}
                                        />
                                        <span className="popup-regular-text">
                                            <b>WARNING:</b> Do not change
                                            the order of the columns in the
                                            source data, or updates will not
                                            work
                                        </span>
                                    </div>
                                    <div
                                        className="my-row"
                                        style={{
                                            alignItems: "center",
                                            marginLeft: "19px",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span className="popup-regular-text">
                                            Select or create data
                                        </span>
                                        <CreatableSelect
                                            inputId="select-data-scope"
                                            filterOption={createFilter({
                                                ignoreAccents: false,
                                            })}
                                            onCreateOption={(option) => {
                                                this.setState({
                                                    newDataScope: {
                                                        label: option,
                                                        value: NaN,
                                                        permissionType:
                                                            Permission.ReadWrite,
                                                    },
                                                    dataScope: {
                                                        label: option,
                                                        value: NaN,
                                                        permissionType:
                                                            Permission.ReadWrite,
                                                    },
                                                    permissions: [],
                                                });
                                            }}
                                            placeholder={""}
                                            styles={{
                                                ...customSelectStyles,
                                                container: (base) => ({
                                                    ...base,
                                                    marginLeft: "5px",
                                                    width: "25em",
                                                    height: "38px",
                                                    marginRight: "10px",
                                                }),
                                            }}
                                            options={dataScopes.dataScopesOptions
                                                .filter(
                                                    (option) =>
                                                        option.permissionType ===
                                                        Permission.ReadWrite
                                                )
                                                .concat(
                                                    this.state
                                                        .newDataScope !=
                                                        null
                                                        ? [
                                                              this.state
                                                                  .newDataScope,
                                                          ]
                                                        : []
                                                )}
                                            onChange={(newValue) => {
                                                let stateDiff: any = {
                                                    dataScope: newValue as DataScopeOption,
                                                    permissions: [],
                                                };
                                                if (
                                                    Number.isNaN(
                                                        stateDiff.dataScope
                                                            ?.value
                                                    )
                                                ) {
                                                    stateDiff.uploadOption =
                                                        uploadOptions[0];
                                                } else if (
                                                    stateDiff.dataScope
                                                        ?.value != null
                                                ) {
                                                    this.getPermissions(
                                                        stateDiff.dataScope
                                                            .value
                                                    );
                                                }

                                                this.setState(stateDiff);
                                            }}
                                            value={this.state.dataScope}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: "white",
                                                    primary25: "var(--selectors-background-hover-color)",
                                                },
                                            })}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginLeft: "19px",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <span
                                            className="popup-regular-text"
                                            style={{
                                                marginBottom: "5px",
                                            }}
                                        >
                                            Access control
                                        </span>
                                        <GroupsPermissionsSelector
                                            selectedUserGroups={
                                                this.state.permissions
                                            }
                                            onChange={(permissions) => {
                                                this.setState({
                                                    permissions: permissions,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
                <div style={{ flexGrow: 1 }} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        marginBottom: "15px",
                    }}
                >
                    <Button
                        type="button"
                        className="btn btn-lg btn-primary my-primary"
                        style={{
                            width: 150,
                            height: "38px",
                            paddingTop: 0,
                            marginRight: "10px",
                            paddingBottom: 0,
                        }}
                        onClick={() => {
                            this.setState({
                                status: UpdateStatus.loading,
                            });
                            let editCanvasRequest: BriefEditCanvasRequest = {
                                sheets: [
                                    {
                                        canvasId: this.props.canvas.id,
                                        title: this.state.title,
                                    },
                                ],
                            };
                            editCanvasTitle(
                                editCanvasRequest,
                                cookies.get("instrumentation_session_id")
                            )
                                .then(() => {
                                    Canvases(
                                        this.props.canvas.page_id
                                    ).update();
                                    if (
                                        !this.props.canvas.live_streaming
                                            .exists &&
                                        this.state.liveStreaming &&
                                        this.props.canEditLiveStreaming
                                    ) {
                                        turnOnLiveStreamingApi(
                                            this.props.canvas.id,
                                            this.state
                                        )
                                            .then(() => {
                                                this.setState({
                                                    status:
                                                        UpdateStatus.success,
                                                });
                                                Canvases(
                                                    this.props.canvas
                                                        .page_id
                                                ).updateCanvas(
                                                    this.props.canvas.id
                                                );
                                                this.setState(
                                                    {
                                                        status:
                                                            UpdateStatus.success,
                                                    },
                                                    () => {
                                                        setTimeout(() => {
                                                            this.props.onClose();
                                                        }, 100);
                                                    }
                                                );
                                            })
                                            .catch((error) => {
                                                this.setState({
                                                    status:
                                                        UpdateStatus.error,
                                                    errorMessage: String(
                                                        error
                                                    ),
                                                });
                                            });
                                    } else if (
                                        this.props.canvas.live_streaming
                                            .exists &&
                                        !this.state.liveStreaming &&
                                        this.props.canEditLiveStreaming
                                    ) {
                                        turnOffLiveStreamingApi(
                                            this.props.canvas.id
                                        )
                                            .then(() => {
                                                Canvases(
                                                    this.props.canvas
                                                        .page_id
                                                ).updateCanvas(
                                                    this.props.canvas.id
                                                );
                                                this.setState(
                                                    {
                                                        status:
                                                            UpdateStatus.success,
                                                    },
                                                    () => {
                                                        setTimeout(() => {
                                                            this.props.onClose();
                                                        }, 100);
                                                    }
                                                );
                                            })
                                            .catch((error) => {
                                                this.setState({
                                                    status:
                                                        UpdateStatus.error,
                                                    errorMessage: String(
                                                        error
                                                    ),
                                                });
                                            });
                                    } else {
                                        this.setState(
                                            {
                                                status:
                                                    UpdateStatus.success,
                                            },
                                            () => {
                                                setTimeout(() => {
                                                    this.props.onClose();
                                                }, 100);
                                            }
                                        );
                                    }
                                })
                                .catch((errorMessage) => {
                                    this.setState({
                                        status: UpdateStatus.error,
                                        errorMessage: errorMessage,
                                    });
                                });
                        }}
                    >
                        SAVE
                    </Button>
                    {this.buildAlert()}
                </div>
            </div>
        );
    }

    private renderContents(
        globalContext: GlobalContextContents
    ): JSX.Element {
        const height: number = 500;
        const width: number = 950;
        return (
            <Popup
                arrow={true}
                contentStyle={{
                    width: width,
                    minHeight: height,
                    border: "none",
                    backgroundColor: "transparent",
                }}
                open={true}
                onClose={() => {
                    this.props.onClose();
                }}
                closeOnDocumentClick
            >
                <div
                    className="dashboard-rect element"
                    style={{
                        overflowX: "visible",
                        overflowY: "auto",
                        boxShadow: "0 12px 24px 0 rgba(0,0,0,0.5)",
                        borderRadius: 0,
                        alignItems: "center",
                        cursor: "pointer",
                        minHeight: height,
                        maxHeight: "100vh",
                        width: width,
                    }}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                    onMouseDown={(evt: any) => {
                        evt.stopPropagation();
                    }}
                >
                    {this.buildInnerView(globalContext, height)}
                </div>
            </Popup>
        );
    }

    public render(): JSX.Element {
        return (
            <GlobalContext.ObserverConsumer>
                {this.renderContents}
            </GlobalContext.ObserverConsumer>
        );
    }
}

export default EditCanvasPopup;
