import React from "react";

export default function ReferenceLabel(props) {
	const {
		fillBackground,
		fillText,
		value,
		textAnchor,
		fontSize,
		viewBox,
		dy,
		dx,
	} = props;
	const x = viewBox.width + viewBox.x + 20;
	const y = viewBox.y - 6;
	return (
		<svg>
			<rect
				width={value.length * 5}
				dy={dy}
				dx={dx}
				height={viewBox.height}
				x={x - 20}
				y={y + 6}
				fill={fillBackground}
			/>
			<text
				x={x}
				y={y}
				dy={dy}
				dx={dx}
				stroke={fillText}
				fill={fillText}
				fontSize={fontSize || 10}
				textAnchor={textAnchor}
			>
				{value}
			</text>
		</svg>
	);
}