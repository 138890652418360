import Variables from "common/Variables";
import { conditionsToJson } from "common/Conditions";
import axios from "common/ServerConnection";

class Api {
    static getFinding(props, moduleId) {
        let { leaders, laggers, compareInfo, showMap, config } = props;
        let dataTableIdx =
            typeof config.dataScope === "undefined"
                ? 0
                : config.dataScope.value;
        let units = Variables(dataTableIdx, moduleId).getUnits(
            config.targetVariable
        );
        let showedVariables = [
            config.aggregateVariable,
            ...config.showedVariables,
        ].filter((item) => item && item !== config.targetVariable);

        let item = {};
        if (compareInfo && compareInfo.length === 2) {
            item = {
                type: "1ai",
                content: {
                    geographyLevels: config.geographyLevels,
                    compareInfo: compareInfo,
                    showedVariables: showedVariables,
                    targetVariable: config.targetVariable,
                    units: units,
                    showMap: showMap,
                },
                config: config,
            };
        } else {
            item = {
                type: "1a",
                content: {
                    geographyLevels: config.geographyLevels,
                    leaders: leaders,
                    showedVariables: showedVariables,
                    laggers: laggers,
                    targetVariable: config.targetVariable,
                    units: units,
                    showMap: showMap,
                    frozen: true,
                },
                config: config,
            };
        }
        return item;
    }

    static getData(config, previousFinding, moduleId) {
        let {
            dataScope,
            targetVariableIndex,
            additionalParameterIndex,
            aggregateVariableIndex,
            showedVariablesIndices,
            additionalParameter,
            leftAdditionalOperator,
            rightAdditionalOperator,
            leftAdditionalValue,
            rightAdditionalValue,
            selectedTable,
            conditions,
            count,
            viewOption,
        } = config;
        leftAdditionalOperator = leftAdditionalOperator ?? {
            label: "=",
            value: "=",
        };
        rightAdditionalOperator = rightAdditionalOperator ?? {
            label: "=",
            value: "=",
        };
        conditions = conditions.filter(
            (condition) =>
                condition.variable && condition.value && condition.operation
        );
        conditions = conditionsToJson(conditions);
        let dataScopeValue =
            typeof dataScope === "undefined" ? 0 : dataScope.value;

        if (
            !additionalParameter ||
            !leftAdditionalValue ||
            !rightAdditionalValue
        ) {
            let requestJson = {
                measured_by_index: targetVariableIndex,
                map_group_index: aggregateVariableIndex,
                variable_indices: showedVariablesIndices,
                count: count ?? 5,
                data_table_idx: dataScopeValue,
                table: selectedTable.value,
                condition_id: selectedTable.condition_id,
                conditions: conditions,
                module_id: moduleId,
                view_option: viewOption,
            };
            return axios
                .post("/api/e/get_leaders_and_laggers", requestJson)
                .then((response) => {
                    if (response.data.success) {
                        let result = {};
                        result.leaders = response.data.leaders;
                        result.laggers = response.data.laggers;
                        result.showMap = response.data.show_map;
                        result.config = config;
                        let item = Api.getFinding(result, moduleId);
                        return Promise.resolve(item);
                    } else {
                        let result = {};
                        result.leaders = [];
                        result.laggers = [];
                        result.config = config;
                        let item = Api.getFinding(result, moduleId);
                        return Promise.resolve(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    return Promise.reject(error);
                });
        } else {
            let requestJson1 = {
                measured_by_index: targetVariableIndex,
                map_group_index: aggregateVariableIndex,
                variable_indices: showedVariablesIndices,
                count: count ?? 5,
                view_option: viewOption,
                where: {
                    group_index: additionalParameterIndex,
                    operation: leftAdditionalOperator.value,
                    value: leftAdditionalValue.value,
                },
                data_table_idx:
                    typeof dataScope === "undefined" ? 0 : dataScope.value,
                table: selectedTable.value,
                condition_id: selectedTable.condition_id,
                conditions: conditions,
                module_id: moduleId,
            };
            let requestJson2 = {
                measured_by_index: targetVariableIndex,
                map_group_index: aggregateVariableIndex,
                variable_indices: showedVariablesIndices,
                count: count ?? 5,
                view_option: viewOption,
                data_table_idx:
                    typeof dataScope === "undefined" ? 0 : dataScope.value,
                where: {
                    group_index: additionalParameterIndex,
                    operation: rightAdditionalOperator.value,
                    value: rightAdditionalValue.value,
                },
                table: selectedTable.value,
                condition_id: selectedTable.condition_id,
                conditions: conditions,
                module_id: moduleId,
            };
            return axios
                .all([
                    axios.post("/api/e/get_leaders_and_laggers", requestJson1),
                    axios.post("/api/e/get_leaders_and_laggers", requestJson2),
                ])
                .then(
                    axios.spread((res1, res2) => {
                        if (res1.data.success && res2.data.success) {
                            let compareInfo = [
                                {
                                    group: additionalParameter,
                                    operator: leftAdditionalOperator.label,
                                    label: leftAdditionalValue.label,
                                    leaders: res1.data.leaders,
                                    laggers: res1.data.laggers,
                                },
                                {
                                    group: additionalParameter,
                                    operator: rightAdditionalOperator.label,
                                    label: rightAdditionalValue.label,
                                    leaders: res2.data.leaders,
                                    laggers: res2.data.laggers,
                                },
                            ];
                            let item = Api.getFinding(
                                {
                                    config: config,
                                    compareInfo: compareInfo,
                                    showMap: res1.data.show_map,
                                },
                                moduleId
                            );
                            return Promise.resolve(item);
                        } else {
                            console.log(res1.data.error_msg);
                            console.log(res2.data.error_msg);
                            return Promise.reject(res1.data.error_msg);
                        }
                    })
                )
                .catch((error) => {
                    return Promise.reject(error);
                });
        }
    }
}

export { Api };