import React, { Component } from "react";
import CopyTablePopup from "./canvas_elements/CopyTablePopup";
import AddVariablePopup from "./canvas_elements/AddVariablePopup";
import ExportCsvPopup from "./canvas_elements/ExportCsvPopup";
import { TableOption } from "common/Tables";
import { Button } from "react-bootstrap";

interface Props {
	dataScopeId: string | number | undefined;
	table: TableOption | null;
	currentModuleId?: number;
	onUpdate: () => void;
}

interface State {
	exportedTableOption: TableOption | undefined;
	copiedTableOption: TableOption | undefined;
	addVariableTableOption: TableOption | undefined;
	rowLevel: boolean;
}

export default class DatasetEditToolbar extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			exportedTableOption: undefined,
			copiedTableOption: undefined,
			addVariableTableOption: undefined,
			rowLevel: false,
		};
	}
	render() {
		return (
			<>
				<div
					style={{
						display: "flex",
					}}
				>
					<Button
						type="button"
						className="btn btn-lg btn-primary my-primary"
						style={{
							height: "35px",
							paddingTop: 0,
							paddingBottom: 0,
							marginRight: "15px",
							width: "15em",
							fontSize: "14px",
							backgroundColor: '#3b82c9'
						}}
						disabled={this.props.dataScopeId == null}
						onClick={() => {
							if (this.props.dataScopeId != null) {
								this.setState((state) => ({
									rowLevel: false,
									addVariableTableOption: this.props.table ?? {
										label: "",
										value: [],
										data_table_idx: this.props.dataScopeId,
										optimized: false,
									},
								}));
							}
						}}
					>
						Create new column
					</Button>
					<Button
						type="button"
						className="btn btn-lg btn-primary my-primary"
						style={{
							height: "35px",
							paddingTop: 0,
							paddingBottom: 0,
							marginRight: "15px",
							width: "19em",
							fontSize: "14px",
							backgroundColor: '#3b82c9'
						}}
						disabled={this.props.dataScopeId == null}
						onClick={() => {
							if (this.props.dataScopeId != null) {
								this.setState((state) => ({
									rowLevel: true,
									addVariableTableOption: this.props.table ?? {
										label: "",
										value: [],
										data_table_idx: this.props.dataScopeId,
										optimized: false,
									},
								}));
							}
						}}
					>
						Create new column (Row level)
					</Button>
					<Button
						type="button"
						className="btn btn-lg btn-primary my-primary"
						style={{
							height: "35px",
							paddingTop: 0,
							paddingBottom: 0,
							marginRight: "15px",
							width: "15em",
							fontSize: "14px",
							backgroundColor: '#3b82c9'
						}}
						disabled={this.props.dataScopeId == null}
						onClick={() => {
							if (this.props.dataScopeId != null) {
								this.setState((state) => ({
									copiedTableOption: this.props.table ?? {
										label: "",
										value: [],
										data_table_idx: this.props.dataScopeId,
										optimized: false,
									},
								}));
							}
						}}
					>
						Copy to new data set
					</Button>
					<Button
						type="button"
						className="btn btn-lg btn-primary my-primary"
						style={{
							height: "35px",
							paddingTop: 0,
							paddingBottom: 0,
							width: "15em",
							fontSize: "14px",
							backgroundColor: '#3b82c9'
						}}
						disabled={this.props.dataScopeId == null}
						onClick={() => {
							if (this.props.dataScopeId != null) {
								this.setState((state) => ({
									exportedTableOption: this.props.table ?? {
										label: "",
										value: [],
										data_table_idx: this.props.dataScopeId,
										optimized: false,
									},
								}));
							}
						}}
					>
						Export to CSV
					</Button>
				</div>
				{this.state.exportedTableOption != null && (
					<ExportCsvPopup
						overlayStyle={{
							zIndex: 100000000,
						}}
						inputTable={this.state.exportedTableOption}
						onClose={() => {
							this.setState({
								exportedTableOption: undefined,
							});
						}}
						currentModuleId={this.props.currentModuleId}
					/>
				)}
				{this.state.addVariableTableOption != null && (
					<AddVariablePopup
						overlayStyle={{
							zIndex: 100000000,
						}}
						inputTable={this.state.addVariableTableOption}
						onUpdate={this.props.onUpdate}
						onClose={() => {
							this.setState({
								addVariableTableOption: undefined,
							});
						}}
						currentModuleId={this.props.currentModuleId}
						rowLevel={this.state.rowLevel}
					/>
				)}
				{this.state.copiedTableOption != null && (
					<CopyTablePopup
						overlayStyle={{
							zIndex: 100000000,
						}}
						inputTable={this.state.copiedTableOption}
						onUpdate={this.props.onUpdate}
						onClose={() => {
							this.setState({ copiedTableOption: undefined });
						}}
						currentModuleId={this.props.currentModuleId}
					/>
				)}
			</>
		);
	}
}