import React from "react";
import SentimentAnalysisChart from "common/graphics/SentimentAnalysisChart";

function MainComponent(props) {
	let finding = props.finding;
	return (
		<div style={{ width: "100%", height: "100%" }}>
			<SentimentAnalysisChart
				data={finding.content.data}
				config={finding.config}
			/>
		</div>
	);
}

export { MainComponent };
