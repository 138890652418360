import React from "react";
import BaseAPIComponent from "../common_components/BaseAPIComponent";
import { APIComponentProps } from "../APIComponentProps";
import { lightThemeStyle } from "common/LightThemeStyle";
import Select, { SingleValue } from "react-select";
import { getCustomSelectStyleLight } from "common/SelectStyles";

interface State {
    inputHeadersList: KeyValue[];
    inputBodyParamsList: KeyValue[];
}
interface KeyValue {
    key: string;
    value: string;
}

export default class GENERIC extends React.Component<APIComponentProps, State> {
    constructor(props: APIComponentProps) {
        super(props);
        this.state = {
            inputHeadersList: [{ key: "", value: "" }],
            inputBodyParamsList: [{ key: "", value: "" }],
        };
    }

    componentDidMount() {
        if (this.props.options == null) {
            this.props.onOptionsChange({
                requestMethod: "GET",
            });
        }
        this.setState({
            inputHeadersList: this.props.options?.headers || [
                { key: "", value: "" },
            ],
            inputBodyParamsList: this.props.options?.bodyParams || [
                { key: "", value: "" },
            ],
        });
    }

    render() {
        const handleMethodChange = (e: { value: string; label: string }) => {
            let options = { ...this.props.options };
            this.props.onOptionsChange({
                ...options,
                requestMethod: e.value,
            });
            if (e.value === "GET") {
                const bodyParamsDiv =
                    document.getElementById("inputBodyParams");
                bodyParamsDiv?.setAttribute("style", "display:none");
            }
            if (e.value === "POST") {
                const bodyParamsDiv =
                    document.getElementById("inputBodyParams");
                bodyParamsDiv?.removeAttribute("style");
            }
        };

        const handleInputHeaderChange = (
            e: { target: { name: string; value: string } },
            index: number
        ) => {
            const { name, value } = e.target;
            const list = [...this.state.inputHeadersList];
            if (name === "headerName") list[index].key = value;
            if (name === "headerValue") list[index].value = value;
            this.setState({ inputHeadersList: list });
            let options = { ...this.props.options };
            this.props.onOptionsChange({
                ...options,
                headers: list,
            });
        };

        const handleInputBodyParamChange = (
            e: { target: { name: string; value: string } },
            index: number
        ) => {
            const { name, value } = e.target;
            const list = [...this.state.inputBodyParamsList];
            if (name === "bodyParamName") list[index].key = value;
            if (name === "bodyParamValue") list[index].value = value;
            this.setState({ inputBodyParamsList: list });
            let options = { ...this.props.options };
            this.props.onOptionsChange({
                ...options,
                bodyParams: list,
            });
        };

        // handle click event of the Remove header button
        const handleRemoveHeaderClick = (index: number) => {
            const list = [...this.state.inputHeadersList];
            list.splice(index, 1);
            this.setState({ inputHeadersList: list });
        };

        // handle click event of the Add header button
        const handleAddHeaderClick = () => {
            this.setState({
                inputHeadersList: [
                    ...this.state.inputHeadersList,
                    { key: "", value: "" },
                ],
            });
        };

        // handle click event of the Add body param button
        const handleAddBodyParamClick = () => {
            this.setState({
                inputBodyParamsList: [
                    ...this.state.inputBodyParamsList,
                    { key: "", value: "" },
                ],
            });
        };

        // handle click event of the Remove body param button
        const handleRemoveBodyParamClick = (index: number) => {
            const list = [...this.state.inputBodyParamsList];
            list.splice(index, 1);
            this.setState({ inputBodyParamsList: list });
        };
        let selectStyles = getCustomSelectStyleLight(14, false);

        let contentStyle: React.CSSProperties = {
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: 400,
            color: lightThemeStyle.primaryTextColor,
        };

        let titleStyle = {
            ...contentStyle,
            color: lightThemeStyle.secondaryTextColor,
        };

        let smallInputStyle = {
            fontSize: 12,
            marginTop: 10,
            width: 175,
            padding: "5px 5px",
        };

        let btnStyle: React.CSSProperties = {
            fontFamily: "Roboto",
            fontSize: 12,
            margin: 10,
            borderColor: "transparent",
        };

        return (
            <div
                className="flex-simple-column"
                style={this.props.containerStyle}
            >
                <BaseAPIComponent {...this.props} />
                <div
                    className="flex-simple-column"
                    style={{ marginTop: "23px" }}
                ></div>
                <span className="APILabel">URL</span>
                <input
                    style={{
                        marginTop: 4,
                        width: 300,
                    }}
                    className="textInput"
                    placeholder="Type a valid URL here"
                    value={this.props.options?.genericApiEndpoint ?? ""}
                    onChange={(evt) => {
                        let genericApiEndpointValue = evt.target.value;
                        let options = { ...this.props.options };
                        this.props.onOptionsChange({
                            ...options,
                            genericApiEndpoint: genericApiEndpointValue,
                        });
                    }}
                ></input>
                <div
                    style={{
                        marginTop: 20,
                        display: "flex",
                        alignItems: "center",
                    }}
                    id="inputMethod"
                >
                    <span style={titleStyle}>Request Method: </span>
                    <Select
                        name="hubspot-option"
                        options={[
                            { value: "GET", label: "GET" },
                            { value: "POST", label: "POST" },
                        ]}
                        defaultValue={{
                            value: this.props.options?.requestMethod || "GET",
                            label: this.props.options?.requestMethod || "GET",
                        }}
                        styles={{
                            ...selectStyles,
                            container: (base) => ({
                                ...base,
                                height: "40px",
                                width: 120,
                                marginLeft: 5,
                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 2,
                            colors: {
                                ...theme.colors,
                                text: "white",
                                primary25:
                                    "var(--selectors-background-hover-color)",
                            },
                        })}
                        onChange={(e) =>
                            handleMethodChange(
                                e as { value: string; label: string }
                            )
                        }
                    />
                </div>
                <div style={{ marginTop: 20 }} id="inputHeaders">
                    {this.state.inputHeadersList.length === 0 && (
                        <button
                            style={btnStyle}
                            onClick={handleAddHeaderClick}
                            className="textBtn"
                        >
                            Add Http Header
                        </button>
                    )}
                    {this.state.inputHeadersList.length !== 0 && (
                        <div style={titleStyle}>Custom Headers</div>
                    )}
                    {this.state.inputHeadersList.map((x, i) => {
                        return (
                            <div key={i}>
                                <input
                                    style={smallInputStyle}
                                    className="textInput"
                                    name="headerName"
                                    placeholder="Type a header name here"
                                    value={x.key}
                                    onChange={(e) =>
                                        handleInputHeaderChange(e, i)
                                    }
                                />
                                <span style={{ margin: "0 5px" }}>:</span>
                                <input
                                    style={smallInputStyle}
                                    className="textInput"
                                    name="headerValue"
                                    placeholder="Type a header value here"
                                    value={x.value}
                                    onChange={(e) =>
                                        handleInputHeaderChange(e, i)
                                    }
                                />
                                <button
                                    className="btn my-primary btn-primary"
                                    style={btnStyle}
                                    onClick={() => handleRemoveHeaderClick(i)}
                                >
                                    Remove
                                </button>
                                <button
                                    className="textBtn"
                                    onClick={handleAddHeaderClick}
                                    style={btnStyle}
                                >
                                    Add 1+ Header
                                </button>
                            </div>
                        );
                    })}
                </div>
                <div style={{ marginTop: 20 }}></div>
                <div
                    style={{
                        display:
                            this.props.options?.requestMethod === "POST"
                                ? "block"
                                : "none",
                    }}
                    id="inputBodyParams"
                >
                    {this.state.inputBodyParamsList.length === 0 && (
                        <button
                            style={btnStyle}
                            onClick={handleAddBodyParamClick}
                            className="textBtn"
                        >
                            Add Body Param
                        </button>
                    )}
                    {this.state.inputBodyParamsList.length !== 0 && (
                        <div style={titleStyle}>Custom Body Params</div>
                    )}
                    {this.state.inputBodyParamsList.map((x, i) => {
                        return (
                            <div key={i}>
                                <input
                                    style={smallInputStyle}
                                    className="textInput"
                                    name="bodyParamName"
                                    placeholder="Type a body param here"
                                    value={x.key}
                                    onChange={(e) =>
                                        handleInputBodyParamChange(e, i)
                                    }
                                />
                                <span style={{ margin: "0 5px" }}>:</span>
                                <input
                                    style={smallInputStyle}
                                    className="textInput"
                                    name="bodyParamValue"
                                    placeholder="Type a body param value here"
                                    value={x.value}
                                    onChange={(e) =>
                                        handleInputBodyParamChange(e, i)
                                    }
                                />
                                <button
                                    className="btn my-primary btn-primary"
                                    style={btnStyle}
                                    onClick={() =>
                                        handleRemoveBodyParamClick(i)
                                    }
                                >
                                    Remove
                                </button>
                                <button
                                    className="textBtn"
                                    onClick={handleAddBodyParamClick}
                                    style={btnStyle}
                                >
                                    Add 1+ Body Param
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export { GENERIC as MainComponent };
