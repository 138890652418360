import React from "react";
import HoldOutPredictionBarChart from "common/graphics/HoldOutPredictionBarChart";

function MainComponent(props) {
	let finding = props.finding;
	let ivNames = undefined;
	if (finding.content.regressionResult.iv_main_names != null)
		ivNames = [...finding.content.regressionResult.iv_main_names];
	if (finding.content.regressionResult.iv_other_names != null) {
		if (ivNames != null)
			ivNames = ivNames.concat(
				finding.content.regressionResult.iv_other_names
			);
		else ivNames = [...finding.content.regressionResult.iv_other_names];
	}
	return (
		<div style={{ width: "100%", height: "100%" }}>
			<HoldOutPredictionBarChart
				showLeverBars={finding.config.showLeverBars ?? true}
				userValues={finding.config.userValues}
				onNewResult={(update, index) => {
					let newFinding = Object.assign({}, finding);
					newFinding.content.regressionResult.predictedBars[index] = {
						...newFinding.content.regressionResult.predictedBars[
							index
						],
						...update,
					};
					if (props.onNewFinding) {
						props.onNewFinding(newFinding);
					}
				}}
				onNewUserValues={(userValues, diff) => {
					let newFinding = Object.assign({}, finding);
					newFinding.config.userValues = userValues;
					for (let name of diff) {
						newFinding.config.regressionInfo[3].defaultValue = {
							...newFinding.config.regressionInfo[3]
								?.defaultValue,
							[name]: false,
						};
					}
					if (props.onNewFinding) {
						props.onNewFinding(newFinding);
					}
				}}
				ivNames={ivNames}
				showDynamicOptions={
					finding.config.regressionInfo.length === 6
						? finding.config.regressionInfo[5]
						: undefined
				}
				defaultValue={finding.config.regressionInfo[3]?.defaultValue}
				decimals={
					finding.config.regressionInfo?.[3]
						?.decimals
				}
				showIntervals={finding.config.showIntervals}
				onShowIntervalsChange={(showIntervals, clustIndex) => {
					let newFinding = Object.assign({}, finding);
					newFinding.content.regressionResult.predictedBars[
						clustIndex
					].show_intervals = showIntervals;
					if (props.onNewFinding) {
						props.onNewFinding(newFinding);
					}
				}}
				onCenterItem={(clustIndex, chartInterval) => {
					let newFinding = Object.assign({}, finding);
					newFinding.content.regressionResult.predictedBars[
						clustIndex
					].chart_interval = chartInterval;
					if (props.onNewFinding) {
						props.onNewFinding(newFinding);
					}
				}}
				onConfidenceLevelChange={(confidenceLevel, clustIndex) => {
					let newFinding = Object.assign({}, finding);
					newFinding.content.regressionResult.predictedBars[
						clustIndex
					].confidence_level = confidenceLevel;
					if (props.onNewFinding) {
						props.onNewFinding(newFinding);
					}
				}}
				confidenceLevel={finding.config.confidenceLevel}
				dynamic={finding.config.dynamic}
				regressionOptions={finding.config.regressionInfo[0]}
				dependentVariable={finding.config.selectedDependentVariable}
				showTrueValue={finding.config.showTrueValue}
				dataScopeId={finding.config.dataScope.value}
				data={finding.content.regressionResult.predictedBars}
			/>
		</div>
	);
}

export { MainComponent };