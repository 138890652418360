import React from "react";
import { mainStyle } from "common/MainStyle";

export default function MultiVariablesSelector(props) {
	let variableName = props.variableName;
	let variablesName = props.variablesName;
	let selectedVariables = props.selectedVariables;
	let allVariables = props.allVariables;
	return (
		<div
			style={{
				marginTop: 20,
				display: "grid",
				gridTemplateColumns: "repeat(4, 1fr)",
				gridRowGap: "20px",
				gridColumnGap: "20px",
			}}
		>
			{allVariables.map((variable, index) => {
				let selected = selectedVariables.includes(variable);
				let journeyVariableStyle = "journey-variable".concat(
					selected ? "-selected" : ""
				);
				let journeyVariableSmallStyle = "journey-small-variable".concat(
					selected ? "-selected" : ""
				);

				return (
					<div
						onClick={() => {
							props.onChange(variableName, variablesName, index);
						}}
						key={index}
						className={"my-row ".concat(journeyVariableStyle)}
						style={{
							justifyContent: "space-between",
							height: "56px",
							width: "250px",
							cursor: "pointer",
						}}
					>
						<div
							className="my-row"
							style={{ alignItems: "center" }}
						>
							<div
								className={journeyVariableSmallStyle}
								style={{
									margin: 10,
									height: "28px",
									width: "26px",
								}}
							>
								<div
									className="center-container"
									style={{
										height: "100%",
										alignItems: "center",
									}}
								>
									<span
										style={{
											fontFamily: "Roboto",
											fontSize: "20px",
											lineHeight: "23px",
											color: selected
												? "#20293C"
												: mainStyle.getPropertyValue(
														"--selectors-text-color"
												  ),
											fontWeight: 300,
										}}
									>
										{String.fromCharCode(65 + index)}
									</span>
								</div>
							</div>
							<span
								style={{
									textAlign: "center",
									wordBreak: "break-word",
									fontFamily: "Roboto",
									fontSize: "20px",
									lineHeight: "23px",
									color: selected
										? "#39F"
										: mainStyle.getPropertyValue(
												"--selectors-text-color"
										  ),
									fontWeight: 300,
									display: "block",
								}}
							>
								{variable}
							</span>
						</div>
						{selected ? (
							<div
								style={{
									margin: 20,
									alignItems: "center",
									display: "flex",
								}}
							>
								<img
									alt=""
									src="/dist/img/data-exploration/icon_checkbox.png"
								/>
							</div>
						) : (
							<div style={{ height: "27px" }} />
						)}
					</div>
				);
			})}
		</div>
	);
}