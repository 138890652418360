import React from "react";
import CustomBarChart from "./CustomBarChart";
import { Element } from "react-scroll";
import { LegendEditElement } from "../LegendEditElement";
import { LeversBenchmarkingFinding } from "common/Finding";
import mobileBreakpoint from "common/utilities/UIResponsiveManager";
import {
    getContainerStyleByTheme,
    getDefaultColorsByTheme,
} from "../BarChartTheme";
import sections from "sections.json";

interface Props {
    data: LeversBenchmarkingFinding["content"]["data"];
    config: LeversBenchmarkingFinding["config"];
    groupValue: string | number;
    compareValue: string | number;
    aggregateVariable: string;
    groupOperation: "best" | "worst" | "average";
    compareOperation: "best" | "worst" | "average";
    preview?: boolean;
    editable?: boolean;
    columnDragActive?: boolean;
    onChangeData?: (data: any[], updateFinding?: boolean) => void;
    onChangeConfig?: (config: any, updateFinding?: boolean) => void;
    showGrid?: boolean;
    truncValues?: boolean;
    manualTicks?: boolean;
    clustName?: string;
}

function LeversBenchmarkingChart(props: Props) {
    let {
        editable,
        groupValue,
        compareValue,
        aggregateVariable,
        clustName,
        groupOperation,
        compareOperation,
        config,
        onChangeConfig,
        onChangeData,
        data,
        showGrid,
        truncValues,
        manualTicks,
    } = props;
    if (!aggregateVariable) return null;
    let {
        dataScope,
        barSize,
        barGap,
        barCategoryGap,
        maxYRange,
        minYRange,
        linesCount,
        statusExpressions,
        selectedTargetVariablesIndices,
        labelMapping,
        nameColorMapping,
        legendsColor,
        axesColor,
        showXAxisName,
        showYAxisName,
        yAxisName,
        axesLinesColor,
        ticksColor,
        ticksSize,
        axesNamesColor,
        tooltipColor,
        tooltipFontSize,
        chartTheme,
        baseBackgroundColor,
        showLegend,
        legendSize,
        stacked,
        showAsDots,
    } = config;

    let legendFirst = groupOperation.concat(" ").concat(aggregateVariable);
    let legendSecond = compareOperation.concat(" ").concat(aggregateVariable);

    if (groupValue)
        legendFirst = legendFirst.concat(": ").concat(groupValue.toString());
    if (compareValue)
        legendSecond = legendSecond
            .concat(": ")
            .concat(compareValue.toString());

    let currentEditVariableIndex: number | undefined = undefined;
    if (dataScope != null && props.columnDragActive) {
        currentEditVariableIndex = data.findIndex(
            (item) => item.variableIndex == null
        );
    }

    let defaultOptions = getDefaultColorsByTheme(chartTheme);
    let containerStyle = getContainerStyleByTheme(
        chartTheme,
        baseBackgroundColor ?? defaultOptions.baseBackgroundColor
    );

    return (
        <div
            className="flex-simple-column"
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            <div
                className="my-row"
                style={{
                    justifyContent: "center",
                    marginLeft: 20,
                    marginRight: 20,
                    width: "100%",
                    minHeight: 16,
                }}
            >
                {(showLegend ?? true) && (
                    <>
                        <LegendEditElement
                            allowColorChange={editable}
                            textSize={legendSize}
                            disallowTextChange
                            onFinishEdit={(label) => {
                                let newLabelMapping = {
                                    ...labelMapping,
                                    [legendFirst]: label,
                                };
                                let newConfig = {
                                    ...config,
                                    labelMapping: newLabelMapping,
                                };
                                onChangeConfig?.(newConfig);
                            }}
                            onFinishColor={(color) => {
                                let newColorMapping = {
                                    ...nameColorMapping,
                                    "0": color,
                                };
                                let newConfig = {
                                    ...config,
                                    nameColorMapping: newColorMapping,
                                };
                                onChangeConfig?.(newConfig);
                            }}
                            color={nameColorMapping?.[0] ?? "#39F"}
                            text={labelMapping?.[legendFirst] ?? legendFirst}
                            textColor={legendsColor}
                        />
                        <LegendEditElement
                            textSize={legendSize}
                            allowColorChange={editable}
                            disallowTextChange
                            onFinishEdit={(label) => {
                                let newLabelMapping = {
                                    ...labelMapping,
                                    [legendSecond]: label,
                                };
                                let newConfig = {
                                    ...config,
                                    labelMapping: newLabelMapping,
                                };
                                onChangeConfig?.(newConfig);
                            }}
                            onFinishColor={(color) => {
                                let newColorMapping = {
                                    ...nameColorMapping,
                                    "1": color,
                                };
                                let newConfig = {
                                    ...config,
                                    nameColorMapping: newColorMapping,
                                };
                                onChangeConfig?.(newConfig);
                            }}
                            color={nameColorMapping?.[1] ?? "#EE423D"}
                            text={labelMapping?.[legendSecond] ?? legendSecond}
                            textColor={legendsColor}
                        />
                    </>
                )}
            </div>
            <div
                className="my-row"
                style={{
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                <Element
                    name="scrollable"
                    className="element"
                    style={{
                        height: "100%",
                        paddingTop: 10,
                        overflowX: mobileBreakpoint() ? "hidden" : "auto",
                        overflowY: "hidden",
                        backgroundColor: "transparent",
                        position: "relative",
                        ...containerStyle,
                    }}
                >
                    <CustomBarChart
                        ticksSize={ticksSize}
                        maxYRange={maxYRange}
                        minYRange={minYRange}
                        linesCount={linesCount}
                        axesColor={axesColor}
                        barSize={barSize}
                        watermark={sections._Global?.watermark}
                        groupNames={[groupOperation, compareOperation]}
                        barColors={[
                            nameColorMapping?.[0] ?? "#39F",
                            nameColorMapping?.[1] ?? "#EE423D",
                        ]}
                        colorByGroup={true}
                        data={data}
                        title={clustName}
                        showXAxisName={showXAxisName}
                        showYAxisName={showYAxisName}
                        tooltipColor={tooltipColor}
                        tooltipFontSize={tooltipFontSize}
                        yAxisName={yAxisName}
                        editable={editable}
                        chartTheme={chartTheme}
                        currentEditVariableIndex={currentEditVariableIndex}
                        baseBackgroundColor={
                            baseBackgroundColor ??
                            defaultOptions.baseBackgroundColor
                        }
                        statusExpressions={statusExpressions}
                        barIndices={selectedTargetVariablesIndices}
                        axesLinesColor={axesLinesColor}
                        axesNamesColor={axesNamesColor}
                        ticksColor={ticksColor}
                        labelMapping={labelMapping}
                        barGap={barGap}
                        barCategoryGap={barCategoryGap}
                        stacked={stacked}
                        showAsDots={showAsDots}
                        onLinkVariable={(
                            index,
                            variableName,
                            variableIndex
                        ) => {
                            let newData = Array.from(data);
                            newData[index].name = variableName;
                            newData[index].originalName = variableName;
                            newData[index].variableIndex = variableIndex;
                            onChangeData?.(newData, true);
                        }}
                        onRenameVariable={(index, name) => {
                            let newData = Array.from(data);
                            newData[index].name = name;
                            onChangeData?.(newData);
                        }}
                        onConfigChange={(changes) => {
                            let newConfig = { ...config, ...changes };
                            onChangeConfig?.(newConfig);
                        }}
                        showGrid={showGrid}
                        truncValues={truncValues}
                        manualTicks={manualTicks}
                    />
                </Element>
            </div>
        </div>
    );
}

export default LeversBenchmarkingChart;
