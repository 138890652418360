import {
    CanvasSurvey,
    SurveyQuestionType,
    SurveyQuestion,
    RuleSurveyQuestion,
} from "common/Canvas";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import React from "react";
import styles from "./Rules.module.css";
import { modifySchemaApi } from "common/DataApi";
import { EditVariable, NewVariable } from "common/VariableCreator";
import { Panel, Type } from "common/InputData";
import RenderRule from "./Rule";
import remoteModuleId from "common/remoteModuleId";
import _ from "lodash";
import Variables from "common/Variables";

const RULES_LIMIT = 10;
interface RulesProps {
    canvasId: number;
    node: CanvasSurvey;
    canvasTreeStore: CanvasTreeStore;
    currentModuleId?: number;
    onChange: (
        changes: Partial<CanvasSurvey>,
        commit?: boolean,
        skipHistory?: boolean
    ) => void;
}

const Rules: React.FC<RulesProps> = ({
    node,
    onChange,
    currentModuleId,
    canvasTreeStore,
}) => {
    const [disabled, setDisabled] = React.useState(false);
    const debounceClick = React.useRef(
        _.debounce(() => {
            setDisabled(false);
        }, 500)
    ).current;

    const modifyDbSchema = (
        newVariable: NewVariable | null,
        dropVariable: number | null,
        editVariable: EditVariable | null,
        previousState?: SurveyQuestion
    ) => {
        if (node.backendOutput.tableOption?.data_table_idx) {
            if (editVariable) editVariable.index = editVariable.index + 1;
            modifySchemaApi(
                node.backendOutput.tableOption?.data_table_idx,
                editVariable ? [editVariable] : [],
                newVariable ? [newVariable] : [],
                dropVariable !== null ? [dropVariable + 1] : [],
                remoteModuleId ?? currentModuleId
            )
                .then(() => {
                    Variables(
                        node.backendOutput.tableOption?.data_table_idx
                    ).update(currentModuleId ?? remoteModuleId);
                    canvasTreeStore.updateAllAsyncAction.bothParts(
                        false,
                        canvasTreeStore.canvasTreeState
                    );
                })
                .catch(() => {
                    if (editVariable != null && previousState != null) {
                        let questions = [...node.questions];
                        questions[editVariable.index - 1] = previousState;
                        onChange({
                            questions: questions,
                        });
                    }
                    if (newVariable !== null) {
                        let questions = [...node.questions];
                        questions = questions.filter(
                            (q: SurveyQuestion) =>
                                newVariable.name !== q.columnName ?? q.question
                        );
                        onChange({
                            questions: questions,
                        });
                    }
                });
        }
    };

    const addRule = () => {
        let newQuestionId = node.questions[node.questions.length - 1].id + 1;
        let ruleQuestions = node.questions.filter(
            (x) => x.type === SurveyQuestionType.Rule
        );
        if (ruleQuestions.length < RULES_LIMIT) {
            let newRuleName = `Rule ${newQuestionId}...`;
            onChange(
                {
                    questions: [
                        ...node.questions,
                        {
                            question: newRuleName,
                            type: SurveyQuestionType.Rule,
                            id: newQuestionId,
                            options: [],
                            linkedToId: null,
                            rules: [
                                {
                                    value: "",
                                    ruleConditions: [],
                                },
                            ],
                        } as RuleSurveyQuestion,
                    ],
                },
                true,
                false
            );

            let newVariable: NewVariable = {
                name: newRuleName,
                unit: null,
                type: Type.Str,
                level: null,
                format: null,
                panel: Panel.Regular,
            };

            if (node.backendOutput.tableOption?.data_table_idx) {
                modifyDbSchema(newVariable, null, null);
            }
        }
        setDisabled(true);
        debounceClick();
    };

    return (
        <div className={styles.rulesContainer}>
            <p className={styles.rulesHeader}>Rules</p>
            <p
                className={styles.add}
                onClick={() => {
                    if (!disabled) addRule();
                }}
            >
                + Add
            </p>
            {node.questions.map((question, questionIndex) => {
                if (question.type !== SurveyQuestionType.Rule) {
                    return null;
                } else {
                    return (
                        <RenderRule
                            key={question.id}
                            modifyDbSchema={modifyDbSchema}
                            questionIndex={questionIndex}
                            onChange={onChange}
                            node={node}
                            currentModuleId={currentModuleId}
                        />
                    );
                }
            })}
        </div>
    );
};

export default Rules;
