export type Interval = [number, number];

/**
 * Returns distance between two intervals.
 *
 * Distance will be negative if the intervals overlap
 */
export function distanceBetweenIntervals(i1: Interval, i2: Interval): number {
    if (i1[0] < i2[0]) {
        return i2[0] - i1[1];
    } else {
        return i1[0] - i2[1];
    }
}

/**
 * Returns middle point between two intervals.
 *
 */
export function middleBetweenIntervals(i1: Interval, i2: Interval): number {
    if (i1[0] < i2[0]) {
        return (i2[0] + i1[1]) / 2;
    } else {
        return (i1[0] + i2[1]) / 2;
    }
}
