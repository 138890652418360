import { observable, computed, makeObservable, action } from "mobx";
import axios from "./ServerConnection";

export interface VariableOption {
    label: string;
    value: number;
    type: string;
    panel: string;
    unit?: string;
    level?: string | null;
    update?: string;
    contextId?: string;
}

export interface Variable {
    name: string;
    type: string;
    unit: string;
    panel: string;
    level: string | null;
    index: number | null;
    derived_from: number | null;
    format: string | null;
    description?: string;
    summary?:
        | { count: number; pie?: { [key: string]: number } }
        | {
              max: number;
              min: number;
              hist: number[];
              bin_edges: number[];
              is_datetime: boolean;
          };
}

export interface ROEVariable extends Variable {
    variable?: string;
    overall?: boolean;
}

export class VariablesInner {
    @observable public variableInfoState: Variable[] = [];
    @observable public columnUnitOptionsState: string[] = [
        "",
        "%",
        "Cal",
        "hours",
        "Lbs",
    ];
    public columnTypeOptionsState: string[] = ["str", "int", "float"];

    private dataScopeId: number | string | undefined;
    @observable public initializedState: boolean = false;
    @observable public initializedWithSummaryState: boolean = false;

    public loadingState: boolean = false;
    public loadingSummaryState: boolean = false;

    constructor(
        dataScopeId: number | string | undefined,
        moduleId?: number | string | null,
        withSummary?: boolean
    ) {
        makeObservable(this);
        this.dataScopeId = dataScopeId;
        this.update(moduleId, withSummary);
    }

    @action.bound
    assignVariables(variables: Variable[], withSummary: boolean) {
        this.variableInfoState = variables;
        this.initializedState = true;
        this.loadingState = false;
        this.initializedWithSummaryState = withSummary;
        if (withSummary) this.loadingSummaryState = false;
    }

    @computed public get types(): { [key: string]: string } {
        let types: { [key: string]: string } = {};
        this.variableInfoState.forEach((item: Variable) => {
            types[item.name] = item.type;
        });
        return types;
    }

    @action.bound
    updateDescription(variableIndex: number, description: string) {
        let variables = Array.from(this.variableInfoState);
        let variable = variables.find(
            (variable) => variable.index === variableIndex
        );
        if (variable != null) {
            variable.description = description;
            this.variableInfoState = variables;
        }
    }

    @computed public get initialized(): boolean {
        return this.initializedState;
    }

    @computed public get initializedWithSummary(): boolean {
        return this.initializedWithSummaryState;
    }

    @computed public get variableNames(): string[] {
        return this.variableInfoState.map((item) => item.name);
    }

    @computed public get dataVariables(): Variable[] {
        return this.variableInfoState;
    }

    @computed public get geographyVariables(): Variable[] {
        let filtered: Variable[] = this.variableInfoState.filter(
            (item) => item.panel === "geography"
        );
        return filtered;
    }
    public geographyGroup(variableName: string): Variable[] {
        let filtered: Variable[] = this.variableInfoState.filter(
            (item) => item.name === variableName
        );
        if (filtered.length === 0) return [];
        let variable: Variable = filtered[0];
        if (variable.panel !== "geography") return [];
        if (variable.derived_from == null) {
            let results = [variable].concat(
                this.variableInfoState.filter(
                    (item) => item.derived_from === variable.index
                )
            );
            return results;
        } else {
            let results = this.variableInfoState.filter(
                (item) =>
                    item.derived_from === variable.derived_from ||
                    item.index === variable.derived_from
            );
            return results;
        }
    }
    public derivedGroup(variableIndex: number): Variable[] {
        let variable: Variable | undefined = this.variableInfoState.find(
            (item) => item.index === variableIndex
        );
        if (variable == null) return [];
        if (variable.derived_from == null) {
            let results = this.variableInfoState.filter(
                (item) => item.derived_from === variable!.index
            );

            return results;
        }
        return [];
    }
    @computed public get timeVariables(): string[] {
        let filtered: string[] = this.variableInfoState
            .filter((item) => item.panel === "time" || item.type === "datetime")
            .map((item) => item.name);
        return filtered;
    }

    public isMonthVariable(monthVariable: string): boolean {
        let filtered: Variable[] = this.variableInfoState.filter(
            (item) => item.level === "month" && item.name === monthVariable
        );
        return filtered.length > 0;
    }
    public variableLevels(parentVariableName: string): string[] {
        let variableIndex = this.variableInfoState.findIndex(
            (variable) => variable.name === parentVariableName
        );
        let filtered: string[] = this.variableInfoState
            .filter((item) => item.derived_from === variableIndex)
            .map((item) => item.name);
        return filtered;
    }
    public variableToIndex(variableName: string): number | null {
        let variableIndex = this.variableInfoState.findIndex(
            (variable) => variable.name === variableName
        );
        if (variableIndex === -1) return null;
        return variableIndex;
    }
    @computed public get regularVariables(): Variable[] {
        return this.variableInfoState.filter(
            (item) => item.panel === "regular" && item.type !== "datetime"
        );
    }
    @computed public get numericVariables(): Variable[] {
        return this.variableInfoState.filter(
            (item) =>
                ["int", "float"].includes(item.type) && item.panel === "regular"
        );
    }
    @computed public get textVariables(): Variable[] {
        return this.variableInfoState.filter(
            (item) => item.type === "str" && item.panel === "regular"
        );
    }

    @computed public get categoricalVariables(): Variable[] {
        return this.variableInfoState.filter((item) => item.type !== "float");
    }

    @computed public get columnUnitOptions(): string[] {
        return this.columnUnitOptionsState;
    }

    @computed public get columnTypeOptions(): string[] {
        return this.columnTypeOptionsState;
    }

    @computed public get aggregateVariables(): string[] {
        return this.variableInfoState
            .map((item) => item.name)
            .filter((item: string | undefined): item is string => item != null);
    }

    @computed public get roeVariables(): ROEVariable[] {
        return this.numericVariables
            .map(
                (variable: Variable): ROEVariable => ({
                    unit: "%",
                    panel: "regular",
                    type: "float",
                    name: "roe_".concat(variable.name),
                    variable: variable.name,
                    derived_from: variable.derived_from,
                    index: null,
                    level: null,
                    format: null,
                })
            )
            .concat([
                {
                    unit: "%",
                    panel: "regular",
                    type: "float",
                    name: "roe_overall",
                    overall: true,
                    derived_from: null,
                    index: null,
                    level: null,
                    format: null,
                },
            ]);
    }

    @computed public get scaleMap(): { [key: string]: string[] } {
        let scaleMap: { [key: string]: string[] } = {};
        this.aggregateVariables.forEach((variable) => {
            scaleMap[variable] = this.variableLevels(variable);
        });
        return scaleMap;
    }

    @computed public get variablesForRenaming(): string[] {
        return this.variableInfoState
            .map((item) => item.name)
            .filter((item: string | undefined): item is string => item != null);
    }

    @computed public get variableOptions(): VariableOption[] {
        return this.variableInfoState.map((item) => ({
            label: item.name,
            value: item.index as number,
            type: item.type,
            panel: item.panel,
            unit: item.unit,
            level: item.level,
        }));
    }

    @computed public get numericVariableOptions(): VariableOption[] {
        return this.numericVariables.map((item) => ({
            label: item.name,
            value: item.index as number,
            type: item.type,
            panel: item.panel,
            unit: item.unit,
            level: item.level,
        }));
    }

    public setColumnUnitOptions(newValue: string[]): void {
        this.columnUnitOptionsState = newValue;
    }

    public getUnits(variableName: string): string {
        let itemInfo = this.variableInfoState.filter(
            (variable) => variable.name === variableName
        );
        if (itemInfo.length > 0) {
            return itemInfo[0].unit;
        } else return "";
    }

    public getVariableByName(variableName: string): Variable | null {
        let itemInfo = this.variableInfoState.filter(
            (variable) => variable.name === variableName
        );
        if (itemInfo.length > 0) {
            return itemInfo[0];
        } else return null;
    }

    public getVariableByIndex(index: number): Variable | null {
        let itemInfo = this.variableInfoState.filter(
            (variable) => variable.index === index
        );
        if (itemInfo.length > 0) {
            return itemInfo[0];
        } else return null;
    }

    public getType(variableName: string): string {
        let itemInfo = this.variableInfoState.filter(
            (variable) => variable.name === variableName
        );
        if (itemInfo.length > 0) {
            return itemInfo[0].type;
        } else return "";
    }

    public getTypeByIndex(index: number): string {
        let itemInfo = this.variableInfoState.filter(
            (variable) => variable.index === index
        );
        if (itemInfo.length > 0) {
            return itemInfo[0].type;
        } else return "";
    }

    @action.bound
    public async update(
        moduleId?: number | string | null,
        withSummary?: boolean
    ): Promise<void> {
        if (typeof this.dataScopeId !== "undefined") {
            this.loadingState = true;
            if (withSummary) this.loadingSummaryState = true;
            return axios
                .post<{
                    success: boolean;
                    error_msg: string;
                    variable_info: Variable[];
                }>("/api/e/get_variable_info", {
                    data_table_idx: this.dataScopeId,
                    module_id: moduleId,
                    with_summary: withSummary,
                })
                .then((response) => {
                    if (
                        response.data.success &&
                        response.data.variable_info != null
                    ) {
                        this.assignVariables(
                            response.data.variable_info.map((item, index) => ({
                                ...item,
                                index: index,
                            })),
                            withSummary ?? false
                        );
                    } else {
                        this.loadingState = false;
                        if (withSummary) this.loadingSummaryState = false;
                        console.log(response.data.error_msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingState = false;
                    if (withSummary) this.loadingSummaryState = false;
                    return Promise.reject(error);
                });
        } else return Promise.resolve();
    }
}

let variablesMap: { [key in number | string]: VariablesInner } = {};
let variablesUndefined: VariablesInner = new VariablesInner(undefined);

export default function Variables(
    dataScopeId: number | string | undefined,
    moduleId?: number | string | null,
    withSummary?: boolean,
    skipUpdate?: boolean
): VariablesInner {
    if (dataScopeId != null) {
        if (!(dataScopeId in variablesMap)) {
            variablesMap[dataScopeId] = new VariablesInner(
                dataScopeId,
                moduleId,
                withSummary
            );
        } else if (
            !withSummary &&
            !variablesMap[dataScopeId].initializedState &&
            !variablesMap[dataScopeId].loadingState &&
            !skipUpdate
        ) {
            variablesMap[dataScopeId].update(moduleId, withSummary);
        } else if (
            withSummary &&
            !variablesMap[dataScopeId].initializedWithSummaryState &&
            !variablesMap[dataScopeId].loadingSummaryState &&
            !skipUpdate
        ) {
            variablesMap[dataScopeId].update(moduleId, withSummary);
        }
        return variablesMap[dataScopeId];
    } else {
        return variablesUndefined;
    }
}
