import { mainStyle } from "common/MainStyle";
export const lightThemeStyle = {
    backgroundColor: mainStyle
        .getPropertyValue("--section-portal-background-color")
        .trim(),
    contentColor: mainStyle
        .getPropertyValue("--section-portal-content-color")
        .trim(),
    titleBackgroundColor: mainStyle
        .getPropertyValue("--section-portal-title-background-color")
        .trim(),
    titleTextColor: mainStyle
        .getPropertyValue("--section-portal-title-text-color")
        .trim(),
    primaryTextColor: mainStyle
        .getPropertyValue("--section-portal-primary-text-color")
        .trim(),
    secondaryTextColor: mainStyle
        .getPropertyValue("--section-portal-secondary-text-color")
        .trim(),
    tertiaryTextColor: mainStyle
        .getPropertyValue("--section-portal-tertiary-text-color")
        .trim(),
    cancelButtonBackgroundColor: mainStyle
        .getPropertyValue("--section-portal-cancel-button-background-color")
        .trim(),
    conditionInputsBackgroundColor: mainStyle
        .getPropertyValue("--section-portal-condition-inputs-background-color")
        .trim(),
    switchOffColor: mainStyle
        .getPropertyValue("--section-portal-switch-off-color")
        .trim(),
    switchOnColor: mainStyle
        .getPropertyValue("--section-portal-switch-on-color")
        .trim(),
    switchOnHandleColor: mainStyle
        .getPropertyValue("--section-portal-switch-on-handle-color")
        .trim(),
    switchOffHandleColor: mainStyle
        .getPropertyValue("--section-portal-switch-off-handle-color")
        .trim(),
};