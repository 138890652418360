import React, {
    ChangeEventHandler,
    CSSProperties,
    FocusEventHandler,
    KeyboardEventHandler,
    Ref,
} from "react";

interface Props {
    currentSimpleEdit: React.RefObject<HTMLInputElement>;
    placeholder: string;
    style: CSSProperties;
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onFocus: FocusEventHandler<HTMLInputElement>;
    keepSimpleEditChanges: Function;
    onKeyDown: KeyboardEventHandler<HTMLInputElement>;
}

const InputFieldDefault: React.FC<Props> = (props) => {
    const {
        currentSimpleEdit,
        placeholder,
        style,
        value,
        onChange,
        onFocus,
        keepSimpleEditChanges,
        onKeyDown,
    } = props;
    return (
        <input
            ref={currentSimpleEdit}
            placeholder={placeholder}
            autoFocus
            style={style}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={(evt) => {
                keepSimpleEditChanges();
            }}
            onKeyDown={onKeyDown}
        />
    );
};

export default InputFieldDefault;
