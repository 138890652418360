import {
    StylesConfig,
    GroupBase,
    CSSObjectWithLabel,
    InputProps,
} from "react-select";
import { mainStyle } from "common/MainStyle";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import StringOption from "./StringOption";

export type Styles = StylesConfig<any, boolean, GroupBase<any>>;

const searchComponentStyles: Styles = {
    option: (provided, state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
        fontSize: 12,
        fontFamily: "Roboto",
        backgroundColor: state.isFocused
            ? mainStyle.getPropertyValue("--selectors-background-hover-color")
            : state.isSelected
                ? mainStyle.getPropertyValue("--selectors-background-hover-color")
                : mainStyle.getPropertyValue("--selectors-background-color"),
    }),
    control: (provided, _state) => ({
        ...provided,
        backgroundColor: mainStyle.getPropertyValue(
            "--selectors-background-color"
        ),
        borderRadius: 5,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
        fontSize: 12,
        fontFamily: "Roboto",
        display: state.selectProps?.menuIsOpen ? "none" : "block",
    }),
    input: (provided, _state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
        fontSize: 12,
        fontFamily: "Roboto",
    }),
    menu: (provided, _state) => ({
        ...provided,
        backgroundColor: mainStyle.getPropertyValue(
            "--selectors-background-color"
        ),
        borderRadius: 5,
    }),
    dropdownIndicator: (provided, _state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
    }),
};

export function getCustomSelectStyle(fontSize: number): Styles {
    return getCustomSelectStyleLight(fontSize, false);
    // return {
    //     option: (provided, state) => ({
    //         ...provided,
    //         color: mainStyle.getPropertyValue("--selectors-text-color"),
    //         fontSize: fontSize,
    //         fontFamily: "Roboto",
    //         backgroundColor: state.isFocused
    //             ? mainStyle.getPropertyValue(
    //                   "--selectors-background-hover-color"
    //               )
    //             : state.isSelected
    //             ? mainStyle.getPropertyValue(
    //                   "--selectors-background-hover-color"
    //               )
    //             : mainStyle.getPropertyValue("--selectors-background-color"),
    //     }),
    //     control: (provided, _state) => ({
    //         ...provided,
    //         border: 0,
    //         backgroundColor: mainStyle.getPropertyValue(
    //             "--selectors-background-color"
    //         ),
    //         borderRadius: 3,
    //     }),
    //     singleValue: (provided, state) => ({
    //         ...provided,
    //         color: mainStyle.getPropertyValue("--selectors-text-color"),
    //         fontSize: fontSize,
    //         fontFamily: "Roboto",
    //         display: state.selectProps.menuIsOpen ? "none" : "block",
    //     }),
    //     input: (provided, _state) => ({
    //         ...provided,
    //         color: mainStyle.getPropertyValue("--selectors-text-color"),
    //         fontSize: fontSize,
    //         fontFamily: "Roboto",
    //     }),
    //     placeholder: (provided) => ({
    //         ...provided,
    //         color: "#98A7B9",
    //     }),
    //     menu: (provided, _state) => ({
    //         ...provided,
    //         backgroundColor: mainStyle.getPropertyValue(
    //             "--selectors-background-color"
    //         ),
    //         borderRadius: 3,
    //     }),
    //     indicatorSeparator: (provided, _state) => ({
    //         ...provided,
    //         width: 0,
    //     }),
    //     multiValueLabel: (provided, _state) => ({
    //         ...provided,
    //         color: mainStyle.getPropertyValue("--selectors-text-color"),
    //         fontSize: fontSize,
    //         backgroundColor: mainStyle.getPropertyValue(
    //             "--selectors-background-color"
    //         ),
    //         fontFamily: "Roboto",
    //         display: "block",
    //     }),
    //     multiValueRemove: (provided, _state) => {
    //         return {
    //             ...provided,
    //             color: mainStyle.getPropertyValue("--selectors-text-color"),
    //             fontSize: fontSize,
    //             backgroundColor: mainStyle.getPropertyValue(
    //                 "--selectors-background-color"
    //             ),
    //             fontFamily: "Roboto",
    //             paddingLeft: 0,
    //             paddingRight: 0,
    //         };
    //     },
    //     dropdownIndicator: (provided, _state) => ({
    //         ...provided,
    //         color: mainStyle.getPropertyValue("--selectors-text-color"),
    //     }),
    // };
}

const customSelectStyles: Styles = getCustomSelectStyle(14);

const customSelectStylesForSharePopupNoBorder: Styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected
            ? "var(--selectors-text-color)"
            : state.isFocused
                ? "#39F"
                : "var(--selectors-text-color)",
        fontSize: 12,
        fontFamily: "Roboto",
        backgroundColor: state.isFocused
            ? mainStyle.getPropertyValue("--selectors-background-hover-color")
            : state.isSelected
                ? mainStyle.getPropertyValue("--selectors-background-hover-color")
                : mainStyle.getPropertyValue("--selectors-background-color"),
    }),
    control: (provided, _state) => ({
        ...provided,
        minHeight: "32px",
        borderRadius: 0,
        border: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
    }),
    placeholder: (provided, _state) => ({
        ...provided,
        opacity: 0.5,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
        fontSize: 12,
        fontFamily: "Roboto",
        display: state.selectProps.menuIsOpen ? "none" : "block",
    }),
    input: (provided, _state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
        fontSize: 12,
        fontFamily: "Roboto",
    }),
    menu: (provided, _state) => ({
        ...provided,
        backgroundColor: mainStyle.getPropertyValue(
            "--selectors-background-color"
        ),
        borderRadius: 4,
    }),
    indicatorSeparator: (provided, _state) => ({
        ...provided,
        width: 0,
    }),
    dropdownIndicator: (provided, _state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
    }),
};

const customSelectStylesForSharePopup: Styles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected
            ? "var(--selectors-text-color)"
            : state.isFocused
                ? "#39F"
                : "var(--selectors-text-color)",
        fontSize: 12,
        fontFamily: "Roboto",
        backgroundColor: state.isFocused
            ? mainStyle.getPropertyValue("--selectors-background-hover-color")
            : state.isSelected
                ? mainStyle.getPropertyValue("--selectors-background-hover-color")
                : mainStyle.getPropertyValue("--selectors-background-color"),
    }),
    control: (provided, _state) => ({
        ...provided,
        minHeight: "32px",
        borderRadius: 0,
        boxShadow: "none",
        backgroundColor: mainStyle.getPropertyValue(
            "--selectors-background-color"
        ),
    }),
    placeholder: (provided, _state) => ({
        ...provided,
        opacity: 0.5,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
        fontSize: 12,
        fontFamily: "Roboto",
        display: state.selectProps.menuIsOpen ? "none" : "block",
    }),
    input: (provided, _state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
        fontSize: 12,
        fontFamily: "Roboto",
    }),
    menu: (provided, _state) => ({
        ...provided,
        backgroundColor: mainStyle.getPropertyValue(
            "--selectors-background-color"
        ),
        borderRadius: 4,
    }),
    indicatorSeparator: (provided, _state) => ({
        ...provided,
        width: 0,
    }),
    dropdownIndicator: (provided, _state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
    }),
};

const customFilterStyles: Styles = {
    option: (provided, state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
        fontSize: 14,
        fontFamily: "Roboto",
        backgroundColor: state.isFocused
            ? mainStyle.getPropertyValue("--selectors-background-hover-color")
            : state.isSelected
                ? mainStyle.getPropertyValue("--selectors-background-hover-color")
                : mainStyle.getPropertyValue("--selectors-background-color"),
    }),
    control: (provided, _state) => ({
        ...provided,
        //     border: 0,
        backgroundColor: mainStyle.getPropertyValue(
            "--selectors-background-hover-color"
        ),
        margin: 3,
        borderRadius: 0,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--filters-text-color"),
        fontSize: 14,
        fontFamily: "Roboto",
        display: state.selectProps.menuIsOpen ? "none" : "block",
    }),
    input: (provided, _state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
        fontSize: 14,
        fontFamily: "Roboto",
    }),
    menu: (provided, _state) => ({
        ...provided,
        backgroundColor: mainStyle.getPropertyValue(
            "--selectors-background-color"
        ),
    }),
    indicatorSeparator: (provided, _state) => ({
        ...provided,
        width: 0,
    }),
    dropdownIndicator: (_provided, _state) => ({
        display: "none",
    }),
    clearIndicator: (_provided, _state) => ({
        display: "none",
    }),
};

const customMultiSelectStyles: Styles = {
    ...customSelectStyles,
    multiValue: (provided, _state) => {
        return {
            ...provided,
            width: "100%",
            backgroundColor: "transparent",
            justifyContent: "space-between",
        };
    },
    multiValueLabel: (provided, _state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
        fontSize: 14,
        backgroundColor: mainStyle.getPropertyValue(
            "--selectors-background-color"
        ),
        fontFamily: "Roboto",
        display: "block",
    }),
    multiValueRemove: (provided, _state) => {
        return {
            ...provided,
            fontSize: 14,
            backgroundColor: mainStyle.getPropertyValue(
                "--selectors-background-color"
            ),
            fontFamily: "Roboto",
            paddingLeft: 0,
            paddingRight: 0,
        };
    },
    indicatorsContainer: (provided, _state) => {
        return {
            ...provided,
            width: "36px",
            flexDirection: "column",
            justifyContent: "space-between",
        };
    },
};

const customSelectStylesWithoutIcons: Styles = {
    ...customSelectStyles,
    dropdownIndicator: (_provided, _state) => ({
        display: "none",
    }),
    clearIndicator: (_provided, _state) => ({
        display: "none",
    }),
};

const dataScienceSelectStyles: Styles = {
    option: (provided, state) => {
        return {
            ...provided,
            color: state.isFocused ? "#39F" : "#FFFFFF",
            fontSize: 25,
            fontFamily: "Roboto",
            border: state.isFocused
                ? "1px solid #1F8EFA"
                : "1px dashed #FFFFFF",
            borderRadius: "4px",
            backgroundColor: "transparent",
            marginTop: "5px",
            marginBottom: "5px",
        };
    },
    placeholder: (provided, _state) => ({
        ...provided,
        fontSize: 25,
    }),
    control: (provided, _state) => ({
        ...provided,
        border: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: dataScienceElementsStyle.primaryTextColor,
        fontSize: 25,
        fontFamily: "Roboto",
        backgroundColor: "transparent",
        display: state.selectProps.menuIsOpen ? "none" : "block",
    }),
    input: (provided, _state) => ({
        ...provided,
        color: dataScienceElementsStyle.primaryTextColor,
        fontSize: 25,
        fontFamily: "Roboto",
    }),
    dropdownIndicator: (provided, _state) => ({
        ...provided,
        color: mainStyle.getPropertyValue("--selectors-text-color"),
    }),
    menu: (provided, _state) => ({
        ...provided,
        backgroundColor: mainStyle.getPropertyValue("--primary-content-color"),
        border: "none",
        boxShadow: "none",
    }),
    indicatorSeparator: (provided, _state) => ({
        ...provided,
        width: 0,
    }),
};

export function getSmallSelectStyle(fontSize: number): Styles {
    return {
        option: (provided, state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            fontFamily: (state as unknown as StringOption).value? (state as unknown as StringOption).value: "Roboto",
            backgroundColor: state.isFocused
                ? mainStyle.getPropertyValue(
                    "--selectors-background-hover-color"
                )
                : state.isSelected
                    ? mainStyle.getPropertyValue(
                        "--selectors-background-hover-color"
                    )
                    : mainStyle.getPropertyValue("--selectors-background-color"),
        }),
        control: (provided, _state) => ({
            ...provided,
            backgroundColor: mainStyle.getPropertyValue(
                "--selectors-background-color"
            ),
            minHeight: 16,
            borderRadius: 3,
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            fontFamily: "Roboto",
            display: state.selectProps.menuIsOpen ? "none" : "block",
        }),
        input: (provided, _state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            fontFamily: "Roboto",
        }),
        menu: (provided, _state) => ({
            ...provided,
            backgroundColor: mainStyle.getPropertyValue(
                "--selectors-background-color"
            ),
            borderRadius: 3,
        }),
        placeholder: (provided, _state) => ({
            ...provided,
            fontSize: fontSize,
        }),
        indicatorSeparator: (provided, _state) => ({
            ...provided,
            width: 0,
        }),
        clearIndicator: (provided, _state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            transformOrigin: "right center",
            transform: "scale(0.5)",
        }),
        dropdownIndicator: (provided, _state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            transformOrigin: "right center",
            transform: "scale(0.5)",
        }),
        multiValueLabel: (provided, _state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            backgroundColor: mainStyle.getPropertyValue(
                "--selectors-background-color"
            ),
            fontFamily: "Roboto",
            display: "block",
        }),
        multiValueRemove: (provided, _state) => {
            return {
                ...provided,
                fontSize: fontSize,
                backgroundColor: mainStyle.getPropertyValue(
                    "--selectors-background-color"
                ),
                fontFamily: "Roboto",
                paddingLeft: 0,
                paddingRight: 0,
            };
        },
    };
}

export function getSmallSelectStyleFontFace(fontSize: number): Styles {
    return {
        option: (provided, state) => ({
            ...provided,
            height:"25px",
            cursor: "pointer",
            padding: "3px 0px 4px 5px",
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            fontFamily: (state as unknown as StringOption).value? (state as unknown as StringOption).value: "Roboto",
            backgroundColor: state.isFocused
                ? mainStyle.getPropertyValue(
                    "--selectors-background-hover-color"
                )
                : state.isSelected
                    ? mainStyle.getPropertyValue(
                        "--selectors-background-hover-color"
                    )
                    : mainStyle.getPropertyValue("--selectors-background-color"),
        }),
        control: (provided, _state) => ({
            ...provided,
            backgroundColor: mainStyle.getPropertyValue(
                "--selectors-background-color"
            ),
            minHeight: 16,
            borderRadius: 3,
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            fontFamily: "Roboto",
            display: state.selectProps.menuIsOpen ? "none" : "block",
        }),
        input: (provided, _state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            fontFamily: "Roboto",
        }),
        menu: (provided, _state) => ({
            ...provided,
            backgroundColor: mainStyle.getPropertyValue(
                "--selectors-background-color"
            ),
            borderRadius: 6,
        }),
        placeholder: (provided, _state) => ({
            ...provided,
            fontSize: fontSize,
        }),
        indicatorSeparator: (provided, _state) => ({
            ...provided,
            width: 0,
        }),
        clearIndicator: (provided, _state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            transformOrigin: "right center",
            transform: "scale(0.5)",
        }),
        dropdownIndicator: (provided, _state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            transformOrigin: "right center",
            transform: "scale(0.5)",
        }),
        multiValueLabel: (provided, _state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            backgroundColor: mainStyle.getPropertyValue(
                "--selectors-background-color"
            ),
            fontFamily: "Roboto",
            display: "block",
        }),
        multiValueRemove: (provided, _state) => {
            return {
                ...provided,
                fontSize: fontSize,
                backgroundColor: mainStyle.getPropertyValue(
                    "--selectors-background-color"
                ),
                fontFamily: "Roboto",
                paddingLeft: 0,
                paddingRight: 0,
            };
        },
    };
}

const customSelectStylesSmall: Styles = getSmallSelectStyle(8);

const customSelectStylesFontFace: Styles = getSmallSelectStyleFontFace(15);


interface StylesLight extends Omit<Styles, "input"> {
    input?: <Option>(
        provided: CSSObjectWithLabel,
        state?: InputProps<Option, boolean, GroupBase<Option>>
    ) => CSSObjectWithLabel;
}

// export function getCustomSelectStyleLight(
//     fontSize: number,
//     small: boolean,
//     backgroundColor: string = "#FFFFFF"
// ): StylesLight {
//     return {
//         option: (provided, state) => ({
//             ...provided,
//             backgroundColor: state.isFocused
//                 ? mainStyle.getPropertyValue(
//                       "--selectors-background-hover-color"
//                   )
//                 : state.isSelected
//                 ? "rgba(101, 125, 149, 0.1)"
//                 : mainStyle.getPropertyValue("--selectors-background-color"),
//             color: state.isSelected
//                 ? mainStyle.getPropertyValue("--selectors-text-color")
//                 : "#98A7B9",
//             fontSize: fontSize,
//             textAlign: "center",
//             fontFamily: "Roboto",
//         }),
//         control: (provided, _state) => ({
//             ...provided,
//             backgroundColor: backgroundColor,
//             minHeight: small ? 16 : 38,
//             boxShadow: "inset 0px 0px 2px rgba(0, 0, 0, 0.25)",
//             borderRadius: "3px",
//             borderColor: "rgb(204, 204, 204)",
//         }),
//         singleValue: (provided, state) => ({
//             ...provided,
//             color: "#98A7B9",
//             fontSize: fontSize,
//             width: "100%",
//             textAlign: "center",
//             fontFamily: "Roboto",
//             display: state.selectProps.menuIsOpen ? "none" : "block",
//         }),
//         multiValueLabel: (provided, _state) => ({
//             ...provided,
//             color: "#98A7B9",
//             fontSize: fontSize,
//             backgroundColor: mainStyle.getPropertyValue(
//                 "--selectors-background-color"
//             ),
//             fontFamily: "Roboto",
//             display: "block",
//         }),
//         multiValueRemove: (provided, _state) => {
//             return {
//                 ...provided,
//                 fontSize: fontSize,
//                 backgroundColor: mainStyle.getPropertyValue(
//                     "--selectors-background-color"
//                 ),
//                 fontFamily: "Roboto",
//                 paddingLeft: 0,
//                 paddingRight: 0,
//             };
//         },
//         input: (provided, _state) => ({
//             ...provided,
//             textAlign: "center",
//             width: "100%",
//             color: "#98A7B9",
//             fontSize: fontSize,
//             fontFamily: "Roboto",
//         }),
//         menu: (provided, _state) => ({
//             ...provided,
//             backgroundColor: mainStyle.getPropertyValue(
//                 "--selectors-background-color"
//             ),
//             borderRadius: 3,
//         }),
//         placeholder: (provided, _state) => ({
//             ...provided,
//             fontSize: fontSize,
//             opacity: 0.3,
//         }),
//         indicatorsContainer: (provided, state) => {
//             if (state.isMulti)
//                 return {
//                     ...provided,
//                     display: "none",
//                 };
//             return provided;
//         },
//         indicatorSeparator: (provided, _state) => ({
//             ...provided,
//             width: 0,
//         }),
//         dropdownIndicator: (provided, _state) => ({
//             ...provided,
//             paddingTop: 0,
//             paddingBottom: 0,
//             color: "#39F",
//             transformOrigin: "right center",
//             transform: small ? "scale(0.5)" : "none",
//         }),
//     };
// }

export function getCustomSelectStyleLight(
    fontSize: number,
    small: boolean,
    customBackgroundColor?: string,
    scale: number = 1,
): StylesLight {
    let defaultBackgroundColor = mainStyle.getPropertyValue(
        "--selectors-background-color"
    );
    let backgroundColor = customBackgroundColor ?? defaultBackgroundColor;
    let multiBackgroundColor = customBackgroundColor ?? defaultBackgroundColor;

    return {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#EAF0FA" : "transparent",
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            textAlign: "center",
            fontFamily: "Roboto",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            paddingLeft: 0,
        }),
        control: (provided, _state) => ({
            ...provided,
            backgroundColor: backgroundColor,
            minHeight: small ? 16 : 38,
            boxSizing: "border-box",
            borderRadius: "4px",
            borderColor: "#D1D1D1",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            width: "100%",
            textAlign: "left",
            marginLeft: "10px",
            fontFamily: "Roboto",
            display: state.selectProps.menuIsOpen ? "none" : "block",
        }),
        multiValueLabel: (provided, _state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            backgroundColor: multiBackgroundColor,
            fontFamily: "Roboto",
            display: "block",
        }),
        multiValueRemove: (provided, _state) => {
            return {
                ...provided,
                fontSize: fontSize,
                color: mainStyle.getPropertyValue("--selectors-text-color"),
                backgroundColor: multiBackgroundColor,
                fontFamily: "Roboto",
                paddingLeft: 0,
                paddingRight: 0,
            };
        },
        input: (provided, _state) => ({
            ...provided,
            textAlign: "center",
            width: "100%",
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            fontFamily: "Roboto",
            marginLeft: 10,
        }),
        menu: (provided, _state) => ({
            ...provided,
            backgroundColor: mainStyle.getPropertyValue(
                "--selectors-background-color"
            ),
            borderRadius: 6,
        }),
        placeholder: (provided, _state) => ({
            ...provided,
            fontSize: fontSize,
            marginLeft: 10,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            opacity: 0.5,
        }),
        indicatorsContainer: (provided, state) => {
            if (state.isMulti)
                return {
                    ...provided,
                    display: "none",
                };
            return provided;
        },
        indicatorSeparator: (provided, _state) => ({
            ...provided,
            width: 0,
        }),
        dropdownIndicator: (provided, _state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            color: "#606060",
            transformOrigin: "right center",
            transform: small ? "scale(0.5)" : "none",
        }),
    };
}

export function getCustomSelectStyleRefine(
    fontSize: number,
    small: boolean,
    backgroundColor: string = mainStyle.getPropertyValue(
        "--selectors-background-color"
    ),
    scale: number = 1,
): StylesLight {
    return {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#EAF0FA" : "transparent",
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize * scale,
            textAlign: "center",
            fontFamily: "Roboto",
        }),
        control: (provided, _state) => ({
            ...provided,
            backgroundColor: backgroundColor,
            minHeight: small ? 16 : 38,
            boxSizing: "border-box",
            borderRadius: 0,
            borderWidth: "0 0 1px 0",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            width: "100%",
            fontFamily: "Roboto",
            display: state.selectProps.menuIsOpen ? "none" : "block",
        }),
        multiValueLabel: (provided, _state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            backgroundColor: "rgba(59, 130, 201, 0.12)",
            fontFamily: "Roboto",
            display: "block",
        }),
        multiValueRemove: (provided, _state) => {
            return {
                ...provided,
                fontSize: fontSize,
                color: mainStyle.getPropertyValue("--selectors-text-color"),
                backgroundColor: "rgba(59, 130, 201, 0.12)",
                fontFamily: "Roboto",
                paddingLeft: 0,
                paddingRight: 0,
            };
        },
        input: (provided, _state) => ({
            ...provided,
            textAlign: "center",
            width: "100%",
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            fontFamily: "Roboto",
        }),
        menu: (provided, _state) => ({
            ...provided,
            backgroundColor: mainStyle.getPropertyValue(
                "--selectors-background-color"
            ),
            borderRadius: 3,
        }),
        placeholder: (provided, _state) => ({
            ...provided,
            fontSize: fontSize,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            opacity: 0.5,
        }),
        indicatorsContainer: (provided, state) => {
            if (state.isMulti)
                return {
                    ...provided,
                    display: "none",
                };
            return provided;
        },
        indicatorSeparator: (provided, _state) => ({
            ...provided,
            width: 0,
        }),
        dropdownIndicator: (provided, _state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            color: "#606060",
            transformOrigin: "right center",
            transform: small ? "scale(0.5)" : "none",
        }),
    };
}

export const getCustomSelectStyleForDataSection = getCustomSelectStyleLight;

export function getCustomSelectStyleForRegressionOptions(
    fontSize: number,
    small: boolean
): StylesLight {
    return {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#EAF0FA" : "#EFEFEF",
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            textAlign: "center",
            fontFamily: "Roboto",
        }),
        control: (provided, _state) => ({
            ...provided,
            width: "100%",
            backgroundColor: _state.isFocused ? "#EBF2F9" : "#EFEFEF",
            minHeight: 22,
            boxSizing: "border-box",
            borderRadius: "23px",
            borderColor: _state.isFocused ? "#3B82C9" : "transparent",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            width: "100%",
            textAlign: "center",
            fontFamily: "Roboto",
            display: state.selectProps.menuIsOpen ? "none" : "block",
        }),
        input: (provided, _state) => ({
            ...provided,
            textAlign: "center",
            width: "100%",
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            fontSize: fontSize,
            fontFamily: "Roboto",
        }),
        menu: (provided, _state) => ({
            ...provided,
            backgroundColor: mainStyle.getPropertyValue(
                "--selectors-background-color"
            ),
            borderRadius: 3,
        }),
        placeholder: (provided, _state) => ({
            ...provided,
            fontSize: fontSize,
            color: mainStyle.getPropertyValue("--selectors-text-color"),
            opacity: 0.5,
        }),
        indicatorsContainer: (provided, state) => {
            if (state.isMulti)
                return {
                    ...provided,
                    display: "none",
                };
            return provided;
        },
        indicatorSeparator: (provided, _state) => ({
            ...provided,
            width: 0,
        }),
        clearIndicator: (_provided, _state) => ({
            display: "none",
        }),
        dropdownIndicator: (provided, _state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            color: "#606060",
            transformOrigin: "right center",
            transform: small ? "scale(0.5)" : "none",
        }),
    };
}

export function leftAlignAndScrollBar(): Styles {
    return {
        singleValue: (base) => ({
            ...base,
            textAlign: 'left',
            paddingLeft: '5px',
        }),
        placeholder: (base) => ({
            ...base,
            paddingLeft: '5px',
            textAlign: 'left',
        }),
        option: ((base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? "var(--greyColor)" : "white",
            color: "black",
            borderRadius: 5,
            "&:hover": {
                backgroundColor: state.isSelected ? "var(--greyColor)" : "var(--greyColor)"
            }
        })),
        input:(base=>({
            ...base,
            paddingLeft:5
        })),
        menu:(base)=>({
            ...base,
            overflow:'auto',
            borderRadius:6
        }),
        menuList: (base => ({
            ...base,
            "::-webkit-scrollbar": {
                width: "6px",
                height: 5
            },
            "::-webkit-scrollbar-track": {
                background: "transparent"
                // background: "var(--greyColor)"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "50px"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            },
        }))
    };
}

const leftAlignAndScrollBarCSS: Styles = leftAlignAndScrollBar();
const customSelectStylesLight: Styles = getCustomSelectStyleLight(10, true);
const customSelectStylesRefine: Styles = getCustomSelectStyleRefine(10, true);


export default customSelectStyles;
export {
    customSelectStylesWithoutIcons,
    customSelectStylesSmall,
    customSelectStylesFontFace,
    customFilterStyles,
    searchComponentStyles,
    customMultiSelectStyles,
    dataScienceSelectStyles,
    customSelectStylesForSharePopup,
    customSelectStylesForSharePopupNoBorder,
    customSelectStylesLight,
    customSelectStylesRefine,
    leftAlignAndScrollBarCSS
};


