import React, { Component, CSSProperties } from "react";
import {
    CanvasElement,
    CanvasElementOutput,
    CanvasTextBox,
    hasAdditionalOutputs,
    CanvasProgressElement,
} from "common/Canvas";
import Collapse from "react-bootstrap/Collapse";
import styles from "./OutputsSelector.module.css";
import cx from "classnames";
import { ReactComponent as ChevronIcon } from "icons/chevron.svg";

interface Props {
    value: CanvasElement | CanvasTextBox | CanvasProgressElement;
    onChange: <T extends keyof CanvasElement>(
        changes: Pick<CanvasElement, T>
    ) => void;
    style?: CSSProperties;
}

interface State {
    collapsedRows: { [key: string]: boolean };
}

class OutputsSelector extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            collapsedRows: {},
        };
    }
    private buildOutputsSelectorItem(
        node: CanvasElement | CanvasTextBox | CanvasProgressElement,
        index: number | null,
        visibleIndex: number
    ): JSX.Element {
        let output: CanvasElementOutput =
            index != null && hasAdditionalOutputs(node)
                ? node.additionalOutputs[index]
                : {
                      leftUnit: node.leftUnit,
                      metric: node.metric,
                      subtitle: node.subtitle,
                      value: node.value,
                      unit: node.unit ?? "",
                      decimalPoints: node.decimalPoints,
                  };

        return (
            <div key={index} className={styles.collapsableLine}>
                <div className={styles.lineButtons}>
                    <button
                        type="button"
                        onClick={() => {
                            this.setState((prev) => {
                                const collapsedRowsCopy = {
                                    ...prev.collapsedRows,
                                };

                                const key = String(visibleIndex);
                                if (key in collapsedRowsCopy) {
                                    delete collapsedRowsCopy[key];
                                } else {
                                    collapsedRowsCopy[key] = true;
                                }

                                return { collapsedRows: collapsedRowsCopy };
                            });
                        }}
                        className={styles.expressionCollapseButton}
                    >
                        <ChevronIcon
                            className={cx(styles.expressionCollapseButtonIcon, {
                                [styles.chevronOpen]: !(
                                    String(visibleIndex) in
                                    this.state.collapsedRows
                                ),
                            })}
                        />
                        Output {visibleIndex}
                    </button>

                    {hasAdditionalOutputs(node) &&
                        (index === null ? (
                            <button
                                className={styles.addRemoveButton}
                                type="button"
                                onClick={() => {
                                    let additionalOutputs = Array.from(
                                        node.additionalOutputs
                                    );
                                    additionalOutputs.splice(
                                        index != null ? index + 1 : 0,
                                        0,
                                        {
                                            metric: "",
                                            value: NaN,
                                            unit: "",
                                        }
                                    );
                                    this.props.onChange({
                                        additionalOutputs: additionalOutputs,
                                    });
                                }}
                            >
                                {"\uFF0B"} Add
                            </button>
                        ) : (
                            <button
                                className={styles.addRemoveButton}
                                type="button"
                                onClick={() => {
                                    let additionalOutputs = Array.from(
                                        node.additionalOutputs
                                    );
                                    additionalOutputs.splice(index!, 1);
                                    this.props.onChange({
                                        additionalOutputs: additionalOutputs,
                                    });
                                }}
                            >
                                {"\u2715"}
                            </button>
                        ))}
                </div>
                <Collapse
                    in={!(String(visibleIndex) in this.state.collapsedRows)}
                >
                    <div>
                        <div
                            key={index || undefined}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "5px",
                            }}
                        >
                            <input
                                type="text"
                                className="like-select"
                                placeholder="NAME"
                                style={{
                                    width: "100px",
                                    marginLeft: "5px",
                                    alignSelf: "center",
                                }}
                                value={output?.subtitle || ""}
                                onChange={(evt) => {
                                    let newValue: string = evt.target.value;
                                    if (index == null) {
                                        this.props.onChange({
                                            subtitle: newValue,
                                        });
                                    } else if (hasAdditionalOutputs(node)) {
                                        let additionalOutputs = Array.from(
                                            node.additionalOutputs
                                        );
                                        additionalOutputs[index].subtitle =
                                            newValue;
                                        this.props.onChange({
                                            additionalOutputs:
                                                additionalOutputs,
                                        });
                                    }
                                }}
                            />

                            <input
                                type="text"
                                className="like-select"
                                placeholder="UNIT"
                                style={{
                                    width: "55px",
                                    marginLeft: "5px",
                                    alignSelf: "center",
                                }}
                                value={output?.leftUnit || ""}
                                onChange={(evt) => {
                                    let newValue: string = evt.target.value;
                                    if (index == null) {
                                        this.props.onChange({
                                            leftUnit: newValue,
                                        });
                                    } else if (hasAdditionalOutputs(node)) {
                                        let additionalOutputs = Array.from(
                                            node.additionalOutputs
                                        );
                                        additionalOutputs[index].leftUnit =
                                            newValue;
                                        this.props.onChange({
                                            additionalOutputs:
                                                additionalOutputs,
                                        });
                                    }
                                }}
                            />
                            <input
                                type="text"
                                className="like-select"
                                placeholder="METRIC"
                                style={{
                                    flex: 1,
                                    marginLeft: "5px",
                                    alignSelf: "center",
                                }}
                                value={output.metric}
                                onChange={(evt) => {
                                    let newValue: string = evt.target.value;
                                    if (index == null) {
                                        this.props.onChange({
                                            metric: newValue,
                                        });
                                    } else if (hasAdditionalOutputs(node)) {
                                        let additionalOutputs = Array.from(
                                            node.additionalOutputs
                                        );
                                        additionalOutputs[index].metric =
                                            newValue;
                                        this.props.onChange({
                                            additionalOutputs:
                                                additionalOutputs,
                                        });
                                    }
                                }}
                            />
                            <input
                                type="text"
                                className="like-select"
                                placeholder="UNIT"
                                style={{
                                    width: "55px",
                                    marginLeft: "5px",
                                    alignSelf: "center",
                                }}
                                value={output.unit}
                                onChange={(evt) => {
                                    let newValue: string = evt.target.value;
                                    if (index == null) {
                                        this.props.onChange({ unit: newValue });
                                    } else if (hasAdditionalOutputs(node)) {
                                        let additionalOutputs = Array.from(
                                            node.additionalOutputs
                                        );
                                        additionalOutputs[index].unit =
                                            newValue;
                                        this.props.onChange({
                                            additionalOutputs:
                                                additionalOutputs,
                                        });
                                    }
                                }}
                            />
                            <input
                                type={"number"}
                                className="like-select"
                                min="0"
                                max="9"
                                style={{
                                    padding: "2 0 0 0",
                                    marginLeft: "5px",
                                    width: "45px",
                                }}
                                value={output.decimalPoints}
                                placeholder="DEC"
                                onChange={(evt) => {
                                    let newValue: string = evt.target.value;
                                    if (index == null) {
                                        this.props.onChange({
                                            decimalPoints: Number(newValue),
                                        });
                                    } else if (hasAdditionalOutputs(node)) {
                                        let additionalOutputs = Array.from(
                                            node.additionalOutputs
                                        );
                                        additionalOutputs[index].decimalPoints =
                                            Number(newValue);
                                        this.props.onChange({
                                            additionalOutputs:
                                                additionalOutputs,
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }

    render() {
        let node = this.props.value;
        return (
            <div className="flex-simple-column" style={this.props.style}>
                {this.buildOutputsSelectorItem(node, null, 1)}
                {hasAdditionalOutputs(node) &&
                    node.additionalOutputs.map((_additionalOutput, index) => {
                        return this.buildOutputsSelectorItem(
                            node,
                            index,
                            index + 2
                        );
                    })}
            </div>
        );
    }
}
export default OutputsSelector;
