import React from "react";
import FindingItem from "./FindingItem";

function calcHeight(finding) {
    if (finding.type === "1a" || finding.type === "1ai") return 450;
    if (finding.type === "5") {
        return (
            finding.content.regressionResult.regressionBars.length * 100 + 60
        );
    }
    if (finding.type === "7") {
        return finding.content.clust.length * 300 + 100;
    }
    if (finding.type === "2" || finding.type === "10") return 300;
    return window.screen.height * 0.3;
}

function calcWidth(finding) {
    if (finding.type === "2") return finding.content.dataComparison.length * 55;
    if (finding.type === "10") return finding.content.data.length * 55;

    if (finding.type === "1a" || finding.type === "1ai")
        return window.screen.width * 2 - 100;
    return window.screen.width * 2;
}

function calcVoffset(finding) {
    if (finding.type === "1ai") return 50;
    return 0;
}

export default function MobileFindingItemWrapper(props) {
    let { finding } = props;

    return (
        <div
            style={{
                marginTop: 0,
                display: "flex",
                maxWidth: "100%",
            }}
        >
            <div
                className="element"
                style={{
                    width: "100%",
                    paddingRight: 0,
                    overflow: "auto",
                    height: window.screen.height * 0.7 * 0.6 * 0.8 - 10,
                    paddingBottom: 10,
                }}
            >
                <div
                    style={{
                        width: window.screen.width * 2,
                        height: "100%",
                        position: "relative",
                        paddingTop: 10,
                    }}
                >
                    <FindingItem
                        finding={finding}
                        storyId={props.storyId}
                        slideIndex={props.slideIndex}
                        insightId={props.insightId}
                        edited={false}
                        onEdit={() => {}}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: calcVoffset(finding),
                            left: 0,
                            zIndex: 5001,
                            backgroundColor: "#0000ff00",
                            height: calcHeight(finding),
                            width: calcWidth(finding),
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
