import React, { Component } from "react";
import { AggregateTableElement } from "common/Canvas";
import {
    aggregationOptions,
    updateCurrentAggregate,
    defaultAggregateOptions,
} from "common/AggregateTable";
import { mainStyle } from "common/MainStyle";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import QuestionHeaderView from "./question_views/QuestionHeaderView";
import DataScopeAndTableSelectorView from "common/DataScopeAndTableSelectorView";
import { Button } from "react-bootstrap";
import VariablesSelectorView from "common/VariablesSelectorView";
import AdminTableWithFullFeatures from "common/AdminTableWithFullFeatures";
import Tables, { TableOption } from "common/Tables";
import { DataScopeOption } from "common/DataScopes";
import Switch from "react-switch";
import { reaction } from "mobx";
import Variables from "common/Variables";
import { dataScienceSelectStyles } from "common/SelectStyles";
import Select, { createFilter } from "react-select";
import StringOption from "common/StringOption";
import CanvasPreventPropagationButton from "./CanvasPreventPropagationButton";
import { goToInternalLink } from "common/InternalLinksHelper";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import { dataScienceElementsStyle } from "common/DataScienceElementsStyle";
import remoteModuleId from "common/remoteModuleId";

// Added SVG buttons
import UpButtonBig from "icons/UpButtonBig.svg";
import DownButtonBig from "icons/DownButtonBig.svg";

interface Props {
    onContextMenu: (evt: React.MouseEvent) => void;
    aggregateTableElement: AggregateTableElement;
    aggregateTableElementId: string;
    height: number;
    live: boolean;
    frozen: boolean;
    sharedPolicy: CanvasSharedPolicy;
    onChange: (aggregatetableElement: AggregateTableElement) => void;
    onDelete: () => void;
    currentModuleId: number | undefined;
}

enum AggregateStatus {
    NotStarted = 1,
    AggregatingData = 2,
    Success = 3,
    Error = 4,
}

interface State {
    step: Step;
    animationDirection: string;
    aggregateMessageError: string | null;
    aggregateStatus: AggregateStatus;
    tableHeader: string[];
    tablePreview: any[];
    droppedValuesCount: number;
}
enum Step {
    Initial = 0,
    SelectData = 1,
    SelectAggregateVariables = 2,
    SelectOtherVariables = 3,
    Preview = 4,
    Final = 5,
}

function Header(props: { frozen: boolean; onDelete: () => void }) {
    return (
        <div
            style={{
                minHeight: "90px",
                width: "100%",
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 30,
            }}
        >
            <div className="my-row" style={{ justifyContent: "space-between" }}>
                {false ? (
                    <div className="my-row" style={{ alignItems: "center" }}>
                        <img
                            alt=""
                            src={"/dist/img/data-exploration/server_purple.png"}
                        />
                        <span
                            style={{
                                marginLeft: 30,
                                fontFamily: "Roboto",
                                fontSize: mainStyle.getPropertyValue(
                                    "--path-header-size"
                                ),
                                color:
                                    dataScienceElementsStyle.primaryTextColor,
                            }}
                        >
                            {"Creating Aggregate Data Element"}
                        </span>
                    </div>
                ) : (
                    <div />
                )}
                {!props.frozen && (
                    <CanvasPreventPropagationButton>
                        <div
                            onClick={(evt) => {
                                evt.stopPropagation();
                                props.onDelete();
                            }}
                        >
                            <img
                                alt=""
                                src="/dist/img/insights/insights_remove.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </CanvasPreventPropagationButton>
                )}
            </div>
        </div>
    );
}

export default class AggregateDataView extends Component<Props, State> {
    rootRef: React.RefObject<HTMLDivElement>;
    stepHistory: Step[];
    dataVariablesReaction: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            droppedValuesCount: -1,
            step: Step.Initial,
            animationDirection: "top",
            aggregateMessageError: null,
            aggregateStatus: AggregateStatus.NotStarted,
            tableHeader: [],
            tablePreview: [],
        };
        this.stepHistory = [Step.Initial];
        this.rootRef = React.createRef();
    }

    componentDidMount() {
        this.dataVariablesReaction = reaction(
            () =>
                Variables(
                    this.props.aggregateTableElement.dataScope?.value,
                    this.props.currentModuleId ?? remoteModuleId
                ).dataVariables,
            () => {
                if (this.props.aggregateTableElement.dataScope) {
                    let mergeDataElement = {
                        ...this.props.aggregateTableElement,
                        updateCurrentVariableAggregation: defaultAggregateOptions(
                            this.props.aggregateTableElement.dataScope.value
                        ),
                    };
                    this.props.onChange(mergeDataElement);
                }
            }
        );
    }
    componentWillUnmount() {
        this.dataVariablesReaction();
    }

    buildHeader() {
        if (this.props.live) return <div style={{ minHeight: "90px" }} />;
        return (
            <Header
                frozen={this.props.frozen}
                onDelete={() => {
                    this.props.onDelete();
                }}
            />
        );
    }

    private aggregateData(
        aggregateTableElement: AggregateTableElement,
        preview: boolean
    ): void {
        if (!preview) {
            this.setState({
                aggregateStatus: AggregateStatus.AggregatingData,
            });
        }
        updateCurrentAggregate(
            aggregateTableElement,
            (previewData) => {
                if (!preview) {
                    this.setState({
                        aggregateStatus: AggregateStatus.Success,
                    });
                    Tables(
                        aggregateTableElement.dataScope!.value,
                        this.props.currentModuleId ?? remoteModuleId
                    ).update(this.props.currentModuleId ?? remoteModuleId);
                } else {
                    this.setState({
                        droppedValuesCount: previewData!.droppedValuesCount,
                        tableHeader: previewData!.tableHeader,
                        tablePreview: previewData!.tablePreview,
                    });
                }
            },
            (errorMessage: string) => {
                if (!preview) {
                    this.setState({
                        aggregateStatus: AggregateStatus.Error,
                        aggregateMessageError: errorMessage,
                    });
                } else this.setState({ aggregateMessageError: errorMessage });
            },
            preview,
            aggregateTableElement.updateCurrentDropMissing,
            this.props.currentModuleId ?? remoteModuleId
        );
    }

    calculateNextStep(step: Step): Step {
        let newStep = step;
        switch (step) {
            case Step.Initial:
                newStep = Step.SelectData;
                break;
            case Step.SelectData:
                if (
                    this.props.aggregateTableElement.dataScope != null &&
                    this.props.aggregateTableElement.aggregateDataTable != null
                )
                    newStep = Step.SelectAggregateVariables;
                break;
            case Step.SelectAggregateVariables:
                newStep = Step.SelectOtherVariables;
                break;
            case Step.SelectOtherVariables:
                newStep = Step.Preview;
                break;
            case Step.Preview:
                if (this.state.aggregateMessageError == null)
                    newStep = Step.Final;
                break;
            default:
                // code...
                break;
        }
        return newStep;
    }
    stepUp() {
        if (this.state.step === Step.Initial) return;
        if (this.state.step === Step.Final) {
            if (this.state.aggregateStatus === AggregateStatus.AggregatingData)
                return;
            this.setState({
                aggregateStatus: AggregateStatus.NotStarted,
                aggregateMessageError: null,
            });
        }
        if (this.state.step === Step.Preview) {
            this.setState({
                aggregateMessageError: null,
            });
        }
        let prevStep = this.stepHistory[this.stepHistory.length - 2];
        this.setState({ animationDirection: "bottom" }, () => {
            this.setState(
                {
                    step: prevStep!,
                },
                () => {
                    this.stepHistory.pop();
                }
            );
        });
    }
    goToInitial() {
        this.setState(
            {
                animationDirection: "bottom",
                aggregateStatus: AggregateStatus.NotStarted,
            },
            () => {
                this.setState(
                    {
                        step: Step.Initial,
                    },
                    () => {
                        this.stepHistory = [this.state.step];
                    }
                );
            }
        );
    }
    stepDown() {
        if (this.state.step === Step.Final) return;
        let nextStep = this.calculateNextStep(this.state.step);
        if (nextStep === this.state.step) return;
        if (nextStep === Step.Preview) {
            this.aggregateData(this.props.aggregateTableElement, true);
        }

        this.setState({ animationDirection: "top" }, () => {
            this.setState(
                {
                    step: nextStep,
                },
                () => {
                    this.stepHistory.push(nextStep);
                }
            );
        });
        this.rootRef.current?.focus();
    }

    buildLeftBar() {
        return (
            <div
                className="flex-simple-column"
                style={{
                    paddingLeft: 48,
                    minWidth: "116px",
                }}
            >
                {this.state.step > Step.Initial && (
                    <CanvasPreventPropagationButton>
                        <div
                            onClick={(evt) => {
                                evt.stopPropagation();
                                this.stepUp();
                            }}
                        >
                            <img
                                alt=""
                                src={UpButtonBig} //"/dist/img/canvas/up_big.png"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </CanvasPreventPropagationButton>
                )}
                <div style={{ flexGrow: 1 }}> </div>
                {this.state.step !== Step.Initial &&
                    this.state.step < Step.Final && (
                        <CanvasPreventPropagationButton>
                            <div
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    this.stepDown();
                                }}
                            >
                                <img
                                    alt=""
                                    src={DownButtonBig} //"/dist/img/canvas/down_big.png"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </CanvasPreventPropagationButton>
                    )}
            </div>
        );
    }

    buildInnerItem() {
        if (this.state.step === Step.Initial) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        paddingBottom: "52px",
                        paddingRight: "116px",
                    }}
                >
                    <div
                        className="flex-simple-column"
                        style={{ width: "100%", alignItems: "center" }}
                    >
                        <div className="flex-simple-column">
                            <span
                                style={{
                                    textAlign: "center",
                                    fontSize: mainStyle.getPropertyValue(
                                        "--primary-path-title-size"
                                    ),
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto",
                                    color:
                                        dataScienceElementsStyle.primaryTextColor,
                                }}
                            >
                                {"Aggregate variables in data table"}
                            </span>
                            <CanvasPreventPropagationButton
                                style={{
                                    marginTop: "31px",
                                    alignSelf: "center",
                                }}
                            >
                                <div
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        if (
                                            this.props.sharedPolicy ===
                                            CanvasSharedPolicy.NotShared
                                        )
                                            this.stepDown();
                                        if (
                                            this.props.sharedPolicy ===
                                            CanvasSharedPolicy.SharedSlideUnAuth
                                        )
                                            goToInternalLink("/");
                                    }}
                                >
                                    <img
                                        alt=""
                                        src="/dist/img/data/add_data_small.png"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                            </CanvasPreventPropagationButton>
                            <CanvasPreventPropagationButton
                                style={{ alignSelf: "center", marginTop: 10 }}
                            >
                                <span
                                    style={{
                                        cursor: "pointer",
                                        border: "none",
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        fontFamily: "Roboto",
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        color:
                                            dataScienceElementsStyle.secondaryTextColor,
                                    }}
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        if (
                                            this.props.sharedPolicy ===
                                            CanvasSharedPolicy.NotShared
                                        )
                                            this.stepDown();
                                        if (
                                            this.props.sharedPolicy ===
                                            CanvasSharedPolicy.SharedSlideUnAuth
                                        )
                                            goToInternalLink("/");
                                    }}
                                >
                                    {"Click to proceed"}
                                </span>
                            </CanvasPreventPropagationButton>
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.step === Step.SelectData) {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Select the dataset and table"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <DataScopeAndTableSelectorView
                        needWritePermissions={true}
                        dataScopeOption={
                            this.props.aggregateTableElement.dataScope
                        }
                        tableOption={
                            this.props.aggregateTableElement.aggregateDataTable
                        }
                        maxHeight={this.props.height * 0.5}
                        onChange={(
                            dataScopeOption: DataScopeOption | null,
                            tableOption: TableOption | null
                        ) => {
                            let mergeDataElement = {
                                ...this.props.aggregateTableElement,
                                dataScope: dataScopeOption,
                                aggregateDataTable: tableOption,
                                updateCurrentBy: [null],
                                updateCurrentVariableAggregation: defaultAggregateOptions(
                                    dataScopeOption!.value
                                ),
                            };
                            this.props.onChange(mergeDataElement);
                        }}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        }
        if (this.state.step === Step.SelectAggregateVariables) {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={`Now select the variables (one  or more) at which level you want the data to be unique`}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <VariablesSelectorView
                        dataScope={this.props.aggregateTableElement.dataScope!}
                        variables={
                            this.props.aggregateTableElement.updateCurrentBy
                        }
                        maxHeight={this.props.height * 0.5}
                        onChange={(variables) => {
                            let aggregateTableElement = {
                                ...this.props.aggregateTableElement,
                                updateCurrentBy: variables,
                            };
                            aggregateTableElement.updateCurrentBy.forEach(
                                (variable) => {
                                    if (variable != null) {
                                        aggregateTableElement.updateCurrentVariableAggregation[
                                            variable.value
                                        ] = aggregationOptions[6];
                                    }
                                }
                            );
                            this.props.onChange(aggregateTableElement);
                        }}
                        currentModuleId={this.props.currentModuleId}
                    />
                </div>
            );
        }
        if (this.state.step === Step.SelectOtherVariables) {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={`For each of the other variables in ${
                            this.props.aggregateTableElement.aggregateDataTable!
                                .label
                        }, select the function to apply`}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <div
                        className="element"
                        style={{
                            display: "grid",
                            overflow: "auto",
                            gridTemplateColumns: "repeat(auto-fill, 200px)",
                            gridRowGap: "10px",
                            gridColumnGap: "10px",
                            maxHeight: this.props.height * 0.5,
                            height: this.props.height * 0.5,
                            marginLeft: "90px",
                        }}
                    >
                        {Variables(
                            this.props.aggregateTableElement.dataScope!.value,
                            this.props.currentModuleId ?? remoteModuleId
                        ).dataVariables.map((variable, index) => {
                            let isDisabled = this.props.aggregateTableElement.updateCurrentBy
                                .map((item) => item?.value ?? null)
                                .includes(variable.index);
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    onKeyDown={(evt) => {
                                        evt.stopPropagation();
                                    }}
                                    onMouseDown={(evt: any) => {
                                        evt.stopPropagation();
                                    }}
                                >
                                    <span
                                        style={{
                                            paddingLeft: "5px",
                                            fontSize: mainStyle.getPropertyValue(
                                                "--primary-path-title-size"
                                            ),
                                            fontFamily: "Roboto",
                                            color:
                                                dataScienceElementsStyle.secondaryTextColor,
                                        }}
                                    >
                                        {variable.name}, {variable.unit}
                                    </span>

                                    <Select
                                        menuPortalTarget={document.body}
                                        menuShouldBlockScroll={true}
                                        filterOption={createFilter({
                                            ignoreAccents: false,
                                        })}
                                        isDisabled={isDisabled}
                                        placeholder={""}
                                        styles={{
                                            ...dataScienceSelectStyles,
                                            container: (base) => ({
                                                ...base,
                                                width: "10em",
                                                height: "38px",
                                                marginBottom: "2px",
                                            }),
                                            dropdownIndicator: (provided) => {
                                                if (isDisabled)
                                                    return {
                                                        ...provided,
                                                        display: "none",
                                                    };
                                                else return provided;
                                            },
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 51,
                                            }),
                                        }}
                                        options={
                                            ["int", "float"].includes(
                                                variable.type
                                            )
                                                ? aggregationOptions
                                                : aggregationOptions.slice(4)
                                        }
                                        onChange={(newValue) => {
                                            let newUpdateCurrentVariableAggregation = [
                                                ...this.props
                                                    .aggregateTableElement
                                                    .updateCurrentVariableAggregation,
                                            ];
                                            newUpdateCurrentVariableAggregation[
                                                index
                                            ] = newValue as StringOption;
                                            this.props.onChange({
                                                ...this.props
                                                    .aggregateTableElement,
                                                updateCurrentVariableAggregation: newUpdateCurrentVariableAggregation,
                                            });
                                        }}
                                        value={
                                            this.props.aggregateTableElement
                                                .updateCurrentVariableAggregation[
                                                index
                                            ]
                                        }
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: "white",
                                                primary25:
                                                    "var(--selectors-background-hover-color)",
                                            },
                                        })}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
        if (this.state.step === Step.Preview) {
            return (
                <div
                    className="flex-simple-column"
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingRight: "109px",
                    }}
                >
                    <QuestionHeaderView
                        question={"Preview of the aggregated data"}
                        live={true}
                        frozen={true}
                        onChange={() => {}}
                    />
                    <div style={{ flexGrow: 0.3 }} />
                    <div
                        style={{
                            height: this.props.height * 0.5,
                            maxHeight: this.props.height * 0.5,
                            marginTop: "20px",
                            marginRight: "109px",
                        }}
                    >
                        <div
                            className="element"
                            style={{ overflow: "auto" }}
                            onKeyDown={(evt) => {
                                evt.stopPropagation();
                            }}
                            onMouseDown={(evt: any) => {
                                evt.stopPropagation();
                            }}
                        >
                            <AdminTableWithFullFeatures
                                tableName="Data"
                                paging={false}
                                tableHeader={this.state.tableHeader}
                                tableContent={this.state.tablePreview}
                            />
                        </div>
                        <div className="my-row" style={{ marginTop: "20px" }}>
                            <span
                                className="content-regular-text"
                                style={{
                                    color:
                                        dataScienceElementsStyle.primaryTextColor,
                                }}
                            >
                                {"Drop missing"}
                            </span>
                            <div
                                style={{ marginLeft: "5px" }}
                                onKeyDown={(evt) => {
                                    evt.stopPropagation();
                                }}
                                onMouseDown={(evt: any) => {
                                    evt.stopPropagation();
                                }}
                            >
                                <Switch
                                    onChange={() => {
                                        let aggregateTableElement = {
                                            ...this.props.aggregateTableElement,
                                            updateCurrentDropMissing: !this
                                                .props.aggregateTableElement
                                                .updateCurrentDropMissing,
                                        };
                                        this.props.onChange(
                                            aggregateTableElement
                                        );
                                        this.aggregateData(
                                            aggregateTableElement,
                                            true
                                        );
                                    }}
                                    checked={
                                        this.props.aggregateTableElement
                                            .updateCurrentDropMissing
                                    }
                                    width={40}
                                    height={20}
                                    offColor="#20293C"
                                    onColor="#20293C"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offHandleColor="#70889E"
                                    onHandleColor="#1F8EFA"
                                />
                            </div>
                            {this.state.droppedValuesCount !== -1 && (
                                <span
                                    style={{
                                        marginLeft: "5px",
                                        color:
                                            dataScienceElementsStyle.primaryTextColor,
                                    }}
                                    className="content-regular-text"
                                >
                                    {`${this.state.droppedValuesCount} will be dropped`}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.step === Step.Final) {
            let title = "";
            let color = dataScienceElementsStyle.primaryTextColor;
            switch (this.state.aggregateStatus) {
                case AggregateStatus.NotStarted:
                    title = "You’re all set! Click aggregate data to finish.";
                    break;
                case AggregateStatus.AggregatingData:
                    title = "Aggregate Data...";
                    break;

                case AggregateStatus.Success:
                    title = "Thank you for aggregating!";
                    break;
                case AggregateStatus.Error:
                    color = "red";
                    title = this.state.aggregateMessageError ?? "Unknown Error";
                    break;

                default:
                    // code...
                    break;
            }

            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        paddingBottom: "52px",
                        paddingRight: "116px",
                    }}
                >
                    <span
                        style={{
                            textAlign: "center",
                            fontSize: mainStyle.getPropertyValue(
                                "--primary-path-title-size"
                            ),
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            fontFamily: "Roboto",
                            color: color,
                        }}
                    >
                        {title}
                    </span>
                </div>
            );
        }
    }
    buildBottomBar() {
        if (this.state.step < Step.Final)
            return this.state.aggregateMessageError != null ? (
                <div
                    className="my-row"
                    style={{
                        alignSelf: "flex-end",
                        paddingRight: "109px",
                        marginTop: "10px",
                    }}
                >
                    <span
                        style={{
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "red",
                        }}
                    >
                        {this.state.aggregateMessageError}
                    </span>
                </div>
            ) : null;
        if (this.state.step === Step.Final)
            return (
                <div
                    className="my-row"
                    style={{
                        alignSelf: "flex-end",
                        paddingRight: "109px",
                        marginTop: "10px",
                    }}
                >
                    <CanvasPreventPropagationButton>
                        <Button
                            type="button"
                            className="btn btn-sm btn-primary my-primary"
                            style={{
                                width: "200px",
                            }}
                            onClick={(evt: any) => {
                                evt.stopPropagation();
                                if (
                                    this.state.aggregateStatus !==
                                    AggregateStatus.Success
                                ) {
                                    this.aggregateData(
                                        this.props.aggregateTableElement,
                                        false
                                    );
                                } else {
                                    this.goToInitial();
                                }
                            }}
                            disabled={
                                this.state.aggregateStatus ===
                                AggregateStatus.AggregatingData
                            }
                        >
                            {this.state.aggregateStatus ===
                            AggregateStatus.Success
                                ? "FINISH"
                                : "AGGREGATE DATA"}
                        </Button>
                    </CanvasPreventPropagationButton>
                </div>
            );
        return null;
    }
    buildContent() {
        return (
            <div
                className="my-row"
                style={{ height: "100%", width: "100%", paddingBottom: "38px" }}
            >
                {this.buildLeftBar()}
                <div className="flex-simple-column" style={{ width: "100%" }}>
                    <React.Fragment key={this.state.step}>
                        {this.buildInnerItem()}
                    </React.Fragment>
                    {this.buildBottomBar()}
                </div>
            </div>
        );
    }
    render() {
        return (
            <div
                ref={this.rootRef}
                className="dashboard-rect-canvas dashboard-rect-canvas-focus"
                tabIndex={0}
                style={{
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                }}
                onContextMenu={this.props.onContextMenu}
                onKeyDown={(evt) => {
                    if (evt.key === "ArrowDown") {
                        evt.stopPropagation();
                        this.stepDown();
                        evt.preventDefault();
                        return;
                    }
                    if (evt.key === "ArrowUp") {
                        evt.stopPropagation();
                        evt.preventDefault();
                        this.stepUp();
                        return;
                    }
                    if (evt.key === "Enter" && evt.shiftKey) {
                        evt.stopPropagation();
                        evt.preventDefault();
                        this.stepDown();
                        return;
                    }
                }}
            >
                <div
                    className="flex-column"
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                >
                    {this.buildHeader()}

                    <TransitionGroup
                        style={{
                            height: "100%",
                            display: "flex",
                        }}
                    >
                        <CSSTransition
                            key={this.state.step}
                            timeout={500}
                            classNames={"journeywizard-".concat(
                                this.state.animationDirection || ""
                            )}
                        >
                            <div style={{ minWidth: "100%" }}>
                                {this.buildContent()}
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}
