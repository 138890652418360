import React from "react";
import moment from "moment";
import DefaultTable, { Column } from "common/Table";
import styles from "./HomeSession.module.css";
import TableActions, { Action } from "common/Table/components/TableActions";
import { ReactComponent as Delete } from "icons/guidance_wizard/table_options/delete.svg";
import { ReactComponent as Disable } from "icons/guidance_wizard/table_options/disable.svg";
import { ReactComponent as Edit } from "icons/guidance_wizard/table_options/edit.svg";
import { ReactComponent as Share } from "icons/guidance_wizard/table_options/share.svg";
import { ReactComponent as MagnifierIcon } from "icons/magnifier.svg";
import MessagePopup from "common/MessagePopup";
import {
    guidanceWizardEntryEditApi,
    guidanceWizardEntryListApi,
    guidanceWizardEntryRemoveApi,
} from "../../Api";

interface IProps {
    onEditClicked: (entryId: number) => void;
    onShareClicked: (entryId: number) => void;
}

interface Row {
    id: number;
    title: string;
    lastModified: number; // Timestamp
    status: string;
}

const HomeSession: React.FC<IProps> = ({ onEditClicked, onShareClicked }) => {
    const [searchText, setSearchText] = React.useState<string>("");
    const [deleteModalOpenForId, setDeleteModalOpenForId] = React.useState<
        number | undefined
    >(undefined);
    const [rows, setRows] = React.useState<Row[]>([]);

    const actions: Action<Row>[] = React.useMemo(
        () => [
            {
                Icon: () => <Edit />,
                optLabel: "Edit",
                optKey: "edit",
                callback: (row) => {
                    onEditClicked(row.id);
                },
            },
            {
                Icon: () => <Share />,
                optLabel: "Share",
                optKey: "share",
                callback: (row) => {
                    onShareClicked(row.id);
                },
            },
            {
                Icon: () => <Disable />,
                optLabel: (row) =>
                    row.status === "active" ? "Disable" : "Enable",
                optKey: "disable",
                callback: (row, rowIndex) => {
                    let newStatus =
                        row.status === "active" ? "inactive" : "active";
                    guidanceWizardEntryEditApi(
                        row.id,
                        undefined,
                        undefined,
                        undefined,
                        newStatus === "inactive"
                    )
                        .then(() => {
                            setRows((rows) => {
                                let newRows = [...rows];
                                newRows[rowIndex] = {
                                    ...newRows[rowIndex],
                                    status: newStatus,
                                };
                                return newRows;
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                },
            },
            {
                Icon: () => <Delete />,
                optLabel: "Delete",
                optKey: "delete",
                callback: (row) => {
                    setDeleteModalOpenForId(row.id);
                },
            },
        ],
        [onEditClicked, onShareClicked, setDeleteModalOpenForId]
    );

    const columns: Column<Row>[] = React.useMemo(
        () => [
            {
                headerName: "Name",
                objectName: "title",
                sortable: true,
                width: 45,
            },
            {
                headerName: "Last Updated",
                objectName: "lastModified",
                sortable: true,
                width: 25,
                formatter: (row) =>
                    moment.unix(row.lastModified).format("YYYY/MM/DD"),
            },
            {
                headerName: "Status",
                objectName: "status",
                sortable: true,
                width: 25,
            },
            {
                headerName: "Action",
                objectName: "id",
                sortable: false,
                width: 5,
                align: "center",
                AltRender: ({ row, rowIndex }) => {
                    return (
                        <TableActions
                            row={row}
                            rowIndex={rowIndex}
                            actions={actions}
                        />
                    );
                },
            },
        ],
        [actions]
    );

    React.useEffect(() => {
        guidanceWizardEntryListApi()
            .then((entries) =>
                setRows(
                    entries.map((entry) => ({
                        id: entry.id,
                        title: entry.title,
                        lastModified: entry.lastModified,
                        status: entry.disabled ? "inactive" : "active",
                    }))
                )
            )
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div className={styles.card}>
            {deleteModalOpenForId != null && (
                <MessagePopup
                    title={"Delete Presentation"}
                    danger
                    message={
                        "Are you sure you want to delete this presentation?"
                    }
                    acceptButtonTitle={"Delete"}
                    onAccept={() => {
                        guidanceWizardEntryRemoveApi(deleteModalOpenForId)
                            .then(() => {
                                setRows((rows) =>
                                    rows.filter(
                                        (row) => row.id !== deleteModalOpenForId
                                    )
                                );
                                setDeleteModalOpenForId(undefined);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }}
                    onReject={() => {
                        setDeleteModalOpenForId(undefined);
                    }}
                    zIndex={100000000}
                />
            )}
            <div className={styles.contentWrapper}>
                <p className={styles.header}>Apps</p>
                <div className={styles.searchBarWrapper}>
                    <div className={styles.searchContainer}>
                        <input
                            placeholder="Search"
                            type="text"
                            value={searchText}
                            onChange={(evt) => setSearchText(evt.target.value)}
                            className={styles.searchInput}
                        />
                        <MagnifierIcon stroke="red" />
                    </div>
                </div>
                <DefaultTable
                    columns={columns}
                    data={rows}
                    initialSort={{ columnIndex: 1, direction: "desc" }}
                    search={
                        searchText.length !== 0
                            ? {
                                  columnIndex: 0,
                                  searchText: searchText,
                              }
                            : undefined
                    }
                    textIfDataEmpty="No presentations"
                />
            </div>
        </div>
    );
};

export default HomeSession;
